import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map, pick, omit } from 'lodash';
import queryString from 'query-string';
import { countryOptions } from 'core/assets/js/lib/isoCountries';
import { languageOptions } from 'core/assets/js/lib/isoLanguages';
import { stringifyQueryParams } from 'core/assets/js/lib/utils';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import TagsFilterField from 'core/assets/js/components/FinalFormFilterFields/TagsFilterField.jsx';
import RangeFilterField from 'core/assets/js/components/FinalFormFilterFields/RangeFilterField.jsx';
import SwitchFilterField from 'core/assets/js/components/FinalFormFilterFields/SwitchFilterField.jsx';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { prepareRateLimit } from 'search/assets/js/lib/utils';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';


class DiscoverTalentSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
    this.onFiltersChanged = this.onFiltersChanged.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();
    const filtersList = map(searchSpec.filters, 'paramName');

    let values = queryString.parse(location.search);
    values = pick(values, ['kw'].concat(filtersList));
    return values;
  }

  getSearchSpec() {
    const { activeOrg } = this.props;
    const rateRangeLimit = prepareRateLimit({ rangeMax: 1000 });

    return {
      searchTerm: {
        placeholder: 'Search...',
        paramName: 'kw',
        component: AutoSuggestField,
        fetchSuggestions: false,
      },
      filters: [
        {
          label: 'Rate',
          paramName: 'rate',
          fieldComponent: RangeFilterField,
          min: rateRangeLimit.min,
          max: rateRangeLimit.max,
          step: rateRangeLimit.step,
          symbol: CURRENCY_SYMBOL[activeOrg.currency],
        },
        {
          label: 'Country',
          paramName: 'countryCode',
          fieldComponent: TagsFilterField,
          inputClassName: 'col-12 col-md-6',
          options: countryOptions,
        },
        {
          label: 'Language',
          paramName: 'languages',
          fieldComponent: SelectableListFilterField,
          options: languageOptions,
          multiple: true,
        },
        {
          label: 'Attachments',
          switchText: 'Only with attachments',
          paramName: 'hasPortfolio',
          fieldComponent: SwitchFilterField,
        },
      ],
    };
  }

  onFiltersChanged(query) {
    const { history } = this.props;
    history.push({
      path: history.location.pathname,
      search: stringifyQueryParams(query.kw ? { ...omit(query, 'tags') } : query),
    });
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;

    return (
      <SearchFinalForm
        className="pl-0 pr-0"
        filtersOpen={filtersOpen}
        initialValues={this.getInitialValues()}
        name="talents"
        onFiltersChanged={this.onFiltersChanged}
        onFiltersToggle={onFiltersToggle}
        searchSpec={this.getSearchSpec()}
        searchContainerClass="col-12 col-md-auto mt-3 mt-md-0 ml-0 ml-md-3 px-0 position-relative w-50"
      />
    );
  }
}
DiscoverTalentSearch.propTypes = {
  activeOrg: orgSpec.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});
const PeopleSearchConnect = connect(
  mapStateToProps,
)(DiscoverTalentSearch);

export default withRouter(PeopleSearchConnect);
