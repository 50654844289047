import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import queryString from 'query-string';
import { omit, isEmpty } from 'lodash';

/*
 * Wraps a View component and manages the filters toggling.
 */
const withFilters = (WrappedComponent, componentName = null) => {
  const WrappedComponentClass = class extends React.Component {
    static FetchData(args) {
      return WrappedComponent.FetchData(args);
    }

    static GetComponentName(params) {
      return componentName || WrappedComponent.GetComponentName(params);
    }

    constructor(props) {
      super(props);
      this.state = {
        filtersOpen: false,
      };

      this.onFiltersToggle = this.onFiltersToggle.bind(this);
    }

    onFiltersToggle(isOpen) {
      this.setState({ filtersOpen: isOpen });
    }

    render() {
      const { filtersOpen } = this.state;
      const { location } = this.props;
      const query = queryString.parse(location.search);
      const showingSearchResults = !isEmpty(omit(query, ['page', 'sortBy', 'ordering']));
      return (
        <WrappedComponent
          onFiltersToggle={this.onFiltersToggle}
          filtersOpen={filtersOpen}
          showingSearchResults={showingSearchResults}
          {...this.props}
        />
      );
    }
  };

  WrappedComponentClass.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return WrappedComponentClass;
};

withFilters.propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
};

export default compose(withRouter, withFilters);
