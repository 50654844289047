import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import PeopleDocumentsTab from 'people/assets/js/components/PeopleDocumentsTab.jsx';
import PeopleExperienceTab from 'people/assets/js/components/PeopleExperienceTab.jsx';
import PeopleNotesTab from 'people/assets/js/components/PeopleNotesTab.jsx';
import PeoplePaymentDetailsTab from 'people/assets/js/components/PeoplePaymentDetailsTab.jsx';
import PeopleProjectsTab from 'people/assets/js/components/PeopleProjectsTab.jsx';
import PeopleRatesTab from 'people/assets/js/components/PeopleRatesTab.jsx';
import PeopleReviewsTab from 'people/assets/js/components/PeopleReviewsTab.jsx';
import PeopleTaxInformationTab from 'people/assets/js/components/PeopleTaxInformationTab.jsx';
import { PROFILE_TABS, ICON } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import OnboardingProgressBar from 'core/assets/js/components/OnboardingProgressBar.jsx';

const ProfileTab = ({
  company,
  organization,
  parentComponentName,
  paymentMethod,
  profile,
  tab,
  user,
  userId,
  userCard,
  viewerId,
}) => {
  const { alias, currencySymbol, rateUnit } = organization;
  if (!tab) {
    return null;
  }

  const tabMapping = {
    [PROFILE_TABS.REVIEWS]: (
      <PeopleReviewsTab
        userId={userId}
        viewerId={viewerId}
      />
    ),
    [PROFILE_TABS.EXPERIENCE]: (
      <PeopleExperienceTab
        parentComponentName={parentComponentName}
        profile={profile}
        user={user}
      />
    ),
    [PROFILE_TABS.ONBOARDING]: (
      <div className="user-profile__onboarding-progress">
        <Card.Header className="d-flex align-items-center justify-content-between">
          Onboarding progress
          <OnboardingProgressBar percentage={userCard.onboardingProgress} alignRight />
        </Card.Header>
        {userCard?.onboardingSteps?.map(({ completed, description }, stepIndex) => (
          <div
            className="step py-3 mx-4 d-flex align-items-center justify-content-between"
            key={`${description}-${stepIndex}`}
          >
            <div className={`description${completed ? ' completed' : ''}`}>
              {description}
            </div>
            {completed && <span className={ICON.SUCCESS} />}
          </div>
        ))}
      </div>
    ),
    [PROFILE_TABS.NOTES]: (
      <PeopleNotesTab
        viewerId={viewerId}
      />
    ),
    [PROFILE_TABS.RATES]: (
      <PeopleRatesTab
        orgAlias={alias}
        currencySymbol={currencySymbol}
        rateUnit={rateUnit}
        userCard={userCard}
        viewerId={viewerId}
      />
    ),
    [PROFILE_TABS.PROJECTS]: <PeopleProjectsTab userCard={userCard} viewerId={viewerId} />,
    [PROFILE_TABS.TAX_INFORMATION]: <PeopleTaxInformationTab company={company} />,
    [PROFILE_TABS.PAYMENT_DETAILS]: <PeoplePaymentDetailsTab paymentMethod={paymentMethod} />,
    [PROFILE_TABS.DOCUMENTS]: <PeopleDocumentsTab viewerId={viewerId} />,
  };
  return tabMapping[tab];
};

ProfileTab.propTypes = {
  company: PropTypes.object,
  organization: PropTypes.shape({
    alias: PropTypes.string,
    currencySymbol: PropTypes.string,
    rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  }).isRequired,
  parentComponentName: PropTypes.string.isRequired,
  paymentMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  profile: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  user: PropTypes.object,
  userId: PropTypes.number.isRequired,
  userCard: PropTypes.object.isRequired,
  viewerId: PropTypes.number.isRequired,
};

ProfileTab.defaultProps = {
  paymentMethod: null,
  company: null,
  user: {},
};

export default ProfileTab;
