import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const ReportHeaderMenu = ({ children }) => {
  return (
    <span
      className="ml-auto finance-report__toolbar flex-wrap align-items-center justify-content-end"
      data-testid="report-header-menu"
    >
      {children}
    </span>
  );
};

ReportHeaderMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const ReportHeaderMenuConnected = connect(
  mapStateToProps,
)(ReportHeaderMenu);

export default withRouter(ReportHeaderMenuConnected);
