import React from 'react';
import PropTypes from 'prop-types';

import ReadMore from 'core/assets/js/components/ReadMore.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import TagList from 'core/assets/js/components/TagList.jsx';
import AttachmentList from 'core/assets/js/components/FileUploader/AttachmentList.jsx';
import OpportunityRateGuide from 'projects/assets/js/components/OpportunityRateGuide.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import AnswersList from 'core/assets/js/components/AnswersList.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';

const ProjectBriefDescription = (
  {
    project,
    project: {
      attachments,
      brief,
      clients,
      currency,
      rateGuideFixed,
      rateGuideMaximum,
      rateGuideMinimum,
      rateGuideUnit,
      skills,
      tags,
    },
    rateGuideClassName,
    showSkills, truncateDescription, words,
  },
) => (
  <div className="project-description-widget">
    {rateGuideUnit && (
      <OpportunityRateGuide
        className={rateGuideClassName}
        currency={currency}
        fixed={rateGuideFixed}
        maximum={rateGuideMaximum}
        minimum={rateGuideMinimum}
        rateUnit={rateGuideUnit}
      />
    )}

    <h4>Project Brief</h4>

    {!truncateDescription && (
      <MarkdownText withBreaksPlugin text={brief} />
    )}

    {truncateDescription && (
      <ReadMore
        text={brief}
        words={words}
      />
    )}

    <h4 className="mt-5">Attachments</h4>
    <div className="attachments-list">
      <AttachmentList attachments={attachments} />
    </div>

    <h4 className="mt-5">Client</h4>
    <div className="mb-4">
      <TagList tags={clients} />
    </div>

    <h4 className="mt-5">Tags</h4>
    <div className="mb-4">
      <TagList tags={tags} />
    </div>

    {showSkills && (
      <React.Fragment>
        <h4 className="mt-5">Skills</h4>
        <div className="mb-4">
          <SkillList skills={skills} />
        </div>
      </React.Fragment>
    )}

    <AnswersList
      answers={project.answers}
      labelClass="project-field-label mt-5"
      wrapperClass="answer-value mb-4"
    />
  </div>
);

ProjectBriefDescription.propTypes = {
  project: projectSpec.isRequired,
  rateGuideClassName: PropTypes.string,
  showSkills: PropTypes.bool,
  truncateDescription: PropTypes.bool,
  words: PropTypes.number,
};
ProjectBriefDescription.defaultProps = {
  rateGuideClassName: 'mb-5',
  showSkills: true,
  truncateDescription: false,
  words: 40,
};

export default ProjectBriefDescription;
