import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loginUrl } from 'accounts/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { validateInvitationActiveDS, selectActiveInvitation } from 'invitations/assets/js/ducks/invitation';
import { invitationManageUrl } from 'invitations/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import InvitationCard from 'invitations/assets/js/components/InvitationCard.jsx';
import { selectAuthenticated } from 'accounts/assets/js/reducers/auth';


class InvitationStatusView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(validateInvitationActiveDS({
        token: params.token, url, authedAxios, componentName,
      })),
    ]);
  }

  static GetComponentName() {
    return 'InvitationStatusView';
  }

  componentDidMount() {
    const { isAuthenticated, history, params } = this.props;
    if (isAuthenticated === true) {
      history.push(invitationManageUrl(params.token));
    }
  }

  render() {
    const { dispatch, isValid, invitation } = this.props;

    return (
      <div className="clearfix">
        <TDApiConnected duck="invitation" component={this.constructor}>
          <InvitationCard
            allowReject={false}
            allowAccept={false}
            dispatch={dispatch}
            doneUrl={loginUrl()}
            invitation={invitation}
            isValid={isValid}
            pendingInvitationMessage="You have received an email with an invitation"
            showCard={false}
            componentName={this.constructor.GetComponentName()}
          />
        </TDApiConnected>
      </div>
    );
  }
}

InvitationStatusView.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  invitation: invitationSpec.isRequired,
  params: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
};

InvitationStatusView.defaultProps = {
  isValid: null,
};

const mapStateToProps = (state, props) => {
  const invitationState = selectActiveInvitation(state);
  return {
    isAuthenticated: selectAuthenticated(state),
    isValid: invitationState.isValid,
    invitation: invitationState.item,
    params: props.match.params,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationStatusView));
