
export const discoverTalentApiUrl = ({ orgAlias, integration = 'pph' }, url = '') => (
  `${url}/api/${orgAlias}/search/${integration}`
);

export const discoverTalentDetailApiUrl = ({ orgAlias, talentId, integration = 'pph' }, url = '') => (
  `${url}/api/${orgAlias}/search/${integration}/${talentId}`
);

export const discoverTalentReviewApiUrl = ({ orgAlias, talentId, integration = 'pph' }, url = '') => (
  `${url}/api/${orgAlias}/search/${integration}/${talentId}/reviews`
);

export const orgInviteTalentApiUrl = ({ orgAlias, integration = 'pph' }) => (
  `/api/${orgAlias}/search/${integration}/invite`
);

export const toggleFavoriteApiURL = ({ orgAlias, integration = 'pph' }, url = '') => (
  `${url}/api/${orgAlias}/search/${integration}/favorite`
);
