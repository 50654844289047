import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { orgPeopleListApiUrl } from 'people/urls';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import { BS_STYLE, USER_CARD_STATUS } from 'core/assets/js/constants';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { postFinControllerDS } from 'settings/assets/js/data-services/settings';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import FinControllerView from 'settings/assets/js/FinControllerView.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';

export const MODAL_ID = 'add-financial-controller-modal';

class AddFinancialControllerPanel extends Component {
  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    const apiUrl = orgPeopleListApiUrl(params, url);
    const prerequisites = [
      dispatch(fetchListDS({
        url: apiUrl,
        querystring,
        componentName,
        authedAxios,
      })),
    ];

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'AddFinancialControllerPanel';
  }

  constructor(props) {
    super(props);

    this.props = props;
    this.constantFilters = {
      excludeFinControllers: true,
      excludeOrgOwner: true,
      status: USER_CARD_STATUS.APPROVED,
    };
    this.state = {
      query: { ...this.constantFilters },
      showSelectedItems: false,
      submitting: false,
    };

    this.handleShowSelectedItemsToggle = this.handleShowSelectedItemsToggle.bind(this);
    this.handleClosePanel = this.handleClosePanel.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
  }

  handleClosePanel() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(MODAL_ID));
  }

  handleQueryUpdate(newQuery) {
    const { pagination } = this.props;
    this.setState({ query: { ...this.constantFilters, ...newQuery, page: pagination.page } });
  }

  handlePageChange(newPagination) {
    const { query } = this.state;
    const newQuery = { ...this.constantFilters, ...query, ...newPagination };
    this.setState(
      { query: newQuery },
    );
  }

  handleShowSelectedItemsToggle() {
    const { showSelectedItems } = this.state;
    this.setState({ showSelectedItems: !showSelectedItems });
  }

  handleSubmission() {
    this.setState({
      submitting: true,
    });
    const {
      dispatch, selectedItems, match: { params: { orgAlias } },
    } = this.props;
    const userIds = selectedItems.map(({ user: { id } }) => id);
    dispatch(postFinControllerDS({ orgAlias, userIds })).then(() => {
      this.handleClosePanel();
      dispatch(requestRefreshAC(FinControllerView.GetComponentName()));
      this.setState({
        submitting: false,
      });
    });
  }

  render() {
    const {
      isAddFinControllerModalOpen, people, filtersOpen,
      onFiltersToggle, selectedItems, rateLimits, userGroupNames,
      match: { params: { orgAlias } },
    } = this.props;

    const userType = PEOPLE_TYPE.MANAGERS;

    const { query, showSelectedItems, submitting } = this.state;

    const userGroupNamesFormatted = userGroupNames.map(ugn => ({
      value: ugn.id.toString(), text: ugn.name,
    }));
    const availabilityFilter = query.availability ? JSON.parse(query.availability) : null;

    return (
      <ModalPanel
        modalId={MODAL_ID}
        open={isAddFinControllerModalOpen}
        heading={(
          <h4>
            Add financial controller
          </h4>
        )}
        body={(
          <React.Fragment>
            {/* Member selection Step */}
            <SelectableListWrapper
              componentName={AddFinancialControllerPanel.GetComponentName()}
              fetchData={
                ({ params, ...rest }) => AddFinancialControllerPanel.FetchData({
                  ...rest, params: { ...params, peopleType: userType },
                })
              }
              skeletonComponent={PeopleListSkeleton}
              query={query}
              filtersOpen={filtersOpen}
              selectModeEnabled
              showSelectedItems={showSelectedItems}
              cardItem={{
                component: ManagerCard,
                props: {
                  orgAlias,
                  onUpdated: () => {},
                  availabilityFilter,
                  showAvailabilityMessages: true,
                  // Open link in a new tab so that user doesn't loose selected items.
                  linkTarget: '_blank',
                },
              }}
              onPageChange={this.handlePageChange}
              emptyListMessage={`No ${userType} found.`}
              emptySelectedListMessage={(
                <p className="discreet">
                  {`There are currently no selected ${userType}.`}
                  <br />
                  <span
                    className="imitate-link"
                    onClick={this.handleShowSelectedItemsToggle}
                  >
                    {`Find ${userType}`}
                  </span>
                  .
                </p>
              )}
              searchComponent={(
                <PeopleSearch
                  peopleType={userType}
                  query={query}
                  rateLimits={rateLimits}
                  onFiltersToggle={onFiltersToggle}
                  filtersOpen={filtersOpen}
                  isManager
                  onFiltersChanged={this.handleQueryUpdate}
                  hideStatus
                  userGroupNames={userGroupNamesFormatted}
                />
              )}
              items={people}
            />
          </React.Fragment>
        )}
        footer={(
          <React.Fragment>
            <span className="cursor-pointer d-flex align-items-center" onClick={this.handleShowSelectedItemsToggle}>
              <TDSwitch selected={showSelectedItems} />
              <span className="ml-3">
                { !showSelectedItems
                  ? `Show ${selectedItems.length} selected ${
                  // Remove 's' if only one
                    selectedItems.length === 1
                      ? userType.substring(0, userType.length - 1)
                      : userType
                  }`
                  : `Show all ${userType}`
                }
              </span>
            </span>

            <div className="ml-auto">
              <React.Fragment>
                <TDButton
                  label="Cancel"
                  onClick={this.handleClosePanel}
                />

                <TDButton
                  className="ml-4"
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                  disabled={selectedItems.length === 0 || submitting}
                  label="SUBMIT"
                  onClick={this.handleSubmission}
                />
              </React.Fragment>
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}
AddFinancialControllerPanel.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  isAddFinControllerModalOpen: PropTypes.bool,
  people: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  orgAlias: PropTypes.string,
  pagination: PropTypes.object.isRequired,
  rateLimits: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  userGroupNames: PropTypes.array,
  match: routerMatchContentsSpec.isRequired,
};

AddFinancialControllerPanel.defaultProps = {
  isAddFinControllerModalOpen: false,
  orgAlias: '',
  rateLimits: {},
  userGroupNames: [],
  people: [],
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, AddFinancialControllerPanel.GetComponentName());

  return {
    isAddFinControllerModalOpen: getIsModalOpen(state, MODAL_ID),
    match: props.match,
    pagination: listState.pagination,
    people: listState.items,
    rateLimits: listState.extras.rateLimits,
    searchActive: listState.search.isActive,
    selectedItems: listState.selectedItems,
    userGroupNames: listState.extras.userGroupNames,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const AddFinancialControllerPanelReduxConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddFinancialControllerPanel);

export default withFilters(AddFinancialControllerPanelReduxConnect);
