import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import AccountDropdown from 'accounts/assets/js/components/AccountDropdown.jsx';
import HelpDropdown from 'core/assets/js/components/HelpDropdown.jsx';
import CountdownTimer from 'core/assets/js/components/CountdownTimer.jsx';
import { ICON, LOGO, NAVBAR_SECTIONS } from 'core/assets/js/constants';
import { NotificationsDropdown } from 'notifier/assets/js';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { orgPlanStatusUrl } from 'organizations/urls';
import { selectTrialSubscription, selectIsOnTrial, selectIsTrialExpired } from 'organizations/assets/js/reducers/subscriptions';
import { billingViewUrl } from 'finance/urls';
import NavBarItem from 'core/assets/js/components/NavBar/NavBarItem.jsx';
import { BILLING_TABS } from 'finance/assets/js/constants';
import { isMenuItemActive } from 'core/assets/js/lib/utils';

/**
 * Navbar Footer Section
 */
const NavBarFooter = ({
  activeOrg,
  activeSection,
  isManager,
  isOnTrial,
  isTrialExpired,
  location,
  match,
  menuIsVisible,
  showNotifications,
  setActiveSection,
  showBilling,
  trialSubscription,
}) => {
  const showTrialCountDown = isManager && trialSubscription && isOnTrial && !isTrialExpired;

  const expiresAt = (
    !isTrialExpired && trialSubscription && (
      trialSubscription.trial_expiration_date
    )
  );

  const createdAt = !isTrialExpired && trialSubscription && (
    trialSubscription.started_at || trialSubscription.created_at
  );
  const timeBetweenTrial = new Date(expiresAt).getTime() - new Date(createdAt).getTime();
  const trialPeriod = Math.floor(timeBetweenTrial / (1000 * 60 * 60 * 24)) + 1;
  const planStatusIsActive = (location.pathname === orgPlanStatusUrl(activeOrg.alias));
  const billingSections = useMemo(() => ([
    billingViewUrl(activeOrg.alias, BILLING_TABS.OVERVIEW),
    billingViewUrl(activeOrg.alias, BILLING_TABS.BILLING_HISTORY),
    billingViewUrl(activeOrg.alias, BILLING_TABS.SUBSCRIPTION),
  ]), [activeOrg.alias]);
  return (
    <div className="navbar__footer">
      <div className="navbar__footer-section">
        { showTrialCountDown && (
          <div className="my-3">
            <Link
              className={`navbar__footer-plan-link ${planStatusIsActive ? 'navbar__footer-plan-link--active navbar__menuItem--state-open' : ''}`}
              to={orgPlanStatusUrl(activeOrg.alias)}
            >
              <CountdownTimer
                expiresAt={expiresAt}
                trialPeriod={trialPeriod}
                isActive={planStatusIsActive}
                isSmall
              />
              <span className="ml-3">Free trial in progress</span>
            </Link>
          </div>
        )}

        { showBilling && (
          <div className="my-3">
            <NavBarItem
              isSectionActive={isMenuItemActive(match, location, billingSections)}
              key={billingViewUrl(activeOrg.alias)}
              path={billingViewUrl(activeOrg.alias)}
              label="Billing"
              icon={ICON.WALLET}
              tabIndex={-1}
              sections={billingSections}
            />
          </div>
        ) }
        {activeSection !== NAVBAR_SECTIONS.SETTINGS && (
          <a
            className="d-flex navbar-footer-toggle my-2 pr-3"
            onClick={() => setActiveSection(NAVBAR_SECTIONS.SETTINGS)}
          >
            <div data-testid="nav-bar-item-settings" className="col px-0">
              <i className={`${ICON.COG}`} />
              Settings
            </div>
          </a>
        )}
        { showNotifications && <NotificationsDropdown menuIsVisible={menuIsVisible} /> }
        <HelpDropdown />
      </div>

      <AccountDropdown orgAlias={activeOrg.alias} />
      <div className="text-center mt-2">
        Powered by
        <img src={LOGO.DEFAULT} className="navbar-col__logo ml-2" alt="TalentDesk.io" />
      </div>
    </div>
  );
};
NavBarFooter.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeSection: PropTypes.string,
  isManager: PropTypes.bool,
  isOnTrial: PropTypes.bool.isRequired,
  isTrialExpired: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  menuIsVisible: PropTypes.bool.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  showNotifications: PropTypes.bool.isRequired,
  showBilling: PropTypes.bool.isRequired,
  trialSubscription: PropTypes.object,
};

NavBarFooter.defaultProps = {
  activeSection: null,
  isManager: false,
  isTrialExpired: false,
  trialSubscription: null,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = state => ({
  isManager: getHasOrgAccess(state)({ requireManager: true }),
  isOnTrial: selectIsOnTrial(state),
  isTrialExpired: selectIsTrialExpired(state),
  trialSubscription: selectTrialSubscription(state),
});

const NavBarFooterConnected = connect(mapStateToProps, mapDispatchToProps)(NavBarFooter);

export default withRouter(NavBarFooterConnected);
