import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pick } from 'lodash';

import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import ExpenseCancelModal from 'finance/assets/js/components/ExpenseCancelModal.jsx';
import ExpenseDetailsModal from 'finance/assets/js/components/ExpenseDetailsModal.jsx';
import ExpenseItemActions from 'finance/assets/js/components/ExpenseItemActions.jsx';
import ServiceOrderStatus from 'finance/assets/js/components/ServiceOrderStatus.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import { IMG_SIZE } from 'core/assets/js/constants';
import { INVOICE_TYPE } from 'finance/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { expandColumnComponent } from 'core/assets/js/lib/utils-jsx';
import { expenseSpec } from 'finance/assets/js/lib/objectSpecs';
import { financeExpenseViewUrl } from 'finance/urls';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { DOCUMENT_QUERY_SELECTOR_ALL } from 'core/assets/js/config/settings';

const EXPENSE_DETAILS_MODAL_ID = 'expense-details-modal';
const EXPENSE_CANCEL_MODAL_ID = 'expense-cancel-modal';

const ExpensesTable = ({
  dispatch,
  embeddedMode,
  hasOrgAccess,
  history,
  isExpenseView,
  isDetailsModalOpen,
  isCancelModalOpen,
  list,
  match: { params: { orgAlias } },
  detailsModalPayload,
  cancelModalPayload,
  onExpenseCancelled,
  rateUnit,
  ...rest
}) => {
  /**
   * prevent default react-bootstrap-table behaviour of expanding when clicking on the whole row
   * making sure that row will only expand when chevron icon is clicked.
   */
  const onTableRowClick = (e) => {
    const tRow = e && e.target && e.target.closest('tr');

    if (
      e && e.target
      && e.target.tagName !== 'I'
      && !e.target.classList.contains('td-drop-button__toggle')
      && tRow.classList.contains('clickable')
      && e.target.tagName !== 'IMG'
    ) {
      const objectId = [...tRow.classList]
        .find(c => c.startsWith('objectId-'))
        .replace('objectId-', '');

      history.push(
        financeExpenseViewUrl(orgAlias, objectId),
        history.location.pathname,
        '/projects/',
      );
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
      .forEach(trow => trow.addEventListener('click', onTableRowClick));
    return () => {
      DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
        .forEach(trow => trow.removeEventListener('click', onTableRowClick));
    };
  });

  const handleDetailsShow = expense => (
    dispatch(modalOpenAC(
      EXPENSE_DETAILS_MODAL_ID, pick(expense, ['summary', 'attachments'])),
    )
  );

  const handleCancelDialog = (e, expense) => {
    e.stopPropagation();
    dispatch(modalOpenAC(
      EXPENSE_CANCEL_MODAL_ID, pick(expense, ['id', 'projectId']),
    ));
  };

  const handleModalClose = () => dispatch(modalCloseAC());

  const statusColumnSpec = {
    key: 'statusCode',
    label: 'Status',
    width: '180px',
    dataFormat: (statusCode, row) => <ServiceOrderStatus serviceOrder={row} />,
  };

  const amountColumnSpec = {
    key: 'amount',
    label: 'Amount',
    columnClassName: 'text-right',
    width: '160px',
    isMoney: true,
  };

  const expenseViewColumns = [
    {
      key: 'managerName',
      label: 'Name',
      dataFormat: (cell, expense) => (
        <div className="d-flex align-items-center">
          <ProfilePic
            className="mr-3"
            url={expense.avatar}
            alt={expense.providerName}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
          />
          {expense.providerName}
        </div>
      ),
    },
    { key: 'projectDescription', label: 'Project' },
    { key: 'createdAt', label: 'Date', width: '120px', isDate: true },
    amountColumnSpec,
  ];

  if (!isExpenseView) {
    expenseViewColumns.push(statusColumnSpec);
    expenseViewColumns.push({
      key: 'actions', width: '80px', label: '',
      dataAlign: 'right', columnClassName: 'actions-cell',
    });
  }

  const embeddedViewColumns = [
    {
      key: 'managerName',
      label: 'Name',
      dataFormat: (cell, expense) => (
        <React.Fragment>
          <ProfilePic
            className="mr-3"
            url={expense.avatar}
            alt={expense.providerName}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
          />
          {expense.providerName}
        </React.Fragment>
      ),
    },
    {
      key: 'summary',
      label: 'Description',
      dataFormat: (cell, expense) => (
        <div className="truncate">
          {expense.summary}
        </div>
      ),
    },
    { key: 'createdAt', label: 'Date', width: '120px', isDate: true },
    amountColumnSpec,
    statusColumnSpec,
    {
      key: 'actions', width: '80px', label: '',
      dataAlign: 'right', columnClassName: 'actions-cell',
    },
  ];

  const parsedList = list.map(expense => ({
    ...expense,
    amount: (
      <>
        <NumberTpl
          value={expense.expenseAmount}
          decimals={2}
          prefix={expense.expenseCurrencySymbol}
        />
        {expense.organizationCurrency !== expense.expenseCurrency && (
          <ExchangeRateInfoPopOver
            className="ml-2"
            exchangeRate={expense.exchangeRate}
            exchangeRateDate={expense.exchangeRateUpdatedAt}
            exchangeRateService={expense.expenseToOrganizationFxRateSource}
            sourceCurrency={expense.expenseCurrency}
            targetAmount={expense.organizationAmount}
            targetCurrency={expense.organizationCurrency}
          />
        )}
      </>
    ),
    projectDescription: `${expense.projectTitle} (${expense.projectReference})`,
    invoiceNumbers: (expense.invoices || []).filter(i => (
      // get all provider invoices
      i && [INVOICE_TYPE.INBOUND, INVOICE_TYPE.DIRECT].includes(i.type)
    )).map(i => i.number).join(', '),
    actions: (
      <ExpenseItemActions
        history={history}
        expense={expense}
        orgAlias={orgAlias}
        onCancel={e => handleCancelDialog(e, expense)}
      />
    ),
  }));

  let tableOptions = {
    onRowClick: onTableRowClick,
    expandableRow: () => true,
    expandComponent: opts => (
      <AttachmentsList
        attachments={opts.attachments}
        label="Expense Attachments"
      />
    ),
    expandColumnOptions: {
      expandColumnVisible: true,
      expandColumnComponent,
      columnWidth: '30px',
    },
  };

  if (isExpenseView) {
    tableOptions = {
      onRowClick: handleDetailsShow,
    };
  }

  // different columns/format per view (Finance / Project)
  let tableColumns = {};
  if (embeddedMode) {
    tableColumns = embeddedViewColumns;
  } else {
    tableColumns = expenseViewColumns;
  }

  return (
    <React.Fragment>
      <Table
        cols={tableColumns}
        items={parsedList}
        {...rest}
        {...tableOptions}
        isLarge={!embeddedMode}
        trClassName={(row) => { return `objectId-${row && row.id}`; }}
      />

      { isDetailsModalOpen && detailsModalPayload && (
        <ExpenseDetailsModal
          onClose={handleModalClose}
          summary={detailsModalPayload.summary}
          attachments={detailsModalPayload.attachments}
          isOpen
        />
      )}

      { isCancelModalOpen && cancelModalPayload && (
        <ExpenseCancelModal
          onClose={handleModalClose}
          expenseId={cancelModalPayload.id}
          projectId={cancelModalPayload.projectId}
          onExpenseCancelled={onExpenseCancelled}
          orgAlias={orgAlias}
          isOpen
        />
      )}
    </React.Fragment>
  );
};

ExpensesTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  embeddedMode: PropTypes.bool,
  hasOrgAccess: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isExpenseView: PropTypes.bool,
  isDetailsModalOpen: PropTypes.bool,
  isCancelModalOpen: PropTypes.bool,
  list: PropTypes.arrayOf(expenseSpec),
  match: routerMatchSpec.isRequired,
  detailsModalPayload: PropTypes.object,
  cancelModalPayload: PropTypes.object,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  onExpenseCancelled: PropTypes.func,
};

ExpensesTable.defaultProps = {
  embeddedMode: false,
  isExpenseView: false,
  isDetailsModalOpen: false,
  isCancelModalOpen: false,
  list: [],
  detailsModalPayload: null,
  cancelModalPayload: null,
  rateUnit: RATE_UNIT.PER_HOUR,
  onExpenseCancelled: () => {},
};

const mapStateToProps = state => ({
  hasOrgAccess: getHasOrgAccess(state),
  isDetailsModalOpen: getIsModalOpen(state, EXPENSE_DETAILS_MODAL_ID),
  isCancelModalOpen: getIsModalOpen(state, EXPENSE_CANCEL_MODAL_ID),
  detailsModalPayload: getModalPayload(state, EXPENSE_DETAILS_MODAL_ID),
  cancelModalPayload: getModalPayload(state, EXPENSE_CANCEL_MODAL_ID),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ExpensesTableConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpensesTable);

export default withRouter(ExpensesTableConnected);
