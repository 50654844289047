import React from 'react';
import { connect } from 'react-redux';

import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { Card } from 'react-bootstrap';
import { IMG_SIZE } from 'core/assets/js/constants';
import { NavLink } from 'react-router-dom';
import { invitationManageUrl } from 'invitations/urls';
import { orgProgressSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  STATUS as INVITATION_STATUS,
  STATUS_CLASS as INVITATION_STATUS_CLASS,
  STATUS_LABEL as INVITATION_STATUS_LABEL,
} from 'invitations/assets/js/constants';

const OrgInvitationCardItem = ({ item }) => {
  if (!item) {
    return null;
  }
  const {
    token, orgWebsite, orgAlias, orgLogo, orgName, message, status, sentOn, sentByUserName,
  } = item;

  return (
    <Card
      className={`organization-list-item card--${INVITATION_STATUS_CLASS[status]} shadow-none`}
    >
      <Card.Body>
        <div className="row">
          <div className="col-8 col-sm-7 col-md-6 col-xl-5">
            <OrganizationLogo
              className="organization-list-item__logo"
              url={orgLogo}
              orgName={orgName}
              size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
            />

            <span className="organization-list-item__title font-weight-bolder">
              {orgName}
            </span>

            <div className="organization-list-item__meta">
              {orgWebsite && (
                <span>
                  {orgWebsite}
                </span>
              )}
            </div>
          </div>

          <div className="d-none d-md-flex col-2 px-0 align-items-center align-content-center">
            <StatusTag
              className="mx-auto"
              statusClass={INVITATION_STATUS_CLASS[INVITATION_STATUS.PENDING]}
              label={INVITATION_STATUS_LABEL[status]}
            />
          </div>

          <div className="pl-0 col-4 col-sm-5 col-md-4 ml-auto justify-content-end align-items-center d-flex text-right">
            <NavLink to={invitationManageUrl(token, orgAlias)}>
              Manage invitation
            </NavLink>
          </div>
        </div>

        <hr className="my-4" />

        {message && (
          <>
            <strong className="mt-5">Invitation message:</strong>
            <p className="mt-2 mb-4">{message}</p>
          </>
        )}

        <span className="discreet">Sent on: </span>
        {sentOn}
        <span className="discreet"> by </span>
        {sentByUserName}
      </Card.Body>
    </Card>
  );
};

OrgInvitationCardItem.propTypes = {
  item: orgProgressSpec,
};

OrgInvitationCardItem.defaultProps = {
  item: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(undefined, mapDispatchToProps)(OrgInvitationCardItem);

