import React from 'react';
import { toastr } from 'react-redux-toastr';
import { Button } from 'react-bootstrap';

function ToastrSection() {
  return (
    <section id="toastr">
      <h2>Toastr Notification Messages</h2>
      <div className="row">
        <div className="col-6 col-sm-3 text-center mt-3">
          <Button
            variant="success"
            onClick={() => {
              toastr.success('Well Done!', 'This is the message shown in the toastr.');
            }}
          >
            Show Success
          </Button>
        </div>
        <div className="col-6 col-sm-3 text-center mt-3">
          <Button
            variant="danger"
            onClick={() => {
              toastr.error('Oh Snap!', 'This is the message shown in the toastr.');
            }}
          >
            Show Error
          </Button>
        </div>
        <div className="col-6 col-sm-3 text-center mt-3">
          <Button
            variant="warning"
            onClick={() => {
              toastr.warning('Warning!', 'This is the message shown in the toastr.');
            }}
          >
            Show Warning
          </Button>
        </div>
        <div className="col-6 col-sm-3 text-center mt-3">
          <Button
            variant="info"
            onClick={() => {
              toastr.info('Heads Up!', 'This is the message shown in the toastr.');
            }}
          >
            Show Info
          </Button>
        </div>
      </div>
      <div className="discreet text-center mt-3">Click the buttons to see the toastr notification messages</div>
    </section>
  );
}

export default ToastrSection;
