import React from 'react';
import { isEmpty } from 'lodash';
import { financeEntityScheme } from 'finance/assets/js/lib/objectSpecs';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';

const InvoiceDENoIdentificationOfSalesTax = ({ owner, recipient }) => {
  if (!owner || !recipient || isEmpty(owner) || isEmpty(recipient)) {
    return null;
  }
  const ownerFE = new FinancialEntity(owner);
  const recipientFE = new FinancialEntity(recipient);
  const ownerVatPercent = ownerFE.getVatPercent();

  const shouldShowText = (
    ownerFE.isInGermany()
    && recipientFE.isInGermany()
    && !ownerFE.isVatRegistered()
    && (!ownerVatPercent || ownerVatPercent === 0)
  );

  if (!shouldShowText) {
    return null;
  }

  return (
    <div data-testid="invoice-de-no-identification-of-sales-tax">
      <p>
        No identification of sales tax, since small business owners according to §19 UstG.
      </p>
    </div>
  );
};

InvoiceDENoIdentificationOfSalesTax.propTypes = {
  owner: financeEntityScheme,
  recipient: financeEntityScheme,
};

InvoiceDENoIdentificationOfSalesTax.defaultProps = {
  owner: null,
  recipient: null,
};

export default InvoiceDENoIdentificationOfSalesTax;
