import { LAYOUTS } from 'core/assets/js/constants';

// Views
import AccountSettingsView from 'accounts/assets/js/AccountSettingsView.jsx';
import GeneralSettingsView from 'settings/assets/js/GeneralSettingsView.jsx';
import ProfileBankAccountsList from 'settings/assets/js/ProfileBankAccountsList.jsx';
import OrgManageTemplatesView from 'settings/assets/js/OrgManageTemplatesView.jsx';
import InvitationTemplateCreateView from 'settings/assets/js/InvitationTemplateCreateView.jsx';
import InvitationTemplateEditView from 'settings/assets/js/InvitationTemplateEditView.jsx';
import CustomFieldsTemplateSelectTypeToCreateView from 'settings/assets/js/CustomFieldsTemplateSelectTypeToCreateView.jsx';
import CustomFieldsTemplateCreateView from 'settings/assets/js/CustomFieldsTemplateCreateView.jsx';
import CustomFieldsTemplateEditView from 'settings/assets/js/CustomFieldsTemplateEditView.jsx';
import FinControllerView from 'settings/assets/js/FinControllerView.jsx';
import OrganizationEditView from 'settings/assets/js/OrganizationEditView.jsx';
import NotificationsEditView from 'settings/assets/js/NotificationsEditView.jsx';
import RatesView from 'rates/assets/js/RatesView.jsx';
import RatesCreateView from 'rates/assets/js/RatesCreateView.jsx';
import RatesEditView from 'rates/assets/js/RatesEditView.jsx';
import OrgSettingsFinanceView from 'settings/assets/js/OrgSettingsFinanceView.jsx';
import OrgSettingsTaxView from 'settings/assets/js/OrgSettingsTaxView.jsx';
import PrivacySettingsView from 'settings/assets/js/PrivacySettingsView.jsx';
import ProjectsSettingsView from 'settings/assets/js/ProjectsSettingsView.jsx';
import OrgManageSkillsView from 'settings/assets/js/OrgManageSkillsView.jsx';
import OrgSettingsEmailTemplateView from 'settings/assets/js/OrgSettingsEmailTemplateView.jsx';
import OrgSettingsEmailTemplatesView from 'settings/assets/js/OrgSettingsEmailTemplatesView.jsx';
import ProfileManageBankAccount from 'settings/assets/js/ProfileManageBankAccount.jsx';
import ProfileManagePaypal from 'settings/assets/js/ProfileManagePaypal.jsx';
import ProfileManagePayoneer from 'settings/assets/js/ProfileManagePayoneer.jsx';
import ProfileManagePayoneerSuccessView from 'settings/assets/js/ProfileManagePayoneerSuccessView.jsx';
import ProfileManagePayoneerStatusView from 'settings/assets/js/ProfileManagePayoneerStatusView.jsx';
import ProfileManagePayoneerCancelView from 'settings/assets/js/ProfileManagePayoneerCancelView.jsx';
import OrgSettingsPaymentsView from 'settings/assets/js/OrgSettingsPaymentsView.jsx';
import CreatePaymentMethodSelectView from 'settings/assets/js/CreatePaymentMethodSelectView.jsx';
import { urls as rateUrls } from 'rates/assets/js';
import { PERMISSIONS } from 'roles/assets/js/constants';
import {
  customFieldTemplateCreateUrl,
  customFieldTemplateEditUrl,
  customFieldTemplateTypeSelectUrl,
  notificationSettingsUrl,
  orgInvitationTemplateCreateUrl,
  orgInvitationTemplateEditUrl,
  orgSettingsApiKeysManageUrl,
  orgSettingsEmailTemplateUrl,
  orgSettingsEmailTemplatesUrl,
  orgSettingsFinanceUrl,
  orgSettingsFinControllerUrl,
  orgSettingsGeneralUrl,
  orgSettingsPaymentsUrl,
  orgSettingsPrivacyUrl,
  orgSettingsProjectsUrl,
  orgSettingsSkillDirectoryManageUrl,
  orgSettingsSkillsUrl,
  orgSettingsTaxUrl,
  orgSettingsUrl,
  orgTemplatesManageUrl,
  settingsAccountUrl,
  settingsBankAccountCreateUrl,
  settingsBankAccountOrgSelectUrl,
  settingsBankAccountEditUrl,
  settingsPaymentMethodCreateSelectUrl,
  settingsPaymentsSubPageUrl,
  settingsPayoneerCreateUrl,
  settingsPayoneerEditUrl,
  settingsPaypalCreateUrl,
  settingsPaypalEditUrl,
  settingsPayoneerSuccessUrl,
  settingsPayoneerStatusUrl,
  settingsPayoneerCancelUrl,
} from 'settings/urls';

import { FIELD_ENTITY_TYPE_TO_URL_PARAM } from 'interviews/assets/js/constants';
import {
  ORG_SETTINGS_PAYMENTS_TABS,
  SETTINGS_PAYMENTS_SUB_PAGES,
  SETTINGS_TEMPLATE_TABS,
  ORG_SETTINGS_FINANCE_TABS,
  SETTINGS_SKILL_TABS,
} from 'settings/assets/js/constants';
import OrgManageApiKeysView from 'settings/assets/js/OrgManageApiKeysView.jsx';
import OrgSkillDirectoryManageView from 'settings/assets/js/OrgSkillDirectoryManageView.jsx';
import PaymentMethodOrgListSelection from 'settings/assets/js/components/PaymentMethodOrgListSelection.jsx';

const { orgSettingsRatesUrl, orgSettingsRatesCreateUrl, orgSettingsRatesEditUrl } = rateUrls;

const settingsRouteConfig = [
  {
    path: settingsAccountUrl(':orgAlias'),
    requireAuth: true,
    component: AccountSettingsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPaypalCreateUrl(':orgAlias'),
    requireAuth: true,
    component: ProfileManagePaypal,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPaypalEditUrl(':orgAlias', ':bankAccountId'),
    requireAuth: true,
    component: ProfileManagePaypal,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPayoneerCreateUrl(':orgAlias'),
    requireAuth: true,
    component: ProfileManagePayoneer,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPayoneerEditUrl(':orgAlias', ':bankAccountId'),
    requireAuth: true,
    component: ProfileManagePayoneer,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPayoneerStatusUrl(':orgAlias', ':bankAccountId'),
    requireAuth: true,
    component: ProfileManagePayoneerStatusView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPayoneerSuccessUrl(':orgAlias', ':bankAccountId'),
    requireAuth: true,
    requireUserIsApprovedOrgMember: false,
    component: ProfileManagePayoneerSuccessView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPayoneerCancelUrl(':orgAlias', ':bankAccountId'),
    requireAuth: true,
    requireUserIsApprovedOrgMember: false,
    component: ProfileManagePayoneerCancelView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsBankAccountOrgSelectUrl(':orgAlias', ':paymentMethodType', ':bankAccountId'),
    requireAuth: true,
    component: PaymentMethodOrgListSelection,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsBankAccountCreateUrl(':orgAlias'),
    requireAuth: true,
    component: ProfileManageBankAccount,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPaymentMethodCreateSelectUrl(':orgAlias'),
    requireAuth: true,
    component: CreatePaymentMethodSelectView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsBankAccountEditUrl(':orgAlias', ':bankAccountId'),
    requireAuth: true,
    component: ProfileManageBankAccount,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: orgSettingsPaymentsUrl(':orgAlias', `:tab(${Object.values(ORG_SETTINGS_PAYMENTS_TABS).join('|')})`),
    requireAuth: true,
    component: OrgSettingsPaymentsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: settingsPaymentsSubPageUrl(
      ':orgAlias', `:tab(${Object.values(SETTINGS_PAYMENTS_SUB_PAGES).join('|')})`,
    ),
    requireAuth: true,
    component: ProfileBankAccountsList,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: orgSettingsUrl(':orgAlias'),
    component: OrganizationEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    component: GeneralSettingsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsGeneralUrl(':orgAlias'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: NotificationsEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: notificationSettingsUrl(':orgAlias'),
  },
  {
    component: FinControllerView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsFinControllerUrl(':orgAlias'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: OrgSettingsFinanceView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsFinanceUrl(
      ':orgAlias', `:tab(${Object.values(ORG_SETTINGS_FINANCE_TABS).join('|')})`,
    ),
    requirePermission: PERMISSIONS.CAN_MANAGE_PAYMENTS_SETTINGS,
  },
  {
    component: PrivacySettingsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsPrivacyUrl(':orgAlias'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: ProjectsSettingsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsProjectsUrl(':orgAlias', ':tab'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: OrgManageSkillsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsSkillsUrl(
      ':orgAlias', `:tab(${Object.values(SETTINGS_SKILL_TABS).join('|')})`,
    ),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    path: orgSettingsSkillDirectoryManageUrl(':orgAlias', ':skillDirectoryId'),
    requireManager: true,
    component: OrgSkillDirectoryManageView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  // Rates
  {
    path: orgSettingsRatesUrl(':orgAlias'),
    component: RatesView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireFinController: true,
    requireManager: true,
    requireProvider: true,
  },
  {
    path: orgSettingsRatesCreateUrl(':orgAlias'),
    component: RatesCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireFinController: true,
    requireManager: true,
    requireProvider: true,
  },
  {
    path: orgSettingsRatesEditUrl(':orgAlias', ':rateId'),
    component: RatesEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireFinController: true,
    requireManager: true,
    requireProvider: true,
  },

  {
    path: orgTemplatesManageUrl(':orgAlias', `:tab(${Object.values(SETTINGS_TEMPLATE_TABS).join('|')})`),
    requireAuth: true,
    requireManager: true,
    component: OrgManageTemplatesView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: orgInvitationTemplateCreateUrl(':orgAlias'),
    component: InvitationTemplateCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: orgInvitationTemplateEditUrl(),
    component: InvitationTemplateEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: customFieldTemplateTypeSelectUrl(':orgAlias'),
    component: CustomFieldsTemplateSelectTypeToCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: customFieldTemplateCreateUrl(':orgAlias', `:entityType(${Object.values(FIELD_ENTITY_TYPE_TO_URL_PARAM).join('|')})`),
    component: CustomFieldsTemplateCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: customFieldTemplateEditUrl(':orgAlias', `:entityType(${Object.values(FIELD_ENTITY_TYPE_TO_URL_PARAM).join('|')})`, ':templateId'),
    component: CustomFieldsTemplateEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    component: OrgManageApiKeysView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsApiKeysManageUrl(':orgAlias'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: OrgSettingsTaxView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsTaxUrl(':orgAlias'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: OrgSettingsEmailTemplatesView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsEmailTemplatesUrl(':orgAlias'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: OrgSettingsEmailTemplateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgSettingsEmailTemplateUrl(':orgAlias', ':type'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
];

export default settingsRouteConfig;
