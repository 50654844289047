import PropTypes from 'prop-types';
import React from 'react';

import DateRangePickerField from 'core/assets/js/components/FinalFormFields/DateRangePickerField.jsx';

const DateRangePickerFilterField = ({ filter: { label }, ...rest }) => {
  return (
    <>
      <h4>{label}</h4>
      <DateRangePickerField {...rest} />
    </>
  );
};

DateRangePickerFilterField.propTypes = {
  filter: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
};

export default DateRangePickerFilterField;
