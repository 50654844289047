import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import SearchSelectField from 'core/assets/js/components/FinalFormFields/SearchSelectField.jsx';
import TDButtonGroup from 'core/assets/js/components/TDButtonGroup.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { SKILL_IDS_MATCH } from 'skills/assets/js/constants';
import { getSkillsByIdsApiUrl, skillSearchApiUrl } from 'skills/urls';

const SkillsAndOrSelectField = ({ label, name, required, sublabel }) => {
  const { submitErrors, values } = useFormState();
  const { change } = useForm();
  const activeOrg = useSelector(selectActiveOrg);

  const matchName = `${name}Match`;

  return (
    <SearchSelectField
      extraContentBefore={(
        <>
          <div className="d-flex flex-row align-items-center">
            <span>Matches</span>
            <TDButtonGroup
              className="mx-3"
              primaryButtonActive={values[matchName] === SKILL_IDS_MATCH.OR}
              primaryButton="ANY"
              primaryButtonAction={() => change(matchName, SKILL_IDS_MATCH.OR)}
              secondaryButton="ALL"
              secondaryButtonActive={values[matchName] === SKILL_IDS_MATCH.AND}
              secondaryButtonAction={() => change(matchName, SKILL_IDS_MATCH.AND)}
            />
            <span>of these skills:</span>
          </div>
          {submitErrors?.[matchName] && (
            <div className="text-danger my-2">{submitErrors[matchName]}</div>
          )}
        </>
      )}
      getOptionsByIds={async skillIds => {
        const { data } = await axios.post(getSkillsByIdsApiUrl(activeOrg.alias), { skillIds });
        return data.map(entry => ({ label: entry.label, value: entry.id }));
      }}
      label={label}
      loadOptions={async term => {
        const { data } = await axios.get(skillSearchApiUrl({
          defaultSkillsEnabled: true, orgAlias: activeOrg.alias, paginationEnabled: false, term,
        }));
        return data.map(skill => ({ label: skill.label, value: skill.id }));
      }}
      name={name}
      placeholder="Search skills"
      required={required}
      sublabel={sublabel}
    />
  );
};

SkillsAndOrSelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
};

SkillsAndOrSelectField.defaultProps = {
  label: null,
  required: false,
  sublabel: null,
};

export default SkillsAndOrSelectField;
