import React from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';

import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import SelectableItem from 'core/assets/js/components/SelectableItem.jsx';

/**
 * Component is used in various cases when we want to show a list of components
 *
 * @param {Object} cardItem
 * @param {String} emptyListClassName - optional class name for the empty message wrapper div
 * @param {String|Node} emptyListMessage - the message t be displayed when list is empty
 * @param {Boolean} groupByFirstLetter - Group items by first letter
 * @param {Boolean} hoverable - show UI interactivity on list item hovering
 * @param {Array} items - the list of items that we want to show
 * @param {String} listClassName - class name for wrapper ul element
 * @param {String} listName
 * @param {Function} onPageChange
 * @param {Object} pagination
 * @param {Boolean} selectOne
 * @param {Boolean} selectable - if the list is selectable
 * @returns {*}
 * @constructor
 */
function TDList({
  cardItem,
  emptyListClassName,
  emptyListMessage,
  groupByFirstLetter,
  hoverable,
  items,
  listClassName,
  listName,
  onPageChange,
  pagination,
  selectOne,
  selectable,
}) {
  const listClassNames = ['td-list', listClassName];
  const emptyListClassNames = ['empty-list-message text-center py-4'];
  if (hoverable) {
    listClassNames.push('td-list--hoverable');
  }
  if (emptyListClassName) {
    emptyListClassNames.push(emptyListClassName);
  }
  let list = [];

  const itemsLength = items.length;

  if (!itemsLength) {
    list.push(
      <div
        className={emptyListClassNames.join(' ')}
        key="-1"
      >
        {emptyListMessage}
      </div>,
    );
  } else {
    list = items.map((item, idx) => {
      const isLastItem = idx === (itemsLength - 1);
      const extraItemData = { isLastItem, itemsLength };

      if (selectable) {
        return (
          <SelectableItem
            key={`item-${item.id || idx}`}
            listName={listName}
            cardItem={cardItem}
            itemData={{ ...item, ...extraItemData }}
            selectOne={selectOne}
          />
        );
      }

      const { component: Component } = cardItem;
      if (React.isValidElement(Component)) {
        return Component;
      }

      return React.createElement(Component, {
        key: `item-${item.id || idx}`,
        item,
        ...cardItem.props,
        ...extraItemData,
      });
    });

    if (groupByFirstLetter) {
      const groups = groupBy(
        items,
        (item) => item?.label?.toLowerCase()[0] || item?.name?.toLowerCase()[0],
      );
      list = Object.keys(groups)?.sort().map((group) => {
        const { component: Component } = cardItem;

        if (React.isValidElement(Component)) {
          return Component;
        }

        const letterCount = groups[group].length;
        return (
          <div key={group}>
            <h3 className="mb-3 text-uppercase">
              {group}
              <span className="ml-2 discreet font-weight-normal">
                {`(${letterCount})`}
              </span>
            </h3>

            <div className="px-0">
              <div className="skill-list">
                {groups[group]?.map((item, idx) => {
                  const isLastItem = idx === (itemsLength - 1);
                  const extraItemData = { isLastItem, itemsLength };
                  return React.createElement(Component, {
                    key: `item-${item.id || idx}`,
                    skill: item,
                    ...cardItem.props,
                    ...extraItemData,
                  });
                })}
              </div>
            </div>
          </div>
        );
      });
    }
  }

  return (
    <React.Fragment>
      <ul className={listClassNames.join(' ')}>{list}</ul>
      { pagination && (
        <TDPagination {...pagination} onPageChange={onPageChange} />
      )}
    </React.Fragment>
  );
}

TDList.propTypes = {
  cardItem: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    props: PropTypes.object,
  }).isRequired,
  groupByFirstLetter: PropTypes.bool,
  emptyListClassName: PropTypes.string,
  emptyListMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hoverable: PropTypes.bool,
  items: PropTypes.array.isRequired,
  listClassName: PropTypes.string,
  listName: PropTypes.string,
  onPageChange: PropTypes.func,
  pagination: paginationSpec,
  selectOne: PropTypes.bool,
  selectable: PropTypes.bool,
};
TDList.defaultProps = {
  emptyListClassName: null,
  groupByFirstLetter: false,
  emptyListMessage: 'There aren\'t any items to show.',
  hoverable: false,
  listClassName: '',
  listName: '',
  onPageChange: null,
  pagination: null,
  selectOne: false,
  selectable: false,
};

export default TDList;
