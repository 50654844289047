import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BS_STYLE } from 'core/assets/js/constants';
import {
  getIsModalOpen, getModalPayload, modalOpenAC, modalCloseAC,
} from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { downloadFileApiUrl } from 'files/urls';
import {
  US_TAX_FORM_STATUS_CLASS, US_TAX_FORM_STATUS_LABEL, US_TAX_FORM_TYPE_LABEL,
} from 'finance/assets/js/constants';
import { financeDeleteUSTaxFormApiUrl } from 'finance/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const REJECTED_REASON_MODAL_ID = 'user-us-tax-form-rejected-reason-modal-id';
const REMOVE_MODAL_ID = 'user-us-tax-form-remove-modal-id';

const UserUSTaxFormsList = ({ reload, usTaxForms }) => {
  const rejectedReasonModalIsOpen = useSelector(
    state => getIsModalOpen(state, REJECTED_REASON_MODAL_ID),
  );
  const rejectedReasonModalPayload = useSelector(
    state => getModalPayload(state, REJECTED_REASON_MODAL_ID),
  );
  const removeModalIsOpen = useSelector(state => getIsModalOpen(state, REMOVE_MODAL_ID));
  const removeModalPayload = useSelector(state => getModalPayload(state, REMOVE_MODAL_ID));
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);

  if (!Array.isArray(usTaxForms) || usTaxForms.length === 0) {
    return null;
  }
  return (
    <>
      <Table
        cols={[
          { dataFormat: type => US_TAX_FORM_TYPE_LABEL[type], key: 'type', label: 'Form' },
          { key: 'createdAt', label: 'Uploaded' },
          {
            dataFormat: status => (
              <StatusTag
                label={US_TAX_FORM_STATUS_LABEL[status]}
                statusClass={US_TAX_FORM_STATUS_CLASS[status]}
              />
            ),
            key: 'status',
            label: 'Status',
          },
          {
            dataAlign: 'right',
            dataFormat: (fileId, userUsTaxForm) => {
              const actions = [{
                label: 'Download', onClick: () => WINDOW_OPEN(downloadFileApiUrl(fileId)),
              }];
              if (userUsTaxForm.rejectedReason) {
                actions.push({
                  label: 'Rejected reason',
                  onClick: () => dispatch(modalOpenAC(REJECTED_REASON_MODAL_ID, { userUsTaxForm })),
                });
              }
              if (userUsTaxForm.allowedActions?.canRemove) {
                actions.push({
                  label: 'Remove',
                  onClick: () => dispatch(modalOpenAC(REMOVE_MODAL_ID, { userUsTaxForm })),
                });
              }
              return <SplitButton actions={actions} primaryButtonVariant={BS_STYLE.DEFAULT} />;
            },
            key: 'fileId',
            label: '',
          },
        ]}
        containerClass="user-us-tax-forms-list"
        items={usTaxForms}
      />
      <ModalSimple
        body={rejectedReasonModalPayload?.userUsTaxForm?.rejectedReason}
        heading="Rejected reason"
        onClose={() => dispatch(modalCloseAC())}
        open={rejectedReasonModalIsOpen}
      />
      <ModalConfirm
        body={(
          !removeModalPayload?.userUsTaxForm ? '' : [
            'Are you sure you want to remove this ',
            `${US_TAX_FORM_TYPE_LABEL[removeModalPayload.userUsTaxForm.type]} form?`,
          ].join('')
        )}
        confirmLabel="Remove"
        heading="Remove"
        onClose={() => dispatch(modalCloseAC())}
        onConfirm={async () => {
          try {
            await axios.delete(financeDeleteUSTaxFormApiUrl(
              activeOrg.alias, removeModalPayload.userUsTaxForm.id,
            ));
            toastr.success(
              'Well Done!',
              [
                'You have removed that ',
                `${US_TAX_FORM_TYPE_LABEL[removeModalPayload.userUsTaxForm.type]} form`,
              ].join(''),
            );
            reload();
          } catch (e) {
            toastr.error('Oh Snap!', e.response?.data?._error || e.message);
          }
        }}
        open={removeModalIsOpen}
      />
    </>
  );
};

UserUSTaxFormsList.propTypes = {
  reload: PropTypes.func.isRequired,
  usTaxForms: PropTypes.arrayOf(PropTypes.object),
};

UserUSTaxFormsList.defaultProps = {
  usTaxForms: [],
};

export default UserUSTaxFormsList;
