import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { isEmpty, pick } from 'lodash';
import { withRouter } from 'react-router-dom';

import SignupForm from 'accounts/assets/js/components/SignupForm.jsx';
import { selectAuthenticated } from 'accounts/assets/js/reducers/auth';
import { ACCEPTED_UTM_KEYS } from 'core/assets/js/constants';
import { logoutUrl, signupVerifyUrl } from 'accounts/urls';
import { getViewState } from 'core/assets/js/ducks/view';
import { selectActiveInvitation } from 'invitations/assets/js/ducks/invitation';
import { orgPublicSpec } from 'organizations/assets/js/lib/objectSpecs';
import { WINDOW_REDIRECT, WINDOW_HREF, WINDOW_ORIGIN } from 'core/assets/js/config/settings';

class SignupCleanView extends React.Component {
  static GetComponentName() {
    return 'SignupCleanView';
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      // don't use signupCleanUrl(), incase there's a next query param in the url
      WINDOW_REDIRECT(logoutUrl(WINDOW_HREF.replace(WINDOW_ORIGIN, '')));
    }
  }

  render() {
    const { location, organization } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const nextUrl = parsedQueryString.next || signupVerifyUrl();

    const initialValues = {};
    const utmData = pick(parsedQueryString, ACCEPTED_UTM_KEYS);
    if (!isEmpty(utmData)) {
      initialValues.utm_metadata = utmData;
    }

    return (
      <SignupForm
        initialValues={initialValues}
        next={nextUrl}
        organization={organization}
      />
    );
  }
}

SignupCleanView.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  organization: orgPublicSpec,
};

SignupCleanView.defaultProps = {
  organization: {},
};

const mapStateToProps = (state) => {
  const invitationState = selectActiveInvitation(state);
  return {
    invitation: invitationState.item,
    isAuthenticated: selectAuthenticated(state),
    isValid: invitationState.isValid,
    organization: getViewState(state, SignupCleanView.GetComponentName()).item,
  };
};

export default withRouter(connect(mapStateToProps)(SignupCleanView));
