import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import IntegrationStatusBadge from 'integrations/assets/js/components/IntegrationStatusBadge.jsx';
import IntegrationSwitch from 'integrations/assets/js/components/IntegrationSwitch.jsx';
import { INTEGRATION_STATUS } from 'integrations/assets/js/constants';
import { syncIntegrationStatus } from 'integrations/assets/js/data-services/list';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const IntegrationCta = ({
  dispatch, integration, match: { params: { orgAlias } }, onToggle, onSync,
}) => {
  if (integration?.status === INTEGRATION_STATUS.INITIATED) {
    return null;
  }

  const refreshData = async () => {
    try {
      await dispatch(syncIntegrationStatus({ integrationId: integration.id, orgAlias }));
      toastr.success(
        'Well Done!', 'Refreshing integration\'s data started, this may take some time',
      );
    } catch (err) {
      toastr.error('Oh Snap!', err._error || err.message);
    }
  };

  if (integration?.status === INTEGRATION_STATUS.ACTIVE && !integration.setupCompleted) {
    return (
      <TDButton
        btnIcon={ICON.ARROWS_ROTATE}
        label="Refresh data"
        onClick={refreshData}
        variant={BS_STYLE.DEFAULT}
      />
    );
  }

  const isToggleEnabled = (
    integration.allowedActions?.canBeDisabled || integration.allowedActions?.canBeEnabled
  );

  return (
    <div className="row align-items-center">
      <IntegrationStatusBadge status={integration.status} />
      <div className="ml-5">
        {isToggleEnabled && (
          <IntegrationSwitch
            containerClassName="mr-3"
            integrationId={integration.id}
            onToggle={onToggle}
            isEnabled={integration.isActive}
          />
        )}
      </div>
      <div className="mx-5 d-flex flex-column integration-details-header-last-sync">
        <div>Last sync:</div>
        <div>{integration.lastSync || 'None'}</div>
      </div>
      <Dropdown as={ButtonGroup} className="td-dropdown d-none d-md-inline-block">
        <TDButton label="Run sync" onClick={onSync} variant={BS_STYLE.PRIMARY} />
        <Dropdown.Toggle split variant={BS_STYLE.PRIMARY}>
          <span className={ICON.CHEVRON_DOWN} />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          <Dropdown.Item onClick={refreshData}>Refresh data</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

IntegrationCta.propTypes = {
  dispatch: PropTypes.func.isRequired,
  integration: integrationSpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onSync: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationCtaConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationCta);

export default withRouter(IntegrationCtaConnected);
