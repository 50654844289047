import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const BillableAnalyticsMeta = ({ onOpenModal, currencySymbol, totalAmount, total, showTotal }) => (
  !!totalAmount && (
    <span>
      {showTotal && `Showing ${total} entries `}
      of
      {' '}
      {
        <a className="text-primary" onClick={onOpenModal}>
          <NumberFormat
            prefix={currencySymbol}
            displayType="text"
            value={totalAmount}
            thousandSeparator
          />
        </a>
      }
      {' '}
      amount
    </span>
  )
);

BillableAnalyticsMeta.propTypes = {
  total: PropTypes.number,
  showTotal: PropTypes.bool,
  totalAmount: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  onOpenModal: PropTypes.func.isRequired,
};

BillableAnalyticsMeta.defaultProps = {
  total: 0,
  showTotal: false,
};

export default BillableAnalyticsMeta;
