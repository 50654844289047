import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PeopleRatesTabSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import DocumentAssignmentsListItem from 'core/assets/js/components/DocumentAssignmentsListItem.jsx';
import AorDocumentListItem from 'core/assets/js/components/AorDocumentListItem.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { fetchListDS, getListState, listUpdateItemAC } from 'core/assets/js/ducks/list';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { userDocumentsApiUrl } from 'people/urls';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { getAorInfoApiUrl } from 'documents/urls';

const COMPONENT_NAME = 'documentAssignments';

const PeopleDocumentsTab = ({ items, aor, pagination }) => {
  const dispatch = useDispatch();
  return (
    <div className="tab-content w-100 p-4">
      { (aor.aorEnabled && aor.aorContract.fileUrl)  && (
        <div className="aor-list pb-6">
          <h2>Agent of Record</h2>
          <AorDocumentListItem aorData={
            {
              signedAt: aor.aorContract.signedAt,
              status: aor.aorContract.status,
              docUrl: aor.aorContract.fileUrl,
              userFullName: aor.aorContract.user.fullName,
            }
          }
          />
        </div>
      )}
      <div className="documents-list w-100">
        <h2>Documents</h2>
        <TDList
          listClassName="profile-documents-list"
          items={items}
          pagination={pagination}
          cardItem={{
            // eslint-disable-next-line react/prop-types, react/no-multi-comp
            component: ({ item }) => (
              <DocumentAssignmentsListItem
                documentAssignment={item}
                onUpdated={updatedAssignment => {
                  dispatch(listUpdateItemAC(item.id, updatedAssignment, COMPONENT_NAME));
                }}
              />
            ),
          }}
          emptyListMessage="There aren't any documents yet."
        />
      </div>
    </div>
  );
};

PeopleDocumentsTab.propTypes = {
  items: PropTypes.array,
  aor: PropTypes.object,
  pagination: paginationSpec.isRequired,
};

PeopleDocumentsTab.defaultProps = {
  items: [],
  aor: {},
};

const PeopleDocumentsTabTDApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params, authedAxios, querystring, componentName,
  }) => {
    const { orgAlias, userId, userType } = params;

    return Promise.all([
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        params,
        querystring,
        url: userDocumentsApiUrl({ orgAlias, userId, userType }),
      })),
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        url: getAorInfoApiUrl(orgAlias, userId),
        extrasKey: 'aor',
      })),
    ]);
  },
  storeKey: COMPONENT_NAME,
  duck: 'list',
  skeletonComponent: () => <PeopleRatesTabSkeleton className="px-4" />,
})(PeopleDocumentsTab);

const mapStateToProps = (state) => {
  const listState = getListState(state, COMPONENT_NAME);

  return {
    pagination: listState.pagination,
    aor: listState.extras?.aor,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PeopleDocumentsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleDocumentsTabTDApiConnected);
export default withRouter(PeopleDocumentsTabConnected);
