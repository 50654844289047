import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { projectThinListApiUrl } from 'projects/urls';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { API_DATE_FORMAT, MULTISELECT_TYPE, MULTISELECT_TARGET } from 'core/assets/js/constants';
import MultiselectField from 'core/assets/js/components/ReduxFormFields/MultiselectField.jsx';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import { parseDate } from 'core/assets/js/lib/utils';

export const FORM_ID = 'task-clone-components-form';

const TaskCloneComponentsSelection = ({
  className, handleSubmit, initialValues, items, taskDeadlineMandatory, startsOn,
}) => (
  <form className={className} onSubmit={handleSubmit}>
    <Field
      component={InputField}
      label="Title"
      name="title"
      required
      type="text"
    />
    <Field
      name="description"
      type="checkbox"
      label="Description"
      component={InputField}
      checked
      disabled
    />
    <Field
      name="attachments"
      type="checkbox"
      label="Attachments"
      component={InputField}
    />
    <Field
      name="items"
      type="checkbox"
      label="Actionable Items"
      component={InputField}
    />
    <Field
      name="tags"
      type="checkbox"
      label="Tags"
      component={InputField}
    />
    <Field
      name="skills"
      type="checkbox"
      label="Skills"
      component={InputField}
    />
    <Field
      name="project_id"
      label="Copy to project"
      size="extra-large"
      className="mt-3"
      component={MultiselectField}
      multiselectComponent={MULTISELECT_TYPE.SIMPLE}
      target={MULTISELECT_TARGET.PROJECTS}
      defaultValue={initialValues.project_id}
      searchable
      options={items}
      makeInitialCall
    />
    <div className="row">
      <div className="col-12 col-md-6">
        <Field
          component={InputField}
          label="Starts On"
          name="starts_on"
          placeholder="DD / MM / YYYY"
          type="date"
        />
      </div>
      <div className="col-12 col-md-6">
        <Field
          component={InputField}
          disablePastDates
          label="Deadline"
          name="deadline"
          minDate={startsOn}
          placeholder="DD / MM / YYYY"
          required={taskDeadlineMandatory}
          type="date"
        />
      </div>
    </div>
  </form>
);

TaskCloneComponentsSelection.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object), // added via withTdApiConnected
  taskDeadlineMandatory: PropTypes.bool.isRequired,
  startsOn: PropTypes.object,
};

TaskCloneComponentsSelection.defaultProps = {
  className: '',
  items: [],
  initialValues: {},
  startsOn: null,
};
const selector = formValueSelector(FORM_ID);

const mapStateToProps = (state) => {
  const startsOn = selector(state, 'starts_on');
  const parsedStartsOn = parseDate(startsOn, API_DATE_FORMAT, false);
  return { startsOn: parsedStartsOn?.isValid() ? parsedStartsOn : null };
};
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskCloneComponentsSelectionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskCloneComponentsSelection);

const TaskCloneComponentsSelectionReduxForm = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
})(TaskCloneComponentsSelectionConnected);

const ConnectedTaskCloneComponentsSelection = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) => dispatch(fetchListDS({
    authedAxios, componentName, querystring, url: projectThinListApiUrl(params.orgAlias, url),
  })),
  duck: 'list',
  storeKey: 'TaskCloneComponentSelection',
  skeletonComponent: ListSkeleton,
})(TaskCloneComponentsSelectionReduxForm);

export default ConnectedTaskCloneComponentsSelection;
