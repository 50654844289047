import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { PROJECT_STATUS } from 'projects/assets/js/constants';
import { getPercentages } from 'analytics/assets/js/lib/utils';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import StatusDot from 'core/assets/js/components/StatusDot.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

const ProjectBudgetWidget = (props) => {
  const { project } = props;
  const isProjectPosted = project.status === PROJECT_STATUS.POSTED;
  const projectBudgetProgress = {};
  const projectBudgetCompleted = {};
  const { total, used, pending, available, refunded } = project.budgetBreakdown;
  // Calculate budget progress percentages
  [
    projectBudgetProgress.used,
    projectBudgetProgress.pending,
    projectBudgetProgress.available,
    projectBudgetProgress.refunded,
  ] = getPercentages([used, pending, available, refunded]);

  [
    projectBudgetCompleted.used,
    projectBudgetCompleted.refunded,
  ] = getPercentages([used, refunded]);

  return (
    <Card
      className="project-budget"
    >
      <Card.Header
        className="border-0 pb-0"
      >
        <div className="d-flex align-items-center project-budget__header-title">
          <span className="d-inline-block m-0 p-0">Budget</span>
          <span className="ml-auto project-budget__header-amount">
            <NumberTpl
              prefix={project.currency_symbol}
              thousandSeparator
              value={total}
            />
          </span>
        </div>
      </Card.Header>
      <Card.Body
        className="pt-2"
      >
        <div className="project-budget__line-container mt-2 mb-4">
          {isProjectPosted && parseInt(total, 10) === 0 && (
            <TDElementWithTooltip
              tooltipMsg={(
                <NumberTpl
                  prefix={project.currency_symbol}
                  thousandSeparator
                  value={total}
                />
              )}
            >
              <div
                style={{ width: `${100}%` }}
                className="project-budget__line project-budget__line--posted"
              />
            </TDElementWithTooltip>
          )}
          {!isProjectPosted && (
            <React.Fragment>
              {parseInt(used, 10) > 0 && (
                <TDElementWithTooltip
                  tooltipMsg={(
                    <NumberTpl
                      prefix={project.currency_symbol}
                      thousandSeparator
                      value={used}
                    />
                  )}
                >
                  <div
                    style={{ width: `${projectBudgetProgress.used}%` }}
                    className="project-budget__line project-budget__line--used"
                  />
                </TDElementWithTooltip>
              )}
              {parseInt(refunded, 10) > 0 && (
                <TDElementWithTooltip
                  tooltipMsg={(
                    <NumberTpl
                      prefix={project.currency_symbol}
                      thousandSeparator
                      value={refunded}
                    />
                  )}
                >
                  <div
                    style={{ width: `${projectBudgetProgress.refunded}%` }}
                    className="project-budget__line project-budget__line--refunded"
                  />
                </TDElementWithTooltip>
              )}
              {parseInt(available, 10) > 0 && (
                <TDElementWithTooltip
                  tooltipMsg={(
                    <NumberTpl
                      prefix={project.currency_symbol}
                      thousandSeparator
                      value={available}
                    />
                  )}
                >
                  <div
                    style={{ width: `${projectBudgetProgress.available}%` }}
                    className="project-budget__line project-budget__line--available"
                  />
                </TDElementWithTooltip>
              )}
              {parseInt(pending, 10) > 0 && (
                <TDElementWithTooltip
                  tooltipMsg={(
                    <NumberTpl
                      prefix={project.currency_symbol}
                      thousandSeparator
                      value={pending}
                    />
                  )}
                >
                  <div
                    style={{ width: `${projectBudgetProgress.pending}%` }}
                    className="project-budget__line project-budget__line--pending"
                  />
                </TDElementWithTooltip>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="project-budget__info-container flex-wrap">
          <div className="mr-5 d-flex align-items-center">
            <StatusDot statusClass="info" classes="ml-0" noFill />
            <span className="mr-3"><b>Used:</b></span>
            <NumberTpl
              prefix={project.currency_symbol}
              thousandSeparator
              value={used || 0}
            />
          </div>
          {parseInt(refunded, 10) > 0 && (
            <div className="mr-5 d-flex align-items-center">
              <StatusDot classes="ml-0" noFill />
              <span className="mr-3"><b>Refunded:</b></span>
              <NumberTpl
                prefix={project.currency_symbol}
                thousandSeparator
                value={refunded || 0}
              />
            </div>
          )}
          <div className="mr-5 d-flex align-items-center">
            <StatusDot classes="ml-0" statusClass="pending" noFill />
            <span className="mr-3"><b>Requested:</b></span>
            <NumberTpl
              prefix={project.currency_symbol}
              thousandSeparator
              value={pending || 0}
            />
          </div>
          <div className="d-flex align-items-center">
            <StatusDot classes="ml-0" statusClass="success" noFill />
            <span className="mr-3"><b>Available:</b></span>
            <NumberTpl
              prefix={project.currency_symbol}
              thousandSeparator
              value={available || 0}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

ProjectBudgetWidget.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectBudgetWidget;
