import { toastr } from 'react-redux-toastr';
import { isArray } from 'lodash';
import { SubmissionError } from 'redux-form';

import {
  orgInvitationTemplateDeleteApiUrl,
  orgInvitationTemplatesApiUrl,
  orgInvitationTemplateUpdateApiUrl,
  orgSettingsFinControllerApiUrl,
  orgSkillApiUrl,
  orgSkillsApiUrl,
  settingsBankAccountApiUrl,
  settingsBankAccountsApiUrl,
  settingsOrgBankAccountApiUrl,
  settingsPaymentTermsApiUrl,
  skillDirectoriesListApiUrl,
  skillDirectoriesManageApiUrl,
  skillDirectoryRemoveSkillApiUrl,
  skillDirectorySkillsApiUrl,
  skillEndorseApiUrl,
  skillEndorseVoteApiUrl,
  settingsBankAccountOrganizationsApiUrl,
} from 'settings/urls';
import {
  payoneerCreateRegistrationApiUrl,
  twCountryCurrencyValidationApiUrl,
  twCurrencyRequirementsUrl,
} from 'services/urls';
import { viewFetchAC, viewUpdateAC } from 'core/assets/js/ducks/view';
import { listFetchAC, listAppendItemAC, listRemoveItemAC } from 'core/assets/js/ducks/list';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';
import { completeStepDS } from 'accounts/assets/js/ducks/account';

const createBankAccountDS = async (orgAlias, values, dispatch) => {
  const responseData = await dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: settingsBankAccountsApiUrl(),
    values,
  }));
  toastr.removeByType('info');
  if (responseData.isUsersFirstAccount) {
    await completeStepDS(dispatch, orgAlias, 'PaymentMethodStep');
  } else {
    toastr.success('Well Done!', 'Payment method created successfully.');
  }
  return responseData;
};

const createPayoneerRegistrationLinkDS = (values, dispatch) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: payoneerCreateRegistrationApiUrl(),
    values,
  }))
);

const updateBankAccountDS = (values, dispatch) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: settingsBankAccountApiUrl(values.id),
    values,
  })).then(() => {
    toastr.removeByType('info');
    toastr.success('Well Done!', 'Payment method updated successfully.');
  })
);

const validateBankAccountCountryCurrencyDS = async ({ values, dispatch, url = '' }) => {
  const responseData = await dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: twCountryCurrencyValidationApiUrl(values.currency, values.countryCode, { url }),
    values,
  }));
  return responseData;
};

const updateBankAccountOrganizationsDS = ({ values, bankAccountId }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: settingsBankAccountOrganizationsApiUrl(bankAccountId),
    values,
  })
);

const updateOrgBankAccount = (values, dispatch, componentName, orgAlias) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: settingsOrgBankAccountApiUrl(orgAlias),
    pushDataAC: responseData => viewFetchAC(responseData, componentName),
    values,
  }))
);

const deleteBankAccountDS = (bankAccountId, dispatch) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: settingsBankAccountApiUrl(bankAccountId),
  })).then(() => {
    toastr.success('Well Done!', 'Payment method removed successfully.');
  })
);

const postPaymentSettingDS = (values, dispatch, componentName) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: settingsPaymentTermsApiUrl(),
    pushDataAC: responseData => viewFetchAC(responseData, componentName),
    values,
  })).then(() => {
    toastr.success('Well Done!', 'Payment settings updated successfully.');
  })
);

const fetchFinControllerDS = ({ params, url = '', authedAxios, componentName }) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => orgSettingsFinControllerApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => listFetchAC(responseData, componentName),
  })
);

const postFinControllerDS = ({ orgAlias, userIds }) => (
  dispatch => (
    dispatch(
      pushDataDS({
        pushApiUrl: orgSettingsFinControllerApiUrl(orgAlias),
        values: { newFinControllers: userIds },
      }),
    ).then((responseData) => {
      toastr.success('Well Done!', 'Financial Controller set successfully.');
      return responseData;
    }).catch((err) => {
      if (isArray(err.errors) && err.errors.length > 0) {
        toastr.error('Oh Snap!', err.errors[0]._error);
      } else {
        toastr.error('Oh Snap!', 'Could not set a new Financial Controller');
      }
    })
  )
);

const deleteFinControllerDS = ({ orgAlias, userId }) => (
  dispatch => (
    dispatch(
      pushDataDS({
        reqType: REQ_TYPE.DELETE,
        pushApiUrl: orgSettingsFinControllerApiUrl(orgAlias, userId),
      }),
    ).then(
      (responseData) => {
        toastr.success('Well Done!', 'Financial Controller removed successfully.');
        return responseData;
      },
    ).catch((err) => {
      if (isArray(err.errors) && err.errors.length > 0) {
        toastr.error('Oh Snap!', err.errors[0]._error);
      } else {
        toastr.error('Oh Snap!', 'Could not remove Financial Controller');
      }
    })
  )
);

// Invitation templates
const createInvitationTemplateDS = ({ orgAlias, values }) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: orgInvitationTemplatesApiUrl(orgAlias),
    values,
  }))
    .catch((data) => {
      throw new SubmissionError(data.errors);
    })
);

const updateInvitationTemplateDS = ({ orgAlias, values }) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: orgInvitationTemplateUpdateApiUrl(orgAlias, values.id),
    values,
  }))
    .catch((data) => {
      throw new SubmissionError(data.errors);
    })
);

const deleteInvitationTemplatefDS = ({ orgAlias, id }) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: orgInvitationTemplateDeleteApiUrl(orgAlias, id),
  }))
    .catch((data) => {
      throw new SubmissionError(data.errors);
    })
);

const createOrgSkillDS = (orgAlias, values) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.POST,
      pushApiUrl: orgSkillsApiUrl(orgAlias),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Skill created successfully.');
    })
  )
);

const updateOrgSkillDS = (orgAlias, values) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: orgSkillApiUrl(orgAlias, values.id),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Skill updated successfully.');
    })
  )
);

const deleteOrgSkillDS = (orgAlias, id) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: orgSkillApiUrl(orgAlias, id),
  }))
    .then(
      () => (
        toastr.success('Well Done!', 'Skill removed successfully.')
      ))
    .catch((err) => {
      if (isArray(err.errors) && err.errors.length > 0) {
        toastr.error('Oh Snap!', err.errors[0]._error);
      } else {
        toastr.error('Oh Snap!', 'Could not delete organization skill');
      }
    })
);

// TODO - remove queryParams !!!
const getTWRequirementsDS = ({ componentName, currency, details, queryParams } = {}) => pushDataDS({
  pushApiUrl: twCurrencyRequirementsUrl(currency, { queryParams }),
  pushDataAC: responseData => viewFetchAC(responseData, componentName),
  values: details,
});

const endorseProfileSkillDS = (orgAlias, values) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.POST,
      pushApiUrl: skillEndorseApiUrl(orgAlias),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Skill endorsed successfully.');
    })
  )
);


const upVoteProfileSkillDS = (orgAlias, values) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: skillEndorseVoteApiUrl(orgAlias, 'up-vote', ''),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Skill upvoted successfully.');
    })
  )
);

const retractVoteProfileSkillDS = (orgAlias, values) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: skillEndorseVoteApiUrl(orgAlias, 'retract-vote', ''),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Skill retracted successfully.');
    })
  )
);

const createSkillDirectoryDS = ({ values, componentName, orgAlias }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: skillDirectoriesListApiUrl(orgAlias),
    pushDataAC: responseData => listAppendItemAC(responseData, componentName),
    values,
  })
);

const updateSkillDirectoryDS = ({ values, componentName, orgAlias, skillDirectoryId }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: skillDirectoriesManageApiUrl(orgAlias, skillDirectoryId),
    pushDataAC: responseData => viewUpdateAC(responseData, componentName),
    values,
  })
);

const deleteSkillDirectoryDS = ({ componentName, orgAlias, skillDirectoryId }) => (
  pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: skillDirectoriesManageApiUrl(orgAlias, skillDirectoryId),
    pushDataAC: responseData => listRemoveItemAC(responseData.id, componentName),
  })
);

const skillDirectoryRemoveSkillDS = ({ orgAlias, skillDirectoryId, skillId }) => (
  pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: skillDirectoryRemoveSkillApiUrl(orgAlias, skillDirectoryId, skillId),
  })
);

const skillDirectoryAddSkillsDS = ({ orgAlias, skillDirectoryId, values }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: skillDirectorySkillsApiUrl(orgAlias, skillDirectoryId),
    values,
  })
);


export {
  createBankAccountDS,
  createInvitationTemplateDS,
  createOrgSkillDS,
  createSkillDirectoryDS,
  deleteBankAccountDS,
  deleteFinControllerDS,
  deleteInvitationTemplatefDS,
  deleteOrgSkillDS,
  deleteSkillDirectoryDS,
  endorseProfileSkillDS,
  fetchFinControllerDS,
  getTWRequirementsDS,
  postFinControllerDS,
  postPaymentSettingDS,
  retractVoteProfileSkillDS,
  skillDirectoryAddSkillsDS,
  skillDirectoryRemoveSkillDS,
  updateBankAccountDS,
  updateInvitationTemplateDS,
  updateOrgBankAccount,
  updateOrgSkillDS,
  updateSkillDirectoryDS,
  updateBankAccountOrganizationsDS,
  upVoteProfileSkillDS,
  createPayoneerRegistrationLinkDS,
  validateBankAccountCountryCurrencyDS,
};
