import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import { CURRENCY_SYMBOL, ICON } from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';

const InvoiceWorksheetItemsBreakdownCard = ({ item }) => {
  const { amount, currency, description, period, projectTitle, worksheet, worksheetId } = item;
  const expandableArea = (
    <div className="mx-n4 px-4 py-3 invoice-breakdown-worksheet-card__expandable-content">
      <h4 className="d-inline-block">Worksheet summary</h4>
      <Card className="bg-white">
        <Card.Body className="p-3">
          {description}
        </Card.Body>
      </Card>

      {!isEmpty(worksheet?.items) && (
        <>
          <h4 className="d-inline-block mt-5">Work</h4>
          { worksheet?.items && worksheet.items.map(wItem => (
            <Card className="bg-white py-1">
              <Card.Body className="pt-3 pb-1 px-3">
                <div className="row">
                  <div className="col-6 d-lg-flex mb-2">
                    <div className="invoice-breakdown-worksheet-card__label">
                      {wItem.taskId ? 'Task' : 'Project work'}
                    </div>
                    <div className="invoice-breakdown-worksheet-card__value">{wItem.description}</div>
                  </div>
                  <div className="col-6 d-lg-flex mb-2">
                    <div className="invoice-breakdown-worksheet-card__label">Amount</div>
                    <div className="invoice-breakdown-worksheet-card__value">
                      <span className="font-weight-bolder">
                        <NumberTpl
                          value={wItem.worksheetItemTotal}
                          prefix={CURRENCY_SYMBOL[wItem.worksheetItemCurrency]}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-6 d-lg-flex mb-2">
                    <div className="invoice-breakdown-worksheet-card__label">Quantity</div>
                    <div className="invoice-breakdown-worksheet-card__value">
                      {`${wItem.quantity} ${RATE_UNIT_FORMAT[wItem.rateUnit].unit}`}
                    </div>
                  </div>
                  <div className="col-6 d-lg-flex mb-2">
                    <div className="invoice-breakdown-worksheet-card__label">Rate</div>
                    <div className="invoice-breakdown-worksheet-card__value">
                      <p className="d-flex justify-content-between mb-0">
                        <span className="p-0 mr-4">
                          <NumberTpl
                            value={wItem.worksheetItemRateAmount}
                            prefix={CURRENCY_SYMBOL[wItem.worksheetItemCurrency]}
                          />
                          {` ${RATE_UNIT_FORMAT[wItem.rateUnit].abbreviation}`}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}
        </>
      )}
    </div>
  );

  return (
    <Card className="invoice-breakdown-worksheet-card">
      <Card.Body>
        <div className="row mb-n3">
          <div className="col-6 d-lg-flex mb-3">
            <div className="invoice-breakdown-worksheet-card__label">Project</div>
            <div className="invoice-breakdown-worksheet-card__value">{projectTitle}</div>
          </div>
          <div className="col-6 d-lg-flex mb-3">
            <div className="invoice-breakdown-worksheet-card__label">Period</div>
            <div className="invoice-breakdown-worksheet-card__value">{period}</div>
          </div>
          <div className="col-6 d-lg-flex mb-3">
            <div className="invoice-breakdown-worksheet-card__label">Worksheet ID</div>
            <div className="invoice-breakdown-worksheet-card__value">{worksheetId}</div>
          </div>
          <div className="col-6 d-lg-flex mb-3">
            <div className="invoice-breakdown-worksheet-card__label">Amount</div>
            <div className="invoice-breakdown-worksheet-card__value">
              <span className="font-weight-bolder">
                <NumberTpl
                  value={amount}
                  prefix={CURRENCY_SYMBOL[currency]}
                />
              </span>
            </div>
          </div>
        </div>
      </Card.Body>

      <Card.Footer className="py-2 px-4">
        <AreaCollapsible
          // headingClassName="m-0 my-n2 row p-0"
          headingChildren={(
            <span className="invoice-breakdown-worksheet-card__expand-title">More</span>
          )}
          contentChildren={expandableArea}
          containerElement="div"
          icons={{ expand: ICON.CHEVRON_RIGHT, collapse: ICON.CHEVRON_DOWN }}
        />
      </Card.Footer>
    </Card>
  );
};

InvoiceWorksheetItemsBreakdownCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default InvoiceWorksheetItemsBreakdownCard;
