import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { SubmissionError } from 'redux-form';

import { STATUS } from 'invitations/assets/js/constants';
import { BS_STYLE, IMG_SIZE } from 'core/assets/js/constants';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import { validateInvitationActiveDS, selectActiveInvitation, usePublicInvitationDS } from 'invitations/assets/js/ducks/invitation';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import InvitationPublicJoinForm from 'invitations/assets/js/components/InvitationPublicJoinForm.jsx';
import RedirectHelper from 'core/assets/js/config/routes/RedirectHelper';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { determinePostPublicInviteUrl } from 'invitations/assets/js/lib/utils';

class InvitationPublicView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    if (params.token) {
      return Promise.all([
        dispatch(validateInvitationActiveDS({
          token: params.token, url, authedAxios, componentName,
        })),
      ]);
    }
    return Promise.resolve();
  }

  static GetComponentName() {
    return 'InvitationPublicView';
  }

  constructor(props) {
    super(props);
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this);
  }

  handleSubmitSuccess(response) {
    const { history, isLoggedIn } = this.props;

    if (!response.invitation) {
      throw new Error('Invitation not found');
    }

    history.push(determinePostPublicInviteUrl(response.invitation, { isLoggedIn }));
  }

  render() {
    const {
      isValid, invitation, isLoading, history, isLoggedIn, profile,
    } = this.props;


    if (!isLoading
      && (isValid !== null)
      && (!isValid || invitation.status !== STATUS.PENDING || invitation.hasExpired)
    ) {
      RedirectHelper.redirectToInvitationReject({ history, token: invitation.token });
      return null;
    }

    // Wrong type? the invitation is bound to a user
    if (!isLoading && invitation.userId) {
      RedirectHelper.redirectToInvitationManage({ history, token: invitation.token });
      return null;
    }


    const classNames = [
      'invitation-card__org-info',
      'invitation-card__org-info--inline',
      'invitation-card__org-info--compact',
    ];

    return (
      <TDApiConnected duck="invitation" component={this.constructor}>
        <div>
          <div
            className={classNames.join(' ')}
          >
            { invitation.orgName && (
              <OrganizationLogo
                url={invitation.orgLogo}
                orgName={invitation.orgName}
                size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
              />
            )}
            <h3>{invitation.orgName}</h3>
          </div>

          { isLoggedIn && (
            <TDSystemMessage
              type={BS_STYLE.INFO}
              title="Heads up!"
              className="mb-4"
            >
              {`You are already logged in as “${profile.firstName} ${profile.lastName}”`}
            </TDSystemMessage>
          )}

          <div className="mb-4" data-testid="invitation-public-view-text">
            <p>
              {`If you are on this page you have applied to be part of the talent pool at
              “${invitation.orgName}”. To get you to the right place we need you to submit
              your email address in the box below.`}
            </p>
            <p>
              If you already have a TalentDesk.io profile you will be redirected to your login
              so that you can submit the required onboarding information.
              Otherwise we will require you to create a profile with us first.
              Do not worry, it takes 2 minutes.
            </p>
          </div>
        </div>
        <InvitationPublicJoinForm
          onSubmit={async (values) => {
            try {
              const response = await usePublicInvitationDS(invitation.token, values);

              if (response.data._error) {
                throw new SubmissionError(response.data);
              }

              return response.data;
            } catch (err) {
              throw new SubmissionError(err.response.data);
            }
          }}
          onSubmitSuccess={this.handleSubmitSuccess}
          initialValues={{ sent_to: profile && profile.email ? profile.email : null }}
        />
      </TDApiConnected>
    );
  }
}

InvitationPublicView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invitation: invitationSpec.isRequired,
  history: routerHistorySpec.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  profile: profileSpec,
};

InvitationPublicView.defaultProps = {
  profile: {},
  isValid: null,
};

const mapStateToProps = (state, props) => {
  const activeInvitation = selectActiveInvitation(state);

  return {
    isLoading: activeInvitation.isLoading,
    isValid: activeInvitation.isValid,
    invitation: activeInvitation.item,
    history: props.history,
    isLoggedIn: !isEmpty(selectProfile(state)),
    profile: selectProfile(state),
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const InvitationPublicViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationPublicView);

export default withRouter(InvitationPublicViewConnected);
