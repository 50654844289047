import moment from 'moment';
import { isEmpty, isNaN, isNil, range } from 'lodash';

import { API_DATE_FORMAT } from 'core/assets/js/constants';
import { DEFAULT_BALANCE_CURRENCY, SUBSCRIPTION_PLANS } from 'finance/assets/js/constants';
import Money from 'finance/assets/js/lib/Money';


const parseAmount = (value, currency, defaultValue = '0.00') => (
  (isNil(value) || isNaN(value)) ? defaultValue : new Money(value, currency).toString()
);

export class SubscriptionFeeScheme {
  constructor(...args) {
    if (isEmpty(args) || args.every(a => isEmpty(a))) {
      return this.init();
    }
    if (args[0] instanceof SubscriptionFeeScheme) {
      return this.init(args[0].serialize());
    }
    return this.init(...args);
  }

  init({
    currency = DEFAULT_BALANCE_CURRENCY,
    subscriptionFee = '0.00',
    periodStart, periodEnd, quantity,
    plan, paymentProviderFee, vat,
    subscriptionId,
  } = {}) {
    this.spec = {
      currency,
      subscriptionFee: parseAmount(subscriptionFee, currency),
      periodStart: periodStart || moment().format(API_DATE_FORMAT),
      periodEnd: periodEnd || moment().format(API_DATE_FORMAT),
      subscriptionId,
      quantity: parseInt(quantity || 0, 10),
      plan: parseInt(plan || SUBSCRIPTION_PLANS.BASIC, 10),
      paymentProviderFee: parseAmount(paymentProviderFee, currency),
      vat: parseAmount(vat, currency),
    };
  }

  serialize() {
    return this.spec;
  }

  hasSubscriptionFee() {
    const { subscriptionFee, currency } = this.spec;

    return !new Money(subscriptionFee, currency).isZero();
  }

  getCurrency() {
    return this.spec.currency;
  }

  getSubscriptionFee() {
    return this.spec.subscriptionFee;
  }

  getTotal() {
    return this.spec.subscriptionFee;
  }

  getPaymentProviderFee() {
    return this.spec.paymentProviderFee;
  }

  getVat() {
    return this.spec.vat;
  }

  getPeriodStart() {
    return this.spec.periodStart;
  }

  getNumberOfMonths() {
    const { periodStart } = this.spec;
    return this.getRemainingNumberOfMonths(moment(periodStart));
  }

  getRemainingNumberOfMonths(now) {
    if (!now) {
      throw new Error('expecting current time');
    }
    if (!(now instanceof Date || moment.isMoment(now))) {
      throw new Error('expecting current time as an instance of Date or moment');
    }
    const { periodEnd } = this.spec;
    let start = moment(now).startOf('day');
    const end = moment(periodEnd).startOf('day');
    if (start > end) {
      throw new Error('start date should be earlier than end date');
    }
    let counter = 0;
    while (start < end && counter <= 24) {
      counter += 1;
      start = moment(now).startOf('day').add(counter, 'months');
    }
    if (counter > 24) {
      throw new Error('end date should be earlier than 2 years after start date');
    }
    return counter;
  }

  getSubscriptionFeePerMonth() {
    const fee = this.getSubscriptionFee();
    const numMonths = this.getNumberOfMonths();
    const currency = this.getCurrency();
    return new Money(fee, currency).div(numMonths).toString();
  }

  getPaymentProviderFeePerMonth() {
    const fee = this.getPaymentProviderFee();
    const numMonths = this.getNumberOfMonths();
    const currency = this.getCurrency();
    return new Money(fee, currency).div(numMonths).toString();
  }

  getVatPerMonth() {
    const vat = this.getVat();
    const numMonths = this.getNumberOfMonths();
    const currency = this.getCurrency();
    return new Money(vat, currency).div(numMonths).toString();
  }

  splitIntoMonthlyAmortizedFees() {
    const { spec } = this;
    const numMonths = this.getNumberOfMonths();
    if (numMonths === 0) {
      return [];
    }
    const subscriptionFeePerMonth = this.getSubscriptionFeePerMonth();
    const paymentProviderFeePerMonth = this.getPaymentProviderFeePerMonth();
    const vatPerMonth = this.getVatPerMonth();
    const periodStart = this.getPeriodStart();
    return range(numMonths).reduce((acc, curr, idx) => {
      acc.push({
        ...spec,
        subscriptionFee: subscriptionFeePerMonth,
        periodStart: moment(periodStart).add(idx, 'months'),
        periodEnd: moment(periodStart).add(idx + 1, 'months').subtract(1, 'days'),
        paymentProviderFee: paymentProviderFeePerMonth,
        vat: vatPerMonth,
      });
      return acc;
    }, []);
  }

  getPeriodEnd() {
    return this.spec.periodEnd;
  }

  getQuantity() {
    return this.spec.quantity;
  }

  getPlan() {
    return this.spec.plan;
  }

  toString() {
    return JSON.stringify(this.serialize(), null, 2);
  }

  isEmpty() {
    const { spec } = this;
    return !spec || isEmpty(spec);
  }

  isZero() {
    const { subscriptionFee, currency } = this.spec;
    return new Money(subscriptionFee, currency).isZero();
  }

  hasFee() {
    const { subscriptionFee, currency } = this.spec;
    return !new Money(subscriptionFee, currency).isZero();
  }
}

class SubscriptionFeeAnalysis {
  constructor(args) {
    if (!args || isEmpty(args)) {
      this.init({
        currency: DEFAULT_BALANCE_CURRENCY,
        stripe: null,
      });
    } else if (Array.isArray(args) && !isEmpty(args)) {
      this.init({
        currency: args[0].currency,
        stripe: args[0],
      });
    } else {
      this.init(args);
    }
  }

  init({ currency, stripe }) {
    this.details = {
      currency: currency || stripe?.currency || DEFAULT_BALANCE_CURRENCY,
      stripe: new SubscriptionFeeScheme(stripe),
    };
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    const { stripe } = this.details;
    return {
      stripe: stripe.serialize(),
      total: this.getTotal(),
    };
  }

  getComponents() {
    const { stripe } = this.details;
    return [stripe];
  }

  aggregate() {
    const components = this.getComponents();
    if (isEmpty(components)) {
      return '0.00';
    }
    const currency = this.getCurrency();
    let fee = new Money(0, currency);
    components.forEach((part) => {
      fee = fee.add(part.getTotal());
    });
    return fee.toString();
  }

  hasMultipleFeeSchemes() {
    const components = this.getComponents();
    return components.length > 1;
  }

  getPaymentProviderFee() {
    if (this.isEmpty()) {
      return 0;
    }
    if (this.hasMultipleFeeSchemes()) {
      // guard against multiple fee schemes which will require a more complex implementation
      throw new Error('cannot calculate with multiple fee schemes');
    }
    const { stripe } = this.details;
    return stripe.getPaymentProviderFee();
  }

  getNumberOfMonths() {
    if (this.isEmpty()) {
      return 0;
    }
    if (this.hasMultipleFeeSchemes()) {
      // guard against multiple fee schemes which will require a more complex implementation
      throw new Error('cannot calculate with multiple fee schemes');
    }
    const { stripe } = this.details;
    return stripe.getNumberOfMonths();
  }

  getRemainingNumberOfMonths(now) {
    if (this.isEmpty()) {
      return 0;
    }
    if (this.hasMultipleFeeSchemes()) {
      // guard against multiple fee schemes which will require a more complex implementation
      throw new Error('cannot calculate with multiple fee schemes');
    }
    const { stripe } = this.details;
    return stripe.getRemainingNumberOfMonths(now);
  }

  getSubscriptionFeePerMonth() {
    if (this.isEmpty()) {
      return '0.00';
    }
    if (this.hasMultipleFeeSchemes()) {
      // guard against multiple fee schemes which will require a more complex implementation
      throw new Error('cannot calculate with multiple fee schemes');
    }
    const { stripe } = this.details;
    return stripe.getSubscriptionFeePerMonth();
  }

  getPaymentProviderFeePerMonth() {
    if (this.isEmpty()) {
      return '0.00';
    }
    if (this.hasMultipleFeeSchemes()) {
      // guard against multiple fee schemes which will require a more complex implementation
      throw new Error('cannot calculate with multiple fee schemes');
    }
    const { stripe } = this.details;
    return stripe.getPaymentProviderFeePerMonth();
  }

  splitIntoMonthlyAmortizedFees() {
    if (this.isEmpty()) {
      return [];
    }
    if (this.hasMultipleFeeSchemes()) {
      // guard against multiple fee schemes which will require a more complex implementation
      throw new Error('cannot calculate with multiple fee schemes');
    }
    const { stripe } = this.details;
    return stripe.splitIntoMonthlyAmortizedFees();
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getTotal() {
    return this.aggregate();
  }

  isEmpty() {
    return new Money(this.getTotal(), this.getCurrency()).isZero();
  }
}

export default SubscriptionFeeAnalysis;
