import React from 'react';
import { useFormState } from 'react-final-form';

import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import { ORGANIZATION_CURRENCY } from 'core/assets/js/constants';
import { RATE_UNIT_SELECT_FILTERED } from 'rates/assets/js/constants';
import SalesTaxFields from 'settings/assets/js/components/SalesTaxFields.jsx';
import { getInfoFromFormValues } from 'settings/assets/js/lib/sales_tax_utils';

const OrgAccountingPrefsForm = () => {
  const { values, submitting } = useFormState();
  const salesTaxInfo = getInfoFromFormValues(values);

  return (
    <>
      <h2>Finance details</h2>

      <CurrencySelectField
        currencyOptions={ORGANIZATION_CURRENCY}
        isClearable={false}
        label="Company currency"
        name="currency"
        popOverContent={(
          <>
            <p>
              The organisation currency - AKA base currency, determines the currency of the:
            </p>
            <ol type="a" className="pl-4">
              <li>Invoices your organisation receives by TalentDesk</li>
              <li>Default rates set by your providers</li>
              <li>Budget allocated to managers and projects</li>
            </ol>
          </>
        )}
        popOverTitle="Organisation currency explanation"
        required
        sublabel="Choose the currency that you want to operate in"
      />

      <SelectField
        label="Default billing rate"
        name="default_rate_unit"
        optionsMapping={RATE_UNIT_SELECT_FILTERED}
        popOverContent={(
          <>
            <p>
              The base rate unit determines the unit of the default rates of your providers
              - e.g. per hour, per day, per word etc. By having a base rate unit you can
              efficiently filter and discover providers using their default rate.
            </p>
            <p>
              However, this doesn&rsquo;t mean that all payments need to happen based on the
              selected rate unit. Your providers will have the flexibility to request payments
              in any rate unit they want - e.g. per hour, per day, per word, commission-based,
              per task etc.
            </p>
          </>
        )}
        popOverTitle="Rate unit explanation"
        required
        sublabel={(
          'Choose the default rate that your contractors will invoice with. They will be able to use'
          + ' other rates. However, we require a default'
        )}
      />

      <SalesTaxFields
        {...salesTaxInfo}
        showTaxSystemSelect
        submitting={submitting}
      />
    </>
  );
};

OrgAccountingPrefsForm.GetComponentName = () => 'OrgAccountingPrefsForm';

export default OrgAccountingPrefsForm;
