import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import DocumentSelectField from 'core/assets/js/components/FinalFormFields/DocumentSelectField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import { INPUT_TYPE } from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import MultitextField from 'core/assets/js/components/FinalFormFields/MultitextField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON, MAX_UPLOAD_FILES, USER_TYPE } from 'core/assets/js/constants';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { uploaderInterviewPath } from 'core/urls';
import { TYPE, TYPE_LABEL, VISIBLE_TO_OPTIONS } from 'interviews/assets/js/constants';
import { getCustomFieldPath } from 'interviews/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const TYPE_OPTIONS = [
  { icon: ICON.TEXTFIELD, label: 'Text Field', type: TYPE.TEXT },
  { icon: ICON.COINS, label: 'Money', type: TYPE.MONEY },
  { icon: ICON.RADIO, label: 'Selection', type: TYPE.SELECT },
  { icon: ICON.ATTACHMENT, label: 'Attachment', type: TYPE.FILE },
  { icon: ICON.CALENDAR_ALT, label: 'Date', type: TYPE.DATE },
  { icon: ICON.CHECKMARK, label: 'Yes/No', type: TYPE.YESNO },
];

const ATTACHMENT_TYPES = [
  { value: 'IMAGES', text: 'Image' },
  { value: 'DOCUMENTS', text: 'Document' },
  { value: 'VIDEOS', text: 'Video' },
];

const DragHandle = SortableHandle(() => (
  <span className={`${ICON.DRAG_HANDLE} interview-builder__drag-handle`} />
));

const yesNoOptions = [{ value: true, text: 'Yes' }, { value: false, text: 'No' }];

const Question = SortableElement(({
  fieldIndex, fieldName, interviewId, onChange, onRemove, organizationId, question,
}) => {
  return (
    <div className="interview-builder__question">
      <DragHandle />
      <div className="clearfix">
        <i
          className={`${ICON.CROSS} interview-builder__question-remove`}
          onClick={onRemove}
        />
        <h4 className="interview-builder__question-title">
          {`FIELD ${fieldIndex + 1}: `}
          <span>{TYPE_LABEL[question.type]}</span>
        </h4>
        <TextInputField name={`${fieldName}.path`} type="hidden" />
        <TextInputField
          name={`${fieldName}.label`}
          placeholder="Label"
          required
        />
        <TextInputField name={`${fieldName}.description`} placeholder="Optional description" />
        <RadioField
          name={`${fieldName}.required`}
          label="Is this required?"
          onChange={value => onChange(`${fieldName}.required`, value)}
          options={yesNoOptions}
          required
        />
        {question.type === TYPE.MONEY && (
          <RadioField
            name={`${fieldName}.payload.isRate`}
            label="Add the answer as a rate for the user?"
            onChange={value => onChange(`${fieldName}.payload.isRate`, value)}
            options={yesNoOptions}
            required
            sublabel={`Select 'yes' if you want to create a rate on the user's profile with the
              amount provided by the user`}
          />
        )}
        {question.type === TYPE.TEXT && (
          <RadioField
            name={`${fieldName}.payload.isTextArea`}
            label="Use multiple lines?"
            onChange={value => onChange(`${fieldName}.payload.isTextArea`, value)}
            options={yesNoOptions}
            required
          />
        )}
        {question.type === TYPE.SELECT && (
          <>
            <RadioField
              name={`${fieldName}.payload.multiple`}
              label="Allow selecting multiple answers?"
              onChange={value => onChange(`${fieldName}.payload.multiple`, value)}
              options={yesNoOptions}
              required
            />
            <MultitextField
              asOptions
              inputType={INPUT_TYPE.MARKDOWN_INPUT}
              label="Choices"
              maxLength={255}
              name={`${fieldName}.payload.choices`}
              onAutoIncrementUpdate={() => null}
              required
            />
            <RadioField
              name={`${fieldName}.payload.has_other`}
              label="Has 'Other' option?"
              onChange={value => onChange(`${fieldName}.has_other`, value)}
              options={yesNoOptions}
              required
            />
          </>
        )}
        {question.type === TYPE.FILE && (
          <>
            <CheckboxField
              name={`${fieldName}.payload.attachment_types`}
              label="Allowed attachment types:"
              options={ATTACHMENT_TYPES}
              wrapperClasses="attachment_types--options"
            />
            <RadioField
              name={`${fieldName}.payload.multiple`}
              label="Upload multiple files"
              onChange={value => onChange(`${fieldName}.payload.multiple`, value)}
              options={yesNoOptions}
              required
            />
            <RadioField
              name={`${fieldName}.payload.setExpiryDate`}
              label="Prompt the invitee to set the expiry date"
              onChange={value => onChange(`${fieldName}.payload.setExpiryDate`, value)}
              options={yesNoOptions}
              required
            />
            <FileUploaderField
              acceptAll
              name={`${fieldName}.payload.attachments_dump`}
              label="Optional file template"
              maxFiles={MAX_UPLOAD_FILES}
              path={uploaderInterviewPath(organizationId, interviewId)}
            />
          </>
        )}
        <RadioField
          name={`${fieldName}.answeredByUserType`}
          label="Should this be answered by a manager?"
          onChange={value => onChange(`${fieldName}.answeredByUserType`, value)}
          options={[
            { value: USER_TYPE.MANAGER, text: 'Yes' }, { value: USER_TYPE.PROVIDER, text: 'No' },
          ]}
          required
        />
        <CheckboxField
          name={`${fieldName}.payload.visibleTo`}
          label="Answer visible to:"
          options={VISIBLE_TO_OPTIONS}
          required
        />
      </div>
    </div>
  );
});

Question.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  interviewId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
};

Question.defaultProps = {
  interviewId: null,
};

const SortContainer = SortableContainer(({ children }) => children);

const InterviewFormConfigure = ({ match: { params: { interviewId } } }) => {
  const { change } = useForm();
  const { submitError, values: { questions } } = useFormState();

  const activeOrg = useSelector(selectActiveOrg);

  const questionsLength = Array.isArray(questions) ? questions.length : 0;
  const hasQuestions = questionsLength > 0;

  return (
    <div className="interview-builder">
      <TextInputField
        label="Name your template"
        name="name"
        placeholder="e.g. Freelance designers"
        required
      />
      <FieldArray name="questions">
        {({ fields }) => (
          <>
            {hasQuestions && (
              <SortContainer
                onSortEnd={({ newIndex, oldIndex }) => {
                  fields.move(oldIndex, newIndex);
                }}
                useDragHandle
              >
                <ul className="interview-builder__questions-list">
                  {fields.map((fieldName, index) => (
                    <Question
                      fieldIndex={index}
                      fieldName={fieldName}
                      index={index}
                      interviewId={interviewId}
                      key={fieldName}
                      onChange={change}
                      onRemove={() => {
                        fields.remove(index);
                      }}
                      organizationId={activeOrg.id}
                      question={questions[index]}
                    />
                  ))}
                </ul>
              </SortContainer>
            )}
            {!hasQuestions && (
              <div className="interview-builder__intro-message">
                Select any of the below elements to start building your customised template.
              </div>
            )}
            {submitError && <p className="text-danger">{submitError}</p>}
            <DocumentSelectField
              allowCountersigners={activeOrg.documents_with_countersigning_enabled}
              className="mb-3"
              showSelected
              sublabel={`Do you require invited team members to review and agree to any legal
                documents before obtaining access to the organization?`}
            />
            <div className="interview-builder__controls d-flex flex-nowrap">
              {TYPE_OPTIONS.map(({ icon, label, type }) => (
                <TDButton
                  block
                  key={type}
                  onClick={() => {
                    fields.insert(questionsLength, {
                      description: '',
                      id: questionsLength,
                      path: getCustomFieldPath(questionsLength),
                      payload: {},
                      type,
                    });
                  }}
                  variant={BS_STYLE.LINK}
                >
                  <span className={icon} />
                  {label}
                </TDButton>
              ))}
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

InterviewFormConfigure.propTypes = {
  match: routerMatchSpec.isRequired,
};

export default withRouter(InterviewFormConfigure);
