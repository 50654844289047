import { LAYOUTS } from 'core/assets/js/constants';

// Views
import InvitationView from 'invitations/assets/js/InvitationView.jsx';
import InvitationStatusView from 'invitations/assets/js/InvitationStatusView.jsx';
import InvitationRejectView from 'invitations/assets/js/InvitationRejectView.jsx';
import InvitationPublicView from 'invitations/assets/js/InvitationPublicView.jsx';
import {
  invitationPublicUrl, invitationRejectUrl, invitationStatusUrl, invitationManageUrl,
} from 'invitations/urls';


const invitationsRouteConfig = [
  {
    path: invitationRejectUrl(':token'),
    component: InvitationRejectView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
  },
  {
    path: invitationRejectUrl(':token', ':orgAlias'),
    component: InvitationRejectView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
  },
  {
    path: invitationStatusUrl(':token'),
    component: InvitationStatusView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
  },
  {
    path: invitationStatusUrl(':token', ':orgAlias'),
    component: InvitationStatusView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
  },
  {
    path: invitationManageUrl(':token'),
    component: InvitationView,
    exact: true,
    layout: LAYOUTS.WITH_HEADER,
    layoutProps: {
      title: 'Manage invitation',
    },
    requireAuth: true,
  },
  {
    path: invitationManageUrl(':token', ':orgAlias'),
    component: InvitationView,
    exact: true,
    layout: LAYOUTS.WITH_HEADER,
    layoutProps: {
      title: 'Manage invitation',
    },
    requireAuth: true,
    skipOrgSwitch: true,
  },
  {
    path: invitationPublicUrl(':token', ':alias'),
    requireAuth: false,
    component: InvitationPublicView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
    layoutProps: {
      showTalentdeskLogo: false,
      className: 'solo-container__box solo-container__box--flipped solo-container__box--compact',
    },
  },
];

export default invitationsRouteConfig;
