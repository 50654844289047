import React, { useState } from 'react';
import PropTypes from 'prop-types';

import withField from 'core/assets/js/components/withField.jsx';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';

const getInitialSelectedValues = (selectedOptions, value) => {
  if (selectedOptions && selectedOptions.length > 0) {
    return selectedOptions;
  }
  if (value && value.length > 0) {
    return value;
  }
  return [];
};

const CheckboxField = ({
  input, label, sublabel, className, additionalError, disabled,
  required, meta: { error, pristine, submitError, touched }, options, inputClassName,
  wrapperClasses, labelClassName, valueTransformer, selectedOptions,
}) => {
  const [
    selectedValues, setSelectedValues,
  ] = useState(getInitialSelectedValues(selectedOptions, input.value));

  const showAdditionalError = pristine && additionalError;
  const hasError = (touched && (!!error || !!submitError)) || showAdditionalError;
  const groupClassName = ['form-group'];

  if (hasError) {
    groupClassName.push('has-error');
  }
  if (wrapperClasses) {
    groupClassName.push(wrapperClasses);
  }

  const classNames = ['form-group selection-field'];

  if (className) {
    classNames.push(className);
  }

  if (inputClassName) {
    classNames.push(inputClassName);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  const handleChecked = (isChecked, option) => {
    const selectedOption = { value: option.value, text: option.raw || option.text };

    const newSelectedValues = isChecked
      ? [...selectedValues, selectedOption]
      : selectedValues.filter(v => v.value !== selectedOption.value);

    setSelectedValues(newSelectedValues);
    input.onChange(
      newSelectedValues.map(value => (valueTransformer ? valueTransformer(value) : value)),
    );

    return newSelectedValues;
  };

  const _options = options.map((option) => {
    const isChecked = Array.isArray(selectedValues)
      && selectedValues.some(sel => sel.value === option.value);

    const key = option.path
      ? `${option.path}-${option.value}`
      : option.value;

    const fieldId = `${input.name}-choice-${option.value}`;

    return (
      <div
        key={key}
        className="form-check mb-2"
      >
        <input
          data-testid="checkbox-form"
          id={fieldId}
          name={input.name}
          type="checkbox"
          value={option.value}
          className="form-check-input"
          checked={isChecked}
          onChange={e => handleChecked(e.target.checked, option)}
          disabled={disabled}
        />

        <label
          className="form-check-label ml-2"
          htmlFor={fieldId}
        >
          {option.text}
        </label>
      </div>
    );
  });

  const field = (
    <div
      className={classNames.join(' ')}
      id={`field-${input.name}`}
    >
      {_options}
    </div>
  );

  return (
    <div className={groupClassName.join(' ')}>
      {label && (
        <label htmlFor={input.name} className={labelClassName}>
          {label}
          {required && ' *'}
          {sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
      )}

      {showAdditionalError && <span className="help-block">{additionalError}</span>}
      {field}
      {hasError && <span className="help-block">{submitError || error}</span>}
    </div>
  );
};

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: finalFormFieldMetaSpec,
  label: finalFormFieldLabelSpec,
  sublabel: finalFormFieldLabelSpec,
  className: PropTypes.string,
  valueTransformer: PropTypes.func,
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  additionalError: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

CheckboxField.defaultProps = {
  meta: {
    error: '',
    submitError: '',
    pristine: true,
  },
  selectedOptions: [],
  valueTransformer: null,
  className: null,
  sublabel: null,
  label: '',
  required: false,
  disabled: false,
  inputClassName: '',
  labelClassName: '',
  additionalError: '',
  wrapperClasses: '',
};

export default withField(CheckboxField);
