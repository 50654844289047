import {
  stripeOpenCheckoutLinkApiUrl,
  stripeOpenUpdateLinkApiUrl,
  stripeCancelSubscriptionApiUrl,
} from 'services/urls';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';

const openStripeCheckoutLinkDS = (values, dispatch) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: stripeOpenCheckoutLinkApiUrl(),
    values,
  }))
);

const openStripeUpdateLinkDS = (values, dispatch) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: stripeOpenUpdateLinkApiUrl(),
    values,
  }))
);

const cancelStripeSubscriptionDS = (values, dispatch) => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: stripeCancelSubscriptionApiUrl(),
    values,
  }))
);

export {
  openStripeCheckoutLinkDS,
  openStripeUpdateLinkDS,
  cancelStripeSubscriptionDS,
};
