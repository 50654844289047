import React from 'react';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchOrgListDS } from 'organizations/assets/js/data-services/list';
import { isReactTest } from 'core/assets/js/config/checks';

const OrganizationsProvider = () => {
  if (isReactTest) {
    return null;
  }

  return (
    <TDApiConnected
      duck="list"
      fetchData={({ authedAxios, dispatch, isAuthenticated, isFirstRender, url }) => {
        if (isAuthenticated && isFirstRender) {
          return dispatch(fetchOrgListDS({ authedAxios, cached: false, url }));
        }
        return Promise.resolve();
      }}
      loadingEnabled={false}
      orgSpecific={false}
      shouldRefetchOnQueryChange={false}
      storeKey="OrganizationsProvider"
    />
  );
};

export default OrganizationsProvider;
