import queryString from 'query-string';

export const twTransferUrl = id => (
  `https://transferwise.com/send/#/transfer/${id}`
);

// TODO - remove queryParams !!!
export const twCurrencyRequirementsUrl = (currency, { url = '', queryParams } = {}) => {
  if (queryParams) {
    return `${url}/api/tw/currency-requirements/${currency}?${queryString.stringify(queryParams)}`;
  }
  return `${url}/api/tw/currency-requirements/${currency}`;
};

export const twCountryCurrencyValidationApiUrl = (currency, countryCode, { url = '' }) => (
  `${url}/api/tw/validate-country/${currency}/${countryCode}`
);

export const statusApiUrl = ({ url } = { url: '' }) => (
  `${url}/health`
);

// ---- Payoneer -----

export const payoneerCreateRegistrationApiUrl = ({ url = '' } = {}) => (
  `${url}/api/payoneer/create-registration-link`
);

// Stripe

export const stripeOpenCheckoutLinkApiUrl = ({ url = '' } = {}) => (
  `${url}/api/stripe/open-checkout-link`
);

export const stripeOpenUpdateLinkApiUrl = ({ url = '' } = {}) => (
  `${url}/api/stripe/open-update-link`
);

export const stripeCancelSubscriptionApiUrl = ({ url = '' } = {}) => (
  `${url}/api/stripe/cancel-subscription`
);
