import React from 'react';
import PropTypes from 'prop-types';

import CircleSkeleton from './CircleSkeleton.jsx';
import RectangleSkeleton from './RectangleSkeleton.jsx';


const InterviewCardSkeleton = ({ variation }) => {
  switch (variation) {
    case '1':
    default:
      return (
        <div className="skeleton--project-list-item clearfix">
          <div className="float-left">
            <RectangleSkeleton width="183" height="20" />
            <RectangleSkeleton width="100" height="20" />
            <CircleSkeleton diameter="20" />
            <br />
            <RectangleSkeleton width="80" height="10" />
          </div>
        </div>
      );

    case '2':
      return (
        <div className="skeleton--project-list-item clearfix">
          <div className="float-left">
            <RectangleSkeleton width="90" height="15" />
            <RectangleSkeleton width="70" height="15" />
            <RectangleSkeleton width="80" height="15" />
            <br />
            <br />
            <RectangleSkeleton width="220" height="20" />
            <RectangleSkeleton width="80" height="20" />
            <RectangleSkeleton width="110" height="20" />
            <CircleSkeleton diameter="15" />
            <br />
            <RectangleSkeleton width="80" height="10" />
            <RectangleSkeleton width="40" height="10" />
          </div>
        </div>
      );
  }
};


InterviewCardSkeleton.propTypes = {
  variation: PropTypes.string,
};

InterviewCardSkeleton.defaultProps = {
  variation: '1',
};


export default InterviewCardSkeleton;
