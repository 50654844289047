import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { orgSettingsSkillsUrl } from 'settings/urls';
import { SETTINGS_SKILL_TABS } from 'settings/assets/js/constants';
import TabBar from 'core/assets/js/components/TabBar.jsx';

/**
 * This is the tabs component we use on Org Settings -> Skills
 * @returns {*}
 */
const ManageSkillsTabs = ({ tab, match: { params: { orgAlias } } }) => {
  const tabSpec = [
    {
      key: SETTINGS_SKILL_TABS.SKILLS,
      label: 'Custom skills',
      href: orgSettingsSkillsUrl(orgAlias, SETTINGS_SKILL_TABS.SKILLS),
    },
    {
      key: SETTINGS_SKILL_TABS.SKILL_DIRECTORIES,
      label: 'Skill directories',
      href: orgSettingsSkillsUrl(orgAlias, SETTINGS_SKILL_TABS.SKILL_DIRECTORIES),
    },
  ];

  return (
    <div className="horizontal-scroll">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

ManageSkillsTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ManageSkillsTabs);
