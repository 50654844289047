import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { createInvitationTemplateDS } from 'settings/assets/js/data-services/settings';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import OrgInvitationTemplateForm from 'settings/assets/js/components/OrgInvitationTemplateForm.jsx';
import { orgTemplatesManageUrl } from 'settings/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';


function InvitationTemplateCreateView({ match, history, dispatch }) {
  const { orgAlias } = match.params;
  const orgManageInvitationTemplatesUrl = orgTemplatesManageUrl(orgAlias);
  const breadcrumbs = [
    {
      title: 'Templates',
      url: orgManageInvitationTemplatesUrl,
    },
    {
      title: 'Org Invitations',
      url: orgManageInvitationTemplatesUrl,
    },
    {
      title: 'Create template',
      url: null,
    },
  ];

  const onsubmit = (values) => {
    return dispatch(createInvitationTemplateDS({ orgAlias, values })).then(() => {
      history.push(orgTemplatesManageUrl(orgAlias));
      toastr.success('Well Done!', 'Invitation template created successfully.');
    });
  };

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--invitation-template-create">
        <div className="container">
          <OrgInvitationTemplateForm
            onSubmit={onsubmit}
            submitLabel="Create"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
InvitationTemplateCreateView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const InvitationTemplateCreateViewConnected = connect(
  null,
  mapDispatchToProps,
)(InvitationTemplateCreateView);

export default withRouter(InvitationTemplateCreateViewConnected);
