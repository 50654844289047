import React, { useEffect } from 'react';
import { DOCUMENT_BODY_APPEND_CHILD, DOCUMENT_CREATE_ELEMENT } from 'core/assets/js/config/settings';

const UpgradeTrialModal = () => {
  useEffect(() => {
    const hsScript = DOCUMENT_CREATE_ELEMENT('script');
    hsScript.type = 'text/javascript';
    hsScript.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';

    DOCUMENT_BODY_APPEND_CHILD(hsScript);
  }, []);

  return (
    <div
      className="meetings-iframe-container"
      data-src="https://meetings.hubspot.com/katie267/free-trial?embed=true"
    />
  );
};

export default UpgradeTrialModal;
