import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import DateRangeFilterField from 'core/assets/js/components/FinalFormFilterFields/DateRangeFilterField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import { ORDERING_DIRECTION } from 'core/assets/js/constants';
import { DOCUMENT_STATUS_LABEL, DOCUMENT_STATUS_VALUES } from 'documents/assets/js/constants';

const DocumentsSearch = ({ filtersOpen, location: { search }, onFiltersToggle }) => {
  const searchSpec = {
    defaultOrdering: { direction: ORDERING_DIRECTION.DESC, sortBy: 'createdAt' },
    filters: [
      {
        fieldComponent: SelectableListFilterField,
        label: 'Status',
        multiple: true,
        options: DOCUMENT_STATUS_VALUES.map(value => ({
          text: DOCUMENT_STATUS_LABEL[value], value,
        })),
        paramName: 'status',
      },
      {
        className: 'col-6 col-sm-4 col-md-4 col-lg-3 mb-4',
        fieldComponent: DateRangeFilterField,
        label: 'Date Created',
        paramName: 'createdAt',
      },
    ],
    orderingOptions: [{ text: 'Created at', value: 'createdAt' }],
    searchTerm: { component: TextInputField, paramName: 'kw', placeholder: 'Search contract templates' },
  };

  const initialValues = pick(
    queryString.parse(search), ['kw'].concat(searchSpec.filters.map(f => f.paramName)),
  );

  return (
    <SearchFinalForm
      filtersOpen={filtersOpen}
      initialValues={initialValues}
      name="contract templates"
      onFiltersToggle={onFiltersToggle}
      searchSpec={searchSpec}
    />
  );
};

DocumentsSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(DocumentsSearch);
