import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TaskProgressBar = ({ id, progress, tooltip, className, statusClass }) => {
  const showProgress = progress !== null;
  const progressPercent = showProgress ? parseInt(progress, 10) : 100;
  const progressTooltip = (
    <Tooltip id={`task-progress-tooltip-${id}`}>
      { `${progressPercent}%` }
    </Tooltip>
  );

  const cssClasses = `task-progress-bar td-progress-bar__wrapper ${className}`.trim();

  return (
    <div className={cssClasses}>
      <div
        className={`td-progress-bar td-progress-bar--${statusClass}`}
        style={{ width: `${progressPercent}%` }}
      >
        {showProgress && (
          <React.Fragment>
            {tooltip && (
              <OverlayTrigger
                placement="top"
                overlay={progressTooltip}
              >
                <span className="indicator" />
              </OverlayTrigger>
            )}
            {!tooltip && <span className="indicator" />}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

TaskProgressBar.propTypes = {
  id: PropTypes.number.isRequired,
  tooltip: PropTypes.bool,
  progress: PropTypes.number,
  className: PropTypes.string,
  statusClass: PropTypes.string,
};

TaskProgressBar.defaultProps = {
  progress: null,
  tooltip: true,
  className: '',
  statusClass: null,
};

export default TaskProgressBar;
