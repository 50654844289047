/* globals File */
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bytes from 'bytes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { downloadFileApiUrl, getFileApiUrl } from 'files/urls';
import { ICON, MIME_TYPES, BS_TOOLTIP_PLACEMENT } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import FilePreview from 'core/assets/js/components/FileUploader/FilePreview.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { fileToDataUrl } from 'core/assets/js/lib/utils';

const Filebox = ({ clickHandler, file, invertedColors, additionalActions, showDownloadButton }) => {
  const [state, setState] = useState({
    fileName: file?.filename, mimeType: file?.mimetype, size: file?.size, url: file?.url,
  });
  const { fileName, mimeType, size, url } = state;

  const handleError = e => toastr.error('Oh Snap!', e._error || e.message);
  useEffect(() => {
    if (file?.file instanceof File && /^image\//.test(file.mimetype)) {
      fileToDataUrl(file.file)
        .then(newUrl => {
          setState({ ...state, url: newUrl });
        })
        .catch(handleError);
      return;
    }
    if (['string', 'number'].includes(typeof file)) {
      axios
        .get(getFileApiUrl(file))
        .then(({ data }) => {
          setState({
            ...state, ...pick(data, 'fileName', 'mimeType', 'size'), url: downloadFileApiUrl(file),
          });
        })
        .catch(handleError);
    }
  }, [file]);

  const isImage = MIME_TYPES.IMAGES.includes(mimeType);
  const filePreviewClasses = ['fileuploader-item__icon-wrapper', 'float-left'];
  if (isImage) {
    filePreviewClasses.push('fileuploader-item__icon-wrapper--image');
  }

  const deleteTooltip = (
    <Tooltip id={`tooltip__delete-item-${url}`}>
      Delete this file
    </Tooltip>
  );
  const classNames = ['fileuploader-item'];

  if (invertedColors) {
    classNames.push('fileuploader-item--inverted-colors');
  }

  return (
    <div key={url} className={classNames.join(' ')}>
      { clickHandler && (
        <OverlayTrigger placement="top" overlay={deleteTooltip}>
          <i
            data-testid="button-remove-file"
            className={` ${ICON.CROSS} fileuploader-item__remove`}
            onClick={() => {
              clickHandler(file);
            }}
          />
        </OverlayTrigger>
      )}

      {url && showDownloadButton && (
        <a
          href={url}
          className="fileuploader-item__download"
          rel="noopener noreferrer"
          target="_blank"
          download
        >
          <TDElementWithTooltip
            el={<i className={ICON.DOWNLOAD} />}
            placement={BS_TOOLTIP_PLACEMENT.TOP}
            tooltipMsg="Download"
          />
        </a>
      )}

      {additionalActions && (
        <div className="fileuploader-item__additional-actions">
          {additionalActions}
        </div>
      )}

      <FilePreview className={filePreviewClasses.join(' ')} mimeType={mimeType} url={url} />

      <div
        data-testid="uploaded-file"
        className={`fileuploader-item__meta${invertedColors ? ' fileuploader-item--invertedColors' : ''}`}
      >
        {url && (
          <a href={url} rel="noopener noreferrer" target="_blank" download>
            {fileName}
          </a>
        )}
        {!url && <span>{fileName}</span>}
        <br />
        <span className="fileuploader-item__size">{bytes(size)}</span>
      </div>
    </div>
  );
};

Filebox.propTypes = {
  additionalActions: PropTypes.node,
  clickHandler: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  invertedColors: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
};

Filebox.defaultProps = {
  additionalActions: null,
  clickHandler: null,
  invertedColors: false,
  showDownloadButton: true,
};

export default Filebox;
