import React from 'react';
import PropTypes from 'prop-types';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';


export class FieldSelect extends React.PureComponent {
  render() {
    const {
      disabled, name, value, onChange, optionsMapping, inputClassName, defaultOptionText,
    } = this.props;
    const options = optionsMapping.map((option) => {
      const key = option.path
        ? `${option.path}-${option.value}`
        : option.value;

      return (
        <option key={key} value={option.value} disabled={option.disabled}>{option.text}</option>
      );
    });

    return (
      <div className="form-control-container">
        <select
          id={`fieldId-${name}`}
          name={name}
          value={value}
          className={['form-control', inputClassName].join(' ')}
          onChange={onChange}
          disabled={disabled}
        >
          {defaultOptionText && (
            <option key="please-select" value="">{defaultOptionText}</option>
          )}
          { options }
        </select>
      </div>
    );
  }
}
FieldSelect.propTypes = {
  defaultOptionText: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  optionsMapping: PropTypes.array.isRequired,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FieldSelect.defaultProps = {
  defaultOptionText: 'Select option',
  disabled: false,
  name: 'select',
  inputClassName: '',
  onChange: () => {},
  value: null,
};

/**
 * Redux form compatible select field
 *
 * It displays a decorated input field with a label and an error message
 */
function SelectField({
  additionalError,
  'data-testid': dataTestId,
  defaultOptionText,
  disabled,
  input,
  inputClassName,
  label,
  labelClassName,
  meta: { error, touched },
  optionsMapping,
  required,
  sublabel,
  wrapperClasses,
}) {
  const hasError = (error && touched) || additionalError;
  const groupClassName = ['form-group'];

  if (hasError) {
    groupClassName.push('has-error');
  }
  if (wrapperClasses) {
    groupClassName.push(wrapperClasses);
  }

  return (
    <div className={groupClassName.join(' ')} data-testid={dataTestId}>
      { label && (
        <label htmlFor={`fieldId-${input.name}`} className={labelClassName}>
          {label}
          {required && ' *'}
          {sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
      )}
      {additionalError && <span className="help-block">{additionalError}</span>}
      <FieldSelect
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        optionsMapping={optionsMapping}
        inputClassName={inputClassName}
        labelClassName={labelClassName}
        disabled={disabled || false}
        defaultOptionText={defaultOptionText}
      />
      {hasError && <span className="help-block mt-3">{error}</span>}
    </div>
  );
}

SelectField.propTypes = {
  additionalError: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultOptionText: PropTypes.string,
  disabled: PropTypes.bool,
  input: reduxInputSpec.isRequired,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool }),
  optionsMapping: PropTypes.array,
  required: PropTypes.bool,
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  wrapperClasses: PropTypes.string,
};

SelectField.defaultProps = {
  additionalError: '',
  'data-testid': '',
  defaultOptionText: 'Choose option',
  disabled: false,
  inputClassName: '',
  label: '',
  labelClassName: '',
  meta: { error: false, touched: false },
  optionsMapping: [],
  required: false,
  sublabel: '',
  wrapperClasses: '',
};

export default SelectField;
