import React from 'react';

import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { BS_TOOLTIP_PLACEMENT } from 'core/assets/js/constants';

const TalentMarketIndicator = () => (
  <TDElementWithTooltip
    delay={200}
    placement={BS_TOOLTIP_PLACEMENT.RIGHT}
    tooltipMsg="TalentMarket"
  >
    <span className="talent-market-indicator">TM</span>
  </TDElementWithTooltip>
);

export default TalentMarketIndicator;
