import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import {
  INTEGRATION_AVAILABILITY_STATUS,
  INTEGRATION_AVAILABILITY_STATUS_CLASS,
  INTEGRATION_AVAILABILITY_STATUS_LABEL,
  INTEGRATION_CANNOT_BE_ADDED_REASON,
  INTEGRATION_CANNOT_BE_ADDED_REASON_DESCRIPTION,
} from 'integrations/assets/js/constants';
import { orgIntegrationConnectUrl } from 'integrations/urls';

const AvailableIntegrationCard = ({ config, history, orgAlias }) => {
  const {
    availability, cannotBeAdded, cannotBeAddedReason, description, logo, name, type,
  } = config;
  const disabled = cannotBeAdded || availability === INTEGRATION_AVAILABILITY_STATUS.COMING_SOON;
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <Card
        className={`integration-card${disabled ? ' disabled' : ''}`}
        key={type}
        onClick={() => {
          if (disabled) {
            return;
          }
          history.push(orgIntegrationConnectUrl(orgAlias, type));
        }}
      >
        <div className="integration-card-img d-flex justify-content-center align-items-center">
          <Card.Img variant="top" src={logo} />
        </div>
        <Card.Body>
          <Card.Title as="h3">
            {name}
            {!cannotBeAdded && availability !== INTEGRATION_AVAILABILITY_STATUS.ACTIVE && (
              <span
                className={(
                  [
                    'ml-3',
                    'status',
                    'status--tag',
                    `status--${INTEGRATION_AVAILABILITY_STATUS_CLASS[availability]}`,
                  ].join(' ')
                )}
              >
                {INTEGRATION_AVAILABILITY_STATUS_LABEL[availability]}
              </span>
            )}
          </Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
        {cannotBeAdded && (
          <Card.Footer className="px-4">
            {cannotBeAddedReason
            === INTEGRATION_CANNOT_BE_ADDED_REASON.CODAT_INTEGRATION_ORG_NOT_VIA && (
              <span className="status status--tag status--pending d-block text-center">
                {INTEGRATION_CANNOT_BE_ADDED_REASON_DESCRIPTION[cannotBeAddedReason]}
              </span>
            )}
            {!cannotBeAddedReason
            === INTEGRATION_CANNOT_BE_ADDED_REASON.CODAT_INTEGRATION_ORG_NOT_VIA && (
              <span className="danger text-danger">
                {INTEGRATION_CANNOT_BE_ADDED_REASON_DESCRIPTION[cannotBeAddedReason]}
              </span>
            )}
          </Card.Footer>
        )}
      </Card>
    </div>
  );
};

AvailableIntegrationCard.propTypes = {
  config: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

export default AvailableIntegrationCard;
