import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { selectAuthenticated } from 'accounts/assets/js/reducers/auth';
import SiteWideBanner from 'core/assets/js/components/SiteWideBanner.jsx';
import Storage from 'core/assets/js/lib/Storage';
import axios from 'core/assets/js/lib/tdAxios';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgGetSiteWideBannersApiUrl } from 'organizations/urls';

const localStoragePrefix = 'site-wide-banners_hide_';

const SiteWideBannersProvider = () => {
  const { alias } = useSelector(selectActiveOrg);
  const isAuthenticated = useSelector(selectAuthenticated);
  const [banners, setBanners] = useState([]);
  const [hideBannerIds, setHideBannerIds] = useState([]);

  useEffect(() => {
    if (!isAuthenticated || !alias) {
      setBanners([]);
      setHideBannerIds([]);
      return;
    }
    axios
      .get(orgGetSiteWideBannersApiUrl(alias))
      .then(({ data }) => {
        if (!Array.isArray(data)) {
          throw new Error('banners response should be an array');
        }
        setBanners(data);
        data.forEach(banner => {
          if (banner.showAfterDismissed) {
            return;
          }
          const isHidden = Storage.get(`${localStoragePrefix}${banner.id}`);
          if (isHidden) {
            setHideBannerIds([...hideBannerIds, banner.id]);
          }
        });
      })
      .catch(error => {
        toastr.error(error.message);
      });
  }, [alias, isAuthenticated]);

  const finalBanners = banners.filter(b => !hideBannerIds.includes(b.id));

  if (finalBanners.length === 0) {
    return null;
  }

  const onClose = bannerId => {
    const banner = banners.find(b => b.id === bannerId);
    if (!banner) {
      return;
    }
    if (!banner.showAfterDismissed) {
      Storage.set(`${localStoragePrefix}${bannerId}`, true);
    }
    setHideBannerIds([...hideBannerIds, bannerId]);
  };

  return (
    <div className="site-wide-banners d-flex flex-column w-100">
      {finalBanners.map(banner => (
        <SiteWideBanner banner={banner} key={banner.id} onClose={onClose} />
      ))}
    </div>
  );
};

export default SiteWideBannersProvider;
