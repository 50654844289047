import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Form } from 'react-final-form';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';

const ModalConfirmFinalForm = ({
  confirmButtonDisabled, FormContentComponent, formProps, open, ...rest
}) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (!open && formRef.current) {
      // unset if the modal is closed, so the Form's render will set it to the correct
      // form instance when next opened
      formRef.current = null;
    }
  }, [open]);

  return (
    <ModalConfirm
      body={(
        <Form
          render={renderProps => {
            const { form, handleSubmit } = renderProps;
            if (!formRef.current) {
              // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is
              // broken https://github.com/final-form/react-final-form/issues/483
              formRef.current = form;
            }
            return (
              <form onSubmit={handleSubmit}>
                <FormContentComponent renderProps={renderProps} />
              </form>
            );
          }}
          {...formProps}
        />
      )}
      closeOnConfirm={false}
      confirmButtonDisabled={formRef.current?.getState().submitting || confirmButtonDisabled}
      onConfirm={async () => formRef.current?.submit()}
      open={open}
      {...rest}
    />
  );
};

ModalConfirmFinalForm.propTypes = {
  confirmButtonDisabled: PropTypes.bool,
  FormContentComponent: PropTypes.func.isRequired,
  formProps: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

ModalConfirmFinalForm.defaultProps = {
  confirmButtonDisabled: false,
};

export default ModalConfirmFinalForm;
