import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TDSelect from 'core/assets/js/components/TDSelect.jsx';
import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import { BS_SIZE } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE,
  WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_LABEL,
  WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_VALUES,
} from 'projects/assets/js/constants';
import OrgSettingContainer from 'settings/assets/js/components/OrgSettingContainer.jsx';
import { postOrganizationDS } from 'settings/assets/js/data-services/organizations';

const options = WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_VALUES.map(value => ({
  text: WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_LABEL[value], value,
}));

const OrgTimeTrackerSettingsTab = () => {
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();

  const handleSettingsChanged = changes => dispatch(postOrganizationDS(activeOrg.alias, changes));

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <h3 className="heading-block">Time tracker settings</h3>
      <div className="settings-row">
        <TDToggleWithConfirmationModal
          disableHeader="Disable time tracker"
          DisableModalBody={() => (
            <>
              <p>
                By disabling this functionality, providers will no longer be able to use the time
                tracker with tasks.
              </p>
              <p className="mb-4">
                Are you sure you want to deactivate the time tracker?
              </p>
            </>
          )}
          enableHeader="Enable time tracker"
          EnableModalBody={() => (
            <>
              <p>
                By enabling this functionality, providers will be able to use the time tracker with
                tasks.
              </p>
              <p className="mb-4">
                Are you sure you want to enable the time tracker?
              </p>
            </>
          )}
          label="Enable time tracker"
          modalId="time-tracker-enabled"
          onToggled={selected => handleSettingsChanged({ time_tracker_enabled: selected })}
          popOverContent={(
            <>
              <p>
                When enabled, a new feature will become available on the navigation menu for
                Providers. If the Provider is part of an active Task for which their rate is
                Per-Hour, they will be able to select Task and start the timer. When they complete
                their work on the Task they need to press stop on the timer. The Time Tracker
                calculates their earnings for the time worked based on their hourly rate.
              </p>
              <p>
                Once the Provider has completed all their tracked work times for the current billing
                period, they can press the Create Worksheet button for the Project and all the
                tracked time will be automatically converted to a Worksheet and sent for managerial
                approval.
              </p>
            </>
          )}
          popOverTitle="How does it work?"
          selected={activeOrg.time_tracker_enabled}
          sublabel={(
            <>
              Allow Providers to track the time they work on Tasks using a stopwatch. Tracked times
              are converted to Worksheets
            </>
          )}
        />
        {activeOrg.time_tracker_enabled && (
          <OrgSettingContainer
            label="Time tracker worksheet creation mode"
            modalId="time-tracker-worksheet-creation-mode"
            popOverContent={(
              <>
                <p>
                  When a Provider has tracked one or more work times on Tasks, they will be able to
                  create a Worksheet based on the tracked times. We provide 3 options for how these
                  Worksheets will be created, choose the one that best suits your business needs:
                </p>
                <b>
                  {WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_LABEL[
                    WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE.ONE_PER_PROJECT
                  ]}
                </b>
                <p>
                  For each Project that the Provider has recorded Task work for, a single Worksheet
                  will be created for all the tracked times.
                </p>
                <b>
                  {WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_LABEL[
                    WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE.ONE_PER_PROJECT_PER_DAY
                  ]}
                </b>
                <p>
                  If a Provider has recorded multiple times for Project Tasks in a given day, these
                  recorded times will be converted to individual Worksheets for each day of the
                  week that they have recorded work on. Worksheets are Project-specific, so if the
                  Provider has recorded time on different Projects on the same day, a Worksheet will
                  be created for each Project.
                </p>
                <b>
                  {WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_LABEL[
                    WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE.ONE_PER_WORK_TIME_BLOCK
                  ]}
                </b>
                <p>
                  This option will create the most number of Worksheets. For every recorded time
                  that a Provider has, a Worksheet will be created for each.
                </p>
                <b>Note</b>
                <p>
                  For each of the grouping modes, it will still be possible for Providers to create
                  additional Worksheets if they record new tracked time after they have created
                  their Worksheets in a given billing period. The next time they press the Create
                  Worksheet button, a new Worksheet for the new recorded times will be created.
                </p>
                <p>
                  Additionally, Providers have the ability to manually create a Worksheet per
                  individual recorded time.
                </p>
              </>
            )}
            popOverSize={BS_SIZE.LARGE}
            popOverTitle="Time tracker settings"
            subLabel="How would you like to default the creation of Worksheets from tracked time?"
          >
            <TDSelect
              onChange={newValue => handleSettingsChanged({
                time_tracker_worksheet_creation_mode: newValue,
              })}
              options={options}
              value={activeOrg.time_tracker_worksheet_creation_mode}
            />
          </OrgSettingContainer>
        )}
      </div>
    </div>
  );
};

export default OrgTimeTrackerSettingsTab;
