import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

const MarkdownText = ({ className, disallowedTypes, text, withBreaksPlugin, withGFMPlugin }) => {
  const plugins = [];
  const components = {};

  if (withBreaksPlugin) {
    plugins.push(breaks);
  }
  if (withGFMPlugin) {
    plugins.push(remarkGfm);
  }
  if (disallowedTypes.includes('paragraph')) {
    /*
      For some reason having `paragraph` in disallowedElements with react-markdown 6.0.3 doesn't
      work. So instead use `components` to replace it with an empty wrapper.
    */
    components.p = Fragment;
  }
  return (
    <ReactMarkdown
      className={`markdown-content ${className}`}
      components={components}
      disallowedElements={disallowedTypes}
      linkTarget="_blank"
      remarkPlugins={plugins}
      skipHtml
      unwrapDisallowed
    >
      {text}
    </ReactMarkdown>
  );
};

MarkdownText.propTypes = {
  className: PropTypes.string,
  disallowedTypes: PropTypes.array,
  text: PropTypes.string.isRequired,
  withBreaksPlugin: PropTypes.bool,
  withGFMPlugin: PropTypes.bool,
};

MarkdownText.defaultProps = {
  className: '',
  disallowedTypes: [],
  withBreaksPlugin: false,
  withGFMPlugin: true,
};

export default MarkdownText;
