import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { ICON, LOGO } from 'core/assets/js/constants';
import AORFeeScheme from 'finance/assets/js/lib/fees/AORFeeScheme';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import { USER_EMPLOYMENT_TYPE } from 'people/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { SERVICE_KEY_NAME } from 'finance/assets/js/constants';

const DEFAULT_AOR_FEE_SCHEME = AORFeeScheme.getDefault();
const DEFAULT_AOR_PER_SEAT_CURRENCY_SYMBOL = DEFAULT_AOR_FEE_SCHEME.getCurrencySymbol();
const DEFAULT_AOR_PER_SEAT_VALUE = DEFAULT_AOR_FEE_SCHEME.getPerAORSeat();

const AOR_OPTIONS = {
  DISABLED: 1,
  ENABLED: 2,
};

const AOROption = ({ centerText, item: { cost, logo, name, popular, text, title } }) => (
  <div className={`d-flex ${centerText ? 'flex-column align-items-stretch' : ''}`}>
    {logo && (
      <div
        className={`logo d-flex ${centerText ? 'centered mb-3' : 'mr-3'}`}
        style={{ backgroundImage: `url(${logo})` }}
      />
    )}
    <div className={`d-flex flex-column align-items-${centerText ? 'center' : 'start'} justify-content-center`}>
      <span className="font-weight-bold mb-2">{name}</span>
      <span className="discreet font-weight-bold">{title}</span>
      <p className={centerText ? 'text-center' : ''}>{text}</p>
      <span className="cost font-weight-bold">{cost}</span>
    </div>
    {popular && (
      <div className="popular font-weight-bold">
        <i className={`${ICON.STAR_FILLED} mr-2`} />
        Most popular
      </div>
    )}
  </div>
);

AOROption.propTypes = {
  centerText: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    cost: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    logo: PropTypes.string,
    name: PropTypes.string.isRequired,
    popular: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const InvitePeopleAorSelector = ({ centerText, employmentType, titleElement }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const { submitErrors, values } = useFormState();
  const { change } = useForm();
  const isEmployeeUserType = employmentType === USER_EMPLOYMENT_TYPE.EMPLOYEE;

  const showAorChoice = (
    !isEmployeeUserType
    && activeOrg.aor_onboarding_invitations_enabled
    && !activeOrg.all_contractors_are_aor
  );

  if (!showAorChoice) {
    return null;
  }

  let aorPerSeatCurrencySymbol = DEFAULT_AOR_PER_SEAT_CURRENCY_SYMBOL;
  let aorPerSeatValue = DEFAULT_AOR_PER_SEAT_VALUE;

  if (activeOrg.invoicing_fee_scheme) {
    const aorFeeScheme = new InvoicingFeeScheme(activeOrg.invoicing_fee_scheme)
      .getLicenceScheme()
      ._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.AOR);
    if (!aorFeeScheme.isEmpty()) {
      aorPerSeatCurrencySymbol = aorFeeScheme.getCurrencySymbol();
      aorPerSeatValue = aorFeeScheme.getPerAORSeat();
    }
  }

  const aorOptions = [
    {
      cost: 'FREE',
      id: AOR_OPTIONS.DISABLED,
      logo: activeOrg.logo,
      name: activeOrg.name,
      popular: false,
      text: 'You sign your own contract directly with the contractor',
      title: 'You sign',
    },
    {
      cost: (
        <>
          <NumberTpl prefix={aorPerSeatCurrencySymbol} value={aorPerSeatValue} />
          {' per seat per month'}
        </>
      ),
      id: AOR_OPTIONS.ENABLED,
      logo: LOGO.SMALL,
      name: 'TalentDesk',
      popular: true,
      text: `We act as Agent of Record (AOR) and provide and sign the contract
        with the contractor.`,
      title: 'We sign',
    },
  ];

  const TitleElement = titleElement;

  return (
    <>
      <TitleElement>Who will be handling the contract?</TitleElement>
      <CheckableList
        className={`invite-aor-checkable-list${centerText ? ' centered' : ''}`}
        initialValue={(
          values.aor !== undefined
            ? { id: values.aor ? AOR_OPTIONS.ENABLED : AOR_OPTIONS.DISABLED }
            : null
        )}
        items={aorOptions}
        itemComponent={AOROption}
        itemComponentProps={{ centerText }}
        onItemChecked={value => {
          change('aor', value.id === AOR_OPTIONS.ENABLED ? 1 : 0);
        }}
      />
      {submitErrors?.aor && (
        <div className="clearfix mt-3 text-danger">{submitErrors.aor}</div>
      )}
    </>
  );
};

InvitePeopleAorSelector.propTypes = {
  centerText: PropTypes.bool,
  employmentType: PropTypes.oneOf(Object.values(USER_EMPLOYMENT_TYPE)),
  titleElement: PropTypes.string,
};

InvitePeopleAorSelector.defaultProps = {
  centerText: false,
  employmentType: null,
  titleElement: 'h4',
};

export default InvitePeopleAorSelector;
