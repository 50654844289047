import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { BS_STYLE } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';

const ProviderBillingDetailsForm = ({ modalState, onSubmit, initialValues }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <div className="d-inline">
            <span>
              <span onClick={modalState.open} className="imitate-link">Edit</span>
            </span>
            <ModalSimple
              open={modalState.isOpen}
              heading="Update additional billing details"
              body={(
                <form
                  className="p-0"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-12">
                      <TextAreaField
                        label="Additional billing details"
                        name="free_billing_details_text"
                        extraInfo='The additional information provided will appear on your invoice beneath your billing details. You can enter any information that is required by your jurisdiction - e.g Your "PAN No" if you are in India. It will be formatted as input. You can change it at any time.'
                      />
                    </div>
                  </div>
                </form>
              )}
              onClose={modalState.close}
              footer={(
                <TDButton
                  onClick={() => {
                    handleSubmit();
                    modalState.close();
                  }}
                  floatRight
                  className="col-6 col-sm-4"
                  type="submit"
                  variant={BS_STYLE.SUCCESS}
                  label="Update"
                  disabled={submitting}
                />
              )}
            />
          </div>
        );
      }}
    />
  );
};

ProviderBillingDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  modalState: modalStateSpec.isRequired,
};

ProviderBillingDetailsForm.defaultProps = {
  initialValues: {},
};

export default withStateModal(ProviderBillingDetailsForm);
