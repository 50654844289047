import React from 'react';
import PropTypes from 'prop-types';

const TDButtonGroup = ({
  className, primaryButton, primaryButtonAction, secondaryButton, secondaryButtonAction,
  secondaryButtonActive, primaryButtonActive,
}) => {
  const classNames = ['td-btn-group btn-group'];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} role="group">
      <button
        type="button"
        className={`btn btn-primary px-4 ${primaryButtonActive ? 'btn--active' : ''}`}
        onClick={primaryButtonAction}
      >
        {primaryButton}
      </button>
      <button
        type="button"
        className={`btn btn-primary px-4 ${secondaryButtonActive ? 'btn--active' : ''}`}
        onClick={secondaryButtonAction}
      >
        {secondaryButton}
      </button>
    </div>
  );
};

TDButtonGroup.propTypes = {
  className: PropTypes.string,
  primaryButton: PropTypes.node.isRequired,
  primaryButtonAction: PropTypes.func,
  primaryButtonActive: PropTypes.bool,
  secondaryButton: PropTypes.node,
  secondaryButtonAction: PropTypes.func,
  secondaryButtonActive: PropTypes.bool,
};

TDButtonGroup.defaultProps = {
  className: null,
  primaryButtonAction: () => {},
  primaryButtonActive: false,
  secondaryButton: null,
  secondaryButtonAction: () => {},
  secondaryButtonActive: false,
};

export default TDButtonGroup;
