import React from 'react';
import { withRouter } from 'react-router-dom';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { settingsPaymentsSubPageUrl, settingsPayoneerBankAccountCancelApiUrl } from 'settings/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import PayoneerStatus from 'settings/assets/js/components/payoneer-components/PayoneerStatus.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { pushViewDS } from 'core/assets/js/ducks/view';

const componentName = 'ProfileManagePayoneerCancelView';

/**
 * We are using this view when the user is redirected from the Payoneer registration process
 * after cancelling it.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ProfileManagePayoneerCancelView(props) {
  const { history, match: { params: { orgAlias } } } = props;
  const breadcrumbs = [
    {
      title: 'Payments',
      url: settingsPaymentsSubPageUrl(orgAlias),
    },
    {
      title: 'Payment method status',
      url: null,
    },
  ];
  return (
    <React.Fragment>
      <ContentHeader
        breadcrumbs={breadcrumbs}
      />
      <div className="page page--settings bank-account--payoneer-status">
        <div className="container">
          <TDApiConnected
            duck="view"
            name={componentName}
            skeletonComponent={SettingsPageSkeleton}
            fetchData={({ dispatch, params, url }) => (
              dispatch(pushViewDS({
                url: settingsPayoneerBankAccountCancelApiUrl(params.bankAccountId, url),
                componentName,
              }))
            )}
          >
            <PayoneerStatus />
          </TDApiConnected>
          <div className="button-container mt-4 text-right">
            <TDButton
              label="Go to your bank accounts"
              variant={BS_STYLE.PRIMARY}
              onClick={() => {
                history.push(settingsPaymentsSubPageUrl(orgAlias));
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

ProfileManagePayoneerCancelView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ProfileManagePayoneerCancelView);
