import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const PaidLabel = () => (
  <>
    Paid
    <ElementWithPopOver
      className="d-inline-block ml-2"
      popOverContent={(
        `Your invoice has been paid. It should arrive in your chosen payment method soon, if it
        hasn't already done so.`
      )}
      popOverTitle="Paid"
    />
  </>
);

export default PaidLabel;
