import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import { proFormaInvoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import { lineBreakToBr } from 'core/assets/js/lib/utils-jsx';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';

const ProFormaInvoiceBreakdown = (
  {
    serviceOrder: { summary, attachments },
  },
) => {
  const itemTypes = [];

  return (
    <>
      {itemTypes.map(([type, title, thisItems]) => (
        <>
          <h3 className="mb-2">{title}</h3>
          <table className="table pro-forma-invoice-breakdown mb-4">
            <tbody>
              {thisItems.map(item => {
                const exchangeRateProps = (
                  item.proFormaInvoiceItemCurrency === item.organizationCurrency
                ) ? null : {
                    className: 'ml-2',
                    exchangeRate: item.exchangeRate,
                    exchangeRateDate: item.exchangeRateUpdatedAt,
                    exchangeRateService: item.proFormaInvoiceItemToOrganizationFxRateSource,
                    sourceCurrency: item.proFormaInvoiceItemCurrency,
                    targetCurrency: item.organizationCurrency,
                  };

                return (
                  <tr
                    data-testid={`pro-forma-inovice-breakdown-${type}-item`}
                    key={`pro-forma-inovice-item-${item.id}`}
                  >
                    <td className="description">
                      {item.description}
                    </td>
                    <td>
                      {item.quantity}
                      {' '}
                      {
                        item.rateUnit !== RATE_UNIT.CUSTOM
                          ? RATE_UNIT_FORMAT[item.rateUnit].unit
                          : 'units'
                      }
                    </td>
                    <td
                      className="text-right"
                      data-testid={`pro-forma-inovice-breakdown-${type}-item-rate-amount`}
                    >
                      <NumberTpl
                        prefix={item.proFormaInvoiceItemCurrencySymbol}
                        value={item.proFormaInvoiceItemRateAmount}
                        decimals={(
                          item.rateUnit !== RATE_UNIT.CUSTOM
                            ? RATE_UNIT_FORMAT[item.rateUnit].decimals
                            : 2
                        )}
                      />
                      {exchangeRateProps && (
                        <ExchangeRateInfoPopOver
                          {...exchangeRateProps}
                          targetAmount={item.organizationRateAmount}
                        />
                      )}
                    </td>
                    <td
                      className="text-right"
                      data-testid={`pro-forma-inovice-breakdown-${type}-item-total`}
                    >
                      <NumberTpl
                        prefix={item.proFormaInvoiceItemCurrencySymbol}
                        value={item.proFormaInvoiceItemTotal}
                      />
                      {exchangeRateProps && (
                        <ExchangeRateInfoPopOver
                          {...exchangeRateProps}
                          targetAmount={item.organizationTotal}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ))}
      <div>
        <h3 className="mb-2">
          Summary
        </h3>
        <p>
          {lineBreakToBr(summary, 'ws-summary-line-')}
        </p>
      </div>
      {!isEmpty(attachments) && (
        <div>
          <AttachmentsList
            attachments={attachments}
            label="Attachments"
          />
        </div>
      )}
    </>
  );
};

ProFormaInvoiceBreakdown.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  serviceOrder: proFormaInvoiceSpec.isRequired,
};

export default withRouter(ProFormaInvoiceBreakdown);
