import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.isExpanded || false,
      showToggle: false,
    };
    this.tagsContainer = null;
    this.props = props;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderFullTagList = this.renderFullTagList.bind(this);
  }

  componentDidMount() {
    this.checkContentHeight();
  }

  openModal(ev) {
    const { modalId, tags, maxShownItems, dispatch } = this.props;
    ev.stopPropagation();
    // Show popup only if total tags count is greater than the max shown.
    if (tags && tags.length > maxShownItems) {
      dispatch(modalOpenAC(modalId));
    }
  }

  closeModal(ev) {
    const { dispatch } = this.props;
    if (ev) {
      ev.stopPropagation();
    }
    dispatch(modalCloseAC());
  }

  checkContentHeight() {
    this.setState({});
    // Calculate tags height.
    const height = this.tagsContainer ? this.tagsContainer.clientHeight : 0;
    // If height is greater than a line, show the toggle button.
    if (height > 35) {
      this.setState({ showToggle: true });
    }
  }

  renderFullTagList() {
    const { tags } = this.props;

    return (
      <div className="tag-list">
        { tags.map(tag => (
          <span title={tag} key={`tag-${tag}`} className="tag">
            <span>{tag}</span>
          </span>
        ))
        }
      </div>
    );
  }

  render() {
    const {
      collapsible,
      inline,
      isModalOpen,
      tags,
      invertedColors,
      maxShownItems,
      alignTags,
    } = this.props;

    const { isExpanded, showToggle } = this.state;
    if (!tags) {
      return <i className="no-tags">-</i>;
    }

    const trimmedTagList = tags.slice(0, maxShownItems).map(tag => (
      <span
        onClick={this.openModal}
        title={tag}
        key={`tag-${tag}`}
        className="tag"
      >
        <span>{tag}</span>
      </span>
    ));

    const wrapperClasses = ['tag-list clearfix mb-4 mb-sm-0'];

    if (invertedColors) {
      wrapperClasses.push('tag-list--inverted-colors');
    }

    if (inline) {
      wrapperClasses.push('tag-list--inline');
    }

    if (collapsible) {
      wrapperClasses.push('tag-list--collapsible');
    }

    if (alignTags === 'right') {
      wrapperClasses.push('tag-list--right-aligned');
    } else {
      wrapperClasses.push('tag-list--left-aligned');
    }

    if (isExpanded) {
      wrapperClasses.push('tag-list--expanded');
    } else {
      wrapperClasses.push('tag-list--collapsed');
    }

    return (
      <div className={wrapperClasses.join(' ')}>
        { collapsible && showToggle }
        { inline
          ? (
            <span
              className={`${tags.length > maxShownItems ? 'tag-list__trimmed-list' : ''} truncate`}
            >
              {trimmedTagList}
            </span>
          )
          : this.renderFullTagList()
        }

        <ModalSimple
          heading="Tag List"
          open={isModalOpen}
          onClose={this.closeModal}
          body={this.renderFullTagList()}
        />
      </div>
    );
  }
}

TagList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  collapsible: PropTypes.bool,
  modalId: PropTypes.string,
  invertedColors: PropTypes.bool,
  inline: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  alignTags: PropTypes.string,
  tags: PropTypes.array,
  /* If total tags is greater than this number, then show a "..."
   * link and open a popup to show the whole tag list.
   */
  maxShownItems: PropTypes.number,
};

TagList.defaultProps = {
  maxShownItems: 8,
  collapsible: false,
  inline: false,
  isExpanded: false,
  isModalOpen: false,
  invertedColors: false,
  tags: [],
  alignTags: 'left',
  modalId: null,
};

const mapStateToProps = (state, props) => ({
  isModalOpen: getIsModalOpen(state, props.modalId),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TagList);
