import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map, pick } from 'lodash';
import queryString from 'query-string';

import {
  PROJECT_ACCESS_TYPE, PROJECT_ACCESS_TYPE_LABEL, PROJECTS_DEFAULT_ORDERING, PROJECT_STATUS_OPTIONS,
} from 'projects/assets/js/constants';
import { CURRENCY_SYMBOL, ICON, ORDERING_DIRECTION } from 'core/assets/js/constants';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import RangeFilterField from 'core/assets/js/components/FinalFormFilterFields/RangeFilterField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';

const defaultOrdering = PROJECTS_DEFAULT_ORDERING;

class ProjectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();
    const filtersList = map(searchSpec.filters, 'paramName');

    let values = queryString.parse(location.search);
    values = pick(values, ['kw', 'ordering'].concat(filtersList));

    // Set default ordering value
    if (!values.ordering) {
      values.ordering = JSON.stringify(PROJECTS_DEFAULT_ORDERING);
    }

    return values;
  }

  getSearchSpec() {
    const { activeOrg, budgetLimits, isManager, isOrgCreator, customFieldFilters } = this.props;
    // Prepare Status filter options
    const projectAccessTypeOptions = Object.values(PROJECT_ACCESS_TYPE)
      .filter((option) => {
        const removedOptions = [];
        if (!isOrgCreator) {
          removedOptions.push(PROJECT_ACCESS_TYPE.NOT_MEMBER);
        }
        if (!isManager) {
          removedOptions.push(PROJECT_ACCESS_TYPE.OWNER);
        }
        return !(removedOptions.includes(option));
      })
      .map(value => (
        { text: PROJECT_ACCESS_TYPE_LABEL[value], value }),
      );

    const searchSpec = {
      customFieldFilters,
      searchTerm: {
        placeholder: 'Search Projects',
        paramName: 'kw',
        component: AutoSuggestField,
      },
      defaultOrdering,
      orderingOptions: [
        { text: 'Created date', value: 'created_at', filterDefaultDirection: ORDERING_DIRECTION.DESC },
        { text: 'Deadline', value: 'deadline', filterDefaultDirection: ORDERING_DIRECTION.DESC },
        { text: 'Title', value: 'title', filterDefaultDirection: ORDERING_DIRECTION.ASC },
      ],
      filters: [
        // Note: Ordering filter is only shown on mobile devices.
        {
          label: 'Sort By',
          paramName: 'ordering',
          fieldComponent: SelectableListFilterField,
          multiple: false,
          isOrdering: true,
          options: [
            {
              text: (
                <span>
                  Created date
                  {' '}
                  <i className={ICON.SORTING_ASC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'ASC',
                sortBy: 'created_at',
              }),
            },
            {
              text: (
                <span>
                  Created date
                  {' '}
                  <i className={ICON.SORTING_DESC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'DESC',
                sortBy: 'created_at',
              }),
            },
            {
              text: (
                <span>
                  Deadline
                  {' '}
                  <i className={ICON.SORTING_ASC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'ASC',
                sortBy: 'deadline',
              }),
            },
            {
              text: (
                <span>
                  Deadline
                  {' '}
                  <i className={ICON.SORTING_DESC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'DESC',
                sortBy: 'deadline',
              }),
            },
            {
              text: (
                <span>
                  Title
                  {' '}
                  <i className={ICON.SORTING_ASC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'ASC',
                sortBy: 'title',
              }),
            },
            {
              text: (
                <span>
                  Title
                  {' '}
                  <i className={ICON.SORTING_DESC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'DESC',
                sortBy: 'title',
              }),
            },
          ],
        },
        {
          label: 'Status',
          paramName: 'status',
          fieldComponent: SelectableListFilterField,
          options: PROJECT_STATUS_OPTIONS,
          multiple: true,
        },
        ...(
          !isManager ? [] : [{
            label: 'Archived',
            paramName: 'archived',
            fieldComponent: SelectableListFilterField,
            options: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
          }]
        ),
        {
          label: 'Budget',
          paramName: 'budget',
          fieldComponent: RangeFilterField,
          min: budgetLimits.min,
          max: budgetLimits.max,
          step: budgetLimits.step,
          symbol: CURRENCY_SYMBOL[activeOrg.currency],
        },
      ],
    };
    if (isManager) {
      searchSpec.filters.push({
        fieldComponent: SelectableListFilterField,
        multiple: false,
        label: 'View projects',
        options: projectAccessTypeOptions,
        paramName: 'accessType',
      });
    }
    return searchSpec;
  }

  render() {
    const {
      className, extraSearchElement, filtersOpen, onFiltersChanged, onFiltersToggle, queryParams,
    } = this.props;
    return (
      <SearchFinalForm
        className={className}
        extraSearchElement={extraSearchElement}
        filtersOpen={filtersOpen}
        initialValues={this.getInitialValues()}
        onFiltersChanged={onFiltersChanged}
        onFiltersToggle={onFiltersToggle}
        query={queryParams}
        searchSpec={this.getSearchSpec()}
      />
    );
  }
}

ProjectSearch.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  budgetLimits: PropTypes.object,
  className: PropTypes.string,
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  extraSearchElement: PropTypes.element,
  filtersOpen: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  isOrgCreator: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersChanged: PropTypes.func,
  onFiltersToggle: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
};

ProjectSearch.defaultProps = {
  budgetLimits: {},
  className: null,
  customFieldFilters: [],
  extraSearchElement: null,
  onFiltersChanged: null,
  queryParams: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  isManager: getHasOrgAccess(state)({ requireManager: true }),
  isOrgCreator: getHasOrgAccess(state)({ requireOrgCreator: true }),
});
const ProjectSearchConnect = connect(
  mapStateToProps,
)(ProjectSearch);

export default withRouter(ProjectSearchConnect);
