import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { connect } from 'react-redux';

import TaskChecklist from 'projects/assets/js/components/TaskChecklist.jsx';
import TaskItemForm from 'projects/assets/js/components/TaskItemForm.jsx';
import { listAppendItemAC, listRemoveItemAC } from 'core/assets/js/ducks/list';
import { projectTaskSpec, taskAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  projectCreateTaskItemDS,
  projectUpdateTaskItemDS,
  projectArchiveTaskItemDS,
  projectToggleTaskItemDS,
} from 'projects/assets/js/data-services/tasks';

const NEW_ITEM_ID = -1;

class TaskChecklistWidget extends React.Component {
  constructor(props) {
    super(props);

    this.handleItemUpdated = this.handleItemUpdated.bind(this);
    this.handleItemRemoved = this.handleItemRemoved.bind(this);
    this.handleItemDuplicated = this.handleItemDuplicated.bind(this);
    this.handleDescriptionReset = this.handleDescriptionReset.bind(this);
    this.handleShowAdditem = this.handleShowAdditem.bind(this);
    this.handleHideAddItem = this.handleHideAddItem.bind(this);
    this.handleItemToggled = this.handleItemToggled.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  /**
   * Returns task items at a given index
   *
   * @param {Number} index
   * @returns {Object}
   */
  getItemAt(index) {
    const { items } = this.props;

    if (!items[index]) {
      throw new Error('Index out of bounds');
    }

    return items[index];
  }

  /**
   * Creates a new task item on the remote API
   */
  async createItem(values) {
    const { dispatch, orgAlias, projectId, task, parentComponentName, onInfoUpdated } = this.props;
    this.handleHideAddItem();

    await dispatch(
      projectCreateTaskItemDS(
        orgAlias, projectId, task.id, omit(values, 'id'), parentComponentName,
      ),
    );

    await onInfoUpdated();
  }

  /**
   * Updates an existing task item on the remote API
   */
  async updateItem(values) {
    const { dispatch, orgAlias, projectId, task, parentComponentName } = this.props;
    return dispatch(
      projectUpdateTaskItemDS(orgAlias, projectId, task.id, values.id, values, parentComponentName),
    );
  }

  /**
   * An item got updated or created, stores it on the remote API
   */
  async handleItemUpdated(values) {
    if (values.id && values.id !== NEW_ITEM_ID) {
      await this.updateItem(values);
    } else {
      await this.createItem(values);
    }
  }

  async handleItemRemoved(index) {
    const { dispatch, orgAlias, projectId, task, parentComponentName, onInfoUpdated } = this.props;
    const item = this.getItemAt(index);

    if (item.id === NEW_ITEM_ID) {
      this.handleHideAddItem();
      return;
    }

    await dispatch(
      projectArchiveTaskItemDS(orgAlias, projectId, task.id, item.id, parentComponentName),
    );

    await onInfoUpdated();
  }

  /**
   * An item's description got reset to its initial state
   */
  handleDescriptionReset(index) {
    const item = this.getItemAt(index);

    // If we're reverting a newly added item (via the "Add item" button), just remove it
    if (item.id === NEW_ITEM_ID) {
      return this.handleHideAddItem();
    }

    return true;
  }

  /**
   * An item got duplicated
   * (ie. we're copying a new item to the state and making it editable)
   */
  handleItemDuplicated(index) {
    const { dispatch, parentComponentName, items } = this.props;

    const alreadyHasNewItem = !!items.find(it => it.id === NEW_ITEM_ID);
    if (alreadyHasNewItem) {
      this.handleHideAddItem();
    }

    const newItem = {
      ...omit(this.getItemAt(index), ['id']),
      id: NEW_ITEM_ID,
    };

    return dispatch(listAppendItemAC(newItem, parentComponentName));
  }

  /**
   * A task item's "completed" checkbox got toggled
   */
  async handleItemToggled(index, event, isChecked) {
    const {
      dispatch, orgAlias, projectId, task: { id: taskId }, parentComponentName, onItemToggled,
    } = this.props;

    const item = this.getItemAt(index);
    const values = { completed: isChecked };

    await dispatch(
      projectToggleTaskItemDS(
        orgAlias, projectId, taskId, item.id, values, parentComponentName,
      ),
    );

    await onItemToggled(item, isChecked);
  }

  /**
   * Adds an item to the state that uses a special id (NEW_ITEM_ID)
   * which indicates that we're adding a new task item
   */
  handleShowAdditem() {
    const { dispatch, parentComponentName, items } = this.props;

    // if the item already exists in state, bail
    const alreadyHasNewItem = !!items.find(it => it.id === NEW_ITEM_ID);
    if (alreadyHasNewItem) {
      return false;
    }

    // Form the new item and add it to the list
    const newItem = { id: NEW_ITEM_ID, description: '', completed: false };
    return dispatch(
      listAppendItemAC(newItem, parentComponentName),
    );
  }

  /**
   * Removes an item from the state that uses a special id (NEW_ITEM_ID)
   * which indicates that we're adding a new task item
   */
  handleHideAddItem() {
    const { dispatch, parentComponentName } = this.props;
    return dispatch(
      listRemoveItemAC(NEW_ITEM_ID, parentComponentName),
    );
  }

  render() {
    const { items, allowedActions } = this.props;
    const disabled = !allowedActions.canEditTaskItems;

    // Form the task items to be rendered as forms
    const taskItems = items.map((item, index) => {
      const initialValues = ({ ...item, index });
      const formKey = `task-form-${item.id}`;
      // automatically focus when we're adding or duplicating a new item
      const autoFocus = !item.id || item.id === NEW_ITEM_ID || !item.description;

      return (
        <TaskItemForm
          key={formKey}
          formName={formKey}
          initialValues={initialValues}
          index={index}
          disabled={disabled}
          isCompleted={item.completed}
          onItemUpdated={this.handleItemUpdated}
          onItemToggled={this.handleItemToggled}
          onDescriptionEditable={this.handleDescriptionEditable}
          onDescriptionViewable={this.handleDescriptionViewable}
          onItemRemoved={this.handleItemRemoved}
          onItemDuplicated={this.handleItemDuplicated}
          onUpdateCancelled={this.handleHideAddItem}
          onDescriptionReset={this.handleDescriptionReset}
          autoFocus={autoFocus}
        />
      );
    });

    return (
      <TaskChecklist
        onItemAdded={this.handleShowAdditem}
        showAddItem={!disabled}
        items={taskItems}
      />
    );
  }
}

TaskChecklistWidget.propTypes = {
  orgAlias: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  task: projectTaskSpec.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  parentComponentName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  allowedActions: taskAllowedActionsSpec,
  onItemToggled: PropTypes.func,
  onInfoUpdated: PropTypes.func,
};

TaskChecklistWidget.defaultProps = {
  items: [],
  allowedActions: {},
  onItemToggled: () => {},
  onInfoUpdated: () => {},
};

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = () => ({});

const TaskChecklistWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskChecklistWidget);

export default TaskChecklistWidgetConnected;
