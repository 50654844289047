import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import { updateTimeOffDS } from 'accounts/assets/js/data-services/timeoff';
import { timeOffUrl, timeOffItemApiUrl } from 'accounts/urls';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TimeOffForm from 'accounts/assets/js/components/TimeOffForm.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class TimeOffEditView extends React.Component {
  static FetchData({ dispatch, params, authedAxios, componentName }) {
    return dispatch(fetchViewDS(
      { id: params.timeOffId, url: timeOffItemApiUrl(params.timeOffId),
        authedAxios, componentName,
      }));
  }

  static GetComponentName() {
    return 'TimeOffEditView';
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { activeOrg, dispatch, history } = this.props;
    return dispatch(updateTimeOffDS(values)).then(() => {
      history.push(timeOffUrl(activeOrg.alias));
      toastr.success('Well Done!', 'Availability updated successfully.');
    });
  }

  render() {
    const { activeOrg, timeOff: { periodEnd, periodStart, ...timeOffRest } } = this.props;

    const breadcrumbs = [
      {
        title: 'Availability',
        url: timeOffUrl(activeOrg.alias),
      },
      {
        title: 'Edit',
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--timeoff-create">
          <div className="container">
            <TDApiConnected duck="view" component={this.constructor} blockingLoading skeletonComponent={SettingsPageSkeleton}>
              <TimeOffForm
                initialValues={{ period: { max: periodEnd, min: periodStart }, ...timeOffRest }}
                onSubmit={this.handleSubmit}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TimeOffEditView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  timeOff: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, TimeOffEditView.GetComponentName());

  return {
    activeOrg: selectActiveOrg(state),
    timeOff: viewState.item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TimeOffTEditViewConnected = connect(mapStateToProps, mapDispatchToProps)(TimeOffEditView);
export default withRouter(TimeOffTEditViewConnected);
