/* eslint-disable react/jsx-fragments */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset, submit, isSubmitting } from 'redux-form';
import queryString from 'query-string';

import BankAccountForm, { FORM_ID as BANK_FORM_ID } from 'settings/assets/js/components/BankAccountForm.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import PaymentProviderOfflineCard from 'settings/assets/js/components/PaymentProviderOfflineCard.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TWBankAccountForm from 'settings/assets/js/components/TWBankAccountForm.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { SERVICE_STATUS_SERVICE } from 'services/assets/js/constants';
import { SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { createBankAccountDS, updateBankAccountDS } from 'settings/assets/js/data-services/settings';
import { fetchServiceStatusDS } from 'services/assets/js/data-services/services';
import { fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import { fetchViewDS, getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { isServiceStatusOk } from 'services/assets/js/lib/utils';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { settingsPaymentsSubPageUrl, settingsBankAccountApiUrl } from 'settings/urls';
import { shouldShowTWBankAccountForm } from 'settings/assets/js/lib/utils';

class ProfileManageBankAccount extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    const promises = [];
    const { bankAccountId } = params;
    if (bankAccountId) {
      // TODO - remove me !!!
      const queryParams = queryString.parse(querystring);
      promises.push(dispatch(fetchViewDS({
        url: settingsBankAccountApiUrl(params.bankAccountId, url, { query: {
          fetchRequirements: true,
          ...queryParams,
        } }),
        params,
        authedAxios,
        componentName,
      })));
    }

    promises.push(dispatch(fetchServiceStatusDS({
      authedAxios,
      componentName,
    })));

    return Promise.all(promises);
  }

  static GetComponentName() {
    return 'ProfileManageBankAccount';
  }

  render() {
    const {
      activeOrg, bankAccount, match: { params: { bankAccountId } },
      dispatch, submitting, history, serviceStatus,
    } = this.props;
    const bankAccountType = bankAccount.bank_account_type;
    const showTWBankAccountForm = shouldShowTWBankAccountForm(bankAccountType);
    const breadcrumbs = [
      {
        title: 'Payments',
        url: settingsPaymentsSubPageUrl(activeOrg.alias),
      },
      {
        title: bankAccountId ? 'Edit bank account' : 'Add new bank account',
        url: null,
      },
    ];
    const isWiseOnline = isServiceStatusOk(serviceStatus, SERVICE_STATUS_SERVICE.TRANSFERWISE);
    // Check if is coming from get started page
    let fromGetStarted = false;
    if (history?.location?.state?.fromGetStarted) {
      fromGetStarted = true;
    }

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--settings bank-account">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={showTWBankAccountForm ? null : SettingsPageSkeleton}
            >
              { showTWBankAccountForm && (
                <>
                  {isWiseOnline ? (
                    <TWBankAccountForm
                      bankAccount={bankAccount}
                      fromGetStarted={fromGetStarted}
                    />
                  ) : (
                    <PaymentProviderOfflineCard />
                  )}
                </>
              )}
              { !showTWBankAccountForm && (
                <React.Fragment>
                  <BankAccountForm
                    onSubmitSuccess={() => {
                      dispatch(reset(BANK_FORM_ID));
                      history.push(settingsPaymentsSubPageUrl(
                        activeOrg.alias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
                      ));
                    }}
                    onSubmit={(values) => {
                      if (bankAccountId) {
                        return updateBankAccountDS(values, dispatch)
                          .then(async () => {
                            await dispatch(fetchSetupStepsDS());
                          });
                      }

                      return createBankAccountDS(activeOrg.alias, values, dispatch)
                        .then(async () => {
                          await dispatch(fetchSetupStepsDS());
                        });
                    }}
                    initialValues={bankAccount}
                  />
                  <div className="button-container mt-4 text-right">
                    <TDButton
                      type="button"
                      className="btn btn-lg btn-default"
                      label="Cancel"
                      onClick={() => {
                        history.push(settingsPaymentsSubPageUrl(activeOrg.alias));
                      }}
                    />

                    <TDButton
                      type="submit"
                      variant={BS_STYLE.PRIMARY}
                      onClick={() => dispatch(submit(BANK_FORM_ID))}
                      disabled={submitting}
                      label="Save"
                    />
                  </div>
                </React.Fragment>
              )}
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileManageBankAccount.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  bankAccount: bankAccountSpec,
  submitting: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  serviceStatus: PropTypes.any.isRequired,
};

ProfileManageBankAccount.defaultProps = {
  bankAccount: {},
  submitting: false,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  bankAccount: getViewState(state, ProfileManageBankAccount.GetComponentName()).item,
  submitting: isSubmitting(BANK_FORM_ID)(state),
  serviceStatus: getViewStateExtras(state, ProfileManageBankAccount.GetComponentName(), 'serviceStatus'),
});

const ProfileManageBankAccountReduxConnect = connect(
  mapStateToProps,
)(ProfileManageBankAccount);

export default withRouter(ProfileManageBankAccountReduxConnect);
