import React from 'react';
import PropTypes from 'prop-types';

const BillableVoidNotAllowed = ({ billableTypeLabel }) => (
  <>
    <p>
      You cannot void this
      {' '}
      {billableTypeLabel}
      {' '}
      because it has already been included in an invoice.
    </p>
    <p>
      If it got approved by mistake, please reach out to your customer
      success manager to discuss the issue.
    </p>
  </>
);

BillableVoidNotAllowed.propTypes = {
  billableTypeLabel: PropTypes.string.isRequired,
};

export default BillableVoidNotAllowed;
