import React from 'react';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const SkillSelectSectionSkeleton = () => (
  <div className="skeleton--skill-select mb-n5">
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="100" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="130" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="60" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="170" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="100" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="150" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="100" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="100" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="150" height="32" />
    <RectangleSkeleton className="m-0 mb-3 mr-4 skeleton--skill-select-item" width="100" height="32" />
  </div>
);

export default SkillSelectSectionSkeleton;
