import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, merge } from 'lodash';
import { Modal } from 'react-bootstrap';
import { reduxForm, startSubmit, stopSubmit } from 'redux-form';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import { logger } from 'core/assets/js/lib/Logger';

const FORM_NAME = 'modalForm';

const ModalFormContents = ({
  heading, confirmLabel, confirmStyle, cancelLabel,
  withFooter, onClose,
  Form, showConfirm, ...formProps
}) => {
  const { dispatch, handleSubmit, submitting } = formProps;
  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isEmpty(formProps) && React.createElement(Form, formProps)}
      </Modal.Body>
      {withFooter && (
        <Modal.Footer>
          <TDButton
            onClick={() => onClose()}
            label={cancelLabel}
          />

          {showConfirm && (
            <TDButton
              disabled={submitting}
              variant={confirmStyle}
              onClick={() => {
                dispatch(startSubmit(FORM_NAME));
                return handleSubmit();
              }}
              label={confirmLabel}
            />
          )}
        </Modal.Footer>
      )}
    </React.Fragment>
  );
};

ModalFormContents.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  confirmLabel: PropTypes.string,
  confirmStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
  cancelLabel: PropTypes.string,
  withFooter: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  Form: PropTypes.elementType.isRequired,
  showConfirm: PropTypes.bool,
};

ModalFormContents.defaultProps = {
  confirmLabel: 'Accept',
  confirmStyle: BS_STYLE.PRIMARY,
  cancelLabel: 'Close',
  showConfirm: true,
  withFooter: true,
};

const ConnectedModalFormContents = reduxForm({
  form: FORM_NAME,
  onSubmitSuccess: (result, dispatch, props) => {
    dispatch(stopSubmit(FORM_NAME));
    const { onClose } = props;
    return onClose();
  },
})(ModalFormContents);

const withModalForm = ({
  heading = 'Update',
  confirmLabel = 'Accept',
  cancelLabel = 'Cancel',
  confirmStyle = BS_STYLE.PRIMARY,
  withFooter = true,
  showConfirm,
  size = MODAL_SIZES.LARGE,
  form,
  testId,
} = {}) => (WrappedComponent) => {
  const isReduxForm = WrappedComponent.displayName === 'ReduxForm';
  if (isReduxForm) {
    logger.error(new Error('Form is not expected to be already wrapped in reduxForm()'));
  }

  class _WithModalForm extends React.PureComponent {
    render() {
      const {
        modalState, ...rest
      } = this.props;
      const props = merge({
        ...rest,
      }, {
        heading,
        confirmLabel,
        confirmStyle,
        cancelLabel,
        withFooter,
        showConfirm,
        size,
        form,
      });
      if (isReduxForm) {
        return null;
      }
      return (
        <Modal
          data-testid={testId}
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
          className="static-modal"
          enforceFocus
          size={props.size}
          onHide={() => modalState.close()}
          show={modalState.isOpen}
        >
          {modalState.isOpen && (
            <ConnectedModalFormContents
              {...props}
              onClose={() => modalState.close()}
              Form={WrappedComponent}
            />
          )}
        </Modal>
      );
    }
  }

  _WithModalForm.propTypes = {
    withFooter: PropTypes.bool,
    heading: PropTypes.string,
    confirmLabel: PropTypes.string,
    confirmStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
    modalState: modalStateSpec.isRequired,
    showConfirm: PropTypes.bool,
  };

  _WithModalForm.defaultProps = {
    withFooter: true,
    heading: 'Update',
    confirmLabel: 'Accept',
    confirmStyle: BS_STYLE.PRIMARY,
    showConfirm: true,
  };

  const WithModalConnect = withStateModal(_WithModalForm);

  return WithModalConnect;
};

export default withModalForm;
