export const DOCUMENT_RENEWAL_TERM_TYPE = {
  HOURS: 1,
  DAYS: 2,
  WEEKS: 3,
  MONTHS: 4,
  YEARS: 5,
};

export const DOCUMENT_RENEWAL_TERM_TYPE_VALUES = Object.values(DOCUMENT_RENEWAL_TERM_TYPE);

export const DOCUMENT_RENEWAL_TERM_TYPE_LABEL = {
  [DOCUMENT_RENEWAL_TERM_TYPE.HOURS]: 'Hours',
  [DOCUMENT_RENEWAL_TERM_TYPE.DAYS]: 'Days',
  [DOCUMENT_RENEWAL_TERM_TYPE.WEEKS]: 'Weeks',
  [DOCUMENT_RENEWAL_TERM_TYPE.MONTHS]: 'Months',
  [DOCUMENT_RENEWAL_TERM_TYPE.YEARS]: 'Years',
};

export const DOCUMENT_RENEWAL_TYPE = {
  MANUAL: 1,
  EVERGREEN: 2,
};

export const DOCUMENT_RENEWAL_TYPE_LABEL = {
  [DOCUMENT_RENEWAL_TYPE.MANUAL]: 'Manual',
  [DOCUMENT_RENEWAL_TYPE.EVERGREEN]: 'Evergreen',
};

export const DOCUMENT_RENEWAL_TYPE_VALUES = Object.values(DOCUMENT_RENEWAL_TYPE);

export const DOCUMENT_STATUS = {
  DRAFT: 1,
  ACTIVE: 2,
  ARCHIVED: 3,
};

export const DOCUMENT_STATUS_VALUES = Object.values(DOCUMENT_STATUS);

export const DOCUMENT_STATUS_LABEL = {
  [DOCUMENT_STATUS.DRAFT]: 'Draft',
  [DOCUMENT_STATUS.ACTIVE]: 'Active',
  [DOCUMENT_STATUS.ARCHIVED]: 'Archived',
};

export const DOCUMENT_STATUS_CLASS = {
  [DOCUMENT_STATUS.DRAFT]: 'raised',
  [DOCUMENT_STATUS.ACTIVE]: 'approved',
  [DOCUMENT_STATUS.ARCHIVED]: 'expired',
};

export const DOCUMENT_CONTRACT_TERM = {
  DEFINITE: 1,
  INDEFINITE: 2,
};

export const DOCUMENT_CONTRACT_TERM_VALUES = Object.values(DOCUMENT_CONTRACT_TERM);

export const DOCUMENT_CONTRACT_TERM_LABEL = {
  [DOCUMENT_CONTRACT_TERM.DEFINITE]: 'Definite',
  [DOCUMENT_CONTRACT_TERM.INDEFINITE]: 'Indefinite',
};

export const DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE = {
  ON_SPECIFIC_DATE: 1,
  ON_EXECUTION: 2,
};

export const DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_VALUES = Object.values(
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE,
);

export const DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_LABEL = {
  [DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_SPECIFIC_DATE]: 'On specific date',
  [DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_EXECUTION]: 'On execution',
};

export const DOCUMENT_CONTRACT_TERM_END_TYPE = {
  ON_SPECIFIC_DATE: 1,
  RELATIVE_DATE: 2,
  ON_CONDITION: 3,
  ON_FULFILMENT: 4,
};

export const DOCUMENT_CONTRACT_TERM_END_TYPE_VALUES = Object.values(
  DOCUMENT_CONTRACT_TERM_END_TYPE,
);

export const DOCUMENT_CONTRACT_TERM_END_TYPE_LABEL = {
  [DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE]: 'On specific date',
  [DOCUMENT_CONTRACT_TERM_END_TYPE.RELATIVE_DATE]: '# months/years',
  [DOCUMENT_CONTRACT_TERM_END_TYPE.ON_CONDITION]: 'On condition',
  [DOCUMENT_CONTRACT_TERM_END_TYPE.ON_FULFILMENT]: 'On fulfilment',
};

export const DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE = {
  MONTHS: 1,
  YEARS: 2,
};

export const DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_VALUES = Object.values(
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE,
);

export const DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL = {
  [DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE.MONTHS]: 'Months',
  [DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE.YEARS]: 'Years',
};

export const DOCUMENT_TAX_TYPE = {
  INCLUDED: 1,
  PAYMENT_TERMS: 2,
};

export const DOCUMENT_TAX_TYPE_VALUES = Object.values(DOCUMENT_TAX_TYPE);

export const DOCUMENT_TAX_TYPE_LABEL = {
  [DOCUMENT_TAX_TYPE.INCLUDED]: 'All taxes included',
  [DOCUMENT_TAX_TYPE.PAYMENT_TERMS]: 'As per payment terms',
};

export const DOCUMENT_CREATE_STEPS = {
  DETAILS: 'details',
  TYPE: 'type',
  ESIGN_UPLOAD: 'esign-upload',
  ESIGN_EDIT: 'esign-edit',
  DOCUMENT: 'document',
};

export const DOCUMENT_TYPE = {
  CONTENT: 1,
  FILE: 2,
  ESIGN: 3,
};

export const DOCUMENT_TYPE_VALUES = Object.values(DOCUMENT_TYPE);

export const DOCUMENT_TYPE_LABEL = {
  [DOCUMENT_TYPE.CONTENT]: 'Editor',
  [DOCUMENT_TYPE.FILE]: 'Attachment',
};

export const DOCUMENT_CONTRACT_TYPE = {
  CLICK_THROUGH: 1,
  ESIGN: 2,
};

export const DOCUMENT_CONTRACT_TYPE_LABEL = {
  [DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH]: 'Checkbox',
  [DOCUMENT_CONTRACT_TYPE.ESIGN]: 'E-Signature',
};

export const DOCUMENT_CONTRACT_TYPE_LABEL_WARNING = {
  [DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH]: 'FREE',
  [DOCUMENT_CONTRACT_TYPE.ESIGN]: 'charges may apply*',
};

export const DOCUMENT_CONTRACT_TYPE_BULLET_POINTS = {
  [DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH]: [
    'Upload a static PDF contract or use our built-in editor to create your contract',
    'All recipients receive and confirm the exact same contract template text (not customised per recipient)',
    'Recipients confirm their agreement with the contract by enabling a checkbox and typing in their name',
  ],
  [DOCUMENT_CONTRACT_TYPE.ESIGN]: [
    'Upload a PDF or Word contract template',
    'Use our embedded editor to add text inputs, dates, and signature fields for the recipient complete',
    "The signed and completed contract will be stored with the user's profile and available to download as a PDF",
  ],
};

export const DOCUMENT_CONTRACT_TYPE_VALUES = Object.values(DOCUMENT_CONTRACT_TYPE);

// Stored in the DB as Enum values.
export const DOCUMENT_ASSIGNMENT_STATUS = {
  PENDING: 'pending',
  SIGNED: 'signed',
  CANCELLED: 'cancelled',
};

export const DOCUMENT_ASSIGNMENT_STATUS_VALUES = Object.values(DOCUMENT_ASSIGNMENT_STATUS);

export const DOCUMENT_ASSIGNMENT_STATUS_LABEL = {
  [DOCUMENT_ASSIGNMENT_STATUS.PENDING]: 'Pending',
  [DOCUMENT_ASSIGNMENT_STATUS.SIGNED]: 'Confirmed',
  [DOCUMENT_ASSIGNMENT_STATUS.CANCELLED]: 'Cancelled',
};

export const DOCUMENT_ASSIGNMENT_STATUS_CLASS = {
  [DOCUMENT_ASSIGNMENT_STATUS.PENDING]: 'pending',
  [DOCUMENT_ASSIGNMENT_STATUS.SIGNED]: 'completed',
  [DOCUMENT_ASSIGNMENT_STATUS.CANCELLED]: 'cancelled',
};

export const DOCUMENT_ASSIGNMENT_STATUS_COLORS = {
  [DOCUMENT_ASSIGNMENT_STATUS.PENDING]: '#fab152',
  [DOCUMENT_ASSIGNMENT_STATUS.SIGNED]: '#22c55e',
  [DOCUMENT_ASSIGNMENT_STATUS.CANCELLED]: '#ff595a',
};

export const DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS = {
  PENDING: 'pending',
  SIGNED: 'signed',
};

export const DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_LABEL = {
  [DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.PENDING]: 'Pending',
  [DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.SIGNED]: 'Signed',
};

export const DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_CLASS = {
  [DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.PENDING]: 'pending',
  [DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.SIGNED]: 'completed',
};

export const DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_VALUES = Object.values(
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS,
);

export const DOCUMENT_DETAILS_PROPERTIES = [
  'title',
  'description',
  'version',
  'effectiveType',
  'effectiveDate',
  'endType',
  'enDdate',
  'endRelativeValue',
  'endRelativeType',
  'endText',
  'renewalType',
  'renewalTermValue',
  'renewalTermType',
  'renewalNoticePeriod',
  'renewalExpirationReminder',
  'currency',
  'amount',
  'taxType',
  'taxTerms',
  'isCritical',
];

export const DOCUMENT_VIEW_TABS = {
  OVERVIEW: 'overview',
  CONFIRMATIONS: 'confirmations',
};

export const AOR_CONTRACT_STATUS = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  PENDING_COUNTERSIGN: 'pending-countersign',
  PENDING_PROVIDER_SIGNATURE: 'pending-provider-signature',
};

export const SIGNABLE_ENVELOPE_STATUS = {
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  FAILED: 'failed',
};

export const AOR_CONTRACT_STATUS_LABEL = {
  [AOR_CONTRACT_STATUS.ACTIVE]: 'Active',
  [AOR_CONTRACT_STATUS.DELETED]: 'Deleted',
  [AOR_CONTRACT_STATUS.PENDING_COUNTERSIGN]: 'Pending Review',
  [AOR_CONTRACT_STATUS.PENDING_PROVIDER_SIGNATURE]: 'Pending Signature',
};

export const AOR_CONTRACT_STATUS_LABEL_CLASS = {
  [AOR_CONTRACT_STATUS.ACTIVE]: 'active',
  [AOR_CONTRACT_STATUS.DELETED]: 'deleted',
  [AOR_CONTRACT_STATUS.PENDING_COUNTERSIGN]: 'pending-review',
  [AOR_CONTRACT_STATUS.PENDING_PROVIDER_SIGNATURE]: 'pending-signature',
};

export const SIGN_NOW_EVENTS = {
  DOCUMENT_DECLINED: 'user.document.fieldinvite.decline',
  DOCUMENT_SIGNED: 'user.document.fieldinvite.signed',
};

export const SIGN_NOW_EVENTS_VALUES = Object.values(SIGN_NOW_EVENTS);

export const DOCUMENT_ESIGN_EVENTS = {
  CANCEL: 'cancel',
  BACK: 'back',
  SAVE_DRAFT: 'save-draft',
  PUBLISH: 'publish',
  CONTINUE: 'continue',
};

export const SIGN_NOW_INVITING_MANAGER_ROLE_NAME = 'Sending manager';
