import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { profileSettingsUrl } from 'accounts/urls';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const ProfileBankAccountsList = ({ location: { search }, match: { params: { tab } } }) => {
  const activeOrg = useSelector(selectActiveOrg);
  let to = profileSettingsUrl(activeOrg.alias, tab);
  if (search) {
    to += `?${search}`;
  }
  return <RedirectRoute status={302} to={to} />;
};

ProfileBankAccountsList.GetComponentName = () => 'ProfileBankAccountsList';

ProfileBankAccountsList.propTypes = {
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ProfileBankAccountsList);
