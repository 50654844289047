import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';

const AorDocumentViewDropdownItem = ({ assignment }) => {
  return (
    <Dropdown.Item
      key="view-document-assignment"
      onClick={e => {
        e.stopPropagation();
        WINDOW_OPEN(
          assignment.docUrl,
          '_blank',
        );
      }}
    >
      View
    </Dropdown.Item>
  );
};

AorDocumentViewDropdownItem.propTypes = {
  assignment: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(AorDocumentViewDropdownItem);
