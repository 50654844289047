import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { isNaN } from 'lodash';

import { PROJECT_STATUS } from 'projects/assets/js/constants';

const ProjectProgress = ({ budget, budgetUsed, status }) => {
  let percentage = Math.round((budgetUsed / budget) * 100);

  if (percentage > 100 || status === PROJECT_STATUS.COMPLETED) {
    percentage = 100;
  } else if (status === PROJECT_STATUS.POSTED || isNaN(percentage)) {
    percentage = 0;
  }

  if (percentage === 0) {
    return null;
  }

  return (
    <NumberFormat
      value={percentage}
      suffix="%"
      displayType="text"
    />
  );
};

ProjectProgress.propTypes = {
  budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  budgetUsed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.oneOf(Object.values(PROJECT_STATUS)).isRequired,
};

ProjectProgress.defaultProps = {
  budget: Infinity,
  budgetUsed: 0,
};

export default ProjectProgress;
