import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { invitationResetAC, selectActiveInvitation } from 'invitations/assets/js/ducks/invitation';
import { verifyEmailDS } from 'accounts/assets/js/data-services/email';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { loginUrl } from 'accounts/urls';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { refreshAccountDS } from 'accounts/assets/js/data-services/account';
import { notificationSettingsUrl } from 'settings/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';

class EmailVerifyView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, hasOrgAccess }) {
    if (params.token) {
      return Promise.all([
        dispatch(verifyEmailDS(params.token, url, authedAxios)),
      ]).then(() => {
        if (hasOrgAccess({ requireAuth: true })) {
          dispatch(refreshAccountDS(authedAxios));
        }
      });
    }
    return Promise.resolve();
  }

  static GetComponentName() {
    return 'EmailVerifyView';
  }

  componentDidMount() {
    const { history, isValid, profile } = this.props;
    if (isValid === false) {
      if (profile) {
        history.push(profile.homeUrl);
        return;
      }
      history.push(loginUrl());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(invitationResetAC());
  }

  render() {
    const { profile, history, invitation } = this.props;

    let url;
    if (invitation.updateOrg) {
      url = notificationSettingsUrl(invitation.orgAlias);
    } else {
      url = profile ? profile.homeUrl : loginUrl();
    }
    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
        blockingLoading
      >
        <div className="page page--email-verify">
          <div className="container">
            <Card className="card card--email-verify text-center">
              <Card.Body className="flex-column">
                <div className="col-12 col-lg-6 offset-lg-3">
                  <i className={`card--email-verify__icon mb-4 ${ICON.SUCCESS}`} />
                  <h1 data-testid="sign-up-successful-message">Sign up was successful!</h1>
                  <p className="mb-4 pb-3">Thank you for signing up with TalentDesk!</p>
                  <TDButton
                    data-testid="email-verify-view-button-continue"
                    variant={BS_STYLE.PRIMARY}
                    onClick={() => { history.push(url); }}
                    label="Continue"
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </TDApiConnected>
    );
  }
}

EmailVerifyView.propTypes = {
  profile: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isValid: PropTypes.bool,
  invitation: PropTypes.shape({
    id: PropTypes.number,
    token: PropTypes.string,
    sent_to: PropTypes.string,
    updateOrg: PropTypes.number,
    orgAlias: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

EmailVerifyView.defaultProps = {
  profile: null,
  isLoading: true,
  isValid: null,
};

const mapStateToProps = (state, props) => {
  const invitation = selectActiveInvitation(state);
  return {
    profile: selectProfile(state),
    match: props.match,
    isLoading: invitation.isLoading,
    isValid: invitation.isValid,
    invitation: invitation.item,
    params: props.match.params,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const EmailVerifyViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailVerifyView);

export default withRouter(EmailVerifyViewConnect);
