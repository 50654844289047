import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { billingViewUrl } from 'finance/urls';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { BILLING_TABS } from 'finance/assets/js/constants';


const BillingTabs = ({ match: { params: { orgAlias, tab } } }) => {
  const tabSpec = [{
    key: 'overview',
    label: 'Overview',
    href: billingViewUrl(orgAlias, BILLING_TABS.OVERVIEW),
  }, {
    key: 'billing-history',
    label: 'Billing history',
    href: billingViewUrl(orgAlias, BILLING_TABS.BILLING_HISTORY),
  }, {
    key: 'subscription',
    label: 'Subscription',
    href: billingViewUrl(orgAlias, BILLING_TABS.SUBSCRIPTION),
  }];

  return (
    <div className="horizontal-scroll" data-testid="billing-tabs">
      <TabBar activeKey={tab} tabSpec={tabSpec} />
    </div>
  );
};

BillingTabs.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(BillingTabs);
