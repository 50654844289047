import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { documentInvitingManagerSignatureApiUrl } from 'documents/urls';

const DocumentEsignConfirmationInvitingManagerView = ({ match: { params: { invitationId } } }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!invitationId) {
      setLoading(false);
      return;
    }
    axios
      .post(documentInvitingManagerSignatureApiUrl(invitationId))
      .catch(err => {
        toastr.error('Oh Snap!', err.response?.data?._error || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className={[
        'document-esign-external-container',
        'd-flex',
        'align-items-center',
        'justify-content-center',
      ].join(' ')}
    >
      <p>
        {loading && 'Loading'}
        {!loading && 'Thank you for signing'}
      </p>
    </div>
  );
};

DocumentEsignConfirmationInvitingManagerView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(DocumentEsignConfirmationInvitingManagerView);
