import { REQ_TYPE } from 'core/assets/js/constants';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  listAppendItemAC,
  listFetchAC,
  listFetchExtrasAC,
  listUpdateItemAC,
  paginationFetchAC,
} from 'core/assets/js/ducks/list';
import {
  accountingCreateContractorApiUrl,
  accountingCreateSystemOrgApiUrl,
  accountingIntegrationActivityApiUrl,
  accountingRetryInvoiceSyncApiUrl,
  accountingSupplierContractorsMappingsApiUrl,
  accountingSupplierMappingApiUrl,
  accountingSupplierTalentDeskMappingsApiUrl,
  addIntegrationApiUrl,
  orgIntegrationSyncStatusApiUrl,
  orgIntegrationsApiUrl,
} from 'integrations/urls';

export const fetchIntegrationListDS = ({
  componentName,
  orgAlias,
  querystring,
  authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => orgIntegrationsApiUrl(orgAlias),
    paginationAC: paginationFetchAC,
    fetchDataAC: responseData => [
      listFetchAC(responseData, componentName),
      listFetchExtrasAC(responseData.available, componentName, 'available'),
    ],
    componentName,
  })
);

export const addIntegrationTypeDS = ({ orgAlias, integrationType }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: addIntegrationApiUrl(orgAlias, integrationType),
  })
);

export const fetchAccountingIntegrationActivityDS = ({
  orgAlias,
  integrationId,
  componentName,
  querystring,
  querystring: { status },
  authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    querystring,
    paginationAC: paginationFetchAC,
    fetchApiUrl: () => accountingIntegrationActivityApiUrl(orgAlias, integrationId, status),
    fetchDataAC: responseData => [
      listFetchAC(responseData, componentName),
    ],
  })
);

export const fetchSupplierContractorMappingsDS = ({
  orgAlias,
  integrationId,
  componentName,
  querystring,
  authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    querystring,
    paginationAC: paginationFetchAC,
    fetchApiUrl: () => accountingSupplierContractorsMappingsApiUrl(orgAlias, integrationId),
    fetchDataAC: responseData => [listFetchAC(responseData, componentName)],
  })
);

export const fetchSupplierTalentDeskMappingsDS = ({
  authedAxios = null, componentName, integrationId, orgAlias,
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    paginationAC: paginationFetchAC,
    fetchApiUrl: () => accountingSupplierTalentDeskMappingsApiUrl(orgAlias, integrationId),
    fetchDataAC: responseData => [listFetchAC(responseData, componentName)],
  })
);

export const updateSupplierMappingDS = ({
  orgAlias,
  integrationId,
  componentName,
  values,
}) => (
  pushDataDS({
    values,
    reqType: REQ_TYPE.PUT,
    pushApiUrl: accountingSupplierMappingApiUrl(orgAlias, integrationId),
    pushDataAC: responseData => [listUpdateItemAC(responseData.id, responseData, componentName)],
  })
);

export const createContractorDS = ({ componentName, integrationId, orgAlias, values }) => (
  dispatch => dispatch(
    pushDataDS({
      pushApiUrl: accountingCreateContractorApiUrl(orgAlias, integrationId),
      pushDataAC: responseData => [listUpdateItemAC(responseData.id, responseData, componentName)],
      reqType: REQ_TYPE.POST,
      values,
    }),
  )
);

export const createSystemOrgDS = ({ componentName, integrationId, orgAlias, values }) => (
  dispatch => dispatch(
    pushDataDS({
      pushApiUrl: accountingCreateSystemOrgApiUrl(orgAlias, integrationId),
      pushDataAC: responseData => [listUpdateItemAC(responseData.id, responseData, componentName)],
      reqType: REQ_TYPE.POST,
      values,
    }),
  )
);

export const syncIntegrationStatus = ({ componentName, integrationId, orgAlias }) => (
  dispatch => dispatch(
    pushDataDS({
      pushApiUrl: orgIntegrationSyncStatusApiUrl(orgAlias, integrationId),
      pushDataAC: responseData => {
        const actions = [];
        if (componentName) {
          actions.push(listUpdateItemAC(responseData.id, responseData, componentName));
        }
        return actions;
      },
      reqType: REQ_TYPE.PUT,
    }),
  )
);

export const retryInvoiceSyncDS = ({ componentName, integrationId, invoiceId, orgAlias }) => (
  dispatch => dispatch(
    pushDataDS({
      pushApiUrl: accountingRetryInvoiceSyncApiUrl(orgAlias, integrationId, invoiceId),
      pushDataAC: responseData => ([listAppendItemAC(responseData, componentName)]),
      reqType: REQ_TYPE.PUT,
    }),
  )
);
