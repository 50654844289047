import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Big from 'big.js';
import { isNaN } from 'lodash';

import {
  CURRENCIES_WITHOUT_DECIMALS, CURRENCY_BY_SYMBOL, CURRENCY_SYMBOL, CURRENCY_VALUES,
} from 'core/assets/js/constants';

/**
 * Number formating template
 * @param {Number|String}    options.value
 * @param {Number?}          options.decimals Number of decimals to show
 * @return {NumberFormat}
 */
function NumberTpl({ className, currency, decimals, prefix, value, ...rest }) {
  if (decimals === null) {
    throw new Error('Decimals is required');
  }

  let finalDecimals = decimals;
  let finalCurrency = currency;
  let finalValue = value;
  let finalPrefix = prefix;

  if (!currency && prefix) {
    finalCurrency = CURRENCY_BY_SYMBOL[prefix];
  }
  const noDecimals = CURRENCIES_WITHOUT_DECIMALS.includes(finalCurrency);
  if (noDecimals) {
    finalDecimals = 0;
  }

  if (noDecimals && finalValue.toString().includes('.')) {
    finalValue = finalValue.toString().split('.')[0];
  } else {
    finalValue =  value && new Big(value).toFixed(finalDecimals);
  }
  if (finalCurrency && !prefix) {
    finalPrefix = CURRENCY_SYMBOL[finalCurrency];
  }

  const isNumber = !isNaN(parseFloat(finalValue));

  return (
    <React.Fragment>
      {isNumber && (
        <NumberFormat
          className={className}
          decimalScale={finalDecimals || undefined}
          displayType="text"
          fixedDecimalScale={!!finalDecimals}
          prefix={finalPrefix}
          thousandSeparator
          value={finalValue}
          {...rest}
        />
      )}
      {!isNumber && finalValue}
    </React.Fragment>
  );
}

NumberTpl.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.oneOf(CURRENCY_VALUES),
  decimals: PropTypes.number,
  prefix: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

NumberTpl.defaultProps = {
  className: null,
  currency: null,
  decimals: 2,
  prefix: null,
  value: 0,
};

export default NumberTpl;
