import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { groupBy } from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import { projectViewTaskUrl } from 'projects/urls';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { getTaskPendingActions } from 'projects/assets/js/lib/helpers';
import { TASK_ACTION } from 'projects/assets/js/constants';
import { TASK_TABS, ICON } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import TaskParticipantAvatar from 'core/assets/js/components/DiscussionBoard/TaskParticipantAvatar.jsx';

const ParticipantsList = ({
  actions,
  participants,
  accessControl,
  selectedParticipant,
  match: { params: { orgAlias, id: projectId, taskId, userId: participantId } },
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const actionsPerUser = groupBy(actions.filter(act => !!act.targetUserId), 'targetUserId');

  const groupDiscussionUrl = projectViewTaskUrl(
    orgAlias, projectId, taskId, TASK_TABS.DISCUSSION,
  );
  const assignTaskUrl = projectViewTaskUrl(
    orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, TASK_ACTION.ASSIGN, participantId,
  );

  return (
    <ul
      className={`discussion-board__participants-list ${isExpanded ? 'discussion-board__participants-list--expanded' : ''}`}
    >
      {participants.length > 0 && (
        <TDElementWithTooltip
          delay={200}
          tooltipMsg={!isExpanded ? 'Group chat' : null}
          offset={[0, 6]}
        >
          <li
            className={`discussion-board__participants-list-item ${!selectedParticipant ? 'discussion-board__participants-list-item--active' : ''}`}
          >
            <Link to={groupDiscussionUrl}>
              <span className={`discussion-board__icon ${ICON.USERS_SOLID}`} />
              <span className="pl-3 hide-on-collapsed">
                <strong className="text-truncate">Group Chat</strong>
                <br />
                <span className="text-truncate">{`${pluralize('user', participants.length, true)}`}</span>
              </span>
            </Link>
          </li>
        </TDElementWithTooltip>
      )}

      {participants.map((participant) => {
        const {
          user, user: { id: userId }, assignment, isManager, isOwner, isDeleted,
        } = participant;
        const pendingActionsCount = getTaskPendingActions(actionsPerUser[userId]).length;
        const cssClasses = ['discussion-board__participants-list-item'];

        if (selectedParticipant && selectedParticipant.user.id === userId) {
          cssClasses.push('discussion-board__participants-list-item--active');
        }

        if (isDeleted) {
          cssClasses.push(
            'discussion-board__participants-list-item--with-status',
            'discussion-board__participants-list-item--inactive',
          );
        }

        return (
          <TaskParticipantAvatar
            key={`task-participant-${userId}`}
            user={user}
            assignment={assignment}
            className={cssClasses.join(' ')}
            isManager={isManager}
            isOwner={isOwner}
            allowedActions={accessControl}
            pendingActionsCount={pendingActionsCount}
            showCollapsibleDetails
          />
        );
      })}

      {accessControl.canAssignTask && (
        <TDElementWithTooltip
          delay={200}
          tooltipMsg={!isExpanded ? 'Add providers' : null}
          offset={[0, 6]}
        >
          <li className="discussion-board__participants-list-item">
            <Link to={assignTaskUrl}>
              <span className={`discussion-board__icon ${ICON.ADD_CIRCLE}`} />
              <span className="pl-3 hide-on-collapsed">
                <strong className="text-truncate">Add providers</strong>
              </span>
            </Link>
          </li>
        </TDElementWithTooltip>
      )}

      <TDElementWithTooltip
        delay={200}
        tooltipMsg={!isExpanded ? 'Expand' : null}
        offset={[0, 6]}
      >
        <li className="discussion-board__participants-list-toggle">
          <a onClick={() => setIsExpanded(!isExpanded)}>
            <span className={isExpanded ? ICON.COLLAPSE : ICON.EXPAND} />
            <span className="pl-3 hide-on-collapsed">Collapse</span>
          </a>
        </li>
      </TDElementWithTooltip>
    </ul>
  );
};

ParticipantsList.propTypes = {
  match: routerMatchSpec.isRequired,
  participants: PropTypes.array.isRequired,
  selectedParticipant: PropTypes.object,
  accessControl: PropTypes.object,
  actions: PropTypes.array,
};

ParticipantsList.defaultProps = {
  selectedParticipant: null,
  accessControl: {},
  actions: [],
};

export default withRouter(ParticipantsList);
