import React from 'react';
import PropTypes from 'prop-types';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import { IMG_SIZE } from 'core/assets/js/constants';

const ProjectManagerCheckableItem = ({ label, profile }) => {
  const classes = [
    'user-list-item',
    'user-item__content',
    'd-flex',
    'align-items-center',
    'flex-nowrap',
    'justify-content-between',
  ];

  if (label) {
    classes.push('has-label checkable-list__option p-4 mb-4');
  }

  return (
    <div className={classes.join(' ')}>
      <div className="d-flex">
        <span title={profile.name}>
          <ProfilePic
            alt={profile.name}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
            url={profile.avatar}
          />
        </span>

        <div className="user-item__basic-info pl-3">
          <span className="user-item__title truncate" title={profile.name}>{profile.name}</span>

          {profile.jobTitle &&  <div className="discreet">{profile.jobTitle}</div>}
        </div>
      </div>
      {label}
    </div>
  );
};

ProjectManagerCheckableItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  profile: profileSpec.isRequired,
};

ProjectManagerCheckableItem.defaultProps = {
  label: null,
};

export default ProjectManagerCheckableItem;
