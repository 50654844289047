import PropTypes from 'prop-types';

import { IMPORT_STATUS_VALUES, IMPORT_TYPE_VALUES } from 'importer/assets/js/constants';

export const importSpec = PropTypes.shape({ // eslint-disable-line import/prefer-default-export
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  rowCancelledCount: PropTypes.number.isRequired,
  rowCompleteCount: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowErrorsCount: PropTypes.number.isRequired,
  rowInProgressCount: PropTypes.number.isRequired,
  rowPendingCount: PropTypes.number.isRequired,
  startedAt: PropTypes.string,
  status: PropTypes.oneOf(IMPORT_STATUS_VALUES),
  type: PropTypes.oneOf(IMPORT_TYPE_VALUES),
});
