import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { IMG_SIZE, ICON } from 'core/assets/js/constants';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import TalentMarketIndicator from 'people/assets/js/components/TalentMarketIndicator.jsx';

const ChatRoomsListItemPic = ({ chat, showTalentMarketIndicator }) => {
  const activeUserCard = useSelector((state) => selectActiveUserCard(state));
  if (chat?.chatRoomUsers?.length > 2) {
    return (
      <span
        className={`chat__icon mr-3 ${ICON.USERS_SOLID}`}
        title={chat?.title ? chat?.title : 'Group Message'}
      />
    );
  }

  const user = chat?.chatRoomUsers?.find(
    (c) => c.userId !== activeUserCard?.user?.id,
  );

  return (
    <>
      <ProfilePic
        data-testid="contact-details-panel-profile-pic"
        url={user?.avatar}
        alt={user?.name}
        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
        className="mr-3"
      />
      {showTalentMarketIndicator && user?.isTalentMarket && <TalentMarketIndicator />}
    </>
  );
};

ChatRoomsListItemPic.propTypes = {
  chat: PropTypes.object.isRequired,
  showTalentMarketIndicator: PropTypes.bool,
};

ChatRoomsListItemPic.defaultProps = {
  showTalentMarketIndicator: true,
};

export default ChatRoomsListItemPic;
