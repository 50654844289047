import { isNaN, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { SALES_TAX_SCENARIOS, SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { settingsPaymentsSubPageUrl } from 'settings/urls';

const SalesTaxWarning = ({ activeOrganization, invoicingVatPercent, salesTaxScenario }) => {
  const companySettingsUrl = settingsPaymentsSubPageUrl(
    activeOrganization.alias, SETTINGS_PAYMENTS_TABS.INVOICE_DETAILS,
  );

  let formattedPercent = 'not set';
  if (isNumber(invoicingVatPercent) && !isNaN(invoicingVatPercent)) {
    formattedPercent = `set to ${invoicingVatPercent}%. This amount will automatically`
                        + ' be added to your invoices';
  }

  const commonWarningProps = { className: 'mb-5', type: BS_STYLE.WARNING };

  switch (salesTaxScenario) {
    case SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_NOT_UK:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="via-mode-contractor-not-uk"
          title="Sales tax automatically applied"
        >
          As we are using TalentDesk as an intermediary for your invoices, you are not
          {' eligible to add any VAT/sales tax to your invoices. '}
          <b>
            Please note that it is your responsibility for setting up the correct sales
            tax rate for invoices raised on your behalf by TalentDesk. If you believe the
            {' below to be incorrect, please update your company settings '}
            <Link to={companySettingsUrl}>here</Link>
            .
          </b>
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_UK__NOT_TAX_REGISTERED:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="via-mode-contractor-uk-not-vat-registered"
          title="Sales tax automatically applied"
        >
          As we are using TalentDesk as an intermediary for your invoices, you are not
          {' eligible to add any VAT/sales tax to your invoices. '}
          <b>
            Please note that it is your responsibility for setting up the correct sales
            tax rate for invoices raised on your behalf by TalentDesk. If you believe the
            {' below to be incorrect, please update your company settings '}
            <Link to={companySettingsUrl}>here</Link>
            .
          </b>
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_UK__TAX_REGISTERED:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="via-mode-contractor-uk-vat-registered"
          title="Sales tax automatically applied"
        >
          As we are using TalentDesk as an intermediary for your invoices and our
          records show that you are registered for VAT in the UK, your sales tax is
          set to 20%. This amount will automatically be added to your invoices.
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__TAX_REGISTERED:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="not-via-mode-org-uk-contractor-uk-vat-registered"
          title="Sales tax automatically applied"
        >
          Our records show that the organisation you are invoicing is in the UK and
          that you are registered for VAT. Your sales tax has been set to 20%. This
          {' amount will automatically be added to your invoices. '}
          <b>
            Please note that it is your responsibility for setting up the correct
            sales tax rate. If you believe the below to be incorrect, please update
            {' your company settings '}
            <Link to={companySettingsUrl}>here</Link>
            .
          </b>
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__NOT_TAX_REGISTERED:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="not-via-mode-org-uk-contractor-uk-not-vat-registered"
          title="Sales tax automatically applied"
        >
          Our records show that the organisation you are invoicing is in the UK and that
          you are not registered for VAT. You are not eligible to add any VAT/sales tax to
          {' your invoices. '}
          <b>
            Please note that it is your responsibility for setting up the correct sales
            tax rate. If you believe the below to be incorrect, please update your
            {' company settings '}
            <Link to={companySettingsUrl}>here</Link>
            .
          </b>
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_NOT_UK__CAN_EDIT:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="not-via-mode-org-uk-contractor-not-uk-can-edit"
          title="Review your sales tax"
        >
          {`You are invoicing ${activeOrganization.name}. Please set the correct sales tax below.`}
          {' '}
          <b>
            Please note that it is your responsibility for setting up the correct sales
            {' tax rate for invoices, please update your company settings '}
            <Link to={companySettingsUrl}>here</Link>
            .
          </b>
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_NOT_UK__CANNOT_EDIT:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="not-via-mode-org-uk-contractor-not-uk-cannot-edit"
          title="Sales tax automatically applied"
        >
          {`You are invoicing ${activeOrganization.name} and your sales tax is`}
          {` ${formattedPercent}. If you believe the below to be incorrect, please`}
          {' update your company settings '}
          <Link to={companySettingsUrl}>here</Link>
          {` or contact ${activeOrganization.name}.`}
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_UK:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="not-via-mode-org-not-uk-contractor-uk"
          title="Sales tax automatically applied"
        >
          Our records show that the organisation you are invoicing is not in the UK.
          {' You are not eligible to add any VAT/sales tax to your invoices. '}
          <b>
            Please note that it is your responsibility for setting up the correct sales
            {' tax rate for invoices, please update your company settings '}
            <Link to={companySettingsUrl}>here</Link>
            .
          </b>
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_NOT_UK__CAN_EDIT:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="not-via-mode-org-not-uk-contractor-not-uk-can-edit"
          title="Review your sales tax"
        >
          {`You are invoicing ${activeOrganization.name}. Please set the correct sales tax below.`}
          {' '}
          <b>
            Please note that it is your responsibility for setting up the correct sales
            {' tax rate for invoices, please update your company settings '}
            <Link to={companySettingsUrl}>here</Link>
            .
          </b>
        </TDSystemMessage>
      );
    case SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_NOT_UK__CANNOT_EDIT:
      return (
        <TDSystemMessage
          {...commonWarningProps}
          data-testid="not-via-mode-org-not-uk-contractor-not-uk-cannot-edit"
          title="Sales tax automatically applied"
        >
          {`You are invoicing ${activeOrganization.name} and your sales tax is`}
          {` ${formattedPercent}. If you believe the below to be incorrect, please`}
          {' update your company settings '}
          <Link to={companySettingsUrl}>here</Link>
          {` or contact ${activeOrganization.name}.`}
        </TDSystemMessage>
      );
    default:
      return null;
  }
};

SalesTaxWarning.propTypes = {
  activeOrganization: orgSpec,
  invoicingVatPercent: PropTypes.number,
  salesTaxScenario: PropTypes.oneOf(Object.values(SALES_TAX_SCENARIOS)),
};

SalesTaxWarning.defaultProps = {
  activeOrganization: {},
  invoicingVatPercent: null,
  salesTaxScenario: null,
};

const mapStateToProps = state => ({ activeOrganization: selectActiveOrg(state) });

export default connect(mapStateToProps)(SalesTaxWarning);
