import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgUserProfileUrl, orgPeopleDiscoverDetailUrl } from 'people/urls';
import { ICON, IMG_SIZE, USER_TYPE } from 'core/assets/js/constants';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

const ChatParticipantsList = ({
  participants,
  onAddParticipant,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { alias: orgAlias } = useSelector(selectActiveOrg);

  return (
    <ul
      className={`chat__participants-list ${isExpanded ? 'chat__participants-list--expanded' : ''} d-none d-md-flex`}
    >
      <li
        className="chat__participants-list-item"
      >
        <div>
          <span className={`chat__icon add__icon ${ICON.CIRCLE_PLUS}`} onClick={onAddParticipant} />
          <span className="pl-3 hide-on-collapsed">
            <strong className="text-truncate name">Add participants</strong>
          </span>
        </div>
      </li>

      {participants.map(({
        name, avatar, isManager, isTalentMarket, talentId, userId, allowedActions,
      }, idx) => {
        let profileUrl;
        if (isTalentMarket) {
          profileUrl = orgPeopleDiscoverDetailUrl(
            orgAlias,
            talentId,
          );
        } else {
          profileUrl = orgUserProfileUrl(
            orgAlias,
            isManager ? USER_TYPE.MANAGER : USER_TYPE.PROVIDER, userId,
          );
        }

        return (
          <li
            className="chat__participants-list-item"
            key={`${name}${idx}`}
          >
            <div>
              <TDElementWithTooltip
                delay={200}
                tooltipMsg={!isExpanded ? name : null}
                offset={[0, 6]}
              >
                <div>
                  {allowedActions?.canViewProfile && (
                    <Link to={profileUrl}>
                      <ProfilePic
                        url={avatar}
                        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                      />
                    </Link>
                  )}

                  {!allowedActions?.canViewProfile && (
                    <span>
                      <ProfilePic
                        url={avatar}
                        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                      />
                    </span>
                  )}
                </div>
              </TDElementWithTooltip>

              <span className="pl-3 hide-on-collapsed">
                <strong className="text-truncate name">{name}</strong>
              </span>
            </div>
          </li>
        );
      })}

      <li className="chat__participants-list-toggle mt-auto w-100">
        <div className="cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          <TDElementWithTooltip
            delay={200}
            tooltipMsg={!isExpanded ? 'Expand' : null}
            offset={[0, 6]}
          >
            <span className={`py-2 ${isExpanded ? ICON.COLLAPSE : ICON.EXPAND}`} />
          </TDElementWithTooltip>
          <span className="pl-3 hide-on-collapsed">Collapse</span>
        </div>
      </li>
    </ul>
  );
};

ChatParticipantsList.propTypes = {
  participants: PropTypes.array.isRequired,
  onAddParticipant: PropTypes.func.isRequired,
};


export default ChatParticipantsList;
