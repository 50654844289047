import React from 'react';

import { Card } from 'react-bootstrap';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import ProjectDistributionWidgetSkeleton from 'projects/assets/js/components/skeletons/ProjectDistributionWidgetSkeleton.jsx';

const ProjectWorksheetsWidgetSkeleton = () => (
  <React.Fragment>
    <div className="row">
      <div className="col-12 col-sm-6 mb-4">
        <ProjectDistributionWidgetSkeleton header="Worksheets" />
      </div>
      <div className="col-12 col-sm-6 mb-4">
        <ProjectDistributionWidgetSkeleton header="Expenses" />
      </div>
      <AllowAccessToManager>
        <div className="col-12 mb-4">
          <Card className="project-widget__details">
            <Card.Header>
              <div className="d-flex align-items-center">
                <span className="d-inline-block m-0 p-0">Worksheets / Expenses</span>
                <span className="ml-auto">
                  <RectangleSkeleton width="70" height="20" />
                </span>
              </div>
            </Card.Header>
            <Card.Body className="project-worksheets-list">
              <div className="row mb-3">
                <div className="col-10 d-flex align-items-center">
                  <CircleSkeleton className="float-left" diameter="36" />
                  <RectangleSkeleton className="col-7" height="20" />
                </div>
                <div className="col-2 ml-auto d-flex align-items-center text-right">
                  <RectangleSkeleton className="w-100" height="20" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-10 d-flex align-items-center">
                  <CircleSkeleton className="float-left" diameter="36" />
                  <RectangleSkeleton className="col-7" height="20" />
                </div>
                <div className="col-2 ml-auto d-flex align-items-center text-right">
                  <RectangleSkeleton className="w-100" height="20" />
                </div>
              </div>
              <div className="row">
                <div className="col-10 d-flex align-items-center">
                  <CircleSkeleton className="float-left" diameter="36" />
                  <RectangleSkeleton className="col-7" height="20" />
                </div>
                <div className="col-2 ml-auto d-flex align-items-center text-right">
                  <RectangleSkeleton className="w-100" height="20" />
                </div>
              </div>
            </Card.Body>
            <Card.Footer
              className="p-4"
            >
              <div className="project-worksheets-list__footer row mx-0 ">
                <div className="d-flex align-items-center col-12 col-sm-4 pl-0 project-worksheets-list__include-managers-container">
                  <RectangleSkeleton width="100" height="20" />
                </div>
                <div className="col-12 col-sm-8 pr-0 text-right project-worksheets-list__total-container">
                  <RectangleSkeleton className="pr-0 mr-0" width="80" height="20" />
                </div>
              </div>
            </Card.Footer>
          </Card>
        </div>
      </AllowAccessToManager>
    </div>
  </React.Fragment>

);

export default ProjectWorksheetsWidgetSkeleton;
