import React from 'react';
import { Card } from 'react-bootstrap';

import RectangleSkeleton from './RectangleSkeleton.jsx';

const UserTimeOffWidgetSkeleton = () => (
  <Card className="mt-5">
    <Card.Header>
      Availability
    </Card.Header>

    <Card.Body>
      <div className="d-flex mb-3">
        <RectangleSkeleton width="53" height="13" />
        <RectangleSkeleton width="60" height="13" />
        <span className="ml-auto">
          <RectangleSkeleton width="95" height="13" />
        </span>
      </div>

      <div className="d-flex mb-3">
        <RectangleSkeleton width="50" height="13" />
        <RectangleSkeleton width="55" height="13" />
        <span className="ml-auto">
          <RectangleSkeleton width="110" height="13" />
        </span>
      </div>
    </Card.Body>
  </Card>
);

export default UserTimeOffWidgetSkeleton;
