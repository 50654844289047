import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import ModalConfirmFinalForm from 'core/assets/js/components/ModalConfirmFinalForm.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { viewFetchExtrasAC, viewUpdateAC } from 'core/assets/js/ducks/view';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import ProjectFundsBreakdown from 'projects/assets/js/components/ProjectFundsBreakdown.jsx';
import {
  PROJECT_COMPLETION_FUNDS,
  PROJECT_COMPLETION_FUNDS_LABEL,
  PROJECT_STATE_CHANGE,
  PROJECT_TASKS_STATUS_CHANGE,
  PROJECT_TASKS_STATUS_CHANGE_LABEL,
} from 'projects/assets/js/constants';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectStateUpdateApiUrl } from 'projects/urls';

export const MODAL_ID = 'project-complete-modal';

const RETURN_FUNDS_OPTIONS = Object.values(PROJECT_COMPLETION_FUNDS).map(value => ({
  text: PROJECT_COMPLETION_FUNDS_LABEL[value], value,
}));

const TASKS_STATUS_OPTIONS = Object.values(PROJECT_TASKS_STATUS_CHANGE).map(value => ({
  text: PROJECT_TASKS_STATUS_CHANGE_LABEL[value], value,
}));

const ProjectCompleteModal = ({
  PendingDocumentsLabelComponent, pendingTasksCount, project, rootComponentName,
}) => {
  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const dispatch = useDispatch();
  const { id: projectId, orgAlias } = useParams();
  const onClose = () => dispatch(modalCloseAC());

  return (
    <ModalConfirmFinalForm
      confirmLabel="Mark as completed"
      FormContentComponent={({ renderProps }) => {
        const { submitError, submitting } = renderProps;
        return (
          <>
            <p className="mb-4" data-testid="project-finalize-form-text">
              The managers and providers that participate in the project will get notified.
            </p>
            {pendingTasksCount > 0 && (
              <>
                <TDSystemMessage
                  className="mb-4 mt-4"
                  title="There are pending tasks"
                  type={BS_STYLE.WARNING}
                >
                  <PendingDocumentsLabelComponent
                    count={pendingTasksCount}
                    label="pending Task"
                    prefix="There"
                  />
                  {` that ${pluralize('need', pendingTasksCount)} `}
                  to be completed, stopped or archived before the project can be completed.
                </TDSystemMessage>
                <SelectField
                  data-testid="project-task-status-change"
                  disabled={submitting}
                  label="What do you want to do with these tasks?"
                  name="tasksChangeStatus"
                  optionsMapping={TASKS_STATUS_OPTIONS}
                />
              </>
            )}
            {project.budgetBreakdown && parseInt(project.budgetBreakdown.available, 10) > 0 && (
              <>
                <p className="mb-4">There is remaining budget in the project.</p>
                <ProjectFundsBreakdown
                  budgetBreakdown={project.budgetBreakdown}
                  className="simple my-5"
                  projectCurrency={project.currency_symbol}
                />
                <SelectField
                  data-testid="project-finalize-form-deallocate"
                  disabled={submitting}
                  label="What would you like to do with the unused budget from this project?"
                  name="deallocateAllFunds"
                  optionsMapping={RETURN_FUNDS_OPTIONS}
                />
              </>
            )}
            {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
          </>
        );
      }}
      formProps={{
        initialValues: { deallocateAllFunds: PROJECT_COMPLETION_FUNDS.RETURN_TO_ORG_OWNER },
        onSubmit: async values => { // eslint-disable-line consistent-return
          try {
            const { data } = await axios.put(
              projectStateUpdateApiUrl(
                orgAlias, projectId, PROJECT_STATE_CHANGE.MARK_AS_COMPLETED,
              ),
              values,
            );
            dispatch(viewUpdateAC(data.project, rootComponentName));
            dispatch(viewFetchExtrasAC(data.allowedActions, rootComponentName, 'allowedActions'));
            onClose();
          } catch (err) {
            return parseAxiosErrorForFinalForm(err);
          }
        },
      }}
      heading="Is the job done?"
      onClose={onClose}
      open={isOpen}
    />
  );
};

ProjectCompleteModal.propTypes = {
  PendingDocumentsLabelComponent: PropTypes.func.isRequired,
  pendingTasksCount: PropTypes.number.isRequired,
  project: projectSpec.isRequired,
  rootComponentName: PropTypes.string.isRequired,
};

export default ProjectCompleteModal;
