import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';

export const DELETE_SKILL_MODAL_ID = 'delete-skill-modal';

const RemoveSkillModal = ({
  dispatch, open, skill, onConfirm,
}) => {
  const {
    id: skillId, copiedByUser, isManagerEndorsed, label: skillLabel, votesCount,
  } = skill;
  const isOnlyUpvoted = !isManagerEndorsed && copiedByUser && votesCount > 0;

  return (
    <ModalConfirm
      heading="Remove skill"
      confirmLabel="Remove"
      open={open}
      closeOnConfirm={false}
      onClose={() => dispatch(modalCloseAC(DELETE_SKILL_MODAL_ID))}
      onConfirm={() => onConfirm(skillId)}
    >
      {isOnlyUpvoted && (
        <p>
          You are about to remove
          <strong>{ ` "${skillLabel}" ` }</strong>
          from your skills. Please note that by doing so, you will also lose
          the skill votes, and this change is not reversible.
        </p>
      )}

      <p>
        Are you sure you want to remove
        <strong>{ ` "${skillLabel}" ` }</strong>
        ?
      </p>
    </ModalConfirm>
  );
};

RemoveSkillModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  skill: skillSpec,
  onConfirm: PropTypes.func.isRequired,
};

RemoveSkillModal.defaultProps = {
  open: false,
  skill: null,
};

const mapStateToProps = (state) => {
  const modalPayload = getModalPayload(state, DELETE_SKILL_MODAL_ID) || {};
  return {
    open: getIsModalOpen(state, DELETE_SKILL_MODAL_ID),
    skill: modalPayload?.skill,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const RemoveSkillModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveSkillModal);

export default RemoveSkillModalConnected;
