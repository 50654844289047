import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { signupInviteUrl } from 'accounts/urls';
import { browserRedirect } from 'core/assets/js/lib/utils';
import { BS_STYLE, ICON, IMG_SIZE } from 'core/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { STATUS, TYPE, ORG_INVITATION_TYPES, PROJECT_INVITATION_TYPES } from 'invitations/assets/js/constants';
import {
  acceptInvitationActiveDS, rejectInvitationActiveDS,
} from 'invitations/assets/js/ducks/invitation';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import { fetchOrgListDS } from 'organizations/assets/js/data-services/list';
import { refreshPendingInvitationsDS } from 'core/assets/js/ducks/pendingInvitations';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';


class InvitationCard extends React.Component {
  constructor(props) {
    super(props);
    this.accept = this.accept.bind(this);
    this.cancel = this.cancel.bind(this);
    this.reject = this.reject.bind(this);
    this.done = this.done.bind(this);
  }

  accept() {
    const { dispatch, invitation } = this.props;
    return dispatch(acceptInvitationActiveDS(invitation.token)).then(async () => {
      await dispatch(refreshPendingInvitationsDS());
      // IMPORTANT: Since the user just accepted in a new organisation, we need to update the
      //            org list in the store, so that CoreRoute component will activate the
      //            newly created org!
      await dispatch(fetchOrgListDS());
    });
  }

  reject() {
    const { dispatch, invitation } = this.props;
    return dispatch(rejectInvitationActiveDS(invitation.token)).then(async () => {
      await dispatch(refreshPendingInvitationsDS());
    });
  }

  cancel() {
    const { history, invitation } = this.props;
    history.push(signupInviteUrl(invitation.token));
  }

  done() {
    const { history, invitation, doneUrl } = this.props;
    if (invitation.status === STATUS.REJECTED) {
      browserRedirect('/');
    } else {
      history.push(doneUrl);
    }
  }

  render() {
    const {
      allowAccept, allowReject, cancelUrl, history, invitation, isValid,
      pendingInvitationMessage, showCard,
    } = this.props;

    const contClassName = ['clearfix'];
    const email = 'support@talentdesk.io';
    const subject = invitation.type === TYPE.INVOICE_DOWNLOAD
      ? 'Invoice can\'t be downloaded'
      : 'Invitation Expired';

    const newLine = '%0D%0A';
    const body = [
      `My invitation to join TalentDesk.io that was sent on "${invitation.sentOn}" has expired.`,
      newLine,
      `Invitee email: "${invitation.sentTo}"`,
      `Invitation token: "${invitation.token}"`,
    ];

    const mailToEl = (
      <a
        rel="noopener noreferrer"
        href={`mailto:${email}?subject=${subject}&body=${body.join(newLine)}`}
      >
        here
      </a>
    );

    let pendingMessage = pendingInvitationMessage;
    if (!pendingMessage) {
      if (ORG_INVITATION_TYPES.includes(invitation.type)) {
        pendingMessage = 'You have been invited to join the organisation';
      } else if (PROJECT_INVITATION_TYPES.includes(invitation.type)) {
        pendingMessage = 'You have been invited to join';
        pendingMessage += invitation.project
          ? ` project “${invitation.project.title}”`
          : ' this project';
      } else {
        pendingMessage = 'You have been invited to join';
      }
    }

    if (showCard) {
      contClassName.push('invitation-card rounded shadow-sm p-4 bg-white');
    }

    return (
      <div className={contClassName.join(' ')}>
        { // The invitation is invalid
          !isValid && (
            <React.Fragment>
              <TDSystemMessage
                type="danger"
                className="mb-5"
                title="Invalid invitation"
              >
                <p>The invitation does not exist or sent originally to you</p>
              </TDSystemMessage>
              <div>
                <TDButton
                  className="col-12 col-sm-3 d-flex mx-auto justify-content-center"
                  variant={BS_STYLE.DEFAULT}
                  onClick={this.done}
                  label="Done"
                />
              </div>
            </React.Fragment>
          )}
        { // The invitation is valid
          isValid && invitation && (
            <div>
              <div className="invitation-card__org-info">
                { invitation.orgName && (
                  <OrganizationLogo
                    className="mb-4"
                    url={invitation.orgLogo}
                    orgName={invitation.orgName}
                    size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
                  />
                )}
                <h3>{invitation.orgName}</h3>
              </div>
              { invitation.status === STATUS.ACCEPTED && (
                <React.Fragment>
                  <TDSystemMessage
                    type="success"
                    className="mb-5"
                    title="Invitation accepted"
                  >
                    <p>The invitation has been accepted</p>
                  </TDSystemMessage>
                  <div>
                    <TDButton
                      data-testid="invitation-card-button-done"
                      className="col-12 col-sm-3 d-flex mx-auto justify-content-center"
                      variant={BS_STYLE.DEFAULT}
                      onClick={this.done}
                      label="Done"
                    />
                  </div>
                </React.Fragment>
              )}
              { invitation.status === STATUS.REJECTED && (
                <React.Fragment>
                  <TDSystemMessage
                    type="danger"
                    className="mb-5"
                    title="Invitation rejected"
                  >
                    <p>The invitation has been rejected</p>
                  </TDSystemMessage>
                  <div>
                    <TDButton
                      className="col-12 col-sm-3 d-flex mx-auto justify-content-center"
                      variant={BS_STYLE.DEFAULT}
                      onClick={this.done}
                      label="Done"
                    />
                  </div>
                </React.Fragment>
              )}
              { invitation.status === STATUS.PENDING && invitation.hasExpired && (
                <TDSystemMessage
                  type="warning"
                  title={subject}
                  className="mb-5"
                >
                  {invitation.type !== TYPE.INVOICE_DOWNLOAD && (
                    <p>
                      Your invitation has expired. Click
                      {' '}
                      {mailToEl}
                      {' '}
                      to contact us and renew your invitation.
                    </p>
                  )}
                  {invitation.type === TYPE.INVOICE_DOWNLOAD && (
                    <p>
                      This invoice link is no longer active. You can login with your TalentDesk.io
                      account and download the invoice through Finance &gt; Invoices.
                    </p>
                  )}
                </TDSystemMessage>
              )}
              { invitation.status === STATUS.CANCELLED && (
                <TDSystemMessage
                  type="warning"
                  className="mb-5"
                  title="Invitation cancelled"
                >
                  <p>Your invitation has been cancelled.</p>
                </TDSystemMessage>
              )}
              { invitation.status === STATUS.PENDING && !invitation.hasExpired && (
                <div className="text-center mb-4">
                  <p key={1}>{pendingMessage}</p>
                  <div key={2} className="d-flex mt-5 justify-content-center">
                    { cancelUrl && (
                      <TDButton
                        variant={BS_STYLE.DEFAULT}
                        label="Cancel"
                        onClick={() => history.push(cancelUrl)}
                        rounded
                      />
                    )}
                    { allowReject && (
                      <TDButton
                        variant={BS_STYLE.DANGER}
                        btnIcon={ICON.CROSS}
                        label="Reject"
                        onClick={() => this.reject()}
                      />
                    )}
                    { allowAccept && (
                      <TDButton
                        variant={BS_STYLE.SUCCESS}
                        btnIcon={ICON.CHECKMARK}
                        label="Accept"
                        onClick={() => this.accept()}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}

InvitationCard.propTypes = {
  allowAccept: PropTypes.bool,
  allowReject: PropTypes.bool,
  cancelUrl: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  doneUrl: PropTypes.string.isRequired,
  history: routerHistorySpec.isRequired,
  invitation: invitationSpec.isRequired,
  pendingInvitationMessage: PropTypes.string,
  showCard: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  componentName: PropTypes.string.isRequired,
};
InvitationCard.defaultProps = {
  allowAccept: false,
  allowReject: true,
  cancelUrl: null,
  pendingInvitationMessage: null,
  showCard: true,
};

export default withRouter(InvitationCard);
