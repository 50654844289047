import React from 'react';
import PropTypes from 'prop-types';

import CircleSkeleton from './CircleSkeleton.jsx';
import RectangleSkeleton from './RectangleSkeleton.jsx';


const PeopleCardSkeleton = ({ className }) => (
  <div className={['skeleton--people-list-item clearfix', className].join(' ')}>
    <div className="float-left">
      <CircleSkeleton className="float-left" diameter="36" />
      <RectangleSkeleton width="183" height="13" />
      <br />
      <RectangleSkeleton width="60" height="10" />
    </div>
  </div>
);

PeopleCardSkeleton.propTypes = {
  className: PropTypes.string,
};

PeopleCardSkeleton.defaultProps = {
  className: '',
};

export default PeopleCardSkeleton;
