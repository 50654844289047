import {
  documentAssignmentGetApiUrl,
  documentViewApiUrl,
  sendSignableEnvelopeApiUrl,
} from 'documents/urls';

import { viewFetchAC, viewFetchErrorAC } from 'core/assets/js/ducks/view';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';

const _validateParams = ({ id, orgAlias }) => {
  if (!orgAlias || !id) {
    throw new Error(`[_fetchDocumentDS Error] Invalid orgAlias (${orgAlias}) or id (${id}) params`);
  }
};

export const fetchDocumentDS = ({
  authedAxios = null, componentName, id, orgAlias,
}) => fetchDataDS({
  authedAxios,
  fetchApiUrl: () => documentViewApiUrl(orgAlias, id),
  fetchDataAC: (responseData) => ([viewFetchAC(responseData, componentName)]),
  fetchDataErrorAC: viewFetchErrorAC,
  validate: () => _validateParams({ id, orgAlias }),
});

export const fetchDocumentAssignmentDS = ({
  authedAxios = null, componentName, id, orgAlias,
}) => fetchDataDS({
  authedAxios,
  fetchApiUrl: () => documentAssignmentGetApiUrl(orgAlias, id),
  fetchDataAC: responseData => ([viewFetchAC(responseData, componentName)]),
  fetchDataErrorAC: viewFetchErrorAC,
  validate: () => _validateParams({ id, orgAlias }),
});

export const sendSignableEnvelopeDS = ({
  authedAxios = null, componentName, id, orgAlias,
}) => pushDataDS({
  authedAxios,
  pushApiUrl: sendSignableEnvelopeApiUrl(orgAlias, id),
  pushDataAC: responseData => ([viewFetchAC(responseData, componentName)]),
  fetchDataErrorAC: viewFetchErrorAC,
});
