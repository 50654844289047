import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { interviewQuestionSpec } from 'interviews/assets/js/lib/objectSpecs';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { ICON } from 'core/assets/js/constants';

function SubmissionCardItem({ id, name, questions, allowedActions, url }) {
  const viewLink = (
    <TDElementWithTooltip
      id={id}
      tooltipMsg={allowedActions.canSubmit ? 'Submit Interview' : 'View Submission'}
      el={(
        <Link to={url} className="btn btn-link">
          <span className={allowedActions.canSubmit ? ICON.EDIT : ICON.VIEW} />
        </Link>
      )}
    />
  );
  return (
    <Card>
      <Card.Body className="row">
        <div className="col-9">
          <h3 className="interview-list-item__name">
            {name}
          </h3>
          <div>
            <span className="interview-list-item__num-questions">
              {pluralize('question', questions.length, true)}
            </span>
          </div>
        </div>

        <div className="col-3 text-right button-container">
          {viewLink}
        </div>
      </Card.Body>
    </Card>
  );
}

SubmissionCardItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(interviewQuestionSpec).isRequired,
  allowedActions: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
};

export default SubmissionCardItem;
