import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the percentage indicator in a form of a circle area.
 * It supports 6 steps (0-5)
 */

const CircleArea = ({ percent }) => {
  const step = parseInt((5 * percent) / 100, 10);
  return (
    <span
      className={`circle-area--container circle-area--fill-${step}`}
    >
      <span className="circle-area--circle-one" />
      <span className="circle-area--circle-two" />
    </span>
  );
};

CircleArea.propTypes = {
  percent: PropTypes.number,
};
CircleArea.defaultProps = {
  percent: 0,
};

export default CircleArea;
