import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import InvoiceWorksheetItemsBreakdownCard from 'finance/assets/js/components/InvoiceWorksheetItemsBreakdownCard.jsx';
import { financeInvoiceItemListApiUrl } from 'finance/urls';
import { fetchFromApi } from 'core/assets/js/lib/dataServices';

class InvoiceItemsModalTable extends React.PureComponent {
  constructor(props) {
    super(props);
    const { invoiceItems } = props;
    this.state = {
      items: !isEmpty(invoiceItems) ? invoiceItems : [],
    };
  }

  componentDidMount() {
    const { orgAlias, ids, invoiceItems } = this.props;
    if (isEmpty(invoiceItems)) {
      // in the case of on demand invoices, we are passing the invoice items to the invoice manually
      // We do so, because the on demand invoice preview is transaction wrapped.
      // Because of this we won't be able to fetch the invoice items from the API
      // when the user clicks the invoice preview row, because by then the invoice items will
      // not exist.
      fetchFromApi({ url: () => financeInvoiceItemListApiUrl(orgAlias, ids) })
        .then(response => this.setState({ items: response.data.items }));
    }
  }

  render() {
    const { items } = this.state;

    if (items.length === 0) {
      return <ListSkeleton />;
    }

    return items.map(item => (
      <InvoiceWorksheetItemsBreakdownCard
        key={item.id}
        item={item}
      />
    ));
  }
}

InvoiceItemsModalTable.propTypes = {
  ids: PropTypes.array.isRequired,
  orgAlias: PropTypes.string.isRequired,
  invoiceItems: PropTypes.array,
};

InvoiceItemsModalTable.defaultProps = {
  invoiceItems: [],
};

export default InvoiceItemsModalTable;
