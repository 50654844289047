import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import UserGroupListSkeleton from 'core/assets/js/components/Skeleton/UserGroupListSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { fetchListDS, getListState, listUpdateItemAC } from 'core/assets/js/ducks/list';
import TDList from 'core/assets/js/components/TDList.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import UserGroupAvatar from 'people/assets/js/components/UserGroupAvatar.jsx';
import UserGroupsListSearch from 'people/assets/js/components/UserGroupsListSearch.jsx';
import { userGroupAddMembersDS, userGroupRemoveMemberDS } from 'people/assets/js/data-services/list';
import { userGroupsListApiUrl } from 'people/urls';

export const MODAL_ID = 'manage-users-groups-modal';

const ManageUsersGroupsModal = () => {
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);
  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const { userId, userName } = useSelector(state => getModalPayload(state, MODAL_ID)) || {};
  const componentName = ManageUsersGroupsModal.GetComponentName({ params: { userId } });
  const [queryParams, setQueryParams] = useState({});

  const qs = queryString.stringify({
    ...queryParams, excludeDynamic: true, getMemberShipStatusForUserId: userId,
  });

  const loadGroups = () => {
    dispatch(fetchListDS({
      componentName,
      url: `${userGroupsListApiUrl({ orgAlias: activeOrg.alias })}?${qs}`,
    }));
  };

  useEffect(() => {
    if (!userId || !isOpen) {
      return;
    }
    // we are managing the query params in useState, as this is inside a modal which is displayed
    // over the paginated teams pages, so we don't want to change the url query params
    loadGroups();
  }, [isOpen, userId, qs]);

  const { hasLoaded, items, pagination } = useSelector(state => getListState(state, componentName));

  const onlyGroupsUserIsAMemberOf = !!queryParams.memberUserId;

  return (
    <ModalConfirm
      heading={`Manage ${userName ? `${userName}'s ` : ''}groups`}
      open={isOpen}
      onClose={() => dispatch(modalCloseAC())}
      showConfirmButton={false}
      size={MODAL_SIZES.LARGE}
    >
      <UserGroupsListSearch
        extraSearchElement={(
          <div className="d-flex align-items-center ml-0 mt-5 ml-lg-5 mt-lg-0">
            <TDSwitch
              className="mr-3"
              onClick={() => {
                const newQueryParams = { ...queryParams };
                if (onlyGroupsUserIsAMemberOf) {
                  delete newQueryParams.memberUserId;
                } else {
                  newQueryParams.memberUserId = userId;
                }
                setQueryParams(newQueryParams);
              }}
              selected={onlyGroupsUserIsAMemberOf}
            />
            <span className="mr-2">
              {`Only show groups ${userName || 'user'} is a member of`}
            </span>
          </div>
        )}
        onFiltersChanged={setQueryParams}
        queryParams={queryParams}
      />
      {hasLoaded && (
        <TDList
          cardItem={{
            component: ({ isLastItem, item, item: { id, name, isMember } }) => {
              const wrapperClasses = [
                'manage-user-groups-modal-item',
                'rounded',
                'p-4',
                'bg-white',
                'd-flex',
                'align-items-center',
                'justify-content-between',
              ];
              if (!isLastItem) {
                wrapperClasses.push('mb-2');
              }
              return (
                <div className={wrapperClasses.join(' ')}>
                  <div className="d-flex align-items-center">
                    <UserGroupAvatar iconClassName="fa-2x" {...item} />
                    <span className="ml-5">{name}</span>
                  </div>
                  {isMember && (
                    <TDButton
                      label="Remove"
                      onClick={async () => {
                        try {
                          await dispatch(userGroupRemoveMemberDS({
                            orgAlias: activeOrg.alias,
                            userGroupId: id,
                            userId,
                          }));
                          dispatch(listUpdateItemAC(id, { isMember: false }, componentName));
                          toastr.success(
                            'Well Done!', `${userName} has been removed from group ${name}`,
                          );
                        } catch (e) {
                          toastr.error('Oh Snap!', e.response?.data?._error || e.message);
                        }
                      }}
                      variant={BS_STYLE.DANGER}
                    />
                  )}
                  {!isMember && (
                    <TDButton
                      label="Add"
                      onClick={async () => {
                        try {
                          const { count } = await dispatch(userGroupAddMembersDS({
                            orgAlias: activeOrg.alias,
                            userGroupId: id,
                            values: { userIds: [userId] },
                          }));
                          if (count === 0) {
                            toastr.warning(
                              'Not added', `${userName} was not added to group ${name}`,
                            );
                          } else {
                            dispatch(listUpdateItemAC(id, { isMember: true }, componentName));
                            toastr.success(
                              'Well Done!', `${userName} has been added to group ${name}`,
                            );
                          }
                        } catch (e) {
                          toastr.error('Oh Snap!', e.response?.data?._error || e.message);
                        }
                      }}
                      variant={BS_STYLE.SUCCESS}
                    />
                  )}
                </div>
              );
            },
          }}
          emptyListMessage={(
            onlyGroupsUserIsAMemberOf
              ? `No groups found that ${userName} is a member of`
              : 'No groups found'
          )}
          items={items}
          listClassName="manage-user-groups-modal-list"
          onPageChange={changes => setQueryParams({ ...queryParams, ...changes })}
          pagination={pagination}
        />
      )}
      {!hasLoaded && <UserGroupListSkeleton />}
    </ModalConfirm>
  );
};

ManageUsersGroupsModal.GetComponentName = ({ params }) => `ManageUsersGroupsModal-${params.userId}`;

export default ManageUsersGroupsModal;
