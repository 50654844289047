import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';

const ListItem = ({ isReview }) => (
  <Card className="card--light-gray border-0 shadow-none mb-4">
    <Card.Body className="note-review-card-item flex-column p-4">
      <div className="row">
        <div className="col-auto d-flex align-items-center">
          <div className="note-review-card-item__profile-pic">
            <CircleSkeleton diameter="50" />
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div className="note-review-card-item__name">
              <div>
                <RectangleSkeleton width="130" height="16" />
              </div>
              <div className="note-review-card-item__job-title my-2 mb-sm-0">
                <RectangleSkeleton width="90" height="13" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-block text-right mr-0 col">
          {isReview && (
            <RectangleSkeleton
              width="145"
              height="14"
            />
          )}
        </div>
      </div>

      <p className="note-review-card-item__content mt-4 mb-0">
        <RectangleSkeleton width="300" height="15" />
      </p>

      <div className="note-review-card-item__meta d-flex pt-4 mt-3">
        <RectangleSkeleton className="d-none d-md-inline-block" width="170" height="13" />

        <div className="discreet ml-auto">
          <RectangleSkeleton width="170" height="13" />
        </div>
      </div>
    </Card.Body>
  </Card>
);
ListItem.propTypes = {
  isReview: PropTypes.bool.isRequired,
};

const PeopleReviewsNotesTabSkeleton = ({ isReview }) => (
  <div className="px-4">
    <ListItem isReview={isReview} />
    <ListItem isReview={isReview} />
  </div>
);
PeopleReviewsNotesTabSkeleton.propTypes = {
  isReview: PropTypes.bool,
};
PeopleReviewsNotesTabSkeleton.defaultProps = {
  isReview: false,
};
export default PeopleReviewsNotesTabSkeleton;
