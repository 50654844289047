import React from 'react';
import PropTypes from 'prop-types';
import { INVOICE_TYPE } from 'finance/assets/js/constants';
import FeeAnalysisTable from 'finance/assets/js/components/invoicing/FeeAnalysisTable.jsx';
import SimpleContractorChargesTable from 'finance/assets/js/components/invoicing/SimpleContractorChargesTable.jsx';
import InvoiceMainTableTotals from 'finance/assets/js/components/invoicing/InvoiceMainTableTotals.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import Money from 'finance/assets/js/lib/Money';

class SimpleInvoiceMainTable extends React.PureComponent {
  render() {
    const { invoicingSettings, invoice, owner } = this.props;
    const { orgAlias } = invoicingSettings;
    if (!invoice || !invoice.amounts) {
      return null;
    }
    if (!invoicingSettings) {
      throw new Error('undefined invoicing settings');
    }

    if (!invoicingSettings.feeScheme) {
      throw new Error('undefined fee scheme');
    }

    const { month } = invoice;
    const { credit, currency } = invoice.amounts;
    const isOutbound = invoice.type === INVOICE_TYPE.OUTBOUND;
    const hasFees = isOutbound;
    const hasCredit = !new Money(credit, currency).isZero();
    const justFees = hasFees && !hasCredit;

    const invoiceAmounts = new InvoiceAmounts(invoice.amounts);
    const hasPaymentProcessingFees = invoiceAmounts.hasProcessingFee();

    return (
      <div>
        {justFees ? (
          <FeeAnalysisTable
            hasTotals
            month={month}
            amounts={invoice.amounts}
            invoicingSettings={invoicingSettings}
          />
        ) : (
          <SimpleContractorChargesTable
            invoice={invoice}
            orgAlias={orgAlias}
            invoicingSettings={invoicingSettings}
          />
        )}
        <InvoiceMainTableTotals
          owner={owner}
          month={month}
          amounts={invoice.amounts}
          withFeeModal={hasPaymentProcessingFees}
          invoicingSettings={invoicingSettings}
          isOutbound={isOutbound}
          invoiceDate={invoice.date}
        />
      </div>
    );
  }
}

SimpleInvoiceMainTable.propTypes = {
  invoice: PropTypes.object.isRequired,
  invoicingSettings: PropTypes.object,
  owner: PropTypes.object,
};

SimpleInvoiceMainTable.defaultProps = {
  invoicingSettings: {},
  owner: {},
};

export default SimpleInvoiceMainTable;
