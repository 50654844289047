import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const ProcessingLabel = () => (
  <>
    Processing
    <ElementWithPopOver
      className="d-inline-block ml-2"
      popOverContent={(
        `We have received payment for your invoice from the organisation and we are currently
        processing your payment.`
      )}
      popOverTitle="Processing"
    />
  </>
);

export default ProcessingLabel;
