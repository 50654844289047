import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import { differenceWith, map, isEqual, isEmpty } from 'lodash';

import SelectableListToggleElement from 'core/assets/js/components/SelectableListToggleElement.jsx';
import SelectableItem from 'core/assets/js/components/SelectableItem.jsx';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import {
  getListState, listIsSelectionModeEnabledAC, listResetSelectItemsAC,
  listRemoveSelectedItemsAC, listAddSelectedItemsAC, listHasAllPageItemsSelectedAC,
} from 'core/assets/js/ducks/list';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';

/**
 * Wraps a component (Typically a View with a TDList) and provides
 * props related to the selected items.
 *
 * If "selectable" prop is set to false, the wrapper will act just like a regular TDList.
 * (It will just return the TDList without any selectable-related actions/buttons).
 * @param {Object} props
 * @param {Boolean} props.selectable - it enables multiple selection
 * It will not show multiple selection dropdown if selectModeEnabled is set to true
 * @param {Boolean} props.selectModeEnabled - It will show multiple selection checkboxes by default
 */
class SelectableListWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _showSelectedItems: false,
    };

    this.handleShowSelectedItemsToggle = this.handleShowSelectedItemsToggle.bind(this);
    this.handleSelectPageItemsToggle = this.handleSelectPageItemsToggle.bind(this);
    this.handleSelectModeToggle = this.handleSelectModeToggle.bind(this);
    this.shouldShowSelectedItems = this.shouldShowSelectedItems.bind(this);
  }

  componentDidMount() {
    const { dispatch, selectModeEnabled, componentName } = this.props;
    if (selectModeEnabled) {
      dispatch(listIsSelectionModeEnabledAC(true, componentName));
    }
  }

  /* When changing pages, we need to check if all items of the next data set are selected
   * so that we pre-check the "select all" checkbox.
   */
  componentDidUpdate(prevProps) {
    const {
      dispatch, isSelectionModeEnabled, componentName, selectedItems, items, pagination,
    } = this.props;
    const { _showSelectedItems } = this.state;
    if (
      prevProps.pagination && pagination && prevProps.pagination.page !== pagination.page
      && isSelectionModeEnabled) {
      const itemsToCheckFor = _showSelectedItems ? selectedItems : items;
      const allPageItemsSelected = isEmpty(differenceWith(map(itemsToCheckFor, 'id'), map(selectedItems, 'id'), isEqual));
      dispatch(listHasAllPageItemsSelectedAC(allPageItemsSelected, componentName));
    }
    return null;
  }

  handleShowSelectedItemsToggle() {
    const { _showSelectedItems } = this.state;
    this.setState({ _showSelectedItems: !_showSelectedItems });
  }

  handleSelectModeToggle() {
    const { dispatch, componentName, isSelectionModeEnabled } = this.props;
    this.setState({
      _showSelectedItems: false,
    });

    // Reset selected items
    dispatch(listResetSelectItemsAC(componentName));

    // Toggle selection mode
    dispatch(listIsSelectionModeEnabledAC(
      !isSelectionModeEnabled, componentName,
    ));
  }

  handleSelectPageItemsToggle() {
    const { dispatch, componentName, pageItemsSelected, items, selectedItems } = this.props;
    const { _showSelectedItems } = this.state;
    if (pageItemsSelected) {
      const itemsToRemove = _showSelectedItems ? selectedItems : items;
      dispatch(listRemoveSelectedItemsAC(itemsToRemove.map(it => it.id), componentName));
      this.setState({ _showSelectedItems: false });
    } else {
      const itemsNotAddedYet = differenceWith(map(items, 'id'), map(selectedItems, 'id'), !isEqual);
      dispatch(listAddSelectedItemsAC(
        items.filter(it => itemsNotAddedYet.includes(it.id)), componentName),
      );
    }
  }

  shouldShowSelectedItems() {
    const { showSelectedItems } = this.props;
    const { _showSelectedItems } = this.state;
    // Parent component might override showSelectedItems state by passing it as a prop.
    return showSelectedItems || _showSelectedItems;
  }

  render() {
    const {
      componentName, searchComponent, isSelectionModeEnabled, selectedItems, selectModeEnabled,
      pagination, filtersOpen, fetchData, skeletonComponent, items, selectable, cardItem,
      bulkActions, emptyListMessage, emptyListClassName, emptySelectedListMessage, query,
      className, onPageChange, hoverable, pageItemsSelected, additionalContent, selectOne,
    } = this.props;
    const hasBulkActions = bulkActions && Array.isArray(bulkActions) && bulkActions.length > 0;

    const _classNames = ['selectable-list-wrapper'];
    if (className) {
      _classNames.push(className);
    }

    if (additionalContent) {
      _classNames.push('selectable-list-wrapper--with-additional-content');
    }

    const atLeastOneItem = items ? items.length > 0 : false;

    _classNames.push(`selectable-list-wrapper--${atLeastOneItem ? 'with-items' : 'empty'}`);

    /*
      Only show the toggle for bulk selection, if there are non-disabled items,
      selectOne is disabled and we're not currently showing selected items
    */
    const showBulkSelect = (
      atLeastOneItem
      && items.every(item => !item.disabled)
      && !selectOne
      && !this.shouldShowSelectedItems()
    );

    const containerClassName = 'col-auto d-flex align-items-end ml-auto pl-0 pl-md-3'
      + ' justify-content-end mt-3 mb-4';

    return (
      <div className={_classNames.join(' ')} data-testid="selectable-list-wrapper">
        <div className="row align-items-start">
          {!this.shouldShowSelectedItems() && searchComponent}

          { selectable && !filtersOpen && (
            <div className={containerClassName}>
              { additionalContent && (
                <div className="selectable-list__additional-content">
                  {additionalContent}
                </div>
              )}
              { selectedItems.length > 0 && !selectModeEnabled && (
                <Dropdown
                  as={ButtonGroup}
                  className="td-dropdown mr-3 btn-group--active"
                >
                  <Button
                    className="font-weight-normal px-3"
                    onClick={this.handleShowSelectedItemsToggle}
                    variant={BS_STYLE.DEFAULT}
                  >
                    { this.shouldShowSelectedItems() ? 'Show all' : `Show ${selectedItems.length} selected` }
                  </Button>

                  { hasBulkActions && (
                    <>
                      <Dropdown.Toggle
                        split
                        variant={BS_STYLE.DEFAULT}
                        className="px-2"
                      >
                        <span className={ICON.CHEVRON_DOWN} />
                      </Dropdown.Toggle>

                      { hasBulkActions && (
                        <Dropdown.Menu alignRight>
                          {
                            bulkActions.map(action => (
                              <Dropdown.Item
                                key={action.label}
                                onClick={() => action.handleClick(selectedItems)}
                              >
                                {action.label}
                              </Dropdown.Item>
                            ))
                          }
                        </Dropdown.Menu>
                      )}
                    </>
                  )}
                </Dropdown>
              )}
              {showBulkSelect && (
                <SelectableListToggleElement
                  handleSelectModeToggle={this.handleSelectModeToggle}
                  handleSelectPageItemsToggle={this.handleSelectPageItemsToggle}
                  selectModeEnabled={selectModeEnabled}
                  pageItemsSelected={pageItemsSelected}
                  isSelectionModeEnabled={isSelectionModeEnabled}
                />
              )}
            </div>
          )}

          {!selectable && !filtersOpen && additionalContent && (
            <div className={containerClassName}>
              {additionalContent && (
                <div className="selectable-list__additional-content">
                  {additionalContent}
                </div>
              )}
            </div>
          )}
        </div>

        {this.shouldShowSelectedItems() && (
          <div>
            { selectedItems.length === 0 && (
              <div className="text-center mt-4">
                {emptySelectedListMessage}
              </div>
            )}

            { selectedItems.map(item => (
              <SelectableItem
                key={`item-${item.id}`}
                listName={componentName}
                cardItem={cardItem}
                itemData={item}
              />
            ))}
          </div>
        )}

        <TDApiConnected
          duck="list"
          storeKey={componentName}
          fetchData={fetchData}
          loadingEnabled={false}
          skeletonComponent={skeletonComponent}
          query={query}
        >
          { !filtersOpen && (
            <div className={!this.shouldShowSelectedItems() ? 'd-block' : 'd-none'}>
              <TDList
                hoverable={hoverable}
                listName={componentName}
                items={items}
                selectable={isSelectionModeEnabled}
                emptyListMessage={emptyListMessage}
                emptyListClassName={emptyListClassName}
                cardItem={cardItem}
                pagination={pagination}
                onPageChange={onPageChange}
                selectOne={selectOne}
              />
            </div>
          )}
        </TDApiConnected>
      </div>
    );
  }
}

SelectableListWrapper.propTypes = {
  additionalContent: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.node]),
  bulkActions: PropTypes.arrayOf(PropTypes.object),
  cardItem: PropTypes.object.isRequired,
  className: PropTypes.string,
  componentName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  emptyListClassName: PropTypes.string,
  emptyListMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  emptySelectedListMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fetchData: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool,
  hoverable: PropTypes.bool,
  isSelectionModeEnabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onPageChange: PropTypes.func,
  pageItemsSelected: PropTypes.bool,
  pagination: paginationSpec.isRequired,
  query: PropTypes.object,
  searchComponent: PropTypes.node,
  selectModeEnabled: PropTypes.bool,
  selectOne: PropTypes.bool,
  selectable: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  showSelectedItems: PropTypes.bool,
  skeletonComponent: PropTypes.oneOfType([
    PropTypes.func, PropTypes.element, PropTypes.node,
  ]),
};
SelectableListWrapper.defaultProps = {
  additionalContent: null,
  bulkActions: [],
  className: null,
  emptyListClassName: null,
  emptySelectedListMessage: 'There are currently no selected entries.',
  filtersOpen: false,
  hoverable: false,
  isSelectionModeEnabled: false,
  onPageChange: null,
  pageItemsSelected: false,
  query: null,
  searchComponent: null,
  selectModeEnabled: false,
  selectOne: false,
  selectable: true,
  showSelectedItems: false,
  skeletonComponent: null,
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, props.componentName);
  return {
    isSelectionModeEnabled: listState.isSelectionModeEnabled,
    pageItemsSelected: listState.pageItemsSelected,
    pagination: listState.pagination,
    selectedItems: listState.selectedItems,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const SelectableListWrapperConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectableListWrapper);

export default SelectableListWrapperConnected;
