import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { USER_CARD_STATUS, BS_TOOLTIP_PLACEMENT } from 'core/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { financeManagersBudgetBreakdownUrl } from 'finance/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';


function ManagersBudgetTable({ managers, history, match, ...rest }) {
  const currency = (managers && managers.length && managers[0].currency ? managers[0].currency : '');
  const allocatedBudgetTooltip = (
    <TDElementWithTooltip
      placement={BS_TOOLTIP_PLACEMENT.BOTTOM}
      tooltipMsg="Budget allocated to projects via budget requests"
    />
  );
  const deallocatedBudgetTooltip = (
    <TDElementWithTooltip
      placement={BS_TOOLTIP_PLACEMENT.BOTTOM}
      tooltipMsg="Budget returned back to the organisation owner when a project completes with remaining funds"
    />
  );
  const tableColumns = [
    { key: 'fullName', label: 'Manager' },
    {
      key: 'approvedBudget',
      label: 'Budget',
      isMoney: true,
      currencySymbol: currency,
      columnClassName: 'text-right',
      width: '150px',
    },
    {
      key: 'allocatedToProjectsBudget',
      label: (
        <span>
          Allocated to projects
          {' '}
          {allocatedBudgetTooltip}
        </span>
      ),
      isMoney: true,
      currencySymbol: currency,
      columnClassName: 'text-right',
      width: '210px',
    },
    {
      key: 'deallocatedFromProjectsBudget',
      label: (
        <span>
          Deallocated from projects
          {' '}
          {deallocatedBudgetTooltip}
        </span>
      ),
      isMoney: true,
      currencySymbol: currency,
      columnClassName: 'text-right',
      width: '190px',
    },
    {
      key: 'availableBudget',
      label: 'Balance',
      isMoney: true,
      currencySymbol: currency,
      columnClassName: 'text-right',
      width: '130px',
    },
  ];
  return (
    <Table
      cols={tableColumns}
      trClassName={({ userCardStatus }) => {
        return (userCardStatus === USER_CARD_STATUS.DEACTIVATED) ? 'text-muted' : '';
      }}
      items={managers}
      {...rest}
      onRowClick={(rowData) => {
        history.push(financeManagersBudgetBreakdownUrl(match.params.orgAlias, rowData.id));
      }}
    />
  );
}

ManagersBudgetTable.propTypes = {
  managers: PropTypes.array.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(ManagersBudgetTable);
