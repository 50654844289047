import React from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';

import SkillTag from 'core/assets/js/components/SkillTag.jsx';


const SkillSearchResults = ({
  skills, isSkillSelected, onSkillClick, skillActions, getSelectedItem,
  isDefaultEndorsed, getDefaultEndorsedItem,
}) => {
  const groups = groupBy(skills, skill => skill.label.toLowerCase()[0]);
  return (
    <div className="skill-list mb-n5">
      {skills.length === 0 && (
        <p className="discreet py-4">No skills found</p>
      )}
      {Object.keys(groups).map((group) => {
        const selectedSkillCount = groups[group].filter(skill => isSkillSelected(skill)).length;
        const letterCount = groups[group].length;
        return (
          <div key={group} className="mb-5 pb-3">
            <h4 className="m-0 d-inline text-uppercase">{group}</h4>
            <span className="ml-2 discreet font-weight-normal">
              {`(${selectedSkillCount > 0 ? `${selectedSkillCount} / ` : ''}${letterCount})`}
            </span>

            <div className="py-3">
              <div className="skill-list mb-n5">
                {groups[group].map((skill) => {
                  const isSelected = isSkillSelected(skill);
                  if (isSelected) {
                    Object.assign(skill, getSelectedItem(skill));
                  }
                  if (isDefaultEndorsed(skill)) {
                    const { votesCount } = getDefaultEndorsedItem(skill);
                    Object.assign(skill, {
                      votesCount,
                    });
                  }
                  return (
                    <SkillTag
                      key={skill.id}
                      skill={skill}
                      isSelected={isSelected}
                      onClick={onSkillClick ? () => onSkillClick(skill) : null}
                      actions={skillActions}
                      isSelectable
                      showEndorsementIndication
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
SkillSearchResults.propTypes = {
  skills: PropTypes.array.isRequired,
  isSkillSelected: PropTypes.func,
  onSkillClick: PropTypes.func,
  skillActions: PropTypes.array,
  getSelectedItem: PropTypes.func,
  isDefaultEndorsed: PropTypes.func,
  getDefaultEndorsedItem: PropTypes.func,
};
SkillSearchResults.defaultProps = {
  onSkillClick: null,
  isSkillSelected: () => false,
  skillActions: [],
  getSelectedItem: () => null,
  isDefaultEndorsed: () => null,
  getDefaultEndorsedItem: () => null,
};

export default SkillSearchResults;
