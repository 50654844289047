import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import RateCreateForm from 'rates/assets/js/components/RateCreateForm.jsx';
import { orgSettingsRatesUrl } from 'rates/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';

function RatesCreateView({ currency, match, history, rateUnit }) {
  const { orgAlias } = match.params;
  const rateListUrl = orgSettingsRatesUrl(orgAlias);
  const breadcrumbs = [
    {
      title: 'Rates',
      url: rateListUrl,
    },
    {
      title: 'New rate',
      url: null,
    },
  ];

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--rate-create">
        <div className="container">
          <RateCreateForm
            initialValues={{
              currency,
              unit: rateUnit,
            }}
            onSubmitSuccess={() => {
              history.push(rateListUrl);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
RatesCreateView.propTypes = {
  currency: PropTypes.string.isRequired,
  rateUnit: PropTypes.number.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
};

const mapStateToProps = (state) => {
  const org = selectActiveOrg(state);

  return {
    currency: org.currency,
    rateUnit: org.default_rate_unit,
  };
};

const RatesCreateViewConnected = connect(
  mapStateToProps,
)(RatesCreateView);

export default withRouter(RatesCreateViewConnected);
