
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import PeopleList from 'people/assets/js/components/PeopleList.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, VIEW_TYPES } from 'core/assets/js/constants';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import UserGroupAddMembersPanel, { MODAL_ID as ADD_MEMBERS_TO_GROUP_MODAL_ID } from 'people/assets/js/components/UserGroupAddMembersPanel.jsx';
import UserGroupCard from 'people/assets/js/components/UserGroupCard.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { userGroupAddMembersDS, userGroupRemoveMemberDS } from 'people/assets/js/data-services/list';
import { getPeopleListViewType } from 'core/assets/js/ducks/settings';
import {
  userGroupEditUrl, userGroupMembersListApiUrl, userGroupsUrl, userGroupApiUrl,
} from 'people/urls';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';

const REMOVE_MEMBER_FROM_GROUP_MODAL_ID = 'user-group-remove-member-modal';

const UserGroupManageView = () => {
  const [addingMembers, setAddingMembers] = useState(false);

  const dispatch = useDispatch();
  const hasOrgAccess = useSelector(getHasOrgAccess);
  const history = useHistory();
  const listViewType = useSelector(getPeopleListViewType);
  const location = useLocation();
  const match = useRouteMatch();

  const isAddMembersToGroupModalOpen = useSelector(state => getIsModalOpen(
    state, ADD_MEMBERS_TO_GROUP_MODAL_ID,
  ));
  const isRemoveUserGroupMemberModalOpen = useSelector(state => getIsModalOpen(
    state, REMOVE_MEMBER_FROM_GROUP_MODAL_ID,
  ));
  const removeMemberModalPayload = useSelector(state => getModalPayload(
    state, REMOVE_MEMBER_FROM_GROUP_MODAL_ID,
  ));

  const isGrid = listViewType === VIEW_TYPES.GRID;
  const { params: { orgAlias, userGroupId } } = match;

  const { isLoading: listIsLoading, pagination } = fetchDataHook({
    changesArray: [listViewType],
    componentName: UserGroupManageView.GetComponentName(),
    duck: 'list',
    url: `${userGroupMembersListApiUrl({ orgAlias, userGroupId })}`,
    queryStringParams: { pageSize: isGrid ? 12 : 10 },
  });

  const { hasLoaded: viewHasLoaded, item: userGroup } = fetchDataHook({
    componentName: UserGroupManageView.GetComponentName(),
    url: userGroupApiUrl({ orgAlias, userGroupId }),
  });

  const handleOpenAddMembersToGroupModal = (userType) => {
    dispatch(modalOpenAC(ADD_MEMBERS_TO_GROUP_MODAL_ID, {
      orgAlias,
      userGroupId,
      userType,
    }));
  };

  const onListUpdate = async () => {
    await dispatch(fetchListDS({
      componentName: UserGroupManageView.GetComponentName(),
      querystring: location.search,
      url: userGroupMembersListApiUrl({ orgAlias, userGroupId }),
    }));
  };

  const handleAddMembersToGroup = async (userIds) => {
    setAddingMembers(true);
    try {
      const values = await dispatch(
        userGroupAddMembersDS({
          componentName: UserGroupManageView.GetComponentName(),
          orgAlias,
          userGroupId,
          values: { userIds },
        }),
      );
      dispatch(modalCloseAC(ADD_MEMBERS_TO_GROUP_MODAL_ID));
      if (values.count === 0) {
        toastr.warning('None added', 'No new members have been added');
      } else {
        await onListUpdate();
        toastr.success('Well Done!', `${values.count} member(s) added successfully.`);
      }
    } catch (e) {
      toastr.error('Oh Snap!', e.response?.data?._error || e.message);
    } finally {
      setAddingMembers(false);
    }
  };

  const handleRemoveMemberFromGroup = () => dispatch(
    userGroupRemoveMemberDS({
      componentName: UserGroupManageView.GetComponentName(),
      orgAlias,
      userGroupId,
      userId: removeMemberModalPayload.userId,
    }),
  ).then(async () => {
    dispatch(modalCloseAC(REMOVE_MEMBER_FROM_GROUP_MODAL_ID));
    await onListUpdate();
    toastr.success('Well Done!', 'User group member removed successfully.');
  });

  const isAnyManager = hasOrgAccess({ requireManager: true });
  const breadcrumbs = [
    {
      title: 'Groups',
      url: userGroupsUrl(orgAlias),
    },
    {
      title: userGroup?.name,
      url: null,
    },
  ];

  const ctaButtonItems = [];

  if (userGroup?.allowedActions?.canAddUsers) {
    ctaButtonItems.push(
      {
        label: 'Add providers',
        onClick: () => handleOpenAddMembersToGroupModal(PEOPLE_TYPE.PROVIDERS),
        variant: BS_STYLE.PRIMARY,
      },
      {
        label: 'Add managers',
        onClick: () => handleOpenAddMembersToGroupModal(PEOPLE_TYPE.MANAGERS),
      },
    );
  }

  if (userGroup?.allowedActions?.canEdit) {
    ctaButtonItems.push({
      label: 'Edit',
      onClick: () => history.push(userGroupEditUrl(orgAlias, userGroupId)),
      variant: !userGroup.allowedActions?.canAddUsers ? BS_STYLE.PRIMARY : null,
    });
  }

  return (viewHasLoaded && (
    <>
      <ContentHeader
        breadcrumbs={breadcrumbs}
        ctaButtonItems={ctaButtonItems}
      />
      <div className="page page--group">
        <div className="container">
          <div className="mb-5">
            <UserGroupCard
              item={userGroup}
              membersCount={listIsLoading ? '-' : pagination?.membersCount}
            />
          </div>
          <PeopleList
            addUsersComponent={userGroup.allowedActions?.canAddUsers && (
              <div className="d-flex mt-4">
                <TDButton
                  data-testid="user-group-members-list-add-providers"
                  label="Add providers"
                  onClick={() => handleOpenAddMembersToGroupModal(PEOPLE_TYPE.PROVIDERS)}
                  variant={BS_STYLE.PRIMARY}
                />
                <TDButton
                  className="ml-4"
                  data-testid="user-group-members-list-add-managers"
                  label="Add managers"
                  onClick={() => handleOpenAddMembersToGroupModal(PEOPLE_TYPE.MANAGERS)}
                  variant={BS_STYLE.PRIMARY}
                />
              </div>
            )}
            canInviteUsers={false}
            componentName={UserGroupManageView.GetComponentName()}
            getActionsDropDownItems={
              item => (!userGroup.isDynamic || isAnyManager) && (
                <Dropdown.Item
                  onClick={
                    () => dispatch(modalOpenAC(REMOVE_MEMBER_FROM_GROUP_MODAL_ID, {
                      userId: item.user.id,
                    }))
                  }
                >
                  Remove member
                </Dropdown.Item>
              )
            }
            noUsersMessage="This group does not have any members yet"
          />
        </div>
      </div>
      {isRemoveUserGroupMemberModalOpen && (
        <ModalSimple
          heading="Delete user member"
          body="Are you sure you want to remove this user from the group?"
          onClose={() => dispatch(modalCloseAC(REMOVE_MEMBER_FROM_GROUP_MODAL_ID))}
          open={isRemoveUserGroupMemberModalOpen}
          footer={[
            <TDButton
              key={1}
              className="float-right"
              label="Delete"
              variant={BS_STYLE.DANGER}
              onClick={() => handleRemoveMemberFromGroup()}
            />,
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              onClick={() => dispatch(modalCloseAC(REMOVE_MEMBER_FROM_GROUP_MODAL_ID))}
            />,
          ]}
        />
      )}
      {isAddMembersToGroupModalOpen && (
        <UserGroupAddMembersPanel
          handleAddMembersToGroup={userIds => handleAddMembersToGroup(userIds)}
          submitting={addingMembers}
        />
      )}
    </>
  ));
};

UserGroupManageView.GetComponentName = () => 'UserGroupManageView';

export default UserGroupManageView;
