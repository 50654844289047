import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { getActiveUserCardPermissionChecker } from 'organizations/assets/js/reducers/organizations';
import TaskRateSuggestion from 'projects/assets/js/components/TaskRateSuggestion.jsx';
import RateSelectField from 'rates/assets/js/components/finalFormFields/RateSelectField.jsx';
import { RATE_SUGGEST_VALUE, RATE_UNIT } from 'rates/assets/js/constants';
import { PERMISSIONS } from 'roles/assets/js/constants';

const TaskRateSelection = ({
  rates, defaultRateUnit, currencySymbol, label, suggestedRateUnit, fieldPrefix,
}) => {
  const hasRates = !isEmpty(rates);
  const [isCustomRateVisible, setIsCustomRateVisible] = useState(!hasRates);
  const handleItemSelect = (value) => {
    const [rate] = Array.isArray(value) ? value : [value];
    setIsCustomRateVisible(rate === RATE_SUGGEST_VALUE);
  };
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  return (
    <Fragment>
      <RateSelectField
        canSuggestRate={hasPermission(PERMISSIONS.CAN_SUGGEST_TASK_RATES)}
        disabled={!hasRates}
        handleItemSelect={handleItemSelect}
        label={label}
        name={fieldPrefix ? `${fieldPrefix}.rate_id` : 'rate_id'}
        rates={rates}
        required
      />

      {!hasRates && (
        <Field
          name={fieldPrefix ? `${fieldPrefix}.rate_id` : 'rate_id'}
          readOnly
        >
          {({ input }) => (
            <input
              {...input}
              type="hidden"
              value={RATE_SUGGEST_VALUE}
            />
          )}
        </Field>
      )}

      { !!isCustomRateVisible && (
        <TaskRateSuggestion
          fieldPrefix={fieldPrefix}
          currencySymbol={currencySymbol}
          defaultRateUnit={defaultRateUnit}
          suggestedRateUnit={suggestedRateUnit}
        />
      )}
    </Fragment>
  );
};

TaskRateSelection.propTypes = {
  rates: PropTypes.arrayOf(PropTypes.object),
  defaultRateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  suggestedRateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  currencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  label: PropTypes.string,
  fieldPrefix: PropTypes.string,
};

TaskRateSelection.defaultProps = {
  rates: [],
  label: null,
  currencySymbol: null,
  defaultRateUnit: null,
  suggestedRateUnit: null,
  fieldPrefix: null,
};

export default TaskRateSelection;
