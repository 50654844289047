import React from 'react';
import { withRouter } from 'react-router-dom';

import { BS_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectWorksheetImportCsvDownloadUrl } from 'projects/urls';

const ProjectWorksheetImporterDownloadTemplate = (props) => {
  const { match: { params: { id: projectId, orgAlias } } } = props;
  const csvDownloadUrl = projectWorksheetImportCsvDownloadUrl(orgAlias, projectId);
  return (
    <div>
      <h2>Download template</h2>

      <div className="import-worksheets-page--wrapper bg-light py-4 mx-0 mb-4 d-flex">
        <div className="import-worksheets-page--download--section mt-4">
          <a
            className="col-auto d-flex mt-4 mt-sm-0 file-sample-link"
            href={csvDownloadUrl}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <TDButton
              className="import-worksheets-page--download--btn"
              bsSize={BS_SIZE.LARGE}
              variant={BS_STYLE.PRIMARY}
              block
              label={(
                <span>
                  <span className={`${ICON.DOWNLOAD} mr-3`} />
                  Download template
                </span>
              )}
            />
          </a>

          <div className="col-lg-12 col-sm-12 mt-4">
            <CheckboxField
              className="import-worksheets-page--download--checkbox"
              name="downloaded_template"
              label=""
              required
              options={[
                { text: 'I have already downloaded a recent version of the template', value: 1 },
              ]}
            />
          </div>
        </div>
        <div className="import-worksheets-page--info--section mt-4">
          <p className="mb-0 import-worksheets-page--download--info">
            Download the CSV file which contains information about
            the active project members.
          </p>
          <p className="mb-0 import-worksheets-page--download--info">
            Use a spreadsheet editor to enter all of the required
            worksheet information - e.g user id, user, claiming amount, description,
            currency, summary, period start, period end.
          </p>
        </div>
      </div>
    </div>
  );
};

ProjectWorksheetImporterDownloadTemplate.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ProjectWorksheetImporterDownloadTemplate);
