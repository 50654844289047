import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import DocumentNewVersionConfirmModal, { MODAL_ID_PREFIX as NEW_VERSION_MODAL_ID_PREFIX } from 'documents/assets/js/components/DocumentNewVersionConfirmModal.jsx';
import { MODAL_ID as REQUEST_SIGNATURES_MODAL_ID } from 'documents/assets/js/components/DocumentRequestSignaturesModal.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { CUSTOM_ICONS } from 'core/assets/js/constants';
import { DOCUMENT_STATUS_CLASS, DOCUMENT_STATUS_LABEL } from 'documents/assets/js/constants';
import { archiveDocumentDS, duplicateDocumentDS, publishDocumentDS } from 'documents/assets/js/data-services/form';
import { documentEditUrl, documentViewUrl } from 'documents/urls';
import { documentSpec } from 'documents/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const DocumentCard = ({
  activeOrg, dispatch, history, item, itemsLength, listComponentName, isLastItem,
}) => {
  const actions = [];
  const {
    canArchive,
    canCreateNewVersion,
    canDuplicate,
    canEdit,
    canPublish,
    canRequestSignatures,
  } = item.allowedActions;

  actions.push(
    <Dropdown.Item
      eventKey="preview"
      key="preview"
      onClick={() => history.push(documentViewUrl(activeOrg.alias, item.id))}
    >
      Preview
    </Dropdown.Item>,
  );

  if (canRequestSignatures) {
    actions.push(
      <Dropdown.Item
        eventKey="request-signatures"
        key="request-signatures"
        onClick={() => dispatch(modalOpenAC(
          REQUEST_SIGNATURES_MODAL_ID,
          { document: pick(item, 'countersignersCount', 'id', 'title') },
        ))}
      >
        Request signatures
      </Dropdown.Item>,
    );
  }

  if (canCreateNewVersion) {
    actions.push(
      <Dropdown.Item
        eventKey="new-version"
        key="new-version"
        onClick={() => dispatch(modalOpenAC(`${NEW_VERSION_MODAL_ID_PREFIX}${item.id}`))}
      >
        Create a new version
      </Dropdown.Item>,
    );
  }

  if (canDuplicate) {
    actions.push(
      <Dropdown.Item
        eventKey="duplicate"
        key="duplicate"
        onClick={async () => {
          try {
            await dispatch(duplicateDocumentDS({
              id: item.id, listComponentName, orgAlias: activeOrg.alias,
            }));
            toastr.success('Well Done!', `"${item.title}" was duplicated successfully`);
          } catch (error) {
            toastr.error('Oh Snap!', error._error || error.message);
          }
        }}
      >
        Duplicate
      </Dropdown.Item>,
    );
  }

  if (canEdit) {
    actions.push(
      <Dropdown.Item
        eventKey="edit"
        key="edit"
        onClick={() => history.push(documentEditUrl(activeOrg.alias, item.id))}
      >
        Edit
      </Dropdown.Item>,
    );
  }

  if (canPublish) {
    actions.push(
      <Dropdown.Item
        eventKey="publish"
        key="publish"
        onClick={async () => {
          try {
            await dispatch(publishDocumentDS({
              id: item.id, listComponentName, orgAlias: activeOrg.alias,
            }));
            toastr.success('Well Done!', `"${item.title}" was published successfully`);
          } catch (error) {
            toastr.error('Oh Snap!', error._error || error.message);
          }
        }}
      >
        Publish
      </Dropdown.Item>,
    );
  }

  if (canArchive) {
    actions.push(
      <Dropdown.Divider key="archive-divider" />,
      <Dropdown.Item
        className="text-danger"
        eventKey="archive"
        key="archive"
        onClick={async () => {
          try {
            await dispatch(archiveDocumentDS({
              id: item.id, listComponentName, orgAlias: activeOrg.alias,
            }));
            toastr.success('Well Done!', `"${item.title}" was archived successfully`);
          } catch (error) {
            toastr.error('Oh Snap!', error._error || error.message);
          }
        }}
      >
        Archive
      </Dropdown.Item>,
    );
  }

  return (
    <Card className="document-item">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <div className="d-flex align-items-center mr-4">
              {
                item.signNowDocumentId
                  ? (<img alt="esign icon" src={CUSTOM_ICONS.ESIGN} />)
                  : (<img alt="checkbox file icon" src={CUSTOM_ICONS.FILE_CHECKBOX} />)
              }
            </div>
            <div className="d-flex flex-column">
              <h3 className="my-0">
                {canEdit && (
                  <Link className="text-dark" to={documentEditUrl(activeOrg.alias, item.id)}>
                    {item.title}
                  </Link>
                )}
                {!canEdit && (
                  <Link className="text-dark" to={documentViewUrl(activeOrg.alias, item.id)}>
                    {item.title}
                  </Link>
                )}
              </h3>
              <span className="discreet">{`Created: ${formatDate(item.createdAt)}`}</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="font-weight-bolder mr-5">
              <span className="discreet">v. </span>
              {`${item.version}`}
            </div>
            <StatusTag
              label={DOCUMENT_STATUS_LABEL[item.status]}
              statusClass={DOCUMENT_STATUS_CLASS[item.status]}
            />
            {actions.length > 0 && (
              <>
                <TDDropButton
                  data-testid="document-card-actions"
                  className="ml-5"
                  placement={isLastItem && itemsLength > 2 ? 'top-end' : 'bottom-end'}
                  stopPropagation
                >
                  {actions}
                </TDDropButton>
                {canCreateNewVersion && <DocumentNewVersionConfirmModal document={item} />}
              </>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

DocumentCard.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  item: documentSpec.isRequired,
  itemsLength: PropTypes.number.isRequired,
  listComponentName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const DocumentCardConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(DocumentCard);

export default withRouter(DocumentCardConnected);
