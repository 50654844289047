import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, isEmpty } from 'lodash';

import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { customFieldSpec, customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';

const CustomFieldTemplatesSync = ({
  templates, customFields, enableTemplateRemoval, onFieldAdded, onTemplateRemoved, withSeparator,
}) => {
  if (isEmpty(templates) || isEmpty(customFields)) {
    return null;
  }

  const grouppedFields = groupBy(customFields, 'customFieldTemplateId');
  const collapsibleContent = templates.map((template) => {
    const { id: templateId, name: templateName, isMandatory } = template;
    const unsyncedFields = grouppedFields[templateId];
    const hasUnsyncedFields = !isEmpty(unsyncedFields);

    if (
      !hasUnsyncedFields && (!enableTemplateRemoval || (enableTemplateRemoval && isMandatory))
    ) {
      return null;
    }

    return (
      <table
        key={`template-additions-${templateId}`}
        className="mb-2 w-100 table table-borderless"
      >
        <thead>
          <tr>
            <th className="align-middle">
              {templateName}
            </th>
            <th className="text-right">
              {enableTemplateRemoval && (
                <React.Fragment>
                  {!isMandatory && (
                    <TDButton
                      bsSize={BS_SIZE.XSMALL}
                      bsStyle={BS_STYLE.LINK}
                      onClick={() => onTemplateRemoved(template)}
                    >
                      Remove fields
                    </TDButton>
                  )}

                  {isMandatory && (
                    <TDElementWithTooltip
                      key={`tooltip-not-removable-${template.id}`}
                      tooltipMsg="This is a mandatory template, you cannot remove it"
                    >
                      <span
                        className="text-muted"
                      >
                        Remove fields
                      </span>
                    </TDElementWithTooltip>
                  )}
                </React.Fragment>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {hasUnsyncedFields && unsyncedFields.map(customField => (
            <tr key={`template-additions-cf-${templateId}-${customField.id}`}>
              <td className="align-middle">
                {customField.label}
              </td>
              <td className="align-middle text-right">
                <TDButton
                  bsSize={BS_SIZE.XSMALL}
                  bsStyle={BS_STYLE.LINK}
                  onClick={() => onFieldAdded(customField)}
                >
                  Add field
                </TDButton>
              </td>
            </tr>
          ))}
          {!hasUnsyncedFields && (
            <tr>
              <td
                colSpan={2}
                className="small text-center align-middle"
              >
                There aren’t any fields left to sync
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <React.Fragment>
      <TDSystemMessage
        type="info"
        title={(
          <div>
            <div>
              Optional fields available!
            </div>

            <div className="text-dark mt-1">
              You have optional fields that you can add because manager has changed
              Contact template and added extra fields.
            </div>
          </div>
        )}
        className="mb-4"
        dismissible
      />

      <AreaCollapsible
        solidColor
        className="mb-4"
        headingClassName="px-0 py-3"
        headingChildren="Optional fields that you can add"
        contentChildren={collapsibleContent}
      />

      {withSeparator && (
        <hr />
      )}
    </React.Fragment>
  );
};

CustomFieldTemplatesSync.propTypes = {
  templates: PropTypes.arrayOf(customFieldTemplateSpec),
  customFields: PropTypes.arrayOf(customFieldSpec),
  enableTemplateRemoval: PropTypes.bool,
  onFieldAdded: PropTypes.func.isRequired,
  onTemplateRemoved: PropTypes.func.isRequired,
  withSeparator: PropTypes.bool,
};

CustomFieldTemplatesSync.defaultProps = {
  customFields: [],
  templates: [],
  enableTemplateRemoval: true,
  withSeparator: false,
};

export default CustomFieldTemplatesSync;
