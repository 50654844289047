import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import Big from 'big.js';

import { pluralize } from 'core/assets/js/lib/utils';
import { ICON } from 'core/assets/js/constants';

const RatingScore = ({ classNames, rating, numReviews, hasLabel, showStarIcon }) => {
  if (!rating || !isNumber(parseFloat(rating))) {
    return null;
  }
  const classes = [];
  if (classNames) {
    classes.push(classNames);
  }
  return (
    <span className={classes.join(' ')}>
      {showStarIcon && (
        <>
          <i className={ICON.STAR} />
          {' '}
        </>
      )}
      {Big(rating).toFixed(1)}
      {' '}
      {hasLabel && (
        <span className="discreet">
          {`(${numReviews} ${pluralize('review', numReviews)})`}
        </span>
      )}
    </span>
  );
};

RatingScore.propTypes = {
  classNames: PropTypes.string,
  hasLabel: PropTypes.bool,
  numReviews: PropTypes.number,
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showStarIcon: PropTypes.bool,
};

RatingScore.defaultProps = {
  classNames: '',
  hasLabel: true,
  numReviews: 0,
  rating: null,
  showStarIcon: true,
};

export default RatingScore;
