import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const RaisedLabel = () => (
  <>
    Submitted
    <ElementWithPopOver
      className="d-inline-block ml-2"
      popOverContent={(
        `You have successfully created your worksheet, expense or proforma invoice. It will soon be
        reviewed by a manager.`
      )}
      popOverTitle="Submitted"
    />
  </>
);

export default RaisedLabel;
