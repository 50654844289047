import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import FilePreview from 'core/assets/js/components/FilePreview.jsx';
import {
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_LABEL,
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL,
  DOCUMENT_CONTRACT_TERM_END_TYPE,
  DOCUMENT_CONTRACT_TERM_END_TYPE_LABEL,
  DOCUMENT_RENEWAL_TERM_TYPE_LABEL,
  DOCUMENT_RENEWAL_TYPE,
  DOCUMENT_RENEWAL_TYPE_LABEL,
} from 'documents/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { documentFileApiUrl } from 'documents/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const Overview = ({
  document: {
    content,
    description,
    effectiveDate,
    effectiveType,
    endDate,
    endRelativeType,
    endRelativeValue,
    endText,
    endType,
    files,
    id,
    renewalExpirationReminder,
    renewalNoticePeriod,
    renewalTermType,
    renewalTermValue,
    renewalType,
    signNowTemplateId,
    title,
    version,
  },
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const isEsign = !!signNowTemplateId;
  const file = files && files[0];
  const isPDF = isEsign || file?.mimetype === 'application/pdf';
  const pdfFileUrl = isEsign ? documentFileApiUrl(activeOrg.alias, id) : file?.url;

  const listItemClassName = [
    'list-group-item d-flex border-bottom',
    'justify-content-between align-items-center',
  ].join(' ');

  return (
    <>
      <div className="bg-white p-3 rounded shadow-sm mb-5">
        <div className="row">
          <div className="col-12 col-xl-4">
            <ul className="list-group list-group-flush">
              <li className={listItemClassName}>
                <span className="mr-5">Title</span>
                {title}
              </li>
              <li className={listItemClassName}>
                <span className="mr-5">Version</span>
                <div>
                  <span className="discreet">v. </span>
                  {version}
                </div>
              </li>
            </ul>
          </div>

          <div className="col-12 col-xl-4">
            <ul className="list-group list-group-flush">
              <li className={listItemClassName}>
                <span className="mr-5">Effective date</span>
                {effectiveDate && (
                  formatDate(effectiveDate)
                )}
                {!effectiveDate && (
                  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_LABEL[effectiveType]
                )}
              </li>
              <li className={listItemClassName}>
                <span className="mr-5">Renewal type</span>
                {DOCUMENT_RENEWAL_TYPE_LABEL[renewalType] || '-'}
              </li>

              { endType === DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE && (
                <li className={listItemClassName}>
                  <span className="mr-5">End date</span>
                  {formatDate(endDate)}
                </li>
              )}

              { endType && endType !== DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE && (
                <li className={listItemClassName}>
                  <span className="mr-5">End type</span>
                  {endType !== DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE && (
                    DOCUMENT_CONTRACT_TERM_END_TYPE_LABEL[endType]
                  )}
                </li>
              )}

              { endRelativeType && endRelativeValue && (
                <li className={listItemClassName}>
                  <span className="mr-5">End relative date</span>
                  {` ${endRelativeValue} `}
                  {pluralize(
                    DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL[endRelativeType]
                      .toLowerCase(),
                    endRelativeValue,
                  )}
                </li>
              )}

              { renewalType === DOCUMENT_RENEWAL_TYPE.EVERGREEN && (
                <li className={listItemClassName}>
                  <span className="mr-5">Renewal term</span>
                  {`${renewalTermValue} `}
                  {pluralize(
                    DOCUMENT_RENEWAL_TERM_TYPE_LABEL[renewalTermType]
                      .toLowerCase(),
                    renewalTermValue,
                  )}
                </li>
              )}

              { renewalType === DOCUMENT_RENEWAL_TYPE.MANUAL && (
                <>
                  <li className={listItemClassName}>
                    <span className="discreet mr-5">Renewal notice period</span>
                    {`${renewalNoticePeriod} ${pluralize('day', renewalNoticePeriod)}`}
                  </li>

                  <li className={listItemClassName}>
                    <span className="discreet mr-5">Renewal expiration reminder</span>
                    {
                      `${renewalExpirationReminder}`
                      + ` ${pluralize('day', renewalExpirationReminder)}`
                    }
                  </li>
                </>
              )}

              {[
                DOCUMENT_CONTRACT_TERM_END_TYPE.ON_CONDITION,
                DOCUMENT_CONTRACT_TERM_END_TYPE.ON_FULFILMENT,
              ].includes(endType) && (
                <li className={listItemClassName}>
                  <span className="col-6 mr-5">
                    {endType === DOCUMENT_CONTRACT_TERM_END_TYPE.ON_CONDITION && (
                      'Condition'
                    )}
                    {endType === DOCUMENT_CONTRACT_TERM_END_TYPE.ON_FULFILMENT && (
                      'Fulfilment'
                    )}
                  </span>
                  <p className="col-5 text-right mb-0">{endText}</p>
                </li>
              )}
            </ul>
          </div>

          <div className="col-12 col-xl-4 mt-3 mt-xl-0 mx-3 mx-xl-0">
            <span className="d-block mr-5 pt-1 my-0 my-xl-2">Description</span>
            <p className="text-justify mb-0 pr-3 pb-3">{description || '-'}</p>
          </div>
        </div>
      </div>

      <Card className="mt-5 document-viewer">
        <Card.Header>Contract template preview</Card.Header>
        <Card.Body>
          <>
            {isPDF && pdfFileUrl && <FilePreview mimeType="application/pdf" url={pdfFileUrl} />}
            {file && !isPDF && <p>{`Cannot preview a file with mimetype "${file.mimetype}"`}</p>}
            {!file && !isEsign && (
              <div className="p-5">
                <div
                  className="html-content p-5"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            )}
          </>
        </Card.Body>
      </Card>
    </>
  );
};

Overview.propTypes = {
  document: PropTypes.object.isRequired,
};

export default Overview;
