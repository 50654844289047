import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import {
  API_DATE_FORMAT, IMG_SIZE, MODAL_SIZES, ORDERING_DIRECTION,
} from 'core/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import {
  getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { formatDate, parseDate } from 'core/assets/js/lib/utils';
import DocumentAssignmentViewDropdownItem from 'documents/assets/js/components/DocumentAssignmentViewDropdownItem.jsx';
import {
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS,
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_CLASS,
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_LABEL,
  DOCUMENT_ASSIGNMENT_STATUS,
  DOCUMENT_ASSIGNMENT_STATUS_LABEL,
  DOCUMENT_ASSIGNMENT_STATUS_CLASS,
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE,
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL,
  DOCUMENT_CONTRACT_TERM_END_TYPE,
} from 'documents/assets/js/constants';
import { cancelDocumentAssignmentDS } from 'documents/assets/js/data-services/list';
import { documentAssignmentApiUrl, resendCountersignerEmailApiUrl } from 'documents/urls';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import { orgUserProfileUrl } from 'people/urls';

export const DOCUMENT_ASSIGNMENT_STATUS_OPTIONS = Object.values(DOCUMENT_ASSIGNMENT_STATUS).map(
  value => ({
    text: DOCUMENT_ASSIGNMENT_STATUS_LABEL[value],
    value,
  }),
);

const MODAL_ID = 'document-view-countersigners-modal';

const Confirmations = ({ filtersOpen, location, match: { params }, onFiltersToggle }) => {
  const dispatch = useDispatch();
  const componentName = Confirmations.GetComponentName({ params });
  const { items, pagination, search } = useSelector(state => (
    getListState(state, componentName)
  ));
  const modalIsOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const modalPayload = useSelector(state => getModalPayload(state, MODAL_ID));

  const searchSpec = {
    defaultOrdering: { direction: ORDERING_DIRECTION.DESC, sortBy: 'created_at' },
    searchTerm: { component: AutoSuggestField, paramName: 'kw', placeholder: 'Search' },
    filters: [{
      fieldComponent: SelectableListFilterField,
      label: 'Status',
      paramName: 'status',
      options: DOCUMENT_ASSIGNMENT_STATUS_OPTIONS,
      multiple: true,
    }],
    orderingOptions: [
      { text: 'Created at', value: 'created_at' },
      { text: 'Name', value: 'last_name' },
      { text: 'Status', value: 'status' },
      { text: 'Effective from date', value: 'effective_from_date' },
      { text: 'Effective end date', value: 'effective_end_date' },
    ],
  };
  const initialValues = pick(
    queryString.parse(location.search), ['kw'].concat(searchSpec.filters.map(f => f.paramName)),
  );

  const onExec = DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_EXECUTION;

  return (
    <>
      <TDApiConnected
        duck="list"
        storeKey={componentName}
        fetchData={({
          authedAxios, componentName: thisComponentName, dispatch: thisDispatch, querystring,
        }) => (
          thisDispatch(fetchListDS({
            authedAxios,
            componentName: thisComponentName,
            params,
            querystring,
            url: documentAssignmentApiUrl(params.orgAlias, params.id),
          }))
        )}
      />
      {/*
        This is rendered outside of TDApiConnected, because TDApiConnected has issues rendering
        content in tabs
      */}
      <div className="row">
        {search.isActive && (
          <SearchFinalForm
            searchSpec={searchSpec}
            filtersOpen={filtersOpen}
            initialValues={initialValues}
            name="reports"
            onFiltersToggle={onFiltersToggle}
          />
        )}
      </div>
      {!filtersOpen && (
        <>
          <Table
            cols={[
              {
                dataFormat: (_, assignment) => {
                  if (!assignment.user) {
                    return 'Invited user';
                  }
                  if (!assignment.userCard) {
                    return assignment.user.profile.name;
                  }
                  return (
                    <Link
                      className="d-flex align-items-center"
                      to={orgUserProfileUrl(
                        params.orgAlias, assignment.userCard.userRole.ofType, assignment.userId,
                      )}
                    >
                      <ProfilePic
                        className="mr-3"
                        alt={assignment.user.profile.name}
                        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                        url={assignment.user.profile.avatar}
                      />
                      {assignment.user.profile.name}
                    </Link>
                  );
                },
                label: 'Name',
              },
              {
                dataFormat: (_, assignment) => (
                  assignment.user ? assignment.user.email : assignment.invitation.sentTo
                ),
                label: 'Email',
              },
              {
                dataFormat: (_, assignment) => (
                  <StatusColFormat
                    statusLabel={assignment.statusLabel}
                    statusClass={DOCUMENT_ASSIGNMENT_STATUS_CLASS[assignment.status]}
                  />
                ),
                label: 'Status',
              },
              {
                dataFormat: signedAt => formatDate(signedAt),
                key: 'signedAt',
                label: 'Confirmed date',
              },
              {
                dataFormat: (_, assignment) => {
                  // This should match the logic in DocumentAssignmentListener and
                  // DocumentAssignmentDBIndex ordering
                  const { document: { effectiveDate, effectiveType }, signedAt } = assignment;
                  const isOnExec = effectiveType === onExec;
                  if (isOnExec && !signedAt) {
                    return null;
                  }
                  return formatDate(isOnExec ? signedAt : effectiveDate);
                },
                label: 'Effective from date',
              },
              {
                dataFormat: (_, assignment) => {
                  // This should match the logic in DocumentAssignmentListener and
                  // DocumentAssignmentDBIndex ordering
                  const {
                    document: {
                      effectiveDate,
                      effectiveType,
                      endDate,
                      endRelativeType,
                      endRelativeValue,
                      endType,
                    },
                    signedAt,
                  } = assignment;
                  const isOnExec = effectiveType === onExec;
                  if (isOnExec && !signedAt) {
                    return null;
                  }
                  const startDate = isOnExec ? signedAt : effectiveDate;
                  switch (endType) {
                    case DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE:
                      return formatDate(endDate);
                    case DOCUMENT_CONTRACT_TERM_END_TYPE.RELATIVE_DATE:
                      return formatDate(
                        parseDate(startDate, API_DATE_FORMAT).add(
                          endRelativeValue,
                          DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL[endRelativeType]
                            .toLowerCase(),
                        ),
                      );
                    default:
                      return 'N/A';
                  }
                },
                label: 'Effective end date',
              },
              {
                className: 'text-nowrap',
                dataFormat: (_, assignment) => (
                  assignment.signatures.filter(s => s.isCounterSigner).length
                ),
                label: 'Countersigners',
              },
              {
                columnClassName: 'actions-cell',
                dataAlign: 'right',
                dataFormat: (_, assignment) => {
                  if (
                    !assignment.allowedActions?.canView
                    && !assignment.allowedActions?.canCancel
                  ) {
                    return null;
                  }
                  return (
                    <TDDropButton data-testid="document-assignment-table-actions" stopPropagation>
                      {assignment.allowedActions?.canView && (
                        <DocumentAssignmentViewDropdownItem assignment={assignment} />
                      )}
                      {assignment.signatures.filter(s => s.isCounterSigner).length > 0 && (
                        <Dropdown.Item
                          key="view-countersigners"
                          onClick={() => dispatch(modalOpenAC(MODAL_ID, assignment))}
                        >
                          View countersigners
                        </Dropdown.Item>
                      )}
                      {assignment.allowedActions?.canCancel && (
                        <Dropdown.Item
                          key="cancel-document-assignment"
                          onClick={() => {
                            dispatch(cancelDocumentAssignmentDS({
                              componentName,
                              documentAssignmentId: assignment.id,
                              orgAlias: params.orgAlias,
                            }))
                              .then(() => {
                                toastr.success(
                                  'Well Done!', 'Successfully cancelled that confirmation',
                                );
                              });
                          }}
                        >
                          Cancel
                        </Dropdown.Item>
                      )}
                    </TDDropButton>
                  );
                },
                label: '',
              },
            ]}
            emptyText="No confirmations found"
            items={items}
          />
          <TDPagination {...pagination} />
          <ModalConfirm
            heading="Countersigners"
            confirmLabel="Remove"
            open={modalIsOpen}
            onClose={() => dispatch(modalCloseAC())}
            showConfirmButton={false}
            size={MODAL_SIZES.LARGE}
          >
            <Table
              cols={[
                { columnClassName: 'text-nowrap', label: 'Email', key: 'email' },
                {
                  dataFormat: (_, signature) => (
                    <StatusColFormat
                      statusLabel={DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_LABEL[signature.status]}
                      statusClass={DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_CLASS[signature.status]}
                    />
                  ),
                  label: 'Status',
                },
                {
                  dataFormat: (_, signature) => {
                    if (
                      signature.status !== DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.PENDING
                      || !modalPayload.signatures.some(s => (
                        !s.isCounterSigner
                        && s.status === DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.SIGNED
                      ))
                    ) {
                      return null;
                    }
                    return (
                      <TDButton
                        onClick={async () => {
                          try {
                            await axios.post(resendCountersignerEmailApiUrl(
                              params.orgAlias, signature.id,
                            ));
                            toastr.success(
                              'Well Done!',
                              `We have resent the request to sign to ${signature.email}`,
                            );
                          } catch (err) {
                            toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                          }
                        }}
                        label="Re-send request to sign"
                      />
                    );
                  },
                  label: '',
                },
              ]}
              items={(modalPayload?.signatures || []).filter(s => s.isCounterSigner)}
            />
          </ModalConfirm>
        </>
      )}
    </>
  );
};

Confirmations.GetComponentName = ({ params: { id } }) => `DocumentConfirmationsTab-${id}`;

Confirmations.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(withFilters(Confirmations));
