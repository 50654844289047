import { intersection } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { profileSettingsUrl } from 'accounts/urls';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { SERVICE_ORDER_PREREQUISITES } from 'finance/assets/js/constants';
import { SERVICE_ORDER_TYPE_LABEL } from 'projects/assets/js/constants';
import { ORG_SETTINGS_PAYMENTS_TABS, SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { settingsPaymentsSubPageUrl, orgSettingsPaymentsUrl } from 'settings/urls';

const ServiceOrderPrerequisites = ({
  isForAnotherUser, missingFields, orgAlias, orgName, serviceOrderType,
}) => {
  if (!missingFields || missingFields.length === 0) {
    return null;
  }

  const listItems = [];

  if (missingFields.includes(SERVICE_ORDER_PREREQUISITES.PAYMENT_METHOD)) {
    listItems.push(
      <li key="settings-bank-details">
        {'Payment method information in the '}
        {isForAnotherUser && 'payment settings page'}
        {!isForAnotherUser && (
          <Link to={settingsPaymentsSubPageUrl(orgAlias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS)}>
            payment settings page
          </Link>
        )}
      </li>,
    );
  }

  if (missingFields.includes(SERVICE_ORDER_PREREQUISITES.COMPANY)) {
    listItems.push(
      <li key="settings-company-details">
        {'Company information in the '}
        {isForAnotherUser && 'company page'}
        {!isForAnotherUser && (
          <Link to={settingsPaymentsSubPageUrl(orgAlias, SETTINGS_PAYMENTS_TABS.INVOICE_DETAILS)}>
            company page
          </Link>
        )}
      </li>,
    );
  }
  if (missingFields.includes(SERVICE_ORDER_PREREQUISITES.INVOICING_VAT_PERCENT)) {
    listItems.push(
      <li key="settings-invoice-vat">
        {`Invoice sales tax rate for ${orgName} in the `}
        {isForAnotherUser && 'sales tax settings page'}
        {!isForAnotherUser && (
          <Link to={orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_PAYMENTS_TABS.SALES_TAX)}>
            sales tax settings page
          </Link>
        )}
      </li>,
    );
  }

  if (missingFields.includes(SERVICE_ORDER_PREREQUISITES.PROFILE)) {
    listItems.push(
      <li key="settings-profile">
        {'Profile details in the '}
        {isForAnotherUser && 'profile settings page'}
        {!isForAnotherUser && (
          <Link to={profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION)}>
            profile settings page
          </Link>
        )}
      </li>,
    );
  }

  if (
    intersection(
      missingFields,
      [
        SERVICE_ORDER_PREREQUISITES.ORG_PAYMENT_METHOD,
        SERVICE_ORDER_PREREQUISITES.ORG_PAYMENT_METHOD_PAYPAL,
        SERVICE_ORDER_PREREQUISITES.ORG_PAYMENT_METHOD_PAYONEER,
      ],
    ).length > 0
  ) {
    listItems.push(
      <li key="settings-bank-transfer">
        {'New payment method via the '}
        {isForAnotherUser && 'payment method settings section'}
        {!isForAnotherUser && (
          <Link to={orgSettingsPaymentsUrl(orgAlias)}>payment method settings section</Link>
        )}
        . There is not a valid payment method selected for this organisation
      </li>,
    );
  }

  const serviceOrderTypeLabel = SERVICE_ORDER_TYPE_LABEL[serviceOrderType];
  const serviceOrderTypePlural = `${serviceOrderTypeLabel}s`;

  return (
    <>
      <TDSystemMessage className="mb-5" title="Missing information" type="danger">
        <div>
          <p>
            {`To be able to submit a ${serviceOrderTypeLabel}`}
            {isForAnotherUser ? ' for this user, they' : 'you'}
            {' must first enter the following required information:'}
          </p>
          <ul className="bullet-list mb-2">{listItems}</ul>
          <p>
            {`${isForAnotherUser ? 'They' : 'You'} will only need to fill out the section(s) above`}
            {` once to begin raising ${serviceOrderTypePlural}. This information will then be used`}
            {` for ${isForAnotherUser ? 'their' : 'your'} future ${serviceOrderTypePlural}.`}
          </p>
        </div>
      </TDSystemMessage>
    </>
  );
};

ServiceOrderPrerequisites.propTypes = {
  isForAnotherUser: PropTypes.bool,
  missingFields: PropTypes.array,
  orgAlias: PropTypes.string,
  orgName: PropTypes.string,
  serviceOrderType: PropTypes.number.isRequired,
};

ServiceOrderPrerequisites.defaultProps = {
  isForAnotherUser: false,
  missingFields: [],
  orgAlias: '',
  orgName: '',
};

export default ServiceOrderPrerequisites;
