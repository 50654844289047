import PropTypes from 'prop-types';
import React, { useState } from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE, USER_CARD_STATUS } from 'core/assets/js/constants';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { addUsersToRoleDS } from 'roles/assets/js/data-services/role';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { orgPeopleListApiUrl } from 'people/urls';
import { pluralize } from 'core/assets/js/lib/utils';
import { roleFetchUsersApiUrl } from 'roles/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';


export const MODAL_ID = 'role-add-users-modal';

const RoleAddUsersPanel = ({
  dispatch,
  filtersOpen,
  isOpen,
  listComponentName,
  location: { search },
  match: { params },
  onFiltersToggle,
  pagination,
  people,
  selectedItems,
}) => {
  const { orgAlias } = params;
  const roleId = params.roleId || params.systemRoleId;
  const constantFilters = {
    exclude_members_of_role: roleId,
    exclude_system_admins: true,
    status: USER_CARD_STATUS.APPROVED,
  };
  const [showSelectedItems, setShowSelectedItems] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [query, setQuery] = useState({ ...constantFilters });
  const selectedItemsLength = selectedItems.length;

  const handleShowSelectedItemsToggle = () => {
    setShowSelectedItems(!showSelectedItems);
  };

  const handleClosePanel = () => {
    dispatch(modalCloseAC());
  };

  const handleQueryUpdate = (newQuery) => {
    setQuery({ ...constantFilters, ...newQuery, page: pagination.page });
  };

  const handlePageChange = (newPagination) => {
    const newQuery = {
      ...constantFilters,
      ...query,
      ...newPagination,
    };
    setQuery(newQuery);
  };

  const onAddUsers = () => {
    setSubmitting(true);
    return dispatch(
      addUsersToRoleDS({
        orgAlias,
        roleId,
        userIds: selectedItems.map(u => u.user.id),
      }),
    )
      .then((addedUsers) => {
        setSubmitting(false);
        toastr.success(
          'Well Done!',
          `${pluralize('User', addedUsers.length)} added successfully.`,
        );
        dispatch(fetchListDS({
          componentName: listComponentName,
          querystring: search,
          url: roleFetchUsersApiUrl(orgAlias, roleId),
        }));
        dispatch(modalCloseAC());
      })
      .catch(() => {
        toastr.error('Oh Snap!', 'Could not add users to role.');
      });
  };

  return (
    <ModalPanel
      modalId={MODAL_ID}
      open={isOpen}
      heading={<h4>Add Users</h4>}
      body={(
        <>
          <SelectableListWrapper
            cardItem={{
              component: ManagerCard,
              props: {
                linkTarget: '_blank',
                onUpdated: () => {},
                orgAlias,
              },
            }}
            componentName={RoleAddUsersPanel.GetComponentName()}
            emptyListMessage="No members found."
            emptySelectedListMessage={(
              <p className="discreet">
                There are currently no selected members.
                <br />
                <span
                  className="imitate-link"
                  onClick={handleShowSelectedItemsToggle}
                >
                  Find members
                </span>
                .
              </p>
            )}
            fetchData={({ authedAxios, url }) => dispatch(
              fetchListDS({
                authedAxios,
                componentName: RoleAddUsersPanel.GetComponentName(),
                querystring: qs.stringify(query),
                url: orgPeopleListApiUrl({ orgAlias, peopleType: PEOPLE_TYPE.MANAGERS }, url),
              }),
            )}
            filtersOpen={filtersOpen}
            items={people}
            onPageChange={handlePageChange}
            query={query}
            searchComponent={(
              <PeopleSearch
                filtersOpen={filtersOpen}
                hideStatus
                isManager
                onFiltersChanged={handleQueryUpdate}
                onFiltersToggle={onFiltersToggle}
                peopleType={PEOPLE_TYPE.MANAGERS}
                query={query}
              />
            )}
            selectModeEnabled
            showSelectedItems={showSelectedItems}
            skeletonComponent={PeopleListSkeleton}
          />
        </>
      )}
      footer={(
        <>
          <a
            className="cursor-pointer d-flex align-items-center"
            onClick={handleShowSelectedItemsToggle}
          >
            <TDSwitch selected={showSelectedItems} />

            <span className="ml-3">
              { !showSelectedItems
                ? `Show ${selectedItemsLength} selected ${pluralize('member', selectedItemsLength)}`
                : 'Show all members'
              }
            </span>
          </a>

          <div className="ml-auto">
            <>
              <TDButton
                label="Cancel"
                onClick={handleClosePanel}
              />

              <TDButton
                className="ml-4"
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={selectedItemsLength === 0 || submitting}
                label={`Add ${selectedItemsLength} ${pluralize('member', selectedItemsLength)}`}
                onClick={onAddUsers}
              />
            </>
          </div>
        </>
      )}
    />
  );
};
RoleAddUsersPanel.GetComponentName = () => 'RoleAddUsersPanel';


RoleAddUsersPanel.propTypes = {
  addMembersModalPayload: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  isOpen: PropTypes.bool,
  listComponentName: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  orgAlias: PropTypes.string,
  pagination: PropTypes.object.isRequired,
  people: PropTypes.array,
  rateLimits: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  userGroupNames: PropTypes.array,
};

RoleAddUsersPanel.defaultProps = {
  addMembersModalPayload: {},
  isOpen: false,
  orgAlias: '',
  people: [],
  rateLimits: {},
  userGroupNames: [],
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, RoleAddUsersPanel.GetComponentName());

  return {
    addMembersModalPayload: getModalPayload(state, MODAL_ID),
    isOpen: getIsModalOpen(state, MODAL_ID),
    match: props.match,
    pagination: listState.pagination,
    people: listState.items,
    rateLimits: listState.extras.rateLimits,
    searchActive: listState.search.isActive,
    selectedItems: listState.selectedItems,
    userGroupNames: listState.extras.userGroupNames,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectAddMembersPanelReduxConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoleAddUsersPanel);

export default withRouter(withFilters(ProjectAddMembersPanelReduxConnect));
