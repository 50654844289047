import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pick, find, isEmpty, orderBy } from 'lodash';
import queryString from 'query-string';

import StatusReason from 'finance/assets/js/components/StatusReason.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { RATE_STATUS, RATE_UNIT, RATE_SUGGEST_VALUE } from 'rates/assets/js/constants';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { financeWorksheetsUrl, financeWorksheetViewUrl } from 'finance/urls';
import { getListState } from 'core/assets/js/ducks/list';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import {
  fetchFinanceWorksheetDS,
} from 'finance/assets/js/data-services/view';
import { projectUpdateWorksheetDS } from 'projects/assets/js/data-services/form';
import { exchangeRatesResponseSpec, worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import WorksheetForm from 'projects/assets/js/components/WorksheetForm.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import { fetchRatesDS } from 'rates/assets/js/data-services/rates';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { fetchExchangeRatesDS } from 'finance/assets/js/data-services/form';
import { formatServiceOrderItems } from 'finance/assets/js/lib/utils';

class WorksheetEdit extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchFinanceWorksheetDS({
        orgAlias: params.orgAlias, id: params.worksheetId, url, authedAxios, componentName,
      })),
      dispatch(fetchRatesDS({
        orgAlias: params.orgAlias,
        status: [RATE_STATUS.ACTIVE],
        url,
        authedAxios,
        componentName,
      })),
      dispatch(fetchExchangeRatesDS({
        authedAxios, componentName, orgAlias: params.orgAlias, url,
      })),
    ]);
  }

  static GetComponentName() {
    return 'WorksheetEdit';
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.calculateInitialValues = this.calculateInitialValues.bind(this);
  }

  /**
   * Generates the initial values of the worksheet form.
   * If a selected rate is no longer available (User has deleted it), as a fallback,
   * the selected rate becomes "Claim an amount" with the amount calculated as
   * oldRate * orlQuantity.

   * @returns Object
   */
  calculateInitialValues() {
    const { worksheet, rates } = this.props;
    const orderedItems = orderBy(worksheet.items || [], 'taskId', 'desc');
    const _values = {
      ...(pick(worksheet, 'id', 'summary', 'attachments')),
      currency: worksheet.worksheetCurrency,
      period: {
        periodStart: worksheet.periodStart,
        periodEnd: worksheet.periodEnd,
      },
      items: (orderedItems).map((item) => {
        const rateExists = find(rates, rate => rate.id === item.rateId);

        return {
          currency: item.worksheetItemCurrency,
          currencySymbol: item.worksheetItemCurrencySymbol,
          description: item.description,
          exchangeRate: item.exchangeRate,
          exchangeRateUpdatedAt: item.exchangeRateUpdatedAt,
          id: item.id,
          projectId: item.project?.id,
          rate: item.worksheetItemRateAmount,
          rate_amount: item.worksheetItemRateAmount,
          rate_id: rateExists ? item.rateId : RATE_SUGGEST_VALUE,
          rate_unit: item.rateUnit,
          quantity: item.quantity,
          task_id: item.taskId,
          title: item.task ? item.task.title : item.title,
        };
      }),
    };

    return _values;
  }

  handleSubmit(values) {
    const {
      worksheet, match: { params: { orgAlias } },
      dispatch, location: { search }, history,
    } = this.props;
    const queryParams = queryString.parse(search);
    const backUrl = (queryParams && queryParams.backUrl) || financeWorksheetsUrl(orgAlias);
    return dispatch(projectUpdateWorksheetDS({
      orgAlias,
      projectId: worksheet.projectId,
      worksheetId: worksheet.id,
      redirect: () => {
        history.push(backUrl);
      },
    },
    {
      ...values,
      items: formatServiceOrderItems(values.items),
      // If custom value, update the rate unit
      rateUnit: values.rate_id === RATE_SUGGEST_VALUE ? RATE_UNIT.FIXED : values.rateUnit,
      projectId: worksheet.projectId,
      periodStart: values.period.periodStart,
      periodEnd: values.period.periodEnd,
    }));
  }


  render() {
    const {
      match: { params: { orgAlias }, url: currentUrl }, location: { search }, info,
      worksheet, rates, project, exchangeRatesResponse,
    } = this.props;
    const {
      allowedBillingPeriodStart, allowedBillingPeriodEnd, missingPeriods,
    } = info;
    const queryParams = queryString.parse(search);
    const backUrl = (queryParams && queryParams.backUrl) || financeWorksheetsUrl(orgAlias);

    const {
      rates: exchangeRates = {}, service: exchangeRatesService, timestamp: exchangeRateUpdatedAt,
    } = exchangeRatesResponse;

    if (!isEmpty(worksheet) && !worksheet.allowedActions.canBeEdited) {
      return (
        <RedirectRoute
          from={currentUrl}
          status={302}
          to={financeWorksheetViewUrl(orgAlias, worksheet.id)}
        />
      );
    }

    const breadcrumbs = [
      {
        title: 'Worksheets',
        url: financeWorksheetsUrl(orgAlias),
      },
      {
        title: 'Edit Worksheet',
        url: null,
      },
    ];
    const initialValues = this.calculateInitialValues();

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--edit-worksheet">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              blockingLoading
              skeletonComponent={ProjectCardSkeleton}
            >
              {!isEmpty(worksheet) && <StatusReason item={worksheet} itemType="worksheet" />}
              <WorksheetForm
                exchangeRates={exchangeRates}
                exchangeRatesService={exchangeRatesService}
                exchangeRateUpdatedAt={exchangeRateUpdatedAt}
                onSubmit={this.handleSubmit}
                backUrl={backUrl}
                orgAlias={orgAlias}
                info={{
                  allowedBillingPeriodStart,
                  allowedBillingPeriodEnd,
                  missingPeriods: missingPeriods || [],
                }}
                rates={rates}
                worksheet={worksheet}
                project={project}
                initialValues={initialValues}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

WorksheetEdit.propTypes = {
  allowedActions: PropTypes.shape({
    expense: PropTypes.object,
    project: PropTypes.object,
    task: PropTypes.object,
    worksheet: PropTypes.object,
  }).isRequired,
  exchangeRatesResponse: exchangeRatesResponseSpec,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  info: PropTypes.shape({
    missingPeriods: PropTypes.array,
    allowedBillingPeriodStart: PropTypes.string,
    allowedBillingPeriodEnd: PropTypes.string,
  }),
  location: PropTypes.object.isRequired,
  worksheet: worksheetSpec,
  project: projectSpec,
  dispatch: PropTypes.func.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
};

WorksheetEdit.defaultProps = {
  exchangeRatesResponse: { rates: {}, service: null, timestamp: null },
  info: {},
  worksheet: null,
  project: null,
  rates: null,
};

const mapStateToProps = (state) => {
  const componentName = WorksheetEdit.GetComponentName();
  const viewState = getViewState(state, componentName);
  const listState = getListState(state, componentName);

  return {
    allowedActions: getViewStateExtras(state, componentName, 'allowedActions'),
    exchangeRatesResponse: getViewStateExtras(state, componentName, 'exchangeRates'),
    info: getViewStateExtras(state, componentName, 'nextWorksheetInfo'),
    project: getViewStateExtras(state, componentName, 'project'),
    rates: listState.items,
    worksheet: viewState.item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const WorksheetEditConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorksheetEdit);

export default withRouter(WorksheetEditConnected);
