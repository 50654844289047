import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, flatten } from 'lodash';
import { connect } from 'react-redux';

import { MAX_UPLOAD_FILES, USER_TYPE, USER_TYPE_LABEL, MIME_TYPES } from 'core/assets/js/constants';
import { TYPE as CUSTOM_FIELD_TYPE } from 'interviews/assets/js/constants';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import SelectionField from 'core/assets/js/components/FinalFormFields/SelectionField.jsx';
import YesNoField from 'core/assets/js/components/FinalFormFields/YesNoField.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';

const CustomFieldRenderer = ({ field, selectedOption, isManager }) => {
  let fieldComponent;
  let inputType;
  let isMultipleFiles;
  let fieldChoices = field.payload?.choices || [];
  if (field.type === CUSTOM_FIELD_TYPE.YESNO && isEmpty(fieldChoices)) {
    fieldChoices = [
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ];
  }

  const fieldLabel = (
    <MarkdownText
      text={field.label}
      disallowedTypes={['paragraph']}
    />
  );
  const choices = fieldChoices.map(choice => ({
    value: choice.value,
    text: (
      <MarkdownText
        text={choice.text}
        key={`${field.path}-choice-${choice.value}`}
        disallowedTypes={['paragraph']}
      />
    ),
    raw: choice.text,
  }));

  const getFileUploaderFieldAcceptFiles = (fieldPayload = {}) => {
    const { attachment_types: attachmentTypes } = fieldPayload;
    if (isEmpty(attachmentTypes)) {
      return [];
    }

    return flatten(
      attachmentTypes.map(({ value }) => MIME_TYPES[value]),
    );
  };

  const { answeredByUserType } = field;
  const isDisabled = field.type !== CUSTOM_FIELD_TYPE.TEXT_BLOB && answeredByUserType && (
    (answeredByUserType === USER_TYPE.MANAGER && !isManager)
    || (answeredByUserType === USER_TYPE.PROVIDER && isManager)
  );
  const userTypeLabel = answeredByUserType && USER_TYPE_LABEL[answeredByUserType]?.toLowerCase();
  const disableWarning = isDisabled && (
    <span className="warning">
      The above question will be filled in by the
      {' '}
      {userTypeLabel}
      {'.'}
    </span>
  );

  switch (field.type) {
    case CUSTOM_FIELD_TYPE.TEXT && !field.payload.isTextArea:
    case CUSTOM_FIELD_TYPE.NUMBER:
      inputType = field.type === CUSTOM_FIELD_TYPE.NUMBER ? 'number' : 'text';
      fieldComponent = (
        <TextInputField
          type={inputType}
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          disabled={isDisabled}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.TEXT && field.payload.isTextArea:
      fieldComponent = (
        <TextAreaField
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          disabled={isDisabled}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.SELECT:
      fieldComponent = (
        <SelectionField
          name={field.path}
          required={field.required}
          label={fieldLabel}
          sublabel={field.description}
          optionsMapping={choices}
          isMultiple={field.payload.multiple}
          hasOther={field.payload.has_other}
          selectedOption={(
            selectedOption && !Array.isArray(selectedOption)
              ? [{ value: selectedOption, label: field.name }]
              : selectedOption
          )}
          disabled={isDisabled}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.TEXT_BLOB:
      fieldComponent = (
        <div className="form-group">
          <label>{fieldLabel}</label>
          <MarkdownText text={field.payload?.text || ''} withBreaksPlugin />
        </div>
      );
      break;
    case CUSTOM_FIELD_TYPE.DATE:
      fieldComponent = (
        <DatePickerField
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          disablePastDates={field.payload.disablePastDates || false}
          disableBeforeDate={field.payload.disableBeforeDate || null}
          disableAfterDate={field.payload.disableAfterDate || null}
          disabled={isDisabled}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.MONEY:
      fieldComponent = (
        <MoneyInputField
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          prefix={field.payload.prefix}
          decimals={field.payload.decimals}
          disabled={isDisabled}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.FILE:
    case CUSTOM_FIELD_TYPE.MULTIFILE:
      isMultipleFiles = field.type === CUSTOM_FIELD_TYPE.MULTIFILE || !!field.payload.multiple;
      fieldComponent = (
        <FileUploaderField
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          maxFiles={isMultipleFiles ? MAX_UPLOAD_FILES : 1}
          disabled={isDisabled}
          acceptFiles={getFileUploaderFieldAcceptFiles(field?.payload)}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.CHECKBOX:
      fieldComponent = (
        <CheckboxField
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          options={choices}
          disabled={isDisabled}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.RADIO:
      fieldComponent = (
        <RadioField
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          options={choices}
          disabled={isDisabled}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.YESNO:
      fieldComponent = (
        <YesNoField
          name={field.path}
          label={fieldLabel}
          sublabel={field.description}
          required={field.required}
          isMultiple={field.payload.multiple}
          disabled={isDisabled}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <React.Fragment>
      {fieldComponent}
      {disableWarning}
    </React.Fragment>
  );
};

CustomFieldRenderer.propTypes = {
  field: PropTypes.object.isRequired,
  selectedOption: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]),
  isManager: PropTypes.bool,
};

CustomFieldRenderer.defaultProps = {
  selectedOption: undefined,
  isManager: false,
};

const mapStateToProps = state => ({
  isManager: getHasOrgAccess(state)({ requireManager: true }),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const CustomFieldRendererConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFieldRenderer);

export default CustomFieldRendererConnected;
