import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { timeOffCreateUrl } from 'accounts/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import TimeOffList from 'accounts/assets/js/components/TimeOffList.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class TimeOffsView extends React.PureComponent {
  static GetComponentName() {
    return 'TimeOffsView';
  }

  render() {
    const { activeOrg, history } = this.props;
    const breadcrumbs = [
      {
        title: 'Availability',
        url: null,
      },
    ];

    const ctaButtonItems = [{
      label: 'Update availability',
      onClick: () => history.push(timeOffCreateUrl(activeOrg.alias)),
      variant: BS_STYLE.PRIMARY,
    }];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />
        <div className="page page--projects">
          <div className="container">
            <TimeOffList orgAlias={activeOrg.alias} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TimeOffsView.propTypes = {
  activeOrg: orgSpec.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const TimeOffsViewConnected = connect(mapStateToProps)(TimeOffsView);

export default withRouter(TimeOffsViewConnected);
