import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { PPH_STATUS, PPH_STATUS_CLASS } from 'search/assets/js/constants';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';


const OnboardingTalentStatus = ({ customLabel, status }) => {
  if (status === PPH_STATUS.NOT_INVITED) {
    return null;
  }

  return (
    <div className="onboarding-talent__status">
      <StatusTag
        hideTitle
        statusClass={PPH_STATUS_CLASS[status]}
        label={customLabel || _.startCase(status)}
      />
    </div>
  );
};

OnboardingTalentStatus.propTypes = {
  customLabel: PropTypes.string,
  status: PropTypes.oneOf(Object.values(PPH_STATUS)),
};

OnboardingTalentStatus.defaultProps = {
  customLabel: '',
  status: PPH_STATUS.NOT_INVITED,
};

export default OnboardingTalentStatus;
