/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'core/assets/js/lib/tdAxios';
import { filter, isEmpty } from 'lodash';

import { isSSR } from 'core/assets/js/config/checks';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import { MEMBER_SEARCH_TARGET, USER_TYPE, REACT_SELECT_STYLES, IMG_SIZE } from 'core/assets/js/constants';
import { PROJECT_MEMBER_STATUS } from 'projects/assets/js/constants';
import { projectMembersSearchApiUrl } from 'projects/urls';
import { orgPeopleListApiUrl } from 'people/urls';
import { userCardToAutocompleteItem } from 'organizations/assets/js/lib/utils';
import {
  orgSearchManagersApiUrl,
  orgSearchManagersStrictApiUrl,
  orgSearchManagersWithBudgetApiUrl,
  orgSearchProvidersApiUrl,
} from 'organizations/urls';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

const MAX_SKILLS = 3;
const memberSearchTypeToUrl = {
  [MEMBER_SEARCH_TARGET.MANAGERS]: ({ orgAlias, kw, projectId }) => (
    orgSearchManagersApiUrl(orgAlias, kw, projectId)
  ),
  [MEMBER_SEARCH_TARGET.MANAGERS_STRICT]: ({ orgAlias, kw, projectId }) => (
    orgSearchManagersStrictApiUrl(orgAlias, kw, projectId)
  ),
  [MEMBER_SEARCH_TARGET.MANAGERS_WITH_BUDGET]: ({ orgAlias, kw, budget }) => (
    orgSearchManagersWithBudgetApiUrl(orgAlias, kw, budget)
  ),
  [MEMBER_SEARCH_TARGET.PROVIDERS]: ({ orgAlias, kw, projectId }) => (
    orgSearchProvidersApiUrl(orgAlias, kw, projectId)
  ),
  [MEMBER_SEARCH_TARGET.PROJECT_PROVIDERS]: ({ orgAlias, kw, projectId }) => (
    projectMembersSearchApiUrl(
      orgAlias, projectId, kw, USER_TYPE.PROVIDER, PROJECT_MEMBER_STATUS.ACCEPTED,
    )
  ),
  [MEMBER_SEARCH_TARGET.TASK_ASSIGNMENT_ELIGIBLES]: ({ orgAlias, kw, projectId, taskId }) => (
    orgPeopleListApiUrl({
      orgAlias, peopleType: MEMBER_SEARCH_TARGET.PROVIDERS, projectId, taskId, kw,
    })
  ),
};

const memberSearchTypeToResponse = {
  [MEMBER_SEARCH_TARGET.MANAGERS]: response => (
    (response.userCards || []).map(uc => userCardToAutocompleteItem(uc))
  ),
  [MEMBER_SEARCH_TARGET.MANAGERS_STRICT]: response => (
    (response.userCards || []).map(uc => userCardToAutocompleteItem(uc))
  ),
  [MEMBER_SEARCH_TARGET.MANAGERS_WITH_BUDGET]: response => (
    (response.userCards || []).map(uc => userCardToAutocompleteItem(uc))
  ),
  [MEMBER_SEARCH_TARGET.PROVIDERS]: response => (
    (response.userCards || []).map(uc => userCardToAutocompleteItem(uc))
  ),
  [MEMBER_SEARCH_TARGET.PROJECT_PROVIDERS]: response => (
    (response.projectMembers || []).map(pm => userCardToAutocompleteItem(pm.userCard))
  ),
  [MEMBER_SEARCH_TARGET.TASK_ASSIGNMENT_ELIGIBLES]: response => (
    (response.projectMembers || []).map(pm => userCardToAutocompleteItem(pm.userCard))
  ),
};

const SingleValue = (props) => {
  const { data: { jobTitle, fullName } } = props;
  const tagLabel = jobTitle
    ? `${fullName} (${jobTitle})` : fullName;
  return (
    <components.SingleValue {...props}>
      <div className="Select-value">
        <span className="Select-value-label">
          {tagLabel}
          <span className="Select-aria-only">&nbsp;</span>
        </span>
      </div>
    </components.SingleValue>
  );
};
SingleValue.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  data: PropTypes.object.isRequired,
};


const MemberOptionRenderer = (props) => {
  const { data:
    { id, label, fullName, jobTitle, avatar, currency, rate, rateUnit, skills },
  } = props;
  const hasCompleteRate = rate && rateUnit && currency;
  const userRate = hasCompleteRate ? getDisplayRate(rate, rateUnit, { currency }) : '';

  return (
    <components.Option {...props}>
      <div className="user-select-item multi-select-option d-flex align-items-center  flex-nowrap" data-user-id={id}>
        <ProfilePic
          url={avatar}
          alt={label}
          size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
        />

        <div className="col-7 pl-3 pr-0">
          <div className="user-select-item__title text-truncate w-100">
            { fullName }
          </div>
          <div className="user-select-item__extra discreet text-truncate w-100">
            { jobTitle }
          </div>
        </div>

        { hasCompleteRate && (
          <div className="user-item__rate px-2 ml-auto">
            {userRate}
          </div>
        )}

      </div>

      { skills && skills.length > 0 && (
        <div className="user-item__skills d-none d-lg-flex col-12 px-0">
          <div className="w-100">
            <SkillList
              skills={skills}
              inline
              maxShownItems={MAX_SKILLS}
              modalId={`member-skills-${id}`}
            />
          </div>
        </div>
      )}
    </components.Option>
  );
};

MemberOptionRenderer.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    currency: PropTypes.string,
    rate: PropTypes.string,
    rateUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    skills: PropTypes.array,
  }).isRequired,
};


/**
 * Multiselect member component
 *
 * Searches members of an organization based on user role type or a keyword that is matched
 * against profiles.(first_name|last_name|job_title)
 */
class MemberMultiselect extends React.Component {
  constructor(props) {
    super(props);

    this.multiselectMode = (
      typeof props.multiselect === 'boolean'
        ? props.multiselect
        : props.target !== MEMBER_SEARCH_TARGET.MANAGERS_WITH_BUDGET);
    this.isBudgetSearch = props.target === MEMBER_SEARCH_TARGET.MANAGERS_WITH_BUDGET;

    this.minKwLength = 2;
    this.onChange = this.onChange.bind(this);
    this.smartSkillMatch = this.smartSkillMatch.bind(this);
    this.getMembers = this.getMembers.bind(this);
    this.parseMembers = this.parseMembers.bind(this);

    this.state = {
      value: props.input.value || '',
      options: [],
    };
  }

  componentDidMount() {
    const { useAsync, prefilledOptions } = this.props;
    if (!useAsync) {
      if (!prefilledOptions) {
        this.getMembers('', true).then((result) => {
          this.setState({ options: result });
        });
      } else {
        this.setState(this.parseMembers(prefilledOptions));
      }
    }
  }

  /**
   * Fire when the skill list is updated
   *
   * @param value Array of skill objects, each entry contains an id and label
   */
  onChange(value = '') {
    const { input, onItemsChanged } = this.props;
    this.setState({
      value,
    }, () => {
      // If multi select mode is not enabled, expect a single value
      const items = !Array.isArray(value)
        ? [(value || '')]
        : value;
      const serializedValues = items.map(item => item.id);
      // Notify redux form field about the change to update the value of the hidden field
      input.onChange(serializedValues.join(','));

      onItemsChanged(items);
    });
  }

  getMembers(input, omitKeyword = false) {
    const { orgAlias, payload, target, projectId, taskId } = this.props;
    const kw = input ? input.trim() : input;

    // Start searching after the 2nd letter
    if (!omitKeyword && !this.isBudgetSearch && (!kw || (kw && kw.length < this.minKwLength))) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
      }

      this.filterTimeout = setTimeout(() => {
        clearTimeout(this.filterTimeout);

        const urlGenerator = memberSearchTypeToUrl[target];
        if (!urlGenerator) {
          throw new Error(`We couldn't find a URL generator for ${target}`);
        }

        const apiUrl = urlGenerator({
          orgAlias, kw, projectId, budget: payload.budget, taskId,
        });

        const responseParser = memberSearchTypeToResponse[target];

        if (!responseParser) {
          throw new Error(`We couldn't find a response parser for ${target}`);
        }

        return axios.get(apiUrl)
          .then(response => responseParser(response.data))
          .then(userData => (
            resolve(this.parseMembers(userData, kw))
          )).catch(() => (
            reject([]) // eslint-disable-line
          ));
      }, 500);
    });
  }

  parseMembers(userData, kw = '') {
    const options = userData.map(user => ({
      ...user,
      skills: this.smartSkillMatch(kw, user.skills),
    }));
    return options;
  }

  /**
   * Scan all user skills and prioritize those that have a match on the user keyword
   *
   * We show MAX_SKILLS per user card; first show the matched skills, then pick any of the
   * rest till the MAX_SKILLS limit is satisfied
   *
   * @param kw
   * @param userSkills
   * @returns {*}
   */
  smartSkillMatch(kw, userSkills) { // eslint-disable-line
    const kwLower = kw ? kw.toLowerCase() : '';
    let orderedSkills;
    if (!isEmpty(userSkills)) {
      // Find the skills that contain the user keyword
      const matched = filter(userSkills,
        sk => sk.label.toLowerCase().includes(kwLower));

      if (matched.length) {
        if (matched.length > MAX_SKILLS) {
          // Show only the matched skills
          orderedSkills = matched.slice(0, MAX_SKILLS);
        } else {
          // Show all matched skills and then any of the rest till the MAX_SKILLS limit is satisfied
          const allButMatched = filter(
            userSkills, sk => !sk.label.toLowerCase().includes(kwLower),
          );
          orderedSkills = [
            ...matched,
            ...allButMatched.slice(0, (
              MAX_SKILLS > allButMatched.length
                ? MAX_SKILLS - allButMatched.length
                : allButMatched.length - MAX_SKILLS
            )),
          ];
        }
      } else {
        orderedSkills = userSkills.slice(0, MAX_SKILLS);
      }
    }
    return orderedSkills;
  }

  render() {
    const { input, placeholder, disabled, searchable, useAsync, prefilledOptions } = this.props;
    const { value: currentValue, options } = this.state;

    return (
      <div>
        <input {...input} type="hidden" />
        { !useAsync && (
          <Select
            isDisabled={disabled}
            name="members-multiselect"
            className="members-multiselect"
            styles={REACT_SELECT_STYLES}
            value={currentValue}
            onChange={this.onChange}
            valueKey="id"
            labelKey="label"
            menuPlacement="auto"
            menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
            isMulti={this.multiselectMode}
            joinValues
            isSearchable={searchable}
            cache={false}
            options={prefilledOptions ? options.concat(prefilledOptions) : options}
            components={{ SingleValue, Option: MemberOptionRenderer }}
            placeholder={placeholder}
            noResultsText="No results found."
            getOptionValue={opt => opt.id}
          />
        )}

        { useAsync && (
          <AsyncSelect
            disabled={disabled}
            name="members-multiselect"
            styles={REACT_SELECT_STYLES}
            menuPlacement="auto"
            menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
            className="members-multiselect"
            classNamePrefix="react-select"
            value={currentValue}
            onChange={this.onChange}
            noOptionsMessage={(data) => {
              if (data.inputValue === '') {
                return 'Start typing...';
              }
              return 'No result found';
            }}
            valueKey="id"
            labelKey="label"
            isMulti={this.multiselectMode}
            cacheOptions={false}
            loadOptions={this.getMembers}
            components={{ SingleValue, Option: MemberOptionRenderer }}
            placeholder={placeholder}
            isClearable
            getOptionValue={opt => opt.id}
          />
        )}
      </div>
    );
  }
}
MemberMultiselect.propTypes = {
  input: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  taskId: PropTypes.number,
  multiselect: PropTypes.bool,
  target: PropTypes.oneOf(Object.values(MEMBER_SEARCH_TARGET)).isRequired,
  payload: PropTypes.object,
  placeholder: PropTypes.string,
  useAsync: PropTypes.bool,
  onItemsChanged: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  prefilledOptions: PropTypes.array,
};
MemberMultiselect.defaultProps = {
  projectId: null,
  taskId: null,
  payload: {},
  multiselect: null,
  disabled: false,
  searchable: false,
  useAsync: true,
  placeholder: 'Select an option...',
  prefilledOptions: null,
  onItemsChanged: () => {},
};
export default MemberMultiselect;
