import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import { routerMatchSpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { projectCsvApiUrl, projectListApiUrl, projectMembersCsvApiUrl } from 'projects/urls';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import TDList from 'core/assets/js/components/TDList.jsx';
import ProjectCardItem from 'projects/assets/js/components/ProjectCardItem.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import ProjectSearch from 'projects/assets/js/components/ProjectSearch.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';

const COMPONENT_NAME = 'ProjectListView';

const ProjectList = ({
  extras, filtersOpen, items, location, match, onFiltersToggle, pagination, search,
}) => {
  const { isActive: isSearchActive } = search;
  const { customFieldFilters, budgetLimits } = extras;
  return (
    <React.Fragment>
      <div className="row">
        {isSearchActive && (
          <ProjectSearch
            budgetLimits={budgetLimits}
            onFiltersToggle={onFiltersToggle}
            filtersOpen={filtersOpen}
            customFieldFilters={customFieldFilters}
          />
        )}
        {!filtersOpen && (
          <ListActionsButton
            options={[
              {
                name: 'Export projects',
                onClick: () => {
                  const exportUrl = `${projectCsvApiUrl(match.params.orgAlias)}${location.search}`;
                  WINDOW_OPEN(exportUrl);
                },
              },
              {
                name: 'Export project members',
                onClick: () => {
                  WINDOW_OPEN(
                    `${projectMembersCsvApiUrl(match.params.orgAlias)}${location.search}`,
                  );
                },
              },
            ]}
          />
        )}
      </div>
      {!filtersOpen && (
        <TDList
          items={items}
          pagination={pagination}
          cardItem={{
            component: ProjectCardItem,
            props: {
              orgAlias: match.params.orgAlias,
            },
          }}
          emptyListMessage="No projects found"
          listClassName="project-list"
        />
      )}
    </React.Fragment>
  );
};

ProjectList.propTypes = {
  extras: PropTypes.shape({
    budgetLimits: PropTypes.object,
    customFieldFilters: PropTypes.array,
  }),
  filtersOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(projectSpec),
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  search: PropTypes.shape({
    isActive: PropTypes.bool,
  }),
};

ProjectList.defaultProps = {
  items: [],
  search: {},
  extras: {
    budgetLimits: {},
    customFieldFilters: [],
  },
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectList);

const TDApiConnectedProjectList = withTDApiConnected({
  fetchData: ({
    dispatch, params: { orgAlias }, url, authedAxios, componentName, querystring,
  }) => dispatch(fetchListDS({
    authedAxios, componentName, querystring, url: projectListApiUrl(orgAlias, url),
  })),
  duck: 'list',
  storeKey: COMPONENT_NAME,
  skeletonComponent: ProjectListSkeleton,
})(ProjectListConnected);

export default withRouter(TDApiConnectedProjectList);
