import React from 'react';
import pluralize from 'pluralize';
import { Card } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';
import { BS_STYLE } from 'core/assets/js/constants';
import { subscriptionSpec, subscriptionProductSpec } from 'finance/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchSubscriptionProductsDS } from 'finance/assets/js/data-services/list';
import SubscriptionCardSkeleton from 'finance/assets/js/components/skeletons/SubscriptionCardSkeleton.jsx';
import SubscriptionDetails from 'finance/assets/js/lib/SubscriptionDetails';
import { formatDate } from 'core/assets/js/lib/utils';
import StripeUpdateSubscriptionButton from 'finance/assets/js/components/StripeUpdateSubscriptionButton.jsx';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { billingSubscriptionStoreKey } from 'finance/assets/js/BillingView.jsx';
import BillingNoSubscriptionFound from 'finance/assets/js/components/BillingNoSubscription.jsx';
import { getListState } from 'core/assets/js/ducks/list';

const BillingTabSubscription = ({
  activeSubscription, orgMembersCount, subscriptionProducts,
}) => {
  const {
    renewal_date: renewalDate,
    total_seats: totalSeats,
    organization_id: subscriptionOrgId,
  } = activeSubscription;
  if (isEmpty(activeSubscription.details)) {
    return <BillingNoSubscriptionFound />;
  }
  const subscriptionDetails = new SubscriptionDetails(activeSubscription.details);
  const product = subscriptionDetails.getPlanProduct();
  const renewalInterval = subscriptionDetails.getPlanRenewalInterval();
  const costPerSeat = subscriptionDetails.getPlanCostPerSeat({ withCurrency: true });
  const totalCost = subscriptionDetails.getPlanTotalCost({ withCurrency: true });
  const usedSubscriptionProduct = subscriptionProducts.find(subProd => (
    subProd.subscriptionPlan === product && subProd.renewalInterval === renewalInterval),
  );
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <Card className="billing-widget">
          <Card.Header>
            Subscription details
          </Card.Header>
          <Card.Body>
            <h1>
              {`${SUBSCRIPTION_PLAN_LABELS[product]} (${totalSeats} ${pluralize('seat', totalSeats)})`}
            </h1>
            <div className="row">
              <div className="col-6 mb-4 col-md-3 mb-md-0">
                <div className="mb-2 sub-heading">Billing period</div>
                <div className="text-capitalize">{renewalInterval}</div>
              </div>
              <div className="col-6 mb-4 col-md-3 mb-md-0">
                <div className="mb-2 sub-heading">Next charge</div>
                <div>{formatDate(renewalDate) || '-'}</div>
              </div>
              <div className="col-6 col-md-3">
                <div className="mb-2 sub-heading">Seats price</div>
                <div>{`${totalCost} (VAT inc.)`}</div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-12 col-xl-4 mb-3 mb-xl-0">
        <Card className="billing-widget">
          <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
            <div>Active plan</div>
            <StripeUpdateSubscriptionButton
              orgId={subscriptionOrgId}
              label="Upgrade plan"
            />
          </Card.Header>
          <Card.Body className="d-flex flex-column align-items-center justify-content-center">
            <h3 className="text-danger mb-0">{SUBSCRIPTION_PLAN_LABELS[product]}</h3>
            <h1 className="mb-0">{costPerSeat}</h1>
            <div className="hint">
              {`per seat, ${renewalInterval}`}
            </div>
            <div className="mt-3 mb-5 text-center">
              {usedSubscriptionProduct.description}
            </div>
            <StripeUpdateSubscriptionButton
              orgId={subscriptionOrgId}
              label="Cancel plan"
              variant={BS_STYLE.LINK}
              className="text-danger"
            />
          </Card.Body>
        </Card>
      </div>
      <div className="col-12 col-xl-4">
        <Card className="billing-widget">
          <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
            <div>Seats</div>
            <StripeUpdateSubscriptionButton
              orgId={subscriptionOrgId}
              label="Manage seats"
            />
          </Card.Header>
          <Card.Body
            className="d-flex flex-column align-items-center align-items-xl-start justify-content-start"
          >
            <h1 className="mt-0">{`${totalSeats} ${pluralize('seat', totalSeats)}`}</h1>
            <div className="mb-2 sub-heading">Seats usage</div>
            <div>{`${orgMembersCount}/${totalSeats} ${pluralize('seat', totalSeats)} used`}</div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

const SubscriptionProductsStoreKey = 'Subscription-products';
BillingTabSubscription.GetComponentName = () => 'BillingTab-Subscription';

BillingTabSubscription.propTypes = {
  orgMembersCount: PropTypes.number.isRequired,
  activeSubscription: subscriptionSpec,
  subscriptionProducts: PropTypes.arrayOf(subscriptionProductSpec),
};

BillingTabSubscription.defaultProps = {
  activeSubscription: {},
  subscriptionProducts: [],
};

const BillingTabSubscriptionConnected = withTDApiConnected({
  duck: 'view',
  storeKey: BillingTabSubscription.GetComponentName(),
  fetchData: ({ dispatch, url, querystring, authedAxios }) => {
    return Promise.all([
      dispatch(fetchSubscriptionProductsDS({
        url, componentName: SubscriptionProductsStoreKey, authedAxios, querystring,
      })),
    ]);
  },
  skeletonComponent: SubscriptionCardSkeleton,
})(BillingTabSubscription);

const mapStateToProps = (state) => {
  const viewState = getViewState(state, billingSubscriptionStoreKey);
  const productsListState = getListState(state, SubscriptionProductsStoreKey);
  const orgMembersCount = getViewStateExtras(state, billingSubscriptionStoreKey, 'orgMembersCount');
  return {
    activeSubscription: viewState.item,
    orgMembersCount,
    subscriptionProducts: productsListState.items,
  };
};

export default connect(mapStateToProps)(BillingTabSubscriptionConnected);
