import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { ButtonGroup, Dropdown, Popover, Overlay } from 'react-bootstrap';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDRadio from 'core/assets/js/components/TDRadio.jsx';
import { isReactTest } from 'core/assets/js/config/checks';

const TDOverlay = ({
  children,
  ...props
}) => (
  <React.Fragment>
    {isReactTest && children}
    {!isReactTest && (
      <Overlay
        {...props}
      >
        {children}
      </Overlay>
    )}
  </React.Fragment>
);
TDOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

const FilteringDropDown = ({ filteringOptions, onFilterChange, defaultOrdering, location }) => {
  const qs = queryString.parse(location.search);
  let filtering = defaultOrdering;

  if (qs.filtering) {
    filtering = JSON.parse(qs.filtering);
  }

  const [filterBy, setFilterBy] = useState(filtering?.filterBy);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleChange = (filter) => {
    onFilterChange(filter);
  };

  return (
    <React.Fragment>
      <Dropdown
        as={ButtonGroup}
        className={`filter-by-dropdown mr-3 d-none d-md-flex ${show ? 'filter-by-dropdown--active' : ''}`}
      >
        <Dropdown.Toggle
          className="px-4"
          split
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target);
          }}
          variant={BS_STYLE.DEFAULT}
        >
          <span className="d-none d-sm-inline">
            {filterBy ? 'Filtered by' : 'Select Filter'}
            {' '}
          </span>
          { filterBy && (
            <strong>
              {filteringOptions.find(opt => opt.value === filterBy).text}
              {' '}
            </strong>
          )}
          {!filterBy && (
            <i
              className={ICON.CHEVRON_DOWN}
            />
          )}
        </Dropdown.Toggle>
        {filterBy && (
          <i
            onClick={() => {
              handleChange({
                filterBy: null,
              });
              setFilterBy(null);
              setShow(false);
            }}
            className={`${ICON.CROSS} clear-filter`}
          />
        )}
      </Dropdown>

      <TDOverlay
        show={show}
        target={target}
        placement="bottom"
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover
          className="direction-popover"
          id="direction-popover"
        >
          <div className="search-filter-item__header">
            <a className="close" onClick={() => setShow(false)}>
              <i
                className={`float-right ${ICON.CROSS}`}
              />
            </a>
            Filter By
          </div>

          <div className="search-filter-item__body">
            { filteringOptions && filteringOptions.map(option => (
              <div
                className="filter-by-item"
                key={`filterBy-${option.value}`}
                onClick={() => {
                  handleChange({
                    filterBy: option.value,
                  });
                  setFilterBy(option.value);
                  setShow(false);
                }}
              >
                <TDRadio
                  className="ml-0"
                  selected={filterBy === option.value}
                  label={option.text}
                />
              </div>
            ))}
          </div>
        </Popover>
      </TDOverlay>
    </React.Fragment>
  );
};

FilteringDropDown.propTypes = {
  defaultOrdering: PropTypes.object,
  location: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filteringOptions: PropTypes.array.isRequired,
};

FilteringDropDown.defaultProps = {
  defaultOrdering: null,
};

export default withRouter(FilteringDropDown);
