import React from 'react';
import PropTypes from 'prop-types';

import SubmissionCardSkeleton from './SubmissionCardSkeleton.jsx';

const SubmissionListSkeleton = ({ className }) => (
  <div className={['skeleton--project-list', className].join(' ')}>
    <SubmissionCardSkeleton variation="1" />
    <SubmissionCardSkeleton variation="2" />
    <SubmissionCardSkeleton variation="1" />
    <SubmissionCardSkeleton variation="2" />
    <SubmissionCardSkeleton variation="1" />
    <SubmissionCardSkeleton variation="2" />
  </div>
);

SubmissionListSkeleton.propTypes = {
  className: PropTypes.string,
};

SubmissionListSkeleton.defaultProps = {
  className: '',
};

export default SubmissionListSkeleton;
