import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import { determineReportEditUrl, followBackUrlIfPresent } from 'finance/assets/js/lib/utils';
import WorksheetViewSkeleton from 'finance/assets/js/skeletons/WorksheetViewSkeleton.jsx';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import BillableRequestAmendmentForm from 'projects/assets/js/components/BillableRequestAmendmentForm.jsx';
import {
  ACCESS_CONTROL_ALLOWED_ACTIONS as ALLOWED_ACTIONS,
  API_DATE_FORMAT,
  BS_STYLE,
  ICON,
} from 'core/assets/js/constants';
import {
  SERVICE_ORDER_PENDING_STATUSES,
  EXPENSE_STATUS,
  EXPENSE_STATUS_LABEL,
  EXPENSE_STATUS_CLASS,
  SERVICE_ORDER_TYPE,
} from 'projects/assets/js/constants';
import {
  expenseAllowedActionsSpec,
  projectMemberAllowedActionsSpec,
} from 'projects/assets/js/lib/objectSpecs';
import ExpensesTable from 'finance/assets/js/components/ExpensesTable.jsx';
import { expenseSpec } from 'finance/assets/js/lib/objectSpecs';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import { fetchFinanceExpenseDS } from 'finance/assets/js/data-services/view';

import {
  financeExpenseApproveDS,
  financeExpenseConfirmDS,
  financeExpenseRejectDS,
  financeExpenseRequestAmendmentDS,
  financeExpenseVoidDS,
} from 'finance/assets/js/data-services/form';
import { projectUpdateExpenseDS } from 'projects/assets/js/data-services/form';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import ReportLogo from 'finance/assets/js/components/ReportLogo.jsx';
import ReportDetails from 'finance/assets/js/components/ReportDetails.jsx';
import ReportCompany from 'finance/assets/js/components/ReportCompany.jsx';
import ReportPerson from 'finance/assets/js/components/ReportPerson.jsx';
import ReportFooter from 'finance/assets/js/components/ReportFooter.jsx';
import ReportAuditTrail from 'finance/assets/js/components/ReportAuditTrail.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ReasonForm from 'core/assets/js/components/ReasonForm.jsx';
import BillingPeriodWidget from 'finance/assets/js/components/BillingPeriodWidget.jsx';
import ReportProjectLink from 'finance/assets/js/components/ReportProjectLink.jsx';
import ReportInvoiceLinks from 'finance/assets/js/components/ReportInvoiceLinks.jsx';
import { profileSpec } from 'accounts/assets/js/lib/objectSpecs';
import BillableApproveForm from 'projects/assets/js/components/BillableApproveForm.jsx';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';
import ReportFinancialEntity from 'finance/assets/js/components/ReportFinancialEntity.jsx';
import ExpenseCancelForm from 'finance/assets/js/components/ExpenseCancelForm.jsx';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import { projectRequestBudgetUrl } from 'projects/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import BillableVoid from 'finance/assets/js/components/BillableVoid.jsx';

class ExpenseView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchFinanceExpenseDS({
        orgAlias: params.orgAlias, id: params.id, url, componentName, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ExpenseView';
  }

  constructor(props) {
    super(props);
    this.onSubmitUpdate = this.onSubmitUpdate.bind(this);
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.onAmendmentRequested = this.onAmendmentRequested.bind(this);
    this.handleExpenseVoid = this.handleExpenseVoid.bind(this);
  }

  async handleExpenseVoid() {
    const { match: { params: { orgAlias, id } }, dispatch } = this.props;
    await dispatch(financeExpenseVoidDS({
      componentName: this.constructor.GetComponentName(), id, orgAlias,
    }));
  }

  async onSubmitUpdate(values) {
    const { match: { params: { orgAlias } }, report, dispatch } = this.props;
    await dispatch(projectUpdateExpenseDS({
      orgAlias, projectId: report.projectId, expenseId: report.id,
    }, values));

    this.onSubmitSuccess();
  }

  async onAmendmentRequested(values) {
    const { match: { params: { orgAlias, id } }, dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();

    await dispatch(
      financeExpenseRequestAmendmentDS({ orgAlias, id, values, componentName }),
    );
  }

  onSubmitSuccess() {
    const { match: { params: { orgAlias } }, report, dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();

    dispatch(fetchFinanceExpenseDS({
      orgAlias,
      id: report.id,
      componentName,
    }));
    dispatch(refreshFinancePendingCountsDS({
      orgAlias,
    }));
  }

  renderTotals() {
    const { report } = this.props;
    const cssClasses = [
      'finance-report__summary',
      `finance-report__summary--${EXPENSE_STATUS_CLASS[report.statusCode]}`,
    ];

    return (
      <div className={cssClasses.join(' ')}>
        <div>
          <label>Total</label>
          <NumberTpl
            decimals={2}
            prefix={report.expenseCurrencySymbol}
            value={report.expenseAmount}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      dispatch,
      expenseActions,
      history,
      match,
      organization,
      projectMemberActions,
      report,
    } = this.props;
    const reports = [report];
    let fe = FinancialEntity.getEmpty();
    if (!isEmpty(report) && get(organization, 'id')) {
      fe = new FinancialEntity({ ...report.provider, orgId: organization.id });
    }

    const shouldRenderCompany = report.provider && report.provider.company
      && (report.provider.company.invoicable !== false);
    const status = EXPENSE_STATUS_LABEL[report.statusCode];
    const isEmployee = report.provider && report.provider.isEmployee;
    const componentName = this.constructor.GetComponentName();

    const canFundExpense = !!report.allowedActions && !!report.allowedActions.canBeFunded;

    const canApproveExpense = (
      SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
      && !!expenseActions && !!expenseActions.canApproveAnyExpense
      && !!report.allowedActions && !!report.allowedActions.canBeApproved
      && canFundExpense
    );

    const canConfirmExpense = (
      SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
      && !!expenseActions && !!expenseActions.canManageAnyExpense
      && !!report.allowedActions && !!report.allowedActions.canBeConfirmed
    );

    const canRejectExpense = (
      SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
      && !!expenseActions && !!expenseActions.canManageAnyExpense
      && !!report.allowedActions && !!report.allowedActions.canBeRejected
    );
    const canRequestExpenseAmendment = report && !!report.allowedActions
      && !!report.allowedActions.canBeRequestedToBeAmended;

    const ApproveForm = withModalForm({
      heading: 'Approve expense',
      confirmLabel: 'Approve',
      confirmStyle: BS_STYLE.SUCCESS,
    })(BillableApproveForm);
    const approveModalData = !canApproveExpense ? null : {
      key: 'expenseApproveForm',
      btnLabel: 'Approve',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      btnClassName: 'text-success',
      Form: (
        <ApproveForm
          initialValues={{
            process_at: moment().format(API_DATE_FORMAT),
          }}
          billableType={SERVICE_ORDER_TYPE.EXPENSE}
          isEmployee={isEmployee}
          onSubmit={values => (
            dispatch(financeExpenseApproveDS({
              data: values,
              componentName,
              orgAlias: match.params.orgAlias,
              documentId: match.params.id,
            }))
          )}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          warnNotConfirmed={(
            organization.second_level_of_service_order_approval
            && report.statusCode !== EXPENSE_STATUS.CONFIRMED
          )}
        />
      ),
    };

    const ConfirmForm = withModalForm({
      heading: 'Confirm expense',
      confirmLabel: 'Confirm',
      confirmStyle: BS_STYLE.SUCCESS,
    })(ReasonForm);

    const confirmModalData = canConfirmExpense && canFundExpense ? ({
      key: 'expenseConfirmForm',
      btnLabel: 'Confirm',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      btnClassName: 'text-success',
      Form: (
        <ConfirmForm
          onSubmit={values => (
            dispatch(financeExpenseConfirmDS({
              data: values,
              componentName,
              orgAlias: match.params.orgAlias,
              documentId: match.params.id,
            }))
          )}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          question="Are you sure you want to confirm this expense?"
        />
      ),
    }) : null;

    const RequestProjectBudgetForm = withModalForm({
      heading: 'Insufficient funds',
      confirmLabel: 'Request more budget',
      confirmStyle: BS_STYLE.SUCCESS,
    })(ReasonForm);

    const requestProjectBudgetModalData = canConfirmExpense && !canFundExpense ? ({
      key: 'worksheetConfirmForm',
      btnLabel: 'Confirm',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      btnClassName: 'text-success',
      Form: (
        <RequestProjectBudgetForm
          hasReason={false}
          onSubmit={() => {
            const nextLocation = projectRequestBudgetUrl(match.params.orgAlias, report.projectId);
            history.push(nextLocation);
          }}
          question={(
            <React.Fragment>
              <p>There are not enough funds in the project.</p>
              <p>The expense will not be processed until you add more budget to the project.</p>
            </React.Fragment>
          )}
        />
      ),
    }) : null;

    const RejectForm = withModalForm({
      heading: 'Reject expense',
      confirmLabel: 'Reject',
      confirmStyle: BS_STYLE.DANGER,
    })(ReasonForm);

    const rejectModalData = !canRejectExpense ? null : {
      key: 'expenseRejectForm',
      btnLabel: 'Reject',
      btnVariant: BS_STYLE.DANGER,
      btnIcon: ICON.CROSS,
      Form: (
        <RejectForm
          onSubmit={values => (
            dispatch(financeExpenseRejectDS({
              data: values,
              componentName,
              orgAlias: match.params.orgAlias,
              documentId: match.params.id,
            }))
          )}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          question="Are you sure you want to reject this expense claim?"
        />
      ),
    };

    const providerName = report.provider && report.provider.user && report.provider.user.profile
      ? `${report.provider.user.profile.name}’s`
      : 'the';

    const RequestAmendmentForm = withModalForm({
      heading: `Do you require any changes on ${providerName} expense?`,
      confirmLabel: 'Submit',
    })(BillableRequestAmendmentForm);

    const requestAmendmentModalData = !canRequestExpenseAmendment ? null : {
      key: 'expenseRequestAmendmentForm',
      btnLabel: 'Request amendment',
      btnVariant: BS_STYLE.WARNING,
      btnIcon: ICON.EDIT,
      Form: (
        <RequestAmendmentForm
          initialValues={{}}
          onSubmit={this.onAmendmentRequested}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          billable={report}
          billableLabel="expense"
        />
      ),
    };

    let voidModalData = null;
    if (report?.allowedActions?.canBeVoided) {
      const VoidExpenseForm = withModalForm({
        confirmLabel: 'Void', heading: `Void Expense #${report.id}`,
      })(BillableVoid);
      voidModalData = {
        btnIcon: ICON.REMOVE_CIRCLE,
        btnLabel: 'Void',
        btnVariant: BS_STYLE.WARNING,
        Form: (
          <VoidExpenseForm
            initialValues={{}}
            onSubmit={this.handleExpenseVoid}
            onSubmitSuccess={() => followBackUrlIfPresent(history)}
            billableTypeLabel="expense"
          />
        ),
        key: 'expenseVoidForm',
      };
    }

    const projectLink = (
      <ReportProjectLink
        projectId={report.projectId}
        orgAlias={match.params.orgAlias}
        projectReference={report.externalProjectId ? `${report.projectReference} (${report.externalProjectId})` : report.projectReference}
        allowedActions={projectMemberActions}
      />
    );

    const invoiceInfo = (
      <ReportInvoiceLinks
        orgAlias={match.params.orgAlias}
        invoices={report.invoices}
      />
    );

    const billingPeriod = (
      <BillingPeriodWidget
        period={report.period}
        endDate={report.periodEnd}
        startDate={report.periodStart}
        id={report.id}
      />
    );

    const heading = report.projectReference ? `${report.projectReference}_${report.id}` : '';

    const detailsRows = [
      { label: 'Project Ref', value: projectLink },
      { label: 'Invoice No', value: invoiceInfo },
      { label: 'Expense No', value: report.id },
      { label: 'Date', value: report.createdAt },
      { label: 'Billing Period', value: billingPeriod },
      {
        label: 'Status',
        value: (
          <ReportAuditTrail
            heading={heading}
            report={report}
            reportType={REPORT_TYPES.EXPENSE}
          />
        ),
      },
    ];

    const canCancelExpense = report && !!report.allowedActions
      && !!report.allowedActions.canBeCancelled;

    const CancelForm = withModalForm({
      heading: 'Cancel expense claim',
      withFooter: false,
    })(ExpenseCancelForm);

    const cancelModalData = !canCancelExpense ? null : {
      key: 'expenseCancelForm',
      btnLabel: 'Cancel expense',
      btnVariant: BS_STYLE.SECONDARY,
      btnClassName: 'text-danger',
      Form: (
        <CancelForm
          projectId={report.projectId}
          orgAlias={match.params.orgAlias}
          expenseId={report.id}
          initialValues={{}}
          onSuccess={() => { this.onSubmitSuccess(); }}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
        />
      ),
    };

    const editUrl = report.org && determineReportEditUrl(report.org.unique_alias, report);

    return (
      <ReportViewLayout
        reportType={REPORT_TYPES.EXPENSE}
        actions={[
          report.allowedActions && !!report.allowedActions.canBeEdited ? {
            eventKey: 'editWorksheet',
            label: 'Edit',
            onClick: () => history.push(editUrl),
            variant: BS_STYLE.WARNING,
          } : null,
          approveModalData || confirmModalData || requestProjectBudgetModalData,
          rejectModalData,
          requestAmendmentModalData,
          cancelModalData,
          voidModalData,
        ]}
        heading={heading}
        status={status}
        report={report}
      >
        <TDApiConnected
          duck="view"
          component={this.constructor}
          skeletonComponent={() => <WorksheetViewSkeleton type="expense" />}
          blockingLoading
        >
          <div className="finance-report__head row">
            {report.provider && (
              <ReportLogo
                className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0"
                company={report.provider.company}
                user={report.provider.user}
                profilePicFallback
              />
            )}
            <div className="finance-report__meta col-12 col-md-6 col-lg-4">
              <ReportDetails rows={detailsRows} />
            </div>
          </div>

          <hr />

          {report.financialContext && (
            <div className="row mb-4">
              <div className="finance-report__provider col-12 col-md-8">
                <ReportFinancialEntity label="From" financialEntity={report.financialContext.ownerFE} />
              </div>
              <div className="finance-report__provider__details col-12 col-md-4">
                <ReportFinancialEntity label="Addressed to" financialEntity={report.financialContext.recipientFE} />
              </div>
            </div>
          )}

          <div className="row mb-4">
            <div className="finance-report__provider col-12">
              <div>
                {shouldRenderCompany && (
                  <ReportCompany {...report.provider} />
                )}
                <div className="finance-report__provider__details">
                  <h5>Personal Details</h5>
                  <ReportPerson {...report.provider} financialEntity={fe} withJobTitle />
                </div>


                {report && report.attachments && report.attachments.length > 0 && (
                  <div className="mt-5">
                    <h5>Attachments</h5>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <AttachmentsList label={null} attachments={report.attachments} />
                      </div>
                    </div>
                  </div>
                )}
                {report?.summary && (
                  <div className="mt-5">
                    <h5>Summary</h5>
                    <p>{ report.summary }</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
            <ExpensesTable
              rateUnit={report.rateUnit}
              list={reports}
              emptyText="No expenses found"
              isExpenseView
            />
            <div className="finance-report__totals">
              {this.renderTotals()}
            </div>
          </div>

          {report.financialContext && (
            <div className="finance-report__payment__terms mt-5">
              <span>
                Upon approval, the expense will be included in the next invoice and an additional
                {' '}
                {report.financialContext.vatPercent}
                % VAT will be applied to the amount
              </span>
            </div>
          )}
          <div className="mt-4 finance-report__organization-footer-info">
            <ReportFooter owner={report.provider} />
          </div>
        </TDApiConnected>
      </ReportViewLayout>
    );
  }
}

ExpenseView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  organization: orgSpec.isRequired,
  report: expenseSpec,
  profile: profileSpec,
  expenseActions: expenseAllowedActionsSpec,
  projectMemberActions: projectMemberAllowedActionsSpec,
  history: routerHistorySpec.isRequired,
};

ExpenseView.defaultProps = {
  report: {},
  profile: null,
  expenseActions: {},
  projectMemberActions: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, ExpenseView.GetComponentName());
  const expenseActions = get(viewState, `extras.accessControl.${ALLOWED_ACTIONS.EXPENSE}`, {});
  const projectMemberActions = get(viewState, ['extras', 'accessControl', ALLOWED_ACTIONS.EXPENSE], {});

  return {
    match: props.match,
    organization: selectActiveOrg(state),
    profile: selectProfile(state),
    report: viewState.item,
    expenseActions,
    projectMemberActions,
  };
};
const ExpenseViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseView);

export default withRouter(ExpenseViewConnect);
