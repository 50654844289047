import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { getIsModalOpen, modalOpenAC, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import HubSpotForm from 'core/assets/js/components/HubSpotForm.jsx';
import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { BS_TOOLTIP_PLACEMENT, BS_SIZE, HS_FORM_ID_REG_EX, ICON, POPPER_PLACEMENT } from 'core/assets/js/constants';
import { finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';

const HS_MODAL_ID = 'hubspot-modal';

const TDLabel = ({
  className,
  extraInfo,
  label,
  name,
  popOverContent,
  popOverSize,
  popOverTitle,
  required,
  sublabel,
  suffix,
}) => {
  if (!label && !sublabel) {
    return null;
  }
  const isHubSpotModalOpen = useSelector(state => getIsModalOpen(state, HS_MODAL_ID));
  const dispatch = useDispatch();
  const hbFormId = label?.props?.text
    && HS_FORM_ID_REG_EX.test(label.props.text) && label.props.text.match(HS_FORM_ID_REG_EX)[1];

  const openHSFormModal = (e) => {
    e.preventDefault();
    dispatch(modalOpenAC(HS_MODAL_ID));
  };

  const closeHSFormModal = () => {
    dispatch(modalCloseAC());
  };

  return (
    <>
      <label className={className} htmlFor={`field-${name}`}>
        {!hbFormId && label && (
          <span className="label">{label}</span>
        )}

        {hbFormId && label?.props?.text && (
          <>
            <span className="label">
              {label.props.text.replaceAll(/\[link]\(hb-form-id-([a-zA-Z0-9_-]+\))/g, '')}
              <span onClick={openHSFormModal} className="imitate-link">link</span>
            </span>

            <ModalSimple
              backdrop="static"
              body={(
                <HubSpotForm formId={hbFormId} />
              )}
              heading="Form submission"
              noFooter
              onClose={closeHSFormModal}
              open={isHubSpotModalOpen}
              size={BS_SIZE.LARGE}
            />
          </>
        )}
        {required && ' *'}
        {suffix && ` ${suffix}`}
      </label>

      { extraInfo && (
        <TDElementWithTooltip
          el={<span className="ml-2"><i className={ICON.INFO} /></span>}
          placement={BS_TOOLTIP_PLACEMENT.TOP}
          tooltipMsg={extraInfo}
        />
      )}

      {(popOverTitle || popOverContent) && (
        <ElementWithPopOver
          className="d-inline-block"
          element={(<i className={`ml-2 ${ICON.INFO}`} />)}
          placement={POPPER_PLACEMENT.RIGHT}
          popOverTitle={popOverTitle}
          size={popOverSize}
          popOverContent={popOverContent}
        />
      )}

      <br />

      {sublabel && (
        <label htmlFor={`field-${name}`}>
          <span className="sublabel">
            {sublabel}
          </span>
        </label>
      )}
    </>
  );
};

TDLabel.propTypes = {
  className: PropTypes.string,
  extraInfo: PropTypes.string,
  label: finalFormFieldLabelSpec,
  labelPopOver: PropTypes.node,
  name: PropTypes.string.isRequired,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.string,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
};
TDLabel.defaultProps = {
  className: '',
  extraInfo: null,
  label: null,
  labelPopOver: null,
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
  required: false,
  sublabel: null,
  suffix: null,
};

export default TDLabel;
