import React from 'react';
import PropTypes from 'prop-types';

import { LOGO, ICON, PUBLIC_WEBSITE_URL } from 'core/assets/js/constants';

const GuestChatHeader = ({ isMenuOpened, onMenuClick }) => (
  <div className="header-container chat__header py-3 py-sm-5">
    <div className="header__logo-container d-flex align-items-center flex-wrap">
      <div className="d-flex mb-3 mb-sm-0 align-items-center">
        <a
          className={[
            'off-canvas-toggle do-not-print',
            isMenuOpened ? 'off-canvas-toggle--on' : '',
          ].join(' ')}
          onClick={onMenuClick}
        >
          <i className={ICON.MENU} />
        </a>
        <a href={PUBLIC_WEBSITE_URL}>
          <img
            width="130"
            src={LOGO.DEFAULT}
            className="header__logo"
            alt="TalentDesk.io Logo"
          />
        </a>
        <div className="header-container__title">Chat</div>
      </div>
    </div>
  </div>
);

GuestChatHeader.propTypes = {
  isMenuOpened: PropTypes.bool,
  onMenuClick: PropTypes.func,
};

GuestChatHeader.defaultProps = {
  isMenuOpened: false,
  onMenuClick: null,
};

export default GuestChatHeader;
