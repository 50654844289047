import AnnotatedRateMap from 'finance/assets/js/lib/AnnotatedRateMap';
import RateMap, { Conversion } from 'finance/assets/js/lib/RateMap';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import { DEFAULT_BALANCE_CURRENCY, SYSTEM_CURRENCY } from 'finance/assets/js/constants';

/**
 * This class is used to keep and answer questions about the FX rates of a transaction
 *
 * It is a concrete version of an annotated rate map with a schema used for transactions
 *
 */
class TransactionRateMap extends AnnotatedRateMap {
  /**
   * Parses a serialized version that may miss some fields
   *
   * @param {Object} serialized - a serialized rate map
   * @returns {TransactionRateMap} a deserialized instance
   */
  static parseSerializedRates(serialized) {
    const {
      balanceCurrency,
      targetCurrency,
      invoiceCurrency,
      invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
      balanceToTargetRate,
      balanceToInvoiceRate,
      balanceToInvoiceTargetRate,
      balanceToOrgRate,
      balanceToSystemRate,
    } = serialized;

    const conversions = [];
    if (!balanceToTargetRate) {
      throw new Error('expecting balanceToTargetRate');
    }

    conversions.push(new Conversion(
      balanceCurrency, balanceToTargetRate, targetCurrency,
    ));
    if (balanceToInvoiceRate) {
      conversions.push(new Conversion(
        balanceCurrency, balanceToInvoiceRate, invoiceCurrency,
      ));
    }
    if (balanceToOrgRate) {
      conversions.push(new Conversion(
        balanceCurrency, balanceToOrgRate, orgCurrency,
      ));
    }
    if (balanceToInvoiceTargetRate) {
      conversions.push(new Conversion(
        balanceCurrency, balanceToInvoiceTargetRate, invoiceTargetCurrency,
      ));
    }
    if (balanceToSystemRate) {
      conversions.push(new Conversion(
        balanceCurrency, balanceToSystemRate, systemCurrency,
      ));
    }
    return new RateMap(conversions);
  }

  static fromCurrentBalanceRates(currentBalanceRates, {
    balanceCurrency,
    targetCurrency,
    invoiceCurrency,
    invoiceTargetCurrency,
    orgCurrency,
    systemCurrency = SYSTEM_CURRENCY,
  }) {
    assertAllKeysPresent({
      currentBalanceRates,
      balanceCurrency,
      targetCurrency,
      invoiceCurrency,
      invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
    });
    const rateMap = RateMap.fromSingleSourceRates(balanceCurrency, currentBalanceRates, {
      limitCurrencies: [
        balanceCurrency,
        targetCurrency,
        invoiceCurrency,
        invoiceTargetCurrency,
        orgCurrency,
        SYSTEM_CURRENCY,
        DEFAULT_BALANCE_CURRENCY,
      ],
    });

    const rateMapAtTransactionTime = new TransactionRateMap({
      balanceCurrency,
      targetCurrency,
      invoiceCurrency,
      invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
      balanceToTargetRate: rateMap.getRate(balanceCurrency, targetCurrency),
      balanceToInvoiceRate: rateMap.getRate(balanceCurrency, invoiceCurrency),
      balanceToInvoiceTargetRate: rateMap.getRate(balanceCurrency, invoiceTargetCurrency),
      balanceToOrgRate: rateMap.getRate(balanceCurrency, orgCurrency),
      balanceToSystemRate: rateMap.getRate(balanceCurrency, systemCurrency),
    }, { extendsRateMap: rateMap });

    return rateMapAtTransactionTime;
  }

  static fromIdentity(currency) {
    return TransactionRateMap.fromCurrentBalanceRates(
      { [currency]: 1.0 },
      {
        balanceCurrency: currency,
        targetCurrency: currency,
        invoiceCurrency: currency,
        invoiceTargetCurrency: currency,
        orgCurrency: currency,
      },
    );
  }

  constructor(serialized, { extendsRateMap } = {}) {
    super(serialized, { schema: {
      balanceToTargetRate: { from: 'balanceCurrency', to: 'targetCurrency' },
      balanceToInvoiceRate: { from: 'balanceCurrency', to: 'invoiceCurrency' },
      balanceToInvoiceTargetRate: { from: 'balanceCurrency', to: 'invoiceTargetCurrency' },
      balanceToOrgRate: { from: 'balanceCurrency', to: 'orgCurrency' },
      balanceToSystemRate: { from: 'balanceCurrency', to: 'systemCurrency' },
    }, extendsRateMap });
  }
}

export default TransactionRateMap;
