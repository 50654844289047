import PropTypes from 'prop-types';
import React from 'react';

import SkillsAndOrSelectField from 'core/assets/js/components/FinalFormFields/SkillsAndOrSelectField.jsx';

const SkillsFilterField = ({ filter: { label, paramName } }) => (
  <>
    <h4>{label}</h4>
    <SkillsAndOrSelectField name={paramName} />
  </>
);

SkillsFilterField.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string.isRequired, paramName: PropTypes.string.isRequired,
  }).isRequired,
};

export default SkillsFilterField;
