import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { countWords, truncate } from 'core/assets/js/lib/utils';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';

const ReadMore = ({
  text, toggleTextExpand, toggleTextCollapse, words, appendEl,
}) => {
  const [expanded, setExpanded] = useState(false);
  const needsTruncation = countWords(text) > words;

  if (!needsTruncation) {
    return (
      <React.Fragment>
        { text }
        { appendEl }
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      { !expanded && truncate(text, words) }
      { expanded && (
        <MarkdownText withBreaksPlugin text={text} />
      )}
      {' '}
      <span
        className="imitate-link font-weight-bolder"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? toggleTextCollapse : toggleTextExpand}
      </span>
      { appendEl }
    </React.Fragment>
  );
};

ReadMore.propTypes = {
  text: PropTypes.string,
  toggleTextExpand: PropTypes.string,
  toggleTextCollapse: PropTypes.string,
  appendEl: PropTypes.node,
  words: PropTypes.number,
};
ReadMore.defaultProps = {
  toggleTextExpand: 'Show More',
  toggleTextCollapse: 'Show Less',
  words: 20,
  text: '',
  appendEl: null,
};

export default ReadMore;
