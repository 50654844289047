import React from 'react';
import PropTypes from 'prop-types';

import { BS_SIZE } from 'core/assets/js/constants';
import { finalFormFieldLabelSpec, finalFormFieldMetaSpec } from 'core/assets/js/lib/objectSpecs';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import withField from 'core/assets/js/components/withField.jsx';

const SwitchField = ({
  label, sublabel, disabled, additionalError,
  required, input, meta: { error, pristine, submitError, touched },
  wrapperClasses, labelClassName, defaultValue, extraLabelActions,
  popOverContent, popOverSize, popOverTitle,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = (touched && (error || submitError)) || showAdditionalError;
  const hasExtraLabelActions = extraLabelActions;
  const groupClassName = ['form-group'];

  if (hasError) {
    groupClassName.push('has-error');
  }
  if (wrapperClasses) {
    groupClassName.push(wrapperClasses);
  }

  const field = (
    <TDSwitch
      disabled={disabled}
      className="ml-auto"
      data-testid={`field-${input.name}`}
      onClick={() => {
        input.onChange(!input.value);
      }}
      selected={'value' in input ? !!input.value : defaultValue}
    />
  );

  return (
    <div className={groupClassName.join(' ')}>
      <div className="d-flex justify-content-between">
        {label && (
          <TDLabel
            className={labelClassName}
            label={label}
            name={input.name}
            popOverContent={popOverContent}
            popOverSize={popOverSize}
            popOverTitle={popOverTitle}
            required={required}
            sublabel={sublabel}
          />
        )}
        {hasExtraLabelActions && extraLabelActions}

        {field}
      </div>
      {showAdditionalError && (
        <span className="help-block d-inline-block mt-3">{additionalError}</span>
      )}

      {hasError && <span className="help-block d-inline-block mt-3">{submitError || error}</span>}
    </div>
  );
};

SwitchField.propTypes = {
  additionalError: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  extraLabelActions: PropTypes.node,
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  labelClassName: PropTypes.string,
  meta: finalFormFieldMetaSpec,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.string,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  wrapperClasses: PropTypes.string,
};

SwitchField.defaultProps = {
  additionalError: '',
  defaultValue: '',
  disabled: false,
  extraLabelActions: null,
  label: '',
  labelClassName: '',
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
  required: false,
  sublabel: null,
  wrapperClasses: '',
};

export default withField(SwitchField);
