import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import UserGroupListSkeleton from 'core/assets/js/components/Skeleton/UserGroupListSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton';
import TDList from 'core/assets/js/components/TDList.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import UserGroupCardPreview from 'people/assets/js/components/UserGroupCardPreview.jsx';
import UserGroupsListSearch from 'people/assets/js/components/UserGroupsListSearch.jsx';
import { userGroupCreateUrl, userGroupsListApiUrl } from 'people/urls';

const UserGroupsList = ({ items, pagination }) => {
  const history = useHistory();
  const { orgAlias } = useParams();
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);
  return (
    <>
      <UserGroupsListSearch initialValues={pick(parsedQueryString, 'kw', 'ordering')} />
      <TDList
        cardItem={{ component: UserGroupCardPreview }}
        emptyListClassName="rounded shadow-sm p-4 bg-white"
        emptyListMessage={(
          <div
            className={(
              'd-flex flex-column align-items-center justify-content-center empty-list-message'
            )}
          >
            <i className={ICON.USERS_DUOTONE} />
            You don&apos;t have any groups yet
            <TDButton
              className="mt-5"
              label="Create new group"
              onClick={() => history.push(userGroupCreateUrl(orgAlias))}
              variant={BS_STYLE.PRIMARY}
            />
          </div>
        )}
        items={items}
        listClassName="user-group-list"
        pagination={pagination}
      />
    </>
  );
};

UserGroupsList.propTypes = {
  items: PropTypes.array,
  pagination: paginationSpec.isRequired,
};

UserGroupsList.defaultProps = {
  items: [],
};

const ListWithTDApi = withTDApiConnected({
  fetchData: ({
    dispatch, params, authedAxios, componentName, querystring,
  }) => {
    const { orgAlias } = params;
    return dispatch(fetchListDS({
      authedAxios,
      componentName,
      querystring,
      url: userGroupsListApiUrl({ orgAlias }),
    }));
  },
  duck: 'list',
  storeKey: 'UserGroupsListView',
  skeletonComponent: UserGroupListSkeleton,
})(UserGroupsList);

export default ListWithTDApi;
