import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Dropdown } from 'react-bootstrap';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import IntegrationConfirmDeleteModal, { MODAL_ID } from 'integrations/assets/js/components/IntegrationConfirmDeleteModal.jsx';
import IntegrationStatusBadge from 'integrations/assets/js/components/IntegrationStatusBadge.jsx';
import IntegrationSwitch from 'integrations/assets/js/components/IntegrationSwitch.jsx';
import { BS_STYLE, BS_SIZE, ICON } from 'core/assets/js/constants';
import { INTEGRATION_SYNC_TYPE_LABEL } from 'integrations/assets/js/constants';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import { orgIntegrationDetailUrl } from 'integrations/urls';

const IntegrationCard = ({ dispatch, integration, onCheckStatus, onToggle, orgAlias }) => {
  const integrationUrl = orgIntegrationDetailUrl(orgAlias, integration.id);
  const isToggleEnabled = (
    integration.allowedActions?.canBeDisabled || integration.allowedActions?.canBeEnabled
  );

  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 mb-4">
        <Card
          className="integration-card"
          key={integration.type}
        >
          <div className="integration-card-img__container d-flex justify-content-center align-items-center">
            <Link
              to={integrationUrl}
              className="integration-card-img d-flex justify-content-center align-items-center"
            >
              <Card.Img variant="top" src={integration.logo} />
            </Link>
          </div>
          <Card.Body>
            <Link to={integrationUrl}>
              <Card.Title as="h3">{integration.name}</Card.Title>
              <Card.Text>{integration.description}</Card.Text>
              <Card.Text>
                {INTEGRATION_SYNC_TYPE_LABEL[integration.syncType]}
                {` (last sync: ${integration.lastSync || 'None'})`}
              </Card.Text>
            </Link>
          </Card.Body>
          <Card.Footer>
            <div className="row integration-actions align-items-center">
              <div className="col-7">
                <IntegrationStatusBadge
                  status={integration.status}
                />
              </div>
              <div className="col-5">
                <div className="row justify-content-end">
                  {isToggleEnabled && (
                    <IntegrationSwitch
                      containerClassName="mr-3"
                      integrationId={integration.id}
                      onToggle={onToggle}
                      isEnabled={integration.isActive}
                    />
                  )}
                  <TDDropButton
                    className="mr-3"
                    overlayClassName="db-cta-row-dropdown"
                    toggleEl={(
                      <TDButton
                        bsSize={BS_SIZE.SMALL}
                        variant={BS_STYLE.DEFAULT}
                        btnIcon={<i className={ICON.ARROW_DOWN} />}
                      />
                    )}
                  >
                    {integration.allowedActions?.canCheckStatus && (
                      <Dropdown.Item
                        key="check-status"
                        onSelect={onCheckStatus}
                      >
                        Check status
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      key="delete-integration"
                      onSelect={() => dispatch(modalOpenAC(MODAL_ID))}
                    >
                      Delete
                    </Dropdown.Item>
                  </TDDropButton>
                </div>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </div>
      <IntegrationConfirmDeleteModal integration={integration} />
    </>
  );
};

IntegrationCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  integration: integrationSpec.isRequired,
  onCheckStatus: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationCard);

export default IntegrationCardConnected;
