import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchWorksheetImportPreviewInfoDS } from 'projects/assets/js/data-services/view';
import { getViewState } from 'core/assets/js/ducks/view';
import { ICON } from 'core/assets/js/constants';

const COMPONENT_NAME = 'ProjectWorksheetImportDone';

const ProjectWorksheetImportDone = ({ validWorksheetsCount }) => (
  <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
    <i className={ICON.IMPORT_FILE_DUOTONE} />
    <h2>Almost done!</h2>
    {validWorksheetsCount === 0 ? (
      <p className="mb-0">
        <strong>
          We could not find any valid worksheets. Please try again with valid worksheets
        </strong>
      </p>
    ) : (
      <p className="mb-0">
        <strong>
          {validWorksheetsCount}
          {' '}
          worksheets are being imported. Please wait…
        </strong>
      </p>
    )}
    { validWorksheetsCount !== 0 && (
      <p>
        Once import is done, you will be redirected to your worksheets.
      </p>
    )}
    <br />
  </div>
);

ProjectWorksheetImportDone.propTypes = {
  validWorksheetsCount: PropTypes.number.isRequired,
};

const TdApiConnectedPreview = withTDApiConnected({
  fetchData: ({
    dispatch, params: { orgAlias, id }, authedAxios, querystring,
  }) => dispatch(fetchWorksheetImportPreviewInfoDS({
    orgAlias, id, authedAxios, componentName: COMPONENT_NAME, querystring,
  })),
  duck: 'view',
  storeKey: COMPONENT_NAME,
})(ProjectWorksheetImportDone);

const mapStateToProps = (state) => {
  const viewState = getViewState(
    state, COMPONENT_NAME,
  );
  return {
    validWorksheetsCount: viewState.item.validWorksheets?.length,
  };
};

const ProjectWorksheetImportDoneConnected = connect(
  mapStateToProps,
)(TdApiConnectedPreview);
export default ProjectWorksheetImportDoneConnected;
