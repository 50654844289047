import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useLocation, withRouter } from 'react-router-dom';

import { API_DATE_FORMAT, BS_STYLE } from 'core/assets/js/constants';
import { getListState } from 'core/assets/js/ducks/list';
import { fetchViewDS, getViewStateExtras } from 'core/assets/js/ducks/view';
import { proFormaInvoiceAclSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { financeProFormaInvoicesUrl, financeProFormaInvoiceViewUrl } from 'finance/urls';
import {
  createProFormaInvoiceDS,
  fetchDraftInvoiceAllowedActionsDS,
  fetchExchangeRatesDS,
  fetchServiceOrderMissingPrerequisitesDS,
} from 'finance/assets/js/data-services/form';
import { exchangeRatesResponseSpec } from 'finance/assets/js/lib/objectSpecs';
import {
  formatServiceOrderItems, getProFormaInvoiceDefaultNewItem,
} from 'finance/assets/js/lib/utils';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { fetchRatesDS } from 'rates/assets/js/data-services/rates';
import { RATE_STATUS } from 'rates/assets/js/constants';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { settingsPaymentTermsApiUrl } from 'settings/urls';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProFormaInvoiceForm from 'finance/assets/js/ProFormaInvoiceForm.jsx';
import ProFormaInvoiceFormSkeleton from 'finance/assets/js/components/skeletons/ProFormaInvoiceFormSkeleton.jsx';
import ServiceOrderPrerequisites from 'projects/assets/js/components/ServiceOrderPrerequisites.jsx';

const ProFormaInvoiceCreateView = ({
  allowedActions,
  dispatch,
  exchangeRatesResponse,
  history,
  missingPrerequisites,
  organization,
  rates,
}) => {
  const location = useLocation();
  const { provider_id: providerIdInQuery } = queryString.parse(location.search);

  const providerId = providerIdInQuery && /^\d+$/.test(providerIdInQuery.toString())
    ? parseInt(providerIdInQuery, 10)
    : null;

  const {
    rates: exchangeRates,
    service: exchangeRatesService,
    timestamp: exchangeRateUpdatedAt,
  } = exchangeRatesResponse;

  const orgAlias = organization.alias;

  const breadcrumbs = [
    {
      title: 'Proforma Invoices',
      url: financeProFormaInvoicesUrl(orgAlias),
    },
    {
      title: providerId ? 'Pay a bonus' : 'Submit Proforma Invoice',
      url: null,
    },
  ];

  const canCreate = allowedActions.canRaise || (
    allowedActions.canCreateForProviders && providerId
  );

  const onSubmit = async ({ invoiceReferenceNumberEnabled, items, period, ...remainingValues }) => {
    const values = {
      ...remainingValues,
      ...period,
      items: formatServiceOrderItems(items),
    };
    if (allowedActions.canCreateForProviders) {
      values.providerId = providerId;
    }
    const isInvoiceReferenceNumberEnabled = (
      Array.isArray(invoiceReferenceNumberEnabled)
      && invoiceReferenceNumberEnabled.length === 1
      && invoiceReferenceNumberEnabled[0].value === true
    );
    if (isInvoiceReferenceNumberEnabled && !values.invoiceReferenceNumber) {
      return { invoiceReferenceNumber: 'Untick "Add a custom invoice number" or enter a value' };
    }
    if (!isInvoiceReferenceNumberEnabled) {
      values.invoiceReferenceNumber = null;
    }
    try {
      const newInvoice = await createProFormaInvoiceDS(orgAlias, values);
      history.push(financeProFormaInvoiceViewUrl(orgAlias, newInvoice.id));
    } catch (error) {
      if (
        error.response?.data?._meta?.name
        && error.response.data._meta.name.includes('ValidationError')
      ) {
        return error.response.data;
      }
      toastr.error('Oh Snap!', error.response?.data?._error || error.message);
    }
    return null;
  };

  const today = moment().format(API_DATE_FORMAT);

  const defaultItem = getProFormaInvoiceDefaultNewItem(rates.find(r => r.isDefault));

  const initialValues = {
    currency: organization.currency,
    invoiceReferenceNumber: null,
    invoiceReferenceNumberEnabled: false,
    items: [defaultItem],
    period: { periodEnd: today, periodStart: today },
  };

  const onAddItem = fields => { fields.push(defaultItem); };

  return (
    <>
      <ContentHeader breadcrumbs={breadcrumbs} />
      <div className="page page--finance">
        <div className="container">
          <TDApiConnected
            duck="view"
            fetchData={({ authedAxios, componentName }) => Promise.all([
              dispatch(fetchExchangeRatesDS({ authedAxios, componentName, orgAlias })),
              dispatch(fetchRatesDS({
                authedAxios, componentName, orgAlias, status: [RATE_STATUS.ACTIVE],
              })),
              dispatch(fetchViewDS({
                authedAxios, componentName, url: settingsPaymentTermsApiUrl(),
              })),
              dispatch(fetchDraftInvoiceAllowedActionsDS({ authedAxios, orgAlias, componentName })),
              dispatch(fetchServiceOrderMissingPrerequisitesDS({
                authedAxios, componentName, orgAlias, userId: providerId,
              })),
            ])}
            skeletonComponent={ProFormaInvoiceFormSkeleton}
            storeKey={ProFormaInvoiceCreateView.GetComponentName()}
          >
            <>
              {canCreate && (
                <>
                  <ServiceOrderPrerequisites
                    isForAnotherUser={!!providerId}
                    missingFields={missingPrerequisites}
                    orgAlias={orgAlias}
                    orgName={organization.name}
                    serviceOrderType={SERVICE_ORDER_TYPE.PROFORMA_INVOICE}
                  />
                  <ProFormaInvoiceForm
                    exchangeRates={exchangeRates}
                    exchangeRatesService={exchangeRatesService}
                    exchangeRateUpdatedAt={exchangeRateUpdatedAt}
                    initialValues={initialValues}
                    onAddItem={onAddItem}
                    onSubmit={onSubmit}
                    organization={organization}
                    isManagerCreatingForProvider={!!providerId}
                    missingPrerequisites={missingPrerequisites}
                    rates={rates}
                  />
                </>
              )}
              {!canCreate && (
                <TDSystemMessage
                  data-testid="pro-forma-invoice-create-cannot"
                  title="You cannot create a Proforma Invoice"
                  type={BS_STYLE.DANGER}
                >
                  <p>You cannot create a Proforma Invoice.</p>
                </TDSystemMessage>
              )}
            </>
          </TDApiConnected>
        </div>
      </div>
    </>
  );
};

ProFormaInvoiceCreateView.GetComponentName = () => 'ProFormaInvoiceCreateView';

ProFormaInvoiceCreateView.propTypes = {
  allowedActions: proFormaInvoiceAclSpec,
  dispatch: PropTypes.func.isRequired,
  exchangeRatesResponse: exchangeRatesResponseSpec,
  history: routerHistorySpec.isRequired,
  missingPrerequisites: PropTypes.arrayOf(PropTypes.string),
  organization: orgSpec.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
};

ProFormaInvoiceCreateView.defaultProps = {
  allowedActions: {},
  exchangeRatesResponse: {},
  missingPrerequisites: [],
  rates: [],
};

const mapStateToProps = state => {
  const componentName = ProFormaInvoiceCreateView.GetComponentName();
  return {
    allowedActions: getViewStateExtras(state, componentName, 'allowedActions'),
    exchangeRatesResponse: getViewStateExtras(
      state, componentName, 'exchangeRates',
    ),
    organization: selectActiveOrg(state),
    missingPrerequisites: getViewStateExtras(state, componentName, 'missingPrerequisites', []),
    rates: getListState(state, componentName).items,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ProFormaInvoiceCreateViewConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ProFormaInvoiceCreateView);

export default withRouter(ProFormaInvoiceCreateViewConnected);
