import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Form } from 'react-final-form';
import { omit, isEmpty, uniq } from 'lodash';
import { connect } from 'react-redux';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BS_TOOLTIP_PLACEMENT, ICON } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { CONTACTS_IMPORT_LIMIT, CONTACTS_IMPORT_STEP } from 'contacts/assets/js/constants';
import { contactsImportUrl } from 'contacts/urls';
import { fetchContactsImportInfoDS } from 'contacts/assets/js/data-services/view';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ContactsImportMappingFormSkeleton from 'contacts/assets/js/components/skeletons/ContactsImportMappingFormSkeleton.jsx';
import { pluralize, truncate } from 'core/assets/js/lib/utils';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import {
  fetchCustomFieldTemplatesDS, getCustomFieldTemplates,
} from 'interviews/assets/js/ducks/customFields';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

class ContactsImportMappingForm extends React.Component {
  static GetComponentName() {
    return 'ContactsImportMappingForm';
  }

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(fieldsMapping) {
    const {
      first_name: firstName, last_name: lastName, email, phone, notes, resume,
      job_title: jobTitle, address,
    } = fieldsMapping;
    const { history, match: { params: { orgAlias } }, location, customFieldTemplates } = this.props;
    const fileHandle = queryString.parse(location.search).id;
    const defaultMappingFields = [
      'first_name', 'last_name', 'email', 'phone', 'resume', 'notes', 'job_title', 'address', 'id',
    ];
    const fieldKeys = Object.keys(fieldsMapping);

    const allCustomFields = customFieldTemplates.map(template => template.questions).flat();
    const selectedCustomFieldTemplateIds = uniq(allCustomFields
      .filter(field => fieldKeys.includes(field.path))
      .map(field => field.customFieldTemplateId));

    const customFieldParams = omit(fieldsMapping, defaultMappingFields);
    const queryParams = {
      firstName,
      lastName,
      email,
      phone: phone || undefined,
      resume: resume || undefined,
      notes: notes || undefined,
      jobTitle: jobTitle || undefined,
      address: address || undefined,
      id: fileHandle,
      customFieldTemplateIds: selectedCustomFieldTemplateIds,
      ...customFieldParams,
    };
    history.push({
      pathname: contactsImportUrl(orgAlias, CONTACTS_IMPORT_STEP.PREVIEW),
      search: queryString.stringify(queryParams),
    });
  }

  render() {
    const { extras: { rowsCount, headers }, customFieldTemplates,
      match: { params: { orgAlias } }, history,
    } = this.props;
    const maxHeaderWords = 10;
    const requiredFields = ['first_name', 'last_name', 'email'];
    const optionsMappings = headers.map(header => ({ text: header, value: header }));
    const fieldsMap = [
      { label: 'First name', name: 'first_name', required: true },
      { label: 'Last name', name: 'last_name', required: true },
      { label: 'Email', name: 'email', required: true },
      { label: 'Phone', name: 'phone' },
      { label: 'Resume', name: 'resume' },
      { label: 'Notes', name: 'notes' },
      { label: 'Job Title', name: 'job_title' },
      { label: 'Address', name: 'address' },
    ];
    const hasCustomFields = !isEmpty(customFieldTemplates);
    return (
      <Form
        onSubmit={this.submit}
        render={({ handleSubmit, pristine, submitting }) => {
          return (
            <form
              onSubmit={handleSubmit}
            >
              <Card
                className="contacts-import--mapping"
              >
                <Card.Body>
                  <h3 className="mb-3 mt-0">Map fields</h3>
                  <div>
                    We’ve uploaded your CSV file and detected
                    {' '}
                    <span className="font-weight-bolder">{rowsCount}</span>
                    {` ${pluralize('contact', rowsCount)} `}
                    ready for import. Make sure your fields below match the ones TalentDesk
                    uses and finalise your import.
                  </div>
                  {rowsCount > CONTACTS_IMPORT_LIMIT && (
                    <TDSystemMessage
                      type={BS_STYLE.INFO}
                      title="Max contacts per file limit reached"
                      className="mt-4"
                    >
                      {`You have imported a file with ${rowsCount} contacts. Uploaded file should not include more than ${CONTACTS_IMPORT_LIMIT} contacts.`}
                    </TDSystemMessage>
                  )}
                  <hr />
                  <div className="row d-none d-sm-flex align-items-center mb-4">
                    <div className="col-12 col-sm-3 mb-0">
                      <div className="mapping-header font-weight-bolder">Predefined fields</div>
                    </div>
                    <div className="col-12 col-sm-9 pl-0">
                      <div className="mapping-header font-weight-bolder">Imported file headers</div>
                    </div>
                  </div>
                  { fieldsMap.map(field => (
                    <div className="row" key={field.name}>
                      <div className="d-none d-sm-flex col-3">
                        <label className="w-100 bg-gray pt-3 pl-3 mb-0">
                          {`${field.label} ${field.required ? '*' : ''}`}
                        </label>
                      </div>
                      <div className="col-12 col-sm-9 pb-3 pl-4 pl-sm-0">
                        <SelectField
                          labelClassName="d-inline-block d-sm-none"
                          label={field.label}
                          name={field.name}
                          component={SelectField}
                          optionsMapping={optionsMappings}
                          required={field.required}
                          validate={val => (
                            ((requiredFields.includes(field.name) && !val)
                              ? 'Field is required'
                              : null
                            )
                          )}
                        />
                      </div>
                    </div>
                  ))}
                  { hasCustomFields && customFieldTemplates.map(field => (
                    <div key={field.id}>
                      <div className="my-4 mapping-header font-weight-bolder">{field.name}</div>
                      { field.questions.map(question => (
                        <div className="row" key={question.path}>
                          <div className="d-none d-sm-flex col-3">
                            <label className="w-100 bg-gray p-3 mb-0">
                              {truncate(question.label, maxHeaderWords)}
                              { question.description && (
                                <TDElementWithTooltip
                                  el={<span className="ml-2"><i className={ICON.INFO} /></span>}
                                  placement={BS_TOOLTIP_PLACEMENT.TOP}
                                  tooltipMsg={question.description}
                                />
                              )}
                            </label>
                          </div>
                          <div className="col-12 col-sm-9 pb-3 pl-4 pl-sm-0">
                            <SelectField
                              labelClassName="d-inline-block d-sm-none"
                              label={question.label}
                              name={question.path}
                              component={SelectField}
                              optionsMapping={optionsMappings}
                              required={question.required}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </Card.Body>
              </Card>
              <div className="row mt-5 ">
                <div className="col-12 text-right">
                  <TDButton
                    variant={BS_STYLE.DEFAULT}
                    label="Back"
                    onClick={() => history.push(
                      contactsImportUrl(orgAlias, CONTACTS_IMPORT_STEP.UPLOAD),
                    )}
                  />
                  <TDButton
                    className={`position-tweak ${pristine ? 'btn--pristine' : 'btn--dirty'}`}
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    disabled={submitting || pristine || rowsCount > CONTACTS_IMPORT_LIMIT}
                    label="Preview import"
                  />
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

ContactsImportMappingForm.propTypes = {
  extras: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
};

ContactsImportMappingForm.defaultProps = {
  customFieldTemplates: [],
};

const TdApiConnectedMappingForm = withTDApiConnected({
  fetchData: ({ dispatch, params, authedAxios, url, componentName, querystring }) => {
    const { orgAlias } = params;
    return Promise.all([
      dispatch(
        fetchContactsImportInfoDS({ orgAlias, authedAxios, componentName, querystring }),
      ),
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias, entityType: FIELD_ENTITY_TYPE.USER, authedAxios, url,
      })),
    ]);
  },
  duck: 'view',
  storeKey: ContactsImportMappingForm.GetComponentName(),
  skeletonComponent: ContactsImportMappingFormSkeleton,
})(ContactsImportMappingForm);

const mapStateToProps = state => ({
  customFieldTemplates: getCustomFieldTemplates(state),
});

const connectedForm = connect(
  mapStateToProps,
)(TdApiConnectedMappingForm);

export default connectedForm;
