import React from 'react';

import IntegrationContractorsMappingSettings from 'integrations/assets/js/components/settings/IntegrationContractorsMappingSettings.jsx';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const IntegrationSetupContractorsMapping = ({ integration }) => (
  <>
    <h2>Map contractors</h2>
    <IntegrationContractorsMappingSettings integration={integration} />
  </>
);

IntegrationSetupContractorsMapping.propTypes = {
  integration: integrationSpec.isRequired,
};

export default IntegrationSetupContractorsMapping;
