import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { orgPeopleListApiUrl } from 'people/urls';
import { putResendInvitationDS, putCancelInvitationDS } from 'people/assets/js/data-services/list';
import { fetchListDS, listReplaceItemAC } from 'core/assets/js/ducks/list';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import OrgInvitationCard from 'people/assets/js/components/OrgInvitationCard.jsx';
import CancelInvitationForm from 'people/assets/js/components/CancelInvitationForm.jsx';
import OrgInviteesSearch from 'people/assets/js/components/OrgInviteesSearch.jsx';
import { fetchInterviewDS } from 'interviews/assets/js/data-services/interviews';

class OrgInviteesList extends React.Component {

  constructor(props) {
    super(props);
    const defaultModalState = {
      open: false,
      heading: '-',
      body: '-',
      confirmStyle: 'primary',
      confirmLabel: 'Ok',
    };

    // Resend invitation modal
    this.handleResendInvitation = this.handleResendInvitation.bind(this);
    this.handleResendInvitationOpenModal = this.handleResendInvitationOpenModal.bind(this);
    this.handleResendInvitationCloseModal = this.handleResendInvitationCloseModal.bind(this);

    // Cancel invitation modal
    this.handleCancelInvitation = this.handleCancelInvitation.bind(this);
    this.handleCancelInvitationOpenModal = this.handleCancelInvitationOpenModal.bind(this);
    this.handleCancelInvitationCloseModal = this.handleCancelInvitationCloseModal.bind(this);

    this.state = {
      resendInvitationModal: defaultModalState,
      cancelInvitationModal: defaultModalState,
    };
  }

  handleResendInvitationOpenModal(heading, body, confirmLabel, invitationId) {
    this.setState({
      resendInvitationModal: {
        body,
        confirmLabel,
        confirmStyle: 'primary',
        heading,
        open: true,
        payload: {
          invitationId,
        },
      },
    });
  }

  handleResendInvitationCloseModal() {
    const { resendInvitationModal } = this.state;
    this.setState({ resendInvitationModal: { ...resendInvitationModal, open: false } });
  }

  handleResendInvitation({ invitationId }) {
    const { dispatch, match: { params: { orgAlias } } } = this.props;
    const componentName = 'OrgInviteeListView';

    return dispatch(putResendInvitationDS({
      invitationId,
      orgAlias,
    })).then(({ updated }) => {
      dispatch(listReplaceItemAC(updated, componentName));
      toastr.success('Well Done!', 'Invitation was re-sent successfully');
    });
  }

  handleCancelInvitationOpenModal(heading, body, confirmLabel, invitationId) {
    this.setState({
      cancelInvitationModal: {
        body,
        confirmLabel,
        confirmStyle: 'primary',
        heading,
        open: true,
        payload: {
          invitationId,
        },
      },
    });
  }

  handleCancelInvitationCloseModal() {
    const { cancelInvitationModal } = this.state;
    this.setState({
      cancelInvitationModal: {
        ...cancelInvitationModal,
        open: false,
      },
    });
  }

  handleCancelInvitation({ invitationId, values }) {
    const { dispatch, match: { params: { orgAlias } } } = this.props;
    const componentName = 'OrgInviteeListView';

    dispatch(putCancelInvitationDS({
      invitationId,
      orgAlias,
      values,
    })).then(({ updated }) => {
      dispatch(listReplaceItemAC(updated, componentName));
      toastr.success('Well Done!', 'Invitation was cancelled successfully');
    });

    this.handleCancelInvitationCloseModal();
  }

  render() {
    const { items: people, pagination, search, filtersOpen, onFiltersToggle } = this.props;
    const isSearchActive = search.isActive;
    const { resendInvitationModal, cancelInvitationModal } = this.state;
    return (
      <>
        <div className="row">
          {isSearchActive && (
            <OrgInviteesSearch
              onFiltersToggle={onFiltersToggle}
              filtersOpen={filtersOpen}
            />
          )}
        </div>
        <ModalConfirm
          key="resendInvitationModal"
          onClose={this.handleResendInvitationCloseModal}
          onConfirm={this.handleResendInvitation}
          {...resendInvitationModal}
        />
        {cancelInvitationModal.open && (
          <CancelInvitationForm
            onClose={this.handleCancelInvitationCloseModal}
            onConfirm={this.handleCancelInvitation}
            {...cancelInvitationModal}
          />
        )}
        {!filtersOpen && (
          <TDList
            items={people}
            pagination={pagination}
            cardItem={{
              component: OrgInvitationCard,
              props: {
                onOpenResendModal: this.handleResendInvitationOpenModal,
                onOpenCancelModal: this.handleCancelInvitationOpenModal,
              },
            }}
            emptyListMessage="No invited people found."
          />
        )}
      </>
    );
  }
}

OrgInviteesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  pagination: paginationSpec.isRequired,
  search: PropTypes.shape({
    isActive: PropTypes.bool,
  }),
};

OrgInviteesList.defaultProps = {
  items: [],
  search: {},
};

const ListWithTDApi = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName, querystring, props,
  }) => {
    const { interviewId } = props;
    const prerequisites = [
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        querystring,
        url: orgPeopleListApiUrl({ ...params, peopleType: 'managers/invitees' }, url),
      })),
    ];

    if (interviewId) {
      prerequisites.push(dispatch(
        fetchInterviewDS({ url, params, authedAxios, componentName, interviewId })),
      );
    }
    return Promise.all(prerequisites);
  },
  loadingEnabled: false,
  duck: 'list',
  storeKey: 'OrgInviteeListView',
  skeletonComponent: PeopleListSkeleton,
})(OrgInviteesList);

export default withRouter(ListWithTDApi);
