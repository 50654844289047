import React from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';


import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import RolesTable from 'roles/assets/js/components/RolesTable.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { roleCreateUrl } from 'roles/urls';
import RolesPermissionsTabs from 'roles/assets/js/components/RolesPermissionsTabs.jsx';
import PermissionsGroupList from 'roles/assets/js/components/PermissionsGroupList.jsx';
import { ROLES_TABS } from '../constants';
import DeleteRoleModal from 'roles/assets/js/components/DeleteRoleModal.jsx';

const RolesView = () => {
  const history = useHistory();
  const { orgAlias, tab } = useParams();

  return (
    <>
      <ContentHeader
        breadcrumbs={[{ title: 'Roles & Permissions', url: null }]}
        ctaButtonItems={[{
          label: 'Create Role',
          onClick: () => history.push(roleCreateUrl(orgAlias)),
          variant: BS_STYLE.PRIMARY,
        }]}
      >
        <RolesPermissionsTabs />
      </ContentHeader>

      <div className="page page--roles" data-testid="roles-list-page">
        <div className="container">
          {ROLES_TABS.ROLES === tab ? (
            <RolesTable />
          ) : (
            <PermissionsGroupList />
          )}
        </div>
      </div>

      <DeleteRoleModal />
    </>
  );
};

RolesView.GetComponentName = () => 'RolesView';

export default RolesView;
