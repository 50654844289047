import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { TASK_ACTION } from 'projects/assets/js/constants';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

const InformPendingOwnerAction = ({ isManager, action }) => {
  let message;

  switch (action) {
    case TASK_ACTION.INFORM_PENDING_ASSIGNMENT:
      message = 'There aren’t any assigned users yet. The task owner or managers should assign the task';
      break;
    case TASK_ACTION.INFORM_PENDING_RATE_INVITATION_FOR_OWNER:
      message = 'A rate adjustment requested by the provider or task manager is currently awaiting approval.';
      break;
    case TASK_ACTION.INFORM_PENDING_ASSINGMENT_INVITATION_FOR_OWNER:
      message = 'The task was assigned to a provider but they haven’t accepted the task yet.';
      break;
    case TASK_ACTION.INFORM_PENDING_COMPLETION_INVITATION_FOR_OWNER:
      message = 'The provider requested that the task should be completed and the task owner or a task manager should handle this request';
      break;
    default:
      message = null;
      break;
  }

  if (!message || !isManager) {
    return null;
  }

  return (
    <div className="discussion-board__row">
      <TDSystemMessage
        title="Pending actions for the task owner or managers"
        className=""
      >
        {message}
      </TDSystemMessage>
    </div>
  );
};

InformPendingOwnerAction.propTypes = {
  isManager: PropTypes.bool,
  action: PropTypes.oneOf([
    TASK_ACTION.INFORM_PENDING_ASSIGNMENT,
    TASK_ACTION.INFORM_PENDING_RATE_INVITATION_FOR_OWNER,
    TASK_ACTION.INFORM_PENDING_ASSINGMENT_INVITATION_FOR_OWNER,
    TASK_ACTION.INFORM_PENDING_COMPLETION_INVITATION_FOR_OWNER,
  ]).isRequired,
};

InformPendingOwnerAction.defaultProps = {
  isManager: false,
};

const mapStateToProps = state => ({
  isManager: getHasOrgAccess(state)({ requireManager: true }),
});
const mapDispatchToProps = dispatch => ({ dispatch });

const InformPendingOwnerActionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InformPendingOwnerAction);

export default InformPendingOwnerActionConnected;
