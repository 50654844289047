import React from 'react';
import PropTypes from 'prop-types';

import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { FORM_NAME } from 'projects/assets/js/components/WorksheetForm.jsx';
import { ICON, PROJECT_TABS } from 'core/assets/js/constants';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import Storage from 'core/assets/js/lib/Storage';

const ProjectViewItem = ({ item, worksheetType, userId }) => {
  const { id, owner_name: ownerName, title, tasksCount } = item;
  const hasDraft = !!Storage.get(`${FORM_NAME}-${userId}-${id}`);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
        <h3
          className="project-list__item--title d-flex flex-column flex-md-row align-items-md-center"
        >
          {title}
          { hasDraft && worksheetType === SERVICE_ORDER_TYPE.WORKSHEET && (
            <span className="d-inline">
              <StatusTag
                className="ml-0 mt-2 ml-md-2 mt-md-0"
                hideTitle
                statusClass="pending"
                label="Draft"
              />
            </span>
          )}
        </h3>
        <div>
          <span className="discreet project-list__item--label">
            Manager
          </span>
          <span>
            {ownerName}
          </span>

          {tasksCount && (
            <span className="project-list__item--label ml-4">
              <i className={`${ICON.SUCCESS} mr-2 discreet`} />
              {tasksCount}
              {' '}
              {tasksCount === 1 ? 'Task' : 'Tasks'}
            </span>
          )}
        </div>
      </div>
      <span className="project-list__item--warning mr-4">
        <i className={`${ICON.WARNING} mr-2`} />
        You can&apos;t submit a worksheet for a project which has not been started!
      </span>
    </div>
  );
};

ProjectViewItem.propTypes = {
  item: projectSpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  projectTab: PropTypes.oneOf(Object.values(PROJECT_TABS)),
  worksheetType: PropTypes.number.isRequired,
};

ProjectViewItem.defaultProps = {
  projectTab: PROJECT_TABS.DASHBOARD,
};

export default ProjectViewItem;
