import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import { discoverTalentDetailApiUrl } from 'search/urls';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import StatusDot from 'core/assets/js/components/StatusDot.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDRating from 'core/assets/js/components/TDRating.jsx';
import TalentDetailsSkeleton from 'core/assets/js/components/Skeleton/TalentDetailsSkeleton.jsx';
import { BS_STYLE, ICON, IMG_SIZE, MODAL_SIZES } from 'core/assets/js/constants';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import {
  fetchViewDS,
} from 'core/assets/js/ducks/view';

const FreelancerDetailsCard = ({
  address,
  avatar,
  chatRoom,
  countryCode,
  description,
  fullName,
  handleChat,
  handleToggleFavorite,
  isFavorite,
  isFavoriteLoading,
  jobTitle,
  rate,
  rateUnit,
  rating,
  reviews,
}) => {
  const [show, setShow] = useState(false);
  const handleDescription = (textDescription) => {
    if (textDescription.length > 120) {
      return (
        <p className="text-left">
          {`${textDescription.substring(0, 120)}...`}
          <span className="imitate-link" onClick={() => setShow(true)}>
            Read more
          </span>
        </p>
      );
    }
    return <p className="text-left">{textDescription.substring(0, 120)}</p>;
  };

  return (
    <>
      <Card className="user-profile__card mb-2">
        <div className="freelancer-item__fav pr-2">
          <TDButton
            className="p-3 freelancer-item__fav-icon"
            disabled={isFavoriteLoading}
            label={<span className={isFavorite ? ICON.HEART_FILLED : ICON.HEART} />}
            onClick={handleToggleFavorite}
            variant={BS_STYLE.LINK}
          />
        </div>
        <Card.Body className="px-0">
          <div className="user-profile__summary d-flex flex-column align-items-center text-center">
            <ProfilePic
              alt={`${fullName ?? 'User'}'s avatar`}
              className="mr-0 mb-3"
              size={[IMG_SIZE.LARGE, IMG_SIZE.LARGE]}
              url={avatar}
            />
            <div className="user-profile__basic-info d-flex flex-column justify-content-end w-100 freelancer-details-card">
              <div className="px-4">
                <div className="mt-2 mt-md-0">
                  <span className="user-profile__name">{fullName}</span>
                </div>

                <div className="text-truncate w-100">
                  <span
                    className="user-profile__job-title truncate mb-2 my-md-0"
                    title={jobTitle}
                  >
                    {jobTitle}
                  </span>
                </div>

                <div className="my-5">
                  <CountryFlagIcon countryISOCode={countryCode} />
                  <span className="ml-3">
                    {address}
                    <br />
                  </span>
                </div>
              </div>

              <div className="freelancer-details-card__seperator">
                {handleDescription(description)}
              </div>
              <div className="freelancer-details-card__seperator row">
                <div className="col-6 text-center pl-0 pr-1">
                  <h4 className="mb-0">
                    <strong>
                      {rateUnit}
                      {rate}
                      /hr
                    </strong>
                  </h4>

                  <label>
                    <span className="sublabel">Rate</span>
                  </label>
                </div>
                <div className="col-6 text-center pl-1 pr-0">
                  <h4 className="mb-0">
                    <strong>{rating}</strong>
                    <TDRating initialRating={rating} readonly />
                  </h4>

                  <label>
                    <span className="sublabel">
                      {reviews}
                      {' '}
                      Reviews
                    </span>
                  </label>
                </div>
              </div>
              <div className="freelancer-details-card__seperator row justify-content-center align-items-center">
                <Button
                  className="position-relative"
                  data-testid="chat-button"
                  disabled={!fullName}
                  onClick={handleChat}
                  variant={BS_STYLE.PRIMARY}
                >
                  {chatRoom?.unreadCount > 0 && (
                    <StatusDot statusClass={BS_STYLE.DANGER} />
                  )}
                  Message Now
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <ModalSimple
        body={<MarkdownText withBreaksPlugin text={description} />}
        heading="Description"
        noFooter
        onClose={() => setShow(false)}
        open={show}
        size={MODAL_SIZES.LARGE}
      />
    </>
  );
};

FreelancerDetailsCard.propTypes = {
  address: PropTypes.string,
  avatar: PropTypes.string,
  chatRoom: PropTypes.object,
  countryCode: PropTypes.string,
  description: PropTypes.string,
  fullName: PropTypes.string,
  handleChat: PropTypes.func.isRequired,
  handleToggleFavorite: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool,
  isFavoriteLoading: PropTypes.bool.isRequired,
  jobTitle: PropTypes.string,
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rateUnit: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FreelancerDetailsCard.defaultProps = {
  address: '',
  avatar: '',
  chatRoom: null,
  countryCode: '',
  description: '',
  fullName: '',
  isFavorite: false,
  jobTitle: '',
  rate: 0,
  rateUnit: '',
  rating: 0,
  reviews: 0,
};


const FreelancerDetailsCardWithTDApi = withTDApiConnected({
  fetchData: ({
    authedAxios,
    componentName,
    dispatch,
    params,
    querystring,
    url,
  }) => dispatch(
    fetchViewDS({
      authedAxios,
      componentName,
      querystring,
      url: discoverTalentDetailApiUrl(params, url),
    }),
  ),
  duck: 'view',
  loadingEnabled: false,
  shouldRefetchOnQueryChange: false,
  storeKey: 'DiscoverPeopleDetailView',
  skeletonComponent: TalentDetailsSkeleton,
})(FreelancerDetailsCard);

export default FreelancerDetailsCardWithTDApi;
