import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

class OverlayBody extends React.PureComponent {
  render() {
    const { body, className, onClose, ...props } = this.props;
    return (
      <div
        {...omit(props, ['arrowProps', 'outOfBoundaries', 'scheduleUpdate'])}
        className={`${className} dropdown-menu`}
        onClick={onClose}
      >
        {body}
      </div>
    );
  }
}

OverlayBody.propTypes = {
  onClose: PropTypes.func.isRequired,
  body: PropTypes.any.isRequired,
  className: PropTypes.string,
};

OverlayBody.defaultProps = {
  className: '',
};

export default OverlayBody;
