import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';

import { DOCUMENT_GET_ELEMENT_BY_ID, WINDOW } from 'core/assets/js/config/settings';
import { DOCUMENT_ESIGN_EVENTS } from 'documents/assets/js/constants';

const DocumentFormEsignEdit = () => {
  const { values: { embedUrl } } = useFormState();

  const messageHandler = ev => {
    let buttonId = null;
    switch (ev.data) {
      case DOCUMENT_ESIGN_EVENTS.CANCEL:
        buttonId = 'wizard-button-cancel';
        break;
      case DOCUMENT_ESIGN_EVENTS.BACK:
        buttonId = 'wizard-button-back';
        break;
      case DOCUMENT_ESIGN_EVENTS.SAVE_DRAFT:
        buttonId = 'wizard-button-secondary-submit';
        break;
      case DOCUMENT_ESIGN_EVENTS.PUBLISH:
        buttonId = 'wizard-button-submit';
        break;
      default:
    }
    if (buttonId) {
      DOCUMENT_GET_ELEMENT_BY_ID(buttonId).click();
    }
  };

  useEffect(() => {
    WINDOW.addEventListener('message', messageHandler);
    return () => WINDOW.removeEventListener('message', messageHandler);
  }, []);

  return (
    <iframe className="document-form-esign-iframe" src={embedUrl} title="Edit your contract template" />
  );
};

export default DocumentFormEsignEdit;
