import { FORM_ERROR } from 'final-form';
import { upperFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { projectViewUrl } from 'projects/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { orgPeopleListApiUrl } from 'people/urls';
import ProviderCard from 'people/assets/js/components/ProviderCard.jsx';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import { BS_STYLE, IMG_SIZE, PROJECT_TABS, USER_CARD_STATUS } from 'core/assets/js/constants';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { fetchListDS, getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import ProjectAddMembersForm, {
  FORM_ID as PROJECT_ADD_MEMBER_FORM_ID,
} from 'projects/assets/js/components/ProjectAddMembersForm.jsx';
import {
  ADD_TO_PROJECT_TYPE, INVITATION_NEVER_EXPIRE_VALUE,
} from 'invitations/assets/js/constants';
import { projectAddManagersDS, projectAddProvidersDS } from 'projects/assets/js/data-services/form';
import { pluralize } from 'core/assets/js/lib/utils';
import { DISPATCH_EVENT_ON_ELEMENT, DOCUMENT_GET_ELEMENT_BY_ID } from 'core/assets/js/config/settings';
import { parseSearchableFieldsInQuery } from 'interviews/assets/js/lib/utils';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import AddProvidersSkillsQuickFilter from 'projects/assets/js/components/AddProvidersSkillsQuickFilter.jsx';

export const MODAL_ID = 'project-add-member-modal';

const STEPS = {
  SELECT_MEMBERS: 1,
  CONFIRM_SELECTION: 2,
};

class ProjectAddMembersPanel extends Component {
  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    const apiUrl = orgPeopleListApiUrl(params, url);
    const prerequisites = [
      dispatch(fetchListDS({
        url: apiUrl,
        querystring,
        componentName,
        authedAxios,
      })),
    ];

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'ProjectAddMembersPanel';
  }

  constructor(props) {
    super(props);
    const { addMembersModalPayload } = props;
    const { projectId } = addMembersModalPayload;

    this.props = props;
    this.constantFilters = {
      excludeMembersOfProject: projectId,
      status: USER_CARD_STATUS.APPROVED,
      useFallback: true, // THIS IS TEMPORARY WHILST WE INVESTIGATE ES INDEXING ISSUES
    };
    this.state = {
      addToProjectType: ADD_TO_PROJECT_TYPE.INVITATION,
      currentStep: STEPS.SELECT_MEMBERS,
      query: { ...this.constantFilters },
      showSelectedItems: false,
      submitting: false,
    };

    this.handleShowSelectedItemsToggle = this.handleShowSelectedItemsToggle.bind(this);
    this.handleClosePanel = this.handleClosePanel.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.onAddToProjectTypeChange = this.onAddToProjectTypeChange.bind(this);
    this.getTypeName = this.getTypeName.bind(this);
    this.peopleSearchFormRef = React.createRef(null);
  }

  componentDidUpdate(prevProps) {
    const { isAddMembersModalOpen } = this.props;
    if (!prevProps.isAddMembersModalOpen && isAddMembersModalOpen) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      this.peopleSearchFormRef.current = null;
    }
  }

  onAddToProjectTypeChange(addToProjectType) {
    this.setState({ addToProjectType });
  }

  getTypeName() {
    const { addMembersModalPayload, selectedItems } = this.props;
    const { userType } = addMembersModalPayload;
    const multipleSelected = selectedItems.length > 1;
    // Remove 's' if only one
    return multipleSelected ? userType : userType.substring(0, userType.length - 1);
  }

  handleSendInvitation() {
    DISPATCH_EVENT_ON_ELEMENT(
      DOCUMENT_GET_ELEMENT_BY_ID(PROJECT_ADD_MEMBER_FORM_ID),
      'submit',
      { cancelable: true },
    );
  }

  handleClosePanel() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(MODAL_ID));
  }

  handleQueryUpdate(newQuery) {
    const { pagination } = this.props;
    this.setState({
      query: {
        ...this.constantFilters, ...parseSearchableFieldsInQuery(newQuery), page: pagination.page,
      },
    });
  }

  handlePageChange(newPagination) {
    const { query } = this.state;
    const newQuery = { ...this.constantFilters, ...query, ...newPagination };
    this.setState(
      { query: newQuery },
    );
  }

  handleShowSelectedItemsToggle() {
    const { showSelectedItems } = this.state;
    this.setState({ showSelectedItems: !showSelectedItems });
  }

  async _onSubmit(values) {
    const { addMembersModalPayload, history } = this.props;
    const { userType, orgAlias, projectId } = addMembersModalPayload;

    const neverExpire = parseInt(values.expiration_days, 10) === INVITATION_NEVER_EXPIRE_VALUE;

    const finalValues = {
      ...values,
      expiration_days: neverExpire ? null : values.expiration_days,
    };

    this.setState({ submitting: true });

    const reqDS = userType === PEOPLE_TYPE.PROVIDERS
      ? projectAddProvidersDS
      : projectAddManagersDS;

    try {
      await reqDS(orgAlias, projectId, finalValues);

      history.push({
        pathname: projectViewUrl(orgAlias, projectId, PROJECT_TABS.TEAM),
        search: `?dt=${moment().unix()}`, // Trigger list reload
      });

      this.handleClosePanel();

      const invitingUsers = finalValues.add_to_project_type === ADD_TO_PROJECT_TYPE.INVITATION;
      const usersCount = finalValues.users.split(',').length;

      toastr.success(
        'Well Done!',
        invitingUsers
          ? `${pluralize('Invitation', usersCount)} sent successfully.`
          : `${pluralize(upperFirst(this.getTypeName()), usersCount)} added successfully.`,
      );

      return true;
    } catch (error) {
      return {
        [FORM_ERROR]: error.response?.data?._error ? error.response.data._error : error.message,
      };
    } finally {
      this.setState({ submitting: false });
    }
  }

  render() {
    const {
      addMembersModalPayload,
      customFieldFilters,
      filtersOpen,
      isAddMembersModalOpen,
      onFiltersToggle,
      people,
      project,
      rateLimits,
      selectedItems,
      userGroupNames,
    } = this.props;
    const { addToProjectType, currentStep, query, showSelectedItems, submitting } = this.state;

    if (!addMembersModalPayload) {
      return null;
    }

    const { userType, orgAlias, projectId } = addMembersModalPayload;
    const userGroupNamesFormatted = userGroupNames.map(ugn => ({
      value: ugn.id.toString(), text: ugn.name,
    }));
    const availabilityFilter = query.availability ? JSON.parse(query.availability) : null;
    const selectedItemsLength = selectedItems.length;
    const noneSelected = selectedItemsLength === 0;

    const typeName = this.getTypeName();

    let submitButtonLabel = `Send ${pluralize('invitation', selectedItemsLength)}`;
    if (addToProjectType === ADD_TO_PROJECT_TYPE.ADD) {
      submitButtonLabel = `Add ${typeName}`;
    }

    return (
      <ModalPanel
        modalId={MODAL_ID}
        open={isAddMembersModalOpen}
        heading={<h4>{`Add ${userType}`}</h4>}
        body={(
          <React.Fragment>
            {/* Member selection Step */}
            <SelectableListWrapper
              className={currentStep === STEPS.CONFIRM_SELECTION ? 'd-none' : null}
              componentName={ProjectAddMembersPanel.GetComponentName()}
              fetchData={
                ({ params, ...rest }) => ProjectAddMembersPanel.FetchData({
                  ...rest, params: { ...params, peopleType: userType },
                })
              }
              skeletonComponent={PeopleListSkeleton}
              query={query}
              filtersOpen={filtersOpen}
              selectModeEnabled
              showSelectedItems={showSelectedItems}
              cardItem={{
                component: userType === PEOPLE_TYPE.MANAGERS ? ManagerCard : ProviderCard,
                props: {
                  orgAlias,
                  onUpdated: () => {},
                  availabilityFilter,
                  showAvailabilityMessages: true,
                  // Open link in a new tab so that user doesn't loose selected items.
                  linkTarget: '_blank',
                  listViewComponentName: ProjectAddMembersPanel.GetComponentName(),
                },
              }}
              onPageChange={this.handlePageChange}
              emptyListMessage={`No ${userType} found.`}
              emptySelectedListMessage={(
                <p className="discreet">
                  {`There are currently no selected ${userType}.`}
                  <br />
                  <span
                    className="imitate-link"
                    onClick={this.handleShowSelectedItemsToggle}
                  >
                    {`Find ${userType}`}
                  </span>
                  .
                </p>
              )}
              searchComponent={(
                <PeopleSearch
                  customFieldFilters={customFieldFilters}
                  extraSearchRow={userType === PEOPLE_TYPE.PROVIDERS && (
                    <AddProvidersSkillsQuickFilter
                      formRef={this.peopleSearchFormRef}
                      handleQueryUpdate={this.handleQueryUpdate}
                      query={query}
                      skills={project?.skills}
                    />
                  )}
                  filtersOpen={filtersOpen}
                  hideStatus
                  isManager
                  onFiltersChanged={this.handleQueryUpdate}
                  onFiltersToggle={onFiltersToggle}
                  peopleType={userType}
                  query={query}
                  rateLimits={rateLimits}
                  searchFormRef={this.peopleSearchFormRef}
                  userGroupNames={userGroupNamesFormatted}
                />
              )}
              items={people}
            />

            {/* Confirmation Step */}
            {currentStep === STEPS.CONFIRM_SELECTION && (
              <div>
                <p data-testid="project-add-members-panel-confirmation-text">
                  {
                    [
                      `You’ve selected to invite ${selectedItemsLength} new`,
                      typeName,
                      'to your project',
                    ].join(' ')
                  }
                </p>
                <ul className="mb-4">
                  {selectedItems && selectedItems.map(
                    member => (
                      <ProfilePic
                        key={member.user.id}
                        className="mr-3 mb-3"
                        url={member.user.profile.avatar}
                        alt={member.user.profile.name}
                        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                      />
                    ),
                  )}
                </ul>

                <ProjectAddMembersForm
                  initialValues={{
                    add_to_project_type: ADD_TO_PROJECT_TYPE.INVITATION,
                    expiration_days: INVITATION_NEVER_EXPIRE_VALUE,
                    users: selectedItems.map(m => m.user.id).join(','),
                  }}
                  onAddToProjectTypeChange={this.onAddToProjectTypeChange}
                  onSubmit={this._onSubmit}
                  orgAlias={orgAlias}
                  projectId={parseInt(projectId, 10)}
                />
              </div>
            )}
          </React.Fragment>
        )}
        footer={(
          <React.Fragment>
            {currentStep === STEPS.SELECT_MEMBERS && selectedItemsLength > 0 && (
              <span className="cursor-pointer d-flex align-items-center" onClick={this.handleShowSelectedItemsToggle}>
                <TDSwitch selected={showSelectedItems} />
                <span className="ml-3">
                  { !showSelectedItems
                    ? `Show ${selectedItemsLength} selected ${typeName}`
                    : `Show all ${userType}`
                  }
                </span>
              </span>
            )}

            <div className="ml-auto">
              { currentStep === STEPS.SELECT_MEMBERS && (
                <React.Fragment>
                  <TDButton
                    label="Cancel"
                    onClick={this.handleClosePanel}
                  />

                  <TDButton
                    data-testid="project-add-members-button-next"
                    className="ml-4"
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    disabled={noneSelected}
                    label="Next"
                    onClick={() => {
                      this.setState({ currentStep: STEPS.CONFIRM_SELECTION });
                    }}
                  />
                </React.Fragment>
              )}

              { currentStep === STEPS.CONFIRM_SELECTION && (
                <React.Fragment>
                  <TDButton
                    label="Back"
                    disabled={submitting}
                    onClick={() => {
                      this.setState({ currentStep: STEPS.SELECT_MEMBERS });
                    }}
                  />

                  <TDButton
                    data-testid="project-add-members-button-send-invitation"
                    className="ml-4"
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    disabled={noneSelected || submitting}
                    label={submitButtonLabel}
                    onClick={this.handleSendInvitation}
                  />
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}
ProjectAddMembersPanel.propTypes = {
  addMembersModalPayload: PropTypes.object,
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  isAddMembersModalOpen: PropTypes.bool,
  onFiltersToggle: PropTypes.func.isRequired,
  orgAlias: PropTypes.string,
  pagination: PropTypes.object.isRequired,
  people: PropTypes.array,
  project: projectSpec.isRequired,
  rateLimits: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  userGroupNames: PropTypes.array,
};

ProjectAddMembersPanel.defaultProps = {
  addMembersModalPayload: {},
  customFieldFilters: [],
  isAddMembersModalOpen: false,
  orgAlias: '',
  people: [],
  rateLimits: {},
  userGroupNames: [],
};

const mapStateToProps = (state, props) => {
  const componentName = ProjectAddMembersPanel.GetComponentName();
  const listState = getListState(state, componentName);

  return {
    addMembersModalPayload: getModalPayload(state, MODAL_ID),
    customFieldFilters: getListStateExtras(state, componentName, 'customFieldFilters'),
    isAddMembersModalOpen: getIsModalOpen(state, MODAL_ID),
    match: props.match,
    pagination: listState.pagination,
    people: listState.items,
    rateLimits: listState.extras.rateLimits,
    searchActive: listState.search.isActive,
    selectedItems: listState.selectedItems,
    userGroupNames: listState.extras.userGroupNames,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectAddMembersPanelReduxConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectAddMembersPanel);

export default withRouter(withFilters(ProjectAddMembersPanelReduxConnect));
