import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { BS_DROPDOWN_PLACEMENT } from 'core/assets/js/constants';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';

/**
 * A Dropdown wrapper based on the bootstrap dropdown
 */

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    className="dropdown-toggle"
    ref={ref}
    onClick={onClick}
  >
    {children}
  </span>
));

CustomToggle.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};

CustomToggle.defaultProps = {
  children: false,
};

/** This component supports both oprt and children params to pass the items to show.
 *  Opts is a list of objects while children is a reactNode.
 */

const TDDropdown = ({
  alignRight,
  children,
  className,
  'data-testid': dataTestId,
  drop,
  handleToggle,
  history,
  id,
  noCaret,
  opts,
  toggle,
  viewAllLabel,
  viewAllUrl,
}) => {
  const _className = ['td-dropdown', className];
  if (noCaret) {
    _className.push('td-dropdown--no-caret');
  }
  const items = opts && opts.map((opt, index) => {
    const key = `item-${index}`;
    if (opt.isDivider) {
      return (<Dropdown.Item key={key} divider />);
    }
    if (opt.readOnly) {
      return (
        <Dropdown.Item
          className="read-only"
          key={key}
        >
          { opt.label }
        </Dropdown.Item>
      );
    }
    let { onSelect } = opt;
    if (!onSelect) {
      onSelect = () => {
        if (opt.isRedirect) {
          WINDOW_REDIRECT(opt.url);
        } else {
          history.push(opt.url);
        }
      };
    }
    return (
      <Dropdown.Item
        key={key}
        eventKey={opt.eventKey}
        onSelect={onSelect}
        className={opt.itemClassName || ''}
      >
        { opt.label }
      </Dropdown.Item>
    );
  });

  return (
    <Dropdown
      alignRight={alignRight}
      className={_className}
      data-testid={dataTestId}
      drop={drop}
      id={id}
      onToggle={handleToggle}
    >
      <Dropdown.Toggle as={CustomToggle}>
        { toggle }
      </Dropdown.Toggle>

      { children && (
        <Dropdown.Menu>
          { children }
        </Dropdown.Menu>
      )}

      { opts && (!viewAllLabel || !viewAllLabel) && (
        <Dropdown.Menu>
          { items }
        </Dropdown.Menu>
      )}

      { opts && viewAllLabel && viewAllLabel !== '' && (
        <Dropdown.Menu>
          <li>
            <ul>{ items }</ul>
          </li>

          <li>
            <Link
              to={viewAllUrl}
              className="see-all col-12"
            >
              {viewAllLabel}
            </Link>
          </li>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
TDDropdown.propTypes = {
  alignRight: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  drop: PropTypes.string,
  handleToggle: PropTypes.func,
  history: routerHistorySpec.isRequired,
  id: PropTypes.string,
  noCaret: PropTypes.bool,
  opts: PropTypes.array,
  toggle: PropTypes.node.isRequired,
  viewAllLabel: PropTypes.string,
  viewAllUrl: PropTypes.string,
};
TDDropdown.defaultProps = {
  alignRight: false,
  children: null,
  className: '',
  'data-testid': '',
  drop: BS_DROPDOWN_PLACEMENT.DOWN,
  handleToggle: () => {},
  id: null,
  noCaret: true,
  opts: null,
  viewAllLabel: null,
  viewAllUrl: null,
};

export default withRouter(TDDropdown);
