import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { getViewState, viewUpdateAC } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import IntegrationCta from 'integrations/assets/js/components/IntegrationCta.jsx';
import IntegrationDetailsTab from 'integrations/assets/js/components/IntegrationDetailsTab.jsx';
import IntegrationDetailsTabs from 'integrations/assets/js/components/IntegrationDetailsTabs.jsx';
import IntegrationSetup from 'integrations/assets/js/components/IntegrationSetup.jsx';
import {
  fetchIntegrationDS, removeIntegrationDS, runIntegrationSync, toggleIntegrationActiveDS,
} from 'integrations/assets/js/data-services/view';
import { handleIncompleteMappingError } from 'integrations/assets/js/helpers';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import { orgIntegrationDetailUrl, orgIntegrationsUrl } from 'integrations/urls';

class IntegrationDetailsView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { orgAlias, integrationId } = params;

    return Promise.all([
      dispatch(fetchIntegrationDS({
        url, componentName, authedAxios, orgAlias, integrationId,
      })),
    ]);
  }

  static GetComponentName() {
    return 'IntegrationDetailsView';
  }

  constructor() {
    super();

    this.handleIntegrationActiveToggle = this.handleIntegrationActiveToggle.bind(this);
    this.handleSyncRun = this.handleSyncRun.bind(this);
  }

  async componentDidMount() {
    const {
      history, location: { search }, match: { params: { integrationId, orgAlias } },
    } = this.props;
    /*
      Codat redirects to the following URL, if connection is cancelled:
      /org-alias/integrations/123/callback?error_message=User%20cancelled.&status_code=403
      An example of an error trying to connect to Dynamics 365:
      /org-alias/integrations/123/callback?error_message=Unknown%20error%20occurred&status_code=500
    */
    const { error_message: errorMessage, status_code: statusCode } = qs.parse(search);
    if (!/^(4|5)/.test(statusCode)) {
      return;
    }
    try {
      if (errorMessage === 'User cancelled.') {
        await removeIntegrationDS({ integrationId, orgAlias });
        toastr.warning('Cancelled', 'Adding that integration has been cancelled');
      } else {
        toastr.error(
          'Oh Snap!',
          `An error occurred adding that integration: "${errorMessage}"`,
          { timeOut: 0 },
        );
      }
      history.push(orgIntegrationsUrl(orgAlias));
    } catch (error) {
      toastr.error('Oh Snap!', error._error || error.message);
    }
  }

  async handleIntegrationActiveToggle() {
    const {
      dispatch,
      integration: { id: integrationId },
      match: { params: { orgAlias } },
    } = this.props;

    try {
      const { data: updated } = await toggleIntegrationActiveDS({ orgAlias, integrationId });
      dispatch(viewUpdateAC(updated, this.constructor.GetComponentName()));

      toastr.success('Well Done!',
        updated.isActive
          ? 'The integration was activated successfully'
          : 'The integration was deactivated successfully',
      );
    } catch (err) {
      toastr.error('Oh Snap!', 'Something went wrong, please refresh and try again');
    }
  }

  async handleSyncRun() {
    const {
      dispatch,
      history,
      integration: { id: integrationId },
      match: { params: { orgAlias } },
    } = this.props;

    const response = await handleIncompleteMappingError(
      () => dispatch(runIntegrationSync({ orgAlias, integrationId })),
      orgAlias,
      integrationId,
      history,
    );
    if (response) {
      const { scheduled } = response;
      if (scheduled === 0) {
        toastr.warning('Nothing to sync!', 'There are no invoices to be synced.');
      } else {
        toastr.success('Sync started!', `Sync process initiated for ${scheduled} invoices.`);
      }
    }
  }

  render() {
    const {
      dispatch,
      integration,
      match: { params: { orgAlias, integrationId, activeTab } },
    } = this.props;

    const waitingForInitialSync = !integration?.hasCompletedInitialSync;

    const showSetup = integration?.hasCompletedInitialSync && !integration.setupCompleted;

    const breadcrumbs = [
      { title: 'Integrations', url: orgIntegrationsUrl(orgAlias) },
      {
        title: integration.name,
        url: showSetup ? null : orgIntegrationDetailUrl(orgAlias, integrationId),
      },
    ];

    if (showSetup) {
      breadcrumbs.push({ title: 'Setup', url: null });
    }

    const componentName = this.constructor.GetComponentName();

    const showDetailsTabs = !showSetup && !waitingForInitialSync;

    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
        skeletonComponent={() => null}
        /*
          When you change the IntegrationActivity tabs, the status will change in the query params.
          So we need this to prevent the whole view being re-rendered unnecessarily, which also
          causes an unmount/mount race condition with any child TDApiConnected instances
        */
        shouldRefetchOnQueryChange={false}
      >
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={(
            <IntegrationCta
              integration={integration}
              onToggle={this.handleIntegrationActiveToggle}
              onSync={this.handleSyncRun}
            />
          )}
        >
          {showDetailsTabs && (
            <IntegrationDetailsTabs
              orgAlias={orgAlias}
              integrationId={Number(integrationId)}
              tab={activeTab}
            />
          )}
        </ContentHeader>
        <div className="page">
          <div className="container">
            {waitingForInitialSync && (
              <div className="rounded shadow-sm bg-white integration--linking">
                <div className="integration--linking__details">
                  <i className={`${ICON.HOURGLASS_START} fa-6x mb-5`} />
                  <h2>{`Integration with ${integration.name} successfully connected but...`}</h2>
                  <p>
                    {`We are now fetching ${integration.name} account information. `}
                    When the integration is ready to be configured we will notify you.
                  </p>
                  <TDButton
                    className="mt-5"
                    label="Check status"
                    onClick={async () => {
                      const updatedIntegration = await dispatch(fetchIntegrationDS({
                        componentName, integrationId, orgAlias,
                      }));
                      if (updatedIntegration.hasCompletedInitialSync) {
                        toastr.success(
                          'Well Done!', 'The integration is now active and ready to be set up',
                        );
                      } else {
                        toastr.warning(
                          'Warning!', 'Getting your account information has not completed',
                        );
                      }
                    }}
                    variant={BS_STYLE.PRIMARY}
                  />
                </div>
              </div>
            )}
            {!waitingForInitialSync && (
              <>
                {showSetup && (
                  <IntegrationSetup integration={integration} parentComponentName={componentName} />
                )}
                {!showSetup && (
                  <IntegrationDetailsTab
                    integration={integration}
                    orgAlias={orgAlias}
                    parentComponentName={componentName}
                    tab={activeTab}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </TDApiConnected>
    );
  }
}

IntegrationDetailsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  integration: integrationSpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapStateToProps = (state) => {
  const { item: integration } = getViewState(state, IntegrationDetailsView.GetComponentName());

  return {
    integration,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationDetailsViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationDetailsView);

export default withRouter(IntegrationDetailsViewConnected);
