import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import pluralize from 'pluralize';

import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { CONFIRMATION_MODAL_ID } from 'settings/assets/js/components/OrgPaymentMethodsSettingsTab.jsx';

const OrgPaymentMethodCardItem = ({
  organization,
  dispatch,
  paymentMethod,
  paymentMethod: { methodId, name, description, logo, icon, isDefault },
  stats,
}) => {
  const isEnabled = organization.payment_methods.includes(methodId);

  const showConfirmationModal = (method) => {
    dispatch(modalOpenAC(CONFIRMATION_MODAL_ID, method));
  };

  const switchButton = (
    <a
      className="mr-3"
      onClick={() => {
        if (isDefault) {
          return;
        }

        showConfirmationModal(paymentMethod);
      }}
    >
      <TDSwitch
        selected={isEnabled}
        disabled={isDefault}
      />
    </a>
  );

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center">
            <div className="pr-3 d-none d-md-inline-block payment-method-card-item__logo-container">
              { logo && (
                <img width="40px" alt={`${name} logo`} src={logo} />
              )}
              { icon && (
                <i className={icon} />
              )}
            </div>
            <div>
              <h4 className="mt-0 mb-1">{name}</h4>
              <p className="py-0 pr-3 discreet">{description}</p>
            </div>

            <div className="ml-auto d-flex align-items-center">
              { isDefault ? (
                <TDElementWithTooltip
                  tooltipMsg="This is a default payment method and cannot be deselected"
                >
                  {switchButton}
                </TDElementWithTooltip>
              ) : switchButton
              }
            </div>
          </div>
        </Card.Body>

        <Card.Footer className="py-3 px-4 height-auto">
          {stats[methodId] === 0 && (
            <p className="mb-0">No provider uses this method at the moment</p>
          )}
          {stats[methodId] > 0 && (
            <p className="mb-0">{`${pluralize('provider', stats[methodId], true)} use ${name}`}</p>
          )}
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
};

OrgPaymentMethodCardItem.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  organization: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgPaymentMethodCardItemConnected = connect(
  null,
  mapDispatchToProps,
)(OrgPaymentMethodCardItem);

export default OrgPaymentMethodCardItemConnected;
