/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { PROJECT_MEMBER_STATUS, PROJECT_VISIBILITY } from 'projects/assets/js/constants';
import ProjectOwnerModal from 'projects/assets/js/components/ProjectOwnerModal.jsx';
import ProjectPaymentsReviewerModal from 'projects/assets/js/components/ProjectPaymentsReviewerModal.jsx';
import { getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import ProjectAddMembersPanel, { MODAL_ID as ADD_MEMBERS_TO_PROJECT_MODAL_ID } from 'projects/assets/js/components/ProjectAddMembersPanel.jsx';
import ScrollToTopOnMount from 'core/assets/js/components/ScrollToTopOnMount.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { getPendingInvitations } from 'core/assets/js/ducks/pendingInvitations';
import {
  BS_STYLE, PROJECT_TABS, ACCESS_CONTROL_ALLOWED_ACTIONS as ALLOWED_ACTIONS,
} from 'core/assets/js/constants';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import ProjectTabDashboard from 'projects/assets/js/components/projectTabs/ProjectTabDashboard.jsx';
import ProjectTabTeam from 'projects/assets/js/components/projectTabs/ProjectTabTeam.jsx';
import ProjectTabApplications from 'projects/assets/js/components/projectTabs/ProjectTabApplications.jsx';
import ProjectTabInvitees from 'projects/assets/js/components/projectTabs/ProjectTabInvitees.jsx';
import ProjectTabWorksheets from 'projects/assets/js/components/projectTabs/ProjectTabWorksheets.jsx';
import ProjectTabPurchaseOrders from 'projects/assets/js/components/projectTabs/ProjectTabPurchaseOrders.jsx';
import ProjectTabExpenses from 'projects/assets/js/components/projectTabs/ProjectTabExpenses.jsx';
import ProjectTabTasks from 'projects/assets/js/components/projectTabs/ProjectTabTasks.jsx';
import ProjectTabActivity from 'projects/assets/js/components/projectTabs/ProjectTabActivity.jsx';
import * as projectDS from 'projects/assets/js/data-services/view';
import ProjectTabs from 'projects/assets/js/components/ProjectTabs.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import {
  opportunityViewUrl, projectListUrl, projectOpportunitiesUrl, projectViewUrl,
} from 'projects/urls';
import { invitationManageUrl } from 'invitations/urls';
import ProjectCta from 'projects/assets/js/components/ProjectCta.jsx';
import ProjectReturnFundsModal, { MODAL_ID as PROJECT_RETURN_FUND_MODAL_ID } from 'projects/assets/js/components/ProjectReturnFundsModal.jsx';
import ProjectTitleSkeleton from 'projects/assets/js/components/skeletons/ProjectTitleSkeleton.jsx';


const ProjectTab = ({ project, tab, orgAlias, history, parentComponentName }) => {
  const viewUrl = project.visibility === PROJECT_VISIBILITY.ORG_ONLY
    ? opportunityViewUrl
    : projectViewUrl;
  switch (tab) {
    case PROJECT_TABS.TEAM:
      return (
        <ProjectTabTeam
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    case PROJECT_TABS.APPLICATIONS:
      return (
        <ProjectTabApplications
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    case PROJECT_TABS.INVITEES:
      return <ProjectTabInvitees project={project} />;
    case PROJECT_TABS.WORKSHEETS:
      return (
        <ProjectTabWorksheets
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    case PROJECT_TABS.EXPENSES:
      return (
        <ProjectTabExpenses
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    case PROJECT_TABS.PURCHASE_ORDERS:
      return (
        <ProjectTabPurchaseOrders
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    case PROJECT_TABS.DASHBOARD:
      return (
        <ProjectTabDashboard
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    case PROJECT_TABS.TASKS:
      return (
        <ProjectTabTasks
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    case PROJECT_TABS.ACTIVITY:
      return (
        <ProjectTabActivity
          project={project}
          parentComponentName={parentComponentName}
        />
      );
    default:
      history.push(viewUrl(orgAlias, project.id, PROJECT_TABS.DASHBOARD));
      return null;
  }
};

ProjectTab.propTypes = {
  project: projectSpec.isRequired,
  history: routerHistorySpec.isRequired,
  tab: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string.isRequired,
};

class ProjectView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const prerequisites = [
      dispatch(projectDS.fetchProjectActiveDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName,
      })),
      dispatch(projectDS.fetchProjectManagersDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios,
      })),
      dispatch(projectDS.fetchOutstandingCountsDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName,
      })),
    ];

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'ProjectView';
  }

  constructor(props) {
    super(props);
    this.handleProviderInvitationUpdated = this.handleProviderInvitationUpdated.bind(this);
  }

  handleProviderInvitationUpdated() {
    const { dispatch, match: { params } } = this.props;
    dispatch(projectDS.fetchProjectProvidersDS({
      orgAlias: params.orgAlias, id: params.id,
    }));
  }

  render() {
    const {
      project, match: { params, url: currentUrl }, history, allowedActions,
      outstandingCounts, isAddMembersModalOpen,
      isProjectLoading, isReturnFundModalOpen,
      managers, projectInvitations,
    } = this.props;
    const { orgAlias, id: projectId, tab } = params;
    if (!isEmpty(projectInvitations) && !isEmpty(projectInvitations[projectId])) {
      const [invitation] = projectInvitations[projectId];

      if (invitation && invitation.token) {
        return (
          <RedirectRoute
            status={302}
            from={currentUrl}
            to={invitationManageUrl(invitation.token)}
            next={currentUrl}
          />
        );
      }
    }

    const isOpportunity = project.visibility === PROJECT_VISIBILITY.ORG_ONLY;
    const viewUrl = isOpportunity ? opportunityViewUrl : projectViewUrl;

    if (!tab || !Object.values(PROJECT_TABS).includes(tab)) {
      return (
        <RedirectRoute
          status={301}
          from={currentUrl}
          to={viewUrl(orgAlias, projectId, PROJECT_TABS.DASHBOARD)}
        />
      );
    }

    const breadcrumbs = [
      {
        title: isOpportunity ? 'Opportunities' : 'Projects',
        url: (isOpportunity ? projectOpportunitiesUrl : projectListUrl)(params.orgAlias),
      },
      {
        title: !isEmpty(project) ? project.title : <ProjectTitleSkeleton />,
        url: null,
      },
    ];

    const ctaButton = (
      <ProjectCta
        componentName={this.constructor.GetComponentName()}
        currentTab={tab}
        expenseAllowedActions={allowedActions[ALLOWED_ACTIONS.EXPENSE]}
        pendingExpenses={outstandingCounts.expenses}
        pendingPurchaseOrders={outstandingCounts.purchaseOrders}
        pendingApplications={outstandingCounts.projectApplications}
        pendingTasks={outstandingCounts.tasks}
        pendingWorksheets={outstandingCounts.worksheets}
        project={project}
        projectAllowedActions={allowedActions[ALLOWED_ACTIONS.PROJECT]}
        taskAllowedActions={allowedActions[ALLOWED_ACTIONS.TASK]}
        worksheetAllowedActions={allowedActions[ALLOWED_ACTIONS.WORKSHEET]}
      />
    );

    // Potential owners for the project, in case we need to transfer ownership
    const potentialOwners = managers.filter(pm => (
      pm.status === PROJECT_MEMBER_STATUS.ACCEPTED
    )).map(pm => pm.userCard.user);

    return (
      <React.Fragment>
        <ScrollToTopOnMount />

        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={ctaButton}
        >
          { !isEmpty(project) && (
            <ProjectTabs
              isOpportunity={isOpportunity}
              projectAllowedActions={allowedActions[ALLOWED_ACTIONS.PROJECT]}
              outstandingCounts={outstandingCounts}
            />
          )}
        </ContentHeader>

        <div className="page page--project">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              shouldRefetchOnQueryChange={false}
            >
              {isEmpty(project) && (
                <TDSystemMessage
                  type={BS_STYLE.WARNING}
                  title="Access denied"
                >
                  <p>
                    {'You don\'t have access to this Project.'}
                  </p>
                </TDSystemMessage>
              )}
            </TDApiConnected>

            {/* Render active Tab Content */}
            {!(!isProjectLoading && isEmpty(project)) && (
              <ProjectTab
                project={project}
                tab={tab}
                history={history}
                orgAlias={orgAlias}
                parentComponentName={this.constructor.GetComponentName()}
              />
            )}

            { isAddMembersModalOpen && (
              <ProjectAddMembersPanel project={project} />
            )}

            { isReturnFundModalOpen && (
              <ProjectReturnFundsModal
                project={project}
                componentName={this.constructor.GetComponentName()}
              />
            )}

          </div>
        </div>

        {!isProjectLoading && !isEmpty(project) && (
          <>
            <ProjectOwnerModal
              managers={potentialOwners}
              parentComponentName={this.constructor.GetComponentName()}
              project={project}
            />
            <ProjectPaymentsReviewerModal
              managers={managers}
              parentComponentName={this.constructor.GetComponentName()}
              project={project}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

ProjectView.propTypes = {
  allowedActions: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  managers: PropTypes.arrayOf(userCardSpec),
  isAddMembersModalOpen: PropTypes.bool.isRequired,
  isProjectLoading: PropTypes.bool.isRequired,
  isReturnFundModalOpen: PropTypes.bool.isRequired,
  match: routerMatchContentsSpec.isRequired,
  outstandingCounts: PropTypes.object,
  project: projectSpec.isRequired,
  projectInvitations: PropTypes.object,
};

ProjectView.defaultProps = {
  outstandingCounts: {},
  managers: [],
  projectInvitations: {},
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, ProjectView.GetComponentName());

  return {
    allowedActions: getViewStateExtras(state, ProjectView.GetComponentName(), 'allowedActions'),
    isAddMembersModalOpen: getIsModalOpen(state, ADD_MEMBERS_TO_PROJECT_MODAL_ID),
    isProjectLoading: viewState.isLoading,
    isReturnFundModalOpen: getIsModalOpen(state, PROJECT_RETURN_FUND_MODAL_ID),
    managers: state.projects.projectActive.managers,
    outstandingCounts: state.projects.projectActive.outstandingCounts,
    projectInvitations: getPendingInvitations(state, 'project'),
    project: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectView);

export default withRouter(ProjectViewConnected);
