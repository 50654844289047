import React from 'react';
import { useFormState } from 'react-final-form';
import { connect } from 'react-redux';

import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import { DOCUMENT_TYPE } from 'documents/assets/js/constants';
import { documentsUploaderPath } from 'documents/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const DocumentFormDocument = ({ activeOrg }) => {
  const { values } = useFormState();
  const type = parseInt(values.type, 10);
  return (
    <>
      {type === DOCUMENT_TYPE.CONTENT && (
        <TextAreaField className="document-form-content" name="content" richTextEditorEnabled />
      )}
      {type === DOCUMENT_TYPE.FILE && (
        <FileUploaderField
          acceptFiles={['application/pdf']}
          className="document-form-file-uploader"
          label="Upload contract template in PDF format"
          maxFiles={1}
          name="files"
          path={documentsUploaderPath(activeOrg.id)}
          required
        />
      )}
    </>
  );
};

DocumentFormDocument.GetComponentName = () => 'DocumentFormDocument';

DocumentFormDocument.propTypes = {
  activeOrg: orgSpec.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const DocumentFormDocumentConnected = connect(
  mapStateToProps,
)(DocumentFormDocument);

export default DocumentFormDocumentConnected;
