import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { BOOTSTRAP_BREAKPOINTS, PROJECT_TABS } from 'core/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import WorksheetFormRenderer from 'projects/assets/js/components/WorksheetFormRenderer.jsx';
import { isSSR } from 'core/assets/js/config/checks';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { projectViewUrl } from 'projects/urls';
import { exchangeRatesSpec, exchangeRatesServiceSpec } from 'finance/assets/js/lib/objectSpecs';
import { WINDOW_ADD_EVENT_LISTENER, WINDOW_INNER_WIDTH, WINDOW_REMOVE_EVENT_LISTENER } from 'core/assets/js/config/settings';

export const FORM_NAME = 'project-worksheet';

class WorksheetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false,
    };

    this.checkWindowDimensions = this.checkWindowDimensions.bind(this);
    this.cancelWorksheet = this.cancelWorksheet.bind(this);
  }

  componentDidMount() {
    this.checkWindowDimensions();

    if (!isSSR) {
      WINDOW_ADD_EVENT_LISTENER('resize', this.checkWindowDimensions);
    }
  }

  componentWillUnmount() {
    if (!isSSR) {
      WINDOW_REMOVE_EVENT_LISTENER('resize', this.checkWindowDimensions);
    }
  }

  cancelWorksheet() {
    const { history, backUrl, project, orgAlias } = this.props;
    history.push({
      pathname: backUrl || projectViewUrl(
        orgAlias, project.id, PROJECT_TABS.WORKSHEETS,
      ),
      state: { isCancel: true },
    });
  }

  checkWindowDimensions() {
    const isMobileView = WINDOW_INNER_WIDTH < BOOTSTRAP_BREAKPOINTS.MEDIUM;
    this.setState({ isMobileView });
  }

  render() {
    const { isMobileView } = this.state;
    const {
      exchangeRates,
      exchangeRatesService,
      exchangeRateUpdatedAt,
      info,
      initialValues,
      onSubmit,
      organization,
      profile,
      project,
      rates,
      worksheet,
    } = this.props;
    return (
      <Form
        exchangeRates={exchangeRates}
        exchangeRatesService={exchangeRatesService}
        exchangeRateUpdatedAt={exchangeRateUpdatedAt}
        isMobileView={isMobileView}
        cancelWorksheet={this.cancelWorksheet}
        info={info}
        project={project}
        organization={organization}
        rates={rates}
        profile={profile}
        worksheet={worksheet}
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={WorksheetFormRenderer}
        keepDirtyOnReinitialize
        initialValuesEqual={() => true}
      />
    );
  }
}

WorksheetForm.propTypes = {
  exchangeRates: exchangeRatesSpec,
  exchangeRatesService: exchangeRatesServiceSpec,
  exchangeRateUpdatedAt: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  backUrl: PropTypes.string,
  orgAlias: PropTypes.string.isRequired,
  project: projectSpec.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  info: PropTypes.object,
  organization: orgSpec,
  worksheet: PropTypes.object,
  initialValues: PropTypes.object,
  history: routerHistorySpec.isRequired,
  profile: PropTypes.object.isRequired,
};

WorksheetForm.defaultProps = {
  backUrl: null,
  exchangeRates: null,
  exchangeRatesService: null,
  exchangeRateUpdatedAt: null,
  info: null,
  organization: null,
  rates: [],
  worksheet: null,
  initialValues: {},
};

const mapStateToProps = state => ({
  organization: selectActiveOrg(state),
  profile: selectProfile(state),
});

const WorksheetFormReduxConnected = connect(
  mapStateToProps,
)(WorksheetForm);

export default withRouter(WorksheetFormReduxConnected);
