import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { calculateAccountCompletePercentage } from 'accounts/assets/js/lib/helpers';

import OnboardingProgressBar from 'core/assets/js/components/OnboardingProgressBar.jsx';

const AccountProgressBar = ({ activeOrg, barClassName, className, label, steps }) => (
  <OnboardingProgressBar
    barClassName={barClassName}
    className={className}
    label={label}
    percentage={calculateAccountCompletePercentage(steps, activeOrg.alias)}
    alignRight
  />
);

AccountProgressBar.GetComponentName = () => 'AccountProgressBar';

AccountProgressBar.propTypes = {
  activeOrg: orgSpec.isRequired,
  barClassName: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  steps: PropTypes.object.isRequired,
};

AccountProgressBar.defaultProps = {
  barClassName: '',
  className: '',
  label: 'Progress',
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  steps: selectSetupSteps(state),
});

const AccountProgressBarConnected = connect(mapStateToProps)(AccountProgressBar);

export default AccountProgressBarConnected;
