import { selectUserCards, selectActiveUserCard, getOrganizationsInitialState } from 'organizations/assets/js/reducers/organizations';
import AccessControl from 'core/assets/js/lib/AccessControl';
import { createSelector } from 'reselect';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const SETUP_STEPS_FETCH = 'SETUP_STEPS_FETCH';
export const AUTH_PROFILE_UPDATE = 'AUTH_PROFILE_UPDATE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const INITIAL_STATE = { authenticated: false, token: null, profile: null };

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        token: action.payload.authToken,
        profile: action.payload.userProfile,
      };
    case SETUP_STEPS_FETCH:
      return {
        ...state,
        steps: action.payload.steps,
      };
    case AUTH_PROFILE_UPDATE:
      return {
        ...state,
        profile: action.payload.userProfile,
      };
    case LOGOUT_SUCCESS:
      return { ...state, authenticated: false, token: null, profile: null };
    default:
      return state;
  }
}

export const selectProfile = state => (
  state.auth ? state.auth.profile : null
);

export const selectAuthenticated = state => (
  state.auth ? state.auth.authenticated : false
);

/**
 * Return a helper function to find out the user's access to the selected organization
 *
 * @param state
 * @returns {*}
 */

export const getHasOrgAccess = createSelector(
  [selectActiveUserCard, selectAuthenticated],
  (roleInOrg, isAuthenticated) => (
    requirements => AccessControl.hasAccess(roleInOrg, isAuthenticated, requirements)
  ),
);

export const getDetermineHomeUrl = createSelector(
  [selectAuthenticated, selectProfile],
  (isAuthenticated, profile) => (
    ({ fromUrl, forceLogout }) => AccessControl.determineHomeUrl(
      { isAuthenticated, profile }, { fromUrl, forceLogout },
    )
  ),
);

export const getCanRenderView = createSelector(
  [selectAuthenticated, selectActiveUserCard, selectUserCards],
  (isAuthenticated, userCardActive, userCards) => (
    ({ targetOrgAlias, requirements, options }) => AccessControl.decideAuthState(
      { isAuthenticated, userCardActive, userCards }, { targetOrgAlias, requirements }, options,
    )
  ),
);

// action creators
export const authSuccessAC = payload => ({
  type: AUTH_SUCCESS,
  payload,
});

export const authLogoutAC = () => ({
  type: LOGOUT_SUCCESS,
});

export const authProfileUpdateAC = payload => ({
  type: AUTH_PROFILE_UPDATE,
  payload,
});

// initial state
export const getAuthedInitialState = (...args) => auth(INITIAL_STATE, authSuccessAC(...args));

export const getInitialState = (authStore) => {
  // Universal authentication
  const isAuthenticated = !!(authStore);

  const initialState = isAuthenticated
    ? {
      auth: getAuthedInitialState(authStore),
      organizations: getOrganizationsInitialState(authStore.orgs),
    }
    : {};
  return initialState;
};
