import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalOpenAC, modalCloseAC, getModalPayload, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import { routerMatchSpec, routerHistorySpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { timeoffSpec } from 'accounts/assets/js/lib/objectSpecs';
import InvitationTemplateCardItem from 'settings/assets/js/components/InvitationTemplateCardItem.jsx';
import { deleteInvitationTemplatefDS } from 'settings/assets/js/data-services/settings';
import {
  orgInvitationTemplatesApiUrl, orgInvitationTemplateCreateUrl,
} from 'settings/urls';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';


const DELETE_MODAL_ID = 'delete-confirmation-modal';
export const storeKey = 'InterviewTemplatesListView';

const fetchData = ({
  dispatch, url, authedAxios, componentName, querystring, params,
}) => dispatch(fetchListDS({
  authedAxios, componentName, querystring, params,
  url: orgInvitationTemplatesApiUrl(params.orgAlias, url),
}));

const OrgInvitationTemplatesTab = ({
  location, items, pagination, match, dispatch,
  deleteModalOpen, deleteModalPayload, history,
}) => {
  const { params: { orgAlias } } = match;

  const handleDeleteModalOpen = (id) => {
    dispatch(modalOpenAC(DELETE_MODAL_ID, { id }));
  };

  const handleDeleteModalClose = () => {
    dispatch(modalCloseAC(DELETE_MODAL_ID));
  };

  const handleDeleteTimeOff = async () => {
    try {
      dispatch(deleteInvitationTemplatefDS({
        orgAlias,
        id: deleteModalPayload.id,
      })).then(() => {
        toastr.success('Well done!', 'Invitation template period was deleted!');
        fetchData({
          dispatch,
          params: { orgAlias },
          url: '',
          authedAxios: null,
          componentName: storeKey,
          querystring: location.search,
        });
      }).catch(() => {
        toastr.error('Invitation template was not deleted!');
      });
    } catch (err) {
      toastr.error('Invitation template was not deleted!', err.message);
    } finally {
      handleDeleteModalClose();
    }
  };

  return (
    <React.Fragment>
      <div className="rounded shadow-sm p-4 bg-white">
        <TDList
          items={items}
          cardItem={{
            component: InvitationTemplateCardItem,
            history,
            props: {
              orgAlias,
              onDelete: id => handleDeleteModalOpen(id),
            },
          }}
          emptyListMessage={(
            <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
              <i className={ICON.NOTE} />
              You haven&apos;t added any templates
              <br />
              <Link to={orgInvitationTemplateCreateUrl(orgAlias)}>
                Create template
              </Link>
            </div>
          )}
          listClassName="invitation-templates-list"
        />
      </div>

      <div className="my-4">
        { pagination && pagination.end > 0 && (
          <TDPagination {...pagination} />
        )}
      </div>

      <ModalConfirm
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteTimeOff}
        open={deleteModalOpen}
        heading="Confirm deletion"
        body="Are you sure you want to delete this template?"
        confirmLabel="Delete"
        confirmStyle={BS_STYLE.DANGER}
      />

    </React.Fragment>
  );
};

OrgInvitationTemplatesTab.propTypes = {
  match: routerMatchSpec.isRequired,
  pagination: paginationSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(timeoffSpec),
  deleteModalOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
  deleteModalPayload: PropTypes.object,
  history: routerHistorySpec.isRequired,
};

OrgInvitationTemplatesTab.defaultProps = {
  items: [],
  params: {},
  deleteModalOpen: false,
  deleteModalPayload: {},
};
const mapStateToProps = state => ({
  deleteModalOpen: getIsModalOpen(state, DELETE_MODAL_ID),
  deleteModalPayload: getModalPayload(state, DELETE_MODAL_ID),
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TimeOffListConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(OrgInvitationTemplatesTab);

const ConnectedList = withTDApiConnected({
  fetchData,
  duck: 'list',
  storeKey,
  skeletonComponent: ListSkeleton,
})(TimeOffListConnected);

export default ConnectedList;
