import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import PlaygroundMenu from 'playground/assets/js/components/PlaygroundMenu.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';


const PlaygroundView = ({
  match: { params: { section } },
  playgroundName, sections, systemMessageTitle, systemMessageDescription, basePath,
}) => {
  const defaultSectionKey = Object.keys(sections)[0];
  const ActiveSection = sections[section] || sections[defaultSectionKey];

  return (
    <div className={`page--${playgroundName} my-4 container-fluid`}>
      {(systemMessageTitle && systemMessageDescription) && (
        <div className="row">
          <div className="col-12 mb-5">
            <TDSystemMessage title={systemMessageTitle}>
              {systemMessageDescription}
            </TDSystemMessage>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-sm-4 col-md-3 col-lg-2">
          <PlaygroundMenu sections={sections} basePath={basePath} />
        </div>

        <div className={`col-sm-8 col-md-9 col-lg-9 page--${playgroundName}__content`}>
          <ActiveSection />
        </div>
      </div>
    </div>
  );
};

PlaygroundView.propTypes = {
  playgroundName: PropTypes.string,
  sections: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  systemMessageTitle: PropTypes.string,
  systemMessageDescription: PropTypes.element,
  basePath: PropTypes.string,
};
PlaygroundView.defaultProps = {
  playgroundName: 'playground',
  basePath: 'playground',
  systemMessageTitle: null,
  systemMessageDescription: null,
};

export default withRouter(PlaygroundView);
