import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProjectsSettingsTab from 'settings/assets/js/components/ProjectsSettingsTab.jsx';
import { PROJECTS_SETTINGS_TABS } from 'settings/assets/js/constants';
import {
  fetchOrganizationDS, postOrganizationDS,
} from 'settings/assets/js/data-services/organizations';
import { orgSettingsProjectsUrl } from 'settings/urls';

const ProjectsSettingsView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const tab = params.tab || PROJECTS_SETTINGS_TABS.PROJECTS;

  const componentName = ProjectsSettingsView.GetComponentName();

  const { item: organization } = useSelector(state => getViewState(state, componentName));

  const handleSettingsChanged = (changes) => {
    dispatch(postOrganizationDS(organization.alias, changes, componentName));
  };

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Projects & Tasks', url: null }]}>
        <div className="horizontal-scroll">
          <TabBar
            activeKey={tab}
            tabSpec={[
              {
                key: PROJECTS_SETTINGS_TABS.PROJECTS,
                label: 'Projects',
                href: orgSettingsProjectsUrl(organization.alias, PROJECTS_SETTINGS_TABS.PROJECTS),
              },
              {
                key: PROJECTS_SETTINGS_TABS.TASKS,
                label: 'Tasks',
                href: orgSettingsProjectsUrl(organization.alias, PROJECTS_SETTINGS_TABS.TASKS),
              },
            ]}
          />
        </div>
      </ContentHeader>
      <div className="page page--settings page--projects-settings">
        <div className="container">
          <div className="rounded shadow-sm p-4 bg-white">
            <div className="page--settings__content clearfix">
              <TDApiConnected
                blockingLoading
                duck="view"
                fetchData={({ authedAxios }) => Promise.all([
                  dispatch(fetchOrganizationDS(params, '', authedAxios, componentName)),
                ])}
                skeletonComponent={SettingsPageSkeleton}
                storeKey={componentName}
              >
                <div className="settings-row">
                  <ProjectsSettingsTab
                    handleSettingsChanged={handleSettingsChanged}
                    organization={organization}
                    tab={tab}
                  />
                </div>
              </TDApiConnected>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProjectsSettingsView.GetComponentName = () => 'ProjectsSettingsView';

export default ProjectsSettingsView;
