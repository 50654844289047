import React from 'react';
import PropTypes from 'prop-types';

import { isFunction } from 'lodash';
import withComponent from 'core/assets/js/components/withComponent.jsx';
import withApi from 'core/assets/js/components/withApi.jsx';

export const withTDApiConnected = ({
  fetchData, duck, storeKey, skeletonComponent,
}) => (WrappedComponent) => {
  const Connected = withComponent(withApi(WrappedComponent));
  const _WithTDApiConnected = ({ ...props }) => {
    const _storeKey = props.storeKey || storeKey; //eslint-disable-line
    const key = isFunction(_storeKey) ? _storeKey(props) : _storeKey;
    return (
      <Connected
        {...props}
        duck={duck}
        fetchData={args => fetchData(args, props)}
        storeKey={key}
        skeletonComponent={skeletonComponent}
      />
    );
  };
  return _WithTDApiConnected;
};

const TDApiConnected = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

TDApiConnected.propTypes = {
  // Main component
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.node,
  ]),
};

TDApiConnected.defaultProps = {
  // Props coming from parent component
  children: null,
};

export default withComponent(withApi(TDApiConnected));
