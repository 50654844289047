import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Card } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import TalentWidgetSkeleton from 'core/assets/js/components/Skeleton/TalentWidgetSkeleton.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_SIZE, BS_STYLE, ICON, IMG_SIZE } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { PPHProfileSpec } from 'search/assets/js/lib/objectSpecs';
import { orgPeopleDiscoverDetailUrl, orgPeopleDiscoverUrl } from 'people/urls';
import { discoverTalentApiUrl } from 'search/urls';

const COMPONENT_NAME = 'TalentMarketWidget';

const TalentMarketWidget = ({
  dispatch,
  history,
  match: {
    params: { orgAlias },
  },
  people,
}) => {
  const reFetchTalents = () => {
    dispatch(requestRefreshAC(COMPONENT_NAME));
  };
  const headerActionBtn = {
    label: 'Explore talent',
    onClick: () => history.push(orgPeopleDiscoverUrl(orgAlias)),
    variant: BS_STYLE.PRIMARY,
  };

  const footerActionBtn = {
    label: 'View TalentMarket',
    onClick: () => history.push(orgPeopleDiscoverUrl(orgAlias)),
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: 'Unable to find talents',
  };

  const widgetlTitle = 'Explore TalentMarket';
  return (
    <WidgetWrapper
      customHeaderActions={(
        <div className="ml-auto d-flex">
          <TDButton
            bsSize={BS_SIZE.SMALL}
            btnIcon={ICON.ARROWS_ROTATE}
            className="freelancer-widget__refresh"
            onClick={reFetchTalents}
            variant={BS_STYLE.DEFAULT}
          />
          <TDButton
            label={headerActionBtn.label}
            onClick={headerActionBtn.onClick}
            variant={headerActionBtn.variant || BS_STYLE.DEFAULT}
          />
        </div>
      )}
      footerActionBtn={footerActionBtn}
      headerExtraClasses="d-flex align-items-center freelancer-widget__title"
      isEmpty={isEmpty(people)}
      placeholder={emptyPlaceholder}
      popOverContent={(
        <p>
          We offer a curated pool of only the best freelancers. Visit TalentMarket and
          search for the skill needed and view their profile.
          If it’s a match you can invite them to your organization as a provider or a manager.
        </p>
      )}
      popOverTitle={widgetlTitle}
      title={widgetlTitle}
    >
      <div className="row ml-0">
        {people?.map(({ budget, id, numReviews, profile, rating, userCard }) => (
          <div className="col-6 col-lg-3 pl-0 mb-3" key={`${id}--${profile.firstName}`}>
            <Card
              className="user-item cursor-pointer"
              onClick={() =>
                history.push(orgPeopleDiscoverDetailUrl(orgAlias, id))
              }
            >
              <Card.Body className="pb-0">
                <div className="row">
                  <div className="col-12 col-md-3 col-lg-4 freelancer-widget__profile-image">
                    <ProfilePic
                      alt={`${profile.firstName}'s avatar`}
                      className="mr-4"
                      size={[IMG_SIZE.SMEDIUM, IMG_SIZE.SMEDIUM]}
                      url={profile.avatar}
                    />
                  </div>
                  <div className="col-12 col-md-9 col-lg-8 freelancer-widget__profile-content">
                    <div className="freelancer-item__title text-left">
                      {`${profile.fullName} `}
                      <CountryFlagIcon
                        countryISOCode={profile?.address?.countryCode}
                      />
                    </div>
                    {profile.jobTitle && (
                      <div className="freelancer-widget__job">
                        {profile.jobTitle}
                      </div>
                    )}
                    <div className="mt-2">
                      <SkillList
                        inline
                        maxShownItems={2}
                        modalId={`user-skills-${profile.userId}`}
                        skills={profile?.skills}
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className="freelancer-item__footer">
                <div className="row">
                  <div className="col-12 col-md-6 pr-0">
                    <RatingScore
                      classNames="freelancer-item__rating"
                      numReviews={numReviews}
                      rating={rating}
                    />
                  </div>
                  <div className="col-12 col-md-6 text-right pl-0">
                    {!isNil(userCard.currencySymbol && budget) && budget > 0 && (
                      <span className="freelancer-item__budget">
                        <i className={ICON.COINS} />
                        <NumberFormat
                          displayType="text"
                          key="budget"
                          prefix={userCard.currencySymbol}
                          thousandSeparator
                          value={budget}
                        />
                      </span>
                    )}

                    {userCard.currencySymbol && userCard.rate && (
                      <StatusTag
                        hideTitle
                        hideDot
                        label={(
                          <span>
                            {`${userCard.currencySymbol}${userCard.rate} /hr`}
                          </span>
                        )}
                        statusClass="light"
                      />
                    )}
                  </div>
                </div>
              </Card.Footer>
            </Card>
          </div>
        ))}
      </div>
    </WidgetWrapper>
  );
};

TalentMarketWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: routerMatchSpec.isRequired,
  people: PropTypes.arrayOf(PPHProfileSpec),
};

TalentMarketWidget.defaultProps = {
  people: [],
};

const TalentMarketWidgetWithTDApi = withTDApiConnected({
  fetchData: ({ authedAxios, componentName, dispatch, params, url }) => {
    const apiUrl = discoverTalentApiUrl(params, url);
    const querystring = {
      pageSize: 8,
      pageNumber: Math.floor(Math.random() * 26),
    };
    return dispatch(
      fetchListDS({
        authedAxios,
        componentName,
        querystring: queryString.stringify(querystring),
        url: apiUrl,
      }),
    );
  },
  duck: 'list',
  loadingEnabled: false,
  skeletonComponent: TalentWidgetSkeleton,
  storeKey: COMPONENT_NAME,
})(TalentMarketWidget);

const mapStateToProps = (state, props) => {
  const listState = getListState(state, COMPONENT_NAME);
  return {
    currentUser: state.organizations.active,
    match: props.match,
    pagination: listState.pagination,
    people: listState.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const TalentMarketWidgetConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TalentMarketWidgetWithTDApi);

export default withRouter(withFilters(TalentMarketWidgetConnect));
