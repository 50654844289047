import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import {
  addBackUrlIfLocationContains, financeWorksheetViewUrl, financeWorksheetEditUrl,
} from 'finance/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const WorksheetItemActions = ({ history, orgAlias, worksheet, pathname, onCancelClick }) => {
  const showEditButton = worksheet?.allowedActions?.canBeEdited;
  const showCancelButton = worksheet?.allowedActions?.canBeCancelled;
  return (
    <TDDropButton data-testid="worksheets-table-actions" stopPropagation>
      <Dropdown.Item
        eventKey="view-worksheet"
        data-testid="view-worksheet"
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            addBackUrlIfLocationContains(
              financeWorksheetViewUrl(orgAlias, worksheet.id),
              history.location.pathname,
              '/projects/',
            ),
          );
        }}
      >
        View worksheet
      </Dropdown.Item>

      { showEditButton && (
        <Dropdown.Item
          eventKey="edit-worksheet"
          data-testid="edit-worksheet"
          onClick={(e) => {
            e.stopPropagation();
            history.push(financeWorksheetEditUrl(orgAlias, worksheet.id, pathname));
          }}
        >
          <span>Edit worksheet</span>
        </Dropdown.Item>
      )}

      { showCancelButton && (
        <Dropdown.Item
          eventKey="cancel-worksheet"
          data-testid="cancel-worksheet"
          onClick={onCancelClick}
        >
          <span className="text-secondary">Cancel worksheet</span>
        </Dropdown.Item>
      )}
    </TDDropButton>
  );
};

WorksheetItemActions.propTypes = {
  history: routerHistorySpec.isRequired,
  worksheet: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
};

WorksheetItemActions.defaultProps = {
  pathname: null,
};

export default WorksheetItemActions;
