import React from 'react';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';

const StartProjectAction = () => (
  <TDSystemMessage
    title="The project hasn’t started yet"
    type={BS_STYLE.WARNING}
    className="mt-4"
  >
    <p>
      You can’t assign a provider to this task because the project hasn’t started yet.
    </p>
    <p>
      Please start the project first in order to be able to assign providers
    </p>
  </TDSystemMessage>
);

export default StartProjectAction;
