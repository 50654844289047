import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { outstandingCountSpec } from 'finance/assets/js/lib/objectSpecs';
import { opportunityViewUrl, projectViewUrl } from 'projects/urls';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { PROJECT_TABS } from 'core/assets/js/constants';
import { projectAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';


const ProjectTabs = ({
  hasOrgAccess, isOpportunity, match, outstandingCounts, projectAllowedActions,
}) => {
  const { purchaseOrders, worksheets, expenses, tasks, projectApplications } = outstandingCounts;
  const { params } = match;
  const { orgAlias, id, tab } = params;
  const isManager = hasOrgAccess({ requireManager: true });

  const viewUrl = isOpportunity ? opportunityViewUrl : projectViewUrl;

  const tabSpec = [{
    key: 'dashboard',
    label: 'Dashboard',
    href: viewUrl(orgAlias, id, PROJECT_TABS.DASHBOARD),
  }];

  if (projectAllowedActions?.canViewOpportunityInvitees) {
    tabSpec.push({
      key: 'invitees',
      label: 'Invitees',
      href: viewUrl(orgAlias, id, PROJECT_TABS.INVITEES),
    });
  }

  if (isManager && isOpportunity) {
    tabSpec.push({
      href: viewUrl(orgAlias, id, PROJECT_TABS.APPLICATIONS),
      key: 'applications',
      label: 'Applicants',
      outstandingCount: projectApplications,
    });
  }

  if (projectAllowedActions?.canViewProjectMember) {
    tabSpec.push({
      key: 'team',
      label: 'Team',
      href: viewUrl(orgAlias, id, PROJECT_TABS.TEAM),
    });
  }

  tabSpec.push({
    href: viewUrl(orgAlias, id, PROJECT_TABS.TASKS),
    key: 'tasks',
    label: 'Tasks',
    outstandingCount: tasks,
  });

  if (!isOpportunity) {
    tabSpec.push(
      {
        href: viewUrl(orgAlias, id, PROJECT_TABS.WORKSHEETS),
        key: 'worksheets',
        label: 'Worksheets',
        outstandingCount: worksheets,
      },
      {
        href: viewUrl(orgAlias, id, PROJECT_TABS.EXPENSES),
        key: 'expenses',
        label: 'Expenses',
        outstandingCount: expenses,
      },
    );
  }

  if (isManager && !isOpportunity) {
    tabSpec.push({
      key: 'purchase-orders',
      label: 'Budget requests',
      href: viewUrl(orgAlias, id, PROJECT_TABS.PURCHASE_ORDERS),
      outstandingCount: purchaseOrders,
    });
  }

  return (
    <div className="horizontal-scroll" data-testid="project-tabs">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

ProjectTabs.propTypes = {
  isOpportunity: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  outstandingCounts: outstandingCountSpec,
  projectAllowedActions: projectAllowedActionsSpec,
};

ProjectTabs.defaultProps = {
  outstandingCounts: {},
  projectAllowedActions: {},
};

const mapStateToProps = state => ({
  hasOrgAccess: getHasOrgAccess(state),
});

const ProjectTabsConnected = connect(mapStateToProps)(ProjectTabs);

export default withRouter(ProjectTabsConnected);
