import { LAYOUTS } from 'core/assets/js/constants';

// Views
import InterviewListView from 'interviews/assets/js/InterviewListView.jsx';
import SubmissionListView from 'interviews/assets/js/SubmissionListView.jsx';
import SubmissionView from 'interviews/assets/js/SubmissionView.jsx';
import AnswerSyncView from 'interviews/assets/js/AnswerSyncView.jsx';
import InterviewCreateView from 'interviews/assets/js/InterviewCreateView.jsx';
import InterviewEditView from 'interviews/assets/js/InterviewEditView.jsx';

const interviewsRouteConfig = [
  {
    path: '/:orgAlias/interviews',
    requireManager: true,
    component: InterviewListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: '/:orgAlias/submissions',
    requireManager: true,
    component: SubmissionListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: '/:orgAlias/interviews/:interviewId/submit',
    // In this particular case, we need the user to be logged in,
    // but since they don't have an approved userCard yet, we shouldn't require any role
    requireAuth: true,
    requireUserIsApprovedOrgMember: false,
    component: SubmissionView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: '/:orgAlias/submissions/:submissionId/:questionId',
    requireUserIsApprovedOrgMember: true,
    component: AnswerSyncView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
  },
  {
    path: '/:orgAlias/submissions/:submissionId',
    // In this particular case, we need the user to be logged in,
    // but since they don't have an approved userCard yet, we shouldn't require any role
    requireAuth: true,
    requireUserIsApprovedOrgMember: false,
    component: SubmissionView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: '/:orgAlias/interviews/create',
    requireManager: true,
    component: InterviewCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: '/:orgAlias/interviews/:interviewId',
    requireManager: true,
    component: InterviewEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
];

export default interviewsRouteConfig;
