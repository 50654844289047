/* globals File */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { documentFileDownloadApiUrl } from 'documents/urls';

const acceptFileTypes = '.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.png';
const acceptFileTypesLabel = `Supported file types: ${acceptFileTypes.split(',').join(', ')}`;

const DocumentFormEsignUpload = ({ isNewVersion, match: { params: { id, orgAlias } } }) => {
  const { change } = useForm();
  const [loadedExistingFile, setLoadedExistingFile] = useState(false);
  const downloadDocument = id && !isNewVersion;

  useEffect(() => {
    if (!downloadDocument) {
      return;
    }
    axios
      .get(documentFileDownloadApiUrl(orgAlias, id))
      .then(({ data, headers }) => {
        const fileName = headers['content-disposition'].split('filename="')[1].replace(/"$/, '');
        change('eSignFile', new File([data], fileName, { type: headers['content-type'] }));
        setLoadedExistingFile(true);
      })
      .catch(err => {
        if (err.response?.data?._meta?.isValidation) {
          // This contract template is being changed to esign from another contract type,
          // so no file to load
          setLoadedExistingFile(true);
          return;
        }
        toastr.error('Oh Snap!', err._error || err.message);
      });
  }, []);

  if (downloadDocument && !loadedExistingFile) {
    return 'Loading';
  }

  return (
    <FileUploaderDirectField
      accept={acceptFileTypes}
      afterChange={() => {
        if (downloadDocument) {
          change('signNowDocumentId', null);
        }
      }}
      label="Upload contract template"
      name="eSignFile"
      sublabel={acceptFileTypesLabel}
    />
  );
};

DocumentFormEsignUpload.propTypes = {
  isNewVersion: PropTypes.bool,
  match: routerMatchContentsSpec.isRequired,
};

DocumentFormEsignUpload.defaultProps = {
  isNewVersion: false,
};


export default withRouter(DocumentFormEsignUpload);
