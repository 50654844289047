import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { orderBy, groupBy, keyBy } from 'lodash';

import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { ICON, BS_STYLE, BS_SIZE, BS_TOOLTIP_PLACEMENT } from 'core/assets/js/constants';
import { modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import SkillSelectModalPanel, { MODAL_ID as SKILL_SELECT_PANEL_MODAL_ID } from 'core/assets/js/components/SkillSelectModalPanel.jsx';
import ProfileSkillSelectModal, { MODAL_ID as PROFILE_SKILL_SELECT_PANEL_MODAL_ID } from 'core/assets/js/components/ProfileSkillSelectModal.jsx';
import { updateProfileSkillsDS } from 'accounts/assets/js/data-services/profiles';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';
import RemoveSkillModal, { DELETE_SKILL_MODAL_ID } from 'accounts/assets/js/components/RemoveSkillModal.jsx';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import ProfileSettingsView from 'accounts/assets/js/ProfileSettingsView.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { selectUserCards } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { countOrgsCustomSkillsUrl } from 'skills/urls';

const SKILLS_ARE_REQUIRED_MESSAGE = 'At least one skill is required.';

class ProfileSkillsTab extends React.Component {
  static GetComponentName() {
    return 'ProfileSkillsTab';
  }

  constructor(props) {
    super(props);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleOpenRemoveConfirmationModal = this.handleOpenRemoveConfirmationModal.bind(this);
    this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
    this.handleSkillsSelected = this.handleSkillsSelected.bind(this);
    this.reFetchProfile = this.reFetchProfile.bind(this);
  }

  handleSkillsSelected(newSkills) {
    const { dispatch, editingSkills, skills } = this.props;
    const editingSkillsIds = editingSkills.map(({ id }) => id);
    const unChangedSkills = skills.filter(({
      id, isManagerEndorsed, copiedByUser,
    }) => !editingSkillsIds.includes(id) && (!isManagerEndorsed || copiedByUser));

    const payload = [...unChangedSkills, ...newSkills];
    if (payload.length === 0) {
      toastr.error('Oops!', SKILLS_ARE_REQUIRED_MESSAGE);
      return Promise.resolve();
    }
    const componentName = this.constructor.GetComponentName();
    return dispatch(
      updateProfileSkillsDS(payload, componentName),
    )
      .then(() => {
        this.reFetchProfile();
        toastr.success('Well Done!', 'Skills updated successfully.');
      });
  }

  handleModalOpen(params, modalId = SKILL_SELECT_PANEL_MODAL_ID) {
    const { dispatch } = this.props;
    const { selectionFilter, initiallySelected } = params;
    dispatch(modalOpenAC(modalId, { initiallySelected, selectionFilter }));
  }

  handleOpenRemoveConfirmationModal(skill) {
    const { dispatch, skills } = this.props;
    if (skills.length === 1) {
      toastr.error('Oops!', SKILLS_ARE_REQUIRED_MESSAGE);
    } else {
      dispatch(modalOpenAC(DELETE_SKILL_MODAL_ID, { skill }));
    }
  }

  async handleRemoveSkill(skillId) {
    const { dispatch, skills } = this.props;
    const componentName = this.constructor.GetComponentName();
    const newSkills = skills.filter(({
      id, copiedByUser, isManagerEndorsed,
    }) => id !== skillId && (copiedByUser || !isManagerEndorsed));
    return dispatch(
      updateProfileSkillsDS(newSkills, componentName),
    ).then(() => {
      this.reFetchProfile();
      dispatch(modalCloseAC(DELETE_SKILL_MODAL_ID));
      toastr.success('Well Done!', 'Skill removed successfully.');
    });
  }

  /**
   * Handle the refetching the profile
   */
  async reFetchProfile() {
    const { dispatch } = this.props;
    dispatch(requestRefreshAC(
      ProfileSettingsView.GetComponentName(PROFILE_SETTINGS_TABS.SKILLS)),
    );
  }

  render() {
    const {
      skills, isRemoveConfirmationModalOpen,
      userCards, orgCustomSkillCount,
    } = this.props;
    // separate default and custom skills
    const userDefaultSkills = skills.filter(i => (i.isSystemSkill && !i.isManagerEndorsed)
      || (i.isSystemSkill && i.isManagerEndorsed && i.copiedByUser),
    );
    const userCustomSkills = skills.filter(i => !i.isSystemSkill || i.isManagerEndorsed);
    const endorsedDefaultSkills = skills.filter(i => i.isSystemSkill && i.isManagerEndorsed);
    // group org skills by orgId
    const orgSkills = groupBy(userCustomSkills,
      ({ endorserOrganizationId, orgId }) => endorserOrganizationId || orgId,
    );

    const publicSkillsModalOpenProps = {
      initiallySelected: userDefaultSkills,
      selectionFilter: {
        endorsedDefaultSkills,
        onlySystemSkills: true,
        orgId: [],
      },
    };

    return (
      <div className="rounded shadow-sm p-4 bg-white">
        <div className="page--settings__content clearfix">
          <div className="skill-list p-0 mb-n3">
            <h3 className="heading-block has-sub m-0">Your Skills</h3>
            <h4 className="heading-sub font-weight-normal pb-3 mb-5">Visible on your profile for everyone to see.</h4>
            <div className="skill-list">
              {orderBy(userDefaultSkills, 'label').map(skill => (
                <SkillTag
                  key={skill.id}
                  skill={skill}
                  onRemove={() => this.handleOpenRemoveConfirmationModal(skill)}
                  showEndorsementIndication
                  showOrgIndication
                />
              ))}

              {userDefaultSkills?.length > 0 && (
                <div className="mt-5">
                  <TDButton
                    variant={BS_STYLE.PRIMARY}
                    onClick={() => this.handleModalOpen(
                      publicSkillsModalOpenProps,
                      PROFILE_SKILL_SELECT_PANEL_MODAL_ID,
                    )}
                    label="Add skills"
                  />
                </div>
              )}
            </div>


            {userDefaultSkills?.length === 0 && (
              <div className="discreet d-flex flex-column align-items-center justify-content-center empty-list-message p-5">
                <i className={`${ICON.PEN_RULER_DUOTONE} mb-0`} />
                <p className="my-5">
                  You haven’t added any skills yet.
                </p>

                <TDButton
                  tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
                  bsSize={BS_SIZE.SMALL}
                  onClick={() => this.handleModalOpen(
                    publicSkillsModalOpenProps,
                    PROFILE_SKILL_SELECT_PANEL_MODAL_ID,
                  )}
                  variant={BS_STYLE.PRIMARY}
                  label="Add skills"
                />
              </div>
            )}
            {userCards.map(({ organization: { id: orgId, name: orgName, alias: orgAlias } }) => {
              const orgHasCustomSkills = orgCustomSkillCount?.[orgId]
                && orgCustomSkillCount?.[orgId]?.customSkillCount > 0;

              const openManageOrgSkillsModal = () => {
                const defaultOrgSkills = orgSkills?.[orgId]?.filter(
                  ({ isSystemSkill }) => !isSystemSkill,
                );
                this.handleModalOpen({
                  selectionFilter: {
                    orgId,
                    excludeDefaultSkills: true,
                    orgName,
                    orgAlias,
                  },
                  initiallySelected: defaultOrgSkills,
                });
              };

              return (
                <div className="pt-4" key={orgId}>
                  <h3 className="heading-block has-sub mb-0 mt-5">
                    {`${orgName} Skills`}
                  </h3>
                  <h4 className="heading-sub font-weight-normal pb-3 mb-5">
                    {`${orgName} has created their own custom skills. Only visible
                      to members of the ${orgName} organisation`}
                  </h4>

                  { orgSkills?.[orgId]?.length > 0 && (
                    <div className="skill-list pb-3">
                      {orderBy(orgSkills?.[orgId], 'label')?.map(skill => (
                        <SkillTag
                          key={skill.id}
                          skill={skill}
                          onRemove={!skill.isManagerEndorsed
                            ? () => this.handleOpenRemoveConfirmationModal(skill) : false}
                        />
                      ))}

                      <div className="mt-5">
                        <TDButton
                          variant={BS_STYLE.PRIMARY}
                          onClick={openManageOrgSkillsModal}
                          label="Add skills"
                        />
                      </div>
                    </div>
                  )}

                  {!orgSkills?.[orgId] && (
                    <div className="discreet d-flex flex-column align-items-center justify-content-center empty-list-message p-5">
                      <i className={`${ICON.PEN_RULER_DUOTONE} mb-0`} />
                      <p className="my-5">
                        {`You haven’t added any custom skills to ${orgName} yet`}
                      </p>

                      <TDButton
                        disabledMsg={!orgHasCustomSkills ? `${orgName} has not created any custom skills.` : null}
                        tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
                        bsSize={BS_SIZE.SMALL}
                        onClick={openManageOrgSkillsModal}
                        variant={BS_STYLE.PRIMARY}
                        label="Add skills"
                      />
                    </div>
                  )}
                </div>
              );
            })}

            <SkillSelectModalPanel
              handleSubmitSelected={this.handleSkillsSelected}
            />

            <ProfileSkillSelectModal
              handleSubmitSelected={this.handleSkillsSelected}
            />

            {isRemoveConfirmationModalOpen && (
              <RemoveSkillModal
                onConfirm={this.handleRemoveSkill}
              />
            )}

            <hr className="mt-" />
            <ul className="bullet-list bullet-list--outside text-muted font-small mb-5">
              <li>Add skills to your profile for managers to find you and endorse you.</li>
              <li>
                Add specific skills to each organisation you are employed by that
                represent your role and abilities within each organisation.
              </li>
              <li>
                Managers can add additional skills to your profile, these
                skills are only visible to members of their organisation.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

ProfileSkillsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  skills: PropTypes.arrayOf(skillSpec),
  isRemoveConfirmationModalOpen: PropTypes.bool,
  userCards: PropTypes.arrayOf(userCardSpec).isRequired,
  editingSkills: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  orgCustomSkillCount: PropTypes.object,
};

ProfileSkillsTab.defaultProps = {
  skills: [],
  isRemoveConfirmationModalOpen: false,
  editingSkills: [],
  orgCustomSkillCount: {},
};

const ProfileSkillsTabConnected = withTDApiConnected({
  fetchData: ({ dispatch, params, authedAxios, componentName, props }) => {
    const { userCards } = props;
    const orgIds = userCards.map(({ organization: { id } }) => id);
    return dispatch(fetchListDS({
      url: countOrgsCustomSkillsUrl({ orgIds }),
      params,
      authedAxios,
      componentName,
    }));
  },
  storeKey: ProfileSkillsTab.GetComponentName(),
  duck: 'list',
})(ProfileSkillsTab);

const mapStateToProps = (state) => {
  const modalPanelPayload = getModalPayload(state, SKILL_SELECT_PANEL_MODAL_ID);
  const listState = getListState(state, ProfileSkillsTab.GetComponentName());
  const viewState = getViewState(
    state,
    ProfileSettingsView.GetComponentName(PROFILE_SETTINGS_TABS.SKILLS),
  );
  const orgCustomSkillCount = keyBy(listState.items,
    ({ organization_id: orgId }) => orgId,
  );
  return {
    skills: viewState?.item?.skills,
    isRemoveConfirmationModalOpen: getIsModalOpen(state, DELETE_SKILL_MODAL_ID),
    userCards: selectUserCards(state),
    editingSkills: modalPanelPayload?.initiallySelected,
    orgCustomSkillCount,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProfileSocialTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileSkillsTabConnected);

export default ProfileSocialTabConnected;
