import { uniq } from 'lodash';
import { TAX_CODE, VAT_PERCENT, INVOICING_MODE, INVOICE_TYPE, RAISED_BY, RAISED_FOR, RAISED_TO_INVOICE_TYPE, SYSTEM_CURRENCY, EXCHANGE_RATE_SERVICE } from 'finance/assets/js/constants';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import { calcTransactionReference } from 'finance/assets/js/lib/utils';
import InvoiceRateMap from 'finance/assets/js/lib/InvoiceRateMap';

class FinancialAssociation {
  /**
   * Calculate the VAT - code and percent - to be displayed on OUTBOUND invoices
   *
   * VAT rules depending on the client location:
   * - UK: 20% (20.0% S)
   * - SEPA & Rest of the world: 0% (0.0% Z)
   *
   * @param recipientFE
   * @returns {{taxCode: string, vatPercent: number}}
   * @private
   */
  static _calculateOutboundVat(recipientFE) {
    const userDefinedSalesTax = false;
    if (recipientFE.isInTheUK()) {
      return { taxCode: TAX_CODE.S_20, vatPercent: VAT_PERCENT.GB, userDefinedSalesTax };
    }

    return { taxCode: TAX_CODE.Z_0, vatPercent: 0, userDefinedSalesTax };
  }

  /**
   * Calculate the VAT - code and percent - to be displayed on an INBOUND CONTRACTOR invoice.
   *
   * VAT rules:
   * - 0.0% No VAT: Non VAT registered UK supplier
   * - 20.0% S VAT: UK VAT registered supplier
   * - 20% ECS VAT: Suppliers outside of the UK
   *
   * @param ownerFE
   * @returns {{taxCode: string, vatPercent: (*|number)}|{taxCode: string, vatPercent: number}}
   */
  static _calculateInboundVat(ownerFE) {
    const userDefinedSalesTax = false;
    if (ownerFE.isInTheUK()) {
      if (ownerFE.isVatRegistered()) {
        return { taxCode: TAX_CODE.S_20, vatPercent: VAT_PERCENT.GB, userDefinedSalesTax };
      }
      return { taxCode: TAX_CODE.NO_VAT, vatPercent: 0, userDefinedSalesTax };
    }

    if (ownerFE.getCountryCode() === 'IM') {
      return { taxCode: TAX_CODE.S_20, vatPercent: VAT_PERCENT.IM, userDefinedSalesTax };
    }

    return {
      taxCode: TAX_CODE.ECS_20, vatPercent: 0, userDefinedSalesTax,
    };
  }

  /**
   * Calculate the VAT - code and percent - to be displayed on a DIRECT CONTRACTOR invoice.
   *
   * VAT rules:
   * - If the client is in the UK, follow the rules for INBOUND invoices
   * - If the client is not in the UK and the contractor is in the UK:
   *     - If they are VAT registered: 0.0% Z
   *     - If they are NOT VAT registered: 0.0% No VAT
   * - Any other case: Do not use a VAT tax code and use the set Sales tax percent, or 0
   *
   * @param {FinancialEntity} ownerFE
   * @param {FinancialEntity} recipientFE
   * @return {{taxCode: (string|null), vatPercent: (*|number), userDefinedSalesTax: boolean}}
   */
  static _calculateDirectVat(ownerFE, recipientFE) {
    let userDefinedSalesTax = false;
    if (recipientFE.isInTheUK()) {
      if (ownerFE.isInTheUK()) {
        if (ownerFE.isVatRegistered()) {
          return { taxCode: TAX_CODE.S_20, vatPercent: VAT_PERCENT.GB, userDefinedSalesTax };
        }
        return { taxCode: TAX_CODE.NO_VAT, vatPercent: 0, userDefinedSalesTax };
      }

      userDefinedSalesTax = true;

      return {
        taxCode: TAX_CODE.ECS_20, vatPercent: ownerFE.getVatPercent() || 0, userDefinedSalesTax,
      };
    }

    // The contractor is in the UK
    if (ownerFE.isInTheUK()) {
      // And they are a VAT registered business
      if (ownerFE.isVatRegistered()) {
        return { taxCode: TAX_CODE.Z_0, vatPercent: 0, userDefinedSalesTax };
      }

      // And they are NOT a VAT registered business
      return { taxCode: TAX_CODE.NO_VAT, vatPercent: 0, userDefinedSalesTax };

      // Notice:
      // If the contractor would invoice a consumer (B2C) the VAT code would have been 0.0% No VAT.
      // However, we would then have to look at the rules in the individual EC countries that they
      // are charging regarding whether they should be VAT registered in that country or not.
    }

    userDefinedSalesTax = true;

    return { taxCode: null, vatPercent: ownerFE.getVatPercent() || 0, userDefinedSalesTax };
  }

  /**
   * Calculate the VAT - code and payment - to be displayed on invoices
   *
   * The VAT rules factor in the type of invoice - OUBOUND, DIRECT, INBOUND -
   * and the country at the source and destination:
   *
   * - 0.0% No VAT: Not VAT registered UK supplier
   * - 20.0% S VAT: UK VAT registered supplier
   * - 20.0% ECS: VAT Suppliers outside of the UK
   * - 0.0% ECS: Sales to companies in EU outside UK, we have a VAT number for
   *
   * @param ownerFinancialEntity
   * @param recipientFinancialEntity
   * @returns {{taxCode: string, vatPercent: (*|number)}|{taxCode: string, vatPercent: number}}
   */
  static calculateVat({ ownerFinancialEntity, recipientFinancialEntity }) {
    if (ownerFinancialEntity.isSystem()) {
      return FinancialAssociation._calculateOutboundVat(recipientFinancialEntity);
    }

    if (recipientFinancialEntity.isSystem()) {
      return FinancialAssociation._calculateInboundVat(ownerFinancialEntity);
    }

    return FinancialAssociation._calculateDirectVat(
      ownerFinancialEntity, recipientFinancialEntity,
    );
  }


  constructor(ownerFE, recipientFE, invoicingSettings) {
    if (!(ownerFE && ownerFE instanceof FinancialEntity)) {
      throw new Error('cannot create financial association without the financial entity of the owner');
    }
    if (!(recipientFE && recipientFE instanceof FinancialEntity)) {
      throw new Error('cannot create financial association without the financial entity of the recipient');
    }
    this.ownerFE = ownerFE;
    this.recipientFE = recipientFE;
    this.invoicingSettings = invoicingSettings;
    this.raisedBy = ownerFE.isSystem() ? RAISED_BY.TALENTDESK : RAISED_BY.PROVIDER;
    this.raisedFor = recipientFE.isSystem() ? RAISED_FOR.TALENTDESK : RAISED_FOR.CLIENT;
    this.invoiceType = RAISED_TO_INVOICE_TYPE[this.raisedBy][this.raisedFor];
    const { taxCode, vatPercent, userDefinedSalesTax } = FinancialAssociation.calculateVat({
      ownerFinancialEntity: ownerFE,
      recipientFinancialEntity: recipientFE,
    });
    if (vatPercent > 100) {
      throw new Error(`vat percent cannot be over 100% (${vatPercent})`);
    }
    this.vatPercent = vatPercent;
    this.taxCode = taxCode;
    this.userDefinedSalesTax = userDefinedSalesTax;
  }

  getInvoicingSettings() {
    return this.invoicingSettings;
  }

  getOrgId() {
    return this.invoicingSettings.getOrgId();
  }

  getOwnerFE() {
    return this.ownerFE;
  }

  getOwnerFeId() {
    return this.ownerFE.getUserId();
  }

  getOwnerBank() {
    return this.ownerFE.getBank();
  }

  getRecipientFE() {
    return this.recipientFE;
  }

  getRecipientId() {
    const { invoiceType } = this;
    const { mode, orgOwnerId } = this.invoicingSettings;
    if (invoiceType === INVOICE_TYPE.OUTBOUND) {
      // OUTBOUND
      return orgOwnerId;
    }

    // DIRECT AND INBOUND invoice types
    if (mode === INVOICING_MODE.VIA) {
      // VIA invoicing mode
      if (invoiceType === INVOICE_TYPE.DIRECT) {
        throw new Error("cannot have invoicing mode 'via' with 'direct' invoice");
      }
      // the recipient is our system which is represented by NULL in the db
      return null;
    }

    // DIRECT invoicing mode
    if (invoiceType === INVOICE_TYPE.INBOUND) {
      throw new Error("cannot have invoicing mode 'direct' with 'inbound' invoice");
    }
    return orgOwnerId;
  }

  /**
   * Defines if payment details of owner should be displayed or not by combing two flags
   * - on an org level - not showing payment details for ALL providers
   * - on owner level - not showing their payment levels
   * @returns {boolean}
   */
  shouldHideInvoicePaymentDetails() {
    const { ownerFE, invoicingSettings } = this;
    const { mode } = invoicingSettings;

    if (mode === INVOICING_MODE.VIA) {
      const hideProvidersPaymentDetails = invoicingSettings
        .shouldHideProvidersInvoicePaymentDetails();
      const ownerHidesPaymentDetails = ownerFE.shouldHideInvoicePaymentDetails();

      return hideProvidersPaymentDetails || ownerHidesPaymentDetails;
    }

    return false;
  }

  getVatPercent() {
    return this.vatPercent;
  }

  getTaxCode() {
    return this.taxCode;
  }

  getRaisedBy() {
    return this.raisedBy;
  }

  getRaisedFor() {
    return this.raisedFor;
  }

  getFeeScheme() {
    return this.invoicingSettings.getFeeScheme();
  }

  getProcessingFeeScheme() {
    return this.invoicingSettings.getProcessingFeeScheme();
  }

  getCurrency() {
    return this.invoicingSettings.getCurrency();
  }

  getFeeCurrency() {
    return this.invoicingSettings.getFeeCurrency();
  }

  getTargetCurrency() {
    const isSystem = this.ownerFE.isSystem();
    if (isSystem) {
      if (this.invoicingSettings.getCurrency() === this.invoicingSettings.getTargetCurrency()) {
        // whenever someone has not setup some explicit target currency
        return this.ownerFE.getBankCurrency();
      }
      return this.invoicingSettings.getTargetCurrency();
    }
    return this.ownerFE.getBankCurrency() || this.getCurrency();
  }

  getInvoicingMode() {
    return this.invoicingSettings.getMode();
  }

  getInvoiceType() {
    return this.invoiceType;
  }

  getGracePeriod() {
    const { invoiceType } = this;
    if (invoiceType === INVOICE_TYPE.OUTBOUND) {
      return this.recipientFE.getGracePeriod();
    }
    return this.ownerFE.getGracePeriod();
  }

  getComments() {
    const { invoiceType } = this;
    if (invoiceType === INVOICE_TYPE.OUTBOUND) {
      return this.recipientFE.getComments();
    }
    return this.ownerFE.getComments();
  }

  isOutbound() {
    return this.invoiceType === INVOICE_TYPE.OUTBOUND;
  }

  hasFees() {
    return this.isOutbound();
  }

  hasLicenceFee() {
    if (!this.isOutbound()) {
      return false;
    }
    return this.getFeeScheme().hasLicenceFee();
  }

  hasProcessingFee() {
    if (!this.isOutbound()) {
      return false;
    }
    return this.getFeeScheme().hasProcessingFee();
  }

  hasUserDefinedSalesTax() {
    return this.userDefinedSalesTax;
  }

  getTaxMethod() {
    return this.getInvoicingSettings().getTaxMethod();
  }

  getTransactionMode() {
    return this.getInvoicingSettings().getTransactionMode();
  }

  assertHasBankDetails() {
    const ownerFE = this.getOwnerFE();
    if (this.getRaisedBy() === RAISED_BY.PROVIDER
      && this.getInvoicingMode() !== INVOICING_MODE.DIRECT
      && (ownerFE.getBank()?.getBankAccountType() ?? null) === null) {
      throw new Error(`Owner ${ownerFE.getUserId()} appears to be missing bank account details`);
    }
  }

  getInvoiceTransactionReference(invoice) {
    return calcTransactionReference(
      invoice, this.getOwnerFE().getBankCurrency(), this,
    );
  }

  getDistinctCurrencies() {
    const invoicingSettings = this.getInvoicingSettings();
    return uniq([
      this.getTargetCurrency(),
      invoicingSettings.getCurrency(),
      invoicingSettings.getBalanceCurrency(),
      SYSTEM_CURRENCY,
    ]);
  }

  pickCurrencies({ invoiceCurrency, rateMap }) {
    const invoicingSettings = this.getInvoicingSettings();
    const targetCurrency = this.getTargetCurrency();
    const shouldAddExchangeRateMarkup = this.isOutbound() && (invoiceCurrency !== targetCurrency);
    const orgCurrency = invoicingSettings.getCurrency();
    const balanceCurrency = invoicingSettings.getBalanceCurrency();

    const invoiceRateMap = InvoiceRateMap.fromRateMap(rateMap, {
      currency: rateMap.getBaseCurrency().toLowerCase(),
      orgCurrency,
      balanceCurrency,
      targetCurrency,
    });
    return {
      invoiceRateMap,
      exchangeRateMarkup: shouldAddExchangeRateMarkup ? invoicingSettings.getFXMarkUp() : 0,
      exchangeRateSource: EXCHANGE_RATE_SERVICE.TRANSFERWISE,
      taxCode: this.getTaxCode(),
      vatPercent: this.getVatPercent(),
      taxMethod: this.getTaxMethod(),
    };
  }
}

export default FinancialAssociation;
