/* eslint react/no-array-index-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import ProjectBriefDescription from 'projects/assets/js/components/ProjectBriefDescription.jsx';
import { MODAL_ID as OWNERSHIP_MODAL_ID } from 'projects/assets/js/components/ProjectOwnerModal.jsx';
import {
  MODAL_ID as PAYMENTS_REVIEWER_MODAL_ID,
} from 'projects/assets/js/components/ProjectPaymentsReviewerModal.jsx';
import { BS_SIZE, BS_STYLE, BS_TOOLTIP_PLACEMENT, ICON, USER_TYPE } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { formatDate, truncate } from 'core/assets/js/lib/utils';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import LinkModal from 'core/assets/js/components/LinkModal.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgUserProfileUrl } from 'people/urls';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import {
  claimOwnershipDS, projectSelectPaymentsReviewer,
} from 'projects/assets/js/data-services/form';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';

const CONFIRM_MODAL_ID = 'CONFIRM_MODAL_ID';

class ProjectOverviewWidget extends React.Component {
  constructor(props) {
    super(props);
    this.handleClaimOwnership = this.handleClaimOwnership.bind(this);
    this.handleProjectOwnershipModalOpen = this.handleProjectOwnershipModalOpen.bind(this);
    this.handleProjectPaymentsReviewerModalOpen = this
      .handleProjectPaymentsReviewerModalOpen.bind(this);
    this.handleProjectPaymentsReviewerConfirmModalOpen = this
      .handleProjectPaymentsReviewerConfirmModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.removePaymentsReviewer = this.removePaymentsReviewer.bind(this);
  }

  async handleClaimOwnership() {
    const { dispatch, match: { params: { orgAlias } }, project } = this.props;
    try {
      await dispatch(claimOwnershipDS({
        orgAlias,
        projectId: project.id,
        /*
          Usually we'd have the component export it's name as a constant,
          but as this is a child of ProjectView, that would create a circular
          dependency
        */
        rootComponentName: 'ProjectView',
      }));
      toastr.success('Well Done!', "You're now the owner of this project!");
    } catch (e) {
      const message = (
        e.errors?._error || 'Something went wrong, please try again'
      );
      toastr.error(message);
      // required to prevent ModalConfirm calling onConfirmSuccess
      throw new Error(message);
    }
  }

  /**
   * Open the Transfer Project Ownership modal
   */
  handleProjectOwnershipModalOpen() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(OWNERSHIP_MODAL_ID));
  }

  handleProjectPaymentsReviewerModalOpen() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(PAYMENTS_REVIEWER_MODAL_ID));
  }

  handleProjectPaymentsReviewerConfirmModalOpen() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(CONFIRM_MODAL_ID));
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  async removePaymentsReviewer() {
    const { dispatch, match: { params: { orgAlias } }, project } = this.props;
    // Don't use ProjectView.GetComponentName(), as that would result in a circular dependency
    return projectSelectPaymentsReviewer(
      orgAlias, project.id, { user: null }, dispatch, 'ProjectView',
    );
  }

  render() {
    const {
      expenseCount,
      isManager,
      match: { params: { orgAlias } },
      modalIsOpen,
      project,
      purchaseOrderCount,
      secondLevelOfServiceOrderApproval,
      worksheetCount,
    } = this.props;

    const brief = project.brief;

    const {
      allowedActions: { canChangePaymentsReviewer, canClaimOwnership, canTransferOwnership } = {},
      deadline,
      owner_name: ownerName,
      payments_reviewer: paymentsReviewer,
    } = project;

    const descriptionExtras = (
      <ProjectBriefDescription project={project} />
    );

    return (
      <Card className="project-widget__details">
        <Card.Header>
          Overview
        </Card.Header>
        <Card.Body>
          <MarkdownText withBreaksPlugin text={truncate(brief, 25)} />
          <LinkModal
            modalId="project-overview-modal"
            className="imitate-link font-weight-bolder text-right"
            label="Show more"
            modalOptions={{
              heading: 'Description',
              body: descriptionExtras,
            }}
          />

          <hr className="d-inline-block w-100" />

          <div className="project-meta">
            <div className="d-flex align-items-center">
              Status
              <span className="ml-auto">{project.status_label}</span>
            </div>

            <div className="d-flex align-items-center">
              Reference
              <span className="ml-auto">{project.reference}</span>
            </div>

            <div className="d-flex align-items-center">
              External project ID
              <span className="ml-auto">{project.external_project_id || '-'}</span>
            </div>

            { project.started_at && (
              <div className="d-flex align-items-center">
                Start date
                <span className="ml-auto">{formatDate(project.started_at)}</span>
              </div>
            )}

            { deadline && (
              <div className="d-flex align-items-center">
                Deadline
                <span className="ml-auto">{formatDate(deadline)}</span>
              </div>
            )}

            <div className="d-flex align-items-center project-owner">
              Owner
              <span className="ml-auto">
                {canTransferOwnership && (
                  <span onClick={this.handleProjectOwnershipModalOpen}>
                    <TDElementWithTooltip
                      el={(
                        <>
                          <span className="imitate-link mr-2">{ownerName}</span>
                          <i className={ICON.EDIT} />
                        </>
                      )}
                      placement={BS_TOOLTIP_PLACEMENT.TOP}
                      tooltipMsg="Transfer project ownership"
                    />
                  </span>
                )}
                {canClaimOwnership && (
                  <ConfirmationButton
                    buttonClass="p-0"
                    buttonLabel={(
                      <TDElementWithTooltip
                        el={(
                          <>
                            <span className="imitate-link mr-2">{ownerName}</span>
                            <i className={`${ICON.EDIT} ml-0`} />
                          </>
                        )}
                        placement={BS_TOOLTIP_PLACEMENT.TOP}
                        tooltipMsg="Claim project ownership"
                      />
                    )}
                    buttonSize={BS_SIZE.SMALL}
                    buttonStyle={BS_STYLE.LINK}
                    modalProps={{
                      body: (
                        <>
                          <p>
                            Once you become the new owner, you&apos;ll be able to update the
                            project&apos;s members, title, deadline, brief and support documents.
                            The current project owner, will be able to take over again if they wish.
                          </p>
                          <p>
                            Are you sure you want to claim ownership of this project?
                          </p>
                        </>
                      ),
                      cancelLabel: 'No',
                      confirmLabel: 'Yes',
                      heading: 'Are you sure you want to claim ownership of this project?',
                      onConfirm: this.handleClaimOwnership,
                    }}
                  />
                )}
                {!canTransferOwnership && !canClaimOwnership && ownerName}
              </span>
            </div>

            {secondLevelOfServiceOrderApproval && (
              <div className="d-flex align-items-center" data-testid="payments-reviewer-row">
                Payments reviewer
                <span className="ml-auto">
                  {canChangePaymentsReviewer && (
                    <>
                      <ModalSimple
                        footer={[
                          <TDButton
                            key={2}
                            className="mr-4 float-right"
                            label="Cancel"
                            onClick={() => this.handleModalClose()}
                          />,
                          <TDButton
                            key={1}
                            className="float-right"
                            label="Set payments reviewer"
                            variant={BS_STYLE.PRIMARY}
                            onClick={() => {
                              this.handleModalClose();
                              this.handleProjectPaymentsReviewerModalOpen();
                            }}
                          />,
                        ]}
                        heading="Set payments reviewer"
                        onClose={() => this.handleModalClose()}
                        open={modalIsOpen}
                      >
                        <p>
                          Currently the second level of approvals is activated in your organisation.
                          This means that all worksheets and expenses are first confirmed by a
                          project manager.
                        </p>
                        {paymentsReviewer && (
                          <>
                            <p>
                              Then the following manager is responsible for signing off the payment
                              requests:
                            </p>
                            <div
                              className="mb-4 d-flex justify-content-between p-4"
                              id="remove-payments-reviewer"
                            >
                              <span>
                                <a
                                  href={
                                    orgUserProfileUrl(
                                      orgAlias, USER_TYPE.MANAGER, paymentsReviewer.id,
                                    )
                                  }
                                >
                                  {paymentsReviewer.name}
                                </a>
                                {' (payments reviewer)'}
                              </span>
                              <span className="imitate-link" onClick={this.removePaymentsReviewer}>
                                Remove
                              </span>
                            </div>
                          </>
                        )}
                        {!paymentsReviewer && (
                          <p>
                            Then any organization financial controllers are responsible for signing
                            off the payment requests.
                          </p>
                        )}
                        <TDSystemMessage title="How to appoint a payments reviewer">
                          To appoint a
                          {` ${paymentsReviewer ? 'different' : ''} `}
                          manager as the payments reviewer for this project, click on the
                          &quot;Set payments reviewer&quot; button and select a manager from the
                          list.
                        </TDSystemMessage>
                      </ModalSimple>
                      <span onClick={() => this.handleProjectPaymentsReviewerConfirmModalOpen()}>
                        <TDElementWithTooltip
                          el={(
                            <>
                              <span className="imitate-link mr-2">
                                {paymentsReviewer && paymentsReviewer.name}
                                {!paymentsReviewer && 'Select a manager'}
                              </span>
                              <i className={ICON.EDIT} />
                            </>
                          )}
                          placement={BS_TOOLTIP_PLACEMENT.TOP}
                          tooltipMsg="Change payments reviewer"
                        />
                      </span>
                    </>
                  )}
                  {!canChangePaymentsReviewer && (
                    <>
                      {paymentsReviewer && paymentsReviewer.name}
                      {!paymentsReviewer && 'Not assigned'}
                    </>
                  )}
                </span>
              </div>
            )}
          </div>

          <div className="project-stats d-flex mt-4 row">
            <div className={`${isManager ? 'col-4' : 'col-6'} text-center`}>
              <span className="project-stats__value">
                {worksheetCount}
              </span>
              <span className="project-stats__label">
                Worksheets
              </span>
            </div>

            <div className={`${isManager ? 'col-4' : 'col-6'} text-center`}>
              <span className="project-stats__value">
                {expenseCount}
              </span>
              <span className="project-stats__label">
                Expenses
              </span>
            </div>

            { isManager && (
              <div className="col-4 text-center border-left">
                <span className="project-stats__value">
                  {purchaseOrderCount > 0 ? purchaseOrderCount : '-'}
                </span>
                <span className="project-stats__label">
                  POS
                </span>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

ProjectOverviewWidget.propTypes = {
  history: routerHistorySpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  isManager: PropTypes.bool,
  project: projectSpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  isCollapsible: PropTypes.bool,
  purchaseOrderCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  worksheetCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  expenseCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  modalIsOpen: PropTypes.bool,
  secondLevelOfServiceOrderApproval: PropTypes.bool,
};

ProjectOverviewWidget.defaultProps = {
  isCollapsible: true,
  isManager: false,
  modalIsOpen: false,
  purchaseOrderCount: '-',
  secondLevelOfServiceOrderApproval: false,
  worksheetCount: '-',
  expenseCount: '-',
};

const mapStateToProps = state => ({
  modalIsOpen: getIsModalOpen(state, CONFIRM_MODAL_ID),
  secondLevelOfServiceOrderApproval: selectActiveOrg(state).second_level_of_service_order_approval,
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const ProjectOverviewWidgetConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ProjectOverviewWidget);

export default withRouter(ProjectOverviewWidgetConnected);
