import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useLocation, useParams, withRouter } from 'react-router-dom';

import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE, IMG_SIZE, USER_CARD_STATUS } from 'core/assets/js/constants';
import { fetchListDS, getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { pluralize } from 'core/assets/js/lib/utils';
import { parseSearchableFieldsInQuery } from 'interviews/assets/js/lib/utils';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ProviderCard from 'people/assets/js/components/ProviderCard.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { orgPeopleListApiUrl } from 'people/urls';
import AddProvidersSkillsQuickFilter from 'projects/assets/js/components/AddProvidersSkillsQuickFilter.jsx';
import { PROJECT_TAB_INVITEES_COMPONENT_NAME } from 'projects/assets/js/constants';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  getProjectOpportunityInviteesApiUrl, inviteToApplyForOpportunityApiUrl,
} from 'projects/urls';

export const MODAL_ID = 'opportunity-invite-providers-modal';

const STEPS = { SELECT_MEMBERS: 1, CONFIRM_SELECTION: 2 };

const OpportunityInviteProvidersPanel = ({ filtersOpen, onFiltersToggle, project }) => {
  const params = useParams();
  const { orgAlias } = params;
  const location = useLocation();
  const dispatch = useDispatch();
  const peopleSearchFormRef = useRef(null);

  const componentName = OpportunityInviteProvidersPanel.GetComponentName({ params });

  const isModalOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const {
    items: userCards, pagination, rateLimits, selectedItems, userGroupNames,
  } = useSelector(state => getListState(state, componentName));
  const listStateExtras = useSelector(state => getListStateExtras(state, componentName));
  const { customFieldFilters } = listStateExtras || {};

  const [query, setQuery] = useState({});
  const [currentStep, setCurrentStep] = useState(STEPS.SELECT_MEMBERS);
  const [showSelectedItems, setShowSelectedItems] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleQueryUpdate = newQuery => {
    setQuery({ ...parseSearchableFieldsInQuery(newQuery), page: pagination.page });
  };

  const handlePageChange = newPagination => setQuery({ ...query, ...newPagination });

  const closePanel = () => dispatch(modalCloseAC());

  const handleShowSelectedItemsToggle = () => setShowSelectedItems(currentState => !currentState);

  const currentStepIsSelectMembers = currentStep === STEPS.SELECT_MEMBERS;

  const selectedItemsLength = selectedItems.length;
  const noneSelected = selectedItemsLength === 0;

  useEffect(() => {
    setCurrentStep(STEPS.SELECT_MEMBERS);
  }, [isModalOpen]);

  return (
    <ModalPanel
      body={(
        <>
          {/* Member selection Step */}
          <SelectableListWrapper
            cardItem={{
              component: ProviderCard,
              props: {
                availabilityFilter: query.availability ? JSON.parse(query.availability) : null,
                // Open link in a new tab so that user doesn't loose selected items.
                linkTarget: '_blank',
                listViewComponentName: OpportunityInviteProvidersPanel.GetComponentName(),
                orgAlias,
                onUpdated: () => {},
                showAvailabilityMessages: true,
              },
            }}
            className={currentStepIsSelectMembers ? null : 'd-none'}
            componentName={componentName}
            emptyListMessage="No providers found."
            emptySelectedListMessage={(
              <p className="discreet">
                There are currently no selected providers.
                <br />
                <span
                  className="imitate-link"
                  onClick={handleShowSelectedItemsToggle}
                >
                  Find providers
                </span>
                .
              </p>
            )}
            fetchData={() => dispatch(fetchListDS({
              componentName,
              querystring: queryString.stringify({
                ...query,
                excludeInvitedToOpportunityId: project.id,
                excludeMembersOfProject: project.id,
                status: USER_CARD_STATUS.APPROVED,
              }),
              url: orgPeopleListApiUrl({ orgAlias, peopleType: PEOPLE_TYPE.PROVIDERS }),
            }))}
            filtersOpen={filtersOpen}
            items={userCards}
            onPageChange={handlePageChange}
            searchComponent={(
              <PeopleSearch
                customFieldFilters={customFieldFilters}
                extraSearchRow={(
                  <AddProvidersSkillsQuickFilter
                    formRef={peopleSearchFormRef}
                    handleQueryUpdate={handleQueryUpdate}
                    query={query}
                    skills={project?.skills}
                  />
                )}
                filtersOpen={filtersOpen}
                hideStatus
                isManager
                onFiltersChanged={handleQueryUpdate}
                onFiltersToggle={onFiltersToggle}
                peopleType={PEOPLE_TYPE.PROVIDERS}
                query={query}
                rateLimits={rateLimits}
                searchFormRef={peopleSearchFormRef.current}
                userGroupNames={(userGroupNames || []).map(ugn => ({
                  value: ugn.id.toString(), text: ugn.name,
                }))}
              />
            )}
            selectModeEnabled
            showSelectedItems={showSelectedItems}
            skeletonComponent={PeopleListSkeleton}
            query={query}
          />

          {/* Confirmation Step */}
          {!currentStepIsSelectMembers && (
            <div>
              <p data-testid="project-add-members-panel-confirmation-text">
                {
                  [
                    `You’ve selected to invite ${selectedItemsLength} new providers to apply to `,
                    'join this opportunity',
                  ].join(' ')
                }
              </p>
              <ul className="mb-4">
                {selectedItems && selectedItems.map(userCard => (
                  <ProfilePic
                    alt={userCard.user.profile.name}
                    className="mr-3 mb-3"
                    key={userCard.user.id}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                    url={userCard.user.profile.avatar}
                  />
                ))}
              </ul>
            </div>
          )}
        </>
      )}
      footer={(
        <>
          {currentStepIsSelectMembers && selectedItemsLength > 0 && (
            <span
              className="cursor-pointer d-flex align-items-center"
              onClick={handleShowSelectedItemsToggle}
            >
              <TDSwitch selected={showSelectedItems} />
              <span className="ml-3">
                {showSelectedItems
                  ? 'Show all providers'
                  : `Show ${selectedItemsLength} selected providers`
                }
              </span>
            </span>
          )}
          <div className="ml-auto">
            {currentStepIsSelectMembers && (
              <>
                <TDButton label="Cancel" onClick={closePanel} />
                <TDButton
                  className="ml-4"
                  disabled={noneSelected}
                  label="Next"
                  onClick={() => setCurrentStep(STEPS.CONFIRM_SELECTION)}
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />
              </>
            )}
            {!currentStepIsSelectMembers && (
              <>
                <TDButton
                  disabled={submitting}
                  label="Back"
                  onClick={() => setCurrentStep(STEPS.SELECT_MEMBERS)}
                />
                <TDButton
                  className="ml-4"
                  disabled={noneSelected || submitting}
                  label={`Send ${pluralize('invitation', selectedItemsLength)}`}
                  onClick={async () => {
                    setSubmitting(true);
                    try {
                      await axios.post(
                        inviteToApplyForOpportunityApiUrl(orgAlias, project.id),
                        { userIds: selectedItems.map(uc => uc.user.id) },
                      );
                      toastr.success(
                        'Well Done!',
                        `You have invited ${selectedItemsLength} providers to apply for this `
                          + 'opportunity',
                      );
                      // Reload invitees list
                      dispatch(fetchListDS({
                        componentName: PROJECT_TAB_INVITEES_COMPONENT_NAME,
                        url: [
                          getProjectOpportunityInviteesApiUrl(orgAlias, project.id),
                          `?${location.search}`,
                        ].join(''),
                      }));
                      closePanel();
                    } catch (err) {
                      toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />
              </>
            )}
          </div>
        </>
      )}
      heading={<h4>Invite providers to apply</h4>}
      modalId={MODAL_ID}
      open={isModalOpen}
    />
  );
};

OpportunityInviteProvidersPanel.GetComponentName = () => 'OpportunityInviteProvidersPanel';

OpportunityInviteProvidersPanel.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  project: projectSpec.isRequired,
};

export default withRouter(withFilters(OpportunityInviteProvidersPanel));
