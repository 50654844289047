import React from 'react';

import PeopleCardSkeleton from 'core/assets/js/components/Skeleton/PeopleCardSkeleton.jsx';

const itemsCount = 6;
const renderItems = () => {
  return new Array(itemsCount).fill(0).map((item, idx) => (
    <PeopleCardSkeleton key={`people-skeleton-item-${idx}`} /> // eslint-disable-line
  ));
};

const PeopleListSkeleton = () => (
  <div className="skeleton--people-list">
    { renderItems() }
  </div>
);

export default PeopleListSkeleton;
