import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import withFilters from 'core/assets/js/components/withFilters.jsx';

import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { BS_STYLE, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import {
  paginationSpec, routerHistorySpec, routerMatchSpec,
  proFormaInvoiceAclSpec, billableAnalyticsSpec,
} from 'core/assets/js/lib/objectSpecs';
import { proFormaInvoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import ProFormaInvoicesTable from 'finance/assets/js/components/ProFormaInvoicesTable.jsx';
import { getListState, listReplaceItemAC } from 'core/assets/js/ducks/list';
import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { financeProFormaInvoiceCreateUrl, financeProFormaInvoicesCsvApiUrl } from 'finance/urls';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import {
  fetchFinanceProFormaInvoiceListDS,
} from 'finance/assets/js/data-services/list';
import {
  modalOpenAC, getModalPayload,
} from 'core/assets/js/ducks/modalLauncher';
import BillableAnalyticsBreakDownModal from 'finance/assets/js/BillableAnalyticsBreakDownModal.jsx';
import BillableAnalyticsMeta from 'finance/assets/js/BillableAnalyticsMeta.jsx';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

const PRO_FORMA_INVOICE_BILLABLE_BREAKDOWN = 'pro-forma-invoice-billable-breakdown';
export const COMPONENT_NAME = 'ProFormaInvoices';

const ProFormaInvoiceView = ({
  activeOrg,
  amountLimits,
  billableAnalytics,
  billableModalPayload,
  dispatch,
  filtersOpen,
  history,
  location,
  match: { params: { orgAlias } },
  onFiltersToggle,
  organizationCurrency,
  pagination,
  proFormaInvoiceAcl,
  proFormaInvoices,
  rateUnit,
  searchActive,
}) => {
  const onUpdated = (updatedItem) => {
    // Update list item
    dispatch(listReplaceItemAC(updatedItem, COMPONENT_NAME));

    // Update finance pending counts.
    dispatch(refreshFinancePendingCountsDS({
      orgAlias,
    }));
  };

  const _getBillableAnalyticsMetaProps = () => {
    let currencySymbol = null;
    let totalAmount = null;
    let totalsByCurrency = {};
    if (billableAnalytics) {
      ({ totalAmount, totalsByCurrency } = billableAnalytics);
      const currencies = Object.keys(totalsByCurrency);
      const [firstCurrency] = currencies;
      const multipleCurrencies = currencies.length > 1;
      currencySymbol = CURRENCY_SYMBOL[multipleCurrencies ? organizationCurrency : firstCurrency];
      totalAmount = multipleCurrencies ? totalAmount : totalsByCurrency[firstCurrency];
    }
    return {
      currencySymbol,
      onOpenModal: () => {
        dispatch(modalOpenAC(PRO_FORMA_INVOICE_BILLABLE_BREAKDOWN, { billableAnalytics }));
      },
      showTotal: pagination.pages <= 1,
      totalAmount,
      total: pagination.total,
    };
  };

  const breadcrumbs = [
    {
      title: 'Proforma Invoices',
      url: null,
    },
  ];

  let ctaButtonItems = null;
  if (proFormaInvoiceAcl.canRaise) {
    ctaButtonItems = [{
      label: 'Submit Proforma Invoice',
      onClick: () => history.push(financeProFormaInvoiceCreateUrl(orgAlias)),
      variant: BS_STYLE.PRIMARY,
    }];
  }

  const emptyText = 'No Proforma Invoices found';
  const billableAnalyticsMetaProps = _getBillableAnalyticsMetaProps();

  const isDraftInvoiceEnabled = activeOrg.allow_draft_invoice;

  return (
    <>
      {billableModalPayload && <BillableAnalyticsBreakDownModal /> }

      <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />
      <div className="page page--finance">
        <div className="container">
          {isDraftInvoiceEnabled && (
            <>
              <div className="row">
                {searchActive && (
                  <ReportSearch
                    reportType={REPORT_TYPES.PRO_FORMA_INVOICE}
                    amountLimits={amountLimits}
                    onFiltersToggle={onFiltersToggle}
                    filtersOpen={filtersOpen}
                  />
                )}
                {!filtersOpen && (
                  <ListActionsButton
                    options={[{
                      name: 'Export',
                      onClick: () => {
                        WINDOW_OPEN([
                          financeProFormaInvoicesCsvApiUrl(orgAlias), location.search,
                        ].join(''));
                      },
                    }]}
                  />
                )}
              </div>
              <TDApiConnected
                duck="list"
                fetchData={({ authedAxios, dispatch: thisDispatch, params, querystring, url }) => (
                  dispatch(fetchFinanceProFormaInvoiceListDS({
                    authedAxios,
                    componentName: COMPONENT_NAME,
                    dispatch: thisDispatch,
                    params,
                    querystring,
                    url,
                  }))
                )}
                skeletonComponent={FinanceTableSkeleton}
                storeKey={COMPONENT_NAME}
              >
                {!filtersOpen && (
                  <>
                    <div
                      className={(
                        'finance-report finance-report__table-wrapper'
                        + ' finance-report__table-wrapper--responsive'
                        + ' finance-report__table-wrapper--list'
                      )}
                    >
                      <ProFormaInvoicesTable
                        list={proFormaInvoices}
                        rateUnit={rateUnit}
                        emptyText={emptyText}
                        onItemUpdate={onUpdated}
                      />
                    </div>

                    {billableAnalytics && pagination.pages <= 1 && (
                      <BillableAnalyticsMeta
                        {...billableAnalyticsMetaProps}
                      />
                    )}
                    <TDPagination
                      {...pagination}
                      extraMeta={billableAnalytics && (
                        <BillableAnalyticsMeta
                          {...billableAnalyticsMetaProps}
                        />
                      )}
                    />
                  </>
                )}
              </TDApiConnected>
            </>
          )}
          {!isDraftInvoiceEnabled && (
            <TDSystemMessage
              data-testid="pro-forma-invoice-view-cannot"
              title="You cannot view the Proforma Invoices list"
              type={BS_STYLE.DANGER}
            >
              <p>You cannot view the Proforma Invoices list.</p>
            </TDSystemMessage>
          )}
        </div>
      </div>
    </>
  );
};

ProFormaInvoiceView.propTypes = {
  activeOrg: orgSpec.isRequired,
  amountLimits: PropTypes.object,
  billableModalPayload: PropTypes.object,
  billableAnalytics: billableAnalyticsSpec,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  organizationCurrency: PropTypes.string.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  pagination: paginationSpec.isRequired,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  searchActive: PropTypes.bool.isRequired,
  proFormaInvoiceAcl: proFormaInvoiceAclSpec,
  proFormaInvoices: PropTypes.arrayOf(proFormaInvoiceSpec).isRequired,
};

ProFormaInvoiceView.defaultProps = {
  amountLimits: {},
  proFormaInvoiceAcl: { canRaise: false },
  billableModalPayload: null,
  billableAnalytics: null,
};

const mapStateToProps = (state) => {
  const listState = getListState(state, COMPONENT_NAME);
  const props = {
    activeOrg: selectActiveOrg(state),
    amountLimits: listState.extras.amountLimits,
    organizationCurrency: selectActiveOrg(state).currency,
    pagination: listState.pagination,
    rateUnit: selectActiveOrg(state).default_rate_unit,
    searchActive: listState.search.isActive,
    proFormaInvoiceAcl: listState.extras.accessControl,
    proFormaInvoices: listState.items,
    billableAnalytics: listState.extras.billableAnalytics,
    billableModalPayload: getModalPayload(state, PRO_FORMA_INVOICE_BILLABLE_BREAKDOWN),
  };

  return props;
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProFormaInvoiceViewConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ProFormaInvoiceView);

export default withRouter(withFilters(ProFormaInvoiceViewConnected));
