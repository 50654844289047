import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  FIELDS_PER_SETTINGS_SECTION, SETTINGS_SECTION, TAX_RATE_FIELD_PREFIX,
} from 'integrations/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { fetchAccountSettingsDS } from 'integrations/assets/js/data-services/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import InvoiceMappingField from 'integrations/assets/js/components/InvoiceMappingField.jsx';
import MappingFieldName from 'integrations/assets/js/components/MappingFieldName.jsx';
import { getTaxRatePercent } from 'integrations/assets/js/helpers.js';

const IntegrationAccountMappingSettings = ({
  accountOptions,
  fields = [],
  hasLoaded,
  match: { params: { integrationId, orgAlias } },
  taxRateOptions,
}) => {
  const { values } = useFormState();
  const taxRatePercent = getTaxRatePercent(values);

  const componentName = IntegrationAccountMappingSettings.GetComponentName();

  return (
    <>
      {/*
        The content should be inside TDApiConnected, but there was a bug where it was not
        rendering, even though the API request was successful.
        It just gets stuck on `!requestHasBeenTriggered` in withApi.
        Spent hours investigating, but could not find the root cause. So rendering the content
        outside of TDApiConnected is the hacky fix
      */}
      <TDApiConnected
        duck="view"
        fetchData={({ authedAxios, dispatch }) => dispatch(fetchAccountSettingsDS({
          authedAxios, componentName, integrationId, orgAlias,
        }))}
        skeletonComponent={() => null}
        storeKey={componentName}
      />
      {hasLoaded && (
        <>
          <h3 className="heading-block">Account Mapping</h3>
          <table
            className="table w-100 table-bordered integration__invoice-mapping-fields mb-4"
          >
            <thead>
              <tr className="text-uppercase">
                <th scope="col" className="w-50">
                  Talentdesk Invoice Line Item Type
                </th>
                <th scope="col">
                  Account
                </th>
                <th scope="col">
                  Tax rate
                  {taxRatePercent !== null && ` (${taxRatePercent}%)`}
                </th>
              </tr>
            </thead>
            <tbody>
              {fields.map(field => {
                if (
                  !(FIELDS_PER_SETTINGS_SECTION[SETTINGS_SECTION.ACCOUNT] || [])
                    .includes(field.name)
                ) {
                  return null;
                }

                const { label, name } = field;

                const selectedAccount = values[field.name];
                const taxRateFieldOptions = JSON.parse(JSON.stringify(taxRateOptions));
                taxRateFieldOptions[0].options = !selectedAccount
                  ? []
                  : taxRateFieldOptions[0].options.filter(o => (
                    (o.totalTaxRate === null || o.totalTaxRate === taxRatePercent)
                    && (
                      o.validAccountIds === undefined || o.validAccountIds.includes(selectedAccount)
                    )
                  ));

                return (
                  <tr key={`mapping-field-${name}`}>
                    <td><MappingFieldName value={label} /></td>
                    <td>
                      <InvoiceMappingField
                        allowCustom={false}
                        name={name}
                        options={accountOptions}
                        required
                      />
                    </td>
                    <td>
                      <InvoiceMappingField
                        allowCustom={false}
                        disabled={!selectedAccount}
                        name={`${TAX_RATE_FIELD_PREFIX}${name.split(':')[1]}:${taxRatePercent}`}
                        options={taxRateFieldOptions}
                        required
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

IntegrationAccountMappingSettings.GetComponentName = () => 'IntegrationAccountMappingSettings';

IntegrationAccountMappingSettings.propTypes = {
  accountOptions: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  hasLoaded: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  taxRateOptions: PropTypes.arrayOf(PropTypes.object),
};

IntegrationAccountMappingSettings.defaultProps = {
  accountOptions: [],
  fields: [],
  taxRateOptions: [],
};

const mapStateToProps = (state) => {
  const { hasLoaded, item: { accountOptions, fields, taxRateOptions } } = getViewState(
    state, IntegrationAccountMappingSettings.GetComponentName(),
  );

  return { accountOptions, fields, hasLoaded, taxRateOptions };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationAccountMappingSettingsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationAccountMappingSettings);

export default withRouter(IntegrationAccountMappingSettingsConnected);
