import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

import { fetchOrgSubscriptionsListDS } from 'organizations/assets/js/data-services/list';
import { LOGO, ICON } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import AccountProgressBar from 'accounts/assets/js/components/AccountProgressBar.jsx';
import { orgGetStartedUrl } from 'accounts/urls';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { GET_STARTED_SIDE_PANEL_MODAL_ID } from 'accounts/assets/js/constants';

/**
 * Mobile Header
 *
 * Displays the logo and the off canvas menu toggle
 */
const TopNav = ({ activeOrg, dispatch, isMenuOpened, location, onMenuClick, profile }) => {
  useEffect(() => {
    dispatch(fetchOrgSubscriptionsListDS({ orgAlias: activeOrg.alias }));
  }, []);

  if (!profile) {
    return null;
  }

  return (
    <div
      className={[
        'top-header container d-flex d-lg-none px-0 align-items-center justify-content-between',
      ].join(' ')}
    >
      <div className="d-flex align-items-center">
        <a
          className={[
            'off-canvas-toggle do-not-print', (isMenuOpened) ? 'off-canvas-toggle--on' : '',
          ].join(' ')}
          onClick={onMenuClick}
        >
          <i className={ICON.MENU} />
        </a>

        <div className="header__logo-container">
          <NavLink to={profile.homeUrl}>
            <img src={LOGO.DEFAULT} className="header__logo" alt="TalentDesk.io Logo" />
          </NavLink>
        </div>
      </div>

      {location.pathname === orgGetStartedUrl(activeOrg.alias) && (
        <div
          className="d-lg-none mr-4"
          id="mobile-get-started-toggle"
          onClick={() => dispatch(modalOpenAC(GET_STARTED_SIDE_PANEL_MODAL_ID))}
        >
          <AccountProgressBar />
        </div>
      )}
    </div>
  );
};

TopNav.propTypes = {
  activeOrg: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isMenuOpened: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onMenuClick: PropTypes.func,
  profile: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    homeUrl: PropTypes.string,
    jobTitle: PropTypes.string,
    lastName: PropTypes.string,
    userRole: PropTypes.string,
  }),
  withLogo: PropTypes.bool,
};

TopNav.defaultProps = {
  activeOrg: null,
  isMenuOpened: false,
  onMenuClick: null,
  profile: null,
  withLogo: false,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  profile: selectProfile(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const HeadingConnect = connect(mapStateToProps, mapDispatchToProps)(TopNav);

export default withRouter(HeadingConnect);
