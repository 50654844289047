import React from 'react';
import { Card } from 'react-bootstrap';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { WISE_AFFILIATE_URL } from 'settings/assets/js/constants';

const WiseBanner = () => (
  <Card className="wise-banner">
    <div className="wise-banner__header">
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <h1 className="wise-banner__header-heading text-center mr-0 mr-md-4 my-0">Reasons to choose</h1>
        <img
          className="wise-banner__header-logo mt-3 mt-md-0"
          src="/img/payment-options/wise_green_logo.svg"
          alt="Wise logo"
        />
      </div>
    </div>
    <div className="wise-banner__cta d-flex justify-content-center">
      <TDButton
        variant={BS_STYLE.WISE}
        onClick={() => WINDOW_OPEN(WISE_AFFILIATE_URL)}
      >
        Open a Wise account
      </TDButton>
    </div>
    <div className="container ">
      <div className="row">
        <div className="col-12 col-md-4 mb-4 mb-md-0">
          <div className="wise-banner__benefit h-100 text-center d-flex flex-column">
            <div className="wise-banner__benefit-icon-container">
              <i className={`${ICON.MONEY_BILL_1_DUOTONE}`} />
              <i className={`${ICON.SLASH_FORWARD}`} />
            </div>
            <h3 className="wise-banner__benefit-heading mt-0 mb-2">
              No bank fees
            </h3>
            <div className="wise-banner__benefit-description">
              Receiving to Wise from Talentdesk is free
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-4 mb-md-0">
          <div className="wise-banner__benefit h-100 text-center d-flex flex-column">
            <div className="wise-banner__benefit-icon-container">
              <i className={`${ICON.BOLT_DUOTONE}`} />
            </div>
            <h3 className="wise-banner__benefit-heading mt-0 mb-2">
              Instant payments
            </h3>
            <div className="wise-banner__benefit-description">
              Over 50% of transfers from Wise are instant
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="wise-banner__benefit h-100 text-center d-flex flex-column">
            <div className="wise-banner__benefit-icon-container">
              <i className={`${ICON.ARROW_RIGHT_ARROW_RIGHT_DUOTONE}`} />
            </div>
            <h3 className="wise-banner__benefit-heading mt-0 mb-2">
              Mid-market exchange rate
            </h3>
            <div className="wise-banner__benefit-description">
              Wise uses the mid-market rate, meaning no hidden fees and no nasty surprises
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

export default WiseBanner;
