import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import pluralize from 'pluralize';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ContactSearch from 'contacts/assets/js/components/ContactSearch.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { contactCreateUrl, contactsImportUrl, contactsListApiUrl } from 'contacts/urls';
import { listResetSelectItemsAC, fetchListDS, getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import ContactCard from 'contacts/assets/js/components/ContactCard.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { deleteContactsDS } from 'contacts/assets/js/data-services/form';
import { orgPeopleInviteUrl } from 'people/urls';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';

class ContactsListView extends React.Component {
  static FetchData({ dispatch, params, authedAxios, querystring, componentName }) {
    const { orgAlias } = params;
    return dispatch(fetchListDS({
      authedAxios,
      componentName,
      querystring,
      url: contactsListApiUrl({ orgAlias }),
    }));
  }

  static GetComponentName() {
    return 'ContactsListView';
  }

  constructor(props) {
    super(props);
    this.handleDeleteContacts = this.handleDeleteContacts.bind(this);
    this.handleCloseDeleteContactsModal = this.handleCloseDeleteContactsModal.bind(this);
    this.handleOpenDeleteContactsModal = this.handleOpenDeleteContactsModal.bind(this);
    this.handleInviteContacts = this.handleInviteContacts.bind(this);
  }

  handleOpenDeleteContactsModal(contacts) {
    const { dispatch, contactsDeleteModalId } = this.props;
    dispatch(modalOpenAC(contactsDeleteModalId, contacts));
  }

  handleCloseDeleteContactsModal() {
    const { dispatch, contactsDeleteModalId } = this.props;
    dispatch(modalCloseAC(contactsDeleteModalId));
  }

  handleInviteContacts(contacts, peopleType) {
    const { history, match: { params: { orgAlias } } } = this.props;
    const emails = contacts.map(ct => ct.email);
    history.push({ pathname: orgPeopleInviteUrl(orgAlias, peopleType), state: { emails } });
  }

  handleDeleteContacts() {
    const { dispatch, deletedContactsPayload, match: { params: { orgAlias } } } = this.props;
    const componentName = ContactsListView.GetComponentName();
    const contactIds = deletedContactsPayload.map(ct => ct.id);
    return dispatch(
      deleteContactsDS({ contactIds, componentName, orgAlias }),
    ).then(() => {
      this.handleCloseDeleteContactsModal();
      dispatch(listResetSelectItemsAC(componentName));
      toastr.success('Well Done!', `Your ${pluralize('contact', contactIds.length)} ${pluralize('was', contactIds.length)} deleted successfully.`);
    });
  }

  render() {
    const {
      history, match, contacts, isSearchActive, onFiltersToggle, filtersOpen, showingSearchResults,
      isDeleteContactsModalOpen, selectedItems, customFieldFilters,
    } = this.props;

    const bulkActions = [
      {
        label: `Delete ${pluralize('contact', selectedItems.length)}`,
        handleClick: this.handleOpenDeleteContactsModal,
      },
      {
        label: `Invite as ${pluralize('provider', selectedItems.length)}`,
        handleClick: selected => this.handleInviteContacts(selected, PEOPLE_TYPE.PROVIDERS),
      },
      {
        label: `Invite as ${pluralize('manager', selectedItems.length)}`,
        handleClick: selected => this.handleInviteContacts(selected, PEOPLE_TYPE.MANAGERS),
      },
    ];

    const { orgAlias } = match.params;
    const noResultsMessage = (
      <div>No contacts found</div>
    );
    const emptyListMessage = (
      <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
        <i className={ICON.USERS} />
        You haven&apos;t created any contacts yet
        <br />
        <Link to={contactCreateUrl(orgAlias)}>
          Create contact
        </Link>
      </div>
    );
    const breadcrumbs = [
      {
        title: 'Contacts',
        url: null,
      },
    ];
    const ctaComponent = (
      <Dropdown as={ButtonGroup} className="td-dropdown">
        <TDButton
          variant={BS_STYLE.PRIMARY}
          label="Create contact"
          onClick={() => history.push(contactCreateUrl(orgAlias))}
          floatRight
        />
        <Dropdown.Toggle split variant={BS_STYLE.PRIMARY}>
          <span className={ICON.CHEVRON_DOWN} />
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight>
          <Dropdown.Item
            key="import-contacts"
            onClick={() => history.push(contactsImportUrl(orgAlias))}
          >
            Import from CSV
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
    return (
      <div>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={ctaComponent}
        />
        <div className="page page--contacts">
          <div className="container">
            <SelectableListWrapper
              hoverable
              className="contacts-list"
              componentName={this.constructor.GetComponentName()}
              fetchData={ContactsListView.FetchData}
              skeletonComponent={PeopleListSkeleton}
              filtersOpen={filtersOpen}
              selectable
              cardItem={{
                component: ContactCard,
              }}
              emptyListMessage={showingSearchResults ? noResultsMessage : emptyListMessage}
              searchComponent={isSearchActive && (
                <ContactSearch
                  customFieldFilters={customFieldFilters}
                  onFiltersToggle={onFiltersToggle}
                  filtersOpen={filtersOpen}
                />
              )}
              bulkActions={bulkActions}
              items={contacts}
            />
          </div>
        </div>
        <ModalSimple
          body="Are you sure you want to delete the selected contacts?"
          heading="Delete contacts"
          onClose={this.handleCloseDeleteContactsModal}
          open={isDeleteContactsModalOpen}
          footer={(
            <React.Fragment>
              <TDButton
                key={2}
                className="float-right"
                label="Cancel"
                onClick={this.handleCloseDeleteContactsModal}
              />
              <TDButton
                key={1}
                className="float-right"
                label="Delete"
                variant={BS_STYLE.DANGER}
                onClick={this.handleDeleteContacts}
              />
            </React.Fragment>
          )}
        />
      </div>
    );
  }
}

ContactsListView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  contacts: PropTypes.array,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  isSearchActive: PropTypes.bool,
  match: routerMatchSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  showingSearchResults: PropTypes.bool.isRequired,
  selectedItems: PropTypes.array,
  isDeleteContactsModalOpen: PropTypes.bool.isRequired,
  contactsDeleteModalId: PropTypes.string.isRequired,
  deletedContactsPayload: PropTypes.array,
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
};

ContactsListView.defaultProps = {
  contacts: [],
  selectedItems: [],
  deletedContactsPayload: [],
  isSearchActive: false,
  customFieldFilters: [],
};

const mapStateToProps = (state, props) => {
  const componentName = ContactsListView.GetComponentName();
  const listState = getListState(state, componentName);
  const contactsDeleteModalId = 'delete-multiple-contacts-modal';

  return {
    contacts: listState.items,
    history: props.history,
    isSearchActive: listState.search.isActive,
    pagination: listState.pagination,
    selectedItems: listState.selectedItems,
    match: props.match,
    contactsDeleteModalId,
    deletedContactsPayload: getModalPayload(state, contactsDeleteModalId),
    isDeleteContactsModalOpen: getIsModalOpen(state, contactsDeleteModalId),
    customFieldFilters: getListStateExtras(state, componentName, 'customFieldFilters'),
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ContactsListViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactsListView);

export default withRouter(withFilters(ContactsListViewConnected));
