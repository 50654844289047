import { isEmpty, isError } from 'lodash';

import { prepareQueryStringComponent } from 'core/assets/js/lib/utils';


export const orgSettingsRatesUrl = orgAlias => (
  `/${orgAlias}/settings/rates`
);
export const orgSettingsRatesCreateUrl = orgAlias => (
  `/${orgAlias}/settings/rates/create`
);
export const orgSettingsRatesEditUrl = (orgAlias, rateId) => (
  `/${orgAlias}/settings/rates/${rateId}/edit`
);

// Rates API urls
export const ratesFetchApiUrl = (orgAlias, status = [], url = '') => {
  let qs = '';
  if (!isEmpty(status)) {
    const statusComponent = prepareQueryStringComponent('status', status);
    if (!isError(statusComponent)) {
      qs = `?${statusComponent}`;
    }
  }
  return `${url}/api/${orgAlias}/rates${qs}`;
};
export const ratesFetchRatesOfUserApiUrl = (orgAlias, userId, status, url = '') => {
  let qs = '';

  if (!isEmpty(status)) {
    const statusComponent = prepareQueryStringComponent('status', status);
    if (!isError(statusComponent)) {
      qs = `?${statusComponent}`;
    }
  }

  return `${url}/api/${orgAlias}/rates/user/${userId}${qs}`;
};
export const ratesFetchRatesOfUsersApiUrl = (orgAlias, userIds, status, url = '') => {
  const qsParts = [];
  const components = { status, userIds };

  Object.keys(components).forEach((key) => {
    const component = components[key];
    if (isEmpty(component)) {
      return;
    }

    const qsComponent = prepareQueryStringComponent(key, component);

    if (!isError(qsComponent)) {
      qsParts.push(qsComponent);
    }
  });

  const qs = !isEmpty(qsParts) ? `?${qsParts.join('&')}` : '';
  return `${url}/api/${orgAlias}/rates/users${qs}`;
};
export const ratesFetchRateApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}`
);
export const ratesCreateApiUrl = orgAlias => (
  `/api/${orgAlias}/rates/create`
);
export const ratesProposeApiUrl = orgAlias => (
  `/api/${orgAlias}/rates/propose`
);
export const ratesCancelApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}/cancel`
);
export const ratesDeleteApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}`
);
export const ratesEditApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}/edit`
);
export const ratesApproveApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}/approve`
);
export const ratesDeclineApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}/reject`
);
export const ratesRenameApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}/rename`
);
export const rateSetAsDefaultApiUrl = (orgAlias, rateId, url = '') => (
  `${url}/api/${orgAlias}/rates/${rateId}/set-as-default`
);
