import { map, pick } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import SelectableListFilterField from 'core/assets/js/components/FilterFields/SelectableListFilterField.jsx';
import SearchForm from 'core/assets/js/components/SearchForm.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import { paginationSpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { REPORT_TYPES, BUDGETS_TABS } from 'finance/assets/js/constants';
import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import {
  fetchProjectsBudgetListDS,
} from 'finance/assets/js/data-services/list';
import { financeProjectsBudgetListApiUrl } from 'finance/urls';
import ProjectsBudgetTable from 'finance/assets/js/components/ProjectsBudgetTable.jsx';
import { PROJECT_STATUS_OPTIONS } from 'projects/assets/js/constants';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import BudgetsHeader from './components/BudgetsHeader.jsx';

const SEARCH_SPEC = {
  filters: [{
    fieldComponent: SelectableListFilterField,
    label: 'Status',
    multiple: true,
    options: PROJECT_STATUS_OPTIONS,
    paramName: 'status',
  }],
  searchTerm: {},
};

class ProjectsBudget extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return Promise.all([
      dispatch(fetchProjectsBudgetListDS({ url, querystring, componentName, params, authedAxios })),
    ]);
  }

  static GetComponentName() {
    return 'ProjectsBudget';
  }

  render() {
    const {
      filtersOpen,
      location,
      match: { params: { orgAlias } },
      onFiltersToggle,
      pagination,
      projectsBudgets,
      searchActive,
    } = this.props;

    const emptyText = 'No projects with approved Worksheets found';

    const initialValues = pick(
      queryString.parse(location.search), map(SEARCH_SPEC.filters, 'paramName'),
    );

    return (
      <React.Fragment>
        <BudgetsHeader tab={BUDGETS_TABS.PROJECT_BUDGET} />

        <div className="page page--finance">
          <div className="container">
            <div className="row">
              <SearchForm
                filtersOpen={filtersOpen}
                name="project-budget"
                initialValues={initialValues}
                onFiltersToggle={onFiltersToggle}
                searchSpec={SEARCH_SPEC}
              />
              {!filtersOpen && (
                <ListActionsButton
                  options={[{
                    name: 'Export',
                    onClick: () => {
                      const qs = queryString.stringify({
                        ...queryString.parse(location.search), csv: 1,
                      });
                      const exportUrl = `${financeProjectsBudgetListApiUrl(orgAlias)}?${qs}`;
                      WINDOW_OPEN(exportUrl);
                    },
                  }]}
                />
              )}
            </div>
            <div className="row">
              {searchActive && (
                <ReportSearch reportType={REPORT_TYPES.PROJECT_BUDGET} />
              )}
            </div>

            {!filtersOpen && (
              <TDApiConnected duck="list" component={this.constructor} skeletonComponent={FinanceTableSkeleton}>
                <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive  finance-report__table-wrapper--list">
                  <ProjectsBudgetTable projects={projectsBudgets || []} emptyText={emptyText} />
                </div>
                <TDPagination {...pagination} />
              </TDApiConnected>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectsBudget.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  projectsBudgets: PropTypes.array,
  searchActive: PropTypes.bool.isRequired,
};

ProjectsBudget.defaultProps = {
  projectsBudgets: [],
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ProjectsBudget.GetComponentName());
  return {
    projectsBudgets: listState.items,
    searchActive: listState.search.isActive,
    pagination: listState.pagination,
  };
};

export default withRouter(withFilters(connect(mapStateToProps)(ProjectsBudget)));
