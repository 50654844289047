import * as urls from 'rates/urls';
import * as dataServices from 'rates/assets/js/data-services/rates';
import RateList from 'rates/assets/js/components/RateList.jsx';


const components = {
  RateList,
};

export {
  components,
  dataServices,
  urls,
};
