import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { listFetchAC, listFetchExtrasAC, paginationFetchAC, searchFetchAC } from 'core/assets/js/ducks/list';
import {
  getUsedTimeTrackingApiUrl,
  projectMembersListApiUrl,
  projectApplicationsListApiUrl,
  projectAnswersApiUrl,
} from 'projects/urls';


export const fetchProjectMembersListDS = ({ //eslint-disable-line
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => projectMembersListApiUrl(params.orgAlias, projectId, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData.projectMembers, componentName),
      listFetchExtrasAC(responseData.accessControl, componentName, 'accessControl'),
      listFetchExtrasAC(responseData.rateLimits, componentName, 'rateLimits'),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchProjectApplicationsListDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => projectApplicationsListApiUrl(params.orgAlias, projectId, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData.applications, componentName),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchProjectAnswers = ({
  orgAlias, projectId, url = '', componentName, authedAxios = null, querystring = '',
}) => (
  dispatch => dispatch(
    fetchDataDS({
      authedAxios,
      querystring,
      componentName,
      fetchApiUrl: () => projectAnswersApiUrl(orgAlias, projectId, url),
      fetchDataAC: responseData => [
        listFetchAC(responseData, componentName),
      ],
    }),
  )
);

export const fetchUsedTimeTrackerListDS = ({
  componentName, orgAlias, authedAxios = null, querystring = '',
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    fetchApiUrl: () => getUsedTimeTrackingApiUrl(orgAlias),
    fetchDataAC: responseData => listFetchAC(responseData, componentName),
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
    querystring,
  })
);
