import PropTypes from 'prop-types';
import React from 'react';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ListSkeleton = ({ className }) => (
  <div
    className={['skeleton--list-view', className].join(' ')}
    data-testid="skeleton--list-view"
  >
    <div className="skeleton--list-view-item clearfix">
      <RectangleSkeleton width="300" height="25" />
      <br />
      <br />
      <RectangleSkeleton width="183" height="15" />
      <RectangleSkeleton width="100" height="15" />
      <br />
      <br />
      <RectangleSkeleton width="183" height="15" />
      <RectangleSkeleton width="150" height="15" />
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
);

ListSkeleton.propTypes = {
  className: PropTypes.string,
};

ListSkeleton.defaultProps = {
  className: undefined,
};

export default ListSkeleton;
