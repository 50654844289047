import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import Recaptcha from 'react-google-recaptcha';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Big from 'big.js';

import {
  API_DATE_FORMAT,
  BS_TOOLTIP_PLACEMENT,
  CURRENCIES_WITHOUT_DECIMALS,
  CURRENCY_BY_SYMBOL,
  CURRENCY_SYMBOL,
  CURRENCY_VALUES,
  DATE_FORMAT_DEFAULT,
  ICON,
  TEXT_AREA_DEFAULT_HEIGHT,
} from 'core/assets/js/constants';
import { isAmount } from 'core/assets/js/lib/utils';
import MarkdownTextArea from 'core/assets/js/components/MarkdownTextArea.jsx';
import MarkdownInput from 'core/assets/js/components/ReduxFormFields/MarkdownInput.jsx';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import Logger from 'core/assets/js/lib/Logger';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { GOOGLE } from 'core/assets/js/config/settings';

const logger = new Logger('inputfield');

export const INPUT_TYPE = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  MONEY: 'money',
  MONEY_INT: 'money:int',
  CAPTCHA: 'captcha',
  MARKDOWN_INPUT: 'markdown-input',
  MARKDOWN_TEXTAREA: 'markdown-textarea',
  NUMBER: 'number',
};

/**
 * Redux form compatible input field
 *
 * It displays a bootstrap decorated input field with a label and an error
 * message
 */
const InputField = ({
  additionalError,
  autocomplete,
  className,
  currency,
  copyEnabled,
  'data-testid': dataTestId,
  extraInfo,
  groupClassName,
  height,
  input,
  label,
  maxLength,
  meta,
  minDate,
  moneyDecimals,
  muteErrorMessage,
  placeholder,
  prefix,
  required,
  sublabel,
  suffix,
  templateSelectComponent,
  type,
  viewDate,
  ...rest
}) => {
  let field;
  const { error, pristine } = meta;
  const containerClassName = ['form-group', `input--${type}`];

  if (groupClassName) {
    containerClassName.push(groupClassName);
  }
  if ((error) || (pristine && additionalError)) {
    containerClassName.push('has-error');
  }

  let finalDecimals = moneyDecimals;
  let finalCurrency = currency;
  let finalPrefix = prefix;

  if (!currency && (prefix || suffix)) {
    finalCurrency = CURRENCY_BY_SYMBOL[prefix || suffix];
  }
  if (finalCurrency && !prefix && !suffix) {
    finalPrefix = CURRENCY_SYMBOL[finalCurrency];
  }

  if (type === INPUT_TYPE.MARKDOWN_TEXTAREA) {
    field = (
      <MarkdownTextArea input={input} placeholder={placeholder} {...rest} />
    );
  } else if (type === INPUT_TYPE.TEXTAREA) {
    field = (
      <textarea
        id={`fieldId-${input.name}`}
        {...input}
        placeholder={placeholder}
        className={['form-control', className].join(' ')}
        {...rest}
      />
    );
  } else if (type === INPUT_TYPE.CHECKBOX) {
    field = (
      <div className="form-check">
        <input
          type="checkbox"
          id={`fieldId-${input.name}`}
          {...input}
          className={['form-check-input', className].join(' ')}
          {...rest}
        />
        <label
          className="form-check-label"
          htmlFor={`fieldId-${input.name}`}
        >
          {label}
        </label>
      </div>
    );
  } else if (type === INPUT_TYPE.DATE) {
    const { name, value, onChange } = input;
    const { disabled, disablePastDates, disableBeforeDate, disableAfterDate, isValid } = rest;
    const yesterday = Datetime.moment().subtract(1, 'day');
    let isValidDate;

    // TODO rest operator here (rest: { rest: {...} }), fix logic when we remove rest operators
    if (disablePastDates && !minDate) {
      isValidDate = current => current.isAfter(yesterday);
    } else if (disablePastDates && minDate) {
      isValidDate = current => current.isSameOrAfter(minDate);
    } else if (disableBeforeDate && disableAfterDate) {
      isValidDate = current => (
        current.startOf('day').isSameOrAfter(disableBeforeDate.startOf('day'))
          && current.startOf('day').isSameOrBefore(disableAfterDate.startOf('day'))
      );
    }

    if (isValid) {
      isValidDate = isValid;
    }

    field = (
      <Datetime
        closeOnSelect
        dateFormat={DATE_FORMAT_DEFAULT}
        initialValue={
          moment(value, API_DATE_FORMAT, true).isValid()
            ? moment(value)
            : value
        }
        inputProps={{
          autoComplete: 'off',
          className: 'form-control',
          id: `fieldId-${name}`,
          name,
          placeholder,
          disabled,
        }}
        isValidDate={isValidDate}
        onChange={(selectedDate) => {
          if (typeof selectedDate === 'string') {
            // selectDate is not a valid moment object.
            if (selectedDate === '') {
              onChange(null);
            } else {
              onChange(selectedDate);
            }
            return;
          }
          // selectDate is a momentObject.
          onChange(selectedDate.format(API_DATE_FORMAT));
        }}
        timeFormat={false}
        viewDate={viewDate}
      />
    );
  } else if (type.indexOf(INPUT_TYPE.MONEY) === 0) {
    const { min, max } = rest;
    let { step } = rest;
    const noDecimals = CURRENCIES_WITHOUT_DECIMALS.includes(finalCurrency);
    if (noDecimals || type === INPUT_TYPE.MONEY_INT) {
      step = 1;
      finalDecimals = 0;
      if (input && input.value) {
        input.value = parseInt(input.value, 10); //eslint-disable-line
      }
    }

    field = (
      <input
        id={`fieldId-${input.name}`}
        {...input}
        placeholder={placeholder}
        type="number"
        step={step}
        min={min}
        max={max}
        className={['form-control', className].join(' ')}
        onBlur={(e) => {
          if (isAmount(e.target.value)) {
            e.target.value = new Big(e.target.value).toFixed(finalDecimals);
          } else {
            // If the value includes any of the chars '-', 'e', 'E', just empty the value.
            // Notice that the above characters are valid for the input with type number.
            e.target.value = '';
          }

          input.onChange(e);
          input.onBlur();
        }}
        // Prevent scrolling on the input, to prevent accidental increment/decrement
        onWheel={e => e.target.blur()}
        disabled={rest.disabled}
      />
    );
  } else if (type === INPUT_TYPE.CAPTCHA) {
    const recaptchaKey = GOOGLE.recaptchaKey;
    field = (<Recaptcha sitekey={recaptchaKey} onChange={input.onChange} />);
  } else if (type === INPUT_TYPE.MARKDOWN_INPUT) {
    field = (
      <MarkdownInput
        placeholder={placeholder}
        className={className}
        autoComplete={autocomplete}
        inputField={input}
        rest={rest}
      />
    );
  } else {
    field = (
      <input
        data-testid="review-form-add-comment"
        id={`fieldId-${input.name}`}
        {...input}
        placeholder={placeholder}
        type={type}
        autoComplete={autocomplete}
        className={['form-control', className].join(' ')}
        {...rest}
        onChange={(e) => {
          input.onChange(e);
        }}
        maxLength={maxLength}
      />
    );
  }

  if (finalPrefix || suffix) {
    field = (
      <div className="input-group">
        { finalPrefix && (
          <div className="input-group-prepend">
            <span className="input-group-text">{finalPrefix}</span>
          </div>
        )}
        {field}
        { suffix && (
          <div className="input-group-append">
            <span className="input-group-text">{suffix}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={containerClassName.join(' ')} data-testid={dataTestId}>
      { label && type !== INPUT_TYPE.CHECKBOX && (
        <div className={templateSelectComponent ? 'd-block d-sm-flex align-items-end' : ''}>
          <label htmlFor={`fieldId-${input.name}`}>
            <div>
              {label}
              {required && ' *'}
              {sublabel && (
                <span className="sublabel">
                  {' '}
                  {sublabel}
                </span>
              )}
            </div>
          </label>
          { extraInfo && (
            <TDElementWithTooltip
              el={<span className="ml-2"><i className={ICON.INFO} /></span>}
              placement={BS_TOOLTIP_PLACEMENT.TOP}
              tooltipMsg={extraInfo}
            />
          )}

          { templateSelectComponent && (
            <div className="ml-auto text-right mb-2">
              {templateSelectComponent}
            </div>
          )}
        </div>
      )}
      {field}
      { pristine && additionalError && !muteErrorMessage && (
        <span className="help-block mt-3">{additionalError}</span>
      )}
      { copyEnabled && (
        <CopyToClipboard text={input.value} onCopy={() => logger.info('Copied to clipboard')}>
          <button type="button" onClick={e => e.preventDefault()}>Copy</button>
        </CopyToClipboard>
      )}
      {error && !muteErrorMessage && <span className="help-block mt-3">{error}</span>}
    </div>
  );
};

InputField.propTypes = {
  additionalError: PropTypes.string,
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  copyEnabled: PropTypes.bool,
  currency: PropTypes.oneOf(CURRENCY_VALUES),
  'data-testid': PropTypes.string,
  extraInfo: PropTypes.string,
  groupClassName: PropTypes.string,
  height: PropTypes.number,
  input: reduxInputSpec.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  max: PropTypes.number,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({ error: PropTypes.string, pristine: PropTypes.bool }),
  min: PropTypes.number,
  minDate: PropTypes.object,
  moneyDecimals: PropTypes.number,
  muteErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  step: PropTypes.number,
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  suffix: PropTypes.string,
  templateSelectComponent: PropTypes.node,
  type: PropTypes.string,
  // Initial active date on calendar
  viewDate: PropTypes.object,
};

InputField.defaultProps = {
  additionalError: '',
  autocomplete: 'off',
  className: '',
  copyEnabled: false,
  currency: null,
  'data-testid': '',
  extraInfo: '',
  groupClassName: '',
  height: TEXT_AREA_DEFAULT_HEIGHT,
  label: '',
  placeholder: '',
  prefix: null,
  max: null,
  maxLength: null,
  meta: { error: '', pristine: true },
  min: 1,
  minDate: null,
  moneyDecimals: 2,
  muteErrorMessage: false,
  required: false,
  step: 1,
  sublabel: '',
  suffix: null,
  templateSelectComponent: null,
  type: 'text',
  viewDate: undefined,
};

export default InputField;
