import React from 'react';

import BillingHistoryTable from 'finance/assets/js/components/BillingHistoryTable.jsx';

const BillingTabHistory = () => (
  <div className="row">
    <div className="col-12">
      <BillingHistoryTable />
    </div>
  </div>
);

export default BillingTabHistory;
