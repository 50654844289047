import React from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';
import { withRouter } from 'react-router-dom';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { Dropdown } from 'react-bootstrap';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { orgInvitationTemplatesApiUrl, orgTemplatesManageUrl } from 'settings/urls';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

export const COMPONENT_NAME = 'InvitationTemplatesList';

/**
 * Used for apply inline changes
 * @param label tet used in label
 * @param templates list of template items
 * @param onSelect on template select callback function
 * @returns {*}
 */
function InterviewTemplateSelect({
  label, items, onSelect, history, match: { params: { orgAlias } },
}) {
  return (
    <TDDropButton
      className="text-primary small"
      overlayClassName="invitation-template-select"
      toggleEl={(
        <>
          {label}
          {' '}
          <i className={ICON.CHEVRON_DOWN} />
        </>
      )}
    >
      <div className="scrollable-list">
        { items && items.map(item => (
          <Dropdown.Item
            className="py-3"
            eventKey={item.id}
            key={item.id}
            onClick={() => onSelect(item)}
          >
            <span className="text-truncate">{item.title}</span>
          </Dropdown.Item>
        ))}
      </div>

      {items && items.length > 0 && (
        <Dropdown.Divider />
      )}

      <Dropdown.Item
        className="py-2"
        eventKey="manage-templates"
        onClick={() => history.push(orgTemplatesManageUrl(orgAlias))}
      >
        Manage templates
      </Dropdown.Item>

    </TDDropButton>
  );
}

InterviewTemplateSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,

};

InterviewTemplateSelect.defaultProps = {
  label: 'Use a template',
};

const InterviewTemplateSelectApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, url, authedAxios, componentName, querystring, params,
  }) => dispatch(fetchListDS({
    authedAxios, componentName, querystring, params,
    url: orgInvitationTemplatesApiUrl(params.orgAlias, url),
  })),
  storeKey: COMPONENT_NAME,
  duck: 'list',
})(InterviewTemplateSelect);

export default withRouter(InterviewTemplateSelectApiConnected);
