import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import { DATE_FORMAT_DEFAULT, DATETIME_FORMAT_DEFAULT, TIME_FORMAT, API_DATE_FORMAT } from 'core/assets/js/constants';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';


const DatePickerField = ({
  additionalError,
  'data-testid': dataTestId,
  disableAfterDate,
  disableBeforeDate,
  disabled,
  disableFutureDates,
  disablePastDates,
  input,
  isValid,
  label,
  labelPopOverContent,
  labelPopOverTitle,
  meta: { error, pristine, submitError },
  monthSelection,
  placeholder,
  required,
  sublabel,
  viewDate,
  timeSelectionEnabled,
}) => {
  const classNames = ['form-group'];
  const showAdditionalError = pristine && additionalError;
  if (submitError || error || showAdditionalError) {
    classNames.push('has-error');
  }

  const today = Datetime.moment();
  const yesterday = Datetime.moment().subtract(1, 'day');
  let _isValid;
  const validators = [];
  const apiFormat = `${API_DATE_FORMAT}${timeSelectionEnabled ? ` ${TIME_FORMAT}` : ''}`;

  if (disableFutureDates) {
    validators.push(current => current.isSameOrBefore(today));
  }

  if (disablePastDates) {
    validators.push(current => current.isAfter(yesterday));
  }

  if (disableBeforeDate) {
    validators.push(
      current => current.startOf('day').isSameOrAfter(disableBeforeDate.startOf('day')),
    );
  }

  if (disableAfterDate) {
    validators.push(
      current => current.startOf('day').isSameOrBefore(disableAfterDate.startOf('day')),
    );
  }

  if (isValid) {
    _isValid = isValid;
  } else {
    // if the "force" isValid prop is not provided, we need to run the validators
    _isValid = current => validators.every(func => func(current));
  }

  const inputName = `field-${input.name}`;

  const dateFormat = monthSelection ? 'YYYY-MM' : DATE_FORMAT_DEFAULT;
  const valueAsMoment = moment(
    input.value,
    apiFormat,
    true,
  ).isValid()
    ? moment(input.value)
    : null;

  let valueFormat = dateFormat;

  if (timeSelectionEnabled) {
    valueFormat = DATETIME_FORMAT_DEFAULT;
  }

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <TDLabel
        label={label}
        name={input.name}
        popOverContent={labelPopOverContent}
        popOverTitle={labelPopOverTitle}
        required={required}
        sublabel={sublabel}
      />
      {showAdditionalError && (
        <span className="help-block mt-3">{additionalError}</span>
      )}

      <Datetime
        closeOnSelect={!timeSelectionEnabled}
        dateFormat={dateFormat}
        inputProps={{
          autoComplete: 'off',
          className: 'form-control',
          disabled,
          id: inputName, // so screen readers can pick up the label
          name: inputName,
          placeholder,
          value: valueAsMoment ? valueAsMoment.format(valueFormat) : input.value,
        }}
        isValidDate={_isValid}
        onChange={(selectedDate) => {
          if (typeof selectedDate === 'string') {
            // selectDate is not a valid moment object.
            if (selectedDate === '') {
              input.onChange(null);
              return;
            }
            return;
          }
          // selectDate is a momentObject.
          input.onChange(selectedDate.format(apiFormat));
        }}
        timeFormat={timeSelectionEnabled ? TIME_FORMAT : false}
        value={valueAsMoment || input.value}
        viewDate={viewDate}
      />

      {error && <span className="help-block d-inline-block mt-3">{error}</span>}
      {submitError && <span className="help-block d-inline-block mt-3">{submitError}</span>}
    </div>
  );
};


DatePickerField.propTypes = {
  additionalError: PropTypes.string,
  'data-testid': PropTypes.string,
  disableAfterDate: PropTypes.object,
  disableBeforeDate: PropTypes.object,
  disableFutureDates: PropTypes.bool,
  timeSelectionEnabled: PropTypes.bool,
  disablePastDates: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  isValid: PropTypes.func,
  label: finalFormFieldLabelSpec,
  labelPopOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelPopOverTitle: PropTypes.string,
  meta: finalFormFieldMetaSpec,
  monthSelection: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  // Initial active date on calendar
  viewDate: PropTypes.object,
};

DatePickerField.defaultProps = {
  additionalError: '',
  'data-testid': 'date-picker-field',
  disableAfterDate: null,
  disableBeforeDate: null,
  disabled: false,
  timeSelectionEnabled: false,
  disableFutureDates: false,
  disablePastDates: false,
  isValid: undefined,
  label: '',
  labelPopOverContent: null,
  labelPopOverTitle: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  monthSelection: false,
  placeholder: 'Click to select a date',
  required: false,
  sublabel: null,
  viewDate: undefined,
};

export default withField(DatePickerField);
