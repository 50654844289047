import React from 'react';
import PropTypes from 'prop-types';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const FinanceTableSkeleton = ({ responsive }) => {
  if (responsive) {
    // tableClassNames.push('react-bs-table--responsive');
  }
  return (
    <div className="skeleton--finance-table finance-report__table-wrapper finance-report__table-wrapper--list finance-report__table-wrapper--responsive">
      <div className="react-bs-table-container react-bs-table--responsive">
        <div className="react-bs-table react-bs-table-bordered">
          <div className="react-bs-container-header table-header-wrapper">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th><RectangleSkeleton width="180" height="20" /></th>
                  <th><RectangleSkeleton width="120" height="20" /></th>
                  <th><RectangleSkeleton width="80" height="20" /></th>
                  <th><RectangleSkeleton width="135" height="20" /></th>
                  <th><RectangleSkeleton width="135" height="20" /></th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="react-bs-container-body">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <RectangleSkeleton width="90" height="10" />
                      <RectangleSkeleton width="40" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="123" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="90" height="10" />
                      <RectangleSkeleton width="60" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="50" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="50" height="10" />
                      <RectangleSkeleton width="30" height="10" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <RectangleSkeleton width="110" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="180" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="90" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="70" height="10" />
                      <RectangleSkeleton width="20" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="50" height="10" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <RectangleSkeleton width="90" height="10" />
                      <RectangleSkeleton width="40" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="123" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="90" height="10" />
                      <RectangleSkeleton width="60" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="50" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="50" height="10" />
                      <RectangleSkeleton width="30" height="10" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <RectangleSkeleton width="110" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="180" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="90" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="70" height="10" />
                      <RectangleSkeleton width="20" height="10" />
                    </div>
                  </td>
                  <td>
                    <div>
                      <RectangleSkeleton width="50" height="10" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
FinanceTableSkeleton.propTypes = {
  responsive: PropTypes.bool,
};

FinanceTableSkeleton.defaultProps = {
  responsive: true,
};

export default FinanceTableSkeleton;
