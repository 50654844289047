/* globals FormData */
import { omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BS_STYLE, ICON, ORDERING_DIRECTION } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { fetchListDS } from 'core/assets/js/ducks/list';
import {
  getIsModalOpen, getModalPayload, modalOpenAC, modalCloseAC,
} from 'core/assets/js/ducks/modalLauncher';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { formatDate } from 'core/assets/js/lib/utils';
import { downloadFileApiUrl } from 'files/urls';
import {
  US_TAX_FILING_TABS,
  US_TAX_FORM_STATUS,
  US_TAX_FORM_STATUS_CLASS,
  US_TAX_FORM_STATUS_LABEL,
  US_TAX_FORM_STATUS_VALUES,
  US_TAX_FORM_TYPE_LABEL,
  US_TAX_FORM_TYPE,
} from 'finance/assets/js/constants';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import {
  finance1099FilingsUrl,
  financeGetW8TaxFormUsersApiUrl,
  financeGetW9TaxFormUsersApiUrl,
  financeReviewUSTaxFormUrl,
  financeUploadUSTaxFormApiUrl,
} from 'finance/urls';
import { orgUserProfileUrl } from 'people/urls';

const orderingOptions = [
  { text: 'Created at', value: 'createdAt' }, { text: 'Last name', value: 'lastName' },
];
const defaultOrdering = { direction: ORDERING_DIRECTION.DESC, sortBy: 'createdAt' };

const REJECTED_REASON_MODAL_ID = 'user-us-tax-form-rejected-reason-modal-id';
const UPLOAD_MODAL_ID = 'user-us-tax-form-upload-modal-id';

const USTaxForms = ({ filtersOpen, location: { search }, match: { params }, onFiltersToggle }) => {
  const componentName = USTaxForms.GetComponentName(params);
  const isW8 = params.tab === US_TAX_FILING_TABS['W-8_FORMS'];
  const url = (isW8 ? financeGetW8TaxFormUsersApiUrl : financeGetW9TaxFormUsersApiUrl)(
    params.orgAlias,
  );
  const { hasLoaded, isLoading, items, pagination, search: { isActive } } = fetchDataHook({
    componentName, duck: 'list', url,
  });
  const history = useHistory();
  const rejectedReasonModalIsOpen = useSelector(
    state => getIsModalOpen(state, REJECTED_REASON_MODAL_ID),
  );
  const rejectedReasonModalPayload = useSelector(
    state => getModalPayload(state, REJECTED_REASON_MODAL_ID),
  );
  const uploadModalIsOpen = useSelector(state => getIsModalOpen(state, UPLOAD_MODAL_ID));
  const uploadModalPayload = useSelector(state => getModalPayload(state, UPLOAD_MODAL_ID));
  const dispatch = useDispatch();
  const formRef = useRef(null);

  useEffect(() => {
    if (uploadModalIsOpen && formRef.current) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      formRef.current = null;
    }
  }, [uploadModalIsOpen]);

  if (!hasLoaded || isLoading) {
    return <FinanceTableSkeleton />;
  }

  const parsedQueryString = queryString.parse(search);

  const getReviewUrl = userUSTaxFormId => [
    financeReviewUSTaxFormUrl(params.orgAlias, userUSTaxFormId),
    '?backUrl=',
    finance1099FilingsUrl(params.orgAlias, params.tab),
  ].join('');

  return (
    <>
      {isActive && (
        <SearchFinalForm
          className="px-0 mt-5"
          initialValues={{
            ...pick(parsedQueryString, 'kw', 'status', '1099FilingRequired'),
            ordering: parsedQueryString.ordering || defaultOrdering,
          }}
          searchSpec={{
            defaultOrdering,
            orderingOptions,
            searchTerm: {
              component: TextInputField,
              paramName: 'kw',
              placeholder: 'Search contractors',
            },
            filters: [
              // Note: Ordering filter is only shown on mobile devices.
              {
                fieldComponent: SelectableListFilterField,
                isOrdering: true,
                label: 'Sort By',
                options: orderingOptions,
                multiple: false,
                paramName: 'ordering',
              },
              {
                fieldComponent: SelectableListFilterField,
                label: 'Status',
                multiple: true,
                options: US_TAX_FORM_STATUS_VALUES.map(value => ({
                  text: US_TAX_FORM_STATUS_LABEL[value], value,
                })),
                paramName: 'status',
              },
              {
                fieldComponent: SelectableListFilterField,
                label: '1099 required',
                multiple: false,
                options: [{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }],
                paramName: '1099FilingRequired',
              },
            ],
          }}
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
        />
      )}
      <Table
        cols={[
          {
            dataFormat: (contractorName, { userId, userType }) => (
              <Link to={orgUserProfileUrl(params.orgAlias, userType, userId)}>
                {contractorName}
              </Link>
            ),
            key: 'contractorName',
            label: 'Name',
          },
          { key: 'addressDescription', label: 'Address' },
          { key: 'taxIdentificationNumber', label: 'Tax id' },
          {
            dataFormat: effectiveDate => formatDate(effectiveDate),
            key: 'effectiveDate',
            label: 'Effective date',
          },
          {
            dataFormat: required => <i className={required ? ICON.CHECKMARK : ICON.CROSS} />,
            key: '1099Required',
            label: '1099 required',
          },
          {
            dataFormat: status => (
              <StatusTag
                label={US_TAX_FORM_STATUS_LABEL[status]}
                statusClass={US_TAX_FORM_STATUS_CLASS[status]}
              />
            ),
            key: 'status',
            label: 'Status',
          },
          {
            dataAlign: 'right',
            dataFormat: (
              userUSTaxFormId,
              { allowedActions, contractorName, formFileId, rejectedReason, status, userId },
            ) => {
              const actions = [];
              if (status === US_TAX_FORM_STATUS.PENDING_REVIEW) {
                actions.push({
                  label: 'Review',
                  onClick: () => history.push(getReviewUrl(userUSTaxFormId)),
                });
              }
              if (allowedActions.canEdit) {
                actions.push({
                  label: 'Edit',
                  onClick: () => history.push(getReviewUrl(userUSTaxFormId)),
                });
              }
              if (allowedActions.canUpload || allowedActions.canReplace) {
                actions.push({
                  label: allowedActions.canUpload ? 'Upload' : 'Replace',
                  onClick: () => dispatch(modalOpenAC(
                    UPLOAD_MODAL_ID, { contractorName, userId },
                  )),
                });
              }
              if (formFileId) {
                actions.push({
                  label: 'Download',
                  onClick: () => WINDOW_OPEN(downloadFileApiUrl(formFileId)),
                });
              }
              if (rejectedReason) {
                actions.push({
                  label: 'Rejected reason',
                  onClick: () => dispatch(modalOpenAC(
                    REJECTED_REASON_MODAL_ID, { rejectedReason },
                  )),
                });
              }
              return <SplitButton actions={actions} primaryButtonVariant={BS_STYLE.DEFAULT} />;
            },
            key: 'userUSTaxFormId',
            label: '',
          },
        ]}
        containerClass="us-tax-forms-table"
        items={items}
      />
      <TDPagination {...pagination} />
      <ModalSimple
        body={rejectedReasonModalPayload?.rejectedReason}
        heading="Rejected reason"
        onClose={() => dispatch(modalCloseAC())}
        open={rejectedReasonModalIsOpen}
      />
      <ModalConfirm
        body={(
          <Form
            initialValues={{}}
            onSubmit={() => null}
            render={({ form, handleSubmit, submitting }) => {
              if (!formRef.current) {
                // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is broken
                // https://github.com/final-form/react-final-form/issues/483
                formRef.current = form;
              }
              const selectedType = formRef.current?.getState().values.type;
              const type = isW8 ? selectedType : US_TAX_FORM_TYPE['W-9'];
              return (
                <form onSubmit={handleSubmit}>
                  {isW8 && (
                    <SelectField
                      disabled={submitting}
                      label="Select which W-8 form to upload"
                      name="type"
                      optionsMapping={
                        [US_TAX_FORM_TYPE['W-8BEN'], US_TAX_FORM_TYPE['W-8BEN-E']].map(value => ({
                          text: US_TAX_FORM_TYPE_LABEL[value], value,
                        }))
                      }
                      required
                    />
                  )}
                  {(!isW8 || selectedType) && (
                    <FileUploaderDirectField
                      accept={['application/pdf']}
                      label={`Select ${US_TAX_FORM_TYPE_LABEL[type]} form and upload it`}
                      name="file"
                    />
                  )}
                </form>
              );
            }}
          />
        )}
        confirmLabel="Upload"
        heading={[
          `Upload W-${isW8 ? '8' : '9'} form for `,
          uploadModalPayload?.contractorName || 'contractor',
        ].join('')}
        onClose={() => dispatch(modalCloseAC())}
        onConfirm={async () => {
          if (!uploadModalPayload?.userId || !formRef.current) {
            return;
          }
          try {
            const { values } = formRef.current.getState();
            const type = isW8 ? values.type : US_TAX_FORM_TYPE['W-9'];
            const formData = new FormData();
            formData.append('file', values.file);
            formData.append('type', type);
            await axios.post(
              financeUploadUSTaxFormApiUrl(params.orgAlias, uploadModalPayload.userId), formData,
            );
            toastr.success(
              'Well Done!',
              [
                `You have uploaded a ${US_TAX_FORM_TYPE_LABEL[type]} form for `,
                uploadModalPayload.contractorName,
              ].join(''),
            );
            dispatch(fetchListDS({ componentName, url: `${url}${search}` }));
          } catch (e) {
            let error =  e.response?.data?._error || e.message;
            const propertyErrors = omit(e.response?.data, '_error', '_meta');
            const propertyErrorKeys = Object.keys(propertyErrors);
            if (propertyErrorKeys.length > 0) {
              error = propertyErrorKeys.map(key => `${key}: ${propertyErrors[key]}`).join('; ');
            }
            toastr.error('Oh Snap!', error);
            // Throw the error to prevent the modal closing
            throw e;
          }
        }}
        open={uploadModalIsOpen}
      />
    </>
  );
};

USTaxForms.GetComponentName = params => `USTaxForms-${params.tab}`;

USTaxForms.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withFilters(withRouter(USTaxForms));
