import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import AnswerRenderer from 'core/assets/js/components/AnswerRenderer.jsx';

const AnswersList = ({ answers, labelClass, valueClass, wrapperClass }) => {
  if (isEmpty(answers)) {
    return null;
  }

  return answers.map(answer => (
    <AnswerRenderer
      key={`answer-${answer.id}`}
      answer={answer}
      labelClass={labelClass}
      valueClass={valueClass}
      wrapperClass={wrapperClass}
    />
  ));
};

AnswersList.propTypes = {
  answers: PropTypes.array,
  labelClass: PropTypes.string,
  valueClass: PropTypes.string,
  wrapperClass: PropTypes.string,
};

AnswersList.defaultProps = {
  answers: [],
  labelClass: '',
  valueClass: '',
  wrapperClass: '',
};

export default AnswersList;
