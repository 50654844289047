import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { orgSettingsSkillDirectoryManageUrl } from 'settings/urls';
import { deleteSkillDirectoryDS } from 'settings/assets/js/data-services/settings';
import { getIsModalOpen, modalOpenAC, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { pluralize } from 'core/assets/js/lib/utils';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { COMPONENT_NAME as skillDirectoryListComponentName } from 'skills/assets/js/components/SkillDirectoryList.jsx';

class SkillDirectoryCardPreview extends React.Component {
  constructor(props) {
    super(props);
    this.handleCloseDeleteDirectoryModal = this.handleCloseDeleteDirectoryModal.bind(this);
    this.handleDeleteSkillDirectory = this.handleDeleteSkillDirectory.bind(this);
    this.handleOpenDeleteDirectoryModal = this.handleOpenDeleteDirectoryModal.bind(this);
  }

  handleOpenDeleteDirectoryModal() {
    const { dispatch, deleteSkillDirectoryModalId } = this.props;
    dispatch(modalOpenAC(deleteSkillDirectoryModalId));
  }

  handleCloseDeleteDirectoryModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleDeleteSkillDirectory() {
    const { dispatch, item, match: { params: { orgAlias } } } = this.props;
    return dispatch(
      deleteSkillDirectoryDS({
        skillDirectoryId: item.id, componentName: skillDirectoryListComponentName, orgAlias,
      }),
    ).then(() => {
      this.handleCloseDeleteDirectoryModal();
      toastr.success('Well Done!', 'Your skill directory was deleted successfully.');
    });
  }

  render() {
    const {
      item, isDeleteSkillDirectoryModalOpen, hasOrgAccess, match: { params: { orgAlias } },
    } = this.props;
    const { name, skillsCount } = item;
    const skillsCountMessage = skillsCount === 0
      ? 'No skills added yet'
      : `${skillsCount} ${pluralize('skill', skillsCount)}`;
    const isAnyManager = hasOrgAccess({ requireManager: true });
    const editUrl = orgSettingsSkillDirectoryManageUrl(orgAlias, item.id);
    return (
      <li className="mb-3 p-4 border rounded bg-white">
        <div className="skill-directory-card--preview">
          <div className="row d-flex flex-nowrap align-items-center">
            <div className="col description w-100 d-flex align-items-center">
              <i className={`${ICON.FOLDERS_DUOTONE} mr-4`} />

              <div>
                <Link
                  to={editUrl}
                  className="text-dark"
                >
                  <h3 className="my-0">{name}</h3>
                </Link>
                <div className="discreet">{skillsCountMessage}</div>
              </div>
            </div>

            <div className="col-auto ml-auto">
              { isAnyManager && (
                <TDDropChevronButton>
                  <Dropdown.Item
                    eventKey="delete-group"
                    onClick={this.handleOpenDeleteDirectoryModal}
                    className="text-danger"
                  >
                    Delete
                  </Dropdown.Item>
                </TDDropChevronButton>
              )}
            </div>
          </div>
        </div>

        <ModalSimple
          body="Are you sure you want to delete this skill directory?"
          heading="Delete skill directory"
          onClose={this.handleCloseDeleteDirectoryModal}
          open={isDeleteSkillDirectoryModalOpen}
          footer={(
            <>
              <TDButton
                label="Cancel"
                onClick={this.handleCloseDeleteDirectoryModal}
              />
              <TDButton
                label="Delete"
                variant={BS_STYLE.DANGER}
                onClick={this.handleDeleteSkillDirectory}
              />
            </>
          )}
        />
      </li>
    );
  }
}

SkillDirectoryCardPreview.propTypes = {
  deleteSkillDirectoryModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isDeleteSkillDirectoryModalOpen: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    skillsCount: PropTypes.number,
    name: PropTypes.string,
    owner: PropTypes.object,
  }),
  match: routerMatchSpec.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
};

SkillDirectoryCardPreview.defaultProps = {
  isDeleteSkillDirectoryModalOpen: false,
  item: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state, props) => {
  const { item } = props;
  const deleteSkillDirectoryModalId = `delete-skill-directory-modal-id-${item.id}`;

  return {
    deleteSkillDirectoryModalId,
    isDeleteSkillDirectoryModalOpen: getIsModalOpen(state, deleteSkillDirectoryModalId),
    hasOrgAccess: getHasOrgAccess(state),
  };
};

const SkillDirectoryCardPreviewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkillDirectoryCardPreview);

export default withRouter(SkillDirectoryCardPreviewConnected);
