import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { BS_TOOLTIP_PLACEMENT, ICON } from 'core/assets/js/constants';
import MembersPreview from 'core/assets/js/components/MembersPreview.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import { PROJECT_STATUS } from 'projects/assets/js/constants';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectViewUrl } from 'projects/urls';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import BudgetProgressBar from 'projects/assets/js/components/BudgetProgressBar.jsx';
import ProjectProgress from 'projects/assets/js/components/ProjectProgress.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

const ProjectCardItem = ({ item, orgAlias }) => {
  const {
    budgetBreakdown,
    currency_symbol: currencySymbol,
    status_label: statusLabel,
    owner_name: ownerName,
    members,
    membersCount,
    deadline,
    id,
    skills,
    status,
    title,
    tasksCount,
    canViewProjectView,
    allowedActions: {
      canViewBudgets,
    },
  } = item;
  const cardClass = [];
  const url = projectViewUrl(orgAlias, id);

  let titleEl = (<Link to={url}>{title}</Link>);
  // HigherManagers can see all projects in their list but can't view the projects they
  // are not a member of
  if (!canViewProjectView) {
    titleEl = (
      <React.Fragment>
        <span key="title">{title}</span>
        &nbsp;
        <TDElementWithTooltip
          el={<i className={ICON.WARNING} />}
          placement={BS_TOOLTIP_PLACEMENT.TOP}
          key="tooltip"
          tooltipMsg={
            'You don\'t have access to the project because you have not been added yet or have been removed'
          }
        />
      </React.Fragment>
    );
    cardClass.push('card--disabled');
  }

  return (
    <Card className={cardClass.join(' ')}>
      <Card.Body>
        <div className="row">
          <div className="col-12 col-sm-8">
            <h3 data-testid="project-card-title" className="project-list__item--title">
              {titleEl}
            </h3>
          </div>
          <div className="col-12 col-sm-4 d-none d-sm-block">
            {members && (
              <MembersPreview
                showMoreButton={canViewProjectView}
                total={membersCount}
                url={canViewProjectView ? url : ''}
                users={members.map(uc => uc.user)}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            { ownerName && (
              <React.Fragment>
                <span className="discreet project-list__item--label">
                  Manager
                </span>
                <span>
                  {ownerName}
                </span>
              </React.Fragment>
            )}

            { tasksCount && (
              <span className="project-list__item--label ml-4">
                <i className={`${ICON.SUCCESS} mr-2 discreet`} />
                {tasksCount}
                {' '}
                {tasksCount === 1 ? 'Task' : 'Tasks'}
              </span>
            )}

            { deadline && (
              <span className="project-list__item__deadline float-right">
                <span className="discreet project-list__item--label">
                  Deadline
                </span>
                <span>{ formatDate(deadline, 'DD MMM, YYYY') }</span>
              </span>
            )}
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-12 col-sm-8 col-lg-8 mb-3 mb-md-0">
            <SkillList skills={skills} inline />
          </div>
          <div className="col-12 col-sm-4 col-lg-4">
            <div className="project-list__item--progress">
              <div className="project-list__item--status">
                { status === PROJECT_STATUS.INPROGRESS && budgetBreakdown.totalApproved
                  && canViewBudgets && (
                  <React.Fragment>
                    <NumberTpl
                      prefix={currencySymbol}
                      thousandSeparator
                      value={budgetBreakdown.used}
                    />
                    <span className="discreet">
                      {' '}
                      /
                      {' '}
                      <NumberTpl
                        prefix={currencySymbol}
                        thousandSeparator
                        value={budgetBreakdown.totalApproved - budgetBreakdown.refunded}
                      />
                    </span>
                  </React.Fragment>
                )}
                {(status !== PROJECT_STATUS.INPROGRESS || !budgetBreakdown.totalApproved)
                  && statusLabel
                }
              </div>

              <div className="project-list__item--progress__percentage">
                <ProjectProgress
                  budget={budgetBreakdown.totalApproved}
                  budgetUsed={budgetBreakdown.used}
                  status={status}
                />
              </div>
            </div>

            <BudgetProgressBar
              tooltip={false}
              tdStyle="wrapped"
              budget={budgetBreakdown.totalApproved || Infinity}
              budgetUsed={budgetBreakdown.used || 0}
              currencySymbol={currencySymbol}
              projectId={id}
              projectStatus={status}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

ProjectCardItem.propTypes = {
  item: projectSpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

export default ProjectCardItem;
