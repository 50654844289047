import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { IMG_SIZE } from 'core/assets/js/constants';

const MembersPreview = ({ showMoreButton, total, url, users }) => {
  if (!users || users.length === 0) {
    return <div className="members-preview members-preview--empty" />;
  }

  if (users.length > 5) {
    throw new Error('We expect a maximum of 5 users to be shown');
  }

  const remainingUsers = total - users.length;
  const membersList = users.map(user => {
    const profilePic = (
      <ProfilePic
        alt={user.profile.name}
        size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
        url={user.profile.avatar}
      />
    );
    return (
      <li key={user.id}>
        {url ? <Link to={url}>{profilePic}</Link> : <span>{profilePic}</span>}
      </li>
    );
  });

  return (
    <div className="members-preview d-flex justify-content-end align-items-center">
      <ul className="list-inline members-preview__list d-flex">{membersList}</ul>
      {showMoreButton && (remainingUsers > 0) && (
        <Link to={url} className="members-preview__more ml-1">
          {`+ ${remainingUsers}`}
        </Link>
      )}
    </div>
  );
};

MembersPreview.propTypes = {
  showMoreButton: PropTypes.bool,
  total: PropTypes.number,
  url: PropTypes.string,
  users: PropTypes.arrayOf(userSpec).isRequired,
};

MembersPreview.defaultProps = {
  showMoreButton: true,
  total: 0,
  url: '',
};

export default MembersPreview;
