import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { promoteToManagerDS } from 'people/assets/js/ducks/people';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';

export const getModalId = userId => `promote-provider-id-${userId}`;

const PromoteProviderModal = ({
  activeOrg: { alias: orgAlias },
  dispatch,
  isModalOpen,
  listViewComponentName,
  userId,
  userName,
}) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    try {
      await dispatch(promoteToManagerDS({
        componentName: listViewComponentName,
        orgAlias,
        userId,
      }));
      toastr.success('Well Done!', `${userName} is now a manager.`);
      setLoading(false);
    } catch (error) {
      const message = error.response?.data?._error || error.message;
      toastr.error('Oh Snap!', message);
      setLoading(false);
      // required to prevent onConfirmSuccess in ModalConfirm being called
      throw new Error(message);
    }
  };

  return (
    <ModalConfirm
      data-testid="provider-promote-modal"
      heading={`Convert ${userName} to be a manager`}
      confirmLabel="Convert"
      confirmButtonDisabled={loading}
      onConfirm={onConfirm}
      onClose={() => dispatch(modalCloseAC())}
      open={isModalOpen}
    >
      <p>
        Are you sure you want to convert
        {` ${userName} `}
        to be a manager in this organization?
      </p>
      <p>You can convert them to be a provider again afterwards, if required.</p>
    </ModalConfirm>
  );
};

PromoteProviderModal.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  listViewComponentName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  activeOrg: selectActiveOrg(state),
  isModalOpen: getIsModalOpen(state, getModalId(props.userId)),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const PromoteProviderModalConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(PromoteProviderModal);

export default PromoteProviderModalConnected;
