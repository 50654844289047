import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { projectCancelWorksheetDS } from 'projects/assets/js/data-services/form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { worksheetSpec } from 'finance/assets/js/lib/objectSpecs';

export const WorksheetCancelForm = ({
  onCancelSuccess, handleSubmit, submitting,
  orgAlias, worksheet, dispatch,
}) => {
  /**
   * Cancel Worksheet - form submit callback
   *
   * @param values
   * @returns {Promise<any>}
   */
  const onSubmitCancel = (values) => {
    return dispatch(projectCancelWorksheetDS(
      {
        orgAlias,
        projectId: worksheet.projectId,
        worksheetId: worksheet.id,
      },
      { ...values,
        rateUnit: worksheet.rateUnit,
        quantity: worksheet.quantity,
      },
    )).then((res) => {
      onCancelSuccess(res.worksheet, res.projectAllowedActions);
    });
  };

  return (
    <div className="static-modal">
      <form>
        <TDSystemMessage
          type={BS_STYLE.WARNING}
          title="Canceling a Worksheet cannot be undone!"
          className="mb-4"
        />

        <div>
          <Field
            label="Why do you want to cancel your worksheet?"
            name="cancelling_reason"
            type="textarea"
            component={InputField}
          />
        </div>

        <TDButton
          className="col-12"
          onClick={handleSubmit(onSubmitCancel)}
          bsSize={BS_SIZE.LARGE}
          variant={BS_STYLE.DANGER}
          disabled={submitting}
          label="Cancel worksheet"
        />
      </form>
    </div>
  );
};

WorksheetCancelForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancelSuccess: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  worksheet: worksheetSpec,
  submitting: PropTypes.bool,
};
WorksheetCancelForm.defaultProps = {
  submitting: false,
  worksheet: null,
};

export default WorksheetCancelForm;
