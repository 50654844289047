import { get } from 'lodash';

import { BANK_CURRENCY } from 'core/assets/js/constants';

/**
 * Based on the TW bank account dynamic field that is generated, this function checks for help
 * texts and provides them accordingly. Help texts are grouped by currency unless there is a
 * field type that is common between currencies e.g IBAN - in which case we should
 * show the help text in all currencies
 * @param field - The TW bank account dynamic field which is generated
 * @param currency
 * @returns {String|null} help text - Returns a help text or nothing if no help text is found
 */
const twBankAccountFieldHelpText = (field, currency) => {
  const helpTexts = {
    IBAN: 'IBANs are long account numbers used by banks for cross-border transfers.',
    [BANK_CURRENCY.CAD]: {
      'Institution Number': 'The institution number is a 3-digit number identifying the financial institution to which a payment is directed.Along with the account number, it is essential for delivering payments through the clearing system.',
    },
    [BANK_CURRENCY.HKD]: {
      'Account number': 'Bank account numbers in Hong Kong are 9-12 digits long.The 3 digit branch code should be included at the beginning of the account number.',
    },
    [BANK_CURRENCY.AUD]: {
      'BSB code': 'The Bank State Branch code (BSB) identifies the bank and branch holding a bank account in Australia. It\'s 6 digits and there is no need to include the dash in the middle.',
    },
    [BANK_CURRENCY.INR]: {
      'IFSC Code': 'The Indian Financial System Code (IFSC) is a unique alphanumeric code of eleven digits.',
    },
    [BANK_CURRENCY.RUB]: {
      'BIK (Bank Code)': 'BIK code is a 9-digit identification code of the beneficiary bank.',
    },
  };

  return get(helpTexts, `${field.name}`) || get(helpTexts, `${currency}.${field.name}`) || null;
};

/**
 * Check the current tabs validity for currency and form values.
 * For example, USD accounts, when changing the country to something other than US
 * the user should be taken from the local bank account to swift tab, local should
 * only be used for accounts based in the US.
 * @param {string} currency - currency of the account.
 * @param {string} currentTab - the tab the user is currently on.
 * @param {[object]} requirements - the requirements for each bank account in this currency.
 * @param {string} fieldKey - the path of the form field that has just been changed.
 * @param {any} value - the value they have entered/selected for the field.
 * @return {string} the name of tab the user should be using,
 * returns the current tab if the user is okay to continue.
 */
const validateBankAccountTabForCurrency = (
  currency,
  currentTab,
  requirements,
  fieldKey,
  value,
) => {
  // USD accounts should use 'swift' outside the US ( local should only be used with a US address )
  if ((currency || '').toLowerCase() === 'usd') {
    if (currentTab === 'aba' && fieldKey === 'address.country' && value !== 'US') {
      return requirements.includes('swift_code') ? 'swift_code' : currentTab;
    }
  }

  return currentTab;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  twBankAccountFieldHelpText,
  validateBankAccountTabForCurrency,
};
