import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { contactsImportUrl, contactsUrl } from 'contacts/urls';
import { CONTACTS_IMPORT_STEP } from 'contacts/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { createImportedContactsDS } from 'contacts/assets/js/data-services/view';
import ContactsImportProcessingSkeleton from 'contacts/assets/js/components/skeletons/ContactsImportProcessingSkeleton.jsx';
import ContactsImportAnalysisTable from 'contacts/assets/js/components/ContactsImportAnalysisTable.jsx';
import { fetchCustomFieldTemplatesDS } from 'interviews/assets/js/ducks/customFields';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { customFieldSpec } from 'interviews/assets/js/lib/objectSpecs';

class ContactsImportResults extends React.Component {
  static GetComponentName() {
    return 'ContactsImportResults';
  }

  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
    };

    this.handleToggleTable = this.handleToggleTable.bind(this);
  }

  handleToggleTable() {
    this.setState(state => ({ showTable: !state.showTable }));
  }

  render() {
    const {
      history, match: { params: { orgAlias } }, extras, customFieldTemplates,
    } = this.props;
    const { showTable } = this.state;
    const allCustomFields = customFieldTemplates.map(template => template.questions).flat();
    const {
      validContacts, invalidContacts, selectedHeaders: headers,
    } = extras;
    const toggleEl = (
      <span className="imitate-link" onClick={this.handleToggleTable}>
        { `${showTable ? 'Hide' : 'Show'} status table` }
      </span>
    );
    return (
      <div className="contacts-import--results">
        <Card className="mb-4">
          <Card.Body>
            <h3 className="mb-4 mt-0">Results</h3>
            { validContacts.length > 0 && (
              <div>
                { 'Congratulations! We imported'}
                {' '}
                <span className="font-weight-bolder">{ validContacts.length }</span>
                {' '}
                of your contacts.
                { invalidContacts.length > 0 && (
                  <>
                    {' '}
                    Sadly,
                    {' '}
                    <span className="font-weight-bolder">{ invalidContacts.length }</span>
                    {' '}
                    could not be imported because they were invalid.
                  </>
                )}
                {' '}
                {toggleEl}
              </div>
            )}
            { validContacts.length === 0 && (
              <div>
                We analysed your CSV file and sadly,
                none of your contacts could be imported because they are invalid or already exist.
                {' '}
                {toggleEl}
              </div>
            )}
          </Card.Body>
        </Card>
        { showTable && (
          <ContactsImportAnalysisTable
            contacts={invalidContacts}
            headers={headers}
            customFields={allCustomFields}
          />
        )}
        <div className="row mt-5 ">
          <div className="col-12 text-right">
            <TDButton
              variant={BS_STYLE.DEFAULT}
              label="Cancel"
              onClick={() => history.push(contactsImportUrl(orgAlias, CONTACTS_IMPORT_STEP.UPLOAD))}
            />
            <TDButton
              variant={BS_STYLE.PRIMARY}
              onClick={() => history.push(contactsUrl(orgAlias))}
              label="See your contacts"
            />
          </div>
        </div>
      </div>
    );
  }
}

ContactsImportResults.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  extras: PropTypes.object.isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldSpec),
};

ContactsImportResults.defaultProps = {
  customFieldTemplates: [],
};

const TdApiConnectedResults = withTDApiConnected({
  fetchData: ({ dispatch, params, authedAxios, componentName, querystring, url }) => {
    const { orgAlias } = params;
    const values = queryString.parse(querystring);
    return Promise.all([
      dispatch(
        createImportedContactsDS({
          orgAlias, authedAxios, componentName, values,
        }),
      ),
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias, entityType: FIELD_ENTITY_TYPE.USER, authedAxios, url,
      })),
    ]);
  },
  duck: 'view',
  storeKey: ContactsImportResults.GetComponentName(),
  skeletonComponent: ContactsImportProcessingSkeleton,
})(ContactsImportResults);


export default withRouter(TdApiConnectedResults);
