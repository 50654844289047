import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import TDLiveUpdated from 'core/assets/js/components/TDLiveUpdated.jsx';
import { sanitizePusherChannelName } from 'core/assets/js/lib/utils';

const ChatTDLive = ({ authorization, children, email, events }) => {
  const onError = err => {
    toastr.error('Oh Snap!', err?.message || 'An error occured with live chat');
  };


  if (email) {
    // https://pusher.com/docs/channels/using_channels/channels/#channel-naming-conventions
    // Channel names should only include lower and uppercase letters, numbers
    // and the following punctuation _ - = @ , . ;
    // As we regularly use the + notation on user emails for testing purposes we must make
    // sure we sanitize all email addresses.
    const sanitizedEmail = sanitizePusherChannelName(email);
    return (
      <TDLiveUpdated
        channel={`${authorization ? 'private-' : ''}user-channel-${sanitizedEmail}`}
        events={events}
        onError={onError}
      >
        {children}
      </TDLiveUpdated>
    );
  }

  return children;
};

ChatTDLive.propTypes = {
  authorization: PropTypes.bool,
  children: PropTypes.node,
  email: PropTypes.string,
  events: PropTypes.object,
};

ChatTDLive.defaultProps = {
  authorization: true,
  children: null,
  email: '',
  events: {},
};
export default ChatTDLive;
