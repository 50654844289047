import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';

import { API_DATE_FORMAT } from 'core/assets/js/constants';
import { finalFormFieldLabelSpec, reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';


class DateRangeCalendarControlField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: 'startDate',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ startDate, endDate }) {
    const { focusedInput } = this.state;
    let _endDate = endDate;

    if (focusedInput === 'startDate') {
      _endDate = undefined;
    }
    const { input } = this.props;
    input.onChange({
      periodStart: startDate ? startDate.format(API_DATE_FORMAT) : undefined,
      periodEnd: _endDate ? _endDate.format(API_DATE_FORMAT) : undefined,
    });
  }

  render() {
    const { focusedInput } = this.state;
    const {
      input, input: { value }, label, isOutsideRange, required, showHelperLabel, sublabel,
      meta: { touched, error },
    } = this.props;
    const hasError = touched && error;
    const groupClassName = ['form-group form-group--daterangePicker'];

    if (hasError) {
      groupClassName.push('has-error');
    }

    return (
      <div className={groupClassName.join(' ')} data-testid="date-range-picker-field">
        <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />
        <div style={{ minHeight: '300px' }} className="calendar-wrapper--date-select d-flex">
          <DayPickerRangeController
            hideKeyboardShortcutsPanel={!showHelperLabel}
            initialVisibleMonth={() => moment(value.periodStart, API_DATE_FORMAT)}
            startDate={value.periodStart ? moment(value.periodStart, API_DATE_FORMAT) : undefined}
            endDate={value.periodEnd ? moment(value.periodEnd, API_DATE_FORMAT) : undefined}
            minimumNights={0}
            onDatesChange={this.handleChange}
            focusedInput={focusedInput}
            onFocusChange={_focusedInput => this.setState({ focusedInput: _focusedInput || 'startDate' })}
            numberOfMonths={1}
            isOutsideRange={isOutsideRange}
          />
        </div>
        {hasError && <span className="help-block">{error}</span>}
      </div>
    );
  }
}

DateRangeCalendarControlField.propTypes = {
  input: reduxInputSpec.isRequired,
  isOutsideRange: PropTypes.func,
  label: PropTypes.string,
  showHelperLabel: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    rest: PropTypes.object,
  }),
  required: PropTypes.bool,
};
DateRangeCalendarControlField.defaultProps = {
  meta: {
    touched: false,
    error: false,
    rest: {},
  },
  label: '',
  required: false,
  showHelperLabel: false,
  sublabel: null,
  isOutsideRange: () => false,
};

export default DateRangeCalendarControlField;
