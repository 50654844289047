import React from 'react';

import Timeline from 'core/assets/js/components/Timeline.jsx';
import { TIMELINE_ITEM_TYPES } from 'core/assets/js/constants';

const timeLineSampleItems = [
  {
    id: 1,
    variant: TIMELINE_ITEM_TYPES.EMPHASIZE,
    header: <span>&pound;15 &rarr; &pound;25</span>,
    timestamp: '17 Dec, 2017',
    author: 'Christine Munoz',
    content: (
      <p>
        <strong>Message:</strong>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Ut eget imperdiet neque. In volutpat ante semper diam molestie, et aliquam erat laoreet.
      </p>
    ),
    footer: <span className="status status--emphasize">Emphasize</span>,
  },
  {
    id: 2,
    variant: TIMELINE_ITEM_TYPES.DEFAULT,
    header: <span>&pound;15 &rarr; &pound;25</span>,
    timestamp: '18 Dec, 2017',
    author: 'Christine Munoz',
    content: (
      <p>
        <strong>Message:</strong>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Ut eget imperdiet neque. In volutpat ante semper diam molestie, et aliquam erat laoreet.
      </p>
    ),
    footer: <span className="status status--default">Default</span>,
  },
  {
    id: 3,
    variant: TIMELINE_ITEM_TYPES.APPROVED,
    header: <span>&pound;28 &rarr; &pound;32</span>,
    timestamp: '27 Dec, 2017',
    author: 'Kenneth Lewis',
    content: (
      <p>
        <strong>Message:</strong>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Ut eget imperdiet neque. In volutpat ante semper diam molestie, et aliquam erat laoreet.
      </p>
    ),
    footer: <span className="status status--approved">Approved</span>,
  },
  {
    id: 4,
    variant: TIMELINE_ITEM_TYPES.REJECTED,
    header: <span>&pound;28 &rarr; &pound;32</span>,
    timestamp: '28 Dec, 2017',
    author: 'Kenneth Lewis',
    content: (
      <p>
        <strong>Message:</strong>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Ut eget imperdiet neque. In volutpat ante semper diam molestie, et aliquam erat laoreet.
      </p>
    ),
    footer: <span className="status status--rejected">Rejected</span>,
  },
  {
    id: 5,
    variant: TIMELINE_ITEM_TYPES.PENDING,
    header: <span>&pound;28 &rarr; &pound;32</span>,
    timestamp: '29 Dec, 2017',
    author: 'Kenneth Lewis',
    content: (
      <p>
        <strong>Message:</strong>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Ut eget imperdiet neque. In volutpat ante semper diam molestie, et aliquam erat laoreet.
      </p>
    ),
    footer: <span className="status status--pending">Pending</span>,
  },
];

function TimeLineSection() {
  return (
    <section id="timeline">
      <h2>Timeline</h2>
      <div className="row p-4" style={{ background: 'white' }}>
        <div className="col-sm-12">
          <Timeline
            items={timeLineSampleItems}
          />
        </div>
      </div>
    </section>
  );
}

export default TimeLineSection;
