import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { Popover, Overlay } from 'react-bootstrap';
import { isNil } from 'lodash';

import DateRangePicker from 'core/assets/js/components/DateRangePicker.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { formatDate, getDatetime, parseDate } from 'core/assets/js/lib/utils';
import { GET_WINDOW_INNER_WIDTH, WINDOW_ADD_EVENT_LISTENER, WINDOW_REMOVE_EVENT_LISTENER } from 'core/assets/js/config/settings';
import {
  API_DATE_FORMAT,
  BOOTSTRAP_BREAKPOINTS,
  BS_STYLE,
  BS_TOOLTIP_PLACEMENT,
  DATE_FORMAT_FULL_MONTH_NAME,
  DATETIME_FORMAT_ISO,
  DATETIME_ISO_REGEX,
} from 'core/assets/js/constants';
import { finalFormFieldLabelSpec, reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const QUICK_SELECTION_OPTIONS = {
  THIS_WEEK: 'This week',
  LAST_WEEK: 'Last week',
  LAST_SEVEN_DAYS: 'Last 7 days',
  THIS_MONTH: 'This month',
  LAST_MONTH: 'Last month',
  LAST_THREE_MONTHS: 'Last 3 months',
  THIS_YEAR: 'This year',
  LAST_YEAR: 'Last year',
  CUSTOM: 'Custom',
};

const calculateDatesFromQuickSelectionKey = (key) => {
  const now = getDatetime();
  let value;

  switch (QUICK_SELECTION_OPTIONS[key]) {
    case QUICK_SELECTION_OPTIONS.THIS_WEEK:
      value = {
        min: now.clone().startOf('week'),
        max: now.clone().endOf('week'),
      };
      break;
    case QUICK_SELECTION_OPTIONS.LAST_WEEK:
      value = {
        min: now.clone().startOf('week').subtract(1, 'week').startOf('week'),
        max: now.clone().endOf('week').subtract(1, 'week').endOf('week'),
      };
      break;
    case QUICK_SELECTION_OPTIONS.LAST_SEVEN_DAYS:
      value = {
        min: now.clone().subtract(6, 'day'),
        max: now.clone(),
      };
      break;
    case QUICK_SELECTION_OPTIONS.THIS_MONTH:
      value = {
        min: now.clone().startOf('month'),
        max: now.clone().endOf('month'),
      };
      break;
    case QUICK_SELECTION_OPTIONS.LAST_MONTH:
      value = {
        min: now.clone().subtract(1, 'month').startOf('month'),
        max: now.clone().subtract(1, 'month').endOf('month'),
      };
      break;
    case QUICK_SELECTION_OPTIONS.LAST_THREE_MONTHS:
      value = {
        min: now.clone().subtract(3, 'month').startOf('month'),
        max: now.clone().subtract(1, 'month').endOf('month'),
      };
      break;
    case QUICK_SELECTION_OPTIONS.THIS_YEAR:
      value = {
        min: now.clone().startOf('year'),
        max: now.clone().endOf('year'),
      };
      break;
    case QUICK_SELECTION_OPTIONS.LAST_YEAR:
      value = {
        min: now.clone().subtract(1, 'year').startOf('year'),
        max: now.clone().subtract(1, 'year').endOf('year'),
      };
      break;
    case QUICK_SELECTION_OPTIONS.CUSTOM:
    default:
      break;
  }
  return value;
};

const DateRangePickerField = ({
  input,
  isOutsideRange,
  label,
  meta: { error, touched },
  required,
  showQuickSelect,
  sublabel,
}) => {
  let startDate = null;
  let endDate = null;
  let valueParsed = null;
  if (typeof input.value === 'string') {
    try {
      valueParsed = JSON.parse(input.value);
    } catch (_) {} // eslint-disable-line no-empty
  } else if (input.value) {
    valueParsed = { ...input.value };
  }
  ['min', 'max'].forEach(key => {
    if (!valueParsed?.[key]) {
      return;
    }
    if (typeof valueParsed[key] === 'string') {
      valueParsed[key] = parseDate(
        valueParsed[key],
        DATETIME_ISO_REGEX.test(valueParsed[key]) ? DATETIME_FORMAT_ISO : API_DATE_FORMAT,
        false,
      );
    } else if (valueParsed[key] instanceof Date && !isNaN(valueParsed[key]).getTime()) {
      valueParsed[key] = parseDate(valueParsed[key]);
    }
    if (valueParsed[key].isValid && valueParsed[key].isValid()) {
      if (key === 'min') {
        startDate = valueParsed[key];
      }
      if (key === 'max') {
        endDate = valueParsed[key];
      }
    }
  });

  const target = useRef(null);
  const now = getDatetime();
  const [initialMonth, setInitialMonth] = useState(now);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showCalenderControl, setShowCalenderControl] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const checkWindowDimensions = () => {
    setIsMobileView(GET_WINDOW_INNER_WIDTH() < BOOTSTRAP_BREAKPOINTS.MEDIUM);
  };
  const [previousValue, setPreviousValue] = useState();
  const hasError = touched && error;
  const groupClassName = ['form-group form-group--daterangePicker'];

  useEffect(() => {
    checkWindowDimensions();
    WINDOW_ADD_EVENT_LISTENER('resize', checkWindowDimensions);
    return () => WINDOW_REMOVE_EVENT_LISTENER('resize', checkWindowDimensions);
  }, []);

  if (hasError) {
    groupClassName.push('has-error');
  }

  const fakeInputClassName = [
    'form-control form-control--date-picker d-flex justify-content-between px-4 align-items-center',
  ];

  if (focusedInput === START_DATE) {
    fakeInputClassName.push('form-control--start-date-focused');
  } else if (focusedInput === END_DATE) {
    fakeInputClassName.push('form-control--end-date-focused');
  }

  const onChange = newValueIn => {
    const newValue = newValueIn ? { ...newValueIn } : null;
    ['min', 'max'].forEach(key => {
      if (newValue?.[key]) {
        newValue[key] = formatDate(newValue[key], API_DATE_FORMAT);
      }
    });
    input.onChange(newValue);
  };

  const handleQuickSelectionClick = (key) => {
    const newValue = calculateDatesFromQuickSelectionKey(key);
    setFocusedInput(START_DATE);
    onChange(newValue);

    // Force calendar re-render so that it updates visible month
    setInitialMonth(null);
    setTimeout(() => {
      setInitialMonth(() => (newValue?.min || now));
    }, 0);
  };

  const getQuickLinkActiveClass = (key) => {
    const newValue = calculateDatesFromQuickSelectionKey(key);
    if (
      startDate && endDate
      && newValue?.min?.isSame(startDate)
      && newValue?.max?.isSame(endDate)
    ) {
      return 'active';
    }
    return '';
  };

  return (
    <div
      className={groupClassName.join(' ')}
      data-testid="date-range-picker-field"
    >
      <TDLabel
        name={input.name}
        label={label}
        required={required}
        sublabel={sublabel}
      />

      <div
        className={fakeInputClassName.join(' ')}
        onClick={() => {
          setPreviousValue(input.value);
          setShowCalenderControl(true);
          setFocusedInput(focusedInput || START_DATE);
        }}
        ref={target}
      >
        <span
          onClick={(e) => {
            e.stopPropagation();
            setPreviousValue(input.value);
            setFocusedInput(START_DATE);
            setShowCalenderControl(true);
          }}
        >
          {startDate
            ? formatDate(startDate)
            : <i className="text-muted">Start date</i>
          }
        </span>
        -
        <span
          onClick={(e) => {
            e.stopPropagation();
            setPreviousValue(input.value);
            setFocusedInput(END_DATE);
            setShowCalenderControl(true);
          }}
        >
          {endDate
            ? formatDate(endDate)
            : <i className="text-muted">End date</i>
          }
        </span>
      </div>

      {hasError && <span className="help-block">{error}</span>}

      <Overlay
        id="datepicker-overlay"
        placement="bottom-start"
        show={showCalenderControl}
        target={target.current}
        transition={false}
      >
        <Popover
          id="date-picker-popover"
          className="popover--date-picker"
          placement={BS_TOOLTIP_PLACEMENT.BOTTOM}
        >
          <Popover.Content>
            <div className="d-flex border-bottom flex-column flex-md-row">
              <div
                className="calendar-wrapper--date-select d-flex flex-column border-right"
              >
                {initialMonth && (
                  <DateRangePicker
                    className="m-3"
                    endDate={endDate?.toDate()}
                    initialVisibleDate={initialMonth.toDate()}
                    isOutsideRange={isOutsideRange}
                    onChange={newValue => {
                      onChange({ max: newValue.endDate, min: newValue.startDate });
                    }}
                    showSecondMonth={!isMobileView}
                    startDate={startDate?.toDate()}
                  />
                )}
              </div>

              {showQuickSelect && (
                <ul className="quick-selection-list">
                  {Object.keys(QUICK_SELECTION_OPTIONS).map(key => (
                    <li
                      className="d-inline-block d-md-block"
                      key={key}
                    >
                      <a
                        className={`d-inline-block d-md-block ${getQuickLinkActiveClass(key)}`}
                        onClick={() => handleQuickSelectionClick(key)}
                      >
                        {QUICK_SELECTION_OPTIONS[key]}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="d-md-flex justify-content-end p-5">
              <div className="d-flex align-items-md-center flex-column flex-md-row">
                {
                  !isNil(startDate)
                  && !isNil(endDate)
                  && (
                    <div className="mb-5 mb-md-0">
                      {`
                        ${formatDate(startDate, DATE_FORMAT_FULL_MONTH_NAME)}
                        - ${formatDate(endDate, DATE_FORMAT_FULL_MONTH_NAME)}
                      `}
                    </div>
                  )
                }

                <div className="d-flex justify-content-end ml-auto ml-md-0">
                  <TDButton
                    className="ml-5"
                    label="Cancel"
                    onClick={() => {
                      setFocusedInput(null);
                      setShowCalenderControl(false);
                      input.onChange(previousValue);
                    }}
                    variant={BS_STYLE.DEFAULT}
                  />

                  <TDButton
                    className="ml-5"
                    label="Apply"
                    onClick={() => {
                      setFocusedInput(null);
                      setShowCalenderControl(false);
                    }}
                    variant={BS_STYLE.PRIMARY}
                  />
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

DateRangePickerField.propTypes = {
  input: reduxInputSpec.isRequired,
  isOutsideRange: PropTypes.func,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    rest: PropTypes.object,
    touched: PropTypes.bool,
  }),
  required: PropTypes.bool,
  showQuickSelect: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
};

DateRangePickerField.defaultProps = {
  isOutsideRange: () => false,
  label: '',
  meta: {
    error: false,
    rest: {},
    touched: false,
  },
  required: false,
  showQuickSelect: true,
  sublabel: null,
};

export default withField(DateRangePickerField);
