import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import Storage from 'core/assets/js/lib/Storage';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { ICON, AUTO_SAVE_INTERVAL_TIME } from 'core/assets/js/constants';
import { FORM_NAME } from 'projects/assets/js/components/WorksheetForm.jsx';
import { WINDOW_SET_ON_BEFORE_UNLOAD } from 'core/assets/js/config/settings';

class WorksheetFormAutoSaver extends React.Component {
  constructor(props) {
    super(props);

    this.interval = null;
  }

  componentDidMount() {
    const { worksheet, saveChangesToLocalStorage, formValues } = this.props;

    // Notify user that all changes will get lost if tries to refresh / leaves page.
    WINDOW_SET_ON_BEFORE_UNLOAD(() => {
      const { pristine } = this.props;
      return !pristine && 'Are you sure you want to leave this page? The changes you made will be lost.';
    });

    // Save changes every {AUTO_SAVE_INTERVAL_TIME}
    if (!worksheet) {
      this.interval = setInterval(() => {
        saveChangesToLocalStorage(formValues);
      }, AUTO_SAVE_INTERVAL_TIME);
    }
  }

  componentWillUnmount() {
    const {
      history, project, profile: { userId },
      formValues, saveChangesToLocalStorage,
    } = this.props;
    // Stop the interval
    if (this.interval) {
      clearInterval(this.interval);
    }

    // Remove event
    WINDOW_SET_ON_BEFORE_UNLOAD(() => {});
    const isCancel = history.location.state && history.location.state.isCancel;
    if (isCancel) {
      // Remove auto-saved items on Cancel.
      Storage.remove(`${FORM_NAME}-${userId}-${project.id}`);
    } else {
      saveChangesToLocalStorage(formValues);
    }
  }

  render() {
    const { autoSaveState } = this.props;
    return (
      <span className="pb-4 discreet">
        <TDElementWithTooltip tooltipMsg="Auto saved">
          <i className={`${ICON.WARNING_CIRCLE} mr-2 pt-2`} />
        </TDElementWithTooltip>
        {autoSaveState}
      </span>
    );
  }
}

WorksheetFormAutoSaver.propTypes = {
  history: routerHistorySpec.isRequired,
  project: projectSpec,
  worksheet: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  saveChangesToLocalStorage: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  autoSaveState: PropTypes.string.isRequired,
};

WorksheetFormAutoSaver.defaultProps = {
  worksheet: null,
  project: {},
};

export default withRouter(WorksheetFormAutoSaver);
