import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';

const DetailsRows = ({ prefix, rows }) => (
  rows.map(({ label, value }) => {
    const key = kebabCase(label);
    return (
      <div key={key}>
        <label htmlFor={`${prefix}__${key}`}>{`${label}:`}</label>
        <span id={`${prefix}__${key}`}>{value || '-'}</span>
      </div>
    );
  })
);

DetailsRows.propTypes = {
  prefix: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
  })),
};

export default DetailsRows;
