import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { orgGetStartedUrl } from 'accounts/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import InvitationCard from 'invitations/assets/js/components/InvitationCard.jsx';
import { STATUS, ORG_INVITATION_TYPES } from 'invitations/assets/js/constants';
import {
  fetchInvitationActiveDS, selectActiveInvitation,
} from 'invitations/assets/js/ducks/invitation';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { organizationsUrl } from 'organizations/urls';

class InvitationView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchInvitationActiveDS({ token: params.token, url, authedAxios, componentName })),
    ]);
  }

  static GetComponentName() {
    return 'InvitationView';
  }

  componentDidUpdate() {
    const { history, invitation } = this.props;
    if (
      invitation?.status === STATUS.ACCEPTED
      && ORG_INVITATION_TYPES.includes(invitation.type)
      && invitation.orgAlias
    ) {
      history.push(orgGetStartedUrl(invitation.orgAlias));
    }
  }

  render() {
    const { dispatch, isValid, invitation, location } = this.props;
    const queryString = qs.parse(location.search);
    const nextUrl = queryString.next
      ? decodeURI(queryString.next)
      : organizationsUrl(invitation.orgAlias);

    return (
      <React.Fragment>
        <div className="page page--invitations">
          <div className="container">
            <TDApiConnected duck="invitation" component={this.constructor}>
              <InvitationCard
                allowAccept
                allowReject
                dispatch={dispatch}
                doneUrl={nextUrl}
                invitation={invitation}
                isValid={isValid}
                componentName={this.constructor.GetComponentName()}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

InvitationView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  invitation: invitationSpec.isRequired,
  isValid: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

InvitationView.defaultProps = {
  isValid: null,
};

const mapStateToProps = (state) => {
  const invitationState = selectActiveInvitation(state);
  return {
    isValid: invitationState.isValid,
    invitation: invitationState.item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const InvitationViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationView);

export default withRouter(InvitationViewConnected);
