import axios from 'core/assets/js/lib/tdAxios';
import { authProfileUpdateAC } from 'accounts/assets/js/reducers/auth';
import { httpErrorAC } from 'core/assets/js/ducks/errors';
import {
  orgListFetchAC,
  orgListIsLoadingAC,
  orgSubscriptionsListFetchAC,
  orgSubscriptionsListIsLoadingAC,
} from 'organizations/assets/js/actions/list';
import { orgMakeDefaultApiUrl } from 'organizations/urls';
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('ds:list');

/**
 * Fetch all organizations the user belongs to or owns
 *
 * @param {Object} options
 * @param {Object} [options.authedAxios]
 * @param {Bool} [options.cached]
 * @param {String} [options.url]
 */
const fetchOrgListDS = ({ authedAxios = null, cached = true, url = '' } = {}) => (
  (dispatch) => {
    dispatch(orgListIsLoadingAC(true));

    return (authedAxios || axios).get(
      `${url}/api/organizations/belongto-or-own?cached=${cached ? 1 : 0}`,
    )
      .then((response) => {
        dispatch(orgListFetchAC(response.data));
      })
      .catch((error) => {
        logger.error(error);
      });
  }
);

/**
 * Fetch all subscriptions of a given organisation
 *
 * @param {Object} options
 * @param {String} [options.orgAlias]
 * @param {Object} [options.authedAxios]
 * @param {String} [options.url]
 */
export const fetchOrgSubscriptionsListDS = ({
  orgAlias, authedAxios = null, url = '',
} = {}) => (
  (dispatch) => {
    dispatch(orgSubscriptionsListIsLoadingAC(true));

    return (authedAxios || axios).get(`${url}/api/${orgAlias}/organizations/subscriptions`,
    )
      .then((response) => {
        dispatch(orgSubscriptionsListFetchAC(response.data));
      })
      .catch((error) => {
        logger.error(error);
      });
  }
);

const markOrgDefaultDS = (orgAlias, url = '', authedAxios = null) => (dispatch) => {
  const apiUrl = `${url}${orgMakeDefaultApiUrl(orgAlias)}`;
  return (authedAxios || axios).put(apiUrl)
    .then((resp) => {
      dispatch(authProfileUpdateAC(resp.data));
      return resp.data;
    })
    .catch((error) => {
      logger.error(error);
      dispatch(httpErrorAC([error.response.data]));
    });
};

export {
  fetchOrgListDS,
  markOrgDefaultDS,
};
