import React from 'react';
import { Card } from 'react-bootstrap';

import PaymentProviderOffline from 'settings/assets/js/components/PaymentProviderOffline.jsx';

const PaymentProviderOfflineCard = () => (
  <Card
    className="overflow-hidden payment-provder-offline-card"
    data-testid="wise-offline-card"
  >
    <PaymentProviderOffline />
  </Card>
);

export default PaymentProviderOfflineCard;
