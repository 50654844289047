import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_PAGE_SIZE } from 'settings/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import { prepareQueryString } from 'core/assets/js/lib/utils';
import { myTasksListUrl, projectMyTasksListApiUrl } from 'projects/urls';
import { TASK_STATUS } from 'projects/assets/js/constants';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import MyTaskWidgetCardItem from 'core/assets/js/components/Dashboard/MyTaskWidgetCardItem.jsx';
import MyTaskWidgetSkeleton from 'core/assets/js/components/Skeleton/MyTaskWidgetSkeleton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';

const COMPONENT_NAME = 'MyTasksWidgetView';

const MyTasksWidget = ({
  history,
  match: { params: { orgAlias } },
  taskItems,
  toolTip,
  miniTip,
  tipLink,
}) => {
  const footerActionBtn = {
    label: 'View tasks',
    onClick: () => history.push(myTasksListUrl(orgAlias)),
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: `${miniTip}`,
    icon: ICON.CLIPBOARD_LIST_CHECK,
  };

  const widgetlTitle = 'Tasks';

  return (
    <WidgetWrapper
      bodyExtraClasses="p-0"
      footerActionBtn={footerActionBtn}
      isEmpty={isEmpty(taskItems)}
      placeholder={emptyPlaceholder}
      title={widgetlTitle}
      popOverTitle={widgetlTitle}
      popOverContent={(
        <>
          <p>
            {toolTip}
          </p>
          <p>
            <a href={tipLink} target="_blank" rel="noreferrer">
              To learn more
              {' '}
              <span className="visually-hidden">
                {' '}
                about
                {' '}
                {widgetlTitle}
                ,
              </span>
              visit the Knowledge base
            </a>
          </p>
        </>
      )}

    >
      <TDList
        cardItem={{
          component: MyTaskWidgetCardItem,
          props: { orgAlias },
        }}
        items={taskItems}
        listClassName="tasks-widget-list"
      />
    </WidgetWrapper>
  );
};

MyTasksWidget.propTypes = {
  match: routerMatchSpec.isRequired,
  taskItems: PropTypes.arrayOf(projectTaskSpec),
  toolTip: PropTypes.string,
  miniTip: PropTypes.string,
  tipLink: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

MyTasksWidget.defaultProps = {
  taskItems: [],
  toolTip: '',
  miniTip: '',
  tipLink: '',
};

const mapStateToProps = state => ({
  taskItems: getListState(state, COMPONENT_NAME)?.items?.tasks,
});

const mapDispatchToProps = dispatch => ({ dispatch });

const MyTasksWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyTasksWidget);

const TDApiConnectedMyTasksWidget = withTDApiConnected({
  duck: 'list',
  fetchData: ({
    authedAxios,
    componentName,
    dispatch,
    params: { orgAlias },
    url,
  }) => dispatch(
    fetchListDS({
      authedAxios,
      componentName,
      querystring: prepareQueryString({
        pageSize: DASHBOARD_WIDGET_PAGE_SIZE[DASHBOARD_WIDGET.TASKS],
        status: TASK_STATUS.INPROGRESS,
      }),
      url: projectMyTasksListApiUrl(orgAlias, url),
    }),
  ),
  skeletonComponent: MyTaskWidgetSkeleton,
  storeKey: COMPONENT_NAME,
})(MyTasksWidgetConnected);

export default withRouter(TDApiConnectedMyTasksWidget);
