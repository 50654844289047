import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import FilePreview from 'core/assets/js/components/FilePreview.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { FILE_PREVIEW_MODAL_ID, ICON, MODAL_SIZES } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';

const FilePreviewModal = () => {
  const dispatch = useDispatch();

  const [fileBase64, setFileBase64] = useState(null);
  const [fileMimeType, setFileMimeType] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const modalIsOpen = modalId => useSelector(state => getIsModalOpen(state, modalId));
  const modalPayload = modalId => useSelector(state => getModalPayload(state, modalId));

  const filePreviewModalIsOpen = modalIsOpen(FILE_PREVIEW_MODAL_ID);
  const filePreviewModalPayload = modalPayload(FILE_PREVIEW_MODAL_ID) || {};

  const closeModal = () => dispatch(modalCloseAC());

  useEffect(() => {
    if (filePreviewModalPayload.url) {
      setIsLoading(true);
      axios
        .get(filePreviewModalPayload.url, { responseType: 'arraybuffer' })
        .then(response => {
          setFileMimeType(response.headers['content-type']);
          const contentDisposition = response.headers['content-disposition'];
          if (contentDisposition.includes('filename="')) {
            setFileName(contentDisposition.split('filename="')[1].split('"')[0]);
          }
          setFileBase64(Buffer.from(response.data, 'binary').toString('base64'));
        })
        .catch(err => {
          toastr.error('Oh Snap!', err._error || err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [filePreviewModalPayload.url]);

  return (
    <ModalSimple
      body={(
        <div className="d-flex flex-column">
          {isLoading && <div className="m-5 text-center">Loading</div>}
          {!isLoading && (
            <>
              <div className="d-flex flex-row justify-content-end mb-5">
                <TDButton
                  btnIcon={ICON.DOWNLOAD}
                  label="Download"
                  onClick={() => WINDOW_OPEN(filePreviewModalPayload.url, '_blank')}
                />
              </div>
              {fileMimeType && fileBase64 && (
                <FilePreview
                  className="file-preview-modal"
                  mimeType={fileMimeType}
                  url={`data:application/pdf;base64,${fileBase64}`}
                />
              )}
            </>
          )}
        </div>
      )}
      heading={filePreviewModalPayload.heading || fileName}
      noFooter
      onClose={closeModal}
      open={filePreviewModalIsOpen}
      size={MODAL_SIZES.EXTRA_LARGE}
    />
  );
};

export default FilePreviewModal;
