import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_PAGE_SIZE } from 'settings/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import { prepareQueryString } from 'core/assets/js/lib/utils';
import { projectOpportunitiesUrl, projectOpportunitiesListApiUrl } from 'projects/urls';
import { projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import OpportunitiesWidgetCardItem from 'core/assets/js/components/Dashboard/OpportunitiesWidgetCardItem.jsx';
import OpportunitiesWidgetSkeleton from 'core/assets/js/components/Skeleton/OpportunitiesWidgetSkeleton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';

const COMPONENT_NAME = 'OpportunitiesWidgetView';

const OpportunitiesWidget = ({
  history,
  match: { params: { orgAlias } },
  opportunityItems,
}) => {
  const footerActionBtn = {
    label: 'View opportunities',
    onClick: () => history.push(projectOpportunitiesUrl(orgAlias)),
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: 'Discover new work in the organization’s opportunities list',
    icon: ICON.LIGHTBULB_DOLLAR_DUOTONE,
  };

  const widgetlTitle = 'Opportunities';

  return (
    <WidgetWrapper
      footerActionBtn={footerActionBtn}
      isEmpty={isEmpty(opportunityItems)}
      placeholder={emptyPlaceholder}
      title={widgetlTitle}
      popOverTitle={widgetlTitle}
      popOverContent={(
        <>
          <p>
            Opportunities are new project ideas that the organization has created and is
            seeking contractor interest in. Each opportunity will have requires skills
            associated with them, and we match those with your defined skills to find
            the best opportunities for you.
          </p>
        </>
      )}
    >
      <TDList
        cardItem={{
          component: OpportunitiesWidgetCardItem,
          props: { orgAlias },
        }}
        items={opportunityItems}
        listClassName="opportunities-list"
      />
    </WidgetWrapper>
  );
};

OpportunitiesWidget.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: routerMatchSpec.isRequired,
  opportunityItems: PropTypes.arrayOf(projectApplicationSpec),
};

OpportunitiesWidget.defaultProps = {
  opportunityItems: [],
};

const mapStateToProps = state => ({
  opportunityItems: getListState(state, COMPONENT_NAME)?.items,
});

const mapDispatchToProps = dispatch => ({ dispatch });

const OpportunitiesWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpportunitiesWidget);

const TDApiConnectedOpportunitiesWidget = withTDApiConnected({
  duck: 'list',
  fetchData: ({
    authedAxios,
    componentName,
    dispatch,
    params: { orgAlias },
    url,
  }) => dispatch(
    fetchListDS({
      authedAxios,
      componentName,
      querystring: prepareQueryString({
        pageSize: DASHBOARD_WIDGET_PAGE_SIZE[DASHBOARD_WIDGET.OPPORTUNITIES],
      }),
      url: projectOpportunitiesListApiUrl(orgAlias, url),
    }),
  ),
  skeletonComponent: OpportunitiesWidgetSkeleton,
  storeKey: COMPONENT_NAME,
})(OpportunitiesWidgetConnected);

export default withRouter(TDApiConnectedOpportunitiesWidget);
