/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import SettingsItem from 'core/assets/js/components/SettingsItem.jsx';
import LinkModal from 'core/assets/js/components/LinkModal.jsx';
import EmailForm, { emailFormId } from 'accounts/assets/js/components/EmailForm.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import AllowAccessToHigherManager from 'core/assets/js/components/AllowAccessToHigherManager.jsx';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import { getActiveUserCardPermissionChecker } from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';

const ReceiveWorksheetNotifsDisableModalBody = () => (
  <React.Fragment>
    <p>
      Do you want to skip the weekly reminders to submit a worksheet
      for every in-progress project you are a member of? Switch off the worksheet
      reminders option.
    </p>
    <p>
      Please note that if you want to stop receiving reminders only for a
      specific in-progress project because you are not an active contributor,
      you can always remove yourself from the project and stop receiving the weekly
      reminders.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the weekly worksheet reminders for all projects?
    </p>
  </React.Fragment>
);

const ReceiveWorksheetNotifsEnableModalBody = () => (
  <React.Fragment>
    <p>
      Want to be reminded on a weekly basis to submit a worksheet for every in-progress project
      you are a member of? Switch on the worksheet reminders option.
    </p>
    <p>
      Please note that you will stop receiving weekly reminders if you remove yourself
      from the project or the project gets marked as completed or archived.
    </p>
    <p className="mb-4">
      Are you sure you want to activate the weekly worksheet reminders?
    </p>
  </React.Fragment>
);

const ReceiveWeeklyServiceOrdersNotifsDisableModalBody = () => (
  <>
    <p>
      By deactivating this notification, we will stop sending you a summary email
      each Friday of all Worksheets, Expenses, Pro-forma invoices and Budget Requests
      that are awaiting your review.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the weekly reviews reminders for all projects?
    </p>
  </>
);

const ReceiveWeeklyServiceOrdersNotifsEnableModalBody = () => (
  <>
    <p>
      Want to receive a weekly reminder of any Worksheets, Expenses, Pro-forma invoices and Budget
      Requests that are awaiting your review? Enable this notification and we will
      send you a summary email 2 days before each invoicing date.
    </p>
    <p className="mb-4">
      Are you sure you want to activate the weekly reviews reminders?
    </p>
  </>
);

const ReceivePaymentsNotifsDisableModalBody = () => (
  <React.Fragment>
    <p>
      Do you want to skip the reminders to any payments related
      actions? Switch off the payments reminders option.
    </p>
    <p>
      Please note that if you want to stop receiving reminders only for a
      specific in-progress project because you are not an active contributor,
      you can always remove yourself from the project and stop receiving the
      reminders.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the payments reminders for all projects?
    </p>
  </React.Fragment>
);

const ReceivePaymentsNotifsEnableModalBody = () => (
  <React.Fragment>
    <p>
      Want to be reminded of the payments actions for every in-progress project
      you are a member of? Switch on the payments reminders option.
    </p>
    <p>
      Please note that you will stop receiving reminders if you remove yourself
      from the project or the project gets marked as completed or archived.
    </p>
    <p className="mb-4">
      Are you sure you want to activate the reminders?
    </p>
  </React.Fragment>
);

const ReceiveTaskAssigneesWorksheetNotifsEnableModalBody = () => (
  <React.Fragment>
    <p>
      Do you want to (on a weekly basis) remind only project members
      of in-progress projects who have been assigned with a task to
      submit a worksheet? Switch on the worksheet reminders only to task assignees option.
    </p>
    <p>
      Please note that your users will stop receiving weekly reminders
      when they complete all of their tasks or get removed from the project,
      or the project gets marked as completed or archived.
    </p>
    <p className="mb-4">
      Are you sure you want to activate the weekly worksheet reminders only to task
      assignees setting?
    </p>
  </React.Fragment>
);

const ReceiveTaskAssigneesWorksheetNotifsDisableModalBody = () => (
  <React.Fragment>
    <p>
      Do you want to (on a weekly basis) remind all project members of in-progress projects
      to submit a worksheet (even if they are not currently assigned to a task)?
      If yes, please switch off the worksheet reminders only to task assignees option.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the weekly worksheet reminders only to
      task assignees setting?
    </p>
  </React.Fragment>
);

const ReceiveProjectsTeamMemberAdditionNotifsEnableModalBody = () => (
  <React.Fragment>
    <p>
      Activating this setting means that the project owner gets notified
      every time a user accepts or rejects a project invitation.
    </p>
    <p className="mb-4">
      Are you sure you want to activate the
      {' '}
      <strong>Project member addition</strong>
      {' '}
      notification?
    </p>
  </React.Fragment>
);

const ReceiveProjectsTeamMemberAdditionNotifsDisableModalBody = () => (
  <React.Fragment>
    <p>
      By deactivating this setting the project owner does not get notified every
      time a user accepts or rejects a project invitation.
      This reduces the number of notifications sent to the project owner.
      At the same time, the project owner will need to check the status of
      each project member by visiting the project team page.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the
      {' '}
      <strong>
        Project member addition
      </strong>
      {' '}
      notification?
    </p>
  </React.Fragment>
);


/**
 * @param {Object} params
 * @param {Function} params.onFinanceEmailChanged
 * @param {Function} params.onOrgEmailChanged
 * @param {Function} params.onProjectsTeamMemberAdditionNotificationsChanged
 * @param {Function} params.onServiceOrderNotificationsChanged
 * @param {Function} params.onEnableServiceOrdersManagerRemindersChanged
 * @param {Function} params.onTaskAssigneesWsNotificationsChanged
 * @param {Object} params.organization
 * @param {Object} params.userCard
 */
const NotificationsForm = ({
  onFinanceEmailChanged,
  onOrgEmailChanged,
  onProjectsTeamMemberAdditionNotificationsChanged,
  onServiceOrderNotificationsChanged,
  onEnableServiceOrdersManagerRemindersChanged,
  onPaymentsNotificationsChanged,
  onTaskAssigneesWorksheetNotificationsChanged,
  organization,
  userCard,
}) => {
  const dispatch = useDispatch();
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const {
    enableProjectsTeamMemberAdditionNotifications,
    enableServiceOrderNotifications,
    enableServiceOrderManagerReminders,
    enablePaymentsNotifications,
  } = userCard;
  const isProvider = get(userCard, 'userRole.isProvider');
  const isAnyManager = get(userCard, 'userRole.isAnyManager');
  // currently worksheets category is only visible for provider and higher managers.
  // different worksheet setting in these roles though
  return (
    <div className="clearfix rounded shadow-sm p-4 bg-white">
      <section>
        <h3 className="heading-block">Email recipients</h3>
        <SettingsItem
          containerClassName="organization-actions-settings-item"
          label="Organisation Actions"
          preview={userCard.email}
          disclaimer={`All emails sent from ${organization.name} will be sent to this email address.`}
          cta={(
            <LinkModal
              modalId="set-org-email-modal"
              modalOptions={{
                heading: `Set your email address for ${organization.name}`,
                body: (
                  <EmailForm
                    onSubmit={onOrgEmailChanged}
                    onSubmitSuccess={() => dispatch(modalCloseAC())}
                    initialValues={{ email: userCard.email }}
                  />
                ),
                footer: (
                  <TDButton
                    floatRight
                    className="col-6 col-sm-4"
                    type="submit"
                    variant={BS_STYLE.SUCCESS}
                    onClick={() => dispatch(submit(emailFormId))}
                    label="Update"
                  />
                ),
              }}
            />
          )}
        />
        {hasPermission(PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS) && (
          <SettingsItem
            containerClassName="payments-settings-item"
            label="Payments"
            preview={organization.finance_email}
            disclaimer="A copy of issued invoices will be forwarded to this email"
            cta={(
              <LinkModal
                modalId="set-payments-email-modal"
                modalOptions={{
                  heading: 'Set your email address for Payments',
                  body: (
                    <EmailForm
                      onSubmit={onFinanceEmailChanged}
                      onSubmitSuccess={() => dispatch(modalCloseAC())}
                      initialValues={{ email: organization.finance_email }}
                    />
                  ),
                  footer: (
                    <TDButton
                      floatRight
                      className="col-6 col-sm-4"
                      type="submit"
                      variant={BS_STYLE.SUCCESS}
                      onClick={() => dispatch(submit(emailFormId))}
                      label="Update"
                    />
                  ),
                }}
              />
            )}
          />
        )}
        <h3 className="heading-block">Worksheets</h3>
        {isProvider && (
          <TDToggleWithConfirmationModal
            containerClassName="px-3"
            enableHeader="Worksheet reminders setting is deactivated"
            disableHeader="Worksheet reminders setting is activated"
            label="Receive worksheet reminders"
            modalId="receive-worksheet-reminders"
            selected={enableServiceOrderNotifications}
            onToggled={onServiceOrderNotificationsChanged}
            EnableModalBody={ReceiveWorksheetNotifsEnableModalBody}
            DisableModalBody={ReceiveWorksheetNotifsDisableModalBody}
            sublabel="By activating this option you will be receiving on a weekly basis a reminder to submit a worksheet for all in-progress projects you are a member of."
          />
        )}
        {isAnyManager && (
          <TDToggleWithConfirmationModal
            containerClassName="px-3 pb-3"
            enableHeader="Weekly worksheet reminders setting is deactivated"
            disableHeader="Weekly worksheet reminders setting is activated"
            label="Receive weekly worksheet notifications"
            modalId="receive-weekly-worksheet-reminders"
            selected={enableServiceOrderManagerReminders}
            onToggled={onEnableServiceOrdersManagerRemindersChanged}
            EnableModalBody={ReceiveWeeklyServiceOrdersNotifsEnableModalBody}
            DisableModalBody={ReceiveWeeklyServiceOrdersNotifsDisableModalBody}
            sublabel="By activating this option, you will receive a weekly reminder of all worksheets that require you to review and action."
          />
        )}
        <AllowAccessToHigherManager>
          <TDToggleWithConfirmationModal
            containerClassName="px-3"
            enableHeader="Worksheet reminders to task assignees setting is deactivated"
            disableHeader="Worksheet reminders to task assignees setting is activated"
            label="Send worksheet reminders only to project task assignees"
            modalId="receive-task-assignees-worksheet-reminders"
            selected={organization.enable_task_assignees_worksheet_notifications}
            onToggled={onTaskAssigneesWorksheetNotificationsChanged}
            EnableModalBody={ReceiveTaskAssigneesWorksheetNotifsEnableModalBody}
            DisableModalBody={ReceiveTaskAssigneesWorksheetNotifsDisableModalBody}
            sublabel="By activating this option you will ensure that all project members who have been assigned with a task in an in-progress project will be receiving  a reminder on a weekly basis to raise a worksheet."
          />
        </AllowAccessToHigherManager>
        <AllowAccessToManager>
          <h3 className="heading-block">Projects</h3>
          <TDToggleWithConfirmationModal
            containerClassName="px-3"
            enableHeader={'The "Project member addition" notification is deactivated'}
            disableHeader={'The "Project member addition" notification is activated'}
            label="Receive notifications when new members are added to the project team"
            modalId="projects-team-member-addition-notifications"
            selected={enableProjectsTeamMemberAdditionNotifications}
            onToggled={onProjectsTeamMemberAdditionNotificationsChanged}
            EnableModalBody={ReceiveProjectsTeamMemberAdditionNotifsEnableModalBody}
            DisableModalBody={ReceiveProjectsTeamMemberAdditionNotifsDisableModalBody}
            sublabel="When a user accepts or rejects a project invitation you will be notified via email and on-platform notifications"
          />
          <h3 className="heading-block">Payments</h3>
          <TDToggleWithConfirmationModal
            containerClassName="px-3 pb-3"
            enableHeader="Payment reminders setting is deactivated"
            disableHeader="Payment reminders setting is activated"
            label="Receive payment request notifications"
            modalId="receive-payments-reminders"
            selected={enablePaymentsNotifications}
            onToggled={onPaymentsNotificationsChanged}
            EnableModalBody={ReceivePaymentsNotifsEnableModalBody}
            DisableModalBody={ReceivePaymentsNotifsDisableModalBody}
            sublabel="By activating this option you will receive instant notifications for Worksheet & Expense related events."
          />
        </AllowAccessToManager>
      </section>
    </div>
  );
};

NotificationsForm.propTypes = {
  onFinanceEmailChanged: PropTypes.func.isRequired,
  onOrgEmailChanged: PropTypes.func.isRequired,
  onProjectsTeamMemberAdditionNotificationsChanged: PropTypes.func.isRequired,
  onServiceOrderNotificationsChanged: PropTypes.func.isRequired,
  onEnableServiceOrdersManagerRemindersChanged: PropTypes.func.isRequired,
  onPaymentsNotificationsChanged: PropTypes.func.isRequired,
  onTaskAssigneesWorksheetNotificationsChanged: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  userCard: PropTypes.object.isRequired,
};

export default NotificationsForm;
