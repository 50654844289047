import React from 'react';
import PropTypes from 'prop-types';

import TDRating from 'core/assets/js/components/TDRating.jsx';
import withField from 'core/assets/js/components/withField.jsx';


class RatingFilterField extends React.Component {
  static prepareStarsLabel(value) {
    return value && value !== ''
      ? `${value}${value && value < 5 ? '+' : ''} Stars`
      : null;
  }

  constructor(props) {
    super(props);
    const { initialValues, filter, input } = props;
    const initialValue = initialValues[filter.paramName]
      ? parseInt(initialValues[filter.paramName], 10)
      : null;
    input.onChange(initialValue);

    this.handleChange = this.handleChange.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  handleChange(newValue) {
    const { input } = this.props;

    const currentValue = parseInt(input.value, 10);
    const _newValue = newValue === currentValue // Reset filter if a selected star is clicked
      ? null
      : parseInt(newValue, 10);

    input.onChange(_newValue);
  }

  isActive() {
    const { input: { value } } = this.props;
    return (value && value !== '');
  }

  render() {
    const { filter: { label }, input } = this.props;
    const { value } = input;
    return (
      <React.Fragment>
        <h4>{label}</h4>

        <div className="row">
          <div className="col-12">
            <TDRating
              onClick={this.handleChange}
              size="large"
              initialRating={parseInt(value, 10)}
            />

            <span className="rating-value">
              {RatingFilterField.prepareStarsLabel(value)}
            </span>

            <input
              {...input}
              className="form-control"
              type="hidden"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RatingFilterField.propTypes = {
  label: PropTypes.string,
  filter: PropTypes.object,
  input: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

RatingFilterField.defaultProps = {
  filter: {},
  label: '',
};

export default withField(RatingFilterField);
