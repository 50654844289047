import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { isSSR } from 'core/assets/js/config/checks';
import { HAS_WINDOW } from 'core/assets/js/config/settings';

// react-rte uses window, so cannot be used in server side rendering
const RichTextEditor = isSSR || !HAS_WINDOW
  ? null
  : require('react-rte').default;

const RichTextArea = ({ className, disabled, maxLength, onChange, placeholder, value }) => {
  if (!RichTextEditor) {
    return null;
  }

  const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue());

  const wrapperClassNames = ['rich-text-area'];
  if (className) {
    wrapperClassNames.push(className);
  }

  useEffect(() => {
    const currentValue = rteValue.toString('html');
    if (value !== currentValue) {
      setRteValue(RichTextEditor.createValueFromString(value, 'html'));
    }
  }, [value]);

  return (
    <div className={wrapperClassNames.join(' ')}>
      <RichTextEditor
        value={rteValue}
        onChange={newRteValue => {
          setRteValue(newRteValue);
          const htmlString = newRteValue.toString('html');
          onChange(maxLength ? htmlString.slice(0, maxLength) : htmlString);
        }}
        placeholder={placeholder}
        readOnly={disabled}
      />
    </div>
  );
};

RichTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

RichTextArea.defaultProps = {
  className: '',
  disabled: false,
  maxLength: null,
  placeholder: '',
  value: '',
};

export default RichTextArea;
