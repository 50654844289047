
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TDButtonGroup from 'core/assets/js/components/TDButtonGroup.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { ICON, VIEW_TYPES } from 'core/assets/js/constants';
import { getPeopleListViewType, setPeopleListViewTypeAC } from 'core/assets/js/ducks/settings';

const GridViewToggle = () => {
  const dispatch = useDispatch();

  const listViewType = useSelector(getPeopleListViewType);
  const isGrid = listViewType === VIEW_TYPES.GRID;

  const setListViewType = newValue => dispatch(setPeopleListViewTypeAC(newValue));

  return (
    <TDButtonGroup
      primaryButtonActive={isGrid}
      primaryButton={(
        <TDElementWithTooltip
          delay={500}
          tooltipMsg="Grid view"
        >
          <i className={`p-4 m-n4 ${ICON.GRID}`} />
        </TDElementWithTooltip>
      )}
      primaryButtonAction={() => setListViewType(VIEW_TYPES.GRID)}
      secondaryButton={(
        <TDElementWithTooltip
          delay={500}
          tooltipMsg="Table view"
        >
          <i className={`p-4 m-n4 ${ICON.TABLE}`} />
        </TDElementWithTooltip>
      )}
      secondaryButtonActive={!isGrid}
      secondaryButtonAction={() => setListViewType(VIEW_TYPES.TABLE)}
    />
  );
};

export default GridViewToggle;
