import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getDefault1099ContractorsYear } from 'finance/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

export const getSelected1099Year = () => { // eslint-disable-line import/prefer-default-export
  const activeOrg = useSelector(selectActiveOrg);
  const { year } = queryString.parse(useLocation().search);

  if (year && /^\d{4}$/.test(year.toString())) {
    return parseInt(year, 10);
  }
  if (activeOrg?.createdAtYear) {
    return getDefault1099ContractorsYear(activeOrg.createdAtYear);
  }
  return new Date().getFullYear();
};
