import React from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import ImportStatus from 'importer/assets/js/components/ImportStatus.jsx';
import ImportStatusInfo from 'importer/assets/js/components/ImportStatusInfo.jsx';
import ImportViewCancelled from 'importer/assets/js/components/ImportViewCancelled.jsx';
import ImportViewErrors from 'importer/assets/js/components/ImportViewErrors.jsx';
import { IMPORT_TYPE_LABEL, IMPORT_VIEW_TABS } from 'importer/assets/js/constants';
import { importApiUrl, importCancelApiUrl, importsListUrl, importViewUrl } from 'importer/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const MODAL_ID = 'cancel-import-modal';

const ImportView = () => {
  const { id, orgAlias, tab = IMPORT_VIEW_TABS.DETAILS } = useParams();
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);
  const { item } = fetchDataHook({
    componentName: ImportView.GetComponentName(), url: importApiUrl(orgAlias, id),
  });
  const modalIsOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const listItemClassName = 'list-group-item d-flex justify-content-between align-items-center';
  const ctaButtonItems = [];
  const hasPendingRows = item.rowPendingCount > 0;
  if (hasPendingRows) {
    ctaButtonItems.push({
      label: 'Cancel pending rows',
      onClick: () => dispatch(modalOpenAC(MODAL_ID)),
      variant: BS_STYLE.PRIMARY,
    });
  }
  const closeModal = () => dispatch(modalCloseAC());
  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Imports', url: importsListUrl(activeOrg.alias) },
          { title: `${IMPORT_TYPE_LABEL[item.type]} import ${id}`, url: null },
        ]}
        ctaButtonItems={ctaButtonItems}
      >
        <div className="horizontal-scroll">
          <TabBar
            activeKey={tab}
            tabSpec={[
              {
                key: IMPORT_VIEW_TABS.DETAILS,
                label: 'Details',
                href: importViewUrl(activeOrg.alias, id, IMPORT_VIEW_TABS.DETAILS),
              },
              {
                key: IMPORT_VIEW_TABS.CANCELLED,
                label: 'Cancelled',
                href: importViewUrl(activeOrg.alias, id, IMPORT_VIEW_TABS.CANCELLED),
              },
              {
                key: IMPORT_VIEW_TABS.ERRORS,
                label: 'Errors',
                href: importViewUrl(activeOrg.alias, id, IMPORT_VIEW_TABS.ERRORS),
              },
            ]}
          />
        </div>
      </ContentHeader>
      <div className="page page--imports-view">
        <div className="container">
          {(!tab || tab === IMPORT_VIEW_TABS.DETAILS) && (
            <div className="row">
              <div className="col-12">
                <Card>
                  <Card.Header>Details</Card.Header>
                  <Card.Body>
                    {item && (
                      <ul className="list-group list-group-flush">
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">
                            Status
                            <ImportStatusInfo />
                          </span>
                          <ImportStatus import={item} />
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">Type</span>
                          <span>{IMPORT_TYPE_LABEL[item.type]}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">Rows</span>
                          <span>{item.rowCount}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">Pending</span>
                          <span>{item.rowPendingCount}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">In progress</span>
                          <span>{item.rowInProgressCount}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">Errors</span>
                          <span>{item.rowErrorsCount}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">Cancelled</span>
                          <span>{item.rowCancelledCount}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">Completed</span>
                          <span>{item.rowCompleteCount}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">User</span>
                          <span>{item.createdBy}</span>
                        </li>
                        <li className={listItemClassName}>
                          <span className="discreet mr-5">Created</span>
                          <span>{item.createdAt}</span>
                        </li>
                      </ul>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          )}
          {tab === IMPORT_VIEW_TABS.CANCELLED && <ImportViewCancelled />}
          {tab === IMPORT_VIEW_TABS.ERRORS && <ImportViewErrors />}
          {hasPendingRows && (
            <ModalConfirm
              body={(
                'This will cancel any pending rows in the import. Are you sure you want to '
                + 'do this? A few minutes after all rows are processed, the import will be '
                + 'marked as complete.'
              )}
              closeOnConfirm={false}
              confirmLabel="Cancel pending rows"
              heading="Cancel pending rows?"
              onClose={closeModal}
              onConfirm={async () => {
                try {
                  await axios.post(importCancelApiUrl(activeOrg.alias, id));
                  toastr.success('Well Done!', 'Pending import rows have been cancelled');
                  dispatch(fetchViewDS({
                    componentName: ImportView.GetComponentName(),
                    url: importApiUrl(activeOrg.alias, id),
                  }));
                  closeModal();
                } catch (err) {
                  toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                }
              }}
              open={modalIsOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

ImportView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

ImportView.GetComponentName = () => 'ImportView';

export default ImportView;
