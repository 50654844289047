import React from 'react';
import PropTypes from 'prop-types';
import { Overlay } from 'react-bootstrap';

import { ICON, POPPER_PLACEMENT } from 'core/assets/js/constants';
import OverlayBody from 'core/assets/js/components/OverlayBody.jsx';

class TDDropButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(e) {
    const { show } = this.state;
    const { stopPropagation } = this.props;
    /** In cases that we put the dropButton inside an element that has already
     * an onClick event attached to it, we might need to enable the stopPropagation method
     * so that the onClick event of the parent element is not triggered.
     * ie: DropButton inside a finance reports table row.
     * By default this is false.
     */
    if (stopPropagation) {
      e.stopPropagation();
    }

    return this.setState({ show: !show });
  }


  render() {
    const { show } = this.state;
    const {
      children,
      className,
      'data-testid': dataTestId,
      disabled,
      overlayClassName,
      placement,
      onEntered,
      toggleEl,
      popperConfig,
    } = this.props;

    const _className = ['td-drop-button__toggle'];
    if (className) {
      _className.push(className);
    }

    if (show && !disabled) {
      _className.push('td-drop-button__toggle--active');
    }

    return (
      <React.Fragment>
        <span
          className={_className.join(' ')}
          data-testid={dataTestId ? `${dataTestId}-toggle` : ''}
          onClick={this.onToggle}
          ref={(c) => {
            this.target = c;
          }}
        >
          {toggleEl}
        </span>

        <Overlay
          target={() => this.target}
          show={show && !disabled}
          rootClose
          rootCloseEvent="click"
          placement={placement}
          transition={false}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            },
            ...popperConfig,
          }}
          onEntered={onEntered}
          onHide={() => this.setState({ show: false })}
        >
          <OverlayBody
            className={overlayClassName}
            body={children}
            data-testid={dataTestId ? `${dataTestId}-overlay` : ''}
            onClose={() => this.setState({ show: false })}
          />
        </Overlay>
      </React.Fragment>
    );
  }
}

TDDropButton.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  overlayClassName: PropTypes.string,
  toggleEl: PropTypes.node,
  children: PropTypes.any,
  onEntered: PropTypes.func,
  placement: PropTypes.string,
  stopPropagation: PropTypes.bool,
  disabled: PropTypes.bool,
  popperConfig: PropTypes.object,
};

TDDropButton.defaultProps = {
  className: null,
  'data-testid': '',
  overlayClassName: null,
  toggleEl: <i className={ICON.ELLIPSIS} />,
  children: false,
  placement: POPPER_PLACEMENT.BOTTOM_END,
  stopPropagation: false,
  disabled: false,
  popperConfig: {},
  onEntered: () => {},
};

export default TDDropButton;
