import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import OrgWorksheetSettingsTab from 'settings/assets/js/components/OrgWorksheetSettingsTab.jsx';
import OrgInvoiceSettingsTab from 'settings/assets/js/components/OrgInvoiceSettingsTab.jsx';
import OrgPaymentMethodsSettingsTab from 'settings/assets/js/components/OrgPaymentMethodsSettingsTab.jsx';
import OrgTimeTrackerSettingsTab from 'settings/assets/js/components/OrgTimeTrackerSettingsTab.jsx';
import { ORG_SETTINGS_FINANCE_TABS } from 'settings/assets/js/constants';
import { orgSettingsPaymentsUrl } from 'settings/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const OrgSettingsFinanceTab = ({ tab, history, orgAlias }) => {
  switch (tab) {
    case ORG_SETTINGS_FINANCE_TABS.WORKSHEETS:
      return (
        <OrgWorksheetSettingsTab />
      );
    case ORG_SETTINGS_FINANCE_TABS.INVOICES:
      return (
        <OrgInvoiceSettingsTab />
      );
    case ORG_SETTINGS_FINANCE_TABS.PAYMENT_METHODS:
      return (
        <OrgPaymentMethodsSettingsTab />
      );
    case ORG_SETTINGS_FINANCE_TABS.TIME_TRACKER:
      return (
        <OrgTimeTrackerSettingsTab />
      );
    default: // eslint-disable-line no-fallthrough
      history.push(
        orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_FINANCE_TABS.WORKSHEETS),
      );
      return null;
  }
};

OrgSettingsFinanceTab.propTypes = {
  history: routerHistorySpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default withRouter(OrgSettingsFinanceTab);
