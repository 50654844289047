import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import {
  documentSignaturesRequestErrorsApiUrl, documentSignaturesRequestJobView,
} from 'documents/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const DocumentSignaturesRequestJobView = () => {
  const { jobId } = useParams();
  const activeOrg = useSelector(selectActiveOrg);

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Contract signatures request', url: null },
          { title: `#${jobId}`, url: documentSignaturesRequestJobView(activeOrg.alias, jobId) },
        ]}
      />
      <div className="page page--document-signatures-request">
        <div className="container">
          <TDButton
            btnIcon={ICON.DOWNLOAD}
            label="Download errors"
            onClick={() => WINDOW_OPEN(documentSignaturesRequestErrorsApiUrl(
              activeOrg.alias, jobId,
            ))}
            variant={BS_STYLE.PRIMARY}
          />
        </div>
      </div>
    </>
  );
};

DocumentSignaturesRequestJobView.GetComponentName = () => 'DocumentSignaturesRequestJobView';

export default DocumentSignaturesRequestJobView;
