/* eslint no-param-reassign: [
 "error",
 { "props": true, "ignorePropertyModificationsFor": ["staticContext"] }
 ] */

import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Route, Redirect } from 'react-router-dom';


const RedirectRoute = ({ status, from, to, next }) => {
  let targetUrl = to;

  if (next) {
    const [existingQs] = to.split('?').splice(1);

    const queryString = existingQs ? qs.parse(existingQs) : {};
    queryString.next = next;

    targetUrl += `?${qs.stringify(queryString)}`;
  }

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = status;
        }

        return <Redirect from={from} to={targetUrl} />;
      }}
    />
  );
};

RedirectRoute.propTypes = {
  status: PropTypes.number.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  next: PropTypes.string,
};

RedirectRoute.defaultProps = {
  next: null,
};

export default RedirectRoute;
