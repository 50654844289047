import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { ICON } from 'core/assets/js/constants';
import IntegrationSupplierMappingForm from 'integrations/assets/js/components/IntegrationSupplierMappingForm.jsx';
import { INTEGRATION_ENTITY_TYPES } from 'integrations/assets/js/constants';

const IntegrationSupplierMappingCard = ({
  dataTestIdSuffix,
  initiallyExpanded,
  integrationName,
  mapping,
  onCreateSupplier,
  onSupplierMapped,
  remoteType,
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const { isAutoMapped, isConfirmed, local, localId, localType, remote, syncError } = mapping;
  const handleToggleExpanded = () => setIsExpanded(!isExpanded);
  const className = 'supplier-mapping-card row py-4 bg-gray align-items-end';

  const handleSupplierMapped = async (values) => {
    if (!values.remoteId) {
      return;
    }

    await onSupplierMapped({
      localId,
      localType,
      remoteType,
      remoteId: values.remoteId,
    });

    setIsExpanded(false);
  };

  const dataTestId = `integration-supplier-mapping-card_${dataTestIdSuffix}`;

  // Collapsed state
  if (!isExpanded) {
    return (
      <div className={className} data-testid={dataTestId} onClick={handleToggleExpanded}>
        <div className="col-4">
          <div className="row">
            <div className="col-11">
              {local?.summary}
            </div>
            <div className="col-1">
              <i className={ICON.ARROW_RIGHT_LONG} />
            </div>
          </div>
        </div>

        <div className="col-3">
          { remote?.summary || (
            <span className="text-info">Map it</span>
          )}
        </div>

        <div className="col-5">
          <div className="row align-items-center justify-content-end">
            {syncError && (
              <>
                <StatusTag
                  reason={syncError}
                  label="Supplier creation failed"
                  statusClass="removed"
                />
                <div className="ml-3 text-danger"><i className={ICON.CROSS_CIRCLE} /></div>
              </>
            )}
            {isAutoMapped && <StatusTag label="Auto-mapped" statusClass="pending" />}
            {isConfirmed && (
              <div className="ml-3 text-success"><i className={ICON.CHECKMARK_CIRCLE} /></div>
            )}
            <i className={`mx-5 ${ICON.CHEVRON_DOWN}`} />
          </div>
        </div>
      </div>
    );
  }

  // Expanded state
  return (
    <>
      <div className={className} data-testid={dataTestId} onClick={handleToggleExpanded}>
        <div className="col-8">
          {local?.summary}
        </div>

        <div className="col-4">
          <div className="row align-items-center">
            <div className="offset-8 col-2 text-success d-flex justify-content-end">
              {isConfirmed && (
                <i className={ICON.CHECKMARK_CIRCLE} />
              )}
            </div>
            <div className="col-2 d-flex justify-content-end">
              <i className={ICON.CHEVRON_UP} />
            </div>
          </div>
        </div>
      </div>

      <div className={className} data-testid={`${dataTestId}_details`}>
        <div className="col-4">
          <div className="row">
            <div className="col-11">
              {local?.summary}
              <br />
              {local?.details?.join('\n')}
              <br />
              {local?.url && (
                <Link
                  to={local.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  User’s profile
                </Link>
              )}
            </div>
            <div className="col-1">
              <i className={ICON.ARROW_RIGHT_LONG} />
            </div>
          </div>
        </div>

        <div className="col-8">
          <div className="mb-4 search-form">
            <Form
              onSubmit={handleSupplierMapped}
              render={({ handleSubmit }) => (
                <form className="d-block" onSubmit={handleSubmit}>
                  <IntegrationSupplierMappingForm />
                </form>
              )}
            />
          </div>

          {remote && (
            <>
              { remote?.summary }
              <br />
              {remote?.details?.join('\n')}
            </>
          )}
          {!remote && (
            <>
              <p>Search for a supplier to map</p>
              {onCreateSupplier && (
                <p>
                  {`Do you want to create this supplier in ${integrationName}?`}
                  <TDButton
                    className="ml-5"
                    label="Create"
                    onClick={async () => {
                      await onCreateSupplier(local);
                      setIsExpanded(false);
                    }}
                  />
                </p>
              )}
            </>
          )}
          {syncError && (
            <p className="text-danger">
              {`"${local.name}" supplier creation failed:`}
              <br />
              {syncError}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

IntegrationSupplierMappingCard.propTypes = {
  dataTestIdSuffix: PropTypes.string.isRequired,
  initiallyExpanded: PropTypes.bool,
  integrationName: PropTypes.string.isRequired,
  mapping: PropTypes.object.isRequired,
  onCreateSupplier: PropTypes.func,
  onSupplierMapped: PropTypes.func.isRequired,
  remoteType: PropTypes.oneOf(Object.values(INTEGRATION_ENTITY_TYPES)),
};

IntegrationSupplierMappingCard.defaultProps = {
  initiallyExpanded: false,
  onCreateSupplier: null,
  remoteType: INTEGRATION_ENTITY_TYPES.SUPPLIER,
};

export default IntegrationSupplierMappingCard;
