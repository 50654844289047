import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import { ORDERING_DIRECTION } from 'core/assets/js/constants';

const RolesSearch = ({ filtersOpen, location: { search }, onFiltersToggle }) => {
  const searchSpec = {
    defaultOrdering: { direction: ORDERING_DIRECTION.DESC, sortBy: 'createdAt' },
    filters: [],
    orderingOptions: [
      { text: 'Created at', value: 'createdAt' },
    ],
    searchTerm: { component: TextInputField, paramName: 'kw', placeholder: 'Search roles'  },
  };

  const initialValues = pick(
    queryString.parse(search), ['kw'].concat(searchSpec.filters.map(f => f.paramName)),
  );

  return (
    <SearchFinalForm
      filtersOpen={filtersOpen}
      initialValues={initialValues}
      name="roles"
      onFiltersToggle={onFiltersToggle}
      searchSpec={searchSpec}
    />
  );
};

RolesSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(RolesSearch);
