import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { IMG_SIZE } from 'core/assets/js/constants';
import RoundedPic from 'core/assets/js/components/RoundedPic.jsx';

function ReportLogo({ company, 'data-testid': dataTestId, user, className, profilePicFallback }) {
  const invoicable = get(company, 'invoicable');
  const companyName = get(company, 'name');
  const companyLogo = get(company, 'logo');
  const profileName = get(user, 'profile.name');
  const profileAvatar = get(user, 'profile.avatar');

  const shouldRenderCompany = company && invoicable !== false && companyName;
  const name = shouldRenderCompany ? companyName : profileName;

  // Show logo if exists.
  if (shouldRenderCompany && companyLogo) {
    return (
      <div className={className} data-testid={dataTestId}>
        <img alt={companyName} src={companyLogo} className="finance-report__organization-logo" />
      </div>
    );
  }

  // If no logo is found, check if profilePicFallback is enabled and show profile pic.
  // Otherwise show full name.
  let logo = (
    <span className="finance-report__organization-text-logo">{name}</span>
  );
  if (profilePicFallback && profileAvatar) {
    logo = (
      <RoundedPic
        data-testid="report-logo-rounded-pic"
        url={profileAvatar}
        alt={profileName}
        size={[IMG_SIZE.LARGE, IMG_SIZE.LARGE]}
        className={['profile-pic rounded-circle', className].join(' ')}
      />
    );
  }

  return (
    <div className={className} data-testid={dataTestId}>{logo}</div>
  );
}

ReportLogo.propTypes = {
  className: PropTypes.string,
  company: PropTypes.object,
  'data-testid': PropTypes.string,
  user: PropTypes.object,
  profilePicFallback: PropTypes.bool,
};

ReportLogo.defaultProps = {
  className: null,
  company: null,
  'data-testid': '',
  user: {
    profile: {},
  },
  profilePicFallback: null,
};

export default ReportLogo;
