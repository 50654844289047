import axios from 'core/assets/js/lib/tdAxios';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { submissionUrl, answerSyncApiUrl } from 'interviews/urls';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';


class AnswerSyncView extends React.Component {
  static GetComponentName() {
    return 'AnswerSyncView';
  }

  componentDidMount() {
    const { history, match } = this.props;
    const { orgAlias, submissionId, questionId } = match.params;
    const apiUrl = answerSyncApiUrl(orgAlias, submissionId, questionId);
    return axios.post(apiUrl).then(() => {
      history.push(submissionUrl(orgAlias, submissionId));
    });
  }

  render() {
    return (
      <LoadingComponent />
    );
  }
}

AnswerSyncView.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(AnswerSyncView);
