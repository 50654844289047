import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_SIZE, BS_STYLE } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { getViewState } from 'core/assets/js/ducks/view';
import TaskAssignmentChangeCapModal from 'projects/assets/js/components/TaskAssignmentChangeCapModal.jsx';
import TaskReOpenModal from 'projects/assets/js/components/TaskReOpenModal.jsx';
import { WorkTimeBlockTable } from 'projects/assets/js/components/timeTracker/TimeTrackerUnused.jsx';
import { removeTaskMemberDS } from 'projects/assets/js/data-services/tasks';
import { fetchTimeTrackerUnusedRecordsDS } from 'projects/assets/js/data-services/view';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';

export const CANNOT_ADJUST_RATE_MODAL_ID = 'task-assignment-cannot-adjust-rate-modal';
export const CHANGE_CAP_MODAL_ID = 'task-assignment-change-cap-modal';
export const REMOVE_MODAL_ID = 'task-assignment-remove-modal';
export const SKILLS_MODAL_ID = 'task-assignment-skills-modal';

const TaskAssignmentModals = ({ listViewComponentName, parentComponentName, task }) => {
  const dispatch = useDispatch();
  const { id: projectId, orgAlias, taskId } = useParams();

  const closeModal = () => dispatch(modalCloseAC());

  const adjustRateModalIsOpen = useSelector(
    state => getIsModalOpen(state, CANNOT_ADJUST_RATE_MODAL_ID),
  );
  const adjustRateModalPayload = useSelector(
    state => getModalPayload(state, CANNOT_ADJUST_RATE_MODAL_ID),
  );
  const skillsModalIsOpen = useSelector(state => getIsModalOpen(state, SKILLS_MODAL_ID));
  const skillsModalPayload = useSelector(state => getModalPayload(state, SKILLS_MODAL_ID));

  const removeModalIsOpen = useSelector(state => getIsModalOpen(state, REMOVE_MODAL_ID));
  const removeModalPayload = useSelector(state => getModalPayload(state, REMOVE_MODAL_ID));

  const onRemoveTaskMemberConfirmation = ({ deleteWorkTimeBlocks = false } = { }) => (
    dispatch(removeTaskMemberDS({
      assignmentId: removeModalPayload?.assignment?.id,
      componentName: parentComponentName,
      deleteWorkTimeBlocks,
      listViewComponentName,
      orgAlias,
      projectId,
      taskId,
      userId: removeModalPayload.assignment.userId,
    }))
  );

  const componentName = TaskAssignmentModals.GetComponentName();

  useEffect(() => {
    if (removeModalIsOpen) {
      dispatch(fetchTimeTrackerUnusedRecordsDS({
        componentName, orgAlias, userId: removeModalPayload?.assignment?.userId,
      }));
    }
  }, [removeModalIsOpen]);

  const { item: unusedWorkTimeBlocks } = useSelector(state => getViewState(state, componentName));

  const taskRelatedWorkTimeBlocks = unusedWorkTimeBlocks
    ?.projects?.find(p => p.id === parseInt(projectId, 10))
    ?.workTimeBlocks?.filter(tb => tb.taskId === parseInt(taskId, 10));

  const hasUnusedTimeBlocks = taskRelatedWorkTimeBlocks && taskRelatedWorkTimeBlocks.length > 0;

  return (
    <>
      <ModalSimple
        body={(
          <TDSystemMessage
            className="mb-4"
            title="You can't adjust the agreed rate!"
            type={BS_STYLE.DANGER}
          >
            {adjustRateModalPayload?.assignment?.allowedActions.canProposeNewTaskRate.reasonLabel}
          </TDSystemMessage>
        )}
        heading="Adjust agreed rate"
        onClose={closeModal}
        open={adjustRateModalIsOpen}
      />
      <TaskAssignmentChangeCapModal
        listViewComponentName={listViewComponentName}
        modalId={CHANGE_CAP_MODAL_ID}
      />
      <ModalConfirm
        confirmLabel="Remove user"
        confirmStyle={BS_STYLE.DANGER}
        data-testid="remove-user-confirm"
        heading={`Remove ${removeModalPayload?.assignment?.user.profile.name} from the task?`}
        open={removeModalIsOpen}
        onClose={closeModal}
        onConfirm={() => onRemoveTaskMemberConfirmation()}
        onSecondaryAction={() => onRemoveTaskMemberConfirmation({
          deleteWorkTimeBlocks: true,
        })}
        secondaryActionLabel={hasUnusedTimeBlocks ? 'Delete all tracked times & Remove user' : null}
        secondaryActionStyle={BS_STYLE.DANGER}
        size={BS_SIZE.LARGE}
      >
        {hasUnusedTimeBlocks && (
          <>
            <TDSystemMessage
              className="mb-4"
              title="This provider has unpaid work on this Task "
              type={BS_STYLE.WARNING}
            >
              <div className="text-dark">
                <p>
                  {`${removeModalPayload?.assignment?.user.profile.name} currently has `}
                  time-tracked work that needs to be added to a Worksheet before you can remove
                  {' them from the Task. '}
                  <strong>By continuing this action</strong>
                  {' the following actions will occur automatically:'}
                </p>
                <ol>
                  <li>
                    If the Provider is actively tracking time on the Task, their time tracker
                    will be stopped and the time record saved.
                  </li>
                  <li>
                    All time-tracked work on this Task will be converted to a Worksheet for
                    approval (or auto-approved if it’s within their Invoice Cap).
                  </li>
                </ol>
              </div>
            </TDSystemMessage>

            <p>
              {`Here is ${removeModalPayload?.assignment?.user.profile.name}'s time-tracked work `}
              for the task:
            </p>

            <WorkTimeBlockTable
              readOnly
              workTimeBlocks={taskRelatedWorkTimeBlocks}
            />

            <div className="time-tracker-total p-4 d-flex justify-content-end mb-5">
              <div className="label">Total amount</div>
              <div className="amount">
                <NumberTpl
                  prefix={removeModalPayload?.assignment?.currencySymbol}
                  value={taskRelatedWorkTimeBlocks.reduce(
                    (acc, { amount }) => acc + (amount ? parseFloat(amount) : 0), 0,
                  ).toFixed(2)}
                />
              </div>
            </div>
          </>
        )}

        <p>
          {`If you remove ${removeModalPayload?.assignment?.user.profile.name} from the task, `}
          they won’t be able to track time, send messages, collaborate, submit deliverables or raise
          Worksheets for this task.
        </p>
        <p>
          {`Are you sure you want to remove ${removeModalPayload?.assignment?.user.profile.name} `}
          from the task?
        </p>
      </ModalConfirm>
      <ModalSimple
        body={(
          <ul className="bullet-list">
            {skillsModalPayload?.assignment?.user.profile.skills.map(skill => (
              <li>{skill.label}</li>
            ))}
          </ul>
        )}
        heading={`${skillsModalPayload?.assignment?.user.profile.name}'s skills`}
        onClose={closeModal}
        open={skillsModalIsOpen}
      />
      <TaskReOpenModal
        listViewComponentName={listViewComponentName}
        parentComponentName={parentComponentName}
        task={task}
      />
    </>
  );
};

TaskAssignmentModals.GetComponentName = () => 'TaskAssignmentModals';

TaskAssignmentModals.propTypes = {
  listViewComponentName: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
};

export default TaskAssignmentModals;
