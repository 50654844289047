import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Filebox from 'core/assets/js/components/FileUploader/Filebox.jsx';

/**
 * Returns a list of attachments
 * @param {Array} attachments file attachments
 * @param {String} label The label of the file attachments
 * @param {Boolean} invertedColors
 * @returns {null | Array<Filebox>}
 */
const AttachmentsList = ({ attachments, 'data-testid': dataTestId, label, invertedColors }) => {
  if (isEmpty(attachments)) {
    return null;
  }

  return (
    <div className="attachments-list" data-testid={dataTestId}>
      {label && (<p className="font-weight-bolder attachments-list__label">{label}</p>)}
      {attachments.map(file => (
        <Filebox invertedColors={invertedColors} key={file.handle || file} file={file} />
      ))}
    </div>
  );
};

AttachmentsList.propTypes = {
  attachments: PropTypes.array,
  'data-testid': PropTypes.string,
  invertedColors: PropTypes.bool,
  label: PropTypes.string,
};

AttachmentsList.defaultProps = {
  attachments: [],
  'data-testid': '',
  invertedColors: false,
  label: 'Attachments',
};

export default AttachmentsList;
