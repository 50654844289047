import { STATUS } from 'invitations/assets/js/constants';
import { loginUrl } from 'accounts/urls';
import { projectListUrl } from 'projects/urls';
import { invitationManageUrl, invitationStatusUrl } from 'invitations/urls';

// eslint-disable-next-line import/prefer-default-export
export const determinePostPublicInviteUrl = (invitation, { isLoggedIn = false } = {}) => {
  // The inviation is already accepted and the user is already member of the org
  if (invitation.status === STATUS.ACCEPTED) {
    // the user is logged in, should be redirected to the org's project list
    if (isLoggedIn) {
      return projectListUrl(invitation.orgAlias);
    }

    // The user is not logged in, should view the org's project list after log in
    return loginUrl(projectListUrl(invitation.orgAlias));
  }

  const token = invitation.token;

  // if the invitation is bound to a user, use the invitation page url and
  // prompt the user to login (if not already)
  if (invitation.userId) {
    const acceptUrl = invitationManageUrl(token);
    return isLoggedIn ? acceptUrl : loginUrl(acceptUrl);
  }

  // Otherwise use the signup url
  return !isLoggedIn ? invitationStatusUrl(token) : invitationManageUrl(token);
};
