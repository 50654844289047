import React from 'react';

import IntegrationGeneralSettings from 'integrations/assets/js/components/settings/IntegrationGeneralSettings.jsx';

const IntegrationSetupGeneralSettings = () => (
  <>
    <h2>Settings</h2>
    <IntegrationGeneralSettings />
  </>
);

export default IntegrationSetupGeneralSettings;
