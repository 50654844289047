import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalOpenAC, modalCloseAC, getModalPayload, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import { routerMatchSpec, routerHistorySpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import CustomFieldsTemplateCard from 'settings/assets/js/components/CustomFieldsTemplateCard.jsx';
import CustomFieldTemplatesSearch from 'settings/assets/js/components/CustomFieldTemplatesSearch.jsx';
import { deleteCustomFieldTemplateDS } from 'interviews/assets/js/data-services/interviews';
import { customFieldTemplateTypeSelectUrl } from 'settings/urls';
import { customFieldsTemplateListApiUrl } from 'interviews/urls';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import { FIELD_ENTITY_TYPE, ENTITY_TYPE_LABEL, FIELD_ENTITY_TYPE_TO_URL_PARAM } from 'interviews/assets/js/constants';


const DELETE_MODAL_ID = 'delete-confirmation-modal';
export const storeKey = 'TaskTemplatesListView';

const fetchData = ({
  dispatch, url, authedAxios, componentName, querystring, params,
}) => dispatch(fetchListDS({
  authedAxios, componentName, querystring, params,
  url: customFieldsTemplateListApiUrl({
    orgAlias: params.orgAlias,
    url,
  }),
}));

const CustomFieldTemplatesTab = ({
  location, items, pagination, match, dispatch, filtersOpen, onFiltersToggle,
  deleteModalOpen, deleteModalPayload, history,
}) => {
  const { params: { orgAlias, entityType } } = match;
  const handleDeleteModalOpen = (item) => {
    dispatch(modalOpenAC(DELETE_MODAL_ID, item));
  };

  const handleDeleteModalClose = () => {
    dispatch(modalCloseAC(DELETE_MODAL_ID));
  };

  const handleDeleteTemplate = async () => {
    try {
      dispatch(deleteCustomFieldTemplateDS({
        orgAlias,
        entityType: FIELD_ENTITY_TYPE_TO_URL_PARAM[deleteModalPayload.entityType],
        templateId: deleteModalPayload.id,
      })).then(() => {
        toastr.success('Well done!', 'Task template was deleted!');
        fetchData({
          dispatch,
          params: { orgAlias },
          url: '',
          authedAxios: null,
          componentName: storeKey,
          querystring: location.search,
        });
      }).catch(() => {
        toastr.error('Task template was not deleted!');
      });
    } catch (err) {
      toastr.error('Task template was not deleted!', err.message);
    } finally {
      handleDeleteModalClose();
    }
  };

  return (
    <React.Fragment>
      <CustomFieldTemplatesSearch
        onFiltersToggle={onFiltersToggle}
        filtersOpen={filtersOpen}
      />

      {!filtersOpen && (
        <React.Fragment>
          <div className="rounded shadow-sm p-4 bg-white">
            <TDList
              items={items}
              cardItem={{
                component: CustomFieldsTemplateCard,
                history,
                props: {
                  orgAlias,
                  entityType,
                  onDelete: item => handleDeleteModalOpen(item),
                },
              }}
              emptyListMessage={(
                <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
                  <i className={ICON.NOTE} />
                  You haven&apos;t added any templates
                  <br />
                  <Link to={customFieldTemplateTypeSelectUrl(orgAlias)}>
                    Create template
                  </Link>
                </div>
              )}
              listClassName="invitation-templates-list"
            />
          </div>

          <div className="my-4">
            { pagination && pagination.end > 0 && (
              <TDPagination {...pagination} />
            )}
          </div>
        </React.Fragment>
      )}

      <ModalConfirm
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteTemplate}
        open={deleteModalOpen}
        heading="Confirm deletion"
        body={(
          <React.Fragment>
            {!!deleteModalPayload?.usages && (
              <p>
                This
                {' '}
                {deleteModalPayload?.entityType && (
                  <React.Fragment>
                    {ENTITY_TYPE_LABEL[deleteModalPayload.entityType].toLowerCase()}
                    {' '}
                  </React.Fragment>
                )}
                template has been used
                {' '}
                {deleteModalPayload.usages}
                {' '}
                times. By deleting it you will also delete all custom fields associated with it
                {deleteModalPayload?.entityType === FIELD_ENTITY_TYPE.USER && (
                  'and as a result, all relevant information from the user profiles and contacts that use it'
                )}
                .
              </p>
            )}
            <p>
              {'Are you sure you want to delete '}
              <strong>{deleteModalPayload?.name}</strong>
              ?
            </p>
          </React.Fragment>
        )}
        confirmLabel="Delete"
        confirmStyle={BS_STYLE.DANGER}
      />

    </React.Fragment>
  );
};

CustomFieldTemplatesTab.propTypes = {
  match: routerMatchSpec.isRequired,
  pagination: paginationSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  deleteModalOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
  deleteModalPayload: PropTypes.object,
  history: routerHistorySpec.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

CustomFieldTemplatesTab.defaultProps = {
  items: [],
  params: {},
  deleteModalOpen: false,
  deleteModalPayload: {},
};
const mapStateToProps = state => ({
  deleteModalOpen: getIsModalOpen(state, DELETE_MODAL_ID),
  deleteModalPayload: getModalPayload(state, DELETE_MODAL_ID),
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFieldTemplatesTab);

const ConnectedList = withTDApiConnected({
  fetchData,
  duck: 'list',
  storeKey,
  skeletonComponent: ListSkeleton,
})(withFilters(ListConnected));

export default ConnectedList;
