import axios from 'core/assets/js/lib/tdAxios';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import { fetchProviderReviewsApiUrl, fetchProviderApiUrl, postReviewApiUrl } from 'people/urls';
import { validateObject } from 'core/assets/js/lib/utils';

// review action types
export const PEOPLE_REVIEWS_LOAD_SUCCESS = 'people/REVIEWS_LOAD_SUCCESS';
export const PEOPLE_REVIEWS_RESET = 'people/REVIEWS_RESET';
export const PEOPLE_REVIEWS_POST_SUCCESS = 'people/REVIEWS_POST_SUCCESS';

// review action creators
export const reviewsLoadSuccessAC = reviews => ({
  type: PEOPLE_REVIEWS_LOAD_SUCCESS,
  items: reviews,
});

export const reviewsResetAC = () => ({
  type: PEOPLE_REVIEWS_RESET,
});

export const reviewsPostSuccessAC = review => ({
  type: PEOPLE_REVIEWS_POST_SUCCESS,
  item: review,
});

/**
 * Handles the reviews list related state
 * @param state
 * @param action
 * @returns {*}
 */
const reviewListInitialState = {
  items: [],
};
const reviewList = (state = reviewListInitialState, action) => {
  switch (action.type) {
    case PEOPLE_REVIEWS_LOAD_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case PEOPLE_REVIEWS_RESET:
      return { ...reviewListInitialState };
    case PEOPLE_REVIEWS_POST_SUCCESS:
      return {
        ...state,
        items: [action.item, ...state.items],
      };
    default:
      return state;
  }
};

const initialState = {
  reviewList: reviewListInitialState,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PEOPLE_REVIEWS_LOAD_SUCCESS:
    case PEOPLE_REVIEWS_RESET:
    case PEOPLE_REVIEWS_POST_SUCCESS:
      return {
        ...state,
        reviewList: reviewList(state.reviewList, action),
      };
    default:
      return state;
  }
};

export const fetchReviewsDS = ({ url = '', params = {}, authedAxios = null }) => (
  fetchDataDS({
    authedAxios,
    validate: () => validateObject(params, ['orgAlias', 'userId']),
    fetchApiUrl: () => fetchProviderReviewsApiUrl(params.orgAlias, params.userId, url),
    fetchDataAC: responseData => reviewsLoadSuccessAC(responseData),
  })
);

export const postReviewDS = (params, values) => {
  validateObject(params, ['orgAlias', 'userId']);
  return axios.post(postReviewApiUrl(params.orgAlias, params.userId, values.project_id), values);
};

export const fetchProviderDS = ({ url = '', params, authedAxios, componentName }) => (
  fetchViewDS({
    validate: () => validateObject(params, ['orgAlias', 'userId']),
    url: fetchProviderApiUrl(params.orgAlias, params.userId, url),
    componentName,
    authedAxios,
  })
);

export default reducer;
