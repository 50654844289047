import { toastr } from 'react-redux-toastr';

import { ICON } from 'core/assets/js/constants';
import {
  FIELD_TYPE, INTEGRATION_DETAILS_TABS, INTERPOLATION_ITEM_TYPES_VALUES, TAX_RATE_FIELD_PREFIX,
} from 'integrations/assets/js/constants';
import { orgIntegrationDetailUrl } from 'integrations/urls';

/**
 * Returns the icon class related to the field type (eg. a calendar for text)
 *
 * @param {String} type the type of the field
 * @returns {String}
 */
export const getIconByFieldType = (type) => {
  switch (type) {
    case FIELD_TYPE.TEXT:
      return ICON.TEXT;
    case FIELD_TYPE.DATE:
      return ICON.CALENDAR_ALT;
    case FIELD_TYPE.NUMBER:
      return ICON.NUMBER_ONE;
    case FIELD_TYPE.CUSTOM:
      return ICON.CODE_SOLID;
    default:
      return ICON.TEXT;
  }
};

/**
 * Validates a value for a mapping field
 *
 * @param {String} value the value provided
 * @param {Array} placeholders the placeholders to validate against
 * @param {Object} options
 * @param {Boolean} options.required whether a value is required
 * @returns {undefined} if the value is valid
 * @throws {Error} if the value is invalid
 */
export const validateMappingField = (value, placeholders = [], { required = false } = {}) => {
  if (!value) {
    if (required) {
      throw new Error('You have to select a value');
    }

    return undefined;
  }

  let leftBrackets = 0;
  let rightBrackets = 0;
  String(value).split('').forEach((str) => {
    if (str === '{') {
      leftBrackets += 1;
    } else if (str === '}') {
      rightBrackets += 1;
    }
  });

  if (leftBrackets !== rightBrackets) {
    throw new Error('The template is malformed. All curly brackets must match');
  }

  // Look for invalid entries
  if (String(value).match(/{{(|[\s\W0-9]+)}}/)) {
    throw new Error('Some of the placeholders used are invalid');
  }

  const matches = String(value).match(/({{[\w.0-9_]+}})/gi) || [];
  if (matches.some(match => !placeholders.includes(match))) {
    throw new Error('Some of the placeholders used are invalid');
  }

  return undefined;
};

export const handleIncompleteMappingError = async (apiCall, orgAlias, integrationId, history) => {
  let response = null;
  try {
    response = await apiCall();
  } catch (err) {
    let errorMessage = err._error || err.message;
    if (err.errors) {
      const errorTabs = [
        ['systemOrgMapping', INTEGRATION_DETAILS_TABS.SUPPLIER_MAPPING],
        ['contractorsMapping', INTEGRATION_DETAILS_TABS.SUPPLIER_MAPPING],
        ['accountMapping', INTEGRATION_DETAILS_TABS.ACCOUNT_MAPPING],
        ['invoiceMapping', INTEGRATION_DETAILS_TABS.INVOICE_MAPPING],
        ['settings', INTEGRATION_DETAILS_TABS.SETTINGS],
      ];
      const errorTabsLength = errorTabs.length;
      for (let i = 0; i < errorTabsLength; i += 1) {
        const [errorKey, tab] = errorTabs[i];
        if (err.errors[errorKey]) {
          errorMessage = err.errors[errorKey];
          history.push(orgIntegrationDetailUrl(orgAlias, integrationId, tab));
          break;
        }
      }
    }
    toastr.error('Oh Snap!', errorMessage);
  }
  return response;
};

export const taxRateRegExp = new RegExp(
  `^${TAX_RATE_FIELD_PREFIX}(${INTERPOLATION_ITEM_TYPES_VALUES.join('|')}):\\d+$`,
);

export const getTaxRatePercent = values => {
  const firstTaxRateFieldName = Object.keys(values).find(name => taxRateRegExp.test(name));
  return firstTaxRateFieldName ? parseInt(firstTaxRateFieldName.split(':')[2], 10) : null;
};
