import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { TYPE, CUSTOM_FIELD_PAYLOAD_DEFAULTS, FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { ICON, BS_STYLE, BS_TOOLTIP_PLACEMENT, USER_TYPE, USER_TYPE_LABEL } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

/*
  IMPORTANT
  If the field types below change, then our public API custom field schema documentation will need
  updated. Specifically the enum for the type property:
  api/endpoints/shared/docs/schemas/CustomField.yaml
*/

const TemplateControlSelector = ({ onAdd, templateType }) => {
  const onItemAdd = ((item, payload = {}) => {
    const params = {
      required: true,
      isSearchable: true,
      ...item,
      payload: {
        ...CUSTOM_FIELD_PAYLOAD_DEFAULTS,
        ...payload,
      },
    };

    if (templateType === FIELD_ENTITY_TYPE.USER) {
      Object.assign(params, {
        answeredByUserType: USER_TYPE.MANAGER,
      });
    }

    return onAdd(params);
  });

  const visibleTo = Object.values(USER_TYPE).map(type => ({
    value: type,
    text: USER_TYPE_LABEL[type],
  }));

  return (
    <Sticky scrollElement=".page" boundaryElement=".page">
      <div className="template-builder__controls">
        <TDButton
          block
          variant={BS_STYLE.LINK}
          onClick={() => onItemAdd({
            type: TYPE.TEXT,
          }, { visibleTo })}
        >
          <TDElementWithTooltip
            delay={500}
            tooltipMsg="Add text field"
            placement={BS_TOOLTIP_PLACEMENT.RIGHT}
          >
            <div>
              <span className={ICON.TEXTFIELD} />
              Text Field
            </div>
          </TDElementWithTooltip>
        </TDButton>

        <TDButton
          block
          variant={BS_STYLE.LINK}
          onClick={() => onItemAdd({ type: TYPE.MONEY }, { visibleTo })}
        >
          <TDElementWithTooltip
            delay={500}
            tooltipMsg="Add money field"
            placement={BS_TOOLTIP_PLACEMENT.RIGHT}
          >
            <div>
              <span className={ICON.COINS} />
              Money
            </div>
          </TDElementWithTooltip>
        </TDButton>

        <TDButton
          block
          variant={BS_STYLE.LINK}
          onClick={() => onItemAdd({
            type: TYPE.SELECT,
          }, { visibleTo })}
        >
          <TDElementWithTooltip
            delay={500}
            tooltipMsg="Add selection field"
            placement={BS_TOOLTIP_PLACEMENT.RIGHT}
          >
            <div>
              <span className={ICON.RADIO} />
              Selection
            </div>
          </TDElementWithTooltip>
        </TDButton>

        <TDButton
          block
          variant={BS_STYLE.LINK}
          onClick={() => onItemAdd({
            type: TYPE.FILE,
            isSearchable: false,
          }, { visibleTo })}
        >
          <TDElementWithTooltip
            delay={500}
            tooltipMsg="Add attachment field"
            placement={BS_TOOLTIP_PLACEMENT.RIGHT}
          >
            <div>
              <span className={ICON.ATTACHMENT} />
              Attachment
            </div>
          </TDElementWithTooltip>
        </TDButton>

        <TDButton
          block
          variant={BS_STYLE.LINK}
          onClick={() => onItemAdd({ type: TYPE.DATE }, { visibleTo })}
        >
          <TDElementWithTooltip
            delay={500}
            tooltipMsg="Add date field"
            placement={BS_TOOLTIP_PLACEMENT.RIGHT}
          >
            <div>
              <span className={ICON.CALENDAR_ALT} />
              Date
            </div>
          </TDElementWithTooltip>
        </TDButton>

        <TDButton
          block
          variant={BS_STYLE.LINK}
          onClick={() => onItemAdd({ type: TYPE.YESNO }, { visibleTo })}
        >
          <TDElementWithTooltip
            delay={500}
            tooltipMsg="Add Yes/No field"
            placement={BS_TOOLTIP_PLACEMENT.RIGHT}
          >
            <div>
              <span className={ICON.CHECKMARK} />
              Yes/No
            </div>
          </TDElementWithTooltip>
        </TDButton>

        <TDButton
          block
          variant={BS_STYLE.LINK}
          onClick={() => onItemAdd({
            type: TYPE.TEXT_BLOB,
            isSearchable: false,
          }, { visibleTo })}
        >
          <TDElementWithTooltip
            delay={500}
            tooltipMsg="Add text blob field"
            placement={BS_TOOLTIP_PLACEMENT.RIGHT}
          >
            <div>
              <span className={ICON.TEXT_BLOB} />
              Text blob
            </div>
          </TDElementWithTooltip>
        </TDButton>
      </div>
    </Sticky>
  );
};

TemplateControlSelector.propTypes = {
  onAdd: PropTypes.func.isRequired,
  templateType: PropTypes.number,
};

TemplateControlSelector.defaultProps = {
  templateType: null,
};

export default TemplateControlSelector;
