import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { get, set, pick } from 'lodash';

import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { BANK_ACCOUNT_TYPE, PAYMENT_METHODS, PAYMENT_METHOD_DETAILS } from 'settings/assets/js/constants';
import { BANK_CURRENCY, BS_STYLE, USER_CARD_STATUS } from 'core/assets/js/constants';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { calculateAccountCompletePercentage } from 'accounts/assets/js/lib/helpers';
import { createBankAccountDS, updateBankAccountDS } from 'settings/assets/js/data-services/settings';
import { orgGetStartedUrl } from 'accounts/urls';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg, selectUserCards } from 'organizations/assets/js/reducers/organizations';
import { selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { settingsPaymentsSubPageUrl, settingsBankAccountOrgSelectUrl } from 'settings/urls';

class PaypalForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(values) {
    const { activeOrg, bankAccount, dispatch, history, steps, userCards } = this.props;
    const hasBankAccount = !!get(bankAccount, 'id');
    const accountCompletePercentage = calculateAccountCompletePercentage(steps, activeOrg.alias);

    const hasActiveUserCards = !!(userCards && userCards.find(
      uc => uc.status === USER_CARD_STATUS.APPROVED,
    ));
    const paymentsUrl = settingsPaymentsSubPageUrl(activeOrg.alias);

    const toPost = {
      id: bankAccount.id,
      paymentMethodType: PAYMENT_METHODS.PAYPAL,
      bank_account_type: BANK_ACCOUNT_TYPE.PAYPAL,
      beneficiary: values.handle,
      ...pick(values, ['alias', 'handle', 'currency', 'custom_reference']),
    };

    try {
      let selectionUrl;
      if (hasBankAccount) {
        await updateBankAccountDS(toPost, dispatch);
        selectionUrl = settingsBankAccountOrgSelectUrl(
          activeOrg.alias, PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.PAYPAL].alias, bankAccount.id,
        );
      } else {
        const newBankAccount = await createBankAccountDS(activeOrg.alias, toPost, dispatch);
        selectionUrl = settingsBankAccountOrgSelectUrl(
          activeOrg.alias, PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.PAYPAL].alias, newBankAccount.id,
        );
      }

      if (accountCompletePercentage < 100) {
        history.push({
          pathname: orgGetStartedUrl(activeOrg.alias),
          state: { moveToNextStep: true },
        });
        return null;
      }

      history.push(hasActiveUserCards ? selectionUrl : paymentsUrl);
    } catch (err) {
      const res = { [FORM_ERROR]: err.errors._error };
      Object.entries(err.errors).forEach(([key, value]) => {
        set(res, key, value);
      });
      return res;
    }
    return null;
  }

  render() {
    const { activeOrg, bankAccount, fromGetStarted, history } = this.props;
    const hasBankAccount = !!get(bankAccount, 'id');
    let initialValues = {
      currency: BANK_CURRENCY.GBP,
    };
    if (hasBankAccount) {
      initialValues = bankAccount;
    }
    return (
      <Form
        onSubmit={this.submit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className="p-0">
              <div className="rounded bg-white p-4 shadow-sm">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <TextInputField
                      name="handle"
                      required
                      label="Paypal email or phone"
                      placeholder="e.g someone@example.com"
                      validate={val => (!val ? 'Field is required' : null)}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <CurrencySelectField
                      required
                      validate={val => (!val ? 'Field is required' : null)}
                      isClearable={false}
                      name="currency"
                      label="Currency"
                      defaultOptionText={null}
                      currencyOptions={BANK_CURRENCY}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <TextInputField
                      name="alias"
                      required
                      label="Friendly name for your account"
                      validate={val => (!val ? 'Field is required' : null)}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <TextInputField
                      name="custom_reference"
                      placeholder="e.g acustomreference"
                      extraInfo="You can add a custom reference message. We'll ensure we pass it to the bank every time we make a transfer."
                      label="Payee reference message"
                    />
                  </div>
                </div>
              </div>
              <div className="button-container mt-4 text-right">
                <TDButton
                  className="btn btn-lg btn-default"
                  label="Cancel"
                  type="button"
                  onClick={() => {
                    history.push(settingsPaymentsSubPageUrl(activeOrg.alias));
                  }}
                />
                <TDButton
                  disabled={submitting}
                  label={fromGetStarted ? 'Save & continue' : 'Save'}
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />
              </div>
            </form>
          );
        }}
      />
    );
  }
}

PaypalForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  bankAccount: bankAccountSpec,
  dispatch: PropTypes.func.isRequired,
  fromGetStarted: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  steps: PropTypes.object.isRequired,
  userCards: PropTypes.arrayOf(userCardSpec).isRequired,
};

PaypalForm.defaultProps = {
  bankAccount: {},
  fromGetStarted: false,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  steps: selectSetupSteps(state),
  userCards: selectUserCards(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PaypalFormConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(PaypalForm);

export default withRouter(PaypalFormConnected);
