import React from 'react';
import PropTypes from 'prop-types';

import { SETTINGS_SKILL_TABS } from 'settings/assets/js/constants';
import OrgSkillsListView from 'settings/assets/js/OrgSkillsListView.jsx';
import SkillDirectoryList from 'skills/assets/js/components/SkillDirectoryList.jsx';

/**
 * Returns different manage skill tab depending which tab is active
 * @param tab The active tab
 * @returns {Component}
 */
const ManageSkillTabContent = ({ tab }) => {
  switch (tab) {
    case SETTINGS_SKILL_TABS.SKILLS:
      return (
        <OrgSkillsListView />
      );
    case SETTINGS_SKILL_TABS.SKILL_DIRECTORIES:
      return (
        <SkillDirectoryList />
      );
    default:
      return (
        <OrgSkillsListView />
      );
  }
};

ManageSkillTabContent.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default ManageSkillTabContent;
