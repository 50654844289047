import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { BS_SIZE, BS_STYLE } from 'core/assets/js/constants';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';

export const ORG_SKILL_DELETE_ID = 'org-skill-delete-modal';

class OrgSkillModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const { open, skill, onConfirm } = this.props;
    return (
      <ModalConfirm
        open={open}
        onClose={this.handleModalClose}
        onConfirm={onConfirm}
        size={BS_SIZE.LARGE}
        body={(
          <React.Fragment>
            { (skill.usedInProfileCount !== 0 || skill.usedInProjectCount !== 0)
            && (
              <p>
                There are
                <strong>{` ${skill.usedInProfileCount} `}</strong>
                users and
                <strong>{` ${skill.usedInProjectCount} `}</strong>
                projects which have selected this skill in their profile.
              </p>
            )}
            <p>
              Deleting this skill means that the skill will be removed
              from the user profiles and projects as well.
              Are you sure you want to delete the skill
              <strong>{` "${skill.label}"`}</strong>
              ?
            </p>
          </React.Fragment>
        )}
        confirmLabel="Delete"
        confirmStyle={BS_STYLE.DANGER}
        heading={`Are you sure you want to delete the skill "${skill.label}" ?`}
      />
    );
  }
}

OrgSkillModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  skill: skillSpec,
  open: PropTypes.bool,
};

OrgSkillModal.defaultProps = {
  open: false,
  skill: null,
};

const mapStateToProps = state => ({
  skill: getModalPayload(state, ORG_SKILL_DELETE_ID),
  open: getIsModalOpen(state, ORG_SKILL_DELETE_ID),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgSkillModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgSkillModal);

export default OrgSkillModalConnected;
