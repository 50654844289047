export const orgOnboardCreateOrgUrl = (allowNewOrg = null) => (
  `/org/onboard${allowNewOrg === 'allowNewOrg' ? '?allowNewOrg=td' : ''}`
);
export const orgOnboardStepUrl = (step, orgAlias = null, allowNewOrg = null) => (
  `/${orgAlias || 'org'}/onboard${step ? `/${step}` : ''}${allowNewOrg === 'allowNewOrg' ? '?allowNewOrg=td' : ''}`
);
export const orgViewUrl = orgAlias => (
  `/${orgAlias}`
);
export const orgPlanStatusUrl = orgAlias => (
  `/${orgAlias}/plan-status`
);

export const amlUrl = orgAlias => `/${orgAlias}/aml`;

export const orgDashboardUrl = (orgAlias, url = '') => (
  `${url}/${orgAlias}/dashboard`
);

// Api urls
export const orgCreateOrgApiUrl = () => (
  '/api/organizations/create'
);
export const orgFetchPublicApiUrl = (orgAlias, url = '') => (
  `${url}/api/organizations/${orgAlias}`
);
export const orgFetchSystemOrgApiUrl = () => (
  '/api/organizations/system-org'
);
export const userCardsApiUrl = (url = '') => (
  `${url}/api/organizations/usercards`
);

export const onboardingProgressListApiUrl = (url = '') => (
  `${url}/api/profile/progress`
);

export const orgInviteManagersApiUrl = orgAlias => (
  `/api/${orgAlias}/organizations/invite-managers`
);
export const orgInviteProvidersApiUrl = orgAlias => (
  `/api/${orgAlias}/organizations/invite-providers`
);
export const orgSearchManagersWithBudgetApiUrl = (orgAlias, kw = '', budget) => (
  `/api/${orgAlias}/organizations/search-managers-with-budget?kw=${encodeURIComponent(kw.trim())}&budget=${budget}`
);
export const orgSearchManagersStrictApiUrl = (
  orgAlias, kw = '', projectId = null,
) => (
  `/api/${orgAlias}/organizations/search-managers-strict?kw=${encodeURIComponent(kw.trim())}&project=${projectId}`
);
export const orgSearchManagersApiUrl = (
  orgAlias, kw = '', projectId = null,
) => (
  `/api/${orgAlias}/organizations/search-managers?kw=${encodeURIComponent(kw.trim())}&project=${projectId}`
);
export const orgSearchProvidersApiUrl = (
  orgAlias, kw = '', projectId = null,
) => (
  `/api/${orgAlias}/organizations/search-providers?kw=${encodeURIComponent(kw.trim())}&project=${projectId}`
);
export const organizationsUrl = orgAlias => `/${orgAlias}/organizations`;

export const orgMakeDefaultApiUrl = orgAlias => (
  `/api/${orgAlias}/organizations/make-default`
);

export const orgGetOutstandingCountsApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/organizations/${userId}/outstanding-counts`
);

export const orgGetOutstandingItemsToActionApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/organizations/${userId}/outstanding-items-to-action`
);

export const orgApiKeysApiUrl = (orgAlias, apiId) => (
  `/api/${orgAlias}/organizations/api-keys${apiId ? `/${apiId}` : ''}`
);

export const orgGetSiteWideBannersApiUrl = orgAlias => (
  `/api/${orgAlias}/organizations/site-wide-banners`
);

export const getLatestAMLCheckApiUrl = orgAlias => (
  `/api/${orgAlias}/organizations/latest-aml-check`
);

export const submitAMLCheckApiUrl = orgAlias => (
  `/api/${orgAlias}/organizations/aml-check`
);

export const getDashboardPendingActionsApiUrl = orgAlias => (
  `/api/${orgAlias}/organizations/dashboard/pending-actions`
);
