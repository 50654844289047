import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FilePreview from 'core/assets/js/components/FilePreview.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import DocumentTermsContentBlocker from 'documents/assets/js/components/DocumentTermsContentBlocker.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import SignDocumentForm from 'documents/assets/js/components/SignDocumentForm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { DOCUMENT_ASSIGNMENT_STATUS_CLASS, DOCUMENT_ASSIGNMENT_STATUS_LABEL } from 'documents/assets/js/constants';
import { fetchDocumentAssignmentDS } from 'documents/assets/js/data-services/view';
import { getViewState } from 'core/assets/js/ducks/view';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectInvitationsUrl } from 'projects/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const DocumentAssignmentView = ({
  activeOrg, dispatch, documentAssignment, history, match: { params },
}) => {
  const componentName = DocumentAssignmentView.GetComponentName({ params });
  const [hasAccepted, setHasAccepted] = useState(false);
  const { id } = params;
  const { status } = documentAssignment;
  const canSign = documentAssignment?.allowedActions?.canSign;
  const file = documentAssignment?.document?.files && documentAssignment.document.files[0];
  const { location } = history;
  const skipTermsAndConditionsMessage = !!location?.state?.skipTermsAndConditionsMessage;

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          {
            title: documentAssignment && documentAssignment.document
              ? `Review and confirm the contract template - ${documentAssignment.document.title}`
              : (
                <>
                  Review and confirm the contract template -
                  <RectangleSkeleton className="ml-3" width="155" height="25" />
                  <RectangleSkeleton className="ml-3" width="55" height="25" />
                </>
              ),
            url: null,
          },
        ]}
      />

      <div
        className={
          `page page--document-sign ${(hasAccepted || !canSign || skipTermsAndConditionsMessage)
            ? '' : 'overflow-hidden'}`
        }
      >
        {!skipTermsAndConditionsMessage && canSign && (
          <DocumentTermsContentBlocker
            onAccept={() => setHasAccepted(true)}
          />
        )}

        <div className="container">
          <TDApiConnected
            duck="view"
            fetchData={({ authedAxios }) => dispatch(fetchDocumentAssignmentDS({
              authedAxios,
              componentName,
              id,
              orgAlias: activeOrg.alias,
            }))}
            blockingLoading
            storeKey={componentName}
          >
            <div className="text-right mb-3">
              <StatusTag
                className="ml-md-4"
                hideTitle
                label={DOCUMENT_ASSIGNMENT_STATUS_LABEL[status]}
                statusClass={DOCUMENT_ASSIGNMENT_STATUS_CLASS[status]}
              />
            </div>
            <div className="document-viewer rounded shadow-sm bg-white">
              {file && <FilePreview mimeType={file.mimetype} url={file.url} />}

              {!file && (
                <div className="p-5">
                  <div
                    className="html-content p-5"
                    dangerouslySetInnerHTML={{ __html: documentAssignment?.document?.content }}
                  />
                </div>
              )}
            </div>

            <SignDocumentForm
              document={documentAssignment?.document}
              initialValues={{
                date: documentAssignment.signedAt || moment(),
                legalName: documentAssignment.legalName || null,
                documentId: documentAssignment?.document?.id,
              }}
              onCancel={() => history.push(projectInvitationsUrl(activeOrg.alias))}
              onSuccess={async () => {
                history.push(projectInvitationsUrl(
                  activeOrg.alias, { continueInvitation: documentAssignment.invitation_id },
                ));
              }}
              readOnly
            />
          </TDApiConnected>
        </div>
      </div>
    </>
  );
};

DocumentAssignmentView.GetComponentName = ({ params: { id } }) => `DocumentAttachmentSignView-${id}`;

DocumentAssignmentView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  documentAssignment: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapStateToProps = (state, { match: { params } }) => {
  const viewState = getViewState(state, DocumentAssignmentView.GetComponentName({ params }));

  return {
    activeOrg: selectActiveOrg(state),
    documentAssignment: viewState.item,
  };
};

const DocumentAttachmentSignViewConnected = connect(
  mapStateToProps,
)(DocumentAssignmentView);

export default withRouter(DocumentAttachmentSignViewConnected);
