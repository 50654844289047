import React from 'react';
import PropTypes from 'prop-types';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import { INTEGRATION_DETAILS_TABS } from 'integrations/assets/js/constants';
import { orgIntegrationDetailUrl } from 'integrations/urls';

const IntegrationDetailsTabs = ({ orgAlias, integrationId, tab }) => {
  const tabSpec = [
    {
      key: INTEGRATION_DETAILS_TABS.ACTIVITY,
      label: 'Activity',
      href: orgIntegrationDetailUrl(orgAlias, integrationId, INTEGRATION_DETAILS_TABS.ACTIVITY),
    },
    {
      key: INTEGRATION_DETAILS_TABS.SETTINGS,
      label: 'Settings',
      href: orgIntegrationDetailUrl(orgAlias, integrationId, INTEGRATION_DETAILS_TABS.SETTINGS),
    },
    {
      key: INTEGRATION_DETAILS_TABS.SUPPLIER_MAPPING,
      label: 'Supplier Mapping',
      href: orgIntegrationDetailUrl(
        orgAlias, integrationId, INTEGRATION_DETAILS_TABS.SUPPLIER_MAPPING,
      ),
    },
    {
      key: INTEGRATION_DETAILS_TABS.ACCOUNT_MAPPING,
      label: 'Account Mapping',
      href: orgIntegrationDetailUrl(
        orgAlias, integrationId, INTEGRATION_DETAILS_TABS.ACCOUNT_MAPPING,
      ),
    },
    {
      key: INTEGRATION_DETAILS_TABS.INVOICE_MAPPING,
      label: 'Invoice Mapping',
      href: orgIntegrationDetailUrl(
        orgAlias, integrationId, INTEGRATION_DETAILS_TABS.INVOICE_MAPPING,
      ),
    },
  ];

  return (
    <div className="horizontal-scroll">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

IntegrationDetailsTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  integrationId: PropTypes.number.isRequired,
};

export default IntegrationDetailsTabs;
