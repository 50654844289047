import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { MODAL_ID } from 'integrations/assets/js/components/IntegrationCreateSupplierModal.jsx';
import IntegrationSupplierMappingCard from 'integrations/assets/js/components/IntegrationSupplierMappingCard.jsx';
import {
  fetchSupplierTalentDeskMappingsDS, updateSupplierMappingDS,
} from 'integrations/assets/js/data-services/list';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const IntegrationTalentDeskMappingSettings = ({
  dispatch, integration, mappings, match: { params: { orgAlias, integrationId } },
}) => {
  const componentName = IntegrationTalentDeskMappingSettings.GetComponentName();
  return (
    <TDApiConnected
      duck="list"
      fetchData={({ authedAxios }) => dispatch(fetchSupplierTalentDeskMappingsDS({
        authedAxios, componentName, integrationId, orgAlias,
      }))}
      skeletonComponent={() => null}
      storeKey={componentName}
    >
      <p>
        <strong>
          Please select from the supplier list the correct one that represents each TalentDesk
          company.
        </strong>
      </p>
      {mappings.map(mapping => (
        <div className="mx-4 my-2 bg-gray" key={mapping.id}>
          <IntegrationSupplierMappingCard
            dataTestIdSuffix="system-org"
            integrationName={integration.name}
            mapping={mapping}
            onCreateSupplier={local => dispatch(modalOpenAC(
              MODAL_ID, { componentName, local, systemOrg: true },
            ))}
            onSupplierMapped={async values => {
              try {
                await dispatch(updateSupplierMappingDS({
                  componentName, integrationId, orgAlias, values,
                }));
                toastr.success('Well Done!', 'The supplier mapping has been updated');
              } catch (error) {
                toastr.error('Oh Snap!', error._error || error.message);
              }
            }}
          />
        </div>
      ))}
    </TDApiConnected>
  );
};

IntegrationTalentDeskMappingSettings.GetComponentName = () => 'IntegrationTalentDeskMappingSettings';

IntegrationTalentDeskMappingSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  integration: integrationSpec.isRequired,
  mappings: PropTypes.arrayOf(PropTypes.object),
  match: routerMatchSpec.isRequired,
};

IntegrationTalentDeskMappingSettings.defaultProps = {
  mappings: [],
};

const mapStateToProps = state => ({
  mappings: getListState(state, IntegrationTalentDeskMappingSettings.GetComponentName()).items,
});

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationTalentDeskMappingSettingsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationTalentDeskMappingSettings);

export default withRouter(IntegrationTalentDeskMappingSettingsConnected);
