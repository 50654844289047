

/**
 * Standard assertion function, ensure that the condition is truthy,
 * when it's falsey throw an error.
 *
 * @param {boolean} condition - condition to check and throw based on.
 * @param {string} message - message to use in the exception that's thrown.
 */
// eslint-disable-next-line import/prefer-default-export
export const assert = (condition, message) => {
  if (!condition) {
    throw new Error(message);
  }
};

