import Big from 'big.js';
import React from 'react';
import PropTypes from 'prop-types';

import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectSpec, worksheetItemSpec } from 'projects/assets/js/lib/objectSpecs';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import { CURRENCY_SYMBOL, MIME_TYPES, MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import { uploaderProjectWorksheetsPath } from 'core/urls';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import { exchangeRatesServiceSpec, exchangeRatesSpec } from 'finance/assets/js/lib/objectSpecs';

Big.RM = 1;
Big.DP = 2;

/**
 * Worksheet form meta fields includes
 * Total billing info bar
 * Worksheet summary
 * Worksheet attachments
 */
const WorksheetFormMetaFields = props => {
  const {
    exchangeRatesService,
    isMobileView,
    organization,
    project,
    totalAmountOrgValue,
    totalAmountValue,
    worksheetCurrency,
    worksheetToOrganizationExchangeRate,
    worksheetToOrganizationExchangeRateUpdatedAt,
  } = props;
  const currencySymbol = CURRENCY_SYMBOL[worksheetCurrency];

  const shouldDisplayExchangeRateInfo = (
    totalAmountValue > 0
    && worksheetCurrency !== organization.currency
    && worksheetToOrganizationExchangeRate
  );

  const totalElement = (
    <>
      <NumberTpl
        value={totalAmountValue}
        prefix={currencySymbol}
      />
      {shouldDisplayExchangeRateInfo && (
        <ExchangeRateInfoPopOver
          className="ml-2"
          exchangeRate={worksheetToOrganizationExchangeRate}
          exchangeRateService={exchangeRatesService}
          exchangeRateDate={worksheetToOrganizationExchangeRateUpdatedAt}
          sourceCurrency={worksheetCurrency}
          targetAmount={totalAmountOrgValue}
          targetCurrency={organization.currency}
        />
      )}
    </>
  );

  return (
    <>
      { !isMobileView && (
        <tr className="worksheet-items-table__total">
          <td colSpan={5} />
          <td className="text-right">Total</td>
          <td data-testid="worksheet-items-total">{totalElement}</td>
          <td />
        </tr>
      )}

      { isMobileView && (
        <tr className="worksheet-items-table__total">
          <td className="px-4">
            <div className="d-flex align-items-center">
              <span className="worksheet-form__mobile-label">Total</span>

              <div className="ml-auto">{totalElement}</div>
            </div>
          </td>
        </tr>
      )}

      <tr className="worksheet-items-table__extra">
        <td className={isMobileView ? 'px-4 pt-0' : ''} colSpan={!isMobileView ? 7 : 1}>
          <TextAreaField
            name="summary"
            type="textarea"
            data-testid="worksheet-items-summary"
            placeholder="Add a summary of your work"
            label=""
          />

          <div className="mt-4">
            <FileUploaderField
              data-testid="worksheet-items-attachments"
              name="attachments"
              type="text"
              label="Attachments"
              uploaderWrapperClassName="fileuploader--white"
              acceptFiles={['image/*', '.flv', '.mov', '.mp4', '.wmv', '.avi', ...MIME_TYPES.DOCUMENTS]}
              maxFiles={MAX_UPLOAD_FILES}
              path={uploaderProjectWorksheetsPath(organization.id, project.id)}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

WorksheetFormMetaFields.propTypes = {
  exchangeRates: exchangeRatesSpec,
  exchangeRatesService: exchangeRatesServiceSpec.isRequired,
  info: PropTypes.object,
  isMobileView: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(worksheetItemSpec),
  organization: orgSpec.isRequired,
  project: projectSpec.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  totalAmountOrgValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalAmountValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  worksheetCurrency: PropTypes.string,
  worksheetToOrganizationExchangeRate: PropTypes.number,
  worksheetToOrganizationExchangeRateUpdatedAt: PropTypes.string,
};

WorksheetFormMetaFields.defaultProps = {
  exchangeRates: {},
  info: null,
  items: [],
  rates: [],
  worksheetCurrency: null,
  worksheetToOrganizationExchangeRate: null,
  worksheetToOrganizationExchangeRateUpdatedAt: null,
};

export default WorksheetFormMetaFields;
