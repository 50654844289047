import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const TDCopyToClipboard = ({ text, className }) => {
  const handleClipboardCopy = () => (
    toastr.success('Invite Link copied to clipboard!')
  );

  const classNames = ['copy-to-clipboard'];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="copy-to-clipboard__text">
        {text}
      </div>

      <CopyToClipboard
        text={text}
        onCopy={handleClipboardCopy}
      >
        <TDButton
          label="Copy"
          bsSize={BS_SIZE.XSMALL}
          variant={BS_STYLE.DEFAULT}
          className="copy-to-clipboard__button"
        />
      </CopyToClipboard>
    </div>
  );
};

TDCopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TDCopyToClipboard.defaultProps = {
  className: '',
};

export default TDCopyToClipboard;
