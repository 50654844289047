import React from 'react';
import PropTypes from 'prop-types';

import { reviewSpec } from 'people/assets/js/lib/objectSpecs';
import ReviewCard from 'people/assets/js/components/ReviewCard.jsx';

const ReviewList = ({ reviews, viewerId }) => {
  const reviewsTable = reviews.map(review => (
    (
      <ReviewCard
        key={review.id}
        id={review.id}
        viewerId={viewerId}
        body={review.body}
        authorId={review.author_id}
        avatar={review.author.profile.avatar}
        firstName={review.author.profile.firstName}
        lastName={review.author.profile.lastName}
        jobTitle={review.author.profile.jobTitle}
        createdAt={review.created_at}
        rating={review.rating}
        project={review.project}
        task={review.task}
      />
    )
  ));

  if (reviews.length === 0) {
    return (
      <div className="py-2 px-2">
        <i className="discreet">No reviews available yet.</i>
      </div>
    );
  }

  return (
    <div>
      {reviewsTable}
    </div>
  );
};

ReviewList.propTypes = {
  reviews: PropTypes.arrayOf(reviewSpec).isRequired,
  viewerId: PropTypes.number,
};

ReviewList.defaultProps = {
  viewerId: null,
};

export default ReviewList;
