import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

/**
 * Used for apply inline changes
 * @param {String} ctaCancelLabel Saving button label
 * @param {String} ctaCreateClassName Additional className for create button
 * @param {Function} ctaCreateAction Create button action
 * @param {String} ctaCreateLabel Create button label
 * @param {Boolean} ctaCreateRounded If create button is rounded
 * @param {Boolean} ctaCreateShow Boolean to show Create button
 * @param {String} ctaCreateVariant Variant of create button
 * @param {String} ctaEditClassName Additional className for edit button
 * @param {String} ctaEditLabel Editing button label
 * @param {Boolean} ctaEditRounded If editing button is rounded
 * @param {Boolean} ctaEditShow Boolean to show Edit button
 * @param {String} ctaEditVariant Variant of edit button
 * @param {String} ctaSaveLabel Saving button label
 * @param {String|Node} disclaimer Usually a text to give some more info for the value shown
 * @param {String|Node} editForm Form that indicates inline changes
 * @param {String|Node} label An optional label for the previewed value
 * @param {Function} onSave Form on saving callback function
 * @param {String|Node} preview The value that is able to change using the cta functionality
 * @param {Boolean} submitting A boolean value to check if changes are submitting
 * @param {String} wrapperExtraClasses
 * @returns {*}
 * @constructor
 */
function InlineEditItem({
  ctaCancelLabel,
  ctaCreateAction,
  ctaCreateClassName,
  ctaCreateLabel,
  ctaCreateRounded,
  ctaCreateShow,
  ctaCreateVariant,
  ctaEditClassName,
  ctaEditLabel,
  ctaEditRounded,
  ctaEditShow,
  ctaEditVariant,
  ctaSaveLabel,
  disclaimer,
  editForm,
  label,
  onSave,
  preview,
  submitting,
  wrapperExtraClasses,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  return (
    <div
      className={`inline-edit-item d-flex align-items-center flex-column flex-sm-row ${wrapperExtraClasses}`}
    >
      <div className="mb-3 mb-sm-0 col-sm-5">
        {label && (
          <div className="mb-2 text-dark">{label}</div>
        )}
        {disclaimer && (
          <div className="discreet">{disclaimer}</div>
        )}
      </div>

      {((preview && !isEditMode) || (editForm && isEditMode)) && (
        <div className="mb-3 mb-sm-0 mr-5 ml-5 col-sm-3 ml-auto">
          {isEditMode ? editForm : preview}
        </div>
      )}

      <div className="pl-0 pl-sm-4 ml-sm-auto mr-4">
        {!isEditMode && (
          <div className="d-flex">
            {ctaEditShow && (
              <TDButton
                data-testid="button-change-payment-method"
                bsSize={BS_SIZE.SMALL}
                className={ctaEditClassName}
                rounded={ctaEditRounded}
                onClick={() => {
                  setIsEditMode(true);
                }}
                variant={ctaEditVariant}
              >
                {ctaEditLabel}
              </TDButton>
            )}
            {ctaCreateShow && !isEditMode && (
              <TDButton
                data-testid="button-create-payment-method"
                bsSize={BS_SIZE.SMALL}
                className={ctaCreateClassName}
                rounded={ctaCreateRounded}
                onClick={() => ctaCreateAction()}
                variant={ctaCreateVariant}
              >
                {ctaCreateLabel}
              </TDButton>
            )}
          </div>
        )}
        {isEditMode && (
          <div className="d-flex">
            <TDButton
              rounded
              bsSize={BS_SIZE.SMALL}
              variant={BS_STYLE.DEFAULT}
              onClick={() => {
                setIsEditMode(false);
              }}
            >
              {ctaCancelLabel}
            </TDButton>
            <TDButton
              rounded
              bsSize={BS_SIZE.SMALL}
              variant={BS_STYLE.PRIMARY}
              disabled={submitting}
              onClick={onSave}
            >
              {ctaSaveLabel}
            </TDButton>
          </div>
        )}
      </div>
    </div>
  );
}

InlineEditItem.propTypes = {
  ctaCancelLabel: PropTypes.string,
  ctaCreateClassName: PropTypes.string,
  ctaCreateAction: PropTypes.func,
  ctaCreateLabel: PropTypes.string,
  ctaCreateRounded: PropTypes.bool,
  ctaCreateShow: PropTypes.bool,
  ctaCreateVariant: PropTypes.string,
  ctaEditClassName: PropTypes.string,
  ctaEditLabel: PropTypes.string,
  ctaEditRounded: PropTypes.bool,
  ctaEditShow: PropTypes.bool,
  ctaEditVariant: PropTypes.string,
  ctaSaveLabel: PropTypes.string,
  disableEdit: PropTypes.bool,
  disclaimer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  editForm: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSave: PropTypes.func.isRequired,
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  submitting: PropTypes.bool,
  wrapperExtraClasses: PropTypes.string,
};

InlineEditItem.defaultProps = {
  ctaCancelLabel: 'Cancel',
  ctaCreateAction: () => null,
  ctaCreateClassName: '',
  ctaCreateLabel: 'Create',
  ctaCreateRounded: false,
  ctaCreateShow: false,
  ctaCreateVariant: BS_STYLE.LINK,
  ctaEditClassName: '',
  ctaEditLabel: 'Change',
  ctaEditRounded: false,
  ctaEditShow: false,
  ctaEditVariant: BS_STYLE.LINK,
  ctaSaveLabel: 'Save',
  disableEdit: false,
  disclaimer: null,
  label: null,
  preview: null,
  submitting: false,
  wrapperExtraClasses: '',
};

export default InlineEditItem;
