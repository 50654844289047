import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { deleteExperienceDS } from 'accounts/assets/js/data-services/form';
import { profileEditExperienceUrl } from 'accounts/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class ProfileExperienceItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseDeleteExperienceModal = this.handleCloseDeleteExperienceModal.bind(this);
    this.handleDeleteExperience = this.handleDeleteExperience.bind(this);
    this.handleOpenDeleteExperienceModal = this.handleOpenDeleteExperienceModal.bind(this);
  }

  handleCloseDeleteExperienceModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleOpenDeleteExperienceModal() {
    const { dispatch, deleteExperienceModalId } = this.props;
    dispatch(modalOpenAC(deleteExperienceModalId));
  }

  handleDeleteExperience() {
    const { dispatch, experience } = this.props;
    const componentName = 'ProfileExperienceTab';
    return dispatch(
      deleteExperienceDS(experience.id, componentName),
    ).then(() => {
      this.handleCloseDeleteExperienceModal();
      toastr.success('Well Done!', 'Your experience was deleted successfully.');
    });
  }

  render() {
    const {
      activeOrg, canEditExperience, dragHandle, history, isDeleteExperienceModalOpen,
      experience: { attachments, company, id, period, skills, description },
    } = this.props;
    const DragHandleComponent = dragHandle;
    const classNames = ['experience-list--item mb-3 py-3'];

    if (canEditExperience) {
      classNames.push('experience-list--item__editable');
    }

    return (
      <div className={classNames.join(' ')}>
        <div className="row">
          <div className="col-10">
            { dragHandle && <DragHandleComponent /> }
            <span className="company mb-2">{company}</span>
          </div>
          {canEditExperience && (
            <TDDropButton className="ml-auto pr-4" stopPropagation>
              <Dropdown.Item
                eventKey="1"
                onClick={() => history.push(profileEditExperienceUrl(activeOrg.alias, id))}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="1"
                onClick={this.handleOpenDeleteExperienceModal}
              >
                Delete
              </Dropdown.Item>
            </TDDropButton>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <span className="period">{period}</span>
          </div>
        </div>
        {description && (
          <div className="row mb-4">
            <div className="col-12">
              <span className="description">{description}</span>
            </div>
          </div>
        )}
        {attachments.length >= 1 && (
          <div className="row mb-4">
            <div className="col-12">
              <div className="attachments-list">
                <AttachmentsList attachments={attachments} label={null} />
              </div>
            </div>
          </div>
        )}
        {skills.length >= 1 && (
          <div className="row">
            <div className="col-12">
              <div className="skill-list">
                <SkillList skills={skills} />
              </div>
            </div>
          </div>
        )}
        <ModalSimple
          heading="Delete experience"
          body="Are you sure you want to delete this experience?"
          onClose={this.handleCloseDeleteExperienceModal}
          open={isDeleteExperienceModalOpen}
          footer={[
            <TDButton
              key={1}
              className="float-right"
              label="Delete"
              variant={BS_STYLE.DANGER}
              onClick={this.handleDeleteExperience}
            />,
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              onClick={this.handleCloseDeleteExperienceModal}
            />,
          ]}
        />
      </div>
    );
  }
}

ProfileExperienceItem.propTypes = {
  activeOrg: orgSpec.isRequired,
  canEditExperience: PropTypes.bool,
  deleteExperienceModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dragHandle: PropTypes.func,
  experience: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isDeleteExperienceModalOpen: PropTypes.bool.isRequired,
};

ProfileExperienceItem.defaultProps = {
  canEditExperience: false,
  dragHandle: null,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state, props) => {
  const { item } = props;
  const deleteExperienceModalId = `delete-experience-modal-id-${item.id}`;

  return {
    activeOrg: selectActiveOrg(state),
    deleteExperienceModalId,
    experience: item,
    isDeleteExperienceModalOpen: getIsModalOpen(state, deleteExperienceModalId),
  };
};

const ProfileExperienceItemConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileExperienceItem);

export default withRouter(ProfileExperienceItemConnected);
