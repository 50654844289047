import React from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Table from 'core/assets/js/components/Table.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { BS_STYLE } from 'core/assets/js/constants';
import { IMPORT_ROW_STATUS } from 'importer/assets/js/constants';
import { importFetchCancelledApiUrl, importRowsApiUrl } from 'importer/urls';

const ImportViewCancelled = () => {
  const { id, orgAlias } = useParams();
  const { items, pagination } = fetchDataHook({
    componentName: ImportViewCancelled.GetComponentName(),
    duck: 'list',
    queryStringParams: { status: IMPORT_ROW_STATUS.CANCELLED },
    url: importRowsApiUrl(orgAlias, id),
  });
  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between">
        <span>Cancelled</span>
        <TDButton
          label="Download cancelled list"
          onClick={() => {
            WINDOW_OPEN(importFetchCancelledApiUrl(orgAlias, id), '_blank');
          }}
          variant={BS_STYLE.PRIMARY}
        />
      </Card.Header>
      <Card.Body>
        <Table
          cols={[
            {
              key: 'payload',
              label: 'Row data',
              dataFormat: payload => `"${payload.join('","')}"`,
            },
          ]}
          items={items}
        />
        <TDPagination {...pagination} />
      </Card.Body>
    </Card>
  );
};

ImportViewCancelled.GetComponentName = () => 'ImportViewCancelled';

export default ImportViewCancelled;
