import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import SkillSelectModalPanel, { MODAL_ID as SKILL_SELECT_PANEL_MODAL_ID } from 'core/assets/js/components/SkillSelectModalPanel.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';
import { ICON } from 'core/assets/js/constants';
import { orgPublicSpec } from 'organizations/assets/js/lib/objectSpecs';

const AddMoreSkillsButton = ({ className, handleModalOpen }) => (
  <OverlayTrigger
    popperConfig={{
      modifiers: {
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
      },
    }}
    delay={{ show: 250, hide: 0 }}
    overlay={<Tooltip id="add-skill-tooltip">Add skill</Tooltip>}
  >
    <a className={`add-skill-button ${className}`} onClick={handleModalOpen}>
      <span className={ICON.ADD_CIRCLE} />
    </a>
  </OverlayTrigger>
);

AddMoreSkillsButton.propTypes = {
  className: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
};

class SkillSelectField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onItemsSelected = this.onItemsSelected.bind(this);
    this.handleSkillRemove = this.handleSkillRemove.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
  }

  onChange(event, { newValue }) {
    const { input } = this.props;
    input.onChange(newValue || null);
  }

  onItemsSelected(selectedItems) {
    const { input } = this.props;
    input.onChange(selectedItems);
  }

  handleSkillRemove(skill) {
    const { input: { value } } = this.props;
    const idx = value.findIndex(_item => _item.id === skill.id);
    if (idx > -1) {
      // Remove Item
      this.onItemsSelected(value.filter(item => item.id !== skill.id));
    }
  }

  handleModalOpen() {
    const { dispatch, input: { value }, organization } = this.props;
    dispatch(modalOpenAC(SKILL_SELECT_PANEL_MODAL_ID, {
      value,
      selectionFilter: {
        orgId: organization.id,
        orgName: organization.name,
        orgAlias: organization.alias,
      },
    }));
  }

  render() {
    const { input, label, memberSkillsOnly, meta: { touched, error } } = this.props;
    const hasError = touched && error;
    const groupClassName = ['form-group skill-list-field'];

    if (hasError) {
      groupClassName.push('has-error');
    }

    return (
      <div className={groupClassName.join(' ')}>
        {label && (
          <label htmlFor={input.name}>{label}</label>
        )}
        <div className="skill-list">
          {input.value && input.value.map(skill => (
            <SkillTag
              key={skill.id}
              skill={skill}
              onRemove={this.handleSkillRemove}
            />
          ))}

          {(!input.value || (input.value && input.value.length === 0)) && (
            <div className="d-flex align-content-between align-items-center flex-row">
              <p className="discreet mb-1">
                Click the &quot;+&quot; button to select skills
              </p>

              <span className="ml-auto">
                <AddMoreSkillsButton className="px-2 py-1" handleModalOpen={this.handleModalOpen} />
              </span>
            </div>
          )}

          {input.value && input.value.length > 0 && (
            <AddMoreSkillsButton className="pt-1" handleModalOpen={this.handleModalOpen} />
          )}

          <SkillSelectModalPanel
            memberSkillsOnly={memberSkillsOnly}
            initiallySelected={input.value}
            handleSubmitSelected={this.onItemsSelected}
          />
        </div>

        {hasError && <span className="help-block">{error}</span>}
        <input type="hidden" name={input.name} value={JSON.stringify(input.value)} />
      </div>
    );
  }
}

SkillSelectField.propTypes = {
  input: reduxInputSpec.isRequired,
  label: PropTypes.string,
  memberSkillsOnly: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  organization: orgPublicSpec,
};

SkillSelectField.defaultProps = {
  label: '',
  memberSkillsOnly: true,
  meta: {
    touched: false,
    error: false,
  },
  organization: {},
};

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillSelectField);
