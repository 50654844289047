import { isNil, pick } from 'lodash';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import Money from 'finance/assets/js/lib/Money';


class InvoiceItemAmountsAggregate {
  static copy(aggregate) {
    return new InvoiceItemAmountsAggregate(aggregate.serialize());
  }

  static zero(currency) {
    return new InvoiceItemAmountsAggregate({
      currency,
      serviceOrderTotal: '0.00',
      charges: '0.00',
      credit: '0.00',
      billable: '0.00',
      appliedInvoiceItemVat: '0.00',
    });
  }

  constructor(props) {
    this.init(props);
  }

  init({
    currency,
    serviceOrderTotal,
    charges,
    credit,
    billable,
    appliedInvoiceItemVat = '0.00',
  }) {
    if (isNil(currency)) {
      throw new Error('missing currency');
    }
    if (isNil(serviceOrderTotal)) {
      throw new Error('missing serviceOrderTotal amount');
    }
    if (isNil(billable)) {
      throw new Error('missing billable amount');
    }
    if (isNil(credit)) {
      throw new Error('missing credit amount');
    }
    if (isNil(charges)) {
      throw new Error('missing charges amount');
    }
    this.details = {
      currency,
      serviceOrderTotal: new Money(serviceOrderTotal, currency).toString(),
      credit: new Money(credit, currency).toString(),
      billable: new Money(billable, currency).toString(),
      charges: new Money(charges, currency).toString(),
      appliedInvoiceItemVat: new Money(appliedInvoiceItemVat, currency).toString(),
    };
  }

  serialize() {
    return pick(this.details, [
      'currency', 'serviceOrderTotal', 'credit', 'billable', 'charges', 'appliedInvoiceItemVat',
    ]);
  }

  toInvoiceAmounts() {
    const { serviceOrderTotal, charges } = this.details;
    return {
      ...pick(this.details, [
        'currency', 'credit', 'billable',
      ]),
      amount: serviceOrderTotal,
      contractorCharges: charges,
    };
  }

  get currencySymbol() {
    const { currency } = this;
    return CURRENCY_SYMBOL[currency];
  }

  getCurrency() {
    return this.details.currency;
  }

  getCredit() {
    return this.details.credit;
  }

  getAppliedVat() {
    return this.details.appliedInvoiceItemVat;
  }
}

export default InvoiceItemAmountsAggregate;
