import { get, omit } from 'lodash';

// https://api-docs.transferwise.com/#recipient-accounts-create-response
class TransferwiseRecipient {
  constructor({
    id, profile, user, accountHolderName, currency, country, type, ownedByCustomer, details,
  } = {}) {
    this.id = id;
    this.profile = profile;
    this.user = user;
    this.accountHolderName = accountHolderName;
    this.currency = currency;
    this.country = country;
    this.type = type;
    this.ownedByCustomer = ownedByCustomer;
    this.details = {
      // backwards compatibility
      ...omit(details, ['details']),
      ...get(details, 'details', {}),
      address: {
        ...get(details, 'address', {}),
        ...get(details, 'details.address', {}),
      },
    };
  }

  serialize() {
    return {
      id: this.id,
      profile: this.profile,
      user: this.user,
      accountHolderName: this.accountHolderName,
      currency: this.currency,
      country: this.country,
      type: this.type,
      ownedByCustomer: this.ownedByCustomer,
      details: omit(this.details, ['details']),
    };
  }
}

export default TransferwiseRecipient;
