import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import { ICON, IMG_SIZE } from 'core/assets/js/constants';
import { projectSpec, projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDRating from 'core/assets/js/components/TDRating.jsx';

const ReviewCard = ({
  avatar, body, createdAt, firstName, lastName, project, rating, jobTitle, task,
}) => {
  return (
    <Card className="card--light-gray border-0 shadow-none">
      <Card.Body className="note-review-card-item flex-column p-4">
        <div className="row">
          <div className="col-auto d-flex align-items-center">
            <div className="note-review-card-item__profile-pic">
              <ProfilePic
                url={avatar}
                alt={`${firstName}'s avatar`}
                size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
              />
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between">
              <div className="note-review-card-item__name">
                <div>
                  {`${firstName} ${lastName}`}
                </div>
                <div className="note-review-card-item__job-title my-2 mb-sm-0">
                  {jobTitle}
                </div>
              </div>
            </div>
          </div>

          <TDRating
            className="d-block text-right mr-0 col"
            readonly
            initialRating={rating}
          />
        </div>

        <p className="note-review-card-item__content mt-4 mb-0">
          {body}
        </p>

        <div className="note-review-card-item__meta d-flex pt-4 mt-3">
          {project && task && (
            <div className="note-review-card-item__project-title">
              <i className={ICON.CHECKMARK} />
              {task.title}
            </div>
          )}

          { project && !task && (
            <div className="note-review-card-item__project-title">
              <i className={ICON.BROWSER} />
              {project.title}
            </div>
          )}

          <time className="discreet ml-auto" dateTime={createdAt}>{formatDate(createdAt)}</time>
        </div>
      </Card.Body>
    </Card>
  );
};

ReviewCard.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  project: projectSpec.isRequired,
  task: projectTaskSpec,
};

ReviewCard.defaultProps = {
  avatar: '',
  task: null,
};

export default ReviewCard;
