import PropTypes from 'prop-types';
import React from 'react';

import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { RATE_ADJUSTMENT_SUBMIT_TYPE } from 'rates/assets/js/constants';

const RateAdjustmentReviewForm = ({ form }) => {
  const submitting = form?.getState().submitting;
  return (
    <div key="body" className="rate-adj-form  rate-adj-form__request p-4 mt-4">
      <div className="row">
        <div className="col-12">
          <TextAreaField
            className="textarea-half"
            label="Message"
            name="message"
            sublabel="Add a message to your decision"
          />
        </div>
      </div>
      <div className="d-flex text-right">
        <TDButton
          type="submit"
          onClick={() => {
            form.change('submitType', RATE_ADJUSTMENT_SUBMIT_TYPE.REJECT);
            form.submit();
          }}
          variant={BS_STYLE.DANGER}
          disabled={submitting}
          btnIcon={ICON.CROSS}
          label="Reject"
        />
        <TDButton
          type="submit"
          onClick={() => {
            form.change('submitType', RATE_ADJUSTMENT_SUBMIT_TYPE.APPROVE);
            form.submit();
          }}
          variant={BS_STYLE.SUCCESS}
          disabled={submitting}
          btnIcon={ICON.CHECKMARK}
          label="Approve"
        />
      </div>
    </div>
  );
};

RateAdjustmentReviewForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default RateAdjustmentReviewForm;
