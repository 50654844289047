import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import Table from 'core/assets/js/components/Table.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE } from 'core/assets/js/constants';
import { IMPORT_ROW_STATUS } from 'importer/assets/js/constants';
import { importFetchErrorsApiUrl, importRowsApiUrl } from 'importer/urls';

const ImportViewErrors = ({ match: { params: { id, orgAlias } } }) => {
  const {
    items: importRows, pagination,
  } = useSelector(state => getListState(state, ImportViewErrors.GetComponentName()));
  return (
    <>
      <TDApiConnected
        duck="list"
        fetchData={({
          authedAxios, componentName, dispatch, params, querystring,
        }) => dispatch(fetchListDS({
          authedAxios,
          componentName,
          querystring: qs.stringify({
            ...qs.parse(querystring), status: IMPORT_ROW_STATUS.ERRORED,
          }),
          url: importRowsApiUrl(params.orgAlias, params.id),
        }))}
        skeletonComponent={() => null}
        storeKey={ImportViewErrors.GetComponentName()}
      />
      {/*
        This has to be rendered outside TDApiConnected, otherwise on page changes it blocks
        rendering it's children
      */}
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <span>Errors</span>
          <TDButton
            label="Download errors list"
            onClick={() => {
              WINDOW_OPEN(importFetchErrorsApiUrl(orgAlias, id), '_blank');
            }}
            variant={BS_STYLE.PRIMARY}
          />
        </Card.Header>
        <Card.Body>
          <Table
            cols={[
              {
                key: 'payload',
                label: 'Row data',
                dataFormat: payload => `"${payload.join('","')}"`,
              },
              { key: 'errorMessage', label: 'Error' },
            ]}
            items={importRows}
          />
          <TDPagination {...pagination} />
        </Card.Body>
      </Card>
    </>
  );
};

ImportViewErrors.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

ImportViewErrors.GetComponentName = () => 'ImportViewErrors';

export default withRouter(ImportViewErrors);
