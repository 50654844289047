import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import { submitSignupDS } from 'accounts/assets/js/data-services/account';
import { loginUrl, signupVerifyUrl } from 'accounts/urls';
import { NODE_ENV, ENV_DEVELOPMENT, RECAPTCHA_DISABLED_LOCALLY, WINDOW_REDIRECT } from 'core/assets/js/config/settings';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import PhoneNumberField from 'core/assets/js/components/FinalFormFields/PhoneNumberField.jsx';
import RecaptchaField from 'core/assets/js/components/FinalFormFields/RecaptchaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { privacyUrl, tncUrl } from 'core/urls';

const SignupForm = ({ history, dispatch, initialValues, next, withProfile, match }) => {
  const { params: { token } } = match;
  const phoneNrRequired = !token;

  // Skip captcha check when token is present.
  const useRecaptcha = !token && !(NODE_ENV === ENV_DEVELOPMENT && RECAPTCHA_DISABLED_LOCALLY);

  const onSubmit = async ({ tnc, termsOfEngagement, ...rest }) => {
    const values = { ...rest };
    if (withProfile) {
      values.tnc = Array.isArray(tnc)
        && tnc.length === 1
        && tnc[0].value;
    } else {
      values.termsOfEngagement = Array.isArray(termsOfEngagement)
        && termsOfEngagement.length === 1
        && termsOfEngagement[0].value;
    }
    if ((phoneNrRequired || values.phone) && !isPossiblePhoneNumber(values.phone || '')) {
      return { phone: 'Invalid phone number ' };
    }
    try {
      await dispatch(submitSignupDS(values, !!token)).then(async (response) => {
        if (response?._error && !!response.verified) {
          if (next) {
            WINDOW_REDIRECT(`${next}?td-ue=${(encodeURIComponent(values.email))}`);
          } else {
            history.push({
              pathname: signupVerifyUrl(),
              state: { email: values.email },
            });
          }
        } else {
          await dispatch(fetchSetupStepsDS());
        }
      });

      return null;
    } catch (error) {
      if (error.errors) {
        toastr.error('Oh snap!', error.message);
        return error.errors;
      }
      return { [FORM_ERROR]: error._error || error.message };
    }
  };

  return (
    <div className="signup-form">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ form: { getState }, handleSubmit, submitErrors, submitting }) => (
          <>
            {!submitErrors?.verified && (
              <>
                <form onSubmit={handleSubmit}>
                  <input name="token" type="hidden" value={getState().values.token} />
                  <TextInputField
                    disabled={!!initialValues?.first_name}
                    label="First name"
                    name="first_name"
                    required
                  />
                  <TextInputField
                    disabled={!!initialValues?.last_name}
                    label="Last name"
                    name="last_name"
                    required
                  />
                  <TextInputField
                    disabled={!!initialValues?.email}
                    label="Email"
                    name="email"
                    required
                    type="email"
                  />
                  <PhoneNumberField
                    disabled={!!initialValues?.phone}
                    label="Phone"
                    name="phone"
                    required={phoneNrRequired}
                  />
                  <TextInputField
                    label="Password"
                    name="password"
                    required
                    showStrengthIndicator
                    type="password"
                  />
                  <TextInputField
                    label="Repeat password"
                    name="repeat_password"
                    required
                    type="password"
                  />
                  <CheckboxField
                    name={withProfile ? 'tnc' : 'termsOfEngagement'}
                    options={[{
                      text: (
                        <span>
                          {'I agree to the '}
                          <a
                            href={tncUrl()}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>
                          {'. Please see our '}
                          <a
                            href={privacyUrl()}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </span>
                      ),
                      value: true,
                    }]}
                  />
                  {useRecaptcha && <RecaptchaField className="mb-4" />}
                  <div className="form-group">
                    <TDButton
                      data-testid="button-sign-up"
                      block
                      disabled={submitting}
                      label="SIGN UP"
                      type="submit"
                      variant={BS_STYLE.PRIMARY}
                    />
                  </div>

                  <TextInputField
                    type="hidden"
                    name="utm_metadata"
                    required
                  />
                </form>
                <div className="text-center">
                  <p className="w-100 mt-5">
                    Already have an account? &nbsp;
                    <Link data-testid="login-link" to={loginUrl(next)}>Log in</Link>
                  </p>
                </div>
              </>
            )}
          </>
        )}
      />
    </div>
  );
};

SignupForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  next: PropTypes.string,
  withProfile: PropTypes.bool,
};

SignupForm.defaultProps = {
  initialValues: {},
  next: null,
  withProfile: false,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const SignupFormConnected = connect(null, mapDispatchToProps)(SignupForm);

export default withRouter(SignupFormConnected);
