import React from 'react';
import PropTypes from 'prop-types';

import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TaskAttachmentsWidget from 'projects/assets/js/components/widgets/TaskAttachmentsWidget.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import { ACTIVITY_DATE_FORMAT } from 'notifier/assets/js/constants';
import { IMG_SIZE } from 'core/assets/js/constants';

const UserMessage = ({
  profile, body, timestamp, isSender, isSuccess, isFailure, attachments, onAttachmentUpdated,
}) => {
  const hasAttachments = attachments && attachments.length > 0;
  const paragraphClasses = ['db-user-message__text'];

  if (hasAttachments) {
    paragraphClasses.push('db-user-message__text--with-attachments');
  }

  if (isSuccess) {
    paragraphClasses.push('db-user-message__text--success');
  }

  if (isFailure) {
    paragraphClasses.push('db-user-message__text--reject');
  }

  return (
    <div
      className={
        `discussion-board__row db-user-message ${isSender ? 'db-user-message--sender' : ''}`
      }
    >
      <ProfilePic
        url={profile.avatar}
        alt={profile.name}
        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
      />

      <div className="db-user-message__meta">
        <div className={`d-block d-md-flex align-items-center ${isSender ? 'flex-row-reverse justify-content-end' : 'flex-row'}`}>
          <span className="db-user-message__name">
            {profile.firstName}
            {' '}
            {profile.lastName.charAt(0)}
            {'.'}
          </span>
          <time className="discussion-board__timestamp">
            {formatDate(timestamp, ACTIVITY_DATE_FORMAT)}
          </time>
        </div>
        <div className={paragraphClasses.join(' ')}>
          {body && (
            <MarkdownText
              text={body}
              withBreaksPlugin
            />
          )}

          { hasAttachments && (
            <TaskAttachmentsWidget
              className="db-user-message__attachments mt-4"
              onDeliverableStatusToggled={onAttachmentUpdated}
              attachments={attachments}
              showDates={false}
              invertColors
            />
          )}
        </div>
      </div>
    </div>
  );
};

UserMessage.propTypes = {
  attachments: PropTypes.array,
  isSender: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailure: PropTypes.bool,
  body: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  onAttachmentUpdated: PropTypes.func,
};

UserMessage.defaultProps = {
  attachments: null,
  isSender: false,
  isSuccess: false,
  isFailure: false,
  onAttachmentUpdated: () => {},
};

export default UserMessage;
