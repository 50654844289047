import React from 'react';
import PropTypes from 'prop-types';

import ProjectUpcomingPaymentsWidget from 'projects/assets/js/components/widgets/ProjectUpcomingPaymentsWidget.jsx';
import ProjectBudgetWidget from 'projects/assets/js/components/widgets/ProjectBudgetWidget.jsx';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import ProjectWorksheetsWidget from 'projects/assets/js/components/widgets/ProjectWorksheetsWidget.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchUpcomingPaymentsAnalyticsDS, fetchWorksheetAnalyticsDS } from 'analytics/assets/js/data-services/analytics';
import ProjectUpcomingPaymentsSkeleton from 'projects/assets/js/components/skeletons/ProjectUpcomingPaymentsSkeleton.jsx';
import { DATE_DURATION, DATE_DURATION_API_KEY } from 'core/assets/js/constants';
import ProjectWorksheetsWidgetSkeleton from 'projects/assets/js/components/skeletons/ProjectWorksheetsWidgetSkeleton.jsx';
import ProjectDistributionWidgets from 'projects/assets/js/components/widgets/ProjectDistributionWidgets.jsx';
import ProjectBurnDownWidget from 'projects/assets/js/components/widgets/ProjectBurnDownWidget.jsx';

const ProjectAnalyticsWidgets = ({
  project, currentMonthAnalytics, threeMonthsAnalytics, sixMonthsAnalytics,
  oneYearAnalytics, fromStartAnalytics,
}) => (
  <React.Fragment>
    <AllowAccessToManager>
      <div className="col-12 mb-4">
        <ProjectBudgetWidget
          project={project}
        />
      </div>
    </AllowAccessToManager>

    <div className="col-12 mb-4">
      <TDApiConnected
        duck="view"
        name={ProjectUpcomingPaymentsWidget.GetComponentName()}
        fetchData={({ dispatch, params, url, authedAxios, componentName }) => (
          dispatch(fetchUpcomingPaymentsAnalyticsDS({
            url,
            ...params,
            componentName,
            authedAxios,
            dispatch,
          }))
        )}
        blockingLoading
        skeletonComponent={ProjectUpcomingPaymentsSkeleton}
      >
        <ProjectUpcomingPaymentsWidget
          project={project}
        />
      </TDApiConnected>
    </div>

    <div className="col-12 mt-2">
      <TDApiConnected
        duck="view"
        name="worksheetAnalytics"
        fetchData={
          ({ dispatch, params, url, authedAxios, componentName }) => {
            const fetches = [];
            Object.keys(DATE_DURATION).forEach((key) => {
              fetches.push(
                dispatch(fetchWorksheetAnalyticsDS({
                  url,
                  ...params,
                  projectId: params.id,
                  dateDuration: DATE_DURATION[key],
                  componentName: `${componentName}_${DATE_DURATION_API_KEY[DATE_DURATION[key]]}`,
                  authedAxios,
                  dispatch,
                })),
              );
            });
            return Promise.all(fetches);
          }}
        blockingLoading
        skeletonComponent={ProjectWorksheetsWidgetSkeleton}
      >
        <div className="row">
          <div className="col-12 mb-4">
            <ProjectDistributionWidgets
              currentMonthAnalytics={currentMonthAnalytics}
              threeMonthsAnalytics={threeMonthsAnalytics}
              sixMonthsAnalytics={sixMonthsAnalytics}
              oneYearAnalytics={oneYearAnalytics}
              fromStartAnalytics={fromStartAnalytics}
              project={project}
            />
          </div>
          <AllowAccessToManager>
            <div className="col-12 mb-4">
              <ProjectWorksheetsWidget
                currentMonthAnalytics={currentMonthAnalytics}
                threeMonthsAnalytics={threeMonthsAnalytics}
                sixMonthsAnalytics={sixMonthsAnalytics}
                oneYearAnalytics={oneYearAnalytics}
                fromStartAnalytics={fromStartAnalytics}
              />
            </div>
          </AllowAccessToManager>
        </div>
      </TDApiConnected>
    </div>
    <AllowAccessToManager>
      <div className="col-12 mb-4">
        <ProjectBurnDownWidget
          currencySymbol={project.currency_symbol}
        />
      </div>
    </AllowAccessToManager>
  </React.Fragment>
);


ProjectAnalyticsWidgets.propTypes = {
  project: PropTypes.object.isRequired,
  currentMonthAnalytics: PropTypes.object.isRequired,
  threeMonthsAnalytics: PropTypes.object.isRequired,
  sixMonthsAnalytics: PropTypes.object.isRequired,
  oneYearAnalytics: PropTypes.object.isRequired,
  fromStartAnalytics: PropTypes.object.isRequired,
};

export default ProjectAnalyticsWidgets;
