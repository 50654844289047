import {
  EXPENSE_STATUS,
  EXPENSE_STATUS_LABEL,
  WORKSHEET_STATUS,
  WORKSHEET_STATUS_LABEL,
  SERVICE_ORDER_TYPE,
} from 'projects/assets/js/constants';
import { addResizeToUrl } from 'core/assets/js/lib/utils-jsx';

const stringifiedProfilePic = ({ user }) => {
  const avatarUrl = user.avatar ? addResizeToUrl(user.avatar, 32) : '/img/default-avatar.png';
  return (
    `<div class="d-flex align-items-center flex-column">
      <span
        title='${user.name}'
        class="profile-pic rounded-circle flex-shrink-0"
        style="background-image: url(${avatarUrl}); width: 32px; height: 32px; flex-basis: 32px"
      ></span>
      <div class="mt-2 text-wrap">
        ${user.name}
      </div>
    </div>`
  );
};

const prepareSeriesChartData = ({ analytics, type }) => {
  const label = type === SERVICE_ORDER_TYPE.WORKSHEET
    ? WORKSHEET_STATUS_LABEL
    : EXPENSE_STATUS_LABEL;
  const status = type === SERVICE_ORDER_TYPE.WORKSHEET ? WORKSHEET_STATUS : EXPENSE_STATUS;
  const aggregatedData = [
    {
      name: [label[status.RAISED]],
      data: [],
    },
    {
      name: [label[status.APPROVED]],
      data: [],
    },
  ];

  if (analytics.length > 0) {
    analytics.forEach((sa) => {
      const wsApprovedAmount = type === SERVICE_ORDER_TYPE.EXPENSE
        ? sa.expensesApprovedAmount
        : sa.worksheetsApprovedAmount;
      const wsRaisedAmount = type === SERVICE_ORDER_TYPE.EXPENSE
        ? sa.expensesRaisedAmount
        : sa.worksheetsRaisedAmount;
      if (wsApprovedAmount || wsRaisedAmount) {
        aggregatedData[0].data.push(wsRaisedAmount || 0);
        aggregatedData[1].data.push(wsApprovedAmount || 0);
      }
    });
  }
  return aggregatedData;
};

export {
  prepareSeriesChartData,
  stringifiedProfilePic,
};
