import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { omit, pick } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FORM_ERROR } from 'final-form';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import ProjectTaskForm from 'projects/assets/js/components/ProjectTaskForm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { PROJECT_TABS, TASK_TABS } from 'core/assets/js/constants';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { extractCustomFieldSetup } from 'interviews/assets/js/lib/utils';
import { getCustomFieldTemplates, fetchCustomFieldTemplatesDS } from 'interviews/assets/js/ducks/customFields';
import { getListState } from 'core/assets/js/ducks/list';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { projectTaskSpec, projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectUpdateTaskDS, fetchProjectTaskDS, fetchProjectTaskItemsDS } from 'projects/assets/js/data-services/tasks';
import { projectViewUrl, projectViewTaskUrl, projectListUrl } from 'projects/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';

const overwriteCustomFieldPayloadsWithDimQuestion = (customFieldTemplatesIn, answers) => {
  const customFieldTemplates = customFieldTemplatesIn.slice(0);
  if (Array.isArray(answers) && answers.length > 0) {
    // We need to use the "frozen" payload from the dim_question records, so update the templates
    answers.forEach(a => {
      const customFieldTemplate = customFieldTemplates.find(t => (
        t.id === a.question.customFieldTemplateId
      ));
      if (!customFieldTemplate) {
        // Shouldn't happen
        return;
      }
      const customField = customFieldTemplate.questions.find(q => (
        q.id === a.question.customFieldId
      ));
      if (customField) {
        customField.payload = a.question.payload;
      }
    });
  }
  return customFieldTemplates;
};

class ProjectTaskEditView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { orgAlias, id: projectId, taskId } = params;

    return Promise.all([
      dispatch(
        fetchProjectTaskDS({ orgAlias, projectId, taskId, authedAxios, url, componentName }),
      ),
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias, entityType: FIELD_ENTITY_TYPE.TASK, authedAxios, url,
      })),
      dispatch(
        fetchProjectTaskItemsDS({ orgAlias, projectId, taskId, authedAxios, url, componentName }),
      ),
    ]);
  }

  static GetComponentName() {
    return 'ProjectTaskEditView';
  }

  constructor(props) {
    super(props);

    this.handleTaskSubmitted = this.handleTaskSubmitted.bind(this);
    this.handleTaskDiscarded = this.handleTaskDiscarded.bind(this);
  }

  async handleTaskSubmitted(values) {
    const {
      history, task: { id: taskId }, match: { params: { orgAlias, id: projectId } },
    } = this.props;

    try {
      const response = await projectUpdateTaskDS(
        orgAlias,
        projectId,
        taskId,
        { ...omit(values, 'skills'), skillIds: (values.skills || []).map(s => s.id) },
      );
      const { data: { task } } = response;
      history.push(projectViewTaskUrl(orgAlias, projectId, task.id, TASK_TABS.DASHBOARD));
      return {};
    } catch (error) {
      const errorData = error.response.data;
      if (errorData._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        [FORM_ERROR]: errorData._error || 'Oops! Something went wrong. Please try again',
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  }

  handleTaskDiscarded() {
    const { history, match: { params: { orgAlias, id: projectId } }, task } = this.props;
    history.push(projectViewTaskUrl(orgAlias, projectId, task.id, TASK_TABS.DASHBOARD));
  }

  render() {
    const {
      match: { params: { orgAlias, id } },
      project,
      task,
      taskItems,
    } = this.props;
    const customFieldTemplates = overwriteCustomFieldPayloadsWithDimQuestion(
      // eslint-disable-next-line react/destructuring-assignment
      this.props.customFieldTemplates, task.answers,
    );
    const projectOverviewUrl = projectViewUrl(orgAlias, id, PROJECT_TABS.OVERVIEW);
    const projectTasksUrl = projectViewUrl(orgAlias, id, PROJECT_TABS.TASKS);
    const {
      initialValues: customFieldInitialValues,
      selectedCustomFields,
    } = extractCustomFieldSetup({ answers: task.answers, templates: customFieldTemplates });


    const breadcrumbs = [
      { title: 'Projects', url: projectListUrl(orgAlias) },
      { title: project.title, url: projectOverviewUrl },
      { title: 'Tasks', url: projectTasksUrl },
      { title: task.title },
    ];

    const initialValues = {
      ...pick(task, ['deadline', 'description', 'skills', 'tags', 'title']),
      attachments: task.attachments ? task.attachments.map(att => att.attachment) : [],
      starts_on: task.startsOn ? moment(task.startsOn) : null,
      items: taskItems,
      ...customFieldInitialValues,
    };

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--add-worksheet">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={ProjectCardSkeleton}
            >
              <ProjectTaskForm
                project={project}
                onDiscard={this.handleTaskDiscarded}
                onTaskSubmitted={this.handleTaskSubmitted}
                initialValues={initialValues}
                customFields={selectedCustomFields}
                customFieldTemplates={customFieldTemplates}
                allowTemplateSelection={false}
                formHeading="Edit task"
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectTaskEditView.propTypes = {
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
  task: projectTaskSpec,
  match: routerMatchContentsSpec.isRequired,
  project: projectSpec,
  history: routerHistorySpec.isRequired,
  taskItems: PropTypes.array,
};

ProjectTaskEditView.defaultProps = {
  customFieldTemplates: [],
  project: {},
  task: {},
  taskItems: [],
};

const mapStateToProps = (state) => {
  const componentName = ProjectTaskEditView.GetComponentName();

  return {
    task: getViewState(state, componentName).item,
    taskItems: getListState(state, componentName).items,
    project: getViewStateExtras(state, componentName, 'project'),
    customFieldTemplates: getCustomFieldTemplates(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectTaskEditViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTaskEditView);

export default withRouter(ProjectTaskEditViewConnected);
