import axios from 'core/assets/js/lib/tdAxios';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import {
  editManagerBudgetApiUrl,
  fetchActiveManagerBudgetApiUrl,
  fetchManagerApiUrl,
  fetchManagerBudgetApiUrl,
  fetchManagerOwnsApiUrl,
} from 'people/urls';
import { validateObject } from 'core/assets/js/lib/utils';

export const PEOPLE_MANAGERBUDGET_IS_LOADING = 'people/MANAGERBUDGET_IS_LOADING';
export const PEOPLE_MANAGERBUDGET_FETCH = 'people/MANAGERBUDGET_FETCH';
export const PEOPLE_MANAGERBUDGET_RESET = 'people/MANAGERBUDGET_RESET';

export const peopleManagerBudgetIsLoadingAC = bool => ({
  type: PEOPLE_MANAGERBUDGET_IS_LOADING,
  isLoading: bool,
});

export const peopleManagerBudgetFetchAC = item => ({
  type: PEOPLE_MANAGERBUDGET_FETCH,
  item,
});

export const peopleManagerBudgetResetAC = () => ({
  type: PEOPLE_MANAGERBUDGET_RESET,
});

/**
 * Handles the manager edit budget view related state
 * @param state
 * @param action
 * @returns {*}
 */
const managerBudgetInitialState = {
  item: {},
  isLoading: false,
};
const managerBudget = (state = {}, action) => {
  switch (action.type) {
    case PEOPLE_MANAGERBUDGET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case PEOPLE_MANAGERBUDGET_FETCH:
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    case PEOPLE_MANAGERBUDGET_RESET:
      return managerBudgetInitialState;
    default:
      return state;
  }
};

export const initialState = {
  managerBudget: managerBudgetInitialState,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PEOPLE_MANAGERBUDGET_IS_LOADING:
    case PEOPLE_MANAGERBUDGET_FETCH:
    case PEOPLE_MANAGERBUDGET_RESET:
      return {
        ...state,
        managerBudget: managerBudget(state.managerBudget, action),
      };
    default:
      return state;
  }
};

export const fetchActiveManagerBudgetDS = ({
  orgAlias, componentName, authedAxios = null, url = '' } = {},
) => (
  dispatch => (
    dispatch(fetchViewDS({
      url: fetchActiveManagerBudgetApiUrl(orgAlias, url),
      componentName,
      authedAxios,
    }))
  )
);

export const fetchManagerBudgetDS = (params = {}, authedAxios = null, url = '') => (
  (dispatch) => {
    dispatch(peopleManagerBudgetIsLoadingAC(true));

    return dispatch(fetchDataDS({
      authedAxios,
      validate: () => validateObject(params, ['orgAlias', 'userId']),
      fetchApiUrl: () => fetchManagerBudgetApiUrl(params.orgAlias, params.userId, url),
      fetchDataAC: responseData => peopleManagerBudgetFetchAC(responseData),
      fetchDataErrorAC: () => peopleManagerBudgetIsLoadingAC(false),
    }));
  }
);

export const managerSendBudgetDiffDS = (orgAlias, userId, data) => {
  validateObject({ orgAlias, userId }, ['orgAlias', 'userId']);
  return axios.post(editManagerBudgetApiUrl(orgAlias, userId), data);
};

export const fetchManagerDS = ({ url = '', params, authedAxios, componentName }) => (
  fetchViewDS({
    url: fetchManagerApiUrl(params.orgAlias, params.userId, url),
    validate: () => validateObject(params, ['orgAlias', 'userId']),
    componentName,
    authedAxios,
  })
);

export const fetchManagerOwns = ({ authedAxios, componentName, orgAlias, userId }) => (
  dispatch => (
    dispatch(fetchViewDS({
      authedAxios, componentName, url: fetchManagerOwnsApiUrl(orgAlias, userId),
    }))
  )
);

export default reducer;
