import React, { useState } from 'react';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { projectViewTaskUrl } from 'projects/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { orgPeopleListApiUrl } from 'people/urls';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import { BS_STYLE, TASK_TABS, USER_CARD_STATUS, IMG_SIZE } from 'core/assets/js/constants';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { fetchListDS, getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { taskAddManagersDS } from 'projects/assets/js/data-services/tasks';
import { pluralize } from 'core/assets/js/lib/utils';
import { parseSearchableFieldsInQuery } from 'interviews/assets/js/lib/utils';

export const MODAL_ID = 'task-add-managers-modal';

const STEPS = {
  SELECT_MEMBERS: 1,
  CONFIRM_SELECTION: 2,
};

const TaskAddManagersPanel = ({
  activeUserCard,
  modalPayload,
  modalPayload: {
    taskId,
    userType,
    orgAlias,
    projectId,
  },
  customFieldFilters,
  filtersOpen,
  isAddMembersModalOpen,
  onFiltersToggle,
  people,
  pagination,
  history,
  rateLimits,
  selectedItems,
  userGroupNames,
}) => {
  const dispatch = useDispatch();
  const constantFilters = {
    excludeMembersOfTask: taskId,
    exclude: [activeUserCard?.user?.id],
    status: USER_CARD_STATUS.APPROVED,
    useFallback: true, // THIS IS TEMPORARY WHILST WE INVESTIGATE ES INDEXING ISSUES
  };
  const [query, setQuery] = useState({ ...constantFilters });
  const [submitting, setSubmitting] = useState(false);
  const [showSelectedItems, setShowSelectedItems] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS.SELECT_MEMBERS);

  const handleClosePanel = () => {
    dispatch(modalCloseAC(MODAL_ID));
  };

  const handleQueryUpdate = (newQuery) => {
    setQuery({
      ...constantFilters, ...parseSearchableFieldsInQuery(newQuery), page: pagination.page,
    });
  };

  const handlePageChange = (newPagination) => {
    const newQuery = { ...constantFilters, ...query, ...newPagination };
    setQuery(newQuery);
  };

  const handleShowSelectedItemsToggle = () => {
    setShowSelectedItems(!showSelectedItems);
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const userIds = selectedItems.map(m => m.user.id);
      await dispatch(taskAddManagersDS({
        orgAlias,
        projectId,
        taskId,
        userIds,
      }));

      history.push({
        pathname: projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.MANAGERS),
        search: `?dt=${moment().unix()}`, // Trigger list reload
      });

      handleClosePanel();
      const usersCount = userIds.length;
      toastr.success(
        'Well Done!',
        `${pluralize('Manager', usersCount)} added successfully.`,
      );

      return true;
    } catch (error) {
      return {
        [FORM_ERROR]: error.response?.data?._error ? error.response.data._error : error.message,
      };
    } finally {
      setSubmitting(false);
    }
  };

  if (!modalPayload) {
    return null;
  }

  const userGroupNamesFormatted = userGroupNames.map(ugn => ({
    value: ugn.id.toString(), text: ugn.name,
  }));
  const availabilityFilter = query.availability ? JSON.parse(query.availability) : null;
  const selectedItemsLength = selectedItems.length;
  const noneSelected = selectedItemsLength === 0;

  const submitButtonLabel = `Add ${pluralize('manager', selectedItemsLength)}`;

  return (
    <ModalPanel
      modalId={MODAL_ID}
      open={isAddMembersModalOpen}
      heading={<h4>{`Add ${userType}`}</h4>}
      body={(
        <>
          {/* Member selection Step */}
          <SelectableListWrapper
            className={currentStep === STEPS.CONFIRM_SELECTION ? 'd-none' : null}
            componentName={TaskAddManagersPanel.GetComponentName()}
            fetchData={
              ({ params, ...rest }) => TaskAddManagersPanel.FetchData({
                ...rest, params: { ...params, peopleType: PEOPLE_TYPE.MANAGERS },
              })
            }
            skeletonComponent={PeopleListSkeleton}
            query={query}
            filtersOpen={filtersOpen}
            selectModeEnabled
            showSelectedItems={showSelectedItems}
            cardItem={{
              component: ManagerCard,
              props: {
                orgAlias,
                onUpdated: () => {},
                availabilityFilter,
                showAvailabilityMessages: true,
                // Open link in a new tab so that user doesn't loose selected items.
                linkTarget: '_blank',
                listViewComponentName: TaskAddManagersPanel.GetComponentName(),
              },
            }}
            onPageChange={handlePageChange}
            emptyListMessage={`No ${userType} found.`}
            emptySelectedListMessage={(
              <p className="discreet">
                {`There are currently no selected ${userType}.`}
                <br />
                <span
                  className="imitate-link"
                  onClick={handleShowSelectedItemsToggle}
                >
                  {`Find ${userType}`}
                </span>
                .
              </p>
            )}
            searchComponent={(
              <PeopleSearch
                customFieldFilters={customFieldFilters}
                filtersOpen={filtersOpen}
                hideStatus
                isManager
                onFiltersChanged={handleQueryUpdate}
                onFiltersToggle={onFiltersToggle}
                peopleType={userType}
                query={query}
                rateLimits={rateLimits}
                userGroupNames={userGroupNamesFormatted}
              />
            )}
            items={people}
          />

          {/* Confirmation Step */}
          {currentStep === STEPS.CONFIRM_SELECTION && (
            <div className="d-flex flex-column h-100">
              <p>
                {`You’ve selected to invite
                    ${selectedItemsLength} new ${pluralize('manager', selectedItemsLength)}
                   `}
              </p>
              <ul className="mb-4">
                {selectedItems && selectedItems.map(
                  member => (
                    <ProfilePic
                      key={member.user.id}
                      className="mr-3 mb-3"
                      url={member.user.profile.avatar}
                      alt={member.user.profile.name}
                      size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                    />
                  ),
                )}
              </ul>

              <p className="mt-auto discreet mr-5">
                By adding a manager to a Task, if they are not already part of the Project team
                then they will be automatically added to that too.
              </p>
            </div>
          )}
        </>
      )}
      footer={(
        <React.Fragment>
          {currentStep === STEPS.SELECT_MEMBERS && selectedItemsLength > 0 && (
            <span className="cursor-pointer d-flex align-items-center" onClick={handleShowSelectedItemsToggle}>
              <TDSwitch selected={showSelectedItems} />
              <span className="ml-3">
                { !showSelectedItems
                  ? `Show ${selectedItemsLength} selected ${pluralize('manager', selectedItemsLength)}`
                  : `Show all ${userType}`
                }
              </span>
            </span>
          )}

          <div className="ml-auto">
            { currentStep === STEPS.SELECT_MEMBERS && (
              <React.Fragment>
                <TDButton
                  label="Cancel"
                  onClick={handleClosePanel}
                />

                <TDButton
                  className="ml-4"
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                  disabled={noneSelected}
                  label="Next"
                  onClick={() => {
                    setCurrentStep(STEPS.CONFIRM_SELECTION);
                  }}
                />
              </React.Fragment>
            )}

            { currentStep === STEPS.CONFIRM_SELECTION && (
              <React.Fragment>
                <TDButton
                  label="Back"
                  disabled={submitting}
                  onClick={() => {
                    setCurrentStep(STEPS.SELECT_MEMBERS);
                  }}
                />

                <TDButton
                  className="ml-4"
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                  disabled={noneSelected || submitting}
                  label={submitButtonLabel}
                  onClick={onSubmit}
                />
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    />
  );
};

TaskAddManagersPanel.GetComponentName = () => 'TaskAddManagersPanel';

TaskAddManagersPanel.FetchData = async ({
  dispatch,
  componentName,
  authedAxios,
  querystring,
  params,
  url,
}) => {
  const apiUrl = orgPeopleListApiUrl(params, url);
  return dispatch(fetchListDS({
    url: apiUrl,
    querystring,
    componentName,
    authedAxios,
  }));
};

TaskAddManagersPanel.propTypes = {
  activeUserCard: PropTypes.object.isRequired,
  modalPayload: PropTypes.object,
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  isAddMembersModalOpen: PropTypes.bool,
  onFiltersToggle: PropTypes.func.isRequired,
  orgAlias: PropTypes.string,
  pagination: PropTypes.object.isRequired,
  people: PropTypes.array,
  rateLimits: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  userGroupNames: PropTypes.array,
};

TaskAddManagersPanel.defaultProps = {
  modalPayload: {},
  customFieldFilters: [],
  isAddMembersModalOpen: false,
  orgAlias: '',
  people: [],
  rateLimits: {},
  userGroupNames: [],
};

const mapStateToProps = (state, props) => {
  const componentName = TaskAddManagersPanel.GetComponentName();
  const listState = getListState(state, componentName);

  return {
    modalPayload: getModalPayload(state, MODAL_ID),
    customFieldFilters: getListStateExtras(state, componentName, 'customFieldFilters'),
    isAddMembersModalOpen: getIsModalOpen(state, MODAL_ID),
    match: props.match,
    pagination: listState.pagination,
    people: listState.items,
    rateLimits: listState.extras.rateLimits,
    searchActive: listState.search.isActive,
    selectedItems: listState.selectedItems,
    activeUserCard: selectActiveUserCard(state),
    userGroupNames: listState.extras.userGroupNames,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TaskAddManagersPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAddManagersPanel);

export default withRouter(withFilters(TaskAddManagersPanelConnected));
