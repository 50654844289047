import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';


export const modalStateSpec = PropTypes.shape({
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
});

const withStateModal = (WrappedComponent) => {
  const _WithStateModal = ({ onClose, isModalOpen, triggerEl, ...passThroughProps }) => {
    const [isOpen, setIsOpen] = useState(isNil(isModalOpen) ? false : isModalOpen);

    if (!isNil(isModalOpen) && isOpen !== isModalOpen) {
      setIsOpen(isModalOpen);
    }

    return (
      <React.Fragment>
        {triggerEl && React.cloneElement(triggerEl, { onClick: () => {
          setIsOpen(true);
        } })}
        <WrappedComponent
          {...passThroughProps}
          modalState={{
            isOpen,
            open: () => {
              setIsOpen(true);
            },
            close: () => {
              setIsOpen(false);
              onClose();
            },
          }}
        />
      </React.Fragment>
    );
  };

  _WithStateModal.propTypes = {
    onClose: PropTypes.func,
    isModalOpen: PropTypes.bool,
    triggerEl: PropTypes.node,
  };

  _WithStateModal.defaultProps = {
    onClose: () => {},
    isModalOpen: null,
    triggerEl: null,
  };

  return _WithStateModal;
};

export default withStateModal;
