import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Dropdown } from 'react-bootstrap';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import EditableValueField, {
  UPDATE_MANUALLY, UPDATE_ON_BLUR,
} from 'core/assets/js/components/FinalFormFields/EditableValueField.jsx';

export {
  UPDATE_MANUALLY, UPDATE_ON_BLUR,
} from 'core/assets/js/components/FinalFormFields/EditableValueField.jsx';

const TaskItemField = ({
  index,
  disabled,
  fieldName,
  updateMode,
  isEditable,
  isCompleted,
  onItemRemoved,
  onItemDuplicated,
  onCompleteUpdated,
  onDescriptionUpdated,
  onDescriptionEditable,
  onDescriptionViewable,
}) => {
  const [controlsVisibility, setControlsVisibility] = useState(!isEditable);

  // once the `completed` field changes, trigger the `blur` event so that changes are stored
  const handleCompleteUpdated = async (e) => {
    await onCompleteUpdated(index, e, e.target.checked);
  };

  // prevent form submission when the description changes
  const handleDescriptionUpdated = (event) => {
    onDescriptionUpdated(index, event);
  };

  const handleDescriptionEditable = () => {
    setControlsVisibility(false);
    onDescriptionEditable(index);
  };

  const handleDescriptionViewable = (event) => {
    setControlsVisibility(true);
    onDescriptionViewable(index, event);
  };

  const handleItemDuplicated = e => (
    onItemDuplicated(index, e)
  );

  const handleItemRemoved = e => (
    onItemRemoved(index, e)
  );

  let hasVisibleControls = false;
  if (updateMode === UPDATE_MANUALLY) {
    hasVisibleControls = !isEditable;
  } else {
    hasVisibleControls = !!controlsVisibility;
  }

  const getFieldName = attr => (
    fieldName ? `${fieldName}.${attr}` : attr
  );

  return (
    <div
      className={`input-group task-checklist__item mx-n2 px-2 w-auto ${isCompleted ? 'task-checklist__item--completed' : ''}`}
    >
      <Field
        name={getFieldName('id')}
        disabled={disabled}
        component="input"
        type="hidden"
      />

      <Field
        name={getFieldName('index')}
        disabled={disabled}
        component="input"
        type="hidden"
      />

      {hasVisibleControls && (
        <div className="input-group-prepend">
          <span className="input-group-text align-items-start pl-1 mr-0 pt-3">
            <Field
              name={getFieldName('completed')}
              disabled={disabled}
              component="input"
              type="checkbox"
              onChange={handleCompleteUpdated}
              className="task-checklist__item__completion-checkbox"
            />
          </span>
        </div>
      )}

      <Field
        name={getFieldName('description')}
        disabled={disabled}
        component={EditableValueField}
        onValueRemoved={handleItemRemoved}
        onValueUpdated={handleDescriptionUpdated}
        onEditable={handleDescriptionEditable}
        onViewable={handleDescriptionViewable}
        isEditable={isEditable}
        updateMode={updateMode}
        type="text"
        className="task-checklist__item__description"
        viewingModeClassName="input-group-text px-2"
      />

      {hasVisibleControls && !disabled && (
        <TDDropButton
          className="px-3 mr-2"
        >
          <Dropdown.Item onClick={handleItemDuplicated}>
            Duplicate
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleItemRemoved}>
            <span className="text-danger">
              Delete
            </span>
          </Dropdown.Item>
        </TDDropButton>
      )}
    </div>
  );
};

TaskItemField.propTypes = {
  fieldName: PropTypes.string,
  index: PropTypes.number.isRequired,
  isCompleted: PropTypes.bool,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  updateMode: PropTypes.oneOf([UPDATE_MANUALLY, UPDATE_ON_BLUR]),
  onCompleteUpdated: PropTypes.func,
  onDescriptionUpdated: PropTypes.func,
  onDescriptionEditable: PropTypes.func,
  onDescriptionViewable: PropTypes.func,
  onItemRemoved: PropTypes.func.isRequired,
  onItemDuplicated: PropTypes.func.isRequired,
};

TaskItemField.defaultProps = {
  disabled: false,
  updateMode: UPDATE_ON_BLUR,
  fieldName: null,
  isCompleted: false,
  onCompleteUpdated: () => {},
  onDescriptionUpdated: () => {},
  onDescriptionEditable: () => {},
  onDescriptionViewable: () => {},
};

export default TaskItemField;
