import React from 'react';
import PropTypes from 'prop-types';

import TDRadio from 'core/assets/js/components/TDRadio.jsx';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import withField from 'core/assets/js/components/withField.jsx';


const RadioField = ({
  additionalError,
  className,
  'data-testid': dataTestId,
  disabled,
  input,
  inputClassName,
  label,
  labelClassName,
  meta: { error, pristine, submitError },
  options,
  required,
  sublabel,
  showInline,
  wrapperClasses,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = !!error || !!submitError || showAdditionalError;
  const groupClassName = ['form-group'];

  if (hasError) {
    groupClassName.push('has-error');
  }
  if (wrapperClasses) {
    groupClassName.push(wrapperClasses);
  }

  const classNames = ['form-group custom-radio-field'];

  if (className) {
    classNames.push(className);
  }

  if (showInline) {
    classNames.push('custom-radio-field--inline');
  }

  if (disabled) {
    classNames.push('custom-radio-field--disabled');
  }

  if (inputClassName) {
    classNames.push(inputClassName);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  const _options = options.map((option) => {
    const key = option.path
      ? `${option.path}-${option.value}`
      : option.value;

    return (
      <div className="mb-2" key={key} onClick={() => input.onChange(option.value)}>
        <TDRadio
          selected={input.value === option.value}
          className="ml-0 d-flex align-items-center"
          label={(
            <div className="pl-2">
              {option.text}
              {option.sublabel && (
                <span className="sublabel">{option.sublabel}</span>
              )}
            </div>
          )}
        />
      </div>
    );
  });

  const field = (
    <div
      className={classNames.join(' ')}
      id={`field-${input.name}`}
    >
      {_options}
    </div>
  );

  return (
    <div className={groupClassName.join(' ')} data-testid={dataTestId}>
      {label && (
        <label htmlFor={input.name} className={labelClassName}>
          {label}
          {required && ' *'}
          {sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
      )}

      {showAdditionalError && <span className="help-block">{additionalError}</span>}
      {field}
      {hasError && <span className="help-block">{submitError || error}</span>}
    </div>
  );
};

RadioField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultOptionText: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  inputClassName: PropTypes.string,
  label: finalFormFieldLabelSpec,
  labelClassName: PropTypes.string,
  meta: finalFormFieldMetaSpec,
  options: PropTypes.array.isRequired,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  showInline: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

RadioField.defaultProps = {
  additionalError: '',
  className: null,
  'data-testid': null,
  defaultOptionText: 'Choose option',
  disabled: false,
  inputClassName: '',
  label: '',
  labelClassName: '',
  meta: {
    error: '',
    submitError: '',
    pristine: true,
  },
  prefix: null,
  required: false,
  showInline: false,
  sublabel: null,
  suffix: null,
  wrapperClasses: '',
};

export default withField(RadioField);
