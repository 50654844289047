import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';

import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, MULTISELECT_TYPE, MULTISELECT_TARGET } from 'core/assets/js/constants';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';
import MultiselectField from 'core/assets/js/components/ReduxFormFields/MultiselectField.jsx';

export const ENDORSE_SKILL_MODAL_ID = 'endorse-skill-modal';
export const ENDORSE_SKILL_FORM_ID = 'endorse-skill-form';


/**
 * Skills Endorse Modal
 * this component holdes logic
 * for selecting skills for endorsment
 */

class EndorseSkillsModal extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      upVotes: [],
      retractVotes: [],
    };

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleUpVote = this.handleUpVote.bind(this);
    this.handleRetractVote = this.handleRetractVote.bind(this);
  }

  handleModalClose() {
    this.clearState();
    const { dispatch } = this.props;
    dispatch(reset(ENDORSE_SKILL_FORM_ID));
    dispatch(modalCloseAC());
  }

  handleModalSubmit(values, dispatch) {
    const { onSubmit } = this.props;
    const { upVotes, retractVotes } = this.state;


    return onSubmit({
      upVotes,
      retractVotes,
    }, dispatch).then(() => {
      this.handleModalClose();
    });
  }

  handleUpVote(newUpVotes) {
    const { upVotes } = this.state;
    this.setState({
      upVotes: [...upVotes, ...newUpVotes],
    });
  }

  handleRetractVote(newRetractVotes) {
    const { retractVotes } = this.state;
    this.setState({
      retractVotes: [...retractVotes, ...newRetractVotes],
    });
  }

  clearState() {
    this.setState({
      upVotes: [],
      retractVotes: [],
    });
  }

  render() {
    const { open, submitting, skills, handleSubmit, username, filteringSkills } = this.props;

    return (
      <div className="static-modal">
        <form onSubmit={handleSubmit(this.handleModalSubmit)}>
          <ModalSimple
            open={open}
            onClose={this.handleModalClose}
            heading="Endorse skill"
            body={(
              <div>
                <p>
                  Have you worked with
                  <strong>{` ${username} `}</strong>
                  within TalentDesk.io, or outside of the platform,
                  and want to share your experience with your team members?
                </p>
                <p>
                  Υοu can do so by endorsing
                  <strong>{` ${username} `}</strong>
                  for additional skills.
                </p>
                <p>
                  Please select the skills you want to endorse
                  <strong>{` ${username} `}</strong>
                  for and confirm by clicking &rdquo;Endorse skills&rdquo;.
                </p>
                <Field
                  name="skills"
                  component={MultiselectField}
                  multiselectComponent={MULTISELECT_TYPE.SKILL}
                  target={MULTISELECT_TARGET.SKILLS}
                  filteringItems={filteringSkills}
                  onUpVote={this.handleUpVote}
                  onRetractVote={this.handleRetractVote}
                />
              </div>
            )}
            footer={[
              <TDButton
                key={1}
                className="float-right"
                label="Endorse skills"
                variant={BS_STYLE.PRIMARY}
                disabled={submitting || !skills}
                onClick={handleSubmit(this.handleModalSubmit)}
              />,
            ]}
          />
        </form>
      </div>
    );
  }
}

EndorseSkillsModal.propTypes = {
  skills: PropTypes.arrayOf(skillSpec),
  filteringSkills: PropTypes.arrayOf(skillSpec),
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  username: PropTypes.string,
};

EndorseSkillsModal.defaultProps = {
  open: false,
  skills: [],
  filteringSkills: [],
  username: null,
};

const selector = formValueSelector(ENDORSE_SKILL_FORM_ID);

const mapStateToProps = (state) => {
  const modalPayload = getModalPayload(state, ENDORSE_SKILL_MODAL_ID);
  return {
    skills: selector(state, 'skills'),
    initialValues: {
      skills: modalPayload?.skills,
    },
    open: getIsModalOpen(state, ENDORSE_SKILL_MODAL_ID),
    username: modalPayload?.username,
    filteringSkills: modalPayload?.filteringSkills,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const EndorseSkillsModalForm = reduxForm({
  form: ENDORSE_SKILL_FORM_ID,
  enableReinitialize: true,
})(EndorseSkillsModal);

const EndorseSkillsModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EndorseSkillsModalForm);

export default EndorseSkillsModalConnected;
