import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import BillingHistoryTable from 'finance/assets/js/components/BillingHistoryTable.jsx';
import { BILLING_TABS } from 'finance/assets/js/constants';
import { billingViewUrl } from 'finance/urls';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import BillingOverviewSubscription from 'finance/assets/js/components/BillingOverviewSubscription.jsx';


const BillingTabOverview = ({ match: { params: { orgAlias } } }) => (
  <div className="row">
    <div className="col-12 col-xl-4 mb-3">
      <BillingOverviewSubscription />
    </div>
    <div className="col-12">
      <Card>
        <Card.Header>
          Billing history
        </Card.Header>
        <Card.Body>
          <BillingHistoryTable isOverview />
        </Card.Body>
        <Card.Footer className="d-flex card-footer--secondary">
          <Link
            className="ml-auto"
            to={billingViewUrl(orgAlias, BILLING_TABS.BILLING_HISTORY)}
          >
            View full history
          </Link>
        </Card.Footer>
      </Card>
    </div>
  </div>
);

BillingTabOverview.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(BillingTabOverview);
