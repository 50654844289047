import { get, isEmpty, pick } from 'lodash';
import { matchPath } from 'react-router';
import * as urls from 'accounts/urls';
import { mainGuestUrl } from 'core/urls';
import {
  orgDashboardUrl, orgOnboardStepUrl, orgPlanStatusUrl, orgViewUrl,
} from 'organizations/urls';
import { DEFAULT_METATAGS, LAYOUTS } from 'core/assets/js/constants';

// App routes
import analyticsRouteConfig from 'analytics/assets/js/routes.jsx';
import contactsRouteConfig from 'contacts/assets/js/routes.jsx';
import documentsRouteConfig from 'documents/assets/js/routes.jsx';
import financeRouteConfig from 'finance/assets/js/routes.jsx';
import importerRouteConfig from 'importer/assets/js/routes.jsx';
import integrationsRouteConfig from 'integrations/assets/js/routes.jsx';
import interviewsRouteConfig from 'interviews/assets/js/routes.jsx';
import invitationsRouteConfig from 'invitations/assets/js/routes.jsx';
import organizationsRouteConfig from 'organizations/assets/js/routes.jsx';
import peopleRouteConfig from 'people/assets/js/routes.jsx';
import profileRoutesConfig from 'accounts/assets/js/routes.jsx';
import projectsRouteConfig from 'projects/assets/js/routes.jsx';
import rolesRouteConfig from 'roles/assets/js/routes.jsx';
import settingsRouteConfig from 'settings/assets/js/routes.jsx';
import { routes as notifierRouteConfig } from 'notifier/assets/js';

// Views
import EmailVerifyView from 'accounts/assets/js/EmailVerifyView.jsx';
import ErrorTestbed from 'playground/assets/js/ErrorTestbed.jsx';
import LoginView from 'accounts/assets/js/LoginView.jsx';
import GuestChat from 'accounts/assets/js/GuestChat.jsx';
import OrgDashboardPage from 'organizations/assets/js/OrgDashboardPage.jsx';
import OrgOnboardView from 'organizations/assets/js/OrgOnboardView.jsx';
import OrgViewPage from 'organizations/assets/js/OrgViewPage.jsx';
import PasswordForgotView from 'accounts/assets/js/PasswordForgotView.jsx';
import PasswordResetView from 'accounts/assets/js/PasswordResetView.jsx';
import PlanStatusView from 'core/assets/js/PlanStatusView.jsx';
import SignupVerifyView from 'accounts/assets/js/SignupVerifyView.jsx';
import SignupView from 'accounts/assets/js/SignupView.jsx';
import SignupCleanView from 'accounts/assets/js/SignupCleanView.jsx';
import StyleGuideView from 'playground/assets/js/StyleGuideView.jsx';

// The routes configuration
export const routesConfig = [
  {
    component: LoginView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
    layoutProps: { showTalentdeskLogo: false },
    path: urls.loginUrl(),
  },
  {
    component: LoginView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
    layoutProps: { showTalentdeskLogo: false },
    path: urls.orgLoginUrl(':orgAlias'),
  },
  // just a plain route for guests, useful for tests
  {
    component: LoginView,
    layout: LAYOUTS.PLAIN_SOLO,
    path: mainGuestUrl(),
  },
  {
    component: SignupView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
    layoutProps: { showTalentdeskLogo: false },
    path: urls.signupUrl(),
  },
  {
    component: SignupCleanView,
    darkBackground: true,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: urls.signupCleanUrl(),
  },
  {
    component: GuestChat,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: urls.guestChatUrl(':token'),
  },
  {
    component: SignupView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
    layoutProps: { showTalentdeskLogo: false },
    path: urls.orgSignupInviteUrl(':orgAlias', ':token'),
    requireAuth: false,
    requireUserIsApprovedOrgMember: false,
    skipOrgSwitch: true,
  },
  {
    component: SignupView,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
    layoutProps: { showTalentdeskLogo: false },
    path: urls.signupInviteUrl(':token'),
  },
  {
    component: EmailVerifyView,
    layout: LAYOUTS.WITH_HEADER,
    layoutProps: {
      title: 'Success',
    },
    path: urls.emailVerifyUrl(':token'),
  },
  {
    component: SignupVerifyView,
    layout: LAYOUTS.WITH_HEADER,
    layoutProps: {
      title: 'Verify email',
    },
    path: urls.signupVerifyUrl(),
    requireAuth: false,
  },
  {
    component: PasswordForgotView,
    layout: LAYOUTS.PLAIN_SOLO,
    path: urls.passwordForgotUrl(),
  },
  {
    component: PasswordResetView,
    layout: LAYOUTS.PLAIN_SOLO,
    path: urls.passwordResetUrl(':token'),
  },
  {
    component: PasswordResetView,
    layout: LAYOUTS.PLAIN_SOLO,
    path: urls.passwordCreateUrl(':token'),
  },
  {
    component: OrgOnboardView,
    layout: LAYOUTS.WITH_HEADER,
    layoutProps: {
      darkBackground: true,
      title: 'Create an organization',
    },
    path: orgOnboardStepUrl(),
    requireAuth: true,
  },
  {
    component: StyleGuideView,
    exact: true,
    layout: LAYOUTS.WITH_HEADER,
    layoutProps: { title: 'Playground' },
    path: '/playground',
    requireAuth: true,
  },
  {
    component: StyleGuideView,
    exact: true,
    layout: LAYOUTS.WITH_HEADER,
    path: '/playground/:section',
    requireAuth: true,
  },
  {
    component: ErrorTestbed,
    exact: true,
    layout: LAYOUTS.PLAIN,
    path: '/error-testbed',
    requireAuth: true,
  },
  {
    component: ErrorTestbed,
    exact: true,
    layout: LAYOUTS.PLAIN,
    path: '/error-testbed/:section',
    requireAuth: true,
  },
  {
    component: PlanStatusView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgPlanStatusUrl(':orgAlias'),
    requireAuth: true,
  },
  {
    path: orgDashboardUrl(':orgAlias'),
    component: OrgDashboardPage,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireAuth: true,
  },
  ...projectsRouteConfig,
  ...importerRouteConfig,
  ...invitationsRouteConfig,
  ...interviewsRouteConfig,
  ...integrationsRouteConfig,
  ...notifierRouteConfig,
  ...peopleRouteConfig,
  ...organizationsRouteConfig,
  ...settingsRouteConfig,
  ...financeRouteConfig,
  ...profileRoutesConfig,
  ...analyticsRouteConfig,
  ...contactsRouteConfig,
  ...documentsRouteConfig,
  ...rolesRouteConfig,
  {
    // since this is a root-level wildcard, it should be the last entry in the config
    component: OrgViewPage,
    exact: true,
    layout: LAYOUTS.PLAIN_SOLO,
    path: orgViewUrl(':orgAlias'),
  },
];

const getMetaTags = (route) => {
  if (!route || !route.component || typeof route.component.getMetaTags !== 'function') {
    return { ...DEFAULT_METATAGS };
  }
  const _tags = route.component.getMetaTags();
  // Use default meta tags if any tag is missing / not set from the component.
  const metaTags = { ...DEFAULT_METATAGS, ..._tags };
  // If title is set, append ' | TalentDesk.io'.
  if (!isEmpty(_tags.title)) {
    metaTags.title = `${metaTags.title} | TalentDesk.io`;
  }

  // Generate canonical url
  metaTags.canonical = `https://talentdesk.io${route.path}`;

  return metaTags;
};

export const extractRequirements = (matchedRoute) => {
  if (!matchedRoute) {
    return {};
  }
  const result = pick(matchedRoute, [
    'requireAuth',
    'requireFinController',
    'requireManager',
    'requireOrgCreator',
    'requirePermission',
    'requireProvider',
    'requireUserIsApprovedOrgMember',
  ]);
  if (result.requirePermission && !result.requireAuth) {
    result.requireAuth = true;
  }
  return result;
};

export const matchRoute = (location) => {
  const { pathname } = location;
  if (!pathname) {
    throw new Error('unknown pathname');
  }
  const matchedRoute = routesConfig.find(r => matchPath(pathname, r));
  const match = matchedRoute ? matchPath(pathname, matchedRoute) : null;
  const targetOrgAlias = get(match, 'params.orgAlias');
  return {
    match,
    matchedRoute,
    metaTags: getMetaTags(matchedRoute),
    requirements: extractRequirements(matchedRoute),
    skipOrgSwitch: (matchedRoute && matchedRoute.skipOrgSwitch) || false,
    targetOrgAlias,
  };
};

export default routesConfig;
