// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { USER_TYPE } from 'core/assets/js/constants';


/**
 * Wrapper that renders its children only when the user is authorized to see them
 *
 */
const AllowAccess = ({ hasOrgAccess, oneOf, children }) => {
  const requireAuth = true;
  const requireManager = oneOf.includes(USER_TYPE.MANAGER);
  const requireProvider = oneOf.includes(USER_TYPE.PROVIDER);
  const requireFinController = oneOf.includes(USER_TYPE.FIN_CONTROLLER);
  const requireOrgCreator = oneOf.includes(USER_TYPE.ORG_CREATOR);
  const hasAccessToActiveOrg = hasOrgAccess({
    requireAuth, requireManager, requireProvider, requireFinController, requireOrgCreator,
  });
  if (!hasAccessToActiveOrg) {
    return null;
  }
  return (hasAccessToActiveOrg && children);
};


AllowAccess.propTypes = {
  children: PropTypes.any.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  oneOf: PropTypes.array,
};

AllowAccess.defaultProps = {
  oneOf: [],
};

const mapStateToProps = state => ({
  hasOrgAccess: getHasOrgAccess(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllowAccess);
