import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { orgSettingsRatesUrl } from 'rates/urls';
import { cancelRateDS } from 'rates/assets/js/data-services/rates';
import { RATE_STATUS } from 'rates/assets/js/constants';


class RateCancelForm extends React.Component {
  static GetComponentName() {
    return 'RateCancelForm';
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const { dispatch, match, rate } = this.props;

    return dispatch(cancelRateDS(match.params.orgAlias, rate.id, values));
  }

  render() {
    const { handleSubmit, history, match, rate, submitting } = this.props;
    const { orgAlias } = match.params;
    // A rate is considered new if the user creates it now or it's still in DRAFT state
    const newRate = !rate.status || rate.status === RATE_STATUS.DRAFT;
    const cancelBtnLabel = newRate
      ? 'Cancel rate request'
      : 'Cancel rate adjustment';

    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(this.submit)}>
          <div className="mt-4 text-right button-container">
            <TDButton
              type="button"
              disabled={submitting}
              label="Cancel"
              onClick={() => {
                history.push(orgSettingsRatesUrl(orgAlias));
              }}
            />
            <TDButton
              type="submit"
              variant={BS_STYLE.WARNING}
              disabled={submitting}
              label={cancelBtnLabel}
            />
          </div>
        </form>
      </React.Fragment>
    );
  }
}

RateCancelForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  rate: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
};
RateCancelForm.defaultProps = {};

const RateCreateReduxForm = reduxForm({
  form: 'rate-cancel',
})(RateCancelForm);

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const RateCancelFormConnected = connect(
  null, mapDispatchToProps,
)(RateCreateReduxForm);

export default withRouter(RateCancelFormConnected);
