import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { validateLength } from 'core/assets/js/lib/utils';

export const REJECT_MODAL_ID = 'application-reject-modal';
const validateReason = value => validateLength(value, 2000);

const FORM_ID = 'rejectProjectApplicationForm';

let ProjectApplicationRejectModalForm = ({ isOpen, error, handleSubmit, submitting, dispatch,
}) => (
  <React.Fragment>
    <form onSubmit={handleSubmit}>
      <ModalSimple
        open={isOpen}
        heading="Reject project application?"
        body={(
          <React.Fragment>
            <Field
              name="reason"
              type="textarea"
              label="Reason"
              validate={validateReason}
              placeholder="Please provide a reason..."
              component={InputField}
            />
            {error && (
              <div className="form-group has-error">
                <span className="help-block">{error}</span>
              </div>
            )}
          </React.Fragment>
        )}
        onClose={() => dispatch(modalCloseAC(REJECT_MODAL_ID))}
        footer={(
          <React.Fragment>
            <TDButton
              type="button"
              variant={BS_STYLE.DEFAULT}
              label="Close"
              onClick={() => dispatch(modalCloseAC(REJECT_MODAL_ID))}
              disabled={submitting}
            />

            <TDButton
              type="submit"
              variant={BS_STYLE.DANGER}
              label="Reject"
              onClick={handleSubmit}
              disabled={submitting}
            />
          </React.Fragment>
        )}
      />
    </form>
  </React.Fragment>
);

ProjectApplicationRejectModalForm.propTypes = {
  application: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
ProjectApplicationRejectModalForm.defaultProps = {
  error: null,
};

const selector = formValueSelector(FORM_ID);
const mapStateToProps = state => ({
  gracePeriod: parseInt(selector(state, 'grace_period'), 10),
  comments: selector(state, 'comments'),
});
const mapDispatchToProps = dispatch => ({ dispatch });

ProjectApplicationRejectModalForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectApplicationRejectModalForm);

const ProjectApplicationRejectModalFormConnect = reduxForm({
  form: FORM_ID,
})(ProjectApplicationRejectModalForm);

export default ProjectApplicationRejectModalFormConnect;
