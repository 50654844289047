import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { BS_STYLE, ICON, ERROR_REPORT_TYPE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { modalCloseAC, modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { postErrorReportDS } from 'core/assets/js/data-services/form';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';

class ModalErrorSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit({ userMessage }) {
    const { payload, dispatch, pageView } = this.props;
    dispatch(postErrorReportDS({
      values: {
        // Related info that need to follow the error report so we can debug efficiently
        payload,
        // The page where the modal will show up
        // It is used by us to identify the part of our app from which the message was sent
        pageView,
        // The error msg submitted by the user
        userMessage,
        errorReportType: ERROR_REPORT_TYPE.PAYMENTS,
      },
    }))
      .then(() => {
        toastr.success('Well Done!', 'Your information were submitted successfully.');
        this.handleModalClose();
      });
  }

  handleModalClose() {
    const { dispatch, modalId } = this.props;
    dispatch(modalCloseAC(modalId));
  }

  handleModalOpen() {
    const { dispatch, modalId } = this.props;
    dispatch(modalOpenAC(modalId));
  }

  render() {
    const { linkClassName, linkName, heading, isModalOpen, promptMsg } = this.props;
    const modalBody = (
      <React.Fragment>
        <div className="mb-3">
          {promptMsg}
        </div>
        <TextAreaField
          name="userMessage"
        />
      </React.Fragment>
    );
    const classes = ['imitate-link'];
    if (linkClassName) {
      classes.push(linkClassName);
    }
    return (
      <React.Fragment>
        <span
          className={classes.join(' ')}
          onClick={this.handleModalOpen}
        >
          {linkName}
        </span>
        {isModalOpen && (
          <Form
            onSubmit={this.handleSubmit}
            render={({ handleSubmit }) => (
              <form className="d-inline-block">
                <ModalSimple
                  heading={heading}
                  open={isModalOpen}
                  body={modalBody}
                  onClose={this.handleModalClose}
                  footer={(
                    <div>
                      <TDButton
                        label="Cancel"
                        onClick={this.handleModalClose}
                        variant={BS_STYLE.DEFAULT}
                      />
                      <TDButton
                        label="Send"
                        onClick={handleSubmit}
                        type="submit"
                        variant={BS_STYLE.SUCCESS}
                      />
                    </div>
                  )}
                />
              </form>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

ModalErrorSubmission.propTypes = {
  dispatch: PropTypes.func.isRequired,
  linkName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isModalOpen: PropTypes.bool,
  linkClassName: PropTypes.string,
  modalId: PropTypes.string.isRequired,
  pageView: PropTypes.string.isRequired,
  payload: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  promptMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ModalErrorSubmission.defaultProps = {
  promptMsg: 'Use the form below to share with our team more information about the issues you are facing. We do our best to respond to all individual requests within a day.',
  linkName: 'Send report',
  isModalOpen: false,
  linkClassName: '',
  heading: (
    <div>
      <span className={`${ICON.WARNING_CIRCLE} mr-3`} />
      <span>Report an issue</span>
    </div>
  ),
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state, props) => {
  const { modalId } = props;
  return {
    modalId,
    isModalOpen: getIsModalOpen(state, modalId),
  };
};

const ModalErrorSubmissionConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalErrorSubmission);

export default ModalErrorSubmissionConnect;
