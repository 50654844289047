import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FileUploader from 'core/assets/js/components/FileUploader.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { uploaderProjectTasksPath } from 'core/urls';
import { ICON } from 'core/assets/js/constants';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { uploadDeliverablesDS } from 'projects/assets/js/data-services/tasks';

class UploadDeliverables extends React.Component {
  constructor(props) {
    super(props);

    this.handleDeliverablesUploaded = this.handleDeliverablesUploaded.bind(this);
  }

  static renderButton({ onPick }) {
    return (
      <TDButton
        floatRight
        type="button"
        onClick={onPick}
        label={(
          <React.Fragment>
            Upload
            <i className={`ml-4 ${ICON.ADD_CIRCLE}`} />
          </React.Fragment>
        )}
      />
    );
  }

  getFilesInput() {
    return {
      name: 'task_deliverables',
      onChange: this.handleDeliverablesUploaded,
    };
  }

  async handleDeliverablesUploaded(files) {
    const {
      onDeliverablesUploaded,
      match: { params: { orgAlias, id: projectId, taskId } },
    } = this.props;

    const deliverables = await uploadDeliverablesDS({ orgAlias, projectId, taskId, files });

    await onDeliverablesUploaded(deliverables);
  }

  render() {
    const { match: { params: { id: projectId } }, activeOrg: { id: orgId } } = this.props;
    const uploaderPath = uploaderProjectTasksPath(orgId, projectId);

    return (
      <FileUploader
        wrapperClassName="d-flex"
        uploadRenderer={UploadDeliverables.renderButton}
        reduxInput={this.getFilesInput()}
        path={uploaderPath}
        maxFiles={99999}
        preview={false}
        resetAfterUpload
        acceptAll
      />
    );
  }
}

UploadDeliverables.propTypes = {
  match: routerMatchSpec.isRequired,
  onDeliverablesUploaded: PropTypes.func,
  activeOrg: orgSpec.isRequired,
};

UploadDeliverables.defaultProps = {
  onDeliverablesUploaded: () => {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const UploadDeliverablesConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadDeliverables);

export default withRouter(UploadDeliverablesConnected);
