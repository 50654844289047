import {
  ORGANIZATION_LIST_FETCH,
  ORGANIZATION_LIST_IS_LOADING,
  ORGANIZATION_SELECTED,
  ORGANIZATION_UPDATED,
  USERCARD_UPDATED,
  PROGRESS_LOAD_SUCCESS,
} from 'organizations/assets/js/constants';
import { orgListFetchAC } from 'organizations/assets/js/actions/list';
import { checkPermissionOnUserCard } from 'organizations/assets/js/lib/utils';

import { uniq } from 'lodash';

const USER_CARD_LIST_INITIAL_STATE = {
  isLoading: false,
  items: [],
};

const ORG_INITIAL_STATE = {
  id: null,
  name: null,
  alias: null,
  logo: null,
  userId: null,
};

const ROLE_INITIAL_STATE = {
  isManager: null,
  isProvider: null,
  isOrgCreator: null,
  isFinCon: null,
};

const USER_CARD_INITIAL_STATE = {
  organization: ORG_INITIAL_STATE,
  userRole: ROLE_INITIAL_STATE,
  user: {
    id: null,
  },
  currency: '',
};


export const selectUserCards = (state) => {
  if (state.organizations.list && state.organizations.list.items) {
    return state.organizations.list.items;
  }
  return [];
};

export const selectOrgCurrencies = (state) => {
  if (state.organizations.list && state.organizations.list.items) {
    return state.organizations.list.items.map(i => i.organization.currency);
  }
  return [];
};

export const selectActiveUserCard = (state) => {
  if (state.organizations && state.organizations.active) {
    return state.organizations.active;
  }
  return USER_CARD_INITIAL_STATE;
};

export const getActiveUserCardPermissionChecker = state => permission => {
  const uc = selectActiveUserCard(state);
  return checkPermissionOnUserCard(uc, permission);
};

export const selectActiveOrg = (state) => {
  const uc = selectActiveUserCard(state);
  return uc.organization;
};

export const selectActiveUserType = (state) => {
  const uc = selectActiveUserCard(state);
  return uc.userRole?.ofType || null;
};

export const selectDistinctOrgPaymentMethods = (state) => {
  const userCards = selectUserCards(state);
  return uniq(userCards.map(uc => uc.organization.payment_methods).flat());
};

const updateProgress = (org, progresses) => {
  const progress = progresses.find(p => (
    p.organization.alias === org.alias && p.user.id === org.userId
  ));
  if (!progress) {
    return org;
  }
  const newOrg = {
    ...org,
    status: {
      code: progress.status,
      label: progress.statusLabel,
      hasCompletedOnboarding: progress.completed,
    },
  };
  return newOrg;
};

// Organization list reducer
const orgListReducer = (state = USER_CARD_LIST_INITIAL_STATE, action) => {
  switch (action.type) {
    case ORGANIZATION_LIST_FETCH:
      return {
        ...state,
        isLoading: false,
        items: action.organizations.map(o => o.userCard),
      };
    case ORGANIZATION_LIST_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ORGANIZATION_UPDATED:
      return {
        ...state,
        items: state.items.map((it) => {
          if (action.organization.id !== it.organization.id) {
            return it;
          }
          return {
            ...it,
            organization: { ...it.organization, ...action.organization },
          };
        }),
      };
    case USERCARD_UPDATED:
      return {
        ...state,
        items: state.items.map((it) => {
          if (action.userCard.id !== it.id) {
            return it;
          }
          return {
            ...it,
            ...action.userCard,
            organization: { ...it.organization, ...action.userCard.organization },
          };
        }),
      };
    case PROGRESS_LOAD_SUCCESS:
      return {
        ...state,
        items: state.items.map(org => updateProgress(org, action.progresses)),
      };
    default:
      return state;
  }
};

// Active organization reducer
const activeOrgReducer = (state = USER_CARD_INITIAL_STATE, action, list) => {
  let selectedOrg;
  switch (action.type) {
    case ORGANIZATION_LIST_FETCH:
      selectedOrg = action.organizations.find(uc => (
        state.organization.alias === uc.alias
      ));
      if (!selectedOrg) {
        return USER_CARD_INITIAL_STATE;
      }
      return { ...selectedOrg.userCard } || USER_CARD_INITIAL_STATE;
    case ORGANIZATION_SELECTED: {
      if (!action.orgAlias) {
        return { ...USER_CARD_INITIAL_STATE };
      }
      selectedOrg = list.items.find(uc => (
        action.orgAlias === uc.organization.alias
      ));
      return selectedOrg ? { ...selectedOrg } : state;
    }
    case ORGANIZATION_UPDATED:
      return state.organization.id === action.organization.id ? {
        ...state, organization: action.organization,
      } : state;
    case USERCARD_UPDATED:
      return state.id === action.userCard.id ? {
        ...state,
        ...action.userCard,
        organization: { ...state.organization, ...action.userCard.organization },
      } : state;
    case PROGRESS_LOAD_SUCCESS:
      return updateProgress(state, action.progresses);
    default:
      return state;
  }
};

// Combined reducer
const INITIAL_STATE = {
  list: USER_CARD_LIST_INITIAL_STATE,
  active: USER_CARD_INITIAL_STATE,
  siteWideBannersCount: 0,
};

const organizations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORGANIZATION_LIST_FETCH:
    case ORGANIZATION_LIST_IS_LOADING:
      return {
        ...state,
        list: orgListReducer(state.list, action),
        active: activeOrgReducer(state.active, action, state.list),
      };
    case ORGANIZATION_SELECTED:
      return {
        ...state,
        active: activeOrgReducer(state.active, action, state.list),
      };
    case USERCARD_UPDATED:
    case ORGANIZATION_UPDATED:
      return {
        ...state,
        active: activeOrgReducer(state.active, action),
        list: orgListReducer(state.list, action),
      };
    case PROGRESS_LOAD_SUCCESS:
      return {
        ...state,
        active: activeOrgReducer(state.active, action),
        list: orgListReducer(state.list, action),
      };
    default:
      return state;
  }
};

export const getOrganizationsInitialState = (...args) => organizations(
  INITIAL_STATE,
  orgListFetchAC(...args),
);

export default organizations;
