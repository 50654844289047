import { FORM_ERROR } from 'final-form';
import { omit, pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { privacyUrl } from 'core/urls';
import { completeStepDS, selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { getPhoneDefaultCountry } from 'accounts/assets/js/lib/helpers';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { orgGetStartedUrl } from 'accounts/urls';
import DatePickerSimpleField from 'core/assets/js/components/FinalFormFields/DatePickerSimpleField.jsx';
import PhoneNumberField from 'core/assets/js/components/FinalFormFields/PhoneNumberField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { API_DATE_FORMAT, BS_STYLE, GENDERS, TITLES } from 'core/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { countryOptions } from 'core/assets/js/lib/isoCountries';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { formatDate, getDatetime } from 'core/assets/js/lib/utils';
import {
  SMART_SEARCH_DOCUMENT_TYPE_LABEL, SMART_SEARCH_DOCUMENT_TYPE_VALUES,
} from 'organizations/assets/js/constants';
import { getLatestAMLCheckApiUrl, submitAMLCheckApiUrl } from 'organizations/urls';

const TITLES_OPTIONS = TITLES.map(value => ({ text: value, value }));
const GENDER_OPTIONS = GENDERS.map(value => ({ text: value, value }));
const DOCUMENT_OPTIONS = SMART_SEARCH_DOCUMENT_TYPE_VALUES.map(value => ({
  text: SMART_SEARCH_DOCUMENT_TYPE_LABEL[value], value,
}));

const AMLCheckView = ({ history, match: { params: { orgAlias }, url } }) => {
  const [loadingAMLCheck, setLoadingAMLCheck] = useState(false);
  const [amlCheck, setAMLCheck] = useState(null);

  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const steps = useSelector(selectSetupSteps);

  useEffect(() => {
    setLoadingAMLCheck(true);
    axios
      .get(getLatestAMLCheckApiUrl(orgAlias))
      .then(({ data }) => {
        setAMLCheck(data);
      })
      .catch(error => {
        toastr.error('Oh Snap!', error.errors?._error || error.message);
      })
      .finally(() => {
        setLoadingAMLCheck(false);
      });
  }, [orgAlias]);

  if (loadingAMLCheck) {
    return null;
  }

  const getStartedUrl = orgGetStartedUrl(orgAlias);

  if (
    amlCheck?.id || steps?.userCardSetupSteps?.[orgAlias]?.some(step => (
      step.name === 'ClassificationQuestionnaireStep' && !step.completed
    ))
  ) {
    return <RedirectRoute from={url} status={302} to={getStartedUrl} />;
  }

  const countryCode = profile?.address_components?.country_code;

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          {
            title: 'Complete your identity check',
            url: null,
          },
        ]}
        backUrl={getStartedUrl}
      />
      <div className="page page--aml-check row">
        <div className="container">
          <Form
            onSubmit={async values => {
              try {
                await axios.post(submitAMLCheckApiUrl(orgAlias), values);
                await completeStepDS(dispatch, orgAlias, 'AMLStep', { history });
                return null;
              } catch (err) {
                if (
                  err.response?.data
                  && Object.keys(omit(err.response.data, '_error', '_meta')).length > 0
                ) {
                  return err.response.data;
                }
                return { [FORM_ERROR]: err.response?.data?._error || err.message };
              }
            }}
            initialValues={{
              ...pick(profile, 'dateOfBirth', 'firstName', 'lastName', 'phone'),
              country: countryCode,
              issuingCountry: countryCode,
              postCode: profile?.address_components?.postal_code,
              town: profile?.address_components?.city,
            }}
            render={({ handleSubmit, submitError, submitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="rounded shadow-sm p-4 bg-white">
                  <h2>Personal details</h2>
                  <TDSystemMessage
                    className="mb-5"
                    title="Information about the identity check"
                  >
                    <p>
                      Before you can get started using the platform we need to verify your
                      identity. The identity verification will be performed by our partner
                      SmartSearch, as such the data that you supply on this form will be processed
                      by SmartSearch.
                    </p>
                    <p>
                      You will receive an email from SmartSearch that contains a link which will
                      launch a service where you can upload your identity document (passport or
                      driving licence).
                    </p>
                    <p>
                      <a
                        href={privacyUrl('cookies')}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Please see our privacy policy
                      </a>
                      .
                    </p>
                  </TDSystemMessage>
                  <SelectField
                    name="title"
                    optionsMapping={TITLES_OPTIONS}
                    label="Title"
                    required
                  />
                  <TextInputField
                    disabled={submitting}
                    label="First name"
                    name="firstName"
                    required
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Last name"
                    name="lastName"
                    required
                  />
                  <PhoneNumberField
                    defaultCountry={getPhoneDefaultCountry(profile)}
                    disabled={submitting}
                    label="Phone number"
                    name="phone"
                    required
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Flat"
                    name="flat"
                    sublabel="Flat or apartment name/number"
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Building"
                    name="building"
                    required
                    sublabel="Building name or number"
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Street line 1"
                    name="street1"
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Street line 2"
                    name="street2"
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Town/city"
                    name="town"
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Region"
                    name="region"
                  />
                  <TextInputField
                    disabled={submitting}
                    label="Postcode/zipcode"
                    name="postCode"
                    required
                  />
                  <SelectField
                    name="country"
                    optionsMapping={countryOptions}
                    label="Country"
                    required
                  />
                  <SelectField
                    name="issuingCountry"
                    optionsMapping={countryOptions}
                    label="Document issuing country"
                    required
                  />
                  <DatePickerSimpleField
                    disableAfterDate={formatDate(getDatetime(), API_DATE_FORMAT)}
                    disabled={submitting}
                    label="Date of birth"
                    name="dateOfBirth"
                    required
                  />
                  <SelectField
                    name="gender"
                    optionsMapping={GENDER_OPTIONS}
                    label="Gender"
                    required
                  />
                  <SelectField
                    name="documentType"
                    optionsMapping={DOCUMENT_OPTIONS}
                    label="Document type"
                    required
                  />
                  {submitError && <span className="text-danger mt-5">{submitError}</span>}
                </div>
                <div className="mt-5 d-flex justify-content-end">
                  <TDButton
                    disabled={submitting}
                    label="Cancel"
                    onClick={() => history.push(getStartedUrl)}
                  />
                  <TDButton
                    disabled={submitting}
                    label="Save & continue"
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

AMLCheckView.GetComponentName = () => 'AMLCheckView';

AMLCheckView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(AMLCheckView);
