import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { capitalize } from 'lodash';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { orgPeopleProvidersUrl, orgPeopleManagersUrl } from 'people/urls';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import { PEOPLE_TYPE } from 'people/assets/js/constants';

const InvitePeopleViewHeader = ({ peopleType, orgAlias }) => {
  const isProviderInvite = peopleType === PEOPLE_TYPE.PROVIDERS;
  const returnUrl = (isProviderInvite ? orgPeopleProvidersUrl : orgPeopleManagersUrl)(orgAlias);

  const breadcrumbs = [
    {
      title: capitalize(peopleType),
      url: returnUrl,
    },
    {
      title: 'Invite',
      url: null,
    },
  ];

  return (
    <ContentHeader breadcrumbs={breadcrumbs} />
  );
};

InvitePeopleViewHeader.propTypes = {
  orgAlias: PropTypes.string,
  peopleType: peopleTypeSpec.isRequired,
};

InvitePeopleViewHeader.defaultProps = {
  orgAlias: null,
};

const mapStateToProps = (state, props) => ({
  orgAlias: props.match.params?.orgAlias,
  peopleType: props.match.params?.peopleType,
});

const InvitePeopleViewHeaderConnect = connect(
  mapStateToProps,
)(InvitePeopleViewHeader);

export default withRouter(InvitePeopleViewHeaderConnect);
