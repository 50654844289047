import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stringify, parse } from 'query-string';

import { DATE_DURATION, DATE_DURATION_API_KEY } from 'core/assets/js/constants';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { stackedWorksheetAnalyticsApiUrl } from 'analytics/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ChartStacked from 'projects/assets/js/components/charts/ChartStacked.jsx';
import ProjectChartStackedSkeleton from 'projects/assets/js/components/skeletons/ProjectChartStackedSkeleton.jsx';

class ProjectWorksheetsChartStacked extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, selectedDateDuration, querystring }) {
    const storeKey = ProjectWorksheetsChartStacked.GetStoreKey(selectedDateDuration);
    return dispatch(fetchListDS({
      url: stackedWorksheetAnalyticsApiUrl(params.orgAlias, params.id, url),
      id: params.id,
      componentName: storeKey,
      authedAxios,
      querystring: stringify({
        ...parse(querystring),
        dateDuration: selectedDateDuration,
      }),
    }));
  }

  static GetStoreKey(selectedDateDuration) {
    const dateDurationKey = DATE_DURATION_API_KEY[selectedDateDuration];
    return `stackedWsAnalytics_${dateDurationKey}`;
  }

  render() {
    const { analytics, currencySymbol, selectedDateDuration } = this.props;

    const filteredAnalytics = analytics
      .filter(pm => pm.worksheetsApprovedAmount || pm.worksheetsRaisedAmount);
    const pmWithWorksheets = filteredAnalytics
      .map(pm => ({ name: pm.firstName, avatar: pm.avatar }));

    return (
      <TDApiConnected
        duck="list"
        storeKey={ProjectWorksheetsChartStacked.GetStoreKey(selectedDateDuration)}
        fetchData={({ ...args }) => (
          ProjectWorksheetsChartStacked.FetchData({ ...args, selectedDateDuration },
          ))}
        blockingLoading
        skeletonComponent={ProjectChartStackedSkeleton}
      >
        <ChartStacked
          currencySymbol={currencySymbol}
          analytics={filteredAnalytics}
          emptyText="No worksheets found"
          projectMembers={pmWithWorksheets}
          type={SERVICE_ORDER_TYPE.WORKSHEET}
        />
      </TDApiConnected>
    );
  }
}

ProjectWorksheetsChartStacked.propTypes = {
  analytics: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  selectedDateDuration: PropTypes.number,
};

ProjectWorksheetsChartStacked.defaultProps = {
  selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
};

const mapStateToProps = (state, props) => {
  const { selectedDateDuration } = props;
  const listState = getListState(
    state, ProjectWorksheetsChartStacked.GetStoreKey(selectedDateDuration),
  );
  return {
    analytics: listState.items,
  };
};

const ProjectTabWorksheetsChartStackedConnected = connect(
  mapStateToProps,
  null,
)(ProjectWorksheetsChartStacked);

export default ProjectTabWorksheetsChartStackedConnected;
