import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, keyBy } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { pluralize } from 'core/assets/js/lib/utils';
import { withRouter } from 'react-router-dom';

import { userCardsApiUrl } from 'organizations/urls';
import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import { BS_STYLE, IMG_SIZE, ICON } from 'core/assets/js/constants';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import {
  settingsBankAccountApiUrl, settingsBankAccountsApiUrl, settingsPaymentsSubPageUrl,
} from 'settings/urls';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { PAYMENT_METHOD_DETAILS, SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { updateBankAccountOrganizationsDS } from 'settings/assets/js/data-services/settings';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import { fetchListDS, getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const ListItem = ({ item: userCard, selectedItems }) => {
  if (isEmpty(userCard.organization)) {
    return null;
  }
  const { organization } = userCard;
  const hasBankAccount = !!userCard.bankAccountId && !!userCard.bankAccount;
  return (
    <div className="d-flex w-100 align-items-center">
      <OrganizationLogo
        className="organization-list-item__logo"
        url={organization.logo}
        orgName={organization.name}
        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
      />
      <div>
        <h3>{organization.name}</h3>
        { hasBankAccount && (
          <div className="p-0">
            <div className="hint">
              {`Using "${userCard?.bankAccount?.alias}" payment method`}
            </div>
            {selectedItems.some(so => organization.id === so.organization.id) && (
              <div className="text-warning mt-2">
                <span className={`${ICON.WARNING} mr-1`} />
                By selecting this, you will replace the currently used payment
                method in this organisation.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ListItem.propTypes = {
  item: userCardSpec.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const COMPONENT_NAME = 'PaymentMethodOrgListSelection';
const BANK_ACCOUNTS_EXTRAS_KEY = 'bankAccounts';

const PaymentMethodOrgListSelection = ({
  activeOrg, dispatch, history, selectionBankAccount,
  match: { params: { paymentMethodType, bankAccountId } },
  userCards, bankAccounts,
}) => {
  const [selectedUcs, handleSelection] = useState([]);
  const bankAccountsPerId = keyBy(bankAccounts, 'id');
  const selectedPaymentMethodDetails = Object.values(PAYMENT_METHOD_DETAILS).find(pm => (
    pm.alias === paymentMethodType
  ));

  // we will not show organizations that do not support the selected payment's method type
  const ucFilterSelectedPaymentMethod = userCards.filter(uc => (
    uc.organization.payment_methods.includes(selectedPaymentMethodDetails.methodId)
  ));
  const ucFilteredWithBankAccounts = ucFilterSelectedPaymentMethod.map((uc) => {
    if (uc.bankAccountId) {
      return Object.assign(uc, { bankAccount: bankAccountsPerId[uc.bankAccountId] });
    }
    return uc;
  });

  const breadcrumbs = [
    {
      title: 'Payments',
      url: settingsPaymentsSubPageUrl(activeOrg.alias),
    }, {
      title: 'Payment methods',
      url: settingsPaymentsSubPageUrl(activeOrg.alias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS),
    }, {
      title: (
        <div>
          {`Select ${pluralize('organisation', userCards.length)} for`}
          {' '}
          {selectionBankAccount.alias
            ? (
              <span>
                &apos;
                {selectionBankAccount.alias}
                &apos;
              </span>
            )
            : <RectangleSkeleton width="183" height="25" />}
        </div>
      ),
      url: null,
    },
  ];

  function handleOrgSelection(selectedUc) {
    const ucWasAlreadyAdded = selectedUcs.find(({ id }) => id === selectedUc.id);
    if (!isEmpty(ucWasAlreadyAdded)) {
      // Removing item - item was already in the selection list
      const updatedUcs = selectedUcs.filter(({ id }) => id !== selectedUc.id);
      handleSelection(updatedUcs);
    } else {
      // Adding item - item was not in the selection list
      handleSelection(prevSelectedUcs => [...prevSelectedUcs, selectedUc]);
    }
  }

  function handleSubmit() {
    const selectedOrgIds = selectedUcs.map(uc => uc.organization.id);
    return dispatch(
      updateBankAccountOrganizationsDS({
        values: { selectedOrgIds },
        bankAccountId,
      }),
    ).then(() => {
      toastr.success('Well Done!', 'Payment method settings updated successfully.');
      history.push(settingsPaymentsSubPageUrl(
        activeOrg.alias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
      ));
    });
  }

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />
      <div className="page page--settings payment-method-org-select">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <TDSystemMessage
                type="info"
                title="Information message"
              >
                <React.Fragment>
                  <p>
                    If you don&apos;t want to take this decision now, you can click
                    {' '}
                    <strong>Skip</strong>
                    {' '}
                    and choose your preferred payment method for an organisation
                    via &apos;Organisation settings &gt; Payments &gt; Payment methods&apos;.
                  </p>
                  <p>
                    You can receive regular payments via this payment method in any of the
                    organisations your are a member of. Just select any of the following
                    organisations and click
                    {' '}
                    <strong>Save</strong>
                    {' '}
                    to use this payment method as your preferred one in the selected organisations.
                  </p>
                </React.Fragment>
              </TDSystemMessage>
            </div>
          </div>
          <TDApiConnected
            duck="view"
            storeKey={COMPONENT_NAME}
            skeletonComponent={SettingsPageSkeleton}
            fetchData={({
              authedAxios, componentName, url,
            }) => (
              Promise.all([
                dispatch(fetchViewDS({
                  authedAxios, componentName,
                  url: settingsBankAccountApiUrl(bankAccountId, url),
                })),
                dispatch(fetchListDS({
                  url: userCardsApiUrl(), componentName, authedAxios,
                })),
                dispatch(fetchListDS({
                  url: settingsBankAccountsApiUrl(), componentName, authedAxios,
                  extrasKey: BANK_ACCOUNTS_EXTRAS_KEY,
                })),
              ])
            )}
          >
            <CheckableList
              multipleSelection
              items={ucFilteredWithBankAccounts}
              itemComponentProps={{ selectedItems: selectedUcs }}
              itemComponent={ListItem}
              onItemChecked={handleOrgSelection}
              emptyItemsMessage="This payment method is not activated in the organisations you are a member of."
            />
          </TDApiConnected>
          <div className="button-container mt-4 text-right">
            <TDButton
              data-testid="payment-method-org-list-selection-back"
              label="Back"
              onClick={() => history.goBack()}
              type="button"
              variant={BS_STYLE.LINK}
            />
            <TDButton
              data-testid="payment-method-org-list-selection-skip"
              label="Skip"
              onClick={() => history.push(
                settingsPaymentsSubPageUrl(activeOrg.alias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS),
              )}
              variant={BS_STYLE.DEFAULT}
            />
            <TDButton
              data-testid="payment-method-org-list-selection-save"
              disabled={selectedUcs.length === 0}
              label="Save"
              onClick={handleSubmit}
              variant={BS_STYLE.PRIMARY}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PaymentMethodOrgListSelection.propTypes = {
  activeOrg: orgSpec.isRequired,
  bankAccounts: PropTypes.arrayOf(bankAccountSpec),
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  selectionBankAccount: userCardSpec,
  match: routerMatchContentsSpec.isRequired,
  userCards: PropTypes.arrayOf(userCardSpec),
};

PaymentMethodOrgListSelection.defaultProps = {
  userCards: [],
  bankAccounts: [],
  selectionBankAccount: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  userCards: getListState(state, COMPONENT_NAME).items,
  selectionBankAccount: getViewState(state, COMPONENT_NAME).item,
  bankAccounts: getListStateExtras(state, COMPONENT_NAME, BANK_ACCOUNTS_EXTRAS_KEY),
});

const PaymentMethodOrgListSelectionConnected = connect(
  mapStateToProps,
)(PaymentMethodOrgListSelection);

export default withRouter(PaymentMethodOrgListSelectionConnected);
