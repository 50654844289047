import React from 'react';
import { withRouter } from 'react-router-dom';

import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { contactsUrl } from 'contacts/urls';
import ContactsImportStep from 'contacts/assets/js/components/ContactsImportStep.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';

function ContactsImportView(props) {
  const { match: { params: { orgAlias, step } }, history } = props;
  const breadcrumbs = [
    {
      title: 'Contacts',
      url: contactsUrl(orgAlias),
    },
    {
      title: 'Import from CSV',
      url: null,
    },
  ];
  return (
    <div>
      <ContentHeader
        breadcrumbs={breadcrumbs}
      />
      <div className="page page--contacts">
        <div className="container">
          <ContactsImportStep
            orgAlias={orgAlias}
            step={step}
            history={history}
          />
        </div>
      </div>
    </div>
  );
}

ContactsImportView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  history: routerHistorySpec.isRequired,
};

export default withRouter(ContactsImportView);
