import React from 'react';
import PropTypes from 'prop-types';

import TDRating from 'core/assets/js/components/TDRating.jsx';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';

class RatingField extends React.Component {
  constructor(props) {
    super(props);
    const { initialRate } = props;
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: initialRate,
    };
  }

  handleChange(value) {
    const { input } = this.props;
    this.setState({ value }, () => {
      // Notify redux form field about the change to update the value of the hidden field
      input.onChange(value);
    });
  }

  render() {
    const {
      input, label, rest, size,
      meta: { touched, error },
    } = this.props;
    const { value } = this.state;
    const hasError = touched && error;
    const groupClassName = ['form-group form-group--rating'];

    if (hasError) {
      groupClassName.push('has-error');
    }

    return (
      <div className={groupClassName.join(' ')}>
        <label htmlFor={input.name}>{label}</label>
        <TDRating
          onClick={(rating) => {
            // Reset filter if same star clicked twice
            this.handleChange(rating === value ? null : rating);
          }}
          size={size}
          {...input}
          {...rest}
          initialRating={value}
        />

        {hasError && <span className="help-block">{error}</span>}
      </div>
    );
  }
}

RatingField.propTypes = {
  input: reduxInputSpec.isRequired,
  label: PropTypes.string,
  initialRate: PropTypes.number,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  rest: PropTypes.object,
  size: PropTypes.string,
};
RatingField.defaultProps = {
  meta: {
    touched: false,
    error: false,
  },
  label: '',
  rest: {},
  size: '',
  initialRate: null,
};

export default RatingField;
