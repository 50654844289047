import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BS_STYLE, ICON } from 'core/assets/js/constants';

import { invoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import {
  INVOICE_STATUS_LABEL,
  REPORT_TYPES,
} from 'finance/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import { fetchFinanceInvoiceDS } from 'finance/assets/js/data-services/view';
import {
  financeInvoiceMarkPaidDS,
  financeInvoiceChangeNumberDS,
} from 'finance/assets/js/data-services/form';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import Invoice from 'finance/assets/js/components/Invoice.jsx';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';
import ReasonForm from 'core/assets/js/components/ReasonForm.jsx';
import InvoiceNumberForm from 'finance/assets/js/components/InvoiceNumberForm.jsx';


class InvoiceView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchFinanceInvoiceDS({
        orgAlias: params.orgAlias, id: params.id, url, componentName, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'InvoiceView';
  }

  render() {
    const { dispatch, report, match } = this.props;
    const statusLabel = INVOICE_STATUS_LABEL[report.status];
    const { orgAlias } = match.params;

    const MarkPaidForm = withModalForm({
      heading: 'Mark invoice as paid',
      confirmLabel: 'Mark as paid',
      confirmStyle: BS_STYLE.SUCCESS,
    })(ReasonForm);

    const canMarkPaidInvoice = get(report, 'allowedActions.canMarkAsPaid');
    const markPaidModalData = !canMarkPaidInvoice ? null : {
      key: 'invoiceMarkPaidForm',
      btnLabel: 'Mark as paid',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      Form: (
        <MarkPaidForm
          onSubmit={() => dispatch(financeInvoiceMarkPaidDS({
            componentName: InvoiceView.GetComponentName(),
            documentId: report.id,
            orgAlias,
          }))}
          hasReason={false}
          question="Are you sure you want to mark this invoice as paid?"
        />
      ),
    };

    const canChangeInvoiceNumber = get(report, 'allowedActions.canChangeNumber');

    const changeNumberModalData = !canChangeInvoiceNumber ? null : {
      key: 'invoiceChangeNumberForm',
      btnLabel: 'Change number',
      btnIcon: ICON.EDIT,
      btnVariant: BS_STYLE.PRIMARY,
      Form: (
        <InvoiceNumberForm
          initialValues={{ number: get(report, 'number') }}
          onSubmit={values => dispatch(financeInvoiceChangeNumberDS({
            componentName: InvoiceView.GetComponentName(),
            documentId: report.id,
            orgAlias: match.params.orgAlias,
            data: values,
          }))}
          orgAlias={orgAlias}
        />
      ),
    };

    return (
      <ReportViewLayout
        actions={[changeNumberModalData, markPaidModalData]}
        heading={report.number}
        reportType={REPORT_TYPES.INVOICE}
        status={statusLabel}
        report={report}
      >
        <TDApiConnected duck="view" component={this.constructor} blockingLoading>
          <Invoice
            invoice={report}
            ownerFE={report.ownerFE}
            systemFE={report.systemFE}
            invoicingSettings={report.invoicingSettings}
            recipientFE={report.recipientFE}
            firstBillable={report.firstBillable}
            lastBillable={report.lastBillable}
            attachments={report.attachments}
          />
        </TDApiConnected>
      </ReportViewLayout>
    );
  }
}

InvoiceView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  report: invoiceSpec,
  match: PropTypes.object.isRequired,
};

InvoiceView.defaultProps = {
  report: {},
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, InvoiceView.GetComponentName());
  return {
    report: viewState.item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const InvoiceViewConnect = connect(mapStateToProps, mapDispatchToProps)(InvoiceView);

export default withRouter(InvoiceViewConnect);
