import React from 'react';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import OrgSettingsFinanceTab from 'settings/assets/js/components/OrgSettingsFinanceTab.jsx';
import OrgSettingsFinanceTabs from 'settings/assets/js/components/OrgSettingsFinanceTabs.jsx';

const OrgSettingsFinanceView = (props) => {
  const { match } = props;
  const { orgAlias, tab } = match.params;
  const breadcrumbs = [
    {
      title: 'Payments',
      url: null,
    },
  ];

  return (
    <React.Fragment>
      <ContentHeader
        breadcrumbs={breadcrumbs}
      >
        <OrgSettingsFinanceTabs
          tab={tab}
          orgAlias={orgAlias}
        />
      </ContentHeader>
      <div className="page page--settings payments">
        <div className="container">
          <OrgSettingsFinanceTab
            tab={tab}
            orgAlias={orgAlias}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

OrgSettingsFinanceView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(OrgSettingsFinanceView);
