import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { skillDirectoriesListApiUrl } from 'settings/urls';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import FilteringDropDown from 'core/assets/js/components/FilteringDropDown.jsx';

class SkillFilterForm extends React.Component {
  static GetComponentName() {
    return 'SkillFilterForm';
  }

  render() {
    const { skillDirectoryOptions, onFiltersChanged } = this.props;
    const orgHasSkillDirectories = skillDirectoryOptions.length > 0;
    return (
      orgHasSkillDirectories && (
        <FilteringDropDown
          onFilterChange={onFiltersChanged}
          filteringOptions={skillDirectoryOptions}
        />
      )
    );
  }
}

SkillFilterForm.propTypes = {
  onFiltersChanged: PropTypes.func,
  skillDirectoryOptions: PropTypes.array.isRequired,
};

SkillFilterForm.defaultProps = {
  onFiltersChanged: null,
};

const SkillFilterFormWithTDApi = withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, querystring,
    props: { selectionFilter },
  }) => {
    const { orgAlias } = selectionFilter;
    return dispatch(fetchListDS({
      authedAxios,
      componentName: SkillFilterForm.GetComponentName(),
      querystring,
      url: skillDirectoriesListApiUrl(orgAlias),
    }));
  },
  duck: 'list',
  storeKey: 'SkillFilterForm',
})(SkillFilterForm);

const mapStateToProps = (state) => {
  const skillDirectoryListState = getListState(state, SkillFilterForm.GetComponentName());
  const skillDirectoryOptions = skillDirectoryListState?.items.map(({
    id, name,
  }) => ({ value: id, text: name }));
  return {
    skillDirectoryOptions,
    activeOrg: selectActiveOrg(state),
  };
};
const SkillFilterFormConnect = connect(
  mapStateToProps,
)(SkillFilterFormWithTDApi);

export default SkillFilterFormConnect;
