import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { ICON, BS_STYLE, BS_SIZE, BS_TOOLTIP_PLACEMENT } from 'core/assets/js/constants';
import { modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import LanguageSelectModalPanel, { MODAL_ID as LANGUAGES_MODAL_ID } from 'core/assets/js/components/LanguageSelectModalPanel.jsx';
import { updateProfileLanguagesDS } from 'accounts/assets/js/data-services/profiles';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';

const DELETE_LANGUAGE_MODAL_ID = 'delete-language-modal';

const ProfileLanguageTab = ({
  profile, dispatch, isRemoveConfirmationModalOpen, removeConfirmationModalPayload,
}) => {
  if (!profile) {
    return null;
  }

  const [languages, setLanguages] = useState(profile.languages);

  const handleModalOpen = () => {
    dispatch(modalOpenAC(LANGUAGES_MODAL_ID, { languages }));
  };

  const handleOpenRemoveConfirmationModal = async (language) => {
    dispatch(modalOpenAC(DELETE_LANGUAGE_MODAL_ID, { language }));
  };

  const handleRemoveLanguage = async (language) => {
    const componentName = 'ProfileLanguageTab';
    const newLangs = languages.filter(lng => lng.id !== language.id);
    return dispatch(
      updateProfileLanguagesDS(newLangs, componentName),
    ).then((response) => {
      setLanguages(response.updated);
      dispatch(modalCloseAC(DELETE_LANGUAGE_MODAL_ID));
      toastr.success('Well Done!', 'Language removed successfully.');
    });
  };
  const handleLanguagesSelected = (newLangs) => {
    const componentName = 'ProfileLanguageTab';
    return dispatch(
      updateProfileLanguagesDS(newLangs, componentName),
    ).then(() => {
      setLanguages(newLangs);
      toastr.success('Well Done!', 'Languages updated successfully.');
    });
  };

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <div className="page--settings__content clearfix">
        <h3 className="heading-block">Languages</h3>
        <div className="skill-list p-0 mb-n3">
          {languages && languages.map(lang => (
            <SkillTag
              key={lang.id}
              skill={lang}
              isLanguage
              showRemoveConfirmation
              onRemove={() => handleOpenRemoveConfirmationModal(lang)}
            />
          ))}

          {languages && languages.length === 0 && (
            <p className="text-center pt-4">
              You haven’t added any languages yet.
              <br />
            </p>
          )}

          <TDButton
            tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
            className="mt-4 mb-5"
            bsSize={BS_SIZE.SMALL}
            onClick={handleModalOpen}
            variant={BS_STYLE.PRIMARY}
            btnIcon={ICON.ADD_CIRCLE}
            rounded
            label={(
              <React.Fragment>
                Add language
              </React.Fragment>
            )}
          />

          <LanguageSelectModalPanel
            initiallySelected={languages}
            handleSubmitSelected={handleLanguagesSelected}
          />

          <ModalConfirm
            heading="Remove language"
            confirmLabel="Remove"
            open={isRemoveConfirmationModalOpen}
            closeOnConfirm={false}
            onClose={() => dispatch(modalCloseAC(DELETE_LANGUAGE_MODAL_ID))}
            onConfirm={() => handleRemoveLanguage(removeConfirmationModalPayload.language)}
          >
            Are you sure you want to remove
            {' '}
            <strong>
              { removeConfirmationModalPayload.language
               && removeConfirmationModalPayload.language.label
              }
            </strong>
            ?
          </ModalConfirm>
        </div>
      </div>
    </div>
  );
};

ProfileLanguageTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profile: PropTypes.object,
  isRemoveConfirmationModalOpen: PropTypes.bool,
  removeConfirmationModalPayload: PropTypes.object,
};

ProfileLanguageTab.defaultProps = {
  profile: {},
  isRemoveConfirmationModalOpen: false,
  removeConfirmationModalPayload: {},
};

const mapStateToProps = state => ({
  isRemoveConfirmationModalOpen: getIsModalOpen(state, DELETE_LANGUAGE_MODAL_ID),
  removeConfirmationModalPayload: getModalPayload(state, DELETE_LANGUAGE_MODAL_ID) || {},
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProfileLanguageTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileLanguageTab);

export default ProfileLanguageTabConnected;
