import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { routerMatchSpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { MODAL_ID } from 'integrations/assets/js/components/IntegrationCreateSupplierModal.jsx';
import IntegrationSupplierMappingCard from 'integrations/assets/js/components/IntegrationSupplierMappingCard.jsx';
import {
  fetchSupplierContractorMappingsDS, updateSupplierMappingDS,
} from 'integrations/assets/js/data-services/list';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const IntegrationContractorsMappingSettings = ({
  dispatch,
  integration,
  mappings,
  match: { params: { orgAlias, integrationId } },
  pagination,
}) => {
  const componentName = IntegrationContractorsMappingSettings.GetComponentName();
  return (
    <TDApiConnected
      duck="list"
      fetchData={({ authedAxios, querystring }) => dispatch(fetchSupplierContractorMappingsDS({
        authedAxios, componentName, integrationId, orgAlias, querystring,
      }))}
      skeletonComponent={() => null}
      storeKey={componentName}
    >
      <p>
        <strong>
          Please select from the list the correct item
          one that represents the supplier
        </strong>
      </p>
      {mappings.map(mapping => (
        <div
          className="mx-4 my-2 bg-gray"
          key={`integration-supplier-${mapping.id}`}
        >
          <IntegrationSupplierMappingCard
            dataTestIdSuffix="contractor"
            integrationName={integration.name}
            onCreateSupplier={local => dispatch(modalOpenAC(MODAL_ID, { componentName, local }))}
            onSupplierMapped={async values => {
              try {
                await dispatch(updateSupplierMappingDS({
                  componentName, integrationId, orgAlias, values,
                }));
                toastr.success('Well Done!', 'The supplier mapping has been updated');
              } catch (error) {
                toastr.error('Oh Snap!', error._error || error.message);
              }
            }}
            mapping={mapping}
          />
        </div>
      ))}
      <TDPagination {...pagination} />
    </TDApiConnected>
  );
};

IntegrationContractorsMappingSettings.GetComponentName = () => 'IntegrationContractorsMappingSettings';

IntegrationContractorsMappingSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  integration: integrationSpec.isRequired,
  mappings: PropTypes.arrayOf(PropTypes.object),
  match: routerMatchSpec.isRequired,
  pagination: paginationSpec.isRequired,
};

IntegrationContractorsMappingSettings.defaultProps = {
  mappings: [],
};

const mapStateToProps = (state) => {
  const { items: mappings, pagination } = getListState(
    state, IntegrationContractorsMappingSettings.GetComponentName(),
  );
  return { mappings, pagination };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationContractorsMappingSettingsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationContractorsMappingSettings);

export default withRouter(IntegrationContractorsMappingSettingsConnected);
