import React from 'react';

import TDPagination from 'core/assets/js/components/TDPagination.jsx';

function PaginationSection() {
  return (
    <section id="pagination">
      <h2>Pagination</h2>
      <div className="clearfix">
        <TDPagination
          page={1}
          pages={15}
        />
      </div>
    </section>
  );
}

export default PaginationSection;
