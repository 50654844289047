import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { map, pick } from 'lodash';
import queryString from 'query-string';

import {
  STATUS_LABEL as INVITATION_STATUS_LABEL,
} from 'invitations/assets/js/constants';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import DateRangePickerFilterField from 'core/assets/js/components/FinalFormFilterFields/DateRangePickerFilterField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';
import { ORG_INVITEES_STATUS_DEFAULT_FILTER, PEOPLE_DEFAULT_ORDERING } from 'people/assets/js/constants';

class OrgInviteesSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();
    const filtersList = map(searchSpec.filters, 'paramName');

    let values = queryString.parse(location.search);
    values = pick(values, ['kw', 'ordering'].concat(filtersList));

    return values;
  }

  getSearchSpec() { // eslint-disable-line class-methods-use-this
    // filter by invitation status
    // search using email or invitation id

    // Prepare Status filter options
    const statusOptions = ORG_INVITEES_STATUS_DEFAULT_FILTER
      .map(status => ({
        text: INVITATION_STATUS_LABEL[status],
        value: status,
      }));

    const searchSpec = {
      searchTerm: {
        placeholder: 'Search Invitees',
        paramName: 'kw',
        component: AutoSuggestField,
      },
      defaultOrdering: PEOPLE_DEFAULT_ORDERING,
      filters: [
        {
          fieldComponent: SelectableListFilterField,
          label: 'Status',
          multiple: true,
          options: statusOptions,
          paramName: 'status',
        },
        {
          fieldComponent: DateRangePickerFilterField,
          label: 'Created',
          paramName: 'createdAt',
        },
        {
          paramName: 'interviewId',
          hidden: true,
        },
      ],
    };
    return searchSpec;
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;
    return (
      <SearchFinalForm
        name="org-invitees"
        initialValues={this.getInitialValues()}
        searchSpec={this.getSearchSpec()}
        onFiltersToggle={onFiltersToggle}
        filtersOpen={filtersOpen}
      />
    );
  }
}

OrgInviteesSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(OrgInviteesSearch);
