import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { isBoolean, isEqual, isEmpty, isNil, pick } from 'lodash';
import { hasWebpackHash, webpackHash, isSSR } from 'core/assets/js/config/checks';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectAuthenticated, getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { fetchFromApiThunk, getRequestState, requestPendingAC, requestSucceededAC, requestFailedAC, requestResetAC } from 'core/assets/js/ducks/requests';
import AxiosContext from 'core/assets/js/lib/AxiosContext';
import Logger from 'core/assets/js/lib/Logger';
import ApiLoading from 'core/assets/js/components/ApiLoading.jsx';
import ApiError from 'core/assets/js/components/ApiError.jsx';
import withDuck from 'core/assets/js/components/withDuck.jsx';
import withStoreDescriptor from 'core/assets/js/components/withStoreDescriptor.jsx';


const withApi = (WrappedComponent) => {
  class _WithApi extends React.Component {
    constructor(props) {
      super(props);
      this._fetchPromise = Promise.resolve();

      if (hasWebpackHash) {
        this.hash = webpackHash();
        this.logger = new Logger(`tdapiconnected:${props.descriptor}:api:${webpackHash()}`);
      } else {
        this.logger = new Logger(`tdapiconnected:${props.descriptor}:api`);
      }
      this.logger.log('created');
    }

    componentDidMount() {
      this.logger.log('mounting');
      this.clientFetchData({ isFirstRender: true });
    }

    componentDidUpdate(prevProps) {
      /* KEEP THE FOLLOWING COMMENT FOR DEBUGGING PROPS */
      // this.logger.log('Rrow update diff:');
      // const keysToDiff = ['requestState'];
      // const now = Object.entries(this.props);
      // const added = now.filter(([key, val]) => {
      //   if (prevProps[key] === undefined) return true;
      //   if (prevProps[key] !== val) {
      //     if (keysToDiff.includes(key)) {
      //       this.logger.log(`${key}
      //         - ${JSON.stringify(prevProps[key])}
      //         + ${JSON.stringify(val)}
      //       `);
      //     } else {
      //       this.logger.log(`${key}`);
      //     }
      //   }
      //   return false;
      // });
      // added.forEach(([key, val]) => {
      //   if (keysToDiff.includes(key)) {
      //     this.logger.log(`added ${key} + ${JSON.stringify(val)}`);
      //   } else {
      //     this.logger.log(`added ${key}`);
      //   }
      // });

      const { isAheadOfRedux, requestState: { requestMayRestart } } = this.props;
      if (
        (requestMayRestart || isAheadOfRedux)
      ) {
        this.logger.log('component got update');
        this.clientFetchData(prevProps);
      }
    }

    componentWillUnmount() {
      const { resetRequest, descriptor, persistent, history, activeOrg } = this.props;
      this.logger.log('unmounting');
      if (hasWebpackHash && this.hash !== webpackHash()) {
        // hot reloaded, do not unmount
        this.logger.log('not resetting for', descriptor, 'because of hot reloading');
        return;
      }
      if (!persistent) {
        this.logger.log('resetting for', descriptor);
        // reset the state after the fetchData promise has resolved
        // so that we don't overwrite the state with a request that returned
        // after the component has unmounted
        const orgAlias = activeOrg?.unique_alias;
        this._fetchPromise.then(() => resetRequest(descriptor, orgAlias, history.length));
      }
    }

    componentShouldRefetch(prevProps) {
      const { logger } = this;
      const {
        activeOrg, requestState: { orgAlias, requestHasBeenTriggered }, isAheadOfRedux,
        shouldRefetchOnQueryChange, location, match, query,
      } = this.props;

      const shouldFetch = !requestHasBeenTriggered;
      if (!prevProps) {
        if (shouldFetch) {
          this.logger.log('should fetch because it has no prev props and has not been triggered');
          return true;
        }
        this.logger.log('should not fetch because it has no prev props but has already been triggered');
        return false;
      }

      if (!isEmpty(prevProps) && !isEqual(match, prevProps.match)) {
        this.logger.log(`should fetch because we have changed url ${match} ${prevProps.match}`);
        return true;
      }

      if (!isEmpty(prevProps)) {
        if (!isEqual(location.search, prevProps.location.search)) {
          const oldQuery = queryString.parse(prevProps.location.search);
          const newQuery = queryString.parse(location.search);
          logger.log('changed query');
          if (shouldRefetchOnQueryChange) {
            if (isBoolean(shouldRefetchOnQueryChange)) {
              this.logger.log('should fetch because the query params have changed and we were asked to refetch in this case');
              return true;
            }
            if (shouldRefetchOnQueryChange(oldQuery, newQuery)) {
              // New url queries
              this.logger.log('should fetch because the query params have changed and we were asked to refetch in this case');
              return true;
            }
          }
        }

        if (query && prevProps.query && (!isEqual(prevProps.query, query))) {
          if (shouldRefetchOnQueryChange) {
            if (isBoolean(shouldRefetchOnQueryChange)) {
              this.logger.log('should fetch because the query params have changed and we were asked to refetch in this case');
              return true;
            }

            if (shouldRefetchOnQueryChange(prevProps.query, query)) {
              // New url queries
              this.logger.log('should fetch because the query params have changed and we were asked to refetch in this case');
              return true;
            }
          }
        }
      }

      if (isAheadOfRedux) {
        const activeOrgAlias = activeOrg?.unique_alias;
        const direction = isAheadOfRedux ? `ahead: ${orgAlias}->${activeOrgAlias}` : `behind: ${activeOrgAlias}->${orgAlias}`;
        this.logger.log(`should fetch because it is ahead of redux state (${direction})...`);
        return true;
      }

      if (shouldFetch) {
        this.logger.log('should fetch because it has not been triggered');
      } else {
        this.logger.log('should not fetch because it has already been triggered');
      }
      return shouldFetch;
    }

    printRequestState() {
      const {
        requestState: { initialized, isLoading, hasLoaded, httpErrorCode },
      } = this.props;
      return `initialized: ${initialized}, isLoading: ${isLoading}, hasLoaded: ${hasLoaded}, hasError: ${httpErrorCode}`;
    }

    /**
     * Fetch data from API
     *
     * @returns {Promise.<TResult>}
     */
    clientFetchData({ isFirstRender = false, ...prevProps } = {}) {
      const { logger } = this;
      const authedAxios = this.context;
      const {
        dispatch, location, match, history, descriptor, fetchData,
        startRequest, completeRequest, failRequest,
        hasOrgAccess, isAuthenticated, activeOrg, query,
        requestState,
        ...restProps
      } = this.props;
      const orgAlias = activeOrg?.unique_alias;
      if (!this.componentShouldRefetch(prevProps)) {
        return Promise.resolve();
      }
      const historyLength = isSSR ? null : history.length;
      startRequest(descriptor, orgAlias, historyLength);
      this._fetchPromise = fetchFromApiThunk({
        dispatch,
        hasOrgAccess,
        isAuthenticated,
        activeOrg,
        authedAxios,
        isFirstRender,
        fetchData,
        location,
        match,
        query,
        componentName: descriptor,
        componentProps: restProps,
      }).then(() => {
        logger.log('finished fetching');
        completeRequest(descriptor, orgAlias, historyLength);
      }).catch((err) => {
        const status = err.response && err.response.status
          ? err.response.status
          : 500;
        failRequest(descriptor, status, orgAlias, historyLength);
        logger.error(err);
      });
      return this._fetchPromise;
    }

    render() {
      const {
        className, isAheadOfRedux, requestState, descriptor,
        ...passthroughProps
      } = this.props;
      const { requestHasBeenTriggered, isLoading, httpErrorCode, orgAlias } = requestState;

      this.logger.log(`try rendering (${this.printRequestState()})`);

      if (isAheadOfRedux) {
        const { activeOrg } = this.props;
        const activeOrgAlias = activeOrg?.unique_alias;
        const direction = isAheadOfRedux ? `ahead: ${orgAlias}->${activeOrgAlias}` : `behind: ${activeOrgAlias}->${orgAlias}`;
        this.logger.log(`rendering nothing, our component uses stale redux state (${direction})...`);
        return null;
      }

      if (!requestHasBeenTriggered) {
        this.logger.log('rendering nothing, waiting for store...');
        return null;
      }

      if (httpErrorCode) {
        return (<ApiError httpErrorCode={httpErrorCode} />);
      }

      const loadingProps = pick(this.props, ['blockingLoading', 'loadingEnabled', 'skeletonComponent']);

      if (loadingProps.blockingLoading && isLoading) {
        return <ApiLoading {...loadingProps} />;
      }

      this.logger.log('rendering component');

      return (
        <WrappedComponent
          {...passthroughProps}
          requestState={requestState}
          descriptor={descriptor}
          className={`td_api_connected-loaded ${className}`}
        />
      );
    }
  }

  _WithApi.contextType = AxiosContext;

  _WithApi.propTypes = {
    dispatch: PropTypes.func.isRequired,
    descriptor: PropTypes.string.isRequired,
    fetchData: PropTypes.func.isRequired,
    query: PropTypes.object,
    shouldRefetchOnQueryChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    persistent: PropTypes.bool,
    blockingLoading: PropTypes.bool,
    loadingEnabled: PropTypes.bool,
    skeletonComponent: PropTypes.oneOfType([
      PropTypes.func, PropTypes.element, PropTypes.node,
    ]),
    className: PropTypes.string,
    // router props
    match: routerMatchSpec.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    // auth state
    hasOrgAccess: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    activeOrg: orgSpec.isRequired,
    // request state
    requestState: PropTypes.object.isRequired,
    isAheadOfRedux: PropTypes.bool.isRequired,
    // actions
    resetRequest: PropTypes.func.isRequired,
    startRequest: PropTypes.func.isRequired,
    failRequest: PropTypes.func.isRequired,
    completeRequest: PropTypes.func.isRequired,
  };

  _WithApi.defaultProps = {
    className: '',
    persistent: false,
    shouldRefetchOnQueryChange: () => true,
    blockingLoading: true,
    loadingEnabled: true,
    skeletonComponent: null,
    query: null,
  };

  const mapStateToProps = (state, props) => {
    const requestState = getRequestState(state, props.descriptor);
    const activeOrg = selectActiveOrg(state);
    const { history } = props;
    const { orgAlias, historyLength } = requestState;
    let isAheadOfRedux = false;
    const activeOrgAlias = activeOrg?.unique_alias;
    if (activeOrgAlias && orgAlias && activeOrgAlias !== orgAlias) {
      isAheadOfRedux = isNil(historyLength) || history.length > historyLength;
    }
    return {
      requestState,
      isAheadOfRedux,
      hasOrgAccess: getHasOrgAccess(state),
      isAuthenticated: selectAuthenticated(state),
      activeOrg,
    };
  };

  const mapDispatchToProps = dispatch => ({
    dispatch,
    ...bindActionCreators({
      resetRequest: requestResetAC,
      startRequest: requestPendingAC,
      failRequest: requestFailedAC,
      completeRequest: requestSucceededAC,
    }, dispatch),
  });


  return withRouter(withStoreDescriptor(withDuck(
    connect(mapStateToProps, mapDispatchToProps)(_WithApi),
  )));
};

export default withApi;
