import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import ErrorRoute from 'core/assets/js/config/routes/ErrorRoute.jsx';
import LayoutError from 'core/assets/js/layout/LayoutError.jsx';
import Error50x from 'core/assets/js/components/errors/Error50x.jsx';
import Logger from 'core/assets/js/lib/Logger';


const sentryLogger = new Logger('SENTRY');

/**
 * Capture any REACT errors and capture user feedback via the Sentry modal
 *
 * Used in:
 * - CoreRoute
 */
const ReactErrorView = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(fallbackProps) => {
        const { error, eventId, resetError } = fallbackProps;
        sentryLogger.error(`Event id "${eventId}"`);

        return (
          <React.Fragment>
            <ErrorRoute status={500}>
              <LayoutError component={Error50x} errors={[error]} resetError={resetError} />
            </ErrorRoute>
          </React.Fragment>
        );
      }}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

ReactErrorView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReactErrorView;
