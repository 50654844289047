import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';


const SettingsLayout = (props) => {
  const { footerText, title, children } = props;
  const breadcrumbs = [
    {
      title,
      url: null,
    },
  ];

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />
      <div className="page page--settings">
        <div className="container">
          <div className="rounded shadow-sm p-4 bg-white">
            <div className="page--settings__content clearfix">
              { children }

              {footerText && (
                <div className="card-footer">
                  {footerText }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SettingsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  footerText: PropTypes.string,
  title: PropTypes.string,
};

SettingsLayout.defaultProps = {
  footerText: null,
  title: '',
};

export default withRouter(SettingsLayout);
