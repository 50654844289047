import { FORM_ERROR } from 'final-form';
import { isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import {
  getModalPayload, modalCloseAC, getIsModalOpen, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import {
  getModalView, modalDataViewFetchAC, modalDataViewResetAC,
} from 'core/assets/js/ducks/modalView';
import axios from 'core/assets/js/lib/tdAxios';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { fetchProviderRateAdjustmentsDS } from 'people/assets/js/data-services/rate-adjustments';
import RateAdjustmentFooter from 'rates/assets/js/components/RateAdjustmentFooter.jsx';
import RateAdjustmentModalForm from 'rates/assets/js/components/RateAdjustmentModalForm.jsx';
import RateAdjustmentReviewForm from 'rates/assets/js/components/RateAdjustmentReviewForm.jsx';
import RateAdjustmentTimeline from 'rates/assets/js/components/RateAdjustmentTimeline.jsx';
import UpdateTaskAssignmentRatesModal, { MODAL_ID as UPDATE_TASK_ASSIGNMENTS_RATES_MODAL_ID } from 'rates/assets/js/components/UpdateTaskAssignmentsRatesModal.jsx';
import { RATE_ADJUSTMENT_MODAL_ID, RATE_ADJUSTMENT_SUBMIT_TYPE } from 'rates/assets/js/constants';
import {
  ratesCancelApiUrl,
  ratesEditApiUrl,
  ratesApproveApiUrl,
  ratesDeclineApiUrl,
} from 'rates/urls';

const RateAdjustmentModal = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);
  const isModalOpen = useSelector(state => getIsModalOpen(state, RATE_ADJUSTMENT_MODAL_ID));
  const { isLoading, item: rate } = useSelector(getModalView);
  const {
    hasActiveTasks, rateId,
  } = useSelector(state => getModalPayload(state, RATE_ADJUSTMENT_MODAL_ID)) || {};

  const rateAdjustmentAllowedActions = rate.rateAdjustmentAllowedActions || {};
  const rateUnit = rate.unit;

  const onClose = () => {
    dispatch(modalCloseAC());
    dispatch(modalDataViewResetAC());
    // We need to reset the form reference after the modal is closed, so if it is
    // re-opened, the correct reference is used
    setTimeout(() => {
      formRef.current.reset();
      formRef.current = null;
    }, 500);
  };

  return (
    <>
      <Form
        onSubmit={async ({ submitType, ...values }) => {
          let urlGen = null;
          let successMsg = '';
          switch (submitType) {
            case RATE_ADJUSTMENT_SUBMIT_TYPE.APPROVE:
              urlGen = ratesApproveApiUrl;
              successMsg = 'Proposed rate approved';
              break;
            case RATE_ADJUSTMENT_SUBMIT_TYPE.CANCEL:
              urlGen = ratesCancelApiUrl;
              successMsg = 'Rate adjustment cancelled';
              break;
            case RATE_ADJUSTMENT_SUBMIT_TYPE.INITIATE:
              urlGen = ratesEditApiUrl;
              if (values.approve) {
                successMsg = 'Rate changed';
              } else {
                successMsg = 'New rate suggested';
              }
              break;
            case RATE_ADJUSTMENT_SUBMIT_TYPE.REJECT:
              urlGen = ratesDeclineApiUrl;
              successMsg = 'Proposed rate rejected';
              break;
            default:
          }

          if (
            submitType === RATE_ADJUSTMENT_SUBMIT_TYPE.INITIATE
            && rateAdjustmentAllowedActions.canInitiateAndApprove
            && values.approve === undefined
          ) {
            return { approve: 'Please select if you want to immediately approve this rate change' };
          }

          try {
            const { data } = await axios.put(urlGen(activeOrg.alias, rateId), values);
            dispatch(modalDataViewFetchAC(data));
            onClose();
            dispatch(modalDataViewResetAC());
            onSuccess(data);
            toastr.success('Well Done!', successMsg);
            if (
              hasActiveTasks
              && data.isDefault
              && activeOrg.can_apply_default_rate_change_to_tasks
              && values.approve
              && activeUserCard.userRole.isAnyManager
            ) {
              dispatch(modalOpenAC(
                UPDATE_TASK_ASSIGNMENTS_RATES_MODAL_ID, { newRate: data, oldRate: rate },
              ));
            }
            return null;
          } catch (err) {
            const errors = omit(err.response?.data || {}, '_error', '_meta');
            if (Object.keys(errors).length > 0) {
              return errors;
            }
            return { [FORM_ERROR]: err.response?.data._error || err.message };
          }
        }}
        render={({ form, handleSubmit, submitError, submitting }) => {
          if (!formRef.current) {
            // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is broken
            // https://github.com/final-form/react-final-form/issues/483
            formRef.current = form;
          }
          let timelineFooterEl = null;
          if (!rateAdjustmentAllowedActions.canAmend) {
            timelineFooterEl = (
              <TDSystemMessage
                type={BS_STYLE.INFO}
                className="mb-5"
                title="User on-boarding in progress"
              >
                <p>
                  The user&apos;s on-boarding in your organisation at TalentDesk.io has not been
                  completed yet. Their default rate will be set once their on-boarding application
                  gets approved by a manager. However, you can renegotiate their rate at any time.
                </p>
                <p>
                  Stay tuned!
                </p>
              </TDSystemMessage>
            );
          }
          if (rateAdjustmentAllowedActions.canAmend && rateAdjustmentAllowedActions.canRespond) {
            timelineFooterEl = <RateAdjustmentReviewForm form={formRef.current} />;
          }
          return (
            <form onSubmit={handleSubmit}>
              <ModalSimple
                body={(
                  <>
                    {(submitting || isLoading) && <LoadingComponent />}
                    <RateAdjustmentTimeline
                      rateAdjustments={rate.rateAdjustments}
                      scrollMode
                      timelineFooter={timelineFooterEl}
                    />
                    {rateAdjustmentAllowedActions.canInitiate && (
                      <RateAdjustmentModalForm
                        canInitiateAndApprove={rateAdjustmentAllowedActions.canInitiateAndApprove}
                        currencySymbol={rate.currencySymbol}
                        rateUnit={rateUnit}
                      />
                    )}
                    {submitError && (
                      <div className="has-error mb-4">
                        <span className="help-block">{submitError}</span>
                      </div>
                    )}
                  </>
                )}
                data-testid="rate-adjustment-modal"
                footer={(
                  <RateAdjustmentFooter
                    allowedActions={rateAdjustmentAllowedActions}
                    form={formRef.current}
                    key="rate-adjustment-footer"
                  />
                )}
                heading={rate.alias || 'Loading...'}
                onClose={onClose}
                onOpen={() => {
                  form.reset();
                  if (!rate || isEmpty(rate)) {
                    // The rate is not available, fetch it now
                    dispatch(fetchProviderRateAdjustmentsDS({ orgAlias: activeOrg.alias, rateId }));
                    return;
                  }
                  // The initial rate can be passed as a prop or fetched on modal open
                  dispatch(modalDataViewFetchAC(rate));
                }}
                open={isModalOpen}
              />
            </form>
          );
        }}
      />
      <UpdateTaskAssignmentRatesModal />
    </>
  );
};

RateAdjustmentModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default RateAdjustmentModal;
