/* eslint react/prop-types: "warn", import/prefer-default-export: "warn", react/no-multi-comp: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { isFinite } from 'lodash';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import ProcessingFeeScheme from 'finance/assets/js/lib/ProcessingFeeScheme';
import LicenceFeeScheme from 'finance/assets/js/lib/LicenceFeeScheme';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Money from 'finance/assets/js/lib/Money';
import { SERVICE_KEY_NAME } from 'finance/assets/js/constants';

const FeeRange = ({ feePercent, range, currency }) => {
  const symbol = CURRENCY_SYMBOL[currency];
  if (isFinite(range[1])) {
    return (
      <span>
        {`${feePercent}% from `}
        <NumberTpl prefix={symbol} value={range[0]} />
        {' to '}
        <NumberTpl prefix={symbol} value={range[1]} />
      </span>
    );
  }
  return (
    <span>
      {`${feePercent}% over `}
      <NumberTpl prefix={symbol} value={range[0]} />
    </span>
  );
};

FeeRange.propTypes = {
  range: PropTypes.array.isRequired,
  feePercent: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export const FeeScale = ({ scaleSteps, currency }) => {
  if (scaleSteps.length === 1) {
    const { feePercent } = scaleSteps[0];
    return (
      <span>
        {feePercent}
        {'%'}
      </span>
    );
  }
  return (
    <React.Fragment>
      {
        scaleSteps
          .map(s => (<FeeRange feePercent={s.feePercent} range={s.range} currency={currency} />))
          .reduce((acc, x) => {
            return acc === null ? x : (
              <>
                {acc}
                <br />
                {x}
              </>
            );
          }, null)
      }
    </React.Fragment>
  );
};

FeeScale.propTypes = {
  scaleSteps: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
};

export const ProcessingFeeSpec = ({ scheme }) => {
  if (!scheme) {
    return '-';
  }
  const parsed = new ProcessingFeeScheme(scheme);
  if (!parsed.hasFee()) {
    return '-';
  }
  const symbol = parsed.getCurrencySymbol();
  const scaleSteps = parsed.getScaleSteps();
  const currency = parsed.getCurrency();
  const floor = parsed.getFloor();
  const perApprovedWorksheet = parsed.getPerApprovedWorksheet();
  return (
    <span>
      {!parsed.hasFee() && (
        '-'
      )}
      {parsed.hasScale() && (
        <FeeScale scaleSteps={scaleSteps} currency={currency} />
      )}
      {parsed.hasFloor() && (
        <div>
          {'floored at '}
          <NumberTpl prefix={symbol} value={floor} />
        </div>
      )}
      {parsed.hasPerApprovedWorksheet() && (
        <div className="">
          <NumberTpl prefix={symbol} value={perApprovedWorksheet} />
          {' per approved Worksheet'}
        </div>
      )}
    </span>
  );
};

ProcessingFeeSpec.propTypes = {
  scheme: PropTypes.object.isRequired,
};

export const LicenceFeeSpec = ({ scheme }) => {
  if (!scheme) {
    return '-';
  }
  const parsed = new LicenceFeeScheme(scheme);
  if (!parsed.hasFee() && !parsed.hasAdditionalServicesFee()) {
    return '-';
  }
  const symbol = parsed.getCurrencySymbol();
  const {
    baseLicenceFee, baseManagerSeats, baseProviderSeats, perManagerSeat, perProviderSeat,
    currency,
  } = parsed.serialize();

  const serviceKeyToDisplayMapping = {
    [SERVICE_KEY_NAME.AOR]: ' per AOR seat',
    [SERVICE_KEY_NAME.API]: ' for API integration',
    [SERVICE_KEY_NAME.CODAT]: ' for Codat integration',
  };

  return (
    <span>
      {parsed.isFixed() ? (
        <span>
          <NumberTpl prefix={symbol} value={parsed.getFee()} />
          {' fixed '}
        </span>
      ) : (
        <>
          <span>
            {baseManagerSeats ? (
              <span>
                {new Money(baseLicenceFee, currency).isZero() ? (
                  <span>
                    Free
                    {' '}
                  </span>
                ) : (
                  <span>
                    <NumberTpl prefix={symbol} value={baseLicenceFee} />
                  </span>
                )}
                <br />
                up to
                {' '}
                {baseManagerSeats}
                {' '}
                managers and
                {' '}
                {baseProviderSeats}
                {' '}
                providers
                <br />
              </span>
            ) : (
              <span>
                <NumberTpl prefix={symbol} value={baseLicenceFee} />
                {' base fee'}
                <br />
              </span>
            )}
            <NumberTpl prefix={symbol} value={perManagerSeat} />
            {' per extra manager'}
            <br />
            <NumberTpl prefix={symbol} value={perProviderSeat} />
            {' per extra provider'}
            <br />
          </span>
          {Object.keys(serviceKeyToDisplayMapping).map(key => parsed
            .hasDefinedFeeForServiceKey(key) && (
            <span key={key}>
              <NumberTpl
                prefix={parsed._getFeeSchemeForServiceKey(key).getCurrencySymbol()}
                value={key === SERVICE_KEY_NAME.AOR
                  ? parsed._getFeeSchemeForServiceKey(key).getPerAORSeat()
                  : parsed._getFeeSchemeForServiceKey(key).getBasePrice()}
              />
              {serviceKeyToDisplayMapping[key]}
              <br />
            </span>
          ))}
        </>
      )}
    </span>
  );
};

LicenceFeeSpec.propTypes = {
  scheme: PropTypes.object.isRequired,
};

const FeeSchemePanel = ({ scheme }) => {
  if (!scheme) {
    return null;
  }
  const parsed = new InvoicingFeeScheme(scheme);
  return (
    <React.Fragment>
      <ProcessingFeeSpec scheme={parsed.getProcessingFeeSpec()} />
      <LicenceFeeSpec scheme={parsed.getLicenceFeeSpec()} />
    </React.Fragment>
  );
};

FeeSchemePanel.propTypes = {
  scheme: PropTypes.object.isRequired,
};

export default FeeSchemePanel;
