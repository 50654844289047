import React from 'react';
import PropTypes from 'prop-types';


const RectangleSkeleton = ({ width, height, className }) => (
  <span
    className={['skeleton--rectangle', className].join(' ')}
    style={{
      width: `${width}px`,
      height: `${height}px`,
    }}
  />
);

RectangleSkeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

RectangleSkeleton.defaultProps = {
  width: '100',
  height: '25',
  className: '',
};

export default RectangleSkeleton;
