import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { isArray, find } from 'lodash';

import { isSSR } from 'core/assets/js/config/checks';
import { REACT_SELECT_STYLES } from 'core/assets/js/constants';
import withField from 'core/assets/js/components/withField.jsx';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

class TagsFilterField extends React.Component {
  constructor(props) {
    super(props);
    const { initialValues, filter, input } = props;
    let initialValue = initialValues[filter.paramName] || [];

    // If value is not an array, transform it to one.
    if (initialValue && !isArray(initialValue)) {
      initialValue = [initialValue];
    }

    /**
     *  Since we are passing filter values to the location search, the redux field value
     *  needs to be an array of strings.
     *  BUT, the Select component expects as a value an array of objects. For this reason we
     *  need to transform the array of strings to an array
     *  of values by finding the object in the filter.options array.
     *
     *  For example the input value ['ita', 'ell'] needs to be transformed into:
     *  [{ id: 'ita', label: 'Italian' }, { value: 'ell' , label: 'Greek' }]
     */
    this.value = initialValue.map(
      // find the whole object
      val => find(filter.options, opt => opt.value === val),
    );

    input.onChange(initialValue);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    const { input: { onChange } } = this.props;
    this.value = value;
    onChange(value && value.map(item => item.value));
  }

  render() {
    const {
      filter: { label, options }, input: { name }, inputClassName,
    } = this.props;

    return (
      <React.Fragment>
        <h4>{label}</h4>
        <div className="row">
          <Select
            name={name}
            className={inputClassName}
            classNamePrefix="react-select"
            styles={REACT_SELECT_STYLES}
            value={this.value}
            onChange={this.handleChange}
            valueKey="id"
            labelKey="label"
            menuPlacement="auto"
            menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
            isMulti
            joinValues
            isSearchable
            cache={false}
            options={options}
            getOptionValue={opt => opt.value}
            getOptionLabel={opt => opt.text}
          />
        </div>
      </React.Fragment>
    );
  }
}

TagsFilterField.propTypes = {
  filter: PropTypes.object,
  input: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  inputClassName: PropTypes.string,
};
TagsFilterField.defaultProps = {
  filter: {},
  inputClassName: 'col-12',
};

export default withField(TagsFilterField);
