import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { postOrganizationDS } from 'settings/assets/js/data-services/organizations';

const GeneralSettingsView = () => {
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();

  const updateOrgSetting = setting => newValue => dispatch(postOrganizationDS(
    activeOrg.unique_alias, { [setting]: newValue },
  ));

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'General settings', url: null }]} />
      <div className="page page--settings general">
        <div className="container">
          <div className="rounded shadow-sm p-4 bg-white">
            <h3 className="heading-block">Team</h3>
            <div className="settings-row">
              <TDToggleWithConfirmationModal
                disableHeader="Providers can see the managers list"
                DisableModalBody={() => (
                  <>
                    <p>
                      By deactivating this setting, providers will not be able to see the
                      organization&apos;s managers list.
                    </p>
                    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
                  </>
                )}
                enableHeader="Providers cannot see the managers list"
                EnableModalBody={() => (
                  <>
                    <p>
                      By activating this setting, providers will be able to see the
                      organization&apos;s managers list.
                    </p>
                    <p className="mb-4">Are you sure you want to activate this setting?</p>
                  </>
                )}
                label="Allow Providers to view the list of Managers"
                modalId="enable-providers-seeing-managers-list"
                onToggled={updateOrgSetting('providers_can_see_managers')}
                popOverContent={(
                  'By activating this option your providers are able to see your full list of '
                    + 'managers'
                )}
                popOverTitle="Allow Providers to view the list of Managers"
                selected={activeOrg.providers_can_see_managers}
                sublabel={(
                  'By activating this option your Providers are able to see the list of all the '
                    + "organisation's Managers"
                )}
              />
              <TDToggleWithConfirmationModal
                disableHeader="Providers can see the providers list"
                DisableModalBody={() => (
                  <>
                    <p>
                      By enabling this option your providers have access to the provider list or
                      other providers&apos; profile page in your organisation.
                    </p>
                    <p className="mb-4">
                      Are you sure you want to activate the &apos;Allow full visibility of your
                      provider list&apos; option?
                    </p>
                  </>
                )}
                enableHeader="Providers cannot see the providers list"
                EnableModalBody={() => (
                  <>
                    <p>
                      Deactivating this option means that only managers will have access to the
                      provider list or other providers&apos; profile page in your organisation.
                    </p>
                    <p className="mb-4">
                      Are you sure you want to deactivate the &apos;Allow full visibility of your
                      provider list&apos; option?
                    </p>
                  </>
                )}
                label="Allow Providers to view the list of Providers"
                modalId="enable-providers-seeing-providers-list"
                onToggled={updateOrgSetting('should_provider_view_other_providers')}
                popOverContent={(
                  'By activating this option your providers are able to see your full list of '
                    + 'providers'
                )}
                popOverTitle="Allow Providers to view the list of Providers"
                selected={activeOrg.should_provider_view_other_providers}
                sublabel={(
                  'By activating this option your Providers are able to see your full list of '
                    + 'Providers'
                )}
              />
              <TDToggleWithConfirmationModal
                disableHeader="Everyone can see global messaging"
                DisableModalBody={() => (
                  <>
                    <p>
                      Deactivating this option means that Managers and Providers will
                      not be able to communicate with each other within the TalentDesk.
                    </p>
                    <p className="mb-4">
                      Are you sure you want to deactivate the
                      &apos;Enable Global Messaging&apos; option?
                    </p>
                  </>
                )}
                enableHeader="No one can see global messaging"
                EnableModalBody={() => (
                  <>
                    <p>
                      By activating Global Messaging, your Managers and Providers will be able to
                      communicate with each other within the TalentDesk platform via either 1-on-1
                      or Group messages.
                    </p>
                    <p className="mb-4">
                      Are you sure you want to activate the
                      &apos;Enable Global Messaging&apos; option?
                    </p>
                  </>
                )}
                label="Enable Global Messaging"
                modalId="enable-message-hub"
                onToggled={updateOrgSetting('message_hub_enabled')}
                selected={activeOrg.message_hub_enabled}
                sublabel={`
                  By activating Global Messaging, your Managers and Providers will be able
                  to communicate with each other within the TalentDesk platform via either
                  1-on-1 or Group messages
                `}
              />
            </div>
            <h3 className="heading-block  mt-5">Reviews</h3>
            <div className="settings-row">
              <TDToggleWithConfirmationModal
                disableHeader="Full visibility of provider reviews is enabled"
                DisableModalBody={() => (
                  <>
                    <p>
                      Deactivating this option means that only managers will have access to the
                      average rating of other providers and all reviews left by your managers to
                      other providers.
                    </p>
                    <p className="mb-4">
                      Are you sure you want to deactivate the &apos;Allow full visibility of your
                      user reviews to all providers&apos; option?
                    </p>
                  </>
                )}
                enableHeader="Visibility of user reviews is restricted"
                EnableModalBody={() => (
                  <>
                    <p>
                      By enabling this option your providers have access to the average user rating
                      of other providers and all reviews left by your managers to other providers.
                    </p>
                    <p className="mb-4">
                      Are you sure you want to activate the &apos;Allow full visibility of your user
                      reviews to all providers&apos; option?
                    </p>
                  </>
                )}
                label="Allow Providers to view user reviews"
                modalId="should-provider-view-other-providers-review"
                onToggled={updateOrgSetting('should_provider_view_other_providers_review')}
                selected={activeOrg.should_provider_view_other_providers_review}
                sublabel={(
                  'By activating this option your Providers are able to see the reviews left by '
                    + 'your Managers to other Providers'
                )}
              />
            </div>
            <h3 className="heading-block mt-5">Rates</h3>
            <div className="settings-row">
              <TDToggleWithConfirmationModal
                disableHeader="Don't allow Managers to apply default rate changes to existing tasks"
                DisableModalBody={() => (
                  <>
                    <p>
                      By deactivating this setting, if a manager edits or selects a new default rate
                      for a Provider, they will not be allowed to apply this rate to the active
                      tasks that the provider has.
                    </p>
                    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
                  </>
                )}
                enableHeader="Allow Managers to apply default rate changes to existing tasks"
                EnableModalBody={() => (
                  <>
                    <p>
                      By activating this setting, if a manager edits or selects a new default rate
                      for a Provider, they will be allowed to apply this rate to the active tasks
                      that the provider has.
                    </p>
                    <p className="mb-4">Are you sure you want to activate this setting?</p>
                  </>
                )}
                label="Allow Managers to apply default rate changes to existing tasks"
                modalId="can-apply-default-rate-change-to-tasks"
                onToggled={updateOrgSetting('can_apply_default_rate_change_to_tasks')}
                selected={activeOrg.can_apply_default_rate_change_to_tasks}
                sublabel={(
                  'If a manager creates or edits the default rate for a Provider, they will be '
                    + 'allowed to apply this rate to the active tasks that the Provider has'
                )}
              />
            </div>
            <h3 className="heading-block mt-5">Availability</h3>
            <div className="settings-row">
              <TDToggleWithConfirmationModal
                enableHeader="Show the availability feature in this organization"
                EnableModalBody={() => (
                  <>
                    <p>
                      By activating this setting, when users have this organization selected, they
                      will see the availability feature.
                    </p>
                    <p className="mb-4">Are you sure you want to activate this setting?</p>
                  </>
                )}
                disableHeader="Hide the availability feature in this organization"
                DisableModalBody={() => (
                  <>
                    <p>
                      By deactivating this setting, when users have this organization selected, they
                      will not see the availability feature.
                    </p>
                    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
                  </>
                )}
                label="Show the availability feature in this organization"
                modalId="show-availability"
                onToggled={updateOrgSetting('show_availability')}
                selected={activeOrg.show_availability}
                sublabel={(
                  'By activating this option, when users have this organization selected, they '
                  + 'will see the availability feature'
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettingsView;
