/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';

import { BS_STYLE, TASK_TABS } from 'core/assets/js/constants';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { projectViewTaskUrl } from 'projects/urls';


export const FORM_ID = 'InvitationResponseForm';

const InvitationResponseForm = ({
  buttonLabel,
  buttonStyle,
  handleSubmit,
  invitation,
  match: {
    params: {
      orgAlias,
      taskId: matchTaskId,
    },
  },
  onCancel,
  pristine,
  project,
  submitting,
  taskId: propTaskId,
}) => {
  const taskId = matchTaskId || propTaskId;
  const overviewTabUrl = projectViewTaskUrl(
    orgAlias,
    project.id,
    taskId,
    TASK_TABS.DASHBOARD,
  );

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="message"
        type="textarea"
        placeholder="Type your message..."
        component={InputField}
        label="Message"
        autoFocus
      />

      {invitation && (
        <Field
          name="token"
          component="input"
          type="hidden"
          value={invitation.token}
        />
      )}

      <div className="text-left mt-4">
        By accepting this task you agree to the terms set in the the overview page. To review them, please
        {' '}
        <Link to={overviewTabUrl}>
          click here
        </Link>
        .
      </div>
      <div className="text-right mt-4">
        {onCancel && (
          <TDButton
            onClick={onCancel}
            type="button"
            variant={BS_STYLE.DEFAULT}
            disabled={submitting}
            label="Cancel"
          />
        )}

        <TDButton
          className={pristine ? 'btn--pristine' : 'btn--dirty'}
          type="submit"
          variant={buttonStyle}
          disabled={submitting}
          label={buttonLabel}
        />
      </div>
    </form>
  );
};

InvitationResponseForm.propTypes = {
  buttonLabel: PropTypes.string,
  buttonStyle: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  invitation: invitationSpec,
  match: PropTypes.object,
  onCancel: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  taskId: PropTypes.number,
};

InvitationResponseForm.defaultProps = {
  buttonLabel: 'Send',
  buttonStyle: BS_STYLE.PRIMARY,
  invitation: {},
  match: {},
  onCancel: null,
  taskId: null,
};

const ConnectedInvitationResponseForm = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
})(InvitationResponseForm);

export default withRouter(ConnectedInvitationResponseForm);
