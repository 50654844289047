import { authProfileUpdateAC } from 'accounts/assets/js/reducers/auth';
import { fetchViewDS, viewFetchAC, viewUpdateAC } from 'core/assets/js/ducks/view';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';
import {
  profileApiUrl,
  profileFetchExperienceApiUrl,
  profileReorderExperienceApiUrl,
  profileSocialApiUrl,
  profileLanguagesApiUrl,
  profileSkillsApiUrl,
} from 'accounts/urls';
import { listFetchAC } from 'core/assets/js/ducks/list';


const fetchProfileDS = ({ url = '', authedAxios, componentName }) => (
  fetchViewDS({
    authedAxios,
    componentName,
    url: profileApiUrl(url),
  })
);

const postProfileDS = (values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: profileApiUrl(),
    pushDataAC: responseData => [
      viewUpdateAC(responseData.updated, componentName),
      authProfileUpdateAC(responseData.authState),
    ],
    values,
  })
);

const postProfileSocialDS = (values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: profileSocialApiUrl(),
    pushDataAC: responseData => [
      viewUpdateAC(responseData.updated, componentName),
    ],
    values,
  })
);

const updateProfileSkillsDS = (values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: profileSkillsApiUrl(),
    pushDataAC: responseData => [
      viewUpdateAC(responseData.updated, componentName),
    ],
    values,
  })
);
const updateProfileLanguagesDS = (values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: profileLanguagesApiUrl(),
    pushDataAC: responseData => [
      viewUpdateAC(responseData.updated, componentName),
    ],
    values,
  })
);

const fetchExperienceDS = ({ experienceId, url = '', authedAxios, componentName }) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => profileFetchExperienceApiUrl(experienceId, url),
    fetchDataAC: responseData => viewFetchAC(responseData.experience, componentName),
  })
);

const reorderExperienceDS = (values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: profileReorderExperienceApiUrl(),
    pushDataAC: responseData => listFetchAC(responseData.experiences, componentName),
    values,
  })
);

export {
  fetchExperienceDS,
  fetchProfileDS,
  postProfileDS,
  postProfileSocialDS,
  reorderExperienceDS,
  updateProfileSkillsDS,
  updateProfileLanguagesDS,
};
