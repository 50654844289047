import { pick } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useLocation, useParams, withRouter } from 'react-router-dom';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { pluralize } from 'core/assets/js/lib/utils';
import { parseSearchableFieldsInQuery } from 'interviews/assets/js/lib/utils';
import UserGroupAvatar from 'people/assets/js/components/UserGroupAvatar.jsx';
import UserGroupsListSearch from 'people/assets/js/components/UserGroupsListSearch.jsx';
import { userGroupManageUrl, userGroupsListApiUrl } from 'people/urls';
import { PROJECT_TAB_INVITEES_COMPONENT_NAME } from 'projects/assets/js/constants';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  getProjectOpportunityInviteesApiUrl, inviteToApplyForOpportunityApiUrl,
} from 'projects/urls';

export const MODAL_ID = 'opportunity-invite-groups-modal';

const STEPS = { SELECT_GROUPS: 1, CONFIRM_SELECTION: 2 };

const OpportunityInviteGroupsModal = ({ filtersOpen, project }) => {
  const params = useParams();
  const { orgAlias } = params;
  const location = useLocation();
  const dispatch = useDispatch();

  const componentName = OpportunityInviteGroupsModal.GetComponentName({ params });

  const isModalOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const {
    items: groups, pagination, selectedItems,
  } = useSelector(state => getListState(state, componentName));

  const [query, setQuery] = useState({});
  const [currentStep, setCurrentStep] = useState(STEPS.SELECT_GROUPS);
  const [showSelectedItems, setShowSelectedItems] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleQueryUpdate = newQuery => {
    setQuery({ ...parseSearchableFieldsInQuery(newQuery), page: pagination.page });
  };

  const handlePageChange = newPagination => setQuery({ ...query, ...newPagination });

  const closeModal = () => dispatch(modalCloseAC());

  const handleShowSelectedItemsToggle = () => setShowSelectedItems(currentState => !currentState);

  const currentStepIsSelectGroups = currentStep === STEPS.SELECT_GROUPS;

  const selectedItemsLength = selectedItems.length;
  const noneSelected = selectedItemsLength === 0;

  useEffect(() => {
    setCurrentStep(STEPS.SELECT_GROUPS);
  }, [isModalOpen]);

  return (
    <ModalSimple
      className="opportunity-invite-groups-modal"
      body={(
        <>
          <TDSystemMessage className="mb-5" title="Info">
            Invitations will be sent to the current group members. If new members are added to this
            group afterwards, the new members will not receive an invitation to the opportunity.
            Similarly, if a member is removed from a group the invitation will not be revoked.
          </TDSystemMessage>
          {/* Group selection Step */}
          <SelectableListWrapper
            cardItem={{
              component: ({ item }) => {
                const { id, membersCount, name } = item;
                return (
                  <div
                    className="d-flex align-items-center justify-content-between group-option p-5"
                  >
                    <Link
                      className="d-flex align-items-center text-decoration-none mr-5"
                      to={userGroupManageUrl(orgAlias, id)}
                      target="_blank"
                    >
                      <UserGroupAvatar iconClassName="fa-1x" {...item} />
                      <div className="ml-5">{name}</div>
                    </Link>
                    <div className="d-flex align-items-center members-count">
                      <i className={`${ICON.USER_LIGHT} mr-3`} />
                      <span>
                        {membersCount === 0 && 'No members added yet'}
                        {membersCount > 0 && `${membersCount} ${pluralize('member', membersCount)}`}
                      </span>
                    </div>
                  </div>
                );
              },
            }}
            className={currentStepIsSelectGroups ? null : 'd-none'}
            componentName={componentName}
            emptyListMessage="No groups found."
            emptySelectedListMessage={(
              <p className="discreet">
                There are currently no selected groups.
                <br />
                <span
                  className="imitate-link"
                  onClick={handleShowSelectedItemsToggle}
                >
                  Find groups
                </span>
                .
              </p>
            )}
            fetchData={() => dispatch(fetchListDS({
              componentName,
              querystring: queryString.stringify({
                ...query, excludeInvitedToOpportunityId: project.id,
              }),
              url: userGroupsListApiUrl({ orgAlias }),
            }))}
            filtersOpen={filtersOpen}
            items={groups}
            onPageChange={handlePageChange}
            searchComponent={(
              <UserGroupsListSearch
                className=""
                initialValues={pick(query, 'kw', 'ordering')}
                onFiltersChanged={handleQueryUpdate}
              />
            )}
            selectModeEnabled
            showSelectedItems={showSelectedItems}
            skeletonComponent={PeopleListSkeleton}
            query={query}
          />

          {/* Confirmation Step */}
          {!currentStepIsSelectGroups && (
            <div>
              <p data-testid="project-add-members-panel-confirmation-text">
                {
                  [
                    `You’ve selected to invite ${selectedItemsLength} new groups to apply to `,
                    'join this opportunity',
                  ].join(' ')
                }
              </p>
              {selectedItems && selectedItems.map(userGroup => (
                <div className="selected-group d-inline-block mr-3" key={userGroup.id}>
                  <UserGroupAvatar iconClassName="fa-2x" {...userGroup} />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      footer={(
        <>
          {currentStepIsSelectGroups && selectedItemsLength > 0 && (
            <span
              className="cursor-pointer d-flex align-items-center"
              onClick={handleShowSelectedItemsToggle}
            >
              <TDSwitch selected={showSelectedItems} />
              <span className="ml-3">
                {showSelectedItems
                  ? 'Show all groups'
                  : `Show ${selectedItemsLength} selected groups`
                }
              </span>
            </span>
          )}
          <div className="ml-auto">
            {currentStepIsSelectGroups && (
              <>
                <TDButton label="Cancel" onClick={closeModal} />
                <TDButton
                  className="ml-4"
                  disabled={noneSelected}
                  label="Next"
                  onClick={() => setCurrentStep(STEPS.CONFIRM_SELECTION)}
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />
              </>
            )}
            {!currentStepIsSelectGroups && (
              <>
                <TDButton
                  disabled={submitting}
                  label="Back"
                  onClick={() => setCurrentStep(STEPS.SELECT_GROUPS)}
                />
                <TDButton
                  className="ml-4"
                  disabled={noneSelected || submitting}
                  label={`Send ${pluralize('invitation', selectedItemsLength)}`}
                  onClick={async () => {
                    setSubmitting(true);
                    try {
                      await axios.post(
                        inviteToApplyForOpportunityApiUrl(orgAlias, project.id),
                        { userGroupIds: selectedItems.map(ug => ug.id) },
                      );
                      toastr.success(
                        'Well Done!',
                        `You have invited ${selectedItemsLength} groups to apply for this `
                          + 'opportunity',
                      );
                      // Reload invitees list
                      dispatch(fetchListDS({
                        componentName: PROJECT_TAB_INVITEES_COMPONENT_NAME,
                        url: [
                          getProjectOpportunityInviteesApiUrl(orgAlias, project.id),
                          `?${location.search}`,
                        ].join(''),
                      }));
                      closeModal();
                    } catch (err) {
                      toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />
              </>
            )}
          </div>
        </>
      )}
      heading={<h4>Invite groups to apply</h4>}
      onClose={closeModal}
      open={isModalOpen}
      size={BS_SIZE.LARGE}
    />
  );
};

OpportunityInviteGroupsModal.GetComponentName = () => 'OpportunityInviteGroupsModal';

OpportunityInviteGroupsModal.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  project: projectSpec.isRequired,
};

export default withRouter(withFilters(OpportunityInviteGroupsModal));
