import React from 'react';

import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ExpensesWidgetItemSkeleton = () => (
  <div className="skeleton--task-list-item clearfix">
    <div className="float-left">
      <RectangleSkeleton width="183" height="20" />
      <RectangleSkeleton width="100" height="20" />
      <CircleSkeleton className="ml-2" diameter="20" />
      <br />
      <RectangleSkeleton width="80" height="10" />
    </div>
  </div>
);

export default ExpensesWidgetItemSkeleton;
