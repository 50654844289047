import { INVOICE_CSV_TYPE } from 'exporter/constants';

// API URLS
export const exporterReportExportUrl = ({ orgAlias, id, reportType, exportType }) => (
  `/api/${orgAlias}/export/${reportType}/${id}/${exportType}`
);


export const invoiceInvitationDownloadUrl = (orgAlias, id, token) => (
  `/api/${orgAlias}/export/invoice/${id}/pdf/${token}`
);

export const invoicesReportDownloadUrl = ({ orgAlias, token }) => (
  `/api/${orgAlias}/export/invoices/summary-report/pdf/${token}`
);

export const exporterInvoicesExportUrl = ({
  orgAlias, querystring, csvType = INVOICE_CSV_TYPE.STANDARD,
}) => (
  `/api/${orgAlias}/export/invoices/csv/${csvType}${querystring}`
);

export const exporterWorksheetsExportUrl = ({ orgAlias, queryString }) => (
  `/api/${orgAlias}/export/worksheets${queryString}`
);
