/* eslint no-underscore-dangle: ["error", { "allow": ["_lastCountryCode"] }] */
import React from 'react';
import { Field } from 'redux-form';

import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { isValidBic, isValidIban } from 'core/assets/js/lib/validators';
import { InvalidBankAccountError } from 'settings/assets/js/lib/errors';

const validateIbanField = (value) => {
  const noError = '';
  if (!value) {
    return noError;
  }

  try {
    isValidIban(value);
    return noError;
  } catch (err) {
    if (err instanceof InvalidBankAccountError) {
      return err.message;
    }
  }
  return noError;
};

const validateBicField = (value) => {
  const noError = '';
  if (!value) {
    return noError;
  }

  try {
    isValidBic(value);
    return noError;
  } catch (err) {
    if (err instanceof InvalidBankAccountError) {
      return err.message;
    }
  }
  return noError;
};


const BankAccountForm = () => (
  <React.Fragment>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name="swift_bic_code"
          type="text"
          component={InputField}
          label="BIC/Swift Code"
          validate={validateBicField}
        />
      </div>
      <div className="col-12 col-sm-6">
        <Field
          name="iban"
          type="text"
          component={InputField}
          label="IBAN"
          validate={validateIbanField}
        />
      </div>
    </div>
  </React.Fragment>
);

export default BankAccountForm;
