import React from 'react';
import { withRouter } from 'react-router-dom';

import { userGroupCreateUrl } from 'people/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import UserGroupsList from 'people/assets/js/components/UserGroupsList.jsx';

class UserGroupsListView extends React.Component {
  static GetComponentName() {
    return 'UserGroupsListView';
  }

  render() {
    const { history, match } = this.props;
    const { orgAlias } = match.params;
    const breadcrumbs = [
      {
        title: 'Groups',
        url: null,
      },
    ];
    const ctaComponent = (
      <TDButton
        variant={BS_STYLE.PRIMARY}
        label="Create new group"
        onClick={() => history.push(userGroupCreateUrl(orgAlias))}
        floatRight
      />
    );
    return (
      <>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={ctaComponent}
        />
        <div className="page page--group">
          <div className="container">
            <UserGroupsList
              orgAlias={orgAlias}
            />
          </div>
        </div>
      </>
    );
  }
}

UserGroupsListView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(UserGroupsListView);
