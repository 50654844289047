import React from 'react';
import PropTypes from 'prop-types';

const ReportSalesTax = ({ showVAT, vat, showFullVat, fullVat, showGST, gst, showHST, hst }) => (
  <>
    {showVAT && (
      <div>
        {showFullVat && fullVat ? (
          <span>{`VAT: ${fullVat}`}</span>
        ) : (
          <span>{`VAT: ${vat}`}</span>
        )}
      </div>
    )}
    {showGST && (
      <div>
        <span>{`GST: ${gst}`}</span>
      </div>
    )}
    {showHST && (
      <div>
        <span>{`HST: ${hst}`}</span>
      </div>
    )}
  </>
);

ReportSalesTax.propTypes = {
  showVAT: PropTypes.bool,
  vat: PropTypes.string,
  showFullVat: PropTypes.bool,
  fullVat: PropTypes.string,
  showGST: PropTypes.bool,
  gst: PropTypes.string,
  showHST: PropTypes.bool,
  hst: PropTypes.string,
};

ReportSalesTax.defaultProps = {
  showVAT: false,
  vat: '',
  showFullVat: false,
  fullVat: '',
  showGST: false,
  gst: '',
  showHST: false,
  hst: '',
};

export default ReportSalesTax;
