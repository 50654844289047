import React from 'react';
import PropTypes from 'prop-types';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';

const StatusColFormat = ({ statusLabel, statusClass, title }) => {
  // there are cases of statusLabel of more than one words
  // we want these cases to end up with dash joined strings for labels
  // e.g statusLabel paid => class paid
  // statusLabel "bad debt" => class bad-debt
  const _statusClass = statusClass || (statusLabel ? statusLabel.trim().split(' ').join('-').toLowerCase() : '');
  return (
    <StatusTag
      statusClass={_statusClass}
      label={statusLabel}
      title={title}
    />
  );
};

StatusColFormat.propTypes = {
  statusLabel: PropTypes.string,
  statusClass: PropTypes.string,
  title: PropTypes.string,
};

StatusColFormat.defaultProps = {
  statusLabel: '',
  statusClass: '',
  title: '',
};

export default StatusColFormat;
