import React from 'react';
import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import WizardSteps from 'core/assets/js/components/FinalFormFields/WizardSteps.jsx';

const required = value => (value ? undefined : 'Required Field');

function WizardSection() {
  return (
    <section id="text">
      <h2>Wizards</h2>
      <div className="bg-white p-3 pb-5">
        <div className="row">
          <div className="col-12">
            <React.Fragment>
              <h1>React Final Form Example</h1>
              <h2>Wizard Form</h2>
              <Wizard
                onSubmit={() => {
                  // values are passed to props
                }}
              >
                <Wizard.Page title="Step 1">
                  <div>
                    <h1>Step 1</h1>
                    <TextInputField
                      label="First Name"
                      name="firstName"
                      component="input"
                      type="text"
                      placeholder="First Name"
                      validate={required}
                      required
                    />
                  </div>
                </Wizard.Page>
                <Wizard.Page title="Step 2">
                  <div>
                    <h1>Step 2</h1>
                    <TextInputField
                      label="Middle Name"
                      name="middleName"
                      component="input"
                      placeholder="Last Name"
                      validate={required}
                      required
                    />
                  </div>
                </Wizard.Page>
                <Wizard.Page title="Step 3">
                  <div>
                    <h1>Step 3</h1>
                    <TextInputField
                      label="Last Name"
                      name="lastName"
                      component="input"
                      placeholder="Last Name"
                      validate={required}
                      required
                    />
                  </div>
                </Wizard.Page>
              </Wizard>
              <h2>Wizard Steps</h2>
              <WizardSteps steps={
                [
                  { title: 'Active', active: true, page: 1, completed: false },
                  { title: 'Completed', active: false, page: 2, completed: true },
                  { title: 'Inactive', active: false, page: 3, completed: false },
                  { title: 'Rejected', active: true, page: 4, completed: false, rejected: true },
                  { title: 'Pending', active: false, page: 5, completed: false, pending: true },
                ]
              }
              />
            </React.Fragment>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WizardSection;
