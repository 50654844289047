import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, pick } from 'lodash';

import CustomFieldRenderer from 'core/assets/js/components/CustomFieldRenderer.jsx';
import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_SIZE, BS_STYLE } from 'core/assets/js/constants';
import { customFieldSpec, customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { getCanUserAnswerQuestion } from 'people/assets/js/lib/utils';
import { TYPE as CUSTOM_FIELD_TYPE } from 'interviews/assets/js/constants';

const CustomFieldsFormFieldset = ({
  activeUserType,
  allowTemplateSelection,
  customFields,
  customFieldTemplates,
  fieldWrapper,
  initialValues,
  onTemplateAdded,
  onTemplateCleared,
  onTemplateRemoved,
  onTemplatesValueSet,
  templateFieldLabel,
  templateFieldSublabel,
  templatesFieldName,
}) => {
  const handleTemplatesSelected = (
    templateIds, { addedValue: addedTemplateId, removedValue: removedTemplateId } = {},
  ) => {
    if (addedTemplateId) {
      onTemplateAdded(addedTemplateId);
    } else if (removedTemplateId) {
      onTemplateRemoved(removedTemplateId);
    } else {
      onTemplatesValueSet(templateIds || []);
    }
  };

  const customFieldTemplateOptions = customFieldTemplates.map(
    tpl => ({ label: tpl.name, value: tpl.id, isFixed: !!tpl.isMandatory }),
  );

  const customFieldsByTemplateId = {};
  customFields.forEach(customField => {
    const { customFieldTemplateId } = customField;
    if (!customFieldTemplateId) {
      // Should not happen
      return;
    }
    const template = customFieldTemplates.find(t => t.id === customFieldTemplateId);
    if (!template) {
      // Should not happen
      return;
    }

    if (!customFieldsByTemplateId[customFieldTemplateId]) {
      customFieldsByTemplateId[customFieldTemplateId] = {
        customFields: [],
        hasQuestionThatCanBeAnswered: false,
        ...pick(template, 'isMandatory', 'name'),
      };
    }
    customFieldsByTemplateId[customFieldTemplateId].customFields.push(customField);
    if (getCanUserAnswerQuestion(customField, activeUserType)) {
      customFieldsByTemplateId[customFieldTemplateId].hasQuestionThatCanBeAnswered = true;
    }
    if (customField.type === CUSTOM_FIELD_TYPE.SELECT) {
      const [templateQuestion] = template.questions.filter(q => q.path === customField.path);
      // eslint-disable-next-line no-param-reassign
      customField.payload.choices = templateQuestion.payload.choices;
    }
  });

  return (
    <React.Fragment>
      {allowTemplateSelection && !isEmpty(customFieldTemplates) && (
        <CustomSelectField
          data-testid="custom-fields-template-selector"
          isMulti
          label={templateFieldLabel}
          name={templatesFieldName}
          onItemsUpdated={handleTemplatesSelected}
          options={customFieldTemplateOptions}
          sublabel={templateFieldSublabel}
        />
      )}

      {Object.entries(customFieldsByTemplateId).map(([templateId, template]) => (
        <React.Fragment key={templateId}>
          {onTemplateCleared && (
            <h3
              className={(
                'custom-fields-form-fieldset-template-title mb-4 mx-0 d-flex align-items-center'
                + ' justify-content-between'
              )}
            >
              <span>{template.name}</span>
              {!template.isMandatory && template.hasQuestionThatCanBeAnswered && (
                <TDButton
                  bsSize={BS_SIZE.SMALL}
                  className="pr-0 d-flex align-items-center imitate-link"
                  onClick={() => onTemplateCleared(parseInt(templateId, 10))}
                  variant={BS_STYLE.LINK}
                >
                  Clear
                </TDButton>
              )}
            </h3>
          )}
          {template.customFields.map((field) => {
            const renderedField = (
              <CustomFieldRenderer
                field={field}
                key={field.path}
                selectedOption={initialValues[field.path]}
              />
            );
            return typeof fieldWrapper === 'function' ? fieldWrapper(renderedField) : renderedField;
          })}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

CustomFieldsFormFieldset.propTypes = {
  activeUserType: PropTypes.number.isRequired,
  allowTemplateSelection: PropTypes.bool,
  customFields: PropTypes.arrayOf(customFieldSpec),
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
  fieldWrapper: PropTypes.func,
  initialValues: PropTypes.object,
  onTemplateAdded: PropTypes.func,
  onTemplateCleared: PropTypes.func,
  onTemplateRemoved: PropTypes.func,
  onTemplatesValueSet: PropTypes.func,
  templateFieldLabel: PropTypes.string.isRequired,
  templateFieldSublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  templatesFieldName: PropTypes.string,
};

CustomFieldsFormFieldset.defaultProps = {
  allowTemplateSelection: false,
  customFields: [],
  customFieldTemplates: [],
  fieldWrapper: null,
  initialValues: {},
  onTemplateAdded: () => {},
  onTemplateCleared: null,
  onTemplateRemoved: () => {},
  onTemplatesValueSet: () => {},
  templatesFieldName: 'custom_field_templates',
  templateFieldSublabel: null,
};

export default CustomFieldsFormFieldset;
