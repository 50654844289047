import React from 'react';
import TagsInput from 'react-tagsinput';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import { ICON } from 'core/assets/js/constants';
import withField from 'core/assets/js/components/withField.jsx';

// eslint-disable-next-line
export const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const userIconClassName = `${ICON.USER_DUOTONE} input-email-tags__tag--user-icon mr-4`;

const renderEmailInput = (props) => {
  const { addTag, onChange, value, key, ...other } = props;
  const valueIsValid = EMAIL_VALIDATION_REGEX.test(value);
  return (
    <>
      <div className="input-email-tags__tag mr-3" key={key}>
        <i className={userIconClassName} />
        <input data-testid="input-invitee-email" className="input-email-tags__tag--input" type="text" onChange={onChange} value={value} {...other} />
      </div>
      {valueIsValid && (
        <div data-testid="add-provider-button" className="input-email-tags__tag--add-btn-wrapper">
          <i className={`input-email-tags__tag--add-btn ${ICON.ADD}`} onClick={() => addTag()} />
        </div>
      )}
    </>
  );
};

renderEmailInput.propTypes = {
  addTag: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const renderEmailTag = ({ onRemove, getTagDisplayValue, tag, key }) => (
  <div className="input-email-tags__tag mr-3 mb-2" key={key}>
    <i className={userIconClassName} />
    <span className="input-email-tags__tag--value">{getTagDisplayValue(tag)}</span>
    <i className={`input-email-tags__tag--remove-btn ${ICON.CROSS}`} onClick={() => onRemove(key)} />
  </div>
);

renderEmailTag.protoType = {
  onRemove: PropTypes.func.isRequired,
  getTagDisplayValue: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
};

class InputEmailAsTag extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.parseValue = memoize(
      (value) => {
        const parsed = value ? JSON.parse(value) : [];
        return parsed;
      },
    );
  }

  handleChange(emails) {
    const { input } = this.props;
    input.onChange(JSON.stringify(emails));
  }

  render() {
    const {
      input, label, sublabel, placeholder, rest, meta: { error, submitError },
    } = this.props;
    const emails = this.parseValue(input?.value);
    const inputEmailAsTagClassName = ['input-email-tags', 'form-group'];
    const hasError = !!error || !!submitError;

    if (hasError) {
      inputEmailAsTagClassName.push('has-error');
    }
    return (
      <div className={inputEmailAsTagClassName.join(' ')}>
        {label && (
          <label htmlFor={input?.name}>
            {label}
            {sublabel && (
              <span className="sublabel">
                {' '}
                {sublabel}
              </span>
            )}
          </label>
        )}
        <TagsInput
          value={emails}
          addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
          onlyUnique
          addOnBlur
          addOnPaste
          inputProps={{ placeholder }}
          renderInput={renderEmailInput}
          renderLayout={
            (tagComponents, inputComponent) => (
              <>
                {tagComponents}
                {inputComponent}
              </>
            )
          }
          renderTag={renderEmailTag}
          validationRegex={EMAIL_VALIDATION_REGEX}
          pasteSplit={data => (
            data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
          )}
          onChange={this.handleChange}
        />
        <input
          {...input}
          placeholder={placeholder}
          type="hidden"
          className="form-control"
          {...rest}
        />
        {hasError && <span className="help-block mt-2 ml-2">{error || submitError}</span>}
      </div>
    );
  }
}

InputEmailAsTag.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  rest: PropTypes.object,
};

InputEmailAsTag.defaultProps = {
  meta: {
    touched: false,
    error: false,
  },
  placeholder: '',
  label: '',
  rest: {},
  sublabel: '',
};

export default withField(InputEmailAsTag);
