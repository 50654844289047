import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { profileSettingsUrl } from 'accounts/urls';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import ProfileExperienceForm from 'accounts/assets/js/components/ProfileExperienceForm.jsx';
import { createExperienceDS } from 'accounts/assets/js/data-services/form';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class ProfileCreateExperienceView extends React.Component {
  static GetComponentName() {
    return 'ProfileCreateExperienceView';
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    const { dispatch } = this.props;
    return dispatch(
      createExperienceDS(values, 'ProfileCreateExperienceView'),
    ).then(() => {
      toastr.success('Well Done!', 'Your experience was created successfully.');
    });
  }

  render() {
    const { activeOrg, history } = this.props;
    const breadcrumbs = [
      {
        title: 'Profile',
        url: profileSettingsUrl(activeOrg.alias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION),
      }, {
        title: 'Experience',
        url: profileSettingsUrl(activeOrg.alias, PROFILE_SETTINGS_TABS.EXPERIENCE),
      }, {
        title: 'Add your experience',
        url: null,
      },
    ];
    return (
      <>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--settings">
          <div className="container">
            <ProfileExperienceForm
              onSubmit={this.onSubmit}
              onSubmitSuccess={() => {
                history.push(profileSettingsUrl(activeOrg.alias, PROFILE_SETTINGS_TABS.EXPERIENCE));
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

ProfileCreateExperienceView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProfileCreateExperienceViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileCreateExperienceView);

export default withRouter(ProfileCreateExperienceViewConnected);
