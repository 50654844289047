import PropTypes from 'prop-types';
import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';
import { BS_SIZE, ICON } from 'core/assets/js/constants';

const OrgSettingContainer = ({
  children,
  label,
  modalId,
  popOverContent,
  popOverSize,
  popOverTitle,
  subLabel,
  containerClassName,
}) => {
  const classes = ['d-flex flex-container--standalone align-items-center'];
  if (containerClassName) {
    classes.push(containerClassName);
  }
  return (
    <div className={classes.join(' ')} data-testid={modalId}>
      <div className="settings-row__content pr-2">
        <div>
          {label && <span>{label}</span>}
          {(popOverTitle || popOverContent) && (
            <ElementWithPopOver
              className="d-inline-block"
              element={(<i className={`ml-2 ${ICON.INFO}`} />)}
              popOverTitle={popOverTitle}
              size={popOverSize}
              popOverContent={popOverContent}
            />
          )}
          {subLabel && <span className="discreet d-block mt-1">{subLabel}</span>}
        </div>
      </div>

      <div className="settings-row__cta">
        {children}
      </div>
    </div>
  );
};

OrgSettingContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  containerClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalId: PropTypes.string.isRequired,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.oneOf(Object.values(BS_SIZE)),
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

OrgSettingContainer.defaultProps = {
  containerClassName: '',
  label: null,
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
  subLabel: null,
};

export default OrgSettingContainer;
