import React from 'react';
import PropTypes from 'prop-types';


const CircleSkeleton = ({ diameter, className }) => (
  <span
    className={['skeleton--circle flex-shrink-0', className].join(' ')}
    style={{
      width: `${diameter}px`,
      height: `${diameter}px`,
      borderRadius: `${diameter}px`,
    }}
  />
);

CircleSkeleton.propTypes = {
  diameter: PropTypes.string,
  className: PropTypes.string,
};

CircleSkeleton.defaultProps = {
  diameter: '15',
  className: '',
};

export default CircleSkeleton;
