/* eslint no-underscore-dangle: ["error", { "allow": ["_error"] }] */
/*
eslint react/prop-types: [
  "error",
  {
    "ignore": [
      "input", "name", "type", "label", "placeholder", "meta", "error", "handleSubmit", "submitting"
    ]
  }
]
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, hasSubmitSucceeded } from 'redux-form';
import { Link } from 'react-router-dom';
import PasswordField from 'core/assets/js/components/ReduxFormFields/PasswordField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { loginUrl } from 'accounts/urls';
import { validatePassword } from 'core/assets/js/lib/utils';


function PasswordResetForm({
  error, handleSubmit, submitting, pristine, submitSucceeded, title, buttonLabel, successMessage,
  hasBackToLogin,
}) {
  return (
    <div>
      {submitSucceeded && (
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <p>
            {successMessage}
          </p>

          <Link
            to={loginUrl()}
            className="btn btn-primary mt-4 mb-4"
          >
            &laquo; Back to login
          </Link>
        </div>
      )}
      {!submitSucceeded && (
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">{title}</h1>

          <Field
            name="password"
            type="password"
            component={PasswordField}
            validate={[validatePassword]}
            label="Password"
            placeholder=""
            showStrengthIndicator
          />

          <Field
            name="repeat_password"
            type="password"
            component={PasswordField}
            label="Repeat Password"
            placeholder=""
          />
          {error && (
            <div className="form-group has-error">
              <span className="help-block">{error}</span>
            </div>
          )}

          <div id="buttons" className="row">
            <div className="col-12">
              <TDButton
                block
                className={['mt-4 mb-4', pristine ? 'btn--pristine' : 'btn--dirty'].join(' ')}
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={submitting}
                label={buttonLabel}
              />

              {hasBackToLogin && (
                <div className="text-center">
                  <Link to={loginUrl()}>&laquo; Back to login</Link>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

PasswordResetForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  hasBackToLogin: PropTypes.bool,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  successMessage: PropTypes.string,
};

PasswordResetForm.defaultProps = {
  title: 'Password Reset',
  buttonLabel: 'Reset my password',
  successMessage: 'Thank you! Your password has been reset.',
  hasBackToLogin: true,
};

const mapStateToProps = state => ({
  submitSucceeded: hasSubmitSucceeded('passwordResetForm')(state),
});
const PasswordResetFormConnect = reduxForm({
  form: 'passwordResetForm',
})(PasswordResetForm);
export default connect(mapStateToProps)(PasswordResetFormConnect);
