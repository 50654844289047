import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatDate } from 'core/assets/js/lib/utils';
import TDCheckbox from 'core/assets/js/components/TDCheckbox.jsx';


const WorksheetBillingPeriodSelection = ({ missingPeriods, onSelect, selectedPeriod }) => {
  const [currentPeriod, ..._missingPeriods] = missingPeriods;

  if (!currentPeriod) {
    return null;
  }

  const isPeriodSelected = (period) => {
    if (!selectedPeriod || !selectedPeriod.periodStart || !selectedPeriod.periodEnd) {
      return false;
    }

    return (
      moment(period.periodStart).isSame(moment(selectedPeriod.periodStart), 'day')
      && moment(period.periodEnd).isSame(moment(selectedPeriod.periodEnd), 'day')
    );
  };

  return (
    <React.Fragment>
      <h3>Current billing period</h3>
      <ul className="row billing-periods-list d-block my-2">
        <li
          className="col-12 billing-period-list-item"
        >
          <a
            onClick={() => onSelect(currentPeriod)}
            className="d-flex align-items-center justify-content-between py-3"
          >
            {`${formatDate(currentPeriod.periodStart)} - ${formatDate(currentPeriod.periodEnd)}`}
            <TDCheckbox green checked={isPeriodSelected(currentPeriod)} />
          </a>
        </li>
      </ul>

      {_missingPeriods.length > 0 && (
        <React.Fragment>
          <h3>
            Non billed periods
            <span className="discreet d-block text">
              Since you joined the project you haven‘t submitted any worksheets for the following
              periods.
            </span>
          </h3>
          <ul className="row billing-periods-list d-block my-2">
            { _missingPeriods.map(period => (
              <li
                key={period.periodStart}
                className="col-12 billing-period-list-item"
              >
                <a
                  onClick={() => onSelect(period)}
                  className="d-flex align-items-center justify-content-between py-3"
                >
                  {`${formatDate(period.periodStart)} - ${formatDate(period.periodEnd)}`}
                  <TDCheckbox green checked={isPeriodSelected(period)} />
                </a>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

WorksheetBillingPeriodSelection.propTypes = {
  missingPeriods: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.object,
};
WorksheetBillingPeriodSelection.defaultProps = {
  selectedPeriod: {},
};

export default WorksheetBillingPeriodSelection;
