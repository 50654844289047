import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { isMenuItemActive } from 'core/assets/js/lib/utils';


/**
 * Navbar submenu item
 */
const NavBarSubItem = ({ count, label, path, regEx, sections, tabIndex }) => (
  <li className="navbar__subMenuItem">
    <NavLink
      data-testid={`navbar-submenu-item-${label}`}
      activeClassName="navbar__menuItem--state-active"
      isActive={(_match, _location) => isMenuItemActive(_match, _location, sections, regEx)}
      tabIndex={tabIndex}
      to={path}
    >
      { count > 0 && (
        <span className="badge float-right">{count}</span>
      )}
      {label}
    </NavLink>
  </li>
);
NavBarSubItem.propTypes = {
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  regEx: PropTypes.array,
  sections: PropTypes.array,
  tabIndex: PropTypes.number.isRequired,
};
NavBarSubItem.defaultProps = {
  count: 0,
  regEx: null,
  sections: [],
};

export default NavBarSubItem;
