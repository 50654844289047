import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { Button, ButtonGroup, Card, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DetailsRows from 'core/assets/js/components/DetailsRows.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ChatTDLive from 'accounts/assets/js/ChatTDLive.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import {
  getViewState,
  getViewStateExtras,
  extrasUpdateAC,
  viewFetchExtrasAC,
} from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { extractPaginationFromHeaders } from 'core/assets/js/lib/utils';
import {
  selectActiveOrg,
  selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import FreelancerDetailsCard from 'people/assets/js/components/FreelancerDetailsCard.jsx';
import OnboardingTalentStatus from 'people/assets/js/components/OnboardingTalentStatus.jsx';
import TalentPortfolioCard from 'people/assets/js/components/TalentPortfolioCard.jsx';
import TalentReviewsCard from 'people/assets/js/components/TalentReviewsCard.jsx';
import MiniMessageWindow from 'people/assets/js/components/chat/MiniMessageWindow.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { toggleFavoriteViewDS } from 'people/assets/js/data-services/view';
import {
  postChatMessage,
  updateChatRead,
} from 'people/assets/js/data-services/form';
import {
  getChatByEmailsUrl,
  getChatMessagesUrl,
  orgPeopleDiscoverUrl,
  orgPeopleInviteUrl,
} from 'people/urls';
import { PPH_STATUS } from 'search/assets/js/constants';
import { getPPHStatus } from 'search/assets/js/lib/pph';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { isScrolledToTheTop } from 'people/assets/js/lib/utils';

const DiscoverPeopleDetailView = (props) => {
  const listInnerRef = useRef();
  const [newMessage, setNewMessage] = useState('');
  const [isChatExpanded, setIsChatExpanded] = useState();
  const [showChat, setShowChat] = useState(false);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const {
    activeOrg,
    activeUserCard,
    chatRoom,
    dispatch,
    hasLoaded,
    history,
    messagesPagination,
    talent,
  } = props;
  const orgAlias = activeOrg.unique_alias;
  const { profile } = talent;
  const componentName = DiscoverPeopleDetailView.GetComponentName();
  const status = talent?.invitations?.length
    ? getPPHStatus(talent.invitations)
    : '';
  const breadcrumbs = [
    {
      title: 'TalentMarket',
      url: orgPeopleDiscoverUrl(orgAlias),
    },
    {
      title: profile?.fullName,
      url: null,
    },
  ];
  useEffect(() => {
    if (history?.location?.state?.openedChat) {
      setIsChatExpanded(true);
      setShowChat(true);
    }
  }, [history?.location?.state?.openedChat]);
  const getDropDown = (showProfile) => {
    if (!status || [PPH_STATUS.NOT_INVITED, PPH_STATUS.CANCELLED].includes(status)) {
      return (
        <Dropdown as={ButtonGroup} className="td-dropdown" data-testid="talent-market-invite-dropdown">
          <Button
            disabled={!profile}
            onClick={() =>
              history.push({
                pathname: orgPeopleInviteUrl(orgAlias, PEOPLE_TYPE.PROVIDERS),
                state: { profile },
              })
            }
            variant={BS_STYLE.PRIMARY}
          >
            Invite as Provider
          </Button>

          <Dropdown.Toggle disabled={!profile} variant={BS_STYLE.PRIMARY} split>
            <span className={ICON.CHEVRON_DOWN} />
          </Dropdown.Toggle>

          <Dropdown.Menu alignRight>
            <Dropdown.Item
              onClick={() =>
                history.push({
                  pathname: orgPeopleInviteUrl(orgAlias, PEOPLE_TYPE.MANAGERS),
                  state: { profile },
                })
              }
            >
              Invite as Manager
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (showProfile) {
      return (
        <Button
          disabled={!profile}
          href={talent.url}
          target="_blank"
          variant={BS_STYLE.PRIMARY}
        >
          View profile
        </Button>
      );
    }

    return <OnboardingTalentStatus status={status} />;
  };

  const handleToggleFavorite = async () => {
    setIsFavoriteLoading(true);
    await dispatch(toggleFavoriteViewDS({ orgAlias, values: { talentId: talent.id },
      parentComponentName: componentName }));
    setIsFavoriteLoading(false);
  };

  const getChatByEmails = () => {
    return dispatch(
      fetchDataDS({
        fetchApiUrl: () => getChatByEmailsUrl({ orgAlias }),
        querystring: queryString.stringify({
          emails: [talent.email],
        }),
        fetchDataAC: (responseData) =>
          viewFetchExtrasAC(
            { chatRoom: responseData },
            componentName,
          ),
      }),
    );
  };

  const handleMessageSubmit = async (message) => {
    if (!(message)) {
      return;
    }
    dispatch(
      postChatMessage({
        orgAlias,
        values: {
          message,
          ...(chatRoom?.id
            ? {
              chatId: chatRoom.id,
              message,
            }
            : {
              users: [
                {
                  talentId: talent.id,
                  email: talent.email,
                  name: profile.fullName,
                  avatar: profile?.avatar,
                },
              ],
            }),
        },
        pushDataAC: (responseData) => {
          if (
            !Array.isArray(chatRoom?.chatMessages)
          ) {
            return getChatByEmails();
          }
          return viewFetchExtrasAC(
            {
              chatRoom: {
                ...chatRoom,
                chatMessages: [
                  ...(responseData?.chatRoomMessage ? [responseData.chatRoomMessage] : []),
                  ...chatRoom.chatMessages,
                ],
              },
            },
            componentName,
          );

        },
      }),
    );
  };

  const getMessages = (query = '') => {
    dispatch(
      fetchDataDS({
        fetchApiUrl: () =>
          getChatMessagesUrl({ orgAlias, chatId: chatRoom.id }),
        fetchDataAC: (responseData) => {
          const currentMessages = chatRoom?.chatMessages
          && Array.isArray(chatRoom?.chatMessages) ? chatRoom?.chatMessages : [];
          return extrasUpdateAC(
            {
              chatRoom: {
                ...chatRoom,
                chatMessages: [
                  ...currentMessages,
                  ...responseData,
                ],
              },
            },
            componentName,
          );
        },
        paginationAC: (headers) => {
          return extrasUpdateAC(
            { messagesPagination: extractPaginationFromHeaders(headers) },
            componentName,
          );
        },
        querystring: query,
      }),
    );
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isScrolled = isScrolledToTheTop(clientHeight, scrollHeight, scrollTop);
      if (isScrolled && messagesPagination?.next) {
        getMessages(messagesPagination.next);
      }
    }
  };

  const handleRead = () => {
    if (!chatRoom?.chatMessages?.length && chatRoom?.unreadCount <= 0) {
      return;
    }
    const currentChatUser = chatRoom?.chatRoomUsers?.find(
      (u) => u.userEmail === activeUserCard?.user?.email,
    );
    if (!currentChatUser) {
      return;
    }
    dispatch(
      updateChatRead({
        orgAlias,
        chatId: chatRoom.id,
        values: { messageId: chatRoom.chatMessages[0].id },
        pushDataAC: () =>
          viewFetchExtrasAC(
            { chatRoom: { ...chatRoom, unreadCount: 0 } },
            componentName,
          ),
      }),
    );
  };

  useEffect(() => {
    if (talent.email) {
      getChatByEmails();
    }
  }, [talent]);

  useEffect(() => {
    if (chatRoom?.id && !chatRoom?.chatMessages?.length) {
      getMessages();
    }
  }, [chatRoom]);

  useEffect(() => {
    if (!newMessage?.chatRoomMessage) {
      return;
    }
    if (
      !chatRoom?.chatMessages?.find(
        (m) => m.id === newMessage?.chatRoomMessage?.id,
      )
    ) {
      const messages = [newMessage.chatRoomMessage, ...chatRoom.chatMessages];
      messages.sort(
        (d1, d2) =>
          new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime(),
      );

      if (
        chatRoom?.chatMessages
        && Array.isArray(chatRoom?.chatMessages)
        && chatRoom?.chatMessages?.length
      ) {
        const unreadCount = chatRoom.unreadCount ? chatRoom.unreadCount + 1 : 1;
        dispatch(
          viewFetchExtrasAC(
            {
              chatRoom: {
                ...chatRoom,
                chatMessages: messages,
                unreadCount: isChatExpanded ? 0 : unreadCount,
              },
              messagesPagination,
            },
            componentName,
          ),
        );
      }

      setNewMessage();
    }
  }, [newMessage]);

  const handleFreelancerChat = () => {
    const messageWindow = (
      <MiniMessageWindow
        chatRoom={chatRoom}
        handleMessageSubmit={handleMessageSubmit}
        handleRead={handleRead}
        isChatExpanded={isChatExpanded}
        listInnerRef={listInnerRef}
        onScroll={onScroll}
        onStateChange={setIsChatExpanded}
        profile={profile}
        setIsChatExpanded={setIsChatExpanded}
        setShowChat={setShowChat}
        showChat={showChat}
      />
    );

    if (!(activeUserCard?.user?.email && chatRoom.id)) {
      return messageWindow;
    }

    return (
      <ChatTDLive
        email={activeUserCard?.user?.email}
        events={{
          [`event-chat-add-${chatRoom?.id}`]: setNewMessage,
        }}
      >
        {messageWindow}
      </ChatTDLive>
    );
  };

  const expandAndShowChat = () => {
    setShowChat(true);
    setIsChatExpanded(true);
  };

  return (
    <>
      <ContentHeader
        breadcrumbs={breadcrumbs}
        ctaComponent={getDropDown()}
      />

      <div className="page page--profile">
        <div className="container">
          <div className="user-profile row">
            <div className="col-12 col-lg-4">
              <FreelancerDetailsCard
                address={profile?.address.label}
                avatar={profile?.avatar}
                chatRoom={chatRoom}
                countryCode={profile?.address.countryCode}
                description={profile?.resume}
                fullName={profile?.fullName}
                handleChat={expandAndShowChat}
                handleToggleFavorite={handleToggleFavorite}
                isFavorite={talent?.isFavorite}
                isFavoriteLoading={isFavoriteLoading}
                jobTitle={profile?.jobTitle}
                rate={profile?.rate}
                rateUnit={talent?.userCard?.currencySymbol}
                rating={talent?.rating}
                reviews={talent?.numReviews}
              />
              <Card className="mt-4">
                <Card.Header>Skills</Card.Header>
                <Card.Body>
                  <div className="user-profile__skills">
                    <SkillList alignTags="left" skills={profile?.skills} />
                  </div>
                </Card.Body>
              </Card>
              <Card className="mt-4">
                <Card.Header>Insights</Card.Header>
                <Card.Body className="user-profile__joined-at row mx-0 d-flex flex-column px-4 ">
                  <DetailsRows
                    prefix="user-profile-joined-at"
                    rows={[
                      {
                        label: 'Projects worked on',
                        value: talent?.numProjects,
                      },
                      {
                        label: 'Organizations worked with',
                        value: talent?.numOrganizations,
                      },
                      {
                        label: 'Response time',
                        value: talent?.responseTime,
                      },
                    ]}
                  />
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-8 mt-4 mt-lg-0">
              {!(hasLoaded && !talent?.portfolio?.length)
              && (<TalentPortfolioCard talent={talent} />)}
              <TalentReviewsCard />
            </div>
          </div>
        </div>
      </div>

      {handleFreelancerChat()}
    </>
  );
};

DiscoverPeopleDetailView.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: PropTypes.object.isRequired,
  chatRoom: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  messagesPagination: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
};

DiscoverPeopleDetailView.defaultProps = {
  chatRoom: null,
};

DiscoverPeopleDetailView.GetComponentName = () => 'DiscoverPeopleDetailView';

const mapStateToProps = (state) => {
  const componentName = DiscoverPeopleDetailView.GetComponentName();
  const viewState = getViewState(
    state,
    componentName,
  );
  return {
    activeOrg: selectActiveOrg(state),
    activeUserCard: selectActiveUserCard(state),
    profile: selectProfile(state),
    hasLoaded: viewState.hasLoaded,
    talent: viewState.item,
    chatRoom: getViewStateExtras(
      state,
      componentName,
      'chatRoom',
    ),
    messagesPagination: getViewStateExtras(
      state,
      componentName,
      'messagesPagination',
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const DiscoverPeopleDetailViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverPeopleDetailView);

export default withRouter(withFilters(DiscoverPeopleDetailViewConnected));
