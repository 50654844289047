import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TimeAgo from 'timeago-react';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { isExternalUrl, makeRelative } from 'core/assets/js/lib/utils';
import { notificationListUrl } from 'notifier/urls';
import {
  fetchSummariesDS, markReadNotificationDS,
} from 'notifier/assets/js/data-services/notifications';
import { selectSummaries } from 'notifier/assets/js/ducks/notifications';
import { notificationSpec } from 'notifier/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class NavBarNotificationsList extends React.Component {
  static FetchData({ dispatch, url, authedAxios, componentName, isFirstRender }) {
    const prerequisites = [];
    if (isFirstRender) {
      prerequisites.push(dispatch(fetchSummariesDS({ url, componentName, authedAxios })));
    }
    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'NavBarNotificationsList';
  }

  constructor(props) {
    super(props);
    this.dropdownOpts = this.dropdownOpts.bind(this);
  }

  dropdownOpts() {
    const { activeOrg, dispatch, notifications, onClose } = this.props;

    const onItemClick = async (n) => {
      if (!n.is_read) {
        await dispatch(markReadNotificationDS({ values: { [n.id]: true } }));
      }
      onClose();
    };

    const items = notifications.map(n => {
      const isExternal = isExternalUrl(n.targetUrl);
      const url = n.targetUrl ? makeRelative(n.targetUrl) : notificationListUrl(activeOrg.alias);
      return (
        <li key={n.id}>
          <Link
            onClick={() => onItemClick(n)}
            to={isExternal ? { pathname: url } : url}
            className={`navbar-notifications-list__item ${n.is_read ? 'seen' : ''}`}
            target={isExternal ? '_blank' : null}
            rel="noopener noreferrer"
          >
            <i className={`navbar-notifications-list__icon ${n.icon}`} />

            <div>
              <p dangerouslySetInnerHTML={{ __html: n.summary }} />
              <div className="navbar-notifications-list__meta">
                <TimeAgo datetime={n.created_at} live={false} />
                {' by '}
                <strong>{ n.org.name }</strong>
                .
              </div>
            </div>
          </Link>
        </li>
      );
    });

    return (
      <>
        <ul className="navbar-notifications-list">
          {items}
        </ul>

        {items.length === 0 && (
          <div className="empty-list-message text-center d-flex flex-column align-items-center justify-content-center">
            <img width="40" alt="Notifications" src="/img/notification-icon.png" />
            <p className="mt-3">
              No recent Notifications!
            </p>
            <div className="text-center mt-4">
              <Link
                onClick={onClose}
                to={notificationListUrl(activeOrg.alias)}
                className="discreet d-inline p-0"
              >
                Click here to see all notifications
              </Link>
            </div>
          </div>
        )}

        {items.length > 0 && (
          <div>
            <Link
              onClick={onClose}
              className="navbar-notifications-list__see-all"
              to={notificationListUrl(activeOrg.alias)}
            >
              See all
            </Link>
          </div>
        )}
      </>
    );
  }

  render() {
    return (
      <TDApiConnected persistent duck="notifications" component={this.constructor} loadingEnabled={false} className="btn-group">
        {this.dropdownOpts()}
      </TDApiConnected>
    );
  }
}

NavBarNotificationsList.propTypes = {
  activeOrg: orgSpec.isRequired,
  notifications: PropTypes.arrayOf(notificationSpec),
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

NavBarNotificationsList.defaultProps = {
  notifications: [],
};

const mapStateToProps = (state) => {
  const summaryState = selectSummaries(state);
  return {
    activeOrg: selectActiveOrg(state),
    notifications: summaryState.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const NavBarNotificationsListConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBarNotificationsList);

export default withRouter(NavBarNotificationsListConnect);
