import axios from 'core/assets/js/lib/tdAxios';
import { toastr } from 'react-redux-toastr';
import { SubmissionError } from 'redux-form';

import {
  projectApplicationRejectApiUrl,
  projectApplicationAcceptApiUrl,
  projectApplicationCancelApiUrl,
} from 'projects/urls';

// Action types
export const PROJECT_APPLICATION_IS_LOADING = 'redux-project-application/IS_LOADING';
export const PROJECT_APPLICATION_ACCEPT_SUCCESS = 'redux-project-application/ACCEPT_SUCCESS';
export const PROJECT_APPLICATION_REJECT_SUCCESS = 'redux-project-application/REJECT_SUCCESS';
export const PROJECT_APPLICATION_LOAD_FAILURE = 'redux-project-application/LOAD_FAILURE';

export const projectApplicationIsLoadingAC = (bool, componentName) => ({
  type: PROJECT_APPLICATION_IS_LOADING,
  componentName,
  isLoading: bool,
});

export const projectApplicationLoadFailureAC = (error, componentName) => {
  const action = {
    type: PROJECT_APPLICATION_LOAD_FAILURE,
    componentName,
    error,
  };
  return action;
};

export const projectApplicationRejectSuccessAC = application => ({
  type: PROJECT_APPLICATION_REJECT_SUCCESS,
  item: application,
});

export const projectApplicationAcceptSuccessAC = application => ({
  type: PROJECT_APPLICATION_ACCEPT_SUCCESS,
  item: application,
});

export const rejectProjectApplicationDS = (
  orgAlias, projectId, applicationId, values, url = '',
) => (dispatch) => {
  dispatch(projectApplicationIsLoadingAC(true));
  const apiUrl = projectApplicationRejectApiUrl(orgAlias, projectId, applicationId, url);
  return axios.put(apiUrl, values)
    .then((resp) => {
      dispatch(projectApplicationRejectSuccessAC(resp.data));
      toastr.success('Well Done!', 'Application rejected successfully.');
    })
    .catch((err) => {
      dispatch(projectApplicationLoadFailureAC(err));
      toastr.error('Oh Snap!', err.response.data._error);
      throw new SubmissionError({ reason: err.response.data.reason });
    });
};

export const acceptProjectApplicationDS = (
  orgAlias, projectId, applicationId, url = '',
) => (dispatch) => {
  dispatch(projectApplicationIsLoadingAC(true));
  const apiUrl = projectApplicationAcceptApiUrl(orgAlias, projectId, applicationId, url);
  return axios.put(apiUrl)
    .then((resp) => {
      dispatch(projectApplicationAcceptSuccessAC(resp.data));
      toastr.success('Well Done!', 'Application accepted successfully.');
    })
    .catch((err) => {
      toastr.error('Oh Snap!', err.response.data._error);
      dispatch(projectApplicationLoadFailureAC(err));
    });
};

export const cancelProjectApplicationDS = (
  orgAlias, projectId, applicationId, values, url = '',
) => (dispatch) => {
  dispatch(projectApplicationIsLoadingAC(true));
  const apiUrl = projectApplicationCancelApiUrl(orgAlias, projectId, applicationId, url);
  return axios.delete(apiUrl, { data: values })
    .then((resp) => {
      dispatch(projectApplicationRejectSuccessAC(resp.data));
      toastr.success('Well Done!', 'Application canceled successfully.');
    })
    .catch((err) => {
      dispatch(projectApplicationLoadFailureAC(err));
      toastr.error('Oh Snap!', err.response.data._error);
      throw new SubmissionError({ reason: err.response.data.reason });
    });
};
