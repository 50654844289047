import React from 'react';
import PropTypes from 'prop-types';

import ChartBasicLine from 'core/assets/js/components/ChartBasicLine.jsx';
import ProjectChartBasicLineSkeleton from 'projects/assets/js/components/skeletons/ProjectChartBasicLineSkeleton.jsx';
import { DATE_DURATION, DATE_FORMAT_CHARTS } from 'core/assets/js/constants';

const ChartLine = ({
  analytics, chartName, currencySymbol, emptyText, selectedDateDuration,
}) => (
  <React.Fragment>
    { analytics.length > 0 && (
      <ChartBasicLine
        chartData={analytics}
        currencySymbol={currencySymbol}
        tooltip={{ xDateFormat: selectedDateDuration === DATE_DURATION.CURRENT_MONTH ? DATE_FORMAT_CHARTS : '%b %y' }}
        chartName={chartName}
      />
    )}
    { analytics.length === 0 && (
      <ProjectChartBasicLineSkeleton
        noDataMsg={emptyText}
        noData
      />
    )}
  </React.Fragment>
);

ChartLine.propTypes = {
  analytics: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  chartName: PropTypes.string,
  emptyText: PropTypes.string,
  selectedDateDuration: PropTypes.number,
};

ChartLine.defaultProps = {
  chartName: '',
  emptyText: null,
  selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
};

export default ChartLine;
