import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const TalentCardSkeleton = ({ className }) => (
  <div className="col-xl-3 col-md-4 col-sm-6 col-xs-12 pl-0 pt-0 pr-3 pb-3">
    <Card className="user-item freelancer-item col-12">
      <div
        className={['skeleton--people-list-item clearfix', className].join(' ')}
      >
        <div className="row d-flex align-items-center position-relative">
          <div className="col-12 d-flex justify-content-center my-3">
            <CircleSkeleton className="mr-0" diameter="100" />
          </div>
        </div>
        <div className="user-item__title mb-3">
          <RectangleSkeleton height="24" width="183" />
        </div>
        <div className="user-item__basic-info d-flex">
          <div className="user-item__extra discreet col-auto px-0 mb-2 text-center">
            <RectangleSkeleton height="16" width="80" />
            <br />
          </div>
        </div>
        <div className="text-center my-5 w-100">
          <RectangleSkeleton height="14" width="50" />
        </div>
      </div>
      <Card.Footer className="bg-white row">
        <RectangleSkeleton className="my-2" height="20" width="140" />
        {' '}
        <br />
        <RectangleSkeleton className="mr-1" height="24" width="50" />
        <RectangleSkeleton className="mr-1" height="24" width="80" />
        <RectangleSkeleton className="mr-1" height="24" width="50" />
        {' '}
        <br />
        <div className="row mt-4 mb-2 mx-0">
          <div className="col-4 pl-0">
            <RectangleSkeleton className="user-item__small" height="16" width="50" />
          </div>
          <div className="col-8 text-right pr-0">
            <RectangleSkeleton height="24" width="70" />
          </div>
        </div>
      </Card.Footer>
    </Card>
  </div>
);

TalentCardSkeleton.propTypes = {
  className: PropTypes.string,
};

TalentCardSkeleton.defaultProps = {
  className: '',
};

export default TalentCardSkeleton;
