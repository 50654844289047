import React from 'react';
import PropTypes from 'prop-types';

import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import FileUploader from 'core/assets/js/components/FileUploader.jsx';
import Filebox from 'core/assets/js/components/FileUploader/Filebox.jsx';


const FileUploaderField = ({
  input, required, sublabel, label, additionalError, softDelete, meta, uploaderWrapperClassName,
  attachments, 'data-testid': dataTestId, maxFiles, ...rest
}) => {
  const { error, pristine } = meta;
  const hasError = error || additionalError;
  const groupClassName = ['form-group'];
  if (hasError) {
    groupClassName.push('has-error');
  }

  const attachmentLinks = attachments
    ? attachments.map(file => (
      <Filebox key={file.handle} file={file} />
    ))
    : [];

  return (
    <div
      className={groupClassName.join(' ')}
      data-testid={dataTestId}
    >
      { label && (
        <label htmlFor={input.name}>
          {label}
          {required && ' *'}
          {maxFiles && ` (Maximum ${maxFiles} files`}
          {sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
      )}
      <ul className="attachments-list">
        {attachmentLinks}
      </ul>

      <FileUploader
        reduxInput={input}
        wrapperClassName={uploaderWrapperClassName}
        softDelete={softDelete}
        maxFiles={maxFiles}
        {...rest}
      />

      {pristine && additionalError && (
        <span className="help-block">
          {additionalError}
          {' - '}
          Please delete the old item in order to upload a new one
        </span>
      )}
      {hasError && <span className="help-block">{error}</span>}
    </div>
  );
};

FileUploaderField.propTypes = {
  'data-testid': PropTypes.string,
  maxFiles: PropTypes.number,
  input: reduxInputSpec.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  required: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    pristine: PropTypes.bool.isRequired,
  }),
  rest: PropTypes.object,
  additionalError: PropTypes.string,
  softDelete: PropTypes.bool,
  attachments: PropTypes.array,
  path: PropTypes.string,
  container: PropTypes.string,
  uploaderWrapperClassName: PropTypes.string,
};

FileUploaderField.defaultProps = {
  'data-testid': '',
  maxFiles: null,
  meta: {
    touched: false,
    error: false,
  },
  rest: {},
  sublabel: '',
  label: null,
  required: false,
  additionalError: '',
  softDelete: false,
  attachments: null,
  path: null,
  container: null,
  uploaderWrapperClassName: null,
};

export default FileUploaderField;
