import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { documentExternalSignatureApiUrl } from 'documents/urls';

const DocumentEsignConfirmationExternalView = ({ match: { params: { token } } }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }
    axios
      .post(documentExternalSignatureApiUrl(token))
      .catch(err => {
        toastr.error('Oh Snap!', err.response?.data?._error || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className={[
        'document-esign-external-container',
        'd-flex',
        'align-items-center',
        'justify-content-center',
      ].join(' ')}
    >
      <p>
        {loading && 'Loading'}
        {!loading && 'Thank you for signing, you can now close this window'}
      </p>
    </div>
  );
};

DocumentEsignConfirmationExternalView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(DocumentEsignConfirmationExternalView);
