import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link, withRouter } from 'react-router-dom';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import { Dropdown } from 'react-bootstrap';

import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { FIELD_ENTITY_TYPE_TO_URL_PARAM, ENTITY_TYPE_LABEL, FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { customFieldTemplateEditUrl } from 'settings/urls';
import { ICON } from 'core/assets/js/constants';


const CustomFieldsTemplateCard = ({ item, history, orgAlias, onDelete }) => {
  const { id, name, questions, entityType, usages } = item;
  const editUrl = customFieldTemplateEditUrl(
    orgAlias,
    FIELD_ENTITY_TYPE_TO_URL_PARAM[entityType],
    id);
  let templateIcon;

  switch (entityType) {
    case FIELD_ENTITY_TYPE.TASK:
      templateIcon = ICON.TASKS;
      break;
    case FIELD_ENTITY_TYPE.PROJECT:
      templateIcon = ICON.BROWSER;
      break;
    case FIELD_ENTITY_TYPE.USER:
    default:
      templateIcon = ICON.USER;
      break;
  }
  return (
    <div className="interview-list-item">
      <div className="row">
        <div className="col-9">
          <h3 className="interview-list-item__name">
            <TDElementWithTooltip
              delay={500}
              el={<i className={`${templateIcon} discreet mr-2 pr-1`} />}
              tooltipMsg={`${ENTITY_TYPE_LABEL[entityType]} template`}
            />
            <Link
              className="text-dark"
              to={editUrl}
            >
              {name}
            </Link>
          </h3>
          <span className="interview-list-item__meta">
            {pluralize('question', questions ? questions.length : 0, true)}
          </span>
        </div>

        <div className="col-3 text-right button-container">
          <TDDropButton>
            <AllowAccessToManager>
              <Dropdown.Item
                eventKey="2"
                onClick={() => history.push(editUrl)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={() => onDelete({ id, name, entityType, usages })}
              >
                <span className="text-danger">
                  Delete
                </span>
              </Dropdown.Item>
            </AllowAccessToManager>
          </TDDropButton>
        </div>
      </div>
    </div>
  );
};

CustomFieldsTemplateCard.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

export default withRouter(CustomFieldsTemplateCard);
