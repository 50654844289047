import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import 'react-dates/initialize';

import { formatDate } from 'core/assets/js/lib/utils';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import WorksheetBillingPeriodSelection from 'projects/assets/js/components/WorksheetBillingPeriodSelection.jsx';
import DateRangeCalendarControlField from 'core/assets/js/components/ReduxFormFields/DateRangeCalendarControlField.jsx';
import { BS_SIZE } from 'core/assets/js/constants';

export const PERIOD_MODAL_ID = 'period-selection-modal';

class WorksheetFormBillingPeriodModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleClosePeriodModal = this.handleClosePeriodModal.bind(this);
    this.handleBillingPeriodSelect = this.handleBillingPeriodSelect.bind(this);
  }

  handleClosePeriodModal() {
    const { dispatch, updateForm, formValues } = this.props;
    // If user has selected only periodStart, then make it a single day selection.
    if (!formValues.period.periodEnd) {
      updateForm(
        'period',
        {
          periodStart: formValues.period.periodStart,
          periodEnd: formValues.period.periodStart,
        },
      );
    }
    dispatch(modalCloseAC(PERIOD_MODAL_ID));
  }

  handleBillingPeriodSelect(selectedPeriod) {
    const { updateForm } = this.props;
    updateForm(
      'period',
      {
        periodStart: selectedPeriod.periodStart,
        periodEnd: selectedPeriod.periodEnd,
      },
    );
  }

  render() {
    const { formValues, isOutsideRange, isPeriodSelectModalOpen, missingPeriods } = this.props;
    const periodStart = formValues.period ? formValues.period.periodStart : null;
    const periodEnd = formValues.period ? formValues.period.periodEnd : null;

    return (
      <ModalSimple
        data-testid="worksheet-form-billing-period-modal"
        heading={(
          <React.Fragment>
            Billing period
            <aside>
              <small>
                {periodStart ? formatDate(periodStart) : '...'}
                {' - '}
                {periodEnd ? formatDate(periodEnd) : '...'}
              </small>
            </aside>
          </React.Fragment>
        )}
        size={BS_SIZE.XSMALL}
        open={isPeriodSelectModalOpen}
        onClose={this.handleClosePeriodModal}
        body={(
          <React.Fragment>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <Field
                name="period"
                component={DateRangeCalendarControlField}
                label=""
                required
                isOutsideRange={isOutsideRange}
              />
            </div>
            {missingPeriods && (
              <WorksheetBillingPeriodSelection
                missingPeriods={missingPeriods}
                onSelect={this.handleBillingPeriodSelect}
                selectedPeriod={{
                  periodStart,
                  periodEnd,
                }}
              />
            )}
          </React.Fragment>
        )}
      />
    );
  }
}

WorksheetFormBillingPeriodModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  isOutsideRange: PropTypes.func,
  isPeriodSelectModalOpen: PropTypes.bool.isRequired,
  missingPeriods: PropTypes.arrayOf(PropTypes.object),
  updateForm: PropTypes.func.isRequired,
};

WorksheetFormBillingPeriodModal.defaultProps = {
  formValues: {},
  isOutsideRange: () => false,
  missingPeriods: null,
};

const mapStateToProps = state => ({
  isPeriodSelectModalOpen: getIsModalOpen(state, PERIOD_MODAL_ID),
});

const WorksheetFormBillingPeriodModalReduxConnected = connect(
  mapStateToProps,
)(WorksheetFormBillingPeriodModal);

export default WorksheetFormBillingPeriodModalReduxConnected;
