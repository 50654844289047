import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { SubmissionError, Field } from 'redux-form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';


const TestForm = ({ handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit}>
    <Field name="email" type="text" component={InputField} label="Email" placeholder="" />
    {submitting && (
      <p>Submitting...</p>
    )}
  </form>
);

TestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

class ModalsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.confirmModalDefaultState = {
      open: false,
      heading: '-',
      body: '-',
      // Date Range Picker
      dateRangeDateStart: null,
      dateRangeDateEnd: null,
      dateRangeFocusedInput: null,
      // Single Date Picker
      sigleDatePickerFocused: false,
      // Date Range Controller
      dateRangeControllerFocusedInput: null,
      confirmLabel: 'Ok',
      confirmStyle: 'primary',
    };


    this.handleCloseModalConfirm = this.handleCloseModalConfirm.bind(this);
    this.handleConfirmModalConfirm = this.handleConfirmModalConfirm.bind(this);
    this.handleOpenModalConfirm = this.handleOpenModalConfirm.bind(this);
  }

  handleCloseModalConfirm() {
    this.setState({
      confirmModal: this.confirmModalDefaultState,
    });
  }

  /**
   * Invoked when the user clicks on the confirm button
   *
   * It should return a promise
   * @returns {Promise}
   */
  handleConfirmModalConfirm() {
    return new Promise(async (resolve, reject) => {
      try {
        // Do an API call if needed, update the state and return
        this.setState({
          confirmModal: this.confirmModalDefaultState,
        });

        return resolve();
      } catch (err) {
        return reject(err);
      }
    });
  }

  /**
   * Open the invitation modal and provide its content
   *
   * @param heading
   * @param body
   * @param confirmLabel
   * @param confirmStyle
   * @param randomVar
   */
  handleOpenModalConfirm(heading, body, confirmLabel, confirmStyle, randomVar) {
    this.setState({
      // Data used by the invitation modal
      confirmModal: {
        body,
        confirmLabel,
        confirmStyle,
        heading,
        open: true,
        payload: {
          randomVar,
        },
      },
    });
  }

  render() {
    const { confirmModal, modalFormOpen } = this.state;
    const ModalForm = withModalForm({
      heading: 'a heading',
      form: 'testForm',
    })(TestForm);
    return (
      <section id="modals">
        <h2>Modals</h2>

        <div className="row">
          <div className="col-12 col-sm-4 mt-4 text-center">
            <Button
              variant="primary"
              onClick={() => {
                this.handleOpenModalConfirm(
                  'Were you doing something?',
                  (<div>Hey, you are about to close this window, are you sure?</div>),
                  'Yes!', 'primary', 'Random string',
                );
              }}
            >
              Show Default
            </Button>
          </div>
          <div className="col-12 col-sm-4 mt-4 text-center">
            <Button
              variant="danger"
              onClick={() => {
                this.handleOpenModalConfirm(
                  'Are you sure about it?',
                  (<div>Hey, you are about to do something dangerous, are you sure?</div>),
                  'Confirm', 'danger', 'Random number 1',
                );
              }}
            >
              Show Danger
            </Button>
          </div>
          <div className="col-12 col-sm-4 mt-4 text-center">
            <Button
              variant="success"
              onClick={() => {
                this.handleOpenModalConfirm(
                  'You are one click away',
                  (<div>Make sure you want to &quot;Approve&quot; this action</div>),
                  'Approve', 'success', ['random', 'array'],
                );
              }}
            >
              Show Success
            </Button>
          </div>

          <div className="static-modal">
            <ModalConfirm
              onClose={this.handleCloseModalConfirm}
              onConfirm={this.handleConfirmModalConfirm}
              {...confirmModal}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-4 mt-4 text-center">
            <ModalForm
              isModalOpen={modalFormOpen}
              onClose={() => this.setState({ modalFormOpen: false })}
              onSubmit={(values) => {
                if (!values.email) {
                  throw new SubmissionError({ _error: 'stop', email: 'Email cannot be empty' });
                }
                return new Promise(resolve => setTimeout(resolve, 2000));
              }}
              form="my-test"
            />
            <Button variant="warning" onClick={() => this.setState({ modalFormOpen: true })}>
              Show Managed Form
            </Button>
          </div>
          <div className="col-12 col-sm-4 mt-4 text-center">
            <ModalForm
              onSubmit={(values) => {
                if (!values.email) {
                  throw new SubmissionError({ _error: 'stop', email: 'Email cannot be empty' });
                }
                return new Promise(resolve => setTimeout(resolve, 2000));
              }}
              triggerEl={(
                <Button variant="warning">
                  Show Unmanaged Form
                </Button>
              )}
              form="my-test-2"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default ModalsSection;
