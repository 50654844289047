import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { profileSettingsUrl } from 'accounts/urls';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import ProfileExperienceForm from 'accounts/assets/js/components/ProfileExperienceForm.jsx';
import { editExperienceDS } from 'accounts/assets/js/data-services/form';
import { fetchExperienceDS } from 'accounts/assets/js/data-services/profiles';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ProfileExperienceFormSkeleton from 'accounts/assets/js/components/skeletons/ProfileExperienceFormSkeleton.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class ProfileEditExperienceView extends React.Component {
  static GetComponentName() {
    return 'ProfileEditExperienceView';
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    const { dispatch, match } = this.props;
    const experienceId = match.params.experienceId;
    return dispatch(
      editExperienceDS(experienceId, values, ProfileEditExperienceView.GetComponentName()),
    ).then(() => {
      toastr.success('Well Done!', 'Your experience was updated successfully.');
    });
  }

  render() {
    const { activeOrg, history, item } = this.props;
    const breadcrumbs = [
      {
        title: 'Profile',
        url: profileSettingsUrl(activeOrg.alias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION),
      }, {
        title: 'Experience',
        url: profileSettingsUrl(activeOrg.alias, PROFILE_SETTINGS_TABS.EXPERIENCE),
      }, {
        title: 'Edit your experience',
        url: null,
      },
    ];

    let experienceInitialValues = {};
    if (item) {
      experienceInitialValues = {
        company: item.company,
        period_end: item.periodEnd,
        period_start: item.periodStart,
        is_current_position: item.isCurrentPosition,
        description: item.description,
        attachments: item.attachments,
        skills: item.skills,
      };
    }
    return (
      <>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--settings">
          <div className="container">
            <ProfileExperienceForm
              initialValues={experienceInitialValues}
              onSubmit={this.onSubmit}
              onSubmitSuccess={() => {
                history.push(profileSettingsUrl(activeOrg.alias, PROFILE_SETTINGS_TABS.EXPERIENCE));
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

ProfileEditExperienceView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  item: PropTypes.object,
  match: PropTypes.object.isRequired,
};

ProfileEditExperienceView.defaultProps = {
  item: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const ProfileEditExperienceViewConnected = connect(mapStateToProps)(ProfileEditExperienceView);

const ProfileEditExperienceViewApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, componentName, querystring, params, url,
  }) => dispatch(fetchExperienceDS({
    authedAxios, componentName, querystring, experienceId: params.experienceId, url,
  })),
  duck: 'view',
  storeKey: 'ProfileEditExperienceView',
  skeletonComponent: ProfileExperienceFormSkeleton,
})(ProfileEditExperienceViewConnected);

export default withRouter(ProfileEditExperienceViewApiConnected);
