import React from 'react';
import { withRouter } from 'react-router-dom';

import { BS_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { uploaderProjectWorksheetsCsvPath } from 'core/urls';
import { WORKSHEET_IMPORT_LIMIT } from 'projects/assets/js/constants';

const ProjectWorksheetImporterUploadTemplate = (
  { match: { params: { orgAlias, id: projectId } } },
) => (
  <div>
    <h2>
      Upload template
      {' '}
      {orgAlias}
    </h2>
    <div className="import-worksheets-page--wrapper bg-light py-4 mx-0 mb-4 d-flex">
      <div className="import-worksheets-page--download--section mt-4">
        <FileUploaderField
          name="attachments_metadata"
          className="col-auto d-flex"
          invertedColors
          type="text"
          maxFiles={1}
          acceptFiles={['.csv', '.txt']}
          path={uploaderProjectWorksheetsCsvPath(orgAlias, projectId)}
          uploadRenderer={({ onPick }) => (
            <TDButton
              className="import-worksheets-page--download--btn"
              onClick={onPick}
              bsSize={BS_SIZE.LARGE}
              variant={BS_STYLE.PRIMARY}
              block
              label={(
                <span>
                  <span className={`${ICON.DOWNLOAD} mr-3`} />
                  Upload template
                </span>
              )}
            />
          )}
        />
      </div>
      <div className="import-worksheets-page--info--section mt-4">
        <ul className="import-worksheets-page--check--list">
          <li className="mb-2">
            <span className={`${ICON.SUCCESS} mr-2`} />
            The name of your file should end with either .csv or .txt.
          </li>
          <li className="mb-2">
            <span className={`${ICON.SUCCESS} mr-2`} />
            Your file&#39;s first row should include headers.
          </li>
          <li className="mb-2">
            <span className={`${ICON.SUCCESS} mr-2`} />
            Maximum worksheets to be uploaded at once are
            <strong>
              {` ${WORKSHEET_IMPORT_LIMIT}`}
            </strong>
            .
          </li>
          <li className="mb-2">
            <span className={`${ICON.SUCCESS} mr-2`} />
            All fields are required and should be filled in before uploading.
          </li>
        </ul>
      </div>
    </div>
  </div>
);

ProjectWorksheetImporterUploadTemplate.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ProjectWorksheetImporterUploadTemplate);
