import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

class TransferOwnershipModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUserId: props.ownerId,
      submitting: false,
    };

    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleUserSelected = this.handleUserSelected.bind(this);
  }

  async handleModalSubmit() {
    const { onOwnershipTransferred } = this.props;
    const { selectedUserId } = this.state;
    this.setState({ submitting: true });

    await onOwnershipTransferred(selectedUserId);

    this.handleModalClose();
    this.setState({ submitting: false });
  }

  handleModalClose() {
    const { dispatch, modalId, ownerId } = this.props;
    this.setState({ selectedUserId: ownerId, submitting: false });
    dispatch(modalCloseAC(modalId));
  }

  handleUserSelected(user) {
    this.setState({
      selectedUserId: user.id,
      submitting: false,
    });
  }

  renderModalBody() {
    const {
      users,
      ownerId,
      singleUserWarningTitle,
      singleUserWarningMessage,
      itemComponent: ItemComponent,
      itemComponentProps,
      ownershipWarning,
      ownershipWarningTitle,
    } = this.props;

    const isSingleUser = users.length === 1;

    const currentUser = users.find(u => u.id === ownerId);
    const otherUsers = users.filter(u => u.id !== ownerId);

    return (
      <div className="modal-change-ownership__body">
        {isSingleUser && (
          <TDSystemMessage
            type={BS_STYLE.WARNING}
            title={singleUserWarningTitle}
            className="mb-4"
          >
            {singleUserWarningMessage}
          </TDSystemMessage>
        )}

        {!isSingleUser && ownershipWarning && (
          <TDSystemMessage
            type={BS_STYLE.WARNING}
            title={ownershipWarningTitle}
            className="mb-4"
          >
            {ownershipWarning}
          </TDSystemMessage>
        )}

        {currentUser && <ItemComponent item={currentUser} {...itemComponentProps} />}

        <CheckableList
          emptyItemsIcon={ICON.USER_CROWN}
          emptyItemsMessage="There are no other managers yet"
          itemComponent={ItemComponent}
          itemComponentProps={itemComponentProps}
          items={otherUsers}
          onItemChecked={this.handleUserSelected}
        />
      </div>
    );
  }

  renderModalFooter() {
    const { submitting, selectedUserId } = this.state;
    const { ownerId, users } = this.props;
    const isDisabled = !selectedUserId
      || ownerId === selectedUserId
      || submitting
      || users.filter(u => u.id !== ownerId).length === 0;

    return (
      <div key="footer" className="d-flex">
        <TDButton
          type="submit"
          className="ml-auto"
          onClick={this.handleModalSubmit}
          variant={isDisabled ? BS_STYLE.DEFAULT : BS_STYLE.SUCCESS}
          disabled={isDisabled}
          label="Transfer Ownership"
        />
      </div>
    );
  }

  render() {
    const { isOpen, modalId, heading } = this.props;

    return (
      <ModalSimple
        key={`modal-${modalId}`}
        heading={heading}
        open={isOpen}
        body={this.renderModalBody()}
        onClose={this.handleModalClose}
        footer={this.renderModalFooter()}
      />
    );
  }
}

TransferOwnershipModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  ownerId: PropTypes.number,
  isOpen: PropTypes.bool,
  itemComponent: PropTypes.func.isRequired,
  itemComponentProps: PropTypes.object,
  singleUserWarningTitle: PropTypes.string,
  singleUserWarningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ownershipWarning: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ownershipWarningTitle: PropTypes.string,
  onOwnershipTransferred: PropTypes.func,
};

TransferOwnershipModal.defaultProps = {
  users: [],
  ownerId: null,
  itemComponentProps: {},
  singleUserWarningTitle: 'It looks like you’re the only one available',
  singleUserWarningMessage: null,
  ownershipWarning: null,
  ownershipWarningTitle: null,
  onOwnershipTransferred: () => {},
  isOpen: false,
};

const mapStateToProps = (state, props) => ({
  isOpen: getIsModalOpen(state, props.modalId),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const TransferOwnershipModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferOwnershipModal);

export default TransferOwnershipModalConnected;
