import axios from 'axios';
import { isNil } from 'lodash';
import { isFront } from 'core/assets/js/config/checks';
import Logger from 'core/assets/js/lib/Logger';
import Cookies from 'cookies-js';

const logger = new Logger('axios');
let defaultInstance = axios.create();

const hasInterceptors = instance => (
  !isNil(instance._requestInterceptor) && !isNil(instance._responseInterceptor)
);

export const initAxios = ({ instance, apiUrl, dispatch } = {}) => {
  if (instance._requestInterceptor) {
    instance.interceptors.request.eject(instance._requestInterceptor);
  }
  // Add a request interceptor
  Object.assign(instance, { _requestInterceptor: instance.interceptors.request.use((config) => {
    let token;
    if (Cookies.get) {
      token = Cookies.get('token');
    }
    const headers = config.headers;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const newConfig = {
      ...config,
      headers,
    };
    if (apiUrl) {
      newConfig.baseURL = apiUrl;
    }
    return newConfig;
  }, Promise.reject) });

  if (instance._responseInterceptor) {
    instance.interceptors.response.eject(instance._responseInterceptor);
  }
  // Add a response interceptor
  Object.assign(instance, { _responseInterceptor: instance.interceptors.response.use(
    response => response,
    (error) => {
      if (dispatch) {
        try {
          const { handleError } = require('core/assets/js/lib/errors');
          try {
            handleError(dispatch, error);
          } catch (e) {
            // TODO: do not catch this error if you want global SubmissionError
            logger.error(e);
          }
          return Promise.reject(error);
        } catch (err) {
          return Promise.reject(err);
        }
      } else {
        return Promise.reject(error);
      }
    },
  ) });

  if (!hasInterceptors(instance)) {
    throw new Error('cannot add interceptors');
  }
  return instance;
};

export const createAxios = ({ apiUrl, dispatch } = {}) => {
  defaultInstance = initAxios({ instance: defaultInstance, apiUrl, dispatch });

  if (!hasInterceptors(defaultInstance)) {
    throw new Error('cannot create intercepted instance of axios');
  }

  return defaultInstance;
};

const axiosProxy = new Proxy(defaultInstance, {
  get: (target, name) => {
    if (isFront()) {
      return target[name];
    }
    if (!hasInterceptors(target)) {
      throw new Error('trying to get axios before it has been initialized');
    }
    if (target[name]) {
      return target[name];
    }

    if (name === Symbol.toStringTag) {
      return obj => obj.toString();
    }

    throw new Error(`unknown property ${name}`);
  },
});

export default axiosProxy;
