import React from 'react';
import PropTypes from 'prop-types';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ProjectChartBasicLineSkeleton = ({ noData, noDataMsg }) => (
  <div
    className={`skeleton__chart-basic-line skeleton__chart-basic-line--with-grid ${noData && 'skeleton__chart-stacked--no-data'}`}
  >
    <div className="graph-container d-flex">
      <div
        className="y-axis d-flex flex-column align-items-center justify-content-between my-4"
      >
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
      </div>
      <div
        className={`data-area d-flex flex-direction-column ${noData ? 'align-items-center' : 'align-items-end'} justify-content-around my-4 mr-4`}
      >
        {noData && (
          <div className="discreet chart-empty__text--line">{noDataMsg}</div>
        )}
        {!noData && (
          <React.Fragment>
            <div className="chart-line chart-line--first" />
            <div className="chart-line-connector chart-line-connector--first" />
            <div className="chart-line chart-line--second" />
            <div className="chart-line-connector chart-line-connector--second" />
            <div className="chart-line chart-line--third" />
            <div className="chart-line-connector chart-line-connector--third" />
            <div className="chart-line chart-line--fourth" />
            <div className="chart-line-connector chart-line-connector--fourth" />
            <div className="chart-line chart-line--fifth" />
            <div className="chart-line-connector chart-line-connector--fifth" />
          </React.Fragment>
        )}
      </div>
    </div>
  </div>
);

ProjectChartBasicLineSkeleton.propTypes = {
  noData: PropTypes.bool,
  noDataMsg: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ProjectChartBasicLineSkeleton.defaultProps = {
  noData: false,
  noDataMsg: 'No data to display',
};

export default ProjectChartBasicLineSkeleton;
