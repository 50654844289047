import { pick } from 'lodash';
import React from 'react';
import { useFormState } from 'react-final-form';

import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { USER_TYPE, USER_TYPE_LABEL } from 'core/assets/js/constants';
import { TYPE } from 'interviews/assets/js/constants';

const getChoices = payload => {
  const choices = payload.choices ? payload.choices.slice(0) : [];
  if (payload.has_other) {
    choices.push({ text: 'Other', value: payload.choices.length });
  }
  return choices;
};

const InterviewFormPreview = () => {
  const { submitError, values: { name, questions } } = useFormState();
  return (
    <div className="interview-builder interview-builder--preview">
      <h3>{name}</h3>
      {questions.map(question => {
        const canSubmit = !!question.allowedActions?.canSubmit;
        const answeredByUserType = question.answeredByUserType
          || question.answered_by_user_type || USER_TYPE.PROVIDER;
        const visibleTo = question.payload.visibleTo || question.visibleTo || [];
        const userTypeLabel = USER_TYPE_LABEL[answeredByUserType].toLowerCase();
        const commonProps = {
          ...pick(question, 'label', 'required'),
          disabled: true,
          name: question.path,
          sublabel: question.description,
        };
        return (
          <div
            className={`submission-question-wrap${!canSubmit ? ' disabled' : ''}`}
            key={question.path}
          >
            {question.type === TYPE.TEXT && (
              <>
                {question.payload.isTextArea && <TextAreaField {...commonProps} />}
                {!question.payload.isTextArea && <TextInputField {...commonProps} />}
              </>
            )}
            {question.type === TYPE.MONEY && <MoneyInputField {...commonProps} />}
            {question.type === TYPE.SELECT && (
              <>
                {question.payload.multiple && (
                  <CheckboxField {...commonProps} options={getChoices(question.payload)} />
                )}
                {!question.payload.multiple && (
                  <RadioField {...commonProps} options={getChoices(question.payload)} />
                )}
              </>
            )}
            {question.type === TYPE.FILE && (
              <>
                <FileUploaderField {...commonProps} />
                {question.payload.setExpiryDate && (
                  <DatePickerField
                    disabled
                    label="Expiry date"
                    name={`${question.path}-expires_at`}
                    required
                    sublabel="Set the expiry date of the uploaded document"
                  />
                )}
              </>
            )}
            {question.type === TYPE.DATE && <DatePickerField {...commonProps} />}
            {question.type === TYPE.YESNO && (
              <RadioField
                {...commonProps}
                options={[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]}
              />
            )}
            {!canSubmit && (
              <p className="warning">
                {`The above question will be filled in by the ${userTypeLabel}.`}
                <br />
                The answer will be visible to users of type:
                {` ${visibleTo.map(({ value }) => USER_TYPE_LABEL[value]).join(', ')}.`}
              </p>
            )}
          </div>
        );
      })}
      {submitError && <p className="text-danger">{submitError}</p>}
    </div>
  );
};

export default InterviewFormPreview;
