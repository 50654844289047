import { IMPORT_VIEW_TABS } from 'importer/assets/js/constants';

export const importsListUrl = orgAlias => `/${orgAlias}/imports`;
export const importViewUrl = (orgAlias, id, tab = IMPORT_VIEW_TABS.DETAILS) => (
  `${importsListUrl(orgAlias)}/${id}/${tab}`
);
export const importCreateUrl = orgAlias => `${importsListUrl(orgAlias)}/create`;

export const importsListApiUrl = orgAlias => `/api/${orgAlias}/imports`;
export const importApiUrl = (orgAlias, id) => `${importsListApiUrl(orgAlias)}/${id}`;
export const importRowsApiUrl = (orgAlias, id) => `${importApiUrl(orgAlias, id)}/rows`;

export const fetchCsvInfoApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/import/csv/info`);
export const fetchContactsCsvAnalysisApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/import/csv/contacts/results-analysis`);

export const importFetchErrorsApiUrl = (orgAlias, id) => `/api/${orgAlias}/import/${id}/errors`;
export const importFetchCancelledApiUrl = (orgAlias, id) => `/api/${orgAlias}/import/${id}/cancelled`;
export const importCreateApiUrl = orgAlias => `/api/${orgAlias}/import`;
export const importAnalyseCSVApiUrl = orgAlias => `/api/${orgAlias}/import/analyse-csv-headers`;
export const importDownloadTemplateApiUrl = (orgAlias, type) => `/api/${orgAlias}/import/${type}/template`;
export const importCancelApiUrl = (orgAlias, id) => `/api/${orgAlias}/import/${id}/cancel`;
export const importFetchCustomFieldsApiUrl = (orgAlias, type) => (
  `/api/${orgAlias}/import/${type}/custom-fields`
);
