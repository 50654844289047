import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { selectShouldHaveCompanyDetails } from 'accounts/assets/js/ducks/account';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { tncUrl, referralProgramUrl } from 'core/urls';
import { organizationsUrl } from 'organizations/urls';
import { ICON, IMG_SIZE } from 'core/assets/js/constants';
import { settingsAccountUrl } from 'settings/urls';
import { profileSettingsUrl, timeOffUrl } from 'accounts/urls';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { UNKNOWN_ALIAS } from 'organizations/assets/js/constants';

const NavBarAccountMenu = ({ activeOrg, activeUserCard, history, onClose, orgAlias, profile }) => {
  const { name, jobTitle, avatar } = profile;

  const onItemSelect = ({ path }) => {
    onClose();

    if (path) {
      history.push(path);
    }
  };

  return (
    <div className="px-3">
      <NavLink
        onClick={onItemSelect}
        to={activeUserCard.profileUrl || '/'}
        className="dropdown-item dropdown-item__profile account-drop-down__sub-item px-0 py-3"
      >
        <div className="d-flex px-3">
          <ProfilePic
            url={avatar}
            alt={name}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
            className="mr-4 align-self-center"
          />

          <div className="flex-grow-1 flex-shrink-1 w-auto overflow-hidden">
            <div className="w-100">
              <div title={name} className="truncate account-drop-down__name">{name}</div>
            </div>
            <div className="w-100">
              <div title={jobTitle} className="truncate account-drop-down__job-title">
                {jobTitle}
              </div>
            </div>
          </div>
        </div>
      </NavLink>

      <div className="account-menu">
        <div
          className="dropdown-item"
        >
          <i className={ICON.COG} />
          <strong>My Settings</strong>
        </div>

        <NavLink
          onClick={onItemSelect}
          to={settingsAccountUrl(orgAlias)}
          className="dropdown-item account-drop-down__sub-item"
        >
          Account
        </NavLink>

        <NavLink
          onClick={onItemSelect}
          to={profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION)}
          className="dropdown-item account-drop-down__sub-item"
        >
          Profile
        </NavLink>

        {activeOrg.show_availability && (
          <NavLink
            onClick={onItemSelect}
            to={timeOffUrl(orgAlias)}
            className="dropdown-item account-drop-down__sub-item"
          >
            Availability
          </NavLink>
        )}

        <NavLink
          onClick={onItemSelect}
          to={organizationsUrl(orgAlias)}
          className="dropdown-item"
        >
          <i className={ICON.BUILDING} />
          <strong>Organisations</strong>
        </NavLink>

        <a
          onClick={onItemSelect}
          href="/accounts/logout"
          className="dropdown-item"
        >
          <i className={ICON.LOGOUT} />
          <strong>Log out</strong>
        </a>

        <hr />

        <a
          onClick={onItemSelect}
          className="dropdown-item"
          rel="noopener noreferrer"
          target="_blank"
          href={referralProgramUrl()}
        >
          Referral program
        </a>

        <a
          onClick={onItemSelect}
          className="dropdown-item"
          rel="noopener noreferrer"
          target="_blank"
          href={tncUrl()}
        >
          Legal
        </a>
      </div>
    </div>
  );
};

NavBarAccountMenu.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec.isRequired,
  hasCompanyDetails: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  onClose: PropTypes.func,
  orgAlias: PropTypes.string,
  profile: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    jobTitle: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

NavBarAccountMenu.defaultProps = {
  onClose: () => {},
  orgAlias: UNKNOWN_ALIAS,
};

const mapStateToProps = (state) => {
  const activeUserCard = selectActiveUserCard(state);
  const profile = selectProfile(state);

  if (activeUserCard.profileUrl) {
    profile.url = activeUserCard.profileUrl;
  }

  return {
    activeOrg: selectActiveOrg(state),
    activeUserCard,
    hasCompanyDetails: selectShouldHaveCompanyDetails(state),
    profile: selectProfile(state),
  };
};

const NavBarAccountMenuConnect = connect(
  mapStateToProps,
)(NavBarAccountMenu);

export default withRouter(NavBarAccountMenuConnect);
