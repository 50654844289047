import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';

const HeaderCta = ({ ctaButtonItems, ctaComponent }) => {
  if (ctaComponent) {
    return ctaComponent;
  }
  if (!Array.isArray(ctaButtonItems) || ctaButtonItems.length === 0) {
    return null;
  }
  const firstButton = (
    <TDButton
      data-testid={`button-${ctaButtonItems[0].label}`}
      key={`header-cta-item-${ctaButtonItems[0].label}`}
      label={ctaButtonItems[0].label}
      onClick={ctaButtonItems[0].onClick}
      variant={ctaButtonItems[0].variant || BS_STYLE.DEFAULT}
    />
  );
  if (ctaButtonItems.length === 1) {
    return firstButton;
  }
  return (
    <Dropdown as={ButtonGroup} className="td-dropdown d-none d-md-inline-block">
      {firstButton}
      <Dropdown.Toggle split variant={ctaButtonItems[0].variant || BS_STYLE.DEFAULT}>
        <span className={ICON.CHEVRON_DOWN} />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        {ctaButtonItems.slice(1).map(({ label, onClick }) => (
          <Dropdown.Item key={`header-cta-item-${label}`} onClick={onClick}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

HeaderCta.propTypes = {
  ctaButtonItems: PropTypes.array,
  ctaComponent: PropTypes.node,
};

HeaderCta.defaultProps = {
  ctaButtonItems: null,
  ctaComponent: null,
};

export default HeaderCta;
