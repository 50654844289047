import React from 'react';
import PropTypes from 'prop-types';

import StatusTag from 'core/assets/js/components/StatusTag.jsx';

const ProjectStatus = ({ statusLabel }) => {
  const statusClass = statusLabel.replace(/\s+/g, '-').toLowerCase();
  return (
    <StatusTag label={statusLabel} statusClass={statusClass} />
  );
};

ProjectStatus.propTypes = {
  statusLabel: PropTypes.string.isRequired,
};

export default ProjectStatus;
