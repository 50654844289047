import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { BS_STYLE } from 'core/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const FORM_ID = 'invitation-public-join';

const InvitationPublicJoinForm = ({ handleSubmit, initialValues, error }) => {
  return (
    <form onSubmit={handleSubmit} method="POST">
      <div className="row">
        <div className="col-12">
          { !initialValues.sent_to && (
            <Field
              name="sent_to"
              type="email"
              component={InputField}
              label="Your email"
            />
          )}
          { initialValues.sent_to && (
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input id="email" type="email" readOnly value={initialValues.sent_to} className="form-control" />
              <Field
                name="sent_to"
                type="hidden"
                component={InputField}
                value={initialValues.sent_to}
              />
            </div>
          )}
          <Field name="captcha" type="captcha" component={InputField} />

          {error && (
            <div className="form-group has-error">
              <span className="help-block">{error}</span>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TDButton
            type="submit"
            variant={BS_STYLE.PRIMARY}
            label="Submit"
            // floatRight
          />
        </div>
      </div>
    </form>
  );
};

InvitationPublicJoinForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
};

InvitationPublicJoinForm.defaultProps = {
  initialValues: {},
  error: null,
};

const mapStateToProps = () => ({
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const InvitationPublicJoinFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationPublicJoinForm);

export default reduxForm({
  form: FORM_ID,
})(InvitationPublicJoinFormConnected);
