import React from 'react';
import { Card } from 'react-bootstrap';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import PeopleReviewsNotesTabSkeleton from 'core/assets/js/components/Skeleton/PeopleReviewsNotesTabSkeleton.jsx';

const PeopleProfileTabsSkeleton = () => (
  <Card className="user-profile__tabs d-flex flex-column align-self-md-stretch w-100">
    <Card.Header className="py-4 mb-4">
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <RectangleSkeleton className="py-3" width="230" height="13" />
      </div>
    </Card.Header>

    <PeopleReviewsNotesTabSkeleton />
  </Card>
);

export default PeopleProfileTabsSkeleton;
