import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import RedirectToHome from 'core/assets/js/components/RedirectToHome.jsx';
import { accessRequirementsSpec } from 'core/assets/js/lib/objectSpecs';
import { getCanRenderView, getDetermineHomeUrl } from 'accounts/assets/js/reducers/auth';
import { orgSelectedAC } from 'organizations/assets/js/actions/list';
import { selectHasFatalError } from 'core/assets/js/ducks/errors';
import { selectIsTrialExpired, selectSubscriptionsLoaded } from 'organizations/assets/js/reducers/subscriptions';
import { orgPlanStatusUrl } from 'organizations/urls';

/**
 * Wrapper that either renders its children or redirects to the homeUrl depending
 * on whether the user is authorized to view the children or not
 *
 * An edge case is then the user has a different active organisation than the one requested, or
 * none. In this case, we don't redirect, but refresh the redux state to activate the requested
 * organisation, and wait for the next render cycle to determine whether we should show
 * the contents or redirect him.
 *
 */
class AuthWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.determineState = memoize(
      ({ decideAuthState, targetOrgAlias, requirements, options }) => {
        return decideAuthState({ targetOrgAlias, requirements, options });
      },
    );
  }

  render() {
    const {
      children,
      decideAuthState,
      dispatch,
      isTrialExpired,
      hasFatalError,
      history,
      location,
      requirements,
      skipOrgSwitch,
      subscriptionsLoaded,
      targetOrgAlias,
    } = this.props;

    const computedState = this.determineState({
      decideAuthState,
      targetOrgAlias,
      requirements,
      options: { skipOrgSwitch },
    });
    const { shouldRedirect, shouldSwitchOrg, shouldRenderChildren } = computedState;

    if (shouldRedirect || hasFatalError) {
      return <RedirectToHome />;
    }

    if (shouldSwitchOrg) {
      // in case we should switch, just switch the active organisation
      dispatch(orgSelectedAC(targetOrgAlias));
    }
    const { location: { pathname: currentUrl } } = history;

    // IMPORTANT:
    // 1. If the AuthWrapper is instructed to switch org using a redirect, it **must** not render
    // the children or the children will access an outdated snapshot of redux store!
    // 2. AuthWrapper should render if the user should not switch and is authorized to view data of
    // active organisation
    if (!shouldRenderChildren) {
      return null;
    }

    return (
      <div id="auth-wrapper">
        {
        // If is in an expired trial period redirect to "Plan status" page.
        // Also check if already in "Plan status" page.
          isTrialExpired
        && subscriptionsLoaded
        && (location.pathname !== orgPlanStatusUrl(targetOrgAlias))
        && (
          <RedirectRoute status={307} from={currentUrl} to={orgPlanStatusUrl(targetOrgAlias)} />
        )}
        {children}
      </div>
    );
  }
}


AuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  decideAuthState: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isTrialExpired: PropTypes.bool.isRequired,
  hasFatalError: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  requirements: accessRequirementsSpec, //eslint-disable-line
  skipOrgSwitch: PropTypes.bool,
  subscriptionsLoaded: PropTypes.bool.isRequired,
  targetOrgAlias: PropTypes.string,
};

AuthWrapper.defaultProps = {
  children: null,
  hasFatalError: false,
  requirements: {
    requireAuth: false,
    requireFinController: false,
    requireManager: false,
    requireOrgCreator: false,
    requirePermission: false,
    requireProvider: false,
    requireUserIsApprovedOrgMember: false,
  },
  skipOrgSwitch: false,
  targetOrgAlias: null,
};

const mapStateToProps = state => ({
  decideAuthState: getCanRenderView(state),
  determineHomeUrl: getDetermineHomeUrl(state),
  isTrialExpired: selectIsTrialExpired(state),
  subscriptionsLoaded: selectSubscriptionsLoaded(state),
  hasFatalError: selectHasFatalError(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const AuthWrapperConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthWrapper);

export default withRouter(AuthWrapperConnect);
