import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import account from 'accounts/assets/js/ducks/account';
import auth from 'accounts/assets/js/reducers/auth';
import customFields from 'interviews/assets/js/ducks/customFields';
import dynamicFormReducer from 'core/assets/js/ducks/form';
import errors from 'core/assets/js/ducks/errors';
import invitation from 'invitations/assets/js/ducks/invitation';
import listReducer from 'core/assets/js/ducks/list';
import managers from 'people/assets/js/ducks/managers';
import modalLauncher from 'core/assets/js/ducks/modalLauncher';
import modalList from 'core/assets/js/ducks/modalList';
import modalView from 'core/assets/js/ducks/modalView';
import settings from 'core/assets/js/ducks/settings';
import notifications from 'notifier/assets/js/ducks/notifications';
import organizations from 'organizations/assets/js/reducers/organizations';
import pendingCount from 'core/assets/js/ducks/pendingCount';
import pendingInvitations from 'core/assets/js/ducks/pendingInvitations';
import people from 'people/assets/js/ducks/people';
import projects from 'projects/assets/js/reducers/projects';
import providers from 'people/assets/js/ducks/providers';
import requests from 'core/assets/js/ducks/requests';
import subscriptions from 'organizations/assets/js/reducers/subscriptions';
import viewReducer from 'core/assets/js/ducks/view';
import { reducer as toastrReducer } from 'react-redux-toastr';

const appReducers = combineReducers({
  account,
  auth,
  customFields,
  dynamicForm: dynamicFormReducer,
  errors,
  form: reduxFormReducer,
  invitation,
  list: listReducer,
  managers,
  modalLauncher,
  modalList,
  modalView,
  notifications,
  organizations,
  pendingCount,
  pendingInvitations,
  people,
  projects,
  providers,
  requests,
  settings,
  subscriptions,
  toastr: toastrReducer,
  view: viewReducer,
});

export default appReducers;
