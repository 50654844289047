import RoleCreateView from 'roles/assets/js/components/RoleCreateView.jsx';
import RoleEditView from 'roles/assets/js/components/RoleEditView.jsx';
import RolesView from 'roles/assets/js/components/RolesView.jsx';
import SystemRoleView from 'roles/assets/js/components/SystemRoleView.jsx';
import { LAYOUTS } from 'core/assets/js/constants';
import {
  roleCreateUrl,
  roleEditUrl,
  rolesListUrl,
  systemRoleViewUrl,
} from 'roles/urls';
import { ROLES_TABS, ROLE_TABS } from 'roles/assets/js/constants';

const documentsRouteConfig = [
  {
    component: RoleCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: roleCreateUrl(':orgAlias'),
  },
  {
    component: RoleEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: roleEditUrl(
      ':orgAlias',
      ':roleId',
      `:tab(${Object.values(ROLE_TABS).join('|')})`,
    ),
  },
  {
    component: RolesView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: rolesListUrl(':orgAlias', `:tab(${Object.values(ROLES_TABS).join('|')})`),
  },
  {
    component: SystemRoleView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: systemRoleViewUrl(
      ':orgAlias',
      ':systemRoleId',
      `:tab(${Object.values(ROLE_TABS).join('|')})`,
    ),
  },
];

export default documentsRouteConfig;
