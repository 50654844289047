import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import { signupCleanUrl } from 'accounts/urls';
import { HOMEPAGE_URL } from 'core/urls';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import LoginForm from 'accounts/assets/js/components/LoginForm.jsx';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import { IMG_SIZE, LOGO } from 'core/assets/js/constants';
import { orgPublicSpec } from 'organizations/assets/js/lib/objectSpecs';
import { getViewState } from 'core/assets/js/ducks/view';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { fetchPublicOrgInformation } from 'organizations/assets/js/data-services/organizations';


class LoginView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { orgAlias } = params;

    if (orgAlias) {
      return Promise.all([
        dispatch(fetchPublicOrgInformation({
          orgAlias,
          authedAxios,
          url,
          componentName,
        })),
      ]);
    }

    return Promise.resolve();
  }

  static GetComponentName() {
    return 'LoginView';
  }

  componentDidMount() {
    const { history, location: { search } } = this.props;
    const { next } = queryString.parse(search);
    if (new RegExp(`^${signupCleanUrl()}`).test(next)) {
      history.push(next);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { profile } = this.props;
    // When a guest user logs in and the profile loads in the store, it will not trigger a redirect
    // to the homeUrl (see check at the top of the render() fn, by stopping the component from
    // re-rendering. Notice that this does not stop the child component to re-render when there is
    // a change in its state
    return !(!profile && nextProps.profile);
  }

  render() {
    const { match, profile, organization } = this.props;

    // This is a logged in user, redirect to their homeUrl
    if (profile) {
      return (
        <RedirectRoute
          status={302}
          from={match.url}
          to={profile.homeUrl}
        />
      );
    }

    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
      >
        <div
          className="solo-container__logo-container mb-4"
        >
          {!isEmpty(organization) && (
            <React.Fragment>
              <OrganizationLogo
                url={organization.logo}
                orgName={organization.name}
                size={[IMG_SIZE.XLARGE, IMG_SIZE.XLARGE]}
              />

              <h3>{organization.orgName}</h3>
              <p className="text-center small">
                powered by
                {' '}
                <a href="/">Talentdesk.io</a>
              </p>
            </React.Fragment>
          )}

          {isEmpty(organization) && (
            <React.Fragment>
              <a className="solo-container__logo-container mb-4" href={HOMEPAGE_URL}>
                <img src={LOGO.DEFAULT} alt="TalentDesk.io" />
              </a>
            </React.Fragment>
          )}
        </div>

        <LoginForm
          orgAlias={organization.alias}
        />
      </TDApiConnected>
    );
  }
}

LoginView.propTypes = {
  history: routerHistorySpec.isRequired,
  location: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  organization: orgPublicSpec,
  profile: PropTypes.object,
};

LoginView.defaultProps = {
  location: {},
  organization: {},
  profile: null,
};

LoginView.getMetaTags = () => ({
  title: 'Login',
});

const mapStateToProps = state => ({
  organization: getViewState(state, LoginView.GetComponentName()).item,
  profile: selectProfile(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const LoginViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginView);

export default withRouter(LoginViewConnected);
