/* eslint-env browser */
/* eslint no-underscore-dangle: [
    "error", {
      "allow": ["__PRELOADED_STATE__", "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"],
    }]
*/

import React from 'react';
import ReactDom from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { objectFromBase64, generateTalentDeskGlobalObject } from 'core/assets/js/lib/utils';
import Logger from 'core/assets/js/lib/Logger';
import RootComponent from './components/RootComponent.jsx';
import { SENTRY, DOCUMENT_GET_ELEMENT_BY_ID, WINDOW_PRELOADED_STATE, WINDOW_SET_TALENTDESK_GLOBAL } from 'core/assets/js/config/settings';

const sentryLogger = new Logger('SENTRY');

/**
 * Create the redux store and enable Webpack hot module replacement for reducers
 *
 * @returns {Store<S>}
 */
/**
 * Render the view and wire all routes
 */
const $mount = DOCUMENT_GET_ELEMENT_BY_ID('mount-container');
const render = (Component) => {
  let initialState;
  if (WINDOW_PRELOADED_STATE) {
    initialState = objectFromBase64(WINDOW_PRELOADED_STATE);
  }

  // Set global object containing info for marketing purposes
  WINDOW_SET_TALENTDESK_GLOBAL(generateTalentDeskGlobalObject(initialState)); // eslint-disable-line

  const renderMethod = module.hot ? ReactDom.render : ReactDom.hydrate;
  renderMethod(
    <Component initialState={initialState} />,
    $mount,
  );
};

/**
 * Bootstrap the app
 *
 * - Do the initial rendering of the up
 * - Set up hot reload
 * - Set up the Sentry client optionally - enabled only on staging and production
 */
const launchApp = () => {
  if ($mount) {
    render(RootComponent);
  }
};

if (SENTRY.enabled && typeof Sentry !== 'undefined') {
  sentryLogger.info('Enabled');
  // Configure Sentry to report errors to Sentry
  Sentry.init({
    dsn: SENTRY.dsnPublic,
    release: SENTRY.release,
    environment: SENTRY.environment,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: SENTRY.tracesSampleRate,
  });
} else {
  sentryLogger.info('Disabled');
}

launchApp();
