import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { orderBy, isEmpty } from 'lodash';
import { getFormValues } from 'redux-form';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { getListState, listReplaceItemAC } from 'core/assets/js/ducks/list';
import { pluralize } from 'core/assets/js/lib/utils';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { taskAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  copyTaskAttachmentsDS, fetchProjectTaskDeliverablesDS, removeTaskAttachmentDS,
} from 'projects/assets/js/data-services/tasks';
import { modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload, updateModalPayloadAC } from 'core/assets/js/ducks/modalLauncher';
import UploadDeliverables from 'projects/assets/js/components/UploadDeliverables.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import TaskAttachmentCard from 'projects/assets/js/components/TaskAttachmentCard.jsx';
import TaskDeliverablesCopySelection, { FORM_ID as COPY_ATTACHMENTS_FORM_ID } from 'projects/assets/js/components/TaskDeliverablesCopySelection.jsx';

export const COPY_MODAL_ID = 'copy-modal';

class DeliverablesList extends React.Component {
  static GetComponentName() {
    return 'DeliverablesList';
  }

  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { orgAlias, id: projectId, taskId } = params;
    return dispatch(
      fetchProjectTaskDeliverablesDS({
        orgAlias, projectId, taskId, authedAxios, url, componentName,
      }),
    );
  }

  constructor(props) {
    super(props);

    this.handleDeliverablesCopy = this.handleDeliverablesCopy.bind(this);
    this.refreshDeliverables = this.refreshDeliverables.bind(this);
    this.handleDeliverablePinToggled = this.handleDeliverablePinToggled.bind(this);
    this.handleCopyModalOpen = this.handleCopyModalOpen.bind(this);
    this.handleCopyModalClose = this.handleCopyModalClose.bind(this);
    this.handleDeliverableRemoved = this.handleDeliverableRemoved.bind(this);
  }

  async refreshDeliverables() {
    const {
      dispatch,
      parentComponentName,
      onInfoUpdated,
      match: { params: { orgAlias, id: projectId, taskId } },
    } = this.props;

    await dispatch(fetchProjectTaskDeliverablesDS({
      orgAlias,
      projectId,
      taskId,
      componentName: this.constructor.GetComponentName(),
      parentComponentName,
    }));

    await onInfoUpdated();
  }

  async handleDeliverablesCopy() {
    const {
      dispatch,
      match: { params: { orgAlias, id: projectId, taskId } },
      copyAttachmentsFormValues,
      parentComponentName,
    } = this.props;

    if (!isEmpty(copyAttachmentsFormValues.attachments)) {
      dispatch(updateModalPayloadAC(COPY_MODAL_ID, {
        copying: true,
        deliverablesCount: copyAttachmentsFormValues.attachments.length,
      }));
    }

    await dispatch(
      copyTaskAttachmentsDS({
        orgAlias, projectId, taskId, values: copyAttachmentsFormValues, parentComponentName,
      }),
    );

    dispatch(updateModalPayloadAC(COPY_MODAL_ID, { copied: true }));
  }

  handleDeliverablePinToggled(item) {
    const { dispatch } = this.props;
    dispatch(listReplaceItemAC(item, this.constructor.GetComponentName()));
  }

  handleDeliverableRemoved(item) {
    const { dispatch, match: { params: { orgAlias, id: projectId, taskId } } } = this.props;
    dispatch(removeTaskAttachmentDS({
      attachmentId: item.id,
      componentName: this.constructor.GetComponentName(),
      orgAlias,
      projectId,
      taskId,
    }));
  }

  handleCopyModalOpen(selections) {
    const { dispatch } = this.props;

    dispatch(modalOpenAC(COPY_MODAL_ID, {
      deliverables: Array.isArray(selections) ? selections : [selections],
    }));
  }

  handleCopyModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(COPY_MODAL_ID));
  }

  render() {
    const {
      deliverables,
      isCopyModalOpen,
      copyModalPayload,
      allowedActions,
      isManager,
      match: { params: { taskId } },
    } = this.props;

    const attachments = orderBy(deliverables, ['isPinned', 'createdAt'], ['desc', 'desc']);

    const bulkActions = [{
      label: 'Clone Deliverables',
      handleClick: selectedItems => this.handleCopyModalOpen(selectedItems),
    }];

    return (
      <React.Fragment>
        <SelectableListWrapper
          componentName={this.constructor.GetComponentName()}
          fetchData={this.constructor.FetchData}
          skeletonComponent={ProjectListSkeleton}
          selectable={isManager}
          className="task-deliverables__list"
          additionalContent={allowedActions.canUploadDeliverables && (
            <UploadDeliverables
              onDeliverablesUploaded={this.refreshDeliverables}
            />
          )}
          cardItem={{
            component: TaskAttachmentCard,
            props: {
              onDeliverableCopied: item => this.handleCopyModalOpen([item]),
              onDeliverablePinToggled: this.handleDeliverablePinToggled,
              onDeliverableStatusToggled: this.refreshDeliverables,
              onRemoved: this.handleDeliverableRemoved,
              showDate: true,
              showPin: true,
            },
          }}
          emptyListMessage="There aren’t any deliverables, yet"
          bulkActions={bulkActions}
          items={attachments}
        />
        {!isEmpty(copyModalPayload) && (
          <ModalConfirm
            heading="Copy as attachments to task"
            confirmLabel="Copy"
            cancelLabel={(copyModalPayload.copying || copyModalPayload.copied) ? 'Close' : 'Cancel'}
            open={isCopyModalOpen}
            closeOnConfirm={false}
            onClose={this.handleCopyModalClose}
            onConfirm={this.handleDeliverablesCopy}
            showConfirmButton={!copyModalPayload.copying && !copyModalPayload.copied}
          >
            {copyModalPayload.deliverables && (
              <TaskDeliverablesCopySelection
                attachments={copyModalPayload.deliverables || []}
                initialValues={{
                  task_id: +taskId,
                  attachments: (copyModalPayload.deliverables || []).map(att => +att.id),
                }}
              />
            )}

            {copyModalPayload.copying && (
              <p>
                Copying
                {` ${copyModalPayload.deliverablesCount}`}
                {` ${pluralize('deliverable', copyModalPayload.deliverablesCount)} `}
                to the target task.
              </p>
            )}

            {copyModalPayload.copied && (
              <p>
                {`The ${pluralize('deliverable', copyModalPayload.deliverablesCount)} `}
                copied successfully and attached to the target task.
              </p>
            )}
          </ModalConfirm>
        )}
      </React.Fragment>
    );
  }
}

DeliverablesList.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  deliverables: PropTypes.arrayOf(PropTypes.object),
  copyModalPayload: PropTypes.object,
  isCopyModalOpen: PropTypes.bool,
  isManager: PropTypes.bool,
  copyAttachmentsFormValues: PropTypes.object,
  allowedActions: taskAllowedActionsSpec,
  parentComponentName: PropTypes.string,
  onInfoUpdated: PropTypes.func,
};

DeliverablesList.defaultProps = {
  deliverables: [],
  isCopyModalOpen: false,
  isManager: false,
  copyModalPayload: {},
  copyAttachmentsFormValues: {},
  allowedActions: {},
  parentComponentName: null,
  onInfoUpdated: () => {},
};

const mapStateToProps = (state) => {
  const hasOrgAccess = getHasOrgAccess(state);

  return {
    deliverables: getListState(state, DeliverablesList.GetComponentName()).items,
    isCopyModalOpen: getIsModalOpen(state, COPY_MODAL_ID),
    copyModalPayload: getModalPayload(state, COPY_MODAL_ID) || {},
    copyAttachmentsFormValues: getFormValues(COPY_ATTACHMENTS_FORM_ID)(state) || {},
    isManager: hasOrgAccess({ requireManager: true }),
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const DeliverablesListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliverablesList);

export default withRouter(DeliverablesListConnected);
