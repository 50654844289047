import React from 'react';
import PropTypes from 'prop-types';

const Root = (props) => {
  const {
    alignItemsCenter,
    children,
    darkBackground,
    flexContainer,
    flexVertically,
    justifyContentCenter,
  } = props;

  const className = ['root-container'];
  if (flexContainer) {
    className.push('d-flex');
  }
  if (flexVertically) {
    className.push('flex-column');
  }
  if (alignItemsCenter) {
    className.push('align-items-center');
  }
  if (justifyContentCenter) {
    className.push('justify-content-center');
  }
  if (darkBackground) {
    className.push('dark-background');
  }
  return (
    <div className={className.join(' ')}>
      {children}
    </div>
  );
};

Root.propTypes = {
  alignItemsCenter: PropTypes.bool,
  children: PropTypes.node,
  darkBackground: PropTypes.bool,
  flexContainer: PropTypes.bool,
  flexVertically: PropTypes.bool,
  justifyContentCenter: PropTypes.bool,
};

Root.defaultProps = {
  alignItemsCenter: false,
  children: null,
  darkBackground: false,
  flexContainer: false,
  flexVertically: false,
  justifyContentCenter: false,
};

export default Root;
