import { toastr } from 'react-redux-toastr';

import axios from 'core/assets/js/lib/tdAxios';

import { httpErrorAC } from 'core/assets/js/ducks/errors';
import {
  fetchNotificationsSummariesApiUrl,
  markAsSeenNotificationApiUrl,
  markReadNotificationApiUrl,
  markAllNotificationsAsReadApiUrl,
  fetchPreviewApiUrl,
  fetchPreviewByIdApiUrl,
} from 'notifier/urls';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { viewFetchAC } from 'core/assets/js/ducks/view';
import {
  notificationsSummariesSuccessAC,
  notificationsMarkAsSeenSuccessAC,
  notificationsMarkReadSuccessAC,
  notificationsMarkAllReadSuccessAC,
} from 'notifier/assets/js/ducks/notifications';


const fetchSummariesDS = ({
  url = '', componentName, authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => fetchNotificationsSummariesApiUrl(url),
    fetchDataAC: responseData => notificationsSummariesSuccessAC({
      ...responseData,
      componentName,
    }),
  })
);

const fetchPreviewDS = ({ url = '', params = {}, authedAxios = null, componentName }) => {
  const apiUrl = params.type
    ? fetchPreviewApiUrl(params.type, url)
    : fetchPreviewByIdApiUrl(params.id, url);

  return fetchDataDS({
    authedAxios,
    fetchApiUrl: () => apiUrl,
    fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  });
};

const _updateNotificationDS = ({ apiUrl, successAC = () => [], values = {} }) => (
  async (dispatch) => {
    try {
      const response = await axios.put(apiUrl, values);
      successAC(response.data).map(ac => dispatch(ac));
      return response.data;
    } catch (e) {
      toastr.error('Oh Snap!', e.response?.data?._error || e.message);
      if (e.response?.data) {
        dispatch(httpErrorAC([e.response.data]));
      }
      return null;
    }
  }
);

const markAsSeenNotificationDS = () => (
  _updateNotificationDS({
    apiUrl: markAsSeenNotificationApiUrl(),
    successAC: () => ([
      notificationsMarkAsSeenSuccessAC(),
    ]),
  })
);

const markAllNotificationsReadDS = () => (
  _updateNotificationDS({
    apiUrl: markAllNotificationsAsReadApiUrl(),
    successAC: () => ([
      notificationsMarkAllReadSuccessAC(),
    ]),
  })
);

const markReadNotificationDS = ({ values }) => (
  _updateNotificationDS({
    apiUrl: markReadNotificationApiUrl(),
    values,
    successAC: responseData => ([
      notificationsMarkReadSuccessAC(responseData),
    ]),
  })
);

export {
  fetchSummariesDS,
  markAsSeenNotificationDS,
  markAllNotificationsReadDS,
  markReadNotificationDS,
  fetchPreviewDS,
};
