import React from 'react';
import PropTypes from 'prop-types';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';

const IntegrationSwitch = ({ integrationId, isEnabled, onToggle, containerClassName }) => (
  <TDToggleWithConfirmationModal
    containerClassName={containerClassName}
    disableModalId={`disable-integration-${integrationId}`}
    enableModalId={`enable-integration-${integrationId}`}
    modalId={`integration-toggle-${integrationId}`}
    onToggled={onToggle}
    selected={isEnabled}
  />
);

IntegrationSwitch.propTypes = {
  containerClassName: PropTypes.string,
  integrationId: PropTypes.number.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

IntegrationSwitch.defaultProps = {
  containerClassName: '',
};

export default IntegrationSwitch;
