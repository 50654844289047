import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Modal } from 'react-bootstrap';
import { logger } from 'core/assets/js/lib/Logger';

import { BS_STYLE } from 'core/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';


const CancelInvitationForm = ({
  open, heading, onClose, onConfirm, submitting, handleSubmit, confirmLabel, payload,
}) => (
  <div className="static-modal">
    <form>
      <Modal className="worksheet-amend" show={open} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Field
            name="reason"
            type="textarea"
            component={InputField}
            label="Reason"
            placeholder="Provide the reason for the cancellation"
          />
        </Modal.Body>
        <Modal.Footer>
          <TDButton
            type="button"
            variant={BS_STYLE.PRIMARY}
            disabled={submitting}
            onClick={handleSubmit(values => onConfirm({ ...payload, values }))}
            label={confirmLabel}
          />
        </Modal.Footer>
      </Modal>
    </form>
  </div>
);

CancelInvitationForm.propTypes = {
  heading: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  payload: PropTypes.object,
};

CancelInvitationForm.defaultProps = {
  open: false,
  heading: '',
  payload: {},
  confirmLabel: 'Confirm',
  onClose: () => logger.warn('Please specify the `onClose` callback'),
};

const CancelInvitationFormRedux = reduxForm({
  form: 'cancelInvitationForm',
  enableReinitialize: true,
})(CancelInvitationForm);

export default connect()(CancelInvitationFormRedux);
