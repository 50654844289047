import React, { useState } from 'react';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import OnboardingProgressBar from 'core/assets/js/components/OnboardingProgressBar.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import { BS_TOOLTIP_PLACEMENT, USER_TYPE } from 'core/assets/js/constants';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { RATE_ADJUSTMENT_MODAL_ID } from 'rates/assets/js/constants';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { orgUserProfileUrl } from 'people/urls';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

const TeamMembersTableList = ({
  ActionsDropDownComponent,
  loadingOnboardingProgress,
  onboardingProgressByUserId,
  peopleType,
  people,
  showProgress,
}) => {
  const { params: { orgAlias }  } = useRouteMatch();
  const [skillsModalOpen, setSkillsModalOpen] = useState(false);
  const [skillsModalPayload, setSkillsModalPayload] = useState([]);
  const dispatch = useDispatch();

  const cols = [
    {
      dataFormat: (
        value, { aorEnabled, isEmployee, user: { profile: { name, avatar, userId } } },
      ) => {
        const userType = peopleType === PEOPLE_TYPE.MANAGERS
          ? USER_TYPE.MANAGER : USER_TYPE.PROVIDER;
        const indicators = [isEmployee ? 'Employee' : 'Contractor'];
        if (aorEnabled) {
          indicators.push('AOR');
        }
        return (
          <div className="d-flex align-items-center">
            <ProfilePic
              alt={name}
              className="mr-3"
              url={avatar}
              size={[36, 36]}
            />
            <div className="d-flex flex-column align-items-start">
              <Link
                className="text-dark font-weight-bolder"
                to={orgUserProfileUrl(
                  orgAlias,
                  userType,
                  userId,
                )}
              >
                <span className="text-break">{name}</span>
              </Link>
              <div>{indicators.join('; ')}</div>
            </div>
          </div>
        );
      },
      key: 'name',
      label: 'Name',
    },
    {
      dataFormat: ({ profile: { jobTitle } }) => jobTitle,
      width: '170px',
      key: 'user',
      label: 'Title',
    },
    {
      dataFormat: ({ profile }) => {
        return (
          <div className="d-flex align-items-center">
            {profile.address_components?.country_code && (
              <CountryFlagIcon
                countryISOCode={profile.address_components.country_code}
              />
            )}
            <span className="ml-2 pl-1">
              {countryNames[profile.address_components?.country_code]}
            </span>
          </div>
        );
      },
      width: '175px',
      key: 'user',
      label: 'Location',
    },
    {
      dataFormat: (value, { user: { profile: { skills: profileSkills } } }) => {
        return (
          <>
            {
              profileSkills && profileSkills
                .slice(0, 2).map(skill => skill.label).join(', ')
            }
            {profileSkills?.length > 2 && (
              <>
                {', '}
                <span
                  className="imitate-link"
                  onClick={() => {
                    setSkillsModalPayload(profileSkills);
                    setSkillsModalOpen(true);
                  }}
                >
                  {`${profileSkills.length - 2} more`}
                </span>
              </>
            )}
          </>
        );
      },
      width: '150px',
      key: 'skills',
      label: 'Skills',
    },
    {
      dataFormat: (rate, userCard) => {
        const { currencySymbol, rateAdjustmentActions, rateUnit } = userCard;

        const hasCompleteRate = !!(currencySymbol && parseFloat(rate) > 0.00);
        // Rate display
        let userRate = '';
        if (hasCompleteRate) {
          userRate = (
            <span className="cursor-default">
              <RateAmount
                amount={rate}
                unit={rateUnit}
                symbol={currencySymbol}
              />
            </span>
          );
        }

        return (
          <>
            { rateAdjustmentActions && !rateAdjustmentActions.hasPending && hasCompleteRate && (
              userRate
            )}

            { rateAdjustmentActions?.hasPending && rate && (
              <TDElementWithTooltip
                el={(
                  <span>
                    <StatusTag
                      hideTitle
                      statusClass="pending"
                      onClick={() => dispatch(modalOpenAC(RATE_ADJUSTMENT_MODAL_ID, userCard))}
                      label={userRate}
                    />
                  </span>
                )}
                placement={BS_TOOLTIP_PLACEMENT.TOP}
                tooltipMsg="Pending Adjustment"
              />
            )}
          </>
        );
      },
      width: '120px',
      key: 'rate',
      label: 'Rate',
    },
  ];

  if (peopleType === PEOPLE_TYPE.PROVIDERS) {
    cols.splice(4, 0,
      {
        dataFormat: (rating, { numReviews }) => (
          <RatingScore
            showStarIcon={false}
            rating={rating}
            numReviews={numReviews}
            classNames="font-smaller"
          />
        ),
        width: '120px',
        key: 'rating',
        label: 'Rating',
      },
    );

    cols.push({
      dataFormat: user => {
        // loadingOnboardingProgress
        const onboardingProgress = onboardingProgressByUserId[user.id];
        return (
          <div className="small">
            {(loadingOnboardingProgress || isNumber(onboardingProgress)) && (
              <OnboardingProgressBar
                isLoading={loadingOnboardingProgress}
                label=""
                percentage={onboardingProgress}
                showLabel={false}
              />
            )}
          </div>
        );
      },
      hidden: !showProgress,
      width: '95px',
      key: 'user',
      label: 'Progress',
    });
  }

  cols.push({
    dataFormat: (id, item) => <ActionsDropDownComponent item={item} />,
    width: '68px',
    key: 'id',
    label: '',
  });

  return (
    <>
      <Table
        cols={cols}
        items={people}
        responsive={false}
      />

      <ModalSimple
        heading="User skills"
        body={(
          <SkillList
            skills={skillsModalPayload}
            alignTags="left"
          />
        )}
        onClose={() => setSkillsModalOpen(false)}
        open={skillsModalOpen}
      />
    </>
  );
};

TeamMembersTableList.propTypes = {
  ActionsDropDownComponent: PropTypes.func.isRequired,
  loadingOnboardingProgress: PropTypes.bool.isRequired,
  onboardingProgressByUserId: PropTypes.object.isRequired,
  people: PropTypes.arrayOf(userCardSpec),
  peopleType: peopleTypeSpec.isRequired,
  showProgress: PropTypes.bool.isRequired,
};

TeamMembersTableList.defaultProps = {
  people: [],
};

export default TeamMembersTableList;
