import {
  TASK_STATUS, TASK_ASSIGNMENT_STATUS, TASK_STATUS_CLASS, TASK_ASSIGNMENT_STATUS_CLASS,
  PROJECT_APPLICATION_STATUS, INDICATABLE_TASK_ACTIONS,
} from 'projects/assets/js/constants';
import { isEmpty, uniqBy } from 'lodash';
import { pluralize, formatDate } from 'core/assets/js/lib/utils';

export const generateApplicationStatusLabel = ({
  status, statusLabel, createdAt, updatedAt, reviewedAt,
}) => {
  let date = null;

  if (status === PROJECT_APPLICATION_STATUS.CANCELLED) {
    date = updatedAt;
  } else if (status === PROJECT_APPLICATION_STATUS.REJECTED) {
    date = reviewedAt;
  } else if (status === PROJECT_APPLICATION_STATUS.ACCEPTED) {
    date = reviewedAt;
  } else if (status === PROJECT_APPLICATION_STATUS.PENDING) {
    date = createdAt;
  }

  return `${statusLabel} on ${formatDate(date)}`;
};

/**
 * Returns the effective task status that should be used in the UI
 *
 * @param {Object} organization
 * @param {Object} task
 * @param {Object} [options]
 * @param {Object} [options.assignment]
 * @returns {{ label: String, progress: Number, status: Number, statusClass: String }}
 */
export const getEffectiveTaskInfo = (organization, task, { assignment } = {}) => {
  if (!task) {
    return {};
  }

  let progress = null; // hide the progress by default
  const taskStatusesWithVisibleProgress = [TASK_STATUS.INPROGRESS, TASK_STATUS.COMPLETED];
  if (isEmpty(assignment) || task.status === TASK_STATUS.STOPPED) {
    const isCompleted = task.status === TASK_STATUS.COMPLETED;
    if (taskStatusesWithVisibleProgress.includes(task.status)) {
      if (isCompleted) {
        progress = 100;
      } else {
        progress = parseInt(task.progress, 10);
      }
    }

    const isPendingApproval = (
      isCompleted && organization.task_manual_completion && !task.approvedAt
    );

    return {
      status: task.status,
      statusClass: TASK_STATUS_CLASS[isPendingApproval ? TASK_STATUS.PENDING : task.status],
      label: isPendingApproval ? 'Pending approval' : task.statusLabel,
      progress,
    };
  }

  const assignmentStatusesWithVisibleProgress = [
    TASK_ASSIGNMENT_STATUS.ACCEPTED,
    TASK_ASSIGNMENT_STATUS.COMPLETED,
  ];

  if (assignmentStatusesWithVisibleProgress.includes(assignment.status)) {
    if (assignment.status === TASK_ASSIGNMENT_STATUS.COMPLETED) {
      progress = 100;
    } else {
      progress = parseInt(assignment.progress, 10);
    }
  }

  return {
    status: assignment.status,
    label: assignment.statusLabel,
    statusClass: TASK_ASSIGNMENT_STATUS_CLASS[assignment.status],
    progress,
  };
};

/**
 * Gets an object from ProjectSearchHandler.getOutstandingCounts() and returns the pending items
 * as texts.
 *
 * The object argument, takes 4 optional formatting functions:
 *
 * prefixer:
 *  - A function that generates the prefix to prepend to the text
 *    eg. (total) => (total === 1 ? 'is' : 'are')
 *
 * suffixer:
 *  - A function that generates the suffix to append to the text
 *    eg. (total) => (total === 1 ? 'is' : 'are')
 *
 * concatenator:
 *  - Joins the outstanding document entries
 *    eg. (entries) => (entries.join(', '))
 *
 * formatter:
 *  - A function that formats the strings to be added in the entries array
 *    eg. (label, count) => (<><strong>{count}</strong> {label}</>)
 *
 * This is due to the fact that we need this function to be used in various cases,
 * both front and backend where eg. node concatenation is very different than string concatenation
 *
 * @param {Object} outstandingCounts an outstanding counts object generated via ProjectSearchHandler
 * @param {Object}
 * @returns {String}
 */
export const outstandingCountsToText = (
  outstandingCounts, {
    prefixer = null,
    suffixer = null,
    formatter = null,
    concatenator = null,
  } = {},
) => {
  const totalOutstanding = Object.values(outstandingCounts).reduce((a, b) => a + b, 0);
  let text = '';

  if (typeof prefixer === 'function') {
    text += prefixer(totalOutstanding);
  }

  const mapping = {
    tasks: 'task',
    worksheets: 'worksheet',
    expenses: 'expense',
    purchaseOrders: 'budget request',
    projectApplications: 'provider application',
  };

  const entries = [];
  Object.keys(outstandingCounts).forEach((key) => {
    const count = outstandingCounts[key];
    const label = mapping[key];

    if (!count || !label) {
      return;
    }

    if (typeof formatter === 'function') {
      entries.push(formatter(label, count));
    } else {
      entries.push(`${count} ${pluralize(label, count)}`);
    }
  });

  if (typeof concatenator === 'function') {
    text += concatenator(entries);
  } else {
    const last = entries.pop();
    text += entries.join(', ');

    if (last) {
      if (entries.length) {
        text += ' and ';
      }

      text += last;
    }
  }

  if (typeof suffixer === 'function') {
    text += suffixer(totalOutstanding);
  }

  return text.trim();
};


/**
 * Get the task's pending actions
 *
 * @param {Array} actions the actions to count
 * @returns {Array} the actions that should draw the user's attention
 */
export const getTaskPendingActions = (actions) => {
  if (!Array.isArray(actions) || isEmpty(actions)) {
    return [];
  }

  return uniqBy(
    actions, action => `${action.name}-${action.targetUserId || '-none'}`,
  ).map(
    a => a.name,
  ).filter(
    a => INDICATABLE_TASK_ACTIONS.includes(a),
  );
};
