import React from 'react';
import PropTypes from 'prop-types';

import { parseDateWithOptions } from 'core/assets/js/lib/utils';
import { CURRENCY_SYMBOL, BS_SIZE, ICON } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import FeeAnalysisTable from 'finance/assets/js/components/invoicing/FeeAnalysisTable.jsx';
import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';

const FeeAnalysisModal = ({ invoicingSettings, modalState, amounts, month, invoiceDate }) => {
  if (!amounts) {
    return null;
  }

  const settings = new InvoicingSettings(invoicingSettings);
  const scheme = settings.getFeeScheme().getProcessingScheme();
  const frequency = settings.getInvoicingFrequency();
  const schemeFloor = scheme.getFloor();
  const { fee, currency } = amounts;
  const currencySymbol = CURRENCY_SYMBOL[currency];

  const endOfMonth = frequency.isMonthlyProcessingBillingDate(
    parseDateWithOptions(invoiceDate, { retryWithUnknownFormat: true }),
  );
  const invoiceAmounts = new InvoiceAmounts(amounts);
  const processingFeeAnalysis = invoiceAmounts.getProcessingFeeAnalysis();
  const prepaidFee = processingFeeAnalysis.getPrepaidFee();
  const floorRemainder = processingFeeAnalysis.getFloorRemainder();
  const isFloorGreaterThanFees = processingFeeAnalysis.isFloorGreaterThanFees();

  const showExtendedUI = endOfMonth && scheme.hasFloor();

  return (
    <React.Fragment>
      <span onClick={modalState.open} className={`${ICON.INFO} do-not-print`} />
      <ModalSimple
        open={modalState.isOpen}
        heading="Service fees"
        body={(
          <>
            <div className="finance-report__table-wrapper finance-report__table-wrapper--list invoice-table-wrapper mt-4">
              <FeeAnalysisTable
                amounts={amounts}
                invoicingSettings={invoicingSettings}
                month={month}
              />
              <div className="finance-report__summary">
                {showExtendedUI && (
                  <>
                    <div>
                      <label>Payment processing fee floor</label>
                      <NumberTpl prefix={currencySymbol} value={schemeFloor} decimals={2} />
                    </div>
                    <div>
                      <label>Previous charges</label>
                      <NumberTpl prefix={currencySymbol} value={`-${prepaidFee}`} decimals={2} />
                    </div>
                    <div>
                      <label>Floor remainder</label>
                      <NumberTpl prefix={currencySymbol} value={floorRemainder} decimals={2} />
                    </div>
                  </>
                )}
                <div>
                  <label>{`${showExtendedUI ? '*' : ''}Subtotal`}</label>
                  <NumberTpl prefix={currencySymbol} value={fee} decimals={2} />
                </div>
              </div>
            </div>

            {showExtendedUI && (
              <div>
                <label>
                  {isFloorGreaterThanFees ? '* Floor is greater than incurred fees, charging floor remainder' : '* Fees incurred are greater than floor, charging incurred fees'}
                </label>
              </div>
            )}
          </>
        )}
        onClose={modalState.close}
        noFooter
        size={BS_SIZE.LARGE}
      />
    </React.Fragment>
  );
};

FeeAnalysisModal.propTypes = {
  amounts: PropTypes.object.isRequired,
  month: PropTypes.string.isRequired,
  invoicingSettings: PropTypes.object.isRequired,
  modalState: modalStateSpec.isRequired,
  invoiceDate: PropTypes.string.isRequired,
};

export default withStateModal(FeeAnalysisModal);
