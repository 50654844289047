import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';

const ImportCreateMapFields = ({ csvHeaders }) => {
  const { submitErrors, values } = useFormState();

  return (
    <div className="">
      <h2 className="mt-4 mb-3">Map fields</h2>
      <p>
        <strong>Make sure your fields below match </strong>
        the ones TalentDesk uses and finalise your import.
      </p>

      <table className="import-field-mapping">
        <thead>
          <tr>
            <th>Predefined field</th>
            <th>Imported file headers</th>
          </tr>
        </thead>

        <tbody>
          <FieldArray name="colMapping">
            {({ fields }) => fields.map((col, index) => {
              const colName = values?.colMapping[index]?.colName;
              return (
                <tr key={col}>
                  <td>{colName}</td>
                  <td>
                    <SelectField
                      data-testid={`import-mapping-selector_${colName}`}
                      defaultOptionText="Select a column"
                      name={`${col}.csvColumnName`}
                      optionsMapping={csvHeaders.map(text => ({ text, value: text }))}
                      selectedOption={`${col}.csvColumnName`}
                      wrapperClasses="mb-0"
                    />
                  </td>
                </tr>
              );
            })}
          </FieldArray>
        </tbody>
      </table>

      {submitErrors?.colMapping && (
        <div className="text-danger mt-4">{submitErrors.colMapping}</div>
      )}
    </div>
  );
};

ImportCreateMapFields.propTypes = {
  csvHeaders: PropTypes.array,
};

ImportCreateMapFields.defaultProps = {
  csvHeaders: [],
};

export default ImportCreateMapFields;
