import React from 'react';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { refreshPendingInvitationsDS } from 'core/assets/js/ducks/pendingInvitations';
import { isReactTest } from 'core/assets/js/config/checks';

export const COMPONENT_NAME = 'pendingInvitations';

class PendingInvitationsProvider extends React.Component {
  static FetchPendingInvitations({ isAuthenticated, dispatch, authedAxios, isFirstRender }) {
    if (isAuthenticated && isFirstRender) {
      return dispatch(refreshPendingInvitationsDS({
        authedAxios,
      }));
    }

    return Promise.resolve();
  }

  render() {
    if (isReactTest) {
      return null;
    }

    return (
      <TDApiConnected
        duck="pendingInvitations"
        storeKey={COMPONENT_NAME}
        loadingEnabled={false}
        shouldRefetchOnQueryChange={false}
        fetchData={PendingInvitationsProvider.FetchPendingInvitations}
        orgSpecific={false}
      />
    );
  }
}

export default PendingInvitationsProvider;
