import React from 'react';
import PropTypes from 'prop-types';

import { taskAttachmentSpec } from 'projects/assets/js/lib/objectSpecs';
import TaskAttachmentCard from 'projects/assets/js/components/TaskAttachmentCard.jsx';

const TaskAttachmentsWidget = ({
  className, attachments, itemClassName, filePreviewClassName, invertColors, showDates, ...rest
}) => (
  <div
    className={
      ['task-attachments__container', 'attachments-list', className].filter(c => !!c).join(' ')
    }
  >
    {attachments.map(att => (
      <TaskAttachmentCard
        key={att.id}
        item={att}
        className={itemClassName}
        showDate={!!showDates}
        filePreviewClassName={filePreviewClassName}
        invertColors={invertColors}
        {...rest}
      />
    ))}
  </div>
);

TaskAttachmentsWidget.propTypes = {
  className: PropTypes.string,
  attachments: PropTypes.arrayOf(taskAttachmentSpec),
  itemClassName: PropTypes.string,
  filePreviewClassName: PropTypes.string,
  showDates: PropTypes.bool,
  invertColors: PropTypes.bool,
};

TaskAttachmentsWidget.defaultProps = {
  className: null,
  attachments: [],
  itemClassName: null,
  filePreviewClassName: null,
  showDates: true,
  invertColors: false,
};

export default TaskAttachmentsWidget;
