import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import AorDocumentViewDropdownItem from 'documents/assets/js/components/AorDocumentViewDropdownItem.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropdown from 'core/assets/js/components/TDDropdown.jsx';
import { BS_STYLE, BS_SIZE, ICON } from 'core/assets/js/constants';
import { AOR_CONTRACT_STATUS_LABEL, AOR_CONTRACT_STATUS_LABEL_CLASS } from 'documents/assets/js/constants';

import { formatDate } from 'core/assets/js/lib/utils';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const AorDocumentListItem = ({ aorData }) => {
  if (isEmpty(aorData)) {
    return null;
  }

  const { signedAt, status, userFullName } = aorData;
  const endDate = aorData.endDate ? formatDate(aorData.endDate) : '...';

  return (
    <div className="bg-light-grey px-4 py-3 mb-3 rounded">
      <div className="d-flex row">
        <div className="col-12 col-sm">
          <span className="discreet font-smaller">
            AOR Contract
          </span>
          <br />
          <span className="discreet font-smaller mr-2">Full Legal Name</span>
          {userFullName}
          <br />
          <span className="discreet font-smaller">
            {`Signed: ${signedAt ? formatDate(signedAt) : '-'}`}
          </span>
          <br />
          <span className="discreet font-smaller">
            {`Effective date: ${signedAt ? `${formatDate(signedAt)} - ${endDate}` : '-'}`}
          </span>
        </div>

        <div className="col-12 col-sm-auto mt-3 mt-md-0 align-self-center align-items-center ml-auto me-5 pe-5 d-flex flex-nowrap">
          <div className="mr-5 pr-5 d-flex text-nowrap align-items-center">
            <StatusTag
              hideTitle
              label={AOR_CONTRACT_STATUS_LABEL[status]}
              statusClass={AOR_CONTRACT_STATUS_LABEL_CLASS[status]}
            />
          </div>

          <div className="ml-auto">
            <TDDropdown
              variant={BS_STYLE.LINK}
              noCaret
              alignRight
              toggle={(
                <TDButton
                  bsSize={BS_SIZE.SMALL}
                  variant={BS_STYLE.DEFAULT}
                  btnIcon={<i className={ICON.CHEVRON_DOWN} />}
                />
              )}
            >
              <AorDocumentViewDropdownItem assignment={aorData} />
            </TDDropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

AorDocumentListItem.propTypes = {
  aorData: PropTypes.object.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(AorDocumentListItem);
