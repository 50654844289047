import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ExpenseCancelForm from 'finance/assets/js/components/ExpenseCancelForm.jsx';
import { MODAL_SIZES } from 'core/assets/js/constants';

const ExpenseCancelModal = ({
  isOpen, onClose, onExpenseCancelled, expenseId, projectId, orgAlias,
}) => {
  const handleExpenseCancelled = async (expense) => {
    await onExpenseCancelled(expense);
    onClose();
  };

  const ReduxExpenseCancelForm = reduxForm({
    form: 'ExpenseCancelForm',
  })(ExpenseCancelForm);

  return (
    <ModalSimple
      open={isOpen}
      heading="Cancel expense"
      size={MODAL_SIZES.LARGE}
      body={(
        <ReduxExpenseCancelForm
          onSuccess={handleExpenseCancelled}
          expenseId={expenseId}
          projectId={projectId}
          orgAlias={orgAlias}
          initialValues={{}}
        />
      )}
      onClose={onClose}
      noFooter
    />
  );
};

ExpenseCancelModal.propTypes = {
  expenseId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onExpenseCancelled: PropTypes.func,
  orgAlias: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
};

ExpenseCancelModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onExpenseCancelled: () => {},
};

export default ExpenseCancelModal;
