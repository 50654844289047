import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory, useParams } from 'react-router-dom';

import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import { BS_STYLE, IMG_SIZE, USER_ACTION, USER_TYPE_LABEL } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { listRemoveItemAC } from 'core/assets/js/ducks/list';
import { fetchViewDS, viewUpdateKeysAC } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { formatDate, parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { downloadFileApiUrl } from 'files/urls';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import { INVOICE_STATUS_LABEL } from 'finance/assets/js/constants';
import {
  financeInvoiceViewUrl, financeServiceOrderManageApiUrl, financeServiceOrderViewUrl,
} from 'finance/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgGetOutstandingItemsToActionApiUrl } from 'organizations/urls';
import PeopleListView from 'people/assets/js/PeopleListView.jsx';
import { deactivateUserCardApiUrl, orgPeopleListUrl } from 'people/urls';
import {
  SERVICE_ORDER_STATUS,
  SERVICE_ORDER_STATUS_CLASS,
  SERVICE_ORDER_STATUS_LABEL,
  SERVICE_ORDER_TYPE_LABEL,
} from 'projects/assets/js/constants';
import { projectViewUrl, projectViewTaskUrl } from 'projects/urls';

const DeactivateUserView = () => {
  const params = useParams();
  const { orgAlias, peopleType, userId } = params;
  const activeOrg = useSelector(selectActiveOrg);
  const [actioning, setActioning] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const componentName = DeactivateUserView.GetComponentName(params);
  const loadUrl = orgGetOutstandingItemsToActionApiUrl(orgAlias, userId);
  const { hasLoaded, item } = fetchDataHook({ componentName, url: loadUrl });

  const approvedServiceOrders = [];
  const pendingServiceOrders = [];
  (item?.serviceOrders || []).forEach(so => {
    if (so.status === SERVICE_ORDER_STATUS.APPROVED) {
      approvedServiceOrders.push(so);
    } else {
      pendingServiceOrders.push(so);
    }
  });

  const serviceOrderBaseColumns = statusColumnWidth => [
    {
      dataFormat: (id, { periodEnd, periodStart, projectReference, projectTitle, type }) => {
        if (!id) {
          return null;
        }
        return (
          <Link to={financeServiceOrderViewUrl(orgAlias, type, id)}>
            {[
              projectTitle ? `${projectTitle} (${projectReference}) ` : '',
              formatDate(periodStart),
              ' - ',
              formatDate(periodEnd),
            ].join('')}
          </Link>
        );
      },
      key: 'id',
      label: 'Item',
    },
    {
      dataFormat: type => type && SERVICE_ORDER_TYPE_LABEL[type],
      key: 'type',
      label: 'Type',
      width: '150px',
    },
    {
      dataFormat: status => (
        status && (
          <StatusColFormat
            statusLabel={SERVICE_ORDER_STATUS_LABEL[status]}
            statusClass={SERVICE_ORDER_STATUS_CLASS[status]}
          />
        )
      ),
      key: 'status',
      label: 'Status',
      width: statusColumnWidth,
    },
  ];

  const actionSo = async (type, id, userAction) => axios.put(financeServiceOrderManageApiUrl(
    orgAlias, type, id, userAction,
  ));

  const updateServiceOrders = removeId => {
    const newServiceOrders = [...item.serviceOrders];
    newServiceOrders.splice(
      item.serviceOrders.findIndex(so => so.id === removeId), 1,
    );
    dispatch(viewUpdateKeysAC(
      { serviceOrders: newServiceOrders },
      ['serviceOrders'],
      componentName,
    ));
  };

  const projectsAndTasks = [
    ...(item?.projects || []).map(p => ({ ...p, type: 'Project' })),
    ...(item?.tasks || []).map(t => ({ ...t, type: 'Task' })),
  ];

  const userTypeLabel = USER_TYPE_LABEL[item.userType];

  const peopleListUrl = orgPeopleListUrl(orgAlias, peopleType);

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Team', url: peopleListUrl },
          { title: `Deactivate ${item?.userName || 'user'}` },
        ]}
      />
      <div className="page page--deactivate-user">
        <div className="container">
          {hasLoaded && item && (
            <>
              <TDSystemMessage
                className="mb-5"
                title={`Deactivating ${userTypeLabel || 'user'}`}
                type={BS_STYLE.WARNING}
              >
                <p>
                  {'You are about to deactivate '}
                  <b>{`${item.userName} (${item.userEmail})`}</b>
                </p>
                <p>
                  {`Once deactivated, the user will lose access to ${activeOrg.name}. All `}
                  approved worksheets, expenses and proforma invoices will be invoiced unless
                  voided. This change is reversible by reactivating the user.
                </p>
              </TDSystemMessage>
              <Form
                onSubmit={async values => { // eslint-disable-line consistent-return
                  try {
                    await axios.put(deactivateUserCardApiUrl(orgAlias, userId), values);
                    dispatch(listRemoveItemAC(item.id, PeopleListView.GetComponentName(params)));
                    toastr.success('Well Done!', `You have deactivated ${item.userName}`);
                    history.push(peopleListUrl);
                  } catch (error) {
                    return parseAxiosErrorForFinalForm(error);
                  }
                }}
                render={({ handleSubmit, submitError, submitting }) => (
                  <form className="d-flex flex-column" onSubmit={handleSubmit}>
                    <Card>
                      <Card.Body>
                        {approvedServiceOrders.length > 0 && (
                          <>
                            <div className="d-flex flex-column flex-md-row">
                              <div>
                                <b>Approved service orders</b>
                                <p className="mt-2">
                                  Unless voided, these service orders will be invoiced at the end
                                  of the current billing cycle.
                                </p>
                              </div>

                              {approvedServiceOrders.length > 1 && (
                                <div
                                  className="ml-auto"
                                >
                                  <TDButton
                                    disabled={actioning || submitting}
                                    className="mb-4 mb-md-0"
                                    label="Void all"
                                    onClick={async () => {
                                      setActioning(true);
                                      try {
                                        await Promise.all(approvedServiceOrders.map(so => (
                                          actionSo(so.type, so.id, USER_ACTION.VOID)
                                        )));

                                        toastr.success(
                                          'Well Done!',
                                          [
                                            'You have voided those service orders',
                                          ].join(''),
                                        );

                                        dispatch(viewUpdateKeysAC(
                                          { serviceOrders: pendingServiceOrders },
                                          ['serviceOrders'],
                                          componentName,
                                        ));
                                      } catch (err) {
                                        toastr.error(
                                          'Oh Snap!', err.response?.data?._error || err.message,
                                        );
                                      } finally {
                                        setActioning(false);
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            <Table
                              containerClass="mb-5"
                              cols={[
                                ...serviceOrderBaseColumns('130px'),
                                {
                                  dataFormat: (id, { type }) => (
                                    <TDButton
                                      className={id ? null : 'float-right'}
                                      disabled={actioning || submitting}
                                      label="Void"
                                      onClick={async () => {
                                        setActioning(true);
                                        try {
                                          await actionSo(type, id, USER_ACTION.VOID);

                                          toastr.success(
                                            'Well Done!',
                                            [
                                              'You have voided ',
                                              id ? 'that service order' : 'those service orders',
                                            ].join(''),
                                          );
                                          updateServiceOrders(id);

                                        } catch (err) {
                                          toastr.error(
                                            'Oh Snap!', err.response?.data?._error || err.message,
                                          );
                                        } finally {
                                          setActioning(false);
                                        }
                                      }}
                                    />
                                  ),
                                  key: 'id',
                                  label: 'Action',
                                  width: '200px',
                                },
                              ]}
                              items={approvedServiceOrders}
                            />
                          </>
                        )}
                        {pendingServiceOrders.length > 0 && (
                          <>
                            <b>Service orders that require action</b>
                            <p className="mt-2">
                              {`Before this ${userTypeLabel} can be deactivated, the following `}
                              service orders must be either approved or rejected.
                            </p>
                            <Table
                              containerClass="mb-5"
                              cols={[
                                ...serviceOrderBaseColumns('210px'),
                                {
                                  dataFormat: (id, { status, type }) => {
                                    const actions = [{
                                      className: 'text-nowrap',
                                      label: 'Reject',
                                      onClick: async () => {
                                        setActioning(true);
                                        try {
                                          await actionSo(type, id, USER_ACTION.REJECT);
                                          toastr.success(
                                            'Well Done!',
                                            'You have rejected that service order',
                                          );
                                          updateServiceOrders(id);
                                        } catch (err) {
                                          toastr.error(
                                            'Oh Snap!',
                                            err.response?.data?._error || err.message,
                                          );
                                        } finally {
                                          setActioning(false);
                                        }
                                      },
                                    }];
                                    if (status !== SERVICE_ORDER_STATUS.NOT_ENOUGH_FUNDS) {
                                      actions.push({
                                        className: 'text-nowrap',
                                        label: 'Approve',
                                        onClick: async () => {
                                          setActioning(true);
                                          try {
                                            await actionSo(type, id, USER_ACTION.APPROVE);
                                            toastr.success(
                                              'Well Done!',
                                              'You have approved that service order',
                                            );
                                            // Reload, because there will now be new approved
                                            // approved order table records
                                            dispatch(fetchViewDS({
                                              componentName, url: loadUrl,
                                            }));
                                          } catch (err) {
                                            toastr.error(
                                              'Oh Snap!',
                                              err.response?.data?._error || err.message,
                                            );
                                          } finally {
                                            setActioning(false);
                                          }
                                        },
                                      });
                                    }
                                    return (
                                      <SplitButton
                                        actions={actions}
                                        disabled={submitting || actioning}
                                        primaryButtonVariant={BS_STYLE.DEFAULT}
                                      />
                                    );
                                  },
                                  key: 'id',
                                  label: 'Action',
                                  width: '160px',
                                },
                              ]}
                              items={pendingServiceOrders}
                            />
                          </>
                        )}
                        {(
                          Array.isArray(item?.deferredInvoices) && item.deferredInvoices.length > 0
                        ) && (
                          <>
                            <b>Deferred invoices</b>
                            <p className="mt-2">
                              {`The ${userTypeLabel} has these deferred invoices. Payment for `}
                              these will be made at the end of the current billing cycle.
                            </p>
                            <Table
                              containerClass="mb-5"
                              cols={[
                                {
                                  dataFormat: (id, { uniqueNumber }) => (
                                    <Link to={financeInvoiceViewUrl(orgAlias, id)}>
                                      {uniqueNumber}
                                    </Link>
                                  ),
                                  key: 'id',
                                  label: '#',
                                },
                                { key: 'postedDate', label: 'Posted date' },
                                { key: 'billingPeriod', label: 'Billing period' },
                                { key: 'owner', label: 'Owner' },
                                { key: 'dueDate', label: 'Due date' },
                                { key: 'amount', label: 'Amount' },
                                {
                                  dataFormat: status => (
                                    <StatusColFormat statusLabel={INVOICE_STATUS_LABEL[status]} />
                                  ),
                                  key: 'status',
                                  label: 'Status',
                                },
                              ]}
                              items={item.deferredInvoices}
                            />
                          </>
                        )}
                        {projectsAndTasks.length > 0 && (
                          <>
                            <b>Projects and Tasks</b>
                            <p className="mt-2">
                              {`${item.userName} is a part of `}
                              <b>{`${item.projects.length} projects`}</b>
                              {' and '}
                              <b>{`${item.tasks.length} tasks`}</b>
                              {`. The ${userTypeLabel} will be removed from all projects and `}
                              tasks when deactivated.
                            </p>
                            <Table
                              containerClass="mb-5"
                              cols={[
                                {
                                  dataFormat: (title, { id, projectId, type }) => (
                                    <Link
                                      to={(
                                        type === 'Project'
                                          ? projectViewUrl(orgAlias, id)
                                          : projectViewTaskUrl(orgAlias, projectId, id)
                                      )}
                                    >
                                      {title}
                                    </Link>
                                  ),
                                  key: 'title',
                                  label: 'Item',
                                },
                                { key: 'type', label: 'Type', width: '80px' },
                                {
                                  dataFormat: (ownerName, { ownerAvatarFileId }) => (
                                    <>
                                      {ownerAvatarFileId && (
                                        <ProfilePic
                                          alt={ownerName}
                                          className="mr-3"
                                          size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
                                          url={downloadFileApiUrl(ownerAvatarFileId)}
                                        />
                                      )}
                                      {ownerName}
                                    </>
                                  ),
                                  key: 'ownerName',
                                  label: 'Owner',
                                  width: '260px',
                                },
                              ]}
                              items={projectsAndTasks}
                            />
                          </>
                        )}
                        <TextAreaField label="Reason" name="reason" required />
                        {submitError && <span className="text-danger mt-5">{submitError}</span>}
                      </Card.Body>
                    </Card>
                    <div className="d-flex align-items-center justify-content-end mt-5">
                      <TDButton
                        disabled={submitting || actioning}
                        label="Cancel"
                        onClick={() => history.push(peopleListUrl)}
                      />
                      <TDButton
                        disabled={submitting || actioning}
                        label="Deactivate user"
                        onClick={() => null}
                        type="submit"
                        variant={BS_STYLE.DANGER}
                      />
                    </div>
                  </form>
                )}
              />
            </>
          )}
          {!hasLoaded && <SettingsPageSkeleton />}
        </div>
      </div>
    </>
  );
};

DeactivateUserView.GetComponentName = () => 'DeactivateUserView';

export default DeactivateUserView;
