import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ICON } from 'core/assets/js/constants';

const MobileGuestChatNavbar = ({ chatRooms, handleClose, isMenuOpened }) => {
  const [activeSection, setActiveSection] = useState(null);

  const onClose = () => {
    setActiveSection(null);
    handleClose();
  };
  return (
    <>
      <a
        className={`off-canvas__close-button ${
          isMenuOpened ? 'off-canvas__close-button--open' : ''
        }`}
        onClick={() => onClose()}
      >
        <i className={ICON.CROSS} />
      </a>

      <div className="navbar-col mobile-navbar no-select">
        <div
          className={`navbar__backdrop ${
            activeSection ? 'navbar__backdrop--active' : ''
          }`}
        />

        <ul className="chat__navbar">{chatRooms}</ul>
      </div>
    </>
  );
};

MobileGuestChatNavbar.propTypes = {
  chatRooms: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  isMenuOpened: PropTypes.bool,
};

MobileGuestChatNavbar.defaultProps = {
  isMenuOpened: false,
};

export default MobileGuestChatNavbar;
