import React from 'react';
import PropTypes from 'prop-types';

import { NODE_ENV, ENV_DEVELOPMENT, WINDOW_SET_RECAPTCHA_OPTIONS } from 'core/assets/js/config/settings';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { setSentryContext } from 'core/assets/js/lib/sentry';
import Store from 'core/assets/js/config/store';
import { isSSR, isReactTest } from 'core/assets/js/config/checks';
import { matchRoute as matchAppRoute } from 'core/assets/js/routes.jsx';
import { createAxios } from 'core/assets/js/lib/tdAxios';
import AxiosContext from 'core/assets/js/lib/AxiosContext';
import Router from 'core/assets/js/config/Router.jsx';

/**
 * Wrapper component to setup all routes and mount the app on the DOM
 *
 * @returns {XML}
 * @constructor
 */
class RootComponent extends React.PureComponent {
  static createStore(props) {
    return Store.createStore(props);
  }

  static matchRoute(location) {
    return matchAppRoute(location);
  }

  static getDerivedStateFromProps({ store: existingStore, initialState }, state) {
    if (state && state.store) {
      return null;
    }
    const store = existingStore || RootComponent.createStore(initialState);
    return {
      store,
    };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // Required for Recaptcha to work in China
    WINDOW_SET_RECAPTCHA_OPTIONS({ useRecaptchaNet: true });
  }

  render() {
    const { url, children, apiUrl } = this.props;
    const { store } = this.state;

    const auth = store.getState().auth;
    if (!isSSR && !isReactTest) {
      // Set user context submitted to Sentry every time an error is reported
      setSentryContext({ isAuthenticated: auth.authenticated, profile: auth.profile });
    }
    const axios = createAxios({ apiUrl, dispatch: store.dispatch });
    return (
      <AxiosContext.Provider value={axios}>
        <Provider store={store}>
          <React.Fragment>
            {children || (
              <Router url={url} />
            )}
          </React.Fragment>
        </Provider>
      </AxiosContext.Provider>
    );
  }
}

RootComponent.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object,
  store: PropTypes.object,
  apiUrl: PropTypes.string,
  url: PropTypes.string,
};

RootComponent.defaultProps = {
  children: null,
  initialState: null,
  store: null,
  apiUrl: '',
  url: '',
};


const Root = module.hot && NODE_ENV === ENV_DEVELOPMENT
  ? hot(RootComponent)
  : RootComponent;

export default Root;
