import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuthenticated } from 'accounts/assets/js/reducers/auth';
import { fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import { updateLastActivityApiUrl } from 'accounts/urls';
import { isReactTest } from 'core/assets/js/config/checks';
import axios from 'core/assets/js/lib/tdAxios';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const AccountProvider = () => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isReactTest && isAuthenticated) {
      Promise.all([
        dispatch(fetchSetupStepsDS({ orgAlias: activeOrg.alias })),
        axios.put(updateLastActivityApiUrl()),
      ]);
    }
  }, [isAuthenticated, activeOrg?.id]);

  return null;
};

export default AccountProvider;
