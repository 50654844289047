import React from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  INVITATION_ACTION,
  STATUS,
  STATUS_CLASS as INVITATION_STATUS_CLASS,
} from 'invitations/assets/js/constants';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { BS_STYLE, ICON, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { PROJECT_MEMBER_STATUS } from 'projects/assets/js/constants';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import ProjectInvitationContent from 'projects/assets/js/components/ProjectInvitationContent.jsx';
import { projectViewUrl } from 'projects/urls';

const ProjectInvitationCardItem = ({ item: invitation, onOpenInvitationModal }) => {
  const {
    project, status, sentOn, hasExpired, orgAlias, token, sender,
    expiresAt, answeredAt, statusLabel, updatedAt, id: invId, projectMemberStatus,
  } = invitation;
  const leftProject = projectMemberStatus === PROJECT_MEMBER_STATUS.LEFT;
  const removedFromProject = projectMemberStatus === PROJECT_MEMBER_STATUS.REMOVED;
  const isPending = status === STATUS.PENDING;
  const isAccepted = status === STATUS.ACCEPTED;
  const showLink = isAccepted && !leftProject && !removedFromProject;
  const statusClass = hasExpired ? 'pending' : INVITATION_STATUS_CLASS[invitation.status];
  const sentOnFormatted = formatDate(sentOn, DATE_FORMAT_DEFAULT);
  let invitationInfo;
  if (hasExpired) {
    const expiredDateFormatted = formatDate(expiresAt, DATE_FORMAT_DEFAULT);
    invitationInfo = `${statusLabel} on ${expiredDateFormatted}`;
  } else if (invitation.status === STATUS.CANCELLED) {
    const cancelledDateFormatted = formatDate(updatedAt, DATE_FORMAT_DEFAULT);
    invitationInfo = `${statusLabel} on ${cancelledDateFormatted}`;
  } else {
    const repliedDateFormatted = formatDate(answeredAt, DATE_FORMAT_DEFAULT);
    invitationInfo = `${statusLabel} on ${repliedDateFormatted}`;
  }
  const {
    skills,
    title,
    id: projectId,
  } = project;
  const senderName = sender?.profile?.name || project.owner_name;
  const cardHeader = (
    <>
      <div className="d-flex w-100 justify-content-end">
        <div className="invitation-list-item__icon-container invitation-list-item__icon-container--project mr-4">
          <span className={ICON.PROJECTS} />
        </div>

        <h3>
          <span className="invitation-list-item__project-title">
            { showLink && (
              <Link to={projectViewUrl(orgAlias, project.id)}>{title}</Link>
            )}
            { !showLink && title}
          </span>
          <aside>{`Sent on ${sentOnFormatted} by ${senderName}`}</aside>
        </h3>
      </div>

      <div className="flex-shrink-0 action-buttons d-flex flex-column align-items-end d-sm-block mb-4 pb-4 pb-sm-0 mb-sm-0 px-4 px-sm-0">
        { (isPending && !hasExpired)
          && (
            <div className="d-flex w-100 justify-content-end">
              <div>
                <Dropdown as={ButtonGroup} className="td-dropdown">
                  <TDButton
                    data-testid="project-invitation-card-button-accept"
                    variant={BS_STYLE.SUCCESS}
                    onClick={() => {
                      const heading = 'Accept invitation';
                      const body = (
                        <div>
                          Click on the
                          {' '}
                          <span className="font-weight-bold">Accept</span>
                          {' '}
                          button below to start working with
                          {' '}
                          <span className="font-weight-bold">{senderName}</span>
                          {' '}
                          on the
                          {' '}
                          <span className="font-weight-bold">{title}</span>
                          {' '}
                          project.
                        </div>
                      );
                      const confirmLabel = 'Accept';
                      onOpenInvitationModal(
                        heading, body, confirmLabel, 'success', token, project.id, null, INVITATION_ACTION.ACCEPT,
                      );
                    }}
                  >
                    <span className={`${ICON.CHECKMARK} mr-3`} />
                    Accept
                  </TDButton>

                  <Dropdown.Toggle split variant={BS_STYLE.SUCCESS}>
                    <span className={ICON.CHEVRON_DOWN} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu alignRight>
                    <Dropdown.Item
                      data-testid="project-invitation-card-button-reject"
                      variant={BS_STYLE.DANGER}
                      onClick={() => {
                        const heading = 'Reject invitation';
                        const body = (
                          <div>
                            Are you sure you want to
                            {' '}
                            <span className="font-weight-bold">reject</span>
                            {' '}
                            the invitation you received from
                            {' '}
                            <span className="font-weight-bold">{senderName}</span>
                            {' '}
                            to join the
                            {' '}
                            <span className="font-weight-bold">{title}</span>
                            {' '}
                            project?
                          </div>
                        );
                        const confirmLabel = 'Reject';
                        onOpenInvitationModal(
                          heading, body, confirmLabel, 'danger', token, project.id, null, INVITATION_ACTION.REJECT,
                        );
                      }}
                    >
                      <span className="text-danger">
                        Reject
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )
        }
        {(!isPending || hasExpired) && (
          <StatusTag statusClass={statusClass} label={invitationInfo} />
        )}
        {(leftProject || removedFromProject) && (
          <div className="invitation-list-item__warning warning d-flex align-items-center text-warning p-2 mt-2">
            <span className={`${ICON.WARNING} mr-3`} />
            Access to this project has been revoked, because
            {removedFromProject && ' the project manager removed you from '}
            {leftProject && ' you left '}
            the project
          </div>
        )}
      </div>
    </>
  );

  const headingChildren = (
    <SkillList
      skills={skills}
      invertedColors={invitation === null}
      inline
      maxShownItems={10}
      modalId={`project-skills-${projectId}`}
    />
  );

  return (
    <Card className="invitation-list-item">
      <Card.Header className="d-flex flex-column flex-sm-row mx-0 px-4">
        {cardHeader}
      </Card.Header>
      <Card.Body className="mx-4">
        <AreaCollapsible
          contentChildren={(
            <ProjectInvitationContent
              storeKey={`ProjectInvContent${invId}_${projectId}`}
              project={project}
            />
          )}
          headingChildren={headingChildren}
          toggleAfterCard
        />
      </Card.Body>
    </Card>
  );
};

ProjectInvitationCardItem.propTypes = {
  item: invitationSpec.isRequired,
  onOpenInvitationModal: PropTypes.func.isRequired,
};
ProjectInvitationCardItem.defaultProps = {};

export default ProjectInvitationCardItem;
