import React from 'react';

import { formatDate } from 'core/assets/js/lib/utils';
import { worksheetItemSpec, projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';

const WorksheetProjectWorkItemCard = ({
  project: { deadline: projectDeadline, owner_name },
  item: { description },
}) => (
  <>
    <div className="worksheet-item-card__title mb-1">
      {description}
    </div>

    <div className="worksheet-item-card__meta d-flex flex-row">
      <div>
        <span className="discreet mr-2">Manager</span>
        <span>{owner_name}</span>
      </div>

      { projectDeadline && (
        <div>
          <span className="discreet mr-2">Deadline</span>
          <span>{formatDate(projectDeadline, DATE_FORMAT_DEFAULT)}</span>
        </div>
      )}
    </div>
  </>
);
WorksheetProjectWorkItemCard.propTypes = {
  item: worksheetItemSpec.isRequired,
  project: projectSpec.isRequired,
};

export default WorksheetProjectWorkItemCard;
