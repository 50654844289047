import React from 'react';
import PropTypes from 'prop-types';
import appRoutes from 'core/assets/js/routes.jsx';
import {
  Route, Switch, Router as ReactRouter, BrowserRouter, StaticRouter,
} from 'react-router-dom';
import { isReactTest, isSSR } from 'core/assets/js/config/checks';
import { createMemoryHistory } from 'history';
// Custom routes
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import CoreRoute from 'core/assets/js/config/routes/CoreRoute.jsx';
import NotFoundView from 'core/assets/js/NotFoundView.jsx';
import { updateDocumentTitle } from 'core/assets/js/lib/utils';
import { settingsAccountUrl } from 'settings/urls';
import { UNKNOWN_ALIAS } from 'organizations/assets/js/constants';

const RouteSwitch = ({ children }) => (
  <div className="routes-container clearfix">
    <CoreRoute>
      <Switch>
        { appRoutes.map((route, index) => {
          const key = `route-${index}`;
          const { path, exact, component: Component } = route;
          if (children) {
            return (
              <Route
                key={key}
                render={() => children}
                path={path}
                exact={exact}
              />
            );
          }
          return (
            <Route
              key={key}
              render={(...args) => {
                updateDocumentTitle(Component);
                return <Component {...args} />;
              }}
              path={path}
              exact={exact}
            />
          );
        })}
        <RedirectRoute status={301} from="/settings" to={settingsAccountUrl(UNKNOWN_ALIAS)} />
        <Route component={NotFoundView} />
      </Switch>
    </CoreRoute>
  </div>
);

RouteSwitch.propTypes = {
  children: PropTypes.node,
};

RouteSwitch.defaultProps = {
  children: null,
};

/**
 * Wrapper component to setup all routes
 *
 * @returns {JSX}
 */
const Router = ({ url, routingContext, children, history }) => {
  if (isReactTest) {
    const customHistory = createMemoryHistory();
    customHistory.push(url);
    return (
      <ReactRouter
        history={history || customHistory}
      >
        <RouteSwitch>
          {children}
        </RouteSwitch>
      </ReactRouter>
    );
  }

  if (isSSR) {
    return (
      <StaticRouter
        location={url}
        context={routingContext}
      >
        <RouteSwitch>
          {children}
        </RouteSwitch>
      </StaticRouter>
    );
  }

  return (
    <BrowserRouter
      context={routingContext}
    >
      <RouteSwitch>
        {children}
      </RouteSwitch>
    </BrowserRouter>
  );
};

Router.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  routingContext: PropTypes.object,
  history: PropTypes.object,
};

Router.defaultProps = {
  children: null,
  url: '',
  routingContext: {},
  history: null,
};

export default Router;
