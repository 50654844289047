import React from 'react';
import PropTypes from 'prop-types';
import ReduxToastr from 'react-redux-toastr';

import Root from 'core/assets/js/layout/placeholder/Root.jsx';
import Content from 'core/assets/js/layout/placeholder/Content.jsx';
import Header from 'core/assets/js/layout/placeholder/Header.jsx';
import { TOASTR_DEFAULT_PROPS } from 'core/assets/js/constants';


const LayoutWithHeader = ({ children, layoutProps }) => {
  const { darkBackground, title } = layoutProps;
  return (
    <Root darkBackground={darkBackground}>
      <ReduxToastr newestOnTop {...TOASTR_DEFAULT_PROPS} />
      <Header title={title} />
      <Content>
        {children}
      </Content>
    </Root>
  );
};

LayoutWithHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  layoutProps: PropTypes.object,
};


LayoutWithHeader.defaultProps = {
  children: null,
  layoutProps: {},
};

export default LayoutWithHeader;
