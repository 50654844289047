import React from 'react';
import { withRouter } from 'react-router-dom';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { Field, useForm } from 'react-final-form';

import axios from 'core/assets/js/lib/tdAxios';
import { accountingSupplierSearchApiUrl } from 'integrations/urls';
import AsyncSelectionField from 'core/assets/js/components/FinalFormFields/AsyncSelectionField.jsx';

const IntegrationSupplierMappingForm = ({ match }) => {
  const { params: { orgAlias, integrationId } } = match;
  const { change, submit } = useForm();

  const handleSuggestionSelected = async (suggestion) => {
    change('remoteId', suggestion.value);

    await submit();
  };

  return (
    <>
      <strong className="d-block mb-2">Integration suppliers:</strong>
      <div className="search-form">
        <div
          className="w-100 px-0 position-relative kw-field d-block"
        >

          <Field
            name="remoteId"
            component="input"
            type="hidden"
          />

          <AsyncSelectionField
            loadOptions={async inputValue => {
              const { data } = await axios.get(accountingSupplierSearchApiUrl(
                orgAlias, integrationId, inputValue,
              ));
              return data;
            }}
            name="kw"
            onChange={handleSuggestionSelected}
            required
          />
        </div>
      </div>
    </>
  );
};

IntegrationSupplierMappingForm.propTypes = {
  match: routerMatchSpec.isRequired,
};

export default withRouter(IntegrationSupplierMappingForm);
