import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { has } from 'lodash';

import { isSSR } from 'core/assets/js/config/checks';
import { REACT_SELECT_STYLES } from 'core/assets/js/constants';
import withField from 'core/assets/js/components/withField.jsx';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';
import { finalFormFieldMetaSpec } from 'core/assets/js/lib/objectSpecs';

const filterOptions = (options, value) => {
  const opts = Array.isArray(options) && options.every(g => has(g, 'options'))
    ? options.map(g => g.options).flat()
    : options;

  return opts.filter(
    opt => (Array.isArray(value) ? value.includes(opt.value) : opt.value === value),
  );
};

/**
 * Redux form compatible select field
 *
 * It displays a bootstrap decorated input field with a label and an error
 * message
 */
const CustomSelectField = ({ // eslint-disable-line react/no-multi-comp
  additionalError,
  className,
  'data-testid': dataTestId,
  defaultOptionText,
  disabled,
  groupLabelRenderer,
  hint,
  input,
  isMulti,
  isSearchable,
  label,
  labelRenderer,
  meta: { error, initial: initialValue, pristine, submitError, touched },
  onItemsUpdated,
  OptionComponent,
  options,
  required,
  SingleValueComponent,
  sublabel,
  valueRenderer,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    filterOptions(options, input.value || initialValue),
  );

  const handleChange = (allItems, { action, removedValue, option: addedValue } = {}) => {
    let newOptions = [...selectedOptions];

    switch (action) {
      case 'select-option':
      case 'value':
        newOptions = Array.isArray(allItems) ? allItems : [allItems];
        break;
      case 'deselect-option':
      case 'remove-value':
      case 'pop-value':
        if (removedValue?.isFixed) {
          return;
        }

        newOptions = newOptions.filter(opt => opt.value !== removedValue.value);
        break;
      case 'clear':
        newOptions = newOptions.filter(opt => !!opt.isFixed);
        break;
      default:
        break;
    }

    setSelectedOptions(newOptions);

    let newValue = null;
    if (newOptions.length > 0) {
      newValue = isMulti ? newOptions.map(opt => opt.value) : newOptions[0].value;
    }

    input.onChange(newValue);
    onItemsUpdated(newValue, {
      addedValue: addedValue?.value,
      removedValue: removedValue?.value,
      allItems,
    });
  };

  useEffect(() => {
    setSelectedOptions(filterOptions(options, input.value));
  }, [input.value]);

  const showAdditionalError = pristine && additionalError;
  const hasError = ((error || submitError) && touched) || showAdditionalError;
  const groupClassName = ['form-group'];

  if (className) {
    groupClassName.push(className);
  }

  if (hasError) {
    groupClassName.push('has-error');
  }

  const styles = {
    ...REACT_SELECT_STYLES,
    multiValueRemove: (base, state) => {
      const attrs = REACT_SELECT_STYLES.multiValueRemove(base, state);

      if (state.data.isFixed) {
        Object.assign(attrs, { display: 'none' });
      }

      return attrs;
    },
  };

  return (
    <div className={groupClassName.join(' ')} data-testid={dataTestId}>
      { label && (
        <label
          htmlFor={input.name}
        >
          {label}
          {' '}
          {required && '*'}
          {' '}
          { sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
      )}

      { hint && (
        <span className="hint">
          {hint}
        </span>
      )}

      {showAdditionalError && (
        <span className="help-block d-inline-block mt-3">
          {additionalError}
        </span>
      )}

      <div className="form-control-container custom-select-field">
        <Select
          styles={styles}
          name={input.name}
          menuPlacement="auto"
          isSearchable={isSearchable}
          menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
          classNamePrefix="react-select"
          id={`${input.name}-dropdown`}
          value={selectedOptions}
          components={{ Option: OptionComponent, SingleValue: SingleValueComponent }}
          placeholder={defaultOptionText}
          isDisabled={options.length === 0 || disabled}
          isClearable={options.some(v => !v.isFixed)}
          onChange={handleChange}
          options={options}
          isMulti={isMulti}
          formatGroupLabel={groupLabelRenderer || (opts => opts.label)}
          getOptionValue={valueRenderer || (opt => opt.value)}
          getOptionLabel={labelRenderer || (opt => (opt.label))}
        />
      </div>

      { hasError && (
        <span className="help-block d-inline-block mt-3">
          {error || submitError}
        </span>
      )}
    </div>
  );
};

CustomSelectField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultOptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  groupLabelRenderer: PropTypes.func,
  hint: PropTypes.node,
  input: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  labelRenderer: PropTypes.func,
  meta: finalFormFieldMetaSpec,
  onItemsUpdated: PropTypes.func,
  OptionComponent: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  SingleValueComponent: PropTypes.func,
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  valueRenderer: PropTypes.func,
};

CustomSelectField.defaultProps = {
  additionalError: '',
  className: null,
  'data-testid': '',
  defaultOptionText: 'Select an option',
  disabled: false,
  groupLabelRenderer: null,
  hint: null,
  isMulti: false,
  isSearchable: false,
  label: '',
  labelRenderer: null,
  meta: {
    error: false,
    initial: undefined,
    pristine: true,
    touched: false,
  },
  onItemsUpdated: () => {},
  OptionComponent: components.Option,
  options: [],
  required: false,
  SingleValueComponent: components.SingleValue,
  sublabel: '',
  valueRenderer: null,
};

export default withField(CustomSelectField);
