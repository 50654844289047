import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { getListState } from 'core/assets/js/ducks/list';
import { projectClaimExpenseUrl } from 'projects/urls';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { fetchProjectsCreateExpenseForDS } from 'finance/assets/js/data-services/list';
import { financeExpensesUrl } from 'finance/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import ProjectViewItem from 'finance/assets/js/components/ProjectViewItem.jsx';

class ExpenseCreateView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchProjectsCreateExpenseForDS({
        orgAlias: params.orgAlias, componentName, url, params, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ExpenseCreateView';
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedProject: null,
    };

    this.handleProjectSelected = this.handleProjectSelected.bind(this);
  }

  handleProjectSelected(project) {
    this.setState({
      selectedProject: project,
    });
  }

  render() {
    const { projects, history, match: { params: { orgAlias } } } = this.props;

    const { selectedProject } = this.state;

    const breadcrumbs = [
      {
        title: 'Expenses',
        url: financeExpensesUrl(orgAlias),
      },
      {
        title: 'Select Project...',
        url: null,
      },
    ];

    const emptyListHeading = 'You are all set!';
    const emptyListMessage = 'There are currently no Projects you can claim an Expense for.';
    const hasProjects = projects && projects.length > 0;

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--finance">
          <div className="container">
            <TDApiConnected
              duck="list"
              component={this.constructor}
              skeletonComponent={ProjectCardSkeleton}
            >
              { !hasProjects && (
                <TDSystemMessage
                  title={emptyListHeading}
                  className="mb-4"
                >
                  {emptyListMessage}
                </TDSystemMessage>
              )}
              {hasProjects && (
                <Card className="mb-4 service-order-select-project">
                  <Card.Body>
                    <CheckableList
                      defaultValue={projects[0]}
                      items={projects}
                      itemComponent={ProjectViewItem}
                      itemComponentProps={{
                        orgAlias, serviceOrderType: SERVICE_ORDER_TYPE.EXPENSE,
                      }}
                      onItemChecked={this.handleProjectSelected}
                    />
                  </Card.Body>
                </Card>
              )}
              <div className="button-container">
                <div className="text-right">
                  <TDButton
                    variant={BS_STYLE.DEFAULT}
                    onClick={() => history.push(financeExpensesUrl(orgAlias))}
                    label={hasProjects ? 'Cancel' : 'Back to expenses'}
                  />

                  { hasProjects && (
                    <TDButton
                      variant={BS_STYLE.PRIMARY}
                      onClick={() => (
                        selectedProject && history.push(
                          projectClaimExpenseUrl(orgAlias, selectedProject.id),
                        )
                      )}
                      disabled={!selectedProject}
                      label="Claim new expense"
                    />
                  )}
                </div>
              </div>
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ExpenseCreateView.propTypes = {
  projects: PropTypes.arrayOf(projectSpec).isRequired,
  match: routerMatchSpec.isRequired,
  history: routerHistorySpec.isRequired,
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ExpenseCreateView.GetComponentName());
  return {
    projects: listState.items,
  };
};

const ExpenseCreateViewConnected = connect(mapStateToProps)(ExpenseCreateView);
export default withRouter(ExpenseCreateViewConnected);
