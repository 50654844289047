import React from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ICON, MODAL_SIZES, IMG_SIZE, USER_TYPE_LABEL } from 'core/assets/js/constants';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import ReadMore from 'core/assets/js/components/ReadMore.jsx';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import AnswersList from 'core/assets/js/components/AnswersList.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';

class ContactDetailsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleCloseContactDetailsPanel = this.handleCloseContactDetailsPanel.bind(this);
  }

  handleCloseContactDetailsPanel() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      contactDetailsPanelId, isContactDetailsPanelOpen, panelActions,
      contact: {
        avatar, fullName, email, phone, resume, address, attachments,
        skills, jobTitle, role, answers, notes,
      },
      'data-testid': dataTestId,
      hasUserRole,
    } = this.props;
    const panelBody = (
      <div className="contact-preview">
        <div className="d-flex justify-content-between align-items-center">
          <span className={`${ICON.TIMES}`} onClick={this.handleCloseContactDetailsPanel} />
          { !hasUserRole && (
            <TDDropButton
              className="mr-3 position-relative"
              toggleEl={(
                <span className="p-3 mr-n3">
                  <i className={ICON.ELLIPSIS} />
                </span>
              )}
            >
              { panelActions }
            </TDDropButton>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center pb-3">
          <ProfilePic
            data-testid="contact-details-panel-profile-pic"
            url={avatar}
            alt={fullName}
            size={[IMG_SIZE.LARGE, IMG_SIZE.LARGE]}
          />
        </div>
        <div className="pb-3">
          <div
            className="row d-flex justify-content-center align-items-center name"
            data-testid="contact-details-panel-name"
          >
            {fullName}
          </div>
          { jobTitle && (
            <div
              className="row d-flex justify-content-center align-items-center pt-1 job-title"
              data-testid="contact-details-panel-job-title"
            >
              {jobTitle}
            </div>
          )}
        </div>
        <div className="information py-4">
          { email && (
            <div
              className="d-flex align-items-center pt-2 email"
              data-testid="contact-details-panel-email"
            >
              <span className={`${ICON.EMAIL} mr-3`} />
              { email }
            </div>
          )}
          { role && (
            <div
              className="d-flex align-items-center pt-4 role"
              data-testid="contact-details-panel-role"
            >
              <span className={`${ICON.USER_TAG} mr-3`} />
              { USER_TYPE_LABEL[role] }
            </div>
          )}
          { phone && (
            <div
              className="d-flex align-items-center pt-4 phone"
              data-testid="contact-details-panel-phone"
            >
              <span className={`${ICON.PHONE_ALT} mr-3`} />
              { phone }
            </div>
          )}
          { address && (
            <div
              className="d-flex align-items-center pt-4 address"
              data-testid="contact-details-panel-address"
            >
              <span className={`${ICON.MAP_PIN_ALT} mr-4`} />
              { address.description }
            </div>
          )}
          { resume && (
            <div
              className="d-flex align-items-center pt-3"
              data-testid="contact-details-panel-resume"
            >
              <span className={`${ICON.NOTE} align-self-start mr-3`} />
              <div>
                <ReadMore
                  text={resume}
                />
              </div>
            </div>
          )}
          { notes && (
            <div
              className="d-flex align-items-center pt-3"
              data-testid="contact-details-panel-notes"
            >
              <span className={`${ICON.NOTE} align-self-start mr-3`} />
              <div>
                <MarkdownText
                  withBreaksPlugin
                  text={notes}
                />
              </div>
            </div>
          )}
        </div>
        <AnswersList
          answers={answers}
          labelClass="h3 mb-2 text-dark markdown-content"
          wrapperClass="mb-4"
          valueClass="value"
        />
        {!isEmpty(attachments) && (
          <div className="py-3 attachments">
            <AttachmentsList
              attachments={attachments}
              data-testid="contact-details-panel-attachments-list"
              invertedColors
              label={null}
            />
          </div>
        )}
        {!isEmpty(skills) && (
          <div className="pt-3 skills">
            <SkillList data-testid="contact-details-panel-skill-list" skills={skills} />
          </div>
        )}
      </div>
    );
    return (
      <ModalPanel
        data-testid={dataTestId}
        modalId={contactDetailsPanelId}
        open={isContactDetailsPanelOpen}
        size={MODAL_SIZES.SMALL}
        body={panelBody}
        noFooter
        noHeader
      />
    );
  }
}

ContactDetailsPanel.propTypes = {
  contact: PropTypes.object.isRequired,
  contactDetailsPanelId: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  hasUserRole: PropTypes.bool,
  isContactDetailsPanelOpen: PropTypes.bool,
  panelActions: PropTypes.node.isRequired,
};

ContactDetailsPanel.defaultProps = {
  'data-testid': '',
  isContactDetailsPanelOpen: false,
  hasUserRole: false,
};


const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state, props) => {
  const { contactDetailsPanelId } = props;
  return {
    isContactDetailsPanelOpen: getIsModalOpen(state, contactDetailsPanelId),
  };
};

const ContactModalPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactDetailsPanel);

export default ContactModalPanelConnected;
