import { get } from 'lodash';

import { WORKSHEET_STATUS, WORKSHEET_STATUS_GROUP, SERVICE_ORDER_TYPE_LABEL } from 'projects/assets/js/constants';

// constants used only for the pie chart and pie chart table

const PIE_CHART_USER_TYPE = {
  PROVIDER: 0,
  ANY_MANAGER: 1,
};

const PIE_CHART_USER_TYPE_LABEL = {
  [PIE_CHART_USER_TYPE.PROVIDER]: 'Provider',
  [PIE_CHART_USER_TYPE.ANY_MANAGER]: 'Manager',
};

const colors = {
  [PIE_CHART_USER_TYPE.ANY_MANAGER]: {
    mainCategory: '#3aad4c',
    subCategories: {
      [WORKSHEET_STATUS.APPROVED]: '#3ABC4E',
      [WORKSHEET_STATUS.RAISED]: '#9CDDA6',
    },
  },
  [PIE_CHART_USER_TYPE.PROVIDER]: {
    mainCategory: '#187ab2',
    subCategories: {
      [WORKSHEET_STATUS.APPROVED]: '#1584C6',
      [WORKSHEET_STATUS.RAISED]: '#8AC1E2',
    },
  },
};

const aggregatePieChartAnalytics = ({ type, analytics }) => {
  let mainCategories = [];
  const hasData = get(analytics, `byType[${type}].byIsManager`);
  const structuredDataForPieChart = [];
  if (hasData) {
    const bySelectedType = analytics.byType[type];
    Object.keys(bySelectedType.byIsManager).forEach((roleKey) => {
      const entry = bySelectedType.byIsManager[roleKey];
      structuredDataForPieChart.push({
        totalAmount: +entry.amount,
        name: `${PIE_CHART_USER_TYPE_LABEL[roleKey]}s`,
        subCategories: Object.keys(entry.byStatusGroup).map(statusKey => ({
          amount: +entry.byStatusGroup[statusKey].amount,
          color: colors[roleKey].subCategories[statusKey],
          label: +statusKey === +WORKSHEET_STATUS_GROUP.PENDING ? 'Pending' : 'Approved',
          count: entry.byStatusGroup[statusKey].count,
        })),
        userRole: PIE_CHART_USER_TYPE[roleKey],
        mainCategoryColor: colors[roleKey].mainCategory,
        count: entry.count,
      });
    });
    mainCategories = structuredDataForPieChart.map(entry => entry.name);
  }
  return {
    structuredDataForPieChart,
    mainCategories,
    hasData,
  };
};

const pieChartConfig = ({ type, analytics, currencySymbol }) => {
  // eslint-disable-next-line no-unused-vars
  const subcategoryData = [];
  const mainCategoryData = [];
  const data = [];
  const currency = currencySymbol;

  const {
    structuredDataForPieChart, mainCategories, hasData,
  } = aggregatePieChartAnalytics({ type, analytics });

  structuredDataForPieChart.forEach((entry) => {
    // prepare specific configuration object passed to pie chart
    const { count, totalAmount, name, mainCategoryColor, subCategories } = entry;
    data.push({
      count,
      categoryLabel: `${SERVICE_ORDER_TYPE_LABEL[type]}s`,
      y: totalAmount,
      color: mainCategoryColor,
      drilldown: {
        name,
        categories: subCategories.map(subCat => subCat.label),
        data: subCategories.map(subCat => subCat.amount),
        colors: subCategories.map(subCat => subCat.color),
        count: subCategories.map(subCat => subCat.count),
      },
    });
  });
  const dataLen = data.length;

  // Build the data arrays
  for (let i = 0; i < dataLen; i += 1) {
    mainCategoryData.push({
      name: mainCategories[i],
      y: data[i].y,
      color: data[i].color,
      count: data[i].count,
      categoryLabel: data[i].categoryLabel,
      currency,
    });

    const drillDataLen = data[i].drilldown.data.length;
    for (let j = 0; j < drillDataLen; j += 1) {
      subcategoryData.push({
        name: data[i].drilldown.categories[j],
        y: data[i].drilldown.data[j],
        color: data[i].drilldown.colors[j],
        count: data[i].drilldown.count[j],
        categoryLabel: data[i].categoryLabel,
        currency,
      });
    }
  }

  return {
    currency,
    tooltip: {
      useHTML: true,
      outside: true,
      style: {
        width: 'auto',
        whiteSpace: 'wrap',
      },
      headerFormat: '<div style="margin-bottom: 5px; border-bottom: 1px solid #A8A8A8; padding-bottom: 2px;"><strong>{point.key}</strong></div><table>',
      pointFormat: '<tr><td><strong>{point.categoryLabel} #:</strong></td><td style="text-align: right">{point.count}</td></tr>'
        + '<tr><td><strong>Total:</strong></td><td style="text-align: right"> {point.currency}{point.y:,.2f}</td></tr>',
      footerFormat: '</table>',
    },
    series: hasData ? [{
      name: `${SERVICE_ORDER_TYPE_LABEL[type]}s`,
      data: mainCategoryData,
      size: '60%',
    }, {
      name: `${SERVICE_ORDER_TYPE_LABEL[type]}s`,
      data: subcategoryData,
      size: '80%',
      innerSize: '60%',
      id: 'categories',
    }] : [],
  };
};

export {
  aggregatePieChartAnalytics,
  pieChartConfig,
  PIE_CHART_USER_TYPE,
};
