import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import { ICON } from 'core/assets/js/constants';
import InputEmailAsText from 'core/assets/js/components/FinalFormFields/InputEmailAsText.jsx';
import InputEmailAsTag from 'core/assets/js/components/FinalFormFields/InputEmailAsTag.jsx';
import { PEOPLE_INVITATION_WORDINGS } from 'people/assets/js/constants';

const InvitePeopleAdding = ({ peopleType }) => {
  const [isSingleAddView, setIsSingleAddView] = useState(true);
  const { [peopleType]: { header, subHeader, addPopoverContent } } = PEOPLE_INVITATION_WORDINGS;
  const popOverRef = useRef(null);

  return (
    <div className="invite-people-adding">
      <h2>{header}</h2>
      <h3 className="mb-0">{subHeader}</h3>

      <p className="imitate-link mt-4 mb-4">
        <span onClick={() => setIsSingleAddView(!isSingleAddView)}>
          {isSingleAddView ? 'Add all at once' : 'Add one by one'}
        </span>

        <OverlayTrigger
          delay={{ show: 250, hide: 0 }}
          overlay={(
            <Popover placement="bottom">
              <Popover.Title>
                All at once
                <span
                  className="popover__close-button"
                  onClick={() => popOverRef.current && popOverRef.current.hide()}
                >
                  <i className={ICON.TIMES} />
                </span>
              </Popover.Title>

              <Popover.Content>{addPopoverContent}</Popover.Content>
            </Popover>
          )}
          placement="right"
          ref={popOverRef}
          rootClose
          trigger="click"
        >
          <a className="ml-3 adding-info-overlay-icon">
            <i className={ICON.INFO} />
          </a>
        </OverlayTrigger>
      </p>

      {!isSingleAddView && (
        <InputEmailAsText
          label="Copy and paste a comma concatenated list of emails"
          name="emails"
          placeholder="e.g someone@example.com"
          required
        />
      )}

      {isSingleAddView && (
        <InputEmailAsTag
          name="emails"
          placeholder="e.g someone@example.com"
          required
        />
      )}
    </div>
  );
};

InvitePeopleAdding.propTypes = {
  peopleType: peopleTypeSpec.isRequired,
};

const mapStateToProps = (state, props) => ({
  orgAlias: props.match.params?.orgAlias,
  peopleType: props.match.params?.peopleType,
});

const InvitePeopleAddingConnect = connect(
  mapStateToProps,
)(InvitePeopleAdding);

export default withRouter(InvitePeopleAddingConnect);
