import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import DocumentSelectField from 'core/assets/js/components/FinalFormFields/DocumentSelectField.jsx';
import SelectedDocumentsLinks from 'documents/assets/js/components/SelectedDocumentsLinks.jsx';
import { documentOptionsSpec } from 'documents/assets/js/lib/objectSpecs';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import AllowAccessToOrgCreator from 'core/assets/js/components/AllowAccessToOrgCreator.jsx';
import { interviewCreateUrl } from 'interviews/urls';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import { interviewSpec } from 'interviews/assets/js/lib/objectSpecs';
import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import { INVITATION_EXPIRATION_DAYS_OPTIONS, INVITATION_NEVER_EXPIRE_VALUE } from 'invitations/assets/js/constants';
import InterviewTemplateSelect from 'settings/assets/js/components/InterviewTemplateSelect.jsx';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { PEOPLE_INVITATION_WORDINGS, USER_EMPLOYMENT_TYPE } from 'people/assets/js/constants';
import { getDocumentIdsFromSelectedOnboardingForm } from 'people/assets/js/lib/utils';

export const COMPONENT_NAME = 'InvitePeopleDetails';

class InvitePeopleDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSingleAddView: true,
    };

    this.switchView = this.switchView.bind(this);
    this.onInterviewTemplateSelect = this.onInterviewTemplateSelect.bind(this);
  }

  onInterviewTemplateSelect(template) {
    const { updateFormField } = this.props;
    updateFormField('message', template.content);
  }

  switchView() {
    this.setState(state => ({
      isSingleAddView: !state.isSingleAddView,
    }));
  }

  render() {
    const {
      activeOrg: {
        documents_with_countersigning_enabled: documentsWithCountersigningEnabled,
        onboarding_forms_are_mandatory: onboardingFormsAreMandatory,
      },
      canManageInstantApproval,
      countersigningEnabled: countersigningEnabledIn,
      documentOptions,
      formValues,
      headerText,
      interviews,
      invitationsExpire,
      location,
      orgAlias,
      peopleType,
    } = this.props;
    const countersigningEnabled = countersigningEnabledIn && documentsWithCountersigningEnabled;
    const { employment_type: employmentType } = formValues;
    const isEmployee = parseInt(employmentType, 10) === USER_EMPLOYMENT_TYPE.EMPLOYEE;
    const expirationDaysOptions = [...INVITATION_EXPIRATION_DAYS_OPTIONS];
    const addNeverExpiresOption = !invitationsExpire && !expirationDaysOptions.find(({ text }) => text === 'Never expire');
    if (addNeverExpiresOption) {
      expirationDaysOptions.push({ value: INVITATION_NEVER_EXPIRE_VALUE, text: 'Never expire' });
    }

    const onboardingFormEnabled = Array.isArray(formValues.onboardingFormEnabled)
      && formValues.onboardingFormEnabled.length === 1
      && formValues.onboardingFormEnabled[0].value === true;

    const interviewSubLabel = (
      <AllowAccessToOrgCreator>
        Build a
        {' '}
        <Link to={interviewCreateUrl(orgAlias, location.pathname)}>
          new Onboarding form
        </Link>
      </AllowAccessToOrgCreator>
    );

    const instantApprovalOptions = [{ text: 'Instant approval', value: true }];

    const templateSelectComponent = (
      <InterviewTemplateSelect onSelect={this.onInterviewTemplateSelect} />
    );

    const { [peopleType]: { instantApproval, messageSublabel } } = PEOPLE_INVITATION_WORDINGS;

    const advancedOptions = (
      <React.Fragment>
        {canManageInstantApproval && !isEmployee && (
          <CheckboxField
            name="approved"
            type="checkbox"
            label="Instant approval"
            sublabel={instantApproval}
            options={instantApprovalOptions}
          />
        )}

        <SelectField
          name="expiration_days"
          type="text"
          optionsMapping={expirationDaysOptions}
          defaultOptionText={null}
          label="Invitation expires in"
          sublabel="The invitation will expire in the designated number of days. You can always resend the invitation to renew it."
        />
      </React.Fragment>
    );

    const onboardingFormDocumentIds = getDocumentIdsFromSelectedOnboardingForm(
      formValues, interviews, onboardingFormsAreMandatory,
    );

    return (
      <div>
        <h2>{headerText}</h2>

        <TextAreaField
          label="Custom message"
          sublabel={messageSublabel}
          templateSelectComponent={templateSelectComponent}
          name="message"
        />

        { !isEmployee && (
          <>
            {!onboardingFormsAreMandatory && (
              <CheckboxField
                name="onboardingFormEnabled"
                options={[{
                  text: `Do you want the ${peopleType} to complete an onboarding form?`,
                  value: true,
                }]}
              />
            )}
            <SelectField
              data-testid="select-onboarding-form"
              defaultOptionText={null}
              disabled={!onboardingFormsAreMandatory && !onboardingFormEnabled}
              label="Onboarding Form"
              name="interview"
              optionsMapping={interviews.map(i => ({ value: i.id, text: i.name }))}
              sublabel={interviewSubLabel}
              type="text"
            />
            <SelectedDocumentsLinks
              className="mb-5"
              documentOptions={documentOptions}
              label="Documents included in onboarding form"
              selectedDocumentIds={onboardingFormDocumentIds}
            />
          </>
        )}

        <DocumentSelectField
          allowCountersigners={countersigningEnabled}
          className="mt-2"
          name="documents"
          showSelected
          sublabel={`Do you require invited ${peopleType} to review and agree to any legal
            documents before obtaining access to the organization?`}
        />

        <AreaCollapsible
          data-testid="invite-people-advanced-options-collapsible"
          fieldSet
          contentChildren={advancedOptions}
          headingChildren={<span>Advanced Options</span>}
          keepInDOM
        />
      </div>
    );
  }
}

InvitePeopleDetails.propTypes = {
  activeOrg: orgSpec.isRequired,
  canManageInstantApproval: PropTypes.bool.isRequired,
  countersigningEnabled: PropTypes.bool,
  documentOptions: PropTypes.arrayOf(documentOptionsSpec).isRequired,
  formValues: PropTypes.object,
  headerText: PropTypes.string,
  interviews: PropTypes.arrayOf(interviewSpec),
  invitationsExpire: PropTypes.bool,
  location: PropTypes.object,
  orgAlias: PropTypes.string.isRequired,
  peopleType: peopleTypeSpec.isRequired,
  updateFormField: PropTypes.func.isRequired,
};

InvitePeopleDetails.defaultProps = {
  countersigningEnabled: true,
  formValues: {},
  headerText: 'Details',
  interviews: [],
  location: {},
  invitationsExpire: false,
};

const mapStateToProps = (state, props) => {
  const activeOrg = selectActiveOrg(state);
  const activeUserCard = selectActiveUserCard(state);
  const isAnyManager = activeUserCard.userRole.isAnyManager;
  const canManageInstantApproval = (
    !!activeOrg.should_high_manager_manage_instant_approval && isAnyManager
  );
  const { params } = props.match;

  return {
    activeOrg,
    canManageInstantApproval,
    orgAlias: params?.orgAlias,
  };
};

const InvitePeopleDetailsConnect = connect(
  mapStateToProps,
)(InvitePeopleDetails);

export default withRouter(InvitePeopleDetailsConnect);
