import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { USER_CARD_STATUS } from 'core/assets/js/constants';
import { getListState, fetchListDS, listBulkUpdateAC, listUpdateItemAC } from 'core/assets/js/ducks/list';
import { refreshPendingInvitationsDS, getPendingInvitations } from 'core/assets/js/ducks/pendingInvitations';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import OrganizationCardItem from 'organizations/assets/js/components/OrganizationCardItem.jsx';
import OrgInvitationCardItem from 'organizations/assets/js/components/OrgInvitationCardItem.jsx';
import { markOrgDefaultDS } from 'organizations/assets/js/data-services/list';
import { onboardingProgressListApiUrl } from 'organizations/urls';

class OrganizationsView extends React.Component {
  static FetchData({
    dispatch, url, authedAxios, componentName, querystring,
  }) {
    const apiUrl = onboardingProgressListApiUrl(url);
    return Promise.all([
      dispatch(refreshPendingInvitationsDS({ authedAxios })),
      dispatch(fetchListDS({ url: apiUrl, querystring, componentName, authedAxios })),
    ]);
  }

  static GetComponentName() {
    return 'OrganizationsView';
  }

  constructor(props) {
    super(props);
    this.handleMarkDefaultOrg = this.handleMarkDefaultOrg.bind(this);
  }

  handleMarkDefaultOrg(orgAlias) {
    const { dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();
    dispatch(markOrgDefaultDS(orgAlias)).then(({ userProfile }) => {
      dispatch(listBulkUpdateAC([], { isDefault: false }, componentName));
      dispatch(listUpdateItemAC(userProfile.defaultUserCardId, { isDefault: true }, componentName));
    });
  }

  render() {
    const {
      orgInvitations,
      pagination,
      progresses,
    } = this.props;

    const activeOrgs = orderBy(
      progresses.filter(p => (
        (p.percent && p.percent === 100)
        || !p.percent
        || p.status === USER_CARD_STATUS.REJECTED
        || p.status === USER_CARD_STATUS.DEACTIVATED
      )),
      ['status', p => p.organization.name],
      ['asc', 'asc'],
    );

    const pendingOrgs = progresses.filter(p => (
      (p.percent && p.percent < 100)
      && p.status !== USER_CARD_STATUS.REJECTED
      && p.status !== USER_CARD_STATUS.DEACTIVATED
    ));

    const breadcrumbs = [
      {
        title: 'Organisations',
        url: null,
      },
    ];

    const groupedInvitations = [];
    if (!isEmpty(orgInvitations)) {
      Object.values(orgInvitations).forEach(arr => {
        groupedInvitations.push(...arr.filter(i => !i.hasExpired));
      });
    }

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--organizations">
          <div className="container">
            <TDApiConnected duck="list" component={this.constructor}>
              {Array.isArray(activeOrgs) && activeOrgs.length > 0 && (
                <section className="mb-5 pb-3">
                  <div className="d-flex mb-4 align-items-end">
                    <h2 className="my-0">Approved organizations</h2>
                    <span className="ml-auto mr-3 d-none d-md-block">Choose a default organization:</span>
                  </div>

                  <div className="rounded shadow-sm p-4 bg-white">
                    <TDList
                      pagination={pagination}
                      listClassName="organization-list"
                      items={activeOrgs}
                      cardItem={{
                        component: OrganizationCardItem,
                        props: {
                          onMarkedDefault: this.handleMarkDefaultOrg,
                          showDefaultCheckbox: true,
                        },
                      }}
                      emptyListMessage="You have not been invited to any organizations yet"
                    />
                  </div>
                </section>
              )}
            </TDApiConnected>

            {Array.isArray(pendingOrgs) && pendingOrgs.length > 0 && (
              <section className="mb-5 pb-3">
                <h2 className="mt-0 mb-4">Pending organizations</h2>

                <div className="rounded shadow-sm p-4 bg-white">
                  <TDList
                    pagination={pagination}
                    listClassName="organization-list"
                    items={pendingOrgs}
                    cardItem={{
                      component: OrganizationCardItem,
                      props: {
                        onMarkedDefault: this.handleMarkDefaultOrg,
                        showDefaultCheckbox: true,
                      },
                    }}
                  />
                </div>
              </section>
            )}

            {Array.isArray(groupedInvitations) && groupedInvitations.length > 0 && (
              <section>
                <h2 className="mt-0 mb-4">Pending invitations</h2>
                <div className="rounded shadow-sm p-4 bg-white">
                  <TDList
                    listClassName="organization-list"
                    items={groupedInvitations}
                    cardItem={{
                      component: OrgInvitationCardItem,
                      props: {
                        onMarkedDefault: this.handleMarkDefaultOrg,
                        showDefaultCheckbox: true,
                      },
                    }}
                  />
                </div>
              </section>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OrganizationsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  orgInvitations: PropTypes.object,
  pagination: paginationSpec.isRequired,
  progresses: PropTypes.array.isRequired,
};

OrganizationsView.defaultProps = {
  orgInvitations: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, OrganizationsView.GetComponentName());
  return {
    orgInvitations: getPendingInvitations(state, 'organization'),
    pagination: listState.pagination,
    progresses: listState.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const OrganizationsViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationsView);
export default withRouter(OrganizationsViewConnect);
