import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectRemoveMemberDS } from 'projects/assets/js/data-services/form';
import { BS_STYLE } from 'core/assets/js/constants';
import { projectMemberSpec } from 'projects/assets/js/lib/objectSpecs';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';

class RemoveProjectMemberModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submissionFailed: false,
      submissionError: null,
    };

    this.handleRemovalConfirmation = this.handleRemovalConfirmation.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleRemovalConfirmation() {
    const {
      dispatch, match: { params: { orgAlias, id: projectId } }, projectMember, onMemberRemoved,
    } = this.props;

    return new Promise((resolve, reject) => (
      projectRemoveMemberDS({
        orgAlias, projectId, userId: projectMember.userId,
      }).then((response) => {
        const { projectMember: removedProjectMember } = response.data;
        dispatch(modalCloseAC());
        onMemberRemoved(removedProjectMember);
        toastr.success('Project Member removed successfully');
        resolve(removedProjectMember);
      }).catch((err) => {
        const error = err.response.data._error;
        this.setState({
          submissionFailed: true,
          submissionError: error,
        });

        reject(error);
      })
    ));
  }

  handleModalClose() {
    const { dispatch } = this.props;

    dispatch(modalCloseAC());

    this.setState({
      submissionFailed: false,
      submissionError: null,
    });
  }

  render() {
    const { isModalOpen, projectMember } = this.props;

    if (!projectMember || !projectMember.userCard) {
      return null;
    }

    const { submissionFailed, submissionError } = this.state;
    const { userCard: { user: { profile } } } = projectMember;
    const heading = `Remove ${profile.firstName} from the Project`;

    if (submissionFailed) {
      return (
        <ModalSimple
          heading={heading}
          open={isModalOpen}
          body={(
            <React.Fragment>
              <p>
                {`Failed to remove ${profile.firstName} from the project`}
              </p>
              <p>
                { submissionError }
              </p>
            </React.Fragment>
          )}
          onClose={this.handleModalClose}
        />
      );
    }

    let body = null;
    if (projectMember.isPaymentsReviewer) {
      body = (
        <p>
          You cannot remove the current payments reviewer from the project. In order to do so, you
          have to first appoint another manager as the payments reviewer.
        </p>
      );
    } else {
      body = (
        <>
          <p>
            {[
              `By removing ${profile.firstName} from the project you revoke their view permissions
              and so they will not be able to view the project.`,
              !projectMember.wasPaymentsReviewer ? '' : `Also, ${profile.firstName} will not be able
              to access anymore the worksheets and expenses they have already reviewed.`,
              'However, you can always add them back should you change your mind.',
            ].join(' ')}
          </p>
          <p>
            Are you sure you want to remove them from the project?
          </p>
        </>
      );
    }

    return (
      <ModalConfirm
        data-testid="remove-project-member-modal"
        open={isModalOpen}
        heading={heading}
        body={body}
        closeOnConfirm={false}
        confirmStyle={BS_STYLE.DANGER}
        onClose={this.handleModalClose}
        onConfirm={this.handleRemovalConfirmation}
        confirmLabel="Remove"
        cancelLabel="Cancel"
        showConfirmButton={!projectMember.isPaymentsReviewer}
      />
    );
  }
}

RemoveProjectMemberModal.propTypes = {
  modalId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  match: routerMatchContentsSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  projectMember: projectMemberSpec,
  onMemberRemoved: PropTypes.func.isRequired,
};

RemoveProjectMemberModal.defaultProps = {
  isModalOpen: false,
  projectMember: null,
};

const mapStateToProps = (state, props) => {
  const payload = getModalPayload(state, props.modalId);
  return {
    isModalOpen: getIsModalOpen(state, props.modalId),
    projectMember: payload && payload.projectMember ? payload.projectMember : null,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const RemoveProjectMemberModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveProjectMemberModal);


export default withRouter(RemoveProjectMemberModalConnected);
