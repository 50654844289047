/* globals File, FormData */
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import DocumentFormConfirmModal, { MODAL_ID } from 'documents/assets/js/components/DocumentFormConfirmModal.jsx';
import DocumentFormDetails from 'documents/assets/js/components/DocumentFormDetails.jsx';
import DocumentFormDocument from 'documents/assets/js/components/DocumentFormDocument.jsx';
import DocumentFormEsignEdit from 'documents/assets/js/components/DocumentFormEsignEdit.jsx';
import DocumentFormEsignUpload from 'documents/assets/js/components/DocumentFormEsignUpload.jsx';
import DocumentFormType from 'documents/assets/js/components/DocumentFormType.jsx';
import {
  DOCUMENT_CONTRACT_TYPE,
  DOCUMENT_CONTRACT_TYPE_VALUES,
  DOCUMENT_CREATE_STEPS,
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_VALUES,
} from 'documents/assets/js/constants';
import { getDocumentDetailsErrors } from 'documents/assets/js/lib/utils';
import {
  documentCheckIfValidSignNowDocumentApiUrl,
  documentCreateSignNowDocumentApiUrl,
  documentGetSignNowDocumentEditUrlApiUrl,
  documentsUrl,
} from 'documents/urls';

const esignSelected = values => (
  (
    values.contractType
    && parseInt(values.contractType, 10) === DOCUMENT_CONTRACT_TYPE.ESIGN
  )
  || (values.type && parseInt(values.type, 10) === DOCUMENT_TYPE.ESIGN)
);

const getEmbedUrl = async (orgAlias, signNowDocumentId) => {
  const { data: { embedUrl } } = await axios.get(documentGetSignNowDocumentEditUrlApiUrl(
    orgAlias, signNowDocumentId,
  ));
  return embedUrl;
};

const DocumentForm = ({
  contentPrefix,
  dispatch,
  history,
  initialValues,
  match: { params: { orgAlias } },
  onSubmit,
  secondarySubmitAction,
  secondarySubmitButtonTitle,
  secondarySubmitLoading,
  versionHelperText,
  versionNotIn,
  versionSubLabel,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  return (
    <Wizard
      className="create-document-form"
      extraContent={(
        <DocumentFormConfirmModal onConfirm={onSubmit} setPageNumber={setPageNumber} />
      )}
      initialValues={initialValues}
      onCancel={() => history.push(documentsUrl(orgAlias))}
      onStepChange={newPageNumber => setPageNumber(newPageNumber)}
      onSubmit={() => {
        dispatch(modalOpenAC(MODAL_ID));
      }}
      pageNumber={pageNumber}
      secondarySubmitAction={(
        secondarySubmitAction ? values => secondarySubmitAction(values, setPageNumber) : null
      )}
      secondarySubmitButtonTitle={secondarySubmitButtonTitle}
      secondarySubmitLoading={secondarySubmitLoading}
      submitBtnTitle="Publish"
    >
      <Wizard.Page
        beforePageChange={formValues => getDocumentDetailsErrors(formValues, versionNotIn)}
        component={DocumentFormDetails}
        contentPrefix={contentPrefix}
        componentProps={{ versionHelperText, versionSubLabel }}
        step={DOCUMENT_CREATE_STEPS.DETAILS}
        title="Details"
      />
      <Wizard.Page
        beforePageChange={async values => {
          const contractType = values.contractType && parseInt(values.contractType, 10);
          if (!DOCUMENT_CONTRACT_TYPE_VALUES.includes(contractType)) {
            return { type: 'Please select a contract type' };
          }
          const type = values.type && parseInt(values.type, 10);
          if (!DOCUMENT_TYPE_VALUES.includes(type)) {
            return { type: 'Please select a contract template type' };
          }
          if (
            (
              contractType === DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH
              && type === DOCUMENT_TYPE.ESIGN
            )
            || (
              contractType === DOCUMENT_CONTRACT_TYPE.ESIGN
              && type !== DOCUMENT_TYPE.ESIGN
            )
          ) {
            return { type: 'Please select a valid contract template type' };
          }
          if (
            type === DOCUMENT_TYPE.ESIGN
            && values.hasCountersigners?.isYes
            && (!values.countersignersCount || !/^\d+$/.test(values.countersignersCount.toString()))
          ) {
            return { countersignersCount: 'Please enter how many countersignatures are required' };
          }
          return null;
        }}
        component={DocumentFormType}
        contentPrefix={contentPrefix}
        nextBtnTitle="Select"
        step={DOCUMENT_CREATE_STEPS.TYPE}
        title="Type"
      />
      <Wizard.Page
        beforePageChange={async values => {
          try {
            if (values.signNowDocumentId) {
              // We already have the file uploaded to sign now
              if (values.embedUrl) {
                // We have already generated an embed url for editing the sign now document
                return null;
              }
              const embedUrl = await getEmbedUrl(orgAlias, values.signNowDocumentId);
              return { values: { ...values, embedUrl } };
            }
            if (!(values.eSignFile instanceof File)) {
              return { eSignFile: 'Please select a file to create esign from' };
            }
            const formData = new FormData();
            formData.append('eSignFile', values.eSignFile);
            formData.append(
              'countersignersCount',
              (values.hasCountersigners?.isYes && values.countersignersCount) || 0,
            );
            const { data } = await axios.post(
              documentCreateSignNowDocumentApiUrl(orgAlias), formData,
            );
            return {
              values: { ...values, embedUrl: data.embedUrl, signNowDocumentId: data.documentId },
            };
          } catch (err) {
            return parseAxiosErrorForFinalForm(err);
          }
        }}
        component={DocumentFormEsignUpload}
        componentProps={{ isNewVersion: !!versionHelperText }}
        nextBtnTitle="Upload"
        showStep={esignSelected}
        step={DOCUMENT_CREATE_STEPS.ESIGN_UPLOAD}
        title="Esign file upload"
      />
      <Wizard.Page
        beforePageChange={async (
          { countersignersCount, hasCountersigners, hasInvitingManagerFields, signNowDocumentId },
          { change },
        ) => {
          try {
            await axios.post(
              documentCheckIfValidSignNowDocumentApiUrl(orgAlias, signNowDocumentId),
              {
                countersignersCount: (hasCountersigners?.isYes && countersignersCount) || 0,
                hasInvitingManagerFields: !!hasInvitingManagerFields,
              },
            );
            return null;
          } catch (err) {
            if (err.response?.data?._meta?.isValidation) {
              // If the document is invalid, we have to get a new embed url, as the user may have
              // already saved in the SignNow UI
              try {
                const embedUrl = await getEmbedUrl(orgAlias, signNowDocumentId);
                // SignNow could return the same URL, so add a property to force a reload
                change(
                  'embedUrl',
                  `${embedUrl}${embedUrl.includes('?') ? '&' : '?'}reload=${Date.now()}`,
                );
              } catch (err2) {
                toastr.error('Oh Snap!', err2.response?.data?._error || err2.message);
              }
            }
            const formError = err.response?.data?._error || err.message;
            toastr.error('Oh Snap!', formError);
            return { [FORM_ERROR]: formError };
          }
        }}
        component={DocumentFormEsignEdit}
        showStep={esignSelected}
        step={DOCUMENT_CREATE_STEPS.ESIGN_EDIT}
        title="Esign file edit"
      />
      <Wizard.Page
        beforePageChange={async ({ content, files, type }) => {
          if (type === DOCUMENT_TYPE.CONTENT && !content) {
            return { content: 'Please provide valid content' };
          }
          if (type === DOCUMENT_TYPE.FILE) {
            let parsedFiles = files;
            if (typeof parsedFiles === 'string') {
              try {
                parsedFiles = JSON.parse(files);
              } catch (_) {} // eslint-disable-line no-empty
            }
            if (!Array.isArray(parsedFiles) || parsedFiles.length === 0) {
              return { files: 'Please provide a valid file' };
            }
          }
          return null;
        }}
        component={DocumentFormDocument}
        contentPrefix={contentPrefix}
        showStep={values => !esignSelected(values)}
        step={DOCUMENT_CREATE_STEPS.DOCUMENT}
        title="Contract Template"
        withStepContentContainer={false}
      />
    </Wizard>
  );
};

DocumentForm.GetComponentName = () => 'DocumentForm';

DocumentForm.propTypes = {
  contentPrefix: PropTypes.element,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  initialValues: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onSubmit: PropTypes.func.isRequired,
  secondarySubmitAction: PropTypes.func,
  secondarySubmitButtonTitle: PropTypes.string,
  secondarySubmitLoading: PropTypes.bool,
  versionHelperText: PropTypes.string,
  versionNotIn: PropTypes.arrayOf(PropTypes.string),
  versionSubLabel: PropTypes.string,
};

DocumentForm.defaultProps = {
  contentPrefix: null,
  secondarySubmitAction: null,
  secondarySubmitButtonTitle: null,
  secondarySubmitLoading: false,
  versionHelperText: null,
  versionNotIn: null,
  versionSubLabel: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({ dispatch });

const DocumentFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentForm);

export default withRouter(DocumentFormConnected);
