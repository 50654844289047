export const invitationAcceptUrl = (token, orgAlias) => (
  orgAlias ? `/${orgAlias}/signup/${token}` : `/signup/${token}`
);
export const invitationRejectUrl = (token, orgAlias) => (
  orgAlias ? `/${orgAlias}/invitations/reject/${token}` : `/invitations/reject/${token}`
);

export const invitationStatusUrl = (token, orgAlias) => (
  orgAlias ? `/${orgAlias}/invitations/status/${token}` : `/invitations/status/${token}`
);

export const invitationManageUrl = (token, orgAlias) => (
  orgAlias ? `/${orgAlias}/invitations/${token}` : `/invitations/${token}`
);

export const invitationPublicUrl = (token, orgAlias) => (
  orgAlias ? `/${orgAlias}/join/${token}` : `/join/${token}`
);

// API
export const invitationsPendingApiUrl = (url = '') => (
  `${url}/api/invitations/pending`
);

export const invitationApiUrl = (token, url) => (
  `${url}/api/invitations/${token}`
);

export const invitationValidateApiUrl = url => (
  `${url}/api/invitations/validate`
);

export const invitationAcceptApiUrl = (token, url) => (
  `${url}/api/invitations/${token}/accept`
);

export const invitationRejectApiUrl = (token, url) => (
  `${url}/api/invitations/${token}/reject`
);

export const publicInvitationJoinApiUrl = (token, url) => (
  `${url}/api/invitations/${token}/join-public`
);
