import React from 'react';
import PropTypes from 'prop-types';

import { timelineItemSpec } from 'core/assets/js/lib/objectSpecs';
import TimelineItem from 'core/assets/js/components/TimelineItem.jsx';
import { DOCUMENT_QUERY_SELECTOR } from 'core/assets/js/config/settings';


class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.scrollItem = null;
    this.setItemRef = (el) => {
      this.scrollItem = el;
    };
  }

  componentDidMount() {
    this._scrollToBottom({ tryAgainIfNoScrollHeight: true });
  }

  componentDidUpdate() {
    this._scrollToBottom();
  }

  /**
   * Scroll to the bottom of the screen
   *
   * The scrollable element is different depending on the timeline mode:
   * - scrollMode enabled: scroll the timeline container
   * - scrollMode disabled: scroll the .page container
   *
   * @param {object} [props]
   * @param {boolean} props.tryAgainIfNoScrollHeight
   *
   * @private
   */
  _scrollToBottom({ tryAgainIfNoScrollHeight = false } = {}) {
    const { scrollToBottom } = this.props;
    if (!scrollToBottom) {
      return;
    }
    const { scrollMode } = this.props;
    const targetEl = scrollMode
      ? this.scrollItem
      : DOCUMENT_QUERY_SELECTOR('.page');

    if (targetEl) {
      if (targetEl.scrollHeight === 0 && tryAgainIfNoScrollHeight) {
        // if this timeline is in e.g. a modal,
        // then it may not immediately have a scrollHeight
        setTimeout(() => {
          this._scrollToBottom();
        }, 500);
        return;
      }
      targetEl.scrollTop = targetEl.scrollHeight;
    }
  }

  render() {
    const { component, items, scrollMode, timelineFooter } = this.props;
    const timelineClassName = ['timeline', 'mb-4'];
    const TimelineCard = component;

    if (scrollMode) {
      timelineClassName.push('timeline--scrolled');
    }

    const timelineItems = items.map(item => (
      <TimelineCard
        key={`timeline-item-${item.id}`}
        item={item}
      />
    ));

    return (
      <div className={timelineClassName.join(' ')} ref={this.setItemRef}>
        <div className="timeline-line-wrapper">
          <div className="timeline-item-container mr-2">
            {timelineItems}
            { timelineFooter && (
              <div className="mt-3 ml-4">
                { timelineFooter }
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Timeline.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  items: PropTypes.arrayOf(timelineItemSpec).isRequired,
  scrollMode: PropTypes.bool,
  scrollToBottom: PropTypes.bool,
  timelineFooter: PropTypes.node,
};

Timeline.defaultProps = {
  component: TimelineItem,
  scrollMode: false,
  scrollToBottom: true,
  timelineFooter: '',
};

export default Timeline;
