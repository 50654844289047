import React from 'react';

import { countryCodeSpec } from 'core/assets/js/lib/objectSpecs';
import { isSEPA } from 'finance/assets/js/constants';
import USBankAccountForm from 'settings/assets/js/components/bank-account-forms/USBankAccountForm.jsx';
import CABankAccountForm from 'settings/assets/js/components/bank-account-forms/CABankAccountForm.jsx';
import INBankAccountForm from 'settings/assets/js/components/bank-account-forms/INBankAccountForm.jsx';
import HKBankAccountForm from 'settings/assets/js/components/bank-account-forms/HKBankAccountForm.jsx';
import AUBankAccountForm from 'settings/assets/js/components/bank-account-forms/AUBankAccountForm.jsx';
import EUBankAccountForm from 'settings/assets/js/components/bank-account-forms/EUBankAccountForm.jsx';
import CHBankAccountForm from 'settings/assets/js/components/bank-account-forms/CHBankAccountForm.jsx';
import UKBankAccountForm from 'settings/assets/js/components/bank-account-forms/UKBankAccountForm.jsx';
import CNBankAccountForm from 'settings/assets/js/components/bank-account-forms/CNBankAccountForm.jsx';
import BaseBankAccountForm from 'settings/assets/js/components/bank-account-forms/BaseBankAccountForm.jsx';

const AccountTypeFields = ({ countryCode }) => {
  if (isSEPA(countryCode) && countryCode !== 'GB') {
    return <EUBankAccountForm />;
  }
  switch (countryCode) {
    case 'CH':
      return (
        <CHBankAccountForm />
      );
    case 'GB':
      return (
        <UKBankAccountForm />
      );
    case 'IN':
      return (
        <INBankAccountForm />
      );
    case 'HK':
      return (
        <HKBankAccountForm />
      );
    case 'US':
    case 'PA':
      return (
        <USBankAccountForm />
      );
    case 'CA':
      return (
        <CABankAccountForm />
      );
    case 'AU':
      return (
        <AUBankAccountForm />
      );
    case 'CN':
      return (
        <CNBankAccountForm />
      );
    case 'NZ':
    default:
      return <BaseBankAccountForm countryCode={countryCode} />;
  }
};

AccountTypeFields.propTypes = {
  countryCode: countryCodeSpec,
};

AccountTypeFields.defaultProps = {
  countryCode: null,
};

export default AccountTypeFields;
