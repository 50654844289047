/* eslint import/prefer-default-export: off */
import PropTypes from 'prop-types';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

export const customFieldSchema = {
  id: PropTypes.number,
  alias: PropTypes.string,
  weight: PropTypes.number,
  type: PropTypes.number,
  required: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
};

export const interviewQuestionSpec = PropTypes.shape(customFieldSchema);
export const customFieldSpec = PropTypes.shape(customFieldSchema);

export const interviewAnswerSpec = PropTypes.object;

export const submissionSpec = PropTypes.shape({
  id: PropTypes.number,
  questions: PropTypes.arrayOf(interviewQuestionSpec),
  answers: PropTypes.object,
  errors: PropTypes.object,
});

export const interviewSpec = PropTypes.shape({
  id: PropTypes.number,
  questions: PropTypes.arrayOf(interviewQuestionSpec),
  name: PropTypes.string,
  org: orgSpec,
});

export const interviewAllowedActionsSpec = PropTypes.shape({
  canDelete: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canGenerateInviteLink: PropTypes.bool.isRequired,
  canRevokeInviteLink: PropTypes.bool.isRequired,
  canView: PropTypes.bool.isRequired,
});

export const customFieldTemplateSchema = {
  id: PropTypes.number,
  name: PropTypes.string,
  entityType: PropTypes.number,
  entityTypeLabel: PropTypes.string,
  fields: PropTypes.arrayOf(customFieldSpec),
  isMandatory: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  deletedAt: PropTypes.string,
};

export const answerDetailsSchema = {
  id: PropTypes.number,
  userId: PropTypes.number,
  contactId: PropTypes.number,
  projectId: PropTypes.number,
  taskId: PropTypes.number,
  dimQuestionId: PropTypes.number,
  weight: PropTypes.number,
  path: PropTypes.string,
  body: PropTypes.any,
  question: PropTypes.object,
  expiresAt: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

export const answerDetailsSpec = PropTypes.shape(answerDetailsSchema);

export const customFieldTemplateSpec = PropTypes.shape(customFieldTemplateSchema);
