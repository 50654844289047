import { pick } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_SIZE, TASK_TABS } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { listUpdateItemAC } from 'core/assets/js/ducks/list';
import {
  getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import { refreshProjectInvitationsCountDS } from 'core/assets/js/ducks/pendingCount';
import { documentAssignmentSignUrl } from 'documents/urls';
import { INVITATION_ACTION, STATUS, STATUS_LABEL, TYPE } from 'invitations/assets/js/constants';
import { ORG_INVITEES_STATUS_DEFAULT_FILTER } from 'people/assets/js/constants';
import InvitationCardItem from 'projects/assets/js/components/InvitationCardItem.jsx';
import ProjectTaskInvitationRateAdjustmentModal from 'projects/assets/js/components/ProjectTaskInvitationRateAdjustmentModal.jsx';
import { INVITATIONS_LIST_TABS } from 'projects/assets/js/constants';
import { projectManageInvitationDS } from 'projects/assets/js/data-services/form';
import {
  acceptTaskInvitationDS, rejectTaskInvitationDS,
} from 'projects/assets/js/data-services/tasks';
import {
  projectInvitationsApiUrl, projectInvitationsUrl, projectViewTaskUrl, projectViewUrl,
} from 'projects/urls';

const MODAL_ID = 'invitation-modal-id';

const TaskOrProjectInvitations = ({ filtersOpen, onFiltersToggle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { orgAlias } = params;
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  let tab = INVITATIONS_LIST_TABS.TASKS;
  if (Object.values(INVITATIONS_LIST_TABS).includes(parsedQueryString.tab)) {
    ({ tab } = parsedQueryString);
  }

  const componentName = TaskOrProjectInvitations.GetComponentName();

  const { hasLoaded, isLoading, items: invitations, pagination } = fetchDataHook({
    componentName,
    duck: 'list',
    url: projectInvitationsApiUrl(orgAlias),
    queryStringParams: {
      type: tab === INVITATIONS_LIST_TABS.TASKS ? TYPE.TASK_ASSIGNMENT : TYPE.PROJECT_ADD_MEMBER,
    },
  });

  const modalIsOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const modalPayload = useSelector(state => getModalPayload(state, MODAL_ID));

  const closeModal = () => dispatch(modalCloseAC());

  const onSignAndAccept = documentAssignments => {
    const assignment = documentAssignments.find(da => !da.signedAt);

    // Close discussion board modal
    closeModal();

    if (!assignment) {
      return;
    }

    const [pathname, search] = documentAssignmentSignUrl(
      orgAlias,
      assignment.id,
      {
        backUrl: projectInvitationsUrl(orgAlias, assignment.invitation_id),
        next: projectInvitationsUrl(orgAlias, { continueInvitation: assignment.invitation_id }),
      },
    ).split('?');

    history.push({
      pathname,
      search,
      // if is coming from another document - has already accepted terms
      state: { skipTermsAndConditionsMessage: !!parsedQueryString.continueInvitation },
    });
  };

  if (!hasLoaded || isLoading) {
    return <ProjectListSkeleton />;
  }

  return (
    <>
      <SearchFinalForm
        filtersOpen={filtersOpen}
        initialValues={pick(
          queryString.parse(location.search), ['kw', 'ordering', 'status'],
        )}
        onFiltersToggle={onFiltersToggle}
        searchSpec={{
          searchTerm: false,
          filters: [{
            fieldComponent: SelectableListFilterField,
            label: 'Status',
            multiple: true,
            options: ORG_INVITEES_STATUS_DEFAULT_FILTER.map(status => ({
              text: STATUS_LABEL[status], value: status,
            })),
            paramName: 'status',
          }],
        }}
      />
      <ModalConfirm
        onClose={closeModal}
        onConfirm={async ({ actionType, token, projectId, taskId }) => {
          if (!Object.values(INVITATION_ACTION).includes(actionType)) {
            throw new Error(`Invalid actionType "${actionType}"`);
          }
          let inv;
          if (taskId) {
            const isAcceptAction = INVITATION_ACTION.ACCEPT === actionType;
            const action = isAcceptAction ? acceptTaskInvitationDS : rejectTaskInvitationDS;
            const { invitation } = await dispatch(action({
              orgAlias, projectId, taskId, values: {
                message: !isAcceptAction ? 'Invitation got rejected' : '', token,
              },
            }));
            inv = invitation;
          } else {
            const { data: invitation } = await projectManageInvitationDS(
              orgAlias, projectId, actionType, { token },
            );
            inv = invitation;
          }
          // Update the invitation status and pending count in the redux store
          dispatch(listUpdateItemAC(
            inv.id,
            { answeredAt: inv.answeredAt, status: inv.status, statusLabel: inv.statusLabel },
            componentName,
          ));
          dispatch(refreshProjectInvitationsCountDS({ componentName, orgAlias }));

          if (inv.status !== STATUS.ACCEPTED) {
            return null;
          }

          // Invitation accepted, redirect to project view
          return () => {
            history.push(
              taskId
                ? projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION)
                : projectViewUrl(orgAlias, projectId),
            );
          };
        }}
        open={modalIsOpen}
        size={BS_SIZE.LARGE}
        {...modalPayload}
      />
      {!filtersOpen && (
        <TDList
          pagination={pagination}
          items={invitations}
          cardItem={{
            component: InvitationCardItem,
            props: {
              onOpenInvitationModal: (
                heading,
                body,
                confirmLabel,
                confirmStyle,
                token,
                projectId,
                taskId,
                actionType,
              ) => dispatch(modalOpenAC(MODAL_ID, {
                body,
                confirmLabel,
                confirmStyle,
                heading,
                payload: { actionType, projectId, taskId, token },
              })),
              onSignAndAccept,
            },
          }}
          emptyListMessage="No invitations found."
        />
      )}
      <ProjectTaskInvitationRateAdjustmentModal onSignAndAccept={onSignAndAccept} />
    </>
  );
};

TaskOrProjectInvitations.GetComponentName = () => 'TaskOrProjectInvitations';

TaskOrProjectInvitations.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withFilters(TaskOrProjectInvitations);
