import React from 'react';
import PropTypes from 'prop-types';

const InvoicePaymentTerms = ({ terms, comments }) => {
  if (!terms) {
    return null;
  }
  return (
    <div data-testid="invoice-payment-terms">
      <h5>Payment Terms</h5>
      <p>{terms}</p>
      {comments && (
        <p>{comments}</p>
      )}
    </div>
  );
};

InvoicePaymentTerms.propTypes = {
  terms: PropTypes.string,
  comments: PropTypes.string,
};

InvoicePaymentTerms.defaultProps = {
  terms: null,
  comments: null,
};

export default InvoicePaymentTerms;
