import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import { orgOnboardCreateOrgUrl } from 'organizations/urls';
import { IMG_SIZE, ICON } from 'core/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectListUrl } from 'projects/urls';
import { selectActiveOrg, selectActiveUserCard, selectUserCards } from 'organizations/assets/js/reducers/organizations';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';

const NavBarOrgsList = ({ activeOrg, userCards, history, onClose }) => {
  const handleChange = (orgAlias) => {
    onClose();
    history.push(projectListUrl(orgAlias));
  };

  const orgItems = orderBy(userCards, uc => (uc.organization.alias === activeOrg.alias ? 1 : 0), 'desc');

  return (
    <React.Fragment>
      <ul className="organization-switcher__orgs-list">
        {orgItems.map(uc => {
          const { organization: org } = uc;
          const isActive = org.alias === activeOrg.alias;

          return (
            <li key={`org-link-${org.alias}`}>
              <a
                onClick={() => handleChange(org.alias)}
                className={`organization-switcher__org-item truncate org-link-${org.alias} ${isActive ? 'organization-switcher__org-item--active' : ''}`}
              >
                <OrganizationLogo
                  url={org.logo}
                  orgName={org.name}
                  size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  className="img-radius mr-3"
                />
                {org.name}
              </a>
            </li>
          );
        })}
      </ul>

      <div className="organization-switcher__create-org">
        <Link to={orgOnboardCreateOrgUrl()}>
          <i className={ICON.ADD_CIRCLE} />
          Create new organisation
        </Link>
      </div>
    </React.Fragment>
  );
};

NavBarOrgsList.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  userCards: PropTypes.arrayOf(userCardSpec).isRequired,
  activeUserCard: userCardSpec.isRequired,
  onClose: PropTypes.func,
};
NavBarOrgsList.defaultProps = {
  onClose: () => {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
  userCards: selectUserCards(state),
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const NavBarOrgsListConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBarOrgsList);

export default withRouter(NavBarOrgsListConnect);
