import React from 'react';
import PropTypes from 'prop-types';


import { countryNames } from 'core/assets/js/lib/isoCountries';

function CountryFlagIcon({ countryISOCode }) {
  return (
    <div
      className={`fflag fflag-${countryISOCode} ff-md`}
      title={countryNames[countryISOCode]}
    />
  );
}

CountryFlagIcon.propTypes = {
  countryISOCode: PropTypes.string,
};

CountryFlagIcon.defaultProps = {
  countryISOCode: '',
};

export default CountryFlagIcon;
