import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROLE_TABS, SYSTEM_ROLES, SYSTEM_ROLES_ICONS } from 'roles/assets/js/constants';
import { ICON } from 'core/assets/js/constants';
import { roleEditUrl, systemRoleViewUrl } from 'roles/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const PermissionMember = ({ id, name }) => {
  const activeOrg = useSelector(selectActiveOrg);

  const isSystemRole = Object.values(SYSTEM_ROLES).includes(id);

  return (
    <div className="permission-roles-cards__list-item d-flex align-items-center justify-content-start mt-3 px-4 py-5" key={id}>
      <i className={`${isSystemRole ? SYSTEM_ROLES_ICONS[id] : ICON.GEAR_DUOTONE} mr-4`} />
      <Link
        className="permission-roles-cards__name"
        to={(
          isSystemRole
            ? systemRoleViewUrl(activeOrg.alias, id, ROLE_TABS.CONFIG)
            : roleEditUrl(activeOrg.alias, id, ROLE_TABS.CONFIG)
        )}
      >
        {name}
      </Link>
    </div>
  );
};

PermissionMember.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
};

export default PermissionMember;
