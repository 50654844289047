import React from 'react';
import { Card } from 'react-bootstrap';

const BillingNoSubscriptionFound = () => (
  <Card>
    <Card.Body>
      No subscription found
    </Card.Body>
  </Card>
);

export default BillingNoSubscriptionFound;
