import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { validateInvitationActiveDS, selectActiveInvitation } from 'invitations/assets/js/ducks/invitation';
import { postPasswordResetDS } from 'accounts/assets/js/data-services/password';
import { loginUrl } from 'accounts/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import PasswordResetForm from 'accounts/assets/js/components/PasswordResetForm.jsx';
import { selectProfile, selectAuthenticated } from 'accounts/assets/js/reducers/auth';


class PasswordResetView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(validateInvitationActiveDS({
        token: params.token, url, authedAxios, componentName,
      })),
    ]);
  }

  static GetComponentName() {
    return 'PasswordResetView';
  }

  constructor(props) {
    super(props);

    this.formProps = {};

    if (this.constructor.GetComponentName() === 'PasswordResetView') {
      this.formProps = {
        title: 'Password Reset',
        buttonLabel: 'Reset my password',
        successMessage: 'Thank you! Your password has been reset.',
        hasBackToLogin: true,
      };
    } else if (this.constructor.GetComponentName() === 'PasswordCreateView') {
      this.formProps = {
        title: 'Create a Password',
        buttonLabel: 'Create my password',
        successMessage: 'Thank you! Your password has been created.',
        hasBackToLogin: false,
      };
    }
  }

  componentDidMount() {
    const { history, isValid, profile, isAuthenticated } = this.props;
    if (isAuthenticated) {
      history.push(profile.homeUrl);
    } else if (isValid === false) {
      history.push(loginUrl());
    }
  }

  render() {
    const { invitation, dispatch } = this.props;
    return (
      <div className="clearfix">
        <TDApiConnected duck="invitation" component={this.constructor}>
          <PasswordResetForm
            onSubmit={values => postPasswordResetDS(invitation.token, values, dispatch)}
            {...this.formProps}
          />
        </TDApiConnected>
      </div>
    );
  }
}

PasswordResetView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  invitation: invitationSpec.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isValid: PropTypes.bool,
  profile: PropTypes.object,
};

PasswordResetView.defaultProps = {
  isValid: null,
  profile: {},
};

PasswordResetView.getMetaTags = () => ({
  title: 'Password Reset',
});

const mapStateToProps = (state) => {
  const invitationState = selectActiveInvitation(state);
  return {
    isAuthenticated: selectAuthenticated(state),
    profile: selectProfile(state),
    invitation: invitationState.item,
    isValid: invitationState.isValid,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetView));
