import PropTypes from 'prop-types';
import React from 'react';

import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import ProviderCard from 'people/assets/js/components/ProviderCard.jsx';

import { managerUserCardSpec, providerUserCardSpec } from 'organizations/assets/js/lib/objectSpecs';

const UserCard = ({ item, ...rest }) => {
  const Component = item.userRole?.isAnyManager ? ManagerCard : ProviderCard;
  return <Component item={item} {...rest} />;
};

UserCard.propTypes = {
  item: PropTypes.oneOfType([managerUserCardSpec, providerUserCardSpec]).isRequired,
};

export default UserCard;
