import { isNumber, omit, pick } from 'lodash';

import { API_DATE_FORMAT, CURRENCY_VALUES } from 'core/assets/js/constants';
import { dateTimeIsValid, formatDate, parseDate } from 'core/assets/js/lib/utils';
import {
  DOCUMENT_CONTRACT_TERM,
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE,
  DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_VALUES,
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE,
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL,
  DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_VALUES,
  DOCUMENT_CONTRACT_TERM_END_TYPE,
  DOCUMENT_CONTRACT_TERM_END_TYPE_VALUES,
  DOCUMENT_CONTRACT_TERM_VALUES,
  DOCUMENT_CONTRACT_TYPE,
  DOCUMENT_RENEWAL_TERM_TYPE_VALUES,
  DOCUMENT_RENEWAL_TYPE,
  DOCUMENT_RENEWAL_TYPE_VALUES,
  DOCUMENT_TAX_TYPE,
  DOCUMENT_TAX_TYPE_VALUES,
  DOCUMENT_TYPE,
} from 'documents/assets/js/constants';

/**
 * Calculates the expiration date of a document assignment
 *
 * @param {DocumentAssignment} Document assignment
 * @return {String}
 */
export const calculateExpirationDate = ({
  document: {
    endDate,
    endType,
    endText,
    effectiveType,
    endRelativeValue,
    endRelativeType,
    effectiveDate,
  },
  signedAt,
}) => {
  let calculatedExpirationDate = 'n/a';
  let _effectiveDate = null;

  switch (endType) {
    case DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE:
      calculatedExpirationDate = formatDate(endDate);
      break;
    case DOCUMENT_CONTRACT_TERM_END_TYPE.ON_CONDITION:
      calculatedExpirationDate = endText;
      break;
    case DOCUMENT_CONTRACT_TERM_END_TYPE.ON_FULFILMENT:
      calculatedExpirationDate = endText;
      break;
    case DOCUMENT_CONTRACT_TERM_END_TYPE.RELATIVE_DATE:
      // Calculate effectiveDate
      if (effectiveType === DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_SPECIFIC_DATE) {
        _effectiveDate = effectiveDate;
      } else if (
        effectiveType === DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_EXECUTION
        && signedAt
      ) {
        _effectiveDate = signedAt;
      } else {
        calculatedExpirationDate = `${endRelativeValue}
            ${DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_LABEL[endRelativeType]} after execution`
          .toLowerCase();
      }

      if (_effectiveDate) {
        const multiplier = endRelativeType === DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE.YEARS
          ? 12 : 1;
        const calculatedRelEndDateInMonths = multiplier * endRelativeValue;
        const _date = parseDate(_effectiveDate, API_DATE_FORMAT)
          .add(calculatedRelEndDateInMonths, 'month');
        calculatedExpirationDate = formatDate(_date);
      }
      break;
    default: // is Indefinite.
      calculatedExpirationDate = '-';
      break;
  }
  return calculatedExpirationDate;
};

const isNonNegativeNumber = value => value && isNumber(Number(value)) && Number(value) >= 0;

/**
  * Converts any values based on integer values, as when used in form fields, they can be changed
  * to strings
  *
  * @param {Object} formValues
  * @return {Object}
  */
export const sanitiseIntegerValues = formValues => {
  const thisFormValues = { ...formValues };
  [
    'contractTerm',
    'countersignersCount',
    'effectiveType',
    'endRelativeType',
    'endRelativeValue',
    'endType',
    'renewalExpirationReminder',
    'renewalNoticePeriod',
    'renewalTermType',
    'renewalTermValue',
    'renewalType',
    'taxType',
  ].forEach(key => {
    if (typeof thisFormValues[key] === 'string' && /^\d+$/.test(thisFormValues[key])) {
      thisFormValues[key] = parseInt(thisFormValues[key], 10);
    }
  });
  return thisFormValues;
};

/**
  * Parses the document form details step's values and returns any errors
  *
  * @param {Object} formValues
  * @param {String[]} [versionNotIn]
  * @return {Object|Null}
  */
export const getDocumentDetailsErrors = (formValues, versionNotIn) => {
  const {
    amount,
    contractTerm,
    currency,
    effectiveDate,
    effectiveType,
    endDate,
    endText,
    endType,
    endRelativeType,
    endRelativeValue,
    isCritical,
    isRenewable,
    renewalExpirationReminder,
    renewalNoticePeriod,
    renewalTermType,
    renewalTermValue,
    renewalType,
    taxTerms,
    taxType,
    title,
    version,
  } = sanitiseIntegerValues(formValues);

  const errors = {};
  if (!title) {
    errors.title = 'Please provide a valid title';
  }
  if (!version) {
    errors.version = 'Please provide a valid version';
  } else if (versionNotIn && versionNotIn.includes(version)) {
    errors.version = 'Please provide a different version';
  }
  if (!DOCUMENT_CONTRACT_TERM_VALUES.includes(contractTerm)) {
    errors.contractTerm = 'Please select a valid contract term';
  } else {
    if (!DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE_VALUES.includes(effectiveType)) {
      errors.effectiveType = 'Please select a valid effective date type';
    } else if (
      effectiveType === DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_SPECIFIC_DATE
      && !dateTimeIsValid(effectiveDate, API_DATE_FORMAT)
    ) {
      errors.effectiveDate = 'Please provide a valid effective date';
    }
    if (contractTerm === DOCUMENT_CONTRACT_TERM.DEFINITE) {
      if (!DOCUMENT_CONTRACT_TERM_END_TYPE_VALUES.includes(endType)) {
        errors.endType = 'Please select a valid end date type';
      } else if (
        endType === DOCUMENT_CONTRACT_TERM_END_TYPE.ON_SPECIFIC_DATE
        && (
          !dateTimeIsValid(endDate, API_DATE_FORMAT)
          || (
            effectiveType === DOCUMENT_CONTRACT_TERM_EFFECTIVE_TYPE.ON_SPECIFIC_DATE
            && parseDate(endDate, API_DATE_FORMAT).isBefore(
              parseDate(effectiveDate, API_DATE_FORMAT),
            )
          )
        )
      ) {
        errors.endDate = 'Please provide a valid end date';
      } else if (endType === DOCUMENT_CONTRACT_TERM_END_TYPE.RELATIVE_DATE) {
        if (!DOCUMENT_CONTRACT_TERM_END_RELATIVE_TYPE_VALUES.includes(endRelativeType)) {
          errors.endRelativeType = 'Please select a valid end date relative type';
        }
        if (!isNonNegativeNumber(endRelativeValue)) {
          errors.endRelativeValue = 'Please provide a valid end date relative value';
        }
      } else if (
        [
          DOCUMENT_CONTRACT_TERM_END_TYPE.ON_CONDITION,
          DOCUMENT_CONTRACT_TERM_END_TYPE.ON_FULFILMENT,
        ].includes(endType) && !endText
      ) {
        errors.endText = 'Please provide valid end date text';
      }
    }
  }
  if (isRenewable === undefined) {
    errors.isRenewable = 'Please select if the contract is renewable';
  } else if (isRenewable?.isYes) {
    if (!DOCUMENT_RENEWAL_TYPE_VALUES.includes(renewalType)) {
      errors.renewalType = 'Please select a valid renewal type';
    } else if (renewalType === DOCUMENT_RENEWAL_TYPE.MANUAL) {
      if (!isNonNegativeNumber(renewalNoticePeriod)) {
        errors.renewalNoticePeriod = 'Please enter a valid renewal notice period';
      }
      if (!isNonNegativeNumber(renewalExpirationReminder)) {
        errors.renewalExpirationReminder = 'Please enter a valid renewal expiration'
                                            + ' reminder';
      }
    } else {
      if (!DOCUMENT_RENEWAL_TERM_TYPE_VALUES.includes(renewalTermType)) {
        errors.renewalTermType = 'Please select a valid renewal term type';
      }
      if (!isNonNegativeNumber(renewalTermValue)) {
        errors.renewalTermValue = 'Please enter a valid renewal term value';
      }
    }
  }
  if (amount) {
    if (!CURRENCY_VALUES.includes(currency)) {
      errors.currency = 'Please select a valid currency';
    }
    if (!isNonNegativeNumber(amount)) {
      errors.amount = 'Please provide a valid amount, or empty the field';
    }
    if (!DOCUMENT_TAX_TYPE_VALUES.includes(taxType)) {
      errors.taxType = 'Please select a valid tax type';
    } else if (taxType === DOCUMENT_TAX_TYPE.PAYMENT_TERMS && !taxTerms) {
      errors.taxTerms = 'Please provide valid tax terms text';
    }
  }
  if (isCritical === undefined) {
    errors.isCritical = 'Please select if this document is critical or not';
  }
  return Object.values(errors).length > 0 ? errors : null;
};

export const parseDocumentValues = formValues => {
  let countersignersCount = 0;
  if (formValues.hasCountersigners?.isYes && formValues.countersignersCount) {
    countersignersCount = formValues.countersignersCount;
  }
  return {
    ...omit(
      formValues,
      'contractTerm',
      'contractType',
      'embedUrl',
      'eSignFile',
      'hasCountersigners',
      'isRenewable',
      'type',
    ),
    countersignersCount,
  };
};

export const parseExistingDocumentValues = (orgCurrency, document, isNewVersion) => {
  let version;
  if (isNewVersion) {
    version = /^\d+$/.test(document.version) ? (parseInt(document.version, 10) + 1).toString() : '';
  } else {
    version = document.version;
  }
  return {
    ...pick(
      document,
      'amount',
      'content',
      'countersignersCount',
      'description',
      'effectiveDate',
      'effectiveType',
      'endDate',
      'endRelativeType',
      'endRelativeValue',
      'endText',
      'endType',
      'files',
      'isCritical',
      'renewalExpirationReminder',
      'renewalNoticePeriod',
      'renewalTermType',
      'renewalTermValue',
      'renewalType',
      'taxTerms',
      'taxType',
      'title',
      ...(isNewVersion ? [] : ['signNowDocumentId', 'signNowTemplateId']),
    ),
    contractTerm: document.endType
      ? DOCUMENT_CONTRACT_TERM.DEFINITE
      : DOCUMENT_CONTRACT_TERM.INDEFINITE,
    contractType: document.content || document.files
      ? DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH
      : DOCUMENT_CONTRACT_TYPE.ESIGN,
    currency: document.currency || orgCurrency,
    hasCountersigners: {
      isYes: typeof document.countersignersCount === 'number' && document.countersignersCount > 0,
    },
    isRenewable: { isYes: !!document.renewalType },
    type: document.content ? DOCUMENT_TYPE.CONTENT : DOCUMENT_TYPE.FILE,
    version,
  };
};
