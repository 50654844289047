import React from 'react';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash';
import { toastr } from 'react-redux-toastr';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import RoleForm, { RENAME_MODAL_ID } from 'roles/assets/js/components/RoleForm.jsx';
import RoleUsersList from 'roles/assets/js/components/RoleUsersList.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { MODAL_ID as ADD_USERS_MODAL_ID } from 'roles/assets/js/components/RoleAddUsersPanel.jsx';
import { ROLE_TABS } from 'roles/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { roleEditUrl, roleFetchApiUrl, rolesListUrl, roleDuplicateUrl } from 'roles/urls';
import { updateRoleDS } from 'roles/assets/js/data-services/role';
import DeleteRoleModal, { DELETE_MODAL_ID } from 'roles/assets/js/components/DeleteRoleModal.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';

const RoleEditView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgAlias, roleId, tab } = useParams();

  const { hasLoaded, item: role } = fetchDataHook({
    componentName: RoleEditView.GetComponentName(),
    url: roleFetchApiUrl(orgAlias, roleId),
  });

  const handleSubmit = async (values) => {
    try {
      await dispatch(
        updateRoleDS({
          componentName: RoleEditView.GetComponentName(),
          orgAlias,
          roleId: role.id,
          values,
        }),
      );

      toastr.success('Well Done!', 'Role updated successfully.');
      return true;
    } catch (err) {
      const errorData = err.errors;
      if (errorData?._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  };

  const tabSpec = [
    {
      key: 'config',
      label: 'Permissions',
      url: roleEditUrl(orgAlias, roleId, ROLE_TABS.CONFIG),
    },
    {
      key: 'members',
      label: 'Members',
      url: roleEditUrl(orgAlias, roleId, ROLE_TABS.MEMBERS),
    },
  ];

  const ctaButton = tab === ROLE_TABS.MEMBERS ? [{
    label: 'Add members',
    onClick: () => dispatch(modalOpenAC(ADD_USERS_MODAL_ID)),
    variant: BS_STYLE.PRIMARY,
  }] : [
    {
      label: 'Duplicate',
      onClick: () => history.push(roleDuplicateUrl(
        orgAlias, role?.id,
      )),
      variant: BS_STYLE.PRIMARY,
    },
    {
      label: 'Rename role',
      onClick: () => dispatch(modalOpenAC(RENAME_MODAL_ID)),
      variant: BS_STYLE.PRIMARY,
    },
    {
      label: 'Delete',
      onClick: () => dispatch(modalOpenAC(DELETE_MODAL_ID, role)),
      variant: BS_STYLE.DANGER,
    },
  ];

  const backUrl = rolesListUrl(orgAlias);

  return (
    <>
      <ContentHeader
        backUrl={backUrl}
        breadcrumbs={[
          { title: 'Roles & Permissions', url: backUrl },
          { title: role?.name ? role?.name : <RectangleSkeleton width="183" height="25" />,
            url: '',
          },
        ]}
        ctaButtonItems={ctaButton}
      >
        <div className="horizontal-scroll" data-testid="system-role-tabs">
          <TabBar
            activeKey={tab}
            handleTabSelect={(tabKey) => {
              history.push(tabSpec.find(i => i.key === tabKey).url);
            }}
            tabSpec={tabSpec}
          />
        </div>
      </ContentHeader>
      <div className="page page--roles" data-testid="roles-list-page">
        <div className="container">
          {!hasLoaded ? (
            <ListSkeleton />
          ) : (
            <>
              {tab === ROLE_TABS.CONFIG && (
                <RoleForm
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: role?.name,
                    permissions: role?.permissions && role.permissions.map(p => p.permission),
                  }}
                />
              )}

              {tab === ROLE_TABS.MEMBERS && <RoleUsersList />}
            </>
          )}
        </div>
      </div>

      <DeleteRoleModal />
    </>
  );
};

RoleEditView.GetComponentName = () => 'RoleEditView';

export default RoleEditView;
