export const STATUS = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  USED: 3,
  CANCELLED: 4,
  RATE_ADJUSTMENT_REQUESTED: 5,
};
export const STATUS_LABEL = {
  [STATUS.PENDING]: 'Pending',
  [STATUS.ACCEPTED]: 'Accepted',
  [STATUS.REJECTED]: 'Rejected',
  [STATUS.USED]: 'Used',
  [STATUS.CANCELLED]: 'Cancelled',
  [STATUS.RATE_ADJUSTMENT_REQUESTED]: 'Rate adjustment requested',
};
export const STATUS_CLASS = {
  [STATUS.PENDING]: 'pending',
  [STATUS.ACCEPTED]: 'accepted',
  [STATUS.REJECTED]: 'rejected',
  [STATUS.USED]: 'in-progress',
  [STATUS.CANCELLED]: 'cancelled',
  [STATUS.RATE_ADJUSTMENT_REQUESTED]: 'pending',
};


export const INVITATION_ACTION = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  CANCEL: 'cancel',
};

export const TYPE = {
  ORG_INVITE: 1,
  ORG_INVITE_PPH: 2,
  ORG_INVITE_PUBLIC: 3,
  PROJECT_ADD_MEMBER: 11,
  OPPORTUNITY_ADD_MEMBER: 12,
  PASSWORD_RESET: 20,
  EMAIL_VERIFY: 30,
  INVOICE_DOWNLOAD: 40,
  TASK_ASSIGNMENT: 50,
  TASK_RATE_SUGGESTION: 51,
  TASK_COMPLETION: 52,
};

export const TYPE_LABEL = {
  [TYPE.ORG_INVITE]: 'Organisation',
  [TYPE.ORG_INVITE_PPH]: 'People Per Hour',
  [TYPE.ORG_INVITE_PUBLIC]: 'Public',
  [TYPE.PROJECT_ADD_MEMBER]: 'Project',
  [TYPE.PASSWORD_RESET]: 'Password reset',
  [TYPE.EMAIL_VERIFY]: 'Email verification',
  [TYPE.INVOICE_DOWNLOAD]: 'Invoice download',
  [TYPE.TASK_ASSIGNMENT]: 'Task',
  [TYPE.TASK_RATE_SUGGESTION]: 'Rate suggestion',
  [TYPE.TASK_COMPLETION]: 'Task Completion',
};

// Group the invitation types into handy lists
export const ORG_INVITATION_TYPES = [
  TYPE.ORG_INVITE,
  TYPE.ORG_INVITE_PPH,
  TYPE.ORG_INVITE_PUBLIC,
];
export const PROJECT_INVITATION_TYPES = [
  TYPE.PROJECT_ADD_MEMBER,
];
export const TASK_INVITATION_TYPES = [
  TYPE.TASK_ASSIGNMENT,
  TYPE.TASK_RATE_SUGGESTION,
  TYPE.TASK_COMPLETION,
];

export const INVITATION_EXPIRATION_DAYS = [
  { id: 1, label: '1 day' },
  { id: 2, label: '2 days' },
  { id: 3, label: '3 days' },
  { id: 4, label: '4 days' },
  { id: 5, label: '5 days' },
  { id: 6, label: '6 days' },
  { id: 7, label: '7 days' },
];

export const INVITATION_EXPIRATION_DAYS_OPTIONS = INVITATION_EXPIRATION_DAYS.map(
  day => ({ value: day.id, text: day.label }),
);

export const ADD_TO_PROJECT_TYPE = {
  INVITATION: 1,
  ADD: 2,
};

export const EXPIRES_IN_DAYS = 5;

export const INVITATION_NEVER_EXPIRE_VALUE = -1;
