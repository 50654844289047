import pluralize from 'pluralize';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useLocation, useParams } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { fetchListDS } from 'core/assets/js/ducks/list';
import {
  getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import PeopleList from 'people/assets/js/components/PeopleList.jsx';
import RoleAddUsersPanel, { MODAL_ID as ADD_USERS_MODAL_ID } from 'roles/assets/js/components/RoleAddUsersPanel.jsx';
import { removeUsersFromRoleDS } from 'roles/assets/js/data-services/role';
import { roleFetchUsersApiUrl } from 'roles/urls';

export const REMOVE_USER_MODAL_ID = 'remove-user-modal';

const RoleUsersList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { search } = useLocation();
  const removeUserModalOpen = useSelector(state => getIsModalOpen(state, REMOVE_USER_MODAL_ID));
  const removeUserModalPayload = useSelector(state => getModalPayload(state, REMOVE_USER_MODAL_ID));

  const { orgAlias } = params;
  const roleId = params.roleId || params.systemRoleId;
  const componentName = RoleUsersList.GetComponentName(params);

  const url = roleFetchUsersApiUrl(orgAlias, roleId);
  fetchDataHook({ componentName, duck: 'list', url });

  return (
    <>
      <PeopleList
        addUsersComponent={(
          <>
            <br />
            You haven&apos;t added any members yet
            <br />
            <TDButton
              className="mt-4"
              data-testid="add-users-cta"
              onClick={() => dispatch(modalOpenAC(ADD_USERS_MODAL_ID))}
              variant={BS_STYLE.PRIMARY}
            >
              Add members
            </TDButton>
          </>
        )}
        componentName={componentName}
        getActionsDropDownItems={item => item.allowedActions?.canRemoveFromRole && (
          <Dropdown.Item
            onClick={() => dispatch(modalOpenAC(REMOVE_USER_MODAL_ID, item))}
          >
            Remove member
          </Dropdown.Item>
        )}
        noUsersMessage="This role does not have any members yet"
      />

      <RoleAddUsersPanel listComponentName={componentName} />

      <ModalConfirm
        cancelLabel="Cancel"
        confirmLabel="Remove"
        heading="Delete role"
        onClose={() => dispatch(modalCloseAC(REMOVE_USER_MODAL_ID))}
        onConfirm={async () => {
          try {
            const addedUsers  = await dispatch(removeUsersFromRoleDS({
              orgAlias,
              roleId,
              userIds: [removeUserModalPayload?.user.id],
            }));
            dispatch(fetchListDS({ componentName, querystring: search, url }));
            toastr.success(
              'Well Done!',
              `${pluralize('Member', addedUsers.length)} removed successfully.`,
            );
          } catch (err) {
            toastr.error('Oh Snap!', err.response?.data?._error || err.message);
          }
        }}
        open={removeUserModalOpen}
      >
        <p>
          {'Are you sure you want to remove '}
          <strong>{removeUserModalPayload?.user.profile.name}</strong>
          ?
        </p>
      </ModalConfirm>
    </>
  );
};

RoleUsersList.GetComponentName = () => 'RoleCreateView';

export default RoleUsersList;
