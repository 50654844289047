import React from 'react';
import PropTypes from 'prop-types';

const WorksheetProjectWorkItemHeaderRow = ({ hasOnlyClaimAmount, isProjectWork }) => (
  <tr className="worksheet-items-table__header">
    <th className="worksheet-items-table__header--description">
      {isProjectWork && 'Project work'}
      {!isProjectWork && 'Work'}
    </th>
    <th className="worksheet-items-table__header--rate-selection" colSpan={2}>Your rates</th>
    {hasOnlyClaimAmount && (
      <th colSpan={3} className="worksheet-items-table__header--fixed-amount">Fixed rate</th>
    )}
    {!hasOnlyClaimAmount && (
      <>
        <th className="worksheet-items-table__header--rate">Rate</th>
        <th className="pl-0 pr-0" />
        <th className="worksheet-items-table__header--qty">QTY</th>
      </>
    )}
    <th className="worksheet-items-table__header--amount">Amount</th>
    <th style={{ width: '35px' }} />
  </tr>
);

WorksheetProjectWorkItemHeaderRow.propTypes = {
  hasOnlyClaimAmount: PropTypes.bool,
  isProjectWork: PropTypes.bool.isRequired,
};

WorksheetProjectWorkItemHeaderRow.defaultProps = {
  hasOnlyClaimAmount: false,
};

export default WorksheetProjectWorkItemHeaderRow;
