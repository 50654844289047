import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { REPORT_TYPES, BUDGETS_TABS } from 'finance/assets/js/constants';
import PurchaseOrdersTable from 'finance/assets/js/components/PurchaseOrdersTable.jsx';
import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import {
  fetchFinancePurchaseOrderListDS,
} from 'finance/assets/js/data-services/list';
import BudgetsHeader from './components/BudgetsHeader.jsx';

class PurchaseOrders extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return Promise.all([
      dispatch(fetchFinancePurchaseOrderListDS({
        url, querystring, componentName, params, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'PurchaseOrders';
  }

  render() {
    const { pagination, purchaseOrders, searchActive, amountLimits,
      filtersOpen, onFiltersToggle,
    } = this.props;

    return (
      <React.Fragment>
        <BudgetsHeader tab={BUDGETS_TABS.BUDGET_REQUESTS} />

        <div className="page page--finance">
          <div className="container">
            <div className="row">
              {searchActive && (
                <ReportSearch
                  reportType={REPORT_TYPES.PURCHASE_ORDER}
                  amountLimits={amountLimits}
                  onFiltersToggle={onFiltersToggle}
                  filtersOpen={filtersOpen}
                />
              )}
            </div>
            <TDApiConnected duck="list" component={this.constructor} skeletonComponent={FinanceTableSkeleton}>
              {!filtersOpen && (
                <React.Fragment>
                  <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
                    <PurchaseOrdersTable
                      list={purchaseOrders}
                      emptyText="No budget requests found"
                    />
                  </div>
                  <TDPagination {...pagination} />
                </React.Fragment>
              )}
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
PurchaseOrders.propTypes = {
  amountLimits: PropTypes.object,
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  purchaseOrders: PropTypes.arrayOf(PropTypes.object),
  searchActive: PropTypes.bool.isRequired,
};

PurchaseOrders.defaultProps = {
  purchaseOrders: [],
  amountLimits: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, PurchaseOrders.GetComponentName());
  return {
    amountLimits: listState.extras.amountLimits,
    pagination: listState.pagination,
    purchaseOrders: listState.items,
    searchActive: listState.search.isActive,
  };
};

export default connect(mapStateToProps)(withFilters(PurchaseOrders));
