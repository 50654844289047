import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { selectActiveUserType } from 'organizations/assets/js/reducers/organizations';
import { isFieldVisibleToUserType } from 'interviews/assets/js/lib/utils';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { TYPE as CUSTOM_FIELD_TYPE } from 'interviews/assets/js/constants';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SelectionField from 'core/assets/js/components/FinalFormFields/SelectionField.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import YesNoField from 'core/assets/js/components/FinalFormFields/YesNoField.jsx';
import DateRangeFilterField from 'core/assets/js/components/FinalFormFilterFields/DateRangeFilterField.jsx';

const CustomFieldFilterRenderer = ({ field, activeUserType }) => {
  let fieldComponent = null;
  const inputType = [CUSTOM_FIELD_TYPE.NUMBER].includes(field.type)
    ? 'number'
    : 'text';

  if (!isFieldVisibleToUserType(field, activeUserType) || !field.searchKey) {
    return null;
  }

  const fieldLabel = (
    <MarkdownText
      text={field.label}
      disallowedTypes={['paragraph']}
    />
  );

  switch (field.type) {
    case CUSTOM_FIELD_TYPE.TEXT:
    case CUSTOM_FIELD_TYPE.NUMBER:
    case CUSTOM_FIELD_TYPE.MONEY:
    case CUSTOM_FIELD_TYPE.TEXTAREA:
      fieldComponent = (
        <TextInputField
          type={inputType}
          name={field.searchKey}
        />
      );
      break;

    case CUSTOM_FIELD_TYPE.SELECT:
    case CUSTOM_FIELD_TYPE.RADIO:
    case CUSTOM_FIELD_TYPE.CHECKBOX:
      fieldComponent = (
        <SelectionField
          name={field.searchKey}
          optionsMapping={field.payload?.choices || []}
          isMultiple={field.payload?.multiple || false}
          valueTransformer={val => omit(val, ['text'])}
        />
      );
      break;

    case CUSTOM_FIELD_TYPE.DATE:
      fieldComponent = (
        <DateRangeFilterField
          name={field.searchKey}
          initialValues={{}}
          filter={{
            paramName: field.searchKey,
          }}
        />
      );
      break;

    case CUSTOM_FIELD_TYPE.YESNO:
      fieldComponent = (
        <YesNoField
          name={field.searchKey}
          showTextInput={false}
          valueTransformer={val => omit(val, ['autoIncrement', 'checksum'])}
        />
      );
      break;

    case CUSTOM_FIELD_TYPE.FILE:
    case CUSTOM_FIELD_TYPE.MULTIFILE:
    case CUSTOM_FIELD_TYPE.TEXT_BLOB:
    default:
      break;
  }

  if (!fieldComponent) {
    return null;
  }

  return (
    <React.Fragment>
      <h4>
        {fieldLabel}
      </h4>

      {fieldComponent}
    </React.Fragment>
  );
};

CustomFieldFilterRenderer.propTypes = {
  field: customFieldTemplateSpec.isRequired,
  activeUserType: PropTypes.number,
};

CustomFieldFilterRenderer.defaultProps = {
  activeUserType: null,
};

const mapStateToProps = state => ({
  activeUserType: selectActiveUserType(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const CustomFieldFilterRendererConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFieldFilterRenderer);

export default CustomFieldFilterRendererConnected;
