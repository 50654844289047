import React, { Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory } from 'react-router-dom';

import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import {
  CUSTOMIZABLE_NOTIFICATIONS,
  NOTIFICATION_GROUP_ICONS,
  NOTIFICATION_GROUP_LABELS,
  NOTIFICATION_GROUPS,
  NOTIFICATION_TYPE_DESCRIPTIONS,
  NOTIFICATION_TYPE_LABELS,
  NOTIFICATION_TYPES_BY_GROUP,
} from 'notifier/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import EmailTemplatePreview, { MODAL_ID } from 'settings/assets/js/components/EmailTemplatePreview.jsx';
import {
  orgNotificationSettingsSendTestApiUrl, orgSettingsEmailTemplateUrl,
} from 'settings/urls';

const GROUPS = Object.values(NOTIFICATION_GROUPS).reduce(
  (acc, groupId) => {
    const allTypesInGroup = NOTIFICATION_TYPES_BY_GROUP[groupId];
    const types = CUSTOMIZABLE_NOTIFICATIONS.filter(type => allTypesInGroup.includes(type));
    if (types.length > 0) {
      acc.push([groupId, types]);
    }
    return acc;
  },
  [],
);

const OrgSettingsEmailTemplatesView = () => {
  const history = useHistory();
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();
  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Email Templates', url: null }]} />
      <div className="page page--settings email-templates">
        <div className="container">
          {GROUPS.map(([groupId, types]) => (
            <Fragment key={groupId}>
              <h2>{NOTIFICATION_GROUP_LABELS[groupId]}</h2>
              <div className="d-flex flex-column mb-5 pb-3">
                {types.map((type, typeIndex) => {
                  const classNames = [
                    'rounded',
                    'shadow-sm',
                    'd-flex',
                    'bg-white',
                    'p-5',
                    'align-items-center',
                    'justify-content-between',
                    'w-100',
                  ];
                  if (typeIndex > 0) {
                    classNames.push('mt-3');
                  }
                  const typeLabel = NOTIFICATION_TYPE_LABELS[type];
                  const url = orgSettingsEmailTemplateUrl(activeOrg.alias, type);
                  return (
                    <div className={classNames.join(' ')} key={type}>
                      <Link className="d-flex align-items-center" to={url}>
                        <div className="icon-container mr-5">
                          <i className={`${NOTIFICATION_GROUP_ICONS[groupId]} fa-2xl`} />
                        </div>
                        <div className="d-flex flex-column">
                          <h2 className="my-0">{typeLabel}</h2>
                          <span>{NOTIFICATION_TYPE_DESCRIPTIONS[type]}</span>
                        </div>
                      </Link>
                      <TDDropChevronButton>
                        <Dropdown.Item onClick={() => dispatch(modalOpenAC(MODAL_ID, { type }))}>
                          Show preview
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push(url)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={async () => {
                            try {
                              const { data: { sentTo } } = await axios.post(
                                orgNotificationSettingsSendTestApiUrl(activeOrg.alias, type),
                              );
                              toastr.success(
                                'Well Done!',
                                [
                                  `We have sent an example of the "${typeLabel}" notification to `,
                                  sentTo,
                                ].join(''),
                              );
                            } catch (error) {
                              toastr.error('Oh Snap!', error.message);
                            }
                          }}
                        >
                          Send test
                        </Dropdown.Item>
                      </TDDropChevronButton>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <EmailTemplatePreview />
    </>
  );
};

export default OrgSettingsEmailTemplatesView;
