// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDetermineHomeUrl } from 'accounts/assets/js/reducers/auth';
import { selectHasFatalError } from 'core/assets/js/ducks/errors';
import RedirectHelper from 'core/assets/js/config/routes/RedirectHelper';

const RedirectToHome = ({ history, location, determineHomeUrl, hasFatalError }) => {
  RedirectHelper.redirectToHome({
    history, location, determineHomeUrl, forceLogout: hasFatalError,
  });
  return null;
};

RedirectToHome.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hasFatalError: PropTypes.bool.isRequired,
  determineHomeUrl: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  hasFatalError: selectHasFatalError(state),
  determineHomeUrl: getDetermineHomeUrl(state),
});

const RedirectToHomeConnect = connect(
  mapStateToProps,
)(RedirectToHome);

export default withRouter(RedirectToHomeConnect);
