import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BS_STYLE, PROFILE_TABS, IMG_SIZE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { projectReviewsSubmitAC } from 'projects/assets/js/actions/view';
import ReviewForm from 'people/assets/js/components/ReviewForm.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { orgUserProfileUrl } from 'people/urls';

const reviewUserModalId = 'review-modal-id';

class ReviewRequestItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenReviewModal = this.handleOpenReviewModal.bind(this);
    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);
    this.handleReviewAdded = this.handleReviewAdded.bind(this);
  }

  handleOpenReviewModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(reviewUserModalId));
  }

  handleCloseReviewModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleReviewAdded(review) {
    const { dispatch, match, profile, history, userType } = this.props;
    const { orgAlias } = match.params;
    dispatch(projectReviewsSubmitAC(review));
    history.push(orgUserProfileUrl(orgAlias, userType, profile.userId, PROFILE_TABS.REVIEWS));
  }

  render() {
    const { isReviewModalOpen, profile, project } = this.props;

    return (
      <>
        <div className="row review-request-item rounded-sm d-flex align-items-center py-4 mx-0 mb-4">
          <div className="col-sm-12 col-md-9 pl-0 mb-4 mb-md-0">
            <div className="d-flex col-sm-12 flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-center text-center text-sm-left">
              <ProfilePic
                className="mr-0 mr-sm-3 mb-3"
                url={profile && profile.avatar}
                alt={`${profile ? profile.firstName : 'Users'}'s avatar`}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
              />
              <div>
                <div className="font-weight-bold mb-1">{profile.firstName}</div>
                <div>
                  Hi, thanks for approving my worksheet.
                  How was my work? Would you like to write me a review?
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 flex-shrink-0 text-center text-sm-right">
            <TDButton
              type="submit"
              variant={BS_STYLE.PRIMARY}
              onClick={this.handleOpenReviewModal}
              label="Write Review"
            />
          </div>
        </div>
        <ReviewForm
          projectOptions={[{ text: project.title, value: project.id }]}
          initialValues={{
            project_id: project.id,
          }}
          onAfterSubmit={this.handleReviewAdded}
          onCloseModal={this.handleCloseReviewModal}
          show={isReviewModalOpen}
          revieweeFirstName={profile.firstName}
          showProjectDropdown
          payload={{ subject_id: profile.userId }}
        />
      </>
    );
  }
}

ReviewRequestItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isReviewModalOpen: PropTypes.bool,
  match: PropTypes.object.isRequired,
  userType: PropTypes.number.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  profile: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

ReviewRequestItem.defaultProps = {
  isReviewModalOpen: false,
  profile: {},
  project: {},
};


const mapStateToProps = (state) => {
  const isReviewModalOpen = getIsModalOpen(state, reviewUserModalId);
  return {
    isReviewModalOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ReviewRequestItemConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewRequestItem);

export default withRouter(ReviewRequestItemConnected);
