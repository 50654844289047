import React from 'react';
import PropTypes from 'prop-types';
import { sortBy, sortedUniqBy } from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import TransferwiseValidator from 'services/assets/js/lib/TransferwiseValidator';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import { twBankAccountFieldHelpText } from 'settings/assets/js/components/tw-form-components/twBankAccountUtils';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
/**
 * It returns a final form field with all props needed based on the field type provided.
 * @param field - The TW bank account dynamic field which is generated
 * @param currency
 * @returns {TextInputField | SelectField | RadioField}
 */
const TWBankAccountSwitchFields = ({ field, currency, onCountrySelect }) => {
  let options;
  const validation = (_field, _value) => {
    try {
      TransferwiseValidator.validateField(_field, _value);
      return null;
    } catch (err) {
      return err.message;
    }
  };

  // we are showing country select field in our first step of the TW Wizard so we need to disable
  // it in the second step
  const isCountryField = field.key === 'address.country';
  const countryFieldChangeLink = <Link onClick={onCountrySelect}>Change country</Link>;

  switch (field.type) {
    case 'date':
      return (
        <DatePickerField
          required={field.required}
          name={field.key}
          label={field.name}
          sublabel={field.sublabel}
          placeholder={field.example}
          validate={val => validation(field, val)}
        />
      );
    case 'text':
      return (
        <TextInputField
          required={field.required}
          name={field.key}
          label={field.name}
          sublabel={field.sublabel}
          placeholder={field.example}
          extraInfo={twBankAccountFieldHelpText(field, currency)}
          validate={val => validation(field, val)}
        />
      );
    case 'select':
    case 'radio':
      options = Array.isArray(field.valuesAllowed)
        ? sortedUniqBy(sortBy(field.valuesAllowed, 'name'), 'name').map((val => ({ text: val.name, value: val.key })))
        : [];
      return (
        <SelectField
          extraLabelActions={isCountryField ? countryFieldChangeLink : null}
          required={field.required}
          disabled={isCountryField}
          name={field.key}
          label={field.name}
          sublabel={field.sublabel}
          defaultOptionText="Select your option"
          optionsMapping={options}
          validate={val => validation(field, val)}
        />
      );
    default:
      return null;
  }
};

TWBankAccountSwitchFields.propTypes = {
  currency: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  onCountrySelect: PropTypes.func.isRequired,
};


export default withRouter(TWBankAccountSwitchFields);
