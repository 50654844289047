import React from 'react';

import { ListGroup } from 'react-bootstrap';

function ListGroupSection() {
  return (
    <section id="list-group">
      <h2>List-Group</h2>
      <div className="row">
        <div className="col-12 col-sm-6">
          <ListGroup className="simple">
            <ListGroup.Item>
              Line 1
              <span className="float-right">$5,000</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Line 2
              <span className="float-right">$15,000</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Line 3
              <span className="float-right">$80,000</span>
            </ListGroup.Item>
            <ListGroup.Item className="emphasize">
              Summary
              <span className="float-right">$100,000</span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </section>
  );
}

export default ListGroupSection;
