import React from 'react';
import { Card } from 'react-bootstrap';

import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const TalentDetailsSkeleton = () => (
  <Card className="user-profile__card mb-2">
    <Card.Body className="px-0">
      <div className="user-profile__summary d-flex flex-column align-items-center text-center">
        <CircleSkeleton className="mb-2" diameter="100" />
        <div className="user-profile__basic-info d-flex flex-column justify-content-end w-100 freelancer-details-card">
          <div className="mt-2 mt-md-0">
            <span className="user-profile__name">
              <RectangleSkeleton height="30" width="183" />
            </span>
          </div>

          <div className="text-truncate w-100">
            <RectangleSkeleton height="24" width="183" />
          </div>

          <div className="my-5">
            <RectangleSkeleton height="20" width="100" />
          </div>

          <div className="freelancer-details-card__seperator">
            <RectangleSkeleton height="20" width="140" />
            {' '}
            <br />
            <RectangleSkeleton height="20" width="160" />
          </div>
          <div className="freelancer-details-card__seperator row">
            <div className="row mt-4 mb-2 mx-0 d-flex justify-content-around w-100">
              <RectangleSkeleton
                className="user-item__small"
                height="50"
                width="150"
              />
              <RectangleSkeleton height="50" width="100" />
            </div>
          </div>
          <div className="freelancer-details-card__seperator row justify-content-center align-items-center">
            <RectangleSkeleton height="50" width="70" />
          </div>
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default TalentDetailsSkeleton;
