import React, { cloneElement }  from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';

const NavBarSectionWrapper = ({ onClose, children, isOpen, title }) => {
  return (
    <div className={`navbar-section ${isOpen ? 'navbar-section--open' : ''}`}>
      <div className="navbar-section__header">
        <i
          onClick={() => onClose(false)}
          className={`${ICON.ARROW_LEFT} cursor-pointer`}
        />
        <span className="navbar-section__title">{title}</span>
      </div>

      {cloneElement(children, { onClose })}
    </div>
  );
};

NavBarSectionWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
};
NavBarSectionWrapper.defaultProps = {
  title: null,
};

export default NavBarSectionWrapper;
