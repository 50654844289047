import { toInteger } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { interviewSpec, submissionSpec } from 'interviews/assets/js/lib/objectSpecs';
import { changeOnboardingFormApiUrl, interviewListApiUrl, submissionUrl } from 'interviews/urls';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { orgPeopleListUrl } from 'people/urls';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

const interviewListKey = componentName => `${componentName}-interviews-list`;

export const MODAL_ID = 'change-onboarding-form-modal';

const ChangeOnboardingFormModal = ({
  dispatch,
  history,
  interviews,
  isOpen,
  onClose,
  orgAlias,
  parentComponentName,
  submission,
}) => {
  const changeOnboardingForm = async ({ interview_id: interviewId }) => {
    try {
      const { data: newSubmission } = await axios.post(
        changeOnboardingFormApiUrl({ orgAlias, submissionId: submission.id }),
        { interviewId },
      );
      toastr.success('Well Done!', 'Onboarding form successfully changed.');
      if (newSubmission && newSubmission.id) {
        // Have to use WINDOW_LOCATION_ASSIGN to load the new submission. Using history.push when
        // the new route is the same as the current one, prevents it from calling FetchData
        history.replace(submissionUrl(orgAlias, newSubmission.id));
      } else {
        // The logged in user is a manager or financial controller and the new submission does not
        // contain any questions they can answer. So they cannot access it
        history.push(orgPeopleListUrl(orgAlias, PEOPLE_TYPE.PROVIDERS));
      }
    } catch (e) {
      const error = e.response?.data?._error || e.message;
      toastr.error('Oh Snap!', error);
      // This is required to prevent the modal thinking confirm was successful and then closing
      // automatically
      throw new Error(error);
    }
  };

  const onboardingFormOptions = interviews
    .filter(i => i.id !== submission.interviewId)
    .map(i => ({ text: i.name, value: i.id }));

  const hasOnboardingFormOptions = onboardingFormOptions.length > 0;

  const interviewListStoreKey = interviewListKey(parentComponentName);

  return (
    <Form
      onSubmit={changeOnboardingForm}
      render={({ form: { getState }, handleSubmit }) => (
        <ModalConfirm
          confirmButtonDisabled={!toInteger(getState().values.interview_id)}
          confirmLabel="Send"
          data-testid="change-onboarding-form-modal"
          heading="Send a new onboarding form"
          open={isOpen}
          onClose={onClose}
          onConfirm={handleSubmit}
          showConfirmButton={hasOnboardingFormOptions}
          size={MODAL_SIZES.LARGE}
        >
          <TDApiConnected
            duck="list"
            fetchData={({ authedAxios, url }) => dispatch(fetchListDS({
              authedAxios,
              componentName: interviewListStoreKey,
              url: interviewListApiUrl(orgAlias, url),
            }))}
            storeKey={interviewListStoreKey}
          >
            <p>
              Is there a mistake in the onboarding form sent to the user? Or maybe you
              want to send a different onboarding form?
              <br />
              Simply select the new onboarding form and then click &quot;Send&quot;.
            </p>
            <AreaCollapsible
              className="mb-5 mt-5"
              contentChildren={(
                <p>
                  If you choose to send a new onboarding form to the user, the existing
                  onboarding submission and any answers provided will be deleted and the
                  user will have to re-submit their answers.
                </p>
              )}
              headingChildren="Is it safe to send a new onboarding form?"
              solidColor
            />
            {hasOnboardingFormOptions && (
              <SelectField
                label="Onboarding form"
                name="interview_id"
                optionsMapping={onboardingFormOptions}
                required
              />
            )}
            {!hasOnboardingFormOptions && (
              <TDSystemMessage
                data-testid="change-onboarding-form-modal-cannot-message"
                title="Cannot change onboarding form"
                type={BS_STYLE.DANGER}
              >
                <p>
                  Your organization does not have any other onboarding forms, so you
                  cannot change this onboarding submission.
                </p>
              </TDSystemMessage>
            )}
          </TDApiConnected>
        </ModalConfirm>
      )}
    />
  );
};

ChangeOnboardingFormModal.GetComponentName = () => 'ChangeOnboardingFormModal';

ChangeOnboardingFormModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  interviews: PropTypes.arrayOf(interviewSpec),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  submission: submissionSpec.isRequired,
};

ChangeOnboardingFormModal.defaultProps = {
  interviews: [],
};

const mapStateToProps = (state, props) => ({
  interviews: getListState(state, interviewListKey(props.parentComponentName)).items,
});

const mapDispatchToProps = dispatch => ({ dispatch });

const ChangeOnboardingFormModalConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ChangeOnboardingFormModal);

export default withRouter(ChangeOnboardingFormModalConnected);
