import { InvalidBankAccountError } from 'settings/assets/js/lib/errors';
import { bankAccountType, bankAccountSubType } from 'core/assets/js/lib/bankAccountDetailOptions';

const ibantools = require('ibantools');

/**
 * ABA Routing Number validation check
 * @param  {String}  routingNumber
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidUsRouting = (routingNumber) => {
  if (!/^\d{9}$/.test(routingNumber)) {
    throw new InvalidBankAccountError('US routing number has 9 digits');
  }

  const d = String(routingNumber)
    .split('')
    .map(n => parseInt(n, 10));

  // Check Checksum
  // https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit
  if ((
    (3 * (d[0] + d[3] + d[6]))
      + (7 * (d[1] + d[4] + d[7]))
      + (d[2] + d[5] + d[8])
  ) % 10 !== 0) {
    throw new InvalidBankAccountError('Invalid us routing number');
  }
  return true;
};

/**
 * Is valid international account
 *
 * Any bank account that is not US, GB or SEPA. In that case the validation rules
 * are rather loose.
 *
 * @param  {String}  accNumber
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidAccount = (accNumber) => {
  if (!/^\d{6,17}$/gi.test(accNumber)) {
    throw new InvalidBankAccountError('Account has 6 to 17 digits');
  }
  return true;
};

/**
 * Is valid Russian account
 *
 * Russian Bank Account number validation
 * are rather loose.
 *
 * @param  {String}  accNumber
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidRuAccount = (accNumber) => {
  if (!/^\d{6,20}$/gi.test(accNumber)) {
    throw new InvalidBankAccountError('Account has 6 to 20 digits');
  }
  return true;
};

/**
 * US Bank Account number validation check
 * @param  {String}  accNumber
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidUsAccount = (accNumber) => {
  if (!/^\d{4,17}$/.test(accNumber)) {
    throw new InvalidBankAccountError('Account has 4 to 17 digits');
  }
  return true;
};

/**
 * UK Sort Code validation check
 * @param  {String}  bankCode
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidUkSortCode = (bankCode) => {
  if (!/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(bankCode)) {
    throw new InvalidBankAccountError('Sort-code has 6 digits');
  }
  return true;
};

/**
 * Australia BSB Code validation check
 * @param  {String}  bsbCode
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidAuBsbCode = (bsbCode) => {
  if (!/^[0-9|A-Z|a-z]{5,6}$/.test(bsbCode)) {
    throw new InvalidBankAccountError('Invalid bsb code');
  }
  return true;
};

/**
 * CA Institution No. validation check
 * @param  {String}  institutionNo
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidCaInstitutionNo = (institutionNo) => {
  if (!/^(?!(?:0{3}|00-00-00))(?:\d{3}|\d\d-\d\d-\d\d)$/.test(institutionNo)) {
    throw new InvalidBankAccountError('Institution no. has 3 digits');
  }
  return true;
};

/**
 * CA Transit No. validation check
 * @param  {String}  transitNo
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const validateCaTransitNo = (transitNo) => {
  if (!/^(?!(?:0{5}|00-00-00))(?:\d{5}|\d\d-\d\d-\d\d)$/.test(transitNo)) {
    throw new InvalidBankAccountError('Transit no. has 5 digits');
  }
  return true;
};

/**
 * IN IFSC Code validation check
 * @param  {String}  ifscCode
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const validateIfscCode = (ifscCode) => {
  if (!/[A-Z|a-z]{4}[0][\d]{6}$/.test(ifscCode)) {
    throw new InvalidBankAccountError('Invalid ifsc code');
  }
  return true;
};

/**
 * Account Type validation check
 * @param  {String}  accountType
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const validateAccountType = (accountType) => {
  if (!bankAccountType[accountType]) {
    throw new InvalidBankAccountError('Select valid account type');
  }
  return true;
};

/**
 * Account Sub Type validation check
 * @param  {String}  accountSubType
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const validateAccountSubType = (accountSubType) => {
  if (!bankAccountSubType[accountSubType]) {
    throw new InvalidBankAccountError('Select valid account subtype');
  }
  return true;
};

/**
 * UK Account Number validation check
 * @param  {String}  accNumber
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidUkAccount = (accNumber) => {
  if (!/^\d{6,11}$/.test(accNumber)) {
    throw new InvalidBankAccountError('Account has 6 to 11 digits');
  }
  return true;
};

export const isValidCnAccount = (accNumber) => {
  const isFullNumericValid = /^[\d]{16,22}$/.test(accNumber);
  const isHyphenatedValid = /^\d{3,6}-\d{7,11}(-\d{2})?$/.test(accNumber);

  if (!isFullNumericValid && !isHyphenatedValid) {
    throw new InvalidBankAccountError('Card number has up to 22 digits');
  }

  return true;
};

/**
 * BIC/SWIFT code validation check
 * @param  {String}  bic
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidBic = (bic) => {
  if (!bic || !ibantools.isValidBIC(bic)) {
    throw new InvalidBankAccountError('Invalid bic/swift code');
  }
  return true;
};

/**
 * CN Bank code validation check
 * @param  {String}  bic
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidCNBankCode = (bankCode) => {
  if (!bankCode || !ibantools.isValidBIC(bankCode)) {
    throw new InvalidBankAccountError('Invalid bank code');
  }
  return true;
};


/**
 * IBAN validation check
 * @param  {String}  iban
 * @return {Boolean} Boolean true if valid, otherwise throws InvalidBankAccountError
 */
export const isValidIban = (iban) => {
  if (!ibantools.isValidIBAN(ibantools.electronicFormatIBAN(iban))) {
    throw new InvalidBankAccountError(`Invalid iban (${iban})`);
  }
  return true;
};

/**
 * Validate bank network info by Country Code
 * @param  {String} countryCode ISO 2-letter country code
 * @return {Function}           one of the isValid* functions
 */
export const validateBankNetworkByCc = (countryCode) => {
  switch (countryCode) {
    case 'US':
      return isValidUsRouting;
    case 'GB':
      return isValidUkSortCode;
    case 'AU':
      return isValidAuBsbCode;
    case 'CA':
      return isValidCaInstitutionNo;
    case 'CN':
      return isValidCNBankCode;
    default:
      return isValidBic;
  }
};

/**
 * Validate bank network info by Country Code
 * @param  {String} countryCode ISO 2-letter country code
 * @return {Function}           one of the isValid* functions
 */
export const validateBankAccountByCc = (countryCode) => {
  if (countryCode === 'US') {
    return isValidUsAccount;
  }

  if (countryCode === 'GB') {
    return isValidUkAccount;
  }

  if (countryCode === 'CN') {
    return isValidCnAccount;
  }

  if (countryCode === 'RU') {
    return isValidRuAccount;
  }

  return isValidAccount;
};

/**
 * Validates if a url is valid or not
 * @param url Url to be tested for validation
 * @returns {boolean}
 */
export const isValidUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+;=.]+$/gm;
  return regex.test(url);
};
