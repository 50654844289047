import moment from 'moment';
import { getDatetime } from 'core/assets/js/lib/utils';
import { INVOICING_FREQUENCY_SEGMENT } from 'finance/assets/js/constants';
import CompoundFrequency from 'finance/assets/js/lib/CompoundFrequency';

/** A POJO keeping the invoicing frequency for a specific invoicing segment */
class InvoicingSegmentFrequency extends CompoundFrequency {
  /**
   * Validates invoicing segments
   *
   * @param {[string]} segments - an array of segments
   * @throws Will throw an error if the segments are not one of INVOICING_FREQUENCY_SEGMENT
   */
  static validateSegments(segments) {
    if (!segments) {
      throw new Error('segments is required');
    }
    segments.forEach((segment) => {
      if (!Object.values(INVOICING_FREQUENCY_SEGMENT).includes(segment)) {
        throw new Error(`unknown invoice segment ${segment}`);
      }
    });
  }

  /**
   * Creates an instance that targets all available segments
   *
   * @return {InvoicingSegmentFrequency}
   */
  static forAllSegments(schema) {
    return new InvoicingSegmentFrequency({
      segments: Object.values(INVOICING_FREQUENCY_SEGMENT),
      schema,
    });
  }

  constructor({ segments, schema }) {
    super(schema);
    InvoicingSegmentFrequency.validateSegments(segments);
    Object.assign(this.details, { segments });
  }

  /**
   * According to current date (which can be overridden to replay a billing cycle),
   * return what was the previous and the next billing process that billing should occur
   *
   * @param {Date} now
   * @returns {Object}
   */
  getBillingProcessRange(now) {
    const { default: BillingProcess } = require('finance/assets/js/lib/BillingProcess');
    const lastDeadline = this.getPrevDate({ now, inclusive: true });
    const nextDeadline = this.getNextDate({ now: lastDeadline });
    return {
      from: BillingProcess.fromDeadline(lastDeadline),
      to: BillingProcess.fromDeadline(nextDeadline),
    };
  }

  /**
   * According to current date (which can be overridden to replay a billing cycle),
   * return what was the billing process that billing should have *already* occurred.
   *
   * @param {Date} now
   * @returns BillingProcess
   */
  getLastBillingProcess(now = getDatetime()) {
    return this.getBillingProcessRange(now).from;
  }

  /**
   * According to current date (which can be overriden to replay a billing cycle),
   * return what is the next billing process that billing will occur.
   *
   * @param {Date} now
   * @returns BillingProcess
   */
  getNextBillingProcess(now = getDatetime()) {
    return this.getBillingProcessRange(now).to;
  }

  /**
  * According to current date (which can be overridden to replay a billing cycle), return what is
  * the current billing cycle.
  *
  * @params {Date} now
  * @returns Date
  */
  getDatetimeRange(now = getDatetime()) {
    const billingProcessRange = this.getBillingProcessRange(now);
    return {
      fromDateTime: billingProcessRange.from.getBillingDeadline(),
      toDateTime: moment(billingProcessRange.to.getLastInclusiveDatetime()),
    };
  }
}
export default InvoicingSegmentFrequency;
