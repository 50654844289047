import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_SIZE } from 'core/assets/js/constants';
import { stringifyQueryParams } from 'core/assets/js/lib/utils';
import { orgPeopleDiscoverFreelancersUrl } from 'people/urls';

const SKILLS = [
  { id: 1, label: 'Content' },
  { id: 2, label: 'Design' },
  { id: 3, label: 'Development' },
  { id: 4, label: 'Seo' },
  { id: 5, label: 'Social' },
  { id: 6, label: 'Translation' },
];

const SearchBannerWidget = ({ history, orgAlias, title }) => {
  const getSearchSpec = () => ({
    filters: [],
    searchTerm: {
      component: AutoSuggestField,
      paramName: 'kw',
      placeholder: 'Try “marketing”',
    },
  });

  const getInitialValues = () => {
    return {};
  };

  return (
    <div className="search-banner-widget-section">
      <h1 className="search-banner-widget-section__title">{title}</h1>
      <SearchFinalForm
        alwaysShowSearch
        className="pl-0 flex-grow-0"
        initialValues={getInitialValues()}
        name="talents"
        onFiltersChanged={(query) => {
          if (query?.kw) {
            history.push(
              `${orgPeopleDiscoverFreelancersUrl(orgAlias)}?${stringifyQueryParams(
                query,
              )}`,
            );
          }
        }}
        searchSpec={getSearchSpec()}
        searchContainerClass="mt-3 col-12 col-md-6 search-banner-widget-section__input px-0 position-relative"
      />
      <div className="col-12 px-0">
        <div className="row d-flex align-items-center">
          <div className="col-xs-12 col-auto mb-2 pr-0">
            <span className="freelancer-item__small">Popular skills:</span>
          </div>
          <div className="col-xs-12 col-auto">
            {SKILLS.map((skill, idx) => {
              return (
                <TDButton
                  bsSize={BS_SIZE.XSMALL}
                  className="mr-3 ml-0 search-banner-widget-section__link"
                  key={`${idx}-${skill.label}`}
                  label={skill.label}
                  onClick={() =>
                    history.push(
                      `${orgPeopleDiscoverFreelancersUrl(orgAlias)}?kw=${skill.label}`,
                    )
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBannerWidget.propTypes = {
  history: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withRouter(withFilters(SearchBannerWidget));
