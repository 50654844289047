export const PPH_STATUS = {
  NOT_INVITED: 'not invited',
  INVITED: 'invited',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const PPH_STATUS_CLASS = {
  [PPH_STATUS.NOT_INVITED]: 'default',
  [PPH_STATUS.INVITED]: 'posted',
  [PPH_STATUS.CANCELLED]: 'cancelled',
  [PPH_STATUS.EXPIRED]: 'expired',
  [PPH_STATUS.ACCEPTED]: 'accepted',
  [PPH_STATUS.REJECTED]: 'rejected',
};

export const SEARCH_MAX_LENGTH = 50;
