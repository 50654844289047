import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { documentGetExternalSignUrlApiUrl } from 'documents/urls';

const containerClass = [
  'document-esign-external-container', 'd-flex', 'align-items-center', 'justify-content-center',
].join(' ');

const DocumentSignExternalView = ({ match: { params: { token } } }) => {
  const [loading, setLoading] = useState(true);
  const [embedUrl, setEmbedUrl] = useState(false);

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }
    axios
      .get(documentGetExternalSignUrlApiUrl(token))
      .then(({ data }) => {
        setEmbedUrl(data.embedUrl);
      })
      .catch(err => {
        toastr.error('Oh Snap!', err.response?.data?._error || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className={containerClass}><p>Loading</p></div>;
  }

  if (!loading && !embedUrl) {
    return <div className={containerClass}><p>Invalid token</p></div>;
  }

  return (
    <iframe className="document-esign-external-iframe" src={embedUrl} title="Sign your document" />
  );
};

DocumentSignExternalView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(DocumentSignExternalView);
