import { fromPairs, isArray, isFinite, isEmpty, isNil, isNaN, isObject, sortBy, reverse, toPairs, zip } from 'lodash';
import Big from 'big.js';

Big.RM = 1;
Big.DP = 2;

class FeeScale {
  static parsePercentage(percentage) {
    if (!isFinite(parseFloat(percentage))) {
      throw new Error('percentage is not a number');
    }
    return parseFloat(percentage);
  }

  static parseAmount(amount) {
    if (!isFinite(parseFloat(amount))) {
      throw new Error(`amount is not a number (${amount})`);
    }
    return Big(amount).toFixed(2);
  }

  static parse(spec) {
    let parsedSpec;
    if (isArray(spec)) {
      parsedSpec = fromPairs(
        spec.map(([k, v]) => ([
          FeeScale.parseAmount(k), FeeScale.parsePercentage(v),
        ])),
      );
    } else if (isObject(spec)) {
      parsedSpec = fromPairs(
        toPairs(spec).map(([k, v]) => ([
          FeeScale.parseAmount(k), FeeScale.parsePercentage(v),
        ])),
      );
    } else if (!isNaN(spec)) {
      // plain number
      parsedSpec = { '0.00': isNil(spec) ? 0 : spec };
    } else {
      throw new Error(`cannot parse ${spec} as a fee scale`);
    }

    if (isEmpty(parsedSpec)) {
      parsedSpec = { '0.00': 0 };
    }

    if (typeof parsedSpec['0.00'] === 'undefined') {
      throw new Error('Fee scheme must always have a percentage for charges over "0.00"');
    }

    const steps = sortBy(Object.keys(parsedSpec), k => parseFloat(k));
    const ranges = zip(steps.slice(0, steps.length - 1), steps.slice(1));
    ranges.push([steps[steps.length - 1], Infinity]);

    const scale = ranges.map(r => ({
      range: r,
      feePercent: parsedSpec[r[0]],
      feeFactor: parsedSpec[r[0]] / 100,
    }));
    return { parsedSpec, scale };
  }

  constructor(spec) {
    if (spec instanceof FeeScale) {
      throw new Error('spec is already an instance of FeeScale');
    }
    const { parsedSpec, scale } = FeeScale.parse(spec);
    this.steps = scale;
    this.basePercent = scale[0].feePercent;
    this.spec = parsedSpec;
  }

  serialize() {
    return this.spec;
  }

  toString() {
    return JSON.stringify(this.serialize(), null, 2);
  }

  getBasePercent() {
    return this.basePercent;
  }

  isZero() {
    return this.steps.every(s => (
      Big(s.feePercent).toFixed(2) === '0.00'
    ));
  }

  getDecreasedSteps(preBilledAmount = '0.00') {
    const { steps } = this;
    return steps.filter((s) => {
      if (s.range[1] === Infinity) {
        return true;
      }
      return Big(s.range[1]).gte(preBilledAmount);
    }).map((s) => {
      return {
        ...s,
        range: [
          Big(s.range[0]).gte(preBilledAmount) ? Big(s.range[0]).minus(preBilledAmount).toFixed(2) : '0.00',
          s.range[1] === Infinity ? Infinity : Big(s.range[1]).minus(preBilledAmount).toFixed(2),
        ],
      };
    });

  }

  apply(amount, { preBilledAmount = '0.00' } = {}) {
    const steps = this.getDecreasedSteps(preBilledAmount);
    const split = [];
    let residual = Big(amount);
    // We need immutability here, so we don't change steps which is a part of 'this'
    const _steps = [...steps];
    reverse(_steps).forEach((s) => {
      const largerThanLowerLimit = residual.cmp(s.range[0]) === 1;
      const lessThanUpperLimit = !isFinite(s.range[1]) || residual.cmp(s.range[1]) !== 1;
      if (largerThanLowerLimit && lessThanUpperLimit) {
        const billableAmount = Big(residual).minus(s.range[0]).toFixed(2);
        split.push({
          billableAmount,
          feeFactor: s.feeFactor,
          feePercent: s.feePercent,
          billableRange: s.range,
          fee: Big(billableAmount).times(s.feeFactor).toFixed(2),
        });
        residual = residual.minus(billableAmount);
      }
    });
    const applied = reverse(split);
    return applied;
  }

  getDescription() {
    const { steps } = this;
    return steps.map((s) => {
      if (isFinite(s.range[1])) {
        return `${s.feePercent}% from ${s.range[0]} to ${s.range[1]}`;
      }
      return `${s.feePercent}% over ${s.range[0]}`;
    }).join(', ');
  }

  getSteps() {
    return this.steps;
  }
}

export default FeeScale;
