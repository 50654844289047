import React, { useState } from 'react';

import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import UpgradeTrialModal from 'core/assets/js/components/UpgradeTrialModal.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const UpgradeTrialBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="text-white upgrade-trial-banner d-flex align-items-center justify-content-center">
      Let us help you make the most of your trial, book a call with one of our experts.

      <TDButton
        className="col-auto ml-4 btn-md"
        floatRight
        label="Book a Call"
        onClick={() => setIsModalOpen(true)}
        type="button"
        variant={BS_STYLE.PRIMARY}
      />

      <ModalSimple
        body={(
          <UpgradeTrialModal />
        )}
        heading="Book a Call"
        noFooter
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        size={BS_SIZE.LARGE}
      />
    </div>
  );
};

export default UpgradeTrialBanner;
