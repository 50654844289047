import { pick } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { getViewState, viewUpdateAC } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import RateCancelForm from 'rates/assets/js/components/RateCancelForm.jsx';
import RateCreateForm from 'rates/assets/js/components/RateCreateForm.jsx';
import RateReviewForm from 'rates/assets/js/components/RateReviewForm.jsx';
import { orgSettingsRatesUrl } from 'rates/urls';
import { fetchRateDS } from 'rates/assets/js/data-services/rates';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import RateAdjustmentTimeline from 'rates/assets/js/components/RateAdjustmentTimeline.jsx';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { orgUserProfileUrl } from 'people/urls';
import {  PROFILE_TABS, USER_TYPE } from 'core/assets/js/constants';

class RatesEditView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchRateDS({
        orgAlias: params.orgAlias, id: params.rateId, url, authedAxios, componentName,
      })),
    ]);
  }

  static GetComponentName() {
    return 'RatesEditView';
  }

  handleBreadCrumbUrl() {
    const { rate, match } = this.props;
    const { orgAlias } = match.params;
    const { rateAdjustmentAllowedActions } = rate;
    if (!rateAdjustmentAllowedActions?.isAnyManager) {
      return orgSettingsRatesUrl(orgAlias);
    }
    return  orgUserProfileUrl(
      orgAlias,
      rateAdjustmentAllowedActions?.isTargetManager ? USER_TYPE.MANAGE : USER_TYPE.PROVIDER,
      rate.user_id,
      PROFILE_TABS.RATES,
    );
  }

  render() {
    const { dispatch, rate } = this.props;
    const { rateAdjustmentAllowedActions, rateAdjustments } = rate;
    const breadcrumbs = [
      {
        title: 'Rates',
        url: this.handleBreadCrumbUrl(),
      },
      {
        title: 'Edit',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--rate-edit">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={RatesEditView}
              skeletonComponent={ProjectCardSkeleton}
            >
              <div className="rounded shadow-sm p-4 bg-white align-top">
                <RateAdjustmentTimeline
                  scrollMode={false}
                  rateAdjustments={rateAdjustments}
                />
                { rateAdjustmentAllowedActions?.canInitiate && (
                  <RateCreateForm
                    dispatch={dispatch}
                    initialValues={pick(rate, ['alias', 'unit'])}
                    onSubmitSuccess={(response) => {
                      dispatch(viewUpdateAC(response, RatesEditView.GetComponentName()));
                      toastr.success(
                        'Well Done!', 'The new rate adjustment request sent for review');
                    }}
                    displayInContainer
                    rate={rate}
                  />
                )}
                {rateAdjustmentAllowedActions?.canCancel && (
                  <RateCancelForm
                    rate={rate}
                    onSubmitSuccess={(response) => {
                      dispatch(viewUpdateAC(response, RatesEditView.GetComponentName()));
                      toastr.success('Well Done!', 'The rate adjustment request cancelled');
                    }}
                  />
                )}
                {rateAdjustmentAllowedActions?.canRespond && (
                  <RateReviewForm
                    rate={rate}
                    onSubmitSuccess={(updatedRate) => {
                      dispatch(viewUpdateAC(updatedRate, RatesEditView.GetComponentName()));
                    }}
                  />
                )}
              </div>
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RatesEditView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  rate: PropTypes.shape(rateSpec),
};
RatesEditView.defaultProps = {
  rate: {},
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, RatesEditView.GetComponentName());
  return {
    rate: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const RatesEditViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RatesEditView);

export default withRouter(RatesEditViewConnect);
