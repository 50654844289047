import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import { withRouter, NavLink } from 'react-router-dom';

/**
 * Styleguide Menu component
 */
function PlaygroundMenu({ sections, basePath }) {
  return (
    <ListGroup className="styleguide-menu">
      {Object.keys(sections).map(section => (
        <ListGroup.Item key={section} className="pl-4 py-3">
          <NavLink to={`/${basePath}/${section}`}>
            {section}
          </NavLink>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

PlaygroundMenu.propTypes = {
  sections: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default withRouter(PlaygroundMenu);
