import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import CustomFieldRenderer from 'core/assets/js/components/CustomFieldRenderer.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { orgTemplatesManageUrl } from 'settings/urls';
import { uploaderInterviewPath } from 'core/urls';
import { INPUT_TYPE } from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import MultitextField from 'core/assets/js/components/FinalFormFields/MultitextField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import {
  BS_SIZE, BS_STYLE, ICON, MAX_UPLOAD_FILES, MIME_TYPES, USER_TYPE,
} from 'core/assets/js/constants';
import {
  ENTITY_TYPE_LABEL, FIELD_ENTITY_TYPE, TYPE, TYPE_LABEL, VISIBLE_TO_OPTIONS,
} from 'interviews/assets/js/constants';
import { Card } from 'react-bootstrap';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TemplateControlSelector from 'settings/assets/js/components/TemplateControlSelector.jsx';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { pluralize } from 'core/assets/js/lib/utils';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';
import ButtonsContainer from 'core/assets/js/components/ButtonsContainer.jsx';

const DragHandle = SortableHandle(() => (
  <span className={`${ICON.DRAG_HANDLE} interview-builder__drag-handle`} />
));

const attachmentTypes = [
  { value: 'IMAGES', text: 'Image' },
  { value: 'DOCUMENTS', text: 'Document' },
  { value: 'VIDEOS', text: 'Video' },
];

const SortableList = SortableContainer(({
  form, fields, initialValues, userCard, meta, entityType, showSearchableInfoModal,
  showReportableInfoModal,
}) => (
  <ul className="interview-builder__questions-list py-0">
    {fields.map((name, index) => {
      const data = fields.value && fields.value[index];
      return (
        <SortableCustomField
          key={name}
          data={data}
          name={name}
          form={form}
          userCard={userCard}
          index={index}
          idx={index}
          fields={fields}
          initialValues={initialValues}
          meta={meta}
          entityType={entityType}
          showSearchableInfoModal={showSearchableInfoModal}
          showReportableInfoModal={showReportableInfoModal}
        />
      );
    })}
  </ul>
));

const SortableCustomField = SortableElement(({
  form, data, fields, initialValues, userCard, name, idx, entityType,
  showSearchableInfoModal, showReportableInfoModal, meta: { submitError },
}) => {
  const index = idx;
  const mimeTypes = Array.isArray(data.mimetypes) && !isEmpty(data.mimetypes)
    ? data.mimetypes
    : MIME_TYPES.DOCUMENTS;

  const selectedVisibleToOptions = Array.isArray(data?.payload?.visibleTo)
    ? VISIBLE_TO_OPTIONS.filter(opt => (data?.payload?.visibleTo || []).includes(opt.value))
    : VISIBLE_TO_OPTIONS;

  const { usages = 0 } = fields.value[index] || {};
  const handleFieldRemoved = () => fields.remove(index);
  const entityTypeLabelPlural = pluralize(ENTITY_TYPE_LABEL[entityType], usages).toLowerCase();
  const isAnsweredByUserTypeVisible = FIELD_ENTITY_TYPE.USER === entityType
    && data.type !== TYPE.TEXT_BLOB;

  return (
    <div key={name} className="interview-builder__question">
      <DragHandle />

      <h4 className="pl-5 interview-builder__question-title pl-0">
        {`FIELD ${index + 1}: `}
        <span>{TYPE_LABEL[data.type]}</span>
      </h4>

      <ConfirmationButton
        buttonStyle={BS_STYLE.LINK}
        buttonSize={BS_SIZE.XSMALL}
        buttonIcon={ICON.CROSS}
        buttonClass="interview-builder__question-remove"
        modalProps={{
          heading: 'Delete Custom field',
          body: (
            <React.Fragment>
              {!!usages && (
                <p>
                  This custom field is used in
                  {' '}
                  {`${usages} ${entityTypeLabelPlural}`}
                  .
                  {' '}
                  {entityType === FIELD_ENTITY_TYPE.USER && (
                    `By deleting it, you will also delete the relevant information
                    from the user profiles and contacts that use it.`
                  )}
                  {entityType !== FIELD_ENTITY_TYPE.USER && (
                    `Deleting it does not affect the relevant information from the
                    ${entityTypeLabelPlural} that use it. However, it will not be available in any
                    new ${entityTypeLabelPlural} in the future.`
                  )}
                </p>
              )}
              <p>
                Are you sure you want to delete this custom field?
              </p>
            </React.Fragment>
          ),
          confirmLabel: 'Delete',
          confirmStyle: BS_STYLE.DANGER,
          cancelLabel: 'Close',
          onConfirm: async () => handleFieldRemoved(),
        }}
      />

      <div className="col-12 px-0">
        <TextInputField
          name={`${name}.label`}
          type={INPUT_TYPE.MARKDOWN_INPUT}
          component={TextInputField}
          placeholder="Label"
          validate={val => (!val ? 'Field is required' : null)}
          required
        />

        <TextInputField
          name={`${name}.description`}
          type={INPUT_TYPE.TEXT}
          placeholder="Optional description"
        />

        {data.type !== TYPE.TEXT_BLOB && (
          <RadioField
            name={`${name}.required`}
            label="Is this required?"
            options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
            required
          />
        )}

        { ![TYPE.TEXT_BLOB, TYPE.FILE].includes(data.type) && (
          <React.Fragment>
            <RadioField
              name={`${name}.isSearchable`}
              label="Is this searchable?"
              sublabel="Setting this field as searchable allows you to search via a dedicated filter, which performs an exact match search."
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />

            { /* Show info popup when user sett isSearchable to false */ }
            <OnChange name={`${name}.isSearchable`}>
              {(value, previous) => {
                if (value !== previous && value === false) {
                  showSearchableInfoModal();
                }
              }}
            </OnChange>
          </React.Fragment>
        )}

        {data.type === TYPE.TEXT_BLOB && (
          <TextAreaField
            name={`${name}.payload.text`}
            label="Text"
            required
            validate={val => (!val ? 'Field is required' : null)}
            mdEditorEnabled
          />
        )}

        {data.type === TYPE.TEXT && (
          <div>
            <RadioField
              name={`${name}.payload.isTextArea`}
              label="Use multiple lines?"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />
          </div>
        )}

        {data.type === TYPE.SELECT && (
          <div>
            <RadioField
              name={`${name}.payload.multiple`}
              label="Select multiple answers"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />

            <Field name={`${name}.payload.choicesAutoIncrement`} type="hidden" component="input" />

            <MultitextField
              asOptions
              inputType={INPUT_TYPE.MARKDOWN_INPUT}
              label="Choices"
              maxLength={255}
              name={`${name}.payload.choices`}
              onAutoIncrementUpdate={newAI => form.mutators.setChoicesAI(name, newAI)}
              required
            />

            <RadioField
              name={`${name}.payload.has_other`}
              component={RadioField}
              label="Has 'Other' option?"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />
          </div>
        )}

        {data.type === TYPE.FILE && (
          <React.Fragment>
            <CheckboxField
              name={`${name}.payload.attachment_types`}
              label="Allowed attachment types:"
              options={attachmentTypes}
            />

            <RadioField
              name={`${name}.payload.multiple`}
              label="Upload multiple files"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />

            <FileUploaderField
              name={`${name}.payload.attachments_dump`}
              label="Optional file template"
              acceptFiles={mimeTypes}
              maxFiles={MAX_UPLOAD_FILES}
              path={uploaderInterviewPath(
                userCard.organization.id,
                initialValues.id,
              )}
            />
          </React.Fragment>
        )}

        <div className="row">
          <div className="col-auto">
            <CheckboxField
              name={`${name}.payload.visibleTo`}
              label="Visible to"
              sublabel="Select the roles of the users who access access this custom field."
              options={VISIBLE_TO_OPTIONS}
              valueTransformer={item => item.value}
              selectedOptions={selectedVisibleToOptions}
              validate={(val) => {
                let error = null;
                if (
                  typeof val === 'undefined'
                  || !Array.isArray(val)
                  || (Array.isArray(val) && val.length === 0)
                ) {
                  error = 'Field is required';
                }
                return error;
              }}
            />

            { ![TYPE.TEXT_BLOB, TYPE.FILE].includes(data.type) && (
              <React.Fragment>
                { /* Show info popup when user sett isSearchable to false */ }
                <OnChange name={`${name}.isReportable`}>
                  {(value, previous) => {
                    if (value !== previous && value === true) {
                      showReportableInfoModal();
                    }
                  }}
                </OnChange>

                <RadioField
                  name={`${name}.isReportable`}
                  label="Is this reportable?"
                  sublabel="Setting this field as reportable grants permission to the system to include it in the contractor invoices."
                  options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
                  required
                />
              </React.Fragment>
            )}
          </div>
        </div>

        {isAnsweredByUserTypeVisible && (
          <RadioField
            label="Should this be answered by a manager?"
            name={`${name}.answeredByUserType`}
            options={[{ value: USER_TYPE.MANAGER, text: 'Yes' }, { value: USER_TYPE.PROVIDER, text: 'No' }]}
            required
          />
        )}
      </div>

      {submitError && submitError && submitError[index] && (
        <div className="has-error">
          <span className="help-block">{submitError[index]}</span>
        </div>
      )}
    </div>
  );
});


const TemplateBuilderForm = ({
  history, userCard, templateType, onSubmit, initialValues, match,
}) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [searchableInfoModalOpen, setSearchableInfoModalOpen] = useState(false);
  const [reportableInfoModalOpen, setReportableInfoModalOpen] = useState(false);
  const showPreviewModal = () => setPreviewModalOpen(true);
  const showSearchableInfoModal = () => setSearchableInfoModalOpen(true);
  const showReportableInfoModal = () => setReportableInfoModalOpen(true);
  const { orgAlias } = match.params;

  const handleItemAdded = (item, fields) => (
    fields.push({
      ...item,
      path: `cf_${fields.length + 1}`,
      payload: {
        visibleTo: Object.values(USER_TYPE),
      },
    })
  );

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
        setChoicesAI: (args, state, utils) => {
          utils.changeValue(state, `${args[0]}.payload.choicesAutoIncrement`, () => args[1]);
        },
      }}
      initialValues={{
        ...initialValues,
        field_entity_type: templateType,
      }}
      render={({
        initialValues: formInitialValues, form, handleSubmit, submitting,
      }) => (
        <form onSubmit={handleSubmit} className="p-0">
          <Card className="template-builder">
            <Card.Body className="py-0">
              <FieldArray name="questions">
                {({ fields, meta }) => {
                  const onSortEnd = ({ oldIndex, newIndex }) => {
                    fields.swap(oldIndex, newIndex);
                  };

                  return (
                    <div className="row">
                      <div className="col-12 p-0 template-control-selector-wrapper">
                        <TemplateControlSelector
                          onAdd={item => handleItemAdded(item, fields)}
                          templateType={templateType}
                        />
                      </div>

                      <div className="col py-4 border-left">
                        <Field name="field_entity_type" type="hidden" component="input" />
                        <div className="row">
                          <div className="col-12">
                            <TextInputField
                              name="name"
                              required
                              label="Template name"
                              placeholder="e.g Simple task"
                              validate={val => (!val ? 'Field is required' : null)}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <RadioField
                              name="isMandatory"
                              label="Is this template mandatory?"
                              options={[
                                { value: true, text: 'Yes' },
                                { value: false, text: 'No' },
                              ]}
                              validate={val => (typeof val === 'undefined' ? 'Field is required' : null)}
                              required
                            />
                          </div>
                        </div>

                        <h3 className="heading-block mt-4 mb-4">Template fields</h3>

                        <SortableList
                          userCard={userCard}
                          fields={fields}
                          initialValues={formInitialValues}
                          onSortEnd={onSortEnd}
                          form={form}
                          meta={meta}
                          entityType={templateType}
                          showSearchableInfoModal={showSearchableInfoModal}
                          showReportableInfoModal={showReportableInfoModal}
                          useDragHandle
                        />

                        {fields.length === 0 && (
                          <div className="interview-builder__intro-message">
                            Select any of the elements in the controls list to start building
                            your customised template.
                          </div>
                        )}
                      </div>

                      <ModalSimple
                        open={previewModalOpen}
                        heading="Custom fields preview"
                        body={fields.map((field, index) => {
                          const data = fields.value && fields.value[index];
                          const key = `custom-field-${index}`;
                          return (
                            <CustomFieldRenderer
                              key={key}
                              field={data}
                            />
                          );
                        })}
                        onClose={() => setPreviewModalOpen(false)}
                        fullWidth
                      />

                      <ModalSimple
                        open={searchableInfoModalOpen}
                        heading={(
                          <React.Fragment>
                            <i className={`discreet mr-2 ${ICON.WARNING}`} />
                            Did you know?
                          </React.Fragment>
                        )}
                        body={(
                          <p>
                            By setting this custom field as non searchable, the dedicated filter
                            will not be available anymore in the relevant listings. You can always
                            set it back to searchable in the future if the need arises.
                          </p>
                        )}
                        onClose={() => setSearchableInfoModalOpen(false)}
                      />

                      <ModalSimple
                        open={reportableInfoModalOpen}
                        heading={(
                          <React.Fragment>
                            <i className={`discreet mr-2 ${ICON.WARNING}`} />
                            Did you know?
                          </React.Fragment>
                        )}
                        body={(
                          <>
                            <p>
                              By setting this field as reportable you grant permission to the system
                              to include it in the contractor invoices.
                            </p>
                            <p>
                              If this custom fields contains sensitive information that should not
                              be disclosed to your providers, you must not activate the reportable
                              option.
                            </p>
                          </>
                        )}
                        onClose={() => setReportableInfoModalOpen(false)}
                      />
                    </div>
                  );
                }}
              </FieldArray>
            </Card.Body>
          </Card>
          <ButtonsContainer
            className="mt-5 text-right"
            primaryButtons={[
              <TDButton
                disabled={submitting}
                label="Save template"
                type="submit"
                variant={BS_STYLE.PRIMARY}
              />,
            ]}
            secondaryButtons={[
              <TDButton
                className="mb-4 mb-sm-0"
                label="Cancel"
                onClick={() => history.push(
                  orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.CUSTOM_FIELDS),
                )}
                variant={BS_STYLE.DEFAULT}
              />,
              <TDButton
                label="Preview"
                onClick={showPreviewModal}
                variant={BS_STYLE.DEFAULT}
              />,
            ]}
          />
        </form>
      )}
    />
  );
};

TemplateBuilderForm.propTypes = {
  templateType: PropTypes.number.isRequired,
  history: routerHistorySpec.isRequired,
  userCard: userCardSpec.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  match: routerMatchSpec.isRequired,
};

TemplateBuilderForm.defaultProps = {
  initialValues: {},
};

const mapStateToProps = state => ({
  userCard: selectActiveUserCard(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TemplateBuilderFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateBuilderForm);

export default withRouter(TemplateBuilderFormConnected);
