import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { contactsImportUrl } from 'contacts/urls';
import { CONTACTS_IMPORT_STEP } from 'contacts/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchContactsPreviewInfoDS } from 'contacts/assets/js/data-services/view';
import ContactsImportPreviewSkeleton from 'contacts/assets/js/components/skeletons/ContactsImportPreviewSkeleton.jsx';
import ContactsImportAnalysisTable from 'contacts/assets/js/components/ContactsImportAnalysisTable.jsx';
import {
  fetchCustomFieldTemplatesDS,
  getCustomFieldTemplates,
} from 'interviews/assets/js/ducks/customFields';
import { customFieldSpec } from 'interviews/assets/js/lib/objectSpecs';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';

class ContactsImportPreview extends React.Component {
  static GetComponentName() {
    return 'ContactsImportPreview';
  }

  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
    };

    this.handleToggleTable = this.handleToggleTable.bind(this);
  }

  handleToggleTable() {
    this.setState(state => ({ showTable: !state.showTable }));
  }

  render() {
    const {
      history, match: { params: { orgAlias } }, location, extras, customFieldTemplates,
    } = this.props;
    const allCustomFields = customFieldTemplates.map(template => template.questions).flat();
    const { showTable } = this.state;
    const {
      validContacts, invalidContacts, selectedHeaders: headers,
    } = extras;
    const toggleEl = (
      <span className="imitate-link" onClick={this.handleToggleTable}>
        { `${showTable ? 'Hide' : 'Show'} status table` }
      </span>
    );
    const uploadedFileHandle = queryString.parse(location.search).id;
    return (
      <div className="contacts-import--preview">
        <Card className="mb-4">
          <Card.Body>
            <h3 className="mb-4 mt-0">Preview</h3>
            { validContacts.length > 0 && (
              <div>
                { 'We analysed your CSV file and we\'re ready to import'}
                {' '}
                <span className="font-weight-bolder">{ validContacts.length }</span>
                {' '}
                contacts.
                { invalidContacts.length > 0 && (
                  <>
                    {' '}
                    Sadly,
                    {' '}
                    <span className="font-weight-bolder">{ invalidContacts.length }</span>
                    {' '}
                    cannot be imported because they are invalid.
                  </>
                )}
                {' '}
                {toggleEl}
              </div>
            )}
            { validContacts.length === 0 && (
              <div>
                We analysed your CSV file and sadly,
                we cannot import any of your contacts because they are invalid or already exist.
                {' '}
                {toggleEl}
              </div>
            )}
          </Card.Body>
        </Card>
        { showTable && (
          <ContactsImportAnalysisTable
            contacts={invalidContacts}
            headers={headers}
            customFields={allCustomFields}
          />
        )}
        <div className="row mt-5 ">
          <div className="col-12 text-right">
            <TDButton
              variant={BS_STYLE.DEFAULT}
              label="Back"
              onClick={() => {
                history.push({
                  pathname: contactsImportUrl(orgAlias, CONTACTS_IMPORT_STEP.MAPPING),
                  search: `?id=${uploadedFileHandle}`,
                });
              }}
            />
            <TDButton
              onClick={() => {
                history.push({
                  pathname: contactsImportUrl(orgAlias, CONTACTS_IMPORT_STEP.RESULTS),
                  search: location.search,
                });
              }}
              variant={BS_STYLE.PRIMARY}
              disabled={validContacts.length === 0}
              label="Import contacts"
            />
          </div>
        </div>
      </div>
    );
  }
}

ContactsImportPreview.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  location: PropTypes.object.isRequired,
  extras: PropTypes.object.isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldSpec),
};

ContactsImportPreview.defaultProps = {
  customFieldTemplates: [],
};

const TdApiConnectedPreview = withTDApiConnected({
  fetchData: ({ dispatch, params, authedAxios, componentName, querystring, url }) => {
    const { orgAlias } = params;
    return Promise.all([
      dispatch(
        fetchContactsPreviewInfoDS({
          orgAlias, authedAxios, componentName, querystring,
        }),
      ),
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias, entityType: FIELD_ENTITY_TYPE.USER, authedAxios, url,
      })),
    ]);
  },
  duck: 'view',
  storeKey: ContactsImportPreview.GetComponentName(),
  skeletonComponent: ContactsImportPreviewSkeleton,
})(ContactsImportPreview);

const mapStateToProps = state => ({
  customFieldTemplates: getCustomFieldTemplates(state),
});

const ContactsImportPreviewConnected = connect(
  mapStateToProps,
)(TdApiConnectedPreview);

export default withRouter(ContactsImportPreviewConnected);
