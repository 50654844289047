import React from 'react';

const colors = [
  { variableName: '$td-blue', hexCode: '#52C2CC' },
  { variableName: '$graphite-dark', hexCode: '#739DA3' },
  { variableName: '$graphite-light', hexCode: '#CFDFE1' },
  { variableName: '$black', hexCode: '#2B304F' },
  { variableName: '$greyish-brown', hexCode: '#464A67' },
  { variableName: '$warm-gray', hexCode: '#868998' },
  { variableName: '$pale-gray', hexCode: '#EDF2F2' },
  { variableName: '$pale-gray-two', hexCode: '#F7F9F9' },
  { variableName: '$tomato-red', hexCode: '#FF595A' },
  { variableName: '$medium-green', hexCode: '#52CC83' },
  { variableName: '$pending', hexCode: ' #FAB152' },
  { variableName: '$lightblue-two', hexCode: '#7DBDF9' },
];
const darkColors = ['$black', '$black-two', '$gray', '$greyish-brown', '$nice-blue', '$td-blue'];

function ColorsSection() {
  return (
    <section id="colors">
      <h2>Colors</h2>
      <div className="row">
        {colors.map(color => (
          <div key={color.hexCode} className="col-4 col-md-3 d-flex justify-content-center align-items-center flex-column my-4">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '80px', width: '80px', border: '1px solid gray', backgroundColor: color.hexCode, color: darkColors.includes(color.variableName) ? 'white' : 'black' }}
            />
            <p className="text-center mb-0">{color.variableName}</p>
            <p className="text-center text-secondary" style={{ textTransform: 'uppercase' }}>{`(${color.hexCode})`}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ColorsSection;
