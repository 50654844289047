import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const ConfirmedLabel = () => (
  <>
    Confirmed
    <ElementWithPopOver
      className="d-inline-block ml-2"
      popOverContent={(
        `Your worksheet, expense, or proforma invoice has been confirmed by a manager. If they have
        requested an amendment, please amend and submit again for review.`
      )}
      popOverTitle="Confirmed"
    />
  </>
);

export default ConfirmedLabel;
