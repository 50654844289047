import React from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  CLASSIFICATION_SUBMISSION_RISK_DESCRIPTIONS, CLASSIFICATION_SUBMISSION_RISK_LABELS,
} from 'accounts/assets/js/constants';
import { classificationQuestionnaireApiUrl, classificationQuestionnairesUrl } from 'accounts/urls';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';

const ClassificationQuestionaireIndividualView = () => {
  const params = useParams();
  const { id, orgAlias } = params;
  const { hasLoaded, item } = fetchDataHook({
    componentName: ClassificationQuestionaireIndividualView.GetComponentName(params),
    url: classificationQuestionnaireApiUrl(orgAlias, id),
  });

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          {
            title: 'Classification Questionnaire',
            url: classificationQuestionnairesUrl(orgAlias),
          },
          { title: item?.fullName || id },
        ]}
      />
      <div className="page page--classification-questionnaire">
        <div className="container">
          {hasLoaded && (
            <Card className="desktop-view">
              <table>
                <tr>
                  <td className="p-5 font-weight-bold">
                    <span>{CLASSIFICATION_SUBMISSION_RISK_DESCRIPTIONS[item.risk]}</span>
                    <span className="float-right border-right">Risk</span>
                  </td>
                  <td className={`${item.risk} p-5`}>
                    {CLASSIFICATION_SUBMISSION_RISK_LABELS[item.risk]}
                  </td>
                </tr>
                <tr className="text-uppercase font-weight-bold">
                  <td className="p-5 border-right">Question</td>
                  <td className="p-5">Answer</td>
                </tr>
                {item.answers.map(({ choice, question }, answerIndex) => (
                  <tr key={answerIndex}>
                    <td className="p-5 border-right">{`${answerIndex + 1}. ${question}`}</td>
                    <td className="p-5">{ choice}</td>
                  </tr>
                ))}
              </table>
            </Card>
          )}
          {!hasLoaded && <SettingsPageSkeleton />}
        </div>
      </div>
    </>
  );
};

ClassificationQuestionaireIndividualView.GetComponentName = () => (
  'ClassificationQuestionaireIndividualView'
);

export default ClassificationQuestionaireIndividualView;
