import React from 'react';
import PropTypes from 'prop-types';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';

import { sendSignableEnvelopeDS } from 'documents/assets/js/data-services/view';


const AORContractSign = ({ item: { signing_embed } }) => (
  <>
    {signing_embed && (
      <div
        style={{ minHeight: '850px', height: '70vh' }}
        className="signable-iframe-container"
        dangerouslySetInnerHTML={{ __html: signing_embed }}
      />
    )}

    {!signing_embed && '-'}
  </>
);

AORContractSign.propTypes = {
  item: PropTypes.object,
};

AORContractSign.defaultProps = {
  item: null,
};

AORContractSign.GetComponentName = () => 'AORContractSign';

const AORContractSignConnected = withTDApiConnected({
  fetchData: ({ dispatch, params, authedAxios, componentName, url }) => {
    const { orgAlias } = params;

    return Promise.all([
      dispatch(sendSignableEnvelopeDS({
        orgAlias, authedAxios, componentName, url,
      })),
    ]);
  },
  duck: 'view',
  storeKey: AORContractSign.GetComponentName(),
  skeletonComponent: ListSkeleton,
})(AORContractSign);

export default AORContractSignConnected;

