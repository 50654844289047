import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { pieChartConfig } from 'projects/assets/js/lib/pieChart';

import {
  DATE_DURATION,
  DATE_DURATION_API_KEY,
} from 'core/assets/js/constants';
import ChartPie from 'core/assets/js/components/ChartPie.jsx';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import PieChartInfoTable from 'projects/assets/js/components/widgets/PieChartInfoTable.jsx';
import DateDurationDropdown from 'core/assets/js/components/DateDurationDropdown.jsx';

const ProjectExpensesDistributionWidget = (props) => {
  const [selectedDateDuration, setDateDuration] = useState(DATE_DURATION.CURRENT_MONTH);
  const selectedAnalytics = `${DATE_DURATION_API_KEY[selectedDateDuration]}Analytics`;
  // eslint-disable-next-line react/destructuring-assignment
  const { analytics } = props[selectedAnalytics];
  const { project } = props;
  const currencySymbol = project.currency_symbol;
  const pieChartConfigData = pieChartConfig({
    type: SERVICE_ORDER_TYPE.EXPENSE,
    analytics,
    currencySymbol,
  });
  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center">
          <span
            className="d-inline-block m-0 p-0"
            data-testid="project-expenses-distribution-widget-header"
          >
            Expenses
          </span>
          <DateDurationDropdown
            data-testid="project-expenses-distribution-widget-date-duration-dropdown"
            selectedDateDuration={selectedDateDuration}
            onDateDurationChanged={date => setDateDuration(date)}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <div className="pie-chart-container row align-items-center justify-content-around">
          <div className="col-12 col-sm-6 justify-content-center">
            <ChartPie config={pieChartConfigData} />
          </div>
          <div className="col-12 col-sm-6">
            <PieChartInfoTable
              currencySymbol={currencySymbol}
              analytics={analytics}
              type={SERVICE_ORDER_TYPE.EXPENSE}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

ProjectExpensesDistributionWidget.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectExpensesDistributionWidget;
