import React from 'react';
import { Dropdown } from 'react-bootstrap';

import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import TDDropdown from 'core/assets/js/components/TDDropdown.jsx';
import { ICON, BS_SIZE, BS_STYLE } from 'core/assets/js/constants';

function ButtonsSection() {
  return (
    <section id="buttons">
      <h2>Buttons</h2>

      <h3>Sizes</h3>
      <div className="my-4 row">
        <div className="col-12">
          <TDButton
            bsSize={BS_SIZE.LARGE}
            label="Large"
          />

          <TDButton
            label="Default"
          />

          <TDButton
            bsSize={BS_SIZE.SMALL}
            label="Small"
          />

          <TDButton
            bsSize={BS_SIZE.XSMALL}
            label="X-Small"
          />
        </div>
      </div>

      <h3>Styles</h3>

      <div className="mt-4 row">
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.DEFAULT}
            label="Default"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.DEFAULT}
            label="Disabled"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.DEFAULT}
            label="Disabled (MSG)"
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>

      <div className="mt-4 row">
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            label="Primary"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.PRIMARY}
            label="Disabled"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            label="Disabled (MSG)"
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>

      <div className="mt-4 row">
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.SUCCESS}
            label="Approve"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.SUCCESS}
            label="Approve"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.SUCCESS}
            label="Approve (MSG)"
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>

      <div className="mt-4 row">
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.DANGER}
            label="Danger"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.DANGER}
            label="Disabled"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.DANGER}
            label="Disabled (MSG)"
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>

      <div className="mt-4 row">
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.WARNING}
            label="Warning"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.WARNING}
            label="Disabled"
          />
        </div>
        <div className="col-4 text-center">
          <TDButton
            variant={BS_STYLE.WARNING}
            label="Disabled (MSG)"
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>

      <div className="mt-4 row">
        <div className="col-12 text-center">
          <TDButton
            variant={BS_STYLE.LINK}
            btnIcon={ICON.DOWNLOAD}
            tooltipMsg="Action name"
          />
          <span className="discreet">(Icon button with tooltip - Hover to see).</span>
        </div>
      </div>


      <br />
      <h3>With Icon</h3>
      <div className="mt-4 row">
        <div className="col-6 col-md-3 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.DEFAULT}
            label="Default"
            btnIcon={ICON.ADD}
          />
        </div>
        <div className="col-6 col-md-3 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            label="Primary"
            btnIcon={ICON.PRINT}
          />
        </div>
        <div className="col-6 col-md-3 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.SUCCESS}
            label="Approve"
            btnIcon={ICON.CHECKMARK}
          />
        </div>
        <div className="col-6 col-md-3 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.DANGER}
            label="Danger"
            btnIcon={ICON.CROSS}
          />
        </div>
        <div className="col-6 col-md-3 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.WARNING}
            label="Warning"
            btnIcon={ICON.CROSS}
          />
        </div>
      </div>
      <br />
      <h3>Rounded buttons</h3>
      <div className="row">
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            rounded
            label="Without Icon"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.PRIMARY}
            rounded
            label="Without Icon"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            label="Without Icon (MSG)"
            rounded
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            btnIcon={ICON.DOWNLOAD}
            rounded
            label="Download"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.PRIMARY}
            btnIcon={ICON.DOWNLOAD}
            rounded
            label="Download"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            btnIcon={ICON.DOWNLOAD}
            label="Download (MSG)"
            rounded
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.SUCCESS}
            btnIcon={ICON.CHECKMARK}
            rounded
            label="Approve"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.SUCCESS}
            btnIcon={ICON.CHECKMARK}
            rounded
            label="Approve"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.SUCCESS}
            btnIcon={ICON.CHECKMARK}
            label="Approve (MSG)"
            rounded
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.DANGER}
            btnIcon={ICON.CROSS}
            rounded
            label="Reject"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.DANGER}
            btnIcon={ICON.CROSS}
            rounded
            label="Reject"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.DANGER}
            btnIcon={ICON.CROSS}
            rounded
            label="Reject  (MSG)"
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.WARNING}
            btnIcon={ICON.CROSS}
            rounded
            label="Cancel"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            disabled
            variant={BS_STYLE.WARNING}
            btnIcon={ICON.CROSS}
            rounded
            label="Cancel"
          />
        </div>
        <div className="col-4 mt-3 text-center">
          <TDButton
            variant={BS_STYLE.WARNING}
            btnIcon={ICON.CROSS}
            rounded
            label="CANCEL (MSG)"
            disabledMsg="You don't have permission to do this action"
          />
        </div>
      </div>
      <br />

      <div className="row">
        <div className="col-4">
          <h3>Button with overflow menu</h3>
          <TDDropdown
            id="demo-dropdown-1"
            variant={BS_STYLE.LINK}
            noCaret
            alignRight
            toggle={(
              <TDButton
                variant={BS_STYLE.PRIMARY}
                label="Set of actions"
                btnIcon={<i className={ICON.ELLIPSIS} />}
              />
            )}
          >
            <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
          </TDDropdown>

          <TDDropdown
            id="demo-dropdown-2"
            className="finance-report mr-4 d-none d-md-flex my-5"
            variant={BS_STYLE.LINK}
            noCaret
            alignRight
            toggle={(
              <TDButton
                variant={BS_STYLE.DEFAULT}
                label="Actions"
                btnIcon={<i className={ICON.ELLIPSIS} />}
              />
            )}
          >
            <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
          </TDDropdown>
        </div>

        <div className="col-4">
          <h3>Drop Button:</h3>
          <TDDropButton>
            <Dropdown.Item eventKey="1">Request Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Request Action 2</Dropdown.Item>
          </TDDropButton>
        </div>

        <div className="col-4">
          <h3>Drop Button with chevron:</h3>
          <TDDropChevronButton>
            <Dropdown.Item eventKey="1">Request Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Request Action 2</Dropdown.Item>
          </TDDropChevronButton>
        </div>

        <div className="col-4">
          <h3>Split Button:</h3>
          <SplitButton
            actions={[
              { label: 'Split Button', onClick: () => null },
              { label: 'Action', onClick: () => null },
              { label: 'Another action', onClick: () => null },
              { label: 'Something else', onClick: () => null },
            ]}
          />
          <br />
          <SplitButton
            actions={[
              { label: 'Split Button', onClick: () => null },
              { label: 'Action', onClick: () => null },
              { label: 'Another action', onClick: () => null },
              { label: 'Something else', onClick: () => null },
            ]}
            className="mt-5"
            primaryButtonVariant={BS_STYLE.DEFAULT}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>

  );
}

export default ButtonsSection;
