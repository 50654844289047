import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import MembersPreview from 'core/assets/js/components/MembersPreview.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import { getEffectiveTaskInfo } from 'projects/assets/js/lib/helpers';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { orgUserProfileUrl } from 'people/urls';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectViewTaskUrl } from 'projects/urls';
import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { TASK_ASSIGNMENT_STATUS, TASK_STATUS } from 'projects/assets/js/constants';
import { CURRENCY_SYMBOL, ICON, IMG_SIZE, TASK_TABS, USER_TYPE } from 'core/assets/js/constants';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TagList from 'core/assets/js/components/TagList.jsx';
// import TaskProgressBar from 'projects/assets/js/components/TaskProgressBar.jsx';

const DISABLED_ASSIGNMENT_STATUSES = [
  TASK_ASSIGNMENT_STATUS.DECLINED,
  TASK_ASSIGNMENT_STATUS.LEFT,
  TASK_ASSIGNMENT_STATUS.REMOVED,
];

const DISABLED_TASK_STATUSES = [
  TASK_STATUS.COMPLETED,
  TASK_STATUS.REJECTED,
  TASK_STATUS.STOPPED,
];

const ACTIVE_TASK_STATUSES = [
  TASK_STATUS.DRAFT,
  TASK_STATUS.INPROGRESS,
  TASK_STATUS.PENDING,
];

const MyTaskWidgetCardItem = ({
  activeOrg, activeUserCard, className, isManager, item, orgAlias,
}) => {
  const {
    assignments, attachments, deadline, id, info, ownerId, ownerName, projectId, tags,
  } = item;
  const classNames = ['task-card'];
  const completedItemsCount = info?.completedItemsCount || 0;
  const profileUrl = orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, ownerId);
  const totalItemsCount = info && info.totalItemsCount ? info.totalItemsCount : 0;

  if (className) {
    classNames.push(className);
  }

  const assignment = assignments
    ? assignments.find(a => a.user.id === activeUserCard.user.id)
    : null;

  const isAssignedToCurrentUser = (
    !!assignment && assignment.status === TASK_ASSIGNMENT_STATUS.ACCEPTED
  );

  const acceptedAssignments = assignments
    ? assignments.filter(a => a.status === TASK_ASSIGNMENT_STATUS.ACCEPTED)
    : [];
  // We have multiple assignments per task, which means that the task status should be different
  // for users that are accepted assignees than the ones that are eg. rejected
  const {
    label: effectiveLabel, /* progress: taskProgress, */status, statusClass: taskStatusClass,
  } = getEffectiveTaskInfo(activeOrg, item, { assignment });

  const taskCardDisabled = item.deleted
    || (assignment && DISABLED_ASSIGNMENT_STATUSES.includes(assignment.status))
    || DISABLED_TASK_STATUSES.includes(status);

  if (taskCardDisabled) {
    classNames.push('task-card--disabled');
  }

  let effectiveTaskStatusLabel = effectiveLabel;
  if (isAssignedToCurrentUser && deadline && ACTIVE_TASK_STATUSES.includes(status)) {
    const daysRemaining = moment(deadline).diff(moment(), 'days');

    if (daysRemaining > 0) {
      effectiveTaskStatusLabel = `${daysRemaining} days remaining`;
    } else if (daysRemaining === 0) {
      effectiveTaskStatusLabel = 'Due today';
    }
  }

  const acceptedAssignmentsLength = acceptedAssignments.length;
  const showInlineAssignment = !isEmpty(acceptedAssignments) && acceptedAssignmentsLength === 1;
  const inlineAssignment = showInlineAssignment && acceptedAssignments[0];

  return (
    <div className="tasks-widget-list__item">
      <div className="row">
        <div className="task-card__basic-info d-flex flex-column">
          <div className="col-12 col-sm-8">
            <h3 className="mt-0 mb-4">
              <Link
                to={projectViewTaskUrl(orgAlias, projectId, item.id, TASK_TABS.DASHBOARD)}
                className="text-dark"
              >
                {item.title}
              </Link>
            </h3>
          </div>
        </div>

        <div className="col-12 col-sm-4 d-none d-sm-block">
          <div className="task-card__assignments d-flex justify-content-end align-items-center">
            { /* Show rate inline if only one assignment found */ }
            {showInlineAssignment && (
              <span className="pt-3 mt-n3 profile-pic-wrapper">
                <Link
                  to={
                    orgUserProfileUrl(
                      orgAlias, USER_TYPE.PROVIDER, inlineAssignment.user.id,
                    )
                  }
                >
                  <ProfilePic
                    alt={inlineAssignment.user.profile.name}
                    size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
                    url={inlineAssignment.user.profile.avatar}
                  />
                </Link>

                <StatusTag
                  hideTitle
                  className="ml-2 rate"
                  statusClass="default"
                  customAttrs={{
                    'data-testid': 'assignment-rate',
                  }}
                  label={getDisplayRate(
                    inlineAssignment.rate,
                    inlineAssignment.rate_unit,
                    { currency: CURRENCY_SYMBOL[inlineAssignment.currency] },
                  )}
                  hideDot
                />
              </span>
            )}

            { /* Show rate in tooltip if assignments are more than one */ }
            {!isEmpty(acceptedAssignments) && acceptedAssignmentsLength > 1 && (
              <MembersPreview
                total={acceptedAssignmentsLength}
                url={projectViewTaskUrl(orgAlias, projectId, id)}
                users={acceptedAssignments.slice(0, 5).map(uc => uc.user)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          { ownerName && (
            <>
              <span className="discreet project-list__item--label">
                Manager
              </span>
              <span>
                <Link className="text-dark" to={profileUrl}>
                  {ownerName}
                </Link>
              </span>
            </>
          )}

          { deadline && (
            <span className="project-list__item__deadline ml-4">
              <span className="discreet project-list__item--label">
                Deadline
              </span>
              <span>{ formatDate(deadline, 'DD MMM, YYYY') }</span>
            </span>
          )}
        </div>
      </div>

      <hr />

      <div className="row align-items-center">
        <div className="col-12 col-sm-6 col-lg-6 mb-3 mb-md-0">
          {!isEmpty(tags) && (
            <TagList tags={tags} />
          )}

          {isManager && totalItemsCount > 0 && (
            <div className="task-card__completion mr-4">
              <span className={`${ICON.SUCCESS} mr-2`} />
              {`${completedItemsCount}/${totalItemsCount}`}
            </div>
          )}

          {!isEmpty(attachments) && (
            <div className="task-card__attachments">
              <span className={`${ICON.ATTACHMENT} mr-2 discreet`} />
              {attachments.length}
            </div>
          )}
        </div>

        <div className="col-12 col-sm-6 col-lg-6 text-right">
          <StatusTag
            label={effectiveTaskStatusLabel}
            statusClass={taskStatusClass}
            title={effectiveTaskStatusLabel}
          />
          {/* <div className="project-list__item--progress pt-1">
            <StatusTag
              statusClass={taskStatusClass}
              label={effectiveTaskStatusLabel}
              title={effectiveTaskStatusLabel}
            />
            <div className="project-list__item--progress__percentage">
              { taskProgress > 0 && `${taskProgress}%` }
            </div>
          </div>
          <TaskProgressBar
            id={id}
            progress={taskProgress}
            statusClass={taskStatusClass}
            className="task-progress-bar--thick mt-2"
          /> */}
        </div>
      </div>
    </div>
  );
};

MyTaskWidgetCardItem.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec,
  className: PropTypes.string,
  isManager: PropTypes.bool,
  item: projectTaskSpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

MyTaskWidgetCardItem.defaultProps = {
  activeUserCard: {},
  className: null,
  isManager: false,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
  isManager: getHasOrgAccess(state)({ requireManager: true }),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const MyTaskWidgetCardItemConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyTaskWidgetCardItem);

export default MyTaskWidgetCardItemConnected;
