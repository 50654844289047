import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Table from 'core/assets/js/components/Table.jsx';
import { truncate } from 'core/assets/js/lib/utils';

const formatValidationErrors = (errorList) => {
  const errors = (Array.isArray(errorList) ? errorList : [errorList]).filter(e => !!e);
  const hasErrors = !isEmpty(errors);

  return (
    <>
      {hasErrors && errors.map(error => (
        <span className="text-danger">
          {error}
          <br />
        </span>
      ))}
      {!hasErrors && (
        <span className="text-success">Valid</span>
      )}
    </>
  );
};

/**
 * The table we show in the third step of the uploading worksheets form
 * @param {Object} params
 * @param {Array} params.worksheets
 * @param {Array<String>} params.headers
 * field question as the table header
 * @returns {JSX.Element|null}
 * @constructor
 */
function ProjectWorksheetImportAnalysisTable({ worksheets, headers, emptyText }) {
  if (!Array.isArray(headers) || (!headers.length)) {
    return null;
  }

  const maxHeaderWords = 10;
  const tableColumns = [{
    key: 'validationErrors',
    label: 'Status',
    width: '180px',
    isError: true,
    dataFormat: formatValidationErrors,
  },
  ...headers.map(header => (
    { key: header, label: truncate(header, maxHeaderWords), width: '125px' }
  ))];

  return (
    <Table
      containerClass="mt-5"
      cols={tableColumns}
      items={worksheets}
      emptyText={emptyText}
    />
  );
}

ProjectWorksheetImportAnalysisTable.propTypes = {
  worksheets: PropTypes.array,
  headers: PropTypes.array,
  emptyText: PropTypes.string,
};
ProjectWorksheetImportAnalysisTable.defaultProps = {
  worksheets: [],
  headers: [],
  emptyText: 'No worksheets found',
};

export default ProjectWorksheetImportAnalysisTable;
