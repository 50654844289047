import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { projectInvitationsUrl } from 'projects/urls';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { STATUS } from 'invitations/assets/js/constants';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';

const ManageInvitationButton = ({
  label,
  invitation,
  history,
  match: { params: { orgAlias } },
  task,
}) => {
  let expand = null;
  if (task?.id) {
    expand = task.id;
  } else if (invitation) {
    expand = invitation?.id || invitation?.taskId;
  }
  return (
    <TDButton
      onClick={() => {
        history.push(projectInvitationsUrl(
          orgAlias, expand ? { expand, status: STATUS.PENDING } : {},
        ));
      }}
      variant={BS_STYLE.PRIMARY}
      label={label}
    />
  );
};

ManageInvitationButton.propTypes = {
  history: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  match: routerMatchContentsSpec.isRequired,
  task: projectTaskSpec,
};

ManageInvitationButton.defaultProps = {
  task: null,
};

export default withRouter(ManageInvitationButton);
