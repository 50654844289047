import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { PROJECT_TABS } from 'core/assets/js/constants';
import ProjectTitleSkeleton from 'projects/assets/js/components/skeletons/ProjectTitleSkeleton.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { fetchProjectActiveDS } from 'projects/assets/js/data-services/view';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectViewUrl, projectListUrl } from 'projects/urls';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { getViewState } from 'core/assets/js/ducks/view';

const COMPONENT_NAME = 'ProjectWorksheetImportHeader';

const ProjectWorksheetImportHeader = ({ match: { params: { orgAlias, id } }, project }) => {
  const breadcrumbs = [
    {
      title: 'Projects',
      url: projectListUrl(orgAlias),
    },
    {
      title: !isEmpty(project) ? project.title : <ProjectTitleSkeleton />,
      url: projectViewUrl(orgAlias, id, PROJECT_TABS.WORKSHEETS),
    },
    {
      title: 'Import worksheets',
      url: null,
    },
  ];

  return (
    <ContentHeader breadcrumbs={breadcrumbs} />
  );
};

ProjectWorksheetImportHeader.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  project: projectSpec.isRequired,
};

const TdApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params: { orgAlias, id }, authedAxios, url,
  }) => dispatch(fetchProjectActiveDS({
    orgAlias, id, url, authedAxios, componentName: COMPONENT_NAME,
  })),
  duck: 'view',
  storeKey: COMPONENT_NAME,
})(ProjectWorksheetImportHeader);

const mapStateToProps = (state) => {
  const viewState = getViewState(
    state, COMPONENT_NAME,
  );
  return {
    project: viewState.item,
  };
};

const ProjectWorksheetImportHeaderConnected = connect(
  mapStateToProps,
)(TdApiConnected);
export default ProjectWorksheetImportHeaderConnected;
