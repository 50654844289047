import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import {
  financeManagersBudgetUrl,
  financePurchaseOrderListUrl,
  financeProjectsBudgetUrl,
} from 'finance/urls';
import { BUDGETS_TABS } from 'finance/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';

const BudgetsHeader = ({ tab, match: { params: { orgAlias } } }) => {
  const breadcrumbs = [
    {
      title: 'Budgets',
      url: null,
    },
  ];

  const tabSpec = [
    {
      key: BUDGETS_TABS.MANAGER_BUDGET,
      label: 'Budget Managers',
      href: financeManagersBudgetUrl(orgAlias),
    },
    {
      key: BUDGETS_TABS.PROJECT_BUDGET,
      label: 'Project Budget',
      href: financeProjectsBudgetUrl(orgAlias),
    },
    {
      key: BUDGETS_TABS.BUDGET_REQUESTS,
      label: 'Budget Requests',
      href: financePurchaseOrderListUrl(orgAlias),
    },
  ];

  return (
    <ContentHeader breadcrumbs={breadcrumbs}>
      <div className="horizontal-scroll">
        <TabBar
          activeKey={tab}
          tabSpec={tabSpec}
        />
      </div>
    </ContentHeader>
  );
};

BudgetsHeader.propTypes = {
  tab: PropTypes.string.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(BudgetsHeader);
