import React from 'react';
import Big from 'big.js';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import StatusDot from 'core/assets/js/components/StatusDot.jsx';
import { WORKSHEET_STATUS_GROUP } from 'projects/assets/js/constants';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import { PIE_CHART_USER_TYPE } from 'projects/assets/js/lib/pieChart';

const PieChartInfoTable = ({ currencySymbol, analytics, type }) => {
  const data = {
    managers: {
      approved: Big(get(analytics, `byType[${type}].byIsManager[${PIE_CHART_USER_TYPE.ANY_MANAGER}].byStatusGroup[${WORKSHEET_STATUS_GROUP.APPROVED}].amount`, 0)).toFixed(2),
      raised: Big(get(analytics, `byType[${type}].byIsManager[${PIE_CHART_USER_TYPE.ANY_MANAGER}].byStatusGroup[${WORKSHEET_STATUS_GROUP.PENDING}].amount`, 0)).toFixed(2),
    },
    providers: {
      approved: Big(get(analytics, `byType[${type}].byIsManager[${PIE_CHART_USER_TYPE.PROVIDER}].byStatusGroup[${WORKSHEET_STATUS_GROUP.APPROVED}].amount`, 0)).toFixed(2),
      raised: Big(get(analytics, `byType[${type}].byIsManager[${PIE_CHART_USER_TYPE.PROVIDER}].byStatusGroup[${WORKSHEET_STATUS_GROUP.PENDING}].amount`, 0)).toFixed(2),
    },
  };
  return (
    <div className="pie-chart-info-table" data-testid="pie-chart-info-table">
      <AllowAccessToManager>
        <div className="pie-chart-info--managers">
          <div><b>Managers</b></div>
          <div className="d-flex justify-content-between">
            <div>
              <StatusDot noFill statusClass="pie-chart-managers-approved" />
              <span>Approved</span>
            </div>
            <div>
              <NumberFormat prefix={currencySymbol} thousandSeparator decimals={2} displayType="text" value={data.managers.approved} />
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <StatusDot noFill statusClass="pie-chart-managers-raised" />
              <span>Submitted</span>
            </div>
            <div>
              <NumberFormat prefix={currencySymbol} thousandSeparator decimals={2} displayType="text" value={data.managers.raised} />
            </div>
          </div>
        </div>
      </AllowAccessToManager>
      <div className="pie-chart-info--providers">
        <AllowAccessToManager>
          <div><b>Providers</b></div>
        </AllowAccessToManager>
        <div className="d-flex justify-content-between">
          <div>
            <StatusDot noFill statusClass="pie-chart-providers-approved" />
            <span>Approved</span>
          </div>
          <div>
            <NumberFormat prefix={currencySymbol} thousandSeparator decimals={2} displayType="text" value={data.providers.approved} />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <StatusDot noFill statusClass="pie-chart-providers-raised" />
            <span>Submitted</span>
          </div>
          <div>
            <NumberFormat prefix={currencySymbol} thousandSeparator decimals={2} displayType="text" value={data.providers.raised} />
          </div>
        </div>
      </div>
    </div>
  );
};

PieChartInfoTable.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  analytics: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
};

export default PieChartInfoTable;
