import moment from 'moment';
import Big from 'big.js';

import { DATETIME_FORMAT_HUMAN_FRIENDLY } from 'core/assets/js/constants';
import { SERVICE_STATUS_CONDITION, STRIPE_SUBSCRIPTION_STATUS } from 'services/assets/js/constants';

Big.RM = 1;
Big.DP = 2;

/**
 * Returns whether a string contains any unicode characters or not assuming UTF-8
 *
 * @param {String} str - the string to check
 * @returns {Boolean} whether the string contains a unicode character
 */
export const hasUnicodeCharacters = (str) => {
  for (let i = 0; i < str.length; i += 1) {
    if (str.charCodeAt(i) > 127) {
      return true;
    }
  }
  return false;
};

/**
 * Sanitize RegExp strings to remove the escaping from characters which do not need it.
 * For example, '-' should not be escaped, if it is and we compile with the unicode
 * switch this will cause JS to throw an 'Invalid regular expression' exception.
 * @param {string} regex - regexp string to be sanitized.
 * @return {string} sanitized regexp string.
 */
export const sanitizeRegExpString = regex => regex.replace(/\\-/g, '-');

/**
 * Return if a given service status is 'ok'.
 * @param {object} status - list of service statuses, returned by /health endpoint.
 * @param {SERVICE_STATUS_SERVICE} name - service to check status of.
 * @return {boolean} true if status of service is okay, otherwise false.
 */
export const isServiceStatusOk = (status, name) => status[name] === SERVICE_STATUS_CONDITION.OK;

/**
 * Format a stripe timestamp for display.
 * @param {number} secs - time stamp in seconds ( this appears to be the default format for stripe )
 * @param {string} format - time format, defaults to DATETIME_FORMAT_HUMAN_FRIENDLY
 * @return {string|null} formatted time stamp string or NULL if timestamp falsey
 */
export const formatStripeTimestamp = (
  secs,
  format = DATETIME_FORMAT_HUMAN_FRIENDLY,
) => (secs ? moment(secs * 1000).format(format) : null);

/**
 * Get a text color depending on stripe status
 * @param {string} status - status to get color for
 * @return {string} bootstrap text color class name
 */
export const getStripeStatusColor = status => ({
  [STRIPE_SUBSCRIPTION_STATUS.ACTIVE]: 'text-success',
  [STRIPE_SUBSCRIPTION_STATUS.PAST_DUE]: 'text-danger',
  [STRIPE_SUBSCRIPTION_STATUS.UNPAID]: 'text-danger',
  [STRIPE_SUBSCRIPTION_STATUS.CANCELED]: 'text-muted',
  [STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE]: 'text-muted',
  [STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: 'text-muted',
  [STRIPE_SUBSCRIPTION_STATUS.TRIALING]: 'text-warning',
})[status] || 'text-secondary';

/**
 * Convert a stripe integer amount to a currency amount.
 * @param {number} int - the integer value to convert, essentially the fractional amount
 * premultiplied by 100.
 * @param {string} cuurency - the currency the amount is in, may affect not fractional
 * currencies like JPY.
 * @return {string} fractional value, ie '100.01'.
 */
export const convertStripeIntToCurrencyAmount = (int, currency) => {
  const nonFractionalCurrencies = [
    // TODO - check how 100JPY is passed by stripe, is it 100 or 10000
    // 'jpy',
  ];

  // handle non fractional values by simply returning the original value
  if (nonFractionalCurrencies.includes((currency || '').toLowerCase())) {
    return Big(parseInt(int || 0, 10)).toFixed(2);
  }

  // otherwise return 10001 as '100.01'
  return Big(parseInt(int || 0, 10) / 100.0).toFixed(2);
};
