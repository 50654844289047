import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { toastr } from 'react-redux-toastr';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { getListState, listUpdateItemAC } from 'core/assets/js/ducks/list';
import {
  projectSpec, projectMemberSpec, projectAllowedActionsSpec,
} from 'projects/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { fetchProjectMembersListDS } from 'projects/assets/js/data-services/list';
import { fetchReviewsDS } from 'projects/assets/js/data-services/view';
import { projectReviewsSubmitAC } from 'projects/assets/js/actions/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import ProjectMemberCard from 'projects/assets/js/components/ProjectMemberCard.jsx';
import ProjectMemberSearch from 'projects/assets/js/components/ProjectMemberSearch.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { ACCESS_CONTROL_ALLOWED_ACTIONS as ALLOWED_ACTIONS } from 'core/assets/js/constants';
import { PEOPLE_DEFAULT_ORDERING } from 'people/assets/js/constants';


class ProjectTabTeam extends React.Component {
  static FetchData({
    dispatch, params, url, querystring, componentName, authedAxios, isProjManager,
  }) {
    const _queryString = queryString.parse(querystring);

    // Use default ordering in case user haven't specified any.
    if (!_queryString.ordering) {
      _queryString.ordering = JSON.stringify(PEOPLE_DEFAULT_ORDERING);
    }

    const prerequisites = [
      dispatch(fetchProjectMembersListDS({
        url,
        params,
        querystring: queryString.stringify(_queryString),
        projectId: params.id,
        componentName,
        authedAxios,
        dispatch,
      })),
    ];

    if (isProjManager) {
      prerequisites.push(
        dispatch(fetchReviewsDS({
          orgAlias: params.orgAlias,
          id: params.id,
          url,
          authedAxios,
          componentName,
        })),
      );
    }

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'ProjectTabTeam';
  }

  constructor(props) {
    super(props);

    this.handleMemberRemoved = this.handleMemberRemoved.bind(this);
    this.handleInvitationUpdated = this.handleInvitationUpdated.bind(this);
    this.handleReviewAdded = this.handleReviewAdded.bind(this);
  }

  handleMemberRemoved(projectMember) {
    const { dispatch } = this.props;
    dispatch(
      listUpdateItemAC(projectMember.id, projectMember, ProjectTabTeam.GetComponentName()),
    );
  }

  handleInvitationUpdated(invitation) {
    const { dispatch, projectMembers } = this.props;
    const member = projectMembers.find(pm => pm.userId === invitation.userId);

    if (!member) {
      throw new Error('Project member not found');
    }

    const invitations = (member.invitations || []).map(inv => (
      inv.id === invitation.id ? invitation : inv
    ));

    dispatch(
      listUpdateItemAC(member.id, { ...member, invitations }, ProjectTabTeam.GetComponentName()),
    );
  }

  handleReviewAdded(review) {
    const { dispatch, projectMembers } = this.props;
    const member = projectMembers.find(pm => pm.userId === review.subject_id);
    member.userCard.numReviews = member.userCard.numReviews ? member.userCard.numReviews += 1 : 1;
    member.userCard.rating += (review.rating - member.userCard.rating) / member.userCard.numReviews;
    dispatch(projectReviewsSubmitAC(review));
    dispatch(listUpdateItemAC(member.id, { ...member }, ProjectTabTeam.GetComponentName()));

    return toastr.success('Well Done!', 'Your review was added successfully.');
  }

  render() {
    const {
      filtersOpen, onFiltersToggle, project, projectMembers, pagination,
      match, accessControl, rateLimits,
    } = this.props;
    const { orgAlias } = match.params;

    return (
      <React.Fragment>
        <div className="row">
          <ProjectMemberSearch
            rateLimits={rateLimits}
            onFiltersToggle={onFiltersToggle}
            filtersOpen={filtersOpen}
          />
        </div>

        <TDApiConnected
          duck="list"
          component={this.constructor}
          blockingLoading
          skeletonComponent={PeopleListSkeleton}
        >
          { !filtersOpen && (
            <TDList
              items={projectMembers}
              pagination={pagination}
              cardItem={{
                component: ProjectMemberCard,
                props: {
                  orgAlias,
                  project,
                  projectAllowedActions: accessControl[ALLOWED_ACTIONS.PROJECT] || {},
                  onMemberRemoved: this.handleMemberRemoved,
                  onInvitationResent: this.handleInvitationUpdated,
                  onInvitationCancelled: this.handleInvitationUpdated,
                  onReviewAdded: this.handleReviewAdded,
                },
              }}
              emptyListMessage="No members found."
            />
          )}
        </TDApiConnected>
      </React.Fragment>
    );
  }
}

ProjectTabTeam.propTypes = {
  accessControl: PropTypes.shape({ project: projectAllowedActionsSpec }),
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  project: projectSpec.isRequired,
  projectMembers: PropTypes.arrayOf(projectMemberSpec).isRequired,
  rateLimits: PropTypes.object,
};

ProjectTabTeam.defaultProps = {
  accessControl: {},
  rateLimits: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ProjectTabTeam.GetComponentName());

  return {
    projectMembers: listState.items,
    accessControl: listState.extras.accessControl,
    rateLimits: listState.extras.rateLimits,
    pagination: listState.pagination,
  };
};

const ProjectTeamViewConnected = connect(
  mapStateToProps,
)(ProjectTabTeam);

export default withRouter(withFilters(ProjectTeamViewConnected));
