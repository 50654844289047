import { omit } from 'lodash';
import { SubmissionError } from 'redux-form';
import { httpErrorAC } from 'core/assets/js/ducks/errors';

export const handleUnexpectedError = (dispatch, error) => dispatch(httpErrorAC([error]));

export const handleValidationError = (error) => {
  throw new SubmissionError(error);
};

export const handleServerError = (dispatch, error) => dispatch(httpErrorAC([error]));

export const handleClientError = (dispatch, error) => {
  if (error._meta?.isValidation) {
    return handleValidationError(error);
  }
  return dispatch(httpErrorAC([error]));
};

export const handleResponseError = (dispatch, response) => {
  if (response.status === 404) {
    return dispatch(httpErrorAC([response.data]));
  }
  if (response.status >= 400 && response.status < 500) {
    return handleClientError(dispatch, response.data);
  }
  if (response.status >= 500 && response.status < 600) {
    return handleServerError(dispatch, response.data);
  }
  return handleUnexpectedError(response.data);
};

export const handleError = (dispatch, error) => {
  if (!dispatch || !error) {
    throw new Error('Undefined dispatch or error');
  }
  if (error.response) {
    return handleResponseError(dispatch, error.response);
  }
  return handleUnexpectedError(dispatch, error);
};

export const serializedErrorToString = serializedError => {
  let errorMessage = serializedError._error;
  const extraErrors = omit(serializedError || {}, '_error', '_meta');
  const extraErrorsKeys = Object.keys(extraErrors);
  if (extraErrorsKeys.length > 0) {
    errorMessage = extraErrorsKeys
      .map(key => {
        let nestedErrorMessage;
        if (typeof extraErrors[key] === 'object') {
          const _errors = omit(extraErrors[key], '_error', '_meta');
          nestedErrorMessage = Object.values(_errors)
            .map(_err => Object.values(omit(_err, '_error')))
            .join(', ');
        } else {
          nestedErrorMessage = extraErrors[key];
        }
        return `${key}: ${nestedErrorMessage}`;
      })
      .join('; ');
  }
  return errorMessage;
};
