import { REQ_TYPE } from 'core/assets/js/constants';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  orgInviteManagersApiUrl, orgInviteProvidersApiUrl,
} from 'organizations/urls';
import {
  cancelInvitationUrl, resendInvitationUrl, userGroupAddMembersApiUrl, userGroupRemoveMemberApiUrl,
} from 'people/urls';
import { toggleFavoriteApiURL } from 'search/urls';
import { listUpdateItemAC } from 'core/assets/js/ducks/list';

export const putResendInvitationDS = ({ invitationId, orgAlias }) =>
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: resendInvitationUrl({ invitationId, orgAlias }),
  });

export const putCancelInvitationDS = ({ invitationId, orgAlias, values }) =>
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: cancelInvitationUrl({ invitationId, orgAlias }),
    values,
  });

export const userGroupRemoveMemberDS = ({ orgAlias, userGroupId, userId, values }) =>
  pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: userGroupRemoveMemberApiUrl(orgAlias, userGroupId, userId),
    values,
  });

export const userGroupAddMembersDS = ({ orgAlias, userGroupId, values }) =>
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: userGroupAddMembersApiUrl({ orgAlias, userGroupId }),
    values,
  });

export const inviteProviderDS = ({ orgAlias, values }) =>
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: orgInviteProvidersApiUrl(orgAlias),
    values,
  });

export const inviteManagerDS = ({ orgAlias, values }) =>
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: orgInviteManagersApiUrl(orgAlias),
    values,
  });

export const toggleFavoriteDS = ({ item, orgAlias, parentComponentName, values }) =>
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: toggleFavoriteApiURL({ orgAlias }),
    pushDataAC:
    (responseData) => listUpdateItemAC(item.id,
      { ...item, isFavorite: responseData.isFavorite }, parentComponentName),
    values,
  });
