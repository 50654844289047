import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { SOCIAL_PROFILES, SOCIAL_PROFILES_INFO } from 'accounts/assets/js/constants';

const ProfileSocialForm = ({ handleSubmit, pristine, submitting }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.LINKEDIN}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.LINKEDIN].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.LINKEDIN].profileUrlPrefix}
        />
      </div>
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.FACEBOOK}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.FACEBOOK].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.FACEBOOK].profileUrlPrefix}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.GITHUB}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.GITHUB].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.GITHUB].profileUrlPrefix}
        />
      </div>
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.TWITTER}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.TWITTER].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.TWITTER].profileUrlPrefix}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.DRIBBBLE}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.DRIBBBLE].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.DRIBBBLE].profileUrlPrefix}
        />
      </div>
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.BEHANCE}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.BEHANCE].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.BEHANCE].profileUrlPrefix}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.WEBSITE}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.WEBSITE].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.WEBSITE].profileUrlPrefix}
        />
      </div>
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.INSTAGRAM}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.INSTAGRAM].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.INSTAGRAM].profileUrlPrefix}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.YOUTUBE}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.YOUTUBE].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.YOUTUBE].profileUrlPrefix}
        />
      </div>
      <div className="col-12 col-sm-6">
        <Field
          name={SOCIAL_PROFILES.OTHER}
          type="text"
          component={InputField}
          label={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.OTHER].label}
          prefix={SOCIAL_PROFILES_INFO[SOCIAL_PROFILES.OTHER].profileUrlPrefix}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 text-right">
        <TDButton
          className={`position-tweak ${pristine ? 'btn--pristine' : 'btn--dirty'}`}
          type="submit"
          variant={BS_STYLE.PRIMARY}
          disabled={submitting}
          label="Update"
        />
      </div>
    </div>
  </form>
);


ProfileSocialForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

ProfileSocialForm.defaultProps = {
  error: '',
};

const ProfileSocialReduxForm = reduxForm({
  form: 'profileSettingsSocialForm',
  enableReinitialize: true,
})(ProfileSocialForm);

export default ProfileSocialReduxForm;
