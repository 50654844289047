import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { fetchTasksBurnDownAnalyticsApiUrl } from 'analytics/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ChartBurnDown from 'core/assets/js/components/ChartBurnDown.jsx';
import BurnDownSkeleton from 'core/assets/js/components/Skeleton/BurnDownSkeleton.jsx';


const ProjectTasksBurnDownChart = (props) => {
  const { analytics } = props;
  return (
    <TDApiConnected
      duck="list"
      storeKey="tasksBurnDownAnalytics"
      fetchData={
        ({ dispatch, params, url, authedAxios, componentName }) => {
          const { orgAlias, id } = params;
          const tasksBurnDownApiUrl = fetchTasksBurnDownAnalyticsApiUrl(orgAlias, id, url);
          return dispatch(fetchListDS({
            url: tasksBurnDownApiUrl, componentName, authedAxios,
          }));
        }}
      blockingLoading
      skeletonComponent={BurnDownSkeleton}
    >
      <ChartBurnDown
        data={analytics}
        chartName="Tasks"
        suffix="%"
      />
    </TDApiConnected>
  );
};


ProjectTasksBurnDownChart.propTypes = {
  analytics: PropTypes.array,
};

ProjectTasksBurnDownChart.defaultProps = {
  analytics: [],
};

const mapStateToProps = state => ({
  analytics: getListState(state, 'tasksBurnDownAnalytics').items,
});

const ProjectTasksBurnDownChartConnected = connect(
  mapStateToProps,
  null,
)(ProjectTasksBurnDownChart);

export default ProjectTasksBurnDownChartConnected;
