import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { uploaderContactPath, uploaderContactAttachmentsPath } from 'core/urls';
import { customFieldSpec, customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { BS_STYLE, MAX_UPLOAD_FILES, USER_TYPE_LABEL, USER_TYPE } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { contactsUrl } from 'contacts/urls';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import GeoSuggestField from 'core/assets/js/components/FinalFormFields/GeoSuggestField.jsx';
import PhoneNumberField from 'core/assets/js/components/FinalFormFields/PhoneNumberField.jsx';
import SkillSelectField from 'core/assets/js/components/FinalFormFields/SkillSelectField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import CustomFieldsUpdater from 'interviews/assets/js/components/CustomFieldsUpdater.jsx';

const FORM_ID = 'contact-form';

const ContactForm = ({
  history,
  contactId,
  onSubmit,
  match: { params: { orgAlias } },
  userCard,
  initialValues,
  customFields,
  customFieldTemplates,
  allowTemplateSelection,
  enableFieldTemplateSync,
}) => (
  <Form
    name={FORM_ID}
    onSubmit={values => {
      if (values.phone && !isPossiblePhoneNumber(values.phone)) {
        return { phone: 'Invalid phone number ' };
      }
      return onSubmit(values);
    }}
    initialValues={initialValues}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize // important: mandatory templates will not work properly otherwise
    render={({ handleSubmit, submitting, form }) => (
      <form onSubmit={handleSubmit}>
        <div className="rounded shadow-sm p-4 bg-white">
          <div className="image-upload-row clearfix">
            <FileUploaderField
              name="avatar_metadata"
              label="Photo"
              maxFiles={1}
              path={uploaderContactPath(userCard.organization.id, initialValues.id)}
              acceptFiles={['image/*']}
              acceptAll
            />
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <TextInputField
                name="first_name"
                label="First name"
                required
              />
            </div>
            <div className="col-12 col-sm-6">
              <TextInputField
                name="last_name"
                label="Last name"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <TextInputField
                name="email"
                label="Email"
                type="email"
                required
              />
            </div>
            <div className="col-12 col-sm-6">
              <PhoneNumberField label="Phone" name="phone" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <TextInputField
                name="job_title"
                sublabel=" "
                label="Job title"
              />
            </div>
            <div className="col-12 col-sm-6">
              <SkillSelectField
                name="skills"
                label="Skills"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <GeoSuggestField
                label="Address"
                name="address"
              />
            </div>
            <div className="col-12 col-sm-6">
              <SelectField
                name="role"
                sublabel="Select the role of this contact"
                optionsMapping={[
                  { text: USER_TYPE_LABEL[USER_TYPE.PROVIDER], value: USER_TYPE.PROVIDER },
                  { text: USER_TYPE_LABEL[USER_TYPE.MANAGER], value: USER_TYPE.MANAGER },
                ]}
                label="Role"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextAreaField
                name="resume"
                label="Resume"
                placeholder="Provide a resume for this person"
                mdEditorEnabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextAreaField
                name="notes"
                label="Notes"
                placeholder="Provide notes for this person"
                mdEditorEnabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FileUploaderField
                name="attachments_metadata"
                label="Attachments"
                maxFiles={MAX_UPLOAD_FILES}
                acceptFiles={[
                  'image/*',
                  'application/pdf',
                  'application/msword',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'audio/mpeg',
                  'audio/mp3',
                  'video/mp4',
                ]}
                path={uploaderContactAttachmentsPath(userCard.organization.id, initialValues.id)}
              />
            </div>
          </div>

          <CustomFieldsUpdater
            form={form}
            templates={customFieldTemplates}
            entityId={contactId}
            entityType={FIELD_ENTITY_TYPE.USER}
            enableSync={enableFieldTemplateSync}
            allowTemplateSelection={allowTemplateSelection}
            initialCustomFields={customFields}
            templateFieldLabel="Contact template"
          />

        </div>
        <div
          className="col-12 mt-4 text-right px-0 d-flex justify-content-end"
        >
          <TDButton
            type="submit"
            variant={BS_STYLE.DEFAULT}
            disabled={submitting}
            label="Cancel"
            onClick={() => history.push(contactsUrl(orgAlias))}
          />
          <TDButton
            type="submit"
            variant={BS_STYLE.PRIMARY}
            disabled={submitting}
            label="Save contact"
          />
        </div>
      </form>
    )}
  />
);


ContactForm.propTypes = {
  contactId: PropTypes.number,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userCard: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  allowTemplateSelection: PropTypes.bool,
  enableFieldTemplateSync: PropTypes.bool,
  customFields: PropTypes.arrayOf(customFieldSpec),
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
};

ContactForm.defaultProps = {
  contactId: null,
  enableFieldTemplateSync: false,
  customFields: [],
  customFieldTemplates: [],
  allowTemplateSelection: false,
  initialValues: {},
};

const mapStateToProps = state => ({
  userCard: selectActiveUserCard(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const ContactFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactForm);

export default withRouter(ContactFormConnected);
