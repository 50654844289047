import React from 'react';

import { privacyUrl } from 'core/urls';
import Storage from 'core/assets/js/lib/Storage';


class CookiesNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleConfirm = this.handleConfirm.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }

  componentDidMount() {
    const cookiesAccepted = Storage.get('cookiesAccepted');
    if (!cookiesAccepted) {
      this.showNotification();
    }
  }

  showNotification() {
    this.setState({
      showNotification: true,
    });
  }

  handleConfirm() {
    Storage.set('cookiesAccepted', true);
    this.setState({
      showNotification: false,
    });
  }

  render() {
    const { showNotification } = this.state;
    return showNotification ? (
      <div className="cookies-notification">
        <p>
          TalentDesk.io uses cookies. By continuing to access the site you are agreeing to their
          use.
          {' '}
          <a href={privacyUrl('cookies')} className="more">Click here</a>
          {' '}
          to learn more about cookies.
        </p>
        <a
          href=""
          className="cookies-notification_confirm"
          onClick={this.handleConfirm}
        >
          OK
        </a>
      </div>
    ) : null;
  }
}

export default CookiesNotification;
