import React from 'react';
import TalentCardSkeleton from 'people/assets/js/components/skeletons/TalentCardSkeleton.jsx';

const itemsCount = 4;
const renderItems = () => new Array(itemsCount)
  .fill(0)
  .map((_item, idx) => (
    <TalentCardSkeleton key={`people-skeleton-item-${idx}`} />
  ));


const TalentListSkeleton = () => (
  <div className="skeleton--people-list row mx-0">{renderItems()}</div>
);

export default TalentListSkeleton;
