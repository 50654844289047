import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import IntegrationTypeModal from 'integrations/assets/js/components/IntegrationTypeModal.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { browserRedirect } from 'core/assets/js/lib/utils';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import {
  CODAT_DATA_TYPE_LABELS, CODAT_INTEGRATION_TYPES, INTEGRATION_TYPE_CONFIG,
} from 'integrations/assets/js/constants';
import {
  addIntegrationTypeDS, fetchIntegrationListDS,
} from 'integrations/assets/js/data-services/list';
import { fetchIntegrationConfigDS } from 'integrations/assets/js/data-services/view';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import { orgIntegrationsUrl, orgIntegrationDetailUrl } from 'integrations/urls';

const dataTypeLabels = Object.values(CODAT_DATA_TYPE_LABELS);

class IntegrationConnectView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    const { orgAlias, integrationType } = params;
    return Promise.all([
      dispatch(fetchIntegrationConfigDS({
        url, querystring, componentName, orgAlias, integrationType, authedAxios,
      })),
      dispatch(fetchIntegrationListDS({
        authedAxios, componentName, orgAlias, querystring, url,
      })),
    ]);
  }

  static GetComponentName() {
    return 'IntegrationConnectView';
  }

  constructor(props) {
    super(props);
    this.state = {
      isButtonEnabled: true,
      isModalOpen: false,
    };

    this.handleAddIntegrationType = this.handleAddIntegrationType.bind(this);
    this.showExternalIntegrationModal = this.showExternalIntegrationModal.bind(this);
    this.closeExternalIntegrationModal = this.closeExternalIntegrationModal.bind(this);
  }

  async handleAddIntegrationType() {
    const {
      dispatch,
      match: { params: { orgAlias } },
      integration: { type: integrationType },
    } = this.props;

    try {
      this.setState({ isButtonEnabled: false });

      const values = await dispatch(
        addIntegrationTypeDS({
          componentName: 'IntegrationConnectView',
          orgAlias,
          integrationType,
        }),
      );

      browserRedirect(values.redirectUrl);
    } catch (err) {
      toastr.error('Oh Snap!', 'Something went wrong. Please refresh and try again');
      this.setState({ isButtonEnabled: true });
    }
  }

  showExternalIntegrationModal() {
    this.setState({ isModalOpen: true });
  }

  closeExternalIntegrationModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      existing, existingIntegrations, integration, match: { url: currentUrl, params: { orgAlias } },
    } = this.props;
    const { isModalOpen, isButtonEnabled } = this.state;

    if (integration.isUnique && !isEmpty(existing)) {
      // If there's only 1 integration, redirect to it. Otherwise, go to the integrations list
      const redirectUrl = existing.length === 1
        ? orgIntegrationDetailUrl(orgAlias, existing[0].id)
        : orgIntegrationsUrl(orgAlias);

      return (
        <RedirectRoute
          status={302}
          from={currentUrl}
          to={redirectUrl}
        />
      );
    }

    let otherCodatIntegration = null;
    if (CODAT_INTEGRATION_TYPES.includes(integration.type)) {
      otherCodatIntegration = existingIntegrations.find(i => (
        CODAT_INTEGRATION_TYPES.includes(i.type)
      ));
    }

    const breadcrumbs = [
      { title: 'Integrations', url: orgIntegrationsUrl(orgAlias) },
      { title: integration.name, url: null },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={() => null}
            >
              {otherCodatIntegration && (
                <TDSystemMessage className="mb-5" title="Integration disabled">
                  {'This integration is disabled because '}
                  <b>you can only have 1 active accounting integration</b>
                  {`. In order to use ${integration.name}, you need to remove `}
                  <Link to={orgIntegrationDetailUrl(orgAlias, otherCodatIntegration.id)}>
                    {INTEGRATION_TYPE_CONFIG[otherCodatIntegration.type].name}
                  </Link>
                  {' from your integrations.'}
                </TDSystemMessage>
              )}
              <Card
                key={integration.type}
              >
                <div className="integration-card-img d-flex justify-content-center align-items-center">
                  <Card.Img variant="top" src={integration.logo} />
                </div>
                <Card.Body>
                  <div className="integration-card-body row">
                    <div className="col-md-6 col-lg-4">
                      <Card.Title as="h3">Description</Card.Title>
                      <Card.Text>{integration.description}</Card.Text>
                    </div>
                    <div className="col-md-6 col-lg-8">
                      <Card.Title as="h3">Permissions</Card.Title>
                      <div>
                        <ul className="integrations__integration-features d-flex">
                          {dataTypeLabels.map(dataType => (
                            <li key={dataType}>
                              <span className={ICON.SUCCESS} />
                              {` ${dataType}`}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix text-center">
                    <hr />
                    <TDButton
                      disabled={otherCodatIntegration}
                      label="Start integration"
                      onClick={() => {
                        if (otherCodatIntegration) {
                          return;
                        }
                        this.showExternalIntegrationModal();
                      }}
                      variant={BS_STYLE.PRIMARY}
                    />
                  </div>
                </Card.Body>
                <ModalSimple
                  open={isModalOpen}
                  onClose={this.closeExternalIntegrationModal}
                  size={BS_SIZE.LARGE}
                  body={(
                    <IntegrationTypeModal
                      isButtonEnabled={isButtonEnabled}
                      integrationType={integration.type}
                      handleAddIntegrationType={this.handleAddIntegrationType}
                      onClose={this.closeExternalIntegrationModal}
                    />
                  )}
                  noFooter
                />
              </Card>
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

IntegrationConnectView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: routerMatchContentsSpec.isRequired,
  integration: integrationSpec.isRequired,
  existing: PropTypes.arrayOf(PropTypes.object),
  existingIntegrations: PropTypes.arrayOf(PropTypes.object),
};

IntegrationConnectView.defaultProps = {
  existing: [],
  existingIntegrations: [],
};

const mapStateToProps = (state) => {
  const componentName = IntegrationConnectView.GetComponentName();

  return {
    existing: getViewStateExtras(state, componentName, 'existing'),
    existingIntegrations: getListState(state, componentName).items,
    integration: getViewState(state, componentName).item,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationsListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationConnectView);

export default withRouter(IntegrationsListConnected);
