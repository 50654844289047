import React from 'react';
import PropTypes from 'prop-types';

import { BS_SIZE } from 'core/assets/js/constants';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';

const SelectField = ({
  additionalError,
  'data-testid': dataTestId,
  defaultOptionText,
  defaultValue,
  disabled,
  extraLabelActions,
  input,
  inputClassName,
  label,
  labelClassName,
  meta: { error, pristine, submitError, touched },
  optionsMapping,
  popOverContent,
  popOverSize,
  popOverTitle,
  required,
  sublabel,
  wrapperClasses,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = (touched && (error || submitError)) || showAdditionalError;
  const hasExtraLabelActions = extraLabelActions;
  const groupClassName = ['form-group'];

  if (hasError) {
    groupClassName.push('has-error');
  }
  if (wrapperClasses) {
    groupClassName.push(wrapperClasses);
  }

  const options = optionsMapping.map((option) => {
    const key = option.path
      ? `${option.path}-${option.value}`
      : `${option.text}-${option.value}`;

    const extraProps = {};
    if (defaultValue && defaultValue === option.value) {
      extraProps.selected = 'selected';
    }
    return (
      <option
        key={key}
        value={option.value}
        {...extraProps}
      >
        {option.text}
      </option>
    );
  });

  const field = (
    <select
      data-testid={dataTestId}
      className={['form-control', inputClassName].join(' ')}
      disabled={disabled}
      id={`field-${input.name}`}
      {...input}
    >
      {defaultOptionText && !defaultValue && (
        <option key="please-select" value="">{defaultOptionText}</option>
      )}
      {options}
    </select>
  );

  return (
    <div className={groupClassName.join(' ')}>
      <div className="d-flex justify-content-between">
        {label && (
          <div>
            <TDLabel
              popOverSize={popOverSize}
              popOverContent={popOverContent}
              popOverTitle={popOverTitle}
              sublabel={sublabel}
              required={required}
              className={labelClassName}
              label={label}
              name={input.name}
            />
          </div>
        )}
        {hasExtraLabelActions && extraLabelActions}
      </div>
      {showAdditionalError && (
        <span className="help-block d-inline-block mt-3">{additionalError}</span>
      )}
      {field}
      {hasError && <span className="help-block d-inline-block mt-3">{submitError || error}</span>}
    </div>
  );
};

SelectField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultOptionText: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  extraLabelActions: PropTypes.node,
  input: PropTypes.object.isRequired,
  inputClassName: PropTypes.string,
  label: finalFormFieldLabelSpec,
  labelClassName: PropTypes.string,
  meta: finalFormFieldMetaSpec,
  optionsMapping: PropTypes.array.isRequired,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.string,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefix: PropTypes.string,
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

SelectField.defaultProps = {
  additionalError: '',
  className: null,
  'data-testid': 'select-field-country-dropdown',
  defaultOptionText: 'Choose option',
  defaultValue: '',
  disabled: false,
  extraLabelActions: null,
  inputClassName: '',
  label: '',
  labelClassName: '',
  meta: {
    error: '',
    submitError: '',
    pristine: true,
  },
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
  prefix: null,
  required: false,
  sublabel: null,
  suffix: null,
  wrapperClasses: '',
};

export default withField(SelectField);
