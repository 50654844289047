import React from 'react';
import PropTypes from 'prop-types';

import { dataReportsSpec } from 'analytics/assets/js/lib/objectSpecs';
import { reportUrl } from 'analytics/urls';
import { ICON } from 'core/assets/js/constants';

const ReportsList = ({ onSubmitSelectedReport, orgAlias, reports }) => (
  reports.length > 0 ? reports.map(({ columns, id, title, description }) => (
    <ul className="report-list__container">
      <li
        className="mt-4"
        data-testid="checkable-list-select-org-for-payment-method"
        key={`checkable-list-item-${id}`}
        id={`checkable-list-item-${id}`}
      >
        <a
          className="d-flex p-4 w-100"
          onClick={() => onSubmitSelectedReport(reportUrl(orgAlias, id))}
        >
          <div>
            <h4 className="mt-0 mb-2 font-weight-bolder">{title}</h4>
            <p className="mb-0">{description}</p>
            <p className="mb-0 hint">
              {`Report headers: ${columns.join(', ')}`}
            </p>
          </div>
        </a>
      </li>
    </ul>
  )) : (
    <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
      <i className={ICON.FILE_CHART_LINE} />
      There are no data reports available yet in your account.
    </div>
  )
);

ReportsList.propTypes = {
  orgAlias: PropTypes.string.isRequired,
  onSubmitSelectedReport: PropTypes.func.isRequired,
  reports: dataReportsSpec.isRequired,
};

export default ReportsList;
