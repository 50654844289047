import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateTalentDeskGlobalObjectFromDashboardView } from 'core/assets/js/lib/utils';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { BS_STYLE } from 'core/assets/js/constants';
import {
  modalOpenAC,
  modalCloseAC,
  getIsModalOpen,
} from 'core/assets/js/ducks/modalLauncher';
import DashboardLayoutSettingModal, {
  DASHBOARD_LAYOUT_SETTING_MODAL_ID,
} from 'core/assets/js/components/Dashboard/LayoutSettingModal.jsx';
import Storage from 'core/assets/js/lib/Storage';
import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_FIELD_NAME } from 'settings/assets/js/constants';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import BannerWidget from 'core/assets/js/components/Dashboard/BannerWidget.jsx';
import ContractorsCounterWidget from 'core/assets/js/components/Dashboard/ContractorsCounterWidget.jsx';
import ExpensesWidget from 'core/assets/js/components/Dashboard/ExpensesWidget.jsx';
import ManagersWidget from 'core/assets/js/components/Dashboard/ManagersWidget.jsx';
import MyTasksWidget from 'core/assets/js/components/Dashboard/MyTasksWidget.jsx';
import OpportunitiesWidget from 'core/assets/js/components/Dashboard/OpportunitiesWidget.jsx';
import PaymentStatusWidgetWrapper from 'core/assets/js/components/Dashboard/PaymentStatusWidget/PaymentStatusWidgetWrapper.jsx';
import PendingActionsWidget from 'core/assets/js/components/Dashboard/PendingActionsWidget.jsx';
import ProjectsWidget from 'core/assets/js/components/Dashboard/ProjectsWidget.jsx';
import TalentMarketWidget from 'core/assets/js/components/Dashboard/TalentMarketWidget.jsx';
import WorksheetsWidget from 'core/assets/js/components/Dashboard/WorksheetsWidget.jsx';
import WidgetEmptyPlaceHolder from 'core/assets/js/components/Dashboard/WidgetEmptyPlaceHolder.jsx';
import { WINDOW_SET_TALENTDESK_GLOBAL } from 'core/assets/js/config/settings';
import { getDashboardWidgetDefaultSettings } from 'settings/assets/js/lib/utils';

export const DASHBOARD_LAYOUT_SETTING_STORAGE_KEY = 'dashboardLayoutSetting';

const OrgDashboardPage = ({
  activeOrg, activeUserCard, dispatch, hasOrgAccess, isDashboardLayoutSettingModalOpen,
}) => {
  const userCardId = activeUserCard.id;
  const isManager = hasOrgAccess({ requireManager: true });

  const [layoutSetting, setLayoutSetting] = useState(null);

  useEffect(() => {
    const settingInStorage = Storage.get(DASHBOARD_LAYOUT_SETTING_STORAGE_KEY);
    const shouldResetStorage = settingInStorage?.userCardId !== userCardId;
    const layoutConfigInStorage = shouldResetStorage ? null : settingInStorage?.config;

    if (shouldResetStorage) {
      Storage.remove(DASHBOARD_LAYOUT_SETTING_STORAGE_KEY);
    }

    setLayoutSetting(
      layoutConfigInStorage || getDashboardWidgetDefaultSettings(activeOrg, activeUserCard),
    );
  }, []);

  const onAddWidget = () => {
    dispatch(modalOpenAC(DASHBOARD_LAYOUT_SETTING_MODAL_ID, layoutSetting));
  };

  const onAddWidgetSubmit = (newLayoutSetting) => {
    Storage.set(DASHBOARD_LAYOUT_SETTING_STORAGE_KEY, {
      userCardId,
      config: newLayoutSetting,
    });

    setLayoutSetting(newLayoutSetting);
    dispatch(modalCloseAC());
  };

  const breadcrumbs = [
    {
      title: 'Dashboard',
      url: null,
    },
  ];

  const ctaButtonItems = [{
    label: 'Edit Dashboard',
    onClick: onAddWidget,
    variant: BS_STYLE.DEFAULT,
  }];

  const isViewAllowed = widget => layoutSetting[DASHBOARD_WIDGET_FIELD_NAME[widget]];
  const isEmptyView = (
    layoutSetting && Object.values(layoutSetting).every(widget => widget === false)
  );

  return (
    <>
      {isDashboardLayoutSettingModalOpen && (
        <DashboardLayoutSettingModal onSubmit={onAddWidgetSubmit} />
      )}
      <ContentHeader
        breadcrumbs={breadcrumbs}
        ctaButtonItems={ctaButtonItems}
      />
      <div className="page page--projects">
        <div className="container">
          <BannerWidget />

          {isEmptyView && (
            <WidgetEmptyPlaceHolder
              content="You have disabled all Dashboard widgets"
              cta={{
                label: 'Add widgets to dashboard',
                onClick: onAddWidget,
                variant: BS_STYLE.PRIMARY,
              }}
            />
          )}

          <div className="row">
            {layoutSetting && (
              <>
                {activeOrg.payment_status_widget_enabled
                && isViewAllowed(DASHBOARD_WIDGET.PAYMENT_STATUS)
                && (
                  <div className="col-12 mb-4 pb-4">
                    <PaymentStatusWidgetWrapper />
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.PENDING_ACTIONS) && (
                  <div className="col-12 mb-4 pb-4"><PendingActionsWidget /></div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.WORKSHEETS) && (
                  <div className="col-12 col-xl-6 mb-4 pb-4">
                    <WorksheetsWidget />
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.EXPENSES) && (
                  <div className="col-12 col-xl-6 mb-4 pb-4">
                    <ExpensesWidget />
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.TASKS) && (
                  <div className="col-12 col-xl-6 mb-4 pb-4">
                    {isManager ? <MyTasksWidget toolTip="Tasks are smaller units of work that can be created to break down a project’s scope. Tasks need to be created against a project. In order to create a new task, select a project that you manage and navigate to the Tasks tab." miniTip="Break down large projects into deliverable tasks" tipLink="https://www.talentdesk.io/knowledge-base/projects/manager/assigning-and-managing-tasks" />  : <MyTasksWidget toolTip="Tasks are small work items that you have agreed to work on. This list will display some of the tasks that are currently active for you to deliver" miniTip="The projects that you are working on are managed by tasks" tipLink="https://www.talentdesk.io/knowledge-base/projects/provider/how-to-start-working-on-projects" />}
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.OPPORTUNITIES) && (
                  <div className="col-12 col-xl-6 mb-4 pb-4">
                    <OpportunitiesWidget />
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.PROJECTS) && (
                  <div className="col-12 col-xl-6 mb-4 pb-4">
                    <ProjectsWidget />
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.MANAGERS) && (
                  <div className="col-12 col-xl-6 mb-4 pb-4">
                    <ManagersWidget />
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.CONTRACTORS) && (
                  <div className="col-12 col-xl-6 mb-4 pb-4">
                    <ContractorsCounterWidget />
                  </div>
                )}
                {isViewAllowed(DASHBOARD_WIDGET.TALENTMARKET) && (
                  <div className="col-12 mb-4 pb-4">
                    <TalentMarketWidget />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

OrgDashboardPage.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec,
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  isDashboardLayoutSettingModalOpen: PropTypes.bool,
};

OrgDashboardPage.defaultProps = {
  isDashboardLayoutSettingModalOpen: false,
  activeUserCard: {},
};

const mapStateToProps = (state, props) => {
  WINDOW_SET_TALENTDESK_GLOBAL(updateTalentDeskGlobalObjectFromDashboardView(state));
  return {
    activeOrg: selectActiveOrg(state),
    activeUserCard: selectActiveUserCard(state),
    hasOrgAccess: getHasOrgAccess(state),
    isDashboardLayoutSettingModalOpen: getIsModalOpen(
      state,
      DASHBOARD_LAYOUT_SETTING_MODAL_ID,
    ),
    match: props.match,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgDashboardPageConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgDashboardPage);

export default withRouter(OrgDashboardPageConnect);
