import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Tooltip, Popover, Overlay } from 'react-bootstrap';

import { formatDate } from 'core/assets/js/lib/utils';
import { ICON, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { exchangeRatesServiceSpec } from 'finance/assets/js/lib/objectSpecs';
import { EXCHANGE_RATE_SERVICE_LABEL, EXCHANGE_RATE_SERVICE_URL } from 'finance/assets/js/constants';

const ExchangeRateInfoPopOver = ({
  className,
  exchangeRate,
  exchangeRateDate,
  exchangeRateService,
  sourceCurrency,
  targetAmount,
  targetCurrency,
}) => {
  const target = useRef(null);
  const TOOLTIP_TITLE = 'Exchange rate info';
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPopOver, setShowPopOver] = useState(false);
  return (
    <span className={className}>
      <a
        className="exchange-rate-info-popover__toggle"
        ref={target}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => {
          setShowPopOver(!showPopOver);
          setShowTooltip(false);
        }}
      >
        <i className={ICON.EXCHANGE} />
      </a>

      <Overlay
        target={target.current}
        show={showTooltip && !showPopOver}
        placement="top"
        onHide={() => setShowTooltip(false)}
      >
        {(props) => {
          const newProps = { ...props };
          delete newProps.show;
          return (
            <Tooltip id="overlay-example" {...newProps}>
              {TOOLTIP_TITLE}
            </Tooltip>
          );
        }}
      </Overlay>

      <Overlay
        id="popover-overlay"
        target={target.current}
        show={showPopOver}
        placement="bottom"
        onHide={() => setShowPopOver(false)}
        rootClose
      >
        <Popover
          id="popover-1"
          placement="bottom"
          className="exchange-rate-info-popover"
          data-testid="exchange-rate-info-popover"
        >
          <Popover.Title>
            Exchange rate info
            <span
              className="popover__close-button"
              onClick={() => setShowPopOver(false)}
            >
              <i className={ICON.TIMES} />
            </span>
          </Popover.Title>

          <Popover.Content>
            <span className="d-block mb-4">
              <span className="font-weight-bolder">
                <NumberFormat
                  value={targetAmount}
                  prefix={CURRENCY_SYMBOL[targetCurrency]}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </span>
            <p className="discreet mb-4">
              Exchange rate
              {` on ${formatDate(exchangeRateDate)}`}
              <br />
              <span className="font-weight-bold">
                <span className="text-uppercase">
                  {sourceCurrency}
                </span>
                {' to '}
                <span className="text-uppercase">
                  {targetCurrency}
                </span>
                {' '}
                {exchangeRate}
              </span>
            </p>
            <p>
              Source:
              {' '}
              <a
                href={EXCHANGE_RATE_SERVICE_URL[exchangeRateService]}
                rel="noopener noreferrer"
                target="_blank"
              >
                {EXCHANGE_RATE_SERVICE_LABEL[exchangeRateService]}
              </a>
            </p>
          </Popover.Content>
        </Popover>
      </Overlay>
    </span>
  );
};

ExchangeRateInfoPopOver.propTypes = {
  className: PropTypes.string,
  exchangeRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  exchangeRateDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  exchangeRateService: exchangeRatesServiceSpec.isRequired,
  sourceCurrency: PropTypes.string.isRequired,
  targetAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  targetCurrency: PropTypes.string.isRequired,
};
ExchangeRateInfoPopOver.defaultProps = {
  className: '',
};

export default ExchangeRateInfoPopOver;
