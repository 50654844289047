import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { TASK_ACTION, TASK_ACTION_LABEL } from 'projects/assets/js/constants';
import { projectSpec, projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { TASK_TABS } from 'core/assets/js/constants';
import { projectViewTaskUrl } from 'projects/urls';
import ReopenTaskButton from 'core/assets/js/components/DiscussionBoard/actions/ReopenTaskButton.jsx';
import SystemMessage from 'core/assets/js/components/DiscussionBoard/messages/SystemMessage.jsx';

const TaskAssignmentCompletedMessage = ({
  message, timestamp, task, project, actions, parentComponentName, match, history,
}) => {
  let ctaButton = null;
  const reopenAction = actions.find(act => act.name === TASK_ACTION.REOPEN);

  if (reopenAction) {
    const handleTaskReopen = () => {
      const { params: { orgAlias } } = match;
      history.push(
        projectViewTaskUrl(
          orgAlias,
          project.id,
          task.id,
          TASK_TABS.DISCUSSION,
          TASK_ACTION.REOPEN,
          reopenAction.targetUserId,
        ),
      );
    };

    ctaButton = (
      <ReopenTaskButton
        task={task}
        project={project}
        label={TASK_ACTION_LABEL[TASK_ACTION.REOPEN]}
        formType={TASK_ACTION.REOPEN}
        onClick={handleTaskReopen}
        parentComponentName={parentComponentName}
        {...reopenAction.data}
      />
    );
  }

  return (
    <SystemMessage
      message={message}
      timestamp={timestamp}
      className="db-system-message--completed  d-flex align-items-center"
      append={ctaButton}
    />
  );
};

TaskAssignmentCompletedMessage.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object),
  project: projectSpec.isRequired,
  task: projectTaskSpec.isRequired,
  parentComponentName: PropTypes.string.isRequired,
};

TaskAssignmentCompletedMessage.defaultProps = {
  actions: [],
};

export default withRouter(TaskAssignmentCompletedMessage);
