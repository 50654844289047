import React from 'react';
import { Card } from 'react-bootstrap';

import BurnDownSkeleton from 'core/assets/js/components/Skeleton/BurnDownSkeleton.jsx';

const ProjectBurnDownWidgetSkeleton = () => (
  <Card>
    <Card.Header>
      Burndown
    </Card.Header>
    <Card.Body>
      <BurnDownSkeleton />
    </Card.Body>
  </Card>
);

export default ProjectBurnDownWidgetSkeleton;
