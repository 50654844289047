import { fetchListDS } from 'core/assets/js/ducks/list';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import {
  ratesCancelApiUrl, ratesCreateApiUrl, ratesDeleteApiUrl, ratesEditApiUrl,
  ratesFetchApiUrl, ratesFetchRateApiUrl, ratesFetchRatesOfUserApiUrl, ratesRenameApiUrl,
  ratesApproveApiUrl, ratesDeclineApiUrl, ratesFetchRatesOfUsersApiUrl, ratesProposeApiUrl,
  rateSetAsDefaultApiUrl,
} from 'rates/urls';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';


const _validateParams = ({ orgAlias }) => {
  if (!orgAlias) {
    throw new Error(`Invalid orgAlias ${orgAlias}`);
  }
};

export const fetchRatesDS = ({
  orgAlias, userId, status = [], componentName, url = '', authedAxios = null,
}) => {
  const apiUrl = userId
    ? ratesFetchRatesOfUserApiUrl(orgAlias, userId, status, url)
    : ratesFetchApiUrl(orgAlias, status, url);

  return (
    fetchListDS({
      validate: () => _validateParams({ orgAlias }),
      authedAxios,
      componentName,
      url: apiUrl,
    })
  );
};

export const fetchRatesForUsersDS = ({
  orgAlias, userIds, status = [], componentName, url = '', authedAxios = null,
} = {}) => (
  fetchListDS({
    validate: () => _validateParams({ orgAlias }),
    authedAxios,
    componentName,
    url: ratesFetchRatesOfUsersApiUrl(orgAlias, userIds, status, url),
  })
);

export const fetchRateDS = ({
  orgAlias, id, componentName, url = '', authedAxios = null,
}) => (
  fetchViewDS({
    validate: () => _validateParams({ orgAlias }),
    authedAxios,
    componentName,
    url: ratesFetchRateApiUrl(orgAlias, id, url),
  })
);


export const createRateDS = (orgAlias, values) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.POST,
    pushApiUrl: ratesCreateApiUrl(orgAlias),
    values,
  })
);

export const proposeRateDS = (orgAlias, values) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.POST,
    pushApiUrl: ratesProposeApiUrl(orgAlias),
    values,
  })
);

export const editRateDS = (orgAlias, rateId, values) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.PUT,
    pushApiUrl: ratesEditApiUrl(orgAlias, rateId),
    values,
  })
);

export const cancelRateDS = (orgAlias, rateId, values) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.PUT,
    pushApiUrl: ratesCancelApiUrl(orgAlias, rateId),
    values,
  })
);

export const deleteRateDS = (orgAlias, rateId) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: ratesDeleteApiUrl(orgAlias, rateId),
  })
);

export const renameRateDS = (orgAlias, rateId, values) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.PUT,
    pushApiUrl: ratesRenameApiUrl(orgAlias, rateId),
    values,
  })
);

export const approveRateDS = (orgAlias, rateId, values) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.PUT,
    pushApiUrl: ratesApproveApiUrl(orgAlias, rateId),
    values,
  })
);

export const declineRateDS = (orgAlias, rateId, values) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.PUT,
    pushApiUrl: ratesDeclineApiUrl(orgAlias, rateId),
    values,
  })
);

export const setRateAsDefaultDS = (orgAlias, rateId) => (
  pushDataDS({
    validate: _validateParams({ orgAlias }),
    reqType: REQ_TYPE.PUT,
    pushApiUrl: rateSetAsDefaultApiUrl(orgAlias, rateId),
  })
);
