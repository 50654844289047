import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BS_STYLE } from 'core/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { MODAL_ID as ADD_USERS_MODAL_ID } from 'roles/assets/js/components/RoleAddUsersPanel.jsx';
import RoleForm from 'roles/assets/js/components/RoleForm.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  PERMISSIONS,
  ROLE_TABS,
  SYSTEM_ROLES,
  SYSTEM_ROLES_PERMISSION_GROUPS,
  SYSTEM_ROLES_LABEL,
} from 'roles/assets/js/constants';
import { systemRoleViewUrl, rolesListUrl, roleDuplicateUrl } from 'roles/urls';
import RoleUsersList from 'roles/assets/js/components/RoleUsersList.jsx';

const SystemRoleView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orgAlias, systemRoleId, tab } = useParams();
  const systemRolePermissions = systemRoleId === SYSTEM_ROLES.SYSTEM_ADMIN
    ? Object.values(PERMISSIONS)
    : SYSTEM_ROLES_PERMISSION_GROUPS[systemRoleId].flatMap((group) =>
      group.subgroups.flatMap((subgroup) => subgroup.permissions),
    );

  const tabSpec = [
    {
      key: 'config',
      label: 'Permissions',
      url: systemRoleViewUrl(orgAlias, systemRoleId, ROLE_TABS.CONFIG),
    },
    {
      key: 'members',
      label: 'Members',
      url: systemRoleViewUrl(orgAlias, systemRoleId, ROLE_TABS.MEMBERS),
    },
  ];

  const ctaButton = [
    {
      label: 'Duplicate',
      onClick: () => history.push(roleDuplicateUrl(
        orgAlias, systemRoleId,
      )),
      variant: BS_STYLE.PRIMARY,
    },
  ];

  if (tab === ROLE_TABS.MEMBERS) {
    ctaButton.unshift({
      label: 'Add members',
      onClick: () => dispatch(modalOpenAC(ADD_USERS_MODAL_ID)),
      variant: BS_STYLE.PRIMARY,
    });
  }

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          {
            title: 'Roles & Permissions',
            url: rolesListUrl(orgAlias),
          },
          { title: SYSTEM_ROLES_LABEL[systemRoleId],
            url: '',
          },
        ]}
        ctaButtonItems={ctaButton}
      >
        <div className="horizontal-scroll" data-testid="system-role-tabs">
          <TabBar
            activeKey={tab}
            handleTabSelect={(tabKey) => {
              history.push(tabSpec.find(i => i.key === tabKey).url);
            }}
            tabSpec={tabSpec}
          />
        </div>
      </ContentHeader>
      <div className="page page--roles" data-testid="roles-list-page">
        <div className="container">
          {tab === ROLE_TABS.CONFIG && (
            <RoleForm
              onSubmit={() => {}}
              initialValues={{
                name: SYSTEM_ROLES_LABEL[systemRoleId],
                permissions: systemRolePermissions,
              }}
              isSystemRole
            />
          )}

          {tab === ROLE_TABS.MEMBERS && <RoleUsersList />}
        </div>
      </div>
    </>
  );
};

SystemRoleView.GetComponentName = () => 'SystemRoleView';

export default SystemRoleView;
