import React from 'react';
import PropTypes from 'prop-types';

import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import TDList from 'core/assets/js/components/TDList.jsx';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import RateCard from 'rates/assets/js/components/RateCard.jsx';
import RateAdjustmentModal from 'rates/assets/js/components/RateAdjustmentModal.jsx';

const RateList = ({
  editInModal, isProvider, listComponentName, onEdit, rates, userCard,
}) => (
  rates.length > 0 ? (
    <>
      <TDList
        listClassName="rates-list"
        items={rates}
        cardItem={{
          component: RateCard,
          props: {
            editInModal,
            isProvider,
            listComponentName,
            userCard,
          },
        }}
      />
      <RateAdjustmentModal onSuccess={onEdit} />
    </>
  ) : (
    <div className="py-2 px-2">
      <i className="discreet">No rates created yet.</i>
    </div>
  )
);

RateList.propTypes = {
  editInModal: PropTypes.bool,
  isProvider: PropTypes.bool,
  listComponentName: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  userCard: userCardSpec,
};

RateList.defaultProps = {
  editInModal: false,
  isProvider: true,
  onEdit: () => {},
  rates: [],
  userCard: null,
};

export default RateList;
