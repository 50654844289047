import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { find } from 'lodash';

import { isSSR } from 'core/assets/js/config/checks';
import { REACT_SELECT_STYLES } from 'core/assets/js/constants';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';


const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {children}
    </components.SingleValue>
  );
};
SingleValue.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  data: PropTypes.object.isRequired,
};

/**
 * Redux form compatible select field
 *
 * It displays a bootstrap decorated input field with a label and an error
 * message
 */
const CustomSelectField = ({
  'data-testid': dataTestId,
  input,
  required,
  label,
  sublabel,
  options,
  selectedOption,
  meta: { touched, error },
  additionalError,
  className,
  onItemSelected,
  hint,
  isSearchable,
  defaultOptionText,
  disabled,
}) => {
  const handleChange = ({ value }) => {
    input.onChange(value);
    onItemSelected(value);
  };
  const _selectedOption = find(options, opt => opt.value === selectedOption);
  const hasError = (error && touched) || additionalError;
  const groupClassName = ['form-group'];

  if (className) {
    groupClassName.push(className);
  }

  if (hasError) {
    groupClassName.push('has-error');
  }

  return (
    <div className={groupClassName.join(' ')} data-testid={dataTestId}>
      { label && (
        <label
          htmlFor={input.name}
        >
          {label}
          {' '}
          {required && '*'}
          {' '}
          { sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
      )}

      { hint && (
        <span className="hint">
          {hint}
        </span>
      )}

      { additionalError && (
        <span className="help-block">
          {additionalError}
        </span>
      )}

      <div className="form-control-container custom-select-field">
        <Select
          styles={REACT_SELECT_STYLES}
          name={input.name}
          menuPlacement="auto"
          isSearchable={isSearchable}
          menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
          classNamePrefix="react-select"
          id={`${input.name}-dropdown`}
          defaultValue={
            _selectedOption || {
              value: '',
              label: defaultOptionText,
            }
          }
          components={{ SingleValue }}
          placeholder={defaultOptionText}
          isDisabled={options.length === 0 || disabled}
          onChange={handleChange}
          options={options}
          getOptionValue={opt => opt.value}
        />
      </div>

      { hasError && (
        <span className="help-block">
          {error}
        </span>
      )}
    </div>
  );
};

CustomSelectField.propTypes = {
  'data-testid': PropTypes.string,
  defaultOptionText: PropTypes.string,
  disabled: PropTypes.bool,
  input: reduxInputSpec.isRequired,
  required: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.array,
  selectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  additionalError: PropTypes.string,
  onItemSelected: PropTypes.func,
  hint: PropTypes.node,
  className: PropTypes.string,
};

CustomSelectField.defaultProps = {
  'data-testid': '',
  defaultOptionText: 'Select an option',
  disabled: false,
  meta: {
    touched: false,
    error: false,
  },
  label: '',
  className: null,
  selectedOption: '',
  required: false,
  sublabel: '',
  options: [],
  additionalError: '',
  hint: null,
  isSearchable: false,
  onItemSelected: () => {},
};

export default CustomSelectField;
