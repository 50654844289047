import { omit } from 'lodash';

// Action Types
export const MODAL_LAUNCHER = {
  OPEN: 'MODAL_LAUNCHER_OPEN',
  CLOSE: 'MODAL_LAUNCHER_CLOSE',
  UPDATE_PAYLOAD: 'MODAL_LAUNCHER_UPDATE_PAYLOAD',
};

// Reducer
export const initialState = {
  stack: [],
  payloads: {},
};

export const reducer = (state = initialState, action) => {
  let stack;
  let payloads;
  let activeModalId;

  switch (action.type) {
    case MODAL_LAUNCHER.OPEN:
      // add the recently opened modal id to the stack
      stack = [...state.stack];
      stack.push(action.modalId);

      payloads = { ...state.payloads };
      payloads[action.modalId] = action.payload || null;

      return {
        ...state,
        stack,
        payloads,
      };
    case MODAL_LAUNCHER.CLOSE:
      // remove the last modal id from the stack
      stack = [...state.stack];
      activeModalId = stack.pop();

      payloads = omit(state.payloads, [activeModalId]);

      return {
        ...state,
        stack,
        payloads,
      };
    case MODAL_LAUNCHER.UPDATE_PAYLOAD:
      payloads = { ...state.payloads };
      payloads[action.modalId] = action.payload || null;

      return {
        ...state,
        payloads,
      };
    default:
      return state;
  }
};

// Action creators
export const modalOpenAC = (modalId, payload = null) => ({
  type: MODAL_LAUNCHER.OPEN,
  modalId,
  payload,
});

export const modalCloseAC = () => ({
  type: MODAL_LAUNCHER.CLOSE,
});

export const updateModalPayloadAC = (modalId, payload) => ({
  type: MODAL_LAUNCHER.UPDATE_PAYLOAD,
  modalId,
  payload,
});

export const getIsModalOpen = (store, modalId) => {
  return (
    modalId && store.modalLauncher.stack.includes(modalId)
  );
};

export const getModalPayload = (store, modalId) => (
  modalId && store.modalLauncher.payloads && store.modalLauncher.payloads[modalId]
    ? store.modalLauncher.payloads[modalId]
    : null
);

export default reducer;
