import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { isRateLimitError, logger, createClient } from 'core/assets/js/lib/tdPusher';

const withLiveUpdates = (WrappedComponent) => {
  const WrappedComponentClass = class extends React.Component {
    constructor(props) {
      super(props);
      this.client = null;
      this.connection = null;
      this.channel = null;
      this.isConnected = false;
      this.logger = logger;

      this.connectToProvider = this.connectToProvider.bind(this);
      this.bindConnectionevents = this.bindConnectionevents.bind(this);
      this.subsribeToChannel = this.subsribeToChannel.bind(this);
      this.unsubscribeFromChannel = this.unsubscribeFromChannel.bind(this);
      this.bindChannelEvents = this.bindChannelEvents.bind(this);
      this.logger.log('initializing the HOC');
    }

    componentDidMount() {
      this.logger.log('Component mounted');

      if (!this.isConnected) {
        this.connectToProvider();
      }

      const { channel } = this.props;
      this.subsribeToChannel(channel);
    }

    componentDidUpdate(prevProps) {
      const { channel: previousChannel } = prevProps;
      const { channel: newChannel } = this.props;

      if (previousChannel !== newChannel) {
        this.logger.log(
          `Component updated and channel is different, switching from ${previousChannel} to ${newChannel}`,
        );

        this.unsubscribeFromChannel(previousChannel);
        this.subsribeToChannel(newChannel);
      }
    }

    componentWillUnmount() {
      this.logger.log('Component will unmount');
      const { channel } = this.props;
      this.unsubscribeFromChannel(channel);
      this.client.disconnect();
    }

    connectToProvider() {
      this.logger.log('Connecting to socket provider');
      this.client = createClient();
      this.connection = this.client.connection;
      this.bindConnectionevents();
      this.client.connect();
    }

    bindConnectionevents() {
      this.logger.log('Binding connection events');
      const { onLimitReached, onError, onConnected, onDisconnected } = this.props;

      this.connection.bind('error', (err) => {
        this.logger.error('Error while connecting to the socket provider', err);
        if (err?.type === 'PusherError' && err?.data?.code === 1006) {
          // 1006 is a connection abnormally closed error:
          // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
          // Seems to be a common error with Chromium based browsers (e.g. Chrome, Brave etc) so it
          // should be safe to ignore: https://stackoverflow.com/a/53340067
          return;
        }
        if (isRateLimitError(err)) {
          onLimitReached(err);
          return;
        }
        onError(err);
      });

      this.connection.bind('state_change', ({ previous, current }) => {
        this.logger.log(`Connection state change from ${previous} to ${current}`);
        this.isConnected = current === 'connected';
      });

      this.connection.bind('connected', () => {
        this.logger.log('Connected to socket provider');
        onConnected();
      });

      this.connection.bind('disconnected', () => {
        this.logger.log('Disconnected from the socket provider');
        onDisconnected();
      });
    }

    subsribeToChannel(channel) {
      this.logger.log(`Subscribing to channel ${channel}`);
      this.channel = this.client.subscribe(channel);
      this.bindChannelEvents();
    }

    unsubscribeFromChannel(channel) {
      this.logger.log(`Unsubscribing from channel ${channel}`);
      this.channel.unbind(); // unbind all events from the channel
      this.channel = null;
      this.client.unsubscribe(channel);
    }

    bindChannelEvents() {
      const { events, channel, dispatch, match, history } = this.props;
      this.logger.log(`Binding channel events for channel ${channel}`);

      if (!this.channel) {
        throw new Error('We are not subscribed to the channel');
      }

      if (isEmpty(events)) {
        this.logger.warn('No events were specified, can’t bind anything');
        return;
      }

      this.logger.log('Proceeding with event bindings on the subscribed channel', events);

      Object.keys(events).forEach((key) => {
        this.logger.log(`Binding handler for ${key} event`);
        const eventHandler = events[key];

        if (typeof eventHandler !== 'function') {
          throw new Error(`Invalid event handler for ${key}`);
        }

        this.channel.bind(key, (data) => {
          this.logger.log(`Event ${key} was triggered, calling eventHandler`);
          eventHandler(data, { key, dispatch, match, history });
        });

        this.logger.log(`Event handler for key ${key} was bound`);
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      );
    }
  };

  WrappedComponentClass.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: routerHistorySpec.isRequired,
    match: routerMatchSpec.isRequired,
    channel: PropTypes.string.isRequired,
    events: PropTypes.object.isRequired,
    onConnected: PropTypes.func,
    onDisconnected: PropTypes.func,
    onLimitReached: PropTypes.func,
    onError: PropTypes.func,
  };

  WrappedComponentClass.defaultProps = {
    onConnected: () => {},
    onDisconnected: () => {},
    onLimitReached: () => {},
    onError: () => {},
  };

  const mapStateToProps = () => ({});
  const mapDispatchToProps = dispatch => ({ dispatch });

  const WrappedComponentClassConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WrappedComponentClass);

  return withRouter(WrappedComponentClassConnected);
};

export default withLiveUpdates;
