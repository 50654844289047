import React from 'react';
import PropTypes from 'prop-types';

import { NODE_ENV, ENV_PRODUCTION } from 'core/assets/js/config/settings';
import { renderErrors } from 'core/assets/js/lib/utils-jsx';
import { organizationsUrl } from 'organizations/urls';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { UNKNOWN_ALIAS } from 'organizations/assets/js/constants';

const Error50x = ({ errors, resetError, displayInline }) => {
  const contClassName = ['error-page__wrapper'];
  if (displayInline) {
    contClassName.push('error-page__wrapper--inline');
  }
  const isProduction = typeof NODE_ENV !== 'undefined' && NODE_ENV === ENV_PRODUCTION;
  const showErrorList = !isProduction && errors.length > 0;
  const errorList = renderErrors(errors);
  return (
    <div className={contClassName.join(' ')}>
      <img className="error-page__icon" src="/img/error-icons/50x.svg" alt="Error 50x" />
      <h1 className="error-page__code">50x</h1>
      <h2 className="error-page__title">Server Error</h2>
      <p className="error-page__msg">
        Please try again later. If this problem persists, please
        {' '}
        <a href="mailto:support@talentdesk.io">contact us</a>
        .
      </p>
      { // Show the errors
        showErrorList ? errorList : null
      }

      <a href={organizationsUrl(UNKNOWN_ALIAS)}>&larr; Back to TalentDesk.io</a>
      {' or '}
      <TDElementWithTooltip
        tooltipMsg="If the error persists, click on the 'Back to TalentDesk.io' link at the bottom of the page"
      >
        <a onClick={resetError}>Reset the error</a>
      </TDElementWithTooltip>
    </div>
  );
};

Error50x.propTypes = {
  errors: PropTypes.array,
  resetError: PropTypes.func,
  displayInline: PropTypes.bool,
};
Error50x.defaultProps = {
  errors: [],
  displayInline: false,
  resetError: null,
};

export default Error50x;
