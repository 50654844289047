import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';

import { projectTaskThinListApiUrl } from 'projects/urls';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { MULTISELECT_TYPE, MULTISELECT_TARGET } from 'core/assets/js/constants';
import Filebox from 'core/assets/js/components/FileUploader/Filebox.jsx';
import MultiselectField from 'core/assets/js/components/ReduxFormFields/MultiselectField.jsx';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';

export const FORM_ID = 'task-copy-attachments-form';

const TaskAttachmentsCopySelection = ({
  className, handleSubmit, attachments, items, initialValues,
}) => (
  <form className={className} onSubmit={handleSubmit}>
    {!isEmpty(attachments) && (
      attachments.map(att => (
        <Filebox
          key={`task-attachment-${att.id}`}
          file={att.attachment}
          showDownloadButton={false}
        />
      ))
    )}

    <FieldArray
      name="attachments"
      type="hidden"
      component="input"
    />

    <div className="row">
      <div className="mt-3 col-12 col-md-8">
        <Field
          name="task_id"
          label="Copy to"
          size="extra-large"
          component={MultiselectField}
          multiselectComponent={MULTISELECT_TYPE.SIMPLE}
          target={MULTISELECT_TARGET.TASKS}
          defaultValue={initialValues.task_id}
          searchable
          options={items}
          makeInitialCall
        />
      </div>
    </div>
  </form>
);

TaskAttachmentsCopySelection.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object), // added via withTdApiConnected
  initialValues: PropTypes.object,
  attachments: PropTypes.arrayOf(PropTypes.object),
};

TaskAttachmentsCopySelection.defaultProps = {
  className: '',
  items: [],
  attachments: [],
  initialValues: {},
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskAttachmentsCopySelectionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAttachmentsCopySelection);

const TaskAttachmentsCopySelectionReduxForm = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
})(TaskAttachmentsCopySelectionConnected);

const ConnectedTaskAttachmentsCopySelection = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) => dispatch(fetchListDS({
    authedAxios,
    componentName,
    querystring,
    url: projectTaskThinListApiUrl(params.orgAlias, params.id, url),
  })),
  duck: 'list',
  storeKey: 'TaskCopyAttachmentsModal',
  skeletonComponent: ListSkeleton,
})(TaskAttachmentsCopySelectionReduxForm);

export default ConnectedTaskAttachmentsCopySelection;
