// Project view
export const PROJECT_MANAGERS_FETCH = 'PROJECT_MANAGERS_FETCH';
export const PROJECT_PROVIDERS_FETCH = 'PROJECT_PROVIDERS_FETCH';
export const PROJECT_WORKSHEETS_FETCH = 'PROJECT_WORKSHEETS_FETCH';
export const PROJECT_EXPENSES_FETCH = 'PROJECT_EXPENSES_FETCH';
export const PROJECT_REVIEWS_FETCH = 'PROJECT_REVIEWS_FETCH';
export const PROJECT_REVIEWS_SUBMIT = 'PROJECT_REVIEWS_SUBMIT';
export const PROJECT_ACTIVE_RESET = 'PROJECT_ACTIVE_RESET';
export const PROJECT_PROVIDER_REPLACE = 'PROJECT_PROVIDER_REPLACE';
export const PROJECT_MANAGER_REPLACE = 'PROJECT_MANAGER_REPLACE';
export const PROJECT_OUTSTANDING_COUNTS_FETCH = 'PROJECT_OUTSTANDING_COUNTS_FETCH';
