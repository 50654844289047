import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import SettingsLayout from 'settings/assets/js/SettingsLayout.jsx';
import { fetchSettingsDS, postSettingsDS, settingsResetAC, selectAccountSettings } from 'accounts/assets/js/ducks/account';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import LinkModal from 'core/assets/js/components/LinkModal.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import SettingsItem from 'core/assets/js/components/SettingsItem.jsx';
import { modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { changePasswordDS } from 'accounts/assets/js/data-services/password';
import PasswordChangeForm, { passwordChangeFormId } from 'accounts/assets/js/components/PasswordChangeForm.jsx';
import EmailForm, { emailFormId } from 'accounts/assets/js/components/EmailForm.jsx';

class AccountSettingsView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios }) {
    return dispatch(fetchSettingsDS(params, url, authedAxios));
  }

  static GetComponentName() {
    return 'AccountSettingsView';
  }

  // @todo: Remove me - that should not be needed due to the use of TDApiConnected
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(settingsResetAC());
  }

  render() {
    const { dispatch, settings } = this.props;

    return (
      <SettingsLayout title="Account">
        <TDApiConnected
          component={AccountSettingsView}
          duck="view"
          skeletonComponent={SettingsPageSkeleton}
        >
          <div className="settings-row mb-4">
            <h3 className="heading-block">Security & Login</h3>
            <SettingsItem
              disclaimer={'Changing your email here will affect your log in and account-wide notifications, if you are looking to just change your notification settings please go to your organisation notification settings via "Organisation > Settings > Notifications"'}
              label="Email"
              preview={settings.email}
              cta={(
                <LinkModal
                  modalId="change-email-modal"
                  modalOptions={{
                    heading: 'Change your Email',
                    body: (
                      <EmailForm
                        onSubmit={postSettingsDS}
                        onSubmitSuccess={() => dispatch(modalCloseAC())}
                        initialValues={{ email: settings.email }}
                      />
                    ),
                    footer: (
                      <TDButton
                        floatRight
                        type="submit"
                        variant={BS_STYLE.PRIMARY}
                        onClick={() => dispatch(submit(emailFormId))}
                        label="Update"
                      />
                    ),
                  }}
                />
              )}
            />
          </div>

          <hr />
          <SettingsItem
            disclaimer="Set a unique password to protect your personal account."
            label="Password"
            cta={(
              <LinkModal
                wrapperClasses="d-flex justify-content-center"
                modalId="change-password-modal"
                label={(
                  <TDButton
                    rounded
                    variant={BS_STYLE.PRIMARY}
                    btnIcon={ICON.EDIT}
                    label="Change Password"
                  />
                )}
                modalOptions={{
                  heading: 'Change your password',
                  body: (
                    <PasswordChangeForm
                      onSubmit={changePasswordDS}
                      onSubmitSuccess={() => dispatch(modalCloseAC())}
                      initialValues={{ email: settings.email }}
                    />
                  ),
                  footer: (
                    <TDButton
                      floatRight
                      type="submit"
                      variant={BS_STYLE.PRIMARY}
                      onClick={() => dispatch(submit(passwordChangeFormId))}
                      label="Save"
                    />
                  ),
                }}
              />
            )}
          />
        </TDApiConnected>
      </SettingsLayout>
    );
  }
}

AccountSettingsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return ({
    settings: selectAccountSettings(state).item,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ConnectedAccountSettingsView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettingsView);


export default ConnectedAccountSettingsView;
