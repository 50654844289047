import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import moment from 'moment';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import PlaygroundView from 'playground/assets/js/components/PlaygroundView.jsx';
import { SENTRY, NODE_ENV, ENV_PRODUCTION } from 'core/assets/js/config/settings';


const sentryInspect = () => {
  const isSentryEnabled = SENTRY.enabled;
  const isSentrySDKLoaded = typeof Sentry !== 'undefined';
  const isSentryConfigured = isSentryEnabled && isSentrySDKLoaded;
  const sentryProjectId = SENTRY.projectId;

  return {
    isSentryEnabled, isSentrySDKLoaded, isSentryConfigured, sentryProjectId,
  };
};

const SentryNotConfiguredError = () => {
  return (
    <>
      <h2>Sentry is not configured</h2>
      <p>
        Sentry is either not enabled or its SDK is not loaded.
      </p>
    </>
  );
};

const SentryCaptureMessage = () => {
  const [message, setMessage] = useState();
  const [eventId, setEventId] = useState();
  const {
    isSentryConfigured, sentryProjectId,
  } = sentryInspect();

  if (!isSentryConfigured) {
    return (
      <section id="sentry-capture-message">
        <SentryNotConfiguredError />
      </section>
    );
  }

  return (
    <section id="sentry-capture-message">
      <h2>Sentry handled errors - capturing messages</h2>
      <p>
        Click a button below to capture a message with different severity levels.
      </p>
      <p>
        Click
        <a
          href="https://docs.sentry.io/platforms/javascript/guides/react/usage/#capturing-messages"
          rel="noreferrer"
          target="_blanl"
        >
          {' here '}
        </a>
        to learn more about capturing messages.
      </p>
      <TDButton
        type="button"
        variant={BS_STYLE.DEFAULT}
        onClick={() => {
          const msg = `Capturing an INFO message (${moment()})`;
          const sentryEventId = Sentry.captureMessage(msg);
          setEventId(sentryEventId);
          setMessage(msg);
        }}
        label="Capture INFO message"
      />
      <TDButton
        type="button"
        variant={BS_STYLE.DANGER}
        onClick={() => {
          const msg = `Capturing an ERROR message (${moment()})`;
          const sentryEventId = Sentry.captureMessage(msg, 'error');
          setEventId(sentryEventId);
          setMessage(msg);
        }}
        label="Capture ERROR message"
      />
      <pre className="mt-5">
        {!message && (
          <p>No message captured</p>
        )}
        {eventId && (
          <div>
            <strong>Sentry eventId: </strong>
            {eventId}
          </div>
        )}
        {message && (
          <div>
            <strong>Message: </strong>
            {message}
            {' '}
            <a
              href={`https://sentry.io/organizations/talentdesk-io/issues/?project=${sentryProjectId}`}
              rel="noreferrer"
              target="_blank"
            >
              (Check the message on Sentry)
            </a>
          </div>
        )}
      </pre>
    </section>
  );
};

const SentryCaptureException = () => {
  const [error, setError] = useState();
  const [eventId, setEventId] = useState();
  const {
    isSentryConfigured, sentryProjectId,
  } = sentryInspect();

  if (!isSentryConfigured) {
    return (
      <section id="sentry-capture-exception">
        <SentryNotConfiguredError />
      </section>
    );
  }

  return (
    <section id="sentry-capture-exception">
      <h2>Sentry handled errors - capturing exceptions</h2>
      <p>
        Click a button below to capture an exception and show the the feedback dialog.
      </p>
      <p>
        Click
        <a
          href="https://docs.sentry.io/platforms/javascript/guides/react/usage/#capturing-errors"
          rel="noreferrer"
          target="_blank"
        >
          {' here '}
        </a>
        to learn more about capturing exceptions.
      </p>
      <TDButton
        type="button"
        variant={BS_STYLE.PRIMARY}
        onClick={() => {
          try {
            aNonExistentFn(); // eslint-disable-line
          } catch (err) {
            const sentryEventId = Sentry.captureException(err);
            setError(err);
            setEventId(sentryEventId);
          }
        }}
        label="Capture exception"
      />
      {eventId && (
        <TDButton
          type="button"
          onClick={() => {
            Sentry.showReportDialog();
          }}
          label="Show feedback dialog"
        />
      )}
      <pre className="mt-5">
        {!error && (
          <p>No error captured</p>
        )}
        {eventId && (
          <div>
            <strong>Sentry eventId: </strong>
            {eventId}
          </div>
        )}
        {error && (
          <div>
            <strong>Error message: </strong>
            {error.message}
            {' '}
            <a
              href={`https://sentry.io/organizations/talentdesk-io/issues/?project=${sentryProjectId}`}
              rel="noreferrer"
              target="_blank"
            >
              (Check the issue on Sentry)
            </a>
          </div>
        )}
      </pre>
    </section>
  );
};

const SentryUnhandledException = () => {
  const [error, setError] = useState('There is no error in the page.');
  const { isSentryConfigured } = sentryInspect();

  if (!isSentryConfigured) {
    return (
      <section id="sentry-unhandled-exception">
        <SentryNotConfiguredError />
      </section>
    );
  }

  return (
    <section id="sentry-unhandled-exception">
      <h2>Sentry unhandled exceptions</h2>
      <p>
        Click the `Break page` button to cause an exception which will be captured by the
        SentryErrorBoundary and reported to Sentry.
      </p>
      <p>
        Click
        <a
          href="https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/"
          rel="noreferrer"
          target="_blank"
        >
          {' here '}
        </a>
        to learn more about unhandled exceptions.
      </p>
      <TDButton
        type="button"
        variant={BS_STYLE.PRIMARY}
        onClick={() => {
          setError({ message: { text: 'A random error message' } });
        }}
        label="Break page"
      />
      <pre className="mt-5">
        <p>{error}</p>
      </pre>
    </section>
  );
};

const sections = {
  'Unhandled exceptions': SentryUnhandledException,
  'Capture message': SentryCaptureMessage,
  'Capture exception': SentryCaptureException,
};

const ListItemWithIcon = ({ isItemActive, activeText, inactiveText }) => {
  const icon = isItemActive ? ICON.CHECKMARK : ICON.CROSS;
  return (
    <div>
      <span className={icon} />
      &nbsp;
      {isItemActive && activeText}
      {!isItemActive && inactiveText}
    </div>
  );
};
ListItemWithIcon.propTypes = {
  isItemActive: PropTypes.bool.isRequired,
  activeText: PropTypes.string.isRequired,
  inactiveText: PropTypes.string.isRequired,
};

const ErrorTestbed = () => {
  const isSentryEnabled = SENTRY.enabled;
  const isSentrySDKLoaded = typeof Sentry !== 'undefined';
  return (
    <PlaygroundView
      playgroundName="errors-testbed"
      sections={sections}
      basePath="error-testbed"
      systemMessageTitle="Errors playground"
      systemMessageDescription={(
        <>
          <p>
            Test the Sentry integration and error handling via Sentry.
          </p>

          <hr />

          <p>
            Environment:
            &nbsp;
            <strong>{NODE_ENV}</strong>
            {NODE_ENV === ENV_PRODUCTION && (
              <>
                {' ('}
                <strong>Attention: </strong>
                Watch out when testing Sentry on production. All triggered errors
                will be captured on the production account and an a new sentry issue might be
                created)
              </>
            )}
          </p>

          <ListItemWithIcon
            isItemActive={isSentryEnabled}
            activeText="Sentry is enabled"
            inactiveText="Sentry is disabled. You will need to activate it via the relevant env settings."
          />
          <ListItemWithIcon
            isItemActive={isSentrySDKLoaded}
            activeText="Sentry SDK is loaded"
            inactiveText="Sentry is SDK is not loaded. Make sure the relevant npm package (@sentry/react) is installed."
          />
        </>
      )}
    />
  );
};

export default ErrorTestbed;
