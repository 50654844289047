import { FORM_ERROR } from 'final-form';
import { get, isEmpty, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, NavLink, Link } from 'react-router-dom';

import AllowAccess from 'core/assets/js/components/AllowAccess.jsx';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import ChangeOnboardingFormModal, { MODAL_ID as CHANGE_MODAL_ID } from 'interviews/assets/js/ChangeOnboardingFormModal.jsx';
import ResetOnboardingFormModal, { MODAL_ID as RESET_MODAL_ID } from 'interviews/assets/js/ResetOnboardingFormModal.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import ProviderCard from 'people/assets/js/components/ProviderCard.jsx';
import ReviewForm from 'interviews/assets/js/components/ReviewForm.jsx';
import SubmissionForm from 'interviews/assets/js/components/SubmissionForm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, USER_TYPE } from 'core/assets/js/constants';
import { SUBMISSION_STATUS } from 'interviews/assets/js/constants';
import { fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import {
  fetchSubmissionChangedDetailsDS, fetchSubmissionDS, postSubmissionDS, postSubmissionReviewDS,
} from 'interviews/assets/js/data-services/interviews';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { isCustomFieldPath, mapValuesToPostFields } from 'interviews/assets/js/lib/utils';
import { orgGetStartedUrl } from 'accounts/urls';
import { orgPeopleProvidersUrl, orgUserProfileUrl } from 'people/urls';
import { organizationsUrl } from 'organizations/urls';
import { refreshAccountDS } from 'accounts/assets/js/data-services/account';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { submissionSpec } from 'interviews/assets/js/lib/objectSpecs';
import { submissionUrl } from 'interviews/urls';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

class SubmissionView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const promises = [dispatch(fetchSubmissionDS({ authedAxios, componentName, params, url }))];
    if (params.submissionId) {
      promises.push(dispatch(fetchSubmissionChangedDetailsDS({
        authedAxios,
        componentName,
        orgAlias: params.orgAlias,
        submissionId:
        params.submissionId, url,
      })));
    }
    return Promise.all(promises);
  }

  static GetComponentName({ params } = {}) {
    if (!params || !params.submissionId) {
      return 'SubmissionView';
    }
    return `SubmissionView-${params.submissionId}`;
  }

  componentDidUpdate() {
    const { submission, params, history } = this.props;
    if (submission.id && !params.submissionId) {
      // if we have just created a submission, move to the submission edit url
      history.push(
        submissionUrl(params.orgAlias, submission.id),
      );
    }
  }

  getReturnToUrl() {
    const { params, location } = this.props;
    const nextUrl = location.search ? queryString.parse(location.search).next : null;
    return nextUrl || orgPeopleProvidersUrl(params.orgAlias);
  }

  render() {
    const {
      activeUserCard,
      changedDetails,
      changeModalIsOpen,
      dispatch,
      history,
      params,
      submission,
      location,
    } = this.props;
    const { orgAlias } = params;
    const submissionReason = submission.reason;
    const allowedActions = submission.allowedActions || {};
    const inviterFullName = get(submission, 'manager.user.profile.name');
    const inviterUserId = get(submission, 'manager.user.id');
    const providerUserRole = get(submission, 'provider.userRole');
    const isAnyManager = get(submission, 'provider.userRole.isAnyManager');
    const ownerUserId = get(submission, 'provider.user.id');
    const isOwner = ownerUserId && ownerUserId === activeUserCard.user.id;
    // Check if is coming from get started page
    let fromGetStarted = false;
    if (history?.location?.state?.fromGetStarted) {
      fromGetStarted = true;
    }

    const breadcrumbs = [
      {
        title: 'Onboarding form',
        url: null,
      },
    ];

    const componentName = this.constructor.GetComponentName({ params });
    const reasonPrefix = isOwner ? 'Your' : 'This user\'s';
    // inviter link should be shown when user visiting the onboarding form is a manager
    // or a provider and the onboarding form is not yet finalized.
    const showInviterProfileLink = !(
      !isEmpty(providerUserRole)
      && providerUserRole.isProvider
      && isOwner
      && !allowedActions.hasBeenFinalized
    );

    const ctaButtonItems = [];

    if (allowedActions.canChangeOnboardingForm) {
      ctaButtonItems.push({
        label: 'Change form',
        onClick: () => dispatch(modalOpenAC(CHANGE_MODAL_ID)),
        variant: BS_STYLE.PRIMARY,
      });
    }

    if (allowedActions.canReset) {
      ctaButtonItems.push({
        label: 'Reset form',
        onClick: () => dispatch(modalOpenAC(RESET_MODAL_ID)),
        variant: BS_STYLE.PRIMARY,
      });
    }

    const closeModal = () => dispatch(modalCloseAC());

    const isProvider = activeUserCard?.userRole?.isProvider;

    return (
      <React.Fragment key={location.key}>
        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

        <div className="page page--interviews">
          <div className="container">
            <TDApiConnected duck="view" component={this.constructor}>
              {allowedActions.canChangeOnboardingForm && (
                <ChangeOnboardingFormModal
                  isOpen={changeModalIsOpen}
                  onClose={closeModal}
                  orgAlias={orgAlias}
                  parentComponentName={componentName}
                  submission={submission}
                />
              )}
              {allowedActions.canReset && (
                <ResetOnboardingFormModal orgAlias={orgAlias} submissionId={submission.id} />
              )}
              {isEmpty(submission) && (
                <TDSystemMessage
                  className="mb-5"
                  title="Could not find that onboarding submission"
                  type={BS_STYLE.WARNING}
                >
                  {changedDetails?.newInterviewSubmissionId && (
                    <>
                      {isProvider && (
                        <>
                          {`${changedDetails.managerName} changed your onboarding form. You can `}
                          {'complete the new one '}
                          <Link
                            to={submissionUrl(orgAlias, changedDetails.newInterviewSubmissionId)}
                          >
                            here
                          </Link>
                          .
                        </>
                      )}
                      {!isProvider && (
                        <>
                          {`${changedDetails.managerName} changed `}
                          {`${changedDetails.targetUserName}'s onboarding form from `}
                          {`"${changedDetails.oldInterviewName}" to `}
                          {`"${changedDetails.newInterviewName}". You can find the new submission `}
                          <Link
                            to={submissionUrl(orgAlias, changedDetails.newInterviewSubmissionId)}
                          >
                            here
                          </Link>
                          .
                        </>
                      )}
                    </>
                  )}
                  {!changedDetails?.newInterviewSubmissionId && (
                    <>
                      {isProvider && (
                        <>
                          We could not find that onboarding submission. Your onboarding form may
                          have been changed. You can complete your current onboarding form from the
                          {' '}
                          <Link to={orgGetStartedUrl(orgAlias)}>get started page</Link>
                          .
                        </>
                      )}
                      {!isProvider && (
                        <>
                          We could not find that onboarding submission. The user&lsquo;s onboarding
                          form may have been changed. You can view their current onboarding
                          submission from the
                          {' '}
                          <Link to={orgPeopleProvidersUrl(orgAlias)}>team page</Link>
                          .
                        </>
                      )}
                    </>
                  )}
                </TDSystemMessage>
              )}
              {submission.status === SUBMISSION_STATUS.APPROVED && (
                <TDSystemMessage
                  type={BS_STYLE.SUCCESS}
                  className="mb-5"
                  title="Onboarding form approved"
                >
                  <p>
                    {`${reasonPrefix} onboarding form has been approved.`}
                    { submissionReason && (
                      <span>{" Here is the reviewer's comment:"}</span>
                    )}
                  </p>
                  { submissionReason && (
                    <blockquote>{submissionReason}</blockquote>
                  )}
                  { !isOwner && (
                    <p>
                      The user is now a member of your organisation at TalentDesk.io!
                    </p>
                  )}
                </TDSystemMessage>
              )}
              {submission.status === SUBMISSION_STATUS.DRAFT && (
                <TDSystemMessage
                  className="mb-5"
                  title={isOwner ? 'Onboarding form not submitted!' : 'Pending onboarding form!'}
                  type={BS_STYLE.WARNING}
                >
                  {isOwner && (
                    <p>
                      Once you are finished with the onboarding form, please submit it, so you can
                      be accepted as a provider.
                    </p>
                  )}
                  {!isOwner && (
                    <p>The provider has not submitted the onboarding form yet.</p>
                  )}
                </TDSystemMessage>
              )}
              {submission.status === SUBMISSION_STATUS.REJECTED && (
                <TDSystemMessage
                  type={BS_STYLE.DANGER}
                  className="mb-5"
                  title="Onboarding form rejected"
                >
                  <p>
                    {`${reasonPrefix} onboarding form has been rejected.`}
                    { submissionReason && (
                      <span>{" Here is the reviewer's comment:"}</span>
                    )}
                  </p>
                  { submissionReason && (
                    <blockquote>{submissionReason}</blockquote>
                  )}
                  {!isOwner && (
                    <p>
                      The user was not granted access to your organisation.
                      {allowedActions.canReopen && (
                        <React.Fragment>
                          You may re-evaluate them at any time by clicking on the
                          {' '}
                          <span className="font-weight-bolder">Reopen onboarding form</span>
                          {' '}
                          link below.
                        </React.Fragment>
                      )}
                    </p>
                  )}
                </TDSystemMessage>
              )}
              { isOwner && allowedActions.isBeingReviewed && (
                <TDSystemMessage
                  type={BS_STYLE.INFO}
                  className="mb-5"
                  title="Onboarding form being reviewed"
                >
                  <p>
                    The Onboarding form has been submitted.
                    We will review it and contact you soon. Thank you!
                  </p>
                </TDSystemMessage>
              )}
              { !allowedActions.canSubmit && submission.status === SUBMISSION_STATUS.AMEND && (
                <TDSystemMessage
                  className="mb-5"
                  title="Onboarding form being amended"
                >
                  <p>
                    The Onboarding form is being amended by the provider.
                    We will inform you when they have submitted it. Thank you!
                  </p>
                </TDSystemMessage>
              )}
              { !!inviterUserId && (
                <Card className="mb-4">
                  <Card.Body>
                    Invited by
                    {' '}
                    { showInviterProfileLink
                      ? (
                        <Link
                          to={orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, inviterUserId)}
                        >
                          {inviterFullName}
                        </Link>
                      ) : inviterFullName
                    }
                  </Card.Body>
                </Card>
              )}
              { !isOwner && allowedActions.canView && (
                <div className="mb-5">
                  { isAnyManager
                    ? (
                      <ManagerCard
                        withActions={false}
                        orgAlias={orgAlias}
                        item={submission.provider}
                        showContactDetails
                      />
                    ) : (
                      <ProviderCard
                        withActions={false}
                        orgAlias={orgAlias}
                        item={submission.provider}
                        showContactDetails
                      />
                    )
                  }
                </div>
              )}

              { allowedActions.canSubmit && (
                <SubmissionForm
                  onCancel={() => history.push(this.getReturnToUrl())}
                  onSubmit={async values => {
                    const toSubmit = mapValuesToPostFields(values);
                    try {
                      const { submission: updated } = await dispatch(postSubmissionDS(
                        { orgAlias, submissionId: submission.id }, toSubmit, componentName,
                      ));
                      // Update get started steps
                      await dispatch(fetchSetupStepsDS());
                      if (
                        isOwner
                      && allowedActions.isBeingEdited
                      && !updated.allowedActions.isBeingEdited
                      ) {
                      // refetch account to update user status, homeUrl etc.
                        await Promise.all([
                          dispatch(refreshAccountDS()).then(({ auth }) => {
                            history.push({
                              pathname: auth.userProfile.homeUrl,
                              state: { moveToNextStep: true },
                            });
                          }),
                        ]);
                      } else if (isOwner) {
                        history.push({
                          pathname: orgGetStartedUrl(orgAlias),
                          state: { moveToNextStep: true },
                        });
                      }
                      return null;
                    } catch (error) {
                      return error.errors || { [FORM_ERROR]: error._error || error.message };
                    }
                  }}
                  fromGetStarted={fromGetStarted}
                  submission={submission}
                />
              )}

              {(!allowedActions.canSubmit && (
                allowedActions.canView || allowedActions.canReview
              )) && (
                <div className="interview-review content-box rounded-bottom shadow-sm p-4 bg-white">
                  <ReviewForm
                    {...submission}
                    canReopen={submission.allowedActions.canReopen}
                    canReview={submission.allowedActions.canReview}
                    onSubmit={({ reopen, reject, reason, ...values }) => {
                      const toSubmit = {
                        errors: pickBy(values, (value, key) => isCustomFieldPath(key)),
                        reject,
                        reopen,
                        reason,
                      };
                      return dispatch(postSubmissionReviewDS({
                        orgAlias, submissionId: submission.id,
                      }, toSubmit, componentName)).then(() => {
                        history.push(this.getReturnToUrl());
                      });
                    }}
                    orgAlias={orgAlias}
                    parentComponentName={componentName}
                  />
                </div>
              )}
              { !(allowedActions.canSubmit && allowedActions.isBeingEdited) && (
                <div className="mt-4">
                  <AllowAccess oneOf={[USER_TYPE.PROVIDER]}>
                    <NavLink to={organizationsUrl(orgAlias)} className="btn btn-lg btn-primary">
                      Back to organizations
                    </NavLink>
                  </AllowAccess>
                  <AllowAccess oneOf={[USER_TYPE.MANAGER]}>
                    <NavLink to={this.getReturnToUrl()} className="btn btn-lg btn-default">
                      Back to teams
                    </NavLink>
                  </AllowAccess>
                </div>
              )}
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SubmissionView.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  changedDetails: PropTypes.object,
  changeModalIsOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.shape({
    orgAlias: PropTypes.string,
    submissionId: PropTypes.string,
  }).isRequired,
  submission: submissionSpec.isRequired,
};

SubmissionView.defaultProps = {
  changedDetails: null,
};

const mapStateToProps = (state, props) => {
  const componentName = SubmissionView.GetComponentName(props.match);
  return {
    activeUserCard: selectActiveUserCard(state),
    changedDetails: getViewStateExtras(state, componentName, 'changedDetails'),
    changeModalIsOpen: getIsModalOpen(state, CHANGE_MODAL_ID),
    params: props.match.params,
    submission: getViewState(state, componentName).item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const SubmissionViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmissionView);

export default withRouter(SubmissionViewConnect);
