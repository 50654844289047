
import { ORDERING_DIRECTION, USER_CARD_STATUS } from 'core/assets/js/constants';
import { STATUS as INVITATION_STATUS } from 'invitations/assets/js/constants';

export const PEOPLE_TYPE = {
  PROVIDERS: 'providers',
  MANAGERS: 'managers',
};

export const USER_EMPLOYMENT_TYPE = {
  CONTRACTOR: 0,
  EMPLOYEE: 1,
};

export const USER_EMPLOYMENT_TYPE_LABEL = {
  [USER_EMPLOYMENT_TYPE.CONTRACTOR]: 'contractor',
  [USER_EMPLOYMENT_TYPE.EMPLOYEE]: 'employee',
};

export const INCORPORATION_TYPE = {
  INCORPORATED: 1,
  NOT_INCORPORATED: 0,
};

export const INCORPORATION_TYPE_LABEL = {
  [INCORPORATION_TYPE.INCORPORATED]: 'incorporated',
  [INCORPORATION_TYPE.NOT_INCORPORATED]: 'not incorporated',
};

export const PEOPLE_DEFAULT_ORDERING = {
  direction: ORDERING_DIRECTION.DESC,
  sortBy: 'joined_at',
};

export const ORG_INVITEES_STATUS_DEFAULT_FILTER = [
  INVITATION_STATUS.ACCEPTED,
  INVITATION_STATUS.CANCELLED,
  INVITATION_STATUS.PENDING,
  INVITATION_STATUS.REJECTED,
];

export const REVIEW_REQUEST_DAYS_INTERVAL = 5;

export const MAX_USER_GROUPS = 100;

export const MAX_SKILL_DIRECTORIES = 100;
export const MAX_SKILL_DIRECTORY_SKILLS = 200;

export const PEOPLE_INVITE_STEP = {
  USER_TYPE: 'user_type',
  ADD: 'add',
  DETAILS: 'details',
  ESIGN_COUNTERSIGNERS: 'select-contract-signers',
  ESIGN_INVITING_MANAGER: 'inviting-manager-sign',
  DONE: 'done',
};

export const PEOPLE_PROVIDER_INVITE_STEP_LABEL = {
  [PEOPLE_INVITE_STEP.ADD]: 'Add providers',
  [PEOPLE_INVITE_STEP.DETAILS]: 'Details',
  [PEOPLE_INVITE_STEP.ESIGN_COUNTERSIGNERS]: 'Select contract signers',
  [PEOPLE_INVITE_STEP.ESIGN_INVITING_MANAGER]: 'Customization',
};

export const PEOPLE_MANAGER_INVITE_STEP_LABEL = {
  [PEOPLE_INVITE_STEP.ADD]: 'Add managers',
  [PEOPLE_INVITE_STEP.DETAILS]: 'Details',
  [PEOPLE_INVITE_STEP.ESIGN_COUNTERSIGNERS]: 'Select contract signers',
  [PEOPLE_INVITE_STEP.ESIGN_INVITING_MANAGER]: 'Customization',
};

export const PEOPLE_INVITATION_WORDINGS = {
  [PEOPLE_TYPE.PROVIDERS]: {
    submit: 'Invite Providers',
    header: 'Add providers',
    subHeader: 'Invite your Providers to TalentDesk.io',
    messageSublabel: 'Welcome your providers with a personalised message',
    instantApproval: 'Pre-approve the providers and fast-track their vetting process. You should only tick this box if you know or have worked with them before.',
    defaultMessage: 'Hi,\n\nWe would like to invite you to TalentDesk.io - a freelance management tool where we can collaborate on all our freelance projects together. Please join us - it only takes 2 minutes!',
    addPopoverContent: 'To invite multiple providers at once you can copy and paste a comma concatenated list of their emails.',
    emailInvalid: 'Please provide emails of your providers',
    userTypeDescription: 'A Provider invited as a Contractor receives regular reminders by TalentDesk.io to create Worksheets for the projects they work on. Based on the approved Worksheets TalentDesk.io generates invoices on their behalf either bi-weekly or monthly.',
  },
  [PEOPLE_TYPE.MANAGERS]: {
    submit: 'Invite Managers',
    header: 'Add managers',
    subHeader: 'Invite your Managers to TalentDesk.io',
    messageSublabel: 'Welcome your managers with a personalised message',
    instantApproval: 'Pre-approve the managers and fast-track their vetting process. You should only tick this box if you know or have worked with them before.',
    defaultMessage: 'Hi,\n\nI\'d like to invite you to TalentDesk.io - a freelance management tool we are using to manage all our freelance projects with our contingent workforce. Please join us - it takes 2 minutes!',
    addPopover: 'To invite multiple managers at once you can copy and paste a comma concatenated list of their emails.',
    emailInvalid: 'Please provide emails of your managers',
    userTypeDescription: 'A Provider invited as a Contractor receives regular reminders by TalentDesk.io to create Worksheets for the projects they work on. Based on the approved Worksheets TalentDesk.io generates invoices on their behalf either bi-weekly or monthly.',
  },
};

export const HOW_IT_WORKS_MODAL_ID = 'how-it-works-talent-market';

export const DISCOVER_TALENTS_TABS = {
  FAVOURITES: 'favourites',
  TALENTS: 'talents',
  MESSAGES: 'messages',
};

export const CREATE_CHAT_MODAL_ID = 'create-chat-modal-id';
export const ADD_PARTICIPANTS_MODAL_ID = 'add-particpants-modal-id';
export const PARTICIPANTS_LIST_MODAL_ID = 'particpants-list-modal-id';

export const CHAT_ALLOWED_USER_CARD_STATUS = [
  USER_CARD_STATUS.PENDING,
  USER_CARD_STATUS.PENDING_APPROVAL,
  USER_CARD_STATUS.AMEND_REQUESTED,
  USER_CARD_STATUS.APPROVED,
  USER_CARD_STATUS.PENDING_MANAGER_ANSWERS,
];

export const PROFILE_VIEW_COMPONENT_NAME = 'ProfileView';

export const CREATE_GROUP_TYPE = {
  SELECT: 'select',
  STANDARD: 'standard',
  DYNAMIC: 'dynamic',
};

export const USER_GROUP_AVATAR_MAX_WIDTH = 480;
