// Views
import NotificationsView from 'notifier/assets/js/NotificationsView.jsx';
import { notificationListUrl } from 'notifier/urls';

const notifierRouteConfig = [
  {
    path: notificationListUrl(':orgAlias'),
    requireUserIsApprovedOrgMember: false,
    component: NotificationsView,
    strict: false,
    exact: true,
    layout: 'LayoutDefault',
    requireAuth: true,
  },
];

export default notifierRouteConfig;
