import React from 'react';
import PropTypes from 'prop-types';

import { ICON, MIME_TYPES } from 'core/assets/js/constants';

const FilePreview = ({ className, mimeType, url }) => {
  const isDocument = MIME_TYPES.DOCUMENTS.includes(mimeType);
  const isImage = MIME_TYPES.IMAGES.includes(mimeType);
  const isVideo = MIME_TYPES.VIDEOS.includes(mimeType);

  if (isDocument) {
    return (
      <span className={className}>
        <i className={ICON.FILE_DOCUMENT} />
      </span>
    );
  }

  if (isImage && url) {
    return (
      <span className={className}>
        <img src={url} alt="Uploaded file" />
      </span>
    );
  }

  if (isVideo) {
    return (
      <span className={className}>
        <i className={ICON.VIDEO} />
      </span>
    );
  }

  return (
    <span className={className}>
      <i className={ICON.INFO} />
    </span>
  );
};

FilePreview.propTypes = {
  className: PropTypes.string,
  mimeType: PropTypes.string,
  url: PropTypes.string,
};

FilePreview.defaultProps = {
  className: '',
  mimeType: null,
  url: null,
};

export default FilePreview;
