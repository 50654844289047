/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as projectDS from 'projects/assets/js/data-services/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import TaskCard from 'projects/assets/js/components/TaskCard.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import ScrollToTopOnMount from 'core/assets/js/components/ScrollToTopOnMount.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TaskSearch from 'projects/assets/js/components/TaskSearch.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { fetchMyTaskListDS } from 'projects/assets/js/data-services/tasks';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import { paginationSpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectMyTaskAssigneesCsvApiUrl, projectMyTasksCsvApiUrl } from 'projects/urls';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';


class MyTasksListView extends React.Component {
  static FetchData({ dispatch, params, url, querystring, componentName, authedAxios }) {
    return dispatch(
      fetchMyTaskListDS({
        url,
        params,
        querystring,
        componentName,
        authedAxios,
        dispatch,
      }),
    );
  }

  static GetComponentName() {
    return 'MyTasksListView';
  }

  constructor(props) {
    super(props);
    this.handleProviderInvitationUpdated = this.handleProviderInvitationUpdated.bind(this);
  }

  handleProviderInvitationUpdated() {
    const { dispatch, match: { params } } = this.props;
    dispatch(projectDS.fetchProjectProvidersDS({
      orgAlias: params.orgAlias, id: params.id,
    }));
  }

  render() {
    const {
      customFieldFilters,
      filtersOpen,
      isManager,
      location,
      match: { params: { orgAlias } },
      onFiltersToggle,
      pagination,
      tasks,
    } = this.props;

    const breadcrumbs = [
      {
        title: 'My tasks',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ScrollToTopOnMount />

        <ContentHeader
          breadcrumbs={breadcrumbs}
        />

        <div className="page page--project">
          <div className="container">
            <div className="row">
              <TaskSearch
                onFiltersToggle={onFiltersToggle}
                filtersOpen={filtersOpen}
                isManager={isManager}
                customFieldFilters={customFieldFilters}
              />

              {!filtersOpen && (
                <ListActionsButton
                  options={[
                    {
                      name: 'Export tasks',
                      onClick: () => {
                        WINDOW_OPEN(`${projectMyTasksCsvApiUrl(orgAlias)}${location.search}`);
                      },
                    },
                    {
                      name: 'Export task assignees',
                      onClick: () => {
                        WINDOW_OPEN(
                          `${projectMyTaskAssigneesCsvApiUrl(orgAlias)}${location.search}`,
                        );
                      },
                    },
                  ]}
                />
              )}
            </div>

            <TDApiConnected
              duck="list"
              component={this.constructor}
              blockingLoading
              skeletonComponent={ProjectCardSkeleton}
            >
              <div className="tasks-list">
                { !filtersOpen && (
                  <TDList
                    items={tasks}
                    pagination={pagination}
                    emptyListMessage="No tasks found."
                    cardItem={{
                      component: TaskCard,
                      props: {
                        orgAlias,
                      },
                    }}
                  />
                )}
              </div>
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MyTasksListView.propTypes = {
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  isManager: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  tasks: PropTypes.arrayOf(projectTaskSpec).isRequired,
};

MyTasksListView.defaultProps = {
  isManager: false,
  customFieldFilters: [],
};
const mapStateToProps = (state) => {
  const componentName = MyTasksListView.GetComponentName();
  const listState = getListState(state, componentName);
  const hasOrgAccess = getHasOrgAccess(state);

  return {
    tasks: listState.items,
    pagination: listState.pagination,
    isManager: hasOrgAccess({ requireManager: true }),
    customFieldFilters: getListStateExtras(state, componentName, 'customFieldFilters'),
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const MyTasksListViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyTasksListView);

export default withRouter(withFilters(MyTasksListViewConnected));
