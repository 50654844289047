import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';

import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import {
  BS_STYLE, USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS, USER_CARD_DEACTIVATE_COUNT_ITEMS,
} from 'core/assets/js/constants';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import {
  PROJECT_MEMBER_STATUS,
  PROJECT_MEMBER_STATUS_CLASS,
  PROJECT_MEMBER_STATUS_LABEL,
  PROJECT_STATUS_CLASS,
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_STATUS_CLASS,
  PURCHASE_ORDER_STATUS_LABEL,
  SERVICE_ORDER_STATUS,
  SERVICE_ORDER_STATUS_CLASS,
  SERVICE_ORDER_STATUS_LABEL,
  TASK_STATUS_CLASS,
} from 'projects/assets/js/constants';
import { projectViewTaskUrl, projectViewUrl } from 'projects/urls';

const ManagerDeactivateReview = ({ orgAlias, outstandingCounts, userName, managers }) => {
  const hasOutstandingItems = Object.keys(outstandingCounts).some(key => (
    outstandingCounts[key].length > 0
  ));
  const pendingItems = [];
  let hasInProgressProjects = false;
  let hasInProgressTasks = false;
  if (hasOutstandingItems) {
    [
      USER_CARD_DEACTIVATE_COUNT_ITEMS.EXPENSES,
      USER_CARD_DEACTIVATE_COUNT_ITEMS.PRO_FORMA_INVOICES,
      USER_CARD_DEACTIVATE_COUNT_ITEMS.WORKSHEETS,
    ].forEach(type => {
      if (outstandingCounts[type].length === 0) {
        return;
      }
      pendingItems.push({
        items: outstandingCounts[type].map(so => {
          const toStatus = so.statusCode === SERVICE_ORDER_STATUS.APPROVED
            ? SERVICE_ORDER_STATUS.VOID
            : SERVICE_ORDER_STATUS.CANCELLED;
          return {
            name: type === USER_CARD_DEACTIVATE_COUNT_ITEMS.PRO_FORMA_INVOICES
              ? `${so.invoiceReferenceNumber || `#${so.id}`}`
              : [
                so.projectTitle,
                so.projectReference ? ` (${so.projectReference})` : '',
                ` ${so.period}`,
              ].join(''),
            statusFrom: (
              <StatusColFormat
                statusLabel={so.status}
                statusClass={SERVICE_ORDER_STATUS_CLASS[so.statusCode]}
              />
            ),
            statusTo: <StatusColFormat
              statusLabel={SERVICE_ORDER_STATUS_LABEL[toStatus]}
              statusClass={SERVICE_ORDER_STATUS_CLASS[toStatus]}
            />,
          };
        }),
        name: USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS[type],
      });
    });
    if (outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.PURCHASE_ORDERS].length > 0) {
      pendingItems.push({
        items: outstandingCounts[
          USER_CARD_DEACTIVATE_COUNT_ITEMS.PURCHASE_ORDERS
        ].map(purchaseOrder => {
          const toStatus = PURCHASE_ORDER_STATUS.CANCELLED;
          return {
            name: [
              purchaseOrder.projectTitle,
              purchaseOrder.projectReference ? ` (${purchaseOrder.projectReference})` : '',
            ].join(''),
            statusFrom: (
              <StatusColFormat
                statusLabel={purchaseOrder.statusLabel}
                statusClass={PURCHASE_ORDER_STATUS_CLASS[purchaseOrder.status]}
              />
            ),
            statusTo: <StatusColFormat
              statusLabel={PURCHASE_ORDER_STATUS_LABEL[toStatus]}
              statusClass={PURCHASE_ORDER_STATUS_CLASS[toStatus]}
            />,
          };
        }),
        name: USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS[
          USER_CARD_DEACTIVATE_COUNT_ITEMS.PURCHASE_ORDERS
        ],
      });
    }
    if (outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECT_MEMBERSHIPS].length > 0) {
      pendingItems.push({
        items: outstandingCounts[
          USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECT_MEMBERSHIPS
        ].map(project => {
          const fromStatus = PROJECT_MEMBER_STATUS.ACCEPTED;
          const toStatus = PROJECT_MEMBER_STATUS.REMOVED;
          return {
            name: `${project.title}${project.reference ? ` (${project.reference})` : ''}`,
            statusFrom: (
              <StatusColFormat
                statusLabel={PROJECT_MEMBER_STATUS_LABEL[fromStatus]}
                statusClass={PROJECT_MEMBER_STATUS_CLASS[fromStatus]}
              />
            ),
            statusTo: <StatusColFormat
              statusLabel={PROJECT_MEMBER_STATUS_LABEL[toStatus]}
              statusClass={PROJECT_MEMBER_STATUS_CLASS[toStatus]}
            />,
          };
        }),
        name: USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS[
          USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECT_MEMBERSHIPS
        ],
      });
    }
    hasInProgressProjects = outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECTS].length > 0;
    hasInProgressTasks = outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.TASKS].length > 0;
  }
  const { values } = useFormState();
  return (
    <>
      {hasOutstandingItems && (
        <TDSystemMessage title="All pending items will be auto resolved" type={BS_STYLE.WARNING}>
          {`All of the items that belong to ${userName} will have their status changed `}
          automatically as in below table.
        </TDSystemMessage>
      )}
      {!hasOutstandingItems && (
        <>
          <p>
            {'Are you sure you want to deactivate manager '}
            <strong>{userName}</strong>
            {' in this organisation?'}
          </p>
          <p>
            They will no longer be able to access the organisation, or any of its
            content.
          </p>
          <p>You can re-activate them at any time, which will restore their access.</p>
        </>
      )}
      {pendingItems.map(({ items, name }) => (
        <Table
          containerClass="mt-5"
          cols={[
            { key: 'name', label: name },
            { key: 'statusFrom', label: 'Status (from)' },
            { key: 'statusTo', label: 'Status (to)' },
          ]}
          items={items}
          key={name}
        />
      ))}
      {hasInProgressProjects && (
        <Table
          containerClass="mt-5"
          cols={[
            {
              dataFormat: (title, project) => (
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  to={projectViewUrl(orgAlias, project.id)}
                >
                  {title}
                </Link>
              ),
              key: 'title',
              label: 'Project',
            },
            {
              dataFormat: (statusLabel, project) => (
                <StatusColFormat
                  statusLabel={statusLabel}
                  statusClass={PROJECT_STATUS_CLASS[project.status]}
                />
              ),
              key: 'status_label',
              label: 'Status',
            },
            {
              dataFormat: (budget, project) => (
                <NumberTpl prefix={project.currency_symbol} value={budget || 0} />
              ),
              key: 'budget',
              label: 'Budget',
            },
            {
              dataFormat: (_, project) => {
                const manager = managers.find(m => (
                  m.value === values[`project_${project.id}`][0].value
                ));
                return manager ? manager.text : '';
              },
              key: 'managers',
              label: 'New owner',
            },
          ]}
          items={outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECTS]}
        />
      )}
      {hasInProgressTasks && (
        <Table
          containerClass="mt-5"
          cols={[
            {
              dataFormat: (title, task) => (
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  to={projectViewTaskUrl(orgAlias, task.projectId, task.id)}
                >
                  {title}
                </Link>
              ),
              key: 'title',
              label: 'Task',
            },
            {
              dataFormat: (statusLabel, task) => (
                <StatusColFormat
                  statusLabel={statusLabel}
                  statusClass={TASK_STATUS_CLASS[task.status]}
                />
              ),
              key: 'statusLabel',
              label: 'Status',
            },
            {
              dataFormat: (_, task) => {
                const manager = managers.find(m => (
                  m.value === values[`task_${task.id}`][0].value
                ));
                return manager ? manager.text : '';
              },
              key: 'managers',
              label: 'New owner',
            },
          ]}
          items={outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.TASKS]}
        />
      )}
      <TextAreaField className="mt-5" label="Reason" name="reason" required />
    </>
  );
};

ManagerDeactivateReview.propTypes = {
  orgAlias: PropTypes.string.isRequired,
  outstandingCounts: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  managers: PropTypes.array.isRequired,
};

export default ManagerDeactivateReview;
