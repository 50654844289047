import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { ICON } from 'core/assets/js/constants';
import { download1099FilingPDF } from 'finance/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const UserUSTaxFilingsList = ({ className, usTaxFilings }) => {
  const activeOrg = useSelector(selectActiveOrg);
  if (!Array.isArray(usTaxFilings) || usTaxFilings.length === 0) {
    return null;
  }
  return (
    <Card className={`user-us-tax-filings-list${className ? ` ${className}` : ''}`}>
      <Card.Header>1099 forms</Card.Header>
      <Card.Body className="d-flex flex-column">
        {usTaxFilings.map(f => (
          <div className="d-flex justify-content-between align-items-center w-100 py-3" key={f.id}>
            <div className="d-flex">
              <div className="icon mr-4">
                <i className={`${ICON.FILE_DUOTONE} fa-2xl`} />
                <span>1099</span>
              </div>
              <span>{f.year}</span>
            </div>
            <div>
              <TDButton
                btnIcon={ICON.DOWNLOAD}
                label="Download"
                onClick={() => download1099FilingPDF(activeOrg.alias, f.id)}
                showIconOnLeft
              />
            </div>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

UserUSTaxFilingsList.propTypes = {
  className: PropTypes.string,
  usTaxFilings: PropTypes.arrayOf(PropTypes.object),
};

UserUSTaxFilingsList.defaultProps = {
  className: null,
  usTaxFilings: [],
};

export default UserUSTaxFilingsList;
