import React from 'react';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const PeopleListSkeleton = () => (
  <div className="skeleton--settings-page">
    <div className="skeleton--settings-page-item clearfix">
      <RectangleSkeleton width="300" height="25" />
      <br />
      <br />
      <RectangleSkeleton width="183" height="15" />
      <RectangleSkeleton width="100" height="15" />
      <br />
      <br />
      <RectangleSkeleton width="183" height="15" />
      <RectangleSkeleton width="150" height="15" />
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
);

export default PeopleListSkeleton;
