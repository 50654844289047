import React from 'react';
import PropTypes from 'prop-types';

import { TEXT_AREA_DEFAULT_HEIGHT } from 'core/assets/js/constants';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import MarkdownTextArea from 'core/assets/js/components/MarkdownTextArea.jsx';
import RichTextArea from 'core/assets/js/components/RichTextArea.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';


const TextAreaField = ({
  additionalError,
  'data-testid': dataTestId,
  className,
  disabled,
  extraInfo,
  height,
  input,
  label,
  maxLength,
  mdEditorEnabled,
  meta: { error, pristine, submitError, touched },
  placeholder,
  previewFunction,
  required,
  richTextEditorEnabled,
  sublabel,
  templateSelectComponent,
}) => {
  const classNames = ['form-group position-relative'];

  const showAdditionalError = pristine && additionalError;
  const hasError = ((error || submitError) && touched) || showAdditionalError;

  if (hasError) {
    classNames.push('has-error');
  }

  if (className) {
    classNames.push(className);
  }

  if (mdEditorEnabled && richTextEditorEnabled) {
    throw Error('You can only use markdown OR rich text');
  }

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <div className={templateSelectComponent ? 'd-block d-sm-flex align-items-end' : ''}>
        <div>
          <TDLabel
            name={input.name}
            label={label}
            required={required}
            sublabel={sublabel}
            extraInfo={extraInfo}
          />
        </div>
        { templateSelectComponent && (
          <div className="ml-auto text-right mb-2">
            {templateSelectComponent}
          </div>
        )}
      </div>
      {showAdditionalError && (
        <span className="help-block mt-3">{additionalError}</span>
      )}
      {!mdEditorEnabled && !richTextEditorEnabled && (
        <textarea
          autoComplete="off"
          className="form-control"
          disabled={disabled}
          id={`field-${input.name}`}
          maxLength={maxLength}
          placeholder={placeholder}
          {...input}
        />
      )}

      {mdEditorEnabled && (
        <MarkdownTextArea
          height={height}
          id={`field-${input.name}`}
          input={input}
          maxLength={maxLength}
          placeholder={placeholder}
          previewFunction={previewFunction}
          {...input}
        />
      )}

      {richTextEditorEnabled && (
        <RichTextArea
          id={`field-${input.name}`}
          maxLength={maxLength}
          onChange={input.onChange}
          value={input.value}
        />
      )}

      { hasError && (
        <span className="help-block d-inline-block mt-3">
          {error || submitError}
        </span>
      )}
    </div>
  );
};

TextAreaField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  extraInfo: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  maxLength: PropTypes.number,
  mdEditorEnabled: PropTypes.bool,
  meta: finalFormFieldMetaSpec,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  previewFunction: PropTypes.func,
  required: PropTypes.bool,
  richTextEditorEnabled: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
  templateSelectComponent: PropTypes.node,
};

TextAreaField.defaultProps = {
  additionalError: '',
  className: null,
  'data-testid': 'text-area-field',
  disabled: false,
  extraInfo: null,
  height: TEXT_AREA_DEFAULT_HEIGHT,
  label: '',
  maxLength: null,
  mdEditorEnabled: false,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  placeholder: '',
  prefix: null,
  previewFunction: null,
  required: false,
  richTextEditorEnabled: false,
  sublabel: null,
  suffix: null,
  templateSelectComponent: null,
};

export default withField(TextAreaField);
