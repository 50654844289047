import React from 'react';
import { Field } from 'redux-form';

import SelectField from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import { bankAccountTypeOptions, bankAccountSubTypeOptions } from 'core/assets/js/lib/bankAccountDetailOptions';

const AccountTypeAndSubType = () => (
  <div className="row">
    <div className="col-12 col-sm-6">
      <Field
        name="account_type"
        type="text"
        optionsMapping={bankAccountTypeOptions}
        component={SelectField}
        label="Account Type"
      />
    </div>
    <div className="col-12 col-sm-6">
      <Field
        name="account_subtype"
        type="text"
        optionsMapping={bankAccountSubTypeOptions}
        component={SelectField}
        label="Account Subtype"
      />
    </div>
  </div>
);

export default AccountTypeAndSubType;
