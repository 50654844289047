import PropTypes from 'prop-types';
import React from 'react';
import TDButton from 'core/assets/js/components/TDButton.jsx';

import ContactSalesModal from 'core/assets/js/components/ContactSalesModal.jsx';
import CountdownTimer from 'core/assets/js/components/CountdownTimer.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import { BS_STYLE, BS_SIZE, ICON, DATE_FORMAT_NO_YEAR } from 'core/assets/js/constants';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formatDate } from 'core/assets/js/lib/utils';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { getViewState } from 'core/assets/js/ducks/view';
import {
  selectActiveSubscription,
  selectHasActiveSubscription,
  selectIsOnTrial,
  selectIsTrialExpired,
  selectSubscriptions,
  selectSubscriptionsLoaded,
  selectTrialSubscription,
} from 'organizations/assets/js/reducers/subscriptions';
import { withRouter } from 'react-router-dom';

const PlanStatusView = ({
  activeSubscription,
  areSubscriptionsLoaded,
  hasActiveSubscription,
  hasOrgAccess,
  isOnTrial,
  isTrialExpired,
  modalState,
  trialSubscription,
}) => {
  const isAnyManager = hasOrgAccess({
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  });

  const trialExpirationDate = (
    !isTrialExpired && trialSubscription && (
      trialSubscription.trial_expiration_date
    )
  );

  const createdAt = !isTrialExpired && trialSubscription && trialSubscription.created_at;
  const timeBetweenTrial = new Date(trialExpirationDate).getTime() - new Date(createdAt).getTime();
  const trialPeriod = Math.floor(timeBetweenTrial / (1000 * 60 * 60 * 24)) + 1;

  if (!areSubscriptionsLoaded) {
    return (
      <div className="page page--plan-status">
        <div className="container text-center">
          <Card className="card card--plan-status text-center">
            <Card.Body className="flex-column">
              <i>Loading...</i>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page page--plan-status">
        <div className="container">
          <Card className="card card--plan-status text-center">
            <Card.Body className="flex-column">
              <div className="col-12 col-lg-6 offset-lg-3">
                {activeSubscription && !isOnTrial && (
                  <>
                    <i className={`card__icon mb-4 ${ICON.CHECKMARK_DUOTONE}`} />
                    <h1>Your organisation is on an active subscription</h1>
                  </>
                )}

                {!isTrialExpired && !activeSubscription && !isOnTrial && (
                  <>
                    <i className={`card__icon mb-4 ${ICON.BAN}`} />
                    <h1>Your organisation&apos;s subscriptions has expired!</h1>
                    <p className="mb-4">
                      Upgrade to keep using TalentDesk.
                    </p>
                    <TDButton
                      className="mb-0 mt-2"
                      variant={BS_STYLE.PRIMARY}
                      onClick={modalState.open}
                    >
                      I am ready to upgrade
                    </TDButton>
                  </>
                )}

                {
                  !isTrialExpired
                  && trialSubscription
                  && isOnTrial
                  && (
                    <>
                      <CountdownTimer
                        trialPeriod={trialPeriod}
                        expiresAt={trialExpirationDate}
                      />
                      <h1>Your organisation is on a free trial!</h1>
                      <p className="mb-4">
                        To help you get started on TalentDesk, your team is on a free trial,
                        through to
                        {' '}
                        <strong>
                          {formatDate(trialExpirationDate, DATE_FORMAT_NO_YEAR)}
                        </strong>
                        {'. '}
                        Upgrade anytime to keep using TalentDesk when your trial&nbsp;ends.
                      </p>
                      <TDButton
                        className="mb-0 mt-2"
                        variant={BS_STYLE.PRIMARY}
                        onClick={modalState.open}
                      >
                        I am ready to upgrade
                      </TDButton>
                    </>
                  )
                }

                {isTrialExpired && isOnTrial && isAnyManager && (
                  <>
                    <i className={`card__icon mb-4 ${ICON.HOURGLASS_END_DUOTONE}`} />
                    <h1>Your organisation free trial has expired!</h1>
                    <p className="mb-4">
                      Your trial has expired! In order to continue using TalentDesk you need to
                      {' '}
                      choose on of our plans that work best for you.
                    </p>
                    <TDButton
                      className="mb-0 mt-2"
                      variant={BS_STYLE.PRIMARY}
                      onClick={modalState.open}
                    >
                      I am ready to upgrade
                    </TDButton>
                  </>
                )}

                {isTrialExpired && !isAnyManager && (
                  <>
                    <i className={`card__icon mb-4 ${ICON.HOURGLASS_END_DUOTONE}`} />
                    <h1>Your organisation free trial has expired!</h1>
                    <p className="mb-3"><strong>Please contact your administrator to upgrade the plan!</strong></p>
                    <TDButton
                      className="mb-2 mt-2"
                      variant={BS_STYLE.PRIMARY}
                    >
                      Notify administrator
                    </TDButton>
                    <p>
                      <small>
                        (We will send an email to administrator about upgrading the plan)
                      </small>
                    </p>
                  </>
                )}

                {isTrialExpired && activeSubscription && !hasActiveSubscription && isAnyManager && (
                  <TDButton
                    className="mb-0 mt-2"
                    onClick={modalState.open}
                    variant={BS_STYLE.PRIMARY}
                  >
                    Upgrade plan
                  </TDButton>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <ModalSimple
        heading="Contact sales"
        open={modalState.isOpen}
        onClose={modalState.close}
        noFooter
        size={BS_SIZE.LARGE}
        body={(
          <ContactSalesModal />
        )}
      />
    </React.Fragment>
  );
};

PlanStatusView.propTypes = {
  activeSubscription: PropTypes.object,
  areSubscriptionsLoaded: PropTypes.bool,
  hasActiveSubscription: PropTypes.bool.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  isTrialExpired: PropTypes.bool,
  isOnTrial: PropTypes.bool.isRequired,
  modalState: modalStateSpec.isRequired,
  trialSubscription: PropTypes.object,
};
PlanStatusView.defaultProps = {
  activeSubscription: null,
  areSubscriptionsLoaded: false,
  isTrialExpired: null,
  trialSubscription: null,
};

PlanStatusView.GetComponentName = () => 'PlanStatusView';

const mapStateToProps = (state) => {
  const viewState = getViewState(state, PlanStatusView.GetComponentName());
  return {
    activeSubscription: selectActiveSubscription(state),
    areSubscriptionsLoaded: selectSubscriptionsLoaded(state),
    hasActiveSubscription: selectHasActiveSubscription(state),
    hasOrgAccess: getHasOrgAccess(state),
    isOnTrial: selectIsOnTrial(state),
    isTrialExpired: selectIsTrialExpired(state),
    subscriptions: selectSubscriptions(state),
    sysOrg: viewState.extras.sysOrg,
    trialSubscription: selectTrialSubscription(state),
    userCard: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PlanStatusViewConnected = connect(mapStateToProps, mapDispatchToProps)(PlanStatusView);

export default withRouter(withStateModal(PlanStatusViewConnected));
