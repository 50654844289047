import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  WINDOW_ADD_EVENT_LISTENER, WINDOW_INNER_WIDTH, WINDOW_REMOVE_EVENT_LISTENER,
} from 'core/assets/js/config/settings';
import {
  BOOTSTRAP_BREAKPOINTS, PEOPLE_LIST_VIEW_TYPE_KEY, VIEW_TYPES,
} from 'core/assets/js/constants';
import { setPeopleListViewTypeAC } from 'core/assets/js/ducks/settings';
import Storage from 'core/assets/js/lib/Storage';

const PeopleListViewTypeProvider = () => {
  const dispatch = useDispatch();

  const update = newValue => dispatch(setPeopleListViewTypeAC(newValue));

  const forceGridViewOnMobile = e => {
    let currentWidth = WINDOW_INNER_WIDTH;

    if (e) {
      currentWidth = e.target.window.innerWidth;
    }

    if (currentWidth < BOOTSTRAP_BREAKPOINTS.EXTRA_LARGE) {
      update(VIEW_TYPES.GRID);
    }
  };

  useEffect(() => {
    const storageValue = Storage.get(PEOPLE_LIST_VIEW_TYPE_KEY);
    if (Object.values(VIEW_TYPES).includes(storageValue)) {
      update(storageValue);
    }

    forceGridViewOnMobile();
    WINDOW_ADD_EVENT_LISTENER('resize', forceGridViewOnMobile);

    return () => WINDOW_REMOVE_EVENT_LISTENER('resize', forceGridViewOnMobile);
  }, []);

  return null;
};

export default PeopleListViewTypeProvider;
