import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { viewFetchExtrasAC } from 'core/assets/js/ducks/view';
import { statusApiUrl } from 'services/urls';

// TODO - remove lint disable once more functions are added
/* eslint-disable import/prefer-default-export */
/**
 * Request service statuses.
 * Returns a object with SERVICE_STATUS_SERVICE keys, and SERVICE_STATUS_CONDITION values.
 * @param {object} param0 - component name and axios.
 * @return {Promise<any>} completion promise.
 */
export const fetchServiceStatusDS = ({
  componentName, authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => statusApiUrl(),
    componentName,
    fetchDataAC: responseData => [
      viewFetchExtrasAC(responseData, componentName, 'serviceStatus'),
    ],
  })
);
