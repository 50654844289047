import PropTypes from 'prop-types';
import React from 'react';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { ICON, ORDERING_DIRECTION } from 'core/assets/js/constants';

const orderingOptions = [
  { text: 'Created at', value: 'created_at' }, { text: 'Name', value: 'name' },
];
const defaultOrdering = { direction: ORDERING_DIRECTION.ASC, sortBy: 'name' };

export const UserGroupsListSearch = ({
  className,
  extraSearchElement,
  filtersKey,
  filtersOpen,
  initialValues,
  onFiltersChanged,
  onFiltersToggle,
  queryParams,
}) => (
  <SearchFinalForm
    className={className}
    extraSearchElement={extraSearchElement}
    filtersKey={filtersKey}
    filtersOpen={filtersOpen}
    initialValues={{
      kw: initialValues.kw,
      ordering: initialValues.ordering || defaultOrdering,
    }}
    onFiltersChanged={onFiltersChanged}
    onFiltersToggle={onFiltersToggle}
    searchSpec={{
      defaultOrdering,
      filters: [{ // Note: Ordering filter is only shown on mobile devices.
        fieldComponent: SelectableListFilterField,
        isOrdering: true,
        label: 'Sort By',
        multiple: false,
        options: [['Created at', 'created_at'], ['Name', 'name']].reduce(
          (acc, [label, sortBy]) => {
            [
              [ICON.SORTING_ASC, ORDERING_DIRECTION.ASC],
              [ICON.SORTING_DESC, ORDERING_DIRECTION.DESC],
            ].forEach(([icon, direction]) => {
              acc.push({
                text: (
                  <span>
                    {`${label} `}
                    <i className={icon} />
                  </span>
                ),
                value: JSON.stringify({ direction, sortBy }),
              });
            });
            return acc;
          },
          [],
        ),
        paramName: 'ordering',
      }],
      orderingOptions,
      searchTerm: {
        component: TextInputField,
        paramName: 'kw',
        placeholder: 'Search groups',
      },
    }}
    query={queryParams}
  />
);

UserGroupsListSearch.propTypes = {
  className: PropTypes.string,
  extraSearchElement: PropTypes.element,
  filtersKey: PropTypes.string,
  filtersOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  onFiltersChanged: PropTypes.func,
  onFiltersToggle: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
};

UserGroupsListSearch.defaultProps = {
  className: 'px-0 mt-5',
  extraSearchElement: null,
  filtersKey: null,
  initialValues: {},
  onFiltersChanged: null,
  queryParams: null,
};

export default withFilters(UserGroupsListSearch);
