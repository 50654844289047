/* global __webpack_hash__ */
import exenv from 'exenv';
import { NODE_ENV, ENV_DEVELOPMENT, ENV_TEST, PROCESS_MODE, HEALTH_MODE, IS_CLIENT } from 'core/assets/js/config/settings';

export function isFront() {
  return IS_CLIENT;
}

export const isReactTest = (PROCESS_MODE === HEALTH_MODE) || (NODE_ENV === ENV_TEST && isFront());
export const isSSR = !exenv.canUseDOM && !isReactTest;
export const hasWebpackHash = NODE_ENV === ENV_DEVELOPMENT && typeof __webpack_hash__ !== 'undefined';
export const webpackHash = () => hasWebpackHash ? __webpack_hash__ : ''; //eslint-disable-line

export function assertIsFront() {
  if (!isFront()) {
    throw new Error('This code can only used at the frontend');
  }
}

export function assertIsBack() {
  if (isFront() && NODE_ENV !== ENV_TEST && PROCESS_MODE !== HEALTH_MODE) {
    throw new Error('This code can only used at the backend');
  }
}
