import React from 'react';
import PropTypes from 'prop-types';
import {
  STATUS as INVITATION_STATUS,
  STATUS_LABEL as INVITATION_STATUS_LABEL,
  STATUS_CLASS as INVITATION_STATUS_CLASS,
} from 'invitations/assets/js/constants';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';


const InvitationStatus = ({ status, hasExpired, reason }) => {
  if (status === INVITATION_STATUS.ACCEPTED) {
    return null;
  }

  const label = hasExpired ? 'Expired' : INVITATION_STATUS_LABEL[status];

  return (
    <React.Fragment>
      <div className={['onboarding-status mx-n4', `onboarding-status--${INVITATION_STATUS_CLASS[status]}`].join(' ')}>
        <StatusTag
          hideTitle
          statusClass={INVITATION_STATUS_CLASS[status]}
          label={label}
          reason={reason}
        />
      </div>
    </React.Fragment>
  );
};

InvitationStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(INVITATION_STATUS)),
  hasExpired: PropTypes.bool,
  reason: PropTypes.string,
};

InvitationStatus.defaultProps = {
  status: INVITATION_STATUS.ACCEPTED,
  hasExpired: false,
  reason: null,
};

export default InvitationStatus;
