import React from 'react';
import { Card } from 'react-bootstrap';

import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const TalentWidgetSkeleton = () => (
  <div className="skeleton__dashboard-widget">
    <div className="skeleton__dashboard-widget__header mb-5 clearfix">
      <div className="float-left">
        <RectangleSkeleton width="150" height="30" />
      </div>
      <div className="float-right">
        <RectangleSkeleton width="170" height="30" />
      </div>
    </div>
    <div className="skeleton--people-list row mx-0">
      {[...Array(8).keys()].map((e) => (
        <div className="col-6 col-lg-3 pl-0 mb-3" key={e.toString()}>
          <Card className="user-item">
            <Card.Body className="pb-0">
              <div className="d-flex">
                <CircleSkeleton className="mr-3" diameter="48" />
                <div className="overflow-hidden">
                  <div className="freelancer-item__title text-left">
                    <RectangleSkeleton height="24" width="120" />
                  </div>
                  <div className="freelancer-widget__job">
                    <RectangleSkeleton height="16" width="60" />
                  </div>
                  <div className="mt-2 row">
                    <RectangleSkeleton
                      className="mr-1"
                      height="24"
                      width="50"
                    />
                    <RectangleSkeleton
                      className="mr-1"
                      height="24"
                      width="60"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="freelancer-item__footer">
              <div className="row">
                <div className="col-7 pr-0">
                  <RectangleSkeleton
                    className="user-item__small"
                    height="16"
                    width="50"
                  />
                </div>
                <div className="col-5 text-right pl-0">
                  <RectangleSkeleton height="24" width="70" />
                </div>
              </div>
            </Card.Footer>
          </Card>
        </div>
      ))}
    </div>
    <div className="skeleton__dashboard-widget__footer mt-5 clearfix">
      <div className="float-right">
        <RectangleSkeleton width="120" height="20" />
      </div>
    </div>
  </div>
);

export default TalentWidgetSkeleton;
