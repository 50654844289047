import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const InvoicedLabel = () => (
  <>
    Invoiced
    <ElementWithPopOver
      className="d-inline-block ml-2"
      popOverContent={(
        `Your worksheet, expense, or proforma invoice has been invoiced to the organization. Once
        we have received payment from the organisation we will begin processing your payment.`
      )}
      popOverTitle="Invoiced"
    />
  </>
);

export default InvoicedLabel;
