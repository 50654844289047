import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ICON } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { ADD_WORKSHEET_ITEM_MODAL_ID } from 'projects/assets/js/components/WorksheetAddItemPanel.jsx';
import { getInvoiceCapUsage } from 'projects/assets/js/data-services/view';

const WorksheetFormPlaceholder = ({ info: { missingFields }, saveChangesToLocalStorage }) => {
  const dispatch = useDispatch();
  const { usageAt100 } = useSelector(getInvoiceCapUsage);
  const activeOrg = useSelector(selectActiveOrg);

  const classNames = [
    'd-flex',
    'flex-column',
    'align-items-center',
    'justify-content-center',
    'worksheet-form__empty-text',
  ];

  const usedInvoiceCap = usageAt100 && !activeOrg.invoice_caps_allow_raising_beyond_cap;

  return (
    <div
      className={classNames.join(' ')}
      data-testid="worksheet-form-empty-text"
    >
      {usedInvoiceCap && (
        <>
          <i className={`${ICON.HAND_DUOTONE} mb-4`} />
          You can&apos;t submit new Worksheets for the current period
        </>
      )}
      {!usedInvoiceCap && (
        <>
          <i className={`${ICON.CLOCK_SOLID} mb-4`} />
          What did you work on this week?
          <br />
          { !(missingFields && missingFields.length > 0) && (
            <a
              className="imitate-link"
              onClick={() => {
                dispatch(modalOpenAC(
                  ADD_WORKSHEET_ITEM_MODAL_ID,
                  { onModalClose: () => saveChangesToLocalStorage() },
                ));
              }}
            >
              Add task or project work
            </a>
          )}
        </>
      )}
    </div>
  );
};

WorksheetFormPlaceholder.propTypes = {
  info: PropTypes.object,
  saveChangesToLocalStorage: PropTypes.func.isRequired,
};

WorksheetFormPlaceholder.defaultProps = {
  info: null,
};

export default WorksheetFormPlaceholder;
