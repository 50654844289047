import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import DocumentAssignmentsListItem from 'core/assets/js/components/DocumentAssignmentsListItem.jsx';

/**
 * Renders a list of documentAttachments
 * @param {Object} props
 * @param {String} props.heading The label of the documentAttachments
 * @param {Object[]} props.documentAttachments
 */
const DocumentAssignmentsList = ({ documentAttachments, heading }) => {
  if (isEmpty(documentAttachments)) {
    return null;
  }

  return (
    <>
      {heading && (<h4>{heading}</h4>)}
      <div>
        { documentAttachments.map(documentAttachment => (
          <DocumentAssignmentsListItem documentAssignment={documentAttachment} />
        ))}
      </div>
    </>
  );
};

DocumentAssignmentsList.propTypes = {
  documentAttachments: PropTypes.array,
  heading: PropTypes.string,
};

DocumentAssignmentsList.defaultProps = {
  documentAttachments: [],
  heading: 'Required Documents',
};

export default DocumentAssignmentsList;
