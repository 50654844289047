import React from 'react';

import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { timelineItemSpec } from 'core/assets/js/lib/objectSpecs';

const TimelineItem = ({ item }) => {
  const {
    author,
    content,
    footer,
    header,
    variant,
    timestamp,
    timestampTitle,
  } = item;
  const footerClass = [
    'timeline-item__footer',
    `timeline-item__footer--${variant}`,
    'px-3 mx-0 d-flex justify-content-between align-items-center',
  ];

  return (
    <div className={`timeline-item timeline-item--${variant}`}>
      <div className={`timeline-item__header ${header ? '' : 'p-0'}`}>
        {header}
      </div>

      <div className="timeline-item__body">
        {author && <div className="timeline-item__author">{author}</div>}
        {content}
      </div>

      <div className={`${footerClass.join(' ')} ${footer ? '' : 'p-0'}`}>
        {footer}
        {timestamp && (
          <TDElementWithTooltip tooltipMsg={timestampTitle}>
            <time>{timestamp}</time>
          </TDElementWithTooltip>
        )}
      </div>
    </div>
  );
};

TimelineItem.propTypes = {
  item: timelineItemSpec.isRequired,
};

export default TimelineItem;
