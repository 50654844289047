import React from 'react';
import { map, pick } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { managerBudgetSpec, managerBudgetTotalsSpec } from 'finance/assets/js/lib/objectSpecs';
import { BUDGETS_TABS } from 'finance/assets/js/constants';
import { USER_CARD_STATUS, USER_CARD_STATUS_LABEL } from 'core/assets/js/constants';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import {
  fetchManagersBudgetListDS,
} from 'finance/assets/js/data-services/list';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import SearchForm from 'core/assets/js/components/SearchForm.jsx';
import SelectableListFilterField from 'core/assets/js/components/FilterFields/SelectableListFilterField.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import ManagersBudgetTable from './components/ManagersBudgetTable.jsx';
import BudgetsHeader from './components/BudgetsHeader.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';

const statusOptions = Object.keys(USER_CARD_STATUS).map(status => ({
  text: USER_CARD_STATUS_LABEL[USER_CARD_STATUS[status]],
  value: USER_CARD_STATUS[status],
}));

const SEARCH_SPEC = {
  filters: [{
    fieldComponent: SelectableListFilterField,
    label: 'Status',
    multiple: true,
    options: statusOptions,
    paramName: 'status',
  }],
  searchTerm: {},
};

class ManagersBudgetView extends React.Component {
  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    return Promise.all([
      dispatch(fetchManagersBudgetListDS({ url, querystring, componentName, params, authedAxios })),
    ]);
  }

  static GetComponentName() {
    return 'ManagersBudgetView';
  }

  renderTotals() {
    const { managersBudgetsTotals, managersBudgets } = this.props;
    const currency = managersBudgets && managersBudgets.length && managersBudgets[0].currency
      ? managersBudgets[0].currency
      : '';
    return (
      <div className="finance-table__summary">
        <div style={{ width: 'auto' }} className="d-none d-xl-inline-block">
          <span>Total</span>
        </div>
        <div style={{ width: '150px' }}>
          <div className="d-block d-xl-none">
            Total Budget
          </div>
          <span><NumberTpl prefix={currency} value={managersBudgetsTotals.approved} /></span>
        </div>
        <div style={{ width: '190px' }}>
          <div className="d-block d-xl-none">
            Total Allocated Projects
          </div>
          <span>
            <NumberTpl prefix={currency} value={managersBudgetsTotals.allocatedToProjects} />
          </span>
        </div>
        <div style={{ width: '190px' }}>
          <div className="d-block d-xl-none">
            Total Deallocated from projects
          </div>
          <span>
            <NumberTpl prefix={currency} value={managersBudgetsTotals.deallocatedFromProjects} />
          </span>
        </div>
        <div style={{ width: '125px' }}>
          <div className="d-block d-xl-none">
            Total Balance
          </div>
          <span><NumberTpl prefix={currency} value={managersBudgetsTotals.available} /></span>
        </div>
      </div>
    );
  }

  render() {
    const {
      managersBudgets,
      filtersOpen,
      onFiltersToggle,
      location,
    } = this.props;

    const initialValues = pick(
      queryString.parse(location.search), map(SEARCH_SPEC.filters, 'paramName'),
    );

    const emptyText = 'No manager budgets found';
    return (
      <React.Fragment>
        <BudgetsHeader tab={BUDGETS_TABS.MANAGER_BUDGET} />

        <div className="page page--finance">
          <div className="container">
            <div className="row">
              <SearchForm
                filtersOpen={filtersOpen}
                name="project-budget"
                initialValues={initialValues}
                onFiltersToggle={onFiltersToggle}
                searchSpec={SEARCH_SPEC}
              />
            </div>

            {!filtersOpen && (
              <TDApiConnected duck="list" component={this.constructor} skeletonComponent={FinanceTableSkeleton}>
                <div className="finance-report">
                  <div className="finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
                    <ManagersBudgetTable managers={managersBudgets || []} emptyText={emptyText} />
                    <div className="finance-report__totals">
                      {this.renderTotals()}
                    </div>
                  </div>
                </div>
              </TDApiConnected>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ManagersBudgetView.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  managersBudgets: PropTypes.arrayOf(managerBudgetSpec),
  managersBudgetsTotals: managerBudgetTotalsSpec,
  searchActive: PropTypes.bool.isRequired,
};

ManagersBudgetView.defaultProps = {
  managersBudgets: [],
  managersBudgetsTotals: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ManagersBudgetView.GetComponentName());
  return {
    managersBudgets: listState.items,
    managersBudgetsTotals: listState.extras.totals,
    searchActive: listState.search.isActive,
  };
};

export default withRouter(withFilters(connect(mapStateToProps)(ManagersBudgetView)));
