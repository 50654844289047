import urlLib from 'url';

import { loginUrl, logoutUrl } from 'accounts/urls';
import { USER_CARD_STATUS } from 'core/assets/js/constants';
import { checkPermissionOnUserCard } from 'organizations/assets/js/lib/utils';
import { PERMISSIONS_VALUES } from 'roles/assets/js/constants';

// import Logger from 'core/assets/js/lib/Logger';

// const logger = new Logger('accessControl');

class AccessControl {
  /**
   * Control if access should be granted based on the authentication status
   * of the user and their role in the organization
   *
   * The role requirements can be combined so that access can be given in
   * a flexible way to everyone of finController and orgCreator only or
   * providers and managers only or any possible combination.
   *
   * @param userCard Object coming from the redux state that contains info
   *                  about the role of the logged in user in the active organization
   * @param userCard
   * @param isAuthenticated
   * @param requirements
   *          requireManager
   *          requireProvider
   *          requireFinController
   *          requireOrgCreator
   *          requireUserIsApprovedOrgMember
   * @returns {boolean}
   */
  static hasAccess(userCard = {}, isAuthenticated, requirements = {}) {
    const { requireAuth, requireUserIsApprovedOrgMember } = requirements;

    const {
      requireFinController,
      requireLowerManager,
      requireManager,
      requireOrgCreator,
      requireProvider,
    } = requirements;

    const role = userCard.userRole || {};

    const { isProvider, isOrgCreator, isHigherManager, isAnyManager } = role;
    const { status } = userCard;

    const isApproved = status === USER_CARD_STATUS.APPROVED;
    const specificRoleRequired = (
      requireFinController
      || requireLowerManager
      || requireManager
      || requireOrgCreator
      || requireProvider
    );
    const anyRoleRequired = specificRoleRequired || requireUserIsApprovedOrgMember;
    const shouldBeAuthenticated = requireAuth || anyRoleRequired;

    const assertAccess = () => {
      // give access to public pages to everyone
      if (!shouldBeAuthenticated) {
        return true;
      }

      // block every guest from accessing all other pages
      if (!isAuthenticated) {
        throw new Error('User is not authenticated');
      }

      // here, all users are authenticated

      // give access to any authorized user if no role is required
      if (!anyRoleRequired) {
        return true;
      }

      // if any role is required, the user must be approved
      if (!isApproved) {
        throw new Error('User is not approved');
      }

      // give access to *any* user that has a valid user card in this org
      if (!specificRoleRequired) {
        return true;
      }

      // here, match a specific role requirement
      if (requireProvider && isProvider) {
        return true;
      }
      if (requireLowerManager && isAnyManager && !isHigherManager) {
        return true;
      }
      if (requireManager && isAnyManager) {
        return true;
      }
      if (requireFinController && isHigherManager) {
        return true;
      }
      if (requireOrgCreator && isOrgCreator) {
        return true;
      }

      throw new Error('User does not match any required role');
    };

    try {
      const outcome = assertAccess();
      return outcome;
    } catch (e) {
      // these are not actual errors, just return false to hasAccess
      // logger.log(e);
      // logger.log('[auth]', { requirements, userCard, isAuthenticated });
      return false;
    }
  }

  /**
   * Checks if a user has all provided permissions
   *
   * @param {Object} activeUserCard
   * @param {String|String[]} permissionsIn
   * @returns {Boolean}
   */
  static hasPermissions(activeUserCard, permissionsIn) {
    const permissions = (Array.isArray(permissionsIn) ? permissionsIn : [permissionsIn]).reduce(
      (acc, permission) => {
        if (PERMISSIONS_VALUES.includes(permission)) {
          acc.push(permission);
        }
        return acc;
      },
      [],
    );
    const permissionsLength = permissions.length;
    for (let i = 0; i < permissionsLength; i += 1) {
      if (!checkPermissionOnUserCard(activeUserCard, permissions[i])) {
        return false;
      }
    }
    return true;
  }

  /**
   * This method decides whether the user is authorized to view
   * a specific route or if we should redirect him to his home url.
   *
   * It decides to:
   *   1. Render the children components if the user needs not switch active organization and
   *      is authorized to view the children
   *   2. Switch the active organization in the redux store if the user requests a view of a
   *      different organization, and is actually authorized to
   *   3. Redirect in any other case to the home Url
   *
   * @param isAuthenticated whether the user is authenticated
   * @param userCardActive The userCard of the currently selected organization
   * @param userCards A list of all user's userCards
   * @param targetOrgAlias The alias of the target org in case the system needs to switch to that
   * @param requirements The user role requirements of the matched route
   * @param skipOrgSwitch Optional option. Skips org redirect check
   *
   * @returns {{
   *   targetOrgAlias,
   *   requirements, The user role requirements of the matched route
   *   shouldRenderChildren: boolean, Whether the AuthWrapper should render the children
   *   isAuthorizedToAccessOrg: boolean,
   *   shouldRedirect: boolean,
   *   activeOrgAlias: *,
   *   shouldSwitchOrg: boolean
   * }}
   */
  static decideAuthState(
    { isAuthenticated, userCardActive, userCards } = {}, // state variables
    { targetOrgAlias, requirements = {} } = {}, // route variables
    { skipOrgSwitch } = { skipOrgSwitch: false }, // options
  ) {
    const {
      requireAuth,
      requireFinController,
      requireLowerManager,
      requireManager,
      requireOrgCreator,
      requirePermission,
      requireProvider,
      requireUserIsApprovedOrgMember,
    } = requirements;

    const orgActive = userCardActive ? userCardActive.organization : {};
    const activeOrgAlias = orgActive.alias;

    // Check if the organization requested is the active one, or whether we should switch.
    const shouldSwitchToAnotherOrg = (
      (!!targetOrgAlias && isAuthenticated && activeOrgAlias !== targetOrgAlias)
      && !skipOrgSwitch
    );

    const shouldSwitchToNoOrg = !targetOrgAlias && !!activeOrgAlias;
    // Check if the user is a member of the target organization
    const userCard = shouldSwitchToAnotherOrg && Array.isArray(userCards)
      ? userCards.find(o => o.organization.alias === targetOrgAlias)
      : userCardActive;
    // Check if user can view the current active organization
    let isAuthorizedToAccessOrg = AccessControl.hasAccess(userCard, isAuthenticated, {
      requireAuth,
      requireFinController,
      requireLowerManager,
      requireManager,
      requireOrgCreator,
      requireProvider,
      /*
        There are cases where we need the user to be able to view a page without an active role
        in the organization (ie. submission forms, where the user hasn't been accepted yet)
        in that case we need to make sure we requireAuth but we don't require any role.
        Otherwise, if we should switch org, then we need at least one role in the target org.
        Only exception is when requireUserIsApprovedOrgMember is explicitly false, meaning we can
        access this org without being a member yet (e.g. in submission views)
      */
      requireUserIsApprovedOrgMember: (
        requireUserIsApprovedOrgMember
        || (shouldSwitchToAnotherOrg && requireUserIsApprovedOrgMember !== false)
      ),
    });

    if (
      userCardActive?.user?.id // We have loaded the active user card
      && isAuthorizedToAccessOrg
      && !this.hasPermissions(userCardActive, requirePermission)
    ) {
      isAuthorizedToAccessOrg = false;
    }

    // IMPORTANT:
    // 1. If the AuthWrapper is instructed to switch org using a redirect, it **must** not render
    // the children or the children will access an outdated snapshot of redux store!
    // 2. AuthWrapper should render if the user should not switch and is authorized to view data of
    // active organization
    const shouldRenderChildren = isAuthorizedToAccessOrg && !shouldSwitchToAnotherOrg;
    const shouldRedirect = !isAuthorizedToAccessOrg;

    const res = {
      activeOrgAlias,
      isAuthorizedToAccessOrg,
      requirements,
      shouldRedirect,
      shouldRenderChildren,
      shouldSwitchOrg: (shouldSwitchToAnotherOrg && isAuthorizedToAccessOrg) || shouldSwitchToNoOrg,
      targetOrgAlias,
    };

    return res;
  }

  static determineHomeUrl(
    // state variables
    { isAuthenticated, profile } = {},
    { fromUrl, forceLogout = false } = {},
  ) {
    let method = 'history';
    let homeUrl = loginUrl();

    // if we should not render, issue a redirect to the default home url
    if (!isAuthenticated) {
      homeUrl = loginUrl(fromUrl);
    } else if (forceLogout) {
      homeUrl = logoutUrl(fromUrl);
      method = 'window';
    } else if (profile && profile.homeUrl && fromUrl !== profile.homeUrl) {
      homeUrl = profile.homeUrl;
    } else {
      homeUrl = logoutUrl(fromUrl);
      method = 'window';
    }

    if (
      // we cannot resolve the home url
      !homeUrl
      // home url is the same as from url, so we are stuck in an infinite loop
      || (fromUrl && urlLib.parse(homeUrl).pathname === urlLib.parse(fromUrl).pathname)
    ) {
      homeUrl = logoutUrl(fromUrl);
      method = 'window';
    }

    // user has no access to their home url, something is wrong with their state
    // so, logout and redirect them to login, to set up their state properly
    return { homeUrl, method };
  }
}

export default AccessControl;
