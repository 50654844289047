import React from 'react';

import { worksheetItemSpec, projectSpec } from 'projects/assets/js/lib/objectSpecs';
import WorksheetProjectWorkItemCard from 'projects/assets/js/components/WorksheetProjectWorkItemCard.jsx';
import WorksheetTaskItemCard from 'projects/assets/js/components/WorksheetTaskItemCard.jsx';

const WorksheetItemCard = ({ project, item }) => {

  const renderWarning = () => {
    if (!item?.warning) {
      return null;
    }
    if (!Array.isArray(item.warning)) {
      return <div key={item.warning} className="worksheet-item-card__reason text-warning">{item.warning}</div>;
    }
    return item.warning.map(warningText =>
      <div key={warningText} className="worksheet-item-card__reason text-warning">{warningText}</div>,
    );
  };

  return (
    <div className="worksheet-item-card">
      {item && item.task_id && (
        <WorksheetTaskItemCard item={item} project={project} />
      )}
      {(!item || !item.task_id) && (
        <WorksheetProjectWorkItemCard item={item} project={project} />
      )}
      {renderWarning()}
    </div>
  );
};

WorksheetItemCard.propTypes = {
  item: worksheetItemSpec.isRequired,
  project: projectSpec.isRequired,
};

export default WorksheetItemCard;
