import { INVOICE_STATUS } from 'finance/assets/js/constants';
import { SERVICE_ORDER_STATUS } from 'projects/assets/js/constants';

export const confirmedIsGreen = serviceOrder => (
  [
    SERVICE_ORDER_STATUS.APPROVED,
    SERVICE_ORDER_STATUS.CONFIRMED,
    SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED,
    SERVICE_ORDER_STATUS.NOT_ENOUGH_FUNDS,
  ].includes(serviceOrder.status)
);

export const approvedIsGreen = (serviceOrder, isSecondLevelApprovalEnabled) => (
  serviceOrder.status === SERVICE_ORDER_STATUS.APPROVED
  || (
    serviceOrder.status === SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED
    && (!isSecondLevelApprovalEnabled || serviceOrder.confirmed)
  )
);

export const invoicedIsGreen = serviceOrder => (
  [INVOICE_STATUS.RAISED, INVOICE_STATUS.EXECUTED, INVOICE_STATUS.PAID].includes(
    serviceOrder.contractorInvoiceStatus,
  )
);

export const processingIsGreen = serviceOrder => (
  [INVOICE_STATUS.EXECUTED, INVOICE_STATUS.PAID].includes(serviceOrder.contractorInvoiceStatus)
);

export const paidIsGreen = serviceOrder => (
  serviceOrder.contractorInvoiceStatus === INVOICE_STATUS.PAID
);
