import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { BS_SIZE, BS_STYLE, IMG_SIZE } from 'core/assets/js/constants';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';
import { fetchManagerBudgetDS, managerSendBudgetDiffDS } from 'people/assets/js/ducks/managers';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';

class EditManagerBudget extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);

    this.state = { submitting: false };
  }

  handleOpenModal() {
    const { dispatch, orgAlias, manager: { id: userId } } = this.props;
    dispatch(fetchManagerBudgetDS({ orgAlias, userId }));
  }

  /**
   * Validate, send & close the edit manager budget modal
   */
  handleCloseModal() {
    const { dispatch, onModalClose } = this.props;
    dispatch(modalCloseAC());
    onModalClose();
  }

  /**
   * Hndle the confirmation request of the invitation modal
   */
  async handleSubmitModal({ budgetDiff }, add) {
    this.setState({ submitting: true });
    const { orgAlias, manager } = this.props;

    const amount = !add ? budgetDiff * -1 : budgetDiff;
    try {
      await managerSendBudgetDiffDS(orgAlias, manager.id, { budgetDiff: amount });
      this.handleCloseModal();
      toastr.success('Well Done!', 'Budget updated successfully.');
    } catch (e) {
      toastr.error('Oh Snap!', e.response.data?._error || e.message);
    } finally {
      this.setState({ submitting: false });
    }
  }

  render() {
    const { activeUserCard, error, isLoading, isModalOpen, item, manager } = this.props;
    const { submitting } = this.state;

    const isSelfAndOrgOwner = (
      activeUserCard.user?.id === manager?.id && activeUserCard.userRole?.isOrgCreator
    );

    return (
      <div className="static-modal">
        <Form
          onSubmit={() => null}
          render={({ values }) => (
            <form onSubmit={() => null}>
              <Modal
                show={isModalOpen}
                className="edit-manager-budget"
                onEnter={this.handleOpenModal}
                onHide={this.handleCloseModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Budget Management</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  { (submitting || isLoading) && <LoadingComponent /> }

                  <div className="d-flex align-items-center justify-content-center">
                    <ProfilePic
                      url={manager.profile.avatar}
                      alt={`${manager.profile.firstName}'s avatar`}
                      size={[IMG_SIZE.LARGE, IMG_SIZE.LARGE]}
                    />

                    <div className="edit-manager-budget__meta pl-5 d-flex flex-column justify-content-center">
                      <div className="edit-manager-budget__name">
                        {`${manager.profile.firstName} ${manager.profile.lastName}`}
                      </div>
                      <div className="edit-manager-budget__job-title">
                        {manager.profile.jobTitle}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="text-center col-12">
                      <strong>
                        {isSelfAndOrgOwner && (
                          'As the Organization Owner, you can only add budget for yourself'
                        )}
                        {!isSelfAndOrgOwner && (
                          `You can add or remove budget for ${manager.profile.firstName}`
                        )}
                      </strong>
                    </div>
                  </div>

                  <div className="edit-manager-budget__totals">
                    <div>
                      <span>Total Budget</span>
                      <span className="value">
                        {item && !isLoading && (
                          <NumberTpl
                            prefix={item.currency}
                            thousandSeparator
                            value={item.approvedBudget}
                          />
                        )}
                        {isLoading && '-'}
                      </span>
                    </div>

                    <div>
                      <span>Used Budget</span>
                      <span className="value">
                        {item && !isLoading && (
                          <NumberTpl
                            prefix={item.currency}
                            thousandSeparator
                            value={item.usedBudget}
                          />
                        )}
                        {isLoading && '-'}
                      </span>
                    </div>

                    <div>
                      <span>Available</span>
                      <span className="value total">
                        {item && !isLoading && (
                          <NumberTpl
                            prefix={item.currency}
                            thousandSeparator
                            value={item.availableBudget}
                          />
                        )}
                        {isLoading && '-'}
                      </span>
                    </div>
                  </div>

                  <MoneyInputField
                    className={`input-${BS_SIZE.LARGE}`}
                    label="Amount"
                    name="budgetDiff"
                    prefix={item.currency}
                  />

                  { error && (
                    <div className="has-error gutter-bottom">
                      <span className="help-block">{ error }</span>
                    </div>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <TDButton
                    disabled={submitting || isSelfAndOrgOwner}
                    onClick={() => this.handleSubmitModal(values)}
                    variant={BS_STYLE.DANGER}
                  >
                    Remove
                  </TDButton>

                  <TDButton
                    disabled={submitting}
                    onClick={() => this.handleSubmitModal(values, true)}
                    variant={BS_STYLE.SUCCESS}
                  >
                    Add
                  </TDButton>
                </Modal.Footer>
              </Modal>
            </form>
          )}
        />
      </div>
    );
  }
}

EditManagerBudget.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  item: PropTypes.object.isRequired,
  manager: profileSpec.isRequired,
  modalId: PropTypes.string,
  onModalClose: PropTypes.func,
  orgAlias: PropTypes.string.isRequired,
};

EditManagerBudget.defaultProps = {
  error: '',
  isLoading: undefined,
  isModalOpen: false,
  modalId: 'edit-manager-budget',
  onModalClose: () => {},
};

const mapStateToProps = (state, props) => ({
  activeUserCard: selectActiveUserCard(state),
  history: props.history,
  isLoading: state.managers.managerBudget ? state.managers.managerBudget.isLoading : undefined,
  isModalOpen: props.modalId && getIsModalOpen(state, props.modalId),
  item: state.managers.managerBudget ? state.managers.managerBudget.item || {} : {},
  match: props.match,
  params: props.match.params,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const EditManagerBudgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditManagerBudget);

export default withRouter(EditManagerBudgetConnected);
