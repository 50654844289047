import moment from 'moment';
import { maxBy } from 'lodash';
import { PPH_STATUS } from 'search/assets/js/constants';
import { STATUS } from 'invitations/assets/js/constants';

export const getPPHStatus = (invitations) => { //eslint-disable-line
  if (invitations.length === 0) {
    return PPH_STATUS.NOT_INVITED;
  }

  const lastInvitation = maxBy(invitations, i => moment(i.sentOn, 'DD MMM YYYY'));
  if (lastInvitation.status === STATUS.ACCEPTED) {
    return PPH_STATUS.ACCEPTED;
  }

  if (lastInvitation.status === STATUS.REJECTED) {
    return PPH_STATUS.REJECTED;
  }

  if (lastInvitation.hasExpired) {
    return PPH_STATUS.EXPIRED;
  }

  if (lastInvitation.wasCancelled) {
    return PPH_STATUS.CANCELLED;
  }

  return PPH_STATUS.INVITED;
};
