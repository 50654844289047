import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import GeoSuggestField from 'core/assets/js/components/FinalFormFields/GeoSuggestField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import { uploaderOrgPath } from 'core/urls';
import SalesTaxFields, { SalesTaxSwitch } from 'settings/assets/js/components/SalesTaxFields.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import { getInfoFromFormValues } from 'settings/assets/js/lib/sales_tax_utils';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { Modal } from 'react-bootstrap';

const BillingDetailsForm = ({
  orgId, onSubmit, initialValues, modalState,
}) => (
  <Modal
    onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
    className="static-modal"
    enforceFocus
    onHide={() => modalState.close()}
    show={modalState.isOpen}
  >
    <Form
      name="billingDetails"
      initialValues={initialValues}
      onSubmit={(...args) => onSubmit(...args).then((err) => {
        if (!err) {
          modalState.close();
          return null;
        }
        return err;
      })}
      render={({ handleSubmit, form: { getState }, submitError }) => {
        const { dirty, submitting, values } = getState();
        const salesTaxInfo = getInfoFromFormValues(values);
        return (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Update billing details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <FileUploaderField
                    disabled={submitting}
                    imgHeight={150}
                    imgWidth={390}
                    maxFiles={1}
                    label="logo"
                    name="logo_metadata"
                    path={uploaderOrgPath(orgId)}
                    required
                    softDelete
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TextInputField name="name" type="text" label="Name" />
                </div>
                <div className="col-12">
                  <GeoSuggestField
                    disabled={submitting}
                    label="Address"
                    name="address"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SalesTaxSwitch submitting={submitting} />
                </div>
              </div>

              <SalesTaxFields
                submitting={submitting}
                {...salesTaxInfo}
              />


              <div className="row">
                <div className="col-12">
                  <TextInputField
                    disabled={submitting}
                    label="Registration number"
                    name="registration_number"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TextAreaField
                    extraInfo='The additional information provided will appear on your invoice beneath your billing details. You can enter any information that is required by your jurisdiction - e.g Your "PAN No" if you are in India.
                    It will be formatted as input. You can change it at any time.'
                    name="free_bill_to_text"
                    label="Additional billing info"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TextInputField name="website" label="Website" placeholder="" />
                </div>
              </div>
              {submitError && <p className="text-danger">{submitError}</p>}
            </Modal.Body>
            <Modal.Footer>
              <TDButton
                onClick={() => modalState.close()}
                label="Cancel"
              />
              <TDButton
                data-testid="company-form-button-save-or-update"
                className={`position-tweak ${dirty ? 'btn--dirty' : 'btn--pristine'}`}
                variant={BS_STYLE.PRIMARY}
                disabled={submitting}
                label="Update"
                onClick={() => handleSubmit()}
              />
            </Modal.Footer>
          </form>
        );
      }}
    />
  </Modal>
);

BillingDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  initialValues: PropTypes.object,
  modalState: modalStateSpec.isRequired,
};

BillingDetailsForm.defaultProps = {
  initialValues: {},
};

const BillingDetailsFormModal = withStateModal(BillingDetailsForm);

export default BillingDetailsFormModal;
