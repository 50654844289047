import { ROLES_TABS, ROLE_TABS } from 'roles/assets/js/constants';

// App urls
export const rolesBaseUrl = (orgAlias, tab = ROLES_TABS.ROLES) => {
  return `/${orgAlias}/settings/roles/${tab}`;
};
export const rolesListUrl = (orgAlias, tab) => rolesBaseUrl(orgAlias, tab);
export const roleCreateUrl = orgAlias => `${rolesBaseUrl(orgAlias)}/create`;
export const roleEditUrl = (orgAlias, roleId, tab = ROLE_TABS.CONFIG) => (
  `${rolesBaseUrl(orgAlias)}/${roleId}/${tab}`
);
export const roleDuplicateUrl = (orgAlias, sourceRoleId) =>
  `${rolesBaseUrl(orgAlias)}/create?srcRole=${sourceRoleId}`;
export const systemRoleViewUrl = (
  orgAlias,
  systemRoleId,
  tab = ROLE_TABS.CONFIG,
) => `${rolesBaseUrl(orgAlias)}/system-roles/${systemRoleId}/${tab}`;
// API Urls
export const rolesApiBaseUrl = (orgAlias, url = '') =>
  `${url}/api/${orgAlias}/roles`;

export const rolesListApiUrl = (orgAlias, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}`;

export const roleCreateApiUrl = (orgAlias, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/create`;

export const roleFetchApiUrl = (orgAlias, roleId, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/${roleId}`;

export const roleAddUsersApiUrl = (orgAlias, roleId, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/${roleId}/add-members`;

export const roleRemoveUsersApiUrl = (orgAlias, roleId, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/${roleId}/remove-members`;

export const roleFetchUsersApiUrl = (orgAlias, roleId, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/${roleId}/members`;

export const roleUpdateApiUrl = (orgAlias, roleId, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/${roleId}`;

export const roleDeleteApiUrl = (orgAlias, roleId, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/${roleId}`;

export const getRoleOptionsApiUrl = orgAlias => `${rolesApiBaseUrl(orgAlias)}/options`;

export const systemRolesListApiUrl = (orgAlias, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/system-roles`;

export const permissionsMembersFetchApiUrl = (orgAlias, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/permissions/members`;

export const systemRoleRemoveUsersApiUrl = (orgAlias, systemRoleId, userCardId, url = '') =>
  `${rolesApiBaseUrl(orgAlias, url)}/system-roles/${systemRoleId}/remove-member/${userCardId}`;
