import React from 'react';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

function SystemMessagesSection() {
  return (
    <section id="system-messages">
      <h2>System Messages</h2>

      <TDSystemMessage
        type="info"
        title={(
          <div>
            <div>
              Optional fields available!
            </div>

            <div className="text-dark mt-1">
              You have optional fields that you can add because manager has changed
              Contact template and added extra fields.
            </div>
          </div>
        )}
        className="mb-4 mt-4"
        dismissible
      />

      <TDSystemMessage
        type="info"
        title={(
          <div>
            <div>
              Optional fields available!
            </div>

            <div className="text-dark mt-1">
              You have optional fields that you can add because manager has changed
              Contact template and added extra fields.
            </div>
          </div>
        )}
        className="mb-4 mt-4"
        dismissible
      />

      <TDSystemMessage
        type="info"
        title="Information message"
        className="mb-4 mt-4"
      >
        <div>
          <p>
            Maecenas lacinia eu orci sit amet aliquet. In ligula lectus,
            vulputate id orci at, vulputate aliquet magna. Morbi non faucibus
            ipsum, blandit fringilla tortor. Praesent
            convallis aliquet lacus sapien.
          </p>
        </div>
      </TDSystemMessage>

      <TDSystemMessage
        type="warning"
        title="Warning message"
        className="mb-4 mt-4"
      >
        <div>
          <p>
            Maecenas lacinia eu orci sit amet aliquet. In ligula lectus, vulputate
            id orci at, vulputate aliquet magna. Morbi non faucibus ipsum,
            blandit fringilla tortor. Praesent convallis aliquet lacus, quis
            hendrerit justo. Curabitur dolor dolor, viverra congue dapibus a,
            fringilla ac orci. Nam sit amet blandit nibh, in consequat sapien.
          </p>
        </div>
      </TDSystemMessage>

      <TDSystemMessage
        type="danger"
        title="Error message"
        className="mb-4 mt-4"
      >
        <div>
          <p>
            Maecenas lacinia eu orci sit amet aliquet. In ligula lectus,
            vulputate id orci at, vulputate aliquet magna. Morbi non faucibus
            ipsum, blandit fringilla tortor. Praesent convallis aliquet lacus,
            quis hendrerit justo. Curabitur dolor dolor, viverra congue dapibus
            a, fringilla ac orci. Nam sit amet blandit nibh, in consequat sapien.
          </p>
        </div>
      </TDSystemMessage>

      <TDSystemMessage
        type="success"
        title="Success message"
        className="mb-4 mt-4"
      >
        <div>
          <p>
            Maecenas lacinia eu orci sit amet aliquet. In ligula lectus, vulputate
            id orci at, vulputate aliquet magna. Morbi non faucibus ipsum, blandit
            fringilla tortor. Praesent convallis aliquet lacus, quis hendrerit justo.
            Curabitur dolor dolor, viverra congue dapibus a, fringilla ac orci. Nam
            sit amet blandit nibh, in consequat sapien.
          </p>
        </div>
      </TDSystemMessage>
    </section>
  );
}

export default SystemMessagesSection;
