import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Table from 'core/assets/js/components/Table.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ImportStatus from 'importer/assets/js/components/ImportStatus.jsx';
import ImportStatusInfo from 'importer/assets/js/components/ImportStatusInfo.jsx';
import { IMPORT_TYPE_LABEL } from 'importer/assets/js/constants';
import { importCreateUrl, importsListApiUrl, importsListUrl, importViewUrl } from 'importer/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const ImportsView = ({ history, match: { params: { orgAlias } } }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const {
    items: imports, pagination,
  } = useSelector(state => getListState(state, ImportsView.GetComponentName()));
  const hasImports = imports.length > 0;
  const importOnClick = useCallback(
    () => history.push(importCreateUrl(activeOrg.alias)),
    [history, activeOrg],
  );
  return (
    <>
      <ContentHeader
        breadcrumbs={[{ title: 'Imports', url: importsListUrl(activeOrg.alias) }]}
        ctaButtonItems={[{ label: 'Import', onClick: importOnClick, variant: BS_STYLE.PRIMARY }]}
      />
      <div className="page page--imports-view">
        <div className="container">
          {hasImports && (
            <>
              <Table
                cols={[
                  {
                    key: 'id',
                    label: 'Name',
                    dataFormat: (id, importObj) => (
                      <Link to={importViewUrl(orgAlias, id)}>
                        {`${IMPORT_TYPE_LABEL[importObj.type]} import ${id}`}
                      </Link>
                    ),
                  },
                  {
                    columnClassName: 'text-nowrap',
                    key: 'status',
                    label: (
                      <>
                        Status
                        <ImportStatusInfo />
                      </>
                    ),
                    dataFormat: (status, importObj) => <ImportStatus import={importObj} />,
                  },
                  {
                    key: 'type',
                    label: 'Type',
                    dataFormat: type => IMPORT_TYPE_LABEL[type],
                  },
                  { key: 'rowCount', label: 'Rows' },
                  { key: 'rowErrorsCount', label: 'Errors' },
                  { key: 'rowCancelledCount', label: 'Cancelled' },
                  { key: 'rowCompleteCount', label: 'Completed' },
                  { key: 'createdBy', label: 'User' },
                  { key: 'createdAt', label: 'Created' },
                ]}
                items={imports}
              />
              <TDPagination {...pagination} />
            </>
          )}
          {!hasImports && (
            <div className="rounded shadow-sm p-4 bg-white d-flex flex-column align-items-center justify-content-center">
              <i className={`${ICON.IMPORT_FILE_DUOTONE} fa-5x my-5`} />
              <p className="mb-5">You have not imported anything yet</p>
              <TDButton
                className="mb-5"
                label="Import"
                onClick={importOnClick}
                type="submit"
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ImportsView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

ImportsView.GetComponentName = () => 'ImportsView';

const ImportsViewApiConnected = withTDApiConnected({
  fetchData: ({
    authedAxios, componentName, dispatch, params, querystring,
  }) => dispatch(fetchListDS({
    authedAxios,
    componentName,
    querystring,
    url: importsListApiUrl(params.orgAlias),
  })),
  duck: 'list',
  storeKey: ImportsView.GetComponentName(),
})(ImportsView);

export default withRouter(ImportsViewApiConnected);
