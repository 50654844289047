import { isEmpty, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { BS_STYLE } from 'core/assets/js/constants';
import { getListState } from 'core/assets/js/ducks/list';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { financeProFormaInvoicesUrl, financeProFormaInvoiceViewUrl } from 'finance/urls';
import { editProFormaInvoiceDS, fetchExchangeRatesDS } from 'finance/assets/js/data-services/form';
import { fetchFinanceProFormaInvoiceDS } from 'finance/assets/js/data-services/view';
import { exchangeRatesResponseSpec, proFormaInvoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import {
  formatServiceOrderItems, getProFormaInvoiceDefaultNewItem,
} from 'finance/assets/js/lib/utils';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { fetchRatesDS } from 'rates/assets/js/data-services/rates';
import { RATE_STATUS, RATE_SUGGEST_VALUE } from 'rates/assets/js/constants';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { paymentSettingSpec } from 'settings/assets/js/lib/objectSpecs';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProFormaInvoiceForm from 'finance/assets/js/ProFormaInvoiceForm.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';

const ProFormaInvoiceEditView = ({
  dispatch,
  exchangeRatesResponse,
  hasLoaded,
  history,
  match: { params: { id }, url: currentUrl },
  organization,
  proFormaInvoice,
  rates,
}) => {
  const orgAlias = organization.alias;

  if (!isEmpty(proFormaInvoice) && !proFormaInvoice.allowedActions.canBeEdited) {
    return (
      <RedirectRoute
        from={currentUrl}
        status={302}
        to={financeProFormaInvoiceViewUrl(orgAlias, proFormaInvoice.id)}
      />
    );
  }

  const {
    rates: exchangeRates,
    service: exchangeRatesService,
    timestamp: exchangeRateUpdatedAt,
  } = exchangeRatesResponse;

  const breadcrumbs = [
    {
      title: 'Proforma Invoices',
      url: financeProFormaInvoicesUrl(orgAlias),
    },
    {
      title: 'Edit Proforma Invoice',
      url: null,
    },
  ];

  const isDraftInvoiceEnabled = organization.allow_draft_invoice;

  const onSubmit = async ({ invoiceReferenceNumberEnabled, items, period, ...remainingValues }) => {
    const values = {
      ...remainingValues,
      ...period,
      items: formatServiceOrderItems(items),
    };
    const isInvoiceReferenceNumberEnabled = (
      Array.isArray(invoiceReferenceNumberEnabled)
      && invoiceReferenceNumberEnabled.length === 1
      && invoiceReferenceNumberEnabled[0].value === true
    );
    if (isInvoiceReferenceNumberEnabled && !values.invoiceReferenceNumber) {
      return { invoiceReferenceNumber: 'Untick "Add a custom invoice number" or enter a value' };
    }
    if (!isInvoiceReferenceNumberEnabled) {
      values.invoiceReferenceNumber = null;
    }
    try {
      const updatedInvoice = await editProFormaInvoiceDS(orgAlias, id, values);
      history.push(financeProFormaInvoiceViewUrl(orgAlias, updatedInvoice.id));
    } catch (error) {
      if (
        error.response?.data?._meta?.name
        && error.response.data._meta.name.includes('ValidationError')
      ) {
        return error.response.data;
      }
      toastr.error('Oh Snap!', error.response?.data?._error || error.message);
    }
    return null;
  };

  const initialValues = {
    currency: proFormaInvoice.proFormaInvoiceCurrency,
    invoiceReferenceNumberEnabled: !proFormaInvoice.invoiceReferenceNumber ? [] : [{ value: true }],
    items: (proFormaInvoice?.items || []).map(item => {
      const rateExists = rates.find(rate => rate.id === item.rateId);
      return {
        currency: item.proFormaInvoiceItemCurrency,
        rate_amount: item.proFormaInvoiceItemRateAmount,
        rate_id: rateExists ? item.rateId : RATE_SUGGEST_VALUE,
        rate_unit: item.rateUnit,
        ...pick(item, ['description', 'id', 'quantity']),
      };
    }),
    period: pick(proFormaInvoice, ['periodEnd', 'periodStart']),
    ...pick(proFormaInvoice, ['attachments', 'invoiceReferenceNumber', 'summary']),
  };

  const onAddItem = fields => {
    fields.push(getProFormaInvoiceDefaultNewItem(rates.find(r => r.isDefault)));
  };

  return (
    <>
      <ContentHeader breadcrumbs={breadcrumbs} />
      <div className="page page--finance">
        <div className="container">
          <TDApiConnected
            duck="view"
            fetchData={({ authedAxios, componentName }) => Promise.all([
              dispatch(fetchExchangeRatesDS({ authedAxios, componentName, orgAlias })),
              dispatch(fetchRatesDS({
                authedAxios, componentName, orgAlias, status: [RATE_STATUS.ACTIVE],
              })),
              dispatch(fetchFinanceProFormaInvoiceDS({ authedAxios, componentName, id, orgAlias })),
            ])}
            storeKey={ProFormaInvoiceEditView.GetComponentName()}
          >
            {hasLoaded && (
              <>
                {isDraftInvoiceEnabled && (
                  <ProFormaInvoiceForm
                    exchangeRates={exchangeRates}
                    exchangeRatesService={exchangeRatesService}
                    exchangeRateUpdatedAt={exchangeRateUpdatedAt}
                    initialValues={initialValues}
                    onAddItem={onAddItem}
                    onSubmit={onSubmit}
                    organization={organization}
                    proFormaInvoice={proFormaInvoice}
                    rates={rates}
                  />
                )}
                {!isDraftInvoiceEnabled && (
                  <TDSystemMessage
                    data-testid="pro-forma-invoice-edit-cannot"
                    title="You cannot edit this Proforma Invoice"
                    type={BS_STYLE.DANGER}
                  >
                    <p>You cannot edit this Proforma Invoice.</p>
                  </TDSystemMessage>
                )}
              </>
            )}
          </TDApiConnected>
        </div>
      </div>
    </>
  );
};

ProFormaInvoiceEditView.GetComponentName = () => 'ProFormaInvoiceEditView';

ProFormaInvoiceEditView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  exchangeRatesResponse: exchangeRatesResponseSpec,
  hasLoaded: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  organization: orgSpec.isRequired,
  paymentSettings: paymentSettingSpec,
  proFormaInvoice: proFormaInvoiceSpec.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
};

ProFormaInvoiceEditView.defaultProps = {
  exchangeRatesResponse: {},
  paymentSettings: {},
  rates: [],
};

const mapStateToProps = state => {
  const componentName = ProFormaInvoiceEditView.GetComponentName();
  const viewState = getViewState(state, componentName);
  return {
    activeUserCard: selectActiveUserCard(state),
    exchangeRatesResponse: getViewStateExtras(
      state, componentName, 'exchangeRates',
    ),
    hasLoaded: viewState.hasLoaded,
    hasOrgAccess: getHasOrgAccess(state),
    organization: selectActiveOrg(state),
    proFormaInvoice: viewState.item,
    rates: getListState(state, componentName).items,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ProFormaInvoiceEditViewConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ProFormaInvoiceEditView);

export default withRouter(ProFormaInvoiceEditViewConnected);
