import React from 'react';
import { Card } from 'react-bootstrap';

import { ICON } from 'core/assets/js/constants';

const ContactsImportProcessingSkeleton = () => (
  <Card className="contacts-skeleton--processing shadow-none border-0">
    <Card.Body>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <span className={`discreet ${ICON.IMPORT_FILE} mb-4`} />
        <p>Your contacts are being imported. Please wait...</p>
      </div>
    </Card.Body>
  </Card>
);

export default ContactsImportProcessingSkeleton;
