import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { purchaseOrderSpec } from 'finance/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import PurchaseOrdersTable from 'finance/assets/js/components/PurchaseOrdersTable.jsx';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import { getListState } from 'core/assets/js/ducks/list';
import { fetchFinancePurchaseOrderListDS } from 'finance/assets/js/data-services/list';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { PROJECT_TAB_VIEW_TYPE } from 'projects/assets/js/constants';
import SearchFormWithChartsToggleHeader from 'finance/assets/js/components/SearchFormWithChartsToggleHeader.jsx';
import { CURRENCY_SYMBOL, DATE_DURATION } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import ProjectPurchaseOrdersChartLine from 'projects/assets/js/components/widgets/ProjectPurchaseOrdersChartLine.jsx';

class ProjectTabPurchaseOrders extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, querystring = '', isProjManager, componentName }) {
    const prerequisites = [];
    if (isProjManager) {
      prerequisites.push(
        dispatch(fetchFinancePurchaseOrderListDS({
          url,
          params,
          querystring,
          projectId: params.id,
          componentName,
          authedAxios,
          dispatch,
        })),
      );
    }
    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'ProjectTabPurchaseOrders';
  }

  constructor(props) {
    super(props);
    this.state = {
      activeView: PROJECT_TAB_VIEW_TYPE.LIST,
      selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
    };
    this.onChartsToggled = this.onChartsToggled.bind(this);
    this.handleDateDurationChange = this.handleDateDurationChange.bind(this);
  }

  onChartsToggled() {
    const { activeView } = this.state;
    const nextView = activeView === PROJECT_TAB_VIEW_TYPE.LIST
      ? PROJECT_TAB_VIEW_TYPE.CHARTS
      : PROJECT_TAB_VIEW_TYPE.LIST;
    this.setState({
      activeView: nextView,
    });
  }

  handleDateDurationChange(selectedDate) {
    this.setState({
      selectedDateDuration: selectedDate,
    });
  }

  render() {
    const { activeView, selectedDateDuration } = this.state;
    const showGraphs = activeView === PROJECT_TAB_VIEW_TYPE.CHARTS;
    const {
      amountLimits, currency,  filtersOpen, purchaseOrders, pagination, onFiltersToggle,
    } = this.props;
    const emptyText = 'No budget requests found';
    const currencySymbol = CURRENCY_SYMBOL[currency];

    return (
      <React.Fragment>
        <SearchFormWithChartsToggleHeader
          amountLimits={amountLimits}
          showCharts={activeView === PROJECT_TAB_VIEW_TYPE.CHARTS}
          reportType={REPORT_TYPES.PURCHASE_ORDER}
          onChartsToggled={this.onChartsToggled}
          selectedDateDuration={selectedDateDuration}
          onDateDurationChanged={this.handleDateDurationChange}
          filtersOpen={filtersOpen}
          onFiltersToggle={onFiltersToggle}
        />
        { showGraphs && (
          <Card>
            <Card.Body>
              <ProjectPurchaseOrdersChartLine
                currencySymbol={currencySymbol}
                selectedDateDuration={selectedDateDuration}
              />
            </Card.Body>
          </Card>
        )}
        <AllowAccessToManager>
          <TDApiConnected
            duck="list"
            component={this.constructor}
            blockingLoading
            skeletonComponent={FinanceTableSkeleton}
          >
            { !filtersOpen && !showGraphs && (
              <React.Fragment>
                <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
                  <PurchaseOrdersTable
                    list={purchaseOrders}
                    emptyText={emptyText}
                    embeddedMode
                  />
                </div>
                <TDPagination
                  {...pagination}
                />
              </React.Fragment>
            )}
          </TDApiConnected>
        </AllowAccessToManager>
      </React.Fragment>
    );
  }
}

ProjectTabPurchaseOrders.propTypes = {
  amountLimits: PropTypes.object,
  currency: PropTypes.string.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  purchaseOrders: PropTypes.arrayOf(purchaseOrderSpec).isRequired,
};

ProjectTabPurchaseOrders.defaultProps = {
  amountLimits: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ProjectTabPurchaseOrders.GetComponentName());

  return {
    amountLimits: listState.extras.amountLimits,
    currency: selectActiveOrg(state).currency,
    pagination: listState.pagination,
    purchaseOrders: listState.items,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectTabPurchaseOrdersConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTabPurchaseOrders);

export default withRouter(withFilters(ProjectTabPurchaseOrdersConnected));
