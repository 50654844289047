import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { ICON, IMG_SIZE } from 'core/assets/js/constants';
import NoteForm from 'people/assets/js/components/NoteForm.jsx';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import { modalCloseAC, modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

class NoteCard extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  handleCloseModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleOpenModal(modalId) {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(modalId));
  }

  render() {
    const {
      id, avatar, firstName, authorId, lastName, onDeleteNote,
      viewerId, jobTitle, createdAt, body, onEditNote, attachments,
      attachments_metadata, isDeleteModalOpen, isEditModalOpen,
      editNoteModalId, deleteNoteModalId, activeOrg, subjectId,
    } = this.props;
    const editFormName = `notes-edit-${id}`;

    return (
      <Card className="card--light-gray border-0 shadow-none">
        <Card.Body className="note-review-card-item flex-column p-4">
          { authorId === viewerId && (
            <div className="action-links">
              <a onClick={() => this.handleOpenModal(editNoteModalId)}>
                <i className={ICON.EDIT} />
              </a>
              <a
                onClick={() => this.handleOpenModal(deleteNoteModalId)}
              >
                <i className={ICON.CROSS} />
              </a>

              <NoteForm
                subjectId={subjectId}
                orgId={activeOrg.id}
                initialValues={{ body, attachments_metadata, noteId: id }}
                show={isEditModalOpen}
                form={editFormName}
                onSubmit={onEditNote}
                onCloseModal={this.handleCloseModal}
                onSubmitSuccess={this.handleCloseModal}
                reinitialize
                editForm
              />
            </div>
          )}

          <div className="row">
            <div className="col-auto d-flex align-items-center">
              <div className="note-review-card-item__profile-pic">
                <ProfilePic
                  url={avatar}
                  alt={`${firstName}'s avatar`}
                  size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
                />
              </div>

              <div className="d-flex flex-column flex-sm-row justify-content-between">
                <div className="note-review-card-item__name">
                  <div>
                    {`${firstName} ${lastName}`}
                  </div>
                  <div className="note-review-card-item__job-title my-2 mb-sm-0">
                    {jobTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MarkdownText
            className="note-review-card-item__content mt-4 mb-0"
            withBreaksPlugin
            text={body}
          />
          <AttachmentsList
            attachments={attachments}
            label={null}
          />
          <ModalConfirm
            open={isDeleteModalOpen}
            onClose={this.handleCloseModal}
            onConfirm={() => onDeleteNote(id)}
            confirmLabel="Delete"
            confirmStyle="danger"
            heading="Delete note"
            body="Are you sure you want to delete this note?"
          />

          <div className="note-review-card-item__meta d-flex pt-4 mt-3">
            <time className="ml-auto discreet" dateTime={createdAt}>{formatDate(createdAt)}</time>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

NoteCard.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  attachments: PropTypes.array,
  attachments_metadata: PropTypes.string,
  authorId: PropTypes.number.isRequired,
  avatar: PropTypes.string,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  deleteNoteModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  editNoteModalId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isDeleteModalOpen: PropTypes.bool,
  isEditModalOpen: PropTypes.bool,
  jobTitle: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  subjectId: PropTypes.number.isRequired,
  viewerId: PropTypes.number.isRequired,
};

NoteCard.defaultProps = {
  attachments: null,
  attachments_metadata: null,
  avatar: null,
  jobTitle: '',
  isEditModalOpen: false,
  isDeleteModalOpen: false,
};

const mapStateToProps = (state, props) => {
  const { id: noteId } = props;
  const editNoteModalId = `edit-note-${noteId}-modal`;
  const deleteNoteModalId = `delete-note-${noteId}-modal`;
  const isEditModalOpen = getIsModalOpen(state, editNoteModalId);
  const isDeleteModalOpen = getIsModalOpen(state, deleteNoteModalId);
  return ({
    deleteNoteModalId,
    editNoteModalId,
    isDeleteModalOpen,
    isEditModalOpen,
    activeOrg: selectActiveOrg(state),
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const NoteCardConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(NoteCard);

export default NoteCardConnected;
