import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { projectListUrl, projectViewUrl } from 'projects/urls';
import { projectMemberAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';

const ReportProjectLink = ({ orgAlias, projectId, projectReference, allowedActions }) => {
  if (!projectReference || !projectId) {
    return (
      <span>
        -
      </span>
    );
  }

  const canViewProject = allowedActions.canViewProjectDetails;

  if (canViewProject) {
    return (
      <Link
        to={projectViewUrl(orgAlias, projectId)}
      >
        {projectReference}
      </Link>
    );
  }

  return (
    <Link
      to={projectListUrl(orgAlias, [projectId])}
    >
      {projectReference}
    </Link>
  );
};

ReportProjectLink.propTypes = {
  orgAlias: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  projectReference: PropTypes.string,
  allowedActions: projectMemberAllowedActionsSpec,
};

ReportProjectLink.defaultProps = {
  projectReference: null,
  projectId: null,
  allowedActions: {},
};

export default ReportProjectLink;
