import React from 'react';
import { logger } from 'core/assets/js/lib/Logger';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import {
  TABS_STYLING_VARIATION,
} from 'core/assets/js/constants';


const tabSpec = [
  {
    label: 'Tab 1',
    key: 1,
  },
  {
    label: (
      <React.Fragment>
        Tab 2
        <span className="badge">2</span>
      </React.Fragment>
    ),
    key: 2,
  },
  {
    label: 'Tab 3',
    key: 3,
  },
];

function TabsSection() {
  return (
    <section id="tabs">
      <h2>Tabs</h2>
      <div>
        <div className="mt-3">Default</div>
        <TabBar
          activeKey={1}
          handleTabSelect={(tabKey) => {
            logger.log(`Tab ${tabKey} selected`);
          }}
          tabSpec={tabSpec}
        />

        <div className="mt-5">Simple:</div>
        <TabBar
          activeKey={1}
          handleTabSelect={(tabKey) => {
            logger.log(`Tab ${tabKey} selected`);
          }}
          tabSpec={tabSpec}
          variation={TABS_STYLING_VARIATION.SIMPLE}
        />

        <div className="mt-5">Pills:</div>
        <TabBar
          activeKey={1}
          handleTabSelect={(tabKey) => {
            logger.log(`Tab ${tabKey} selected`);
          }}
          tabSpec={tabSpec}
          variation={TABS_STYLING_VARIATION.PILLS}
        />
      </div>
    </section>
  );
}

export default TabsSection;
