import React from 'react';

import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { IMPORT_STATUS, IMPORT_STATUS_CLASS, IMPORT_STATUS_LABEL } from 'importer/assets/js/constants';
import { importSpec } from 'importer/assets/js/lib/objectSpecs';

const ImportStatus = ({ import: { startedAt, status } }) => {
  let label = IMPORT_STATUS_LABEL[status];
  let reason = null;
  const isPending = status === IMPORT_STATUS.PENDING;
  if (isPending) {
    if (startedAt) {
      label = 'In progress';
    } else {
      reason = 'This import has been added to our processing queue. There are other imports before '
        + 'it, that will be processed first';
    }
  }
  return (
    <StatusTag
      label={label}
      reason={reason}
      statusClass={IMPORT_STATUS_CLASS[status]}
      title={(isPending && !startedAt) ? null : label}
    />
  );
};

ImportStatus.propTypes = {
  import: importSpec.isRequired,
};

export default ImportStatus;
