/* eslint-disable import/prefer-default-export */
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { modalDataViewIsLoadingAC, modalDataViewFetchAC } from 'core/assets/js/ducks/modalView';
import { ratesFetchRateApiUrl } from 'rates/urls';

const _validateParams = (params) => {
  if (!params.orgAlias) {
    throw new Error('Expected the "orgAlias" param');
  }
};

export const fetchProviderRateAdjustmentsDS = (params = {}, authedAxios = null, url = '') => (
  (dispatch) => {
    _validateParams(params);
    dispatch(modalDataViewIsLoadingAC(true));

    return dispatch(fetchDataDS({
      authedAxios,
      fetchApiUrl: () => ratesFetchRateApiUrl(
        params.orgAlias, params.rateId, url),
      fetchDataAC: responseData => modalDataViewFetchAC(responseData),
      fetchDataErrorAC: () => modalDataViewIsLoadingAC(false),
    }));
  }
);
