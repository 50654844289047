import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';
import { connect } from 'react-redux';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';

export const MODAL_ID = 'document-create-confirm-modal';

const DocumentFormConfirmModal = ({ dispatch, isModalOpen, onConfirm, setPageNumber }) => {
  const values = omit(useFormState().values, 'secondarySubmit');
  return (
    <ModalConfirm
      closeOnConfirm={false}
      data-testid="document-create-confirm-modal"
      confirmLabel="Publish"
      heading="Are you sure you want to publish this contract template?"
      onClose={() => dispatch(modalCloseAC())}
      onConfirm={() => onConfirm(values, setPageNumber)}
      open={isModalOpen}
      size={MODAL_SIZES.LARGE}
    >
      When publishing a contract template, it will only remain editable until it has been sent to a
      recipient. Once this has occured, the contract template will require a new version in order
      to make changes.
    </ModalConfirm>
  );
};

DocumentFormConfirmModal.GetComponentName = () => 'DocumentFormConfirmModal';

DocumentFormConfirmModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
};

DocumentFormConfirmModal.defaultProps = {
  isModalOpen: false,
};

const mapStateToProps = state => ({
  isModalOpen: getIsModalOpen(state, MODAL_ID),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const DocumentFormConfirmModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentFormConfirmModal);

export default DocumentFormConfirmModalConnected;
