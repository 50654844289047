import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';

import DiscussionBoard, { COMPONENT_NAME as DISCUSSION_BOARD_COMPONENT_NAME } from 'core/assets/js/components/DiscussionBoard/DiscussionBoard.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ProjectInvitationListView from 'projects/assets/js/ProjectInvitationListView.jsx';
import { BS_SIZE } from 'core/assets/js/constants';
import { fetchListDS, listResetAC } from 'core/assets/js/ducks/list';
import { fetchProjectTaskMessagesDS, fetchProjectTaskDS, fetchProjectTaskAssignmentsDS } from 'projects/assets/js/data-services/tasks';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { getViewStateExtras } from 'core/assets/js/ducks/view';
import { projectInvitationsApiUrl } from 'projects/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

export const MODAL_ID = 'TASK_RATE_ADJUSTMENT_MODAL';

const ProjectTaskInvitationRateAdjustmentModal = ({
  accessControl,
  actions,
  activeUserCard,
  dispatch,
  history,
  isModalOpen,
  location,
  match,
  modalData,
  onSignAndAccept,
  stateName,
}) => {
  const { params: { orgAlias } } = match;
  if (!modalData || !activeUserCard) {
    return null;
  }

  const {
    hasPendingDocuments = false,
    invitation: { task, sender },
    project,
  } = modalData;

  const managerId =  sender?.id || task.ownerId;

  useEffect(() => {
    dispatch(fetchProjectTaskDS({
      orgAlias,
      projectId: project.id,
      taskId: task.id,
      componentName: DISCUSSION_BOARD_COMPONENT_NAME,
    }));

    dispatch(fetchProjectTaskAssignmentsDS({
      orgAlias,
      projectId: project.id,
      taskId: task.id,
      componentName: DISCUSSION_BOARD_COMPONENT_NAME,
    }));

    dispatch(fetchProjectTaskMessagesDS({
      orgAlias,
      projectId: project.id,
      taskId: task.id,
      componentName: DISCUSSION_BOARD_COMPONENT_NAME,
      participantId: managerId,
    }));

    return () => {
      dispatch(listResetAC(DISCUSSION_BOARD_COMPONENT_NAME));
    };
  }, [task]);

  const refreshTaskStateAndAssignments = async () => {
    await Promise.all([
      dispatch(fetchProjectTaskDS({
        orgAlias,
        projectId: project.id,
        taskId: task.id,
        componentName: DISCUSSION_BOARD_COMPONENT_NAME,
      })),

      dispatch(fetchProjectTaskAssignmentsDS({
        orgAlias,
        projectId: project.id,
        taskId: task.id,
        componentName: DISCUSSION_BOARD_COMPONENT_NAME,
      })),
    ]);
  };

  if (!task) {
    return null;
  }

  return (
    <ModalSimple
      backdrop="static"
      body={(
        <DiscussionBoard
          accessControl={accessControl}
          actions={actions}
          activeParticipant={managerId}
          hasPendingDocuments={hasPendingDocuments}
          isEmbeddedMode
          onSignAndAccept={onSignAndAccept}
          onStateUpdated={refreshTaskStateAndAssignments}
          onTaskUpdated={() => {}}
          parentComponentName={ProjectInvitationListView.GetComponentName()}
          participants={[]}
          project={project}
          showParticipantsList={false}
          stateName={stateName}
          task={task}
        />
      )}
      heading="Invitation chat"
      noFooter
      onClose={() => {
        dispatch(modalCloseAC(MODAL_ID));
        history.push({
          pathname: location.pathname,
          search: location.search,
          state: { formAction: '' },
        });
        dispatch(fetchListDS({
          componentName: ProjectInvitationListView.GetComponentName(),
          querystring: qs.stringify(qs.parse(location.search)),
          url: projectInvitationsApiUrl(orgAlias),
        }));
      }}
      open={isModalOpen}
      size={BS_SIZE.LARGE}
    />
  );
};

ProjectTaskInvitationRateAdjustmentModal.propTypes = {
  accessControl: PropTypes.object,
  actions: PropTypes.array,
  activeUserCard: userCardSpec,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  modalData: PropTypes.object,
  onSignAndAccept: PropTypes.func.isRequired,
  stateName: PropTypes.string,
};

ProjectTaskInvitationRateAdjustmentModal.defaultProps = {
  accessControl: {},
  actions: [],
  activeUserCard: null,
  modalData: null,
  stateName: null,
};

const mapStateToProps = (state) => {
  const taskState = getViewStateExtras(state, DISCUSSION_BOARD_COMPONENT_NAME, 'taskState');

  return {
    accessControl: getViewStateExtras(state, DISCUSSION_BOARD_COMPONENT_NAME, 'accessControl'),
    actions: taskState.actions,
    activeUserCard: selectActiveUserCard(state),
    isModalOpen: getIsModalOpen(state, MODAL_ID),
    modalData: getModalPayload(state, MODAL_ID),
    stateName: taskState.state,
  };
};
const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectTaskInvitationRateAdjustmentModalConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ProjectTaskInvitationRateAdjustmentModal);

export default withRouter(ProjectTaskInvitationRateAdjustmentModalConnected);
