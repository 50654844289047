import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates';
import { withRouter, Link } from 'react-router-dom';

import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { financeWorksheetViewUrl, financeExpenseViewUrl } from 'finance/urls';
import WorksheetTypeIndicator from 'finance/assets/js/components/WorksheetTypeIndicator.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { API_DATE_FORMAT, BS_SIZE } from 'core/assets/js/constants';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';


class BillingPeriodWidget extends React.PureComponent {
  render() {
    const {
      dateFormat, period, modalState, startDate, endDate,
      firstBillable, lastBillable, match,
    } = this.props;
    const start = moment(startDate, dateFormat, true);
    const end = moment(endDate, dateFormat, true);
    if (!start.isValid() && end.isValid()) {
      return null;
    }
    const { orgAlias } = match.params;

    return (
      <React.Fragment>
        <div className="imitate-link" onClick={modalState.open}>{period}</div>
        <ModalSimple
          size={BS_SIZE.XSMALL}
          heading="Billing Period"
          open={modalState.isOpen}
          onClose={modalState.close}
          noFooter
        >
          <div className="d-flex justify-content-center">
            <DayPickerRangeController
              noBorder
              hideKeyboardShortcutsPanel
              startDate={moment(start)}
              endDate={moment(end)}
              onDatesChange={() => {}}
              onFocusChange={() => {}}
              focusedInput="startDate"
            />
          </div>
          { firstBillable && (
            <div>
              <h3 className="mb-3">
                Earliest and latest service orders included
              </h3>

              <ul>
                {firstBillable && (
                  <li className="border-bottom py-3 d-flex w-100">
                    <WorksheetTypeIndicator type={firstBillable.type} />
                    <Link
                      className="ml-3"
                      to={
                        firstBillable.type === SERVICE_ORDER_TYPE.WORKSHEET
                          ? financeWorksheetViewUrl(orgAlias, firstBillable.id)
                          : financeExpenseViewUrl(orgAlias, firstBillable.id)
                      }
                    >
                      {firstBillable.id}
                    </Link>
                    <div className="text-secondary ml-auto text-sm">
                      {firstBillable.period}
                    </div>
                  </li>
                )}

                {lastBillable && (firstBillable.id !== lastBillable.id) && (
                  <li className="border-bottom py-3 d-flex w-100">
                    <WorksheetTypeIndicator type={lastBillable.type} />
                    <Link
                      className="ml-3"
                      to={
                        firstBillable.type === SERVICE_ORDER_TYPE.WORKSHEET
                          ? financeWorksheetViewUrl(orgAlias, firstBillable.id)
                          : financeExpenseViewUrl(orgAlias, firstBillable.id)
                      }
                    >
                      {lastBillable.id}
                    </Link>
                    <div className="text-secondary ml-auto text-sm">
                      {lastBillable.period}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
        </ModalSimple>
      </React.Fragment>
    );
  }
}

BillingPeriodWidget.propTypes = {
  endDate: PropTypes.PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  period: PropTypes.string.isRequired,
  match: routerMatchContentsSpec.isRequired,
  startDate: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  firstBillable: PropTypes.object,
  lastBillable: PropTypes.object,
  modalState: modalStateSpec.isRequired,
};

BillingPeriodWidget.defaultProps = {
  dateFormat: API_DATE_FORMAT,
  firstBillable: null,
  lastBillable: null,
};

export default withRouter(withStateModal(BillingPeriodWidget));
