import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { projectSpec, projectAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { fetchProjectApplicationsListDS } from 'projects/assets/js/data-services/list';
import { fetchReviewsDS } from 'projects/assets/js/data-services/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import ProjectApplicationCard from 'projects/assets/js/components/ProjectApplicationCard.jsx';
import ProjectApplicationsSearch from 'projects/assets/js/components/ProjectApplicationsSearch.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { ACCESS_CONTROL_ALLOWED_ACTIONS as ALLOWED_ACTIONS } from 'core/assets/js/constants';
import { PEOPLE_DEFAULT_ORDERING } from 'people/assets/js/constants';


class ProjectTabApplications extends React.Component {
  static FetchData({
    dispatch, params, url, querystring, componentName, authedAxios, isProjManager,
  }) {
    const _queryString = queryString.parse(querystring);

    // Use default ordering in case user haven't specified any.
    if (!_queryString.ordering) {
      _queryString.ordering = JSON.stringify(PEOPLE_DEFAULT_ORDERING);
    }

    const prerequisites = [
      dispatch(fetchProjectApplicationsListDS({
        url,
        params,
        querystring: queryString.stringify(_queryString),
        projectId: params.id,
        componentName,
        authedAxios,
        dispatch,
      })),
    ];

    if (isProjManager) {
      prerequisites.push(
        dispatch(fetchReviewsDS({
          orgAlias: params.orgAlias,
          id: params.id,
          url,
          authedAxios,
          componentName,
        })),
      );
    }

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'ProjectTabApplicants';
  }

  render() {
    const {
      filtersOpen, onFiltersToggle, project, applications, pagination,
      match, accessControl, rateLimits,
    } = this.props;
    const { orgAlias } = match.params;

    return (
      <React.Fragment>
        <div className="row">
          <ProjectApplicationsSearch
            rateLimits={rateLimits}
            onFiltersToggle={onFiltersToggle}
            filtersOpen={filtersOpen}
          />
        </div>

        <TDApiConnected
          duck="list"
          component={this.constructor}
          blockingLoading
          skeletonComponent={PeopleListSkeleton}
        >
          { !filtersOpen && (
            <TDList
              items={applications}
              pagination={pagination}
              cardItem={{
                component: ProjectApplicationCard,
                props: {
                  orgAlias,
                  project,
                  projectAllowedActions: accessControl[ALLOWED_ACTIONS.PROJECT] || {},
                },
              }}
              emptyListMessage="No applications found."
            />
          )}
        </TDApiConnected>
      </React.Fragment>
    );
  }
}

ProjectTabApplications.propTypes = {
  accessControl: PropTypes.shape({ project: projectAllowedActionsSpec }),
  filtersOpen: PropTypes.bool.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  project: projectSpec.isRequired,
  applications: PropTypes.arrayOf(projectSpec).isRequired,
  rateLimits: PropTypes.object,
};

ProjectTabApplications.defaultProps = {
  accessControl: {},
  rateLimits: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ProjectTabApplications.GetComponentName());

  return {
    applications: listState.items,
    accessControl: listState.extras.accessControl,
    rateLimits: listState.extras.rateLimits,
    pagination: listState.pagination,
  };
};

const ProjectTeamViewConnected = connect(
  mapStateToProps,
)(ProjectTabApplications);

export default withRouter(withFilters(ProjectTeamViewConnected));
