import React from 'react';

import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';

const MarkdownFormattingExample = () => (
  <div className="markdown-example markdown-content">
    <p>
      TalentDesk uses Markdown for formatting. Here are the basics.
    </p>

    <pre className="mt-4"># Heading</pre>
    <MarkdownText
      text="# Heading"
      withBreaksPlugin
    />

    <pre className="mt-4">**bold text**</pre>
    <MarkdownText
      text="**bold text**"
      withBreaksPlugin
    />

    <pre className="mt-4">*italics*</pre>
    <MarkdownText
      text="*italics*"
      withBreaksPlugin
    />

    <pre className="mt-4">~~strikethrough~~</pre>
    <MarkdownText
      text="~~strikethrough~~"
      withBreaksPlugin
    />

    <pre className="mt-4">[link](https://yourlink.com)</pre>
    <p>
      <a href="#">https://yourlink.com</a>
    </p>

    <pre className="mt-4">
      * Bullet one
      <br />
      * Bullet two
    </pre>
    <ul>
      <li>1. Bullet one</li>
      <li>2. Bullet two</li>
    </ul>

    <pre className="mt-4">
      1. Item 1
      <br />
      2. Item 2
    </pre>
    <ol>
      <li>Item 1</li>
      <li>Item 2</li>
    </ol>
  </div>
);

export default MarkdownFormattingExample;
