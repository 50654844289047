import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { BS_STYLE, ICON, IMG_SIZE, USER_TYPE } from 'core/assets/js/constants';
import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_PAGE_SIZE } from 'settings/assets/js/constants';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { orgPeopleManagersUrl, orgPeopleInviteUrl, orgUserProfileUrl, orgPeopleListApiUrl } from 'people/urls';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { fetchListDS, getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import { prepareQueryString } from 'core/assets/js/lib/utils';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';

const COMPONENT_NAME = 'ManagersWidget';

const ManagersWidget = ({
  allowedActions: { canInviteManagers },
  managerItems,
  match: {
    params: { orgAlias },
  },
  history,
}) => {
  const headerActionBtn = canInviteManagers ? {
    onClick: () => history.push(orgPeopleInviteUrl(orgAlias, PEOPLE_TYPE.MANAGERS)),
    label: 'Invite managers',
    variant: BS_STYLE.PRIMARY,
  } : null;

  const footerActionBtn = {
    onClick: () => history.push(orgPeopleManagersUrl(orgAlias)),
    label: 'View managers',
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: 'Invite your team to manage contractors, projects, and costs',
    icon: ICON.USER_CROWN,
  };

  const widgetTitle = 'Managers';

  return (
    <WidgetWrapper
      footerActionBtn={footerActionBtn}
      headerActionBtn={headerActionBtn}
      isEmpty={isEmpty(managerItems)}
      placeholder={emptyPlaceholder}
      title={widgetTitle}
      popOverTitle={widgetTitle}
      popOverContent={(
        <>
          <p>
            Managers are your team, your inner circle of people who will invite and manage
            providers, create and manage projects &amp; tasks, and ultimately review the worksheets
            and expenses that the providers create. Creating more managers allows you to
            delegate more work.
          </p>
          <p>
            <a
              href="https://www.talentdesk.io/knowledge-base/teams/higher-manager/adding-or-removing-budgets-to-manager-profiles"
              target="_blank"
              rel="noreferrer"
            >
              {'To learn more '}
              <span className="visually-hidden">
                {`about ${widgetTitle}, `}
              </span>
              visit the Knowledge base
            </a>
          </p>
        </>
      )}
    >
      {managerItems.map((manager) => {
        const { user: { profile: { name, avatar }, id } } = manager;
        const managerUrl = orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, id);
        return (
          <Link className="text-dark" key={id} to={managerUrl}>
            <div className="row d-flex align-items-center mb-5">
              <div className="col-md-auto">
                <span className="pt-3 mt-n3 profile-pic-wrapper">
                  <ProfilePic
                    alt={name}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                    url={avatar}
                  />
                </span>
              </div>
              <div className="pl-0 col-md-auto font-weight-bold">
                {manager.user.profile.name}
              </div>
            </div>
          </Link>
        );
      })}
    </WidgetWrapper>
  );
};

ManagersWidget.propTypes = {
  allowedActions: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  managerItems: PropTypes.arrayOf(userCardSpec),
  match: routerMatchSpec.isRequired,
};

ManagersWidget.defaultProps = {
  allowedActions: {},
  managerItems: [],
};

const mapStateToProps = state => ({
  allowedActions: getListStateExtras(state, COMPONENT_NAME, 'allowedActions'),
  hasOrgAccess: getHasOrgAccess(state),
  isManager: getHasOrgAccess(state)({ requireManager: true }),
  managerItems: getListState(state, COMPONENT_NAME).items,
});

const ManagersWidgetConnected = connect(mapStateToProps)(ManagersWidget);

const TdApiConnectedManagersWidget = withTDApiConnected({
  fetchData: ({ dispatch, params: { orgAlias }, url, authedAxios }) => dispatch(
    fetchListDS({
      authedAxios,
      componentName: COMPONENT_NAME,
      querystring: prepareQueryString({
        pageSize: DASHBOARD_WIDGET_PAGE_SIZE[DASHBOARD_WIDGET.MANAGERS],
      }),
      url: orgPeopleListApiUrl({ orgAlias, peopleType: PEOPLE_TYPE.MANAGERS }, url),
    }),
  ),
  duck: 'list',
  storeKey: COMPONENT_NAME,
})(ManagersWidgetConnected);

export default withRouter(TdApiConnectedManagersWidget);
