/* eslint no-console: warn */
import moment from 'moment';
import axios from 'axios';
import {
  isEqual,
  isString,
  flatten,
  fromPairs,
  merge,
  set,
  get,
  isEmpty,
  omit,
  uniq,
  zip,
  isArray,
} from 'lodash';
import querystring from 'querystring';
import Logger from 'core/assets/js/lib/Logger';

import { MultiValidationError, ValidationErrorItem, ValidationError, NestedValidationErrorList } from 'core/assets/js/errors';
import { waitAllPromises } from 'core/assets/js/lib/utils';
import TransferwiseRecipient from 'services/assets/js/lib/TransferwiseRecipient';
import { sanitizeRegExpString } from 'services/assets/js/lib/utils';
import { truncatePaymentReferenceForLength } from 'finance/assets/js/lib/utils';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { isFront } from 'core/assets/js/config/checks';

const logger = new Logger('service:transferwise:validator');

const fieldsAreSame = (field1, field2) => (
  field1.group.map(g => g.key).join(',') === field2.group.map(g => g.key).join(',')
);

// https://api-docs.transferwise.com/#recipient-accounts-requirements
class TransferwiseValidator {
  static validateField(fieldSpec, value) {
    const {
      minLength, maxLength, valuesAllowed, validationRegexp, key, required,
    } = fieldSpec;
    if (!value) {
      if (required) {
        throw new ValidationErrorItem(key, 'Field is required');
      }
      return;
    }

    // we need to sanitize the regular expression for use with unicode ( 'u' )
    // TrW can send illegally escaped regular expressions for JS, ie \\-
    if (validationRegexp) {
      const regex = (
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/unicode#description
        validationRegexp.includes('\\p')
        || validationRegexp.includes('\\P')
        || validationRegexp.includes('\\u')
        || validationRegexp.includes('\\U')
      ) ? new RegExp(sanitizeRegExpString(validationRegexp), 'u')
        : new RegExp(validationRegexp);
      if (!regex.test(value)) {
        throw new ValidationErrorItem(key, 'Field value is not valid');
      }
    }

    if (minLength && value.length < minLength) {
      throw new ValidationErrorItem(key, 'Field value has very small length');
    }

    if (maxLength && value.length > maxLength) {
      throw new ValidationErrorItem(key, 'Field value has very large length');
    }

    if (!isEmpty(valuesAllowed) && !valuesAllowed.some(v => v.key === value)) {
      throw new ValidationErrorItem(key, 'Field value does not match any allowed values');
    }
  }

  static async asyncValidateGroup({ url, queryParams }) {
    if (isFront()) {
      return [];
    }
    const query = querystring.stringify(queryParams);
    const validationUrl = `${url}?${query}`;
    try {
      await axios.get(validationUrl);
    } catch (e) {
      let errors = get(e, 'response.data.errors', []).map(({ path, message }) => (
        new ValidationErrorItem(path, message)
      ));
      if (isEmpty(errors)) {
        errors = Object.entries(queryParams).map(([key, value]) => (
          new ValidationErrorItem(key, `async validation failed for value (${value})`)
        ));
      }
      throw new ValidationError('async validation failed', errors);
    }
    return [];
  }

  static async validateFieldGroup(group, details) {
    const validFields = [];
    await waitAllPromises(group.map(async (fieldSpec) => {
      const { key } = fieldSpec;
      const value = get(details, key);
      TransferwiseValidator.validateField(fieldSpec, value);
      validFields.push(key);
    }));

    await waitAllPromises(group.filter(g => g.validationAsync).map(async ({ validationAsync }) => {
      const { url, params } = validationAsync;
      const queryParams = fromPairs(params.map(({ key: k, parameterName }) => (
        [parameterName, get(details, k)]
      )));
      await TransferwiseValidator.asyncValidateGroup({ url, queryParams });
      validFields.push(...params.map(p => p.key));
    }));

    const validDetails = uniq(validFields).reduce((sum, key) => {
      const value = get(details, key);
      set(sum, key, value);
      return sum;
    }, {});

    return validDetails;
  }

  static async validateType(typeSpec, details) {
    const { fields, title, type } = typeSpec;
    try {
      const values = await waitAllPromises(fields.map(async (field) => {
        try {
          const res = await TransferwiseValidator.validateFieldGroup(field.group, details);
          return res;
        } catch (e) {
          throw new MultiValidationError(`Invalid ${field.name}`, e);
        }
      }));
      const validDetails = merge({}, ...values);
      return validDetails;
    } catch (e) {
      throw new NestedValidationErrorList(
        type, `Invalid recipient account for type '${title}'`, e,
      );
    }
  }

  static async validateRequirements(requirements, details) {
    const res = await waitAllPromises(requirements.map(t => (
      TransferwiseValidator.validateType(t, details)
    )), { throwErrors: false });

    requirements.forEach((r) => {
      if (!r) {
        throw new Error('empty requirements');
      }
    });

    const perType = fromPairs(zip(requirements.map(r => r.type), res));
    return perType;
  }

  static async validateRecipient({
    alias, type, bankName, accountHolderName, currency, details,
  }, requirements, {
    validateAlias = false,
    validateBankName = false,
    includeOriginalDetails = ['address.occupation', 'cardToken'],
  } = {}) {
    let types = requirements.map(t => t.type);

    if (isEmpty(types)) {
      throw new Error('empty requirement types');
    }

    if (type) {
      types = types.filter(t => t === type);
      if (isEmpty(types)) {
        throw new Error(`selected type '${type}' is not included in requirement types '${requirements.map(t => t.type).join(', ')}'`);
      }
    }

    const typeErrors = {};

    if (!accountHolderName) {
      types.forEach((t) => {
        typeErrors[t] = [...(typeErrors[t] || []), new ValidationErrorItem('accountHolderName', 'Field is required')];
      });
    }

    if (validateAlias && !alias) {
      types.forEach((t) => {
        typeErrors[t] = [...(typeErrors[t] || []), new ValidationErrorItem('alias', 'Field is required')];
      });
    }

    if (validateBankName && !bankName) {
      types.forEach((t) => {
        typeErrors[t] = [...(typeErrors[t] || []), new ValidationErrorItem('bank_name', 'Field is required')];
      });
    }

    if (!currency) {
      types.forEach((t) => {
        typeErrors[t] = [...(typeErrors[t] || []), new ValidationErrorItem('currency', 'Field is required')];
      });
    }

    const res = await TransferwiseValidator.validateRequirements(
      requirements.filter(r => types.includes(r.type)), { ...details, accountHolderName },
    );
    const validTypes = {};

    Object.entries(res).forEach(([t, result]) => {
      if (result instanceof Error) {
        typeErrors[t] = [...(typeErrors[t] || []), ...result.errors];
      } else {
        validTypes[t] = result;
      }
    });

    if (isEmpty(validTypes)) {
      if (type) {
        // return specific errors for selected type
        const error = new ValidationError('Invalid recipient', typeErrors[type]);
        throw error;
      } else {
        // return errors grouped by type
        const validationErrors = Object.entries(typeErrors).map(([path, errors]) => (
          new NestedValidationErrorList(path, `Invalid recipient account for type '${path}'`, errors)
        ));
        const error = new ValidationError('Invalid recipient', validationErrors);
        throw error;
      }
    }
    // return the first valid type
    const [t, validatedDetails] = Object.entries(validTypes)[0];

    if (includeOriginalDetails
      && isArray(includeOriginalDetails)
      && !isEmpty(includeOriginalDetails)) {
      // add back any details that may have been stripped
      // note - cardToken may have been added to details although it has no requirement
      //        and would have now been stripped, but we need to send it to TrW
      includeOriginalDetails.filter(key => undefined === get(validatedDetails, key))
        .forEach((key) => {
          if (undefined !== get(details, key)) {
            set(validatedDetails, key, get(details, key));
          }
        });
    }

    return new TransferwiseRecipient({
      type: t, details: validatedDetails,
      accountHolderName, currency: currency.toUpperCase(),
    });
  }

  static async validateTransfer({ quoteUuid, details, type = 'transfer' }, requirements) {
    let types = requirements.map(t => t.type);

    if (isEmpty(types)) {
      throw new Error('empty requirement types');
    }

    if (type) {
      types = types.filter(t => t === type);
      if (isEmpty(types)) {
        throw new Error(`selected type '${type}' is not included in requirement types '${requirements.map(t => t.type).join(', ')}'`);
      }
    }

    const typeErrors = {};

    if (!quoteUuid) {
      types.forEach((t) => {
        typeErrors[t] = [...(typeErrors[t] || []), new ValidationErrorItem('quoteId', 'Field is required')];
      });
    }

    if (!details.reference) {
      types.forEach((t) => {
        typeErrors[t] = [...(typeErrors[t] || []), new ValidationErrorItem('details.reference', 'Field is required')];
      });
    }

    const res = await TransferwiseValidator.validateRequirements(
      requirements, details,
    );
    const validTypes = {};

    Object.entries(res).forEach(([t, result]) => {
      if (result instanceof Error) {
        typeErrors[t] = [...(typeErrors[t] || []), ...result.errors];
      } else {
        validTypes[t] = result;
      }
    });

    if (isEmpty(validTypes)) {
      if (type) {
        // return specific errors for selected type
        const error = new ValidationError('Invalid transfer', typeErrors[type]);
        throw error;
      } else {
        // return errors grouped by type
        const validationErrors = Object.entries(typeErrors).map(([path, errors]) => (
          new NestedValidationErrorList(path, `Invalid transfer for type '${path}'`, errors)
        ));
        const error = new ValidationError('Invalid transfer', validationErrors);
        throw error;
      }
    }
    // return the first valid type
    const [, validatedDetails] = Object.entries(validTypes)[0];

    return validatedDetails;
  }

  static isSameValue(localVal, remoteVal, fieldKey) {
    if (!remoteVal) {
      // here we have no remote value and we may have local ones
      return true;
    }
    if (!localVal) {
      // here we have a remote value but not a local one
      logger.error(`missing local value for remote value ${fieldKey}: ${remoteVal}`);
      return false;
    }
    const localValue = isString(localVal) ? localVal.trim() : localVal;
    const remoteValue = isString(remoteVal) ? remoteVal.trim() : remoteVal;
    if (isEqual(localValue, remoteValue)) {
      return true;
    }
    let same = false;
    switch ((fieldKey || '').toLowerCase()) {
      case 'legaltype':
        same = remoteValue === localValue
          || (remoteValue === 'PERSON' && localValue === 'PRIVATE')
          || (remoteValue === 'INSTITUTION' && localValue === 'BUSINESS');
        break;
      case 'iban':
        same = remoteValue.replace(/\s/g, '') === localValue.replace(/\s/g, '');
        break;
      case 'sortcode':
      case 'rut':
      case 'accountnumber':
        same = remoteValue.replace(/-/g, '') === localValue.replace(/-/g, '');
        break;
      case 'bic':
        same = localValue && localValue.includes(remoteValue);
        break;
      case 'address':
        same = Object.entries(localValue).every(([key, value]) => {
          if (key === 'firstLine') {
            return true;
          }
          if (key === 'occupation' && !remoteValue[key]) {
            return true;
          }
          if (key === 'countryCode' && !remoteValue[key]) {
            return isEqual(value, remoteValue.country);
          }

          return isEqual(
            value && value.toLowerCase(),
            remoteValue[key] && remoteValue[key].toLowerCase(),
          );
        });
        break;
      default:
        same = localValue.trim() === remoteValue.trim();
        break;
    }
    return same;
  }

  static mayBeSameRecipient({ accountHolderName, currency, details }, remoteRecipient) {
    if (!accountHolderName || !remoteRecipient.accountHolderName) {
      throw new Error('recipient missing accountHolderName');
    }
    if (!currency || !remoteRecipient.currency) {
      throw new Error('recipient missing currency');
    }
    if (!details || !remoteRecipient.details) {
      throw new Error('recipient missing details');
    }
    const localName = accountHolderName.toLowerCase().trim();
    const remoteName = remoteRecipient.accountHolderName.toLowerCase().trim();
    if (localName !== remoteName) {
      // logger.info(
      //   `different name '${accountHolderName}' '${remoteRecipient.accountHolderName}'`,
      // );
      return false;
    }
    if (currency !== remoteRecipient.currency) {
      // logger.info('different currency', currency, remoteRecipient.currency);
      return false;
    }

    if (!details) {
      return false;
    }
    return true;
  }

  static isSameRecipient({ accountHolderName, type, currency, details }, remoteRecipient) {
    if (!TransferwiseValidator.mayBeSameRecipient({
      accountHolderName, currency, details, type,
    }, remoteRecipient)) {
      return false;
    }
    const hasDifferences = Object.entries(details)
      .filter(([key]) => !['accountHolderName'].includes(key))
      .some(([key, value]) => {
        const remoteValue = remoteRecipient.details[key];
        const isSame = TransferwiseValidator.isSameValue(value, remoteValue, key);
        if (!isSame) {
          logger.error('different', key, 'remote', remoteValue, 'local', value);
        }
        return !isSame;
      });

    if (hasDifferences) {
      return false;
    }
    return true;
  }

  static mayBeSameTransfer({
    recipientId, plausibleRecipientIds, currency, createdAfterDate,
  }, remoteTransfer) {
    if (!recipientId) {
      return false;
    }
    if (!currency) {
      throw new Error('invoice is missing currency');
    }
    if (!createdAfterDate) {
      throw new Error('invoice is missing createdAfterDate');
    }
    if (remoteTransfer.targetAccount.toString() !== recipientId.toString()) {
      if (plausibleRecipientIds.every(id => (
        remoteTransfer.targetAccount.toString() !== id.toString()
      ))) {
        // logger.debug(`${remoteTransfer.id} mismatch => different recipient
        // ${remoteTransfer.targetAccount.toString()} != ${recipientId.toString()}`);
        return false;
      }
    }
    if (!moment(remoteTransfer.created).isAfter(createdAfterDate)) {
      // logger.debug(`${remoteTransfer.id} mismatch => ${remoteTransfer.created} is not
      // after ${createdAfterDate}`);
      return false;
    }
    if (currency.toUpperCase() !== remoteTransfer.targetCurrency) {
      // logger.debug(`${remoteTransfer.id} mismatch => different currency
      // ${currency.toUpperCase()} != ${remoteTransfer.targetCurrency}`);
      return false;
    }

    return true;
  }

  static isSameTransfer(localTransfer, remoteTransfer) {
    const { reference } = localTransfer;
    if (!TransferwiseValidator.mayBeSameTransfer(localTransfer, remoteTransfer)) {
      return false;
    }

    if (reference !== remoteTransfer.reference) {
      // logger.debug(`${remoteTransfer.id} mismatch =>
      // different reference ${remoteTransfer.reference} != ${reference}`);
      return false;
    }

    // if (Big(remoteTransfer.sourceValue).toFixed(2) !== Big(amount).toFixed(2)) {
    //   logger.debug(`different amount
    //   ${Big(remoteTransfer.sourceValue).toFixed(2)} != ${Big(amount).toFixed(2)}`);
    //   return false;
    // }

    return true;
  }

  /**
   * Determines whether the transferwise requirements dictate that we should
   * refetch them, given the user has filled in some details.
   *
   * @returns TransferwiseRecipient
   */
  static shouldRefetch(requirements, details) {
    const shouldRefetch = requirements.some(type => (
      type.fields.some(fieldGroup => (
        fieldGroup.group.some(field => (
          field.refreshRequirementsOnChange && get(details, field.key)
        ))
      ))
    ));
    return shouldRefetch;
  }

  static getDetailsToRefresh(details, requirements, refreshedDetails = {}) {
    const newDetailsToRefresh = {};
    requirements.forEach((type) => {
      type.fields.forEach((fieldGroup) => {
        fieldGroup.group.forEach((field) => {
          if (
            field.refreshRequirementsOnChange
            && !refreshedDetails[field.key]
            && get(details, field.key)
          ) {
            newDetailsToRefresh[field.key] = get(details, field.key);
          }
        });
      });
    });
    if (isEmpty(newDetailsToRefresh)) {
      return {};
    }
    return { ...refreshedDetails, ...newDetailsToRefresh };
  }

  static print(requirements, { logging = console, omitFieldParams = [
    'validationAsync', 'valuesAllowed',
  ] } = {}) {
    logging.log(
      JSON.stringify(
        requirements.map(r => flatten(r.fields.map(f => f.group)).map(
          f => omit(f, omitFieldParams),
        )), null, 2,
      ),
    );
  }

  static getAllFieldKeys(requirements) {
    return flatten(requirements.map(r => flatten(r.fields.map(f => f.group)).map(f => f.key)));
  }


  static getAllPossibleFields(requirements) {
    return flatten(requirements.map(r => flatten(r.fields.map(f => f.group)).map(f => f)));
  }

  static getAllowedCountryCodes(requirements) {
    const anyPossibleField = TransferwiseValidator.getAllPossibleFields(requirements);
    const countryFields = anyPossibleField.filter(field => field.name === 'Country');
    if (countryFields.length === 0) {
      // requirements do not depend on country, all countries are valid
      return Object.keys(countryNames);
    }
    const allowedCountryCodes = uniq(flatten(
      countryFields.map(f => f.valuesAllowed.map(v => v.key)),
    ));
    return allowedCountryCodes;
  }

  static findFieldByKey(requirements, key) {
    let matchingFieldSpec = null;
    requirements.forEach((typeSpec) => {
      typeSpec.fields.forEach((fieldGroupSpec) => {
        fieldGroupSpec.group.forEach((fieldSpec) => {
          if (fieldSpec.key === key) {
            matchingFieldSpec = fieldSpec;
          }
        });
      });
    });
    return matchingFieldSpec;
  }

  static includesAddressFields(requirements) {
    const explicitlyRequested = requirements
      .reduce((showAddressFields, r) => r.showAddressFields || showAddressFields, false);
    if (explicitlyRequested) {
      return true;
    }
    const fieldKeys = TransferwiseValidator.getAllFieldKeys(requirements);
    const hasAddressFields = !!fieldKeys.find(k => k.startsWith('address.'));
    if (hasAddressFields) {
      return true;
    }

    return false;
  }

  static mergeAddressFields(requirements, addressFields) {
    requirements.forEach((r) => {
      const extraFields = addressFields.filter(f => !r.fields.some(rf => fieldsAreSame(rf, f)));
      Object.assign(r, { fields: [
        ...r.fields,
        ...extraFields,
      ] });
    });

    return requirements;
  }

  static getMaxReferenceLength(requirements) {
    const transferRequirements = requirements.find(x => x.type === 'transfer');
    if (!transferRequirements) {
      throw new Error('corrupt requirements');
    }
    const field = TransferwiseValidator.findFieldByKey(requirements, 'reference');
    if (!field) {
      throw new Error('cannot find reference requirements');
    }
    return field.maxLength;
  }

  static getTruncatedReference(requirements, reference) {
    const maxReferenceLength = TransferwiseValidator.getMaxReferenceLength(requirements);
    const truncatedReference = truncatePaymentReferenceForLength(
      reference, maxReferenceLength,
    );
    return truncatedReference;
  }

  static getCleanedTransferDetails(requirements, originalDetails) {
    const truncatedReference = TransferwiseValidator.getTruncatedReference(
      requirements,
      originalDetails.reference,
    );
    if (truncatedReference !== originalDetails.reference) {
      logger.warn(`truncating reference '${originalDetails.reference}' to '${truncatedReference}'`);
    }
    return {
      ...originalDetails,
      reference: truncatedReference,
    };
  }
}

export default TransferwiseValidator;
