
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { BS_STYLE, PROFILE_TABS, USER_TYPE } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { userProjectsApiUrl, orgUserProfileUrl } from 'people/urls';
import ManageProjectsModal, { MODAL_ID } from 'projects/assets/js/components/ManageProjectsModal.jsx';
import ProjectCardItem from 'projects/assets/js/components/ProjectCardItem.jsx';
import { PROJECT_STATUS } from 'projects/assets/js/constants';

const PeopleProjectsTab = ({ userCard, viewerId }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { orgAlias, userType: peopleType } = useParams();
  const history = useHistory();
  const isModalOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));

  const hasOrgAccess = useSelector(getHasOrgAccess);
  const componentName = PeopleProjectsTab.GetComponentName();
  const {
    hasLoaded, items: projects, pagination,
  } = useSelector(state => getListState(state, componentName));

  const [showInactiveProjects, setShowInactiveProjects] = useState(false);

  const includeStatuses = [PROJECT_STATUS.POSTED, PROJECT_STATUS.INPROGRESS];
  if (showInactiveProjects) {
    includeStatuses.push(PROJECT_STATUS.COMPLETED);
  }
  const queryString = qs.stringify({ ...qs.parse(location.search), status: includeStatuses });

  const loadProjects = () => dispatch(fetchListDS({
    componentName, url: `${userProjectsApiUrl(orgAlias, userCard.user.id)}?${queryString}`,
  }));

  useEffect(() => {
    loadProjects();
  }, [showInactiveProjects, queryString]);

  useEffect(() => {
    if (!isModalOpen) {
      loadProjects();
    }
  }, [isModalOpen]);

  const isAnyManager = hasOrgAccess({
    requireManager: true, requireFinController: true, requireOrgCreator: true,
  });
  const isThemself = viewerId === userCard.user.id;

  const getProfileUrl = tab => orgUserProfileUrl(
    orgAlias,
    peopleType === PEOPLE_TYPE.PROVIDERS ? USER_TYPE.PROVIDER : USER_TYPE.MANAGER,
    userCard.user.id,
    tab,
  );

  if (!isAnyManager && !isThemself) {
    return (
      <RedirectRoute
        from={location.pathname}
        status={302}
        to={getProfileUrl(PROFILE_TABS.EXPERIENCE)}
      />
    );
  }

  const canManageProjects = isAnyManager && !isThemself;

  return (
    <div className="page page--projects page--no-shadow-effect mb-5 pt-4">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <h2>Projects</h2>
          <div className="d-flex align-items-center">
            <TDSwitch
              className="mr-3"
              onClick={() => {
                const newShowInactiveProjects = !showInactiveProjects;
                setShowInactiveProjects(newShowInactiveProjects);
                if (!newShowInactiveProjects) {
                  const queryParams = qs.parse(location.search);
                  if (queryParams.page) {
                    history.push([
                      getProfileUrl(PROFILE_TABS.PROJECTS), qs.stringify(omit(queryParams, 'page')),
                    ].join('?'));
                  }
                }
              }}
              selected={showInactiveProjects}
            />
            <span className="mr-2">Show inactive projects</span>
            {canManageProjects && (
              <>
                <TDButton
                  className="ml-5"
                  label="Manage projects"
                  onClick={() => dispatch(modalOpenAC(MODAL_ID))}
                  variant={BS_STYLE.PRIMARY}
                />
                <ManageProjectsModal userCard={userCard} />
              </>
            )}
          </div>
        </div>
        {hasLoaded && (
          <TDList
            items={projects}
            pagination={pagination}
            cardItem={{
              component: ProjectCardItem,
              props: {
                orgAlias,
              },
            }}
            emptyListMessage="No projects found"
            listClassName="project-list"
          />
        )}
        {!hasLoaded && <ProjectListSkeleton />}
      </div>
    </div>
  );
};

PeopleProjectsTab.GetComponentName = () => 'PeopleProjectsTab';

PeopleProjectsTab.propTypes = {
  userCard: userCardSpec.isRequired,
  viewerId: PropTypes.number.isRequired,
};

export default PeopleProjectsTab;
