import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { findIndex } from 'lodash';

import { BS_SIZE, CURRENCY, CURRENCY_TO_COUNTRY_CODE, CURRENCY_DESCRIPTION, REACT_SELECT_STYLES } from 'core/assets/js/constants';
import { isSSR } from 'core/assets/js/config/checks';
import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

const SingleValue = ({ children, data, ...props }) => (
  <components.SingleValue {...props}>
    <div className="d-flex align-items-center w-100">
      <CountryFlagIcon countryISOCode={CURRENCY_TO_COUNTRY_CODE[data.value]} />
      <span data-testid="currency-select-field" className="currency-select__currency ml-3">{data.value?.toUpperCase()}</span>
      <span className="currency-select__currency-description ml-2">{CURRENCY_DESCRIPTION[data.value]}</span>
    </div>
  </components.SingleValue>
);
SingleValue.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  data: PropTypes.object.isRequired,
};

const Option = ({ children, data, ...props }) => ( // eslint-disable-line react/no-multi-comp
  <components.Option {...props}>
    <div className="d-flex align-items-center w-100">
      <CountryFlagIcon countryISOCode={CURRENCY_TO_COUNTRY_CODE[data.value]} />
      <span className="currency-select__currency ml-3">{data.value?.toUpperCase()}</span>
      <span className="currency-select__currency-description ml-2">{CURRENCY_DESCRIPTION[data.value]}</span>
    </div>
  </components.Option>
);
Option.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  data: PropTypes.object.isRequired,
};

const filterOptions = (options, value) => (
  options.filter(opt => (Array.isArray(value) ? value.includes(opt.value) : opt.value === value))
);

/**
 * Final form compatible currency select field
 *
 * It displays a bootstrap decorated input field with a label and an error
 * message
 */
const CurrencySelectField = ({ // eslint-disable-line react/no-multi-comp
  additionalError,
  className,
  currencyOptions,
  defaultOptionText,
  disabled,
  input,
  isClearable,
  label,
  meta: { error, initial, pristine, submitError, touched },
  popOverContent,
  popOverSize,
  popOverTitle,
  required,
  sublabel,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = ((error || submitError) && touched) || showAdditionalError;
  const groupClassName = ['form-group'];

  const options = Object.keys(currencyOptions).map(key => ({
    value: currencyOptions[key], label: key,
  }));

  const [selectedOptions, setSelectedOptions] = useState(filterOptions(options, input.value));

  if (className) {
    groupClassName.push(className);
  }

  if (hasError) {
    groupClassName.push('has-error');
  }

  const idx = findIndex(options, c => c.value === initial);
  const initialValue = idx > -1
    ? options[idx]
    : null;

  const customFilter = (option, searchText) => {
    return (
      option.value.toLowerCase().includes(searchText.toLowerCase())
      || CURRENCY_DESCRIPTION[option.value].toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const handleChange = (item, { action, removedValue }) => {
    let newOptions = [...selectedOptions];

    switch (action) {
      case 'select-option':
      case 'value':
        newOptions = Array.isArray(item) ? item : [item];
        break;
      case 'deselect-option':
      case 'remove-value':
      case 'pop-value':
        if (removedValue?.isFixed) {
          return;
        }

        newOptions = newOptions.filter(opt => opt.value !== removedValue.value);
        break;
      case 'clear':
        newOptions = newOptions.filter(opt => !!opt.isFixed);
        break;
      default:
        break;
    }

    setSelectedOptions(newOptions);

    const newValue = newOptions[0]?.value;

    input.onChange(newValue);
  };

  const customStyles = {
    ...REACT_SELECT_STYLES,
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#eaeaea' : '#fff',
        ':hover': {
          ...styles[':active'],
          backgroundColor: isSelected ? '#eaeaea' : '#fafafa',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? '#eaeaea' : '#fff',
        },
      };
    },
  };

  return (
    <div className={groupClassName.join(' ')}>
      { label && (
        <TDLabel
          name={input.name}
          label={label}
          required={required}
          sublabel={sublabel}
          popOverTitle={popOverTitle}
          popOverContent={popOverContent}
          popOverSize={popOverSize}
        />
      )}

      {showAdditionalError && (
        <span className="help-block d-inline-block mt-3">
          {additionalError}
        </span>
      )}

      <div className="form-control-container custom-select-field">
        <Select
          isClearable={isClearable}
          isDisabled={disabled}
          name={input.name}
          menuPlacement="auto"
          menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
          classNamePrefix="currency-select"
          id={`${input.name}-dropdown`}
          components={{ SingleValue, Option }}
          placeholder={defaultOptionText}
          options={options}
          getOptionValue={opt => opt.value}
          defaultValue={initialValue}
          isSearchable
          filterOption={customFilter}
          styles={customStyles}
          onChange={handleChange}
        />
      </div>

      { hasError && (
        <span className="help-block d-inline-block mt-3">
          {error || submitError}
        </span>
      )}
    </div>
  );
};

CurrencySelectField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  currencyOptions: PropTypes.object,
  disabled: PropTypes.bool,
  defaultOptionText: PropTypes.string,
  input: PropTypes.object.isRequired,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    initial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pristine: PropTypes.bool,
    submitError: PropTypes.string,
    touched: PropTypes.bool,
  }),
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.string,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
};

CurrencySelectField.defaultProps = {
  additionalError: '',
  disabled: false,
  className: null,
  currencyOptions: CURRENCY,
  defaultOptionText: 'Select a currency...',
  isClearable: true,
  meta: {
    error: false,
    initial: null,
    pristine: true,
    touched: false,
  },
  label: '',
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
  required: false,
  sublabel: '',
};

export default withField(CurrencySelectField);
