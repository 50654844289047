import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BS_STYLE } from 'core/assets/js/constants';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { deleteFinControllerDS } from 'settings/assets/js/data-services/settings';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';

export const MODAL_ID = 'remove-fincon-modal';

class RemoveFinancialController extends React.Component {
  constructor(props) {
    super(props);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleConfirm() {
    const {
      dispatch, onConfirmSuccess, organization: { alias: orgAlias }, manager: { userId },
    } = this.props;
    return dispatch(deleteFinControllerDS({
      orgAlias, userId,
    })).then(onConfirmSuccess);
  }

  handleClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const { isModalOpen, manager, organization } = this.props;

    if (!isModalOpen || !manager) {
      return null;
    }

    const confirmBody = (
      <React.Fragment>
        <p>{`You are about to remove ${manager.name} from the position of Financial Controller.`}</p>
        <p>
          {`They will still be able allocate the budget given to them to new or ongoing projects.
          However, they will no longer receive invoices from Providers who are working on projects
          initiated by ${organization.name} on TalentDesk.io or from TalentDesk.io.`}
        </p>
        <p>
          Are you sure you want to revoke their Financial Controller rights?
        </p>
      </React.Fragment>
    );

    return (
      <ModalConfirm
        data-testid="remove-financial-controller-confirm"
        onClose={this.handleClose}
        onConfirm={this.handleConfirm}
        open={isModalOpen}
        heading="Remove Financial Controller"
        body={confirmBody}
        confirmStyle={BS_STYLE.DANGER}
        confirmLabel="Yes"
        cancelLabel="No"
      />
    );
  }
}

RemoveFinancialController.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  organization: orgSpec.isRequired,
  onConfirmSuccess: PropTypes.func.isRequired,
  manager: profileSpec,
};

RemoveFinancialController.defaultProps = {
  manager: null,
};

const mapStateToProps = (state) => {
  const modalPayload = getModalPayload(state, MODAL_ID);
  return {
    organization: selectActiveOrg(state),
    isModalOpen: getIsModalOpen(state, MODAL_ID),
    manager: modalPayload?.manager,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveFinancialController);
