import React from 'react';
import PropTypes from 'prop-types';

import DeliverablesList from 'core/assets/js/components/DiscussionBoard/DeliverablesList.jsx';

const TaskDeliverablesTab = ({ accessControl, onInfoUpdated }) => (
  <div className="task-deliverables">
    <DeliverablesList
      allowedActions={accessControl}
      onInfoUpdated={onInfoUpdated}
    />
  </div>
);

TaskDeliverablesTab.propTypes = {
  accessControl: PropTypes.object,
  onInfoUpdated: PropTypes.func,
};

TaskDeliverablesTab.defaultProps = {
  accessControl: {},
  onInfoUpdated: () => {},
};

export default TaskDeliverablesTab;
