import { pick } from 'lodash';

import { REQ_TYPE } from 'core/assets/js/constants';
import {
  activateUserCardApiUrl,
  deactivateUserCardApiUrl,
  deleteNoteApiUrl,
  demoteToProviderApiUrl,
  editNoteApiUrl,
  fetchNotesApiUrl,
  postNoteApiUrl,
  promoteToManagerApiUrl,
  toggleEmploymentStatusApiUrl,
} from 'people/urls';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import { validateObject } from 'core/assets/js/lib/utils';
import { listRemoveItemAC, listReplaceItemAC, listUpdateItemAC } from 'core/assets/js/ducks/list';

// notes action types
export const PEOPLE_NOTES_LOAD_SUCCESS = 'people/NOTES_LOAD_SUCCESS';
export const PEOPLE_NOTES_RESET = 'people/NOTES_RESET';
export const PEOPLE_NOTES_POST_SUCCESS = 'people/NOTES_POST_SUCCESS';
export const PEOPLE_NOTES_EDIT_SUCCESS = 'people/NOTES_EDIT_SUCCESS';
export const PEOPLE_NOTES_DELETE_SUCCESS = 'people/NOTES_DELETE_SUCCESS';

export const notesLoadSuccessAC = notes => ({
  type: PEOPLE_NOTES_LOAD_SUCCESS,
  items: notes,
});
export const notesResetAC = () => ({
  type: PEOPLE_NOTES_RESET,
});
export const notesPostSuccessAC = note => ({
  type: PEOPLE_NOTES_POST_SUCCESS,
  item: note,
});
export const notesEditSuccessAC = note => ({
  type: PEOPLE_NOTES_EDIT_SUCCESS,
  item: note,
});
export const notesDeleteSuccessAC = note => ({
  type: PEOPLE_NOTES_DELETE_SUCCESS,
  note,
});

/**
 * Handles the notes list related state
 * @param state
 * @param action
 * @returns {*}
 */
const noteListInitialState = {
  items: [],
};

const noteList = (state = noteListInitialState, action) => {
  let idx;
  switch (action.type) {
    case PEOPLE_NOTES_LOAD_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case PEOPLE_NOTES_RESET:
      return noteListInitialState;
    case PEOPLE_NOTES_POST_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.item],
      };
    case PEOPLE_NOTES_EDIT_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id !== action.item.id) {
            return item;
          }
          return action.item;
        }),
      };
    case PEOPLE_NOTES_DELETE_SUCCESS:
      idx = state.items.findIndex(item => item.id === action.note.id);
      return {
        ...state,
        items: [...state.items.slice(0, idx), ...state.items.slice(idx + 1)],
      };
    default:
      return state;
  }
};

export const initialState = {
  noteList: noteListInitialState,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PEOPLE_NOTES_LOAD_SUCCESS:
    case PEOPLE_NOTES_RESET:
    case PEOPLE_NOTES_POST_SUCCESS:
    case PEOPLE_NOTES_EDIT_SUCCESS:
    case PEOPLE_NOTES_DELETE_SUCCESS:
      return {
        ...state,
        noteList: noteList(state.noteList, action),
      };
    default:
      return state;
  }
};

// Data services
export const fetchNotesDS = (url = '', params = {}, authedAxios = null) => fetchDataDS({
  authedAxios,
  validate: () => validateObject(params, ['orgAlias', 'userId']),
  fetchApiUrl: () => fetchNotesApiUrl(params.orgAlias, params.userId, url),
  fetchDataAC: responseData => notesLoadSuccessAC(responseData),
});

export const postNoteDS = (params, values) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    validate: () => validateObject(params, ['orgAlias', 'userId']),
    pushApiUrl: postNoteApiUrl(params.orgAlias, params.userId),
    pushDataAC: responseData => notesPostSuccessAC(responseData),
    values,
  })
);

export const editNoteDS = (params, values) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: editNoteApiUrl(values.noteId, params.orgAlias, params.userId),
    validate: () => validateObject(params, ['orgAlias', 'userId']),
    pushDataAC: responseData => notesEditSuccessAC(responseData),
    values,
  })
);

export const deleteNoteDS = (noteId, params) => {
  return pushDataDS({
    reqType: REQ_TYPE.DELETE,
    validate: () => validateObject(params, ['orgAlias', 'userId']),
    pushApiUrl: deleteNoteApiUrl(noteId, params.orgAlias, params.userId),
    pushDataAC: responseData => notesDeleteSuccessAC(responseData.note),
  });
};

export const activateUserCardDS = (orgAlias, userId, componentName) => (
  pushDataDS({
    pushApiUrl: activateUserCardApiUrl(orgAlias, userId),
    pushDataAC: responseData => listReplaceItemAC(responseData, componentName),
    reqType: REQ_TYPE.PUT,
  })
);

export const deactivateUserCardDS = (orgAlias, userId, componentName, values) => (
  pushDataDS({
    pushApiUrl: deactivateUserCardApiUrl(orgAlias, userId),
    pushDataAC: responseData => listReplaceItemAC(responseData, componentName),
    reqType: REQ_TYPE.PUT,
    values,
  })
);

export const promoteToManagerDS = ({ componentName, orgAlias, userId }) => (
  pushDataDS({
    pushApiUrl: promoteToManagerApiUrl(orgAlias, userId),
    pushDataAC: updatedUserCard => listRemoveItemAC(updatedUserCard.id, componentName),
    reqType: REQ_TYPE.PUT,
  })
);

export const demoteToProviderDS = ({ componentName, onboardingFormId, orgAlias, userId }) => (
  pushDataDS({
    pushApiUrl: demoteToProviderApiUrl(orgAlias, userId),
    pushDataAC: ({ userCard }) => listRemoveItemAC(userCard.id, componentName),
    reqType: REQ_TYPE.PUT,
    values: { onboardingFormId },
  })
);

export const toggleEmploymentStatusDS = ({ componentName, onboardingFormId, orgAlias, userId }) => (
  pushDataDS({
    pushApiUrl: toggleEmploymentStatusApiUrl(orgAlias, userId),
    pushDataAC: ({ userCard }) => listUpdateItemAC(
      userCard.id,
      pick(userCard, ['allowedActions', 'isEmployee', 'status', 'statusLabel']),
      componentName,
    ),
    reqType: REQ_TYPE.PUT,
    values: { onboardingFormId },
  })
);

export default reducer;
