import PropTypes from 'prop-types';
import React from 'react';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const STEPS = [
  {
    description: 'Have a chat - understand the scope of work',
    icon: ICON.COMMENT,
    isLast: false,
    name: 'Negotiate terms, availability and rates.',
  },
  {
    description:
      'Once you have negotiated with an organization, you will get invited to the organization as a contractor.',
    icon: ICON.ENVELOPE_INVITE,
    isLast: true,
    name: 'Get an invite. Start working together. Make $$',
  },
];

const GuestChatModal = ({ show, handleClose }) => (
  <ModalSimple
    body={(
      <div className="chat-modal d-flex flex-column py-5 align-items-center">
        <i
          className={`${ICON.CROSS} chat-modal__close`}
          onClick={handleClose}
        />
        <h1 className="chat-modal__title font-weight-light text-center mb-4">
          Opportunity knocks! A new prospective client wants to talk to you
        </h1>
        <h3 className="chat-modal__sub text-capitalize text-center font-weight-light mt-0 pt-0">
          Chat allows you to negotiate your terms, availability and rates. Use
          this opportunity to learn about the organization.
        </h3>
        <div className="row w-100 text-center justify-content-around">
          {STEPS.map((step) => (
            <React.Fragment key={step.name}>
              <div
                className="col-5 col-sm-12 col-xs-12 col-md-5 px-0"

              >
                <i className={`${step.icon} chat-modal__icon p-5`} />
                <h4 className="chat-modal__semibold">{step.name}</h4>
                <p>{step.description}</p>
              </div>
              {!step.isLast && (
                <i className={`${ICON.ARROW_RIGHT} chat-modal__arrow my-2 mx-0`} />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="justify-content-center mt-4">
          <TDButton
            variant={BS_STYLE.PRIMARY}
            label="Chat now"
            onClick={handleClose}
          />
        </div>
      </div>
    )}
    noFooter
    noHeader
    onClose={handleClose}
    open={show}
    size="xl"
  />
);

GuestChatModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

GuestChatModal.defaultProps = {
  show: false,
};

export default GuestChatModal;
