import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, submit } from 'redux-form';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { uploaderProjectTasksPath } from 'core/urls';
import { ICON, BS_STYLE, MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import InputField, { INPUT_TYPE } from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import FileUploaderField from 'core/assets/js/components/ReduxFormFields/FileUploaderField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

export const FORM_ID = 'DiscussionBoardMessageForm';

const MessageForm = ({
  handleSubmit,
  onCancel,
  withAttachments,
  pristine,
  submitting,
  buttonStyle,
  buttonLabel,
  accessControl,
  match: { params: { orgAlias, id: projectId } },
}) => {
  const [showAttachments, setShowAttachments] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="message"
        type={INPUT_TYPE.MARKDOWN_TEXTAREA}
        placeholder="Type your message..."
        component={InputField}
        label="Message"
        autoFocus
      />

      { showAttachments && withAttachments && (
        <div>
          <Field
            name="attachments"
            type="text"
            component={FileUploaderField}
            maxFiles={MAX_UPLOAD_FILES}
            path={uploaderProjectTasksPath(orgAlias, projectId)}
            acceptAll
          />
        </div>
      )}

      <div className="text-right mt-4">
        { withAttachments && (
          <div className="row d-block d-sm-flex align-items-center px-4">
            <div className="">
              <a
                className="db-attachments-toggle imitate-link"
                onClick={() => setShowAttachments(() => !showAttachments)}
              >
                <i className={ICON.ATTACHMENT} />
                {showAttachments ? 'Hide attachments' : 'Add attachments'}
              </a>
            </div>

            { accessControl && accessControl.canUploadDeliverables && (
              <Field
                name="is_deliverable"
                type="checkbox"
                component={InputField}
                label="Mark the attachments as deliverables"
                groupClassName="mb-0  ml-auto"
              />
            )}
          </div>
        )}

        <div className="text-right mt-4">
          {onCancel && (
            <TDButton
              onClick={onCancel}
              type="button"
              variant={BS_STYLE.DEFAULT}
              disabled={submitting}
              label="Cancel"
            />
          )}

          <TDButton
            data-testid="message-form-send-message-button"
            className={pristine ? 'btn--pristine' : 'btn--dirty'}
            type="submit"
            variant={buttonStyle}
            disabled={submitting}
            label={buttonLabel}
          />
        </div>
      </div>
    </form>
  );
};

MessageForm.propTypes = {
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  withAttachments: PropTypes.bool,
  buttonStyle: PropTypes.string,
  buttonLabel: PropTypes.string,
  accessControl: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
};

MessageForm.defaultProps = {
  withAttachments: false,
  onCancel: null,
  buttonStyle: BS_STYLE.PRIMARY,
  buttonLabel: 'Send',
  accessControl: {},
};

const ConnectedMessageForm = reduxForm({
  form: FORM_ID,
  onSubmit: submit,
})(MessageForm);

export default withRouter(ConnectedMessageForm);
