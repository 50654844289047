import React from 'react';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReportsList from 'analytics/assets/js/components/ReportsList.jsx';
import { fetchReportsInfoDS } from 'analytics/assets/js/data-services/analytics';
import { dataReportsSpec } from 'analytics/assets/js/lib/objectSpecs';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import {
  routerHistorySpec,
  routerMatchContentsSpec,
} from 'core/assets/js/lib/objectSpecs';


class ReportsView extends React.Component {
  static FetchData({ dispatch, params, url = '', authedAxios, componentName }) {
    return dispatch(fetchReportsInfoDS({
      orgAlias: params.orgAlias, url, authedAxios, componentName }));
  }

  static GetComponentName() {
    return 'ReportsView';
  }

  render() {
    const { history, match: { params: { orgAlias } }, reports } = this.props;
    const breadcrumbs = [
      {
        title: 'Select a report',
        url: null,
      },
    ];

    return (
      <>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--reports">
          <div className="container">
            <TDApiConnected
              duck="list"
              component={this.constructor}
              skeletonComponent={SettingsPageSkeleton}
            >
              <ReportsList
                orgAlias={orgAlias}
                onSubmitSelectedReport={(targetUrl) => {
                  history.push(targetUrl);
                }}
                reports={sortBy(reports, 'title')}
              />
            </TDApiConnected>
          </div>
        </div>
      </>
    );
  }
}

ReportsView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  reports: dataReportsSpec.isRequired,
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ReportsView.GetComponentName());

  return {
    reports: listState.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ReportsViewConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ReportsView);

export default withRouter(ReportsViewConnected);
