import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import { createCustomFieldTemplateDS } from 'interviews/assets/js/data-services/interviews';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TemplateBuilderForm from 'settings/assets/js/components/TemplateBuilderForm.jsx';
import { getEntityTypeByUrlParam } from 'interviews/assets/js/lib/utils';
import { orgTemplatesManageUrl } from 'settings/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';


function CustomFieldsTemplateCreateView({ match, history, dispatch }) {
  const { orgAlias, entityType } = match.params;
  const orgManageInvitationTemplatesUrl = orgTemplatesManageUrl(orgAlias);
  const breadcrumbs = [
    {
      title: 'Templates',
      url: orgManageInvitationTemplatesUrl,
    },
    {
      title: 'Custom fields',
      url: orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.CUSTOM_FIELDS),
    },
    {
      title: (
        <span>{`Create ${entityType.slice(0, -1)} template`}</span>
      ),
      url: null,
    },
  ];

  const handleSubmit = async values => (
    dispatch(createCustomFieldTemplateDS({
      orgAlias, entityType, values,
    }))
      .then(() => {
        history.push(orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.CUSTOM_FIELDS));
        toastr.success('Well Done!', 'Template created successfully.');
      })
      .catch((error) => {
        toastr.error('Oops!', 'We couldn’t create your Template');

        if (error.response.status === 400) {
          return error.response.data;
        }

        throw error;
      })
  );

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--invitation-template-create">
        <div className="container-fluid">
          <TemplateBuilderForm
            templateType={getEntityTypeByUrlParam(entityType)}
            onSubmit={handleSubmit}
            initialValues={{ isMandatory: false }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
CustomFieldsTemplateCreateView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const CustomFieldsTemplateCreateViewConnected = connect(
  null,
  mapDispatchToProps,
)(CustomFieldsTemplateCreateView);

export default withRouter(CustomFieldsTemplateCreateViewConnected);
