import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SubmissionError, submit } from 'redux-form';
import { isEmpty, pick, isEqual, without } from 'lodash';
import queryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  getActiveUserCardPermissionChecker, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { RATE_SUGGEST_VALUE } from 'rates/assets/js/constants';
import { BS_STYLE, PROJECT_TABS, API_DATE_FORMAT, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { projectViewUrl } from 'projects/urls';
import {
  acceptTaskInvitationDS,
  acceptTaskBudgetAmendmentDS,
  cancelTaskBudgetAmendmentDS,
  cancelTaskInvitationDS,
  completeTaskAssignmentDS,
  completeTaskDS,
  fetchProjectTaskMessagesDS,
  leaveTaskDS,
  reOpenTaskAssignmentDS,
  rejectTaskBudgetAmendmentDS,
  rejectTaskInvitationDS,
  requestTaskBudgetAmendmentDS,
  requestTaskCompletionDS,
  reviewTaskDS,
  sendTaskMessageDS,
  stopTaskDS,
} from 'projects/assets/js/data-services/tasks';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectSpec, projectTaskSpec, taskParticipantSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  TASK_ACTION,
  TASK_ACTION_LABEL,
  TASK_ASSIGNMENT_STATUS,
} from 'projects/assets/js/constants';
import MessageForm, { FORM_ID as MESSAGE_FORM_ID } from 'core/assets/js/components/DiscussionBoard/MessageForm.jsx';
import InvitationResponseForm, { FORM_ID as INVITATION_FORM_ID } from 'core/assets/js/components/DiscussionBoard/InvitationResponseForm.jsx';
import BudgetAmendmentRequestForm, { FORM_ID as BUDGET_FORM_ID } from 'core/assets/js/components/DiscussionBoard/BudgetAmendmentRequestForm.jsx';
import ReviewForm, { FORM_ID as REVIEW_FORM_ID } from 'core/assets/js/components/DiscussionBoard/ReviewForm.jsx';
import TaskAssigneesPanel from 'projects/assets/js/components/TaskAssigneesPanel.jsx';
import { createTimeOffDS } from 'accounts/assets/js/data-services/timeoff';
import PendingTaskAssignments from 'projects/assets/js/components/PendingTaskAssignments.jsx';
import { PERMISSIONS } from 'roles/assets/js/constants';

const ACCEPTED_TASK_MODAL_ID = 'accepted-task-modal';
const ACTION_CONFIRMATION_MODAL_ID = 'action-confirmation-modal';
const CHECK_AVAILABILITY_MODAL_ID = 'check-availability-modal';

const StopTaskConfirmation = ({ task }) => (
  <React.Fragment>
    <p>
      You are about to stop the task “
      {task.title}
      ”. If you proceed, you will not be able to message your providers, nor they’ll be able
      to upload their deliverables so
      {' '}
      <strong>make sure you have everything in hand</strong>
    </p>
    <p>
      Are you sure you want to do that?
    </p>
  </React.Fragment>
);

StopTaskConfirmation.propTypes = {
  task: projectTaskSpec.isRequired,
};

const LeaveTaskConfirmation = () => (
  <React.Fragment>
    <p>
      Once you leave the task you will not be able to collaborate with your manager or
      request a payment for your work. Make sure you have submitted a worksheet for the
      work you have already completed.
    </p>
    <p>
      Are you sure you want to leave this task?
    </p>
  </React.Fragment>
);

const SUCCESS_ACTIONS = [
  TASK_ACTION.MANAGE_ASSIGNMENT_INVITATION,
  TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION,
  TASK_ACTION.ACCEPT_RATE_INVITATION,
  TASK_ACTION.REQUEST_COMPLETION,
  TASK_ACTION.ACCEPT_COMPLETION_INVITATION,
  TASK_ACTION.REVIEW,
  TASK_ACTION.COMPLETE,
  TASK_ACTION.COMPLETE_ASSIGNMENT,
];

const DANGER_ACTIONS = [
  TASK_ACTION.REJECT_ASSIGNMENT_INVITATION,
  TASK_ACTION.REJECT_RATE_INVITATION,
  TASK_ACTION.REJECT_COMPLETION_INVITATION,
  TASK_ACTION.STOP,
  TASK_ACTION.LEAVE,
  TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION,
  TASK_ACTION.CANCEL_RATE_INVITATION,
  TASK_ACTION.CANCEL_COMPLETION_INVITATION,
];

const FORM_TO_COMPONENT = {
  [TASK_ACTION.MANAGE_ASSIGNMENT_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ token: action.data.invitation.token }),
  },
  [TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ token: action.data.invitation.token }),
  },
  [TASK_ACTION.REJECT_ASSIGNMENT_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ token: action.data.invitation.token }),
  },
  [TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ token: action.data.invitation.token }),
  },
  [TASK_ACTION.PROPOSE_TASK_RATE]: {
    component: BudgetAmendmentRequestForm,
    componentPropsGetter: action => ({ currency: action?.assignment?.currency }),
    formId: BUDGET_FORM_ID,
    formInitialValuesGetter: ({ activeUserCard }) => ({
      rate_id: RATE_SUGGEST_VALUE,
      rate_unit: activeUserCard.rateUnit,
    }),
  },
  [TASK_ACTION.ACCEPT_RATE_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ user_id: action.targetUserId }),
  },
  [TASK_ACTION.REJECT_RATE_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ user_id: action.targetUserId }),
  },
  [TASK_ACTION.CANCEL_RATE_INVITATION]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ user_id: action.targetUserId }),
  },
  [TASK_ACTION.REQUEST_COMPLETION]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
  },
  [TASK_ACTION.ACCEPT_COMPLETION_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ token: action.data.invitation.token }),
  },
  [TASK_ACTION.REJECT_COMPLETION_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ token: action.data.invitation.token }),
  },
  [TASK_ACTION.CANCEL_COMPLETION_INVITATION]: {
    component: InvitationResponseForm,
    formId: INVITATION_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ token: action.data.invitation.token }),
  },
  [TASK_ACTION.COMPLETE]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
  },
  [TASK_ACTION.COMPLETE_ASSIGNMENT]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ user_id: action.targetUserId }),
  },
  [TASK_ACTION.REVIEW]: {
    component: ReviewForm,
    formId: REVIEW_FORM_ID,
    formInitialValuesGetter: ({ action }) => ({ user_id: action.targetUserId }),
    componentPropsGetter: action => ({ assigneeName: action.user.profile.firstName }),
  },
  [TASK_ACTION.REOPEN]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
  },
  [TASK_ACTION.MESSAGE]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
  },
  [TASK_ACTION.STOP]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
  },
  [TASK_ACTION.LEAVE]: {
    component: MessageForm,
    formId: MESSAGE_FORM_ID,
  },
};

const FORM_TO_DATASERVICE = {
  [TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION]: acceptTaskInvitationDS,
  [TASK_ACTION.ACCEPT_RATE_INVITATION]: acceptTaskBudgetAmendmentDS,
  [TASK_ACTION.ACCEPT_COMPLETION_INVITATION]: acceptTaskInvitationDS,

  [TASK_ACTION.REJECT_RATE_INVITATION]: rejectTaskBudgetAmendmentDS,
  [TASK_ACTION.REJECT_ASSIGNMENT_INVITATION]: rejectTaskInvitationDS,
  [TASK_ACTION.REJECT_COMPLETION_INVITATION]: rejectTaskInvitationDS,

  [TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION]: cancelTaskInvitationDS,
  [TASK_ACTION.CANCEL_RATE_INVITATION]: cancelTaskBudgetAmendmentDS,
  [TASK_ACTION.CANCEL_COMPLETION_INVITATION]: cancelTaskInvitationDS,

  [TASK_ACTION.MESSAGE]: sendTaskMessageDS,
  [TASK_ACTION.PROPOSE_TASK_RATE]: requestTaskBudgetAmendmentDS,

  [TASK_ACTION.REQUEST_COMPLETION]: requestTaskCompletionDS,
  [TASK_ACTION.COMPLETE]: completeTaskDS,
  [TASK_ACTION.COMPLETE_ASSIGNMENT]: completeTaskAssignmentDS,
  [TASK_ACTION.REOPEN]: reOpenTaskAssignmentDS,
  [TASK_ACTION.REVIEW]: reviewTaskDS,
  [TASK_ACTION.STOP]: stopTaskDS,
  [TASK_ACTION.LEAVE]: leaveTaskDS,
};

const ACTIONS_WITH_CONFIRMATION = {
  [TASK_ACTION.STOP]: {
    heading: 'Stop the task?',
    confirmLabel: 'Stop task',
    confirmStyle: BS_STYLE.DANGER,
    bodyComponent: StopTaskConfirmation,
  },
  [TASK_ACTION.LEAVE]: {
    heading: 'Leave task',
    confirmLabel: 'Leave task',
    confirmStyle: BS_STYLE.DANGER,
    bodyComponent: LeaveTaskConfirmation,
  },
};

const getActionFromQuery = (search, actions = []) => {
  const { action } = queryString.parse(search);

  if (!action) {
    return {};
  }

  const currentAction = actions.find(a => a.name === action);

  if (!currentAction) {
    return {};
  }

  return currentAction;
};

// eslint-disable-next-line react/no-multi-comp
class FormsWrapper extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { location: { search }, actions } = props;
    const newAction = getActionFromQuery(search, actions);

    if (!isEqual(newAction, state.currentAction)) {
      return {
        currentAction: newAction,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentAction: {},
      actionConfirmed: false,
    };

    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTaskAccepted = this.handleTaskAccepted.bind(this);
    this.handleTaskRejected = this.handleTaskRejected.bind(this);
    this.getActiveAction = this.getActiveAction.bind(this);
    this.getFormComponent = this.getFormComponent.bind(this);
    this.confirmAction = this.confirmAction.bind(this);
    this.shouldShowConfirmationModal = this.shouldShowConfirmationModal.bind(this);
    this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);
    this.handleModalConfirmed = this.handleModalConfirmed.bind(this);
    this.updateQueryAction = this.updateQueryAction.bind(this);
    this.handleTaskAssignmentLeft = this.handleTaskAssignmentLeft.bind(this);
    this.handleTimeOffSubmit = this.handleTimeOffSubmit.bind(this);
    this.reloadMessages = this.reloadMessages.bind(this);

    this.postSubmitCallbacks = {
      [TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION]: this.handleTaskAccepted,
      [TASK_ACTION.REJECT_ASSIGNMENT_INVITATION]: this.handleTaskRejected,
      [TASK_ACTION.LEAVE]: this.handleTaskAssignmentLeft,
      [TASK_ACTION.PROPOSE_TASK_RATE]: this.reloadMessages,
      [TASK_ACTION.ACCEPT_RATE_INVITATION]: this.reloadMessages,
      [TASK_ACTION.REJECT_RATE_INVITATION]: this.reloadMessages,
      [TASK_ACTION.CANCEL_RATE_INVITATION]: this.reloadMessages,
    };
  }

  /**
   * @returns {Object} the active action we're showing in the form
   */
  getActiveAction() {
    const { currentAction } = this.state;
    const { actions, isEmbeddedMode, history } = this.props;
    if (isEmbeddedMode) {
      return history?.location?.state?.formAction || { };
    }

    // if the user is able to review a task assignment,
    // and there's not a given current action
    // we need to have the review form shown by default
    // otherwise, we show the action that's been selected by the user
    const reviewAction = actions.find(act => act.name === TASK_ACTION.REVIEW);
    if (reviewAction && isEmpty(currentAction)) {
      return reviewAction;
    }

    if (!isEmpty(currentAction)) {
      return currentAction;
    }

    return {};
  }

  /**
   * @returns {Object} the component to render for the form
   */
  getFormComponent() {
    const activeAction = this.getActiveAction();

    if (!activeAction) {
      return {};
    }

    return FORM_TO_COMPONENT[activeAction.name] || {};
  }

  /**
   * Replaces the action param in the search
   *
   * @param {String} actionName the action name
   */
  updateQueryAction(actionName) {
    const { isEmbeddedMode, location: { pathname, search }, history } = this.props;
    if (isEmbeddedMode) {
      history.push({
        pathname,
        search,
        state: {
          formAction: actionName !== '' ? { name: actionName } : {},
        },
      });
      return;
    }
    const urlState = {
      ...without(queryString.parse(search), 'action'),
    };

    if (actionName) {
      urlState.action = actionName;
    }

    const url = `${pathname}?${queryString.stringify(urlState)}`;
    history.replace(url);
  }

  showForm(action) {
    const { onFormToggle } = this.props;
    this.updateQueryAction(action);
    onFormToggle();
  }

  hideForm() {
    const { onFormToggle } = this.props;
    this.updateQueryAction('');
    onFormToggle();
  }

  handleTaskAccepted() {
    const { dispatch, task } = this.props;
    if (task.deadline && task.startsOn) {
      dispatch(modalOpenAC(CHECK_AVAILABILITY_MODAL_ID));
    } else {
      dispatch(modalOpenAC(ACCEPTED_TASK_MODAL_ID));
    }
  }

  handleTaskRejected() {
    const { history, match: { params: { orgAlias, id: projectId } } } = this.props;
    history.push(projectViewUrl(orgAlias, projectId, PROJECT_TABS.TASKS));
  }

  handleTaskAssignmentLeft() {
    const { history, match: { params: { orgAlias, id: projectId } } } = this.props;
    history.push(projectViewUrl(orgAlias, projectId, PROJECT_TABS.TASKS));
  }

  reloadMessages(values = {}) {
    const {
      dispatch, location: { search }, match: { params }, parentComponentName,
    } = this.props;
    const { orgAlias } = params;
    const { page } = queryString.parse(search);
    dispatch(fetchProjectTaskMessagesDS({
      orgAlias,
      projectId: values?.projectId || params.id,
      taskId: values?.taskId || params.taskId,
      componentName: parentComponentName,
      page,
      participantId: values?.participantId || params.userId,
      reset: true,
    }));
  }

  async confirmAction(actionName, values) {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(ACTION_CONFIRMATION_MODAL_ID, { actionName, values }));
    this.setState({ actionConfirmed: false });
  }

  handleCloseConfirmModal() {
    const { dispatch, isEmbeddedMode } = this.props;
    if (!isEmbeddedMode) {
      dispatch(modalCloseAC(ACTION_CONFIRMATION_MODAL_ID));
    }
  }

  async handleModalConfirmed(formId) {
    if (!formId) {
      throw new Error('No form id was specified');
    }

    const { dispatch } = this.props;
    this.setState({ actionConfirmed: true }, async () => {
      await dispatch(submit(formId));
      this.setState({ actionConfirmed: false });
    });
  }

  async handleSubmit(values) {
    const activeAction = this.getActiveAction();

    if (!activeAction) {
      throw new Error('Current action is not defined');
    }

    const { actionConfirmed } = this.state;
    const confirmables = Object.keys(ACTIONS_WITH_CONFIRMATION);
    const requiresConfirmation = !actionConfirmed && confirmables.includes(activeAction.name);

    if (requiresConfirmation) {
      await this.confirmAction(activeAction.name, values);
    } else {
      await this.submitForm(values);
    }
  }

  async submitForm(values) {
    const { isEmbeddedMode, task } = this.props;
    const {
      dispatch,
      parentComponentName: componentName,
      match: { params: { orgAlias } },
      onStateUpdated,
    } = this.props;

    let {
      match: { params: { id: projectId, taskId, userId: participantId } },
    } = this.props;

    if (isEmbeddedMode) {
      projectId = task.projectId;
      taskId = task.id;
      participantId = task.ownerId;
    }
    const activeAction = this.getActiveAction();

    if (!activeAction) {
      throw new Error('Current action is not defined');
    }

    const dataService = FORM_TO_DATASERVICE[activeAction.name];

    if (!dataService) {
      throw new Error('No data service found to fullfil the request');
    }

    try {
      await dispatch(
        dataService({
          orgAlias, projectId, taskId, participantId, values, componentName,
        }),
      );
      await onStateUpdated();

      this.handleCloseConfirmModal();
      this.hideForm();

      const submitCallback = this.postSubmitCallbacks[activeAction.name];
      if (typeof submitCallback === 'function') {
        submitCallback({ participantId, projectId, taskId });
      }
    } catch (err) {
      this.handleCloseConfirmModal();
      if (err.errors) {
        throw new SubmissionError(err.errors);
      }
      toastr.error('Oh Snap!', err._error || err.message);
    }
  }

  handleTimeOffSubmit() {
    const { dispatch, task: {
      title: taskTitle,
      startsOn: periodStart,
      deadline: periodEnd,
    }, project: {
      title: projectTitle,
    } } = this.props;
    return dispatch(createTimeOffDS({
      periodStart,
      periodEnd,
      reason: 'Unavailable',
      description: `Busy working on another project (${projectTitle} > ${taskTitle})`,
    })).then(() => {
      dispatch(modalCloseAC(ACCEPTED_TASK_MODAL_ID));
      toastr.success('Well Done!', 'Time off created successfully.');
    });
  }

  shouldShowConfirmationModal() {
    const { isConfirmationModalOpen, confirmationModalPayload: { actionName } } = this.props;
    if (!isConfirmationModalOpen || !actionName) {
      return false;
    }

    return Object.keys(ACTIONS_WITH_CONFIRMATION).includes(actionName);
  }

  render() {
    const {
      accessControl,
      actions,
      activeUserCard,
      confirmationModalPayload: { actionName: confirmationAction },
      dispatch,
      hasPermission,
      isAcceptedTaskModalOpen,
      isCheckAvailabilityModalOpen,
      isEmbeddedMode,
      onStateUpdated,
      project,
      task,
    } = this.props;

    let ActiveFormComponent;
    let formInitialValues = {};
    let componentProps = {};
    let formId;
    let buttonStyle = BS_STYLE.PRIMARY;

    const activeAction = this.getActiveAction();
    const messageAction = actions.find(ac => ac.name === TASK_ACTION.MESSAGE);
    const isAssignmentActionActive = (
      !isEmpty(activeAction) && activeAction.name === TASK_ACTION.ASSIGN
    );

    if (activeAction && !isEmpty(activeAction)) {
      let formInitialValuesGetter;
      let componentPropsGetter;

      ({
        formId,
        component: ActiveFormComponent,
        formInitialValuesGetter,
        componentPropsGetter,
      } = this.getFormComponent());
      const actionName = activeAction.name;

      if (SUCCESS_ACTIONS.includes(actionName)) {
        buttonStyle = BS_STYLE.SUCCESS;
      } else if (DANGER_ACTIONS.includes(actionName)) {
        buttonStyle = BS_STYLE.DANGER;
      }

      if (formInitialValuesGetter) {
        formInitialValues = formInitialValuesGetter({ action: activeAction, activeUserCard });
      }

      if (componentPropsGetter) {
        componentProps = componentPropsGetter(activeAction);
      }

      if (isEmbeddedMode) {
        componentProps.taskId = activeAction?.data?.invitation?.taskId;
      }
    }

    let confirmationModalProps = {};
    const shouldShowConfirmation = this.shouldShowConfirmationModal();
    if (shouldShowConfirmation) {
      const BodyComponent = ACTIONS_WITH_CONFIRMATION[confirmationAction].bodyComponent;

      confirmationModalProps = {
        ...pick(
          ACTIONS_WITH_CONFIRMATION[confirmationAction], 'heading', 'confirmLabel', 'confirmStyle',
        ),
        body: <BodyComponent task={task} />,
      };
    }

    if (activeAction.name === TASK_ACTION.COMPLETE) {
      const pendingAssignments = task.assignments.filter(a => (
        a.status === TASK_ASSIGNMENT_STATUS.PENDING
      ));
      if (pendingAssignments.length) {
        return <PendingTaskAssignments pendingAssignments={pendingAssignments} />;
      }
    }

    const noActionAndCanMessage = !!messageAction && isEmpty(activeAction);
    const isMessaging = noActionAndCanMessage || activeAction.name === TASK_ACTION.MESSAGE;

    if (isMessaging && !accessControl.canMessageInDiscussion) {
      return (
        <TDSystemMessage title="You cannot send messages" type={BS_STYLE.DANGER}>
          Discussions have been disabled by this organization. Please get in touch with your manager
          for further information.
        </TDSystemMessage>
      );
    }

    const actionIsProposeRate = activeAction.name === TASK_ACTION.PROPOSE_TASK_RATE;

    const taskAssigneeIsNegotiatingInitialRate = (
      actionIsProposeRate
      && activeAction.assignment?.status === TASK_ASSIGNMENT_STATUS.PENDING
      && activeAction.assignment?.userId === activeUserCard.user.id
    );

    if (
      actionIsProposeRate
      && !taskAssigneeIsNegotiatingInitialRate
      && !hasPermission(PERMISSIONS.CAN_SUGGEST_TASK_RATES)
    ) {
      return (
        <TDSystemMessage title="You cannot propose a new rate" type={BS_STYLE.DANGER}>
          You do not have permission to propose a new task rate.
        </TDSystemMessage>
      );
    }

    return (
      <React.Fragment>
        {shouldShowConfirmation && (
          <ModalConfirm
            open={shouldShowConfirmation}
            {...confirmationModalProps}
            onClose={this.handleCloseConfirmModal}
            onConfirm={() => this.handleModalConfirmed(formId)}
          />
        )}
        <ModalConfirm
          open={isCheckAvailabilityModalOpen}
          onClose={() => dispatch(modalCloseAC(CHECK_AVAILABILITY_MODAL_ID))}
          heading="Set Time Off"
          body={(
            <div className="task-modal-started">
              <p>
                Do you want to appear as unavailable during the task period
                {' '}
                <strong>
                  {moment(task.startsOn, API_DATE_FORMAT).format(DATE_FORMAT_DEFAULT)}
                </strong>
                {' - '}
                <strong>
                  {moment(task.deadline, API_DATE_FORMAT).format(DATE_FORMAT_DEFAULT)}
                </strong>
                {' ?'}
              </p>
            </div>
          )}
          confirmLabel="Schedule time off"
          onConfirm={this.handleTimeOffSubmit}
        />

        <ModalSimple
          open={isAcceptedTaskModalOpen}
          onClose={() => dispatch(modalCloseAC(ACCEPTED_TASK_MODAL_ID))}
          heading="Are you ready to begin this task?"
          body={(
            <div className="task-modal-started">
              <p>Here are a few handy tips for once you get started:</p>
              <ul className="ml-4 pl-4">
                <li>
                  Keep in touch with your Manager and send your deliverables
                  through the Message Board
                </li>
                <li>
                  Communicate your progress through the Checklist
                </li>
                <li>
                  When you are done Mark the task as completed so that your
                  Manager can review your work and leave you a review
                </li>
              </ul>
            </div>
          )}
        />

        {noActionAndCanMessage && (
          <input
            data-testid="forms-wrapper-input-message"
            className="form-control input-lg db-form-toggle-input"
            value=""
            placeholder="Start typing your message..."
            onFocus={() => this.showForm(messageAction.name)}
            onChange={() => {}}
          />
        )}

        {!isEmpty(activeAction) && !!ActiveFormComponent && (
          <div className="discussion-board__form">
            <ActiveFormComponent
              project={project}
              onSubmit={this.handleSubmit}
              onCancel={this.hideForm}
              buttonLabel={TASK_ACTION_LABEL[activeAction.name]}
              buttonStyle={buttonStyle}
              withAttachments={activeAction.name === TASK_ACTION.MESSAGE}
              initialValues={formInitialValues}
              accessControl={accessControl}
              {...componentProps}
            />
          </div>
        )}

        {isAssignmentActionActive && (
          <TaskAssigneesPanel
            onAssigneesInvited={onStateUpdated}
            task={task}
          />
        )}
      </React.Fragment>
    );
  }
}

FormsWrapper.propTypes = {
  accessControl: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  activeUserCard: userCardSpec,
  confirmationModalPayload: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  hasPermission: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isAcceptedTaskModalOpen: PropTypes.bool,
  isCheckAvailabilityModalOpen: PropTypes.bool,
  isConfirmationModalOpen: PropTypes.bool,
  isEmbeddedMode: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFormToggle: PropTypes.func,
  onStateUpdated: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  participant: taskParticipantSpec,
  project: projectSpec.isRequired,
  task: projectTaskSpec.isRequired,
};

FormsWrapper.defaultProps = {
  accessControl: {},
  actions: [],
  activeUserCard: {},
  confirmationModalPayload: {},
  isAcceptedTaskModalOpen: false,
  isCheckAvailabilityModalOpen: false,
  isConfirmationModalOpen: false,
  onFormToggle: () => {},
  participant: null,
};

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = state => ({
  activeUserCard: selectActiveUserCard(state),
  confirmationModalPayload: getModalPayload(state, ACTION_CONFIRMATION_MODAL_ID) || {},
  hasPermission: getActiveUserCardPermissionChecker(state),
  isAcceptedTaskModalOpen: getIsModalOpen(state, ACCEPTED_TASK_MODAL_ID),
  isCheckAvailabilityModalOpen: getIsModalOpen(state, CHECK_AVAILABILITY_MODAL_ID),
  isConfirmationModalOpen: getIsModalOpen(state, ACTION_CONFIRMATION_MODAL_ID),
});

const FormsWrapperConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormsWrapper);

export default withRouter(FormsWrapperConnected);
