import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isFunction, omit } from 'lodash';
import { bindActionCreators } from 'redux';

import Logger from 'core/assets/js/lib/Logger';
import { cartesianProduct } from 'core/assets/js/lib/utils';
import { hasWebpackHash, webpackHash } from 'core/assets/js/config/checks';
import * as list from 'core/assets/js/ducks/list';
import * as view from 'core/assets/js/ducks/view';
import * as invitation from 'invitations/assets/js/ducks/invitation';
import * as pendingCount from 'core/assets/js/ducks/pendingCount';
import * as pendingInvitations from 'core/assets/js/ducks/pendingInvitations';

const getResetAC = (duck) => {
  let resetAC;
  switch (duck) {
    case 'account':
      resetAC = null;
      break;
    case 'list':
      resetAC = list.listResetAC;
      break;
    case 'view':
      resetAC = view.viewResetAC;
      break;
    case 'invitation':
      resetAC = invitation.invitationResetAC;
      break;
    case 'notifications':
      break;
    case 'pendingCount':
      resetAC = pendingCount.pendingCountResetAC;
      break;
    case 'pendingInvitations':
      resetAC = pendingInvitations.pendingInvitationsResetAC;
      break;
    default:
      throw new Error('Cannot resolve duck');
  }
  return resetAC;
};

const getSelector = (duck) => {
  let selector;
  switch (duck) {
    case 'list':
      selector = list.getListState;
      break;
    case 'view':
      selector = view.getViewState;
      break;
    case 'invitation':
      selector = invitation.selectActiveInvitation;
      break;
    case 'pendingCount':
      selector = pendingCount.getPendingCount;
      break;
    case 'pendingInvitations':
      selector = pendingInvitations.getPendingInvitations;
      break;
    case 'account':
    case 'notifications':
      break;
    default:
      throw new Error('Cannot resolve duck');
  }

  return selector;
};

const withDuck = (WrappedComponent) => {
  class _WithDuck extends React.Component {
    constructor(props) {
      super(props);
      if (hasWebpackHash) {
        this.logger = new Logger(`tdapiconnected:${props.descriptor}:duck:${webpackHash()}`);
      } else {
        this.logger = new Logger(`tdapiconnected:${props.descriptor}:duck`);
      }
      if (hasWebpackHash) {
        this.hash = webpackHash();
      }
    }

    componentWillUnmount() {
      if (hasWebpackHash && this.hash !== webpackHash()) {
        // hot reloaded, do not unmount
        this.logger.log('not unmounting because of hot reload');
        return;
      }

      const { persistent, reset, descriptor } = this.props;
      if (!persistent) {
        cartesianProduct(reset, descriptor).forEach(([fn, key]) => {
          if (isFunction(fn)) {
            fn(key);
          }
        });
      }
    }

    render() {
      const { duckState, descriptor } = this.props;
      const passthroughProps = omit(this.props, ['reset', 'duckState']);

      return (
        <WrappedComponent
          {...duckState}
          {...passthroughProps}
          descriptor={descriptor}
        />
      );
    }
  }

  _WithDuck.propTypes = {
    persistent: PropTypes.bool,
    duck: PropTypes.string.isRequired,
    descriptor: PropTypes.string.isRequired,
    // redux state props
    reset: PropTypes.func,
    duckState: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  };

  _WithDuck.defaultProps = {
    persistent: false,
    duckState: null,
    reset: () => {},
  };

  const mapStateToProps = (state, props) => {
    const { duck, descriptor } = props;
    if (!duck) {
      throw new Error('You need to specify the duck used by withDuck');
    }

    if (!descriptor) {
      throw new Error(`You need to specify the storeKey for duck ${duck}`);
    }

    let duckState;
    const selector = getSelector(duck);
    if (selector) {
      duckState = selector(state, descriptor);
    }
    return {
      duckState,
    };
  };

  const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    reset: getResetAC(props.duck),
  }, dispatch);

  const WithDuckConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(_WithDuck);

  return WithDuckConnect;
};

export default withDuck;
