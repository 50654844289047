import React from 'react';

import OrgSettingsPaymentsBankForm from 'settings/assets/js/components/OrgSettingsPaymentsBankForm.jsx';
import DeferredPaymentsSetting from 'settings/assets/js/components/DeferredPaymentsSetting.jsx';


const OrgSettingsPaymentsMethodsTab = () => {

  return (
    <>
      <OrgSettingsPaymentsBankForm />
      <DeferredPaymentsSetting />
    </>
  );
};

export default OrgSettingsPaymentsMethodsTab;
