import React from 'react';
import PropTypes from 'prop-types';

import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import OrgInvitationTemplatesTab from 'settings/assets/js/components/OrgInvitationTemplatesTab.jsx';
import CustomFieldTemplatesTab from 'settings/assets/js/components/CustomFieldTemplatesTab.jsx';

/**
 * Returns different manage template tab depending which tab is active
 * @param tab The active tab
 * @returns {Component}
 */
const ManageTemplatesTabContent = ({ tab }) => {
  switch (tab) {
    case SETTINGS_TEMPLATE_TABS.CUSTOM_FIELDS:
      return (
        <CustomFieldTemplatesTab />
      );
    case SETTINGS_TEMPLATE_TABS.ORG_INVITATIONS:
    default:
      return (
        <OrgInvitationTemplatesTab />
      );
  }
};

ManageTemplatesTabContent.propTypes = {
  tab: PropTypes.string.isRequired,
};


export default ManageTemplatesTabContent;
