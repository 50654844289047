import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import ChartBurnDown from 'core/assets/js/components/ChartBurnDown.jsx';
import { fetchBudgetBurnDownAnalyticsApiUrl } from 'analytics/urls';
import BurnDownSkeleton from 'core/assets/js/components/Skeleton/BurnDownSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';


const ProjectBudgetBurnDownChart = (props) => {
  const { analytics, currencySymbol } = props;
  return (
    <TDApiConnected
      duck="list"
      storeKey="budgetBurnDownAnalytics"
      fetchData={
        ({ dispatch, params, url, authedAxios, componentName }) => {
          const { orgAlias, id } = params;
          const budgetBurnDownApiUrl = fetchBudgetBurnDownAnalyticsApiUrl(orgAlias, id, url);
          return dispatch(fetchListDS({
            url: budgetBurnDownApiUrl, componentName, authedAxios,
          }));
        }}
      blockingLoading
      skeletonComponent={BurnDownSkeleton}
    >
      <ChartBurnDown
        data={analytics.length > 1 ? analytics : []}
        prefix={currencySymbol}
        chartName="Budget"
      />
    </TDApiConnected>
  );
};


ProjectBudgetBurnDownChart.propTypes = {
  analytics: PropTypes.array,
  currencySymbol: PropTypes.string,
};

ProjectBudgetBurnDownChart.defaultProps = {
  analytics: [],
  currencySymbol: '',
};

const mapStateToProps = state => ({
  analytics: getListState(state, 'budgetBurnDownAnalytics').items,
});

const ProjectBurnDownChartConnected = connect(
  mapStateToProps,
  null,
)(ProjectBudgetBurnDownChart);

export default ProjectBurnDownChartConnected;
