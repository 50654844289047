/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject } from 'lodash';
import { connect } from 'react-redux';

import { parseJson, formatDate } from 'core/assets/js/lib/utils';
import { selectActiveUserType } from 'organizations/assets/js/reducers/organizations';
import { TYPE as CUSTOM_FIELD_TYPE } from 'interviews/assets/js/constants';
import { answerDetailsSpec } from 'interviews/assets/js/lib/objectSpecs';
import { isFieldVisibleToUserType, getSelectedChoicesAsText } from 'interviews/assets/js/lib/utils';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import AttachmentList from 'core/assets/js/components/FileUploader/AttachmentList.jsx';

const AnswerRenderer = ({
  activeUserType,
  answer: { body, question, question: { label, payload, type } },
  labelClass,
  valueClass,
  withWrapper,
  wrapperClass,
}) => {
  if (!isFieldVisibleToUserType(question, activeUserType)) {
    return null;
  }

  let representation = '';
  let files = [];

  switch (type) {
    case CUSTOM_FIELD_TYPE.TEXT:
    case CUSTOM_FIELD_TYPE.TEXTAREA:
    case CUSTOM_FIELD_TYPE.NUMBER:
    case CUSTOM_FIELD_TYPE.MONEY:
      representation = (
        <MarkdownText
          text={body || '-'}
          disallowedTypes={['paragraph']}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.TEXT_BLOB:
      representation = (
        <MarkdownText
          text={payload?.text || '-'}
          disallowedTypes={['paragraph']}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.SELECT:
    case CUSTOM_FIELD_TYPE.RADIO:
    case CUSTOM_FIELD_TYPE.CHECKBOX:
      representation = (
        <MarkdownText
          text={getSelectedChoicesAsText(body, { separator: '\n' })}
          disallowedTypes={['paragraph']}
        />
      );
      break;
    case CUSTOM_FIELD_TYPE.FILE:
    case CUSTOM_FIELD_TYPE.MULTIFILE:
      files = [];
      if (Array.isArray(body)) {
        files = body;
      } else if (typeof body === 'string') {
        files = parseJson(body);
      }
      representation = !isEmpty(files)
        ? <AttachmentList attachments={files} />
        : '-';
      break;
    case CUSTOM_FIELD_TYPE.DATE:
      representation = formatDate(body);
      break;
    case CUSTOM_FIELD_TYPE.YESNO:
      representation = isObject(body) && body.isYes ? 'Yes' : 'No';

      if (isObject(body) && body.isYes && Array.isArray(body.answers)) {
        const answersRepresentation = body.answers.map(a => a.text).filter(a => !!a);
        representation += `: ${answersRepresentation.join(', ')}`;
      }
      break;
    default:
      break;
  }

  const content = (
    <>
      <div className={labelClass}>
        <MarkdownText
          text={label}
          disallowedTypes={['paragraph']}
        />
      </div>

      <div className={valueClass}>
        {representation}
      </div>
    </>
  );

  if (!withWrapper) {
    return content;
  }

  return <div className={wrapperClass}>{content}</div>;
};

AnswerRenderer.propTypes = {
  answer: answerDetailsSpec.isRequired,
  activeUserType: PropTypes.number,
  labelClass: PropTypes.string,
  valueClass: PropTypes.string,
  withWrapper: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

AnswerRenderer.defaultProps = {
  activeUserType: null,
  labelClass: '',
  valueClass: '',
  withWrapper: true,
  wrapperClass: '',
};

const mapStateToProps = state => ({
  activeUserType: selectActiveUserType(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const AnswerRendererConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnswerRenderer);

export default AnswerRendererConnected;
