import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProjectOpportunityCardItem from 'projects/assets/js/components/ProjectOpportunityCardItem.jsx';
import { OPPORTUNITIES_TABS } from 'projects/assets/js/constants';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import {
  opportunityCreateUrl, projectOpportunitiesListApiUrl, projectOpportunitiesUrl,
} from 'projects/urls';

const ProjectOpportunitiesListView = () => {
  const params = useParams();
  const { orgAlias, tab: paramsTab } = params;
  const history = useHistory();
  const { userRole: { isAnyManager } } = useSelector(selectActiveUserCard);

  const tab = isAnyManager ? null : (paramsTab || OPPORTUNITIES_TABS.OPEN);

  const queryStringParams = {};
  const changesArray = [];
  if (!isAnyManager) {
    if (tab === OPPORTUNITIES_TABS.APPLICATIONS) {
      queryStringParams.only_applied = true;
    }
    changesArray.push(tab);
  }

  const componentName = ProjectOpportunitiesListView.GetComponentName(params);
  const { hasLoaded, items, pagination } = fetchDataHook({
    changesArray,
    componentName,
    duck: 'list',
    url: projectOpportunitiesListApiUrl(params.orgAlias),
    queryStringParams,
  });

  let ctaButtonItems = null;
  if (isAnyManager) {
    ctaButtonItems = [{
      label: 'New opportunity',
      onClick: () => history.push(opportunityCreateUrl(params.orgAlias, true)),
      variant: BS_STYLE.PRIMARY,
    }];
  }

  return (
    <>
      <ContentHeader
        breadcrumbs={[{ title: 'Opportunities', url: null }]}
        ctaButtonItems={ctaButtonItems}
      >
        {!isAnyManager && (
          <div className="horizontal-scroll">
            <TabBar
              activeKey={tab}
              tabSpec={[
                {
                  key: OPPORTUNITIES_TABS.OPEN,
                  label: 'Open opportunities',
                  href: projectOpportunitiesUrl(orgAlias),
                },
                {
                  key: OPPORTUNITIES_TABS.APPLICATIONS,
                  label: 'My applications',
                  href: projectOpportunitiesUrl(orgAlias, OPPORTUNITIES_TABS.APPLICATIONS),
                },
              ]}
            />
          </div>
        )}
      </ContentHeader>
      <div className="page page--projects">
        <div className="container">
          {hasLoaded && (
            <TDList
              cardItem={{
                component: ProjectOpportunityCardItem, props: { listComponentName: componentName },
              }}
              emptyListMessage="No project opportunities found."
              items={items}
              pagination={pagination}
            />
          )}
          {!hasLoaded && <ProjectListSkeleton />}
        </div>
      </div>
    </>
  );
};

ProjectOpportunitiesListView.GetComponentName = () => 'ProjectOpportunitiesListView';

export default ProjectOpportunitiesListView;
