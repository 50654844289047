import React from 'react';
import { Card } from 'react-bootstrap';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const SkillDirectoryListSkeleton = () => (
  <Card>
    <Card.Body>
      <div className="row">
        <div className="col-auto">
          <div>
            <RectangleSkeleton width="200" height="20" />
          </div>
          <div className="mt-3">
            <RectangleSkeleton width="100" height="10" />
          </div>
        </div>
        <div className="col-auto ml-auto">
          <RectangleSkeleton width="50" height="20" />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-auto">
          <div>
            <RectangleSkeleton width="200" height="20" />
          </div>
          <div className="mt-3">
            <RectangleSkeleton width="100" height="10" />
          </div>
        </div>
        <div className="col-auto ml-auto">
          <RectangleSkeleton width="50" height="20" />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-auto">
          <div>
            <RectangleSkeleton width="200" height="20" />
          </div>
          <div className="mt-3">
            <RectangleSkeleton width="100" height="10" />
          </div>
        </div>
        <div className="col-auto ml-auto">
          <RectangleSkeleton width="50" height="20" />
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default SkillDirectoryListSkeleton;
