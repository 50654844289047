import React from 'react';
import PropTypes from 'prop-types';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';

const ContactDetailsCard = ({ email, profile: { phone, address } }) => (
  <div className="user-contact-details-card">
    { address && address.description && (
      <div className="row">
        <div className="col-2 discreet">
          Address:
        </div>
        <div className="col-10">
          {address.description}
        </div>
      </div>
    )}
    { phone && (
      <div className="row">
        <div className="col-2 discreet">
          Phone:
        </div>
        <div className="col-10">
          {phone}
        </div>
      </div>
    )}
    { email && (
      <div className="row">
        <div className="col-2 discreet">
          Email:
        </div>
        <div className="col-10">
          <a href={`mailto: ${email}`}>{email}</a>
        </div>
      </div>
    )}
  </div>
);

ContactDetailsCard.propTypes = {
  email: PropTypes.string.isRequired,
  profile: profileSpec.isRequired,
};

export default ContactDetailsCard;
