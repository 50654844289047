import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import { DATE_FORMAT_CHARTS } from 'core/assets/js/constants';
import { fitData } from 'core/assets/js/lib/statistics';

const MAX_PROJECTION_DAYS = 90;

const enumerateDaysBetweenDates = (startDate, endDate) => {
  const dates = [];

  const currDate = startDate.startOf('day');
  const lastDate = endDate.startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

const ChartBurnDown = ({ data, prefix, suffix, chartName }) => {
  if (data.length <= 0) {
    return (
      <div data-testid="chart-burn-down-empty">
        We are collecting more data to draw your chart. Stay tuned!
      </div>
    );
  }
  const sourceData = [].concat(data);
  const gridLineColor = '#f4f4f4';
  const linRegression = fitData(sourceData);

  // it is used for formatting the label that shows up when hovering over a point
  // in a burn down chart
  const pointFormat = () => {
    let pointFormatted = '';
    if (prefix) {
      pointFormatted = `{point.series.name}: ${prefix}{point.y:,.2f}`;
    }
    if (suffix) {
      pointFormatted = `{point.series.name}: {point.y:,.2f}${suffix}`;
    }
    return pointFormatted;
  };

  // Find last day
  const lastItem = sourceData[sourceData.length - 1];
  const lastDate = lastItem && moment(lastItem[0]);
  const lastCalculatedDate = moment(linRegression.x(0));
  const projectDuration = moment(lastItem[0]).diff(sourceData[0][0], 'days');
  const projectionDuration = Math.floor(projectDuration  * 0.45);
  const days = enumerateDaysBetweenDates(lastDate, lastCalculatedDate).map(d => [
    d.valueOf(),
    linRegression.y(d.valueOf()),
  ]);

  const projectionDays = (projectionDuration < MAX_PROJECTION_DAYS)
    ? projectionDuration : MAX_PROJECTION_DAYS;

  const linRegressionData = [lastItem];
  if (days.length > projectionDays) {
    linRegressionData.push(days[projectionDays]);
  } else {
    linRegressionData.push([linRegression.x(0), 0]);
  }

  const series = [
    {
      type: 'area',
      name: chartName,
      pointIntervalUnit: 'day',
      data: sourceData,
    },
  ];

  // do not show regression line when slope is negative
  if (linRegression.slope < 0) {
    series.unshift({
      type: 'line',
      name: 'Prediction',
      data: [
        [linRegressionData[0][0], linRegressionData[0][1]],
        [
          linRegressionData[linRegressionData.length - 1][0],
          linRegressionData[linRegressionData.length - 1][1],
        ],
      ],
      marker: { enabled: false },
      threshold: null,
    });
  }

  const config = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
    },
    tooltip: {
      pointFormat: pointFormat(),
      xDateFormat: DATE_FORMAT_CHARTS,
    },
    xAxis: {
      type: 'datetime',
      visible: true,
      ordinal: false,
      xDateFormat: DATE_FORMAT_CHARTS,
      dateTimeLabelFormats: {
        millisecond: DATE_FORMAT_CHARTS,
        second: DATE_FORMAT_CHARTS,
        minute: DATE_FORMAT_CHARTS,
        hour: DATE_FORMAT_CHARTS,
        day: DATE_FORMAT_CHARTS,
        week: DATE_FORMAT_CHARTS,
      },
      gridLineWidth: 1,
      gridZIndex: 2,
      gridLineColor,
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      gridLineColor,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      line: {
        color: 'rgba(125,182,236,0.4)',
      },
      scrollbar: {
        barBackgroundColor: 'gray',
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: 'gray',
        buttonBorderWidth: 0,
        buttonArrowColor: 'yellow',
        buttonBorderRadius: 7,
        rifleColor: 'yellow',
        trackBackgroundColor: 'white',
        trackBorderWidth: 1,
        trackBorderColor: 'silver',
        trackBorderRadius: 7,
      },
      bar: {
        dataLabels: {
          enabled: true,
        },
        cropThreshold: 1000,
      },
      series: {
        animation: {
          duration: 1500,
        },
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 3.5,
          lineWidth: 1.5,
          fillColor: '#1584C7',
        },
      },
      area: {
        lineWidth: 2,
        lineColor: '#1584C7',
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, 'rgba(125,182,236,1)'],
            [1, 'rgba(255,255,255,0.1)'],
          ],
        },
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
    },

    series,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={config}
    />
  );
};

ChartBurnDown.propTypes = {
  data: PropTypes.array.isRequired,
  chartName: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

ChartBurnDown.defaultProps = {
  chartName: '',
  prefix: '',
  suffix: '',
};

export default ChartBurnDown;
