import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { orgUserProfileUrl } from 'people/urls';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { IMG_SIZE } from 'core/assets/js/constants';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';


const ProjectWidgetMembersPreview = ({ canViewProjectView, members, orgAlias, total, url }) => {
  if (!members || !members.length) {
    return (
      <div className="project__members-preview project__members-preview--empty" />
    );
  }

  const remainingMembers = total - members.length;
  const membersList = members.map((m) => {
    const userUrl = orgUserProfileUrl(orgAlias, m.userRole.ofType, m.user.id);
    const profilePic = (
      <ProfilePic
        url={m.user.profile.avatar}
        alt={m.user.profile.name}
        size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
      />
    );
    const profilePicWrapper = userUrl
      ? <Link to={userUrl}>{profilePic}</Link>
      : <span>{profilePic}</span>;

    return (
      <li key={`project-member-${m.id}`}>{profilePicWrapper}</li>
    );
  });

  return (
    <div className="project__members-preview d-flex justify-content-end align-items-center">
      <ul className="list-inline project__members-preview__list d-flex">
        { membersList }
      </ul>
      { canViewProjectView && (remainingMembers > 0) && (
        <Link to={url} className="project__members-preview__more ml-1">
          {`+ ${remainingMembers}`}
        </Link>
      )}
    </div>
  );
};

ProjectWidgetMembersPreview.propTypes = {
  members: PropTypes.arrayOf(userCardSpec).isRequired,
  total: PropTypes.number,
  url: PropTypes.string,
  canViewProjectView: PropTypes.bool,
  orgAlias: PropTypes.string.isRequired,
};

ProjectWidgetMembersPreview.defaultProps = {
  total: 0,
  url: '',
  canViewProjectView: true,
};

export default ProjectWidgetMembersPreview;
