import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { BS_TOOLTIP_PLACEMENT, DATE_FORMAT_DEFAULT, USER_TYPE, ICON } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { orgUserProfileUrl } from 'people/urls';
import { PROJECT_STATUS } from 'projects/assets/js/constants';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectViewUrl } from 'projects/urls';
import BudgetProgressBar from 'projects/assets/js/components/BudgetProgressBar.jsx';
import ProjectWidgetMembersPreview from 'core/assets/js/components/Dashboard/ProjectWidgetMembersPreview.jsx';
import ProjectProgress from 'projects/assets/js/components/ProjectProgress.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

const ProjectWidgetCardItem = ({ item, orgAlias }) => {
  const {
    allowedActions: { canViewBudgets },
    budgetBreakdown,
    canViewProjectView,
    currency_symbol: currencySymbol,
    deadline,
    id,
    members,
    membersCount,
    owner_name: ownerName,
    owner_id: ownerId,
    skills,
    status_label: statusLabel,
    status,
    tasksCount,
    title,
  } = item;

  const cardClass = [];
  const url = projectViewUrl(orgAlias, id);
  const ownerUrl = orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, ownerId);

  // HigherManagers can see all projects in their list but can't view the projects they
  // are not a member of
  if (!canViewProjectView) {
    cardClass.push('card--disabled');
  }

  return (
    <div className="project-widget-list__item">
      <div className="row">
        <div className="col-12 col-sm-8">
          <h3 className="project-widget-list__item--title">
            {!canViewProjectView ? (
              <>
                <span key="title">{title}</span>
                &nbsp;
                <TDElementWithTooltip
                  el={<i className={ICON.WARNING} />}
                  placement={BS_TOOLTIP_PLACEMENT.TOP}
                  key="tooltip"
                  tooltipMsg={
                    `You don't have access to the project because you
                    have not been added yet or have been removed`
                  }
                />
              </>
            ) : (
              <Link to={url}>{title}</Link>
            )}
          </h3>
        </div>

        <div className="col-12 col-sm-4 d-none d-sm-block">
          {members && (
            <ProjectWidgetMembersPreview
              members={members}
              total={membersCount}
              url={canViewProjectView ? url : ''}
              canViewProjectView={canViewProjectView}
              orgAlias={orgAlias}
            />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {ownerName && (
            <>
              <span className="discreet project-widget-list__item--label">
                Manager
              </span>
              <Link className="text-dark" to={ownerUrl}>
                {ownerName}
              </Link>
            </>
          )}

          {tasksCount && (
            <span className="project-widget-list__item--label ml-4">
              <i className={`${ICON.SUCCESS} mr-2 discreet`} />
              {tasksCount}
              {tasksCount === 1 ? 'Task' : 'Tasks'}
            </span>
          )}

          {deadline && (
            <span className="project-widget-list__item__deadline float-right">
              <span className="discreet project-widget-list__item--label">
                Deadline
              </span>
              <span>{formatDate(deadline, DATE_FORMAT_DEFAULT)}</span>
            </span>
          )}
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-12 col-sm-6 col-lg-6 mb-3 mb-md-0">
          <SkillList skills={skills} inline />
        </div>
        <div className="col-12 col-sm-6 col-lg-6">
          <div className="project-widget-list__item--progress">
            <div className="project-widget-list__item--status">
              {status === PROJECT_STATUS.INPROGRESS
                && budgetBreakdown.totalApproved
                && canViewBudgets && (
                <>
                  <NumberTpl
                    prefix={currencySymbol}
                    thousandSeparator
                    value={budgetBreakdown.used}
                  />
                  <span className="discreet">
                    {' / '}
                    <NumberTpl
                      prefix={currencySymbol}
                      thousandSeparator
                      value={budgetBreakdown.totalApproved}
                    />
                  </span>
                </>
              )}
              {(status !== PROJECT_STATUS.INPROGRESS
                || !budgetBreakdown.totalApproved)
                && statusLabel}
            </div>

            <div className="project-widget-list__item--progress__percentage">
              <ProjectProgress
                budget={budgetBreakdown.totalApproved}
                budgetUsed={budgetBreakdown.used}
                status={status}
              />
            </div>
          </div>

          <BudgetProgressBar
            budget={budgetBreakdown.totalApproved || Infinity}
            budgetUsed={budgetBreakdown.used || 0}
            currencySymbol={currencySymbol}
            projectId={id}
            projectStatus={status}
            tdStyle="wrapped"
            tooltip={false}
          />
        </div>
      </div>
    </div>
  );
};

ProjectWidgetCardItem.propTypes = {
  item: projectSpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

export default ProjectWidgetCardItem;
