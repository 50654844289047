import { pull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { MODAL_SIZES } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import {
  getModalPayload, modalCloseAC, getIsModalOpen,
} from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { changeTaskRatesToDefaultRateApiUrl, userTaskAssignmentsApiUrl } from 'people/urls';
import { TASK_ASSIGNMENT_STATUS, TASK_STATUS } from 'projects/assets/js/constants';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';

export const MODAL_ID = 'update-task-assignment-rate-modal';

const UpdateTaskAssignmentsRatesModal = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const { oldRate, newRate } = useSelector(state => getModalPayload(state, MODAL_ID)) || {};

  const userId = oldRate?.user_id;

  const [page, setPage] = useState(1);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [error, setError] = useState(null);

  const componentName = UpdateTaskAssignmentsRatesModal.GetComponentName(params);

  const {
    hasLoaded, isLoading, items, pagination,
  } = useSelector(state => getListState(state, componentName));

  useEffect(() => {
    if (!userId) {
      return;
    }
    const queryParams = [
      ...[TASK_ASSIGNMENT_STATUS.PENDING, TASK_ASSIGNMENT_STATUS.ACCEPTED].map(
        status => `status=${status}`,
      ),
      ...[TASK_STATUS.DRAFT, TASK_STATUS.PENDING, TASK_STATUS.INPROGRESS].map(
        status => `taskStatus=${status}`,
      ),
      'ignore_having_default_rate=1',
      `page=${page}`,
    ].join('&');
    dispatch(fetchListDS({
      componentName, url: `${userTaskAssignmentsApiUrl(params.orgAlias, userId)}?${queryParams}`,
    }));
  }, [params.orgAlias, userId, page]);

  const onClose = () => {
    dispatch(modalCloseAC());
    setPage(1);
    setSelectedTaskIds([]);
    setError(null);
  };

  const newRateFormatted = newRate && (
    <RateAmount
      amount={newRate.amount}
      symbol={newRate.currencySymbol}
      unit={newRate.unit}
    />
  );

  return (
    <ModalConfirm
      body={oldRate && newRate && (
        <>
          <p>
            {'You have just updated the default rate for this provider from '}
            <RateAmount
              amount={oldRate.amount}
              symbol={oldRate.currencySymbol}
              unit={oldRate.unit}
            />
            {' to '}
            {newRateFormatted}
          </p>
          <p>
            {"Do you wish to update the rate for any of the provider's active tasks to "}
            <b>{newRateFormatted}</b>
          </p>
          <h4>List of all provider&apos;s active tasks</h4>
          {(!hasLoaded || isLoading) && <FinanceTableSkeleton />}
          {hasLoaded && !isLoading && (
            <>
              <Table
                cols={[
                  {
                    columnClassName: 'checkbox',
                    dataFormat: task => (
                      <input
                        checked={selectedTaskIds.includes(task.id)}
                        onChange={() => {
                          setSelectedTaskIds(currentSelectedTaskIds => {
                            const newSelectedTaskIds = [...currentSelectedTaskIds];
                            if (newSelectedTaskIds.includes(task.id)) {
                              pull(newSelectedTaskIds, task.id);
                            } else {
                              newSelectedTaskIds.push(task.id);
                            }
                            return newSelectedTaskIds;
                          });
                        }}
                        type="checkbox"
                      />
                    ),
                    key: 'task',
                    label: '',
                    width: '40px',
                  },
                  {
                    dataFormat: task => (
                      <>
                        {task.title}
                        <span className="discreet ml-2">
                          {`(Project: ${task.project.title})`}
                        </span>
                      </>
                    ),
                    key: 'task',
                    label: 'Task',
                  },
                  {
                    dataFormat: assignedRate => assignedRate?.alias,
                    key: 'assignedRate',
                    label: 'Rate name',
                    width: '140px',
                  },
                  {
                    dataFormat: (amount, { currencySymbol, rate_unit: rateUnit }) => (
                      <RateAmount
                        amount={amount}
                        symbol={currencySymbol}
                        unit={rateUnit}
                      />
                    ),
                    key: 'rate',
                    label: 'Rate',
                    width: '140px',
                  },
                ]}
                containerClass="update-task-assignments-rates-table"
                items={items}
                trClassName={row => (selectedTaskIds.includes(row.task.id) ? 'checked' : '')}
              />
              <TDPagination
                onPageChange={({ page: newPage }) => {
                  setPage(newPage);
                }}
                {...pagination}
              />
              {error && <div className="text-danger my-3">{error}</div>}
            </>
          )}
        </>
      )}
      cancelLabel="No, don't"
      closeOnConfirm={false}
      confirmLabel="Yes, update"
      heading="Update rate for active tasks?"
      onClose={onClose}
      onConfirm={async () => { // eslint-disable-line consistent-return
        setError(null);
        const selectedTaskIdsLength = selectedTaskIds.length;
        if (selectedTaskIdsLength === 0) {
          setError('You have not selected any tasks');
          return;
        }
        try {
          await axios.put(
            changeTaskRatesToDefaultRateApiUrl(params.orgAlias, userId), selectedTaskIds,
          );
          onClose();
          toastr.success(
            'Well Done!', `You have updated ${selectedTaskIdsLength} task assignments`,
          );
        } catch (e) {
          setError(e._error || e.message);
        }
      }}
      open={isOpen}
      size={MODAL_SIZES.LARGE}
    />
  );
};

UpdateTaskAssignmentsRatesModal.GetComponentName = () => 'UpdateTaskAssignmentsRatesModal';

export default UpdateTaskAssignmentsRatesModal;
