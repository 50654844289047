import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { DATETIME_FORMAT_HUMAN_FRIENDLY, IMG_SIZE } from 'core/assets/js/constants';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { formatDate } from 'core/assets/js/lib/utils';


const ChatRoomMessages = ({
  chatRoom,
}) => {
  const activeUserCard = useSelector(state => selectActiveUserCard(state));

  if (
    !Array.isArray(chatRoom?.chatMessages)
  ) {
    return [];
  }
  const currentUser = chatRoom?.chatRoomUsers?.find(
    (c) => c.userId === activeUserCard.user.id,
  );
  return chatRoom?.chatMessages?.map((chatRoomMessage) => {
    const dateTimeSent = formatDate(
      chatRoomMessage.createdAt,
      DATETIME_FORMAT_HUMAN_FRIENDLY,
    );

    if (chatRoomMessage.chatRoomUserId === currentUser?.id) {
      return (
        <div className="d-flex justify-content-end mt-3 w-100">
          <div>
            <p className="mb-1 text-right">
              <small>
                {dateTimeSent}
              </small>
              {' '}
              <strong>
                {currentUser?.name}
              </strong>
            </p>
            <div className="chat__box-message p-3 ml-auto">
              {chatRoomMessage.message}
            </div>
          </div>
          <ProfilePic
            data-testid="contact-details-panel-profile-pic"
            url={currentUser?.avatar}
            alt={chatRoomMessage.id}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
            className="mx-3"
          />
        </div>
      );
    }
    const user = chatRoom?.chatRoomUsers?.find(
      (c) => c.id === chatRoomMessage.chatRoomUserId,
    );
    return (
      <div className="d-flex mt-3 w-100">
        <ProfilePic
          data-testid="contact-details-panel-profile-pic"
          url={user?.avatar}
          alt={chatRoomMessage.id}
          size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
          className="mx-3"
        />
        <div>
          <p className="mb-1">
            <small>
              {dateTimeSent}
            </small>
            {' '}
            {user?.name}
          </p>
          <div className="chat__box-message p-3">
            {chatRoomMessage.message}
          </div>
        </div>
      </div>
    );
  });
};

ChatRoomMessages.propTypes = {
  chatRoom: PropTypes.object,
};

ChatRoomMessages.defaultProps = {
  chatRoom: null,
};

export default ChatRoomMessages;
