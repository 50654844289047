/* eslint-disable react/jsx-fragments */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import PaypalForm from 'settings/assets/js/components/PaypalForm.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { settingsPaymentsSubPageUrl, settingsBankAccountApiUrl } from 'settings/urls';

class ProfileManagePaypal extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { bankAccountId } = params;
    if (bankAccountId) {
      return Promise.all([
        dispatch(fetchViewDS({
          url: settingsBankAccountApiUrl(params.bankAccountId, url),
          params,
          authedAxios,
          componentName,
        })),
      ]);
    }
    return Promise.resolve();
  }

  static GetComponentName() {
    return 'ProfileManagePaypal';
  }

  render() {
    const {
      activeOrg, bankAccount, history, match: { params: { bankAccountId } },
    } = this.props;
    const breadcrumbs = [
      {
        title: 'Payments',
        url: settingsPaymentsSubPageUrl(activeOrg.alias),
      },
      {
        title: bankAccountId ? 'Edit paypal account' : 'Add new paypal account',
        url: null,
      },
    ];
    // Check if is coming from get started page
    let fromGetStarted = false;
    if (history?.location?.state?.fromGetStarted) {
      fromGetStarted = true;
    }

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--settings bank-account">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={SettingsPageSkeleton}
            >
              <PaypalForm
                bankAccount={bankAccount}
                fromGetStarted={fromGetStarted}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileManagePaypal.propTypes = {
  activeOrg: orgSpec.isRequired,
  bankAccount: bankAccountSpec,
  match: routerMatchSpec.isRequired,
  history: routerHistorySpec.isRequired,
};

ProfileManagePaypal.defaultProps = {
  bankAccount: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  bankAccount: getViewState(state, ProfileManagePaypal.GetComponentName()).item,
});

const ProfileManagePaypalReduxConnect = connect(
  mapStateToProps,
)(ProfileManagePaypal);

export default withRouter(ProfileManagePaypalReduxConnect);
