import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { interviewDuplicateUrl } from 'interviews/urls';
import ModalConfirmFinalForm from 'core/assets/js/components/ModalConfirmFinalForm.jsx';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { toastr } from 'react-redux-toastr';

export const DUPLICATE_INTERVIEW_MODAL = 'duplicate-interview-modal';

const DuplicateInterviewModal = ({ onDuplicating }) => {
  const dispatch = useDispatch();
  const duplicateModalOpen = useSelector(state => getIsModalOpen(state, DUPLICATE_INTERVIEW_MODAL));
  const duplicateModalPayload = useSelector(state => getModalPayload(
    state,
    DUPLICATE_INTERVIEW_MODAL,
  ));
  const { orgAlias } = useParams();
  const onClose = () => dispatch(modalCloseAC());
  const interviewId = duplicateModalPayload?.id;

  return (
    <ModalConfirmFinalForm
      cancelLabel="Cancel"
      confirmLabel="Duplicate"
      heading="Duplicate Interview"
      onClose={onClose}
      open={duplicateModalOpen}
      FormContentComponent={({ renderProps }) => {
        const { submitError, submitting } = renderProps;
        return (
          <>
            <TextInputField
              disabled={submitting}
              label="Onboarding form name"
              name="newName"
              required
            />
            {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
          </>
        );
      }}
      formProps={{
        initialValues: { newName: '' },
        onSubmit: async (values) => { // eslint-disable-line consistent-return
          try {
            await axios.post(
              interviewDuplicateUrl(
                orgAlias, interviewId,
              ),
              values,
            );
            toastr.success('Well Done!', 'Onboarding form was duplicated!');
            onDuplicating();
            onClose();
          } catch (err) {
            return parseAxiosErrorForFinalForm(err);
          }
        },
      }}
    />
  );
};

DuplicateInterviewModal.propTypes = {
  onDuplicating: PropTypes.func.isRequired,
};

DuplicateInterviewModal.GetComponentName = () => 'DuplicateInterviewModal';

export default DuplicateInterviewModal;
