import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import {
  worksheetsProjectAnalyticsApiUrl,
  purchaseOrdersProjectAnalyticsApiUrl,
  upcomingPaymentsProjectAnalyticsApiUrl,
  reportsFetchReportsInfoApiUrl,
  reportsFetchReportInfoApiUrl,
} from 'analytics/urls';
import { viewFetchAC, viewFetchErrorAC } from 'core/assets/js/ducks/view';
import { listFetchAC } from 'core/assets/js/ducks/list';


const _validateParams = ({ id, orgAlias, componentName }) => {
  if (!componentName) {
    throw new Error(`Invalid componentName (${componentName})`);
  }
  if (!orgAlias || !id) {
    throw new Error(`Invalid orgAlias (${orgAlias}) or id (${id}) params`);
  }
};

export const fetchWorksheetAnalyticsDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null, dateDuration,
}) => fetchDataDS({
  authedAxios,
  componentName,
  validate: () => _validateParams({ id, orgAlias, componentName }),
  fetchApiUrl: () => worksheetsProjectAnalyticsApiUrl(orgAlias, id, dateDuration, url),
  fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  fetchDataErrorAC: viewFetchErrorAC,
});

export const fetchUpcomingPaymentsAnalyticsDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  componentName,
  validate: () => _validateParams({ id, orgAlias, componentName }),
  fetchApiUrl: () => upcomingPaymentsProjectAnalyticsApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  fetchDataErrorAC: viewFetchErrorAC,
});

export const fetchPurchaseOrderAnalyticsDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  componentName,
  validate: () => _validateParams({ id, orgAlias, componentName }),
  fetchApiUrl: () => purchaseOrdersProjectAnalyticsApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  fetchDataErrorAC: viewFetchErrorAC,
});

const _validateReportsParams = ({ orgAlias, componentName }) => {
  if (!componentName) {
    throw new Error(`Invalid componentName (${componentName})`);
  }
  if (!orgAlias) {
    throw new Error(`Invalid orgAlias (${orgAlias})`);
  }
};

export const fetchReportsInfoDS = ({
  orgAlias, url = '', componentName, authedAxios = null,
}) => {
  return fetchDataDS({
    authedAxios,
    componentName,
    validate: () => _validateReportsParams({ orgAlias, componentName }),
    fetchApiUrl: () => reportsFetchReportsInfoApiUrl(orgAlias, url),
    fetchDataAC: responseData => listFetchAC(responseData, componentName),
  });
};

export const fetchReportInfoDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => {
  return fetchDataDS({
    authedAxios,
    componentName,
    validate: () => _validateParams({ orgAlias, id, componentName }),
    fetchApiUrl: () => reportsFetchReportInfoApiUrl(orgAlias, id, url),
    fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  });
};
