import React from 'react';
import moment from 'moment';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import CircleArea from 'core/assets/js/components/CircleArea.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDCheckbox, { CHECKBOX_SIZES } from 'core/assets/js/components/TDCheckbox.jsx';
import TDRating from 'core/assets/js/components/TDRating.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import { EXCHANGE_RATE_SERVICE } from 'finance/assets/js/constants';
import { IMG_SIZE, BANK_CURRENCY } from 'core/assets/js/constants';

function MiscSection() {
  return (
    <section id="misc" className="bg-white p-4">
      <h2>Misc</h2>

      <div className="my-5">
        <h4>Exchange rate info PopOver</h4>

        <span className="mr-2">£2,000</span>

        <ExchangeRateInfoPopOver
          exchangeRate={0.7114}
          exchangeRateDate={moment()}
          exchangeRateService={EXCHANGE_RATE_SERVICE.FIXER}
          sourceCurrency={BANK_CURRENCY.GBP}
          targetAmount={2827.12}
          targetCurrency={BANK_CURRENCY.USD}
        />
      </div>

      <div className="row mt-4">
        <div className="col-sm-6">
          <h4>Profile Pic</h4>
          <div className="mt-4 d-flex align-items-center">
            <ProfilePic
              url={null}
              alt="This is the alt text."
            />
            &ensp;
            (Default)
          </div>
          <div className="mt-4 d-flex align-items-center">
            <ProfilePic
              url="https://pbs.twimg.com/profile_images/594503991960215552/06tr40IK.png"
              alt="This is the alt text."
            />
            &ensp;
            (with Avatar)
          </div>
          <div className="mt-4 d-flex align-items-center">
            <ProfilePic
              url="https://pbs.twimg.com/profile_images/594503991960215552/06tr40IK.png"
              alt="This is the alt text."
              size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
            />
            &ensp;
            (custom size)
          </div>
        </div>
        <div className="col-sm-6">
          <h4>Rating</h4>
          <div className="col-12">
            <h4>Default:</h4>
            <TDRating
              initialRating={1}
            />
            <br />
            <br />
            <TDRating
              size="large"
              initialRating={2}
            />
            (.large)
          </div>
          <div className="col-12">
            <h4>Read-only:</h4>
            <TDRating
              readonly
              initialRating={3}
            />
            <br />
            <br />
            <TDRating
              readonly
              size="large"
              initialRating={4}
            />
            (.large)
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-sm-4">
          <h4>Tags</h4>
          <span className="tag">Tag-1</span>
          <span className="tag">Tag-2</span>
          <span className="tag">Tag-3</span>
          <span className="tag">Another-Tag</span>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <h4>Custom Checkbox</h4>
          <div className="col-5 justify-content-center">
            <TDCheckbox size={CHECKBOX_SIZES.LARGE} checked />
            Checked
            <br />
            <br />
            <TDCheckbox size={CHECKBOX_SIZES.LARGE} />
            Unchecked
          </div>
        </div>
        <div className="col-6">
          <h4>Custom Switch</h4>
          <div className="col-6 justify-content-center">
            <TDSwitch selected />
            &nbsp;
            Selected
            <br />
            <br />
            <TDSwitch />
            &nbsp;
            Unselected
          </div>
        </div>
        <div className="col-6">
          <h4 className="mt-4">Circle Area</h4>
          <div className="col-6 justify-content-center">
            <i>Supports percentages (0-100)</i>
            <br />
            <CircleArea percent={0} />
            <CircleArea percent={10} />
            <CircleArea percent={20} />
            <CircleArea percent={30} />
            <CircleArea percent={40} />
            <CircleArea percent={50} />
            <CircleArea percent={60} />
            <CircleArea percent={70} />
            <CircleArea percent={80} />
            <CircleArea percent={90} />
            <CircleArea percent={100} />
          </div>
        </div>
      </div>

      <div className="row mt-4 px-3">
        <div className="col-12">
          <h4 className="my-4">Area Collapsible</h4>

          <AreaCollapsible
            containerElement="div"
            className="mb-5 w-100"
            fieldSet
            contentChildren={
              <p>This is the content</p>
            }
            headingChildren={<span className="bg-light">Advanced Options - fieldSet true</span>}
          />

          <AreaCollapsible
            className="w-100"
            contentChildren={
              <p>This is the content</p>
            }
            headingChildren={<span>Advanced Options -  fieldSet false</span>}
          />

          <AreaCollapsible
            solidColor
            className="w-100"
            contentChildren={
              <p>This is the content</p>
            }
            headingChildren={<span>Advanced Options -  fieldSet false</span>}
          />
        </div>
      </div>

      <br />
      <br />
      <br />
    </section>
  );
}

export default MiscSection;
