import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { remove } from 'lodash';

import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { postOrganizationDS, fetchOrganizationDS, fetchOrganizationPaymentMethodStatsDS } from 'settings/assets/js/data-services/organizations';
import { getViewState } from 'core/assets/js/ducks/view';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { PAYMENT_METHODS, PAYMENT_METHOD_DETAILS } from 'settings/assets/js/constants';
import OrgPaymentMethodCardItem from 'settings/assets/js/components/OrgPaymentMethodCardItem.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';

export const CONFIRMATION_MODAL_ID = 'payment-method-toggle-modal';
const COMPONENT_NAME = 'OrgPaymentMethodSettingsTab';

const OrgPaymentMethodsSettingsTab = ({
  dispatch,
  organization,
  stats,
  isConfirmationModalOpen,
  confirmationModalPayload,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onToggle = async ({ methodId }) => {
    const currentPaymentMethods = [...organization.payment_methods];
    const isRemove = currentPaymentMethods.includes(methodId);

    if (isLoading) {
      return;
    }

    // Remove item
    if (isRemove) {
      remove(
        currentPaymentMethods, method => methodId === method,
      );
    }

    // Add item
    if (!isRemove) {
      currentPaymentMethods.push(methodId);
    }

    setIsLoading(true);
    await dispatch(postOrganizationDS(
      organization.alias,
      {
        payment_methods: currentPaymentMethods,
      },
      COMPONENT_NAME,
    ));
    setIsLoading(false);
    dispatch(modalCloseAC(CONFIRMATION_MODAL_ID));
  };

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <h3 className="heading-block">Payment methods</h3>
      <p className="text-secondary mb-5">Only the enabled payment methods will be available to members of your organisation</p>

      <div>
        {Object.values(PAYMENT_METHODS).filter(
          m => organization.available_payment_methods.includes(m),
        ).map((method => (
          <OrgPaymentMethodCardItem
            key={method}
            organization={organization}
            stats={stats}
            paymentMethod={PAYMENT_METHOD_DETAILS[method]}
          />
        )))}
      </div>

      <ModalConfirm
        heading={(({ name, methodId }) => {
          const isEnabled = organization.payment_methods.includes(methodId);
          return `${isEnabled ? 'Deactivate' : 'Activate'} ${name} `;
        })(confirmationModalPayload)}
        confirmLabel="Confirm"
        open={isConfirmationModalOpen}
        closeOnConfirm={false}
        onClose={() => dispatch(modalCloseAC(CONFIRMATION_MODAL_ID))}
        onConfirm={() => onToggle(confirmationModalPayload)}
      >
        {
          (({ name, methodId }) => {
            const isEnabled = organization.payment_methods.includes(methodId);
            return (
              isEnabled ? (
                <React.Fragment>
                  <p>
                    {`By deactivating ${name} your providers will not be able to create and get paid via this method.`}
                  </p>

                  { stats[methodId] > 0 && (
                    <p>
                      {`Moreover, ${pluralize('provider', stats[methodId], true)} actively use it.`}
                    </p>
                  )}

                  <p>
                    {`Are you sure you want to deactivate the ${name}?`}
                  </p>
                </React.Fragment>
              ) : (
                <p>
                  {`Are you sure you want to activate ${name} as a payment method?`}
                </p>
              )
            );
          })(confirmationModalPayload)
        }
      </ModalConfirm>
    </div>
  );
};

OrgPaymentMethodsSettingsTab.propTypes = {
  organization: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  confirmationModalPayload: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, COMPONENT_NAME);
  return ({
    organization: viewState.item,
    isConfirmationModalOpen: getIsModalOpen(state, CONFIRMATION_MODAL_ID),
    confirmationModalPayload: getModalPayload(state, CONFIRMATION_MODAL_ID) || {},
    stats: viewState.extras,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgPaymentMethodsSettingsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgPaymentMethodsSettingsTab);

const WithTDApiConnectedOrgPaymentMethodsSettingsTabConnected = withTDApiConnected({
  fetchData: ({ dispatch, params, url = '', authedAxios, componentName }) => (
    Promise.all([
      dispatch(fetchOrganizationDS(params, url, authedAxios, componentName)),
      dispatch(fetchOrganizationPaymentMethodStatsDS(params, url, authedAxios, componentName)),
    ])
  ),
  duck: 'list',
  storeKey: COMPONENT_NAME,
  skeletonComponent: SettingsPageSkeleton,
})(OrgPaymentMethodsSettingsTabConnected);

export default WithTDApiConnectedOrgPaymentMethodsSettingsTabConnected;
