import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { orgPeopleDiscoverFreelancersUrl } from 'people/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { ICON } from 'core/assets/js/constants';

export const Categories = [
  {
    icon: ICON.LAPTOP_CODE_DUOTONE,
    id: 9041,
    label: 'Technology & Programming',
    url: '?tags=9041',
  },
  {
    icon: ICON.TYPEWRITER_DUOTONE,
    id: 8480,
    label: 'Content Writing & Translation',
    url: '?tags=8480',
  },
  {
    icon: ICON.PAINT_BRUSH_DUOTONE,
    id: 9146,
    label: 'Graphic Design & Creative',
    url: '?tags=9146',
  },
  {
    icon: ICON.MEGAPHONE_DUOTONE,
    id: 9348,
    label: 'Digital Marketing & PR',
    url: '?tags=9348',
  },
  {
    icon: ICON.CAMERA_DUOTONE,
    id: 9466,
    label: 'Video & Animation',
    url: '?tags=9466',
  },
  {
    icon: ICON.BRIEFCASE_DUOTONE,
    id: 9385,
    label: 'Business Support',
    url: '?tags=9385',
  },
  {
    icon: ICON.MUSIC_DUOTONE,
    id: 9447,
    label: 'Music, Narration & Audio',
    url: '?tags=9447',
  },
  {
    icon: ICON.BULLSEYE_ARROW_DUOTONE,
    id: 9313,
    label: 'Marketing & Advertising',
    url: '?tags=9313',
  },
  {
    icon: ICON.COMMENTS_DUOTONE,
    id: 9511,
    label: 'Social Media',
    url: '?tags=9511',
  },
  {
    icon: ICON.SCALE_DUOTONE,
    id: 9415,
    label: 'Legal Services',
    url: '?tags=9415',
  },
];

const CategorySection = ({ activeOrg, history }) => (
  <>
    <h1>Popular Categories</h1>
    <div className="row ml-0">
      {Categories.map((category) => (
        <div
          className="col-xs-4 col-sm-4 col-md-3 col-lg pl-0 mb-3 category-section__item"
          key={category.label}
        >
          <Card
            className="category-section shadow-none"
            onClick={() =>
              history.push(
                `${orgPeopleDiscoverFreelancersUrl(activeOrg.alias)}${
                  category.url
                }`,
              )
            }
          >
            <Card.Body className="category-section__container">
              <i className={category.icon} />
              <h3 className="mb-0">{category.label}</h3>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  </>
);

CategorySection.propTypes = {
  activeOrg: orgSpec.isRequired,
  history: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  activeOrg: selectActiveOrg(state),
});

const CategorySectionConnected = connect(mapStateToProps)(CategorySection);

export default withRouter(CategorySectionConnected);
