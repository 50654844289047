import React from 'react';


function LoadingComponent() {
  return (
    <div
      className="loading-component"
      data-testid="loading-component"
    >
      <div className="loader" />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default LoadingComponent;
