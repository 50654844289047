import AnnotatedRateMap from 'finance/assets/js/lib/AnnotatedRateMap';
import TransactionRateMap from 'finance/assets/js/lib/TransactionRateMap';
import { uniq } from 'lodash';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import { TRANSACTION_MODE, SYSTEM_CURRENCY } from 'finance/assets/js/constants';
import Money from 'finance/assets/js/lib/Money';
import { assert } from 'finance/assets/js/lib/Assert';

/**
 * This class is used to keep and answer questions about the FX rates of an invoice
 *
 * It is a concrete version of an annotated rate map with a schema used for invoices
 *
 */
class InvoiceRateMap extends AnnotatedRateMap {
  static aggregateMoneyInDifferentCurrencies({
    allSystemMoney,
    allOrgMoney,
    allInvoiceMoney,
    allBalanceMoney,
    allTargetMoney,
    allServiceOrderMoney,
  }) {
    // aggregate amounts in system currency, to use as fallback in case of multiple currencies
    assert(Money.haveSameCurrency(allSystemMoney), 'cannot aggregate invoices of different system currencies');
    const systemMoney = Money.aggregate(allSystemMoney);

    // aggregate amounts in org currency, to use as fallback in case of multiple currencies
    const orgMoney = Money.haveSameCurrency(allOrgMoney)
      ? Money.aggregate(allOrgMoney)
      : systemMoney; // we cannot aggregate, so, use the aggregation of the system currency

    // use org money as the commonCurrencyMoney
    const commonCurrencyMoney = orgMoney;

    // aggregate amounts in all other currencies. if aggregations cannot be made
    // because of currency differences, use the common currency equivalent
    const invoiceMoney = Money.haveSameCurrency(allInvoiceMoney)
      ? Money.aggregate(allInvoiceMoney)
      : commonCurrencyMoney; // we cannot aggregate, so, use an aggregation of a common currency

    const balanceMoney = Money.haveSameCurrency(allBalanceMoney)
      ? Money.aggregate(allBalanceMoney)
      : commonCurrencyMoney; // we cannot aggregate, so, use an aggregation of a common currency

    const targetMoney = Money.haveSameCurrency(allTargetMoney)
      ? Money.aggregate(allTargetMoney)
      : commonCurrencyMoney; // we cannot aggregate, so, use an aggregation of a common currency

    const serviceOrderMoney = Money.haveSameCurrency(allServiceOrderMoney)
      ? Money.aggregate(allServiceOrderMoney)
      : commonCurrencyMoney; // we cannot aggregate, so, use an aggregation of a common currency

    const rateMapAtInvoiceTime = InvoiceRateMap.fromMoney({
      invoiceMoney, balanceMoney, orgMoney, targetMoney, systemMoney,
    });

    return {
      invoiceMoney,
      balanceMoney,
      orgMoney,
      targetMoney,
      systemMoney,
      serviceOrderMoney,
      rateMapAtInvoiceTime,
    };
  }

  static aggregateInvoiceAmounts(invoiceAmountsInstances) {
    if (invoiceAmountsInstances.length === 1) {
      const [invoiceAmounts] = invoiceAmountsInstances;
      return {
        ...invoiceAmounts.getMoneyInAllCurrencies(),
        rateMapAtInvoiceTime: invoiceAmounts.getInvoiceRateMap(),
      };
    }
    const allMoney = invoiceAmountsInstances.map(a => a.getMoneyInAllCurrencies());
    return InvoiceRateMap.aggregateMoneyInDifferentCurrencies({
      allSystemMoney: allMoney.map(m => m.systemMoney),
      allOrgMoney: allMoney.map(m => m.orgMoney),
      allInvoiceMoney: allMoney.map(m => m.invoiceMoney),
      allBalanceMoney: allMoney.map(m => m.balanceMoney),
      allTargetMoney: allMoney.map(m => m.targetMoney),
      allServiceOrderMoney: allMoney.map(m => m.serviceOrderMoney),
    });
  }

  static fromRateMap(other, {
    currency,
    orgCurrency,
    balanceCurrency,
    targetCurrency,
    systemCurrency = SYSTEM_CURRENCY,
  }) {
    assertAllKeysPresent({
      other,
      currency,
      orgCurrency,
      balanceCurrency,
      targetCurrency,
      systemCurrency,
    });
    return new InvoiceRateMap({
      currency,
      orgCurrency,
      balanceCurrency,
      targetCurrency,
      systemCurrency,
      invoiceToOrgRate: other.getRate(currency, orgCurrency),
      invoiceToBalanceRate: other.getRate(currency, balanceCurrency),
      invoiceToTargetRate: other.getRate(currency, targetCurrency),
      invoiceToSystemRate: other.getRate(currency, systemCurrency),
    }, { extendsRateMap: other });
  }

  static fromMoney({
    invoiceMoney, balanceMoney, orgMoney, targetMoney, systemMoney,
  }) {
    assertAllKeysPresent({
      invoiceMoney, balanceMoney, orgMoney, targetMoney, systemMoney,
    });
    if (invoiceMoney.isZero()) {
      assert(balanceMoney.isZero(), 'zero invoice with non-zero balance amount');
      assert(orgMoney.isZero(), 'zero invoice with non-zero org amount');
      assert(targetMoney.isZero(), 'zero invoice with non-zero target amount');
      assert(systemMoney.isZero(), 'zero invoice with non-zero system amount');
      return new InvoiceRateMap({
        currency: invoiceMoney.getCurrency(),
        orgCurrency: orgMoney.getCurrency(),
        balanceCurrency: balanceMoney.getCurrency(),
        targetCurrency: targetMoney.getCurrency(),
        systemCurrency: systemMoney.getCurrency(),
        invoiceToOrgRate: 1,
        invoiceToBalanceRate: 1,
        invoiceToTargetRate: 1,
        invoiceToSystemRate: 1,
      });
    }
    return new InvoiceRateMap({
      currency: invoiceMoney.getCurrency(),
      orgCurrency: orgMoney.getCurrency(),
      balanceCurrency: balanceMoney.getCurrency(),
      targetCurrency: targetMoney.getCurrency(),
      systemCurrency: systemMoney.getCurrency(),
      invoiceToOrgRate: invoiceMoney.getRate(orgMoney),
      invoiceToBalanceRate: invoiceMoney.getRate(balanceMoney),
      invoiceToTargetRate: invoiceMoney.getRate(targetMoney),
      invoiceToSystemRate: invoiceMoney.getRate(systemMoney),
    });
  }

  static fromIdentity(currency) {
    return new InvoiceRateMap({
      currency,
      orgCurrency: currency,
      balanceCurrency: currency,
      targetCurrency: currency,
      systemCurrency: currency,
      invoiceToOrgRate: 1.0,
      invoiceToBalanceRate: 1.0,
      invoiceToTargetRate: 1.0,
      invoiceToSystemRate: 1.0,
    });
  }

  constructor(serialized, { testAgainstMoney } = {}) {
    super(serialized, { schema: {
      invoiceToOrgRate: { from: 'currency', to: 'orgCurrency' },
      invoiceToBalanceRate: { from: 'currency', to: 'balanceCurrency' },
      invoiceToTargetRate: { from: 'currency', to: 'targetCurrency' },
      invoiceToSystemRate: { from: 'currency', to: 'systemCurrency' },
    }, testAgainstMoney });
  }

  /**
   * Converts the invoice rate map to a transaction rate map
   *
   * @param {BANK_CURRENCY} balanceCurrency - the balance currency of the transaction
   * @param {BANK_CURRENCY} targetCurrency - the target currency of the transaction
   * @param {RateMap} rateMapAtTransactionTime - the generic rate map that has been offered
   *                                             from our 3rd parties (e.g. Wise)
   * @param {Boolean} assertExistingCurrencies - whether we should force keep some extra
   *                                             currency conversions (e.g. always keep
   *                                             'GBP' conversions since we use it for reports)
   * @returns {TransactionRateMap} the transaction rate map
   */
  toTransactionRateMap({
    balanceCurrency,
    targetCurrency,
    rateMapAtTransactionTime,
    assertExistingCurrencies,
  }) {
    assertAllKeysPresent({
      balanceCurrency,
      targetCurrency,
      rateMapAtTransactionTime,
    });

    const invoiceCurrencies = this.serializeCurrencies();

    const {
      currency,
      targetCurrency: invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
    } = invoiceCurrencies;

    assertAllKeysPresent({
      currency,
      balanceCurrency,
      targetCurrency,
      invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
    });

    const allCurrencies = uniq([
      ...Object.values(invoiceCurrencies),
      balanceCurrency,
      targetCurrency,
    ]);

    if (assertExistingCurrencies) {
      allCurrencies.push(...assertExistingCurrencies);
    }

    const rateMap = this.toPlainRateMap().changeBaseAccordingToRateMap(
      rateMapAtTransactionTime,
    ).getRateMapOfCurrencies(allCurrencies);

    return new TransactionRateMap({
      balanceCurrency,
      targetCurrency,
      invoiceCurrency: currency,
      invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
      balanceToTargetRate: rateMap.getRate(balanceCurrency, targetCurrency),
      balanceToInvoiceRate: rateMap.getRate(balanceCurrency, currency),
      balanceToInvoiceTargetRate: rateMap.getRate(balanceCurrency, invoiceTargetCurrency),
      balanceToOrgRate: rateMap.getRate(balanceCurrency, orgCurrency),
      balanceToSystemRate: rateMap.getRate(balanceCurrency, systemCurrency),
    }, { extendsRateMap: rateMap });
  }

  /**
   * Calculates how much money is owed depending on the transaction mode and the
   * invoiced money
   *
   * Depending on the transaction mode, even if an invoice may report an amount,
   * we may still consider that we owe something different, e.g. the exact amount
   * of service orders on a different currency
   *
   * This is common when using multiple currencies, since just one of them can
   * be considered fixed. Therefore, this method is used to calculate the money
   * that we owe, rather than what is invoiced.
   *
   * @param {TRANSACTION_MODE} transactionMode - the transaction mode
   * @param {Money} invoicedMoney - the total money of the invoice
   * @param {Money} serviceOrderMoney - the money that was included in
   *                                    service orders (in org currency)
   * @returns {TransactionRateMap} the transaction rate map
   */
  calculateOwedMoney({ transactionMode, invoicedMoney, serviceOrderMoney }) {
    const { balanceCurrency, orgCurrency, targetCurrency } = this.serializeCurrencies();
    const balanceMoney = this.convert({ money: invoicedMoney, toCurrency: balanceCurrency });
    const orgMoney = this.convert({ money: invoicedMoney, toCurrency: orgCurrency });
    const targetMoney = this.convert({ money: invoicedMoney, toCurrency: targetCurrency });

    switch (transactionMode) {
      case TRANSACTION_MODE.FIXED_BALANCE:
        return balanceMoney;
      case TRANSACTION_MODE.FIXED_INVOICE:
        return invoicedMoney;
      case TRANSACTION_MODE.FIXED_RECIPIENT_BANK:
        return targetMoney;
      case TRANSACTION_MODE.FIXED_ORG_PAYMENT:
        return orgMoney;
      case TRANSACTION_MODE.FIXED_SO_AMOUNT:
        // usually, this should return the total of service orders amounts
        // however, since this is not directly stored on the invoice amounts,
        // we rely on the caller to provide the aggregation. In case they don't,
        // we use the orgMoney which is the closest we have
        return serviceOrderMoney || orgMoney;
      default:
        throw new Error(`unknown transaction mode ${transactionMode}`);
    }
  }

  changeBase(newBaseCurrency) {
    return InvoiceRateMap.fromRateMap(this, {
      ...this.serialize(),
      currency: newBaseCurrency,
    });
  }

  getExpectedNetOutgoingMoney({ owedMoney, outgoingCurrency }) {
    return this.convert({ money: owedMoney, toCurrency: outgoingCurrency });
  }

  getExpectedOutgoingMoney({ owedMoney, outgoingCurrency, expectedFee }) {
    assertAllKeysPresent({ owedMoney, outgoingCurrency, expectedFee });
    return this.convert({ money: owedMoney, toCurrency: outgoingCurrency }).add(expectedFee);
  }
}

export default InvoiceRateMap;
