import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { omit } from 'lodash';
import { withRouter } from 'react-router-dom';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import TalentListSkeleton from 'people/assets/js/components/skeletons/TalentListSkeleton.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TalentCard from 'people/assets/js/components/TalentCard.jsx';
import { PPHProfileSpec } from 'search/assets/js/lib/objectSpecs';
import { discoverTalentApiUrl } from 'search/urls';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';

const FreelancersList = (props) => {
  const { currentUser, match, pagination, people } = props;
  const { orgAlias } = match.params;

  return (
    <TDList
      cardItem={{
        component: TalentCard,
        props: {
          currentUser,
          parentComponentName: FreelancersList.GetComponentName(),
          orgAlias,
        },
      }}
      emptyListMessage="No talents found for your search criteria."
      items={people}
      listClassName="row mr-0 ml-0"
      pagination={pagination}
    />
  );
};

FreelancersList.GetComponentName = () => 'FreelancersList';

FreelancersList.propTypes = {
  currentUser: userSpec,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object,
  pagination: paginationSpec.isRequired,
  people: PropTypes.arrayOf(PPHProfileSpec),
  showFavoritesOnly: PropTypes.bool,
};

FreelancersList.defaultProps = {
  currentUser: {},
  match: {},
  people: [],
  showFavoritesOnly: false,
};

const FreelancersListWithTDApi = withTDApiConnected({
  fetchData: ({
    authedAxios,
    componentName,
    dispatch,
    params,
    props,
    querystring,
    url,
  }) => {
    const { showFavoritesOnly } = props;
    const updatedQueryString = queryString.parse(querystring);
    updatedQueryString.showFavorite = showFavoritesOnly;
    const apiUrl = discoverTalentApiUrl(params, url);
    return dispatch(
      fetchListDS({ authedAxios, componentName,
        querystring: queryString.stringify(updatedQueryString.kw
          ? { ...omit(updatedQueryString, 'tags') } : updatedQueryString), url: apiUrl,
      }),
    );
  },
  duck: 'list',
  loadingEnabled: true,
  skeletonComponent: TalentListSkeleton,
  storeKey: FreelancersList.GetComponentName(),
  shouldRefetchOnQueryChange: (oldQuery, newQuery) =>
    // if invite is the same, then some search param has changed
    // so, refetch
    oldQuery.invite === newQuery.invite,
})(FreelancersList);

const mapStateToProps = (state, props) => {
  const listState = getListState(state, FreelancersList.GetComponentName());
  return {
    currentUser: state.organizations.active,
    match: props.match,
    people: listState.items,
    pagination: listState.pagination,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const FreelancersListConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FreelancersListWithTDApi);

export default withRouter(withFilters(FreelancersListConnect));
