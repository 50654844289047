import PropTypes from 'prop-types';
import React from 'react';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const ProjectsSettingsProjectsTab = ({
  handleSettingsChanged,
  organization: {
    allow_managers_to_claim_projects: allowManagersToClaimProjects,
  },
}) => (
  <TDToggleWithConfirmationModal
    disableHeader="Managers can claim ownership of projects"
    DisableModalBody={() => (
      <>
        <p>
          By deactivating this setting, managers who are members of a project, but
          not the owner, will no longer be able to claim ownership.
        </p>
        <p>
          The current project owner can continue to transfer ownership, to another
          of the project&#39;s managers.
        </p>
        <p className="mb-4">Are you sure you want to deactivate this setting?</p>
      </>
    )}
    enableHeader="Managers cannot claim ownership of projects"
    EnableModalBody={() => (
      <>
        <p>
          By enabling this setting, managers who are members of a project, but not
          the owner, will be able to claim ownership of it.
        </p>
        <p>
          They will then be able to update the project&#39;s members, deadlines,
          briefs, and support documents.
        </p>
        <p>The current project owner will be able to re-claim ownership.</p>
        <p className="mb-4">Are you sure you want to activate this setting?</p>
      </>
    )}
    label="Allow managers to claim project ownership"
    modalId="allow-managers-to-claim-projects"
    onToggled={selected => handleSettingsChanged({
      allow_managers_to_claim_projects: selected,
    })}
    selected={allowManagersToClaimProjects}
    sublabel={(
      'Allow managers to claim ownership of a project, when they are not the project owner.'
    )}
  />
);

ProjectsSettingsProjectsTab.propTypes = {
  handleSettingsChanged: PropTypes.func.isRequired,
  organization: orgSpec.isRequired,
};

export default ProjectsSettingsProjectsTab;
