import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BS_STYLE, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { paginationSpec, routerHistorySpec, routerMatchSpec, billableAnalyticsSpec } from 'core/assets/js/lib/objectSpecs';
import { expenseSpec } from 'finance/assets/js/lib/objectSpecs';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import { getListState, listUpdateItemAC } from 'core/assets/js/ducks/list';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { financeExpensesCreateUrl, financeExpensesCsvApiUrl } from 'finance/urls';
import { fetchFinanceExpenseListDS } from 'finance/assets/js/data-services/list';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import ExpensesTable from 'finance/assets/js/components/ExpensesTable.jsx';
import {
  modalOpenAC, getModalPayload,
} from 'core/assets/js/ducks/modalLauncher';
import BillableAnalyticsBreakDownModal from 'finance/assets/js/BillableAnalyticsBreakDownModal.jsx';
import BillableAnalyticsMeta from 'finance/assets/js/BillableAnalyticsMeta.jsx';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';

const WORKSHEET_BILLABLE_BREAKDOWN = 'worksheet-billable-breakdown';

class ExpensesListView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return Promise.all([
      dispatch(fetchFinanceExpenseListDS({
        url,
        params,
        querystring,
        componentName,
        authedAxios,
        dispatch,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ExpensesListView';
  }

  constructor(props) {
    super(props);

    this.handleExpenseUpdated = this.handleExpenseUpdated.bind(this);
  }

  handleExpenseUpdated(expense) {
    const { dispatch, match: { params: { orgAlias } } } = this.props;

    dispatch(
      listUpdateItemAC(expense.id, expense, ExpensesListView.GetComponentName()),
    );
    dispatch(refreshFinancePendingCountsDS({ orgAlias }));
  }

  _getBillableAnalyticsMetaProps() {
    const { billableAnalytics, dispatch, organizationCurrency, pagination } = this.props;
    let currencySymbol = null;
    let totalAmount = null;
    let totalsByCurrency = {};
    if (billableAnalytics) {
      ({ totalAmount, totalsByCurrency } = billableAnalytics);
      const currencies = Object.keys(totalsByCurrency);
      const [firstCurrency] = currencies;
      const multipleCurrencies = currencies.length > 1;
      currencySymbol = CURRENCY_SYMBOL[multipleCurrencies ? organizationCurrency : firstCurrency];
      totalAmount = multipleCurrencies ? totalAmount : totalsByCurrency[firstCurrency];
    }
    return {
      currencySymbol,
      onOpenModal: () => {
        dispatch(modalOpenAC(WORKSHEET_BILLABLE_BREAKDOWN, { billableAnalytics }));
      },
      showTotal: pagination.pages <= 1,
      totalAmount,
      total: pagination.total,
    };
  }

  render() {
    const {
      amountLimits,
      billableAnalytics,
      billableModalPayload,
      expenseAccessControl,
      expenses,
      filtersOpen,
      history,
      location,
      match: { params: { orgAlias } },
      onFiltersToggle,
      pagination,
      rateUnit,
      searchActive,
    } = this.props;

    const breadcrumbs = [
      {
        title: 'Expenses',
        url: null,
      },
    ];

    let ctaButtonItems = null;
    if (expenseAccessControl.canClaim) {
      ctaButtonItems = [{
        label: 'Claim expense',
        onClick: () => history.push(financeExpensesCreateUrl(orgAlias)),
        variant: BS_STYLE.PRIMARY,
      }];
    }
    const billableAnalyticsMetaProps = this._getBillableAnalyticsMetaProps();

    return (
      <React.Fragment>
        {billableModalPayload && <BillableAnalyticsBreakDownModal /> }

        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

        <div className="page page--finance">
          <div className="container">
            <div className="row">
              {searchActive && (
                <ReportSearch
                  reportType={REPORT_TYPES.EXPENSE}
                  amountLimits={amountLimits}
                  onFiltersToggle={onFiltersToggle}
                  filtersOpen={filtersOpen}
                />
              )}
              {!filtersOpen && (
                <ListActionsButton
                  options={[{
                    name: 'Export',
                    onClick: () => {
                      WINDOW_OPEN(`${financeExpensesCsvApiUrl(orgAlias)}${location.search}`);
                    },
                  }]}
                />
              )}
            </div>
            <TDApiConnected duck="list" component={this.constructor} skeletonComponent={FinanceTableSkeleton}>
              {!filtersOpen && (
                <React.Fragment>
                  <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
                    <ExpensesTable
                      list={expenses}
                      onExpenseCancelled={this.handleExpenseUpdated}
                      rateUnit={rateUnit}
                      emptyText="No expenses found"
                    />
                  </div>
                  {billableAnalytics && pagination.pages <= 1 && (
                    <BillableAnalyticsMeta
                      {...billableAnalyticsMetaProps}
                    />
                  )}
                  <TDPagination
                    {...pagination}
                    extraMeta={billableAnalytics && (
                      <BillableAnalyticsMeta
                        {...billableAnalyticsMetaProps}
                      />
                    )}
                  />
                </React.Fragment>
              )}
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ExpensesListView.propTypes = {
  amountLimits: PropTypes.object,
  billableAnalytics: billableAnalyticsSpec,
  billableModalPayload: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  expenseAccessControl: PropTypes.object,
  expenses: PropTypes.arrayOf(expenseSpec).isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  organizationCurrency: PropTypes.string.isRequired,
  pagination: paginationSpec.isRequired,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  searchActive: PropTypes.bool.isRequired,
};

ExpensesListView.defaultProps = {
  amountLimits: {},
  billableAnalytics: null,
  billableModalPayload: null,
  expenseAccessControl: { canClaim: false },
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ExpensesListView.GetComponentName());

  const props = {
    amountLimits: listState.extras.amountLimits,
    organizationCurrency: selectActiveOrg(state).currency,
    expenseAccessControl: listState.extras.accessControl,
    expenses: listState.items,
    pagination: listState.pagination,
    rateUnit: selectActiveOrg(state).default_rate_unit,
    searchActive: listState.search.isActive,
    billableAnalytics: listState.extras.billableAnalytics,
    billableModalPayload: getModalPayload(state, WORKSHEET_BILLABLE_BREAKDOWN),
  };

  return props;
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ExpensesListViewConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ExpensesListView);

export default withRouter(withFilters(ExpensesListViewConnected));
