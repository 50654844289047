const { COOKIE_MAX_AGE } = require('core/assets/js/constants');

export * from 'core/assets/js/constants';

// The cookie max age is set to milliseconds, while we need to use seconds for the redis ttl
export const SESSION_TTL_SECS = COOKIE_MAX_AGE / 1000;
export const REMEMBER_ME_COOKIE_NAME = 'rememberMe';

export const TALENTDESK_ORGS_ALIASES = [
  'talent-desk-io-core', 'pph',
];

export const SLACK_TEST_CHANNEL = 'slack-messages-test';
export const SLACK_DEV_TEAM_CHANNEL = 'dev-team';
export const SLACK_INVOICING_CHANNEL = 'invoicing';
