import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DOCUMENT_CREATE_ELEMENT, DOCUMENT_BODY, DOCUMENT_QUERY_SELECTOR } from 'core/assets/js/config/settings';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectTrialSubscription } from 'organizations/assets/js/reducers/subscriptions';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';


const HubSpotForm = ({ formId, activeOrg }) => {
  useEffect(() => {
    const hsScript = DOCUMENT_CREATE_ELEMENT('script');
    hsScript.innerHTML = `
      hbspt.forms.create({
        region: "na1",
        portalId: "4984585",
        formId: "${formId}",
        onFormReady: (form) => {
          form.elements["talentdesk_aor_associated_organization"].value = '${activeOrg?.alias}';
        },
      });
    `;

    const hsFormScript = DOCUMENT_CREATE_ELEMENT('script');
    hsFormScript.type = 'text/javascript';
    hsFormScript.src = '//js.hsforms.net/forms/v2.js';
    hsFormScript.onload = () => {
      DOCUMENT_QUERY_SELECTOR('#hubspot-form-placeholder').appendChild(hsScript);
    };
    DOCUMENT_BODY.appendChild(hsFormScript);
  }, []);

  return (
    <div id="hubspot-form-placeholder" />
  );
};

HubSpotForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  formId: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = state => ({
  trialSubscription: selectTrialSubscription(state),
  activeOrg: selectActiveOrg(state),
});

const ContactSalesModalConnected = connect(mapStateToProps, mapDispatchToProps)(HubSpotForm);

export default ContactSalesModalConnected;
