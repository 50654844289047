import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { reviewSpec } from 'people/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ReviewList from 'people/assets/js/components/ReviewList.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { fetchReviewsDS } from 'people/assets/js/ducks/providers';
import PeopleReviewsNotesTabSkeleton from 'core/assets/js/components/Skeleton/PeopleReviewsNotesTabSkeleton.jsx';


class PeopleReviewsTab extends React.PureComponent {
  static FetchData({ dispatch, params, url, authedAxios }) {
    return dispatch(fetchReviewsDS({ url, params, authedAxios }));
  }

  static GetComponentName() {
    return 'PeopleReviewsTab';
  }

  render() {
    const {
      reviews,
      viewerId,
    } = this.props;

    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
        skeletonComponent={() => <PeopleReviewsNotesTabSkeleton isReview />}
      >
        <div className="tab-content w-100 p-4">
          <ReviewList
            reviews={reviews}
            viewerId={viewerId}
          />
        </div>
      </TDApiConnected>
    );
  }
}

PeopleReviewsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reviews: PropTypes.arrayOf(reviewSpec).isRequired,
  viewerId: PropTypes.number.isRequired,
};


const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, PeopleReviewsTab.GetComponentName());
  const { item: userCard, item: { user } } = viewState;

  return {
    userCard,
    user,
    params: props.match.params,
    reviews: state.providers.reviewList.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PeopleReviewTabConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleReviewsTab);

export default withRouter(PeopleReviewTabConnect);
