import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pick, isEmpty } from 'lodash';

import { PROJECT_TABS } from 'core/assets/js/constants';
import { exchangeRatesResponseSpec, expenseSpec } from 'finance/assets/js/lib/objectSpecs';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { fetchFinanceExpenseDS } from 'finance/assets/js/data-services/view';
import { financeExpensesUrl } from 'finance/urls';
import { projectViewUrl } from 'projects/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import ExpenseForm from 'finance/assets/js/components/ExpenseForm.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { projectUpdateExpenseDS } from 'projects/assets/js/data-services/form';
import { fetchNextExpenseInfoDS } from 'projects/assets/js/data-services/view';
import { fetchExchangeRatesDS } from 'finance/assets/js/data-services/form';

class ExpenseEditView extends React.Component {
  static async FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const [{ expense }] = await Promise.all([
      dispatch(fetchFinanceExpenseDS({
        orgAlias: params.orgAlias, id: params.expenseId, url, authedAxios, componentName,
      })),
      dispatch(fetchExchangeRatesDS({
        authedAxios, componentName, orgAlias: params.orgAlias, url,
      })),
    ]);
    return dispatch(fetchNextExpenseInfoDS({
      authedAxios, componentName, id: expense.projectId, orgAlias: params.orgAlias, url,
    }));
  }

  static GetComponentName() {
    return 'ExpenseEditView';
  }

  constructor(props) {
    super(props);

    this.getFormInitialValues = this.getFormInitialValues.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  getFormInitialValues() {
    const { expense } = this.props;

    if (!expense || isEmpty(expense)) {
      return {};
    }

    let cost = 0;
    let { worksheetCurrency: currency } = expense;
    let quantity = 0;

    if (!isEmpty(expense.items)) {
      ({
        items: [{
          expenseItemCurrency: currency, expenseItemRateAmount: cost, quantity,
        }],
      } = expense);
    }

    return {
      ...pick(expense, 'attachments', 'summary', 'periodStart', 'periodEnd'),
      cost,
      currency,
      quantity,
    };
  }

  async _onSubmit(values) {
    const { dispatch, history, match: { params: { expenseId, orgAlias } }, project } = this.props;
    try {
      await dispatch(
        projectUpdateExpenseDS({
          expenseId, orgAlias, projectId: project.id, values,
        }),
      );

      history.push(projectViewUrl(orgAlias, project.id, PROJECT_TABS.EXPENSES));

      return true;
    } catch (error) {
      return error.errors;
    }
  }

  render() {
    const { exchangeRatesResponse, info, match: { params: { orgAlias } }, project } = this.props;
    const initialValues = this.getFormInitialValues();
    const breadcrumbs = [
      {
        title: 'Expenses',
        url: financeExpensesUrl(orgAlias),
      },
      {
        title: 'Edit Expense',
        url: null,
      },
    ];

    const {
      rates: exchangeRates = {}, service: exchangeRatesService, timestamp: exchangeRateUpdatedAt,
    } = exchangeRatesResponse;

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />

        <div className="page page--update-expense">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={ProjectCardSkeleton}
            >
              <ExpenseForm
                allowedPeriodStart={info.allowedPeriodStart}
                disableSubmit={false}
                exchangeRates={exchangeRates}
                exchangeRatesService={exchangeRatesService}
                exchangeRateUpdatedAt={exchangeRateUpdatedAt}
                initialValues={initialValues}
                onSubmit={this._onSubmit}
                orgAlias={orgAlias}
                project={project}
                updating
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ExpenseEditView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  exchangeRatesResponse: exchangeRatesResponseSpec,
  expense: expenseSpec.isRequired,
  history: routerHistorySpec.isRequired,
  info: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  project: projectSpec.isRequired,
};

ExpenseEditView.defaultProps = {
  exchangeRatesResponse: {},
  info: {},
};

const mapStateTopProps = (state) => {
  const componentName = ExpenseEditView.GetComponentName();
  return {
    exchangeRatesResponse: getViewStateExtras(state, componentName, 'exchangeRates'),
    expense: getViewState(state, componentName).item,
    info: getViewStateExtras(state, componentName, 'nextExpenseInfo'),
    project: getViewStateExtras(state, componentName, 'project'),
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ExpenseEditViewConnected = connect(
  mapStateTopProps,
  mapDispatchToProps,
)(ExpenseEditView);

export default withRouter(ExpenseEditViewConnected);
