import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { modalDataSpec } from 'core/assets/js/lib/objectSpecs';

const ConfirmationButton = ({
  buttonSize, buttonStyle, buttonLabel, buttonClass, modalProps, buttonIcon,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <React.Fragment>
      <ModalConfirm
        {...modalProps}
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
      />
      <TDButton
        type="button"
        variant={buttonStyle}
        bsSize={buttonSize}
        onClick={() => setIsModalOpen(true)}
        onKeyDown={e => e.key === 'Enter' && setIsModalOpen(true)}
        className={buttonClass}
        btnIcon={buttonIcon}
        tabIndex={0}
        label={buttonLabel}
      />
    </React.Fragment>
  );
};

ConfirmationButton.propTypes = {
  buttonClass: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonSize: PropTypes.oneOf(Object.values(BS_SIZE)),
  buttonStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
  children: PropTypes.node,
  modalProps: modalDataSpec.isRequired,
};

ConfirmationButton.defaultProps = {
  children: null,
  buttonClass: '',
  buttonLabel: null,
  buttonStyle: BS_STYLE.PRIMARY,
  buttonSize: BS_SIZE.DEFAULT,
  buttonIcon: null,
};

export default ConfirmationButton;
