import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  RATE_ADJUSTMENT_TIMELINE_ITEM_VARIANT,
} from 'rates/assets/js/constants';
import { TIMELINE_ITEM_TYPES } from 'core/assets/js/constants';
import { lineBreakToBr } from 'core/assets/js/lib/utils-jsx';
import Timeline from 'core/assets/js/components/Timeline.jsx';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';


const RateAdjustmentTimeline = ({ rateAdjustments, scrollMode, timelineFooter }) => {
  if (!rateAdjustments.length) {
    return [];
  }

  const timelineItems = rateAdjustments.map((ra) => {
    const variant = RATE_ADJUSTMENT_TIMELINE_ITEM_VARIANT[ra.status]
      || TIMELINE_ITEM_TYPES.EMPHASIZE;
    // The user can't change the rate unit - the prev and new rate unit are always the same
    const rateUnit = ra.prev_rate_unit;
    const prevDisplayRate = (
      <RateAmount
        amount={ra.prev_amt}
        unit={rateUnit}
        symbol={ra.currencySymbol}
      />
    );
    const newDisplayRate = (
      <RateAmount
        amount={ra.new_amt}
        unit={rateUnit}
        symbol={ra.currencySymbol}
      />
    );

    const header = (
      <span>
        { ra.prev_amt && (
          <React.Fragment>
            {prevDisplayRate}
            &nbsp;&rarr;&nbsp;
          </React.Fragment>
        )}
        {newDisplayRate}
      </span>
    );

    const content = (
      <p>
        <strong>Message: </strong>
        <br />
        {lineBreakToBr(ra.initiator_msg)}
        { ra.responder_msg && (
          <span>
            <br />
            <strong>Decision comment:</strong>
            <br />
            {lineBreakToBr(ra.responder_msg)}
          </span>
        )}
      </p>
    );
    const footer = (
      <span className={`float-right status status--${ra.status_label}`}>{ra.status_label}</span>
    );
    const date = moment(ra.date);

    return {
      id: ra.id,
      variant,
      header,
      timestamp: date.fromNow(),
      timestampTitle: date.format('LLL'),
      author: ra.initiator_name,
      content,
      footer,
    };
  });

  return (
    <Timeline
      key="timeline"
      scrollMode={scrollMode}
      items={timelineItems}
      timelineFooter={timelineFooter}
    />
  );
};

RateAdjustmentTimeline.propTypes = {
  timelineFooter: PropTypes.node,
  rateAdjustments: PropTypes.array,
  scrollMode: PropTypes.bool,
};
RateAdjustmentTimeline.defaultProps = {
  timelineFooter: '',
  rateAdjustments: [],
  scrollMode: false,
};

export default RateAdjustmentTimeline;
