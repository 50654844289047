import React from 'react';
import { Card } from 'react-bootstrap';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const fieldsMap = [
  { label: 'First name', name: 'first_name', required: true },
  { label: 'Last name', name: 'last_name', required: true },
  { label: 'Email', name: 'email', required: true },
  { label: 'Phone', name: 'phone' },
  { label: 'Resume', name: 'resume' },
  { label: 'Job Title', name: 'job_title' },
  { label: 'Address', name: 'address' },
];

const ContactsImportMappingFormSkeleton = () => (
  <Card
    className="contacts-skeleton--mapping contacts-import--mapping"
  >
    <Card.Body>
      <h3 className="mb-3 mt-0">Map fields</h3>
      <div>
        We’ve uploaded your CSV file and detected
        {' '}
        <span className="font-weight-bolder">
          <RectangleSkeleton height="20" width="20" />
        </span>
        {' '}
        contacts ready for import. Make sure your fields below match the ones TalentDesk
        uses and finalise your import.
      </div>
      <hr />
      <div className="row d-none d-sm-flex align-items-center mb-4">
        <div className="col-12 col-sm-3 mb-0">
          <div className="mapping-header font-weight-bolder">Predefined fields</div>
        </div>
        <div className="col-12 col-sm-9 pl-0">
          <div className="mapping-header font-weight-bolder">Imported file headers</div>
        </div>
      </div>
      { fieldsMap.map(field => (
        <div className="row" key={field.name}>
          <div className="d-none d-sm-flex col-3">
            <label className="w-100 bg-gray pt-3 pl-3 mb-0">
              {`${field.label} ${field.required ? '*' : ''}`}
            </label>
          </div>
          <div className="col-12 col-sm-9 pb-3 pl-4 pl-sm-0">
            <div className="d-block d-sm-none">{`${field.label} ${field.required ? '*' : ''}`}</div>
            <RectangleSkeleton
              labelClassName="d-inline-block d-sm-none"
              height="30"
              width="400"
            />
          </div>
        </div>
      ))}
    </Card.Body>
  </Card>
);

export default ContactsImportMappingFormSkeleton;
