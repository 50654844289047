import PropTypes from 'prop-types';
import React from 'react';

import SearchSelectField from 'core/assets/js/components/FinalFormFields/SearchSelectField.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { getLanguagesByIdsApiUrl, languageSearchApiUrl } from 'skills/urls';

const LanguageSelectField = ({ label, name, required, sublabel }) => (
  <SearchSelectField
    getOptionsByIds={async languageIds => {
      const { data } = await axios.post(getLanguagesByIdsApiUrl(), { languageIds });
      return data.map(entry => ({ label: entry.label, value: entry.id }));
    }}
    label={label}
    loadOptions={async term => {
      const { data } = await axios.get(languageSearchApiUrl({ term }));
      return data.map(l => ({ label: l.label, value: l.id }));
    }}
    name={name}
    placeholder="Search languages"
    required={required}
    sublabel={sublabel}
  />
);

LanguageSelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
};

LanguageSelectField.defaultProps = {
  label: null,
  required: false,
  sublabel: null,
};

export default LanguageSelectField;
