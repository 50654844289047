import { SYSTEM_ROLES } from 'roles/assets/js/constants';

// eslint-disable-next-line import/prefer-default-export
export const userCardToAutocompleteItem = (userCard) => {
  const {
    currencySymbol,
    rate,
    rateUnit,
    user: { id, profile, profile: { skills } },
    userRole,
  } = userCard;

  const skillNames = skills.map(s => s.label);
  const label = `${profile.name} (${profile.jobTitle}) [${skillNames.join(', ')}]`;

  const autoComplete = {
    id,
    label,
    fullName: profile.name,
    jobTitle: profile.jobTitle,
    avatar: profile.avatar,
  };

  if (userRole && userRole.isProvider) {
    Object.assign(autoComplete, {
      skills,
      rate,
      rateUnit,
      currency: currencySymbol,
    });
  }

  return autoComplete;
};

/**
 * Based on the passed in user card, do they have the specified permission
 * IMPORTANT: This must match the logic in the UserCard model's hasPermission method
 *
 * @param {Object} userCard
 * @param {String} permission
 * @returns {Boolean}
 */
export const checkPermissionOnUserCard = (userCard, permission) => (
  userCard?.userRole?.isOrgCreator
  || userCard?.userCardRoles?.some(
    ({ system_role_id }) => system_role_id === SYSTEM_ROLES.SYSTEM_ADMIN,
  )
  || userCard?.userCardPermissions?.includes(permission)
);
