import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { orgOnboardCreateOrgUrl } from 'organizations/urls';
import { USER_CARD_STATUS, IMG_SIZE, ICON } from 'core/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import { projectListUrl } from 'projects/urls';
import { selectActiveOrg, selectActiveUserCard, selectUserCards } from 'organizations/assets/js/reducers/organizations';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
/**
 * The organisation switcher
 *
 * Allows a user to switch from one organisation they belong to or own, to another
 */

class OrganizationSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.props = props;
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.expand = this.expand.bind(this);
    this.collapse = this.collapse.bind(this);
    this.prepareOrgList = this.prepareOrgList.bind(this);
  }

  handleChange(orgAlias) {
    const { history } = this.props;
    this.collapse();
    history.push(projectListUrl(orgAlias));
  }

  expand() {
    this.setState({ open: true });
  }

  collapse() {
    this.setState({ open: false });
  }

  toggle() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  /**
   * Prepare the user's organisation list
   *
   * Omit the active org and the organisations in which the user's submission is pending or
   * rejected.
   *
   * TODO: Use appropriate method of AccessControl
   */
  prepareOrgList() {
    const { activeOrg, userCards } = this.props;
    const orgOpts = [];

    userCards.forEach((uc) => {
      const { organization: org } = uc;

      if (!(
        org.alias !== activeOrg.alias
        && org.status === ORGANIZATION_STATUS.ACTIVE
        && uc.status === USER_CARD_STATUS.APPROVED
      )) {
        return false;
      }

      if (org) {
        orgOpts.push(
          <li key={`org-link-${org.alias}`}>
            <a
              onClick={() => this.handleChange(org.alias)}
              className="organization-switcher__org-item truncate"
            >
              <OrganizationLogo
                url={org.logo}
                orgName={org.name}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                className="img-radius"
              />
              {org.name}
            </a>
          </li>,
        );
      }

      return orgOpts;
    });

    return (
      <ul className="organization-switcher__orgs-list">
        {orgOpts}
      </ul>
    );
  }

  render() {
    const { activeOrg, activeUserCard } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <div key="organization-switcher" className={`organization-switcher ${open ? 'organization-switcher--open' : ''}`}>
          <div
            onClick={this.toggle}
            className="organization-switcher__selected-org"
          >
            <span className="organization-switcher__switch-icon fal fa-window-restore" />

            <div className="organization-switcher__org-item clearfix">
              {activeOrg.name && (
                <OrganizationLogo
                  url={activeOrg.logo}
                  orgName={activeOrg.name}
                  size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  className="img-radius float-left"
                />
              )}
              <div>
                <span className="organization-switcher__org-title truncate">
                  {activeOrg.name}
                </span>
                <span className="organization-switcher__user-role">
                  {activeUserCard.userRole.title}
                </span>
              </div>
            </div>
          </div>

          <div className="organization-switcher__expanded-area">
            {this.prepareOrgList()}

            <div className="organization-switcher__create-org">
              <Link to={orgOnboardCreateOrgUrl()}>
                <i className={ICON.ADD_CIRCLE} />
                Create new organisation
              </Link>
            </div>
          </div>
        </div>
        <div
          key="org-switcher-backdrop"
          className="organization-switcher__backdrop"
          onClick={this.collapse}
        />
      </React.Fragment>
    );
  }
}

OrganizationSwitcher.propTypes = {
  activeOrg: orgSpec.isRequired,
  history: routerHistorySpec.isRequired,
  userCards: PropTypes.arrayOf(userCardSpec).isRequired,
  activeUserCard: userCardSpec.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
  userCards: selectUserCards(state),
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrganizationSwitcherConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationSwitcher);

export default withRouter(OrganizationSwitcherConnect);
