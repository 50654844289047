import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import Recaptcha from 'react-google-recaptcha';
import { GOOGLE } from 'core/assets/js/config/settings';

const recaptchaKey = GOOGLE.recaptchaKey;
const recaptchaRef = React.createRef();

const RecaptchaField = React.memo(({ className, name }) => (
  <Field
    /**
    * Bug card: BT-396: We need to reset captcha field in every form submission otherwise,
    * in case of form submission error we get a recaptcha validation failed error too.
    */
    beforeSubmit={() => {
      // Improve UX by not "flashing" recaptcha field on successful submission.
      setTimeout(() => {
        if (recaptchaRef && recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }, 1000);
    }}
    component={({ input: { onChange }, meta: { error, submitError } }) => {
      const hasError = error || submitError;
      const classNames = ['form-group'];
      if (className) {
        classNames.push(className);
      }
      if (hasError) {
        classNames.push('has-error');
      }
      return (
        <div className={classNames.join(' ')}>
          <Recaptcha
            onChange={onChange}
            ref={recaptchaRef}
            sitekey={recaptchaKey}
          />
          {hasError && (
            <span className="help-block mt-2">{error || submitError}</span>
          )}
        </div>
      );
    }}
    name={name}
  />
));

RecaptchaField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

RecaptchaField.defaultProps = {
  className: null,
  name: 'captcha',
};

export default RecaptchaField;
