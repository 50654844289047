import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniq, pick, isEmpty } from 'lodash';

import {
  addEditableCustomFieldAC, setEditableCustomFieldsAC, getEditableCustomFields,
  fetchCustomFieldTemplateAdditionsDS, resetEditableCustomFieldsAC,
  addEditableTemplateAC, removeEditableTemplateAC, setEditableTemplatesAC,
} from 'interviews/assets/js/ducks/customFields';
import { getListState } from 'core/assets/js/ducks/list';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { isFieldVisibleToUserType } from 'interviews/assets/js/lib/utils';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import CustomFieldTemplatesSync from 'interviews/assets/js/components/CustomFieldTemplatesSync.jsx';
import CustomFieldsFormFieldset from 'interviews/assets/js/components/CustomFieldsFormFieldset.jsx';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { customFieldSpec, customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { getCanUserAnswerQuestion } from 'people/assets/js/lib/utils';

export const COMPONENT_NAME = 'CustomFieldsUpdater';

class CustomFieldsUpdater extends React.Component {
  constructor(props) {
    super(props);

    this.getUnsyncedCustomFields = this.getUnsyncedCustomFields.bind(this);
    this.getTemplateById = this.getTemplateById.bind(this);
    this.addFieldToForm = this.addFieldToForm.bind(this);
    this.removeFieldFromForm = this.removeFieldFromForm.bind(this);
    this.handleCustomFieldAdded = this.handleCustomFieldAdded.bind(this);
    this.handleCustomFieldRemoved = this.handleCustomFieldRemoved.bind(this);
    this.handleTemplateRemoved = this.handleTemplateRemoved.bind(this);
    this.handleTemplatesValueSet = this.handleTemplatesValueSet.bind(this);
    this.handleTemplateAdded = this.handleTemplateAdded.bind(this);
  }

  componentDidMount() {
    const { dispatch, initialCustomFields } = this.props;

    // Populate the editable custom fields by adding the initial custom fields
    if (!isEmpty(initialCustomFields)) {
      dispatch(setEditableCustomFieldsAC(initialCustomFields));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetEditableCustomFieldsAC());
  }

  getUnsyncedCustomFields() {
    const { editableCustomFields, newlyAddedCustomFields } = this.props;
    const editableFieldPaths = editableCustomFields.map(cf => cf.path);

    const editableTemplateIds = uniq(editableCustomFields.map(cf => cf.customFieldTemplateId));
    return newlyAddedCustomFields.filter(cf => (
      editableTemplateIds.includes(cf.customFieldTemplateId)
        && !editableFieldPaths.includes(cf.path)
    ));
  }

  getTemplateById(templateId) {
    const { templates } = this.props;
    const template = templates.find(t => t.id === templateId);

    if (!template) {
      throw new Error('Template not found in props');
    }

    return template;
  }

  addFieldToForm(field) {
    const { addedCustomFieldsFieldName, form: { registerField, mutators: { push } } } = this.props;

    if (typeof push !== 'function') {
      throw new Error('Please add the array mutator to the form');
    }

    registerField(field.path, (fieldState) => { fieldState.change(''); });
    push(addedCustomFieldsFieldName, field.id);
  }

  removeFieldFromForm(field) {
    const { form: { change } } = this.props;
    change(field.path, undefined);
  }

  handleCustomFieldAdded(field) {
    const { dispatch, onFieldAdded } = this.props;
    dispatch(addEditableCustomFieldAC(field));
    this.addFieldToForm(field);
    onFieldAdded(field);
  }

  handleCustomFieldRemoved(field) {
    const { onFieldRemoved } = this.props;
    this.removeFieldFromForm(field);
    onFieldRemoved(field);
  }

  handleTemplateAdded(templateId) {
    const { dispatch, onTemplateAdded, form: { batch } } = this.props;
    const template = this.getTemplateById(templateId);

    // Add the template as editable
    dispatch(addEditableTemplateAC(template));
    // Batch update the form
    batch(() => template.questions.forEach(cf => this.addFieldToForm(cf)));

    onTemplateAdded(template);
  }

  handleTemplateRemoved(templateId) {
    const { dispatch, onTemplateRemoved, form: { batch } } = this.props;
    const template = this.getTemplateById(templateId);

    // Remove fields from being editable
    dispatch(removeEditableTemplateAC(template));
    // Batch update the form
    batch(() => template.questions.forEach(cf => this.removeFieldFromForm(cf)));

    onTemplateRemoved(template);
  }

  handleTemplatesValueSet(templateIds) {
    const { dispatch, editableCustomFields, templates, form: { batch } } = this.props;
    const selectedTemplates = templates.filter(t => templateIds.includes(t.id));
    const selectedCustomFields = selectedTemplates.map(t => t.questions).flat();
    const selectedPaths = selectedCustomFields.map(q => q.path);
    const currentPaths = editableCustomFields.map(cf => cf.path);

    // Fix the form values - add new custom fields, remove the ones that were removed from state
    const addedPaths = selectedPaths.filter(p => !currentPaths.includes(p));
    const removedPaths = currentPaths.filter(p => !selectedPaths.includes(p));
    const addedCustomFields = selectedCustomFields.filter(cf => addedPaths.includes(cf.path));
    const removedCustomFields = editableCustomFields.filter(cf => removedPaths.includes(cf.path));

    // reset the templates selection in state
    dispatch(setEditableTemplatesAC(selectedTemplates));

    // reset the form values to the ones we're currently using
    batch(() => addedCustomFields.forEach(cf => this.addFieldToForm(cf)));
    batch(() => removedCustomFields.forEach(cf => this.removeFieldFromForm(cf)));
  }

  render() {
    const {
      activeUserCard: { userRole: { ofType: activeUserType } },
      addedCustomFieldsFieldName,
      allowTemplateSelection,
      editableCustomFields,
      enableSync,
      form: { change, getState },
      showTemplateNames,
      templates,
      useCurrentTemplates,
    } = this.props;

    const { initialValues } = getState();
    const unsyncedFields = this.getUnsyncedCustomFields();

    const templateIds = templates.map(t => t.id);

    const fieldsetProps = {
      ...pick(this.props, 'templateFieldLabel', 'templateFieldSublabel',
        'templateFieldSublabel', 'templatesFieldName', 'fieldWrapper',
        'onTemplatesValueSet',
      ),
      activeUserType,
      initialValues,
      allowTemplateSelection,
      customFields: editableCustomFields.filter(field => (
        isFieldVisibleToUserType(field, activeUserType)
        // Only include custom fields for the currently selected templates
        && (!useCurrentTemplates || templateIds.includes(field.customFieldTemplateId))
      )),
      customFieldTemplates: templates,
    };

    return (
      <React.Fragment>
        {enableSync && (
          <CustomFieldTemplatesSync
            templates={templates}
            customFields={unsyncedFields}
            onFieldAdded={this.handleCustomFieldAdded}
            onTemplateRemoved={this.handleTemplateRemoved}
            enableTemplateRemoval={!allowTemplateSelection}
          />
        )}

        <CustomFieldsFormFieldset
          {...fieldsetProps}
          onTemplateAdded={this.handleTemplateAdded}
          onTemplateCleared={!showTemplateNames ? null : templateId => {
            if (!showTemplateNames) {
              // Should not get here
              return;
            }
            const template = templates.find(t => t.id === templateId);
            if (!template) {
              // Should not happen
              return;
            }
            if (template.isMandatory) {
              // Should not get here
              return;
            }
            template.questions.forEach(question => {
              if (getCanUserAnswerQuestion(question, activeUserType)) {
                change(question.path, '');
              }
            });
          }}
          onTemplateRemoved={this.handleTemplateRemoved}
          onTemplatesValueSet={this.handleTemplatesValueSet}
        />

        <FieldArray
          name={addedCustomFieldsFieldName}
        >
          {({ fields }) => fields.map(name => (
            <Field
              key={`custom-field-id-${name}`}
              component="input"
              type="hidden"
              name={name}
            />
          ))}
        </FieldArray>
      </React.Fragment>
    );
  }
}

CustomFieldsUpdater.propTypes = {
  activeUserCard: userCardSpec,
  addedCustomFieldsFieldName: PropTypes.string,
  allowTemplateSelection: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  editableCustomFields: PropTypes.arrayOf(customFieldSpec),
  enableSync: PropTypes.bool,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.oneOf(Object.values(FIELD_ENTITY_TYPE)).isRequired,
  fieldWrapper: PropTypes.func,
  form: PropTypes.object.isRequired,
  initialCustomFields: PropTypes.arrayOf(customFieldSpec),
  initialValues: PropTypes.object,
  newlyAddedCustomFields: PropTypes.arrayOf(customFieldSpec),
  onFieldAdded: PropTypes.func,
  onFieldRemoved: PropTypes.func,
  onTemplateAdded: PropTypes.func,
  onTemplateRemoved: PropTypes.func,
  onTemplatesValueSet: PropTypes.func,
  showTemplateNames: PropTypes.bool,
  templateFieldLabel: PropTypes.string.isRequired,
  templateFieldSublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  templates: PropTypes.arrayOf(customFieldTemplateSpec),
  templatesFieldName: PropTypes.string,
  useCurrentTemplates: PropTypes.bool,
};

CustomFieldsUpdater.defaultProps = {
  activeUserCard: {},
  addedCustomFieldsFieldName: 'custom_field_ids',
  allowTemplateSelection: false,
  editableCustomFields: [],
  enableSync: false,
  entityId: null,
  fieldWrapper: null,
  initialCustomFields: [],
  initialValues: {},
  newlyAddedCustomFields: [],
  onFieldAdded: () => {},
  onFieldRemoved: () => {},
  onTemplateAdded: () => {},
  onTemplateRemoved: () => {},
  onTemplatesValueSet: () => {},
  showTemplateNames: false,
  templateFieldSublabel: null,
  templates: [],
  templatesFieldName: 'custom_field_templates',
  useCurrentTemplates: false,
};


const mapStateToProps = state => ({
  activeUserCard: selectActiveUserCard(state),
  newlyAddedCustomFields: getListState(state, COMPONENT_NAME).items,
  editableCustomFields: getEditableCustomFields(state, COMPONENT_NAME),
});
const mapDispatchToProps = dispatch => ({ dispatch });

const CustomFieldsUpdaterConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFieldsUpdater);

const CustomFieldsUpdaterWithTdApiConnected = withTDApiConnected({
  fetchData: ({
    url,
    dispatch,
    authedAxios,
    componentName,
    params: { orgAlias },
    props: { entityType, entityId, enableSync },
  }) => {
    if (entityId && enableSync) {
      return dispatch(fetchCustomFieldTemplateAdditionsDS({
        orgAlias, entityType, entityId, authedAxios, url, componentName,
      }));
    }

    return Promise.resolve();
  },
  duck: 'list',
  storeKey: COMPONENT_NAME,
  skeletonComponent: ListSkeleton,
})(CustomFieldsUpdaterConnected);

export default CustomFieldsUpdaterWithTdApiConnected;
