import React from 'react';
import PropTypes from 'prop-types';

import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import withField from 'core/assets/js/components/withField.jsx';


class SwitchFilterField extends React.Component {
  constructor(props) {
    super(props);
    const { initialValues, filter, input } = props;
    let initialValue = null;

    if (initialValues[filter.paramName]) {
      initialValue = JSON.parse(initialValues[filter.paramName]);
    }
    input.onChange(initialValue);

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    const { input } = this.props;
    input.onChange(!input.value ? true : null);
  }

  render() {
    const { input, filter: { label, switchText } } = this.props;

    return (
      <React.Fragment>
        <h4>{label}</h4>

        <div className="row">
          <div className="col-12">
            <ul className="selectable-list">
              <li>
                <label className="d-flex align-items-center" onClick={this.handleToggle}>
                  <TDSwitch selected={input.value} />
                  <span className="ml-2">{switchText}</span>
                </label>
              </li>
            </ul>

            <input
              {...input}
              type="hidden"
              className="form-control"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SwitchFilterField.propTypes = {
  filter: PropTypes.object,
  input: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

SwitchFilterField.defaultProps = {
  filter: {},
};

export default withField(SwitchFilterField);
