import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { Card } from 'react-bootstrap';
import ActivityCard from 'notifier/assets/js/components/ActivityCard.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { activityLogApiUrl } from 'notifier/urls';


class ProjectTabActivity extends React.Component {
  static GetComponentName() {
    return 'ProjectTabActivity';
  }

  render() {
    const { records, pagination } = this.props;

    return (
      <React.Fragment>
        <TDApiConnected
          duck="list"
          storeKey={ProjectTabActivity.GetComponentName()}
          fetchData={({ dispatch, querystring, componentName, authedAxios, url, params }) => {
            const apiUrl = activityLogApiUrl(params.orgAlias, params.id, url);
            return dispatch(fetchListDS({ url: apiUrl, querystring, componentName, authedAxios }));
          }}
        >
          <Card className="discussion-board">
            <Card.Body className="discussion-board__content">
              <div className="discussion-board__messages">
                <div className="discussion-board__messages-wrapper">
                  <TDList
                    items={records}
                    pagination={pagination}
                    cardItem={{
                      component: ActivityCard,
                    }}
                    emptyListMessage="There aren't any messages yet."
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </TDApiConnected>
      </React.Fragment>
    );
  }
}

ProjectTabActivity.propTypes = {
  records: PropTypes.array.isRequired,
  pagination: paginationSpec.isRequired,
};

const mapStateToProps = (state) => {
  const listState = getListState(state, ProjectTabActivity.GetComponentName());

  return {
    records: listState.items,
    accessControl: listState.extras.accessControl,
    rateLimits: listState.extras.rateLimits,
    pagination: listState.pagination,
  };
};

const ProjectTeamViewConnected = connect(
  mapStateToProps,
)(ProjectTabActivity);

export default withRouter(ProjectTeamViewConnected);
