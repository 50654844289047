import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { filter, isEmpty } from 'lodash';

import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import InvoiceItemActions from 'finance/assets/js/components/InvoiceItemActions.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { expandColumnComponent } from 'core/assets/js/lib/utils-jsx';
import { financeInvoiceViewUrl } from 'finance/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { DOCUMENT_QUERY_SELECTOR_ALL } from 'core/assets/js/config/settings';

function overdueColFormat(dueAt, row) {
  if (row.isOverdue) {
    return (
      <TDElementWithTooltip
        tooltipMsg="Overdue"
      >
        <span className="text-danger">
          {dueAt}
        </span>
      </TDElementWithTooltip>
    );
  }
  return dueAt;
}


function InvoicesTable({ list, isInvoiceView, history, match: { params: { orgAlias } }, ...rest }) {
  /**
   * prevent default react-bootstrap-table behaviour of expanding when clicking on the whole row
   * making sure that row will only expand when chevron icon is clicked.
   */
  const onTableRowClick = (e) => {
    const tRow = e && e.target && e.target.closest('tr');

    if (
      e && e.target
      && e.target.tagName !== 'I'
      && !e.target.classList.contains('td-drop-button__toggle')
      && tRow.classList.contains('clickable')
      && e.target.tagName !== 'IMG'
    ) {
      const objectId = [...tRow.classList]
        .find(c => c.startsWith('objectId-'))
        .replace('objectId-', '');

      history.push(
        financeInvoiceViewUrl(orgAlias, objectId),
        history.location.pathname,
        '/projects/',
      );
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
      .forEach(trow => trow.addEventListener('click', onTableRowClick));
    return () => {
      DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
        .forEach(trow => trow.removeEventListener('click', onTableRowClick));
    };
  });

  // Some columns appear only in the worksheet list and others in worksheet view
  let tableColumns = [
    { key: 'number', label: '#' },
    { key: 'date', label: 'Posted Date' },
    { key: 'billingPeriod', label: 'Billing Period', width: '240px' },
    { key: 'ownerName', label: 'Owner' },
    { key: 'dueAt', label: 'Due Date', dataFormat: overdueColFormat },
    {
      key: 'total',
      label: 'Amount',
      columnClassName: 'text-right',
    },
    { key: 'statusLabel',
      label: 'Status',
      dataFormat: statusLabel => (
        <StatusColFormat statusLabel={statusLabel} />
      ),
      width: '180px',
    },
    {
      key: 'actions', width: '80px', label: '',
      dataAlign: 'right', columnClassName: 'actions-cell',
    },
  ];
  // @todo: show different columns in invoice view
  let excludedColumns = [];
  if (isInvoiceView) {
    excludedColumns = [/* @todo: Add column names here */];
    tableColumns = filter(tableColumns, col => !excludedColumns.includes(col.key));
  } else {
    excludedColumns = [/* @todo: Add column names here */];
    tableColumns = filter(tableColumns, col => !excludedColumns.includes(col.key));
  }
  const rows = list.map(it => ({
    ...it,
    ownerName: it.ownerFE ? it.ownerFE.name : '',
    actions: (
      <InvoiceItemActions
        history={history}
        invoice={it}
        orgAlias={orgAlias}
      />
    ),
  }));
  const tableOptions = {
    expandableRow: () => true,
    onRowClick: onTableRowClick,
    expandComponent: opts => (
      !isEmpty(opts.attachments)
        ? (
          <AttachmentsList
            attachments={opts.attachments}
            label="Invoice Attachments"
          />
        )
        : 'No invoice attachments'
    ),
    expandColumnOptions: {
      expandColumnVisible: true,
      expandColumnComponent,
      columnWidth: '30px',
    },
  };
  return (
    <Table
      cols={tableColumns}
      items={rows.map(invoice => ({
        ...invoice,
        total: (
          <NumberTpl
            value={invoice.total}
            decimals={2}
            prefix={CURRENCY_SYMBOL[invoice.currency]}
          />
        ),
      }))}
      trClassName={(row) => { return `objectId-${row && row.id}`; }}
      {...rest}
      {...tableOptions}
    />
  );
}

InvoicesTable.propTypes = {
  list: PropTypes.array,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  isInvoiceView: PropTypes.bool,
};
InvoicesTable.defaultProps = {
  list: [],
  isInvoiceView: false,
};

export default withRouter(InvoicesTable);
