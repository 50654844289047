import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getChatMessagesUrl,
  getChatByManagerUrl,
  getOnboardingManagerUrl,
} from 'people/urls';

import {
  postChatMessage,
  updateChatRead,
} from 'people/assets/js/data-services/form';
import { extractPaginationFromHeaders } from 'core/assets/js/lib/utils';
import ChatTDLive from 'accounts/assets/js/ChatTDLive.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import GetStartedSteps from 'accounts/assets/js/components/GetStartedSteps.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { BOOTSTRAP_BREAKPOINTS, USER_CARD_STATUS } from 'core/assets/js/constants';
import { GET_STARTED_SIDE_PANEL_MODAL_ID } from 'accounts/assets/js/constants';
import { modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { WINDOW_INNER_WIDTH } from 'core/assets/js/config/settings';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { organizationsUrl } from 'organizations/urls';
import { viewFetchExtrasAC, getViewStateExtras, extrasUpdateAC } from 'core/assets/js/ducks/view';
import MiniMessageWindow from 'people/assets/js/components/chat/MiniMessageWindow.jsx';
import { isScrolledToTheTop } from 'people/assets/js/lib/utils';

// fetchSetupStepsDS
const GetStartedView = ({
  chatRoom, activeOrg, activeUserCard, dispatch, profile, messagesPagination, manager,
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [isChatExpanded, setIsChatExpanded] = useState();
  const listInnerRef = useRef();
  const orgAlias = activeOrg.unique_alias;
  const messageHubEnabled = activeOrg.message_hub_enabled;
  const componentName = GetStartedView.GetComponentName();

  useEffect(() => {
    // check screen size and if not already open
    if (
      WINDOW_INNER_WIDTH >= BOOTSTRAP_BREAKPOINTS.LARGE
    ) {
      dispatch(modalOpenAC(GET_STARTED_SIDE_PANEL_MODAL_ID));
    }

    return () => dispatch(modalCloseAC(GET_STARTED_SIDE_PANEL_MODAL_ID));
  }, []);

  useEffect(() => {
    dispatch(
      fetchDataDS({
        fetchApiUrl: () => getOnboardingManagerUrl({ orgAlias }),
        fetchDataAC: managerData => viewFetchExtrasAC(
          managerData,
          componentName,
          'manager',
        ),
      }),
    );
  }, []);

  const getChat = () => {
    return dispatch(
      fetchDataDS({
        fetchApiUrl: () => getChatByManagerUrl({ orgAlias }),
        fetchDataAC: chatRoomData => viewFetchExtrasAC(
          chatRoomData,
          componentName,
          'chatRoom',
        ),
      }),
    );
  };

  const handleMessageSubmit = async (message) => {
    if (!(message)) {
      return;
    }
    dispatch(
      postChatMessage({
        orgAlias,
        values: {
          message,
          chatId: chatRoom?.id,
          chatWithManager: true,
        },
        pushDataAC: (responseData) => {
          if (
            !Array.isArray(chatRoom?.chatMessages)
          ) {
            return getChat();
          }
          return viewFetchExtrasAC(
            {
              ...chatRoom,
              chatMessages: [
                ...(responseData?.chatRoomMessage ? [responseData.chatRoomMessage] : []),
                ...chatRoom.chatMessages,
              ],
            },
            componentName,
            'chatRoom',
          );
        },
      }),
    );
  };

  const getMessages = (query = '') => {
    dispatch(
      fetchDataDS({
        fetchApiUrl: () =>
          getChatMessagesUrl({ orgAlias, chatId: chatRoom.id }),
        fetchDataAC: (responseData) => {
          const currentMessages = chatRoom?.chatMessages
          && Array.isArray(chatRoom?.chatMessages) ? chatRoom?.chatMessages : [];
          return extrasUpdateAC(
            {
              chatRoom: {
                ...chatRoom,
                chatMessages: [
                  ...currentMessages,
                  ...responseData,
                ],
              },
            },
            componentName,
          );
        },
        paginationAC: (headers) => {
          return extrasUpdateAC(
            { messagesPagination: extractPaginationFromHeaders(headers) },
            componentName,
          );
        },
        querystring: query,
      }),
    );
  };

  useEffect(() => {
    if (!profile) {
      return;
    }

    // initialize chat
    getChat();
  }, [profile]);

  useEffect(() => {
    if (!chatRoom?.id) {
      return;
    }

    // initialize chat
    getMessages();
  }, [chatRoom?.id]);

  useEffect(() => {
    if (!newMessage?.chatRoomMessage) {
      return;
    }
    if (
      !chatRoom?.chatMessages?.find(
        (m) => m.id === newMessage?.chatRoomMessage?.id,
      )
    ) {
      const messages = [newMessage.chatRoomMessage, ...chatRoom.chatMessages];
      messages.sort(
        (d1, d2) =>
          new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime(),
      );

      if (
        chatRoom?.chatMessages
        && Array.isArray(chatRoom?.chatMessages)
        && chatRoom?.chatMessages?.length
      ) {
        const unreadCount = chatRoom.unreadCount ? chatRoom.unreadCount + 1 : 1;
        dispatch(
          viewFetchExtrasAC(
            {
              ...chatRoom,
              chatMessages: messages,
              unreadCount: isChatExpanded ? 0 : unreadCount,
            },
            componentName,
            'chatRoom',
          ),
        );
      }
    }
  }, [newMessage]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isScrolled = isScrolledToTheTop(clientHeight, scrollHeight, scrollTop);
      if (isScrolled && messagesPagination?.next) {
        getMessages(messagesPagination.next);
      }
    }
  };

  const handleRead = () => {
    if (!chatRoom?.chatMessages?.length && chatRoom?.unreadCount <= 0) {
      return;
    }
    const currentChatUser = chatRoom?.chatRoomUsers?.find(
      (u) => u.userEmail === activeUserCard?.user?.email,
    );
    if (!currentChatUser) {
      return;
    }
    dispatch(
      updateChatRead({
        orgAlias,
        chatId: chatRoom.id,
        values: { messageId: chatRoom.chatMessages[0].id },
        pushDataAC: () =>
          viewFetchExtrasAC(
            { chatRoom: { ...chatRoom, unreadCount: 0 } },
            componentName,
          ),
      }),
    );
  };

  if (activeUserCard?.status === USER_CARD_STATUS.DEACTIVATED) {
    return (
      <RedirectRoute status={302} to={organizationsUrl(activeOrg.alias)} />
    );
  }

  const breadcrumbs = [
    {
      title: 'Get started',
      url: null,
    },
  ];

  const managerProfile = chatRoom?.chatRoomUsers?.find(
    (u) => u.userId !== activeUserCard?.user?.id,
  );

  const messageWindow = (
    <MiniMessageWindow
      chatRoom={chatRoom}
      chatWindowTitle={managerProfile?.name || manager?.profile?.name}
      handleMessageSubmit={handleMessageSubmit}
      handleRead={handleRead}
      isChatExpanded={isChatExpanded}
      listInnerRef={listInnerRef}
      onScroll={onScroll}
      onStateChange={setIsChatExpanded}
      profile={managerProfile || manager?.profile}
      setIsChatExpanded={setIsChatExpanded}
      showChat={!!messageHubEnabled}
    />
  );

  return (
    <>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--finance">
        <div className="container" id="get-started-page">
          <h2 data-testid="get-started-greeting" className="mb-0">
            {profile && `Hello ${profile.firstName}!`}
          </h2>

          <GetStartedSteps />
        </div>
      </div>

      {!chatRoom?.id && (
        messageWindow
      )}

      {chatRoom?.id && (
        <ChatTDLive
          email={activeUserCard?.user?.email}
          events={{
            [`event-chat-add-${chatRoom?.id}`]: setNewMessage,
          }}
        >
          {messageWindow}
        </ChatTDLive>
      )}
    </>
  );
};

GetStartedView.GetComponentName = () => 'GetStartedView';

GetStartedView.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec.isRequired,
  chatRoom: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  manager: PropTypes.object,
  messagesPagination: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

GetStartedView.defaultProps = {
  chatRoom: null,
  manager: null,
};


const mapStateToProps = (state) => {
  const componentName = GetStartedView.GetComponentName();

  return {
    activeOrg: selectActiveOrg(state),
    activeUserCard: selectActiveUserCard(state),
    profile: selectProfile(state),
    chatRoom: getViewStateExtras(
      state,
      componentName,
      'chatRoom',
    ),
    manager: getViewStateExtras(
      state,
      componentName,
      'manager',
    ),
    messagesPagination: getViewStateExtras(
      state,
      componentName,
      'messagesPagination',
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const GetStartedViewConnected = connect(mapStateToProps, mapDispatchToProps)(GetStartedView);

export default GetStartedViewConnected;
