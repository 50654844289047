import { omit } from 'lodash';

export const ERROR_HTTP = 'ERROR_HTTP';
export const ERRORS_RESET = 'ERRORS_RESET';

const componentListInitialState = [];

const componentReducer = (state = componentListInitialState, action) => {
  switch (action.type) {
    case ERROR_HTTP: {
      return [
        ...state,
        ...action.payload,
      ];
    }
    case ERRORS_RESET:
      return [];
    default:
      return state;
  }
};

export const errorsInitialState = {
  all: [],
};

const reducer = (state = errorsInitialState, action) => {
  const { componentName } = action;
  if (componentName) {
    const componentState = componentReducer(state[componentName], action);
    switch (action.type) {
      case ERROR_HTTP: {
        return {
          ...state,
          all: [
            ...state.all,
            ...action.payload,
          ],
          [componentName]: componentState,
        };
      }
      case ERRORS_RESET:
        return omit(state, componentName);
      default:
        return state;
    }
  }

  switch (action.type) {
    case ERROR_HTTP: {
      return {
        ...state,
        all: [
          ...state.all,
          ...action.payload,
        ],
      };
    }
    case ERRORS_RESET:
      return errorsInitialState;
    default:
      return state;
  }
};

export const httpErrorAC = (rawPayload, componentName) => {
  // NOTICE: This is an httpError, there should always be a reponse.data object
  const payload = rawPayload && rawPayload[0] && rawPayload[0].response
    ? rawPayload[0].response.data
    : rawPayload;

  return {
    type: ERROR_HTTP,
    payload,
    componentName,
  };
};

export const resetErrorsAC = () => ({
  type: ERRORS_RESET,
});


export const selectErrors = (state, componentName) => {
  if (!componentName) {
    return state.errors.all;
  }
  return state.errors[componentName] || componentListInitialState;
};

export const selectHasFatalError = (state) => {
  const errors = selectErrors(state);
  if (errors.find(e => e._meta && e._meta.isFatal)) {
    return true;
  }
  return false;
};

export default reducer;
