import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { TABS_STYLING_VARIATION } from 'core/assets/js/constants';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import ProjectBudgetBurnDownChart from 'projects/assets/js/components/ProjectBudgetBurnDownChart.jsx';
import ProjectTasksBurnDownChart from 'projects/assets/js/components/ProjectTasksBurnDownChart.jsx';

const ProjectBurnDownWidget = (props) => {
  const { currencySymbol } = props;
  const [activeTab, setActiveTab] = useState('budget');
  const tabSpec = [{
    label: 'Budget',
    key: 'budget',
  }, {
    label: 'Tasks',
    key: 'tasks',
  }];


  return (
    <Card>
      <Card.Header>
        <div className="project-burn-down row d-flex align-items-center">
          <div className="col">Burndown</div>
          <div className="col-12 col-sm-auto text-right ml-auto">
            <TabBar
              className="justify-content-end"
              activeKey={activeTab}
              handleTabSelect={(tabKey) => {
                setActiveTab(tabKey);
              }}
              tabSpec={tabSpec}
              variation={TABS_STYLING_VARIATION.SIMPLE}
            />
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        { activeTab === 'budget' && (
          <ProjectBudgetBurnDownChart
            currencySymbol={currencySymbol}
          />
        )}
        { activeTab === 'tasks' && (
          <ProjectTasksBurnDownChart />
        )}
      </Card.Body>
    </Card>
  );
};


ProjectBurnDownWidget.propTypes = {
  currencySymbol: PropTypes.string,
};

ProjectBurnDownWidget.defaultProps = {
  currencySymbol: '',
};

export default ProjectBurnDownWidget;
