import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalOpenAC, modalCloseAC, getModalPayload, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { deleteTimeOffDS } from 'accounts/assets/js/data-services/timeoff';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import { routerMatchSpec, routerHistorySpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { timeoffSpec } from 'accounts/assets/js/lib/objectSpecs';
import TimeOffCardItem from 'accounts/assets/js/components/TimeOffCardItem.jsx';
import { timeOffListApiUrl, timeOffCreateUrl } from 'accounts/urls';
import TimeOffListSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const DELETE_MODAL_ID = 'delete-confirmation-modal';
const storeKey = 'TimeOffListView';

const fetchData = ({
  dispatch, url, authedAxios, componentName, querystring, params,
}) => dispatch(fetchListDS({
  authedAxios, componentName, querystring, params, url: timeOffListApiUrl(url),
}));

const TimeOffList = ({
  activeOrg, params, location, items, pagination, match, dispatch,
  deleteModalOpen, deleteModalPayload, history,
}) => {
  const handleDeleteModalOpen = (id) => {
    dispatch(modalOpenAC(DELETE_MODAL_ID, { id }));
  };

  const handleDeleteModalClose = () => {
    dispatch(modalCloseAC(DELETE_MODAL_ID));
  };

  const handleDeleteTimeOff = async () => {
    try {
      dispatch(deleteTimeOffDS(deleteModalPayload.id)).then(() => {
        toastr.success('Well done!', 'Time off period was deleted!');
        fetchData({
          dispatch,
          params,
          url: '',
          authedAxios: null,
          componentName: storeKey,
          querystring: location.search,
        });
      });
    } catch (err) {
      toastr.error('Time off period was not deleted!', err.message);
    } finally {
      handleDeleteModalClose();
    }
  };

  return (
    <React.Fragment>
      <div className="rounded shadow-sm p-4 bg-white">
        <TDList
          items={items}
          cardItem={{
            component: TimeOffCardItem,
            history,
            props: {
              orgAlias: match.params.orgAlias,
              onDelete: id => handleDeleteModalOpen(id),
            },
          }}
          emptyListMessage={(
            <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
              <i className={ICON.USER_CLOCK} />
              You will be shown as available for all days that you do not mark as days off
              <br />
              <Link to={timeOffCreateUrl(activeOrg.alias)}>
                Update availability
              </Link>
            </div>
          )}
          listClassName="timeOff-list"
        />
      </div>

      <div className="my-4">
        <TDPagination {...pagination} />
      </div>

      <ModalConfirm
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteTimeOff}
        open={deleteModalOpen}
        heading="Confirm deletion"
        body="Are you sure you want to delete this time off period?"
        confirmLabel="Delete"
        confirmStyle={BS_STYLE.DANGER}
      />

    </React.Fragment>
  );
};

TimeOffList.propTypes = {
  activeOrg: orgSpec.isRequired,
  match: routerMatchSpec.isRequired,
  pagination: paginationSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(timeoffSpec),
  deleteModalOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
  deleteModalPayload: PropTypes.object,
  history: routerHistorySpec.isRequired,
};

TimeOffList.defaultProps = {
  items: [],
  params: {},
  deleteModalOpen: false,
  deleteModalPayload: {},
};
const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  deleteModalOpen: getIsModalOpen(state, DELETE_MODAL_ID),
  deleteModalPayload: getModalPayload(state, DELETE_MODAL_ID),
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TimeOffListConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(TimeOffList);

const ConnectedList = withTDApiConnected({
  fetchData,
  duck: 'list',
  storeKey,
  skeletonComponent: TimeOffListSkeleton,
})(TimeOffListConnected);

export default ConnectedList;
