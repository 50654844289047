import { CONTACTS_IMPORT_STEP } from 'contacts/constants';

export const contactsUrl = orgAlias => (`/${orgAlias}/contacts`);
export const contactEditUrl = (orgAlias, contactId) => (`/${orgAlias}/contact/${contactId}/edit`);
export const contactCreateUrl = orgAlias => (`/${orgAlias}/contacts/create`);

// import csv

export const contactsImportUrl = (orgAlias, stepName) => {
  const step = stepName || CONTACTS_IMPORT_STEP.UPLOAD;
  return `/${orgAlias}/contacts/import/${step}`;
};
// ----------------------------- API URLS -----------------------------

export const contactsListApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/contacts`);
export const contactsListCsvApiUrl = orgAlias => (`${contactsListApiUrl({ orgAlias })}/csv`);
export const contactCreateApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/contacts/create`);
export const contactsCreateImportedApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/contacts/create-imported`);
export const contactsDeleteApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/contacts/delete`);
export const contactApiUrl = ({ orgAlias, contactId }) => (`/api/${orgAlias}/contacts/${contactId}`);
export const contactEditApiUrl = ({ orgAlias, contactId }) => (`/api/${orgAlias}/contacts/${contactId}/edit`);
