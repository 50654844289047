/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { toastr } from 'react-redux-toastr';

import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectViewUrl, projectWorksheetImportUrl } from 'projects/urls';
import { PROJECT_TABS } from 'core/assets/js/constants';
import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import ProjectWorksheetImporterDownloadTemplate from 'projects/assets/js/components/steps/ProjectWorksheetImporterDownloadTemplate.jsx';
import ProjectWorksheetImporterUploadTemplate from 'projects/assets/js/components/steps/ProjectWorksheetImporterUploadTemplate.jsx';
import ProjectWorksheetImportMappingForm from 'projects/assets/js/components/steps/ProjectWorksheetImportMappingForm.jsx';
import ProjectWorksheetImportPreview from 'projects/assets/js/components/steps/ProjectWorksheetImportPreview.jsx';
import ProjectWorksheetComplete from 'projects/assets/js/components/steps/ProjectWorksheetComplete.jsx';
import { PROJECT_WORKSHEET_IMPORT_STEP_PAGE, PROJECT_WORKSHEET_IMPORT_STEP } from 'projects/assets/js/constants';
import { importWorksheetsDS } from 'projects/assets/js/data-services/view';
import ProjectWorksheetImportHeader from 'projects/assets/js/components/steps/ProjectWorksheetImportHeader.jsx';

class ProjectWorksheetImportView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: queryString.parse(props.location.search),
    };

    this.validateDownloadTemplate = this.validateDownloadTemplate.bind(this);
    this.validateUploadTemplate = this.validateUploadTemplate.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.saveWizardState = this.saveWizardState.bind(this);
    this.onSubmitPreview = this.onSubmitPreview.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitMapFields = this.onSubmitMapFields.bind(this);
    this.onStepBack = this.onStepBack.bind(this);
    this.getSubmissionStatus = this.getSubmissionStatus.bind(this);
  }

  onSubmitMapFields(step, formValues) {
    const {
      match: { params: { orgAlias, id } }, history,
    } = this.props;
    const urlQuery = {};

    if (formValues.attachments_metadata) {
      const [{ handle }] = JSON.parse(formValues.attachments_metadata);
      Object.assign(urlQuery, {
        id: handle,
      });
    }

    history.push({
      pathname: projectWorksheetImportUrl(
        orgAlias, id, step,
      ),
      search: queryString.stringify(urlQuery),
    });
  }

  onSubmitPreview(step, formValues) {
    const {
      match: { params: { orgAlias, id } },
      history, location,
    } = this.props;
    const { id: fileHandle } = queryString.parse(location.search);

    const {
      user, userId, summary, claimingAmount, periodEnd,
      periodStart, currency, description,
    } = formValues;

    const urlQuery = {
      id: fileHandle, user, userId, summary, claimingAmount, periodEnd,
      periodStart, currency, description,
    };

    history.push({
      pathname: projectWorksheetImportUrl(
        orgAlias, id, step,
      ),
      search: queryString.stringify(urlQuery),
    });
  }

  async onSubmit(formValues) {
    const {
      match: { params: { orgAlias, id } },
      history, location, dispatch,
    } = this.props;

    const { id: fileHandle } = queryString.parse(location.search);
    const {
      user, userId, summary, claimingAmount, periodEnd,
      periodStart, currency, description,
    } = formValues;
    const values = {
      id: fileHandle, user, userId, summary, claimingAmount,
      periodEnd, periodStart, currency, description,
    };

    try {
      await dispatch(importWorksheetsDS({
        orgAlias, id,
        values,
      }));
      history.push(
        projectViewUrl(orgAlias, id, PROJECT_TABS.WORKSHEETS),
      );
    } catch (err) {
      const errorData = err.errors;

      if (errorData._error) {
        toastr.error('Oh Snap!', errorData._error);
      }
    }
  }

  onStepBack(step) {
    const {
      match: { params: { orgAlias, id } }, history, location,
    } = this.props;
    const urlQuery = {};

    const {
      id: fileId, claimingAmount, currency, description,
      periodEnd, periodStart, summary, userId, user,
    } = queryString.parse(location.search);
    const { MAP_FIELDS, PREVIEW } = PROJECT_WORKSHEET_IMPORT_STEP;

    if (step === MAP_FIELDS) {
      Object.assign(urlQuery, {
        id: fileId,
      });
    }

    if (step === PREVIEW) {
      Object.assign(urlQuery, {
        id: fileId,
        claimingAmount, currency, description,
        periodEnd, periodStart, summary, userId, user,
      });
    }

    history.push({
      pathname: projectWorksheetImportUrl(
        orgAlias, id, step,
      ),
      search: queryString.stringify(urlQuery),
    });
  }

  async onStepChange(page, formValues = {}, isStepBack) {
    const { MAP_FIELDS, PREVIEW, DONE } = PROJECT_WORKSHEET_IMPORT_STEP;

    const step = Object.keys(PROJECT_WORKSHEET_IMPORT_STEP_PAGE).find(
      key => PROJECT_WORKSHEET_IMPORT_STEP_PAGE[key] === page,
    );

    if (isStepBack) {
      this.onStepBack(step);
    }

    if (!isStepBack) {
      if (page === PROJECT_WORKSHEET_IMPORT_STEP_PAGE[MAP_FIELDS]) {
        this.onSubmitMapFields(step, formValues);
      }

      if (page === PROJECT_WORKSHEET_IMPORT_STEP_PAGE[PREVIEW]) {
        this.onSubmitPreview(step, formValues);
      }

      if (page === PROJECT_WORKSHEET_IMPORT_STEP_PAGE[DONE]) {
        await this.onSubmit(formValues);
      }
    }
  }

  onCancel() {
    const { match: { params: { orgAlias, id } }, history } = this.props;
    history.push(
      projectViewUrl(orgAlias, id, PROJECT_TABS.DASHBOARD),
    );
  }

  getSubmissionStatus(formValues) {
    if (isEmpty(formValues.validWorksheets)) {
      return true;
    }

    return false;
  }

  validateDownloadTemplate(formValues) {
    if (isEmpty(formValues.downloaded_template)) {
      return {
        downloaded_template: 'Please download recent version of the template',
      };
    }
    return {};
  }

  validateUploadTemplate(formValues) {
    if (
      !formValues.attachments_metadata
        || isEmpty(JSON.parse(formValues.attachments_metadata))
    ) {
      return {
        attachments_metadata: 'Please upload template file',
      };
    }
    try {
      const metadata = JSON.parse(formValues.attachments_metadata);
      if (
        !Array.isArray(metadata)
        || metadata.length === 0
        || !/\.(csv|txt)$/.test(metadata[0].filename)
      ) {
        return { attachments_metadata: 'Please upload a .csv or .txt template file' };
      }
      // eslint-disable-next-line no-empty
    } catch (_) {
      return { attachments_metadata: 'Please upload a .csv or .txt template file' };
    }
    return {};
  }

  saveWizardState(values) {
    this.setState({
      initialValues: values,
    });
  }

  render() {
    const { match: { params } } = this.props;
    const { initialValues } = this.state;

    const WIZARD_STEPS = [
      {
        title: 'Download template',
        validate: this.validateDownloadTemplate,
        component: ProjectWorksheetImporterDownloadTemplate,
        step: PROJECT_WORKSHEET_IMPORT_STEP.DOWNLOAD,
      },

      {
        title: 'Upload template',
        validate: this.validateUploadTemplate,
        component: ProjectWorksheetImporterUploadTemplate,
        step: PROJECT_WORKSHEET_IMPORT_STEP.UPLOAD,
      },

      {
        title: 'Map fields',
        component: ProjectWorksheetImportMappingForm,
        step: PROJECT_WORKSHEET_IMPORT_STEP.MAP_FIELDS,
      },

      {
        title: 'Preview',
        component: ProjectWorksheetImportPreview,
        step: PROJECT_WORKSHEET_IMPORT_STEP.PREVIEW,
        nextBtnTitle: 'Import Worksheets',
        getSubmissionStatus: this.getSubmissionStatus,
      },

      {
        title: 'Done',
        component: ProjectWorksheetComplete,
        step: PROJECT_WORKSHEET_IMPORT_STEP.DONE,
      },
    ];

    return (
      <React.Fragment>
        <ProjectWorksheetImportHeader />

        <div className="page page--project import-worksheets-page">
          <div className="container">
            <Wizard
              defaultPage={PROJECT_WORKSHEET_IMPORT_STEP_PAGE[params.step]}
              saveWizardState={this.saveWizardState}
              lastStepIsLoader
              onStepChange={this.onStepChange}
              onCancel={this.onCancel}
              initialValues={initialValues}
            >
              {WIZARD_STEPS.map(({
                title, validate, component: Component, step,
                nextBtnTitle, getSubmissionStatus,
              }) => (
                <Wizard.Page
                  key={step}
                  validate={validate}
                  title={title}
                  nextBtnTitle={nextBtnTitle}
                  getSubmissionStatus={getSubmissionStatus}
                  component={Component}
                />
              ))}
            </Wizard>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectWorksheetImportView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectWorksheetImportViewConnected = connect(
  mapDispatchToProps,
)(ProjectWorksheetImportView);

export default withRouter(ProjectWorksheetImportViewConnected);
