import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { BS_STYLE, MIME_TYPES, MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import { uploaderExperiencePath } from 'core/urls';
import { profileSettingsUrl } from 'accounts/urls';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import SkillSelectField from 'core/assets/js/components/ReduxFormFields/SkillSelectField.jsx';
import FileUploaderField from 'core/assets/js/components/ReduxFormFields/FileUploaderField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const FORM_ID = 'profileExperienceForm';

function ProfileExperienceForm({
  activeOrg, history, onSubmit, isCurrentPosition, handleSubmit, submitting, userId,
}) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="rounded shadow-sm p-4 bg-white">
        <div className="page--settings__content clearfix">
          <div className="row">
            <div className="col-12">
              <Field
                name="company"
                type="text"
                component={InputField}
                label="Company name"
                required
              />
            </div>
            <div className="col-12 col-sm-6">
              <Field
                name="period_start"
                type="date"
                component={InputField}
                label="From"
                required
              />
            </div>
            <div className="col-12 col-sm-6">
              <Field
                name="period_end"
                type="date"
                component={InputField}
                label="To"
                disabled={isCurrentPosition}
                required={!isCurrentPosition}
              />
            </div>
            <div className="col-12 col-sm-6">
              <Field
                name="is_current_position"
                type="checkbox"
                component={InputField}
                label="This is a current position"
                placeholder=""
                className="mb-5"
              />
            </div>
            <div className="col-12">
              <Field
                name="description"
                type="textarea"
                component={InputField}
                label="Description"
              />
            </div>
            <div className="col-12">
              <Field
                name="attachments"
                type="text"
                component={FileUploaderField}
                maxFiles={MAX_UPLOAD_FILES}
                label="Attachments"
                acceptFiles={['image/*', ...MIME_TYPES.DOCUMENTS]}
                path={uploaderExperiencePath(userId)}
                invertedColors
              />
            </div>
            <div className="col-12">
              <Field
                name="skills"
                label="Skills"
                component={SkillSelectField}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-12 mt-4 text-right px-0 d-flex justify-content-end"
      >
        <TDButton
          type="submit"
          variant={BS_STYLE.DEFAULT}
          disabled={submitting}
          label="Cancel"
          onClick={() => (
            history.push(profileSettingsUrl(activeOrg.alias, PROFILE_SETTINGS_TABS.EXPERIENCE))
          )}
        />
        <TDButton
          type="submit"
          variant={BS_STYLE.PRIMARY}
          disabled={submitting}
          label="Save"
        />
      </div>
    </form>
  );
}


ProfileExperienceForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isCurrentPosition: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

ProfileExperienceForm.defaultProps = {
  error: '',
  isCurrentPosition: false,
};

const ProfileExperienceReduxForm = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
})(ProfileExperienceForm);

const valueSelector = formValueSelector(FORM_ID);

const mapStateToProps = (state) => {
  const userId = selectProfile(state).userId;
  return ({
    activeOrg: selectActiveOrg(state),
    isCurrentPosition: valueSelector(state, 'is_current_position'),
    userId,
  });
};


const ProfileExperienceReduxFormConnected = connect(
  mapStateToProps,
  null,
)(ProfileExperienceReduxForm);

export default withRouter(ProfileExperienceReduxFormConnected);
