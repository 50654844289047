import { listReplaceItemAC } from 'core/assets/js/ducks/list';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { cancelDocumentAssignmentApiUrl } from 'documents/urls';

export const cancelDocumentAssignmentDS = ({ // eslint-disable-line import/prefer-default-export
  authedAxios = null, componentName, documentAssignmentId, orgAlias,
}) => pushDataDS({
  authedAxios,
  pushApiUrl: cancelDocumentAssignmentApiUrl(orgAlias, documentAssignmentId),
  pushDataAC: responseData => [listReplaceItemAC(responseData, componentName)],
});
