import React from 'react';
import PropTypes from 'prop-types';

import { BS_TOOLTIP_PLACEMENT, USER_TYPE } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';


const EmploymentStatusIndicator = ({ isEmployee, userType }) => {
  /**
   * Show Employment Indicator only in case of:
   * a. Providers that are permanent employees or
   * b. Managers that are contractors. Other wise return null
   */
  if (
    (userType === USER_TYPE.PROVIDER && !isEmployee)
    || (userType !== USER_TYPE.PROVIDER && isEmployee)
  ) {
    return null;
  }

  return (
    <TDElementWithTooltip
      el={<span className="status-dot status-dot--permanent-employee status-dot--circle" />}
      placement={BS_TOOLTIP_PLACEMENT.TOP}
      tooltipMsg={userType === USER_TYPE.PROVIDER ? 'Permanent Employee' : 'Contractor'}
    />
  );
};

EmploymentStatusIndicator.propTypes = {
  isEmployee: PropTypes.bool,
  userType: PropTypes.oneOf(Object.values(USER_TYPE)),
};

EmploymentStatusIndicator.defaultProps = {
  isEmployee: false,
  userType: USER_TYPE.PROVIDER,
};

export default EmploymentStatusIndicator;
