import { SubmissionError } from 'redux-form';

import { timeOffListApiUrl, timeOffItemApiUrl } from 'accounts/urls';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';

export const createTimeOffDS = values => (dispatch) => {
  return (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.POST,
      pushApiUrl: timeOffListApiUrl(),
      values,
    }))
      .catch((data) => {
        throw new SubmissionError(data.errors);
      })
  );
};

export const updateTimeOffDS = values => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: timeOffItemApiUrl(values.id),
    values,
  }))
    .catch((data) => {
      throw new SubmissionError(data.errors);
    })
);

export const deleteTimeOffDS = id => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: timeOffItemApiUrl(id),
  }))
    .catch((data) => {
      throw new SubmissionError(data.errors);
    })
);
