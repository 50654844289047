import Big from 'big.js';
import { isEmpty } from 'lodash';
import ProcessingFeeAnalysis from 'finance/assets/js/lib/ProcessingFeeAnalysis';
import LicenceFeeAnalysis from 'finance/assets/js/lib/LicenceFeeAnalysis';
import SubscriptionFeeAnalysis from 'finance/assets/js/lib/SubscriptionFeeAnalysis';
import { removeNilValues } from 'core/assets/js/lib/utils';

Big.RM = 1;
Big.DP = 2;

class InvoiceFeeAnalysis {
  constructor(args) {
    if (!args || isEmpty(args)) {
      this.init();
    } else {
      this.init(args);
    }
  }

  init({ licenceFee, processingFee, subscriptionFee } = {}) {
    this.details = {
      licenceFeeAnalysis: licenceFee instanceof LicenceFeeAnalysis
        ? licenceFee
        : new LicenceFeeAnalysis(licenceFee),
      processingFeeAnalysis: processingFee instanceof ProcessingFeeAnalysis
        ? processingFee
        : new ProcessingFeeAnalysis(processingFee),
      subscriptionFeeAnalysis: subscriptionFee instanceof SubscriptionFeeAnalysis
        ? subscriptionFee
        : new SubscriptionFeeAnalysis(subscriptionFee),
    };
  }

  isEmpty() {
    const { licenceFeeAnalysis, processingFeeAnalysis, subscriptionFeeAnalysis } = this.details;
    return isEmpty(removeNilValues({
      processingFee: processingFeeAnalysis.serialize(),
      licenceFee: licenceFeeAnalysis.serialize(),
      subscriptionFee: subscriptionFeeAnalysis.serialize(),
    }));
  }

  hasEmptyLicenceFee() {
    const { licenceFeeAnalysis } = this.details;
    return licenceFeeAnalysis.isEmpty();
  }

  getLicenceProvidersFee() {
    const { licenceFeeAnalysis } = this.details;
    return licenceFeeAnalysis.getProvidersFee();
  }

  getLicenceManagersFee() {
    const { licenceFeeAnalysis } = this.details;
    return licenceFeeAnalysis.getManagersFee();
  }

  getLicenceBaseFee() {
    const { licenceFeeAnalysis } = this.details;
    return licenceFeeAnalysis.getBaseFee();
  }

  getProcessingFeeAnalysis() {
    const { processingFeeAnalysis } = this.details;
    return processingFeeAnalysis;
  }

  getLicenceFeeAnalysis() {
    const { licenceFeeAnalysis } = this.details;
    return licenceFeeAnalysis;
  }

  getSubscriptionFeeAnalysis() {
    const { subscriptionFeeAnalysis } = this.details;
    return subscriptionFeeAnalysis;
  }

  getTotal() {
    const {
      processingFeeAnalysis,
      licenceFeeAnalysis,
      subscriptionFeeAnalysis,
    } = this.details;
    return Big(processingFeeAnalysis.getTotal())
      .plus(subscriptionFeeAnalysis.getTotal())
      .plus(licenceFeeAnalysis.getTotal())
      .toFixed(2);
  }

  serialize() {
    const { licenceFeeAnalysis, processingFeeAnalysis, subscriptionFeeAnalysis } = this.details;
    if (this.isEmpty()) {
      return null;
    }
    return removeNilValues({
      processingFee: processingFeeAnalysis.serialize(),
      licenceFee: licenceFeeAnalysis.serialize(),
      subscriptionFee: subscriptionFeeAnalysis.serialize(),
      total: this.getTotal(),
    });
  }
}

export default InvoiceFeeAnalysis;
