import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import { ICON } from 'core/assets/js/constants';

const InvitePeopleComplete = ({ formValues, isFromPPH, peopleType }) => {
  const { emails } = formValues;
  const importingPeopleCount = isFromPPH ? 1 : JSON.parse(emails).length;
  return (
    <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
      <i className={ICON.IMPORT_FILE_DUOTONE} />
      <h2 data-testid="almost-done-message">Almost done!</h2>
      {importingPeopleCount === 0 ? (
        <p className="mb-0">
          <strong>
            We could not find any valid worksheets. Please try again with valid worksheets
          </strong>
        </p>
      ) : (
        <p data-testid="providers-being-invited" className="mb-0">
          <strong>
            {`${importingPeopleCount} ${peopleType} are being invited. Please wait…`}
          </strong>
        </p>
      )}
      { importingPeopleCount !== 0 && (
        <p>
          Once invitation is done, you will be redirected to your invitees.
        </p>
      )}
      <br />
    </div>
  );
};

InvitePeopleComplete.propTypes = {
  formValues: PropTypes.object.isRequired,
  isFromPPH: PropTypes.bool,
  peopleType: peopleTypeSpec.isRequired,
};

InvitePeopleComplete.defaultProps = {
  isFromPPH: false,
};

const mapStateToProps = (state, props) => {
  const { params } = props.match;

  return {
    peopleType: params?.peopleType,
  };
};

const InvitePeopleCompleteConnect = connect(
  mapStateToProps,
)(InvitePeopleComplete);

export default withRouter(InvitePeopleCompleteConnect);
