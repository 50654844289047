import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { financeEntityScheme } from 'finance/assets/js/lib/objectSpecs';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import BankAccountVariant from 'settings/assets/js/lib/BankAccountVariant';
import InvoiceTWBankDetails from 'finance/assets/js/components/invoicing/InvoiceTWBankDetails.jsx';
import FinancialAssociation from 'finance/assets/js/lib/FinancialAssociation';
import InvoicingSettings from 'finance/assets/js/lib/InvoicingSettings';

const InvoiceBankDetails = ({
  'data-testid': dataTestId,
  owner,
  system,
  invoicingSettings,
  bankAccount,
  showLabel,
  classes,
}) => {
  let bank;
  let hidePaymentDetails = false;

  if (bankAccount) {
    bank = new BankAccountVariant(bankAccount);
  } else {
    if (isEmpty(owner) || isEmpty(system) || isEmpty(invoicingSettings)) {
      return null;
    }
    const ownerFE = new FinancialEntity(owner);
    const systemFE = new FinancialEntity(system);
    const invoicingSettingsPOJO = new InvoicingSettings(invoicingSettings);
    const ownerSystemFA = new FinancialAssociation(ownerFE, systemFE, invoicingSettingsPOJO);
    hidePaymentDetails = ownerSystemFA.shouldHideInvoicePaymentDetails();
    bank = ownerFE.getBank();
  }
  if (!bank) {
    return null;
  }

  if (hidePaymentDetails) {
    return null;
  }

  const customReference = bank.getCustomReference();
  const paypalHandle = bank.getPaypalHandle();
  return (
    <div
      className={`${classes} bank-details`}
      data-testid={dataTestId}
      style={{ height: '170px', overflowY: 'auto' }}
    >
      {showLabel && (
        <h5>Electronic Payment Details</h5>
      )}
      {bank.isPaypalAccount() ? (
        <React.Fragment>
          <span className="bank-beneficiary">
            {`PayPal: ${paypalHandle}`}
          </span>
          {customReference && (
            <div className="custom-reference">
              {`Payee reference message: ${customReference}`}
            </div>
          )}
        </React.Fragment>
      ) : (
        <InvoiceTWBankDetails owner={owner} bankAccount={bankAccount} />
      )}
    </div>
  );
};

InvoiceBankDetails.propTypes = {
  'data-testid': PropTypes.string,
  owner: financeEntityScheme,
  system: financeEntityScheme,
  invoicingSettings: PropTypes.object,
  bankAccount: bankAccountSpec,
  showLabel: PropTypes.bool,
  classes: PropTypes.string,
};

InvoiceBankDetails.defaultProps = {
  'data-testid': '',
  owner: null,
  system: null,
  invoicingSettings: null,
  bankAccount: null,
  showLabel: true,
  classes: '',
};

export default InvoiceBankDetails;
