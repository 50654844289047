import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import NoDataPieChart from 'projects/assets/js/components/widgets/NoDataPieChart.jsx';

const ChartPie = ({ config }) => {
  const options = {
    ...config,
    chart: {
      type: 'pie',
      height: '100%',
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: null,
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
      },
    },
  };
  const hasData = options.series.length > 1;
  return (
    hasData ? (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    ) : (
      <NoDataPieChart
        currencySymbol={options.currency}
      />
    )
  );
};


ChartPie.propTypes = {
  config: PropTypes.object.isRequired,
};

export default ChartPie;
