/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { isSSR } from 'core/assets/js/config/checks';
import { REACT_SELECT_STYLES } from 'core/assets/js/constants';
import withField from 'core/assets/js/components/withField.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

const AsyncSelectionField = ({
  additionalError,
  className,
  components,
  extraConfiguration,
  disabled,
  input,
  isMultiple,
  isSearchable,
  label,
  loadOptions,
  meta: { error, pristine, submitError },
  onChange,
  placeholder,
  required,
  sublabel,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = !!error || !!submitError || showAdditionalError;
  const groupClassName = ['selection-field form-group'];
  if (hasError) {
    groupClassName.push('has-error');
  }

  if (disabled) {
    groupClassName.push('selection-field-disabled');
  }

  if (typeof className === 'string') {
    groupClassName.push(className);
  }

  const [selections, setSelections] = useState();

  const handleChange = (items) => {
    setSelections(items);
    input.onChange(items);
    onChange(items);
  };

  useEffect(() => {
    setSelections(input.value);
  }, [input.value]);

  return (
    <div className={groupClassName.join(' ')}>
      {label && (
        <TDLabel
          name={input.name}
          label={label}
          required={required}
          sublabel={sublabel}
        />
      )}

      {showAdditionalError && <span className="help-block">{additionalError}</span>}

      <AsyncSelect
        {...input}
        classNamePrefix="react-select"
        disabled={disabled}
        formatCreateLabel={value => `Other: ${value}`}
        id={`${input.name}-dropdown`}
        isClearable={isMultiple || !required}
        isMulti={isMultiple}
        isSearchable={isSearchable}
        loadOptions={loadOptions}
        menuPlacement="auto"
        menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
        onChange={handleChange}
        styles={REACT_SELECT_STYLES}
        value={selections}
        placeholder={placeholder}
        components={{ ...components }}
        {...extraConfiguration}
      />

      {hasError && <span className="help-block mt-2">{error || submitError}</span>}
    </div>
  );
};

AsyncSelectionField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  components: PropTypes.object,
  extraConfiguration: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  input: PropTypes.object.isRequired,
  isMultiple: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  loadOptions: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.string,
    pristine: true,
    submitError: null,
  }),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
};

AsyncSelectionField.defaultProps = {
  additionalError: '',
  className: null,
  disabled: false,
  isMultiple: false,
  isSearchable: true,
  label: null,
  loadOptions: null,
  meta: {
    error: null,
    pristine: true,
    submitError: null,
  },
  onChange: () => null,
  placeholder: null,
  required: false,
  sublabel: null,
  components: {},
  extraConfiguration: {},
};

export default withField(AsyncSelectionField);
