/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';

import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import { ORG_SETTINGS_FINANCE_TABS } from 'settings/assets/js/constants';
import { BS_STYLE } from 'core/assets/js/constants';
import { RATE_UNIT_SELECT_FILTERED } from 'rates/assets/js/constants';
import { orgSettingsFinanceUrl } from 'settings/urls';
import { uploaderOrgPath } from 'core/urls';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import GeoSuggestField from 'core/assets/js/components/FinalFormFields/GeoSuggestField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import SalesTaxFields, { SalesTaxSwitch } from 'settings/assets/js/components/SalesTaxFields.jsx';
import { getInfoFromFormValues } from 'settings/assets/js/lib/sales_tax_utils';

const OrganizationForm = ({
  organization, onSubmit, initialValues, dispatch,
}) => (
  <Form
    onSubmit={values => onSubmit(values, dispatch)}
    initialValues={initialValues}
    render={({ handleSubmit: _handleSubmit, submitting, pristine, values }) => {
      const salesTaxInfo = getInfoFromFormValues(values);

      return (
        <form className="org-form">
          <Card>
            <Card.Body>
              <FileUploaderField
                name="logo_metadata"
                imgWidth={390}
                imgHeight={150}
                label="Logo"
                maxFiles={1}
                softDelete
                path={uploaderOrgPath(organization.id)}
              />
              <div>
                <TextInputField name="name" label="Name" placeholder="" required />
                <GeoSuggestField name="address" label="Address" placeholder="" />
                <div className="row">
                  <div className="col-12">
                    <SalesTaxSwitch submitting={submitting} />
                  </div>
                </div>
                <SalesTaxFields
                  submitting={submitting}
                  {...salesTaxInfo}
                />

                <TextInputField name="registration_number" label="Registration Number" />
                <TextInputField name="website" label="Website" placeholder="" />
                <SelectField name="default_rate_unit" optionsMapping={RATE_UNIT_SELECT_FILTERED} label="Default rate" required disabled />
              </div>
              <div>
                <p>
                  All invoices will be addressed to the above company details. Billing settings can be altered in the
                  {' '}
                  <Link to={orgSettingsFinanceUrl(organization.alias, ORG_SETTINGS_FINANCE_TABS.INVOICES)}>Payments section</Link>
                  .
                </p>
              </div>
            </Card.Body>
          </Card>
          <div className="text-right mt-4">
            <TDButton
              className={pristine ? 'btn--pristine' : 'btn--dirty'}
              type="submit"
              onClick={_handleSubmit}
              variant={BS_STYLE.PRIMARY}
              disabled={submitting}
              label="Update"
            />
          </div>
        </form>
      );
    }}
  />
);

OrganizationForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organization: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
};

OrganizationForm.defaultProps = {
  organization: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const connectedOrganizationForm = connect(null, mapDispatchToProps)(OrganizationForm);

export default connectedOrganizationForm;
