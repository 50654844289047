import React from 'react';
import PropTypes from 'prop-types';

import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import RateField from 'rates/assets/js/components/finalFormFields/RateField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';

const RateAdjustmentModalForm = ({ canInitiateAndApprove, currencySymbol, rateUnit }) => {
  return (
    <div className="rate-adj-form">
      <div className="row">
        <div className="col-12">
          <RateField
            currencySymbol={currencySymbol}
            name="amount"
            unit={rateUnit}
          />
          <TextAreaField
            className="textarea-half"
            label="Message"
            name="message"
            sublabel="Describe why you'd like to change the rate"
          />
          {canInitiateAndApprove && (
            <RadioField
              name="approve"
              label="Immediately approve the rate change"
              groupClassName="mt-5"
              options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
              required
            />
          )}
        </div>

      </div>
    </div>
  );
};

RateAdjustmentModalForm.propTypes = {
  canInitiateAndApprove: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string,
  rateUnit: PropTypes.number,
};

RateAdjustmentModalForm.defaultProps = {
  currencySymbol: '',
  rateUnit: null,
};
export default RateAdjustmentModalForm;
