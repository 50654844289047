import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { FieldArray } from 'react-final-form-arrays';

import SkillSelectField from 'core/assets/js/components/FinalFormFields/SkillSelectField.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { uploaderProjectTasksPath } from 'core/urls';
import { customFieldSpec, customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { BS_STYLE, BS_SIZE, MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import CustomFieldsUpdater from 'interviews/assets/js/components/CustomFieldsUpdater.jsx';
import TaskChecklistFieldset from 'projects/assets/js/components/TaskChecklistFieldset.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import TagsInputField from 'core/assets/js/components/FinalFormFields/TagsInputField.jsx';

const FORM_ID = 'project-update-task';


class ProjectTaskForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleDiscardTask = this.handleDiscardTask.bind(this);
  }

  async handleDiscardTask() {
    const { onDiscard } = this.props;
    onDiscard();
  }

  render() {
    const {
      allowTemplateSelection,
      customFields,
      customFieldTemplates,
      formHeading,
      initialValues,
      initialValues: { items: itemInitialValues },
      match: { params: { taskId } },
      onTaskSubmitted,
      org,
      org: { id: orgId },
      project: { id: projectId },
    } = this.props;

    const advancedOptions = (
      <React.Fragment>
        <TagsInputField
          name="tags"
          label="Extra tags"
          size="extra-large"
        />
      </React.Fragment>
    );

    return (
      <div>
        <Form
          name={FORM_ID}
          onSubmit={onTaskSubmitted}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          keepDirtyOnReinitialize // important: mandatory templates will not work properly otherwise
          render={({
            form, handleSubmit, submitting, pristine, values: { starts_on: startsOn },
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className="project-task__details">
                <Card.Header>
                  {formHeading}
                </Card.Header>
                <Card.Body>
                  <TextInputField
                    name="title"
                    type="text"
                    label="Task title"
                    placeholder="e.g Landing page design"
                    required
                  />

                  <TextAreaField
                    name="description"
                    label="Description"
                    placeholder="Provide a brief for the task explaining what needs to be done..."
                    mdEditorEnabled
                    required
                  />

                  <FileUploaderField
                    name="attachments"
                    label="Attachments"
                    maxFiles={MAX_UPLOAD_FILES}
                    path={uploaderProjectTasksPath(orgId, projectId)}
                    acceptAll
                  />

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <DatePickerField
                        name="starts_on"
                        label="Starts On"
                        placeholder="Choose date"
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <DatePickerField
                        disableBeforeDate={startsOn ? moment(startsOn) : null}
                        label="Deadline"
                        name="deadline"
                        placeholder="Choose date"
                        required={org.task_deadline_mandatory}
                      />
                    </div>
                  </div>

                  <FieldArray
                    name="items"
                  >
                    {({ fields, meta }) => (
                      <TaskChecklistFieldset
                        label="Checklist"
                        itemInitialValues={itemInitialValues}
                        fields={fields}
                        meta={meta}
                      />
                    )}
                  </FieldArray>

                  <SkillSelectField
                    label="Skills"
                    memberSkillsOnly={false}
                    modalHeading="Task skills"
                    name="skills"
                    sublabel={([
                      'Choose which skills you can easily filter providers by, when adding them ',
                      'to this task',
                    ].join(''))}
                  />

                  {/*
                  we don't allow the syncing features because the task is a binding contract
                  */}
                  <CustomFieldsUpdater
                    form={form}
                    entityId={taskId}
                    templates={customFieldTemplates}
                    entityType={FIELD_ENTITY_TYPE.TASK}
                    allowTemplateSelection={allowTemplateSelection}
                    initialCustomFields={customFields}
                    templateFieldLabel="Task template"
                    enableSync={false}
                    templateFieldSublabel={(
                      <p className="text-warning">
                        By selecting one or more task templates and creating a task,
                        the relevant custom fields freeze on it.
                        You will not be able to edit a task and choose other task templates.
                        <br />
                        Before you create a new task, please make sure that
                        you have selected the right task templates.
                      </p>
                    )}
                  />

                  <AreaCollapsible
                    fieldSet
                    contentChildren={advancedOptions}
                    headingChildren={<span>Advanced Options</span>}
                  />
                </Card.Body>
              </Card>

              <div className="mt-4 mb-8 w-100 d-flex justify-content-end">
                {pristine && (
                  <TDButton
                    type="button"
                    variant={BS_STYLE.DEFAULT}
                    bsSize={BS_SIZE.LARGE}
                    onClick={this.handleDiscardTask}
                    label="Cancel"
                  />
                )}
                {!pristine && (
                  <ConfirmationButton
                    buttonStyle={BS_STYLE.DEFAULT}
                    buttonSize={BS_SIZE.LARGE}
                    buttonLabel="Cancel"
                    modalProps={{
                      heading: 'Are you sure you want to discard changes to this task?',
                      body: (
                        <React.Fragment>
                          <p>
                            There are unsaved changes in your Task, if you discard now,
                            you will lose those changes.
                          </p>
                          <p>
                            Are you sure you want to continue?
                          </p>
                        </React.Fragment>
                      ),
                      confirmLabel: 'Yes',
                      cancelLabel: 'No',
                      onConfirm: this.handleDiscardTask,
                    }}
                  />
                )}

                <TDButton
                  data-testid="project-task-form-save-task-button"
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                  bsSize={BS_SIZE.LARGE}
                  disabled={submitting}
                  label="Save task"
                />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

ProjectTaskForm.propTypes = {
  project: projectSpec.isRequired,
  org: orgSpec.isRequired,
  onTaskSubmitted: PropTypes.func,
  match: routerMatchContentsSpec.isRequired,
  onDiscard: PropTypes.func,
  initialValues: PropTypes.object,
  formHeading: PropTypes.string,
  allowTemplateSelection: PropTypes.bool,
  customFields: PropTypes.arrayOf(customFieldSpec),
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
};

ProjectTaskForm.defaultProps = {
  formHeading: 'Task Details',
  customFields: [],
  customFieldTemplates: [],
  allowTemplateSelection: true,
  initialValues: {},
  onTaskSubmitted: () => {},
  onDiscard: () => {},
};

const mapStateToProps = state => ({
  org: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectTaskFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTaskForm);

export default withRouter(ProjectTaskFormConnected);
