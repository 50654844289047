import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { ORG_SKILL_DIRECTORY_MODAL_ID } from 'settings/assets/js/components/OrgSkillDirectoryModalForm.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { ICON, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';

const SkillDirectoryCard = ({ item, skillsCount }) => {
  const dispatch = useDispatch();
  const creatorFullName = item?.creator?.profile?.name;
  const creationDate = moment(item.createdAt).format(DATE_FORMAT_DEFAULT);
  return (
    <Card className="skill-directory-card border-0">
      <Card.Body>
        <div className="row">
          <div className="col-12 col-xl-4">
            <h1 className="mb-3 mt-0 d-inline-flex align-items-center">
              {item.name}
              <a
                className="rename-button ml-3"
                onClick={() => {
                  dispatch(modalOpenAC(ORG_SKILL_DIRECTORY_MODAL_ID, item));
                }}
              >
                <i className={ICON.EDIT} />
              </a>
            </h1>
          </div>
          <div className="col-12 col-xl-4">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex border-bottom justify-content-between align-items-center">
                <span className="mr-5">Created by</span>
                {creatorFullName}
              </li>
              <li className="list-group-item d-flex border-bottom justify-content-between align-items-center">
                <span className="mr-5">
                  Date
                </span>
                {creationDate}
              </li>
            </ul>
          </div>

          <div className="col-12 col-xl-4">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex border-bottom justify-content-between align-items-center">
                <span className="mr-5">
                  Skills
                </span>
                {skillsCount}
              </li>
            </ul>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

SkillDirectoryCard.propTypes = {
  skillsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  item: PropTypes.shape({
    createdAt: PropTypes.string,
    description: PropTypes.string,
    creator: PropTypes.shape({
      profile: PropTypes.object,
    }),
    name: PropTypes.string,
  }),
};

SkillDirectoryCard.defaultProps = {
  item: {},
  skillsCount: '-',
};

export default SkillDirectoryCard;
