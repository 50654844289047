import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';
import {
  profileExperienceCreateApiUrl,
  profileExperienceDeleteApiUrl,
  profileExperienceEditApiUrl,
} from 'accounts/urls';
import { listAppendItemAC, listRemoveItemAC } from 'core/assets/js/ducks/list';
import { viewUpdateAC } from 'core/assets/js/ducks/view';

const createExperienceDS = (values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: profileExperienceCreateApiUrl(),
    pushDataAC: responseData => listAppendItemAC(responseData.experience, componentName),
    values,
  })
);

const editExperienceDS = (experienceId, values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: profileExperienceEditApiUrl(experienceId),
    pushDataAC: responseData => viewUpdateAC(responseData, componentName),
    values,
  })
);

const deleteExperienceDS = (experienceId, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: profileExperienceDeleteApiUrl(experienceId),
    pushDataAC: responseData => (
      listRemoveItemAC(responseData.experienceId, componentName)
    ),
  })
);

export {
  createExperienceDS,
  deleteExperienceDS,
  editExperienceDS,
};
