import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import pluralize from 'pluralize';

import { orgInvitationTemplateEditUrl } from 'settings/urls';
import { invitationTemplateSpec } from 'settings/assets/js/lib/objectSpecs';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';

const InvitationTemplateCardItem = ({ item, onDelete, history, orgAlias }) => {
  const editUrl = orgInvitationTemplateEditUrl(orgAlias, item.id);
  const { title, content } = item;
  const wordsCount = content.split(' ').length;
  return (
    <div className="timeOff-list-item">
      <div className="row">
        <div className="col-9">
          <h3 className="timeOff-list-item__period">
            <Link
              className="text-dark"
              to={editUrl}
            >
              {title}
            </Link>
          </h3>
          <div className="pl-0">
            <span className="timeOff-list-item__reason">
              {pluralize('word', wordsCount, true)}
            </span>
          </div>
        </div>

        <div className="col-3 d-flex align-items-center justify-content-end">
          <TDDropButton>
            <Dropdown.Item
              eventKey="delete"
              onClick={() => history.push(editUrl)}
            >
              Edit
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="delete"
              onClick={() => onDelete(item.id)}
            >
              Delete
            </Dropdown.Item>
          </TDDropButton>
        </div>
      </div>
    </div>
  );
};

InvitationTemplateCardItem.propTypes = {
  item: invitationTemplateSpec.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

export default withRouter(InvitationTemplateCardItem);
