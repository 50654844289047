import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from 'core/assets/js/components/Breadcrumbs.jsx';

/**
 * Content Heading of the default layout
 *
 * Displays a smart breadcrumbs component with an optional CTA button
 * @param {Object} props
 * @param {Object<Array>} props.breadcrumbs
 * @param {Object<Array>} props.ctaButtonItems
 * @param {Any} props.children
 * @param {Object} props.ctaComponent
 *
 * @returns {JSX.Element}
 */
const ContentHeader = ({
  backButtonText, backUrl, breadcrumbs, ctaButtonItems, children, ctaComponent,
}) => (
  <div className="content-header__wrapper">
    <div
      className={[
        'container content-header',
        breadcrumbs ? 'content-header--with-breadcrumbs' : '',
      ].join(' ')}
    >
      <div className="d-flex align-items-center">
        { breadcrumbs && (
          <Breadcrumbs
            backButtonText={backButtonText}
            backUrl={backUrl}
            ctaButtonItems={ctaButtonItems}
            ctaComponent={ctaComponent}
            items={breadcrumbs}
          />
        )}
      </div>
      { children }
    </div>
  </div>
);

ContentHeader.propTypes = {
  backButtonText: PropTypes.string,
  backUrl: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
  ctaComponent: PropTypes.node,
  ctaButtonItems: PropTypes.array,
};

ContentHeader.defaultProps = {
  backButtonText: 'Back',
  backUrl: null,
  breadcrumbs: null,
  ctaComponent: null,
  ctaButtonItems: null,
  children: null,
};

export default ContentHeader;
