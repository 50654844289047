import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { MODAL_SIZES } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { createContractorDS, createSystemOrgDS } from 'integrations/assets/js/data-services/list';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

export const MODAL_ID = 'create-supplier-modal-id';

const IntegrationCreateSupplierModal = ({
  createSupplierModalPayload,
  dispatch,
  integration,
  isCreateSupplierModalOpen,
  match: { params: { orgAlias, integrationId } },
}) => (
  <ModalConfirm
    data-testid="integration-supplier-mapping-create-supplier-modal"
    heading={`Create supplier in ${integration.name}`}
    confirmLabel="Create supplier"
    open={isCreateSupplierModalOpen}
    onClose={() => dispatch(modalCloseAC())}
    onConfirm={async () => {
      const { componentName, local: { id, name }, systemOrg } = createSupplierModalPayload;
      try {
        const values = { [systemOrg ? 'invoicingEntityId' : 'userCardId']: id };
        const func = systemOrg ? createSystemOrgDS : createContractorDS;
        const response = await dispatch(func({ componentName, integrationId, orgAlias, values }));
        toastr.success(
          'Well Done!',
          response.remoteId
            ? `"${name}" supplier successfully created and mapped`
            : `Request to create "${name}" supplier sent, we'll try to auto-map when complete`,
        );
      } catch (error) {
        toastr.error('Oh Snap!', error._error || error.message);
      }
    }}
    size={MODAL_SIZES.LARGE}
  >
    <p>
      {'This will create a new supplier record in '}
      <b>{integration.name}</b>
      , with the following details:
    </p>
    <ul className="bg-gray p-5 mb-5">
      <li>{`Supplier name: ${createSupplierModalPayload?.local?.name}`}</li>
      <li>{`Contact name: ${createSupplierModalPayload?.local?.name}`}</li>
      <li>{`Email: ${createSupplierModalPayload?.local?.email}`}</li>
      <li>{`Address: ${createSupplierModalPayload?.local?.address || ''}`}</li>
      <li>
        Registration number:
        {` ${createSupplierModalPayload?.local?.registrationNumber || ''}`}
      </li>
      <li>{`Tax number: ${createSupplierModalPayload?.local?.taxNumber || ''}`}</li>
      <li>{`Default currency: ${createSupplierModalPayload?.local?.currency}`}</li>
    </ul>
    <TDSystemMessage title="What happens next?">
      When we have successfully synchronised the new supplier record back to TalentDesk,
      {` you will be able to map it to "${createSupplierModalPayload?.local?.name}".`}
    </TDSystemMessage>
  </ModalConfirm>
);

IntegrationCreateSupplierModal.propTypes = {
  createSupplierModalPayload: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  integration: integrationSpec.isRequired,
  isCreateSupplierModalOpen: PropTypes.bool,
  match: routerMatchSpec.isRequired,
};

IntegrationCreateSupplierModal.defaultProps = {
  createSupplierModalPayload: {},
  isCreateSupplierModalOpen: false,
};

const mapStateToProps = state => ({
  createSupplierModalPayload: getModalPayload(state, MODAL_ID) || {},
  isCreateSupplierModalOpen: getIsModalOpen(state, MODAL_ID),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationCreateSupplierModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationCreateSupplierModal);

export default withRouter(IntegrationCreateSupplierModalConnected);
