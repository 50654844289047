import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { FIELD_ENTITY_TYPE, ENTITY_TYPE_LABEL } from 'interviews/assets/js/constants';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';


const searchSpec = {
  searchTerm: {
    placeholder: 'Search',
    paramName: 'kw',
    component: null,
  },
  filters: [
    {
      label: 'Template type',
      paramName: 'templateType',
      fieldComponent: SelectableListFilterField,
      options: Object.keys(FIELD_ENTITY_TYPE)
        // Omit onboarding form templates
        .filter(key => FIELD_ENTITY_TYPE[key] !== FIELD_ENTITY_TYPE.ONBOARDING_FORM)
        .map(templateType => ({
          text: ENTITY_TYPE_LABEL[FIELD_ENTITY_TYPE[templateType]],
          value: FIELD_ENTITY_TYPE[templateType],
        })),
    },
  ],
};

class CustomFieldTemplatesSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;

    return queryString.parse(location.search);
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;

    return (
      <SearchFinalForm
        initialValues={this.getInitialValues()}
        searchSpec={searchSpec}
        onFiltersToggle={onFiltersToggle}
        filtersOpen={filtersOpen}
      />
    );
  }
}

CustomFieldTemplatesSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

const CustomFieldTemplatesSearchConnect = connect(
)(CustomFieldTemplatesSearch);

export default withRouter(CustomFieldTemplatesSearchConnect);
