import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { isObject, isString } from 'lodash';
import { withRouter } from 'react-router-dom';
import { ButtonGroup, Dropdown, Button, Popover, Overlay } from 'react-bootstrap';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDRadio from 'core/assets/js/components/TDRadio.jsx';
import { isReactTest } from 'core/assets/js/config/checks';


const DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const TDOverlay = ({
  children,
  ...props
}) => (
  <React.Fragment>
    {isReactTest && children}
    {!isReactTest && (
      <Overlay
        {...props}
      >
        {children}
      </Overlay>
    )}
  </React.Fragment>
);
TDOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
const OrderingDropDown = ({ orderingOptions, onOrderingChange, defaultOrdering, location }) => {
  const qs = queryString.parse(location.search);
  let ordering = defaultOrdering;

  if (isString(qs.ordering)) {
    ordering = JSON.parse(qs.ordering);
  } else if (isObject(qs.ordering)) {
    ordering = qs.ordering;
  }

  const [sortBy, setSortBy] = useState(ordering.sortBy);
  const [direction, setDirection] = useState(ordering.direction);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleChange = (od) => {
    onOrderingChange(od);
  };

  const handleChangeDirection = (option) => {
    handleChange({
      direction: option.filterDefaultDirection || direction,
      sortBy: option.value,
    });
    setSortBy(option.value);
    setShow(false);
  };

  return (
    <React.Fragment>
      <Dropdown
        as={ButtonGroup}
        className={`sort-by-dropdown mr-3 d-none d-md-flex ${show ? 'sort-by-dropdown--active' : ''}`}
      >
        <Button
          className="px-4"
          onClick={() => {
            const newValue = direction === DIRECTION.DESC ? DIRECTION.ASC : DIRECTION.DESC;
            setDirection(newValue);
            handleChange({ direction: newValue, sortBy });
          }}
          variant={BS_STYLE.DEFAULT}
        >
          <i className={direction === DIRECTION.DESC ? ICON.SORTING_DESC : ICON.SORTING_ASC} />
        </Button>

        <Dropdown.Toggle
          className="px-4"
          split
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target);
          }}
          variant={BS_STYLE.DEFAULT}
        >
          <span className="d-none d-sm-inline">
            Sort by
            {' '}
          </span>
          { sortBy && (
            <strong>
              {orderingOptions.find(opt => opt.value === sortBy)?.text}
              {' '}
            </strong>
          )}
          <i className={ICON.CHEVRON_DOWN} />
        </Dropdown.Toggle>

      </Dropdown>


      <TDOverlay
        show={show}
        target={target}
        placement="bottom"
        rootClose
        // onEnter={}
        onHide={() => setShow(false)}
      >
        <Popover
          className="direction-popover"
          id="direction-popover"
        >
          <div className="search-filter-item__header">
            <a className="close" onClick={() => setShow(false)}>
              <i
                className={`float-right ${ICON.CROSS}`}
              />
            </a>
            Sort By
          </div>

          <div className="search-filter-item__body">
            { orderingOptions && orderingOptions.map(option => (
              <div
                className="sort-by-item"
                key={`sortBy-${option.value}`}
                onClick={() => handleChangeDirection(option)}
              >
                <TDRadio
                  className="ml-0"
                  selected={sortBy === option.value}
                  label={option.text}
                />
              </div>
            ))}
          </div>
        </Popover>
      </TDOverlay>
    </React.Fragment>
  );
};

OrderingDropDown.propTypes = {
  defaultOrdering: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onOrderingChange: PropTypes.func.isRequired,
  orderingOptions: PropTypes.array.isRequired,
};

export default withRouter(OrderingDropDown);
