import React from 'react';
import { USER_TYPE } from 'core/assets/js/constants';
import AllowAccess from './AllowAccess.jsx';


/**
 * Wrapper that renders its children only when the user is a manager
 *
 */
const AllowAccessToManager = props => (
  <AllowAccess
    oneOf={[USER_TYPE.FIN_CONTROLLER, USER_TYPE.ORG_CREATOR, USER_TYPE.MANAGER]}
    {...props}
  />
);

export default AllowAccessToManager;
