import { LAYOUTS } from 'core/assets/js/constants';
import ImportCreateView from 'importer/assets/js/ImportCreateView.jsx';
import ImportsView from 'importer/assets/js/ImportsView.jsx';
import ImportView from 'importer/assets/js/ImportView.jsx';
import { importCreateUrl, importsListUrl, importViewUrl } from 'importer/urls';

const routesConfig = [
  {
    component: ImportsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: importsListUrl(':orgAlias'),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
  {
    component: ImportCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: importCreateUrl(':orgAlias'),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
  {
    component: ImportView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: importViewUrl(':orgAlias', ':id', ':tab?'),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
];

export default routesConfig;
