import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import {
  addBackUrlIfLocationContains, financeProFormaInvoiceViewUrl, financeProFormaInvoiceEditUrl,
} from 'finance/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const ProFormaInvoiceItemActions = ({
  history, onCancelClick, orgAlias, pathname, serviceOrder,
}) => {
  const showEditButton = serviceOrder?.allowedActions?.canBeEdited;
  const showCancelButton = serviceOrder?.allowedActions?.canBeCancelled;
  return (
    <TDDropButton data-testid="pro-forma-invoices-table-actions" stopPropagation>
      <Dropdown.Item
        eventKey="view-serviceOrder"
        data-testid="view-serviceOrder"
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            addBackUrlIfLocationContains(
              financeProFormaInvoiceViewUrl(orgAlias, serviceOrder.id),
              history.location.pathname,
              '/projects/',
            ),
          );
        }}
      >
        View Proforma Invoice
      </Dropdown.Item>

      { showEditButton && (
        <Dropdown.Item
          eventKey="edit-pro-forma-invoice"
          data-testid="edit-pro-forma-invoice"
          onClick={(e) => {
            e.stopPropagation();
            history.push(financeProFormaInvoiceEditUrl(orgAlias, serviceOrder.id, pathname));
          }}
        >
          <span>Edit Proforma Invoice</span>
        </Dropdown.Item>
      )}

      { showCancelButton && (
        <Dropdown.Item
          eventKey="cancel-pro-forma-invoice"
          data-testid="cancel-pro-forma-invoice"
          onClick={onCancelClick}
        >
          <span className="text-secondary">Cancel Proforma Invoice</span>
        </Dropdown.Item>
      )}
    </TDDropButton>
  );
};

ProFormaInvoiceItemActions.propTypes = {
  history: routerHistorySpec.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
};

ProFormaInvoiceItemActions.defaultProps = {
  pathname: null,
};

export default ProFormaInvoiceItemActions;
