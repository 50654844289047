import { FORM_ERROR } from 'final-form';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import axios from 'core/assets/js/lib/tdAxios';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { INVOICING_MODE } from 'finance/assets/js/constants';
import { FIELDS_PER_SETTINGS_SECTION, SETTINGS_SECTION } from 'integrations/assets/js/constants';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import IntegrationCreateSupplierModal from 'integrations/assets/js/components/IntegrationCreateSupplierModal.jsx';
import IntegrationSetupAccountMapping from 'integrations/assets/js/components/setup/IntegrationSetupAccountMapping.jsx';
import IntegrationSetupContractorsMapping from 'integrations/assets/js/components/setup/IntegrationSetupContractorsMapping.jsx';
import IntegrationSetupGeneralSettings from 'integrations/assets/js/components/setup/IntegrationSetupGeneralSettings.jsx';
import IntegrationSetupInvoiceMapping from 'integrations/assets/js/components/setup/IntegrationSetupInvoiceMapping.jsx';
import IntegrationSetupTalentDeskMapping from 'integrations/assets/js/components/setup/IntegrationSetupTalentDeskMapping.jsx';
import { completeSetupDS, fetchInvoiceSettingsDS } from 'integrations/assets/js/data-services/view';
import { taxRateRegExp } from 'integrations/assets/js/helpers';
import {
  accountingInvoiceSettingsApiUrl,
  accountingSupplierTalentDeskMappingsApiUrl,
  integrationUpdateSettingsApiUrl,
} from 'integrations/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const IntegrationSetup = ({ activeOrg, dispatch, integration, parentComponentName, settings }) => {
  const componentName = IntegrationSetup.GetComponentName();
  const { id: integrationId, syncInvoiceStatus, syncType, syncStartDate } = integration;
  const initialValues = { ...settings, startDate: syncStartDate, syncInvoiceStatus, syncType };
  const { alias: orgAlias } = activeOrg;
  return (
    <TDApiConnected
      duck="view"
      fetchData={({ authedAxios }) => dispatch(fetchInvoiceSettingsDS({
        authedAxios, componentName, integrationId, orgAlias,
      }))}
      skeletonComponent={() => null}
      storeKey={componentName}
    >
      <Wizard
        className="integration-setup"
        extraContent={<IntegrationCreateSupplierModal integration={integration} />}
        initialValues={initialValues}
        onSubmit={async formValues => {
          try {
            const settingsValues = pick(formValues, 'startDate', 'syncInvoiceStatus', 'syncType');
            await axios.put(
              integrationUpdateSettingsApiUrl(orgAlias, integrationId), settingsValues,
            );
            await dispatch(completeSetupDS({
              componentName: parentComponentName, integrationId, orgAlias,
            }));
            return null;
          } catch (error) {
            return { [FORM_ERROR]: error._error || error.message };
          }
        }}
        submitBtnTitle="Finish setup"
      >
        <Wizard.Page
          beforePageChange={async () => {
            try {
              const { data: mappings } = await axios.get(
                accountingSupplierTalentDeskMappingsApiUrl(orgAlias, integrationId),
              );
              if (mappings.some(mapping => !mapping.remoteId)) {
                return {
                  [FORM_ERROR]: 'Please map each TalentDesk company to one of your suppliers',
                };
              }
              return null;
            } catch (error) {
              return { [FORM_ERROR]: error._error || error.message };
            }
          }}
          component={IntegrationSetupTalentDeskMapping}
          componentProps={{ integration }}
          title="Map TalentDesk companies"
        />
        {activeOrg.invoicing_mode !== INVOICING_MODE.VIA && (
          <Wizard.Page
            beforePageChange={async () => {
              // TODO check all contractors are mapped
              return { [FORM_ERROR]: 'Please map your contractors, to supplier records' };
            }}
            component={IntegrationSetupContractorsMapping}
            componentProps={{ integration }}
            title="Map contractors"
          />
        )}
        <Wizard.Page
          beforePageChange={async formValues => {
            const values = pick(
              formValues,
              [
                ...FIELDS_PER_SETTINGS_SECTION[SETTINGS_SECTION.ACCOUNT],
                ...Object.keys(formValues).filter(name => taxRateRegExp.test(name)),
              ],
            );
            try {
              await axios.put(
                accountingInvoiceSettingsApiUrl(orgAlias, integrationId), values,
              );
              return null;
            } catch (error) {
              return { [FORM_ERROR]: error._error || error.message };
            }
          }}
          component={IntegrationSetupAccountMapping}
          title="Map accounts"
        />
        <Wizard.Page
          beforePageChange={async formValues => {
            const values = pick(
              formValues,
              [
                SETTINGS_SECTION.INVOICE,
                SETTINGS_SECTION.LINE_ITEMS,
                SETTINGS_SECTION.TRACKING_CATEGORIES,
              ].reduce(
                (acc, section) => {
                  acc.push(...FIELDS_PER_SETTINGS_SECTION[section]);
                  return acc;
                },
                [],
              ),
            );
            try {
              await axios.put(
                accountingInvoiceSettingsApiUrl(orgAlias, integrationId), values,
              );
              return null;
            } catch (error) {
              return { [FORM_ERROR]: error._error || error.message };
            }
          }}
          component={IntegrationSetupInvoiceMapping}
          componentProps={{ integration }}
          title="Map invoices"
        />
        <Wizard.Page component={IntegrationSetupGeneralSettings} title="Settings" />
      </Wizard>
    </TDApiConnected>
  );
};

IntegrationSetup.GetComponentName = () => 'IntegrationSetup';

IntegrationSetup.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  integration: integrationSpec.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  settings: PropTypes.object,
};

IntegrationSetup.defaultProps = {
  settings: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  settings: getViewState(state, IntegrationSetup.GetComponentName()).item.items,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const IntegrationSetupConnected = connect(mapStateToProps, mapDispatchToProps)(IntegrationSetup);

export default IntegrationSetupConnected;
