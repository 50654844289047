import React from 'react';
import PropTypes from 'prop-types';

import ScrollToTopOnMount from 'core/assets/js/components/ScrollToTopOnMount.jsx';
import ReportViewHeaderActions from 'finance/assets/js/components/ReportViewHeaderActions.jsx';
import ReportViewBreadcrumbs from 'finance/assets/js/components/ReportViewBreadcrumbs.jsx';
import StatusReason from 'finance/assets/js/components/StatusReason.jsx';

const ReportViewLayout = ({
  heading, children, withHeader, reportType, report, reportActionRequest, actions,
}) => (
  <>
    <ScrollToTopOnMount />
    { withHeader && (
      <div className="content-header__wrapper">
        <div className="content-header content-header--with-breadcrumbs container">
          <div className="finance-report__heading-wrapper d-flex flex-wrap">
            <ReportViewBreadcrumbs
              heading={heading}
              reportType={reportType}
              report={report}
              ctaComponent={report && (
                <ReportViewHeaderActions
                  reportType={reportType}
                  report={report}
                  actions={actions}
                />
              )}
            />
          </div>
        </div>
      </div>
    )}
    <section className="page page--finance finance-report__content">
      <div className="container">
        {report && <StatusReason item={report} itemType={reportType} />}
        { reportActionRequest }
        <div className="finance-report__content-wrapper">
          { children }
        </div>
      </div>
    </section>
  </>
);

ReportViewLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.object]).isRequired,
  heading: PropTypes.string,
  report: PropTypes.object,
  reportActionRequest: PropTypes.node,
  reportType: PropTypes.string.isRequired,
  withHeader: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    Form: PropTypes.node.isRequired,
    btnLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    btnVariant: PropTypes.string,
    btnIcon: PropTypes.string,
    btnClassName: PropTypes.string,
  })),
};

ReportViewLayout.defaultProps = {
  heading: '',
  report: null,
  reportActionRequest: null,
  withHeader: true,
  actions: [],
};

export default ReportViewLayout;
