import PropTypes from 'prop-types';


export const dataReportSpec = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  columns: PropTypes.array,
});

export const dataReportsSpec = PropTypes.arrayOf(dataReportSpec);
