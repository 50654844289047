import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import PasswordForgotForm from 'accounts/assets/js/components/PasswordForgotForm.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { postPasswordForgotDS } from 'accounts/assets/js/data-services/password';
import { selectProfile } from 'accounts/assets/js/reducers/auth';


const PasswordForgotView = ({ profile, match, dispatch }) => {
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  if (profile) {
    return (
      <RedirectRoute status={302} from={match.url} to={profile.homeUrl} />
    );
  }
  return (
    <div>
      <PasswordForgotForm
        onSubmit={async values => {
          try {
            await postPasswordForgotDS(values, dispatch);
            setSubmitSucceeded(true);
            return null;
          } catch (error) {
            if (error.errors) {
              return error.errors;
            }
            return { [FORM_ERROR]: error._error || error.message };
          }
        }}
        submitSucceeded={submitSucceeded}
      />
    </div>
  );
};

PasswordForgotView.propTypes = {
  profile: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
};

PasswordForgotView.defaultProps = {
  profile: null,
};

PasswordForgotView.getMetaTags = () => ({
  title: 'Forgot Password',
});

const mapStateToProps = (state, props) => ({
  profile: selectProfile(state),
  match: props.match,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PasswordForgotViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordForgotView);

export default withRouter(PasswordForgotViewConnected);
