import React from 'react';
import PropTypes from 'prop-types';
import ReactMde, { commands } from 'react-mde';

import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import { ICON, TEXT_AREA_DEFAULT_HEIGHT } from 'core/assets/js/constants';
import LinkModal from 'core/assets/js/components/LinkModal.jsx';
import MarkdownFormattingExample from 'core/assets/js/components/MarkdownFormattingExample.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';


const markdownCommands = [
  {
    commands: [
      commands.boldCommand,
      commands.italicCommand,
      commands.strikeThroughCommand,
      commands.linkCommand,
      commands.headerCommand,
      commands.orderedListCommand,
      commands.unorderedListCommand,
    ],
  },
];

const ICON_MAPPING = {
  bold: ICON.BOLD,
  italic: ICON.ITALIC,
  strikethrough: ICON.STRIKE_THROUGH,
  link: ICON.LINK_SOLID,
  'ordered-list': ICON.OLIST,
  'unordered-list': ICON.ULIST,
  header: ICON.HEADING,
};

const ICON_DESCRIPTION = {
  bold: 'Add bold text',
  italic: 'Add Italic text',
  strikethrough: 'Strike though',
  link: 'Add a link',
  'ordered-list': 'Add an ordered list',
  'unordered-list': 'Add an un-ordered list',
  header: 'Add a header',
};

const MarkdownTextArea = ({ input, height, placeholder, previewFunction, ...rest }) => {
  const [selectedTab, setSelectedTab] = React.useState('write');

  return (
    <React.Fragment>
      <ReactMde
        value={input.value}
        textAreaProps={{
          placeholder,
          ...rest,
        }}
        onChange={input.onChange}
        className="markdown-content"
        commands={markdownCommands}
        selectedTab={selectedTab}
        minEditorHeight={height}
        maxEditorHeight={height}
        minPreviewHeight={height - 20}
        l18n={{
          preview: (
            <TDElementWithTooltip
              delay={300}
              tooltipMsg="Preview"
            >
              <span className={ICON.VIEW} />
            </TDElementWithTooltip>
          ),
          write: (
            <TDElementWithTooltip
              delay={300}
              tooltipMsg="Close preview"
            >
              <span className={`${ICON.VIEW} active`} />
            </TDElementWithTooltip>
          ),
        }}
        getIcon={(commandName) => {
          return (
            <TDElementWithTooltip
              delay={300}
              tooltipMsg={ICON_DESCRIPTION[commandName]}
            >
              <span className={ICON_MAPPING[commandName]} />
            </TDElementWithTooltip>
          );
        }}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={markdown => {
          if (previewFunction) {
            return previewFunction(markdown);
          }
          return Promise.resolve((
            <MarkdownText withBreaksPlugin text={markdown || ''} />
          ));
        }}
      />

      <span className="markdown-help">
        <LinkModal
          modalId="markdown-help-modal"
          label={(
            <TDElementWithTooltip
              delay={300}
              tooltipMsg="Markdown formatting"
            >
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M593.8 59.1H46.2C20.7 59.1 0 79.8 0 105.2v301.5c0 25.5 20.7 46.2 46.2 46.2h547.7c25.5 0 46.2-20.7 46.1-46.1V105.2c0-25.4-20.7-46.1-46.2-46.1zM338.5 360.6H277v-120l-61.5 76.9-61.5-76.9v120H92.3V151.4h61.5l61.5 76.9 61.5-76.9h61.5v209.2zm135.3 3.1L381.5 256H443V151.4h61.5V256H566z"
                />
              </svg>
            </TDElementWithTooltip>
          )}
          modalOptions={{
            heading: 'Markdown formatting',
            body: (
              <MarkdownFormattingExample />
            ),
          }}
        />
      </span>
    </React.Fragment>
  );
};

MarkdownTextArea.propTypes = {
  height: PropTypes.number,
  input: reduxInputSpec.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  previewFunction: PropTypes.func,
};

MarkdownTextArea.defaultProps = {
  height: TEXT_AREA_DEFAULT_HEIGHT,
  maxLength: null,
  placeholder: '',
  previewFunction: null,
};

export default MarkdownTextArea;
