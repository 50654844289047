import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';

function DatePicker() {
  return (
    <section id="date-picker" className="clearfix">
      <h2>Date Picker (Inline / Read only view)</h2>

      <div className="d-flex justify-content-center justify-content-sm-start">
        <DayPickerRangeController
          startDate={moment().date(5)}
          endDate={moment().date(22)}
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false} // Allows past dates selection
          onDatesChange={() => {}}
        />
      </div>
    </section>
  );
}

export default DatePicker;
