import React from 'react';
import PropTypes from 'prop-types';

import { lineBreakToBr } from 'core/assets/js/lib/utils-jsx';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';

const ExpenseDetailsModal = ({ isOpen, summary, attachments, onClose }) => (
  <ModalSimple
    heading="Expense Details"
    open={isOpen}
    onClose={onClose}
    body={(
      <React.Fragment>
        <p>
          <strong>
            Expense Summary
          </strong>
        </p>

        <p className="mb-4">
          {lineBreakToBr(summary, 'ts-summary-line-')}
        </p>

        <AttachmentsList
          attachments={attachments}
          label="Expense Attachments"
        />
      </React.Fragment>
    )}
  />
);


ExpenseDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  summary: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
};

ExpenseDetailsModal.defaultProps = {
  isOpen: false,
  summary: '',
  attachments: [],
  onClose: () => {},
};

export default ExpenseDetailsModal;
