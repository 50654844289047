import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDCheckbox from 'core/assets/js/components/TDCheckbox.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchWorksheetImportPreviewInfoDS } from 'projects/assets/js/data-services/view';
import ProjectWorksheetImportPreviewSkeleton from 'projects/assets/js/components/skeletons/ProjectWorksheetImportPreviewSkeleton.jsx';
import ProjectWorksheetImportAnalysisTable from 'projects/assets/js/components/ProjectWorksheetImportAnalysisTable.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';

const COMPONENT_NAME = 'ProjectWorksheetImportPreview';

class ProjectWorksheetImportPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlyInvalidTable: false,
    };

    this.handleToggleTable = this.handleToggleTable.bind(this);
  }

  componentDidMount() {
    const { updateFormField, validWorksheets } = this.props;
    updateFormField('validWorksheets', validWorksheets);
  }

  handleToggleTable() {
    this.setState(state => ({ showOnlyInvalidTable: !state.showOnlyInvalidTable }));
  }

  render() {
    const {
      validWorksheets, invalidWorksheets, selectedHeaders: headers,
    } = this.props;
    const { showOnlyInvalidTable } = this.state;
    const invalidFile = validWorksheets.length === 0;
    const toggleEl = (
      <span className="import-worksheets-page--toggle-view-checkbox" onClick={this.handleToggleTable}>
        <TDCheckbox
          checked={showOnlyInvalidTable}
        />
        Show only invalid worksheets
      </span>
    );
    return (
      <div className="project-worksheet-import--preview">
        <h3 className="mb-4 mt-0">
          Preview
          {!invalidFile && toggleEl}
        </h3>
        {invalidFile && (
          <TDSystemMessage
            type={BS_STYLE.DANGER}
            title="All worksheets are invalid!"
            className="mt-4"
          >
            Oops, we could not find any valid worksheets. Please try again!
          </TDSystemMessage>
        )}

        { !invalidFile && (
          <div>
            { 'We analysed your CSV file and we\'re ready to import'}
            {' '}
            <span className="font-weight-bolder">{ validWorksheets.length }</span>
            {' '}
            worksheets.

            {invalidWorksheets.length > 0 && (
              <>
                {' '}
                Sadly,
                {' '}
                <span className="font-weight-bolder">{ invalidWorksheets.length }</span>
                {' '}
                cannot be imported because they are invalid.
              </>
            )}
            {' '}
          </div>
        )}
        <ProjectWorksheetImportAnalysisTable
          worksheets={
            showOnlyInvalidTable ? invalidWorksheets : [...invalidWorksheets, ...validWorksheets]
          }
          headers={headers}
          emptyText={
            showOnlyInvalidTable ? 'No invalid worksheets found' : 'No worksheets found'
          }
        />
      </div>
    );
  }
}

ProjectWorksheetImportPreview.propTypes = {
  validWorksheets: PropTypes.array.isRequired,
  invalidWorksheets: PropTypes.array.isRequired,
  selectedHeaders: PropTypes.array.isRequired,
  updateFormField: PropTypes.func.isRequired,
};

const TdApiConnectedPreview = withTDApiConnected({
  fetchData: ({
    dispatch, params: { orgAlias, id }, authedAxios, querystring,
  }) => dispatch(fetchWorksheetImportPreviewInfoDS({
    orgAlias, id, authedAxios, componentName: COMPONENT_NAME, querystring,
  })),
  duck: 'view',
  storeKey: COMPONENT_NAME,
  skeletonComponent: ProjectWorksheetImportPreviewSkeleton,
})(ProjectWorksheetImportPreview);

const mapStateToProps = (state) => {
  const viewState = getViewState(
    state, COMPONENT_NAME,
  );
  return {
    validWorksheets: viewState.item.validWorksheets,
    invalidWorksheets: viewState.item.invalidWorksheets,
    selectedHeaders: viewState.item.selectedHeaders,
  };
};

const ProjectWorksheetImportPreviewConnected = connect(
  mapStateToProps,
)(TdApiConnectedPreview);

export default ProjectWorksheetImportPreviewConnected;
