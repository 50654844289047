import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import GetStartedProgressNavBarItem from 'accounts/assets/js/components/GetStartedProgressNavBarItem.jsx';
import Logger from 'core/assets/js/lib/Logger';
import NavBarFooter from 'core/assets/js/components/NavBar/NavBarFooter.jsx';
import NavBarItem from 'core/assets/js/components/NavBar/NavBarItem.jsx';
import NavBarSectionWrapper from 'core/assets/js/components/NavBar/sections/NavBarSectionWrapper.jsx';
import NavBarSettingsList from 'core/assets/js/components/NavBar/sections/NavBarSettingsList.jsx';
import OrganizationSwitcher from 'organizations/assets/js/components/OrganizationSwitcher.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import GetPaidModal, { GET_PAID_MODAL_ID } from 'core/assets/js/components/Dashboard/GetPaidModal.jsx';
import { USER_CARD_STATUS, NAVBAR_SECTIONS, BS_STYLE } from 'core/assets/js/constants';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { isMenuItemActive } from 'core/assets/js/lib/utils';
import { modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg, selectUserCards, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { selectIsTrialExpired } from 'organizations/assets/js/reducers/subscriptions';
import { selectPendingCounts } from 'core/assets/js/ducks/pendingCount';
import { userCardSpec, orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

/**
 * The navigation bar
 *
 * @param props
 * @returns {XML}
 */
class NavBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.logger = new Logger('navbar');
    this.state = { activeSection: null };
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { location, match, settingsSubItems } = this.props;
    const { activeSection } = this.state;
    if (
      activeSection !== NAVBAR_SECTIONS.SETTINGS
      && settingsSubItems.some(item => isMenuItemActive(match, location, item.sections))
    ) {
      this.setState({ activeSection: NAVBAR_SECTIONS.SETTINGS });
    }
  }

  onClose(close = true) {
    const { handleClose } = this.props;
    this.setState({ activeSection: null });

    if (close) {
      handleClose();
    }
  }

  render() {
    const {
      activeOrg,
      activeUserCard,
      dispatch,
      isBlockedByGetStarted,
      isTrialExpired,
      isGetPaidModalOpen,
      items,
      location,
      match,
      settingsSubItems,
      showAccountProgressBar,
      showBilling,
      showNotifications,
      userCards,
    } = this.props;
    const { activeSection } = this.state;

    const onAddWidget = () => {
      dispatch(modalOpenAC(GET_PAID_MODAL_ID));
    };

    const menuItems = items.map((item, index) => (
      <NavBarItem
        isSectionActive={isMenuItemActive(match, location, item.sections)}
        key={`${item.path}`}
        onClick={item.onClick}
        tabIndex={index}
        {...pick(item, [
          'count', 'darkHighlightWhenActive', 'icon', 'items', 'label', 'path', 'sections', 'suffix',
        ])}
      />
    ));

    const isMenuDisabled = isTrialExpired
      || (
        activeUserCard.pendingApproval
        || [USER_CARD_STATUS.REJECTED, USER_CARD_STATUS.DEACTIVATED]
          .includes(activeUserCard.status)
      ) || activeOrg.status === ORGANIZATION_STATUS.DEACTIVATED;

    const isAnyManager = activeUserCard.userRole.isAnyManager;

    const showMenu = !isMenuDisabled && !isBlockedByGetStarted;

    return (
      <div className="navbar-col no-select">
        <OrganizationSwitcher
          organizations={userCards}
        />

        {!isAnyManager && !isMenuDisabled && !isBlockedByGetStarted && (
          <div className="get-paid-button-wrap col-auto mb-3 mt-3">
            <TDButton
              className="get-paid-button-wrap__button"
              onClick={onAddWidget}
              label="Get Paid"
              variant={BS_STYLE.PRIMARY}
            />
          </div>
        )}

        {isGetPaidModalOpen && (
          <GetPaidModal />
        )}

        <div className="navbar__container">
          {menuItems.length > 0
            && (
              <>
                <NavBarSectionWrapper
                  isOpen={activeSection === NAVBAR_SECTIONS.SETTINGS}
                  onClose={this.onClose}
                >
                  <NavBarSettingsList items={settingsSubItems} />
                </NavBarSectionWrapper>
                {showAccountProgressBar && (
                  <ul className={`navbar__menu mb-2
                ${activeSection === NAVBAR_SECTIONS.SETTINGS ? 'navbar__menu-inActive' : ''}`}
                  >
                    <GetStartedProgressNavBarItem isCompact={!isBlockedByGetStarted} />
                  </ul>
                )}

                {showMenu && (
                  <ul
                    className={`navbar__menu ${activeSection === NAVBAR_SECTIONS.SETTINGS ? 'navbar__menu-inActive' : ''}
                  ${isMenuDisabled ? 'navbar__menu--disabled' : ''}`}
                  >
                    { menuItems }
                  </ul>
                )}
              </>
            )
          }

          <NavBarFooter
            activeOrg={activeOrg}
            activeSection={activeSection}
            menuIsVisible={showMenu}
            orgAlias={activeOrg.orgAlias}
            setActiveSection={section => this.setState({ activeSection: section })}
            showBilling={showBilling}
            showNotifications={showNotifications}
          />
        </div>
      </div>
    );
  }
}

NavBar.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isTrialExpired: PropTypes.bool.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  isBlockedByGetStarted: PropTypes.bool.isRequired,
  isMenuOpened: PropTypes.bool,
  items: PropTypes.array,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onMenuClick: PropTypes.func,
  pendingCounts: PropTypes.object.isRequired,
  settingsSubItems: PropTypes.array.isRequired,
  showAccountProgressBar: PropTypes.bool,
  showBilling: PropTypes.bool,
  showNotifications: PropTypes.bool,
  userCards: PropTypes.array,
  withLogo: PropTypes.bool,
  isGetPaidModalOpen: PropTypes.bool,
};

NavBar.defaultProps = {
  activeUserCard: {},
  isGetPaidModalOpen: false,
  isMenuOpened: false,
  items: [],
  onMenuClick: null,
  showAccountProgressBar: false,
  showBilling: false,
  showNotifications: true,
  userCards: [],
  withLogo: false,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = (state, props) => ({
  activeOrg: selectActiveOrg(state),
  isTrialExpired: selectIsTrialExpired(state),
  hasOrgAccess: getHasOrgAccess(state),
  location: props.location,
  match: props.match,
  pendingCounts: selectPendingCounts(state),
  userCards: selectUserCards(state),
  activeUserCard: selectActiveUserCard(state),
  isGetPaidModalOpen: getIsModalOpen(state, GET_PAID_MODAL_ID),
});

const NavBarConnected = connect(mapStateToProps, mapDispatchToProps)(NavBar);

export default withRouter(NavBarConnected);
