import React from 'react';
import { Field } from 'redux-form';

import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { countryCodeSpec } from 'core/assets/js/lib/objectSpecs';
import { isValidBic } from 'core/assets/js/lib/validators';
import { InvalidBankAccountError } from 'settings/assets/js/lib/errors';
import AccountNumber from 'settings/assets/js/components/bank-account-forms/AccountNumber.jsx';

const validateBicField = (value) => {
  const noError = '';
  if (!value) {
    return noError;
  }

  try {
    isValidBic(value);
    return noError;
  } catch (err) {
    if (err instanceof InvalidBankAccountError) {
      return err.message;
    }
  }
  return noError;
};

const BankAccountForm = ({ countryCode }) => (
  <React.Fragment>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name="swift_bic_code"
          type="text"
          component={InputField}
          label="BIC/Swift Code"
          validate={validateBicField}
        />
      </div>
      <div className="col-12 col-sm-6">
        <AccountNumber countryCode={countryCode} />
      </div>
    </div>
  </React.Fragment>
);

BankAccountForm.propTypes = {
  countryCode: countryCodeSpec,
};

BankAccountForm.defaultProps = {
  countryCode: null,
};

export default BankAccountForm;
