import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { orgPeopleDiscoverDetailUrl, orgPeopleDiscoverUrl } from 'people/urls';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { discoverTalentDetailApiUrl } from 'search/urls';
import { ICON } from 'core/assets/js/constants';

const DiscoverPeoplePortfolioView = () => {
  const params = useParams();
  const { orgAlias, talentId, fileIndex } = params;

  const [currentImageIndex, setCurrentImageIndex] = useState(parseInt(fileIndex, 10) || 0);

  const { hasLoaded, item: talent } = fetchDataHook({
    componentName: DiscoverPeoplePortfolioView.GetComponentName(),
    url: discoverTalentDetailApiUrl({ orgAlias, talentId }),
  });

  const portfolio = talent?.portfolio || [];

  const getDownloadButton = () => {
    const currentItem = portfolio[currentImageIndex];
    if (currentItem.file) {
      return (
        <a className="image-gallery-download-action with-icon cursor-pointer" href={currentItem.file}>
          <span className={ICON.DOWNLOAD} />
          {' '}
          <span>Download</span>
        </a>
      );
    }

    return null;
  };

  const breadcrumbs = [
    {
      title: 'TalentMarket',
      url: orgPeopleDiscoverUrl(orgAlias),
    },
    {
      title: talent?.profile?.fullName,
      url: orgPeopleDiscoverDetailUrl(orgAlias, talentId),
    },
    {
      title: 'Portfolio',
      url: null,
    },
  ];

  return (hasLoaded && (
    <>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="container mt-5">
        <div className="talent-portfolio__container col-12 rounded-lg bg-white p-4">
          {portfolio?.length > 0 && (
            <ImageGallery
              additionalClass="talent"
              items={
                portfolio?.map(p => {
                  if (p.itemType === 'file') {
                    const imageUrl = '/img/document-placeholder.png';
                    return { ...p, file: p.original,
                      original: imageUrl, thumbnail: imageUrl,
                    };
                  }
                  if (p.itemType === 'embed') {
                    return {
                      ...p, embedUrl: p.original, original: p.thumbnail,
                      renderItem: (
                        <iframe
                          allowFullScreen
                          frameBorder="0"
                          height="400"
                          src={p.embedUrl}
                          title={p.title}
                          width="600"
                        />
                      ),
                    };
                  }
                  return p;
                })
              }
              onSlide={currentIndex => setCurrentImageIndex(currentIndex)}
              renderCustomControls={getDownloadButton}
              showFullscreenButton={false}
              showPlayButton={false}
              showBullets={false}
              startIndex={currentImageIndex}
            />
          )}
        </div>
      </div>
    </>
  ));
};

DiscoverPeoplePortfolioView.GetComponentName = () => 'DiscoverPeoplePortfolioView';

export default DiscoverPeoplePortfolioView;
