import React, { useCallback } from 'react';

import HSChat from 'core/assets/js/lib/HSChat';

/**
 * User friendly message that shows when attempting to access Wise
 * accounts ( create or edit ) if the service status is not ok.
 * @return {JSX.Element} component JSX.
 */
const PaymentProviderOffline = () => {
  const hsChatOpen = useCallback(() => {
    HSChat.openHSChat();
  }, []);

  // when mobile the socket and plug should be centered, however
  // otherwise socket should be centered with plug offset to rhs
  return (
    <div
      className="d-flex align-items-center flex-column"
      data-testid="wise-offline"
    >
      <div className="d-flex justify-content-center mb-5 payment-provider-offline-image">
        <img
          className="invisible payment-provider-offline-image__left-image"
          src="/img/payments-offline/plug.svg"
          alt="Payments offline"
        />
        <img
          src="/img/payments-offline/outlet.svg"
          alt="Payments offline"
        />
        <img
          className="payment-provider-offline-image__right-image"
          src="/img/payments-offline/plug.svg"
          alt="Payments offline"
        />
      </div>
      <h1 className="mt-5">
        Connection lost
      </h1>
      <p className="p-2 text-center payment-provider-offline__sm-text">
        Currently we cannot establish a connection with our payment provider in order to
        {' '}
        process and validate your payment details.
      </p>
      <p className="p-2 text-center payment-provider-offline__md-text">
        Please try again in a few minutes.
      </p>
      <p className="p-2 text-center">
        If the issue persists please report it via the
        {' '}
        <a className="text-primary" onClick={hsChatOpen}>live chat</a>
        {' or at '}
        <a href="mailto:support@talentdesk.io">support@talentdesk.io</a>
      </p>
    </div>
  );
};

export default PaymentProviderOffline;
