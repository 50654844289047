import React from 'react';
import PropTypes from 'prop-types';

import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const TaskCardSkeleton = ({ variation }) => {
  switch (variation) {
    case '1':
    default:
      return (
        <div className="skeleton--task-list-item clearfix">
          <div className="float-left">
            <RectangleSkeleton width="183" height="20" />
            <RectangleSkeleton width="100" height="20" />
            <CircleSkeleton className="ml-2" diameter="20" />
            <br />
            <RectangleSkeleton width="80" height="10" />
          </div>
        </div>
      );

    case '2':
      return (
        <div className="skeleton--task-list-item clearfix">
          <div className="float-left">
            <RectangleSkeleton width="90" height="15" />
            <RectangleSkeleton width="70" height="15" />
            <RectangleSkeleton width="80" height="15" />
            <br />
            <br />
            <RectangleSkeleton width="220" height="20" />
            <RectangleSkeleton width="80" height="20" />
            <CircleSkeleton className="ml-2 d-none d-md-inline" diameter="15" />
            <br />
            <RectangleSkeleton width="80" height="10" />
            <RectangleSkeleton width="40" height="10" />
          </div>
        </div>
      );
  }
};


TaskCardSkeleton.propTypes = {
  variation: PropTypes.string,
};

TaskCardSkeleton.defaultProps = {
  variation: '1',
};


export default TaskCardSkeleton;
