import Pusher from 'pusher-js';

import { IS_PRODUCTION, PUSHER } from 'core/assets/js/config/settings';
import Logger from 'core/assets/js/lib/Logger';

export const LOGING_NAMESPACE = 'tdliveupdates';
export const logger = new Logger(LOGING_NAMESPACE);

export const createClient = () => {
  const { appKey, cluster } = PUSHER;

  Pusher.logToConsole = !IS_PRODUCTION;
  Pusher.log = (msg) => logger.log(msg);

  return new Pusher(appKey, {
    cluster,
    channelAuthorization: {
      endpoint: '/pusher/auth',
    },
  });
};

export const isRateLimitError = (err) => err.error?.data?.code === 4004;
