import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import queryString from 'query-string';
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router-dom';

function TDPagination({
  containerClassName,
  end,
  extraMeta,
  history,
  location,
  onPageChange,
  page,
  pages,
  paginationKey,
  start,
  total,
}) {
  if (pages <= 1) {
    return null;
  }
  const containerClassNames = [
    'pagination-container row d-flex align-items-center text-center text-md-left',
    'justify-content-between',
  ];
  if (typeof containerClassName === 'string' && containerClassName.length > 0) {
    containerClassNames.push(containerClassName);
  }
  return (
    <div className={containerClassNames.join(' ')}>
      <div className="pagination-container__page-info col-12 col-md text-center text-md-left mb-3 mb-md-0">
        {`Showing ${start} to ${end} of ${total} entries`}
        {extraMeta && ' '}
        {extraMeta}
      </div>

      <ReactPaginate
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pages}
        initialPage={page - 1}
        marginPagesDisplayed={2}
        disableInitialCallback
        pageRangeDisplayed={4}
        onPageChange={(newPage) => {
          if (onPageChange) {
            return onPageChange({ page: newPage.selected + 1 });
          }
          let query = queryString.parse(location.search);

          let pagination = { ...query };
          if (paginationKey) {
            pagination = {};
            if (query[paginationKey]) {
              try {
                pagination = JSON.parse(query[paginationKey]);
              } catch (_) {} // eslint-disable-line no-empty
            }
          }

          pagination = {
            ...omit(pagination, ['page', 'after', 'before', 'order']),
            page: newPage.selected + 1,
          };

          if (paginationKey) {
            query[paginationKey] = JSON.stringify(pagination);
          } else {
            query = pagination;
          }

          return history.push({
            path: history.location.pathName, search: queryString.stringify(query),
          });
        }}
        containerClassName="pagination-container__pages col-12 col-md-auto mt-2 mt-md-0"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
    </div>
  );
}

TDPagination.propTypes = {
  containerClassName: PropTypes.string,
  end: PropTypes.number,
  extraMeta: PropTypes.node,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  paginationKey: PropTypes.string,
  pages: PropTypes.number.isRequired,
  start: PropTypes.number,
  total: PropTypes.number.isRequired,
};

TDPagination.defaultProps = {
  containerClassName: 'mt-5',
  end: null,
  extraMeta: null,
  onPageChange: null,
  paginationKey: null,
  start: null,
};

export default withRouter(TDPagination);
