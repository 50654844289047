import { LAYOUTS } from 'core/assets/js/constants';
import { reportsUrl, reportUrl } from 'analytics/urls';

// Views
import ReportsView from 'analytics/assets/js/ReportsView.jsx';
import ReportView from 'analytics/assets/js/ReportView.jsx';

const analyticsRouteConfig = [
  {
    path: reportsUrl(':orgAlias'),
    component: ReportsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: reportUrl(':orgAlias', ':id'),
    component: ReportView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
];

export default analyticsRouteConfig;
