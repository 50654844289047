import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { financeCancelProFormaInvoiceDS } from 'finance/assets/js/data-services/form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { proFormaInvoiceSpec } from 'finance/assets/js/lib/objectSpecs';

const ProFormaInvoiceCancelForm = ({
  onCancelSuccess, handleSubmit, submitting,
  orgAlias, serviceOrder, dispatch,
}) => {
  /**
   * Cancel Proforma Invoice - form submit callback
   *
   * @param values
   * @returns {Promise<any>}
   */
  const onSubmitCancel = values => (
    dispatch(financeCancelProFormaInvoiceDS(
      {
        orgAlias,
        projectId: serviceOrder.projectId,
        serviceOrderId: serviceOrder.id,
      },
      { ...values,
        rateUnit: serviceOrder.rateUnit,
        quantity: serviceOrder.quantity,
      },
    )).then((res) => {
      onCancelSuccess(res.serviceOrder, res.projectAllowedActions);
    })
  );

  return (
    <div className="static-modal">
      <form>
        <TDSystemMessage
          type={BS_STYLE.WARNING}
          title="Canceling a Proforma Invoice cannot be undone!"
          className="mb-4"
        />

        <div>
          <Field
            label="Why do you want to cancel your Proforma Invoice?"
            name="reason"
            type="textarea"
            component={InputField}
          />
        </div>

        <TDButton
          className="col-12"
          onClick={handleSubmit(onSubmitCancel)}
          bsSize={BS_SIZE.LARGE}
          variant={BS_STYLE.DANGER}
          disabled={submitting}
          label="Cancel Proforma Invoice"
        />
      </form>
    </div>
  );
};

ProFormaInvoiceCancelForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancelSuccess: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  serviceOrder: proFormaInvoiceSpec,
  submitting: PropTypes.bool,
};
ProFormaInvoiceCancelForm.defaultProps = {
  submitting: false,
  serviceOrder: null,
};

export default ProFormaInvoiceCancelForm;
