import React from 'react';
import { Card } from 'react-bootstrap';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ListItem = () => (
  <Card className="card--light-gray border-0 shadow-none">
    <Card.Body className="flex-column px-4 py-4">
      <div className="row py-2">
        <div className="col-auto d-flex align-items-center">
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <RectangleSkeleton width="130" height="16" />
          </div>
        </div>

        <div className="d-block text-right mr-0 col">
          <RectangleSkeleton
            width="145"
            height="14"
          />
        </div>
      </div>
    </Card.Body>
  </Card>
);

const PeopleRatesTabSkeleton = () => (
  <div className="px-4">
    <ListItem />
    <ListItem />
  </div>
);

export default PeopleRatesTabSkeleton;
