import React from 'react';
import PropTypes from 'prop-types';
import RoundedPic from 'core/assets/js/components/RoundedPic.jsx';

const OrganizationLogo = ({ url, orgName, className, size }) => {
  // If logo URL found, use logo.
  if (url) {
    return (
      <RoundedPic
        url={url}
        alt={`${orgName}'s logo`}
        size={size}
        className={['organization-logo flex-shrink-0', className].join(' ')}
      />
    );
  }

  // If no Logo URL found, use company name's first Letters of first 2 words.
  const matches = String(orgName).match(/\b(\w)/g); // First letters of each word.
  const abbreviation = matches ? matches.join('').substring(0, 2) : orgName;
  const [width, height] = size;

  return (
    <span
      title={`${orgName}'s logo`}
      className={['organization-logo organization-logo--abbreviation flex-shrink-0', className].join(' ')}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        lineHeight: `${height}px`,
      }}
    >
      {abbreviation}
    </span>
  );
};

OrganizationLogo.propTypes = {
  className: PropTypes.string,
  orgName: PropTypes.string.isRequired,
  url: PropTypes.string,
  size: PropTypes.array,
};
OrganizationLogo.defaultProps = {
  className: '',
  size: ['40', '40'],
  url: '',
};

export default OrganizationLogo;
