import React from 'react';
import PropTypes from 'prop-types';
import { NotFound } from 'core/assets/js/NotFoundView.jsx';
import Error50x from 'core/assets/js/components/errors/Error50x.jsx';

const ApiError = ({ httpErrorCode }) => {
  if (httpErrorCode === 403 || httpErrorCode === 401) {
    return <NotFound errMsg="You don't have access to this page" displayInline />;
  }
  if (httpErrorCode === 404) {
    return <NotFound errMsg="This page does not exist" displayInline />;
  }
  if (httpErrorCode === 500) {
    return <Error50x displayInline />;
  }

  if (httpErrorCode) {
    return (
      <div className="td_api_connected-loaded">
        An error has occurred.
      </div>
    );
  }
  return null;
};

ApiError.propTypes = {
  httpErrorCode: PropTypes.number,
};

ApiError.defaultProps = {
  httpErrorCode: null,
};

export default ApiError;
