import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { projectCancelExpenseDS } from 'projects/assets/js/data-services/form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';

const ExpenseCancelForm = ({
  onSuccess, handleSubmit, submitting, orgAlias, projectId, expenseId, dispatch,
}) => {
  /**
   * Cancel Expense - form submit callback
   *
   * @param values
   * @returns {Promise<any>}
   */
  const onConfirmed = async (values) => {
    const { expense: updatedExpense } = await dispatch(projectCancelExpenseDS({
      orgAlias,
      projectId,
      expenseId,
      values,
    }));

    await onSuccess(updatedExpense);
  };

  return (
    <form>
      <TDSystemMessage
        type={BS_STYLE.WARNING}
        title="Canceling an Expense cannot be undone!"
        className="mb-4"
      />

      <div>
        <Field
          label="Why do you want to cancel your expense?"
          name="cancelling_reason"
          type="textarea"
          component={InputField}
        />
      </div>

      <TDButton
        className="col-12"
        onClick={handleSubmit(onConfirmed)}
        bsSize={BS_SIZE.LARGE}
        variant={BS_STYLE.DANGER}
        disabled={submitting}
        label="Cancel expense claim"
      />
    </form>
  );
};

ExpenseCancelForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  expenseId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  submitting: PropTypes.bool,
};

ExpenseCancelForm.defaultProps = {
  submitting: false,
};

export default ExpenseCancelForm;
