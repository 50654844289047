import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import OrgPrivacySettings from 'settings/assets/js/components/OrgPrivacySettings.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { fetchOrganizationDS, postOrganizationDS } from 'settings/assets/js/data-services/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { getViewState } from 'core/assets/js/ducks/view';

class PrivacySettingsView extends React.Component {
  static FetchData({ dispatch, params, url = '', authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchOrganizationDS(params, url, authedAxios, componentName)),
    ]);
  }

  static GetComponentName() {
    return 'PrivacySettingsView';
  }

  render() {
    const { dispatch, organization } = this.props;
    const breadcrumbs = [
      {
        title: 'Privacy',
        url: null,
      },
    ];

    const componentName = this.constructor.GetComponentName();

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--settings page--privacy-settings">
          <div className="container">
            <div className="rounded shadow-sm p-4 bg-white">
              <div className="page--settings__content clearfix">
                <TDApiConnected duck="view" component={this.constructor} blockingLoading skeletonComponent={SettingsPageSkeleton}>
                  <OrgPrivacySettings
                    organization={organization}
                    onSettingsChanged={changes => (
                      dispatch(postOrganizationDS(organization.alias, changes, componentName))
                    )}
                  />
                </TDApiConnected>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PrivacySettingsView.propTypes = {
  organization: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, PrivacySettingsView.GetComponentName());

  return {
    organization: viewState.item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PrivacySettingsViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacySettingsView);

export default PrivacySettingsViewConnected;
