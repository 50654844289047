/* eslint-disable import/prefer-default-export */
import {
  ORGANIZATION_LIST_FETCH,
  ORGANIZATION_LIST_IS_LOADING,
  ORGANIZATION_SELECTED,
  ORGANIZATION_UPDATED,
  USERCARD_UPDATED,
  PROGRESS_LOAD_SUCCESS,
  SUBSCRIPTIONS_LIST_FETCH,
  SUBSCRIPTIONS_LIST_IS_LOADING,
} from 'organizations/assets/js/constants';

export const orgSelectedAC = orgAlias => ({
  type: ORGANIZATION_SELECTED,
  orgAlias,
});

export const orgUpdatedAC = organization => ({
  type: ORGANIZATION_UPDATED,
  organization,
});

export const userCardUpdatedAC = userCard => ({
  type: USERCARD_UPDATED,
  userCard,
});

export const orgListFetchAC = organizations => ({
  type: ORGANIZATION_LIST_FETCH,
  organizations,
});

export const orgListIsLoadingAC = bool => ({
  type: ORGANIZATION_LIST_IS_LOADING,
  isLoading: bool,
});

export const progressLoadSuccessAC = progresses => ({
  type: PROGRESS_LOAD_SUCCESS,
  progresses,
});

export const orgSubscriptionsListIsLoadingAC = bool => ({
  type: SUBSCRIPTIONS_LIST_IS_LOADING,
  isLoading: bool,
});

export const orgSubscriptionsListFetchAC = subscriptions => ({
  type: SUBSCRIPTIONS_LIST_FETCH,
  subscriptions,
});
