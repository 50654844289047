import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';

import { BS_STYLE } from 'core/assets/js/constants';

import GeoSuggestField from 'core/assets/js/components/FinalFormFields/GeoSuggestField.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';

const OrgCreateForm = ({ updateFormField }) => {
  const { values: { no_subscription: noSubscription, name } } = useFormState();

  useEffect(() => updateFormField('unique_alias', kebabCase(name)), [name]);


  return (
    <>
      <h2>Company details</h2>

      {noSubscription === 1 && (
        <TDSystemMessage
          className="mb-4"
          data-testid="org-create-form-no-subscription"
          title="No subscription will be created"
          type={BS_STYLE.WARNING}
        >
          Please manually create a subscription for this new organization, after it has been
          created, in our admin portal.
        </TDSystemMessage>
      )}

      <TextInputField
        label="Legal company name"
        sublabel="This will be used on your invoices"
        name="name"
        required
      />

      <TextInputField
        label="Organisation alias"
        name="unique_alias"
        required
        sublabel="Use only letters, numbers & dashes"
      />

      <GeoSuggestField label="Company address" name="address" required />

      <TextInputField
        label="Your company registration number"
        sublabel={(
          "If you have one, please supply your company's registration number (not your company tax"
          + ' number)'
        )}
        name="registration_number"
      />
    </>
  );
};

OrgCreateForm.GetComponentName = () => 'OrgCreateForm';

OrgCreateForm.propTypes = {
  updateFormField: PropTypes.func.isRequired,
};

export default OrgCreateForm;
