import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { getListState } from 'core/assets/js/ducks/list';
import InvoicesTable from 'finance/assets/js/components/InvoicesTable.jsx';
import { invoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import {
  fetchFinanceInvoiceListDS,
} from 'finance/assets/js/data-services/list';
import ExportInvoicesModal from 'finance/assets/js/components/ExportInvoicesModal.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import RequestDeferredPaymentsPayout from 'finance/assets/js/components/RequestDeferredPaymentsPayout.jsx';

const INVOICES_EXPORT_MODAL_ID = 'invoicesExportModalId';

class Invoices extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return Promise.all([
      dispatch(fetchFinanceInvoiceListDS({ url, params, querystring, componentName, authedAxios })),
    ]);
  }

  static GetComponentName() {
    return 'Invoices';
  }

  constructor(props) {
    super(props);
    this.openInvoicesExportModal = this.openInvoicesExportModal.bind(this);
  }

  openInvoicesExportModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(INVOICES_EXPORT_MODAL_ID));
  }

  render() {
    const {
      amountLimits, dateLimits, filtersOpen, invoices,
      onFiltersToggle, pagination, searchActive,
    } = this.props;
    const emptyText = 'No invoices found';
    const breadcrumbs = [
      {
        title: 'Invoices',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--finance">
          <div className="container">
            <div className="row">
              {searchActive && (
                <ReportSearch
                  reportType={REPORT_TYPES.INVOICE}
                  amountLimits={amountLimits}
                  onFiltersToggle={onFiltersToggle}
                  filtersOpen={filtersOpen}
                />
              )}
              { !filtersOpen && (
                <div className="d-block pr-4 pb-4 ml-auto">
                  <TDDropButton
                    overlayClassName="actions-dropdown"
                    toggleEl={(
                      <TDButton
                        btnIcon={<i className={ICON.ELLIPSIS} />}
                        className="px-3"
                        variant={BS_STYLE.DEFAULT}
                      />
                    )}
                  >
                    <Dropdown.Item
                      eventKey="export-invoices"
                      onClick={this.openInvoicesExportModal}
                      disabled={invoices.length === 0}
                    >
                      Export
                    </Dropdown.Item>
                  </TDDropButton>
                </div>
              )}
            </div>
            <RequestDeferredPaymentsPayout />

            <TDApiConnected duck="list" component={this.constructor} skeletonComponent={FinanceTableSkeleton}>
              { !filtersOpen && (
                <React.Fragment>
                  <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
                    <InvoicesTable
                      list={invoices}
                      emptyText={emptyText}
                    />
                  </div>
                  <TDPagination {...pagination} />
                </React.Fragment>
              )}
            </TDApiConnected>
            <ExportInvoicesModal
              dateLimits={dateLimits}
              invoicesCount={pagination.total}
              onFiltersToggle={onFiltersToggle}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Invoices.propTypes = {
  amountLimits: PropTypes.object,
  dateLimits: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  invoices: PropTypes.arrayOf(invoiceSpec).isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  searchActive: PropTypes.bool.isRequired,
};

Invoices.defaultProps = {
  amountLimits: {},
  dateLimits: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state) => {
  const listState = getListState(state, Invoices.GetComponentName());
  return {
    amountLimits: listState.extras.amountLimits,
    dateLimits: listState.extras.dateLimits,
    invoices: listState.items,
    pagination: listState.pagination,
    searchActive: listState.search.isActive,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFilters(Invoices));
