/* eslint-disable no-restricted-globals */
/* global localStorage */
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('storage');

class Storage {
  static get(key) {
    if (typeof localStorage === 'undefined' || !localStorage) {
      return null;
    }
    const item = localStorage.getItem(key);

    if (item) {
      try {
        return JSON.parse(item);
      } catch (error) {
        logger.error(error);
        logger.error('[Storage] faulty item:', item);
        return null;
      }
    }
    return null;
  }

  static set(key, value) {
    if (typeof localStorage !== 'undefined' && !!localStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  static remove(key) {
    if (typeof localStorage !== 'undefined' && !!localStorage) {
      localStorage.removeItem(key);
    }
  }
}

export default Storage;
