import React from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import StatusDot from 'core/assets/js/components/StatusDot.jsx';


const StatusTag = (props) => {
  const {
    displayAsTag, title, label, statusClass, hideTitle,
    onClick, hideDot, reason, className, customAttrs,
  } = props;
  const statusClassName = ['status', `status--${statusClass}`];

  if (displayAsTag) {
    statusClassName.push('status--tag');
  }

  if (className) {
    statusClassName.push(className);
  }

  return (
    <span
      className={statusClassName.join(' ')}
      title={!hideTitle ? (title || label) : ''}
      onClick={onClick}
      {...customAttrs}
    >
      { !hideDot && (
        <StatusDot statusClass={statusClass} />
      )}
      <span className="text-center">
        { label }
        {reason && (
          <TDElementWithTooltip
            tooltipMsg={`Reason: ${reason}`}
          >
            <i className={`${ICON.INFO} ml-3`} />
          </TDElementWithTooltip>
        )}
      </span>
    </span>
  );
};

StatusTag.propTypes = {
  displayAsTag: PropTypes.bool,
  label: PropTypes.node,
  hideTitle: PropTypes.bool,
  className: PropTypes.string,
  hideDot: PropTypes.bool,
  onClick: PropTypes.func,
  statusClass: PropTypes.string.isRequired,
  title: PropTypes.string,
  reason: PropTypes.string,
  customAttrs: PropTypes.object,
};

StatusTag.defaultProps = {
  displayAsTag: true,
  label: '',
  className: null,
  title: '',
  reason: null,
  hideTitle: false,
  hideDot: false,
  onClick: () => {},
  customAttrs: null,
};

export default StatusTag;
