/* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderModalManagerCheckbox", "renderModalManager"] }] */ // eslint-disable-line
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import { projectViewUrl } from 'projects/urls';
import { taskChangeOwnerDS } from 'projects/assets/js/data-services/tasks';
import { projectSpec, projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import TransferOwnershipModal from 'core/assets/js/components/TransferOwnershipModal.jsx';
import ProjectManagerCheckableItem from 'projects/assets/js/components/ProjectManagerCheckableItem.jsx';

export const MODAL_ID = 'task-change-ownership';

const TaskManagerItem = ({ item, task }) => {
  const { profile, id: selectedId } = item;
  const isTaskOwner = task.owner && +task.owner.id === +selectedId;
  return (
    <ProjectManagerCheckableItem label={isTaskOwner && 'Current task owner'} profile={profile} />
  );
};

TaskManagerItem.propTypes = {
  item: PropTypes.object.isRequired,
  task: projectTaskSpec.isRequired,
};

const TaskOwnerModal = ({
  dispatch, history, match: { params: { orgAlias } }, managers, task, project, parentComponentName,
  ownershipWarning, ownershipWarningTitle,
}) => {
  const singleUserWarningMessage = (
    <React.Fragment>
      To transfer ownership, first
      {' '}
      <a
        className="imitate-link"
        onClick={() => {
          history.push(projectViewUrl(orgAlias, project.id, 'team'));
        }}
      >
        add another manager
      </a>
      {' '}
      into the project.
    </React.Fragment>
  );

  const onOwnershipTransferred = selectedUserId => (
    dispatch(taskChangeOwnerDS({
      orgAlias,
      projectId: project.id,
      taskId: task.id,
      values: { manager_id: selectedUserId },
      componentName: parentComponentName,
    })).then(() => {
      toastr.success('Well Done!', 'You’ve got a new task owner!');
    }).catch((err) => {
      let message = 'Something went wrong, please try again';
      if (err.errors && err.errors._error) {
        message = err.errors._error;
      }
      toastr.error('Oops!', message);
    })
  );

  return (
    <TransferOwnershipModal
      modalId={MODAL_ID}
      heading="Transfer task ownership"
      users={managers}
      ownerId={task.owner ? task.owner.id : null}
      itemComponent={TaskManagerItem}
      itemComponentProps={{ task }}
      singleUserWarningTitle="It looks like you are the only manager in this project."
      singleUserWarningMessage={singleUserWarningMessage}
      onOwnershipTransferred={onOwnershipTransferred}
      ownershipWarning={ownershipWarning}
      ownershipWarningTitle={ownershipWarningTitle}
    />
  );
};

TaskOwnerModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  managers: PropTypes.arrayOf(userSpec).isRequired,
  match: routerMatchContentsSpec.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
  project: projectSpec.isRequired,
  ownershipWarning: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ownershipWarningTitle: PropTypes.string,
};

TaskOwnerModal.defaultProps = {
  ownershipWarning: null,
  ownershipWarningTitle: null,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskOwnerModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskOwnerModal);

export default withRouter(TaskOwnerModalConnected);
