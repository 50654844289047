import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { financeOutstandingDocumentsApiUrl } from 'finance/urls';
import { orgInviteePendingCountApiUrl } from 'people/urls';
import { projectPendingInvitationCountApiUrl } from 'projects/urls';
import { createSelector } from 'reselect';

export const PENDING_COUNT_FETCH = 'pending-count/FETCH';
export const PENDING_COUNT_RESET = 'pending-count/RESET';

export const PENDING_COUNT_TYPE = {
  PURCHASE_ORDERS: 'PURCHASE_ORDERS',
  WORKSHEETS: 'WORKSHEETS',
  EXPENSES: 'EXPENSES',
  TASKS: 'TASKS',
  INVOICES: 'INVOICES',
  OPPORTUNITY_INVITATIONS: 'OPPORTUNITY_INVITATIONS',
  PROJECT_INVITATIONS: 'PROJECT_INVITATIONS',
  TASK_INVITATIONS: 'TASK_INVITATIONS',
  ORG_INVITATIONS: 'ORG_INVITATIONS',
  ORG_INVITEES: 'ORG_INVITEES',
  PRO_FORMA_INVOICES: 'PRO_FORMA_INVOICES',
};

export const singleCountInitialState = {
  count: 0,
};

export const pendingCountInitialState = {
  [PENDING_COUNT_TYPE.PURCHASE_ORDERS]: singleCountInitialState,
  [PENDING_COUNT_TYPE.WORKSHEETS]: singleCountInitialState,
  [PENDING_COUNT_TYPE.EXPENSES]: singleCountInitialState,
  [PENDING_COUNT_TYPE.TASKS]: singleCountInitialState,
  [PENDING_COUNT_TYPE.INVOICES]: singleCountInitialState,
  [PENDING_COUNT_TYPE.OPPORTUNITY_INVITATIONS]: singleCountInitialState,
  [PENDING_COUNT_TYPE.PROJECT_INVITATIONS]: singleCountInitialState,
  [PENDING_COUNT_TYPE.TASK_INVITATIONS]: singleCountInitialState,
  [PENDING_COUNT_TYPE.ORG_INVITATIONS]: singleCountInitialState,
  [PENDING_COUNT_TYPE.ORG_INVITEES]: singleCountInitialState,
  [PENDING_COUNT_TYPE.PRO_FORMA_INVOICES]: singleCountInitialState,
};


export const singleCountReducer = (state = singleCountInitialState, action) => {
  switch (action.type) {
    case PENDING_COUNT_FETCH:
      return {
        ...state,
        count: action.count,
      };
    case PENDING_COUNT_RESET:
      return singleCountInitialState;
    default:
      return state;
  }
};

export const reducer = (state = pendingCountInitialState, action) => {
  if (action.type && action.type.startsWith('pending-count') && action.type !== PENDING_COUNT_RESET && !action.key) {
    throw new Error(`cannot use pending-count duck without specifying a key on action ${action.type}`);
  }
  const { key } = action;
  const keyState = singleCountReducer(state[key], action);
  let newState = { ...state };
  switch (action.type) {
    case PENDING_COUNT_FETCH:
      newState = {
        ...state,
        [key]: keyState,
      };
      break;
    case PENDING_COUNT_RESET:
      if (action.key) {
        newState = {
          ...state,
          [key]: keyState,
        };
      } else {
        newState = pendingCountInitialState;
      }
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

// Action creators
export const pendingCountFetchAC = (key, count) => ({
  type: PENDING_COUNT_FETCH,
  count,
  key,
});

export const pendingCountResetAC = key => ({
  type: PENDING_COUNT_RESET,
  key,
});


// selectors (use in mapStateToProps)
export const getPendingCount = (state, key) => (
  state && state.pendingCount && state.pendingCount[key] ? state.pendingCount[key].count : 0
);

export const selectSinglePendingCount = (state, key) => {
  if (!key) {
    throw new Error('cannot get pending count state without specifying key');
  }
  return state.pendingCount[key] || singleCountInitialState;
};

export const selectAllPendingCounts = state => state.pendingCount;

export const selectPendingCounts = createSelector(
  [selectAllPendingCounts],
  pendingCounts => pendingCounts,
);

// data services
export const refreshFinancePendingCountsDS = ({
  orgAlias, url = '', authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => {
    if (!orgAlias) {
      throw new Error(`Invalid orgAlias ${orgAlias}`);
    }
  },
  fetchApiUrl: () => financeOutstandingDocumentsApiUrl(orgAlias, url),
  fetchDataAC: responseData => [
    pendingCountFetchAC(PENDING_COUNT_TYPE.PURCHASE_ORDERS, responseData.pos || 0),
    pendingCountFetchAC(PENDING_COUNT_TYPE.WORKSHEETS, responseData.worksheets || 0),
    pendingCountFetchAC(PENDING_COUNT_TYPE.EXPENSES, responseData.expenses || 0),
    pendingCountFetchAC(PENDING_COUNT_TYPE.INVOICES, responseData.invoices || 0),
    pendingCountFetchAC(PENDING_COUNT_TYPE.PRO_FORMA_INVOICES, responseData.proformaInvoices || 0),
  ],
});


export const refreshInviteePendingCountDS = ({
  params, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => orgInviteePendingCountApiUrl(params, url),
    fetchDataAC: responseData => (
      pendingCountFetchAC(PENDING_COUNT_TYPE.ORG_INVITEES, responseData.pendingCount)
    ),
  })
);

export const refreshProjectInvitationsCountDS = ({
  orgAlias, url = '', authedAxios = null, componentName,
}) => fetchDataDS({
  authedAxios,
  componentName,
  validate: () => {
    if (!orgAlias) {
      throw new Error(`[_fetchDataDS Error] Invalid orgAlias ${orgAlias}`);
    }
  },
  fetchApiUrl: () => projectPendingInvitationCountApiUrl(orgAlias, url),
  fetchDataAC: responseData => [
    pendingCountFetchAC(PENDING_COUNT_TYPE.PROJECT_INVITATIONS, responseData.projects),
    pendingCountFetchAC(PENDING_COUNT_TYPE.TASK_INVITATIONS, responseData.tasks),
    pendingCountFetchAC(PENDING_COUNT_TYPE.OPPORTUNITY_INVITATIONS, responseData.opportunities),
  ],
});

export default reducer;
