import React from 'react';
import PropTypes from 'prop-types';

import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';

const WorksheetViewSkeleton = ({ type }) => (
  <React.Fragment>
    <div className="finance-report__head row">
      <div
        className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0"
      >
        <CircleSkeleton diameter="120" />
      </div>
      <div className="finance-report__meta col-12 col-md-6 col-lg-4">
        <div>
          <div>
            <label htmlFor="worksheet-details-1">
              Project Ref:
            </label>
            <span id="worksheet-details-1">
              <RectangleSkeleton width="70" height="18" />
            </span>
          </div>
          <div>
            <label htmlFor="worksheet-details-1">
              Invoice No:
            </label>
            <span id="worksheet-details-1">
              <RectangleSkeleton width="70" height="18" />
            </span>
          </div>
          <div>
            <label htmlFor="worksheet-details-1">
              {`${(type === 'worksheet') ? 'Worksheet' : 'Expense'} No :`}
            </label>
            <span id="worksheet-details-1">
              <RectangleSkeleton width="70" height="18" />
            </span>
          </div>
          <div>
            <label htmlFor="worksheet-details-1">
              Date:
            </label>
            <span id="worksheet-details-1">
              <RectangleSkeleton width="110" height="18" />
            </span>
          </div>
          <div>
            <label htmlFor="worksheet-details-1">
              Status:
            </label>
            <span id="worksheet-details-1">
              <RectangleSkeleton width="70" height="18" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div className="row mb-4">
      <div className="finance-report__provider col-12 col-md-8">
        <div className="mt-4">
          <h5>From</h5>
          <div>
            <h4>
              <RectangleSkeleton width="60" height="20" />
              <RectangleSkeleton width="90" height="20" />
            </h4>
            <div className="finance-report__provider__company">
              <div>
                <RectangleSkeleton width="140" height="20" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="finance-report__provider__details col-12 col-md-4">
        <div className="mt-4">
          <h5>Addressed to</h5>
          <div className="mb-4">
            <h4>
              <RectangleSkeleton width="60" height="20" />
              <RectangleSkeleton width="90" height="20" />
            </h4>
            <div className="finance-report__provider__company">
              <div>
                <RectangleSkeleton width="140" height="20" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row mb-4">
      <div className="finance-report__provider col-12">
        <div>
          <div className="finance-report__provider__details">
            <h5>Personal Details</h5>
            <RectangleSkeleton width="100" height="20" />
            <RectangleSkeleton width="80" height="20" />
          </div>
        </div>
      </div>
    </div>

    <br />

    <div className="d-none d-lg-block">
      <FinanceTableSkeleton />
    </div>

    <div className="d-block d-lg-none border p-4">
      <RectangleSkeleton width="180" height="20" />
      <br />
      <br />
      <RectangleSkeleton width="180" height="20" />
      <br />
      <br />
      <RectangleSkeleton width="180" height="20" />
      <br />
      <br />
      <RectangleSkeleton width="180" height="20" />
    </div>

    <div className="finance-report__payment__terms mt-5">
      <RectangleSkeleton width="180" height="20" />
    </div>
  </React.Fragment>
);

WorksheetViewSkeleton.propTypes = {
  type: PropTypes.string,
};
WorksheetViewSkeleton.defaultProps = {
  type: 'worksheet',
};

export default WorksheetViewSkeleton;
