import PropTypes from 'prop-types';

export const invitationSchema = {
  hasExpired: PropTypes.bool,
  id: PropTypes.number,
  message: PropTypes.string,
  orgAlias: PropTypes.string,
  orgId: PropTypes.number,
  orgLogo: PropTypes.string,
  orgName: PropTypes.string,
  project: PropTypes.object,
  sender: PropTypes.object,
  sentOn: PropTypes.string,
  sentTo: PropTypes.string,
  status: PropTypes.number,
  token: PropTypes.string,
  userRole: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  statusReason: PropTypes.string,
  statusLabel: PropTypes.string,
  expiresAt: PropTypes.string,
  answeredAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

export const invitationSpec = PropTypes.shape(invitationSchema);
