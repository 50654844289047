import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  fetchCsvInfoApiUrl,
  fetchContactsCsvAnalysisApiUrl,
} from 'importer/urls';
import { viewFetchExtrasAC } from 'core/assets/js/ducks/view';
import { REQ_TYPE } from 'core/assets/js/constants';
import { contactsCreateImportedApiUrl } from 'contacts/urls';

const _validateParams = ({ orgAlias }) => {
  if (!orgAlias) {
    throw new Error(
      `Invalid orgAlias (${orgAlias})`);
  }
};

export const fetchContactsImportInfoDS = ({
  orgAlias, componentName, authedAxios = null, querystring = '',
}) => fetchDataDS({
  querystring,
  authedAxios,
  validate: () => _validateParams({ orgAlias }),
  fetchApiUrl: () => fetchCsvInfoApiUrl({ orgAlias }),
  fetchDataAC: responseData => (
    viewFetchExtrasAC(responseData, componentName)
  ),
});

export const fetchContactsPreviewInfoDS = ({
  orgAlias, componentName, authedAxios = null, querystring = '',
}) => fetchDataDS({
  querystring,
  authedAxios,
  validate: () => _validateParams({ orgAlias }),
  fetchApiUrl: () => fetchContactsCsvAnalysisApiUrl({ orgAlias }),
  fetchDataAC: responseData => viewFetchExtrasAC({ ...responseData.analysis }, componentName),
});


export const createImportedContactsDS = ({ values, componentName, orgAlias }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: contactsCreateImportedApiUrl({ orgAlias }),
    pushDataAC: responseData => viewFetchExtrasAC({ ...responseData.analysis }, componentName),
    values,
  })
);
