import React from 'react';
import { Field } from 'redux-form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';

const PurchaseOrderCancelForm = () => {
  return (
    <div className="static-modal">
      <form>
        <div>
          <Field
            label="Why do you want to cancel your budget request?"
            name="reason"
            type="textarea"
            component={InputField}
          />
        </div>

        <div className="mb-4">
          Remember, you can request more budget later by creating another budget request.
          <br />
          <br />
          Are you sure you want to cancel this budget request?
        </div>
      </form>
    </div>
  );
};

export default PurchaseOrderCancelForm;
