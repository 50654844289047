import { findLast, findIndex } from 'lodash';
import moment from 'moment';

import {
  SUBSCRIPTIONS_LIST_FETCH,
  SUBSCRIPTIONS_LIST_IS_LOADING,
} from 'organizations/assets/js/constants';

const initialState = {
  isLoading: false,
  initialRequestCompleted: false,
  items: [],
};

export const selectSubscriptionsLoading = state => state.subscriptions.isLoading;

export const selectSubscriptionsLoaded = state => state.subscriptions.initialRequestCompleted;

export const selectSubscriptions = (state) => {
  if (state.subscriptions.initialRequestCompleted && state.subscriptions.items) {
    return state.subscriptions.items;
  }
  return [];
};

// Returns the trial period subscription no matter if it is expired or not.
export const selectTrialSubscription = (state) => {
  if (state.subscriptions.initialRequestCompleted && state.subscriptions.items) {
    // if there is only one subscription and it is a trial, then check expiration date.
    const subscriptions = state.subscriptions.items;
    return findLast(subscriptions, s => s.is_trial);
  }

  return null;
};

/**
 * Returns Active (trial or Plan) subscription
 *
 * @param state
 * @return {null|boolean}
 */
export const selectActiveSubscription = (state) => {
  if (state.subscriptions.initialRequestCompleted && state.subscriptions.items) {
    // if there is only one subscription and it is a trial, then check expiration date.
    const subscriptions = state.subscriptions.items;
    return findLast(subscriptions, s => (
      moment(s.started_at).isSameOrBefore(moment(), 'day')
      && (
        s.expires_at === null
        || moment(s.expires_at).isSameOrAfter(moment(), 'day'))
    ));
  }

  return null;
};

/**
 * Returns True if currently active subscription is a trial and has expired.
 *
 * @param state
 * @return {boolean}
 */
export const selectIsTrialExpired = (state) => {
  const trialSubscription = selectTrialSubscription(state);
  const subscriptions = state.subscriptions.items;

  const trialSubscriptionIdx = findIndex(subscriptions, s => s.is_trial);
  // Do not check expiration date if there is another subscription after the trial one.
  const isLatestSubscription = trialSubscriptionIdx === subscriptions.length - 1;
  const isExpires = trialSubscription && moment(trialSubscription.trial_expiration_date || trialSubscription.expires_at).isBefore(moment(), 'day');

  return !!(isLatestSubscription && trialSubscription && isExpires);
};

/**
 * Returns True if organisation is on trial period
 *
 * @param state
 * @return {null|boolean}
 */
export const selectIsOnTrial = (state) => {
  const subscriptions = state.subscriptions.items;

  return (
    subscriptions.length > 0
      && subscriptions[subscriptions.length - 1].is_trial
  );
};

/**
 * Returns true if any of the org subscriptions is not expired.
 *
 * @param state
 * @return {boolean}
 */
export const selectHasActiveSubscription = (state) => {
  if (state.subscriptions.initialRequestCompleted && state.subscriptions.items) {
    // if there is only one subscription and it is a trial, then check expiration date.
    const subscriptions = state.subscriptions.items;
    if (subscriptions.length === 0) {
      return false;
    }

    return findIndex(
      subscriptions,
      s => s.expires_at === null
        || moment(s.expires_at).isSameOrAfter(moment(), 'day')) > -1;
  }

  return false;
};

// Subscriptions list reducer
const subscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS_LIST_FETCH:
      return {
        ...state,
        isLoading: false,
        initialRequestCompleted: true,
        items: action.subscriptions,
      };
    case SUBSCRIPTIONS_LIST_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS_LIST_FETCH:
    case SUBSCRIPTIONS_LIST_IS_LOADING:
      return subscriptionsReducer(state, action);
    default:
      return state;
  }
};

export default subscriptions;
