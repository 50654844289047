import React from 'react';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ProjectTitleSkeleton = () => (
  <>
    <RectangleSkeleton width="110" height="25" />
    <RectangleSkeleton width="45" height="25" />
  </>
);

export default ProjectTitleSkeleton;
