import React from 'react';
import PropTypes from 'prop-types';
import { addResizeToUrl } from 'core/assets/js/lib/utils-jsx';

function RoundedPic({ url, alt, className, 'data-testid': dataTestId, size }) {
  if (url) {
    return (
      <span
        title={alt}
        className={className}
        data-testid={dataTestId}
        style={{
          backgroundImage: `url(${addResizeToUrl(url, size[0])})`,
          width: `${size[0]}px`,
          height: `${size[1]}px`,
          flexBasis: `${size[0]}px`,
        }}
      />
    );
  }
}

RoundedPic.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  alt: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.array,
};

RoundedPic.defaultProps = {
  alt: '',
  className: 'irounded-circle',
  'data-testid': '',
  url: null,
  size: ['40', '40'],
};

export default RoundedPic;
