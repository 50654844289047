import PropTypes from 'prop-types';
import React from 'react';

import { ICON } from 'core/assets/js/constants';

const PermissionMemberCard = ({
  user: {
    userId,
    name,
    roles,
  },
}) => {
  return (
    <div className="permission-user-cards__list-item align-items-center d-flex justify-content-start px-4 py-5 mb-3" key={userId}>
      <i className={`${ICON.USER_DUOTONE} mr-4`} />
      <p className="permission-user-cards__name">{name}</p>
      <p className="permission-user-cards__roles">
        {`(${roles.join(', ')})`}
      </p>
    </div>
  );
};

PermissionMemberCard.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.number,
    name: PropTypes.string,
    roles: PropTypes.array,
  }).isRequired,
};

export default PermissionMemberCard;
