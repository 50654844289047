import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { ICON } from 'core/assets/js/constants';

const TDRating = (props) => {
  const { className, size } = props;
  return (
    <Rating
      {...props}
      className={['rating', className, size].join(' ')}
      emptySymbol={<i className={ICON.STAR} />}
      fullSymbol={<i className={ICON.STAR_FILLED} />}
    />
  );
};

TDRating.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

TDRating.defaultProps = {
  className: '',
  size: '',
};

export default TDRating;
