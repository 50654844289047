import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ChartStackedColumns = ({ chartData, currencySymbol }) => {
  const config = {
    colors: ['#dda354', '#3abc4e'],
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: chartData.xAxisCategories,
      gridLineWidth: 1,
      gridLineDashStyle: 'dash',
      labels: {
        useHTML: true,
        formatter() {
          return chartData.xAxisFormatter(this.value);
        },
      },
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      gridLineDashStyle: 'dash',
      title: {
        text: '',
      },
      stackLabels: {
        enabled: false,
      },
    },
    tooltip: {
      headerFormat: '<b>{point.x.name}</b><br/>',
      pointFormat: `{series.name}: ${currencySymbol}{point.y}`,
      valueDecimals: 2,
    },
    plotOptions: {
      series: {
        pointWidth: '30',
      },
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
          color: 'blue',
        },
      },
    },
    series: chartData.series,
    currencySymbol,
    chart: {
      type: 'column',
      spacingTop: 20,
      scrollablePlotArea: {
        minWidth: chartData.totalEntries * 70,
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={config}
    />
  );
};

ChartStackedColumns.propTypes = {
  chartData: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string,
};

ChartStackedColumns.defaultProps = {
  currencySymbol: '',
};

export default ChartStackedColumns;
