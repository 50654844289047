import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { finalFormFieldMetaSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  PERMISSIONS_GROUPS,
  PERMISSION_GROUPS_LABEL,
  PERMISSION_GROUPS_CONFIGURATION,
  PERMISSION_SUBGROUPS,
  PERMISSION_SUBGROUPS_ICONS,
  PERMISSION_SUBGROUPS_LABEL,
} from 'roles/assets/js/constants';
import { ICON } from 'core/assets/js/constants';
import PermissionSubgroupList from 'roles/assets/js/components/PermissionSubgroupList.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';

export const SUBGROUP_MODAL_ID = 'subgroup-role-modal';

/**
 * Determines if a group is available for an organization
 *
 * @param {Object} activeOrg
 * @param {String} group
 * @returns {Boolean}
 */
const groupIsAvailable = (activeOrg, group) => {
  if (group === PERMISSIONS_GROUPS.INVOICING && !activeOrg.invoice_caps_enabled) {
    return false;
  }
  return true;
};

/**
 * Determines if a subgroup is available for an organization
 * @param {Object} activeOrg
 * @param {String} subgroup
 * @returns {Boolean}
 */
const subgroupIsAvailable = (activeOrg, subgroup) => {
  if (
    subgroup === PERMISSION_SUBGROUPS.CLASSIFICATION_QUESTIONNAIRE
    && !activeOrg.aor_classification_questionnaire_enabled
  ) {
    return false;
  }
  return true;
};

const PermissionsFinalFormField = ({
  input,
  meta: {
    error,
    initial,
    submitError,
  },
  isCreate,
  isSystemRole,
  onPermissionUpdate,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedSubgroup, setSelectedSubgroup] = useState(null);

  useEffect(() => {
    if (initial) {
      setSelectedPermissions(initial);
    }
  }, [initial]);

  useEffect(() => {
    dispatch(modalOpenAC(SUBGROUP_MODAL_ID));
  }, [selectedSubgroup]);

  const formatSubgroupState = (permissions) => {
    if (permissions.every(permission => selectedPermissions.includes(permission))) {
      return (
        <>
          <i className={`${ICON.CIRCLE_CHECK} mr-4`} />
          <span>Full Access</span>
        </>
      );
    }
    if (permissions.some(permission => selectedPermissions.includes(permission))) {
      return (
        <>
          <i className={`${ICON.CIRCLE_HALF_STROKE} mr-4`} />
          <span>Partial Access</span>
        </>
      );
    }

    return (
      <>
        <i className={`${ICON.CIRCLE} mr-4`} />
        <span>No Access</span>
      </>
    );
  };

  const handleSelectSubgroup = (subgroup) => {
    setSelectedSubgroup(subgroup);
  };

  const handleToggleAll = (permissions) => {
    const newValue = [...selectedPermissions];
    if (permissions.every(permission => newValue.includes(permission))) {
      // remove all permissions
      permissions.forEach(permission => {
        const idx = newValue.indexOf(permission);
        newValue.splice(idx, 1);
      });
    } else {
      // add all permissions
      permissions.forEach(permission => {
        if (!newValue.includes(permission)) {
          newValue.push(permission);
        }
      });
    }
    setSelectedPermissions(newValue);
    input.onChange(newValue);

    if (!isCreate) {
      onPermissionUpdate();
    }
  };

  const handleToggle = ({ permission }) => {
    const newValue = [...selectedPermissions];
    if (newValue.includes(permission)) {
      // remove permission
      const idx = newValue.indexOf(permission);
      newValue.splice(idx, 1);
    } else {
      // add permission
      newValue.push(permission);
    }
    setSelectedPermissions(newValue);
    input.onChange(newValue);

    if (!isCreate) {
      onPermissionUpdate();
    }
  };

  return (
    <>
      <div className="roles-form__info w-100 mt-4 mb-4 my-md-0">
        <h2 className="my-0">Permissions</h2>
        <p className="my-0">Select permissions you would like to add for this role</p>
      </div>
      <div className="roles-form__subgroups w-100 w-md-50">

        {PERMISSION_GROUPS_CONFIGURATION.map(group => {
          if (!groupIsAvailable(activeOrg, group.id)) {
            return null;
          }

          return (
            <div
              className="rounded shadow-sm p-4 bg-white mb-5"
              key={group.id}
            >
              <h3 className="mt-2 mb-0">
                {PERMISSION_GROUPS_LABEL[group.id]}
              </h3>

              <hr className="mx-n4 my-5" />

              <ul>

                {group.subgroups.map(subgroup => {
                  if (!subgroupIsAvailable(activeOrg, subgroup.id)) {
                    return null;
                  }

                  return (
                    <li
                      className={`
                      subgroups-permissions-card__list-item
                      align-items-center
                      d-flex
                      justify-content-between
                      mt-3
                      px-4
                      py-5
                      ${selectedSubgroup?.id === subgroup.id ? 'selected' : ''}
                    `}
                      key={subgroup.id}
                      onClick={() => handleSelectSubgroup(subgroup)}
                    >
                      <div className="d-flex align-items-center">
                        <div className={`icon mr-4 icon--${subgroup.id}`}>
                          <i className={`${PERMISSION_SUBGROUPS_ICONS[subgroup.id]}`} />
                          {PERMISSION_SUBGROUPS['1099_FILINGS'] === subgroup.id && (
                            <span>1099</span>
                          )}
                        </div>
                        <div className="subgroups-permissions-card__label">
                          {PERMISSION_SUBGROUPS_LABEL[subgroup.id]}
                        </div>

                      </div>
                      <div className="subgroups-permissions-card__state">
                        {formatSubgroupState(subgroup.permissions)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}

        {error && <span className="text-danger d-inline-block mt-3">{error}</span>}
        {submitError && <span className="text-danger d-inline-block mt-3">{submitError}</span>}
      </div>

      {selectedSubgroup && (
        <PermissionSubgroupList
          handleToggle={handleToggle}
          handleToggleAll={handleToggleAll}
          selectedPermissions={selectedPermissions}
          subgroup={selectedSubgroup}
          isSystemRole={isSystemRole}
        />
      )}
    </>
  );
};

PermissionsFinalFormField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: finalFormFieldMetaSpec,
  isCreate: PropTypes.bool.isRequired,
  isSystemRole: PropTypes.bool,
  onPermissionUpdate: PropTypes.func,
};

PermissionsFinalFormField.defaultProps = {
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  isSystemRole: false,
  onPermissionUpdate: () => {},
};

export default PermissionsFinalFormField;
