import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import IntegrationActivity from 'integrations/assets/js/components/tabs/IntegrationActivity.jsx';
import IntegrationSettings from 'integrations/assets/js/components/tabs/IntegrationSettings.jsx';
import IntegrationMapping from 'integrations/assets/js/components/tabs/IntegrationMapping.jsx';
import IntegrationSupplierMapping from 'integrations/assets/js/components/tabs/IntegrationSupplierMapping.jsx';
import { INTEGRATION_DETAILS_TABS } from 'integrations/assets/js/constants';
import { orgIntegrationDetailUrl } from 'integrations/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const IntegrationDetailsTab = ({ tab, history, integration, orgAlias, parentComponentName }) => {
  switch (tab) {
    case INTEGRATION_DETAILS_TABS.ACTIVITY:
      return (
        <IntegrationActivity
          integration={integration}
          parentComponentName={parentComponentName}
        />
      );
    case INTEGRATION_DETAILS_TABS.SETTINGS:
      return (
        <IntegrationSettings
          integration={integration}
          parentComponentName={parentComponentName}
        />
      );
    case INTEGRATION_DETAILS_TABS.ACCOUNT_MAPPING:
      return (
        <IntegrationMapping
          integration={integration}
          parentComponentName={parentComponentName}
          tab={INTEGRATION_DETAILS_TABS.ACCOUNT_MAPPING}
        />
      );
    case INTEGRATION_DETAILS_TABS.INVOICE_MAPPING:
      return (
        <IntegrationMapping
          integration={integration}
          parentComponentName={parentComponentName}
          tab={INTEGRATION_DETAILS_TABS.INVOICE_MAPPING}
        />
      );
    case INTEGRATION_DETAILS_TABS.SUPPLIER_MAPPING:
      return (
        <IntegrationSupplierMapping
          integration={integration}
          parentComponentName={parentComponentName}
        />
      );
    default:
      history.push(
        orgIntegrationDetailUrl(orgAlias, integration.id, INTEGRATION_DETAILS_TABS.ACTIVITY),
      );
      return null;
  }
};

IntegrationDetailsTab.propTypes = {
  history: routerHistorySpec.isRequired,
  integration: integrationSpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default withRouter(IntegrationDetailsTab);
