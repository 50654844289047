import React from 'react';
import PropTypes from 'prop-types';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import { ORG_SETTINGS_PAYMENTS_TABS, SALES_TAX_TOOLTIP_MSG } from 'settings/assets/js/constants';
import { orgSettingsPaymentsUrl } from 'settings/urls';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { BS_TOOLTIP_PLACEMENT, ICON } from 'core/assets/js/constants';

/**
 * This is the tabs component we use on Organization -> Settings -> Payments
 * @returns {*}
 */
const OrgSettingsPaymentsTabs = ({ orgAlias, tab }) => {
  const tabSpec = [
    {
      key: ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
      label: 'Payment methods',
      href: orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS),
    },
    {
      key: ORG_SETTINGS_PAYMENTS_TABS.SALES_TAX,
      label: (
        <div>
          <span>Sales tax</span>
          <TDElementWithTooltip
            placement={BS_TOOLTIP_PLACEMENT.BOTTOM}
            tooltipMsg={SALES_TAX_TOOLTIP_MSG}
            el={(
              <span className="ml-2">
                <i className={ICON.INFO} />
              </span>
            )}
          />
        </div>
      ),
      href: orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_PAYMENTS_TABS.SALES_TAX),
    },
    {
      key: ORG_SETTINGS_PAYMENTS_TABS.INVOICES,
      label: 'Invoices',
      href: orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_PAYMENTS_TABS.INVOICES),
    },
  ];

  return (
    <div className="horizontal-scroll">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

OrgSettingsPaymentsTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

export default OrgSettingsPaymentsTabs;
