export const notificationListUrl = (orgAlias, url = '') => (
  `${url}/${orgAlias}/notifications`
);

export const fetchNotificationsApiUrl = (url = '') => (
  `${url}/api/notifications`
);

export const fetchNotificationsSummariesApiUrl = (url = '') => (
  `${url}/api/notifications/summaries`
);

export const fetchPreviewApiUrl = (type, url = '') => (
  `${url}/api/notifications/preview/${type}`
);

export const fetchPreviewByIdApiUrl = (id, url = '') => (
  `${url}/api/notifications/preview/instance/${id}`
);

export const markReadNotificationApiUrl = (url = '') => (
  `${url}/api/notifications/mark-read`
);

export const markAllNotificationsAsReadApiUrl = (url = '') => (
  `${url}/api/notifications/mark-all-as-read`
);

export const markAsSeenNotificationApiUrl = (url = '') => (
  `${url}/api/notifications/mark-seen`
);

export const activityLogApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/activity/project/${projectId}`
);
