import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { maxBy, uniqBy, keyBy } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { TASK_ACTION } from 'projects/assets/js/constants';
import { getRequestState } from 'core/assets/js/ducks/requests';
import { routerMatchSpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { projectTaskSpec, projectSpec, taskParticipantSpec } from 'projects/assets/js/lib/objectSpecs';
import { fetchProjectTaskMessagesDS } from 'projects/assets/js/data-services/tasks';
import { getListState, getListStateExtras, listUpdateExtrasItem } from 'core/assets/js/ducks/list';
import FormsWrapper from 'core/assets/js/components/DiscussionBoard/FormsWrapper.jsx';
import ChatHeader from 'core/assets/js/components/DiscussionBoard/ChatHeader.jsx';
import ParticipantsList from 'core/assets/js/components/DiscussionBoard/ParticipantsList.jsx';
import MessagesList from 'core/assets/js/components/DiscussionBoard/messages/MessagesList.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import { DOCUMENT_QUERY_SELECTOR } from 'core/assets/js/config/settings';

export const COMPONENT_NAME = 'task-discussion-board';
const FOCUS_DELAY = 300;

// Actions that are available in every discussion
const OMNIPRESENT_ACTIONS = [
  TASK_ACTION.ASSIGN,
  TASK_ACTION.REQUEST_COMPLETION,
  TASK_ACTION.COMPLETE,
  TASK_ACTION.STOP,
  TASK_ACTION.LEAVE,
  TASK_ACTION.START_PROJECT,
];

class DiscussionBoard extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, querystring, componentName }) {
    const { orgAlias, id: projectId, taskId, userId: participantId } = params;
    const { page } = queryString.parse(querystring);

    if (!taskId) {
      return new Promise((resolve) => { resolve(); });
    }

    return dispatch(
      fetchProjectTaskMessagesDS({
        orgAlias,
        projectId,
        taskId,
        authedAxios,
        componentName,
        participantId,
        page,
        url,
      }),
    );
  }

  static GetComponentName({ params: { userId } }) {
    return userId ? `${COMPONENT_NAME}-participant-${userId}` : COMPONENT_NAME;
  }

  constructor(props) {
    super(props);

    this.scrollToEndOfMessages = this.scrollToEndOfMessages.bind(this);
    this.getSelectedParticipant = this.getSelectedParticipant.bind(this);
    this.fetchNextPage = this.fetchNextPage.bind(this);
    this.handleStateUpdated = this.handleStateUpdated.bind(this);
    this.handleAttachmentUpdated = this.handleAttachmentUpdated.bind(this);
    this.getLastMessage = this.getLastMessage.bind(this);
    this.getHasNextPage = this.getHasNextPage.bind(this);
    this.getSelectedParticipant = this.getSelectedParticipant.bind(this);
    this.getSelectedParticipantId = this.getSelectedParticipantId.bind(this);
    this.getActionsForCurrentDiscussion = this.getActionsForCurrentDiscussion.bind(this);
  }

  componentDidMount() {
    const { onDiscussionSelected } = this.props;
    setTimeout(this.scrollToEndOfMessages, FOCUS_DELAY);
    onDiscussionSelected(this.getSelectedParticipant());
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search: previousSearch, pathname: previousPathname },
      match: { params: { userId: previousUserId } },
    } = prevProps;

    const {
      location: { search, pathname },
      match: { params: { userId } },
      onDiscussionSelected,
    } = this.props;

    if (previousPathname !== pathname || previousSearch !== search) {
      setTimeout(this.scrollToEndOfMessages, FOCUS_DELAY);
    }

    if (previousUserId !== userId) {
      onDiscussionSelected(this.getSelectedParticipant());
    }
  }

  /**
   * @returns {Boolean} whether there are more pages to load
   */
  getHasNextPage() {
    const { pagination: { page, pages } } = this.props;
    return page < pages;
  }

  /**
   * @returns {Object} the most recent message in the list
   */
  getLastMessage() {
    const { messages } = this.props;
    return maxBy(messages, 'id');
  }

  /**
   * @returns {Number} the id of the participant we're currently browsing
   */
  getSelectedParticipantId() {
    const { match: { params: { userId } }, activeParticipant } = this.props;
    if (activeParticipant) {
      return activeParticipant;
    }
    return userId ? parseInt(userId, 10) : null;
  }

  /**
   * @returns {Object} the participant that the current discussion is with
   */
  getSelectedParticipant() {
    const { participants } = this.props;
    const userId = this.getSelectedParticipantId();
    return userId ? participants.find(p => p.user.id === +userId) : null;
  }

  /**
   * @returns {Array} the actions for the current discussion
   */
  getActionsForCurrentDiscussion() {
    const participantId = this.getSelectedParticipantId();
    const { actions, hasOrgAccess, isEmbeddedMode, task } = this.props;
    const isProvider = hasOrgAccess({ requireProvider: true });

    let actionsPerParticipant = [];
    if (isEmbeddedMode && participantId && isProvider) {
      // This is a task assignee discussing the rate for their pending task assignment. So we need
      // to include any rate actions initiated by the current task owner or any task managers
      const taskOwnerOrManagers = [task.ownerId, ...task.managers.map(u => u.id)];
      const taskRateNames = [
        TASK_ACTION.ACCEPT_RATE_INVITATION,
        TASK_ACTION.REJECT_RATE_INVITATION,
        TASK_ACTION.PROPOSE_TASK_RATE,
      ];
      actionsPerParticipant = actions.filter(act => (
        act.targetUserId === participantId
        || (taskRateNames.includes(act.name) && taskOwnerOrManagers.includes(act.targetUserId))
      ));
    } else {
      actionsPerParticipant = actions.filter(
        act => (participantId ? act.targetUserId === participantId : !act.targetUserId),
      );
    }

    const omniPresentActions = actions.filter(act => OMNIPRESENT_ACTIONS.includes(act.name));

    return uniqBy(
      [...actionsPerParticipant, ...omniPresentActions],
      act => `${act.name}-${act.targetUserId}`,
    );
  }

  async fetchNextPage() {
    const {
      componentName,
      dispatch,
      isEmbeddedMode,
      match: { params: { orgAlias } },
      pagination: { page },
      task,
    } = this.props;

    let {
      match: { params: { id: projectId, taskId } },
    } = this.props;

    if (isEmbeddedMode) {
      projectId = task.projectId;
      taskId = task.id;
    }

    const participantId = this.getSelectedParticipantId();

    if (this.getHasNextPage()) {
      await dispatch(
        fetchProjectTaskMessagesDS({
          orgAlias, projectId, taskId, componentName, participantId, page: (page + 1),
        }),
      );
    }
  }

  async handleAttachmentUpdated(attachment) {
    const { dispatch, componentName, onInfoUpdated } = this.props;
    dispatch(listUpdateExtrasItem(attachment, componentName, 'taskAttachments'));
    // reload task info, to update deliverablesCount
    onInfoUpdated();
  }

  async handleStateUpdated() {
    const {
      componentName,
      dispatch,
      isEmbeddedMode,
      match: { params: { orgAlias } },
      onInfoUpdated,
      onStateUpdated,
      task,
    } = this.props;

    let {
      match: { params: { id: projectId, taskId } },
    } = this.props;

    if (isEmbeddedMode) {
      projectId = task.projectId;
      taskId = task.id;
    }


    const lastMessage = this.getLastMessage() || {};
    const participantId = this.getSelectedParticipantId();

    await Promise.all([
      onStateUpdated(),
      onInfoUpdated(),
      dispatch(
        fetchProjectTaskMessagesDS({
          orgAlias, projectId, taskId, componentName, participantId, after: lastMessage.id,
        }),
      ),
    ]);
  }

  /**
   * Scroll to the bottom of messages section
   *
   *
   * @private
   */
  scrollToEndOfMessages() { // eslint-disable-line class-methods-use-this
    const targetEl = DOCUMENT_QUERY_SELECTOR(
      '.discussion-board_messages-list .discussion-board__row:last-child',
    );

    if (targetEl) {
      targetEl.scrollIntoView();
    }
  }

  render() {
    const {
      accessControl,
      actions,
      hasPendingDocuments,
      isEmbeddedMode,
      match,
      messages,
      messagesLoading,
      onSignAndAccept,
      onTaskUpdated,
      participants,
      project,
      showParticipantsList,
      stateName,
      task,
      taskAttachments,
    } = this.props;

    const selectedParticipant = this.getSelectedParticipant();
    const hasMoreMessages = this.getHasNextPage();
    const participantActions = this.getActionsForCurrentDiscussion();

    return (
      <div className="discussion-board h-100 bg-white shadow-sm w-100 mb-4">
        {showParticipantsList && (
          <ParticipantsList
            participants={participants}
            selectedParticipant={selectedParticipant}
            accessControl={accessControl}
            actions={actions}
          />
        )}

        <div className="discussion-board__chat-container">
          { selectedParticipant && (
            <ChatHeader
              task={task}
              selectedParticipant={selectedParticipant}
            />
          )}

          <div className="discussion-board__content">
            <TDApiConnected
              duck="list"
              fetchData={this.constructor.FetchData}
              storeKey={this.constructor.GetComponentName(match)}
              blockingLoading={false}
              shouldRefetchOnQueryChange={false}
              skeletonComponent={ProjectListSkeleton}
            >
              <MessagesList
                accessControl={accessControl}
                actions={participantActions}
                hasMoreMessages={hasMoreMessages}
                isEmbeddedMode={isEmbeddedMode}
                messages={messages}
                hasPendingDocuments={hasPendingDocuments}
                messagesLoading={messagesLoading}
                onAttachmentUpdated={this.handleAttachmentUpdated}
                onLoadMoreMessages={this.fetchNextPage}
                onSignAndAccept={onSignAndAccept}
                onStateUpdated={this.handleStateUpdated}
                onTaskUpdated={onTaskUpdated}
                parentComponentName={this.constructor.GetComponentName(match)}
                project={project}
                stateName={stateName}
                task={task}
                taskAttachments={keyBy(taskAttachments, 'handle')}
              />

              <FormsWrapper
                accessControl={accessControl}
                actions={participantActions}
                isEmbeddedMode={isEmbeddedMode}
                onFormToggle={this.scrollToEndOfMessages}
                onStateUpdated={this.handleStateUpdated}
                onTaskUpdated={onTaskUpdated}
                parentComponentName={this.constructor.GetComponentName(match)}
                participant={selectedParticipant}
                project={project}
                task={task}
              />
            </TDApiConnected>
          </div>
        </div>
      </div>
    );
  }
}

DiscussionBoard.propTypes = {
  accessControl: PropTypes.object,
  actions: PropTypes.array,
  activeParticipant: PropTypes.number,
  componentName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  hasPendingDocuments: PropTypes.bool,
  isEmbeddedMode: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  messagesLoading: PropTypes.bool.isRequired,
  onDiscussionSelected: PropTypes.func,
  onInfoUpdated: PropTypes.func,
  onSignAndAccept: PropTypes.func,
  onStateUpdated: PropTypes.func.isRequired,
  onTaskUpdated: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  participants: PropTypes.arrayOf(taskParticipantSpec),
  project: projectSpec.isRequired,
  showParticipantsList: PropTypes.bool,
  stateName: PropTypes.string,
  task: projectTaskSpec.isRequired,
  taskAttachments: PropTypes.array,
};

DiscussionBoard.defaultProps = {
  accessControl: {},
  actions: [],
  activeParticipant: null,
  hasPendingDocuments: false,
  isEmbeddedMode: false,
  onDiscussionSelected: () => {},
  onInfoUpdated: () => {},
  onSignAndAccept: () => null,
  participants: [],
  showParticipantsList: true,
  stateName: null,
  taskAttachments: [],
};

const mapStateToProps = (state, props) => {
  const { match: { params }, location: { search } } = props;
  const componentName = DiscussionBoard.GetComponentName({ params, search });

  return {
    componentName,
    hasOrgAccess: getHasOrgAccess(state),
    pagination: getListState(state, componentName).pagination,
    messages: getListState(state, componentName).items,
    messagesLoading: getRequestState(state, componentName).isLoading,
    taskAttachments: getListStateExtras(state, componentName, 'taskAttachments'),
  };
};


const mapDispatchToProps = dispatch => ({ dispatch });

const DiscussionBoardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscussionBoard);

export default withRouter(DiscussionBoardConnected);
