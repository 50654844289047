import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { fetchIntegrationListDS } from 'integrations/assets/js/data-services/list';
import { removeIntegrationDS } from 'integrations/assets/js/data-services/view';
import IntegrationsListView from 'integrations/assets/js/IntegrationsListView.jsx';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import { orgIntegrationsUrl } from 'integrations/urls';

export const MODAL_ID = 'INTEGRATION_CONFIRM_DELETE_MODAL_ID';

const IntegrationConfirmDeleteModal = ({
  dispatch,
  history,
  integration,
  isOpen,
  match: { params: { orgAlias } },
}) => (
  <ModalConfirm
    confirmLabel="Permanently delete"
    confirmStyle={BS_STYLE.DANGER}
    heading={`Delete "${integration.name}" integration?`}
    onClose={() => dispatch(modalCloseAC())}
    onConfirm={async () => {
      try {
        await removeIntegrationDS({ integrationId: integration.id, orgAlias });
        await dispatch(fetchIntegrationListDS({
          componentName: IntegrationsListView.GetComponentName(), orgAlias,
        }));
        toastr.success('Well Done!', 'The integration was deleted successfully');
        history.push(orgIntegrationsUrl(orgAlias));
      } catch (err) {
        toastr.error('Oh Snap!', 'Something went wrong, please refresh and try again');
      }
    }}
    open={isOpen}
  >
    <p>Are you sure you want to delete this integration?</p>
    <b>This will delete all data associated with this integration. This cannot be reversed.</b>
  </ModalConfirm>
);

IntegrationConfirmDeleteModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  integration: integrationSpec.isRequired,
  isOpen: PropTypes.bool,
  match: routerMatchContentsSpec.isRequired,
};

IntegrationConfirmDeleteModal.defaultProps = {
  isOpen: false,
};

const mapStateToProps = state => ({
  isOpen: getIsModalOpen(state, MODAL_ID),
});
const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationConfirmDeleteModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationConfirmDeleteModal);

export default withRouter(IntegrationConfirmDeleteModalConnected);
