/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import RatingField from 'core/assets/js/components/ReduxFormFields/RatingField.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

export const FORM_ID = 'DiscussionBoardReviewForm';

const ReviewForm = ({ assigneeName, handleSubmit, pristine, submitting }) => (
  <form className="db-review-form" onSubmit={handleSubmit}>
    <Field
      name="rating"
      type="rating"
      onChange={() => {}}
      component={RatingField}
    />

    <Field
      name="user_id"
      type="hidden"
      component={InputField}
    />

    <Field
      name="message"
      type="textarea"
      component={InputField}
      label={`How would you rate ${assigneeName}’s work on this task?`}
      placeholder={`Describe how well did ${assigneeName} handle this task...`}
      autoFocus
    />


    <div className="text-right mt-4">
      <TDButton
        className={pristine ? 'btn--pristine' : 'btn--dirty'}
        type="submit"
        variant={BS_STYLE.PRIMARY}
        disabled={submitting}
        label="Submit your review"
      />
    </div>
  </form>
);

ReviewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  assigneeName: PropTypes.string.isRequired,
};

const ConnectedReviewForm = reduxForm({
  form: FORM_ID,
})(ReviewForm);

export default ConnectedReviewForm;
