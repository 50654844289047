import React from 'react';
import PropTypes from 'prop-types';
import Big from 'big.js';

import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { isAmount } from 'core/assets/js/lib/utils';


const InputNumberField = ({
  className,
  'data-testid': dataTestId,
  decimals,
  disabled,
  input,
  inputClassName,
  label,
  max,
  meta: { error, submitError },
  min,
  muteErrorMessage,
  placeholder,
  prefix,
  required,
  step,
  sublabel,
  suffix,
}) => {
  const hasError = (submitError || error) && !muteErrorMessage;
  const classNames = ['form-group'];

  if (className) {
    classNames.push(className);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  let field = (
    <input
      className={`form-control${inputClassName ? ` ${inputClassName}` : ''}`}
      placeholder={placeholder}
      autoComplete="off"
      disabled={disabled}
      min={min}
      max={max}
      step={step}
      {...input}
      type="number"
      onBlur={(e) => {
        if (isAmount(e.target.value)) {
          if (decimals) {
            e.target.value = new Big(e.target.value).toFixed(decimals);
          }
        } else {
          // If the value includes any of the chars '-', 'e', 'E', just empty the value.
          // Notice that the above characters are valid for the input with type number.
          e.target.value = '';
        }

        input.onChange(e);
        input.onBlur();
      }}
      // Prevent scrolling on the input, to prevent accidental increment/decrement
      onWheel={e => e.target.blur()}
      id={`field-${input.name}`}
    />
  );

  if (prefix || suffix) {
    field = (
      <div className="input-group">
        {prefix && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prefix}</span>
          </div>
        )}
        {field}
        {suffix && (
          <div className="input-group-append">
            <span className="input-group-text">{suffix}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />
      {field}
      {error && <span className="help-block d-inline-block mt-3">{error}</span>}
      {hasError && <span className="help-block d-inline-block mt-3">{submitError}</span>}
    </div>
  );
};

InputNumberField.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  decimals: PropTypes.number,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  inputClassName: PropTypes.string,
  label: finalFormFieldLabelSpec,
  meta: finalFormFieldMetaSpec,
  max: PropTypes.number,
  min: PropTypes.number,
  muteErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
};

InputNumberField.defaultProps = {
  className: null,
  'data-testid': 'number-input-field',
  decimals: null,
  disabled: false,
  inputClassName: null,
  label: '',
  max: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  min: 0,
  muteErrorMessage: false,
  placeholder: '',
  prefix: null,
  required: false,
  step: 1,
  sublabel: null,
  suffix: null,
};

export default withField(InputNumberField);
