import axios from 'core/assets/js/lib/tdAxios';
import {
  invitationValidateApiUrl,
  invitationApiUrl,
  invitationRejectApiUrl,
  invitationAcceptApiUrl,
  publicInvitationJoinApiUrl,
} from 'invitations/urls';

// Action types
export const INVITATION_ACTIVE_IS_LOADING = 'redux-invitation/IS_LOADING';
export const INVITATION_ACTIVE_LOAD_SUCCESS = 'redux-invitation/LOAD_SUCCESS';
export const INVITATION_ACTIVE_REJECT_SUCCESS = 'redux-invitation/REJECT_SUCCESS';
export const INVITATION_ACTIVE_ACCEPT_SUCCESS = 'redux-invitation/ACCEPT_SUCCESS';
export const INVITATION_ACTIVE_LOAD_FAILURE = 'redux-invitation/LOAD_FAILURE';
export const INVITATION_ACTIVE_RESET = 'redux-invitation/RESET';


/**
 * Handles the invitation view related state
 * @param state
 * @param action
 * @returns {*}
 */
const invitationActiveInitial = {
  isLoading: false,
  isValid: null,
  item: {},
  storeComponentName: '',
  hasLoaded: false,
};

const invitationActive = (state = invitationActiveInitial, action) => {
  switch (action.type) {
    case INVITATION_ACTIVE_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasLoaded: !action.isLoading,
        storeComponentName: action.componentName || state.storeComponentName,
      };
    case INVITATION_ACTIVE_LOAD_SUCCESS:
      return {
        ...state,
        item: action.item,
        isLoading: false,
        isValid: true,
        storeComponentName: action.componentName,
        hasLoaded: true,
      };
    case INVITATION_ACTIVE_ACCEPT_SUCCESS:
      return {
        ...state,
        item: action.item,
        isLoading: false,
        hasLoaded: true,
        isValid: true,
      };
    case INVITATION_ACTIVE_REJECT_SUCCESS:
      return {
        ...state,
        item: action.item,
        isLoading: false,
        hasLoaded: true,
        isValid: true,
      };
    case INVITATION_ACTIVE_LOAD_FAILURE:
      return {
        ...state,
        item: {},
        isLoading: false,
        hasLoaded: true,
        isValid: false,
        storeComponentName: action.componentName || state.storeComponentName,
      };
    case INVITATION_ACTIVE_RESET:
      return invitationActiveInitial;
    default:
      return state;
  }
};


/**
 * Expected state structure
 * {
 *   invitations: {
 *     invitationActive: {
 *        isValid: false,
 *        isLoading: false,
 *        item: {}
 *     }
 *   }
 * }
 * @param state
 * @param action
 * @returns {*}
 */
const initial = {
  invitationActive: invitationActiveInitial,
};
const invitations = (state = initial, action) => {
  let newState;
  switch (action.type) {
    case INVITATION_ACTIVE_IS_LOADING:
    case INVITATION_ACTIVE_LOAD_SUCCESS:
    case INVITATION_ACTIVE_REJECT_SUCCESS:
    case INVITATION_ACTIVE_ACCEPT_SUCCESS:
    case INVITATION_ACTIVE_LOAD_FAILURE:
    case INVITATION_ACTIVE_RESET:
      newState = {
        ...state,
        invitationActive: invitationActive(state.invitationActive, action),
      };
      return newState;
    default:
      return state;
  }
};


export const invitationIsLoadingAC = (bool, componentName) => ({
  type: INVITATION_ACTIVE_IS_LOADING,
  componentName,
  isLoading: bool,
});

export const invitationLoadSuccessAC = (invitation, componentName) => ({
  type: INVITATION_ACTIVE_LOAD_SUCCESS,
  componentName,
  item: invitation,
});

export const invitationLoadFailureAC = (error, componentName) => {
  const action = {
    type: INVITATION_ACTIVE_LOAD_FAILURE,
    componentName,
    error,
  };
  return action;
};

export const invitationRejectSuccessAC = invitation => ({
  type: INVITATION_ACTIVE_REJECT_SUCCESS,
  item: invitation,
});

export const invitationAcceptSuccessAC = invitation => ({
  type: INVITATION_ACTIVE_ACCEPT_SUCCESS,
  item: invitation,
});

export const invitationResetAC = () => ({
  type: INVITATION_ACTIVE_RESET,
});


// SELECTORS

export const selectActiveInvitation = state => (
  state.invitation && state.invitation.invitationActive
    ? state.invitation.invitationActive
    : invitationActiveInitial
);


// DATA SERVICES

export const validateInvitationActiveDS = ({
  token, url = '', authedAxios = null, componentName,
}) =>
  (dispatch) => {
    const apiUrl = invitationValidateApiUrl(url);
    return (authedAxios || axios).post(apiUrl, { token })
      .then(resp =>
        dispatch(invitationLoadSuccessAC(resp.data, componentName)),
      )
      .catch((err) => {
        dispatch(invitationLoadFailureAC(err, componentName));
      });
  };

export const fetchInvitationActiveDS = ({
  token, url = '', authedAxios = null, componentName,
}) =>
  (dispatch) => {
    const apiUrl = invitationApiUrl(token, url);
    return (authedAxios || axios).get(apiUrl)
      .then(resp =>
        dispatch(invitationLoadSuccessAC(resp.data, componentName)),
      )
      .catch((err) => {
        dispatch(invitationLoadFailureAC(err));
      });
  };

export const rejectInvitationActiveDS = (token, url = '') =>
  (dispatch) => {
    dispatch(invitationIsLoadingAC(true));
    const apiUrl = invitationRejectApiUrl(token, url);
    return axios.post(apiUrl, { token })
      .then(resp => dispatch(invitationRejectSuccessAC(resp.data)))
      .catch((err) => {
        dispatch(invitationLoadFailureAC(err));
      });
  };

export const acceptInvitationActiveDS = (token, url = '') =>
  (dispatch) => {
    dispatch(invitationIsLoadingAC(true));
    const apiUrl = invitationAcceptApiUrl(token, url);
    return axios.post(apiUrl, { token })
      .then((resp) => {
        dispatch(invitationAcceptSuccessAC(resp.data));
        return resp.data;
      })
      .catch((err) => {
        dispatch(invitationLoadFailureAC(err));
      });
  };

export const usePublicInvitationDS = (token, values, url = '') => {
  const apiUrl = publicInvitationJoinApiUrl(token, url);
  return axios.post(apiUrl, values);
};

export default invitations;
