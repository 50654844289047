import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import UserGroupForm from 'people/assets/js/components/UserGroupForm.jsx';
import { CREATE_GROUP_TYPE } from 'people/assets/js/constants';
import { userGroupCreateUrl, userGroupsUrl } from 'people/urls';

const UserGroupCreateView = () => {
  const history = useHistory();
  const { orgAlias, type } = useParams();
  const [selectedType, setSelectedType] = useState(type);

  let breadCrumbTitle = 'Select which type of group to create';
  const selectingType = type === CREATE_GROUP_TYPE.SELECT;
  if (!selectingType) {
    breadCrumbTitle = `Create ${type} group`;
  }

  const listUrl = userGroupsUrl(orgAlias);

  return (
    <>
      <ContentHeader
        breadcrumbs={[{ title: 'Groups', url: listUrl }, { title: breadCrumbTitle, url: null }]}
      />
      <div className="page page--people">
        <div className="container">
          {selectingType && (
            <>
              <div className="rounded shadow-sm p-4 bg-white">
                <CheckableList
                  className="user-group-create-select-type"
                  items={[
                    {
                      icon: ICON.USERS_DUOTONE,
                      id: CREATE_GROUP_TYPE.STANDARD,
                      subtext: (
                        'This group consists of members that are manually added to this group.'
                      ),
                      title: 'Standard group',
                    },
                    {
                      icon: ICON.USER_TAG_DUOTONE,
                      id: CREATE_GROUP_TYPE.DYNAMIC,
                      subtext: "This group consists of members that match group's skills. Members "
                        + 'will be automatically added or removed from this group.',
                      title: 'Dynamic group',
                    },
                  ]}
                  itemComponent={({ item: { icon, subtext, title } }) => (
                    <div
                      className="d-flex flex-column align-items-center justify-content-center py-5"
                    >
                      <i className={`${icon} fa-4x`} />
                      <h4>{title}</h4>
                      <p className="text-center d-none d-lg-block">{subtext}</p>
                    </div>
                  )}
                  onItemChecked={value => setSelectedType(value.id)}
                />
              </div>
              <div className="d-flex align-items-center justify-content-end mt-5">
                <TDButton
                  label="Cancel"
                  onClick={() => history.push(listUrl)}
                />
                <TDButton
                  disabled={!selectedType}
                  label="Select"
                  onClick={() => history.push(userGroupCreateUrl(orgAlias, selectedType))}
                  variant={BS_STYLE.PRIMARY}
                />
              </div>
            </>
          )}
          {!selectingType && <UserGroupForm type={selectedType} />}
        </div>
      </div>
    </>
  );
};

UserGroupCreateView.GetComponentName = () => 'UserGroupCreateView';

export default UserGroupCreateView;
