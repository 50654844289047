/* eslint-disable react/no-multi-comp */

import React from 'react';
import { Form } from 'react-final-form';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ORG_SETTINGS_COMMON_FOOTNOTES_LABEL, ORG_SETTINGS_COMMON_FOOTNOTES_CONTENT, REVERSE_CHARGE_PLACEHOLDER } from 'settings/assets/js/constants';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import { BS_STYLE, ICON, IMG_SIZE, USER_TYPE } from 'core/assets/js/constants';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import {
  fetchUserCardWithVatDS,
  postUserCardDS,
} from 'settings/assets/js/data-services/organizations';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import TDToggleWithConfirmationModal
  from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import { INVOICING_MODE } from 'finance/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const CommonRegulationsTemplateSelect = ({ onHandleSelect }) => (
  <TDDropButton
    toggleEl={(
      <div className="imitate-link">
        Common regulations
        {' '}
        <i className={ICON.CHEVRON_DOWN} />
      </div>
    )}
  >
    <div className="scrollable-list">
      { Object.keys(ORG_SETTINGS_COMMON_FOOTNOTES_LABEL).map(key => (
        <Dropdown.Item
          className="py-3"
          eventKey={key}
          key={key}
          onClick={() => onHandleSelect(ORG_SETTINGS_COMMON_FOOTNOTES_CONTENT[key])}
        >
          <span className="text-truncate">{ORG_SETTINGS_COMMON_FOOTNOTES_LABEL[key]}</span>
        </Dropdown.Item>
      ))}
    </div>
  </TDDropButton>
);

CommonRegulationsTemplateSelect.propTypes = {
  onHandleSelect: PropTypes.func.isRequired,
};

const EnablePaymentDetailsModalBody = () => (
  <>
    <p>
      By activating this setting, your payment details will be displayed
      on your invoices.
    </p>
    <p className="mb-4">Are you sure you want to activate this setting?</p>
  </>
);

const DisablePaymentDetailsModalBody = () => (
  <>
    <p>
      By deactivating this setting, your payment details will not be displayed
      on your invoices.
    </p>
    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
  </>
);

export const COMPONENT_NAME = 'OrgSettingsPaymentsInvoices';

class OrgSettingsPaymentsInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectRegulation = this.handleSelectRegulation.bind(this);
  }

  /**
   * This is a React Final Form mutator function
   * It updates the invoicing_footnote value based on the value the user selected
   * from the CommonRegulationTemplateSelect values
   * @param {Array} selectedText - the value selected by the user
   * @param {Object} state - React Final Form state
   * @param {Function} changeValue - Used to change a RFF's field value
   */
  handleSelectRegulation([selectedText], state, { changeValue }) {
    const regulationTextFieldName = 'invoicing_footnote';
    const oldText = state.fields[regulationTextFieldName].lastFieldState.value;
    const newText = `${oldText ? `${oldText} \n` : ''}${selectedText}`;
    changeValue(state, regulationTextFieldName, () => newText);
  }

  render() {
    const {
      item: { invoicingFootnote, userRole, hideInvoicePaymentDetails },
      dispatch,
      match: { params: { orgAlias } },
      activeOrg,
    } = this.props;
    const isProvider = userRole?.ofType === USER_TYPE.PROVIDER;
    const orgInvoicingMode = activeOrg.invoicing_mode;
    const isViaMode = orgInvoicingMode === INVOICING_MODE.VIA;
    const hidePaymentDetailsOrgLevel = activeOrg.hide_providers_invoice_payment_details;

    const showPaymentDetailsSetting = !hidePaymentDetailsOrgLevel && isViaMode;
    const reverseChargePlaceholder = `e.g Reverse Charge - According to Article 194, 196 of Council Directive 2006/112/EEC, VAT liability rests with the service recipient ${REVERSE_CHARGE_PLACEHOLDER}.`;

    return (
      <Form
        mutators={{ handleSelectRegulation: this.handleSelectRegulation }}
        onSubmit={values => (
          dispatch(postUserCardDS(orgAlias, {
            invoicing_footnote: values.invoicing_footnote,
          }, COMPONENT_NAME))
        )}
        initialValues={{
          invoicing_footnote: invoicingFootnote,
        }}
        render={({ handleSubmit, pristine, form }) => (
          <React.Fragment>
            { showPaymentDetailsSetting && (
              <div
                className="clearfix rounded shadow-sm p-4 bg-white mb-4"
                data-testid="payment-details-container"
              >
                <h3 className="heading-block">Payment Details</h3>
                {isProvider && (
                  <TDToggleWithConfirmationModal
                    containerClassName="px-3"
                    enableHeader="Display payment details setting is deactivated"
                    disableHeader="Display payment details setting is activated"
                    label="Do not display payment details on my invoices"
                    modalId="hide-invoice-payment-details-setting"
                    selected={hideInvoicePaymentDetails}
                    onToggled={value => (
                      dispatch(postUserCardDS(orgAlias, {
                        hide_invoice_payment_details: value,
                      }, COMPONENT_NAME))
                    )}
                    DisableModalBody={DisablePaymentDetailsModalBody}
                    EnableModalBody={EnablePaymentDetailsModalBody}
                    sublabel="By default, your payment details are displayed on your invoices to faciliate payment. You can enable this option and your payment details will not be displayed. You will still receive your payments as normal."
                  />
                )}
              </div>
            )}
            <p className="mb-4">
              This is an optional section for you to add any regulatory compliance text as a
              footnote to the invoice, if you think it is needed. For example, adding text
              applicable to the EU&apos;s common system of value added tax. Please note it
              is your responsibility to add any text based on applicable regulations.
            </p>
            <div className="rounded shadow-sm p-4 bg-white">
              <form onSubmit={handleSubmit} className="px-0">
                <div className="row">
                  <div className="col-12">
                    <TextAreaField
                      name="invoicing_footnote"
                      type="text"
                      label="Regulatory compliance text"
                      sublabel="Based on the payment regulations of your country or jurisdiction, you can add a footnote to your invoice here"
                      templateSelectComponent={(
                        <CommonRegulationsTemplateSelect
                          onHandleSelect={form.mutators.handleSelectRegulation}
                        />
                      )}
                      placeholder={reverseChargePlaceholder}
                    />
                    <AreaCollapsible
                      className="w-100 p-3 bg-light"
                      containerElement="div"
                      contentChildren={(
                        <React.Fragment>
                          <img
                            alt="invoice sample img"
                            className="mt-3 mb-4 shadow-sm"
                            src="/img/invoice-regulations-text-indicator.png"
                            width={IMG_SIZE.XXLARGE}
                          />
                          <p>
                            Regulatory compliance text will be shown as a footnote below the
                            itemised invoice.
                          </p>
                        </React.Fragment>
                      )}
                      headingChildren={(
                        <p className="mb-0 font-weight-bold">
                          What&apos;s a regulatory compliance text?
                        </p>
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TDButton
                      className="position-tweak mr-3"
                      type="submit"
                      variant={BS_STYLE.PRIMARY}
                      label="Update"
                      disabled={pristine}
                    />
                  </div>
                </div>
              </form>
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}

OrgSettingsPaymentsInvoices.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  activeOrg: orgSpec,
};

OrgSettingsPaymentsInvoices.defaultProps = {
  item: {},
  activeOrg: {},
};

const WithTDApiConnectedOrgPaymentsInvoices = withTDApiConnected({
  fetchData: ({ dispatch, params, url = '', authedAxios, componentName }) => (
    dispatch(fetchUserCardWithVatDS(params, url, authedAxios, componentName))
  ),
  duck: 'view',
  storeKey: COMPONENT_NAME,
  skeletonComponent: SettingsPageSkeleton,
})(OrgSettingsPaymentsInvoices);

const mapStateToProps = (state) => {
  return {
    activeOrg: selectActiveOrg(state),
  };
};

const OrgPaymentsInvoicesConnect = connect(
  mapStateToProps,
)(WithTDApiConnectedOrgPaymentsInvoices);

export default OrgPaymentsInvoicesConnect;
