/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import { isEmpty } from 'lodash';

import withField from 'core/assets/js/components/withField.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';


const DEFAULT_RANGE = {
  min: 1,
  max: 1500,
};

/**
 * Display the range of rates - min/max
 *
 * Displays percentages and amounts differently
 *
 * @param props
 * @returns {RateRangeLabel.props.defaultLabel|*|*}
 * @constructor
 */
const RateRangeLabel = (props) => {
  const { defaultLabel, displayDecimals, range, value, symbol } = props;
  const decimals = displayDecimals ? 2 : 0;

  if (!range || !range.max) {
    return defaultLabel;
  }

  const isPercentage = symbol === '%';
  let min = range.min;
  let max = range.max;

  if (value && range.max) {
    min = isPercentage
      ? value.min * 100
      : value.min;
    max = isPercentage
      ? value.max * 100
      : value.max;
  }
  const prefix = !isPercentage ? symbol : null;
  const suffix = isPercentage ? symbol : null;

  return (
    <React.Fragment>
      <NumberTpl
        value={min}
        prefix={prefix}
        suffix={suffix}
        decimals={decimals}
      />
      {' '}
      -
      {' '}
      <NumberTpl
        value={max}
        prefix={prefix}
        suffix={suffix}
        decimals={decimals}
      />
    </React.Fragment>
  );
};
RateRangeLabel.propTypes = {
  defaultLabel: PropTypes.string.isRequired,
  displayDecimals: PropTypes.bool,
  range: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  symbol: PropTypes.string.isRequired,
};
RateRangeLabel.defaultProps = {
  displayDecimals: false,
  value: null,
  range: DEFAULT_RANGE,
};

class RangeFilterField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentValue: {} };

    const { filter: { step } } = props;

    this.displayDecimals = step % 1 !== 0;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { initialValues, filter: { paramName, min, max } } = this.props;
    const initialValue = initialValues[paramName] || {};

    if (!isEmpty(initialValue)) {
      this.handleChange([initialValue.min, initialValue.max]);
      this.setState({ currentValue: initialValue });
    } else {
      this.setState({ currentValue: { min, max } });
    }
  }

  handleChange(newValue) {
    const { input } = this.props;

    const [min, max] = newValue;
    const currentValue = { min, max };

    const _value = newValue ? JSON.stringify(currentValue) : null;
    input.onChange(_value);

    this.setState({ currentValue });
  }

  render() {
    const { filter: { label, min, max, step, symbol }, input } = this.props;
    const { currentValue } = this.state;

    return (
      <React.Fragment>
        <h4>{label}</h4>

        <div className="form-control-container">
          <div className="row">
            <div className="col-12">
              <RateRangeLabel
                defaultLabel={label}
                displayDecimals={this.displayDecimals}
                symbol={symbol}
                value={currentValue}
                range={{ min, max }}
              />

              <Range
                onChange={this.handleChange}
                min={min}
                max={max}
                step={step}
                defaultValue={[min, max]}
                value={!isEmpty(currentValue) ? [currentValue.min, currentValue.max] : [min, max]}
              />
            </div>
          </div>
        </div>

        <input
          {...input}
          type="hidden"
          className="form-control"
        />
      </React.Fragment>
    );
  }
}

RangeFilterField.propTypes = {
  filter: PropTypes.object,
  input: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isPercentage: PropTypes.bool,
  value: PropTypes.object,
};
RangeFilterField.defaultProps = {
  filter: {},
  isPercentage: false,
  value: null,
};

export default withField(RangeFilterField);
