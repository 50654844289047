import React from 'react';

const WorksheetTaskItemHeaderRow = () => (
  <tr className="worksheet-items-table__header">
    <th>
      Task
    </th>
    <th>
      Task rate
    </th>
    <th>
      Billed
    </th>
    <th className="worksheet-items-table__header--rate">
      Rate
    </th>
    <th style={{ paddingLeft: 0, paddingRight: 0 }} />
    <th className="worksheet-items-table__header--qty">
      QTY
    </th>
    <th className="worksheet-items-table__header--amount">
      Amount
    </th>
    <th style={{ width: '35px' }} />
  </tr>
);
WorksheetTaskItemHeaderRow.propTypes = {};

export default WorksheetTaskItemHeaderRow;
