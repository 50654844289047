import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { isSSR } from 'core/assets/js/config/checks';
import { orgUserProfileUrl } from 'people/urls';
import { projectViewTaskUrl } from 'projects/urls';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { USER_TYPE, TASK_TABS, ICON, BOOTSTRAP_BREAKPOINTS } from 'core/assets/js/constants';
import { getEffectiveTaskInfo } from 'projects/assets/js/lib/helpers';
import TaskProgressBar from 'projects/assets/js/components/TaskProgressBar.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { WINDOW_INNER_WIDTH, WINDOW_ADD_EVENT_LISTENER, WINDOW_REMOVE_EVENT_LISTENER } from 'core/assets/js/config/settings';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';

const ChatHeader = ({
  task,
  selectedParticipant, activeOrg, hasOrgAccess,
  match: { params: { orgAlias, id: projectId, taskId } },
}) => {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkWindowDimensions = () => {
    setIsMobileView(WINDOW_INNER_WIDTH < BOOTSTRAP_BREAKPOINTS.MEDIUM);
  };

  useEffect(() => {
    checkWindowDimensions();

    if (!isSSR) {
      WINDOW_ADD_EVENT_LISTENER('resize', checkWindowDimensions);

      return () => WINDOW_REMOVE_EVENT_LISTENER('resize', checkWindowDimensions);
    }

    return null;
  }, []);

  const { user: { id: userId, profile: { name } }, assignment, isAssignee } = selectedParticipant;
  const profileUrl = isAssignee
    ? orgUserProfileUrl(orgAlias, USER_TYPE.PROVIDER, userId)
    : orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, userId);

  let assignmentRate = null;
  let assignmentProgress = null;

  const isAnyManager = hasOrgAccess({ requireManager: true });
  const canViewProviders = (
    isAnyManager || activeOrg.should_provider_view_other_providers
  );

  if (!isEmpty(assignment)) {
    const {
      currencySymbol,
      rate,
      rate_unit: rateUnit,
      allowedActions: { canViewProgress, canViewRates },
    } = assignment;

    assignmentRate = canViewRates && (
      <RateAmount amount={rate} symbol={currencySymbol} unit={rateUnit} />
    );

    if (canViewProgress) {
      const {
        label: statusLabel, statusClass, progress: perceivedProgress,
      } = getEffectiveTaskInfo(activeOrg, task, { assignment });

      assignmentProgress = (
        <>
          <TaskProgressBar
            id={task.id}
            progress={perceivedProgress}
            statusClass={statusClass}
            className="task-progress-bar--thick discussion-board__header__progress-bar"
          />
          <span className="ml-4 discussion-board__header__progress-label">
            {perceivedProgress ? `${perceivedProgress}%` : statusLabel}
          </span>
        </>
      );
    }
  }

  if (isMobileView) {
    return (
      <>
        <div className="discussion-board__header d-flex align-items-center">
          {canViewProviders ? (
            <Link
              to={profileUrl}
              className="text-muted"
            >
              {name}
            </Link>
          ) : name}

          <Link
            className="discussion-board__close-chat ml-auto btn btn-link p-0"
            to={projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION)}
          >
            <span className={ICON.CROSS} />
          </Link>
        </div>
        <div className="discussion-board__header d-flex align-items-center">
          {assignmentProgress && (
            <>
              {assignmentProgress}
            </>
          )}

          <div className="ml-auto">
            {assignmentRate && (
              <>
                <span className="discreet" data-testid="chat-header-assignment-rate">
                  {assignmentRate}
                </span>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="discussion-board__header d-flex align-items-center">
      {canViewProviders ? (
        <Link
          to={profileUrl}
          className="text-muted"
        >
          {name}
        </Link>
      ) : name}

      <>
        {assignmentRate && (
          <>
            <span className="discreet px-3">
              &bull;
            </span>
            <span className="discreet" data-testid="chat-header-assignment-rate">
              {assignmentRate}
            </span>
          </>
        )}
        {assignmentProgress && (
          <>
            <span className="discreet px-3">
              &bull;
            </span>
            {assignmentProgress}
          </>
        )}
      </>

      <Link
        className="discussion-board__close-chat ml-auto btn btn-link p-0"
        to={projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION)}
      >
        <span className={ICON.CROSS} />
      </Link>
    </div>
  );
};

ChatHeader.propTypes = {
  task: projectTaskSpec.isRequired,
  match: routerMatchSpec.isRequired,
  selectedParticipant: PropTypes.object,
  activeOrg: orgSpec.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
};

ChatHeader.defaultProps = {
  selectedParticipant: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  hasOrgAccess: getHasOrgAccess(state),
});

const ChatHeaderConnected = connect(
  mapStateToProps,
)(ChatHeader);

export default withRouter(ChatHeaderConnected);
