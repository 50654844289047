import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { orgFetchPublicApiUrl, orgGetOutstandingCountsApiUrl } from 'organizations/urls';
import { viewFetchAC } from 'core/assets/js/ducks/view';

export const fetchPublicOrgInformation = ({
  orgAlias, url = '', authedAxios = null, componentName,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => {
      if (!orgAlias) {
        throw new Error('The orgAlias is required');
      }
    },
    fetchApiUrl: () => orgFetchPublicApiUrl(orgAlias, url),
    componentName,
    fetchDataAC: responseData => (
      viewFetchAC(responseData, componentName)
    ),
  })
);

export const getOutstandingCountsDS = ({
  authedAxios, componentName = null, orgAlias, userId,
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    fetchApiUrl: () => orgGetOutstandingCountsApiUrl(orgAlias, userId),
    fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  })
);
