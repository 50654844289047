import React from 'react';
import PropTypes from 'prop-types';

function TDRadio({ className, selected, label, onClick, sublabel }) {
  const _className = [className, 'custom-radio'].filter(c => c !== '');

  if (selected) {
    _className.push('custom-radio--selected');
  }
  return (
    <React.Fragment>
      <label onClick={onClick} className={_className.join(' ')}>
        <span data-testid="radio-checkbox" className="custom-radio__circle mr-2" />
        {' '}
        {label}
      </label>
      {sublabel && <p className="discreet">{` ${sublabel}`}</p>}
    </React.Fragment>
  );
}

TDRadio.propTypes = {
  label: PropTypes.node,
  selected: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

TDRadio.defaultProps = {
  label: '',
  selected: false,
  className: '',
  onClick: () => {},
  sublabel: '',
};

export default TDRadio;
