import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { isNumber } from 'lodash';

import {
  BS_TOOLTIP_PLACEMENT,
  DATE_FORMAT_DEFAULT,
  ICON,
  IMG_SIZE,
  PROFILE_TABS,
  USER_CARD_STATUS,
  USER_TYPE,
} from 'core/assets/js/constants';
import { SOCIAL_PROFILES_OPTIONS } from 'accounts/assets/js/constants';
import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDRating from 'core/assets/js/components/TDRating.jsx';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import ContactDetailsCard from 'people/assets/js/components/ContactDetailsCard.jsx';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import OnboardingStatus from 'people/assets/js/components/OnboardingStatus.jsx';
import UserDetailsSkeleton from 'core/assets/js/components/Skeleton/UserDetailsSkeleton.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { formatDate } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import DetailsRows from 'core/assets/js/components/DetailsRows.jsx';
import { submissionUrl } from 'interviews/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { orgUserProfileUrl } from 'people/urls';

const SocialProfiles = ({ profile }) => (
  SOCIAL_PROFILES_OPTIONS.map(sp => (
    profile?.social_profiles?.[sp.key] && (
      <TDElementWithTooltip
        key={sp.key}
        placement={BS_TOOLTIP_PLACEMENT.TOP}
        tooltipMsg={sp.label}
      >
        <a className={`mr-3 pt-2 ${sp.key}`} href={profile.social_profiles[sp.key]} target="_blank" rel="noopener noreferrer">
          <span className={`mb-3 ${sp.icon}`} />
        </a>
      </TDElementWithTooltip>
    )
  ))
);

class UserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseContactModal = this.handleCloseContactModal.bind(this);
    this.handleOpenContactModal = this.handleOpenContactModal.bind(this);
  }

  handleOpenContactModal() {
    const { dispatch, contactModalId } = this.props;
    dispatch(modalOpenAC(contactModalId));
  }

  handleCloseContactModal() {
    const { dispatch, contactModalId } = this.props;
    dispatch(modalCloseAC(contactModalId));
  }

  render() {
    const {
      activeKey,
      canViewRating,
      email,
      isContactModalOpen,
      organization,
      profile,
      submissionLink,
      userCard,
    } = this.props;
    const {
      budget,
      currencySymbol,
      deactivationReason,
      numReviews,
      rate,
      rateUnit,
      rating,
      status,
      userRole,
    } = userCard;
    const isAnyManager = userRole && userRole.isAnyManager;

    const hasCompleteRate = (currencySymbol && rate && rateUnit);
    const hasCompleteBudget = (currencySymbol && isNumber(budget));

    if (!profile) {
      return (
        <UserDetailsSkeleton />
      );
    }

    // Rate display
    let userRate = '';
    if (hasCompleteRate) {
      userRate = (
        <RateAmount
          amount={rate}
          unit={rateUnit}
          symbol={currencySymbol}
        />
      );
    }

    const joinedAtRows = [];
    if (userCard.joinedAt) {
      joinedAtRows.push({
        label: 'Joined on',
        value: formatDate(userCard.joinedAt, DATE_FORMAT_DEFAULT),
      });
      if (submissionLink) {
        joinedAtRows.push({
          label: 'Onboarding information',
          value: <a href={submissionLink}>View</a>,
        });
      }
    }
    if (userCard.invitation && userCard.invitation.sender) {
      joinedAtRows.push({
        label: 'Invited by',
        value: (
          <a
            href={orgUserProfileUrl(
              organization.alias, USER_TYPE.MANAGER, userCard.invitation.sender.id,
            )}
          >
            {userCard.invitation.sender.profile.name}
          </a>
        ),
      });
    }
    return (
      <Card className={`user-profile__card mb-2 ${activeKey === PROFILE_TABS.EXPERIENCE ? 'd-block' : 'd-md-block d-none'}`}>
        <Card.Body className="px-0">
          <div className="user-profile__summary px-4 d-flex flex-column align-items-center text-center">
            <ProfilePic
              className="mr-0 mb-3"
              url={profile && profile.avatar}
              alt={`${profile ? profile.firstName : 'Users'}'s avatar`}
              size={[IMG_SIZE.LARGE, IMG_SIZE.LARGE]}
            />

            <div className="user-profile__basic-info d-flex flex-column justify-content-end w-100">
              <div className="mt-2 mt-md-0">
                { profile && (
                  <span className="user-profile__name">{profile.name}</span>
                )}
              </div>

              { profile && (
                <div className="text-truncate w-100">
                  <span
                    title={profile.jobTitle}
                    className="user-profile__job-title truncate mb-2 my-md-0"
                  >
                    {profile.jobTitle}
                  </span>
                </div>
              )}

              <div className="user-profile__buttons my-3 text-center">
                <SocialProfiles profile={profile} />
                <TDElementWithTooltip
                  placement={BS_TOOLTIP_PLACEMENT.TOP}
                  tooltipMsg={`Contact ${profile.firstName}`}
                >
                  <a className="mr-3 pt-2 contact" onClick={this.handleOpenContactModal}>
                    <span className={`${ICON.EMAIL}`} />
                  </a>
                </TDElementWithTooltip>
              </div>
            </div>
          </div>

          <div
            className={[
              'user-profile__joined-at',
              'user-profile__light-border-top',
              'row',
              'mx-0',
              'd-flex',
              'flex-column',
              'px-4',
              status !== USER_CARD_STATUS.APPROVED ? 'onboarding-status-visible' : '',
            ].join(' ')}
          >
            <OnboardingStatus
              status={status}
              showBackgroundLine={false}
              deactivationReason={deactivationReason}
            />

            {joinedAtRows.length > 0 && (
              <DetailsRows prefix="user-profile-joined-at" rows={joinedAtRows} />
            )}
          </div>

          {(hasCompleteRate || !isAnyManager || hasCompleteBudget) && (
            <div className="user-profile__meta row mx-0 flex-wrap align-items-center justify-content-around">
              { hasCompleteRate && (
                <div className="col-4 text-center pl-0 pr-1">
                  <div className="value">{userRate}</div>

                  <div className="label">
                    Rate
                  </div>
                </div>
              )}

              { /** TODO: Remove isAnyManager check when we decide how we handle Manager Reviews */}
              {!isAnyManager && canViewRating && (
                <div className="col-4 text-center px-0">
                  { rating > 0 && (
                    <TDRating
                      readonly
                      className="value"
                      size="extra-small"
                      initialRating={rating}
                    />
                  )}
                  { !rating && (
                    <span className="value">-</span>
                  )}
                  <div className="label">
                    {`${numReviews} ${numReviews === 1 ? 'review' : 'reviews'}`}
                  </div>
                </div>
              )}

              { hasCompleteBudget && (
                <div className="col-4 text-center pl-2 pr-0">
                  <NumberFormat
                    className="value"
                    value={budget}
                    prefix={currencySymbol}
                    displayType="text"
                    thousandSeparator
                  />

                  <div className="label">
                    Earnings
                  </div>
                </div>
              )}

            </div>
          )}
        </Card.Body>

        { profile && (
          <ModalSimple
            key="profile--contact-modal"
            open={isContactModalOpen}
            heading={`Contact ${profile.firstName}`}
            body={(
              <ContactDetailsCard
                email={email}
                profile={profile}
              />
            )}
            onClose={this.handleCloseContactModal}
          />
        )}
      </Card>
    );
  }
}

UserDetails.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]).isRequired,
  canViewRating: PropTypes.bool,
  contactModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string,
  isContactModalOpen: PropTypes.bool,
  organization: orgSpec.isRequired,
  profile: profileSpec,
  userCard: PropTypes.object.isRequired,
  submissionLink: PropTypes.string,
};

UserDetails.defaultProps = {
  canViewRating: false,
  email: null,
  isContactModalOpen: false,
  profile: {},
  submissionLink: '',
};

const mapStateToProps = (state, props) => {
  const contactModalId = 'user-details-contact-modal';
  const hasOrgAccess = getHasOrgAccess(state);
  const isManager = hasOrgAccess({ requireManager: true });
  const activeOrg = selectActiveOrg(state);
  const canViewRating = isManager || activeOrg.should_provider_view_other_providers_review;
  const submission = props.userCard.submissions && props.userCard.submissions[0];
  const submissionLink = submission && submissionUrl(activeOrg.alias, submission.id);

  return {
    canViewRating,
    contactModalId,
    isContactModalOpen: getIsModalOpen(state, contactModalId),
    notes: state.people.noteList.items,
    organization: activeOrg,
    submissionLink,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const UserDetailsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDetails);

export default UserDetailsConnected;
