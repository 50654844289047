import React from 'react';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { ANCHOR_LEFT, ANCHOR_RIGHT } from 'react-dates/constants';
import { Form } from 'react-final-form';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BANK_CURRENCY } from 'core/assets/js/constants';
import { RATE_UNIT_CUSTOM_SELECT_FILTERED } from 'rates/assets/js/constants';

// Form Fields
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import { REVERSE_CHARGE_PLACEHOLDER } from 'settings/assets/js/constants';

class FormsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      focusedInput, singleDatePickerDate, singleDatePickerFocused,
      startDate, endDate,
    } = this.state;


    const reverseChargePlaceholder = `e.g Reverse Charge - According to Article 194, 196 of Council Directive 2006/112/EEC, VAT liability rests with the service recipient ${REVERSE_CHARGE_PLACEHOLDER}.`;
    return (
      <section id="forms">
        <h2>Forms</h2>
        <div className="col-12 p-5 mb-5" style={{ background: '#fff' }}>
          <form>
            <div className="form-group">
              <label
                htmlFor="ex1"
              >
                Example label
              </label>
              <input
                type="text"
                className="form-control"
                id="ex1"
                placeholder="e.g. landing page design"
              />
            </div>
            <div className="form-group">
              <label htmlFor="ex2">Another label</label>
              <textarea
                className="form-control"
                id="ex2"
                placeholder="Describe the project brief..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="ex2">Standard Select</label>
              <select className="form-control">
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
                <option value="4">Option 4</option>
              </select>
            </div>
            <div className="form-group">
              <TDButton variant={BS_STYLE.PRIMARY} block label="Submit" />
            </div>
          </form>
        </div>

        <h2>Form Fields</h2>

        <div className="row mt-4">
          <div className="col-6">
            <label>Date range picker example</label>
            <DateRangePicker
              hideKeyboardShortcutsPanel
              small
              block
              numberOfMonths={2}
              anchorDirection={ANCHOR_RIGHT}
              startDate={startDate}
              isOutsideRange={() => false} // Allows past dates selection
              startDateId="unique_start_date_id"
              endDate={endDate}
              endDateId="unique_end_date_id"
              onDatesChange={({ startDate, endDate }) => ( // eslint-disable-line no-shadow
                this.setState({ startDate, endDate })
              )}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => ( // eslint-disable-line no-shadow
                this.setState({ focusedInput },
                ))}
            />
          </div>

          <div className="col-6">
            <label>Single Date picker example</label>
            <SingleDatePicker
              small
              block
              hideKeyboardShortcutsPanel
              displayFormat="DD/MM/YYYY"
              isOutsideRange={() => false} // Allows past dates selection
              anchorDirection={ANCHOR_LEFT}
              date={singleDatePickerDate}
              onDateChange={date => this.setState({ singleDatePickerDate: date })}
              focused={singleDatePickerFocused}
              onFocusChange={
                ({ focused }) => this.setState({ singleDatePickerFocused: focused })
              }
            />
          </div>
        </div>

        <h2 className="my-5">FinalForm Fields</h2>

        <Form
          mutators={{ handleSelectRegulation: this.handleSelectRegulation }}
          onSubmit={() => {}}
          initialValues={{
            currency: 'usd',
          }}
          render={({ handleSubmit }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit} className="px-0">
                <div className="rounded shadow-sm p-4 bg-white">
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <CurrencySelectField
                        label="Currency"
                        name="currency"
                      />
                    </div>
                    <div className="col-12 col-md-3">
                      <CurrencySelectField
                        label="Currency (Full list)"
                        name="currency-2"
                        currencyOptions={BANK_CURRENCY} // <-- Optional
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <TextAreaField
                        extraInfo="Text content"
                        name="invoicing_footnote"
                        type="text"
                        label="Regulatory compliance text"
                        sublabel="Based on the payment regulations of your country or jurisdiction, you can add a footnote to your invoice here"
                        placeholder={reverseChargePlaceholder}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <TextInputField
                        name="title"
                        type="text"
                        label="Project title"
                        placeholder="e.g Landing page design"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <TextInputField
                        name="title"
                        type="text"
                        label="Project title"
                        popOverTitle="title here"
                        popOverContent="Content goes here"
                        extraInfo="Some extra details here."
                        placeholder="e.g Landing page design"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <CheckboxField
                        name="text-checkbox"
                        label="Is this real?"
                        sublabel="Speak the truth"
                        required
                        options={[
                          { text: 'Option 1', value: 1 },
                          { text: 'Option 2', value: 2 },
                          { text: 'Option 3', value: 3 },
                        ]}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <CustomSelectField
                        label="Pick one"
                        name="custom-select-1"
                        options={RATE_UNIT_CUSTOM_SELECT_FILTERED}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="w-100 d-flex mt-4">
                  <div className="ml-auto">
                    <TDButton
                      type="button"
                      variant={BS_STYLE.DEFAULT}
                      label="Cancel"
                    />

                    <TDButton
                      type="submit"
                      variant={BS_STYLE.PRIMARY}
                      label="Submit"
                    />
                  </div>
                </div>
              </form>
            </React.Fragment>
          )}
        />
      </section>
    );
  }
}

export default FormsSection;
