import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import UserAndGroupSearchField from 'core/assets/js/components/FinalFormFields/UserAndGroupSearchField.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { CHAT_ALLOWED_USER_CARD_STATUS, CREATE_CHAT_MODAL_ID } from 'people/assets/js/constants';

const CreateChatModal = ({ handleMessageSubmit, modalId }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => getIsModalOpen(state, modalId));

  return (
    <Form
      onSubmit={handleMessageSubmit}
    >
      {({ handleSubmit }) => (
        <ModalSimple
          onClose={() => dispatch(modalCloseAC())}
          body={(
            <form onSubmit={handleSubmit}>
              <UserAndGroupSearchField
                excludeLoggedInUser={false}
                excludeOrgOwner={false}
                label="Participants"
                name="participants"
                userCardStatus={CHAT_ALLOWED_USER_CARD_STATUS}
              />
              {modalId === CREATE_CHAT_MODAL_ID && (
                <TextInputField
                  name="title"
                  label="Message Title"
                />
              )}
              <div className="d-flex justify-content-end">
                <TDButton
                  label="Cancel"
                  onClick={() => {
                    dispatch(modalCloseAC());
                  }}
                />
                <TDButton
                  label={modalId === CREATE_CHAT_MODAL_ID ? 'Create' : 'Add'}
                  variant={BS_STYLE.PRIMARY}
                  type="submit"
                />
              </div>
            </form>
          )}
          noFooter
          heading="New chat"
          open={isModalOpen}
          size={MODAL_SIZES.LARGE}
        />
      )}
    </Form>
  );
};

CreateChatModal.GetComponentName = () => 'CreateChatModal';

CreateChatModal.propTypes = {
  handleMessageSubmit: PropTypes.func.isRequired,
  modalId: PropTypes.string,
};
CreateChatModal.defaultProps = {
  modalId: CREATE_CHAT_MODAL_ID,
};
export default withRouter(withFilters(CreateChatModal));
