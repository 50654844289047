import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import ApprovedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/ApprovedLabel.jsx';
import ConfirmedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/ConfirmedLabel.jsx';
import InvoicedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/InvoicedLabel.jsx';
import PaidLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/PaidLabel.jsx';
import ProcessingLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/ProcessingLabel.jsx';
import RaisedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/RaisedLabel.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import {
  approvedIsGreen, confirmedIsGreen, invoicedIsGreen, paidIsGreen, processingIsGreen,
} from 'core/assets/js/lib/paymentStatusWidget';
import WorksheetTypeIndicator from 'finance/assets/js/components/WorksheetTypeIndicator.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { serviceOrderLightSpec } from 'finance/assets/js/lib/objectSpecs';
import { financeServiceOrderViewUrl, financeWorksheetsCreateUrl } from 'finance/urls';
import {
  SERVICE_ORDER_STATUS, SERVICE_ORDER_TYPE, SERVICE_ORDER_TYPE_LABEL,
} from 'projects/assets/js/constants';

const AmendButton = ({ history, id, orgAlias, type }) => (
  <TDButton
    className="text-nowrap amend-button"
    label={`Amend ${SERVICE_ORDER_TYPE_LABEL[type].toLowerCase()}`}
    onClick={() => history.push(financeServiceOrderViewUrl(
      orgAlias, type, id,
    ))}
    variant={BS_STYLE.WARNING}
  />
);

AmendButton.propTypes = {
  history: routerHistorySpec.isRequired,
  id: PropTypes.number,
  orgAlias: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(SERVICE_ORDER_TYPE)),
};

AmendButton.defaultProps = {
  id: null,
  type: null,
};

const PaymentStatusWidgetMobile = ({
  history, isSecondLevelApprovalEnabled, orgAlias, serviceOrders,
}) => {
  const [selectedServiceOrderId, setSelectedServiceOrderId] = useState(null);
  const selectedServiceOrder = selectedServiceOrderId
    ? serviceOrders.find(so => so.id === selectedServiceOrderId)
    : null;

  const getStatusLabel = serviceOrder => {
    if (paidIsGreen(serviceOrder)) {
      return 'Paid';
    }
    if (processingIsGreen(serviceOrder)) {
      return 'Processing';
    }
    if (invoicedIsGreen(serviceOrder)) {
      return 'Invoiced';
    }
    if (approvedIsGreen(serviceOrder, isSecondLevelApprovalEnabled)) {
      return 'Approved';
    }
    if (confirmedIsGreen(serviceOrder)) {
      return 'Confirmed';
    }
    return 'Submitted';
  };

  return (
    <div className="payment-status__mobile d-flex d-xl-none flex-column px-4 py-3">
      {selectedServiceOrder && (
        <>
          <div
            className={(
              'row align-items-center justify-content-center position-relative pb-3 header'
            )}
          >
            <div className="col-4">
              <TDButton
                btnIcon={ICON.ARROW_LEFT_LONG}
                className="back-button imitate-link"
                label="Back"
                onClick={() => setSelectedServiceOrderId(null)}
                rounded
                variant={BS_STYLE.LINK}
              />
            </div>
            <div className="col-4">
              <WorksheetTypeIndicator type={selectedServiceOrder.type} />
              <span className="ml-2">{selectedServiceOrder.id}</span>
            </div>
            <div className="col-4" />
          </div>
          <div className="d-flex align-items-center justify-content-between py-4">
            <div className="font-weight-bold">
              <h5 className="font-weight-bold">Amount</h5>
              <NumberTpl
                prefix={selectedServiceOrder.serviceOrderCurrencySymbol}
                value={selectedServiceOrder.serviceOrderAmount}
              />
            </div>
            <div>
              <h5 className="font-weight-bold">Last action</h5>
              <span className="text-danger">{selectedServiceOrder.updatedAt}</span>
            </div>
          </div>
          <div className="d-flex align-items-start flex-column mt-5">
            <div className="d-flex align-items-center justify-content-start w-100">
              <div className="dot complete" />
              <span className="status-label"><RaisedLabel /></span>
            </div>
            {isSecondLevelApprovalEnabled && (
              <>
                <div
                  className={[
                    'bar', confirmedIsGreen(selectedServiceOrder, true) ?  ' complete' : '',
                  ].join('')}
                />
                <div className="d-flex align-items-center justify-content-start w-100">
                  <div
                    className={[
                      'dot', confirmedIsGreen(selectedServiceOrder, true) ?  ' complete' : '',
                    ].join('')}
                  />
                  <span className="status-label"><ConfirmedLabel /></span>
                  {
                    selectedServiceOrder.status === SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED
                    && !selectedServiceOrder.confirmed
                    && (
                      <AmendButton
                        history={history}
                        id={selectedServiceOrder.id}
                        orgAlias={orgAlias}
                        type={selectedServiceOrder.type}
                      />
                    )
                  }
                </div>
              </>
            )}
            <div
              className={
                [
                  'bar',
                  approvedIsGreen(selectedServiceOrder, isSecondLevelApprovalEnabled)
                    ? ' complete'
                    : '',
                ].join('')
              }
            />
            <div className="d-flex align-items-center justify-content-start w-100">
              <div
                className={
                  [
                    'dot',
                    approvedIsGreen(selectedServiceOrder, isSecondLevelApprovalEnabled)
                      ? ' complete'
                      : '',
                  ].join('')
                }
              />
              <span className="status-label">
                <ApprovedLabel isSecondLevelApprovalEnabled={isSecondLevelApprovalEnabled} />
              </span>
              {
                selectedServiceOrder.status === SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED
                && (!isSecondLevelApprovalEnabled || selectedServiceOrder.confirmed)
                && (
                  <AmendButton
                    history={history}
                    id={selectedServiceOrder.id}
                    orgAlias={orgAlias}
                    type={selectedServiceOrder.type}
                  />
                )
              }
            </div>
            <div className={`bar${invoicedIsGreen(selectedServiceOrder) ? ' complete' : ''}`} />
            <div className="d-flex align-items-center justify-content-start w-100">
              <div
                className={`dot${invoicedIsGreen(selectedServiceOrder) ? ' complete' : ''}`}
              />
              <span className="status-label"><InvoicedLabel /></span>
            </div>
            <div className={`bar${processingIsGreen(selectedServiceOrder) ? ' complete' : ''}`} />
            <div className="d-flex align-items-center justify-content-start w-100">
              <div
                className={
                  [
                    'dot',
                    processingIsGreen(selectedServiceOrder) ? ' complete' : '',
                  ].join('')
                }
              />
              <span className="status-label"><ProcessingLabel /></span>
            </div>
            <div className={`bar${paidIsGreen(selectedServiceOrder) ? ' complete' : ''}`} />
            <div className="d-flex align-items-center justify-content-start w-100">
              <div className={`dot${paidIsGreen(selectedServiceOrder) ? ' complete' : ''}`} />
              <span className="status-label"><PaidLabel /></span>
            </div>
          </div>
        </>
      )}
      {!selectedServiceOrder && (
        <>
          <div className="d-flex justify-content-start">
            <TDButton
              label="Submit worksheet"
              onClick={() => history.push(financeWorksheetsCreateUrl(orgAlias))}
              variant={BS_STYLE.PRIMARY}
            />
          </div>
          {serviceOrders.map(serviceOrder => (
            <div className="row align-items-center pt-4" key={serviceOrder.id}>
              <div className="col-4">
                <WorksheetTypeIndicator type={serviceOrder.type} />
                <span className="ml-2">{serviceOrder.id}</span>
              </div>
              <div className="col-4 font-weight-bold">{getStatusLabel(serviceOrder)}</div>
              <div className="col-4">
                <TDButton
                  className="w-100"
                  label="View"
                  onClick={() => setSelectedServiceOrderId(serviceOrder.id)}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

PaymentStatusWidgetMobile.propTypes = {
  history: routerHistorySpec.isRequired,
  isSecondLevelApprovalEnabled: PropTypes.bool.isRequired,
  orgAlias: PropTypes.string.isRequired,
  serviceOrders: PropTypes.arrayOf(serviceOrderLightSpec).isRequired,
};

export default withRouter(PaymentStatusWidgetMobile);
