import React from 'react';
import PropTypes from 'prop-types';

import ErrorRoute from 'core/assets/js/config/routes/ErrorRoute.jsx';
import LayoutError from 'core/assets/js/layout/LayoutError.jsx';

export const NotFound = ({ displayInline, errMsg }) => {
  const contClassName = ['error-page__wrapper'];
  if (displayInline) {
    contClassName.push('error-page__wrapper--inline');
  }

  return (
    <div className={contClassName.join(' ')}>
      <img className="error-page__icon" src="/img/error-icons/404.svg" alt="Error 404" />
      <h1 className="error-page__code">404</h1>
      <h2 className="error-page__title">Page not found</h2>
      { errMsg && (
        <p className="error-page__msg">{errMsg}</p>
      )}
      { !errMsg && (
        <p className="error-page__msg">
          {'It\'s looking like you may have taken a wrong turn.'}
          <br />
          {'Don\'t worry... It happens to the best of us.'}
        </p>
      )}

      { !displayInline && (
        <a href="/">
          &larr; Back to TalentDesk.io
        </a>
      )}
    </div>
  );
};
NotFound.propTypes = {
  displayInline: PropTypes.bool,
  errMsg: PropTypes.string,
};
NotFound.defaultProps = {
  displayInline: false,
  errMsg: null,
};

const NotFoundView = () => (
  <ErrorRoute status={404}>
    <LayoutError component={NotFound} />
  </ErrorRoute>
);

export default NotFoundView;
