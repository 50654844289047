import { pick } from 'lodash';

/**
 * POJO to encapsulate a subscription plan purchased by client.
 */
class SubscriptionPlanDetails {
  /**
   * Constructor.
   * @param {object} props - POJO properties.
   * @return {SubscriptionPlanDetails} instance.
   */
  constructor(props) {
    if (props instanceof SubscriptionPlanDetails) {
      return props;
    }

    this.init(props);
  }

  /**
   * Initialise a POJO instance
   * @param {object} param0 - POJO properties.
   */
  init({
    active, totalCost, costPerUnit, currency, created,
    renewalInterval, vendorName, plan, quantity,
  } = {}) {
    this.active = active;
    this.totalCost = totalCost;
    this.costPerUnit = costPerUnit;
    this.currency = currency;
    this.created = created;
    this.renewalInterval = renewalInterval;
    this.vendorName = vendorName;
    this.plan = plan;
    this.quantity = quantity;
  }

  /**
   * Serialise a POJO instance for storage ( ie in the db ).
   * @return {object} object properties ready for serialization.
   */
  serialize() {
    return {
      ...pick(this, [
        // status
        'active',

        // price
        'totalCost', 'costPerUnit', 'currency',

        // dates
        'created', 'renewalInterval',

        // plan
        'vendorName', 'plan', 'quantity',
      ]),
    };
  }

  /**
   * Getter for active state.
   * @return {bool} if plan is active.
   */
  isActive() {
    return this.active;
  }

  /**
   * Getter for total cost of plan, unit cost * quantity.
   * @return {number} total cost of plan.
   */
  getTotalCost() {
    return this.totalCost;
  }

  /**
   * Getter for unit cost of plan, cost of 1 plan.
   * @return {number} unit cost of plan.
   */
  getCostPerUnit() {
    return this.costPerUnit;
  }

  /**
   * Getter for plan currency, ie current of costs.
   * @return {string} currency, ie 'usd'.
   */
  getCurrency() {
    return this.currency;
  }

  /**
   * Getter for created date
   * @return {string} date and time plan was created.
   */
  getCreated() {
    return this.created;
  }

  /**
   * Getter for plan renewal period.
   * @return {SUBSCRIPTION_RENEWAL_INTERVAL} plan renewal period.
   */
  getRenewalInterval() {
    return this.renewalInterval;
  }

  /**
   * Getter for plan.
   * @return {SUBSCRIPTION_PLANS} subscription plan.
   */
  getPlan() {
    return this.plan;
  }

  /**
   * Getter for plan quantity.
   * @return {number} plan quantity.
   */
  getQuantity() {
    return this.quantity;
  }

  /**
   * Getter for number of manager seats covered by plan.
   * @return {number} number of manager seats.
   */
  getNumManagerSeats() {
    return this.quantity;
  }

  /**
   * Getter for number of provider seats covered by plan.
   * @return {number} number of provider seats.
   */
  getNumProviderSeats() {
    return 0;
  }
}

export default SubscriptionPlanDetails;
