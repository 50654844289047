import React from 'react';
import PropTypes from 'prop-types';

function GetPaidMethodSelectCard({ item: { name, description, icon } }) {
  return (
    <div className="d-block">
      <div className="get-paid-item">
        { icon && (
          <div className="d-none d-md-inline-block get-paid-item__icon mb-4">
            <i className={icon} />
          </div>
        )}
        <h4 className="mt-0 mb-1 get-paid-item__title">{name}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}

GetPaidMethodSelectCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default GetPaidMethodSelectCard;
