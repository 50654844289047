import React from 'react';
import { BS_SIZE, ICON } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';

const BillableSubtotalModal = ({ modalState }) => (
  <React.Fragment>
    <span onClick={modalState.open} className={`${ICON.INFO} do-not-print`} />
    <ModalSimple
      open={modalState.isOpen}
      heading="Billable subtotal"
      body={(
        <p>
          Calculated as the total amount of all Worksheets created
          {' '}
          <strong>after</strong>
          {' '}
          the trial period ended,
          {' '}
          <strong>excluding</strong>
          {' '}
          the amounts of all Expenses.
        </p>
      )}
      onClose={modalState.close}
      noFooter
      size={BS_SIZE.LARGE}
    />
  </React.Fragment>
);

BillableSubtotalModal.propTypes = {
  modalState: modalStateSpec.isRequired,
};

export default withStateModal(BillableSubtotalModal);
