import React from 'react';
import PropTypes from 'prop-types';

import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';

export const QuarterSkeleton = ({ diameter, className, degrees }) => (
  <span
    className={['skeleton--quarter flex-shrink-0', className].join(' ')}
    style={{
      borderRadius: `${diameter}px`,
      borderWidth: `${diameter}px`,
      borderStyle: 'solid',
      transform: `rotate(${degrees}deg)`,
    }}
  />
);

QuarterSkeleton.propTypes = {
  diameter: PropTypes.string,
  className: PropTypes.string,
  degrees: PropTypes.string,
};

QuarterSkeleton.defaultProps = {
  diameter: '70',
  degrees: '45',
  className: '',
};

const PieChartSkeleton = ({ diameterOuterCircle, className }) => {
  return (
    <div className={['d-flex justify-content-center align-items-center py-5 my-4', className].join(' ')}>
      <CircleSkeleton className="mr-0 skeleton__pie-chart--outer-circle" diameter={diameterOuterCircle} />
      <CircleSkeleton className="mr-0 skeleton__pie-chart--main-circle" diameter={`${diameterOuterCircle - 30}`} />
      <QuarterSkeleton className="skeleton__pie-chart--quarter" diameter={`${diameterOuterCircle / 2 - 15}`} degrees="-45" />
    </div>
  );
};

PieChartSkeleton.propTypes = {
  diameterOuterCircle: PropTypes.string,
  className: PropTypes.string,
};

PieChartSkeleton.defaultProps = {
  diameterOuterCircle: '100',
  className: '',
};

export default PieChartSkeleton;
