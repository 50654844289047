import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW } from 'core/assets/js/config/settings';
import { BS_STYLE } from 'core/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import {
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS, DOCUMENT_ESIGN_EVENTS,
} from 'documents/assets/js/constants';
import { documentAssignmentSignatureSpec } from 'documents/assets/js/lib/objectSpecs';
import {
  documentAssignmentSignatureGetUrlApiUrl, documentAssignmentSignatureGetApiUrl,
} from 'documents/urls';

const DocumentAssignmentEsign = ({
  match: { params: { orgAlias, id } },
  onCancel,
  onSuccess,
  signature,
}) => {
  const [embedUrl, setEmbedUrl] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const { data: { status } } = await axios.get(documentAssignmentSignatureGetApiUrl(
        orgAlias, id, signature.id,
      ));
      if (status === DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.PENDING) {
        toastr.error('Oh Snap!', 'You have not signed');
      }
      if (status === DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.SIGNED) {
        toastr.success('Well Done!', 'You have successfully signed');
        await onSuccess();
      }
    } catch (err) {
      toastr.error('Oh Snap!', err._error || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const messageHandler = ev => {
    if (ev.data === DOCUMENT_ESIGN_EVENTS.CONTINUE) {
      onSubmit();
    }
  };

  useEffect(() => {
    WINDOW.addEventListener('message', messageHandler);
    return () => WINDOW.removeEventListener('message', messageHandler);
  }, []);

  useEffect(() => {
    if (!signature?.id) {
      return;
    }
    setIsLoading(true);
    axios
      .get(documentAssignmentSignatureGetUrlApiUrl(orgAlias, id, signature.id))
      .then(({ data }) => {
        setEmbedUrl(data.embedUrl);
      })
      .catch(err => {
        toastr.error('Oh Snap!', err._error || err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [signature?.id]);

  if (!embedUrl) {
    return null;
  }

  return (
    <>
      <iframe className="document-assignment-esign-iframe" src={embedUrl} title="Please sign" />
      <div className="buttons-container text-right mt-5">
        <TDButton onClick={onCancel}>Cancel</TDButton>
        <TDButton disabled={loading} onClick={onSubmit} variant={BS_STYLE.PRIMARY}>
          Confirm & continue
        </TDButton>
      </div>
    </>
  );
};

DocumentAssignmentEsign.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  signature: documentAssignmentSignatureSpec.isRequired,
};

export default withRouter(DocumentAssignmentEsign);
