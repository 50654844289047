/* eslint-disable react/jsx-fragments */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { settingsPaymentsSubPageUrl, settingsBankAccountApiUrl } from 'settings/urls';
import PayoneerForm from 'settings/assets/js/components/payoneer-components/PayoneerForm.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class ProfileManagePayoneer extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { bankAccountId } = params;
    if (bankAccountId) {
      return Promise.all([
        dispatch(fetchViewDS({
          url: settingsBankAccountApiUrl(params.bankAccountId, url),
          params,
          authedAxios,
          componentName,
        })),
      ]);
    }
    return Promise.resolve();
  }

  static GetComponentName() {
    return 'ProfileManagePayoneer';
  }

  render() {
    const {
      activeOrg, bankAccount, match: { params: { bankAccountId } },
    } = this.props;
    const breadcrumbs = [
      {
        title: 'Payments',
        url: settingsPaymentsSubPageUrl(activeOrg.alias),
      },
      {
        title: bankAccountId ? 'Edit payoneer account' : 'Add new payoneer account',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--settings bank-account">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={SettingsPageSkeleton}
            >
              <PayoneerForm
                bankAccount={bankAccount}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileManagePayoneer.propTypes = {
  activeOrg: orgSpec.isRequired,
  bankAccount: bankAccountSpec,
  match: routerMatchSpec.isRequired,
};

ProfileManagePayoneer.defaultProps = {
  bankAccount: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  bankAccount: getViewState(state, ProfileManagePayoneer.GetComponentName()).item,
});

const ProfileManagePayoneerReduxConnect = connect(
  mapStateToProps,
)(ProfileManagePayoneer);

export default withRouter(ProfileManagePayoneerReduxConnect);
