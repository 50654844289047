import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';

import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import { reviewsPostSuccessAC } from 'people/assets/js/ducks/providers';
import { BS_STYLE, ICON, PROFILE_TABS } from 'core/assets/js/constants';
import ReviewForm from 'people/assets/js/components/ReviewForm.jsx';
import ProfileTab from 'people/assets/js/components/ProfileTab.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import NoteForm from 'people/assets/js/components/NoteForm.jsx';
import { getHasOrgAccess, selectProfile } from 'accounts/assets/js/reducers/auth';
import { postNoteDS } from 'people/assets/js/ducks/people';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import UserTimeOffWidget from 'people/assets/js/components/UserTimeOffWidget.jsx';
import SkillsLanguagesWidget from 'people/assets/js/SkillsLanguagesWidget.jsx';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import InvoiceCapWidget from 'people/assets/js/components/InvoiceCapWidget.jsx';
import ProfileExtraTaxWidgets from 'people/assets/js/components/profile/ProfileExtraTaxWidgets.jsx';

class PeopleProfileTabs extends React.PureComponent {
  render() {
    const {
      activeOrg,
      areNotesLoading,
      areReviewsLoading,
      dispatch,
      handleCloseNoteModal,
      handleCloseReviewModal,
      handleOpenNoteModal,
      handleOpenReviewModal,
      hasOrgAccess,
      isNotesModalOpen,
      isReviewModalOpen,
      params,
      parentComponentName,
      profile,
      projectOptions,
      tab,
      userCard,
      viewerId,
    } = this.props;
    const isManager = hasOrgAccess({ requireManager: true });
    const userId = userCard && userCard.user && userCard.user.id;
    const isThemself = userCard.user && (viewerId === userId);
    return (
      <>
        <Card className="user-profile__tabs d-flex flex-column align-self-md-stretch w-100">
          <ProfileTab
            company={userCard.user.company}
            organization={{
              alias: activeOrg.alias,
              currencySymbol: activeOrg.currency_symbol,
              rateUnit: activeOrg.default_rate_unit,
            }}
            parentComponentName={parentComponentName}
            paymentMethod={userCard.paymentMethod}
            profile={profile}
            projectOptions={projectOptions}
            tab={tab}
            user={userCard.user}
            userId={userCard.user.id}
            viewerId={viewerId}
            userCard={userCard}
          />

          { tab === PROFILE_TABS.REVIEWS && (
            <AllowAccessToManager>
              <ReviewForm
                payload={{ subject_id: userCard.user.id }}
                show={isReviewModalOpen}
                projectOptions={projectOptions}
                onAfterSubmit={response => dispatch(reviewsPostSuccessAC(response))}
                onCloseModal={handleCloseReviewModal}
                revieweeFirstName={userCard.user.profile.firstName}
              />
              <div className="mt-auto text-right pr-4 pt-0 pb-4">
                <TDButton
                  className="ml-auto"
                  variant={BS_STYLE.PRIMARY}
                  disabled={projectOptions.length === 0 || areReviewsLoading}
                  onClick={handleOpenReviewModal}
                  btnIcon={ICON.EDIT}
                  label="Write review"
                />
              </div>
            </AllowAccessToManager>
          )}

          { tab === PROFILE_TABS.NOTES && (
            <AllowAccessToManager>
              <NoteForm
                orgId={activeOrg.id}
                subjectId={userCard.user.id}
                show={isNotesModalOpen}
                form="notes-add"
                onSubmit={values => dispatch(postNoteDS(params, values))}
                onCloseModal={handleCloseNoteModal}
                onSubmitSuccess={handleCloseNoteModal}
              />
              <div className="mt-auto text-right pr-4 pt-4 pb-4">
                <TDButton
                  className="ml-auto"
                  variant={BS_STYLE.PRIMARY}
                  disabled={areNotesLoading}
                  onClick={handleOpenNoteModal}
                  label="Add note"
                  btnIcon={ICON.EDIT}
                />
              </div>
            </AllowAccessToManager>
          )}
        </Card>

        {tab === PROFILE_TABS.EXPERIENCE && (
          <>
            <SkillsLanguagesWidget />
            { profile && profile.attachments && profile.attachments.length > 0 && (
              <Card className="mt-4">
                <Card.Header>
                  Attachments
                </Card.Header>
                <Card.Body>
                  <AttachmentsList label={null} attachments={profile.attachments} />
                </Card.Body>
              </Card>
            )}

            {(isManager || isThemself) && activeOrg.show_availability && (
              <UserTimeOffWidget isThemself={isThemself} orgAlias={activeOrg.alias} />
            )}
          </>
        )}

        {tab === PROFILE_TABS.RATES && <InvoiceCapWidget userCard={userCard} />}

        {tab === PROFILE_TABS.TAX_INFORMATION && (
          <ProfileExtraTaxWidgets parentComponentName={parentComponentName} userCard={userCard} />
        )}
      </>
    );
  }
}

PeopleProfileTabs.propTypes = {
  areNotesLoading: PropTypes.bool,
  activeOrg: PropTypes.object.isRequired,
  areReviewsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleCloseNoteModal: PropTypes.func.isRequired,
  handleCloseReviewModal: PropTypes.func.isRequired,
  handleOpenNoteModal: PropTypes.func.isRequired,
  handleOpenReviewModal: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  isNotesModalOpen: PropTypes.bool,
  isReviewModalOpen: PropTypes.bool,
  params: PropTypes.object,
  profile: PropTypes.object.isRequired,
  projectOptions: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  userCard: PropTypes.object.isRequired,
  viewerId: PropTypes.number.isRequired,
};

PeopleProfileTabs.defaultProps = {
  areNotesLoading: false,
  areReviewsLoading: false,
  isNotesModalOpen: false,
  isReviewModalOpen: false,
  params: {},
};

const mapStateToProps = (state, props) => {
  const hasOrgAccess = getHasOrgAccess(state);
  const profile = selectProfile(state);
  const areNotesLoading = state.requests && state.requests.PeopleNotesTab
    && state.requests.PeopleNotesTab.isLoading;
  const areReviewsLoading = state.requests && state.requests.PeopleReviewsTab
    && state.requests.PeopleReviewsTab.isLoading;

  return {
    hasOrgAccess,
    viewerId: profile?.userId,
    params: props.match.params,
    areNotesLoading,
    areReviewsLoading,
    activeOrg: selectActiveOrg(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PeopleProfileTabsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleProfileTabs);

export default withRouter(PeopleProfileTabsConnect);
