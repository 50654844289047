export const EXPORT_TYPES = {
  PDF: 'pdf',
  CSV: 'csv',
};

export const INVOICE_CSV_TYPE = {
  QUICKBOOKS: 'quickbooks',
  STANDARD: 'standard',
  XERO: 'xero',
};


/**
 * The order in which these headers are shown in each array is the order in
 * which they will appear in the exported files. Quickbooks and Xero column
 * order is standard and should be modified only if we know that the
 * standard file formatting of the respected software has changed.
 */

export const CSV_FILE_HEADERS = {
  [INVOICE_CSV_TYPE.STANDARD]: [
    'Owner name', 'Owner address', 'Owner email', 'Invoice No', 'Posted date',
    'Billing period', 'Grace period in days', 'Due date', 'Provider charges', 'Service fees',
    'Bank fees', 'VAT', 'Total', 'Status', 'Currency',
  ],
  [INVOICE_CSV_TYPE.QUICKBOOKS]: [
    '*InvoiceNo', '*Customer', '*InvoiceDate', '*DueDate', 'Terms', 'Location', 'Memo', 'Item(Product/Service)',
    'ItemDescription', 'ItemQuantity', 'ItemRate', '*ItemAmount', '*ItemTaxCode', 'ItemTaxAmount', 'Currency', 'Service Date',
  ],
  [INVOICE_CSV_TYPE.XERO]: [
    '*ContactName', 'EmailAddress', 'POAddressLine1', 'POAddressLine2', 'POAddressLine3',
    'POAddressLine4', 'POCity', 'PORegion', 'POPostalCode', 'POCountry', '*InvoiceNumber', 'Reference',
    '*InvoiceDate', '*DueDate', 'Total', 'InventoryItemCode', '*Description', '*Quantity',
    '*UnitAmount', 'Discount', '*AccountCode', '*TaxType', 'TaxAmount', 'TrackingName1',
    'TrackingOption1', 'TrackingName2', 'TrackingOption2', 'Currency', 'BrandingTheme',
  ],
};

/**
 * Here we are mapping each value with a file's header
 * E.g Header for Invoice No is (for each type):
 * Standard type CSV - Invoice No
 * Quickbooks type CSV - *InvoiceNo
 * Xero type CSV - *InvoiceNumber
 * If a header is not found in a specified csv type (check CSV_FILE_HEADERS)
 * it won't show up at all.
 * @see exportInvoicesCsv endpoint
 */
export const CSV_HEADERS_MAPPING = {
  BANK_FEES: { [INVOICE_CSV_TYPE.STANDARD]: 'Bank fees' },
  BILLING_PERIOD: { [INVOICE_CSV_TYPE.STANDARD]: 'Billing period', [INVOICE_CSV_TYPE.QUICKBOOKS]: 'Service Date' },
  CURRENCY: { [INVOICE_CSV_TYPE.STANDARD]: 'Currency', [INVOICE_CSV_TYPE.XERO]: 'Currency', [INVOICE_CSV_TYPE.QUICKBOOKS]: 'Currency' },
  DUE_DATE: { [INVOICE_CSV_TYPE.STANDARD]: 'Due date', [INVOICE_CSV_TYPE.QUICKBOOKS]: '*DueDate', [INVOICE_CSV_TYPE.XERO]: '*DueDate' },
  GRACE_PERIOD: { [INVOICE_CSV_TYPE.STANDARD]: 'Grace period in days' },
  INVOICE_NO: { [INVOICE_CSV_TYPE.STANDARD]: 'Invoice No', [INVOICE_CSV_TYPE.XERO]: '*InvoiceNumber', [INVOICE_CSV_TYPE.QUICKBOOKS]: '*InvoiceNo' },
  OWNER_ADDRESS: { [INVOICE_CSV_TYPE.STANDARD]: 'Owner address' },
  OWNER_EMAIL: { [INVOICE_CSV_TYPE.STANDARD]: 'Owner email' },
  OWNER_NAME: { [INVOICE_CSV_TYPE.STANDARD]: 'Owner name' },
  POSTED_DATE: { [INVOICE_CSV_TYPE.STANDARD]: 'Posted date', [INVOICE_CSV_TYPE.QUICKBOOKS]: '*InvoiceDate', [INVOICE_CSV_TYPE.XERO]: '*InvoiceDate' },
  PROVIDER_CHARGES: { [INVOICE_CSV_TYPE.STANDARD]: 'Provider charges', [INVOICE_CSV_TYPE.QUICKBOOKS]: '*ItemAmount' },
  SERVICE_FEES: { [INVOICE_CSV_TYPE.STANDARD]: 'Service fees' },
  STATUS: { [INVOICE_CSV_TYPE.STANDARD]: 'Status' },
  TOTAL: { [INVOICE_CSV_TYPE.STANDARD]: 'Total', [INVOICE_CSV_TYPE.XERO]: 'Total' },
  VAT: { [INVOICE_CSV_TYPE.STANDARD]: 'VAT', [INVOICE_CSV_TYPE.XERO]: 'TaxAmount', [INVOICE_CSV_TYPE.QUICKBOOKS]: 'ItemTaxAmount' },
  CUSTOMER: { [INVOICE_CSV_TYPE.XERO]: '*ContactName', [INVOICE_CSV_TYPE.QUICKBOOKS]: '*Customer' },
  CUSTOMER_EMAIL: { [INVOICE_CSV_TYPE.XERO]: 'EmailAddress' },
  COUNTRY: { [INVOICE_CSV_TYPE.XERO]: 'POCountry' },
  CITY: { [INVOICE_CSV_TYPE.XERO]: 'POCity' },
  STREET: { [INVOICE_CSV_TYPE.XERO]: 'POAddressLine1' },
  POSTAL_CODE: { [INVOICE_CSV_TYPE.XERO]: 'POPostalCode' },
  LOCATION: { [INVOICE_CSV_TYPE.QUICKBOOKS]: 'Location' },
};

export const MAX_MONTHS_EXPORT_DURATION = 12;
