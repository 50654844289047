import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import DocumentForm from 'documents/assets/js/components/DocumentForm.jsx';
import { DOCUMENT_DETAILS_PROPERTIES } from 'documents/assets/js/constants';
import { documentNewVersionDS, publishDocumentDS } from 'documents/assets/js/data-services/form';
import DocumentListView from 'documents/assets/js/DocumentListView.jsx';
import { fetchDocumentDS } from 'documents/assets/js/data-services/view';
import { documentSpec } from 'documents/assets/js/lib/objectSpecs';
import { parseDocumentValues, parseExistingDocumentValues } from 'documents/assets/js/lib/utils';
import { documentsUrl } from 'documents/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const DocumentNewVersionView = ({
  activeOrg, dispatch, document, history, match: { params },
}) => {
  const { id } = params;
  const [savingDraft, setSavingDraft] = useState(false);

  const componentName = DocumentNewVersionView.GetComponentName({ params });

  const saveDraft = async (formValues, setPageNumber, { alsoPublishing = false } = {}) => {
    setSavingDraft(true);
    try {
      // omit any form values used for controlling the UI, but not needed by the API
      const values = parseDocumentValues(formValues);
      const listComponentName = DocumentListView.GetComponentName();
      const response = await dispatch(documentNewVersionDS({
        id, listComponentName, orgAlias: activeOrg.alias, values,
      }));
      if (alsoPublishing) {
        await dispatch(publishDocumentDS({
          id: response.id, listComponentName, orgAlias: activeOrg.alias,
        }));
        dispatch(modalCloseAC());
      }
      toastr.success(
        'Well Done!',
        `Your new version was ${alsoPublishing ? 'published' : 'created'} successfully.`,
      );
      history.push(documentsUrl(activeOrg.alias));
    } catch (error) {
      let errorMessage = error?.errors?._error || error.message;
      const fieldErrors = Object.entries(omit(error?.errors || {}, '_error', '_meta'));
      if (fieldErrors.length > 0) {
        errorMessage = fieldErrors.map(([key, value]) => `${key}: ${value}`).join('; ');
      }
      toastr.error('Oh Snap!', errorMessage);
      if (
        error.errors?._meta?.isValidation
        && Object.keys(error.errors).some(key => DOCUMENT_DETAILS_PROPERTIES.includes(key))
      ) {
        // Redirect to Details page
        setPageNumber(0);
      }
      if (alsoPublishing) {
        dispatch(modalCloseAC());
      }
    } finally {
      setSavingDraft(false);
    }
  };

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Contract Templates', url: documentsUrl(activeOrg.alias) },
          { title: 'New version', url: null },
        ]}
      />
      <div className="page page--documents">
        <div className="container">
          <TDApiConnected
            duck="view"
            fetchData={({ authedAxios }) => dispatch(fetchDocumentDS({
              authedAxios,
              componentName,
              id,
              orgAlias: activeOrg.alias,
            }))}
            blockingLoading
            storeKey={componentName}
          >
            {!document?.allowedActions?.canCreateNewVersion && (
              <div className="text-center p-5 mt-5">
                You cannot create a new version of this contract template
              </div>
            )}
            {document?.allowedActions?.canCreateNewVersion && (
              <DocumentForm
                contentPrefix={(
                  <p data-testid="document-new-version-content-prefix">
                    {'This will be a new version of contract template '}
                    <b>{document.title}</b>
                    {' ('}
                    <b>{document.version}</b>
                    )
                  </p>
                )}
                initialValues={parseExistingDocumentValues(activeOrg.currency, document, true)}
                onSubmit={(formValues, setPageNumber) => saveDraft(
                  formValues, setPageNumber, { alsoPublishing: true },
                )}
                secondarySubmitAction={saveDraft}
                secondarySubmitButtonTitle="Save draft"
                secondarySubmitLoading={savingDraft}
                versionHelperText={`Your previous document version number was: ${document.version}`}
                versionNotIn={document.versionNumbers}
                versionSubLabel="Add a new version number for the contract template"
              />
            )}
          </TDApiConnected>
        </div>
      </div>
    </>
  );
};

DocumentNewVersionView.GetComponentName = ({ params: { id }  }) => `DocumentNewVersionView-${id}`;

DocumentNewVersionView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  document: documentSpec.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapStateToProps = (state, { match: { params } }) => ({
  activeOrg: selectActiveOrg(state),
  document: getViewState(state, DocumentNewVersionView.GetComponentName({ params })).item,
});

const DocumentNewVersionViewConnected = connect(
  mapStateToProps,
)(DocumentNewVersionView);

export default withRouter(DocumentNewVersionViewConnected);
