import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { resetOnboardingFormApiUrl, submissionUrl } from 'interviews/urls';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { orgPeopleListUrl } from 'people/urls';

export const MODAL_ID = 'reset-onboarding-form-modal';

const ResetOnboardingFormModal = ({ submissionId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orgAlias } = useParams();

  const resetOnboardingForm = async () => {
    try {
      const { data: newSubmission } = await axios.post(resetOnboardingFormApiUrl({
        orgAlias, submissionId,
      }));
      toastr.success('Well Done!', 'Onboarding form successfully reset.');
      if (newSubmission && newSubmission.id) {
        // Have to use WINDOW_LOCATION_ASSIGN to load the new submission. Using history.push when
        // the new route is the same as the current one, prevents it from calling FetchData
        history.replace(submissionUrl(orgAlias, newSubmission.id));
      } else {
        // The logged in user is a manager or financial controller and the new submission does not
        // contain any questions they can answer. So they cannot access it
        history.push(orgPeopleListUrl(orgAlias, PEOPLE_TYPE.PROVIDERS));
      }
    } catch (e) {
      toastr.error('Oh Snap!', e.response?.data?._error || e.message);
      // This is required to prevent the modal thinking confirm was successful and then closing
      // automatically
      throw new Error(e);
    }
  };

  return (
    <ModalConfirm
      confirmLabel="Reset"
      data-testid="reset-onboarding-form-modal"
      heading="Reset this onboarding form"
      open={useSelector(state => getIsModalOpen(state, MODAL_ID))}
      onClose={() => dispatch(modalCloseAC())}
      onConfirm={resetOnboardingForm}
    >
      Are you sure you want to reset this onboarding form? The existing onboarding submission and
      any answers provided will be deleted and the user will be notified to re-submit their answers.
    </ModalConfirm>
  );
};

ResetOnboardingFormModal.propTypes = {
  submissionId: PropTypes.number.isRequired,
};

export default ResetOnboardingFormModal;
