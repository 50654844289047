import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { get, set, pick } from 'lodash';

import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { settingsPaymentsSubPageUrl, settingsBankAccountOrgSelectUrl } from 'settings/urls';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { updateBankAccountDS, createPayoneerRegistrationLinkDS } from 'settings/assets/js/data-services/settings';
import { BANK_ACCOUNT_TYPE, PAYMENT_METHODS, PAYMENT_METHOD_DETAILS } from 'settings/assets/js/constants';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';

class PayoneerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirecting: false,
      isRedirectModalOpen: false,
      registrationLink: null,
    };
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleModalClose() {
    this.setState({
      isRedirectModalOpen: false,
    });
  }

  handleModalShow() {
    this.setState({
      isRedirectModalOpen: true,
    });
  }

  async submit(values) {
    const {
      activeOrg, history, bankAccount, dispatch, match: { params: { orgAlias } },
    } = this.props;
    const hasBankAccount = !!get(bankAccount, 'id');

    const toPost = {
      id: bankAccount.id,
      orgAlias,
      paymentMethodType: PAYMENT_METHODS.PAYONEER,
      bank_name: PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.PAYONEER].name,
      bank_account_type: BANK_ACCOUNT_TYPE.PAYONEER,
      ...pick(values, [
        'alias', 'beneficiary', 'custom_reference',
      ]),
    };

    try {
      if (hasBankAccount) {
        await updateBankAccountDS(toPost, dispatch);
        history.push(settingsBankAccountOrgSelectUrl(
          activeOrg.alias, PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.PAYONEER].alias, bankAccount.id,
        ));
      } else {
        const registrationLink = await createPayoneerRegistrationLinkDS(toPost, dispatch);
        this.setState({
          registrationLink,
        }, () => this.handleModalShow());
      }
    } catch (err) {
      const res = { [FORM_ERROR]: err.errors._error };
      Object.entries(err.errors).forEach(([key, value]) => {
        set(res, key, value);
      });
      return res;
    }
    return null;
  }

  render() {
    const { activeOrg, bankAccount, history } = this.props;
    const { isRedirectModalOpen, registrationLink, isRedirecting } = this.state;
    const hasBankAccount = !!get(bankAccount, 'id');
    let initialValues = {};
    if (hasBankAccount) {
      initialValues = {
        ...bankAccount,
      };
    }
    const advancedFields = ['custom_reference'];
    return (
      <React.Fragment>
        <Form
          onSubmit={this.submit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit} className="p-0">
                <div className="rounded bg-white p-4 shadow-sm">
                  <TDSystemMessage
                    title="Payments support information"
                    type={BS_STYLE.INFO}
                    className="mb-4"
                  >
                    <p>
                      Please note we support payments in
                      <strong> US dollars only </strong>
                      and no other currencies
                      via Payoneer.  Also, we are unable to pay any amount less than $7.  In the
                      case of a payout for less than this amount we will contact you to either
                      make payment to a different account or carry over the outstanding amount
                      to a future payment, once the payment exceeds $7.
                    </p>
                  </TDSystemMessage>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <TextInputField
                        name="beneficiary"
                        required
                        label="Full name of the account holder"
                        validate={val => (!val ? 'Field is required' : null)}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <TextInputField
                        name="alias"
                        required
                        label="Friendly name for your account"
                        validate={val => (!val ? 'Field is required' : null)}
                      />
                    </div>
                    <div className="col-12">
                      <AreaCollapsible
                        containerElement="div"
                        className="mb-5 w-100"
                        fieldSet
                        // expand advanced fields if any of the advanced fields is filled
                        isExpanded={advancedFields.some(field => initialValues[field])}
                        contentChildren={(
                          <div className="row">
                            <div className="col-6">
                              <TextInputField
                                name="custom_reference"
                                placeholder="e.g acustomreference"
                                extraInfo="You can add a custom reference message. We'll ensure we pass it to the bank every time we make a transfer."
                                label="Payee reference message"
                              />
                            </div>
                          </div>
                        )}
                        headingChildren={<span>Advanced Fields</span>}
                      />
                    </div>
                  </div>
                </div>
                <div className="button-container mt-4 text-right">
                  <TDButton
                    className="btn btn-lg btn-default"
                    label="Cancel"
                    type="button"
                    onClick={() => {
                      history.push(settingsPaymentsSubPageUrl(activeOrg.alias));
                    }}
                  />
                  <TDButton
                    disabled={submitting || pristine || isRedirectModalOpen}
                    label={hasBankAccount ? 'Save' : 'Proceed'}
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                  />
                </div>
              </form>
            );
          }}
        />
        <ModalSimple
          open={isRedirectModalOpen}
          body={(
            <div className="payoneer-redirect-modal">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <i className={`my-5 ${ICON.TRANSPORTER}`} />
                <h1 className="text-center">
                  You will be redirected to Payoneer!
                </h1>
                <div className="mb-5 d-flex align-items-center justify-content-center">
                  <img
                    className="payoneer-logo"
                    alt="Payoneer logo"
                    src={PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.PAYONEER].logo}
                  />
                  <div className="payoneer-name">Payoneer</div>
                </div>
              </div>
              <p className="text-center">
                In order to create a new or link your existing Payoneer
                account you will get redirected to the Payoneer&apos;s
                website. Ready to start?
              </p>
            </div>
          )}
          onClose={this.handleModalClose}
          footer={[
            <TDButton
              key={1}
              disabled={isRedirecting}
              className="float-right"
              label="Cancel"
              onClick={this.handleModalClose}
            />,
            <TDButton
              key={2}
              className="float-right"
              disabled={isRedirecting}
              label={isRedirecting ? 'Redirecting...' : 'Continue'}
              variant={BS_STYLE.SUCCESS}
              onClick={() => {
                this.setState({
                  isRedirecting: true,
                });
                WINDOW_REDIRECT(registrationLink);
              }}
            />,
          ]}
        />
      </React.Fragment>
    );
  }
}

PayoneerForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  bankAccount: bankAccountSpec,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

PayoneerForm.defaultProps = {
  bankAccount: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PayoneerFormConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(PayoneerForm);

export default withRouter(PayoneerFormConnected);
