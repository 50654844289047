import React from 'react';
import PropTypes from 'prop-types';
import { get, isNumber } from 'lodash';
import { connect } from 'react-redux';

import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { INVOICING_MODE } from 'finance/assets/js/constants';
import SalesTaxForm from 'settings/assets/js/components/SalesTaxForm.jsx';
import { BS_TOOLTIP_PLACEMENT, ICON } from 'core/assets/js/constants';
import { COMPANY_TAX_SYSTEM, COMPANY_TAX_SYSTEM_INFO } from 'settings/assets/js/constants';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import { financeDefaultInvoicingEntityApiUrl } from 'finance/urls';

export const defaultInvoicingEntityKey = 'defaultInvoicingEntity';

class SalesTaxItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formModalOpen: false,
    };
    this.closeFormModal = this.closeFormModal.bind(this);
  }

  closeFormModal() {
    this.setState({ formModalOpen: false });
  }

  render() {
    const { formModalOpen } = this.state;
    const {
      activeOrg, companyTaxSystemInfo, onVatSettingsUpdated, sysOrg, userCard, vatPercentOverride,
      defaultInvoicingEntity,
    } = this.props;
    const ucInvoicingVatPercent = vatPercentOverride === null
      ? userCard.invoicing_vat_percent
      : vatPercentOverride;
    const canSetSalesTax = userCard?.allowedActions?.canSetSalesTax;
    const taxSystemType = get(userCard, 'organization.billing_details.tax_system_type');
    let orgTaxSystemNumber;
    let taxSystemLabel;
    if (taxSystemType) {
      taxSystemLabel = COMPANY_TAX_SYSTEM_INFO[taxSystemType].label;
      orgTaxSystemNumber = get(
        userCard,
        `organization.billing_details[${COMPANY_TAX_SYSTEM_INFO[taxSystemType].systemLabel}]`,
      );
    }
    const invoicingViaTalentDesk = (activeOrg.invoicing_mode === INVOICING_MODE.VIA);
    const ucVatPercent = isNumber(ucInvoicingVatPercent)
      ? ` ${ucInvoicingVatPercent}%`
      : ' Not set';

    return (
      <div className="vat-settings-item__preview">
        <div className="row">
          <div className="col-12 col-md-6">
            <TDApiConnected
              duck="view"
              storeKey={defaultInvoicingEntityKey}
              fetchData={({ dispatch, authedAxios, componentName }) => (
                dispatch(fetchViewDS({
                  authedAxios, componentName,
                  url: financeDefaultInvoicingEntityApiUrl(),
                }))
              )}
            >
              <div>
                <span className="organization-list-item__title">
                  {invoicingViaTalentDesk ? defaultInvoicingEntity?.name : activeOrg.name}
                </span>
                <span className="organization-list-item__reg-number">
                  {invoicingViaTalentDesk && (
                    <>
                      {COMPANY_TAX_SYSTEM_INFO[COMPANY_TAX_SYSTEM.VAT].label}
                      {` ${defaultInvoicingEntity?.taxNumber} `}
                      <TDElementWithTooltip
                        el={<i className={ICON.INFO_2} />}
                        id="vat-settings-info"
                        placement={BS_TOOLTIP_PLACEMENT.TOP}
                        tooltipMsg={`TalentDesk.io handles invoicing on behalf of ${activeOrg.name}`}
                      />
                    </>
                  )}
                  {!invoicingViaTalentDesk && taxSystemLabel && (
                    `${taxSystemLabel} ${orgTaxSystemNumber}`
                  )}
                </span>
              </div>
            </TDApiConnected>
          </div>

          <div className="col-12 col-md-6 my-2 my-md-0 text-md-right organization-list-item__vat d-flex d-md-block">
            <span>
              {`Your ${companyTaxSystemInfo.rateLabel}: `}
              <strong>{ucVatPercent}</strong>
            </span>
            {canSetSalesTax && (
              <TDElementWithTooltip tooltipMsg={`Set up your ${companyTaxSystemInfo.rateLabel}`}>
                <a
                  className="vat-setup-btn text-primary ml-4"
                  onClick={() => {
                    if (!canSetSalesTax) {
                      return;
                    }
                    this.setState({ formModalOpen: true });
                  }}
                >
                  Edit
                </a>
              </TDElementWithTooltip>
            )}
          </div>
        </div>

        <SalesTaxForm
          companyTaxSystemInfo={companyTaxSystemInfo}
          initialValues={userCard}
          activeOrg={activeOrg}
          show={formModalOpen}
          onSubmit={onVatSettingsUpdated}
          onCloseModal={this.closeFormModal}
          sysOrg={invoicingViaTalentDesk ? sysOrg : null}
        />
      </div>
    );
  }
}

SalesTaxItem.propTypes = {
  activeOrg: orgSpec.isRequired,
  userCard: userCardSpec.isRequired,
  companyTaxSystemInfo: PropTypes.object.isRequired,
  sysOrg: orgSpec,
  onVatSettingsUpdated: PropTypes.func,
  defaultInvoicingEntity: PropTypes.object,
  vatPercentOverride: PropTypes.number,
};

SalesTaxItem.defaultProps = {
  sysOrg: null,
  defaultInvoicingEntity: {},
  onVatSettingsUpdated: () => {},
  vatPercentOverride: null,
};

const mapStateToProps = state => ({
  defaultInvoicingEntity: getViewState(state, defaultInvoicingEntityKey)
    ?.item?.defaultInvoicingEntity,
});


const SalesTaxItemConnected = connect(
  mapStateToProps,
)(SalesTaxItem);

export default SalesTaxItemConnected;
