import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { settingsPaymentTermsApiUrl } from 'settings/urls';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import SettingsItem from 'core/assets/js/components/SettingsItem.jsx';
import PaymentTermsForm from 'settings/assets/js/components/PaymentTermsForm.jsx';
import InvoiceNumberForm from 'settings/assets/js/components/InvoiceNumberForm.jsx';
import { postPaymentSettingDS } from 'settings/assets/js/data-services/settings';
import ProviderBillingDetailsForm from 'settings/assets/js/components/ProviderBillingDetailsForm.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const ProfilePaymentTermsTab = () => {
  const dispatch = useDispatch();

  const componentName = ProfilePaymentTermsTab.GetComponentName();

  const { hasLoaded, item: paymentSetting } = fetchDataHook({
    componentName, url: settingsPaymentTermsApiUrl(),
  });

  const activeOrg = useSelector(selectActiveOrg);

  if (!hasLoaded) {
    return <SettingsPageSkeleton />;
  }

  const freeBillingDetailsText = paymentSetting?.free_billing_details_text;

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <div className="settings-row">
        <SettingsItem
          label="Payment terms"
          preview={`${paymentSetting.grace_period} days`}
          disclaimer="This will appear in your invoices under the terms section"
          cta={(
            <PaymentTermsForm
              initialValues={paymentSetting}
              onSubmit={values => postPaymentSettingDS(
                values, dispatch, ProfilePaymentTermsTab.GetComponentName(),
              )}
            />
          )}
        />
        <SettingsItem
          label="Invoice number and payment reference"
          preview={paymentSetting.next_custom_number}
          disclaimer="Set a custom invoice number and see your payment reference"
          cta={(
            <InvoiceNumberForm
              initialValues={paymentSetting}
              onSubmit={values => postPaymentSettingDS(
                values, dispatch, ProfilePaymentTermsTab.GetComponentName(),
              )}
              orgAlias={activeOrg.alias}
            />
          )}
        />
        <SettingsItem
          containerClassName="pb-0"
          label="Additional billing details"
          cta={(
            <ProviderBillingDetailsForm
              initialValues={paymentSetting}
              onSubmit={values => (postPaymentSettingDS(
                values, dispatch, ProfilePaymentTermsTab.GetComponentName(),
              ))}
            />
          )}
        />
        <div className="px-3">
          {freeBillingDetailsText && (
            <Card>
              <Card.Body>{freeBillingDetailsText}</Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

ProfilePaymentTermsTab.GetComponentName = () => 'ProfilePaymentTermsTab';

export default ProfilePaymentTermsTab;
