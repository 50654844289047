import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import { ACTIVITY_TYPE } from 'notifier/assets/js/constants';
import { projectTaskSpec, projectSpec } from 'projects/assets/js/lib/objectSpecs';
import CTARow from 'core/assets/js/components/DiscussionBoard/CTARow.jsx';
import SystemMessage from 'core/assets/js/components/DiscussionBoard/messages/SystemMessage.jsx';
import UserMessage from 'core/assets/js/components/DiscussionBoard/messages/UserMessage.jsx';
import TaskStateActions from 'core/assets/js/components/DiscussionBoard/TaskStateActions.jsx';
import TaskAssignmentCompletedMessage from 'core/assets/js/components/DiscussionBoard/messages/TaskAssignmentCompletedMessage.jsx';

const SUCCESS_MESSAGE_TYPES = [
  ACTIVITY_TYPE.TASK_COMPLETED,
  ACTIVITY_TYPE.TASK_ASSIGNMENT_REVIEWED,
  ACTIVITY_TYPE.TASK_COMPLETION_ACCEPTED,
  ACTIVITY_TYPE.TASK_ASSIGNMENT_ACCEPTED,
  ACTIVITY_TYPE.TASK_RATE_AMENDMENT_ACCEPTED,
];

const FAILURE_MESSAGE_TYPES = [
  ACTIVITY_TYPE.TASK_COMPLETION_CANCELLED,
  ACTIVITY_TYPE.TASK_ASSIGNMENT_CANCELLED,
  ACTIVITY_TYPE.TASK_RATE_AMENDMENT_CANCELLED,
  ACTIVITY_TYPE.TASK_STOPPED,
  ACTIVITY_TYPE.TASK_COMPLETION_REJECTED,
  ACTIVITY_TYPE.TASK_ASSIGNMENT_REJECTED,
  ACTIVITY_TYPE.TASK_ASSIGNMENT_LEFT,
  ACTIVITY_TYPE.TASK_ASSIGNMENT_REMOVED,
  ACTIVITY_TYPE.TASK_RATE_AMENDMENT_REJECTED,
];

const CUSTOM_MESSAGE_TYPES = {
  [ACTIVITY_TYPE.TASK_ASSIGNMENT_COMPLETED]: {
    component: TaskAssignmentCompletedMessage,
    showUserMessage: true,
    propsGenerator: message => ({
      timestamp: message.createdAt,
      message: message.body,
    }),
  },
  [ACTIVITY_TYPE.TASK_COMPLETION_ACCEPTED]: {
    component: TaskAssignmentCompletedMessage,
    showUserMessage: true,
    propsGenerator: message => ({
      timestamp: message.createdAt,
      message: message.body,
    }),
  },
  [ACTIVITY_TYPE.MESSAGE_DIRECT]: {
    component: UserMessage,
    showUserMessage: false,
    propsGenerator: message => ({
      timestamp: message.createdAt,
      isSender: message.isSender,
      profile: message.user.profile,
      body: message.context.message,
    }),
  },
  [ACTIVITY_TYPE.MESSAGE_BROADCAST]: {
    component: UserMessage,
    showUserMessage: false,
    propsGenerator: message => ({
      timestamp: message.createdAt,
      isSender: message.isSender,
      profile: message.user.profile,
      body: message.context.message,
    }),
  },
};

const MessagesList = ({
  accessControl,
  actions,
  hasMoreMessages,
  hasPendingDocuments,
  isEmbeddedMode,
  messages,
  messagesLoading,
  onAttachmentUpdated,
  onLoadMoreMessages,
  onSignAndAccept,
  onStateUpdated,
  onTaskUpdated,
  parentComponentName,
  project,
  stateName,
  task,
  taskAttachments,
}) => {
  const list = orderBy(messages, ['id'], ['asc']).map((message) => {
    const key = `messages-${message.id}`;
    const attachments = message && message.context && Array.isArray(message.context.attachments)
      ? message.context.attachments.map(att => taskAttachments[att.handle]).filter(att => !!att)
      : [];

    if (CUSTOM_MESSAGE_TYPES[message.type]) {
      const {
        component: MessageComponent, showUserMessage, propsGenerator,
      } = CUSTOM_MESSAGE_TYPES[message.type];

      const messageProps = {
        ...propsGenerator(message),
        attachments,
        onAttachmentUpdated,
      };

      return (
        <React.Fragment key={key}>
          {showUserMessage && !!message.context && !!message.context.message && (
            <UserMessage
              timestamp={message.createdAt}
              isSender={message.isSender}
              profile={message.user.profile}
              body={message.context.message}
              attachments={attachments}
              onAttachmentUpdated={onAttachmentUpdated}
              isSuccess={SUCCESS_MESSAGE_TYPES.includes(message.type)}
              isFailure={FAILURE_MESSAGE_TYPES.includes(message.type)}
            />
          )}
          <MessageComponent
            key={key}
            actions={actions}
            task={task}
            project={project}
            parentComponentName={parentComponentName}
            {...messageProps}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={key}>
        {!!message.context && !!message.context.message && (
          <UserMessage
            timestamp={message.createdAt}
            isSender={message.isSender}
            profile={message.user.profile}
            body={message.context.message}
            attachments={attachments}
            onAttachmentUpdated={onAttachmentUpdated}
            isSuccess={SUCCESS_MESSAGE_TYPES.includes(message.type)}
            isFailure={FAILURE_MESSAGE_TYPES.includes(message.type)}
          />
        )}
        <SystemMessage
          timestamp={message.createdAt}
          message={message.body}
        />
      </React.Fragment>
    );
  });

  if (hasMoreMessages) {
    const loadMore = (
      <a
        key="pager-link"
        onClick={onLoadMoreMessages}
        className="imitate-link"
      >
        {messagesLoading ? 'Loading more...' : 'Load more'}
      </a>
    );

    list.unshift((
      <CTARow
        key="pager-cta-row"
        CTAButtons={[loadMore]}
      />
    ));
  }

  return (
    <div className="discussion-board__messages d-flex flex-row align-items-end">
      <div className="discussion-board__messages-wrapper w-100">
        <div className="discussion-board_messages-list">
          {list}
          {actions && (
            <TaskStateActions
              accessControl={accessControl}
              actions={actions}
              hasPendingDocuments={hasPendingDocuments}
              isEmbeddedMode={isEmbeddedMode}
              onSignAndAccept={onSignAndAccept}
              onStateUpdated={onStateUpdated}
              onTaskUpdated={onTaskUpdated}
              parentComponentName={parentComponentName}
              project={project}
              stateName={stateName}
              task={task}
            />
          )}
        </div>
      </div>
    </div>
  );
};

MessagesList.propTypes = {
  accessControl: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  hasMoreMessages: PropTypes.bool,
  hasPendingDocuments: PropTypes.bool,
  isEmbeddedMode: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.object),
  messagesLoading: PropTypes.bool,
  onAttachmentUpdated: PropTypes.func,
  onLoadMoreMessages: PropTypes.func,
  onSignAndAccept: PropTypes.func.isRequired,
  onStateUpdated: PropTypes.func.isRequired,
  onTaskUpdated: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  project: projectSpec.isRequired,
  stateName: PropTypes.string,
  task: projectTaskSpec.isRequired,
  taskAttachments: PropTypes.object,
};

MessagesList.defaultProps = {
  accessControl: {},
  actions: [],
  hasMoreMessages: false,
  hasPendingDocuments: false,
  isEmbeddedMode: false,
  messages: [],
  messagesLoading: false,
  onAttachmentUpdated: () => {},
  onLoadMoreMessages: () => {},
  stateName: null,
  taskAttachments: [],
};

export default MessagesList;
