import React from 'react';
import PropTypes from 'prop-types';

import FreelancerChatList from 'people/assets/js/components/FreelancerChatList.jsx';

const DiscoverPeopleChatView = ({ showChatsOnly }) => {
  return (
    <div className="page page--people">
      <div className="container">
        <FreelancerChatList showChatsOnly={showChatsOnly} />
      </div>
    </div>
  );
};
DiscoverPeopleChatView.GetComponentName = () => 'DiscoverPeopleChatView';

DiscoverPeopleChatView.propTypes = {
  showChatsOnly: PropTypes.bool,
};

DiscoverPeopleChatView.defaultProps = {
  showChatsOnly: false,
};

export default DiscoverPeopleChatView;
