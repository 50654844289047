import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';

export const ListActionsButton = ({ options, wrapperClassName }) => (
  <div className={wrapperClassName}>
    <TDDropButton
      overlayClassName="actions-dropdown"
      toggleEl={(
        <TDButton
          btnIcon={<i className={ICON.ELLIPSIS} />}
          className="px-3"
          variant={BS_STYLE.DEFAULT}
        />
      )}
    >
      {options.map(({ disabled, name, onClick, className }) => (
        <Dropdown.Item
          className={className}
          disabled={disabled}
          key={name}
          onClick={onClick}
        >
          {name}
        </Dropdown.Item>
      ))}
    </TDDropButton>
  </div>
);

ListActionsButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
  wrapperClassName: PropTypes.string,
};

ListActionsButton.defaultProps = {
  wrapperClassName: 'd-block pr-4 pb-4 ml-auto',
};

export default ListActionsButton;
