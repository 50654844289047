import { omit, pick } from 'lodash';
import memoize from 'memoize-one';
import { createSelector } from 'reselect';

import { REQ_TYPE } from 'core/assets/js/constants';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';

// Action types
export const VIEW_FETCH = 'redux-view/FETCH';
export const VIEW_FETCH_ERROR = 'redux-view/FETCH_ERROR';
export const VIEW_FETCH_EXTRAS = 'redux-view/FETCH_EXTRAS';
export const VIEW_IS_LOADING = 'redux-view/IS_LOADING';
export const VIEW_RESET = 'redux-view/RESET';
export const VIEW_UPDATE = 'redux-view/UPDATE';
export const VIEW_REPLACE = 'redux-view/REPLACE';
export const VIEW_UPDATE_KEYS = 'redux-view/UPDATE_KEYS';
export const VIEW_UPDATE_EXTRAS = 'redux-view/UPDATE_EXTRAS';

// Reducer
export const componentViewInitialState = {
  isLoading: false,
  extras: {},
  item: {},
  httpErrorCode: null,
  touched: false,
  hasLoaded: false,
};

export const viewInitialState = {};

export const componentReducer = (state = componentViewInitialState, action) => {
  switch (action.type) {
    case VIEW_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasLoaded: !action.isLoading,
      };
    case VIEW_FETCH:
      return {
        ...state,
        item: action.item,
        touched: false,
        isLoading: false,
        hasLoaded: true,
      };
    case VIEW_REPLACE:
      return {
        ...state,
        item: action.item,
      };
    case VIEW_FETCH_ERROR:
      return {
        ...state,
        httpErrorCode: action.httpErrorCode,
      };
    case VIEW_FETCH_EXTRAS:
      return {
        ...state,
        extras: (
          action.extrasKey ? { ...state.extras, [action.extrasKey]: action.extras } : action.extras
        ),
      };
    case VIEW_RESET:
      return {
        ...viewInitialState,
        touched: false,
      };
    case VIEW_UPDATE:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.item,
        },
        touched: true,
      };
    case VIEW_UPDATE_KEYS:
      return {
        ...state,
        item: {
          ...state.item,
          ...pick(action.item, action.keys),
        },
      };
    case VIEW_UPDATE_EXTRAS:
      return {
        ...state,
        extras: {
          ...state.extras,
          ...action.extras,
        },
      };
    default:
      return state;
  }
};

export const reducer = (state = viewInitialState, action) => {
  if (action.type && action.type.startsWith('redux-view') && !action.componentName) {
    throw new Error(`cannot use view duck without specifying a component name on action ${action.type}`);
  }
  const { componentName } = action;
  const componentState = componentReducer(state[componentName], action);
  let newState = state;
  switch (action.type) {
    case VIEW_IS_LOADING:
    case VIEW_FETCH:
    case VIEW_REPLACE:
    case VIEW_FETCH_ERROR:
    case VIEW_FETCH_EXTRAS:
    case VIEW_UPDATE:
    case VIEW_UPDATE_EXTRAS:
    case VIEW_UPDATE_KEYS:
      newState = {
        ...state,
        [componentName]: componentState,
      };
      break;
    case VIEW_RESET:
      newState = omit(state, componentName);
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

const _getViewStateSelector = createSelector(
  state => state.view,
  viewState => memoize(
    componentName => {
      if (!componentName) {
        throw new Error('cannot get view state without specifying component name');
      }
      return viewState[componentName] || componentViewInitialState;
    },
  ),
);

/**
 * Return the view state if the data currently saved in the store belong to the active component
 *
 * @param state
 * @param componentName
 * @returns {*}
 */
export const getViewState = (state, componentName) => _getViewStateSelector(state)(componentName);

const _getViewStateExtrasSelector = createSelector(
  state => state.view,
  viewState => memoize(
    (componentName, extrasKey, defaultValue = {}) => {
      if (!componentName) {
        throw new Error('cannot get view state extras without specifying component name');
      }
      const { extras } = viewState[componentName] || componentViewInitialState;
      return extras[extrasKey] || defaultValue;
    },
  ),
);

export const getViewStateExtras = (state, componentName, extrasKey, defaultValue = {}) => (
  _getViewStateExtrasSelector(state)(componentName, extrasKey, defaultValue)
);

// Action creators
export const viewIsLoadingAC = (bool, componentName) => ({
  type: VIEW_IS_LOADING,
  componentName,
  isLoading: bool,
});

export const viewFetchAC = (item, componentName) => ({
  type: VIEW_FETCH,
  componentName,
  item,
});

export const viewFetchErrorAC = (error, componentName) => {
  if (!componentName) {
    throw new Error('cannot dispatch view action without component name');
  }
  if (!error) {
    throw new Error('cannot dispatch error action without error');
  }
  return {
    type: VIEW_FETCH_ERROR,
    componentName,
    httpErrorCode: error.response.status,
  };
};

export const viewFetchExtrasAC = (extras, componentName, extrasKey = null) => ({
  type: VIEW_FETCH_EXTRAS,
  componentName,
  extras,
  extrasKey,
});

export const viewReplaceAC = (item, componentName) => ({
  type: VIEW_REPLACE,
  item,
  componentName,
});

export const viewResetAC = componentName => ({
  type: VIEW_RESET,
  componentName,
});

export const viewUpdateAC = (item, componentName) => ({
  type: VIEW_UPDATE,
  item,
  componentName,
});

export const viewUpdateKeysAC = (item, keys, componentName) => ({
  type: VIEW_UPDATE_KEYS,
  item,
  keys,
  componentName,
});

export const extrasUpdateAC = (extras, componentName) => ({
  type: VIEW_UPDATE_EXTRAS,
  extras,
  componentName,
});

// Data services
export const fetchViewDS = ({ authedAxios = null, componentName = '', url, validate, querystring }) => (
  fetchDataDS({
    authedAxios,
    validate,
    componentName,
    querystring,
    fetchApiUrl: () => url,
    fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  })
);

export const putViewDS = ({ values, url, componentName }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: url,
    pushDataAC: responseData => viewUpdateAC(responseData, componentName),
    values,
  })
);

export const pushViewDS = ({ values = {}, url, componentName }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: url,
    pushDataAC: responseData => viewUpdateAC(responseData, componentName),
    values,
  })
);

export default reducer;
