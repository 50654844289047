import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { POPPER_PLACEMENT } from 'core/assets/js/constants';
import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const loremIpsum = 'Lorem ipsum dolor sit amet, ex adipiscing omittantur mel, vel eros error'
  + ' postulant ea. Tota dolore molestie vel ad, vis aeque efficiendi ei. Eu vis diam diceret'
  + ' nominati, nisl atqui discere ad usu, omnis conceptam duo ea. Dolores propriae ut vim, pro ne'
  + ' postulant vituperata. Ea augue eirmod invidunt pro.';

const PopOverSection = () => (
  <section id="text">
    <h2>Pop Over Section</h2>
    <div className="bg-white p-3 pb-5">
      <div className="row">
        <div className="col-12 col-md-3">
          <ElementWithPopOver
            className="d-inline-block"
            element={<span>[With title]</span>}
            popOverContent="Say cheese!"
            popOverTitle="Popover Title"
          />
        </div>

        <div className="col-12 col-md-3">
          <ElementWithPopOver
            className="d-inline-block"
            element={<span>[Without title]</span>}
            popOverContent="Say cheese!"
          />
        </div>

        <div className="col-12 col-md-3">
          <ElementWithPopOver
            className="d-inline-block"
            element={<span>[position: right]</span>}
            placement={POPPER_PLACEMENT.RIGHT}
            popOverContent={loremIpsum}
          />
        </div>

        <div className="col-12 col-md-3">
          <OverlayTrigger
            overlay={(
              <Popover id="popover-example" title="Popover Title">
                {loremIpsum}
              </Popover>
            )}
            placement={POPPER_PLACEMENT.BOTTOM_END}
            trigger="hover"
          >
            <a>[open on hover]</a>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  </section>
);

export default PopOverSection;
