import React from 'react';
import PropTypes from 'prop-types';

import Filebox from 'core/assets/js/components/FileUploader/Filebox.jsx';

const AttachmentList = ({ attachments, invertedColors, emptyListMessage }) => {
  // Array.isArray check is needed since attachments may result as an error object or null
  if (!Array.isArray(attachments) || (Array.isArray(attachments) && !attachments.length)) {
    return emptyListMessage;
  }

  return attachments.map(file => (
    <Filebox key={file.handle || file} file={file} invertedColors={invertedColors} />
  ));
};

AttachmentList.propTypes = {
  attachments: PropTypes.array,
  invertedColors: PropTypes.bool,
  emptyListMessage: PropTypes.string,
};

AttachmentList.defaultProps = {
  attachments: [],
  invertedColors: false,
  emptyListMessage: 'No attachments uploaded.',
};

export default AttachmentList;
