import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import ChatRoomMessages from 'people/assets/js/components/chat/ChatRoomMessages.jsx';
import { DATETIME_FORMAT_HUMAN_FRIENDLY, ICON, POPPER_PLACEMENT } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';

const MiniMessageWindow = ({
  showChat, chatRoom, onStateChange, onScroll, handleRead, listInnerRef, handleMessageSubmit,
  profile, setShowChat, setIsChatExpanded, isChatExpanded, chatWindowTitle,
}) => {
  const [message, setMessage] = useState('');

  if (!showChat) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleMessageSubmit(message);
    setMessage('');
  };

  return (
    <div className="freelancer-chat">
      <AreaCollapsible
        className="freelancer-chat__container m-0 p-0"
        headingClassName="m-0"
        onStateChange={onStateChange}
        icons={{ expand: ICON.CHEVRON_UP, collapse: ICON.CHEVRON_DOWN }}
        contentChildren={(
          <div className="freelancer-chat__body">
            <Card.Body
              className="chat__box-body overflow-auto pt-4 d-flex flex-column-reverse"
              onScroll={onScroll}
              ref={listInnerRef}
            >
              <ChatRoomMessages chatRoom={chatRoom} />

              {chatRoom?.id && (
                <p className="mb-4 chat__box-caption">
                  <div>
                    <small className="mr-2">
                      {formatDate(
                        chatRoom.createdAt,
                        DATETIME_FORMAT_HUMAN_FRIENDLY,
                      )}
                    </small>
                    <strong>
                      Started messaging thread
                    </strong>
                  </div>
                </p>
              )}
            </Card.Body>
            <Card.Footer className="chat__footer p-4">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Start typing your message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onFocus={handleRead}
                  />
                </div>
              </form>
            </Card.Footer>
          </div>
        )}
        headingChildren={(
          <div className="freelancer-chat__header w-100 py-3 pl-4 pr-3 d-flex align-items-center justify-content-between">
            <div
              className="w-100"
              data-testid="chat-collapsable"
              onClick={() => {
                handleRead();
                setIsChatExpanded(!isChatExpanded);
              }}
            >
              <ProfilePic
                url={profile?.avatar}
                alt={profile?.fullName}
                size={[24, 24]}
                className="mr-3"
              />
              <label className="mb-0 cursor-pointer">
                {`${chatWindowTitle || profile?.fullName || ''}`}
              </label>
              {chatRoom?.unreadCount > 0 && (
                <span className="badge d-inline-block py-0 px-1 ml-3 text-center">{chatRoom?.unreadCount}</span>
              )}
            </div>

            {setShowChat && (
              <TDDropButton
                toggleEl={(<i className={`${ICON.DRAG_HANDLE} px-3`} />)}
                placement={!isChatExpanded ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
                popperConfig={{
                  modifiers: {
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  },
                }}
              >
                <Dropdown.Item
                  key="close-chat"
                  eventKey="close-chat"
                  onSelect={() => setShowChat(false)}
                >
                  <strong>Close</strong>
                </Dropdown.Item>
              </TDDropButton>
            )}
          </div>
        )}
        expandOnHeaderChildren
        initiallyExpanded={isChatExpanded}
        iconClassName="m-0"
      />
    </div>
  );
};

MiniMessageWindow.propTypes = {
  chatRoom: PropTypes.object,
  chatWindowTitle: PropTypes.string,
  handleMessageSubmit: PropTypes.func.isRequired,
  handleRead: PropTypes.func.isRequired,
  isChatExpanded: PropTypes.bool,
  listInnerRef: PropTypes.object.isRequired,
  onScroll: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  profile: PropTypes.object,
  setIsChatExpanded: PropTypes.func.isRequired,
  setShowChat: PropTypes.func,
  showChat: PropTypes.bool,
};

MiniMessageWindow.defaultProps = {
  chatRoom: null,
  chatWindowTitle: null,
  isChatExpanded: false,
  profile: null,
  setShowChat: null,
  showChat: false,
};

export default MiniMessageWindow;
