import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { invitationTemplateSpec } from 'settings/assets/js/lib/objectSpecs';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { updateInvitationTemplateDS } from 'settings/assets/js/data-services/settings';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import OrgInvitationTemplateForm from 'settings/assets/js/components/OrgInvitationTemplateForm.jsx';
import { orgTemplatesManageUrl, orgInvitationTemplateFetchApiUrl } from 'settings/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';

const VIEW_NAME = 'InvitationTemplateEditView';

function InvitationTemplateEditView({ match, history, dispatch, template }) {
  const { orgAlias } = match.params;
  const orgManageInvitationTemplatesUrl = orgTemplatesManageUrl(orgAlias);
  const breadcrumbs = [
    {
      title: 'Templates',
      url: orgManageInvitationTemplatesUrl,
    },
    {
      title: 'Org Invitations',
      url: orgManageInvitationTemplatesUrl,
    },
    {
      title: template && template.title,
      url: null,
    },
  ];

  const onsubmit = (values) => {
    return dispatch(updateInvitationTemplateDS({ orgAlias, values })).then(() => {
      history.push(orgTemplatesManageUrl(orgAlias));
      toastr.success('Well Done!', 'Invitation template updated successfully.');
    });
  };

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--invitation-template-create">
        <div className="container">
          <TDApiConnected
            duck="view"
            storeKey={VIEW_NAME}
            fetchData={
              ({ params: { templateId }, url, authedAxios, componentName }) => {
                const budgetBurnDownApiUrl = orgInvitationTemplateFetchApiUrl(
                  orgAlias, templateId, url,
                );
                return dispatch(fetchViewDS({
                  url: budgetBurnDownApiUrl, componentName, authedAxios,
                }));
              }}
            blockingLoading
            skeletonComponent={SettingsPageSkeleton}
          >
            <OrgInvitationTemplateForm
              onSubmit={onsubmit}
              initialValues={{ ...template }}
              submitLabel="Update"
            />
          </TDApiConnected>
        </div>
      </div>
    </React.Fragment>
  );
}
InvitationTemplateEditView.propTypes = {
  template: invitationTemplateSpec.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const viewState = getViewState(state, VIEW_NAME);

  return {
    template: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const InvitationTemplateEditViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationTemplateEditView);

export default withRouter(InvitationTemplateEditViewConnected);
