import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';

export const FORM_ID = 'project-clone-form';

const ProjectCloneForm = ({
  attachmentsCount, clientsCount, initialValues, onSubmit, skillsCount, tagsCount,
}) => (
  <Form initialValues={initialValues} onSubmit={onSubmit}>
    {({ handleSubmit }) => (
      <form data-testid={FORM_ID} id={FORM_ID} onSubmit={handleSubmit}>
        <TextInputField label="Title" name="title" required />
        <p>
          Clone this project to create an exact replica. Please select which of the following data
          you want to copy over to the new project:
        </p>
        <CheckboxField
          name="includeAttachments"
          options={[{ text: `Attachments (${attachmentsCount})`, value: true }]}
        />
        <CheckboxField
          name="includeClients"
          options={[{ text: `Clients (${clientsCount})`, value: true }]}
        />
        <CheckboxField
          name="includeTags"
          options={[{ text: `Tags (${tagsCount})`, value: true }]}
        />
        <CheckboxField
          name="includeSkills"
          options={[{ text: `Skills (${skillsCount})`, value: true }]}
        />
        <p>
          Once you clone this project, you will get redirected to the new copy you just created.
        </p>
        <p>Are you sure you want to clone this project?</p>
      </form>
    )}
  </Form>
);

ProjectCloneForm.propTypes = {
  attachmentsCount: PropTypes.number.isRequired,
  clientsCount: PropTypes.number.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  skillsCount: PropTypes.number.isRequired,
  tagsCount: PropTypes.number.isRequired,
};

export default ProjectCloneForm;
