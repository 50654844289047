import React from 'react';
import { Card } from 'react-bootstrap';

import CircleSkeleton from './CircleSkeleton.jsx';
import RectangleSkeleton from './RectangleSkeleton.jsx';

const UserDetailsSkeleton = () => (
  <Card className="user-profile__card">
    <Card.Body className="px-0">
      <div className="user-profile__summary px-4 d-flex flex-column align-items-center text-center">
        <CircleSkeleton diameter="80" />

        <div className="user-profile__basic-info d-flex flex-column justify-content-end w-100">
          <div className="mt-2 mt-md-0">
            <span className="user-profile__name">
              <RectangleSkeleton width="135" height="13" />
            </span>
          </div>

          <div className="text-truncate w-100">
            <span
              className="user-profile__job-title truncate mb-2 my-md-0"
            >
              <RectangleSkeleton width="143" height="13" />
            </span>
          </div>
        </div>
      </div>

      <div className="user-profile__meta row mx-0 flex-wrap align-items-center justify-content-around">
        <div className="col-4 text-center pl-0 pr-1">
          <RectangleSkeleton width="50" height="15" />
          <br />
          <RectangleSkeleton width="35" height="13" />
        </div>

        <div className="col-4 text-center px-0">
          <RectangleSkeleton width="55" height="15" />
          <br />
          <RectangleSkeleton width="35" height="13" />
        </div>

        <div className="col-4 text-center pl-2 pr-0">
          <RectangleSkeleton width="50" height="15" />
          <br />
          <RectangleSkeleton width="35" height="13" />
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default UserDetailsSkeleton;
