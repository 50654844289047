import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { putUserCardDS } from 'settings/assets/js/data-services/organizations';
import {
  financeDeferredPaymentsFeatureFlagApiUrl,
  financeInvoicesUrl, financePendingDeferredPaymentsMetaApiUrl,
} from 'finance/urls';
import DeferredPaymentsSettingEnableBody from 'settings/assets/js/components/DeferredPaymentsSettingEnableBody.jsx';
import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import {
  extrasUpdateAC,
  getViewState,
  getViewStateExtras,
  viewFetchAC,
} from 'core/assets/js/ducks/view';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';

export const COMPONENT_NAME = 'DeferredPaymentsSetting';

const DeferredPaymentsSetting = () => {
  const match = useRouteMatch();

  const { params: { orgAlias } } = match;
  const dispatch = useDispatch();
  const {
    userCard,
    org,
    pendingDeferredPaymentsMeta,
    financeDeferredPaymentsSetting,
  } = useSelector(state => ({
    userCard: selectActiveUserCard(state),
    org: selectActiveOrg(state),
    pendingDeferredPaymentsMeta: getViewState(state, COMPONENT_NAME)?.item,
    financeDeferredPaymentsSetting: getViewStateExtras(state, COMPONENT_NAME, 'deferredPaymentsFeatureSetting'),
  }));

  const deferredPaymentsFeatureEnabled = financeDeferredPaymentsSetting.value === '1';

  useEffect(() => {
    dispatch(fetchDataDS({
      fetchApiUrl: () => financeDeferredPaymentsFeatureFlagApiUrl(),
      fetchDataAC: responseData => ([
        extrasUpdateAC(
          { deferredPaymentsFeatureSetting: responseData.setting },
          COMPONENT_NAME,
        ),
      ]),
    }));
    dispatch(fetchDataDS({
      fetchApiUrl: () => financePendingDeferredPaymentsMetaApiUrl(orgAlias),
      fetchDataAC: responseData => ([
        viewFetchAC(responseData.pendingDeferredPaymentsMeta, COMPONENT_NAME),
      ]),
    }));
  }, [dispatch, orgAlias]);

  if (!deferredPaymentsFeatureEnabled || !org.allow_deferred_contractor_payments) {
    return null;
  }


  const hasPendingDeferredPayments = !isEmpty(pendingDeferredPaymentsMeta.perCurrency);

  const { enableDeferredPayments, userRole } = userCard;
  const isProvider = userRole?.isProvider;


  return (
    <>
      {enableDeferredPayments && (
        <TDSystemMessage
          className="mt-4"
          title="Information"
          type={BS_STYLE.INFO}
        >
          <p>
            If you change your payment details while you have invoices pending payment,
            when you request a payout all unpaid invoices will be sent to the active
            payment method.
          </p>
        </TDSystemMessage>
      )}
      {isProvider && (
        <>
          <div className="mt-4">
            <div className="rounded shadow-sm p-4 bg-white">
              <TDToggleWithConfirmationModal
                enableHeader="Enable Instant payouts"
                disableHeader="Disable Instant payouts"
                label={<strong>Instant payouts</strong>}
                modalId="instant-payouts"
                selected={!enableDeferredPayments}
                onToggled={setting => dispatch(putUserCardDS(orgAlias, {
                  enable_deferred_payments: !setting,
                }))}
                disableCtaLabel="Disable"
                enableCtaLabel="Enable"
                cancelEnableCtaLabel={hasPendingDeferredPayments ? 'Close' : 'Cancel'}
                hideEnableCta={hasPendingDeferredPayments}
                EnableModalBody={() => (
                  <DeferredPaymentsSettingEnableBody
                    hasPendingDeferredPayments={hasPendingDeferredPayments}
                    pendingDeferredPaymentsMeta={pendingDeferredPaymentsMeta}
                  />
                )}
                DisableModalBody={() => 'Are you sure you want to disable instant payouts?'}
                sublabel="TalentDesk will instantly send payment to your chosen payment method as soon as funds have been received from the Organisation.If you would prefer to bundle your payments and choose to receive your payment on-demand then disable this setting.You will be able to request a bundle payment via your Invoices list."
              />
            </div>
          </div>
          <Card className="mt-4 p-4">
            <div>
              View your invoices
              {' '}
              <Link to={financeInvoicesUrl(orgAlias)}>
                here
              </Link>
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default DeferredPaymentsSetting;
