import { flatten } from 'lodash';
/**
 * This is a generic error that just has a namespace=td in order to easily differentiate any
 * derived error from other library errors, without checking for instance inheritance
 *
 */
export class TDError extends Error {
  constructor(message) {
    super(message);
    this.namespace = 'td';
  }
}


/**
 * @when Throw when we are validating multiple fields and any of them fails. Especially
 *       usefull inside form validations, where many form items may fail at the same time
 * @effect This will be added to a parent validation error, along with any rest validation
 *         errors from other fields.
 *
 */
export class ValidationErrorItem extends TDError {
  constructor(path, message) {
    super(message);
    this.name = 'ValidationErrorItem';
    this.path = path;
    this.type = 'custom violation';
  }
}

export class WiseBadCurrencyErrorItem extends ValidationErrorItem {
}

/**
 * @when Throw to signal the frontend that a validation error over a single field has occured.
 *       Used only when this is the only field that has failed
 * @effect This will result in a standard '400 Bad Request' with an error for a single field
 *
 */
export class SingleValidationError extends TDError {
  constructor(message, path) {
    super(message);
    this.name = 'ValidationError';
    this.errors = [new ValidationErrorItem(path, message)];
  }
}

/**
 * We flatten the errors in our ValidationError wrapper, but this is not always what we need.
 * We need to have special cases where the errors should be nested and serialized as such.
 * @example FieldArray components in redux-form
 *
 * @param {Error} e
 * @returns {Boolean} whether the error should be nested or flattened
 */
const isNestedError = e => (
  e.name === 'NestedValidationErrorList' || e.name === 'NestedValidationErrorListItem'
);

/**
 * @when Throw when we have a list of ValidationErrorItems and we want to combine them
 *       in a comprehensive one. This is the standard validation error for a form validation
 *       which has multiple fields
 * @effect This will result in a standard '400 Bad Request' with an error for all
 *         validation error items that are included in this one
 */
export class ValidationError extends TDError {
  constructor(message, errors = [], { path } = {}) {
    super(message);
    this.name = 'ValidationError';
    this.path = path;

    // Flatten the non-nested errors
    this.errors = flatten(
      errors.filter(e => !isNestedError(e)).map(e => e.errors || [e]),
    );

    // Then append any nested ones
    this.errors = this.errors.concat(
      errors.filter(e => isNestedError(e)),
    );
  }

  flatten() {
    this.errors = flatten(this.errors.map(e => e.errors || []));
  }
}

/**
 * Super-charges list-like errors (ie. NestedValidationErrorList, NestedValidationErrorListItem)
 *
 * @mixin
 * @param {TDError} SuperClass the class to extend
 * @returns {Object}
 */
const ErrorListMixin = SuperClass => (
  class extends SuperClass {
    /**
     * Adds an error to the list
     *
     * @param {Error} error the error to add
     */
    add(error) {
      this.errors.push(error);
    }

    find(lookup) { // eslint-disable-line class-methods-use-this, no-unused-vars
      throw new Error('This function needs to be overloaded in a child class');
    }

    findOrCreate(lookup, errorOptions = {}) {
      let listItem = this.find(lookup);

      const { message, errorClass: ErrorClass, errors = [] } = errorOptions;

      if (!message) {
        throw new Error('You have to provide the message for the error');
      }

      if (!ErrorClass) {
        throw new Error('You have to specify the class of the error to be created');
      }

      // Valid, expected list item, return it
      if (listItem instanceof ErrorClass) {
        return listItem;
      }

      // List item exists, but it's not an instance of the expected class,
      // create a new one and just add it to the errors
      if (listItem && !(listItem instanceof ErrorClass)) {
        errors.push(listItem);
      }

      listItem = new ErrorClass(lookup, message, [...errors]);
      this.add(listItem);

      return listItem;
    }
  }
);

/**
 * @mixes ErrorListMixin
 * @when Throw when we have an error for a specific list item that has to be in a
 *       FieldArray component. This has to be nested as follows:
 *       <ValidationError
 *        errors: [
 *          <NestedValidationErrorList:
 *            path: 'items',
 *            errors: [
 *              <NestedValidationErrorListItem index: 0, message: 'Some error' >,
 *              <NestedValidationErrorListItem index: 1, message: 'Some error' >,
 *            ]>
 *        ]>
 */
export class NestedValidationErrorListItem extends ErrorListMixin(ValidationErrorItem) {
  constructor(index, message, errors = []) {
    super(String(index), message);
    this.name = 'NestedValidationErrorListItem';
    this.errors = errors;
    this.index = index;
  }

  /**
   * Finds an error by its path
   * @param {String} path the error's Path
   * @returns {Error}
   */
  find(path) {
    return this.errors.find(e => e.path === path);
  }
}

/**
 * @when Throw when multiple, nested errors are thrown for objects within a certain path.
 * @see NestedValidationErrorListItem
 * @mixes ErrorListMixin
 */
export class NestedValidationErrorList extends ErrorListMixin(ValidationError) {
  constructor(path, message, errors = []) {
    super(message, errors);
    this.name = 'NestedValidationErrorList';
    this.path = path;
  }

  /**
   * Returns the item that is associated with a given index
   *
   * @param {Number} index the index that the error should be associated to
   * @returns {NestedValidationErrorListItem}
   */
  find(index) {
    return this.errors.find(e => e.index === index);
  }
}

/**
 * @when Throw when we have a list of validation errors either our own, from the DB, or any
 *       other library and we want to combine them into one
 * @effect This will result in a standard '400 Bad Request' with an error for all
 *         validation errors that are included in this one
 */
export class MultiValidationError extends TDError {
  constructor(message, errors = [], { path } = {}) {
    super(message);
    this.name = 'MultiValidationError';
    this.path = path;
    this.errors = errors.reduce((list, e) => {
      if (e.errors) {
        list.push(...e.errors);
      } else {
        list.push(e);
      }
      return list;
    }, []);
  }
}

/**
 * @when Throw when we did not find the resource requested. This is usually used when trying
 *       to get some model from the db using its id, and cannot find it
 * @effect This will result in a standard '404 Not Found' which will be handled by the frontend
 */
export class NotFoundError extends TDError {
  constructor(message) {
    super(message);
    this.name = 'NotFoundError';
  }
}

/**
 * @when Throw when we did find the resource requested but the user is not allowed access to it
 * @effect This will result in a standard '403 Unauthorized' which will be handled by the frontend
 */
export class UnauthorizedError extends TDError {
  constructor(message) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

/**
 * @when Throw when we cannot proceed using the session information of the user and we feel
 *       something has been expired or even corrupted. This is highly disruptive and will
 *       essentially result in a logout.
 * @effect Throwing a FatalError will signal the frontend to destroy the session and try to
 *         re-login in order to re-create the user's session.
 */
export class FatalError extends TDError {
  constructor(message) {
    super(message);
    this.name = 'FatalError';
  }
}


export class ExpectedError extends TDError {
  constructor(message) {
    super(message);
    this.name = 'ExpectedError';
  }
}

/**
 * @when Throw when a payments audit check that doesn't pass.
 * Should only be thrown by FactAuditLog forceCreate.
 * @effect Throwing an AuditError will add a message and severity level to the overall audit,
 *         potentially for display in some UI.
 */
export class AuditError extends TDError {
  constructor(message, level) {
    super(message);
    this.level = level;
  }
}
