import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { fetchFinanceManagerBudgetBreakdownDS } from 'finance/assets/js/data-services/view';
import { managerBudgetTotalsSpec, managerBudgetInfoSpec, postingSpec } from 'finance/assets/js/lib/objectSpecs';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import ManagerBudgetBreakdownTable from 'finance/assets/js/components/ManagerBudgetBreakdownTable.jsx';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';


class ManagerBudgetBreakdownView extends React.Component {
  static FetchData({ dispatch, params, url = '', authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchFinanceManagerBudgetBreakdownDS({
        orgAlias: params.orgAlias, id: params.id, url, componentName, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ManagerBudgetBreakdownView';
  }

  renderTotals() {
    const { postingsTotals, info: { currency } } = this.props;
    const hasTotal = !['-', '0.00'].includes(postingsTotals.balance);
    return (
      postingsTotals.balance && (
        <div className="finance-table__summary">
          <div>
            <span>Total Available</span>
          </div>
          <div>
            <span><NumberTpl prefix={currency} value={hasTotal ? postingsTotals.balance : '0.00'} /></span>
          </div>
        </div>
      )
    );
  }

  render() {
    const { info, postings } = this.props;
    const emptyText = 'No financial actions were found';
    return (
      <ReportViewLayout
        heading={info.fullName}
        reportType={REPORT_TYPES.MANAGER_BUDGET}
      >
        <TDApiConnected duck="view" component={this.constructor} blockingLoading>
          <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list mb-xl-0">
            <ManagerBudgetBreakdownTable
              postings={postings || []}
              info={info}
              emptyText={emptyText}
            />
          </div>
          <div className="finance-report__totals">
            {this.renderTotals()}
          </div>
        </TDApiConnected>
      </ReportViewLayout>
    );
  }
}

ManagerBudgetBreakdownView.propTypes = {
  info: managerBudgetInfoSpec,
  postings: PropTypes.arrayOf(postingSpec),
  postingsTotals: managerBudgetTotalsSpec,
};

ManagerBudgetBreakdownView.defaultProps = {
  info: {},
  postings: [],
  postingsTotals: {},
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, ManagerBudgetBreakdownView.GetComponentName());
  return {
    info: viewState.item.info,
    postings: viewState.item.items,
    postingsTotals: viewState.item.totals,
    organization: selectActiveOrg(state),
  };
};

export default connect(mapStateToProps)(ManagerBudgetBreakdownView);
