import { groupBy } from 'lodash';

import {
  ORG_INVITATION_TYPES, PROJECT_INVITATION_TYPES, TASK_INVITATION_TYPES,
} from 'invitations/assets/js/constants';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { invitationsPendingApiUrl } from 'invitations/urls';

export const PENDING_INVITATIONS_FETCH = 'pending-invitations/FETCH';
export const PENDING_INVITATIONS_RESET = 'pending-invitations/RESET';

export const pendingInvitationsInitialState = {
  project: {},
  organization: {},
  task: {},
  total: 0,
};

export const reducer = (state = pendingInvitationsInitialState, action) => {
  switch (action.type) {
    case PENDING_INVITATIONS_FETCH:
      return {
        ...state,
        total: action.total,
        organization: groupBy(
          action.invitations.filter(i => ORG_INVITATION_TYPES.includes(i.type)),
          i => i.orgAlias || i.orgId,
        ),
        project: groupBy(
          action.invitations.filter(
            i => PROJECT_INVITATION_TYPES.includes(i.type) && !!i.projectId,
          ),
          'projectId',
        ),
        task: groupBy(
          action.invitations.filter(i => TASK_INVITATION_TYPES.includes(i.type) && !!i.taskId),
          'taskId',
        ),
      };

    case PENDING_INVITATIONS_RESET:
      return pendingInvitationsInitialState;
    default:
      return state;
  }
};

export const pendingInvitationsFetchAC = (invitations, total) => ({
  type: PENDING_INVITATIONS_FETCH,
  invitations,
  total,
});

export const pendingInvitationsResetAC = () => ({
  type: PENDING_INVITATIONS_RESET,
});

export const getPendingInvitations = (state, key) => (
  state && state.pendingInvitations && state.pendingInvitations[key]
    ? state.pendingInvitations[key]
    : {}
);

export const refreshPendingInvitationsDS = ({
  url = '', authedAxios = null, componentName,
} = {}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    fetchApiUrl: () => invitationsPendingApiUrl(url),
    fetchDataAC: responseData => (
      pendingInvitationsFetchAC(responseData.invitations, responseData.total)
    ),
  })
);

export default reducer;
