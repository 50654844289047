// ISO 639-3 languages
export const isoLanguages = {
  aar: 'Afar',
  abk: 'Abkhazian',
  afr: 'Afrikaans',
  aka: 'Akan',
  amh: 'Amharic',
  ara: 'Arabic',
  arg: 'Aragonese',
  asm: 'Assamese',
  ava: 'Avaric',
  ave: 'Avestan',
  aym: 'Aymara',
  aze: 'Azerbaijani',
  bak: 'Bashkir',
  bam: 'Bambara',
  bel: 'Belarusian',
  ben: 'Bengali; Bangla',
  bih: 'Bihari',
  bis: 'Bislama',
  bos: 'Bosnian',
  bre: 'Breton',
  bul: 'Bulgarian',
  cat: 'Catalan',
  cha: 'Chamorro',
  che: 'Chechen',
  chu: 'Church Slavic',
  chv: 'Chuvash',
  cor: 'Cornish',
  cos: 'Corsican',
  cre: 'Cree',
  cze: 'Czech',
  dan: 'Danish',
  div: 'Dhivehi',
  dzo: 'Dzongkha',
  ell: 'Greek',
  eng: 'English',
  epo: 'Esperanto',
  est: 'Estonian',
  eus: 'Basque',
  ewe: 'Ewe',
  fao: 'Faroese',
  fas: 'Persian',
  fij: 'Fijian',
  fin: 'Finnish',
  fra: 'French',
  fry: 'Western Frisian',
  ful: 'Fulah',
  ger: 'German',
  gla: 'Scottish Gaelic',
  gle: 'Irish',
  glg: 'Galician',
  glv: 'Manx',
  grn: 'Guarani',
  guj: 'Gujarati',
  hat: 'Haitian',
  hau: 'Hausa',
  heb: 'Hebrew',
  her: 'Herero',
  hin: 'Hindi',
  hmo: 'Hiri Motu',
  hrv: 'Croatian',
  hun: 'Hungarian',
  hye: 'Armenian',
  ibo: 'Igbo',
  ido: 'Ido',
  iii: 'Sichuan Yi',
  iku: 'Inuktitut',
  ile: 'Interlingue',
  ina: 'Interlingua',
  ind: 'Indonesian',
  ipk: 'Inupiaq',
  isl: 'Icelandic',
  ita: 'Italian',
  jav: 'Javanese',
  jpn: 'Japanese',
  kal: 'Kalaallisut',
  kan: 'Kannada',
  kas: 'Kashmiri',
  kat: 'Georgian',
  kau: 'Kanuri',
  kaz: 'Kazakh',
  khm: 'Khmer',
  kik: 'Kikuyu',
  kin: 'Kinyarwanda',
  kir: 'Kirghiz',
  kom: 'Komi',
  kon: 'Kongo',
  kor: 'Korean',
  kua: 'Kuanyama',
  kur: 'Kurdish',
  lao: 'Lao',
  lat: 'Latin',
  lav: 'Latvian',
  lim: 'Limburgan',
  lin: 'Lingala',
  lit: 'Lithuanian',
  ltz: 'Luxembourgish',
  lub: 'Luba-Katanga',
  lug: 'Ganda',
  mah: 'Marshallese',
  mal: 'Malayalam',
  mar: 'Marathi',
  mkd: 'Macedonian',
  mlg: 'Malagasy',
  mlt: 'Maltese',
  mon: 'Mongolian',
  mri: 'Maori',
  msa: 'Malay',
  mya: 'Burmese',
  nau: 'Nauru',
  nav: 'Navajo',
  nbl: 'South Ndebele',
  nde: 'North Ndebele',
  ndo: 'Ndonga',
  nep: 'Nepali',
  nld: 'Dutch',
  nno: 'Norwegian Nynorsk',
  nob: 'Norwegian',
  nor: 'Norwegian',
  nya: 'Nyanja',
  oci: 'Occitan',
  oji: 'Ojibwa',
  ori: 'Oriya',
  orm: 'Oromo',
  oss: 'Ossetian',
  pan: 'Panjabi',
  pli: 'Pali',
  pol: 'Polish',
  por: 'Portuguese',
  pus: 'Pushto',
  que: 'Quechua',
  roh: 'Romansh',
  ron: 'Romanian',
  run: 'Rundi',
  rus: 'Russian',
  sag: 'Sango',
  san: 'Sanskrit',
  sin: 'Sinhala',
  slo: 'Slovak',
  slv: 'Slovenian',
  sme: 'Northern Sami',
  smo: 'Samoan',
  sna: 'Shona',
  snd: 'Sindhi',
  som: 'Somali',
  sot: 'Southern Sotho',
  spa: 'Spanish',
  sqi: 'Albanian',
  srd: 'Sardinian',
  srp: 'Serbian',
  ssw: 'Swati',
  sun: 'Sundanese',
  swa: 'Swahili',
  swe: 'Swedish',
  tah: 'Tahitian',
  tam: 'Tamil',
  tat: 'Tatar',
  tel: 'Telugu',
  tgk: 'Tajik',
  tgl: 'Tagalog',
  tha: 'Thai',
  tib: 'Tibetan',
  tir: 'Tigrinya',
  ton: 'Tonga, Tonga Islands',
  tsn: 'Tswana',
  tso: 'Tsonga',
  tuk: 'Turkmen',
  tur: 'Turkish',
  twi: 'Twi',
  uig: 'Uighur',
  ukr: 'Ukrainian',
  urd: 'Urdu',
  uzb: 'Uzbek',
  ven: 'Venda',
  vie: 'Vietnamese',
  vol: 'VolapÃ¼k',
  wel: 'Welsh',
  wln: 'Walloon',
  wol: 'Wolof',
  xho: 'Xhosa',
  yid: 'Yiddish',
  yor: 'Yoruba',
  zha: 'Zhuang',
  zho: 'Chinese',
  zul: 'Zulu',

  // languages added which exist in ISO693-3
  cmn: 'Mandarin',
  yue: 'Cantonese',

  // Custom languages added which DO NOT exist in ISO693-3
  // We use the primary languages code + _ + a 3 letter code
  // e.g Brazilian Portuguese (td_code: por_bra)
  // Since Brazilian Portuguese do not exist in ISO693-3, we take Portuguese ISO6933 code -> 'por'
  // as a main language and we add an identifier prefixed by underscore por + _ + bra = por_bra
  fra_swi: 'Swiss French',
  por_bra: 'Brazilian Portuguese',
};

export const languageOptions = Object.keys(isoLanguages).map(key => ({
  value: key, text: isoLanguages[key],
}));

export function getLanguageName(languageCode) {
  if (isoLanguages[languageCode]) {
    return isoLanguages[languageCode];
  }
  return languageCode;
}
