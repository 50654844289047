/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { BS_STYLE } from 'core/assets/js/constants';

export const DEACTIVE_MODAL_ID = 'deactivate-org-default-skills-modal';

const tooltip = (
  <Tooltip>
    In order to hide the default skills you must first define
    custom skills in your organisation.
  </Tooltip>
);

class OrgDefaultSkillsAvailability extends React.Component {
  static GetComponentName() {
    return 'OrgDefaultSkillsAvailability';
  }

  render() {
    const {
      deactivateModalOpen, handleToggle, showDefaultSkills,
      dispatch, hasOrgSkills,
    } = this.props;
    return (
      <React.Fragment>
        <ModalConfirm
          onClose={() => {
            dispatch(modalCloseAC(DEACTIVE_MODAL_ID));
          }}
          onConfirm={() => handleToggle(true)}
          open={deactivateModalOpen}
          heading={
            showDefaultSkills ? 'Default skills are available' : 'Default skills are not available'
          }
          body={
            showDefaultSkills ? (
              <React.Fragment>
                <p>
                  Deactivating this setting means that your users will only
                  be able to use the custom skills defined in your organisation.
                  Any default skills that have already been added
                  to user profiles, projects or tasks will not be visible anymore.
                </p>
                <p>
                  Are you sure you want to deactivate the default skills setting?
                </p>
              </React.Fragment>

            ) : (
              <React.Fragment>
                <p>
                  Activating this setting means that your users will now gain
                  access to the full list of default skills available on
                  TalentDesk.io and the custom skills defined in your organisation.
                </p>
                <p>
                  Are you sure you want to activate the default skills setting?
                </p>

              </React.Fragment>
            )}
          confirmLabel={showDefaultSkills ? 'Deactivate' : 'Activate'}
          confirmStyle={showDefaultSkills ? BS_STYLE.DANGER : BS_STYLE.SUCCESS}
        />
        <div className="d-flex flex-container--standalone align-items-center">
          <div className="settings-row__content">
            <label>Make default skills available</label>
          </div>
          <div className="settings-row__cta">
            {!hasOrgSkills && showDefaultSkills ? (
              <OverlayTrigger
                placement="left"
                overlay={tooltip}
              >
                <div className="organization-list-item__default-status align-self-center">
                  <TDSwitch
                    selected={showDefaultSkills}
                    disabled
                  />
                </div>
              </OverlayTrigger>
            ) : (
              <TDSwitch
                selected={showDefaultSkills}
                onClick={() => {
                  handleToggle();
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex flex-container--standalone align-items-center">
          <div className="settings-row__content">
            <p className="discreet">
              By activating this option your users gain access to the full list of
              default skills available on TalentDesk.io and the custom skills defined
              in your organisation.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OrgDefaultSkillsAvailability.propTypes = {
  showDefaultSkills: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  deactivateModalOpen: PropTypes.bool,
  hasOrgSkills: PropTypes.bool,
};

OrgDefaultSkillsAvailability.defaultProps = {
  showDefaultSkills: true,
  deactivateModalOpen: false,
  hasOrgSkills: true,
};

const mapStateToProps = (state, props) => ({
  deactivateModalOpen: getIsModalOpen(state, DEACTIVE_MODAL_ID),
  handleToggle: props.handleToggle,
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgDefaultSkillsAvailability);
