import { pick } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE, ICON, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { formatDate } from 'core/assets/js/lib/utils';
import ProjectInvitationContent from 'projects/assets/js/components/ProjectInvitationContent.jsx';
import {
  PROJECT_OPPORTUNITY_INVITEES_STATUS,
  PROJECT_OPPORTUNITY_INVITEES_STATUS_LABEL,
  PROJECT_OPPORTUNITY_INVITEES_STATUS_CLASS,
} from 'projects/assets/js/constants';
import { getOpportunityInvitationsApiUrl, projectOpportunitiesApplyUrl } from 'projects/urls';

const OpportunityInvitations = ({ filtersOpen, onFiltersToggle }) => {
  const { orgAlias } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { hasLoaded, isLoading, items, pagination } = fetchDataHook({
    componentName: OpportunityInvitations.GetComponentName(),
    duck: 'list',
    url: getOpportunityInvitationsApiUrl(orgAlias),
  });

  if (!hasLoaded || isLoading) {
    return <ProjectListSkeleton />;
  }

  return (
    <>
      <SearchFinalForm
        filtersOpen={filtersOpen}
        initialValues={pick(
          queryString.parse(location.search), ['kw', 'ordering', 'status'],
        )}
        onFiltersToggle={onFiltersToggle}
        searchSpec={{
          searchTerm: false,
          filters: [{
            fieldComponent: SelectableListFilterField,
            label: 'Status',
            multiple: true,
            options: Object.values(PROJECT_OPPORTUNITY_INVITEES_STATUS).map(status => ({
              text: PROJECT_OPPORTUNITY_INVITEES_STATUS_LABEL[status], value: status,
            })),
            paramName: 'status',
          }],
        }}
      />
      {!filtersOpen && (
        <TDList
          cardItem={{
            component: ({
              item: {
                allowedActions: { canAccept },
                createdAt,
                createdByProfile,
                project,
                projectId,
                status,
              },
            }) => (
              <Card className="invitation-list-item">
                <Card.Header className="d-flex flex-column flex-sm-row mx-0 px-4">
                  <div className="d-flex w-100 justify-content-end">
                    <div
                      className={(
                        'invitation-list-item__icon-container '
                          + 'invitation-list-item__icon-container--opportunity mr-4'
                      )}
                    >
                      <span className={ICON.LIGHTBULB_DOLLAR} />
                    </div>
                    <h3>
                      <span className="invitation-list-item__project-title">
                        {project.title}
                      </span>
                      <aside>
                        {`Sent on ${formatDate(createdAt, DATE_FORMAT_DEFAULT)} by `}
                        {createdByProfile.name}
                      </aside>
                    </h3>
                  </div>
                  <div
                    className={(
                      'flex-shrink-0 action-buttons d-flex flex-column align-items-end '
                        + 'd-sm-block mb-4 pb-4 pb-sm-0 mb-sm-0 px-4 px-sm-0'
                    )}
                  >
                    {canAccept && (
                      <TDButton
                        onClick={() => (
                          history.push(projectOpportunitiesApplyUrl(orgAlias, projectId))
                        )}
                        variant={BS_STYLE.SUCCESS}
                      >
                        <span className={`${ICON.CHECKMARK} mr-3`} />
                        Accept
                      </TDButton>
                    )}
                    {!canAccept && (
                      <StatusTag
                        label={PROJECT_OPPORTUNITY_INVITEES_STATUS_LABEL[status]}
                        statusClass={PROJECT_OPPORTUNITY_INVITEES_STATUS_CLASS[status]}
                      />
                    )}
                  </div>
                </Card.Header>
                <Card.Body className="mx-4">
                  <AreaCollapsible
                    contentChildren={(
                      <ProjectInvitationContent
                        project={project}
                        storeKey={`opportunity-invitation-content_${projectId}`}
                      />
                    )}
                    headingChildren={(
                      <SkillList
                        inline
                        maxShownItems={10}
                        modalId={`project-skills-${projectId}`}
                        skills={project.skills}
                      />
                    )}
                    toggleAfterCard
                  />
                </Card.Body>
              </Card>
            ),
          }}
          emptyListMessage="No invitations found."
          items={items}
          pagination={pagination}
        />
      )}
    </>
  );
};

OpportunityInvitations.GetComponentName = () => 'OpportunityInvitations';

OpportunityInvitations.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withFilters(OpportunityInvitations);
