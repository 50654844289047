import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchViewDS } from 'core/assets/js/ducks/view';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectOpportunityFetchApiUrl, projectOpportunitiesUrl } from 'projects/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import ProjectApplicationForm from 'projects/assets/js/components/ProjectApplicationForm.jsx';

const ProjectApplicationFormView = ({ match, history, item }) => {
  const { orgAlias } = match.params;
  const { title } = item;

  const breadcrumbs = [
    {
      title: 'Opportunities',
      url: projectOpportunitiesUrl(match.params.orgAlias),
    },
    {
      title: title || '',
      url: null,
    },
  ];

  const initialValues = {};
  if (item.rateGuideFixed) {
    initialValues.rateAmount = item.rateGuideFixed;
  }

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--project-application">
        <div className="container">
          <ProjectApplicationForm
            initialValues={initialValues}
            project={item}
            onSubmitSuccess={() => {
              history.push(projectOpportunitiesUrl(orgAlias));
            }}
            orgAlias={orgAlias}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

ProjectApplicationFormView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  item: projectSpec,
};
ProjectApplicationFormView.defaultProps = {
  item: null,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectApplicationFormViewConnected = connect(
  null,
  mapDispatchToProps,
)(ProjectApplicationFormView);

const ProjectApplicationFormViewApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) => dispatch(fetchViewDS({
    authedAxios, componentName, querystring,
    url: projectOpportunityFetchApiUrl(params.orgAlias, params.id, url),
  })),
  duck: 'view',
  storeKey: 'ProjectApplicationFormView',
  skeletonComponent: null,
})(ProjectApplicationFormViewConnected);

export default withRouter(ProjectApplicationFormViewApiConnected);
