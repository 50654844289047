import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import pluralize from 'pluralize';

import SelectField from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import ProjectFundsBreakdown from 'projects/assets/js/components/ProjectFundsBreakdown.jsx';
import {
  PROJECT_COMPLETION_FUNDS,
  PROJECT_COMPLETION_FUNDS_LABEL,
  PROJECT_TASKS_STATUS_CHANGE,
  PROJECT_TASKS_STATUS_CHANGE_LABEL,
} from 'projects/assets/js/constants';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { PendingDocumentsLabel } from 'projects/assets/js/components/ProjectCta.jsx';

export const FORM_ID = 'project-finalize-form';

const returnFundsOptions = Object.values(PROJECT_COMPLETION_FUNDS).map(value => ({
  text: PROJECT_COMPLETION_FUNDS_LABEL[value], value,
}));

const tasksStatusOptions = Object.values(PROJECT_TASKS_STATUS_CHANGE).map(value => ({
  text: PROJECT_TASKS_STATUS_CHANGE_LABEL[value], value,
}));

/**
 * Form of project mark as completed modal
 *
 * Used in ProjectCTA
 *
 * @param budgetBreakdown The projects budget breakdown
 * @param projectCurrency The projects currency
 * @param deallocateAllFunds Whether user has selected to deallocate all project funds or not
 * @returns {*} ProjectFinalizeForm connected with ReduxForm
 */
const ProjectFinalizeForm = ({
  text,
  budgetBreakdown,
  pendingTasks,
  projectCurrency,
  showFundsDeallocationOption,
  fundsDeallocationMessage,
}) => {
  const hasAvailableBudget = parseInt(budgetBreakdown.available, 10) > 0;
  return (
    <form>
      { text && (
        <p className="mb-4" data-testid="project-finalize-form-text">
          {text}
        </p>
      )}
      {typeof pendingTasks === 'number' && pendingTasks > 0 && (
        <>
          <TDSystemMessage className="mb-4 mt-4" title="There are pending tasks" type={BS_STYLE.WARNING}>
            <PendingDocumentsLabel
              count={pendingTasks}
              label="pending Task"
              prefix="There"
            />
            {` that ${pluralize('need', pendingTasks)} `}
            to be completed, stopped or archived before the project can be completed.
          </TDSystemMessage>
          <Field
            className="d-flex"
            component={SelectField}
            data-testid="project-task-status-change"
            label="What do you want to do with these tasks?"
            name="task_status_change"
            optionsMapping={tasksStatusOptions}
          />
        </>
      )}
      {hasAvailableBudget && (
        <React.Fragment>
          <p className="mb-4">
            There is remaining budget in the project.
          </p>

          <ProjectFundsBreakdown
            className="simple my-5"
            budgetBreakdown={budgetBreakdown}
            projectCurrency={projectCurrency}
          />

          {showFundsDeallocationOption && (
            <Field
              className="d-flex"
              component={SelectField}
              data-testid="project-finalize-form-deallocate"
              label="What would you like to do with the unused budget from this project?"
              name="deallocate_all_funds"
              optionsMapping={returnFundsOptions}
            />
          )}

          {fundsDeallocationMessage && (
            <p className="mb-4">{fundsDeallocationMessage}</p>
          )}
        </React.Fragment>
      )}
    </form>
  );
};

ProjectFinalizeForm.propTypes = {
  text: PropTypes.string,
  budgetBreakdown: PropTypes.object.isRequired,
  pendingTasks: PropTypes.number.isRequired,
  projectCurrency: PropTypes.string.isRequired,
  fundsDeallocationMessage: PropTypes.string,
  showFundsDeallocationOption: PropTypes.bool,
};

ProjectFinalizeForm.defaultProps = {
  text: '',
  showFundsDeallocationOption: true,
  fundsDeallocationMessage: null,
};

const withReduxForm = reduxForm({
  form: FORM_ID,
})(ProjectFinalizeForm);

export default withReduxForm;
