import React from 'react';
import { withRouter } from 'react-router-dom';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import { ROLES_TABS } from 'roles/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { rolesListUrl } from 'roles/urls';

const RolesPermissionsTabs = ({ match: { params: { orgAlias, tab } } }) => {
  const tabSpec = [
    {
      key: 'roles',
      label: 'Roles',
      href: rolesListUrl(orgAlias, ROLES_TABS.ROLES),
    },
    {
      key: 'permissions',
      label: 'Permissions',
      href: rolesListUrl(orgAlias, ROLES_TABS.PERMISSIONS),
    },
  ];

  return (
    <div className="horizontal-scroll" data-testid="roles-tabs">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

RolesPermissionsTabs.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(RolesPermissionsTabs);
