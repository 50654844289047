import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import { ICON, USER_TYPE, USER_TYPE_LABEL } from 'core/assets/js/constants';
import InvitePeopleAorSelector from 'people/assets/js/components/InvitePeopleAorSelector.jsx';
import {
  PEOPLE_INVITATION_WORDINGS, PEOPLE_TYPE, USER_EMPLOYMENT_TYPE, USER_EMPLOYMENT_TYPE_LABEL,
} from 'people/assets/js/constants';

const USER_TYPES = [
  {
    icon: ICON.USER_DUOTONE,
    id: USER_TYPE.PROVIDER,
    subtext: 'Can be added to projects and tasks in order to start working on deliverables.',
    title: USER_TYPE_LABEL[USER_TYPE.PROVIDER],
  },
  {
    icon: ICON.USER_CROWN_DUOTONE,
    id: USER_TYPE.MANAGER,
    subtext: 'Can create projects, assign work to talent and control budgets.',
    title: USER_TYPE_LABEL[USER_TYPE.MANAGER],
  },
];

const EMPLOYMENT_TYPES = [
  {
    icon: ICON.HOUSE_LAPTOP_DUOTONE,
    id: USER_EMPLOYMENT_TYPE.CONTRACTOR,
    title: startCase(USER_EMPLOYMENT_TYPE_LABEL[USER_EMPLOYMENT_TYPE.CONTRACTOR]),
  },
  {
    icon: ICON.BRIEFCASE_DUOTONE,
    id: USER_EMPLOYMENT_TYPE.EMPLOYEE,
    title: startCase(USER_EMPLOYMENT_TYPE_LABEL[USER_EMPLOYMENT_TYPE.EMPLOYEE]),
  },
];

const ImportUserTypeOption = ({ item: { icon, subtext, title } }) => (
  <div className="d-flex flex-column align-items-center justify-content-center py-5">
    <i className={`${icon} fa-4x`} />
    <h4>{title}</h4>
    {subtext && <p className="text-center d-none d-lg-block">{subtext}</p>}
  </div>
);

ImportUserTypeOption.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    subtext: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const ImportUserType = () => {
  const { change } = useForm();
  const { submitErrors, values: { employmentType, userType } } = useFormState();
  const userTypeSelected = [USER_TYPE.PROVIDER, USER_TYPE.MANAGER].includes(userType);
  const employmentTypeSelected = [
    USER_EMPLOYMENT_TYPE.CONTRACTOR, USER_EMPLOYMENT_TYPE.EMPLOYEE,
  ].includes(employmentType);
  return (
    <>
      <h2>What access level would you like to grant them?</h2>
      <CheckableList
        className="bulk-import-checkable-list user-type"
        initialValue={userType !== undefined ? { id: userType } : null}
        items={USER_TYPES}
        itemComponent={ImportUserTypeOption}
        onItemChecked={value => {
          change('userType', value.id);
          const peopleType = value.id === USER_TYPE.PROVIDER
            ? PEOPLE_TYPE.PROVIDERS
            : PEOPLE_TYPE.MANAGERS;
          const { [peopleType]: { defaultMessage } } = PEOPLE_INVITATION_WORDINGS;
          change('message', defaultMessage);
        }}
      />
      {submitErrors?.userType && (
        <div className="clearfix mt-3 text-danger">{submitErrors.userType}</div>
      )}
      {userTypeSelected && (
        <>
          <h2>What is their contract type?</h2>
          <CheckableList
            className="bulk-import-checkable-list employment-type"
            initialValue={employmentType !== undefined ? { id: employmentType } : null}
            items={EMPLOYMENT_TYPES}
            itemComponent={ImportUserTypeOption}
            onItemChecked={value => {
              change('employmentType', value.id);
            }}
          />
          {submitErrors?.employmentType && (
            <div className="clearfix mt-3 text-danger">{submitErrors.employmentType}</div>
          )}
        </>
      )}
      {userTypeSelected && employmentTypeSelected && (
        <InvitePeopleAorSelector centerText employmentType={employmentType} titleElement="h2" />
      )}
    </>
  );
};

export default ImportUserType;
