import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { groupBy, isNil } from 'lodash';
import { withRouter } from 'react-router-dom';
import { parse as queryParser } from 'query-string';

import SkillSearchForm from 'core/assets/js/components/SkillSearchForm.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import SkillSearchResults from 'core/assets/js/components/SkillSearchResults.jsx';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { skillSearchApiUrl, skillCountsByLetterApiUrl } from 'skills/urls';
import SkillSelectSectionSkeleton from 'core/assets/js/components/Skeleton/SkillSelectSectionSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { modalOpenAC, getIsModalOpen, modalCloseAC, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import DeSelectProfileSkillModal, { DESELECT_PROFILE_SKILL_MODAL_ID } from 'accounts/assets/js/components/DeSelectProfileSkillModal.jsx';
import SkillFilterForm from 'core/assets/js/components/SkillFilterForm.jsx';

export const MODAL_ID = 'skills-select-modal';
const SEARCH_KEYWORD_MIN_LENGTH = 2;

class SkillSelectModalPanel extends React.Component {
  static GetComponentName() {
    return 'SkillSelectModalPanel';
  }

  constructor(props) {
    super(props);
    this.props = props;
    this.constantFilters = {
      paginationEnabled: false,
    };

    const { initiallySelected } = this.props;
    const _initiallySelected = initiallySelected || [];
    this.state = {
      query: {},
      showSelected: false,
      selectedItems: [..._initiallySelected],
      filteredDirectoryId: null,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleToggleItem = this.handleToggleItem.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.isDefaultEndorsed = this.isDefaultEndorsed.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.handleDirectoryFilerUpdate = this.handleDirectoryFilerUpdate.bind(this);
    this.initValues = this.initValues.bind(this);
    this.getSelectedItem = this.getSelectedItem.bind(this);
    this.getDefaultEndorsedItem = this.getDefaultEndorsedItem.bind(this);
    this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
    this.onConfirmRemoveEndorsedSkill = this.onConfirmRemoveEndorsedSkill.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { initiallySelected } = this.props;
    if (initiallySelected !== prevProps.initiallySelected) {
      const _initiallySelected = initiallySelected || [];
      this.initValues([..._initiallySelected]);
    }
  }

  async onConfirmRemoveEndorsedSkill(skillId) {
    const { dispatch } = this.props;
    this.handleRemoveSkill(skillId);
    return dispatch(modalCloseAC(DESELECT_PROFILE_SKILL_MODAL_ID));
  }

  getSelectedItem({ id }) {
    const { selectedItems } = this.state;
    if (!selectedItems) {
      return null;
    }

    const selectedItem = selectedItems.find(({ id: itemId }) => itemId === id);

    return selectedItem;
  }

  getDefaultEndorsedItem(item) {
    const { selectionFilter } = this.props;
    const { endorsedDefaultSkills } = selectionFilter;
    if (!endorsedDefaultSkills) {
      return null;
    }

    return endorsedDefaultSkills.find(({ id: itemId }) => itemId === item.id);
  }

  isItemSelected({ id }) {
    const { selectedItems } = this.state;
    if (!selectedItems) {
      return false;
    }

    const isItemSelected = selectedItems.find(({ id: itemId }) => (itemId === id));

    return !!isItemSelected;
  }

  isDefaultEndorsed(item) {
    const { selectionFilter } = this.props;
    const { endorsedDefaultSkills } = selectionFilter;
    if (!endorsedDefaultSkills) {
      return false;
    }

    return endorsedDefaultSkills.findIndex(_item => (_item.id === item.id)) > -1;
  }

  handleCloseModal() {
    const { dispatch, initiallySelected } = this.props;
    this.setState({
      selectedItems: initiallySelected, showSelected: false, query: {}, filteredDirectoryId: null,
    });
    dispatch(modalCloseAC(MODAL_ID));
  }

  handleToggleItem(selectedItem) {
    const { dispatch } = this.props;
    const { selectedItems } = this.state;
    const { initiallySelected } = this.props;

    const isSelected = this.isItemSelected(selectedItem);
    const wereInitiallySelected = Array.isArray(initiallySelected) ? initiallySelected
      .find(({ id }) => id === selectedItem.id) : [];

    if (isSelected) {
      // Remove Item
      if (selectedItem.votesCount > 0 && wereInitiallySelected) {
        dispatch(modalOpenAC(DESELECT_PROFILE_SKILL_MODAL_ID, { skill: selectedItem }));
      } else {
        this.handleRemoveSkill(selectedItem.id);
      }
    } else {
      // Add Item
      this.setState({ selectedItems: [...selectedItems, selectedItem] });
    }
  }

  initValues(values) {
    this.setState({
      selectedItems: values,
    });
  }

  handleQueryUpdate(newQuery) {
    this.setState({ query: { ...this.constantFilters, ...newQuery } });
  }

  handleDirectoryFilerUpdate({ filterBy: directoryId }) {
    this.setState({ filteredDirectoryId: directoryId });
  }

  handleRemoveSkill(skillId) {
    const { selectedItems } = this.state;
    this.setState({ selectedItems: selectedItems.filter(skill => skill.id !== skillId) });
  }


  render() {
    const {
      isOpen,
      handleSubmitSelected,
      lists,
      skillSearchResult,
      memberSkillsOnly,
      isDeselectConfirmationModalOpen,
      selectionFilter,
      heading,
    } = this.props;
    const {
      selectedItems, showSelected, query, query: { kw },
      filteredDirectoryId,
    } = this.state;
    const counts = lists && lists['skill-counts'] && lists['skill-counts'].items;
    const { kw: term } = query;
    const shouldShowSearchResults = (term && term.length >= SEARCH_KEYWORD_MIN_LENGTH)
      || !isNil(filteredDirectoryId);
    const type = 'skill';
    const groups = groupBy(lists['skill-list']?.items, (item) => item?.label?.toLowerCase()[0] || item?.name?.toLowerCase()[0]);

    const commonApiProps = {
      excludeDefaultSkills: selectionFilter?.excludeDefaultSkills,
      memberSkillsOnly,
      onlySystemSkills: selectionFilter?.onlySystemSkills,
      orgId: selectionFilter?.orgId,
    };

    return (
      <ModalPanel
        data-testid="skill-select-modal-panel"
        modalId={MODAL_ID}
        open={isOpen}
        onClose={this.handleCloseModal}
        heading={heading || (
          `Manage your skills ${selectionFilter.orgName ? `for ${selectionFilter.orgName}` : ''}`
        )}
        body={(
          <div className="skill-select-panel no-select">
            {isDeselectConfirmationModalOpen && (
              <DeSelectProfileSkillModal
                onConfirm={this.onConfirmRemoveEndorsedSkill}
              />
            )}

            { !showSelected && (
              <React.Fragment>
                <div className="row">
                  {selectionFilter.orgName && (
                    <div className="col-12 col-md-5 px-0 px-md-3">
                      <SkillFilterForm
                        onFiltersChanged={this.handleDirectoryFilerUpdate}
                        selectionFilter={selectionFilter}
                      />
                    </div>
                  )}
                  <div className="col-12 col-md-5 ml-auto px-0 px-md-3">
                    <SkillSearchForm
                      initialValues={{ kw }}
                      query={{
                        ...query,
                        directoryId: filteredDirectoryId,
                      }}
                      onFiltersChanged={this.handleQueryUpdate}
                    />
                  </div>
                </div>

                <TDApiConnected
                  duck="list"
                  storeKey="skill-counts"
                  loadingEnabled={false}
                  fetchData={({ authedAxios, componentName, dispatch, querystring }) => {
                    const { directoryId } = queryParser(querystring);
                    const url = skillCountsByLetterApiUrl({
                      ...commonApiProps,
                      directoryId,
                    });
                    return dispatch(fetchListDS({
                      url, componentName, authedAxios,
                    }));
                  }}
                  query={{
                    directoryId: filteredDirectoryId,
                  }}
                />


                { !counts && !shouldShowSearchResults && (
                  <SkillSelectSectionSkeleton />
                )}

                <TDApiConnected
                  duck="list"
                  storeKey="skill-list"
                  shouldRefetchOnQueryChange={false}
                  fetchData={({ dispatch, authedAxios, componentName, querystring }) => {
                    const { directoryId } = queryParser(querystring);
                    const url = skillSearchApiUrl({
                      ...commonApiProps,
                      directoryId,
                      paginationEnabled: false,
                      term: '',
                    });
                    return dispatch(fetchListDS({
                      url, componentName, authedAxios,
                    }));
                  }}
                  skeletonComponent={SkillSelectSectionSkeleton}
                  query={{
                    directoryId: filteredDirectoryId,
                  }}
                >
                  {!shouldShowSearchResults && Object.keys(groups)?.sort().map((letter) => {
                    const letterCount = groups[letter].length;
                    return (
                      <div key={letter}>
                        <h3 className="mb-3 text-uppercase">
                          {letter}
                          <span className="ml-2 discreet font-weight-normal">
                            {`(${letterCount})`}
                          </span>
                        </h3>

                        <div className="px-0">
                          <div className="skill-list">
                            { groups[letter]?.map(skill => {
                              const isSelected = this.isItemSelected(skill);
                              if (isSelected) {
                                Object.assign(skill, this.getSelectedItem(skill));
                              }

                              return (
                                <SkillTag
                                  key={skill.id}
                                  skill={skill}
                                  isSelected={isSelected}
                                  onClick={() => this.handleToggleItem(skill)}
                                  showEndorsementIndication
                                  isSelectable
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </TDApiConnected>
              </React.Fragment>
            )}

            <TDApiConnected
              duck="list"
              storeKey={SkillSelectModalPanel.GetComponentName()}
              fetchData={
                ({
                  authedAxios, componentName, dispatch, querystring,
                }) => {
                  const parsedQuery = queryParser(querystring);
                  if (
                    (!parsedQuery.kw || parsedQuery.kw.length <= SEARCH_KEYWORD_MIN_LENGTH)
                    && !parsedQuery.directoryId
                  ) {
                    return Promise.resolve();
                  }

                  const url = skillSearchApiUrl({
                    ...commonApiProps,
                    directoryId: parsedQuery.directoryId,
                    memberSkillsOnly,
                    paginationEnabled: false,
                    term,
                  });
                  return dispatch(fetchListDS({
                    url, componentName, authedAxios,
                  }));
                }}
              loadingEnabled={false}
              skeletonComponent={SkillSelectSectionSkeleton}
              query={{
                ...query,
                directoryId: filteredDirectoryId,
              }}
            >
              {shouldShowSearchResults && (
                <SkillSearchResults
                  skills={skillSearchResult}
                  isSkillSelected={this.isItemSelected}
                  onSkillClick={this.handleToggleItem}
                  getSelectedItem={this.getSelectedItem}
                  isDefaultEndorsed={this.isDefaultEndorsed}
                  getDefaultEndorsedItem={this.getDefaultEndorsedItem}
                />
              )}
            </TDApiConnected>

            {showSelected && (
              <div className="skill-list mb-n5">
                {selectedItems && selectedItems.length === 0 && (
                  <p className="discreet">You haven&apos;t selected any skill yet</p>
                )}

                <SkillSearchResults
                  skills={selectedItems}
                  isSkillSelected={this.isItemSelected}
                  onSkillClick={this.handleToggleItem}
                  getSelectedItem={this.getSelectedItem}
                />
              </div>
            )}
          </div>
        )}
        footer={(
          <React.Fragment>
            <div>
              <label
                onClick={() => {
                  this.setState({ showSelected: !showSelected });
                }}
                className={`d-flex align-items-center mb-5 mb-md-0 cursor-pointer ${selectedItems.length === 0 ? 'discreet' : ''}`}
              >
                <TDSwitch
                  disabled={!selectedItems || selectedItems.length === 0}
                  className="mr-3"
                  selected={showSelected}
                />
                {`Show selected ${pluralize(type, selectedItems.length)}`}
              </label>
            </div>

            <div className="ml-auto">
              <TDButton
                variant={BS_STYLE.DEFAULT}
                onClick={this.handleCloseModal}
                label="Close"
              />

              <TDButton
                className="ml-4"
                variant={BS_STYLE.PRIMARY}
                disabled={!selectedItems} // Allow the selection of empty array
                onClick={() => {
                  this.handleCloseModal();
                  handleSubmitSelected(selectedItems);
                }}
                label={
                  `Select ${selectedItems.length > 0 ? ` ${selectedItems.length} ` : ''} ${pluralize(type, selectedItems.length)}`}
              />
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}
SkillSelectModalPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmitSelected: PropTypes.func.isRequired,
  heading: PropTypes.string,
  history: PropTypes.object.isRequired,
  initiallySelected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isDeselectConfirmationModalOpen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  lists: PropTypes.object.isRequired,
  memberSkillsOnly: PropTypes.bool,
  selectionFilter: PropTypes.object,
  skillSearchResult: PropTypes.array,
};
SkillSelectModalPanel.defaultProps = {
  heading: null,
  initiallySelected: [],
  isDeselectConfirmationModalOpen: false,
  memberSkillsOnly: false,
  selectionFilter: {},
  skillSearchResult: [],
};

const mapStateToProps = (state, props) => {
  const skillListState = getListState(state, SkillSelectModalPanel.GetComponentName());
  const modalPanelPayload = getModalPayload(state, MODAL_ID);
  return {
    skillSearchResult: skillListState.items,
    isOpen: getIsModalOpen(state, MODAL_ID),
    lists: state.list,
    isDeselectConfirmationModalOpen: getIsModalOpen(state, DESELECT_PROFILE_SKILL_MODAL_ID),
    selectionFilter: modalPanelPayload?.selectionFilter,
    initiallySelected: modalPanelPayload?.initiallySelected || props.initiallySelected,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });
const SkillSelectModalPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkillSelectModalPanel);

export default withRouter(SkillSelectModalPanelConnected);
