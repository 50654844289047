import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import YesNoField from 'core/assets/js/components/FinalFormFields/YesNoField.jsx';
import { CUSTOM_ICONS, ICON } from 'core/assets/js/constants';
import {
  DOCUMENT_CONTRACT_TYPE,
  DOCUMENT_CONTRACT_TYPE_LABEL,
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_LABEL,
  DOCUMENT_CONTRACT_TYPE_BULLET_POINTS,
  DOCUMENT_CONTRACT_TYPE_LABEL_WARNING,
} from 'documents/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const SelectCard = ({ item: { name, icon, sublabel, isContractType = false } }) => (
  <div className="d-flex align-items-center document-form-type-option">
    <div className={`pr-3 d-md-inline-block payment-method-card-item__logo-container
      ${isContractType && 'align-self-baseline'}`}
    >
      {icon}
    </div>
    <div className="mx-3">
      <h4 className="mt-0 mb-1 payment-method-item-card__title">{name}</h4>
      <div className="hint">{sublabel}</div>
    </div>
  </div>
);

SelectCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    icon: PropTypes.element.isRequired,
    sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    isContractType: PropTypes.bool,
  }).isRequired,
};

const getContractTypeItems = (documentsWithEsignEnabled) => {
  const items = [
    {
      icon: <img alt="checkbox file icon" src={CUSTOM_ICONS.FILE_CHECKBOX} />,
      id: DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH,
      name:
  <>
    {DOCUMENT_CONTRACT_TYPE_LABEL[DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH]}
    <span className="document-type-label-warning">
      {DOCUMENT_CONTRACT_TYPE_LABEL_WARNING[DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH]}
    </span>
  </>,
      sublabel:
  <ul className="document-type-points">
    {
      DOCUMENT_CONTRACT_TYPE_BULLET_POINTS[DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH].map(item => (
        <li className="document-type-points-item" key={item}>{item}</li>
      ))
    }
  </ul>,
      isContractType: true,
    },
  ];
  if (documentsWithEsignEnabled) {
    items.push({
      icon: <img alt="esign icon" src={CUSTOM_ICONS.ESIGN} />,
      id: DOCUMENT_CONTRACT_TYPE.ESIGN,
      name:
  <>
    {DOCUMENT_CONTRACT_TYPE_LABEL[DOCUMENT_CONTRACT_TYPE.ESIGN]}
    <span className="document-type-label-warning">
      {DOCUMENT_CONTRACT_TYPE_LABEL_WARNING[DOCUMENT_CONTRACT_TYPE.ESIGN]}
    </span>
  </>,
      sublabel:
  <ul className="document-type-points">
    {
      DOCUMENT_CONTRACT_TYPE_BULLET_POINTS[DOCUMENT_CONTRACT_TYPE.ESIGN].map(item => (
        <li className="document-type-points-item" key={item}>{item}</li>
      ))
    }
  </ul>,
      isContractType: true,
    });
  }
  return items;
};

const documentTypeItems = [
  {
    icon: <i className={`${ICON.PEN_LINE_DUOTONE} fa-2xl`} />,
    id: DOCUMENT_TYPE.CONTENT,
    name: DOCUMENT_TYPE_LABEL[DOCUMENT_TYPE.CONTENT],
    sublabel: 'Editable contract template where you can write or copy-paste the text',
  },
  {
    icon: <i className={`${ICON.PDF_DUOTONE} fa-2xl`} />,
    id: DOCUMENT_TYPE.FILE,
    name: DOCUMENT_TYPE_LABEL[DOCUMENT_TYPE.FILE],
    sublabel: 'PDF attachment which you can’t edit',
  },
];

const DocumentFormType = () => {
  const { change } = useForm();
  const { submitErrors, values: { contractType, hasCountersigners, type } } = useFormState();
  const activeOrg = useSelector(selectActiveOrg);
  const contractTypeItems = useMemo(
    () => getContractTypeItems(activeOrg.documents_with_esign_enabled),
    [activeOrg?.documents_with_esign_enabled],
  );
  const countersigningEnabled = activeOrg?.documents_with_esign_enabled
    && activeOrg?.documents_with_countersigning_enabled;
  return (
    <>
      <h2>How would you like to execute this contract?</h2>
      <CheckableList
        data-testid="document-select-contract-type"
        defaultValue={(
          contractType && contractTypeItems.find(i => i.id === parseInt(contractType, 10))
        )}
        itemComponent={SelectCard}
        items={contractTypeItems}
        onItemChecked={({ id }) => {
          change('contractType', id);
          if (id === DOCUMENT_CONTRACT_TYPE.ESIGN) {
            change('type', DOCUMENT_TYPE.ESIGN);
            change('files', null);
            change('content', null);
          }
        }}
      />
      {contractType === DOCUMENT_CONTRACT_TYPE.CLICK_THROUGH && (
        <>
          <h2>Select contract template type</h2>
          <CheckableList
            data-testid="document-select-document-type"
            defaultValue={type && documentTypeItems.find(i => i.id === parseInt(type, 10))}
            itemComponent={SelectCard}
            items={documentTypeItems}
            onItemChecked={({ id }) => {
              change('type', id);
              change(id === DOCUMENT_TYPE.CONTENT ? 'files' : 'content', null);
            }}
          />
        </>
      )}
      {countersigningEnabled && contractType === DOCUMENT_CONTRACT_TYPE.ESIGN && (
        <>
          <h2 className="mt-5">Contract countersigning</h2>
          <YesNoField
            label="Does this contract need to be countersigned by someone in your Organization?"
            name="hasCountersigners"
            required
            showInline
            showTextInput={false}
          />
          {hasCountersigners?.isYes && (
            <SelectField
              label="How many countersignatures are required?"
              name="countersignersCount"
              optionsMapping={[{ text: '1', value: 1 }, { text: '2', value: 2 }]}
              required
              sublabel={[
                'We will automatically create the countersignature roles, for you to place',
                ' signature fields in the contract template',
              ].join('')}
            />
          )}
        </>
      )}
      {submitErrors?.type && (
        <div className="text-danger p-4">{submitErrors.type}</div>
      )}
    </>
  );
};

DocumentFormType.GetComponentName = () => 'DocumentFormType';

export default DocumentFormType;
