/* eslint-disable
  no-restricted-globals, node/no-process-env, react/no-multi-comp
*/
/* global __SETTINGS__, __CLIENT__, localStorage, window, document,
  Event, Headers, Blob, navigator, File */
import { get, isEmpty } from 'lodash';

/**
 * Attempts to read a setting from the global __SETTINGS__ object that is expected to
 * be present as a global definition from Webpack, and is representing the frontend
 * environment settings
 *
 * @param {string} key - The key of the setting
 * @param {any} defaultValue - A default value to return in case there is not setting
 */
const readSetting = (key, defaultValue) => {
  const value = (typeof __SETTINGS__ !== 'undefined' && __SETTINGS__[key]) || defaultValue;
  return value;
};

/**
 * Attempts to read a setting from process.env. Environment variables are not actually
 * present in the frontend application, however, we are allowing our backend to reuse
 * some utilities of the frontend, in which case, the settings are defined by the
 * respective environment variables
 *
 * @param {string} key - The key of the setting
 * @param {any} defaultValue - A default value to return in case there is not setting
 */
const readFromEnv = (key, defaultValue) => {
  const value = (typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env[key]) || defaultValue;
  return value;
};

/**
 * Attempts to read a setting from localStorage.
 *
 * @param {string} key - The key of the setting
 * @param {any} defaultValue - A default value to return in case there is not setting
 */
const readFromLocalStorage = (key, defaultValue) => {
  const value = (typeof localStorage !== 'undefined' && !isEmpty(localStorage) && localStorage[key]) || defaultValue;
  return value;
};

/**
 * Attempts to read a setting from window.
 *
 * @param {string} key - The key of the setting
 * @param {any} defaultValue - A default value to return in case there is not setting
 */
const readFromWindow = (key, defaultValue) => {
  const value = (typeof window !== 'undefined' && !isEmpty(window) && get(window, key, defaultValue)) || defaultValue;
  if (typeof window !== 'undefined' && typeof value === 'function') {
    return value.bind(window);
  }
  return value;
};

/**
 * Attempts to read a setting from document.
 *
 * @param {string} key - The key of the setting
 * @param {any} defaultValue - A default value to return in case there is not setting
 */
const readFromDocument = (key, defaultValue) => {
  const value = (typeof document !== 'undefined' && !isEmpty(document) && get(document, key, defaultValue)) || defaultValue;
  if (typeof document !== 'undefined' && typeof value === 'function') {
    return value.bind(document);
  }
  return value;
};

const IS_CLIENT = (typeof __CLIENT__ !== 'undefined' && __CLIENT__);

const NODE_ENV = readSetting('NODE_ENV', readFromEnv('NODE_ENV'));
const ENV_PRODUCTION = readSetting('ENV_PRODUCTION', 'production');
const ENV_STAGING = readSetting('ENV_STAGING', 'staging');
const ENV_TEST = readSetting('ENV_TEST', 'test');
const ENV_DEVELOPMENT = readSetting('ENV_DEVELOPMENT', 'development');
const IS_PRODUCTION = NODE_ENV === ENV_PRODUCTION;
const IS_DEVELOPMENT = NODE_ENV === ENV_DEVELOPMENT;

const PROCESS_MODE = readSetting('PROCESS_MODE', readFromEnv('MODE'));
const HEALTH_MODE = readSetting('HEALTH_MODE', 'health');

const TIMETRAVEL = readSetting('TIMETRAVEL', readFromEnv('TIMETRAVEL'));
const REDUX_DEBUG = NODE_ENV === ENV_DEVELOPMENT;
const RECAPTCHA_DISABLED_LOCALLY = true;


// Services
const BEAMER = readFromWindow('Beamer');
const COOKIES = readSetting('cookies');
const FILESTACK = readSetting('filestack');
const HUBSPOT = readFromWindow('hubspot');

const GOOGLE = readSetting('google');
const SENTRY = readSetting('sentry');
const PUSHER = readSetting('pusher');
const SIGNABLE = readSetting('signable');

// For logging
const TD_DEBUG = readFromLocalStorage('TD_DEBUG');
const LOG_ERROR_STACK = readFromEnv('LOG_ERROR_STACK', false);

// Document vars
const HAS_DOCUMENT = typeof document !== 'undefined';
const DOCUMENT_ACTIVE_ELEMENT = readFromDocument('activeElement', '');
const DOCUMENT_BODY = readFromDocument('body', '');

/**
 * Attempts to read a property from body
 *
 * @param {String} propertyName - The property name
 * @param {Any} defaultValue - A default value to return in case there is no property
 * @param {String} [expectedType] - The expected type of the property
 */
const readFromBody = (propertyName, defaultValue, expectedType = 'function') => {
  // eslint-disable-next-line valid-typeof
  if (typeof DOCUMENT_BODY !== 'object' || typeof DOCUMENT_BODY[propertyName] !== expectedType) {
    return defaultValue;
  }
  const property = DOCUMENT_BODY[propertyName];
  return expectedType === 'function' ? property.bind(DOCUMENT_BODY) : property;
};

const DOCUMENT_BODY_APPEND_CHILD = readFromBody('appendChild', () => null);
const DOCUMENT_BODY_CHILDREN = readFromBody('children', [], 'object');
const DOCUMENT_BODY_CLICK = readFromBody('click', () => null);
const DOCUMENT_BODY_REMOVE_CHILD = readFromBody('removeChild', () => null);
const DOCUMENT_CREATE_ELEMENT = readFromDocument('createElement', () => null);
const DOCUMENT_GET_ELEMENT_BY_ID = readFromDocument('getElementById', () => null);
const DOCUMENT_QUERY_SELECTOR = readFromDocument('querySelector', () => null);
const DOCUMENT_QUERY_SELECTOR_ALL = readFromDocument('querySelectorAll', () => null);
const DOCUMENT_SET_TITLE = (title) => {
  if (typeof document !== 'undefined') {
    document.title = title;
  }
};
const DOCUMENT_COOKIE = readFromDocument('cookie', '');

// Window vars
const HAS_WINDOW = typeof window !== 'undefined';
const WINDOW = (typeof window !== 'undefined') && window;
const WINDOW_ADD_EVENT_LISTENER = readFromWindow('addEventListener', () => {});
const WINDOW_ATOB = readFromWindow('atob', val => val);
const WINDOW_HREF = readFromWindow('location.href');
const WINDOW_HUBSPOT_CONVERSATIONS_OPEN = () => {
  const chatBubblewidget = readFromWindow('HubSpotConversations.widget');
  chatBubblewidget?.open();
};
const WINDOW_LOCATION_ASSIGN = readFromWindow('location.assign', () => {});
const WINDOW_INNER_WIDTH = readFromWindow('innerWidth');
const WINDOW_MATCH_MEDIA = readFromWindow('matchMedia', () => {});
const WINDOW_OPEN = readFromWindow('open', () => {});
const WINDOW_ORIGIN = readFromWindow('location.origin');
const WINDOW_PRELOADED_STATE = readFromWindow('__PRELOADED_STATE__');
const WINDOW_REDIRECT = (url) => {
  if (typeof window === 'undefined' || !window) {
    return;
  }
  window.location.href = url;
};
const WINDOW_RELOAD = () => {
  if (typeof window === 'undefined' || !window) {
    return;
  }
  window.location.reload();
};
const WINDOW_REMOVE_EVENT_LISTENER = readFromWindow('removeEventListener', () => {});
const WINDOW_REDUX_DEVTOOLS_EXTENSION_COMPOSE = readFromWindow('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__');

const WINDOW_SET = (key, value) => {
  if (typeof window !== 'undefined') {
    window[key] = value;
  }
};

const WINDOW_SET_ON_BEFORE_UNLOAD = (fn) => {
  if (typeof window === 'undefined' || !window) {
    return;
  }
  window.onbeforeunload = fn;
};
const WINDOW_SET_RECAPTCHA_OPTIONS = (options) => {
  if (typeof window === 'undefined' || !window) {
    return;
  }
  window.recaptchaOptions = options;
};
const WINDOW_SET_TALENTDESK_GLOBAL = (state) => {
  if (typeof window === 'undefined' || !window) {
    return;
  }
  window._talentdesk = state;
};
const WINDOW_SCROLL_TO = readFromWindow('scrollTo', () => {});


const CREATE_BLOB = (...args) => {
  if (typeof Blob === 'undefined') {
    throw new Error('Browser API does not support Blob');
  }
  return new Blob(...args);
};

const DISPATCH_EVENT_ON_ELEMENT = (element, ...eventParams) => {
  if (!element || typeof Event === 'undefined') {
    return;
  }
  element.dispatchEvent(new Event(...eventParams));
};

const GET_HTTP_HEADERS = (params) => {
  if (typeof Headers === 'undefined') {
    return params;
  }
  return new Headers(params);
};

const WRITE_TO_CLIPBOARD = (value) => {
  if (typeof navigator === 'undefined') {
    return;
  }
  navigator.clipboard.writeText(value);
};

const GET_BROWSER_LOCALE = () => {
  if (typeof navigator === 'undefined') {
    return 'en-US';
  }
  return navigator.language || 'en-US';
};

const GET_WINDOW_INNER_WIDTH = () => {
  return readFromWindow('innerWidth');
};

const IS_FILE = element => typeof File !== 'undefined' && (element instanceof File);

export {
  IS_CLIENT,
  IS_FILE,

  NODE_ENV,
  ENV_PRODUCTION,
  ENV_STAGING,
  ENV_TEST,
  ENV_DEVELOPMENT,
  IS_PRODUCTION,
  IS_DEVELOPMENT,

  PROCESS_MODE,
  HEALTH_MODE,

  TIMETRAVEL,
  REDUX_DEBUG,
  RECAPTCHA_DISABLED_LOCALLY,

  BEAMER,
  COOKIES,
  FILESTACK,
  HUBSPOT,
  GOOGLE,
  PUSHER,
  SENTRY,
  SIGNABLE,

  TD_DEBUG,
  LOG_ERROR_STACK,

  HAS_DOCUMENT,
  DOCUMENT_ACTIVE_ELEMENT,
  DOCUMENT_BODY,
  DOCUMENT_BODY_APPEND_CHILD,
  DOCUMENT_BODY_CHILDREN,
  DOCUMENT_BODY_CLICK,
  DOCUMENT_BODY_REMOVE_CHILD,
  DOCUMENT_COOKIE,
  DOCUMENT_CREATE_ELEMENT,
  DOCUMENT_GET_ELEMENT_BY_ID,
  DOCUMENT_QUERY_SELECTOR,
  DOCUMENT_QUERY_SELECTOR_ALL,
  DOCUMENT_SET_TITLE,

  HAS_WINDOW,
  WINDOW,
  WINDOW_ADD_EVENT_LISTENER,
  WINDOW_ATOB,
  WINDOW_HREF,
  WINDOW_HUBSPOT_CONVERSATIONS_OPEN,
  WINDOW_INNER_WIDTH,
  WINDOW_LOCATION_ASSIGN,
  WINDOW_MATCH_MEDIA,
  WINDOW_OPEN,
  WINDOW_ORIGIN,
  WINDOW_PRELOADED_STATE,
  WINDOW_REDIRECT,
  WINDOW_REDUX_DEVTOOLS_EXTENSION_COMPOSE,
  WINDOW_REMOVE_EVENT_LISTENER,
  WINDOW_RELOAD,
  WINDOW_SET,
  WINDOW_SET_ON_BEFORE_UNLOAD,
  WINDOW_SET_RECAPTCHA_OPTIONS,
  WINDOW_SET_TALENTDESK_GLOBAL,
  WINDOW_SCROLL_TO,

  CREATE_BLOB,
  DISPATCH_EVENT_ON_ELEMENT,
  GET_HTTP_HEADERS,
  GET_WINDOW_INNER_WIDTH,
  WRITE_TO_CLIPBOARD,
  GET_BROWSER_LOCALE,
};
