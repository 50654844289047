export const bankAccountType = {
  commercial: 'Business/commercial',
  personal: 'Individual/personal',
};

export const bankAccountTypeOptions = Object.keys(bankAccountType).map(k => ({
  value: k, text: bankAccountType[k],
}));

export const bankAccountSubType = {
  checking: 'Checking',
  savings: 'Savings',
};

export const bankAccountSubTypeOptions = Object.keys(bankAccountSubType).map(k => ({
  value: k, text: bankAccountSubType[k],
}));
