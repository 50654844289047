import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import ManageTemplatesTabs from 'settings/assets/js/components/ManageTemplatesTabs.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import ManageTemplatesTabContent from 'settings/assets/js/components/ManageTemplatesTabContent.jsx';
import { orgInvitationTemplateCreateUrl, customFieldTemplateTypeSelectUrl } from 'settings/urls';

class OrgManageTemplatesView extends React.Component {
  static GetComponentName() {
    return 'OrgManageTemplatesView';
  }

  render() {
    const { match: { params: { tab, orgAlias } }, history } = this.props;

    const breadcrumbs = [
      {
        title: 'Templates',
        url: null,
      },
    ];

    let createUrl = orgInvitationTemplateCreateUrl(orgAlias);

    if (tab === SETTINGS_TEMPLATE_TABS.CUSTOM_FIELDS) {
      createUrl = customFieldTemplateTypeSelectUrl(orgAlias);
    }

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaButtonItems={[{
            label: 'Create template',
            onClick: () => history.push(createUrl),
            variant: BS_STYLE.PRIMARY,
          }]}
        >
          <ManageTemplatesTabs tab={tab} />
        </ContentHeader>

        <div className="page page--settings">
          <div className="container">
            <ManageTemplatesTabContent tab={tab} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OrgManageTemplatesView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(OrgManageTemplatesView));
