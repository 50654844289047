import PropTypes from 'prop-types';

const bankAccountSpec = PropTypes.shape({
  account_number: PropTypes.string,
  alias: PropTypes.string,
  bank_code: PropTypes.string,
  bank_name: PropTypes.string,
  beneficiary: PropTypes.string,
  country_code: PropTypes.string,
  custom_reference: PropTypes.string,
});

const twCurrencyReqsSpec = PropTypes.shape({
  currency: PropTypes.string,
  requirements: PropTypes.object,
});

const paymentSettingSpec = PropTypes.shape({
  id: PropTypes.number,
  grace_period: PropTypes.number,
});

const invitationTemplateSpec = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string,
});

export {
  bankAccountSpec,
  twCurrencyReqsSpec,
  paymentSettingSpec,
  invitationTemplateSpec,
};
