import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { listReplaceItemAC } from 'core/assets/js/ducks/list';
import { viewFetchExtrasAC } from 'core/assets/js/ducks/view';
import WorksheetsTable from 'finance/assets/js/components/WorksheetsTable.jsx';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { financeWorksheetAnalyticsApiUrl, financeWorksheetListApiUrl } from 'finance/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const TaskWorksheetsTab = ({ filtersOpen, onFiltersToggle, parentComponentName }) => {
  const dispatch = useDispatch();
  const { taskId } = useParams();
  const location = useLocation();
  const activeOrg = useSelector(selectActiveOrg);

  const componentName = TaskWorksheetsTab.GetComponentName();
  const { hasLoaded, isLoading, items, pagination } = fetchDataHook({
    componentName,
    duck: 'list',
    queryStringParams: { taskId },
    url: financeWorksheetListApiUrl(activeOrg.alias),
  });
  const { item: { amountLimits } } = fetchDataHook({
    changesArray: [location.search],
    componentName,
    url: `${financeWorksheetAnalyticsApiUrl(activeOrg.alias)}${location.search}`,
  });

  const finalIsLoading = !hasLoaded || isLoading;

  return (
    <>
      <div className="row">
        <ReportSearch
          amountLimits={amountLimits}
          filtersOpen={filtersOpen}
          onFiltersToggle={onFiltersToggle}
          reportType={REPORT_TYPES.WORKSHEET}
        />
      </div>
      {finalIsLoading && <FinanceTableSkeleton />}
      {!finalIsLoading && (
        <WorksheetsTable
          currency={CURRENCY_SYMBOL[activeOrg.currency]}
          emptyText="No worksheets found"
          expandableRow={false}
          list={items}
          onItemUpdate={(updatedItem, projectAllowedActions) => {
            // Update list item
            dispatch(listReplaceItemAC(updatedItem, TaskWorksheetsTab.GetComponentName()));
            // Update project Allowed actions.
            dispatch(viewFetchExtrasAC(
              projectAllowedActions, parentComponentName, 'allowedActions',
            ));
          }}
          rateUnit={activeOrg?.default_rate_unit}
        />
      )}
      <TDPagination {...pagination} />
    </>
  );
};

TaskWorksheetsTab.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
};

TaskWorksheetsTab.GetComponentName = () => 'TaskWorksheetsTab';

export default withFilters(TaskWorksheetsTab);
