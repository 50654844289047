import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import ProjectOverviewWidget from 'projects/assets/js/components/widgets/ProjectOverviewWidget.jsx';
import ProjectAnalyticsWidgets from 'projects/assets/js/components/widgets/ProjectAnalyticsWidgets.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { getViewState } from 'core/assets/js/ducks/view';
import * as projectDS from 'projects/assets/js/data-services/view';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import ProjectTabDashboardSkeleton from 'projects/assets/js/components/skeletons/ProjectTabDashboardSkeleton.jsx';
import { fetchPurchaseOrderAnalyticsDS } from 'analytics/assets/js/data-services/analytics';
import { DATE_DURATION, DATE_DURATION_API_KEY } from 'core/assets/js/constants';

class ProjectTabDashboard extends React.Component {
  static GetComponentName() {
    return 'ProjectDashboard';
  }

  constructor(props) {
    super(props);
    this.handleProviderInvitationUpdated = this.handleProviderInvitationUpdated.bind(this);
  }

  handleProviderInvitationUpdated() {
    const { dispatch, match: { params } } = this.props;
    dispatch(projectDS.fetchProjectProvidersDS({
      orgAlias: params.orgAlias, id: params.id,
    }));
  }

  render() {
    const {
      currentMonthAnalytics,
      fromStartAnalytics,
      isManager,
      match,
      oneYearAnalytics,
      parentComponentName,
      project,
      purchaseOrderAnalytics,
      sixMonthsAnalytics,
      threeMonthsAnalytics,
    } = this.props;
    // Show skeleton while loading project. (Checks if project object is "{}" )
    if (Object.getOwnPropertyNames(project).length === 0) {
      return (<ProjectTabDashboardSkeleton />);
    }

    return (
      <div className="row">
        <div className="col-12 col-md-4 mb-4 mb-md-0">
          <ProjectOverviewWidget
            isCollapsible={false}
            orgAlias={match.params.orgAlias}
            parentComponentName={parentComponentName}
            project={project}
            isManager={isManager}
            purchaseOrderCount={purchaseOrderAnalytics.count}
            worksheetCount={get(fromStartAnalytics, `analytics.byType.${SERVICE_ORDER_TYPE.WORKSHEET}.count`)}
            expenseCount={get(fromStartAnalytics, `analytics.byType.${SERVICE_ORDER_TYPE.EXPENSE}.count`)}
          />
        </div>
        <div className="col-12 col-md-8 mt-2 mt-md-0">
          <div className="row">
            <ProjectAnalyticsWidgets
              project={project}
              currentMonthAnalytics={currentMonthAnalytics}
              threeMonthsAnalytics={threeMonthsAnalytics}
              sixMonthsAnalytics={sixMonthsAnalytics}
              oneYearAnalytics={oneYearAnalytics}
              fromStartAnalytics={fromStartAnalytics}
            />
          </div>
        </div>

        { isManager && (
          <TDApiConnected
            duck="view"
            name="purchaseOrderAnalytics"
            fetchData={({ dispatch, params, url, authedAxios, componentName }) => (
              dispatch(fetchPurchaseOrderAnalyticsDS({
                url,
                ...params,
                projectId: params.id,
                componentName,
                authedAxios,
                dispatch,
              }))
            )}
          />
        )}
      </div>
    );
  }
}

ProjectTabDashboard.propTypes = {
  currentMonthAnalytics: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fromStartAnalytics: PropTypes.object.isRequired,
  isManager: PropTypes.bool,
  match: routerMatchContentsSpec.isRequired,
  oneYearAnalytics: PropTypes.object.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  project: projectSpec.isRequired,
  purchaseOrderAnalytics: PropTypes.object.isRequired,
  sixMonthsAnalytics: PropTypes.object.isRequired,
  threeMonthsAnalytics: PropTypes.object.isRequired,
  worksheetAnalytics: PropTypes.object,
};

ProjectTabDashboard.defaultProps = {
  isManager: false,
  worksheetAnalytics: {},
};

const mapStateToProps = (state) => {
  const hasOrgAccess = getHasOrgAccess(state);
  const currentMonthAnalytics = getViewState(state, `worksheetAnalytics_${DATE_DURATION_API_KEY[DATE_DURATION.CURRENT_MONTH]}`);
  const threeMonthsAnalytics = getViewState(state, `worksheetAnalytics_${DATE_DURATION_API_KEY[DATE_DURATION.THREE_MONTHS]}`);
  const sixMonthsAnalytics = getViewState(state, `worksheetAnalytics_${DATE_DURATION_API_KEY[DATE_DURATION.SIX_MONTHS]}`);
  const oneYearAnalytics = getViewState(state, `worksheetAnalytics_${DATE_DURATION_API_KEY[DATE_DURATION.ONE_YEAR]}`);
  const fromStartAnalytics = getViewState(state, `worksheetAnalytics_${DATE_DURATION_API_KEY[DATE_DURATION.FROM_START]}`);

  return {
    currentMonthAnalytics: currentMonthAnalytics.item,
    fromStartAnalytics: fromStartAnalytics.item,
    isManager: hasOrgAccess({ requireManager: true }),
    oneYearAnalytics: oneYearAnalytics.item,
    purchaseOrderAnalytics: getViewState(state, 'purchaseOrderAnalytics').item,
    sixMonthsAnalytics: sixMonthsAnalytics.item,
    threeMonthsAnalytics: threeMonthsAnalytics.item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectTabDashboardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTabDashboard);

export default withRouter(ProjectTabDashboardConnected);
