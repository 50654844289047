import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import axios from 'core/assets/js/lib/tdAxios';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import AsyncSelectionField from 'core/assets/js/components/FinalFormFields/AsyncSelectionField.jsx';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BS_SIZE, ICON } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { skillSearchApiUrl } from 'skills/urls';

export const MODAL_ID = 'profile-skills-select-modal';

const OptionRenderer = ({ data: { label, value: id }, innerProps }) => (
  <div
    {...innerProps}
    className={(
      'react-select__group-item d-flex align-items-center w-100 py-2 pl-3 pr-4 justify-content-between'
    )}
    key={id}
  >
    {label}
    <i className={ICON.ADD} />
  </div>
);

OptionRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
};

const ProfileSkillSelectModal = ({
  handleSubmitSelected,
  heading,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [isOpen]);

  const isItemSelected = ({ id }) => {
    if (!selectedItems) {
      return false;
    }

    const _isItemSelected = selectedItems.find(({ id: itemId }) => (itemId === id));
    return !!_isItemSelected;
  };

  const handleCloseModal = () => {
    dispatch(modalCloseAC(MODAL_ID));
  };

  const handleAddItem = (selectedItem) => {
    if (!isItemSelected(selectedItem)) {
      setSelectedItems([...selectedItems, selectedItem]);
    }
  };

  const handleRemoveSkill = ({ id }) => {
    setSelectedItems(selectedItems.filter(skill => skill.id !== id));
  };

  const searchSkills = async term => {
    try {
      const url = skillSearchApiUrl({
        excludeDefaultSkills: false,
        onlySystemSkills: true,
        excludeSkillIds: selectedItems.map(s => s.id).join(','),
        term,
        excludeUserProfileSkills: true,
      });
      const { data } = await axios.get(url);
      return data.map(skill => ({ label: skill.label, value: skill.id }));
    } catch (err) {
      toastr.error('Oh Snap!', err.data?._error || err.message);
      return [];
    }
  };

  const searchSkillsDebounced = useCallback(
    debounce((term, callback) => {
      searchSkills(term).then(options => callback(options));
    }, 500),
    [selectedItems],
  );

  return (
    <ModalSimple
      modalId={MODAL_ID}
      open={isOpen}
      size={BS_SIZE.LARGE}
      onClose={handleCloseModal}
      heading={heading}
      body={(
        <div className="skill-select-panel no-select">
          <div className="skill-list mb-3">
            {selectedItems.map((skill) => (
              <SkillTag
                key={skill.id}
                skill={skill}
                onRemove={() => handleRemoveSkill(skill)}
              />
            ))}
          </div>


          <Form
            onSubmit={() => {}}
            render={() => (
              <AsyncSelectionField
                loadOptions={searchSkillsDebounced}
                name="kw"
                extraConfiguration={{
                  noOptionsMessage: ({ inputValue }) => {
                    if (inputValue) {
                      return 'No skills found';
                    }
                    return null;
                  },
                  controlShouldRenderValue: false,
                }}
                onChange={(item) => {
                  if (!item) {
                    return;
                  }

                  const { value: id, label } = item;
                  handleAddItem({ id, label });
                }}
                placeholder="Search skills"
                components={{
                  DropdownIndicator: () => (
                    <i className={`${ICON.SEARCH} mr-3`} />
                  ),
                  IndicatorSeparator: () => null,
                  Option: OptionRenderer,
                }}
              />
            )}
          />
        </div>
      )}
      footer={(
        <div className="ml-auto">
          <TDButton
            variant={BS_STYLE.DEFAULT}
            onClick={handleCloseModal}
            label="Close"
          />

          <TDButton
            className="ml-4"
            variant={BS_STYLE.PRIMARY}
            disabled={!selectedItems || selectedItems.length === 0}
            onClick={() => {
              handleCloseModal();
              handleSubmitSelected(selectedItems);
            }}
            label={
              `Add ${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`}
          />
        </div>
      )}
    />
  );
};

ProfileSkillSelectModal.GetComponentName = () => 'ProfileSkillSelectModalPanel';

ProfileSkillSelectModal.propTypes = {
  handleSubmitSelected: PropTypes.func.isRequired,
  heading: PropTypes.string,
};
ProfileSkillSelectModal.defaultProps = {
  heading: 'Add your skills',
};

export default withRouter(ProfileSkillSelectModal);
