/* eslint no-underscore-dangle: ["error", { "allow": ["_error"] }] */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { validateLength } from 'core/assets/js/lib/utils';

export const validateReason = value => validateLength(value, 2000);

const ReasonForm = React.memo(({ hasReason, error, question }) => {
  let questionDisplay = null;
  if (question) {
    if (typeof question === 'string') {
      questionDisplay = <p>{question}</p>;
    } else {
      questionDisplay = question;
    }
  }
  return (
    <form>
      {questionDisplay}
      {hasReason && (
        <Field
          component={InputField}
          label="Reason"
          name="reason"
          type="textarea"
          validate={validateReason}
        />
      )}
      {error && (
        <div className="form-group has-error">
          <span className="help-block">{error}</span>
        </div>
      )}
    </form>
  );
});

ReasonForm.propTypes = {
  question: PropTypes.string,
  error: PropTypes.string,
  hasReason: PropTypes.bool,
};

ReasonForm.defaultProps = {
  question: '',
  error: '',
  hasReason: true,
};

export default ReasonForm;
