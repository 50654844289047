import React from 'react';
import PropTypes from 'prop-types';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { ORDERING_DIRECTION } from 'core/assets/js/constants';


const DEFAULT_ORDERING = {
  direction: ORDERING_DIRECTION.DESC,
  sortBy: 'created_at',
};


class ActivitySearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
  }

  getSearchSpec() { // eslint-disable-line class-methods-use-this
    const searchSpec = {
      filters: [],
      searchTerm: {
        placeholder: 'Search',
        paramName: 'kw',
        component: TextInputField,
      },
      defaultOrdering: DEFAULT_ORDERING,
      orderingOptions: [
        { text: 'Date', value: 'created_at', filterDefaultDirection: ORDERING_DIRECTION.DESC },
        // { text: 'ID', value: 'id', filterDefaultDirection: ORDERING_DIRECTION.ASC },
        // { text: 'Type', value: 'type', filterDefaultDirection: ORDERING_DIRECTION.ASC },
      ],
    };
    return searchSpec;
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;

    return (
      <SearchFinalForm
        filtersOpen={filtersOpen}
        onFiltersToggle={onFiltersToggle}
        searchSpec={this.getSearchSpec()}
      />
    );
  }
}

ActivitySearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withFilters(ActivitySearch);
