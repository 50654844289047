import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';


function ReportDetails({ rows }) {
  if (!rows || rows.length === 0) {
    return null;
  }
  const children = rows.map((r) => {
    const key = kebabCase(r.label);
    return (
      <div key={key}>
        <label htmlFor={`worksheet-details-${key}`}>{`${r.label}:`}</label>
        <span id={`worksheet-details-${key}`}>{r.value}</span>
      </div>
    );
  });
  return (
    <div>
      {children}
    </div>
  );
}

ReportDetails.propTypes = {
  rows: PropTypes.array,
};

ReportDetails.defaultProps = {
  rows: [],
};

export default ReportDetails;
