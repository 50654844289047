import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import SkillSelectModalPanel, { MODAL_ID as SKILL_SELECT_PANEL_MODAL_ID } from 'core/assets/js/components/SkillSelectModalPanel.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';
import { ICON } from 'core/assets/js/constants';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { orgPublicSpec } from 'organizations/assets/js/lib/objectSpecs';

const addSkillTooltip = (
  <Tooltip id="add-skill-tooltip">
    Add skill
  </Tooltip>
);

const AddMoreSkillsButton = ({ className, handleModalOpen }) => (
  <OverlayTrigger
    popperConfig={{
      modifiers: {
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
      },
    }}
    delay={{ show: 250, hide: 0 }}
    overlay={addSkillTooltip}
  >
    <a className={`add-skill-button ${className}`} onClick={handleModalOpen}>
      <span className={ICON.ADD_CIRCLE} />
    </a>
  </OverlayTrigger>
);

AddMoreSkillsButton.propTypes = {
  className: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
};

const SkillSelectField = ({
  dispatch, label, required, className, sublabel, memberSkillsOnly, modalHeading,
  input, meta: { error, submitError }, organization,
}) => {
  const classNames = ['form-group skill-list-field'];

  if (className) {
    classNames.push(className);
  }

  if (submitError || error) {
    classNames.push('has-error');
  }

  const handleModalOpen = () => {
    dispatch(modalOpenAC(SKILL_SELECT_PANEL_MODAL_ID, {
      value: input.value,
      selectionFilter: {
        orgId: organization.id,
        orgName: organization.name,
        orgAlias: organization.alias,
      },
    }));
  };

  const onItemsSelected = (selectedItems) => {
    input.onChange(selectedItems);
  };

  const handleSkillRemove = (skill) => {
    const idx = input.value.findIndex(_item => _item.id === skill.id);
    if (idx > -1) {
      // Remove Item
      onItemsSelected(input.value.filter(item => item.id !== skill.id));
    }
  };

  return (
    <div className={classNames.join(' ')} data-testid="skill-select-field">
      <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />

      <div className="skill-list">
        {input.value && input.value.map(skill => (
          <SkillTag
            key={skill.id}
            skill={skill}
            onRemove={handleSkillRemove}
          />
        ))}

        {(!input.value || (input.value && input.value.length === 0)) && (
          <div className="d-flex align-content-between align-items-center flex-row">
            <a data-testid="skills-select-field-select-skills" className="discreet mb-1" onClick={handleModalOpen}>
              Click the &quot;+&quot; button to select skills
            </a>

            <span className="ml-auto">
              <AddMoreSkillsButton className="px-2 py-1" handleModalOpen={handleModalOpen} />
            </span>
          </div>
        )}

        {input.value && input.value.length > 0 && (
          <AddMoreSkillsButton className="pt-1" handleModalOpen={handleModalOpen} />
        )}

        <SkillSelectModalPanel
          memberSkillsOnly={memberSkillsOnly}
          initiallySelected={input.value}
          handleSubmitSelected={onItemsSelected}
          heading={modalHeading}
        />
      </div>

      {error && <span className="help-block d-inline-block mt-3">{error}</span>}
      {submitError && <span className="help-block d-inline-block mt-3">{submitError}</span>}

      <input type="hidden" name={input.name} value={JSON.stringify(input.value)} />
    </div>
  );
};

SkillSelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: finalFormFieldMetaSpec,
  label: finalFormFieldLabelSpec,
  sublabel: finalFormFieldLabelSpec,
  className: PropTypes.string,
  required: PropTypes.bool,
  memberSkillsOnly: PropTypes.bool,
  modalHeading: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  organization: orgPublicSpec,
};

SkillSelectField.defaultProps = {
  meta: {
    error: '',
    submitError: '',
    pristine: true,
  },
  className: null,
  sublabel: null,
  label: '',
  memberSkillsOnly: false,
  modalHeading: null,
  required: false,
  organization: {},
};

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = (state, props) => ({
  organization: props.organization || selectActiveOrg(state),
});

const SkillSelectFieldConnected = connect(mapStateToProps, mapDispatchToProps)(SkillSelectField);
export default withField(SkillSelectFieldConnected);
