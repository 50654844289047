import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import DetailsRows from 'core/assets/js/components/DetailsRows.jsx';
import MaskedValue from 'core/assets/js/components/MaskedValue.jsx';
import ParseBool from 'core/assets/js/components/ParseBool.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { BS_STYLE, USER_TYPE } from 'core/assets/js/constants';
import { maskTaskIdentificationNumber } from 'finance/assets/js/lib/utils';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { orgUserProfileUrl } from 'people/urls';
import { PERMISSIONS } from 'roles/assets/js/constants';
import { getSalesTaxDetailsRows } from 'settings/assets/js/components/SalesTaxFields.jsx';

const PeopleTaxInformationTab = ({ company }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const { userId, userType } = useParams();
  const userTypeId = userType === 'providers' ? USER_TYPE.PROVIDER : USER_TYPE.MANAGER;

  if (!hasPermission(PERMISSIONS.CAN_VIEW_PROVIDER_TAX_INFORMATION)) {
    return (
      <RedirectRoute status={302} to={orgUserProfileUrl(activeOrg.alias, userTypeId, userId)} />
    );
  }

  if (!company) {
    return (
      <TDSystemMessage title="Not completed" type={BS_STYLE.WARNING}>
        This user has not completed their tax information
      </TDSystemMessage>
    );
  }

  const detailsRows = [{
    label: 'Is a US taxpayer', value: <ParseBool>{company.isUSTaxpayer}</ParseBool>,
  }];

  if (company.is_incorporated) {
    if (company.logo) {
      detailsRows.push({
        label: 'Logo',
        value: <img className="company-logo" src={company.logo} alt={company.name} />,
      });
    }
    detailsRows.push(
      { label: 'Company name', value: company.name },
      { label: 'Registration number', value: company.registration_number },
      { label: 'Address', value: company.address?.description || 'Not entered' },
    );
    if (company.isUSTaxpayer) {
      detailsRows.push({
        label: 'Tax identification number',
        value: (
          !company.tax_identification_number ? '' : (
            <MaskedValue
              maskedValue={maskTaskIdentificationNumber(company.tax_identification_number)}
              value={company.tax_identification_number}
            />
          )
        ),
      });
    }
  } else {
    detailsRows.push({ label: 'Full legal name', value: company.full_legal_name });
    if (company.isUSTaxpayer) {
      detailsRows.push({
        label: 'Social security number',
        value: (
          !company.social_security_number ? '' : (
            <MaskedValue
              maskedValue={maskTaskIdentificationNumber(company.social_security_number)}
              value={company.social_security_number}
            />
          )
        ),
      });
    }
  }
  detailsRows.push(...getSalesTaxDetailsRows(company));

  return (
    <div className="tab-content w-100 p-4 provider-tax-information">
      <div className="mb-3 mt-n2">
        <div className="provider-tax-information-meta">
          <DetailsRows prefix="provider-tax-information-meta" rows={detailsRows} />
        </div>
      </div>
    </div>
  );
};

PeopleTaxInformationTab.propTypes = {
  company: PropTypes.object,
};

PeopleTaxInformationTab.defaultProps = {
  company: {},
};

export default PeopleTaxInformationTab;
