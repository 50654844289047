import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Dropdown, ButtonGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON, IMG_SIZE } from 'core/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  selectActiveOrg,
  selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import OnboardingTalentStatus from 'people/assets/js/components/OnboardingTalentStatus.jsx';
import { toggleFavoriteDS } from 'people/assets/js/data-services/list';
import { pphSellerSpec } from 'people/assets/js/lib/objectSpecs';
import { orgPeopleDiscoverDetailUrl } from 'people/urls';
import { PPH_STATUS } from 'search/assets/js/constants';
import { getPPHStatus } from 'search/assets/js/lib/pph';

const TalentCard = ({
  activeOrg,
  activeUserCard,
  dispatch,
  parentComponentName,
  item,
  isChatCard,
  history,
}) => {
  const {
    budget,
    chat,
    invitations,
    numProjects,
    numReviews,
    profile,
    rating,
    userCard,
  } = item;
  const hasCompleteHourlyRate = userCard.currencySymbol && userCard.rate;
  const hasCompleteBudget = userCard.currencySymbol && budget;
  const { skills } = profile;
  const status = getPPHStatus(invitations);
  const orgAlias = activeOrg.unique_alias;
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleFavorite = async () => {
    setIsLoading(true);
    await dispatch(
      toggleFavoriteDS({
        orgAlias,
        values: { talentId: item.id },
        item,
        parentComponentName,
      }),
    );
    setIsLoading(false);
  };

  const getLastMessage = () => {
    if (activeUserCard.email === chat.lastSentUserEmail) {
      return `You: ${chat.lastMessage}`;
    }
    const user = chat.chatRoomUsers.find(
      (u) => u.user_email === chat.lastSentUserEmail,
    );
    return `${user.name}: ${chat.lastMessage}`;
  };

  return (
    <div
      className="col-xl-3 col-md-4 col-sm-6 col-xs-12 pl-0 pt-0 pb-3
      freelancer-item__container"
      key={item.id.toString()}
    >
      <div className="freelancer-item__fav pr-5">
        {isChatCard ? (
          <Dropdown as={ButtonGroup} className="td-dropdown">
            <Dropdown.Toggle variant="default" size="sm" className="border-0">
              <span className={ICON.CHEVRON_DOWN} />
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight>
              <Dropdown.Item onClick={() => {
                history.push({
                  pathname: orgPeopleDiscoverDetailUrl(orgAlias, item.id),
                });
              }}
              >
                View Profile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  history.push({
                    pathname: orgPeopleDiscoverDetailUrl(orgAlias, item.id),
                    state: { openedChat: true },
                  });
                }}
              >
                Message Now
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <TDButton
            className="p-3 freelancer-item__fav-icon"
            disabled={isLoading}
            label={(
              <span
                className={item.isFavorite ? ICON.HEART_FILLED : ICON.HEART}
              />
            )}
            onClick={handleToggleFavorite}
            variant={BS_STYLE.LINK}
          />
        )}
      </div>

      <Card
        className={`user-item freelancer-item col-12 cursor-pointer${
          isChatCard ? ' freelancer-item__extended' : ''
        }`}
      >
        <Card.Body
          onClick={() =>
            history.push(orgPeopleDiscoverDetailUrl(orgAlias, item.id))
          }
        >
          <div className="row d-flex align-items-center position-relative">
            <div className="col-12 d-flex justify-content-center my-3">
              <ProfilePic
                alt={`${profile.firstName}'s avatar`}
                size={[IMG_SIZE.LARGE, IMG_SIZE.LARGE]}
                url={profile.avatar}
              />
            </div>
            <div className="freelancer-item__title">{profile.fullName}</div>
            <div className="freelancer-item__basic-info d-flex">
              <div className="freelancer-item__extra discreet col-auto px-0 mb-2 text-center">
                {profile.jobTitle && (
                  <span className="text-center" title={profile.jobTitle}>
                    {profile.jobTitle}
                  </span>
                )}
              </div>
            </div>
          </div>

          {isChatCard
            ? chat?.unreadCount > 0 && (
              <OnboardingTalentStatus
                customLabel={`${chat?.unreadCount} Message${chat?.unreadCount > 1 ? 's' : ''}`}
                status={PPH_STATUS.INVITED}
              />
            )
            : status !== PPH_STATUS.NOT_INVITED && (
              <OnboardingTalentStatus status={status} />
            )}
          <p className="d-flex justify-content-center align-items-center text-center mt-5 freelancer-item__muted">
            <CountryFlagIcon
              countryISOCode={item?.profile?.address?.countryCode}
            />
            <span className="ml-3">{item?.profile?.address?.label}</span>
          </p>
        </Card.Body>

        <Card.Footer className="row freelancer-item__footer">
          <div
            onClick={() =>
              history.push(orgPeopleDiscoverDetailUrl(orgAlias, item.id))
            }
          >
            <div className="freelancer-item__details">
              {rating && (
                <RatingScore
                  classNames="freelancer-item__rating"
                  numReviews={numReviews}
                  rating={rating}
                />
              )}
            </div>
            <div className="col-12 my-3 pl-0">
              {skills && (
                <SkillList
                  inline
                  maxShownItems={2}
                  modalId={`user-skills-${profile.userId}`}
                  skills={skills}
                />
              )}
            </div>
            <div className="row mb-2 mx-0">
              <div className="col-5 pr-0 pl-0">
                <span className="freelancer-item__muted freelancer-item__small">
                  {`${numProjects} projects`}
                </span>
              </div>
              <div className="col-7 text-right pl-0 pr-0">
                {!isNil(hasCompleteBudget) && budget > 0 && (
                  <span className="freelancer-item__budget">
                    <i key="coins-icon" className={ICON.COINS} />
                    <NumberFormat
                      displayType="text"
                      key="budget"
                      prefix={userCard.currencySymbol}
                      thousandSeparator
                      value={budget}
                    />
                  </span>
                )}

                {hasCompleteHourlyRate && (
                  <StatusTag
                    hideTitle
                    hideDot
                    label={(
                      <span>
                        {`${userCard.currencySymbol}${userCard.rate} /hr`}
                      </span>
                    )}
                    statusClass="light"
                  />
                )}
              </div>
            </div>
          </div>
          {isChatCard && chat?.lastMessage && (
            <div
              className="freelancer-item__reply py-3"
              onClick={() => {
                history.push({
                  pathname: orgPeopleDiscoverDetailUrl(orgAlias, item.id),
                  state: { openedChat: true },
                });
              }}
            >
              <TDButton
                className="py-3 px-0 freelancer-item__reply-icon"
                label={(
                  <>
                    <span className={ICON.COMMENT} />
                    {' '}
                    <span>Reply</span>
                  </>
                )}
                variant={BS_STYLE.LINK}
              />
              <p className="freelancer-item__reply-message">
                {getLastMessage()}
              </p>
            </div>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
};
TalentCard.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  item: pphSellerSpec.isRequired,
  isChatCard: PropTypes.bool,
};

TalentCard.defaultProps = {
  isChatCard: false,
};

const mapStateToProps = (state) => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const TalentCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TalentCard);

export default withRouter(TalentCardConnected);
