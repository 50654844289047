/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { find } from 'lodash';

import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { isSSR } from 'core/assets/js/config/checks';
import { REACT_SELECT_STYLES } from 'core/assets/js/constants';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

/**
 * Multiselect component
 *
 * Searches members of an organization based on user role type or a keyword that is matched
 * against profiles.(first_name|last_name|job_title)
 */
class SimpleSelect extends React.Component {
  constructor(props) {
    super(props);

    const { options, defaultValue } = props;
    this.onChange = this.onChange.bind(this);

    const selectedItem = options && find(options, item => item.id === defaultValue);
    this.state = {
      value: selectedItem,
    };
  }

  /**
   * Fire when the skill list is updated
   *
   * @param value Array of skill objects, each entry contains an id and label
   */
  onChange(value = '') {
    const { input } = this.props;
    this.setState({
      value,
    }, () => {
      // If multi select mode is not enabled, expect a single value
      const items = !Array.isArray(value)
        ? [(value || '')]
        : value;
      const serializedValues = items.map(item => item.id);
      // Notify redux form field about the change to update the value of the hidden field
      input.onChange(serializedValues.join(','));
    });
  }

  render() {
    const { input, placeholder, disabled, searchable, options, multiselect } = this.props;
    const { value: currentValue } = this.state;

    return (
      <div>
        <input {...input} type="hidden" />
        <Select
          isDisabled={disabled}
          name="simple-multiselect"
          className="simple-multiselect"
          styles={REACT_SELECT_STYLES}
          value={currentValue}
          onChange={this.onChange}
          valueKey="id"
          labelKey="label"
          menuPlacement="auto"
          menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
          isMulti={multiselect}
          joinValues
          isSearchable={searchable}
          cache={false}
          options={options}
          placeholder={placeholder}
          noResultsText="No results found."
          getOptionValue={opt => opt.id}
          getValue={opt => opt.id}
          getOptionLabel={opt => opt.title}
        />
      </div>
    );
  }
}
SimpleSelect.propTypes = {
  input: PropTypes.object.isRequired,
  projectId: PropTypes.number,
  taskId: PropTypes.number,
  multiselect: PropTypes.bool,
  payload: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  options: PropTypes.array,
  activeOrg: orgSpec,
  defaultValue: PropTypes.any,
};
SimpleSelect.defaultProps = {
  projectId: null,
  taskId: null,
  payload: {},
  multiselect: null,
  activeOrg: null,
  defaultValue: null,
  disabled: false,
  searchable: false,
  placeholder: 'Select an option...',
  options: [],
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

export default connect(mapStateToProps)(SimpleSelect);
