import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { projectCreateUrl } from 'projects/urls';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { getActiveUserCardPermissionChecker } from 'organizations/assets/js/reducers/organizations';
import ProjectList from 'projects/assets/js/components/ProjectList.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { PERMISSIONS } from 'roles/assets/js/constants';

class ProjectListView extends React.PureComponent {
  static GetComponentName() {
    return 'ProjectListView';
  }

  render() {
    const {
      match, history, hasPermission, onFiltersToggle, filtersOpen,
    } = this.props;

    const canCreateProjects = hasPermission(PERMISSIONS.CAN_CREATE_PROJECTS);
    const breadcrumbs = [
      {
        title: 'Projects',
        url: null,
      },
    ];

    let ctaButtonItems = null;

    if (canCreateProjects) {
      ctaButtonItems = [{
        label: 'Create new project',
        onClick: () => history.push(projectCreateUrl(match.params.orgAlias)),
        variant: BS_STYLE.PRIMARY,
      }];
    }

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />
        <div className="page page--projects">
          <div className="container">
            <ProjectList filtersOpen={filtersOpen} onFiltersToggle={onFiltersToggle} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectListView.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  hasPermission: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  hasPermission: getActiveUserCardPermissionChecker(state),
  history: props.history,
  match: props.match,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectListViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFilters(ProjectListView));
export default withRouter(ProjectListViewConnect);
