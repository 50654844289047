import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import UserUSTaxFilingsList from 'finance/assets/js/components/UserUSTaxFilingsList.jsx';
import UserUSTaxFormsList from 'finance/assets/js/components/UserUSTaxFormsList.jsx';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';

const ProfileExtraTaxWidgets = ({ parentComponentName, userCard }) => {
  const { orgAlias, userId, userType } = useParams();
  const dispatch = useDispatch();
  return (
    <>
      {Array.isArray(userCard?.user?.usTaxForms) && userCard.user.usTaxForms.length > 0 && (
        <Card className="mt-5">
          <Card.Header>Tax information documents</Card.Header>
          <Card.Body className="p-0">
            <UserUSTaxFormsList usTaxForms={userCard.user.usTaxForms} />
          </Card.Body>
        </Card>
      )}
      <UserUSTaxFilingsList
        className="mt-5"
        reload={() => {
          const ds = userType === 'providers' ? fetchProviderDS : fetchManagerDS;
          dispatch(ds({ componentName: parentComponentName, params: { orgAlias, userId } }));
        }}
        usTaxFilings={userCard?.usTaxFilings}
      />
    </>
  );
};

ProfileExtraTaxWidgets.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
  userCard: PropTypes.object.isRequired,
};

export default ProfileExtraTaxWidgets;
