import React from 'react';
import { Card } from 'react-bootstrap';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ProjectWorksheetImportPreviewSkeleton = () => (
  <div className="contacts-skeleton--preview">
    <Card className="mb-4">
      <Card.Body>
        <RectangleSkeleton width="100" height="15" className="mb-4" />
        <br />
        <RectangleSkeleton width="300" height="10" />
      </Card.Body>
    </Card>
    <table className="table table table-bordered">
      <thead>
        <tr>
          <th><RectangleSkeleton width="100" height="10" /></th>
          <th><RectangleSkeleton width="100" height="10" /></th>
          <th><RectangleSkeleton width="100" height="10" /></th>
          <th><RectangleSkeleton width="100" height="10" /></th>
          <th><RectangleSkeleton width="100" height="10" /></th>
          <th><RectangleSkeleton width="100" height="10" /></th>
          <th><RectangleSkeleton width="100" height="10" /></th>
          <th><RectangleSkeleton width="100" height="10" /></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><RectangleSkeleton width="50" height="10" /></td>
          <td><RectangleSkeleton width="50" height="10" /></td>
          <td><RectangleSkeleton width="70" height="10" /></td>
          <td><RectangleSkeleton width="70" height="10" /></td>
          <td><RectangleSkeleton width="50" height="10" /></td>
          <td><RectangleSkeleton width="50" height="10" /></td>
          <td><RectangleSkeleton width="80" height="10" /></td>
          <td><RectangleSkeleton width="80" height="10" /></td>
        </tr>
        <tr>
          <td><RectangleSkeleton width="60" height="10" /></td>
          <td><RectangleSkeleton width="60" height="10" /></td>
          <td><RectangleSkeleton width="40" height="10" /></td>
          <td><RectangleSkeleton width="40" height="10" /></td>
          <td><RectangleSkeleton width="80" height="10" /></td>
          <td><RectangleSkeleton width="80" height="10" /></td>
          <td><RectangleSkeleton width="60" height="10" /></td>
          <td><RectangleSkeleton width="60" height="10" /></td>
        </tr>
        <tr>
          <td><RectangleSkeleton width="50" height="10" /></td>
          <td><RectangleSkeleton width="50" height="10" /></td>
          <td><RectangleSkeleton width="65" height="10" /></td>
          <td><RectangleSkeleton width="40" height="10" /></td>
          <td><RectangleSkeleton width="80" height="10" /></td>
          <td><RectangleSkeleton width="65" height="10" /></td>
          <td><RectangleSkeleton width="40" height="10" /></td>
          <td><RectangleSkeleton width="80" height="10" /></td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default ProjectWorksheetImportPreviewSkeleton;
