import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import {
  deleteInterviewDS,
} from 'interviews/assets/js/data-services/interviews';

const DELETE_MODAL_ID = 'delete-interview';

class RemoveInterviewModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.handleDeleteInterview = this.handleDeleteInterview.bind(this);
  }

  componentWillUnmount() {
    this.handleCloseDeleteModal();
  }

  /**
   * Close modal
   */
  handleCloseDeleteModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  /**
   * Hndle the confirmation request of the delete Onboarding form modal
   */
  async handleDeleteInterview() {
    try {
      const { dispatch, match, deleteModalPayload, onDelete } = this.props;
      const { params } = match;
      await deleteInterviewDS(params, deleteModalPayload.id, dispatch);
      toastr.success('Well Done!', 'Onboarding form was deleted!');
      onDelete();
    } catch (err) {
      toastr.error(('Oh Snap!', err.message || 'Onboarding form was not deleted'));
    } finally {
      this.handleCloseDeleteModal();
    }
  }

  render() {
    const {
      isDeleteModalOpen, deleteModalPayload,
    } = this.props;
    return (
      <ModalConfirm
        onClose={this.handleCloseDeleteModal}
        onConfirm={this.handleDeleteInterview}
        body={(
          <React.Fragment>
            {deleteModalPayload.pendingInvitationCount !== 0 && (
              <React.Fragment>
                <p>
                  This onboarding form has been used in
                  {' '}
                  <strong>
                    {deleteModalPayload.pendingInvitationCount}
                  </strong>
                  {' '}
                  pending invitations.
                  By deleting it, all pending invitations will get cancelled.
                </p>
              </React.Fragment>
            )}
            Are you sure you want to delete the onboarding form
            <strong>
              {` "${deleteModalPayload.name}"`}
            </strong>
            ?
          </React.Fragment>
        )}
        confirmLabel="Delete"
        confirmStyle="danger"
        heading={`Delete Onboarding form "${deleteModalPayload.name}"`}
        open={isDeleteModalOpen}
      />
    );
  }
}

RemoveInterviewModal.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteModalPayload: PropTypes.object,
  isDeleteModalOpen: PropTypes.bool,
};

RemoveInterviewModal.defaultProps = {
  isDeleteModalOpen: false,
  deleteModalPayload: null,
};

const mapStateToProps = state => ({
  deleteModalPayload: getModalPayload(state, DELETE_MODAL_ID),
  isDeleteModalOpen: getIsModalOpen(state, DELETE_MODAL_ID),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const RemoveInterviewModalConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveInterviewModal);

export default withRouter(RemoveInterviewModalConnect);
