import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';

import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import SalesTaxItem from 'settings/assets/js/components/SalesTaxItem.jsx';
import SalesTaxWarning from 'settings/assets/js/components/SalesTaxWarning.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { COMPANY_TAX_SYSTEM, COMPANY_TAX_SYSTEM_INFO, SALES_TAX_SCENARIOS, SETTINGS_PAYMENTS_TABS, SALES_TAX_ACTIVITY_DESCRIPTION } from 'settings/assets/js/constants';
import { completeStepDS, fetchSetupStepsDS, selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { fetchUserCardWithVatDS, postUserCardWithVatDS } from 'settings/assets/js/data-services/organizations';
import { getSalesTaxScenario } from 'settings/assets/js/lib/utils';
import { getViewState } from 'core/assets/js/ducks/view';
import { orgGetStartedUrl } from 'accounts/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { profileSpec } from 'accounts/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { settingsPaymentsSubPageUrl } from 'settings/urls';

class OrgSettingsPaymentsVatSalesInfo extends React.Component {
  static FetchData({ dispatch, params, url = '', authedAxios, componentName }) {
    return dispatch(fetchUserCardWithVatDS(params, url, authedAxios, componentName));
  }

  static GetComponentName() {
    return 'OrgSettingsPaymentsVatSalesInfo';
  }

  render() {
    const {
      activeOrg, activeProfile, company, dispatch, onboardingSteps, sysOrg, userCard, history,
    } = this.props;
    const showSysMsg = !company || (company && !company.has_tax_system);
    const orgCustomVatMsg = userCard?.organization?.custom_sales_tax_msg;
    const companyTaxSystemInfo = company
      ? COMPANY_TAX_SYSTEM_INFO[company.tax_system_type]
      : COMPANY_TAX_SYSTEM_INFO[COMPANY_TAX_SYSTEM.NO_SYSTEM];
    const componentName = this.constructor.GetComponentName();

    const salesTaxScenario = getSalesTaxScenario(activeOrg, company, activeProfile);

    let vatPercentOverride = null;
    if (
      [
        SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_NOT_UK,
        SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_UK__NOT_TAX_REGISTERED,
        SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__NOT_TAX_REGISTERED,
        SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_NOT_UK__CONTRACTOR_UK,
      ].includes(salesTaxScenario)
    ) {
      vatPercentOverride = 0;
    } else if (
      [
        SALES_TAX_SCENARIOS.VIA_MODE__CONTRACTOR_UK__TAX_REGISTERED,
        SALES_TAX_SCENARIOS.NOT_VIA_MODE__ORG_UK__CONTRACTOR_UK__TAX_REGISTERED,
      ].includes(salesTaxScenario)
    ) {
      vatPercentOverride = 20;
    }

    return (
      <TDApiConnected duck="view" component={this.constructor} blockingLoading skeletonComponent={SettingsPageSkeleton}>
        <div className="row">
          <div className="col-12">
            <div className="rounded shadow-sm p-4 bg-white">
              <div className="vat-settings-item__info">
                <SalesTaxWarning
                  invoicingVatPercent={userCard.invoicing_vat_percent}
                  salesTaxScenario={salesTaxScenario}
                />
                {showSysMsg && (
                  <TDSystemMessage className="mb-5" title="Tell us more about your activity">
                    <p>
                      {SALES_TAX_ACTIVITY_DESCRIPTION}
                      {' - '}
                      please make sure you update your company records at TalentDesk.io by clicking
                      {' '}
                      <Link
                        to={settingsPaymentsSubPageUrl(
                          activeOrg.alias, SETTINGS_PAYMENTS_TABS.INVOICE_DETAILS,
                        )}
                      >
                        here
                      </Link>
                      .
                    </p>
                  </TDSystemMessage>
                )}
                {!!orgCustomVatMsg && (
                  <TDSystemMessage className="mb-5" title="VAT/Sales tax guidelines">
                    <MarkdownText text={orgCustomVatMsg} />
                  </TDSystemMessage>
                )}
                <SalesTaxItem
                  activeOrg={activeOrg}
                  companyTaxSystemInfo={companyTaxSystemInfo}
                  userCard={userCard}
                  sysOrg={sysOrg}
                  onVatSettingsUpdated={async values => {
                    try {
                      await dispatch(postUserCardWithVatDS(
                        activeOrg.alias,
                        { invoicing_vat_percent: parseFloat(values.invoicing_vat_percent) },
                        componentName,
                      ));
                      const salesTaxStep = (
                        onboardingSteps?.userCardSetupSteps?.[activeOrg.alias] || []
                      ).find(s => s.name === 'SalesTaxStep');
                      if (salesTaxStep && !salesTaxStep.completed) {
                        await completeStepDS(dispatch, activeOrg.alias, 'SalesTaxStep');
                        history.push({
                          pathname: orgGetStartedUrl(activeOrg.alias),
                          state: { moveToNextStep: true },
                        });
                        return;
                      }
                      toastr.success('Well Done!', 'Your sales tax has been updated successfully.');
                      await dispatch(fetchSetupStepsDS());
                    } catch (e) {
                      const error = e.errors?.invoicing_vat_percent || e.message;
                      toastr.error('Oh Snap!', error);
                      // this is required to stop ModalConfirm calling onConfirmSuccess
                      throw new Error(error);
                    }
                  }}
                  vatPercentOverride={vatPercentOverride}
                />
              </div>
            </div>
          </div>
        </div>
      </TDApiConnected>
    );
  }
}

OrgSettingsPaymentsVatSalesInfo.propTypes = {
  activeOrg: orgSpec,
  activeProfile: profileSpec.isRequired,
  company: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onboardingSteps: PropTypes.object.isRequired,
  sysOrg: orgSpec,
  userCard: PropTypes.object.isRequired,
};

OrgSettingsPaymentsVatSalesInfo.defaultProps = {
  activeOrg: {},
  company: null,
  sysOrg: null,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, OrgSettingsPaymentsVatSalesInfo.GetComponentName());
  const { company, sysOrg } = viewState.extras;
  return {
    activeOrg: selectActiveOrg(state),
    activeProfile: selectProfile(state),
    company,
    onboardingSteps: selectSetupSteps(state),
    organization: viewState.item,
    sysOrg,
    userCard: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OrgSettingsPaymentsVatSalesInfo));
