import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TimeAgo from 'timeago-react';

import { isExternalUrl, makeRelative } from 'core/assets/js/lib/utils';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { notificationSpec } from 'notifier/assets/js/lib/objectSpecs';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';


const NotificationCard = ({
  item, onMarkAsRead, history,
}) => {
  const { id, body, created_at: createdAt, is_read: isRead, org, targetUrl, icon } = item;

  return (
    <li className={`notifications-list-item ${isRead ? 'seen' : ''}`}>
      <span
        className="d-block"
        onClick={async () => {
          if (!isRead) {
            await onMarkAsRead([id]);
          }
          if (!targetUrl) {
            return;
          }
          const isExternal = isExternalUrl(targetUrl);
          if (isExternal) {
            WINDOW_OPEN(targetUrl, '_blank');
          } else {
            history.push(makeRelative(targetUrl));
          }
        }}
      >
        <span className={`notification-icon ${icon}`} />
        <div dangerouslySetInnerHTML={{ __html: body }} />
        <div className="notifications-list-item__meta">
          {createdAt && (
            <TimeAgo datetime={createdAt} live={false} />
          )}
          {' '}
          {org && (
            <React.Fragment>
              by
              {' '}
              <strong>{ org.name }</strong>
              .
            </React.Fragment>
          )}
        </div>
      </span>
    </li>
  );
};

NotificationCard.propTypes = {
  history: routerHistorySpec.isRequired,
  item: notificationSpec.isRequired,
  targetUrl: PropTypes.string,
  onMarkAsRead: PropTypes.func,
};

NotificationCard.defaultProps = {
  onMarkAsRead: () => {},
  targetUrl: null,
};

export default withRouter(NotificationCard);
