import React from 'react';
import PropTypes from 'prop-types';

const StatusDot = (props) => {
  /**
   * @param props
   * @param {bool} props.noFill By setting it to true, it will create a dot with no filling
   */
  const { statusClass, noFill, classes } = props;

  const classNames = [
    'status-dot',
    `status-dot--${statusClass}`,
  ];
  if (noFill) {
    classNames.push('status-dot--no-fill');
  }
  if (classes) {
    classNames.push(classes);
  }
  return (
    <span className={classNames.join(' ')} />
  );
};

StatusDot.propTypes = {
  statusClass: PropTypes.string,
  classes: PropTypes.string,
  noFill: PropTypes.bool,
};

StatusDot.defaultProps = {
  statusClass: '',
  classes: '',
  noFill: false,
};

export default StatusDot;
