import React from 'react';
import PropTypes from 'prop-types';

import ProjectWorksheetsDistributionWidget from 'projects/assets/js/components/widgets/ProjectWorksheetsDistributionWidget.jsx';
import ProjectExpensesDistributionWidget from 'projects/assets/js/components/widgets/ProjectExpensesDistributionWidget.jsx';

const ProjectDistributionWidgets = ({
  currentMonthAnalytics, threeMonthsAnalytics, sixMonthsAnalytics, oneYearAnalytics,
  fromStartAnalytics, project,
}) => (
  <div className="row">
    <div className="col-12 col-md-6 mb-4 pr-md-3">
      <ProjectWorksheetsDistributionWidget
        currentMonthAnalytics={currentMonthAnalytics}
        threeMonthsAnalytics={threeMonthsAnalytics}
        sixMonthsAnalytics={sixMonthsAnalytics}
        oneYearAnalytics={oneYearAnalytics}
        fromStartAnalytics={fromStartAnalytics}
        project={project}
      />
    </div>

    <div className="col-12 col-md-6 mt-2 pl-md-3">
      <ProjectExpensesDistributionWidget
        currentMonthAnalytics={currentMonthAnalytics}
        threeMonthsAnalytics={threeMonthsAnalytics}
        sixMonthsAnalytics={sixMonthsAnalytics}
        oneYearAnalytics={oneYearAnalytics}
        fromStartAnalytics={fromStartAnalytics}
        project={project}
      />
    </div>
  </div>
);

ProjectDistributionWidgets.propTypes = {
  currentMonthAnalytics: PropTypes.object.isRequired,
  threeMonthsAnalytics: PropTypes.object.isRequired,
  sixMonthsAnalytics: PropTypes.object.isRequired,
  oneYearAnalytics: PropTypes.object.isRequired,
  fromStartAnalytics: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default ProjectDistributionWidgets;
