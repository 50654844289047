import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stringify, parse } from 'query-string';

import { DATE_DURATION, DATE_DURATION_API_KEY } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { linePurchaseOrderAnalyticsApiUrl } from 'analytics/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ChartLine from 'projects/assets/js/components/charts/ChartLine.jsx';
import ProjectChartBasicLineSkeleton from 'projects/assets/js/components/skeletons/ProjectChartBasicLineSkeleton.jsx';

class ProjectPurchaseOrdersChartLine extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, selectedDateDuration, querystring }) {
    const storeKey = ProjectPurchaseOrdersChartLine.GetStoreKey(selectedDateDuration);
    return dispatch(fetchListDS({
      url: linePurchaseOrderAnalyticsApiUrl(
        params.orgAlias,
        params.id,
        url,
      ),
      id: params.id,
      componentName: storeKey,
      authedAxios,
      querystring: stringify({
        ...parse(querystring),
        dateDuration: selectedDateDuration,
      }),
    }));
  }

  static GetStoreKey(selectedDateDuration) {
    const dateDurationKey = DATE_DURATION_API_KEY[selectedDateDuration];
    return `linePoAnalytics_${dateDurationKey}`;
  }

  render() {
    const { analytics, currencySymbol, selectedDateDuration } = this.props;
    const analyticsFormatted = analytics.map(entry => [entry.recordedAt, entry.amount]);

    return (
      <TDApiConnected
        duck="list"
        storeKey={ProjectPurchaseOrdersChartLine.GetStoreKey(selectedDateDuration)}
        fetchData={({ ...args }) => (
          ProjectPurchaseOrdersChartLine.FetchData({ ...args, selectedDateDuration },
          ))}
        blockingLoading
        skeletonComponent={ProjectChartBasicLineSkeleton}
      >
        <ChartLine
          analytics={analyticsFormatted}
          currencySymbol={currencySymbol}
          chartName="Budget requests"
          emptyText="No approved budget requests found"
          selectedDateDuration={selectedDateDuration}
        />
      </TDApiConnected>
    );
  }
}

ProjectPurchaseOrdersChartLine.propTypes = {
  analytics: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  selectedDateDuration: PropTypes.number,
};

ProjectPurchaseOrdersChartLine.defaultProps = {
  selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
};

const mapStateToProps = (state, props) => {
  const { selectedDateDuration } = props;
  const listState = getListState(
    state, ProjectPurchaseOrdersChartLine.GetStoreKey(selectedDateDuration),
  );
  return {
    analytics: listState.items,
  };
};

const ProjectPurchaseOrdersChartLineConnected = connect(
  mapStateToProps,
  null,
)(ProjectPurchaseOrdersChartLine);

export default ProjectPurchaseOrdersChartLineConnected;
