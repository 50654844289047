import React from 'react';
import { WINDOW_SCROLL_TO } from 'core/assets/js/config/settings';

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    WINDOW_SCROLL_TO(0, 0);
  }

  render() {
    return null;
  }
}

export default ScrollToTopOnMount;
