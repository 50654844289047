/* eslint-env browser */
import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/async-creatable';
import axios from 'core/assets/js/lib/tdAxios';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import { isSSR } from 'core/assets/js/config/checks';
import { finalFormFieldMetaSpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { REACT_SELECT_STYLES } from 'core/assets/js/constants';
import { skillSearchApiUrl } from 'skills/urls';
import withField from 'core/assets/js/components/withField.jsx';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

/**
 * Creatable Select skill component
 *
 * Loads skills async via an API endpoint
 */
const SkillCreatableSelectField = ({
  defaultSkillsEnabled,
  input,
  input: { name, value },
  isOptionDisabled,
  label,
  memberSkillsOnly,
  meta: { error, submitError },
  required,
  sublabel,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const loadOptions = async term => {
    try {
      const orgAlias = activeOrg ? activeOrg.unique_alias || activeOrg.alias : null;
      const { data } = await axios.get(skillSearchApiUrl({
        term, memberSkillsOnly, orgAlias, defaultSkillsEnabled,
      }));
      return data.map(skill => ({ label: skill.label, value: skill.id }));
    } catch (err) {
      toastr.error('Oh Snap!', err.data?._error || err.message);
      return [];
    }
  };

  const loadOptionsDebounced = useCallback(
    debounce((term, callback) => {
      loadOptions(term).then(options => callback(options));
    }, 500),
    [],
  );

  const classNames = ['form-group'];

  const hasError = submitError || error;
  if (hasError) {
    classNames.push('has-error');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="d-flex justify-content-between">
        {label && (
          <div>
            <TDLabel
              label={label}
              name={name}
              required={required}
              sublabel={sublabel}
            />
          </div>
        )}
      </div>

      <AsyncCreatableSelect
        cacheOptions={false}
        classNamePrefix="react-select"
        createOptionPosition="first"
        formatCreateLabel={inputValue => (
          <span>
            Add
            {' '}
            <strong>
              &rdquo;
              {inputValue}
              &rdquo;
            </strong>
          </span>
        )}
        getOptionValue={opt => opt.id}
        instanceId="skills"
        isOptionDisabled={isOptionDisabled}
        loadOptions={loadOptionsDebounced}
        menuPlacement="auto"
        menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
        name="skills-multiselect"
        noOptionsMessage={(data) => {
          if (data.inputValue === '') {
            return 'Start typing...';
          }
          return 'No result found';
        }}
        onChange={val => input.onChange(val)}
        styles={REACT_SELECT_STYLES}
        value={value}
      />
      {hasError && <span className="help-block d-inline-block mt-3">{error || submitError}</span>}
    </div>
  );
};

SkillCreatableSelectField.propTypes = {
  defaultSkillsEnabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  isOptionDisabled: PropTypes.func,
  label: PropTypes.string,
  memberSkillsOnly: PropTypes.bool,
  meta: finalFormFieldMetaSpec,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
};

SkillCreatableSelectField.defaultProps = {
  defaultSkillsEnabled: false,
  label: '',
  memberSkillsOnly: true,
  meta: {
    error: '',
    submitError: '',
  },
  isOptionDisabled: () => false,
  required: false,
  sublabel: '',
};

export default withField(SkillCreatableSelectField);
