import { CURRENCY_SYMBOL, SYSTEM } from 'core/assets/js/constants';
import { isEmpty, isNaN, isNil, keyBy, mapValues, omit } from 'lodash';

import LicenceFeeAnalysis from 'finance/assets/js/lib/LicenceFeeAnalysis';
import SeatFeeAnalysis from 'finance/assets/js/lib/fees/SeatFeeAnalysis';
import AORFeeScheme from 'finance/assets/js/lib/fees/AORFeeScheme';
import ApiFeeScheme from 'finance/assets/js/lib/fees/ApiFeeScheme';
import CodatFeeScheme from 'finance/assets/js/lib/fees/CodatFeeScheme';
import ESignFeeScheme from 'finance/assets/js/lib/fees/ESignFeeScheme';
import Ten99FeeScheme from 'finance/assets/js/lib/fees/Ten99FeeScheme';
import TINFeeScheme from 'finance/assets/js/lib/fees/TINFeeScheme';
import { removeNilValues } from 'core/assets/js/lib/utils';
import Money from 'finance/assets/js/lib/Money';
import { SERVICE_KEY_NAME } from 'finance/assets/js/constants';
import { assert } from 'finance/assets/js/lib/Assert';


const parseAmount = (value, currency, defaultValue = '0.00') => (
  (isNil(value) || isNaN(value)) ? defaultValue : new Money(value, currency).toString()
);

class LicenceFeeScheme {
  static getDefaultSystemReg() {
    return SYSTEM.DEFAULT_REGISTRATION_NUMBER;
  }

  static getAdditionalServicesList() {
    return [{
      name: 'AOR', id: SERVICE_KEY_NAME.AOR,
    }, {
      name: 'API', id: SERVICE_KEY_NAME.API,
    }, {
      name: 'Codat', id: SERVICE_KEY_NAME.CODAT,
    }, {
      name: 'E-sign', id: SERVICE_KEY_NAME.ESIGN,
    }, {
      name: '1099', id: SERVICE_KEY_NAME.TEN99,
    }, {
      name: 'TIN', id: SERVICE_KEY_NAME.TIN,
    }];
  }

  static fromFormValues(formValues) {
    const { additionalFormServices, currency } = formValues;
    const additionalServicesIds = this.getAdditionalServicesList().map(i => i.id);
    const mapped = mapValues(keyBy(additionalFormServices, 'service'), (v) => {
      switch (v.service) {
        case SERVICE_KEY_NAME.AOR:
          return AORFeeScheme.fromFormValues({ currency, ...v });
        case SERVICE_KEY_NAME.API:
          return ApiFeeScheme.fromFormValues({ currency, ...v });
        case SERVICE_KEY_NAME.CODAT:
          return CodatFeeScheme.fromFormValues({ currency, ...v });
        case SERVICE_KEY_NAME.ESIGN:
          return ESignFeeScheme.fromFormValues({ currency, ...v });
        case SERVICE_KEY_NAME.TEN99:
          return Ten99FeeScheme.fromFormValues({ currency, ...v });
        case SERVICE_KEY_NAME.TIN:
          return TINFeeScheme.fromFormValues({ currency, ...v });
        default:
          throw new Error('unknown service');
      }
    });
    return new LicenceFeeScheme({
      ...omit(formValues, [...additionalServicesIds, 'additionalFormServices']),
      ...mapped,
    });
  }

  constructor(serialized) {
    if (serialized instanceof LicenceFeeScheme) {
      return new LicenceFeeScheme(serialized.serialize());
    }
    this.init(serialized);
  }

  init({
    currency, baseLicenceFee,
    baseManagerSeats, baseProviderSeats, perManagerSeat, perProviderSeat,
    aor, codat, api, esign, ten99, tin, disableLicenceFeeValidation,
  }) {
    const systemReg = LicenceFeeScheme.getDefaultSystemReg();
    this.spec = {
      currency,
      baseLicenceFee: parseAmount(baseLicenceFee, currency),
      baseManagerSeats: baseManagerSeats || 0,
      baseProviderSeats: baseProviderSeats || 0,
      perManagerSeat: parseAmount(perManagerSeat, currency),
      perProviderSeat: parseAmount(perProviderSeat, currency),
      aorFeeScheme: new AORFeeScheme(aor),
      apiFeeScheme: new ApiFeeScheme(api),
      codatFeeScheme: new CodatFeeScheme(codat),
      eSignFeeScheme: new ESignFeeScheme(esign),
      ten99FeeScheme: new Ten99FeeScheme(ten99),
      tinFeeScheme: new TINFeeScheme(tin),
      systemReg,
      disableLicenceFeeValidation: disableLicenceFeeValidation || false,
    };
  }

  getSystemRegistrationNumber() {
    const { systemReg } = this.spec;
    return systemReg;
  }

  copy() {
    return new LicenceFeeScheme(this.serialize());
  }

  hasBaseLicence() {
    const { baseLicenceFee, currency } = this.spec;
    return !new Money(baseLicenceFee, currency).isZero();
  }

  hasPerProvider() {
    const { perProviderSeat, currency } = this.spec;
    return !new Money(perProviderSeat, currency).isZero();
  }

  hasPerManager() {
    const { perManagerSeat, currency } = this.spec;
    return !new Money(perManagerSeat, currency).isZero();
  }

  serialize() {
    const {
      aorFeeScheme, apiFeeScheme, codatFeeScheme, eSignFeeScheme,
      ten99FeeScheme, tinFeeScheme,
    } = this.spec;
    return {
      ...omit(this.spec, [
        'aorFeeScheme',
        'apiFeeScheme',
        'codatFeeScheme',
        'eSignFeeScheme',
        'tinFeeScheme',
        'ten99FeeScheme',
      ]),
      ...removeNilValues({
        aor: aorFeeScheme.serialize(),
        api: apiFeeScheme.serialize(),
        codat: codatFeeScheme.serialize(),
        esign: eSignFeeScheme.serialize(),
        ten99: ten99FeeScheme.serialize(),
        tin: tinFeeScheme.serialize(),
      }),
    };
  }

  getBaseLicenceFee() {
    return this.spec.baseLicenceFee;
  }

  setBaseManagerSeats(baseManagerSeats) {
    Object.assign(this.spec, { baseManagerSeats });
  }

  getBaseManagerSeats() {
    return this.spec.baseManagerSeats;
  }

  setBaseProviderSeats(baseProviderSeats) {
    Object.assign(this.spec, { baseProviderSeats });
  }

  getBaseProviderSeats() {
    return this.spec.baseProviderSeats;
  }

  getPerManagerSeat() {
    return this.spec.perManagerSeat;
  }

  getPerProviderSeat() {
    return this.spec.perProviderSeat;
  }

  toString() {
    return JSON.stringify(this.serialize(), null, 2);
  }

  setCurrency(currency) {
    this.spec.currency = currency;
  }

  getCurrency() {
    return this.spec.currency;
  }

  getCurrencySymbol() {
    return CURRENCY_SYMBOL[this.getCurrency()];
  }

  isEmpty() {
    const { spec } = this;
    return !spec || isEmpty(spec);
  }

  /**
   * Returns false if specs were set up with any kind of seats cost, true otherwise
   *
   * @returns {boolean} returns false if any of the following exists:
   * - seats fee,
   * - base fee
   * - fee per manager seat
   * - fee per provider seat
   */
  _hasZeroSeatsFee() {
    const { baseLicenceFee, perManagerSeat, perProviderSeat, currency } = this.spec;

    return new Money(baseLicenceFee, currency).isZero()
      && new Money(perManagerSeat, currency).isZero()
      && new Money(perProviderSeat, currency).isZero();
  }

  /**
   * Returns true if LicenceFeeScheme has any kind of costs applied
   * Seats, AOR, Codat, API, ESign, TIN, 1099
   *
   * @returns {Boolean}
   */
  isZero() {
    const services = Object.values(SERVICE_KEY_NAME);
    // TODO - replace with an 'array.some', and exit on the first non zero ?
    const areAdditionalServicesZero = services
      .reduce((isZero, key) => (isZero && this._getFeeSchemeForServiceKey(key).isZero()), true);
    return (
      this._hasZeroSeatsFee() && areAdditionalServicesZero
    );
  }

  isSeatsFixed() {
    const { baseLicenceFee, perManagerSeat, perProviderSeat } = this.spec;
    const currency = this.getCurrency();
    return !new Money(baseLicenceFee, currency).isZero()
      && new Money(perManagerSeat, currency).isZero()
      && new Money(perProviderSeat, currency).isZero();
  }

  isFixed() {
    return this.isSeatsFixed()
      && this._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.AOR).isZero();
  }

  hasFee() {
    return !this.isZero();
  }

  hasAdditionalServicesFee() {
    const services = Object.values(SERVICE_KEY_NAME);
    // TDOO - replace with an 'array.some' and exit on the first with fees ?
    const hasFees = services
      .reduce((hasFee, key) => (hasFee || this.hasFeeForServiceKey(key)), false);
    return hasFees;
  }

  /**
   * Set the fee scheme for a given service.
   *
   * @throws Will throw if the service key is not valid.
   *
   * @param {SERVICE_KEY_NAME} key - service to set.
   * @param {object} scheme - scheme to set.
   */
  setFeeSchemeForServiceKey(key, scheme) {
    switch (key) {
      case SERVICE_KEY_NAME.AOR:
        Object.assign(this.spec, {
          aorFeeScheme: new AORFeeScheme(scheme),
        });
        break;
      case SERVICE_KEY_NAME.API:
        Object.assign(this.spec, {
          apiFeeScheme: new ApiFeeScheme(scheme),
        });
        break;
      case SERVICE_KEY_NAME.CODAT:
        Object.assign(this.spec, {
          codatFeeScheme: new CodatFeeScheme(scheme),
        });
        break;
      case SERVICE_KEY_NAME.ESIGN:
        Object.assign(this.spec, {
          eSignFeeScheme: new ESignFeeScheme(scheme),
        });
        break;
      case SERVICE_KEY_NAME.TEN99:
        Object.assign(this.spec, {
          ten99FeeScheme: new Ten99FeeScheme(scheme),
        });
        break;
      case SERVICE_KEY_NAME.TIN:
        Object.assign(this.spec, {
          tinFeeScheme: new TINFeeScheme(scheme),
        });
        break;
      default:
        assert(false, `Unhandled service - ${key}`);
    }
  }

  /**
   * Get fee scheme for service key.
   *
   * @throws Will throw if the service key is not valid
   *
   * @param {SERVICE_KEY_NAME} key - service to get.
   * @return {object} fee scheme for given service.
   */
  _getFeeSchemeForServiceKey(key) {
    switch (key) {
      case SERVICE_KEY_NAME.AOR:
        return this.spec.aorFeeScheme;
      case SERVICE_KEY_NAME.API:
        return this.spec.apiFeeScheme;
      case SERVICE_KEY_NAME.CODAT:
        return this.spec.codatFeeScheme;
      case SERVICE_KEY_NAME.ESIGN:
        return this.spec.eSignFeeScheme;
      case SERVICE_KEY_NAME.TEN99:
        return this.spec.ten99FeeScheme;
      case SERVICE_KEY_NAME.TIN:
        return this.spec.tinFeeScheme;
      default:
        assert(false, `Unhandled service - ${key}`);
        return null;
    }
  }

  /**
   * Check if fee is present for service.
   *
   * @param {SERVICE_KEY_NAME} key - service to check.
   * @return {boolean} if fee is present.
   */
  hasDefinedFeeForServiceKey(key) {
    return !this._getFeeSchemeForServiceKey(key).isEmpty();
  }

  /**
   * Check if fee is configured for service key.
   *
   * @param {SERVICE_KEY_NAME} key - service to check.
   * @return {boolean} if fee is configured.
   */
  hasFeeForServiceKey(key) {
    return !this._getFeeSchemeForServiceKey(key).isZero();
  }

  /**
   * This method applies the metrics passed to the settings set by the licence scheme
   * The result is the applied charges
   *
   * @param {object} metrics (The metrics for the licence services)
   * @param {string} metrics.systemRegistrationNumber
   * @param {number} metrics.numProviders=0
   * @param {number} metrics.numManagers=1 - We are assuming that one manager always
   * exists - the org owner
   * @param {number} metrics.numAORSeats=0 - number of user seats that are using AOR
   * @param {object[]} metrics.seatsFeeBreakdownItems=[] - Fee Breakdown items
   * for seats showing seats per org
   * @param {object[]} metrics.aorFeeBreakdownItems=[] - Fee Breakdown items
   * for AOR showing seats per org
   * @param {boolean} metrics.aorEnabled=false - AOR will be disabled by default
   * @param {boolean} metrics.apiEnabled=false - Api will be disabled by default
   * @param {boolean} metrics.codatEnabled=false - Codat will be disabled by default
   * @param {boolean} metrics.esignEnabled=false - ESign will be disabled by default
   * @param {boolean} metrics.ten99Enabled=false - 1099 will be disabled by default
   * @param {boolean} metrics.tinEnabled=false - TIN will be disabled by default
   *
   * @returns {LicenceFeeAnalysis}
   */
  apply({
    // we need a default one for Licence fees and Services which use the same system entity
    systemRegistrationNumber = LicenceFeeScheme.getDefaultSystemReg(),
    // seats
    numManagers = 1, numProviders = 0,
    seatsFeeBreakdownItems = [],
    // aor
    numAORSeats = 0, aorEnabled = false,
    aorFeeBreakdownItems = [],
    // api
    apiEnabled = false,
    // codat
    codatEnabled = false,
    // esign
    esignEnabled = false,
    numESignDocs = 0,
    // 1099
    ten99Enabled = false,
    numTen99Filings = 0,
    // TIN
    tinEnabled = false,
    numTINChecks = 0,
  } = {}) {
    const { currency } = this.spec;

    // aor
    const aorMetrics = {
      numAORSeats,
      hasBeenEnabled: aorEnabled,
      systemRegistrationNumber,
      feeBreakdownItems: aorFeeBreakdownItems,
    };

    const aorScheme = this._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.AOR);
    const aorAnalysis = aorScheme.apply(aorMetrics);
    const aorAnalysisSerialized = aorAnalysis.serialize();

    // api
    const apiMetrics = {
      hasBeenEnabled: apiEnabled,
      systemRegistrationNumber,
    };

    const apiScheme = this._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.API);
    const apiAnalysis = apiScheme.apply(apiMetrics);
    const apiAnalysisSerialized = apiAnalysis.serialize();

    // codat
    const codatMetrics = {
      hasBeenEnabled: codatEnabled,
      systemRegistrationNumber,
    };

    const codatScheme = this._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.CODAT);
    const codatAnalysis = codatScheme.apply(codatMetrics);
    const codatAnalysisSerialized = codatAnalysis.serialize();

    // esign
    const esignMetrics = {
      hasBeenEnabled: esignEnabled,
      numESignDocs,
      systemRegistrationNumber,
    };

    const esignScheme = this._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.ESIGN);
    const esignAnalysis = esignScheme.apply(esignMetrics);
    const esignAnalysisSerialized = esignAnalysis.serialize();

    // 1099
    const ten99Metrics = {
      hasBeenEnabled: ten99Enabled,
      numTen99Filings,
      systemRegistrationNumber,
    };

    const ten99Scheme = this._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.TEN99);
    const ten99Analysis = ten99Scheme.apply(ten99Metrics);
    const ten99AnalysisSerialized = ten99Analysis.serialize();

    // TIN
    const tinMetrics = {
      hasBeenEnabled: tinEnabled,
      numTINChecks,
      systemRegistrationNumber,
    };

    const tinScheme = this._getFeeSchemeForServiceKey(SERVICE_KEY_NAME.TIN);
    const tinAnalysis = tinScheme.apply(tinMetrics);
    const tinAnalysisSerialized = tinAnalysis.serialize();

    // seats
    const seatsMetrics = {
      numManagers,
      numProviders,
      systemRegistrationNumber,
      feeBreakdownItems: seatsFeeBreakdownItems,
    };

    const seatsAnalysis = this.applySeats(seatsMetrics);
    const seatsAnalysisSerialized = seatsAnalysis.serialize();

    return new LicenceFeeAnalysis({
      currency,
      seats: seatsAnalysisSerialized,
      aor: aorAnalysisSerialized,
      api: apiAnalysisSerialized,
      codat: codatAnalysisSerialized,
      esign: esignAnalysisSerialized,
      ten99: ten99AnalysisSerialized,
      tin: tinAnalysisSerialized,
    });
  }

  /**
   * @param {object} metrics
   * @param {number} metrics.numManagers
   * @param {number} metrics.numProviders
   * @param {object[]} metrics.feeBreakdownItems
   * @param {string} metrics.systemRegistrationNumber
   *
   * @returns {SeatFeeAnalysis}
   */
  applySeats({
    numManagers = 1,
    numProviders = 0,
    feeBreakdownItems = [],
    systemRegistrationNumber,
  } = {}) {
    const {
      baseLicenceFee,
      baseManagerSeats,
      baseProviderSeats,
      perManagerSeat,
      perProviderSeat,
      systemReg,
      currency,
    } = this.spec;

    if (!isNil(systemRegistrationNumber) && systemRegistrationNumber !== systemReg) {
      // When system numbers don't match it means that the scheme's generated charges
      // are not applied by the system entity's reg number used. So we return an empty analysis.

      return SeatFeeAnalysis.getEmptyAnalysis();
    }

    const extraManagers = numManagers > baseManagerSeats ? numManagers - baseManagerSeats : 0;
    const extraProviders = numProviders > baseProviderSeats ? numProviders - baseProviderSeats : 0;
    const managersFee = new Money(perManagerSeat, currency).mul(extraManagers).toString();
    const providersFee = new Money(perProviderSeat, currency).mul(extraProviders).toString();
    const fee = new Money(baseLicenceFee, currency).add(managersFee).add(providersFee).toString();

    return new SeatFeeAnalysis({
      baseLicenceFee,
      extraManagers,
      extraProviders,
      fee,
      managersFee,
      numManagers,
      numProviders,
      feeBreakdownItems,
      perManagerSeat,
      perProviderSeat,
      providersFee,
    });
  }

  getFee({ numManagers = 1, numProviders = 0 } = {}) {
    const applied = this.apply({ numManagers, numProviders });
    return applied.getTotal();
  }

  getAdditionalServicesFormValues() {
    const {
      aorFeeScheme, apiFeeScheme, codatFeeScheme, eSignFeeScheme,
      ten99FeeScheme, tinFeeScheme,
    } = this.spec;
    const toReturn = [];
    if (this.hasDefinedFeeForServiceKey(SERVICE_KEY_NAME.AOR)) {
      toReturn.push({ service: SERVICE_KEY_NAME.AOR, ...aorFeeScheme.toFormValues() });
    }
    if (this.hasDefinedFeeForServiceKey(SERVICE_KEY_NAME.API)) {
      toReturn.push({ service: SERVICE_KEY_NAME.API, ...apiFeeScheme.toFormValues() });
    }
    if (this.hasDefinedFeeForServiceKey(SERVICE_KEY_NAME.CODAT)) {
      toReturn.push({ service: SERVICE_KEY_NAME.CODAT, ...codatFeeScheme.toFormValues() });
    }
    if (this.hasDefinedFeeForServiceKey(SERVICE_KEY_NAME.ESIGN)) {
      toReturn.push({ service: SERVICE_KEY_NAME.ESIGN, ...eSignFeeScheme.toFormValues() });
    }
    if (this.hasDefinedFeeForServiceKey(SERVICE_KEY_NAME.TEN99)) {
      toReturn.push({
        service: SERVICE_KEY_NAME.TEN99,
        ...ten99FeeScheme.toFormValues(),
      });
    }
    if (this.hasDefinedFeeForServiceKey(SERVICE_KEY_NAME.TIN)) {
      toReturn.push({ service: SERVICE_KEY_NAME.TIN, ...tinFeeScheme.toFormValues() });
    }

    return toReturn;
  }

  getAllAvailableServiceSchemes() {
    const {
      aorFeeScheme, apiFeeScheme, codatFeeScheme, eSignFeeScheme,
      ten99FeeScheme, tinFeeScheme,
    } = this.spec;
    const seatsFeeScheme = new LicenceFeeScheme({
      ...omit(this.spec, [
        'aorFeeScheme', 'apiFeeScheme', 'codatFeeScheme', 'eSignFeeScheme',
        'ten99FeeScheme', 'tinFeeScheme',
      ]),
    });

    const services = [
      aorFeeScheme,
      apiFeeScheme,
      codatFeeScheme,
      seatsFeeScheme,
      eSignFeeScheme,
      ten99FeeScheme,
      tinFeeScheme,
    ];

    return services;
  }

  hasFeeByRegistrationNumber(systemRegistrationNumber) {
    const availableServiceSchemes = this.getAllAvailableServiceSchemes(systemRegistrationNumber);
    const filteredServiceSchemes = availableServiceSchemes
      .filter(service => service.getSystemRegistrationNumber() === systemRegistrationNumber);

    if (filteredServiceSchemes.some(serviceScheme => serviceScheme.hasFee())) {
      return true;
    }
    return false;
  }

  removeAdditionalService(key) {
    this.init({
      ...this.serialize(),
      [key]: null,
    });
  }

  /**
   * Set a default fee scheme for a given service.
   *
   * @throws Will throw if the service key is not valid.
   *
   * @param {SERVICE_KEY_NAME} key - service key to set.
   */
  setDefaultAdditionalService(key) {
    let defaultScheme;
    switch (key) {
      case SERVICE_KEY_NAME.AOR:
        defaultScheme = AORFeeScheme.getDefault();
        break;
      case SERVICE_KEY_NAME.API:
        defaultScheme = ApiFeeScheme.getDefault();
        break;
      case SERVICE_KEY_NAME.CODAT:
        defaultScheme = CodatFeeScheme.getDefault();
        break;
      case SERVICE_KEY_NAME.ESIGN:
        defaultScheme = ESignFeeScheme.getDefault();
        break;
      case SERVICE_KEY_NAME.TEN99:
        defaultScheme = Ten99FeeScheme.getDefault();
        break;
      case SERVICE_KEY_NAME.TIN:
        defaultScheme = TINFeeScheme.getDefault();
        break;
      default:
        assert(false, `Unhandled service - ${key}`);
        break;
    }

    this.setFeeSchemeForServiceKey(key, defaultScheme);
  }

  /**
   * Returns an array of unique system registration numbers used in the schemes
   * @see LicenceInvoiceHandler
   *
   * @returns {String[]}
   */
  getSystemRegistrationNumbers() {
    const services = this.getAdditionalServicesFormValues();
    return [
      ...new Set([
        LicenceFeeScheme.getDefaultSystemReg(),
        ...services.map(service => service.systemReg),
      ]),
    ];
  }

  /**
   * Check if licence fee validation is disabled.
   *
   * @return {boolean} flag if licence fee validation is disabled.
   */
  isLicenceFeeValidationDisabled() {
    return this.spec.disableLicenceFeeValidation;
  }
}

export default LicenceFeeScheme;
