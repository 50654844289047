import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import { ICON } from 'core/assets/js/constants';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import { bankAccountSpec, twCurrencyReqsSpec } from 'settings/assets/js/lib/objectSpecs';
import TWBankDetailFieldsSkeleton from 'settings/assets/js/components/skeletons/TWBankDetailFieldsSkeleton.jsx';
import { getTWRequirementsDS } from 'settings/assets/js/data-services/settings';
import TWBankAccountDynamicFields from 'settings/assets/js/components/tw-form-components/TWBankAccountDynamicFields.jsx';
import Error50x from 'core/assets/js/components/errors/Error50x.jsx';

/**
 * It returns a Tab bar and its tab contents based on requirements received from Transferwise
 * In more details it translates the received currency's requirements and translates it into
 * how many bank account types are proposed to be filled. Each account type has its own tab.
 * e.g IBAN and/or Local account etc
 *
 * @params {Object} params
 * @params {Object} params.details
 * @returns {TWBankAccountDynamicFieldsContainer}
 * @constructor
 */
const TWBankAccountDynamicFieldsContainer = ({
  formValues, orgCurrencies, dispatch, item, descriptor, bankAccount, onCountrySelect,
  formMutators,
}) => {
  const LOCAL_ACCOUNT_TYPE_OPTIONS = {
    JAPANESE: 'japanese',
    AUSTRALIAN: 'australian',
    IBAN: 'iban',
    CANADIAN: 'canadian',
    malaysian: 'canadian',
  };
  /**
   * Prepares the mapping (used in our dropdowns)
   * of provided type options based on selected currency
   * @param {Object} currencyReqs - The currency requirements provided by Transferwise
   * @returns {Array}  {text: *, value: *}
   */
  function getAccountTypeOptionsMapping(currencyReqs) {
    const getIcon = (type) => {
      const typeIcons = {
        email: ICON.ENVELOPE_OPEN_DOLLAR,
        aba: ICON.MAP_MARKER_CHECK,
        swift_code: ICON.GLOBE,
        wallet: ICON.WALLET,
      };
      const locals = Object.values(LOCAL_ACCOUNT_TYPE_OPTIONS);
      switch (true) {
        case (locals.includes(type)):
          return ICON.MAP_MARKER_CHECK;
        case !!typeIcons[type]:
          return typeIcons[type];
        default:
          return ICON.GLOBE;
      }
    };
    const optionsMapping = Object.keys(currencyReqs)
      .map(type => {
        const icon = getIcon(type);
        return ({
          value: currencyReqs[type].type,
          label: (
            <>
              <i className={`mx-2 ${icon}`} />
              {currencyReqs[type].title}
            </>
          ),
        });
      });

    return optionsMapping;
  }

  const { requirements: currencyReqs } = item;
  const { type: paymentType, currency, address: { country } } = formValues;
  const hasSelectedPaymentType = !!paymentType;
  const requirements = currencyReqs || bankAccount.requirements;

  // To avoid cased where Transferwise has random issues and we don't
  // get the requirements
  if (isEmpty(requirements)) {
    return <Error50x displayInline />;
  }

  if (hasSelectedPaymentType && isEmpty(requirements[paymentType])) {
    formMutators.setValue('type');
  }

  const accountTypeOptionsMapping = getAccountTypeOptionsMapping(requirements);
  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <h2 className="mb-4">Bank details</h2>
          <CustomSelectField
            label="Account type"
            name="type"
            defaultOptionText="Select account type"
            options={accountTypeOptionsMapping}
            required
            validate={val => (!val ? 'Field is required' : null)}
          />
        </div>
      </div>
      <TWBankAccountDynamicFields
        currency={currency}
        country={country}
        paymentType={paymentType}
        dispatch={dispatch}
        requirements={requirements}
        orgCurrencies={orgCurrencies}
        descriptor={descriptor}
        onCountrySelect={onCountrySelect}
      />
    </>
  );
};

const TWDynamicFieldsWithTDApi = withTDApiConnected({
  fetchData: ({ dispatch, componentName, props, querystring }) => {
    const { formValues, formValues: { currency } } = props;
    // TODO - remove me !!! ???
    const queryParams = queryString.parse(querystring);
    return dispatch(
      getTWRequirementsDS({ componentName, currency, details: formValues, queryParams }),
    );
  },
  storeKey: ({ descriptor }) => descriptor,
  duck: 'view',
  skeletonComponent: TWBankDetailFieldsSkeleton,
})(TWBankAccountDynamicFieldsContainer);

TWBankAccountDynamicFieldsContainer.propTypes = {
  bankAccount: bankAccountSpec,
  descriptor: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  formMutators: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  item: twCurrencyReqsSpec.isRequired,
  onCountrySelect: PropTypes.func.isRequired,
  orgCurrencies: PropTypes.array,
};

TWBankAccountDynamicFieldsContainer.defaultProps = {
  orgCurrencies: [],
  bankAccount: {},
};

export default TWDynamicFieldsWithTDApi;
