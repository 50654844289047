import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_PAGE_SIZE } from 'settings/assets/js/constants';
import { fetchFinanceWorksheetListDS } from 'finance/assets/js/data-services/list';
import { financeWorksheetsCreateUrl, financeWorksheetsUrl, financeWorksheetViewUrl } from 'finance/urls';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { getListState } from 'core/assets/js/ducks/list';
import { prepareQueryString } from 'core/assets/js/lib/utils';
import { routerMatchSpec, worksheetAclSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { WORKSHEET_STATUS, WORKSHEET_STATUS_CLASS, WORKSHEET_STATUS_LABEL } from 'projects/assets/js/constants';
import { worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';
import WorksheetsWidgetSkeleton from 'core/assets/js/components/Skeleton/WorksheetsWidgetSkeleton.jsx';

export const COMPONENT_NAME = 'WorksheetsWidget';

const WorksheetsWidget = ({
  worksheetAccessControl,
  worksheetItems,
  hasOrgAccess,
  match: {
    params: { orgAlias },
  },
  history,
}) => {
  const isManager = hasOrgAccess({ requireManager: true });
  const headerActionBtn = {
    onClick: () => history.push(financeWorksheetsCreateUrl(orgAlias)),
    label: 'Submit worksheet',
    variant: BS_STYLE.PRIMARY,
  };

  const footerActionBtn = {
    onClick: () => history.push(financeWorksheetsUrl(orgAlias)),
    label: 'View worksheets',
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: 'Get paid for work you’ve done by raising a worksheet',
    icon: ICON.CLOCK_DUOTONE,
  };

  const worksheetStatusColFormat = (worksheet) => {
    const {
      statusCode,
      isDelayed,
      processAt,
      isAutoApproved,
      invoicedAt,
    } = worksheet;
    const isApproved = statusCode === WORKSHEET_STATUS.APPROVED;
    const isInvoiced = !!invoicedAt;

    if (isApproved && isInvoiced) {
      return (
        <StatusColFormat statusLabel="Invoiced" statusClass={WORKSHEET_STATUS_CLASS[statusCode]} />
      );
    }

    if (isApproved && isAutoApproved && isManager) {
      return (
        <StatusColFormat
          statusLabel="Auto approved"
          statusClass="approved-auto"
          title="Auto approved by TalentDesk.io"
        />
      );
    }

    if (isDelayed) {
      return (
        <StatusColFormat
          statusLabel="Approved"
          statusClass="delayed"
          title={`The worksheet will not be processed prior to ${processAt}`}
        />
      );
    }

    return (
      <StatusColFormat
        statusLabel={WORKSHEET_STATUS_LABEL[statusCode]}
        statusClass={WORKSHEET_STATUS_CLASS[statusCode]}
      />
    );
  };

  const widgetlTitle = 'Worksheets';

  return (
    <WidgetWrapper
      headerActionBtn={worksheetAccessControl.canCreate ? headerActionBtn : null}
      footerActionBtn={footerActionBtn}
      headerExtraClasses="d-flex align-items-center flex-wrap"
      isEmpty={isEmpty(worksheetItems)}
      placeholder={emptyPlaceholder}
      title={widgetlTitle}
      popOverTitle={widgetlTitle}
      popOverContent={(
        <>
          <p>
            Worksheets are the way that you start the process of getting paid for the work that
            you have completed. Worksheets can have multiple line items, each needing to be linked
            to a project or task. You can create multiple worksheets in a given billing period,
            however, these will be consolidated into a single invoice for the organization to pay.
          </p>
          <p>
            <a
              href="https://www.talentdesk.io/knowledge-base/finances/provider/raising-worksheets-and-expenses"
              target="_blank"
              rel="noreferrer"
            >
              To learn more
              {' '}
              <span className="visually-hidden">
                {' '}
                about
                {' '}
                {widgetlTitle}
                ,
              </span>
              visit the Knowledge base
            </a>
          </p>
        </>
      )}
    >
      <div className="d-none d-md-block">
        <div className="row d-flex align-items-center mb-3">
          <div className="col-md-6">
            <p className="font-weight-bold">Period</p>
          </div>
          <div className="col-md-3">
            <p className="font-weight-bold">Amount</p>
          </div>
          <div className="col-md-3">
            <p className="font-weight-bold align-center">Status</p>
          </div>
        </div>

        {worksheetItems.map((worksheet) => {
          const { id, period, worksheetAmount, worksheetCurrencySymbol } = worksheet;
          return (
            <div className="row d-flex align-items-center mb-5" key={id}>
              <div className="col-md-6">
                <Link
                  to={financeWorksheetViewUrl(orgAlias, id)}
                >
                  {period}
                </Link>
              </div>
              <div className="col-md-3">
                <NumberTpl value={worksheetAmount} prefix={worksheetCurrencySymbol} />
              </div>
              <div className="col-md-3">
                {worksheetStatusColFormat(worksheet)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-block d-md-none">
        {worksheetItems.map((worksheet, index) => {
          const { id, period, worksheetAmount, worksheetCurrencySymbol } = worksheet;
          const pt = index === 0 ? 3 : 5;
          const pb = index === worksheetItems.length - 1 ? 3 : 5;
          return (
            <div className={`row mx-0 pt-${pt} pb-${pb} px-0 widget-item__container`} key={id}>
              <div className="col-md-12 px-0 pb-5">
                <p className="font-weight-bold mb-1">Period</p>
                {period}
              </div>
              <div className="col-md-12 px-0 pb-5">
                <p className="font-weight-bold mb-1">Amount</p>
                <NumberTpl value={worksheetAmount} prefix={worksheetCurrencySymbol} />
              </div>
              <div className="col-md-12 px-0 pb-5">
                <p className="font-weight-bold mb-2 align-center">Status</p>
                {worksheetStatusColFormat(worksheet)}
              </div>
              <div className="col-md-12 px-0 pt-2">
                <TDButton
                  type="submit"
                  variant={BS_STYLE.DEFAULT}
                  label="View"
                  onClick={() => (
                    history.push(financeWorksheetViewUrl(orgAlias, id))
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
    </WidgetWrapper>
  );
};

WorksheetsWidget.propTypes = {
  hasOrgAccess: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  worksheetAccessControl: worksheetAclSpec,
  worksheetItems: PropTypes.arrayOf(worksheetSpec),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

WorksheetsWidget.defaultProps = {
  worksheetItems: [],
  worksheetAccessControl: { canCreate: false },
};

const mapStateToProps = (state) => {
  const listState = getListState(state, COMPONENT_NAME);
  return {
    hasOrgAccess: getHasOrgAccess(state),
    worksheetItems: listState.items,
    worksheetAccessControl: listState.extras.accessControl,
  };
};

const WorksheetsWidgetConnected = connect(mapStateToProps)(WorksheetsWidget);

const TdApiConnectedWorksheetsWidget = withTDApiConnected({
  fetchData: ({ dispatch, params, url, authedAxios }) => dispatch(fetchFinanceWorksheetListDS({
    authedAxios,
    componentName: COMPONENT_NAME,
    dispatch,
    params,
    querystring: prepareQueryString({
      pageSize: DASHBOARD_WIDGET_PAGE_SIZE[DASHBOARD_WIDGET.WORKSHEETS],
    }),
    url,
  })),
  duck: 'list',
  skeletonComponent: WorksheetsWidgetSkeleton,
  storeKey: COMPONENT_NAME,
})(WorksheetsWidgetConnected);

export default withRouter(TdApiConnectedWorksheetsWidget);
