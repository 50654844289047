import qs from 'query-string';
import { toastr } from 'react-redux-toastr';
import { omit, pick } from 'lodash';

import axios from 'core/assets/js/lib/tdAxios';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE, USER_CARD_STATUS } from 'core/assets/js/constants';
import { RATE_STATUS } from 'rates/assets/js/constants';
import { ratesFetchRatesOfUserApiUrl } from 'rates/urls';
import {
  extrasUpdateAC,
  viewFetchAC,
  viewFetchExtrasAC,
  viewUpdateAC,
} from 'core/assets/js/ducks/view';
import {
  projectArchiveTaskApiUrl,
  projectArchiveTaskItemApiUrl,
  projectCopyAttachmentsApiUrl,
  projectTaskAddManagersApiUrl,
  projectTaskRemoveManagersApiUrl,
  projectCreateTaskApiUrl,
  projectCreateTaskItemApiUrl,
  projectCreateWorksheetsAndCompleteTaskApiUrl,
  projectCreateWorksheetsForTaskApiUrl,
  projectFetchTaskAssignmentsApiUrl,
  projectFetchTaskDeliverablesApiUrl,
  projectFetchTaskForWorksheetUrl,
  projectFetchTaskItemsApiUrl,
  projectFetchTaskListForWorksheetsUrl,
  projectMyTasksListApiUrl,
  projectTaskAnswersUrl,
  projectTaskApproveApiUrl,
  projectTaskAssignmentAnalyticsUrl,
  projectTaskAssignmentReOpenApiUrl,
  projectTaskChangeOwnerApiUrl,
  projectTaskClaimOwnershipApiUrl,
  projectTaskCloneApiUrl,
  projectTaskCompleteApiUrl,
  projectTaskCompleteAssignmentApiUrl,
  projectTaskCreateAssignmentsApiUrl,
  projectTaskDeliverableStatusToggleApiUrl,
  projectTaskDeliverablesUploadApiUrl,
  projectTaskDeliverableToggleApiUrl,
  projectTaskInfoFetchApiUrl,
  projectTaskInvitationAcceptUrl,
  projectTaskInvitationCancelUrl,
  projectTaskInvitationRejectUrl,
  projectTaskLeaveApiUrl,
  projectTaskListApiUrl,
  projectTaskMessageAcceptBudgetAmendmentApiUrl,
  projectTaskMessageCancelBudgetAmendmentApiUrl,
  projectTaskMessageCreateApiUrl,
  projectTaskMessageRejectBudgetAmendmentApiUrl,
  projectTaskMessageRequestBudgetAmendmentApiUrl,
  projectTaskMessagesFetchApiUrl,
  projectTaskRemoveAssignmentApiUrl,
  projectTaskRemoveAttachmentApiUrl,
  projectTaskRequestCompletionApiUrl,
  projectTaskReviewApiUrl,
  projectTasksAssignmentAnalyticsUrl,
  projectTaskStopApiUrl,
  projectTaskUnwatchApiUrl,
  projectTaskWatchApiUrl,
  projectToggleTaskItemApiUrl,
  projectUnarchiveTaskApiUrl,
  projectUpdateTaskApiUrl,
  projectUpdateTaskAssignmentCapApiUrl,
  projectUpdateTaskItemApiUrl,
  projectUpdateTaskProgressApiUrl,
  projectFetchTaskApiUrl,
} from 'projects/urls';
import { orgPeopleListApiUrl } from 'people/urls';
import {
  listFetchAC,
  listAddExtrasAC,
  listFetchExtrasAC,
  listAppendItemsAC,
  paginationFetchAC,
  searchFetchAC,
  listUpdateItemAC,
  listAppendItemAC,
  listRemoveItemAC,
  listPrependItemsAC,
  listUpdateExtrasAC,
  listUpdateExtrasItem,
} from 'core/assets/js/ducks/list';

const _validateParams = ({ orgAlias, id }) => {
  if (!orgAlias || !id) {
    throw new Error(`Invalid orgAlias (${orgAlias} or projectId (${id}))`);
  }
};

const _validateTaskParams = ({ orgAlias, id, taskId }) => {
  if (!taskId) {
    throw new Error(`Invalid taskId ${taskId}`);
  }

  return _validateParams({ orgAlias, id });
};

export const fetchTaskListDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => projectTaskListApiUrl(params.orgAlias, projectId, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData.tasks, componentName),
      listFetchExtrasAC(responseData.accessControl, componentName, 'allowedActions'),
      listFetchExtrasAC(responseData.customFieldFilters, componentName, 'customFieldFilters'),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchMyTaskListDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '',
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => projectMyTasksListApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData.tasks, componentName),
      listFetchExtrasAC(responseData.accessControl, componentName, 'allowedActions'),
      listFetchExtrasAC(responseData.customFieldFilters, componentName, 'customFieldFilters'),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchTaskForWorksheetDS = ({
  authedAxios = null, componentName, orgAlias, projectId, querystring = '', taskId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => projectFetchTaskForWorksheetUrl(orgAlias, projectId, taskId),
    fetchDataAC: responseData => [
      viewFetchExtrasAC(responseData[0], componentName, 'task'),
    ],
    componentName,
  })
);

export const fetchTaskListForWorksheetDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => projectFetchTaskListForWorksheetsUrl(params.orgAlias, projectId, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData, componentName),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchTasksAssignmentAnalyticsDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring,
    fetchApiUrl: () => projectTasksAssignmentAnalyticsUrl(
      params.orgAlias, projectId, url,
    ),
    fetchDataAC: responseData => [
      listFetchExtrasAC(responseData, componentName, 'tasksAssignmentAnalytics'),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

// Fetch a task for a project
export const fetchProjectTaskDS = ({
  orgAlias, projectId, taskId, componentName, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
    fetchApiUrl: () => projectFetchTaskApiUrl(orgAlias, projectId, taskId, url),
    componentName,
    fetchDataAC: responseData => [
      viewFetchAC(responseData.task, componentName),
      extrasUpdateAC(
        {
          accessControl: responseData.task.accessControl,
          project: responseData.project,
          taskState: responseData.state,
        },
        componentName,
      ),
    ],
  })
);

export const fetchProjectTaskItemsDS = ({
  orgAlias, projectId, taskId, componentName, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
    fetchApiUrl: () => projectFetchTaskItemsApiUrl(orgAlias, projectId, taskId, url),
    componentName,
    fetchDataAC: responseData => [
      listFetchAC(responseData.items, componentName),
    ],
  })
);

export const fetchProjectTaskAnalyticsDS = ({
  orgAlias, projectId, taskId, componentName, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
    fetchApiUrl: () => projectTaskAssignmentAnalyticsUrl(orgAlias, projectId, taskId, url),
    componentName,
    fetchDataAC: responseData => [
      listFetchExtrasAC(responseData, componentName, 'taskAssignmentAnalytics'),
    ],
  })
);

export const fetchProjectTaskAnswers = ({
  orgAlias, projectId, taskId, componentName, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
    fetchApiUrl: () => projectTaskAnswersUrl(orgAlias, projectId, taskId, url),
    componentName,
    fetchDataAC: responseData => [
      listFetchAC(responseData, componentName),
    ],
  })
);

export const projectCreateTaskDS = (orgAlias, projectId, values) => (
  axios.post(projectCreateTaskApiUrl(orgAlias, projectId), values)
);

export const projectUpdateTaskDS = (orgAlias, projectId, taskId, values) => (
  axios.put(projectUpdateTaskApiUrl(orgAlias, projectId, taskId), values)
);

export const projectArchiveTaskDS = (orgAlias, projectId, taskId) => (
  axios.delete(projectArchiveTaskApiUrl(orgAlias, projectId, taskId))
);

export const projectUnarchiveTaskDS = (orgAlias, projectId, taskId) => (
  axios.put(projectUnarchiveTaskApiUrl(orgAlias, projectId, taskId))
);

export const fetchTaskAssigneeRatesDS = (orgAlias, userId) => (
  axios.get(ratesFetchRatesOfUserApiUrl(orgAlias, userId, [RATE_STATUS.ACTIVE]))
);

export const projectUpdateTaskAssignmentProgressDS = ({
  orgAlias, projectId, taskId, userId, values, componentName,
}) => (
  dispatch => dispatch(
    pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectUpdateTaskProgressApiUrl(orgAlias, projectId, taskId, userId),
      values,
      pushDataAC: responseData => [
        viewUpdateAC(responseData.task, componentName),
        viewFetchExtrasAC(responseData.state, componentName, 'taskState'),
        listUpdateExtrasItem(responseData.assignment, componentName, 'assignments'),
      ],
    }),
  )
);

export const projectCreateTaskItemDS = (orgAlias, projectId, taskId, values, componentName) => (
  dispatch => (
    dispatch(
      pushDataDS({
        reqType: REQ_TYPE.POST,
        pushApiUrl: projectCreateTaskItemApiUrl(orgAlias, projectId, taskId),
        values,
        pushDataAC: responseData => (
          listAppendItemAC(responseData.item, componentName)
        ),
      }),
    )
  )
);

export const projectUpdateTaskItemDS = (
  orgAlias, projectId, taskId, itemId, values, componentName,
) => (
  dispatch => (
    dispatch(
      pushDataDS({
        reqType: REQ_TYPE.PUT,
        pushApiUrl: projectUpdateTaskItemApiUrl(orgAlias, projectId, taskId, itemId),
        values,
        pushDataAC: responseData => (
          listUpdateItemAC(itemId, responseData.item, componentName)
        ),
      }),
    )
  )
);

export const projectArchiveTaskItemDS = (
  orgAlias, projectId, taskId, itemId, componentName,
) => (
  dispatch => (
    dispatch(
      pushDataDS({
        reqType: REQ_TYPE.DELETE,
        pushApiUrl: projectArchiveTaskItemApiUrl(orgAlias, projectId, taskId, itemId),
        pushDataAC: () => listRemoveItemAC(itemId, componentName),
      }),
    )
  )
);

export const projectToggleTaskItemDS = (
  orgAlias, projectId, taskId, itemId, values, componentName,
) => (
  dispatch => (
    dispatch(
      pushDataDS({
        reqType: REQ_TYPE.PUT,
        pushApiUrl: projectToggleTaskItemApiUrl(orgAlias, projectId, taskId, itemId),
        values,
        pushDataAC: responseData => (
          listUpdateItemAC(itemId, responseData.item, componentName)
        ),
      }),
    )
  )
);

export const fetchProjectTaskInfoDS = ({
  orgAlias, projectId, taskId, componentName, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
    fetchApiUrl: () => projectTaskInfoFetchApiUrl(orgAlias, projectId, taskId, url),
    componentName,
    fetchDataAC: responseData => [
      viewFetchExtrasAC(responseData.info, componentName, 'taskInfo'),
    ],
  })
);

export const fetchProjectTaskMessagesDS = ({
  after = null,
  authedAxios = null,
  componentName,
  orgAlias,
  page = null,
  projectId,
  participantId,
  reset = false,
  taskId,
  url = '',
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
    fetchApiUrl: () => projectTaskMessagesFetchApiUrl(
      orgAlias, projectId, taskId, page, after, participantId, url,
    ),
    paginationAC: paginationFetchAC,
    componentName,
    fetchDataAC: responseData => [
      (reset ? listFetchAC : listPrependItemsAC)(responseData.messages, componentName),
      listAddExtrasAC(responseData.taskAttachments, componentName, 'taskAttachments'),
    ],
  })
);

export const fetchProjectTaskDeliverablesDS = ({
  orgAlias, projectId, taskId, componentName, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
    fetchApiUrl: () => projectFetchTaskDeliverablesApiUrl(orgAlias, projectId, taskId, url),
    componentName,
    fetchDataAC: responseData => [
      listFetchAC(responseData.deliverables, componentName),
    ],
  })
);

export const fetchProjectTaskAssignmentsDS = ({
  orgAlias, projectId, taskId, componentName, url = '', authedAxios = null,
}) => (
  dispatch => dispatch(
    fetchDataDS({
      authedAxios,
      validate: () => _validateTaskParams({ orgAlias, id: projectId, taskId }),
      fetchApiUrl: () => projectFetchTaskAssignmentsApiUrl(orgAlias, projectId, taskId, url),
      fetchDataAC: responseData => [
        listFetchExtrasAC(responseData.assignments, componentName, 'assignments'),
      ],
    }),
  )
);

export const sendTaskMessageDS = ({
  orgAlias, projectId, taskId, participantId, values, componentName,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskMessageCreateApiUrl(orgAlias, projectId, taskId, participantId),
    values,
    pushDataAC: responseData => [
      listAppendItemsAC(responseData.messages, componentName),
      listAddExtrasAC(responseData.attachments, componentName, 'taskAttachments'),
    ],
  })
);

export const requestTaskBudgetAmendmentDS = ({
  orgAlias, projectId, taskId, values, participantId,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskMessageRequestBudgetAmendmentApiUrl(
      orgAlias, projectId, taskId, participantId,
    ),
    values,
  })
);

export const acceptTaskBudgetAmendmentDS = ({ orgAlias, projectId, taskId, values }) => (
  pushDataDS({
    pushApiUrl: projectTaskMessageAcceptBudgetAmendmentApiUrl(orgAlias, projectId, taskId),
    reqType: REQ_TYPE.PUT,
    values,
  })
);

export const cancelTaskBudgetAmendmentDS = ({ orgAlias, projectId, taskId, values }) => (
  pushDataDS({
    pushApiUrl: projectTaskMessageCancelBudgetAmendmentApiUrl(
      orgAlias, projectId, taskId,
    ),
    reqType: REQ_TYPE.PUT,
    values,
  })
);

export const rejectTaskBudgetAmendmentDS = ({ orgAlias, projectId, taskId, values }) => (
  pushDataDS({
    pushApiUrl: projectTaskMessageRejectBudgetAmendmentApiUrl(orgAlias, projectId, taskId),
    reqType: REQ_TYPE.PUT,
    values,
  })
);

export const acceptTaskInvitationDS = ({ orgAlias, projectId, taskId, values, url = '' }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskInvitationAcceptUrl(orgAlias, projectId, taskId, url),
    values,
  })
);

export const rejectTaskInvitationDS = ({ orgAlias, projectId, taskId, values, url = '' }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskInvitationRejectUrl(orgAlias, projectId, taskId, url),
    values,
  })
);

export const cancelTaskInvitationDS = ({ orgAlias, projectId, taskId, values, url = '' }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskInvitationCancelUrl(orgAlias, projectId, taskId, url),
    values,
  })
);

export const requestTaskCompletionDS = ({
  orgAlias, projectId, taskId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskRequestCompletionApiUrl(orgAlias, projectId, taskId),
    values,
  })
);

export const completeTaskDS = ({
  orgAlias, projectId, taskId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: projectTaskCompleteApiUrl(orgAlias, projectId, taskId),
    values,
  })
);

export const completeTaskAssignmentDS = ({
  orgAlias, projectId, taskId, participantId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: projectTaskCompleteAssignmentApiUrl(orgAlias, projectId, taskId, participantId),
    values,
  })
);

export const reOpenTaskAssignmentDS = ({
  componentName,
  listViewComponentName,
  orgAlias,
  participantId,
  projectId,
  taskId,
  values,
}) => pushDataDS({
  pushApiUrl: projectTaskAssignmentReOpenApiUrl(orgAlias, projectId, taskId, participantId),
  pushDataAC: responseData => {
    const actions = [
      viewUpdateAC(responseData.task, componentName),
      extrasUpdateAC(
        { accessControl: responseData.accessControl, taskState: responseData.state },
        componentName,
      ),
    ];
    if (listViewComponentName) {
      actions.push(listUpdateItemAC(
        responseData.assignment.id, responseData.assignment, listViewComponentName,
      ));
    }
    return actions;
  },
  reqType: REQ_TYPE.PUT,
  values,
});

export const reviewTaskDS = ({
  orgAlias, projectId, taskId, participantId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskReviewApiUrl(orgAlias, projectId, taskId, participantId),
    values,
  })
);

export const stopTaskDS = ({
  orgAlias, projectId, taskId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: projectTaskStopApiUrl(orgAlias, projectId, taskId),
    values,
  })
);

export const cloneTaskDS = ({
  orgAlias, projectId, taskId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskCloneApiUrl(orgAlias, projectId, taskId),
    values,
  })
);

export const approveTaskDS = ({ componentName, orgAlias, projectId, taskId }) => pushDataDS({
  pushApiUrl: projectTaskApproveApiUrl(orgAlias, projectId, taskId),
  pushDataAC: responseData => ([
    viewUpdateAC(responseData.task, componentName),
    extrasUpdateAC(
      { accessControl: responseData.accessControl, taskState: responseData.state },
      componentName,
    ),
  ]),
  reqType: REQ_TYPE.POST,
});

export const copyTaskAttachmentsDS = ({
  orgAlias, projectId, taskId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectCopyAttachmentsApiUrl(orgAlias, projectId, taskId),
    values,
  })
);

export const removeTaskAttachmentDS = ({
  componentName, orgAlias, projectId, taskId, attachmentId,
}) => (
  pushDataDS({
    pushApiUrl: projectTaskRemoveAttachmentApiUrl(orgAlias, projectId, taskId, attachmentId),
    pushDataAC: () => listRemoveItemAC(attachmentId, componentName),
    reqType: REQ_TYPE.DELETE,
  })
);

export const leaveTaskDS = ({
  orgAlias, projectId, taskId, values,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: projectTaskLeaveApiUrl(orgAlias, projectId, taskId),
    values,
  })
);

export const toggleDeliverablePinned = async ({
  orgAlias, projectId, taskId, deliverableId, isPinned,
}) => {
  const url = projectTaskDeliverableToggleApiUrl(orgAlias, projectId, taskId, deliverableId);

  const { data: { deliverable } } = await axios.put(url, { isPinned });

  return deliverable;
};

export const toggleDeliverableStatusToggled = async ({
  orgAlias, projectId, taskId, attachmentId, isDeliverable,
}) => {
  const url = projectTaskDeliverableStatusToggleApiUrl(orgAlias, projectId, taskId, attachmentId);

  const { data: { attachment } } = await axios.put(url, {
    is_deliverable: isDeliverable,
  });

  return attachment;
};

export const uploadDeliverablesDS = async ({ orgAlias, projectId, taskId, files }) => {
  const url = projectTaskDeliverablesUploadApiUrl(orgAlias, projectId, taskId);

  const { data: { deliverables } } = await axios.post(url, { files });

  return deliverables;
};

export const claimTaskOwnershipDS = ({ orgAlias, projectId, taskId, componentName }) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectTaskClaimOwnershipApiUrl(orgAlias, projectId, taskId),
      pushDataAC: responseData => ([
        viewUpdateAC(responseData.task, componentName),
        extrasUpdateAC(
          {
            accessControl: responseData.accessControl,
            taskState: responseData.state,
          },
          componentName,
        ),
      ]),
    }))
  )
);

export const taskChangeOwnerDS = ({ orgAlias, projectId, taskId, values, componentName }) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectTaskChangeOwnerApiUrl(orgAlias, projectId, taskId),
      values,
      pushDataAC: responseData => ([
        viewUpdateAC(responseData.task, componentName),
        extrasUpdateAC(
          {
            accessControl: responseData.accessControl,
            taskState: responseData.state,
          },
          componentName,
        ),
      ]),
    }))
  )
);

export const removeTaskManagersDS = ({ //eslint-disable-line
  url = '', componentName, orgAlias, projectId, taskId, taskManagerId,
}) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: projectTaskRemoveManagersApiUrl(
      orgAlias,
      projectId,
      taskId,
      taskManagerId,
      url,
    ),
    pushDataAC: () => listRemoveItemAC(taskManagerId, componentName),
  }))
);

export const availableTaskMembersDS = ({
  authedAxios, componentName, orgAlias, projectId, taskId, url = '', querystring = '', peopleType,
}) => dispatch => {
  const queryString = qs.parse(querystring);
  queryString.status = USER_CARD_STATUS.APPROVED;
  return dispatch(
    fetchDataDS({
      authedAxios,
      querystring: qs.stringify(queryString),
      fetchApiUrl: () => orgPeopleListApiUrl({
        orgAlias, projectId, taskId, url, peopleType,
      }),
      fetchDataAC: responseData => [
        listFetchAC(responseData.items, componentName),
        listFetchExtrasAC(responseData.userGroupNames, componentName, 'userGroupNames'),
        listFetchExtrasAC(responseData.customFieldFilters, componentName, 'customFieldFilters'),
      ],
      componentName,
      paginationAC: paginationFetchAC,
      searchAC: searchFetchAC,
    }),
  );
};

export const assignTaskDS = ({ orgAlias, projectId, taskId, values }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: projectTaskCreateAssignmentsApiUrl(orgAlias, projectId, taskId),
    values,
  })
);

export const watchTaskDS = (orgAlias, projectId, taskId) => (
  dispatch => dispatch(
    pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectTaskWatchApiUrl(orgAlias, projectId, taskId),
    }),
  )
);

export const unwatchTaskDS = (orgAlias, projectId, taskId) => (
  dispatch => dispatch(
    pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectTaskUnwatchApiUrl(orgAlias, projectId, taskId),
    }),
  )
);

export const removeTaskMemberDS = ({
  assignmentId,
  componentName,
  deleteWorkTimeBlocks = false,
  listViewComponentName,
  orgAlias,
  projectId,
  taskId,
  userId,
}) => (
  dispatch => dispatch(
    pushDataDS({
      reqType: REQ_TYPE.DELETE,
      pushApiUrl: projectTaskRemoveAssignmentApiUrl(
        orgAlias, projectId, taskId, userId, deleteWorkTimeBlocks,
      ),
      pushDataAC: responseData => [
        viewUpdateAC(responseData.task, componentName),
        viewFetchExtrasAC(responseData.state, componentName, 'taskState'),
        listRemoveItemAC(assignmentId, listViewComponentName),
      ],
    }),
  )
    .then(() => {
      toastr.success('Well Done!', 'Provider removed successfully from the task.');
    })
    .catch(({ errors = {} }) => {
      const errorMsg = Object.keys(omit(errors, ['_meta', '_error']))
        .map(key => errors[key]).join(' ');
      toastr.error(errors._error, errorMsg);
    })
);

export const taskAddManagersDS = ({ orgAlias, projectId, taskId, userIds }) => (
  dispatch => dispatch(
    pushDataDS({
      reqType: REQ_TYPE.POST,
      pushApiUrl: projectTaskAddManagersApiUrl(orgAlias, projectId, taskId),
      values: {
        userIds,
      },
    }),
  )
);

export const projectUpdateTaskAssignmentCapDS = ({
  assigneeUserId, assignmentId, componentName, orgAlias, projectId, taskId, values,
}) => (
  dispatch => dispatch(
    pushDataDS({
      pushApiUrl: projectUpdateTaskAssignmentCapApiUrl(orgAlias, projectId, taskId, assigneeUserId),
      pushDataAC: responseData => listUpdateItemAC(
        assignmentId, pick(responseData, 'rateMaxBillingQuantity'), componentName,
      ),
      reqType: REQ_TYPE.PUT,
      values,
    }),
  )
);

export const createWorksheetsForTaskDS = ({
  alsoComplete = false,
  orgAlias,
  projectId,
  rootComponentName,
  taskId,
  values,
}) => {
  const urlFunc = alsoComplete
    ? projectCreateWorksheetsAndCompleteTaskApiUrl
    : projectCreateWorksheetsForTaskApiUrl;
  return dispatch => dispatch(
    pushDataDS({
      pushApiUrl: urlFunc(orgAlias, projectId, taskId),
      pushDataAC: responseData => {
        const updateActions = [
          listUpdateExtrasAC({ assignments: responseData.task.assignments }, rootComponentName),
          viewUpdateAC(responseData.task, rootComponentName),
        ];
        if (alsoComplete) {
          updateActions.push(
            extrasUpdateAC({ accessControl: responseData.task.accessControl }, rootComponentName),
          );
        }
        return updateActions;
      },
      values,
    }),
  );
};

export const createWorksheetsAndCompleteTaskDS = props => createWorksheetsForTaskDS({
  alsoComplete: true,
  ...props,
});
