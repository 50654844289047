import axios from 'core/assets/js/lib/tdAxios';
import { has } from 'lodash';

import { REQ_TYPE } from 'core/assets/js/constants';
import { httpErrorAC } from 'core/assets/js/ducks/errors';
import {
  changeSubmissionStatusToSubmittedApiUrl,
  customFieldsCreateTemplateApiUrl,
  customFieldsDeleteTemplateApiUrl,
  customFieldsUpdateTemplateApiUrl,
  interviewApiUrl,
  interviewListApiUrl,
  interviewPublicInviteApiUrl,
  submissionApiUrl,
  submissionByInterviewIdApiUrl,
  submissionChangedDetailsApiUrl,
  submissionReviewApiUrl,
} from 'interviews/urls';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  viewFetchAC, viewFetchErrorAC, viewFetchExtrasAC, viewUpdateAC,
} from 'core/assets/js/ducks/view';
import { customFieldsFetchAC } from 'interviews/assets/js/ducks/customFields';

const _validateParams = ({ orgAlias, interviewId, submissionId }) => {
  if (!((orgAlias && interviewId) || submissionId)) {
    throw new Error(
      `[fetchInterviewDS Error] Invalid orgAlias (${orgAlias}), interviewId (${interviewId}) or submissionId (${submissionId})`,
    );
  }
};

const addQuestionsFetchDataAC = componentName => res => {
  res.questions = res.questions.map(q => ({
    ...q,
  }));
  return [
    viewFetchAC(res, componentName),
    customFieldsFetchAC(res.questions),
  ];
};

export const fetchInterviewDS = ({
  params, url = '', authedAxios = null, componentName, interviewId,
}) => {
  const { orgAlias } = params;

  // fetchInterviewDS is passed interviewIs in InviteeList & InterviewBuilder in different ways
  const intervId = params.interviewId || interviewId;

  return fetchDataDS({
    authedAxios,
    validate: () => _validateParams({ orgAlias, interviewId: intervId }),
    fetchApiUrl: () => interviewApiUrl(orgAlias, intervId, url),
    fetchDataAC: addQuestionsFetchDataAC(componentName),
    componentName,
  });
};

export const fetchSubmissionDS = ({
  params, url = '', authedAxios = null, componentName,
}) => {
  const { orgAlias, interviewId, submissionId } = params;

  const fetchDataAC = response => viewFetchAC(response, componentName);

  return fetchDataDS({
    authedAxios,
    validate: () => _validateParams({ orgAlias, interviewId, submissionId }),
    fetchApiUrl: () => {
      if (submissionId) {
        return submissionApiUrl(orgAlias, submissionId, url);
      }
      return submissionByInterviewIdApiUrl(orgAlias, interviewId, url);
    },
    componentName,
    fetchDataAC,
    fetchDataErrorAC: viewFetchErrorAC,
  });
};

export const fetchSubmissionChangedDetailsDS = ({
  authedAxios = null, componentName, orgAlias, submissionId,
}) => fetchDataDS({
  authedAxios,
  componentName,
  fetchApiUrl: () => submissionChangedDetailsApiUrl(orgAlias, submissionId),
  fetchDataAC: response => viewFetchExtrasAC(response, componentName, 'changedDetails'),
  fetchDataErrorAC: viewFetchErrorAC,
});


const _writeDS = (axiosParams, successAC) => (
  async dispatch => {
    if (!successAC) {
      throw new Error(`[fetchDataDS] Invalid fetchDataAC(): ${successAC}`);
    }
    const { data, method, url } = axiosParams;
    const response = await axios[method](url, data);
    const acs = successAC(response.data);
    if (Array.isArray(acs)) {
      acs.forEach(ac => dispatch(ac));
    } else {
      dispatch(acs);
    }
    return response.data;
  }
);

export const postInterviewDS = ({ orgAlias, interviewId }, data, componentName) => {
  const apiUrl = (interviewId ? interviewApiUrl : interviewListApiUrl)(orgAlias, interviewId);
  const method = interviewId ? 'put' : 'post';
  return _writeDS({ url: apiUrl, data, method }, responseData => (
    viewUpdateAC(responseData, componentName)
  ));
};

export const postSubmissionDS = ({ orgAlias, submissionId }, values, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      pushApiUrl: submissionApiUrl(orgAlias, submissionId),
      pushDataAC: responseData => viewFetchAC(responseData.submission, componentName),
      reqType: REQ_TYPE.PUT,
      values,
    }))
  )
);

export const postSubmissionReviewDS = ({ orgAlias, submissionId }, data, componentName) => {
  const apiUrl = submissionReviewApiUrl(orgAlias, submissionId);
  return _writeDS({ url: apiUrl, data, method: 'post' }, (responseData) => {
    const acs = [
      viewUpdateAC(responseData.submission, componentName),
    ];
    return acs;
  });
};

export const deleteInterviewDS = ({ orgAlias }, interviewId, dispatch) => (
  new Promise((resolve, reject) => {
    const params = { url: interviewApiUrl(orgAlias, interviewId), method: 'delete' };
    axios(params)
      .then((response) => {
        if (response.data && response.data._error && response.data._meta.isValidation) {
          reject(new Error(response.data._error));
        } else {
          resolve();
        }
      })
      .catch((error) => {
        if (has(error, 'response.data._meta') && error.response.data._meta.isValidation) {
          reject(new Error(error.response.data._error));
        } else {
          dispatch(httpErrorAC([error.response.data]));
        }
      });
  })
);


export const generatePublicInvite = ({ orgAlias, interviewId }) => {
  const apiUrl = interviewPublicInviteApiUrl(orgAlias, interviewId);
  return axios.post(apiUrl, {});
};

export const revokePublicInvite = ({ orgAlias, interviewId }) => {
  const apiUrl = interviewPublicInviteApiUrl(orgAlias, interviewId);
  return axios.delete(apiUrl);
};

export const createCustomFieldTemplateDS = ({
  orgAlias, entityType, values, authedAxios = null,
}) => (
  async () => (authedAxios || axios).post(
    customFieldsCreateTemplateApiUrl({ orgAlias, entityType }),
    values,
  ));

export const updateCustomFieldTemplateDS = ({
  orgAlias, entityType, templateId, values, authedAxios = null,
}) => (
  async () => (authedAxios || axios).put(
    customFieldsUpdateTemplateApiUrl({ orgAlias, entityType, templateId }),
    values,
  ));

export const deleteCustomFieldTemplateDS = ({
  orgAlias, entityType, templateId, authedAxios = null,
}) => (
  async () => {
    await (authedAxios || axios).delete(
      customFieldsDeleteTemplateApiUrl({ orgAlias, entityType, templateId }),
    );
  }
);

export const changeSubmissionStatusToSubmittedDS = ({ componentName, orgAlias, submissionId }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: changeSubmissionStatusToSubmittedApiUrl({ orgAlias, submissionId }),
    pushDataAC: responseData => viewUpdateAC(responseData, componentName),
  })
);
