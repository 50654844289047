import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';


function ReportFooter({ 'data-testid': dataTestId, owner }) {
  if (!owner || isEmpty(owner)) {
    return null;
  }
  const { company, user } = owner;
  const hasTaxSystemRegCompany = company && (company.own_vat_company || company.tax_system_type);

  return (
    <div data-testid={dataTestId}>
      <strong>
        {hasTaxSystemRegCompany ? company.name : user.profile.name}
      </strong>
      <br />
      {user.email}
      {company && company.website}
    </div>
  );
}

ReportFooter.propTypes = {
  'data-testid': PropTypes.string,
  owner: PropTypes.shape({
    user: PropTypes.object,
    company: PropTypes.object,
  }),
};

ReportFooter.defaultProps = {
  'data-testid': '',
  owner: null,
};

export default ReportFooter;
