import queryString from 'query-string';

import { DOCUMENT_STATUS, DOCUMENT_VIEW_TABS } from 'documents/constants';

export const documentsUrl = orgAlias => `/${orgAlias}/documents`;
export const documentCreateUrl = orgAlias => `${documentsUrl(orgAlias)}/create`;
export const documentEditUrl = (orgAlias, id) => `${documentsUrl(orgAlias)}/${id}/edit`;
export const documentViewUrl = (
  orgAlias, id, tabName = DOCUMENT_VIEW_TABS.OVERVIEW, query = null,
) => `${documentsUrl(orgAlias)}/${id}/${tabName}${query ? `?${query}` : ''}`;
export const documentNewVersionUrl = (orgAlias, id) => (
  `${documentsUrl(orgAlias)}/${id}/new-version`
);
export const documentsUploaderPath = orgId => `org-${orgId}/documents`;
export const documentsApiUrl = orgAlias => `/api/${orgAlias}/documents`;
export const documentsSearchApiUrl = ({
  orgAlias,
  term = '',
  pageSize = 100,
}) => `/api/${orgAlias}/documents?status=${DOCUMENT_STATUS.ACTIVE}&pageSize=${pageSize}&kw=${term}`;

export const documentCreateApiUrl = orgAlias => `${documentsApiUrl(orgAlias)}/create`;
export const documentViewApiUrl = (orgAlias, id) => `${documentsApiUrl(orgAlias)}/${id}`;
export const documentFileApiUrl = (orgAlias, id) => `${documentViewApiUrl(orgAlias, id)}/file`;
export const documentFileDownloadApiUrl = (orgAlias, id) => (
  `${documentViewApiUrl(orgAlias, id)}/file-download`
);
export const documentGetSignNowDocumentEditUrlApiUrl = (orgAlias, signNowDocumentId) => (
  `${documentViewApiUrl(orgAlias, signNowDocumentId)}/document-edit-url`
);
export const documentEditApiUrl = documentViewApiUrl;

export const documentPublishApiUrl = (orgAlias, id) => (
  `${documentViewApiUrl(orgAlias, id)}/publish`
);

export const documentArchiveApiUrl = (orgAlias, id) => (
  `${documentViewApiUrl(orgAlias, id)}/archive`
);

export const documentNewVersionApiUrl = (orgAlias, id) => (
  `${documentViewApiUrl(orgAlias, id)}/new-version`
);

export const documentDuplicateApiUrl = (orgAlias, id) => (
  `${documentViewApiUrl(orgAlias, id)}/duplicate`
);
export const documentOptionsApiUrl = (orgAlias, allowCountersigners) => (
  `${documentsApiUrl(orgAlias)}/options${allowCountersigners ? '?countersigners=1' : ''}`
);

// Document assignments
export const documentAssignmentViewUrl = (orgAlias, documentAssignmentId) => (
  `/${orgAlias}/document-assignments/${documentAssignmentId}`
);

export const documentAssignmentSignUrl = (orgAlias, documentAssignmentId, queries = {}) => {
  const query = Object.keys(queries).length > 0 ? `?${queryString.stringify(queries)}` : '';
  return `/${orgAlias}/document-assignments/${documentAssignmentId}/sign${query}`;
};
export const documentAssignmentSignConfirmationUrl = invitationType => (
  `/document-assignments/sign-confirmation${invitationType ? `/${invitationType}` : ''}`
);
export const documentAssignmentSignExternalUrl = token => (
  `/esign-external-sign/${token}`
);
export const documentAssignmentSignConfirmationExternalUrl = token => (
  `/esign-external-sign-confirmed/${token}`
);
export const documentAssignmentSignConfirmationInvitingManagerUrl = invitationId => (
  `/esign-inviting-manager-sign-confirmed/${invitationId}`
);

export const documentAssignmentApiUrl = (orgAlias, id) => (
  `${documentViewApiUrl(orgAlias, id)}/assignments`
);

export const aorContractSignUrl = orgAlias => (
  `/${orgAlias}/aor-contract/sign`
);
export const aorContractSignConfirmationUrl = orgAlias => (
  `/${orgAlias}/aor-contract/sign-confirmation`
);

export const documentEsignReadyView = () => '/document-esign-ready';
export const documentSignaturesRequestJobView = (orgAlias, jobId) => (
  `${documentsUrl(orgAlias)}/signatures-job/${jobId}`
);

// API endpoints

export const documentAssignmentGetApiUrl = (orgAlias, documentAssignmentId) => (
  `/api/${orgAlias}/document-assignments/${documentAssignmentId}`
);
export const documentAssignmentGetPDFCopyApiUrl = (orgAlias, documentAssignmentId) => (
  `${documentAssignmentGetApiUrl(orgAlias, documentAssignmentId)}/download`
);
export const documentAssignmentSignApiUrl = (orgAlias, documentAssignmentId) => (
  `/api/${orgAlias}/document-assignments/${documentAssignmentId}/sign`
);
export const sendSignableEnvelopeApiUrl = orgAlias => (
  `/api/${orgAlias}/aor-contract/send-envelope`
);
export const documentCreateSignNowDocumentApiUrl = orgAlias => `/api/${orgAlias}/documents/sign-now`;
export const documentCheckIfValidSignNowDocumentApiUrl = (orgAlias, signNowDocumentId) => (
  `/api/${orgAlias}/documents/sign-now/${signNowDocumentId}/valid`
);
export const documentAssignmentSignatureGetApiUrl = (
  orgAlias, documentAssignmentId, documentAssignmentSignatureId,
) => [
  `/api/${orgAlias}/document-assignments/${documentAssignmentId}/signature`,
  documentAssignmentSignatureId,
].join('/');
export const documentAssignmentSignatureGetUrlApiUrl = (
  orgAlias, documentAssignmentId, documentAssignmentSignatureId,
) => [
  documentAssignmentSignatureGetApiUrl(
    orgAlias, documentAssignmentId, documentAssignmentSignatureId,
  ),
  'url',
].join('/');
export const documentGetExternalSignUrlApiUrl = token => (
  `/api/documents/get-external-embed-url/${token}`
);
export const documentExternalSignatureApiUrl = token => (
  `/api/documents/external-signature/${token}`
);
export const documentConfirmInvitingManagerSignedApiUrl = orgAlias => (
  `/api/${orgAlias}/documents/confirm-inviting-manager-signed`
);
export const documentInvitingManagerSignatureApiUrl = invitationId => (
  `/api/documents/inviting-manager-signature/${invitationId}`
);
export const cancelDocumentAssignmentApiUrl = (orgAlias, documentAssignmentId) => (
  `/api/${orgAlias}/document-assignments/${documentAssignmentId}/cancel`
);
export const getAorInfoApiUrl = (orgAlias, userId) => `/api/${orgAlias}/aor-contract/user/${userId}`;
export const documentRequestSignaturesApiUrl = (orgAlias, documentId) => (
  `/api/${orgAlias}/documents/${documentId}/request-signatures`
);
export const documentSignaturesRequestErrorsApiUrl = (orgAlias, jobId) => (
  `/api/${orgAlias}/documents/signatures-request-errors/${jobId}`
);
export const resendCountersignerEmailApiUrl = (orgAlias, signatureId) => (
  `/api/${orgAlias}/documents/resend-countersigner-email/${signatureId}`
);
export const getAorContractFile = (orgAlias, aorContractId) => (
  `/api/${orgAlias}/aor-contract/${aorContractId}/download`
);
