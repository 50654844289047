import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import PieChartSkeleton from 'core/assets/js/components/Skeleton/PieChartSkeleton.jsx';
import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';

const ProjectDistributionWidgetSkeleton = ({ header }) => (
  <Card>
    <Card.Header>
      <div className="d-flex align-items-center">
        <span className="d-inline-block m-0 p-0">{header}</span>
        <span className="ml-auto">
          <RectangleSkeleton width="70" height="20" />
        </span>
      </div>
    </Card.Header>
    <Card.Body>
      <div className="pie-chart-container row align-items-center justify-content-around">
        <div className="col-12 col-sm-6 justif-content-center">
          <PieChartSkeleton diameterOuterCircle="120" />
        </div>
        <div className="col-12 col-sm-6">
          <div className="pie-chart-info-table">
            <AllowAccessToManager>
              <span><b>Managers</b></span>
              <div className="d-flex justify-content-between">
                <div>
                  <CircleSkeleton diameter="13" />
                  <RectangleSkeleton height="10" width="40" />
                </div>
                <div>
                  <RectangleSkeleton height="10" width="30" />
                </div>
              </div>
              <div className="mb-3 d-flex justify-content-between">
                <div>
                  <CircleSkeleton diameter="13" />
                  <RectangleSkeleton height="10" width="40" />
                </div>
                <div>
                  <RectangleSkeleton height="10" width="30" />
                </div>
              </div>
              <span><b>Providers</b></span>
            </AllowAccessToManager>
            <div className="d-flex justify-content-between">
              <div>
                <CircleSkeleton diameter="13" />
                <RectangleSkeleton height="10" width="40" />
              </div>
              <div>
                <RectangleSkeleton height="10" width="30" />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <CircleSkeleton diameter="13" />
                <RectangleSkeleton height="10" width="40" />
              </div>
              <div>
                <RectangleSkeleton height="10" width="30" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card.Body>
  </Card>
);

ProjectDistributionWidgetSkeleton.propTypes = {
  header: PropTypes.string.isRequired,
};

export default ProjectDistributionWidgetSkeleton;
