import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { noteSpec } from 'people/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import PeopleReviewsNotesTabSkeleton from 'core/assets/js/components/Skeleton/PeopleReviewsNotesTabSkeleton.jsx';
import NoteList from 'people/assets/js/components/NoteList.jsx';
import { fetchNotesDS, editNoteDS, deleteNoteDS } from 'people/assets/js/ducks/people';

class PeopleNotesTab extends React.PureComponent {
  static FetchData({ dispatch, params, url, authedAxios, hasOrgAccess }) {
    const hasNotes = hasOrgAccess({ requireManager: true });
    if (hasNotes) {
      return dispatch(fetchNotesDS(url, params, authedAxios));
    }
    return Promise.resolve();
  }

  static GetComponentName() {
    return 'PeopleNotesTab';
  }

  render() {
    const { viewerId, notes, dispatch, params } = this.props;

    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
        skeletonComponent={PeopleReviewsNotesTabSkeleton}
      >
        <div className="tab-content w-100 p-4">
          <NoteList
            notes={notes}
            viewerId={viewerId}
            onEditNote={values => dispatch(editNoteDS(params, values))}
            onDeleteNote={id => dispatch(deleteNoteDS(id, params))}
          />
        </div>
      </TDApiConnected>
    );
  }
}

PeopleNotesTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(noteSpec).isRequired,
  params: PropTypes.object,
  viewerId: PropTypes.number.isRequired,

};

PeopleNotesTab.defaultProps = {
  params: {},
};

const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, PeopleNotesTab.GetComponentName());
  const { item: userCard, item: { user } } = viewState;

  return {
    notes: state.people.noteList.items,
    params: props.match.params,
    user,
    userCard,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PeopleNotesTabConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleNotesTab);

export default withRouter(PeopleNotesTabConnect);
