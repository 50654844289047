import { LAYOUTS } from 'core/assets/js/constants';
import AMLCheckView from 'organizations/assets/js/AMLCheckView.jsx';
import { amlUrl, organizationsUrl } from 'organizations/urls';

// Views
import OrganizationsView from 'organizations/assets/js/OrganizationsView.jsx';

const organizationsRouteConfig = [
  {
    path: organizationsUrl(':orgAlias'),
    requireUserIsApprovedOrgMember: false,
    requireAuth: true,
    component: OrganizationsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    component: AMLCheckView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: amlUrl(':orgAlias'),
  },
];

export default organizationsRouteConfig;
