import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import urlLib from 'url';

import ProfileSocialForm from 'accounts/assets/js/components/ProfileSocialForm.jsx';
import { postProfileSocialDS } from 'accounts/assets/js/data-services/profiles';
import ProfileSettingsView from 'accounts/assets/js/ProfileSettingsView.jsx';
import { addPrefixToUrl } from 'accounts/assets/js/lib/helpers';
import { SOCIAL_PROFILES_INFO } from 'accounts/assets/js/constants';

class ProfileSocialTab extends React.Component {
  static GetComponentName() {
    return 'ProfileSocialTab';
  }

  static getFormInitialValues(socialProfiles) {
    const formInitialValues = {};
    if (socialProfiles) {
      Object.keys(socialProfiles).forEach((profile) => {
        if (socialProfiles[profile]) {
          const pathName = SOCIAL_PROFILES_INFO[profile].profileUrlPrefix
            ? urlLib.parse(socialProfiles[profile]).pathname
            : socialProfiles[profile];
          formInitialValues[profile] = pathName.replace(/^\//, '');
        } else {
          formInitialValues[profile] = '';
        }
      });
    }
    return formInitialValues;
  }

  constructor(props) {
    super(props);
    this.handleSocialProfilesUpdated = this.handleSocialProfilesUpdated.bind(this);
  }

  handleSocialProfilesUpdated(socialProfiles) {
    const { dispatch } = this.props;
    const componentName = ProfileSettingsView.GetComponentName();
    const prefixedFormValues = {};
    if (socialProfiles) {
      Object.keys(socialProfiles).forEach((profile) => {
        if (socialProfiles[profile]) {
          prefixedFormValues[profile] = addPrefixToUrl(
            SOCIAL_PROFILES_INFO[profile].profileUrlPrefix,
            socialProfiles[profile],
          );
        } else {
          prefixedFormValues[profile] = '';
        }
      });
    }
    return dispatch(
      postProfileSocialDS(prefixedFormValues, componentName),
    ).then(() => {
      toastr.success('Well Done!', 'Your social profiles updated successfully.');
    });
  }

  render() {
    const { profile } = this.props;
    const socialProfilesInitialValues = ProfileSocialTab.getFormInitialValues(
      profile.social_profiles,
    );
    return (
      <div className="rounded shadow-sm p-4 bg-white">
        <div className="page--settings__content clearfix">
          <ProfileSocialForm
            onSubmit={this.handleSocialProfilesUpdated}
            initialValues={socialProfilesInitialValues}
          />
        </div>
      </div>
    );
  }
}

ProfileSocialTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

ProfileSocialTab.defaultProps = {
  profile: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProfileSocialTabConnected = connect(
  null,
  mapDispatchToProps,
)(ProfileSocialTab);

export default ProfileSocialTabConnected;
