/* eslint import/prefer-default-export: off */
import * as Sentry from '@sentry/react';
import { SENTRY } from 'core/assets/js/config/settings';


/**
 * Set user context submitted to Sentry every time an error is reported
 *
 * Invoked in two cases:
 * - Every time the app loads (see core/assets/js/launch-app.js)
 * - When the user logs in or signs up (see core/assets/js/data-services/account.js)
 * @param isAuthenticated
 * @param profile
 */
export const setSentryContext = ({
  isAuthenticated = false, profile = {},
} = {}) => {
  if (!SENTRY.enabled || typeof Sentry === 'undefined') {
    return false;
  }

  if (isAuthenticated && profile.userId) {
    Sentry.setUser({ id: profile.userId });
  }

  return true;
};
