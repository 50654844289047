import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { INVOICING_MODE } from 'finance/assets/js/constants';
import IntegrationCreateSupplierModal from 'integrations/assets/js/components/IntegrationCreateSupplierModal.jsx';
import IntegrationContractorsMappingSettings from 'integrations/assets/js/components/settings/IntegrationContractorsMappingSettings.jsx';
import IntegrationTalentDeskMappingSettings from 'integrations/assets/js/components/settings/IntegrationTalentDeskMappingSettings.jsx';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const IntegrationSupplierMapping = ({ activeOrg, integration }) => (
  <div className="rounded shadow-sm p-4 bg-white">
    <h3 className="heading-block">TalentDesk Company</h3>
    <IntegrationTalentDeskMappingSettings integration={integration} />
    {activeOrg.invoicing_mode !== INVOICING_MODE.VIA && (
      /*
        In via mode, only outbound invoices are synced to accounting packages. So there is
        no need to map contractors
      */
      <>
        <h3 className="heading-block">Contractors mapping</h3>
        <IntegrationContractorsMappingSettings integration={integration} />
      </>
    )}
    <IntegrationCreateSupplierModal integration={integration} />
  </div>
);

IntegrationSupplierMapping.GetComponentName = () => 'IntegrationSupplierMapping';

IntegrationSupplierMapping.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  integration: integrationSpec.isRequired,
};

const mapStateToProps = state => ({ activeOrg: selectActiveOrg(state) });

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationSupplierMappingConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationSupplierMapping);

export default IntegrationSupplierMappingConnected;
