import React from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';

const ParseBool = ({ children }) => {
  return typeof children === 'boolean'
    ? <span className={ICON[children ? 'CHECKMARK' : 'CROSS']} />
    : <>{children || '-'}</>;
};

ParseBool.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default ParseBool;
