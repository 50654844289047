/* globals Image */
import React from 'react';

import { DOCUMENT_CREATE_ELEMENT } from 'core/assets/js/config/settings';
import { FILE_PREVIEW_MODAL_ID, ICON } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  documentAssignmentGetPDFCopyApiUrl, documentAssignmentSignUrl, documentAssignmentViewUrl,
} from 'documents/urls';

/**
 * Replace "/n" with <br> html tag
 *
 * Each line is placed inside a span element
 *
 * @param text
 * @returns {any[]}
 */
export function lineBreakToBr(text, lineClass = '') {
  return (
    (text || '').replace(/(?:\r\n|\r|\n)/g, '\n').split('\n').map((item, index) => (
      <React.Fragment key={`${lineClass}${index}`}>
        <span>{item}</span>
        <br />
      </React.Fragment>
    ))
  );
}

/**
 * Render an array of errors in a list
 *
 * @param errors
 * @returns {*}
 */
export function renderErrors(errors) {
  const errorList = errors.map((err, idx) => (
    <li key={`err-${idx}`}>
      <strong>{` > ${err.message}`}</strong>
      <pre>{err.stack}</pre>
    </li>
  ));

  if (!errorList.length) {
    errorList.push(<li key={-1}><h2>No errors found</h2></li>);
  }

  return (
    <ul className="mb-5">{errorList}</ul>
  );
}

export const expandColumnComponent = opts => (
  <i className={opts.isExpanded ? ICON.CHEVRON_DOWN : ICON.CHEVRON_RIGHT} />
);

/**
 * Concatenates a list of nodes with a given separator
 *
 * @param {Array} nodes
 * @param {String} separator
 *
 * @returns {Array}
 */
export const concatNodes = (nodes, separator = ', ') => {
  if (!Array.isArray(nodes)) {
    throw new Error('A list of React nodes is required');
  }

  return nodes.reduce((a, b) => {
    if (a.length > 0) {
      a.push(separator);
    }

    a.push(b);

    return a;
  }, []);
};

/**
 * Adds 'resize' in the url to reduce the final size of the filestack image
 * https://www.filestack.com/docs/api/processing/#image-transformations
 *
 * @param url
 * @param size
 */
export const addResizeToUrl = (url, size = 200) => {
  // check that the url used is one of filestack instead of e.g our default image
  if (typeof url === 'string' && url.match(/filestack/)) {
    // setting twice the size requested for clarity reasons
    const resizeSize = Math.max(Math.ceil(size / 100) * 100, 300);
    return url.replace('.com/', `.com/resize=width:${resizeSize}/`);
  }
  return url;
};

/**
 * The action to take when viewing a document assignment
 *
 * @param {String} orgAlias
 * @param {Object} assignment
 * @param {Object} history
 * @param {String} from
 * @returns {Void}
 */
export const viewDocumentAssignment = (orgAlias, assignment, history, from, dispatch) => {
  if (assignment.allowedActions?.canSign) {
    history.push({ pathname: documentAssignmentSignUrl(orgAlias, assignment.id), state: { from } });
    return;
  }
  if (assignment.document?.signNowDocumentId) {
    dispatch(modalOpenAC(FILE_PREVIEW_MODAL_ID, {
      heading: assignment.document.title,
      url: documentAssignmentGetPDFCopyApiUrl(orgAlias, assignment.id),
    }));
    return;
  }
  history.push({ pathname: documentAssignmentViewUrl(orgAlias, assignment.id), state: { from } });
};

/**
 * Returns a blob of a cropped version of a supplied image
 *
 * @param {String} imageToCropDataUrl
 * @param {Object} pixelCrop
 * @param {Number} pixelCrop.height
 * @param {Number} pixelCrop.width
 * @param {Number} pixelCrop.x
 * @param {Number} pixelCrop.y
 * @param {Object} [options]
 * @param {Number} [options.maxHeight]
 * @param {Number} [options.maxWidth]
 * @returns {Promise<Blob>}
 */
export const cropImage = async (
  imageToCropDataUrl,
  pixelCrop,
  { maxHeight: maxHeightIn = null, maxWidth: maxWidthIn = null } = {},
) => {
  const maxHeight = maxHeightIn || maxWidthIn;
  const maxWidth = maxWidthIn || maxHeightIn;

  // First turn the image data url into an Image instance
  const image = await new Promise((resolve, reject) => {
    const imageInstance = new Image();
    imageInstance.addEventListener('load', () => resolve(imageInstance));
    imageInstance.addEventListener('error', reject);
    imageInstance.src = imageToCropDataUrl;
  });

  // Draw the image on an html canvas
  const canvas = DOCUMENT_CREATE_ELEMENT('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return null;
  }
  canvas.width = image.width;
  canvas.height = image.height;
  ctx.drawImage(image, 0, 0);

  // Draw the image canvas on a cropped html canvas
  let croppedCanvas = DOCUMENT_CREATE_ELEMENT('canvas');
  const croppedCtx = croppedCanvas.getContext('2d');
  if (!croppedCtx) {
    return null;
  }
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  if (maxWidth && (maxWidth > pixelCrop.width || maxHeight > pixelCrop.height)) {
    const resizedCanvas = DOCUMENT_CREATE_ELEMENT('canvas');
    const resizedCtx = resizedCanvas.getContext('2d');
    if (!resizedCtx) {
      return null;
    }
    resizedCanvas.width = maxWidth;
    resizedCanvas.height = maxHeight;
    resizedCtx.drawImage(croppedCanvas, 0, 0, maxWidth, maxHeight);
    croppedCanvas = resizedCanvas;
  }

  // Get the cropped image canvas as a blob
  const croppedImageBlob = await new Promise(resolve => {
    croppedCanvas.toBlob(
      blob => {
        resolve(blob);
      },
      'image/jpeg',
    );
  });
  return croppedImageBlob;
};
