import { isEmpty, isEqual } from 'lodash';

import { DEFAULT_BALANCE_CURRENCY } from 'finance/assets/js/constants';
import Money from 'finance/assets/js/lib/Money';


/**
 * This class is used to calculate the 1099 charges applied to an organization.
 * While the Ten99FeeScheme is the record of the service settings,
 * the Ten99FeeAnalysis is the actual calculated charges.
 */
class Ten99FeeAnalysis {
  static getEmptyAnalysis() {
    return new Ten99FeeAnalysis();
  }

  /**
   * It is used for de-serializing a Ten99FeeAnalysis serialized object
   *
   * @param {Object} serialized
   *
   * @returns {Ten99FeeAnalysis}
   */
  constructor(...serialized) {
    if (isEmpty(serialized) || serialized.every(s => isEmpty(s))) {
      return this.init();
    }
    return this.init(...serialized);
  }

  /**
   *
   * @param {Object} params
   * @param {String} params.currency - 1099 service currency
   * @param {Number} params.basePrice - Price which will be charged for 1099 usage
   * @param {Boolean} params.hasBeenEnabled - Check if 1099 service was enabled
   * @returns {Ten99FeeAnalysis}
   */
  init({
    currency = DEFAULT_BALANCE_CURRENCY,
    basePrice = '0.00',
    hasBeenEnabled = false,
    numTen99Filings = 0,
    total,
  } = {}) {
    this.details = {
      currency,
      basePrice,
      hasBeenEnabled,
      numTen99Filings,
    };

    Object.assign(this.details, {
      total: total || this.aggregate(),
    });
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  aggregate() {
    const {
      basePrice,
      hasBeenEnabled,
      currency,
      numTen99Filings,
    } = this.details;
    if (!hasBeenEnabled) {
      return '0.00';
    }
    const fee = new Money(basePrice, currency).mul(numTen99Filings);
    return fee.toString();
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getTotal() {
    return this.aggregate();
  }

  getNumFilings() {
    const { numTen99Filings } = this.details;
    return numTen99Filings || 0;
  }

  /**
   * Compares the analysis passed with the current one
   *
   * @param {Ten99FeeAnalysis} analysisToCompareWith
   * @returns {boolean}
   */
  isEqual(analysisToCompareWith) {
    return isEqual(this, analysisToCompareWith);
  }

  /**
   * Checks if the total calculated as the analysis total is zero
   *
   * @returns {Boolean}
   */
  isZero() {
    return new Money(this.getTotal(), this.getCurrency()).isZero();
  }

  /**
   * Checks if an analysis is empty
   *
   * @returns {Boolean}
   */
  isEmpty() {
    const emptyAnalysis = Ten99FeeAnalysis.getEmptyAnalysis();
    return this.isEqual(emptyAnalysis);
  }

  /**
   * Returns whether the service has been enabled
   *
   * @returns {Boolean}
   */
  isEnabled() {
    const { hasBeenEnabled } = this.details;
    return hasBeenEnabled;
  }

  /**
   * @returns {String}
   */
  getServiceTitle() {
    return `1099 filings (${this.getNumFilings()})`;
  }

  /**
   * @returns {String}
   */
  getServiceMetricsDescriptions() {
    return [{
      description: '1099 submissions',
      fee: this.getTotal(),
      quantity: this.getNumFilings(),
    }];
  }
}

export default Ten99FeeAnalysis;
