import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, kebabCase } from 'lodash';
import BillingPeriodWidget from 'finance/assets/js/components/BillingPeriodWidget.jsx';
import { DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';


function InvoiceDetails({ invoice, firstBillable, lastBillable }) {
  if (!invoice || isEmpty(invoice)) {
    return null;
  }

  const billingPeriod = invoice.billingPeriod && (
    <BillingPeriodWidget
      period={invoice.billingPeriod}
      endDate={invoice.periodEnd}
      startDate={invoice.periodStart}
      id={invoice.id}
      dateFormat={DATE_FORMAT_DEFAULT}
      firstBillable={firstBillable}
      lastBillable={lastBillable}
    />
  );

  const rows = [
    { label: 'Invoice no', value: invoice.number },
    { label: 'Date', value: invoice.date },
    { label: 'Billing Period', value: billingPeriod },
  ];

  const children = rows.map((r) => {
    const key = kebabCase(r.label);
    return (
      <div key={key}>
        <label htmlFor={`worksheet-details-${key}`}>{`${r.label}:`}</label>
        <span id={`worksheet-details-${key}`}>{r.value}</span>
      </div>
    );
  });
  return (
    <div className="invoice-details" data-testid="invoice-details">
      {children}
    </div>
  );
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  firstBillable: PropTypes.object,
  lastBillable: PropTypes.object,
};

InvoiceDetails.defaultProps = {
  invoice: {},
  firstBillable: null,
  lastBillable: null,
};

export default InvoiceDetails;
