import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { SETTINGS_SKILL_TABS } from 'settings/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ManageSkillsTabs from 'settings/assets/js/components/ManageSkillsTabs.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import ManageSkillTabContent from 'settings/assets/js/components/ManageSkillTabContent.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { ORG_SKILL_MODAL_ID } from 'settings/assets/js/components/OrgSkillModalForm.jsx';
import { ORG_SKILL_DIRECTORY_MODAL_ID } from 'settings/assets/js/components/OrgSkillDirectoryModalForm.jsx';

function OrgManageSkillsView({ match: { params: { tab } } }) {
  const dispatch = useDispatch();

  const breadcrumbs = [
    {
      title: 'Custom skills',
      url: null,
    },
  ];

  let ctaComponent = null;
  if (tab === SETTINGS_SKILL_TABS.SKILLS) {
    ctaComponent = (
      <TDButton
        variant={BS_STYLE.PRIMARY}
        onClick={() => dispatch(modalOpenAC(ORG_SKILL_MODAL_ID))}
        label="Create skills"
      />
    );
  } else if (tab === SETTINGS_SKILL_TABS.SKILL_DIRECTORIES) {
    ctaComponent = (
      <TDButton
        variant={BS_STYLE.PRIMARY}
        onClick={() => dispatch(modalOpenAC(ORG_SKILL_DIRECTORY_MODAL_ID))}
        label="Create skill directory"
      />
    );
  }

  return (
    <React.Fragment>
      <ContentHeader
        breadcrumbs={breadcrumbs}
        ctaComponent={ctaComponent}
      >
        <ManageSkillsTabs tab={tab} />
      </ContentHeader>
      <div className="page page--settings">
        <div className="container">
          <ManageSkillTabContent tab={tab} />
        </div>
      </div>
    </React.Fragment>
  );
}

OrgManageSkillsView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(OrgManageSkillsView);
