import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import { getPendingInvitations } from 'core/assets/js/ducks/pendingInvitations';
import { invitationManageUrl } from 'invitations/urls';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { getRequestState } from 'core/assets/js/ducks/requests';
import { COMPONENT_NAME as PENDING_INVITATIONS_COMPONENT_NAME } from 'core/assets/js/components/PendingInvitationsProvider.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';

const withPendingInvitationCheck = (WrappedComponent) => {
  const _withPendingInvitationCheck = ({
    match,
    orgInvitations,
    projectInvitations,
    invitationsRequestState,
    history,
    ...passThroughProps
  }) => {
    // Get the state of the request responsible for fetching the pending invitations.
    // We shouldn't proceed with rendering if this request is still in progress
    const { hasLoaded: hasLoadedInvitations } = invitationsRequestState;

    // Don't render unless we have the pending invitations in place
    // The reason for that is that they're loaded through an independent provider
    // in the CoreRoute component and it's not possible to block loading in order to
    // load the pending invitations
    if (hasLoadedInvitations) {
      // We're going to check
      //  a) whether there are pending organization invites for the current org alias
      //  b) same for pending project invites for the current project id
      //  c) same for pending task invites for the current task id
      const checks = [
        { param: 'orgAlias', invitations: orgInvitations },
        { param: 'id', invitations: projectInvitations },
      ];

      // Get the list of pending invitations that are related to the current url,
      // ordered from the least to the most specific ones
      const pendingInvitations = checks.map(({ param, invitations }) => {
        const { params: { [param]: paramValue } } = match;
        const { [paramValue]: invitationList } = invitations;

        return invitationList || [];
      }).flat();

      // Get the full path from the history object instead of the match.
      // Since we're dealing with nested components, the match might not represent the entire url
      // and result in an additional redirection once we try to return to the referring URL
      const { location: { pathname: currentUrl } } = history;

      // The list of pending invitations now contains any a) organization invitations for the
      // current organization alias and b) any project invitations that are still pending for the
      // user
      const [pendingInvitation] = !isEmpty(pendingInvitations) ? pendingInvitations : [];

      if (pendingInvitation && pendingInvitation.token) {
        return (
          <RedirectRoute
            status={302}
            from={currentUrl}
            to={invitationManageUrl(pendingInvitation.token)}
            next={currentUrl}
          />
        );
      }
    }

    return (
      <WrappedComponent
        {...passThroughProps}
      />
    );
  };

  _withPendingInvitationCheck.propTypes = {
    orgInvitations: PropTypes.object,
    projectInvitations: PropTypes.object,
    history: routerHistorySpec.isRequired,
    match: routerMatchContentsSpec.isRequired,
    invitationsRequestState: PropTypes.object,
  };

  _withPendingInvitationCheck.defaultProps = {
    orgInvitations: {},
    projectInvitations: {},
    invitationsRequestState: {},
  };

  const mapStateToProps = state => ({
    orgInvitations: getPendingInvitations(state, 'organization'),
    projectInvitations: getPendingInvitations(state, 'project'),
    invitationsRequestState: getRequestState(state, PENDING_INVITATIONS_COMPONENT_NAME),
  });

  const mapDispatchToProps = dispatch => ({
    dispatch,
  });

  const _withPendingInvitationCheckConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(_withPendingInvitationCheck);

  return withRouter(_withPendingInvitationCheckConnected);
};

withPendingInvitationCheck.propTypes = {
  pendingInvitationsKey: PropTypes.string.isRequired,
};

export default withPendingInvitationCheck;
