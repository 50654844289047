import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import IntegrationTypeModal from 'integrations/assets/js/components/IntegrationTypeModal.jsx';
import IntegrationCard from 'integrations/assets/js/components/IntegrationCard.jsx';
import AvailableIntegrationCard from 'integrations/assets/js/components/AvailableIntegrationCard.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { browserRedirect } from 'core/assets/js/lib/utils';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import {
  addIntegrationTypeDS, fetchIntegrationListDS, syncIntegrationStatus,
} from 'integrations/assets/js/data-services/list';
import { toggleIntegrationActiveDS } from 'integrations/assets/js/data-services/view';
import { listUpdateItemAC, getListState, getListStateExtras } from 'core/assets/js/ducks/list';

class IntegrationsListView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    const { orgAlias } = params;
    return Promise.all([
      dispatch(fetchIntegrationListDS({
        url, querystring, componentName, orgAlias, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'IntegrationsListView';
  }

  constructor(props) {
    super(props);
    this.state = {
      externalIntegrationModal: {
        isOpen: false,
        heading: '',
        body: '',
      },
    };

    this.handleAddIntegrationType = this.handleAddIntegrationType.bind(this);
    this.showExternalIntegrationModal = this.showExternalIntegrationModal.bind(this);
    this.closeExternalIntegrationModal = this.closeExternalIntegrationModal.bind(this);
    this.handleIntegrationActiveToggle = this.handleIntegrationActiveToggle.bind(this);
  }

  async handleAddIntegrationType(integrationType) {
    const { dispatch, match: { params: { orgAlias } } } = this.props;

    const values = await dispatch(
      addIntegrationTypeDS({
        componentName: 'IntegrationsListView',
        orgAlias,
        integrationType: integrationType.type,
      }),
    );

    browserRedirect(values.redirectUrl);
  }

  async handleIntegrationActiveToggle(integrationId) {
    const { dispatch, match: { params: { orgAlias } } } = this.props;
    try {
      const { data: updated } = await toggleIntegrationActiveDS({ orgAlias, integrationId });
      dispatch(listUpdateItemAC(integrationId, updated, this.constructor.GetComponentName()));
      toastr.success('Well Done!',
        updated.isActive
          ? 'The integration was activated successfully'
          : 'The integration was deactivated successfully',
      );
    } catch (err) {
      toastr.error('Oh Snap!', err._error || err.message);
    }
  }

  async handleIntegrationCheckStatus(integration) {
    const { dispatch, match: { params: { orgAlias } } } = this.props;
    const componentName = this.constructor.GetComponentName();
    try {
      const updatedIntegration = await dispatch(syncIntegrationStatus({
        componentName, integrationId: integration.id, orgAlias,
      }));
      toastr.success(
        'Well Done!',
        integration.status !== updatedIntegration.status
          ? "The integration's status has been updated"
          : "The integration's status has not changed",
      );
    } catch (err) {
      toastr.error('Oh Snap!', err._error || err.message);
    }
  }

  showExternalIntegrationModal(integrationType) {
    const body = (
      <IntegrationTypeModal
        integrationType={integrationType}
        handleAddIntegrationType={this.handleAddIntegrationType}
        onClose={this.closeExternalIntegrationModal}
      />
    );
    this.setState({
      externalIntegrationModal: {
        isOpen: true,
        heading: '',
        body,
      },
    });
  }

  closeExternalIntegrationModal() {
    const { externalIntegrationModal } = this.state;

    this.setState({
      externalIntegrationModal: { ...externalIntegrationModal, isOpen: false },
    });
  }

  render() {
    const {
      integrations,
      available,
      history,
      location,
      match: { params: { orgAlias } },
    } = this.props;

    const breadcrumbs = [
      { title: 'Integrations', url: null },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page">
          <div className="container">
            <TDApiConnected duck="list" component={this.constructor} skeletonComponent={() => null}>
              <h2>My integrations</h2>
              <div className="integration-cards-container row">
                {integrations.length > 0 && integrations.map(integration => (
                  <IntegrationCard
                    key={`available-integration-${integration.type}`}
                    integration={integration}
                    location={location}
                    history={history}
                    orgAlias={orgAlias}
                    onCheckStatus={() => this.handleIntegrationCheckStatus(integration)}
                    onToggle={() => this.handleIntegrationActiveToggle(integration.id)}
                  />
                ))}
              </div>
              {!integrations.length && (
                <p>No integrations enabled.</p>
              )}
              {available.length > 0 && (
                <React.Fragment>
                  <h2>Available integrations</h2>
                  <div className="integration-cards-container available row">
                    {available.map(config => (
                      <AvailableIntegrationCard
                        key={`integration-card-available-${config.type}`}
                        config={config}
                        orgAlias={orgAlias}
                        location={location}
                        history={history}
                      />
                    ))}
                  </div>
                </React.Fragment>
              )}
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

IntegrationsListView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: routerMatchContentsSpec.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.object),
  available: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
};

IntegrationsListView.defaultProps = {
  integrations: [],
  available: [],
};

const mapStateToProps = (state) => {
  const componentName = IntegrationsListView.GetComponentName();
  const listState = getListState(state, componentName);

  return {
    integrations: listState.items,
    available: getListStateExtras(state, componentName, 'available'),
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationsListConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationsListView);

export default withRouter(IntegrationsListConnected);
