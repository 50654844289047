import React from 'react';
import { ANCHOR_RIGHT } from 'react-dates/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { isArray, pick, startCase } from 'lodash';
import queryString from 'query-string';

import { countryOptions } from 'core/assets/js/lib/isoCountries';
import { languageOptions } from 'core/assets/js/lib/isoLanguages';
import { parseQueryArrayIds } from 'core/assets/js/lib/utils';
import {
  INCORPORATION_TYPE,
  INCORPORATION_TYPE_LABEL,
  USER_EMPLOYMENT_TYPE,
  USER_EMPLOYMENT_TYPE_LABEL,
  PEOPLE_DEFAULT_ORDERING,
  PEOPLE_TYPE,
} from 'people/assets/js/constants';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TagsFilterField from 'core/assets/js/components/FinalFormFilterFields/TagsFilterField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import SkillsFilterField from 'core/assets/js/components/FinalFormFilterFields/SkillsFilterField.jsx';
import RatingFilterField from 'core/assets/js/components/FinalFormFilterFields/RatingFilterField.jsx';
import RangeFilterField from 'core/assets/js/components/FinalFormFilterFields/RangeFilterField.jsx';
import DateRangeFilterField from 'core/assets/js/components/FinalFormFilterFields/DateRangeFilterField.jsx';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import { CURRENCY_SYMBOL, USER_CARD_STATUS, USER_CARD_STATUS_LABEL, ICON } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import { SKILL_IDS_MATCH } from 'skills/assets/js/constants';

const defaultOrdering = PEOPLE_DEFAULT_ORDERING;

class PeopleSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location, query } = this.props;
    let values;

    const searchSpec = this.getSearchSpec();

    const filtersList = [];
    searchSpec.filters.forEach((f) => {
      // Add all paramNames if is array
      if (isArray(f.paramName)) {
        f.paramName.forEach(paramName => filtersList.push(paramName));
      }
      filtersList.push(f.paramName);
    });

    if (query) {
      values = query;
    } else {
      values = queryString.parse(location.search);
    }
    values = pick(values, ['kw', 'ordering', 'skillIdsMatch'].concat(filtersList));
    values.skillIds = parseQueryArrayIds(values.skillIds).map(skillId => ({ value: skillId }));
    values.skillIdsMatch = values.skillIdsMatch || SKILL_IDS_MATCH.OR;

    // Set default ordering value
    if (!values.ordering) {
      values.ordering = JSON.stringify(PEOPLE_DEFAULT_ORDERING);
    }

    return values;
  }

  getSearchSpec() {
    // Prepare Status filter options
    const statusOptions = Object.keys(USER_CARD_STATUS).map(status => ({
      text: USER_CARD_STATUS_LABEL[USER_CARD_STATUS[status]],
      value: USER_CARD_STATUS[status],
    }));
    const employmentTypeOptions = Object.values(USER_EMPLOYMENT_TYPE).map(type => ({
      text: startCase(USER_EMPLOYMENT_TYPE_LABEL[type]),
      value: type,
    }));

    const incorporationTypeOptions = Object.values(INCORPORATION_TYPE).map(type => ({
      text: startCase(INCORPORATION_TYPE_LABEL[type]),
      value: type,
    }));
    const {
      activeOrg, customFieldFilters, hideStatus, isManager, peopleType, rateLimits, userGroupNames,
    } = this.props;

    const rateUnit = activeOrg.default_rate_unit;
    const rateUnitFormat = RATE_UNIT_FORMAT[rateUnit];
    const isCommission = rateUnit === RATE_UNIT.COMMISSION;

    const orderingOptions = [
      { text: 'Join date', value: 'joined_at' },
      { text: 'Last name', value: 'last_name' },
    ];

    let filters = [
      // Note: Ordering filter is only shown on mobile devices.
      {
        label: 'Sort By',
        paramName: 'ordering',
        fieldComponent: SelectableListFilterField,
        multiple: false,
        isOrdering: true,
        options: [
          {
            text: (
              <span>
                Join Date
                {' '}
                <i className={ICON.SORTING_ASC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'ASC',
              sortBy: 'joined_at',
            }),
          },
          {
            text: (
              <span>
                Join Date
                {' '}
                <i className={ICON.SORTING_DESC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'DESC',
              sortBy: 'joined_at',
            }),
          },
          {
            text: (
              <span>
                Last name
                {' '}
                <i className={ICON.SORTING_ALPHA_ASC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'ASC',
              sortBy: 'last_name',
            }),
          },
          {
            text: (
              <span>
                Last name
                {' '}
                <i className={ICON.SORTING_ALPHA_DESC} />
              </span>
            ),
            value: JSON.stringify({
              direction: 'DESC',
              sortBy: 'last_name',
            }),
          },
        ],
      },
      {
        label: activeOrg && rateUnit
          ? rateUnitFormat.title
          : 'Rate',
        paramName: 'rate',
        fieldComponent: props => (
          <AllowAccessToManager>
            <RangeFilterField {...props} />
          </AllowAccessToManager>
        ),
        min: rateLimits.min,
        max: rateLimits.max,
        step: rateLimits.step,
        symbol: isCommission ? '%' : CURRENCY_SYMBOL[activeOrg.currency],
      },
    ];

    if (isManager || activeOrg.should_provider_view_other_providers_review) {
      filters.push({
        label: 'Rating',
        paramName: 'rating',
        fieldComponent: RatingFilterField,
      });
    }

    filters = [
      ...filters,
      {
        label: 'Country',
        paramName: 'countryCode',
        fieldComponent: TagsFilterField,
        inputClassName: 'col-12 col-md-6',
        options: countryOptions,
      },
      {
        label: 'Language',
        paramName: 'languageCode',
        inputClassName: 'col-12 col-md-6',
        fieldComponent: TagsFilterField,
        options: languageOptions,
      },
      {
        label: 'Employment Type',
        paramName: 'employmentType',
        fieldComponent: SelectableListFilterField,
        multiple: true,
        options: employmentTypeOptions,
      },
    ];

    if (isManager && activeOrg.show_availability) {
      filters.push({
        className: 'col-12',
        fieldComponent: DateRangeFilterField,
        fieldComponentProps: { anchorDirection: ANCHOR_RIGHT },
        isOutsideRange: day => moment(day).isBefore(moment(), 'day'),
        label: 'Availability',
        paramName: 'availability',
      });
    }

    const isProviders = peopleType === PEOPLE_TYPE.PROVIDERS;
    if (isProviders) {
      filters.push({
        label: 'Legal status',
        paramName: 'isIncorporated',
        fieldComponent: SelectableListFilterField,
        multiple: true,
        options: incorporationTypeOptions,
      });
    }
    if (isManager && Array.isArray(userGroupNames) && userGroupNames.length > 0) {
      filters.push({
        label: 'Groups',
        paramName: 'groupId',
        inputClassName: 'col-12 col-md-6',
        fieldComponent: TagsFilterField,
        options: userGroupNames,
      });
    }
    if (isProviders && isManager) {
      filters.push(
        {
          fieldComponent: SelectableListFilterField,
          label: 'Invited by me',
          multiple: true,
          options: [{ text: 'Yes', value: 1 }],
          paramName: 'invitedByMe',
        },
        {
          fieldComponent: SkillsFilterField,
          label: 'Skills',
          multiple: true,
          paramName: 'skillIds',
        },
      );
    }

    if (!hideStatus) {
      filters.push({
        label: 'Status',
        paramName: 'status',
        fieldComponent: props => (
          <AllowAccessToManager>
            <SelectableListFilterField {...props} />
          </AllowAccessToManager>
        ),
        multiple: true,
        options: statusOptions,
      });
    }

    const spec = {
      customFieldFilters,
      defaultOrdering,
      orderingOptions,
      searchTerm: {
        placeholder: `Search ${peopleType || 'users'}`,
        paramName: 'kw',
        component: TextInputField,
      },
      filters,
    };

    return spec;
  }

  render() {
    const {
      extraSearchElement,
      extraSearchRow,
      filtersOpen,
      onFiltersChanged,
      onFiltersToggle,
      query,
      searchFormRef,
    } = this.props;

    return (
      <SearchFinalForm
        extraResetFilterParamNames={['skillIdsMatch']}
        extraSearchElement={extraSearchElement}
        extraSearchRow={extraSearchRow}
        filtersOpen={filtersOpen}
        formRef={searchFormRef}
        initialValues={this.getInitialValues()}
        name="people"
        onFiltersChanged={onFiltersChanged}
        onFiltersToggle={onFiltersToggle}
        parseFilters={filtersIn => {
          const filters = JSON.parse(JSON.stringify(filtersIn));
          if (Array.isArray(filters.skillIds) && filters.skillIds.length > 0) {
            filters.skillIds = filters.skillIds.map(s => s.value);
          }
          return filters;
        }}
        query={query}
        searchSpec={this.getSearchSpec()}
      />
    );
  }
}

PeopleSearch.propTypes = {
  activeOrg: orgSpec.isRequired,
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  extraSearchElement: PropTypes.element,
  extraSearchRow: PropTypes.element,
  filtersOpen: PropTypes.bool.isRequired,
  hideStatus: PropTypes.bool,
  isManager: PropTypes.bool,
  location: PropTypes.object.isRequired,
  onFiltersChanged: PropTypes.func,
  onFiltersToggle: PropTypes.func.isRequired,
  peopleType: peopleTypeSpec,
  query: PropTypes.object,
  rateLimits: PropTypes.object,
  searchFormRef: PropTypes.object,
  userGroupNames: PropTypes.array,
};

PeopleSearch.defaultProps = {
  customFieldFilters: [],
  extraSearchElement: null,
  extraSearchRow: null,
  isManager: false,
  hideStatus: false,
  onFiltersChanged: null,
  peopleType: null,
  query: null,
  rateLimits: { min: 0, max: 1500, step: 5 },
  searchFormRef: null,
  userGroupNames: [],
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});
const PeopleSearchConnect = connect(
  mapStateToProps,
)(PeopleSearch);

export default withRouter(PeopleSearchConnect);
