import { isNil, isEqual, mapValues, omit } from 'lodash';
import queryString from 'query-string';
// import Logger from 'core/assets/js/lib/Logger';

// Action types
export const GET_REFRESH = 'redux-requests/GET_REFRESH';
export const GET_PENDING = 'redux-requests/GET_PENDING';
export const GET_SUCCEEDED = 'redux-requests/GET_SUCCEEDED';
export const GET_FAILED = 'redux-requests/GET_FAILED';
export const GET_RESET = 'redux-requests/GET_RESET';
export const GET_STOP = 'redux-requests/GET_STOP';

// Reducer
export const requestInitialState = {
  initialized: false,
  isLoading: false,
  hasLoaded: false,
  httpErrorCode: null,
  historyLength: null,
  orgAlias: null,
};

const requestHasBeenTriggered = ({ initialized, isLoading, hasLoaded, httpErrorCode }) => (
  initialized && !!(isLoading || hasLoaded || httpErrorCode)
);

const requestMayRestart = requestState => (
  requestState.initialized && !requestState.isLoading
);

const actionIsStale = (state = requestInitialState, action) => {
  const { descriptor, historyLength } = action;
  const { historyLength: currentHistoryLength } = (state[descriptor] || requestInitialState);
  if (!isNil(currentHistoryLength)
    && !isNil(historyLength)
    && currentHistoryLength !== historyLength
    && currentHistoryLength > historyLength
  ) {
    return true;
  }
  return false;
};

export const initialState = {};

const requestReducer = (state = requestInitialState, action) => {
  switch (action.type) {
    case GET_REFRESH:
      if (state.hasLoaded) {
        return {
          ...state,
          initialized: true,
          isLoading: false,
          hasLoaded: false,
        };
      }
      return state;
    case GET_PENDING:
      if (!state.isLoading) {
        return {
          ...state,
          isLoading: true,
          hasLoaded: false,
          initialized: true,
          httpErrorCode: null,
        };
      }
      return state;
    case GET_SUCCEEDED:
      if (!state.initialized || state.isLoading) {
        return {
          ...state,
          isLoading: false,
          hasLoaded: true,
          initialized: true,
        };
      }
      return state;
    case GET_FAILED:
      if (state.isLoading) {
        return {
          ...state,
          isLoading: false,
          hasLoaded: true,
          httpErrorCode: action.httpErrorCode,
          initialized: true,
        };
      }
      return state;
    default:
      return state;
  }
};

export const reducer = (state = initialState, action) => {
  if (action.type
    && action.type.startsWith('redux-requests')
    && action.type !== GET_RESET
    && action.type !== GET_STOP
    && !action.descriptor
  ) {
    throw new Error(`cannot use requests duck without specifying a descriptor on action ${action.type}`);
  }
  const { descriptor, orgAlias, historyLength } = action;

  let descriptorState;

  // const logger = new Logger(`tdapiconnected:${descriptor}:requests`);
  if (actionIsStale(state, action)) {
    // do nothing when redux already has the state of a newer component
    // logger.log('stale', action.type, historyLength);
    descriptorState = state[descriptor];
  } else {
    descriptorState = requestReducer(state[descriptor], action);
    Object.assign(descriptorState, {
      requestHasBeenTriggered: requestHasBeenTriggered(descriptorState),
      requestMayRestart: requestMayRestart(descriptorState),
      historyLength,
      orgAlias,
    });
  }

  let newState = { ...state };
  switch (action.type) {
    case GET_REFRESH:
    case GET_PENDING:
    case GET_SUCCEEDED:
    case GET_FAILED:
      // logger.log(action.type, historyLength);
      newState = {
        ...state,
        [descriptor]: descriptorState,
      };
      break;
    case GET_RESET:
      // logger.log(action.type, historyLength);
      if (descriptor) {
        newState = omit(state, descriptor);
      } else {
        newState = { ...initialState };
      }
      break;
    case GET_STOP:
      // logger.log(action.type, historyLength);
      newState = mapValues(state, v => ({ ...v, isLoading: false }));
      break;
    default:
      newState = state;
      break;
  }
  if (isEqual(state, newState)) {
    return state;
  }
  // logger.log('new state');
  // logger.log(newState);
  return newState;
};

/**
 * Return the view state if the data currently saved in the store belong to the active component
 *
 * @param state
 * @param componentName
 * @returns {*}
 */
export const getRequestState = (state, descriptor) => {
  if (!descriptor) {
    throw new Error('cannot get list state without specifying component name');
  }
  if (Array.isArray(descriptor)) {
    const states = descriptor.map(c => getRequestState(state, c));
    return {
      initialized: states.every(s => s.initialized),
      isLoading: states.some(s => s.isLoading),
      hasLoaded: states.every(s => s.hasLoaded),
      httpErrorCode: states.find(s => !!s.httpErrorCode),
    };
  }
  if (!state.requests[descriptor]) {
    return requestInitialState;
  }
  return state.requests[descriptor];
};

// Action creators
export const requestRefreshAC = descriptor => ({
  type: GET_REFRESH,
  descriptor,
});

export const requestPendingAC = (descriptor, orgAlias, historyLength) => ({
  type: GET_PENDING,
  descriptor,
  historyLength,
  orgAlias,
});

export const requestSucceededAC = (descriptor, orgAlias, historyLength) => ({
  type: GET_SUCCEEDED,
  descriptor,
  historyLength,
  orgAlias,
});

export const requestFailedAC = (descriptor, httpErrorCode, orgAlias, historyLength) => ({
  type: GET_FAILED,
  descriptor,
  httpErrorCode,
  historyLength,
  orgAlias,
});

export const requestResetAC = (descriptor, orgAlias, historyLength) => ({
  type: GET_RESET,
  descriptor,
  historyLength,
  orgAlias,
});

// stops all requests (set isLoading to false)
export const requestsStopAC = () => ({
  type: GET_STOP,
});

// data services
export const fetchFromApiThunk = ({
  dispatch, authedAxios, isFirstRender, fetchData, location, match, componentName, componentProps,
  isAuthenticated, hasOrgAccess, activeOrg, query,
}) => {
  const isProjManager = hasOrgAccess({
    requireAuth: true,
    requireManager: true,
  });

  const params = { ...match.params, orgAlias: match.params.orgAlias || activeOrg.unique_alias };

  const querystring = query ? queryString.stringify(query) : location.search;
  return fetchData({
    dispatch,
    params,
    querystring,
    url: '',
    authedAxios,
    isProjManager,
    componentName,
    hasOrgAccess,
    isAuthenticated,
    isFirstRender,
    props: componentProps,
  });
};

export default reducer;
