
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { ICON, BS_STYLE, CURRENCY } from 'core/assets/js/constants';
import WorksheetFormDayFormat from 'projects/assets/js/components/WorksheetFormDayFormat.jsx';
import { PERIOD_MODAL_ID } from 'projects/assets/js/components/WorksheetFormBillingPeriodModal.jsx';
import { ADD_WORKSHEET_ITEM_MODAL_ID } from 'projects/assets/js/components/WorksheetAddItemPanel.jsx';
import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import { exchangeRatesSpec } from 'finance/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { getInvoiceCapUsage } from 'projects/assets/js/data-services/view';

const WorksheetFormBillingPeriodMenu = ({
  exchangeRates,
  formValues,
  info,
  isMobileView,
  saveChangesToLocalStorage,
  showAddButton,
  submitting,
}) => {
  const dispatch = useDispatch();
  const { usageAt100 } = useSelector(getInvoiceCapUsage);
  const activeOrg = useSelector(selectActiveOrg);
  const periodStart = formValues.period ? formValues.period.periodStart : null;
  const periodEnd = formValues.period ? formValues.period.periodEnd : null;
  const disableAddBtn = (info?.missingFields && !isEmpty(info.missingFields))
    || submitting || (usageAt100 && !activeOrg.invoice_caps_allow_raising_beyond_cap);

  const calendarClasses = [
    'd-inline-flex', 'imitate-link', 'justify-content-center',
    'align-items-center', 'border-right', 'mr-0', 'mr-md-4',
    'worksheet-form__calendar-toggle',
  ];

  const showExchangeRateSelector = exchangeRates && Object.keys(exchangeRates).length > 0;

  const handleOpenPeriodModal = () => dispatch(modalOpenAC(PERIOD_MODAL_ID));

  return (
    <React.Fragment>
      <div
        className={
          [
            'align-items-center',
            'border-bottom',
            'd-flex',
            'flex-wrap',
            'justify-content-between',
            'row mb-4',
          ].join(' ')
        }
        data-testid="worksheet-form-billing-period-menu"
      >
        <div
          className="d-flex d-md-inline-flex pr-0 pr-md-4 cursor-pointer"
        >
          <div
            role="none"
            className={calendarClasses.join(' ')}
            onClick={handleOpenPeriodModal}
          >
            <i className={ICON.CALENDAR_ALT} />
          </div>

          {!isMobileView && (
            <a
              onClick={handleOpenPeriodModal}
              className="d-inline-flex justify-content-center align-items-center"
              data-testid="worksheet-form-open-period"
            >
              { periodStart && (
                <WorksheetFormDayFormat date={periodStart} />
              )}
              <div className="px-5">
                <i className={ICON.ARROW_RIGHT_2} />
              </div>
              { periodEnd && (
                <WorksheetFormDayFormat date={periodEnd} />
              )}
            </a>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-end col-4 pr-0">
          {!isMobileView && showExchangeRateSelector && (
            <CurrencySelectField
              className="mb-0 col-10"
              currencyOptions={CURRENCY}
              isClearable={false}
              name="currency"
            />
          )}

          {showAddButton && (
            <TDElementWithTooltip
              tooltipMsg="Add task or project work"
              delay={300}
            >
              <TDButton
                data-testid="worksheet-form-add"
                type="button"
                variant={BS_STYLE.PRIMARY}
                onClick={() => dispatch(modalOpenAC(
                  ADD_WORKSHEET_ITEM_MODAL_ID,
                  { onModalClose: () => saveChangesToLocalStorage() },
                ))}
                className="worksheet-add-item-button"
                disabled={disableAddBtn}
                label={<i className={ICON.ADD} />}
              />
            </TDElementWithTooltip>
          )}
        </div>
      </div>

      {isMobileView && (
        <>
          <a
            onClick={handleOpenPeriodModal}
            className="d-flex d-sm-inline-flex justify-content-between align-items-center pt-2 mb-4"
          >
            <WorksheetFormDayFormat date={periodStart} />
            <div className="px-2 px-sm-5">
              <i className={ICON.ARROW_RIGHT_2} />
            </div>
            <WorksheetFormDayFormat date={periodEnd} />
          </a>

          {showExchangeRateSelector && (
            <div className="border-top border-bottom row d-flex pt-4 pb-4">
              <CurrencySelectField
                className="mb-0 col-12"
                currencyOptions={CURRENCY}
                isClearable={false}
                name="currency"
              />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

WorksheetFormBillingPeriodMenu.propTypes = {
  exchangeRates: exchangeRatesSpec,
  formValues: PropTypes.object.isRequired,
  info: PropTypes.object,
  isMobileView: PropTypes.bool.isRequired,
  saveChangesToLocalStorage: PropTypes.func,
  showAddButton: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
};

WorksheetFormBillingPeriodMenu.defaultProps = {
  exchangeRates: {},
  info: null,
  saveChangesToLocalStorage: () => null,
  showAddButton: true,
};

export default WorksheetFormBillingPeriodMenu;
