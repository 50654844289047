import PropTypes from 'prop-types';

export const integrationSpec = PropTypes.shape({ // eslint-disable-line import/prefer-default-export
  allowedActions: PropTypes.object,
  description: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  isConfigured: PropTypes.bool,
  isEnabled: PropTypes.bool,
  isUnique: PropTypes.bool,
  lastSync: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  statuses: PropTypes.object,
  syncInvoiceStatus: PropTypes.number,
  syncStartDate: PropTypes.string,
  syncType: PropTypes.string,
  type: PropTypes.string,
});
