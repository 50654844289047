import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { USER_TYPE, TASK_TABS } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { orgUserProfileUrl } from 'people/urls';
import {
  CANNOT_ADJUST_RATE_MODAL_ID, CHANGE_CAP_MODAL_ID, REMOVE_MODAL_ID,
} from 'projects/assets/js/components/TaskAssignmentModals.jsx';
import { MODAL_ID as RE_OPEN_MODAL_ID } from 'projects/assets/js/components/TaskReOpenModal.jsx';
import { TASK_ACTION } from 'projects/assets/js/constants';
import {
  projectTaskSpec, taskAssignmentSpec, taskAllowedActionsSpec,
} from 'projects/assets/js/lib/objectSpecs';
import { projectTaskAssignmentUrl, projectViewTaskUrl } from 'projects/urls';

const TaskAssignmentActions = ({ assignment, task: { ownerId }, taskAllowedActions }) => {
  const history = useHistory();
  const { orgAlias, id: projectId, taskId } = useParams();

  const dispatch = useDispatch();

  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);

  const {
    allowedActions: {
      canCancel,
      canProposeNewTaskRate,
      canRemoveFromTask,
      canReOpen,
      canSetTaskAssignmentCap,
    },
    user: { id: userId },
  } = assignment;

  const handleOpenUserProfile = () => history.push(
    orgUserProfileUrl(orgAlias, USER_TYPE.PROVIDER, userId),
  );

  const handleAdjustRate = () => {
    if (canProposeNewTaskRate?.value) {
      history.push(
        projectViewTaskUrl(
          orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, TASK_ACTION.PROPOSE_TASK_RATE, userId,
        ),
      );
    } else {
      dispatch(modalOpenAC(CANNOT_ADJUST_RATE_MODAL_ID, { assignment }));
    }
  };

  const {
    canAccessPublicMessages, canMessageAssignees, canMessageOwner, isManager,
  } = taskAllowedActions;

  const actions = [];
  const isAssigneeThemself = userId === activeUserCard.user.id;
  const canViewProviders = (
    isManager || activeOrg.should_provider_view_other_providers
  );

  let discussionBoardUrl = null;
  if (canMessageAssignees && !isAssigneeThemself) {
    discussionBoardUrl = projectViewTaskUrl(
      orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, null, userId,
    );
  } else if (canAccessPublicMessages) {
    discussionBoardUrl = projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION);
  } else if (canMessageOwner) {
    discussionBoardUrl = projectViewTaskUrl(
      orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, null, ownerId,
    );
  }

  if (discussionBoardUrl) {
    actions.push(
      <Dropdown.Item
        key={`view-discussionboard-${taskId}`}
        onClick={() => history.push(discussionBoardUrl)}
      >
        View task discussion
      </Dropdown.Item>,
    );
  }

  if (isAssigneeThemself || canViewProviders) {
    actions.push(
      <Dropdown.Item
        key={`view-profile-${userId}`}
        onClick={handleOpenUserProfile}
      >
        View profile
      </Dropdown.Item>,
    );
  }

  actions.push(
    <Dropdown.Item
      key={`adjust-agreed-rate-${userId}`}
      onClick={handleAdjustRate}
    >
      Adjust agreed rate
    </Dropdown.Item>,
  );


  if (canSetTaskAssignmentCap) {
    actions.push(
      <Dropdown.Item
        key={`edit-task-assignment-${userId}`}
        onClick={() => dispatch(modalOpenAC(CHANGE_CAP_MODAL_ID, { assignment }))}
      >
        Edit assignment cap
      </Dropdown.Item>,
    );
  }

  if (canReOpen) {
    actions.push(
      <Dropdown.Item
        key={`reopen-task-assignment-${userId}`}
        onClick={() => dispatch(modalOpenAC(RE_OPEN_MODAL_ID, { assignment }))}
      >
        Re-open
      </Dropdown.Item>,
    );
  }

  if (canRemoveFromTask) {
    actions.push(
      <Dropdown.Divider key={`dropdown-divider-${userId}`} />,
      <Dropdown.Item
        key={`remove-user-${userId}`}
        onClick={() => dispatch(modalOpenAC(REMOVE_MODAL_ID, { assignment }))}
        className="text-danger"
      >
        Remove from task
      </Dropdown.Item>,
    );
  }

  if (canCancel) {
    actions.push(
      <Dropdown.Divider key={`dropdown-divider-${userId}`} />,
      <Dropdown.Item
        key={`cancel-invitation-${userId}`}
        onClick={() => history.push([
          projectTaskAssignmentUrl(orgAlias, projectId, taskId, userId),
          `?action=${TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION}`,
        ].join(''))}
        className="text-danger"
      >
        Cancel invitation
      </Dropdown.Item>,
    );
  }

  if (actions.length === 0) {
    return null;
  }

  return <>{actions}</>;
};

TaskAssignmentActions.propTypes = {
  assignment: taskAssignmentSpec.isRequired,
  task: projectTaskSpec.isRequired,
  taskAllowedActions: taskAllowedActionsSpec.isRequired,
};

export default TaskAssignmentActions;
