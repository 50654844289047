/* globals FormData */
import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';

import { customFieldSpec, customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TagsInputField from 'core/assets/js/components/FinalFormFields/TagsInputField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import RateField from 'rates/assets/js/components/finalFormFields/RateField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import SkillSelectField from 'core/assets/js/components/FinalFormFields/SkillSelectField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import CustomFieldsUpdater from 'interviews/assets/js/components/CustomFieldsUpdater.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { createProjectDS, updateProjectDS } from 'projects/assets/js/data-services/form';
import { FIELD_ENTITY_TYPE, PATH_PREFIX } from 'interviews/assets/js/constants';
import { BS_STYLE, ICON, MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import {
  projectOpportunitiesUrl, opportunityViewUrl, projectListUrl, projectViewUrl,
} from 'projects/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { fetchActiveManagerBudgetDS } from 'people/assets/js/ducks/managers';
import {
  PROJECT_DOCUMENTS_ALLOWED_MIMETYPES,
  PROJECT_MEMBER_MANAGEMENT,
  PROJECT_MEMBER_MANAGEMENT_LABEL,
  PROJECT_MEMBER_MANAGEMENT_SUBLABEL,
  PROJECT_MEMBER_VIEW,
  PROJECT_MEMBER_VIEW_LABEL,
  PROJECT_MEMBER_VIEW_SUBLABEL,
  PROJECT_VISIBILITY,
  RATE_GUIDANCE_TYPE,
} from 'projects/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { RATE_UNIT_FORMAT, RATE_UNIT_SELECT } from 'rates/assets/js/constants';

const FormRenderer = ({
  activeOrg,
  allowTemplateSelection,
  canChangeVisibility,
  customFields,
  customFieldTemplates,
  enableBudgetAllocation,
  enableEditingMemberSettings,
  expandSettings,
  form,
  handleSubmit,
  history,
  initialValues,
  managerBudget,
  projectId,
  submitting,
}) => {
  const orgAlias = activeOrg.alias;
  const editingProject = !!initialValues.id;
  const isBudgetAllocEnabled = !isEmpty(managerBudget) && enableBudgetAllocation;

  let budgetSublabel = null;
  if (isBudgetAllocEnabled) {
    if (!parseFloat(managerBudget.availableBudget)) {
      budgetSublabel = (
        <React.Fragment>
          You don’t have any budget available, but you can proceed with the project
          creation without allocating a budget. You will be able to request budget once the
          project is created.
        </React.Fragment>
      );
    } else {
      budgetSublabel = (
        <React.Fragment>
          Your available budget is
          {' '}
          <NumberTpl
            prefix={managerBudget.currency}
            value={managerBudget.availableBudget}
            decimals={2}
          />
        </React.Fragment>
      );
    }
  }

  const { values } = form.getState();
  const { currency, visibility } = values;
  const rateGuideUnit = values.rate_guide_unit ? parseInt(values.rate_guide_unit, 10) : null;
  const rateUnitFormat = rateGuideUnit ? RATE_UNIT_FORMAT[rateGuideUnit] : null;
  const isOpportunity = visibility === PROJECT_VISIBILITY.ORG_ONLY;

  return (
    <React.Fragment>
      {submitting && <LoadingComponent />}
      <form onSubmit={handleSubmit} className="project-form">
        <div className="rounded shadow-sm p-4 bg-white">
          <div className="d-flex align-items-center justify-content-start mb-3">
            <i className={`${ICON.BAR_PROGRESS_DUOTONE} fa-2x mr-5 project-icon`} />
            <h4 className="font-weight-bold">Project details</h4>
          </div>
          <div className="clearfix">
            <Field type="hidden" name="id" component="input" />
            <TextInputField
              label="Title"
              name="title"
              placeholder="e.g Landing page design"
              required
              type="text"
            />
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <DatePickerField
                  disablePastDates
                  label="Deadline"
                  name="deadline"
                  sublabel="Leave this field blank in case of on-going projects"
                />
              </div>
            </div>
            <TextAreaField
              height={230}
              label="Brief"
              mdEditorEnabled
              name="brief"
              required
              sublabel="Provide a more detailed description"
            />
            <FileUploaderDirectField
              accept={PROJECT_DOCUMENTS_ALLOWED_MIMETYPES}
              allowMultiple
              label="Supported documents"
              maxFiles={MAX_UPLOAD_FILES}
              name="attachments"
            />
            <SkillSelectField
              label="Skills"
              memberSkillsOnly={false}
              modalHeading="Skills"
              name="skills"
            />
            {/* we don't allow the syncing features because the product is a binding contract */}
            <CustomFieldsUpdater
              allowTemplateSelection={allowTemplateSelection}
              enableSync={false}
              entityId={projectId}
              entityType={FIELD_ENTITY_TYPE.PROJECT}
              form={form}
              initialCustomFields={customFields}
              templateFieldLabel="Project template"
              templateFieldSublabel={(
                <p className="text-warning">
                  By selecting one or more project templates and creating a project, the relevant
                  custom fields freeze on it. You will not be able to edit a project and choose
                  other project templates.
                  <br />
                  Before you create a new project, please make sure that you have selected the right
                  project templates.
                </p>
              )}
              templates={customFieldTemplates}
            />
            <AreaCollapsible
              contentChildren={(
                <>
                  <DatePickerField
                    label="Start Date"
                    name="started_at"
                    placeholder="Choose date"
                    sublabel={(
                      'Set the start date if the project is already in progress. This will allow '
                      + 'the providers to create a worksheet with a past billing period.'
                    )}
                    timeFormat={false}
                  />
                  {isBudgetAllocEnabled && (
                    <div className="col-12 col-md-8 col-lg-4 px-0">
                      <MoneyInputField
                        decimals={2}
                        label="Budget"
                        name="allocated_budget"
                        placeholder="0.00"
                        prefix={managerBudget.currency}
                        sublabel={budgetSublabel}
                      />
                    </div>
                  )}
                  <TextInputField
                    name="external_project_id"
                    label="External project ID"
                    sublabel="Assign a project reference that is unique to your organisation"
                  />
                  <TagsInputField
                    label="Client names"
                    name="clients"
                    size="extra-large"
                  />
                  <TagsInputField
                    label="Extra tags"
                    name="tags"
                    size="extra-large"
                  />
                </>
              )}
              fieldSet
              headingChildren={<span>Advanced Options</span>}
            />
            <AreaCollapsible
              contentChildren={(
                <div className="row">
                  <div className="col-12">
                    <RadioField
                      disabled={!enableEditingMemberSettings}
                      label="Who can manage project members?"
                      name="member_management"
                      options={Object.values(PROJECT_MEMBER_MANAGEMENT).map(value => ({
                        text: (
                          <div>
                            {PROJECT_MEMBER_MANAGEMENT_LABEL[value]}
                            <div className="discreet">
                              {PROJECT_MEMBER_MANAGEMENT_SUBLABEL[value]}
                            </div>
                          </div>
                        ),
                        value,
                      }))}
                    />
                  </div>
                  <div className="col-12">
                    <RadioField
                      label="Who can access the project team page?"
                      name="member_view"
                      options={Object.values(PROJECT_MEMBER_VIEW).map(value => ({
                        text: (
                          <div>
                            {PROJECT_MEMBER_VIEW_LABEL[value]}
                            <div className="discreet">
                              {PROJECT_MEMBER_VIEW_SUBLABEL[value]}
                            </div>
                          </div>
                        ),
                        value,
                      }))}
                    />
                  </div>
                </div>
              )}
              fieldSet
              headingChildren={<span>Settings</span>}
              isExpanded={expandSettings}
            />
          </div>
        </div>
        {(isOpportunity || canChangeVisibility) && (
          <div className="rounded shadow-sm p-4 bg-white mt-5">
            <div className="d-flex align-items-center justify-content-start mb-5">
              <i className={`${ICON.LIGHTBULB_DOLLAR_DUOTONE} fa-2x mr-5 opportunity-icon`} />
              <h4 className="font-weight-bold">Opportunity details</h4>
            </div>
            {canChangeVisibility && (
              <RadioField
                label="Should this be an opportunity?"
                name="visibility"
                options={[
                  {
                    sublabel: 'Providers can apply to join the project',
                    text: 'Yes',
                    value: PROJECT_VISIBILITY.ORG_ONLY,
                  },
                  {
                    sublabel: 'Providers can only be invited to join the project',
                    text: 'No',
                    value: PROJECT_VISIBILITY.INVITATION_ONLY,
                  },
                ]}
              />
            )}
            {isOpportunity && (
              <>
                <RadioField
                  label="Who can see this opportunity?"
                  name="opportunity_is_invitations_only"
                  options={[
                    {
                      sublabel: (
                        'All your organizations providers can view and apply to this'
                          + ' opportunity'
                      ),
                      text: 'Organization providers',
                      value: false,
                    },
                    {
                      sublabel: (
                        'Only your invited providers can view and apply to this '
                          + 'opportunity'
                      ),
                      text: 'Invited only',
                      value: true,
                    },
                  ]}
                />
                <b>Provider rate guidance</b>
                <br />
                <div className="hint mb-5">
                  Set rate guidance for Providers to use when applying for this
                  opportunity
                </div>
                <SelectField
                  label="Rate unit"
                  name="rate_guide_unit"
                  optionsMapping={RATE_UNIT_SELECT}
                />
                <div className="mb-5">Rate range guide</div>
                <RadioField
                  name="rateGuidanceType"
                  options={[
                    { text: 'Range', value: RATE_GUIDANCE_TYPE.RANGE },
                    { text: 'Fixed', value: RATE_GUIDANCE_TYPE.FIXED },
                  ]}
                  showInline
                />
                {values.rateGuidanceType === RATE_GUIDANCE_TYPE.RANGE && (
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <RateField
                        currency={currency}
                        label="Minimum"
                        name="rate_guide_minimum"
                        required={false}
                        unit={rateGuideUnit}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <RateField
                        currency={currency}
                        label="Maximum"
                        name="rate_guide_maximum"
                        required={false}
                        unit={rateGuideUnit}
                      />
                    </div>
                  </div>
                )}
                {values.rateGuidanceType === RATE_GUIDANCE_TYPE.FIXED && (
                  <RateField
                    currency={currency}
                    label={rateUnitFormat?.title || 'Fixed rate'}
                    name="rate_guide_fixed"
                    required={false}
                    unit={rateGuideUnit}
                  />
                )}
                <InputNumberField
                  label="Max number of applicants"
                  name="max_applicants"
                  placeholder="e.g 20"
                  sublabel={(
                    'If set, once the maximum number of applicants has been reached, the '
                      + 'opportunity will be closed to new applications'
                  )}
                />
              </>
            )}
          </div>
        )}
        <div className="col-12 mt-4 text-right px-0 d-flex justify-content-end">
          <TDButton
            label="Cancel"
            onClick={() => {
              let targetUrl = (isOpportunity ? projectOpportunitiesUrl : projectListUrl)(orgAlias);
              if (editingProject) {
                targetUrl = (isOpportunity ? opportunityViewUrl : projectViewUrl)(
                  orgAlias, initialValues.id,
                );
              }
              history.push(targetUrl);
            }}
            variant={BS_STYLE.DEFAULT}
          />
          <TDButton
            data-testid="project-form-create-project-button"
            disabled={submitting}
            label={editingProject ? 'Update' : 'Create'}
            type="submit"
            variant={BS_STYLE.PRIMARY}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

FormRenderer.propTypes = {
  activeOrg: orgSpec.isRequired,
  allowTemplateSelection: PropTypes.bool,
  canChangeVisibility: PropTypes.bool,
  customFields: PropTypes.arrayOf(customFieldSpec),
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
  enableBudgetAllocation: PropTypes.bool,
  enableEditingMemberSettings: PropTypes.bool,
  expandSettings: PropTypes.bool,
  form: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  initialValues: PropTypes.object,
  managerBudget: PropTypes.object,
  projectId: PropTypes.number,
  submitting: PropTypes.bool.isRequired,
};

FormRenderer.defaultProps = {
  allowTemplateSelection: true,
  canChangeVisibility: false,
  customFields: [],
  customFieldTemplates: [],
  enableBudgetAllocation: true,
  enableEditingMemberSettings: true,
  expandSettings: false,
  form: {},
  initialValues: {},
  managerBudget: {},
  projectId: null,
};

// eslint-disable-next-line react/no-multi-comp
class ProjectForm extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { orgAlias } = params;

    return Promise.all([
      dispatch(
        fetchActiveManagerBudgetDS({ orgAlias, authedAxios, url, componentName }),
      ),
    ]);
  }

  static GetComponentName() {
    return 'ProjectCreateForm';
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.hasSkills = false;

    this.submit = this.submit.bind(this);
  }

  async submit(valuesIn) {
    const values = { ...valuesIn };
    const { onSubmitSuccess, match: { params: { id: projectId, orgAlias } } } = this.props;
    const isUpdate = !!projectId;
    const isOpportunity = values.visibility === PROJECT_VISIBILITY.ORG_ONLY;

    if (isOpportunity && ![true, false].includes(values.opportunity_is_invitations_only)) {
      return { opportunity_is_invitations_only: 'Please select who can see this opportunity' };
    }

    if (isUpdate || !isOpportunity) {
      delete values.max_applicants; // eslint-disable-line no-param-reassign
    }

    if (isUpdate) {
      // These are needed for the form UI, but not when updating a project
      delete values.id; // eslint-disable-line no-param-reassign
    }

    let hasCustomFieldAnswers = false;
    if (values.rateGuidanceType === RATE_GUIDANCE_TYPE.RANGE) {
      delete values.rate_guide_fixed; // eslint-disable-line no-param-reassign
    } else {
      delete values.rate_guide_minimum; // eslint-disable-line no-param-reassign
      delete values.rate_guide_maximum; // eslint-disable-line no-param-reassign
    }

    const formData = new FormData();
    const customFieldAnswers = {};

    Object.keys(values).forEach(key => {
      if (
        ['rateGuidanceType', 'currency'].includes(key)
        || (!isOpportunity && key === 'opportunity_is_invitations_only')
      ) {
        return;
      }
      const value = values[key];
      if ([
        'clients', 'custom_field_ids', 'custom_field_templates', 'skills', 'tags',
      ].includes(key)) {
        formData.append(key, JSON.stringify(value || []));
        return;
      }
      if (key.startsWith(PATH_PREFIX)) {
        customFieldAnswers[key] = value;
        hasCustomFieldAnswers = true;
        return;
      }
      if (key === 'attachments' && Array.isArray(value) && value.length > 0) {
        value.forEach(attachment => {
          formData.append('attachments[]', attachment);
        });
        return;
      }
      formData.append(key, value);
    });
    if (hasCustomFieldAnswers) {
      formData.append('customFieldAnswers', JSON.stringify(customFieldAnswers));
    }

    try {
      let response = null;
      if (isUpdate) {
        response = await updateProjectDS(orgAlias, projectId, formData);
      } else {
        response = await createProjectDS(orgAlias, formData);
      }
      if (response.data._error) {
        return response.data;
      }
      if (onSubmitSuccess) {
        onSubmitSuccess(response.data);
      }
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }

  render() {
    const { initialValues, match: { params: { id: projectId } }, ...passThroughProps } = this.props;
    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
        skeletonComponent={ProjectListSkeleton}
      >
        <Form
          initialValues={initialValues}
          keepDirtyOnReinitialize // important: mandatory templates will not work properly otherwise
          mutators={{ ...arrayMutators }}
          onSubmit={this.submit}
          render={FormRenderer}
          projectId={projectId}
          {...passThroughProps}
        />
      </TDApiConnected>
    );
  }
}

ProjectForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  enableBudgetAllocation: PropTypes.bool,
  expandSettings: PropTypes.bool,
  initialValues: PropTypes.object,
  history: routerHistorySpec.isRequired,
  managerBudget: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  onSubmitSuccess: PropTypes.func,
};

ProjectForm.defaultProps = {
  enableBudgetAllocation: true,
  expandSettings: false,
  initialValues: {},
  managerBudget: {},
  onSubmitSuccess: undefined,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  managerBudget: getViewState(state, ProjectForm.GetComponentName()).item,
});

const ProjectCreateFormReduxConnect = connect(mapStateToProps)(ProjectForm);

export default withRouter(ProjectCreateFormReduxConnect);
