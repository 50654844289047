import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';
import { isMenuItemActive } from 'core/assets/js/lib/utils';

const NavBarSettingsList = ({ history, items, location, match }) => {

  const onItemSelect = (path) => {
    if (path) {
      history.push(path);
    }
  };

  return (
    <div className="px-3">
      <div className="settings-menu" data-testid="settings-menu">
        <div
          className="dropdown-item"
        >
          <i className={ICON.COG} />
          <strong>My Settings</strong>
        </div>
        { items.map((item, index) => {
          if (!item) {
            return null;
          }
          let className = 'dropdown-item settings-drop-down__sub-item';
          if (isMenuItemActive(match, location, item.sections)) {
            className += ' active';
          }
          return (
            <NavLink
              data-testid={`navbar-settings-account-dropdown-${item.label}`}
              className={className}
              key={`${item.path}--${index}`}
              onClick={() => onItemSelect(item.path)}
              to={item.path}
            >
              {item.label}
            </NavLink>
          );
        })
        }
      </div>
    </div>
  );
};

NavBarSettingsList.propTypes = {
  history: PropTypes.object.isRequired,
  items: PropTypes.array,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

NavBarSettingsList.defaultProps = {
  items: [],
};

export default withRouter(NavBarSettingsList);
