import React from 'react';
import PropTypes from 'prop-types';

import { skillSpec } from 'skills/assets/js/lib/objectSpecs';
import { SkillVotesBadge } from 'core/assets/js/components/SkillTag.jsx';
import { BS_STYLE, BS_TOOLTIP_PLACEMENT, ICON } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const SkillItem = ({ skill, onUpVote, onRetractVote }) => {
  const {
    id: skillId, profileSkillId, canUpVote,
    canRetractVote, votesCount, label,
    copiedByUser,
  } = skill;
  const className = ['tag'];
  const btnClassName = ['btn-vote'];
  const hasSingleVote = votesCount === 1;
  const hasVotes = votesCount > 0;

  if (!copiedByUser && hasVotes) {
    className.push('bg-lightblue pr-0');
  } else if (canUpVote || canRetractVote) {
    className.push('pr-0');
  }

  if (canUpVote) {
    btnClassName.push('up-vote');
  }

  if (canRetractVote && (!hasSingleVote || copiedByUser)) {
    btnClassName.push('retract-vote');
  }

  if (canRetractVote && hasSingleVote && !copiedByUser) {
    btnClassName.push('remove-endorsement');
  }

  return (
    <span key={`skill-${skillId}`} className={className.join(' ')}>
      <span className="d-inline-flex align-items-center">
        {label}
        {hasVotes && <SkillVotesBadge count={votesCount} /> }
        {(canUpVote || canRetractVote) && (
          <TDButton
            rounded
            variant={BS_STYLE.LINK}
            className={btnClassName.join(' ')}
            onClick={() => {
              const callback = canUpVote ? onUpVote : onRetractVote;
              callback({ profileSkillId });
            }}
            tooltipMsg={canUpVote ? 'Upvote' : 'Retract vote'}
            tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
          >
            <span className={ICON.CROSS} />
            <span className={ICON.THUMBSUP_SOLID} />
          </TDButton>
        )}
      </span>
    </span>
  );
};

SkillItem.propTypes = {
  skill: skillSpec,
  onUpVote: PropTypes.func,
  onRetractVote: PropTypes.func,
};

SkillItem.defaultProps = {
  skill: null,
  onUpVote: () => {},
  onRetractVote: () => {},
};

export default SkillItem;
