import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { paymentTermsTemplate } from 'settings/assets/js/lib/utils';
import { BS_STYLE } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';

const FORM_ID = 'paymentTermsForm';

let PaymentTermsForm = ({
  heading, includeComments, promptMsg, showPreview, gracePeriod, comments,
  modalState, handleSubmit, submitting,
}) => (
  <React.Fragment>
    <div className="d-inline">
      <span>
        <span data-testid="payment-terms-cta" onClick={modalState.open} className="imitate-link">Edit</span>
      </span>
    </div>
    <form onSubmit={handleSubmit}>
      <ModalSimple
        open={modalState.isOpen}
        heading={heading}
        body={(
          <React.Fragment>
            {promptMsg && (
              <div className="row mb-4">
                <div className="col-12 col-sm-12">
                  {promptMsg}
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-8 col-sm-5">
                <Field
                  name="grace_period"
                  type="number"
                  step={1}
                  component={InputField}
                  suffix="days"
                  label="Grace Period:"
                />
              </div>
            </div>

            {includeComments && (
              <div className="row">
                <div className="col-12 col-sm-12">
                  <Field
                    name="comments"
                    type="textarea"
                    rows="2"
                    component={InputField}
                    label="Comments:"
                  />
                </div>
              </div>
            )}

            {showPreview && (
              <React.Fragment>
                <div>
                  <label>This is how it will appear in your invoices under terms section:</label>
                </div>
                <pre className="terms-preview">{paymentTermsTemplate(gracePeriod)}</pre>
                {comments && (
                  <pre className="terms-preview">
                    {comments}
                  </pre>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        onClose={modalState.close}
        footer={(
          <TDButton
            floatRight
            className="col-6 col-sm-4"
            type="submit"
            variant={BS_STYLE.SUCCESS}
            label="Save"
            onClick={handleSubmit}
            disabled={submitting}
          />
        )}
      />
    </form>
  </React.Fragment>
);

PaymentTermsForm.propTypes = {
  heading: PropTypes.string,
  includeComments: PropTypes.bool,
  promptMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showPreview: PropTypes.bool,
  gracePeriod: PropTypes.number.isRequired,
  comments: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalState: modalStateSpec.isRequired,
};

PaymentTermsForm.defaultProps = {
  comments: null,
  heading: 'Update your payment terms',
  includeComments: true,
  promptMsg: null,
  showPreview: true,
};

const selector = formValueSelector(FORM_ID);

PaymentTermsForm = connect(state => ({
  gracePeriod: parseInt(selector(state, 'grace_period'), 10),
  comments: selector(state, 'comments'),
}))(PaymentTermsForm);

const PaymentTermsFormConnect = withStateModal(reduxForm({
  form: FORM_ID,
  onSubmitSuccess: (result, dispatch, props) => props.modalState.close(),
})(PaymentTermsForm));

export default PaymentTermsFormConnect;
