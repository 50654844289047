import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import AnswersList from 'core/assets/js/components/AnswersList.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { fetchProjectAnswers } from 'projects/assets/js/data-services/list';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';

const ProjectInvitationContent = ({ project, answers, storeKey }) => {
  const {
    attachments,
    brief,
    deadline,
  } = project;
  return (
    <div className="project__details">
      <TDApiConnected
        duck="list"
        storeKey={storeKey}
        skeletonComponent={ListSkeleton}
        fetchData={({
          dispatch, params, authedAxios, url,
        }) => {
          const { orgAlias } = params;
          const { id: projectId } = project;
          return dispatch(fetchProjectAnswers({
            orgAlias,
            projectId,
            authedAxios,
            url,
            componentName: storeKey,
          }));
        }}
      >
        <h4>Project Brief</h4>
        <MarkdownText
          withBreaksPlugin
          text={brief}
        />

        <AttachmentsList attachments={attachments} />

        {deadline && (
          <div className="mb-3">
            <h4>Deadline</h4>
            <div>{formatDate(deadline)}</div>
          </div>
        )}

        <AnswersList
          answers={answers}
          labelClass="h3 mb-2 text-dark"
          wrapperClass="answer-value mb-4"
        />

        <h4>Skills</h4>
      </TDApiConnected>
    </div>
  );
};

ProjectInvitationContent.propTypes = {
  project: projectSpec.isRequired,
  answers: PropTypes.array,
  storeKey: PropTypes.string.isRequired,
};
ProjectInvitationContent.defaultProps = {
  answers: [],
};

const mapStateToProps = (state, props) => {
  const { project, storeKey } = props;
  const listState = getListState(state, storeKey);
  return {
    project,
    answers: listState.items,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectInvitationContentConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectInvitationContent);

export default ProjectInvitationContentConnected;
