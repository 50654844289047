import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const WorksheetFormDayFormat = ({ date }) => {
  const mDate = moment(date);

  return (
    <div className="worksheet-day-box d-inline-flex flex-nowrap flex-row align-items-center">
      <div className="worksheet-day-box__day pr-2">
        {mDate.format('DD')}
      </div>

      <div className="pl-2 d-flex flex-column">
        <div className="worksheet-day-box__month-year">
          {`${mDate.format('MMMM')} ${mDate.format('YYYY')}`}
        </div>
        <span className="worksheet-day-box__day-name">
          {mDate.format('dddd')}
        </span>
      </div>

    </div>
  );
};

WorksheetFormDayFormat.propTypes = {
  date: PropTypes.string.isRequired,
};

export default WorksheetFormDayFormat;
