import React from 'react';
import { Field } from 'redux-form';

import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import GeosuggestField from 'core/assets/js/components/ReduxFormFields/GeosuggestField.jsx';
import { validateCaTransitNo, isValidCaInstitutionNo } from 'core/assets/js/lib/validators';
import { InvalidBankAccountError } from 'settings/assets/js/lib/errors';
import AccountTypeAndSubType from 'settings/assets/js/components/bank-account-forms/AccountTypeAndSubType.jsx';
import AccountNumber from 'settings/assets/js/components/bank-account-forms/AccountNumber.jsx';

const validateTransitNoField = (value) => {
  const noError = '';
  if (!value) {
    return noError;
  }

  try {
    validateCaTransitNo(value);
    return noError;
  } catch (err) {
    if (err instanceof InvalidBankAccountError) {
      return err.message;
    }
  }
  return noError;
};

const validateInstitutionNoField = (value) => {
  const noError = '';
  if (!value) {
    return noError;
  }

  try {
    isValidCaInstitutionNo(value);
    return noError;
  } catch (err) {
    if (err instanceof InvalidBankAccountError) {
      return err.message;
    }
  }
  return noError;
};

const BankAccountForm = () => (
  <React.Fragment>
    <AccountTypeAndSubType />
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name="institution_no"
          type="text"
          component={InputField}
          label="Institution No."
          validate={validateInstitutionNoField}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-6">
        <Field
          name="transit_no"
          type="text"
          component={InputField}
          label="Transit No."
          validate={validateTransitNoField}
        />
      </div>
      <div className="col-12 col-sm-6">
        <AccountNumber />
      </div>
      <div className="col-12 col-sm-6">
        <Field
          name="address"
          type="textarea"
          component={GeosuggestField}
          label="Address"
          sublabel="Enter your full address (country, city, address, postal code)"
          onlyAuto
          required
        />
      </div>
    </div>
  </React.Fragment>
);


export default BankAccountForm;
