import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import ButtonsContainer from 'core/assets/js/components/ButtonsContainer.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { postOrganizationApiKeysDS } from 'settings/assets/js/data-services/organizations';
import { COMPONENT_NAME as LIST_COMPONENT_NAME } from 'settings/assets/js/OrgManageApiKeysView.jsx';
import { WRITE_TO_CLIPBOARD } from 'core/assets/js/config/settings';

export const ORG_API_KEY_MODAL_ID = 'org-api-key-modal';

const ORG_API_KEY_MODAL_CONTENT = {
  create_title: 'Create API key',
  warning_title: 'Warning !',
  warning_message: 'API key will be visible only once, then it will be hidden.',
};

const OrgApiKeyModal = ({ activeOrg, dispatch, open }) => {
  const [apiKey, setApiKey] = useState(null);

  const closeModal = () => {
    dispatch(modalCloseAC());
    setApiKey(null);
  };
  const copyApiKey = value => WRITE_TO_CLIPBOARD(value);

  return (
    <div className="static-modal">
      <Form
        onSubmit={async (values, form) => {
          try {
            const { apiKeyValue } = await dispatch(postOrganizationApiKeysDS(
              activeOrg.alias,
              values,
              LIST_COMPONENT_NAME,
              OrgApiKeyModal.GetComponentName(),
            ));
            setApiKey(apiKeyValue);
            form.change('friendlyName', '');
          } catch (error) {
            if (error.errors) {
              return error.errors;
            }
            return { [FORM_ERROR]: error._error || error.message };
          }
          return null;
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalSimple
              open={open}
              onClose={closeModal}
              heading={ORG_API_KEY_MODAL_CONTENT.create_title}
              body={
                apiKey ? (
                  <>
                    <TDSystemMessage
                      type="warning"
                      title={ORG_API_KEY_MODAL_CONTENT.warning_title}
                      className="mb-4 mt-4"
                    >
                      <div><p>{ORG_API_KEY_MODAL_CONTENT.warning_message}</p></div>
                    </TDSystemMessage>
                    <div className="d-flex align-items-center">
                      <div className="col-10 col-auto pl-0">
                        <div className="form-group">
                          <label htmlFor="apikey">Here&apos;s your API key</label>
                          <input
                            className="form-control"
                            id="apikey"
                            readOnly
                            type="text"
                            value={apiKey}
                          />
                        </div>
                      </div>
                      <div className="ml-auto">
                        <TDButton
                          className="mt-3"
                          label="Copy"
                          variant={BS_STYLE.DEFAULT}
                          onClick={() => copyApiKey(apiKey)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p>
                      Please enter the label for your new API key, then click &quot;Create API
                      key&quot;.
                    </p>
                    <TextInputField label="Label" name="friendlyName" />
                  </>
                )
              }
              footer={(
                apiKey ? <TDButton label="Close" onClick={closeModal} /> : (
                  <ButtonsContainer
                    className="mt-5 text-right"
                    primaryButtons={[
                      <TDButton
                        disabled={submitting}
                        label="Create API key"
                        onClick={handleSubmit}
                        variant={BS_STYLE.PRIMARY}
                      />,
                    ]}
                    secondaryButtons={apiKey ? null : [
                      <TDButton label="Cancel" onClick={closeModal} />,
                    ]}
                  />
                )
              )}
            />
          </form>
        )}
      />
    </div>
  );
};

OrgApiKeyModal.GetComponentName = () => 'OrgApiKeyModal';

OrgApiKeyModal.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

OrgApiKeyModal.defaultProps = {
  open: false,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  initialValues: getModalPayload(state, ORG_API_KEY_MODAL_ID),
  open: getIsModalOpen(state, ORG_API_KEY_MODAL_ID),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgApiKeyModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgApiKeyModal);

export default OrgApiKeyModalConnected;
