import React from 'react';
import { Field } from 'redux-form';

import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { countryCodeSpec } from 'core/assets/js/lib/objectSpecs';
import { isValidAccount, isValidRuAccount } from 'core/assets/js/lib/validators';
import { InvalidBankAccountError } from 'settings/assets/js/lib/errors';

const validateAccField = (value) => {
  const noError = '';
  if (!value) {
    return noError;
  }

  try {
    isValidAccount(value);
    return noError;
  } catch (err) {
    if (err instanceof InvalidBankAccountError) {
      return err.message;
    }
  }
  return noError;
};

const validateRUAccField = (value) => {
  const noError = '';
  if (!value) {
    return noError;
  }

  try {
    isValidRuAccount(value);
    return noError;
  } catch (err) {
    if (err instanceof InvalidBankAccountError) {
      return err.message;
    }
  }
  return noError;
};

const getAccountNumberValidator = (countryCode) => {
  switch (countryCode) {
    case 'RU':
      return validateRUAccField;
    default:
      return validateAccField;
  }
};


const AccountNumber = ({ countryCode }) => (
  <Field
    name="account_number"
    type="text"
    component={InputField}
    label="Account Number"
    validate={getAccountNumberValidator(countryCode)}
  />

);

AccountNumber.propTypes = {
  countryCode: countryCodeSpec,
};

AccountNumber.defaultProps = {
  countryCode: null,
};

export default AccountNumber;
