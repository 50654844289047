import PropTypes from 'prop-types';

import React, { useState } from 'react';

import { ICON } from 'core/assets/js/constants';

const MaskedValue = ({ className, maskedValue: maskedValueIn, value }) => {
  const [visible, setVisible] = useState(false);
  const maskedValue = maskedValueIn || new Array(visible.length).fill('*').join('');
  return (
    <div className={`masked-value d-flex align-items-center${className ? ` ${className}` : ''}`}>
      <div>{visible ? value : maskedValue}</div>
      <i
        className={`${ICON[visible ? 'EYE_SLASH' : 'VIEW']} ml-3 imitate-link`}
        onClick={() => setVisible(!visible)}
      />
    </div>
  );
};

MaskedValue.propTypes = {
  className: PropTypes.string,
  maskedValue: PropTypes.string,
  value: PropTypes.string.isRequired,
};

MaskedValue.defaultProps = {
  className: null,
  maskedValue: null,
};

export default MaskedValue;
