import React from 'react';
import { Card } from 'react-bootstrap';

import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import ProjectDistributionWidgetSkeleton from 'projects/assets/js/components/skeletons/ProjectDistributionWidgetSkeleton.jsx';
import ProjectBurnDownWidgetSkeleton from 'projects/assets/js/components/skeletons/ProjectBurnDownWidgetSkeleton.jsx';

const ProjectTabDashboardSkeleton = () => (
  <div className="row">
    <div className="col-12 col-md-4">
      <Card className="project-widget__details">
        <Card.Header>
          Overview
        </Card.Header>
        <Card.Body>
          <div className="mb-2 row mx-n2">
            <div className="col-3 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-2 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-3 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-4 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
          </div>
          <div className="mb-2 row mx-n2">
            <div className="col-4 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-3 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-2 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-3 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
          </div>
          <div className="mb-2 row mx-n2">
            <div className="col-3 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-2 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-3 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
            <div className="col-4 px-2">
              <RectangleSkeleton className="w-100" height="14" />
            </div>
          </div>

          <hr className="d-inline-block w-100 mx-n4" />

          <div className="project-meta">
            <div className="d-flex align-items-center">
              Status
              <span className="ml-auto">
                <RectangleSkeleton width="90" height="14" />
              </span>
            </div>

            <div className="d-flex align-items-center">
              Reference
              <span className="ml-auto">
                <RectangleSkeleton width="90" height="14" />
              </span>
            </div>

            <div className="d-flex align-items-center">
              Start date
              <span className="ml-auto">
                <RectangleSkeleton width="90" height="14" />
              </span>
            </div>

            <div className="d-flex align-items-center">
              Deadline
              <span className="ml-auto">
                <RectangleSkeleton width="90" height="14" />
              </span>
            </div>

            <div className="d-flex align-items-center">
              Owner
              <span className="ml-auto">
                <RectangleSkeleton width="90" height="14" />
              </span>
            </div>
          </div>

          <div className="project-stats d-flex mt-4 row">
            <div className="col-6 text-center">
              <span className="project-stats__value">
                <RectangleSkeleton className="d-inline-block mb-2" width="18" height="30" />
              </span>
              <span className="project-stats__label">
                Worksheets
              </span>
            </div>

            <div className="col-6 text-center border-left">
              <span className="project-stats__value">
                <RectangleSkeleton className="d-inline-block mb-2" width="18" height="30" />
              </span>
              <span className="project-stats__label">
                POS
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>

    <div className="col-12 col-md-8">
      <div className="row">
        <AllowAccessToManager>
          <div className="col-12 mb-4">
            <Card className="project-widget__details">
              <Card.Header>
                <span>Budget</span>
                <span className="float-right">
                  <RectangleSkeleton className="mr-3" height="10" />
                </span>
              </Card.Header>
              <Card.Body>
                <div className="row">
                  <div className="col-12 d-flex align-items-center mb-4">
                    <RectangleSkeleton className="col-12" height="10" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex align-items-center mb-3">
                    <CircleSkeleton className="float-left mr-3" diameter="15" />
                    <RectangleSkeleton className="col-1 mx-3" height="15" />
                    <CircleSkeleton className="float-left mr-3" diameter="15" />
                    <RectangleSkeleton className="col-2 mr-3" height="15" />
                    <CircleSkeleton className="float-left mr-3" diameter="15" />
                    <RectangleSkeleton className="col-1 mx-3" height="15" />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </AllowAccessToManager>
        <div className="col-12 mb-4">
          <Card className="project-widget__upcoming-payments mt-5">
            <Card.Header>
              Upcoming payment orders
            </Card.Header>
            <Card.Body>
              <div className="upcoming-payments upcoming-payments__worksheets-container">
                <div className="row px-4">
                  <div className="col-7 pl-0">
                    <RectangleSkeleton height="10" width="100" />
                  </div>
                  <div className="col-5">
                    <div className="row">
                      <span className="col-6 pr-0 text-right upcoming-amount upcoming-amount--worksheets">
                        <RectangleSkeleton height="10" width="50" />
                      </span>
                      <span className="col-6 pr-0 text-right upcoming-value upcoming-value--worksheets">
                        <RectangleSkeleton height="10" width="50" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcoming-payments upcoming-payments__expenses-container">
                <div className="row px-4">
                  <div className="col-7 pl-0">
                    <RectangleSkeleton height="10" width="100" />
                  </div>
                  <div className="col-5">
                    <div className="row">
                      <span className="col-6 pr-0 text-right upcoming-amount upcoming-amount--expenses">
                        <RectangleSkeleton height="10" width="50" />
                      </span>
                      <span className="col-6 pr-0 text-right upcoming-value upcoming-value--expenses">
                        <RectangleSkeleton height="10" width="50" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcoming-payments upcoming-payments__totals-container">
                <div className="row px-4">
                  <div className="col-12 col-md-7 pl-0">
                    <span><RectangleSkeleton height="10" width="120" /></span>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="row">
                      <span className="col-6 pr-0 text-md-right pl-0 pl-md-3 upcoming--label upcoming-label--totals">
                        <RectangleSkeleton height="10" width="100" />
                      </span>
                      <span className="col-6 pr-0 text-right pl-0 pl-md-3 upcoming--value upcoming--value--totals">
                        <RectangleSkeleton height="10" width="70" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <ProjectDistributionWidgetSkeleton header="Worksheets" />
        </div>
        <div className="col-12 col-md-6 mb-4">
          <ProjectDistributionWidgetSkeleton header="Expenses" />
        </div>
        <AllowAccessToManager>
          <div className="col-12 mb-4">
            <Card className="project-widget__details">
              <Card.Header>
                Worksheets / Expenses
              </Card.Header>
              <Card.Body className="project-worksheets-list">
                <div className="row">
                  <div className="col-10 d-flex align-items-center mb-3">
                    <CircleSkeleton className="float-left" diameter="36" />
                    <RectangleSkeleton className="col-7" height="20" />
                  </div>
                  <div className="col-2 ml-auto d-flex align-items-center text-right">
                    <RectangleSkeleton className="w-100" height="20" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-7 d-flex align-items-center">
                    <CircleSkeleton className="float-left" diameter="36" />
                    <RectangleSkeleton className="col-7" height="20" />
                  </div>
                  <div className="col-2 ml-auto d-flex align-items-center text-right">
                    <RectangleSkeleton className="w-100" height="20" />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 mb-4">
            <ProjectBurnDownWidgetSkeleton />
          </div>
        </AllowAccessToManager>
      </div>
    </div>
  </div>
);

export default ProjectTabDashboardSkeleton;
