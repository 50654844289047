/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import { FIELDS_PER_SETTINGS_SECTION, SETTINGS_SECTION } from 'integrations/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { fetchInvoiceSettingsDS } from 'integrations/assets/js/data-services/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import InvoiceMappingField from 'integrations/assets/js/components/InvoiceMappingField.jsx';
import MappingFieldName from 'integrations/assets/js/components/MappingFieldName.jsx';

const IntegrationInvoiceMappingSettings = ({
  fields = [],
  hasLoaded,
  integrationName,
  match: { params: { integrationId, orgAlias } },
  trackingCategoryOptions,
}) => {
  const getSectionFields = section => {
    const returnedFields = [];
    fields.forEach(field => {
      if ((FIELDS_PER_SETTINGS_SECTION[section] || []).includes(field.name)) {
        returnedFields.push(field);
      }
    });
    return returnedFields;
  };

  const componentName = IntegrationInvoiceMappingSettings.GetComponentName();

  // Disabled until we have more defined specifications for how tracking categories should work
  const disableTrackingCategories = true;
  const hasTrackingCategories = trackingCategoryOptions.length > 0;

  return (
    <>
      {/*
        The content should be inside TDApiConnected, but there was a bug where it was not
        rendering, even though the API request was successful.
        It just gets stuck on `!requestHasBeenTriggered` in withApi.
        Spent hours investigating, but could not find the root cause. So rendering the content
        outside of TDApiConnected is the hacky fix
      */}
      <TDApiConnected
        duck="view"
        fetchData={({ authedAxios, dispatch }) => dispatch(
          fetchInvoiceSettingsDS({ authedAxios, componentName, integrationId, orgAlias }),
        )}
        skeletonComponent={() => null}
        storeKey={componentName}
      />
      {hasLoaded && (
        <>
          <h3 className="heading-block mt-4">Invoice Mapping</h3>
          <p>
            Most invoice fields are auto-mapped, these included dates, amounts, quanties,
            currency, tax, etc. You can however customise the mapping for various text
            fields.
          </p>

          <table
            className="table w-100 table-bordered integration__invoice-mapping-fields mb-4"
          >
            <thead>
              <tr className="text-uppercase">
                <th scope="col" className="w-50">
                  Integration Field
                </th>
                <th scope="col">
                  TalentDesk Field
                </th>
              </tr>
            </thead>
            <tbody>
              {getSectionFields(SETTINGS_SECTION.INVOICE).map(field => (
                <tr key={`mapping-field-${field.name}`}>
                  <td>
                    <MappingFieldName value={field.label} />
                  </td>
                  <td>
                    <InvoiceMappingField
                      name={field.name}
                      options={field.optionGroups}
                      required={field.required}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3 className="heading-block mt-4">Line Items Mapping</h3>
          <p>
            We will auto-map all amounts and quantities for each line item.
            Please choose whether you would prefer to map consolidated summary level
            line items or break down each line item to worksheet level detail.
          </p>

          <table
            className="table w-100 table-bordered integration__invoice-mapping-fields mb-4"
          >
            <thead>
              <tr className="text-uppercase">
                <th scope="col" className="w-50">
                  Integration Field
                </th>
                <th scope="col">
                  TalentDesk Field
                </th>
              </tr>
            </thead>
            <tbody>
              {getSectionFields(SETTINGS_SECTION.LINE_ITEMS).map(field => (
                <tr key={`mapping-field-${field.name}`}>
                  <td>
                    <MappingFieldName value={field.label} />
                  </td>
                  <td>
                    <InvoiceMappingField
                      name={field.name}
                      options={field.optionGroups}
                      required={field.required}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {!disableTrackingCategories && (
            <>
              <h3 className="heading-block mt-4">Advanced: Tracking line items (optional)</h3>
              {hasTrackingCategories && (
                <>
                  <p>
                    Each line item can be optionally mapped to one or more cost centers. Per each
                    line item, TalentDesk will associate each tracking category, if any are
                    detected.
                  </p>

                  <table
                    className="table w-100 table-bordered integration__invoice-mapping-fields mb-4"
                  >
                    <thead>
                      <tr className="text-uppercase">
                        <th scope="col" className="w-50">TalentDesk invoice line item type</th>
                        <th scope="col">Tracking categories</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getSectionFields(SETTINGS_SECTION.TRACKING_CATEGORIES).map(field => (
                        <tr key={`mapping-field-${field.name}`}>
                          <td>
                            <MappingFieldName value={field.label} />
                          </td>
                          <td>
                            <CustomSelectField
                              className="mb-0"
                              isMulti
                              name={field.name}
                              options={trackingCategoryOptions}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {!hasTrackingCategories && (
                <p>{`No tracking categories were found in your ${integrationName} account`}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

IntegrationInvoiceMappingSettings.GetComponentName = () => 'IntegrationInvoiceMappingSettings';

IntegrationInvoiceMappingSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  hasLoaded: PropTypes.bool.isRequired,
  integrationName: PropTypes.string.isRequired,
  match: routerMatchContentsSpec.isRequired,
  trackingCategoryOptions: PropTypes.arrayOf(PropTypes.object),
};

IntegrationInvoiceMappingSettings.defaultProps = {
  fields: [],
  trackingCategoryOptions: [],
};

const mapStateToProps = (state) => {
  const { hasLoaded, item: { fields, trackingCategories } } = getViewState(
    state, IntegrationInvoiceMappingSettings.GetComponentName(),
  );

  return { fields, hasLoaded, trackingCategoryOptions: trackingCategories };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationInvoiceMappingSettingsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationInvoiceMappingSettings);

export default withRouter(IntegrationInvoiceMappingSettingsConnected);
