
// Action types
export const MODAL_DATA_LIST = {
  IS_LOADING: 'MODAL_DATA_LIST_IS_LOADING',
  FETCH: 'MODAL_DATA_LIST_FETCH',
  RESET: 'MODAL_DATA_LIST_RESET',
};

// Reducer
const initialState = {
  items: [],
  isLoading: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_DATA_LIST.IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case MODAL_DATA_LIST.FETCH:
      return {
        ...state,
        items: action.items,
        isLoading: false,
      };
    case MODAL_DATA_LIST.RESET:
      return initialState;
    default:
      return state;
  }
};

// Action creators
export const modalDataListIsLoadingAC = bool => ({
  type: MODAL_DATA_LIST.IS_LOADING,
  isLoading: bool,
});
export const modalDataListFetchAC = items => ({
  type: MODAL_DATA_LIST.FETCH,
  items,
});
export const modalDataListResetAC = () => ({
  type: MODAL_DATA_LIST.RESET,
});

export default reducer;
