import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { contactsUrl } from 'contacts/urls';
import { createContactDS } from 'contacts/assets/js/data-services/form';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { getCustomFieldTemplates, fetchCustomFieldTemplatesDS } from 'interviews/assets/js/ducks/customFields';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { extractCustomFieldSetup } from 'interviews/assets/js/lib/utils';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ContactForm from 'contacts/assets/js/components/ContactForm.jsx';
import ContactsListView from 'contacts/assets/js/ContactsListView.jsx';
import ContactFormSkeleton from 'contacts/assets/js/components/skeletons/ContactFormSkeleton.jsx';

class ContactCreateView extends React.Component {
  static FetchData({ dispatch, params: { orgAlias }, url, authedAxios }) {
    return Promise.all([
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias, entityType: FIELD_ENTITY_TYPE.USER, authedAxios, url,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ContactCreateView';
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    const { dispatch, match: { params: { orgAlias } }, history } = this.props;
    try {
      await dispatch(
        createContactDS({
          values,
          componentName: ContactsListView.GetComponentName(),
          orgAlias,
        }),
      );

      toastr.success('Well Done!', 'Your contact was created successfully.');
      history.push(contactsUrl(orgAlias));
      return true;
    } catch (err) {
      const errorData = err.errors;

      if (errorData._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        [FORM_ERROR]: errorData._error || 'Oops! Something went wrong. Please try again',
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  }

  render() {
    const { match: { params: { orgAlias } }, customFieldTemplates } = this.props;
    const breadcrumbs = [
      {
        title: 'Contacts',
        url: contactsUrl(orgAlias),
      },
      {
        title: 'Create contact',
        url: null,
      },
    ];

    const {
      initialValues: customFieldInitialValues,
      selectedCustomFields,
    } = extractCustomFieldSetup({
      templates: customFieldTemplates,
    });

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--people">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={ContactFormSkeleton}
            >
              <ContactForm
                onSubmit={this.onSubmit}
                customFieldTemplates={customFieldTemplates}
                customFields={selectedCustomFields}
                initialValues={{
                  ...customFieldInitialValues,
                }}
                allowTemplateSelection
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ContactCreateView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
};

ContactCreateView.defaultProps = {
  customFieldTemplates: [],
};

const mapStateToProps = state => ({
  customFieldTemplates: getCustomFieldTemplates(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ContactCreateViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactCreateView);

export default withRouter(ContactCreateViewConnected);
