import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { createSkillDirectoryDS } from 'settings/assets/js/data-services/settings';
import { toastr } from 'react-redux-toastr';
import OrgSkillDirectoryModalForm, { ORG_SKILL_DIRECTORY_MODAL_ID } from 'settings/assets/js/components/OrgSkillDirectoryModalForm.jsx';
import SkillDirectoryCardPreview from 'skills/assets/js/components/SkillDirectoryCardPreview.jsx';
import SkillDirectoryListSkeleton from 'core/assets/js/components/Skeleton/SkillDirectoryListSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { modalOpenAC, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { skillDirectoriesListApiUrl, orgSettingsSkillDirectoryManageUrl, orgSkillsApiUrl } from 'settings/urls';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';

export const COMPONENT_NAME = 'SkillDirectoryList';

const SkillDirectoryList = ({
  history, match: { params: { orgAlias } }, skillDirectories,
}) => {
  const dispatch = useDispatch();

  const listClassNames = ['skill-directory-list'];

  const handleSubmit = values => {
    return dispatch(
      createSkillDirectoryDS({
        values,
        componentName: COMPONENT_NAME,
        orgAlias,
      }),
    ).then(({ id }) => {
      toastr.success('Well Done!', 'Your skill directory was created successfully.');
      dispatch(modalCloseAC());
      history.push(orgSettingsSkillDirectoryManageUrl(orgAlias, id));
    }).catch((err) => {
      const error = err.errors ? err.errors._error : err._error;
      toastr.error('Oops!', (error || 'Could not create the skill directory'));
      dispatch(modalCloseAC());
    });
  };

  return (
    <>
      <div className="mt-5">
        {skillDirectories.length > 0 && (
          <p className="mb-5  ">
            <i className={`${ICON.INFO_2} text-info mr-3`} />
            Create skill directories to easily organise your skills, e.g.,
            design related skills should be added in “Design” skill directory.
          </p>
        )}

        <TDList
          listClassName={listClassNames.join(' ')}
          items={skillDirectories}
          emptyListMessage={(
            <div className="rounded shadow-sm p-4 bg-white discreet">
              <div className="d-flex flex-column align-items-center justify-content-center empty-list-message p-5">
                <i className={`${ICON.FOLDERS_DUOTONE} inverted-colors`} />
                <p className="mb-3">
                  Create skill directories to easily organise your skills, e.g.,
                  design related skills should be added in “Design” skill directory.
                </p>

                <TDButton
                  className="mt-3"
                  variant={BS_STYLE.PRIMARY}
                  onClick={() => dispatch(modalOpenAC(ORG_SKILL_DIRECTORY_MODAL_ID))}
                >
                  Create skill directory
                </TDButton>
              </div>
            </div>
          )}
          cardItem={{
            component: SkillDirectoryCardPreview,
          }}
        />
      </div>

      <OrgSkillDirectoryModalForm
        onSubmit={handleSubmit}
      />
    </>
  );
};

SkillDirectoryList.propTypes = {
  skillDirectories: PropTypes.array,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  hasOrgSkills: PropTypes.bool.isRequired,
};

SkillDirectoryList.defaultProps = {
  skillDirectories: [],
};

const SkillDirectoryListWithTDApi = withTDApiConnected({
  fetchData: ({
    dispatch, params, authedAxios, componentName, querystring, url,
  }) => {
    return Promise.all([
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        querystring,
        url: skillDirectoriesListApiUrl(params.orgAlias),
      })),
      dispatch(fetchListDS({
        url: orgSkillsApiUrl(params.orgAlias, url),
        querystring,
        componentName,
        authedAxios,
        extrasKey: 'orgSkills',
      })),
    ]);
  },
  duck: 'list',
  storeKey: COMPONENT_NAME,
  skeletonComponent: SkillDirectoryListSkeleton,
})(SkillDirectoryList);

const mapStateToProps = (state) => {
  const listState = getListState(state, COMPONENT_NAME);
  return ({
    skillDirectories: listState.items,
    hasOrgSkills: listState.extras?.orgSkills?.length > 0,
  });
};

const SkillDirectoryListWithTDApiConnected = connect(
  mapStateToProps,
)(SkillDirectoryListWithTDApi);


export default withRouter(SkillDirectoryListWithTDApiConnected);
