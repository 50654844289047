/* eslint no-underscore-dangle: ["error", { "allow": ["_meta", "_initialValuesValid"] }] */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Modal } from 'react-bootstrap';
import { find, isEmpty } from 'lodash';

import { load as loadFormData } from 'core/assets/js/ducks/form';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { postReviewDS } from 'people/assets/js/ducks/providers';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import SelectField from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import RatingField from 'core/assets/js/components/ReduxFormFields/RatingField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';


class ReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this._initialValuesValid = this._initialValuesValid.bind(this);
    this.handleOnHide = this.handleOnHide.bind(this);
    this.handleOnShow = this.handleOnShow.bind(this);
    this.submit = this.submit.bind(this);
    this.onRating = this.onRating.bind(this);
    this.onBody = this.onBody.bind(this);
    this.state = {
      hasTouchedBody: false,
      oldBody: '',
    };
  }

  onRating(event, value) {
    const { change } = this.props;
    const { hasTouchedBody } = this.state;

    if (!hasTouchedBody) {
      const messages = {
        1: 'Unhappy',
        2: 'Would not recommend',
        3: 'OK',
        4: 'Would recommend',
        5: 'Happy',
      };
      const msg = messages[value] || '';
      change('body', msg);
    }
  }

  onBody(event, value) {
    const { oldBody } = this.state;

    if (value !== oldBody) {
      this.setState({ hasTouchedBody: true, oldBody: value });
    }
  }

  handleOnHide() {
    const { reset, onCloseModal } = this.props;
    reset();
    onCloseModal();
  }

  handleOnShow() {
    const { payload, load } = this.props;
    // Load form data using the load dynamic form action creator
    if (!isEmpty(payload)) {
      if (!this._initialValuesValid()) {
        throw new Error(`[ReviewForm] Invalid initial values ${JSON.stringify(payload)}`);
      }

      load(payload);
    }
  }

  submit(values) {
    const { match: { params }, onAfterSubmit } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        if (!values.project_id) {
          return reject(new SubmissionError({ project_id: 'You should fill the project' }));
        }
        if (!values.subject_id) {
          return reject(new Error(`Invalid userId defined in subject_id field (${values.subject_id})`));
        }

        // Post the review
        const response = await postReviewDS({
          orgAlias: params.orgAlias, userId: values.subject_id,
        }, values);

        // Run callback function
        onAfterSubmit(response.data);
        this.handleOnHide();
        return resolve();
      } catch (err) {
        if (err.response.data._meta && err.response.data._meta.isValidation) {
          return reject(new SubmissionError(err.response.data));
        }
        return reject(err);
      }
    });
  }

  _initialValuesValid() {
    const { payload } = this.props;

    if (isEmpty(payload)) {
      return false;
    }
    const expectedFields = ['subject_id', 'project_id', 'body', 'rating'];
    const invalidField = find(
      Object.keys(payload), field => !expectedFields.includes(field),
    );
    return !invalidField;
  }

  render() {
    const {
      handleSubmit, showProjectDropdown, revieweeFirstName, projectOptions,
      show, submitting,
    } = this.props;
    return (
      <div className="static-modal">
        <form>
          <Modal
            show={show}
            onHide={this.handleOnHide}
            onShow={this.handleOnShow}
          >
            <Modal.Header closeButton>
              <Modal.Title>{`Let us know how ${revieweeFirstName} did`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-4">
                Did you enjoy working with
                {' '}
                {revieweeFirstName}
                ?
              </div>
              <div className="mb-4">
                Please take a moment to share feedback with your peers on
                {' '}
                {`${revieweeFirstName}'s`}
                {' '}
                performance.
              </div>
              <Field
                name="subject_id"
                type="hidden"
                component="input"
              />

              <Field
                name="project_id"
                wrapperClasses={showProjectDropdown ? '' : 'd-none'}
                label="Project"
                optionsMapping={projectOptions}
                component={SelectField}
              />

              <div className="d-flex justify-content-between">
                <div className="mb-4">
                  <div className="font-weight-bolder">How would you rate the work?</div>
                </div>
                <Field
                  name="rating"
                  type="rating"
                  onChange={this.onRating}
                  component={RatingField}
                  size="small"
                />
              </div>

              <Field
                name="body"
                placeholder={`Describe how well ${revieweeFirstName} did in this project`}
                type="textarea"
                component={InputField}
                onBlur={this.onBody}
              />
            </Modal.Body>
            <Modal.Footer>
              <TDButton
                disabled={submitting}
                onClick={handleSubmit(this.submit)}
                variant={BS_STYLE.PRIMARY}
                label="Leave review"
              />
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    );
  }
}

ReviewForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showProjectDropdown: PropTypes.bool,
  load: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  onAfterSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  payload: PropTypes.object,
  projectOptions: PropTypes.array.isRequired,
  revieweeFirstName: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  show: PropTypes.bool,
  submitting: PropTypes.bool,
};

ReviewForm.defaultProps = {
  showProjectDropdown: true,
  payload: {},
  show: false,
  submitting: false,
};

const mapStateToProps = (state, props) => {
  const initialValues = {
    ...(props.initialValues || {}),
    ...state.dynamicForm.data,
  };

  return {
    initialValues,
  };
};

const ReviewFormRedux = reduxForm({
  form: 'reviewForm',
  enableReinitialize: true,
})(ReviewForm);
const ReviewFormConnect = connect(
  mapStateToProps,
  { load: loadFormData },
)(ReviewFormRedux);
export default withRouter(ReviewFormConnect);
