import * as type from 'projects/assets/js/actions/types';


export const projectManagersFetchAC = managers => ({
  type: type.PROJECT_MANAGERS_FETCH,
  managers,
});

export const projectProvidersFetchAC = providers => ({
  type: type.PROJECT_PROVIDERS_FETCH,
  providers,
});

export const projectReviewsFetchAC = reviews => ({
  type: type.PROJECT_REVIEWS_FETCH,
  reviews,
});

export const projectReviewsSubmitAC = review => ({
  type: type.PROJECT_REVIEWS_SUBMIT,
  review,
});

export const projectResetAC = () => ({
  type: type.PROJECT_ACTIVE_RESET,
});

export const projectReplaceProviderAC = projectMember => ({
  type: type.PROJECT_PROVIDER_REPLACE,
  projectMember,
});

export const projectReplaceManagerAC = projectMember => ({
  type: type.PROJECT_MANAGER_REPLACE,
  projectMember,
});

export const projectOutstandingDocsFetch = outstandingCounts => ({
  type: type.PROJECT_OUTSTANDING_COUNTS_FETCH,
  outstandingCounts,
});
