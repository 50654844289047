import React from 'react';

import WorksheetsWidgetItemSkeleton from './WorksheetsWidgetItemSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const WorksheetsWidgetSkeleton = () => (
  <div className="skeleton__dashboard-widget">
    <div className="skeleton__dashboard-widget__header mb-5 clearfix">
      <div className="float-left">
        <RectangleSkeleton width="150" height="30" />
      </div>
      <div className="float-right">
        <RectangleSkeleton width="170" height="30" />
      </div>
    </div>
    <div className="skeleton--my-tasks-list">
      <WorksheetsWidgetItemSkeleton />
      <WorksheetsWidgetItemSkeleton />
      <WorksheetsWidgetItemSkeleton />
    </div>
    <div className="skeleton__dashboard-widget__footer mt-5 clearfix">
      <div className="float-right">
        <RectangleSkeleton width="120" height="20" />
      </div>
    </div>
  </div>
);

export default WorksheetsWidgetSkeleton;
