import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import { loginUrl } from 'accounts/urls';
import RecaptchaField from 'core/assets/js/components/FinalFormFields/RecaptchaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { useRecaptcha } from 'accounts/assets/js/constants';

const PasswordForgotForm = ({ onSubmit, submitSucceeded }) => (
  <Form
    onSubmit={onSubmit}
    render={({ form: { getState }, handleSubmit, submitting }) => (
      <>
        {submitSucceeded && (
          <div className="col-12 text-center">
            <p>
              <strong>Thank you!</strong>
              <br />
              You will receive an email with steps to reset your password.
            </p>

            <Link
              to={loginUrl()}
              className="btn btn-primary mt-4 mb-4"
            >
              &laquo; Back to login
            </Link>
          </div>
        )}
        {!submitSucceeded && (
          <form onSubmit={handleSubmit}>
            <div className="clearfix">
              <h1 className="text-center">Forgot Password</h1>
              <TextInputField
                label="Email"
                name="email"
                placeholder="Provide your e-mail"
                required
                type="email"
              />
              {useRecaptcha && <RecaptchaField disabled={submitting} />}
            </div>
            <div id="buttons" className="row">
              <div className="col-12">
                <TDButton
                  data-testid="password-forgot-form-reset-button"
                  className={[
                    'mt-4 mb-4', getState().pristine ? 'btn--pristine' : 'btn--dirty',
                  ].join(' ')}
                  type="submit"
                  block
                  variant={BS_STYLE.PRIMARY}
                  disabled={submitting}
                  label="Send me a password reset link"
                />
              </div>
              <div className="col-12 text-center">
                <Link to={loginUrl()}>&laquo; Back to login</Link>
              </div>
            </div>
          </form>
        )}
      </>
    )}
  />
);

PasswordForgotForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

export default PasswordForgotForm;
