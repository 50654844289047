import PropTypes from 'prop-types';
import { get, isEmpty, isNil } from 'lodash';

import { SingleValidationError } from 'core/assets/js/errors';
import { isVatPrefixed } from 'finance/assets/js/lib/utils';
import {
  AVAILABLE_COMPANY_TAX_SYSTEMS,
  COMPANY_TAX_SYSTEM,
  COMPANY_TAX_SYSTEM_INFO,
  SALES_TAX_SYSTEM_FORM_FIELDS,
  SALES_TAX_SYSTEM_SERIALIZATION_FIELDS,
} from 'settings/assets/js/constants';

export const getSalesTaxSystemFormFields = () => SALES_TAX_SYSTEM_FORM_FIELDS;

export const getSalesTaxSystemSerializationFields = () => SALES_TAX_SYSTEM_SERIALIZATION_FIELDS;

export const getSalesTaxPropTypes = () => ({
  has_tax_system: PropTypes.bool,
  tax_system_type: PropTypes.number,
  vat: PropTypes.string,
  gst: PropTypes.string,
  hst: PropTypes.string,
});

export const cleanSalesTaxSystemFormParams = (formParams) => {
  const taxSystemType = formParams.tax_system_type || COMPANY_TAX_SYSTEM.NO_SYSTEM;
  const hasTaxSystem = (
    !!formParams.has_tax_system || taxSystemType !== COMPANY_TAX_SYSTEM.NO_SYSTEM
  );
  const filteredSystems = AVAILABLE_COMPANY_TAX_SYSTEMS.filter(sys => (
    !(hasTaxSystem && taxSystemType)
    || COMPANY_TAX_SYSTEM_INFO[taxSystemType].systemLabel !== sys
  ));
  // We filter out selected company tax system and empty all other tax systems
  filteredSystems.forEach((sys) => {
    Object.assign(formParams, { [sys]: '' });
  });
};

export const assertTaxSystemIsSet = (params) => {
  if (isNil(params.has_tax_system)) {
    throw new SingleValidationError('Please specify if you own a VAT, a GST or an HST registered company', 'has_tax_system');
  }
  if (params.has_tax_system) {
    const parsedSystemType = parseInt(params.tax_system_type, 10);
    switch (parsedSystemType) {
      case COMPANY_TAX_SYSTEM.VAT:
        if (!params.vat) {
          throw new SingleValidationError('Fill in your VAT number', 'vat');
        }
        break;
      case COMPANY_TAX_SYSTEM.GST:
        if (!params.gst) {
          throw new SingleValidationError('Fill in your GST number', 'gst');
        }
        break;
      case COMPANY_TAX_SYSTEM.HST:
        if (!params.hst) {
          throw new SingleValidationError('Fill in your HST number', 'hst');
        }
        break;
      default:
        throw new SingleValidationError(
          'Select a tax identification system', 'tax_system_type',
        );
    }
  }
};

export const getSalesTaxSystemErrors = (params) => {
  try {
    assertTaxSystemIsSet(params);
  } catch (e) {
    return [e];
  }
  return [];
};

export const getSerializedSalesTax = (params, { useFullVat = true } = {}) => {
  const hasTaxSystem = !!params.has_tax_system;
  const taxSystemType = params.tax_system_type || COMPANY_TAX_SYSTEM.NO_SYSTEM;
  const commonFields = {
    has_tax_system: hasTaxSystem,
    tax_system_type: taxSystemType,
    tax_system: COMPANY_TAX_SYSTEM_INFO[taxSystemType].systemLabel,
    vat: '',
    gst: '',
    hst: '',
    full_vat: '',
  };
  if (!hasTaxSystem) {
    return commonFields;
  }
  if (taxSystemType === COMPANY_TAX_SYSTEM.VAT) {
    const fullVAT = params.address
      ? `${isVatPrefixed(params.vat) ? '' : params.country_code}${params.vat}`
      : params.vat;
    let vat = params.vat;
    if (useFullVat) {
      vat = fullVAT || params.vat;
    }
    return {
      ...commonFields,
      vat,
      full_vat: fullVAT,
    };
  }
  if (taxSystemType === COMPANY_TAX_SYSTEM.GST) {
    return {
      ...commonFields,
      gst: params.gst,
    };
  }
  if (taxSystemType === COMPANY_TAX_SYSTEM.HST) {
    return {
      ...commonFields,
      hst: params.hst,
    };
  }
  return commonFields;
};

export const getSerializedSalesTaxForAPI = (params) => {
  const { vat, gst, hst } = params;
  let taxSystem = null;
  let taxIdentificationNumber = null;
  if (vat) {
    taxSystem = 'VAT';
    taxIdentificationNumber = vat;
  } else if (gst) {
    taxSystem = 'GST';
    taxIdentificationNumber = gst;
  } else if (hst) {
    taxSystem = 'HST';
    taxIdentificationNumber = hst;
  }
  return {
    is_tax_registered: !!(vat || gst || hst),
    tax_system: taxSystem,
    tax_identification_number: taxIdentificationNumber,
  };
};

export const getTaxSystemType = (params) => {
  if (params.has_tax_system) {
    if (!isEmpty(params.vat)) {
      return COMPANY_TAX_SYSTEM.VAT;
    }
    if (!isEmpty(params.gst)) {
      return COMPANY_TAX_SYSTEM.GST;
    }
    if (!isEmpty(params.hst)) {
      return COMPANY_TAX_SYSTEM.HST;
    }
    return COMPANY_TAX_SYSTEM.NO_SYSTEM;
  }
  return COMPANY_TAX_SYSTEM.NO_SYSTEM;
};

export const getInfoFromFormValues = (formValues) => {
  const taxSystemType = (
    (!isNil(formValues.has_tax_system) && !formValues.has_tax_system)
    || !formValues.tax_system_type
  )
    ? COMPANY_TAX_SYSTEM.NO_SYSTEM
    : parseInt(formValues.tax_system_type, 10);

  const hasTaxSystem = isNil(formValues.has_tax_system)
    ? !!taxSystemType
    : !!formValues.has_tax_system;

  if (!hasTaxSystem && !taxSystemType) {
    return {
      showTaxSystemSelect: false,
      showVatField: false,
      showGstField: false,
      showHstField: false,
      companyTaxSystemInfo: null,
    };
  }
  const showVatField = taxSystemType === COMPANY_TAX_SYSTEM.VAT;
  const showGstField = taxSystemType === COMPANY_TAX_SYSTEM.GST;
  const showHstField = taxSystemType === COMPANY_TAX_SYSTEM.HST;
  const companyTaxSystemInfo = COMPANY_TAX_SYSTEM_INFO[taxSystemType];
  return {
    hasTaxSystem,
    taxSystemType,
    showTaxSystemSelect: hasTaxSystem,
    showVatField,
    showGstField,
    showHstField,
    showAnySalesTaxField: (showVatField || showGstField || showHstField),
    companyTaxSystemInfo,
  };
};

export const getReportInfo = (params) => {
  const taxSystemType = get(params, 'tax_system_type');
  const ownVatCompany = get(params, 'own_vat_company');
  const vat = get(params, 'vat');
  const fullVat = get(params, 'full_vat');
  const gst = get(params, 'gst');
  const hst = get(params, 'hst');
  const showVAT = (
    taxSystemType === COMPANY_TAX_SYSTEM.VAT || (ownVatCompany && vat)
  );
  const showGST = taxSystemType === COMPANY_TAX_SYSTEM.GST;
  const showHST = taxSystemType === COMPANY_TAX_SYSTEM.HST;
  return {
    showVAT,
    vat,
    fullVat,
    showGST,
    gst,
    showHST,
    hst,
  };
};

export const getSalesTaxDetailsFromFormValues = (formValues) => {
  const hasTaxSystem = !!formValues.has_tax_system || !!formValues.tax_system_type;
  const taxSystemType = formValues.tax_system_type || COMPANY_TAX_SYSTEM.NO_SYSTEM;
  const details = {
    has_tax_system: hasTaxSystem,
    tax_system_type: taxSystemType,
    vat: formValues.vat,
    gst: formValues.gst,
    hst: formValues.hst,
  };
  cleanSalesTaxSystemFormParams(details);
  return details;
};

export const getSystemSalesTaxValues = (systemInvoicingEntity) => {
  const sysCountryCode = systemInvoicingEntity.country_code;
  return {
    has_tax_system: true,
    tax_system_type: COMPANY_TAX_SYSTEM.VAT,
    vat: systemInvoicingEntity.tax_number,
    full_vat: `${sysCountryCode}${systemInvoicingEntity.tax_number}`,
  };
};

export const getFeVat = (financialEntity) => {
  const company = financialEntity.getCompany();
  if (
    (company && company.has_tax_system)
    || (company && company.tax_system_type === COMPANY_TAX_SYSTEM.VAT)
  ) {
    return company.vat;
  }
  return '';
};

export const getFeGst = (financialEntity) => {
  const company = financialEntity.getCompany();
  if (company && (company.tax_system_type === COMPANY_TAX_SYSTEM.GST)) {
    return company.gst;
  }
  return '';
};

export const getFeHst = (financialEntity) => {
  const company = financialEntity.getCompany();
  if (company && (company.tax_system_type === COMPANY_TAX_SYSTEM.HST)) {
    return company.hst;
  }
  return '';
};

export const getFeFullVat = (financialEntity) => {
  const company = financialEntity.getCompany();
  if (
    (company && company.has_tax_system)
    || (company && company.tax_system_type === COMPANY_TAX_SYSTEM.VAT)
  ) {
    return company.full_vat || company.vat;
  }
  return '';
};

export const getFeIsVatRegistered = (financialEntity) => {
  const company = financialEntity.getCompany();
  return !!((
    get(company, 'has_tax_system') || get(company, 'is_incorporated')
  ) && get(company, 'vat'));
};

export const getFeIsGstRegistered = (financialEntity) => {
  const company = financialEntity.getCompany();
  return !!((
    get(company, 'has_tax_system') || get(company, 'is_incorporated')
  ) && get(company, 'gst'));
};

export const getFeIsHstRegistered = (financialEntity) => {
  const company = financialEntity.getCompany();
  return !!((
    get(company, 'has_tax_system') || get(company, 'is_incorporated')
  ) && get(company, 'hst'));
};

export const getFeTaxSystemType = (financialEntity) => {
  const company = financialEntity.getCompany();
  if (
    !getFeIsHstRegistered(financialEntity)
    && !getFeIsGstRegistered(financialEntity)
    && !getFeIsVatRegistered(financialEntity)
  ) {
    return null;
  }

  return company.tax_system_type;
};

export const getSalesTaxModelFields = (DataTypes, { justTaxNumbers = false } = {}) => ({
  ...(justTaxNumbers ? {} : {
    has_tax_system: {
      type: DataTypes.BOOLEAN,
      allowNull: true,
    },
    tax_system_type: {
      type: DataTypes.VIRTUAL,
      get() {
        return getTaxSystemType(this);
      },
    },
  }),
  vat: {
    type: DataTypes.STRING,
    allowNull: false,
    defaultValue: '',
  },
  gst: {
    type: DataTypes.STRING,
    allowNull: false,
    defaultValue: '',
  },
  hst: {
    type: DataTypes.STRING,
    allowNull: false,
    defaultValue: '',
  },
  tax_number: {
    type: DataTypes.VIRTUAL,
    get() {
      switch (this.tax_system_type) {
        case COMPANY_TAX_SYSTEM.VAT:
          return this.vat;
        case COMPANY_TAX_SYSTEM.GST:
          return this.gst;
        case COMPANY_TAX_SYSTEM.HST:
          return this.hst;
        default:
          return null;
      }
    },
  },
});

export const getSalesTaxPdfFields = ({
  showVAT, vat, showFullVat, fullVat, showGST, gst, showHST, hst,
}) => {
  const stack = [];
  if (showVAT) {
    stack.push(`VAT: ${(showFullVat && fullVat) ? fullVat : vat}`);
  }
  if (showGST) {
    stack.push(`GST: ${gst}`);
  }
  if (showHST) {
    stack.push(`HST: ${hst}`);
  }
  return stack;
};
