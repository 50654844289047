import React from 'react';
import PropTypes from 'prop-types';

import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import { RATE_SUGGEST_VALUE, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import withField from 'core/assets/js/components/withField.jsx';

const RateItem = ({ rate }) => {
  const rateUnitFormat = RATE_UNIT_FORMAT[rate.unit];
  const { currencySymbol, isDefault } = rate;

  return (
    <div data-testid="rate-select-field-option" className="react-select__option">
      {rate.alias}
      {isDefault && ' (Default rate)'}

      <div className="pl-2" data-testid="rate-select-field-amount">
        {rateUnitFormat && (
          <RateAmount
            amount={rate.amount}
            symbol={currencySymbol}
            unit={rate.unit}
            withRateUnit
          />
        )}
        {!rateUnitFormat && (`${currencySymbol}${rate.amount}`)}
      </div>
    </div>
  );
};

RateItem.propTypes = {
  rate: PropTypes.shape(rateSpec).isRequired,
};

// eslint-disable-next-line react/no-multi-comp
const RateSelectField = ({
  canSuggestRate,
  className,
  'data-testid': dataTestId,
  defaultOptionText,
  disabled,
  handleItemSelect,
  label,
  rates,
  sublabel,
  input,
  required,
  selectedOption,
  suggestNewRateLabel,
  ...rest
}) => {
  const options = rates.map(rate => ({
    value: rate.id,
    label: <RateItem rate={rate} />,
  }));

  if (canSuggestRate) {
    options.push({ label: <span>{suggestNewRateLabel}</span>, value: RATE_SUGGEST_VALUE });
  }

  return (
    <CustomSelectField
      data-testid={dataTestId}
      label={label}
      sublabel={sublabel}
      options={options}
      name={input.name}
      className={className}
      selectedOption={selectedOption}
      disabled={disabled}
      onItemsUpdated={handleItemSelect}
      defaultOptionText={defaultOptionText}
      required={required}
      {...rest}
    />
  );
};

RateSelectField.propTypes = {
  canSuggestRate: PropTypes.bool.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultOptionText: PropTypes.string,
  disabled: PropTypes.bool,
  handleItemSelect: PropTypes.func,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  required: PropTypes.bool,
  selectedOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  sublabel: PropTypes.string,
  suggestNewRateLabel: PropTypes.string,
};

RateSelectField.defaultProps = {
  defaultOptionText: 'Select a rate',
  label: 'Rate',
  sublabel: '',
  handleItemSelect: () => { },
  rates: [],
  selectedOption: '',
  required: false,
  disabled: false,
  suggestNewRateLabel: 'Suggest new rate',
  className: null,
  'data-testid': 'rate-select-field',
};

export default withField(RateSelectField);
