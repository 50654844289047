import React from 'react';

const BurnDownSkeleton = () => (
  <svg
    className="highcharts-root"
    data-testid="burn-down-skeleton"
    height="100%"
    style={{
      fontFamily: 'Lucida Grande, Lucida Sans, Unicode, Arial, Helvetica, sans-serif',
      fontSize: '12px',
    }}
    version="1.1"
    viewBox="0 0 711 400"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Highcharts 7.1.2</desc>
    <defs>
      <clipPath id="highcharts-vafw6dp-17-">
        <rect x="0" y="0" width="691" height="355" fill="none" />
      </clipPath>
      <clipPath id="highcharts-vafw6dp-21-">
        <rect x="0" y="0" width="937" height="355" fill="none" />
      </clipPath>
      <linearGradient x1="0" y1="0" x2="0" y2="1" id="highcharts-vafw6dp-34">
        <stop offset="0" stopColor="#d1d2d3" stopOpacity="1" />
        <stop offset="1" stopColor="rgb(255,255,255)" stopOpacity="0.1" />
      </linearGradient>
      <clipPath id="highcharts-vafw6dp-46-">
        <rect x="0" y="0" width="691" height="355" fill="none" />
      </clipPath>
    </defs>
    <rect fill="transparent" className="highcharts-background" x="0" y="0" width="711" height="400" rx="0" ry="0" />
    <rect fill="none" className="highcharts-plot-background" x="10" y="10" width="691" height="355" />
    <rect fill="none" className="highcharts-plot-border" data-z-index="1" x="10" y="10" width="691" height="355" />
    <g className="highcharts-grid highcharts-yaxis-grid" data-z-index="1">
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 10 365.5 L 701 365.5" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 10 306.5 L 701 306.5" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 10 247.5 L 701 247.5" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 10 188.5 L 701 188.5" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 10 128.5 L 701 128.5" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 10 69.5 L 701 69.5" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 10 9.5 L 701 9.5" opacity="1" />
    </g>
    <g className="highcharts-grid highcharts-xaxis-grid" data-z-index="2">
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 9.5 10 L 9.5 365" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 96.5 10 L 96.5 365" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 185.5 10 L 185.5 365" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 274.5 10 L 274.5 365" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 365.5 10 L 365.5 365" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 454.5 10 L 454.5 365" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 544.5 10 L 544.5 365" opacity="1" />
      <path fill="none" stroke="#f4f4f4" strokeWidth="1" data-z-index="1" className="highcharts-grid-line" d="M 632.5 10 L 632.5 365" opacity="1" />
    </g>
    <g className="highcharts-axis highcharts-yaxis" data-z-index="2">
      <path fill="none" className="highcharts-axis-line" data-z-index="7" d="M 701 10 L 701 365" />
    </g>
    <path fill="none" className="highcharts-crosshair highcharts-crosshair-thin undefined" data-z-index="2" stroke="#cccccc" strokeWidth="1" style={{ visibility: 'hidden', d: 'M 700.5 10 L 700.5 365' }} />
    <g className="highcharts-series-group" data-z-index="3">
      <g data-z-index="0.1" className="highcharts-series highcharts-series-0 highcharts-line-series  " transform="translate(10,10) scale(1 1)" clipPath="url(#highcharts-vafw6dp-46-)">
        <path fill="none" d="M 0 59.166666666666686 L 691 355" className="highcharts-graph" data-z-index="1" stroke="#a7a9aa" strokeWidth="1.5" strokeLinejoin="round" strokeLinecap="round" />
        <path fill="none" d="M -10 59.166666666666686 L 0 59.166666666666686 L 691 355 L 701 355" visibility="visible" data-z-index="2" className="highcharts-tracker-line" strokeLinejoin="round" stroke="rgba(192,192,192,0.0001)" strokeWidth="21.5" />
      </g>
      <g data-z-index="0.1" className="highcharts-markers highcharts-series-0 highcharts-line-series  highcharts-tracker " transform="translate(10,10) scale(1 1)" clipPath="none">
        <path fill="#a7a9aa" visibility="hidden" d="M 691 355 A 0 0 0 1 1 691 355 Z" className="highcharts-halo highcharts-color-undefined" data-z-index="-1" fillOpacity="0.25" />
        <path fill="#c5c8cc" d="M 691.5 361 A 5.5 5.5 0 1 1 691.5054999990833 360.9999972500002 Z" stroke="#ffffff" strokeWidth="2.5" opacity="1" visibility="hidden" />
      </g>
      <g data-z-index="0.1" className="highcharts-series highcharts-series-1 highcharts-area-series highcharts-color-0  " transform="translate(10,10) scale(1 1)" clipPath="url(#highcharts-vafw6dp-46-)" opacity="1">
        <path fill="url(#highcharts-vafw6dp-34)" d="M 0 59.166666666666686 L 45.400853357694 73.95833333333331 L 86.408075745289 103.54166666666666 L 131.74790645062 147.91666666666666 L 175.68421615162 162.70833333333334 L 221.08506950931 174.54166666666666 L 265.0213792103 192.29166666666666 L 310.422232568 207.08333333333334 L 355.82308592569 213 L 399.75939562669 230.75 L 445.22127163675 245.54166666666669 L 489.15758133774 266.25 L 489.15758133774 355 L 445.22127163675 355 L 399.75939562669 355 L 355.82308592569 355 L 310.422232568 355 L 265.0213792103 355 L 221.08506950931 355 L 175.68421615162 355 L 131.74790645062 355 L 86.408075745289 355 L 45.400853357694 355 L 0 355" className="highcharts-area" data-z-index="0" />
        <path fill="none" d="M 0 59.166666666666686 L 45.400853357694 73.95833333333331 L 86.408075745289 103.54166666666666 L 131.74790645062 147.91666666666666 L 175.68421615162 162.70833333333334 L 221.08506950931 174.54166666666666 L 265.0213792103 192.29166666666666 L 310.422232568 207.08333333333334 L 355.82308592569 213 L 399.75939562669 230.75 L 445.22127163675 245.54166666666669 L 489.15758133774 266.25" className="highcharts-graph" data-z-index="1" stroke="#c5c8cc" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" />
        <path fill="none" d="M -10 59.166666666666686 L 0 59.166666666666686 L 45.400853357694 73.95833333333331 L 86.408075745289 103.54166666666666 L 131.74790645062 147.91666666666666 L 175.68421615162 162.70833333333334 L 221.08506950931 174.54166666666666 L 265.0213792103 192.29166666666666 L 310.422232568 207.08333333333334 L 355.82308592569 213 L 399.75939562669 230.75 L 445.22127163675 245.54166666666669 L 489.15758133774 266.25 L 499.15758133774 266.25" visibility="visible" data-z-index="2" className="highcharts-tracker-line" strokeLinejoin="round" stroke="rgba(192,192,192,0.0001)" strokeWidth="22" />
      </g>
      <g data-z-index="0.1" className="highcharts-markers highcharts-series-1 highcharts-area-series highcharts-color-0  highcharts-tracker " transform="translate(10,10) scale(1 1)" clipPath="none" opacity="1">
        <path fill="#c5c8cc" d="M 2.143131898507868e-16 62.666666666666686 A 3.5 3.5 0 1 1 0.0034999994166689365 62.66666491666683 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 45 77.45833333333331 A 3.5 3.5 0 1 1 45.00349999941667 77.45833158333346 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 86 107.04166666666666 A 3.5 3.5 0 1 1 86.00349999941668 107.0416649166668 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 131 151.41666666666666 A 3.5 3.5 0 1 1 131.00349999941668 151.4166649166668 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 175 166.20833333333334 A 3.5 3.5 0 1 1 175.00349999941668 166.2083315833335 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 221 178.04166666666666 A 3.5 3.5 0 1 1 221.00349999941668 178.0416649166668 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 265 195.79166666666666 A 3.5 3.5 0 1 1 265.00349999941665 195.7916649166668 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 310 210.58333333333334 A 3.5 3.5 0 1 1 310.00349999941665 210.5833315833335 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 355 216.5 A 3.5 3.5 0 1 1 355.00349999941665 216.49999825000015 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 399 234.25 A 3.5 3.5 0 1 1 399.00349999941665 234.24999825000015 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 445 249.04166666666669 A 3.5 3.5 0 1 1 445.00349999941665 249.04166491666683 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
        <path fill="#c5c8cc" d="M 489 269.75 A 3.5 3.5 0 1 1 489.00349999941665 269.74999825000015 Z" stroke="#ffffff" strokeWidth="1.5" opacity="1" className="highcharts-point highcharts-color-0" />
      </g>
    </g>
  </svg>
);

export default BurnDownSkeleton;
