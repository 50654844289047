import axios from 'core/assets/js/lib/tdAxios';
import { invitationLoadSuccessAC, invitationLoadFailureAC } from 'invitations/assets/js/ducks/invitation';


export const verifyEmailDS = (token, url = '', authedAxios = null) => ( //eslint-disable-line
  (dispatch) => {
    const apiUrl = `${url}/api/accounts/email-verify`;
    return (authedAxios || axios).post(apiUrl, { token })
      .then(resp => (
        dispatch(invitationLoadSuccessAC(resp.data))
      ))
      .catch((err) => {
        dispatch(invitationLoadFailureAC(err));
      });
  }
);
