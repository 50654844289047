import React from 'react';
import PropTypes from 'prop-types';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import { IMG_SIZE } from 'core/assets/js/constants';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';

const ProjectChartStackedSkeleton = ({ noData, noDataMsg }) => (
  <div
    className={`mb-4 skeleton__chart-stacked skeleton__chart-stacked--with-grid ${noData && 'skeleton__chart-stacked--no-data'}`}
  >
    <div
      className="graph-container d-flex"
    >
      <div
        className="y-axis d-flex flex-column align-items-center justify-content-between my-4"
      >
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
        <RectangleSkeleton height="5" width="20" />
      </div>
      <div
        className={`data-area d-flex flex-direction-column ${noData ? 'align-items-center' : 'align-items-end'} justify-content-around my-4 mr-4`}
      >
        {noData && (
          <div className="discreet chart-empty__text--stacked">{noDataMsg}</div>
        )}
        {!noData && (
          <React.Fragment>
            <div
              className="data-area--column d-flex flex-column"
            >
              <RectangleSkeleton height="80" width="30" />
              <RectangleSkeleton height="70" width="30" />
              <ProfilePic
                size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
              />
            </div>
            <div
              className="data-area--column d-flex flex-column"
            >
              <RectangleSkeleton height="100" width="30" />
              <RectangleSkeleton height="90" width="30" />
              <ProfilePic
                size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
              />
            </div>
            <div
              className="data-area--column d-flex flex-column"
            >
              <RectangleSkeleton height="140" width="30" />
              <RectangleSkeleton height="70" width="30" />
              <ProfilePic
                size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
              />
            </div>
            <div
              className="data-area--column d-none d-sm-flex flex-column"
            >
              <RectangleSkeleton height="70" width="30" />
              <RectangleSkeleton height="80" width="30" />
              <ProfilePic
                size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
              />
            </div>
            <div
              className="data-area--column d-none d-lg-flex flex-column"
            >
              <RectangleSkeleton height="100" width="30" />
              <RectangleSkeleton height="100" width="30" />
              <ProfilePic
                size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  </div>
);

ProjectChartStackedSkeleton.propTypes = {
  noData: PropTypes.bool,
  noDataMsg: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ProjectChartStackedSkeleton.defaultProps = {
  noData: false,
  noDataMsg: 'No data to display',
};

export default ProjectChartStackedSkeleton;
