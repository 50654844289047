import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import { taskAssignmentSpec, taskAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { orgUserProfileUrl } from 'people/urls';
import { projectViewTaskUrl } from 'projects/urls';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { TASK_ASSIGNMENT_STATUS } from 'projects/assets/js/constants';
import { TASK_TABS, IMG_SIZE, USER_TYPE } from 'core/assets/js/constants';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import UserTypeIndicator from 'people/assets/js/components/UserTypeIndicator.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

const ASSIGNMENT_STATUS_TO_CLASS = {
  [TASK_ASSIGNMENT_STATUS.PENDING]: 'pending',
  [TASK_ASSIGNMENT_STATUS.ACCEPTED]: null,
  [TASK_ASSIGNMENT_STATUS.DECLINED]: 'removed',
  [TASK_ASSIGNMENT_STATUS.REMOVED]: 'removed',
  [TASK_ASSIGNMENT_STATUS.CANCELLED]: 'inactive',
  [TASK_ASSIGNMENT_STATUS.LEFT]: 'inactive',
  [TASK_ASSIGNMENT_STATUS.COMPLETED]: 'completed',
};

const ASSIGNMENT_STATUS_TO_MESSAGE = {
  [TASK_ASSIGNMENT_STATUS.PENDING]: profile => `${profile.firstName} hasn't accepted the task yet`,
  [TASK_ASSIGNMENT_STATUS.ACCEPTED]: profile => profile.name,
  [TASK_ASSIGNMENT_STATUS.DECLINED]: profile => `${profile.firstName} has declined the task`,
  [TASK_ASSIGNMENT_STATUS.REMOVED]: profile => `${profile.firstName} was removed from the task`,
  [TASK_ASSIGNMENT_STATUS.CANCELLED]: profile => `The invitation for ${profile.firstName} was cancelled`,
  [TASK_ASSIGNMENT_STATUS.LEFT]: profile => `${profile.firstName} has left the task`,
  [TASK_ASSIGNMENT_STATUS.COMPLETED]: profile => `${profile.firstName} has completed their work`,
};


const TaskParticipant = ({
  assignment,
  user,
  match: { params: { orgAlias, id: projectId, taskId } },
  className,
  pendingActionsCount,
  allowedActions,
  isOwner,
  isManager,
  showCollapsibleDetails,
  activeUserCard,
}) => {
  const {
    canMessageAssignees, canMessageOwner, canMessageManagers, canMessageTaskManagers,
  } = allowedActions;
  const { id: userId, profile, profile: { avatar, name, jobTitle } } = user;
  const isAssignee = !isEmpty(assignment);
  const status = isAssignee ? assignment.status : null;

  const cssClasses = ['task-participant'];
  if (className) {
    cssClasses.push(className);
  }

  let tooltipMessage = name;
  if (status !== null && typeof ASSIGNMENT_STATUS_TO_MESSAGE[status] === 'function') {
    tooltipMessage = ASSIGNMENT_STATUS_TO_MESSAGE[status](profile);
  }

  if (isOwner) {
    tooltipMessage += ' (Task Owner)';
  } else if (isManager) {
    tooltipMessage += ' (Manager)';
  }

  if (pendingActionsCount > 0) {
    cssClasses.push('task-participant--with-pending-actions');
  }

  if (status !== null && ASSIGNMENT_STATUS_TO_CLASS[status]) {
    cssClasses.push(
      'task-participant--with-status',
      `task-participant--${ASSIGNMENT_STATUS_TO_CLASS[status]}`,
    );
  }

  const hasPrivateDiscussion = (
    (isManager && canMessageManagers)
    || (isManager && canMessageTaskManagers)
    || (isOwner && canMessageOwner)
    || (isAssignee && canMessageAssignees)
  );

  // Use the public discussion board for ourselves
  const discussionBoardUrl = activeUserCard.user.id === userId
    ? projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION)
    : projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, null, userId);

  let url;
  if (hasPrivateDiscussion) {
    url = discussionBoardUrl;
  } else {
    const userType = isManager || isOwner ? USER_TYPE.MANAGER : USER_TYPE.PROVIDER;
    url = orgUserProfileUrl(orgAlias, userType, userId);
  }

  return (
    <TDElementWithTooltip
      key={`task-participant-user-${userId}`}
      delay={200}
      tooltipMsg={tooltipMessage}
    >
      <li
        className={cssClasses.join(' ')}
      >
        <Link to={url}>
          <ProfilePic
            url={avatar}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
          />
          {(isOwner || isManager) && (
            <UserTypeIndicator
              userType={USER_TYPE.MANAGER}
              showTooltip={false}
            />
          )}
          {showCollapsibleDetails && (
            <span className="pl-3 hide-on-collapsed">
              <strong className="text-truncate">{name}</strong>
              <br />
              <span className="text-truncate">{jobTitle}</span>
            </span>
          )}
        </Link>
      </li>
    </TDElementWithTooltip>
  );
};

TaskParticipant.propTypes = {
  user: userSpec.isRequired,
  activeUserCard: userCardSpec,
  match: routerMatchSpec.isRequired,
  className: PropTypes.string,
  assignment: taskAssignmentSpec,
  pendingActionsCount: PropTypes.number,
  showCollapsibleDetails: PropTypes.bool,
  allowedActions: taskAllowedActionsSpec,
  isOwner: PropTypes.bool,
  isManager: PropTypes.bool,
};

TaskParticipant.defaultProps = {
  className: '',
  assignment: {},
  pendingActionsCount: 0,
  showCollapsibleDetails: false,
  allowedActions: {},
  isOwner: false,
  isManager: false,
  activeUserCard: {},
};

const mapStateToProps = state => ({ activeUserCard: selectActiveUserCard(state) });
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskParticipantConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskParticipant);

export default withRouter(TaskParticipantConnected);
