import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { ICON } from 'core/assets/js/constants';
import { withRouter } from 'react-router-dom';

export const SignupVerifyView = ({ location }) => (
  <React.Fragment>
    <div className="page page--signup-verify">
      <div className="container">
        <Card className="card card--signup-verify text-center">
          <Card.Body className="flex-column">
            <div className="col-12 col-lg-6 offset-lg-3">
              <i className={`card--signup-verify__icon mb-4 ${ICON.ENVELOPE_OPEN_DUOTONE}`} />
              <h1 data-testid="check-verification-email">Check your email to verify your account</h1>
              {(
                location.state && location.state.email && (
                  <p className="mb-0">
                    We&rsquo;ve sent a message to
                    <strong>{` ${location.state.email} `}</strong>
                    with a link to activate your account.
                  </p>
                ))
                || (
                  <p className="mb-0">
                    We&rsquo;ve sent you a message with a link to activate your account.
                  </p>
                )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  </React.Fragment>
);
SignupVerifyView.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(SignupVerifyView);
