import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { timeOffEditUrl } from 'accounts/urls';
import { API_DATE_FORMAT, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { timeoffSpec } from 'accounts/assets/js/lib/objectSpecs';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';

const TimeOffCardItem = ({ item, onDelete, orgAlias, history }) => {
  const { periodStart, periodEnd } = item;
  const periodStartFormatted = moment(periodStart, API_DATE_FORMAT)
    .format(DATE_FORMAT_DEFAULT);
  const periodEndFormatted = moment(periodEnd, API_DATE_FORMAT)
    .format(DATE_FORMAT_DEFAULT);
  return (
    <div className="timeOff-list-item">
      <div className="row">
        <div className="col-9">
          <h3 className="timeOff-list-item__period">
            {periodStartFormatted}
            {' - '}
            {periodEndFormatted}
          </h3>
          <div className="pl-0">
            <span className="timeOff-list-item__reason">
              {item.reason}
            </span>
          </div>
        </div>

        <div className="col-3 d-flex align-items-center justify-content-end">
          <TDDropButton>
            <Dropdown.Item
              eventKey="delete"
              onClick={() => history.push(timeOffEditUrl(orgAlias, item.id))}
            >
              Edit
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="delete"
              onClick={() => onDelete(item.id)}
            >
              Delete
            </Dropdown.Item>
          </TDDropButton>
        </div>
      </div>
    </div>
  );
};

TimeOffCardItem.propTypes = {
  item: timeoffSpec.isRequired,
  onDelete: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(TimeOffCardItem);
