import React from 'react';
import PropTypes from 'prop-types';

const NoDataPieChart = ({ currencySymbol }) => (
  <div className="pie-chart-container__no-data d-flex align-items-center justify-content-center">
    <div className="no-data-msg d-flex align-items-center justify-content-center flex-column">
      <span>Total</span>
      <span>{`${currencySymbol}0.00`}</span>
    </div>
    <svg
      version="1.1"
      className="highcharts-root"
      style={{ fontFamily: 'Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif', fontSize: '12px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 261 261"
    >
      <defs>
        <clipPath id="highcharts-zu9c75e-3-">
          <rect x="0" y="0" width="261" height="261" fill="none" />
        </clipPath>
      </defs>
      <g className="highcharts-series-group" data-z-index="3">
        <g
          data-z-index="0.1"
          className="highcharts-series highcharts-series-1 highcharts-pie-series  highcharts-tracker                               "
          transform="translate(0,0) scale(1 1)"
        >
          <path
            fill="rgb(216,216,216)"
            d="M 128.98020296460084 31.800002016062834 A 97.2 97.2 0 1 1 128.86499115001945 31.800093762337255 M 128.91899469001166 70.68005625740236 A 58.32 58.32 0 1 0 128.9881217787605 70.68000120963771"
            transform="translate(0,0)"
            stroke="#ffffff"
            strokeWidth="1"
            opacity="1"
            strokeLinejoin="round"
            className="highcharts-point highcharts-color-0"
          />
        </g>
      </g>
    </svg>
  </div>
);

NoDataPieChart.propTypes = {
  currencySymbol: PropTypes.string,
};

NoDataPieChart.defaultProps = {
  currencySymbol: '',
};

export default NoDataPieChart;
