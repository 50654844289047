import React from 'react';

import { Field, reduxForm } from 'redux-form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';

export const emailFormId = 'email-form';

const EmailForm = () => (
  <form>
    <Field name="email" type="text" component={InputField} label="Email" placeholder="" />
  </form>
);

const ReduxEmailForm = reduxForm(
  { form: emailFormId },
)(EmailForm);

export default ReduxEmailForm;
