import React from 'react';
import { Card } from 'react-bootstrap';

import { ICON, USER_CARD_STATUS_CLASS, USER_CARD_STATUS } from 'core/assets/js/constants';
import { settingsAccountUrl } from 'settings/urls';
import { UNKNOWN_ALIAS } from 'organizations/assets/js/constants';

const renderCard = (withHeader) => {
  const header = (
    <div className="dark">
      Card With Header
    </div>
  );

  const footer = (
    <div>
      <span className="tag">Adobe CC</span>
      &nbsp;
      <span className="tag">Sketch</span>
      &nbsp;
      <span className="tag">HTML/CSS</span>
    </div>
  );

  return (
    <Card
      className="p-3 mb-3"
    >
      {withHeader && (
        <Card.Header>
          {header}
        </Card.Header>
      )}
      <Card.Body>
        Card content
      </Card.Body>
      <Card.Footer>
        {footer}
      </Card.Footer>
    </Card>
  );
};

const renderCardWithStatus = (withHeader, status = USER_CARD_STATUS.APPROVED, content = 'Card Content') => {
  const header = (
    <div className="dark">
      Card With Header
    </div>
  );

  return (
    <div>
      <Card
        className={`mb-3 card--${USER_CARD_STATUS_CLASS[status]}`}
      >
        {withHeader && (
          <Card.Header>
            {header}
          </Card.Header>
        )}
        <Card.Body>
          {content}
        </Card.Body>
      </Card>
    </div>
  );
};

const orgCardExample = {
  desc: 'You should fill in your profile details',
  next: settingsAccountUrl(UNKNOWN_ALIAS),
  organization: {
    website: 'talendesk.io',
    name: 'Talentdesk',
  },
  status: 1,
};

function IconsSection() {
  return (
    <section id="cards">
      <h2>Cards</h2>
      <h3>Simple Cards</h3>
      <div className="clearfix">
        {renderCard()}
      </div>

      <div className="clearfix">
        {renderCard(true)}
      </div>

      <h3>Cards with Status</h3>
      <div className="clearfix">
        {renderCardWithStatus(false, USER_CARD_STATUS.PENDING, 'Pending Card')}
        {renderCardWithStatus(false, USER_CARD_STATUS.APPROVED, 'Approved Card')}
        {renderCardWithStatus(false, USER_CARD_STATUS.REJECTED, 'Rejected Card')}
      </div>
      <h3>Organisation Card Example</h3>
      <div className="clearfix">
        <Card
          className={`organization-list-item card--${USER_CARD_STATUS_CLASS[orgCardExample.status]}`}
        >
          <Card.Body>
            <div className="row">
              <div className="col-10">
                <span className="organization-list-item__title">
                  {orgCardExample.organization.name}
                </span>
                <div className="organization-list-item__meta">
                  <span>
                    <i className={ICON.LINK} />
                    {' '}
                    {orgCardExample.organization.website || '-'}
                  </span>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <div className="row">
              <div className="col-12 d-flex justify-content-between flex-column flex-sm-row">
                <span className="organization-list-item__status text-white">{USER_CARD_STATUS_CLASS[orgCardExample.status].toUpperCase()}</span>
                <a className="text-white" href="#">{`${orgCardExample.desc}.`}</a>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </section>
  );
}

export default IconsSection;
