import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { groupBy } from 'lodash';

import { parse as queryParser } from 'query-string';
import SkillSearchForm from 'core/assets/js/components/SkillSearchForm.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import SkillSearchResults from 'core/assets/js/components/SkillSearchResults.jsx';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { skillSearchApiUrl, skillCountsByLetterApiUrl } from 'skills/urls';
import SkillSelectSectionSkeleton from 'core/assets/js/components/Skeleton/SkillSelectSectionSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { getIsModalOpen, modalCloseAC, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

export const MODAL_ID = 'skills-directory-add-skill-modal';
const SEARCH_KEYWORD_MIN_LENGTH = 2;

class SkillDirectoryAddSkillsPanel extends React.Component {
  static GetComponentName() {
    return 'SkillDirectoryAddSkillsPanel';
  }

  constructor(props) {
    super(props);
    this.props = props;
    this.constantFilters = {
      paginationEnabled: false,
    };

    const { initiallySelected } = this.props;
    const _initiallySelected = initiallySelected || [];
    this.state = {
      query: {},
      showSelected: false,
      selectedItems: [..._initiallySelected],
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleToggleItem = this.handleToggleItem.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.initValues = this.initValues.bind(this);
    this.getSelectedItem = this.getSelectedItem.bind(this);
    this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { initiallySelected } = this.props;
    if (initiallySelected !== prevProps.initiallySelected) {
      const _initiallySelected = initiallySelected || [];
      this.initValues([..._initiallySelected]);
    }
  }

  getSelectedItem({ id }) {
    const { selectedItems } = this.state;
    if (!selectedItems) {
      return null;
    }

    const selectedItem = selectedItems.find(({ id: itemId }) => itemId === id);

    return selectedItem;
  }

  isItemSelected({ id }) {
    const { selectedItems } = this.state;
    if (!selectedItems) {
      return false;
    }

    const isItemSelected = selectedItems.find(({ id: itemId }) => (itemId === id));

    return !!isItemSelected;
  }

  handleCloseModal() {
    const { dispatch, initiallySelected } = this.props;
    this.setState({
      selectedItems: initiallySelected, showSelected: false, query: {},
    });
    dispatch(modalCloseAC(MODAL_ID));
  }

  handleToggleItem(selectedItem) {
    const { selectedItems } = this.state;
    const isSelected = this.isItemSelected(selectedItem);

    if (isSelected) {
      // Remove Item
      this.handleRemoveSkill(selectedItem.id);
    } else {
      // Add Item
      this.setState({ selectedItems: [...selectedItems, selectedItem] });
    }
  }

  initValues(values) {
    this.setState({
      selectedItems: values,
    });
  }

  handleQueryUpdate(newQuery) {
    this.setState({ query: { ...this.constantFilters, ...newQuery } });
  }

  handleRemoveSkill(skillId) {
    const { selectedItems } = this.state;
    this.setState({ selectedItems: selectedItems.filter(skill => skill.id !== skillId) });
  }


  render() {
    const {
      isOpen, handleSubmitSelected, lists, skillSearchResult, memberSkillsOnly,
      organization: { id: orgId }, skillDirectory,
    } = this.props;

    const {
      selectedItems, showSelected, query, query: { kw },
    } = this.state;

    const counts = lists && lists['skill-counts'] && lists['skill-counts'].items;
    const shouldShowSearchResults = kw && kw.length > SEARCH_KEYWORD_MIN_LENGTH;
    // Generate an array containing the letters that contain skills based on the counts response.
    const type = 'skill';
    const groups = groupBy(lists['skill-list']?.items, (item) => item?.label?.toLowerCase()[0] || item?.name?.toLowerCase()[0]);

    return (
      <ModalPanel
        modalId={MODAL_ID}
        open={isOpen}
        onClose={this.handleCloseModal}
        heading={`Manage skills for skill directory "${skillDirectory.name}"`}
        body={(
          <div className="skill-select-panel no-select">
            <div className={showSelected ? 'd-none' : ''}>
              <div className="row">
                <div className="col-12 col-md-6 ml-md-n3 px-0">
                  <SkillSearchForm
                    initialValues={{ kw }}
                    query={query}
                    onFiltersChanged={this.handleQueryUpdate}
                  />
                </div>
              </div>

              <TDApiConnected
                duck="list"
                storeKey="skill-counts"
                loadingEnabled={false}
                shouldRefetchOnQueryChange={false}
                fetchData={
                  ({ dispatch, authedAxios, componentName }) => {
                    const url = skillCountsByLetterApiUrl({
                      memberSkillsOnly,
                      onlySystemSkills: false,
                      excludeDefaultSkills: true,
                      orgId,
                    });
                    return dispatch(fetchListDS({
                      url, componentName, authedAxios,
                    }));
                  }}
              />

              { !counts && !shouldShowSearchResults && (
                <SkillSelectSectionSkeleton />
              )}

              <TDApiConnected
                duck="list"
                storeKey="skill-list"
                shouldRefetchOnQueryChange={false}
                fetchData={({ dispatch, authedAxios, componentName }) => {
                  const url = skillSearchApiUrl({
                    term: '',
                    paginationEnabled: false,
                    memberSkillsOnly,
                    onlySystemSkills: false,
                    excludeDefaultSkills: true,
                    orgId,
                  });
                  return dispatch(fetchListDS({
                    url, componentName, authedAxios,
                  }));
                }}
                skeletonComponent={SkillSelectSectionSkeleton}
              >
                {!shouldShowSearchResults
                  && Object.keys(groups)?.sort().map((letter) => {
                    const letterCount = groups[letter].length;
                    return (
                      <div key={letter}>
                        <h3 className="mb-3 text-uppercase">
                          {letter}
                          <span className="ml-2 discreet font-weight-normal">
                            {`(${letterCount})`}
                          </span>
                        </h3>

                        <div className="px-0">
                          <div className="skill-list">
                            {groups[letter]?.map(skill => {
                              const isSelected = this.isItemSelected(skill);
                              if (isSelected) {
                                Object.assign(skill, this.getSelectedItem(skill));
                              }

                              return (
                                <SkillTag
                                  key={skill.id}
                                  skill={skill}
                                  isSelected={isSelected}
                                  onClick={() => this.handleToggleItem(skill)}
                                  showOrgIndication={false}
                                  isSelectable
                                  showEndorsementIndication={false}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </TDApiConnected>
            </div>

            <TDApiConnected
              duck="list"
              storeKey={SkillDirectoryAddSkillsPanel.GetComponentName()}
              fetchData={
                ({ dispatch, authedAxios, componentName, querystring }) => {
                  const { kw: term } = queryParser(querystring);
                  if (!term || term.length <= SEARCH_KEYWORD_MIN_LENGTH) {
                    return Promise.resolve();
                  }

                  const url = skillSearchApiUrl({
                    term,
                    paginationEnabled: false,
                    memberSkillsOnly,
                    onlySystemSkills: false,
                    excludeDefaultSkills: true,
                    orgId,
                  });
                  return dispatch(fetchListDS({
                    url, componentName, authedAxios,
                  }));
                }}
              loadingEnabled={false}
              skeletonComponent={SkillSelectSectionSkeleton}
              query={query}
            >
              { shouldShowSearchResults && (
                <SkillSearchResults
                  skills={skillSearchResult}
                  isSkillSelected={this.isItemSelected}
                  onSkillClick={this.handleToggleItem}
                  getSelectedItem={this.getSelectedItem}
                />
              )}
            </TDApiConnected>

            {showSelected && (
              <div className="skill-list mb-n5">
                {selectedItems && selectedItems.length === 0 && (
                  <p className="discreet">You haven&apos;t selected any skill yet</p>
                )}

                <SkillSearchResults
                  skills={selectedItems}
                  isSkillSelected={this.isItemSelected}
                  onSkillClick={this.handleToggleItem}
                  getSelectedItem={this.getSelectedItem}
                />
              </div>
            )}
          </div>
        )}
        footer={(
          <React.Fragment>
            <div>
              <label
                onClick={() => {
                  this.setState({ showSelected: !showSelected, query: {} });
                }}
                className={`d-flex align-items-center mb-5 mb-md-0 cursor-pointer ${selectedItems.length === 0 ? 'discreet' : ''}`}
              >
                <TDSwitch
                  disabled={!selectedItems || selectedItems.length === 0}
                  className="mr-3"
                  selected={showSelected}
                />
                {`Show selected ${pluralize(type, selectedItems.length)}`}
              </label>
            </div>

            <div className="ml-auto">
              <TDButton
                variant={BS_STYLE.DEFAULT}
                onClick={this.handleCloseModal}
                label="Close"
              />

              <TDButton
                className="ml-4"
                variant={BS_STYLE.PRIMARY}
                disabled={!selectedItems} // Allow the selection of empty array
                onClick={() => {
                  this.handleCloseModal();
                  handleSubmitSelected(selectedItems);
                }}
                label={`Save ${selectedItems.length > 0 ? ` ${selectedItems.length} ` : ''} ${pluralize(type, selectedItems.length)}`}
              />
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}
SkillDirectoryAddSkillsPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  memberSkillsOnly: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  skillSearchResult: PropTypes.array,
  handleSubmitSelected: PropTypes.func.isRequired,
  lists: PropTypes.object.isRequired,
  initiallySelected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  organization: PropTypes.object.isRequired,
  skillDirectory: PropTypes.object.isRequired,
};
SkillDirectoryAddSkillsPanel.defaultProps = {
  initiallySelected: [],
  skillSearchResult: [],
};

const mapStateToProps = (state, props) => {
  const skillListState = getListState(state, SkillDirectoryAddSkillsPanel.GetComponentName());
  const modalPanelPayload = getModalPayload(state, MODAL_ID);
  return {
    skillSearchResult: skillListState.items,
    isOpen: getIsModalOpen(state, MODAL_ID),
    lists: state.list,
    selectionFilter: modalPanelPayload?.selectionFilter,
    initiallySelected: modalPanelPayload?.initiallySelected || props.initiallySelected,
    organization: selectActiveOrg(state),
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });
const SkillDirectoryAddSkillsPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkillDirectoryAddSkillsPanel);

export default SkillDirectoryAddSkillsPanelConnected;
