import { PEOPLE_LIST_VIEW_TYPE_KEY, VIEW_TYPES } from 'core/assets/js/constants';
import Storage from 'core/assets/js/lib/Storage';

// Action Types
export const SETTINGS = {
  PEOPLE_LIST_VIEW_TYPE_SET: 'PEOPLE_LIST_VIEW_TYPE_SET',
};

// Reducer
export const initialState = {
  peopleListViewType: VIEW_TYPES.GRID,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS.PEOPLE_LIST_VIEW_TYPE_SET:
      return {
        ...state,
        peopleListViewType: action.peopleListViewType,
      };
    default:
      return state;
  }
};

// Action creators
export const setPeopleListViewTypeAC = peopleListViewType => {
  Storage.set(PEOPLE_LIST_VIEW_TYPE_KEY, peopleListViewType);
  return { peopleListViewType, type: SETTINGS.PEOPLE_LIST_VIEW_TYPE_SET };
};

export const getPeopleListViewType = store => store.settings.peopleListViewType;

export default reducer;
