import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import { ICON } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import Breadcrumbs from 'core/assets/js/components/Breadcrumbs.jsx';
import { REPORT_TYPES, REPORT_TYPES_LABEL } from 'finance/assets/js/constants';
import {
  financeInvoicesUrl,
  financePurchaseOrderListUrl,
  financeWorksheetsUrl,
  financeManagersBudgetUrl,
  financeExpensesUrl,
  financeProFormaInvoicesUrl,
} from 'finance/urls';

class ReportViewHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      reasonModal: {
        open: false,
        reason: null,
        heading: '',
      },
    };

    this.handleShowNoteModal = this.handleShowNoteModal.bind(this);
    this.handleCloseNoteModal = this.handleCloseNoteModal.bind(this);
  }

  handleShowNoteModal(reason) {
    const heading = 'Notes';

    this.setState({
      reasonModal: {
        open: true,
        reason,
        heading,
      },
    });
  }

  handleCloseNoteModal() {
    this.setState({
      reasonModal: {
        open: false,
        reason: null,
      },
    });
  }

  render() {
    const { heading, report, reportType, activeOrg, ctaComponent } = this.props;
    const { reasonModal } = this.state;

    const reason = report?.notes || '';
    const tooltipMsg = reason ? 'View notes' : '';

    const orgAlias = activeOrg.unique_alias;
    const reportToListMapping = {
      [REPORT_TYPES.INVOICE]: financeInvoicesUrl(orgAlias),
      [REPORT_TYPES.PURCHASE_ORDER]: financePurchaseOrderListUrl(orgAlias),
      [REPORT_TYPES.MANAGER_BUDGET]: financeManagersBudgetUrl(orgAlias),
      [REPORT_TYPES.WORKSHEET]: financeWorksheetsUrl(orgAlias),
      [REPORT_TYPES.PROJECT_BUDGET]: null,
      [REPORT_TYPES.EXPENSE]: financeExpensesUrl(orgAlias),
      [REPORT_TYPES.PRO_FORMA_INVOICE]: financeProFormaInvoicesUrl(orgAlias),
    };
    const breadcrumbs = [
      {
        // Append 's' if report type is not manager budget
        title: reportType !== REPORT_TYPES.MANAGER_BUDGET ? `${REPORT_TYPES_LABEL[reportType]}s` : REPORT_TYPES_LABEL[reportType],
        url: reportToListMapping[reportType],
      },
      {
        title: (
          <React.Fragment>
            { heading || <RectangleSkeleton width="183" height="25" /> }

            {reason && (
              <TDElementWithTooltip
                el={(
                  <a className="finance-report__heading-reason">
                    <i
                      className={ICON.NOTE}
                      onClick={() => {
                        this.handleShowNoteModal(reason);
                      }}
                    />
                  </a>
                )}
                tooltipMsg={tooltipMsg}
              />
            )}

            {reasonModal.open && (
              <ModalSimple
                open={reasonModal.open}
                heading={reasonModal.heading}
                body={reasonModal.reason}
                onClose={this.handleCloseNoteModal}
              />
            )}
          </React.Fragment>
        ),
        url: null,
      },
    ];

    return (
      <Breadcrumbs items={breadcrumbs} ctaComponent={ctaComponent} />
    );
  }
}
ReportViewHeader.propTypes = {
  ctaComponent: PropTypes.node,
  heading: PropTypes.string.isRequired,
  reportType: PropTypes.string,
  report: PropTypes.object,
  activeOrg: orgSpec.isRequired,
};

ReportViewHeader.defaultProps = {
  ctaComponent: null,
  reportType: null,
  report: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const ReportViewHeaderConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportViewHeader);

export default ReportViewHeaderConnect;
