import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalOpenAC, getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { BS_SIZE, BS_STYLE } from 'core/assets/js/constants';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import OrgSettingContainer from 'settings/assets/js/components/OrgSettingContainer.jsx';

/**
 * Uses TDSwitch and returns TDSwitch with Confirmation Modal
 */
class TDToggleWithConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggleConfirmed = this.handleToggleConfirmed.bind(this);
    this.needsConfirmation = this.needsConfirmation.bind(this);
  }

  handleToggle() {
    if (this.needsConfirmation()) {
      const { dispatch, selected, enableModalId, disableModalId } = this.props;
      return dispatch(modalOpenAC(selected ? disableModalId : enableModalId));
    }
    return this.handleToggleConfirmed();
  }

  needsConfirmation() {
    const { selected, EnableModalBody, DisableModalBody } = this.props;
    return (selected && DisableModalBody) || (!selected && EnableModalBody);
  }

  async handleToggleConfirmed() {
    const { onToggled, selected } = this.props;
    this.setState({ isSubmitting: true });
    await onToggled(!selected);
    this.handleModalClose();
    this.setState({ isSubmitting: false });
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      cancelEnableCtaLabel,
      cancelDisableCtaLabel,
      containerClassName,
      disableCtaLabel,
      disableHeader,
      DisableModalBody,
      enableCtaLabel,
      enableHeader,
      EnableModalBody,
      hideEnableCta,
      isDisableModalOpen,
      isEnableModalOpen,
      label,
      modalId,
      popOverContent,
      popOverTitle,
      popOverSize,
      selected,
      sublabel,
    } = this.props;

    const { isSubmitting } = this.state;

    const enableCtas = [
      <TDButton
        className="float-right"
        disabled={isSubmitting}
        key={1}
        label={cancelEnableCtaLabel}
        onClick={this.handleModalClose}
      />,
    ];

    if (!hideEnableCta) {
      enableCtas.push(
        <TDButton
          className="float-right"
          disabled={isSubmitting}
          key={2}
          label={enableCtaLabel}
          onClick={this.handleToggleConfirmed}
          variant={BS_STYLE.PRIMARY}
        />,
      );
    }

    return (
      <OrgSettingContainer
        containerClassName={containerClassName}
        label={label}
        modalId={modalId}
        popOverContent={popOverContent}
        popOverSize={popOverSize}
        popOverTitle={popOverTitle}
        subLabel={sublabel}
      >
        <TDSwitch
          data-testid={`${modalId}-toggle`}
          onClick={this.handleToggle}
          selected={selected}
        />
        {EnableModalBody && (
          <ModalSimple
            data-testid={`${modalId}-enable-modal`}
            footer={enableCtas}
            heading={enableHeader}
            onClose={this.handleModalClose}
            open={isEnableModalOpen}
          >
            <EnableModalBody />
          </ModalSimple>
        )}
        {DisableModalBody && (
          <ModalSimple
            data-testid={`${modalId}-disable-modal`}
            footer={[
              <TDButton
                className="float-right"
                disabled={isSubmitting}
                key={1}
                label={cancelDisableCtaLabel}
                onClick={this.handleModalClose}
              />,
              <TDButton
                className="float-right"
                disabled={isSubmitting}
                key={2}
                label={disableCtaLabel}
                onClick={this.handleToggleConfirmed}
                variant={BS_STYLE.DANGER}
              />,
            ]}
            heading={disableHeader}
            onClose={this.handleModalClose}
            open={isDisableModalOpen}
          >
            <DisableModalBody />
          </ModalSimple>
        )}
      </OrgSettingContainer>
    );
  }
}

TDToggleWithConfirmationModal.propTypes = {
  cancelDisableCtaLabel: PropTypes.string,
  cancelEnableCtaLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  disableCtaLabel: PropTypes.string,
  disableHeader: PropTypes.string,
  DisableModalBody: PropTypes.elementType,
  disableModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  enableCtaLabel: PropTypes.string,
  enableHeader: PropTypes.string,
  EnableModalBody: PropTypes.elementType,
  enableModalId: PropTypes.string.isRequired,
  isDisableModalOpen: PropTypes.bool,
  isEnableModalOpen: PropTypes.bool,
  hideEnableCta: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalId: PropTypes.string.isRequired, //eslint-disable-line
  onToggled: PropTypes.func,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.string,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selected: PropTypes.bool.isRequired,
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TDToggleWithConfirmationModal.defaultProps = {
  cancelEnableCtaLabel: 'Cancel',
  cancelDisableCtaLabel: 'Cancel',
  containerClassName: '',
  disableHeader: '',
  disableCtaLabel: 'Deactivate',
  enableCtaLabel: 'Activate',
  EnableModalBody: null,
  DisableModalBody: null,
  enableHeader: '',
  hideEnableCta: false,
  isDisableModalOpen: false,
  isEnableModalOpen: false,
  label: null,
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
  onToggled: () => {},
  sublabel: null,
};

const mapStateToProps = (state, props) => {
  const { modalId } = props;
  const enableModalId = `enable-${modalId}`;
  const disableModalId = `disable-${modalId}`;
  return {
    disableModalId,
    enableModalId,
    isDisableModalOpen: getIsModalOpen(state, disableModalId),
    isEnableModalOpen: getIsModalOpen(state, enableModalId),
  };
};

const TDToggleWithConfirmationModalConnected = connect(
  mapStateToProps,
  dispatch => ({ dispatch }),
)(TDToggleWithConfirmationModal);

export default TDToggleWithConfirmationModalConnected;
