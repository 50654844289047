
import { flatten } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import SelectionField from 'core/assets/js/components/FinalFormFields/SelectionField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import YesNoField from 'core/assets/js/components/FinalFormFields/YesNoField.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import { MAX_UPLOAD_FILES, MIME_TYPES } from 'core/assets/js/constants';
import { uploaderInterviewPath } from 'core/urls';
import { TYPE } from 'interviews/assets/js/constants';
import { getAnswerExpiresAtPath } from 'interviews/assets/js/lib/utils';

const typeToComponent = {
  [TYPE.DATE]: DatePickerField,
  [TYPE.MONEY]: MoneyInputField,
  [TYPE.NUMBER]: InputNumberField,
  [TYPE.TEXT]: TextInputField,
  [TYPE.TEXTAREA]: TextAreaField,
  [TYPE.YESNO]: YesNoField,
};

const SubmissionQuestion = ({
  additionalError, disabled, interviewId, orgId, question, submissionId,
}) => {
  const { description, label, path, payload, required, type } = question;

  const commonProps = {
    additionalError: additionalError && `Reviewer comment: ${additionalError}`,
    disabled,
    key: path,
    label: <MarkdownText text={label} disallowedTypes={['paragraph']} />,
    name: path,
    required,
    sublabel: description,
  };

  if ([TYPE.RADIO, TYPE.SELECT].includes(type)) {
    const choices = (payload.choices || []).map(choice => (
      {
        raw: choice.text,
        text: (
          <MarkdownText
            disallowedTypes={['paragraph']}
            key={`${path}-choice-${choice.value}`}
            text={choice.text}
          />
        ),
        value: choice.value,
      }
    ));

    if (type === TYPE.SELECT) {
      return (
        <SelectionField
          {...commonProps}
          hasOther={payload.has_other}
          isMultiple={payload.multiple}
          optionsMapping={choices}
        />
      );
    }

    return <RadioField {...commonProps} options={choices} />;
  }

  if ([TYPE.FILE, TYPE.MULTIFILE].includes(type)) {
    let attachments = [];
    if (payload.attachments_dump) {
      attachments = typeof payload.attachments_dump === 'string'
        ? JSON.parse(payload.attachments_dump)
        : payload.attachments_dump;
    }
    return (
      <>
        <FileUploaderField
          {...commonProps}
          acceptFiles={(
            !payload.attachment_types ? payload.mimetypes : flatten(
              payload.attachment_types.map(at => MIME_TYPES[at]),
            )
          )}
          attachments={attachments}
          maxFiles={payload.multiple ? MAX_UPLOAD_FILES : 1}
          path={uploaderInterviewPath(orgId, interviewId, submissionId)}
        />
        {payload.setExpiryDate && (
          <DatePickerField
            className="col-12 col-md-6 col-lg-4"
            key={getAnswerExpiresAtPath(path)}
            label="Expiry date"
            name={getAnswerExpiresAtPath(path)}
            required
            sublabel="Set the expiry date of the uploaded document"
          />
        )}
      </>
    );
  }

  if (type === TYPE.TEXT && payload.isTextArea) {
    return <TextAreaField {...commonProps} />;
  }

  const Component = typeToComponent[type];

  if (Component) {
    return <Component {...commonProps} />;
  }

  return null;
};

SubmissionQuestion.propTypes = {
  additionalError: PropTypes.string,
  disabled: PropTypes.bool,
  interviewId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  submissionId: PropTypes.number.isRequired,
};

SubmissionQuestion.defaultProps = {
  additionalError: '',
  disabled: false,
};

export default SubmissionQuestion;
