import { isEmpty, omit } from 'lodash';
import Big from 'big.js';

Big.RM = 1;
Big.DP = 2;

class ProcessingFeeAnalysis {
  constructor(...args) {
    if (isEmpty(args) || args.every(a => isEmpty(a))) {
      return this.init();
    }
    return this.init(...args);
  }

  init({
    scale, perApprovedWorksheet, perTransaction,
    estimatedFee = '0.00',
    preBilledAmount = '0.00', prepaidFee = '0.00', floorRemainder = '0.00', usedFloor = false,
    total,
  } = {}) {
    this.details = {
      scale: scale || [],
      perApprovedWorksheet: perApprovedWorksheet || [],
      perTransaction,
      preBilledAmount,
      prepaidFee,
      estimatedFee,
      floorRemainder,
      usedFloor,
    };

    this.details.total = total || this.aggregate();
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    if (isEmpty(this.details.perTransaction)) {
      return omit(this.details, ['perTransaction']);
    }
    return {
      ...this.details,
    };
  }

  aggregate() {
    const {
      scale: scaleAnalysis,
      perApprovedWorksheet: perApprovedWorksheetAnalysis,
      perTransaction: perTransactionAnalysis,
    } = this.details;
    if (isEmpty(scaleAnalysis)) {
      return '0.00';
    }
    let fee = Big(0);
    scaleAnalysis.forEach(({ fee: partialFee }) => {
      fee = fee.plus(Big(partialFee));
    });
    perApprovedWorksheetAnalysis.forEach((category) => {
      fee = fee.plus(Big(category.fee));
    });
    (perTransactionAnalysis || []).forEach((category) => {
      fee = fee.plus(Big(category.fee));
    });
    return fee.toFixed(2);
  }

  getScale() {
    const { scale } = this.details;
    return scale || '0.00';
  }

  getPerApprovedWorksheetAnalysis() {
    const { perApprovedWorksheet } = this.details;
    return perApprovedWorksheet || [];
  }

  getTotal() {
    const { total } = this.details;
    return total || '0.00';
  }

  getBillableAmount() {
    const {
      scale: scaleAnalysis,
    } = this.details;
    let billable = Big(0);
    scaleAnalysis.forEach(({ billableAmount: partialBillable }) => {
      billable = billable.plus(Big(partialBillable));
    });

    return billable.toFixed(2);
  }

  getApprovedNumberOfWorksheets() {
    const {
      perApprovedWorksheet: perApprovedWorksheetAnalysis,
    } = this.details;
    if (isEmpty(perApprovedWorksheetAnalysis)) {
      return 0;
    }
    return perApprovedWorksheetAnalysis[0].numApprovedWorksheets;
  }

  setTransactionFeeAnalysis(perTransaction) {
    let fee = Big(0);
    perTransaction.forEach((category) => {
      fee = fee.plus(Big(category.fee));
    });
    this.details.perTransaction = perTransaction;
    this.details.total = Big(this.details.total).plus(fee).toFixed(2);
  }

  getTransactionFeeAnalysis() {
    const { perTransaction } = this.details;
    return perTransaction;
  }

  hasTransactionFee() {
    const { perTransaction } = this.details;
    return !isEmpty(perTransaction);
  }

  hasUsedFloor(processingFee = 0) {
    const aggregate = this.aggregate();
    return Big(processingFee).toFixed(2) !== aggregate;
  }

  isEmpty() {
    return this.getTotal() === '0.00';
  }

  /**
   * Has there been a previous payment towards payment processing fees.
   * @return {boolean} true is a prepaid free is present.
   */
  hasPrepaidFee() {
    const { prepaidFee } = this.details;
    return Big(prepaidFee || '0.00').toFixed(2) !== '0.00';
  }

  /**
   * Get the amount of any previous paymennts towards payment processing fees.
   * @return {string} prepaid free amount.
   */
  getPrepaidFee() {
    const { prepaidFee } = this.details;
    return prepaidFee || '0.00';
  }

  /**
   * Get the remainder of the floor after deducting any prepaid amount
   * and the current fee.
   * @return {string} the amount of the floor still remaining after all deductions
   * or '0.00' if the floor is completely paid.
   */
  getFloorRemainder() {
    const { floorRemainder } = this.details;
    return floorRemainder || '0.00';
  }

  /**
   * Is the floor still chargeable after all deductions including the current processing fee.
   * @return {boolean} true if the floor has NOT been paid.
   */
  isFloorGreaterThanFees() {
    const { usedFloor } = this.details;
    return usedFloor;
  }
}

export default ProcessingFeeAnalysis;
