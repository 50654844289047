import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDCheckbox from 'core/assets/js/components/TDCheckbox.jsx';
import TDButton from 'core/assets/js/components//TDButton.jsx';

function SelectableListToggleElement({
  handleSelectModeToggle,
  handleSelectPageItemsToggle,
  isSelectionModeEnabled,
  pageItemsSelected,
  selectModeEnabled,
}) {
  return (
    selectModeEnabled ? (
      <TDButton
        className="selectable-list__toggle-button selection-enabled"
        variant={BS_STYLE.DEFAULT}
        onClick={handleSelectPageItemsToggle}
      >
        <TDCheckbox
          checked={pageItemsSelected}
        />
      </TDButton>
    ) : (
      <TDButton
        className={`selectable-list__toggle-button ${isSelectionModeEnabled ? 'btn--active' : ''}`}
        variant={BS_STYLE.DEFAULT}
      >
        <i
          className={`p-3 ${ICON.TASKS}`}
          role="none"
          onClick={handleSelectModeToggle}
        />
        <TDCheckbox
          checked={pageItemsSelected}
          onClick={handleSelectPageItemsToggle}
        />
      </TDButton>
    )
  );
}

SelectableListToggleElement.propTypes = {
  handleSelectModeToggle: PropTypes.func.isRequired,
  handleSelectPageItemsToggle: PropTypes.func.isRequired,
  selectModeEnabled: PropTypes.bool,
  pageItemsSelected: PropTypes.bool,
  isSelectionModeEnabled: PropTypes.bool,
};

SelectableListToggleElement.defaultProps = {
  selectModeEnabled: false,
  pageItemsSelected: false,
  isSelectionModeEnabled: false,
};

export default SelectableListToggleElement;
