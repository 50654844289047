import React from 'react';
import PropTypes from 'prop-types';

const BillableVoid = ({ billableTypeLabel }) => (
  <>
    <p>
      You can void this
      {' '}
      {billableTypeLabel}
      {' '}
      if it contains inaccurate information and got approved
      by mistake. You can then ask your provider to submit a new one
      for you to review and approve.
    </p>
    <p>
      Remember that by voiding this
      {' '}
      {billableTypeLabel}
      {' '}
      the approved funds will be returned back
      to the relevant project and the
      {' '}
      {billableTypeLabel}
      {' '}
      will not be processed or included in
      the next contractor’s invoice.
    </p>
    <p>
      Are you sure you want to void this
      {' '}
      {billableTypeLabel}
      ?
    </p>
  </>
);

BillableVoid.propTypes = {
  billableTypeLabel: PropTypes.string.isRequired,
};

export default BillableVoid;
