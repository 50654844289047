/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

import { currencySpec } from 'core/assets/js/lib/objectSpecs';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';

export const PPHProfileSpec = PropTypes.shape({
  userId: PropTypes.number,
  fullName: PropTypes.string,
  address: PropTypes.shape({ label: PropTypes.string }),
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobTitle: PropTypes.string,
  currency: currencySpec,
  rate: PropTypes.string,
  resume: PropTypes.string,
  skills: PropTypes.arrayOf(skillSpec),
});
