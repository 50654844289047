import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import NotificationsForm from 'settings/assets/js/components/NotificationsForm.jsx';
import {
  postOrganizationFinanceEmailDS,
  postTaskAssigneesWorksheetNotificationsSettingsDS,
  postUserCardEmailDS,
  postServiceOrderNotificationsSettingsDS,
  postProjectsTeamMemberAdditionNotificationsSettingsDS,
} from 'settings/assets/js/data-services/organizations';
import PeopleCardSkeleton from 'core/assets/js/components/Skeleton/PeopleCardSkeleton.jsx';


class NotificationsEditView extends React.Component {
  static FetchData() {
    return Promise.resolve();
  }

  static GetComponentName() {
    return 'NotificationsEditView';
  }

  constructor(props) {
    super(props);
    this.handleServiceOrderNotificationsChange = this
      .handleServiceOrderNotificationsChange.bind(this);
    this.handleEnableServiceOrdersManagerRemindersChange = this
      .handleEnableServiceOrdersManagerRemindersChange.bind(this);
    this.handlePaymentsNotificationsChange = this
      .handlePaymentsNotificationsChange.bind(this);
    this.handleProjectsTeamMemberAdditionNotificationsSettingsChange = this
      .handleProjectsTeamMemberAdditionNotificationsSettingsChange.bind(this);
    this.handleTaskAssigneesWorksheetNotificationsChanged = this
      .handleTaskAssigneesWorksheetNotificationsChanged.bind(this);
  }

  handleServiceOrderNotificationsChange(enableServiceOrderNotifications) {
    const { dispatch, organization } = this.props;

    return dispatch(postServiceOrderNotificationsSettingsDS(
      organization.unique_alias,
      { enable_service_order_notifications: enableServiceOrderNotifications },
    )).then(() => {
      toastr.success('Well Done!', 'Notifications settings updated successfully.');
    });
  }

  handleEnableServiceOrdersManagerRemindersChange(enableServiceOrdersManagerReminders) {
    const { dispatch, organization } = this.props;

    return dispatch(postServiceOrderNotificationsSettingsDS(
      organization.unique_alias,
      { enable_service_orders_manager_reminders: enableServiceOrdersManagerReminders },
    )).then(() => {
      toastr.success('Well Done!', 'Notifications settings updated successfully.');
    });
  }

  handlePaymentsNotificationsChange(enablePaymentsNotifications) {
    const { dispatch, organization } = this.props;

    return dispatch(postServiceOrderNotificationsSettingsDS(
      organization.unique_alias,
      { enable_payments_notifications: enablePaymentsNotifications },
    )).then(() => {
      toastr.success('Well Done!', 'Notifications settings updated successfully.');
    });
  }

  handleProjectsTeamMemberAdditionNotificationsSettingsChange(
    enableProjectsTeamMemberAdditionNotifications,
  ) {
    const { dispatch, organization } = this.props;
    return dispatch(postProjectsTeamMemberAdditionNotificationsSettingsDS(
      organization.unique_alias, {
        enable_projects_team_member_addition_notifications:
          enableProjectsTeamMemberAdditionNotifications,
      },
    )).then(() => {
      toastr.success('Well Done!', 'Notifications settings updated successfully.');
    });
  }

  handleTaskAssigneesWorksheetNotificationsChanged(enableTaskAssigneesWorksheetNotifications) {
    const { dispatch, organization } = this.props;

    return dispatch(postTaskAssigneesWorksheetNotificationsSettingsDS(
      organization.unique_alias,
      { enable_task_assignees_worksheet_notifications: enableTaskAssigneesWorksheetNotifications },
    )).then(() => {
      toastr.success('Well Done!', 'Notifications settings updated successfully.');
    });
  }

  render() {
    const breadcrumbs = [
      {
        title: 'Notifications',
        url: null,
      },
    ];
    const { userCard, organization } = this.props;
    const componentName = this.constructor.GetComponentName();

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--org-settings">
          <div className="container">
            <TDApiConnected duck="view" component={this.constructor} blockingLoading skeletonComponent={PeopleCardSkeleton}>
              <NotificationsForm
                organization={organization}
                userCard={userCard}
                onServiceOrderNotificationsChanged={this.handleServiceOrderNotificationsChange}
                onEnableServiceOrdersManagerRemindersChanged={
                  this.handleEnableServiceOrdersManagerRemindersChange
                }
                onPaymentsNotificationsChanged={this.handlePaymentsNotificationsChange}
                onProjectsTeamMemberAdditionNotificationsChanged={
                  this.handleProjectsTeamMemberAdditionNotificationsSettingsChange
                }
                onTaskAssigneesWorksheetNotificationsChanged={
                  this.handleTaskAssigneesWorksheetNotificationsChanged
                }
                onOrgEmailChanged={(values, dispatch) => (
                  dispatch(postUserCardEmailDS(organization.unique_alias, values, componentName))
                )}
                onFinanceEmailChanged={(values, dispatch) => dispatch(
                  postOrganizationFinanceEmailDS(organization.unique_alias, values, componentName),
                )}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NotificationsEditView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  userCard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const userCard = selectActiveUserCard(state);
  return {
    userCard,
    organization: userCard.organization,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsEditView);
