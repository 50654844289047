import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { BS_SIZE } from 'core/assets/js/constants';
import { Link } from 'react-router-dom';
import { settingsPaymentsSubPageUrl } from 'settings/urls';
import { SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';

const validateInvoiceNumber = (value) => {
  if (!value) {
    return 'field is required';
  }
  const match = value.match(/(.*?)(\d+)$/);
  if (!match) {
    return 'value must end in a number';
  }
  return null;
};

const InvoiceNumberForm = ({ orgAlias }) => (
  <React.Fragment>
    <div className="row">
      <div className="col-12 col-sm-12">
        <label>This number will be used for this invoice:</label>
        <Field
          name="number"
          component={InputField}
          validate={validateInvoiceNumber}
        />
      </div>
    </div>
    <div>
      <p>
        All future invoices will be automatically numbered
        {' '}
        by sequentially increasing the above number.
      </p>
      <p>
        In case you need just the future invoice numbers altered, visit your
        {' '}
        <Link to={settingsPaymentsSubPageUrl(orgAlias, SETTINGS_PAYMENTS_TABS.PAYMENT_TERMS)}>
          payment settings
        </Link>
        .
      </p>
    </div>
  </React.Fragment>
);

InvoiceNumberForm.propTypes = {
  orgAlias: PropTypes.string.isRequired,
};

const InvoiceNumberFormConnect = withModalForm({
  heading: 'Set invoice number',
  confirmLabel: 'Save',
  size: BS_SIZE.SMALL,
})(InvoiceNumberForm);

export default InvoiceNumberFormConnect;
