import React from 'react';

import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const PeopleGridListItemSkeleton = () => (
  <li
    className="team-grid-list-item text-center h-100"
  >
    <div className="team-grid-list-item__container d-flex flex-column bg-white p-4">
      <div className="row d-flex align-items-center position-relative">
        <div className="col-12 d-flex justify-content-center mt-2 mb-4">
          <CircleSkeleton className="mr-0" diameter="80" />
        </div>
      </div>

      <div className="mb-4">
        <RectangleSkeleton height="24" width="168" />
      </div>

      <div className="mb-4">
        <RectangleSkeleton height="16" width="128" />
      </div>

      <hr className="my-5 mx-n4" />

      <div className="d-flex align-content-between mt-5">
        <RectangleSkeleton height="16" width="140" />
        <RectangleSkeleton height="16" width="90" />
      </div>
    </div>
  </li>
);

export default PeopleGridListItemSkeleton;
