/* globals File */
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';

import Fileboxes from 'core/assets/js/components/FileUploader/Fileboxes.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { IS_FILE } from 'core/assets/js/config/settings';
import { BS_SIZE, BS_STYLE, MIME_TYPES } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { finalFormFieldMetaSpec } from 'core/assets/js/lib/objectSpecs';
import { fileToDataUrl } from 'core/assets/js/lib/utils';
import { cropImage } from 'core/assets/js/lib/utils-jsx';

const MODAL_ID = 'crop-image-modal';

const FileUploaderDirectField = ({
  accept,
  additionalError,
  afterChange,
  allowCropping: allowCroppingIn,
  allowMultiple,
  className,
  cropAspect,
  cropModalHeading,
  'data-testid': dataTestId,
  input,
  label,
  maxFiles,
  maxHeight,
  maxWidth,
  meta: { error, pristine, submitError },
  required,
  showAddWhenFileSelected,
  showAsButton,
  sublabel,
}) => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const { change } = useForm();
  const { values } = useFormState();

  const classNames = ['form-group', 'file-uploader-direct-field'];
  if (className) {
    classNames.push(className);
  }
  const showAdditionalError = pristine && additionalError;
  if (submitError || error || showAdditionalError) {
    classNames.push('has-error');
  }

  const inputRef = useRef(null);

  const selectedFiles = [];
  if (Array.isArray(input.value)) {
    selectedFiles.push(...input.value);
  } else if (input.value) {
    selectedFiles.push(input.value);
  }

  const fileSelected = selectedFiles.length > 0 && IS_FILE(selectedFiles[0]);
  const showAdd = allowMultiple || showAddWhenFileSelected || !fileSelected;
  const allowCropping = allowCroppingIn && !allowMultiple && (
    (typeof accept === 'string' && MIME_TYPES.IMAGES.includes(accept))
    || (
      Array.isArray(accept) && accept.length > 0 && accept.every(m => MIME_TYPES.IMAGES.includes(m))
    )
  );

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [pixelCrop, setPixelCrop] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [imageToCropDataUrl, setImageToCropDataUrl] = useState(null);

  const onClick = () => inputRef.current.click();

  const maxFilesIsANumber = typeof maxFiles === 'number';

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      {showAsButton && showAdd && (
        <TDButton
          block
          bsSize={BS_SIZE.LARGE}
          label={label}
          onClick={onClick}
          variant={BS_STYLE.PRIMARY}
        />
      )}
      {!showAsButton && (
        <>
          <TDLabel
            label={label}
            name={input.name}
            required={required}
            sublabel={sublabel}
            suffix={maxFilesIsANumber ? `(Maximum ${maxFiles} files)` : null}
          />
          {showAdd && (
            <div className="fileuploader mb-5" onClick={onClick}>
              <span className="fileuploader__text">
                <span className="fileuploader__attnt">Click</span>
                {` & browse to add file${allowMultiple ? 's' : ''}`}
              </span>
            </div>
          )}
        </>
      )}
      <input
        accept={Array.isArray(accept) ? accept.join(',') : accept}
        multiple={allowMultiple}
        name={input.name}
        onChange={event => {
          const { files } = event.target;
          let newFilesValue = allowMultiple ? Array.from(files) : files[0];
          if (allowMultiple) {
            const currentValue = values[input.name];
            if (Array.isArray(currentValue) && currentValue.length > 0) {
              newFilesValue.unshift(...currentValue);
            }
            if (maxFilesIsANumber) {
              newFilesValue = newFilesValue.slice(0, maxFiles);
            }
          }
          change(input.name, newFilesValue);
          afterChange();
          if (allowCropping) {
            fileToDataUrl(files[0]).then(setImageToCropDataUrl);
            dispatch(modalOpenAC(MODAL_ID));
          }
        }}
        ref={inputRef}
        type="file"
      />
      {(error || submitError) && (
        <span className="help-block d-inline-block mt-5">{error || submitError}</span>
      )}
      {selectedFiles.length > 0 && (
        <Fileboxes
          clickHandler={removingFile => {
            if (allowMultiple) {
              const newValue = input.value.slice(0);
              const index = newValue.findIndex(file => {
                let parsedFile = file;
                if (parsedFile instanceof File) {
                  parsedFile = {
                    filename: parsedFile.name,
                    mimetype: parsedFile.type,
                    size: parsedFile.size,
                  };
                }
                return removingFile.filename === parsedFile.filename
                  && removingFile.mimetype === parsedFile.mimetype
                  && removingFile.size === parsedFile.size;
              });
              newValue.splice(index, 1);
              change(input.name, newValue);
            } else {
              change(input.name, null);
            }
            inputRef.current.value = '';
            afterChange();
          }}
          files={selectedFiles.map(selectedFile => ({
            file: selectedFile,
            filename: selectedFile.name,
            mimetype: selectedFile.type,
            size: selectedFile.size,
          }))}
          inputName={input.name}
          reduxInput={input}
        />
      )}
      {allowCropping && (
        <ModalConfirm
          confirmButtonDisabled={!imageToCropDataUrl}
          confirmLabel="Save"
          heading={cropModalHeading}
          open={modalIsOpen}
          onClose={() => dispatch(modalCloseAC())}
          onConfirm={async () => {
            const croppedImageBlob = await cropImage(
              imageToCropDataUrl, pixelCrop, { maxHeight, maxWidth },
            );

            const newFile = new File(
              [croppedImageBlob],
              `${selectedFiles[0].name.split('.')[0]}-cropped.jpg`,
              { type: 'image/jpeg' },
            );

            change(input.name, newFile);
          }}
        >
          {imageToCropDataUrl && (
            <>
              <div className="file-uploader-direct-crop-wrapper w-100">
                <Cropper
                  aspect={cropAspect}
                  crop={crop}
                  image={imageToCropDataUrl}
                  onCropChange={setCrop}
                  onCropComplete={(croppedArea, croppedAreaPixels) => {
                    setPixelCrop(croppedAreaPixels);
                  }}
                  onZoomChange={setZoom}
                  showGrid
                  zoom={zoom}
                  zoomWithScroll
                />
              </div>
              <div
                className={(
                  'd-flex align-items-center justify-content-between mt-3 '
                  + 'file-uploader-direct-crop-zoom'
                )}
              >
                <span>Zoom</span>
                <input
                  max="3"
                  min="1"
                  name="zoom"
                  onChange={e => {
                    setZoom(e.target.value);
                  }}
                  step="0.1"
                  type="range"
                  value={zoom}
                />
              </div>
            </>
          )}
        </ModalConfirm>
      )}
    </div>
  );
};

FileUploaderDirectField.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  afterChange: PropTypes.func,
  additionalError: PropTypes.string,
  allowCropping: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  className: PropTypes.string,
  cropAspect: PropTypes.number,
  cropModalHeading: PropTypes.string,
  'data-testid': PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  maxFiles: PropTypes.number,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  meta: finalFormFieldMetaSpec,
  required: PropTypes.bool,
  showAddWhenFileSelected: PropTypes.bool,
  showAsButton: PropTypes.bool,
  sublabel: PropTypes.string,
};

FileUploaderDirectField.defaultProps = {
  accept: null,
  afterChange: () => null,
  allowCropping: false,
  additionalError: null,
  allowMultiple: false,
  className: null,
  cropAspect: 1,
  cropModalHeading: 'Crop your image',
  'data-testid': 'file-uploader-direct-field',
  label: 'Upload file',
  maxFiles: null,
  maxHeight: null,
  maxWidth: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  required: false,
  showAddWhenFileSelected: false,
  showAsButton: false,
  sublabel: null,
};

export default withField(FileUploaderDirectField);
