import React from 'react';
import PropTypes from 'prop-types';

const TDSelect = ({ disabled, 'data-testid': dataTestId, onChange, options, value }) => (
  <select
    className="form-control"
    data-testid={dataTestId}
    disabled={disabled}
    onChange={e => onChange(e.target.value)}
    value={value}
  >
    {options.map(option => (
      <option key={option.value} value={option.value}>{option.text}</option>
    ))}
  </select>
);

TDSelect.propTypes = {
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired, value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
};

TDSelect.defaultProps = {
  'data-testid': '',
  disabled: false,
};

export default TDSelect;
