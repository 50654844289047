import React, { useState } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import TaskItemField from 'projects/assets/js/components/TaskItemField.jsx';
import TaskChecklist from 'projects/assets/js/components/TaskChecklist.jsx';
import { EVENT_KEY } from 'core/assets/js/constants';

/**
 * This component should be used in cases where the task items
 * are wrapped in a <FieldArray /> component for example when we're editing
 * them altogether (eg. Task Create form or Task Update form)
 */
const TaskChecklistFieldset = ({
  label, fields, className, itemInitialValues, meta: { error, submitError },
}) => {
  const [fieldValues, setFieldValues] = useState(itemInitialValues);

  const storeFieldValues = () => {
    const values = fields.map((field, index) => fields.value[index]);

    setFieldValues(values);
  };

  // add another task item
  const handleItemAdded = () => fields.push({});

  // remove a task item
  const handleFieldRemoved = (index) => {
    fields.remove(index);
    storeFieldValues(fields);
  };

  // A description switched to viewable mode
  const handleDescriptionViewable = (index, e) => {
    // When hitting the Escape button, we should revert to the initial value
    if (e && e.key === EVENT_KEY.ESCAPE) {
      const field = fields.value[index];

      // Field is newly added, on Escape just remove it
      if (!field.id && !field.description) {
        return fields.remove(index);
      }

      // Look the initial value up
      const previousValue = fieldValues[index];

      // The previousValue value was not found or the description was empty: leave as is
      if (!previousValue || !previousValue.description) {
        return true;
      }

      // Finally, update the field to its previous value
      Object.assign(field, { description: previousValue.description });
      storeFieldValues(fields);
    }

    return true;
  };

  // An item got updated
  const handleDescriptionUpdated = (index, event) => {
    // prevent the form from being submitted when we hit 'Enter' on the description
    event.preventDefault();
    event.stopPropagation();

    // if we're editing the last field available, add another one
    if (index === fields.length - 1) {
      handleItemAdded();
    }
  };

  // An item got duplicated
  const handleItemDuplicated = (index) => {
    fields.push({
      ...omit(fields.value[index], 'id'),
      autoFocus: true,
    });

    storeFieldValues(fields);
  };

  // An item got completed
  const handleCompleteUpdated = (index, event) => {
    const currentValue = fields.value[index];
    currentValue.completed = !!event.target.checked;
    fields.update(index, currentValue);
    storeFieldValues(fields);

    // The value of the checkbox doesn't
    // get updated until the field gets blurred
    if (event.target) {
      event.target.blur();
    }
  };

  const taskItems = fields.map((fieldName, index) => {
    const field = fields.value[index];
    const isEditable = !field.description || !!field.autoFocus;

    return (
      <TaskItemField
        fieldName={fieldName}
        isEditable={isEditable}
        isCompleted={field.completed}
        index={index}
        onItemRemoved={handleFieldRemoved}
        onItemDuplicated={handleItemDuplicated}
        onDescriptionUpdated={handleDescriptionUpdated}
        onDescriptionViewable={handleDescriptionViewable}
        onCompleteUpdated={handleCompleteUpdated}
      />
    );
  });

  return (
    <TaskChecklist
      label={label}
      error={error || submitError}
      className={className}
      onItemAdded={handleItemAdded}
      items={taskItems}
    />
  );
};

TaskChecklistFieldset.propTypes = {
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  fields: PropTypes.object,
  className: PropTypes.string,
  itemInitialValues: PropTypes.arrayOf(PropTypes.object),
};

TaskChecklistFieldset.defaultProps = {
  label: null,
  fields: [],
  className: null,
  itemInitialValues: [],
};

export default TaskChecklistFieldset;
