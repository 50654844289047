import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import AllowAccessToHigherManager from 'core/assets/js/components/AllowAccessToHigherManager.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BS_STYLE, VIEW_TYPES } from 'core/assets/js/constants';
import {
  fetchListDS, getListState, getListStateExtras, listIsLoadingAC, listUpdateItemAC,
} from 'core/assets/js/ducks/list';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { getPeopleListViewType } from 'core/assets/js/ducks/settings';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import ContactDetailsCard from 'people/assets/js/components/ContactDetailsCard.jsx';
import EditManagerBudget from 'people/assets/js/components/EditManagerBudget.jsx';
import ManageUsersGroupsModal from 'people/assets/js/components/ManageUsersGroupsModal.jsx';
import PeopleList from 'people/assets/js/components/PeopleList.jsx';
import PeopleGridListSkeleton from 'people/assets/js/components/skeletons/PeopleGridListSkeleton';
import TeamMemberActionsDropDownItems from 'people/assets/js/components/TeamMemberActionsDropDownItems.jsx';
import { PEOPLE_TYPE, PEOPLE_DEFAULT_ORDERING } from 'people/assets/js/constants';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import {
  orgPeopleListApiUrl,
  orgPeopleListCsvApiUrl,
  orgPeopleInviteUrl,
  orgPeopleManagersUrl,
  orgPeopleProvidersUrl,
} from 'people/urls';
import RateAdjustmentModal from 'rates/assets/js/components/RateAdjustmentModal.jsx';

export const CONTACT_MODAL_ID = 'contact-modal';
export const MANAGE_BUDGET_MODAL_ID = 'manage-budget-modal';

const PeopleListView = ({
  allowedActions,
  hasOrgAccess,
  history,
  location,
  match,
  peopleType,
  userCards,
}) => {
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);
  const listViewType = useSelector(getPeopleListViewType);
  const isContactModalOpen = useSelector(state => getIsModalOpen(state, CONTACT_MODAL_ID));
  const contactModalPayload = useSelector(state => getModalPayload(state, CONTACT_MODAL_ID));
  const manageBudgetModalPayload = useSelector(state => getModalPayload(
    state,
    MANAGE_BUDGET_MODAL_ID,
  ));

  const isManager = hasOrgAccess({ requireManager: true });
  const canViewManagers = isManager || activeOrg.providers_can_see_managers;
  const canViewProviders = (
    isManager || activeOrg.should_provider_view_other_providers
  );

  const { orgAlias } = match.params;
  const { canInviteManagers, canInviteProviders } = allowedActions;
  const breadcrumbs = [{ title: 'Team', url: null }];

  let ctaButtonItems = null;
  let inviteUsersButtonProps = null;
  if (
    (canInviteManagers && peopleType === PEOPLE_TYPE.MANAGERS)
    || (canInviteProviders && peopleType === PEOPLE_TYPE.PROVIDERS)
  ) {
    inviteUsersButtonProps = {
      label: `Invite ${peopleType}`,
      onClick: () => history.push(orgPeopleInviteUrl(orgAlias, peopleType)),
      variant: BS_STYLE.PRIMARY,
    };
    ctaButtonItems = [inviteUsersButtonProps];
  }

  const componentName = PeopleListView.GetComponentName(match.params);

  const tabSpec = [];
  if (canViewProviders) {
    tabSpec.push({
      key: PEOPLE_TYPE.PROVIDERS,
      label: 'Providers',
      href: orgPeopleProvidersUrl(orgAlias),
    });
  }
  if (canViewManagers) {
    tabSpec.push({
      key: PEOPLE_TYPE.MANAGERS,
      label: 'Managers',
      href: orgPeopleManagersUrl(orgAlias),
    });
  }

  const query = queryString.parse(location.search);
  const isGrid = listViewType === VIEW_TYPES.GRID;

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

      <div className="page page--people">
        <div className="container">
          <TabBar
            className="mt-n5 mb-5"
            activeKey={peopleType}
            tabSpec={tabSpec}
          />
          <TDApiConnected
            duck="list"
            storeKey={componentName}
            fetchData={props => PeopleListView.FetchData({
              ...props,
              pageSize: isGrid ? 12 : 10,
            })}
            loadingEnabled
            skeletonComponent={isGrid ? PeopleGridListSkeleton : FinanceTableSkeleton}
            query={{ ...query, listViewType }} // triggers new fetch when listViewType is changed
          >
            <PeopleList
              addUsersComponent={inviteUsersButtonProps && (
                <>
                  <br />
                  <TDButton
                    className="mt-4"
                    data-testid="people-list-invite-users"
                    {...inviteUsersButtonProps}
                  />
                </>
              )}
              componentName={componentName}
              exportFn={() => {
                WINDOW_OPEN(
                  `${orgPeopleListCsvApiUrl(orgAlias, peopleType)}${location.search}`,
                );
              }}
              getActionsDropDownItems={item => (
                <TeamMemberActionsDropDownItems
                  className={isGrid ? 'team-grid-list-item__actions-drop-down--fixed' : null}
                  item={item}
                  peopleType={peopleType}
                  componentName={componentName}
                />
              )}
              noUsersMessage={`You don't have any ${peopleType} yet`}
            />
          </TDApiConnected>
          {contactModalPayload?.profile && (
            <ModalSimple
              open={isContactModalOpen}
              heading={`Contact ${contactModalPayload?.firstName}`}
              body={(
                <ContactDetailsCard
                  email={contactModalPayload?.email}
                  profile={contactModalPayload?.profile}
                />
              )}
              onClose={() => {
                dispatch(modalCloseAC());
              }}
            />
          )}

          <RateAdjustmentModal
            onSuccess={updatedRate => {
              const userCard = userCards.find(uc => uc.user.id === updatedRate.user_id);
              if (userCard) {
                dispatch(listUpdateItemAC(
                  userCard.id,
                  {
                    rate: updatedRate.amount,
                    rateAdjustmentActions: updatedRate.rateAdjustmentAllowedActions,
                  },
                  componentName,
                ));
              }
            }}
          />

          <AllowAccessToHigherManager>
            {manageBudgetModalPayload?.user ? (
              <EditManagerBudget
                manager={manageBudgetModalPayload?.user}
                managerId={manageBudgetModalPayload?.id}
                orgAlias={orgAlias}
                form={`manager-budget-form-${manageBudgetModalPayload?.id}`}
                modalId={MANAGE_BUDGET_MODAL_ID}
              />
            ) : ''}
          </AllowAccessToHigherManager>

          {isManager && <ManageUsersGroupsModal />}
        </div>
      </div>
    </React.Fragment>
  );
};

PeopleListView.FetchData = async ({
  dispatch, params, url, authedAxios, componentName, querystring, pageSize,
}) => {
  const apiUrl = orgPeopleListApiUrl(params, url);
  dispatch(listIsLoadingAC(true, componentName));
  const query = queryString.parse(querystring);
  query.pageSize = pageSize;
  // Use default ordering in case user haven't specified any.
  if (!query.ordering) {
    query.ordering = JSON.stringify(PEOPLE_DEFAULT_ORDERING);
  }

  const result = await dispatch(fetchListDS({
    url: apiUrl,
    querystring: queryString.stringify(query),
    componentName,
    authedAxios,
  }));
  dispatch(listIsLoadingAC(false, componentName));
  return result;
};

PeopleListView.GetComponentName = (params) =>
  `PeopleListView-${params ? params.peopleType : 'providers'}`;

PeopleListView.propTypes = {
  allowedActions: PropTypes.object,
  hasOrgAccess: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  peopleType: peopleTypeSpec.isRequired,
  userCards: PropTypes.arrayOf(userCardSpec).isRequired,
};

PeopleListView.defaultProps = {
  allowedActions: {},
  match: {},
};

const mapStateToProps = (state, props) => {
  const componentName = PeopleListView.GetComponentName(props.match.params);

  return {
    allowedActions: getListStateExtras(state, componentName, 'allowedActions'),
    hasOrgAccess: getHasOrgAccess(state),
    match: props.match,
    peopleType: props.match.params.peopleType,
    userCards: getListState(state, componentName).items,
  };
};

const PeopleListConnect = connect(mapStateToProps)(PeopleListView);

export default withRouter(PeopleListConnect);
