import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover, Overlay } from 'react-bootstrap';

import { BS_SIZE, ICON } from 'core/assets/js/constants';

const ElementWithPopOver = ({
  className, element, placement, popOverClassName, popOverContent, popOverTitle, size,
}) => {
  const target = useRef(null);
  const [showPopOver, setShowPopOver] = useState(false);

  const classnames = [];

  if (popOverClassName) {
    classnames.push(popOverClassName);
  }

  if (size) {
    classnames.push(`popover--${size}`);
  }

  return (
    <>
      <div
        className={`element-with-popover${className ? ` ${className}` : ''}`}
        onClick={() => setShowPopOver(!showPopOver)}
        ref={target}
      >
        {element}
      </div>
      <Overlay
        id="popover-overlay"
        onHide={() => setShowPopOver(false)}
        placement={placement}
        rootClose
        show={showPopOver}
        target={target.current}
      >
        <Popover className={classnames.join(' ')} placement={placement}>
          {popOverTitle && (
            <Popover.Title>
              {popOverTitle}
              <span className="popover__close-button" onClick={() => setShowPopOver(false)}>
                <i className={ICON.TIMES} />
              </span>
            </Popover.Title>
          )}
          <Popover.Content>{popOverContent}</Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

ElementWithPopOver.propTypes = {
  className: PropTypes.string,
  element: PropTypes.node,
  placement: PropTypes.string,
  popOverClassName: PropTypes.string,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(Object.values(BS_SIZE)),
};

ElementWithPopOver.defaultProps = {
  className: '',
  element: <i className={ICON.INFO} />,
  placement: 'bottom',
  popOverClassName: 'mt-4',
  popOverTitle: null,
  size: BS_SIZE.DEFAULT,
};

export default ElementWithPopOver;
