import moment from 'moment';
import { flatten, isEmpty, isNil, uniq, uniqBy } from 'lodash';

import {
  INVOICING_DAYS_GROUP,
  INVOICING_DAYS_CSV,
  INVOICING_FREQUENCY_TO_DAYS,
  INVOICING_FREQUENCY_SCHEMA_TYPES,
  INVOICING_FREQUENCY_SEGMENT,
} from 'finance/assets/js/constants';
import { assertAllKeysPresent, getDatetime } from 'core/assets/js/lib/utils';
import { assert } from 'finance/assets/js/lib/Assert';
import InvoicingSegmentFrequency from 'finance/assets/js/lib/InvoicingSegmentFrequency';


/**
 * It checks if an invoicing frequency constant is a valid one.
 *
 * @param {INVOICING_FREQUENCY} invoicingFrequencyConstant - The constant we need to validate
 * @returns {boolean} - Returns true if the invoicing frequency constant is valid, false otherwise
 */
const _isValidFrequencyConstant = (invoicingFrequencyConstant) => {
  assert(!!invoicingFrequencyConstant, 'Invoicing frequency constant is required');
  return Object.keys(INVOICING_FREQUENCY_TO_DAYS).includes(invoicingFrequencyConstant);
};

/**
 * It converts an invoicing frequency constant to invoicing days
 *
 * @param {INVOICING_FREQUENCY} invoicingFrequencyConstant - The constant we want to convert
 * @returns {number[]} - Returns an array of invoicing days
 */
export const convertFrequencyConstantToDays = (invoicingFrequencyConstant) => {
  if (!invoicingFrequencyConstant) {
    return [];
  }
  if (!_isValidFrequencyConstant(invoicingFrequencyConstant)) {
    throw new Error(`Unsupported invoicing frequency days: ${invoicingFrequencyConstant}`);
  }
  return INVOICING_FREQUENCY_TO_DAYS[invoicingFrequencyConstant];
};


/**
 * It converts invoicing frequency constants to invoicing days
 *
 * @param {INVOICING_FREQUENCY[]} invoicingFrequencyConstants - The constants we want to convert
 * @returns {number[]} - Returns an array of unique invoicing days
 */
export const convertMultipleFrequencyConstantsToDays = (invoicingFrequencyConstants) => {
  assert(!isEmpty(invoicingFrequencyConstants), 'Invoicing frequency constants required');
  if (!Array.isArray(invoicingFrequencyConstants)) {
    throw new Error('Expected array of frequencies');
  }
  const days = invoicingFrequencyConstants.map(convertFrequencyConstantToDays);
  return [...new Set(days.flat())];
};


/**
 * Checks if a given schema is of legacy type
 *
 * @param {string|number[]} schema - The schema to check.
 *
 * @returns {boolean} Returns true if the schema is of legacy type, false otherwise.
 */
export const isCsvSchema = (schema) => {
  assertAllKeysPresent({ schema });

  const allowedLegacyInvoicingDays = INVOICING_DAYS_CSV[INVOICING_DAYS_GROUP.FOUR_PER_MONTH].split(',');
  if (typeof schema === 'string') {
    const parsedSchema = schema.split(/[,\s]+/);
    return parsedSchema.every(day => allowedLegacyInvoicingDays.includes(day));
  }

  return (
    Array.isArray(schema)
    && (schema.every(day => allowedLegacyInvoicingDays.includes(day.toString())))
  );
};


/**
 * Checks if a given schema is of cron type
 *
 * @param {object} schema - The schema to check.
 *
 * @returns {boolean} Returns true if the schema is of cron type, false otherwise.
 */
export const isCronSchema = (schema) => {
  assertAllKeysPresent({ schema });
  return typeof schema.value === 'string' && schema.type === INVOICING_FREQUENCY_SCHEMA_TYPES.CRON;
};

/**
 * Converts a legacy schema to a cron expression
 *
 * @param {string|number[]} schema - The schema to convert.
 *
 * @returns {string} The converted cron expression
 */
export const convertLegacySchemaToCronExpression = (schema) => {
  assertAllKeysPresent({ schema });

  if (isCronSchema(schema)) {
    return schema;
  }

  if (!isCsvSchema(schema)) {
    throw new Error('schema is not of legacy type');
  }

  return `0 0 ${schema} * *`;
};


export const parseToCronSchema = (schema) => {
  if (isNil(schema)) {
    throw new Error('cannot parse an empty schema');
  }

  if (isCsvSchema(schema)) {
    if (typeof schema === 'string' || Array.isArray(schema)) {
      return {
        type: INVOICING_FREQUENCY_SCHEMA_TYPES.CRON,
        value: convertLegacySchemaToCronExpression(schema),
      };
    }
  }
  if (isCronSchema(schema)) {
    return schema;
  }

  throw new Error(`unknown schema type ${schema}`);
};

class InvoicingFrequency {
  static fromCronExpression(
    cronExpression,
    {
      licenceCronExpression = '0 0 1 * *',
    } = {}) {
    return new InvoicingFrequency({
      licence: [{
        type: INVOICING_FREQUENCY_SCHEMA_TYPES.CRON,
        value: licenceCronExpression,
      }],
      processing: [{
        type: INVOICING_FREQUENCY_SCHEMA_TYPES.CRON,
        value: cronExpression,
      }],
    });
  }

  static fromCalendarDays(calendarDays) {
    if (!Array.isArray(calendarDays)) {
      throw new Error('expecting an array of calendar days');
    }
    if (calendarDays.length === 1) {
      return InvoicingFrequency.customOncePerMonth(parseInt(calendarDays[0], 10) || 1);
    }
    return InvoicingFrequency.fromCronExpression(`0 0 ${calendarDays.join(',')} * *`);
  }

  /**
   * Creates an instance given a stringified dump of the schema
   * Originally, the dump is a csv of invoicing days. This can be transformed
   * later to any stringified version of a schema
   *
   * @param {string} dump - The dump as stored in the DB
   *
   * @returns {InvoicingFrequency} An instance representing the given dump
   */
  static fromDump(dump) {
    if (!dump) {
      throw new Error('dump is empty');
    }
    if (typeof dump !== 'string') {
      throw new Error('expecting a dump of string type');
    }
    if (!['[', '{'].includes(dump[0])) {
      throw new Error('dump is malformatted');
    }
    let sanitizedDump;
    try {
      sanitizedDump = JSON.parse(dump);
    } catch (e) {
      throw new Error(`dump is malformatted: ${e}`);
    }
    return new InvoicingFrequency(sanitizedDump);
  }

  static fromInvoicingFrequencyConstant(invoicingFrequencyConstant) {
    assert(!!invoicingFrequencyConstant, 'Invoicing frequency is required');
    if (!(Object.keys(INVOICING_FREQUENCY_TO_DAYS).includes(invoicingFrequencyConstant))) {
      throw new Error(`Unsupported invoicing frequency days: ${invoicingFrequencyConstant}`);
    }
    return InvoicingFrequency.fromCalendarDays(
      INVOICING_FREQUENCY_TO_DAYS[invoicingFrequencyConstant],
    );
  }

  static default() {
    return InvoicingFrequency.fromCronExpression('0 0 1,8,15,23 * *');
  }

  static monthly() {
    return InvoicingFrequency.fromCronExpression('0 0 1 * *');
  }

  static customOncePerMonth(day = 1) {
    assertAllKeysPresent({ day });
    assert(typeof day === 'number', 'day should be a number');
    const cronString = `0 0 ${day} * *`;
    return InvoicingFrequency.fromCronExpression(
      cronString,
      { licenceCronExpression: cronString },
    );
  }

  static customOncePerWeek(day = 5) {
    assertAllKeysPresent({ day });
    assert(typeof day === 'number', 'day should be a number');
    const cronString = `0 0 * * ${day}`;
    return InvoicingFrequency.fromCronExpression(cronString);
  }

  static twoPerMonth() {
    return InvoicingFrequency.fromCronExpression('0 0 1,15 * *');
  }

  static fourPerMonth() {
    return InvoicingFrequency.fromCronExpression('0 0 1,8,15,23 * *');
  }

  /**
   * Create the default invoicing frequency for organizations.
   * Used to create and initialise new organizations.
   *
   * @return {InvoicingFrequency} instance with the default frequency.
   */
  static defaultForOrganizations() {
    return InvoicingFrequency.fromCronExpression('0 0 1,8,15,23 * *');
  }

  /**
   * Returns the list of available frequency templates, for usage in configuration forms
   * It returns them as an array of choices, for rendering in the frontend
   *
   * @param {Object} params
   * @param {Date} params.now
   */
  static getAvailableFrequencyTemplates(extras = []) {
    const available = [
      InvoicingFrequency.monthly(),
      InvoicingFrequency.twoPerMonth(),
      InvoicingFrequency.fourPerMonth(),
      InvoicingFrequency.customOncePerWeek(5),
      ...extras,
    ].map(f => ({
      id: f.toDump(), name: f.toHumanizedString(),
    }));
    return uniqBy(available, 'id');
  }

  static _combine(invoicingFrequencies) {
    return new InvoicingFrequency({
      licence: flatten(invoicingFrequencies.map(freq => freq.serialize().licence)),
      processing: flatten(invoicingFrequencies.map(freq => freq.serialize().processing)),
    });
  }

  /**
   * According to current date (which can be overriden to replay a billing cycle),
   * it returns the next N billing processes
   *
   * @param {Date} now
   * @param {Number} count - the number of future billing processes to return
   * @returns {[BillingProcess]} An array of billing processes
   */
  static getNextBillingProcesses(invoicingFrequencies, { now = getDatetime(), count = 4 } = {}) {
    if (!invoicingFrequencies) {
      throw new Error('invoicingFrequencies are required');
    }
    if (!Array.isArray(invoicingFrequencies)) {
      throw new Error('expecting an array of invoicingFrequencies');
    }
    if (!invoicingFrequencies.every(freq => freq instanceof InvoicingFrequency)) {
      throw new Error('expecting each element to be an instance of InvoicingFrequency');
    }
    const processes = [];
    const freq = InvoicingFrequency._combine(invoicingFrequencies);

    let currDate = moment(now);
    do {
      const next = freq.getNextBillingProcess(currDate);
      processes.push(next);
      currDate = next.getBillingDeadline();
    } while (processes.length < count);

    return processes;
  }

  constructor({ licence, processing }) {
    this.details = {
      segments: {
        [INVOICING_FREQUENCY_SEGMENT.PROCESSING]: new InvoicingSegmentFrequency({
          segments: [INVOICING_FREQUENCY_SEGMENT.PROCESSING],
          schema: processing,
        }),
        [INVOICING_FREQUENCY_SEGMENT.LICENCE]: new InvoicingSegmentFrequency({
          segments: [INVOICING_FREQUENCY_SEGMENT.LICENCE],
          schema: licence,
        }),
      },
    };
  }

  serialize() {
    const serialized = {};
    Object.keys(this.details.segments).forEach(k => {
      serialized[k] = this.details.segments[k].serialize();
    });

    return serialized;
  }

  /**
   * Stringifies the schema of the instance, for storage in the DB
   *
   * @returns {string} A dump representing the instance, ready for storage in the DB
   */
  toDump() {
    return JSON.stringify(this.serialize());
  }

  // SEGMENT methods
  _getSegment(segment) {
    const { segments } = this.details;
    if (!segments[segment]) {
      throw new Error(`unknown segment ${segment}`);
    }
    return segments[segment];
  }

  _getLicenceSegment() {
    return this._getSegment(INVOICING_FREQUENCY_SEGMENT.LICENCE);
  }

  _getProcessingSegment() {
    return this._getSegment(INVOICING_FREQUENCY_SEGMENT.PROCESSING);
  }

  _getAllSegments() {
    return [this._getProcessingSegment(), this._getLicenceSegment()];
  }


  // PUBLIC INTERFACE
  isInvoicingDay(date, segment) {
    switch (segment) {
      case INVOICING_FREQUENCY_SEGMENT.PROCESSING:
        return this._getProcessingSegment().isMatchingDate(date);
      case INVOICING_FREQUENCY_SEGMENT.LICENCE:
        return this._getLicenceSegment().isMatchingDate(date);
      default:
        return this._getAllSegments().some(seg => seg.isMatchingDate(date));
    }
  }

  isBillingDate(billingDate) {
    return (
      this.isProcessingBillingDate(billingDate)
      || this.isLicenceBillingDate(billingDate)
    );
  }

  isProcessingBillingDate(billingDate) {
    const segment = this._getProcessingSegment();
    return segment.isDayBeforeMatch(billingDate);
  }

  isLicenceBillingDate(billingDate) {
    const segment = this._getLicenceSegment();
    return segment.isDayBeforeMatch(billingDate);
  }

  isMonthlyProcessingBillingDate(billingDate) {
    const segment = this._getProcessingSegment();
    if (!segment.isDayBeforeMatch(billingDate)) {
      return false;
    }
    const tomorrow = moment(billingDate).add(1, 'day').startOf('day');
    return segment.isFirstMatchOfMonth(tomorrow);
  }

  /**
   * According to current date (which can be overridden to replay a billing cycle),
   * return what was the billing process that billing should have *already* occurred.
   *
   * @param {Date} now
   * @returns BillingProcess
   */
  getLastBillingProcess(now = getDatetime()) {
    const processes = this._getAllSegments().map(
      seg => seg.getLastBillingProcess(now),
    );
    if (processes[0].getBillingDeadline().isAfter(processes[1].getBillingDeadline())) {
      return processes[0];
    }
    return processes[1];
  }

  getLastBillingDate(now = getDatetime()) {
    return this.getLastBillingProcess(now).getBillingDate();
  }

  /**
   * According to current date (which can be overriden to replay a billing cycle),
   * return what is the next billing process that billing will occur.
   *
   * @param {Date} now
   * @returns BillingProcess
   */
  getNextBillingProcess(now = getDatetime()) {
    const processes = this._getAllSegments().map(
      seg => seg.getNextBillingProcess(now),
    );
    if (processes[0].getBillingDeadline().isBefore(processes[1].getBillingDeadline())) {
      return processes[0];
    }
    return processes[1];
  }

  /**
   * According to current date (which can be overriden to replay a billing cycle),
   * return what is the next billing process that billing for PROCESSING segment will occur.
   *
   * @param {Date} now
   * @returns BillingProcess
   */
  getNextBillingProcessForProcessingSegment(now = getDatetime()) {
    return this._getProcessingSegment().getNextBillingProcess(now);
  }

  /**
   * According to current date (which can be overriden to replay a billing cycle),
   * return what is the next billing process that billing for LICENCE segment will occur.
   *
   * @param {Date} now
   * @returns BillingProcess
   */
  getNextBillingProcessForLicenceSegment(now = getDatetime()) {
    return this._getLicenceSegment().getNextBillingProcess(now);
  }

  /**
   * According to current date (which can be overriden to replay a billing cycle),
   * return what is the last billing process that billing for PROCESSING segment
   * should have occurred.
   *
   * @param {Date} now
   * @returns BillingProcess
   */
  getLastBillingProcessForProcessingSegment(now = getDatetime()) {
    return this._getProcessingSegment().getLastBillingProcess(now);
  }

  /**
  * According to current date (which can be overridden to replay a billing cycle), return what is
  * the current billing cycle.
  *
  * @params {Date} now
  * @returns Date
  */
  _getDatetimeRange(now = getDatetime(), { segment } = {}) {
    let segments = [];
    switch (segment) {
      case INVOICING_FREQUENCY_SEGMENT.PROCESSING:
        segments = [this._getProcessingSegment()];
        break;
      case INVOICING_FREQUENCY_SEGMENT.LICENCE:
        segments = [this._getLicenceSegment()];
        break;
      default:
        segments = [this._getProcessingSegment(), this._getLicenceSegment()];
        break;
    }
    const ranges = segments.map(
      seg => seg.getDatetimeRange(now),
    );

    return {
      fromDateTime: moment.min(ranges.map(r => r.fromDateTime)),
      toDateTime: moment.max(ranges.map(r => r.toDateTime)),
    };
  }

  getDatetimeRangeForProcessingSegment(now = getDatetime()) {
    return this._getDatetimeRange(now, { segment: INVOICING_FREQUENCY_SEGMENT.PROCESSING });
  }

  getDatetimeRangeForLicenceSegment(now = getDatetime()) {
    return this._getDatetimeRange(now, { segment: INVOICING_FREQUENCY_SEGMENT.LICENCE });
  }

  /**
   * Checks if the invoicing frequency is once per month.
   *
   * @returns {boolean} True if the event occurs only once per month,
   * false otherwise.
   */
  isOncePerMonth() {
    return this._getProcessingSegment().isOncePerMonth();
  }

  /**
   * Returns the serialized calendar days
   *
   * @returns {number[]} - an array of calendar days
   */
  toCalendarDaysArray() {
    return this._getProcessingSegment().getMatchingDaysOfMonth();
  }

  toHumanizedString({ segment } = {}) {
    switch (segment) {
      case INVOICING_FREQUENCY_SEGMENT.PROCESSING:
        return this._getProcessingSegment().toHumanizedString();
      case INVOICING_FREQUENCY_SEGMENT.LICENCE:
        return this._getLicenceSegment().toHumanizedString();
      default:
        break;
    }

    // default for all segments
    const descriptions = uniq(this._getAllSegments().map(seg => seg.toHumanizedString()));
    if (descriptions.length === 1) {
      return descriptions[0];
    }
    return `${this._getProcessingSegment().toHumanizedString()} for processing, ${this._getLicenceSegment().toHumanizedString()} for licence`;
  }

  toProcessingHumanizedString() {
    return this.toHumanizedString({ segment: INVOICING_FREQUENCY_SEGMENT.PROCESSING });
  }

  toLicenceHumanizedString() {
    return this.toHumanizedString({ segment: INVOICING_FREQUENCY_SEGMENT.LICENCE });
  }


  getAvailableFrequencyTemplates() {
    return InvoicingFrequency.getAvailableFrequencyTemplates([this]);
  }
}

export default InvoicingFrequency;
