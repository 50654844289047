import React from 'react';
import PropTypes from 'prop-types';

import { projectTaskSpec, projectSpec, taskParticipantSpec } from 'projects/assets/js/lib/objectSpecs';
import DiscussionBoard from 'core/assets/js/components/DiscussionBoard/DiscussionBoard.jsx';

const TaskDiscussionTab = ({
  task,
  project,
  accessControl,
  taskActions,
  stateName,
  onTaskUpdated,
  participants,
  onStateUpdated,
  onInfoUpdated,
  onDiscussionSelected,
  parentComponentName,
}) => (
  <DiscussionBoard
    task={task}
    project={project}
    actions={taskActions}
    stateName={stateName}
    parentComponentName={parentComponentName}
    participants={participants}
    accessControl={accessControl}
    onTaskUpdated={onTaskUpdated}
    onInfoUpdated={onInfoUpdated}
    onStateUpdated={onStateUpdated}
    onDiscussionSelected={onDiscussionSelected}
  />
);

TaskDiscussionTab.propTypes = {
  task: projectTaskSpec,
  project: projectSpec,
  accessControl: PropTypes.object,
  taskActions: PropTypes.arrayOf(PropTypes.object),
  stateName: PropTypes.string,
  parentComponentName: PropTypes.string.isRequired,
  onTaskUpdated: PropTypes.func.isRequired,
  onStateUpdated: PropTypes.func.isRequired,
  onDiscussionSelected: PropTypes.func,
  onInfoUpdated: PropTypes.func,
  participants: PropTypes.arrayOf(taskParticipantSpec),
};

TaskDiscussionTab.defaultProps = {
  task: {},
  project: {},
  accessControl: {},
  taskActions: [],
  stateName: null,
  participants: [],
  onDiscussionSelected: () => {},
  onInfoUpdated: () => {},
};

export default TaskDiscussionTab;
