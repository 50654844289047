import PropTypes from 'prop-types';
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { parseDate } from 'core/assets/js/lib/utils';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { API_DATE_FORMAT, BS_STYLE, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import WorksheetsTable from 'finance/assets/js/components/WorksheetsTable.jsx';
import { listReplaceItemAC } from 'core/assets/js/ducks/list';
import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  financeWorksheetListApiUrl, financeWorksheetAnalyticsApiUrl, financeWorksheetsCreateUrl,
} from 'finance/urls';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import {
  getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import { MAX_MONTHS_EXPORT_DURATION } from 'exporter/assets/js/constants';
import { exporterWorksheetsExportUrl } from 'exporter/urls';
import BillableAnalyticsBreakDownModal from 'finance/assets/js/BillableAnalyticsBreakDownModal.jsx';
import BillableAnalyticsMeta from 'finance/assets/js/BillableAnalyticsMeta.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { importCreateUrl } from 'importer/urls';

const WORKSHEET_BILLABLE_BREAKDOWN = 'worksheet-billable-breakdown';

const EXPORT_MODAL_ID = 'worksheets-export-modal-id';

const WorksheetsView  = ({
  filtersOpen, history, location, match: { params }, onFiltersToggle,
}) => {
  const dispatch = useDispatch();
  const { orgAlias } = params;

  const componentName = WorksheetsView.GetComponentName(params);

  const { isLoading, items, pagination, search: { isActive } } = fetchDataHook({
    componentName,
    duck: 'list',
    queryStringParams: params.taskId ? { taskId: params.taskId } : null,
    url: financeWorksheetListApiUrl(params.orgAlias),
  });
  const { item: { accessControl, amountLimits, billableAnalytics, dateLimits } } = fetchDataHook({
    changesArray: [location.search],
    componentName,
    url: `${financeWorksheetAnalyticsApiUrl(params.orgAlias)}${location.search}`,
  });

  const billableModalPayload = useSelector(state => getModalPayload(
    state, WORKSHEET_BILLABLE_BREAKDOWN,
  ));
  const activeOrg = useSelector(selectActiveOrg);
  const isExportModalOpen = useSelector(state => getIsModalOpen(state, EXPORT_MODAL_ID));

  const breadcrumbs = [
    {
      title: 'Worksheets',
      url: null,
    },
  ];

  const ctaButtonItems = [];
  if (accessControl?.canCreate) {
    ctaButtonItems.push({
      label: 'Submit worksheet',
      onClick: () => history.push(financeWorksheetsCreateUrl(orgAlias)),
      variant: BS_STYLE.PRIMARY,
    });
  }
  if (accessControl?.canBulkImport) {
    ctaButtonItems.push({
      label: 'Import worksheets',
      onClick: () => history.push(importCreateUrl(orgAlias)),
      variant: BS_STYLE.PRIMARY,
    });
  }

  const emptyText = 'No worksheets found';

  let currencySymbol = null;
  let totalAmount = null;
  let totalsByCurrency = {};
  if (billableAnalytics) {
    ({ totalAmount, totalsByCurrency } = billableAnalytics);
    const currencies = Object.keys(totalsByCurrency);
    const [firstCurrency] = currencies;
    const multipleCurrencies = currencies.length > 1;
    currencySymbol = CURRENCY_SYMBOL[multipleCurrencies ? activeOrg.currency : firstCurrency];
    totalAmount = multipleCurrencies ? totalAmount : totalsByCurrency[firstCurrency];
  }
  const billableAnalyticsMetaProps = {
    currencySymbol,
    onOpenModal: () => {
      dispatch(modalOpenAC(WORKSHEET_BILLABLE_BREAKDOWN, { billableAnalytics }));
    },
    showTotal: pagination.pages <= 1,
    totalAmount,
    total: pagination.total,
  };

  return (
    <React.Fragment>
      {billableModalPayload && <BillableAnalyticsBreakDownModal /> }

      <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

      <div className="page page--finance">
        <div className="container">
          <div className="row">
            {isActive && (
              <ReportSearch
                reportType={REPORT_TYPES.WORKSHEET}
                amountLimits={amountLimits}
                onFiltersToggle={onFiltersToggle}
                filtersOpen={filtersOpen}
              />
            )}
            {!filtersOpen && (
              <ListActionsButton
                options={[{
                  disabled: items?.length === 0,
                  name: 'Export',
                  onClick: () => {
                    const months = parseDate(dateLimits.maxDate, API_DATE_FORMAT).diff(
                      parseDate(dateLimits.minDate, API_DATE_FORMAT),
                      'month',
                      true,
                    );
                    if (months > MAX_MONTHS_EXPORT_DURATION) {
                      dispatch(modalOpenAC(EXPORT_MODAL_ID));
                      return;
                    }
                    WINDOW_OPEN(exporterWorksheetsExportUrl({
                      orgAlias, queryString: location.search,
                    }));
                  },
                }]}
              />
            )}
          </div>
          {isLoading && <FinanceTableSkeleton />}
          {!isLoading && !filtersOpen && (
            <>
              <div
                className={[
                  'finance-report',
                  'finance-report__table-wrapper',
                  'finance-report__table-wrapper--responsive',
                  'finance-report__table-wrapper--list',
                ].join(' ')}
              >
                <WorksheetsTable
                  list={items}
                  rateUnit={activeOrg.default_rate_unit}
                  emptyText={emptyText}
                  onItemUpdate={updatedItem => {
                    // Update list item
                    dispatch(listReplaceItemAC(updatedItem, componentName));
                    // Update finance pending counts.
                    dispatch(refreshFinancePendingCountsDS({ orgAlias }));
                  }}
                />
              </div>
              {billableAnalytics && pagination.pages <= 1 && (
                <BillableAnalyticsMeta {...billableAnalyticsMetaProps} />
              )}
              <TDPagination
                {...pagination}
                extraMeta={billableAnalytics && (
                  <BillableAnalyticsMeta {...billableAnalyticsMetaProps} />
                )}
              />
            </>
          )}
          <ModalConfirm
            body={(
              <TDSystemMessage
                title={`You cannot export more than ${MAX_MONTHS_EXPORT_DURATION} months`}
                type={BS_STYLE.DANGER}
              >
                You can export a maximum of
                {` ${MAX_MONTHS_EXPORT_DURATION} months worth of worksheets.`}
              </TDSystemMessage>
            )}
            confirmLabel="Refine filters"
            heading="Export worksheets"
            onClose={() => dispatch(modalCloseAC())}
            onConfirm={async () => {
              dispatch(modalCloseAC());
              onFiltersToggle(true);
            }}
            open={isExportModalOpen}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

WorksheetsView.GetComponentName = () => 'Worksheets';

WorksheetsView.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(withFilters(WorksheetsView));
