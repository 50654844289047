import PropTypes from 'prop-types';
import { getSalesTaxPropTypes } from 'settings/assets/js/lib/sales_tax_utils';

export const profileSpec = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobTitle: PropTypes.string,
  userRole: PropTypes.string,
  avatar: PropTypes.string,
});

export const userSchema = {
  id: PropTypes.number,
  email: PropTypes.string,
  profile: profileSpec,
};

export const userSpec = PropTypes.shape(userSchema);

export const addressComponentsSpec = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  country_code: PropTypes.string,
  street: PropTypes.string,
  postal_code: PropTypes.string,
});

export const companySpec = PropTypes.shape({
  name: PropTypes.string,
  logo: PropTypes.string,
  registration_number: PropTypes.string,
  vat: PropTypes.string,
  address: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  is_incorporated: PropTypes.bool,
  invoicable: PropTypes.bool,
  address_components: addressComponentsSpec,
  ...getSalesTaxPropTypes(),
});

export const timeoffSpec = PropTypes.shape({
  reason: PropTypes.string,
  description: PropTypes.string,
  periodStart: PropTypes.string,
  periodEnd: PropTypes.string,
});
