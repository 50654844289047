import { REQ_TYPE } from 'core/assets/js/constants';
import { viewUpdateKeysAC } from 'core/assets/js/ducks/view';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { toggleFavoriteApiURL } from 'search/urls';

/* eslint-disable import/prefer-default-export */
export const toggleFavoriteViewDS = ({ orgAlias, values, parentComponentName }) =>
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: toggleFavoriteApiURL({ orgAlias }),
    pushDataAC: (responseData) =>
      viewUpdateKeysAC(responseData, ['isFavorite'], parentComponentName),
    values,
  });
