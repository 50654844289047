import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { getIsModalOpen, modalOpenAC, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import US1099Contractors from 'finance/assets/js/components/US1099Contractors.jsx';
import USTaxCompanyDetails from 'finance/assets/js/components/USTaxCompanyDetails.jsx';
import USTaxForms from 'finance/assets/js/components/USTaxForms.jsx';
import {
  US_1099_CONTRACTORS_COMPONENT_NAME,
  US_TAX_1099_FILING_STATUS,
  US_TAX_1099_FILING_STATUS_LABEL,
  US_TAX_FILING_TABS,
} from 'finance/assets/js/constants';
import { getSelected1099Year } from 'finance/assets/js/ducks/hooks';
import { download1099FilingPDF } from 'finance/assets/js/lib/utils';
import {
  finance1099FilingsUrl,
  financeDownloadAll1099ApiUrl,
  financeFileAll1099ApiUrl,
  financeGet1099ContractorsApiUrl,
} from 'finance/urls';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { orgDashboardUrl } from 'organizations/urls';
import { PERMISSIONS } from 'roles/assets/js/constants';

const FILE_ALL_MODAL_ID = 'file-all-1099-modal-id';

const USTaxFilingsView = ({ location: { search }, match: { params: { tab } } }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const fileAllModalIsOpen = useSelector(state => getIsModalOpen(state, FILE_ALL_MODAL_ID));
  const dispatch = useDispatch();
  const year = getSelected1099Year();

  const reload1099Contractors = () => dispatch(fetchListDS({
    componentName: US_1099_CONTRACTORS_COMPONENT_NAME,
    url: `${financeGet1099ContractorsApiUrl(activeOrg.alias)}${search}`,
  }));

  const tabSpec = [];
  if (hasPermission(PERMISSIONS.CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS)) {
    tabSpec.push(
      {
        key: US_TAX_FILING_TABS['W-9_FORMS'],
        label: 'W-9 forms',
        href: finance1099FilingsUrl(activeOrg.alias, US_TAX_FILING_TABS['W-9_FORMS']),
      },
      {
        key: US_TAX_FILING_TABS['W-8_FORMS'],
        label: 'W-8 forms',
        href: finance1099FilingsUrl(activeOrg.alias, US_TAX_FILING_TABS['W-8_FORMS']),
      },
    );
  }
  const tax1099Enabled = activeOrg['1099_filings_enabled'];
  const canFile = tax1099Enabled && hasPermission(PERMISSIONS.CAN_FILE_1099_REPORTS);
  if (canFile) {
    tabSpec.push({
      key: US_TAX_FILING_TABS.COMPANY_DETAILS,
      label: 'Company details',
      href: finance1099FilingsUrl(activeOrg.alias, US_TAX_FILING_TABS.COMPANY_DETAILS),
    });
  }
  const canFileOrCanView = canFile || (
    tax1099Enabled && hasPermission(PERMISSIONS.CAN_VIEW_1099_CONTRACTORS)
  );
  if (canFileOrCanView) {
    tabSpec.push({
      key: US_TAX_FILING_TABS['1099_FILINGS'],
      label: '1099 filings',
      href: finance1099FilingsUrl(activeOrg.alias, US_TAX_FILING_TABS['1099_FILINGS']),
    });
  }

  let redirectUrl = null;
  if (tabSpec.length === 0) {
    redirectUrl = orgDashboardUrl(activeOrg.alias);
  } else if (!tabSpec.some(spec => spec.key === tab)) {
    redirectUrl = tabSpec[0].href;
  }
  if (redirectUrl) {
    return (
      <RedirectRoute
        from={`${finance1099FilingsUrl(activeOrg.alias, tab)}${search}`}
        status={302}
        to={redirectUrl}
      />
    );
  }

  const ctaActions = [];

  const isOn1099Tab = tab === US_TAX_FILING_TABS['1099_FILINGS'];

  const showFileAllButton = isOn1099Tab && canFile;
  if (showFileAllButton) {
    ctaActions.push({
      label: 'File 1099 for all contractors',
      onClick: () => dispatch(modalOpenAC(FILE_ALL_MODAL_ID)),
    });
  }
  if (isOn1099Tab && canFileOrCanView) {
    ctaActions.push({
      label: 'Download all',
      onClick: async () => {
        try {
          const { data: { count, tax1099FilingRequestId } } = await axios.post(
            financeDownloadAll1099ApiUrl(activeOrg.alias), { year },
          );
          if (tax1099FilingRequestId) {
            await download1099FilingPDF(activeOrg.alias, tax1099FilingRequestId);
            return;
          }
          if (count === 0) {
            toastr.warning('None to download', 'There are no 1099 filings to download');
            return;
          }
          toastr.success(
            'Well Done!',
            [
              `We have started generating a zip of ${count} 1099s filings for ${year}. We will `,
              'notify you when it is ready to download',
            ].join(''),
          );
        } catch (e) {
          toastr.error('Oh Snap!', e.response?.data?._error || e.message);
        }
      },
    });
  }

  return (
    <>
      <section className="page page--1099-filings pt-0">
        <ContentHeader
          breadcrumbs={[{ title: 'Taxation' }]}
          ctaComponent={ctaActions.length > 0 && <SplitButton actions={ctaActions} />}
        >
          <div className="horizontal-scroll"><TabBar activeKey={tab} tabSpec={tabSpec} /></div>
        </ContentHeader>
        <div className="container">
          {tab === US_TAX_FILING_TABS.COMPANY_DETAILS && <USTaxCompanyDetails />}
          {tab === US_TAX_FILING_TABS['1099_FILINGS'] && (
            <US1099Contractors reload={reload1099Contractors} />
          )}
          {[US_TAX_FILING_TABS['W-9_FORMS'], US_TAX_FILING_TABS['W-8_FORMS']].includes(tab) && (
            <USTaxForms />
          )}
        </div>
      </section>
      {showFileAllButton && (
        <ModalConfirm
          body={(
            <>
              <p>
                {`This will file a ${year} 1099 form for all contractors with a status of "`}
                {`${US_TAX_1099_FILING_STATUS_LABEL[US_TAX_1099_FILING_STATUS.NOT_FILED]}", who `}
                have earned $600 or more in that year.
              </p>
              <p>
                This will run in the background and take some time, we will notify you if there are
                any errors.
              </p>
            </>
          )}
          confirmLabel="File all 1099s"
          heading="File 1099 for all contractors"
          onClose={() => dispatch(modalCloseAC())}
          onConfirm={async () => {
            try {
              const { data: { count } } = await axios.post(
                financeFileAll1099ApiUrl(activeOrg.alias), { year },
              );
              if (count === 0) {
                toastr.warning(
                  'None to file', 'There are no contractors eligible for a 1099 filing',
                );
                return;
              }
              toastr.success(
                'Well Done!',
                [
                  `We have started filing ${count} 1099s for ${year}. If there are any errors, we`,
                  ' will notify you',
                ].join(''),
              );
              reload1099Contractors();
            } catch (e) {
              toastr.error('Oh Snap!', e.response?.data?._error || e.message);
            }
          }}
          open={fileAllModalIsOpen}
        />
      )}
    </>
  );
};

USTaxFilingsView.propTypes = {
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(USTaxFilingsView);
