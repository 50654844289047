import { FORM_ERROR } from 'final-form';
import { intersection, kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import { authProfileUpdateAC } from 'accounts/assets/js/reducers/auth';

import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { ORGANIZATION_CREATE_STEPS, PRESERVED_ORG_ALIAS } from 'organizations/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { orgCreateOrgApiUrl, orgDashboardUrl } from 'organizations/urls';
import OrgAccountingPrefsForm from 'organizations/assets/js/components/OrgAccountingPrefsForm.jsx';
import OrgCreateForm from 'organizations/assets/js/components/OrgCreateForm.jsx';
import { fetchOrgListDS } from 'organizations/assets/js/data-services/list';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { cleanSalesTaxSystemFormParams } from 'settings/assets/js/lib/sales_tax_utils';

const OrgOnboardView = ({ dispatch, history, location: { search } }) => {
  const { allowNewOrg, no_subscription: noSubscription } = queryString.parse(search);
  const canCreateOrg = allowNewOrg === 'td';

  const [pageNumber, setPageNumber] = useState(0);
  const firstPageFieldNames = useMemo(() => ['name', 'address', 'unique_alias', 'registration_number']);

  if (!canCreateOrg) {
    return (
      <div className="text-center p-5 mt-5 text-white">
        Please contact sales.
      </div>
    );
  }

  const validateOnboardingForm = useCallback((formValues) => {
    const { address, name, unique_alias: uniqueAlias } = formValues;

    if (!name || name.length < 4) {
      return { name: 'Please provide a valid organisation name' };
    }

    if (!uniqueAlias) {
      return { address: 'Please provide a valid org alias' };
    }

    if (uniqueAlias) {
      const aliasIsPreserved = Object.values(PRESERVED_ORG_ALIAS).find(
        preservedValue => uniqueAlias.includes(preservedValue),
      );

      if (aliasIsPreserved) {
        return { unique_alias: 'This organisation alias already exist. Please choose a unique name' };
      }
    }

    if (!address) {
      return { address: 'Please provide a valid address' };
    }

    return null;
  }, []);

  const onSubmitOnboardingForm = useCallback(async (formValues) => {
    try {
      cleanSalesTaxSystemFormParams(formValues);

      const response = await axios.post(orgCreateOrgApiUrl(), formValues);
      dispatch(authProfileUpdateAC(response.data.authState));
      await dispatch(fetchSetupStepsDS());
      // IMPORTANT: Since a new organization was just created, we need to update the
      //            org list in the store, so that CoreRoute component will activate the
      //            newly created org!
      await dispatch(fetchOrgListDS());
      history.push(orgDashboardUrl(kebabCase(formValues.name)));
      return null;
    } catch (error) {
      if (error?.response?.data?._meta?.isValidation) {
        const errorsOnFirsPage = intersection(
          Object.keys(error.response.data), firstPageFieldNames,
        ).length > 0;

        if (errorsOnFirsPage) {
          setPageNumber(0);
        }
        return error.response.data;
      }
      const errorMessage = error?.response?.data?._error || error.message;
      toastr.error('Oh Snap!', errorMessage);
      return { [FORM_ERROR]: errorMessage };
    }
  }, []);

  return (
    <div className="page page--org-onboarding page--no-shadow-effect">
      <div className="container py-5">
        <Wizard
          className="create-org-form"
          initialValues={{
            default_rate_unit: RATE_UNIT.PER_HOUR,
            no_subscription: noSubscription === 'true' ? 1 : 0,
          }}
          onStepChange={newPageNumber => setPageNumber(newPageNumber)}
          onSubmit={onSubmitOnboardingForm}
          pageNumber={pageNumber}
          submitBtnTitle="Create organization"
        >
          <Wizard.Page
            beforePageChange={validateOnboardingForm}
            component={OrgCreateForm}
            step={ORGANIZATION_CREATE_STEPS.CREATE}
            title="Company details"
          />
          <Wizard.Page
            component={OrgAccountingPrefsForm}
            step={ORGANIZATION_CREATE_STEPS.PREFERENCES}
            title="Finance details"
          />
        </Wizard>
      </div>
    </div>
  );
};

OrgOnboardView.GetComponentName = () => 'OrgOnboardView';

OrgOnboardView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const OrgOnboardViewConnect = connect(mapStateToProps, mapDispatchToProps)(OrgOnboardView);

export default withRouter(OrgOnboardViewConnect);
