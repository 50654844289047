import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ModalConfirmFinalForm from 'core/assets/js/components/ModalConfirmFinalForm.jsx';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { viewFetchExtrasAC, viewUpdateAC } from 'core/assets/js/ducks/view';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { PROJECT_STATE_CHANGE } from 'projects/assets/js/constants';
import { projectStateUpdateApiUrl } from 'projects/urls';

export const MODAL_ID = 'project-reopen-modal';

const ProjectReopenModal = ({
  rootComponentName,
}) => {
  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const dispatch = useDispatch();
  const { id: projectId, orgAlias } = useParams();
  const onClose = () => dispatch(modalCloseAC());

  return (
    <ModalConfirmFinalForm
      confirmLabel="Re-open project"
      FormContentComponent={({ renderProps }) => {
        const { submitError } = renderProps;
        return (
          <>
            <p className="mb-4" data-testid="project-finalize-form-text">
              Are you sure that you want to re-open this Project?
            </p>
            {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
          </>
        );
      }}
      formProps={{
        onSubmit: async values => { // eslint-disable-line consistent-return
          try {
            const { data } = await axios.put(
              projectStateUpdateApiUrl(
                orgAlias, projectId, PROJECT_STATE_CHANGE.REOPEN,
              ),
              values,
            );
            dispatch(viewUpdateAC(data.project, rootComponentName));
            dispatch(viewFetchExtrasAC(data.allowedActions, rootComponentName, 'allowedActions'));
            onClose();
          } catch (err) {
            return parseAxiosErrorForFinalForm(err);
          }
        },
      }}
      heading="This project needs more work?"
      onClose={onClose}
      open={isOpen}
    />
  );
};

ProjectReopenModal.propTypes = {
  rootComponentName: PropTypes.string.isRequired,
};

export default ProjectReopenModal;
