import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { SkillVotesBadge } from 'core/assets/js/components/SkillTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

/**
 * Custom option component
 * for SkillMultiSelect component
 */
const SkillSelectorTag = ({
  skill, onUpVote, onRetractVote,
}) => {
  const { label, votesCount = 1, canUpVote, canRetractVote = true } = skill;
  const classNames = ['skill-selector-tag'];
  const btnClassName = ['btn-vote'];
  const hasSingleVote = votesCount === 1;

  if (canUpVote) {
    btnClassName.push('up-vote');
  }

  if (canRetractVote && !hasSingleVote) {
    btnClassName.push('retract-vote');
  }

  if (canRetractVote && hasSingleVote) {
    btnClassName.push('remove-endorsement');
  }

  return (
    <span className={classNames.join(' ')}>
      {label}
      <SkillVotesBadge count={votesCount} />
      {(canUpVote || canRetractVote) && (
        <TDButton
          rounded
          variant={[BS_STYLE.LINK]}
          className={btnClassName.join(' ')}
          onClick={() => {
            const callback = canUpVote ? onUpVote : onRetractVote;
            callback({ skill });
          }}
        >
          <span className={ICON.CROSS} />
          <span className={ICON.THUMBSUP} />
          <span className={ICON.THUMBSUP_SOLID} />
        </TDButton>
      )}
    </span>
  );
};

SkillSelectorTag.propTypes = {
  skill: PropTypes.object.isRequired,
  onUpVote: PropTypes.func,
  onRetractVote: PropTypes.func,
};

SkillSelectorTag.defaultProps = {
  onUpVote: () => {},
  onRetractVote: () => {},
};

export default SkillSelectorTag;
