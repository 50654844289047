import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { getListState } from 'core/assets/js/ducks/list';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { orgSettingsRatesCreateUrl } from 'rates/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import RateList from 'rates/assets/js/components/RateList.jsx';
import { fetchRatesDS } from 'rates/assets/js/data-services/rates';


class RatesView extends React.Component {
  static FetchData({ dispatch, params, url = '', authedAxios, componentName }) {
    return dispatch(fetchRatesDS({ orgAlias: params.orgAlias, url, authedAxios, componentName }));
  }

  static GetComponentName() {
    return 'RatesView';
  }

  render() {
    const { rates, history, params } = this.props;
    const breadcrumbs = [
      {
        title: 'Rates',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaButtonItems={[{
            label: 'New rate',
            onClick: () => history.push(orgSettingsRatesCreateUrl(params.orgAlias)),
            variant: BS_STYLE.PRIMARY,
          }]}
        />

        <div className="page page--settings page--finance-settings">
          <div className="container">
            <div className="rounded shadow-sm p-4 bg-white">
              <div className="page--settings__content clearfix">
                <TDApiConnected
                  duck="list"
                  component={RatesView}
                  skeletonComponent={SettingsPageSkeleton}
                >
                  <RateList rates={rates} listComponentName={RatesView.GetComponentName()} />
                </TDApiConnected>
              </div>
            </div>
            <div className="mt-4 hint">
              Changing your agreed rate does not affect your active projects or tasks
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RatesView.propTypes = {
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)).isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  params: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, RatesView.GetComponentName());

  return {
    params: props.match.params,
    rates: listState.items,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const RatesViewConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(RatesView);

export default withRouter(RatesViewConnected);
