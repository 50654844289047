import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isObject } from 'lodash';
import { DateRangePicker } from 'react-dates';
import { ANCHOR_LEFT, ANCHOR_RIGHT } from 'react-dates/constants';
import 'react-dates/initialize';

import { isSSR } from 'core/assets/js/config/checks';
import { API_DATE_FORMAT, DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import withField from 'core/assets/js/components/withField.jsx';
import { WINDOW_MATCH_MEDIA } from 'core/assets/js/config/settings';

class DateRangeFilterField extends React.Component {
  constructor(props) {
    super(props);
    const { initialValues, filter } = props;

    this.state = {
      focusedInput: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.parseValue = this.parseValue.bind(this);

    let _initialValue = { startDate: null, endDate: null };

    if (initialValues[filter.paramName]) {
      const parsed = this.parseValue(initialValues[filter.paramName]);

      _initialValue = {
        startDate: parsed.min && moment(parsed.min),
        endDate: parsed.max && moment(parsed.max),
      };
    }

    this.handleChange(_initialValue);
  }

  parseValue(value) { // eslint-disable-line class-methods-use-this
    return isObject(value) ? value : JSON.parse(value);
  }

  handleChange({ startDate, endDate }) {
    const { input } = this.props;
    const serialized = JSON.stringify({
      /*
       Setting undefined as default value so that is being ignored by stringify
       (so that is is also skipped from queryParams too)
       */
      min: startDate ? startDate.format(API_DATE_FORMAT) : undefined,
      max: endDate ? endDate.format(API_DATE_FORMAT) : undefined,
    });

    // Update redux form
    input.onChange(serialized);
  }

  render() {
    const { focusedInput } = this.state;
    const {
      anchorDirection, filter: { isOutsideRange = () => false, label }, input, meta: { rest },
    } = this.props;
    const value = input.value && this.parseValue(input.value);
    let totalMonths = 2;
    const mobileSizeQuery = '(max-width: 576px)';
    // in smaller devices showing 2 months creates responsiveness issues
    // https://github.com/airbnb/react-dates/issues/262
    if (!isSSR && WINDOW_MATCH_MEDIA && WINDOW_MATCH_MEDIA(mobileSizeQuery)
      && WINDOW_MATCH_MEDIA(mobileSizeQuery).matches
    ) {
      totalMonths = 1;
    }

    return (
      <React.Fragment>
        {label && (
          <h4>{label}</h4>
        )}

        <div className="row">
          <div className="col-12">
            <DateRangePicker
              hideKeyboardShortcutsPanel
              minimumNights={0}
              small
              block
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              numberOfMonths={totalMonths}
              anchorDirection={anchorDirection}
              startDate={(value && value.min && moment(value.min)) || undefined}
              isOutsideRange={isOutsideRange}
              startDateId="unique_start_date_id"
              endDate={(value && value.max && moment(value.max)) || undefined}
              endDateId="unique_end_date_id"
              onDatesChange={({ startDate, endDate }) => ( // eslint-disable-line no-shadow
                this.handleChange({ startDate, endDate })
              )}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => ( // eslint-disable-line no-shadow
                this.setState({ focusedInput },
                ))}
              displayFormat={DATE_FORMAT_DEFAULT}
            />

            <input
              {...input}
              type="hidden"
              className="form-control"
              {...rest}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DateRangeFilterField.propTypes = {
  anchorDirection: PropTypes.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]),
  filter: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
};

DateRangeFilterField.defaultProps = {
  anchorDirection: ANCHOR_LEFT,
  filter: { valuePrefix: '' },
  label: null,
  meta: {},
};

export default withField(DateRangeFilterField);
