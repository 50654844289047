export const NOTIFICATIONS_SUMMARIES_ARE_LOADING = 'redux-notifications/NOTIFICATIONS_SUMMARIES_ARE_LOADING';
export const NOTIFICATIONS_SUMMARIES_LOAD_SUCCESS = 'redux-notifications/NOTIFICATIONS_SUMMARIES_LOAD_SUCCESS';
export const NOTIFICATIONS_SUMMARIES_RESET = 'redux-notifications/NOTIFICATIONS_SUMMARIES_RESET';
export const NOTIFICATIONS_MARKSEEN_SUCCESS = 'redux-notifications/NOTIFICATIONS_MARKSEEN_SUCCESS';
export const NOTIFICATIONS_MARKREAD_SUCCESS = 'redux-notifications/NOTIFICATIONS_MARKREAD_SUCCESS';
export const NOTIFICATIONS_MARK_ALL_READ_SUCCESS = 'redux-notifications/NOTIFICATIONS_MARK_ALL_READ_SUCCESS';
export const PREVIEW_IS_LOADING = 'redux-notifications/PREVIEW_IS_LOADING';
export const PREVIEW_LOAD_SUCCESS = 'redux-notifications/PREVIEW_LOAD_SUCCESS';
export const PREVIEW_RESET = 'redux-notifications/PREVIEW_RESET';


const SUMMARY_LIST_INITIAL_STATE = {
  hasLoaded: false,
  isLoading: false,
  newCount: 0,
  storeComponentName: '',
  total: 0,
  items: [],
};

const initialState = {
  summaries: SUMMARY_LIST_INITIAL_STATE,
  previewActive: {
    isLoading: true,
    item: {},
  },
};

/**
 * Handles the summary list related state
 * @param state
 * @param action
 * @returns {*}
 */
const summaries = (state = SUMMARY_LIST_INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS_SUMMARIES_ARE_LOADING:
      return {
        ...state,
        storeComponentName: action.componentName,
        isLoading: action.isLoading,
        hasLoaded: !action.isLoading,
      };
    case NOTIFICATIONS_SUMMARIES_LOAD_SUCCESS:
      return {
        ...state,
        storeComponentName: action.componentName,
        items: action.items,
        newCount: action.newCount,
        total: action.total,
        isLoading: false,
        hasLoaded: true,
      };
    case NOTIFICATIONS_MARKSEEN_SUCCESS:
      return {
        ...state,
        newCount: 0,
      };
    case NOTIFICATIONS_MARK_ALL_READ_SUCCESS:
      return {
        ...state,
        items: state.items.map(ntf => ({ ...ntf, is_read: true })),
      };
    case NOTIFICATIONS_MARKREAD_SUCCESS:
      return {
        ...state,
        items: state.items.map((ntf) => {
          if (action.read.includes(ntf.id)) {
            return { ...ntf, is_read: true };
          }
          if (action.unread.includes(ntf.id)) {
            return { ...ntf, is_read: false };
          }
          return ntf;
        }),
      };
    case NOTIFICATIONS_SUMMARIES_RESET:
      return { ...SUMMARY_LIST_INITIAL_STATE };
    default:
      return state;
  }
};

/**
 * Handles the active notification related state
 * @param state
 * @param action
 * @returns {*}
 */
const previewActive = (state = initialState.previewActive, action) => {
  switch (action.type) {
    case PREVIEW_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case PREVIEW_LOAD_SUCCESS:
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    case PREVIEW_RESET:
      return initialState.previewActive;
    default:
      return state;
  }
};
/**
 * Expected state structure
 * {
 *   notifications: {
 *     summaries: {
 *       isLoading: false,
 *       unread: 0,
 *       total: 0,
 *       items: [{},...{}]
 *     },
 *     previewActive: {
 *       isLoading: false,
 *       item: {},
 *     },
 *   }
 * }
 * @param state
 * @param action
 * @returns {*}
 */

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_SUMMARIES_ARE_LOADING:
    case NOTIFICATIONS_SUMMARIES_LOAD_SUCCESS:
    case NOTIFICATIONS_SUMMARIES_RESET:
    case NOTIFICATIONS_MARKSEEN_SUCCESS:
    case NOTIFICATIONS_MARKREAD_SUCCESS:
    case NOTIFICATIONS_MARK_ALL_READ_SUCCESS:
      return {
        ...state,
        summaries: summaries(state.summaries, action),
      };
    case PREVIEW_IS_LOADING:
    case PREVIEW_LOAD_SUCCESS:
    case PREVIEW_RESET:
      return {
        ...state,
        previewActive: previewActive(state.previewActive, action),
      };
    default:
      return state;
  }
};

// ACTION CREATORS
//
export const notificationsSummariesAreLoadingAC = (bool, componentName) => ({
  type: NOTIFICATIONS_SUMMARIES_ARE_LOADING,
  componentName,
  isLoading: bool,
});

export const notificationsSummariesSuccessAC = ({
  notifications, total, newCount, componentName,
}) => ({
  type: NOTIFICATIONS_SUMMARIES_LOAD_SUCCESS,
  componentName,
  items: notifications,
  newCount,
  total,
});

export const notificationsSummariesResetAC = () => ({
  type: NOTIFICATIONS_SUMMARIES_RESET,
});

export const notificationsMarkAsSeenSuccessAC = () => ({
  type: NOTIFICATIONS_MARKSEEN_SUCCESS,
});

export const notificationsMarkReadSuccessAC = ({ read, unread }) => ({
  type: NOTIFICATIONS_MARKREAD_SUCCESS,
  read,
  unread,
});

export const notificationsMarkAllReadSuccessAC = () => ({
  type: NOTIFICATIONS_MARK_ALL_READ_SUCCESS,
});

export const previewIsLoadingAC = bool => ({
  type: PREVIEW_IS_LOADING,
  isLoading: bool,
});

export const previewLoadSuccessAC = notification => ({
  type: PREVIEW_LOAD_SUCCESS,
  item: notification,
});

export const previewResetAC = () => ({
  type: PREVIEW_RESET,
});

// SELECTORS

export const selectSummaries = state => (
  state.notifications && state.notifications.summaries
    ? state.notifications.summaries
    : SUMMARY_LIST_INITIAL_STATE
);

export default reducer;
