import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { modalOpenAC, getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';

class LinkModal extends React.Component {
  /**
   * Returns a link with a 'Edit' default label, where when clicked shows
   * a modal with any content supplied
   */

  constructor(props) {
    super(props);
    this.handleCloseLinkModal = this.handleCloseLinkModal.bind(this);
    this.handleOpenLinkModal = this.handleOpenLinkModal.bind(this);
  }

  handleOpenLinkModal() {
    const { dispatch, modalId } = this.props;
    dispatch(modalOpenAC(modalId));
  }

  handleCloseLinkModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const { isShowMoreModalOpen, modalOptions, label, className, wrapperClasses } = this.props;
    const wrapperClassNames = ['d-inline'];
    if (wrapperClasses) {
      wrapperClassNames.push(wrapperClasses);
    }
    return (
      <div className={wrapperClassNames.join(' ')}>
        <span
          className={`${className}`}
          onClick={this.handleOpenLinkModal}
        >
          {label}
        </span>
        <ModalSimple
          body={modalOptions.body}
          footer={modalOptions.footer}
          heading={modalOptions.heading}
          onClose={this.handleCloseLinkModal}
          open={isShowMoreModalOpen}
        />
      </div>
    );
  }
}

LinkModal.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isShowMoreModalOpen: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalOptions: PropTypes.object,
  wrapperClasses: PropTypes.string,
  modalId: PropTypes.string.isRequired,
};

LinkModal.defaultProps = {
  className: '',
  isShowMoreModalOpen: false,
  wrapperClasses: '',
  modalOptions: {
    body: '',
    heading: 'Details',
    footer: '',
  },
  label: <span className="imitate-link">Edit</span>,
};

const mapStateToProps = (state, props) => {
  const { modalId } = props;
  return ({
    isShowMoreModalOpen: getIsModalOpen(state, modalId),
  });
};

const mapDispatchToProps = dispatch => ({ dispatch });

const LinkModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkModal);

export default LinkModalConnected;
