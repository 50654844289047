import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { NOTIFICATION_TYPE_LABELS } from 'notifier/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgNotificationSettingsGetPreviewApiUrl } from 'settings/urls';

export const MODAL_ID = 'email-template-preview';

const EmailTemplatePreview = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const modalPayload = useSelector(state => getModalPayload(state, MODAL_ID)) || {};
  const { type } = modalPayload;
  const [htmlPreview, setHTMLPreview] = useState(null);
  const activeOrg = useSelector(selectActiveOrg);

  const closeModal = () => dispatch(modalCloseAC());

  useEffect(() => {
    if (!type) {
      setHTMLPreview(null);
      return;
    }
    axios
      .post(
        orgNotificationSettingsGetPreviewApiUrl(activeOrg.alias, type),
        pick(modalPayload, 'markdown', 'subject', 'text'),
      )
      .then(({ data: { html } }) => {
        setHTMLPreview(html);
      })
      .catch(error => {
        closeModal();
        toastr.error('Oh Snap!', error.response?.data?._error || error.message);
      });
  }, [type]);

  const heading = type ? `${NOTIFICATION_TYPE_LABELS[type]} email preview` : 'Email preview';

  return (
    <ModalSimple
      body={htmlPreview && (
        <iframe
          className="email-template-preview-iframe w-100 border-0"
          srcDoc={htmlPreview}
          title={heading}
        />
      )}
      heading={heading}
      onClose={closeModal}
      open={isModalOpen}
      size={MODAL_SIZES.LARGE}
    />
  );
};

export default EmailTemplatePreview;
