import PropTypes from 'prop-types';

export const contactCardSchema = {
  avatar: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string,
  id: PropTypes.number,
  address: PropTypes.object,
  phone: PropTypes.string,
  resume: PropTypes.string,
  jobTitle: PropTypes.string,
};
export const contactCardSpec = PropTypes.shape(contactCardSchema);
