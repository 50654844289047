import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HSChat from 'core/assets/js/lib/HSChat';
import { BEAMER } from 'core/assets/js/config/settings';
import { knowledgebaseUrl, knowledgebaseTicketUrl } from 'core/urls';

const NavBarHelpMenu = ({ onClose }) => {
  const hsChatOpen = () => {
    onClose();
    HSChat.openHSChat();
  };

  const tryOpenBeamer = () => {
    if (BEAMER) {
      BEAMER.show();
    }
  };

  return (
    <div className="px-3">
      <div className="help-menu">
        <a
          className="dropdown-item td-open-beamer"
          id="td-open-whats-new-help-list"
          onClick={tryOpenBeamer}
        >
          <i className="fa-light fa-bullhorn" />
          <strong>What&apos;s New</strong>
        </a>
        <a
          href={knowledgebaseUrl()}
          className="dropdown-item"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="fa-light fa-book" />
          <strong>Knowledge Base</strong>
        </a>
        <a
          href={knowledgebaseTicketUrl()}
          className="dropdown-item"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="fa-light fa-message-question" />
          <strong>Open a Ticket</strong>
        </a>
        <a
          className="dropdown-item"
          onClick={hsChatOpen}
        >
          <i className="fa-light fa-message" />
          <strong>Live Chat</strong>
        </a>
        <a
          className="dropdown-item"
          rel="noopener noreferrer"
          target="_blank"
          href="mailto: support@talentdesk.io"
        >
          <i className="fa-light fa-envelope" />
          <strong>Contact Us</strong>
        </a>
      </div>
    </div>
  );
};

NavBarHelpMenu.propTypes = {
  onClose: PropTypes.func,
};

NavBarHelpMenu.defaultProps = {
  onClose: () => {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const NavBarHelpMenuConnect = connect(
  null,
  mapDispatchToProps,
)(NavBarHelpMenu);

export default withRouter(NavBarHelpMenuConnect);
