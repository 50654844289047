import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import {
  USER_CARD_STATUS, ICON, USER_CARD_STATUS_LABEL, USER_CARD_STATUS_CLASS,
} from 'core/assets/js/constants';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { DOCUMENT_BODY_CLICK } from 'core/assets/js/config/settings';

const OnboardingStatus = ({
  deactivationReason, hideDeactivationReason, showBackgroundLine, status, centerAligned,
}) => {
  if (status === USER_CARD_STATUS.APPROVED) {
    return null;
  }
  const classNames = [
    'onboarding-status mx-n4',
    `onboarding-status--${USER_CARD_STATUS_CLASS[status]}`,
  ];

  if (centerAligned) {
    classNames.push('text-center');
  }

  if (showBackgroundLine) {
    classNames.push('onboarding-status--with-background-stripe');
  }

  return (
    <div className={classNames.join(' ')}>
      <StatusTag
        hideTitle
        statusClass={USER_CARD_STATUS_CLASS[status]}
        label={USER_CARD_STATUS_LABEL[status]}
      />

      {deactivationReason && !hideDeactivationReason && status === USER_CARD_STATUS.DEACTIVATED && (
        <OverlayTrigger
          delay={{ show: 250, hide: 0 }}
          overlay={(
            <Popover
              id="popover-reason"
            >
              <Popover.Title>
                Deactivation reason
                <span
                  className="popover__close-button"
                  onClick={() => DOCUMENT_BODY_CLICK()}
                >
                  <i className={ICON.TIMES} />
                </span>
              </Popover.Title>

              <Popover.Content>
                {deactivationReason}
              </Popover.Content>
            </Popover>
          )}
          placement="auto"
          rootClose
          trigger="click"
        >
          <a><i className={ICON.INFO_2} /></a>
        </OverlayTrigger>
      )}
    </div>
  );
};

OnboardingStatus.propTypes = {
  deactivationReason: PropTypes.string,
  hideDeactivationReason: PropTypes.bool,
  showBackgroundLine: PropTypes.bool,
  centerAligned: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(USER_CARD_STATUS)),
};

OnboardingStatus.defaultProps = {
  deactivationReason: null,
  centerAligned: false,
  hideDeactivationReason: false,
  showBackgroundLine: true,
  status: USER_CARD_STATUS.PENDING,
};

export default OnboardingStatus;
