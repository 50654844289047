import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const EmptyPlaceHolder = ({ content, cta, icon }) => (
  <div className="widget-empty-placeholder">
    <i className={`widget-empty-placeholder__icon ${icon}`} src={icon} />
    <p className="widget-empty-placeholder__content">{content}</p>


    {cta && (
      <TDButton
        className="widget-empty-placeholder-action mt-3"
        label={cta.label}
        onClick={cta.onClick}
        variant={cta.variant || BS_STYLE.DEFAULT}
      />
    )}
  </div>
);

EmptyPlaceHolder.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.string,
  cta: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
  }),
};

EmptyPlaceHolder.defaultProps = {
  content: 'The list is currently empty',
  icon: ICON.GRID_HORIZONTAL,
  cta: null,
};

export default EmptyPlaceHolder;
