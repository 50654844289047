import React from 'react';
import PropTypes from 'prop-types';

import ChartStackedColumns from 'core/assets/js/components/ChartStackedColumns.jsx';
import { stringifiedProfilePic, prepareSeriesChartData } from 'projects/assets/js/lib/tabCharts';
import ProjectChartStackedSkeleton from 'projects/assets/js/components/skeletons/ProjectChartStackedSkeleton.jsx';

const prepareStackedChartData = ({ analytics, projectMembers, type }) => ({
  xAxisCategories: projectMembers,
  xAxisFormatter: value => stringifiedProfilePic({ user: value }),
  series: prepareSeriesChartData({ analytics, type }),
  totalEntries: analytics.length > 0 ? projectMembers.length : 0,
});

const ChartStacked = ({
  analytics, currencySymbol, emptyText, projectMembers, type,
}) => {
  const hasAnalytics = analytics.length > 0;
  return (
    <React.Fragment>
      { hasAnalytics && (
        <div className="chart-stacked-container mb-4">
          <ChartStackedColumns
            chartData={prepareStackedChartData({ analytics, projectMembers, type })}
            currencySymbol={currencySymbol}
          />
        </div>
      )}
      { !hasAnalytics && (
        <div className="chart-stacked-container mb-4">
          <ProjectChartStackedSkeleton
            noDataMsg={emptyText}
            noData
          />
        </div>
      )}
    </React.Fragment>
  );
};

ChartStacked.propTypes = {
  analytics: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  emptyText: PropTypes.string,
  projectMembers: PropTypes.array.isRequired,
  type: PropTypes.number.isRequired,
};

ChartStacked.defaultProps = {
  emptyText: null,
};

export default ChartStacked;
