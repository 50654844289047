import React from 'react';
import PropTypes from 'prop-types';
import Table from 'core/assets/js/components/Table.jsx';
import { customFieldSpec } from 'interviews/assets/js/lib/objectSpecs';
import { truncate } from 'core/assets/js/lib/utils';

/**
 * The table we show in the third step of the uploading contacts form
 * @param {Object} params
 * @param {Array} params.contacts
 * @param {Array<String>} params.headers
 * @param {Array<Object>} params.customFields - used to show the actual custom
 * field question as the table header
 * @returns {JSX.Element|null}
 * @constructor
 */
function ContactsImportAnalysisTable({ contacts, headers, customFields }) {
  if (!Array.isArray(headers) || (!headers.length)) {
    return null;
  }
  const maxHeaderWords = 10;
  const tableColumns = [
    { key: 'rowNumber', label: '#', width: '40px' },
    ...headers.map((header) => {
      const currentCustomField = customFields.find((field) => field.path === header);
      const label = currentCustomField ? currentCustomField.label : header;
      return ({ key: header, label: truncate(label, maxHeaderWords), width: '125px' });
    }),
    { key: 'validationErrors', label: 'Status', width: '180px' },
  ];
  return (
    <Table
      cols={tableColumns}
      items={contacts}
      emptyText="No invalid contacts found"
    />
  );
}

ContactsImportAnalysisTable.propTypes = {
  contacts: PropTypes.array,
  customFields: PropTypes.arrayOf(customFieldSpec),
  headers: PropTypes.array,
};
ContactsImportAnalysisTable.defaultProps = {
  contacts: [],
  customFields: [],
  headers: [],
};

export default ContactsImportAnalysisTable;
