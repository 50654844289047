import { pick } from 'lodash';

import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  financeExpenseViewApiUrl,
  financeInvoiceViewApiUrl,
  financeActiveSubscriptionApiUrl,
  financeManagersBudgetBreakdownApiUrl,
  financeProFormaInvoiceViewApiUrl,
  financePurchaseOrderViewApiUrl,
  financeWorksheetViewApiUrl,
  financeSettleDeferredInvoicesApiUrl,
} from 'finance/urls';
import {
  extrasUpdateAC, viewFetchAC, viewFetchErrorAC, viewFetchExtrasAC,
} from 'core/assets/js/ducks/view';
import { REQ_TYPE } from 'core/assets/js/constants';

const _validateParams = ({ id, orgAlias }) => {
  if (!orgAlias || !id) {
    throw new Error(`[_fetchFinanceDocumentDS Error] Invalid orgAlias (${orgAlias}) or id (${id}) params`);
  }
};

// Fetch the manager's budget postings list
export const fetchFinanceManagerBudgetBreakdownDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ id, orgAlias }),
  fetchApiUrl: () => financeManagersBudgetBreakdownApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  fetchDataErrorAC: viewFetchErrorAC,
});

export const fetchFinancePurchaseOrderDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ id, orgAlias }),
  fetchApiUrl: () => financePurchaseOrderViewApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => [
    viewFetchAC(responseData.purchaseOrder, componentName),
    viewFetchExtrasAC(responseData.accessControl, componentName),
  ],
  fetchDataErrorAC: viewFetchErrorAC,
});

export const fetchFinanceWorksheetDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ id, orgAlias }),
  fetchApiUrl: () => financeWorksheetViewApiUrl(orgAlias, id, url),
  fetchDataAC: (responseData) => ([
    viewFetchAC(responseData.worksheet, componentName),
    extrasUpdateAC(
      {
        ...pick(responseData, ['accessControl', 'project']),
        nextWorksheetInfo: {
          ...pick(responseData, [
            'allowedBillingPeriodEnd', 'allowedBillingPeriodStart', 'missingPeriods',
          ]),
        },
      },
      componentName,
    ),
  ]),
  fetchDataErrorAC: viewFetchErrorAC,
});

export const fetchFinanceExpenseDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ id, orgAlias }),
  fetchApiUrl: () => financeExpenseViewApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => [
    viewFetchAC(responseData.expense, componentName),
    extrasUpdateAC(pick(responseData, ['accessControl', 'project']), componentName),
  ],
  fetchDataErrorAC: viewFetchErrorAC,
});

export const fetchFinanceInvoiceDS = ({
  orgAlias, id, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ id, orgAlias }),
  fetchApiUrl: () => financeInvoiceViewApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => viewFetchAC(responseData, componentName),
  fetchDataErrorAC: viewFetchErrorAC,
  componentName,
});

export const fetchFinanceProFormaInvoiceDS = ({
  authedAxios = null, componentName, id, orgAlias, url = '',
}) => fetchDataDS({
  authedAxios,
  fetchApiUrl: () => financeProFormaInvoiceViewApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => ([
    viewFetchAC(responseData.serviceOrder, componentName),
    extrasUpdateAC(
      { ...pick(responseData, ['accessControl']) },
      componentName,
    ),
  ]),
  fetchDataErrorAC: viewFetchErrorAC,
  validate: () => _validateParams({ id, orgAlias }),
});

// subscriptions

export const fetchActiveSubscriptionDS = ({
  orgAlias, url = '', componentName, authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  fetchApiUrl: () => financeActiveSubscriptionApiUrl(orgAlias, url),
  fetchDataAC: responseData => ([
    viewFetchAC(responseData.subscription, componentName),
    extrasUpdateAC(
      { ...pick(responseData, ['orgMembersCount']) },
      componentName,
    ),
  ]),
  fetchDataErrorAC: viewFetchErrorAC,
});

export const settleDeferredInvoicesDS = ({ orgAlias }) => (
  dispatch => dispatch(
    pushDataDS({
      reqType: REQ_TYPE.POST,
      pushApiUrl: financeSettleDeferredInvoicesApiUrl(orgAlias),
    }),
  )
);
