import { MIME_TYPES, ORDERING_DIRECTION, TIMELINE_ITEM_TYPES } from 'core/assets/js/constants';

export const PROJECT_STATUS = {
  POSTED: 1,
  INPROGRESS: 10,
  COMPLETED: 20,
};

export const PROJECT_STATUS_LABEL = {
  [PROJECT_STATUS.POSTED]: 'Posted',
  [PROJECT_STATUS.INPROGRESS]: 'In progress',
  [PROJECT_STATUS.COMPLETED]: 'Completed',
};

export const PROJECT_STATUS_CLASS = {
  [PROJECT_STATUS.POSTED]: 'posted',
  [PROJECT_STATUS.INPROGRESS]: 'in-progress',
  [PROJECT_STATUS.COMPLETED]: 'completed',
};

export const PROJECT_STATUS_OPTIONS = Object.keys(PROJECT_STATUS).map(status => ({
  text: PROJECT_STATUS_LABEL[PROJECT_STATUS[status]],
  value: PROJECT_STATUS[status],
}));

export const PROJECT_ACCESS_TYPE = {
  OWNER: 1,
  MEMBER: 2,
  NOT_MEMBER: 3,
  ALL: 4,
};

export const PROJECT_ACCESS_TYPE_LABEL = {
  [PROJECT_ACCESS_TYPE.OWNER]: 'I am the owner',
  [PROJECT_ACCESS_TYPE.MEMBER]: 'I am a member',
  [PROJECT_ACCESS_TYPE.NOT_MEMBER]: 'I am not a member',
  [PROJECT_ACCESS_TYPE.ALL]: 'All',
};

export const PROJECT_VISIBILITY = {
  INVITATION_ONLY: 0,
  ORG_ONLY: 1,
};

export const PROJECT_VISIBILITY_LABEL = {
  [PROJECT_VISIBILITY.INVITATION_ONLY]: 'Private',
  [PROJECT_VISIBILITY.ORG_ONLY]: 'Org only',
};

export const PROJECT_VISIBILITY_SUBLABEL = {
  [PROJECT_VISIBILITY.INVITATION_ONLY]: 'Show the project only to providers who have been invited to it',
  [PROJECT_VISIBILITY.ORG_ONLY]: 'Display the project to the opportunities list so that any provider can apply',
};

export const PROJECT_STATE_CHANGE = {
  START: 1,
  MARK_AS_COMPLETED: 10,
  REOPEN: 20,
};

export const PROJECT_MEMBER_STATUS = {
  INVITED: 0,
  ACCEPTED: 10, // Invitation accepted by the provider
  REJECTED: 20, // Invitation rejected by the provider
  REMOVED: 30, // User removed from project
  CANCELLED: 40, // Invitation cancelled by the manager
  LEFT: 50, // User left the project
};

export const PROJECT_MEMBER_STATUS_CLASS = {
  [PROJECT_MEMBER_STATUS.INVITED]: 'pending',
  [PROJECT_MEMBER_STATUS.ACCEPTED]: 'accepted',
  [PROJECT_MEMBER_STATUS.REJECTED]: 'rejected',
  [PROJECT_MEMBER_STATUS.REMOVED]: 'removed',
  [PROJECT_MEMBER_STATUS.CANCELLED]: 'cancelled',
  [PROJECT_MEMBER_STATUS.LEFT]: 'removed',
};

export const PROJECT_MEMBER_STATUS_LABEL = {
  [PROJECT_MEMBER_STATUS.INVITED]: 'Invited',
  [PROJECT_MEMBER_STATUS.ACCEPTED]: 'Accepted',
  [PROJECT_MEMBER_STATUS.REJECTED]: 'Rejected',
  [PROJECT_MEMBER_STATUS.REMOVED]: 'Removed',
  [PROJECT_MEMBER_STATUS.CANCELLED]: 'Cancelled',
  [PROJECT_MEMBER_STATUS.LEFT]: 'Left',
};

export const PROJECT_APPLICATION_STATUS = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  CANCELLED: 3,
};

export const PROJECT_APPLICATION_STATUS_LABEL = {
  [PROJECT_APPLICATION_STATUS.PENDING]: 'Applied',
  [PROJECT_APPLICATION_STATUS.ACCEPTED]: 'Accepted',
  [PROJECT_APPLICATION_STATUS.REJECTED]: 'Rejected',
  [PROJECT_APPLICATION_STATUS.CANCELLED]: 'Cancelled',
};

export const PROJECT_APPLICATION_STATUS_CLASS = {
  [PROJECT_APPLICATION_STATUS.PENDING]: 'pending',
  [PROJECT_APPLICATION_STATUS.ACCEPTED]: 'accepted',
  [PROJECT_APPLICATION_STATUS.REJECTED]: 'rejected',
  [PROJECT_APPLICATION_STATUS.CANCELLED]: 'cancelled',
};

export const PURCHASE_ORDER_STATUS = {
  PENDING: 0,
  APPROVED: 10,
  REJECTED: 20,
  CANCELLED: 30,
};

export const PURCHASE_ORDER_STATUS_LABEL = {
  [PURCHASE_ORDER_STATUS.PENDING]: 'Pending',
  [PURCHASE_ORDER_STATUS.APPROVED]: 'Approved',
  [PURCHASE_ORDER_STATUS.REJECTED]: 'Rejected',
  [PURCHASE_ORDER_STATUS.CANCELLED]: 'Cancelled',
};

export const PURCHASE_ORDER_STATUS_CLASS = {
  [PURCHASE_ORDER_STATUS.PENDING]: 'pending',
  [PURCHASE_ORDER_STATUS.APPROVED]: 'approved',
  [PURCHASE_ORDER_STATUS.REJECTED]: 'rejected',
  [PURCHASE_ORDER_STATUS.CANCELLED]: 'cancelled',
};

export const PURCHASE_ORDER_STATUS_OPTIONS = Object.keys(PURCHASE_ORDER_STATUS).map(status => ({
  text: PURCHASE_ORDER_STATUS_LABEL[PURCHASE_ORDER_STATUS[status]],
  value: PURCHASE_ORDER_STATUS[status],
}));

export const DEFAULT_DAYS_AFTER_WORKSHEETS_AUTO_APPROVAL = 3;

export const SERVICE_ORDER_TYPE = {
  WORKSHEET: 1,
  EXPENSE: 2,
  PROFORMA_INVOICE: 3,
};

export const SERVICE_ORDER_TYPE_LABEL = {
  [SERVICE_ORDER_TYPE.WORKSHEET]: 'Worksheet',
  [SERVICE_ORDER_TYPE.EXPENSE]: 'Expense',
  [SERVICE_ORDER_TYPE.PROFORMA_INVOICE]: 'Proforma Invoice',
};

export const SERVICE_ORDER_TYPE_ABBREVIATION = {
  [SERVICE_ORDER_TYPE.WORKSHEET]: 'WS',
  [SERVICE_ORDER_TYPE.EXPENSE]: 'XP',
  [SERVICE_ORDER_TYPE.PROFORMA_INVOICE]: 'PI',
};

export const SERVICE_ORDER_STATUS = {
  RAISED: 1,
  CONFIRMED: 2,
  APPROVED: 10,
  REJECTED: 20,
  UNRESOLVED: 30,
  NOT_ENOUGH_FUNDS: 40,
  CANCELLED: 50,
  VOID: 60,
  AMENDMENT_REQUESTED: 70,
};

export const SERVICE_ORDER_STATUS_LABEL = {
  [SERVICE_ORDER_STATUS.RAISED]: 'Submitted',
  [SERVICE_ORDER_STATUS.CONFIRMED]: 'Confirmed',
  [SERVICE_ORDER_STATUS.APPROVED]: 'Approved',
  [SERVICE_ORDER_STATUS.REJECTED]: 'Rejected',
  [SERVICE_ORDER_STATUS.UNRESOLVED]: 'Unresolved',
  [SERVICE_ORDER_STATUS.NOT_ENOUGH_FUNDS]: 'Budget exceeded',
  [SERVICE_ORDER_STATUS.CANCELLED]: 'Cancelled',
  [SERVICE_ORDER_STATUS.VOID]: 'Void',
  [SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED]: 'Amendment requested',
};

export const SERVICE_ORDER_STATUS_CLASS = {
  [SERVICE_ORDER_STATUS.RAISED]: 'raised',
  [SERVICE_ORDER_STATUS.CONFIRMED]: 'pending',
  [SERVICE_ORDER_STATUS.APPROVED]: 'approved',
  [SERVICE_ORDER_STATUS.REJECTED]: 'rejected',
  [SERVICE_ORDER_STATUS.UNRESOLVED]: 'unresolved',
  [SERVICE_ORDER_STATUS.NOT_ENOUGH_FUNDS]: 'pending',
  [SERVICE_ORDER_STATUS.CANCELLED]: 'cancelled',
  [SERVICE_ORDER_STATUS.VOID]: 'void',
  [SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED]: 'amendment-requested',
};

export const SERVICE_ORDER_INVOICED = 'invoiced';
export const SERVICE_ORDER_PAID = 'paid';

export const SERVICE_ORDER_RESUBMITTED = 'resubmitted';
export const SERVICE_ORDER_RESUBMITTED_LABEL = 'Re-submitted';

export const SERVICE_ORDER_RAISED_REASON = {
  MANUALLY: 'manually',
  TIME_TRACKER: 'time-tracker',
  BULK_IMPORT_ORG: 'bulk-import-org',
  BULK_IMPORT_PROJECT: 'bulk-import-project',
  RAISED_BY_TASK_MANAGER: 'raised-by-task-manager',
};

export const SERVICE_ORDER_RAISED_REASON_VALUES = Object.values(SERVICE_ORDER_RAISED_REASON);

export const SERVICE_ORDER_TIMELINE_ITEM_VARIANT = {
  [SERVICE_ORDER_STATUS.RAISED]: TIMELINE_ITEM_TYPES.DEFAULT,
  [SERVICE_ORDER_STATUS.CONFIRMED]: TIMELINE_ITEM_TYPES.PENDING,
  [SERVICE_ORDER_STATUS.REJECTED]: TIMELINE_ITEM_TYPES.REJECTED,
  [SERVICE_ORDER_STATUS.CANCELLED]: TIMELINE_ITEM_TYPES.EMPHASIZE,
  [SERVICE_ORDER_STATUS.APPROVED]: TIMELINE_ITEM_TYPES.APPROVED,
};

export const SERVICE_ORDER_PROCESSED = {
  NO: 0,
  YES: 1,
};

export const SERVICE_ORDER_PROCESSED_LABEL = {
  [SERVICE_ORDER_PROCESSED.NO]: 'No',
  [SERVICE_ORDER_PROCESSED.YES]: 'Yes',
};

export const SERVICE_ORDER_PROCESSED_OPTIONS = Object.keys(SERVICE_ORDER_PROCESSED).map(status => ({
  text: SERVICE_ORDER_PROCESSED_LABEL[SERVICE_ORDER_PROCESSED[status]],
  value: SERVICE_ORDER_PROCESSED[status],
}));

export const SERVICE_ORDER_ITEM_TYPE = {
  BASIC: 1,
  PROJECT: 2,
  TASK: 3,
};

export const SERVICE_ORDER_ITEM_TYPE_LABEL = {
  [SERVICE_ORDER_ITEM_TYPE.BASIC]: 'Work',
  [SERVICE_ORDER_ITEM_TYPE.PROJECT]: 'Project work',
  [SERVICE_ORDER_ITEM_TYPE.TASK]: 'Task',
};

export const EXPENSE_STATUS = { ...SERVICE_ORDER_STATUS };
export const EXPENSE_STATUS_LABEL = { ...SERVICE_ORDER_STATUS_LABEL };
export const EXPENSE_STATUS_CLASS = { ...SERVICE_ORDER_STATUS_CLASS };

export const WORKSHEET_STATUS = { ...SERVICE_ORDER_STATUS };
export const WORKSHEET_STATUS_LABEL = { ...SERVICE_ORDER_STATUS_LABEL };
export const WORKSHEET_STATUS_CLASS = { ...SERVICE_ORDER_STATUS_CLASS };

export const WORKSHEET_TIMELINE_ITEM_VARIANT = {
  [WORKSHEET_STATUS.RAISED]: TIMELINE_ITEM_TYPES.DEFAULT,
  [WORKSHEET_STATUS.CONFIRMED]: TIMELINE_ITEM_TYPES.PENDING,
  [WORKSHEET_STATUS.REJECTED]: TIMELINE_ITEM_TYPES.REJECTED,
  [WORKSHEET_STATUS.CANCELLED]: TIMELINE_ITEM_TYPES.EMPHASIZE,
  [WORKSHEET_STATUS.APPROVED]: TIMELINE_ITEM_TYPES.APPROVED,
};

export const EXPENSE_TIMELINE_ITEM_VARIANT = {
  [EXPENSE_STATUS.RAISED]: TIMELINE_ITEM_TYPES.DEFAULT,
  [EXPENSE_STATUS.CONFIRMED]: TIMELINE_ITEM_TYPES.PENDING,
  [EXPENSE_STATUS.REJECTED]: TIMELINE_ITEM_TYPES.REJECTED,
  [EXPENSE_STATUS.CANCELLED]: TIMELINE_ITEM_TYPES.EMPHASIZE,
  [EXPENSE_STATUS.APPROVED]: TIMELINE_ITEM_TYPES.APPROVED,
};

export const SERVICE_ORDER_EDITABLE_STATUSES = [
  SERVICE_ORDER_STATUS.RAISED,
  SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED,
];

export const WORKSHEET_TASK_CAP_APPLYING_STATUSES = [
  WORKSHEET_STATUS.RAISED,
  WORKSHEET_STATUS.CONFIRMED,
  WORKSHEET_STATUS.APPROVED,
];

export const SERVICE_ORDER_PENDING_STATUSES = [
  EXPENSE_STATUS.RAISED,
  EXPENSE_STATUS.CONFIRMED,
  EXPENSE_STATUS.NOT_ENOUGH_FUNDS,
  EXPENSE_STATUS.AMENDMENT_REQUESTED,
];

export const WORKSHEET_STATUS_GROUP = {
  // status group for anything considered pending, as in "not finalized yet", that is
  // WORKSHEET_STATUS.RAISED|WORKSHEET_STATUS.CONFIRMED|WORKSHEETS_STATUS.NOT_ENOUGH_FUNDS
  PENDING: 1,
  // status group for anything considered approved,
  APPROVED: 10,
};

export const PROJECT_VIEW_ANCHOR = {
  WORKSHEETS: 'worksheets',
  EXPENSES: 'expenses',
};

export const TASK_STATUS = {
  DRAFT: 0,
  PENDING: 1,
  INPROGRESS: 2,
  COMPLETED: 3,
  STOPPED: 4,
  REJECTED: 5,
};

export const TASK_ASSIGNMENT_STATUS = {
  PENDING: 0,
  ACCEPTED: 1,
  DECLINED: 2,
  REMOVED: 3,
  CANCELLED: 4,
  LEFT: 5,
  COMPLETED: 6,
  PENDING_RATE_AGREEMENT: 7,
};

export const TASK_WORKSHEET_DISABLE_REASON = {
  INVALID_STATUS: 1,
  NOT_ASSIGNED: 2,
  WORKSHEET_PRESENT: 3,
  FIXED_RATE_INCOMPLETE: 4,
  PENDING_RATE_AGREEMENT: 5,
};

export const TASK_WORKSHEET_DISABLE_REASON_LABEL = {
  [TASK_WORKSHEET_DISABLE_REASON.INVALID_STATUS]: 'The task is not completed, nor in progress',
  [TASK_WORKSHEET_DISABLE_REASON.NOT_ASSIGNED]: 'You are not assigned to this task',
  [TASK_WORKSHEET_DISABLE_REASON.WORKSHEET_PRESENT]: 'There is already a worksheet submitted for this task',
  [TASK_WORKSHEET_DISABLE_REASON.FIXED_RATE_INCOMPLETE]: 'In order to submit a worksheet against a fixed-budget task, you have to either request its task completion, or the manager has to mark it as completed',
  [TASK_WORKSHEET_DISABLE_REASON.PENDING_RATE_AGREEMENT]: 'Please respond to the requested rate adjustment by the Manager in order to submit the worksheet for the task first before raising the worksheet for the task.',
};

export const TASK_STATUS_LABEL = {
  [TASK_STATUS.DRAFT]: 'Draft',
  [TASK_STATUS.PENDING]: 'Pending',
  [TASK_STATUS.INPROGRESS]: 'In progress',
  [TASK_STATUS.COMPLETED]: 'Completed',
  [TASK_STATUS.STOPPED]: 'Stopped',
  [TASK_STATUS.REJECTED]: 'Rejected',
};

export const TASK_STATUS_CLASS = {
  [TASK_STATUS.DRAFT]: 'draft',
  [TASK_STATUS.PENDING]: 'pending',
  [TASK_STATUS.INPROGRESS]: 'in-progress',
  [TASK_STATUS.COMPLETED]: 'completed',
  [TASK_STATUS.STOPPED]: 'stopped',
  [TASK_STATUS.REJECTED]: 'rejected',
};

export const TASK_ASSIGNMENT_STATUS_CLASS = {
  [TASK_ASSIGNMENT_STATUS.PENDING]: 'pending',
  [TASK_ASSIGNMENT_STATUS.ACCEPTED]: 'in-progress',
  [TASK_ASSIGNMENT_STATUS.DECLINED]: 'rejected',
  [TASK_ASSIGNMENT_STATUS.REMOVED]: 'rejected',
  [TASK_ASSIGNMENT_STATUS.CANCELLED]: 'cancelled',
  [TASK_ASSIGNMENT_STATUS.LEFT]: 'rejected',
  [TASK_ASSIGNMENT_STATUS.COMPLETED]: 'completed',
  [TASK_ASSIGNMENT_STATUS.PENDING_RATE_AGREEMENT]: 'pending-rate-agreement',
};

export const TASK_ASSIGNMENT_STATUS_LABEL = {
  [TASK_ASSIGNMENT_STATUS.PENDING]: 'Pending invitation response',
  [TASK_ASSIGNMENT_STATUS.ACCEPTED]: 'In progress',
  [TASK_ASSIGNMENT_STATUS.DECLINED]: 'Declined',
  [TASK_ASSIGNMENT_STATUS.REMOVED]: 'Removed',
  [TASK_ASSIGNMENT_STATUS.CANCELLED]: 'Cancelled',
  [TASK_ASSIGNMENT_STATUS.LEFT]: 'Left',
  [TASK_ASSIGNMENT_STATUS.COMPLETED]: 'Completed',
  [TASK_ASSIGNMENT_STATUS.PENDING_RATE_AGREEMENT]: 'Pending rate agreement',
};

export const TASK_STATE = {
  POSTED: 'posted',
  ASSIGNED: 'assigned',
  COMPLETED: 'completed',
  BLOCKED: 'blocked',
};

export const TASK_ACTION = {
  // assignment
  ASSIGN: 'assign',
  ACCEPT_ASSIGNMENT_INVITATION: 'accept_assignment_invitation',
  REJECT_ASSIGNMENT_INVITATION: 'reject_assignment_invitation',
  CANCEL_ASSIGNMENT_INVITATION: 'cancel_assignment_invitation',
  MANAGE_ASSIGNMENT_INVITATION: 'manage_assignment_invitation',
  // rate suggestion
  PROPOSE_TASK_RATE: 'propose_task_rate',
  ACCEPT_RATE_INVITATION: 'accept_rate_invitation',
  REJECT_RATE_INVITATION: 'reject_rate_invitation',
  CANCEL_RATE_INVITATION: 'cancel_rate_invitation',
  // request completion (as the task assignee)
  REQUEST_COMPLETION: 'request_completion',
  ACCEPT_COMPLETION_INVITATION: 'accept_completion_invitation',
  REJECT_COMPLETION_INVITATION: 'reject_completion_invitation',
  CANCEL_COMPLETION_INVITATION: 'cancel_completion_invitation',
  // completion (as the task owner)
  COMPLETE: 'complete',
  COMPLETE_ASSIGNMENT: 'complete_assignment',
  STOP: 'stop',
  REVIEW: 'review',
  REOPEN: 'reopen',
  LEAVE: 'leave',
  // messaging actions
  MESSAGE: 'message',
  // start project
  START_PROJECT: 'start_project',
  // Blocked discussion board for assignees that are not in the appropriate assignment status
  INFORM_PUBLIC_DISCUSSION_BLOCKED: 'public_discussion_blocked',
  // Blocked actions for managers that are interacting with the task
  INFORM_PENDING_ASSIGNMENT: 'pending_task_assignment',
  INFORM_PENDING_RATE_INVITATION_FOR_OWNER: 'pending_rate_invitation',
  INFORM_PENDING_ASSINGMENT_INVITATION_FOR_OWNER: 'pending_assignment_invitation',
  INFORM_PENDING_COMPLETION_INVITATION_FOR_OWNER: 'pending_completion_invitation',
};

export const TASK_ACTION_LABEL = {
  // assignment
  [TASK_ACTION.ASSIGN]: 'Assign the task',
  [TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION]: 'Accept the task',
  [TASK_ACTION.REJECT_ASSIGNMENT_INVITATION]: 'Reject task',
  [TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION]: 'Cancel invitation',
  [TASK_ACTION.MANAGE_ASSIGNMENT_INVITATION]: 'Review invitation',
  // rate suggestion
  [TASK_ACTION.PROPOSE_TASK_RATE]: 'Suggest new rate',
  [TASK_ACTION.ACCEPT_RATE_INVITATION]: 'Accept new rate',
  [TASK_ACTION.REJECT_RATE_INVITATION]: 'Reject new rate',
  [TASK_ACTION.CANCEL_RATE_INVITATION]: 'Cancel rate amendment',
  // request completion (as the task assignee)
  [TASK_ACTION.REQUEST_COMPLETION]: 'Complete Task',
  [TASK_ACTION.ACCEPT_COMPLETION_INVITATION]: 'Accept completion',
  [TASK_ACTION.REJECT_COMPLETION_INVITATION]: 'Reject completion',
  [TASK_ACTION.CANCEL_COMPLETION_INVITATION]: 'Cancel completion',
  // completion (as the task owner)
  [TASK_ACTION.COMPLETE_ASSIGNMENT]: 'Complete assignment',
  [TASK_ACTION.COMPLETE]: 'Complete task',
  [TASK_ACTION.REVIEW]: 'Review',
  [TASK_ACTION.LEAVE]: 'Leave task',
  [TASK_ACTION.REOPEN]: 'Undo',
  [TASK_ACTION.STOP]: 'Stop task',
  // messaging actions
  [TASK_ACTION.MESSAGE]: 'Send',
};

// Task actions that require the user's attention
export const BLOCKING_TASK_ACTIONS = [
  TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION,
  TASK_ACTION.REJECT_ASSIGNMENT_INVITATION,
  TASK_ACTION.MANAGE_ASSIGNMENT_INVITATION,
  TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION,
  TASK_ACTION.ACCEPT_RATE_INVITATION,
  TASK_ACTION.REJECT_RATE_INVITATION,
  TASK_ACTION.CANCEL_RATE_INVITATION,
  TASK_ACTION.ACCEPT_COMPLETION_INVITATION,
  TASK_ACTION.REJECT_COMPLETION_INVITATION,
  TASK_ACTION.CANCEL_COMPLETION_INVITATION,
];

// The statuses for the task assignment that are open to other assignees
export const ASSIGNEE_VIEWABLE_STATUS = [
  TASK_ASSIGNMENT_STATUS.PENDING,
  TASK_ASSIGNMENT_STATUS.ACCEPTED,
  TASK_ASSIGNMENT_STATUS.COMPLETED,
];

// Task actions that should be marked as outstanding in the discussion board
export const INDICATABLE_TASK_ACTIONS = [
  ...BLOCKING_TASK_ACTIONS,
  TASK_ACTION.START_PROJECT,
  TASK_ACTION.REVIEW,
];

export const TASKS_DEFAULT_ORDERING = {
  direction: ORDERING_DIRECTION.DESC,
  sortBy: 'created_at',
};

export const TASK_STATUS_DEFAULT_FILTER = [
  TASK_STATUS.DRAFT,
  TASK_STATUS.PENDING,
  TASK_STATUS.INPROGRESS,
  TASK_STATUS.COMPLETED,
  TASK_STATUS.STOPPED,
  TASK_STATUS.REJECTED,
];

export const TASK_ASSIGNMENT_STATUS_DEFAULT_FILTER = [
  TASK_ASSIGNMENT_STATUS.PENDING,
  TASK_ASSIGNMENT_STATUS.ACCEPTED,
  TASK_ASSIGNMENT_STATUS.PENDING_RATE_AGREEMENT,
];

export const TASK_CANNOT_EDIT_REASON = {
  NOT_PROJECT_MEMBER_OR_HIGHER_MANAGER: 'not-project-member-or-higher-manager',
  NOT_OWNER_MANAGER_ORG_OWNER: 'not-owner-manager-org-owner',
  NO_TASK: 'no-task',
  PROJECT_COMPLETED: 'project-completed',
  TASK_COMPLETED: 'task-completed',
  TASK_STOPPED: 'task-stopped',
  TASK_ARCHIVED: 'task-archived',
};

export const TASK_CANNOT_EDIT_REASON_LABEL = {
  [TASK_CANNOT_EDIT_REASON.NOT_PROJECT_MEMBER_OR_HIGHER_MANAGER]: 'You are not a project member or higher manager',
  [TASK_CANNOT_EDIT_REASON.NOT_OWNER_MANAGER_ORG_OWNER]: 'You are not the task owner, a manager or the organization owner',
  [TASK_CANNOT_EDIT_REASON.NO_TASK]: 'That task cannot be found',
  [TASK_CANNOT_EDIT_REASON.PROJECT_COMPLETED]: 'The project is completed',
  [TASK_CANNOT_EDIT_REASON.TASK_COMPLETED]: 'The task is completed',
  [TASK_CANNOT_EDIT_REASON.TASK_STOPPED]: 'The task is stopped',
  [TASK_CANNOT_EDIT_REASON.TASK_ARCHIVED]: 'The task is archived',
};

export const TASK_CANNOT_PROPOSE_NEW_TASK_RATE_REASON = {
  NOT_ACCEPTED: 'not-accepted',
  HAS_COMPLETION_REQUEST: 'has-completion-request',
  NOT_TASK_OWNER_OR_MANAGER: 'not-task-owner-or-manager',
  DO_NOT_HAVE_PERMISSION: 'do-not-have-permission',
};

export const TASK_CANNOT_PROPOSE_NEW_TASK_RATE_LABEL = {
  [TASK_CANNOT_PROPOSE_NEW_TASK_RATE_REASON.NOT_ACCEPTED]: "The provider has not accepted the task yet, rates can't be adjusted until they do.",
  [TASK_CANNOT_PROPOSE_NEW_TASK_RATE_REASON.HAS_COMPLETION_REQUEST]: "The provider has requested that their task assignment is completed, rates can't be adjusted any more.",
  [TASK_CANNOT_PROPOSE_NEW_TASK_RATE_REASON.NOT_TASK_OWNER_OR_MANAGER]: 'You are not the task owner or a task manager. You cannot adjust rates.',
  [TASK_CANNOT_PROPOSE_NEW_TASK_RATE_REASON.DO_NOT_HAVE_PERMISSION]: 'You do not have permission to adjust task assignment rates',
};

export const PROJECT_TAB_VIEW_TYPE = {
  LIST: 1,
  CHARTS: 2,
};

export const PROJECTS_DEFAULT_ORDERING = {
  direction: ORDERING_DIRECTION.DESC,
  sortBy: 'created_at',
};

export const PROJECT_MEMBER_MANAGEMENT = {
  ALL_MANAGERS: 0,
  PROJECT_OWNER_ONLY: 1,
};

export const PROJECT_MEMBER_MANAGEMENT_LABEL = {
  [PROJECT_MEMBER_MANAGEMENT.ALL_MANAGERS]: 'All managers',
  [PROJECT_MEMBER_MANAGEMENT.PROJECT_OWNER_ONLY]: 'The project Owner',
};

export const PROJECT_MEMBER_MANAGEMENT_SUBLABEL = {
  [PROJECT_MEMBER_MANAGEMENT.ALL_MANAGERS]: 'All managers can remove and add members to the project',
  [PROJECT_MEMBER_MANAGEMENT.PROJECT_OWNER_ONLY]: 'Only the project owner can remove and add members to the project',
};

export const PROJECT_MEMBER_VIEW = {
  ALL_MEMBERS: 0,
  MANAGERS_ONLY: 1,
};

export const PROJECT_MEMBER_VIEW_LABEL = {
  [PROJECT_MEMBER_VIEW.ALL_MEMBERS]: 'All members',
  [PROJECT_MEMBER_VIEW.MANAGERS_ONLY]: 'Managers only',
};

export const PROJECT_MEMBER_VIEW_SUBLABEL = {
  [PROJECT_MEMBER_VIEW.ALL_MEMBERS]: 'Both managers and providers can view the project team members',
  [PROJECT_MEMBER_VIEW.MANAGERS_ONLY]: 'Managers only can view the project team members',
};

export const PROJECT_DOCUMENTS_ALLOWED_MIMETYPES = [
  ...MIME_TYPES.AUDIO,
  ...MIME_TYPES.COMPRESSED,
  ...MIME_TYPES.DOCUMENTS,
  ...MIME_TYPES.IMAGES,
  ...MIME_TYPES.VIDEOS,
];

export const WORKSHEET_CAN_FUND_REASON = {
  INVALID_STATUS: 1,
  PROJECT_HAS_NO_BUDGET: 2,
  PROJECT_HAS_NOT_ENOUGH_BUDGET: 3,
  USER_INSUFFICIENT_PERMISSION: 4,
  PENDING_TASK_ASSIGNMENT_RATE_AGREEMENT: 5,
};

export const WORKSHEET_CAN_FUND_REASON_LABEL = {
  [WORKSHEET_CAN_FUND_REASON.INVALID_STATUS]: 'The worksheet is not raised, confirmed or with an amendment requested',
  [WORKSHEET_CAN_FUND_REASON.PROJECT_HAS_NO_BUDGET]: 'The project has no budget',
  [WORKSHEET_CAN_FUND_REASON.PROJECT_HAS_NOT_ENOUGH_BUDGET]: 'The project does not have enough budget for this worksheet',
  [WORKSHEET_CAN_FUND_REASON.USER_INSUFFICIENT_PERMISSION]: 'You don’t have the sufficient permission to fund the project',
  [WORKSHEET_CAN_FUND_REASON.PENDING_TASK_ASSIGNMENT_RATE_AGREEMENT]: 'Please respond to the requested rate adjustment by the Manager in order to submit the worksheet for the task first before raising the worksheet for the task. ',
};

// this is expected to be a float percentage e.g. +1% would be 0.01
export const DEFAULT_FX_RATE_SPREAD = 0.01;

export const PROJECT_MEMBER_ADDED_VIA = {
  DIRECTLY: 1,
  INVITATION: 2,
};

export const PROJECT_WORKSHEET_IMPORT_STEP = {
  DOWNLOAD: 'download',
  UPLOAD: 'upload',
  MAP_FIELDS: 'map_fields',
  PREVIEW: 'preview',
  DONE: 'done',
};

export const PROJECT_WORKSHEET_IMPORT_STEP_PAGE = {
  [PROJECT_WORKSHEET_IMPORT_STEP.DOWNLOAD]: 0,
  [PROJECT_WORKSHEET_IMPORT_STEP.UPLOAD]: 1,
  [PROJECT_WORKSHEET_IMPORT_STEP.MAP_FIELDS]: 2,
  [PROJECT_WORKSHEET_IMPORT_STEP.PREVIEW]: 3,
  [PROJECT_WORKSHEET_IMPORT_STEP.DONE]: 4,
};

export const WORKSHEET_IMPORT_LIMIT = 100;

export const WORKSHEET_IMPORT_MAX_ITEM_QUANTITY = 1;

export const WORKSHEET_IMPORT_SCHEME_KEYS = {
  USER_ID: 'userId',
  USER: 'user',
  CLAIMING_AMOUNT: 'claimingAmount',
  DESCRIPTION: 'description',
  CURRENCY: 'currency',
  SUMMARY: 'summary',
  PERIOD_START: 'periodStart',
  PERIOD_END: 'periodEnd',
};

export const WORKSHEET_IMPORT_SCHEME = {
  [WORKSHEET_IMPORT_SCHEME_KEYS.USER_ID]: 'User ID',
  [WORKSHEET_IMPORT_SCHEME_KEYS.USER]: 'User',
  [WORKSHEET_IMPORT_SCHEME_KEYS.CLAIMING_AMOUNT]: 'Claiming Amount',
  [WORKSHEET_IMPORT_SCHEME_KEYS.DESCRIPTION]: 'Description',
  [WORKSHEET_IMPORT_SCHEME_KEYS.CURRENCY]: 'Currency',
  [WORKSHEET_IMPORT_SCHEME_KEYS.SUMMARY]: 'Summary',
  [WORKSHEET_IMPORT_SCHEME_KEYS.PERIOD_START]: 'Period Start',
  [WORKSHEET_IMPORT_SCHEME_KEYS.PERIOD_END]: 'Period End',
};

export const PROJECT_COMPLETION_FUNDS = {
  LEAVE_ON_PROJECT: 'leave-on-project',
  RETURN_TO_ORG_OWNER: 'return-org-owner',
  RETURN_TO_PROJECT_OWNER: 'return-project-owner',
};

export const PROJECT_COMPLETION_FUNDS_LABEL = {
  [PROJECT_COMPLETION_FUNDS.LEAVE_ON_PROJECT]: 'Leave it on the project',
  [PROJECT_COMPLETION_FUNDS.RETURN_TO_ORG_OWNER]: 'Return it to the Organization Owner',
  [PROJECT_COMPLETION_FUNDS.RETURN_TO_PROJECT_OWNER]: 'Return it to the Project Owner',
};

export const PROJECT_TASKS_STATUS_CHANGE = {
  ALL_COMPLETED: 'all-completed',
  ALL_STOPPED: 'all-stopped',
  ALL_ARCHIVED: 'all-archived',
};

export const PROJECT_TASKS_STATUS_CHANGE_LABEL = {
  [PROJECT_TASKS_STATUS_CHANGE.ALL_COMPLETED]: 'Complete all tasks',
  [PROJECT_TASKS_STATUS_CHANGE.ALL_STOPPED]: 'Stop all tasks',
  [PROJECT_TASKS_STATUS_CHANGE.ALL_ARCHIVED]: 'Archive all tasks',
};

export const WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE = {
  ONE_PER_PROJECT: 'one-per-project',
  ONE_PER_PROJECT_PER_DAY: 'one-per-project-per-day',
  ONE_PER_WORK_TIME_BLOCK: 'one-per-work-time-block',
};

export const WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_VALUES = Object.values(
  WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE,
);

export const WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE_LABEL = {
  [WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE.ONE_PER_PROJECT]: 'One worksheet per project',
  [WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE.ONE_PER_PROJECT_PER_DAY]: 'One worksheet per project, per calendar day',
  [WORK_TIME_BLOCK_WORKSHEET_CREATION_MODE.ONE_PER_WORK_TIME_BLOCK]: 'One worksheet per tracked time',
};

export const TIME_TRACKER_TABS = {
  TIME_ENTRIES: 'time-entries',
  ARCHIVE: 'archive',
};

export const INVOICE_CAP_USAGE_BAR_COMPONENT_NAME = 'InvoiceCapUsageBar';

export const INVOICE_CAP_NOT_ENOUGH_BUDGET_MESSAGE = [
  'This worksheet was due to be auto-approved as it was within the Invoice Cap ',
  'allowance, however, the amount exceeded the available project budget which ',
  'prevented the approval. Once more budget has been given to the project this ',
  'worksheet will need to be manually approved.',
].join('');

export const INVOICE_CAP_NOT_APPROVED_REASON = {
  EXCEEDS_CAP: 'exceeds-cap',
  OUTSIDE_CAP_PERIOD: 'outside-cap-period',
  EXCEEDS_CAP_AND_OUTSIDE_CAP_PERIOD: 'exceeds-cap-and-outside-cap-period',
  EXCEEDS_PROJECT_BUDGET: 'exceeds-budget',
  NOT_ALLOWED_FOR_NON_TIME_TRACKER_WORKSHEETS: 'not-allowed',
};

export const INVOICE_CAP_NOT_APPROVED_REASON_VALUES = Object.values(
  INVOICE_CAP_NOT_APPROVED_REASON,
);

export const TASK_PENDING_APPROVAL = 'pending-approval';

export const SERVICE_ORDER_MAX_AGE_DAYS = {
  NONE: 'none',
  90: 90,
  60: 60,
  30: 30,
};

export const SERVICE_ORDER_MAX_AGE_DAYS_LABEL = {
  [SERVICE_ORDER_MAX_AGE_DAYS.NONE]: 'No restriction',
  [SERVICE_ORDER_MAX_AGE_DAYS['90']]: '90 days',
  [SERVICE_ORDER_MAX_AGE_DAYS['60']]: '60 days',
  [SERVICE_ORDER_MAX_AGE_DAYS['30']]: '30 days',
};

export const SERVICE_ORDER_MAX_AGE_DAYS_VALUES = Object.values(SERVICE_ORDER_MAX_AGE_DAYS);

export const OPPORTUNITIES_TABS = {
  OPEN: 'open',
  APPLICATIONS: 'applications',
};

export const SERVICE_ORDER_CANNOT_EDIT_REASON = {
  IS_NOT_RAISED_OR_AMENDMENT_REQUESTED: 'is-not-raised-or-amendment-requested',
  NOT_OWNER: 'not-owner',
  IS_TIME_TRACKER_WORKSHEET: 'is-time-tracker-worksheet',
};

export const RATE_GUIDANCE_TYPE = {
  RANGE: 'range',
  FIXED: 'fixed',
};

export const PROJECT_OPPORTUNITY_INVITEES_STATUS = {
  PENDING: 'pending',
  APPLIED: 'applied',
  CANCELLED: 'cancelled',
};

export const PROJECT_OPPORTUNITY_INVITEES_STATUS_VALUES = Object.values(
  PROJECT_OPPORTUNITY_INVITEES_STATUS,
);

export const PROJECT_OPPORTUNITY_INVITEES_STATUS_LABEL = {
  [PROJECT_OPPORTUNITY_INVITEES_STATUS.PENDING]: 'Pending',
  [PROJECT_OPPORTUNITY_INVITEES_STATUS.APPLIED]: 'Applied',
  [PROJECT_OPPORTUNITY_INVITEES_STATUS.CANCELLED]: 'Cancelled',
};

export const PROJECT_OPPORTUNITY_INVITEES_STATUS_CLASS = {
  [PROJECT_OPPORTUNITY_INVITEES_STATUS.PENDING]: 'pending',
  [PROJECT_OPPORTUNITY_INVITEES_STATUS.APPLIED]: 'approved',
  [PROJECT_OPPORTUNITY_INVITEES_STATUS.CANCELLED]: 'rejected',
};

export const INVITATIONS_LIST_TABS = {
  TASKS: 'tasks',
  PROJECTS: 'projects',
  OPPORTUNITIES: 'opportunities',
};

export const PROJECT_TAB_INVITEES_COMPONENT_NAME = 'project-tab-invitees';
