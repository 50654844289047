/* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderModalManagerCheckbox", "renderModalManager"] }] */ // eslint-disable-line
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { PROJECT_TABS } from 'core/assets/js/constants';
import { modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { formatDate } from 'core/assets/js/lib/utils';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import {
  MODAL_ID as ADD_MEMBERS_TO_PROJECT_MODAL_ID,
} from 'projects/assets/js/components/ProjectAddMembersPanel.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { projectViewUrl } from 'projects/urls';
import { projectChangeOwnerDS } from 'projects/assets/js/data-services/form';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import TransferOwnershipModal from 'core/assets/js/components/TransferOwnershipModal.jsx';
import ProjectManagerCheckableItem from 'projects/assets/js/components/ProjectManagerCheckableItem.jsx';

export const MODAL_ID = 'project-change-ownership';

const ProjectManagerItem = ({ item, project }) => {
  const { profile, id: selectedId } = item;
  const isProjectOwner = +project.owner_id === +selectedId;
  return (
    <ProjectManagerCheckableItem
      label={isProjectOwner && (
        <div className="d-flex flex-column align-items-end">
          <div className="font-weight-bold">Project owner</div>
          <div>
            {'since '}
            <time datatime={project.po_assigned_at}>{formatDate(project.po_assigned_at)}</time>
          </div>
        </div>
      )}
      profile={profile}
    />
  );
};

ProjectManagerItem.propTypes = {
  item: PropTypes.object.isRequired,
  project: projectSpec.isRequired,
};

const ProjectOwnerModal = ({
  dispatch, history, match: { params: { orgAlias } }, managers, project, parentComponentName,
}) => {
  const singleUserWarningMessage = (
    <React.Fragment>
      To transfer ownership, first
      {' '}
      <a
        className="imitate-link"
        onClick={() => {
          history.push(projectViewUrl(orgAlias, project.id, PROJECT_TABS.TEAM));
          dispatch(modalCloseAC(MODAL_ID));
          dispatch(modalOpenAC(
            ADD_MEMBERS_TO_PROJECT_MODAL_ID,
            { orgAlias, projectId: project.id, userType: PEOPLE_TYPE.MANAGERS },
          ));
        }}
      >
        add another manager
      </a>
      {' '}
      into the project.
    </React.Fragment>
  );

  const onOwnershipTransferred = selectedUserId => (
    dispatch(projectChangeOwnerDS({
      orgAlias,
      projectId: project.id,
      values: { newPoId: selectedUserId },
      componentName: parentComponentName,
    })).then(() => {
      toastr.success('Well Done!', 'You’ve got a new project owner!');
    }).catch(() => {
      toastr.error('Oops!', 'Something went wrong, please try again');
    })
  );

  return (
    <TransferOwnershipModal
      modalId={MODAL_ID}
      heading="Transfer project ownership"
      users={managers}
      ownerId={project.owner_id}
      itemComponent={ProjectManagerItem}
      itemComponentProps={{ project }}
      singleUserWarningTitle="It looks like you are the only manager in this project."
      singleUserWarningMessage={singleUserWarningMessage}
      onOwnershipTransferred={onOwnershipTransferred}
    />
  );
};

ProjectOwnerModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  managers: PropTypes.arrayOf(userSpec).isRequired,
  match: routerMatchContentsSpec.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  project: projectSpec.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectOwnerModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectOwnerModal);

export default withRouter(ProjectOwnerModalConnected);
