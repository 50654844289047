/* eslint-disable react/no-multi-comp */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';

import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { ICON } from 'core/assets/js/constants';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';


/**
 * Skill Vote Badge component
 * it's been used for showing count
 * of the votes for endorsed skills
 */
export const SkillVotesBadge = ({ count }) => (
  <span className="ml-2 badge badge-pill">
    {count}
  </span>
);

SkillVotesBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

const SkillTag = ({
  skill, className, isSelected, onClick, onRemove, actions,
  showOrgIndication, isLanguage, showEndorsementIndication, isSelectable,
}) => {
  const {
    label, id, isSystemSkill, orgName, votesCount,
    isManagerEndorsed, copiedByUser, endorserOrganizationName,
  } = skill;

  const isCopiedEndorsedSkill = copiedByUser && isManagerEndorsed;
  const isEndorsedSkill = !copiedByUser && isManagerEndorsed;
  const hasVotes = votesCount > 0;
  const classNames = ['skill-tag'];

  if (className) {
    classNames.push(className);
  }

  if (isSelected) {
    classNames.push('skill-tag--selected');
  }

  if ((onClick || onRemove) && isSelectable) {
    classNames.push('skill-tag--selectable');
  }

  const removeTooltip = (
    <Tooltip
      id={(`remove-tooltip-${id}`)}
    >
      {hasVotes && !isCopiedEndorsedSkill ? 'If you delete this skill your endorsments will be lost and can not be restorted' : ''}
      {isLanguage || !hasVotes || isCopiedEndorsedSkill ? `Remove ${isLanguage ? 'language' : 'skill'}` : ''}
    </Tooltip>
  );

  const lockTooltip = (
    <Tooltip
      id={(`lock-tooltip-${id}`)}
    >
      {`This ${isLanguage ? 'language' : 'skill'} was added by ${endorserOrganizationName} and can not be deleted`}
    </Tooltip>
  );

  const removeOverlay = (
    <OverlayTrigger
      overlay={removeTooltip}
    >
      <button
        data-testid="skilltag-remove-button"
        type="button"
        className="ml-2 skill-tag__remove-button btn btn-link"
        onClick={() => onRemove(skill)}
      >
        <i role="none" className={ICON.CROSS} />
      </button>
    </OverlayTrigger>
  );

  const lockOverlay = (
    <OverlayTrigger
      overlay={lockTooltip}
    >
      <i role="none" className={ICON.LOCK_SOLID} />
    </OverlayTrigger>
  );


  const skillActions = (
    <TDDropButton overlayClassName="td-dropdown--auto" className="ml-3">
      {actions?.map(action => (
        <Dropdown.Item
          key={`skill-${action.label}`}
          onClick={() => action.onClick(skill)}
          eventKey={`skill-${action.label}`}
        >
          {action.label}
        </Dropdown.Item>
      ))}
    </TDDropButton>
  );

  if (!isSystemSkill && showOrgIndication && !onRemove && orgName) {
    return (
      <span
        role="none"
        className={classNames.join(' ')}
        onClick={onClick}
      >
        <TDElementWithTooltip tooltipMsg={`Added by ${orgName}`}>
          <span className="p-2 m-n2">{label}</span>
        </TDElementWithTooltip>
        {hasVotes && (
          <SkillVotesBadge count={votesCount} />
        )}
        {isSelectable && isSelected && (
          <i className={`${ICON.CHECKMARK} ml-2`} />
        )}
        {isSelectable && !isSelected && (
          <i className={`${ICON.ADD} ml-2`} />
        )}
        {isEndorsedSkill && lockOverlay}
        {onRemove && removeOverlay}
        {actions?.length > 0 && skillActions}
      </span>
    );
  }

  if ((isEndorsedSkill || isCopiedEndorsedSkill) && showEndorsementIndication) {
    return (
      <span
        role="none"
        className={classNames.join(' ')}
        onClick={onClick}
      >
        <TDElementWithTooltip tooltipMsg={`Endorsed by ${endorserOrganizationName}`}>
          <span>{label}</span>
        </TDElementWithTooltip>
        {hasVotes && (
          <SkillVotesBadge count={votesCount} />
        )}
        {isSelectable && isSelected && (
          <i className={`${ICON.CHECKMARK} ml-2`} />
        )}
        {isSelectable && !isSelected && (
          <i className={`${ICON.ADD} ml-2`} />
        )}
        {isEndorsedSkill && lockOverlay}
        {onRemove && removeOverlay}
        {actions?.length > 0 && skillActions}
      </span>
    );
  }

  return (
    <span
      role="none"
      className={classNames.join(' ')}
      onClick={onClick}
    >
      {label}
      {hasVotes && (
        <SkillVotesBadge count={votesCount} />
      )}
      {isSelectable && isSelected && (
        <i className={`${ICON.CHECKMARK} ml-2`} />
      )}
      {isSelectable && !isSelected && (
        <i className={`${ICON.ADD} ml-2`} />
      )}
      {onRemove && removeOverlay}
      {isEndorsedSkill && lockOverlay}
      {actions?.length > 0 && skillActions}
    </span>
  );
};

SkillTag.propTypes = {
  isSelected: PropTypes.bool,
  isLanguage: PropTypes.bool,
  showOrgIndication: PropTypes.bool,
  className: PropTypes.string,
  skill: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  actions: PropTypes.array,
  showEndorsementIndication: PropTypes.bool,
  isSelectable: PropTypes.bool,
};

SkillTag.defaultProps = {
  isSelected: false,
  isLanguage: false,
  showOrgIndication: false,
  className: null,
  onRemove: null,
  onClick: null,
  actions: null,
  showEndorsementIndication: false,
  isSelectable: false,
};

export default SkillTag;
