import RateMap, { Conversion } from 'finance/assets/js/lib/RateMap';
import { flatten, fromPairs, pick, uniq } from 'lodash';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';

/**
 * This class is used to serialize/deserialize rate maps using keep arbitrary rate annotations
 *
 * An example would be having a representation like:
 *
 * {
 *   sourceCurrency: 'usd',
 *   targetCurrency: 'gbp',
 *   sourceToTargetRate: 1.1
 *   targetToSourceRate: 0.9
 * }
 *
 * with a schema of
 *
 * {
 *   sourceToTargetRate: { from: 'sourceCurrency', to: 'targetCurrency' },
 *   targetToSourceCurrency: { from: 'targetCurrency', to: 'sourceCurrency' },
 * }
 *
 * The reason we have this class is because historically, we keep FX rates in the DB
 * using annotations, e.g. { 'balanceToOrgRate': 1.1 } instead of { 'USDGBP': 1.1 }
 * so, we need a way to go from an annotated rate map, to a generic one
 *
 */
class AnnotatedRateMap extends RateMap {
  /**
   * Parses a serialized annotated rate map to a list of rate conversions
   *
   * @param {Object} serialized - a serialized annotated rate map from the DB
   * @param {Object} schema - a schema describing the annotations
   * @param {Money} testAgainstMoney - a money instance to test conversions against
   *
   * @returns {Conversion[]} a list of rate conversions
   */
  static parseConversions({ serialized, schema, testAgainstMoney }) {
    const rateNames = Object.keys(schema);
    const rateValues = fromPairs(rateNames.map(f => [f, serialized[f]]));
    assertAllKeysPresent(rateValues);

    const conversions = Object.entries(schema).map(([rateName, { from, to }]) => {
      const rate = serialized[rateName];
      const fromCurrency = serialized[from];
      const toCurrency = serialized[to];
      if (testAgainstMoney) {
        if (testAgainstMoney.getCurrency() !== fromCurrency) {
          throw new Error('mismatching currencies');
        }
        return Conversion.fromMoneyRate(testAgainstMoney, rate, toCurrency);
      }
      return new Conversion(fromCurrency, rate, toCurrency);
    });
    return conversions;
  }

  constructor(serialized, { schema, testAgainstMoney, extendsRateMap }) {
    const currencyNames = uniq(
      flatten(Object.values(schema).map(({ from, to }) => [from, to])),
    );
    const currencyValues = fromPairs(currencyNames.map(f => [f, serialized[f]]));
    assertAllKeysPresent(currencyValues);
    const conversions = AnnotatedRateMap.parseConversions({ serialized, schema, testAgainstMoney });

    super(
      extendsRateMap
        ? [...extendsRateMap.getConversions(), ...conversions]
        : conversions,
    );

    this.currencyMap = pick(serialized, currencyNames);
    this.schema = schema;
  }

  /**
   * Serializes all currencies of the rate map
   *
   * @returns {Object} the currencies of the rate map
   */
  serializeCurrencies() {
    return this.currencyMap;
  }

  /**
   * Serializes all rates of the rate map
   *
   * @returns {Object} the rates of the rate map
   */
  serializeRates() {
    const { currencyMap } = this;
    const serialized = {};
    Object.entries(this.schema).forEach(([rateName, { from, to }]) => {
      Object.assign(serialized, {
        [rateName]: this.getRate(currencyMap[from], currencyMap[to]),
      });
    });
    return serialized;
  }

  /**
   * Serializes the rate map
   *
   * @returns {Object} the serialized rate map
   */
  serialize() {
    const serialized = {
      ...this.serializeCurrencies(),
      ...this.serializeRates(),
    };
    return serialized;
  }

  toPlainRateMap() {
    return super.copy();
  }
}

export default AnnotatedRateMap;
