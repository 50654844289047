import React from 'react';
import PropTypes from 'prop-types';
import { filter as _filter, without, isArray, isString, isNumber, isNil } from 'lodash';

import TDRadio from 'core/assets/js/components/TDRadio.jsx';
import TDCheckbox from 'core/assets/js/components/TDCheckbox.jsx';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';


class SelectableListFilterField extends React.Component {
  constructor(props) {
    super(props);
    const { initialValues, filter, input } = props;
    let initialValue = initialValues[filter.paramName] || [];

    // If value is a number, transform it to string
    if (initialValue && isNumber(initialValue)) {
      initialValue = initialValue.toString();
    }

    // If value is not an array, transform it to one.
    if (initialValue && !isArray(initialValue)) {
      initialValue = [initialValue];
    }

    input.onChange(initialValue);

    this.state = {
      filterText: null,
    };

    // If options are more than this number, the filtering feature automatically is enabled.
    this.enableFilteringItemCount = 10;

    this.handleChange = this.handleChange.bind(this);
    this.isActive = this.isActive.bind(this);
    this.isFilteringEnabled = this.isFilteringEnabled.bind(this);
  }

  handleChange(selectedValue) {
    const { input, filter: { multiple } } = this.props;
    const { value } = input;
    // In case newValue is not a string, cast it as one (ie: status Ids)
    const newValue = !isString(selectedValue) ? selectedValue.toString() : selectedValue;

    // Check if the newValue is already in the list of selected values
    const isSelectedValue = value && value.indexOf(newValue);
    let _value;

    // Multiple selection is allowed
    if (multiple) {
      _value = isSelectedValue === -1
        ? [...value, newValue]
        : without(value, newValue);
    } else {
      // Only a single selection is allowed
      _value = isSelectedValue === -1
        ? [newValue]
        : [];
    }

    // Update redux form
    input.onChange(_value);
  }

  isFilteringEnabled() {
    const { filter: { options } } = this.props;
    return options.length > this.enableFilteringItemCount;
  }

  isActive(option) {
    const { input: { value } } = this.props;
    let isActive = false;
    let _value = value;

    if (isNil(option.value) || option.value === '') {
      return false;
    }

    if (isNumber(value)) {
      _value = value.toString();
    }

    if (!isString(option.value)) {
      // In case value is not a string, cast it as one (ie: Status Ids)
      isActive = (_value.indexOf(option.value.toString()) > -1);
    } else {
      isActive = (_value.indexOf(option.value) > -1);
    }

    return isActive;
  }

  render() {
    const { filter: { options, label, paramName, multiple }, input, maxLength  } = this.props;
    const { filterText } = this.state;
    const isFilteringEnabled = this.isFilteringEnabled();
    let filteredOptions = [].concat(options);

    if (filterText) {
      filteredOptions = _filter(
        filteredOptions,
        o => o.text.toLowerCase().includes(filterText.toLowerCase(),
        ));
    }
    let optionsList = filteredOptions.map((option) => {
      return (
        <li key={`${paramName}-${option.value}`}>
          {multiple && (
            <label
              className="d-flex align-items-center"
              onClick={() => this.handleChange(option.value)}
            >
              <TDCheckbox checked={this.isActive(option)} />
              <span>{option.text}</span>
            </label>
          )}

          {!multiple && (
            <TDRadio
              onClick={() => this.handleChange(option.value)}
              className="ml-0"
              selected={this.isActive(option)}
              label={option.text}
            />
          )}
        </li>
      );
    });

    if (!optionsList.length) {
      optionsList = [<li><i className="discreet">No items found.</i></li>];
    }

    return (
      <React.Fragment>
        <h4>{label}</h4>

        { this.isFilteringEnabled() && (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <input
                type="text"
                className="filter-input w-100"
                placeholder="Start typing..."
                onChange={(e) => {
                  this.setState({
                    filterText: e.target.value,
                  });
                }}
                maxLength={maxLength}
              />
            </div>
          </div>
        )}

        <div className="form-control-container">
          <ul className={`selectable-list ${isFilteringEnabled ? 'selectable-list--multiple' : ''}`}>
            {optionsList}
          </ul>
        </div>

        <input
          {...input}
          className="form-control"
          type="hidden"
        />
      </React.Fragment>
    );
  }
}

SelectableListFilterField.propTypes = {
  filter: PropTypes.object,
  input: reduxInputSpec.isRequired,
  initialValues: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
};
SelectableListFilterField.defaultProps = {
  filter: {},
  maxLength: null,
};

export default SelectableListFilterField;
