import React from 'react';
import { isEmpty } from 'lodash';

import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { financeEntityScheme } from 'finance/assets/js/lib/objectSpecs';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import BankAccountVariant from 'settings/assets/js/lib/BankAccountVariant';
import { getTWDetailsAddress, getTWAllowedDetails, getTwDetailLabel } from 'finance/assets/js/lib/utils';
import { getCountryName } from 'core/assets/js/lib/utils';

const InvoiceTWBankDetails = ({ owner, bankAccount }) => {
  let bank;
  if (bankAccount) {
    bank = new BankAccountVariant(bankAccount);
  } else {
    if (!owner || isEmpty(owner)) {
      return null;
    }
    const ownerFE = new FinancialEntity(owner);
    bank = ownerFE.getBank();
  }
  if (!bank) {
    return null;
  }

  const details = bank.getBankFields();
  const currency = bank.getCurrency();
  const beneficiary = bank.getBeneficiary();
  const countryCode = bank.getCountryCode();
  const bankName = bank.getBankName();
  const customReference = bank.getCustomReference();
  const detailsToShow = getTWAllowedDetails(details, currency);
  return (
    <div className="tw-bank-details">
      {bankName && (
        <div className="bank-name">
          {`Bank name: ${bankName}`}
        </div>
      )}
      {countryCode && (
        <div className="bank-country mb-1">
          {`Bank country: ${getCountryName(countryCode)}`}
        </div>
      )}
      <div className="tw-bank-details--beneficiary mb-1">
        {`Beneficiary: ${beneficiary}`}
      </div>
      { Object.keys(detailsToShow).map((key) => {
        const formattedCurrency = currency.toLowerCase();
        const detailLabel = getTwDetailLabel(key, formattedCurrency);
        if (detailsToShow[key]) {
          const detailInfo = key === 'address'
            ? getTWDetailsAddress(detailsToShow[key])
            : detailsToShow[key];
          const className = `tw-bank-details--${key} mb-1`;
          return (
            <div key={key} className={className}>
              {`${detailLabel}: ${detailInfo}`}
            </div>
          );
        }
        return null;
      })}
      { customReference && (
        <div className="tw-bank-details--custom-reference">
          {`Payee reference message: ${customReference}`}
        </div>
      )}
    </div>
  );
};

InvoiceTWBankDetails.propTypes = {
  owner: financeEntityScheme,
  bankAccount: bankAccountSpec,
};

InvoiceTWBankDetails.defaultProps = {
  owner: null,
  bankAccount: null,
};

export default InvoiceTWBankDetails;
