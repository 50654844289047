import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { reset } from 'redux-form';

import SkillList from 'core/assets/js/components/SkillList.jsx';
import SkillsCardItemActions from 'people/assets/js/components/SkillsCardItemActions.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import { modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import EndorseSkillsModal, { ENDORSE_SKILL_MODAL_ID, ENDORSE_SKILL_FORM_ID } from 'people/assets/js/components/EndorseSkillsModal.jsx';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { endorseProfileSkillDS, upVoteProfileSkillDS, retractVoteProfileSkillDS } from 'settings/assets/js/data-services/settings';
import { PEOPLE_TYPE } from 'people/assets/js/constants';

/**
 * Skills and Languages Widget
 * this component holdes logic
 * for skill endorsement behaviors
 * used at ProfileView component
 */

class SkillsLanguagesWidget extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { userType } = params;
    if (userType === PEOPLE_TYPE.PROVIDERS) {
      return dispatch(fetchProviderDS({ url, params, authedAxios, componentName }));
    }

    return dispatch(fetchManagerDS({ url, params, authedAxios, componentName }));
  }

  static GetComponentName() {
    return 'SkillsLanguagesWidget';
  }

  constructor(props) {
    super(props);
    this.handleOpenEndorseSkills = this.handleOpenEndorseSkills.bind(this);
    this.handleSkillEndorseSubmit = this.handleSkillEndorseSubmit.bind(this);
    this.retractVote = this.retractVote.bind(this);
    this.upVote = this.upVote.bind(this);
  }

  handleOpenEndorseSkills() {
    const { dispatch, profile } = this.props;
    const { skills, name } = profile;
    const notCopiedByProvider = ({
      copiedByUser, isManagerEndorsed,
    }) => !copiedByUser && isManagerEndorsed;
    const copiedByProvider = ({ copiedByUser }) => copiedByUser;

    dispatch(modalOpenAC(ENDORSE_SKILL_MODAL_ID, {
      skills: skills.filter(notCopiedByProvider),
      filteringSkills: skills.filter(copiedByProvider),
      username: name,
    }));
  }

  async handleSkillEndorseSubmit({ upVotes, retractVotes }) {
    const { profile: { userId }, dispatch, organization: {
      unique_alias: uniqueAlias,
    } } = this.props;
    // const skillIds = props.skills.map(i => i.id);

    await dispatch(endorseProfileSkillDS(
      uniqueAlias, {
        upVotes,
        retractVotes,
        userId,
      },
    ));
    dispatch(requestRefreshAC(this.constructor.GetComponentName()));
  }

  async upVote({ profileSkillId }) {
    const { dispatch, organization: {
      unique_alias: uniqueAlias,
    } } = this.props;

    await dispatch(upVoteProfileSkillDS(
      uniqueAlias, {
        profileSkillId,
      },
    ));
    dispatch(requestRefreshAC(this.constructor.GetComponentName()));
  }

  async retractVote({ profileSkillId }) {
    const { dispatch, organization: {
      unique_alias: uniqueAlias,
    } } = this.props;

    await dispatch(retractVoteProfileSkillDS(
      uniqueAlias, {
        profileSkillId,
      },
    ));
    dispatch(requestRefreshAC(this.constructor.GetComponentName()));
  }

  render() {
    const {
      profile, dispatch, canEndorseSkill,
    } = this.props;
    let skills = [];
    let languages = [];

    if (profile) {
      skills = profile.skills;
      languages = profile.languages;
    }

    return (
      <React.Fragment>
        {canEndorseSkill && (
          <EndorseSkillsModal
            onSubmit={this.handleSkillEndorseSubmit}
            onSubmitSuccess={() => {
              dispatch(modalCloseAC(ENDORSE_SKILL_MODAL_ID));
              dispatch(reset(ENDORSE_SKILL_FORM_ID));
            }}
          />
        )}
        <TDApiConnected
          component={this.constructor}
          duck="view"
        />
        <Card className="mt-4">
          <Card.Header>
            Skills &amp; Languages
            {canEndorseSkill && (
              <SkillsCardItemActions
                onEndorseSkills={this.handleOpenEndorseSkills}
              />
            )}
          </Card.Header>
          <Card.Body>
            { profile && skills && (
              <div className="user-profile__skills">
                <SkillList
                  onUpVote={this.upVote}
                  onRetractVote={this.retractVote}
                  skills={skills}
                  alignTags="left"
                />
              </div>
            )}
            <hr />
            { profile && languages && (
              <div className="user-profile__skills">
                <SkillList skills={languages} alignTags="left" />
              </div>
            )}
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

SkillsLanguagesWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profile: profileSpec,
  organization: PropTypes.object,
  canEndorseSkill: PropTypes.bool,
};

SkillsLanguagesWidget.defaultProps = {
  profile: {},
  organization: {},
  canEndorseSkill: false,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, SkillsLanguagesWidget.GetComponentName());
  const hasOrgAccess = getHasOrgAccess(state);
  const isManager = hasOrgAccess({ requireManager: true });
  const { item: { user, userRole } } = viewState;

  return {
    organization: selectActiveOrg(state),
    canEndorseSkill: isManager && userRole?.isProvider,
    profile: user ? user.profile : null,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProviderSkillsLanguagesWidgetConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkillsLanguagesWidget);

export default ProviderSkillsLanguagesWidgetConnect;
