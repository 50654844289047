import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { orgLoginUrl } from 'accounts/urls';

const OrgViewPage = ({ match: { url: currentUrl, params: { orgAlias } }, profile }) => {
  if (!isEmpty(profile) && profile.homeUrl) {
    return (
      <RedirectRoute
        status={301}
        from={currentUrl}
        to={profile.homeUrl}
      />
    );
  }

  return (
    <RedirectRoute
      status={301}
      from={currentUrl}
      to={orgLoginUrl(orgAlias)}
    />
  );
};

OrgViewPage.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  profile: PropTypes.object,
};

OrgViewPage.defaultProps = {
  profile: {},
};

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = state => ({
  profile: selectProfile(state),
});

const OrgViewPageConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgViewPage);

export default withRouter(OrgViewPageConnected);
