import React from 'react';
import PropTypes from 'prop-types';

const CTARow = ({ CTAButtons }) => (
  <div className="discussion-board__row db-cta-row">
    <div className="db-cta-row__buttons-wrapper">
      {CTAButtons.filter(cta => !!cta)}
    </div>
  </div>
);

CTARow.propTypes = {
  CTAButtons: PropTypes.array,
};

CTARow.defaultProps = {
  CTAButtons: [],
};

export default CTARow;
