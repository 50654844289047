import { ORDERING_DIRECTION, USER_TYPE } from 'core/assets/js/constants';
import { STATUS as INVITATION_STATUS } from 'invitations/assets/js/constants';

export const CONTACTS_DEFAULT_ORDERING = {
  direction: ORDERING_DIRECTION.DESC,
  sortBy: 'created_at',
};


export const CONTACT_FILTER_STATUS = {
  MANAGER: 1,
  PROVIDER: 2,
  MANAGER_INV_PENDING: 3,
  PROVIDER_INV_PENDING: 4,
  NOT_INVITED: 5,
};

export const CONTACT_FILTER_STATUS_LABEL = {
  [CONTACT_FILTER_STATUS.MANAGER]: 'Joined as Manager',
  [CONTACT_FILTER_STATUS.PROVIDER]: 'Joined as Provider',
  [CONTACT_FILTER_STATUS.PROVIDER_INV_PENDING]: 'Invited as Provider',
  [CONTACT_FILTER_STATUS.MANAGER_INV_PENDING]: 'Invited as Manager',
  [CONTACT_FILTER_STATUS.NOT_INVITED]: 'Not invited',
};

export const CONTACT_FILTER_STATUS_VALUES = {
  [CONTACT_FILTER_STATUS.MANAGER]: { ofType: USER_TYPE.MANAGER },
  [CONTACT_FILTER_STATUS.PROVIDER]: { ofType: USER_TYPE.PROVIDER },
  [CONTACT_FILTER_STATUS.PROVIDER_INV_PENDING]: {
    ofInvitationStatus: INVITATION_STATUS.PENDING,
    ofInvitationUserRole: USER_TYPE.PROVIDER,
  },
  [CONTACT_FILTER_STATUS.MANAGER_INV_PENDING]: {
    ofInvitationStatus: INVITATION_STATUS.PENDING,
    ofInvitationUserRole: USER_TYPE.MANAGER,
  },
};

export const CONTACT_STATUS = {
  INVITATION_ACCEPTED: 1,
  INVITATION_PENDING: 2,
  INVITATION_REJECTED: 3,
};

export const CONTACT_STATUS_CLASS = {
  [CONTACT_STATUS.INVITATION_ACCEPTED]: 'cancelled',
  [CONTACT_STATUS.INVITATION_PENDING]: 'pending',
  [CONTACT_STATUS.INVITATION_REJECTED]: 'rejected',
};

export const CONTACTS_IMPORT_STEP = {
  UPLOAD: 'upload',
  MAPPING: 'mapping',
  PREVIEW: 'preview',
  RESULTS: 'results',
};

export const CONTACTS_IMPORT_LIMIT = 500;
