import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import GetPaidMethodSelectCard from 'settings/assets/js/components/GetPaidMethodSelectCard.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { financeExpensesCreateUrl, financeWorksheetsCreateUrl, financeProFormaInvoiceCreateUrl } from 'finance/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { withRouter } from 'react-router-dom';

export const GET_PAID_MODAL_ID = 'get-paid-modal';

function GetPaidModal({ activeOrg, dispatch, history, open }) {
  const GET_PAID_METHOD = {
    WORKSHEET: 1,
    PROFORMA: 2,
    EXPENSE: 3,
  };

  const items = [
    {
      icon: ICON.MONEY_CHECK_DUOTONE,
      id: GET_PAID_METHOD.WORKSHEET,
      name: 'Submit a Worksheet',
      description: 'Best for when you were added to a particular project and/or task.',
    },
    {
      icon: ICON.INVOICE_DUOTONE,
      id: GET_PAID_METHOD.PROFORMA,
      name: 'New Proforma Invoice',
      description: 'Best for when you are raising an invoice for a standard / fixed amount that is not linked to Projects.',
    },
    {
      icon: ICON.CLIPBOARD_LIST_CHECK_DUOTONE,
      id: GET_PAID_METHOD.EXPENSE,
      name: 'Expense Claim',
      description: 'Best for when you need to claim an expense for your purchase for the work you did.',
    },
  ];

  const getPaidMethodCreateUrls = {
    [GET_PAID_METHOD.WORKSHEET]: financeWorksheetsCreateUrl,
    [GET_PAID_METHOD.PROFORMA]: financeProFormaInvoiceCreateUrl,
    [GET_PAID_METHOD.EXPENSE]: financeExpensesCreateUrl,
  };

  const isDraftInvoiceEnabled = activeOrg.allow_draft_invoice;

  let listOptions;
  if (!isDraftInvoiceEnabled) {
    listOptions = Object.values(items)
      .filter(m => m.id !== GET_PAID_METHOD.PROFORMA);
  } else {
    listOptions = Object.values(items);
  }

  const checkableListItems = listOptions.map(lo => lo);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const onItemChecked = (item) => {
    setSelectedMethod(item);
  };

  const classNames = ['get-paid-modal__item col-12 mt-4'];
  if (!isDraftInvoiceEnabled) {
    classNames.push('col-lg-6');
  } else {
    classNames.push('col-lg-4');
  }

  const helpUrl = 'https://www.talentdesk.io/knowledge-base/finances#provider';

  return (
    <div className="static-modal">
      <ModalSimple
        body={(
          <>
            <div className="col-lg-8 offset-lg-2 text-center mb-4">
              <h2>How would you like to get paid?</h2>
              <p>
                You can request a simple payment using proforma invoices or if you
                worked on a project you can submit a worksheet or claim an expense.
              </p>
            </div>
            <div className="get-paid-modal row text-center">
              <CheckableList
                itemComponent={GetPaidMethodSelectCard}
                itemComponentProps={{}}
                items={checkableListItems}
                onItemChecked={onItemChecked}
                wrapperClassName={classNames.join(' ')}
                onClose={() => dispatch(modalCloseAC(GET_PAID_MODAL_ID))}
              />
            </div>
          </>
        )}
        onClose={() => dispatch(modalCloseAC())}
        footer={(
          <>
            <div className="get-paid-modal__footer container d-flex justify-content-center mb-4">
              <TDButton
                btnIcon={ICON.ARROW_RIGHT}
                disabled={!selectedMethod}
                label="Continue"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(modalCloseAC());
                  history.push({
                    pathname: getPaidMethodCreateUrls[selectedMethod.id](activeOrg.alias),
                  });
                }}
                variant={BS_STYLE.PRIMARY}
              />
            </div>
            <div className="container align-items-start pl-0 mt-4">
              <p>
                I&rsquo;m not sure which option to choose.
                {' '}
                <a
                  href={helpUrl}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Learn more
                </a>
              </p>
            </div>
          </>
        )}
        open={open}
        size={BS_SIZE.LARGE}
      />
    </div>
  );
}

GetPaidModal.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  open: PropTypes.bool,
  userCard: PropTypes.object.isRequired,
};

GetPaidModal.defaultProps = {
  open: false,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  open: getIsModalOpen(state, GET_PAID_MODAL_ID),
  userCard: selectActiveUserCard(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });
const GetPaidModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GetPaidModal);

export default withRouter(GetPaidModalConnected);
