import { REQ_TYPE } from 'core/assets/js/constants';
import { listAppendItemAC, listReplaceItemAC } from 'core/assets/js/ducks/list';
import { viewFetchAC } from 'core/assets/js/ducks/view';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  documentArchiveApiUrl,
  documentAssignmentSignApiUrl,
  documentCreateApiUrl,
  documentDuplicateApiUrl,
  documentEditApiUrl,
  documentNewVersionApiUrl,
  documentPublishApiUrl,
} from 'documents/urls';

export const archiveDocumentDS = ({ id, listComponentName, orgAlias }) => (
  pushDataDS({
    pushApiUrl: documentArchiveApiUrl(orgAlias, id),
    pushDataAC: responseData => listReplaceItemAC(responseData, listComponentName),
    reqType: REQ_TYPE.PUT,
  })
);

export const createDocumentDS = ({ listComponentName, orgAlias, values }) => (
  pushDataDS({
    pushApiUrl: documentCreateApiUrl(orgAlias),
    pushDataAC: responseData => listAppendItemAC(responseData, listComponentName),
    reqType: REQ_TYPE.POST,
    values,
  })
);

export const editDocumentDS = ({ componentName, id, listComponentName, orgAlias, values }) => (
  pushDataDS({
    pushApiUrl: documentEditApiUrl(orgAlias, id),
    pushDataAC: responseData => [
      listReplaceItemAC(responseData, listComponentName), viewFetchAC(responseData, componentName),
    ],
    reqType: REQ_TYPE.PUT,
    values,
  })
);

export const publishDocumentDS = ({ componentName, id, listComponentName, orgAlias }) => (
  pushDataDS({
    pushApiUrl: documentPublishApiUrl(orgAlias, id),
    pushDataAC: responseData => {
      const actions = [listReplaceItemAC(responseData, listComponentName)];
      if (componentName) {
        actions.push(viewFetchAC(responseData, componentName));
      }
      return actions;
    },
    reqType: REQ_TYPE.PUT,
  })
);

export const documentNewVersionDS = ({ id, listComponentName, orgAlias, values }) => (
  pushDataDS({
    pushApiUrl: documentNewVersionApiUrl(orgAlias, id),
    pushDataAC: responseData => listAppendItemAC(responseData, listComponentName),
    reqType: REQ_TYPE.PUT,
    values,
  })
);

export const duplicateDocumentDS = ({ id, listComponentName, orgAlias }) => (
  pushDataDS({
    pushApiUrl: documentDuplicateApiUrl(orgAlias, id),
    pushDataAC: responseData => listAppendItemAC(responseData, listComponentName),
    reqType: REQ_TYPE.PUT,
  })
);

export const signDocumentDS = ({ documentAssignmentId, values, orgAlias }) => (
  pushDataDS({
    pushApiUrl: documentAssignmentSignApiUrl(orgAlias, documentAssignmentId),
    reqType: REQ_TYPE.POST,
    values,
  })
);

