import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { paginationSpec, routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import Table from 'core/assets/js/components/Table.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import BillingHistoryTableActions from 'finance/assets/js/components/BillingHistoryTableActions.jsx';
import { fetchFinanceInvoiceListDS } from 'finance/assets/js/data-services/list';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import { INVOICE_STATUS_LABEL } from 'finance/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { invoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { parseQueryStringParams, prepareQueryString } from 'core/assets/js/lib/utils';
import { getListState } from 'core/assets/js/ducks/list';


const BILLING_HISTORY_OVERVIEW_PAGE_SIZE = 5;

function BillingHistoryTable({
  match: { params: { tab } },
  invoices,
  isOverview,
  pagination,
}) {
  const tableColumns = [
    { key: 'date', label: 'Date' },
    { key: 'number', label: 'Invoice number' },
    { key: 'subscriptionFrequency', label: 'Billing period' },
    {
      key: 'formattedTotal',
      label: 'Amount',
    },
    { key: 'status',
      label: 'Status',
      dataFormat: status => (
        <StatusColFormat statusLabel={INVOICE_STATUS_LABEL[status]} />
      ),
      width: '180px',
    },
    {
      key: 'actions', width: '80px', label: '',
      dataAlign: 'right', columnClassName: 'actions-cell',
    },
  ];

  return (
    <TDApiConnected
      loadingEnabled={false}
      duck="list"
      storeKey={BillingHistoryTable.GetComponentName(tab)}
      skeletonComponent={FinanceTableSkeleton}
      fetchData={({
        dispatch, params, url, authedAxios, componentName, querystring,
      }) => {
        const queryParams = parseQueryStringParams(querystring);
        return dispatch(fetchFinanceInvoiceListDS({
          url,
          params,
          querystring: prepareQueryString({
            pageSize: isOverview ? BILLING_HISTORY_OVERVIEW_PAGE_SIZE : null,
            isSubscription: 1,
            ...queryParams,
          }),
          componentName,
          authedAxios,
        }));
      }}
    >
      <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list">
        <Table
          cols={tableColumns}
          items={invoices.map((invoice) => {
            const hasSubscriptionPlans = invoice.subscription.details?.plans
              && invoice.subscription.details?.plans.length > 0;
            const subscriptionFrequency = hasSubscriptionPlans ? invoice.subscription.details.plans[0].renewalInterval : '-';
            return ({
              ...invoice,
              formattedTotal: (
                <NumberTpl
                  value={invoice.total}
                  decimals={2}
                  prefix={invoice.currencySymbol}
                />
              ),
              subscriptionFrequency,
              actions: (
                <BillingHistoryTableActions
                  invoiceId={invoice.id}
                />
              ),
            });
          })}
          isLarge
          emptyText="No subscriptions found"
          trClassName={row => `objectId-${row && row.id}`}
        />
      </div>
      {
        !isOverview && (
          <TDPagination {...pagination} />
        )
      }
    </TDApiConnected>
  );
}

BillingHistoryTable.GetComponentName = tab => `BillingHistory-${tab}`;

BillingHistoryTable.propTypes = {
  history: routerHistorySpec.isRequired,
  invoices: PropTypes.arrayOf(invoiceSpec),
  isOverview: PropTypes.bool,
  match: routerMatchSpec.isRequired,
  pagination: paginationSpec.isRequired,
};

BillingHistoryTable.defaultProps = {
  invoices: [],
  isOverview: false,
};

const mapStateToProps = (state, props) => {
  const { match: { params: { tab } } } = props;
  const listState = getListState(state, BillingHistoryTable.GetComponentName(tab));

  return {
    invoices: listState.items,
    pagination: listState.pagination,
  };
};

const BillingHistoryTableConnected = connect(
  mapStateToProps,
)(BillingHistoryTable);


export default withRouter(BillingHistoryTableConnected);
