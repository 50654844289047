import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { concatNodes } from 'core/assets/js/lib/utils-jsx';
import { invoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import { financeInvoiceViewUrl } from 'finance/urls';

const ReportInvoiceLinks = ({ orgAlias, invoices }) => {
  if (!Array.isArray(invoices) || isEmpty(invoices)) {
    return (
      <span>
        -
      </span>
    );
  }

  const invoiceNodes = invoices.filter(inv => !!inv).map((inv) => {
    if (!inv.allowedActions || !inv.allowedActions.canViewNumber) {
      return null;
    }

    if (inv.allowedActions && inv.allowedActions.canViewDetails) {
      return (
        <Link
          key={`invoice-link-${inv.id}`}
          to={financeInvoiceViewUrl(orgAlias, inv.id)}
        >
          {inv.number}
        </Link>
      );
    }

    return inv.number;
  }).filter(inv => !!inv);

  return (
    <span>
      {invoiceNodes.length > 0 ? concatNodes(invoiceNodes.filter(inv => !!inv)) : (
        <span key="none">
          &ensp;
          {' '}
        </span>
      )}
    </span>
  );
};

ReportInvoiceLinks.propTypes = {
  orgAlias: PropTypes.string.isRequired,
  invoices: PropTypes.arrayOf(invoiceSpec),
};

ReportInvoiceLinks.defaultProps = {
  invoices: [],
};

export default ReportInvoiceLinks;
