import React, { useState } from 'react';
import moment from 'moment';
import { Card } from 'react-bootstrap';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import ChartBasicLine from 'core/assets/js/components/ChartBasicLine.jsx';
import ChartBurnDown from 'core/assets/js/components/ChartBurnDown.jsx';
import ChartStackedColumns from 'core/assets/js/components/ChartStackedColumns.jsx';
import ChartPie from 'core/assets/js/components/ChartPie.jsx';
import { CURRENCY_SYMBOL, CURRENCY } from 'core/assets/js/constants';


const pieChartConfig = () => {
  const mainCategories = [
    'Providers',
    'Managers',
  ];

  // eslint-disable-next-line no-unused-vars
  const subcategoryData = [];
  const mainCategoryData = [];
  const data = [
    {
      y: 70000,
      color: 'rgb(21,132,198)',
      count: 100,
      drilldown: {
        name: mainCategories[0],
        categories: [
          'Submitted',
          'Approved',
        ],
        currency: '$',
        data: [
          40000,
          30000,
        ],
        counts: [
          1,
          99,
        ],
        colors: [
          '#38a6e6', // Raised Provider's WS
          '#2292d4', // Approved Provider's WS
        ],
      },
    },
    {
      y: 30000,
      color: '#3ABC4E',
      count: 80,
      drilldown: {
        name: mainCategories[1],
        categories: [
          'Submitted',
          'Approved',
        ],
        currency: '$',
        data: [
          12000,
          18000,
        ],
        counts: [
          49,
          31,
        ],
        colors: [
          '#74e287', // Raised Manager's WS
          '#4fce64', // Approved Manager's WS
        ],
      },
    },
  ];
  const dataLen = data.length;

  // Build the data arrays
  for (let i = 0; i < dataLen; i += 1) {
    mainCategoryData.push({
      name: mainCategories[i],
      y: data[i].y,
      color: data[i].color,
      count: data[i].count,
      categoryLabel: data[i].categoryLabel,
    });

    const drillDataLen = data[i].drilldown.data.length;
    for (let j = 0; j < drillDataLen; j += 1) {
      subcategoryData.push({
        name: data[i].drilldown.categories[j],
        y: data[i].drilldown.data[j],
        color: data[i].drilldown.colors[j],
        count: data[i].drilldown.counts[j],
        categoryLabel: data[i].categoryLabel,
      });
    }
  }

  return {
    currency: '$',
    tooltip: {
      useHTML: true,
      outside: true,
      style: {
        width: 'auto',
        whiteSpace: 'wrap',
      },
      headerFormat: '<div style="margin-bottom: 5px; border-bottom: 1px solid #A8A8A8; padding-bottom: 2px;"><strong>{point.key}</strong></div><table>',
      pointFormat: '<tr><td><strong>{point.categoryLabel} #:</strong></td><td style="text-align: right">{point.count}</td></tr>'
        + '<tr><td><strong>Total:</strong></td><td style="text-align: right"> {point.currency}{point.y:,.2f}</td></tr>',
      footerFormat: '</table>',
    },
    series: [{
      name: 'Worksheets',
      data: mainCategoryData,
      size: '60%',
    }, {
      name: 'Worksheets',
      data: subcategoryData,
      size: '80%',
      innerSize: '60%',
      id: 'categories',
    }],
  };
};

const burndownChartData = [
  [moment('2019-01-01').valueOf(), 1000],
  [moment('2019-01-10').valueOf(), 990],
  [moment('2019-02-03').valueOf(), 880],
  [moment('2019-03-14').valueOf(), 670],
  [moment('2019-04-05').valueOf(), 660],
  [moment('2019-04-16').valueOf(), 650],
  [moment('2019-05-07').valueOf(), 600],
  [moment('2019-05-18').valueOf(), 530],
  [moment('2019-06-09').valueOf(), 520],
  [moment('2019-06-20').valueOf(), 310],
];

const stackedChartSeriesData = [
  {
    name: 'Approved',
    data: [5, 3, 4, 2, 2],
  },
  {
    name: 'Submitted',
    data: [2, 2, 3, 2, 1],
  },
];

const stackedChartData = {
  xAxisCategories: [
    { name: 'Bill' },
    { name: 'Bob' },
    { name: 'Ben' },
    { name: 'Brad' },
    { name: 'Bin' },
  ],
  series: stackedChartSeriesData,
  xAxisFormatter: value => value.name,
  totalEntries: 4,
};

const burnDownTabSpec = [
  {
    label: 'PO = WS',
    key: 'burnDownSample1',
  },
  {
    label: 'PO1 < PO2',
    key: 'burnDownSample2',
  },
  {
    label: 'PO1 > PO2',
    key: 'burnDownSample3',
  },
  {
    label: 'Normal',
    key: 'burnDownSample4',
  },
];

// eslint-disable-next-line no-unused-vars
const burnDownSamples = {
  burnDownSample1: {
    data: [
      [moment('2019-01-01').valueOf(), 1000],
      [moment('2019-01-10').valueOf(), 0],
    ],
    helpText: 'PO is equal to first WS',
  },
  burnDownSample2: {
    data: [
      [moment('2019-01-01').valueOf(), 1000],
      [moment('2019-01-10').valueOf(), 0],
      [moment('2019-01-15').valueOf(), 800],
    ],
    helpText: 'First PO is eliminated, second PO is lower than first',
  },
  burnDownSample3: {
    data: [
      [moment('2019-01-01').valueOf(), 1000],
      [moment('2019-01-10').valueOf(), 0],
      [moment('2019-01-15').valueOf(), 1800],
    ],
    helpText: 'First PO is eliminated, second PO is higher than first',
  },
  burnDownSample4: {
    data: [
      [moment('2019-01-01').valueOf(), 10000],
      [moment('2019-01-02').valueOf(), 8000],
      [moment('2019-04-04').valueOf(), 6000],
      [moment('2019-04-05').valueOf(), 4000],
      [moment('2019-04-06').valueOf(), 2000],
      [moment('2019-04-07').valueOf(), 1000],
      [moment('2019-04-09').valueOf(), 0],
      [moment('2019-06-15').valueOf(), 15000],
      [moment('2019-06-18').valueOf(), 13000],
      [moment('2019-06-19').valueOf(), 6000],
      [moment('2019-06-20').valueOf(), 5000],
      [moment('2019-06-21').valueOf(), 4000],
      [moment('2019-06-22').valueOf(), 2000],
      [moment('2019-06-26').valueOf(), 2000],
      [moment('2019-06-27').valueOf(), 600],
      [moment('2019-07-01').valueOf(), 5000],
    ],
    helpText: 'Normal data',
  },
};

function ChartsSection() {
  const [burnDownSampleSelected, setBurnDownSampleSelected] = useState('burnDownSample1');

  return (
    <section id="charts">
      <h3 className="mt-5">Basic Line Chart</h3>
      <div className="rounded shadow-sm p-4 bg-white mb-5">
        <ChartBasicLine chartData={burndownChartData} chartName="PO" />
      </div>

      <h2 className="mt-5">Charts</h2>
      <h3>Burndown Chart</h3>
      <div className="rounded shadow-sm p-4 bg-white mb-5">
        <Card>
          <Card.Header>
            <TabBar
              tabSpec={burnDownTabSpec}
              handleTabSelect={tabKey => setBurnDownSampleSelected(tabKey)}
            />
          </Card.Header>
          <Card.Body>
            <ChartBurnDown
              data={burnDownSamples[burnDownSampleSelected].data}
              prefix={CURRENCY_SYMBOL[CURRENCY.GBP]}
            />
            <p>{burnDownSamples[burnDownSampleSelected].helpText}</p>
            <p>{JSON.stringify(burnDownSamples[burnDownSampleSelected].data, null, 2)}</p>
          </Card.Body>
        </Card>
      </div>


      <h3 className="mt-5">Stacked Columns Chart</h3>
      <div className="rounded shadow-sm p-4 bg-white mb-5">
        <ChartStackedColumns
          chartData={stackedChartData}
          currencySymbol="$"
        />
      </div>

      <h3 className="mt-5">Pie Chart</h3>
      <div className="pie-chart-container">
        <div className="col-6 col-md-6 rounded shadow-sm bg-white mb-5">
          <ChartPie config={pieChartConfig()} />
        </div>
      </div>
    </section>
  );
}

export default ChartsSection;
