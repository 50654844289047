/* globals File */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';

import { downloadFile } from 'accounts/assets/js/lib/helpers';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProjectApplicationForm from 'projects/assets/js/components/ProjectApplicationForm.jsx';
import { PROJECT_APPLICATION_STATUS } from 'projects/assets/js/constants';
import { projectApplicationViewApiUrl, projectOpportunitiesUrl } from 'projects/urls';
import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';

const ProjectApplicationEditView = () => {
  const history = useHistory();
  const { applicationId, orgAlias, id: projectId } = useParams();
  const [loadedFiles, setLoadedFiles] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const { hasLoaded, item: { application, project } = {} } = fetchDataHook({
    componentName: ProjectApplicationEditView.GetComponentName(),
    url: projectApplicationViewApiUrl(orgAlias, projectId, applicationId),
  });

  const loadFiles = async attachments => {
    if (attachments.length === 0) {
      return;
    }
    try {
      const newAttachmentFiles = [];
      await Promise.all(attachments.map(async attachment => {
        if (/^\d+$/.test(attachment.toString())) {
          // This is the id for one of our files DB records
          const file = await downloadFile(attachment);
          newAttachmentFiles.push(file);
          return;
        }
        if (
          typeof attachment?.url === 'string'
          && typeof attachment.filename === 'string'
          && typeof attachment.mimetype === 'string'
        ) {
          // This is a filestack object
          const { data: fileBlob } = await axios.get(attachment.url, { responseType: 'blob' });
          newAttachmentFiles.push(new File(
            [fileBlob], attachment.filename, { type: attachment.mimetype },
          ));
        }
      }));
      setAttachmentFiles(newAttachmentFiles);
    } catch (e) {
      toastr.error('Oh Snap!', e._error || e.message);
    }
  };

  useEffect(() => {
    if (application) {
      loadFiles(application.attachments)
        .then(() => {
          setLoadedFiles(true);
        });
    }
  }, [application]);

  const breadcrumbs = [
    {
      title: 'Opportunities',
      url: projectOpportunitiesUrl(orgAlias),
    },
    {
      title: project?.title || '',
      url: null,
    },
  ];

  const initialValues = {
    attachments: attachmentFiles,
    id: applicationId,
    message: application?.message,
  };
  if (project?.rateGuideUnit && (project?.rateGuideFixed || application?.rateAmount)) {
    initialValues.rateAmount = parseFloat(project.rateGuideFixed || application.rateAmount).toFixed(
      RATE_UNIT_FORMAT[project.rateGuideUnit].decimals,
    );
  }

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--project-application">
        <div className="container">
          {hasLoaded && loadedFiles && (
            <ProjectApplicationForm
              initialValues={initialValues}
              isReApply={application.status === PROJECT_APPLICATION_STATUS.CANCELLED}
              onSubmitSuccess={() => {
                history.push(projectOpportunitiesUrl(orgAlias));
              }}
              orgAlias={orgAlias}
              project={project}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

ProjectApplicationEditView.GetComponentName = () => 'ProjectApplicationEditView';

export default ProjectApplicationEditView;
