import React from 'react';
import PropTypes from 'prop-types';
import Logger from 'core/assets/js/lib/Logger';
import { withRouter } from 'react-router-dom';

const withComponent = (WrappedComponent) => {
  class _WithComponent extends React.Component {
    constructor(props) {
      super(props);
      const componentName = this.getComponentName();
      this.logger = new Logger(`tdapiconnected:${componentName}:component`);
    }

    /* componentDidUpdate(prevProps, stateProps) {
      const { printPropChanges } = require('core/assets/js/lib/utils');
      this.logger.log('PROPS');
      printPropChanges(this.props, prevProps, { logger: this.logger });
      if (stateProps) {
        this.logger.log('STATE');
        printPropChanges(this.state, stateProps, { logger: this.logger });
      }
    } */

    getComponentName() {
      const { component, match, name } = this.props;
      const { params } = match;
      let componentName;
      if (!component) {
        componentName = name;
      } else {
        componentName = component.GetComponentName({ params });
      }
      return componentName;
    }

    render() {
      const { component, name, ...passthroughProps } = this.props;
      const componentName = this.getComponentName();
      let fetchData;
      if (component) {
        fetchData = component.FetchData;
      }
      // this.logger.log('render');

      return (
        <WrappedComponent
          storeKey={componentName}
          key={
            // by passing 'key' prop here we ensure that mount/unmount cycle will work as expected
            // since new components will get recognized by their different and unique keys
            // eslint-disable-next-line react/destructuring-assignment,react/prop-types
            this.props.storeKey
          }
          componentName={componentName}
          fetchData={fetchData}
          {...passthroughProps}
        />
      );
    }
  }


  _WithComponent.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    name: PropTypes.string,
    match: PropTypes.object.isRequired,
  };

  _WithComponent.defaultProps = {
    name: '',
    component: null,
  };
  return withRouter(_WithComponent);
};

export default withComponent;
