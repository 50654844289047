import React from 'react';
import PropTypes from 'prop-types';

import { LOGO } from 'core/assets/js/constants';

const Header = ({ title }) => (
  <div className="header-container">
    <div className="header__logo-container d-flex align-items-center">
      <a href="/">
        <img width="130" src={LOGO.DEFAULT} className="header__logo" alt="TalentDesk.io Logo" />
      </a>

      {title && (
        <div className="header-container__title">{title}</div>
      )}
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string,
};


Header.defaultProps = {
  title: null,
};
export default Header;
