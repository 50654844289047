/* eslint-env browser */

import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import axios from 'core/assets/js/lib/tdAxios';

import { isSSR } from 'core/assets/js/config/checks';
import { languageSearchApiUrl } from 'skills/urls';
import { REACT_SELECT_STYLES } from 'core/assets/js/constants';
import { logger } from 'core/assets/js/lib/Logger';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

/**
 * Multiselect language component
 *
 * Loads languages async via an API endpoint
 */
class LanguageMultiselect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  /**
   * Fire when the language list is updated
   *
   * @param value Array of language objects, each entry contains an id and label
   */
  onChange(value) {
    const { input: { onChange } } = this.props;
    onChange(value);
  }

  getOptions(input) {
    // Start searching after the 2nd letter
    if (!input || (input && input.trim().length < 2)) {
      return Promise.resolve([]);
    }

    return new Promise((resolve) => {
      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
      }

      this.filterTimeout = setTimeout(() => {
        clearTimeout(this.filterTimeout);
        return axios.get(languageSearchApiUrl({ term: input }))
          .then(response => (
            resolve(response.data)
          ))
          .catch((e) => {
            logger.error(e);
            resolve([]);
          });
      }, 500);
    });
  }

  render() {
    const { input: { value } } = this.props;
    return (
      <div>
        <AsyncSelect
          styles={REACT_SELECT_STYLES}
          menuPlacement="auto"
          menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
          instanceId="languages"
          name="languages-multiselect"
          classNamePrefix="react-select"
          noOptionsMessage={(data) => {
            if (data.inputValue === '') {
              return 'Start typing...';
            }
            return 'No result found';
          }}
          value={value}
          onChange={this.onChange}
          isMulti
          cacheOptions={false}
          loadOptions={this.getOptions}
          getOptionValue={opt => opt.id}
        />
      </div>
    );
  }
}

LanguageMultiselect.propTypes = {
  input: PropTypes.object.isRequired,
};

export default LanguageMultiselect;
