import PropTypes from 'prop-types';
import React from 'react';

import { ICON } from 'core/assets/js/constants';
import { downloadFileApiUrl } from 'files/urls';

const UserGroupAvatar = ({ avatarFileId, iconClassName, isDynamic, name }) => {
  const iconClassNames = [isDynamic ? ICON.USER_TAG_DUOTONE : ICON.USERS_DUOTONE];
  if (iconClassName) {
    iconClassNames.push(iconClassName);
  }
  return (
    <>
      {avatarFileId && (
        <img
          alt={name}
          className="avatar"
          src={downloadFileApiUrl(avatarFileId)}
        />
      )}
      {!avatarFileId && <i className={iconClassNames.join(' ')} />}
    </>
  );
};

UserGroupAvatar.propTypes = {
  avatarFileId: PropTypes.number,
  iconClassName: PropTypes.string,
  isDynamic: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

UserGroupAvatar.defaultProps = {
  avatarFileId: null,
  iconClassName: 'fa-3x',
};

export default UserGroupAvatar;
