import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const ProposeTaskRateButton = ({ onClick, label, asDropdownItem }) => {
  if (asDropdownItem) {
    return (
      <Dropdown.Item onClick={onClick}>
        {label}
      </Dropdown.Item>
    );
  }

  return (
    <TDButton
      onClick={onClick}
      variant={BS_STYLE.DANGER}
      label={label}
    />
  );
};

ProposeTaskRateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  asDropdownItem: PropTypes.bool,
};

ProposeTaskRateButton.defaultProps = {
  asDropdownItem: false,
};

export default ProposeTaskRateButton;
