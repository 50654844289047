import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import queryString from 'query-string';

import {
  selectActiveOrg, selectDistinctOrgPaymentMethods, selectUserCards,
} from 'organizations/assets/js/reducers/organizations';
import {
  EXTENDED_PAYMENT_METHODS,
  EXTENDED_PAYMENT_METHOD_DETAILS,
  SETTINGS_PAYMENTS_TABS,
} from 'settings/assets/js/constants';
import { paymentMethodCreateUrls, settingsPaymentsSubPageUrl } from 'settings/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import WiseBanner from 'settings/assets/js/components/WiseBanner.jsx';
import { getPaymentMethodFeeMsg, isChargedForProviderFees } from 'finance/assets/js/lib/utils';
import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import PaymentMethodSelectCard from 'settings/assets/js/components/PaymentMethodSelectCard.jsx';
import { fetchOrgListDS } from 'organizations/assets/js/data-services/list';
import { orgGetStartedUrl } from 'accounts/urls';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';

const CreatePaymentMethodSelectView = ({
  activeOrg,
  history,
  paymentMethods,
  userCards,
}) => {
  const listOptions = Object.values(EXTENDED_PAYMENT_METHOD_DETAILS);
  const filteredOptions = listOptions.filter(availableOption => (
    paymentMethods.includes(availableOption.methodId)
  ));
  const [selectedMethod, setSelectedMethod] = useState(listOptions[0]);
  const breadcrumbs = [
    {
      title: 'Payments',
      url: settingsPaymentsSubPageUrl(activeOrg.alias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS),
    },
    {
      title: 'Select how you would like to be paid...',
      url: null,
    },
  ];

  const onItemChecked = (item) => {
    setSelectedMethod(item);
  };

  const showProviderFeesMsg = isChargedForProviderFees(userCards);

  if (showProviderFeesMsg) {
    filteredOptions.forEach((option) => {
      Object.assign(option, {
        extraInfo: getPaymentMethodFeeMsg(option.id),
      });
    });
  }

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--finance page--finance_payment-methods">
        <div className="container">
          <Card className="mb-4">
            <Card.Body>
              <CheckableList
                defaultValue={EXTENDED_PAYMENT_METHOD_DETAILS[EXTENDED_PAYMENT_METHODS.WISE]}
                items={filteredOptions}
                itemComponent={PaymentMethodSelectCard}
                itemComponentProps={{}}
                onItemChecked={onItemChecked}
              />
            </Card.Body>
          </Card>

          <div className="button-container">
            <div className="text-right">
              <TDButton
                variant={BS_STYLE.DEFAULT}
                onClick={() => {
                  if (history?.location?.state?.fromGetStarted) {
                    history.push({
                      pathname: orgGetStartedUrl(activeOrg.alias),
                      state: { moveToNextStep: true },
                    });
                    return;
                  }
                  history.push(settingsPaymentsSubPageUrl(
                    activeOrg.alias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
                  ));
                }}
                label="Cancel"
              />

              <TDButton
                data-testid="payment-method-button-select"
                variant={BS_STYLE.PRIMARY}
                onClick={() => (
                  selectedMethod && history.push({
                    pathname: paymentMethodCreateUrls[selectedMethod.methodId](
                      activeOrg.alias,
                    ),
                    search: queryString.stringify({ ...selectedMethod?.queryParams || {} }),
                    state: { fromGetStarted: true },
                  })
                )}
                disabled={!selectedMethod}
                label="Select"
              />
            </div>
          </div>
          <WiseBanner />
        </div>
      </div>
    </React.Fragment>
  );
};

CreatePaymentMethodSelectView.propTypes = {
  activeOrg: orgSpec.isRequired,
  history: routerHistorySpec.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.number).isRequired,
  userCards: PropTypes.arrayOf(userCardSpec).isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  paymentMethods: selectDistinctOrgPaymentMethods(state),
  userCards: selectUserCards(state),
});

const CreatePaymentMethodSelectViewConnected = connect(
  mapStateToProps,
)(CreatePaymentMethodSelectView);

// We need to make sure organizations are loaded and not use session.authStore.orgs, which may
// have out of date payment methods
const CreatePaymentMethodSelectViewConnectedApiConnected = withTDApiConnected({
  duck: 'list',
  fetchData: ({ dispatch }) => dispatch(fetchOrgListDS({ cached: false })),
  storeKey: 'CreatePaymentMethodSelectView',
})(CreatePaymentMethodSelectViewConnected);

export default withRouter(CreatePaymentMethodSelectViewConnectedApiConnected);
