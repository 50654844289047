import React, { useState } from 'react';
import TDLiveUpdated from 'core/assets/js/components/TDLiveUpdated.jsx';

const RealTimeSection = () => {
  const [messages, setMessages] = useState([]);

  const addMessage = message => (
    setMessages(previousMessages => [...previousMessages, message])
  );

  const channel = 'my-channel';
  const events = {
    'my-event': (data) => {
      const msg = typeof data === 'string' ? data : JSON.stringify(data);
      addMessage(msg);
    },
  };

  const onConnected = () => {
    addMessage('Connected to pusher');
  };

  const onDisonnected = () => {
    addMessage('Disconnected from pusher');
  };

  const onLimitReached = () => {
    addMessage('Bummer, reached the quota');
  };

  const onError = () => {
    addMessage('ERROR!');
  };

  return (
    <TDLiveUpdated
      channel={channel}
      events={events}
      onConnected={onConnected}
      onDisonnected={onDisonnected}
      onLimitReached={onLimitReached}
      onError={onError}
    >
      <ul>
        {messages.map((message, idx) => (
          <li key={`message-${idx}`}>
            {message}
          </li>
        ))}
      </ul>
    </TDLiveUpdated>
  );
};

export default RealTimeSection;
