import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import DocumentForm from 'documents/assets/js/components/DocumentForm.jsx';
import { DOCUMENT_DETAILS_PROPERTIES } from 'documents/assets/js/constants';
import { createDocumentDS, publishDocumentDS } from 'documents/assets/js/data-services/form';
import DocumentListView from 'documents/assets/js/DocumentListView.jsx';
import { parseDocumentValues } from 'documents/assets/js/lib/utils';
import { documentsUrl } from 'documents/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const DocumentCreateView = ({ activeOrg, dispatch, hasOrgAccess, history }) => {
  const canCreateDocument = hasOrgAccess({
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  });
  const [savingDraft, setSavingDraft] = useState(false);

  if (!canCreateDocument) {
    return (
      <div className="text-center p-5 mt-5">You cannot create documents</div>
    );
  }

  const saveDraft = async (formValues, setPageNumber, { alsoPublishing = false } = {}) => {
    setSavingDraft(true);
    try {
      // omit any form values used for controlling the UI, but not needed by the API
      const values = parseDocumentValues(formValues);
      const listComponentName = DocumentListView.GetComponentName();
      const response = await dispatch(createDocumentDS({
        listComponentName, orgAlias: activeOrg.alias, values,
      }));
      if (alsoPublishing) {
        await dispatch(publishDocumentDS({
          id: response.id, listComponentName, orgAlias: activeOrg.alias,
        }));
        dispatch(modalCloseAC());
      }
      toastr.success(
        'Well Done!', `Your contract template was ${alsoPublishing ? 'published' : 'created'} successfully.`,
      );
      history.push(documentsUrl(activeOrg.alias));
    } catch (error) {
      let errorMessage = error?.errors?._error || error.message;
      const fieldErrors = Object.entries(omit(error?.errors || {}, '_error', '_meta'));
      if (fieldErrors.length > 0) {
        errorMessage = fieldErrors.map(([key, value]) => `${key}: ${value}`).join('; ');
      }
      toastr.error('Oh Snap!', errorMessage);
      if (
        error.errors?._meta?.isValidation
        && Object.keys(error.errors).some(key => DOCUMENT_DETAILS_PROPERTIES.includes(key))
      ) {
        // Redirect to Details page
        setPageNumber(0);
      }
      if (alsoPublishing) {
        dispatch(modalCloseAC());
      }
    } finally {
      setSavingDraft(false);
    }
  };

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Contract Templates', url: documentsUrl(activeOrg.alias) },
          { title: 'Create contract template', url: null },
        ]}
      />
      <div className="page page--documents">
        <div className="container">
          <DocumentForm
            initialValues={{
              countersignersCount: 1,
              currency: activeOrg.currency,
              hasCountersigners: { isYes: false },
              isCritical: false,
              isRenewable: false,
              version: '1',
            }}
            onSubmit={(formValues, setPageNumber) => saveDraft(
              formValues, setPageNumber, { alsoPublishing: true },
            )}
            secondarySubmitAction={saveDraft}
            secondarySubmitButtonTitle="Save draft"
            secondarySubmitLoading={savingDraft}
          />
        </div>
      </div>
    </>
  );
};

DocumentCreateView.GetComponentName = () => 'DocumentCreateView';

DocumentCreateView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  hasOrgAccess: getHasOrgAccess(state),
});

const DocumentCreateViewConnected = connect(
  mapStateToProps,
)(DocumentCreateView);

export default withRouter(DocumentCreateViewConnected);
