import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';

import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import withField from 'core/assets/js/components/withField.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import { ICON, BS_SIZE, BS_STYLE } from 'core/assets/js/constants';


const DragHandle = SortableHandle(() => (
  <span className="template-builder__drag-handle px-3 mr-1">
    <span className={ICON.DRAG_HANDLE} />
  </span>
));

const SortableList = SortableContainer(({ fields }) => {
  return (
    <ul className="interview-builder__questions-list template-builder__choices-list py-0">
      {fields.map((name, index) => {
        const data = fields.value && fields.value[index];
        return (
          <SortableItem
            key={name}
            data={data}
            name={name}
            index={index}
            idx={index}
            fields={fields}
          />
        );
      })}
    </ul>
  );
});

const SortableItem = SortableElement(({
  data: { text }, fields, name, idx,
}) => {
  const index = idx;

  return (
    <div key={name} className="interview-builder__question template-builder__choice p-0 m-0 mb-2">
      <i
        className={` ${ICON.CROSS} template-builder__question-remove`}
        onClick={() => fields.remove(index)}
      />

      <div className="template-builder__choice">
        <DragHandle />
        <MarkdownText
          text={text}
          disallowedTypes={['paragraph']}
        />
      </div>
    </div>
  );
});

const MultitextField = ({
  className,
  input,
  label,
  maxLength,
  meta: { error, submitError, touched },
  onAutoIncrementUpdate,
  required,
  sublabel,
}) => {
  const [newLineValue, setNewLineValue] = useState('');
  const inputField = useRef(null);
  const [autoIncrementCount, setAutoIncrementCount] = useState(0);
  const hasError = touched && (error || submitError);
  const classNames = ['form-group'];

  if (className) {
    classNames.push(className);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  const handleMakeLink = async () => {
    const linkRegExp = /\[([^\]]+)\]\(([^)]+)\)/g;
    const { value: text, selectionStart: start, selectionEnd: end } = inputField.current;

    let selectedText = text.slice(start, end);
    let urlInserted = 'url';
    let highlightStart = null;
    let highlightEnd = null;
    let textWithLink = `[label](${urlInserted})`;

    if (selectedText) {
      const containsLink = selectedText.match(linkRegExp);

      if (containsLink) {
        urlInserted = selectedText.replace(linkRegExp, '$2');
        selectedText = selectedText.replace(linkRegExp, '$1');
      }

      const firstPart = `${text.slice(0, start).trim()} [${selectedText}](`.trim();
      const secondPart = `) ${text.slice(end).trim()}`.trim();

      highlightStart = firstPart.length;
      highlightEnd = highlightStart + urlInserted.length;

      textWithLink = `${firstPart}${urlInserted}${secondPart}`;
    } else {
      textWithLink = text.endsWith(textWithLink) ? text : `${text.trim()} ${textWithLink}`.trim();
      highlightStart = textWithLink.indexOf(urlInserted);
      highlightEnd = highlightStart + urlInserted.length;
    }
    inputField.current.value = textWithLink.trim();

    if (highlightStart !== highlightEnd) {
      inputField.current.selectionStart = highlightStart;
      inputField.current.selectionEnd = highlightEnd;
      inputField.current.focus();
    }
  };

  return (
    <div className={classNames.join(' ')}>
      <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />

      <FieldArray name={input.name}>
        {({ fields }) => {
          const onSortEnd = ({ oldIndex, newIndex }) => {
            // Copy the array and swap the two items
            const newValues = input.value.slice(0);
            const oldItem = newValues[newIndex];
            newValues[newIndex] = newValues[oldIndex];
            newValues[oldIndex] = oldItem;
            // Loop through the new array and update the values
            newValues.forEach((_, index) => {
              // Reset the value, so the new order is saved
              newValues[index].value = index + 1;
              fields.update(index, newValues[index]);
            });
          };

          return (
            <div className="col-12 col-md-10 col-lg-8 col-xl-6 px-0">
              <div>
                {fields.length === 0 && (
                  <p className="discreet">No choices added yet</p>
                )}


                <SortableList
                  fields={fields}
                  onSortEnd={onSortEnd}
                  useDragHandle
                />
              </div>

              {hasError && <span className="help-block d-inline-block mt-3">{submitError || error}</span>}

              <div className="border-top pt-3 mt-3">
                <form
                  onSubmit={(e) => {
                    e.stopPropagation();
                  }}
                  className="form-inline"
                >
                  <div className="template-builder__new-choice-line">
                    <div className="w-100 pr-3 position-relative">
                      <input
                        className="form-control"
                        maxLength={maxLength}
                        name="newLine"
                        onChange={e => setNewLineValue(e.target.value)}
                        placeholder="Add new choice..."
                        ref={inputField}
                        type="text"
                        value={newLineValue}
                      />

                      <TDButton
                        variant={BS_STYLE.DEFAULT}
                        bsSize={BS_SIZE.XSMALL}
                        className="text-uppercase add-link"
                        onClick={handleMakeLink}
                        label="Link"
                      />
                    </div>

                    <TDButton
                      type="submit"
                      disabled={newLineValue === ''}
                      variant={BS_STYLE.PRIMARY}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let newValue = fields.length;
                        if (fields.value && fields.value.length > 0) {
                          const currentValues = fields.value.map(v => v.value)
                            .sort((a, b) => a - b);
                          newValue = currentValues.pop() + 1;
                        }
                        fields.push({ value: newValue, text: newLineValue });
                        const newAutoIncrement = autoIncrementCount + 1;
                        setAutoIncrementCount(newAutoIncrement);
                        onAutoIncrementUpdate(newAutoIncrement);
                        setNewLineValue('');
                      }}
                      label="Add"
                    />
                  </div>
                </form>
              </div>
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

MultitextField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  maxLength: PropTypes.number,
  meta: finalFormFieldMetaSpec,
  onAutoIncrementUpdate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
  type: PropTypes.string,
};

MultitextField.defaultProps = {
  className: null,
  disabled: false,
  label: '',
  maxLength: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  placeholder: '',
  prefix: null,
  required: false,
  sublabel: null,
  suffix: null,
  type: 'text',
};


export default withField(MultitextField);
