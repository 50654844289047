import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { financeInvoiceViewUrl } from 'finance/urls';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { invoiceSpec } from 'finance/assets/js/lib/objectSpecs';

const InvoiceItemActions = ({ history, orgAlias, invoice }) => (
  <TDDropButton stopPropagation>
    <Dropdown.Item
      eventKey="view-invoice"
      data-testid="view-invoice"
      onClick={e => {
        e.stopPropagation();
        history.push(financeInvoiceViewUrl(orgAlias, invoice.id));
      }}
    >
      View invoice
    </Dropdown.Item>
  </TDDropButton>
);

InvoiceItemActions.propTypes = {
  history: routerHistorySpec.isRequired,
  invoice: invoiceSpec,
  orgAlias: PropTypes.string.isRequired,
};

InvoiceItemActions.defaultProps = {
  invoice: {},
};

export default withRouter(InvoiceItemActions);
