import OffCanvas from 'react-aria-offcanvas';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import ChatTDLive from 'accounts/assets/js/ChatTDLive.jsx';
import GuestChatHeader from 'accounts/assets/js/components/GuestChatHeader.jsx';
import GuestChatModal from 'accounts/assets/js/components/GuestChatModal.jsx';
import MobileGuestChatNavbar from 'accounts/assets/js/components/MobileGuestChatNavbar.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import Storage from 'core/assets/js/lib/Storage';
import { GUEST_CHAT_MODAL } from 'accounts/assets/js/constants';
import {
  DATETIME_FORMAT_HUMAN_FRIENDLY,
  IMG_SIZE,
} from 'core/assets/js/constants';
import {
  getListState,
  getListStateExtras,
  listFetchAC,
  listFetchExtrasAC,
  listPrependItemsAC,
  listUpdateItemAC,
} from 'core/assets/js/ducks/list';
import {
  getIsModalOpen,
  modalCloseAC,
  modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import Content from 'core/assets/js/layout/placeholder/Content.jsx';
import Root from 'core/assets/js/layout/placeholder/Root.jsx';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { extractPaginationFromHeaders, formatDate } from 'core/assets/js/lib/utils';
import { postGuestChatMessage, updateGuestChatRead } from 'people/assets/js/data-services/form';
import { getGuestChatMessagesUrl, getGuestChatsUrl, getGuestChatUrl } from 'people/urls';
import NotFoundView from 'core/assets/js/NotFoundView.jsx';
import { isScrolledToTheTop } from 'people/assets/js/lib/utils';

const getSorted = (arrayToSort, objKey) => {
  const sortedArray = [...arrayToSort];
  sortedArray.sort(
    (d1, d2) =>
      new Date(d2[objKey]).getTime() - new Date(d1[objKey]).getTime(),
  );
  return sortedArray;
};

const GuestChat = ({
  chatRooms,
  dispatch,
  isModalOpen,
  ownerEmail,
  params,
  pagination,
  messagesPagination,
}) => {
  const listInnerRef = useRef();
  const chatListInnerRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [message, setMessage] = useState();
  const [modalHasBeenShown, setModalHasBeenShown] = useState();
  const [newChatId, setNewChatId] = useState();
  const [newMessage, setNewMessage] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [selectedChat, setSelectedChat] = useState();

  // Using only the first 5 chars of the token to reduce local storage size
  const newChatPopupLocalStorageKey = `new-chat-modal-shown-${params?.token.slice(0, 5)}`;

  const toggleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const handleClose = () => {
    setIsMenuOpened(false);
  };

  const getMessages = (paginationReset = false) => {
    if (!params?.token || !selectedChat?.id) {
      return;
    }
    dispatch(
      fetchDataDS({
        fetchApiUrl: () =>
          getGuestChatMessagesUrl(params.token, selectedChat.id),
        fetchDataAC: (responseData) => {
          setIsLoaded(true);
          const messages = [
            ...(selectedChat?.chatMessages?.length
              ? selectedChat.chatMessages
              : []),
            ...responseData.chatMessages,
          ];
          setSelectedChat({
            ...selectedChat,
            chatMessages: paginationReset
              ? responseData.chatMessages
              : messages,
          });
          return listUpdateItemAC(
            selectedChat.id,
            {
              ...selectedChat,
              chatMessages: paginationReset
                ? responseData.chatMessages
                : messages,
            },
            GuestChat.GetComponentName(),
          );
        },
        paginationAC: (headers) =>
          listFetchExtrasAC(
            extractPaginationFromHeaders(headers),
            GuestChat.GetComponentName(),
            'messagesPagination',
          ),
        querystring:
          !paginationReset && messagesPagination?.next
            ? messagesPagination?.next
            : '',
      }),
    );
  };

  useEffect(() => {
    if (!isModalOpen && isLoaded && modalHasBeenShown === false) {
      dispatch(modalOpenAC(GUEST_CHAT_MODAL));
    }
  }, [isLoaded, modalHasBeenShown]);

  useEffect(() => {
    if (chatRooms?.length && !selectedChat?.id) {
      const sorted = getSorted(chatRooms, 'updatedAt');
      setSelectedChat(sorted[0]);
    }
  }, [chatRooms]);

  // Check if guest modal popup was shown in the past
  useEffect(() => {
    const newChatModalShown = !!Storage.get(newChatPopupLocalStorageKey);
    setModalHasBeenShown(newChatModalShown);
    if (!newChatModalShown) {
      Storage.set(newChatPopupLocalStorageKey, true);
    }
  }, []);

  const getChatByEmails = () => {
    dispatch(
      fetchDataDS({
        fetchApiUrl: () => getGuestChatsUrl(params.token),
        fetchDataAC: (responseData) => {
          const updatedChatRooms = [
            ...(chatRooms?.length ? chatRooms : []),
            ...responseData.chatRooms,
          ];
          return [
            listFetchAC(updatedChatRooms, GuestChat.GetComponentName()),
            listFetchExtrasAC(
              responseData.guestEmail,
              GuestChat.GetComponentName(),
              'guestEmail',
            ),
          ];
        },
        fetchDataErrorAC: (err) => {
          if (err?.response?.status === 404) {
            setNotFound(true);
          } else {
            toastr.error(err?.message);
          }
        },
        querystring: pagination?.next ? pagination?.next : '',
      }),
    );
  };

  useEffect(() => {
    if (params?.token) {
      getChatByEmails();
    }
  }, [params?.token]);

  useEffect(() => {
    if (selectedChat?.id) {
      dispatch(
        listFetchExtrasAC(
          null,
          GuestChat.GetComponentName(),
          'messagesPagination',
        ),
      );
      getMessages(true);
    }
  }, [selectedChat?.id]);

  const getChatRoomMessages = () => {
    if (!Array.isArray(selectedChat?.chatMessages)) {
      return null;
    }
    const currentUser = selectedChat?.chatRoomUsers?.find(
      (c) => c.userEmail === ownerEmail,
    );
    return selectedChat?.chatMessages?.map((chatRoomMessage) => {
      if (chatRoomMessage.chatRoomUserId === currentUser?.id) {
        return (
          <div
            className="d-flex text-right justify-content-end mt-3"
            key={chatRoomMessage?.id}
          >
            <div>
              <p className="mb-1">
                <small>
                  {formatDate(
                    chatRoomMessage.createdAt,
                    DATETIME_FORMAT_HUMAN_FRIENDLY,
                  )}
                </small>
                <strong>{`  ${currentUser?.name}`}</strong>
              </p>
              <div className="chat__box-message p-3">
                {chatRoomMessage.message}
              </div>
            </div>
            <ProfilePic
              data-testid="contact-details-panel-profile-pic"
              url={currentUser?.avatar}
              alt={chatRoomMessage.id}
              size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
              className="mx-3"
            />
          </div>
        );
      }
      const user = selectedChat?.chatRoomUsers?.find(
        (c) => c.id === chatRoomMessage.chatRoomUserId,
      );
      return (
        <div className="d-flex mt-3" key={chatRoomMessage?.id}>
          <ProfilePic
            data-testid="contact-details-panel-profile-pic"
            url={user?.avatar}
            alt={chatRoomMessage.id}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
            className="mx-3"
          />
          <div>
            <p className="mb-1">
              <strong>{`${user?.name}  `}</strong>
              <small>
                {formatDate(
                  chatRoomMessage.createdAt,
                  DATETIME_FORMAT_HUMAN_FRIENDLY,
                )}
              </small>
            </p>
            <div className="chat__box-message p-3">
              {chatRoomMessage.message}
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (!newMessage?.chatRoomMessage) {
      return;
    }
    const chatRoom = chatRooms.find(
      (c) => c.id === newMessage.chatRoomMessage.chatRoomId,
    );
    if (
      !chatRoom?.chatMessages?.some(
        (m) => m.id === newMessage?.chatRoomMessage?.id,
      )
    ) {
      const messages = [newMessage.chatRoomMessage, ...chatRoom.chatMessages ?? []];
      const sortedMessages = getSorted(messages, 'createdAt');
      if (selectedChat.id === chatRoom.id) {
        setSelectedChat({
          ...selectedChat,
          chatMessages: sortedMessages,
          unreadCount: selectedChat.unreadCount ? selectedChat.unreadCount + 1 : 1,
        });
        dispatch(
          listUpdateItemAC(
            selectedChat.id,
            {
              ...selectedChat,
              chatMessages: sortedMessages,
              updatedAt: moment().format(),
              unreadCount: selectedChat.unreadCount ? selectedChat.unreadCount + 1 : 1,
            },
            GuestChat.GetComponentName(),
          ),
        );
      }

      setNewMessage();
    }
  }, [newMessage]);

  useEffect(() => {
    if (newChatId && selectedChat.id !== newChatId) {
      dispatch(
        fetchDataDS({
          fetchApiUrl: () =>
            getGuestChatUrl(params.token, newChatId),
          fetchDataAC: (responseData) => {
            if (chatRooms.find(c => c.id === newChatId)) {
              return listUpdateItemAC(newChatId, responseData,
                GuestChat.GetComponentName());
            }
            return listPrependItemsAC(
              [responseData],
              GuestChat.GetComponentName(),
            );

          },
        }));
    }
    setNewChatId(null);
  }, [newChatId]);

  const getEvents = () => {
    const chatEvents = {
      'event-chat-change': (data) => {
        return setNewChatId(data.chatRoomId);
      },
    };
    if (chatRooms?.length) {
      const existingChats = chatRooms.reduce((acc, cur) => {
        if (cur?.id) {
          return {
            ...acc,
            [`event-chat-add-${cur.id}`]: setNewMessage,
          };
        }
        return acc;
      }, {});
      return { ...chatEvents, ...existingChats };
    }
    return chatEvents;
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isScrolled = isScrolledToTheTop(clientHeight, scrollHeight, scrollTop);
      if (isScrolled && messagesPagination?.next) {
        getMessages();
      }
    }
  };

  const onChatListScroll = () => {
    if (chatListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatListInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && pagination?.next) {
        getChatByEmails();
      }
    }
  };

  const handleMessageSubmit = async (e) => {
    if (!(message && e.key === 'Enter')) {
      return;
    }
    dispatch(
      postGuestChatMessage({
        values: {
          message,
          chatId: selectedChat.id,
          senderEmail: ownerEmail,
        },
        pushDataAC: (responseData) => {
          const messages = [
            responseData.chatRoomMessage,
            ...selectedChat.chatMessages,
          ];
          setSelectedChat({
            ...selectedChat,
            chatMessages: messages,
          });
          return listUpdateItemAC(
            selectedChat.id,
            {
              ...selectedChat,
              chatMessages: messages,
            },
            GuestChat.GetComponentName(),
          );
        },
        token: params.token,
      }),
    );
    setMessage('');
  };

  const getChatRooms = () => {
    if (!chatRooms?.length) {
      return [];
    }
    return getSorted(chatRooms, 'updatedAt')?.map((chat) => {
      return (
        <li
          className={`navbar__menuItem ${
            selectedChat?.id === chat.id
              ? 'navbar__menuItem--state-active navbar__menuItem--state-active-dark'
              : ''
          }`}
          key={chat.id.toString()}
          onClick={() => {
            setSelectedChat(chat);
            handleClose();
          }}
        >
          <a className="navbar__sectionItem navbar__menuItem--state-active p-4 d-flex align-items-center">
            <ProfilePic
              alt={chat?.organization.name}
              className="mr-4"
              size={[40, 40]}
              url={chat?.organization?.logo}
            />
            <span className="truncate">
              {chat?.organization?.name}
            </span>
          </a>
          {chat?.unreadCount > 0 && (
            <span className="badge text-center">{chat?.unreadCount}</span>
          )}
        </li>
      );
    });
  };

  const handleRead = () => {
    if (!selectedChat?.chatMessages?.length || selectedChat?.unreadCount <= 0) {
      return null;
    }
    dispatch(
      updateGuestChatRead({
        chatId: selectedChat.id,
        token: params?.token,
        values: { messageId: selectedChat?.chatMessages[0].id },
        pushDataAC: () => {
          setSelectedChat({
            ...selectedChat,
            unreadCount: 0,
          });
          return listUpdateItemAC(
            selectedChat.id,
            {
              ...selectedChat,
              unreadCount: 0,
            },
            GuestChat.GetComponentName(),
          );
        },
      }),
    );
    return null;
  };

  if (notFound) {
    return <NotFoundView />;
  }

  const ownerUser = selectedChat?.chatRoomUsers?.find(
    (u) => u.userEmail !== ownerEmail,
  );
  return (
    <ChatTDLive authorization={false} email={ownerEmail} events={getEvents()}>
      <div
        className={['off-canvas', isMenuOpened ? 'off-canvas--on' : ''].join(
          ' ',
        )}
      >
        <div
          role="none"
          className={`off-canvas-backdrop${
            isMenuOpened ? ' off-canvas-backdrop--on' : ''
          }`}
          onClick={toggleMenu}
        />
        <GuestChatHeader isMenuOpened={isMenuOpened} onMenuClick={toggleMenu} />
        <Root flexContainer>
          <div className="navbar-col no-select">
            <ul
              className="chat__navbar"
              onScroll={onChatListScroll}
              ref={chatListInnerRef}
            >
              {getChatRooms()}
            </ul>
          </div>
          <Content isColFlexContainer>
            <div className="page pb-0 chat__page">
              <div className="container">
                <Card className="chat__box d-flex flex-row">
                  <div className="chat__logo-container">
                    <ProfilePic
                      url={ownerUser?.avatar}
                      alt={ownerUser?.name}
                      size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                      className="m-3"
                    />
                  </div>

                  <div className="w-100">
                    <Card.Header>
                      {ownerUser?.name}
                      {' '}
                      <label className="chat__header-label pb-0 mb-0">
                        &bull;
                        {' '}
                        {selectedChat?.organization?.name}
                        {' '}
                        {selectedChat?.organization?.website && (
                          <>
                            &bull;
                            {' '}
                            <a
                              target="_blank"
                              className="pb-0 mb-0"
                              rel="noopener noreferrer"
                              href={selectedChat.organization.website}
                            >
                              {selectedChat?.organization?.website}
                            </a>
                          </>
                        )}
                        {' '}
                        &bull;
                        {' '}
                        <span className="chat__header-label-muted pb-0 mb-0">
                          {selectedChat?.organization?.country}
                        </span>
                      </label>
                    </Card.Header>
                    <Card.Body
                      className="chat__box-body overflow-auto pt-4 d-flex flex-column-reverse"
                      onScroll={onScroll}
                      ref={listInnerRef}
                    >
                      {getChatRoomMessages()}
                    </Card.Body>
                    <Card.Footer className="chat__footer p-4">
                      <div className="form-group mb-0">
                        <input
                          data-testid="message-input"
                          type="text"
                          className="form-control"
                          placeholder="Start typing your message..."
                          onKeyDown={handleMessageSubmit}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          onFocus={handleRead}
                        />
                      </div>
                    </Card.Footer>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Root>
      </div>

      <OffCanvas
        overlayClassName="off-canvas-overlay"
        width="320px"
        height="100%"
        isOpen={isMenuOpened}
        position="left"
        className="off-canvas-menu-container"
        onClose={handleClose}
      >
        <MobileGuestChatNavbar
          chatRooms={getChatRooms()}
          handleClose={handleClose}
          isMenuOpened={isMenuOpened}
        />
      </OffCanvas>

      <GuestChatModal
        handleClose={() => dispatch(modalCloseAC())}
        show={isModalOpen}
      />
    </ChatTDLive>
  );
};

GuestChat.GetComponentName = () => 'GuestChat';

GuestChat.propTypes = {
  chatRooms: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  messagesPagination: PropTypes.object,
  isModalOpen: PropTypes.bool,
  ownerEmail: PropTypes.string,
  pagination: PropTypes.object,
  params: PropTypes.object,
};

GuestChat.defaultProps = {
  chatRooms: [],
  messagesPagination: {},
  isModalOpen: false,
  ownerEmail: '',
  pagination: {},
  params: {},
};

const mapStateToProps = (state, props) => {
  const componentName = GuestChat.GetComponentName();
  const listState = getListState(state, componentName);
  const ownerEmail = getListStateExtras(
    state,
    componentName,
    'guestEmail',
  );
  const messagesPagination = getListStateExtras(
    state,
    componentName,
    'messagesPagination',
  );
  return {
    isModalOpen: getIsModalOpen(state, GUEST_CHAT_MODAL),
    params: props.match.params,
    pagination: listState.pagination,
    chatRooms: listState.items,
    ownerEmail,
    messagesPagination,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GuestChat),
);
