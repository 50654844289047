import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { orgSkillsApiUrl } from 'settings/urls';
import OrgSkillModalForm, { ORG_SKILL_MODAL_ID } from 'settings/assets/js/components/OrgSkillModalForm.jsx';
import OrgSkillDeleteConfirmModal, { ORG_SKILL_DELETE_ID } from 'settings/assets/js/components/OrgSkillDeleteConfirmModal.jsx';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import CustomSkillList from 'core/assets/js/components/CustomSkillList.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { modalOpenAC, modalCloseAC, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { createOrgSkillDS, updateOrgSkillDS, deleteOrgSkillDS } from 'settings/assets/js/data-services/settings';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import OrgSkillListSkeleton from 'core/assets/js/components/Skeleton/OrgSkillListSkeleton.jsx';

class OrgSkillsList extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    return dispatch(fetchListDS({
      url: orgSkillsApiUrl(params.orgAlias, url),
      querystring,
      componentName,
      authedAxios,
    }));
  }

  static GetComponentName() {
    return 'OrgSkillsList';
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async handleSubmit({ skill, skillDirectoryId }) {
    const { editingSkill, dispatch, organization: {
      unique_alias: uniqueAlias,
    } } = this.props;
    if (!editingSkill) {
      if (typeof skill.value === 'number') {
        return { skill: `Skill "${skill.label}" already exists` };
      }
      await dispatch(createOrgSkillDS(
        uniqueAlias, {
          ...skill,
          tag: skill.label,
          isMemberSkill: true,
          skillDirectoryId,
        },
      ));
    } else {
      await dispatch(updateOrgSkillDS(
        uniqueAlias, {
          ...skill,
          id: editingSkill.id,
          tag: skill.label,
          isMemberSkill: true,
          skillDirectoryId,
        },
      ));
    }
    dispatch(requestRefreshAC(this.constructor.GetComponentName()));
    dispatch(modalCloseAC());
    return null;
  }

  async handleDelete() {
    const { deletingSkill: { id },
      dispatch,
      organization: {
        unique_alias: uniqueAlias,
      },
    } = this.props;

    await dispatch(deleteOrgSkillDS(
      uniqueAlias, id,
    ));

    dispatch(requestRefreshAC(this.constructor.GetComponentName()));
  }

  render() {
    const { dispatch, skills, deletingSkill } = this.props;

    return (
      <TDApiConnected duck="list" component={this.constructor} skeletonComponent={OrgSkillListSkeleton}>
        <OrgSkillModalForm
          componentName={this.constructor.GetComponentName()}
          onSubmit={this.handleSubmit}
        />
        {deletingSkill && (
          <OrgSkillDeleteConfirmModal
            componentName={this.constructor.GetComponentName()}
            onConfirm={this.handleDelete}
          />
        )}
        <Card className="mt-5">
          <Card.Body>
            { skills?.length === 0 ? (
              <div className="discreet">
                <div className="d-flex flex-column align-items-center justify-content-center empty-list-message p-5">
                  <i className={ICON.PEN_RULER_DUOTONE} />
                  <p className="mb-3">
                    Create your own organization’s custom skills that are not found
                    in our skills library.
                  </p>

                  <TDButton
                    className="mt-3"
                    variant={BS_STYLE.PRIMARY}
                    onClick={() => {
                      dispatch(modalOpenAC(ORG_SKILL_MODAL_ID));
                    }}
                  >
                    Create skills
                  </TDButton>
                </div>
              </div>
            ) : (
              <>
                <p>
                  <i className={`${ICON.INFO_2} text-info mr-3`} />
                  Create your own organization’s custom skills that
                  are not found in our skills library.
                </p>

                <CustomSkillList
                  skills={skills}
                  onSkillDelete={(skill) => dispatch(modalOpenAC(ORG_SKILL_DELETE_ID, skill))}
                />
              </>
            )}
          </Card.Body>
        </Card>
      </TDApiConnected>
    );
  }
}

OrgSkillsList.propTypes = {
  skills: PropTypes.arrayOf(skillSpec),
  organization: PropTypes.object,
  editingSkill: PropTypes.object,
  deletingSkill: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

OrgSkillsList.defaultProps = {
  skills: [],
  organization: {},
  editingSkill: null,
  deletingSkill: null,
};

const mapStateToProps = (state) => {
  const listState = getListState(state, OrgSkillsList.GetComponentName());
  return {
    editingSkill: getModalPayload(state, ORG_SKILL_MODAL_ID),
    deletingSkill: getModalPayload(state, ORG_SKILL_DELETE_ID),
    hasOrgSkills: listState.items.length > 0,
    skills: listState.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgSkillsListConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgSkillsList);

export default OrgSkillsListConnect;
