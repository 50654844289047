import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, Card } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { getBankAccountLegacyBankCode } from 'settings/assets/js/lib/utils';
import {
  orgSettingsPaymentsUrl,
  settingsBankAccountEditUrl,
  settingsPaypalEditUrl,
  settingsPayoneerEditUrl,
  settingsBankAccountOrgSelectUrl, settingsPayoneerStatusUrl,
} from 'settings/urls';
import {
  BANK_ACCOUNT_TYPE, ORG_SETTINGS_PAYMENTS_TABS, PAYMENT_METHOD_DETAILS,
  PAYONEER_BANK_ACCOUNT_STATUS_INFO, BANK_ACCOUNT_STATUS, BANK_ACCOUNT_DECLINED_STATUSES,
} from 'settings/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';

const PaymentMethodCardItem = ({
  history, item: bankAccount, onDelete, orgAlias, isLastItem,
}) => {
  const isPaypalAccount = bankAccount.bank_account_type === BANK_ACCOUNT_TYPE.PAYPAL;
  const isPayoneerAccount = bankAccount.bank_account_type === BANK_ACCOUNT_TYPE.PAYONEER;
  const selectionPaymentMethodDetails = Object.values(PAYMENT_METHOD_DETAILS).find(pm => (
    pm.bankAccountTypeId.includes(bankAccount.bank_account_type)
  ));
  const listItemLabel = bankAccount.alias || `${bankAccount.bank_name} ${bankAccount.country_code && bankAccount.country_code.toUpperCase()}`;
  let editUrl;
  if (isPaypalAccount) {
    editUrl = settingsPaypalEditUrl(orgAlias, bankAccount.id);
  } else if (isPayoneerAccount) {
    editUrl = settingsPayoneerEditUrl(orgAlias, bankAccount.id);
  } else {
    editUrl = settingsBankAccountEditUrl(orgAlias, bankAccount.id);
  }
  const { logo: methodLogo, icon: methodIcon, name: methodName } = Object
    .values(PAYMENT_METHOD_DETAILS)
    .find(pm => pm.bankAccountTypeId.includes(bankAccount.bank_account_type)) || {};
  const organizationsUsingPaymentMethod = Array.isArray(bankAccount.user_cards)
  && !isEmpty(bankAccount.user_cards)
    ? bankAccount.user_cards.map(uc => ({
      alias: uc.organization.unique_alias,
      name: uc.organization.name,
    }))
    : [];
  const isPaymentMethodUsedInOrgs = bankAccount.bank_account_used_in_organization_count >= 1;
  const [isPaymentMethodListingModalOpen, handlePaymentMethodListingModal] = useState(false);
  const showBankAccountOptions = !isPayoneerAccount
    || (isPayoneerAccount && bankAccount.status === BANK_ACCOUNT_STATUS.READY);
  const isPayoneerRejected = BANK_ACCOUNT_DECLINED_STATUSES.includes(bankAccount.status);
  const isPayoneerPendingOrRejected = (BANK_ACCOUNT_STATUS.PENDING === bankAccount.status)
    || isPayoneerRejected;
  return (
    <Card className="card bank-account-card card--light-gray shadow-none">
      <Card.Body>
        <div data-testid="payment-method-bank-account-card" className="row">
          <div className="col-9 d-flex align-items-center">
            <div className="pr-3 d-none d-md-inline-block payment-method-card-item__logo-container">
              { methodLogo && (
                <img width="40px" alt={`${methodName} logo`} src={methodLogo} />
              )}
              { methodIcon && (
                <i className={methodIcon} />
              )}
            </div>

            <div>
              <div data-testid="payment-method-card-title" className="bank-account-card__title">
                {listItemLabel}
              </div>
              {(bankAccount.alias && bankAccount.bank_name) && (
                <div data-testid="payment-method-card-sub-title" className="bank-account-card__sub-title">
                  {bankAccount.bank_name}
                </div>
              )}
              {isPaymentMethodUsedInOrgs && (
                <div data-testid="payment-method-linked-to-organisation" className="mt-1">
                  <div>
                    Used in
                    {' '}
                    <span
                      className="imitate-link"
                      onClick={() => handlePaymentMethodListingModal(true)}
                    >
                      {bankAccount.bank_account_used_in_organization_count}
                      {' '}
                      {pluralize('organisation', bankAccount.bank_account_used_in_organization_count)}
                    </span>
                  </div>
                  <ModalSimple
                    open={isPaymentMethodListingModalOpen}
                    heading={`Where is "${listItemLabel}" used?`}
                    onClose={() => handlePaymentMethodListingModal(false)}
                    body={(
                      <div>
                        <p>
                          This payment method is currently used in
                          {' '}
                          <strong>
                            {bankAccount.bank_account_used_in_organization_count}
                          </strong>
                          {' '}
                          {pluralize('organisation', bankAccount.bank_account_used_in_organization_count)}
                          {' '}
                          on TalentDesk.
                        </p>
                        <p>
                          To review your settings click on any of the organisation names below.
                          You will get redirected to the payment method settings section of this
                          organisation, where you can review your selected payment method.
                        </p>
                        {organizationsUsingPaymentMethod.map(org => (
                          <div className="mb-1" key={org.alias}>
                            <Link
                              className="d-block"
                              to={orgSettingsPaymentsUrl(
                                org.alias, ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
                              )}
                            >
                              {`- ${org.name}`}
                            </Link>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
            {isPayoneerPendingOrRejected && (
              <StatusTag
                statusClass={PAYONEER_BANK_ACCOUNT_STATUS_INFO[bankAccount.status]?.statusClass}
                className="ml-3"
                label={PAYONEER_BANK_ACCOUNT_STATUS_INFO[bankAccount.status]?.statusLabel}
              />
            )}
            <div className="bank-account-card__meta pl-4">
              { bankAccount.account_number || bankAccount.iban ? (
                <React.Fragment>
                  {`${getBankAccountLegacyBankCode(bankAccount)} ${bankAccount.account_number || bankAccount.iban}`}
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-end">
            <TDDropButton
              placement={isLastItem ? 'top-end' : 'bottom-end'}
            >
              { showBankAccountOptions && (
                <React.Fragment>
                  <Dropdown.Item
                    data-testid="payment-method-dropdown-item-edit"
                    onClick={() => history.push(editUrl)}
                    eventKey="1"
                  >
                    Edit
                  </Dropdown.Item>
                  {bankAccount.bank_account_used_in_organization_count === 0 && (
                    <Dropdown.Item
                      data-testid="payment-method-dropdown-item-delete"
                      onClick={() => onDelete(bankAccount)}
                      eventKey="2"
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    data-testid="payment-method-dropdown-item-select-organisation"
                    onClick={() => history.push(settingsBankAccountOrgSelectUrl(
                      orgAlias, selectionPaymentMethodDetails.alias, bankAccount.id,
                    ))}
                    eventKey="3"
                  >
                    Select organisation
                  </Dropdown.Item>
                </React.Fragment>
              )}
              {isPayoneerPendingOrRejected && (
                <>
                  <Dropdown.Item
                    onClick={() => history.push(settingsPayoneerStatusUrl(
                      orgAlias, bankAccount.id,
                    ))}
                    eventKey="4"
                  >
                    Check status
                  </Dropdown.Item>
                  {isPayoneerRejected && (
                    <Dropdown.Item
                      onClick={() => onDelete(bankAccount)}
                      eventKey="5"
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                </>
              )}
            </TDDropButton>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

PaymentMethodCardItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    bank_name: PropTypes.string,
    country_code: PropTypes.string,
    account_number: PropTypes.string,
    iban: PropTypes.string,
    bank_account_used_in_organization_count: PropTypes.number,
    bank_account_type: PropTypes.number,
    alias: PropTypes.string,
    user_cards: PropTypes.array,
    status: PropTypes.number,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  history: routerHistorySpec.isRequired,
  isLastItem: PropTypes.bool.isRequired,
};

PaymentMethodCardItem.defaultProps = {};

export default withRouter(PaymentMethodCardItem);
