import axios from 'core/assets/js/lib/tdAxios';
import { REQ_TYPE } from 'core/assets/js/constants';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  putViewDS, viewFetchAC, viewFetchExtrasAC, viewUpdateAC, viewUpdateKeysAC,
} from 'core/assets/js/ducks/view';
import {
  accountingAccountSettingsApiUrl,
  accountingGetInvoiceSyncPushOperationApiUrl,
  accountingIntegrationSyncApiUrl,
  accountingInvoiceSettingsApiUrl,
  deleteIntegrationApiUrl,
  integrationUpdateSettingsApiUrl,
  orgIntegrationCompleteSetupApiUrl,
  orgIntegrationDetailApiUrl,
  orgIntegrationConfigApiUrl,
  toggleIntegrationApiUrl,
} from 'integrations/urls';

export const fetchIntegrationDS = ({
  orgAlias,
  integrationId,
  componentName,
  authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => orgIntegrationDetailApiUrl(orgAlias, integrationId),
    fetchDataAC: responseData => [
      viewFetchAC(responseData, componentName),
    ],
  })
);

export const fetchIntegrationConfigDS = ({
  orgAlias,
  integrationType,
  componentName,
  authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => orgIntegrationConfigApiUrl(orgAlias, integrationType),
    fetchDataAC: responseData => [
      viewFetchAC(responseData.config, componentName),
      viewFetchExtrasAC(responseData.existing, componentName, 'existing'),
    ],
  })
);

export const removeIntegrationDS = ({ orgAlias, integrationId }) => (
  axios.delete(deleteIntegrationApiUrl(orgAlias, integrationId))
);

export const toggleIntegrationActiveDS = ({ orgAlias, integrationId }) => (
  axios.patch(toggleIntegrationApiUrl(orgAlias, integrationId))
);

export const updateIntegrationSettings = ({
  orgAlias,
  integrationId,
  componentName,
  values,
}) => (
  putViewDS({
    values,
    url: integrationUpdateSettingsApiUrl(orgAlias, integrationId),
    componentName,
  })
);

export const runIntegrationSync = ({
  orgAlias,
  integrationId,
  componentName, // eslint-disable-line no-unused-vars
}) => (
  dispatch => dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    values: {},
    pushApiUrl: accountingIntegrationSyncApiUrl(orgAlias, integrationId),
    pushDataAC: () => ([]),
  }))
);

export const fetchAccountSettingsDS = ({
  authedAxios = null, componentName, integrationId, orgAlias,
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    fetchApiUrl: () => accountingAccountSettingsApiUrl(orgAlias, integrationId),
    fetchDataAC: responseData => [viewFetchAC(responseData, componentName)],
  })
);

export const fetchInvoiceSettingsDS = ({
  orgAlias,
  integrationId,
  componentName,
  querystring,
  authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    querystring,
    fetchApiUrl: () => accountingInvoiceSettingsApiUrl(orgAlias, integrationId),
    fetchDataAC: responseData => [
      viewFetchAC(responseData, componentName),
    ],
  })
);

export const updateInvoiceSettingsDS = ({
  orgAlias,
  integrationId,
  values,
  componentName,
}) => (
  pushDataDS({
    values,
    reqType: REQ_TYPE.PUT,
    pushApiUrl: accountingInvoiceSettingsApiUrl(orgAlias, integrationId),
    pushDataAC: responseData => [
      viewUpdateKeysAC(responseData, ['fields', 'items'], componentName),
    ],
  })
);

export const fetchInvoiceSyncPushOperationDS = ({
  authedAxios, codatSyncJobId, componentName, integrationId, orgAlias,
}) => (
  dispatch => dispatch(
    fetchDataDS({
      authedAxios,
      componentName,
      fetchApiUrl: () => accountingGetInvoiceSyncPushOperationApiUrl(
        orgAlias, integrationId, codatSyncJobId,
      ),
      fetchDataAC: responseData => [viewFetchAC(responseData, componentName)],
    }),
  )
);

export const completeSetupDS = ({ componentName, integrationId, orgAlias }) => (
  pushDataDS({
    pushApiUrl: orgIntegrationCompleteSetupApiUrl(orgAlias, integrationId),
    pushDataAC: responseData => viewUpdateAC(responseData, componentName),
    reqType: REQ_TYPE.POST,
  })
);
