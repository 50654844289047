import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import {
  BS_STYLE,
  CURRENCIES_WITHOUT_DECIMALS,
  DATETIME_FORMAT_ISO,
  DATETIME_FORMAT_HUMAN_FRIENDLY_2,
  ICON,
} from 'core/assets/js/constants';
import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { PROFILE_VIEW_COMPONENT_NAME } from 'people/assets/js/constants';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { updateInvoiceCapApiUrl } from 'people/urls';
import InvoiceCapUsageBar from 'projects/assets/js/components/InvoiceCapUsageBar.jsx';
import { fetchInvoiceCapUsageDS } from 'projects/assets/js/data-services/view';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import { INVOICE_CAPS_PERIOD_SHORT_LABELS } from 'settings/assets/js/constants';
import { formatDate, parseDate } from 'core/assets/js/lib/utils';

const MODAL_ID = 'invoice-cap-modal';

const CAP_TYPE = {
  FIXED: 'fixed',
  QUANTITY: 'quantity',
};

const InvoiceCapWidget = ({ match: { params }, userCard }) => {
  const formRef = useRef(null);

  const activeOrg = useSelector(selectActiveOrg);
  const isModalOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const dispatch = useDispatch();

  const closeModal = async () => {
    dispatch(modalCloseAC());
  };

  const openModal = () => dispatch(modalOpenAC(MODAL_ID));

  useEffect(() => {
    if (isModalOpen && formRef.current) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      formRef.current = null;
    }
  }, [isModalOpen]);

  const {
    allowedActions,
    currencySymbol,
    invoiceCap,
    invoiceCapUpdatedAt,
    userRole,
    rateUpdatedAt,
    rate,
  } = userCard;

  const {
    invoice_caps_allow_raising_beyond_cap: beyondCap,
    invoice_caps_auto_approve_within_cap: autoApproval,
    invoice_caps_rate_change_reminder: rateChangeReminderOrgSettting,
  } = activeOrg;

  if (!allowedActions?.canManageInvoiceCap) {
    return null;
  }

  const capPeriod = INVOICE_CAPS_PERIOD_SHORT_LABELS[activeOrg.invoice_caps_period];

  const defaultRateIsInOrgCurrency = userCard.currency === activeOrg.currency;

  const decimals = CURRENCIES_WITHOUT_DECIMALS.includes(activeOrg.currency) ? 0 : 2;

  const fetchDS = userRole.isProvider ? fetchProviderDS : fetchManagerDS;
  const reloadUser = () => dispatch(fetchDS({
    componentName: PROFILE_VIEW_COMPONENT_NAME, params,
  }));
  const reloadInvoiceCapUsage = () => dispatch(fetchInvoiceCapUsageDS({
    orgAlias: activeOrg.alias, userId: userCard.user.id,
  }));

  const rateUpdatedAtParsed = parseDate(rateUpdatedAt, DATETIME_FORMAT_ISO);
  const invoiceCapUpdatedAtParsed = parseDate(invoiceCapUpdatedAt, DATETIME_FORMAT_ISO);

  const rateUpdatedAtFormated = formatDate(rateUpdatedAt, DATETIME_FORMAT_HUMAN_FRIENDLY_2);
  const invoiceCapUpdatedAtFormated = formatDate(
    invoiceCapUpdatedAt, DATETIME_FORMAT_HUMAN_FRIENDLY_2,
  );

  const rateChangeReminder = (
    invoiceCapUpdatedAtParsed && rateUpdatedAtParsed && rateChangeReminderOrgSettting
  ) ? invoiceCapUpdatedAtParsed.isBefore(rateUpdatedAtParsed)
    : false;

  return (
    <>
      <Card className="mt-4 user-profile__card">
        <Card.Header>Invoice cap</Card.Header>
        <Card.Body className="px-0">
          <div className="tab-content w-100">
            {(rateChangeReminder && rate && invoiceCap) && (
              <TDSystemMessage
                className="mx-4 mb-4"
                title="Review invoice cap"
                type={BS_STYLE.INFO}
              >
                {`The default rate for this provider was set at ${currencySymbol}${rate} on ${rateUpdatedAtFormated}.`}
                {` Their invoice cap is set at ${currencySymbol}${invoiceCap} per billing cycle and was last updated on ${invoiceCapUpdatedAtFormated}.`}
                <br />
                You can update their invoice cap below.
              </TDSystemMessage>
            )}
            <p className="p-4">
              An Invoice Cap is a limit on the total billable amount that this
              {` ${userRole.title} can submit in a ${capPeriod}. `}
              {!autoApproval && (
                <>
                  This Organisation is configured with Hard Caps. This means that the
                  {` ${userRole.title} will be allowed to submit Worksheets or Proforma invoices `}
                  {`until the Cap limit has been reached. Once reached, the ${userRole.title} `}
                  cannot submit any new Worksheets or Proforma invoices until the next Cap Period.
                  All Worksheets and Proforma invoices must be approved by a Manager before being
                  included in their Invoice.
                </>
              )}
              {autoApproval && !beyondCap && (
                <>
                  This Organisation is configured with Hard Caps with Auto Approval. This means the
                  {` ${userRole.title} will be allowed to submit Worksheets or Proforma invoices `}
                  {`until the Cap limit has been reached. Once reached, the ${userRole.title} `}
                  cannot submit any new Worksheets or Proforma invoices until the next Cap Period.
                  All Worksheets will be auto-approved as they are submitted.
                </>
              )}
              {autoApproval && beyondCap && (
                <>
                  This Organisation is configured with Soft Caps, which means that all
                  Worksheets and Proforma invoices that accumulate within the Cap amount will be
                  automatically approved when submitted. Any Worksheets or Proforma invoices that
                  exceed the Cap will need to be manually approved by a Manager to be included in
                  their invoice.
                </>
              )}
            </p>
            <div className="user-profile__light-border-top mx-0 p-4">
              {invoiceCap !== null && (
                <>
                  <div
                    className={[
                      'invoice-cap-widget-overview w-100 px-4 py-5 d-flex align-items-center',
                      'justify-content-between mb-5',
                    ].join(' ')}
                  >
                    <span>{`Maximum billable per ${capPeriod}`}</span>
                    <div>
                      <ElementWithPopOver
                        className="d-inline-block mr-5"
                        element={(
                          <i
                            className={[
                              ICON.ADD_CIRCLE,
                              ' auto-approval-indicator',
                              beyondCap ? '' : ' disabled',
                            ].join('')}
                          />
                        )}
                        popOverContent={(
                          <>
                            {beyondCap && (
                              <p>
                                {`This ${userRole.title} can create worksheets or proforma `}
                                invoices that exceed the cap. Any worksheets or proforma invoices
                                that exceed the invoice cap will need to be manually approved.
                              </p>
                            )}
                            {!beyondCap && (
                              <p>
                                {`This ${userRole.title} will not be able to create worksheets or `}
                                proforma invoices that exceed the invoice cap. If they have done
                                more work, they will need to submit the worksheet in the following
                                period.
                              </p>
                            )}
                          </>
                        )}
                        popOverTitle={`Over-billing ${beyondCap ? 'en' : 'dis'}abled`}
                      />
                      <ElementWithPopOver
                        className="d-inline-block mr-5"
                        element={(
                          <i
                            className={[
                              ICON.CHECKMARK_CIRCLE,
                              ' auto-approval-indicator',
                              autoApproval ? '' : ' disabled',
                            ].join('')}
                          />
                        )}
                        popOverContent={(
                          <>
                            {autoApproval && (
                              <p>
                                Worksheets and proforma invoices within this invoice cap will be
                                auto-approved.
                              </p>
                            )}
                            {!autoApproval && (
                              <p>
                                All worksheets and proforma invoices that are submitted by this
                                {` ${userRole.title} will need to be manually reviewed and `}
                                approved.
                              </p>
                            )}
                          </>
                        )}
                        popOverTitle={`Auto-approve ${autoApproval ? 'en' : 'dis'}abled`}
                      />
                      <NumberTpl
                        className="current-invoice-cap"
                        currency={activeOrg.currency}
                        value={invoiceCap}
                      />
                      <TDDropButton className="ml-5" data-testid="invoice-cap-widget-dropdown">
                        <Dropdown.Item
                          data-testid="invoice-cap-widget-edit"
                          onClick={openModal}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-danger"
                          data-testid="invoice-cap-widget-remove"
                          onClick={async () => {
                            try {
                              await axios.put(
                                updateInvoiceCapApiUrl(activeOrg.alias, userCard.user.id),
                                { invoiceCap: null },
                              );
                              toastr.success('Well Done!', 'Invoice cap updated');
                              reloadUser();
                              reloadInvoiceCapUsage();
                            } catch (error) {
                              toastr.error(
                                'Oh Snap!', error.response?.data?._error || error.message,
                              );
                            }
                          }}
                        >
                          Remove
                        </Dropdown.Item>
                      </TDDropButton>
                    </div>
                  </div>
                  <InvoiceCapUsageBar displayWarnings={false} userId={userCard.user?.id} />
                </>
              )}
              {invoiceCap === null && (
                <div className="d-flex align-items-center justify-content-between">
                  <span>There is no Invoice Cap set</span>
                  <TDButton label="Set cap" onClick={openModal} variant={BS_STYLE.PRIMARY} />
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <ModalSimple
        data-testid="invoice-cap-modal"
        heading={`Set an Invoice Cap per ${capPeriod}`}
        open={isModalOpen}
        onClose={closeModal}
        body={(
          <Form
            initialValues={{
              billingPeriod: capPeriod,
              invoiceCap,
              invoiceCapType: CAP_TYPE.FIXED,
              quantity: invoiceCap !== null && defaultRateIsInOrgCurrency
                ? invoiceCap / userCard.rate
                : null,
            }}
            onSubmit={async values => {
              if (!values.invoiceCap) {
                if (values.invoiceCapType === CAP_TYPE.FIXED) {
                  return { invoiceCap: 'You must enter an invoice cap value' };
                }
                return { quantity: 'You must enter a quantity' };
              }
              try {
                await axios.put(
                  updateInvoiceCapApiUrl(activeOrg.alias, userCard.user.id),
                  { invoiceCap: values.invoiceCap },
                );
                toastr.success('Well Done!', 'Invoice cap updated');
                closeModal();
                reloadUser();
                reloadInvoiceCapUsage();
                return null;
              } catch (error) {
                const propertyErrors = omit(error.response?.data || {}, '_error', '_meta');
                if (Object.keys(propertyErrors).length > 0) {
                  return propertyErrors;
                }
                return { [FORM_ERROR]: error.response?.data?._error || error.message };
              }
            }}
            render={({ handleSubmit, form, submitError, submitting }) => {
              if (!formRef.current) {
                // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is broken
                // https://github.com/final-form/react-final-form/issues/483
                formRef.current = form;
              }
              const { values: { invoiceCapType } } = form.getState();
              return (
                <form className="invoice-cap-widget-form" onSubmit={handleSubmit}>
                  <RadioField
                    label={`How would you like to set the ${userRole.title}'s Invoice Cap?`}
                    name="invoiceCapType"
                    options={[
                      {
                        text: "A maximum invoice amount in the Organization's currency",
                        value: CAP_TYPE.FIXED,
                      },
                      {
                        text: (
                          <>
                            A limit on their default rate
                            <ElementWithPopOver
                              className="d-inline-block ml-2"
                              popOverContent={(
                                <>
                                  <p>
                                    {`You may choose to set the ${userRole.title}'s Invoice Cap `}
                                    by the unit of their default rate.
                                  </p>
                                  <p>
                                    {`For example, if the ${userRole.title}'s default rate is $50 `}
                                    per hour, you can set a limit of hours that they can invoice for
                                    during the Cap Period. We then convert this to a total value. So
                                    if you enter 60 hours, the Invoice Cap will be set to $50 x 60
                                    = $3,000.
                                  </p>
                                  <p>
                                    <b>IMPORTANT:</b>
                                    {`If you change the ${userRole.title}'s default rate, the `}
                                    invoice Cap amount remains static. You will have to review and
                                    change their Invoice Cap should you still want to have a 60 hour
                                    {` limit. For example, if you change the ${userRole.title}'s `}
                                    rate to $100 per hour, the Cap limit will remain at $3,000, and
                                    the equivalent hours will be 30.
                                  </p>
                                </>
                              )}
                              popOverTitle={`Cap by ${userRole.title}'s Default Rate`}
                            />
                          </>
                        ),
                        value: CAP_TYPE.QUANTITY,
                      },
                    ]}
                    required
                  />
                  {invoiceCapType === CAP_TYPE.FIXED && (
                    <div className="d-flex flex-column flex-md-row">
                      <MoneyInputField
                        className="mr-5"
                        currency={activeOrg.currency}
                        name="invoiceCap"
                        label={`Invoice cap (${activeOrg.currency.toUpperCase()})`}
                        required
                        disabled={submitting}
                      />
                      <TextInputField
                        className="ml-5"
                        disabled
                        label="Billing period"
                        name="billingPeriod"
                      />
                    </div>
                  )}
                  {invoiceCapType === CAP_TYPE.QUANTITY && (
                    <>
                      {defaultRateIsInOrgCurrency && (
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex flex-column flex-md-row align-items-start">
                            <InputNumberField
                              label={(
                                <>
                                  <span className="mr-2">Default rate</span>
                                  (
                                  <RateAmount
                                    amount={userCard.rate}
                                    symbol={userCard.currencySymbol}
                                    unit={userCard.rateUnit}
                                  />
                                  )
                                </>
                              )}
                              name="quantity"
                              placeholder="Quantity"
                              required
                            />
                            <OnChange name="quantity">
                              {value => {
                                form.change(
                                  'invoiceCap',
                                  value ? (value * userCard.rate).toFixed(decimals) : null,
                                );
                              }}
                            </OnChange>
                            <span className="mx-3 equals">=</span>
                            <MoneyInputField
                              className="mr-5"
                              currency={activeOrg.currency}
                              disabled
                              name="invoiceCap"
                              label={`Invoice cap (${activeOrg.currency.toUpperCase()})`}
                              required
                            />
                          </div>
                          <TextInputField
                            disabled
                            label="Billing period"
                            name="billingPeriod"
                          />
                        </div>
                      )}
                      {!defaultRateIsInOrgCurrency && (
                        <TDSystemMessage
                          className="mb-4"
                          title={`You cannot use the ${userRole.title}'s default rate`}
                          type={BS_STYLE.DANGER}
                        >
                          {`This ${userRole.title} does not have a default rate, or it is not in `}
                          the organization&apos;s currency. So it cannot be used to create their
                          invoice cap.
                        </TDSystemMessage>
                      )}
                    </>
                  )}
                  {submitError && (
                    <div className="has-error mb-4">
                      <span className="help-block">{submitError}</span>
                    </div>
                  )}
                </form>
              );
            }}
          />
        )}
        footer={(
          <div key="footer" className="text-right">
            <TDButton
              disabled={formRef.current?.getState().submitting}
              label="Cancel"
              onClick={closeModal}
            />
            <TDButton
              type="submit"
              onClick={() => {
                formRef.current.submit();
              }}
              variant={BS_STYLE.PRIMARY}
              disabled={(
                formRef.current?.getState().submitting
                || (
                  !defaultRateIsInOrgCurrency
                  && formRef.current?.getState().values.invoiceCapType === CAP_TYPE.QUANTITY
                )
              )}
              label="Save"
            />
          </div>
        )}
      />
    </>
  );
};

InvoiceCapWidget.GetComponentName = () => 'InvoiceCapWidget';

InvoiceCapWidget.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  userCard: userCardSpec.isRequired,
};

export default withRouter(InvoiceCapWidget);
