import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { BILLING_TABS, SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';
import { billingViewUrl } from 'finance/urls';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE } from 'core/assets/js/constants';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { billingSubscriptionStoreKey } from 'finance/assets/js/BillingView.jsx';
import SubscriptionDetails from 'finance/assets/js/lib/SubscriptionDetails';
import { subscriptionSpec } from 'finance/assets/js/lib/objectSpecs';
import BillingNoSubscriptionFound from 'finance/assets/js/components/BillingNoSubscription.jsx';

const BillingOverviewSubscription = (props) => {
  const {
    activeSubscription, orgMembersCount,
    match: { params: { orgAlias } }, history,
  } = props;
  if (isEmpty(activeSubscription.details)) {
    return <BillingNoSubscriptionFound />;
  }
  const { total_seats: subscriptionSeatsCount } = activeSubscription;
  const subscriptionDetails = new SubscriptionDetails(activeSubscription.details);
  const product = subscriptionDetails.getPlanProduct();
  const plan = SUBSCRIPTION_PLAN_LABELS[product];
  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
        <div>Subscription</div>
        <TDButton
          className="ml-auto"
          label="Upgrade plan"
          variant={BS_STYLE.PRIMARY}
          onClick={() => history.push(billingViewUrl(orgAlias, BILLING_TABS.SUBSCRIPTION))}
        />
      </Card.Header>
      <Card.Body>
        <h1 className="mb-3">{plan}</h1>
        <div className="mb-4">
          {`${orgMembersCount}/${subscriptionSeatsCount} seats `}
          (
          <Link
            className="ml-auto"
            to={billingViewUrl(orgAlias, BILLING_TABS.SUBSCRIPTION)}
          >
            manage seats
          </Link>
          )
        </div>
        <div className="sub-heading mb-2">Additional services</div>
        <div>-</div>
      </Card.Body>
      <Card.Footer className="d-flex card-footer--secondary">
        <Link
          className="ml-auto"
          to={billingViewUrl(orgAlias, BILLING_TABS.SUBSCRIPTION)}
        >
          View plan
        </Link>
      </Card.Footer>
    </Card>
  );
};

BillingOverviewSubscription.propTypes = {
  activeSubscription: subscriptionSpec,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  orgMembersCount: PropTypes.number,
};

BillingOverviewSubscription.defaultProps = {
  activeSubscription: {},
  orgMembersCount: null,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, billingSubscriptionStoreKey);
  const orgMembersCount = getViewStateExtras(state, billingSubscriptionStoreKey, 'orgMembersCount');
  return {
    activeSubscription: viewState.item,
    orgMembersCount,
  };
};

const BillingOverviewSubscriptionConnected = connect(mapStateToProps)(BillingOverviewSubscription);


export default withRouter(BillingOverviewSubscriptionConnected);
