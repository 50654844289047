import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import {
  routerHistorySpec, routerMatchContentsSpec, paginationSpec,
} from 'core/assets/js/lib/objectSpecs';
import { orgSettingsFinControllerApiUrl } from 'settings/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { BS_STYLE } from 'core/assets/js/constants';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import TDList from 'core/assets/js/components/TDList.jsx';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { getIsModalOpen, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import AddFinancialControllerPanel, {
  MODAL_ID as ADD_FIN_CONTROLLER_MODAL,
} from 'projects/assets/js/components/AddFinancialControllerPanel.jsx';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { PEOPLE_DEFAULT_ORDERING } from 'people/assets/js/constants';

class FinControllerView extends React.Component {
  static FetchData({ authedAxios, componentName, dispatch, params, querystring, url }) {
    const query = queryString.parse(querystring);
    if (!query.ordering) {
      query.ordering = JSON.stringify(PEOPLE_DEFAULT_ORDERING);
    }
    return dispatch(fetchListDS({
      authedAxios,
      componentName,
      querystring: queryString.stringify(query),
      url: orgSettingsFinControllerApiUrl(params.orgAlias, url),
    }));
  }

  static GetComponentName() {
    return 'FinControllerView';
  }

  render() {
    const {
      activeUserCard,
      dispatch,
      finControllers,
      hasFinController,
      history,
      isAddFinControllerModalOpen,
      pagination,
      location,
      match: { params: { orgAlias } },
    } = this.props;

    const componentName = this.constructor.GetComponentName();

    const breadcrumbs = [
      {
        title: 'Financial Controller',
        url: null,
      },
    ];

    const ctaButton = (
      <TDButton
        variant={BS_STYLE.PRIMARY}
        label="Add financial controller"
        onClick={() => {
          dispatch(modalOpenAC(ADD_FIN_CONTROLLER_MODAL));
        }}
        floatRight
      />
    );

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} ctaComponent={ctaButton} />

        <div className="page page--org-settings">
          <div className="container">
            <TDApiConnected duck="list" component={this.constructor} loadingEnabled>
              { !hasFinController && [] }
              { hasFinController && (
                <React.Fragment>
                  <TDSystemMessage
                    type={BS_STYLE.INFO}
                    title="Revoke Financial Controller rights"
                    className="mb-4"
                  >
                    To appoint a new Financial Controller you can click on
                    “Add financial controller“ button. In order to revoke the
                    rights, click on the menu and select “Remove Financial Controler”.
                  </TDSystemMessage>
                  <div className="mb-4">
                    <TDList
                      items={finControllers}
                      pagination={pagination}
                      cardItem={{
                        component: ManagerCard,
                        props: {
                          orgAlias,
                          activeUserCard,
                          showRemoveFinCon: true,
                          onFinControllerRemoved: item => {
                            if (
                              finControllers.length === 1
                              && finControllers[0].id === item.id
                              && pagination.page > 1
                            ) {
                              // the last financial controller has been removed from the current
                              // page and we're beyond the first page, so redirect to prevent a
                              // blank page
                              const query = queryString.parse(location.search);
                              query.page = pagination.page - 1;
                              history.push({
                                path: history.location.pathName,
                                search: queryString.stringify(query),
                              });
                            }
                            dispatch(requestRefreshAC(componentName));
                          },
                        },
                      }}
                      emptyListMessage="No managers found."
                    />
                  </div>
                </React.Fragment>
              )}
            </TDApiConnected>

            { !hasFinController && (
              <TDSystemMessage
                type={BS_STYLE.INFO}
                title="What is a Financial Controller?"
                className="mb-4"
              >
                <p>
                  The Organisation’s Financial Controller is a Manager with elevated permissions
                  who can distribute the Organisation’s budget amongst Managers and Projects.
                  Moreover, the Financial Controller is the recipient of all Invoices.
                </p>
                <p>
                  You can appoint any Manager as Financial Controller by clicking to
                  “Add financial controller“ button.
                </p>
              </TDSystemMessage>
            )}

            { isAddFinControllerModalOpen && (
              <AddFinancialControllerPanel />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FinControllerView.propTypes = {
  activeUserCard: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  finControllers: PropTypes.arrayOf(userCardSpec),
  hasFinController: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  isAddFinControllerModalOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  pagination: paginationSpec.isRequired,
  searchActive: PropTypes.bool.isRequired,
};

FinControllerView.defaultProps = {
  finControllers: [],
};

const mapStateToProps = (state) => {
  const listState = getListState(state, FinControllerView.GetComponentName());
  const activeUserCard = selectActiveUserCard(state);
  return {
    activeUserCard,
    finControllers: listState.items,
    hasFinController: !isEmpty(listState.items),
    isAddFinControllerModalOpen: getIsModalOpen(state, ADD_FIN_CONTROLLER_MODAL),
    pagination: listState.pagination,
    searchActive: listState.search.isActive,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const FinControllerViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)((FinControllerView));

export default withRouter(FinControllerViewConnected);
