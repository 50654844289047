import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { ICON } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import UserGroupAvatar from 'people/assets/js/components/UserGroupAvatar.jsx';
import { userGroupEditUrl } from 'people/urls';

const UserGroupCard = ({ item }) => {
  const history = useHistory();
  const { orgAlias } = useParams();
  return (
    <Card className="user-group-card border-0">
      <Card.Body>
        <div className="row">
          <div className="col-sm-12 col-md-8 description mb-4 mb-md-0">
            <div className="d-flex align-items-center">
              <UserGroupAvatar {...item} />
              <h2 className="ml-3 my-0">{item.name}</h2>
              <i
                className={`${ICON.EDIT} ml-3 fa-lg edit-icon`}
                onClick={() => history.push(userGroupEditUrl(orgAlias, item.id))}
              />
            </div>
            <div className="mt-5">{item.description}</div>
          </div>
          <div className="col-sm-12 col-md-4 d-flex flex-column justify-content-start">
            <div className="row creator py-2 mx-0 mx-md-1">
              <div className="col-5 pl-0">
                Date created
              </div>
              <div className="col-7 text-left pl-0 font-weight-bolder">
                {item.owner.profile.name}
              </div>
            </div>
            <div className="row created-at py-2 mx-0 mx-md-1">
              <div className="col-5 pl-0">
                Date
              </div>
              <div className="col-7 text-left pl-0 font-weight-bolder">
                {formatDate(item.createdAt)}
              </div>
            </div>
            <div className="row py-2 mx-0 mx-md-1">
              <div className="col-5 pl-0">
                Members
              </div>
              <div className="col-7 text-left pl-0 font-weight-bolder">
                <span className="mr-3">
                  {item.membersCount}
                </span>
              </div>
            </div>
            <div className="row py-2 mx-0 mx-md-1">
              <div className="col-5 pl-0">
                Type
              </div>
              <div className="col-7 text-left pl-0 font-weight-bolder">
                <span className="mr-3">
                  {`${item.isDynamic ? 'Dynamic' : 'Standard'} group`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

UserGroupCard.propTypes = {
  item: PropTypes.shape({
    createdAt: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    isDynamic: PropTypes.bool,
    membersCount: PropTypes.number,
    name: PropTypes.string,
    owner: PropTypes.shape({ profile: PropTypes.object }),
  }),
};

UserGroupCard.defaultProps = {
  item: {},
};

export default UserGroupCard;
