import Big from 'big.js';
import cronValidator from 'cron-validate';
import { capitalize, get, isEmpty, isNil, omit, pick } from 'lodash';
import { parse as queryParser } from 'query-string';
import { toastr } from 'react-redux-toastr';

import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BANK_CURRENCY, CURRENCY, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { assertAllKeysPresent, getDatetime, parseDate } from 'core/assets/js/lib/utils';
import axios from 'core/assets/js/lib/tdAxios';
import { downloadFileApiUrl } from 'files/urls';
import {
  financeExpenseEditUrl,
  financeGet1099FilingPDFFileIdApiUrl,
  financeProFormaInvoiceEditUrl,
  financeWorksheetEditUrl,
} from 'finance/urls';
import { SERVICE_ORDER_STATUS, SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { RATE_SUGGEST_VALUE, RATE_UNIT } from 'rates/assets/js/constants';
import {
  DOCUMENT_TYPE, DOCUMENT_TYPE_PREFIX,
  PAYONEER_BASE_FEE_USD, TIN_TYPE, TRANSACTION_MODE,
  BILL_PAYMENTS_TOLERANCE_PERCENTAGE, INVOICING_MODE,
} from 'finance/assets/js/constants';
import { EXTENDED_PAYMENT_METHODS } from 'settings/assets/js/constants';
import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';
import Money from 'finance/assets/js/lib/Money';
import { assert } from 'finance/assets/js/lib/Assert';

Big.RM = 1;
Big.DP = 2;

const determineReportEditUrl = (orgAlias, report) => {
  let reportEditUrl = null;

  switch (report.type) {
    case SERVICE_ORDER_TYPE.WORKSHEET:
      reportEditUrl = financeWorksheetEditUrl(orgAlias, report.id);
      break;

    case SERVICE_ORDER_TYPE.PROFORMA_INVOICE:
      reportEditUrl = financeProFormaInvoiceEditUrl(orgAlias, report.id);
      break;

    default:
      reportEditUrl = financeExpenseEditUrl(orgAlias, report.id);
  }

  return reportEditUrl;
};

/**
 * Checks if vat already has a prefix
 * in which case we do not add country code
*/
const isVatPrefixed = value => !!value.match(/^[A-Za-z]{2}/);


/**
 * Formats address provided by TW to a user friendly way
 * @param {Object} address
 * @returns {String}
 */
function getTWDetailsAddress(address) {
  if (isEmpty(address)) {
    return '';
  }
  if (!isEmpty(address.description)) {
    return address.description;
  }
  const { firstLine, postCode, city, state, country } = address;
  const nameParts = [
    firstLine, postCode, city, state, country,
  ].filter(c => !!c);
  const name = nameParts.join(', ');
  return name;
}


/**
 * It checks and filters out which information we want to show based on the details
 * object that is saved in our tw recipients
 * @param {Object} details
 * @param {String} currency
 * @returns {Object}
 */
function getTWAllowedDetails(details, currency) {
  if (!currency) {
    throw new Error('cannot get details without currency');
  }
  const allowedFields = [
    'iban', 'routingNumber', 'accountNumber', 'bankCode', 'swiftCode', 'branchCode',
    'cardNumber', 'ifscCode', 'sortCode', 'institutionNumber', 'transitNumber', 'bsbCode',
    'abartn', 'russiaRegion', 'cardToken',
  ];

  if ([BANK_CURRENCY.USD, BANK_CURRENCY.RUB].includes(currency.toLowerCase())) {
    allowedFields.push('address');
  }
  const allowedDetails = pick(details, allowedFields);

  const aliasedFields = {
    BIC: 'swiftCode',
    bic: 'swiftCode',
    IBAN: 'iban',
  };
  Object.entries(aliasedFields).forEach(([key, value]) => {
    if (details[key]) {
      Object.assign(allowedDetails, { [value]: details[key] });
    }
  });

  return allowedDetails;
}

/**
 * @param {String} key - the field's key
 * @param {String} currency
 * @returns {String}
 */
function getTwDetailLabel(key, currency) {
  if (currency === BANK_CURRENCY.CNY && key === 'cardNumber') {
    return 'UnionPay card number';
  }
  if (currency === BANK_CURRENCY.CNY && key === 'accountNumber') {
    return 'Account number';
  }
  switch (key) {
    case 'branchCode':
      return 'Branch';
    case 'iban': // needed so it doesn't change the label according to the default regex
      return 'IBAN';
    case 'ifscCode':
      return 'IFSC Code';
    case 'bsbCode':
      return 'BSB Code';
    case 'abartn':
      return 'ACH routing number';
    case 'russiaRegion':
      return 'Region';
    case 'routingNumber':
      return 'ACH/ABA';
    case 'swiftCode':
      return 'Bic/Swift';
    default:
      // converts camelCase to first letters capital style
      return capitalize(key.replace(/([A-Z])/g, ' $1'));
  }
}

/**
 * Check if the current url contains a query param for 'backUrl',
 * when it does, push that url to the browsers stack.
 * @param {Router.history} history - router history.
 * @returns {boolean} return if back url was present.
 */
function followBackUrlIfPresent(history) {
  const { backUrl } = queryParser(history.location.search);
  if (backUrl) {
    history.push(backUrl);
    return true;
  }
  return false;
}

/**
 * Sanitize a payemtn reference, remove illegal characters and make uppercase.
 * @param {string} reference - reference to be sanitized.
 * @return {string} sanitized reference.
 */
const sanitizePaymentReference = reference => (reference || '')
  // must be uppercase
  // replace illegal characters ( only accept AtoZ, 0to9, - and spaces )
  .toUpperCase()
  .replace(/[\s]+/g, ' ')
  .replace(/[^A-Z|0-9|\-|\s]/g, '');

/**
 * Get the maximum payment reference length for currency,
 * or return the shortest if the currency is unknown.
 *
 * @see https://wise.com/help/articles/2932870/tips-for-paying-invoices
 *
 * @param {BANK_CURRENCY} currency - the currency to return the length for.
 * @return {number} maximum length for payment reference.
 */
const getPaymentReferenceLengthForCurrency = currency => ({
  [BANK_CURRENCY.GBP]: 16,
  [BANK_CURRENCY.USD]: 10,
  [BANK_CURRENCY.EUR]: 35,
  [BANK_CURRENCY.INR]: 16,
  [BANK_CURRENCY.PLN]: 35,
  [BANK_CURRENCY.CHF]: 50,
  [BANK_CURRENCY.DKK]: 35,
  [BANK_CURRENCY.NOK]: 35,
  [BANK_CURRENCY.SEK]: 12,
  [BANK_CURRENCY.AUD]: 18,
  [BANK_CURRENCY.NZD]: 12,
  [BANK_CURRENCY.CAD]: 10,
  [BANK_CURRENCY.BGN]: 35,
  [BANK_CURRENCY.CZK]: 25,
  [BANK_CURRENCY.GEL]: 35,
  [BANK_CURRENCY.HUF]: 35,
  [BANK_CURRENCY.RON]: 30,
  [BANK_CURRENCY.HKD]: 50,
  [BANK_CURRENCY.IDR]: 35,
  [BANK_CURRENCY.KRW]: 35,
  [BANK_CURRENCY.MYR]: 35,
  [BANK_CURRENCY.PHP]: 35,
  [BANK_CURRENCY.SGD]: 35,
  [BANK_CURRENCY.THB]: 50,
  [BANK_CURRENCY.AED]: 35,
  [BANK_CURRENCY.LKR]: 35,
  [BANK_CURRENCY.MAD]: 50,
  [BANK_CURRENCY.MXN]: 100,
  [BANK_CURRENCY.ZAR]: 18,
  [BANK_CURRENCY.VND]: 35,
})[currency || BANK_CURRENCY.USD] || 10;

/**
 * Truncate a payment reference for a set length,
 * returns the last N characters, rather than the first N characets.
 * @param {string} reference - reference to truncate.
 * @param {number} length - number of characters to truncate to.
 * @return {string} truncated payment reference.
 */
const truncatePaymentReferenceForLength = (reference, length) => (reference || '').slice(-length);

/**
* Truncate a payment reference based on the currency of the transaction.
* @param {string} reference - payment reference.
* @param {BANK_CURRENCY} currency - transaction currency.
* @return {string} truncated payment reference.
*/
const truncatePaymentReferenceForCurrency = (reference, currency) => truncatePaymentReferenceForLength((reference || ''), getPaymentReferenceLengthForCurrency(currency));

/**
 * Return the default length of a payment reference, when a single currency isn't available.
 * @return {number} maximum length of a payment reference.
 */
const getDefaultPaymentReferenceLength = () => getPaymentReferenceLengthForCurrency();

/**
 * Generate a payment reference for use in a transaction.
 * @param {string} customerPaymentReference - customer's payment refernce.
 * @param {string} customNumber - invoice number.
 * @param {string} uniqueNumber - internal payment reference.
 * @param {BANK_CURRENCY} currency - transaction currency, needed to determin reference length.
 * @returns {object} sanitized payment references,
 * both as it will appear on bank statement and the full untruncated version.
 */
const generatePaymentReference = (
  customerPaymentReference,
  customNumber,
  uniqueNumber,
  currency,
) => {
  // select source reference in order of priority
  const output = (customerPaymentReference || '').trim() || customNumber || (uniqueNumber || '')
    .replace(/TD-|-/g, '');
  // sanitize
  const finalizedOutput = sanitizePaymentReference(output) || uniqueNumber;
  // return truncate and full payment references
  return {
    fullPaymentReference: finalizedOutput || '',
    paymentReference: truncatePaymentReferenceForCurrency(finalizedOutput, (currency || '').toLowerCase()),
  };
};

/**
 * Calculates the invoice's trasaction reference message
 * @param {Invoice} invoice - invoice to generate payment reference for.
 * @return {string} payment reference.
 */
const calcTransactionReference = (
  invoice,
  targetCurrency,
  financialAssociation,
  { full = false } = {},
) => {
  const { number, unique_number: uniqueNumber } = invoice;
  const currency = targetCurrency || invoice.currency;
  const fa = financialAssociation || get(invoice, 'financialAssociation');
  const fe = fa?.getOwnerFE();
  let customReference;
  if (fe) {
    customReference = fe.getCustomReference();
  }
  const { paymentReference, fullPaymentReference } = generatePaymentReference(
    customReference,
    number,
    uniqueNumber,
    currency,
  );
  return (full ? fullPaymentReference : paymentReference).trim();
};

/**
 * Removes any service order item properties added for the front-end rendering,
 * which are not needed by the API
 *
 * @param {Object[]} items
 * @returns {Object[]}
 */
const formatServiceOrderItems = items => items.map(item => {
  const itemOmitList = [
    'accessControl',
    'currency',
    'currencySymbol',
    'exchangeRate',
    'exchangeRateUpdatedAt',
    'id',
    'rateBilledQuantity',
    'rateMaxBillingQuantity',
    'rate_unit',
    'status',
    'title',
    'warning',
  ];
  if (item.task_id) {
    itemOmitList.push('rate_id');
  }
  return omit(item, itemOmitList);
});

/**
 * Returns a formatted default new item for pro forma invoices
 *
 * @param {Object} [defaultRate]
 * @returns {Object}
 */
const getProFormaInvoiceDefaultNewItem = defaultRate => ({
  description: '',
  rate_amount: defaultRate ? defaultRate.amount : 0,
  rate_id: defaultRate ? defaultRate.id : RATE_SUGGEST_VALUE,
  rate_unit: defaultRate ? defaultRate.unit : RATE_UNIT.CUSTOM,
  quantity: 1,
});

const convertsTo = (sourceAmount, rate, targetAmount) => {
  if (Big(rate).eq(0) || Big(sourceAmount).eq(0)) {
    return Big(targetAmount).eq(0);
  }

  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;
  Big.RM = 1;
  Big.DP = 2;

  const fwdRange = {
    prev: Big(sourceAmount).minus(0.01).times(rate).toFixed(2),
    curr: Big(sourceAmount).times(rate).toFixed(2),
    next: Big(sourceAmount).plus(0.01).times(rate).toFixed(2),
  };

  const bwdRange = {
    prev: Big(targetAmount).minus(0.01).div(rate).toFixed(2),
    curr: Big(targetAmount).div(rate).toFixed(2),
    next: Big(targetAmount).plus(0.01).div(rate).toFixed(2),
  };

  const fwdDiffs = {
    prev: Big(fwdRange.prev).minus(targetAmount).abs().toFixed(2),
    curr: Big(fwdRange.curr).minus(targetAmount).abs().toFixed(2),
    next: Big(fwdRange.next).minus(targetAmount).abs().toFixed(2),
  };

  const bwdDiffs = {
    prev: Big(bwdRange.prev).minus(sourceAmount).abs().toFixed(2),
    curr: Big(bwdRange.curr).minus(sourceAmount).abs().toFixed(2),
    next: Big(bwdRange.next).minus(sourceAmount).abs().toFixed(2),
  };

  // we should match to the closest cent
  const fwdMatch = Big(fwdDiffs.curr).lte(fwdDiffs.prev) && Big(fwdDiffs.curr).lte(fwdDiffs.next);
  const bwdMatch = Big(bwdDiffs.curr).lte(bwdDiffs.prev) && Big(bwdDiffs.curr).lte(bwdDiffs.next);


  // Restore rounding mode and precision to whatever it was previously
  Big.RM = initialRM;
  Big.DP = initialDP;
  return fwdMatch && bwdMatch;
};

const getReverseRate = (rate, { testFromAmount, testToAmount } = {}) => {
  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;

  let reverseRate = Big(1).div(rate).toNumber();

  let precision = 4;
  Big.RM = 1;
  Big.DP = precision;

  reverseRate = Big(1).div(rate).toNumber();

  if (testFromAmount && testToAmount) {
    while (precision < 10 && Big(testFromAmount).times(reverseRate).toFixed(2) !== testToAmount) {
      Big.RM = 1;
      Big.DP = precision;
      precision += 1;
      reverseRate = Big(1).div(rate).toNumber();
    }
  } else {
    while (precision < 10 && reverseRate === 0) {
      Big.RM = 1;
      Big.DP = precision;
      precision += 1;
      reverseRate = Big(1).div(rate).toNumber();
    }
  }

  if (reverseRate === 0) {
    throw new Error('cannot calculate reverse rate');
  }

  // reinstate the precision of Big.js
  Big.RM = initialRM;
  Big.DP = initialDP;
  return reverseRate;
};

const getCombinedRate = (rates, { precision = 4 } = {}) => {
  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;

  Big.RM = 1;
  Big.DP = precision;

  const combined = rates.reduce((acc, rate) => {
    return acc.times(rate);
  }, Big(1));

  const rate = parseFloat(combined.toFixed(precision));

  // reinstate the precision of Big.js
  Big.RM = initialRM;
  Big.DP = initialDP;
  return rate;
};

const calcRate = (fromAmount, toAmount, {
  initialPrecision = 4,
} = {}) => {
  let rate;
  let precision = initialPrecision;

  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;

  do {
    // temporarily change the precision of Big.js
    Big.RM = 1;
    Big.DP = precision;
    precision += 1;
    rate = Big(toAmount).div(fromAmount).toNumber();
  } while (precision < 10 && Big(fromAmount).times(rate).toFixed(2) !== toAmount);
  // reinstate the precision of Big.js
  Big.RM = initialRM;
  Big.DP = initialDP;

  const reverseRate = getReverseRate(rate, { testFromAmount: toAmount, testToAmount: fromAmount });

  return { rate, reverseRate };
};

const getRate = (fromAmount, fromCurrency, toAmount, toCurrency, {
  initialPrecision = 4, knownRates,
} = {}) => {
  if (Big(fromAmount).eq(0)) {
    return { rate: 1, reverseRate: 1 };
  }

  const knownRate = get(knownRates, `${fromCurrency.toUpperCase()}.${toCurrency.toUpperCase()}`);
  const knownReverseRate = get(knownRates, `${toCurrency.toUpperCase()}.${fromCurrency.toUpperCase()}`);

  if (knownRate) {
    if (knownReverseRate) {
      return { rate: knownRate, reverseRate: knownReverseRate };
    }
    return {
      rate: knownRate,
      reverseRate: getReverseRate(knownRate, {
        testFromAmount: toAmount, testToAmount: fromAmount,
      }),
    };
  }

  if (knownReverseRate) {
    return {
      rate: getReverseRate(knownReverseRate, {
        testFromAmount: fromAmount, testToAmount: toAmount,
      }),
      reverseRate: knownReverseRate,
    };
  }

  if (fromCurrency.toLowerCase() === toCurrency.toLowerCase()) {
    return { rate: 1, reverseRate: 1 };
  }

  return calcRate(fromAmount, toAmount, { initialPrecision });
};

const assertIsEqual = (source, target) => {
  if (source !== target) {
    throw new Error(`amounts are not equal (${source}!= ${target})`);
  }
};

const assertConvertsTo = (source, rate, target) => {
  if (!convertsTo(source, rate, target)) {
    throw new Error(`amounts do not convert to each other (${source} * ${rate} == ${Big(source).times(rate).toFixed(2)} != ${target})`);
  }
};

const assertRatesAreComplementary = (rate, reverseRate, { precision = 5 } = {}) => {
  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;
  Big.RM = 1;
  Big.DP = precision;

  const product = Big(rate).times(reverseRate);
  const diff = product.minus(1);

  if (diff.gt(Big(10).pow(-precision))) {
    throw new Error(`rates are not complementary ${rate}*${reverseRate}=${product.toNumber()}!=1`);
  }
  // reinstate the precision of Big.js
  Big.RM = initialRM;
  Big.DP = initialDP;
};

const assertRateMapIsConsistent = (rateMap) => {
  // Change to rounding mode and precision according to monetary calculations,
  // keep the initial values locally in order to restore later
  const initialRM = Big.RM;
  const initialDP = Big.DP;
  Big.RM = 1;
  Big.DP = 5;
  const currencies = Object.keys(rateMap);
  currencies.forEach((from) => {
    currencies.forEach((to) => {
      const fwd = rateMap[from][to];
      const bwd = rateMap[to][from];
      assertRatesAreComplementary(fwd, bwd);
    });
  });
  // reinstate the precision of Big.js
  Big.RM = initialRM;
  Big.DP = initialDP;
};

/**
 * Validates if a string represents a valid UUID (Universally Unique Identifier).
 * @param {String} id - The string to be validated as a UUID.
 *
 * @returns {Boolean} Returns `true` if the string is a valid UUID, and `false` otherwise.
 *
 * @example
 * // Valid UUID: f8123fae-7dec-32d1-a123-00a0c91e6bf6
 * const isValid = isValidUUID('f8123fae-7dec-32d1-a123-00a0c91e6bf6');
 */
const isValidUUID = id => /^[\d|a-f]{8}-[\d|a-f]{4}-[\d|a-f]{4}-[\d|a-f]{4}-[\d|a-f]{12}$/.test(id);

const calcExchangeRateTotal = (exchangeRate, exchangeRateMarkup) => {
  if (!exchangeRateMarkup) {
    return exchangeRate;
  }

  // temporarily change the precision of Big.js
  Big.RM = 1;
  Big.DP = 5;

  const rate = Big(exchangeRate).times(Big(1.0).plus(exchangeRateMarkup)).toFixed(5);

  Big.RM = 1;
  Big.DP = 2;

  return rate;
};

/**
 * Checks if at least one of the user cards have settings through which we charged Payoneer fees
 *
 * @param {UserCard[]} userCards - User cards to check for Payoneer fee settings
 * @returns {*} Returns true if settings are activated in at least one user card,
 * otherwise it returns false
 */
const isChargedForProviderFees = userCards => userCards
  .some(uc => (
    uc?.organization?.invoicing_transaction_mode === TRANSACTION_MODE.FIXED_SO_AMOUNT
  ));

/**
 * Returns an informational fee message for our providers based on the payment method used.
 *
 * @param {number} paymentMethod - The payment method for which to return the fee message.
 *
 * @returns {string} - The fee message
 */
const getPaymentMethodFeeMsg = (paymentMethod) => {
  const feeCurrency = CURRENCY.USD;
  const feeCurrencySymbol = CURRENCY_SYMBOL[feeCurrency];
  switch (paymentMethod) {
    case EXTENDED_PAYMENT_METHODS.WISE:
      return 'Zero fees for same currency payments. Wise fees may apply for currency conversions.';
    case EXTENDED_PAYMENT_METHODS.BANK_TRANSFER:
      return 'Your bank may charge you fees to receive your payment.';
    case EXTENDED_PAYMENT_METHODS.PAYONEER:
      return `Payoneer charges ${feeCurrencySymbol}${new Money(PAYONEER_BASE_FEE_USD, feeCurrency).toNumber()} per payment that will be passed on to you.`;
    default:
      return '';
  }
};

/**
 * Checks if comparisonAmount is within the specified tolerance
 * range of primaryAmount.
 *
 * @param {number|string} primaryAmount - The first amount to compare.
 * @param {number|string} comparisonAmount - The second amount to compare against.
 * @param {number} tolerance - The percentage range to check (0-100).
 * @param {string} [boundsType="insideBoth"] - Specifies the type of comparison
 * (insideBoth, onlyUpper, onlyLower)
 * insideBoth - checks if comparison amount is between lower and upper bound
 * aboveLower - checks if comparison amount is higher than the lower bound
 * belowUpper - checks if comparison amount is less than the higher bound
 *
 * @returns {boolean} - Returns true if comparisonAmount is within the specified percentage
 * range of primaryAmount, otherwise returns false.
 *
 * @throws {Error} - Throws an error if required parameters are missing
 */
const isWithinBounds = ({
  primaryAmount,
  comparisonAmount,
  tolerance,
  boundsType = 'insideBoth',
} = {}) => {
  assertAllKeysPresent({ primaryAmount, comparisonAmount });

  const parsedPrimaryAmount = parseFloat(primaryAmount) || 0;
  const parsedComparisonAmount = parseFloat(comparisonAmount) || 0;

  const upperBound = parsedPrimaryAmount * ((100 + tolerance) / 100);
  const lowerBound = parsedPrimaryAmount * ((100 - tolerance) / 100);

  if (boundsType === 'belowUpper') {
    return parsedComparisonAmount <= upperBound;
  }
  if (boundsType === 'aboveLower') {
    return parsedComparisonAmount >= lowerBound;
  }

  return parsedComparisonAmount >= lowerBound && parsedComparisonAmount <= upperBound;
};

/**
 * Checks if inbound transactions should be created for a bill payments invoice based
 * on the transaction amount that is recorded.
 * @param {string} orgInvoicingMode - the organization's invoice mode
 * @param {string} contractorCharges - the amount of the bill payment's contractor charges
 * @param {string} transactionAmount - the transaction's -  to be recorded - amount
 *
 * @returns {boolean} Returns true if inbound transactions should be created for
 * bill payments invoice, false otherwise.
 */
const shouldCreateBillPaymentInboundTransOnPay = (
  orgInvoicingMode, contractorCharges, transactionAmount,
) => {
  const isTransAmtCloseToContractorCharges = isWithinBounds(
    {
      primaryAmount: contractorCharges,
      comparisonAmount: transactionAmount,
      tolerance: BILL_PAYMENTS_TOLERANCE_PERCENTAGE,
      boundsType: 'aboveLower',
    },
  );

  const isBillPaymentsMode = orgInvoicingMode === INVOICING_MODE.BILL_PAYMENTS;

  return isBillPaymentsMode && isTransAmtCloseToContractorCharges;
};

/**
 * Masks all but the last three characters of a tax identification number
 *
 * @param {String|Number} tin
 * @returns {String}
 */
const maskTaskIdentificationNumber = tin => {
  let tinString = tin;
  if (typeof tinString === 'number') {
    tinString = tinString.toString();
  }
  if (typeof tinString !== 'string') {
    return null;
  }
  const tinLength = tinString.length;
  if (tinLength <= 3) {
    return tin;
  }
  const censoredLength = tinLength - 3;
  return `${new Array(censoredLength).fill('*').join('')}${tinString.substr(censoredLength)}`;
};

/**
 * Determine the personal TIN type, from a supplied TIN
 * https://www.taxact.com/support/19057/2023/ssn-itin-atin-valid-ranges
 *
 * @param {String} taxIdentificationNumber
 * @returns {String|null}
 */
const determinePersonalTINType = taxIdentificationNumber => {
  if (typeof taxIdentificationNumber !== 'string' || !/^\d{9}$/.test(taxIdentificationNumber)) {
    throw new Error('taxIdentificationNumber must be a string of 9 numbers');
  }
  const parsedTIN = parseInt(taxIdentificationNumber, 10);
  if (parsedTIN >= 900930000 && parsedTIN <= 999939999) {
    return TIN_TYPE.ATIN;
  }
  if (
    (parsedTIN >= 900700000 && parsedTIN <= 999889999)
    || (parsedTIN >= 900900000 && parsedTIN <= 999929999)
    || (parsedTIN >= 900940000 && parsedTIN <= 999999999)
  ) {
    return TIN_TYPE.ITIN;
  }
  if (
    (parsedTIN >= 1010001 && parsedTIN <= 665999999)
    || (parsedTIN >= 667010001 && parsedTIN <= 899999999)
    || (parsedTIN >= 750010001 && parsedTIN <= 763999999)
    || (parsedTIN >= 764010001 && parsedTIN <= 899999999)
  ) {
    return TIN_TYPE.SSN;
  }
  return null;
};

const getContractorNotificationContext = ({
  invoiceId, invoiceNumber, providerName, invoicePeriod, invoiceAmounts,
}) => {
  assertAllKeysPresent({
    invoiceId, invoiceNumber, providerName, invoicePeriod, invoiceAmounts,
  });

  const {
    currency: invCurrency, netValue: invNetValue, vat: invVat, total: invTotal,
    orgCurrency, orgNetValue, orgVat, orgTotal,
  } = invoiceAmounts.getAmountsForOrg();

  assertAllKeysPresent({
    invCurrency, invNetValue, invVat, invTotal,
    orgCurrency, orgNetValue, orgVat, orgTotal,
  });

  const netValue = new Money(invNetValue, invCurrency);
  const vat = new Money(invVat, invCurrency);
  const total = new Money(invTotal, invCurrency);
  const netValueInOrgCurrency = new Money(orgNetValue, orgCurrency);
  const vatInOrgCurrency = new Money(orgVat, orgCurrency);
  const totalInOrgCurrency = new Money(orgTotal, orgCurrency);

  const out = orgCurrency === invCurrency ? {
    netValue: netValue.toString({ humanizeAmount: true }),
    vat: vat.toString({ humanizeAmount: true }),
    total: total.toString({ humanizeAmount: true }),
  } : {
    netValue: netValueInOrgCurrency.toString({ humanizeAmount: true }),
    vat: vatInOrgCurrency.toString({ humanizeAmount: true }),
    total: totalInOrgCurrency.toString({ humanizeAmount: true }),
  };

  if (orgCurrency !== invCurrency) {
    Object.assign(out, {
      contractorCurrencyNetValue: `( ${netValue.toString({ withSymbol: true, humanizeAmount: true })} )`,
      contractorCurrencyVAT: `( ${vat.toString({ withSymbol: true, humanizeAmount: true })} )`,
      contractorCurrencyTotal: `( ${total.toString({ withSymbol: true, humanizeAmount: true })} )`,
    });
  }

  return {
    id: invoiceId,
    number: invoiceNumber,
    provider: providerName,
    period: invoicePeriod,
    ...out,
  };
};

const validatePhoneNumberForTaxBandits = phoneNumber => (
  typeof phoneNumber === 'string'
  && (
    /^\d{10}$/.test(phoneNumber)
    || /^\d{3}\.\d{3}\.\d{4}$/.test(phoneNumber)
    || /^\d{3}-\d{3}-\d{4}$/.test(phoneNumber)
    || /^\(\d{3}\) \d{3}-\d{4}$/.test(phoneNumber)
  )
);

/**
 * Gets the current year if April or later, or if org was created in the current year
 *
 * @param {Number} orgCreatedAtYear
 * @returns {Number}
 */
const getDefault1099ContractorsYear = orgCreatedAtYear => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const aprilIndex = 3;
  if (
    orgCreatedAtYear >= currentYear
    || now.getMonth() >= aprilIndex
  ) {
    return currentYear;
  }
  return currentYear - 1;
};

/**
 * Validates a cron string.
 *
 * @param {string} cronString - The cron string to validate.
 * By default we have enabled the following overrides:
 *
 * useAliases - enables aliases for month and daysOfWeek fields
 * (ignores limits for month and daysOfWeek, so be aware of that)
 *
 * useNthWeekdayOfMonth - enables the '#' character to specify
 * the Nth weekday of the month.
 * e.g.: 6#3 would mean the 3rd friday of the month
 * (assuming 6 = friday)
 *
 * @param {object} extraOverrides - Optional extra overrides for the validator.
 *
 * More information about cron validator.
 * @link https://github.com/Airfooox/cron-validate
 *
 *
 * @returns {boolean} - Returns true if the cron string is valid, false otherwise.
 */
const validateCronString = (cronString, { extraOverrides } = {}) => {
  const validator = cronValidator(cronString, {
    override: {
      useAliases: true,
      useNthWeekdayOfMonth: true,
      ...extraOverrides,
    },
  });

  return validator.isValid();
};

/**
 * Checks if process_at is set to a future date later than the next
 * billing date (based on the org invoicing frequencies)
 *
 * @param {ServiceOrder} billable
 * @returns {Boolean}
 */
const isBillableDelayed = (billable) => {
  if (isNil(billable.organization.invoicing_frequency_dump)) {
    throw new Error('organization has no invoicing days');
  }

  if (billable.isInvoiced) {
    return false;
  }

  if (billable.status !== SERVICE_ORDER_STATUS.APPROVED) {
    return false;
  }

  const now = getDatetime();
  const reviewDate = billable.reviewed_at ? parseDate(billable.reviewed_at) : now;
  const processDate = billable.process_at ? parseDate(billable.process_at) : now;


  if (reviewDate.month() === processDate.month() && reviewDate.date() === processDate.date()) {
    // when review date is same as process date, the billable is not considered delayed
    return false;
  }

  const invFreq = InvoicingFrequency.fromDump(billable.organization.invoicing_frequency_dump);
  const billingRange = invFreq.getDatetimeRangeForProcessingSegment(processDate);

  const willBeProcessedInTheFuture = billingRange.toDateTime.isSameOrAfter(now);
  const hasBeenReviewedInPastProcess = billingRange.fromDateTime.isAfter(reviewDate);

  return hasBeenReviewedInPastProcess && willBeProcessedInTheFuture;
};

/**
 * Generate an external document reference number, such as an invoice or a cashout request.
 *
 * 'documentType' determines a prefix, such as 'TD'.  'userSeries' is optional,
 * and identifies the user the document belongs to, such as in the case of a
 * provider invoice.  'num' is a unique identifier for this particular document
 * instance in a series, again such as contractor invoices.
 *
 * The generated string takes the format of
 * <documentType>-<userSeries>-<num>
 * or
 * <documentType>-<num>
 *
 * @param {DOCUMENT_TYPE} documentType - the type of document this reference is for.
 * @param {string} [userSeries] - an owner identifier, optional.
 * @param {number} num - a unique number for this series of documents for the same owner.
 * @return {string} reference.
 */
const generateReference = (documentType, userSeries, num) => {
  assert(documentType, 'documentType is required');
  assert(Object.values(DOCUMENT_TYPE).includes(documentType), 'documentType must be a member of DOCUMENT_TYPE');
  assert(num, 'num is required, and must be non 0');

  const userSeriesInsert = userSeries ? `-${userSeries}` : '';
  return `${DOCUMENT_TYPE_PREFIX[documentType]}${userSeriesInsert}-${num}`;
};

/**
 * Gets the file id for a 1099 filing's PDF, then downloads it. Or errors, if it is not available
 * yet
 *
 * @param {String} orgAlias
 * @param {Number} filingId
 * @param {Object} [options]
 * @param {Boolean} [options.recipient]
 * @returns {Promise<Void>}
 */
const download1099FilingPDF = async (orgAlias, filingId, { recipient = false } = {}) => {
  try {
    const { data: { fileId } } = await axios.get([
      financeGet1099FilingPDFFileIdApiUrl(orgAlias, filingId), recipient ? '?recipient=1' : '',
    ].join(''));
    if (fileId) {
      WINDOW_OPEN(downloadFileApiUrl(fileId));
    } else {
      toastr.warning(
        'Not ready yet',
        'The PDF for the 1099 filing is not ready yet, please try again later',
      );
    }
  } catch (err) {
    toastr.error('Oh Snap!', err.response?.data?._error || err.message);
  }
};

export {
  calcExchangeRateTotal,
  calcRate,
  calcTransactionReference,
  convertsTo,
  assertConvertsTo,
  assertIsEqual,
  assertRatesAreComplementary,
  assertRateMapIsConsistent,
  determinePersonalTINType,
  determineReportEditUrl,
  download1099FilingPDF,
  followBackUrlIfPresent,
  formatServiceOrderItems,
  generatePaymentReference,
  getContractorNotificationContext,
  getDefault1099ContractorsYear,
  getDefaultPaymentReferenceLength,
  getPaymentReferenceLengthForCurrency,
  getPaymentMethodFeeMsg,
  getProFormaInvoiceDefaultNewItem,
  getRate,
  getReverseRate,
  getCombinedRate,
  getTWAllowedDetails,
  getTwDetailLabel,
  getTWDetailsAddress,
  isBillableDelayed,
  isChargedForProviderFees,
  isValidUUID,
  isVatPrefixed,
  maskTaskIdentificationNumber,
  isWithinBounds,
  sanitizePaymentReference,
  shouldCreateBillPaymentInboundTransOnPay,
  truncatePaymentReferenceForCurrency,
  truncatePaymentReferenceForLength,
  validatePhoneNumberForTaxBandits,
  validateCronString,
  generateReference,
};
