import { pick } from 'lodash';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';

import EmailTemplatePreview, { MODAL_ID } from 'settings/assets/js/components/EmailTemplatePreview.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { WRITE_TO_CLIPBOARD } from 'core/assets/js/config/settings';
import { BS_STYLE, BS_TOOLTIP_PLACEMENT, ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { viewFetchAC } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import {
  NOTIFICATION_TAGS, NOTIFICATION_TYPE_DESCRIPTIONS, NOTIFICATION_TYPE_LABELS,
} from 'notifier/assets/js/constants';
import { notificationMarkdownToHTML } from 'notifier/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  orgNotificationSettingsFetchApiUrl,
  orgNotificationSettingsResetApiUrl,
  orgNotificationSettingsUpdateApiUrl,
  orgSettingsEmailTemplatesUrl,
} from 'settings/urls';

const BODY_TYPE = {
  HTML: 'html',
  TEXT: 'text',
};

const OrgSettingsEmailTemplateView = () => {
  const activeOrg = useSelector(selectActiveOrg);
  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const componentName = OrgSettingsEmailTemplateView.GetComponentName();
  const { hasLoaded, item } = fetchDataHook({
    componentName, url: orgNotificationSettingsFetchApiUrl(activeOrg.alias, type),
  });
  const [bodyType, setBodyType] = useState(BODY_TYPE.HTML);
  const [resetting, setResetting] = useState(false);
  const typeLabel = NOTIFICATION_TYPE_LABELS[type];
  const bodyTypeIsHTML = bodyType === BODY_TYPE.HTML;
  const listUrl = orgSettingsEmailTemplatesUrl(activeOrg.alias);
  const tags = NOTIFICATION_TAGS[type] || [];
  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Email Templates', url: listUrl }, { title: typeLabel, url: null },
        ]}
      />
      <div className="page page--settings email-template">
        <div className="container">
          {hasLoaded && (
            <>
              <div className="row bg-white rounded shadow-sm d-flex mx-0 px-4 py-3">
                <div className="col-12 col-md-4 d-flex flex-column justify-content-center pl-0">
                  <h1 className="my-0">{typeLabel}</h1>
                  <p>{NOTIFICATION_TYPE_DESCRIPTIONS[type]}</p>
                </div>
                <div
                  className="col-12 col-md-4 d-flex flex-column justify-content-center pl-0 pl-md-5"
                >
                  <div className="d-flex flex-row w-100 justify-content-between pb-3 info-row">
                    <span>Last modified by</span>
                    <span>{item?.updatedByName || '-'}</span>
                  </div>
                  <div className="d-flex flex-row w-100 justify-content-between py-3 info-row">
                    <span>Date</span>
                    <span>{item?.updatedAt || '-'}</span>
                  </div>
                </div>
              </div>
              <Form
                initialValues={pick(item, 'markdown', 'subject', 'text')}
                onSubmit={async values => { // eslint-disable-line consistent-return
                  try {
                    await axios.put(
                      orgNotificationSettingsUpdateApiUrl(activeOrg.alias, type), values,
                    );
                    toastr.success('Well Done!', `"${typeLabel}" has been updated`);
                    history.push(listUrl);
                  } catch (error) {
                    const result = parseAxiosErrorForFinalForm(error);
                    if (result.markdown && !bodyTypeIsHTML) {
                      setBodyType(BODY_TYPE.HTML);
                    } else if (result.text && bodyTypeIsHTML) {
                      setBodyType(BODY_TYPE.TEXT);
                    }
                    return result;
                  }
                }}
                render={({ form, handleSubmit, submitError, submitting }) => (
                  <form className="d-flex flex-column mt-5 px-0" onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between">
                      <div className="bg-white rounded shadow-sm form">
                        <TextInputField
                          className="p-5 mb-0"
                          disabled={resetting || submitting}
                          label="Subject"
                          name="subject"
                          required
                        />
                        <div
                          className="d-flex align-items-center justify-content-between toolbar p-5"
                        >
                          <div
                            className="imitate-link"
                            onClick={() => dispatch(modalOpenAC(
                              MODAL_ID, { ...form.getState().values, type },
                            ))}
                          >
                            <i className={`${ICON.VIEW} mr-3`} />
                            Show preview
                          </div>
                          <div className="d-flex body-type-toggle">
                            <TDButton
                              className={bodyTypeIsHTML ? 'active' : null}
                              label="HTML"
                              onClick={() => setBodyType(BODY_TYPE.HTML)}
                            />
                            <TDButton
                              className={`m-0 ${!bodyTypeIsHTML ? 'active' : ''}`}
                              label="Text"
                              onClick={() => setBodyType(BODY_TYPE.TEXT)}
                            />
                          </div>
                        </div>
                        <TextAreaField
                          className="mx-5 body-text-area"
                          disabled={resetting || submitting}
                          height={400}
                          name={bodyTypeIsHTML ? 'markdown' : 'text'}
                          label="Body"
                          previewFunction={markdown => Promise.resolve(notificationMarkdownToHTML(
                            markdown,
                          ))}
                          required
                          mdEditorEnabled={bodyTypeIsHTML}
                        />
                        {submitError && <div className="text-danger px-5 pb-5">{submitError}</div>}
                      </div>
                      <div
                        className="bg-white rounded shadow-sm tags p-5 d-none d-sm-flex flex-column"
                      >
                        <h3 className="m-0">Placeholder Tags</h3>
                        <p className="hint">Click on a tag to copy to clipboard</p>
                        {tags.map(({ description, dontEscape, tag }) => {
                          let fullTag = `{{${tag}}}`;
                          if (dontEscape) {
                            fullTag = `{${fullTag}}`;
                          }
                          return (
                            <div className="d-flex mb-3 align-items-center" key={tag}>
                              <TDButton
                                className="py-2 px-3"
                                label={fullTag}
                                onClick={async () => {
                                  try {
                                    await WRITE_TO_CLIPBOARD(fullTag);
                                    toastr.success(
                                      'Well Done!', `${fullTag} has been copied to your clipboard`,
                                    );
                                  } catch (error) {
                                    toastr.error(
                                      'Oh Snap!', `Could not copy to clipboard: ${error.message}`,
                                    );
                                  }
                                }}
                              />
                              <TDElementWithTooltip
                                el={<span className="ml-2"><i className={ICON.INFO} /></span>}
                                placement={BS_TOOLTIP_PLACEMENT.TOP}
                                tooltipMsg={description}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                      {item.isCustomized && (
                        <TDButton
                          disabled={resetting || submitting}
                          label="Restore to default"
                          onClick={async () => {
                            setResetting(true);
                            try {
                              const { data } = await axios.put(orgNotificationSettingsResetApiUrl(
                                activeOrg.alias, type,
                              ));
                              dispatch(viewFetchAC(data, componentName));
                              toastr.success('Well Done!', `"${typeLabel}" has been reset`);
                            } catch (error) {
                              toastr.error(
                                'Oh Snap!', error.response?.data?._error || error.message,
                              );
                            } finally {
                              setResetting(false);
                            }
                          }}
                        />
                      )}
                      <TDButton
                        disabled={resetting || submitting}
                        label="Update"
                        type="submit"
                        variant={BS_STYLE.PRIMARY}
                      />
                    </div>
                  </form>
                )}
              />
              <EmailTemplatePreview />
            </>
          )}
          {!hasLoaded && <SettingsPageSkeleton />}
        </div>
      </div>
    </>
  );
};

OrgSettingsEmailTemplateView.GetComponentName = () => 'OrgSettingsEmailTemplateView';

export default OrgSettingsEmailTemplateView;
