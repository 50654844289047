import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PROJECT_TABS } from 'core/assets/js/constants';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectViewUrl, projectListUrl } from 'projects/urls';
import { getViewState } from 'core/assets/js/ducks/view';
import { fetchProjectActiveDS } from 'projects/assets/js/data-services/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProjectRequestBudgetForm from 'projects/assets/js/components/ProjectRequestBudgetForm.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';


class ProjectRequestBudget extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchProjectActiveDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ProjectRequestBudget';
  }

  render() {
    const { project, history, match } = this.props;
    const { orgAlias, id } = match.params;
    const breadcrumbs = [
      {
        title: 'Projects',
        url: projectListUrl(orgAlias),
      },
      {
        title: project.title,
        url: projectViewUrl(orgAlias, id, PROJECT_TABS.PURCHASE_ORDERS),
      },
      {
        title: 'Request Budget',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--add-worksheet">
          <div className="container">
            <TDApiConnected duck="view" component={this.constructor} skeletonComponent={ProjectCardSkeleton}>
              <ProjectRequestBudgetForm
                onSubmitSuccess={() => {
                  history.push(projectViewUrl(orgAlias, id, PROJECT_TABS.PURCHASE_ORDERS));
                }}
                orgAlias={orgAlias}
                project={{
                  id: parseInt(id, 10),
                  currency: project.currency,
                  currencySymbol: project.currency_symbol,
                }}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ProjectRequestBudget.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  project: projectSpec,
};
ProjectRequestBudget.defaultProps = {
  project: {},
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, ProjectRequestBudget.GetComponentName());

  return {
    project: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectRequestBudgetConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectRequestBudget);

export default withRouter(ProjectRequestBudgetConnect);
