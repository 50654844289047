import { fromPairs, get, isEmpty, isEqual, isString, omit } from 'lodash';
import { CURRENCY } from 'core/assets/js/constants';
import { BANK_ACCOUNT_TYPE, PAYMENT_METHODS } from 'settings/assets/js/constants';
import { getObjectDiff, removeNilValues } from 'core/assets/js/lib/utils';
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('banks');

class BankAccountVariant {
  static parseBankAccount(bank) {
    if (!bank) {
      return null;
    }
    const params = {
      userId: bank.user_id,
      bankAccountId: bank.id,
      alias: bank.alias,
      bankAccountType: bank.bank_account_type,
      bankName: bank.bank_name,
      beneficiary: bank.beneficiary,
      currency: bank.currency,
      customReference: bank.custom_reference,
      paypalHandle: bank.handle,
      payoneerPayeeId: bank.payoneer_payee_id,
      transferwiseTypeSelected: bank.transferwise_type_selected,
      dimTransferwiseRecipientId: bank.dim_transferwise_recipient_id,
      dimRevolutCounterPartyId: bank.dim_revolut_counter_party_id,
      ...(bank.bank_account_type === BANK_ACCOUNT_TYPE.TRANSFERWISE ? get(
        bank, 'dimTransferwiseRecipient.details.details', get(bank, 'recipient.details', {}),
      ) : get(bank, 'recipient.details', {})),
    };
    return new BankAccountVariant(params);
  }

  static _remapTWField(key, value, recipientDetails) {
    if (!value) {
      return null;
    }
    const allowedValues = [
      // TD fields
      'alias',
      'currency',
      // explicit bank system fields (using naming of Transferwise)
      'address',
      'email',
      'legalType',
      'accountNumber',
      'sortCode',
      'abartn',
      'accountType',
      'bankgiroNumber',
      'ifscCode',
      'bsbCode',
      'institutionNumber',
      'institutionAddress',
      'intermediaryBic',
      'transitNumber',
      'dialCode',
      'phoneNumber',
      'bankCode',
      'russiaRegion',
      'routingNumber',
      'branchCode',
      'cpf',
      'cardNumber',
      'cardToken',
      'obfCardNumber',
      'idType',
      'idNumber',
      'idCountryIso3',
      'idValidFrom',
      'idValidTo',
      'clabe',
      'swiftCode',
      'dateOfBirth',
      'clearingNumber',
      'bankName',
      'branchName',
      'businessNumber',
      'province',
      'city',
      'rut',
      'token',
      'cnpj',
      'payinReference',
      'pspReference',
      'orderId',
      'idDocumentType',
      'idDocumentNumber',
      'targetProfile',
      'targetUserId',
      'taxId',
      'job',
      'nationality',
      'interacAccount',
      'bban',
      'town',
      'postCode',
      'language',
      'billerCode',
      'customerReferenceNumber',
      'iban',
      'bic',
      'cnaps',
      'givenName',
      'patronymicName',
      'familyName',
    ];
    if (allowedValues.includes(key)) {
      return [key, value];
    }
    switch (key) {
      case 'account_number':
        return ['accountNumber', value];
      case 'account_type':
        return ['legalType', value.toLowerCase() === 'personal' ? 'PRIVATE' : 'BUSINESS'];
      case 'account_subtype':
        return ['accountType', value.toLowerCase() === 'checking' ? 'CHECKING' : 'SAVINGS'];
      case 'ach_routing_no':
        return ['routingNumber', value];
      case 'accountHolderName':
        return ['beneficiary', value];
      case 'address':
        if (isEmpty(recipientDetails.address)) {
          return null;
        }
        if (recipientDetails.address.description) {
          return ['address', { description: value }];
        }
        throw new Error(`unhandled key ${key}`);
      case 'bank_account_type':
        return ['bankAccountType', value];
      case 'bank_account_method_selected':
        return ['transferwiseTypeSelected', value];
      case 'bank_code':
        return ['bankCode', value];
      case 'bank_name':
        return ['bankName', value];
      case 'beneficiary':
        return ['beneficiary', value];
      case 'BIC':
        return ['bic', value];
      case 'bsb_code':
        return ['bsbCode', value];
      case 'country_code':
        return ['countryCode', value];
      case 'custom_reference':
        return ['customReference', value];
      case 'iban':
      case 'IBAN':
        return ['iban', value];
      case 'id':
        return ['bankAccountId', value];
      case 'ifsc_code':
        return ['ifscCode', value];
      case 'institution_no':
        return ['institutionNumber', value];
      case 'sort_code':
        return ['sortCode', value];
      case 'swift':
        return ['swiftCode', value];
      case 'swift_bic_code':
        if (recipientDetails.iban) {
          return ['bic', value];
        }
        return ['swiftCode', value];
      case 'transferwise_type_selected':
        return ['transferwiseTypeSelected', value];
      case 'transit_no':
        return ['transitNumber', value];
      case 'tw_recipient':
        return ['dimTransferwiseRecipientId', value];
      case 'user_id':
        return ['userId', value];
      case 'wire_transfer_number':
        return null;
      case 'created_at':
      case 'is_default':
      case 'requirements':
      case 'updated_at':
      case 'hashedByLooseHashAlgorithm':
      case 'bin':
      case 'balanceAccountProfileId':
      case 'targetAccountId':
      case 'targetAccountType':
        return null;
      default:
        logger.error(recipientDetails);
        logger.error(`unhandled key ${key} with value ${value}`);
        // throw new Error(`unhandled key ${key} with value ${value}`);
        return null;
    }
  }

  static parseTWv1Recipient(recipient, userId) {
    const recipientType = get(recipient, 'type');
    const res = fromPairs(Object.entries(recipient.details).map(([key, value]) => (
      BankAccountVariant._remapTWField(key, value, recipient.details)
    )).filter(v => !!v));

    const {
      currency, country: countryCode,
      accountHolderName: beneficiary,
      id: dimTransferwiseRecipientId,
      type: transferwiseTypeSelected,
    } = recipient;

    Object.assign(res, {
      userId,
      currency: currency.toLowerCase(),
      countryCode,
      beneficiary,
      transferwiseTypeSelected,
      dimTransferwiseRecipientId,
      bankAccountType: BANK_ACCOUNT_TYPE.TRANSFERWISE,
    });

    if (!res.alias) {
      Object.assign(res, { alias: res.bankName });
    }
    if (!res.bankName) {
      Object.assign(res, { bankName: res.alias });
    }
    if (!res.transferwiseTypeSelected) {
      Object.assign(res, { transferwiseTypeSelected: recipientType });
    }
    if (!res.currency) {
      let currencyFromCountry;
      switch (res.countryCode) {
        case 'US':
          currencyFromCountry = 'usd';
          break;
        case 'BE':
          currencyFromCountry = 'eur';
          break;
        case 'GB':
        default:
          currencyFromCountry = 'gbp';
          break;
      }
      Object.assign(res, { currency: currencyFromCountry });
    }
    return new BankAccountVariant(res);
  }

  static isSameBankField(fieldKey, localVal, remoteVal) {
    if (!remoteVal) {
      // here we have no remote value and we may have local ones
      return true;
    }
    if (!localVal) {
      // here we have a remote value but not a local one
      logger.error(`missing local value for remote value ${fieldKey}: ${remoteVal}`);
      return false;
    }
    const localValue = isString(localVal) ? localVal.trim() : localVal;
    const remoteValue = isString(remoteVal) ? remoteVal.trim() : remoteVal;
    if (isEqual(localValue, remoteValue)) {
      return true;
    }
    let same = false;
    switch ((fieldKey || '').toLowerCase()) {
      case 'legaltype':
        same = remoteValue === localValue
          || (remoteValue === 'PERSON' && localValue === 'PRIVATE')
          || (remoteValue === 'INSTITUTION' && localValue === 'BUSINESS');
        break;
      case 'iban':
        same = remoteValue.replace(/\s/g, '') === localValue.replace(/\s/g, '');
        break;
      case 'sortcode':
      case 'rut':
      case 'accountnumber':
        same = remoteValue.replace(/-/g, '') === localValue.replace(/-/g, '');
        break;
      case 'bic':
        same = !remoteValue || (localValue && localValue.includes(remoteValue));
        break;
      case 'address':
        same = Object.entries(localValue).every(([key, value]) => {
          if (key === 'firstLine') {
            return true;
          }
          if (key === 'occupation' && !remoteValue[key]) {
            return true;
          }
          if (key === 'countryCode' && !remoteValue[key]) {
            return isEqual(value, remoteValue.country);
          }

          return isEqual(
            value && value.toLowerCase(),
            remoteValue[key] && remoteValue[key].toLowerCase(),
          );
        });
        break;
      default:
        same = localValue.trim() === remoteValue.trim();
        break;
    }
    return same;
  }

  constructor(...args) {
    this.init(...args);
  }

  init({
    // common td fields
    id, userId, bankAccountId, alias, bankAccountType, bankName, beneficiary, currency = 'gbp',
    countryCode, customReference, isSystem, bankFields,
    // 3rd party fields
    transferwiseTypeSelected, dimTransferwiseRecipientId,
    dimRevolutCounterPartyId,
    paypalHandle,
    payoneerPayeeId,
    // explicit bank system fields (using naming of Transferwise)
    address,
    email,
    legalType,
    accountNumber,
    sortCode,
    abartn,
    accountType,
    bankgiroNumber,
    ifscCode,
    bsbCode,
    institutionNumber,
    institutionAddress,
    intermediaryBic,
    transitNumber,
    dialCode,
    phoneNumber,
    bankCode,
    russiaRegion,
    routingNumber,
    branchCode,
    cpf,
    cardNumber,
    cardToken,
    obfCardNumber,
    idType,
    idNumber,
    idCountryIso3,
    idValidFrom,
    idValidTo,
    clabe,
    swiftCode,
    dateOfBirth,
    clearingNumber,
    branchName,
    businessNumber,
    province,
    city,
    rut,
    token,
    cnpj,
    payinReference,
    pspReference,
    orderId,
    idDocumentType,
    idDocumentNumber,
    targetProfile,
    targetUserId,
    taxId,
    job,
    nationality,
    interacAccount,
    bban,
    town,
    postCode,
    language,
    billerCode,
    customerReferenceNumber,
    iban,
    // grab IBAN too, we will remap this to 'iban' when setting in this.details
    IBAN,
    bic,
    cnaps,
    givenName,
    patronymicName,
    familyName,
  } = {}) {
    this.id = id;
    if (!userId) {
      throw new Error('userId is required');
    }
    // if (!bankAccountId) {
    //   throw new Error('bankAccountId is required');
    // }
    this.details = {
      userId, bankAccountId, alias, bankAccountType, bankName, beneficiary, currency,
      countryCode,
      customReference,
      paypalHandle,
      payoneerPayeeId,
      isSystem,
      transferwiseTypeSelected, dimTransferwiseRecipientId,
      dimRevolutCounterPartyId,
      bankFields: bankFields || {
        address,
        email,
        legalType,
        accountNumber,
        sortCode,
        abartn,
        accountType,
        bankgiroNumber,
        ifscCode,
        bsbCode,
        institutionNumber,
        institutionAddress,
        intermediaryBic,
        transitNumber,
        dialCode,
        phoneNumber,
        bankCode,
        russiaRegion,
        routingNumber,
        branchCode,
        cpf,
        cardNumber,
        cardToken,
        obfCardNumber,
        idType,
        idNumber,
        idCountryIso3,
        idValidFrom,
        idValidTo,
        clabe,
        swiftCode,
        dateOfBirth,
        clearingNumber,
        branchName,
        businessNumber,
        province,
        city,
        rut,
        token,
        cnpj,
        payinReference,
        pspReference,
        orderId,
        idDocumentType,
        idDocumentNumber,
        targetProfile,
        targetUserId,
        taxId,
        job,
        nationality,
        interacAccount,
        bban,
        town,
        postCode,
        language,
        billerCode,
        customerReferenceNumber,
        iban: iban || IBAN,
        bic,
        cnaps,
        givenName,
        patronymicName,
        familyName,
      },
    };
  }

  serialize({ keepEmpty = false } = {}) {
    const ser = {
      ...omit(this.details, ['transferwiseRecipient']),
    };
    if (keepEmpty) {
      return ser;
    }
    const bankFields = fromPairs(Object.entries(ser.bankFields).filter(([, v]) => !!v));
    const res = {
      ...fromPairs(Object.entries(ser).filter(([k, v]) => (
        k !== 'bankFields' && !!v
      ))),
    };
    if (!isEmpty(bankFields)) {
      Object.assign(res, { bankFields });
    }
    return res;
  }

  getDescription() {
    if (this.isTWAccount()) {
      return `#${this.getTransferwiseRecipientId()}`;
    }
    return this.id;
  }

  getUserId() {
    return this.details.userId;
  }

  /**
   * It returns the bank account's BIC number
   *
   * @returns {String}
   */
  getBic() {
    return this.getBankFields().bic;
  }

  /**
   * It returns the bank account's sort code
   *
   * @returns {String}
   */
  getSortCode() {
    return this.getBankFields().sortCode;
  }

  /**
   * It returns the bank account's swift code
   *
   * @returns {String}
   */
  getSwiftCode() {
    return this.getBankFields().swiftCode;
  }

  getBankAccountId() {
    return this.details.bankAccountId;
  }

  getBankName() {
    return this.details.bankName;
  }

  getBankAccountType() {
    return this.details.bankAccountType;
  }

  getCurrency() {
    return this.details.currency;
  }

  getAlias() {
    return this.details.alias;
  }

  getBeneficiary() {
    return this.details.beneficiary;
  }

  getCustomReference() {
    return this.details.customReference;
  }

  getCountryCode() {
    const countryCode = this.details.countryCode || this.getBankField('address.country');
    if (countryCode) {
      return countryCode;
    }
    const currency = this.getCurrency();
    switch (currency) {
      case CURRENCY.GBP:
        return 'GB';
      case CURRENCY.USD:
        return 'US';
      default:
        // logger.error(`cannot match currency ${currency} to a country`);
        return null;
    }
  }

  getPaypalHandle() {
    return this.details.paypalHandle;
  }

  getPayoneerPayeeId() {
    return this.details.payoneerPayeeId;
  }

  getTransferwiseRecipientId() {
    return this.details.dimTransferwiseRecipientId;
  }

  getRevolutCounterPartyId() {
    return this.details.dimRevolutCounterPartyId;
  }

  isTWAccount() {
    return this.details.bankAccountType === BANK_ACCOUNT_TYPE.TRANSFERWISE;
  }

  isPaypalAccount() {
    return this.details.bankAccountType === BANK_ACCOUNT_TYPE.PAYPAL;
  }

  isPayoneerAccount() {
    return this.details.bankAccountType === BANK_ACCOUNT_TYPE.PAYONEER;
  }

  isRevolutAvailable() {
    return !!this.getRevolutCounterPartyId();
  }

  getTransferwiseTypeSelected() {
    return this.details.transferwiseTypeSelected;
  }

  /**
   * Determine if this bank account is convertable to a Revolut counter party.
   * @return {boolean} true if we can make a Revolut counter party from this bank account.
   */
  couldRevolutCounterPartyBeCreated() {
    // currently only support Wise source accounts for Revolut
    if (this.isTWAccount()) {
      // ensure this Wise account type is supported
      const type = this.getTransferwiseTypeSelected();
      if (this._isWiseAccountTypeConvertableToRevolut(type)) {
        return true;
      }
    }

    // can't create a Revolut counter party for this account
    return false;
  }

  isSystemAccount() {
    return this.details.isSystem;
  }

  getBankFields() {
    return this.details.bankFields;
  }

  getBusinessNumber() {
    return this.getBankFields().businessNumber;
  }

  getBankField(key) {
    return get(this.details.bankFields, key);
  }

  getPaymentMethod() {
    const remapBankAccountToPaymentMethod = {
      [BANK_ACCOUNT_TYPE.PAYPAL]: PAYMENT_METHODS.PAYPAL,
      [BANK_ACCOUNT_TYPE.PAYONEER]: PAYMENT_METHODS.PAYONEER,
    };

    return remapBankAccountToPaymentMethod[this.details.bankAccountType]
      || PAYMENT_METHODS.BANK_TRANSFER;
  }

  setUserId(userId) {
    this.details.userId = userId;
  }

  getAddressComponents() {
    const address = this.getBankField('address');
    return removeNilValues({
      city: get(address, 'city'),
      country: get(address, 'country'),
      country_code: get(address, 'country'),
      postal_code: get(address, 'postCode'),
      street: get(address, 'firstLine'),
    });
  }

  matches(other) {
    const nonMatching = this.nonMatchingFields(other);
    if (isEmpty(nonMatching)) {
      return true;
    }
    logger.info(`${other.getDescription()} has mismatch on fields "${nonMatching.join(',')}"`);
    return false;
  }

  nonMatchingFields(other) {
    const thisBankFields = removeNilValues(this.getBankFields());
    const otherBankFields = removeNilValues(other.getBankFields());
    const nonMatching = getObjectDiff(thisBankFields, otherBankFields);
    return nonMatching.filter(key => !BankAccountVariant.isSameBankField(
      key, this.getBankField(key), other.getBankField(key),
    ));
  }

  mayMatch(other) {
    if (this.getUserId() !== other.getUserId()) {
      // logger.debug(
      //   `different userId "${this.getUserId()}" - "${other.getUserId()}"`
      // );
      return false;
    }

    if (this.getCurrency() !== other.getCurrency()) {
      // logger.debug(
      //   `different currency "${this.getCurrency()}" - "${other.getCurrency()}"`
      // );
      return false;
    }

    if (this.getBeneficiary() !== other.getBeneficiary()) {
      // logger.debug(
      //   `different beneficiary "${this.getBeneficiary()}" - "${other.getBeneficiary()}"`
      // );
      return false;
    }

    return true;
  }

  findMatching(others) {
    const candidates = others.filter(c => this.mayMatch(c));
    if (isEmpty(candidates)) {
      return null;
    }
    logger.log(`checking if variant matches ${candidates.length} remote variants (${candidates.map(c => c.getDescription()).join(',')})`);
    const matching = candidates.filter(c => this.matches(c));
    if (isEmpty(matching)) {
      return null;
    }
    const [candidate] = matching;

    logger.info(`matched '${this.getBeneficiary()}' to variant ${candidate.getDescription()}`);
    return candidate;
  }

  matchTWv1Recipients(recipients) {
    const others = recipients.map(c => BankAccountVariant.parseTWv1Recipient(c, this.getUserId()));
    const match = this.findMatching(others);
    if (match) {
      const recipient = recipients.find(r => r.id === match.getTransferwiseRecipientId());
      return recipient;
    }
    return null;
  }

  /**
   * Determine if Wise selected type is convertable to a Revolut counter party.
   * We need to write converters for each Wise type, so we should know which ones we have
   * and therefore which Wise types are supported.
   *
   * We can use this to determine if a particle Wise account is a
   * candidate for Revolut before beginning the workflow.
   *
   * @param {string} wiseTypeSelected - the Wise type which was selected.
   * @return {boolean} true when the Wise type is convertable.
   */
  // eslint-disable-next-line class-methods-use-this
  _isWiseAccountTypeConvertableToRevolut(wiseTypeSelected) {
    return [
      'iban', 'sort_code', 'aba', 'swift_code',
      'southafrica', 'malaysian', 'australian',
    ].includes(wiseTypeSelected);
  }

  /**
   * Convert TrW vendor specific information to revolut counter party.
   *
   * Parse fields based on underlying account type.
   *
   * @return {Object} parsed vendor specific properties.
   */
  _convertFromTwBankAccountToRevolutCounterParty() {
    const parsedBankAccount = {};

    // get and validate Wise account type
    const type = this.getTransferwiseTypeSelected();
    if (!this._isWiseAccountTypeConvertableToRevolut(type)) {
      throw new Error(`Do not know how to convert Wise - ${type} to Revolut counter party`);
    }

    // build counter party details based on account type
    switch (type) {
      case 'iban':
        parsedBankAccount.iban = this.getBankField('iban');
        break;
      case 'sort_code':
        parsedBankAccount.account_no = this.getBankField('accountNumber');
        parsedBankAccount.sort_code = this.getBankField('sortCode');
        break;
      case 'aba':
        parsedBankAccount.routing_number = this.getBankField('abartn');
        parsedBankAccount.account_no = this.getBankField('accountNumber');
        break;
      case 'swift_code':
      case 'southafrica':
      case 'malaysian':
        parsedBankAccount.bic = this.getBankField('swiftCode');
        parsedBankAccount.account_no = this.getBankField('accountNumber');
        break;
      case 'australian':
        parsedBankAccount.bsb_code = this.getBankField('bsbCode');
        parsedBankAccount.account_no = this.getBankField('accountNumber');
        break;
      default:
        throw new Error(`Unhandled account type when parsing - ${type}`);
    }

    return parsedBankAccount;
  }

  /**
   * Convert bank account variant to revolut counter party.
   *
   * Build shared properties regardless of underlying vendor.
   *
   * Defer parsing of vendor properties to a vendor specific converter.
   *
   * @return {Object} revolut counter party payload.
   */
  convertToRevolutCounterParty() {
    // parse common details
    const parsedBankAccount = {
      currency: (this.getCurrency() || '').toUpperCase(),
      bank_country: this.getCountryCode(),
      address: {
        street_line1: this.getAddressComponents().firstLine,
        country: this.getAddressComponents().country,
        postcode: this.getAddressComponents().postal_code,
      },
      beneficiary_address: {
        street_line1: this.getAddressComponents().firstLine,
        country: this.getAddressComponents().country,
        postcode: this.getAddressComponents().postal_code,
      },
    };

    // include 'optional' address components
    if (this.getAddressComponents().city) {
      parsedBankAccount.address.city = this.getAddressComponents().city;
      parsedBankAccount.beneficiary_address.city = this.getAddressComponents().city;
    }

    // parse legal type
    if (this.getBankField('legalType')) {
      switch (this.getBankField('legalType')) {
        case 'BUSINESS':
          parsedBankAccount.profile_type = 'business';
          parsedBankAccount.company_name = this.getBeneficiary();
          break;
        case 'PRIVATE':
          parsedBankAccount.profile_type = 'personal';
          // note - if we want to use 'individual_name' then we need separate first and last names,
          //        which we may not have for now, lets 'cheat' and use company_name which expects
          //        a combined value
          parsedBankAccount.company_name = this.getBeneficiary();
          break;
        default:
          throw new Error(`Unhandled legal type - ${this.getBankField('legalType')}`);
      }
    }

    // parse vendor specific account types and details
    if (this.isTWAccount()) {
      return {
        ...parsedBankAccount,
        ...this._convertFromTwBankAccountToRevolutCounterParty(),
      };
    }

    // error if we don't know how to parse this bank account variant currently
    throw new Error('Currently, can only create revolut counter parties from TrW accounts');
  }

  /**
   * Check if we should create a Revolut counter party or not.
   *
   * Note, when comparing bank account details, we need to only compare those
   * fields relevant to the bank details, ie ids such as the revolut counter party
   * itself won't exist in the payload we generate, so we need to filter that out.
   * Better to do this with a blacklist rather than a whitelist, so we reduce the
   * risk of bugs where we filter details of bank accounts we may add later on.
   *
   * We should create a new one if -
   * 1. We don't already have one, and one can be created
   * 2. We already have one, but the details are different to the payload we would use now
   *
   * We should NOT create a new one if -
   * 1. We know creation will fail, ie the bank account is not of a covertable type
   * 2. The details we would use now match those in the current dim revolut counter party
   *
   * @param {object} dimRevolutCounterPartyDetails - details_dump from dim_revolut_counter_party.
   * @return {boolean} true if we should create a new revolut counter party.
   */
  shouldCreateRevolutCounterParty(dimRevolutCounterPartyDetails) {
    // 1. check existing counter party id, and if one could be created
    const doesExistingCounterPartyExist = this.isRevolutAvailable();
    const canCounterPartyBeCreated = this.couldRevolutCounterPartyBeCreated();
    // return 'create' if not found and can be created
    if (!doesExistingCounterPartyExist && canCounterPartyBeCreated) {
      // create a new counter party
      return true;
    }
    // return 'dont create' if an existing counter party doesn't exist and can't be created
    if (!canCounterPartyBeCreated) {
      // don't try to create a new countery party, it will fail
      return false;
    }

    // 2. build payload for revolut counter party create, and compare to cache
    let payload;
    try {
      payload = this.convertToRevolutCounterParty();
    } catch (e) {
      logger.error(`Failed to parse bank account for revolut counter party, to compare with cache - ${e}`);
    }

    // return return true if do NOT match, or either is missing
    // note, we only want to check common propertiers between the two structures
    // ie those directly relating to the details of the bank account
    const blackList = [
      // payload
      'address',
      'beneficiary_address',
      'profile_type',
      'company_name',
      // dim revolut counter party
      'id',
      'type',
      'name',
      'recipient_charges',
    ];
    if (!payload
      || !dimRevolutCounterPartyDetails
      || !isEqual(
        omit(dimRevolutCounterPartyDetails, blackList),
        omit(payload, blackList),
      )) {
      // return 'create' if payload appears to be different to what we have cached
      return true;
    }

    // existing cached revolut counter party id appears to be good to be reused
    return false;
  }
}

export default BankAccountVariant;
