import PropTypes from 'prop-types';
import React from 'react';

import { BS_SIZE, HS_FORM_ID_REG_EX } from 'core/assets/js/constants';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import { calculatePasswordStrength } from 'core/assets/js/lib/utils';

const TextInputComponent = ({
  additionalError,
  className,
  'data-testid': dataTestId,
  disabled,
  extraInfo,
  input,
  label,
  maxLength,
  meta: { error, pristine, submitError, touched },
  muteErrorMessage,
  placeholder,
  popOverContent,
  popOverSize,
  popOverTitle,
  prefix,
  required,
  showStrengthIndicator: initialShowStrengthIndicator,
  step,
  sublabel,
  suffix,
  onChange,
}) => {
  const showAdditionalError = pristine && additionalError && !muteErrorMessage;
  const hasError = ((touched && (error || submitError)) || showAdditionalError)
    && !muteErrorMessage;
  const showStrengthIndicator = input.type === 'password' && initialShowStrengthIndicator;

  const classNames = ['form-group'];

  if (className) {
    classNames.push(className);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  let feedback;
  let msg;
  if (showStrengthIndicator) {
    classNames.push('password-strength-meter', 'has-feedback');
    ({ feedback, msg } = calculatePasswordStrength(input.value));
  }

  const hbFormId = label?.props?.text
    && HS_FORM_ID_REG_EX.test(label.props.text) && label.props.text.match(HS_FORM_ID_REG_EX)[1];

  let field = (
    <input
      className="form-control"
      placeholder={placeholder}
      autoComplete="off"
      disabled={disabled}
      maxLength={maxLength}
      id={`field-${input.name}`}
      {...input}
      onChange={(...args) => {
        onChange(...args);
        input.onChange(...args);
      }}
      step={step}
    />
  );

  if (prefix || suffix) {
    field = (
      <div className="input-group">
        {prefix && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prefix}</span>
          </div>
        )}
        {field}
        {suffix && (
          <div className="input-group-append">
            <span className="input-group-text">{suffix}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <TDLabel
        name={input.name}
        label={label}
        required={required}
        sublabel={sublabel}
        popOverTitle={popOverTitle}
        popOverContent={popOverContent}
        popOverSize={popOverSize}
        extraInfo={extraInfo}
      />

      {showAdditionalError && (
        <span className="help-block mt-3">{additionalError}</span>
      )}

      {/* Hide field if a hubspot form id is found in label's content */}
      {!hbFormId && field}

      {showStrengthIndicator && input.value !== '' && (
        <span className="form-control-feedback">
          <span className={`text-${feedback.className}`}>
            <i className={feedback.icon} />
            {' '}
            {feedback.label}
          </span>
        </span>
      )}
      {/* Show error only if strength indicator is disabled or msg is missing. */}
      {hasError && (!showStrengthIndicator || !input.value) && (
        <span className="help-block d-inline-block mt-3">{submitError || error}</span>
      )}
      {showStrengthIndicator && msg && feedback.showTextMessage && input.value && (
        <div className={`password-strength-meter__msg text-${feedback.className}`}>
          <span dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      )}
    </div>
  );
};

TextInputComponent.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  extraInfo: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  maxLength: PropTypes.number,
  meta: finalFormFieldMetaSpec,
  muteErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.string,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefix: PropTypes.string,
  required: PropTypes.bool,
  showStrengthIndicator: PropTypes.bool,
  step: PropTypes.number,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
};

TextInputComponent.defaultProps = {
  additionalError: '',
  className: null,
  'data-testid': 'text-input-field',
  disabled: false,
  extraInfo: null,
  label: '',
  maxLength: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  muteErrorMessage: false,
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
  placeholder: '',
  prefix: null,
  required: false,
  showStrengthIndicator: false,
  step: null,
  sublabel: null,
  suffix: null,
  onChange: () => { },
};

export default withField(TextInputComponent);
