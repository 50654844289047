import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { BS_STYLE } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';

const FORM_ID = 'orgCustomSalesTaxMsgForm';

const OrgCustomSalesTaxMsgForm = ({ modalState, initialValues, onSubmit }) => {
  function _handleSubmit(values) {
    onSubmit(values).then(() => modalState.close());
  }

  return (
    <React.Fragment>
      <span data-testid="custom-sales-tax-msg-cta" onClick={modalState.open} className="imitate-link">Edit</span>
      <ModalSimple
        open={modalState.isOpen}
        heading="Set VAT/Sales tax guidelines"
        onClose={modalState.close}
        noFooter
        body={(
          <Form
            onSubmit={_handleSubmit}
            name={FORM_ID}
            initialValues={initialValues}
            render={({ submitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <p className="mb-4">
                      The following guidelines will show up to all organization&apos;s
                      providers when they are updating their sales tax setting.
                    </p>
                    <TextAreaField
                      name="custom_sales_tax_msg"
                      label="VAT/Sales tax guidelines"
                      type="textarea"
                    />
                  </div>
                </div>
                <div className="modal-footer mx-0 mt-5 border-top-0 mb-0 pb-0">
                  <TDButton
                    disabled={submitting}
                    onClick={modalState.close}
                    label="Cancel"
                  />

                  <TDButton
                    disabled={submitting}
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    label="Save"
                  />
                </div>
              </form>
            )}
          />
        )}
      />
    </React.Fragment>
  );
};

OrgCustomSalesTaxMsgForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  modalState: modalStateSpec.isRequired,
};

const OrgCustomSalesTaxMsgFormModal = withStateModal(OrgCustomSalesTaxMsgForm);

export default OrgCustomSalesTaxMsgFormModal;
