import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import DocumentAssignmentsList from 'core/assets/js/components/DocumentAssignmentsList.jsx';
import AnswersList from 'core/assets/js/components/AnswersList.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import { fetchProjectTaskAnswers } from 'projects/assets/js/data-services/tasks';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';

const ProjectTaskInvitationContent = ({ task, documentAssignments, answers, storeKey }) => {
  const {
    attachments: taskAttachments,
    deadline: taskDeadline,
    description: taskDescription,
    id: taskId,
    projectId,
    startsOn,
  } = task;
  const taskStartsOnDateFormatted = formatDate(startsOn, DATE_FORMAT_DEFAULT);
  return (
    <div className="project__details">
      <TDApiConnected
        duck="list"
        storeKey={storeKey}
        skeletonComponent={ListSkeleton}
        fetchData={({
          dispatch, params, authedAxios, url,
        }) => {
          const { orgAlias } = params;
          return dispatch(fetchProjectTaskAnswers({
            orgAlias,
            projectId,
            taskId,
            authedAxios,
            url,
            componentName: storeKey,
          }));
        }}
      >
        <h4>Task Description</h4>
        <MarkdownText
          withBreaksPlugin
          text={taskDescription}
        />

        { documentAssignments?.length > 0 && (
          <DocumentAssignmentsList documentAttachments={documentAssignments} />
        )}

        <AttachmentsList attachments={taskAttachments.map(a => a.attachment)} />

        {taskStartsOnDateFormatted && (
          <div className="mb-3">
            <h4>Starts on</h4>
            <div>{formatDate(taskStartsOnDateFormatted)}</div>
          </div>
        )}

        {taskDeadline && (
          <div className="mb-3">
            <h4>Deadline</h4>
            <div>{formatDate(taskDeadline)}</div>
          </div>
        )}

        {answers && (
          <AnswersList
            answers={answers}
            labelClass="h3 mb-2 text-dark"
            wrapperClass="answer-value mb-4"
          />
        )}

        <h4>Skills</h4>
      </TDApiConnected>
    </div>
  );
};

ProjectTaskInvitationContent.propTypes = {
  answers: PropTypes.array,
  documentAssignments: PropTypes.array,
  storeKey: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
};
ProjectTaskInvitationContent.defaultProps = {
  answers: [],
  documentAssignments: [],
};

const mapStateToProps = (state, props) => {
  const { task, storeKey } = props;
  const listState = getListState(state, storeKey);
  return {
    answers: listState.items,
    task,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectTaskInvitationContentConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTaskInvitationContent);

export default ProjectTaskInvitationContentConnected;
