import { CURRENCY_BY_SYMBOL, CURRENCIES_WITHOUT_DECIMALS } from 'core/assets/js/constants';
import { isAmount } from 'core/assets/js/lib/utils';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';


/**
 * Display a rate amount, unit and symbol - currency symbol or % - in html or text format
 *
 * @param rate
 * @param rateUnit
 * @param currency refers to currency Symbol
 * @param withRateUnit
 * @returns {*}
 */
export const getDisplayRate = (rateAmount, rateUnit, { // eslint-disable-line
  currency: currencySymbol = '',
  withRateUnit = true,
} = {}) => {
  if (!isAmount(rateAmount)) {
    return '';
  }

  rateUnit = parseInt(rateUnit, 10); // eslint-disable-line no-param-reassign

  if (Object.values(RATE_UNIT).indexOf(rateUnit) === -1) {
    throw new Error(`Invalid rate unit (${rateUnit})`);
  }

  let symbol = currencySymbol;
  let prepend = true;
  const isCommission = rateUnit === RATE_UNIT.COMMISSION;
  const currency = isCommission ? null : CURRENCY_BY_SYMBOL[symbol];

  if (isCommission) {
    symbol = '%';
    prepend = false;
  }

  const rateUnitFormat = RATE_UNIT_FORMAT[rateUnit];
  let rate = rateUnitFormat.format(rateAmount);
  const rateUnitFormatted = withRateUnit && rateUnitFormat.abbreviation
    ? ` ${rateUnitFormat.abbreviation}`
    : '';

  if (
    !isCommission
    && CURRENCIES_WITHOUT_DECIMALS.includes(currency)
    && rate.includes('.')
  ) {
    rate = rate.split('.')[0];
  }

  if (prepend) {
    return `${symbol}${rate}${rateUnitFormatted}`;
  }

  return `${rate}${symbol}${rateUnitFormatted}`;
};
