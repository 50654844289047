import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { PROJECT_STATUS, PROJECT_STATUS_CLASS } from 'projects/assets/js/constants';
import NumberFormat from 'react-number-format';


const BudgetProgressBar = ({
  budget, budgetUsed, currencySymbol, projectId, projectStatus, tdStyle, tooltip,
}) => {
  if (budget === null) {
    return null;
  }

  const statusClass = PROJECT_STATUS_CLASS[projectStatus];
  let progress = Math.floor((parseFloat(budgetUsed) / parseFloat(budget)) * 100);
  progress = progress > 100 || projectStatus === PROJECT_STATUS.COMPLETED ? 100 : progress;
  const budgetTooltip = (
    <Tooltip id={`budget-tooltip-${projectId}`}>
      <NumberFormat
        value={budgetUsed || 0}
        prefix={currencySymbol}
        displayType="text"
        thousandSeparator
      />
    </Tooltip>
  );

  const progressBar = (
    <div
      className={`td-progress-bar td-progress-bar--${statusClass} ${tdStyle === 'wrapped' ? 'td-progress-bar--wrapped' : ''}`}
      style={{ width: `${progress}%` }}
    >
      {tooltip && (
        <OverlayTrigger
          placement="top"
          overlay={budgetTooltip}
        >
          <span className="indicator" />
        </OverlayTrigger>
      )}
      {!tooltip && <span className="indicator" />}
    </div>
  );

  if (tdStyle === 'wrapped') {
    return (
      <div className="budget-progress-bar__wrapper">
        {progressBar}
      </div>
    );
  }

  return progressBar;
};

BudgetProgressBar.propTypes = {
  budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  budgetUsed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencySymbol: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  projectStatus: PropTypes.oneOf(Object.values(PROJECT_STATUS)),
  tdStyle: PropTypes.string,
  tooltip: PropTypes.bool,
};

BudgetProgressBar.defaultProps = {
  currencySymbol: '',
  projectStatus: PROJECT_STATUS.POSTED,
  projectId: null,
  budget: null,
  budgetUsed: null,
  tdStyle: '',
  tooltip: true,
};

export default BudgetProgressBar;
