import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';

import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import TDApiConnected, { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { BS_STYLE, MODAL_SIZES, USER_CARD_DEACTIVATE_COUNT_ITEMS } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { getViewState } from 'core/assets/js/ducks/view';
import { getOutstandingCountsDS } from 'organizations/assets/js/data-services/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import ManagerDeactivatePendingItems from 'people/assets/js/components/manager-deactivate/ManagerDeactivatePendingItems.jsx';
import ManagerDeactivateReview from 'people/assets/js/components/manager-deactivate/ManagerDeactivateReview.jsx';
import { deactivateUserCardDS } from 'people/assets/js/ducks/people';
import { fetchOrgManagerOptions } from 'people/urls';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';

export const getModalId = userId => `manager-deactivate-id-${userId}`;

const ManagerDeactivateModal = ({
  activeOrg,
  dispatch,
  isModalOpen,
  listViewComponentName,
  outstandingCounts,
  outstandingCountsHasLoaded,
  userEmail,
  userId,
  userName,
  managers,
}) => {
  const onClose = () => dispatch(modalCloseAC());
  const hasOutstandingItems = Object.keys(outstandingCounts).some(key => (
    outstandingCounts[key].length > 0
  ));

  const billingProcessDate = useMemo(() => {
    if (activeOrg.invoicing_frequency_dump) {
      const invFreq = InvoicingFrequency.fromDump(activeOrg.invoicing_frequency_dump);
      return formatDate(invFreq.getNextBillingProcess().getBillingDate());
    }
    return null;
  }, [activeOrg]);

  return (
    <div className="static-modal">
      <Modal
        data-testid="manager-deactivate-modal"
        onExited={onClose}
        onHide={onClose}
        size={MODAL_SIZES.LARGE}
        show={isModalOpen}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Deactivate ${userName} in ${activeOrg.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TDApiConnected
            duck="view"
            storeKey={getModalId(userId)}
            fetchData={
              ({ authedAxios, componentName }) => dispatch(getOutstandingCountsDS({
                authedAxios, componentName, orgAlias: activeOrg.alias, userId,
              }))
            }
          >
            {outstandingCountsHasLoaded && (
              <Wizard
                className="deactivate-manager-form"
                confirmStyle={BS_STYLE.DANGER}
                onCancel={onClose}
                onSubmit={async values => {
                  try {
                    await dispatch(deactivateUserCardDS(
                      activeOrg.alias, userId, listViewComponentName, values,
                    ));
                    toastr.success('Well Done!', `Manager ${userName} successfully deactivated`);
                    onClose();
                  } catch (e) {
                    toastr.error('Oh Snap!', e._error || e.message);
                  }
                }}
                showSteps={hasOutstandingItems}
                submitBtnTitle="Deactivate manager"
                withShadow={false}
              >
                {hasOutstandingItems && (
                  <Wizard.Page
                    beforePageChange={async formValues => {
                      const errors = {};
                      outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECTS].forEach(p => {
                        const fieldName = `project_${p.id}`;
                        if (!formValues[fieldName]) {
                          errors[fieldName] = 'Please select a new owner for this project';
                        }
                      });
                      outstandingCounts[USER_CARD_DEACTIVATE_COUNT_ITEMS.TASKS].forEach(task => {
                        const fieldName = `task_${task.id}`;
                        if (!formValues[fieldName]) {
                          errors[fieldName] = 'Please select a new manager for this task';
                        }
                      });
                      if (Object.keys(errors).length > 0) {
                        return errors;
                      }
                      return null;
                    }}
                    component={ManagerDeactivatePendingItems}
                    componentProps={{
                      nextOrgPaymentDate: billingProcessDate,
                      orgAlias: activeOrg.alias,
                      orgName: activeOrg.name,
                      outstandingCounts,
                      userEmail,
                      userId,
                      userName,
                      managers,
                    }}
                    title="Pending items"
                  />
                )}
                <Wizard.Page
                  component={ManagerDeactivateReview}
                  componentProps={{
                    orgAlias: activeOrg.alias,
                    outstandingCounts,
                    userName,
                    managers,
                  }}
                  title="Review"
                />
              </Wizard>
            )}
          </TDApiConnected>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ManagerDeactivateModal.GetComponentName = () => 'ManagerDeactivateModal';

ManagerDeactivateModal.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  listViewComponentName: PropTypes.string.isRequired,
  outstandingCounts: PropTypes.object,
  outstandingCountsHasLoaded: PropTypes.bool,
  userEmail: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  managers: PropTypes.array.isRequired,
};

ManagerDeactivateModal.defaultProps = {
  outstandingCounts: {},
  outstandingCountsHasLoaded: false,
};

const ManagerDeactivateModalApiConnected = withTDApiConnected({
  duck: 'list',
  fetchData: ({ authedAxios, componentName, dispatch, querystring, url, params }) => (
    dispatch(fetchListDS({
      authedAxios,
      componentName,
      querystring,
      url: fetchOrgManagerOptions(params.orgAlias, url),
    }))
  ),
  skeletonComponent: ListSkeleton,
  storeKey: ManagerDeactivateModal.GetComponentName(),
})(ManagerDeactivateModal);


const mapStateToProps = (state, props) => {
  const modalId = getModalId(props.userId);
  const viewState = getViewState(state, modalId);
  const listState = getListState(state, ManagerDeactivateModal.GetComponentName());
  return {
    activeOrg: selectActiveOrg(state),
    isModalOpen: getIsModalOpen(state, modalId),
    outstandingCounts: viewState.item,
    outstandingCountsHasLoaded: viewState.hasLoaded,
    managers: listState.items,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ManagerDeactivateModalConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ManagerDeactivateModalApiConnected);

export default ManagerDeactivateModalConnected;
