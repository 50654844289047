import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import InputField, {
  INPUT_TYPE,
} from 'core/assets/js/components/ReduxFormFields/InputField.jsx';


const RateField = ({ currencySymbol, name, placeholder, required, unit, showLabel }) => {
  const isCommissionBased = unit === RATE_UNIT.COMMISSION;
  const isCustomRate = unit === RATE_UNIT.CUSTOM;
  const currency = !isCommissionBased
    ? currencySymbol
    : '';
  const rateUnitFormat = RATE_UNIT_FORMAT[unit];
  const title = !isCustomRate ? rateUnitFormat.title : 'Rate';
  const label = showLabel ? title : '';

  return (
    <Field
      name={name}
      type={INPUT_TYPE.MONEY}
      moneyDecimals={rateUnitFormat.decimals}
      component={InputField}
      suffix={currency}
      required={required}
      label={label}
      placeholder={placeholder || rateUnitFormat.fieldHelpText}
      min={rateUnitFormat.limit.min}
      max={rateUnitFormat.limit.max}
      step={rateUnitFormat.limit.step}
      validate={rateUnitFormat.validate}
    />
  );
};
RateField.propTypes = {
  currencySymbol: PropTypes.oneOf([
    ...Object.values(CURRENCY_SYMBOL),
    '',
  ]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  unit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  showLabel: PropTypes.bool,
};
RateField.defaultProps = {
  currencySymbol: null,
  name: 'rate_amount',
  placeholder: '',
  required: true,
  unit: RATE_UNIT.PER_HOUR,
  showLabel: true,
};

export default RateField;
