import React from 'react';

import IntegrationAccountMappingSettings from 'integrations/assets/js/components/settings/IntegrationAccountMappingSettings.jsx';

const IntegrationSetupAccountMapping = () => (
  <>
    <h2>Map accounts</h2>
    <IntegrationAccountMappingSettings />
  </>
);

export default IntegrationSetupAccountMapping;
