import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { isMenuItemActive } from 'core/assets/js/lib/utils';
import { tncUrl, referralProgramUrl } from 'core/urls';
import { organizationsUrl } from 'organizations/urls';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectShouldHaveCompanyDetails } from 'accounts/assets/js/ducks/account';
import { ICON, IMG_SIZE } from 'core/assets/js/constants';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { settingsAccountUrl } from 'settings/urls';
import { profileSettingsUrl, timeOffUrl } from 'accounts/urls';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { UNKNOWN_ALIAS } from 'organizations/assets/js/constants';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const AccountDropdown = ({ activeOrg, history, location, match, orgAlias, profile }) => {
  if (!profile) {
    return null;
  }

  const { name, jobTitle, avatar } = profile;
  const classNames = ['d-flex p-3 account-drop-down__toggle'];
  const isActive = isMenuItemActive(
    match, location,
    [`${orgAlias}/settings/profile`,
      `${orgAlias}/settings/account`,
      `${orgAlias}/organizations`,
      `${orgAlias}/settings/time-off`,
    ],
  );

  if (isActive) {
    classNames.push('account-drop-down__toggle--active');
  }

  const toggle = (
    <div className={classNames.join(' ')}>
      <ProfilePic
        url={avatar}
        alt={name}
        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
        className="mr-3 align-self-center col-auto"
      />

      <div className="flex-grow-1 flex-shrink-1 w-auto overflow-hidden pr-3">
        <div className="w-100">
          <div title={name} className="truncate account-drop-down__name">{name}</div>
        </div>
        <div className="w-100">
          <div title={jobTitle} className="truncate pr-2 account-drop-down__job-title">
            {jobTitle}
          </div>
        </div>
      </div>

      <span className="align-self-center ml-auto">
        <i className={`${ICON.CHEVRON_DOWN} navbar-item__chevron`} />
      </span>
    </div>
  );

  const onItemSelect = ({ path }) => {
    history.push(path);
  };

  const { url: profileUrl } = profile;

  return (
    <TDDropButton
      data-testid="account-dropdown-menu-item"
      overlayClassName="account-drop-down"
      className="mt-1 mb-5"
      placement="right-end"
      toggleEl={toggle}
      popperConfig={{
        modifiers: {
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          offset: {
            offset: '10px, -20px',
          },
        },
      }}
    >
      { profileUrl && (
        <NavLink
          onClick={onItemSelect}
          to={profileUrl}
          data-testid="nav-bar-item-p"
          className="dropdown-item account-drop-down__sub-item px-3 py-3"
        >
          <div className="d-flex px-3 w-100">
            <ProfilePic
              url={avatar}
              alt={name}
              size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
              className="mr-4 align-self-center"
            />

            <div className="flex-grow-1 flex-shrink-1 w-auto overflow-hidden">
              <div className="w-100">
                <div title={name} className="truncate account-drop-down__name">{name}</div>
              </div>
              <div className="w-100">
                <div title={jobTitle} className="truncate account-drop-down__job-title">
                  {jobTitle}
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      <Dropdown.Item
        key="my-settings"
        eventKey="my-settings"
        onSelect={() => onItemSelect({ path: settingsAccountUrl(orgAlias) })}
      >
        <i className={ICON.COG} />
        <strong>My Settings</strong>
      </Dropdown.Item>

      <NavLink
        to={settingsAccountUrl(orgAlias)}
        className="dropdown-item account-drop-down__sub-item"
      >
        Account
      </NavLink>

      <NavLink
        to={profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION)}
        className="dropdown-item account-drop-down__sub-item"
      >
        Profile
      </NavLink>

      {activeOrg.show_availability && (
        <NavLink
          to={timeOffUrl(orgAlias)}
          className="dropdown-item account-drop-down__sub-item"
        >
          Availability
        </NavLink>
      )}

      <NavLink
        to={organizationsUrl(orgAlias)}
        className="dropdown-item"
      >
        <i className={ICON.BUILDING} />
        <strong>Organisations</strong>
      </NavLink>

      <Dropdown.Item
        data-testid="account-dropdown-menu-logout"
        key="logout"
        eventKey="logout"
        onSelect={() => {
          WINDOW_REDIRECT('/accounts/logout');
        }}
      >
        <i className={ICON.LOGOUT} />
        <strong>Log out</strong>
      </Dropdown.Item>

      <Dropdown.Divider />

      <a
        className="dropdown-item"
        rel="noopener noreferrer"
        target="_blank"
        href={referralProgramUrl()}
      >
        Referral program
      </a>

      <a
        className="dropdown-item"
        rel="noopener noreferrer"
        target="_blank"
        href={tncUrl()}
      >
        Legal
      </a>
    </TDDropButton>
  );
};

AccountDropdown.propTypes = {
  activeOrg: orgSpec.isRequired,
  hasCompanyDetails: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  orgAlias: PropTypes.string,
  profile: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    jobTitle: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

AccountDropdown.defaultProps = {
  orgAlias: UNKNOWN_ALIAS,
  profile: null,
};

const mapStateToProps = (state) => {
  const activeUserCard = selectActiveUserCard(state);
  const profile = selectProfile(state);

  if (activeUserCard.profileUrl) {
    profile.url = activeUserCard.profileUrl;
  }

  return {
    activeOrg: selectActiveOrg(state),
    hasCompanyDetails: selectShouldHaveCompanyDetails(state),
    profile: selectProfile(state),
  };
};


const AccountDropdownConnect = connect(
  mapStateToProps,
)(AccountDropdown);
export default withRouter(AccountDropdownConnect);
