import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'core/assets/js/lib/utils';
import { ACTIVITY_DATE_FORMAT } from 'notifier/assets/js/constants';

const SystemMessage = ({ className, append, message, timestamp }) => (
  <div className={`discussion-board__row db-system-message ${className}`.trim()}>
    <p>
      <span>
        {message}
      </span>
      <time className="discussion-board__timestamp">
        {formatDate(timestamp, ACTIVITY_DATE_FORMAT)}
      </time>
    </p>
    {append}
  </div>
);

SystemMessage.propTypes = {
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  className: PropTypes.string,
  append: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SystemMessage.defaultProps = {
  className: '',
  append: null,
};

export default SystemMessage;
