import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { BANK_CURRENCY_SYMBOL_LABEL_SELECT } from 'core/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import SelectField from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import AccountTypeFields from 'settings/assets/js/components/bank-account-forms/AccountTypeFields.jsx';
import { countryCodeSpec } from 'core/assets/js/lib/objectSpecs';
import { countryOptions } from 'core/assets/js/lib/isoCountries';

const DEFAULT_COUNTRY_CODE = 'GB';
export const FORM_ID = 'bankAccountForm';

const BankAccountForm = ({
  countryCode,
}) => (
  <form className="p-0 ">
    <div className="bg-white p-5 shadow-sm rounded">
      <div className="row">
        <div className="col-12 col-sm-6">
          <Field
            name="country_code"
            type="text"
            optionsMapping={countryOptions}
            component={SelectField}
            label="Country"
          />
        </div>
        <div className="col-12 col-sm-6">
          <Field name="bank_name" type="text" component={InputField} label="Bank Name" />
        </div>
      </div>
      <AccountTypeFields countryCode={countryCode} />
      <div className="row">
        <div className="col-12 col-sm-6">
          <Field
            name="beneficiary"
            type="text"
            component={InputField}
            label="Beneficiary"
          />
        </div>
        <div className="col-12 col-sm-6">
          <Field
            required
            name="currency"
            component={SelectField}
            label="Currency"
            optionsMapping={BANK_CURRENCY_SYMBOL_LABEL_SELECT}
            defaultOptionText="Choose the account currency"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Field
            name="custom_reference"
            type="text"
            placeholder="e.g acustomreference"
            sublabel="You can add a custom reference message. We'll ensure we pass it to the bank every time we make a transfer."
            component={InputField}
            label="Payee Reference Message"
          />
        </div>
      </div>
    </div>
  </form>
);

BankAccountForm.propTypes = {
  countryCode: countryCodeSpec,
};

BankAccountForm.defaultProps = {
  countryCode: DEFAULT_COUNTRY_CODE,
};

const selector = formValueSelector('bankAccountForm');

const BankAccountFormRedux = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
})(BankAccountForm);

const mapStateToProps = (state) => {
  const countryCode = selector(state, 'country_code');
  return { countryCode };
};

const BankAccountFormReduxConnect = connect(
  mapStateToProps,
)(BankAccountFormRedux);

export default withRouter(BankAccountFormReduxConnect);
