import React from 'react';
import PropTypes from 'prop-types';

import { ListGroup } from 'react-bootstrap';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';

const ProjectFundsBreakdown = ({ className, budgetBreakdown, projectCurrency }) => (
  <ListGroup
    className={className || ''}
    key={3}
  >
    <ListGroup.Item>
      Total Budget
      <span className="float-right value--total" data-testid="project-funds-breakdown-total">
        <NumberTpl
          prefix={projectCurrency}
          thousandSeparator
          decimals={2}
          value={budgetBreakdown.totalApproved}
        />
      </span>
    </ListGroup.Item>
    <ListGroup.Item>
      Used Budget
      <span className="float-right value--used" data-testid="project-funds-breakdown-used">
        <NumberTpl
          prefix={projectCurrency}
          thousandSeparator
          decimals={2}
          value={budgetBreakdown.used}
        />
      </span>
    </ListGroup.Item>
    <ListGroup.Item>
      Refunded Budget
      <span className="float-right value--refunded" data-testid="project-funds-breakdown-refunded">
        <NumberTpl
          prefix={projectCurrency}
          thousandSeparator
          decimals={2}
          value={budgetBreakdown.refunded}
        />
      </span>
    </ListGroup.Item>
    <ListGroup.Item
      className="emphasize"
    >
      Available Budget
      <span
        className="float-right value--available"
        data-testid="project-funds-breakdown-available"
      >
        <NumberTpl
          prefix={projectCurrency}
          thousandSeparator
          decimals={2}
          value={budgetBreakdown.available}
        />
      </span>
    </ListGroup.Item>
  </ListGroup>
);

ProjectFundsBreakdown.propTypes = {
  budgetBreakdown: PropTypes.object.isRequired,
  projectCurrency: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ProjectFundsBreakdown.defaultProps = {
  className: '',
};

export default ProjectFundsBreakdown;
