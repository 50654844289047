/* globals FormData */
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';

import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import LanguageSelectField from 'core/assets/js/components/FinalFormFields/LanguageSelectField.jsx';
import CountrySelectField from 'core/assets/js/components/FinalFormFields/CountrySelectField.jsx';
import SkillsAndOrSelectField from 'core/assets/js/components/FinalFormFields/SkillsAndOrSelectField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, MIME_TYPES } from 'core/assets/js/constants';
import { CREATE_GROUP_TYPE, USER_GROUP_AVATAR_MAX_WIDTH } from 'people/assets/js/constants';
import { createUserGroupDS, editUserGroupDS } from 'people/assets/js/data-services/form';
import { userGroupManageUrl, userGroupsUrl } from 'people/urls';
import { SKILL_IDS_MATCH } from 'skills/assets/js/constants';

const UserGroupForm = ({ initialValues, type }) => {
  const history = useHistory();
  const { orgAlias, userGroupId } = useParams();
  const dispatch = useDispatch();
  const isDynamic = type === CREATE_GROUP_TYPE.DYNAMIC;
  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (
        { avatar, countries, description, languages, name, skills, skillsMatch },
      ) => {
        let skillIds = [];
        if (Array.isArray(skills)) {
          skillIds = skills.map(s => s.value);
        }
        const skillsSelected = skillIds.length > 0;
        let languageIds = [];
        if (Array.isArray(languages)) {
          languageIds = languages.map(l => l.value);
        }
        const languagesSelected = languageIds.length > 0;
        let countriesCodes = [];
        if (Array.isArray(countries)) {
          countriesCodes = countries.map(c => c.value);
        }
        const countriesSelected = countriesCodes.length > 0;
        const errors = {};
        if (typeof name !== 'string' || name.length === 0) {
          errors.name = 'Name is required';
        }
        if (isDynamic) {
          if (!skillsSelected && !languagesSelected && !countriesSelected) {
            errors.languages = 'Please select at least one skill, language or country';
            errors.skills = errors.languages;
            errors.countries = errors.languages;
          }
          if (skillsSelected && !Object.values(SKILL_IDS_MATCH).includes(skillsMatch)) {
            errors.skillsMatch = 'Please select if any or all skills should be matched';
          }
        }
        if (Object.keys(errors).length > 0) {
          return errors;
        }
        const formData = new FormData();
        formData.append('name', name);
        if (avatar) {
          formData.append('avatar', avatar);
        }
        if (typeof description === 'string' && description.length > 0) {
          formData.append('description', description);
        }
        if (skillsSelected) {
          formData.append('skillIds', JSON.stringify(skillIds));
          formData.append('allSkills', skillsMatch === SKILL_IDS_MATCH.AND ? 'true' : 'false');
        }
        if (languagesSelected) {
          formData.append('languageIds', JSON.stringify(languageIds));
        }
        if (countriesSelected) {
          formData.append('countriesCodes', JSON.stringify(countriesCodes));
        }
        try {
          let createdUserGroup = null;
          const commonDSProps = { orgAlias, values: formData };
          if (userGroupId) {
            await dispatch(editUserGroupDS({ ...commonDSProps, userGroupId }));
          } else {
            ({ userGroup: createdUserGroup } = await dispatch(createUserGroupDS(commonDSProps)));
          }
          toastr.success(
            'Well Done!', `Your user group was ${userGroupId ? 'upd' : 'cre'}ated successfully.`,
          );
          history.push(userGroupManageUrl(orgAlias, userGroupId || createdUserGroup.id));
        } catch (err) {
          if (err.errors) {
            return err.errors;
          }
          toastr.error('Oh Snap!', err.message);
        }
        return null;
      }}
      render={({ form: { getState }, handleSubmit, submitError, submitting }) => {
        const { values: { countries, languages, skills } } = getState();
        const hasSelectedLanguagesAndSkillsAndCountries = [
          Array.isArray(languages) && languages.length > 0,
          Array.isArray(skills) && skills.length > 0,
          Array.isArray(countries) && countries.length > 0,
        ].filter(bool => bool).length > 1;

        return (
          <form onSubmit={handleSubmit}>
            <div className="rounded shadow-sm p-4 bg-white">
              <FileUploaderDirectField
                accept={MIME_TYPES.IMAGES}
                allowCropping
                label="Avatar"
                cropModalHeading="Crop the group's avatar"
                maxWidth={USER_GROUP_AVATAR_MAX_WIDTH}
                name="avatar"
              />
              <TextInputField name="name" label="Group name" required />
              {isDynamic && (
                <>
                  <SkillsAndOrSelectField
                    label="Skills"
                    name="skills"
                    sublabel={(
                      "Choose skills for this group. Members matching this group's skills will be "
                      + 'automatically added or removed from this group. Use "Any" or "All" to '
                      + 'control if it matches users who have any or all of the selected skills'
                    )}
                  />
                  <LanguageSelectField
                    label="Languages"
                    name="languages"
                    sublabel={(
                      <>
                        {'Choose languages for this group. Members matching '}
                        <b>ALL</b>
                        {" of this group's languages will be automatically added or removed from "}
                        this group.
                      </>
                    )}
                  />
                  <CountrySelectField
                    label="Countries"
                    name="countries"
                    sublabel={(
                      <>
                        {'Choose countries for this group. Members matching '}
                        <b>ALL</b>
                        {" of this group's countries will be automatically added or removed from "}
                        this group.
                      </>
                    )}
                  />
                  {hasSelectedLanguagesAndSkillsAndCountries && (
                    <TDSystemMessage
                      type={BS_STYLE.WARNING}
                      title="You have selected 2 or more filters selected"
                      className="mb-4"
                    >
                      You have selected 2 or more filters. This means users will be
                      automatically added to this group, who match all.
                    </TDSystemMessage>
                  )}
                </>
              )}
              <TextAreaField name="description" label="Description" />
              {submitError && <p className="text-danger">{submitError}</p>}
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <TDButton
                disabled={submitting}
                label="Cancel"
                onClick={() => history.push(
                  userGroupId ? userGroupManageUrl(orgAlias, userGroupId) : userGroupsUrl(orgAlias),
                )}
              />
              <TDButton
                disabled={submitting}
                label={userGroupId ? 'Save' : 'Create'}
                type="submit"
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

UserGroupForm.propTypes = {
  initialValues: PropTypes.object,
  type: PropTypes.oneOf([CREATE_GROUP_TYPE.STANDARD, CREATE_GROUP_TYPE.DYNAMIC]).isRequired,
};

UserGroupForm.defaultProps = {
  initialValues: {},
};

export default UserGroupForm;
