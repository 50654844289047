import {
  fetchListDS,
  listFetchAC,
  listFetchExtrasAC,
  paginationFetchAC,
  searchFetchAC,
} from 'core/assets/js/ducks/list';
import qs from 'query-string';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import * as apiUrl from 'finance/urls';
import { financeSubscriptionProductsApiUrl } from 'finance/urls';
import { viewFetchErrorAC } from 'core/assets/js/ducks/view';


const _validateParams = ({ orgAlias }) => {
  if (!orgAlias) {
    throw new Error(`[_fetchDataDS Error] Invalid orgAlias ${orgAlias}`);
  }
};

export const fetchProjectsCreateWorksheetForDS = ({
  orgAlias, componentName, url = '', params = {}, authedAxios = null,
}) => fetchListDS({
  validate: () => _validateParams({ orgAlias }),
  authedAxios,
  componentName,
  url: apiUrl.financeWorksheetProjectsListApiUrl(params.orgAlias, url),
});

export const fetchProjectsCreateExpenseForDS = ({
  orgAlias, componentName, url = '', params = {}, authedAxios = null,
}) => fetchListDS({
  validate: () => _validateParams({ orgAlias }),
  authedAxios,
  componentName,
  url: apiUrl.financeExpenseProjectsListApiUrl(params.orgAlias, url),
});

export const fetchManagersBudgetListDS = ({
  url = '', querystring, componentName, params = {}, authedAxios = null,
}) => fetchListDS({
  validate: () => _validateParams({ orgAlias: params.orgAlias }),
  authedAxios,
  componentName,
  querystring,
  url: apiUrl.financeManagersBudgetListApiUrl(params.orgAlias, url),
});

export const fetchProjectsBudgetListDS = ({
  url = '', querystring, componentName, params = {}, authedAxios = null,
}) => (
  fetchListDS({
    authedAxios,
    componentName,
    querystring,
    url: apiUrl.financeProjectsBudgetListApiUrl(params.orgAlias, url),
  })
);

export const fetchFinancePurchaseOrderListDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring: `?${qs.stringify({ ...qs.parse(querystring), projectId })}`,
    fetchApiUrl: () => apiUrl.financePurchaseOrderListApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData.purchaseOrders, componentName),
      listFetchExtrasAC(responseData.accessControl, componentName, 'accessControl'),
      listFetchExtrasAC(responseData.amountLimits, componentName, 'amountLimits'),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchFinanceWorksheetListDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring: `?${qs.stringify({ ...qs.parse(querystring), projectId })}`,
    fetchApiUrl: () => apiUrl.financeWorksheetListApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [listFetchAC(responseData, componentName)],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchFinanceExpenseListDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring: `?${qs.stringify({ ...qs.parse(querystring), projectId })}`,
    fetchApiUrl: () => apiUrl.financeExpenseListApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData.expenses, componentName),
      listFetchExtrasAC(responseData.accessControl, componentName, 'accessControl'),
      listFetchExtrasAC(responseData.amountLimits, componentName, 'amountLimits'),
      listFetchExtrasAC(responseData.billableAnalytics, componentName, 'billableAnalytics'),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);

export const fetchFinanceInvoiceListDS = ({
  url = '', querystring, componentName, params = {}, authedAxios = null,
}) => (
  fetchListDS({
    authedAxios,
    componentName,
    querystring,
    url: apiUrl.financeInvoiceListApiUrl(params.orgAlias, url),
  })
);

export const fetchFinanceProFormaInvoiceListDS = ({
  url = '', componentName, params = {}, authedAxios = null, querystring = '', projectId,
}) => (
  fetchDataDS({
    authedAxios,
    querystring: `?${qs.stringify({ ...qs.parse(querystring), projectId })}`,
    fetchApiUrl: () => apiUrl.financeProFormaInvoiceListApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [
      listFetchAC(responseData.serviceOrders, componentName),
      listFetchExtrasAC(responseData.accessControl, componentName, 'accessControl'),
      listFetchExtrasAC(responseData.amountLimits, componentName, 'amountLimits'),
      listFetchExtrasAC(responseData.billableAnalytics, componentName, 'billableAnalytics'),
    ],
    componentName,
    paginationAC: paginationFetchAC,
    searchAC: searchFetchAC,
  })
);


export const fetchSubscriptionProductsDS = ({
  url = '', componentName, authedAxios = null, querystring,
}) => fetchDataDS({
  authedAxios,
  fetchApiUrl: () => financeSubscriptionProductsApiUrl(url, componentName),
  fetchDataAC: responseData => ([
    listFetchAC(responseData.subscriptionProducts, componentName),
  ]),
  querystring,
  fetchDataErrorAC: viewFetchErrorAC,
});
