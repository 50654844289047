import React from 'react';
import PropTypes from 'prop-types';

import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { profileSettingsUrl } from 'accounts/urls';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';

/**
 * This is the tabs component we use on My Settings -> Profile
 * @returns {*}
 */
const ProfileSettingsTabs = ({ orgAlias, tab }) => {
  const tabSpec = [
    {
      key: PROFILE_SETTINGS_TABS.BASIC_INFORMATION,
      label: 'Basic Information',
      href: profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION),
    },
    {
      key: PROFILE_SETTINGS_TABS.SOCIAL_PROFILES,
      label: 'Social Profiles',
      href: profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.SOCIAL_PROFILES),
    },
    {
      key: PROFILE_SETTINGS_TABS.SKILLS,
      label: 'Skills',
      href: profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.SKILLS),
    },
    {
      key: PROFILE_SETTINGS_TABS.LANGUAGES,
      label: 'Languages',
      href: profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.LANGUAGES),
    },
    {
      key: PROFILE_SETTINGS_TABS.EXPERIENCE,
      label: 'Experience',
      href: profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.EXPERIENCE),
    },
    {
      href: profileSettingsUrl(orgAlias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS),
      key: SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
      label: 'Payment methods',
    },
    {
      href: profileSettingsUrl(orgAlias, SETTINGS_PAYMENTS_TABS.PAYMENT_TERMS),
      key: SETTINGS_PAYMENTS_TABS.PAYMENT_TERMS,
      label: 'Invoicing',
    },
    {
      href: profileSettingsUrl(orgAlias, SETTINGS_PAYMENTS_TABS.INVOICE_DETAILS),
      key: SETTINGS_PAYMENTS_TABS.INVOICE_DETAILS,
      label: 'Tax',
    },
  ];

  return (
    <div className="horizontal-scroll">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

ProfileSettingsTabs.propTypes = {
  orgAlias: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default ProfileSettingsTabs;
