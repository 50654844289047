import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { postOrganizationDS } from 'settings/assets/js/data-services/organizations';

const OrgSettingsTaxView = () => {
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();

  const updateOrgSetting = setting => newValue => dispatch(postOrganizationDS(
    activeOrg.unique_alias, { [setting]: newValue },
  ));

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Tax', url: null }]} />
      <div className="page page--settings tax">
        <div className="container">
          <div className="rounded shadow-sm p-4 bg-white">
            <h3 className="heading-block">Tax settings</h3>
            <div className="settings-row">
              <TDToggleWithConfirmationModal
                disableHeader="Disable mandatory tax identification number for US taxpayers"
                DisableModalBody={() => (
                  <>
                    <p>
                      By deactivating this setting, we will not require that US tax payers suppley
                      their Tax Identification Number.
                    </p>
                    <p className="font-weight-bold">
                      Note: disabling this feature will prevent the ability to perform
                      annual 1099 filings through the platform.
                    </p>
                    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
                  </>
                )}
                enableHeader="Enable mandatory tax identification number for US taxpayers"
                EnableModalBody={() => (
                  <>
                    <p>
                      By activating this setting, we will require that US tax payers suppley
                      their Tax Identification Number.
                    </p>
                    <p className="mb-4">Are you sure you want to activate this setting?</p>
                  </>
                )}
                label="Mandatory collection of Tax Identification Numbers from US taxpayers"
                modalId="tax-number"
                onToggled={updateOrgSetting('user_us_tax_number_required')}
                selected={activeOrg.user_us_tax_number_required}
                sublabel={(
                  `By default, we require all providers who are US taxpayers or operating
                  through a US company to supply their Tax Identification Number (SSN or company
                  TIN). Disable this feature if you would prefer to make this optional.`
                )}
              />
              {activeOrg.can_enable_us_tax_number_validation && (
                <TDToggleWithConfirmationModal
                  disableHeader="Do not validate the Tax Identification Number of US-based users"
                  DisableModalBody={() => (
                    <>
                      <p>
                        By deactivating this setting, we will not validate the Tax Identification
                        Number of US-based users.
                      </p>
                      <p className="mb-4">Are you sure you want to deactivate this setting?</p>
                    </>
                  )}
                  enableHeader="Validate the Tax Identification Number of US-based users"
                  EnableModalBody={() => (
                    <>
                      <p>
                        By activating this setting, we will validate the Tax Identification Number
                        of US-based users. Including existing users who have already filled in these
                        values.
                      </p>
                      <p className="mb-4">Are you sure you want to activate this setting?</p>
                    </>
                  )}
                  label="Validate the Tax Identification Numbers (TIN) of all US-based users"
                  modalId="validate-tin"
                  onToggled={updateOrgSetting('us_tax_number_validation_enabled')}
                  selected={activeOrg.us_tax_number_validation_enabled}
                  sublabel={(
                    `If you need to file W-9 or 1099 forms for your US contractors, having invalid
                    or incorrect Tax Identification Numbers can lead to delays with your filings and
                    additional filing costs. With this feature enabled, we will validate all Social
                    Security Numbers and Company Tax Identification Numbers that your contractors
                    supply. If you already have users onboarded enabling this feature will
                    auto-validate any existing TINs. Usage charges apply for this feature.`
                  )}
                />
              )}
              <TDToggleWithConfirmationModal
                disableHeader="Disable mandatory tax identification documents for US taxpayers"
                DisableModalBody={() => (
                  <>
                    <p>
                      By deactivating this setting, we will not require that US tax payers upload a
                      tax identification document.
                    </p>
                    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
                  </>
                )}
                enableHeader="Enable mandatory tax identification documents for US taxpayers"
                EnableModalBody={() => (
                  <>
                    <p>
                      By activating this setting, we will require that US tax payers upload a tax
                      identification document.
                    </p>
                    <p className="mb-4">Are you sure you want to activate this setting?</p>
                  </>
                )}
                label="Mandatory tax identification documents for US taxpayers"
                modalId="tax-documents"
                onToggled={updateOrgSetting('user_us_tax_form_required')}
                selected={activeOrg.user_us_tax_form_required}
                sublabel={(
                  `Enable this option if you require that all US taxpayers upload their W-9 tax
                  information document before they can perform any work for you. All Providers will
                  be prevented from using the platform until they have supplied this tax document.`
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrgSettingsTaxView;
