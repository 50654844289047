import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ReportSearch from 'finance/assets/js/components/ReportSearch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import DateDurationDropdown from 'core/assets/js/components/DateDurationDropdown.jsx';

const SearchFormWithChartsToggleHeader = ({
  onChartsToggled, showCharts, amountLimits, reportType, selectedDateDuration,
  onDateDurationChanged, onFiltersToggle, filtersOpen,
}) => {

  return (
    <div className="row">
      {showCharts && (
        <div className="col-auto mb-4">
          <DateDurationDropdown
            data-testid="search-form-with-charts-toggle-header-date-duration-dropdown"
            formField
            selectedDateDuration={selectedDateDuration}
            onDateDurationChanged={onDateDurationChanged}
          />
        </div>
      )}
      {!showCharts && (
        <ReportSearch
          reportType={reportType}
          amountLimits={amountLimits}
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
        />
      )}

      { !filtersOpen && (
        <div className="ml-auto col-auto">
          <TDButton
            className="btn-toggle-charts mb-3"
            data-testid="search-form-with-charts-toggle-header-toggle-charts"
            variant={showCharts ? BS_STYLE.PRIMARY : BS_STYLE.DEFAULT}
            btnIcon={ICON.GRAPH}
            onClick={onChartsToggled}
          />
        </div>
      )}
    </div>
  );
};

SearchFormWithChartsToggleHeader.propTypes = {
  amountLimits: PropTypes.object.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  onChartsToggled: PropTypes.func,
  onDateDurationChanged: PropTypes.func.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  selectedDateDuration: PropTypes.number.isRequired,
  showCharts: PropTypes.bool,
};

SearchFormWithChartsToggleHeader.defaultProps = {
  showCharts: false,
  onChartsToggled: () => {},
};

export default SearchFormWithChartsToggleHeader;
