import React from 'react';
import { Card } from 'react-bootstrap';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const SkillDirectoryCardSkeleton = () => (
  <Card className="skill-directory-card shadow-none border-0">
    <Card.Body>
      <div className="row">
        <div className="col-sm-12 col-md-8 description mb-4 mb-md-0">
          <div className="mb-3 mt-0">
            <RectangleSkeleton height="20" width="150" />
          </div>
          <div>
            <RectangleSkeleton height="20" width="80" />
            <RectangleSkeleton height="20" width="50" />
            <RectangleSkeleton height="20" width="50" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 d-flex flex-column justify-content-between">
          <div className="row creator py-2 mx-0 mx-md-1">
            <div className="col-5 pl-0">
              Created by
            </div>
            <div className="col-7 text-left pl-0">
              <RectangleSkeleton height="15" width="150" />
            </div>
          </div>
          <div className="row created-at py-2 mx-0 mx-md-1">
            <div className="col-5 pl-0">
              Date
            </div>
            <div className="col-7 text-left pl-0">
              <RectangleSkeleton height="15" width="150" />
            </div>
          </div>
          <div className="row py-2 mx-0 mx-md-1">
            <div className="col-5 pl-0">
              Members
            </div>
            <div className="col-7 text-left pl-0">
              <RectangleSkeleton height="15" width="150" />
            </div>
          </div>
        </div>

      </div>
    </Card.Body>
  </Card>
);

export default SkillDirectoryCardSkeleton;
