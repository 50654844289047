import React from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import { ICON, MODAL_SIZES } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  CUSTOM_FIELD_EXAMPLE,
  IMPORT_DATE_FORMAT,
  IMPORT_DATE_FORMAT_LABEL,
  IMPORT_PROJECT_AND_TASK_ACTIONS,
  IMPORT_TYPE,
  IMPORT_TYPE_LABEL,
} from 'importer/assets/js/constants';
import { importDownloadTemplateApiUrl, importFetchCustomFieldsApiUrl } from 'importer/urls';
import { TYPE_STRING } from 'interviews/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { RATE_UNIT_TO_STRING } from 'rates/assets/js/constants';

const dateFormatValues = Object.values(IMPORT_DATE_FORMAT);

const dateFormatOptions = dateFormatValues.map(value => ({
  text: (
    <>
      {value}
      <span className="discreet ml-2">{`(${IMPORT_DATE_FORMAT_LABEL[value]})`}</span>
    </>
  ),
  value,
}));

const fieldRequirementExampleClass = (
  'import-field-requirement-example py-1 px-2 rounded d-inline mr-2 mb-2 float-left'
);

const dateFormatExamples = (
  <>
    {dateFormatValues.map(value => (
      <div className={fieldRequirementExampleClass} key={value}>{value}</div>
    ))}
  </>
);

const FIELD_REQUIREMENTS = {
  [IMPORT_TYPE.WORKSHEETS]: [
    {
      field: 'Project reference',
      values: 'Can be found in the Project overview section e.g. PR-123',
    },
    {
      field: 'Task reference',
      values: 'Can be found in the Task Details section e.g. 123',
    },
    { field: 'User ID or email', values: 'e.g. 1234 or example@domain.com' },
    {
      field: 'Claiming amount',
      values: 'The amount to claim for project work e.g. 123.45. Use this OR "Task rate quantity"',
    },
    {
      field: 'Task rate quantity',
      values: 'The quantity to claim for a task assignment e.g. 7. Use this OR "Claiming amount"',
    },
    { field: 'Description', values: "A description of the worksheet's line item" },
    { field: 'Currency', values: "The currency of the worksheet's amount e.g. USD" },
    { field: 'Summary', values: 'A summary of this worksheet' },
    { field: 'Period start', values: dateFormatExamples },
    { field: 'Period end', values: dateFormatExamples },
  ],
  [IMPORT_TYPE.USERS]: [
    { field: 'Email', values: 'e.g. example@domain.com' },
    { field: 'First name', values: "Person's first name" },
    { field: 'Middle name', values: "Person's middle name" },
    { field: 'Last name', values: "Person's last name" },
    { field: 'Full legal name', values: "Person's full legal name" },
    { field: 'Job title', values: "Person's job title" },
    { field: 'Date of birth', values: dateFormatExamples },
    { field: 'Phone number', values: 'e.g. +155512345678' },
    { field: 'Address', values: "Person's address" },
    { field: 'Avatar', values: "URL to the person's avatar" },
    { field: 'Default rate currency', values: 'e.g. USD' },
    { field: 'Default rate amount', values: 'e.g. 123.45' },
    {
      field: 'Default rate unit',
      values: (
        <>
          {Object.values(RATE_UNIT_TO_STRING).map(value => (
            <div className={fieldRequirementExampleClass} key={value}>{value}</div>
          ))}
        </>
      ),
    },
    {
      field: 'Group names',
      values: 'You can add the user to multiple groups by comma separating their names',
    },
    {
      field: 'Project titles',
      values: 'You can add the user to multiple projects by comma separating their titles',
    },
  ],
  [IMPORT_TYPE.SKILLS]: [
    { field: 'Skill name', values: 'The skill name e.g. Project management' },
    {
      field: 'Skill directory name',
      values: [
        "The optional name of the skill directory to add the skill to (creating it if it doesn't ",
        'exist) e.g. Preferred contractor skills',
      ].join(''),
    },
  ],
  [IMPORT_TYPE.PROJECTS]: [
    { field: 'Title', values: "The project's title" },
    { field: 'Brief', values: "The description of the project's brief" },
    { field: 'Deadline', values: dateFormatExamples },
    { field: 'Start date', values: dateFormatExamples },
    {
      field: 'Skills', values: 'You can add skills to the project by comma separating their labels',
    },
    { field: 'Budget', values: 'The budget to give the project, from your available budget' },
    { field: 'External ID', values: "The project's external identifier" },
    {
      field: 'Client names',
      values: 'You can add client names to the project by comma separating their values',
    },
    { field: 'Tags', values: 'You can add tags to the project by comma separating their values' },
    {
      field: 'Is private',
      values: '"true" or "false", if false, providers can apply to join it through opportunities',
    },
    {
      field: 'All managers can manage members',
      values: '"true" or "false", if false, only the project owner can add/invite users to the '
        + 'project',
    },
    {
      field: 'Providers can view team',
      values: '"true" or "false", if false, providers cannot view the team list for the project',
    },
  ],
  [IMPORT_TYPE.PROJECT_AND_TASK_ASSIGNMENTS]: [
    { field: 'User ID or email', values: "The user's ID or email address" },
    {
      field: 'Project reference',
      values: 'Can be found in the Project overview section e.g. PR-123',
    },
    {
      field: 'Task reference',
      values: 'Can be found in the Task overview section e.g. 123',
    },
    {
      field: 'Action',
      values: `One of "${Object.values(IMPORT_PROJECT_AND_TASK_ACTIONS).join('", "')}"`,
    },
  ],
  [IMPORT_TYPE.TASKS]: [
    {
      field: 'Project reference',
      values: 'Can be found in the Project overview section e.g. PR-123',
    },
    { field: 'Title', values: "The task's title" },
    { field: 'Description', values: "The task's description" },
    { field: 'Starts on', values: dateFormatExamples },
    { field: 'Deadline', values: dateFormatExamples },
    {
      field: 'Checklist',
      values: 'You can add check list items to the task by comma separating their values',
    },
    {
      field: 'Skills', values: 'You can add skills to the task by comma separating their labels',
    },
    { field: 'Tags', values: 'You can add tags to the task by comma separating their values' },
  ],
};

const MODAL_ID = 'field-requirements-modal';

const ImportCreateDownloadTemplate = () => {
  const dispatch = useDispatch();

  const { values: { type } } = useFormState();

  const activeOrg = useSelector(selectActiveOrg);
  const modalIsOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));

  const { hasLoaded, item: customFields } = fetchDataHook({
    componentName: ImportCreateDownloadTemplate.GetComponentName(),
    url: importFetchCustomFieldsApiUrl(activeOrg.alias, type),
  });

  return (
    <>
      <div className="import-worksheets-page--wrapper bg-light py-4 mx-0 mb-4 d-flex">
        <div className="import-worksheets-page--download--section mt-4">
          <a
            className="btn btn-primary btn-lg ml-5"
            href={importDownloadTemplateApiUrl(activeOrg.alias, type)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className={`${ICON.DOWNLOAD} mr-3`} />
            Download template
          </a>
        </div>
        <div className="import-worksheets-page--info--section mt-4">
          <p className="mb-0 import-worksheets-page--download--info">
            {`Download the CSV file with example entries for creating ${IMPORT_TYPE_LABEL[type]}.`}
          </p>
          <p className="mb-0 import-worksheets-page--download--info">
            Use a spreadsheet editor to enter all of the required information.
          </p>
          {hasLoaded && (
            <>
              <a
                className="imitate-link"
                data-testid="field-requirements-button"
                onClick={() => dispatch(modalOpenAC(MODAL_ID))}
              >
                <i className={`${ICON.CIRCLE_INFO} mt-4 mr-3`} />
                Field requirements
              </a>
              <ModalSimple
                body={(
                  <>
                    <p>Here are the requirements for each field in the template:</p>
                    <Table
                      items={[
                        ...FIELD_REQUIREMENTS[type],
                        ...customFields.map(cf => ({
                          field: cf.label,
                          values: `A ${TYPE_STRING[cf.type]} custom field (e.g. `
                            + `"${CUSTOM_FIELD_EXAMPLE[cf.type]}")`,
                        })),
                      ]}
                      cols={[
                        { key: 'field', label: 'Field' },
                        { key: 'values', label: 'Acceptable values' },
                      ]}
                    />
                  </>
                )}
                data-testid="field-requirements-modal"
                heading="Field requirements"
                noFooter
                onClose={() => dispatch(modalCloseAC())}
                open={modalIsOpen}
                size={MODAL_SIZES.LARGE}
              />
            </>
          )}
        </div>
      </div>
      {[IMPORT_TYPE.WORKSHEETS, IMPORT_TYPE.USERS, IMPORT_TYPE.PROJECTS].includes(type) && (
        <RadioField
          name="dateFormat"
          label="Please choose the date format that you have used in your import file"
          options={dateFormatOptions}
        />
      )}
    </>
  );
};

ImportCreateDownloadTemplate.GetComponentName = () => 'ImportCreateDownloadTemplate';

export default ImportCreateDownloadTemplate;
