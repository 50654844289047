import React from 'react';
import PropTypes from 'prop-types';

import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import { MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import FileUploader from 'core/assets/js/components/FileUploader.jsx';
import Filebox from 'core/assets/js/components/FileUploader/Filebox.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';


const FileUploaderField = ({
  input, required, sublabel, label, additionalError, softDelete, uploaderWrapperClassName,
  maxFiles, attachments, className, path, acceptFiles, acceptAll, onBusyChange, imgWidth,
  imgHeight, meta: { error, submitError, pristine }, 'data-testid': dataTestId, disabled,
  uploadRenderer,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = !!error || !!submitError || showAdditionalError;
  const classNames = ['form-group'];

  if (hasError) {
    classNames.push('has-error');
  }

  if (disabled) {
    classNames.push('file-uploader-disabled');
  }

  if (className) {
    classNames.push(className);
  }

  const attachmentLinks = attachments
    ? attachments.map(file => (
      <Filebox key={file.handle} file={file} />
    ))
    : [];

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <TDLabel
        name={input.name}
        label={label}
        required={required}
        sublabel={sublabel}
        suffix={maxFiles ? `(Maximum ${maxFiles} files)` : null}
      />

      <ul className="attachments-list">
        {attachmentLinks}
      </ul>

      <FileUploader
        maxFiles={maxFiles}
        reduxInput={input}
        wrapperClassName={uploaderWrapperClassName}
        softDelete={softDelete}
        path={path}
        imgWidth={imgWidth}
        imgHeight={imgHeight}
        acceptFiles={acceptFiles}
        onBusyChange={onBusyChange}
        id={`field-${input.name}`}
        acceptAll={acceptAll}
        disabled={disabled}
        uploadRenderer={uploadRenderer}
      />

      {showAdditionalError && (
        <span className="help-block d-inline-block mt-3">
          {additionalError}
          {' - '}
          Please delete the old item in order to upload a new one
        </span>
      )}

      {hasError && (
        <span className="help-block d-inline-block mt-3">{error || submitError}</span>
      )}
    </div>
  );
};
FileUploaderField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: finalFormFieldMetaSpec,
  maxFiles: PropTypes.number,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  label: finalFormFieldLabelSpec,
  sublabel: finalFormFieldLabelSpec,
  className: PropTypes.string,
  onBusyChange: PropTypes.func,
  required: PropTypes.bool,
  additionalError: PropTypes.string,
  softDelete: PropTypes.bool,
  attachments: PropTypes.array,
  acceptFiles: PropTypes.array,
  acceptAll: PropTypes.bool,
  path: PropTypes.string,
  uploaderWrapperClassName: PropTypes.string,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  uploadRenderer: PropTypes.func,
};

FileUploaderField.defaultProps = {
  meta: {
    error: '',
    submitError: '',
    pristine: true,
  },
  className: '',
  maxFiles: MAX_UPLOAD_FILES,
  sublabel: null,
  imgWidth: null,
  imgHeight: null,
  onBusyChange: undefined,
  label: '',
  required: false,
  additionalError: '',
  softDelete: false,
  attachments: null,
  path: null,
  acceptFiles: undefined,
  acceptAll: false,
  uploaderWrapperClassName: null,
  'data-testid': 'file-uploader-field',
  disabled: false,
  uploadRenderer: null,
};

export default withField(FileUploaderField);
