import React from 'react';
import { useSelector } from 'react-redux';

import PaymentStatusWidgetDesktop from 'core/assets/js/components/Dashboard/PaymentStatusWidget/PaymentStatusWidgetDesktop.jsx';
import PaymentStatusWidgetMobile from 'core/assets/js/components/Dashboard/PaymentStatusWidget/PaymentStatusWidgetMobile.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';
import ProjectWidgetSkeleton from 'core/assets/js/components/Skeleton/ProjectWidgetSkeleton.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { DATETIME_FORMAT_HUMAN_FRIENDLY, ICON, BS_STYLE } from 'core/assets/js/constants';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import { formatDate } from 'core/assets/js/lib/utils';
import { financeGetPaymentsWidgetDataApiUrl, financeInvoiceCalendarPublicUrl } from 'finance/urls';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import InvoiceCapUsageBar from 'projects/assets/js/components/InvoiceCapUsageBar.jsx';

const COMPONENT_NAME = 'PaymentStatusWidget';

const PaymentStatusWidgetWrapper = () => {
  const {
    item: { serviceOrders = [], serviceOrdersCutoffDateTime },
  } = useSelector(state => getViewState(state, COMPONENT_NAME));
  const {
    alias,
    payment_status_widget_show_worksheet_cutoff: showWorksheetCutoff,
    second_level_of_service_order_approval: isSecondLevelApprovalEnabled,
  } = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);

  return (
    <WidgetWrapper
      bodyExtraClasses="p-0 payment-status"
      customHeaderActions={showWorksheetCutoff && serviceOrdersCutoffDateTime && (
        <div
          className={(
            'd-flex align-items-end header-right flex-column flex-xl-row align-items-xl-center'
          )}
        >
          <div>
            <i className={ICON.WARNING} />
            <span>Worksheet cutoff date:</span>
          </div>
          <div className="text-danger">
            {formatDate(new Date(serviceOrdersCutoffDateTime), DATETIME_FORMAT_HUMAN_FRIENDLY)}
          </div>
        </div>
      )}
      footerActionBtn={{
        label: 'View payment calendar',
        onClick: () => {
          WINDOW_OPEN(financeInvoiceCalendarPublicUrl());
        },
        variant: BS_STYLE.LINK,
      }}
      headerExtraClasses="payment-status d-flex align-items-center justify-content-between"
      title="Payment status"
    >
      <InvoiceCapUsageBar
        className="p-4"
        userId={activeUserCard.user?.id}
        serviceOrderType={serviceOrders[0]?.type}
      />
      <PaymentStatusWidgetDesktop
        isSecondLevelApprovalEnabled={isSecondLevelApprovalEnabled}
        orgAlias={alias}
        serviceOrders={serviceOrders}
      />
      <PaymentStatusWidgetMobile
        isSecondLevelApprovalEnabled={isSecondLevelApprovalEnabled}
        orgAlias={alias}
        serviceOrders={serviceOrders}
      />
    </WidgetWrapper>
  );
};

const TDApiConnectedPaymentStatusWidgetWrapper = withTDApiConnected({
  duck: 'view',
  fetchData: ({ authedAxios, componentName, dispatch, params: { orgAlias } }) => (
    dispatch(fetchViewDS({
      authedAxios, componentName, url: financeGetPaymentsWidgetDataApiUrl(orgAlias),
    }))
  ),
  skeletonComponent: ProjectWidgetSkeleton,
  storeKey: COMPONENT_NAME,
})(PaymentStatusWidgetWrapper);

export default TDApiConnectedPaymentStatusWidgetWrapper;
