/* eslint import/prefer-default-export: off */
import PropTypes from 'prop-types';

import { DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_VALUES } from 'documents/assets/js/constants';

export const documentSpec = PropTypes.shape({
  allowedActions: PropTypes.object.isRequired,
  amount: PropTypes.string,
  content: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.number.isRequired,
  currency: PropTypes.string,
  description: PropTypes.string,
  effectiveDate: PropTypes.string,
  effectiveType: PropTypes.number,
  endDate: PropTypes.string,
  endRelativeType: PropTypes.number,
  endRelativeValue: PropTypes.number,
  endText: PropTypes.string,
  endType: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.number.isRequired,
  isCritical: PropTypes.bool,
  organizationId: PropTypes.number.isRequired,
  previousVersionId: PropTypes.number,
  renewalExpirationReminder: PropTypes.number,
  renewalNoticePeriod: PropTypes.number,
  renewalTermType: PropTypes.number,
  renewalTermValue: PropTypes.number,
  renewalType: PropTypes.number,
  status: PropTypes.number.isRequired,
  taxTerms: PropTypes.string,
  taxType: PropTypes.number,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  updatedBy: PropTypes.number.isRequired,
  version: PropTypes.string.isRequired,
  versionNumbers: PropTypes.arrayOf(PropTypes.string),
});

export const documentAssignmentSignatureSpec = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS_VALUES),
});

export const documentOptionsSpec = PropTypes.shape({
  countersignersCount: PropTypes.number.isRequired,
  hasInvitingManagerFields: PropTypes.bool.isRequired,
  isEsign: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});
