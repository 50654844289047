/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import /* OrgDefaultSkillsAvailability, */ { DEACTIVE_MODAL_ID } from 'settings/assets/js/components/OrgDefaultSkillsAvailability.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchOrganizationDS, postOrganizationDS } from 'settings/assets/js/data-services/organizations';
import OrgSkillsList from 'settings/assets/js/components/OrgSkillsList.jsx';

class OrgSkillsListView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return dispatch(fetchOrganizationDS(params, url, authedAxios, componentName));
  }

  static GetComponentName() {
    return 'OrgSkillsListView';
  }

  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(confirmed = false) {
    return new Promise((resolve) => {
      const componentName = this.constructor.GetComponentName();
      const { organization: { show_default_skills, unique_alias }, dispatch } = this.props;
      if (confirmed) {
        dispatch(postOrganizationDS(
          unique_alias, {
            show_default_skills: !show_default_skills,
          }, componentName,
        ));
        dispatch(requestRefreshAC(componentName));
      } else {
        dispatch(modalOpenAC(DEACTIVE_MODAL_ID));
      }
      resolve();
    });
  }

  render() {
    /* const {
      hasOrgSkills , organization, organization: {
        show_default_skills: showDefaultSkills,
      },
    } = this.props; */
    const { organization } = this.props;

    return (
      <React.Fragment>
        <TDApiConnected duck="view" component={this.constructor} skeletonComponent={SettingsPageSkeleton}>
          {/* TODO hidden until the back-end allows users selecting skills for a specific org */}
          {/* <div className="rounded shadow-sm p-4 bg-white">
            <OrgDefaultSkillsAvailability
              hasOrgSkills={hasOrgSkills}
              showDefaultSkills={showDefaultSkills}
              handleToggle={this.handleToggle}
            />
          </div> */}
          <OrgSkillsList organization={organization} />
        </TDApiConnected>
      </React.Fragment>
    );
  }
}

OrgSkillsListView.propTypes = {
  hasOrgSkills: PropTypes.bool,
  organization: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

OrgSkillsListView.defaultProps = {
  hasOrgSkills: false,
  organization: {},
};

const mapStateToProps = (state) => {
  const listState = getListState(state, OrgSkillsList.GetComponentName());
  const viewState = getViewState(state, OrgSkillsListView.GetComponentName());
  return {
    hasOrgSkills: listState.items.length > 0,
    organization: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const OrgSkillsListViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgSkillsListView);

export default OrgSkillsListViewConnect;
