import React from 'react';
import PropTypes from 'prop-types';
import ReduxToastr from 'react-redux-toastr';
import { isBoolean } from 'lodash';

import { HOMEPAGE_URL } from 'core/urls';
import { LOGO, TOASTR_DEFAULT_PROPS } from 'core/assets/js/constants';
import Root from 'core/assets/js/layout/placeholder/Root.jsx';
import CookiesNotification from 'core/assets/js/components/CookiesNotification.jsx';

const LayoutPlainSolo = ({ children, layoutProps }) => {
  const showTalentdeskLogo = isBoolean(layoutProps.showTalentdeskLogo)
    ? layoutProps.showTalentdeskLogo
    : true;

  const containerClass = layoutProps.containerClass || 'solo-container';
  const className = layoutProps.className || 'solo-container__box m-auto';

  return (
    <Root flexContainer flexVertically alignItemsCenter>
      <CookiesNotification />
      <React.Fragment>
        <ReduxToastr newestOnTop {...TOASTR_DEFAULT_PROPS} />
        <div className={containerClass}>
          <div className={className}>
            {showTalentdeskLogo && (
              <a className="solo-container__logo-container mb-4" href={HOMEPAGE_URL}>
                <img src={LOGO.DEFAULT} alt="TalentDesk.io" />
              </a>
            )}
            {children}
          </div>
        </div>
      </React.Fragment>
    </Root>
  );
};


LayoutPlainSolo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  layoutProps: PropTypes.object,
};

LayoutPlainSolo.defaultProps = {
  children: null,
  layoutProps: {},
};

export default LayoutPlainSolo;
