import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';
import { errorReportApiUrl } from 'core/urls';

// eslint-disable-next-line import/prefer-default-export
export const postErrorReportDS = ({ values }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: errorReportApiUrl(),
    values,
  })
);
