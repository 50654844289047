import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

import { BS_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import WidgetEmptyPlaceHolder from 'core/assets/js/components/Dashboard/WidgetEmptyPlaceHolder.jsx';

const WidgetWrapper = ({
  bodyExtraClasses,
  children,
  customHeaderActions,
  extraFooterContent,
  footerActionBtn,
  footerExtraClasses,
  headerActionBtn,
  headerExtraClasses,
  isEmpty,
  placeholder,
  popOverContent,
  popOverSize,
  popOverTitle,
  title,
}) => (
  <Card className={`dashboard-widget ${kebabCase(title)}`}>
    <Card.Header className={`dashboard-widget__header ${headerExtraClasses}`}>
      {popOverContent && popOverTitle && (
        <ElementWithPopOver
          className="d-inline-block"
          popOverClassName=""
          element={(
            <>
              {title}
              <i className={`ml-2 ${ICON.INFO}`} />
            </>
          )}
          popOverContent={popOverContent}
          popOverTitle={popOverTitle}
          popOverSize={popOverSize}
          placement="right-start"
        />
      )}

      {(!popOverContent || !popOverTitle) && <div className="d-inline-block">{title}</div>}

      {customHeaderActions}

      {!customHeaderActions && headerActionBtn && (
        <TDButton
          className="ml-auto"
          label={headerActionBtn.label}
          onClick={headerActionBtn.onClick}
          variant={headerActionBtn.variant || BS_STYLE.DEFAULT}
        />
      )}
    </Card.Header>
    <Card.Body className={`dashboard-widget__body ${bodyExtraClasses}`}>
      {isEmpty ? <WidgetEmptyPlaceHolder {...placeholder} /> : children}
    </Card.Body>
    <Card.Footer className={`dashboard-widget__footer ${footerExtraClasses}`}>
      <div className="d-flex align-items-center">
        {footerActionBtn && (
          <a
            className="dashboard-widget__footer--link ml-auto"
            onClick={footerActionBtn.onClick}
          >
            {footerActionBtn.label}
          </a>
        )}
      </div>
      {extraFooterContent}
    </Card.Footer>
  </Card>
);

WidgetWrapper.propTypes = {
  children: PropTypes.node,
  customHeaderActions: PropTypes.node,
  extraFooterContent: PropTypes.node,
  isEmpty: PropTypes.bool,
  headerActionBtn: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
  }),
  footerActionBtn: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
  }),
  placeholder: PropTypes.shape({
    content: PropTypes.string,
    icon: PropTypes.string,
  }),
  subTitle: PropTypes.string,
  title: PropTypes.string,
  headerExtraClasses: PropTypes.string,
  bodyExtraClasses: PropTypes.string,
  footerExtraClasses: PropTypes.string,
  popOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popOverSize: PropTypes.string,
  popOverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

WidgetWrapper.defaultProps = {
  children: null,
  customHeaderActions: null,
  extraFooterContent: null,
  footerActionBtn: null,
  headerActionBtn: null,
  isEmpty: false,
  placeholder: null,
  subTitle: null,
  title: null,
  headerExtraClasses: 'd-flex align-items-center',
  bodyExtraClasses: '',
  footerExtraClasses: '',
  popOverContent: null,
  popOverSize: BS_SIZE.DEFAULT,
  popOverTitle: null,
};

export default WidgetWrapper;
