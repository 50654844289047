import React from 'react';
import PropTypes from 'prop-types';

import ProjectCardSkeleton from './ProjectCardSkeleton.jsx';

const ProjectListSkeleton = ({ className }) => (
  <div className={['skeleton--project-list', className].join(' ')}>
    <ProjectCardSkeleton variation="1" />
    <ProjectCardSkeleton variation="2" />
    <ProjectCardSkeleton variation="1" />
    <ProjectCardSkeleton variation="2" />
    <ProjectCardSkeleton variation="1" />
    <ProjectCardSkeleton variation="2" />
  </div>
);

ProjectListSkeleton.propTypes = {
  className: PropTypes.string,
};

ProjectListSkeleton.defaultProps = {
  className: '',
};

export default ProjectListSkeleton;
