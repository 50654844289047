import PropTypes from 'prop-types';

import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { PEOPLE_TYPE } from 'people/assets/js/constants';

export const reviewSchema = {
  id: PropTypes.number,
  body: PropTypes.string,
  organization_id: PropTypes.number,
  author_id: PropTypes.number,
  author: userCardSpec,
  subject_id: PropTypes.number,
  created_at: PropTypes.string,
  task: PropTypes.object,
};
export const reviewSpec = PropTypes.shape(reviewSchema);

export const noteSpec = PropTypes.shape({
  id: PropTypes.number,
  body: PropTypes.string,
  rating: PropTypes.number,
  project_id: PropTypes.number,
  organization_id: PropTypes.number,
  author_id: PropTypes.number,
  author: userCardSpec,
  subject_id: PropTypes.number,
  created_at: PropTypes.string,
});

export const inviteeSpec = PropTypes.shape({
  id: PropTypes.number,
  avatar: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  status: PropTypes.number.isRequired,
  statusLabel: PropTypes.string.isRequired,
  userId: PropTypes.number,
});

export const profileSchema = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  address: PropTypes.object,
  jobTitle: PropTypes.string,
  skills: PropTypes.array,
};
export const profileSpec = PropTypes.shape(profileSchema);

export const pphSellerSpec = PropTypes.shape({
  budget: PropTypes.number,
  id: PropTypes.number.isRequired,
  numReviews: PropTypes.number,
  profile: profileSpec,
  projects: PropTypes.array,
  rating: PropTypes.number,
  submissions: PropTypes.array,
  userCard: userCardSpec,
});

export const peopleTypeSpec = PropTypes.oneOf([
  PEOPLE_TYPE.PROVIDERS,
  PEOPLE_TYPE.MANAGERS,
]);
