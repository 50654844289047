import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { documentSpec } from 'documents/assets/js/lib/objectSpecs';
import { documentNewVersionUrl } from 'documents/urls';

export const MODAL_ID_PREFIX = 'document-new-version-conform-modal-';

const DocumentNewVersionConfirmModal = ({
  dispatch, document, history, isModalOpen, match: { params: { orgAlias } },
}) => (
  <ModalConfirm
    closeOnConfirm={false}
    data-testid="document-new-version-confirm-modal"
    confirmLabel="Create"
    heading="Are you sure you want to create a new version?"
    onClose={() => dispatch(modalCloseAC())}
    onConfirm={async () => {
      history.push(documentNewVersionUrl(orgAlias, document.id));
    }}
    open={isModalOpen}
    size={MODAL_SIZES.LARGE}
  >
    {'If you create a new version of '}
    <b>{document.title}</b>
    {' the current version ('}
    <b>{document.version}</b>
    ) will be archived and you won&apos;t be able to use it anymore.
  </ModalConfirm>
);

DocumentNewVersionConfirmModal.GetComponentName = () => 'DocumentFormConfirmModal';

DocumentNewVersionConfirmModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  document: documentSpec.isRequired,
  history: routerHistorySpec.isRequired,
  isModalOpen: PropTypes.bool,
  match: routerMatchSpec.isRequired,
};

DocumentNewVersionConfirmModal.defaultProps = {
  isModalOpen: false,
};

const mapStateToProps = (state, props) => ({
  isModalOpen: getIsModalOpen(state, `${MODAL_ID_PREFIX}${props.document.id}`),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const DocumentNewVersionConfirmModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentNewVersionConfirmModal);

export default withRouter(DocumentNewVersionConfirmModalConnected);
