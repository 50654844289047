/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { pick, omit } from 'lodash';
import { FORM_ERROR } from 'final-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { orgIntegrationsUrl } from 'integrations/urls';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import {
  FIELDS_PER_SETTINGS_SECTION, INTEGRATION_DETAILS_TABS, SETTINGS_SECTION,
} from 'integrations/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import {
  fetchInvoiceSettingsDS, updateInvoiceSettingsDS,
} from 'integrations/assets/js/data-services/view';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';
import IntegrationAccountMappingSettings from 'integrations/assets/js/components/settings/IntegrationAccountMappingSettings.jsx';
import IntegrationInvoiceMappingSettings from 'integrations/assets/js/components/settings/IntegrationInvoiceMappingSettings.jsx';
import { taxRateRegExp } from 'integrations/assets/js/helpers';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const FORM_ID = 'integrations-invoice-mapping';

class IntegrationMapping extends React.Component {
  static FetchData({ dispatch, params, authedAxios, componentName }) {
    const { orgAlias, integrationId } = params;

    return Promise.all([
      dispatch(fetchInvoiceSettingsDS({ componentName, authedAxios, orgAlias, integrationId })),
    ]);
  }

  static GetComponentName({ params: { activeTab } }) {
    return `IntegrationMapping-${activeTab}`;
  }

  constructor() {
    super();

    this.handleFormSubmitted = this.handleFormSubmitted.bind(this);
    this.handleDiscardChanges = this.handleDiscardChanges.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { fields, settings: initialValues } = this.props;
    // Add {input.name}-custom-value values, so they aren't emptied if the form is submitted
    [
      SETTINGS_SECTION.INVOICE, SETTINGS_SECTION.LINE_ITEMS, SETTINGS_SECTION.TRACKING_CATEGORIES,
    ].forEach(section => {
      const fieldNames = FIELDS_PER_SETTINGS_SECTION[section] || [];
      fieldNames.forEach(fieldName => {
        const field = fields.find(f => f.name === fieldName);
        if (!field) {
          return;
        }
        const placeholders = field.optionGroups.map(o => o.options.map(i => i.value)).flat();
        const initialValue = initialValues[fieldName];
        const isCustomInitialValue = Boolean(initialValue) && !placeholders.includes(initialValue);
        if (isCustomInitialValue) {
          initialValues[`${fieldName}-custom-value`] = initialValue;
        }
      });
    });
    return initialValues;
  }

  handleDiscardChanges() {
    const { history, match: { params: { orgAlias } } } = this.props;
    return Promise.resolve(
      history.push(orgIntegrationsUrl(orgAlias)),
    );
  }

  async handleFormSubmitted(values) {
    const { dispatch, fields = [], match: { params } } = this.props;
    const { orgAlias, integrationId } = params;
    const componentName = this.constructor.GetComponentName({ params });
    const fieldNames = [
      ...fields.map(f => f.name), ...Object.keys(values).filter(name => taxRateRegExp.test(name)),
    ];
    try {
      await dispatch(
        updateInvoiceSettingsDS({
          orgAlias, integrationId, values: pick(values, fieldNames), componentName,
        }),
      );

      toastr.success('Well Done!', 'Your settings has been successfully updated.');
      return {};
    } catch (err) {
      // The data service throws a SubmissionError which wraps the error data as an errors object
      const { errors: { _error: errorMessage, ...errors } } = err;
      const formError = errorMessage || 'Oops! Something went wrong. Please try again';

      toastr.error('Oh Snap!', formError);
      return { [FORM_ERROR]: formError, ...omit(errors || {}, '_error', '_meta') };
    }
  }

  render() {
    const { hasLoaded, integration, tab } = this.props;
    const initialValues = this.getInitialValues();

    const isAccountMapping = tab === INTEGRATION_DETAILS_TABS.ACCOUNT_MAPPING;

    return (
      <>
        {/*
          The content should be inside TDApiConnected, but there was a bug where it was not
          rendering, even though the API request was successful.
          It just gets stuck on `!requestHasBeenTriggered` in withApi.
          Spent hours investigating, but could not find the root cause. So rendering the content
          outside of TDApiConnected is the hacky fix
        */}
        <TDApiConnected
          duck="view"
          component={this.constructor}
          skeletonComponent={() => null}
        />
        {hasLoaded && (
          <Form
            name={FORM_ID}
            onSubmit={this.handleFormSubmitted}
            mutators={{ ...arrayMutators }}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <div className="rounded shadow-sm p-4 bg-white">
                  <p>
                    To create an invoice in your account system, we need to do the
                    following configuration setup. Please choose how you would like to create an
                    invoice in your accounting system.
                  </p>

                  {isAccountMapping && <IntegrationAccountMappingSettings />}
                  {!isAccountMapping && (
                    <IntegrationInvoiceMappingSettings integrationName={integration.name} />
                  )}
                </div>

                <div className="mt-4 mb-8 w-100 d-flex justify-content-end">
                  {pristine && (
                    <TDButton
                      type="button"
                      variant={BS_STYLE.DEFAULT}
                      bsSize={BS_SIZE.LARGE}
                      onClick={this.handleDiscardChanges}
                      label="Cancel"
                    />
                  )}

                  {!pristine && (
                    <ConfirmationButton
                      buttonStyle={BS_STYLE.DEFAULT}
                      buttonSize={BS_SIZE.LARGE}
                      buttonLabel="Cancel"
                      modalProps={{
                        heading: 'Are you sure you want to discard changes to the invoice mapping?',
                        body: (
                          <React.Fragment>
                            <p>
                              There are unsaved changes in your
                              {` ${isAccountMapping ? 'Account' : 'Invoice'} Mapping and if you `}
                              discard now, you will lose those changes.
                            </p>
                            <p>
                              Are you sure you want to continue?
                            </p>
                          </React.Fragment>
                        ),
                        confirmLabel: 'Yes',
                        cancelLabel: 'No',
                        onConfirm: this.handleDiscardChanges,
                      }}
                    />
                  )}

                  <TDButton
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    bsSize={BS_SIZE.LARGE}
                    disabled={submitting}
                    label="Save"
                  />
                </div>
              </form>
            )}
          />
        )}
      </>
    );
  }
}

IntegrationMapping.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  hasLoaded: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  integration: integrationSpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  settings: PropTypes.object,
  tab: PropTypes
    .oneOf([INTEGRATION_DETAILS_TABS.ACCOUNT_MAPPING, INTEGRATION_DETAILS_TABS.INVOICE_MAPPING])
    .isRequired,
};

IntegrationMapping.defaultProps = {
  fields: [],
  settings: {},
};

const mapStateToProps = (state, { match: { params } }) => {
  const { hasLoaded, item: { fields, items: settings } } = getViewState(
    state, IntegrationMapping.GetComponentName({ params }),
  );

  return { fields, hasLoaded, settings };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationMappingConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationMapping);

export default withRouter(IntegrationMappingConnected);
