import { BEAMER } from 'core/assets/js/config/settings';
/**
 * Utility functions for HubSpot Chat
 */
class Beamer {
  /**
   * Open the HubSpot Chat widget ( chat window ).
   */
  static openWhatsNew() {
    if (BEAMER) {
      BEAMER.show();
    }
  }
}

export default Beamer;
