import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, getFormSubmitErrors } from 'redux-form';
import queryString from 'query-string';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import ModalResendVerification from 'accounts/assets/js/components/ModalResendVerification.jsx';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { submitLoginDS } from 'accounts/assets/js/data-services/account';
import { passwordForgotUrl } from 'accounts/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import { organizationsUrl } from 'organizations/urls';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      showVerificationModal: false,
    };

    this.handleOpenVerificationModal = this.handleToggleVerificationModal.bind(this, true);
    this.handleCloseVerificationModal = this.handleToggleVerificationModal.bind(this, false);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { testNotMounted } = this.props;
    this.setState({ mounted: !testNotMounted });
  }

  submit(values, dispatch) {
    const { orgAlias, location } = this.props;
    return dispatch(submitLoginDS(values, orgAlias)).then((response) => {
      const parsed = queryString.parse(location.search);
      let targetUrl = organizationsUrl(orgAlias);

      if (parsed.next) {
        targetUrl = parsed.next;
      } else if (response.auth.userProfile && response.auth.userProfile.homeUrl) {
        targetUrl = response.auth.userProfile.homeUrl;
      }

      WINDOW_REDIRECT(targetUrl);
    });
  }

  handleToggleVerificationModal(isVisible) {
    this.setState({
      showVerificationModal: isVisible,
    });
  }

  render() {
    const { mounted, showVerificationModal } = this.state;
    const { error, handleSubmit, submitting, submitErrors, submitSucceeded } = this.props;

    return (
      <div className="login-form">
        {submitErrors.verified && (
          <TDSystemMessage
            type={BS_STYLE.WARNING}
            title="Verify your Email"
            className="mb-3"
          >
            <p>
              Please verify your email address before you log in.
            </p>

            <p>
              Search your inbox and click on the verification link of the TalentDesk.io
              account verification email. If you can&apos;t find the relevant email,
              please click
              {' '}
              <a onClick={this.handleOpenVerificationModal} className="imitate-link">here</a>
              {' '}
              to re-send it.
            </p>

            <ModalResendVerification
              open={showVerificationModal}
              onClose={this.handleCloseVerificationModal}
            />
          </TDSystemMessage>
        )}
        {submitErrors.deactivated && (
          <TDSystemMessage
            type={BS_STYLE.WARNING}
            title="Your account is deactivated."
            className="mb-4"
          >
            <p>
              To enquire regarding the status of your account contact us at
              {' '}
              <a href="mailto:support@talentdesk.io">support@talentdesk.io</a>
            </p>
          </TDSystemMessage>
        )}
        <form onSubmit={handleSubmit(this.submit)} method="POST">
          <Field name="email" type="text" component={InputField} label="Email" placeholder="" />
          <Field
            component={InputField}
            label="Password"
            name="password"
            placeholder=""
            type="password"
          />
          <div className="form-group">
            <label className="d-inline-flex align-items-center" htmlFor="rememberMe">
              <Field className="mr-2" id="rememberMe" name="rememberMe" component="input" type="checkbox" />
              Remember me
            </label>
            <Link className="ml-5 float-right" data-testid="login-form-reset-password" to={passwordForgotUrl()}>
              Forgot your password?
            </Link>
          </div>
          {error && (
            <div className="form-group has-error">
              <span className="help-block">{error}</span>
            </div>
          )}
          <div className="form-group">
            <TDButton
              data-testid="button-login"
              block
              variant={BS_STYLE.PRIMARY}
              label={`LOG IN${mounted ? '' : ' LOADING...'}`}
              disabled={submitting || submitSucceeded || !mounted}
              type="submit"
            />
          </div>
        </form>

        {/*--
        NOTICE: Currently signups wihout invitations are disabled
        <div className="row text-center" style={{ marginTop: '42px' }}>
          Not a member yet? <Link to={signupUrl(next)}>Create your account</Link>
        </div>
        --*/}
      </div>
    );
  }
}

LoginForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  orgAlias: PropTypes.string,
  location: PropTypes.object,
  submitting: PropTypes.bool,
  submitErrors: PropTypes.object.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  testNotMounted: PropTypes.bool,
};
LoginForm.defaultProps = {
  error: '',
  location: {},
  submitting: false,
  orgAlias: null,
  testNotMounted: false,
};

const mapStateToProps = (state, props) => ({
  location: props.location,
  submitErrors: getFormSubmitErrors('login')(state),
});
const LoginFormConnected = connect(mapStateToProps)(LoginForm);
const LoginFormWithRouter = withRouter(LoginFormConnected);

export default reduxForm({
  form: 'login',
})(LoginFormWithRouter);
