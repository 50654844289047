import React from 'react';
import { Card } from 'react-bootstrap';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ContactFormSkeleton = () => (
  <Card className="contacts-skeleton-form shadow-none border-0">
    <Card.Body>
      <div className="row">
        <div className="col-sm-12 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="50" width="1200" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="20" width="500" />
        </div>
        <div className="col-sm-6 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="20" width="500" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="20" width="500" />
        </div>
        <div className="col-sm-6 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="20" width="500" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="20" width="500" />
        </div>
        <div className="col-sm-6 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="20" width="500" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="50" width="1200" />
        </div>
        <div className="col-sm-12 my-5">
          <RectangleSkeleton height="20" width="100" className="mb-3" />
          <br />
          <RectangleSkeleton height="50" width="1200" />
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default ContactFormSkeleton;
