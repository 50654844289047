import React from 'react';
import { Field, reduxForm } from 'redux-form';

import SelectField from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import { orgBankAccountOptionsSpec } from 'core/assets/js/lib/objectSpecs';

export const FORM_ID = 'bankAccountSelectorForm';

const BankAccountSelector = ({
  options,
}) => (
  <form className="bank-account-selector p-0 hide-help-block">
    <Field
      name="bank_account_id"
      type="text"
      optionsMapping={options}
      component={SelectField}
    />
  </form>
);

BankAccountSelector.propTypes = {
  options: orgBankAccountOptionsSpec,
};

BankAccountSelector.defaultProps = {
  options: [],
};

const BankAccountSelectorRedux = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
})(BankAccountSelector);

export default BankAccountSelectorRedux;
