import React from 'react';
import PropTypes from 'prop-types';

import Root from 'core/assets/js/layout/placeholder/Root.jsx';
import { LOGO } from 'core/assets/js/constants';


function LayoutError({ component: Component, ...rest }) {
  const { errors, resetError } = rest;

  return (
    <Root>
      <div className="error-page">
        <a className="error-page__logo-container mb-4" href="/">
          <img src={LOGO.DEFAULT} alt="TalentDesk.io" />
        </a>
        <Component errors={errors} resetError={resetError} />
      </div>
    </Root>
  );
}

LayoutError.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  resetError: PropTypes.func,
};
LayoutError.defaultProps = {
  errors: [],
  resetError: null,
};

export default LayoutError;
