import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { routerHistorySpec, siteWideBannerSpec } from 'core/assets/js/lib/objectSpecs';
import { isAbsolute } from 'core/assets/js/lib/utils';
import {
  SITE_WIDE_BANNER_TYPE, SITE_WIDE_BANNER_TYPE_COLOR, SITE_WIDE_BANNER_TYPE_TEXT_COLOR,
} from 'organizations/assets/js/constants';

const SiteWideBanner = ({
  banner: { buttonLabel, buttonUrl, content, dismissable, id, type }, history, onClose,
}) => (
  <div
    className="site-wide-banner d-flex w-100 justify-content-center align-items-center"
    style={{
      backgroundColor: SITE_WIDE_BANNER_TYPE_COLOR[type],
      color: SITE_WIDE_BANNER_TYPE_TEXT_COLOR[type],
    }}
  >
    <div className="site-wide-banner__content d-flex justify-content-center align-items-center">
      <MarkdownText className="m-5" text={content} withBreaksPlugin />
      {buttonLabel && buttonUrl && (
        <TDButton
          className="ml-5 text-nowrap"
          label={buttonLabel}
          onClick={() => {
            if (isAbsolute(buttonUrl)) {
              WINDOW_OPEN(buttonUrl, '_blank');
              return;
            }
            history.push(buttonUrl);
          }}
          variant={type === SITE_WIDE_BANNER_TYPE.GENERAL ? BS_STYLE.PRIMARY : BS_STYLE.DEFAULT}
        />
      )}
    </div>
    {dismissable && (
      <i
        className={`site-wide-banner__close ${ICON.CROSS} fa-lg mx-5`}
        onClick={() => onClose(id)}
      />
    )}
  </div>
);

SiteWideBanner.propTypes = {
  banner: siteWideBannerSpec.isRequired,
  history: routerHistorySpec.isRequired,
  onClose: PropTypes.func,
};

SiteWideBanner.defaultProps = {
  onClose: () => null,
};

export default withRouter(SiteWideBanner);
