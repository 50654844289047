import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Card } from 'react-bootstrap';
import { isNil, map, omit, pickBy, pick, isArray } from 'lodash';
import queryString from 'query-string';

import OrderingDropDown from 'core/assets/js/components/OrderingDropDown.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { SEARCH_MAX_LENGTH } from 'search/assets/js/constants';

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchInput: false,
    };

    this.resetFilter = this.resetFilter.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.handleToggleSearchInput = this.handleToggleSearchInput.bind(this);
    this.handleCloseFilters = this.handleCloseFilters.bind(this);
    this.submitOrdering = this.submitOrdering.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.countFilters = this.countFilters.bind(this);
  }

  getFieldProps() {
    const { searchSpec: { searchTerm }, initialValues } = this.props;
    const { kw } = initialValues;
    const inputFieldClass = ['input-field'];

    if (kw) {
      inputFieldClass.push('input-field--active');
    }

    return {
      className: inputFieldClass.join(' '),
      initialkwvalue: kw,
      key: searchTerm.paramName,
      name: searchTerm.paramName,
      component: searchTerm.component,
      placeholder: searchTerm.placeholder || 'Type here to search',
      maxLength: SEARCH_MAX_LENGTH,
    };
  }

  handleSelected(paramName, newValue) {
    this.submitFilters({ [paramName]: newValue });
  }

  handleToggleSearchInput() {
    const { showSearchInput } = this.state;
    this.setState({ showSearchInput: !showSearchInput });
  }

  handleCloseFilters() {
    const { onFiltersToggle } = this.props;

    onFiltersToggle(false);
  }

  submitOrdering(ordering) {
    const { history, location, onFiltersChanged } = this.props;
    // Get existing query
    const query = queryString.parse(location.search);

    // Close filters if open.
    this.handleCloseFilters();

    if (onFiltersChanged) {
      return onFiltersChanged({
        ...query,
        ordering: JSON.stringify(ordering),
      });
    }

    return history.push({
      path: history.location.pathName,
      search: queryString.stringify({
        ...query,
        ordering: JSON.stringify(ordering),
      }),
    });
  }

  submitFilters() {
    const { searchSpec, history, location, onFiltersChanged, formValues } = this.props;
    const filterNames = map(searchSpec.filters, f => f.paramName);
    // Get existing query
    let query = queryString.parse(location.search);
    query = {
      // Remove search related params
      ...omit(query, filterNames),
      // Add search params if set
      ...pickBy(formValues, p => !isNil(p)
        && p !== ''
        && p !== '{}',
      ),
      // Reset pagination
      page: 1,
    };

    // If user deletes the kw input, make sure we update the query too.
    if (formValues && !formValues.kw && query && query.kw) {
      delete query.kw;
    }

    this.handleCloseFilters();

    if (onFiltersChanged) {
      return onFiltersChanged(query);
    }

    return history.push({
      path: history.location.pathName, search: queryString.stringify(query),
    });
  }

  resetAllFilters() {
    const { history, location, searchSpec, onFiltersChanged, onFiltersToggle } = this.props;
    // Construct a list with filter names while resetting each one of them
    // if hidden filter exists then it will not be cleared when reset filters is clicked
    const filterNames = map(searchSpec.filters.filter(flt => !flt.hidden), f => f.paramName);

    const query = {
      // remove filter params from query
      ...omit(queryString.parse(location.search), filterNames),
      // Reset Pages
      page: 1,
    };

    if (onFiltersChanged) {
      onFiltersChanged(query);
    } else {
      // Update query string
      history.push({
        path: history.location.pathName, search: queryString.stringify(query),
      });
    }

    // Toggle filters form
    onFiltersToggle(false);
  }

  resetFilter(filter) {
    const { history, location, change, onFiltersChanged } = this.props;

    change(filter.paramName, null);
    // remove filter params from query
    const query = {
      ...omit(queryString.parse(location.search), filter.paramName),
      // Reset Pages
      page: 1,
    };

    if (onFiltersChanged) {
      onFiltersChanged(query);
    } else {
      // Update query string
      history.push({
        path: history.location.pathName, search: queryString.stringify(query),
      });
    }
  }

  countFilters() {
    const { searchSpec, location, query } = this.props;
    let count = 0;
    // White list filters.
    const availableFilterKeys = searchSpec.filters.map(filter => (
      // if hidden filter exists then it will not be counted as enabled filter
      !filter.isOrdering && !filter.hidden && filter.paramName
    ));
    // If query is provided, query will be used instead of location
    const _query = query || queryString.parse(location.search);
    // Extract filters from query string.
    const filters = {
      ...pick(
        _query,
        availableFilterKeys,
      ),
    };

    Object.keys(filters).forEach((key) => {
      if (
        filters[key] !== '{}'
        && !isNil(filters[key])
        && !(isArray(filters[key]) && filters[key].length === 0)
      ) {
        count += 1;
      }
    });

    return count;
  }

  renderFilters() {
    const { searchSpec, initialValues } = this.props;

    return searchSpec.filters && searchSpec.filters.filter(fltr => !fltr.hidden).map((filter) => {
      const {
        fieldComponent, paramName, fieldName, inputClassName = undefined, isOrdering = false,
      } = filter;

      return (
        <div
          key={paramName}
          className={`search-filter-item mb-4 ${isOrdering ? 'd-block d-md-none' : ''}`}
        >
          <Field
            name={fieldName || paramName}
            filter={filter}
            component={fieldComponent}
            inputClassName={inputClassName}
            initialValues={initialValues}
          />
        </div>
      );
    });
  }

  render() {
    const { showSearchInput } = this.state;
    const {
      searchSpec, submitting, filtersOpen, onFiltersToggle,
      initialValues: { kw }, className, 'data-testid': dataTestId,
    } = this.props;
    const { searchTerm, orderingOptions, defaultOrdering } = searchSpec;
    const fieldProps = this.getFieldProps();
    const filtersCount = this.countFilters();
    const filterButtonClass = ['toggle-filters-button px-4'];

    if (filtersOpen) {
      filterButtonClass.push('toggle-filters-button--open btn--active');
    }
    if (filtersCount > 0) {
      filterButtonClass.push('toggle-filters-button--active');
    }

    const classes = [className, 'col-auto', 'flex-grow-1', 'search-form'];
    if (showSearchInput) {
      classes.push('search-open');
    }

    return (
      <div className={classes.join(' ')} data-testid={dataTestId}>
        <form
          aria-label="Search Form"
          name="search-form"
          id="search-form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.submitFilters();
          }}
        >
          <div className="mb-4 d-flex flex-wrap">
            { orderingOptions && (
              <OrderingDropDown
                onOrderingChange={this.submitOrdering}
                orderingOptions={orderingOptions}
                defaultOrdering={defaultOrdering}
              />
            )}

            { searchSpec.filters && searchSpec.filters.length > 0 && (
              <TDButton
                className={filterButtonClass.join(' ')}
                variant={BS_STYLE.DEFAULT}
                label={(
                  <React.Fragment>
                    <i className={ICON.FILTERS} />
                    <span className="ml-0 ml-xl-2 d-none d-xl-inline-block">Filters</span>
                    {filtersCount > 0 && (
                      <React.Fragment>
                        {' '}
                        &bull;
                        {' '}
                        {filtersCount}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                onClick={() => onFiltersToggle(!filtersOpen)}
              />
            )}

            <TDButton
              className={`d-inline-block d-md-none px-4 ml-2 kw-field-toggle ${showSearchInput ? 'btn--active' : ''}`}
              variant={BS_STYLE.DEFAULT}
              label={(
                <i className={ICON.SEARCH} />
              )}
              onClick={this.handleToggleSearchInput}
            />

            { searchTerm && fieldProps.component && (
              <div
                className={`col-12 col-md-auto mt-3 mt-md-0 ml-0 ml-md-3 px-0 position-relative kw-field d-md-block ${showSearchInput ? 'd-block' : 'd-none'}`}
              >
                <Field {...fieldProps} />

                { kw && (
                  <span
                    onClick={() => this.resetFilter(searchTerm)}
                    className={`${ICON.CROSS} clear-kw`}
                  />
                )}

                <TDButton
                  className="search-button"
                  type="submit"
                  variant={BS_STYLE.LINK}
                  disabled={submitting}
                  label={<span className={ICON.SEARCH} />}
                />
              </div>
            )}
          </div>

          { filtersOpen && (
            <Card className="search-filters-list">
              <Card.Body className="d-flex flex-column justify-content-between">
                <div className="search-filters-container">
                  {this.renderFilters()}
                </div>

                <div className="text-right mt-5">
                  <TDButton
                    className="imitate-link"
                    variant={BS_STYLE.LINK}
                    disabled={submitting}
                    label="Clear filters"
                    onClick={this.resetAllFilters}
                  />

                  <TDButton
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    disabled={submitting}
                    label="Apply"
                  />
                </div>
              </Card.Body>
            </Card>
          )}
        </form>
      </div>
    );
  }
}

SearchForm.propTypes = {
  change: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
  filtersOpen: PropTypes.bool,
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersChanged: PropTypes.func,
  formValues: PropTypes.object,
  onFiltersToggle: PropTypes.func,
  query: PropTypes.object,
  searchSpec: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
};
SearchForm.defaultProps = {
  filtersOpen: false,
  onFiltersChanged: null,
  className: '',
  'data-testid': '',
  formValues: {},
  onFiltersToggle: () => {},
  query: null,
};


const mapStateToProps = (state, props) => {
  const formName = props.name ? `${props.name}-SearchForm` : 'searchForm';

  return {
    form: props.name ? `${props.name}-SearchForm` : 'searchForm',
    match: props.match,
    formValues: getFormValues(formName)(state),
  };
};

const SearchFormRedux = reduxForm({
  enableReinitialize: true,
})(SearchForm);

const SearchFormConnect = withRouter(connect(
  mapStateToProps,
)(SearchFormRedux));

export default SearchFormConnect;
