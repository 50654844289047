import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import { IMG_SIZE, MODAL_SIZES } from 'core/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';

const SalesTaxForm = ({
  activeOrg, companyTaxSystemInfo, initialValues, onCloseModal, onSubmit, show, sysOrg,
}) => {
  // If the sysOrg is set it means that TalentDesk.io makes the payments on behalf of the
  // client, so we display the system org details to the provider
  const targetOrg = sysOrg || activeOrg;

  const formRef = useRef(null);

  const onClose = () => {
    onCloseModal();
    // We need to reset the form reference after the modal is closed, so if it is re-opened, the
    // correct reference is used
    setTimeout(() => { formRef.current = null; }, 500);
  };

  return (
    <ModalConfirm
      body={(
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, form }) => {
            if (!formRef.current) {
              // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is broken
              // https://github.com/final-form/react-final-form/issues/483
              formRef.current = form;
            }
            return (
              <form onSubmit={handleSubmit}>
                <p>
                  Please note that it is your responsibility for setting up the correct
                  {' '}
                  {companyTaxSystemInfo.rateLabel}
                  {' '}
                  rate for invoices raised on your behalf by TalentDesk.io.
                </p>
                <p>
                  This includes cases where your client’s organisation is based in a
                  different country to yours.
                </p>
                { sysOrg && (
                  <p>
                    Since TalentDesk.io makes the payments on behalf
                    of
                    {' '}
                    <strong>{activeOrg.name}</strong>
                    , you need to set the correct
                    {' '}
                    {companyTaxSystemInfo.rateLabel}
                    {' '}
                    for invoices billed to TalentDesk.io.
                  </p>
                )}
                <div className="org-details vat-settings-item mt-4 clearfix">
                  <OrganizationLogo
                    className="organization-list-item__logo"
                    url={targetOrg.logo}
                    orgName={targetOrg.name}
                    size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
                  />
                  <div className="org-details-info-section">
                    <span className="organization-list-item__title">
                      {targetOrg.name}
                    </span>
                    <span>
                      {(targetOrg.address && targetOrg.address.description) || '-'}
                    </span>
                  </div>
                  <div>
                    <span className="float-right text-right">
                      <span className="discreet">VAT</span>
                      {' '}
                      {targetOrg.vat}
                    </span>
                  </div>
                </div>
                <div className="mt-4 row">
                  <div className="col-12 col-sm-6" style={{ marginBottom: '-20px' }}>
                    <InputNumberField
                      disabled={submitting}
                      label="Your sales tax"
                      min={0}
                      name="invoicing_vat_percent"
                      step={0.01}
                      suffix="%"
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />
      )}
      confirmLabel="Save"
      data-testid="sales-tax-form"
      heading={`Set your ${companyTaxSystemInfo.rateLabel}`}
      onClose={onClose}
      onConfirm={() => formRef.current && formRef.current.submit()}
      onConfirmSuccess={onClose}
      open={show}
      size={MODAL_SIZES.LARGE}
    />
  );
};

SalesTaxForm.propTypes = {
  activeOrg: orgSpec,
  companyTaxSystemInfo: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  sysOrg: orgSpec,
};

SalesTaxForm.defaultProps = {
  activeOrg: {},
  show: false,
  sysOrg: null,
};

export default SalesTaxForm;
