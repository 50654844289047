import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { withRouter } from 'react-router-dom';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { Categories } from 'people/assets/js/components/CategorySection.jsx';
import DiscoverTalentSearch from 'people/assets/js/components/DiscoverTalentSearch.jsx';
import FreelancersList from 'people/assets/js/components/FreelancersList.jsx';
import { orgPeopleDiscoverUrl } from 'people/urls';


const DiscoverPeopleFreelancersView = ({
  filtersOpen, location, match, onFiltersToggle, showFavoritesOnly, showHeader }) => {

  const getTitleOfBreadCrumb = () => {
    const queries = queryString.parse(location.search);
    if (queries.tags) {
      return Categories.find(c => c.id.toString() === queries.tags)?.label;
    }
    if (queries.kw) {
      return `Results for “${queries.kw}”`;
    }
    return 'Talent List';
  };

  const { orgAlias } = match.params;

  const breadcrumbs = [
    {
      title: 'TalentMarket',
      url: orgPeopleDiscoverUrl(orgAlias),
    },
    {
      title: getTitleOfBreadCrumb(),
      url: null,
    },
  ];

  return (
    <>
      {showHeader && <ContentHeader breadcrumbs={breadcrumbs} />}

      <div className="page page--people">
        <div className="container">
          <DiscoverTalentSearch
            filtersOpen={filtersOpen}
            onFiltersToggle={onFiltersToggle}
            peopleType="talents"
          />
          {!filtersOpen && <FreelancersList showFavoritesOnly={showFavoritesOnly} />}
        </div>
      </div>
    </>
  );
};

DiscoverPeopleFreelancersView.GetComponentName = () => 'DiscoverPeopleFreelancersView';

DiscoverPeopleFreelancersView.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  onFiltersToggle: PropTypes.func.isRequired,
  showFavoritesOnly: PropTypes.bool,
  showHeader: PropTypes.bool,
};

DiscoverPeopleFreelancersView.defaultProps = {
  match: {},
  showFavoritesOnly: false,
  showHeader: true,
};

export default withRouter(withFilters(DiscoverPeopleFreelancersView));
