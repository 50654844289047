import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import {
  USER_TYPE,
} from 'core/assets/js/constants';
import { orgUserProfileUrl, orgPeopleDiscoverDetailUrl } from 'people/urls';

const ProfileLinkDropDownItem = ({ chat }) => {
  const history = useHistory();
  const activeOrg = useSelector((state) => selectActiveOrg(state));
  const activeUserCard = useSelector((state) => selectActiveUserCard(state));
  const chatRoomUser = chat?.chatRoomUsers?.find(cu => cu.userId !== activeUserCard.user.id);

  if (!chatRoomUser?.allowedActions?.canViewProfile) {
    return null;
  }

  return (
    <Dropdown.Item
      onClick={() => {
        let profileUrl;
        if (chatRoomUser?.isTalentMarket) {
          profileUrl = orgPeopleDiscoverDetailUrl(
            activeOrg.alias,
            chatRoomUser?.talentId,
          );
        } else {
          profileUrl = orgUserProfileUrl(
            activeOrg.alias,
            chatRoomUser?.isManager
              ? USER_TYPE.MANAGER
              : USER_TYPE.PROVIDER,
            chatRoomUser?.userId,
          );
        }
        history.push(profileUrl);
      }}
    >
      View profile
    </Dropdown.Item>
  );
};

ProfileLinkDropDownItem.propTypes = {
  chat: PropTypes.object,
};

ProfileLinkDropDownItem.defaultProps = {
  chat: null,
};

export default ProfileLinkDropDownItem;
