import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import TaskItemField from 'projects/assets/js/components/TaskItemField.jsx';
import { EVENT_KEY } from 'core/assets/js/constants';
import { UPDATE_MANUALLY } from 'core/assets/js/components/FinalFormFields/EditableValueField.jsx';

/**
 * This component should be used for cases where each task item gets updated separately
 * For example in task view page, where the provider or manager can add & update task items
 * without updating the task itself
 */
const TaskItemForm = ({
  index,
  reset,
  disabled,
  initialValues,
  isInvalid,
  isEditable,
  isSubmitEnabled,
  isCompleted,
  onItemRemoved,
  onItemToggled,
  handleSubmit,
  onItemDuplicated,
  onUpdateCancelled,
  onDescriptionReset,
  onEditableToggled,
}) => {
  // Called when the item's description becomes editable
  const handleDescriptionEditable = () => onEditableToggled(true);
  const resetForm = () => reset(initialValues);

  // Called when the item's description switches from editable to viewable mode
  const handleDescriptionViewable = (idx, event) => {
    onEditableToggled(false);

    // On cancel, revert to the initial value
    if (event && event.key === EVENT_KEY.ESCAPE) {
      resetForm();
      onDescriptionReset(index);
    }
  };

  const handleFormSubmitted = async (values) => {
    await handleSubmit(values);

    if (!isInvalid) {
      handleDescriptionViewable(index);
    }
  };

  // Cancel key hit in the form
  const handleUpdateCancelled = () => {
    resetForm();
    handleDescriptionViewable(index, true);
    return onUpdateCancelled(index);
  };

  // Called when the "completed" checkbox gets toggled
  const handleItemToggled = (idx, e, isChecked) => onItemToggled(index, e, isChecked);

  // override the state variable when errors are present
  const isFieldEditable = isInvalid || isEditable;

  return (
    <form onSubmit={handleFormSubmitted}>
      <TaskItemField
        index={index}
        disabled={disabled}
        updateMode={UPDATE_MANUALLY}
        isEditable={isFieldEditable}
        isCompleted={isCompleted}
        onCompleteUpdated={handleItemToggled}
        onDescriptionEditable={handleDescriptionEditable}
        onDescriptionViewable={handleDescriptionViewable}
        onItemRemoved={onItemRemoved}
        onItemDuplicated={onItemDuplicated}
      />

      {isFieldEditable && (
        <div
          className="task-controls-container mt-4 mb-4 text-right px-0 d-flex justify-content-end align-items-center"
        >
          <button
            type="button"
            className="btn btn-link text-secondary px-0 mr-2"
            onClick={handleUpdateCancelled}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-primary btn-sm"
            disabled={!isSubmitEnabled}
          >
            Save
          </button>
        </div>
      )}
    </form>
  );
};

TaskItemForm.propTypes = {
  disabled: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool,
  isEditable: PropTypes.bool,
  isSubmitEnabled: PropTypes.bool,
  onItemToggled: PropTypes.func,
  onEditableToggled: PropTypes.func,
  onItemRemoved: PropTypes.func.isRequired,
  onItemDuplicated: PropTypes.func.isRequired,
  onUpdateCancelled: PropTypes.func,
  onDescriptionReset: PropTypes.func,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};
TaskItemForm.defaultProps = {
  disabled: false,
  isInvalid: false,
  isEditable: false,
  isCompleted: false,
  isSubmitEnabled: true,
  initialValues: {},
  onItemToggled: () => {},
  onUpdateCancelled: () => {},
  onDescriptionReset: () => {},
  onEditableToggled: () => {},
};


// eslint-disable-next-line react/no-multi-comp
const TaskItemFormWrapper = ({
  index,
  autoFocus,
  formName,
  onItemUpdated,
  initialValues,
  ...rest
}) => {
  // Submit handler
  const [isEditable, setIsEditable] = useState(!!autoFocus);

  const submitHandler = async (values) => {
    try {
      await onItemUpdated(values, index);
      return {};
    } catch (err) {
      return {
        [FORM_ERROR]: err.errors._error || 'Oops! Something went wrong. Please try again',
        ...omit(err.errors || {}, '_error', '_meta'),
      };
    }
  };

  return (
    <Form
      name={formName}
      onSubmit={submitHandler}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => {
        const { getState, reset } = form;
        const {
          dirty, hasSubmitErrors, hasValidationErrors, submitting, validating, submitFailed,
        } = getState();

        const isInvalid = hasSubmitErrors || hasValidationErrors || submitFailed;
        const isFieldEditable = dirty || validating || submitting || submitFailed || isEditable;

        // hint: we're disabling the submit button because due to this:
        // https://github.com/final-form/react-final-form/issues/430
        // we're not getting a value for the `submitting` field which removes the editable field
        return (
          <TaskItemForm
            handleSubmit={handleSubmit}
            index={index}
            formName={formName}
            invalid={isInvalid}
            isEditable={isFieldEditable}
            isSubmitEnabled={dirty}
            reset={reset}
            onEditableToggled={setIsEditable}
            onItemUpdated={onItemUpdated}
            initialValues={initialValues}
            {...rest}
          />
        );
      }}
    />
  );
};

TaskItemFormWrapper.propTypes = {
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isCompleted: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onItemToggled: PropTypes.func,
  onItemUpdated: PropTypes.func.isRequired,
  onItemRemoved: PropTypes.func.isRequired,
  onItemDuplicated: PropTypes.func.isRequired,
  onUpdateCancelled: PropTypes.func,
  onDescriptionReset: PropTypes.func,
  invalid: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
};

TaskItemFormWrapper.defaultProps = {
  disabled: false,
  autoFocus: false,
  isCompleted: false,
  initialValues: {},
  onItemToggled: () => {},
  onUpdateCancelled: () => {},
  onDescriptionReset: () => {},
};

export default TaskItemFormWrapper;
