import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput, { getCountries } from 'react-phone-number-input';

import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';

export const COUNTRIES = getCountries();

const PhoneNumberField = ({
  additionalError,
  className,
  'data-testid': dataTestId,
  defaultCountry,
  disabled,
  input,
  label,
  meta: { error, pristine, submitError },
  muteErrorMessage,
  placeholder,
  required,
  sublabel,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = (submitError || error || showAdditionalError) && !muteErrorMessage;
  const classNames = ['form-group'];

  if (className) {
    classNames.push(className);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />
      {showAdditionalError && !muteErrorMessage && (
        <span className="help-block mt-3">{additionalError}</span>
      )}
      <PhoneInput
        addInternationalOption={false}
        defaultCountry={defaultCountry}
        disabled={disabled}
        international
        numberInputProps={{ id: `field-${input.name}`, name: input.name }}
        onChange={input.onChange}
        placeholder={placeholder}
        value={input.value}
        withCountryCallingCode
      />
      {error && <span className="help-block d-inline-block mt-3">{error}</span>}
      {hasError && <span className="help-block d-inline-block mt-3">{submitError}</span>}
    </div>
  );
};

PhoneNumberField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultCountry: PropTypes.oneOf(COUNTRIES),
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  meta: finalFormFieldMetaSpec,
  muteErrorMessage: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
};

PhoneNumberField.defaultProps = {
  additionalError: '',
  className: null,
  'data-testid': 'phone-number-field',
  defaultCountry: 'US',
  disabled: false,
  label: '',
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  muteErrorMessage: false,
  placeholder: 'Enter phone number',
  required: false,
  sublabel: null,
};

export default withField(PhoneNumberField);
