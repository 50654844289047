import Big from 'big.js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import {
  BS_STYLE, DATETIME_FORMAT_HUMAN_FRIENDLY, ICON, USER_TYPE,
} from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgUserProfileUrl } from 'people/urls';
import {
  INVOICE_CAP_USAGE_BAR_COMPONENT_NAME, SERVICE_ORDER_TYPE, SERVICE_ORDER_TYPE_LABEL,
} from 'projects/assets/js/constants';
import { fetchInvoiceCapUsageDS, getInvoiceCapUsage } from 'projects/assets/js/data-services/view';

Big.RM = 1;
Big.DP = 2;

const InvoiceCapUsageBar = ({
  additionalOrganizationAmount,
  className,
  displayWarnings,
  serviceOrderId,
  serviceOrderType,
  userId,
  serviceOrderPeriodEnd,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const getUsageData = useSelector(getInvoiceCapUsage);
  const usageData = getUsageData({ additionalOrganizationAmount, serviceOrderPeriodEnd });
  const dispatch = useDispatch();
  const invoiceCapsEnabled = activeOrg.invoice_caps_enabled;
  const [displayInHours, setDisplayInHours] = useState(false);

  useEffect(() => {
    if (invoiceCapsEnabled) {
      dispatch(fetchInvoiceCapUsageDS({ orgAlias: activeOrg.alias, serviceOrderId, userId }));
    }
  }, [activeOrg.alias, serviceOrderId, userId]);

  if (!invoiceCapsEnabled) {
    return null;
  }

  const {
    defaultRateAmount,
    invoiceCap,
    invoiceCapIsSet,
    managerId,
    managerName,
    over100,
    periodEndISO,
    periodStartISO,
    totalUsage,
    usage,
    usageAt100,
  } = usageData;

  if (!invoiceCapIsSet) {
    return null;
  }

  let usedAmountPercent = 0;
  if (usage) {
    usedAmountPercent = Big(usage).div(invoiceCap).times(100).toNumber();
  }
  let additionalAmountPercent = 0;
  if (
    additionalOrganizationAmount
    && (
      !serviceOrderPeriodEnd
      || (
        new Date(`${serviceOrderPeriodEnd}T23:59:59.999Z`).getTime()
          > new Date(periodStartISO).getTime()
      )
    )
  ) {
    additionalAmountPercent = Big(additionalOrganizationAmount || 0)
      .div(invoiceCap).times(100).toNumber();
    if (totalUsage > invoiceCap) {
      additionalAmountPercent = Big(100).minus(usedAmountPercent).toNumber();
    } else {
      additionalAmountPercent = Big(additionalOrganizationAmount)
        .div(invoiceCap).times(100).toNumber();
    }
  }

  const wrapperClassNames = ['invoice-cap-usage-bar w-100 d-flex flex-column'];
  if (className) {
    wrapperClassNames.push(className);
  }

  const periodStartLocalTime = formatDate(periodStartISO, DATETIME_FORMAT_HUMAN_FRIENDLY);
  const periodEndLocalTime = formatDate(periodEndISO, DATETIME_FORMAT_HUMAN_FRIENDLY);
  const periodLocalTime = `${periodStartLocalTime} - ${periodEndLocalTime}`;

  return (
    <div className={wrapperClassNames.join(' ')}>
      {displayWarnings && over100 && (
        <TDSystemMessage className="mb-5" title="Exceeding Invoice Cap!" type={BS_STYLE.DANGER}>
          You are exceeding your Invoice Cap for the current period
          {` (${periodLocalTime}). If you think this is a mistake, please contact `}
          your manager
          {managerName && (
            <>
              {' '}
              <Link
                to={orgUserProfileUrl(activeOrg.alias, USER_TYPE.MANAGER, managerId)}
              >
                {managerName}
              </Link>
            </>
          )}
          .
        </TDSystemMessage>
      )}
      {displayWarnings && usageAt100 && (
        <TDSystemMessage
          className="mb-5"
          title="Invoice Cap has been reached!"
          type={BS_STYLE.WARNING}
        >
          You have already reached your Invoice Cap for the current period
          {` (${periodLocalTime}). You cannot submit any new `}
          {`${SERVICE_ORDER_TYPE_LABEL[serviceOrderType]}s in this period. If you think this is a `}
          mistake, please contact your manager
          {managerName && (
            <>
              {' '}
              <Link
                to={orgUserProfileUrl(activeOrg.alias, USER_TYPE.MANAGER, managerId)}
              >
                {managerName}
              </Link>
            </>
          )}
          .
        </TDSystemMessage>
      )}
      <div className="invoice-cap-usage-bar__text d-flex justify-content-between align-items-center">
        <div>
          Invoice Cap utilisation for the current period
          {` (${periodLocalTime})`}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <div
            className="text-right usage-amount text-nowrap"
            onClick={() => {
              if (defaultRateAmount) {
                setDisplayInHours(!displayInHours);
              }
            }}
          >
            {displayInHours && (
              (parseFloat(totalUsage) / parseFloat(defaultRateAmount)).toFixed(2)
            )}
            {!displayInHours && (
              <NumberTpl
                className={over100 ? 'text-danger' : ''}
                currency={activeOrg.currency}
                value={totalUsage}
              />
            )}
            /
            <b>
              {displayInHours && (
                (parseFloat(invoiceCap) / parseFloat(defaultRateAmount)).toFixed(2)
              )}
              {!displayInHours && (
                <NumberTpl currency={activeOrg.currency} value={invoiceCap} />
              )}
            </b>
            {displayInHours && ' hours'}
          </div>
          <TDElementWithTooltip
            el={<span className="ml-2"><i className={ICON.INFO} /></span>}
            tooltipMsg={`Click to see usage by ${displayInHours ? 'earnings' : 'hours'}`}
          />
        </div>
      </div>
      <div className="bar w-100 d-flex">
        {usedAmountPercent > 0 && (
          <div className="used h-100" style={{ width: `${usedAmountPercent}%` }} />
        )}
        {additionalAmountPercent > 0 && (
          <div
            className={`additional h-100${over100 ? ' more-than-100' : ''}`}
            style={{ width: `${additionalAmountPercent}%` }}
          />
        )}
      </div>
    </div>
  );
};

InvoiceCapUsageBar.GetComponentName = () => INVOICE_CAP_USAGE_BAR_COMPONENT_NAME;

InvoiceCapUsageBar.propTypes = {
  additionalOrganizationAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  displayWarnings: PropTypes.bool,
  serviceOrderId: PropTypes.number,
  serviceOrderType: PropTypes.oneOf([
    SERVICE_ORDER_TYPE.PROFORMA_INVOICE, SERVICE_ORDER_TYPE.WORKSHEET,
  ]),
  userId: PropTypes.number.isRequired,
  serviceOrderPeriodEnd: PropTypes.string,
};

InvoiceCapUsageBar.defaultProps = {
  additionalOrganizationAmount: null,
  className: null,
  displayWarnings: true,
  serviceOrderId: null,
  serviceOrderType: null,
  serviceOrderPeriodEnd: null,
};

export default InvoiceCapUsageBar;
