import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AllowAccessToHigherManager from 'core/assets/js/components/AllowAccessToHigherManager.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import { DATE_FORMAT_NO_DAY } from 'core/assets/js/constants';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import { ProcessingFeeSpec, LicenceFeeSpec } from 'finance/assets/js/components/invoicing/FeeScheme.jsx';
import { getActiveUserCardPermissionChecker } from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';

const OrganizationSummary = ({ org }) => {
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const {
    invoicing_fee_scheme: invoicingFeeScheme,
    invoicing_frequency_label: invoicingFrequency,
    payments_executed_by: paymentsExecutedBy,
    is_active: isActive,
  } = org;

  const scheme = invoicingFeeScheme && new InvoicingFeeScheme(invoicingFeeScheme);

  return (
    <div className="org-summary">
      <div className="org-summary__item org-summary__item--active">
        <label>Active</label>
        <span>
          {isActive && 'Yes'}
          {!isActive && 'No'}
        </span>
      </div>

      <div className="org-summary__item org-summary__item--currency">
        <label>Currency</label>
        <span>{`${org.currency_label} (${org.currency_symbol})`}</span>
      </div>

      <div className="org-summary__item org-summary__item--payments_by">
        <label>Payments by</label>
        <span>{paymentsExecutedBy}</span>
      </div>

      {scheme && scheme.hasFee() && hasPermission(PERMISSIONS.CAN_VIEW_ORGANIZATION_SETUP) && (
        <>
          {scheme && scheme.hasProcessingFee() && (
            <div className="org-summary__item org-summary__item--processing_fees">
              <label>Service fees</label>
              <ProcessingFeeSpec scheme={scheme.getProcessingFeeSpec()} />
            </div>
          )}
          {scheme && scheme.hasLicenceFee() && (
            <div className="org-summary__item org-summary__item--licence_fees">
              <label>Licence fees</label>
              <LicenceFeeSpec scheme={scheme.getLicenceFeeSpec()} />
            </div>
          )}
        </>
      )}

      <div className="org-summary__item">
        <label>Unique alias</label>
        <span>{org.alias}</span>
      </div>
      { org.budgeting_year_end && (
        <div className="org-summary__item">
          <label>Budgeting year end</label>
          <span>
            {formatDate(org.budgeting_year_end, DATE_FORMAT_NO_DAY)}
          </span>
        </div>
      )}

      <div className="org-summary__item">
        <label>Payment cycle</label>
        <span>{invoicingFrequency}</span>
      </div>

      <AllowAccessToHigherManager>
        <div className="org-summary__item">
          <label>Credit period</label>
          <span>{`${org.invoicing_grace_period} days`}</span>
        </div>
      </AllowAccessToHigherManager>

      <AllowAccessToHigherManager>
        <div className="org-summary__item">
          <label>Default rate unit</label>
          <span>{org.default_rate_unit && RATE_UNIT_FORMAT[org.default_rate_unit].title}</span>
        </div>
      </AllowAccessToHigherManager>

      { org.is_currently_on_trial && (
        <div className="org-summary__item">
          <label>Trial Expires On</label>
          <span>
            {formatDate(org.trial_expires_at)}
          </span>
        </div>
      )}
    </div>
  );
};

OrganizationSummary.propTypes = {
  org: PropTypes.object,
};

OrganizationSummary.defaultProps = {
  org: {},
};

export default OrganizationSummary;
