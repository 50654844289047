/* eslint import/prefer-default-export: off */
import PropTypes from 'prop-types';

import { companySpec, userSpec } from 'accounts/assets/js/lib/objectSpecs';
import { currencySpec, currencyCodeSpec, currencySymbolSpec } from 'core/assets/js/lib/objectSpecs';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_LABEL,
  INVOICE_TYPE,
  EXCHANGE_RATE_SERVICE,
  EXCHANGE_RATE_SERVICE_VALID_VALUES,
  US_TAX_FORM_TYPE_VALUES,
} from 'finance/assets/js/constants';
import { orgSpec, orgCompanySpec } from 'organizations/assets/js/lib/objectSpecs';
import { permissionsSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_STATUS_LABEL,
  SERVICE_ORDER_STATUS,
  SERVICE_ORDER_TYPE,
  WORKSHEET_STATUS,
  WORKSHEET_STATUS_LABEL,
} from 'projects/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { CURRENCY, CURRENCY_SYMBOL, CURRENCY_VALUES } from 'core/assets/js/constants';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';


export const outstandingCountSpec = PropTypes.shape({
  pos: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  worksheets: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expenses: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invoices: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const financeEntitySpec = PropTypes.shape({
  user: userSpec,
  company: orgCompanySpec,
});

export const postingSpec = PropTypes.shape({
  id: PropTypes.number,
  date: PropTypes.string,
  balance: PropTypes.string,
  changeAmt: PropTypes.string,
  ownerId: PropTypes.number,
  initiatorId: PropTypes.number,
  isDebit: PropTypes.bool,
  isCredit: PropTypes.bool,
  postingTypeLabel: PropTypes.string,
});

export const managerBudgetSpec = PropTypes.shape({
  allocatedToProjectsBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  approvedBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  availableBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  deallocatedFromProjectsBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullName: PropTypes.string,
  id: PropTypes.number,
  usedBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const managerBudgetTotalsSpec = PropTypes.shape({
  approved: PropTypes.string,
  allocatedToProjects: PropTypes.string,
  deallocatedFromProjects: PropTypes.string,
  available: PropTypes.string,
});

export const managerBudgetInfoSpec = PropTypes.shape({
  currency: PropTypes.string,
  fullName: PropTypes.string,
});

export const invoiceItemSpec = PropTypes.shape({
  id: PropTypes.number.isRequired,
  amount: PropTypes.string,
  fee: PropTypes.string,
  preVat: PropTypes.string,
  vat: PropTypes.string,
  total: PropTypes.string,
  description: PropTypes.string,
  project: PropTypes.string,
  provider: PropTypes.string,
  rate: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  period: PropTypes.string,
});

export const invoiceSpec = PropTypes.shape({
  id: PropTypes.number,
  orgId: PropTypes.number,
  ownerId: PropTypes.number,
  recipientId: PropTypes.number,
  status: PropTypes.oneOf(Object.values(INVOICE_STATUS)),
  statusLabel: PropTypes.oneOf(Object.values(INVOICE_STATUS_LABEL)),
  number: PropTypes.string,
  total: PropTypes.string,
  raisedBy: PropTypes.string,
  raisedFor: PropTypes.string,
  billingPeriod: PropTypes.string,
  date: PropTypes.string,
  dueAt: PropTypes.string,
  isOverdue: PropTypes.bool,
  ownerFE: PropTypes.object,
  recipientFE: PropTypes.object,
});

export const invoiceRenderSpec = PropTypes.shape({
  id: PropTypes.number,
  number: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INVOICE_TYPE)),
  createdAt: PropTypes.string,
  periodStart: PropTypes.string,
  periodEnd: PropTypes.string,
  billingPeriod: PropTypes.string,
  gracePeriod: PropTypes.number,
  date: PropTypes.string,
  dueAt: PropTypes.string,
  currency: currencyCodeSpec,
  currencySymbol: currencySymbolSpec,
  amounts: PropTypes.object,
  terms: PropTypes.string,
  invoiceItemGroups: PropTypes.arrayOf(PropTypes.object),
  invoiceItemIds: PropTypes.array,
  allowedActions: PropTypes.object,
});

export const purchaseOrderSchema = {
  allowedActions: PropTypes.object,
  budget: PropTypes.number,
  createdAt: PropTypes.string,
  currency: currencySpec,
  currencySymbol: currencySymbolSpec,
  externalProjectId: PropTypes.string,
  id: PropTypes.number,
  managerName: PropTypes.string,
  ownerId: PropTypes.number,
  permissions: permissionsSpec,
  projectReference: PropTypes.string,
  projectTitle: PropTypes.string,
  rejectionReason: PropTypes.string,
  reviewerId: PropTypes.number,
  reviewerName: PropTypes.string,
  status: PropTypes.oneOf(Object.values(PURCHASE_ORDER_STATUS)),
  statusCode: PropTypes.oneOf(Object.values(PURCHASE_ORDER_STATUS)),
  statusLabel: PropTypes.oneOf(Object.values(PURCHASE_ORDER_STATUS_LABEL)),
};

export const purchaseOrderSpec = PropTypes.shape(purchaseOrderSchema);

export const auditTrailSpec = PropTypes.arrayOf(PropTypes.shape({
  by: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  message: PropTypes.string,
  status: PropTypes.number,
  status_label: PropTypes.string,
}));

export const serviceOrderItemSchema = {
  createdAt: PropTypes.string,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exchangeRateUpdatedAt: PropTypes.string,
  id: PropTypes.number,
  description: PropTypes.string,
  organizationRate: PropTypes.string,
  organizationRateAmount: PropTypes.string,
  organizationCurrency: PropTypes.string,
  organizationCurrencySymbol: PropTypes.string,
  organizationTotal: PropTypes.string,
  project: PropTypes.object,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rateId: PropTypes.number,
  rateUnit: PropTypes.number,
  task: PropTypes.object,
  taskId: PropTypes.number,
  userId: PropTypes.number,
};

export const worksheetItemSchema = {
  ...serviceOrderItemSchema,
  worksheetId: PropTypes.number,
  worksheetItemCurrency: PropTypes.string,
  worksheetItemCurrencySymbol: PropTypes.string,
  worksheetItemRateAmount: PropTypes.string,
  worksheetItemToOrganizationFxRateSource: PropTypes.oneOf(Object.values(EXCHANGE_RATE_SERVICE)),
  worksheetItemTotal: PropTypes.string,
};

export const expenseItemSchema = {
  ...serviceOrderItemSchema,
  expenseId: PropTypes.number,
  expenseItemCurrency: PropTypes.string,
  expenseItemCurrencySymbol: PropTypes.string,
  expenseItemRateAmount: PropTypes.string,
  expenseItemToOrganizationFxRateSource: PropTypes.oneOf(Object.values(EXCHANGE_RATE_SERVICE)),
  expenseItemTotal: PropTypes.string,
};

export const proFormaInvoiceItemSchema = {
  ...serviceOrderItemSchema,
  proFormaInvoiceId: PropTypes.number,
  proFormaInvoiceItemCurrency: PropTypes.string,
  proFormaInvoiceItemCurrencySymbol: PropTypes.string,
  proFormaInvoiceItemRateAmount: PropTypes.string,
  proFormaInvoiceItemToOrganizationFxRateSource: PropTypes.oneOf(
    Object.values(EXCHANGE_RATE_SERVICE),
  ),
  proFormaInvoiceItemTotal: PropTypes.string,
};

export const serviceOrderSchema = {
  id: PropTypes.number,
  allowedActions: PropTypes.object,
  amount: PropTypes.string,
  auditTrail: auditTrailSpec,
  avatar: PropTypes.string,
  cancellationReason: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  createdAt: PropTypes.string,
  currency: currencySpec,
  currencySymbol: currencySymbolSpec,
  externalProjectId: PropTypes.string,
  invoices: PropTypes.arrayOf(invoiceSpec),
  isDelayed: PropTypes.bool,
  managerName: PropTypes.string,
  org: orgSpec,
  period: PropTypes.string,
  processAt: PropTypes.string,
  processed: PropTypes.bool,
  projectReference: PropTypes.string,
  provider: financeEntitySpec,
  providerName: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  rejectionReason: PropTypes.string,
  status: PropTypes.oneOf(Object.values(WORKSHEET_STATUS_LABEL)),
  statusCode: PropTypes.oneOf(Object.values(WORKSHEET_STATUS)),
  summary: PropTypes.string,
  type: PropTypes.oneOf(Object.values(SERVICE_ORDER_TYPE)),
  wasRaisedLater: PropTypes.bool,
};

export const worksheetSchema = {
  ...serviceOrderSchema,
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  permissions: permissionsSpec,
  items: PropTypes.arrayOf(PropTypes.shape(worksheetItemSchema)),
  worksheetAmount: PropTypes.string,
  worksheetCurrency: PropTypes.oneOf(Object.values(CURRENCY)),
  worksheetCurrencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  worksheetToOrganizationFxRateSource: PropTypes.oneOf(Object.values(EXCHANGE_RATE_SERVICE)),
};

export const worksheetSpec = PropTypes.shape(worksheetSchema);

export const worksheetRenderedSpec = PropTypes.shape({
  ...worksheetSchema, amount: PropTypes.element,
});

export const expenseSchema = {
  ...serviceOrderSchema,
  cost: PropTypes.string,
  permissions: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape(expenseItemSchema)),
  expenseAmount: PropTypes.string,
  expenseCurrency: PropTypes.oneOf(Object.values(CURRENCY)),
  expenseCurrencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  expenseToOrganizationFxRateSource: PropTypes.oneOf(Object.values(EXCHANGE_RATE_SERVICE)),
};

export const expenseSpec = PropTypes.shape(expenseSchema);

export const proFormaInvoiceSchema = {
  ...serviceOrderSchema,
  permissions: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape(proFormaInvoiceItemSchema)),
  proFormaInvoiceAmount: PropTypes.string,
  proFormaInvoiceCurrency: PropTypes.oneOf(Object.values(CURRENCY)),
  proFormaInvoiceCurrencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  proFormaInvoiceToOrganizationFxRateSource: PropTypes.oneOf(Object.values(EXCHANGE_RATE_SERVICE)),
};

export const proFormaInvoiceSpec = PropTypes.shape(proFormaInvoiceSchema);

export const financeEntityScheme = PropTypes.shape({
  user: userSpec,
  company: companySpec,
  bank: bankAccountSpec,
  name: PropTypes.string,
});

export const exchangeRatesSpec = PropTypes.shape(Object.values(CURRENCY).reduce(
  (obj, curr) => PropTypes.shape({
    ...obj,
    [curr]: Object.values(CURRENCY).reduce(
      (subObj, curr2) => ({ ...subObj, [curr2]: PropTypes.number }), {},
    ),
  }),
  {},
));

export const exchangeRatesServiceSpec = PropTypes.oneOf(EXCHANGE_RATE_SERVICE_VALID_VALUES);

export const exchangeRatesResponseSpec = PropTypes.shape({
  rates: exchangeRatesSpec,
  service: exchangeRatesServiceSpec,
  timestamp: PropTypes.string,
});

export const subscriptionSchema = {
  amount: PropTypes.string,
  currency: PropTypes.string,
  details: PropTypes.object,
  is_trial: PropTypes.bool,
  managers_seats: PropTypes.number,
  providers_seats: PropTypes.number,
  total_seats: PropTypes.number,
  plan: PropTypes.number,
  renewal_date: PropTypes.string,
  started_at: PropTypes.string,
  created_at: PropTypes.string,
  id: PropTypes.number,
  organization_id: PropTypes.number,
};

export const subscriptionProduct = {
  title: PropTypes.string,
  id: PropTypes.number,
  productIdLive: PropTypes.string,
  productIdSandbox: PropTypes.string,
  subscriptionPlan: PropTypes.number,
  renewalInterval: PropTypes.string,
};

export const subscriptionSpec = PropTypes.shape(subscriptionSchema);
export const subscriptionProductSpec = PropTypes.shape(subscriptionProduct);

export const serviceOrderLightSpec = PropTypes.shape({
  confirmed: PropTypes.bool,
  id: PropTypes.number,
  processed: PropTypes.bool,
  serviceOrderAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  serviceOrderCurrency: PropTypes.oneOf(CURRENCY_VALUES),
  serviceOrderCurrencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  status: PropTypes.oneOf(Object.values(SERVICE_ORDER_STATUS)),
  type: PropTypes.oneOf(Object.values(SERVICE_ORDER_TYPE)),
  updatedAt: PropTypes.string,
});

export const userUsTaxFormSpec = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  fileId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(US_TAX_FORM_TYPE_VALUES).isRequired,
  updatedAt: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
});
