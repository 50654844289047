/* eslint no-param-reassign: [
 "error",
 { "props": true, "ignorePropertyModificationsFor": ["staticContext"] }
 ] */

import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';


const ErrorRoute = ({ status, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = status;
      }
      return children;
    }}
  />
);
ErrorRoute.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.number.isRequired,
};

export default ErrorRoute;
