import React from 'react';
import { isEmpty } from 'lodash';

import { lineBreakToBr } from 'core/assets/js/lib/utils-jsx';
import { financeEntityScheme } from 'finance/assets/js/lib/objectSpecs';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';

const InvoiceFootnote = ({ owner }) => {
  if (!owner || isEmpty(owner)) {
    return null;
  }
  const ownerFE = new FinancialEntity(owner);
  const footnote = ownerFE.getFootnote();
  return (
    <p className="finance-report__payment__terms--footnote">
      {lineBreakToBr(footnote)}
    </p>
  );
};

InvoiceFootnote.propTypes = {
  owner: financeEntityScheme,
};

InvoiceFootnote.defaultProps = {
  owner: null,
};

export default InvoiceFootnote;
