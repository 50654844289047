/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import ReportHeaderMenu from 'finance/assets/js/components/ReportHeaderMenu.jsx';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { determineReportEditUrl } from 'finance/assets/js/lib/utils';
import { exporterReportExportUrl } from 'exporter/urls';
import { EXPORT_TYPES } from 'exporter/assets/js/constants';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';

// Used to whitelist the reports that can be edited.
const EDITABLE_REPORTS = [
  REPORT_TYPES.WORKSHEET,
  REPORT_TYPES.EXPENSE,
];

const ActionButtons = React.memo(({ actions }) => {
  if (actions.length === 1) {
    return (
      <Button
        data-testid="report-view-header-actions-primary-button"
        onClick={actions[0].onClick}
        variant={actions[0].variant}
      >
        { actions[0].icon && (
          <span className={`mr-3 ${actions[0].icon}`} />
        )}
        {actions[0].label}
      </Button>
    );
  }

  return (
    <Dropdown
      as={ButtonGroup}
      className="td-dropdown"
      data-testid="report-view-header-actions-dropdown"
    >
      { actions[0] && (
        <Button
          data-testid="report-view-header-actions-primary-button"
          onClick={actions[0].onClick}
          variant={actions[0].variant}
        >
          { actions[0].icon && (
            <span className={`mr-3 ${actions[0].icon}`} />
          )}
          {actions[0].label}
        </Button>
      )}

      { actions[0] && (
        <Dropdown.Toggle split variant={actions[0].variant || BS_STYLE.PRIMARY}>
          <span className={ICON.CHEVRON_DOWN} />
        </Dropdown.Toggle>
      )}

      <Dropdown.Menu alignRight>
        {actions.slice(1).map(a => a && (
          <Dropdown.Item
            key={a.eventKey}
            onClick={a.onClick}
          >
            <span className={`text-${a.variant}`}>{a.label}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
});

ActionButtons.propTypes = {
  actions: PropTypes.array.isRequired,
  canEditReport: PropTypes.bool.isRequired,
};

const ReportViewHeaderActions = React.memo(({
  reportType, activeOrg, report, actions, canEditReport,
}) => {
  const [modal, setModal] = useState(null);

  const handleOpenModal = (formKey) => {
    setModal({ form: formKey });
  };

  const handleCloseModal = () => {
    setModal(null);
  };

  const parseAction = (action) => {
    if (!action.Form) {
      return action;
    }

    const formKey = get(action, 'key');
    return {
      eventKey: formKey,
      form: formKey,
      Form: action.Form,
      onClick: () => handleOpenModal(formKey),
      label: action.btnLabel,
      variant: action.btnVariant,
      icon: action.btnIcon,
      className: action.btnClassName,
    };
  };

  const resolveActions = () => {
    const resolvedActions = actions.filter(a => !!a).map(a => parseAction(a));

    if (!report || isEmpty(report)) {
      return resolvedActions;
    }

    resolvedActions.push({
      eventKey: 'download',
      onClick: () => WINDOW_OPEN(exporterReportExportUrl({
        orgAlias: activeOrg.unique_alias,
        id: report.id,
        reportType,
        exportType: EXPORT_TYPES.PDF,
      })),
      label: 'Download',
      variant: BS_STYLE.DEFAULT,
      icon: ICON.DOWNLOAD,
    });

    return resolvedActions;
  };

  const editUrl = determineReportEditUrl(activeOrg.unique_alias, report);
  const btnActions = resolveActions();

  return (
    <>
      <ReportHeaderMenu
        report={report}
        canEditReport={canEditReport}
        editUrl={editUrl}
      >
        <ActionButtons actions={btnActions} canEditReport={canEditReport} />
      </ReportHeaderMenu>

      {btnActions.filter(a => a.Form).map(({ form, eventKey, Form }) => React.cloneElement(Form, {
        isModalOpen: form === get(modal, 'form'),
        onClose: handleCloseModal,
        key: `modal_${eventKey}`,
      }))}

    </>
  );
});

ReportViewHeaderActions.propTypes = {
  report: PropTypes.object.isRequired,
  reportType: PropTypes.string.isRequired,
  activeOrg: orgSpec.isRequired,
  canEditReport: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    Form: PropTypes.node.isRequired,
    btnLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    btnVariant: PropTypes.string,
    btnIcon: PropTypes.string,
    btnClassName: PropTypes.string,
  })),
};

ReportViewHeaderActions.defaultProps = {
  actions: [],
};

const mapStateToProps = (state, props) => {
  const { report, reportType } = props;
  const isEditableType = EDITABLE_REPORTS.includes(reportType);
  const canEditReport = (
    report && !!report.allowedActions
    && !!report.allowedActions.canBeEdited
    && isEditableType
  );

  return {
    activeOrg: selectActiveOrg(state),
    canEditReport,
  };
};

const ReportViewHeaderActionsConnect = connect(
  mapStateToProps,
)(ReportViewHeaderActions);

export default withRouter(ReportViewHeaderActionsConnect);
