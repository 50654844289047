import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import SettingsItem from 'core/assets/js/components/SettingsItem.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { updateIntegrationSettings } from 'integrations/assets/js/data-services/view';
import { handleIncompleteMappingError } from 'integrations/assets/js/helpers';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import IntegrationConfirmDeleteModal, { MODAL_ID } from 'integrations/assets/js/components/IntegrationConfirmDeleteModal.jsx';
import IntegrationGeneralSettings from 'integrations/assets/js/components/settings/IntegrationGeneralSettings.jsx';
import { pick } from 'lodash';

const IntegrationSettings = ({
  dispatch,
  history,
  integration,
  match: { params: { orgAlias } },
  parentComponentName,
}) => {
  const { id: integrationId } = integration;

  const handleSettingUpdated = async (values) => {
    const success = await handleIncompleteMappingError(
      () => dispatch(updateIntegrationSettings({
        componentName: parentComponentName, integrationId, orgAlias, values,
      })),
      orgAlias,
      integrationId,
      history,
    );

    if (success) {
      toastr.success('Well Done!', 'The integration’s settings were updated successfully');
    }
  };

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <h3 className="heading-block">General Settings</h3>

      <Form
        initialValues={{
          ...pick(integration, 'syncInvoiceStatus', 'syncType'), startDate: integration.syncStartDate,
        }}
        onSubmit={handleSettingUpdated}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <IntegrationGeneralSettings />
            <div className="text-right mt-4">
              <TDButton
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={submitting}
                label="Save"
              />
            </div>
          </form>
        )}
      />

      <h3 className="heading-block mt-4">Delete integration</h3>
      <SettingsItem
        containerClassName="mb-4"
        cta={(
          <TDButton
            label="Permanently delete"
            onClick={() => dispatch(modalOpenAC(MODAL_ID))}
            variant={BS_STYLE.DANGER}
          />
        )}
        disclaimer={[
          'This will delete all data associated with this integration. This cannot be reversed.',
        ].join('')}
        label="Are you sure you want to delete this integration?"
      />
      <IntegrationConfirmDeleteModal integration={integration} />
    </div>
  );
};

IntegrationSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  integration: integrationSpec.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationSettingsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationSettings);

export default withRouter(IntegrationSettingsConnected);
