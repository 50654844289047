import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { settingsBankAccountApiUrl } from 'settings/urls';
import {
  BANK_ACCOUNT_DECLINED_STATUSES,
  BANK_ACCOUNT_DECLINED_STATUSES_LABELS,
  PAYONEER_BANK_ACCOUNT_STATUS_INFO,
} from 'settings/assets/js/constants';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import PayoneerStatusSkeleton from 'settings/assets/js/components/skeletons/PayoneerStatusSkeleton.jsx';


class PayoneerStatusComponent extends React.Component {
  static GetComponentName() {
    return 'PayoneerStatusComponent';
  }

  render() {
    const { bankAccount } = this.props;
    const isRejected = BANK_ACCOUNT_DECLINED_STATUSES.includes(bankAccount?.status);
    const hasReason = true; // TODO set it right when we decide where to store the rejection reason;
    const reason = BANK_ACCOUNT_DECLINED_STATUSES_LABELS[bankAccount?.status] || '';
    return (
      <div className="rounded bg-white p-5 shadow-sm">
        <div className="row">
          <div
            className="py-5 col-12 col-md-8 offset-md-2 d-flex justify-content-center align-items-center flex-column"
          >
            <TDApiConnected
              duck="view"
              name={PayoneerStatusComponent.GetComponentName()}
              skeletonComponent={PayoneerStatusSkeleton}
              fetchData={({ dispatch, params, url, authedAxios, componentName }) => {
                return (
                  dispatch(fetchViewDS({
                    url: settingsBankAccountApiUrl(params.bankAccountId, url),
                    params,
                    authedAxios,
                    componentName,
                  }))
                );
              }}
            >
              <React.Fragment>
                <i className={`status--${PAYONEER_BANK_ACCOUNT_STATUS_INFO[bankAccount.status]?.statusClass} mb-5 ${PAYONEER_BANK_ACCOUNT_STATUS_INFO[bankAccount.status]?.icon}`} />
                <h1 className="text-center mb-4">
                  {PAYONEER_BANK_ACCOUNT_STATUS_INFO[bankAccount.status]?.title(bankAccount.alias)}
                </h1>
                <div className="text-center">
                  {PAYONEER_BANK_ACCOUNT_STATUS_INFO[bankAccount.status]
                    ?.subtitle(bankAccount.alias)}
                </div>
                <div className="text-center">
                  {PAYONEER_BANK_ACCOUNT_STATUS_INFO[bankAccount.status]
                    ?.extraInfo(bankAccount.alias)}
                </div>
                { hasReason && isRejected && (
                  <React.Fragment>
                    <div className="mb-3">
                      Here is the reason for rejection:
                    </div>
                    <div className="p-4 bg-gray text-center">
                      {reason}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </TDApiConnected>
          </div>
        </div>
      </div>
    );
  }
}

PayoneerStatusComponent.propTypes = {
  bankAccount: bankAccountSpec.isRequired,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, PayoneerStatusComponent.GetComponentName());
  return {
    bankAccount: viewState.item,
  };
};

const PayoneerStatusComponentConnect = connect(
  mapStateToProps,
)(PayoneerStatusComponent);


export default withRouter(PayoneerStatusComponentConnect);
