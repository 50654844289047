import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';

import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import { ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import InvitePeopleAorSelector from 'people/assets/js/components/InvitePeopleAorSelector.jsx';
import {
  PEOPLE_TYPE, USER_EMPLOYMENT_TYPE, USER_EMPLOYMENT_TYPE_LABEL,
} from 'people/assets/js/constants';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import { userGroupOptionsApiUrl } from 'people/urls';
import { getRoleOptionsApiUrl } from 'roles/urls';

const InvitePeopleUserType = ({ formValues, peopleType }) => {
  const componentName = InvitePeopleUserType.GetComponentName();
  const activeOrg = useSelector(selectActiveOrg);

  const { hasLoaded: roleOptionsHasLoaded, item: roleOptions } = fetchDataHook({
    componentName: `${componentName}-role-options`,
    url: getRoleOptionsApiUrl(activeOrg.alias),
  });
  const { hasLoaded: userGroupOptionsHasLoaded, item: userGroupOptions } = fetchDataHook({
    componentName: `${componentName}-user-group-options`,
    url: userGroupOptionsApiUrl(activeOrg.alias),
  });

  if (!roleOptionsHasLoaded && !userGroupOptionsHasLoaded) {
    return null;
  }

  const employmentType =  parseInt(formValues.employment_type, 10);
  const isEmployeeUserType = employmentType === USER_EMPLOYMENT_TYPE.EMPLOYEE;
  const permanentEmployeeText = (
    <div>
      <p className="discreet">
        A Manager or Provider invited as an Employee receives regular
        reminders by TalentDesk.io to create Worksheets for the projects
        they work on depending on your Organization settings.
        <br />
        However, TalentDesk.io does
        <strong> not </strong>
        generate any invoices
        on your employees behalf since they belong to your company&apos;s payroll.
      </p>
    </div>
  );

  const contractorText = (
    <div>
      <p className="discreet">
        A Manager or Provider invited as a Contractor receives regular reminders
        by TalentDesk.io to create Worksheets for the projects they work on.
        Based on the approved Worksheets TalentDesk.io generates invoices
        on their behalf either bi-weekly or monthly.
      </p>
    </div>
  );

  const displayRoleSelector = (
    peopleType === PEOPLE_TYPE.MANAGERS && Array.isArray(roleOptions) && roleOptions.length > 0
  );

  return (
    <div>
      <h2>Select User Type</h2>
      <CustomSelectField
        data-testid="invite-people-user-type-drop-down"
        name="employment_type"
        defaultOptionText={null}
        options={[
          {
            isFixed: true,
            label: (
              <span>
                <i role="none" className={ICON.USER} />
                {' '}
                {startCase(USER_EMPLOYMENT_TYPE_LABEL[USER_EMPLOYMENT_TYPE.EMPLOYEE])}
              </span>
            ),
            value: USER_EMPLOYMENT_TYPE.EMPLOYEE,
          },
          {
            isFixed: true,
            label: (
              <span>
                <i role="none" className={ICON.LAPTOP_HOUSE} />
                {' '}
                {startCase(USER_EMPLOYMENT_TYPE_LABEL[USER_EMPLOYMENT_TYPE.CONTRACTOR])}
              </span>
            ),
            value: USER_EMPLOYMENT_TYPE.CONTRACTOR,
          },
        ]}
        required
      />
      {isEmployeeUserType ? permanentEmployeeText : contractorText }
      <InvitePeopleAorSelector employmentType={employmentType} />
      {displayRoleSelector && (
        <CustomSelectField
          className="mt-5"
          defaultOptionText="Select roles"
          isMulti
          label="User Role"
          name="roleIds"
          OptionComponent={({ data, ...props }) => (
            <components.Option {...props}>
              <div className="f-flex p-2 w-100 align-items-center invite-people-role-option">
                <i className={`${ICON.BAR_PROGRESS_DUOTONE} mr-3 fa-xl`} />
                <span>{data.label}</span>
              </div>
            </components.Option>
          )}
          options={roleOptions}
          sublabel="Assign a role to managers so they can perform specific actions"
        />
      )}
      {Array.isArray(userGroupOptions) && userGroupOptions.length > 0 && (
        <CustomSelectField
          className="mt-5"
          defaultOptionText="Select groups"
          isMulti
          label="Groups"
          name="userGroupIds"
          options={userGroupOptions}
          sublabel={(
            `Add ${peopleType} to a group to improve your grouping of different ${peopleType}`
          )}
        />
      )}
    </div>
  );
};

InvitePeopleUserType.GetComponentName = () => 'InvitePeopleUserType';

InvitePeopleUserType.propTypes = {
  formValues: PropTypes.object,
  peopleType: peopleTypeSpec.isRequired,
};

InvitePeopleUserType.defaultProps = {
  formValues: {},
};

export default InvitePeopleUserType;
