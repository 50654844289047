import React from 'react';
import PropTypes from 'prop-types';
import { maxBy, get, omit } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FORM_ERROR } from 'final-form';

import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { contactsUrl, contactApiUrl } from 'contacts/urls';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ContactForm from 'contacts/assets/js/components/ContactForm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContactFormSkeleton from 'contacts/assets/js/components/skeletons/ContactFormSkeleton.jsx';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import { fetchCustomFieldTemplatesDS, getCustomFieldTemplates } from 'interviews/assets/js/ducks/customFields';
import { extractCustomFieldSetup } from 'interviews/assets/js/lib/utils';
import { editContactDS } from 'contacts/assets/js/data-services/form';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, USER_TYPE_LABEL } from 'core/assets/js/constants';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';

class ContactEditView extends React.Component {
  static FetchData({ dispatch, authedAxios, componentName, querystring, params, url }) {
    return Promise.all([
      dispatch(fetchViewDS({
        authedAxios, componentName, querystring,
        url: contactApiUrl({ orgAlias: params.orgAlias, contactId: params.contactId }),
      })),
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias: params.orgAlias, entityType: FIELD_ENTITY_TYPE.USER, authedAxios, url,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ContactEditView';
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    const { dispatch, history, match: { params: { orgAlias, contactId } } } = this.props;

    try {
      await dispatch(
        editContactDS({
          values,
          componentName: ContactEditView.GetComponentName(),
          orgAlias,
          contactId,
        }),
      );

      toastr.success('Well Done!', 'Your contact was updated successfully.');
      history.push(contactsUrl(orgAlias));
      return true;
    } catch (err) {
      const errorData = err.errors;

      if (errorData._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        [FORM_ERROR]: errorData._error || 'Oops! Something went wrong. Please try again',
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  }

  render() {
    const {
      item,
      customFieldTemplates,
      match: { params: { orgAlias } },
      item: { role: contactRole, invitations, answers = [] },
    } = this.props;

    const lastInvitation = maxBy(invitations, 'id');
    const invitationRole = get(lastInvitation, 'userRole');
    const differentInvitationRole = contactRole && invitationRole && invitationRole !== contactRole;

    const {
      initialValues: customFieldInitialValues,
      selectedCustomFields,
    } = extractCustomFieldSetup({ answers, templates: customFieldTemplates });

    const breadcrumbs = [
      {
        title: 'Contacts',
        url: contactsUrl(orgAlias),
      },
      {
        title: 'Edit contact',
        url: null,
      },
    ];

    const contactInitialValues = {
      address: item.address,
      attachments_metadata: item.attachments_metadata,
      avatar_metadata: item.avatar_metadata,
      email: item.email,
      first_name: item.firstName,
      id: item.id,
      job_title: item.jobTitle,
      last_name: item.lastName,
      notes: item.notes,
      phone: item.phone,
      role: item.role,
      resume: item.resume,
      skills: item.skills,
      ...customFieldInitialValues,
    };

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
        />
        <div className="page page--contacts">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={ContactFormSkeleton}
            >
              { differentInvitationRole && (
                <TDSystemMessage
                  type={BS_STYLE.INFO}
                  className="mb-4"
                  title="Different invitation type"
                >
                  {`This contact was invited as a ${USER_TYPE_LABEL[invitationRole]} but has a ${USER_TYPE_LABEL[contactRole]} contact role`}
                </TDSystemMessage>
              )}
              <ContactForm
                contactId={item.id}
                onSubmit={this.onSubmit}
                initialValues={contactInitialValues}
                customFields={selectedCustomFields}
                customFieldTemplates={customFieldTemplates}
                enableFieldTemplateSync
                allowTemplateSelection
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ContactEditView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  item: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
};

ContactEditView.defaultProps = {
  item: {},
  customFieldTemplates: [],
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, ContactEditView.GetComponentName());
  return {
    item: viewState.item,
    customFieldTemplates: getCustomFieldTemplates(state),
  };
};

const ContactEditViewConnected = connect(mapStateToProps)(ContactEditView);

export default withRouter(ContactEditViewConnected);
