import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

/*
 * Wraps a View component and manages the filters toggling.
 */
const withField = WrappedComponent => props => (
  <Field
    parse={v => v} // Overrides default behavior of converting '' to undefined.
    {...props}
    component={WrappedComponent}
  />
);


withField.propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
};

export default withField;
