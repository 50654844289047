import PropTypes from 'prop-types';
import React from 'react';

import { ICON } from 'core/assets/js/constants';

const MappingFieldName = ({ value }) => (
  <div className="input-group integration-fieldname-disabled">
    <div className="input-group-prepend">
      <span className="input-group-text">
        <i className={ICON.TEXT} />
      </span>
    </div>
    <input
      className="form-control"
      value={value}
      disabled
    />
  </div>
);

MappingFieldName.propTypes = {
  value: PropTypes.string.isRequired,
};

export default MappingFieldName;
