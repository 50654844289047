import * as type from 'projects/assets/js/actions/types';


/**
 * Handles the project view related state
 *
 * @param state
 * @param action
 * @returns {*}
 */
export const projectActiveInitialState = {
  httpErrorCode: null,
  expenses: [],
  managers: [],
  providers: [],
  purchaseOrders: [],
  reviews: [],
  worksheets: [],
  outstandingCounts: {},
};
export const projectActive = (state = projectActiveInitialState, action) => {
  switch (action.type) {
    case type.PROJECT_MANAGERS_FETCH:
      return {
        ...state,
        managers: action.managers,
      };
    case type.PROJECT_PROVIDERS_FETCH:
      return {
        ...state,
        providers: action.providers,
      };
    case type.PROJECT_PROVIDER_REPLACE:
      return {
        ...state,
        providers: state.providers.map(provider => (
          provider.userId === action.projectMember.userId ? action.projectMember : provider
        )),
      };
    case type.PROJECT_MANAGER_REPLACE:
      return {
        ...state,
        managers: state.managers.map(manager => (
          manager.userId === action.projectMember.userId ? action.projectMember : manager
        )),
      };
    case type.PROJECT_REVIEWS_FETCH:
      return {
        ...state,
        reviews: action.reviews,
      };
    case type.PROJECT_REVIEWS_SUBMIT:
      return {
        ...state,
        reviews: [
          ...state.reviews,
          action.review,
        ],
      };
    case type.PROJECT_OUTSTANDING_COUNTS_FETCH:
      return {
        ...state,
        outstandingCounts: action.outstandingCounts,
      };
    case type.PROJECT_ACTIVE_RESET:
      return {
        ...projectActiveInitialState,
      };
    default:
      return state;
  }
};

/**
 * Expected state structure
 * {
 *   projects: {
 *     activeProject: {
 *        isLoading: false,
 *        item: {}
 *     },
 * }
 * @param state
 * @param action
 * @returns {*}
 */
export const projectsInitialState = {
  projectActive: projectActiveInitialState,
};
export const projects = (state = projectsInitialState, action) => {
  switch (action.type) {
    case type.PROJECT_MANAGERS_FETCH:
    case type.PROJECT_PROVIDERS_FETCH:
    case type.PROJECT_REVIEWS_FETCH:
    case type.PROJECT_REVIEWS_SUBMIT:
    case type.PROJECT_ACTIVE_RESET:
    case type.PROJECT_PROVIDER_REPLACE:
    case type.PROJECT_MANAGER_REPLACE:
    case type.PROJECT_OUTSTANDING_COUNTS_FETCH:
      return {
        ...state,
        projectActive: projectActive(state.projectActive, action),
      };
    default:
      return state;
  }
};

export default projects;
