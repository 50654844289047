/* eslint react/no-danger:off */
import React from 'react';
import PropTypes from 'prop-types';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import { calculatePasswordStrength } from 'core/assets/js/lib/utils';

class PasswordField extends React.Component {
  onChange(event, { newValue }) {
    const { input } = this.props;
    input.onChange(newValue || null);
  }

  render() {
    const {
      input, label, className, showStrengthIndicator,
      meta: { touched, error }, ...rest } = this.props;
    const hasError = touched && error;
    const groupClassName = ['form-group'];

    if (hasError) {
      groupClassName.push('has-error');
    }

    if (showStrengthIndicator) {
      groupClassName.push('password-strength-meter', 'has-feedback');
    }

    const { msg, feedback } = calculatePasswordStrength(input.value);
    const showStrengthMessage = showStrengthIndicator
      && msg && feedback.showTextMessage && input.value;
    return (
      <div className={groupClassName.join(' ')}>
        {label && <label htmlFor={input.name}>{label}</label>}
        <input
          id={`fieldId-${input.name}`}
          {...input}
          type="password"
          className={['form-control', className].join(' ')}
          {...rest}
          onChange={input.onChange}
        />
        { showStrengthIndicator && input.value !== '' && (
          <span className="form-control-feedback">
            <span className={`text-${feedback.className}`}>
              <i className={feedback.icon} />
              {' '}
              {feedback.label}
            </span>
          </span>
        )}
        {/* Show error only if strength indicator is disabled or msg is missing. */}
        {hasError && !showStrengthMessage && (
          <span className="help-block mt-3">{error}</span>
        )}
        {showStrengthMessage && (
          <div className={`password-strength-meter__msg text-${feedback.className}`}>
            <span dangerouslySetInnerHTML={{ __html: msg }} />
          </div>
        )}
      </div>
    );
  }
}

PasswordField.propTypes = {
  input: reduxInputSpec.isRequired,
  label: PropTypes.string,
  showStrengthIndicator: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};
PasswordField.defaultProps = {
  className: '',
  label: '',
  showStrengthIndicator: false,
  meta: {
    touched: false,
    error: false,
  },
};

export default PasswordField;
