import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { rolesListUrl } from 'roles/urls';
import PermissionsFinalFormField from 'roles/assets/js/components/PermissionsFinalFormField.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';

export const RENAME_MODAL_ID = 'rename-modal';

const RoleForm = ({
  initialValues,
  isCreate,
  isSystemRole,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgAlias } = useParams();
  const renameModalOpen = useSelector(state => getIsModalOpen(state, RENAME_MODAL_ID));

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { submitting }, submitError }) => {
        return (
          <>
            {isSystemRole && (
              <TDSystemMessage
                className="mb-4"
                title="Can’t edit system roles!"
                stype={BS_STYLE.WARNING}
              >
                <p>
                  System roles are non-editable. If you would like to configure one of the
                  system roles, you can duplicate the system role to your own version and
                  then manage the permissions and members. Remember to remove any managers
                  from the system role member list if you add them to a duplicate roles.
                </p>
              </TDSystemMessage>
            )}
            <form
              className="roles-form__container"
              onSubmit={handleSubmit}
            >
              <div className="roles-form__wrapper d-flex flex-wrap">
                { isCreate ? (
                  <div className="rounded shadow-sm w-100 bg-white p-4">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="mt-2 mb-0">Role name</h2>

                        <hr className="mx-n4 my-5" />

                        <TextInputField
                          className="mb-0"
                          disabled={submitting}
                          label=""
                          name="name"
                          placeholder="Role name"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <Field
                  component={PermissionsFinalFormField}
                  isCreate={isCreate}
                  isSystemRole={isSystemRole}
                  onPermissionUpdate={() => handleSubmit()}
                  name="permissions"
                />
              </div>

              {!isSystemRole && isCreate && (
                <div className="roles-form__action-buttons">
                  {submitError && <p className="text-danger">{submitError}</p>}

                  <div className="roles-form__action-buttons-wrapper d-flex justify-content-between">
                    <TDButton
                      disabled={submitting}
                      label="Cancel"
                      onClick={() => history.push(rolesListUrl(orgAlias))}
                      type="button"
                      variant={BS_STYLE.DEFAULT}
                    />

                    <TDButton
                      disabled={submitting}
                      label="Create"
                      type="submit"
                      variant={BS_STYLE.PRIMARY}
                    />
                  </div>
                </div>
              )}

              <ModalSimple
                body={(
                  <>
                    <div>Title</div>
                    <TextInputField
                      className="mb-0"
                      disabled={submitting}
                      label=""
                      name="name"
                      placeholder="Role name"
                    />
                  </>
                )}
                footer={(
                  <>
                    <TDButton
                      disabled={submitting}
                      label="Cancel"
                      onClick={() => dispatch(modalCloseAC(RENAME_MODAL_ID))}
                      type="button"
                      variant={BS_STYLE.DEFAULT}
                    />
                    <TDButton
                      disabled={submitting}
                      label="Save"
                      type="submit"
                      variant={BS_STYLE.PRIMARY}
                      onClick={() => {
                        handleSubmit();
                        dispatch(modalCloseAC(RENAME_MODAL_ID));
                      }}
                    />
                  </>
                )}
                heading="Edit title"
                onClose={() => dispatch(modalCloseAC(RENAME_MODAL_ID))}
                open={renameModalOpen}
              />
            </form>
          </>
        );
      }}
    />
  );
};


RoleForm.propTypes = {
  initialValues: PropTypes.object,
  isCreate: PropTypes.bool,
  isSystemRole: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
RoleForm.defaultProps = {
  initialValues: {},
  isCreate: false,
  isSystemRole: false,
};

export default RoleForm;

