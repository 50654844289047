import React from 'react';
import PropTypes from 'prop-types';

/**
 * Used in settings as a row which contains a label, preview of certain value and
 * a cta usually a modal form that is used to change this value
 * @param cta The cta used to show the ability to change the previewed value
 * @param disclaimer Usually a text to give some more info for the value shown
 * @param label An optional label for the previewed value
 * @param preview The value that is able to change using the cta functionality
 * @param containerClassName Classes that will be passed to the component's container
 * @returns {*}
 * @constructor
 */
function SettingsItem({ cta, disclaimer, label, preview, containerClassName }) {
  const classes = ['d-flex flex-container--standalone-small align-items-center flex-column flex-sm-row settings-row__preview px-3 pb-3'];
  if (containerClassName) {
    classes.push(containerClassName);
  }
  return (
    <div
      className={classes.join(' ')}
    >
      <div className="settings-row__content mb-3 mb-sm-0">
        {label && (
          <div className={`${disclaimer ? 'mr-4' : ''} text-break`}>
            {label}
          </div>
        )}
        {!label && preview && (
          <span className="mr-4 text-break">
            {preview}
          </span>
        )}
        {disclaimer && (
          <div className="discreet">{disclaimer}</div>
        )}
      </div>

      <div className="settings-row__cta pl-0 pl-sm-4">
        {preview && label && (
          <span className="mr-4 text-break">
            {preview}
          </span>
        )}
        {cta}
      </div>
    </div>
  );
}

SettingsItem.propTypes = {
  containerClassName: PropTypes.string,
  cta: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disclaimer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SettingsItem.defaultProps = {
  containerClassName: '',
  disclaimer: null,
  label: null,
  preview: null,
};

export default SettingsItem;
