// urls

import {
  contactCreateUrl,
  contactEditUrl,
  contactsImportUrl,
  contactsUrl,
} from 'contacts/urls';

// views

import ContactsListView from 'contacts/assets/js/ContactsListView.jsx';
import ContactCreateView from 'contacts/assets/js/ContactCreateView.jsx';
import ContactEditView from 'contacts/assets/js/ContactEditView.jsx';
import ContactsImportView from 'contacts/assets/js/ContactsImportView.jsx';
import { LAYOUTS } from 'core/assets/js/constants';
import { CONTACTS_IMPORT_STEP } from 'contacts/assets/js/constants';

const contactsRouteConfig = [
  {
    path: contactsUrl(':orgAlias'),
    component: ContactsListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: contactCreateUrl(':orgAlias'),
    component: ContactCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: contactsImportUrl(':orgAlias', `:step(${Object.values(CONTACTS_IMPORT_STEP).join('|')})`),
    component: ContactsImportView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: contactEditUrl(':orgAlias', ':contactId'),
    component: ContactEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
];

export default contactsRouteConfig;
