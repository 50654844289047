import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import { DATE_DURATION, DATE_DURATION_LABEL, ICON } from 'core/assets/js/constants';
import TDDropdown from 'core/assets/js/components/TDDropdown.jsx';

const DateDurationDropdown = (props) => {
  const {
    'data-testid': dataTestId,
    formField,
    onDateDurationChanged,
    selectedDateDuration,
  } = props;
  const classes = ['ml-auto date-duration-dropdown'];
  if (formField) {
    classes.push('date-duration-dropdown__form-field');
  }
  return (
    <TDDropdown
      className={classes.join(' ')}
      data-testid={dataTestId}
      id="dropdown-no-caret"
      noCaret
      alignRight
      toggle={(
        <span className="pl-4">
          <span className="dropdown-label">
            {DATE_DURATION_LABEL[selectedDateDuration]}
          </span>
          {' '}
          <span className={ICON.CHEVRON_DOWN} />
        </span>
      )}
    >
      {
        Object.values(DATE_DURATION)
          .map(date => (
            <Dropdown.Item
              data-testid="date-duration-dropdown-options"
              key={date}
              onClick={() => onDateDurationChanged(date)}
            >
              {DATE_DURATION_LABEL[date]}
            </Dropdown.Item>
          ))
      }
    </TDDropdown>
  );
};

DateDurationDropdown.propTypes = {
  selectedDateDuration: PropTypes.number,
  'data-testid': PropTypes.string,
  formField: PropTypes.bool,
  onDateDurationChanged: PropTypes.func.isRequired,
};

DateDurationDropdown.defaultProps = {
  selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
  'data-testid': '',
  formField: false,
};

export default DateDurationDropdown;
