/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { isSSR } from 'core/assets/js/config/checks';
import { FILE_UPLOAD_MAXIMUM_SIZE_BYTES } from 'core/assets/js/constants';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import Fileboxes from 'core/assets/js/components/FileUploader/Fileboxes.jsx';
import Logger from 'core/assets/js/lib/Logger';
import { FILESTACK, HAS_WINDOW } from 'core/assets/js/config/settings';

const logger = new Logger('fileuploader');

const filestackClient = isSSR || !HAS_WINDOW ? null : require('filestack-js').default;
const ReactFilestack = isSSR || !HAS_WINDOW ? null : require('filestack-react').default;

const DEFAULT_FILE_STACK_OPTIONS = {
  maxSize: FILE_UPLOAD_MAXIMUM_SIZE_BYTES,
  storeTo: {},
  fromSources: ['local_file_system', 'url', 'imagesearch', 'facebook', 'instagram', 'googledrive', 'dropbox', 'onedrive'],
};

const FileUploader = (props) => {
  const {
    maxFiles, reduxInput, invertedColors, uploadRenderer, preview, softDelete, onBusyChange,
    resetAfterUpload, wrapperClassName, acceptAll, acceptFiles, path, imgWidth, imgHeight,
    container,
  } = props;

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [client, setClient] = useState(undefined);

  /**
   * Used to notify listeners whether the uploader is busy (uploading)
   * in order to prevent parent form submitting
   */
  const _notifyChange = () => {
    if (onBusyChange) {
      onBusyChange(submitting);
    }
  };

  // Called by filestack-react
  const _uploadFileFinished = () => {
    setSubmitting(false);
    _notifyChange();
  };


  // Called by filestack-react
  const _uploadFileStarted = () => {
    setSubmitting(true);
    _notifyChange();
  };

  const [filestackOptions, setFilestackOptions] = useState({
    maxFiles,
    onFileUploadStarted: _uploadFileStarted,
    onFileUploadFinished: _uploadFileFinished,
    ...DEFAULT_FILE_STACK_OPTIONS,
  });

  const [hasLoadedProps, setHasLoadedProps] = useState(false);

  /**
   * Render the always visible uploading interface
   * Used by SSR for initial markup instead of using the actual filestack-react
   *
   * @param  {Function} options.onPick Callback when a file is picked for upload by the user
   */
  const renderFileUploader = ({ onPick }) => {
    const classNames = ['fileuploader'];
    if (invertedColors) {
      classNames.push('fileuploader--inverted-colors');
    }
    return (
      <div key="uploader" className={classNames.join(' ')} onClick={onPick}>
        <span key="txt" className="fileuploader__text">
          <span key="att" className="fileuploader__attnt">
            Click
          </span>
          {' '}
          &amp; browse to add file
        </span>
      </div>
    );
  };

  const getDefaultRenderer = (args = {}) => ({ onPick }) => (
    renderFileUploader({ onPick, ...args })
  );

  const _notifyFilesChanged = (newFileList) => {
    if (reduxInput) {
      // If this FileUploader is being used as a Redux Form Field
      // then push the newly updated uploadedFiles
      reduxInput.onChange(JSON.stringify(newFileList));
    }
    if (resetAfterUpload) {
      setSubmitting(false);
      setUploadedFiles([]);
    }
  };

  /**
   * Callback when click X of an already uploaded file
   *
   * @param {Object} file Entry of the uploadedFiles array
   * @param {Object} [options]
   * @param {Boolean} [updateState] If the uploadedFiles state should be updated
   */
  const _removeFile = (file, { updateState = true } = {}) => {
    if (!uploadedFiles || isEmpty(uploadedFiles)) {
      return;
    }

    // Delete the file from FileStack
    if (!softDelete && client) {
      client.remove(file.handle).catch((err) => {
        logger.error(`[FileStack Remove Error] file handle: ${file.handle}, error: ${err.message}`);
      });
    }

    if (updateState) {
      const newFileList = [
        ...uploadedFiles.filter(entry => (
          file.uploadId ? entry.uploadId !== file.uploadId : entry.handle !== file.handle
        )),
      ];
      setUploadedFiles(newFileList);
      _notifyFilesChanged(newFileList);
    }
  };
  useEffect(() => {
    setFilestackOptions({
      ...filestackOptions,
      ...(!acceptAll ? { accept: isEmpty(acceptFiles) ? 'image/*' : acceptFiles } : {}),
      ...(imgWidth || imgHeight ? { imageMax: [imgWidth, imgHeight] } : {}),
      storeTo: {
        ...filestackOptions.storeTo,
        ...(container ? { container } : {}),
        ...(path ? { path: `${path}${path.endsWith('/') ? '' : '/'}` } : {}),
      },
    });
    setHasLoadedProps(true);

    try {
      if (reduxInput.value) {
        const filesIsStringified = typeof reduxInput.value === 'string';
        if (filesIsStringified) {
          setUploadedFiles(JSON.parse(reduxInput.value));
        } else {
          setUploadedFiles(reduxInput.value);
        }
      }
    } catch (_) {
      setUploadedFiles([]);
    }


    setSubmitting(false);

    if (filestackClient) {
      setClient(filestackClient.init(FILESTACK.apiKey, {
        policy: FILESTACK.policy,
        signature: FILESTACK.signature,
      }));
    }
  }, []);


  // Called by filestack-react
  const _uploadFileError = (err) => {
    logger.error('Error uploading file:', err);
    setSubmitting(false);
    _notifyChange();
  };

  // Called by filestack-react
  const _uploadFileSuccess = (file) => {
    setSubmitting(false);
    const newFileList = [...uploadedFiles, ...file.filesUploaded];
    setUploadedFiles(newFileList);
    _notifyFilesChanged(newFileList);
  };
  /**
   * Renders the already uploaded files
   * plus the uploader interface if more can be uplaoded
   * @return {DOM}    for rendering
  */
  const _renderFileUploaderInner = () => {
    const customRenderer = uploadRenderer || getDefaultRenderer({ invertedColors });
    if (!hasLoadedProps) {
      return null;
    }
    let FileUploadTrigger;
    if (!uploadedFiles || uploadedFiles.length < maxFiles) {
      FileUploadTrigger = (
        <ReactFilestack
          apikey={FILESTACK.apiKey}
          actionOptions={filestackOptions}
          onSuccess={_uploadFileSuccess}
          onError={_uploadFileError}
          customRender={customRenderer}
        />
      );
    }

    return (
      <div className="fileuploader__wrapper">
        {preview && (
          <Fileboxes
            files={uploadedFiles}
            inputName={reduxInput.name}
            clickHandler={_removeFile}
            reduxInput={reduxInput}
            invertedColors={invertedColors}
          />
        )}
        { FileUploadTrigger }
      </div>
    );
  };

  const { name } = reduxInput;
  const key = `fileuploader-inst-${name}`;
  return (
    <div className={wrapperClassName} key={key}>
      { !isSSR && _renderFileUploaderInner() }
    </div>
  );
};

FileUploader.propTypes = {
  acceptAll: PropTypes.bool,
  acceptFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  uploadRenderer: PropTypes.func,
  container: PropTypes.string,
  preview: PropTypes.bool,
  resetAfterUpload: PropTypes.bool,
  invertedColors: PropTypes.bool,
  maxFiles: PropTypes.number,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  onBusyChange: PropTypes.func,
  wrapperClassName: PropTypes.string,
  path: PropTypes.string,
  reduxInput: reduxInputSpec.isRequired,
  softDelete: PropTypes.bool,
};

FileUploader.defaultProps = {
  acceptAll: false,
  uploadRenderer: null,
  imgWidth: null,
  imgHeight: null,
  acceptFiles: 'image/*',
  container: null,
  preview: true,
  resetAfterUpload: false,
  invertedColors: false,
  maxFiles: 1,
  onBusyChange: () => {},
  softDelete: false,
  wrapperClassName: null,
  path: null,
};

export default FileUploader;
