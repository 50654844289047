import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import pluralize from 'pluralize';

import OrgSkillDirectoryModalForm, { ORG_SKILL_DIRECTORY_MODAL_ID } from 'settings/assets/js/components/OrgSkillDirectoryModalForm.jsx';
import { orgSettingsSkillDirectoriesUrl, skillDirectoriesManageApiUrl } from 'settings/urls';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import SkillDirectoryCard from 'skills/assets/js/components/SkillDirectoryCard.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { viewUpdateAC, fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import SkillDirectorySkillsList from 'skills/assets/js/components/SkillDirectorySkillsList.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import SkillDirectoryCardSkeleton from 'skills/assets/js/components/SkillDirectoryCardSkeleton.jsx';
import { skillDirectoryAddSkillsDS, skillDirectoryRemoveSkillDS, updateSkillDirectoryDS } from 'settings/assets/js/data-services/settings';
import { getListState, listRemoveItemAC } from 'core/assets/js/ducks/list';
import SkillDirectoryAddSkillsPanel, { MODAL_ID as ADD_SKILLS_TO_DIRECTORY_MODAL_ID } from 'skills/assets/js/components/SkillDirectoryAddSkillsPanel.jsx';
import { getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { getRequestState, requestRefreshAC } from 'core/assets/js/ducks/requests';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';

const REMOVE_SKILL_FROM_DIRECTORY_MODAL_ID = 'skill-directory-remove-skill-modal';
export const SKILL_DIRECTORY_STORE_KEY = 'SkillDirectorySkillsList';

class OrgSkillDirectoryManageView extends React.Component {
  static FetchData({ dispatch, authedAxios, componentName, querystring, params }) {
    const { skillDirectoryId, orgAlias } = params;
    return dispatch(fetchViewDS({
      authedAxios, componentName, querystring,
      url: skillDirectoriesManageApiUrl(orgAlias, skillDirectoryId),
    }));
  }

  static GetComponentName() {
    return 'OrgSkillDirectoryManageView';
  }

  constructor(props) {
    super(props);
    this.handleAddSkillsToDirectory = this.handleAddSkillsToDirectory.bind(this);
    this.handleCloseSkillsToDirectoryModal = this.handleCloseSkillsToDirectoryModal.bind(this);
    this.handleCloseRemoveDirectorySkillModal = this.handleCloseRemoveDirectorySkillModal.bind(
      this,
    );
    this.handleOpenAddSkillsToDirectoryModal = this.handleOpenAddSkillsToDirectoryModal.bind(this);
    this.handleOpenRemoveDirectorySkillModal = this.handleOpenRemoveDirectorySkillModal.bind(this);
    this.handleRemoveSkillFromDirectory = this.handleRemoveSkillFromDirectory.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { dispatch, match } = this.props;
    const { params: { orgAlias, skillDirectoryId } } = match;
    return dispatch(
      updateSkillDirectoryDS({
        values,
        componentName: SKILL_DIRECTORY_STORE_KEY,
        orgAlias,
        skillDirectoryId,
      }),
    ).then((updated) => {
      toastr.success('Well Done!', 'Skill directory was updated successfully.');
      dispatch(viewUpdateAC(updated, OrgSkillDirectoryManageView.GetComponentName()));
      dispatch(modalCloseAC());
    });
  }

  handleOpenAddSkillsToDirectoryModal() {
    const { dispatch, match } = this.props;
    const { params: { orgAlias, skillDirectoryId } } = match;
    dispatch(modalOpenAC(ADD_SKILLS_TO_DIRECTORY_MODAL_ID, {
      orgAlias,
      skillDirectoryId,
    }));
  }

  handleCloseSkillsToDirectoryModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(ADD_SKILLS_TO_DIRECTORY_MODAL_ID));
  }

  handleCloseRemoveDirectorySkillModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(REMOVE_SKILL_FROM_DIRECTORY_MODAL_ID));
  }

  handleAddSkillsToDirectory(skills) {
    const skillIds = skills.map(({ id }) => id);
    const { dispatch, match } = this.props;
    const { params: { orgAlias, skillDirectoryId } } = match;
    return dispatch(
      skillDirectoryAddSkillsDS({
        componentName: SKILL_DIRECTORY_STORE_KEY,
        orgAlias,
        skillDirectoryId,
        values: { skillIds },
      }),
    ).then(() => {
      this.handleCloseSkillsToDirectoryModal();
      dispatch(requestRefreshAC(SKILL_DIRECTORY_STORE_KEY));
      toastr.success('Well Done!', `Skill Directory ${pluralize('skill', skillIds.length)} updated successfully.`);
    });
  }

  handleOpenRemoveDirectorySkillModal(skill) {
    const { id: skillId, skillDirectorySkillId } = skill;
    const { dispatch } = this.props;
    dispatch(modalOpenAC(REMOVE_SKILL_FROM_DIRECTORY_MODAL_ID, {
      skillId,
      skillDirectorySkillId,
    }));
  }

  handleRemoveSkillFromDirectory() {
    const { dispatch, match, removeSkillModalPayload } = this.props;
    const { params: { orgAlias, skillDirectoryId } } = match;
    const { skillId, skillDirectorySkillId } = removeSkillModalPayload;

    return dispatch(
      skillDirectoryRemoveSkillDS({
        componentName: SKILL_DIRECTORY_STORE_KEY,
        orgAlias,
        skillDirectoryId,
        skillId,
      }),
    ).then(() => {
      this.handleCloseRemoveDirectorySkillModal();
      dispatch(listRemoveItemAC(skillDirectorySkillId, SKILL_DIRECTORY_STORE_KEY));
      toastr.success('Well Done!', 'Skill successfully removed from the directory.');
    });
  }

  render() {
    const {
      match, item: skillDirectory, hasOrgAccess, listIsLoading,
      isAddSkillsToDirectoryModalOpen, isRemoveDirectorySkillModalOpen, skillsCount,
      skills,
    } = this.props;
    const { params: { orgAlias } } = match;
    const isAnyManager = hasOrgAccess({ requireManager: true });
    const breadcrumbs = [
      {
        title: 'Skill directories',
        url: orgSettingsSkillDirectoriesUrl(orgAlias),
      },
      {
        title: skillDirectory.name,
        url: null,
      },
    ];

    const ctaComponent = (
      <TDButton
        onClick={this.handleOpenAddSkillsToDirectoryModal}
        variant={BS_STYLE.PRIMARY}
      >
        Manage skills
      </TDButton>
    );
    return (
      <>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={isAnyManager ? ctaComponent : null}
        />

        <div className="page page--skill-directory">
          <div className="container">
            <div className="mb-5">
              <TDApiConnected
                duck="view"
                component={this.constructor}
                skeletonComponent={SkillDirectoryCardSkeleton}
              >
                <SkillDirectoryCard
                  item={skillDirectory}
                  skillsCount={listIsLoading ? '-' : skillsCount}
                />
              </TDApiConnected>
            </div>

            <SkillDirectorySkillsList
              onAddSkillsToDirectory={this.handleOpenAddSkillsToDirectoryModal}
              onRemoveSkillFromDirectory={this.handleOpenRemoveDirectorySkillModal}
              isAnyManager={isAnyManager}
            />
          </div>
        </div>

        {isRemoveDirectorySkillModalOpen && (
          <ModalSimple
            heading="Delete directory skill"
            body="Are you sure you want to remove this skill from the directory?"
            onClose={this.handleCloseRemoveDirectorySkillModal}
            open={isRemoveDirectorySkillModalOpen}
            footer={[
              <TDButton
                key={1}
                className="float-right"
                label="Cancel"
                onClick={this.handleCloseRemoveDirectorySkillModal}
              />,
              <TDButton
                key={2}
                className="float-right"
                label="Delete"
                variant={BS_STYLE.DANGER}
                onClick={this.handleRemoveSkillFromDirectory}
              />,
            ]}
          />
        )}
        {isAddSkillsToDirectoryModalOpen && (
          <SkillDirectoryAddSkillsPanel
            handleSubmitSelected={this.handleAddSkillsToDirectory}
            skillDirectory={skillDirectory}
            initiallySelected={skills}
          />
        )}
        <OrgSkillDirectoryModalForm
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

OrgSkillDirectoryManageView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isAddSkillsToDirectoryModalOpen: PropTypes.bool,
  isRemoveDirectorySkillModalOpen: PropTypes.bool,
  item: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  removeSkillModalPayload: PropTypes.object,
  skillsCount: PropTypes.number,
  listIsLoading: PropTypes.bool.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  skills: PropTypes.array,
};

OrgSkillDirectoryManageView.defaultProps = {
  isAddSkillsToDirectoryModalOpen: false,
  isRemoveDirectorySkillModalOpen: false,
  item: {},
  removeSkillModalPayload: {},
  skillsCount: 0,
  skills: [],
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, OrgSkillDirectoryManageView.GetComponentName());
  const listState = getListState(state, SKILL_DIRECTORY_STORE_KEY);
  const requestState = getRequestState(state, SKILL_DIRECTORY_STORE_KEY);
  return {
    isAddSkillsToDirectoryModalOpen: getIsModalOpen(state, ADD_SKILLS_TO_DIRECTORY_MODAL_ID),
    isRemoveDirectorySkillModalOpen: getIsModalOpen(state, REMOVE_SKILL_FROM_DIRECTORY_MODAL_ID),
    isRenameSkillDirectoryModalOpen: getIsModalOpen(state, ORG_SKILL_DIRECTORY_MODAL_ID),
    item: viewState.item,
    skillsCount: listState.items.length,
    skills: listState.items?.map(({ skill }) => skill),
    listIsLoading: requestState.isLoading,
    removeSkillModalPayload: getModalPayload(state, REMOVE_SKILL_FROM_DIRECTORY_MODAL_ID),
    renameSkillDirectoryModalPayload: getModalPayload(state, ORG_SKILL_DIRECTORY_MODAL_ID),
    hasOrgAccess: getHasOrgAccess(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgSkillDirectoryManageViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgSkillDirectoryManageView);

export default withRouter(OrgSkillDirectoryManageViewConnected);
