/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import { TYPE } from 'invitations/assets/js/constants';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import ProjectTaskInvitationCardItem from 'projects/assets/js/components/ProjectTaskInvitationCardItem.jsx';
import ProjectInvitationCardItem from 'projects/assets/js/components/ProjectInvitationCardItem.jsx';

const InvitationCard = ({
  item,
  onOpenInvitationModal,
  onSignAndAccept,
}) => {
  const { type } = item;
  const isTaskInvitation = type === TYPE.TASK_ASSIGNMENT;
  const isProjectInvitation = type === TYPE.PROJECT_ADD_MEMBER;
  const isTaskRateSuggestion = type === TYPE.TASK_RATE_SUGGESTION;
  const props = {
    item,
    onOpenInvitationModal,
    onSignAndAccept,
  };
  return (
    <React.Fragment>
      {(isTaskInvitation) && (
        <ProjectTaskInvitationCardItem {...props} />
      )}
      {(isProjectInvitation || isTaskRateSuggestion) && (
        <ProjectInvitationCardItem {...props} />
      )}
    </React.Fragment>
  );
};

InvitationCard.propTypes = {
  item: invitationSpec.isRequired,
  onOpenInvitationModal: PropTypes.func.isRequired,
  onSignAndAccept: PropTypes.func.isRequired,
};
InvitationCard.defaultProps = {};

export default InvitationCard;
