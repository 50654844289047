import PropTypes from 'prop-types';
import React from 'react';

import SearchSelectField from 'core/assets/js/components/FinalFormFields/SearchSelectField.jsx';
import { countryOptions } from 'core/assets/js/lib/isoCountries';

const CountrySelectField = ({ label, name, required, sublabel }) => (
  <SearchSelectField
    getOptionsByIds={async countriesCodes => {
      const countries = countryOptions.filter(country => countriesCodes.includes(country.value));
      return countries.map(c => ({ label: c.text, value: c.value }));
    }}
    label={label}
    loadOptions={(term) => {
      const filteredCountries = countryOptions.filter(
        country => country.text.toLowerCase().startsWith(term.toLowerCase()),
      );
      return filteredCountries.map(c => ({ label: c.text, value: c.value }));
    }}
    name={name}
    placeholder="Search countries"
    required={required}
    sublabel={sublabel}
  />
);

CountrySelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
};

CountrySelectField.defaultProps = {
  label: null,
  required: false,
  sublabel: null,
};

export default CountrySelectField;
