import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import { RATE_STATUS, RATE_SUGGEST_VALUE } from 'rates/assets/js/constants';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import { projectAddWorksheetDS } from 'projects/assets/js/data-services/form';
import { API_DATE_FORMAT, PROJECT_TABS, BS_STYLE } from 'core/assets/js/constants';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectListUrl, projectViewUrl } from 'projects/urls';
import { getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import {
  fetchProjectActiveDS,
  fetchNextWorksheetInfoDS,
} from 'projects/assets/js/data-services/view';
import { projectSpec, projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import WorksheetForm, { FORM_NAME } from 'projects/assets/js/components/WorksheetForm.jsx';
import ServiceOrderPrerequisites from 'projects/assets/js/components/ServiceOrderPrerequisites.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import { fetchRatesDS } from 'rates/assets/js/data-services/rates';
import {
  fetchTasksAssignmentAnalyticsDS,
  fetchTaskForWorksheetDS,
} from 'projects/assets/js/data-services/tasks';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import Storage from 'core/assets/js/lib/Storage';
import { formatDate, getDatetime } from 'core/assets/js/lib/utils';
import { formatTasksForWorksheet } from 'projects/assets/js/lib/utils';
import { formatServiceOrderItems } from 'finance/assets/js/lib/utils';
import { fetchExchangeRatesDS } from 'finance/assets/js/data-services/form';
import { orgPublicSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { exchangeRatesResponseSpec } from 'finance/assets/js/lib/objectSpecs';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';

class ProjectAddWorksheetView extends React.Component {
  static FetchData({ dispatch, params, querystring, url, authedAxios, componentName }) {
    const promises = [
      dispatch(fetchProjectActiveDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName,
      })),
      dispatch(fetchNextWorksheetInfoDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName,
      })),
      dispatch(fetchRatesDS({
        orgAlias: params.orgAlias,
        status: [RATE_STATUS.ACTIVE],
        url,
        authedAxios,
        componentName,
      })),
      dispatch(fetchExchangeRatesDS({
        authedAxios, componentName, orgAlias: params.orgAlias, url,
      })),
    ];

    // Check if we've landed on this page by selecting Raise worksheet on a task page
    let defaultTaskId = queryString.parse(querystring).taskid;
    defaultTaskId = /^\d+$/.test(defaultTaskId) ? parseInt(defaultTaskId, 10) : null;

    if (defaultTaskId) {
      promises.push(
        dispatch(fetchTaskForWorksheetDS({
          authedAxios,
          componentName,
          orgAlias: params.orgAlias,
          projectId: params.id,
          taskId: defaultTaskId, url,
        })),
        dispatch(fetchTasksAssignmentAnalyticsDS({
          authedAxios,
          componentName,
          params,
          projectId: params.id,
        })),
      );
    }

    return Promise.all(promises);
  }

  static GetComponentName() {
    return 'ProjectAddWorksheetView';
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.fetchAutoSavedData = this.fetchAutoSavedData.bind(this);
  }

  onSubmitSuccess() {
    const {
      history, dispatch, match: { params: { orgAlias, id } }, profile: { userId },
    } = this.props;
    // Redirect to Project view
    const url = projectViewUrl(orgAlias, id, PROJECT_TABS.WORKSHEETS);
    history.push(url);

    // Update finance pending counts.
    dispatch(refreshFinancePendingCountsDS({ orgAlias }));
    Storage.remove(`${FORM_NAME}-${userId}-${id}`);
  }

  async handleSubmit(values) {
    const { match: { params: { orgAlias, id } } } = this.props;

    try {
      const response = await projectAddWorksheetDS(orgAlias, id, {
        ...values,
        items: formatServiceOrderItems(values.items),
        periodEnd: values.period.periodEnd,
        periodStart: values.period.periodStart,
      });
      this.onSubmitSuccess();

      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        if (
          error.response.data._meta && error.response.data._meta.name.includes('ValidationError')
        ) {
          if (typeof error.response.data === 'object'
          && Object.keys(error.response.data)?.length === 2) {
            toastr.error('Oh Snap!', error.response.data._error);
          }
          return error.response.data;
        }
        toastr.error('Oh Snap!', error.response.data._error);
        return null;
      }
      toastr.error('Oh Snap!', error.message);
      return null;
    }
  }

  fetchAutoSavedData() {
    const { match: { params: { id } }, rates, profile: { userId } } = this.props;
    const autoSavedData = Storage.get(`${FORM_NAME}-${userId}-${id}`);

    // Check if selected rate still exists. If not, remove item.
    if (autoSavedData && autoSavedData.items) {
      autoSavedData.items = autoSavedData.items.filter(({ rate_id }) => {
        const rateExists = rates.findIndex(r => r.id === rate_id) > -1;
        return rateExists || rate_id === RATE_SUGGEST_VALUE || rate_id === null;
      });
    }

    return autoSavedData;
  }

  render() {
    const {
      allowedActions: { worksheet: worksheetAllowedActions },
      history, match: { params: { orgAlias, id } }, info, project, hasLoaded, rates,
      task, tasksAssignmentAnalytics, exchangeRatesResponse, organization,
    } = this.props;
    const canCreateWorksheet = !isEmpty(worksheetAllowedActions)
      ? worksheetAllowedActions.shouldCreateForCurrentPeriod
      : false;

    const {
      rates: exchangeRates = {}, service: exchangeRatesService, timestamp: exchangeRateUpdatedAt,
    } = exchangeRatesResponse;

    const defaultTaskItem = (
      !isEmpty(task)
      && formatTasksForWorksheet(task, tasksAssignmentAnalytics)[0]
    );

    const breadcrumbs = [
      {
        title: 'Projects',
        url: projectListUrl(orgAlias),
      },
      {
        title: project.title,
        url: projectViewUrl(orgAlias, id),
      },
      {
        title: 'Submit Worksheet',
        url: null,
      },
    ];
    const url = projectViewUrl(orgAlias, id, 'worksheets');
    const targetPeriod = !isEmpty(info?.missingPeriods)
      ? info.missingPeriods[0]
      : null;
    const today = formatDate(getDatetime(), API_DATE_FORMAT);

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--add-worksheet">
          <div className="container">
            <TDApiConnected
              blockingLoading
              component={this.constructor}
              duck="view"
              skeletonComponent={ProjectCardSkeleton}
            >
              <ServiceOrderPrerequisites
                missingFields={info.missingFields}
                orgAlias={info.orgAlias}
                orgName={info.orgName}
                serviceOrderType={SERVICE_ORDER_TYPE.WORKSHEET}
              />
              { hasLoaded && canCreateWorksheet && (
                <WorksheetForm
                  exchangeRates={exchangeRates}
                  exchangeRatesService={exchangeRatesService}
                  exchangeRateUpdatedAt={exchangeRateUpdatedAt}
                  onSubmit={this.handleSubmit}
                  orgAlias={orgAlias}
                  info={info}
                  project={project}
                  rates={rates}
                  initialValues={{
                    ...(defaultTaskItem ? { items: [defaultTaskItem] } : this.fetchAutoSavedData()),
                    currency: organization.currency,
                    period: {
                      periodStart: targetPeriod?.periodStart
                        ? formatDate(targetPeriod.periodStart, API_DATE_FORMAT)
                        : today,
                      periodEnd: targetPeriod?.periodEnd
                        ? formatDate(targetPeriod.periodEnd, API_DATE_FORMAT)
                        : today,
                    },
                  }}
                />
              )}
              { hasLoaded && !canCreateWorksheet && (
                <TDSystemMessage
                  type={BS_STYLE.SUCCESS}
                  title="You are all done!"
                  className="mb-4"
                  data-testid="project-add-worksheet-view-already-raised-message"
                >
                  <p>
                    You have already submitted a Worksheet in this project today.
                    You can review your last submitted worksheet by clicking
                    {' '}
                    <Link to={url}>
                      here
                    </Link>
                    .
                  </p>
                  <div className="row">
                    <div className="col-12 mt-5">
                      <TDButton
                        variant={BS_STYLE.DEFAULT}
                        onClick={() => {
                          history.push(url);
                        }}
                        label="Done"
                      />
                    </div>
                  </div>
                </TDSystemMessage>
              )}
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectAddWorksheetView.propTypes = {
  allowedActions: PropTypes.shape({
    expense: PropTypes.object,
    project: PropTypes.object,
    task: PropTypes.object,
    worksheet: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  exchangeRatesResponse: exchangeRatesResponseSpec,
  hasLoaded: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  info: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  organization: orgPublicSpec.isRequired,
  profile: PropTypes.object.isRequired,
  project: projectSpec,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
  task: projectTaskSpec,
  tasksAssignmentAnalytics: PropTypes.arrayOf(PropTypes.object),
};

ProjectAddWorksheetView.defaultProps = {
  exchangeRatesResponse: { rates: {}, service: null, timestamp: null },
  hasLoaded: false,
  info: null,
  project: {},
  rates: [],
  task: false,
  tasksAssignmentAnalytics: [],
};

const mapStateToProps = (state) => {
  const componentName = ProjectAddWorksheetView.GetComponentName();
  const viewState = getViewState(state, componentName);

  return {
    allowedActions: getViewStateExtras(state, componentName, 'allowedActions'),
    exchangeRatesResponse: getViewStateExtras(state, componentName, 'exchangeRates'),
    hasLoaded: viewState.hasLoaded,
    info: getViewStateExtras(state, componentName, 'nextWorksheetInfo'),
    organization: selectActiveOrg(state),
    profile: selectProfile(state),
    project: viewState.item,
    rates: getListState(state, componentName).items,
    task: getViewStateExtras(state, componentName, 'task'),
    tasksAssignmentAnalytics: getListStateExtras(state, componentName, 'tasksAssignmentAnalytics'),
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectAddWorksheetViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectAddWorksheetView);

export default withRouter(ProjectAddWorksheetViewConnected);
