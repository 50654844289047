import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE, ICON, MODAL_SIZES } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { fetchListDS, getListState, listUpdateItemAC } from 'core/assets/js/ducks/list';
import TDList from 'core/assets/js/components/TDList.jsx';
import { ADD_TO_PROJECT_TYPE } from 'invitations/assets/js/constants';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import ProjectSearch from 'projects/assets/js/components/ProjectSearch.jsx';
import {
  projectAddManagersDS, projectAddProvidersDS, projectRemoveMemberDS,
} from 'projects/assets/js/data-services/form';
import { projectListApiUrl } from 'projects/urls';

export const MODAL_ID = 'manage-projects-modal';

const ManageProjectsModal = ({ filtersOpen, onFiltersToggle, userCard }) => {
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);
  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const componentName = ManageProjectsModal.GetComponentName();
  const [queryParams, setQueryParams] = useState({});

  const userId = userCard?.user?.id;

  const qs = queryString.stringify({ ...queryParams, getMemberShipStatusForUserId: userId });

  const loadProjects = () => dispatch(fetchListDS({
    componentName, url: `${projectListApiUrl(activeOrg.alias)}?${qs}`,
  }));

  useEffect(() => {
    if (!userId || !isOpen) {
      return;
    }
    // we are managing the query params in useState, as this is inside a modal, so we don't want to
    // change the url query params
    loadProjects();
  }, [isOpen, userId, qs]);

  const {
    hasLoaded, extras: { budgetLimits, customFieldFilters }, items, pagination,
  } = useSelector(state => getListState(state, componentName));

  const onlyProjectsUserIsAMemberOf = !!queryParams.memberUserId;

  const userName = userCard?.user?.profile?.name;

  return (
    <ModalConfirm
      heading={`Manage projects for ${userName}`}
      open={isOpen}
      onClose={() => dispatch(modalCloseAC())}
      showConfirmButton={false}
      size={MODAL_SIZES.LARGE}
    >
      <ProjectSearch
        budgetLimits={budgetLimits}
        className="px-0 mt-5"
        customFieldFilters={customFieldFilters}
        extraSearchElement={(
          <div className="d-flex align-items-center mt-5">
            <TDSwitch
              className="mr-3"
              onClick={() => {
                const newQueryParams = { ...queryParams };
                if (onlyProjectsUserIsAMemberOf) {
                  delete newQueryParams.memberUserId;
                } else {
                  newQueryParams.memberUserId = userId;
                }
                setQueryParams(newQueryParams);
              }}
              selected={onlyProjectsUserIsAMemberOf}
            />
            <span className="mr-2">{`Only show projects ${userName} is a member of`}</span>
          </div>
        )}
        filtersOpen={filtersOpen}
        onFiltersChanged={setQueryParams}
        onFiltersToggle={onFiltersToggle}
        queryParams={queryParams}
      />
      {hasLoaded && (
        <TDList
          cardItem={{
            component: ({ isLastItem, item: { id, isMember, title } }) => {
              const wrapperClasses = [
                'manage-projects-modal-item',
                'rounded',
                'p-4',
                'bg-white',
                'd-flex',
                'align-items-center',
                'justify-content-between',
              ];
              if (!isLastItem) {
                wrapperClasses.push('mb-2');
              }
              return (
                <div className={wrapperClasses.join(' ')}>
                  <div className="d-flex align-items-center">
                    <i className={`${ICON.BAR_PROGRESS_DUOTONE} fa-2x`} />
                    <span className="ml-5">{title}</span>
                  </div>
                  {isMember && (
                    <TDButton
                      label="Remove"
                      onClick={async () => {
                        try {
                          await projectRemoveMemberDS({
                            orgAlias: activeOrg.alias, projectId: id, userId,
                          });
                          dispatch(listUpdateItemAC(id, { isMember: false }, componentName));
                          toastr.success(
                            'Well Done!', `${userName} has been removed from project ${title}`,
                          );
                        } catch (e) {
                          toastr.error('Oh Snap!', e.response?.data?._error || e.message);
                        }
                      }}
                      variant={BS_STYLE.DANGER}
                    />
                  )}
                  {!isMember && (
                    <TDButton
                      label="Add"
                      onClick={async () => {
                        try {
                          const reqDS = userCard.userRole.isProvider
                            ? projectAddProvidersDS
                            : projectAddManagersDS;
                          await reqDS(
                            activeOrg.alias,
                            id,
                            { add_to_project_type: ADD_TO_PROJECT_TYPE.ADD, users: [userId] },
                          );
                          dispatch(listUpdateItemAC(id, { isMember: true }, componentName));
                          toastr.success(
                            'Well Done!', `${userName} has been added to project ${title}`,
                          );
                        } catch (e) {
                          toastr.error('Oh Snap!', e.response?.data?._error || e.message);
                        }
                      }}
                      variant={BS_STYLE.SUCCESS}
                    />
                  )}
                </div>
              );
            },
          }}
          emptyListMessage={(
            onlyProjectsUserIsAMemberOf
              ? `No projects found that ${userName} is a member of`
              : 'No projects found, that you can manage the membership of'
          )}
          items={items}
          listClassName="manage-projects-modal-list"
          onPageChange={changes => setQueryParams({ ...queryParams, ...changes })}
          pagination={pagination}
        />
      )}
      {!hasLoaded && <ProjectListSkeleton />}
    </ModalConfirm>
  );
};

ManageProjectsModal.GetComponentName = () => 'ManageProjectsModal';

ManageProjectsModal.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  userCard: userCardSpec.isRequired,
};

export default withFilters(ManageProjectsModal);
