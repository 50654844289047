/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import axios from 'core/assets/js/lib/tdAxios';
import { connect } from 'react-redux';

import { isSSR } from 'core/assets/js/config/checks';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { REACT_SELECT_SKILL_MULTISELECT } from 'core/assets/js/constants';
import { skillSearchApiUrl } from 'skills/urls';
import { logger } from 'core/assets/js/lib/Logger';
import SkillSelectorTag from 'core/assets/js/components/SkillSelectorTag.jsx';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

/**
 * Multiselect skill component
 *
 * Loads skills async via an API endpoint
 */
class SkillMultiselect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.onUpVote = this.onUpVote.bind(this);
    this.onRetractVote = this.onRetractVote.bind(this);
  }

  /**
   * Fire when the skill list is updated
   *
   * @param value Array of skill objects, each entry contains an id and label
   */
  onChange(value) {
    const { input: { onChange, value: oldValue }, onUpVote } = this.props;
    const newValues = value.filter(instance => !oldValue.includes(instance));

    onChange(value);
    onUpVote(newValues.map(i => i.id));
  }

  /**
   * Fire when the skill is voted
   *
   * @param skill value which includes skill that is going to be upvoted
   */
  onUpVote({ skill }) {
    const { input: { onChange, value: oldValue }, onUpVote } = this.props;

    const newValues = oldValue.map((oldSkill) => {
      if (oldSkill.id === skill.id) {
        return ({
          ...oldSkill,
          canUpVote: false,
          canRetractVote: true,
          votesCount: oldSkill.votesCount + 1,
        });
      }
      return oldSkill;
    });

    onChange(newValues);
    onUpVote([skill.id]);
  }

  /**
   * Fire when the skill is retracted
   *
   * @param value value which includes skill that is going to be upvoted
   */
  onRetractVote({ skill }) {
    const { input: { onChange }, onRetractVote } = this.props;
    let { input: { value: oldValue } } = this.props;

    const { votesCount } = skill;

    // remove if has single vote
    if (!votesCount || votesCount === 1) {
      oldValue = oldValue.filter(i => i !== skill);
    }

    const newValues = oldValue.map((oldSkill) => {
      if (oldSkill.id === skill.id) {
        return ({
          ...oldSkill,
          canUpVote: true,
          canRetractVote: false,
          votesCount: oldSkill.votesCount - 1,
        });
      }
      return oldSkill;
    });


    onChange(newValues);
    onRetractVote([skill.id]);
  }

  getOptions(input) {
    const { filteringItemIds } = this.props;
    // Start searching after the 2nd letter
    if (!input || (input && input.trim().length < 2)) {
      return Promise.resolve([]);
    }

    const { activeOrg, memberSkillsOnly } = this.props;

    return new Promise((resolve) => {
      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
      }

      this.filterTimeout = setTimeout(() => {
        clearTimeout(this.filterTimeout);
        const orgAlias = activeOrg ? activeOrg.unique_alias || activeOrg.alias : null;
        return axios.get(skillSearchApiUrl({
          defaultSkillsEnabled: true,
          term: input,
          memberSkillsOnly,
          orgAlias,
        }))
          .then((response) => {
            const filteredResult = response.data.filter(
              ({ id: skillId, parentSkillId }) => !filteringItemIds.includes(skillId)
                && !filteringItemIds.includes(parentSkillId),
            );

            return resolve(filteredResult);
          })
          .catch((e) => {
            logger.error(e);
            resolve([]);
          });
      }, 500);
    });
  }

  render() {
    const { input: { value } } = this.props;
    const { onUpVote, onRetractVote } = this;

    const optionLabelFormater = skill => (
      <SkillSelectorTag
        skill={skill}
        onUpVote={onUpVote}
        onRetractVote={onRetractVote}
      />
    );

    return (
      <div>
        <AsyncSelect
          isClearable={false}
          backspaceRemovesValue={false}
          formatOptionLabel={optionLabelFormater}
          styles={REACT_SELECT_SKILL_MULTISELECT}
          menuPlacement="auto"
          classNamePrefix="react-select"
          menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
          instanceId="skills"
          name="skills-multiselect"
          noOptionsMessage={(data) => {
            if (data.inputValue === '') {
              return 'Start typing...';
            }
            return 'No result found';
          }}
          value={value}
          onChange={this.onChange}
          isMulti
          cacheOptions={false}
          loadOptions={this.getOptions}
          getOptionValue={opt => opt.id}
        />
      </div>
    );
  }
}

SkillMultiselect.propTypes = {
  activeOrg: orgSpec,
  input: PropTypes.object.isRequired,
  memberSkillsOnly: PropTypes.bool,
  onUpVote: PropTypes.func,
  onRetractVote: PropTypes.func,
  filteringItemIds: PropTypes.arrayOf(skillSpec),
};

SkillMultiselect.defaultProps = {
  activeOrg: null,
  memberSkillsOnly: true,
  onUpVote: () => {},
  onRetractVote: () => {},
  filteringItemIds: [],
};

const mapStateToProps = (state, props) => {
  const activeOrg = props.activeOrg || selectActiveOrg(state);
  const filteringItemIds = props.filteringItems.map(item => item.parentSkillId || item.id);
  return {
    activeOrg,
    filteringItemIds,
  };
};

export default connect(mapStateToProps)(SkillMultiselect);
