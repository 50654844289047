import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import {
  BS_STYLE,
  MODAL_SIZES,
  USER_CARD_DEACTIVATE_COUNT_ITEMS,
  USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS,
  USER_TYPE,
  USER_TYPE_LABEL,
} from 'core/assets/js/constants';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import { activateUserCardDS, deactivateUserCardDS } from 'people/assets/js/ducks/people';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { modalDataViewResetAC } from 'core/assets/js/ducks/modalView';
import { getOutstandingCountsDS } from 'organizations/assets/js/data-services/organizations';
import { getViewState } from 'core/assets/js/ducks/view';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { orgUserProfileUrl } from 'people/urls';
import { projectViewTaskUrl, projectViewUrl } from 'projects/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

export const getModalId = userId => `toggle-activation-id-${userId}`;

const parseOutstandingCounts = (orgAlias, outstandingCounts) => {
  const counts = [];
  const links = [];
  Object.keys(outstandingCounts).forEach(key => {
    const entries = outstandingCounts[key];
    const name = USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS[key];
    const count = entries.length;
    if (count === 0) {
      return;
    }
    if (
      ![
        USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECT_MEMBERSHIPS,
        USER_CARD_DEACTIVATE_COUNT_ITEMS.TASK_ASSIGNMENTS,
      ].includes(key)
    ) {
      counts.push({ count, name });
      return;
    }
    const isTaskAssignments = key === USER_CARD_DEACTIVATE_COUNT_ITEMS.TASK_ASSIGNMENTS;
    links.push({
      items: entries.map(item => ({
        item: (
          <a
            href={
              isTaskAssignments
                ? projectViewTaskUrl(orgAlias, item.projectId, item.id)
                : projectViewUrl(orgAlias, item.id)
            }
          >
            {item.title}
          </a>
        ),
        owner: (
          <a
            href={
              orgUserProfileUrl(
                orgAlias, USER_TYPE.MANAGER, item[isTaskAssignments ? 'ownerId' : 'owner_id'],
              )
            }
          >
            {item[isTaskAssignments ? 'ownerName' : 'owner_name']}
          </a>
        ),
      })),
      name,
    });
  });
  return { counts, links };
};

const UserToggleActivationModal = ({
  activate,
  activeOrg: { alias: orgAlias },
  dispatch,
  isModalOpen,
  listViewComponentName,
  outstandingCounts,
  userId,
  userName,
  userType,
}) => {
  const formRef = useRef(null);

  const onClose = () => {
    dispatch(modalCloseAC());
    dispatch(modalDataViewResetAC());
    // We need to reset the form reference after the modal is closed, so if it is re-opened, the
    // correct reference is used
    setTimeout(() => { formRef.current = null; }, 500);
  };
  const activationAction = activate ? 'Activate' : 'Deactivate';
  const userTypeLabel = USER_TYPE_LABEL[userType];
  const userTypeLabelLc = userTypeLabel.toLowerCase();
  const { counts, links } = parseOutstandingCounts(orgAlias, outstandingCounts);
  const hasOutstandingCounts = counts.length > 0;
  const hasOutstandingLinks = links.length > 0;
  const hasOutstandingItems = hasOutstandingCounts || hasOutstandingLinks;
  const validateReason = value => (value ? undefined : 'Please provide a reason');

  const handleSubmit = async (values) => {
    if (!activate && hasOutstandingItems) {
      return;
    }
    const ds = activate ? activateUserCardDS : deactivateUserCardDS;
    await dispatch(ds(orgAlias, userId, listViewComponentName, values));
    toastr.success(
      'Well Done!',
      `${userTypeLabel} ${userName} successfully ${activationAction.toLowerCase()}d`,
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ form, invalid }) => {
        if (!formRef.current) {
          // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is broken
          // https://github.com/final-form/react-final-form/issues/483
          formRef.current = form;
        }

        return (
          <ModalConfirm
            data-testid={`${userTypeLabelLc}-toggle-activation`}
            heading={`${activationAction} ${userTypeLabelLc}`}
            confirmLabel={activationAction}
            confirmButtonDisabled={invalid}
            confirmStyle={activate ? BS_STYLE.PRIMARY : BS_STYLE.DANGER}
            onConfirm={() => formRef.current && formRef.current.submit()}
            onClose={onClose}
            open={isModalOpen}
            showConfirmButton={!hasOutstandingItems}
            size={hasOutstandingLinks ? MODAL_SIZES.LARGE : MODAL_SIZES.SMALL}
          >

            <form className="p-0">
              {activate && (
                <>
                  <p>
                    Are you sure you want to activate
                    {` ${userTypeLabelLc}`}
                    {` ${userName} `}
                    in this organisation?
                  </p>
                  <p>This will restore their access to the organisation and its content.</p>
                </>
              )}
              {!activate && (
                <TDApiConnected
                  duck="view"
                  storeKey={getModalId(userId)}
                  fetchData={
                    ({ authedAxios, componentName }) => dispatch(getOutstandingCountsDS({
                      authedAxios, componentName, orgAlias, userId,
                    }))
                  }
                >
                  {hasOutstandingItems && (
                    <>
                      <TDSystemMessage className="mb-5" title="Cannot deactivate" type="danger">
                        You cannot deactivate
                        {` ${userTypeLabelLc}`}
                        {` ${userName} `}
                        in this organisation.
                      </TDSystemMessage>
                      <p>They have the following outstanding items:</p>
                      {hasOutstandingCounts && (
                        <Table
                          containerClass="mt-5"
                          cols={[
                            { key: 'name', label: 'Item' },
                            { key: 'count', label: 'Count' },
                          ]}
                          items={counts}
                        />
                      )}
                      {hasOutstandingLinks && links.map(({ items, name }) => (
                        <Table
                          containerClass="mt-5"
                          cols={[
                            { key: 'item', label: name },
                            { key: 'owner', label: 'Owner' },
                          ]}
                          key={name}
                          items={items}
                        />
                      ))}
                    </>
                  )}

                  {!hasOutstandingItems && (
                    <>
                      <p>
                        {`Are you sure you want to deactivate ${userTypeLabelLc} `}
                        <strong>{userName}</strong>
                        {' in this organisation?'}
                      </p>
                      <p>
                        They will no longer be able to access the organisation, or any of its
                        content.
                      </p>
                      <p>You can re-activate them at any time, which will restore their access.</p>
                    </>
                  )}
                </TDApiConnected>
              )}

              {(!activate && !hasOutstandingItems) && (
                <TextAreaField
                  label="Reason"
                  name="reason"
                  validate={validateReason}
                />
              )}
            </form>
          </ModalConfirm>
        );
      }}
    />
  );
};

UserToggleActivationModal.propTypes = {
  activate: PropTypes.bool.isRequired,
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  listViewComponentName: PropTypes.string.isRequired,
  outstandingCounts: PropTypes.object,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  userType: PropTypes.oneOf([USER_TYPE.MANAGER, USER_TYPE.PROVIDER]).isRequired,
};

UserToggleActivationModal.defaultProps = {
  outstandingCounts: {},
};

const mapStateToProps = (state, props) => {
  const modalId = getModalId(props.userId);
  return {
    activeOrg: selectActiveOrg(state),
    isModalOpen: getIsModalOpen(state, modalId),
    outstandingCounts: getViewState(state, modalId).item,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const UserToggleActivationModalConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(UserToggleActivationModal);

export default UserToggleActivationModalConnected;
