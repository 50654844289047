import React from 'react';
import PropTypes from 'prop-types';

import { ICON, BS_TOOLTIP_PLACEMENT, USER_TYPE, USER_TYPE_LABEL } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';


const UserTypeIndicator = ({ showTooltip, tooltipMsg, userType }) => {
  /**
   * Show Employment Indicator only incase of:
   * a. Providers that are permanent employees or
   * b. Managers that are contractors. Other wise return null
   */
  if (userType === USER_TYPE.PROVIDER) {
    return null;
  }

  if (!showTooltip) {
    return (
      <span className={`${ICON.CROWN} user-type-indicator`} />
    );
  }

  return (
    <TDElementWithTooltip
      el={<span className={`${ICON.CROWN} user-type-indicator`} />}
      placement={BS_TOOLTIP_PLACEMENT.TOP}
      tooltipMsg={tooltipMsg || USER_TYPE_LABEL[userType]}
    />
  );
};

UserTypeIndicator.propTypes = {
  userType: PropTypes.oneOf(Object.values(USER_TYPE)),
  tooltipMsg: PropTypes.string,
  showTooltip: PropTypes.bool,
};

UserTypeIndicator.defaultProps = {
  userType: USER_TYPE.PROVIDER,
  tooltipMsg: null,
  showTooltip: true,
};

export default UserTypeIndicator;
