import PropTypes from 'prop-types';
import React from 'react';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDRadio from 'core/assets/js/components/TDRadio.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { DOCUMENT_GET_ELEMENT_BY_ID } from 'core/assets/js/config/settings';
import { BS_STYLE } from 'core/assets/js/constants';
import { fastChecksum } from 'core/assets/js/lib/utils';

const YesNoField = ({
  className: classNameIn,
  input,
  required,
  label,
  sublabel,
  additionalError,
  disabled,
  showInline,
  showTextInput,
  meta: { error, pristine, submitError },
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = !!error || !!submitError || showAdditionalError;
  const groupClassName = ['form-group'];
  if (hasError) {
    groupClassName.push('has-error');
  }

  const className = ['yes-no-field'];

  if (disabled) {
    className.push('disabled');
  }

  if (classNameIn) {
    className.push(classNameIn);
  }

  const isYes = input.value ? input.value.isYes : null;

  const selectedYes = () => {
    const value = { ...input.value, answers: [], autoIncrement: 1, isYes: true };
    input.onChange(value);
  };

  const selectedNo = () => {
    const value = { ...input.value, isYes: false };
    input.onChange(value);
  };

  const updateAnswers = newAnswers => {
    input.onChange({
      ...input.value,
      answers: newAnswers,
      autoIncrement: newAnswers.length + 1,
      checksum: fastChecksum(newAnswers.map(a => a.text)),
    });
  };

  const addAnswer = text => {
    const existingAnswers = input.value.answers || [];
    updateAnswers([...existingAnswers, { id: existingAnswers.length + 1, text }]);
  };

  const removeAnswer = index => {
    const newAnswers = [...input.value.answers];
    newAnswers.splice(index, 1);
    updateAnswers(newAnswers.map(({ text }, answerIndex) => ({ id: answerIndex + 1, text })));
  };

  const newAnswerId = `${input.name}-new-answer`;

  return (
    <div className={className.join(' ')}>
      <div className={groupClassName.join(' ')}>
        <label htmlFor={input.name}>
          {label}
          {' '}
          {required && ' *'}
          {' '}
          {sublabel && (
            <span className="sublabel">
              {' '}
              {sublabel}
            </span>
          )}
        </label>
        <div className={`d-flex justify-content-start flex-${showInline ? 'row' : 'column'}`}>
          <label htmlFor={`${input.name}-yes`} className="radio-inline ml-0">
            <span style={{ pointerEvents: 'none' }}>
              <TDRadio
                label="Yes"
                selected={isYes === true}
              />
            </span>
            <input
              className="d-none form-check-input"
              id={`${input.name}-yes`}
              checked={isYes === true}
              name={`${input.name}-yes`}
              type="radio"
              defaultValue={isYes === true}
              onChange={selectedYes}
              disabled={disabled}
            />
          </label>
          <label htmlFor={`${input.name}-no`} className="radio-inline ml-0">
            <span style={{ pointerEvents: 'none' }}>
              <TDRadio
                label="No"
                selected={isYes === false}
              />
            </span>
            <input
              className="d-none"
              id={`${input.name}-no`}
              checked={isYes === false}
              name={`${input.name}-no`}
              type="radio"
              defaultValue={isYes === false}
              onChange={selectedNo}
              disabled={disabled}
            />
          </label>
        </div>
        {isYes === true && showTextInput && (
          <div className={groupClassName.join(' ')}>
            {(input.value.answers || []).map((answer, answerIndex) => (
              <div
                className="d-flex align-items-center justify-content-between mb-3"
                key={answerIndex}
              >
                <div className="yes-no-answer-text">{answer.text}</div>
                <TDButton
                  variant={BS_STYLE.PRIMARY}
                  onClick={() => removeAnswer(answerIndex)}
                  label="Remove"
                />
              </div>
            ))}
            <div className="d-flex align-items-center justify-content-between">
              <input
                className="form-control input-sm mr-2"
                disabled={disabled}
                id={newAnswerId}
                onKeyPress={event => {
                  // [Enter] should not submit the form.
                  if (event.charCode === 13) {
                    event.preventDefault();
                    addAnswer(event.target.value);
                    event.target.value = ''; // eslint-disable-line no-param-reassign
                  }
                }}
                placeholder="Add an answer"
                type="text"
              />
              <TDButton
                variant={BS_STYLE.PRIMARY}
                onClick={() => {
                  const newAnswerInput = DOCUMENT_GET_ELEMENT_BY_ID(newAnswerId);
                  const newValue = newAnswerInput?.value;
                  if (newValue) {
                    addAnswer(newValue);
                    newAnswerInput.value = '';
                  }
                }}
                label="Add"
              />
            </div>
          </div>
        )}
        {error && <div className="help-block">{error}</div>}
        {submitError && <div className="help-block">{submitError}</div>}
        {showAdditionalError && <span className="help-block">{additionalError}</span>}
      </div>
    </div>
  );
};

YesNoField.propTypes = {
  additionalError: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    pristine: PropTypes.bool,
    submitError: PropTypes.string,
  }),
  required: PropTypes.bool,
  showInline: PropTypes.bool,
  showTextInput: PropTypes.bool,
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

YesNoField.defaultProps = {
  additionalError: '',
  className: null,
  disabled: false,
  input: {},
  meta: {
    error: null,
    pristine: true,
    submitError: null,
  },
  required: false,
  showInline: false,
  showTextInput: true,
  sublabel: '',
};

export default withField(YesNoField);
