import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const ReopenTaskButton = ({ onClick, label }) => (
  <TDButton
    onClick={onClick}
    variant={BS_STYLE.PRIMARY}
    label={label}
    rounded
  />
);

ReopenTaskButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};


export default ReopenTaskButton;
