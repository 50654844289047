import PropTypes from 'prop-types';
import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const ApprovedLabel = ({ isSecondLevelApprovalEnabled }) => (
  <>
    Approved
    <ElementWithPopOver
      className="d-inline-block ml-2"
      popOverContent={(
        `Your worksheet, expense, or proforma invoice has been approved by a
         ${isSecondLevelApprovalEnabled ? ' higher' : ''}
         manager. If they have requested an amendment, please amend and submit again for review.`
      )}
      popOverTitle="Approved"
    />
  </>
);

ApprovedLabel.propTypes = {
  isSecondLevelApprovalEnabled: PropTypes.bool.isRequired,
};

export default ApprovedLabel;
