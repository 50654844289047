import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { WINDOW } from 'core/assets/js/config/settings';
import { ICON } from 'core/assets/js/constants';

export const HorizontalFreeScroll = ({ children, className }) => {
  const [showChevrons, setShowChevrons] = useState(false);
  const [hideLeft, setHideLeft] = useState(true);
  const [hideRight, setHideRight] = useState(false);

  const onScroll = ({ target: { clientWidth, scrollLeft, scrollWidth } }) => {
    setHideLeft(scrollLeft === 0);
    setHideRight(clientWidth + scrollLeft === scrollWidth);
  };

  const ref = useRef(null);

  const onScreenResize = () => {
    setShowChevrons(ref.current?.scrollWidth > ref.current?.clientWidth);
  };

  useEffect(() => {
    WINDOW.addEventListener('resize', onScreenResize);
    return () => WINDOW.removeEventListener('resize', onScreenResize);
  }, []);

  const classes = ['horizontal-free-scroll'];
  if (className) {
    classes.push(className);
  }
  if (hideLeft || !showChevrons) {
    classes.push('hide-left');
  }
  if (hideRight || !showChevrons) {
    classes.push('hide-right');
  }

  return (
    <div className={classes.join(' ')}>
      <div className="horizontal-free-scroll__chevron justify-content-start left">
        <i className={ICON.CHEVRON_LEFT} />
      </div>
      <div className="horizontal-free-scroll__content" onScroll={onScroll} ref={ref}>
        {children}
      </div>
      <div className="horizontal-free-scroll__chevron justify-content-end right">
        <i className={ICON.CHEVRON_RIGHT} />
      </div>
    </div>
  );
};

HorizontalFreeScroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

HorizontalFreeScroll.defaultProps = {
  className: null,
};

export default HorizontalFreeScroll;
