import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import AcceptInvitationButton from 'core/assets/js/components/DiscussionBoard/actions/AcceptInvitationButton.jsx';
import CancelInvitationButton from 'core/assets/js/components/DiscussionBoard/actions/CancelInvitationButton.jsx';
import DeclineInvitationButton from 'core/assets/js/components/DiscussionBoard/actions/DeclineInvitationButton.jsx';
import InformPendingOwnerAction from 'core/assets/js/components/DiscussionBoard/actions/InformPendingOwnerAction.jsx';
import InformPublicDiscussionBlocked from 'core/assets/js/components/DiscussionBoard/actions/InformPublicDiscussionBlocked.jsx';
import ManageInvitationButton from 'core/assets/js/components/DiscussionBoard/actions/ManageInvitationButton.jsx';
import ProposeTaskRateButton from 'core/assets/js/components/DiscussionBoard/actions/ProposeTaskRateButton.jsx';
import StartProjectAction from 'core/assets/js/components/DiscussionBoard/actions/StartProjectAction.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { BS_STYLE, ICON, TASK_TABS } from 'core/assets/js/constants';
import { TASK_ACTION, TASK_ACTION_LABEL } from 'projects/assets/js/constants';
import { projectSpec, projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectViewTaskUrl } from 'projects/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';

const ACTION_TO_COMPONENT = {
  [TASK_ACTION.START_PROJECT]: {
    component: StartProjectAction,
  },
  [TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION]: {
    component: AcceptInvitationButton,
  },
  [TASK_ACTION.REJECT_ASSIGNMENT_INVITATION]: {
    component: DeclineInvitationButton,
    asDropdownItem: true,
  },
  [TASK_ACTION.MANAGE_ASSIGNMENT_INVITATION]: {
    component: ManageInvitationButton,
    asDropdownItem: false,
  },
  [TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION]: {
    component: CancelInvitationButton,
  },
  // rate suggestion
  [TASK_ACTION.PROPOSE_TASK_RATE]: {
    component: ProposeTaskRateButton,
    asDropdownItem: true,
  },
  [TASK_ACTION.ACCEPT_RATE_INVITATION]: {
    component: AcceptInvitationButton,
  },
  [TASK_ACTION.REJECT_RATE_INVITATION]: {
    component: DeclineInvitationButton,
    asDropdownItem: true,
  },
  [TASK_ACTION.CANCEL_RATE_INVITATION]: {
    component: CancelInvitationButton,
  },
  [TASK_ACTION.ACCEPT_COMPLETION_INVITATION]: {
    component: AcceptInvitationButton,
  },
  [TASK_ACTION.REJECT_COMPLETION_INVITATION]: {
    component: DeclineInvitationButton,
  },
  [TASK_ACTION.CANCEL_COMPLETION_INVITATION]: {
    component: CancelInvitationButton,
  },
  [TASK_ACTION.INFORM_PUBLIC_DISCUSSION_BLOCKED]: {
    component: InformPublicDiscussionBlocked,
  },
  [TASK_ACTION.INFORM_PENDING_ASSIGNMENT]: {
    component: InformPendingOwnerAction,
    action: TASK_ACTION.INFORM_PENDING_ASSIGNMENT,
  },
  [TASK_ACTION.INFORM_PENDING_RATE_INVITATION_FOR_OWNER]: {
    component: InformPendingOwnerAction,
    action: TASK_ACTION.INFORM_PENDING_RATE_INVITATION_FOR_OWNER,
  },
  [TASK_ACTION.INFORM_PENDING_ASSINGMENT_INVITATION_FOR_OWNER]: {
    component: InformPendingOwnerAction,
    action: TASK_ACTION.INFORM_PENDING_ASSINGMENT_INVITATION_FOR_OWNER,
  },
  [TASK_ACTION.INFORM_PENDING_COMPLETION_INVITATION_FOR_OWNER]: {
    component: InformPendingOwnerAction,
    action: TASK_ACTION.INFORM_PENDING_COMPLETION_INVITATION_FOR_OWNER,
  },
};

// Wrapper component
const TaskStateActions = ({
  accessControl,
  actions,
  hasPendingDocuments,
  history,
  isEmbeddedMode,
  location,
  match,
  onSignAndAccept,
  onStateUpdated,
  onTaskUpdated,
  parentComponentName,
  project,
  task,
}) => {
  if (isEmpty(actions)) {
    return null;
  }

  const { isAssignee } = accessControl;
  // Depending on mode (embedded or not), skip the following actions for the assignee:
  const assigneeSkippedActions = isEmbeddedMode
    ? [
      TASK_ACTION.MANAGE_ASSIGNMENT_INVITATION,
    ] : [
      TASK_ACTION.ACCEPT_RATE_INVITATION,
      TASK_ACTION.REJECT_RATE_INVITATION,
      TASK_ACTION.CANCEL_RATE_INVITATION,
      TASK_ACTION.REJECT_ASSIGNMENT_INVITATION,
      TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION,
      TASK_ACTION.PROPOSE_TASK_RATE,
      TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION,
    ];

  const primaryActions = [];
  const secondaryActions = [];

  const handleClick = (action) => {
    // Check if there are pending documents to sign before accepting the invitation.
    if (action?.name === TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION && hasPendingDocuments) {
      const documentAssignments = action?.data?.invitation?.documentAssignments;
      if (Array.isArray(documentAssignments)) {
        onSignAndAccept(documentAssignments);
      }
      return;
    }

    if (isEmbeddedMode) {
      history.push({
        pathname: location.pathname,
        search: location.search,
        state: { formAction: action },
      });
      return;
    }

    const { params: { orgAlias, id: projectId, taskId, userId } } = match;
    history.push(
      projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, action.name, userId),
    );
  };

  const wrapperClasses = ['row align-items-center justify-content-center'];
  actions.forEach((action) => {
    const actionName = action.name;

    if (
      !ACTION_TO_COMPONENT[actionName]
      || (isAssignee && assigneeSkippedActions.includes(actionName))
    ) {
      return;
    }

    const {
      component: Component, asDropdownItem, wrapperClass, formType, ...componentProps
    } = ACTION_TO_COMPONENT[actionName];

    const label = TASK_ACTION_LABEL[actionName];
    const entry = (
      <Component
        asDropdownItem={asDropdownItem}
        formType={formType}
        hasPendingDocuments={hasPendingDocuments}
        key={`task-action-${actionName}${action.targetUserId ? `-${action.targetUserId}` : ''}`}
        label={label}
        onClick={() => handleClick(action)}
        onStateUpdated={onStateUpdated}
        onTaskUpdated={onTaskUpdated}
        parentComponentName={parentComponentName}
        project={project}
        task={task}
        {...(componentProps || {})}
        {...action.data}
      />
    );

    if (!asDropdownItem) {
      primaryActions.push(entry);
    } else {
      secondaryActions.push(entry);
    }

    if (wrapperClass) {
      wrapperClasses.push(wrapperClass);
    }
  });

  if (!primaryActions.length) {
    return null;
  }

  return (
    <div className="discussion-board__row db-cta-row">
      <div className={`db-cta-row__buttons-wrapper ${wrapperClasses.join(' ')}`.trim()}>
        {primaryActions}

        {secondaryActions.length > 0 && (
          <TDDropButton
            overlayClassName="db-cta-row-dropdown"
            toggleEl={(
              <TDButton
                variant={BS_STYLE.DEFAULT}
                label="More"
                btnIcon={<i className={ICON.ELLIPSIS} />}
              />
            )}
          >
            {secondaryActions}
          </TDDropButton>
        )}
      </div>
    </div>
  );
};

TaskStateActions.propTypes = {
  accessControl: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  hasPendingDocuments: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  isEmbeddedMode: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onStateUpdated: PropTypes.func.isRequired,
  onSignAndAccept: PropTypes.func.isRequired,
  onTaskUpdated: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  project: projectSpec.isRequired,
  task: projectTaskSpec,
};

TaskStateActions.defaultProps = {
  accessControl: {},
  actions: [],
  hasPendingDocuments: false,
  task: {},
};

export default withRouter(TaskStateActions);
