import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TaskCard from 'projects/assets/js/components/TaskCard.jsx';
import TaskSearch from 'projects/assets/js/components/TaskSearch.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { fetchTaskListDS } from 'projects/assets/js/data-services/tasks';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';

class ProjectTabTasks extends React.Component {
  static FetchData({ dispatch, params, url, querystring, componentName, authedAxios }) {
    const { id: projectId } = params;

    return dispatch(
      fetchTaskListDS({
        url,
        params,
        querystring,
        projectId,
        componentName,
        authedAxios,
        dispatch,
      }),
    );
  }

  static GetComponentName() {
    return 'ProjectTabTasks';
  }

  render() {
    const {
      onFiltersToggle, filtersOpen, tasks, pagination, isManager, customFieldFilters,
      match: { params: { orgAlias } },
    } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <TaskSearch
            onFiltersToggle={onFiltersToggle}
            filtersOpen={filtersOpen}
            isManager={isManager}
            customFieldFilters={customFieldFilters}
          />
        </div>

        <TDApiConnected
          duck="list"
          component={this.constructor}
          blockingLoading
          skeletonComponent={ProjectCardSkeleton}
        >
          <div className="tasks-list">
            { !filtersOpen && (
              <TDList
                items={tasks}
                pagination={pagination}
                emptyListMessage="No tasks found."
                cardItem={{
                  component: TaskCard,
                  props: {
                    orgAlias,
                  },
                }}
              />
            )}
          </div>
        </TDApiConnected>
      </React.Fragment>
    );
  }
}

ProjectTabTasks.propTypes = {
  tasks: PropTypes.arrayOf(projectTaskSpec).isRequired,
  match: routerMatchContentsSpec.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  isManager: PropTypes.bool,
  pagination: paginationSpec.isRequired,
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
};

ProjectTabTasks.defaultProps = {
  isManager: false,
  customFieldFilters: [],
};

const mapStateToProps = (state) => {
  const componentName = ProjectTabTasks.GetComponentName();
  const listState = getListState(state, componentName);
  const hasOrgAccess = getHasOrgAccess(state);

  return {
    tasks: listState.items,
    pagination: listState.pagination,
    isManager: hasOrgAccess({ requireManager: true }),
    customFieldFilters: getListStateExtras(state, componentName, 'customFieldFilters'),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectTabTasksConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTabTasks);

export default withRouter(withFilters(ProjectTabTasksConnected));
