/* eslint import/prefer-default-export:off */
import queryString from 'query-string';

export const skillSearchApiUrl = ({
  term, paginationEnabled, memberSkillsOnly = false,
  startsWith, orgAlias = null, defaultSkillsEnabled = false,
  onlySystemSkills = false, orgId = null, excludeDefaultSkills = false,
  directoryId = null, excludeSkillIds = null, excludeUserProfileSkills = null,
} = {}) => {
  const baseUrl = orgAlias ? `/api/${orgAlias}/skills` : '/api/skills';
  const qString = {
    kw: term,
    startsWith,
    paginationEnabled,
  };

  if (defaultSkillsEnabled) {
    qString.defaultSkillsEnabled = 1;
  }

  if (memberSkillsOnly) {
    qString.ms = 1;
  }

  if (onlySystemSkills) {
    qString.onlySystemSkills = 1;
  }

  if (excludeDefaultSkills) {
    qString.excludeDefaultSkills = 1;
  }

  if (orgId) {
    qString.orgId = orgId;
  }

  if (excludeSkillIds) {
    qString.excludeSkillIds = encodeURIComponent(excludeSkillIds);
  }

  if (excludeUserProfileSkills) {
    qString.excludeUserProfileSkills = 1;
  }

  if (directoryId) {
    qString.directoryId = directoryId;
  }
  return `${baseUrl}/search?${queryString.stringify(qString)}`;
};

export const skillCountsByLetterApiUrl = ({
  memberSkillsOnly = false, orgAlias = null,
  onlySystemSkills = false, orgId = null, excludeDefaultSkills = false,
  directoryId = null,
} = {}) => {
  const baseUrl = orgAlias ? `/api/${orgAlias}/skills` : '/api/skills';
  const qString = {};

  if (memberSkillsOnly) {
    qString.ms = 1;
  }

  if (onlySystemSkills) {
    qString.onlySystemSkills = 1;
  }

  if (excludeDefaultSkills) {
    qString.excludeDefaultSkills = 1;
  }

  if (orgId) {
    qString.orgId = orgId;
  }

  if (directoryId) {
    qString.directoryId = directoryId;
  }

  return `${baseUrl}/counts-by-letter?${queryString.stringify(qString)}`;
};

export const languageSearchApiUrl = ({ term } = {}) => {
  const keyword = encodeURIComponent(term.trim());
  const baseUrl = '/api/skills/languages';
  return `${baseUrl}/search?kw=${keyword}`;
};

export const countOrgsCustomSkillsUrl = ({ orgIds } = {}) => {
  return `/api/skills/counts-org-custom-skills?orgIds=${JSON.stringify(orgIds)}`;
};

export const getSkillsByIdsApiUrl = orgAlias => `/api/${orgAlias}/skills/get-by-ids`;

export const getLanguagesByIdsApiUrl = () => '/api/skills/languages/get-by-ids';
