import queryString from 'query-string';

import { USER_ACTION_VALUES } from 'core/assets/js/constants';
import { BILLING_TABS } from 'finance/assets/js/constants';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';

export const billingViewUrl = (orgAlias, tabName = null) => {
  const tab = !tabName ? BILLING_TABS.OVERVIEW : tabName;
  return `/${orgAlias}/billing/${tab}`;
};

export const financeManagersBudgetUrl = orgAlias => (
  `/${orgAlias}/finance/managers-budget`
);
export const financeManagersBudgetBreakdownUrl = (orgAlias, id) => (
  `/${orgAlias}/finance/managers-budget/${id}`
);
export const financeProjectsBudgetUrl = orgAlias => (
  `/${orgAlias}/finance/projects-budget`
);
export const financePurchaseOrderListUrl = orgAlias => (
  `/${orgAlias}/finance/purchase-orders`
);
export const financePurchaseOrderViewUrl = (orgAlias, id) => (
  `/${orgAlias}/finance/purchase-orders/${id}`
);
export const financeWorksheetsUrl = (orgAlias, { taskId = null } = {}) => (
  `/${orgAlias}/finance/worksheets${taskId ? `?taskId=${taskId}` : ''}`
);
export const financeWorksheetViewUrl = (orgAlias, id) => (
  `/${orgAlias}/finance/worksheets/${id}`
);
export const financeWorksheetEditUrl = (orgAlias, worksheetId, backUrl = null) => (
  `/${orgAlias}/finance/worksheets/${worksheetId}/edit${backUrl ? `?backUrl=${backUrl}` : ''}`
);
export const financeWorksheetsCreateUrl = orgAlias => (
  `/${orgAlias}/finance/worksheets/new`
);
export const financeExpensesUrl = orgAlias => (
  `/${orgAlias}/finance/expenses`
);
export const financeExpenseViewUrl = (orgAlias, id) => (
  `/${orgAlias}/finance/expenses/${id}`
);
export const financeExpenseEditUrl = (orgAlias, expenseId) => (
  `/${orgAlias}/finance/expenses/${expenseId}/edit`
);
export const financeExpensesCreateUrl = orgAlias => (
  `/${orgAlias}/finance/expenses/new`
);
export const financeInvoicesUrl = orgAlias => (
  `/${orgAlias}/finance/invoices`
);
export const financeInvoiceViewUrl = (orgAlias, id, backUrl = null) => (
  `/${orgAlias}/finance/invoices/${id}${backUrl ? `?backUrl=${backUrl}` : ''}`
);
// Pro Forma invoice Urls
export const financeProFormaInvoicesUrl = orgAlias => `/${orgAlias}/finance/proforma-invoices`;

export const financeProFormaInvoiceCreateUrl = orgAlias => (
  `${financeProFormaInvoicesUrl(orgAlias)}/new`
);
export const financeProFormaInvoiceViewUrl = (orgAlias, id) => (
  `${financeProFormaInvoicesUrl(orgAlias)}/${id}`
);
export const financeProFormaInvoiceEditUrl = (orgAlias, id, backUrl = null) => (
  `${financeProFormaInvoiceViewUrl(orgAlias, id)}/edit${backUrl ? `?backUrl=${backUrl}` : ''}`
);

export const finance1099FilingsUrl = (orgAlias, tab) => (
  `/${orgAlias}/finance/1099-filings${tab ? `/${tab}` : ''}`
);

export const financeReviewUSTaxFormUrl = (orgAlias, userUSTaxFormId) => (
  `/${orgAlias}/finance/review-us-tax-form/${userUSTaxFormId}`
);

export const financeServiceOrderViewUrl = (orgAlias, type, id) => {
  switch (type) {
    case SERVICE_ORDER_TYPE.WORKSHEET:
      return financeWorksheetViewUrl(orgAlias, id);
    case SERVICE_ORDER_TYPE.EXPENSE:
      return financeExpenseViewUrl(orgAlias, id);
    default:
      return financeProFormaInvoiceViewUrl(orgAlias, id);
  }
};

// API
export const financeDeferredPaymentsFeatureFlagApiUrl = (url = '') => `${url}/api/finance/deferred-payments-feature-flag`;

export const financeDeferredSettingFeatureFlagApiUrl = (url = '') => {
  return `${url}/api/finance/deferred-setting-feature-flag`;
};
export const financeSettleDeferredInvoicesApiUrl = (orgAlias, url = '') => {
  return `${url}/api/${orgAlias}/finance/settle-deferred-invoices`;
};
export const financeOutstandingDocumentsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/outstanding-documents`
);
export const financeManagersBudgetListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/managers-budget`
);
export const financePurchaseOrderListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/purchase-orders`
);
export const financePurchaseOrderViewApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/purchase-orders/${id}`
);
export const financePurchaseOrderManageApiUrl = (orgAlias, id, action, url = '') => {
  if (!USER_ACTION_VALUES.includes(action)) {
    throw new Error(`Invalid action: ${action}`);
  }
  return `${url}/api/${orgAlias}/finance/purchase-orders/${id}/${action}`;
};
export const financeProjectsBudgetListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/projects-budget`
);
export const financeWorksheetProjectsListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/worksheets/projects`
);
export const financeWorksheetListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/worksheets`
);
export const financeWorksheetAnalyticsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/worksheets/analytics`
);
export const financeWorksheetViewApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/worksheets/${id}`
);
export const financeWorksheetManageApiUrl = (orgAlias, id, action, url = '') => {
  if (!USER_ACTION_VALUES.includes(action)) {
    throw new Error(`Invalid action: ${action}`);
  }
  return `${url}/api/${orgAlias}/finance/worksheets/${id}/${action}`;
};
export const financeWorksheetRequestAmendmentApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/worksheets/${id}/request-amendment`
);
export const financeInvoiceManageApiUrl = (orgAlias, id, action, url = '') => {
  if (!USER_ACTION_VALUES.includes(action)) {
    throw new Error(`Invalid action: ${action}`);
  }
  return `${url}/api/${orgAlias}/finance/invoices/${id}/${action}`;
};
export const financeExpenseProjectsListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/expenses/projects`
);
export const financeExpenseListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/expenses`
);
export const financeExpenseViewApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/expenses/${id}`
);
export const financeExpenseRequestAmendmentApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/expenses/${id}/request-amendment`
);
export const financeExpenseManageApiUrl = (orgAlias, id, action, url = '') => {
  if (!USER_ACTION_VALUES.includes(action)) {
    throw new Error(`Invalid action: ${action}`);
  }
  return `${url}/api/${orgAlias}/finance/expenses/${id}/${action}`;
};
export const financeInvoiceListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/finance/invoices`
);
export const financeInvoiceViewApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/invoices/${id}`
);
export const financeInvoicePreviewUpcomingOutbound = (orgAlias, billingDeadline) => {
  const query = queryString.stringify({ billingDeadline }, { encode: false });
  return `/api/${orgAlias}/finance/invoices/preview-upcoming-outbound-invoice?${query}`;
};
export const financeInvoiceItemListApiUrl = (orgAlias, ids, url = '') => {
  const query = queryString.stringify({ ids }, { encode: false });
  return `${url}/api/${orgAlias}/finance/invoices/items?${query}`;
};
export const financeManagersBudgetBreakdownApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/managers-budget/${id}`
);
export const addBackUrlIfLocationContains = (url, loc, contains) => (
  (loc || '').includes(contains) ? `${url}?backUrl=${loc}` : url
);
export const financeGetExchangeRatesApiUrl = orgAlias => `/api/${orgAlias}/finance/exchange-rates`;

export const financePendingDeferredPaymentsMetaApiUrl = orgAlias => `/api/${orgAlias}/finance/pending-deferred-payments-meta`;

export const financeGetPaymentsWidgetDataApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/payments-widget-data`
);

export const financeProFormaInvoiceListApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/proforma-invoices`
);

export const financeProFormaInvoicesCsvApiUrl = orgAlias => (
  `${financeProFormaInvoiceListApiUrl(orgAlias)}/csv`
);

export const financeProFormaInvoiceCreateApiUrl = financeProFormaInvoiceListApiUrl;

export const financeProFormaInvoiceViewApiUrl = (orgAlias, id) => (
  `${financeProFormaInvoiceListApiUrl(orgAlias)}/${id}`
);

export const financeProFormaInvoiceEditApiUrl = financeProFormaInvoiceViewApiUrl;

export const financeCancelProFormaApiUrl = (orgAlias, proFormaInvoiceId) => (
  `/api/${orgAlias}/finance/proforma-invoices/${proFormaInvoiceId}/cancel`
);

export const financeProFormaInvoiceRequestAmendmentApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/finance/proforma-invoices/${id}/request-amendment`
);

export const financeProFormaInvoiceManageApiUrl = (orgAlias, id, action, url = '') => {
  if (!USER_ACTION_VALUES.includes(action)) {
    throw new Error(`Invalid action: ${action}`);
  }
  return `${url}/api/${orgAlias}/finance/proforma-invoices/${id}/${action}`;
};

export const financeNextProFormaInvoicinfoApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/proforma-invoices/next`
);

export const financeDraftInvoiceAllowedActionsApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/proforma-invoices/allowed-actions`
);

export const financeServiceOrderMissingPrerequisitesApiUrl = (orgAlias, userId) => [
  `/api/${orgAlias}/finance/service-orders/missing-prerequisites`,
  userId ? `?user_id=${userId}` : '',
].join('');

export const financeGetInvoiceByNumberApiUrl = (orgAlias, number) => (
  `/api/${orgAlias}/finance/invoice-number/${number}`
);

export const financeExpensesCsvApiUrl = (orgAlias, url = '') => (
  `${financeExpenseListApiUrl(orgAlias, url)}/csv`
);

export const financeGetW8TaxFormUsersApiUrl = orgAlias => `/api/${orgAlias}/finance/us-tax/w8`;
export const financeGetW9TaxFormUsersApiUrl = orgAlias => `/api/${orgAlias}/finance/us-tax/w9`;
export const financeGet1099ContractorsApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/us-tax/1099-contractors`
);

export const financeApproveUSTaxFormApiUrl = (orgAlias, userUSTaxFormId) => (
  `/api/${orgAlias}/finance/us-tax/approve-form/${userUSTaxFormId}`
);
export const financeRejectUSTaxFormApiUrl = (orgAlias, userUSTaxFormId) => (
  `/api/${orgAlias}/finance/us-tax/reject-form/${userUSTaxFormId}`
);

export const financeGetTaxFormApiUrl = (orgAlias, userUSTaxFormId) => (
  `/api/${orgAlias}/finance/us-tax/form/${userUSTaxFormId}`
);

export const financeGetUSTaxCompanyDetailsApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/us-tax/company-details`
);
export const financeUpdateUSTaxCompanyDetailsApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/us-tax/company-details`
);

export const financeServiceOrderManageApiUrl = (orgAlias, type, id, action) => {
  if (!USER_ACTION_VALUES.includes(action)) {
    throw new Error(`Invalid action: ${action}`);
  }
  switch (type) {
    case SERVICE_ORDER_TYPE.WORKSHEET:
      return financeWorksheetManageApiUrl(orgAlias, id, action);
    case SERVICE_ORDER_TYPE.EXPENSE:
      return financeExpenseManageApiUrl(orgAlias, id, action);
    default:
      return financeProFormaInvoiceManageApiUrl(orgAlias, id, action);
  }
};

export const financeFileIndividual1099ApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/us-tax/file-individual-1099`
);
export const financeFileAll1099ApiUrl = orgAlias => `/api/${orgAlias}/finance/us-tax/file-all-1099`;
export const financeGet1099FilingPDFFileIdApiUrl = (orgAlias, tax1099FilingRequestId) => (
  `/api/${orgAlias}/finance/us-tax/1099-pdf/${tax1099FilingRequestId}`
);
export const financeUpdate1099OffPlatformPaymentsApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/us-tax/off-platform-payment`
);
export const financeDeleteUSTaxFormApiUrl = financeGetTaxFormApiUrl;
export const financeUploadUSTaxFormApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/finance/us-tax/upload-us-tax-form/${userId}`
);
export const financeDownloadAll1099ApiUrl = orgAlias => (
  `/api/${orgAlias}/finance/us-tax/generate-1099-download-all`
);

// subscriptions

export const financeActiveSubscriptionApiUrl = (orgAlias, url = '') => {
  return `${url}/api/${orgAlias}/finance/subscriptions/active`;
};

export const financeSubscriptionProductsApiUrl = (url = '') => {
  return `${url}/api/finance/subscription-products`;
};

// invoicing entities

export const financeDefaultInvoicingEntityApiUrl = (url = '') => {
  return `${url}/api/finance/default-invoicing-entity`;
};

// Public

export const financeInvoiceCalendarPublicUrl = () => (
  'https://www.talentdesk.io/knowledge-base/finances/manager/'
  + 'important-dates-for-worksheets-invoices'
);
