import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';


import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { getIsModalOpen, modalCloseAC, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';

export const ORG_SKILL_DIRECTORY_MODAL_ID = 'org-skill-directory-modal';

const OrgSkillDirectoryModalForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => getIsModalOpen(state, ORG_SKILL_DIRECTORY_MODAL_ID));
  const modalPayload = useSelector(state => getModalPayload(state, ORG_SKILL_DIRECTORY_MODAL_ID));

  const handleModalClose = () => {
    dispatch(modalCloseAC());
  };

  return (
    <div className="static-modal">
      <Form
        initialValues={modalPayload}
        onSubmit={onSubmit}
        render={({ values, handleSubmit, submitting }) => (
          <ModalSimple
            noFooter
            open={isModalOpen}
            size={BS_SIZE.LARGE}
            onClose={handleModalClose}
            heading={modalPayload ? 'Rename skill directory' : 'Create skill directory'}
            body={(
              <form onSubmit={handleSubmit}>
                <div className="pb-3">
                  <TextInputField
                    name="name"
                    label="Directory name"
                    required
                  />
                </div>

                <div className="button-container mt-4 d-flex justify-content-end border-top mt-5 pt-5">
                  <TDButton
                    label="Cancel"
                    variant={BS_STYLE.DEFAULT}
                    disabled={submitting}
                    onClick={handleModalClose}
                  />

                  <TDButton
                    type="submit"
                    label={modalPayload ? 'Save' : 'Create'}
                    variant={BS_STYLE.PRIMARY}
                    disabled={submitting || !values?.name}
                  />
                </div>
              </form>
            )}
          />
        )}
      />
    </div>
  );
};
OrgSkillDirectoryModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default OrgSkillDirectoryModalForm;
