import PropTypes from 'prop-types';
import React from 'react';

import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import ProjectsSettingsProjectsTab from 'settings/assets/js/components/ProjectsSettingsProjectsTab.jsx';
import ProjectsSettingsTasksTab from 'settings/assets/js/components/ProjectsSettingsTasksTab.jsx';
import {
  PROJECTS_SETTINGS_TABS, PROJECTS_SETTINGS_TABS_VALUES,
} from 'settings/assets/js/constants';

const ProjectsSettingsTab = ({ handleSettingsChanged, organization, tab }) => {
  const commonProps = { handleSettingsChanged, organization };

  if (tab === PROJECTS_SETTINGS_TABS.PROJECTS) {
    return <ProjectsSettingsProjectsTab {...commonProps} />;
  }

  if (tab === PROJECTS_SETTINGS_TABS.TASKS) {
    return <ProjectsSettingsTasksTab {...commonProps} />;
  }

  return null;
};

ProjectsSettingsTab.propTypes = {
  handleSettingsChanged: PropTypes.func.isRequired,
  organization: orgSpec.isRequired,
  tab: PropTypes.oneOf(PROJECTS_SETTINGS_TABS_VALUES).isRequired,
};

export default ProjectsSettingsTab;
