import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getViewState } from 'core/assets/js/ducks/view';
import { BS_STYLE } from 'core/assets/js/constants';
import { WORKSHEET_IMPORT_LIMIT, WORKSHEET_IMPORT_SCHEME } from 'projects/assets/js/constants';
import { fetchProjectWorksheetImportInfoDS } from 'projects/assets/js/data-services/view';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ProjectWorksheetImportMappingFormSkeleton from 'projects/assets/js/components/skeletons/ProjectWorksheetImportMappingFormSkeleton.jsx';
import { pluralize } from 'core/assets/js/lib/utils';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';

const COMPONENT_NAME = 'ProjectWorksheetImportMappingForm';

const ProjectWorksheetImportMappingForm = ({ rowsCount, headers, updateFormField }) => {
  const requiredFields = Object.keys(WORKSHEET_IMPORT_SCHEME);
  const optionsMappings = headers.map(header => ({ text: header, value: header }));
  const fieldsMap = Object.keys(WORKSHEET_IMPORT_SCHEME).map((key) => {
    const defaultValue = headers.find(header => header === WORKSHEET_IMPORT_SCHEME[key]);
    if (defaultValue) {
      updateFormField(key, defaultValue);
    }
    return {
      label: WORKSHEET_IMPORT_SCHEME[key], name: key, required: true,
    };
  });

  return (
    <>
      <h3 className="mb-3 mt-0">Map fields</h3>
      <div>
        We’ve uploaded your CSV file and detected
        {' '}
        <span className="font-weight-bolder">{rowsCount}</span>
        {` ${pluralize('worksheet', rowsCount)} `}
        ready for import. Make sure your fields below match the ones TalentDesk.io
        uses and finalise your import.
      </div>
      {rowsCount > WORKSHEET_IMPORT_LIMIT && (
        <TDSystemMessage
          type={BS_STYLE.INFO}
          title="Max worksheets per file limit reached"
          className="mt-4"
        >
          {`You have imported a file with ${rowsCount} worksheets. Uploaded file should not include more than ${WORKSHEET_IMPORT_LIMIT} worksheets.`}
          {`Last ${rowsCount - WORKSHEET_IMPORT_LIMIT} items in the list will be skipped.`}
        </TDSystemMessage>
      )}
      <hr />
      <div className="row d-none d-sm-flex align-items-center mb-4">
        <div className="col-12 col-sm-3 mb-0">
          <div className="mapping-header font-weight-bolder">Predefined fields</div>
        </div>
        <div className="col-12 col-sm-9 pl-0">
          <div className="mapping-header font-weight-bolder">Imported file headers</div>
        </div>
      </div>
      { fieldsMap.map(({ name, required, label }) => (
        <div className="row" key={name}>
          <div className="d-none d-sm-flex col-3">
            <label className="w-100 bg-gray pt-3 pl-3 mb-0">
              {`${label} ${required ? '*' : ''}`}
            </label>
          </div>
          <div className="col-12 col-sm-9 pb-3 pl-4 pl-sm-0">
            <SelectField
              labelClassName="d-inline-block d-sm-none"
              label={label}
              name={name}
              optionsMapping={optionsMappings}
              required={required}
              validate={val => (((requiredFields.includes(name) && !val)
                ? 'Field is required'
                : null
              ))}
            />
          </div>
        </div>
      ))}
    </>
  );
};

ProjectWorksheetImportMappingForm.propTypes = {
  headers: PropTypes.array.isRequired,
  rowsCount: PropTypes.number.isRequired,
  updateFormField: PropTypes.func.isRequired,
};

const TdApiConnectedMappingForm = withTDApiConnected({
  fetchData: ({
    dispatch, params: { orgAlias, id }, authedAxios, querystring,
  }) => dispatch(fetchProjectWorksheetImportInfoDS({
    orgAlias, id, authedAxios, componentName: COMPONENT_NAME, querystring,
  })),
  duck: 'view',
  storeKey: COMPONENT_NAME,
  skeletonComponent: ProjectWorksheetImportMappingFormSkeleton,
  shouldRefetchOnQueryChange: false,
})(ProjectWorksheetImportMappingForm);

const mapStateToProps = (state) => {
  const viewState = getViewState(
    state, COMPONENT_NAME,
  );
  return {
    rowsCount: viewState.item.rowsCount,
    headers: viewState.item.headers,
  };
};

const ProjectWorksheetImportConnected = connect(
  mapStateToProps,
)(TdApiConnectedMappingForm);

export default ProjectWorksheetImportConnected;
