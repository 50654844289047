import React from 'react';
import { withRouter } from 'react-router-dom';

import { BS_STYLE } from 'core/assets/js/constants';
import { settingsPaymentsSubPageUrl } from 'settings/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import PayoneerStatus from 'settings/assets/js/components/payoneer-components/PayoneerStatus.jsx';

function ProfileManagePayoneerStatusView(props) {
  const { history, match: { params: { orgAlias } } } = props;
  const breadcrumbs = [
    {
      title: 'Payments',
      url: settingsPaymentsSubPageUrl(orgAlias),
    },
    {
      title: 'Payment method status',
      url: null,
    },
  ];
  return (
    <React.Fragment>
      <ContentHeader
        breadcrumbs={breadcrumbs}
      />
      <div className="page page--settings bank-account--payoneer-status">
        <div className="container">
          <PayoneerStatus />
          <div className="button-container mt-4 text-right">
            <TDButton
              label="Go to your bank accounts"
              variant={BS_STYLE.PRIMARY}
              onClick={() => {
                history.push(settingsPaymentsSubPageUrl(orgAlias));
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

ProfileManagePayoneerStatusView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ProfileManagePayoneerStatusView);
