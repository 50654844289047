import React from 'react';
import bytes from 'bytes';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { ICON, MIME_TYPES } from 'core/assets/js/constants';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import { taskAttachmentSpec } from 'projects/assets/js/lib/objectSpecs';
import { toggleDeliverablePinned, toggleDeliverableStatusToggled } from 'projects/assets/js/data-services/tasks';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import FilePreview from 'core/assets/js/components/FileUploader/FilePreview.jsx';

class TaskAttachmentCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleRemoval = this.handleRemoval.bind(this);
    this.handleDeliverableStatusToggle = this.handleDeliverableStatusToggle.bind(this);
    this.handleDeliverablePinToggled = this.handleDeliverablePinToggled.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  async handleRemoval() {
    const { onRemoved, item: attachment } = this.props;
    await onRemoved(attachment);
  }

  async handleDeliverablePinToggled() {
    const {
      item,
      match: { params: { orgAlias, id: projectId, taskId } },
      onDeliverablePinToggled,
    } = this.props;

    const deliverableId = item.id;
    const isPinned = !item.isPinned;

    const updated = await toggleDeliverablePinned({
      orgAlias,
      projectId,
      taskId,
      deliverableId,
      isPinned,
    });

    await onDeliverablePinToggled(updated);
  }

  async handleDeliverableStatusToggle() {
    const {
      item,
      match: { params: { orgAlias, id: projectId, taskId } },
      onDeliverableStatusToggled,
    } = this.props;

    const attachmentId = item.id;
    const isDeliverable = !item.isDeliverable;

    const updated = await toggleDeliverableStatusToggled({
      orgAlias,
      projectId,
      taskId,
      attachmentId,
      isDeliverable,
    });

    await onDeliverableStatusToggled(updated);
  }

  handleCopy() {
    const { onDeliverableCopied, item } = this.props;
    onDeliverableCopied(item);
  }

  render() {
    const {
      className,
      filePreviewClassName,
      invertColors,
      item,
      onDeliverableCopied,
      onRemoved,
      showDate,
      showPin,
    } = this.props;

    const {
      attachment: file,
      allowedActions: {
        canBeCopied,
        canBeDownloaded,
        canBeMarkedAsDeliverable,
        canBePinned,
        canBeRemoved,
        canBeUnmarkedAsDeliverable,
        canBeUnpinned,
      },
      isPinned,
      isDeliverable,
      createdAt,
    } = item;

    const classNames = ['task-attachment-card', 'fileuploader-item'];
    if (invertColors) {
      classNames.push('fileuploader-item--inverted-colors');
    }
    if (className) {
      classNames.push(className);
    }

    const filePreviewClasses = ['fileuploader-item__icon-wrapper'];
    if (MIME_TYPES.IMAGES.includes(file.mimetype)) {
      filePreviewClasses.push('fileuploader-item__icon-wrapper--image');
    }
    if (filePreviewClassName) {
      filePreviewClasses.push(filePreviewClassName);
    }

    const attachmentInfo = [];
    const actions = [];

    if (isDeliverable) {
      attachmentInfo.push(
        <span key="is-deliverable">Deliverable</span>,
      );
    }

    if (file.size) {
      if (!isEmpty(attachmentInfo)) {
        attachmentInfo.push(
          <span className="mx-2" key="size-separator">&bull;</span>,
        );
      }

      attachmentInfo.push(
        <span key="file-size">{bytes(file.size)}</span>,
      );
    }

    if (showDate) {
      if (!isEmpty(attachmentInfo)) {
        attachmentInfo.push(
          <span className="mx-2" key="date-separator">&bull;</span>,
        );
      }

      attachmentInfo.push(
        <span key="created-at">{formatDate(createdAt)}</span>,
      );
    }

    if (canBeDownloaded) {
      actions.push(
        <Dropdown.Item
          href={file.url}
          key="download-attachment"
          rel="noopener noreferrer"
          target="_blank"
          download
        >
          Download
        </Dropdown.Item>,
      );
    }

    if (canBeMarkedAsDeliverable || canBeUnmarkedAsDeliverable) {
      actions.push(
        <Dropdown.Item
          onClick={this.handleDeliverableStatusToggle}
          key="update-attachment-deliverable"
        >
          Mark as
          {' '}
          {isDeliverable ? 'file' : 'deliverable'}
        </Dropdown.Item>,
      );
    }

    if (canBeCopied && onDeliverableCopied) {
      actions.push(
        <Dropdown.Item
          key="copy-deliverable"
          onClick={this.handleCopy}
        >
          Copy
        </Dropdown.Item>,
      );
    }

    if (canBeRemoved && onRemoved) {
      actions.push(
        <Dropdown.Divider
          key="divider-for-dangerous-actions"
        />,
        <Dropdown.Item
          key="remove-attachment"
          onClick={this.handleRemoval}
          className="text-danger"
        >
          Remove
        </Dropdown.Item>,
      );
    }

    return (
      <div className={classNames.join(' ')}>
        <div className={`d-flex align-items-center ${isDeliverable ? 'task-attachment-card--deliverable' : ''}`}>
          <FilePreview
            className={filePreviewClasses.join(' ')}
            mimeType={file?.mimetype}
            url={file?.url}
          />
          <div className="task-attachment-card__details">
            <p className="mb-1 truncate">
              {!canBeDownloaded && (
                <span className="text-dark">
                  {file.filename}
                </span>
              )}
              {canBeDownloaded && (
                <a
                  href={file.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-dark"
                  download
                >
                  {file.filename}
                </a>
              )}
            </p>

            <p className="mb-0 task-attachment-card__meta">
              {attachmentInfo}
            </p>
          </div>

          {showPin && (canBePinned || canBeUnpinned) && (
            <TDElementWithTooltip
              tooltipMsg={isPinned ? 'Unpin' : 'Pin'}
            >
              <span
                onClick={this.handleDeliverablePinToggled}
                className="mr-4 pin-status"
              >
                <i className={`task-deliverable-status ${isPinned ? ICON.PIN_SOLID : ICON.PIN}`} />
              </span>
            </TDElementWithTooltip>
          )}

          {actions.length && (
            <TDDropButton
              className="ml-auto ml-sm-0"
              data-testid="task-attachment-card-actions"
              stopPropagation
            >
              {actions}
            </TDDropButton>
          )}
        </div>
      </div>
    );
  }
}

TaskAttachmentCard.propTypes = {
  item: taskAttachmentSpec.isRequired,
  showDate: PropTypes.bool,
  showPin: PropTypes.bool,
  className: PropTypes.string,
  filePreviewClassName: PropTypes.string,
  invertColors: PropTypes.bool,
  match: routerMatchSpec.isRequired,
  onRemoved: PropTypes.func,
  onDeliverableCopied: PropTypes.func,
  onDeliverableStatusToggled: PropTypes.func,
  onDeliverablePinToggled: PropTypes.func,
};

TaskAttachmentCard.defaultProps = {
  showDate: false,
  showPin: false,
  className: null,
  filePreviewClassName: null,
  invertColors: false,
  onRemoved: null,
  onDeliverableCopied: null,
  onDeliverableStatusToggled: () => {},
  onDeliverablePinToggled: () => {},
};

export default withRouter(TaskAttachmentCard);
