import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';

import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { projectUpdateTaskAssignmentCapDS } from 'projects/assets/js/data-services/tasks';
import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';

const TaskAssignmentChangeCapModal = ({ listViewComponentName, modalId }) => {
  const { id: projectId, orgAlias, taskId } = useParams();

  const dispatch = useDispatch();

  const isOpen = useSelector(state => getIsModalOpen(state, modalId));
  const payload = useSelector(state => getModalPayload(state, modalId));

  const closeModal = () => dispatch(modalCloseAC());

  const { assignment } = payload || {};

  if (!assignment) {
    return null;
  }

  return (
    <div className="static-modal">
      <Form
        initialValues={{ rate_max_billing_quantity: assignment.rateMaxBillingQuantity }}
        onSubmit={async values => { // eslint-disable-line consistent-return
          try {
            await dispatch(projectUpdateTaskAssignmentCapDS({
              assignmentId: assignment.id,
              assigneeUserId: assignment.user.id,
              componentName: listViewComponentName,
              orgAlias,
              projectId,
              taskId,
              values,
            }));
            toastr.success('Well Done!', 'Task assignment cap updated successfully.');
            closeModal();
          } catch (err) {
            if (err.errors) {
              return {
                [FORM_ERROR]: err.errors._error || 'Oops! Something went wrong. Please try again',
                ...omit(err.errors || {}, '_error', '_meta'),
              };
            }
            toastr.error('Oh Snap!', err._error || err.message);
          }
        }}
        render={({
          form: { getState },
          handleSubmit,
          values: { rate_max_billing_quantity: rateMaxBillingQuantity },
        }) => (
          <form onSubmit={handleSubmit}>
            <ModalSimple
              body={(
                <div>
                  <p>
                    If a cap is set the provider will not be able to submit a worksheet
                    claim more than the specified capped number of units
                  </p>
                  <div className="row mb-3">
                    <div className="col-8 col-md-10">
                      <InputNumberField
                        className="mb-0"
                        name="rate_max_billing_quantity"
                        placeholder="Capped value"
                        step="0.5"
                      />
                    </div>
                    <div className="col-4 col-md-2">
                      <p className="mt-3">
                        {RATE_UNIT_FORMAT[assignment.rate_unit].unit}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <p className="info-pill bg-pale-gray">
                        Maximum billable
                        <span className="info-pill--value">
                          {assignment.rate && rateMaxBillingQuantity && [
                            assignment.currencySymbol,
                            (parseFloat(assignment.rate) * rateMaxBillingQuantity).toFixed(2),
                          ].join(' ')}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              footer={[
                <TDButton
                  key={1}
                  className="float-right"
                  label="Set task assignment cap"
                  variant={BS_STYLE.PRIMARY}
                  disabled={getState().submitting || !rateMaxBillingQuantity}
                  onClick={handleSubmit}
                />,
              ]}
              heading="Edit task assignment cap"
              onClose={closeModal}
              open={isOpen}
            />
          </form>
        )}
      />
    </div>
  );
};

TaskAssignmentChangeCapModal.propTypes = {
  listViewComponentName: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
};

export default TaskAssignmentChangeCapModal;
