import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import ModalConfirmFinalForm from 'core/assets/js/components/ModalConfirmFinalForm.jsx';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { reOpenTaskAssignmentDS } from 'projects/assets/js/data-services/tasks';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';

export const MODAL_ID = 'task-re-open-modal';

const TaskReOpenModal = ({ listViewComponentName, parentComponentName, task }) => {
  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const { assignment } = useSelector(state => getModalPayload(state, MODAL_ID)) || {};
  const { orgAlias, id: projectId, taskId } = useParams();
  const dispatch = useDispatch();

  if (!assignment?.allowedActions?.canReOpen) {
    return null;
  }

  const onClose = () => dispatch(modalCloseAC());

  return (
    <ModalConfirmFinalForm
      confirmLabel="Re-open"
      data-testid="task-assignment-re-open-modal"
      FormContentComponent={({ renderProps }) => {
        const { submitError, submitting } = renderProps;
        return (
          <>
            <p>
              {`Are you sure you want to re-open the "${task.title}" assignment for `}
              {`${assignment.user.profile.name}?`}
            </p>
            <p>If the task is currently completed, it will be set back to in progress.</p>
            <TextAreaField
              disabled={submitting}
              label="Message"
              name="message"
              required
              sublabel="The reason why you are re-opening this assignment"
            />
            {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
          </>
        );
      }}
      formProps={{
        onSubmit: async values => { // eslint-disable-line consistent-return
          if (typeof values.message !== 'string' || values.message.length === 0) {
            return { message: 'Please enter the reason why you are re-opening this assignment' };
          }
          try {
            await dispatch(reOpenTaskAssignmentDS({
              componentName: parentComponentName,
              listViewComponentName,
              orgAlias,
              participantId: assignment.user.id,
              projectId,
              taskId,
              values,
            }));
            onClose();
          } catch (err) {
            const fieldErrors = err.errors ? omit(err.errors, '_error', '_meta') : {};
            if (Object.keys(fieldErrors).length > 0) {
              return fieldErrors;
            }
            return { [FORM_ERROR]: err.errors?._error || err.message };
          }
        },
      }}
      heading="Do you want to re-open this assignment?"
      onClose={onClose}
      open={isOpen}
    />
  );
};

TaskReOpenModal.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
  listViewComponentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
};

export default TaskReOpenModal;
