import React from 'react';
import { isEmpty, fromPairs } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { getListState, fetchListDS, listBulkUpdateAC } from 'core/assets/js/ducks/list';
import { BS_STYLE } from 'core/assets/js/constants';
import { fetchNotificationsApiUrl } from 'notifier/urls';
import {
  fetchSummariesDS, markAsSeenNotificationDS, markReadNotificationDS, markAllNotificationsReadDS,
} from 'notifier/assets/js/data-services/notifications';
import { notificationSpec } from 'notifier/assets/js/lib/objectSpecs';
import NotificationCard from 'notifier/assets/js/components/NotificationCard.jsx';
import { selectSummaries } from 'notifier/assets/js/ducks/notifications';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import { NOTIFICATION_GROUP_LABELS } from 'notifier/assets/js/constants';
import { COMPONENT_NAME as NOTIFICATIONS_DROPDOWN_COMPONENT_NAME } from 'notifier/assets/js/components/NotificationsDropdown.jsx';

class NotificationsView extends React.Component {
  static FetchData({ dispatch, url, authedAxios, componentName, querystring }) {
    const apiUrl = fetchNotificationsApiUrl(url);

    return Promise.all([
      dispatch(fetchListDS({ url: apiUrl, querystring, componentName, authedAxios })),
    ]);
  }

  static GetComponentName() {
    return 'NotificationsView';
  }

  constructor(props) {
    super(props);

    this.handleMarkAsRead = this.handleMarkAsRead.bind(this);
    this.handleMarkAllAsRead = this.handleMarkAllAsRead.bind(this);
    this.getSearchSpec = this.getSearchSpec.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { dispatch, newCount } = this.props;
    // Mark any new notifications as seen. The following conditions makes sure that a singe API
    // call is sent even when the component re-renders
    if (newCount > 0 && prevProps.newCount === 0) {
      dispatch(markAsSeenNotificationDS());
    }
  }

  getSearchSpec() { // eslint-disable-line class-methods-use-this
    const notificationGroupOptions = Object.keys(NOTIFICATION_GROUP_LABELS).map(value => ({
      text: NOTIFICATION_GROUP_LABELS[value],
      value,
    }));

    const filters = [{
      label: 'Type',
      paramName: 'type',
      fieldComponent: SelectableListFilterField,
      options: notificationGroupOptions,
    }];

    return { filters };
  }

  async handleMarkAllAsRead() {
    const { dispatch, notifications } = this.props;
    const componentName = this.constructor.GetComponentName();
    await dispatch(markAllNotificationsReadDS());
    const notificationIds = notifications.map(n => n.id);
    dispatch(listBulkUpdateAC(notificationIds, { is_read: true }, componentName));
    dispatch(fetchSummariesDS({ componentName: NOTIFICATIONS_DROPDOWN_COMPONENT_NAME }));
    toastr.success('Well Done!', 'You have succesfully marked all of your notifications as read.');
  }

  async handleMarkAsRead(ids) {
    const { dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();
    const pairs = ids.map(id => ([id, true]));
    const formData = fromPairs(pairs);
    const response = await dispatch(markReadNotificationDS({ values: formData }));
    if (!isEmpty(response?.read)) {
      dispatch(listBulkUpdateAC(response.read, { is_read: true }, componentName));
    }
    if (!isEmpty(response?.unread)) {
      dispatch(listBulkUpdateAC(response.unread, { is_read: false }, componentName));
    }
    dispatch(fetchSummariesDS({ componentName: NOTIFICATIONS_DROPDOWN_COMPONENT_NAME }));
  }

  render() {
    const { filtersOpen, location, notifications, onFiltersToggle, pagination } = this.props;
    const breadcrumbs = [
      {
        title: 'Notifications',
        url: null,
      },
    ];

    const ctaButtonItems = [{
      label: 'Mark all as read',
      onClick: this.handleMarkAllAsRead,
      variant: BS_STYLE.PRIMARY,
    }];

    const query = queryString.parse(location.search);

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

        <div className="page page--notifier">
          <div className="container">
            <SearchFinalForm
              filtersOpen={filtersOpen}
              name="notifications"
              onFiltersToggle={onFiltersToggle}
              query={query}
              searchSpec={this.getSearchSpec()}
            />
            <TDApiConnected duck="list" component={this.constructor}>
              <div className="rounded shadow-sm p-4 bg-white">
                <TDList
                  listClassName="notifications-list"
                  items={notifications}
                  pagination={pagination}
                  cardItem={{
                    component: NotificationCard,
                    props: {
                      onMarkAsRead: this.handleMarkAsRead,
                    },
                  }}
                  emptyListMessage="There aren't any notifications yet."
                />
              </div>
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
NotificationsView.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  notifications: PropTypes.arrayOf(notificationSpec),
  onFiltersToggle: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  newCount: PropTypes.number.isRequired,
  pagination: paginationSpec.isRequired,
};
NotificationsView.defaultProps = {
  notifications: [],
};

const mapStateToProps = (state) => {
  const listState = getListState(state, NotificationsView.GetComponentName());
  const summaryState = selectSummaries(state);
  return {
    notifications: listState.items,
    pagination: listState.pagination,
    newCount: summaryState.newCount,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFilters(NotificationsView)));
