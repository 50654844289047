import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import DragHandle from 'core/assets/js/components/DragHandle.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import ProfileExperienceItem from 'accounts/assets/js/components/ProfileExperienceItem.jsx';
import { ICON } from 'core/assets/js/constants';
import { profileCreateExperienceUrl } from 'accounts/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const DragHandleSortable = SortableHandle(() => <DragHandle classes="experience-list--item__drag-handle" />);

const SortableItem = SortableElement(props => (
  <ProfileExperienceItem {...props} />
));

function ProfileExperienceList({ activeOrg, canEditExperience, history, items }) {
  const hasItems = items.length >= 1;
  const classNames = [];
  if (canEditExperience) {
    classNames.push('p-4');
  }
  if (hasItems && canEditExperience) {
    classNames.push('rounded shadow-sm bg-white');
  }
  const canEditEmptyMessage = (
    <div className="experience-list empty-message-content text-center">
      <span className={`${ICON.PEOPLE_CLOCK} mb-3`} />
      <div className="empty-message">You haven&apos;t added any experience yet</div>
      <div
        className="imitate-link"
        onClick={() => history.push(profileCreateExperienceUrl(activeOrg.alias))}
      >
        Add your experience
      </div>
    </div>
  );

  const previewEmptyMessage = (
    <div className="empty-message-content py-2 px-2 mb-4">
      <i className="discreet">No experience records found.</i>
    </div>
  );
  if (!hasItems && canEditExperience) {
    return canEditEmptyMessage;
  }
  if (!hasItems && !canEditExperience) {
    return previewEmptyMessage;
  }

  if (!canEditExperience) {
    return (
      <TDList
        items={items}
        canEditExperience={canEditExperience}
        cardItem={{
          component: ProfileExperienceItem,
          props: {
            canEditExperience,
          },
        }}
        emptyListMessage={canEditExperience ? canEditEmptyMessage : previewEmptyMessage}
        listClassName="experience-list"
      />
    );
  }
  return (
    <div className={classNames.join(' ')}>
      <div className="page--settings__content clearfix">
        <div className="experience-list">
          {items.map((item, index) => (
            <SortableItem
              item={item}
              key={`$key-${item.id}`}
              index={index}
              canEditExperience={canEditExperience}
              dragHandle={DragHandleSortable}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

ProfileExperienceList.propTypes = {
  activeOrg: orgSpec.isRequired,
  canEditExperience: PropTypes.bool,
  history: PropTypes.object.isRequired,
  items: PropTypes.array,
};

ProfileExperienceList.defaultProps = {
  canEditExperience: false,
  items: [],
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const ProfileExperienceListConnected = connect(mapStateToProps)(ProfileExperienceList);

const SortableList = SortableContainer(ProfileExperienceListConnected);

export default withRouter(SortableList);
