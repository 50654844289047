import React from 'react';
import { Form } from 'react-final-form';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router';

import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import { contactsImportUrl, contactsUrl } from 'contacts/urls';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { uploaderContactsCsvPath } from 'core/urls';
import { parseJson } from 'core/assets/js/lib/utils';
import { CONTACTS_IMPORT_LIMIT, CONTACTS_IMPORT_STEP } from 'contacts/assets/js/constants';

const ContactsImportUploadForm = ({ match: { params: { orgAlias } }, history }) => (
  <Form
    onSubmit={(values) => {
      const parsed = values.attachments_metadata ? parseJson(values.attachments_metadata) : null;
      const fileHandle = (Array.isArray(parsed) && parsed.length >= 1)
        ? parsed[0].handle : null;
      return (
        history.push({
          pathname: contactsImportUrl(orgAlias, CONTACTS_IMPORT_STEP.MAPPING),
          search: `?id=${fileHandle}`,
        }));
    }}
    render={({ handleSubmit, pristine, submitting, form: { getState } }) => {
      const attachmentsFormState = getState('attachments_metadata');
      const attachmentsValue = attachmentsFormState?.values?.attachments_metadata;
      const parsed = attachmentsValue ? parseJson(attachmentsValue) : null;
      const fileHandle = (Array.isArray(parsed) && parsed.length >= 1)
        ? parsed[0].handle : null;
      return (
        <form
          onSubmit={handleSubmit}
        >
          <Card
            className="contacts-import--init"
          >
            <Card.Body>
              <h3 className="mb-3 mt-0">Import your contacts</h3>
              <p>
                You can import contacts from another software using a comma separated values file
                (CSV). For best results, make sure that the file has UTF-8 encoding.
              </p>
              <div className="row bg-light py-4 mx-0 mb-4 d-flex align-items-center">
                <div className="col">
                  To make the process easier, we have prepared a template file that you can use.
                  This file has the correct column headings TalentDesk.io needs to import your
                  contacts. Export your contacts from your old software as a comma
                </div>
                <a
                  className="col-auto d-flex justify-content-center mt-4 mt-sm-0 file-sample-link"
                  href="/file-samples/contactsCsvTemplate.csv"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <TDButton
                    variant={BS_STYLE.PRIMARY}
                    btnIcon={ICON.DOWNLOAD_CIRCLE}
                    rounded
                    label="CSV TEMPLATE"
                  />
                </a>
              </div>
              <FileUploaderField
                name="attachments_metadata"
                invertedColors
                type="text"
                label="Upload your CSV file"
                maxFiles={1}
                acceptFiles={['.csv', '.txt']}
                path={uploaderContactsCsvPath(orgAlias)}
              />
              <div className="important-info">
                <div className="mb-1">IMPORTANT</div>
                <ul className="pl-4">
                  <li>
                    The file you import must be a CSV (Comma Separated Values) file.
                  </li>
                  <li>
                    The name of your file should end with either .csv or .txt.
                  </li>
                  <li>
                    {'Your file\'s first row should include headers.'}
                  </li>
                  <li>
                    {`Maximum contacts to be uploaded at once are ${CONTACTS_IMPORT_LIMIT}.`}
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>
          <div className="row mt-5 ">
            <div className="col-12 text-right">
              <TDButton
                variant={BS_STYLE.DEFAULT}
                label="Cancel"
                onClick={() => history.push(contactsUrl(orgAlias))}
              />
              <TDButton
                className={`position-tweak ${pristine ? 'btn--pristine' : 'btn--dirty'}`}
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={submitting || pristine || !fileHandle}
                label="Next"
              />
            </div>
          </div>
        </form>
      );
    }}
  />
);

ContactsImportUploadForm.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ContactsImportUploadForm);
