import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';

import AsyncSelectionField from 'core/assets/js/components/FinalFormFields/AsyncSelectionField.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import {
  ICON, IMG_SIZE, USER_CARD_STATUS, USER_CARD_STATUS_VALUES,
} from 'core/assets/js/constants';
import axios from 'core/assets/js/lib/tdAxios';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { orgPeopleListApiUrl, userGroupsListApiUrl } from 'people/urls';

const MultiValueRenderer = ({ children, data: { data }, selectProps, ...props }) => {
  return (
    <components.MultiValueContainer {...props}>
      <div className="cursor-default d-flex align-items-center">
        {typeof data?.membersCount === 'undefined'
          ? (
            <ProfilePic
              alt={`${data?.user?.profile}'s avatar`}
              size={[IMG_SIZE.ICON, IMG_SIZE.ICON]}
              url={data?.user?.profile?.avatar}
            />
          )
          : (
            <div className="react-select__group-icon"><i className={ICON.USERS} /></div>
          )
        }
        <span className="ml-2">
          {data?.user?.profile?.name || `${data.name} (${data.membersCount})`}
        </span>
      </div>
    </components.MultiValueContainer>
  );
};

MultiValueRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  selectProps: PropTypes.shape({
    setSelectedValues: PropTypes.func.isRequired,
    selectedValues: PropTypes.array.isRequired,
  }).isRequired,
};

const GroupRenderer = (props) => (
  <div><components.Group {...props} className="react-select__group px-3" /></div>
);

const GroupHeadingRenderer = (props) => (
  <components.GroupHeading {...props} className="react-select__group-heading" />
);

const OptionRenderer = ({ data: { data: item }, innerProps }) => (
  <div
    {...innerProps}
    className={(
      'react-select__group-item d-flex align-items-center w-100 py-2 px-3 justify-content-between'
    )}
    key={item?.id}
  >
    <div>
      {typeof item?.membersCount === 'undefined'
        ? (
          <ProfilePic url={item?.user?.profile?.avatar} size={[IMG_SIZE.ICON, IMG_SIZE.ICON]} />
        )
        : (
          <div className="react-select__group-icon"><i className={ICON.USERS} /></div>
        )
      }
      <span className="mx-3">
        {item?.user?.profile?.name || `${item?.name} (${item?.membersCount})`}
      </span>
    </div>
  </div>
);

OptionRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
};

const formatUserCardOptions = userCard => ({
  data: userCard, isUserCard: true, label: userCard?.user?.profile?.name, value: userCard.user.id,
});

const UserAndGroupSearchField = ({
  excludeLoggedInUser,
  excludeManagers,
  excludeOrgOwner,
  label,
  name,
  required,
  sublabel,
  userCardStatus,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);
  const isAnyManager = activeUserCard?.userRole?.isAnyManager;

  const loadOptions = async kw => {
    const canViewProviders = (isAnyManager || activeOrg.should_provider_view_other_providers);
    const canViewManagers = isAnyManager || activeOrg.providers_can_see_managers;
    const includeManagers = !excludeManagers && canViewManagers;
    const canViewUserGroups = isAnyManager;

    const getPeopleSearch = peopleType => {
      const requestConfig = { params: { kw, status: userCardStatus } };
      if (peopleType === PEOPLE_TYPE.MANAGERS && excludeOrgOwner) {
        requestConfig.params.excludeOrgOwner = 1;
      }
      if (excludeLoggedInUser) {
        requestConfig.params.exclude = [activeUserCard.user.id];
      }
      return axios.get(
        orgPeopleListApiUrl({ orgAlias: activeOrg.alias, peopleType }), requestConfig,
      );
    };

    const [providersResult, managersResult, groupsResult] = await Promise.all([
      canViewProviders ? getPeopleSearch(PEOPLE_TYPE.PROVIDERS) : Promise.resolve({
        data: { items: [] },
      }),
      includeManagers ? getPeopleSearch(PEOPLE_TYPE.MANAGERS) : Promise.resolve({
        data: { items: [] },
      }),
      !canViewUserGroups ? Promise.resolve([]) : axios.get(
        userGroupsListApiUrl({ orgAlias: activeOrg.alias }), { params: { kw } },
      ),
    ]);

    const providers = providersResult?.data?.items || [];
    const managers = managersResult?.data?.items || [];
    const groups = groupsResult?.data?.items || [];

    // Remove the currently logged in user from either result set
    const userCardIndexInProviders = providers.findIndex(p => p.id === activeUserCard.id);
    if (userCardIndexInProviders > -1) {
      providers.splice(userCardIndexInProviders, 1);
    } else {
      const userCardIndexInManagers = managers.findIndex(m => m.id === activeUserCard.id);
      if (userCardIndexInManagers > -1) {
        managers.splice(userCardIndexInManagers, 1);
      }
    }

    const result = [{ label: 'Providers', options: providers.map(formatUserCardOptions) }];

    if (includeManagers) {
      result.push({ label: 'Managers', options: managers.map(formatUserCardOptions) });
    }

    result.push({
      label: 'Groups',
      options: groups.map(g => ({ data: g, isGroup: true, label: g.name, value: g.id })),
    });

    return result;
  };

  const loadOptionsDebounced = useCallback(
    debounce((kw, callback) => {
      loadOptions(kw).then(options => callback(options));
    }, 500),
    [],
  );

  return (
    <div className="form-group">
      <TDLabel
        label={label}
        name={name}
        required={required}
        sublabel={sublabel}
      />
      <div className="form-control-container custom-select-field mb-4">
        <div>
          <AsyncSelectionField
            className="select-people-menu"
            closeMenuOnSelect={false}
            components={{
              Group: GroupRenderer,
              GroupHeading: GroupHeadingRenderer,
              MultiValueLabel: MultiValueRenderer,
              Option: OptionRenderer,
            }}
            defaultOptions={loadOptions}
            extraConfiguration={{
              noOptionsMessage: ({ inputValue: searchValue }) => {
                if (searchValue === '') {
                  return 'Start typing a user or group name...';
                }
                return 'No results found';
              },
            }}
            isMultiple
            isSearchable
            loadOptions={loadOptionsDebounced}
            name={name}
            required={required}
          />
        </div>
      </div>
    </div>
  );
};

UserAndGroupSearchField.propTypes = {
  excludeLoggedInUser: PropTypes.bool,
  excludeManagers: PropTypes.bool,
  excludeOrgOwner: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
  userCardStatus: PropTypes.oneOfType([
    PropTypes.oneOf(USER_CARD_STATUS_VALUES),
    PropTypes.arrayOf(PropTypes.oneOf(USER_CARD_STATUS_VALUES)),
  ]),
};

UserAndGroupSearchField.defaultProps = {
  excludeLoggedInUser: true,
  excludeManagers: false,
  excludeOrgOwner: true,
  required: false,
  sublabel: null,
  userCardStatus: USER_CARD_STATUS.APPROVED,
};

export default UserAndGroupSearchField;
