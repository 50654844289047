import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import { noteSpec } from 'people/assets/js/lib/objectSpecs';
import NoteCard from 'people/assets/js/components/NoteCard.jsx';

const NoteList = ({ notes, viewerId, onEditNote, onDeleteNote }) => {
  const notesTable = orderBy(notes, 'created_at').map(note => (
    (
      <NoteCard
        subjectId={note.subject_id}
        attachments={note.attachments}
        attachments_metadata={note.attachments_metadata}
        authorId={note.author_id}
        avatar={note.author.profile.avatar}
        body={note.body}
        createdAt={note.created_at}
        firstName={note.author.profile.firstName}
        id={note.id}
        jobTitle={note.author.profile.jobTitle}
        key={note.id}
        lastName={note.author.profile.lastName}
        onDeleteNote={onDeleteNote}
        onEditNote={onEditNote}
        viewerId={viewerId}
      />
    )
  ));

  if (notes.length === 0) {
    return (
      <div className="empty-list-message text-center discreet py-4">
        No notes found
      </div>
    );
  }

  return (
    <div>
      {notesTable}
    </div>
  );
};


NoteList.propTypes = {
  notes: PropTypes.arrayOf(noteSpec).isRequired,
  onDeleteNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  viewerId: PropTypes.number.isRequired,
};

export default NoteList;
