import PropTypes from 'prop-types';
import React from 'react';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import { ORG_SETTINGS_FINANCE_TABS } from 'settings/assets/js/constants';
import { orgSettingsFinanceUrl } from 'settings/urls';

/**
 * This is the tabs component we use on Organization -> Settings -> Payments
 * @returns {*}
 */
const OrgSettingsFinanceTabs = ({ orgAlias, tab }) => {
  const tabSpec = [
    {
      key: ORG_SETTINGS_FINANCE_TABS.WORKSHEETS,
      label: 'Service Orders',
      href: orgSettingsFinanceUrl(orgAlias, ORG_SETTINGS_FINANCE_TABS.WORKSHEETS),
    },
    {
      key: ORG_SETTINGS_FINANCE_TABS.INVOICES,
      label: 'Invoices',
      href: orgSettingsFinanceUrl(orgAlias, ORG_SETTINGS_FINANCE_TABS.INVOICES),
    },
    {
      key: ORG_SETTINGS_FINANCE_TABS.PAYMENT_METHODS,
      label: 'Payment Methods',
      href: orgSettingsFinanceUrl(orgAlias, ORG_SETTINGS_FINANCE_TABS.PAYMENT_METHODS),
    },
    {
      key: ORG_SETTINGS_FINANCE_TABS.TIME_TRACKER,
      label: 'Time tracker',
      href: orgSettingsFinanceUrl(orgAlias, ORG_SETTINGS_FINANCE_TABS.TIME_TRACKER),
    },
  ];

  return (
    <div className="horizontal-scroll">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

OrgSettingsFinanceTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

export default OrgSettingsFinanceTabs;
