import { REQ_TYPE } from 'core/assets/js/constants';
import { fetchDataDS, pushDataDS  } from 'core/assets/js/lib/dataServices';
import {
  roleAddUsersApiUrl,
  roleCreateApiUrl,
  roleDeleteApiUrl,
  roleFetchApiUrl,
  roleRemoveUsersApiUrl,
  roleUpdateApiUrl,
} from 'roles/urls';
import { toastr } from 'react-redux-toastr';
import { viewFetchAC, viewUpdateAC } from 'core/assets/js/ducks/view';

export const fetchRoleDS = ({ componentName, orgAlias, roleId, values }) => dispatch =>
  dispatch(
    fetchDataDS({
      fetchApiUrl: () => roleFetchApiUrl(orgAlias, roleId),
      fetchDataAC: responseData => viewFetchAC(responseData, componentName),
      reqType: REQ_TYPE.POST,
      values,
    }),
  );

export const createRoleDS = ({ orgAlias, values }) => dispatch =>
  dispatch(
    pushDataDS({
      pushApiUrl: roleCreateApiUrl(orgAlias),
      reqType: REQ_TYPE.POST,
      values,
    }),
  );

export const updateRoleDS = ({ componentName, orgAlias, roleId, values }) => dispatch => (
  dispatch(pushDataDS({
    pushApiUrl: roleUpdateApiUrl(orgAlias, roleId),
    pushDataAC: responseData => viewUpdateAC(responseData, componentName),
    reqType: REQ_TYPE.PUT,
    values,
  }))
);

export const deleteRoleDS = ({ orgAlias, roleId }) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: roleDeleteApiUrl(orgAlias, roleId),
  }))
    .then(() => {
      toastr.success('Well Done!', 'Role deleted successfully.');
    })
    .catch(() => {
      toastr.error('Role was not deleted!');
    })
);

export const addUsersToRoleDS = ({ orgAlias, roleId, userIds }) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: roleAddUsersApiUrl(orgAlias, roleId),
    values: userIds,
  }))
);

export const removeUsersFromRoleDS = ({ orgAlias, roleId, userIds }) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: roleRemoveUsersApiUrl(orgAlias, roleId),
    values: userIds,
  }))
    .catch(() => {
      toastr.error('Could not remove users from role.');
    })
);
