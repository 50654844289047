import PropTypes from 'prop-types';
import React  from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams, Link } from 'react-router-dom';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, DATETIME_FORMAT_ISO, ICON, PROJECT_TABS } from 'core/assets/js/constants';
import { listReplaceItemAC } from 'core/assets/js/ducks/list';
import axios from 'core/assets/js/lib/tdAxios';
import { formatDate, getDatetime, parseDate } from 'core/assets/js/lib/utils';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { postCreateChat } from 'people/assets/js/data-services/form';
import { orgPeopleMessagingUrl } from 'people/urls';
import ProjectApplicationStatusBar from 'projects/assets/js/components/ProjectApplicationStatusBar.jsx';
import ProjectBriefDescription from 'projects/assets/js/components/ProjectBriefDescription.jsx';
import { projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  opportunityViewUrl,
  projectOpportunitiesApplyUrl,
  projectCloseApplicationsApiUrl,
  projectApplicationUrl,
  projectApplicationEditUrl,
  projectOpportunityEditUrl,
  projectStartApplicationsApiUrl,
} from 'projects/urls';

const ProjectOpportunityCardItem = ({ item: project, listComponentName }) => {
  const dispatch = useDispatch();
  const { orgAlias } = useParams();
  const history = useHistory();
  const { userRole: { isAnyManager } } = useSelector(selectActiveUserCard);

  const {
    allowedActions: {
      canCloseApplications,
      canEditProject,
      canManageInvitations,
      canStartApplications,
      canViewProjectDetails,
      canApply,
      canReApply,
    },
    applicants,
    application,
    applicationsCount,
    created_at,
    deadline,
    hasAtleastOneApplication,
    id,
    is_accepting_applicants: isAcceptingApplicants,
    max_applicants,
    owner_id: ownerId,
    owner_name,
    skills,
    title,
  } = project;

  const ageInDays = getDatetime().diff(parseDate(created_at, DATETIME_FORMAT_ISO), 'days');

  const actions = [];
  if (isAcceptingApplicants && canApply && !canManageInvitations) {
    actions.push({
      label: canReApply ? 'Reapply' : 'Apply',
      onClick: async () => {
        history.push(
          canReApply
            ? projectApplicationEditUrl(orgAlias, id, application.id)
            : projectOpportunitiesApplyUrl(orgAlias, id),
        );
      },
    });
  }
  if (application) {
    actions.push({
      label: 'View application',
      onClick: async () => {
        history.push(projectApplicationUrl(orgAlias, id, application.id));
      },
    });
  }
  if (canManageInvitations && (isAcceptingApplicants || hasAtleastOneApplication)) {
    actions.push({
      label: 'View applications',
      onClick: async () => {
        history.push(opportunityViewUrl(orgAlias, id, PROJECT_TABS.APPLICATIONS));
      },
    });
  }
  if (canEditProject) {
    actions.push({
      label: 'Edit opportunity',
      onClick: async () => {
        history.push(projectOpportunityEditUrl(orgAlias, id));
      },
    });
  }
  if (canStartApplications) {
    actions.push({
      label: 'Start applications',
      onClick: async () => {
        try {
          const { data: { project: updatedProject } } = await axios.put(
            projectStartApplicationsApiUrl(orgAlias, id),
          );
          dispatch(listReplaceItemAC(updatedProject, listComponentName));
          toastr.success('Well Done!', 'Successfully started applications');
        } catch (err) {
          toastr.error('Oh Snap!', err.response?.data?._error || err.message);
        }
      },
    });
  }
  if (canCloseApplications) {
    actions.push({
      label: 'Close applications',
      onClick: async () => {
        try {
          const { data: { project: updatedProject } } = await axios.put(
            projectCloseApplicationsApiUrl(orgAlias, id),
          );
          dispatch(listReplaceItemAC(updatedProject, listComponentName));
          toastr.success('Well Done!', 'Successfully closed applications');
        } catch (err) {
          toastr.error('Oh Snap!', err.response?.data?._error || err.message);
        }
      },
    });
  }

  const hasActions = actions.length > 0;

  const cardHeader = (
    <div
      className={(
        'd-flex flex-column flex-md-row align-items-start align-items-md-center '
        + 'justify-content-between'
      )}
    >
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <h3 className="project-opportunities-card__project-title">
            {canViewProjectDetails && (
              <Link to={opportunityViewUrl(orgAlias, id)}>{title}</Link>
            )}
            {!canViewProjectDetails && title}
          </h3>
          {!isAnyManager && ageInDays < 7 && (
            <div className="new p-2 ml-3 font-weight-bold">New</div>
          )}
        </div>
        <aside className="discreet">
          <div className="mb-2">
            {`Posted on ${formatDate(created_at)} by ${owner_name}`}
          </div>

          <div className="d-flex align-items-center">
            { applicants && applicants.length > 0 && (
              <div className="project-opportunities-card__applicants mr-3">
                { applicants.slice(0, 5).map((
                  { user: { id: applicantId, profile: { avatar, name } } },
                ) => (
                  <ProfilePic
                    key={applicantId}
                    url={avatar}
                    alt={name}
                    size={[20, 20]}
                  />
                ))}
              </div>
            )}

            {isAnyManager && max_applicants > 0 && (
              <span className="mr-4">
                {`${applicationsCount || 0}/${max_applicants} applicants`}
              </span>
            )}

            {deadline && (
              <span>
                {`Deadline ${formatDate(deadline)}`}
              </span>
            )}
          </div>
        </aside>
      </div>

      <div className="d-flex mt-3 mt-0">
        {(canApply || application) && (
          <TDButton
            btnIcon={ICON.COMMENTS}
            className="px-3"
            onClick={() => {
              dispatch(postCreateChat({
                orgAlias,
                pushDataAC: response => {
                  history.push(orgPeopleMessagingUrl(orgAlias, response.id));
                  return [];
                },
                values: { title: `Discuss opportunity "${title}"`, users: [ownerId] },
              }));
            }}
          />
        )}

        {!isAcceptingApplicants && !application && !canManageInvitations && (
          <StatusTag
            label="Applications closed"
            statusClass={`preview${hasActions ? ' mr-5' : ''}`}
          />
        )}

        {canManageInvitations && !isAcceptingApplicants && !hasAtleastOneApplication && (
          <StatusTag
            label="Not accepting applicants"
            statusClass={`preview${hasActions ? ' mr-5' : ''}`}
          />
        )}

        {hasActions && (
          <SplitButton actions={actions} primaryButtonVariant={BS_STYLE.DEFAULT} />
        )}
      </div>
    </div>
  );

  const headingChildren = (
    <div className="col p-0">
      <SkillList
        skills={skills}
        inline
        maxShownItems={10}
        modalId={`project-skills-${id}`}
      />
    </div>
  );

  const contentChildren = (
    <React.Fragment>
      <ProjectBriefDescription
        project={project}
        rateGuideClassName="mb-5 float-none float-md-right"
        showSkills={false}
      />

      <h4 className="mt-5 mb-2">Skills</h4>
    </React.Fragment>
  );

  return (
    <Card
      className="project-opportunities-card"
    >
      <Card.Header className="pb-3 position-relative">
        {cardHeader}

        { application && (
          <ProjectApplicationStatusBar application={application} />
        )}
      </Card.Header>

      <Card.Body className="py-3">
        <AreaCollapsible
          headingClassName="m-0 row p-0"
          contentChildren={contentChildren}
          headingChildren={headingChildren}
          toggleAfterCard
          togglePrefix={<div className="mr-3">Read more</div>}
        />
      </Card.Body>
    </Card>
  );
};

ProjectOpportunityCardItem.propTypes = {
  item: projectApplicationSpec.isRequired,
  listComponentName: PropTypes.string.isRequired,
};

export default ProjectOpportunityCardItem;
