import React from 'react';
import PropTypes from 'prop-types';

import { ICON, BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { skillDirectorySkillsApiUrl } from 'settings/urls';
import { fetchListDS } from 'core/assets/js/ducks/list';
import TDList from 'core/assets/js/components/TDList.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import SkillTag from 'core/assets/js/components/SkillTag.jsx';

function SkillDirectorySkillsList({
  items, onAddSkillsToDirectory, onRemoveSkillFromDirectory, isAnyManager,
}) {
  return (
    <div className="skill-directory-skills">
      <div className="rounded shadow-sm p-4 bg-white">
        <h3 className="heading-block">Skills</h3>
        <TDList
          items={items.map(item => ({
            ...item.skill,
            skillDirectorySkillId: item.id,
          }))}
          cardItem={{
            component: ({ skill }) => (
              <SkillTag
                skill={skill}
                onRemove={onRemoveSkillFromDirectory}
              />
            ),
          }}
          groupByFirstLetter
          emptyListMessage={(
            <div className="discreet">
              <div className="d-flex flex-column align-items-center justify-content-center empty-list-message p-5">
                <i className={`${ICON.PEN_RULER_DUOTONE} mb-0`} />
                <p className="my-5">
                  You haven’t added any skills yet
                </p>
                { isAnyManager && (
                  <TDButton
                    variant={BS_STYLE.PRIMARY}
                    onClick={onAddSkillsToDirectory}
                  >
                    Manage skills
                  </TDButton>
                )}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}

SkillDirectorySkillsList.propTypes = {
  isAnyManager: PropTypes.bool,
  items: PropTypes.array,
  onAddSkillsToDirectory: PropTypes.func.isRequired,
  onRemoveSkillFromDirectory: PropTypes.func.isRequired,
};

SkillDirectorySkillsList.defaultProps = {
  isAnyManager: false,
  items: [],
};

const SkillDirectorySkillsListWithTDApi = withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, componentName, querystring, params,
  }) => {
    const { skillDirectoryId, orgAlias } = params;
    return dispatch(fetchListDS({
      authedAxios, componentName, querystring,
      url: skillDirectorySkillsApiUrl(
        orgAlias,
        skillDirectoryId,
      ),
    }));
  },
  duck: 'list',
  storeKey: 'SkillDirectorySkillsList',
  skeletonComponent: PeopleListSkeleton,
})(SkillDirectorySkillsList);

export default SkillDirectorySkillsListWithTDApi;
