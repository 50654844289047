import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { viewDocumentAssignment } from 'core/assets/js/lib/utils-jsx';
import { documentAssignmentGetPDFCopyApiUrl } from 'documents/urls';

const DocumentAssignmentViewDropdownItem = ({ assignment }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orgAlias } = useParams();

  if (!assignment.allowedActions?.canView) {
    return null;
  }

  return (
    <>
      <Dropdown.Item
        key="view-document-assignment"
        onClick={e => {
          e.stopPropagation();
          viewDocumentAssignment(orgAlias, assignment, history, location.pathname, dispatch);
        }}
      >
        View
      </Dropdown.Item>
      {assignment.document?.signNowDocumentId && (
        <Dropdown.Item
          key="download-document-assignment"
          onClick={e => {
            e.stopPropagation();
            WINDOW_OPEN(
              documentAssignmentGetPDFCopyApiUrl(orgAlias, assignment.id),
              '_blank',
            );
          }}
        >
          Download
        </Dropdown.Item>
      )}
    </>
  );
};

DocumentAssignmentViewDropdownItem.propTypes = {
  assignment: PropTypes.object.isRequired,
};

export default DocumentAssignmentViewDropdownItem;
