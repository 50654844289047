import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const AcceptInvitationButton = ({ hasPendingDocuments, label, onClick }) => (
  <TDButton
    onClick={onClick}
    variant={BS_STYLE.SUCCESS}
    label={hasPendingDocuments ? 'Confirm and accept' : label}
  />
);

AcceptInvitationButton.propTypes = {
  hasPendingDocuments: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
AcceptInvitationButton.defaultProps = {
  hasPendingDocuments: false,
};

export default AcceptInvitationButton;
