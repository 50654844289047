import Big from 'big.js';
import moment from 'moment';

import { RATE_SUGGEST_VALUE, RATE_UNIT } from 'rates/assets/js/constants';
import { TASK_WORKSHEET_DISABLE_REASON_LABEL } from 'projects/assets/js/constants';

Big.RM = 1;
Big.DP = 2;

export const getProjectBudgetBreakdownSkeleton = function () {
  const zeroBudget = new Big(0).toFixed(2);
  return {
    // Need to keep it null instead of 0
    total: null,
    totalApproved: null,
    used: zeroBudget,
    available: zeroBudget,
    pending: zeroBudget,
    refunded: zeroBudget,
  };
};

export const isInDateRange = ({ date, startDate, endDate }) => (
  moment(date).isSameOrAfter(startDate, 'day') && moment(date).isSameOrBefore(endDate, 'day')
);

/**
 * Formats a task (or array of tasks) into the structure required to be added as a worksheet item
 *
 * @param {Task|Task[]} task
 * @param {Object[]} tasksWorksheetAnalytics
 * @returns {Object[]}
 */
export const formatTasksForWorksheet = (task, tasksWorksheetAnalytics) => {
  const tasks = Array.isArray(task) ? task : [task];

  return tasks
    .map(t => {
      const { accessControl, assignments, description, id, status, title } = t;
      const assignment = assignments && assignments[0] ? assignments[0] : {};
      const {
        currency, currencySymbol, rate, rate_unit, rate_id, rateMaxBillingQuantity,
      } = assignment;

      const taskWorksheetAnalytics = tasksWorksheetAnalytics && tasksWorksheetAnalytics.find(({
        task_id: taskId,
      }) => taskId === id);
      const rateIsFixed = rate_unit === RATE_UNIT.FIXED;

      const taskItem = {
        accessControl,
        currency,
        currencySymbol,
        description,
        id,
        quantity: rateIsFixed ? 1 : null,
        rate_amount: rate,
        rateBilledQuantity: taskWorksheetAnalytics ? taskWorksheetAnalytics.rateBilledQuantity : 0,
        rate_id: rate_id || null,
        rate_unit: rate_unit || null,
        rateMaxBillingQuantity,
        status,
        task_id: id,
        title,
      };

      if (accessControl && accessControl.reason) {
        taskItem.warning = TASK_WORKSHEET_DISABLE_REASON_LABEL[accessControl.reason];
      }

      return taskItem;
    })
    .sort(item => -item.accessControl.canCreateWorksheetFor);
};

/**
 * Determine the selected rate amount, unit and currency
 *
 * Rules to determine the above information:
 * - initially the form displays the rate information associated to the user's projectMember
 * - the user might select a rate from their list, in this case the form shows the selected
 *   rate information
 * - the user might select to suggest a new rate, in the case the form prompts the user to
 *   suggest a new rate amount
 *
 * @param {Object} options
 * @param {Number} options.defaultOrgRateUnit
 * @param {Number} [options.defaultRateAmount]
 * @param {Number} [options.defaultRateUnit]
 * @param {Object[]} options.rates
 * @param {Number} [options.selectedRateId]
 * @returns {Object}
 */
export const determineSelectedRate = ({
  defaultOrgRateUnit, defaultRateAmount, defaultRateUnit, rates, selectedRateId,
}) => {
  if (!selectedRateId) {
    return null;
  }

  let selectedRate;
  let selectedRateAmount;
  let selectedRateUnit;
  let suggestNewRate = false;

  // The user selected a rate from their list
  if (rates && selectedRateId) {
    suggestNewRate = selectedRateId === RATE_SUGGEST_VALUE;

    // The user selected one of their agreed rates
    if (!suggestNewRate) {
      selectedRate = rates.find(r => parseInt(r.id, 10) === parseInt(selectedRateId, 10));
      if (selectedRate) {
        selectedRateAmount = selectedRate.amount;
        selectedRateUnit = selectedRate.unit;
      } else {
        selectedRateUnit = RATE_UNIT.FIXED;
      }
    } else { // The user wants to suggest a new rate
      selectedRateUnit = RATE_UNIT.FIXED;
    }
  } else {
    // The user hasn't selected a rate from their list, keep the projectMember rate details
    selectedRateAmount = defaultRateAmount;
    selectedRateUnit = defaultRateUnit || defaultOrgRateUnit;
  }

  return {
    selectedRate,
    selectedRateAmount,
    selectedRateUnit,
  };
};

/**
 * Determines the selected rate amount and unit
 *
 * @param {Object} options
 * @param {Object} options.exchangeRates
 * @param {Object} options.item
 * @param {Object} [options.selectedRate]
 * @param {String} options.serviceOrderCurrency
 * @returns {Object}
 */
export const getSelectedRateAmountAndUnit = ({
  exchangeRates, item, selectedRate, serviceOrderCurrency,
}) => {
  let { currency, rate_amount: selectedRateAmount, rate_unit: selectedRateUnit } = item;

  const isTaskItem = !!item.task_id;

  if (!isTaskItem && selectedRate) {
    if (selectedRate.selectedRateAmount) {
      selectedRateAmount = selectedRate.selectedRateAmount;
    }
    if (selectedRate.selectedRateUnit) {
      selectedRateUnit = selectedRate.selectedRateUnit;
    }
    if (selectedRate.selectedRate && selectedRate.selectedRate.currency) {
      currency = selectedRate.selectedRate.currency;
    }
  }

  const isSuggestedAmount = item.rate_id === RATE_SUGGEST_VALUE;
  const isCommissionRateUnit = selectedRateUnit === RATE_UNIT.COMMISSION;

  if (!selectedRateAmount && (item.rate_unit === RATE_UNIT.FIXED || isSuggestedAmount)) {
    // as the amount is fixed and does not relate to a rate, get it from the item
    selectedRateAmount = item.rate_amount;
  }

  if (
    selectedRateAmount
    && !isCommissionRateUnit
    // if the item is project work with "Claim an amount", then don't currency convert
    // as the user is entering the amount in the worksheet's currency
    && (!isSuggestedAmount || item.task_id)
    && currency !== serviceOrderCurrency
    && exchangeRates
    && exchangeRates[currency]
  ) {
    const itemExchangeRate = exchangeRates[currency][serviceOrderCurrency];
    if (itemExchangeRate) {
      // getDisplayRate will format to the correct decimal places
      selectedRateAmount = Big(selectedRateAmount).times(itemExchangeRate).toNumber();
    }
  }

  return { selectedRateAmount, selectedRateUnit };
};

/**
 * Determines the total from the items
 *
 * @param {Object} options
 * @param {Number} options.defaultOrgRateUnit
 * @param {Number} [options.defaultRateAmount] from the user's project member record
 * @param {Number} [options.defaultRateUnit] from the user's project member record
 * @param {Object} options.exchangeRates
 * @param {Object[]} [options.items]
 * @param {Object[]} options.rates
 * @param {String} options.serviceOrderCurrency
 * @returns {Number}
 */
export const calculateServiceOrderTotalAmount = ({
  defaultOrgRateUnit,
  defaultRateAmount,
  defaultRateUnit,
  exchangeRates,
  items,
  rates,
  serviceOrderCurrency,
}) => {
  const sum = (items || []).reduce((sumAmount, item) => {
    const selectedRateResponse = determineSelectedRate({
      defaultOrgRateUnit,
      defaultRateAmount,
      defaultRateUnit,
      rates,
      selectedRateId: item.rate_id,
    });
    const { selectedRateAmount } = getSelectedRateAmountAndUnit({
      exchangeRates,
      item,
      selectedRate: selectedRateResponse,
      serviceOrderCurrency,
    });

    // Keep the decimal precision of the total to 2 digits
    const total = new Big(parseFloat(selectedRateAmount || 0)).mul(parseFloat(item.quantity || 0))
      .toFixed(2);

    return new Big(sumAmount).plus(total).toFixed(2);
  }, Big(0).toFixed(2));

  return sum;
};
