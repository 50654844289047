import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { claimTaskOwnershipDS } from 'projects/assets/js/data-services/tasks';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { COMPONENT_NAME } from 'projects/assets/js/ProjectTaskView.jsx';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';

class ClaimTaskOwnership extends React.Component {
  constructor(props) {
    super(props);

    this.handleTaskOwnershipClaimed = this.handleTaskOwnershipClaimed.bind(this);
  }

  handleTaskOwnershipClaimed() {
    const {
      dispatch, match: { params: { orgAlias, id: projectId } }, task,
    } = this.props;

    return dispatch(claimTaskOwnershipDS({
      orgAlias,
      projectId,
      taskId: task.id,
      componentName: COMPONENT_NAME,
    })).then(() => {
      toastr.success('Well Done!', 'You’re now the owner of this task!');
    }).catch((err) => {
      let message = 'Something went wrong, please try again';
      if (err.errors && err.errors._error) {
        message = err.errors._error;
      }
      toastr.error('Oops!', message);
    });
  }

  render() {
    const { buttonLabel } = this.props;

    return (
      <ConfirmationButton
        buttonStyle={BS_STYLE.LINK}
        buttonSize={BS_SIZE.SMALL}
        buttonClass="imitate-link"
        buttonLabel={buttonLabel}
        modalProps={{
          heading: 'Are you sure you want to claim ownership of this task?',
          body: (
            <React.Fragment>
              <p>
                Once you become the new owner, you’ll be able to update the task’s assignments,
                descriptions and attachments. The current task owner, will be able to take over
                again if they wish.
              </p>
              <p>
                Are you sure you want to claim ownership of this task?
              </p>
            </React.Fragment>
          ),
          confirmLabel: 'Yes',
          cancelLabel: 'No',
          onConfirm: this.handleTaskOwnershipClaimed,
        }}
      />
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

ClaimTaskOwnership.propTypes = {
  dispatch: PropTypes.func.isRequired,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  match: routerMatchContentsSpec.isRequired,
  task: projectTaskSpec.isRequired,
};

ClaimTaskOwnership.defaultProps = {
  buttonLabel: 'Claim task ownership',
};

const ClaimTaskOwnershipConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimTaskOwnership);

export default withRouter(ClaimTaskOwnershipConnected);
