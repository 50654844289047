import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TimeAgo from 'timeago-react';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { ICON, POPPER_PLACEMENT } from 'core/assets/js/constants';
import { isExternalUrl, makeRelative } from 'core/assets/js/lib/utils';
import { notificationListUrl } from 'notifier/urls';
import {
  fetchSummariesDS,
  markAsSeenNotificationDS,
  markReadNotificationDS,
} from 'notifier/assets/js/data-services/notifications';
import { selectSummaries } from 'notifier/assets/js/ducks/notifications';
import { notificationSpec } from 'notifier/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

export const COMPONENT_NAME = 'NotificationsDropdown';

class NotificationsDropdown extends React.Component {
  static FetchData({ dispatch, url, authedAxios, componentName, isFirstRender }) {
    const prerequisites = [];
    if (isFirstRender) {
      prerequisites.push(dispatch(fetchSummariesDS({ url, componentName, authedAxios })));
    }
    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return COMPONENT_NAME;
  }

  constructor(props) {
    super(props);
    this.dropdownOpts = this.dropdownOpts.bind(this);
  }

  dropdownOpts() {
    const { activeOrg, dispatch, notifications } = this.props;

    const onItemClick = async (n) => {
      if (!n.is_read) {
        await dispatch(markReadNotificationDS({ values: { [n.id]: true } }));
      }
    };

    const items = notifications.map(n => {
      const isExternal = isExternalUrl(n.targetUrl);
      const url = n.targetUrl ? makeRelative(n.targetUrl) : notificationListUrl(activeOrg.alias);
      return (
        <li key={n.id}>
          <Link
            onClick={() => onItemClick(n)}
            to={isExternal ? { pathname: url } : url}
            className={`notifications-drop-down__list-item ${n.is_read ? 'seen' : ''}`}
            target={isExternal ? '_blank' : null}
            rel="noopener noreferrer"
          >
            <i className={`notification-icon ${n.icon}`} />
            <div>
              <p dangerouslySetInnerHTML={{ __html: n.summary }} />
              <div className="notifications-drop-down__meta">
                <TimeAgo datetime={n.created_at} live={false} />
                {' '}
                by
                {' '}
                <strong>{ n.org.name }</strong>
                .
              </div>
            </div>
          </Link>
        </li>
      );
    });

    return (
      <>
        <ul className="notifications-drop-down__list">
          {items}
        </ul>

        {items.length === 0 && (
          <div className="empty-list-message text-center d-flex flex-column align-items-center justify-content-center">
            <img width="40" alt="Notifications" src="/img/notification-icon.png" />
            <p className="mt-3">
              No recent Notifications!
            </p>
            <div className="text-center mt-4">
              <Link to={notificationListUrl(activeOrg.alias)} className="discreet d-inline p-0">Click here to see all notifications</Link>
            </div>
          </div>
        )}

        <div>
          <Link className="notifications-drop-down__see-all" to={notificationListUrl(activeOrg.alias)}>
            See all
          </Link>
        </div>
      </>
    );
  }

  render() {
    const { dispatch, menuIsVisible, newCount } = this.props;
    const toggle = (
      <div className="navbar-footer-toggle pl-4 pr-3 d-flex">
        <i className={ICON.NOTIFICATIONS} />
        Notifications

        <span className="align-self-center ml-auto">
          <i className={`${ICON.CHEVRON_DOWN} navbar-item__chevron`} />
        </span>
      </div>
    );
    const opts = this.dropdownOpts();

    const unreadClass = newCount ? 'td-dropdown__notifications--has-new' : '';

    return (
      <TDApiConnected persistent duck="notifications" component={this.constructor} loadingEnabled={false} className="btn-group">
        <TDDropButton
          className={`td-dropdown__notifications ${unreadClass}`}
          overlayClassName="notifications-drop-down"
          id="notifications-dropdown"
          placement={menuIsVisible ? POPPER_PLACEMENT.RIGHT_END : POPPER_PLACEMENT.RIGHT_START}
          onEntered={() => {
            if (newCount) {
              dispatch(markAsSeenNotificationDS());
            }
          }}
          toggleEl={toggle}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
              offset: {
                offset: '10px, -35px',
              },
            },
          }}
        >
          {opts}
        </TDDropButton>
      </TDApiConnected>
    );
  }
}

NotificationsDropdown.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  menuIsVisible: PropTypes.bool.isRequired,
  newCount: PropTypes.number.isRequired,
  notifications: PropTypes.arrayOf(notificationSpec),
};

NotificationsDropdown.defaultProps = {
  notifications: [],
};

const mapStateToProps = (state) => {
  const summaryState = selectSummaries(state);
  return {
    activeOrg: selectActiveOrg(state),
    notifications: summaryState.items,
    newCount: summaryState.newCount,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const NotificationsDropdownConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsDropdown);

export default withRouter(NotificationsDropdownConnect);
