import { pick, startCase } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useLocation, useParams } from 'react-router-dom';

import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import GridViewToggle from 'core/assets/js/components/GridViewToggle.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import {
  BS_STYLE, IMG_SIZE, ORDERING_DIRECTION, POPPER_PLACEMENT, USER_TYPE, VIEW_TYPES,
} from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import {
  modalOpenAC, getIsModalOpen, modalCloseAC, getModalPayload,
} from 'core/assets/js/ducks/modalLauncher';
import { getPeopleListViewType } from 'core/assets/js/ducks/settings';
import ContactDetailsCard from 'people/assets/js/components/ContactDetailsCard.jsx';
import PeopleGridListSkeleton from 'people/assets/js/components/skeletons/PeopleGridListSkeleton';
import { USER_EMPLOYMENT_TYPE, USER_EMPLOYMENT_TYPE_LABEL } from 'people/assets/js/constants';
import { orgUserProfileUrl } from 'people/urls';
import { removeTaskManagersDS } from 'projects/assets/js/data-services/tasks';
import { projectTaskSpec, taskManagerSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectTaskFetchManagersApiUrl } from 'projects/urls';

const CONTACT_MANAGER_MODAL_ID = 'contact-manager-modal-id';
const REMOVE_MANAGER_MODAL_ID = 'remove-manager-modal-id';

const ActionsDropDownComponent = ({ isLast, listViewType, task, taskManager }) => {
  const dispatch = useDispatch();
  const dropDownClassNames = ['team-grid-list-item__actions-drop-down'];
  if (listViewType === VIEW_TYPES.GRID) {
    dropDownClassNames.push('team-grid-list-item__actions-drop-down--fixed');
  }
  return (
    <TDDropChevronButton
      className={dropDownClassNames.join(' ')}
      data-testid="task-manager-card-actions"
      menuPlacement={isLast ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
      useDropButton
    >
      <Dropdown.Item
        eventKey="contact-user"
        onClick={() => dispatch(modalOpenAC(CONTACT_MANAGER_MODAL_ID, { taskManager }))}
      >
        Contact
      </Dropdown.Item>
      {task.accessControl.canManageTaskManagers && (
        <Dropdown.Item
          eventKey="remove-user"
          onClick={() => dispatch(modalOpenAC(REMOVE_MANAGER_MODAL_ID, { taskManager }))}
          className="text-danger"
        >
          Remove
        </Dropdown.Item>
      )}
    </TDDropChevronButton>
  );
};

ActionsDropDownComponent.propTypes = {
  isLast: PropTypes.bool.isRequired,
  listViewType: PropTypes.oneOf(Object.values(VIEW_TYPES)).isRequired,
  rootComponentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
  taskManager: taskManagerSpec.isRequired,
};

const TaskManagersTab = ({ filtersOpen, onFiltersToggle, task }) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const listViewType = useSelector(getPeopleListViewType);
  const isGrid = listViewType === VIEW_TYPES.GRID;

  const componentName = TaskManagersTab.GetComponentName(params);
  const { hasLoaded, isLoading, items, pagination } = fetchDataHook({
    changesArray: [listViewType],
    componentName,
    duck: 'list',
    url: projectTaskFetchManagersApiUrl(params.orgAlias, params.id, params.taskId),
    queryStringParams: { pageSize: isGrid ? 12 : 10 },
  });

  const contactModalIsOpen = useSelector(state => getIsModalOpen(state, CONTACT_MANAGER_MODAL_ID));
  const contactModalPayload = useSelector(
    state => getModalPayload(state, CONTACT_MANAGER_MODAL_ID),
  );
  const removeModalIsOpen = useSelector(state => getIsModalOpen(state, REMOVE_MANAGER_MODAL_ID));
  const removeModalPayload = useSelector(state => getModalPayload(state, REMOVE_MANAGER_MODAL_ID));

  const loading = isLoading || !hasLoaded;

  if (isLoading || !hasLoaded) {
    return isGrid ? <PeopleGridListSkeleton /> : <PeopleListSkeleton />;
  }

  const closeModal = () => dispatch(modalCloseAC());

  const hasManagers = items.length > 0;

  return (
    <>
      <div className="row">
        <SearchFinalForm
          extraSearchElement={(
            <div className="d-flex align-items-center ml-auto">
              <div className="d-none d-xl-flex flex-nowrap align-items-center">
                <GridViewToggle />
              </div>
            </div>
          )}
          filtersOpen={filtersOpen}
          initialValues={pick(
            queryString.parse(location.search), ['kw', 'ordering', 'employmentType'],
          )}
          name="taskManagers"
          onFiltersToggle={onFiltersToggle}
          searchSpec={{
            defaultOrdering: { direction: ORDERING_DIRECTION.DESC, sortBy: 'created_at' },
            filters: [{
              fieldComponent: SelectableListFilterField,
              label: 'Employment Type',
              multiple: false,
              options: Object.values(USER_EMPLOYMENT_TYPE).map(type => ({
                text: startCase(USER_EMPLOYMENT_TYPE_LABEL[type]), value: type,
              })),
              paramName: 'employmentType',
            }],
            orderingOptions: [
              { text: 'Join Date', value: 'created_at' },
              { text: 'Last Name', value: 'last_name' },
            ],
            searchTerm: {
              component: AutoSuggestField, paramName: 'kw', placeholder: 'Search managers',
            },
          }}
        />
      </div>
      <>
        {loading && (isGrid ? <PeopleGridListSkeleton /> : <PeopleListSkeleton />)}
        {!loading && !filtersOpen && (
          <>
            {isGrid && (
              <>
                {hasManagers && (
                  <ul className="task-managers-grid-view">
                    {items.map(taskManager => (
                      <li className="team-grid-list-item text-center h-100" key={taskManager.id}>
                        <div
                          className={(
                            'team-grid-list-item__container d-flex flex-column bg-white bg-white '
                            + 'p-5'
                          )}
                        >
                          <ActionsDropDownComponent
                            isLast={false}
                            listViewType={listViewType}
                            rootComponentName={componentName}
                            task={task}
                            taskManager={taskManager}
                          />
                          <Link
                            className="d-flex"
                            to={orgUserProfileUrl(
                              params.orgAlias,
                              USER_TYPE.MANAGER,
                              taskManager.userId,
                            )}
                            title={taskManager.userCard.user.profile.name}
                          >
                            <ProfilePic
                              alt={taskManager.userCard.user.profile.name}
                              url={taskManager.userCard.user.profile.avatar}
                              size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                            />
                            <div className="d-flex flex-column align-items-start ml-5">
                              <div>{taskManager.userCard.user.profile.name}</div>
                              <div className="discreet">
                                {taskManager.userCard.user.profile.jobTitle}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {!hasManagers && <div className="w-100 text-center mt-5">No managers</div>}
              </>
            )}
            {!isGrid && (
              <Table
                cols={[
                  {
                    dataFormat: (
                      id, { userCard: { user: { profile: { avatar, name } } }, userId },
                    ) => (
                      <div className="d-flex align-items-center">
                        <ProfilePic
                          alt={name}
                          className="mr-3"
                          url={avatar}
                          size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                        />
                        <div className="d-flex flex-column align-items-start">
                          <Link
                            className="text-dark font-weight-bolder"
                            to={orgUserProfileUrl(
                              params.orgAlias,
                              USER_TYPE.MANAGER,
                              userId,
                            )}
                          >
                            <span className="text-break">{name}</span>
                          </Link>
                        </div>
                      </div>
                    ),
                    key: 'id',
                    label: 'Name',
                  },
                  {
                    dataFormat: (id, { userCard: { user: { profile: { jobTitle } } } }) => jobTitle,
                    key: 'id',
                    label: 'Title',
                  },
                  {
                    dataFormat: (id, taskManager, formatExtraData, rowIdx) => (
                      <ActionsDropDownComponent
                        isLast={rowIdx === items.length - 1}
                        listViewType={listViewType}
                        rootComponentName={componentName}
                        task={task}
                        taskManager={taskManager}
                      />
                    ),
                    key: 'id',
                    label: '',
                    width: '68px',
                  },
                ]}
                containerClass="task-managers-table"
                items={items}
                responsive={false}
              />
            )}
            <TDPagination {...pagination} />
            <ModalSimple
              body={contactModalPayload?.taskManager && (
                <ContactDetailsCard
                  email={contactModalPayload.taskManager.userCard.user.email}
                  profile={contactModalPayload.taskManager.userCard.user.profile}
                />
              )}
              heading={(
                `Contact ${contactModalPayload?.taskManager?.userCard.user.profile.firstName}`
              )}
              onClose={closeModal}
              open={contactModalIsOpen}
            />
            <ModalConfirm
              body={(
                <>
                  {'Are you sure you want to remove '}
                  <strong>{removeModalPayload?.taskManager?.userCard?.user?.profile?.name}</strong>
                  ?
                </>
              )}
              confirmLabel="Remove"
              confirmStyle={BS_STYLE.DANGER}
              heading="Remove manager"
              onClose={closeModal}
              onConfirm={async () => {
                try {
                  await dispatch(removeTaskManagersDS({
                    componentName,
                    orgAlias: params.orgAlias,
                    projectId: params.id,
                    taskId: params.taskId,
                    taskManagerId: removeModalPayload.taskManager.id,
                  }));
                  toastr.success('Well Done!', 'Manager removed successfully.');
                } catch (err) {
                  toastr.error(err._error || err.message);
                }
              }}
              open={removeModalIsOpen}
            />
          </>
        )}
      </>
    </>
  );
};

TaskManagersTab.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  task: projectTaskSpec.isRequired,
};

TaskManagersTab.GetComponentName = () => 'TaskManagersTab';

export default withFilters(TaskManagersTab);
