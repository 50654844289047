import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { omit } from 'lodash';
import { withRouter } from 'react-router-dom';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import TalentListSkeleton from 'people/assets/js/components/skeletons/TalentListSkeleton.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TalentCard from 'people/assets/js/components/TalentCard.jsx';
import { discoverTalentApiUrl } from 'search/urls';

const FreelancersChatList = (props) => {
  const { match, showChatsOnly } = props;
  const { orgAlias } = match.params;
  const { items: people, pagination } = useSelector(state =>
    getListState(state, FreelancersChatList.GetComponentName()));
  const currentUser = useSelector(state => state.organizations.active);
  return (
    <TDList
      cardItem={{
        component: TalentCard,
        props: {
          currentUser,
          parentComponentName: FreelancersChatList.GetComponentName(),
          orgAlias,
          isChatCard: showChatsOnly,
        },
      }}
      emptyListMessage="No chats available yet."
      items={people}
      listClassName="row mr-0 ml-0"
      pagination={pagination}
    />
  );
};

FreelancersChatList.GetComponentName = () => 'FreelancersChatList';

FreelancersChatList.propTypes = {
  match: PropTypes.object,
  showChatsOnly: PropTypes.bool,
};

FreelancersChatList.defaultProps = {
  match: {},
  showChatsOnly: false,
};

const FreelancersChatListWithTDApi = withTDApiConnected({
  fetchData: ({
    authedAxios,
    componentName,
    dispatch,
    params,
    props,
    querystring,
    url,
  }) => {
    const { showChatsOnly } = props;
    const updatedQueryString = queryString.parse(querystring);
    updatedQueryString.showChats = showChatsOnly;
    const apiUrl = discoverTalentApiUrl(params, url);
    return dispatch(
      fetchListDS({ authedAxios, componentName,
        querystring: queryString.stringify(updatedQueryString.kw
          ? { ...omit(updatedQueryString, 'tags') } : updatedQueryString), url: apiUrl,
      }),
    );
  },
  duck: 'list',
  loadingEnabled: true,
  skeletonComponent: TalentListSkeleton,
  storeKey: FreelancersChatList.GetComponentName(),
  shouldRefetchOnQueryChange: (oldQuery, newQuery) =>
    // if invite is the same, then some search param has changed
    // so, refetch
    oldQuery.invite === newQuery.invite,
})(FreelancersChatList);

export default withRouter(withFilters(FreelancersChatListWithTDApi));
