import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { completeStepDS } from 'accounts/assets/js/ducks/account';
import { orgGetStartedUrl } from 'accounts/urls';
import FilePreview from 'core/assets/js/components/FilePreview.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import DocumentTermsContentBlocker from 'documents/assets/js/components/DocumentTermsContentBlocker.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import SignDocumentForm from 'documents/assets/js/components/SignDocumentForm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchDocumentAssignmentDS } from 'documents/assets/js/data-services/view';
import { getViewState } from 'core/assets/js/ducks/view';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectInvitationsUrl } from 'projects/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import DocumentAssignmentEsign from 'documents/assets/js/components/DocumentAssignmentEsign.jsx';
import { TYPE } from 'invitations/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';


const DocumentAssignmentSignView = ({
  activeOrg, dispatch, documentAssignment, history, location: { search }, match: { params },
}) => {
  const componentName = DocumentAssignmentSignView.GetComponentName({ params });
  const [hasAccepted, setHasAccepted] = useState(false);
  const { id, orgAlias } = params;
  const canSign = documentAssignment?.allowedActions?.canSign;
  const file = documentAssignment?.document?.files && documentAssignment.document.files[0];
  const { location } = history;
  const skipTermsAndConditionsMessage = !!location?.state?.skipTermsAndConditionsMessage;

  const isEsign = !!documentAssignment?.document?.signNowTemplateId;
  const isOrgInvite = documentAssignment.invitation?.type === TYPE.ORG_INVITE;

  const onCancel = () => {
    history.push((isOrgInvite ? orgGetStartedUrl : projectInvitationsUrl)(activeOrg.alias));
  };

  const { backUrl, next } = queryString.parse(search);

  const onSuccess = async () => {
    if (isOrgInvite) {
      await completeStepDS(dispatch, orgAlias, 'DocumentStep', { history });
    } else if (next) {
      history.push(next);
    }
  };

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          {
            title: documentAssignment?.document
              ? `Review and confirm the contract template - ${documentAssignment.document.title}`
              : (
                <>
                  Review and confirm the contract template -
                  <RectangleSkeleton className="ml-3" width="155" height="25" />
                  <RectangleSkeleton className="ml-3" width="55" height="25" />
                </>
              ),
            url: null,
          },
        ]}
        backUrl={backUrl}
      />

      <div
        className={
          `page page--document-sign ${(hasAccepted || !canSign || skipTermsAndConditionsMessage)
            ? '' : 'overflow-hidden'}`
        }
      >
        {!skipTermsAndConditionsMessage && canSign && (
          <DocumentTermsContentBlocker
            onAccept={() => setHasAccepted(true)}
          />
        )}

        <div className="container">

          <TDApiConnected
            duck="view"
            fetchData={({ authedAxios }) => dispatch(fetchDocumentAssignmentDS({
              authedAxios,
              componentName,
              id,
              orgAlias: activeOrg.alias,
            }))}
            blockingLoading
            storeKey={componentName}
          >
            {isEsign && documentAssignment.signatures.length > 0 && (
              <DocumentAssignmentEsign
                onCancel={onCancel}
                onSuccess={onSuccess}
                signature={documentAssignment.signatures[0]}
              />
            )}
            {!isEsign && (
              <>
                <div className="document-viewer rounded shadow-sm bg-white">
                  {file && <FilePreview mimeType="application/pdf" url={file.url} />}
                  {!file && (
                    <div className="p-5">
                      <div
                        className="html-content p-5"
                        dangerouslySetInnerHTML={{ __html: documentAssignment?.document?.content }}
                      />
                    </div>
                  )}
                </div>
                <SignDocumentForm
                  document={documentAssignment?.document}
                  initialValues={{
                    date: documentAssignment.signedAt || moment(),
                    documentId: documentAssignment?.document?.id,
                    legalName: documentAssignment.legalName || null,
                  }}
                  onCancel={onCancel}
                  onSuccess={onSuccess}
                  readOnly={!canSign}
                />
              </>
            )}
          </TDApiConnected>
        </div>
      </div>
    </>
  );
};

DocumentAssignmentSignView.GetComponentName = ({ params: { id } }) => `DocumentAttachmentSignView-${id}`;

DocumentAssignmentSignView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  documentAssignment: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapStateToProps = (state, { match: { params } }) => {
  const viewState = getViewState(state, DocumentAssignmentSignView.GetComponentName({ params }));

  return {
    activeOrg: selectActiveOrg(state),
    documentAssignment: viewState.item,
  };
};

const DocumentAttachmentSignViewConnected = connect(
  mapStateToProps,
)(DocumentAssignmentSignView);

export default withRouter(DocumentAttachmentSignViewConnected);
