import React, { Fragment } from 'react';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const ProFormaInvoiceFormSkeleton = () => (
  <div className="skeleton--pro-forma-invoice-form clearfix">
    {new Array(2).fill('').map((_, i) => (
      <Fragment key={i}>
        <RectangleSkeleton width="100" height="15" />
        <br />
        <RectangleSkeleton width="250" height="15" />
        <br />
        <RectangleSkeleton width="300" height="20" />
        <br />
        <br />
      </Fragment>
    ))}
    <div className="clearfix">
      {new Array(4).fill('').map((_, i) => (
        <div className="float-left mr-4" key={i}>
          <RectangleSkeleton width="50" height="15" />
          <br />
          <RectangleSkeleton width="100" height="20" />
        </div>
      ))}
    </div>
    <br />
    <br />
    <RectangleSkeleton width="100" height="15" />
    <br />
    <RectangleSkeleton width="300" height="40" />
    <br />
    <br />
    <RectangleSkeleton width="100" height="15" />
    <br />
    <RectangleSkeleton width="250" height="15" />
    <br />
    <RectangleSkeleton width="300" height="40" />
    <br />
    <br />
    <div className="clearfix">
      <div className="float-right">
        <RectangleSkeleton width="100" height="30" />
        <RectangleSkeleton width="100" height="30" />
      </div>
    </div>
    <br />
    <br />
  </div>
);

export default ProFormaInvoiceFormSkeleton;
