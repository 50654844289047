import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux';
import thunk from 'redux-thunk';

import appReducers from 'core/assets/js/lib/appReducers';
import { isSSR } from 'core/assets/js/config/checks';
import { REDUX_DEBUG, WINDOW_REDUX_DEVTOOLS_EXTENSION_COMPOSE } from 'core/assets/js/config/settings';
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('redux');

/**
 * Logs all actions and states after they are dispatched.
 */
const loggerMiddleware = () => next => (action) => { //eslint-disable-line
  if (typeof action === 'function') {
    logger.debug('dispatching thunk', action.name);
  } else {
    logger.debug('dispatching', action.type);
  }
  const result = next(action);
  // logger.log('next state', store.getState());
  return result;
};

let store = null;

class Store {
  static dispatch(...args) {
    if (store) {
      store.dispatch(...args);
    }
  }

  /**
   * Create the redux store that will be used throughout the application. It may take an initial
   * state to populate it
   *
   * @param preloadedState
   * @returns store
   */
  static createStore(preloadedState, { force = false } = {}) {
    let composeEnhancers = compose;

    const initialState = !store || force
      ? preloadedState
      : store.getState();

    if (!isSSR) {
      // Client side rendering
      if (typeof REDUX_DEBUG !== 'undefined'
        && REDUX_DEBUG === true
        && WINDOW_REDUX_DEVTOOLS_EXTENSION_COMPOSE
      ) {
        composeEnhancers = WINDOW_REDUX_DEVTOOLS_EXTENSION_COMPOSE;
      }

      if (initialState) {
        store = reduxCreateStore(
          appReducers, initialState, composeEnhancers(applyMiddleware(loggerMiddleware, thunk)),
        );
      } else {
        const enhancers = composeEnhancers(applyMiddleware(loggerMiddleware, thunk));
        store = reduxCreateStore(appReducers, enhancers);
      }

      if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('core/assets/js/lib/appReducers.js', () => {
          const nextRootReducer = require('core/assets/js/lib/appReducers');
          store.replaceReducer(nextRootReducer);
        });
      }
    } else {
      store = reduxCreateStore(appReducers, applyMiddleware(loggerMiddleware, thunk));
    }
    return store;
  }
}

export default Store;
