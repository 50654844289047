import React from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';

import SkillTag from 'core/assets/js/components/SkillTag.jsx';


const CustomSkillList = ({ skills, onSkillDelete }) => {
  const groups = groupBy(skills, skill => skill.label.toLowerCase()[0]);
  return (
    <div>
      {skills.length === 0 && (
        <p className="discreet py-4">No skills found</p>
      )}
      {Object.keys(groups).map((group) => {
        const letterCount = groups[group].length;
        return (
          <div key={group}>
            <h3 className="mb-3 text-uppercase">
              {group}
              <span className="ml-2 discreet font-weight-normal">
                {`(${letterCount})`}
              </span>
            </h3>

            <div className="px-0">
              <div className="skill-list">
                {groups[group]?.map(skill => {
                  return (
                    <SkillTag
                      key={skill.id}
                      skill={skill}
                      onRemove={onSkillDelete ? () => onSkillDelete(skill) : null}
                      isSelectable={false}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CustomSkillList.propTypes = {
  skills: PropTypes.array.isRequired,
  onSkillDelete: PropTypes.func,
};
CustomSkillList.defaultProps = {
  onSkillDelete: null,
};

export default CustomSkillList;
