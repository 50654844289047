import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';
import { SkillVotesBadge } from 'core/assets/js/components/SkillTag.jsx';
import SkillListItem from 'core/assets/js/components/SkillListItem.jsx';

const MODAL_ID = 'skill-list';

class SkillList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.isExpanded || false,
      showToggle: false,
    };
    this.tagsContainer = null;
    this.props = props;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderFullSkillList = this.renderFullSkillList.bind(this);
  }

  componentDidMount() {
    this.checkContentHeight();
  }

  openModal(ev) {
    const { modalId, skills, maxShownItems, dispatch } = this.props;
    ev.stopPropagation();
    // Show popup only if total skills count is greater than the max shown.
    if (skills.length > maxShownItems) {
      dispatch(modalOpenAC(modalId));
    }
  }

  closeModal(ev) {
    const { dispatch } = this.props;
    if (ev) {
      ev.stopPropagation();
    }
    dispatch(modalCloseAC());
  }

  checkContentHeight() {
    this.setState({});
    // Calculate tags height.
    const height = this.tagsContainer ? this.tagsContainer.clientHeight : 0;
    // If height is greater than a line, show the toggle button.
    if (height > 35) {
      this.setState({ showToggle: true });
    }
  }

  renderFullSkillList() {
    const { skills, onUpVote, onRetractVote, onShowMore } = this.props;

    return (
      <div className="skill-list">
        { skills.map(skill => (
          <SkillListItem
            key={`skill-${skill.id}`}
            skill={skill}
            onUpVote={onUpVote}
            onRetractVote={onRetractVote}
          />
        ))}

        {onShowMore && (
          <span
            onClick={onShowMore}
            className="skill-tag show-all-link cursor-pointer"
          >
            ...
          </span>
        )}
      </div>
    );
  }

  render() {
    const {
      alignTags,
      collapsible,
      'data-testid': dataTestId,
      emptyTxt,
      inline,
      invertedColors,
      isModalOpen,
      maxShownItems,
      skills,
      wrapperClassName,
    } = this.props;
    const { showToggle, isExpanded } = this.state;

    if (!skills.length) {
      return (
        <i className="no-tags">
          {emptyTxt}
        </i>
      );
    }

    const trimmedSkillList = skills.slice(0, maxShownItems).map((skill) => {
      const { id, votesCount, label, copiedByUser } = skill;

      const className = ['tag'];


      if (copiedByUser === false && votesCount > 0) {
        className.push('bg-lightblue');
      }

      return (
        <span
          onClick={this.openModal}
          title={label}
          key={`skill-${id}`}
          className={className.join(' ')}
        >
          <span>
            {label}
            {votesCount > 0 && <SkillVotesBadge count={votesCount} /> }
          </span>
        </span>
      );
    });

    // Show ellipsis if total skills are more than the shown ones.
    if (skills.length > maxShownItems) {
      trimmedSkillList.push((
        <span
          key="more-skills"
          onClick={this.openModal}
          className="tag"
        >
          ...
        </span>
      ));
    }

    const wrapperClasses = ['skill-list clearfix mb-0'];

    if (invertedColors) {
      wrapperClasses.push('skill-list--inverted-colors');
    }

    if (inline) {
      wrapperClasses.push('skill-list--inline');
    }

    if (collapsible) {
      wrapperClasses.push('skill-list--collapsible');
    }

    if (alignTags === 'right') {
      wrapperClasses.push('skill-list--right-aligned');
    } else {
      wrapperClasses.push('skill-list--left-aligned');
    }

    if (isExpanded) {
      wrapperClasses.push('skill-list--expanded');
    } else {
      wrapperClasses.push('skill-list--collapsed');
    }

    if (wrapperClassName) {
      wrapperClasses.push(wrapperClassName);
    }

    return (
      <div className={wrapperClasses.join(' ')} data-testid={dataTestId}>
        { collapsible && showToggle }
        { inline
          ? (
            <span
              className={`${skills.length > maxShownItems ? 'skill-list__trimmed-list' : ''} truncate`}
            >
              {trimmedSkillList}
            </span>
          )
          : this.renderFullSkillList() }

        <ModalSimple
          data-testid={dataTestId ? `${dataTestId}-modal` : ''}
          heading="Skill List"
          open={isModalOpen}
          onClose={this.closeModal}
          body={this.renderFullSkillList()}
        />
      </div>
    );
  }
}

SkillList.propTypes = {
  alignTags: PropTypes.string,
  collapsible: PropTypes.bool,
  'data-testid': PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  emptyTxt: PropTypes.string,
  inline: PropTypes.bool,
  invertedColors: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  /* If total skills is greater than this number, then show a "..."
   * link and open a popup to show the whole skill list.
   */
  maxShownItems: PropTypes.number,
  modalId: PropTypes.string,
  onRetractVote: PropTypes.func,
  onShowMore: PropTypes.func,
  onUpVote: PropTypes.func,
  skills: PropTypes.arrayOf(skillSpec),
  wrapperClassName: PropTypes.string,
};

SkillList.defaultProps = {
  alignTags: 'left',
  collapsible: false,
  'data-testid': '',
  inline: false,
  invertedColors: false,
  isExpanded: false,
  isModalOpen: false,
  emptyTxt: '-',
  maxShownItems: 8,
  modalId: MODAL_ID,
  onRetractVote: () => {},
  onShowMore: null,
  onUpVote: () => {},
  skills: [],
  wrapperClassName: null,
};

const mapStateToProps = (state, props) => ({
  isModalOpen: getIsModalOpen(state, props.modalId || MODAL_ID),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SkillList);
