import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { CUSTOM_ICONS } from 'core/assets/js/constants';
import { interviewSpec } from 'interviews/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  getDocumentCountersignersFieldName, getSelectedDocumentIdsFromValues,
} from 'people/assets/js/lib/utils';

const InvitePeopleCountersigners = ({ documentsWithCountersigning, interviews }) => {
  const { values } = useFormState();
  const activeOrg = useSelector(selectActiveOrg);

  const documentIds = getSelectedDocumentIdsFromValues(
    values, interviews, activeOrg.onboarding_forms_are_mandatory,
  );

  return (
    <>
      <h2>Select contract signers</h2>
      <p>
        The following contracts require counter signatures. Please choose which Managers will be
        responsible for counter signing each contract.
      </p>
      {documentIds.map(documentId => {
        const document = documentsWithCountersigning[documentId];
        if (!document) {
          return null;
        }
        return (
          <Fragment key={documentId}>
            <div className="d-flex align-items-center mb-3 invite-esign-document-title">
              {
                document.isEsign
                  ? (
                    <img
                      alt="esign icon"
                      src={CUSTOM_ICONS.ESIGN}
                      className="mr-3"
                    />
                  ) : (
                    <img
                      alt="checkbox file icon"
                      src={CUSTOM_ICONS.FILE_CHECKBOX}
                      className="mr-3"
                    />
                  )
              }
              <h4>{document.title}</h4>
            </div>
            {new Array(document.countersignersCount).fill('_').map((_, index) => {
              const name = getDocumentCountersignersFieldName(documentId, index);
              return (
                <TextInputField
                  key={name}
                  label={`Countersigner ${index + 1}`}
                  name={name}
                  required
                  type="email"
                />
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

InvitePeopleCountersigners.propTypes = {
  documentsWithCountersigning: PropTypes.object.isRequired,
  interviews: PropTypes.arrayOf(interviewSpec).isRequired,
};

export default InvitePeopleCountersigners;
