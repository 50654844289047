import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import { orgPublicSpec } from 'organizations/assets/js/lib/objectSpecs';
import SkillMultiselect from 'skills/assets/js/components/SkillMultiselect.jsx';
import LanguageMultiselect from 'skills/assets/js/components/LanguageMultiselect.jsx';
import MemberMultiselect from 'organizations/assets/js/components/MemberMultiselect.jsx';
import SimpleMultiselect from 'core/assets/js/components/ReduxFormFields/SimpleSelect.jsx';
import {
  MULTISELECT_TYPE, MULTISELECT_TARGET, MULTISELECT_VARIANT,
} from 'core/assets/js/constants';

/**
 * Redux form compatible input field
 *
 * It displays a bootstrap decorated input field with a label and an error
 * message
 *
 * @param multiselectComponent One of the SkillMultiselect or memberMultiselect components
 */
function MultiselectField({
  input, label, sublabel, multiselect, multiselectComponent, size,
  makeInitialCall, projectId, variant, useAsync, options, defaultValue,
  orgAlias, target, payload, placeholder, required, memberSkillsOnly, onItemsChanged,
  meta: { touched, error }, disabled, searchable, prefilledOptions, activeOrg,
  defaultSkillsEnabled, onUpVote, onRetractVote, filteringItems, ...rest
}) {
  const hasError = touched && error;
  const groupClassName = ['form-group', size];

  if (variant === MULTISELECT_VARIANT.COMPACT) {
    groupClassName.push('Select-control--compact');
  }

  if (hasError) {
    groupClassName.push('has-error');
  }
  let MultiselectComponent;
  let multiselectOptions = {};
  if (multiselectComponent === MULTISELECT_TYPE.SIMPLE) {
    MultiselectComponent = SimpleMultiselect;
    multiselectOptions = {
      makeInitialCall, searchable, options, defaultValue,
    };
  } else if (multiselectComponent === MULTISELECT_TYPE.SKILL) {
    MultiselectComponent = SkillMultiselect;
    multiselectOptions = {
      memberSkillsOnly,
      activeOrg,
      onUpVote,
      onRetractVote,
      filteringItems,
    };
  } else if (multiselectComponent === MULTISELECT_TYPE.LANGUAGE) {
    MultiselectComponent = LanguageMultiselect;
  } else {
    MultiselectComponent = MemberMultiselect;
    multiselectOptions = {
      orgAlias,
      target,
      payload,
      placeholder,
      projectId,
      disabled,
      onItemsChanged,
      useAsync,
      searchable,
      prefilledOptions,
      ...rest,
    };
  }
  multiselectOptions.multiselect = multiselect;

  return (
    <div className={groupClassName.join(' ')}>
      {
        label && (
          <label htmlFor={input.name}>
            {label}
            {' '}
            {required && ' *'}
            {' '}
            {sublabel && (
              <span className="sublabel">
                {' '}
                {sublabel}
              </span>
            )}
          </label>
        )}

      <MultiselectComponent input={input} {...multiselectOptions} />
      {hasError && <span className="help-block">{error}</span>}
    </div>
  );
}

MultiselectField.propTypes = {
  memberSkillsOnly: PropTypes.bool,
  input: reduxInputSpec.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  sublabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  makeInitialCall: PropTypes.bool,
  multiselect: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  multiselectComponent: PropTypes.oneOf(values(MULTISELECT_TYPE)).isRequired,
  orgAlias: PropTypes.string,
  variant: PropTypes.string,
  defaultValue: PropTypes.any,
  projectId: PropTypes.number,
  target: PropTypes.oneOf(Object.values(MULTISELECT_TARGET)),
  payload: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  prefilledOptions: PropTypes.array,
  options: PropTypes.array,
  onItemsChanged: PropTypes.func,
  useAsync: PropTypes.bool,
  activeOrg: orgPublicSpec,
  defaultSkillsEnabled: PropTypes.bool,
  onUpVote: PropTypes.func,
  onRetractVote: PropTypes.func,
  filteringItems: PropTypes.array,
};
MultiselectField.defaultProps = {
  memberSkillsOnly: true,
  label: '',
  size: '',
  prefilledOptions: null,
  defaultValue: null,
  sublabel: '',
  variant: MULTISELECT_VARIANT.DEFAULT,
  meta: {
    touched: false,
    error: false,
  },
  makeInitialCall: false,
  multiselect: false,
  orgAlias: '',
  projectId: null,
  target: '',
  payload: {},
  options: [],
  placeholder: '',
  required: false,
  useAsync: true,
  disabled: false,
  searchable: false,
  onItemsChanged: () => {},
  activeOrg: null,
  defaultSkillsEnabled: false,
  onUpVote: () => {},
  onRetractVote: () => {},
  filteringItems: [],
};

export default MultiselectField;
