import React from 'react';

import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';

const ImportStatusInfo = () => (
  <ElementWithPopOver
    className="d-inline-block ml-2"
    popOverContent={
      `There may be a few minutes delay between all of the rows having been processed
      and the import being marked as complete`
    }
  />
);

export default ImportStatusInfo;
