import React from 'react';
import { connect } from 'react-redux';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { profileSettingsUrl } from 'accounts/urls';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const ProfileExperienceFormSkeleton = ({ activeOrg: { alias } }) => (
  <>
    <ContentHeader
      breadcrumbs={[
        {
          title: 'Profile',
          url: profileSettingsUrl(alias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION),
        }, {
          title: 'Experience',
          url: profileSettingsUrl(alias, PROFILE_SETTINGS_TABS.EXPERIENCE),
        }, {
          title: 'Edit your experience',
          url: null,
        },
      ]}
    />
    <div className="page page--settings">
      <div className="container">
        <div className="rounded shadow-sm p-4 bg-white">
          <div className="page--settings__content clearfix">
            <div className="row">
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
              <div className="col-12 col-sm-6">
                <RectangleSkeleton width="300" height="20" className="mb-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

ProfileExperienceFormSkeleton.propTypes = {
  activeOrg: orgSpec.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const ProfileExperienceFormSkeletonConnected = connect(
  mapStateToProps,
)(ProfileExperienceFormSkeleton);

export default ProfileExperienceFormSkeletonConnected;
