import { isEmpty, isEqual } from 'lodash';

import { DEFAULT_BALANCE_CURRENCY } from 'finance/assets/js/constants';
import Money from 'finance/assets/js/lib/Money';


/**
 * This class is used to calculate the TIN charges applied to an organization.
 * While the TINFeeScheme is the record of the service settings,
 * the TINFeeAnalysis is the actual calculated charges.
 */
class TINFeeAnalysis {
  static getEmptyAnalysis() {
    return new TINFeeAnalysis();
  }

  /**
   * It is used for de-serializing a TINFeeAnalysis serialized object
   *
   * @param {Object} serialized
   *
   * @returns {TINFeeAnalysis}
   */
  constructor(...serialized) {
    if (isEmpty(serialized) || serialized.every(s => isEmpty(s))) {
      return this.init();
    }
    return this.init(...serialized);
  }

  /**
   *
   * @param {Object} params
   * @param {String} params.currency - TIN service currency
   * @param {Number} params.basePrice - Price which will be charged for TIN usage
   * @param {Boolean} params.hasBeenEnabled - Check if TIN service was enabled
   * @returns {TINFeeAnalysis}
   */
  init({
    currency = DEFAULT_BALANCE_CURRENCY,
    basePrice = '0.00',
    hasBeenEnabled = false,
    numTINChecks = 0,
    total,
  } = {}) {
    this.details = {
      currency,
      basePrice,
      hasBeenEnabled,
      numTINChecks,
    };

    Object.assign(this.details, {
      total: total || this.aggregate(),
    });
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  aggregate() {
    const {
      basePrice,
      hasBeenEnabled,
      currency,
      numTINChecks,
    } = this.details;
    if (!hasBeenEnabled) {
      return '0.00';
    }
    const fee = new Money(basePrice, currency).mul(numTINChecks);
    return fee.toString();
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getTotal() {
    return this.aggregate();
  }

  getNumChecks() {
    const { numTINChecks } = this.details;
    return numTINChecks || 0;
  }

  /**
   * Compares the analysis passed with the current one
   *
   * @param {TINFeeAnalysis} analysisToCompareWith
   * @returns {boolean}
   */
  isEqual(analysisToCompareWith) {
    return isEqual(this, analysisToCompareWith);
  }

  /**
   * Checks if the total calculated as the analysis total is zero
   *
   * @returns {Boolean}
   */
  isZero() {
    return new Money(this.getTotal(), this.getCurrency()).isZero();
  }

  /**
   * Checks if an analysis is empty
   *
   * @returns {Boolean}
   */
  isEmpty() {
    const emptyAnalysis = TINFeeAnalysis.getEmptyAnalysis();
    return this.isEqual(emptyAnalysis);
  }

  /**
   * Returns whether the service has been enabled
   *
   * @returns {Boolean}
   */
  isEnabled() {
    const { hasBeenEnabled } = this.details;
    return hasBeenEnabled;
  }

  /**
   * @returns {String}
   */
  getServiceTitle() {
    return `Tax Identification Number checks (${this.getNumChecks()})`;
  }

  /**
   * @returns {String}
   */
  getServiceMetricsDescriptions() {
    return [{
      description: 'TIN verifications',
      fee: this.getTotal(),
      quantity: this.getNumChecks(),
    }];
  }
}

export default TINFeeAnalysis;
