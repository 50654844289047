import axios from 'core/assets/js/lib/tdAxios';
import { SubmissionError } from 'redux-form';

import { viewUpdateAC } from 'core/assets/js/ducks/view';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';
import { authSuccessAC } from 'accounts/assets/js/reducers/auth';
import {
  loginApiUrl,
  orgLoginApiUrl,
  refreshApiUrl,
  resendVerificationApiUrl,
  signupApiUrl,
  userCustomFieldsUpdateApiUrl,
} from 'accounts/urls';
import { setSentryContext } from 'core/assets/js/lib/sentry';
import { orgListFetchAC } from 'organizations/assets/js/actions/list';
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('ds:account');


const _submitAccount = (apiUrl, values, isLogin = false) => (
  dispatch => (
    new Promise((resolve, reject) => {
      axios.post(apiUrl, values)
        .then((response) => {
          const { data: responseData } = response;
          if (responseData._error) {
            if (!isLogin) {
              return resolve(responseData);
            }
            return reject(new SubmissionError(responseData));
          }

          if (!isLogin) {
            return resolve();
          }

          // Set user context submitted to Sentry every time an error is reported
          setSentryContext({ isAuthenticated: true, profile: responseData.auth.userProfile });
          dispatch(authSuccessAC(responseData.auth));
          dispatch(orgListFetchAC(responseData.orgs));
          return resolve(responseData);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(new SubmissionError(error.response.data));
          }
          return reject(new SubmissionError({
            _error: 'Unexpected error',
          }));
        });
    })));

export const resendVerificationDS = values => (
  dispatch => ( // eslint-disable-line no-unused-vars
    new Promise((resolve, reject) => {
      axios.post(resendVerificationApiUrl(), values)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            reject(new SubmissionError(error.response.data));
          } else {
            logger.error(error);
            reject(new SubmissionError({
              _error: 'Unexpected error',
            }));
          }
        });
    })
  )
);

export const submitLoginDS = (values, orgAlias = null) => _submitAccount(
  orgAlias ? orgLoginApiUrl(orgAlias) : loginApiUrl(), values, true,
);
export const submitSignupDS = (values, isLogin) => (
  dispatch => dispatch(_submitAccount(signupApiUrl(), values, isLogin))
);

export const refreshAccountDS = authedAxios => (
  dispatch => (
    new Promise((resolve, reject) => {
      const apiUrl = refreshApiUrl();
      (authedAxios || axios).get(apiUrl)
        .then((response) => {
          if (response.data._error) {
            logger.error(response.data._error);
            reject(response.data._error);
          } else {
            // Set user context submitted to Sentry every time an error is reported
            setSentryContext({ isAuthenticated: true, profile: response.data.auth.userProfile });
            dispatch(authSuccessAC(response.data.auth));
            dispatch(orgListFetchAC(response.data.orgs));
            resolve(response.data);
          }
        })
        .catch((error) => {
          logger.error(error);
          reject(error);
        });
    })
  )
);

export const postUserCustomFieldsDS = (orgAlias, userId, values, componentName) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: userCustomFieldsUpdateApiUrl(orgAlias, userId),
    values,
    pushDataAC: responseData => [
      viewUpdateAC(responseData, componentName),
    ],
  })
);
