import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import ReportSalesTax from 'finance/assets/js/components/ReportSalesTax.jsx';
import { getReportInfo } from 'settings/assets/js/lib/sales_tax_utils';

class ReportCompany extends React.PureComponent {
  render() {
    const { company, showFullVat } = this.props;
    if (isEmpty(company)) {
      return null;
    }
    const reportSalesTaxInfo = getReportInfo(company);
    const name = get(company, 'name');
    const formattedAddress = ((company.address && typeof company.address === 'object') ? company.address.label : company.address) || '';
    const freeBillToText = get(company, 'free_bill_to_text');

    return (
      <div className="mb-2">
        <h4>{name}</h4>
        <div className="finance-report__provider__company">
          { formattedAddress
            && (
              <div>
                <span>{formattedAddress}</span>
              </div>
            )
          }
          <ReportSalesTax
            showFullVat={showFullVat}
            {...reportSalesTaxInfo}
          />
          { freeBillToText
            && (
              <div className="mt-3">
                <span>{freeBillToText}</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

ReportCompany.propTypes = {
  company: PropTypes.object,
  showFullVat: PropTypes.bool,
};

ReportCompany.defaultProps = {
  company: null,
  showFullVat: false,
};

export default ReportCompany;
