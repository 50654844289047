import React from 'react';
import PropTypes from 'prop-types';

import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import Filebox from 'core/assets/js/components/FileUploader/Filebox.jsx';


const Fileboxes = ({
  children,
  clickHandler,
  files,
  inputName,
  invertedColors,
  reduxInput,
  ...rest
}) => {
  const filesIsStringified = typeof files === 'string';
  const filesArray = filesIsStringified ? JSON.parse(files) : files;
  const output = filesArray ? filesArray.map((file, index) => (
    <Filebox
      key={file.url || index}
      file={file}
      clickHandler={clickHandler}
      invertedColors={invertedColors}
      {...rest}
    />
  )) : [];
  output.push(
    <input data-testid="fileUploader" key={`input-${inputName}`} {...reduxInput} name={inputName} type="hidden" />,
  );
  return (
    <div className="fileuploader__files clearfix">
      { output }
      { children }
    </div>
  );
};

Fileboxes.propTypes = {
  children: PropTypes.node,
  clickHandler: PropTypes.func,
  files: PropTypes.array,
  inputName: PropTypes.string,
  invertedColors: PropTypes.bool,
  reduxInput: reduxInputSpec.isRequired,
};
Fileboxes.defaultProps = {
  children: null,
  clickHandler: () => {},
  files: [],
  inputName: 'files',
  invertedColors: false,
};

export default Fileboxes;
