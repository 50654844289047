import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { isEmpty, pick } from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import SignupForm from 'accounts/assets/js/components/SignupForm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { ACCEPTED_UTM_KEYS, LOGO, IMG_SIZE } from 'core/assets/js/constants';
import { HOMEPAGE_URL } from 'core/urls';
import { STATUS } from 'invitations/assets/js/constants';
import { browserRedirect } from 'core/assets/js/lib/utils';
import { fetchPublicOrgInformation } from 'organizations/assets/js/data-services/organizations';
import { getViewState } from 'core/assets/js/ducks/view';
import { invitationLoadSuccessAC, validateInvitationActiveDS, selectActiveInvitation } from 'invitations/assets/js/ducks/invitation';
import { invitationManageUrl, invitationRejectUrl } from 'invitations/urls';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { orgDashboardUrl } from 'organizations/urls';
import { loginUrl, orgGetStartedUrl } from 'accounts/urls';
import { orgPublicSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { selectProfile } from 'accounts/assets/js/reducers/auth';


class SignupView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { token, orgAlias } = params;
    const prerequisites = [];

    if (params.orgAlias) {
      prerequisites.push(
        dispatch(fetchPublicOrgInformation({ orgAlias, authedAxios, url, componentName })),
      );
    }

    if (token) {
      prerequisites.push(
        dispatch(validateInvitationActiveDS({ token, url, authedAxios, componentName })),
      );
    } else {
      prerequisites.push(dispatch(invitationLoadSuccessAC({}, componentName)));
    }

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'SignupView';
  }

  constructor(props) {
    super(props);

    this.verifyUserSignupEligibility = this.verifyUserSignupEligibility.bind(this);
  }

  componentDidMount() {
    const { history, isValid, match } = this.props;

    if (isValid === false) {
      history.push(invitationRejectUrl(match.params.token));
    }
  }

  /**
   * Check whether the user should be allowed to sign up or not
   *
   * The user is being redirected to another page when:
   * - they are already logged in
   * - there is no invitation token in the url or explicit access given via the `allowSignup`
   *   qs param
   * - their invitation is not pending
   * - their invitation is pending but expired
   *
   * A user can sign up if:
   * - they are organization owner - indicated by the explicit allowSignup qs param
   * - they have received an invitation to join TalentDesk.io as managers or providers
   */
  verifyUserSignupEligibility() {
    const { invitation, location, match, profile, organization } = this.props;
    const guestWithInvitation = !profile && !isEmpty(invitation);
    let redirectTo;
    const { match: { params: { orgAlias, token } } } = this.props;

    const parsedQueryString = queryString.parse(location.search);
    if (parsedQueryString?.utm_campaign !== 'Self-Serve' && !token) {
      return (
        <RedirectRoute
          status={302}
          from={match.url}
          to={loginUrl()}
        />
      );
    }

    if (invitation.userId) {
      // user exists, so redirect to invitations manage url
      if (invitation) {
        return (
          <RedirectRoute
            status={302}
            from={match.url}
            to={invitationManageUrl(invitation.token, organization.alias)}
          />
        );
      }
      return (
        <RedirectRoute
          status={302}
          from={match.url}
          to={orgDashboardUrl(orgAlias)}
        />
      );
    }

    if (profile) {
      if (token) {
        redirectTo = invitationManageUrl(token, organization.alias);
      } else {
        redirectTo = profile.homeUrl;
      }
    }

    if (redirectTo) {
      browserRedirect(redirectTo);
      return (
        <p>{`Please wait, redirecting to ${redirectTo}...`}</p>
      );
    }

    if (
      guestWithInvitation
      && (invitation.status !== STATUS.PENDING
        || (invitation.status === STATUS.PENDING && invitation.hasExpired))
    ) {
      return (
        <RedirectRoute
          status={302}
          from={match.url}
          to={invitationRejectUrl(invitation.token)}
        />
      );
    }
    return true;
  }

  render() {
    const { invitation, location, match, organization } = this.props;
    const { match: { params: { orgAlias } } } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    const nextUrl = match.params.token
      ? orgGetStartedUrl(orgAlias)
      : parsedQueryString.next;

    const rejectUrl = invitation && invitation.token
      ? invitationRejectUrl(invitation.token)
      : null;

    // Determine if a user is allowed to signup
    const el = this.verifyUserSignupEligibility();
    if (typeof el === 'object') {
      return el;
    }

    const initialValues = {
      token: invitation.token,
      email: invitation.sentTo,
    };

    const utmData = pick(parsedQueryString, ACCEPTED_UTM_KEYS);

    if (!isEmpty(utmData)) {
      initialValues.utm_metadata = utmData;
    }

    if (invitation.contact) {
      const { firstName, lastName, skills } = invitation.contact;
      Object.assign(initialValues, {
        first_name: firstName,
        last_name: lastName,
        skills,
      });
    }

    if (invitation.bulkImportProperties?.firstName) {
      initialValues.first_name = invitation.bulkImportProperties.firstName;
    }
    if (invitation.bulkImportProperties?.lastName) {
      initialValues.last_name = invitation.bulkImportProperties.lastName;
    }
    if (invitation.bulkImportProperties?.phoneNumber) {
      initialValues.phone = invitation.bulkImportProperties.phoneNumber;
    }

    return (
      <TDApiConnected
        duck="invitation"
        component={this.constructor}
      >
        <div
          className="solo-container__logo-container mb-4"
        >
          {!isEmpty(organization) && (
            <React.Fragment>
              <OrganizationLogo
                url={organization.logo}
                orgName={organization.name}
                size={[IMG_SIZE.XLARGE, IMG_SIZE.XLARGE]}
              />

              <h3>{organization.orgName}</h3>
            </React.Fragment>
          )}

          {isEmpty(organization) && (
            <React.Fragment>
              <a className="solo-container__logo-container mb-4" href={HOMEPAGE_URL}>
                <img src={LOGO.DEFAULT} alt="TalentDesk.io" />
              </a>
            </React.Fragment>
          )}
        </div>

        <SignupForm
          withProfile={!!invitation.token}
          initialValues={initialValues}
          next={nextUrl}
          organization={organization}
        />

        {!isEmpty(organization) && (
          <p className="text-center small clearfix mt-4">
            powered by
            <a className="ml-3" href="/"><img src={LOGO.DEFAULT} alt="TalentDesk.io" width="109" height="23" /></a>
          </p>
        )}

        {!isEmpty(invitation) && invitation.orgName && (
          <p className="text-center clearfix mt-4">
            Invited by
            {' '}
            {invitation.orgName}
          </p>
        )}

        {rejectUrl && (
          <p className="text-center clearfix mt-5">
            <Link to={rejectUrl}>Reject invitation</Link>
          </p>
        )}
      </TDApiConnected>
    );
  }
}

SignupView.propTypes = {
  isValid: PropTypes.bool,
  invitation: invitationSpec.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  profile: PropTypes.object,
  organization: orgPublicSpec,
  match: routerMatchContentsSpec.isRequired,
};

SignupView.defaultProps = {
  organization: {},
  isValid: null,
  profile: null,
};

const mapStateToProps = (state) => {
  const invitationState = selectActiveInvitation(state);
  return {
    profile: selectProfile(state),
    invitation: invitationState.item,
    isValid: invitationState.isValid,
    organization: getViewState(state, SignupView.GetComponentName()).item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupView));
