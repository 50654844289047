import { isError, isEmpty } from 'lodash';
import queryString from 'query-string';

import { TASK_TABS, PROJECT_TABS } from 'core/assets/js/constants';
import { PROJECT_WORKSHEET_IMPORT_STEP, TIME_TRACKER_TABS } from 'projects/assets/js/constants';
import { prepareQueryStringComponent } from 'core/assets/js/lib/utils';

export const myTasksListUrl = (orgAlias) => {
  return `/${orgAlias}/my-tasks`;
};

/**
 * Load the project create view
 *
 * @param {String} orgAlias
 * @param {Object} [options]
 * @param {String} [options.entitySegment] - So in the router, we can handle opportunities and
 *                                           projects
 * @returns {String}
 */
export const projectCreateUrl = (orgAlias, { entitySegment = 'projects' } = {}) => (
  `/${orgAlias}/${entitySegment}/create`
);

/**
 * Load the project edit view
 *
 * @param {String} orgAlias
 * @param {Number|String} projectId
 * @param {Object} [options]
 * @param {String} [options.entitySegment] - So in the router, we can handle opportunities and
 *                                           projects
 * @returns {String}
 */
export const projectEditUrl = (orgAlias, projectId, { entitySegment = 'projects' } = {}) => (
  `/${orgAlias}/${entitySegment}/${projectId}/edit`
);
export const projectListUrl = (orgAlias, ids) => {
  let qs = '';
  const idsComponent = prepareQueryStringComponent('ids', ids);
  if (!isError(idsComponent)) {
    qs = `?${idsComponent}`;
  }

  return `/${orgAlias}/projects${qs}`;
};
export const projectInvitationsUrl = (orgAlias, queries = {}) => {
  const query = Object.keys(queries).length > 0 ? `?${queryString.stringify(queries)}` : '';
  return `/${orgAlias}/projects/invitations${query}`;
};

/**
 * Load the opportunities list view
 *
 * @param {String} orgAlias
 * @param {String} [tab]
 * @param {Object} [options]
 * @param {String} [options.entitySegment] - So in the router, we can handle the old URL, which was
 *                                           used in notifications
 * @returns {String}
 */
export const projectOpportunitiesUrl = (
  orgAlias, tab, { entitySegment = 'opportunities' } = {},
) => (
  `/${orgAlias}/${entitySegment}${tab ? `/${tab}` : ''}`
);

export const opportunityCreateUrl = orgAlias => projectCreateUrl(
  orgAlias, { entitySegment: 'opportunities' },
);

/**
 * Load the opportunity apply view
 *
 * @param {String} orgAlias
 * @param {Number|String} projectId
 * @param {Object} [options]
 * @param {String} [options.entitySegment] - So in the router, we can handle the old URL, which was
 *                                           used in notifications
 * @returns {String}
 */
export const projectOpportunitiesApplyUrl = (
  orgAlias, projectId, { entitySegment = 'opportunities' } = {},
) => (
  `/${orgAlias}/${entitySegment}/${projectId}/apply`
);

export const projectOpportunityEditUrl = (orgAlias, projectId) => projectEditUrl(
  orgAlias, projectId, { entitySegment: 'opportunities' },
);

/**
 * Load the opportunity application view
 *
 * @param {String} orgAlias
 * @param {Number|String} projectId
 * @param {Number|String} applicationId
 * @param {Object} [options]
 * @param {String} [options.entitySegment] - So in the router, we can handle the old URL, which was
 *                                           used in notifications
 * @returns {String}
 */
export const projectApplicationUrl = (
  orgAlias, projectId, applicationId, { entitySegment = 'opportunities' } = {},
) => (
  `/${orgAlias}/${entitySegment}/${projectId}/applications/${applicationId}`
);

/**
 * Load the project view
 *
 * @param {String} orgAlias
 * @param {Number|String} projectId
 * @param {String} tabName
 * @param {Object} [options]
 * @param {String} [options.entitySegment] - So in the router, we can handle opportunities and
 *                                           projects
 * @returns {String}
 */
export const projectViewUrl = (
  orgAlias, projectId, tabName = null, { entitySegment = 'projects' } = {},
) => {
  const tab = !tabName ? PROJECT_TABS.DASHBOARD : tabName;
  return `/${orgAlias}/${entitySegment}/${projectId}/${tab}`;
};

export const opportunityViewUrl = (orgAlias, projectId, tabName = null) => projectViewUrl(
  orgAlias, projectId, tabName, { entitySegment: 'opportunities' },
);

export const projectRequestBudgetUrl = (orgAlias, projectId) => (
  `/${orgAlias}/projects/${projectId}/request-budget`
);
export const projectSubmitWorksheetUrl = (orgAlias, projectId, taskId) => (
  `/${orgAlias}/projects/${projectId}/add-worksheet${taskId ? `?taskid=${taskId}` : ''}`
);
export const projectClaimExpenseUrl = (orgAlias, projectId) => (
  `/${orgAlias}/projects/${projectId}/claim-expense`
);
// Tasks
export const projectCreateTaskUrl = (orgAlias, projectId) => (
  `/${orgAlias}/projects/${projectId}/create-task`
);
export const projectViewTaskUrl = (
  orgAlias, projectId, taskId, tabName = null, action = null, userId = null,
) => {
  const tab = tabName === null ? TASK_TABS.DASHBOARD : tabName;
  const qs = action ? `?action=${action}` : '';
  const parts = [orgAlias, 'projects', projectId, 'tasks', taskId, tab, userId];

  return `/${parts.filter(p => !!p).join('/')}${qs}`;
};
export const projectEditTaskUrl = (orgAlias, projectId, taskId) => (
  `/${orgAlias}/projects/${projectId}/tasks/${taskId}/edit`
);
export const projectTaskAssignmentUrl = (orgAlias, projectId, taskId, userId) => (
  `/${orgAlias}/projects/${projectId}/tasks/${taskId}/discussion/${userId}`
);
export const projectApplicationsListUrl = (orgAlias, projectId) => (
  `/${orgAlias}/projects/${projectId}/applications`
);
export const projectApplicationEditUrl = (orgAlias, projectId, applicationId) => (
  `/${orgAlias}/opportunities/${projectId}/applications/${applicationId}/edit`
);
export const timeTrackerUrl = (orgAlias, activeTab = TIME_TRACKER_TABS.TIME_ENTRIES) => (
  `/${orgAlias}/time-tracker/${activeTab}`
);

// Api urls
export const projectAddManagersApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/add-managers`
);
export const projectAddProvidersApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/add-providers`
);
export const projectSelectPaymentsReviewerApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/select-payments-reviewer`
);
export const projectCreateApiUrl = orgAlias => (
  `/api/${orgAlias}/projects/create`
);
export const projectFetchManagersApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/fetch-managers`
);
export const projectFetchProvidersApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/fetch-providers`
);
export const projectRemoveMemberApiUrl = (orgAlias, projectId, userId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/members/${userId}`
);
export const projectLeaveApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/leave`
);
export const projectListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/projects`
);
export const projectCsvApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/projects/csv`
);
export const projectMembersCsvApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/projects/members/csv`
);
export const profileProjectListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/projects/profile`
);
export const projectThinListApiUrl = (orgAlias, qs = null, url = '') => (
  `${url}/api/${orgAlias}/projects/thin-list${qs ? `?${qs}` : ''}`
);
export const projectMembersListApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/members`
);
export const projectMembersSearchApiUrl = (orgAlias, projectId, kw, ofType, status, url = '') => {
  const urlParts = { kw, ofType, status };
  const urlParams = Object.keys(urlParts).filter(k => !!urlParts[k]).map(k => (
    prepareQueryStringComponent(k, urlParts[k])
  ));
  return `${url}/api/${orgAlias}/projects/${projectId}/members?${urlParams.join('&')}`;
};
export const projectInvitationsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/projects/invitations`
);
export const projectPendingInvitationCountApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/projects/pending-invitations-count`
);
export const projectUpdateApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/edit`
);
export const projectViewApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}`
);
export const projectInvitationResendApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/invitations/resend`
);
export const projectInvitationManageApiUrl = (orgAlias, projectId, action) => (
  `/api/${orgAlias}/projects/${projectId}/invitations/${action}`
);
export const projectInvitationCancelApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/invitations/cancel`
);
export const projectCreateTaskApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/tasks`
);
export const projectUpdateTaskApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}`
);
export const projectArchiveTaskApiUrl = projectUpdateTaskApiUrl;
export const projectUnarchiveTaskApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/unarchive`
);
export const projectUpdateTaskProgressApiUrl = (
  orgAlias, projectId, taskId, assignmentId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments/${assignmentId}/progress`
);
export const projectMarkDiscussionAsReadApiUrl = (orgAlias, projectId, taskId, senderId = '', url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments/mark-read${senderId ? `/${senderId}` : ''}`
);
export const projectFetchTaskApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}`
);
export const projectTaskListApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks`
);
export const projectMyTasksListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/tasks`
);
export const projectMyTasksCsvApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/tasks/csv`
);
export const projectMyTaskAssigneesCsvApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/tasks/assignees/csv`
);
export const projectTaskThinListApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/thin`
);
export const projectFetchTaskAssignmentsApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments`
);
export const projectFetchTaskAssignmentsPaginatedApiUrl = (orgAlias, projectId, taskId) => (
  `/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments-paginated`
);
export const projectFetchTaskDeliverablesApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/deliverables`
);
export const projectFetchTaskManagersApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/managers`
);
export const projectAddTaskManagersApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/managers`
);
export const projectFetchTaskItemsApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/items`
);
export const projectCreateTaskItemApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/items`
);
export const projectUpdateTaskItemApiUrl = (orgAlias, projectId, taskId, itemId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/items/${itemId}`
);
export const projectArchiveTaskItemApiUrl = projectUpdateTaskItemApiUrl;
export const projectToggleTaskItemApiUrl = (orgAlias, projectId, taskId, itemId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/items/${itemId}/toggle`
);
// Discussion board
export const projectTaskInfoFetchApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/info`
);
export const projectTaskMessagesFetchApiUrl = (
  orgAlias, projectId, taskId, page, after, participantId, url = '',
) => {
  const qsParams = [];
  if (page) {
    qsParams.push(`page=${page}`);
  }
  if (after) {
    qsParams.push(`after=${after}`);
  }

  const qs = !isEmpty(qsParams) ? `?${qsParams.join('&')}` : '';
  const baseUrl = `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/messages`;

  return participantId ? `${baseUrl}/${participantId}${qs}` : `${baseUrl}${qs}`;
};
export const projectTaskMessageCreateApiUrl = (
  orgAlias, projectId, taskId, participantId, url = '',
) => {
  const urlParts = ['api', orgAlias, 'projects', projectId, 'tasks', taskId, 'messages'];

  if (participantId) {
    urlParts.push(participantId);
  }

  return `${url}/${urlParts.join('/')}`;
};
export const projectTaskMessageRequestBudgetAmendmentApiUrl = (
  orgAlias, projectId, taskId, participantId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/request-budget-amendment/${participantId}`
);
export const projectTaskMessageAcceptBudgetAmendmentApiUrl = (orgAlias, projectId, taskId) => (
  `/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/accept-budget-amendment`
);
export const projectTaskMessageCancelBudgetAmendmentApiUrl = (
  orgAlias, projectId, taskId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/cancel-budget-amendment`
);
export const projectTaskMessageRejectBudgetAmendmentApiUrl = (orgAlias, projectId, taskId) => (
  `/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/reject-budget-amendment`
);
export const projectTaskRequestCompletionApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/request-completion`
);
export const projectTaskCompleteApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/complete`
);
export const projectTaskStopApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/stop`
);
export const projectTaskCloneApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/clone`
);
export const projectTaskApproveApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/approve`
);
export const projectCopyAttachmentsApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/attachments/copy`
);
export const projectTaskRemoveAttachmentApiUrl = (orgAlias, projectId, taskId, attachmentId) => (
  `/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/attachments/${attachmentId}`
);
export const projectTaskWatchApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/watch`
);
export const projectTaskUnwatchApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/unwatch`
);
export const projectTaskLeaveApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/leave`
);
export const projectTaskCompleteAssignmentApiUrl = (
  orgAlias, projectId, taskId, participantId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments/${participantId}/complete`
);
export const projectTaskAssignmentReOpenApiUrl = (
  orgAlias, projectId, taskId, participantId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments/${participantId}/reopen`
);
export const projectTaskChangeOwnerApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/change-owner`
);
export const projectTaskClaimOwnershipApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/claim-ownership`
);
export const projectTaskReviewApiUrl = (orgAlias, projectId, taskId, participantId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments/${participantId}/review`
);
export const projectTaskDeliverableToggleApiUrl = (
  orgAlias, projectId, taskId, deliverableId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/deliverables/${deliverableId}/toggle`
);
export const projectTaskDeliverableStatusToggleApiUrl = (
  orgAlias, projectId, taskId, attachmentId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/attachments/${attachmentId}`
);
export const projectTaskDeliverablesUploadApiUrl = (
  orgAlias, projectId, taskId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/deliverables`
);
export const projectTaskInvitationAcceptUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/accept-invitation`
);
export const projectTaskInvitationRejectUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/reject-invitation`
);
export const projectTaskInvitationCancelUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/cancel-invitation`
);
export const projectFetchTaskListForWorksheetsUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/tasks-for-worksheet`
);
export const projectFetchTaskForWorksheetUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/tasks-for-worksheet?taskId=${taskId}`
);
export const projectTasksAssignmentAnalyticsUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/tasks-worksheet-analytics`
);
export const projectTaskCreateAssignmentsApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assign`
);
export const projectTaskRemoveAssignmentApiUrl = (
  orgAlias, projectId, taskId, userId, deleteWorkTimeBlocks, url = '') => {
  const query = deleteWorkTimeBlocks ? '?deleteWorkTimeBlocks=1' : '';
  return (
    `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments/${userId}${query}`
  );
};
export const projectTaskFetchManagersApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/task-managers`
);
export const projectTaskRemoveManagersApiUrl = (
  orgAlias, projectId, taskId, taskManagerId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/task-managers/${taskManagerId}`
);
export const projectTaskAddManagersApiUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/task-managers`
);
export const projectRequestApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/request-budget`
);

export const projectArchiveApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/archive`
);

export const projectUnarchiveApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/unarchive`
);

export const projectFetchOutstandingCountsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/outstanding-counts`
);
export const projectStateUpdateApiUrl = (orgAlias, projectId, action) => (
  `/api/${orgAlias}/projects/${projectId}/state-update/${action}`
);
export const projectReturnFundsApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/return-funds`
);
export const projectNextWorksheetInfoApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/next-worksheet`
);
export const projectAddWorksheetApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/add-worksheet`
);
export const projectUpdateWorksheetApiUrl = (orgAlias, projectId, worksheetId) => (
  `/api/${orgAlias}/projects/${projectId}/update-worksheet/${worksheetId}`
);
export const projectCancelWorksheetApiUrl = (orgAlias, projectId, worksheetId) => (
  `/api/${orgAlias}/projects/${projectId}/cancel-worksheet/${worksheetId}`
);
export const projectChangeOwnerApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/change-owner`
);
export const projectClaimOwnershipApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/claim-ownership`
);
export const projectCloneApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/clone`
);
export const projectClaimExpenseApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/claim-expense`
);
export const projectUpdateExpenseApiUrl = (orgAlias, projectId, expenseId) => (
  `/api/${orgAlias}/projects/${projectId}/update-expense/${expenseId}`
);
export const projectCancelExpenseApiUrl = (orgAlias, projectId, expenseId) => (
  `/api/${orgAlias}/projects/${projectId}/cancel-expense/${expenseId}`
);
export const projectNextExpenseInfoApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/next-expense`
);
export const projectOpportunitiesListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/projects/opportunities`
);
export const projectOpportunityFetchApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/projects/opportunities/${id}`
);
export const projectApplicationCreateApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications`
);
export const projectApplicationUpdateApiUrl = (orgAlias, projectId, applicationId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications/${applicationId}`
);
export const projectApplicationResendApiUrl = (orgAlias, projectId, applicationId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications/${applicationId}/resend`
);
export const projectApplicationsListApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications`
);
export const projectApplicationViewApiUrl = (orgAlias, projectId, applicationId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications/${applicationId}`
);
export const projectApplicationAcceptApiUrl = (orgAlias, projectId, applicationId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications/${applicationId}/approve`
);
export const projectApplicationRejectApiUrl = (orgAlias, projectId, applicationId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications/${applicationId}/reject`
);
export const projectApplicationCancelApiUrl = (orgAlias, projectId, applicationId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/applications/${applicationId}`
);
export const projectTaskAssignmentAnalyticsUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/worksheet-analytics`
);
export const projectTaskAnswersUrl = (orgAlias, projectId, taskId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/answers`
);
export const projectAnswersApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/projects/${projectId}/answers`
);

export const projectUpdateTaskAssignmentCapApiUrl = (
  orgAlias, projectId, taskId, assignmentId, url = '',
) => (
  `${url}/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/assignments/${assignmentId}/assignment-cap`
);

export const projectCreateWorksheetsAndCompleteTaskApiUrl = (orgAlias, projectId, taskId) => (
  `/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/create-worksheets-and-complete`
);
export const projectCreateWorksheetsForTaskApiUrl = (orgAlias, projectId, taskId) => (
  `/api/${orgAlias}/projects/${projectId}/tasks/${taskId}/create-worksheets`
);

export const getProjectOpportunityInviteesApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/opportunity-invitees`
);
export const cancelOpportunityInvitationApiUrl = (orgAlias, projectId, userId) => (
  `/api/${orgAlias}/projects/${projectId}/cancel-invitation/${userId}`
);
export const inviteToApplyForOpportunityApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/invite-to-apply`
);
export const getOpportunityInvitationsApiUrl = orgAlias => (
  `/api/${orgAlias}/projects/opportunity-invitations`
);

export const projectStartApplicationsApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/start-applications`
);

export const projectCloseApplicationsApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/close-applications`
);

export const projectWorksheetImportUrl = (
  orgAlias, projectId, step = PROJECT_WORKSHEET_IMPORT_STEP.DOWNLOAD,
) => `/${orgAlias}/projects/${projectId}/${PROJECT_TABS.WORKSHEETS}/import/${step}`;

export const projectWorksheetImportCsvDownloadUrl = (
  orgAlias, projectId,
) => `/api/${orgAlias}/import/csv/${projectId}/worksheets/csv-template`;

export const fetchWorksheetCsvAnalysisApiUrl = (
  orgAlias, projectId,
) => `/api/${orgAlias}/import/csv/${projectId}/worksheets/results-analysis`;

export const importWorksheetCsvApiUrl = (
  orgAlias, projectId,
) => `/api/${orgAlias}/projects/${projectId}/import-worksheet`;

export const createWorkTimeBlockApiUrl = (orgAlias, isStopWatch) => (
  `/api/${orgAlias}/time-tracking${isStopWatch ? '?isStopWatch=true' : ''}`
);

export const editWorkTimeBlockApiUrl = (orgAlias, workTimeBlockId, isStopWatch) => (
  `/api/${orgAlias}/time-tracking/${workTimeBlockId}${isStopWatch ? '?isStopWatch=true' : ''}`
);

export const getUnusedTimeTrackingApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/time-tracking${userId ? `?userId=${userId}` : ''}`
);
export const getUsedTimeTrackingApiUrl = orgAlias => (
  `${getUnusedTimeTrackingApiUrl(orgAlias)}/used`
);
export const deleteTimeTrackingApiUrl = (orgAlias, id) => (
  `${getUnusedTimeTrackingApiUrl(orgAlias)}/${id}`
);
export const createTimeTrackingWorksheetsApiUrl = (orgAlias, projectId) => (
  `${getUnusedTimeTrackingApiUrl(orgAlias)}/${projectId}`
);
export const getTimeTrackingTaskOptionsApiUrl = orgAlias => (
  `${getUnusedTimeTrackingApiUrl(orgAlias)}/task-options`
);
export const getProjectUnreadDiscussionsPerTaskApiUrl = (orgAlias, projectId) => (
  `/api/${orgAlias}/projects/${projectId}/discussions`
);
