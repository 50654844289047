import React from 'react';

import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import NumberFormat from 'react-number-format';
import { formatDate } from 'core/assets/js/lib/utils';
import { TASK_STATUS_LABEL } from 'projects/assets/js/constants';
import { worksheetItemSpec } from 'projects/assets/js/lib/objectSpecs';
import { DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';

const WorksheetTaskItemCard = ({
  item: { title, status, rate_amount, rate_unit, deadline, currencySymbol },
}) => (
  <>
    <div className="worksheet-item-card__title mb-1">
      {title}
    </div>

    <div className="worksheet-item-card__meta d-flex flex-row">
      <div>
        <span className="discreet mr-2">Rate</span>
        <NumberFormat
          key="rate"
          value={rate_amount}
          prefix={currencySymbol}
          displayType="text"
          thousandSeparator
        />
        {rate_unit && RATE_UNIT_FORMAT[rate_unit].abbreviation}
      </div>

      <div>
        <span className="discreet mr-2">Status</span>
        <span>{TASK_STATUS_LABEL[status]}</span>
      </div>

      { deadline && (
        <div>
          <span className="discreet mr-2">Deadline</span>
          <span>{formatDate(deadline, DATE_FORMAT_DEFAULT)}</span>
        </div>
      )}
    </div>
  </>
);
WorksheetTaskItemCard.propTypes = {
  item: worksheetItemSpec.isRequired,
};

export default WorksheetTaskItemCard;
