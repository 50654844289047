import React from 'react';
import PropTypes from 'prop-types';

import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

class MarkdownInput extends React.Component {
  constructor(props) {
    super(props);

    this.textInput = null;
    this.handleMakeLink = this.handleMakeLink.bind(this);

    this.setTextInputRef = (element) => {
      this.textInput = element;
    };

    this.setTextInputRef = this.setTextInputRef.bind(this);
  }

  async handleMakeLink() {
    const { inputField } = this.props;
    const { value: text, selectionStart: start, selectionEnd: end } = this.textInput;
    const linkRegExp = /\[([^\]]+)\]\(([^)]+)\)/g;

    let selectedText = text.slice(start, end);
    let urlInserted = 'url';
    let highlightStart = null;
    let highlightEnd = null;
    let textWithLink = `[label](${urlInserted})`;

    if (selectedText) {
      const containsLink = selectedText.match(linkRegExp);

      if (containsLink) {
        urlInserted = selectedText.replace(linkRegExp, '$2');
        selectedText = selectedText.replace(linkRegExp, '$1');
      }

      const firstPart = `${text.slice(0, start).trim()} [${selectedText}](`.trim();
      const secondPart = `) ${text.slice(end).trim()}`.trim();

      highlightStart = firstPart.length;
      highlightEnd = highlightStart + urlInserted.length;

      textWithLink = `${firstPart}${urlInserted}${secondPart}`;
    } else {
      textWithLink = text.endsWith(textWithLink) ? text : `${text.trim()} ${textWithLink}`.trim();
      highlightStart = textWithLink.indexOf(urlInserted);
      highlightEnd = highlightStart + urlInserted.length;
    }

    await inputField.onChange(textWithLink.trim());

    if (highlightStart !== highlightEnd) {
      this.textInput.selectionStart = highlightStart;
      this.textInput.selectionEnd = highlightEnd;
      this.textInput.focus();
    }
  }

  render() {
    const { className, placeholder, autoComplete, inputField, rest } = this.props;
    const classNames = ['form-control', 'markdown-input'];

    if (className) {
      classNames.push(className);
    }

    return (
      <React.Fragment>
        <input
          id={`fieldId-${rest.name || inputField.name}`}
          ref={this.setTextInputRef}
          {...inputField}
          className={classNames.join(' ')}
          placeholder={placeholder}
          autoComplete={autoComplete}
          {...rest}
        />
        <TDButton
          variant={BS_STYLE.DEFAULT}
          bsSize={BS_SIZE.XSMALL}
          className="text-uppercase add-link"
          onClick={this.handleMakeLink}
          label="Link"
        />
      </React.Fragment>
    );
  }
}

MarkdownInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  inputField: reduxInputSpec.isRequired,
  rest: PropTypes.object,
};

MarkdownInput.defaultProps = {
  placeholder: '',
  className: '',
  autoComplete: '',
  rest: {},
};

export default MarkdownInput;
