import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { MODAL_SIZES } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';


class ModalPanel extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    const { onOpen } = this.props;
    onOpen();
  }

  handleCloseModal() {
    const { onClose, dispatch, modalId } = this.props;
    onClose();
    dispatch(modalCloseAC(modalId));
  }

  render() {
    const {
      body, footer, noFooter, showCloseButton, enforceFocus,
      heading, open, children, headingClassName, noHeader, size, 'data-testid': dataTestId,
    } = this.props;
    return (
      <div onClick={ev => ev.stopPropagation()} className="static-modal">
        { open
          && (
            <Modal
              data-testid={dataTestId}
              show={open}
              dialogClassName="modal-panel"
              onHide={this.handleCloseModal}
              enforceFocus={enforceFocus}
              backdrop="static"
              size={size}
            >
              { !noHeader && (
                <Modal.Header className={headingClassName} closeButton={showCloseButton}>
                  <Modal.Title>{heading}</Modal.Title>
                </Modal.Header>
              )}
              <Modal.Body>
                {body || children}
              </Modal.Body>
              { !noFooter && (
                <Modal.Footer>
                  { footer || (
                    <TDButton
                      onClick={this.handleCloseModal}
                      label="Close"
                    />
                  )}
                </Modal.Footer>
              )}
            </Modal>
          )
        }
      </div>
    );
  }
}

ModalPanel.propTypes = {
  'data-testid': PropTypes.string,
  modalId: PropTypes.string.isRequired,
  body: PropTypes.node,
  enforceFocus: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  children: PropTypes.node,
  footer: PropTypes.node,
  headingClassName: PropTypes.string,
  size: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};
ModalPanel.defaultProps = {
  'data-testid': '',
  enforceFocus: true,
  body: null,
  showCloseButton: true,
  noFooter: false,
  noHeader: false,
  children: null,
  headingClassName: null,
  footer: null,
  heading: null,
  open: false,
  size: MODAL_SIZES.LARGE,
  onOpen: () => {},
  onClose: () => {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ModalPanelReduxConnect = connect(
  null,
  mapDispatchToProps,
)(ModalPanel);

export default ModalPanelReduxConnect;
