import React from 'react';

import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import { ICON } from 'core/assets/js/constants';

const CHECKMARK_CLASS = `${ICON.CHECKMARK_CIRCLE} mr-3`;

const ImportCreateUpload = () => {
  return (
    <div className="import-worksheets-page--wrapper bg-light py-4 mx-0 mb-4 d-flex">
      <div className="import-worksheets-page--download--section mt-4">
        <FileUploaderDirectField
          accept="text/csv"
          className="import-worksheets-page--download--btn ml-5"
          label={(
            <span>
              <span className={`${ICON.DOWNLOAD} mr-3`} />
              Upload template
            </span>
          )}
          name="file"
          showAsButton
        />
      </div>
      <div className="import-worksheets-page--info--section mt-4">
        <p className="mb-0 import-worksheets-page--download--info">
          <i className={CHECKMARK_CLASS} />
          The name of your file should end with .csv.
        </p>
        <p className="mb-0 import-worksheets-page--download--info">
          <i className={CHECKMARK_CLASS} />
          Your file&apos;s first row should include headers.
        </p>
      </div>
    </div>
  );
};

export default ImportCreateUpload;
