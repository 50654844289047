import PropTypes from 'prop-types';
import React from 'react';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import AnswerRenderer from 'core/assets/js/components/AnswerRenderer.jsx';

const InvoiceCustomFields = ({ customFields: { projects } }) => {
  const projectIds = projects ? Object.keys(projects) : [];

  if (projectIds.length === 0) {
    return null;
  }

  return (
    <AreaCollapsible
      headingChildren={<span>Custom fields</span>}
      className="mt-5"
      fieldSet
      contentChildren={
        projectIds.map((projectId) => {
          const { answers, reference, title, tasks } = projects[projectId];
          const taskIds = tasks ? Object.keys(tasks) : [];
          return (
            <div key={projectId} className="mb-5 invoice-custom-fields-content">
              <h3 className="heading-block mb-0">{`${title} (${reference})`}</h3>

              <h4 className="mb-3 ml-4 mr-4">Project fields</h4>
              {answers.length === 0 && '-'}

              {answers.map(({ answer, customFieldType, payload, question, questionId }) => (
                <AnswerRenderer
                  key={questionId}
                  labelClass="label ml-4"
                  valueClass="font-weight-bolder value mr-4"
                  answer={{
                    body: answer,
                    question: {
                      payload,
                      label: `${question}:`,
                      type: customFieldType,
                    },
                  }}
                  withWrapper={false}
                />
              ))}

              {taskIds.length > 0 && taskIds.map((taskId) => {
                const task = tasks[taskId];
                return (
                  <>
                    <h4 className="mt-5 mb-3 ml-4 mr-4">{`Task: ${task.title}`}</h4>

                    {task.answers.map(({
                      answer, customFieldType, payload, question, questionId,
                    }) => (
                      <AnswerRenderer
                        key={questionId}
                        labelClass="label ml-4"
                        valueClass="font-weight-bolder value mr-4"
                        answer={{
                          body: answer,
                          question: {
                            payload,
                            label: `${question}:`,
                            type: customFieldType,
                          },
                        }}
                        withWrapper={false}
                      />
                    ))}
                  </>
                );
              })}
            </div>
          );
        })}
    />
  );
};

InvoiceCustomFields.propTypes = {
  customFields: PropTypes.object.isRequired,
};

export default InvoiceCustomFields;
