import React from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';


const TaskChecklist = ({
  label, items, className, itemClassName, error, onItemAdded, showAddItem,
}) => (
  <div
    className={['form-group', 'task-checklist', className].filter(c => !!c).join(' ')}
  >
    {label && (
      <label>{label}</label>
    )}
    <ul>
      {!items.length && (
        <li className="discreet">
          There aren’t any checklist items yet
        </li>
      )}
      {items.length > 0 && items.map((item, index) => (
        <li key={`item-${index + 1}`} className={itemClassName}>
          {item}
        </li>
      ))}
      {showAddItem && (
        <li className="task-checklist__add-items mt-0">
          <button
            type="button"
            className="font-weight-normal text-primary btn btn-link px-0 mt-n"
            onKeyPress={onItemAdded}
            onClick={onItemAdded}
          >
            <i className={`${ICON.ADD_CIRCLE} mr-3`} />
            Add item
          </button>
        </li>
      )}
    </ul>

    {error && (
      <div className="has-error">
        <span className="help-block">{error}</span>
      </div>
    )}
  </div>
);

TaskChecklist.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  error: PropTypes.string,
  onItemAdded: PropTypes.func,
  showAddItem: PropTypes.bool,
};

TaskChecklist.defaultProps = {
  label: null,
  items: [],
  className: '',
  itemClassName: '',
  error: null,
  showAddItem: true,
  onItemAdded: () => {},
};

export default TaskChecklist;
