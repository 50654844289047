import React from 'react';
import PropTypes from 'prop-types';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';

const ApiLoading = ({ loadingEnabled, blockingLoading, skeletonComponent }) => {
  // While loading, If skeleton component is passed,
  // show skeleton component instead the loading icon
  const Skeleton = skeletonComponent;

  if (skeletonComponent) {
    return (
      <React.Fragment>
        <LoadingComponent />
        <Skeleton />
      </React.Fragment>
    );
  }

  if (!loadingEnabled) {
    return (
      <div className="td_api_connected-loaded" />
    );
  }
  // Show only the loading element without rendering the component till data is fully loaded
  if (blockingLoading) {
    return <LoadingComponent />;
  }

  return null;
};

ApiLoading.propTypes = {
  blockingLoading: PropTypes.bool,
  loadingEnabled: PropTypes.bool,
  skeletonComponent: PropTypes.oneOfType([
    PropTypes.func, PropTypes.element, PropTypes.node,
  ]),
};

ApiLoading.defaultProps = {
  blockingLoading: true,
  loadingEnabled: true,
  skeletonComponent: null,
};

export default ApiLoading;
