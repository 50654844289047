import { isEmpty, get, omit, pick } from 'lodash';
import { removeNilValues, removeUndefinedValues } from 'core/assets/js/lib/utils';
import { DEFAULT_GRACE_PERIOD, PAYMENT_METHODS } from 'settings/assets/js/constants';
import { isSEPA } from 'finance/assets/js/constants';
import BankAccountVariant from 'settings/assets/js/lib/BankAccountVariant';
import { logger } from 'core/assets/js/lib/Logger';
import { SYSTEM } from 'core/assets/js/constants';
import getRegistrationInfo from 'core/assets/js/messages';
import {
  getFeVat,
  getFeGst,
  getFeHst,
  getFeFullVat,
  getFeIsVatRegistered,
  getFeIsGstRegistered,
  getFeIsHstRegistered,
  getFeTaxSystemType,
  getSystemSalesTaxValues,
} from 'settings/assets/js/lib/sales_tax_utils';

class FinancialEntity {
  static getEmpty() {
    return new FinancialEntity({
      orgId: SYSTEM.ID,
      user: {
        profile: {
          address_components: {
            country_code: 'US',
          },
        },
      },
    });
  }

  /**
   * Builds an instance of a FinancialEntity representing the system
   *
   * @param {InvoicingEntity} the invoicing entity of talentdesk
   * @param {BankAccountVariant} the bank of talentdesk
   */
  static buildSystemFE({ invoicingEntity, bank }) {
    if (!invoicingEntity) {
      throw new Error('unknown invoicing entity');
    }

    const { registration_number: systemNumber } = invoicingEntity;
    const {
      ID: systemOrgId,
      USER_ID: userId,
      EMAIL: email,
    } = SYSTEM;
    const media = JSON.parse(invoicingEntity.logo_metadata);
    const street = `${invoicingEntity.address_line_1} ${invoicingEntity.address_line_2 || ''}`;
    const company = {
      name: invoicingEntity.name,
      logo: Array.isArray(media) ? media[0].url : media.url,
      logo_mimetype: Array.isArray(media) ? media[0].mimetype : media.mimetype,
      registration_number: systemNumber,
      invoicable: true,
      is_incorporated: true,
      ...getSystemSalesTaxValues(invoicingEntity),
      address: invoicingEntity.address.description,
      address_components: {
        city: invoicingEntity.city,
        country: invoicingEntity.country,
        country_code: invoicingEntity.country_code,
        street,
        postal_code: invoicingEntity.postal_code,
      },
    };

    const systemFE = new FinancialEntity({
      systemOrgId,
      userId,
      name: company.name,
      user: {
        id: userId,
        email,
      },
      company,
      bank,
      isSystem: true,
    });


    return systemFE;
  }

  constructor(props) {
    if (props instanceof FinancialEntity) {
      return props;
    }
    this.init(props);
  }

  init({
    id, orgId = null, userCardId = null, userId = null, name = null, user = null,
    company = null,
    bank = null, dimBankAccountId = null, vatPercent = null,
    isSystem = false, isContractor = false, isClient = false,
    isEmployee = false, aorEnabled = false,
    gracePeriod = DEFAULT_GRACE_PERIOD, comments = '', footnote = '', freeBillingDetailsText = '',
    orgProviderGracePeriod = 0,
    hideInvoicePaymentDetails = false,
    countryFallback,
  } = {}, { failWhenNoCountry = true } = {}) {
    // TODO: reinstate this when InvoiceOwner.jsx/InvoiceRecipient.jsx is refactored
    // if (!user && !company) {
    //   throw new Error('cannot create financial entity without user or company');
    // }
    this.id = id;
    if (isSystem) {
      this.orgId = SYSTEM.ID;
      this.userId = SYSTEM.USER_ID;
    } else {
      if (!orgId) {
        throw new Error('cannot create financial entity without organization id');
      }
      this.orgId = orgId;
      this.userId = userId || get(user, 'id');
    }
    this.userCardId = userCardId;
    this.dimBankAccountId = dimBankAccountId || get(bank, 'id');
    this.details = {
      comments,
      company,
      footnote,
      gracePeriod,
      orgProviderGracePeriod,
      hideInvoicePaymentDetails,
      freeBillingDetailsText,
      isClient,
      isContractor,
      isEmployee,
      aorEnabled,
      isSystem,
      name,
      vatPercent,
      user: isEmpty(user) ? {} : {
        ...pick(user, ['id', 'email']),
        profile: {
          ...pick(get(user, 'profile', {}), ['firstName', 'lastName', 'name', 'jobTitle', 'address_components']),
          address: {
            description: get(user, 'profile.address.description', ''),
          },
        },
      },
    };

    if (bank && !(bank instanceof BankAccountVariant)) {
      const bankUserId = bank.userId || userId;
      if (!bankUserId) {
        throw new Error('user id is required in financial entity');
      }
      Object.assign(this.details, { bank: new BankAccountVariant({
        ...bank,
        userId: bankUserId,
      }) });
    } else {
      Object.assign(this.details, { bank });
    }

    const countryCode = this.getCountryCode();
    if (!countryCode) {
      if (countryFallback) {
        logger.info(`using country fallback ${countryFallback} for org id ${this.orgId}, user id ${this.userId} `);
        Object.assign(this.details.user.profile, {
          address_components: {
            country_code: countryFallback,
          },
        });
      } else if (failWhenNoCountry) {
        logger.error(`financial entity always needs a country code - org id ${this.orgId}, user id ${this.userId} `);
      }
    }
  }

  get name() { //eslint-disable-line
    throw new Error('cannot get name directly');
  }

  get user() { //eslint-disable-line
    throw new Error('cannot get user directly');
  }

  get company() { //eslint-disable-line
    throw new Error('cannot get company directly');
  }

  get bank() { //eslint-disable-line
    throw new Error('cannot get bank directly');
  }

  get vatPercent() { //eslint-disable-line
    throw new Error('cannot get vatPercent directly');
  }

  isEmpty() {
    const { user, company } = this.details;
    return isEmpty(user) && isEmpty(company);
  }

  getCompany() {
    const { company } = this.details;
    return company;
  }

  getFootnote() {
    const { footnote } = this.details;
    if (this.isSystem()) {
      const companyName = this.getCompanyName();
      const registrationNumber = this.getRegistrationNumber();
      return footnote || getRegistrationInfo(companyName, registrationNumber);
    }
    return footnote;
  }

  setBank(bank) {
    if (!bank) {
      return;
    }
    if (!(bank instanceof BankAccountVariant)) {
      throw new Error('expecting bank account variant');
    }
    this.dimBankAccountId = bank.id;
    Object.assign(this.details, { bank });
  }

  setProfileAddressComponents(addressComponents) {
    Object.assign(this.details.user.profile, { address_components: addressComponents });
  }

  setCompanyAddressComponents(addressComponents) {
    if (this.details.company) {
      Object.assign(this.details.company, { address_components: addressComponents });
    }
  }

  isSystem() {
    const { isSystem } = this.details;
    return isSystem;
  }

  isIncorporated() {
    const { company } = this.details;
    return company && company.is_incorporated;
  }

  getAddress() {
    const { company } = this.details;
    if (company && company.address) {
      if (typeof company.address === 'object') {
        if (company.address.label) {
          return company.address.label;
        }
      } else {
        return company.address;
      }
    }
    return this.getProfileAddress();
  }

  getCompanyAddressComponents() {
    const { company } = this.details;
    // If the user is not incorporated, then ignore their company address
    if (company?.is_incorporated && !isEmpty(company.address_components)) {
      return removeNilValues(company.address_components) || {};
    }
    return null;
  }

  getAddressComponents() {
    const companyAddressComponents = this.getCompanyAddressComponents();
    const profileAddressComponents = this.getProfileAddressComponents();
    const bankAddressComponents = this.getBankAddressComponents();
    if (!isEmpty(companyAddressComponents)) {
      return companyAddressComponents;
    }
    if (!isEmpty(profileAddressComponents)) {
      return profileAddressComponents;
    }
    if (!isEmpty(bankAddressComponents)) {
      return bankAddressComponents;
    }
    return {};
  }

  serialize() {
    const ser = {
      ...omit(this.details, ['bank']),
      dimBankAccountId: this.dimBankAccountId,
      address: this.getAddressComponents(),
      user: {
        ...pick(this.details.user, ['id', 'email']),
        profile: {
          ...pick(get(this.details.user, 'profile', {}), ['firstName', 'lastName', 'name', 'jobTitle', 'address_components']),
          address: {
            description: get(this.details.user, 'profile.address.description', ''),
          },
        },
      },
    };

    if (this.orgId) {
      Object.assign(ser, {
        orgId: this.orgId,
      });
    }

    if (this.userId) {
      Object.assign(ser, {
        userId: this.userId,
      });
    }

    if (this.details.bank) {
      Object.assign(ser, { bank: this.details.bank.serialize() });
    }

    return removeUndefinedValues(ser);
  }

  // helper functions used in invoice templates
  isContractor() {
    const { isContractor } = this.details;
    return isContractor;
  }

  isClient() {
    const { isClient } = this.details;
    return isClient;
  }

  isEmployee() {
    const { isEmployee } = this.details;
    return isEmployee;
  }

  hasAOREnabled() {
    const { aorEnabled } = this.details;
    return aorEnabled;
  }

  getName() {
    const { name } = this.details;
    return this.getCompanyName() || name;
  }

  getCompanyName() {
    const { company } = this.details;
    return company ? company.name : '';
  }

  shouldHideInvoicePaymentDetails() {
    const { hideInvoicePaymentDetails } = this.details;
    return hideInvoicePaymentDetails;
  }

  getRegistrationNumber() {
    const { company } = this.details;
    return company ? company.registration_number : '';
  }

  getCountryCode() {
    const companyAddressComponents = this.getCompanyAddressComponents();
    const profileAddressComponents = this.getProfileAddressComponents();
    return get(
      companyAddressComponents, 'country_code', get(
        profileAddressComponents, 'country_code', this.getBankCountryCode(),
      ),
    );
  }

  isSepa() {
    const countryCode = this.getCountryCode();
    return countryCode && isSEPA(countryCode);
  }

  isInTheUK() {
    return this.getCountryCode() === 'GB';
  }

  isInGermany() {
    return this.getCountryCode() === 'DE';
  }

  isInSwitzerland() {
    return this.getCountryCode() === 'CH';
  }

  isInFrance() {
    return this.getCountryCode() === 'FR';
  }

  getVat() {
    return getFeVat(this);
  }

  getGst() {
    return getFeGst(this);
  }

  getHst() {
    return getFeHst(this);
  }

  getFullVat() {
    return getFeFullVat(this);
  }

  isVatRegistered() {
    return getFeIsVatRegistered(this);
  }

  isGstRegistered() {
    return getFeIsGstRegistered(this);
  }

  isHstRegistered() {
    return getFeIsHstRegistered(this);
  }

  getTaxSystemType() {
    return getFeTaxSystemType(this);
  }

  getVatPercent() {
    const { vatPercent } = this.details;
    return vatPercent;
  }

  getGracePeriod() {
    const { orgProviderGracePeriod, gracePeriod } = this.details;
    // optional organization provider grace periods override provider's own grace periods
    return orgProviderGracePeriod || gracePeriod;
  }

  getFreeBillingDetailsText() {
    const { freeBillingDetailsText } = this.details;
    return freeBillingDetailsText;
  }

  getComments() {
    const { comments } = this.details;
    return comments;
  }

  getBank() {
    const { bank } = this.details;
    if (!bank) {
      return null;
    }
    return bank;
  }

  getDimBankAccountId() {
    return this.dimBankAccountId;
  }

  getTwRecipient() {
    const { bank } = this.details;
    if (!bank) {
      return null;
    }
    return bank.getTransferwiseRecipientId();
  }

  getBankCurrency() {
    const { bank } = this.details;
    if (!bank) {
      return null;
    }
    return bank.getCurrency();
  }

  getBankCountryCode() {
    const { bank } = this.details;
    if (!bank) {
      return null;
    }
    return bank.getCountryCode();
  }

  getCustomReference() {
    const { bank } = this.details;
    if (!bank) {
      return null;
    }
    return bank.getCustomReference();
  }

  getPaymentMethod() {
    const { bank } = this.details;
    if (!bank) {
      return PAYMENT_METHODS.BANK_TRANSFER;
    }
    return bank.getPaymentMethod();
  }

  // user details
  getUserId() {
    if (this.isSystem()) {
      // this is the system user id
      return 1;
    }
    const { userId } = this;
    const { user } = this.details;
    return userId || user.id;
  }

  getEmail() {
    const { user } = this.details;
    return user.email;
  }

  getFullProfileName() {
    const { user } = this.details;
    return get(user, 'profile.name');
  }

  getFirstName() {
    const { user } = this.details;
    return get(user, 'profile.firstName');
  }

  getLastName() {
    const { user } = this.details;
    return get(user, 'profile.lastName');
  }

  getJobTitle() {
    const { user } = this.details;
    return get(user, 'profile.jobTitle');
  }

  getProfileAddress() {
    const { user } = this.details;
    return get(user, 'profile.address.description', '');
  }

  getProfileAddressComponents() {
    const { user } = this.details;
    return removeNilValues(
      user.profile && (user.profile.address_components),
    ) || [];
  }

  getBankAddressComponents() {
    const bank = this.getBank();
    if (bank) {
      return bank.getAddressComponents();
    }
    return null;
  }
}

export default FinancialEntity;
