import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE } from 'core/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { orgTemplatesManageUrl } from 'settings/urls';


class OrgInvitationTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      handleSubmit, history, match, submitting, submitLabel,
    } = this.props;
    const { orgAlias } = match.params;

    return (
      <form onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
            <Field type="hidden" name="id" component={InputField} />
            <Field
              type="text"
              name="title"
              component={InputField}
              label="Template name"
              placeholder="e.g Designers' template"
              required
            />
            <Field
              type="textarea"
              name="content"
              component={InputField}
              label="Content"
              rest={{ rows: 15 }}
              style={{ height: '200px' }}
              required
            />

            <div className="mt-4 text-right button-container">
              <TDButton
                type="button"
                variant={BS_STYLE.DEFAULT}
                disabled={submitting}
                label="Cancel"
                onClick={() => {
                  history.push(orgTemplatesManageUrl(orgAlias));
                }}
              />

              <TDButton
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={submitting}
                label={submitLabel}
              />
            </div>
          </Card.Body>
        </Card>
      </form>
    );
  }
}

OrgInvitationTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string,
};
OrgInvitationTemplateForm.defaultProps = {
  submitLabel: 'Create template',
};

const formName = 'OrgInvitationTemplateForm';
const OrgInvitationTemplateReduxForm = reduxForm({
  form: formName,
})(OrgInvitationTemplateForm);

export default withRouter(OrgInvitationTemplateReduxForm);
