// Action types
const LOAD = 'redux-form-dynamic/LOAD';

// Reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD:
      return {
        data: action.data,
      };
    default:
      return state;
  }
};

// Action creators
export const load = data => ({ type: LOAD, data });

export default reducer;
