import React from 'react';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import BillingTabs from 'finance/assets/js/components/BillingTabs.jsx';
import BillingTab from 'finance/assets/js/components/BillingTab.jsx';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchActiveSubscriptionDS } from 'finance/assets/js/data-services/view';

export const billingSubscriptionStoreKey = 'Billing-Subscription';

const BillingView = ({ match: { params: { orgAlias, tab } } }) => {
  const breadcrumbs = [{ title: 'Billing', url: null }];
  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs}>
        <BillingTabs />
      </ContentHeader>
      <div className="page page--billing">
        <div className="container">
          <TDApiConnected
            duck="view"
            storeKey={billingSubscriptionStoreKey}
            fetchData={({ dispatch, params, url, authedAxios, componentName }) => (
              dispatch(fetchActiveSubscriptionDS({
                orgAlias: params.orgAlias, url, componentName, authedAxios,
              }))
            )}
            loadingEnabled
          >
            <BillingTab orgAlias={orgAlias} tab={tab} />
          </TDApiConnected>
        </div>
      </div>
    </React.Fragment>
  );
};

BillingView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(BillingView);
