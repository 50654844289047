import React from 'react';
import PropTypes from 'prop-types';

import { INTEGRATION_STATUS_LABEL, INTEGRATION_STATUS_CLASS } from 'integrations/assets/js/constants';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';

const IntegrationStatusBadge = ({ status }) => {
  const variant = INTEGRATION_STATUS_CLASS[status] || 'pending';
  const label = INTEGRATION_STATUS_LABEL[status] || 'Unknown status';

  return (
    <StatusTag
      statusClass={variant}
      label={label}
    />
  );
};

IntegrationStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default IntegrationStatusBadge;
