import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Countdown = ({ expiresAt, trialPeriod, isSmall, isActive }) => {
  // work out days left on the trial
  const [currentTime, setCurrentTime] = useState(Date.now());
  const endTime = new Date(expiresAt).getTime();
  const timeBetween = endTime - currentTime;
  const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24)) + 1;
  const daysGone = Math.floor(trialPeriod - days);
  const counterFill = Math.floor((daysGone / trialPeriod) * 180);

  const classNames = ['countdown-timer'];

  if (isSmall) { classNames.push('is-small'); }
  if (isActive) { classNames.push('is-active'); }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={classNames.join(' ')}>
        <div className="countdown-timer__circle">
          <div className={`mask full--${counterFill}`}>
            <div className={`fill fill--${counterFill}`} />
          </div>
          <div className="mask half">
            <div className={`fill fill--${counterFill}`} />
          </div>
          <div className="countdown-timer__inner">
            {days}
          </div>
        </div>
      </div>
    </>
  );
};

Countdown.propTypes = {
  isActive: PropTypes.bool,
  isSmall: PropTypes.bool,
  expiresAt: PropTypes.string.isRequired,
  trialPeriod: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

Countdown.defaultProps = {
  isActive: false,
  isSmall: false,
};

export default Countdown;
