import React from 'react';
import PropTypes from 'prop-types';
import RoundedPic from 'core/assets/js/components/RoundedPic.jsx';

function ProfilePic({ url, alt, className, 'data-testid': dataTestId, size, squared }) {
  const imgUrl = (url && url !== '') ? url : '/img/default-avatar.png';
  const _className = [className];

  if (squared) {
    _className.push('profile-pic--squared');
  }

  return (
    <RoundedPic
      url={imgUrl}
      alt={alt}
      size={size}
      className={`profile-pic rounded-circle flex-shrink-0 ${_className.join(' ')}`}
      data-testid={dataTestId}
    />
  );
}

ProfilePic.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  alt: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.array,
  squared: PropTypes.bool,
};

ProfilePic.defaultProps = {
  alt: '',
  className: '',
  'data-testid': '',
  url: '',
  size: ['40', '40'],
  squared: false,
};

export default ProfilePic;
