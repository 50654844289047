import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { knowledgebaseUrl, knowledgebaseTicketUrl } from 'core/urls';
import { ICON } from 'core/assets/js/constants';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import HSChat from 'core/assets/js/lib/HSChat';
import Beamer from 'core/assets/js/lib/Beamer';

const HelpDropdown = () => {

  const toggle = (
    <div className="navbar-footer-toggle pl-4 pr-3 d-flex">
      <i className={ICON.QUESTION_CIRCLE} />
      Help
      <span className="align-self-center ml-auto">
        <i className={`${ICON.CHEVRON_DOWN} navbar-item__chevron`} />
      </span>
    </div>
  );
  return (
    <TDDropButton
      overlayClassName="help-drop-down"
      className="mt-1"
      placement="right-end"
      toggleEl={toggle}
      popperConfig={{
        modifiers: {
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          offset: {
            offset: '10px, -20px',
          },
        },
      }}
    >
      <a
        className="dropdown-item td-open-beamer"
        id="td-open-whats-new-dropdown"
        onClick={Beamer.openWhatsNew}
      >
        <i className="fa-light fa-bullhorn" />
        <strong>What&apos;s New</strong>
      </a>
      <a
        href={knowledgebaseUrl()}
        className="dropdown-item"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fa-light fa-book" />
        <strong>Knowledge Base</strong>
      </a>
      <a
        href={knowledgebaseTicketUrl()}
        className="dropdown-item"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fa-light fa-message-question" />
        <strong>Open a Ticket</strong>
      </a>
      <a className="dropdown-item" onClick={HSChat.openHSChat}>
        <i className="fa-light fa-message" />
        <strong>Live Chat</strong>
      </a>
      <a
        className="dropdown-item"
        rel="noopener noreferrer"
        target="_blank"
        href="mailto: support@talentdesk.io"
      >
        <i className="fa-light fa-envelope" />
        <strong>Contact Us</strong>
      </a>
    </TDDropButton>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const HelpDropdownConnect = connect(
  null,
  mapDispatchToProps,
)(HelpDropdown);
export default withRouter(HelpDropdownConnect);
