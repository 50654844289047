import { omit, pick, uniqBy } from 'lodash';
import { toastr } from 'react-redux-toastr';

import axios from 'core/assets/js/lib/tdAxios';
import { getCustomFieldPath } from 'interviews/assets/js/lib/utils';
import { arrayMove } from 'core/assets/js/lib/utils';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { MIME_TYPES } from 'core/assets/js/constants';
import { CUSTOM_FIELD_PAYLOAD_KEYS, TYPE } from 'interviews/assets/js/constants';
import {
  customFieldsTemplateListApiUrl,
  customFieldsTemplateListApiUrlAll,
  entityCustomFieldsTemplateAdditionsApiUrl,
} from 'interviews/urls';

// Action types
export const CUSTOM_FIELD_TEMPLATES_FETCH = 'redux-questions/CUSTOM_FIELD_TEMPLATES_FETCH';
export const CUSTOM_FIELD_TEMPLATE_UPDATED = 'redux-questions/CUSTOM_FIELD_TEMPLATE_UPDATED';
export const CUSTOM_FIELD_TEMPLATE_DELETED = 'redux-questions/CUSTOM_FIELD_TEMPLATES_DELETED';
export const CUSTOM_FIELD_SELECTED_TEMPLATES = 'redux-questions/CUSTOM_FIELD_SELECTED_TEMPLATES';
export const IMPORT_TEMPLATE = 'redux-questions/IMPORT_TEMPLATE';
export const REMOVE_TEMPLATE = 'redux-questions/REMOVE_TEMPLATE';
export const CUSTOM_FIELDS_FETCH = 'redux-questions/CUSTOM_FIELDS_FETCH';
export const ADD_CUSTOM_FIELD = 'redux-questions/ADD_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD = 'redux-questions/UPDATE_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD_ERRORS = 'redux-questions/UPDATE_CUSTOM_FIELD_ERRORS';
export const REORDER_CUSTOM_FIELDS = 'redux-questions/REORDER_CUSTOM_FIELDS';
export const REMOVE_CUSTOM_FIELD = 'redux-questions/REMOVE_CUSTOM_FIELD';
export const RESET_CUSTOM_FIELDS = 'redux-questions/RESET_CUSTOM_FIELDS';
export const SET_EDITABLE_CUSTOM_FIELDS = 'redux-questions/SET_EDITABLE_CUSTOM_FIELDS';
export const ADD_EDITABLE_CUSTOM_FIELD = 'redux-questions/ADD_EDITABLE_CUSTOM_FIELD';
export const REMOVE_EDITABLE_CUSTOM_FIELD = 'redux-questions/REMOVE_EDITABLE_CUSTOM_FIELD';
export const SET_EDITABLE_TEMPLATES = 'redux-questions/SET_EDITABLE_TEMPLATES';
export const ADD_EDITABLE_TEMPLATE = 'redux-questions/ADD_EDITABLE_TEMPLATE';
export const REMOVE_EDITABLE_TEMPLATE = 'redux-questions/REMOVE_EDITABLE_TEMPLATE';
export const RESET_EDITABLE_CUSTOM_FIELDS = 'redux-questions/RESET_EDITABLE_CUSTOM_FIELDS';

// Reducer
export const initialState = {
  items: [],
  templates: [],
  editables: [],
  ai: 0,
  touched: false,
};

const defaultCustomField = {
  required: false,
  description: '',
  payload: {},
  errors: {},
};


export const reducer = (state = initialState, action) => {
  let idx;
  let newCustomField;
  let templateFieldIds;
  let customFields;

  switch (action.type) {
    case CUSTOM_FIELD_TEMPLATES_FETCH:
      return {
        ...state,
        templates: action.templates,
      };
    case CUSTOM_FIELD_TEMPLATE_UPDATED:
      return {
        ...state,
        templates: state.templates.map(t => (t.id === action.template.id ? action.template : t)),
      };
    case CUSTOM_FIELD_TEMPLATE_DELETED:
      return {
        ...state,
        templates: state.templates.filter(t => (t.id !== action.template.id)),
      };
    case CUSTOM_FIELD_SELECTED_TEMPLATES:
      customFields = action.templates.map(tpl => tpl.questions).reduce((a, b) => [...a, ...b], []);

      return {
        ...state,
        items: customFields,
        editables: customFields,
      };
    case IMPORT_TEMPLATE:
      customFields = [...state.items, ...action.template.questions];
      return {
        ...state,
        editables: customFields,
      };
    case REMOVE_TEMPLATE:
      templateFieldIds = action.template.questions.map(cf => cf.id);
      customFields = state.items.filter(cf => !templateFieldIds.includes(cf.id));

      return {
        ...state,
        items: customFields,
        editables: customFields,
      };
    case CUSTOM_FIELDS_FETCH:
      return {
        ...state,
        ai: action.items.reduce((a, b) => Math.max(a, b.id), 0) + 1,
        items: action.items.map(i => ({ ...defaultCustomField, ...i })),
        touched: false,
      };
    case ADD_CUSTOM_FIELD:
      newCustomField = {
        ...defaultCustomField,
        id: state.ai,
        path: getCustomFieldPath(state.ai),
        // rearrange payload into nested object (necessary for question components)
        ...omit(action.customField, CUSTOM_FIELD_PAYLOAD_KEYS),
        payload: pick(action.customField, CUSTOM_FIELD_PAYLOAD_KEYS),
      };
      return {
        ...state,
        items: [...state.items, newCustomField],
        ai: state.ai + 1,
        touched: true,
      };
    case UPDATE_CUSTOM_FIELD:
      idx = action.idx;
      return {
        ...state,
        items: [
          ...state.items.slice(0, idx),
          {
            ...state.items[idx],
            ...omit(action.value, CUSTOM_FIELD_PAYLOAD_KEYS),
            payload: pick(action.value, CUSTOM_FIELD_PAYLOAD_KEYS),
            errors: action.errors,
          },
          ...state.items.slice(idx + 1),
        ],
        touched: true,
      };
    case UPDATE_CUSTOM_FIELD_ERRORS:
      idx = state.items.findIndex(i => i.path === action.fieldPath);
      if (idx === -1) {
        return state;
      }
      return {
        ...state,
        items: [
          ...state.items.slice(0, idx),
          {
            ...state.items[idx],
            errors: action.errors,
          },
          ...state.items.slice(idx + 1),
        ],
        touched: true,
      };
    case REMOVE_CUSTOM_FIELD:
      idx = action.idx;
      return {
        ...state,
        items: [...state.items.slice(0, idx), ...state.items.slice(idx + 1)],
        touched: true,
      };
    case REORDER_CUSTOM_FIELDS:
      if (action.oldIndex === action.newIndex) {
        return state;
      }
      return {
        ...state,
        items: arrayMove([...state.items], action.oldIndex, action.newIndex),
        touched: true,
      };
    case RESET_CUSTOM_FIELDS:
      return {
        ...state,
        ai: 0,
        items: [],
        touched: false,
      };
    case SET_EDITABLE_TEMPLATES:
      return {
        ...state,
        editables: action.templates.map(tpl => tpl.questions).reduce((a, b) => [...a, ...b], []),
      };
    case SET_EDITABLE_CUSTOM_FIELDS:
      return {
        ...state,
        editables: action.items,
      };
    case ADD_EDITABLE_CUSTOM_FIELD:
      return {
        ...state,
        editables: [...state.editables, action.item],
      };
    case REMOVE_EDITABLE_CUSTOM_FIELD:
      return {
        ...state,
        editables: state.editables.filter(cf => cf.id !== action.id),
      };
    case ADD_EDITABLE_TEMPLATE:
      return {
        ...state,
        editables: uniqBy([...state.editables, ...action.template.questions], 'id'),
      };
    case REMOVE_EDITABLE_TEMPLATE:
      return {
        ...state,
        editables: state.editables.filter(
          cf => cf.customFieldTemplateId !== action.template.id,
        ),
      };
    case RESET_EDITABLE_CUSTOM_FIELDS:
      return {
        ...state,
        editables: [],
      };
    default:
      return state;
  }
};

// Action creators
export const customFieldTemplatesFetchAC = templates => ({
  type: CUSTOM_FIELD_TEMPLATES_FETCH,
  templates,
});

export const customFieldTemplateUpdatedAC = template => ({
  type: CUSTOM_FIELD_TEMPLATE_UPDATED,
  template,
});

export const customFieldTemplateDeletedAC = template => ({
  type: CUSTOM_FIELD_TEMPLATE_DELETED,
  template,
});

export const customFieldsFetchAC = items => ({
  type: CUSTOM_FIELDS_FETCH,
  items,
});

export const addCustomFieldAC = customField => ({
  type: ADD_CUSTOM_FIELD,
  customField,
});

export const importTemplateAC = template => ({
  type: IMPORT_TEMPLATE,
  template,
});

export const removeTemplateAC = template => ({
  type: REMOVE_TEMPLATE,
  template,
});

export const updateSelectedTemplatesAC = templates => ({
  type: CUSTOM_FIELD_SELECTED_TEMPLATES,
  templates,
});

export const setEditableCustomFieldsAC = items => ({
  type: SET_EDITABLE_CUSTOM_FIELDS,
  items,
});

export const setEditableTemplatesAC = templates => ({
  type: SET_EDITABLE_TEMPLATES,
  templates,
});

export const addEditableCustomFieldAC = item => ({
  type: ADD_EDITABLE_CUSTOM_FIELD,
  item,
});

export const removeEditableCustomFieldAC = id => ({
  type: REMOVE_EDITABLE_CUSTOM_FIELD,
  id,
});

export const addEditableTemplateAC = template => ({
  type: ADD_EDITABLE_TEMPLATE,
  template,
});

export const removeEditableTemplateAC = template => ({
  type: REMOVE_EDITABLE_TEMPLATE,
  template,
});

export const resetEditableCustomFieldsAC = () => ({
  type: RESET_EDITABLE_CUSTOM_FIELDS,
});

const prepareValue = (value) => {
  let mimetypes = [];
  switch (value.type) {
    case TYPE.FILE:
      if (value.attachment_types) {
        value.attachment_types.forEach((t) => {
          mimetypes = [...mimetypes, ...MIME_TYPES[t]];
        });
      }

      return { ...value, mimetypes };
    default:
      return value;
  }
};

export const updateCustomFieldAC = (idx, value, errors) => ({
  type: UPDATE_CUSTOM_FIELD,
  idx,
  value: prepareValue(value),
  errors,
});

export const updateCustomFieldErrorsAC = (fieldPath, errors) => ({
  type: UPDATE_CUSTOM_FIELD_ERRORS,
  fieldPath,
  errors,
});

export const removeCustomFieldAC = idx => ({
  type: REMOVE_CUSTOM_FIELD,
  idx,
});

export const resetCustomFieldsAC = () => ({
  type: RESET_CUSTOM_FIELDS,
});

export const reorderCustomFieldAC = ({ oldIndex, newIndex }) => ({
  type: REORDER_CUSTOM_FIELDS,
  oldIndex,
  newIndex,
});

export const fetchCustomFieldTemplatesDS = ({
  orgAlias, entityType, authedAxios = null, fetchAll = false,
}) => (
  async (dispatch) => {
    try {
      const { data: templates } = fetchAll ? await (authedAxios || axios).get(
        customFieldsTemplateListApiUrlAll({ orgAlias, entityType }),
      ) : await (authedAxios || axios).get(
        customFieldsTemplateListApiUrl({ orgAlias, entityType }),
      );

      dispatch(customFieldTemplatesFetchAC(templates));
      dispatch(updateSelectedTemplatesAC(templates.filter(t => t.isMandatory)));
    } catch (err) {
      toastr.error('Oops!', 'We couldn’t fetch your custom field templates');
    }
  }
);

export const fetchCustomFieldTemplateAdditionsDS = ({
  orgAlias, entityType, entityId, componentName, authedAxios = null, url = '',
}) => (
  fetchListDS({
    authedAxios,
    componentName,
    url: entityCustomFieldsTemplateAdditionsApiUrl({ orgAlias, entityType, entityId, url }),
  })
);

export const getCustomFieldTemplates = state => (
  state && state.customFields ? state.customFields.templates : []
);

export const getCustomFields = state => (
  state && state.customFields ? state.customFields.items : []
);

export const getEditableCustomFields = state => (
  state && state.customFields ? state.customFields.editables : []
);

export default reducer;
