import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import OrgSettingsPaymentsMethodsTab from 'settings/assets/js/components/OrgSettingsPaymentsMethodsTab.jsx';
import OrgSettingsPaymentsVatSalesInfo from 'settings/assets/js/components/OrgSettingsPaymentsVatSalesInfo.jsx';
import OrgSettingsPaymentsInvoices from 'settings/assets/js/components/OrgSettingsPaymentsInvoices.jsx';
import { ORG_SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { orgSettingsPaymentsUrl } from 'settings/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const OrgSettingsPaymentsTab = ({ tab, history, orgAlias }) => {
  switch (tab) {
    case ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS:
      return (
        <OrgSettingsPaymentsMethodsTab />
      );
    case ORG_SETTINGS_PAYMENTS_TABS.SALES_TAX:
      return (
        <OrgSettingsPaymentsVatSalesInfo />
      );
    case ORG_SETTINGS_PAYMENTS_TABS.INVOICES:
      return (
        <OrgSettingsPaymentsInvoices />
      );
    default:
      history.push(
        orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS),
      );
      return null;
  }
};

OrgSettingsPaymentsTab.propTypes = {
  history: routerHistorySpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default withRouter(OrgSettingsPaymentsTab);
