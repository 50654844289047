import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { ICON, BS_STYLE } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { settingsBankAccountsApiUrl, settingsPaymentMethodCreateSelectUrl, orgSettingsPaymentsUrl } from 'settings/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { ORG_SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import PaymentMethodCardItem from 'settings/assets/js/components/PaymentMethodCardItem.jsx';
import { deleteBankAccountDS } from 'settings/assets/js/data-services/settings';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import TDList from 'core/assets/js/components/TDList.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import {
  modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload,
} from 'core/assets/js/ducks/modalLauncher';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { selectActiveOrg, selectUserCards } from 'organizations/assets/js/reducers/organizations';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const DELETE_BANK_ACCOUNT_MODAL_ID = 'delete-bank-account';

const ProfileBankAccountTab = ({ history }) => {
  const dispatch = useDispatch();

  const componentName = ProfileBankAccountTab.GetComponentName();

  const {
    hasLoaded, items: bankAccounts,
  } = fetchDataHook({ componentName, duck: 'list', url: settingsBankAccountsApiUrl() });

  const activeOrg = useSelector(selectActiveOrg);
  const userCards = useSelector(selectUserCards);
  const deleteModalPayload = useSelector(
    state => getModalPayload(state, DELETE_BANK_ACCOUNT_MODAL_ID),
  );
  const isDeleteModalOpen = useSelector(
    state => getIsModalOpen(state, DELETE_BANK_ACCOUNT_MODAL_ID),
  );

  if (!hasLoaded) {
    return <SettingsPageSkeleton />;
  }

  const handleCloseModal = () => dispatch(modalCloseAC());

  const deleteBankAccount = async () => {
    try {
      await deleteBankAccountDS(deleteModalPayload.id, dispatch);
      dispatch(requestRefreshAC(componentName));
    } catch (err) {
      toastr.error(('Oh Snap!', err.message || 'Payment method was not deleted'));
    } finally {
      handleCloseModal();
    }
  };

  return (
    <React.Fragment>
      { deleteModalPayload && (
        <ModalConfirm
          onClose={handleCloseModal}
          onConfirm={deleteBankAccount}
          body={(
            <React.Fragment>
              Are you sure you want to delete this payment method ?
            </React.Fragment>
          )}
          confirmLabel="Delete"
          confirmStyle={BS_STYLE.DANGER}
          heading="Delete payment method"
          open={isDeleteModalOpen}
        />
      )}
      <TDSystemMessage
        title="Manage your payment methods"
        type={BS_STYLE.INFO}
        className="mb-4"
      >
        <p>Manage your payment methods here for all the organisations you are a member of.</p>
        <p>
          {` To review the settings per organisation, please click on the organisation name below
          and review at "Settings > Payments > Payment methods".`}
        </p>
        {userCards.map(uc => (
          <Link
            key={uc.id}
            className="d-block"
            to={orgSettingsPaymentsUrl(
              uc.organization.alias, ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
            )}
          >
            {`- ${uc.organization.name}`}
          </Link>
        ))}
      </TDSystemMessage>

      <div className={bankAccounts.length > 0 ? 'rounded shadow-sm bg-white p-4' : 'p-0'}>
        <div className="settings-row">
          <TDList
            items={bankAccounts}
            emptyListMessage={(
              <div className="d-flex flex-column align-items-center justify-content-center empty-list-message--no-min-height ">
                <i className={ICON.INVOICE_DUOTONE} />
                <div>You haven&apos;t added any payment methods yet</div>
                <TDButton
                  className="mt-4"
                  key="new-payment-method"
                  variant={BS_STYLE.PRIMARY}
                  label="New payment method"
                  onClick={() => {
                    history.push(settingsPaymentMethodCreateSelectUrl(activeOrg.alias));
                  }}
                />
              </div>
            )}
            cardItem={{
              component: PaymentMethodCardItem,
              props: {
                onDelete: (payload) => {
                  dispatch(modalOpenAC(DELETE_BANK_ACCOUNT_MODAL_ID, payload));
                },
                orgAlias: activeOrg.alias,
              },
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

ProfileBankAccountTab.GetComponentName = () => 'ProfileBankAccountTab';

ProfileBankAccountTab.propTypes = {
  history: routerHistorySpec.isRequired,
};

export default withRouter(ProfileBankAccountTab);
