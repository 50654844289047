import React from 'react';
import { withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW } from 'core/assets/js/config/settings';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { DOCUMENT_ESIGN_EVENTS } from 'documents/assets/js/constants';
import { TYPE } from 'invitations/assets/js/constants';

const DocumentAssignmentSignConfirmationView = ({ match: { params: { invitationType } } }) => (
  <div className="aor-contract-confirmation-message row">
    <div className="col-12 col-md-10 offset-md-1">
      <h1 className="mt-5 mb-3">The contract template assignment has been successfully signed.</h1>
      <p>
        The contract template is now being reviewed by our legal team, they may contact you directly
        if there are any issues with it, otherwise, you will be notified once it has been
        countersigned.
      </p>
      {invitationType && parseInt(invitationType, 10) === TYPE.ORG_INVITE && (
        <p>
          In the meantime, please continue with the rest of the Get Started steps.
        </p>
      )}
      <TDButton
        btnIcon={ICON.ARROW_RIGHT}
        variant={BS_STYLE.PRIMARY}
        className="mx-auto mt-5"
        label="Continue"
        onClick={() => {
          WINDOW.parent.postMessage(DOCUMENT_ESIGN_EVENTS.CONTINUE, '*');
        }}
      />
    </div>
  </div>
);

DocumentAssignmentSignConfirmationView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(DocumentAssignmentSignConfirmationView);
