import React from 'react';
import PropTypes from 'prop-types';
import Geosuggest from 'react-geosuggest';

import withField from 'core/assets/js/components/withField.jsx';
import Address from 'core/assets/js/lib/Address';
import { countryOptions } from 'core/assets/js/lib/isoCountries';
import { FieldSelect } from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import { extractAddressComponent } from 'core/assets/js/lib/utils';
import { isEmpty } from 'lodash';

/**
 * Redux form compatible geographic suggest field
 *
 * It displays a bootstrap decorated input field with a label and an error
 * message
 */
class GeosuggestField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
  }

  static getDerivedStateFromProps({ input: { value } }, state) {
    const address = new Address(value);
    return {
      ...state,
      address,
    };
  }

  onChangeComponent(event, type) {
    const { address } = this.state;
    const { target: { value } } = event;
    const { input: { onChange } } = this.props;
    address[type] = value;
    onChange(address.toObject());
  }

  render() {
    const {
      input,
      label,
      meta: { error, submitError, touched },
      onlyAuto,
      placeholder,
      required,
      sublabel,
      types,
    } = this.props;
    const { showForm, address } = this.state;

    const hasError = touched && (error || submitError);
    const groupClassName = ['form-group'];
    if (hasError) {
      groupClassName.push('has-error');
    }

    const value = input.value ? input.value.label : input.value;
    const field = (
      <Geosuggest
        initialValue={value}
        placeholder={placeholder}
        types={types}
        inputClassName="form-control"
        onSuggestSelect={obj => {
          input.onChange(obj);
          if (!isEmpty(obj) && !extractAddressComponent(obj, 'country')) {
            // Not all google address searches will include a country (e.g. disputed regions)
            // but we require it to be entered
            this.setState({ showForm: true });
          }
        }}
        disabled={showForm}
        onSelect={obj => input.onChange(obj)}
        // Do not delete on change, we need it so that when the user deletes address, it returns an
        // empty string and removes all address details from notAuto form fields
        onChange={obj => input.onChange(obj)}
      />
    );

    return (
      <div className={groupClassName.join(' ')}>
        { label && (
          <label htmlFor={input.name}>
            {label}
            {' '}
            {required && ' *'}
            {' '}
            <span className="sublabel">
              {onlyAuto ? sublabel : (
                <>
                  Find your address using the form below or
                  {' '}
                  <a data-testid="geosuggest-enter-address-manually" className="imitate-link" onClick={() => this.setState({ showForm: !showForm })}>enter it manually</a>
                </>
              )}
            </span>
          </label>
        )}
        {field}
        {hasError && <span className="help-block mt-3">{error || submitError}</span>}
        {showForm && (
          <div className="mt-3">
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Address line 1"
                value={address.addressLine1}
                onChange={ev => this.onChangeComponent(ev, 'addressLine1')}
              />
            </div>

            <div className="form-group">
              <input
                className="form-control"
                placeholder="Address line 2"
                value={address.addressLine2}
                onChange={ev => this.onChangeComponent(ev, 'addressLine2')}
              />

            </div>

            <div className="row">
              <div className="col-12 col-md-6 pr-md-3 form-group">
                <input
                  className="form-control"
                  placeholder="County"
                  value={address.region}
                  onChange={ev => this.onChangeComponent(ev, 'region')}
                />
              </div>

              <div className="col-12 col-md-6 pl-md-3 form-group">
                <input
                  className="form-control"
                  placeholder="Postcode"
                  value={address.postcode}
                  onChange={ev => this.onChangeComponent(ev, 'postcode')}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 pr-md-3 form-group">
                <input
                  className="form-control"
                  placeholder="City/Town"
                  value={address.city}
                  onChange={ev => this.onChangeComponent(ev, 'city')}
                />
              </div>

              <div className="col-12 col-md-6 pl-md-3 form-group">
                <FieldSelect
                  value={address.countryCode}
                  optionsMapping={countryOptions}
                  inputClassName="form-control"
                  defaultOptionText="Country"
                  onChange={ev => this.onChangeComponent(ev, 'countryCode')}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

GeosuggestField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
  }),
  onlyAuto: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
};

GeosuggestField.defaultProps = {
  label: '',
  meta: {
    touched: false,
    error: '',
    submitError: '',
  },
  onlyAuto: false,
  placeholder: '',
  required: false,
  sublabel: '',
  types: ['address'],
};

export default withField(GeosuggestField);
