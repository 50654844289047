import React from 'react';
import PropTypes from 'prop-types';

import { CONTACTS_IMPORT_STEP } from 'contacts/assets/js/constants';
import { contactsImportUrl } from 'contacts/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import ContactsImportUploadForm from 'contacts/assets/js/components/steps/ContactsImportUploadForm.jsx';
import ContactsImportMappingForm from 'contacts/assets/js/components/steps/ContactsImportMappingForm.jsx';
import ContactsImportResults from 'contacts/assets/js/components/steps/ContactsImportResults.jsx';
import ContactsImportPreview from 'contacts/assets/js/components/steps/ContactsImportPreview.jsx';

const ContactsImportStep = ({
  history, orgAlias, step,
}) => {
  switch (step) {
    case CONTACTS_IMPORT_STEP.UPLOAD:
      return (
        <ContactsImportUploadForm />
      );
    case CONTACTS_IMPORT_STEP.MAPPING:
      return (
        <ContactsImportMappingForm />
      );
    case CONTACTS_IMPORT_STEP.PREVIEW:
      return (
        <ContactsImportPreview />
      );
    case CONTACTS_IMPORT_STEP.RESULTS:
      return (
        <ContactsImportResults />
      );
    default:
      history.push(
        contactsImportUrl(orgAlias, CONTACTS_IMPORT_STEP.UPLOAD),
      );
      return null;
  }
};

ContactsImportStep.propTypes = {
  history: routerHistorySpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
};

ContactsImportStep.defaultProps = {
};

export default ContactsImportStep;
