import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BillingDetailsFormModal from 'settings/assets/js/components/BillingDetailsFormModal.jsx';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import TDCheckbox, { CHECKBOX_SIZES } from 'core/assets/js/components/TDCheckbox.jsx';
import { IMG_SIZE } from 'core/assets/js/constants';
import { orgSettingsUrl } from 'settings/urls';
import { orgSpec, billingDetailsSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { SalesTaxPlainFields } from 'settings/assets/js/components/SalesTaxFields.jsx';

const BillingDetails = ({
  isDefault, isOrgBillingDetails, history, billingDetails, activeOrg,
  onBillingDetailsUpdated, onSetDefault,
}) => {
  const addressComponents = billingDetails && billingDetails.address_components;
  const isPristine = billingDetails && billingDetails.pristine_address;
  const tooltip = (
    <Tooltip>
      {isDefault ? 'This is your default billing details' : 'Set as default billing details'}
    </Tooltip>
  );

  const statusCheckbox = (
    <OverlayTrigger
      placement="top"
      overlay={tooltip}
    >
      <div className="organization-list-item__default-status align-self-center">
        <TDCheckbox size={CHECKBOX_SIZES.LARGE} checked={isDefault} />
      </div>
    </OverlayTrigger>
  );

  const markDefaultButton = isDefault ? statusCheckbox : (
    <span onClick={() => onSetDefault(billingDetails.id)}>
      {statusCheckbox}
    </span>
  );

  return (
    <Card
      className="p-0"
    >
      <Card.Body
        className="d-flex flex-column-reverse flex-sm-row justify-content-between"
      >
        <div className="">
          { billingDetails.logo && (
            <div className="mb-2">
              <OrganizationLogo
                url={billingDetails.logo}
                orgName={billingDetails.name}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                className="img-radius"
              />
              <br />
            </div>
          )}
          <React.Fragment>
            <strong className="name">{ billingDetails.name }</strong>
            <br />
          </React.Fragment>
          { addressComponents && (
            <React.Fragment>
              { addressComponents.street && (
                <React.Fragment>
                  <span className="street">{ addressComponents.street }</span>
                  <br />
                </React.Fragment>
              )}
              { !isPristine && (addressComponents.region || addressComponents.postal_code) && (
                <React.Fragment>
                  {addressComponents.region && <span className="region mr-1">{addressComponents.region}</span>}
                  {addressComponents.postal_code && <span className="postal_code">{ addressComponents.postal_code }</span>}
                  <br />
                </React.Fragment>
              )}
              {(addressComponents.city || addressComponents.country) && (
                <React.Fragment>
                  {addressComponents.city && <span className="city mr-1">{addressComponents.city}</span>}
                  {addressComponents.country && <span className="country">{addressComponents.country}</span>}
                  <br />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <SalesTaxPlainFields {...billingDetails} />
          { billingDetails.registration_number && (
            <React.Fragment>
              <span className="registration_number">{ billingDetails.registration_number }</span>
              <br />
            </React.Fragment>
          )}
          { billingDetails.website && (
            <React.Fragment>
              <span className="website">{ billingDetails.website }</span>
              <br />
            </React.Fragment>
          )}
          { billingDetails.free_bill_to_text && (
            <div className="my-3 pr-3">
              <div><strong>Additional billing info</strong></div>
              <span className="free_bill_to_text">{ billingDetails.free_bill_to_text }</span>
              <br />
            </div>
          )}
          {isOrgBillingDetails ? (
            <span
              className="imitate-link"
              onClick={() => history.push(orgSettingsUrl(activeOrg.unique_alias))}
            >
              Edit
            </span>
          ) : (
            <BillingDetailsFormModal
              initialValues={billingDetails}
              orgId={activeOrg.id}
              onSubmit={onBillingDetailsUpdated}
              form={`billingDetails-${billingDetails.id}`}
              triggerEl={(
                <span
                  className="imitate-link"
                >
                  Edit
                </span>
              )}
            />
          )}
          { isDefault && (
            <div className="discreet mt-3">
              <span>
                All TalentDesk.io invoices will be addressed to the above company details.
              </span>
            </div>
          )}
        </div>
        <div className="mb-5 mb-sm-0 align-self-center">
          {markDefaultButton}
        </div>
      </Card.Body>
    </Card>
  );
};

BillingDetails.propTypes = {
  history: PropTypes.object.isRequired,
  billingDetails: billingDetailsSpec.isRequired,
  onBillingDetailsUpdated: PropTypes.func,
  onSetDefault: PropTypes.func,
  activeOrg: orgSpec.isRequired,
  isOrgBillingDetails: PropTypes.bool,
  isDefault: PropTypes.bool,
};

BillingDetails.defaultProps = {
  isOrgBillingDetails: false,
  isDefault: false,
  onBillingDetailsUpdated: () => {},
  onSetDefault: () => {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps,
)(BillingDetails));
