import React from 'react';
import { get, maxBy, isEmpty } from 'lodash';
import { Dropdown, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { contactCardSpec } from 'contacts/assets/js/lib/objectSpecs';
import { STATUS as INVITATION_STATUS } from 'invitations/assets/js/constants';
import ContactDetailsPanel from 'contacts/assets/js/components/ContactDetailsPanel.jsx';
import { contactEditUrl } from 'contacts/urls';
import { orgPeopleInviteUrl } from 'people/urls';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { deleteContactsDS } from 'contacts/assets/js/data-services/form';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import ContactsListView from 'contacts/assets/js/ContactsListView.jsx';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, IMG_SIZE, POPPER_PLACEMENT, USER_TYPE_LABEL } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { CONTACT_STATUS, CONTACT_STATUS_CLASS } from 'contacts/assets/js/constants';
import { getActiveUserCardPermissionChecker } from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';

class ContactCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleCloseDeleteContactModal = this.handleCloseDeleteContactModal.bind(this);
    this.handleOpenDeleteContactModal = this.handleOpenDeleteContactModal.bind(this);
    this.handleDeleteContact = this.handleDeleteContact.bind(this);
    this.handleOpenContactDetailsPanel = this.handleOpenContactDetailsPanel.bind(this);
  }

  handleOpenDeleteContactModal(ev) {
    const { dispatch, deleteContactModalId } = this.props;
    ev.stopPropagation();
    dispatch(modalOpenAC(deleteContactModalId));
  }

  handleCloseDeleteContactModal() {
    const { dispatch, deleteContactModalId } = this.props;
    dispatch(modalCloseAC(deleteContactModalId));
  }

  handleOpenContactDetailsPanel(ev) {
    const { dispatch, contactDetailsPanelId } = this.props;
    ev.stopPropagation();
    dispatch(modalOpenAC(contactDetailsPanelId));
  }

  handleDeleteContact() {
    const { dispatch, item, match: { params: { orgAlias } } } = this.props;
    const componentName = ContactsListView.GetComponentName();
    return dispatch(
      deleteContactsDS({ contactIds: [item.id], componentName, orgAlias }),
    ).then(() => {
      this.handleCloseDeleteContactModal();
      toastr.success('Well Done!', 'Your contact was deleted successfully.');
    });
  }

  render() {
    const {
      contactDetailsPanelId,
      dispatch,
      hasPermission,
      history,
      isContactDetailsPanelOpen,
      isDeleteContactModalOpen,
      isLastItem,
      item,
      item: { id, avatar, email, fullName, invitations },
      match: { params: { orgAlias } },
    } = this.props;
    const userRoleTitle = get(item, 'userCard.userRole.title');
    const lastInvitation = maxBy(invitations, 'id');
    const invitationRole = get(lastInvitation, 'userRole', '');
    const hasUserRole = !!userRoleTitle;
    const invitationIsPending = !isEmpty(lastInvitation)
      && lastInvitation.status === INVITATION_STATUS.PENDING;
    const invitationIsRejected = !isEmpty(lastInvitation)
      && lastInvitation.status === INVITATION_STATUS.REJECTED;
    const classNames = ['contact-list-item'];
    if (hasUserRole) {
      classNames.push('contact-list-item--muted');
    }
    const canInviteProviders = hasPermission(PERMISSIONS.CAN_INVITE_PROVIDERS);
    const canInviteManagers = hasPermission(PERMISSIONS.CAN_INVITE_MANAGERS);
    const actions = (
      <React.Fragment>
        { !isContactDetailsPanelOpen && (
          <Dropdown.Item
            eventKey="edit-contact"
            onClick={this.handleOpenContactDetailsPanel}
          >
            View
          </Dropdown.Item>
        )}
        { !hasUserRole && (
          <Dropdown.Item
            eventKey="edit-contact"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(modalCloseAC());
              history.push(contactEditUrl(orgAlias, id));
            }}
          >
            Edit
          </Dropdown.Item>
        )}
        <Dropdown.Item
          eventKey="delete-contact"
          onClick={this.handleOpenDeleteContactModal}
        >
          Delete
        </Dropdown.Item>
        {!invitationIsPending && !hasUserRole && (
          <>
            {canInviteProviders && (
              <Dropdown.Item
                eventKey="invite-add-as-provider"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(modalCloseAC());
                  history.push({
                    pathname: orgPeopleInviteUrl(orgAlias, PEOPLE_TYPE.PROVIDERS),
                    state: { emails: [email] },
                  });
                }}
              >
                Invite as provider
              </Dropdown.Item>
            )}
            {canInviteManagers && (
              <Dropdown.Item
                eventKey="invite-add-as-manager"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(modalCloseAC());
                  history.push({
                    pathname: orgPeopleInviteUrl(orgAlias, PEOPLE_TYPE.MANAGERS),
                    state: { emails: [email] },
                  });
                }}
              >
                Invite as manager
              </Dropdown.Item>
            )}
          </>
        )}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Card
          className={classNames.join(' ')}
          data-testid={`contact-card-${id}`}
          onClick={this.handleOpenContactDetailsPanel}
        >
          <Card.Body>
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-8 d-flex mb-4 mb-sm-0">
                <div className="d-flex">
                  <ProfilePic
                    url={avatar}
                    alt={fullName}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  />
                </div>
                <div className="pl-3 d-flex justify-content-center flex-column">
                  <div
                    className="font-weight-bolder contact-name"
                    data-testid="contact-card-name"
                  >
                    {fullName}
                  </div>
                  <div
                    className="font-weight-light contact-email"
                    data-testid="contact-card-email"
                  >
                    {email.toLowerCase()}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 d-flex align-items-center justify-content-end">
                <div className="px-0">
                  { invitationIsPending && !hasUserRole && (
                    <StatusTag
                      label={`Invited ${invitationRole ? `as ${USER_TYPE_LABEL[invitationRole]}` : ''}`}
                      statusClass={`${CONTACT_STATUS_CLASS[CONTACT_STATUS.INVITATION_PENDING]}`}
                    />
                  )}
                  { invitationIsRejected && !invitationIsPending && !hasUserRole && (
                    <StatusTag
                      label="Rejected"
                      statusClass={`${CONTACT_STATUS_CLASS[CONTACT_STATUS.INVITATION_REJECTED]}`}
                    />
                  )}
                  { hasUserRole && (
                    <StatusTag
                      label={`Joined as ${userRoleTitle}`}
                      statusClass={`${CONTACT_STATUS_CLASS[CONTACT_STATUS.INVITATION_ACCEPTED]}`}
                    />
                  )}
                </div>
                <div className="px-0 d-flex justify-content-end">
                  <TDDropButton
                    className="ml-auto ml-sm-0"
                    data-testid="contact-card-actions"
                    stopPropagation
                    placement={isLastItem ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
                  >
                    {actions}
                  </TDDropButton>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <ModalSimple
          body="Are you sure you want to delete this contact?"
          data-testid="contact-card-delete-contact-modal"
          heading="Delete contact"
          onClose={this.handleCloseDeleteContactModal}
          open={isDeleteContactModalOpen}
          footer={[
            <TDButton
              key={2}
              className="float-right"
              label="Cancel"
              onClick={this.handleCloseDeleteContactModal}
            />,
            <TDButton
              key={1}
              className="float-right"
              label="Delete"
              variant={BS_STYLE.DANGER}
              onClick={this.handleDeleteContact}
            />,
          ]}
        />
        <ContactDetailsPanel
          contactDetailsPanelId={contactDetailsPanelId}
          contact={item}
          data-testid="contact-card-contact-details-panel"
          panelActions={actions}
          hasUserRole={hasUserRole}
        />
      </React.Fragment>
    );
  }
}

ContactCard.propTypes = {
  contactDetailsPanelId: PropTypes.string.isRequired,
  deleteContactModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  hasPermission: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isContactDetailsPanelOpen: PropTypes.bool,
  isDeleteContactModalOpen: PropTypes.bool,
  isLastItem: PropTypes.bool,
  item: contactCardSpec.isRequired,
  match: routerMatchSpec.isRequired,
};

ContactCard.defaultProps = {
  isDeleteContactModalOpen: false,
  isContactDetailsPanelOpen: false,
  isLastItem: false,
};


const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state, props) => {
  const { item } = props;
  const deleteContactModalId = `delete-contact-modal-id-${item.id}`;
  const contactDetailsPanelId = `contact-id-${item.id}-modal-panel`;

  return {
    contactDetailsPanelId,
    deleteContactModalId,
    hasOrgAccess: getHasOrgAccess(state),
    hasPermission: getActiveUserCardPermissionChecker(state),
    isContactDetailsPanelOpen: getIsModalOpen(state, contactDetailsPanelId),
    isDeleteContactModalOpen: getIsModalOpen(state, deleteContactModalId),
  };
};

const ContactCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactCard);

export default withRouter(ContactCardConnected);
