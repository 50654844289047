import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { BS_TOOLTIP_PLACEMENT, BS_STYLE, ICON, IMG_SIZE } from 'core/assets/js/constants';
import { getFormatDate } from 'core/assets/js/ducks/hooks';
import { STATUS, TYPE } from 'invitations/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import TalentMarketIndicator from 'people/assets/js/components/TalentMarketIndicator.jsx';
import { inviteeSpec } from 'people/assets/js/lib/objectSpecs';
import { orgUserProfileUrl } from 'people/urls';

const ResendInvitationButton = ({ onOpenResendModal, userId, inviteeName }) => (
  <TDButton
    variant={BS_STYLE.LINK}
    btnIcon={ICON.RESEND}
    tooltipMsg="Resend invitation"
    tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
    onClick={() => {
      const heading = 'Resend invitation';
      const body = `Are you sure you want to resend the invitation to ${inviteeName}?`;
      const confirmLabel = 'Resend';
      onOpenResendModal(heading, body, confirmLabel, userId);
    }}
  />
);

ResendInvitationButton.propTypes = {
  onOpenResendModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  inviteeName: PropTypes.string.isRequired,
};

const CancelInvitationButton = ({ onOpenCancelModal, userId, inviteeName }) => (
  <TDButton
    variant={BS_STYLE.LINK}
    btnIcon={ICON.CROSS}
    tooltipMsg="Cancel invitation"
    tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
    onClick={() => {
      const heading = 'Cancel invitation';
      const body = `Are you sure you want to cancel the invitation to ${inviteeName}?`;
      const confirmLabel = 'Cancel invitation';
      onOpenCancelModal(heading, body, confirmLabel, userId);
    }}
  />
);

CancelInvitationButton.propTypes = {
  onOpenCancelModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  inviteeName: PropTypes.string.isRequired,
};

const OrgInvitationCard = ({
  activeOrg: { alias: orgAlias },
  onOpenCancelModal, onOpenResendModal,
  item: {
    avatar,
    createdAtISO,
    email,
    fullName,
    hasExpired,
    id,
    status,
    statusLabel,
    statusReason,
    type,
    userId,
    userType,
  },
}) => {
  const formatDate = getFormatDate();
  const className = hasExpired ? 'expired' : statusLabel.replace(/\s+/g, '-').toLowerCase();
  const label = hasExpired ? 'Expired' : statusLabel;
  const identifier = fullName || email;
  const showCancellationReason = statusReason;

  let actionButton = null;
  if (hasExpired && status === STATUS.PENDING) {
    actionButton = (
      <>
        <CancelInvitationButton
          inviteeName={identifier}
          onOpenCancelModal={onOpenCancelModal}
          userId={id}
        />
        <ResendInvitationButton
          inviteeName={identifier}
          onOpenResendModal={onOpenResendModal}
          userId={id}
        />
      </>
    );
  } else if (!hasExpired && status === STATUS.PENDING) {
    actionButton = (
      <CancelInvitationButton
        inviteeName={identifier}
        onOpenCancelModal={onOpenCancelModal}
        userId={id}
      />
    );
  } else if (!hasExpired && status === STATUS.CANCELLED) {
    actionButton = (
      <ResendInvitationButton
        inviteeName={identifier}
        onOpenResendModal={onOpenResendModal}
        userId={id}
      />
    );
  } else if (status === STATUS.ACCEPTED && !!userId && !!userType) {
    actionButton = (
      <Link to={orgUserProfileUrl(orgAlias, userType, userId)}>View profile</Link>
    );
  } else if (email) {
    actionButton = (
      <span className="user-item__extra d-flex align-items-center">
        <i className={ICON.EMAIL} />
        <a href={`mailto:${email}`}>{email}</a>
      </span>
    );
  }

  let identifierAction = null;
  if (status === STATUS.ACCEPTED && !!userId && !!userType) {
    identifierAction = (
      <>
        <Link className="text-dark font-weight-bolder" to={orgUserProfileUrl(orgAlias, userType, userId)}>{identifier}</Link>
      </>
    );
  } else {
    identifierAction = (
      <>
        { identifier }
      </>
    );
  }

  return (
    <Card
      className="invitee-card user-item"
    >
      <Card.Body>
        <div className="user-item__content row" data-id={id}>
          <div className="col-12 col-md-4 pl-3 d-flex align-items-center">
            <div className="position-relative">
              <ProfilePic
                url={avatar}
                alt={`${identifier}'s avatar`}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
              />
              {type === TYPE.ORG_INVITE_PPH && <TalentMarketIndicator />}
            </div>
            <div className="d-flex flex-column ml-5">
              <span
                data-testid="invitee-email-address"
                className="user-item__identifier mr-0 mr-sm-4"
              >
                {identifierAction}
              </span>
              <div className="discreet">{formatDate(createdAtISO, { includeTime: true })}</div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-start justify-content-md-center px-3 col-6 col-md-4 mt-5 mt-md-0">
            <StatusTag label={label} statusClass={className} />
            {showCancellationReason && (
              <span className="user-item__extra pl-2">
                <TDElementWithTooltip tooltipMsg={`Reason: ${statusReason}`}>
                  <i className={ICON.INFO} />
                </TDElementWithTooltip>
              </span>
            )}
          </div>
          <div className="action-buttons btn-group d-flex align-items-center justify-content-end col-6 col-md-4 mt-5 mt-md-0">
            {actionButton}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

OrgInvitationCard.propTypes = {
  activeOrg: orgSpec.isRequired,
  item: inviteeSpec.isRequired,
  onOpenCancelModal: PropTypes.func.isRequired,
  onOpenResendModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ activeOrg: selectActiveOrg(state) });

const OrgInvitationCardConnected = connect(mapStateToProps)(OrgInvitationCard);

export default OrgInvitationCardConnected;
