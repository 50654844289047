import React from 'react';
import PropTypes from 'prop-types';
import { ICON, BS_SIZE, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';


const BankFeesModal = ({ amounts, modalState }) => {
  const { currency, childrenBankFees, childrenBankFeesAnalysis } = amounts;
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const tableColumns = [
    { key: 'name', label: 'Provider' },
    { key: 'country', label: 'Country' },
    { key: 'fee', label: `Fee (${currencySymbol})`, isFixedDecimals: true, columnClassName: 'text-right', width: '170px' },
  ];
  const table = (
    <div>
      <p>
        Breakdown of the costs and/or transaction charges incurred to process the
        <br />
        providers&#39; payment by third parties as a Payment Fee.
      </p>

      <div className="finance-report__table-wrapper finance-report__table-wrapper--list invoice-table-wrapper mt-4 finance-report__table-wrapper--responsive">
        <Table
          containerClass="invoice-table"
          cols={tableColumns}
          items={childrenBankFeesAnalysis}
        />
        <div className="finance-report__summary">
          <div>
            <label>Total Fees</label>
            <NumberTpl prefix={currencySymbol} value={childrenBankFees} decimals={2} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <span onClick={modalState.open} className={`${ICON.INFO} do-not-print`} />

      <ModalSimple
        open={modalState.isOpen}
        heading="Bank fees breakdown"
        size={BS_SIZE.LARGE}
        body={table}
        onClose={modalState.close}
        noFooter
      />
    </React.Fragment>
  );
};

BankFeesModal.propTypes = {
  amounts: PropTypes.object.isRequired,
  modalState: modalStateSpec.isRequired,
};

export default withStateModal(BankFeesModal);
