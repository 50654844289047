import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { connect } from 'react-redux';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const withStoreDescriptor = (WrappedComponent) => {
  class _WithStoreDescriptor extends React.PureComponent {
    render() {
      const { orgSpecific, storeKey, activeOrg, ...passthroughProps } = this.props;
      const key = isArray(storeKey) ? storeKey.join('-') : storeKey;

      const descriptor = orgSpecific && activeOrg && activeOrg.unique_alias
        ? `${activeOrg.unique_alias}-${key}`
        : key;

      return (
        <WrappedComponent
          descriptor={descriptor}
          {...passthroughProps}
        />
      );
    }
  }

  _WithStoreDescriptor.propTypes = {
    storeKey: PropTypes.string.isRequired,
    activeOrg: orgSpec.isRequired,
    orgSpecific: PropTypes.bool,
  };

  _WithStoreDescriptor.defaultProps = {
    orgSpecific: false,
  };

  const mapStateToProps = state => ({
    activeOrg: selectActiveOrg(state),
  });

  return connect(mapStateToProps)(_WithStoreDescriptor);
};

export default withStoreDescriptor;
