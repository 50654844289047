import React from 'react';
import { USER_TYPE } from 'core/assets/js/constants';
import AllowAccess from './AllowAccess.jsx';


/**
 * Wrapper that renders its children only when the user is a manager
 *
 */
const AllowAccessToOrgCreator = props => (
  <AllowAccess oneOf={[USER_TYPE.ORG_CREATOR]} {...props} />
);


export default AllowAccessToOrgCreator;
