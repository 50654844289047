import { invitationManageUrl, invitationRejectUrl } from 'invitations/urls';
import { logger } from 'core/assets/js/lib/Logger';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';

class RedirectHelper {
  static redirectToHome({ history, location, determineHomeUrl, forceLogout = false } = {}) {
    const { pathname, search } = location;
    const fromUrl = `${pathname}${search}`;
    const { homeUrl, method } = determineHomeUrl({ fromUrl, forceLogout });
    logger.debug('redirecting to', homeUrl, 'using', method);
    if (method === 'window') {
      WINDOW_REDIRECT(homeUrl);
    } else {
      history.push(homeUrl);
    }
  }

  static redirectToInvitationReject({ history, token }) {
    history.push(invitationRejectUrl(token));
  }

  static redirectToInvitationManage({ history, token }) {
    history.push(invitationManageUrl(token));
  }
}

export default RedirectHelper;
