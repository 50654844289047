import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { getMergedGetStartedSteps } from 'accounts/assets/js/lib/helpers';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import OrganizationCardItem from 'organizations/assets/js/components/OrganizationCardItem.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { fetchSetupStepsDS, selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { GET_STARTED_COMPLETE_MODAL_ID, GET_STARTED_PENDING_APPROVAL_MODAL_ID } from 'accounts/assets/js/constants';
import { BS_STYLE, ICON, MODAL_SIZES, USER_CARD_STATUS } from 'core/assets/js/constants';
import { SUBMISSION_STATUS_CLASS, SUBMISSION_STATUS, SUBMISSION_STATUS_LABEL } from 'interviews/assets/js/constants';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { onboardingProgressListApiUrl, orgDashboardUrl } from 'organizations/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';

const stepToRow = (step = false) => {
  const {
    blocked,
    blockedReasons,
    customIconClassName,
    description,
    extras,
    icon,
    name,
    pending,
    title,
    url,
  } = step;
  const className = ['d-flex step-row align-items-center'];
  className.push(`step-row--${step.name}`);

  if (step.completed) {
    className.push('step-row--completed');
  }

  if (step.blocked) {
    className.push('step-row--blocked');
  }

  if (extras.submission) {
    className.push(`step-row--uc-${SUBMISSION_STATUS_CLASS[extras.submission.status]}`);
  }

  if (pending) {
    className.push('step-row--uc-pending');
  }

  let stepIcon = step.completed ? ICON.CHECK_CIRCLE : ICON.CHEVRON_RIGHT;
  if (
    extras.submission
    && (
      extras.submission.status === SUBMISSION_STATUS.PENDING
      || extras.submission.status === SUBMISSION_STATUS.SUBMITTED
    )
  ) {
    stepIcon = ICON.HOURHOURGLASS_START;
  }

  if (pending) {
    stepIcon = ICON.HOURGLASS_START;
  }

  if (blocked) {
    stepIcon = ICON.HOURGLASS_START;
  }

  const [pathname, search] = (url || '').split('?');

  return (
    <Link
      className={className.join(' ')}
      key={`${name}_${title}`}
      onClick={(e) => {
        if (step.completed || pending || !url || blocked) {
          e.preventDefault();
        }
      }}
      to={{
        pathname,
        search,
        state: { fromGetStarted: true, customTitle: title },
      }}
    >
      <div className="step-row__icon-container d-flex align-items-center justify-content-center">
        <i
          className={[
            `step-row__icon ${icon}`,
            customIconClassName ? ` ${customIconClassName}` : '',
          ].join('')}
        />
      </div>
      <div>
        <h3 data-testid={`get-started-step-row-${title}`} className="step-row__title mt-0 mb-1">
          {title}
          { extras.submission && (extras.submission.status !== SUBMISSION_STATUS.DRAFT) && (
            <StatusTag
              hideTitle
              className="ml-md-4"
              statusClass={SUBMISSION_STATUS_CLASS[extras.submission.status]}
              label={SUBMISSION_STATUS_LABEL[extras.submission.status]}
            />
          )}

          {pending && (
            <StatusTag
              hideTitle
              className="ml-md-4"
              statusClass={SUBMISSION_STATUS_CLASS[SUBMISSION_STATUS.PENDING]}
              label="In review"
            />
          )}

          {blocked && blockedReasons.map(blockedReason => (
            <StatusTag
              className="ml-md-4"
              key={blockedReason}
              hideTitle
              label={blockedReason}
              statusClass={SUBMISSION_STATUS_CLASS[SUBMISSION_STATUS.PENDING]}
            />
          ))}
        </h3>
        <span data-testid={`get-started-step-row-${description}`} className="step-row__description">{description}</span>
      </div>

      <div
        className="step-row__icon-container step-row__icon-container--state-icon ml-auto mr-0 d-flex align-items-center"
      >
        <i data-testid="get-started-state-icon" className={`step-row__state-icon ${stepIcon}`} />
      </div>
    </Link>
  );
};

const GetStartedSteps = ({
  activeOrg, completeIsOpen, pendingIsOpen, dispatch, history, steps, progresses, hasOrgAccess,
}) => {
  const orgAlias = activeOrg.unique_alias || null;
  const isOrgCreator = hasOrgAccess({ requireOrgCreator: true });
  const progress = progresses.find(p => p.organization.id === activeOrg.id);
  const isRejected = progress && progress.status === USER_CARD_STATUS.REJECTED;

  const orderedSteps = getMergedGetStartedSteps(orgAlias, steps);

  if (isOrgCreator) {
    history.push(orgDashboardUrl(orgAlias));
  }

  useEffect(() => {
    dispatch(fetchSetupStepsDS());

    // Poll the API periodically to fetch latest steps status
    const pollingInterval = setInterval(() => {
      dispatch(fetchSetupStepsDS());
    }, 60000); // 1m
    return () => clearInterval(pollingInterval);
  }, []);


  // Calculate navigate to next step if needed (moveToNextStep = true)
  useEffect(() => {
    if (!history?.location?.state?.moveToNextStep) {
      return;
    }

    const nextStep = orderedSteps.find(step => {
      const { blocked, completed, extras, pending, title, url } = step;
      // Interview step
      if (title === 'Complete the onboarding form') {
        return (!completed
          && extras.submission
          && extras.submission.status === SUBMISSION_STATUS.DRAFT
        );
      }

      // Rest of the steps
      return !blocked && !completed && !pending && url; // If url is null, skip to next one.
    });

    if (nextStep) {
      // Move to next step
      const { title, url } = nextStep;
      const [pathname, search] = url.split('?');
      history.push({
        pathname,
        search,
        state: { fromGetStarted: true, customTitle: title },
      });
      return;
    }

    if (orderedSteps.some(s => s.pending)) {
      return;
    }

    // show onboarding completed popup
    const interviewStep = orderedSteps
      .find(step => step.title === 'Complete the onboarding form');
    const isAutoApproved = !interviewStep;

    if (isAutoApproved) {
      if (pendingIsOpen) {
        dispatch(modalCloseAC(GET_STARTED_PENDING_APPROVAL_MODAL_ID));
      }
      dispatch(modalOpenAC(GET_STARTED_COMPLETE_MODAL_ID));
      return;
    }

    if (!isAutoApproved) {
      if (interviewStep?.extras?.submission?.status === SUBMISSION_STATUS.APPROVED) {
        // Submission has already been accepted
        if (pendingIsOpen) {
          dispatch(modalCloseAC(GET_STARTED_PENDING_APPROVAL_MODAL_ID));
        }
        dispatch(modalOpenAC(GET_STARTED_COMPLETE_MODAL_ID));
      } else {
        // Pending approval
        dispatch(modalCloseAC(GET_STARTED_COMPLETE_MODAL_ID));
        dispatch(modalOpenAC(GET_STARTED_PENDING_APPROVAL_MODAL_ID));
      }
    }
  }, [steps]);

  const closeCompleteModal = async () => {
    dispatch(modalCloseAC(GET_STARTED_COMPLETE_MODAL_ID));
    // force clean reload
    WINDOW_REDIRECT(orgDashboardUrl(orgAlias));
  };

  const closePendingModal = async () => {
    dispatch(modalCloseAC(GET_STARTED_PENDING_APPROVAL_MODAL_ID));
  };

  if (isRejected) {
    return (
      <div className="mt-5 pt-2">
        <OrganizationCardItem item={progress} />
      </div>
    );
  }

  return (
    <>
      <p>Before you start using TalentDesk, you need to complete the following steps:</p>

      <div className="mt-5 pt-2">
        {orderedSteps.map(step => stepToRow(step))}
      </div>

      <ModalSimple
        body={(
          <div className="d-flex flex-column align-items-center py-5" id="get-started-complete">
            <i className={`${ICON.SUCCESS} my-4 text-success`} />
            <h1 className="text-center">
              Congratulations!
              <br />
              You have completed get started.
            </h1>
            <p>Now you can proceed to organization’s Dashboard.</p>
            <TDButton
              data-testid="get-started-button-continue-to-dashboard"
              onClick={closeCompleteModal}
              className="mt-5"
              variant={BS_STYLE.PRIMARY}
              label="Continue to dashboard"
            />
          </div>
        )}
        noFooter
        onClose={closeCompleteModal}
        open={completeIsOpen}
        size={MODAL_SIZES.DEFAULT}
      />

      <ModalSimple
        body={(
          <div className="d-flex flex-column align-items-center py-5" id="get-started-complete">
            <i className={`${ICON.HOURGLASS_START} my-4 text-warning`} />
            <h1 data-testid="get-started-steps-message-modal" className="text-center">You have completed get started but your onboarding form is pending manager approval!</h1>
            <p className="font-smaller text-center">Once your onboarding form gets approved by your manager you can proceed to organization’s Dashboard.</p>
            <TDButton
              data-testid="get-started-button-got-it"
              onClick={closePendingModal}
              className="mt-5"
              variant={BS_STYLE.PRIMARY}
              label="Got it"
            />
          </div>
        )}
        noFooter
        onClose={closePendingModal}
        open={pendingIsOpen}
        size={MODAL_SIZES.LARGE}
      />
    </>
  );
};

GetStartedSteps.GetComponentName = () => 'GetStartedSidePanel';

GetStartedSteps.propTypes = {
  activeOrg: orgSpec.isRequired,
  completeIsOpen: PropTypes.bool.isRequired,
  pendingIsOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  progresses: PropTypes.array.isRequired,
  steps: PropTypes.object.isRequired,
};

const GetStartedSidePanelApiConnected = withTDApiConnected({
  duck: 'list',
  fetchData: ({ authedAxios, componentName, dispatch, querystring, url }) => (
    dispatch(fetchListDS({
      authedAxios,
      componentName,
      querystring,
      url: onboardingProgressListApiUrl(url),
    }))
  ),
  skeletonComponent: ListSkeleton,
  storeKey: GetStartedSteps.GetComponentName(),
})(GetStartedSteps);

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  completeIsOpen: getIsModalOpen(state, GET_STARTED_COMPLETE_MODAL_ID),
  hasOrgAccess: getHasOrgAccess(state),
  pendingIsOpen: getIsModalOpen(state, GET_STARTED_PENDING_APPROVAL_MODAL_ID),
  progresses: getListState(state, GetStartedSteps.GetComponentName()).items,
  steps: selectSetupSteps(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const GetStartedSidePanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GetStartedSidePanelApiConnected);

export default withRouter(GetStartedSidePanelConnected);
