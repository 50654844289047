import { toastr } from 'react-redux-toastr';
import { get, omit } from 'lodash';
import axios from 'core/assets/js/lib/tdAxios';
import { SubmissionError } from 'redux-form';

import { shouldShowMainValidationError } from 'core/assets/js/lib/utils';
import { httpErrorAC } from 'core/assets/js/ducks/errors';
import { REQ_TYPE } from 'core/assets/js/constants';
import { FORM_ERROR } from 'final-form';


export const reqFn = (reqType) => ({
  [REQ_TYPE.POST]: axios.post,
  [REQ_TYPE.PUT]: axios.put,
  [REQ_TYPE.DELETE]: axios.delete,
})[reqType];

export const fetchFromApi = ({
  authedAxios = null, url, validate = () => {}, querystring = '',
} = {}) => {
  validate();
  if (!url) {
    throw new Error(`[fetchFromApi] Invalid url(): ${url}`);
  }
  const apiUrl = `${url()}${querystring && !querystring.startsWith('?') ? '?' : ''}${querystring}`;
  return (authedAxios || axios).get(apiUrl);
};

/**
 * Wrapper function used by app specific data services to fetch either a list of items or
 * a specific item
 *
 * @param authedAxios
 * @param fetchApiUrl A function that returns the API endpoint
 * @param fetchDataAC A function that returns the action creator function that will update the redux
 *                    store with the data received by the endpoint
 * @param fetchDataErrorAC A function that returns the app specific action creator function the will
 *                         handle a potential API error and update the app specific redux store
 * @param querystring Used especially in case of pagination
 * @param paginationAC When true a new action is dispatched to save the pagination headers in
 *                       redux
 */
const fetchDataDS = ({
  authedAxios = null, fetchApiUrl, validate = () => {}, querystring = '',
  fetchDataAC, fetchDataErrorAC = () => {}, paginationAC, searchAC, componentName = '',
}) => (
  (dispatch) => {
    if (!fetchDataAC) {
      throw new Error(`[fetchDataDS] Invalid fetchDataAC(): ${fetchDataAC}`);
    }

    return fetchFromApi({ authedAxios, url: fetchApiUrl, querystring, validate })
      .then((response) => {
        const actions = fetchDataAC(response.data);
        if (Array.isArray(actions)) {
          actions.forEach(a => dispatch(a));
        } else {
          dispatch(actions);
        }

        if (paginationAC) {
          dispatch(paginationAC(response.headers, componentName));
        }

        if (searchAC) {
          dispatch(searchAC(response.headers, componentName));
        }

        return response.data;
      })
      .catch((error) => {
        const err = get(error, 'response.data') || error;
        dispatch(httpErrorAC([err], componentName));
        const acErr = fetchDataErrorAC(error, componentName);
        if (acErr) {
          dispatch(acErr);
        }
        if (error.response?.status >= 500) {
          throw error;
        }
      });
  }
);

/**
 * Wrapper function used by app specific data services to push either a list of items or
 * a specific item
 *
 * @param reqType The request type (REQ_TYPE[POST|PUT|DELETE])
 * @param pushApiUrl The api endpoint url
 * @param values The values to push
 * @param pushDataAC A function that returns the action creator function that will update the redux
 *                   store with the data received by the endpoint
 * @param pushDataErrorAC A function that returns the app specific action creator function the will
 *                        handle a potential API error and update the app specific redux store

 * @returns {function(*=): Promise<any>}
 */
const pushDataDS = ({
  reqType = REQ_TYPE.POST, pushApiUrl, values, validate = () => {}, pushDataAC,
  pushDataErrorAC = () => {}, forFinalForm = false,
}) => (
  dispatch => (
    new Promise(async (resolve, reject) => {
      try {
        validate();
        if (!pushApiUrl) {
          throw new Error(`[pushDataDS] Invalid pushApiUrl(): ${pushApiUrl}`);
        }

        // Make the request
        const response = await reqFn(reqType)(pushApiUrl, values);

        // A validation error occurred
        if (response.data._error) {
          toastr.error('Oh Snap!', response.data._error);
          if (forFinalForm) {
            return resolve({
              error: {
                [FORM_ERROR]: response.data._error || 'form error',
                ...omit(response.data, ['_error', '_meta']),
              },
            });
          }
          return reject(new SubmissionError(response.data));
        }

        // All ok, fire action creators
        if (typeof pushDataAC === 'function') {
          const actions = pushDataAC(response.data);
          if (Array.isArray(actions)) {
            actions.forEach(a => dispatch(a));
          } else {
            dispatch(actions);
          }
        }

        return resolve(response.data);
      } catch (err) {
        // Validation error
        if (err.response && err.response.data) {
          if (shouldShowMainValidationError(err.response.data)) {
            toastr.error('Oh Snap!', err.response.data._error);
          }
          if (err.response.data._meta && err.response.data._meta.isValidation) {
            if (forFinalForm) {
              return resolve({
                error: {
                  [FORM_ERROR]: err.response.data._error || 'form error',
                  ...omit(err.response.data, ['_error', '_meta']),
                },
              });
            }
            return reject(new SubmissionError(err.response.data));
          }
          dispatch(httpErrorAC([err.response.data]));
        }

        // Unexpected error
        const acErr = pushDataErrorAC(err);
        if (acErr) {
          dispatch(acErr);
        }
        return reject(err);
      }
    })
  )
);

export {
  fetchDataDS,
  pushDataDS,
};
