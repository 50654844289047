import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { addBackUrlIfLocationContains, financeInvoiceViewUrl } from 'finance/urls';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import { exporterReportExportUrl } from 'exporter/urls';
import { EXPORT_TYPES } from 'exporter/assets/js/constants';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';

const BillingHistoryTableActions = (props) => {
  const {
    history,
    invoiceId,
    match: { params: { orgAlias } },
  } = props;

  const handleViewInvoice = (e) => {
    e.stopPropagation();
    history.push(
      addBackUrlIfLocationContains(
        financeInvoiceViewUrl(orgAlias, invoiceId),
        history.location.pathname,
        '/finance/',
      ),
    );
  };

  return (
    <TDDropButton stopPropagation>
      <Dropdown.Item
        eventKey="view-invoice"
        onClick={handleViewInvoice}
      >
        View invoice
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="download-invoice"
        onClick={() => WINDOW_OPEN(exporterReportExportUrl({
          orgAlias,
          id: invoiceId,
          reportType: REPORT_TYPES.INVOICE,
          exportType: EXPORT_TYPES.PDF,
        }))}
      >
        Download invoice
      </Dropdown.Item>
    </TDDropButton>
  );
};

BillingHistoryTableActions.propTypes = {
  history: routerHistorySpec.isRequired,
  invoiceId: PropTypes.number.isRequired,
  match: routerMatchContentsSpec.isRequired,

};

export default withRouter(BillingHistoryTableActions);
