import PropTypes from 'prop-types';
import React from 'react';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';

const FilePreview = ({ className, mimeType, url }) => {
  if (mimeType !== 'application/pdf') {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <p>{`Cannot preview a file with mimetype "${mimeType}"`}</p>
        <TDButton label="Download" onClick={() => WINDOW_OPEN(url)} />
      </div>
    );
  }
  return (
    <embed
      className={`file-preview${className ? ` ${className}` : ''}`}
      src={url}
      type={mimeType}
    />
  );
};

FilePreview.propTypes = {
  className: PropTypes.string,
  mimeType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

FilePreview.defaultProps = {
  className: null,
};

export default FilePreview;
