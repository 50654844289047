import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  PERMISSIONS_LABEL,
} from 'roles/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { permissionsMembersFetchApiUrl } from 'roles/urls';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import PermissionMemberCard from 'roles/assets/js/components/PermissionMemberCard.jsx';
import PermissionRoleCard from 'roles/assets/js/components/PermissionRoleCard.jsx';
import { WINDOW } from 'core/assets/js/config/settings';
import { modalCloseAC, modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { PERMISSION_MODAL_ID } from 'roles/assets/js/components/PermissionsGroupList.jsx';

const PermissionMembersList = ({
  permission,
}) => {
  /* eslint-disable no-restricted-globals, no-undef */
  const screenWidth = window.screen.width;
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();
  const permissionModalOpen = useSelector(state => getIsModalOpen(state, PERMISSION_MODAL_ID));
  const { hasLoaded, item } = fetchDataHook({
    componentName: PermissionMembersList.GetComponentName(),
    url: permissionsMembersFetchApiUrl(activeOrg.alias),
  });

  const onScreenResize = () => {
    if (screenWidth <= 768) {
      dispatch(modalOpenAC(PERMISSION_MODAL_ID));
    }
  };

  useEffect(() => {
    onScreenResize();
    WINDOW.addEventListener('resize', onScreenResize);
    return () => WINDOW.removeEventListener('resize', onScreenResize);
  }, []);

  const body = (
    <div className="permission-members-list__body m-4">
      <h3 className="mt-2 mb-0">
        Roles
      </h3>
      <ul>
        {item[permission]?.roles?.map((role) => (
          <PermissionRoleCard
            key={role.id}
            id={role.id}
            name={role.name}
          />
        ))}
      </ul>
      <h3 className="mt-5 mb-3">
        Members
        {' '}
        <span className="permission-members-list__members-lenght">
          {`(${item[permission]?.members?.length})`}
        </span>
      </h3>
      <ul>
        {item[permission]?.members?.map((user) => (
          <PermissionMemberCard
            key={user.id}
            user={user}
          />
        ))}
      </ul>
    </div>
  );

  if (!hasLoaded) {
    return null;
  }

  return (
    <>
      {screenWidth >= 768 ? (
        <div className="permission-members-list w-50">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">{PERMISSIONS_LABEL[permission]}</div>
            </div>
            {body}
          </div>
        </div>
      ) : (
        <ModalSimple
          body={body}
          noFooter
          heading={PERMISSIONS_LABEL[permission]}
          onClose={() => dispatch(modalCloseAC(PERMISSION_MODAL_ID))}
          open={permissionModalOpen}
        />
      )}
    </>
  );
};

PermissionMembersList.propTypes = {
  permission: PropTypes.string.isRequired,
};

PermissionMembersList.GetComponentName = () => 'PermissionMembersList';

export default PermissionMembersList;
