import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { approveRateDS, declineRateDS } from 'rates/assets/js/data-services/rates';
import { getDisplayRate } from 'rates/assets/js/lib/utils';


class RateReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this._onSubmit = this._onSubmit.bind(this);
    this.onSubmitApprove = this.onSubmitApprove.bind(this);
    this.onSubmitDecline = this.onSubmitDecline.bind(this);
  }

  onSubmitApprove(values) {
    return this._onSubmit(values);
  }

  onSubmitDecline(values) {
    return this._onSubmit(values, { isApproval: false });
  }

  _onSubmit(values, { isApproval = true } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const { dispatch, match } = this.props;
        const { orgAlias, rateId } = match.params;

        let successMsg;
        let response;
        let title;
        if (isApproval) {
          response = await dispatch(approveRateDS(orgAlias, rateId, values));
          const { amount: rate, currencySymbol, unit: rateUnit } = response;
          const newRate = getDisplayRate(rate, rateUnit, { currency: currencySymbol });
          successMsg = `Your new rate is set to ${newRate}`;
          title = 'Nice!';
        } else {
          response = await dispatch(declineRateDS(orgAlias, rateId, values));
          successMsg = 'The rate adjustment request declined';
          title = 'Oh no!';
        }
        toastr.success(title, successMsg);

        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  render() {
    const { error, handleSubmit, submitting } = this.props;

    return (
      <form>
        <div className="align-top rounded p-4 bg-light">
          <Field type="hidden" name="id" component={InputField} />
          <Field
            name="message"
            type="textarea"
            component={InputField}
            placeholder="e.g Approved as per our agreement"
            label="Message"
            sublabel="Add a message to your decision"
            rest={{ rows: 3 }}
          />

          <div key="errors" className="has-error mb-4">
            <span className="help-block">{ error }</span>
          </div>

          <div className="d-flex text-right button-container">
            <TDButton
              type="submit"
              onClick={handleSubmit(this.onSubmitDecline)}
              variant={BS_STYLE.DANGER}
              disabled={submitting}
              btnIcon={ICON.CROSS}
              label="Decline"
            />

            <TDButton
              type="submit"
              onClick={handleSubmit(this.onSubmitApprove)}
              variant={BS_STYLE.SUCCESS}
              disabled={submitting}
              btnIcon={ICON.CHECKMARK}
              label="Approve"
            />
          </div>
        </div>
      </form>
    );
  }
}

RateReviewForm.propTypes = {
  error: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
  }),
  submitting: PropTypes.bool.isRequired,
};
RateReviewForm.defaultProps = {
  error: '',
  initialValues: {},
};

const RateCreateReduxForm = reduxForm({
  form: 'rate-review',
})(RateReviewForm);

export default withRouter(RateCreateReduxForm);
