import { orderBy, sum, take } from 'lodash';

/* This method provides a way to allocate percentages proportionally and
 * avoids percentages not totaling to 100% due to rounding errors
 *
 * @param values A list of integers
 *
 * See more: https://en.wikipedia.org/wiki/Largest_remainder_method
 * https://stackoverflow.com/a/13483710
 */
export const getPercentages = (values, requiredTotal = 100) => { //eslint-disable-line
  const total = sum(values.map(v => parseFloat(v)));
  const percentages = values.map(v => (requiredTotal * parseFloat(v)) / parseFloat(total));
  const roundedDown = percentages.map(v => parseInt(v, 10));
  const calcedTotal = sum(roundedDown);
  const remainder = requiredTotal - calcedTotal;

  const toIncrease = take(orderBy(
    roundedDown.map((v, idx) => ({ value: v, index: idx })),
    'value',
    'desc',
  ), remainder);

  toIncrease.forEach(({ index }) => {
    roundedDown[index] += 1;
  });

  return roundedDown;
};
