import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { taskAssignmentSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectTaskAssignmentUrl } from 'projects/urls';

const PendingTaskAssignments = props => {
  const {
    history,
    match: { params: { id: projectId, orgAlias, taskId } },
    onFollowLink,
    pendingAssignments,
  } = props;
  return (
    <>
      <p>
        You cannot mark this task as completed, because there are pending assignments.
      </p>
      <p>
        Please cancel these assignments, or wait for them to be approved:
      </p>
      <div className="mb-3">
        <ul className="bullet-list ml-4 pl-4" data-testid="pending-task-assignments-list">
          {pendingAssignments.map((a, i) => (
            <li
              className="imitate-link"
              key={i}
              onClick={() => {
                history.push(projectTaskAssignmentUrl(orgAlias, projectId, taskId, a.user.id));
                if (onFollowLink) {
                  onFollowLink();
                }
              }}
            >
              {a.user.profile.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

PendingTaskAssignments.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  onFollowLink: PropTypes.func,
  pendingAssignments: PropTypes.arrayOf(taskAssignmentSpec).isRequired,
};

PendingTaskAssignments.defaultProps = {
  onFollowLink: () => false,
};

export default withRouter(PendingTaskAssignments);
