import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';

import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import {
  fetchListDS, getListState,
} from 'core/assets/js/ducks/list';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { orgPeopleDiscoverUrl, getChatUnreadCountUrl } from 'people/urls';
import CategorySection from 'people/assets/js/components/CategorySection.jsx';
import DiscoverPeopleFreelancersView from 'people/assets/js/DiscoverPeopleFreelancersView.jsx';
import DiscoverPeopleChatView from 'people/assets/js/DiscoverPeopleChatView.jsx';
import HowItWorksModal from 'people/assets/js/components/HowItWorksModal.jsx';
import SearchBannerWidget from 'people/assets/js/components/SearchBannerWidget.jsx';
import TalentListSkeleton from 'people/assets/js/components/skeletons/TalentListSkeleton.jsx';
import TalentCard from 'people/assets/js/components/TalentCard.jsx';
import { DISCOVER_TALENTS_TABS, HOW_IT_WORKS_MODAL_ID } from 'people/assets/js/constants';
import { PPHProfileSpec } from 'search/assets/js/lib/objectSpecs';
import { discoverTalentApiUrl } from 'search/urls';

class DiscoverPeopleView extends React.Component {
  static FetchData({
    authedAxios, componentName, dispatch, params, querystring, url,
  }) {
    const apiUrl = discoverTalentApiUrl(params, url);
    return dispatch(fetchListDS({ authedAxios, componentName, querystring, url: apiUrl }));
  }

  static GetComponentName() {
    return 'DiscoverPeopleView';
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    const { orgAlias } = params;
    try {
      const { data } = await axios.get(getChatUnreadCountUrl({ orgAlias }));
      this.setState({
        unreadCount: data.unreadCount,
      });
    } catch (e) {
      toastr.error('Oh Snap!', e._error || e.message);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      unreadCount: 0,
    };
    this.getActiveTab = this.getActiveTab.bind(this);
  }

  getActiveTab() {
    const {
      currentUser,
      dispatch,
      filtersOpen,
      match: { params, url: currentUrl },
      open,
      people,
    } = this.props;
    const { orgAlias, tab } = params;
    switch (tab) {
      case DISCOVER_TALENTS_TABS.TALENTS:
        return (
          <>
            <div className="page page--people">
              <div className="container">

                <SearchBannerWidget orgAlias={orgAlias} title="Search for top talent" />
                <CategorySection />
                <h1>Curated Talent</h1>
                <TDApiConnected
                  component={this.constructor}
                  duck="list"
                  loadingEnabled={false}
                  shouldRefetchOnQueryChange={(oldQuery, newQuery) => (
                    // if invite is the same, then some search param has changed
                    // so, refetch
                    oldQuery.invite === newQuery.invite
                  )}
                  skeletonComponent={TalentListSkeleton}
                >
                  {!filtersOpen && (
                    <>
                      <TDList
                        cardItem={{
                          component: TalentCard,
                          props: {
                            currentUser,
                            parentComponentName: DiscoverPeopleView.GetComponentName(),
                            orgAlias,
                          },
                        }}
                        emptyListMessage="No talents found for your search criteria."
                        items={people}
                        listClassName="row ml-0"
                      />
                    </>
                  )}
                </TDApiConnected>
              </div>
            </div>
            <HowItWorksModal
              handleClose={() => dispatch(modalCloseAC())}
              show={open}
            />
          </>
        );
      case DISCOVER_TALENTS_TABS.FAVOURITES:
        return (
          <DiscoverPeopleFreelancersView showFavoritesOnly showHeader={false} />
        );
      case DISCOVER_TALENTS_TABS.MESSAGES:
        return (
          <DiscoverPeopleChatView showChatsOnly showHeader={false} />
        );
      default:
        return (
          <RedirectRoute
            status={302}
            from={currentUrl}
            to={orgPeopleDiscoverUrl(orgAlias, DISCOVER_TALENTS_TABS.TALENTS)}
          />
        );
    }
  }

  render() {
    const { dispatch, match } = this.props;
    const { orgAlias, tab } = match.params;
    const { unreadCount } = this.state;
    const breadcrumbs = [
      {
        title: 'TalentMarket',
        url: null,
      },
    ];

    const tabSpec = [
      {
        href: orgPeopleDiscoverUrl(orgAlias, DISCOVER_TALENTS_TABS.TALENTS),
        key: DISCOVER_TALENTS_TABS.TALENTS,
        label: 'Overview',
      },
      {
        href: orgPeopleDiscoverUrl(orgAlias, DISCOVER_TALENTS_TABS.FAVOURITES),
        key: DISCOVER_TALENTS_TABS.FAVOURITES,
        label: 'Favourites',
      },
      {
        href: orgPeopleDiscoverUrl(orgAlias, DISCOVER_TALENTS_TABS.MESSAGES),
        key: DISCOVER_TALENTS_TABS.MESSAGES,
        label: 'Messages',
        outstandingCount: unreadCount,
      },
    ];


    return (
      <>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={(
            <TDButton
              label="How it works"
              variant={BS_STYLE.DEFAULT}
              onClick={() => dispatch(modalOpenAC(HOW_IT_WORKS_MODAL_ID))}
            />
          )}
        >
          <TabBar
            activeKey={tab}
            tabSpec={tabSpec}
            showCircleBadge
          />
        </ContentHeader>
        {this.getActiveTab()}
      </>
    );
  }
}

DiscoverPeopleView.propTypes = {
  currentUser: userSpec,
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  open: PropTypes.bool,
  people: PropTypes.arrayOf(PPHProfileSpec),
};

DiscoverPeopleView.defaultProps = {
  currentUser: {},
  match: {},
  open: false,
  people: [],
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, DiscoverPeopleView.GetComponentName());
  return {
    currentUser: state.organizations.active,
    match: props.match,
    open: getIsModalOpen(state, HOW_IT_WORKS_MODAL_ID),
    people: listState.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PeopleListConnect = connect(mapStateToProps, mapDispatchToProps)(DiscoverPeopleView);
export default withRouter(withFilters(PeopleListConnect));
