import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import SwitchField from 'core/assets/js/components/FinalFormFields/SwitchField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_SIZE, BS_STYLE } from 'core/assets/js/constants';
import { DASHBOARD_WIDGET_LABEL, DASHBOARD_WIDGET_FIELD_NAME } from 'settings/assets/js/constants';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { getAvailableDashboardWidgetSettings } from 'settings/assets/js/lib/utils';

export const DASHBOARD_LAYOUT_SETTING_MODAL_ID = 'dynamic-layout-widget-modal';

const DASHBOARD_LAYOUT_SETTING_MODAL_CONTENT = {
  title: 'Edit dashboard',
  subtitle: 'You can enable or disable cards to customize your own dashboard',
};

const DashboardLayoutSettingModal = ({
  activeOrg, activeUserCard, dispatch, initialValues, onSubmit, open,
}) => {
  const switchers = getAvailableDashboardWidgetSettings(activeOrg, activeUserCard).map((widget) => {
    const fieldName = DASHBOARD_WIDGET_FIELD_NAME[widget];
    const fieldLabel = <p className="font-weight-bold">{DASHBOARD_WIDGET_LABEL[widget]}</p>;

    return (
      <SwitchField
        key={widget}
        name={fieldName}
        label={fieldLabel}
      />
    );
  });

  return (
    <div className="static-modal">
      <Form
        onSubmit={values => onSubmit(values)}
        initialValues={initialValues}
        render={({
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <ModalSimple
              size={BS_SIZE.LARGE}
              open={open}
              onClose={handleSubmit}
              heading={DASHBOARD_LAYOUT_SETTING_MODAL_CONTENT.title}
              body={(
                <div>
                  <p className="mb-4">{DASHBOARD_LAYOUT_SETTING_MODAL_CONTENT.subtitle}</p>
                  {switchers}
                </div>
              )}
              footer={(
                <div>
                  <TDButton
                    label="Cancel"
                    variant={BS_STYLE.DEFAULT}
                    onClick={() => dispatch(modalCloseAC(DASHBOARD_LAYOUT_SETTING_MODAL_ID))}
                  />
                  <TDButton
                    label="Apply"
                    onClick={handleSubmit}
                    variant={BS_STYLE.PRIMARY}
                  />
                </div>
              )}
            />
          </form>
        )}
      />
    </div>
  );
};

DashboardLayoutSettingModal.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

DashboardLayoutSettingModal.defaultProps = {
  open: false,
  initialValues: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
  initialValues: getModalPayload(state, DASHBOARD_LAYOUT_SETTING_MODAL_ID),
  open: getIsModalOpen(state, DASHBOARD_LAYOUT_SETTING_MODAL_ID),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const DashboardLayoutSettingModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardLayoutSettingModal);

export default DashboardLayoutSettingModalConnected;
