import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { completeStepDS, selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { CLASSIFICATION_SUBMISSION_RISK } from 'accounts/constants';
import {
  classificationQuestionnaireUrl,
  getClassificationQuestionnaireApiUrl,
  submitClassificationQuestionnaireApiUrl,
  orgGetStartedUrl,
} from 'accounts/urls';
import { CLASSIFICATION_QUESTIONNAIRE_INTRO } from 'admin/assets/js/constants';
import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const Question = ({ question }) => {
  const { change } = useForm();
  const { submitErrors, values } = useFormState();
  const key = `q_${question.id}`;
  const error = submitErrors?.[key];
  return (
    <Fragment key={question.id}>
      {question.sublabel && <p>{question.sublabel}</p>}
      <CheckableList
        initialValue={values.type !== undefined ? { id: values[key] } : null}
        items={question.choices.map(c => ({ id: c.id, title: c.label }))}
        itemComponent={({ item }) => <h4>{item.title}</h4>}
        onItemChecked={value => {
          change(key, value.id);
        }}
      />
      {error && <div className="clearfix mt-3 text-danger">{error}</div>}
    </Fragment>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
};

const ClassificationQuestionaireView = () => {
  const [submissionRisk, setSubmissionRisk] = useState(null);
  const activeOrg = useSelector(selectActiveOrg);
  const history = useHistory();
  const dispatch = useDispatch();
  const steps = useSelector(selectSetupSteps);

  const { hasLoaded, item } = fetchDataHook({
    componentName: ClassificationQuestionaireView.GetComponentName(),
    url: getClassificationQuestionnaireApiUrl(activeOrg.alias),
  });

  const getStartedUrl = orgGetStartedUrl(activeOrg.alias);

  if (
    steps?.userCardSetupSteps?.[activeOrg.alias]?.some(step => (
      step.name === 'ClassificationQuestionnaireStep' && step.completed
    ))
  ) {
    return (
      <RedirectRoute from={classificationQuestionnaireUrl(activeOrg.alias)} to={getStartedUrl} />
    );
  }

  if (!hasLoaded || !Array.isArray(item.questions)) {
    return null;
  }

  const isHighRisk = submissionRisk === CLASSIFICATION_SUBMISSION_RISK.HIGH;

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          {
            title: 'Complete the Classificatio Questionnaire',
            url: null,
          },
        ]}
        backUrl={getStartedUrl}
      />
      <div className="page page--classification-questionnaire row">
        <div className="container">
          {submissionRisk && (
            <Card className="d-flex flex-column align-items-center p-5">
              <i className={`${ICON.CHECKMARK_CIRCLE} fa-6x my-5`} />
              <h1>Questionnaire completed!</h1>
              <h3>Summary:</h3>
              <div className={`summary ${submissionRisk} p-5 my-5 text-center w-100`}>
                <h4 className="font-weight-bold">
                  {submissionRisk === CLASSIFICATION_SUBMISSION_RISK.LOW && (
                    'You are most likely a contractor'
                  )}
                  {submissionRisk === CLASSIFICATION_SUBMISSION_RISK.MODERATE && (
                    'We think your classification may need extra guidance'
                  )}
                  {isHighRisk && (
                    'You are most likely an employee'
                  )}
                </h4>
                {submissionRisk === CLASSIFICATION_SUBMISSION_RISK.LOW && (
                  <p>i.e. a person who provides services as an independent contractor</p>
                )}
                {isHighRisk && (
                  <p>
                    i.e. an individual who works under a contract of employment to provide services
                    to the employer
                  </p>
                )}
              </div>
              <p className="text-center">
                You should not act upon the information from this quiz without taking professional
                advice. TalentDesk Limited does not accept liability for any consequences of your
                relying upon the information contained in this document or any decision based upon
                it.
              </p>
              <TDButton
                className="my-5"
                label="Continue"
                onClick={() => completeStepDS(
                  dispatch,
                  activeOrg.alias,
                  'ClassificationQuestionnaireStep',
                  { history, moveToNextStep: !isHighRisk },
                )}
                variant={BS_STYLE.PRIMARY}
              />
            </Card>
          )}
          {!submissionRisk && (
            <>
              <div className="d-flex align-items-center mb-5">
                <i className={`${ICON.LIGHTBULB_ON_DUOTONE} fa-2x mr-3`} />
                <div>{CLASSIFICATION_QUESTIONNAIRE_INTRO}</div>
              </div>
              <Wizard
                onCancel={() => history.push(getStartedUrl)}
                onSubmit={async formValues => { // eslint-disable-line consistent-return
                  try {
                    const { data } = await axios.post(
                      submitClassificationQuestionnaireApiUrl(activeOrg.alias),
                      omit(formValues, 'confirmed'),
                    );
                    setSubmissionRisk(data.risk);
                  } catch (e) {
                    return parseAxiosErrorForFinalForm(e);
                  }
                }}
                vertical
              >
                {item.questions.map(question => (
                  <Wizard.Page
                    beforePageChange={async formValues => {
                      const key = `q_${question.id}`;
                      const errors = {};
                      if (!question.choices.some(c => c.id === formValues[key])) {
                        errors[key] = 'Please select a choice';
                      }
                      return Object.keys(errors).length > 0 ? errors : null;
                    }}
                    component={Question}
                    componentProps={{ question }}
                    nextBtnTitle="Continue"
                    title={question.label}
                  />
                ))}
                <Wizard.Page
                  beforePageChange={async formValues => {
                    if (
                      !Array.isArray(formValues.confirmed)
                      || formValues.confirmed.length !== 1
                      || !formValues.confirmed[0].value
                    ) {
                      return { confirmed: 'Please confirm' };
                    }
                    return null;
                  }}
                  component={() => (
                    <CheckboxField
                      className="mt-5"
                      name="confirmed"
                      options={[{
                        text: (
                          <>
                            If the relationship between you (the individual who has answered these
                            questions) and your employer (company appointing work on TalentDesk.io)
                            changes in due course, it is your responsibility to alert us to these
                            changes.
                          </>
                        ),
                        value: true,
                      }]}
                    />
                  )}
                  nextBtnTitle="Continue"
                  title="Disclaimer"
                />
              </Wizard>
            </>
          )}
        </div>
      </div>
    </>
  );
};

ClassificationQuestionaireView.GetComponentName = () => 'ClassificationQuestionaireView';

export default ClassificationQuestionaireView;
