import React from 'react';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import CircleSkeleton from 'core/assets/js/components/Skeleton/CircleSkeleton.jsx';

const PayoneerStatusSkeleton = () => (
  <React.Fragment>
    <CircleSkeleton diameter="60" className="m-0" />
    <br />
    <br />
    <RectangleSkeleton width="400" height="18" />
    <br />
    <RectangleSkeleton width="250" height="18" />
  </React.Fragment>
);

export default PayoneerStatusSkeleton;
