import React from 'react';
import { Modal } from 'react-bootstrap';

import { modalDataObjectOnlySpec } from 'core/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { logger } from 'core/assets/js/lib/Logger';


class ModalConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      secondaryActionSubmitting: false,
      submitting: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirmModal = this.handleConfirmModal.bind(this);
    this.handleSecondaryActionModal = this.handleSecondaryActionModal.bind(this);
    this.teardown = this.teardown.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCloseModal(ev) {
    const { onClose } = this.props;

    onClose(ev);

    this.setState({
      secondaryActionSubmitting: false,
      submitting: false,
    });
  }

  teardown(cb) {
    const { closeOnConfirm, onClose } = this.props;

    if (closeOnConfirm) {
      onClose();
    }

    if (this.mounted) {
      this.setState({
        secondaryActionSubmitting: false,
        submitting: false,
      });
    }

    if (typeof cb === 'function') {
      cb();
    }
  }

  handleConfirmModal() {
    const { onConfirm, payload, onConfirmSuccess } = this.props;
    // Disable the submit button
    this.setState(
      { submitting: true },
      () => (
        onConfirm(payload)
          .then(data => onConfirmSuccess(data))
          .then(data => this.teardown(data))
          .catch(error => {
            // Reset the submitting state, so the user can submit again
            this.setState({
              secondaryActionSubmitting: false,
              submitting: false,
            });
            logger.error(error);
          })
      ));
  }

  handleSecondaryActionModal() {
    const { onSecondaryAction, secondaryActionPayload, onSecondaryActionSuccess } = this.props;
    // Disable the secondary action button
    this.setState(
      { secondaryActionSubmitting: true },
      () => (
        onSecondaryAction(secondaryActionPayload)
          .then(onSecondaryActionSuccess)
          .then(this.teardown)
          .catch(logger.error)
      ));
  }

  render() {
    const {
      body,
      cancelLabel,
      children,
      confirmButtonDisabled,
      confirmLabel,
      confirmStyle,
      'data-testid': dataTestId,
      heading,
      onClosed,
      open,
      secondaryActionLabel,
      secondaryActionStyle,
      showConfirmButton,
      size,
    } = this.props;
    const { secondaryActionSubmitting, submitting } = this.state;

    let finalBody = children;
    if (body) {
      if (typeof body === 'function') {
        finalBody = body();
      } else {
        finalBody = body;
      }
    }

    return (
      <div className="static-modal">
        <Modal
          data-testid={dataTestId}
          onExited={onClosed}
          onHide={this.handleCloseModal}
          size={size || (secondaryActionLabel ? MODAL_SIZES.LARGE : MODAL_SIZES.SMALL)}
          show={open}
        >
          <Modal.Header closeButton>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{finalBody}</Modal.Body>

          <Modal.Footer>
            {cancelLabel && (
              <TDButton
                onClick={this.handleCloseModal}
                label={cancelLabel}
              />
            )}

            {secondaryActionLabel && (
              <TDButton
                disabled={secondaryActionSubmitting}
                variant={secondaryActionStyle}
                label={secondaryActionLabel}
                onClick={this.handleSecondaryActionModal}
              />
            )}

            {showConfirmButton && (
              <TDButton
                disabled={submitting || confirmButtonDisabled}
                variant={confirmStyle}
                onClick={this.handleConfirmModal}
                label={confirmLabel}
              />
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ModalConfirm.propTypes = modalDataObjectOnlySpec;

ModalConfirm.defaultProps = {
  cancelLabel: 'Cancel',
  children: null,
  closeOnConfirm: true,
  confirmButtonDisabled: false,
  confirmStyle: BS_STYLE.PRIMARY,
  'data-testid': '',
  onClose: () => {},
  onClosed: () => {},
  onConfirm: () => logger.warn('[ConfirmModal] onConfirm() should be impelemented'),
  onConfirmSuccess: payload => Promise.resolve(payload),
  onSecondaryAction: () => Promise.resolve(),
  onSecondaryActionSuccess: payload => Promise.resolve(payload),
  open: false,
  payload: {},
  secondaryActionLabel: '',
  showConfirmButton: true,
  secondaryActionStyle: BS_STYLE.DEFAULT,
  size: null,
};

export default ModalConfirm;
