import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';

const SkillsCardItemActions = ({ onEndorseSkills }) => (
  <TDDropButton stopPropagation className="float-right">
    {onEndorseSkills && (
      <Dropdown.Item
        eventKey="edit-skill"
        onClick={onEndorseSkills}
      >
        Endorse Skills
      </Dropdown.Item>
    )}
  </TDDropButton>
);

SkillsCardItemActions.propTypes = {
  onEndorseSkills: PropTypes.func.isRequired,
};

export default withRouter(SkillsCardItemActions);
