/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { FORM_ERROR } from 'final-form';
import { omit, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { isSSR } from 'core/assets/js/config/checks';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { assignTaskDS, availableTaskMembersDS } from 'projects/assets/js/data-services/tasks';
import {
  getListState, getListStateExtras, listRemoveSelectedItemsAC,
} from 'core/assets/js/ducks/list';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { PROJECT_TABS, TASK_TABS, BS_STYLE, MEMBER_SEARCH_TARGET } from 'core/assets/js/constants';
import { locationSetParam, locationUnsetParam } from 'core/assets/js/lib/utils';
import { projectViewUrl, projectViewTaskUrl } from 'projects/urls';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import ProviderCard from 'people/assets/js/components/ProviderCard.jsx';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import TaskAssignmentForm, { FORM_ID as TASK_ASSIGNMENT_FORM_ID } from 'projects/assets/js/components/TaskAssignmentForm.jsx';
import { DOCUMENT_GET_ELEMENT_BY_ID, DISPATCH_EVENT_ON_ELEMENT } from 'core/assets/js/config/settings';
import { parseSearchableFieldsInQuery } from 'interviews/assets/js/lib/utils';
import AddProvidersSkillsQuickFilter from 'projects/assets/js/components/AddProvidersSkillsQuickFilter.jsx';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';

export const MODAL_ID = 'add-task-assignees-panel';

const STEP = {
  BROWSE: 'browse',
  INVITE: 'invite',
};

class TaskAssigneesPanel extends React.Component {
  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    const { orgAlias, id: projectId, taskId } = params;

    const queryString =  qs.stringify({
      ...qs.parse(querystring),
      excludeMembersOfTaskId: taskId,
      useFallback: true, // THIS IS TEMPORARY WHILST WE INVESTIGATE ES INDEXING ISSUES
    });

    return Promise.all([
      dispatch(
        availableTaskMembersDS({
          authedAxios,
          componentName,
          orgAlias,
          peopleType: MEMBER_SEARCH_TARGET.PROVIDERS,
          projectId,
          querystring: queryString,
          taskId,
          url,
        }),
      ),
    ]);
  }

  static GetComponentName() {
    return 'TaskAssigneesPanel';
  }

  constructor(props) {
    super(props);
    const {
      match: { params: { taskId } },
    } = props;

    this.constantFilters = {
      excludeMembersOfTaskId: taskId,
    };

    this.state = {
      submitting: false,
      showSelectedItems: false,
      query: { ...this.constantFilters },
    };

    this.setFormSubmitting = this.setFormSubmitting.bind(this);
    this.submitInvitationsForm = this.submitInvitationsForm.bind(this);
    this.getCurrentStep = this.getCurrentStep.bind(this);
    this.handleItemRemove = this.handleItemRemove.bind(this);
    this.handleMembersAssigned = this.handleMembersAssigned.bind(this);
    this.handleAssignmentCancelled = this.handleAssignmentCancelled.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.handleShowSelectedItemsToggle = this.handleShowSelectedItemsToggle.bind(this);
    this.peopleSearchFormRef = React.createRef(null);
  }

  getCurrentStep() {
    const { history: { location: { search } } } = this.props;
    const userIds = qs.parse(search).userIds;
    return isEmpty(userIds) ? STEP.BROWSE : STEP.INVITE;
  }

  setFormSubmitting(isSubmitting) {
    this.setState({ submitting: isSubmitting });
  }

  submitInvitationsForm() { // eslint-disable-line class-methods-use-this
    if (isSSR) { // ultra-paranoid check here
      return;
    }

    DISPATCH_EVENT_ON_ELEMENT(
      DOCUMENT_GET_ELEMENT_BY_ID(TASK_ASSIGNMENT_FORM_ID),
      'submit',
      { cancelable: true },
    );
  }

  async handleMembersAssigned(values) {
    this.setFormSubmitting(true);
    const {
      onAssigneesInvited, history, dispatch, match: { params: { orgAlias, id: projectId, taskId } },
    } = this.props;

    try {
      await dispatch(
        assignTaskDS({
          orgAlias,
          projectId,
          taskId,
          values,
          componentName: TaskAssigneesPanel.GetComponentName(),
        }),
      );

      await onAssigneesInvited();

      history.push(projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION));
    } catch (err) {
      this.setFormSubmitting(false);
      const errorData = err.errors || {};

      return {
        [FORM_ERROR]: errorData._error || err._error || err.message,
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }

    this.setFormSubmitting(false);

    return true;
  }

  handleItemRemove(userId) {
    const { dispatch } = this.props;
    dispatch(listRemoveSelectedItemsAC(userId, TaskAssigneesPanel.GetComponentName()));
  }

  handlePageChange(newPagination) {
    const { query } = this.state;
    this.setState({ query: { ...query, ...newPagination } });
  }

  handleQueryUpdate(newQuery) {
    const { pagination } = this.props;
    this.setState({ query: { ...parseSearchableFieldsInQuery(newQuery), page: pagination.page } });
  }

  handleAssignmentCancelled() {
    const { history } = this.props;
    history.push(
      locationUnsetParam(locationUnsetParam(history.location, 'userIds'), 'action'),
    );
  }

  handleShowSelectedItemsToggle() {
    const { showSelectedItems } = this.state;
    this.setState({ showSelectedItems: !showSelectedItems });
  }

  handleStepUpdated(step) {
    const { history, selectedProviders } = this.props;
    let newLocation = null;

    if (step === STEP.INVITE) {
      newLocation = locationSetParam(
        history.location, 'userIds', selectedProviders.map(p => p.user.id).join(','),
      );
    } else {
      newLocation = locationUnsetParam(history.location, 'userIds');
    }

    history.push(newLocation);
  }

  render() {
    const {
      customFieldFilters,
      filtersOpen,
      match: { params: { id: projectId, orgAlias } },
      onFiltersToggle,
      providers,
      selectedProviders,
      task,
      userGroupNames,
    } = this.props;

    const userGroupNamesFormatted = userGroupNames.map(n => ({ value: n.id, text: n.name }));
    const { query, showSelectedItems, submitting } = this.state;
    const currentStep = this.getCurrentStep();
    const availabilityFilter = query.availability ? JSON.parse(query.availability) : null;

    const heading = (
      <h4>Add providers</h4>
    );

    const emptyListMessage = (
      <TDSystemMessage
        title="No available providers"
        type={BS_STYLE.WARNING}
        className="mt-4"
      >
        <p className="mb-2">
          There are no available providers you can assign this task to.
        </p>
        <p>
          <Link to={projectViewUrl(orgAlias, projectId, PROJECT_TABS.TEAM)}>
            Click here
          </Link>
          {' '}
          to invite new members to your project.
        </p>
      </TDSystemMessage>
    );

    const body = (
      <React.Fragment>
        <SelectableListWrapper
          selectModeEnabled
          className={currentStep === STEP.INVITE ? 'd-none' : null}
          componentName={TaskAssigneesPanel.GetComponentName()}
          fetchData={TaskAssigneesPanel.FetchData}
          skeletonComponent={PeopleListSkeleton}
          query={query}
          filtersOpen={filtersOpen}
          showSelectedItems={showSelectedItems}
          onPageChange={this.handlePageChange}
          emptyListMessage={emptyListMessage}
          items={providers}
          cardItem={{
            component: ProviderCard,
            props: {
              orgAlias,
              onUpdated: () => { },
              availabilityFilter,
              showAvailabilityMessages: true,
              // Open link in a new tab so that user doesn't loose selected items.
              linkTarget: '_blank',
            },
          }}
          emptySelectedListMessage={(
            <p className="discreet">
              There are currently no selected providers
              <br />
              <span
                className="imitate-link"
                onClick={this.handleShowSelectedItemsToggle}
              >
                Find Providers
              </span>
              .
            </p>
          )}
          searchComponent={(
            <PeopleSearch
              customFieldFilters={customFieldFilters}
              extraSearchRow={(
                <AddProvidersSkillsQuickFilter
                  formRef={this.peopleSearchFormRef}
                  handleQueryUpdate={this.handleQueryUpdate}
                  isTask
                  query={query}
                  skills={task?.skills}
                />
              )}
              filtersOpen={filtersOpen}
              hideStatus
              isManager
              onFiltersChanged={this.handleQueryUpdate}
              onFiltersToggle={onFiltersToggle}
              peopleType={PEOPLE_TYPE.PROVIDERS}
              query={query}
              searchFormRef={this.peopleSearchFormRef}
              userGroupNames={userGroupNamesFormatted}
            />
          )}
        />

        {currentStep === STEP.INVITE && (
          <TaskAssignmentForm
            providers={selectedProviders}
            onAssignmentsSubmitted={this.handleMembersAssigned}
            onRemoveItem={this.handleItemRemove}
          />
        )}
      </React.Fragment>
    );

    const footer = (
      <React.Fragment>
        {currentStep === STEP.BROWSE && selectedProviders.length > 0 && (
          <span
            className="cursor-pointer d-flex align-items-center"
            onClick={this.handleShowSelectedItemsToggle}
          >
            <TDSwitch selected={showSelectedItems} />
            <span className="ml-3">
              {!showSelectedItems
                ? `Show ${selectedProviders.length} selected providers`
                : 'Show all providers'
              }
            </span>
          </span>
        )}

        <div className="ml-auto">
          {currentStep === STEP.BROWSE && (
            <React.Fragment>
              <TDButton
                label="Cancel"
                onClick={this.handleAssignmentCancelled}
              />

              <TDButton
                data-testid="task-asssignees-panel-next-button"
                className="ml-4"
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={selectedProviders.length === 0}
                label="Next"
                onClick={() => this.handleStepUpdated(STEP.INVITE)}
              />
            </React.Fragment>
          )}

          {currentStep === STEP.INVITE && (
            <React.Fragment>
              <TDButton
                label="Back"
                disabled={submitting}
                onClick={() => this.handleStepUpdated(STEP.BROWSE)}
              />

              <TDButton
                data-testid="task-assignees-panel-send-invitation-button"
                className="ml-4"
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={selectedProviders.length === 0 || submitting}
                label={`Send invitation${selectedProviders.length > 1 ? 's' : ''}`}
                onClick={this.submitInvitationsForm}
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );

    return (
      <ModalPanel
        data-testid="task-assignees-panel"
        modalId={MODAL_ID}
        onClose={this.handleAssignmentCancelled}
        heading={heading}
        body={body}
        footer={footer}
        open
      />
    );
  }
}

TaskAssigneesPanel.propTypes = {
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onAssigneesInvited: PropTypes.func,
  onFiltersToggle: PropTypes.func,
  pagination: PropTypes.object.isRequired,
  providers: PropTypes.arrayOf(PropTypes.object),
  selectedProviders: PropTypes.arrayOf(PropTypes.object),
  task: projectTaskSpec.isRequired,
  userGroupNames: PropTypes.arrayOf(PropTypes.object),
};

TaskAssigneesPanel.defaultProps = {
  customFieldFilters: [],
  filtersOpen: false,
  onAssigneesInvited: () => {},
  onFiltersToggle: () => {},
  providers: [],
  selectedProviders: [],
  userGroupNames: [],
};

const mapStateToProps = (state) => {
  const componentName = TaskAssigneesPanel.GetComponentName();
  const listState = getListState(state, componentName);

  return {
    customFieldFilters: getListStateExtras(state, componentName, 'customFieldFilters'),
    pagination: listState.pagination,
    providers: listState.items,
    selectedProviders: listState.selectedItems,
    userGroupNames: listState.extras.userGroupNames,
  };
};
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskAssigneesPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAssigneesPanel);

export default withRouter(withFilters(TaskAssigneesPanelConnected));
