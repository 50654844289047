import React from 'react';
import {
  IMG_SIZE,
  MODAL_SIZES,
  ICON,
} from 'core/assets/js/constants';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import PropTypes from 'prop-types';

const ChatParticipantsModal = ({ participants, onClose, isModalOpen, openAddModal }) => {
  return (
    <ModalSimple
      onClose={onClose}
      body={(
        <ul
          className="d-flex flex-column"
        >
          <li
            className="d-flex flex-row pb-4 cursor-pointer align-items-center"
            onClick={openAddModal}
          >
            <span className={`chat__icon add__icon ${ICON.CIRCLE_PLUS}`} />
            <span className="pl-3">
              <strong className="text-truncate name">Add participants</strong>
            </span>
          </li>
          {participants?.map(({ id, name, avatar }) => {

            return (
              <li
                className="d-flex flex-row pb-4 cursor-pointer"
                key={id}
              >
                <div>
                  <ProfilePic
                    url={avatar}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  />
                  <span className="pl-3">
                    <strong className="text-truncate name">{name}</strong>
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      heading="Participants"
      open={isModalOpen}
      size={MODAL_SIZES.LARGE}
      noFooter
    />

  );

};

ChatParticipantsModal.propTypes = {
  participants: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  openAddModal: PropTypes.func.isRequired,
};

ChatParticipantsModal.defaultProps = {
  participants: [],
};

export default ChatParticipantsModal;
