import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import TimeTrackerForm, { TIME_TRACKER_MODAL_ID } from 'projects/assets/js/components/timeTracker/TimeTrackerForm.jsx';
import StopWatchForm from 'projects/assets/js/components/timeTracker/StopWatchForm.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import InvoiceCapUsageBar from 'projects/assets/js/components/InvoiceCapUsageBar.jsx';
import TimeTrackerArchived from 'projects/assets/js/components/timeTracker/TimeTrackerArchived.jsx';
import TimeTrackerUnused from 'projects/assets/js/components/timeTracker/TimeTrackerUnused.jsx';
import { SERVICE_ORDER_TYPE, TIME_TRACKER_TABS } from 'projects/assets/js/constants';
import { fetchTimeTrackerUnusedRecordsDS } from 'projects/assets/js/data-services/view';
import { getTimeTrackingTaskOptionsApiUrl, timeTrackerUrl } from 'projects/urls';

const TimeTrackerView = () => {
  const [taskOptions, setTasksOptions] = useState([]);
  const [hasLoaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);
  const unUsedTimeBlocks = useSelector(
    state => state.view?.[TimeTrackerUnused.GetComponentName()]?.item,
  );
  const activeUserCard = useSelector(selectActiveUserCard);

  const tab = useParams().tab || TIME_TRACKER_TABS.TIME_ENTRIES;

  const hasTasks = taskOptions.length > 0;

  useEffect(() => {
    axios
      .get(getTimeTrackingTaskOptionsApiUrl(activeOrg.alias))
      .then(response => {
        setTasksOptions(response.data);
      })
      .catch((err) => {
        toastr.error('Oh Snap!', err.response?.data?._error || err.message);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, []);

  const fetchTimeBlocks = () => dispatch(fetchTimeTrackerUnusedRecordsDS({
    componentName: TimeTrackerUnused.GetComponentName(), orgAlias: activeOrg.alias,
  }));

  useEffect(() => {
    fetchTimeBlocks();
  }, []);

  if (!hasLoaded || !activeOrg.time_tracker_enabled) {
    return null;
  }

  const openWorkTimeBlockForm = (payload = null) => dispatch(
    modalOpenAC(TIME_TRACKER_MODAL_ID, payload),
  );

  const additionalOrganizationAmount = unUsedTimeBlocks?.totalOrgAmountInCurrentInvoiceCapPeriod
    || 0;

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Time tracker', url: null }]} />
      <div className="page page--time-tracker">
        <div className="container">
          {hasTasks && (
            <>
              <InvoiceCapUsageBar
                additionalOrganizationAmount={additionalOrganizationAmount}
                className="mb-5"
                serviceOrderType={SERVICE_ORDER_TYPE.WORKSHEET}
                userId={activeUserCard.user?.id}
              />
              <div className="row mb-5 pb-2">
                <div className="col">
                  <div
                    className="h-100 time-tracker-container rounded shadow-sm d-flex align-items-center px-4"
                  >
                    <StopWatchForm
                      taskOptions={taskOptions}
                      onSuccess={fetchTimeBlocks}
                      timeBlocks={unUsedTimeBlocks}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-auto mt-4 mt-md-0">
                  <button
                    className="add-work-time-block w-100"
                    onClick={() => openWorkTimeBlockForm()}
                    type="button"
                  >
                    <i className={`${ICON.CIRCLE_PLUS} mr-3`} />
                    Add time entry manually
                  </button>
                </div>
              </div>
              <div className="rounded shadow-sm bg-white">
                <TabBar
                  activeKey={tab}
                  className="px-5"
                  tabSpec={[
                    {
                      key: TIME_TRACKER_TABS.TIME_ENTRIES,
                      label: 'Time entries',
                      href: timeTrackerUrl(activeOrg.alias, TIME_TRACKER_TABS.TIME_ENTRIES),
                    },
                    {
                      key: TIME_TRACKER_TABS.ARCHIVE,
                      label: 'Archive',
                      href: timeTrackerUrl(activeOrg.alias, TIME_TRACKER_TABS.ARCHIVE),
                    },
                  ]}
                />
                <div className="p-5">
                  {tab === TIME_TRACKER_TABS.TIME_ENTRIES && (
                    <TimeTrackerUnused
                      additionalOrganizationAmount={additionalOrganizationAmount}
                      openWorkTimeBlockForm={openWorkTimeBlockForm}
                    />
                  )}
                  {tab === TIME_TRACKER_TABS.ARCHIVE && <TimeTrackerArchived />}
                </div>
              </div>
              <TimeTrackerForm taskOptions={taskOptions} />
            </>
          )}
          {!hasTasks && (
            <TDSystemMessage
              className="mb-4"
              title="No tasks to track!"
              type={BS_STYLE.WARNING}
            >
              You must first be invited to work on some tasks, with an hourly rate, before you can
              use the Time Tracker. Please talk with your manager to be invited to a task.
            </TDSystemMessage>
          )}
        </div>
      </div>
    </>
  );
};

TimeTrackerView.GetComponentName = () => 'TimeTrackerView';

export default TimeTrackerView;
