import PropTypes from 'prop-types';
import React from 'react';

import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';

const DatePickerSimpleField = ({
  additionalError,
  'data-testid': dataTestId,
  disableAfterDate,
  disableBeforeDate,
  disabled,
  input,
  label,
  labelPopOverContent,
  labelPopOverTitle,
  meta: { error, pristine, submitError },
  placeholder,
  required,
  sublabel,
}) => {
  const classNames = ['form-group'];
  const showAdditionalError = pristine && additionalError;
  if (submitError || error || showAdditionalError) {
    classNames.push('has-error');
  }

  return (
    <div className={classNames.join(' ')} data-testid={dataTestId}>
      <TDLabel
        label={label}
        name={input.name}
        popOverContent={labelPopOverContent}
        popOverTitle={labelPopOverTitle}
        required={required}
        sublabel={sublabel}
      />
      {showAdditionalError && (
        <span className="help-block mt-3">{additionalError}</span>
      )}
      <input
        className="form-control"
        disabled={disabled}
        id={`field-${input.name}`}
        max={disableAfterDate}
        min={disableBeforeDate}
        name={input.name}
        onChange={({ target: { value } }) => {
          input.onChange(value);
        }}
        placeholder={placeholder}
        type="date"
        value={input.value}
      />
      {error && <span className="help-block d-inline-block mt-3">{error}</span>}
      {submitError && <span className="help-block d-inline-block mt-3">{submitError}</span>}
    </div>
  );
};


DatePickerSimpleField.propTypes = {
  additionalError: PropTypes.string,
  'data-testid': PropTypes.string,
  disableAfterDate: PropTypes.string,
  disableBeforeDate: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  labelPopOverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelPopOverTitle: PropTypes.string,
  meta: finalFormFieldMetaSpec,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
};

DatePickerSimpleField.defaultProps = {
  additionalError: '',
  'data-testid': 'date-picker-field',
  disableAfterDate: null,
  disableBeforeDate: null,
  disabled: false,
  label: '',
  labelPopOverContent: null,
  labelPopOverTitle: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  placeholder: 'Select a date',
  required: false,
  sublabel: null,
};

export default withField(DatePickerSimpleField);
