import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import SearchForm from 'core/assets/js/components/SearchForm.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const searchSpec = {
  searchTerm: {
    placeholder: 'Search skills',
    paramName: 'kw',
    component: InputField,
  },
  filters: [],
};

const SkillSearchForm = ({ onFiltersChanged, query, initialValues }) => (
  <SearchForm
    className=""
    name="skill-search"
    query={query}
    initialValues={initialValues}
    searchSpec={searchSpec}
    onFiltersToggle={() => {}}
    onFiltersChanged={onFiltersChanged}
    filtersOpen={false}
  />
);

SkillSearchForm.propTypes = {
  onFiltersChanged: PropTypes.func,
  query: PropTypes.object,
  initialValues: PropTypes.object,
};

SkillSearchForm.defaultProps = {
  initialValues: {},
  onFiltersChanged: null,
  query: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});
const SkillSearchFormConnect = connect(
  mapStateToProps,
)(SkillSearchForm);

export default withRouter(SkillSearchFormConnect);
