import React from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';

const getStepClassName = ({ active, completed, rejected, pending }) => {
  switch (true) {
    case rejected:
      return 'rejected';
    case active:
      return 'active';
    case pending:
      return 'pending';
    case completed:
      return 'completed';
    default:
      return '';
  }
};

const getStepIcon = ({ completed, rejected }) => {
  switch (true) {
    case rejected:
      return ICON.TIMES;
    case completed:
      return ICON.CHECKMARK;
    default:
      return '';
  }
};

const WizardSteps = ({
  steps,
}) => (
  <div className="wizard-horizontal-steps mb-4">
    {steps.map(({ title, active, page, completed, rejected, pending }) => {
      const isLastStep = steps.length === page;
      const extraClass = getStepClassName({ active, completed, pending, rejected });
      const stepIcon = getStepIcon({ completed, rejected });

      return (
        <React.Fragment key={page}>
          <div className={`wizard-horizontal-step ${extraClass}`}>
            <span className="wizard-horizontal-step__num">{(completed || rejected) ? <span className={stepIcon} /> : page}</span>
            <span data-testid={`wizard-step-title-${title}`} className="wizard-horizontal-step__title">{title}</span>
          </div>
          {!isLastStep && <div className="wizard-horizontal-separator" />}
        </React.Fragment>
      );
    })}
  </div>
);

WizardSteps.propTypes = {
  steps: PropTypes.array.isRequired,
};
export default WizardSteps;
