import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { openStripeUpdateLinkDS } from 'finance/assets/js/data-services/stripe';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';

const StripeUpdateSubscriptionButton = ({
  dispatch, orgId, match: { url: returnUrl },
  className, variant, label,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const classes = [];
  if (className) {
    classes.push(className);
  }

  const updateStripeSub = useCallback(() => {
    setSubmitting(true);

    const update = async () => {
      const updateURL = await openStripeUpdateLinkDS({
        orgId,
        returnUrl,
      }, dispatch);

      setSubmitting(false);

      WINDOW_REDIRECT(updateURL);
    };

    update();
  }, [setSubmitting, orgId, dispatch, returnUrl]);

  return (
    <TDButton
      disabled={submitting}
      className={classes.join('')}
      label={label}
      onClick={updateStripeSub}
      type="submit"
      variant={variant}
    />
  );
};

StripeUpdateSubscriptionButton.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string,
  orgId: PropTypes.number.isRequired,
  match: routerMatchSpec.isRequired,
  variant: PropTypes.string,
};

StripeUpdateSubscriptionButton.defaultProps = {
  className: null,
  label: 'Update subscription',
  variant: BS_STYLE.PRIMARY,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps,
)(StripeUpdateSubscriptionButton));
