import React from 'react';

import { Card } from 'react-bootstrap';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const SubscriptionCardSkeleton = () => (
  <Card>
    <Card.Header className="py-3">
      <RectangleSkeleton width="100" height="15" />
    </Card.Header>
    <Card.Body>
      <RectangleSkeleton width="50" height="40" />
      <br />
      <br />
      <RectangleSkeleton width="150" height="15" />
      <br />
      <br />
      <RectangleSkeleton width="150" height="15" />
    </Card.Body>
  </Card>
);

export default SubscriptionCardSkeleton;
