import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { getViewState } from 'core/assets/js/ducks/view';
import { projectViewUrl } from 'projects/urls';
import { API_DATE_FORMAT, BS_TOOLTIP_PLACEMENT, DATE_FORMAT_DEFAULT, ICON, PROJECT_TABS } from 'core/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { SERVICE_ORDER_PROCESSED } from 'projects/assets/js/constants';

class ProjectUpcomingPaymentsWidget extends React.Component {
  static GetComponentName() {
    return 'ProjectUpcomingPaymentsWidget';
  }

  constructor(props) {
    super(props);
    this.goToWorksheets = this.goToWorksheets.bind(this);
    this.goToExpenses = this.goToExpenses.bind(this);
  }

  goToWorksheets({ orgAlias, project, dateRange }) {
    const { history, worksheetsPayments } = this.props;
    const hasWorksheetsPayments = worksheetsPayments.count > 0;
    if (hasWorksheetsPayments) {
      history.push({
        pathname: projectViewUrl(orgAlias, project.id, PROJECT_TABS.WORKSHEETS),
        search: queryString.stringify({
          date: dateRange,
          invoiced: SERVICE_ORDER_PROCESSED.NO,
          page: 1,
          status: 10,
        }),
      });
    }
  }

  goToExpenses({ orgAlias, project, dateRange }) {
    const { expensesPayments, history } = this.props;
    const hasExpensesPayments = expensesPayments.count > 0;
    if (hasExpensesPayments) {
      history.push({
        pathname: projectViewUrl(orgAlias, project.id, PROJECT_TABS.EXPENSES),
        search: queryString.stringify({
          date: dateRange,
          invoiced: SERVICE_ORDER_PROCESSED.NO,
          page: 1,
          status: 10,
        }),
      });
    }
  }

  render() {
    const {
      expensesPayments, hasOrgAccess,
      nextBillingDeadline, project, totalPayments, worksheetsPayments,
      activeOrg,
    } = this.props;
    const orgAlias = activeOrg.unique_alias;
    const lowerLimitForWs = worksheetsPayments
      && worksheetsPayments.nonProcessedCreatedWsDate;
    const lowerLimitForExp = expensesPayments
      && expensesPayments.nonProcessedCreatedExpDate;

    const formattedNextBillDate = moment(nextBillingDeadline).format(DATE_FORMAT_DEFAULT);
    const formattedApiNextBillDate = moment(nextBillingDeadline).format(API_DATE_FORMAT);
    const formattedWsDate = moment(lowerLimitForWs).format(API_DATE_FORMAT);
    const formattedExpDate = moment(lowerLimitForExp).format(API_DATE_FORMAT);

    const wsDateRange = `{"min":"${formattedWsDate}","max":"${formattedApiNextBillDate}"}`;
    const expDateRange = `{"min":"${formattedExpDate}","max":"${formattedApiNextBillDate}"}`;
    const hasWorksheetsPayments = worksheetsPayments && worksheetsPayments.count > 0;
    const hasExpensesPayments = expensesPayments && expensesPayments.count > 0;
    const isManager = hasOrgAccess({ requireManager: true });
    const helpTextForManager = `Total amount of approved worksheets and expenses to be invoiced on ${formattedNextBillDate}. Please note this may include worksheets and expenses of full time employees, which will not be included in our invoice and not be paid through TalentDesk.io.`;
    const helpTextForProvider = `Total amount of approved worksheets and expenses to be invoiced on ${formattedNextBillDate}.  Please note that if you are a full time employee your worksheets and expenses will not be processed and be paid through TalentDesk.io.`;
    const helpTextTooltip = (
      <TDElementWithTooltip
        placement={BS_TOOLTIP_PLACEMENT.TOP}
        tooltipMsg={isManager ? helpTextForManager : helpTextForProvider}
      >
        <i className={`${ICON.INFO} ml-3`} />
      </TDElementWithTooltip>
    );
    return (
      <Card
        className="project-widget__upcoming-payments"
        data-testid="project-upcoming-payments-widget"
      >
        <Card.Header>
          Upcoming payment orders
        </Card.Header>
        <Card.Body>
          {worksheetsPayments && (
            <div className="upcoming-payments upcoming-payments__worksheets-container">
              <div className="row px-4">
                <div className="col-7 pl-0">
                  Worksheets
                </div>
                <div className="col-5">
                  <div className="row">
                    <span
                      className={`col-6 pr-0 text-right upcoming-amount upcoming-amount--worksheets ${hasWorksheetsPayments ? 'imitate-link' : ''}`}
                      onClick={() => this.goToWorksheets({
                        orgAlias, project, dateRange: wsDateRange,
                      })}
                    >
                      {worksheetsPayments.count}
                    </span>
                    <span className="col-6 pr-0 text-right upcoming-value upcoming-value--worksheets">
                      <NumberTpl
                        value={worksheetsPayments.amount}
                        prefix={project.currency_symbol}
                        displayType="text"
                        thousandSeparator
                        decimals={2}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {expensesPayments && (
            <div className="upcoming-payments upcoming-payments__expenses-container">
              <div className="row px-4">
                <div className="col-7 pl-0">
                  Expenses
                </div>
                <div className="col-5">
                  <div className="row">
                    <span
                      className={`col-6 pr-0 text-right upcoming-amount upcoming-amount--expenses ${hasExpensesPayments ? 'imitate-link' : ''}`}
                      onClick={() => this.goToExpenses({
                        orgAlias, project, dateRange: expDateRange,
                      })}
                    >
                      {expensesPayments.count}
                    </span>
                    <span className="col-6 pr-0 text-right upcoming-value upcoming-value--expenses">
                      <NumberTpl
                        value={expensesPayments.amount}
                        prefix={project.currency_symbol}
                        displayType="text"
                        thousandSeparator
                        decimals={2}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="upcoming-payments upcoming-payments__totals-container">
            <div className="row px-4">
              <div className="col-12 col-md-7 pl-0">
                <span>{`Invoice is generated on ${formattedNextBillDate}`}</span>
                {helpTextTooltip}
              </div>
              {totalPayments && (
                <div className="col-12 col-md-5">
                  <div className="row">
                    <span
                      className="col-6 pr-0 text-md-right pl-0 pl-md-3 upcoming-label upcoming-label--totals"
                    >
                      Total payable
                    </span>
                    <span className="col-6 pr-0 text-right pl-0 pl-md-3 upcoming-value upcoming-value--totals">
                      <NumberTpl
                        value={totalPayments.amount}
                        prefix={project.currency_symbol}
                        displayType="text"
                        thousandSeparator
                        decimal={2}
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}


ProjectUpcomingPaymentsWidget.propTypes = {
  activeOrg: orgSpec.isRequired,
  nextBillingDeadline: PropTypes.string.isRequired,
  expensesPayments: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  worksheetsPayments: PropTypes.object.isRequired,
  totalPayments: PropTypes.object.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
};


const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, ProjectUpcomingPaymentsWidget.GetComponentName());
  return {
    match: props.match,
    activeOrg: selectActiveOrg(state),
    worksheetsPayments: viewState.item.worksheetsPayments,
    expensesPayments: viewState.item.expensesPayments,
    totalPayments: viewState.item.totalPayments,
    nextBillingDeadline: viewState.item.nextBillingDeadline,
    hasOrgAccess: getHasOrgAccess(state),
  };
};

const ProjectPaymentsWidgetConnected = connect(
  mapStateToProps,
  null,
)(ProjectUpcomingPaymentsWidget);

export default withRouter(ProjectPaymentsWidgetConnected);
