/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field, useForm, useFormState } from 'react-final-form';

import { ICON } from 'core/assets/js/constants';
import { FIELD_TYPE } from 'integrations/assets/js/constants';
import { validateMappingField, getIconByFieldType } from 'integrations/assets/js/helpers';
import withField from 'core/assets/js/components/withField.jsx';
import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';

const CUSTOM_VALUE = '__custom__';

const labelRenderer = (option) => {
  const iconClass = getIconByFieldType(option.type);

  return (
    <span>
      <i className={`mr-3 ${iconClass}`} />
      {option.label}
    </span>
  );
};

const groupLabel = options => (
  <strong className="text-dark my-2">
    <em>
      {options.label}
    </em>
  </strong>
);

const InvoiceMappingField = ({
  input, required, options, allowCustom, meta: { touched, error, submitError },
}) => {
  const { change, blur } = useForm();
  const customValueInputName = `${input.name}-custom-value`;
  const customSelectName = `${input.name}-custom-select`;
  const placeholders = options.map(o => o.options.map(i => i.value)).flat();

  const {
    values: { [customValueInputName]: customValue },
    initialValues: { [input.name]: initialValue },
  } = useFormState();

  const isCustomInitialValue = Boolean(initialValue) && !placeholders.includes(initialValue);
  const [isCustomOptionVisible, setIsCustomOptionVisible] = useState(isCustomInitialValue);
  const [isHintsDropdownVisible, setHintsDropdownVisible] = useState(false);

  const opts = [...options];
  if (allowCustom) {
    opts.push({
      label: 'Other',
      options: [{ label: 'Custom', value: CUSTOM_VALUE, type: FIELD_TYPE.CUSTOM }],
    });
  }

  const handleItemsUpdated = (value) => {
    const isCustomValue = value === CUSTOM_VALUE;
    setIsCustomOptionVisible(isCustomValue);
    change(input.name, isCustomValue ? customValue : value);
    blur(input.name);
  };

  const handleCustomInputChanged = (event) => {
    change(input.name, event.target.value);
    blur(input.name);
  };

  const handleHintSelected = (option) => {
    const newValue = `${customValue} ${option.value}`.trim();
    change(customValueInputName, newValue);
    change(input.name, newValue);
    setHintsDropdownVisible(false);
  };

  const validateSelection = (value) => {
    try {
      return validateMappingField(value, placeholders, { required });
    } catch (err) {
      return err.message;
    }
  };

  const classNames = ['form-group mb-0'];
  const hasError = (error || submitError) && touched;

  if (hasError) {
    classNames.push('has-error');
  }

  return (
    <div className={classNames.join(' ')}>
      <CustomSelectField
        name={customSelectName}
        options={opts}
        isMulti={false}
        className="mb-0"
        initialValue={isCustomInitialValue ? CUSTOM_VALUE : initialValue}
        labelRenderer={labelRenderer}
        groupLabelRenderer={groupLabel}
        onItemsUpdated={handleItemsUpdated}
        validateFields={[input.name]}
      />

      {isCustomOptionVisible && (
        <>
          <TextInputField
            className="mt-3 mb-0"
            name={customValueInputName}
            initialValue={isCustomInitialValue ? initialValue : ''}
            onChange={handleCustomInputChanged}
            placeholder="Add the template to use for the field"
            validateFields={[input.name]}
            suffix={(
              <a onClick={() => setHintsDropdownVisible(!isHintsDropdownVisible)}>
                <i className={`${ICON.ADD_CIRCLE} px-1`} />
              </a>
            )}
          />

          {isHintsDropdownVisible && (
            <div className="my-3">
              <Select
                options={options}
                isMulti={false}
                menuIsOpen
                blurInputOnSelect
                components={{ Control: () => null }}
                formatGroupLabel={groupLabel}
                getOptionValue={opt => opt.value}
                getOptionLabel={labelRenderer}
                onBlur={() => setHintsDropdownVisible(false)}
                onChange={handleHintSelected}
              />
            </div>
          )}
        </>
      )}

      <Field
        name={input.name}
        component="input"
        type="hidden"
        initialValue={initialValue}
        validate={validateSelection}
      />

      {hasError && (
        <span className="help-block d-inline-block mt-3">
          {error || submitError}
        </span>
      )}
    </div>
  );
};

InvoiceMappingField.propTypes = {
  input: PropTypes.object.isRequired,
  required: PropTypes.bool,
  allowCustom: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string, type: PropTypes.string }),
  ),
};

InvoiceMappingField.defaultProps = {
  allowCustom: true,
  required: false,
  options: [],
  meta: {
    error: false,
    touched: false,
  },
};

export default withField(InvoiceMappingField);
