import PropTypes from 'prop-types';
import React from 'react';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { withRouter, Link } from 'react-router-dom';

import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { profileSettingsUrl } from 'accounts/urls';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { signDocumentDS } from 'documents/assets/js/data-services/form';
import { validateLegalName } from 'core/assets/js/lib/utils';

const SignDocumentForm = ({
  dispatch,
  initialValues,
  match: {
    params: {
      orgAlias,
      id,
    },
  },
  onCancel,
  onSuccess,
  profile,
  readOnly,
}) => {
  const onSubmit = async (values) => {
    try {
      await dispatch(
        signDocumentDS({ documentAssignmentId: id, values, orgAlias }),
      );

      toastr.success('Well Done!', 'Document confirmed and accepted successfully.');
      await onSuccess();
      return true;
    } catch (err) {
      const errorData = err.errors;

      if (errorData && errorData._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        [FORM_ERROR]: (errorData && errorData._error) || 'Oops! Something went wrong. Please try again',
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="row mt-5">
            <div className="col-12 col-md-6">
              <TextInputField
                disabled={readOnly}
                label="Full legal name"
                name="legalName"
                placeholder="Type your name..."
                required
                type="text"
                popOverContent={
                  !readOnly ? (
                    <>
                      {'Review your profile name '}
                      <Link
                        to={profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.BASIC_INFORMATION)}
                        rel="noopener nofollow"
                        target="_blank"
                      >
                        here
                      </Link>
                      .
                    </>
                  ) : null
                }
                validate={value => validateLegalName(value, profile)}
              />
            </div>

            <div className="col-12 col-md-6">
              <DatePickerField
                disabled
                label="Date"
                name="date"
                required
                type="text"
              />
            </div>
          </div>

          {!readOnly && (
            <div className="buttons-container text-right mt-5">
              {onCancel && (
                <TDButton
                  disabled={submitting}
                  onClick={onCancel}
                >
                  Cancel
                </TDButton>
              )}

              <TDButton
                disabled={submitting}
                type="submit"
                variant={BS_STYLE.PRIMARY}
              >
                Confirm & continue
              </TDButton>
            </div>
          )}
        </form>
      )}
    />
  );
};

SignDocumentForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  profile: profileSpec.isRequired,
  readOnly: PropTypes.bool,
};

SignDocumentForm.defaultProps = {
  onCancel: null,
  readOnly: false,
};

const mapStateToProps = state => ({
  profile: selectProfile(state),
});
const mapDispatchToProps = dispatch => ({ dispatch });

const SignDocumentFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignDocumentForm);

export default withRouter(SignDocumentFormConnected);
