/* eslint react/no-array-index-key: off */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { get, isEmpty, orderBy } from 'lodash';
import { WORKSHEET_STATUS_GROUP } from 'projects/assets/js/constants';
import { projectMemberAnalyticsSpec } from 'projects/assets/js/lib/objectSpecs';
import { CURRENCY_SYMBOL, USER_TYPE, DATE_DURATION, DATE_DURATION_API_KEY, IMG_SIZE } from 'core/assets/js/constants';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import UserTypeIndicator from 'people/assets/js/components/UserTypeIndicator.jsx';
import TDCheckbox from 'core/assets/js/components/TDCheckbox.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import NumberFormat from 'react-number-format';
import DateDurationDropdown from 'core/assets/js/components/DateDurationDropdown.jsx';

const WorksheetsAnalyticsCard = ({ projectMember, analytics, logScale }) => {
  const { avatar, name, userRole } = projectMember;
  const userType = userRole.ofType;
  const percentageKey = logScale ? 'logPercentage' : 'byProjectMemberId';
  const currencySymbol = CURRENCY_SYMBOL[analytics.currency];
  return (
    <div className="project-worksheets-list-item d-flex align-items-center mb-3">
      <ProfilePic
        url={avatar}
        alt={name}
        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
      />
      <UserTypeIndicator
        userType={userType}
      />
      <div className="container project-worksheets-list__meta mx-3 px-0 align-items-center">
        <div className="d-flex justify-content-between align-center">
          <div>
            {name}
          </div>
          <div>
            <NumberTpl
              value={analytics.amount}
              prefix={currencySymbol}
              decimals={2}
              thousandSeparator
            />
          </div>
        </div>
        <div className="project-worksheets-list__line-container mb-1">
          {analytics.byStatusGroup[WORKSHEET_STATUS_GROUP.APPROVED] && (
            <TDElementWithTooltip
              tooltipMsg={(
                <NumberTpl
                  value={analytics.byStatusGroup[WORKSHEET_STATUS_GROUP.APPROVED].amount}
                  prefix={currencySymbol}
                  decimals={2}
                  thousandSeparator
                />
              )}
            >
              <div
                style={{ width: `${analytics.byStatusGroup[WORKSHEET_STATUS_GROUP.APPROVED].percentages[percentageKey]}%` }}
                className="project-worksheets-list__line project-worksheets-list__line--success mr-n1"
              />
            </TDElementWithTooltip>
          )}
          {analytics.byStatusGroup[WORKSHEET_STATUS_GROUP.PENDING] && (
            <TDElementWithTooltip
              tooltipMsg={(
                <NumberTpl
                  value={analytics.byStatusGroup[WORKSHEET_STATUS_GROUP.PENDING].amount}
                  prefix={currencySymbol}
                  decimals={2}
                  thousandSeparator
                />
              )}
            >
              <div
                style={{ flexBasis: `calc(${analytics.byStatusGroup[WORKSHEET_STATUS_GROUP.PENDING].percentages[percentageKey]}% + 6px)` }}
                className="project-worksheets-list__line project-worksheets-list__line--pending"
              />
            </TDElementWithTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

WorksheetsAnalyticsCard.propTypes = {
  analytics: PropTypes.object.isRequired,
  projectMember: projectMemberAnalyticsSpec.isRequired,
  logScale: PropTypes.bool,
};

WorksheetsAnalyticsCard.defaultProps = {
  logScale: false,
};

const WorksheetAnalyticsList = ({ list, projectMembers }) => {
  const ordered = orderBy(list, a => parseFloat(a.amount), ['desc']);
  return (
    <React.Fragment>
      {list.length === 0
        && <span>No worksheets / expenses have been submitted yet</span>
      }
      {list.length > 0 && ordered.map(a => (
        <WorksheetsAnalyticsCard
          key={a.key}
          projectMember={projectMembers[a.key]}
          analytics={a}
        />
      ))}
    </React.Fragment>
  );
};

WorksheetAnalyticsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    analytics: PropTypes.object,
    projectMember: projectMemberAnalyticsSpec,
  })),
  projectMembers: PropTypes.object,
};

WorksheetAnalyticsList.defaultProps = {
  list: [],
  projectMembers: {},
};


const WorksheetAnalyticsPanel = ({ analytics, projectMembers, includeManagers }) => {
  if (isEmpty(analytics)) {
    return null;
  }
  const providersByPmId = get(analytics, `byUserRoleType.${USER_TYPE.PROVIDER}.byProjectMemberId`);
  const managersByPmId = get(analytics, `byUserRoleType.${USER_TYPE.MANAGER}.byProjectMemberId`);
  const financialControllersByPmId = get(analytics, `byUserRoleType.${USER_TYPE.FIN_CONTROLLER}.byProjectMemberId`);
  const providersList = providersByPmId ? Object.values(providersByPmId) : [];
  const managersList = managersByPmId ? Object.values(managersByPmId) : [];
  const financialControllersList = financialControllersByPmId
    ? Object.values(financialControllersByPmId)
    : [];
  const membersList = includeManagers
    ? [...providersList, ...managersList, ...financialControllersList]
    : providersList;
  return (
    <WorksheetAnalyticsList
      list={membersList}
      projectMembers={projectMembers}
    />
  );
};

WorksheetAnalyticsPanel.propTypes = {
  analytics: PropTypes.object,
  projectMembers: PropTypes.object,
  includeManagers: PropTypes.bool,
};

WorksheetAnalyticsPanel.defaultProps = {
  analytics: {},
  projectMembers: {},
  includeManagers: true,
};

const ProjectWorksheetsWidget = (props) => {
  const [selectedDateDuration, setDateDuration] = useState(DATE_DURATION.CURRENT_MONTH);
  const [includeManagers, includeManagersOnList] = useState(true);
  const selectedAnalytics = `${DATE_DURATION_API_KEY[selectedDateDuration]}Analytics`;
  // eslint-disable-next-line react/destructuring-assignment
  const { projectMembers, analytics } = props[selectedAnalytics];
  if (!analytics) {
    return null;
  }
  const currencySymbol = CURRENCY_SYMBOL[analytics.currency];
  const providersPayable = get(analytics, `byUserRoleType.${USER_TYPE.PROVIDER}.amount`) || 0;
  const membersPayable = analytics.amount;
  const hasWorksheets = !!analytics.count;

  return (
    <Card
      className="project-widget__details"
    >
      <Card.Header>
        <div className="d-flex align-items-center">
          <span className="d-inline-block m-0 p-0">Worksheets / Expenses</span>
          <DateDurationDropdown
            selectedDateDuration={selectedDateDuration}
            onDateDurationChanged={date => setDateDuration(date)}
          />
        </div>
      </Card.Header>
      <Card.Body
        className="project-worksheets-list"
      >
        <WorksheetAnalyticsPanel
          analytics={analytics}
          includeManagers={includeManagers}
          projectMembers={projectMembers}
        />
      </Card.Body>
      {hasWorksheets && (
        <Card.Footer>
          <div className="project-worksheets-list__footer row mx-0 ">
            <div
              className="d-flex align-items-center col-12 col-sm-4 pl-0 project-worksheets-list__include-managers-container"
              onClick={() => includeManagersOnList(!includeManagers)}
            >
              <TDCheckbox checked={includeManagers} />
              <span>Include Managers</span>
            </div>
            <div className="col-12 col-sm-8 pr-2 text-right project-worksheets-list__total-container">
              <span>Total Payable:</span>
              {' '}
              <NumberFormat
                prefix={currencySymbol}
                thousandSeparator
                decimals={2}
                displayType="text"
                value={includeManagers ? membersPayable : providersPayable}
              />
            </div>
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};


ProjectWorksheetsWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
  match: props.match,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectWorksheetsWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectWorksheetsWidget);


export default withRouter(ProjectWorksheetsWidgetConnected);
