import React from 'react';
import { logger } from 'core/assets/js/lib/Logger';
import Table, { TableColumn } from 'core/assets/js/components/Table.jsx';


const tableColumns = [
  { key: 'id', label: 'Manager ID' },
  { key: 'date', label: 'Date Joined' },
  { key: 'name', label: 'Full Name' },
  { key: 'email', label: 'Email' },
  { key: 'budget', label: 'Total ($)', isMoney: true, currencySymbol: '$' },
  { key: 'status', label: 'Status' },
];

const tableItems = [
  {
    id: 2,
    date: '15/2/1985',
    name: 'Satoshi Nakamoto',
    email: 'test@example.com',
    budget: '15500',
    status: 'PENDING APPROVAL',
  },
  {
    id: 3,
    date: '17/2/1985',
    name: 'Natoshi Sakamoto',
    email: 'test2@example.com',
    budget: '250500',
    status: 'APPROVED',
  },
  {
    id: 5,
    date: '27/2/1985',
    name: 'Natoshi Nakamoto',
    email: 'test4@example.com',
    budget: '500',
    status: 'REJECTED',
  },
];

function TablesSection() {
  function onRowClick(item) {
    logger.warn('- table row clicked:', item);
  }

  function actionColFormat(cell, row) {
    return (<a href={`/managers/${row.id}`}>View</a>);
  }

  return (
    <section id="tables">
      <h2>Table</h2>
      <div className="bg-white p-4">
        <Table cols={tableColumns} items={tableItems} />

        <div className="mt-5">
          <h3>
            With an extra static column
          </h3>
          <div>
            <Table cols={tableColumns} items={tableItems} onRowClick={onRowClick}>
              {/* Example non-data static column */}
              <TableColumn dataFormat={actionColFormat}>Action</TableColumn>
            </Table>
          </div>
        </div>

        <div className="mt-5">
          <h3>
            Empty itemlist
          </h3>
          <div>
            <Table cols={tableColumns} items={[]} onRowClick={onRowClick}>
              {/* Example non-data static column */}
              <TableColumn dataFormat={actionColFormat}>Action</TableColumn>
            </Table>
          </div>
        </div>

        <div className="mt-5">
          <h3>
            Empty itemlist with custom message
          </h3>

          <div>
            <Table cols={tableColumns} items={[]} onRowClick={onRowClick} emptyText="No managers">
              {/* Example non-data static column */}
              <TableColumn dataFormat={actionColFormat}>Action</TableColumn>
            </Table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TablesSection;
