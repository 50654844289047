import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';
import {
  contactCreateApiUrl,
  contactEditApiUrl,
  contactsDeleteApiUrl,
} from 'contacts/urls';
import { listAppendItemAC, listUpdateItemAC } from 'core/assets/js/ducks/list';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';

const createContactDS = ({ values, componentName, orgAlias }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: contactCreateApiUrl({ orgAlias }),
    pushDataAC: responseData => listAppendItemAC(responseData.contact, componentName),
    values,
  })
);

const editContactDS = ({ values, componentName, orgAlias, contactId }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: contactEditApiUrl({ orgAlias, contactId }),
    pushDataAC: responseData => listUpdateItemAC(
      responseData.contact.id, responseData.contact, componentName,
    ),
    values,
  })
);


const deleteContactsDS = ({ componentName, orgAlias, contactIds }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: contactsDeleteApiUrl({ orgAlias }),
    pushDataAC: () => requestRefreshAC(componentName),
    values: { contactIds },
  })
);

export {
  createContactDS,
  deleteContactsDS,
  editContactDS,
};
