import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { orgPeopleListApiUrl } from 'people/urls';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ManagerCard from 'people/assets/js/components/ManagerCard.jsx';
import { BS_STYLE, USER_CARD_STATUS } from 'core/assets/js/constants';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { fetchListDS, getListState, listResetSelectItemsAC } from 'core/assets/js/ducks/list';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectSelectPaymentsReviewer } from 'projects/assets/js/data-services/form';
import { parseQueryStringParams, stringifyQueryParams } from 'core/assets/js/lib/utils';
import TaskParticipantAvatar from 'core/assets/js/components/DiscussionBoard/TaskParticipantAvatar.jsx';
import { PROJECT_MEMBER_STATUS } from 'projects/assets/js/constants';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';

export const MODAL_ID = 'project-payments-reviewer-modal';

const STEPS = {
  SELECT_MEMBERS: 1,
  CONFIRM_SELECTION: 2,
};

class ProjectPaymentsReviewerModal extends Component {
  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    const apiUrl = orgPeopleListApiUrl(params, url);
    const prerequisites = [
      dispatch(fetchListDS({
        url: apiUrl,
        querystring,
        componentName,
        authedAxios,
      })),
    ];

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'ProjectPaymentsReviewerModal';
  }

  constructor(props) {
    super(props);

    this.props = props;
    this.constantFilters = {
      status: USER_CARD_STATUS.APPROVED,
    };
    this.state = {
      currentStep: STEPS.SELECT_MEMBERS,
      query: { ...this.constantFilters },
      showSelectedItems: false,
    };

    this.handleShowSelectedItemsToggle = this.handleShowSelectedItemsToggle.bind(this);
    this.handleClosePanel = this.handleClosePanel.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const {
      dispatch,
      match: { params: { orgAlias } },
      project,
      selectedItems,
    } = this.props;
    const values = { user: selectedItems.map(i => i.user.id)[0] };
    // Don't use ProjectView.GetComponentName(), as that would result in a circular dependency
    projectSelectPaymentsReviewer(orgAlias, project.id, values, dispatch, 'ProjectView')
      .then(() => {
        this.handleClosePanel();
        this.setState({ currentStep: STEPS.SELECT_MEMBERS });
        dispatch(listResetSelectItemsAC(ProjectPaymentsReviewerModal.GetComponentName()));
      });
  }

  handleClosePanel() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(MODAL_ID));
  }

  handleQueryUpdate(newQuery) {
    const { pagination } = this.props;
    this.setState({ query: { ...this.constantFilters, ...newQuery, page: pagination.page } });
  }

  handlePageChange(newPagination) {
    const { query } = this.state;
    const newQuery = { ...this.constantFilters, ...query, ...newPagination };
    this.setState(
      { query: newQuery },
    );
  }

  handleShowSelectedItemsToggle() {
    const { showSelectedItems } = this.state;
    this.setState({ showSelectedItems: !showSelectedItems });
  }

  render() {
    const {
      activeUserCard,
      filtersOpen,
      isOpen,
      match: { params: { orgAlias } },
      onFiltersToggle,
      people,
      project,
      rateLimits,
      selectedItems,
      userGroupNames,
    } = this.props;
    const { query, showSelectedItems, currentStep } = this.state;

    const selectedItemsLegth = selectedItems.length;

    const userGroupNamesFormatted = userGroupNames.map(ugn => ({
      value: ugn.id.toString(), text: ugn.name,
    }));
    const availabilityFilter = query.availability ? JSON.parse(query.availability) : null;

    return (
      <ModalPanel
        modalId={MODAL_ID}
        open={isOpen}
        heading={<h4>Select new payments reviewer for your project</h4>}
        body={(
          <React.Fragment>
            {/* Member selection Step */}
            <SelectableListWrapper
              cardItem={{
                component: ManagerCard,
                props: {
                  orgAlias,
                  onUpdated: () => {},
                  availabilityFilter,
                  showAvailabilityMessages: true,
                  // Open link in a new tab so that user doesn't lose selected items.
                  linkTarget: '_blank',
                  warningMessage: userCard => {
                    if (userCard.user.id === activeUserCard.user.id) {
                      return [
                        'You do not need to set yourself as a payment reviewer. As a project',
                        'owner, you already have the ability to review worksheets and expenses.',
                        'You can select other managers of the project as payment reviewers.',
                      ].join(' ');
                    }

                    if (userCard.userRole.isFinCon) {
                      return [
                        'You do not need to set a higher manager as a payment reviewer. Higher',
                        'managers already have the permission to review worksheets and expenses.',
                        'You can select other managers of the project as payment reviewers.',
                      ].join(' ');
                    }

                    return null;
                  },
                },
              }}
              className={currentStep === STEPS.CONFIRM_SELECTION ? 'd-none' : null}
              componentName={ProjectPaymentsReviewerModal.GetComponentName()}
              emptyListMessage="No managers found."
              emptySelectedListMessage={(
                <p className="discreet">
                  There are currently no selected managers.
                  <br />
                  <span
                    className="imitate-link"
                    onClick={this.handleShowSelectedItemsToggle}
                  >
                    Find managers
                  </span>
                  .
                </p>
              )}
              fetchData={
                ({ params, querystring, ...rest }) => {
                  const queryParams = parseQueryStringParams(querystring);
                  Object.assign(queryParams, {
                    excludeOrgOwner: true,
                    projectId: project.id,
                    pmStatus: PROJECT_MEMBER_STATUS.ACCEPTED,
                  });
                  if (project.payments_reviewer) {
                    queryParams.exclude = project.payments_reviewer.id;
                  }
                  return ProjectPaymentsReviewerModal.FetchData({
                    ...rest,
                    params: {
                      ...params,
                      peopleType: PEOPLE_TYPE.MANAGERS,
                    },
                    querystring: stringifyQueryParams(queryParams),
                  });
                }
              }
              filtersOpen={filtersOpen}
              items={people.map(p => ({
                ...p,
                disabled: p.userRole.isFinCon || p.user.id === activeUserCard.user.id,
              }))}
              onPageChange={this.handlePageChange}
              query={query}
              searchComponent={(
                <PeopleSearch
                  peopleType={PEOPLE_TYPE.MANAGERS}
                  query={query}
                  rateLimits={rateLimits}
                  onFiltersToggle={onFiltersToggle}
                  filtersOpen={filtersOpen}
                  isManager
                  onFiltersChanged={this.handleQueryUpdate}
                  hideStatus
                  userGroupNames={userGroupNamesFormatted}
                />
              )}
              selectModeEnabled
              selectOne
              showSelectedItems={showSelectedItems}
              skeletonComponent={PeopleListSkeleton}
            />

            {/* Confirmation Step */}
            {currentStep === STEPS.CONFIRM_SELECTION && (
              <>
                <p>
                  You have selected to appoint
                  {` ${selectedItemsLegth} `}
                  payments
                  {` ${pluralize('reviewer', selectedItemsLegth)} `}
                  for your project.
                </p>
                <ul className="d-flex flex-row selected-payments-reviewers">
                  {selectedItems.map(item => (
                    <TaskParticipantAvatar
                      key={item.user.id}
                      isManager={item.userRole.isAnyManager}
                      isOwner={item.userRole.isOrgCreator}
                      user={item.user}
                    />
                  ))}
                </ul>
              </>
            )}
          </React.Fragment>
        )}
        footer={(
          <React.Fragment>
            {currentStep === STEPS.SELECT_MEMBERS && selectedItemsLegth > 0 && (
              <span
                className="cursor-pointer d-flex align-items-center"
                onClick={this.handleShowSelectedItemsToggle}
              >
                <TDSwitch selected={showSelectedItems} />
                <span className="ml-3">
                  Show selected payments reviewer
                </span>
              </span>
            )}

            <div className="ml-auto">
              { currentStep === STEPS.SELECT_MEMBERS && (
                <React.Fragment>
                  <TDButton
                    label="Cancel"
                    onClick={this.handleClosePanel}
                  />

                  <TDButton
                    className="ml-4"
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    disabled={selectedItemsLegth === 0}
                    label="Next"
                    onClick={() => {
                      this.setState({ currentStep: STEPS.CONFIRM_SELECTION });
                    }}
                  />
                </React.Fragment>
              )}

              { currentStep === STEPS.CONFIRM_SELECTION && (
                <React.Fragment>
                  <TDButton
                    label="Back"
                    onClick={() => {
                      this.setState({ currentStep: STEPS.SELECT_MEMBERS });
                    }}
                  />

                  <TDButton
                    className="ml-4"
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    disabled={selectedItemsLegth === 0}
                    label={`Save selection${selectedItemsLegth > 1 ? 's' : ''}`}
                    onClick={() => this.handleSubmit()}
                  />
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}
ProjectPaymentsReviewerModal.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  isOpen: PropTypes.bool,
  match: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  people: PropTypes.array,
  project: projectSpec.isRequired,
  rateLimits: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  userGroupNames: PropTypes.array,
};

ProjectPaymentsReviewerModal.defaultProps = {
  isOpen: false,
  people: [],
  rateLimits: {},
  userGroupNames: [],
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, ProjectPaymentsReviewerModal.GetComponentName());

  return {
    activeUserCard: selectActiveUserCard(state),
    addMembersModalPayload: getModalPayload(state, MODAL_ID),
    isOpen: getIsModalOpen(state, MODAL_ID),
    match: props.match,
    pagination: listState.pagination,
    people: listState.items,
    rateLimits: listState.extras.rateLimits,
    searchActive: listState.search.isActive,
    selectedItems: listState.selectedItems,
    userGroupNames: listState.extras.userGroupNames,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectPaymentsReviewerModalReduxConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectPaymentsReviewerModal);

export default withRouter(withFilters(ProjectPaymentsReviewerModalReduxConnect));
