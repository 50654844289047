import Big from 'big.js';

import { TIMELINE_ITEM_TYPES } from 'core/assets/js/constants';
import { isAmount } from 'core/assets/js/lib/utils';

// All rate fileds are defined as DECIMAL(12, 4), so the max number can be 10^8 - 1
export const MAX_RATE = 1e8 - 1;

export const RATE_STATUS = {
  DRAFT: 1,
  ACTIVE: 2,
  DELETED: 3,
};

export const RATE_STATUS_LABEL = {
  [RATE_STATUS.DRAFT]: 'draft',
  [RATE_STATUS.ACTIVE]: 'active',
  [RATE_STATUS.DELETED]: 'deleted',
};

export const RATE_STATUS_CLASS = {
  [RATE_STATUS.DRAFT]: 'draft',
  [RATE_STATUS.ACTIVE]: 'active',
  [RATE_STATUS.DELETED]: 'deleted',
};

export const RATE_ADJUSTMENT_STATUS = {
  PENDING: 1,
  CANCELLED: 2,
  DECLINED: 3,
  APPROVED: 4,
};

export const RATE_ADJUSTMENT_STATUS_LABEL = {
  [RATE_ADJUSTMENT_STATUS.PENDING]: 'pending',
  [RATE_ADJUSTMENT_STATUS.CANCELLED]: 'cancelled',
  [RATE_ADJUSTMENT_STATUS.DECLINED]: 'declined',
  [RATE_ADJUSTMENT_STATUS.APPROVED]: 'approved',
};

export const RATE_ADJUSTMENT_STATUS_CLASS = {
  [RATE_ADJUSTMENT_STATUS.PENDING]: 'pending',
  [RATE_ADJUSTMENT_STATUS.CANCELLED]: 'cancelled',
  [RATE_ADJUSTMENT_STATUS.DECLINED]: 'declined',
  [RATE_ADJUSTMENT_STATUS.APPROVED]: 'approved',
};

export const RATE_ADJUSTMENT_TIMELINE_ITEM_VARIANT = {
  [RATE_ADJUSTMENT_STATUS.PENDING]: TIMELINE_ITEM_TYPES.PENDING,
  [RATE_ADJUSTMENT_STATUS.CANCELLED]: TIMELINE_ITEM_TYPES.EMPHASIZE,
  [RATE_ADJUSTMENT_STATUS.DECLINED]: TIMELINE_ITEM_TYPES.REJECTED,
  [RATE_ADJUSTMENT_STATUS.APPROVED]: TIMELINE_ITEM_TYPES.APPROVED,
};

export const RATE_UNIT = {
  PER_DAY: 1,
  PER_HOUR: 2,
  PER_WORD: 3,
  PER_CALL: 4,
  PER_PAGE: 5,
  PER_MONTH: 6,
  FIXED: 10,
  COMMISSION: 20,
  CUSTOM: 30,
};

export const RATE_UNIT_VALUES = Object.values(RATE_UNIT);

export const RATE_UNIT_TO_STRING = {
  [RATE_UNIT.PER_DAY]: 'daily',
  [RATE_UNIT.PER_HOUR]: 'hourly',
  [RATE_UNIT.PER_MONTH]: 'monthly',
  [RATE_UNIT.PER_WORD]: 'per-word',
  [RATE_UNIT.PER_CALL]: 'per-call',
  [RATE_UNIT.PER_PAGE]: 'per-page',
  [RATE_UNIT.FIXED]: 'fixed',
  [RATE_UNIT.COMMISSION]: 'commission',
  [RATE_UNIT.CUSTOM]: 'custom',
};

export const RATE_UNIT_FROM_STRING = Object.entries(RATE_UNIT_TO_STRING).reduce(
  (acc, [key, string]) => {
    acc[string] = parseInt(key, 10);
    return acc;
  },
  {},
);

const prepareRateUnitLimit = ({ min, max, step }) => ({
  min: min || 0.01,
  max: max || Number.MAX_SAFE_INTEGER,
  step: step || 0.01,
});

export const RATE_UNIT_LIMIT = {
  [RATE_UNIT.PER_DAY]: prepareRateUnitLimit({ min: 1, step: 0.01 }),
  [RATE_UNIT.PER_HOUR]: prepareRateUnitLimit({ min: 1, step: 0.01 }),
  [RATE_UNIT.PER_MONTH]: prepareRateUnitLimit({ min: 1, step: 0.01 }),
  [RATE_UNIT.PER_WORD]: prepareRateUnitLimit({ min: 0.0001, step: 0.0001 }),
  [RATE_UNIT.PER_CALL]: prepareRateUnitLimit({ min: 1, step: 0.01 }),
  [RATE_UNIT.FIXED]: prepareRateUnitLimit({ min: 1, step: 0.01 }),
  [RATE_UNIT.CUSTOM]: prepareRateUnitLimit({ min: 0.1, step: 0.01 }),
  [RATE_UNIT.COMMISSION]: prepareRateUnitLimit({ min: 0.01, max: 1, step: 0.01 }),
  [RATE_UNIT.PER_PAGE]: prepareRateUnitLimit({ min: 1, step: 1 }),
};

const _defaultRateFormat = (rateAmount, { decimals = 2 } = {}) => {
  if (!isAmount(rateAmount)) {
    return '';
  }

  return (
    rateAmount instanceof Big
      ? rateAmount
      : new Big(rateAmount)
  ).toFixed(decimals);
};

export const RATE_UNIT_FORMAT = {
  [RATE_UNIT.PER_DAY]: {
    decimals: 2,
    description: 'daily rate',
    fieldHelpText: 'e.g 1000',
    title: 'Daily rate',
    suffix: 'per day',
    abbreviation: '/day',
    unit: 'days',
    unitTitle: 'Days',
    verb: 'work',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.PER_DAY],
    question: 'How many days would you like to submit a worksheet for?',
    validationMsg: 'Enter the number of days you would like to submit a worksheet for',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 2 }),
    validate: () => undefined,
  },
  [RATE_UNIT.PER_HOUR]: {
    decimals: 2,
    description: 'hourly rate',
    fieldHelpText: 'e.g 50',
    title: 'Hourly rate',
    suffix: 'per hour',
    abbreviation: '/hr',
    unit: 'hours',
    unitTitle: 'Hours',
    verb: 'work',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.PER_HOUR],
    question: 'How many hours would you like to submit a worksheet for?',
    validationMsg: 'Enter the number of hours you would like to submit a worksheet for',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 2 }),
    validate: () => undefined,
  },
  [RATE_UNIT.PER_MONTH]: {
    abbreviation: '/month',
    description: 'monthly rate',
    decimals: 2,
    fieldHelpText: 'e.g 50',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 2 }),
    limit: RATE_UNIT_LIMIT[RATE_UNIT.PER_MONTH],
    question: 'How many months would you like to submit a worksheet for?',
    suffix: 'per month',
    title: 'Monthly rate',
    unit: 'months',
    unitTitle: 'Months',
    validate: () => undefined,
    validationMsg: 'Enter the number of months you would like to submit a worksheet for',
    verb: 'work',
  },
  [RATE_UNIT.PER_WORD]: {
    decimals: 4,
    description: 'per word rate',
    fieldHelpText: 'e.g 0.05',
    title: 'Per word rate',
    suffix: 'per word',
    abbreviation: '/wd',
    unit: 'words',
    unitTitle: 'Words',
    verb: 'write',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.PER_WORD],
    question: 'How many words would you like to submit a worksheet for?',
    validationMsg: 'Enter the number of words you would like to submit a worksheet for',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 4 }),
    validate: () => undefined,
  },
  [RATE_UNIT.PER_CALL]: {
    decimals: 2,
    description: 'per call rate',
    fieldHelpText: 'e.g 50',
    title: 'Per call rate',
    suffix: 'per call',
    abbreviation: '/call',
    unit: 'calls',
    unitTitle: 'Calls',
    verb: 'take',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.PER_WORD],
    question: 'How many calls would you like to submit a worksheet for?',
    validationMsg: 'Enter the number of calls you would like to submit a worksheet for',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 2 }),
    validate: () => undefined,
  },
  [RATE_UNIT.FIXED]: {
    decimals: 2,
    description: 'fixed rate',
    fieldHelpText: 'e.g 500',
    title: 'Fixed rate',
    suffix: 'fixed',
    abbreviation: '',
    unit: 'fixed',
    unitTitle: 'Value',
    verb: 'work',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.FIXED],
    question: 'How much are you looking to claim?',
    validationMsg: 'Enter the amount you are looking to claim',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 2 }),
    validate: () => undefined,
  },
  [RATE_UNIT.CUSTOM]: {
    decimals: 2,
    description: 'custom rate',
    fieldHelpText: 'e.g 5',
    title: 'Custom rate',
    suffix: 'custom',
    abbreviation: '',
    unit: 'custom',
    unitTitle: 'Value',
    verb: 'work',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.CUSTOM],
    question: '',
    validationMsg: 'This field is mandatory',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 2 }),
    validate: () => undefined,
  },
  [RATE_UNIT.COMMISSION]: {
    decimals: 2,
    description: 'commission rate',
    fieldHelpText: 'Enter as decimal e.g 0.50',
    title: 'Commission rate',
    suffix: 'comm',
    abbreviation: 'comm',
    unit: 'commission',
    unitTitle: 'Value',
    verb: 'work',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.COMMISSION],
    question: 'What\'s the total value of the job you carried out?',
    validationMsg: 'Enter the total value of the job you carried out',
    format: rateAmount => _defaultRateFormat(Big(rateAmount).times(100), { decimals: 2 }),
    validate: (formValue) => {
      const errMsg = 'Enter as decimal e.g 0.50';

      if (!formValue) {
        return errMsg;
      }
      const { min, max } = RATE_UNIT_LIMIT[RATE_UNIT.COMMISSION];

      const value = new Big(formValue);
      if (value.lt(min) || value.gt(max)) {
        return errMsg;
      }

      return undefined;
    },
  },
  [RATE_UNIT.PER_PAGE]: {
    decimals: 2,
    description: 'per page rate',
    fieldHelpText: 'e.g 5',
    title: 'Per page rate',
    suffix: 'per page',
    abbreviation: '/page',
    unit: 'pages',
    unitTitle: 'Pages',
    verb: 'work',
    limit: RATE_UNIT_LIMIT[RATE_UNIT.PER_PAGE],
    question: 'How many pages would you like to submit a worksheet for?',
    validationMsg: 'Enter the number of pages you would like to submit a worksheet for',
    format: rateAmount => _defaultRateFormat(rateAmount, { decimals: 2 }),
    validate: () => undefined,
  },
};

const _prepareRateUnitSelect = ({ labelKey = 'text', filtered = false } = {}) => {
  let values = Object.values(RATE_UNIT);

  if (filtered) {
    // Do not show Custom Rate option
    values = values.filter((r) => {
      return r !== RATE_UNIT.CUSTOM;
    });
  }
  return values.map(rateUnit => ({
    value: rateUnit,
    [labelKey]: RATE_UNIT_FORMAT[rateUnit].title,
  }));
};

export const RATE_UNIT_SELECT = _prepareRateUnitSelect();
export const RATE_UNIT_SELECT_FILTERED = _prepareRateUnitSelect({ filtered: true });

// Feeds the custom select field component - relies on react-select
export const RATE_UNIT_CUSTOM_SELECT = _prepareRateUnitSelect({ labelKey: 'label' });
export const RATE_UNIT_CUSTOM_SELECT_FILTERED = _prepareRateUnitSelect({ labelKey: 'label', filtered: true });

export const RATE_SUGGEST_VALUE = -1;

export const RATE_CANNOT_DELETE_REASON = {
  HAS_PENDING_RATE_ADJUSTMENT: 'has-pending-rate-adjustment',
  IS_DEFAULT: 'is-default',
  NO_OTHER_ACTIVE_RATES: 'no-other-active-rates',
  NOT_OWNER_OR_WITH_PERMISSION: 'not-owner-or-with-permission',
};

export const RATE_CANNOT_DELETE_REASON_LABEL = {
  [RATE_CANNOT_DELETE_REASON.HAS_PENDING_RATE_ADJUSTMENT]: 'This rate has a pending adjustment and there are no active rates',
  [RATE_CANNOT_DELETE_REASON.IS_DEFAULT]: 'This is the default rate',
  [RATE_CANNOT_DELETE_REASON.NO_OTHER_ACTIVE_RATES]: 'This is the only active rate',
  [RATE_CANNOT_DELETE_REASON.NOT_OWNER_OR_WITH_PERMISSION]: 'You do not have permission',
};

export const RATE_ADJUSTMENT_SUBMIT_TYPE = {
  APPROVE: 'approve',
  CANCEL: 'cancel',
  INITIATE: 'initiate',
  REJECT: 'reject',
};

export const RATE_ADJUSTMENT_INITIATOR_MESSAGE_MAX_LENGTH = 255;

export const RATE_ADJUSTMENT_MODAL_ID = 'rate-adjustment-modal';
