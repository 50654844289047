import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { BILLING_TABS } from 'finance/assets/js/constants';
import BillingTabOverview from 'finance/assets/js/components/BillingTabOverview.jsx';
import BillingTabHistory from 'finance/assets/js/components/BillingTabHistory.jsx';
import BillingTabSubscription from 'finance/assets/js/components/BillingTabSubscription.jsx';
import { billingViewUrl } from 'finance/urls';

const BillingTab = ({ tab, orgAlias, history }) => {
  switch (tab) {
    case BILLING_TABS.OVERVIEW:
      return (
        <BillingTabOverview />
      );
    case BILLING_TABS.BILLING_HISTORY:
      return (
        <BillingTabHistory />
      );
    case BILLING_TABS.SUBSCRIPTION:
      return (
        <BillingTabSubscription />
      );
    default:
      history.push(
        billingViewUrl(orgAlias, BILLING_TABS.OVERVIEW),
      );
      return null;
  }
};

BillingTab.propTypes = {
  history: routerHistorySpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default withRouter(BillingTab);
