import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import ReviewsSkeleton from 'core/assets/js/components/Skeleton/ReviewsSkeleton.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import TDRating from 'core/assets/js/components/TDRating.jsx';
import Timeline from 'core/assets/js/components/Timeline.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { IMG_SIZE, TIMELINE_ITEM_TYPES } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { Card } from 'react-bootstrap';
import { discoverTalentReviewApiUrl } from 'search/urls';

const TalentReviewsCard = (props) => {
  const getTimeLineItems = (reviews) =>
    (reviews?.length
      ? reviews.map((review) => {
        const date = moment(review.timeStamp);
        return {
          id: review.id,
          variant: TIMELINE_ITEM_TYPES.DEFAULT,
          content: (
            <>
              <div className="d-flex w-100 justify-content-between">
                <p className="discreet">{date.format('Do-MMM-YYYY')}</p>
                <TDRating initialRating={review.sender.rating} readonly />
              </div>
              <div className="d-flex">
                <ProfilePic
                  size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  url={review.sender.avatar}
                />
                <div className="ml-3">
                  <strong>{review.sender.name}</strong>
                  <span className="discreet">
                    {` • ${review.sender.address.label}`}
                  </span>
                  <br />
                  {review.comments}
                </div>
              </div>
            </>
          ),
        };
      })
      : []);

  const { pagination, reviews } = props;
  return (
    <Card className="mt-4">
      <Card.Header>Reviews</Card.Header>
      <Card.Body>
        <Timeline scrollToBottom={false} items={getTimeLineItems(reviews)} />
        <TDPagination {...pagination} />
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const listState = getListState(state, TalentReviewsCard.GetComponentName());
  return {
    pagination: listState.pagination,
    reviews: listState.items,
  };
};

TalentReviewsCard.propTypes = {
  pagination: paginationSpec.isRequired,
  reviews: PropTypes.array,
};

TalentReviewsCard.defaultProps = {
  reviews: [],
};

TalentReviewsCard.GetComponentName = () => 'TalentReviewsCard';

const TalentReviewsCardConnected = connect(mapStateToProps)(TalentReviewsCard);

const TalentReviewsCardApiConnected = withTDApiConnected({
  fetchData: ({
    authedAxios,
    componentName,
    dispatch,
    params,
    querystring,
    url,
  }) => {
    return dispatch(
      fetchListDS({
        authedAxios,
        componentName,
        querystring,
        url: discoverTalentReviewApiUrl(params, url),
      }),
    );
  },
  duck: 'list',
  storeKey: TalentReviewsCard.GetComponentName(),
  skeletonComponent: ReviewsSkeleton,
})(TalentReviewsCardConnected);

export default withRouter(withFilters(TalentReviewsCardApiConnected));
