import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { resendVerificationDS } from 'accounts/assets/js/data-services/account';
import { BS_STYLE } from 'core/assets/js/constants';


const ModalResendVerification = ({
  open, onClose, handleSubmit, submitting, dispatch,
}) => {
  const onSubmit = async (values) => {
    await dispatch(resendVerificationDS(values));
    onClose();
  };

  const body = (
    <React.Fragment>
      <p>
        Please make sure you have verified your email address before you can log in.
        Check your inbox  and click on the verification link of the TalentDesk.io
        account verification email you just received.
      </p>

      <p>
        If you can&apos; find the relevant email,
        enter your email address below or
        {' '}
        <a href="mailto:support@talentdesk.io">contact us</a>
        .
      </p>

      <Field name="email" type="text" component={InputField} label="Email" placeholder="" />
      <Field name="captcha" type="captcha" component={InputField} />
    </React.Fragment>
  );

  const footer = (
    <TDButton
      floatRight
      className="col-6 float-right"
      type="submit"
      variant={BS_STYLE.SUCCESS}
      disabled={submitting}
      onClick={handleSubmit(onSubmit)}
      label="Update"
    />
  );

  return (
    <ModalSimple
      open={open}
      onClose={onClose}
      heading="Having troubles verifying your account?"
      body={body}
      footer={footer}
    />
  );
};

ModalResendVerification.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const ModalResendVerificationRedux = reduxForm({
  form: 'ModalResendVerification',
})(ModalResendVerification);


const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  null,
  mapDispatchToProps,
)(ModalResendVerificationRedux);
