/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import NavBarSubItem from 'core/assets/js/components/NavBar/NavBarSubItem.jsx';
/**
 * Navbar menu item
 */
const NavBarItem = ({
  count,
  darkHighlightWhenActive,
  history,
  icon,
  isSectionActive,
  items,
  label,
  onClick,
  path,
  suffix,
  tabIndex,
}) => {
  /* In case of custom icon, it expects an object
   * with two properties (active, inactive) of type string.
   */
  const useCustomIcon = typeof icon === 'object';
  let iconEl = null;

  if (useCustomIcon) {
    iconEl = (
      <React.Fragment>
        <img className="navbar__icon--active" src={icon.active} alt={label} />
        <img className="navbar__icon--inactive" src={`${icon.inactive}`} alt={label} />
      </React.Fragment>
    );
  } else {
    iconEl = <i className={icon} />;
  }

  const sectionClassNames = ['navbar__menuItem'];
  if (isSectionActive) {
    sectionClassNames.push('navbar__menuItem--state-active');
    if (darkHighlightWhenActive || !items?.length) {
      sectionClassNames.push('navbar__menuItem--state-active-dark');
    }
  }
  const sectionLinkClassNames = ['navbar__sectionItem'];

  return (
    <li
      className={sectionClassNames.join(' ')}
      data-testid={`nav-bar-item-${kebabCase(label)}`}
    >
      <NavLink
        activeClassName="navbar__menuItem--state-active"
        className={sectionLinkClassNames.join(' ')}
        isActive={() => isSectionActive}
        onClick={() => {
          onClick();
          history.push(path);
        }}
        tabIndex={tabIndex}
        to={path}
      >
        {iconEl}
        {label}
        {suffix}

        { count > 0 && (
          <span className="badge float-right">{count}</span>
        )}
      </NavLink>

      { items && (
        <ul data-testid="teams-sub-menu-item" className="navbar__submenu">
          { items.map((item, index) => {
            if (!item) {
              return null;
            }
            return (
              <NavBarSubItem
                key={`submenu-item-${item.label}`}
                tabIndex={index}
                {...item}
              />
            );
          })
          }
        </ul>
      )}
    </li>
  );
};
NavBarItem.propTypes = {
  count: PropTypes.number,
  darkHighlightWhenActive: PropTypes.bool,
  history: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  isSectionActive: PropTypes.bool,
  isSectionOpen: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string.isRequired,
  suffix: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
};
NavBarItem.defaultProps = {
  count: 0,
  darkHighlightWhenActive: false,
  isSectionActive: false,
  isSectionOpen: false,
  items: null,
  onClick: () => null,
  suffix: null,
};

export default withRouter(NavBarItem);
