import React from 'react';
import { Card } from 'react-bootstrap';

import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';

const ReviewsSkeleton = () => (
  <Card className="mt-4">
    <Card.Header>Reviews</Card.Header>
    <div className="px-5">
      <PeopleListSkeleton />
    </div>
  </Card>
);

export default ReviewsSkeleton;
