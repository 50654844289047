import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';

import ChatRoomsListItemPic from 'people/assets/js/components/chat/ChatRoomsListItemPic.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { PARTICIPANTS_LIST_MODAL_ID } from 'people/assets/js/constants';

const ChatRoomTitle = ({ chat }) => {
  const dispatch = useDispatch();
  const activeUserCard = useSelector(state => selectActiveUserCard(state));
  const isTalentMarket = chat?.chatRoomUsers?.some(cu => !!cu.isTalentMarket);

  return (
    <h4 className="truncate">
      <ChatRoomsListItemPic chat={chat} showTalentMarketIndicator={false} />

      <span className="d-inline-block align-items-center">
        {chat?.title
        || chat?.chatRoomUsers
          ?.filter(cu => cu.userId !== activeUserCard.user.id)
          .map((c) => c.name)
          .join(', ')}

        {isTalentMarket && (
          <span
            className="talent-market-indicator ml-3"
          >
            TalentMarket
          </span>
        )}
      </span>

      {chat?.chatRoomUsers?.length > 2 && (
        <>
          <span
            className="ml-5 font-smaller font-weight-light cursor-pointer imitate-link d-inline d-md-none"
            onClick={() => dispatch(modalOpenAC(PARTICIPANTS_LIST_MODAL_ID))}
          >
            {pluralize(
              'participant',
              chat.chatRoomUsers.length,
              true,
            )}
          </span>
          <span className="discreet ml-5 font-smaller font-weight-light d-none d-md-inline">
            {pluralize(
              'participant',
              chat.chatRoomUsers.length,
              true,
            )}
          </span>
        </>
      )}
    </h4>
  );
};

ChatRoomTitle.propTypes = {
  chat: PropTypes.object.isRequired,
};

export default ChatRoomTitle;
