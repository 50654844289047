import React from 'react';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

const InformPublicDiscussionBlocked = () => (
  <div className="discussion-board__row">
    <TDSystemMessage
      title="Public discussion is not available"
      className=""
    >
      You can’t access the public discussion board at this point.
      <br />
      However, you will be able to contact the task owner separately, in a private conversation
    </TDSystemMessage>
  </div>
);

export default InformPublicDiscussionBlocked;
