import React from 'react';

import FilePreviewModal from 'core/assets/js/components/FilePreviewModal.jsx';

const RootModals = () => (
  <>
    <FilePreviewModal />
  </>
);

export default RootModals;
