import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';

import TDCheckbox, { CHECKBOX_SIZES } from 'core/assets/js/components/TDCheckbox.jsx';
import TDRadio from 'core/assets/js/components/TDRadio.jsx';
import {
  getListState,
  listAddSelectedItemsAC,
  listRemoveSelectedItemsAC,
  listSelectOnlyItemAC,
} from 'core/assets/js/ducks/list';

/**
 * Wraps a component (usually a Card) and handles the selection actions.
 */
const SelectableItem = ({
  cardItem, itemData, isSelectionModeEnabled, listName, selectedItems, dispatch, selectOne,
}) => {
  const isSelected = () => {
    const _found = findIndex(selectedItems, (item) => {
      return item.id === itemData.id;
    });
    return _found > -1;
  };

  const onToggleSelect = () => {
    if (!isSelectionModeEnabled || itemData.disabled) {
      return;
    }

    if (!isSelected()) {
      if (selectOne) {
        dispatch(listSelectOnlyItemAC(itemData, listName));
      } else {
        dispatch(listAddSelectedItemsAC(itemData, listName));
      }
    } else {
      dispatch(listRemoveSelectedItemsAC(itemData.id, listName));
    }
  };

  const classNames = ['flex-nowrap selectable-item'];
  if (isSelected()) {
    classNames.push('selectable-item--selected');
  }
  if (itemData.disabled) {
    classNames.push('selectable-item--disabled');
  }

  return (
    <div
      className={classNames.join(' ')}
      onClick={onToggleSelect}
    >
      <div className="d-flex position-relative">
        <div className="col px-0 selectable-item__card-wrapper">
          <cardItem.component item={itemData} {...cardItem.props} />
        </div>

        {isSelectionModeEnabled && (
          <div data-testid="selectable-item-checkbox" className="checkbox-area ml-auto">
            {selectOne && <TDRadio disabled={itemData.disabled} selected={isSelected()} />}
            {!selectOne && (
              <TDCheckbox
                checked={isSelected()}
                disabled={itemData.disabled}
                size={CHECKBOX_SIZES.LARGER}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
SelectableItem.propTypes = {
  cardItem: PropTypes.object.isRequired,
  itemData: PropTypes.object.isRequired,
  listName: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  isSelectionModeEnabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectOne: PropTypes.bool,
};

SelectableItem.defaultProps = {
  selectOne: false,
};

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = (state, props) => {
  const listState = getListState(state, props.listName);
  return {
    selectedItems: listState.selectedItems,
    isSelectionModeEnabled: listState.isSelectionModeEnabled,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectableItem);
