import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import SkillCreatableSelectField from 'core/assets/js/components/FinalFormFields/SkillCreatableSelectField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { skillDirectoryOptionsApiUrl } from 'settings/urls';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';

export const ORG_SKILL_MODAL_ID = 'org-skill-modal';

const OrgSkillModalForm = ({ onSubmit, modalPayload, item: skillDirectories  }) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => getIsModalOpen(state, ORG_SKILL_MODAL_ID));

  const handleModalClose = () => {
    dispatch(modalCloseAC());
  };

  return (
    <div className="static-modal">
      <Form
        onSubmit={onSubmit}
        render={({ values: { skill }, handleSubmit, submitting }) => (
          <ModalSimple
            noFooter
            open={isModalOpen}
            size={BS_SIZE.LARGE}
            onClose={handleModalClose}
            heading={modalPayload ? 'Rename Skill' : 'Create skills'}
            body={(
              <form onSubmit={handleSubmit}>
                <div style={{ minHeight: '250px' }}>
                  <SkillCreatableSelectField
                    defaultSkillsEnabled
                    isOptionDisabled={option => typeof option.value === 'number'}
                    label="Custom skills"
                    name="skill"
                    required
                    sublabel={`
                      Enter a skill name. If no matches are found, you can click "Add" to create
                      it as a skill in this organization.
                    `}
                  />

                  <CustomSelectField
                    name="skillDirectoryId"
                    label="Skill directory (optional)"
                    sublabel="Add all created skills to skills directory"
                    options={skillDirectories.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                </div>

                <div className="button-container mt-4 d-flex justify-content-end border-top mt-5 pt-5">
                  <TDButton
                    label="Cancel"
                    variant={BS_STYLE.DEFAULT}
                    disabled={submitting}
                    onClick={handleModalClose}
                  />

                  <TDButton
                    type="submit"
                    label={modalPayload ? 'Save' : 'Create'}
                    variant={BS_STYLE.PRIMARY}
                    disabled={submitting || !skill}
                  />
                </div>
              </form>
            )}
          />
        )}
      />
    </div>
  );
};

OrgSkillModalForm.propTypes = {
  skill: skillSpec,
  open: PropTypes.bool,
  modalPayload: PropTypes.object,
  item: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};

OrgSkillModalForm.defaultProps = {
  modalPayload: null,
  item: null,
  open: false,
  skill: null,
};

OrgSkillModalForm.GetComponentName = () => 'OrgSkillModalForm';

const OrgSkillModalFormApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, componentName, querystring, params,
  }) => {
    const { orgAlias } = params;
    return dispatch(fetchViewDS({
      authedAxios, componentName, querystring,
      url: skillDirectoryOptionsApiUrl(orgAlias),
    }));
  },
  duck: 'view',
  storeKey: OrgSkillModalForm.GetComponentName(),
})(OrgSkillModalForm);

export default OrgSkillModalFormApiConnected;
