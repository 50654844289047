import { WINDOW_HUBSPOT_CONVERSATIONS_OPEN } from 'core/assets/js/config/settings';

/**
 * Utility functions for HubSpot Chat
 */
class HSChat {
  /**
   * Open the HubSpot Chat widget ( chat window ).
   */
  static openHSChat() {
    WINDOW_HUBSPOT_CONVERSATIONS_OPEN();
  }
}

export default HSChat;
