/* eslint react/no-multi-comp: "off", jsx-a11y/label-has-associated-control: "off", jsx-a11y/label-has-for: "off" */ // eslint-disable-line
import PropTypes from 'prop-types';
import React from 'react';

import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import { COMPANY_TAX_SYSTEM } from 'settings/assets/js/constants';
import ParseBool from 'core/assets/js/components/ParseBool.jsx';

const yesNoOptions = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const getSalesTaxDetailsRows = (params) => {
  const detailsRows = [];
  const { vat, gst, hst } = params;
  if (vat) {
    detailsRows.push({
      label: 'VAT',
      value: <ParseBool>{vat}</ParseBool>,
    });
  }
  if (gst) {
    detailsRows.push({
      label: 'GST',
      value: <ParseBool>{gst}</ParseBool>,
    });
  }
  if (hst) {
    detailsRows.push({
      label: 'HST',
      value: <ParseBool>{hst}</ParseBool>,
    });
  }
  return detailsRows;
};

export const SalesTaxSwitch = ({ submitting }) => (
  <div className="d-flex flex-column flex-sm-row">
    <div className="settings-row__content mb-3">
      <label>Are you VAT, GST or HST registered?</label>
      <span className="d-block discreet">
        If there is no VAT, GST or HST system in your country or you offer your
        services as an individual, please select &quot;No&quot;.
      </span>
    </div>

    <div className="settings-row__cta text-right">
      <RadioField
        className="float-md-right"
        disabled={submitting}
        name="has_tax_system"
        options={yesNoOptions}
        showInline
      />
    </div>
  </div>
);

SalesTaxSwitch.propTypes = {
  submitting: PropTypes.bool,
};

SalesTaxSwitch.defaultProps = {
  submitting: false,
};

export const SalesTaxPlainFields = ({ vat, gst, hst }) => (
  <>
    { vat && (
      <React.Fragment>
        <span className="vat">{`VAT ${vat}`}</span>
        <br />
      </React.Fragment>
    )}
    { gst && (
      <React.Fragment>
        <span className="vat">{`GST ${gst}`}</span>
        <br />
      </React.Fragment>
    )}
    { hst && (
      <React.Fragment>
        <span className="vat">{`HST ${hst}`}</span>
        <br />
      </React.Fragment>
    )}
  </>
);

SalesTaxPlainFields.propTypes = {
  vat: PropTypes.string,
  gst: PropTypes.string,
  hst: PropTypes.string,
};

SalesTaxPlainFields.defaultProps = {
  vat: '',
  gst: '',
  hst: '',
};

const SalesTaxFields = ({
  showTaxSystemSelect,
  showVatField,
  showGstField,
  showHstField,
  submitting,
}) => showTaxSystemSelect && (
  <div className="row">
    <div className="col-12 col-sm-6">
      <SelectField
        disabled={submitting}
        defaultOptionText="Select"
        label="Tax identification system"
        name="tax_system_type"
        optionsMapping={[
          { text: 'VAT - Value added tax', value: COMPANY_TAX_SYSTEM.VAT },
          { text: 'GST - Goods and sales tax', value: COMPANY_TAX_SYSTEM.GST },
          { text: 'HST - Harmonized sales tax', value: COMPANY_TAX_SYSTEM.HST },
        ]}
      />
    </div>
    {showVatField && (
      <div className="col-12 col-sm-6">
        <TextInputField
          disabled={submitting}
          extraInfo={(
            'A value added tax identification number or VAT identification number is'
            + ' an identifier used in many countries, including the countries of the'
            + ' European Union, for value added tax purposes.'
          )}
          label="VAT identification number"
          name="vat"
          required
        />
      </div>
    )}
    {showGstField && (
      <div className="col-12 col-sm-6">
        <TextInputField
          disabled={submitting}
          extraInfo={(
            'A goods and services tax identification number or GST identification'
            + ' number is a unique 15-digit number under GST registration that every'
            + ' business needs to obtain to collect tax and avail input credit.'
          )}
          label="GST identification number"
          name="gst"
          required
        />
      </div>
    )}
    {showHstField && (
      <div className="col-12 col-sm-6">
        <TextInputField
          disabled={submitting}
          extraInfo={(
            'A harmonized sales tax identification number or HST identification'
            + ' number is a unique 9-digit number under HST registration that every'
            + ' business needs to obtain to collect tax and avail input credit.'
          )}
          label="HST identification number"
          name="hst"
          required
        />
      </div>
    )}
  </div>
);

SalesTaxFields.propTypes = {
  showTaxSystemSelect: PropTypes.bool,
  showGstField: PropTypes.bool,
  showHstField: PropTypes.bool,
  showVatField: PropTypes.bool,
  submitting: PropTypes.bool,
};

SalesTaxFields.defaultProps = {
  showTaxSystemSelect: false,
  showGstField: false,
  showHstField: false,
  showVatField: false,
  submitting: false,
};

export default SalesTaxFields;
