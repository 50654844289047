import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { projectListUrl } from 'projects/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectLeaveDS } from 'projects/assets/js/data-services/form';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';

class LeaveProjectConfirmModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submissionFailed: false,
      submissionError: null,
    };

    this.handleLeavingConfirmation = this.handleLeavingConfirmation.bind(this);
    this.handleLeavingModalClose = this.handleLeavingModalClose.bind(this);
  }

  handleLeavingConfirmation() {
    const {
      dispatch,
      history,
      match: { params: { orgAlias, id: projectId } },
    } = this.props;

    return new Promise((resolve, reject) => (
      projectLeaveDS({ orgAlias, projectId }).then(() => {
        dispatch(modalCloseAC());
        toastr.success('You have left the project');
        history.push(projectListUrl(orgAlias));
        resolve();
      }).catch((err) => {
        const error = err.response.data._error;
        this.setState({
          submissionFailed: true,
          submissionError: error,
        });

        reject(error);
      })
    ));
  }

  handleLeavingModalClose() {
    const { dispatch, modalId } = this.props;

    dispatch(modalCloseAC(modalId));

    this.setState({
      submissionFailed: false,
      submissionError: null,
    });
  }

  render() {
    const { isModalOpen } = this.props;

    const { submissionFailed, submissionError } = this.state;
    const heading = 'Leave the Project';

    if (submissionFailed) {
      return (
        <ModalSimple
          heading={heading}
          open={isModalOpen}
          body={(
            <p>
              {submissionError}
            </p>
          )}
          onClose={this.handleLeavingModalClose}
        />
      );
    }

    return (
      <ModalConfirm
        open={isModalOpen}
        heading={heading}
        body={(
          <React.Fragment>
            <p>
              By leaving the project, you will not be able to re-join unless you’re invited again.
            </p>
            <p>
              Additionally, you will not be able to create any Worksheets or Expenses
              for the project, so please make sure you have everything in place.
            </p>
            <p>
              Are you sure you want to leave the project?
            </p>
          </React.Fragment>
        )}
        closeOnConfirm={false}
        confirmStyle={BS_STYLE.DANGER}
        onClose={this.handleLeavingModalClose}
        onConfirm={this.handleLeavingConfirmation}
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
    );
  }
}

LeaveProjectConfirmModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  match: routerMatchContentsSpec.isRequired,
  history: routerHistorySpec.isRequired,
};

LeaveProjectConfirmModal.defaultProps = {
  isModalOpen: false,
};

const mapStateToProps = (state, props) => ({
  isModalOpen: getIsModalOpen(state, props.modalId),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const LeaveProjectConfirmModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaveProjectConfirmModal);

export default withRouter(LeaveProjectConfirmModalConnected);
