import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { omit } from 'lodash';
import { FORM_ERROR } from 'final-form';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { PROJECT_TABS, TASK_TABS } from 'core/assets/js/constants';
import { TASK_ACTION } from 'projects/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { getViewState } from 'core/assets/js/ducks/view';
import { fetchProjectActiveDS } from 'projects/assets/js/data-services/view';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { getCustomFieldTemplates, fetchCustomFieldTemplatesDS, resetCustomFieldsAC } from 'interviews/assets/js/ducks/customFields';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectCreateTaskDS } from 'projects/assets/js/data-services/tasks';
import { projectViewUrl, projectViewTaskUrl, projectListUrl } from 'projects/urls';
import { extractCustomFieldSetup } from 'interviews/assets/js/lib/utils';
import ProjectTaskForm from 'projects/assets/js/components/ProjectTaskForm.jsx';

class ProjectCreateTaskView extends React.Component {
  static FetchData({ dispatch, params: { orgAlias, id }, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchProjectActiveDS({
        orgAlias, id, url, authedAxios, componentName,
      })),
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias, entityType: FIELD_ENTITY_TYPE.TASK, authedAxios, url,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ProjectCreateTaskView';
  }

  constructor(props) {
    super(props);

    this.handleTaskSubmitted = this.handleTaskSubmitted.bind(this);
    this.handleTaskDiscarded = this.handleTaskDiscarded.bind(this);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCustomFieldsAC());
  }

  async handleTaskSubmitted(values) {
    const { history, match: { params: { orgAlias, id: projectId } } } = this.props;

    try {
      const response = await projectCreateTaskDS(orgAlias, projectId, {
        ...omit(values, 'skills'), skillIds: (values.skills || []).map(s => s.id),
      });
      const { data: { task } } = response;

      const discussionBoardUrl = projectViewTaskUrl(
        orgAlias, projectId, task.id, TASK_TABS.DISCUSSION, TASK_ACTION.ASSIGN,
      );

      history.push(discussionBoardUrl);
      return {};
    } catch (error) {
      const errorData = error.response.data;
      if (errorData._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        [FORM_ERROR]: errorData._error || 'Oops! Something went wrong. Please try again',
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  }

  handleTaskDiscarded() {
    const { history, match: { params: { orgAlias, id: projectId } } } = this.props;
    history.push(projectViewUrl(orgAlias, projectId, PROJECT_TABS.TASKS));
  }

  render() {
    const {
      match: { params: { orgAlias, id } }, project, customFieldTemplates,
    } = this.props;

    const projectOverviewUrl = projectViewUrl(orgAlias, id, PROJECT_TABS.OVERVIEW);
    const projectTasksUrl = projectViewUrl(orgAlias, id, PROJECT_TABS.TASKS);

    const breadcrumbs = [
      { title: 'Projects', url: projectListUrl(orgAlias) },
      { title: project.title, url: projectOverviewUrl },
      { title: 'Tasks', url: projectTasksUrl },
      { title: 'New Task' },
    ];

    const {
      initialValues: customFieldInitialValues,
      selectedCustomFields,
    } = extractCustomFieldSetup({ templates: customFieldTemplates });

    const initialValues = {
      ...customFieldInitialValues,
    };

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--add-worksheet">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={ProjectCardSkeleton}
            >
              <ProjectTaskForm
                project={project}
                onDiscard={this.handleTaskDiscarded}
                onTaskSubmitted={this.handleTaskSubmitted}
                formHeading="Create new task"
                customFieldTemplates={customFieldTemplates}
                customFields={selectedCustomFields}
                initialValues={initialValues}
                allowTemplateSelection
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectCreateTaskView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
  project: projectSpec,
  history: routerHistorySpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
};

ProjectCreateTaskView.defaultProps = {
  project: {},
  customFieldTemplates: [],
};

const mapStateToProps = state => ({
  project: getViewState(state, ProjectCreateTaskView.GetComponentName()).item,
  customFieldTemplates: getCustomFieldTemplates(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectCreateTaskViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectCreateTaskView);

export default withRouter(ProjectCreateTaskViewConnected);
