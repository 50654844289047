import React from 'react';
import PropTypes from 'prop-types';
import Root from 'core/assets/js/layout/placeholder/Root.jsx';

const LayoutNaked = ({ children }) => (
  <Root>
    <div className="page--naked page--no-shadow-effect">
      <React.Fragment>
        {children}
      </React.Fragment>
    </div>
  </Root>
);

LayoutNaked.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

LayoutNaked.defaultProps = {
  children: null,
};

export default LayoutNaked;
