import React from 'react';

function HeadersSection() {
  return (
    <section id="headers" className="bg-white p-4">
      <h2>Typography</h2>
      <div className="col-12">
        <h1>HEADING 1</h1>
        <h2>HEADING 2</h2>
        <h3>HEADING 3</h3>
        <h3 className="heading-block">HEADING 3 (.heading-block)</h3>
        <h3 className="heading-block has-sub mb-0">
          HEADING 3 (.heading-block) with sub
        </h3>
        <h4 className="heading-sub pb-3 mb-5">
          HEADING 4 sub
        </h4>
        <h4>HEADING 4</h4>
        <p>
          <strong>Example text content:</strong>
          <br />
          Lorem ipsum dolor sit amet, ex adipiscing omittantur mel,
          vel eros error postulant ea. Tota dolore molestie vel ad, vis aeque efficiendi ei.
          Eu vis diam diceret nominati, nisl atqui discere ad usu, omnis conceptam duo
          ea. Dolores propriae ut vim, pro ne postulant vituperata. Ea augue
          eirmod invidunt pro.
        </p>
      </div>
    </section>
  );
}

export default HeadersSection;
