import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { submissionSpec } from 'interviews/assets/js/lib/objectSpecs';
import { submissionUrl, submissionListApiUrl } from 'interviews/urls';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import SubmissionCardItem from 'interviews/assets/js/components/SubmissionCardItem.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import SubmissionListSkeleton from 'core/assets/js/components/Skeleton/SubmissionListSkeleton.jsx';
import { selectAuthenticated } from 'accounts/assets/js/reducers/auth';

const SubmissionList = ({ orgAlias, submissions }) => {
  const emptyListMsg = 'You haven\'t submitted yet';
  if (submissions.length === 0) {
    return (
      <li key="-1">
        {emptyListMsg}
      </li>
    );
  }
  return submissions.map(submission => (
    <li
      key={`submission-${submission.id}`}
      className="submission-list-item"
    >
      <SubmissionCardItem
        {...submission}
        name={submission.interview.name}
        url={submissionUrl(orgAlias, submission.id)}
        editUrl={submissionUrl(orgAlias, submission.id)}
      />
    </li>
  ));
};

SubmissionList.propTypes = {
  orgAlias: PropTypes.string,
  submissions: PropTypes.arrayOf(submissionSpec).isRequired,
};

SubmissionList.defaultProps = {
  orgAlias: '',
};

class SubmissionListView extends React.Component {
  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    const apiUrl = submissionListApiUrl(params.orgAlias, url);
    const prerequisites = [
      dispatch(fetchListDS({ url: apiUrl, querystring, componentName, authedAxios })),
    ];
    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'SubmissionListView';
  }

  render() {
    const { submissions, params, pagination } = this.props;
    const breadcrumbs = [
      {
        title: 'Submissions',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--org-settings">
          <div className="container">
            <TDApiConnected duck="list" component={this.constructor} skeletonComponent={SubmissionListSkeleton}>
              <div>
                <ul className="submission-list mt-4">
                  <SubmissionList submissions={submissions} orgAlias={params.orgAlias} />
                </ul>

                <TDPagination {...pagination} />
              </div>
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
SubmissionListView.propTypes = {
  submissions: PropTypes.arrayOf(submissionSpec),
  pagination: paginationSpec.isRequired,
  params: PropTypes.object,
};

SubmissionListView.defaultProps = {
  submissions: [],
  params: {},
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, SubmissionListView.GetComponentName());
  return {
    isAuthenticated: selectAuthenticated(state),
    submissions: listState.items,
    pagination: listState.pagination,
    params: props.match.params,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const SubmissionListViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmissionListView);
export default withRouter(SubmissionListViewConnect);
