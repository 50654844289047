import React from 'react';
import PropTypes from 'prop-types';
import { ICON } from 'core/assets/js/constants';

export const CHECKBOX_SIZES = {
  DEFAULT: 'default',
  LARGE: 'large',
  LARGER: 'larger',
};

const TDCheckbox = ({
  checked,
  className,
  disabled,
  green,
  onClick,
  size,
  type,
}) => {
  const classNames = ['custom-checkbox', className].filter(c => !!c);

  if (size) {
    classNames.push(`custom-checkbox--${size}`);
  }

  if (checked) {
    classNames.push('custom-checkbox--checked');
  }

  if (green) {
    classNames.push('custom-checkbox--green');
  }

  if (disabled) {
    classNames.push('custom-checkbox--disabled');
  }

  return (
    <span
      className={classNames.join(' ')}
      onClick={(...args) => !disabled && onClick(...args)}
    >
      { checked && type === 'checkbox' && (
        <span className={ICON.CHECKMARK_SOLID} />
      )}
    </span>
  );
};

TDCheckbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  green: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(CHECKBOX_SIZES)),
  type: PropTypes.string,
};

TDCheckbox.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  green: false,
  onClick: () => {},
  size: CHECKBOX_SIZES.DEFAULT,
  type: 'checkbox',
};

export default TDCheckbox;
