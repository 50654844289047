import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';

export const DESELECT_PROFILE_SKILL_MODAL_ID = 'deselect-profile-skill-modal';

const DeSelectProfileSkillModal = ({
  dispatch, open, skill, onConfirm,
}) => {
  const {
    id: skillId, copiedByUser, isManagerEndorsed,
    endorserOrganizationName, label: skillLabel, votesCount,
  } = skill;

  const isNotRemovable = isManagerEndorsed && !copiedByUser;
  const isCopiedEndorsed = isManagerEndorsed && copiedByUser;
  const isOnlyUpvoted = !isManagerEndorsed && copiedByUser && votesCount > 0;

  return (
    <ModalConfirm
      heading={`Remove "${skillLabel}" from your skill`}
      confirmLabel="Remove"
      open={open}
      closeOnConfirm={false}
      onClose={() => dispatch(modalCloseAC(DESELECT_PROFILE_SKILL_MODAL_ID))}
      onConfirm={() => onConfirm(skillId)}
      showConfirmButton={!isNotRemovable}
      cancelLabel={isNotRemovable ? 'Close' : 'Cancel'}
    >
      {isCopiedEndorsed && (
        <p>
          You are about to remove
          <strong>{ ` "${skillLabel}" ` }</strong>
          from your skills. Please note that you have been endorsed for this skill by
          <strong>{` "${endorserOrganizationName}" `}</strong>
          and even if you remove it from your skills, it will still appear in your
          profile as an endorsed skill.
        </p>
      )}

      {isOnlyUpvoted && (
        <p>
          You are about to remove
          <strong>{ ` "${skillLabel}" ` }</strong>
          from your skills. Please note that by doing so, you will also lose
          the skill votes, and this change is not reversible.
        </p>
      )}

      {isNotRemovable ? (
        <p>
          You have been endorsed for
          <strong>{ ` "${skillLabel}" ` }</strong>
          by
          <strong>{` "${endorserOrganizationName}" `}</strong>
          .
          Endorsed Skills can get adjusted only by managers.
        </p>
      ) : <p>Are you sure you want to continue?</p>}

    </ModalConfirm>
  );
};

DeSelectProfileSkillModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  skill: skillSpec,
  onConfirm: PropTypes.func.isRequired,
};

DeSelectProfileSkillModal.defaultProps = {
  open: false,
  skill: null,
};

const mapStateToProps = (state) => {
  const modalPayload = getModalPayload(state, DESELECT_PROFILE_SKILL_MODAL_ID) || {};
  return {
    open: getIsModalOpen(state, DESELECT_PROFILE_SKILL_MODAL_ID),
    skill: modalPayload?.skill,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const DeSelectProfileSkillModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeSelectProfileSkillModal);

export default DeSelectProfileSkillModalConnected;
