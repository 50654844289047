import { marked } from 'marked';

marked.use({
  renderer: {
    link(href, title, text) {
      return `<a href="${href}" target="_blank"${title ? ` title="${title}"` : ''}>${text}</a>`;
    },
  },
});

// eslint-disable-next-line import/prefer-default-export
export const notificationMarkdownToHTML = markdown => marked.parse(markdown);
