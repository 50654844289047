import React from 'react';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import { Card } from 'react-bootstrap';

const ProjectUpcomingPaymentsSkeleton = () => (
  <Card className="project-widget__upcoming-payments">
    <Card.Header>
      Upcoming payment orders
    </Card.Header>
    <Card.Body>
      <div className="upcoming-payments upcoming-payments__worksheets-container">
        <div className="row px-4">
          <div className="col-7 pl-0">
            <RectangleSkeleton height="10" width="100" />
          </div>
          <div className="col-5">
            <div className="row">
              <span className="col-6 pr-0 text-right upcoming-amount upcoming-amount--worksheets">
                <RectangleSkeleton height="10" width="50" />
              </span>
              <span className="col-6 pr-0 text-right upcoming-value upcoming-value--worksheets">
                <RectangleSkeleton height="10" width="50" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="upcoming-payments upcoming-payments__expenses-container">
        <div className="row px-4">
          <div className="col-7 pl-0">
            <RectangleSkeleton height="10" width="100" />
          </div>
          <div className="col-5">
            <div className="row">
              <span className="col-6 pr-0 text-right upcoming-amount upcoming-amount--expenses">
                <RectangleSkeleton height="10" width="50" />
              </span>
              <span className="col-6 pr-0 text-right upcoming-value upcoming-value--expenses">
                <RectangleSkeleton height="10" width="50" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="upcoming-payments upcoming-payments__totals-container">
        <div className="row px-4">
          <div className="col-12 col-md-7 pl-0">
            <span><RectangleSkeleton height="10" width="120" /></span>
          </div>
          <div className="col-12 col-md-5">
            <div className="row">
              <span className="col-6 pr-0 text-md-right pl-0 pl-md-3 upcoming--label upcoming-label--totals">
                <RectangleSkeleton height="10" width="100" />
              </span>
              <span className="col-6 pr-0 text-right pl-0 pl-md-3 upcoming--value upcoming--value--totals">
                <RectangleSkeleton height="10" width="70" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default ProjectUpcomingPaymentsSkeleton;
