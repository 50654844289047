import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import AutosizeInput from 'react-input-autosize';

import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';


const autosizingRenderInput = ({ addTag, ...props }) => {
  const { onChange, value, ...other } = props;
  return (
    <AutosizeInput type="text" onChange={onChange} value={value} {...other} />
  );
};
autosizingRenderInput.propTypes = {
  addTag: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const TagsInputField = ({
  input, label, sublabel, placeholder, required,
  meta: {
    error, submitError,
  },
}) => {
  const classNames = ['form-group'];
  if (submitError || error) {
    classNames.push('has-error');
  }

  return (
    <div className={classNames.join(' ')} data-testid="tags-input-field">
      <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />

      <TagsInput
        value={input.value || []}
        addKeys={[9, 13, 186, 188]} // tab, enter, space, semicolon, comma
        onlyUnique
        addOnBlur
        addOnPaste
        inputProps={{ placeholder }}
        renderInput={autosizingRenderInput}
        pasteSplit={data => (
          data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
        )}
        onChange={input.onChange}
        id={`field-${input.name}`}
      />

      {error && <span className="help-block d-inline-block mt-3">{error}</span>}
      {submitError && <span className="help-block d-inline-block mt-3">{submitError}</span>}
    </div>
  );
};

TagsInputField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: finalFormFieldMetaSpec,
  placeholder: PropTypes.string,
  label: finalFormFieldLabelSpec,
  sublabel: finalFormFieldLabelSpec,
  required: PropTypes.bool,
};

TagsInputField.defaultProps = {
  meta: {
    error: '',
    submitError: '',
    pristine: true,
  },
  placeholder: '',
  sublabel: null,
  label: '',
  required: false,
};

export default withField(TagsInputField);
