import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { ICON } from 'core/assets/js/constants';
import Storage from 'core/assets/js/lib/Storage';

const DASHBOARD_BANNER_CLOSED_STORAGE_KEY = 'dashboardBannerClosed';

const BannerWidget = ({ title, subTitle, activeUserProfile }) => {
  const greetingMsg = `Hello ${activeUserProfile.firstName},`;
  const welcomeMsg = 'Welcome to TalentDesk!';

  const [showBanner, setShowBanner] = useState();

  useEffect(() => {
    const bannerSettings = Storage.get(
      `${DASHBOARD_BANNER_CLOSED_STORAGE_KEY}-${activeUserProfile.userId}`,
    );
    setShowBanner(!bannerSettings);
  }, []);

  const handleClose = () => {
    setShowBanner(false);
    Storage.set(
      `${DASHBOARD_BANNER_CLOSED_STORAGE_KEY}-${activeUserProfile.userId}`,
      true,
    );
  };

  return (
    <div
      className={`${showBanner ? 'dashboard-banner-widget-section' : 'd-none'}`}
    >
      <a
        className="dashboard-banner-widget-section__close"
        onClick={handleClose}
      >
        <i className={ICON.CROSS} />
      </a>
      <h1
        data-testid="dashboard-greeting"
        className="dashboard-banner-widget-section__title"
      >
        {title || greetingMsg}
      </h1>

      <p className="dashboard-banner-widget-section__subtitle">
        {subTitle || welcomeMsg}
      </p>
    </div>
  );
};

BannerWidget.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  activeUserProfile: PropTypes.shape({
    firstName: PropTypes.string,
    userId: PropTypes.number.isRequired,
  }).isRequired,
};

BannerWidget.defaultProps = {
  title: null,
  subTitle: null,
};

const mapStateToProps = (state) => ({
  activeUserProfile: selectProfile(state),
});

const BannerWidgetConnected = connect(mapStateToProps)(BannerWidget);

export default BannerWidgetConnected;
