import { LAYOUTS } from 'core/assets/js/constants';

import IntegrationsListView from 'integrations/assets/js/IntegrationsListView.jsx';
import IntegrationDetailsView from 'integrations/assets/js/IntegrationDetailsView.jsx';
import IntegrationConnectView from 'integrations/assets/js/IntegrationConnectView.jsx';
import {
  orgIntegrationConnectUrl, orgIntegrationDetailUrl, orgIntegrationsUrl,
} from 'integrations/urls';
import { PERMISSIONS } from 'roles/assets/js/constants';

const integrationsRouteConfig = [
  {
    component: IntegrationsListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgIntegrationsUrl(':orgAlias'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: IntegrationConnectView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgIntegrationConnectUrl(':orgAlias', ':integrationType'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
  {
    component: IntegrationDetailsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgIntegrationDetailUrl(':orgAlias', ':integrationId', ':activeTab'),
    requirePermission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  },
];

export default integrationsRouteConfig;
