import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { tncUrl } from 'core/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';


const DocumentTermsContentBlocker = ({ onAccept }) => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  if (hasAcceptedTerms) {
    return null;
  }

  return (
    <div className="document-terms-content-blocker">
      <div className="document-terms-content-blocker__content p-4">
        <div className="container">
          <Form
            onSubmit={() => {}}
            render={({ values, handleSubmit }) => {
              const isCheckboxActive = values?.approved && values?.approved[0]?.value;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="d-flex align-items-center justify-content-start flex-column flex-md-row">
                    <CheckboxField
                      className="mb-0"
                      inputClassName="mb-0"
                      labelClassName="mb-0"
                      name="approved"
                      options={[{
                        text: (
                          <span>
                            {'I agree to use '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={tncUrl()}
                            >
                              Electronic Records and Signatures
                            </a>
                          </span>
                        ),
                        value: true,
                      }]}
                      type="checkbox"
                      wrapperClasses="mb-4 mb-md-0"
                    />

                    <div className="ml-md-auto">
                      <TDButton
                        disabled={!isCheckboxActive}
                        onClick={() => {
                          setHasAcceptedTerms(true);
                          if (onAccept) {
                            onAccept();
                          }
                        }}
                        type="submit"
                        variant={BS_STYLE.PRIMARY}
                      >
                        Continue
                      </TDButton>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

DocumentTermsContentBlocker.propTypes = {
  onAccept: PropTypes.func.isRequired,
};

export default DocumentTermsContentBlocker;
