import React from 'react';
import PropTypes from 'prop-types';
import Table from 'core/assets/js/components/Table.jsx';


function ManagersBudgetBreakdownTable({ postings, info, ...rest }) {
  const currency = (info && info.currency ? info.currency : '');
  const tableColumns = [
    { key: 'date', label: 'Date', width: '120px' },
    { key: 'postingTypeLabel', label: 'Transaction type', width: '170px' },
    { key: 'debitedFrom', label: 'Debited from' },
    { key: 'creditedTo', label: 'Credited to' },
    {
      key: 'changeAmt',
      label: 'Change',
      isAmtDiff: true,
      currencySymbol: currency,
      columnClassName: 'text-right',
      width: '150px',
    },
    { key: 'balance',
      label: 'Balance',
      isMoney: true,
      currencySymbol: currency,
      columnClassName: 'text-right',
      width: '150px',
    },
  ];
  return (
    <Table cols={tableColumns} items={postings} {...rest} />
  );
}

ManagersBudgetBreakdownTable.propTypes = {
  info: PropTypes.object,
  postings: PropTypes.array.isRequired,
};
ManagersBudgetBreakdownTable.defaultProps = {
  info: '',
};

export default ManagersBudgetBreakdownTable;
