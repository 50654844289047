import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ICON } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  ROLE_TABS, SYSTEM_ROLES, SYSTEM_ROLES_DESCRIPTION, SYSTEM_ROLES_ICONS,
} from 'roles/assets/js/constants';
import { roleEditUrl, systemRoleViewUrl } from 'roles/urls';

const RoleCard = ({
  actionsRowFormatter,
  formatName,
  formatUsers,
  role,
  systemRole,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const isSystemRole = Object.values(SYSTEM_ROLES).includes(systemRole);
  const infoWrapperContent = (
    <>
      <i className={`${systemRole ? SYSTEM_ROLES_ICONS[systemRole] : ICON.GEAR_DUOTONE} mr-4`} />
      <div className="roles-field__info">
        <p className="roles-field__label">{formatName(role)}</p>
        <p className="roles-field__type">
          {systemRole ? SYSTEM_ROLES_DESCRIPTION[systemRole] : 'Custom Role'}
        </p>
      </div>
      <div className="roles-field__actions-button-mobile">
        {actionsRowFormatter(role)}
      </div>
    </>
  );
  const infoWrapperClassName = 'roles-field__info-wrapper d-flex text-decoration-none';
  return (
    <li
      className={`
        roles-field__list-item
        d-flex
        flex-column
        flex-md-row
        justify-content-between
        align-items-start
        align-items-md-center
        mt-3
        px-4
        py-5
      `}
      data-testid={systemRole ? 'system-role-card' : 'custom-role-card'}
    >
      {(isSystemRole || role?.allowedActions?.canEdit) && (
        <Link
          className={infoWrapperClassName}
          to={(
            isSystemRole
              ? systemRoleViewUrl(activeOrg.alias, systemRole, ROLE_TABS.CONFIG)
              : roleEditUrl(activeOrg.alias, role?.id, ROLE_TABS.CONFIG)
          )}
        >
          {infoWrapperContent}
        </Link>
      )}
      {(!isSystemRole && !role?.allowedActions?.canEdit) && (
        <div className={infoWrapperClassName}>{infoWrapperContent}</div>
      )}
      <div className="roles-field__permissions-wrapper d-flex">
        <i className={`${ICON.KEY} mr-2`} />
        {` ${role.permissions.length} permissions`}
      </div>
      <div className="roles-field__members-wrapper d-flex">
        <i className={`${ICON.USER} mr-2`} />
        {formatUsers(role)}
      </div>
      <div className="roles-field__actions-button">
        {actionsRowFormatter(role)}
      </div>
    </li>
  );
};

RoleCard.propTypes = {
  actionsRowFormatter: PropTypes.func,
  formatName: PropTypes.func,
  formatUsers: PropTypes.func,
  role: PropTypes.object.isRequired,
  systemRole: PropTypes.string,
};

RoleCard.defaultProps = {
  actionsRowFormatter: () => {},
  formatName: () => {},
  formatUsers: () => {},
  systemRole: '',
};

export default RoleCard;
