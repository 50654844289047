import React from 'react';
import { withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { orgGetStartedUrl } from 'accounts/urls';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';

const AORContractSignConfirmationView = ({ match: { params: { orgAlias } } }) => {
  const redirectToGetStarted = () => {
    top.location.href = orgGetStartedUrl(orgAlias); // eslint-disable-line
  };
  const isRattlecageUser = top.location.href.includes('rattlecage'); // eslint-disable-line

  return (
    <div className="aor-contract-confirmation-message row">
      <div className="col-12 col-md-10 offset-md-1">

        {isRattlecageUser && (
          <>
            <h1 className="mt-5 mb-3">The AOR contract has been successfully countersigned.</h1>
            <p>
              A notification has been sent and AOR has been enabled for the provider.
            </p>
            <p>
              You can now close this modal.
            </p>
          </>
        )}

        {!isRattlecageUser && (
          <>
            <h1 className="mt-5 mb-3">The AOR contract has been successfully signed.</h1>
            <p>
              The contract is now being reviewed by our legal team, they may contact you directly
              if there are any issues with it, otherwise, you will be notified once it has been
              countersigned.
            </p>
            <p>
              In the meantime, please continue with the rest of the Get Started steps.
            </p>

            <TDButton
              btnIcon={ICON.ARROW_RIGHT}
              variant={BS_STYLE.PRIMARY}
              className="mx-auto mt-5"
              label="Continue"
              onClick={redirectToGetStarted}
            />
          </>
        )}
      </div>
    </div>
  );
};

AORContractSignConfirmationView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(AORContractSignConfirmationView);
