import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { SubmissionError } from 'redux-form';

import axios from 'core/assets/js/lib/tdAxios';
import {
  financeCancelProFormaApiUrl,
  financeDraftInvoiceAllowedActionsApiUrl,
  financeExpenseManageApiUrl,
  financeExpenseRequestAmendmentApiUrl,
  financeGetExchangeRatesApiUrl,
  financeInvoiceManageApiUrl,
  financeProFormaInvoiceCreateApiUrl,
  financeProFormaInvoiceEditApiUrl,
  financeProFormaInvoiceManageApiUrl,
  financeProFormaInvoiceRequestAmendmentApiUrl,
  financePurchaseOrderManageApiUrl,
  financeServiceOrderMissingPrerequisitesApiUrl,
  financeWorksheetManageApiUrl,
  financeWorksheetRequestAmendmentApiUrl,
} from 'finance/urls';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import { listReplaceItemAC } from 'core/assets/js/ducks/list';
import { viewFetchErrorAC, viewFetchExtrasAC, viewUpdateAC } from 'core/assets/js/ducks/view';
import { DATE_FORMAT_DEFAULT, REQ_TYPE, USER_ACTION } from 'core/assets/js/constants';

/**
 * Helper function to update the state of a finance document
 *
 * Used in order to:
 * approve/reject purchase orders
 * reject worksheets
 *
 * @param {Object} properties
 * @param {String} properties.action
 * @param {String} [properties.bindingResponseKey]
 * @param {String} properties.componentName
 * @param {Object} properties.data
 * @param {Number} properties.documentId
 * @param {String} properties.listingComponentName
 * @param {String} properties.manageApiUrl
 * @param {String} properties.orgAlias
 * @returns {Promise.<TResult>}
 * @private
 */
const _financeDocumentManageDS = ({
  action,
  bindingResponseKey = null,
  componentName,
  data,
  documentId,
  listingComponentName,
  manageApiUrl,
  orgAlias,
}) =>
  pushDataDS({
    validate: () => {
      if (!componentName) {
        throw new Error('[_financeDocumentManageDS Error] Cannot update view without componentName');
      }
      if (!orgAlias || !documentId) {
        throw new Error(
          `[_financeDocumentManageDS Error] Invalid orgAlias (${orgAlias}) or documentId (${documentId})`);
      }
      if (!manageApiUrl) {
        throw new Error(`[_financeDocumentManageDS Error] Invalid manageApiUrl (${manageApiUrl}`);
      }

      // Invalid purchase order action
      if (!Object.values(USER_ACTION).includes(action)) {
        throw new Error(`[_financeDocumentManageDS Error] Invalid action "${action}"`);
      }
    },
    reqType: REQ_TYPE.PUT,
    pushApiUrl: manageApiUrl(orgAlias, documentId, action),
    values: data,
    pushDataAC: response => {
      const actions = [
        viewUpdateAC(bindingResponseKey ? response[bindingResponseKey] : response, componentName),
        refreshFinancePendingCountsDS({ orgAlias }),
      ];
      if (action === USER_ACTION.CANCEL && listingComponentName) {
        actions.push(listReplaceItemAC(response, listingComponentName));
      }
      return actions;
    },
  });

export const financePurchaseOrderApproveDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financePurchaseOrderManageApiUrl, action: USER_ACTION.APPROVE, ...args,
  })
);

export const financePurchaseOrderRejectDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financePurchaseOrderManageApiUrl, action: USER_ACTION.REJECT, ...args,
  })
);

export const financePurchaseOrderCancelDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financePurchaseOrderManageApiUrl, action: USER_ACTION.CANCEL, ...args,
  })
);

// Notice: manual approval is not provided at the moment in our UI!
export const financeWorksheetApproveDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeWorksheetManageApiUrl, action: USER_ACTION.APPROVE, ...args,
  })
);

export const financeWorksheetConfirmDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeWorksheetManageApiUrl, action: USER_ACTION.CONFIRM, ...args,
  })
);

export const financeWorksheetRejectDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeWorksheetManageApiUrl, action: USER_ACTION.REJECT, ...args,
  })
);

export const financeExpenseApproveDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeExpenseManageApiUrl, action: USER_ACTION.APPROVE, ...args,
  })
);

export const financeExpenseConfirmDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeExpenseManageApiUrl, action: USER_ACTION.CONFIRM, ...args,
  })
);

export const financeExpenseRejectDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeExpenseManageApiUrl, action: USER_ACTION.REJECT, ...args,
  })
);

export const financeInvoiceMarkPaidDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeInvoiceManageApiUrl, action: USER_ACTION.MARK_PAID, ...args,
  })
);

export const financeInvoiceChangeNumberDS = ({ ...args }) => (
  _financeDocumentManageDS({
    manageApiUrl: financeInvoiceManageApiUrl, action: USER_ACTION.CHANGE_NUMBER, ...args,
  })
);

export const financeWorksheetVoidDS = ({ orgAlias, id, values, componentName }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: financeWorksheetManageApiUrl(orgAlias, id, USER_ACTION.VOID),
    values,
    pushDataAC: response => [
      viewUpdateAC(response, componentName),
    ],
  })
);

export const financeExpenseVoidDS = ({ orgAlias, id, values, componentName }) => (
  pushDataDS({
    pushApiUrl: financeExpenseManageApiUrl(orgAlias, id, USER_ACTION.VOID),
    pushDataAC: response => [viewUpdateAC(response, componentName)],
    reqType: REQ_TYPE.PUT,
    values,
  })
);

export const financeWorksheetRequestAmendmentDS = ({ orgAlias, id, values, componentName }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: financeWorksheetRequestAmendmentApiUrl(orgAlias, id),
    values,
    pushDataAC: response => [
      viewUpdateAC(response, componentName),
      refreshFinancePendingCountsDS({ orgAlias }),
    ],
  })
);

export const financeExpenseRequestAmendmentDS = ({ orgAlias, id, values, componentName }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: financeExpenseRequestAmendmentApiUrl(orgAlias, id),
    values,
    pushDataAC: response => [
      viewUpdateAC(response, componentName),
      refreshFinancePendingCountsDS({ orgAlias }),
    ],
  })
);

export const fetchExchangeRatesDS = ({ authedAxios = null, componentName, orgAlias }) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => financeGetExchangeRatesApiUrl(orgAlias),
    fetchDataAC: values => viewFetchExtrasAC(
      { ...values, timestamp: moment().format(DATE_FORMAT_DEFAULT) },
      componentName,
      'exchangeRates',
    ),
    fetchDataErrorAC: viewFetchErrorAC,
  })
);

export const createProFormaInvoiceDS = async (orgAlias, values) => {
  if (!orgAlias) {
    throw new Error('[createProFormaInvoiceDS Error] Expected the "orgAlias" param');
  }

  const response = await axios.post(financeProFormaInvoiceCreateApiUrl(orgAlias), values);
  return response?.data?.serviceOrder;
};

export const editProFormaInvoiceDS = async (orgAlias, id, values) => {
  if (!orgAlias) {
    throw new Error('[editProFormaInvoiceDS Error] Expected the "orgAlias" param');
  }
  if (!id) {
    throw new Error('[editProFormaInvoiceDS Error] Expected the "id" param');
  }

  const response = await axios.put(financeProFormaInvoiceEditApiUrl(orgAlias, id), values);
  return response?.data?.serviceOrder;
};

export const financeCancelProFormaInvoiceDS = ({ orgAlias, serviceOrderId }, values) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: financeCancelProFormaApiUrl(orgAlias, serviceOrderId),
      values,
    })).then((updated) => {
      toastr.success('Well Done!', 'Proforma Invoice cancelled successfully.');
      return updated;
    })
      .catch((data) => {
        throw new SubmissionError(data.errors);
      })
  )
);

export const financeProFormaInvoiceRequestAmendmentDS = ({
  orgAlias, id, values, componentName,
}) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: financeProFormaInvoiceRequestAmendmentApiUrl(orgAlias, id),
    values,
    pushDataAC: response => [
      viewUpdateAC(response.serviceOrder, componentName),
      refreshFinancePendingCountsDS({ orgAlias }),
    ],
  })
);

export const financeProFormaInvoiceApproveDS = ({ ...args }) => (
  _financeDocumentManageDS({
    action: USER_ACTION.APPROVE,
    bindingResponseKey: 'serviceOrder',
    manageApiUrl: financeProFormaInvoiceManageApiUrl,
    ...args,
  })
);


export const financeProFormaInvoiceConfirmDS = ({ ...args }) => (
  _financeDocumentManageDS({
    action: USER_ACTION.CONFIRM,
    manageApiUrl: financeProFormaInvoiceManageApiUrl,
    bindingResponseKey: 'serviceOrder',
    ...args,
  })
);

export const financeProFormaInvoiceRejectDS = ({ ...args }) => (
  _financeDocumentManageDS({
    action: USER_ACTION.REJECT,
    bindingResponseKey: 'serviceOrder',
    manageApiUrl: financeProFormaInvoiceManageApiUrl,
    ...args,
  })
);

export const financeProFormaInvoiceVoidDS = ({ orgAlias, id, values, componentName }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: financeProFormaInvoiceManageApiUrl(orgAlias, id, USER_ACTION.VOID),
    values,
    pushDataAC: response => [
      viewUpdateAC(response.serviceOrder, componentName),
    ],
  })
);

export const fetchDraftInvoiceAllowedActionsDS = ({
  authedAxios = null, componentName, orgAlias,
}) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => financeDraftInvoiceAllowedActionsApiUrl(orgAlias),
    componentName,
    fetchDataAC: responseData => [
      viewFetchExtrasAC(responseData, componentName, 'allowedActions'),
    ],
  })
);

export const fetchServiceOrderMissingPrerequisitesDS = ({
  authedAxios = null, componentName, orgAlias, userId,
}) => (
  fetchDataDS({
    authedAxios,
    componentName,
    fetchApiUrl: () => financeServiceOrderMissingPrerequisitesApiUrl(orgAlias, userId),
    fetchDataAC: responseData => [
      viewFetchExtrasAC(responseData, componentName, 'missingPrerequisites'),
    ],
  })
);
