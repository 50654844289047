import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import ApprovedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/ApprovedLabel.jsx';
import ConfirmedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/ConfirmedLabel.jsx';
import InvoicedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/InvoicedLabel.jsx';
import PaidLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/PaidLabel.jsx';
import ProcessingLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/ProcessingLabel.jsx';
import RaisedLabel from 'core/assets/js/components/Dashboard/PaymentStatusWidget/RaisedLabel.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import {
  approvedIsGreen, confirmedIsGreen, invoicedIsGreen, paidIsGreen, processingIsGreen,
} from 'core/assets/js/lib/paymentStatusWidget';
import WorksheetTypeIndicator from 'finance/assets/js/components/WorksheetTypeIndicator.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { serviceOrderLightSpec } from 'finance/assets/js/lib/objectSpecs';
import { financeServiceOrderViewUrl, financeWorksheetsCreateUrl } from 'finance/urls';
import { SERVICE_ORDER_STATUS, SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';

const Dot = ({ isFirst, isGreen, isLast, nextIsGreen }) => {
  const preBarClass = (isGreen || isFirst) ? ` ${isFirst ? 'transparent' : 'complete'}` : '';
  const postBarClass = (nextIsGreen || isLast) ? ` ${isLast ? 'transparent' : 'complete'}` : '';
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className={`bar${preBarClass}`} />
      <div className={`dot${isGreen ? ' complete' : ''}`} />
      <div className={`bar${postBarClass}`} />
    </div>
  );
};

Dot.propTypes = {
  isFirst: PropTypes.bool,
  isGreen: PropTypes.bool.isRequired,
  isLast: PropTypes.bool,
  nextIsGreen: PropTypes.bool,
};

Dot.defaultProps = {
  isFirst: false,
  isLast: false,
  nextIsGreen: false,
};

const DotOrAmend = ({ history, id, isGreen, needsAmended, nextIsGreen, orgAlias, type }) => (
  <>
    <div className="d-flex align-items-center justify-content-between">
      {needsAmended && (
        <>
          <div className="bar complete" />
          <TDButton
            className="text-nowrap"
            label="Amend"
            onClick={() => history.push(financeServiceOrderViewUrl(
              orgAlias, type, id,
            ))}
            variant={BS_STYLE.WARNING}
          />
          <div className={`bar${nextIsGreen ? ' complete' : ''}`} />
        </>
      )}
    </div>
    {!needsAmended && <Dot isGreen={isGreen} nextIsGreen={nextIsGreen} />}
  </>
);

DotOrAmend.propTypes = {
  history: routerHistorySpec.isRequired,
  id: PropTypes.number,
  isGreen: PropTypes.bool.isRequired,
  needsAmended: PropTypes.bool.isRequired,
  nextIsGreen: PropTypes.bool,
  orgAlias: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(SERVICE_ORDER_TYPE)),
};

DotOrAmend.defaultProps = {
  id: null,
  nextIsGreen: false,
  type: null,
};

const PaymentStatusWidgetDesktop = ({
  history, isSecondLevelApprovalEnabled, orgAlias, serviceOrders,
}) => {
  const columns = [
    {
      dataFormat: (_, serviceOrder) => (
        serviceOrder.type && <WorksheetTypeIndicator type={serviceOrder.type} />
      ),
      label: 'Type',
      width: 60,
    },
    {
      columnClassName: 'font-weight-bold',
      dataFormat: (_, serviceOrder) => (
        serviceOrder.serviceOrderAmount && (
          <NumberTpl
            prefix={serviceOrder.serviceOrderCurrencySymbol}
            value={serviceOrder.serviceOrderAmount}
          />
        )
      ),
      label: 'Amount',
    },
    {
      columnClassName: 'p-0',
      dataFormat: (_, serviceOrder) => (
        <>
          {serviceOrder.type && (
            <Dot
              isGreen={!!serviceOrder.type}
              isFirst
              nextIsGreen={
                isSecondLevelApprovalEnabled
                  ? confirmedIsGreen(serviceOrder)
                  : approvedIsGreen(serviceOrder, isSecondLevelApprovalEnabled)
              }
            />
          )}
          {!serviceOrder.type && (
            <div className="d-flex align-items-center justify-content-between">
              <div className="bar transparent" />
              <TDButton
                className="text-nowrap"
                label="Submit worksheet"
                onClick={() => history.push(financeWorksheetsCreateUrl(orgAlias))}
                variant={BS_STYLE.PRIMARY}
              />
              <div className="bar" />
            </div>
          )}
        </>
      ),
      label: <RaisedLabel />,
      width: 160,
    },
  ];

  if (isSecondLevelApprovalEnabled) {
    columns.push({
      columnClassName: 'p-0',
      dataFormat: (_, serviceOrder) => (
        <DotOrAmend
          history={history}
          id={serviceOrder.id}
          isGreen={confirmedIsGreen(serviceOrder)}
          needsAmended={
            serviceOrder.status === SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED
            && !serviceOrder.confirmed
          }
          nextIsGreen={approvedIsGreen(serviceOrder, isSecondLevelApprovalEnabled)}
          orgAlias={orgAlias}
          type={serviceOrder.type}
        />
      ),
      label: <ConfirmedLabel />,
    });
  }

  columns.push(
    {
      columnClassName: 'p-0',
      dataFormat: (_, serviceOrder) => (
        <DotOrAmend
          history={history}
          id={serviceOrder.id}
          isGreen={approvedIsGreen(serviceOrder, isSecondLevelApprovalEnabled)}
          needsAmended={
            serviceOrder.status === SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED
            && (!isSecondLevelApprovalEnabled || serviceOrder.confirmed)
          }
          nextIsGreen={invoicedIsGreen(serviceOrder)}
          orgAlias={orgAlias}
          type={serviceOrder.type}
        />
      ),
      label: <ApprovedLabel isSecondLevelApprovalEnabled={isSecondLevelApprovalEnabled} />,
    },
    {
      columnClassName: 'p-0',
      dataFormat: (_, serviceOrder) => (
        <Dot
          isGreen={invoicedIsGreen(serviceOrder)}
          nextIsGreen={processingIsGreen(serviceOrder)}
        />
      ),
      label: <InvoicedLabel />,
    },
    {
      columnClassName: 'p-0',
      dataFormat: (_, serviceOrder) => (
        <Dot
          isGreen={processingIsGreen(serviceOrder)}
          nextIsGreen={paidIsGreen(serviceOrder)}
        />
      ),
      label: <ProcessingLabel />,
    },
    {
      columnClassName: 'p-0',
      dataFormat: (_, serviceOrder) => (
        <Dot isGreen={paidIsGreen(serviceOrder)} isLast />
      ),
      label: <PaidLabel />,
    },
    { columnClassName: 'text-right', key: 'updatedAt', label: 'Last action' },
  );

  return (
    <Table
      cols={columns}
      containerClass="d-none d-xl-block"
      items={[{}, ...serviceOrders]}
      responsive={false}
      tableProps={{ bordered: false }}
    />
  );
};

PaymentStatusWidgetDesktop.propTypes = {
  history: routerHistorySpec.isRequired,
  isSecondLevelApprovalEnabled: PropTypes.bool.isRequired,
  orgAlias: PropTypes.string.isRequired,
  serviceOrders: PropTypes.arrayOf(serviceOrderLightSpec).isRequired,
};

export default withRouter(PaymentStatusWidgetDesktop);
