import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { API_DATE_FORMAT, BS_STYLE, ICON, MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { formatDate, getDatetime, parseDate } from 'core/assets/js/lib/utils';
import Storage from 'core/assets/js/lib/Storage';
import axios from 'core/assets/js/lib/tdAxios';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { PROJECT_ACCESS_TYPE, PROJECT_STATUS } from 'projects/assets/js/constants';
import { projectListApiUrl } from 'projects/urls';
import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';

const MODAL_ID = 'service-order-cutoff-reminder-modal';

const getCountdownString = billingDateObj => (
  moment.utc(billingDateObj.diff(getDatetime(), 'ms')).format('H:mm:ss')
);

const ServiceOrderCutoffReminder = () => {
  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);
  const modalIsOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));

  const [billingDay, setBillingDay] = useState('');
  const [billingMonth, setBillingMonth] = useState('');
  const [billingYear, setBillingYear] = useState('');
  const [countDownString, setCountDownString] = useState('');
  const [localStorageKey, setLocalStorageKey] = useState('');

  const timer = useRef(null);

  const dispatch = useDispatch();

  const openModal = billingDateObj => {
    setCountDownString(getCountdownString(billingDateObj));
    timer.current = setInterval(
      () => {
        setCountDownString(getCountdownString(billingDateObj));
      },
      1000,
    );
    dispatch(modalOpenAC(MODAL_ID));
  };

  const closeModal = () => {
    Storage.set(localStorageKey, true);
    dispatch(modalCloseAC());
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  useEffect(() => {
    /*
      Only show the modal if:
      - The user is a higher manager or a manager who owns an in-progress project
      - It is within 24 hours of the next billing date
      - They have not already dismissed this billing period's modal
    */
    if (activeUserCard.userRole.isProvider) {
      // They're a provider, so don't show the modal
      return;
    }
    if (!activeOrg.invoicing_frequency_dump) {
      return;
    }
    let invFreq;
    try {
      invFreq = InvoicingFrequency.fromDump(activeOrg.invoicing_frequency_dump);
    } catch (e) {
      return;
    }
    const billingDeadline = formatDate(
      invFreq.getNextBillingProcess().billingDeadline,
      API_DATE_FORMAT,
    );
    const prefix = 'service-order-cutoff-reminder-dismissed';
    const newLocalStorageKey = `${prefix}_${activeOrg.alias}_${billingDeadline}`;
    setLocalStorageKey(newLocalStorageKey);
    if (Storage.get(newLocalStorageKey)) {
      // They've dismissed this billing period's modal, so don't show it
      return;
    }
    const billingDeadlineObj = parseDate(billingDeadline, API_DATE_FORMAT);
    const diffDays = billingDeadlineObj.diff(getDatetime(), 'days', true);
    if (diffDays < 0 || diffDays > 1) {
      // It is more than a day before or during the next billing date, so don't show the modal
      return;
    }
    setBillingDay(billingDeadlineObj.format('D'));
    setBillingMonth(billingDeadlineObj.format('MMMM'));
    setBillingYear(billingDeadlineObj.format('YYYY'));
    if (activeUserCard.userRole.isHigherManager) {
      // They are a higher manager, so show the modal
      openModal(billingDeadlineObj);
      return;
    }
    // Check if the manager owns an active project
    axios
      .get(
        [
          projectListApiUrl(activeOrg.alias),
          `?accessType=${PROJECT_ACCESS_TYPE.OWNER}`,
          `&status=${PROJECT_STATUS.INPROGRESS}`,
        ].join(''),
      )
      .then(({ data: { items } }) => {
        if (items.length > 0) {
          // They are a manager that owns an in-progress project, so show the modal
          openModal(billingDeadlineObj);
        }
      })
      .catch(Sentry.captureException);
  }, [activeOrg.invoicing_frequency_dump]);

  return (
    <ModalSimple
      body={(
        <div
          className="service-order-cutoff-reminder d-flex flex-column align-items-center text-center"
        >
          <div className="calendar d-inline-block">
            <i className={`${ICON.CALENDAR_DUOTONE} fa-10x mb-5`} />
            <div className="date d-flex flex-column align-items-center">
              <span className="day mb-1">{billingDay}</span>
              <span>{billingMonth}</span>
              <span>{billingYear}</span>
            </div>
          </div>
          <h2>Upcoming worksheet, expense and proforma invoice deadline</h2>
          <p>
            For any worksheets, expenses or proforma invoices to be included in this invoice run,
            they must be submitted and approved by the end of this timer
          </p>
          <div className="countdown p-5 my-3 d-inline-block">{countDownString}</div>
          <TDButton
            className="my-5"
            label="Got it"
            onClick={closeModal}
            variant={BS_STYLE.PRIMARY}
          />
        </div>
      )}
      data-testid="service-order-cutoff-reminder-modal"
      noFooter
      onClose={closeModal}
      open={modalIsOpen}
      size={MODAL_SIZES.LARGE}
    />
  );
};

export default ServiceOrderCutoffReminder;
