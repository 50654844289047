import React from 'react';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW } from 'core/assets/js/config/settings';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { DOCUMENT_ESIGN_EVENTS } from 'documents/assets/js/constants';

const DocumentEsignReadyView = () => {
  const sendMessage = data => {
    WINDOW.parent.postMessage(data, '*');
  };
  return (
    <div className="p-5">
      <h2>Your contract template is ready to publish</h2>
      <p>
        If you have completed the field mark-up of your Contract tTemplate, you can now Publish it
        so that it may be used when inviting Providers to the Organisation or Tasks.
      </p>
      <TDSystemMessage className="mb-4 mt-4" title="Note" type={BS_STYLE.WARNING}>
        Once you publish a contract template it is not longer editable. You will need to
        create a new version of the contract template in order to make changes.
      </TDSystemMessage>
      <div className="pr-4 pl-4 row mb-5">
        <div className="col-3 mt-4 px-0 d-flex">
          <TDButton onClick={() => sendMessage(DOCUMENT_ESIGN_EVENTS.CANCEL)}>
            Cancel
          </TDButton>
        </div>
        <div className="col-9 mt-4 text-right px-0 d-flex justify-content-end">
          <TDButton onClick={() => sendMessage(DOCUMENT_ESIGN_EVENTS.BACK)}>
            Back
          </TDButton>
          <TDButton onClick={() => sendMessage(DOCUMENT_ESIGN_EVENTS.SAVE_DRAFT)}>
            Save draft
          </TDButton>
          <TDButton
            onClick={() => sendMessage(DOCUMENT_ESIGN_EVENTS.PUBLISH)}
            variant={BS_STYLE.PRIMARY}
          >
            Publish
          </TDButton>
        </div>
      </div>
    </div>
  );
};

export default DocumentEsignReadyView;
