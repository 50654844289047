import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import PasswordField from 'core/assets/js/components/ReduxFormFields/PasswordField.jsx';
import { validatePassword } from 'core/assets/js/lib/utils';

export const passwordChangeFormId = 'password-change-form';

function PasswordChangeForm() {
  return (
    <form>
      <div className="has-feedback">
        <Field
          name="password"
          type="password"
          component={PasswordField}
          validate={[validatePassword]}
          label="Password"
          placeholder=""
          showStrengthIndicator
        />

        <Field
          name="repeat_password"
          type="password"
          component={PasswordField}
          label="Repeat Password"
          placeholder=""
        />
      </div>
    </form>
  );
}

const PasswordChangeFormRedux = reduxForm(
  { form: passwordChangeFormId },
)(PasswordChangeForm);
export default connect()(PasswordChangeFormRedux);
