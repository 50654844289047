import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BS_STYLE, ICON } from 'core/assets/js/constants';

const ICON_MAPPING = {
  [BS_STYLE.DANGER]: ICON.ERROR,
  [BS_STYLE.INFO]: ICON.INFO_2,
  [BS_STYLE.SUCCESS]: ICON.SUCCESS,
  [BS_STYLE.WARNING]: ICON.WARNING,
};

/**
 *
 * @param {String|Node} title The heading showed on the System message component
 * @param {String} type The type of system message we want to show, e.g. informational, warning etc
 * @return {JSX.Element|null}
 */
const TDSystemMessage = ({
  children,
  className,
  dismissible,
  'data-testid': dataTestId,
  selectedIcon,
  title,
  type,
}) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const icon = selectedIcon
    ? <i className={[selectedIcon, 'mr-2 system-message__title-icon'].join(' ')} />
    : <i className={[ICON_MAPPING[type], 'mr-2 system-message__title-icon'].join(' ')} />;

  const classNames = ['system-message', `system-message--${type}`, className];

  if (!children) {
    classNames.push('system-message--large-icon');
  }

  if (isDismissed) {
    return null;
  }

  return (
    <div
      className={classNames.join(' ')}
      data-testid={dataTestId}
    >
      {title && (
        <div data-testid="system-message-invitation" className="system-message__title d-flex align-items-center">
          {icon}
          {title}
          {dismissible && (
            <a
              onClick={() => setIsDismissed(true)}
              className="system-message__close-button"
            >
              <i className={ICON.CROSS} />
            </a>
          )}
        </div>
      )}

      { children && (
        <div className="system-message__content">
          { children }
        </div>
      )}
    </div>
  );
};

TDSystemMessage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  dismissible: PropTypes.bool,
  'data-testid': PropTypes.string,
  selectedIcon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(Object.values(BS_STYLE)),
};

TDSystemMessage.defaultProps = {
  children: null,
  className: '',
  'data-testid': '',
  dismissible: false,
  selectedIcon: null,
  title: '',
  type: BS_STYLE.INFO,
};

export default TDSystemMessage;
