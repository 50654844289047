import React from 'react';
import PropTypes from 'prop-types';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { STATUS as INVITATION_STATUS } from 'invitations/assets/js/constants';
import { PROJECT_MEMBER_STATUS, PROJECT_MEMBER_STATUS_CLASS, PROJECT_MEMBER_STATUS_LABEL } from 'projects/assets/js/constants';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import InvitationStatus from 'invitations/assets/js/components/InvitationStatus.jsx';


const ProjectMemberStatus = ({ status, lastInvitation }) => {
  if (status === PROJECT_MEMBER_STATUS.ACCEPTED) {
    return null;
  }
  let showInvitationStatus = false;

  if (lastInvitation) {
    const hasExpiredInvitation = lastInvitation.hasExpired;
    const hasPendingInvitation = lastInvitation.status === INVITATION_STATUS.PENDING && (
      !lastInvitation.hasExpired
    );
    const hasCancelledInvitation = lastInvitation.status === INVITATION_STATUS.CANCELLED;
    const hasRejectedInvitation = lastInvitation.status === INVITATION_STATUS.REJECTED;
    if (hasExpiredInvitation
      || hasPendingInvitation
      || hasCancelledInvitation
      || hasRejectedInvitation
    ) {
      showInvitationStatus = true;
    }
  }

  return (
    <React.Fragment>
      {showInvitationStatus ? (
        <InvitationStatus
          status={lastInvitation.status}
          reason={lastInvitation.statusReason}
          hasExpired={lastInvitation.hasExpired}
        />
      ) : (
        <div className={['onboarding-status mx-n4', `onboarding-status--${PROJECT_MEMBER_STATUS_CLASS[status]}`].join(' ')}>
          <StatusTag
            hideTitle
            statusClass={PROJECT_MEMBER_STATUS_CLASS[status]}
            label={PROJECT_MEMBER_STATUS_LABEL[status]}
          />
        </div>
      )}
    </React.Fragment>
  );
};

ProjectMemberStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(PROJECT_MEMBER_STATUS)),
  lastInvitation: invitationSpec,
};

ProjectMemberStatus.defaultProps = {
  status: PROJECT_MEMBER_STATUS.ACCEPTED,
  lastInvitation: null,
};

export default ProjectMemberStatus;
