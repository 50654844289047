import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EVENT_KEY } from 'core/assets/js/constants';

export const UPDATE_ON_BLUR = 'blur';
export const UPDATE_MANUALLY = 'manually';

/**
 * Provides a field which is "editable" meaning that its value is on plain display
 * but when clicking on it, (or activating it through its API), it becomes an editable input value.
 *
 * There are two main modes: `viewable` and `editable` which have the corresponding callbacks
 *
 * It supports two update modes:
 *
 * - **UPDATE_ON_BLUR**:  which switches the item back to its 'display' state when the user blurs
 *                        out of the input field. During this `updateMode`,
 *                        the property `isEditable` acts as initial value, meaning that the field
 *                        will initially be editable if the prop is set to `true` but after that,
 *                        the component's state takes over.
 *
 * - **UPDATE_MANUALLY**: which requires the prop `isEditable` to switch to `false` in order for
 *                        the field to switch states. The `isEditable` prop, overrides any changes
 *                        to the component's state when the `updateMode` is set to `UPDATE_MANUALLY`
 *
 * @note in all cases, if the input field has an error, its state will not switch to "viewable"
 *       and it will remain to editable.
 *
 * @param {Object}
 * @returns {Node}
 */
const EditableValueField = ({
  input,
  type,
  disabled,
  placeholder,
  className,
  isEditable,
  updateMode,
  editModeClassName,
  viewingModeClassName,
  onValueRemoved,
  onValueUpdated,
  onEditable,
  onViewable,
  meta,
  meta: { error, submitError },
  ...rest
}) => {
  const hasError = !!error || !!submitError;
  const [isFieldFocused, setisFieldFocused] = useState(isEditable || !input.value || hasError);

  let isFieldEditable = false;
  if (hasError) {
    isFieldEditable = true;
  } else if (updateMode === UPDATE_MANUALLY) {
    isFieldEditable = !!isEditable;
  } else {
    isFieldEditable = !!isFieldFocused;
  }

  // override editability when the field is disabled
  isFieldEditable = isFieldEditable && !disabled;

  const handleMakeEditable = () => {
    if (disabled) {
      return false;
    }

    onEditable();
    setisFieldFocused(true);
    return true;
  };

  const handleMakeViewable = (event) => {
    if (hasError) {
      return false;
    }

    if (input.value !== '') {
      onViewable(event);
      return setisFieldFocused(false);
    }

    return onValueRemoved();
  };

  const handleKeyDown = (e) => {
    e.persist();

    if (EVENT_KEY.BACKSPACE === e.key && input.value === '') {
      return onValueRemoved();
    }

    if (e.key === EVENT_KEY.ENTER || e.key === EVENT_KEY.TAB) {
      onValueUpdated(e, input.value);

      if (updateMode === UPDATE_ON_BLUR) {
        handleMakeViewable(e);
      }

      return true;
    }

    if (e.key === EVENT_KEY.ESCAPE) {
      return handleMakeViewable(e);
    }

    return true;
  };

  let classNames = ['editable-value', className];
  if (isFieldEditable) {
    classNames.push('form-control', 'editable-value--editing', editModeClassName);
  } else {
    classNames.push('editable-value--viewing', viewingModeClassName);
  }

  if (disabled) {
    classNames.push('editable-value--disabled');
  }

  if (hasError) {
    classNames.push('has-error');
  }

  classNames = classNames.filter(c => !!c).join(' ');

  const inputProps = {
    id: `fieldId-${input.name}`,
    ...input,
    placeholder,
    className: classNames,
    onKeyDown: handleKeyDown,
    autoFocus: !!isFieldEditable,
    disabled,
    ...rest,
  };

  // add the `onBlur` event handler
  if (updateMode === UPDATE_ON_BLUR) {
    inputProps.onBlur = handleMakeViewable;
  }

  const containerClassNames = [
    'editable-value__container',
    'd-flex',
    'flex-wrap',
    `update-mode-${updateMode}`,
  ];

  if (hasError) {
    containerClassNames.push('has-error');
  }

  return (
    <React.Fragment>
      { isFieldEditable && (
        <div className={containerClassNames.join(' ')}>
          { type !== 'textarea' && (
            <input
              type={type}
              {...inputProps}
            />
          )}

          {type === 'textarea' && (
            <textarea
              {...inputProps}
            />
          )}

          { hasError && (
            <span className="help-block">{error || submitError}</span>
          )}
        </div>
      )}
      { !isFieldEditable && (
        <a
          onKeyUp={handleMakeEditable}
          onClick={handleMakeEditable}
          className={classNames}
        >
          {input.value}
          <input type="hidden" name={input.name} value={input.value} />
        </a>
      )}
    </React.Fragment>
  );
};

EditableValueField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  updateMode: PropTypes.oneOf([UPDATE_ON_BLUR, UPDATE_MANUALLY]),
  isEditable: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  editModeClassName: PropTypes.string,
  viewingModeClassName: PropTypes.string,
  onValueRemoved: PropTypes.func,
  onValueUpdated: PropTypes.func,
  onEditable: PropTypes.func,
  onViewable: PropTypes.func,
};

EditableValueField.defaultProps = {
  type: 'text',
  disabled: false,
  placeholder: null,
  className: null,
  editModeClassName: null,
  viewingModeClassName: null,
  isEditable: false,
  updateMode: UPDATE_ON_BLUR,
  onValueRemoved: () => {},
  onValueUpdated: () => {},
  onEditable: () => {},
  onViewable: () => {},
};

export default EditableValueField;
