import React from 'react';
import { uniqBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { ICON } from 'core/assets/js/constants';


export const CHECKED_CLASS_NAME = 'checkable-list__option--checked';
export const INITIAL_VALUE_CLASS = 'checkable-list__option--initially-selected';

/**
 * emptyItemsMessage - Message shown when there are no items in the list
 * multipleSelection - Enables multiple selection mode
 * itemComponentProps - Props which will be passed to the list item component
 */
class CheckableList extends React.Component {
  constructor(props) {
    super(props);

    const { initialValue, defaultValue } = this.props;
    const initializedValue = (initialValue && [initialValue]) || (defaultValue && [defaultValue]);
    this.state = {
      // Select initial value item if initialValue is set.
      checkedItems: initializedValue || [],
    };

    this.handleItemChecked = this.handleItemChecked.bind(this);
  }

  componentDidMount() {
    const { checkedItems } = this.state;
    const { onItemChecked, multipleSelection } = this.props;

    if (!multipleSelection && !isEmpty(checkedItems)) {
      onItemChecked(checkedItems[0]);
    } else {
      checkedItems.forEach(it => onItemChecked(it));
    }
  }

  handleItemChecked(selectedItem) {
    const { items, onItemChecked, multipleSelection } = this.props;

    if (multipleSelection) {
      const { checkedItems: prevSelections } = this.state;

      const alreadySelected = prevSelections.some(it => it.id === selectedItem.id);
      let newSelections;
      if (alreadySelected) {
        newSelections = prevSelections.filter(selection => selection.id !== selectedItem.id);
      } else {
        newSelections = uniqBy([...prevSelections, selectedItem], 'id');
      }

      this.setState({
        checkedItems: newSelections,
      });
    } else {
      this.setState({
        checkedItems: [items.find(it => it.id === selectedItem.id)],
      });
    }

    onItemChecked(selectedItem);
  }

  isItemChecked(selectedItem) {
    const { checkedItems } = this.state;
    return checkedItems.some(it => it.id === selectedItem.id);
  }

  render() {
    const {
      className,
      'data-testid': dataTestId,
      disabledItems,
      emptyItemsIcon,
      emptyItemsMessage,
      initialValue,
      items,
      wrapperClassName,
    } = this.props;

    if (!items) {
      return null;
    }

    if (items.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
          <i className={emptyItemsIcon} />
          {emptyItemsMessage}
        </div>
      );
    }

    const {
      itemComponent, itemComponentProps,
    } = this.props;

    const Component = itemComponent;
    let idx = 0;

    const itemsEl = items.map((item) => {
      idx += 1;

      const classNames = ['checkable-list__option cursor-pointer'];

      if (this.isItemChecked(item)) {
        classNames.push(CHECKED_CLASS_NAME);
      }

      if (initialValue && initialValue.id === item.id) {
        classNames.push(INITIAL_VALUE_CLASS);
      }

      if (wrapperClassName) {
        classNames.push(wrapperClassName);
      }

      const disabledItemIdx = disabledItems.findIndex(_item => _item.id === item.id);

      const isDisabled = disabledItemIdx > -1;

      if (isDisabled) {
        classNames.push('checkable-list__option--disabled');
      }

      const listItem = (
        <li
          data-testid="checkable-list-select-org-for-payment-method"
          key={`checkable-list-item-${idx}`}
          id={`checkable-list-item-${item.id}`}
          className={classNames.join(' ')}
        >
          <a
            className="p-4"
            onClick={() => {
              if (!isDisabled) {
                this.handleItemChecked(item);
              }
            }}
          >
            <div className="checkable-list__item">
              { item && (
                <Component item={item} {...itemComponentProps} />
              )}
            </div>
            <div className="checkable-list__indicator">
              <span className={ICON.SUCCESS} />
            </div>
          </a>
        </li>
      );

      // if disabled and has a message, wrap the element with a tooltip
      if (isDisabled && disabledItems[disabledItemIdx].reason) {
        return (
          <TDElementWithTooltip
            key={`checkable-list-item-${idx}`}
            delay={500}
            tooltipMsg={disabledItems[disabledItemIdx].reason}
          >
            {listItem}
          </TDElementWithTooltip>
        );
      }

      return listItem;
    });

    return (
      <ul
        className={`checkable-list__container${className ? ` ${className}` : ''}`}
        data-testid={dataTestId}
      >
        {itemsEl}
      </ul>
    );
  }
}

CheckableList.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultValue: PropTypes.object,
  disabledItems: PropTypes.arrayOf(PropTypes.object),
  emptyItemsIcon: PropTypes.string,
  emptyItemsMessage: PropTypes.string,
  initialValue: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })).isRequired,
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  itemComponentProps: PropTypes.object,
  multipleSelection: PropTypes.bool,
  onItemChecked: PropTypes.func,
  wrapperClassName: PropTypes.string,
};

CheckableList.defaultProps = {
  className: null,
  'data-testid': null,
  defaultValue: null,
  disabledItems: [],
  emptyItemsIcon: ICON.FILE_CHART_LINE,
  emptyItemsMessage: 'The list is currently empty.',
  initialValue: null,
  itemComponentProps: {},
  multipleSelection: false,
  onItemChecked: () => {},
  wrapperClassName: null,
};

export default CheckableList;
