import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';

import { parse as queryParser } from 'query-string';
import SkillSearchForm from 'core/assets/js/components/SkillSearchForm.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import SkillSearchResults from 'core/assets/js/components/SkillSearchResults.jsx';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { languageSearchApiUrl } from 'skills/urls';
import SkillSelectSectionSkeleton from 'core/assets/js/components/Skeleton/SkillSelectSectionSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import { getIsModalOpen, modalCloseAC, getModalPayload } from 'core/assets/js/ducks/modalLauncher';

export const MODAL_ID = 'skills-select-modal';
const SEARCH_KEYWORD_MIN_LENGTH = 2;

class LanguageSelectModalPanel extends React.Component {
  static GetComponentName() {
    return 'LanguageSelectModalPanel';
  }

  constructor(props) {
    super(props);
    this.props = props;
    this.constantFilters = {
      paginationEnabled: false,
    };

    const { initiallySelected } = this.props;

    const _initiallySelected = initiallySelected || [];
    this.state = {
      query: {},
      showSelected: false,
      selectedItems: [..._initiallySelected],
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleToggleItem = this.handleToggleItem.bind(this);
    this.isItemSelected = this.isItemSelected.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.initValues = this.initValues.bind(this);
    this.getSelectedItem = this.getSelectedItem.bind(this);
    this.handleRemoveLang = this.handleRemoveLang.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { initiallySelected } = this.props;
    if (initiallySelected !== prevProps.initiallySelected) {
      const _initiallySelected = initiallySelected || [];
      this.initValues([..._initiallySelected]);
    }
  }

  getSelectedItem({ id }) {
    const { selectedItems } = this.state;
    if (!selectedItems) {
      return null;
    }

    const selectedItem = selectedItems.filter(({ id: itemId }) => itemId === id)[0];

    return selectedItem;
  }

  isItemSelected({ id }) {
    const { selectedItems } = this.state;
    if (!selectedItems) {
      return false;
    }

    const isItemSelected = selectedItems.findIndex(({ id: itemId }) => (itemId === id)) > -1;

    return isItemSelected;
  }

  handleCloseModal() {
    const { dispatch, initiallySelected } = this.props;
    this.setState({
      selectedItems: initiallySelected,
      showSelected: false,
      query: {},
    });
    dispatch(modalCloseAC(MODAL_ID));
  }

  handleToggleItem(selectedItem) {
    const { selectedItems } = this.state;

    const idx = selectedItems ? selectedItems.findIndex(({ id }) => id === selectedItem.id) : -1;
    if (idx > -1) {
      // Remove Item
      this.handleRemoveLang(selectedItem.id);
    } else {
      // Add Item
      this.setState({ selectedItems: [...selectedItems, selectedItem] });
    }
  }

  initValues(values) {
    this.setState({
      selectedItems: values,
    });
  }

  handleQueryUpdate(newQuery) {
    this.setState({ query: { ...this.constantFilters, ...newQuery } });
  }

  handleRemoveLang(langId) {
    const { selectedItems } = this.state;
    this.setState({ selectedItems: selectedItems.filter(lang => lang.id !== langId) });
  }


  render() {
    const {
      isOpen, handleSubmitSelected, lists, skillSearchResult,
    } = this.props;
    const {
      selectedItems, showSelected, query, query: { kw },
    } = this.state;
    const shouldShowSearchResults = kw && kw.length > SEARCH_KEYWORD_MIN_LENGTH;
    const type = 'language';

    return (
      <ModalPanel
        modalId={MODAL_ID}
        open={isOpen}
        onClose={this.handleCloseModal}
        heading="Update your languages"
        body={(
          <div className="skill-select-panel no-select">
            { !showSelected && (
              <React.Fragment>
                <div className="row">
                  <div className="col-12 col-md-5 ml-auto px-0 px-md-3">
                    <SkillSearchForm
                      initialValues={{ kw }}
                      query={query}
                      onFiltersChanged={this.handleQueryUpdate}
                    />
                  </div>
                </div>

                {!shouldShowSearchResults && (
                  <TDApiConnected
                    duck="list"
                    storeKey="languages-list"
                    shouldRefetchOnQueryChange={false}
                    fetchData={
                      ({ dispatch, authedAxios, componentName }) => {
                        const url = languageSearchApiUrl({ term: '', paginationEnabled: false });
                        return dispatch(fetchListDS({
                          url, componentName, authedAxios,
                        }));
                      }}
                    skeletonComponent={SkillSelectSectionSkeleton}
                  >
                    <div className="skill-list mb-n5">
                      { lists['languages-list'] && lists['languages-list'].items && (
                        <SkillSearchResults
                          skills={lists['languages-list'].items}
                          isSkillSelected={this.isItemSelected}
                          onSkillClick={this.handleToggleItem}
                        />
                      )}
                    </div>
                  </TDApiConnected>
                )}
              </React.Fragment>
            )}

            <TDApiConnected
              duck="list"
              storeKey={LanguageSelectModalPanel.GetComponentName()}
              fetchData={
                ({ dispatch, authedAxios, componentName, querystring }) => {
                  const { kw: term } = queryParser(querystring);
                  if (!term || term.length <= SEARCH_KEYWORD_MIN_LENGTH) {
                    return Promise.resolve();
                  }
                  const url = languageSearchApiUrl({
                    term,
                    paginationEnabled: false,
                  });

                  return dispatch(fetchListDS({
                    url, componentName, authedAxios,
                  }));
                }}
              loadingEnabled={false}
              skeletonComponent={SkillSelectSectionSkeleton}
              query={query}
            >
              { shouldShowSearchResults && (
                <SkillSearchResults
                  skills={skillSearchResult}
                  isSkillSelected={this.isItemSelected}
                  onSkillClick={this.handleToggleItem}
                  getSelectedItem={this.getSelectedItem}
                />
              )}
            </TDApiConnected>

            {showSelected && (
              <div className="skill-list mb-n5">
                {selectedItems && selectedItems.length === 0 && (
                  <p className="discreet">You haven&apos;t selected any language yet</p>
                )}
                {selectedItems && selectedItems.length !== 0 && (
                  <SkillSearchResults
                    skills={selectedItems}
                    isSkillSelected={this.isItemSelected}
                    onSkillClick={this.handleToggleItem}
                    getSelectedItem={this.getSelectedItem}
                  />
                )}
              </div>
            )}
          </div>
        )}
        footer={(
          <React.Fragment>
            <div>
              <label
                onClick={() => {
                  this.setState({ showSelected: !showSelected, query: {} });
                }}
                className={`d-flex align-items-center mb-5 mb-md-0 cursor-pointer ${selectedItems.length === 0 ? 'discreet' : ''}`}
              >
                <TDSwitch
                  disabled={!selectedItems || selectedItems.length === 0}
                  className="mr-3"
                  selected={showSelected}
                />
                {`Show selected ${pluralize(type, selectedItems.length)}`}
              </label>
            </div>

            <div className="ml-auto">
              <TDButton
                variant={BS_STYLE.DEFAULT}
                onClick={this.handleCloseModal}
                label="Close"
              />

              <TDButton
                className="ml-4"
                variant={BS_STYLE.PRIMARY}
                disabled={!selectedItems} // Allow the selection of empty array
                onClick={() => {
                  this.handleCloseModal();
                  handleSubmitSelected(selectedItems);
                }}
                label={
                  `Select ${selectedItems.length > 0 ? ` ${selectedItems.length} ` : ''} ${pluralize(type, selectedItems.length)}`}
              />
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}
LanguageSelectModalPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  skillSearchResult: PropTypes.array,
  handleSubmitSelected: PropTypes.func.isRequired,
  lists: PropTypes.object.isRequired,
  initiallySelected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
LanguageSelectModalPanel.defaultProps = {
  initiallySelected: [],
  skillSearchResult: [],
};

const mapStateToProps = (state, props) => {
  const skillListState = getListState(state, LanguageSelectModalPanel.GetComponentName());
  const modalPanelPayload = getModalPayload(state, MODAL_ID);
  return {
    skillSearchResult: skillListState.items,
    isOpen: getIsModalOpen(state, MODAL_ID),
    lists: state.list,
    selectionFilter: modalPanelPayload?.selectionFilter,
    initiallySelected: modalPanelPayload?.initiallySelected || props.initiallySelected,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });
const LanguageSelectModalPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguageSelectModalPanel);

export default LanguageSelectModalPanelConnected;
