import React from 'react';

import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const TWBankDetailFieldsSkeleton = () => (
  <>
    <div className="row mb-4">
      <div className="col-12">
        <h2 className="mb-4">Bank details</h2>
        <div className="d-flex justify-content-start mb-3">
          <RectangleSkeleton height="20" width="300" />
        </div>
      </div>
    </div>
    <div className="mt-4 row">
      <div className="col-12">
        <h3 className="heading-block mb-3">Recipient bank details</h3>
        <hr />
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-start mb-3">
          <RectangleSkeleton height="20" width="100" />
          <RectangleSkeleton height="20" width="100" />
        </div>
        <hr />
      </div>
      <div className="row mx-0 mb-3">
        <div className="col-6">
          <RectangleSkeleton height="15" width="100" className="d-block mb-3" />
          <RectangleSkeleton height="35" width="200" className="ml-0" />
        </div>
        <div className="col-6">
          <RectangleSkeleton height="15" width="100" className="d-block mb-3" />
          <RectangleSkeleton height="35" width="200" className="ml-0" />
        </div>
      </div>
    </div>
    <div className="mt-4 row">
      <div className="col-12">
        <h3 className="heading-block mb-3">Residential address details</h3>
        <hr />
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-start mb-3">
          <RectangleSkeleton height="20" width="100" />
          <RectangleSkeleton height="20" width="100" />
        </div>
        <hr />
      </div>
      <div className="row mx-0 mb-3">
        <div className="col-6">
          <RectangleSkeleton height="15" width="100" className="d-block mb-3" />
          <RectangleSkeleton height="35" width="200" className="ml-0" />
        </div>
        <div className="col-6">
          <RectangleSkeleton height="15" width="100" className="d-block mb-3" />
          <RectangleSkeleton height="35" width="200" className="ml-0" />
        </div>
      </div>
    </div>
  </>
);

export default TWBankDetailFieldsSkeleton;
