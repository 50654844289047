/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';

import { isSSR } from 'core/assets/js/config/checks';
import { REACT_SELECT_STYLES, OTHER_VALUE } from 'core/assets/js/constants';
import withField from 'core/assets/js/components/withField.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import { DOCUMENT_BODY } from 'core/assets/js/config/settings';

const getSelectedOptions = (value, options) => {
  const selectedValues = Array.isArray(value) ? value.map(v => v.value) : [value.value];

  return options.filter(opt => selectedValues.includes(opt.value));
};

const transformAnswersToSelections = options => (
  options.map(opt => ({
    ...opt,
    label: opt.raw || opt.text,
  }))
);

const SelectionField = ({
  additionalError,
  input,
  label,
  sublabel,
  optionsMapping,
  isSearchable,
  required,
  isMultiple,
  hasOther,
  valueTransformer,
  meta: { error, pristine, submitError },
  disabled,
}) => {
  const showAdditionalError = pristine && additionalError;
  const hasError = !!error || !!submitError || showAdditionalError;
  const groupClassName = ['selection-field form-group'];
  if (hasError) {
    groupClassName.push('has-error');
  }

  if (disabled) {
    groupClassName.push('selection-field-disabled');
  }

  let selectedValues = input && input.value ? input.value : [];
  if (
    !Array.isArray(selectedValues)
    && typeof selectedValues === 'object'
    && selectedValues
    && Object.keys(selectedValues).includes('value')
  ) {
    // The passed in value is a single object, but the rest of the component expects it to be an
    // array
    selectedValues = [selectedValues];
  }

  const otherValue = selectedValues && selectedValues.find(option => option.value === OTHER_VALUE);
  if (otherValue) {
    optionsMapping.push(...transformAnswersToSelections([otherValue]));
  }

  const [userInput, setUserInput] = useState(null);
  const [selections, setSelections] = useState(
    transformAnswersToSelections(getSelectedOptions(selectedValues, optionsMapping)),
  );
  const reactSelectOptions = transformAnswersToSelections(optionsMapping);

  const handleChange = (items, { action, removedValue }) => {
    const currentItems = items && !Array.isArray(items) ? [items] : items;

    let newSelections;
    let createdOption;

    switch (action) {
      case 'select-option':
      case 'set-value':
        newSelections = currentItems;
        break;
      case 'deselect-option':
      case 'remove-value':
      case 'pop-value':
        if (removedValue?.value === OTHER_VALUE) {
          setUserInput(null);
        }

        newSelections = currentItems || [];
        break;
      case 'clear':
        newSelections = [];
        break;
      case 'create-option':
        newSelections = currentItems.filter(opt => !opt.__isNew__);
        createdOption = currentItems.find(opt => opt.__isNew__);

        if (createdOption) {
          newSelections.push({
            value: OTHER_VALUE,
            label: `Other: ${createdOption.value}`,
            text: 'Other',
            userInput: createdOption.value,
            __isNew__: true,
          });
        }

        setUserInput(createdOption.value);
        break;
      default:
        newSelections = [];
        break;
    }

    setSelections(newSelections);

    input.onChange(newSelections.map((sel) => {
      const val = {
        value: sel.value,
        text: (sel.label || sel.raw || sel.text),
      };

      if (sel.userInput) {
        Object.assign(val, { userInput: sel.userInput });
      }

      return valueTransformer ? valueTransformer(val) : val;
    }));
  };

  useEffect(() => {
    setSelections(transformAnswersToSelections(getSelectedOptions(selectedValues, optionsMapping)));
  }, [input.value]);

  const SelectComponent = hasOther && !userInput ? CreatableSelect : Select;

  return (
    <div className={groupClassName.join(' ')}>
      {label && (
        <TDLabel
          name={input.name}
          label={label}
          required={required}
          sublabel={sublabel}
        />
      )}

      {showAdditionalError && (
        <span className="help-block">{additionalError}</span>
      )}

      <SelectComponent
        styles={REACT_SELECT_STYLES}
        name={input.name}
        menuPlacement="auto"
        isSearchable={isSearchable}
        menuPortalTarget={!isSSR ? DOCUMENT_BODY : undefined}
        classNamePrefix="react-select"
        id={`${input.name}-dropdown`}
        value={selections}
        options={reactSelectOptions}
        isClearable={isMultiple || !required}
        isDisabled={reactSelectOptions.length === 0}
        components={{ SingleValue: components.SingleValue }}
        placeholder={input.placeholder}
        onChange={handleChange}
        formatCreateLabel={value => `Other: ${value}`}
        getOptionValue={opt => opt.value}
        isMulti={isMultiple}
        disabled={disabled}
      />

      {hasError && (
        <span className="help-block d-inline-block mt-3">{error || submitError}</span>
      )}
    </div>
  );
};

SelectionField.propTypes = {
  additionalError: PropTypes.string,
  disabled: PropTypes.bool,
  hasOther: PropTypes.bool,
  input: PropTypes.object.isRequired,
  isMultiple: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  meta: PropTypes.shape({
    error: PropTypes.string,
    pristine: true,
    submitError: null,
  }),
  optionsMapping: PropTypes.array,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
  valueTransformer: PropTypes.func,
};

SelectionField.defaultProps = {
  additionalError: '',
  disabled: false,
  hasOther: false,
  isMultiple: false,
  isSearchable: true,
  label: null,
  meta: {
    error: null,
    pristine: true,
    submitError: null,
  },
  optionsMapping: [],
  required: false,
  sublabel: null,
  valueTransformer: null,
};

export default withField(SelectionField);
