import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useRouteMatch, useHistory } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { financeInvoicesUrl } from 'finance/urls';
import Money from 'finance/assets/js/lib/Money';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';

const DeferredPaymentsSettingEnableBody = ({
  pendingDeferredPaymentsMeta,
  hasPendingDeferredPayments,
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { params: { orgAlias } } = match;
  if (isEmpty(pendingDeferredPaymentsMeta)) {
    return (
      <>
        <RectangleSkeleton width="300" height="25" />
        <br />
        <br />
        <RectangleSkeleton width="200" height="25" />
      </>
    );
  }

  const pendingPaymentsAmounts = Object
    .keys(pendingDeferredPaymentsMeta.perCurrency)
    .map(currency => (
      new Money(pendingDeferredPaymentsMeta.perCurrency[currency].totalAmount, currency)
        .toString({ withSymbol: true })
    ));
  const formattedPendingPayments = pendingPaymentsAmounts.length > 1
    ? `${pendingPaymentsAmounts.slice(0, -1).join(', ')} and ${pendingPaymentsAmounts.slice(-1)}`
    : pendingPaymentsAmounts[0];


  if (hasPendingDeferredPayments) {
    return (
      <div className="mb-5">
        <div className="mb-3">
          {'You need to '}
          <strong>request a payout</strong>
          {' for your payments before you can enable Instant payouts.You have payments due of'}
          {` ${formattedPendingPayments}.`}
        </div>
        <p>Go to your invoices page and click on a button “Request payout”.</p>
        <p>
          After you have requested a payout, come back to your profile payment methods tab
          and enable Instant payouts.
        </p>
        <TDButton
          variant={BS_STYLE.PRIMARY}
          className="d-block m-auto"
          label="Go to Invoices"
          onClick={() => (
            history.push(financeInvoicesUrl(orgAlias))
          )}
        />
      </div>
    );
  }
  return 'Are you sure you want to enable Instant payouts?';
};

DeferredPaymentsSettingEnableBody.propTypes = {
  pendingDeferredPaymentsMeta: PropTypes.object.isRequired,
  hasPendingDeferredPayments: PropTypes.bool.isRequired,
};

export default DeferredPaymentsSettingEnableBody;
