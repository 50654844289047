import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import ReportSalesTax from 'finance/assets/js/components/ReportSalesTax.jsx';
import { getReportInfo } from 'settings/assets/js/lib/sales_tax_utils';

class ReportPerson extends React.PureComponent {
  render() {
    const { financialEntity, withJobTitle, company, withAddress, withTaxRate } = this.props;

    if (!(financialEntity instanceof FinancialEntity)) {
      throw new Error('expecting financial entity');
    }

    if (isEmpty(financialEntity)) {
      return null;
    }
    const reportSalesTaxInfo = getReportInfo(company);

    const fullLegalName = get(company, 'full_legal_name');
    const name = financialEntity.getFullProfileName();
    const jobTitle = financialEntity.getJobTitle();
    const formattedAddress = financialEntity.getProfileAddress();

    let title = (company && fullLegalName) || name;
    if (withJobTitle && jobTitle) {
      title += ` - ${jobTitle}`;
    }
    return (
      <div>
        <h4>{ title }</h4>
        { withAddress && formattedAddress
          && (
            <div className="finance-report__provider__company">
              <div>
                <span>{formattedAddress}</span>
              </div>
            </div>
          )
        }
        {withTaxRate && (
          <ReportSalesTax
            {...reportSalesTaxInfo}
          />
        )}
      </div>
    );
  }
}

ReportPerson.propTypes = {
  company: PropTypes.object,
  financialEntity: PropTypes.object,
  withAddress: PropTypes.bool,
  withJobTitle: PropTypes.bool,
  withTaxRate: PropTypes.bool,
};

ReportPerson.defaultProps = {
  company: {},
  financialEntity: null,
  withAddress: false,
  withJobTitle: false,
  withTaxRate: false,
};

export default ReportPerson;
