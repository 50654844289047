import React from 'react';
import PropTypes from 'prop-types';
import { filter as _filter, without, isString, isNumber, isObject, isEmpty, isArray, isEqual } from 'lodash';

import TDRadio from 'core/assets/js/components/TDRadio.jsx';
import TDCheckbox from 'core/assets/js/components/TDCheckbox.jsx';
import withField from 'core/assets/js/components/withField.jsx';

class SelectableListFilterField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: null,
    };

    // If options are more than this number, the filtering feature automatically is enabled.
    this.enableFilteringItemCount = 10;
    this.handleChange = this.handleChange.bind(this);
    this.isActive = this.isActive.bind(this);
    this.isFilteringEnabled = this.isFilteringEnabled.bind(this);
  }

  handleChange(selectedValue) {
    const { input, input: { value }, filter: { multiple } } = this.props;
    // In case newValue is not a string, cast it as one (ie: status Ids)
    const newValue = !isString(selectedValue) ? JSON.stringify(selectedValue) : selectedValue;

    // Check if the newValue is already in the list of selected values
    const currentValue = (Array.isArray(value) ? value : [value]).filter(
      v => isNumber(v) || (isString(v) && v !== '') || ((isObject(v) || isArray(v)) && !isEmpty(v)),
    ).map(
      v => (!isString(v) ? JSON.stringify(v) : v),
    );

    const isSelectedValue = currentValue.indexOf(newValue);
    let _value;

    // Multiple selection is allowed
    if (multiple) {
      _value = isSelectedValue === -1
        ? [...currentValue, newValue]
        : without(currentValue, newValue);
    } else {
      // Only a single selection is allowed
      _value = isSelectedValue === -1
        ? [newValue]
        : [];
    }

    // Update redux form
    input.onChange(_value);
  }

  isFilteringEnabled() {
    const { filter: { options } } = this.props;
    return options.length > this.enableFilteringItemCount;
  }

  isActive(option) {
    const { input: { value } } = this.props;

    if (isArray(value)) {
      return value.map(v => String(v)).includes(String(option.value));
    }

    return isEqual(String(option.value), String(value));
  }

  render() {
    const { filter: { options, label, paramName, multiple }, input } = this.props;
    const { filterText } = this.state;
    const isFilteringEnabled = this.isFilteringEnabled();
    let filteredOptions = [].concat(options);

    if (filterText) {
      filteredOptions = _filter(
        filteredOptions,
        o => o.text.toLowerCase().includes(filterText.toLowerCase(),
        ));
    }

    let optionsList = filteredOptions.map((option) => {
      return (
        <li key={`${paramName}-${option.value}`}>
          {multiple && (
            <label
              className="d-flex align-items-center"
              onClick={() => this.handleChange(option.value)}
            >
              <TDCheckbox checked={this.isActive(option)} />
              <span>{option.text}</span>
            </label>
          )}

          {!multiple && (
            <TDRadio
              onClick={() => this.handleChange(option.value)}
              className="ml-0"
              selected={this.isActive(option)}
              label={option.text}
            />
          )}
        </li>
      );
    });

    if (!optionsList.length) {
      optionsList = [<li><i className="discreet">No items found.</i></li>];
    }

    return (
      <React.Fragment>
        <h4>{label}</h4>

        { this.isFilteringEnabled() && (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <input
                type="text"
                className="filter-input w-100"
                placeholder="Start typing..."
                onChange={(e) => {
                  this.setState({
                    filterText: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        )}

        <div className="form-control-container">
          <ul className={`selectable-list ${isFilteringEnabled ? 'selectable-list--multiple' : ''}`}>
            {optionsList}
          </ul>
        </div>

        <input
          {...input}
          className="form-control"
          type="hidden"
        />
      </React.Fragment>
    );
  }
}

SelectableListFilterField.propTypes = {
  filter: PropTypes.object,
  input: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};
SelectableListFilterField.defaultProps = {
  filter: {},
};

export default withField(SelectableListFilterField);
