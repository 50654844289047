import React from 'react';
import { activityRecordSpec } from 'notifier/assets/js';
import SystemMessage from 'core/assets/js/components/DiscussionBoard/messages/SystemMessage.jsx';


const ActicityCard = ({ item }) => {
  const { body, createdAt, user: { profile } } = item;

  return (
    <SystemMessage message={body} timestamp={createdAt} profile={profile} />
  );
};

ActicityCard.propTypes = {
  item: activityRecordSpec.isRequired,
};

export default ActicityCard;
