import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_PAGE_SIZE } from 'settings/assets/js/constants';
import { expenseSpec } from 'finance/assets/js/lib/objectSpecs';
import { EXPENSE_STATUS_CLASS, EXPENSE_STATUS_LABEL, EXPENSE_STATUS } from 'projects/assets/js/constants';
import { fetchFinanceExpenseListDS } from 'finance/assets/js/data-services/list';
import { financeExpensesCreateUrl, financeExpensesUrl, financeExpenseViewUrl } from 'finance/urls';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { getListState } from 'core/assets/js/ducks/list';
import { prepareQueryString } from 'core/assets/js/lib/utils';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';
import ExpensesWidgetSkeleton from 'core/assets/js/components/Skeleton/ExpensesWidgetSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

export const COMPONENT_NAME = 'ExpensesWidget';

const ExpensesWidget = ({
  expenseAccessControl,
  expenseItems,
  hasOrgAccess,
  match: {
    params: { orgAlias },
  },
  history,
}) => {
  const isManager = hasOrgAccess({ requireManager: true });
  const headerActionBtn = {
    onClick: () => history.push(financeExpensesCreateUrl(orgAlias)),
    label: 'Submit expense',
    variant: BS_STYLE.PRIMARY,
  };

  const footerActionBtn = {
    onClick: () => history.push(financeExpensesUrl(orgAlias)),
    label: 'View expenses',
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: 'Get refunded for costs incurred by raising an expense claim',
    icon: ICON.RECEIPT_DUOTONE,
  };

  const expenseStatusColFormat = (expense) => {
    const {
      isInvoiced,
      isAutoApproved,
      isDelayed,
      processAt,
      statusCode,
    } = expense;

    const isApproved = statusCode === EXPENSE_STATUS.APPROVED;
    const statusLabel = EXPENSE_STATUS_LABEL[statusCode];
    const title = '';
    const statusClass = EXPENSE_STATUS_CLASS[statusCode];

    if (isApproved && isInvoiced) {
      return (
        <StatusColFormat statusLabel="Invoiced" statusClass={EXPENSE_STATUS_CLASS[statusCode]} />
      );
    }

    if (isApproved && isAutoApproved && isManager) {
      return (
        <StatusColFormat
          statusLabel="Auto approved"
          statusClass="approved-auto"
          title="Auto approved by TalentDesk.io"
        />
      );
    }

    if (isDelayed) {
      return (
        <StatusColFormat
          statusLabel="Approved"
          statusClass="delayed"
          title={`The worksheet will not be processed prior to ${processAt}`}
        />
      );
    }

    return (
      <StatusColFormat
        statusClass={statusClass}
        statusLabel={statusLabel}
        title={title}
      />
    );
  };

  const widgetlTitle = 'Expenses';

  return (
    <WidgetWrapper
      footerActionBtn={footerActionBtn}
      headerActionBtn={expenseAccessControl.canClaim ? headerActionBtn : null}
      isEmpty={isEmpty(expenseItems)}
      placeholder={emptyPlaceholder}
      title={widgetlTitle}
      popOverTitle={widgetlTitle}
      popOverContent={(
        <>
          <p>
            Expenses are similar to worksheets, however, they should be created for reimbursement
            of expenses that have been incurred on behalf of the organization while carrying out
            project or task works.
          </p>
          <p>
            <a href="https://www.talentdesk.io/knowledge-base/finances/provider/raising-worksheets-and-expenses" target="_blank" rel="noreferrer">
              To learn more
              {' '}
              <span className="visually-hidden">
                {' '}
                about
                {' '}
                {widgetlTitle}
                ,
              </span>
              visit the Knowledge base
            </a>
          </p>
        </>
      )}
    >
      <div className="d-none d-md-block">
        <div className="row d-flex align-items-center mb-3">
          <div className="col-md-6">
            <p className="font-weight-bold">Period</p>
          </div>
          <div className="col-md-3">
            <p className="font-weight-bold">Amount</p>
          </div>
          <div className="col-md-3">
            <p className="font-weight-bold">Status</p>
          </div>
        </div>

        {expenseItems.map((expense) => {
          const { id, period, expenseAmount, expenseCurrencySymbol } = expense;
          return (
            <div className="row d-flex align-items-center mb-5" key={id}>
              <div className="col-md-6">
                <Link
                  to={financeExpenseViewUrl(orgAlias, id)}
                >
                  {period}
                </Link>
              </div>
              <div className="col-md-3">
                <NumberTpl value={expenseAmount} prefix={expenseCurrencySymbol} />
              </div>
              <div className="col-md-3">
                {expenseStatusColFormat(expense)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-block d-md-none">
        {expenseItems.map((expense, index) => {
          const { id, period, expenseAmount, expenseCurrencySymbol } = expense;
          const pt = index === 0 ? 3 : 5;
          const pb = index === expenseItems.length - 1 ? 3 : 5;
          return (
            <div className={`row mx-0 pt-${pt} pb-${pb} px-0 widget-item__container`} key={id}>
              <div className="col-md-12 px-0 pb-5">
                <p className="font-weight-bold mb-1">Period</p>
                {period}
              </div>
              <div className="col-md-12 px-0 pb-5">
                <p className="font-weight-bold mb-1">Amount</p>
                <NumberTpl value={expenseAmount} prefix={expenseCurrencySymbol} />
              </div>
              <div className="col-md-12 px-0 pb-5">
                <p className="font-weight-bold mb-2 align-center">Status</p>
                {expenseStatusColFormat(expense)}
              </div>
              <div className="col-md-12 px-0 pt-2">
                <TDButton
                  type="submit"
                  variant={BS_STYLE.DEFAULT}
                  label="View"
                  onClick={() => (
                    history.push(financeExpenseViewUrl(orgAlias, id))
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
    </WidgetWrapper>
  );
};

ExpensesWidget.propTypes = {
  hasOrgAccess: PropTypes.func.isRequired,
  expenseAccessControl: PropTypes.object,
  expenseItems: PropTypes.arrayOf(expenseSpec),
  match: routerMatchSpec.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ExpensesWidget.defaultProps = {
  expenseAccessControl: { canClaim: false },
  expenseItems: [],
};

const mapStateToProps = (state) => {
  const listState = getListState(state, COMPONENT_NAME);
  return {
    expenseAccessControl: listState.extras.accessControl,
    expenseItems: listState.items,
    hasOrgAccess: getHasOrgAccess(state),
  };
};

const ExpensesWidgetConnected = connect(mapStateToProps)(ExpensesWidget);

const TdApiConnectedExpensesWidget = withTDApiConnected({
  fetchData: ({ dispatch, params, url, authedAxios }) => dispatch(fetchFinanceExpenseListDS({
    authedAxios,
    componentName: COMPONENT_NAME,
    dispatch,
    params,
    querystring: prepareQueryString({
      pageSize: DASHBOARD_WIDGET_PAGE_SIZE[DASHBOARD_WIDGET.EXPENSES],
    }),
    url,
  })),
  duck: 'list',
  skeletonComponent: ExpensesWidgetSkeleton,
  storeKey: COMPONENT_NAME,
})(ExpensesWidgetConnected);

export default withRouter(TdApiConnectedExpensesWidget);
