import React from 'react';

import PropTypes from 'prop-types';


const Content = ({ isColFlexContainer, children, className }) => {
  const classNames = [className, 'content-container'];
  if (isColFlexContainer) {
    classNames.push('content-col');
  }
  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
};

Content.propTypes = {
  isColFlexContainer: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
Content.defaultProps = {
  isColFlexContainer: false,
  className: '',
};

export default Content;
