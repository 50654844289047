import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { selectTrialSubscription } from 'organizations/assets/js/reducers/subscriptions';
import { DOCUMENT_CREATE_ELEMENT, DOCUMENT_BODY_APPEND_CHILD, DOCUMENT_QUERY_SELECTOR } from 'core/assets/js/config/settings';


const ContactSalesModal = () => {
  useEffect(() => {
    const hsScript = DOCUMENT_CREATE_ELEMENT('script');
    hsScript.innerHTML = `
      hbspt.forms.create({
        region: "na1",
        portalId: "4984585",
        formId: "0e2f5b4c-a8b8-4bfa-bf12-0321c5f0347c"
      });
    `;

    const hsFormScript = DOCUMENT_CREATE_ELEMENT('script');
    hsFormScript.type = 'text/javascript';
    hsFormScript.src = '//js.hsforms.net/forms/v2.js';
    hsFormScript.onload = () => {
      DOCUMENT_QUERY_SELECTOR('#contact-sales-form-placeholder').appendChild(hsScript);
    };

    DOCUMENT_BODY_APPEND_CHILD(hsFormScript);
  }, []);

  return (
    <div id="contact-sales-form-placeholder" />
  );
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = state => ({
  trialSubscription: selectTrialSubscription(state),
});

const ContactSalesModalConnected = connect(mapStateToProps, mapDispatchToProps)(ContactSalesModal);

export default ContactSalesModalConnected;
