import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import AccountProgressBar from 'accounts/assets/js/components/AccountProgressBar.jsx';
import { GET_STARTED_SIDE_PANEL_MODAL_ID } from 'accounts/assets/js/constants';
import { orgGetStartedUrl } from 'accounts/urls';
import NavBarItem from 'core/assets/js/components/NavBar/NavBarItem.jsx';
import { WINDOW_INNER_WIDTH } from 'core/assets/js/config/settings';
import { BOOTSTRAP_BREAKPOINTS, ICON } from 'core/assets/js/constants';
import { modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { isMenuItemActive } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const GetStartedProgressNavBarItem = ({ isCompact }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const isProgressBarModalOpen = useSelector(state => getIsModalOpen(
    state, GET_STARTED_SIDE_PANEL_MODAL_ID,
  ));
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();
  const path = orgGetStartedUrl(activeOrg.alias);
  const sections = [path];
  return (
    <NavBarItem
      darkHighlightWhenActive
      icon={ICON.ROCKET}
      isSectionActive={isMenuItemActive(
        match, location, sections,
      )}
      key={path}
      label="Get started"
      path={path}
      onClick={() => {
        // check screen size and if not already open
        if (
          WINDOW_INNER_WIDTH >= BOOTSTRAP_BREAKPOINTS.LARGE
          && !isProgressBarModalOpen
        ) {
          dispatch(modalOpenAC(GET_STARTED_SIDE_PANEL_MODAL_ID));
        }
      }}
      sections={sections}
      suffix={<AccountProgressBar label={isCompact ? null : 'Progress'} />}
      tabIndex={-1}
    />
  );
};

GetStartedProgressNavBarItem.propTypes = {
  isCompact: PropTypes.bool,
};

GetStartedProgressNavBarItem.defaultProps = {
  isCompact: false,
};

export default GetStartedProgressNavBarItem;
