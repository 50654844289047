import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { DATE_FORMAT_CHARTS } from 'core/assets/js/constants';

const ChartBasicLine = ({ chartData, currencySymbol, tooltip, chartName }) => {
  const config = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      visible: true,
      ordinal: false,
      gridLineWidth: 1,
      gridLineDashStyle: 'dash',
      xDateFormat: DATE_FORMAT_CHARTS,
      dateTimeLabelFormats: {
        millisecond: DATE_FORMAT_CHARTS,
        second: DATE_FORMAT_CHARTS,
        minute: DATE_FORMAT_CHARTS,
        hour: DATE_FORMAT_CHARTS,
        day: DATE_FORMAT_CHARTS,
        week: DATE_FORMAT_CHARTS,
      },
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: '',
      },
      gridLineWidth: 1,
      gridLineDashStyle: 'dash',
      opposite: false,
      min: 0,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: `<b>{series.name}</b>: ${currencySymbol}{point.y}`,
      valueDecimals: 2,
      xDateFormat: '%b %y',
      ...tooltip,
    },
    plotOptions: {
      line: {
        color: 'rgba(125,182,236,1)',
      },
      bar: {
        dataLabels: {
          enabled: true,
        },
        cropThreshold: 1000,
      },
      series: {
        animation: {
          duration: 1500,
        },
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 3,
          fillColor: 'rgba(125,182,236,1)',
        },
      },
    },

    series: [
      {
        type: 'line',
        name: chartName,
        pointIntervalUnit: 'day',
        data: chartData,
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={config}
    />
  );
};

ChartBasicLine.propTypes = {
  chartData: PropTypes.array.isRequired,
  chartName: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string,
  tooltip: PropTypes.object,
};

ChartBasicLine.defaultProps = {
  currencySymbol: '',
  tooltip: {},
};

export default ChartBasicLine;
