import { pick } from 'lodash';
import qs from 'query-string';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter, Link } from 'react-router-dom';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { DATETIME_FORMAT_HUMAN_FRIENDLY } from 'core/assets/js/constants';
import { getListState } from 'core/assets/js/ducks/list';
import {
  getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import { getViewState } from 'core/assets/js/ducks/view';
import {
  paginationSpec, routerHistorySpec, routerMatchContentsSpec,
} from 'core/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import { financeInvoiceViewUrl } from 'finance/urls';
import ActivitySearch from 'integrations/assets/js/components/ActivitySearch.jsx';
import {
  INTEGRATION_ACTIVITY_TABS,
  INTEGRATION_DETAILS_TABS,
  INTEGRATION_ERROR_LABEL,
  INTEGRATION_SYNC_JOB_STATUS,
  INTEGRATION_SYNC_JOB_STATUS_LABEL,
  INTEGRATION_SYNC_JOB_STATUS_CSS_CLASS,
} from 'integrations/assets/js/constants';
import {
  fetchAccountingIntegrationActivityDS, retryInvoiceSyncDS,
} from 'integrations/assets/js/data-services/list';
import { fetchInvoiceSyncPushOperationDS } from 'integrations/assets/js/data-services/view';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';
import { orgIntegrationDetailUrl } from 'integrations/urls';

const ACTIVITY_ERROR_MODAL_ID = 'activity_error_modal_id';

class IntegrationActivity extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName, querystring }) {
    const { orgAlias, integrationId } = params;
    return Promise.all([
      dispatch(fetchAccountingIntegrationActivityDS({
        url, querystring, componentName, orgAlias, authedAxios, integrationId,
      })),
    ]);
  }

  static GetComponentName() {
    return 'IntegrationActivity';
  }

  constructor() {
    super();

    this.state = { retrying: false };
  }

  render() {
    const {
      activity,
      activityErrorModalIsOpen,
      dispatch,
      errorPayload,
      hasLoaded,
      history,
      integration,
      invoiceSyncPushOperation,
      pagination,
      location: { pathname, search },
      match: { params: { integrationId, orgAlias } },
    } = this.props;
    const { retrying } = this.state;

    if (!integration.isConfigured) {
      return (
        <div className="rounded shadow-sm bg-white integration__activity--configuration-pending">
          <div className="integration--linking__details">
            <img
              src="/img/integrations/activity.png"
              alt={`${integration.name} is being linked to your account`}
            />
            <p>
              You need to map
              {' '}
              <Link
                to={orgIntegrationDetailUrl(
                  orgAlias, integration.id, INTEGRATION_DETAILS_TABS.INVOICE_MAPPING,
                )}
              >
                invoices
              </Link>
              {' '}
              and
              {' '}
              <Link
                to={orgIntegrationDetailUrl(
                  orgAlias, integration.id, INTEGRATION_DETAILS_TABS.SUPPLIER_MAPPING,
                )}
              >
                suppliers
              </Link>
              {' '}
              first in order to see activity.
              <br />
              Also please check integration
              {' '}
              <Link
                to={orgIntegrationDetailUrl(
                  orgAlias, integration.id, INTEGRATION_DETAILS_TABS.SETTINGS,
                )}
              >
                settings
              </Link>
              .
            </p>
          </div>
        </div>
      );
    }

    const tableColumns = [
      {
        key: 'invoiceNumber', label: 'TalentDesk ID', dataFormat: (invoiceNumber, row) => (
          <Link
            to={(
              financeInvoiceViewUrl(
                orgAlias,
                row.invoiceId,
                orgIntegrationDetailUrl(
                  orgAlias, integration.id, INTEGRATION_DETAILS_TABS.ACTIVITY,
                ),
              )
            )}
          >
            {invoiceNumber}
          </Link>
        ),
      },
      { key: 'supplierName', label: 'Supplier' },
      {
        key: 'status', label: 'Sync Status', dataFormat: (initialStatus, row) => {
          // If the status isn't available, this means that there isn't any job
          // that started yet, which means we can mark it as pending
          const status = initialStatus || INTEGRATION_SYNC_JOB_STATUS.PENDING;
          return (
            <>
              <StatusTag
                statusClass={INTEGRATION_SYNC_JOB_STATUS_CSS_CLASS[status]}
                label={INTEGRATION_SYNC_JOB_STATUS_LABEL[status]}
              />
              {status === INTEGRATION_SYNC_JOB_STATUS.ERRORED && (
                <span
                  className="ml-3 imitate-link"
                  onClick={() => dispatch(modalOpenAC(
                    ACTIVITY_ERROR_MODAL_ID,
                    pick(row, 'codatSyncJobId', 'errorMessage', 'errorType'),
                  ))}
                >
                  View error
                </span>
              )}
              {row.allowedActions?.canRetry && (
                <span
                  className="ml-3 imitate-link"
                  disabled={retrying}
                  onClick={async () => {
                    if (retrying) {
                      return;
                    }
                    this.setState({ retrying: true });
                    try {
                      await dispatch(retryInvoiceSyncDS({
                        componentName: IntegrationActivity.GetComponentName(),
                        integrationId,
                        invoiceId: row.invoiceId,
                        orgAlias,
                      }));
                      toastr.success(
                        'Well Done!', `Retry of syncing invoice ${row.invoiceNumber} started`,
                      );
                    } catch (error) {
                      toastr.error('Oh Snap!', error._error || error.message);
                    } finally {
                      this.setState({ retrying: false });
                      /*
                        Hacky, but with shouldRefetchOnQueryChange enabled, this will trigger
                        reloading the list of activity, to update which records can be retried
                      */
                      const parsedSearch = qs.parse(search);
                      parsedSearch.ts = Date.now();
                      history.push(`${pathname}?${qs.stringify(parsedSearch)}`);
                    }
                  }}
                >
                  Retry
                </span>
              )}
            </>
          );
        },
      },
      {
        key: 'createdAt',
        label: 'Created at',
        dataFormat: date => formatDate(date, DATETIME_FORMAT_HUMAN_FRIENDLY),
      },
    ];

    const { status } = qs.parse(search);

    let innerTab = INTEGRATION_ACTIVITY_TABS.ALL_ACTIVITY;
    switch (status) {
      case INTEGRATION_SYNC_JOB_STATUS.INITIATED:
        innerTab = INTEGRATION_ACTIVITY_TABS.PENDING;
        break;
      case INTEGRATION_SYNC_JOB_STATUS.COMPLETED:
        innerTab = INTEGRATION_ACTIVITY_TABS.COMPLETED;
        break;
      case INTEGRATION_SYNC_JOB_STATUS.ERRORED:
        innerTab = INTEGRATION_ACTIVITY_TABS.FAILED;
        break;
      default:
    }

    const tabSpec = [
      {
        key: INTEGRATION_ACTIVITY_TABS.ALL_ACTIVITY,
        label: 'All Activity',
        href: orgIntegrationDetailUrl(
          orgAlias, integration.id, INTEGRATION_DETAILS_TABS.ACTIVITY,
        ),
      },
      {
        key: INTEGRATION_ACTIVITY_TABS.PENDING,
        label: 'Pending',
        href: orgIntegrationDetailUrl(
          orgAlias,
          integration.id,
          INTEGRATION_DETAILS_TABS.ACTIVITY,
          qs.stringify({ status: INTEGRATION_SYNC_JOB_STATUS.INITIATED }),
        ),
      },
      {
        key: INTEGRATION_ACTIVITY_TABS.COMPLETED,
        label: 'Completed',
        href: orgIntegrationDetailUrl(
          orgAlias,
          integration.id,
          INTEGRATION_DETAILS_TABS.ACTIVITY,
          qs.stringify({ status: INTEGRATION_SYNC_JOB_STATUS.COMPLETED }),
        ),
      },
      {
        key: INTEGRATION_ACTIVITY_TABS.FAILED,
        label: 'Failed',
        href: orgIntegrationDetailUrl(
          orgAlias,
          integration.id,
          INTEGRATION_DETAILS_TABS.ACTIVITY,
          qs.stringify({ status: INTEGRATION_SYNC_JOB_STATUS.ERRORED }),
        ),
      },
    ];

    return (
      <div className="rounded shadow-sm bg-white clearfix">
        <div className="px-4 mb-4 clearfix">
          <TabBar
            emptyText="No activity found"
            activeKey={innerTab}
            tabSpec={tabSpec}
          />
        </div>
        <div className="mb-4 clearfix">
          <ActivitySearch />
        </div>
        <div className="px-4 mb-4 clearfix">
          {/*
            The content should be inside TDApiConnected, but there was a bug where it was not
            rendering, even though the API request was successful.
            It just gets stuck on `!requestHasBeenTriggered` in withApi.
            Spent hours investigating, but could not find the root cause. So rendering the content
            outside of TDApiConnected is the hacky fix
          */}
          <TDApiConnected component={this.constructor} duck="list" skeletonComponent={() => null} />
          {hasLoaded && (
            <>
              <Table cols={tableColumns} expandableRow={() => false} items={activity} />
              <TDPagination {...pagination} />
            </>
          )}
        </div>
        <ModalSimple
          body={(
            <>
              {errorPayload?.codatSyncJobId && (
                <TDApiConnected
                  duck="view"
                  fetchData={({ authedAxios }) => dispatch(fetchInvoiceSyncPushOperationDS({
                    authedAxios,
                    codatSyncJobId: errorPayload.codatSyncJobId,
                    componentName: ACTIVITY_ERROR_MODAL_ID,
                    integrationId,
                    orgAlias,
                  }))}
                  skeletonComponent={() => null}
                  storeKey={ACTIVITY_ERROR_MODAL_ID}
                >
                  <h2>Error Message</h2>
                  <p>{invoiceSyncPushOperation.errorMessage}</p>
                  {invoiceSyncPushOperation.validation && (
                    <>
                      {invoiceSyncPushOperation.validation.errors.length > 0 && (
                        <>
                          <h2>Errors</h2>
                          {invoiceSyncPushOperation.validation.errors.map(error => (
                            <p key={error.itemId}>
                              <b>{`${error.itemId}:`}</b>
                              {` ${error.message}`}
                            </p>
                          ))}
                        </>
                      )}
                      {invoiceSyncPushOperation.validation.warnings.length > 0 && (
                        <>
                          <h2>Warnings</h2>
                          {invoiceSyncPushOperation.validation.warnings.map(warning => (
                            <p key={warning.itemId}>
                              <b>{`${warning.itemId}:`}</b>
                              {` ${warning.message}`}
                            </p>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TDApiConnected>
              )}
              {!errorPayload?.codatSyncJobId && (
                <>
                  <h2>Error Message</h2>
                  {errorPayload?.errorType && (
                    <p>{INTEGRATION_ERROR_LABEL[errorPayload?.errorType]}</p>
                  )}
                  <p>{errorPayload?.errorMessage}</p>
                </>
              )}
            </>
          )}
          onClose={() => dispatch(modalCloseAC())}
          open={activityErrorModalIsOpen}
        />
      </div>
    );
  }
}

IntegrationActivity.propTypes = {
  activityErrorModalIsOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  errorPayload: PropTypes.object,
  hasLoaded: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  integration: integrationSpec.isRequired,
  invoiceSyncPushOperation: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  activity: PropTypes.arrayOf(PropTypes.object),
  pagination: paginationSpec.isRequired,
};

IntegrationActivity.defaultProps = {
  activity: [],
  errorPayload: {},
  invoiceSyncPushOperation: null,
};

const mapStateToProps = (state) => {
  const componentName = IntegrationActivity.GetComponentName();
  const listState = getListState(state, componentName);
  const invoiceSyncPushOperation = getViewState(state, ACTIVITY_ERROR_MODAL_ID).item;
  const modalPayload = getModalPayload(state, ACTIVITY_ERROR_MODAL_ID) || {};

  return {
    ...pick(listState, 'hasLoaded', 'pagination'),
    activity: listState.items,
    activityErrorModalIsOpen: getIsModalOpen(state, ACTIVITY_ERROR_MODAL_ID),
    errorPayload: modalPayload,
    invoiceSyncPushOperation,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const IntegrationActivityConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationActivity);

export default withRouter(IntegrationActivityConnected);
