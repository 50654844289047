/* eslint-disable react/no-multi-comp */
/*
eslint react/prop-types: [
  "error",
  {
    "ignore": [
      "input", "name", "type", "label", "placeholder", "meta", "error", "handleSubmit", "submitting"
    ]
  }
]
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { modalCloseAC, modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON, USER_TYPE } from 'core/assets/js/constants';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_LABEL, TYPE } from 'interviews/assets/js/constants';
import { changeSubmissionStatusToSubmittedDS } from 'interviews/assets/js/data-services/interviews';
import { interviewQuestionSpec } from 'interviews/assets/js/lib/objectSpecs';
import { concatNodes } from 'core/assets/js/lib/utils-jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { getAnswerExpiresAtPath } from 'interviews/assets/js/lib/utils';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

const AnswerBlock = ({ answerEl, questionLabel, userCannotViewAnswer }) => {
  return (
    <>
      <label>
        <MarkdownText text={questionLabel} disallowedTypes={['paragraph']} />
      </label>
      {userCannotViewAnswer && (
        <div className="text-warning p-3 mt-3 mb-3">
          Your user type does not have permission to view this answer.
        </div>
      )}
      {!userCannotViewAnswer && (
        <div className="interview-answer">
          {answerEl}
        </div>
      )}
    </>
  );
};
AnswerBlock.propTypes = {
  answerEl: PropTypes.node.isRequired,
  questionLabel: PropTypes.string.isRequired,
  userCannotViewAnswer: PropTypes.bool,
};
AnswerBlock.defaultProps = {
  userCannotViewAnswer: false,
};

const Answer = ({ activeUserType, answer, expiresAtAnswer, question }) => {
  if (!answer) {
    const userCannotViewAnswer = !question.payload.visibleTo.includes(activeUserType);

    return (
      <AnswerBlock
        answerEl={<p>-</p>}
        questionLabel={question.label}
        userCannotViewAnswer={userCannotViewAnswer}
      />
    );
  }

  let answerEl = null;
  if ([TYPE.FILE, TYPE.MULTIFILE].includes(question.type)) {
    const filesArray = Array.isArray(answer) ? answer : JSON.parse(answer);
    const files = filesArray?.map(a =>
      <li key={a.handle}><a href={a.url} rel="noopener noreferrer" target="_blank">{a.filename}</a></li>,
    );
    answerEl = (<ul>{files}</ul>);
  } else if (question.type === TYPE.YESNO) {
    if (answer.isYes) {
      const lines = answer.answers.filter(a => a.text !== '').map(a =>
        <li key={a.id}>{a.text}</li>,
      );
      answerEl = (<ul>{lines}</ul>);
    } else {
      answerEl = (<p>No</p>);
    }
  } else if (question.type === TYPE.SELECT) {
    const answers = answer.map((a) => {
      if (a.text) {
        if (a.userInput) {
          return `${a.text}: ${a.userInput}`;
        }
        return a.text;
      }
      return question.payload.choices.find(c => c.value === a).text;
    });

    const text = Object.keys(answers).map((idx) => {
      const txt = answers[idx];
      return (
        <MarkdownText key={`q-${question.id}-answer-${idx}`} text={txt} disallowedTypes={['paragraph']} />
      );
    });

    answerEl = (<p>{concatNodes(text)}</p>);
  } else {
    answerEl = (<p>{answer}</p>);
  }

  return (
    <>
      <AnswerBlock
        answerEl={answerEl}
        questionLabel={question.label}
        questionPath={question.path}
      />
      {expiresAtAnswer && (
        <AnswerBlock
          answerEl={<p>{expiresAtAnswer}</p>}
          questionLabel="Expiry date"
          questionPath={getAnswerExpiresAtPath(question.path)}
        />
      )}
    </>
  );
};

Answer.propTypes = {
  activeUserType: PropTypes.oneOf(Object.values(USER_TYPE)).isRequired,
  question: PropTypes.object.isRequired,
  answer: PropTypes.any,
  expiresAtAnswer: PropTypes.any,
};

Answer.defaultProps = {
  answer: null,
  expiresAtAnswer: null,
};

const FORM_NAME = 'reviewForm';

class ReviewForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onChangeManagerAnswers = this.onChangeManagerAnswers.bind(this);
  }

  async onChangeManagerAnswers() {
    const { dispatch, id, orgAlias, parentComponentName } = this.props;

    try {
      await dispatch(changeSubmissionStatusToSubmittedDS({
        componentName: parentComponentName, orgAlias, submissionId: id,
      }));
      toastr.success(
        'Well Done!',
        "Onboarding submission's successfully changed to"
        + ` "${SUBMISSION_STATUS_LABEL[SUBMISSION_STATUS.SUBMITTED]}"`,
      );
    } catch (e) {
      toastr.error('Oh Snap!', e.response?.data?._error || e.message);
    }
  }

  handleOpenModal(modalId) {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(modalId));
  }

  handleCloseModal(modalId) {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(modalId));
  }

  render() {
    const {
      activeUserCard,
      allowedActions,
      answers,
      canReopen,
      canReview,
      dirty,
      error,
      errors: reviewerComments,
      formValues,
      handleSubmit,
      id,
      isReviewApprovalModalOpen,
      isReviewRejectionModalOpen,
      name,
      onSubmit,
      orgAlias,
      provider: { user: { profile } },
      questions,
      reviewApprovalModalId,
      reviewRejectionModalId,
      submitting,
    } = this.props;
    const providerFirstName = profile.firstName;
    // we don't want to change the 'reject' to 'request amend' label when review modal is open
    const onlyModalAnswerChanged = formValues && 'reason' in formValues && Object.keys(formValues).length === 1;
    let answerFields = [];

    if (questions) {
      answerFields = questions.map(question => (
        <div key={question.path} data-testid={`review-form-answer-${question.path}`}>
          <Answer
            activeUserType={activeUserCard.userRole.ofType}
            submissionId={id}
            orgAlias={orgAlias}
            question={question}
            answer={answers[question.path]}
            expiresAtAnswer={answers[getAnswerExpiresAtPath(question.path)]}
          />
          {reviewerComments[question.path] && (
            <div className="form-group has-error">
              <span className="help-block">
                Reviewer comment:
                {' '}
                {reviewerComments[question.path]}
              </span>
            </div>
          )}
          {canReview && (
            <Field
              key={question.path}
              name={question.path}
              type="text"
              component={InputField}
              placeholder="Add your comment..."
            />
          )}
        </div>
      ));
    }

    return (
      <div>
        {name && (
          <div className="interview-header d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
            <h3 className="my-0 mr-4">
              {name}
            </h3>

            {canReopen && (
              <span
                className="imitate-link"
                onClick={() => onSubmit({ reopen: true })}
              >
                Reopen onboarding form
              </span>
            )}
          </div>
        )}
        <form>
          <Field name="id" type="hidden" component={InputField} />
          {answerFields}
          {error && (
            <div className="form-group has-error">
              <span className="help-block">{error}</span>
            </div>
          )}
          {canReview && (
            <section id="buttons" className="d-flex mt-4 text-right">
              <ModalConfirm
                data-testid="review-form-reject-modal"
                key="rejectOnboardingFormModal"
                onConfirm={handleSubmit((values, dispatch) => onSubmit({
                  ...values, reject: true,
                }, dispatch),
                )}
                onClose={() => this.handleCloseModal(reviewRejectionModalId)}
                closeOnConfirm={false}
                heading={`Reject ${providerFirstName}'s onboarding form`}
                body={(
                  <React.Fragment>
                    <p>
                      {'Rejecting the user\'s onboarding form means that they will not have access to your organisation. If you are unhappy with the submission, you could always leave a comment and request an amendment for the respective question.'}
                    </p>
                    <p>
                      If you would like to reject the onboarding form,
                      please enter a descriptive reason.
                    </p>
                    <Field
                      name="reason"
                      type="textarea"
                      component={InputField}
                      autoFocus
                    />
                    <p>
                      Are you sure you want to reject this onboarding form?
                    </p>
                  </React.Fragment>
                )}
                confirmLabel="Reject"
                confirmStyle={BS_STYLE.DANGER}
                open={isReviewRejectionModalOpen}
              />
              <ModalConfirm
                data-testid="review-form-approve-modal"
                key="approveOnboardingFormModal"
                onConfirm={handleSubmit((values, dispatch) => onSubmit({
                  ...values,
                }, dispatch),
                )}
                onClose={() => this.handleCloseModal(reviewApprovalModalId)}
                closeOnConfirm={false}
                heading={`Approve ${providerFirstName}'s onboarding form`}
                body={(
                  <React.Fragment>
                    <p>
                      {'Approving the user\'s onboarding form means that they will have access to your organisation.'}
                    </p>
                    <p>
                      If you would like please enter a descriptive reason for your approval.
                    </p>
                    <Field
                      name="reason"
                      type="textarea"
                      component={InputField}
                      autoFocus
                    />
                    <p>
                      Are you sure you want to approve this onboarding form?
                    </p>
                  </React.Fragment>
                )}
                confirmLabel="Approve"
                confirmStyle={BS_STYLE.SUCCESS}
                open={isReviewApprovalModalOpen}
              />
              {allowedActions.canAmendManagerAnswers && (
                <TDButton
                  disabled={submitting}
                  label="Change manager answers"
                  onClick={() => this.onChangeManagerAnswers()}
                  variant={BS_STYLE.SECONDARY}
                />
              )}
              <TDButton
                data-testid="review-form-button-reject"
                variant={BS_STYLE.DANGER}
                btnIcon={ICON.CROSS}
                disabled={submitting}
                label="Reject"
                onClick={() => this.handleOpenModal(reviewRejectionModalId)}
              />
              {dirty && !onlyModalAnswerChanged && (
                <TDButton
                  data-testid="review-form-button-request-amend"
                  onClick={handleSubmit(onSubmit)}
                  variant={BS_STYLE.WARNING}
                  btnIcon={ICON.EDIT}
                  disabled={submitting}
                  label="Request Amend"
                />
              )}
              {(!dirty || onlyModalAnswerChanged) && (
                <TDButton
                  data-testid="review-form-button-approve"
                  onClick={() => this.handleOpenModal(reviewApprovalModalId)}
                  variant={BS_STYLE.SUCCESS}
                  btnIcon={ICON.CHECKMARK}
                  disabled={submitting}
                  label="Approve"
                />
              )}
            </section>
          )}
        </form>
      </div>
    );
  }
}

ReviewForm.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  allowedActions: PropTypes.object,
  answers: PropTypes.object.isRequired,
  canReopen: PropTypes.bool,
  canReview: PropTypes.bool,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.object,
  formValues: PropTypes.object,
  id: PropTypes.number,
  isReviewApprovalModalOpen: PropTypes.bool,
  isReviewRejectionModalOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  provider: PropTypes.object.isRequired,
  reviewApprovalModalId: PropTypes.string.isRequired,
  reviewRejectionModalId: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(interviewQuestionSpec).isRequired,
};

ReviewForm.defaultProps = {
  allowedActions: {},
  canReopen: false,
  canReview: false,
  dirty: false,
  errors: {},
  formValues: {},
  id: null,
  isReviewApprovalModalOpen: false,
  isReviewRejectionModalOpen: false,
};

const ReviewFormConnect = reduxForm({
  form: FORM_NAME,
})(ReviewForm);


const mapStateToProps = (state) => {
  const reviewApprovalModalId = 'review-onboarding-form-approval-modal';
  const reviewRejectionModalId = 'review-onboarding-form-rejection-modal';
  const isReviewRejectionModalOpen = getIsModalOpen(state, reviewRejectionModalId);
  const isReviewApprovalModalOpen = getIsModalOpen(state, reviewApprovalModalId);
  return ({
    activeUserCard: selectActiveUserCard(state),
    formValues: getFormValues(FORM_NAME)(state),
    isReviewApprovalModalOpen,
    isReviewRejectionModalOpen,
    reviewApprovalModalId,
    reviewRejectionModalId,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewFormConnect);
