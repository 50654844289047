import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Dropdown } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { withRouter, Link } from 'react-router-dom';

import { submissionUrl } from 'interviews/urls';
import { calculateTimeOffWarningMessages } from 'people/assets/js/lib/utils';
import { orgUserProfileUrl } from 'people/urls';
import { orgSettingsRatesCreateUrl } from 'rates/urls';
import {
  getActiveUserCardPermissionChecker, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { managerUserCardSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import {
  USER_TYPE, USER_CARD_STATUS, ICON, BS_TOOLTIP_PLACEMENT, POPPER_PLACEMENT, IMG_SIZE,
} from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import EmploymentStatusIndicator from 'people/assets/js/components/EmploymentStatusIndicator.jsx';
import AllowAccessToHigherManager from 'core/assets/js/components/AllowAccessToHigherManager.jsx';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import { RATE_ADJUSTMENT_MODAL_ID } from 'rates/assets/js/constants';
import EditManagerBudget from 'people/assets/js/components/EditManagerBudget.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import RemoveFinancialController, {
  MODAL_ID as REMOVE_FINCON_MODAL_ID,
} from 'people/assets/js/components/RemoveFinancialController.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ContactDetailsCard from 'people/assets/js/components/ContactDetailsCard.jsx';
import OnboardingStatus from 'people/assets/js/components/OnboardingStatus.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import ManagerDeactivateModal, { getModalId as getManagerDeactivateModalId } from 'people/assets/js/components/manager-deactivate/ManagerDeactivateModal.jsx';
import UserToggleActivationModal, { getModalId as getActivationModalId } from 'people/assets/js/components/UserToggleActivationModal.jsx';
import ConvertToProviderModal, { getModalId as getDemoteModalId } from 'people/assets/js/components/ConvertToProviderModal.jsx';
import ToggleEmploymentModal, { getModalId as getEmploymentModalId } from 'people/assets/js/components/ToggleEmploymentModal.jsx';
import { PERMISSIONS } from 'roles/assets/js/constants';

class ManagerCard extends React.Component {
  constructor(props) {
    super(props);

    this.editManagerBudgetModalId = `edit-budget-id-${props.item.id}`;
    this.handleRemoveFinancialController = this.handleRemoveFinancialController.bind(this);
    this.handleOpenContactModal = this.handleOpenContactModal.bind(this);
    this.handleCloseModals = this.handleCloseModals.bind(this);
    this.handleEditBudget = this.handleEditBudget.bind(this);
    this.openRateAdjustmentModal = this.openRateAdjustmentModal.bind(this);
    this.handleOpenDeactivateModal = this.handleOpenDeactivateModal.bind(this);
    this.handleOpenToggleActivationModal = this.handleOpenToggleActivationModal.bind(this);
    this.handleOpenDemoteToProviderModal = this.handleOpenDemoteToProviderModal.bind(this);
    this.handleOpenToggleEmploymentModal = this.handleOpenToggleEmploymentModal.bind(this);
  }

  openRateAdjustmentModal() {
    const { dispatch, item } = this.props;
    dispatch(modalOpenAC(RATE_ADJUSTMENT_MODAL_ID, item));
  }

  handleEditBudget() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.editManagerBudgetModalId));
  }

  handleOpenContactModal() {
    const { dispatch, contactManagerModalId } = this.props;
    dispatch(modalOpenAC(contactManagerModalId));
  }

  handleCloseModals() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleRemoveFinancialController() {
    const { dispatch, item: { user: { id: userId, profile } } } = this.props;
    dispatch(modalOpenAC(REMOVE_FINCON_MODAL_ID, { userId, manager: profile }));
  }

  handleOpenDeactivateModal() {
    const { dispatch, item } = this.props;
    dispatch(modalOpenAC(getManagerDeactivateModalId(item.user.id)));
  }

  handleOpenToggleActivationModal() {
    const { dispatch, item } = this.props;
    dispatch(modalOpenAC(getActivationModalId(item.user.id)));
  }

  handleOpenDemoteToProviderModal() {
    const { dispatch, item } = this.props;
    dispatch(modalOpenAC(getDemoteModalId(item.user.id)));
  }

  handleOpenToggleEmploymentModal() {
    const { dispatch, item } = this.props;
    dispatch(modalOpenAC(getEmploymentModalId(item.user.id)));
  }

  render() {
    const {
      activeUserCard,
      availabilityFilter,
      hasPermission,
      history,
      isContactModalOpen,
      isGroupMemberCard,
      isLastItem,
      item,
      item: {
        allowedActions: {
          canReactivate,
          canChangeRoleToProvider,
          canChangeToContractor,
          canChangeToEmployee,
          canDeactivate,
        } = {},
        budget,
        currencySymbol,
        email: userCardEmail,
        hasUnprocessedServiceOrders,
        isEmployee,
        rate,
        rateAdjustmentActions,
        rateUnit,
        status,
        submissions,
        user,
        user: { nextTimeoff, profile: { firstName } },
        userGroupMemberId,
      },
      linkTarget,
      listViewComponentName,
      match,
      onFinControllerRemoved,
      onRemoveMemberFromGroup,
      orgAlias,
      showAvailabilityMessages,
      showRemoveFinCon,
      warningMessage,
    } = this.props;
    const { profile, email: userEmail, id } = user || {};
    const profileUrl = orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, id);

    const isOrgCreator = activeUserCard.userRole.isOrgCreator;
    const isThemselves = activeUserCard.user && user && activeUserCard.user.id === user.id;
    const submission = submissions && submissions.length > 0 ? submissions[0] : null;
    const editUrl = submission ? submissionUrl(orgAlias, submission.id, '', match.url) : '';
    const hasCompleteRate = !!(currencySymbol && parseFloat(rate) > 0.00);
    const isApproved = status === USER_CARD_STATUS.APPROVED;
    const hasCompleteBudget = currencySymbol && parseFloat(budget) > 0.00;
    const {
      isOnTimeOff, isLeavingSoon, availabilityMessage, messageTextClassName,
    } = calculateTimeOffWarningMessages({ nextTimeoff, firstName, availabilityFilter });
    const email = userCardEmail || userEmail;

    // Rate display
    let userRate = '';
    if (hasCompleteRate) {
      userRate = (
        <span>
          <RateAmount
            amount={rate}
            unit={rateUnit}
            symbol={currencySymbol}
          />
        </span>
      );
    }

    // Rate adjustment actions
    const rateCanCreate = !rate;
    let rateAdjCanResponderAct = false;
    let rateAdjCanBeInteracted = false;
    if (rate && rateAdjustmentActions) {
      if (rateAdjustmentActions.canRespond || rateAdjustmentActions.canCancel) {
        rateAdjCanResponderAct = true;
      }

      if (isApproved && (rateAdjustmentActions.canInitiate || rateAdjCanResponderAct)) {
        rateAdjCanBeInteracted = true;
      }
    }

    let showSubmissionLink = false;
    let submissionLinkTxt = null;

    if (submission && submission.allowedActions) {
      showSubmissionLink = submission.allowedActions.canView;
      submissionLinkTxt = `${submission.allowedActions.canReview ? 'Review' : 'View'} Onboarding Submission`;
    }

    const warning = warningMessage(item);

    const canToggleActivation = listViewComponentName && (canReactivate || canDeactivate);

    const canToggleEmploymentStatus = canChangeToContractor || canChangeToEmployee;

    const canManageOrgSettings = hasPermission(PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS);

    return (
      <Card className="user-item">
        <Card.Body>
          <div className="row d-flex align-items-center position-relative">
            <div className="user-item__basic-info col-12 col-md-auto d-flex">
              { profile && (
                <Link
                  to={profileUrl}
                  target={linkTarget === '_self' ? undefined : linkTarget}
                  onClick={e =>  e.stopPropagation()}
                  title={profile.name}
                >
                  <ProfilePic
                    url={profile.avatar}
                    alt={profile.name}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  />
                </Link>
              )}

              <div className="col-10 col-md-auto pl-3 d-flex justify-content-center flex-column">
                <Link
                  data-testid="manager-card-title"
                  to={profileUrl}
                  target={linkTarget === '_self' ? undefined : linkTarget}
                  onClick={(e) => { e.stopPropagation(); }}
                  title={profile.name}
                  className="user-item__title truncate"
                >
                  { profile && profile.name }
                  <EmploymentStatusIndicator
                    userType={USER_TYPE.MANAGER}
                    isEmployee={isEmployee}
                  />
                </Link>

                <div className="user-item__extra discreet col-auto px-0">
                  <div className="truncate">
                    { profile && profile.jobTitle && (
                      <span className="truncate" title={profile.jobTitle}>
                        {profile.jobTitle }
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-auto mt-3 mt-md-0 user-item__details ml-0 ml-md-auto">
              { hasCompleteBudget && (
                <span className="user-item__budget">
                  <i key="coins-icon" className={ICON.COINS} />
                  <NumberFormat
                    key="budget"
                    value={budget}
                    prefix={currencySymbol}
                    displayType="text"
                    thousandSeparator
                  />
                </span>
              )}

              { rateAdjustmentActions
                && (hasCompleteRate || rateAdjustmentActions.hasPending) && (
                <React.Fragment>
                  { !rateAdjustmentActions.hasPending && hasCompleteRate && (
                    <StatusTag
                      statusClass="default"
                      hideTitle
                      hideDot
                      label={userRate}
                    />
                  )}

                  { rateAdjustmentActions.hasPending && rate && (
                    <TDElementWithTooltip
                      el={(
                        <span>
                          <StatusTag
                            hideTitle
                            statusClass="pending"
                            onClick={this.openRateAdjustmentModal}
                            label={userRate}
                          />
                        </span>
                      )}
                      placement={BS_TOOLTIP_PLACEMENT.TOP}
                      tooltipMsg="Pending Adjustment"
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          <OnboardingStatus status={status} />
        </Card.Body>

        <Card.Footer className="bg-white d-flex flex-nowrap align-items-center">
          { showAvailabilityMessages && (isLeavingSoon || isOnTimeOff) && (
            <div className="border-right my-n1 py-1 mr-3 pr-1">
              <TDElementWithTooltip
                tooltipMsg={availabilityMessage}
                placement={POPPER_PLACEMENT.AUTO}
              >
                <i className={`${ICON.CALENDAR_ALT} ${messageTextClassName} ml-n3 py-2 px-3`} />
              </TDElementWithTooltip>
            </div>
          )}

          <div className="col-10 pl-0">
            { profile && profile.skills && (
              <SkillList
                skills={profile.skills}
                inline
                maxShownItems={7}
                modalId={`user-skills-${id}`}
              />
            )}
          </div>

          <TDDropButton
            className="ml-auto"
            data-testid="manager-card-actions"
            stopPropagation
            placement={isLastItem ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
          >
            { showSubmissionLink && (
              <Dropdown.Item
                eventKey="submission"
                onClick={() => history.push(editUrl)}
              >
                { submissionLinkTxt }
              </Dropdown.Item>
            )}
            {showRemoveFinCon && canManageOrgSettings && (
              <Dropdown.Item
                eventKey="removeFinCon"
                onClick={this.handleRemoveFinancialController}
              >
                Remove Financial Controller
              </Dropdown.Item>
            )}
            { (isOrgCreator || (isApproved && !isThemselves)) && (
              <AllowAccessToHigherManager>
                <Dropdown.Item
                  eventKey="editBudget"
                  onClick={this.handleEditBudget}
                >
                  Manage Budget
                </Dropdown.Item>
              </AllowAccessToHigherManager>
            )}
            { rateAdjCanBeInteracted && (
              <AllowAccessToManager>
                <Dropdown.Item
                  eventKey="2"
                  onClick={this.openRateAdjustmentModal}
                >
                  { rateAdjustmentActions.canInitiate
                    ? 'Request rate adjustment'
                    : 'Pending rate adjustment'
                  }
                </Dropdown.Item>
              </AllowAccessToManager>
            )}
            { (rateCanCreate && isThemselves && !isOrgCreator) && (
              <AllowAccessToManager>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    history.push(orgSettingsRatesCreateUrl(orgAlias));
                  }}
                >
                  Create rate
                </Dropdown.Item>
              </AllowAccessToManager>
            )}
            {isGroupMemberCard && (
              <Dropdown.Item
                eventKey="remove-group-member"
                onClick={() => onRemoveMemberFromGroup(userGroupMemberId)}
              >
                Remove member
              </Dropdown.Item>
            )}
            <Dropdown.Item
              eventKey="contact"
              onClick={this.handleOpenContactModal}
            >
              Contact
            </Dropdown.Item>
            {canChangeRoleToProvider && (
              <Dropdown.Item eventKey="promote" onClick={this.handleOpenDemoteToProviderModal}>
                Convert to provider
              </Dropdown.Item>
            )}
            {canToggleEmploymentStatus && (
              <Dropdown.Item eventKey="promote" onClick={this.handleOpenToggleEmploymentModal}>
                Convert to
                {` ${canChangeToContractor ? 'contractor' : 'employee'}`}
              </Dropdown.Item>
            )}
            {canToggleActivation && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item
                  className={canDeactivate && 'text-danger'}
                  eventKey="toggle-activation"
                  onClick={
                    isApproved
                      ? this.handleOpenDeactivateModal
                      : this.handleOpenToggleActivationModal
                  }
                >
                  {canDeactivate ? 'Deactivate' : 'Activate'}
                </Dropdown.Item>
              </>
            )}
          </TDDropButton>

          { (isOrgCreator || (!isThemselves)) && (
            <AllowAccessToHigherManager>
              <EditManagerBudget
                manager={user}
                managerId={id}
                orgAlias={orgAlias}
                form={`manager-budget-form-${id}`}
                modalId={this.editManagerBudgetModalId}
              />
            </AllowAccessToHigherManager>
          )}

          { showRemoveFinCon && canManageOrgSettings && (
            <RemoveFinancialController
              manager={item.user.profile}
              onConfirmSuccess={() => onFinControllerRemoved(item)}
            />
          )}

          { profile && (
            <ModalSimple
              open={isContactModalOpen}
              heading={`Contact ${profile.firstName}`}
              body={(
                <ContactDetailsCard
                  email={email}
                  profile={profile}
                />
              )}
              onClose={this.handleCloseModals}
            />
          )}
        </Card.Footer>
        {warning && (
          <Card.Footer className="warning text-warning">
            {warning}
          </Card.Footer>
        )}

        {canToggleActivation && (
          <>
            {canDeactivate && (
              <ManagerDeactivateModal
                listViewComponentName={listViewComponentName}
                userEmail={email}
                userId={id}
                userName={profile.name}
              />
            )}
            {canReactivate && (
              <UserToggleActivationModal
                activate
                listViewComponentName={listViewComponentName}
                userId={id}
                userName={profile.name}
                userType={USER_TYPE.MANAGER}
              />
            )}
          </>
        )}
        {canChangeRoleToProvider && (
          <ConvertToProviderModal
            listViewComponentName={listViewComponentName}
            userId={id}
            isEmployee={isEmployee}
            userName={profile.name}
          />
        )}
        {canToggleEmploymentStatus && (
          <ToggleEmploymentModal
            cannotConvertToEmployee={canChangeToEmployee && hasUnprocessedServiceOrders}
            listViewComponentName={listViewComponentName}
            toContractor={canChangeToContractor}
            userId={id}
            userName={profile.name}
          />
        )}
      </Card>
    );
  }
}

ManagerCard.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  availabilityFilter: PropTypes.object,
  contactManagerModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasPermission: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isContactModalOpen: PropTypes.bool,
  isGroupMemberCard: PropTypes.bool,
  isLastItem: PropTypes.bool,
  item: managerUserCardSpec.isRequired,
  linkTarget: PropTypes.string,
  listViewComponentName: PropTypes.string,
  match: routerMatchSpec.isRequired,
  onRemoveMemberFromGroup: PropTypes.func,
  onFinControllerRemoved: PropTypes.func,
  orgAlias: PropTypes.string,
  showAvailabilityMessages: PropTypes.bool,
  showRemoveFinCon: PropTypes.bool,
  userGroupMemberId: PropTypes.number,
  warningMessage: PropTypes.func,
};

ManagerCard.defaultProps = {
  availabilityFilter: null,
  isContactModalOpen: false,
  isGroupMemberCard: false,
  isLastItem: false,
  linkTarget: '_self',
  listViewComponentName: null,
  onFinControllerRemoved: () => {},
  onRemoveMemberFromGroup: () => {},
  orgAlias: '',
  showAvailabilityMessages: true,
  showRemoveFinCon: false,
  userGroupMemberId: null,
  warningMessage: () => null,
};

const mapStateToProps = (state, props) => {
  const contactManagerModalId = `contact-manager-id-${props.item.id}`;

  return {
    activeUserCard: selectActiveUserCard(state),
    contactManagerModalId,
    hasPermission: getActiveUserCardPermissionChecker(state),
    isContactModalOpen: getIsModalOpen(state, contactManagerModalId),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ManagerCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManagerCard);

export default withRouter(ManagerCardConnected);
