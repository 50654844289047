import React from 'react';
import PropTypes from 'prop-types';


const PaymentMethodSelectCard = ({
  item: {
    name, logo, icon, disabledPaymentMethodMsg,
    extraInfo,
  },
}) => (
  <div className="d-flex align-items-center">
    <div className="pr-3 d-none d-md-inline-block payment-method-card-item__logo-container">
      { logo && (
        <img width="40px" alt={`${name} logo`} src={logo} />
      )}
      { icon && (
        <i className={icon} />
      )}
    </div>
    <div>
      <h4 className="mt-0 mb-1 payment-method-item-card__title">{name}</h4>
      <div className="text-success font-weight-bold">{extraInfo}</div>
      {disabledPaymentMethodMsg && (
        <div className="hint">{disabledPaymentMethodMsg}</div>
      )}
    </div>
  </div>
);

PaymentMethodSelectCard.propTypes = {
  item: PropTypes.object.isRequired,
};


export default PaymentMethodSelectCard;
