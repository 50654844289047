import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_PAGE_SIZE } from 'settings/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { ICON, BS_STYLE } from 'core/assets/js/constants';
import { prepareQueryString } from 'core/assets/js/lib/utils';
import { projectCreateUrl, projectListApiUrl, projectListUrl } from 'projects/urls';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ProjectWidgetCardItem from 'core/assets/js/components/Dashboard/ProjectWidgetCardItem.jsx';
import ProjectWidgetSkeleton from 'core/assets/js/components/Skeleton/ProjectWidgetSkeleton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';
import { getActiveUserCardPermissionChecker } from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';

export const COMPONENT_NAME = 'ProjectsWidgetView';

const ProjectsWidget = ({
  history,
  match: { params: { orgAlias } },
  projectItems,
  hasPermission,
}) => {
  let headerActionBtn = null;

  if (hasPermission(PERMISSIONS.CAN_CREATE_PROJECTS)) {
    headerActionBtn = {
      label: 'Create new project',
      onClick: () => history.push(projectCreateUrl(orgAlias)),
      variant: BS_STYLE.PRIMARY,
    };
  }

  const footerActionBtn = {
    label: 'View projects',
    onClick: () => history.push(projectListUrl(orgAlias)),
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: 'Plan and budget contractor work by creating projects',
    icon: ICON.BAR_PROGRESS_DUOTONE,
  };

  const widgetTitle = 'Projects';

  return (
    <WidgetWrapper
      bodyExtraClasses="p-0"
      footerActionBtn={footerActionBtn}
      headerActionBtn={headerActionBtn}
      isEmpty={isEmpty(projectItems)}
      placeholder={emptyPlaceholder}
      title={widgetTitle}
      popOverTitle={widgetTitle}
      popOverContent={(
        <>
          <p>
            Projects are a way in which you can segregate the various works that providers will do.
            Each project requires a manager who will own the project, and a budget to control the
            amount of work that can be approved for payment. Projects can have many tasks if you
            need to break down the work any further. Like all data within your TalentDesk account,
            it will not be shared in the public domain, only with the managers &amp;
            providers whom you invite to your account.
          </p>
          <p>
            <a href="https://www.talentdesk.io/knowledge-base/projects" target="_blank" rel="noreferrer">
              {'To learn more '}
              <span className="visually-hidden">
                {`about ${widgetTitle}, `}
              </span>
              visit the Knowledge base
            </a>
          </p>
        </>
      )}
    >
      <TDList
        cardItem={{
          component: ProjectWidgetCardItem,
          props: { orgAlias },
        }}
        items={projectItems}
        listClassName="project-widget-list"
      />
    </WidgetWrapper>
  );
};

ProjectsWidget.propTypes = {
  match: routerMatchSpec.isRequired,
  projectItems: PropTypes.arrayOf(projectSpec),
  hasPermission: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ProjectsWidget.defaultProps = {
  projectItems: [],
};

const mapStateToProps = state => ({
  projectItems: getListState(state, COMPONENT_NAME).items,
  hasPermission: getActiveUserCardPermissionChecker(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectsWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectsWidget);

const TDApiConnectedProjectsWidget = withTDApiConnected({
  duck: 'list',
  fetchData: ({
    authedAxios,
    componentName,
    dispatch,
    params: { orgAlias },
    url,
  }) => dispatch(
    fetchListDS({
      authedAxios,
      componentName,
      querystring: prepareQueryString({
        pageSize: DASHBOARD_WIDGET_PAGE_SIZE[DASHBOARD_WIDGET.PROJECTS],
      }),
      url: projectListApiUrl(orgAlias, url),
    }),
  ),
  skeletonComponent: ProjectWidgetSkeleton,
  storeKey: COMPONENT_NAME,
})(ProjectsWidgetConnected);

export default withRouter(TDApiConnectedProjectsWidget);
