/* eslint-disable
  no-console
*/
import { inspect } from 'util';
import { isString, isNumber } from 'lodash';
import globToRegExp from 'glob-to-regexp';
import { LOG_LEVEL, LOG_LEVEL_PRIORITY } from 'core/assets/js/constants';
import { NODE_ENV, ENV_DEVELOPMENT, ENV_TEST, TD_DEBUG, LOG_ERROR_STACK } from 'core/assets/js/config/settings';

const getNamespaceRegex = (namespace) => {
  let selected = [];
  let ignored = [];

  const match = (s, i, n) => {
    const res = s.some(d => n.match(d)) && i.every(d => !n.match(d));
    return res;
  };

  const noMatch = match.bind(this, [], []);

  if (!namespace) {
    return { selected, ignored, match: noMatch };
  }

  const clauses = namespace.split(',');
  if (clauses.length === 0) {
    return { selected, ignored, match: noMatch };
  }

  selected = clauses.filter(d => !d.startsWith('-'));
  ignored = clauses.filter(d => d.startsWith('-')).map(d => d.slice(1));

  const selectedRegex = selected.map(d => globToRegExp(d));
  const ignoredRegex = ignored.map(d => globToRegExp(d));

  const res = {
    selected: selectedRegex,
    ignored: ignoredRegex,
    match: match.bind(this, selectedRegex, ignoredRegex),
  };

  return res;
};

const MAX_INSPECT_DEPTH = 10;
let INSTANCE_COUNTER = 0;
const AVAILABLE_COLORS = [
  'green',
  'blue',
  'magenta',
  'gray',
];

let recordingRegex = getNamespaceRegex();
let recordedMessages = [];

class SimpleLogHandler {
  constructor({ namespace, level }) {
    this.namespace = namespace;
    this.level = level;
  }

  log({ level, message }) {
    if (LOG_LEVEL_PRIORITY[level] >= LOG_LEVEL_PRIORITY[this.level]) {
      console.log(`${this.namespace} ${message}`);
    }
  }
}

class Logger {
  static startRecording(namespace) {
    recordingRegex = getNamespaceRegex(namespace);
  }

  static flush() {
    const res = [...recordedMessages];
    recordedMessages = [];
    recordingRegex = getNamespaceRegex();
    return res;
  }

  static concatenateMessages(messages, { isDev, useJsonLogger } = {}) {
    const parts = messages.map((ms) => {
      if (ms instanceof Error) {
        return useJsonLogger ? ms.toString() : ms.stack;
      }

      if (isString(ms)) {
        return ms;
      }

      if (isNumber(ms)) {
        return String(ms);
      }

      return inspect(ms, {
        depth: MAX_INSPECT_DEPTH,
        compact: !isDev,
      });
    });

    return parts.filter(m => !!m).map((m) => {
      if (/^\s+$/.test(m)) {
        // somebody asked for spaces to be printed on purpose
        return m;
      }
      return m.trim();
    }).join(' ');
  }

  static _prepareLogEntry(level, messages, { isDev, useJsonLogger }) {
    const errors = messages.filter(m => m instanceof Error);
    const message = Logger.concatenateMessages(messages, { isDev, useJsonLogger });

    const logEntry = { level, message };

    if (errors.length) {
      const stack = errors.map(e => e.stack).join('\n');
      Object.assign(logEntry, { stack });
      if (LOG_ERROR_STACK) {
        console.error(stack);
      }
    }
    return logEntry;
  }

  constructor(namespace, {
    tdDebug = TD_DEBUG,
    isDev = [ENV_DEVELOPMENT, ENV_TEST].includes(NODE_ENV),
    useJsonLogger = false,
    colorized = false,
  } = {}) {
    this.namespace = namespace;
    this.colorized = colorized;
    this.tdDebug = tdDebug;
    this.isDev = isDev;
    this.useJsonLogger = useJsonLogger;

    this.id = INSTANCE_COUNTER;
    INSTANCE_COUNTER += 1;
    this.color = AVAILABLE_COLORS[this.id % AVAILABLE_COLORS.length];
    const debugRegex = getNamespaceRegex(tdDebug);
    this.debugMode = debugRegex.match(namespace);
    this.handler = new SimpleLogHandler({
      level: this.debugMode ? LOG_LEVEL.DEBUG : LOG_LEVEL.INFO, namespace,
    });
  }

  _log(level, ...messages) {
    const { namespace, isDev, useJsonLogger } = this;
    const logEntry = Logger._prepareLogEntry(level, messages, {
      isDev, useJsonLogger,
    });

    if (recordingRegex.match(namespace)) {
      recordedMessages.push(logEntry.message);
    }

    this.handler.log(logEntry);
  }

  log(...messages) {
    this._log(LOG_LEVEL.DEBUG, ...messages);
  }

  debug(...messages) {
    this._log(LOG_LEVEL.DEBUG, ...messages);
  }

  info(...messages) {
    this._log(LOG_LEVEL.INFO, ...messages);
  }

  warn(...messages) {
    this._log(LOG_LEVEL.WARN, ...messages);
  }

  error(...messages) {
    this._log(LOG_LEVEL.ERROR, ...messages);
  }

  extend(namespace) {
    const { tdDebug, isDev, useJsonLogger, colorized } = this;
    const LoggerClass = this.constructor;
    return new LoggerClass(`${this.namespace}:${namespace}`, {
      tdDebug, isDev, useJsonLogger, colorized,
    });
  }
}

export const logger = new Logger('td');

export default Logger;
