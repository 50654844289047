import React from 'react';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import AORContractSign from 'documents/assets/js/components/AORContractSign.jsx';
import { orgGetStartedUrl } from 'accounts/urls';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';


const AORContractSignView = ({
  match: { params: { orgAlias } },
}) => (
  <>
    <ContentHeader
      breadcrumbs={[
        {
          title: 'Sign the “Agent of Record” contract',
          url: null,
        },
      ]}
      backUrl={orgGetStartedUrl(orgAlias)}
    />

    <div className="page page--aor-contract-sign row">
      <div className="container">
        <div className="py-5 col-12 col-xl-10 offset-xl-1">
          <AORContractSign />
        </div>
      </div>
    </div>
  </>
);

AORContractSignView.GetComponentName = () => 'AORContractSignView';

AORContractSignView.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(AORContractSignView);
