import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const IntegrationTypeModal = ({
  onClose, integrationType, handleAddIntegrationType, isButtonEnabled,
}) => (
  <div className="d-flex flex-column align-items-center justify-content-center text-center integration-connect-modal">
    <img
      src="/img/integrations/integrate.png"
      alt="You will need to continue the integration into an external page"
    />

    <h1>You&apos;ll be redirected to the external integration page!</h1>

    <p>
      You will be redirected to an external integration page that will guide you through
      the steps. Once completed you will be redirected back to this page.
    </p>

    <div>
      <TDButton
        disabled={!isButtonEnabled}
        disabledMsg={!isButtonEnabled ? 'You are being redirected' : null}
        onClick={onClose}
        variant={BS_STYLE.DEFAULT}
        label="Cancel"
      />
      <TDButton
        disabled={!isButtonEnabled}
        disabledMsg={!isButtonEnabled ? 'You are being redirected' : null}
        onClick={() => handleAddIntegrationType(integrationType)}
        variant={BS_STYLE.PRIMARY}
        label="Continue"
      />
    </div>
  </div>
);

IntegrationTypeModal.propTypes = {
  integrationType: PropTypes.string.isRequired,
  handleAddIntegrationType: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isButtonEnabled: PropTypes.bool,
};

IntegrationTypeModal.defaultProps = {
  isButtonEnabled: false,
};

export default IntegrationTypeModal;
