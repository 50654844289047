import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { filter } from 'lodash';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { addBackUrlIfLocationContains, financePurchaseOrderViewUrl } from 'finance/urls';
import Table from 'core/assets/js/components/Table.jsx';
import { expandColumnComponent } from 'core/assets/js/lib/utils-jsx';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { PURCHASE_ORDER_STATUS, PURCHASE_ORDER_STATUS_LABEL } from 'projects/assets/js/constants';
import {
  DATE_FORMAT_DEFAULT, DATETIME_FORMAT_HUMAN_FRIENDLY_2, IMG_SIZE,
} from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { Dropdown } from 'react-bootstrap';
import { DOCUMENT_QUERY_SELECTOR_ALL } from 'core/assets/js/config/settings';

const PurchaseOrderInfo = ({ poInfo }) => {
  return (
    poInfo.statusLabel === PURCHASE_ORDER_STATUS_LABEL[PURCHASE_ORDER_STATUS.PENDING]
      ? <p className="my-0">{`${poInfo.statusLabel} request by ${poInfo.managerName}`}</p>
      : <p className="my-0">{`${poInfo.statusLabel} by ${poInfo.reviewerName} on ${formatDate(poInfo.answeredAt, DATE_FORMAT_DEFAULT)}`}</p>
  );
};

PurchaseOrderInfo.propTypes = {
  poInfo: PropTypes.object,
};

PurchaseOrderInfo.defaultProps = {
  poInfo: {},
};

const PurchaseOrderActions = (props) => {
  const { orgAlias, poId, history } = props;

  const handleClick = (e) => {
    e.stopPropagation();
    history.push(
      addBackUrlIfLocationContains(
        financePurchaseOrderViewUrl(orgAlias, poId),
        history.location.pathname,
        '/projects/',
      ),
    );
  };

  return (
    <TDDropButton stopPropagation>
      <Dropdown.Item
        eventKey="view-purchase-order"
        onClick={handleClick}
      >
        View budget request
      </Dropdown.Item>
    </TDDropButton>
  );
};

PurchaseOrderActions.propTypes = {
  history: routerHistorySpec.isRequired,
  poId: PropTypes.number,
  orgAlias: PropTypes.string,
};

PurchaseOrderActions.defaultProps = {
  poId: null,
  orgAlias: '',
};

function PurchaseOrdersTable({
  list,
  hideReference,
  history,
  match,
  embeddedMode,
  ...rest
}) {
  /**
   * prevent default react-bootstrap-table behaviour of expanding when clicking on the whole row
   * making sure that row will only expand when chevron icon is clicked.
   */
  const onTableRowClick = (e) => {
    const tRow = e && e.target && e.target.closest('tr');

    if (
      e && e.target
      && e.target.tagName !== 'I'
      && !e.target.classList.contains('td-drop-button__toggle')
      && tRow.classList.contains('clickable')
      && e.target.tagName !== 'IMG'
    ) {
      const objectId = [...tRow.classList]
        .find(c => c.startsWith('objectId-'))
        .replace('objectId-', '');

      history.push(
        addBackUrlIfLocationContains(
          financePurchaseOrderViewUrl(match.params.orgAlias, objectId),
          history.location.pathname,
          '/projects/',
        ),
      );
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
      .forEach(trow => trow.addEventListener('click', onTableRowClick));
    return () => {
      DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
        .forEach(trow => trow.removeEventListener('click', onTableRowClick));
    };
  });

  const currencySymbol = list.length && list[0].currencySymbol
    ? list[0].currencySymbol
    : '';

  const statusColSpec = {
    key: 'statusCode',
    label: 'Status',
    dataFormat: statusCode => (
      <StatusColFormat statusLabel={PURCHASE_ORDER_STATUS_LABEL[statusCode]} />
    ),
  };

  const managerNameSpec = {
    key: 'managerName',
    label: 'Name',
    width: '350px',
    dataFormat: (cell, po) => (
      <React.Fragment>
        <ProfilePic
          className="mr-3"
          url={po.avatar}
          alt={po.managerName}
          size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
        />
        {po.managerName}
      </React.Fragment>
    ),
  };

  const budgetColSpec = {
    key: 'budget',
    label: 'Amount',
    columnClassName: 'text-right',
    currencySymbol,
    isMoney: true,
  };

  const formatCreatedAt = (createdAt, po) => (
    po.createdAtISO
      ? formatDate(po.createdAtISO, DATETIME_FORMAT_HUMAN_FRIENDLY_2)
      : createdAt
  );

  const purchaseOrdersViewColumns = [
    managerNameSpec,
    { dataFormat: formatCreatedAt, isDate: true, key: 'createdAt', label: 'Date', width: '130px' },
    { key: 'projectDescription', label: 'Project' },
    { key: 'reviewerName', label: 'Reviewer' },
    budgetColSpec,
    statusColSpec,
  ];

  let tableOptions = {
    onRowClick: onTableRowClick,
  };

  // Msg shown for each Po on expand
  if (embeddedMode) {
    tableOptions = {
      ...tableOptions,
      expandableRow: () => true,
      expandComponent: poInfo => <PurchaseOrderInfo poInfo={poInfo} />,
      expandColumnOptions: {
        expandColumnVisible: true,
        expandColumnComponent,
        columnWidth: '30px',
      },
    };
  }

  const embeddedViewColumns = [
    managerNameSpec,
    {
      dataFormat: formatCreatedAt,
      isDate: true,
      key: 'createdAt',
      label: 'Date',
      width: '120px',
    },
    budgetColSpec,
    statusColSpec,
    { key: 'actions', width: '120px', label: '',
      dataAlign: 'right', columnClassName: 'actions-cell',
    },
  ];

  // different columns/format per view (Finance / Project)
  let tableColumns = {};
  if (embeddedMode) {
    tableColumns = embeddedViewColumns;
  } else {
    tableColumns = purchaseOrdersViewColumns;
  }
  const withActions = list.map(po => ({
    ...po,
    actions: (
      <PurchaseOrderActions
        history={history}
        poId={po.id}
        orgAlias={match.params.orgAlias}
      />
    ),
    projectDescription: `${po.projectReference} - ${po.projectTitle}`,
  }));

  // No need to show the reference column
  if (hideReference) {
    tableColumns = filter(tableColumns, col => col.key !== 'projectReference');
  }

  return (
    <Table
      cols={tableColumns}
      items={withActions}
      {...rest}
      {...tableOptions}
      isLarge
      trClassName={(row) => { return `objectId-${row && row.id}`; }}
    />
  );
}

PurchaseOrdersTable.propTypes = {
  list: PropTypes.array,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  hideReference: PropTypes.bool,
  embeddedMode: PropTypes.bool,
};
PurchaseOrdersTable.defaultProps = {
  list: [],
  hideReference: false,
  embeddedMode: false,
};

export default withRouter(PurchaseOrdersTable);
