import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';
import {
  userGroupCreateApiUrl,
  userGroupDeleteApiUrl,
  userGroupEditApiUrl,
  postChatMessageUrl,
  postGuestChatUrl,
  updateChatReadUrl,
  postCreateChatUrl,
  updateGuestChatReadUrl,
  addParticipantsChatUrl,
} from 'people/urls';
import { listRemoveItemAC } from 'core/assets/js/ducks/list';

const createUserGroupDS = ({ values, orgAlias }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: userGroupCreateApiUrl({ orgAlias }),
    values,
  })
);

const editUserGroupDS = ({ values, orgAlias, userGroupId }) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: userGroupEditApiUrl({ orgAlias, userGroupId }),
    values,
  })
);

const deleteUserGroupDS = ({ componentName, orgAlias, userGroupId }) => (
  pushDataDS({
    reqType: REQ_TYPE.DELETE,
    pushApiUrl: userGroupDeleteApiUrl({ orgAlias, userGroupId }),
    pushDataAC: responseData => (
      listRemoveItemAC(responseData.userGroup.id, componentName)
    ),
  })
);

export const postChatMessage = ({ orgAlias, pushDataAC, values }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: postChatMessageUrl({ orgAlias }),
    values,
    pushDataAC,
  })
);

export const updateChatRead = ({ orgAlias, chatId, pushDataAC, values }) => (
  pushDataDS({
    pushApiUrl: updateChatReadUrl({ orgAlias, chatId }),
    reqType: REQ_TYPE.PUT,
    values,
    pushDataAC,
  })
);

export const postGuestChatMessage = ({ pushDataAC, token, values }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: postGuestChatUrl(token),
    values,
    pushDataAC,
  })
);

export const updateGuestChatRead = ({ chatId, pushDataAC, token, values }) => (
  pushDataDS({
    pushApiUrl: updateGuestChatReadUrl(chatId, token),
    reqType: REQ_TYPE.PUT,
    values,
    pushDataAC,
  })
);

export const postCreateChat = ({ orgAlias, pushDataAC, values }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: postCreateChatUrl({ orgAlias }),
    values,
    pushDataAC,
  })
);

export const addAddParticipants = ({ chatId, orgAlias, pushDataAC, values }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: addParticipantsChatUrl({ orgAlias, chatId }),
    values,
    pushDataAC,
  })
);

export {
  createUserGroupDS,
  deleteUserGroupDS,
  editUserGroupDS,
};
