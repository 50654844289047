import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { makeId } from 'core/assets/js/lib/utils';

import { BS_TOOLTIP_PLACEMENT, ICON } from 'core/assets/js/constants';


const TDElementWithTooltip = ({ el, id, placement, tooltipMsg, children, delay, offset }) => {
  if (!tooltipMsg) {
    return children || el;
  }

  const tooltip = (
    <Tooltip
      id={id || (`${Date.now()}-${makeId()}`)}
    >
      {tooltipMsg}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      popperConfig={{
        modifiers: [
          { name: 'offset', options: { offset } },
        ],
      }}
      delay={{ show: delay, hide: 0 }}
      placement={placement}
      overlay={tooltip}
    >
      {children || el}
    </OverlayTrigger>
  );
};
TDElementWithTooltip.propTypes = {
  delay: PropTypes.number,
  el: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placement: PropTypes.oneOf(Object.values(BS_TOOLTIP_PLACEMENT)),
  tooltipMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  offset: PropTypes.array,
};
TDElementWithTooltip.defaultProps = {
  delay: undefined,
  el: <i className={ICON.INFO} />,
  id: null,
  children: null,
  offset: [0, 0],
  placement: 'top',
  tooltipMsg: 'N/A',
};

export default TDElementWithTooltip;
