import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isArray, pick } from 'lodash';
import queryString from 'query-string';

import { ORDERING_DIRECTION } from 'core/assets/js/constants';
import SelectableListFilterField from 'core/assets/js/components/FilterFields/SelectableListFilterField.jsx';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { PROJECT_APPLICATION_STATUS, PROJECT_APPLICATION_STATUS_LABEL } from 'projects/assets/js/constants';
import SearchForm from 'core/assets/js/components/SearchForm.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';

class ProjectApplicationsSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();


    const filtersList = [];
    searchSpec.filters.forEach((f) => {
      // Add all paramNames if is array
      if (isArray(f.paramName)) {
        f.paramName.forEach(paramName => filtersList.push(paramName));
      }

      filtersList.push(f.paramName);
    });

    let values = queryString.parse(location.search);
    values = pick(values, ['kw', 'ordering'].concat(filtersList));

    return values;
  }

  getSearchSpec() {
    // Prepare Status filter options
    const statusOptions = Object.keys(PROJECT_APPLICATION_STATUS).map(status => ({
      text: PROJECT_APPLICATION_STATUS_LABEL[PROJECT_APPLICATION_STATUS[status]],
      value: PROJECT_APPLICATION_STATUS[status],
    }));

    const filters = [
      {
        label: 'Status',
        paramName: 'status',
        fieldComponent: props => (
          <AllowAccessToManager>
            <SelectableListFilterField {...props} />
          </AllowAccessToManager>
        ),
        multiple: true,
        options: statusOptions,
      },
    ];

    const spec = {
      defaultOrdering: {
        direction: ORDERING_DIRECTION.DESC,
        sortBy: 'created_at',
      },
      orderingOptions: [
        { text: 'Application date', value: 'created_at' },
      ],
      searchTerm: {
        placeholder: 'Search applicants',
        paramName: 'kw',
        component: InputField,
      },
      filters,
    };

    return spec;
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;

    return (
      <SearchForm
        name="projectMembers"
        initialValues={this.getInitialValues()}
        searchSpec={this.getSearchSpec()}
        onFiltersToggle={onFiltersToggle}
        filtersOpen={filtersOpen}
      />
    );
  }
}

ProjectApplicationsSearch.propTypes = {
  activeOrg: orgSpec.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  rateLimits: PropTypes.object,
};

ProjectApplicationsSearch.defaultProps = {
  rateLimits: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});
const ProjectMemberSearchConnect = connect(
  mapStateToProps,
)(ProjectApplicationsSearch);

export default withRouter(ProjectMemberSearchConnect);
