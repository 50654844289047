import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import OrgInviteesList from 'people/assets/js/components/OrgInviteesList.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { orgPeopleInviteesUrl } from 'people/urls';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { getViewState } from 'core/assets/js/ducks/view';

class OrgInviteeListView extends React.Component {
  static GetComponentName() {
    return 'OrgInviteeListView';
  }

  render() {
    const { onFiltersToggle, filtersOpen, match, location, interview } = this.props;
    const { orgAlias } = match.params;
    const parsedSearch = queryString.parse(location.search);
    const interviewId = parsedSearch.interviewId;

    let breadcrumbs = [
      {
        title: 'Invitees',
        url: null,
      },
    ];

    if (interviewId) {
      breadcrumbs = [{
        title: 'Invitees',
        url: orgPeopleInviteesUrl(orgAlias),
      }, {
        title: interview.name,
        url: null,
      }];
    }

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--people">
          <div className="container mt-4">
            <OrgInviteesList
              filtersOpen={filtersOpen}
              onFiltersToggle={onFiltersToggle}
              interviewId={interviewId}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OrgInviteeListView.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  interview: PropTypes.object,
};

OrgInviteeListView.defaultProps = {
  interview: {},
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, OrgInviteeListView.GetComponentName());
  return {
    interview: viewState.item,
  };
};


const OrgInviteeListViewConnect = connect(
  mapStateToProps,
  null,
)(OrgInviteeListView);

export default withRouter(withFilters(OrgInviteeListViewConnect));
