import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { projectTaskSpec, taskAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { BS_TOOLTIP_PLACEMENT, ICON, BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { watchTaskDS, unwatchTaskDS } from 'projects/assets/js/data-services/tasks';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

class TaskWatchWidget extends React.Component {
  constructor(props) {
    super(props);

    this.handleTaskWatched = this.handleTaskWatched.bind(this);
    this.handleTaskUnwatched = this.handleTaskUnwatched.bind(this);
  }

  async handleTaskWatched() {
    const {
      dispatch,
      match: { params: { orgAlias, id: projectId } },
      task: { id: taskId },
      onStateUpdated,
    } = this.props;

    try {
      await dispatch(watchTaskDS(orgAlias, projectId, taskId));
      await onStateUpdated();
      toastr.success('Well Done!', 'You are now watching the task');
    } catch (err) {
      const error = err.errors ? err.errors._error : err._error;
      toastr.error('Oops!', (error || 'Could not watch the task'));
    }
  }

  async handleTaskUnwatched() {
    const {
      dispatch,
      match: { params: { orgAlias, id: projectId } },
      task: { id: taskId },
      onStateUpdated,
    } = this.props;

    try {
      await dispatch(unwatchTaskDS(orgAlias, projectId, taskId));
      await onStateUpdated();
      toastr.success('Well Done!', 'You are no longer watching the task');
    } catch (err) {
      const error = err.errors ? err.errors._error : err._error;
      toastr.error('Oops!', (error || 'Could not unwatch the task'));
    }
  }

  render() {
    const {
      allowedActions: { isAssignee, isOwner, isWatching, canWatch, canUnwatch },
    } = this.props;
    const isDisabled = (isWatching && !canUnwatch) || (!isWatching && !canWatch);
    const iconClass = isWatching ? ICON.UNWATCH : ICON.WATCH;
    const icon = (
      <i className={iconClass} />
    );

    let disabledReason;
    if (isOwner) {
      disabledReason = 'You can’t unwatch the task because you’re the owner';
    } else if (isAssignee) {
      disabledReason = 'You can’t unwatch the task because you’re assigned to it';
    }

    if (isDisabled && disabledReason) {
      return (
        <TDElementWithTooltip
          placement={BS_TOOLTIP_PLACEMENT.TOP}
          tooltipMsg={disabledReason}
        >
          {icon}
        </TDElementWithTooltip>
      );
    }


    return (
      <TDDropButton
        toggleEl={(
          <TDButton
            className="p-0"
            variant={BS_STYLE.LINK}
            tooltipMsg={isWatching ? 'Unwatch' : 'Watch'}
            tooltipPosition={BS_TOOLTIP_PLACEMENT.TOP}
            bsSize={BS_SIZE.XSMALL}
          >
            {icon}
          </TDButton>
        )}
      >
        <ul
          className="m-0 p-0"
          style={{
            width: '250px',
          }}
        >
          <li>
            <a
              className="d-flex"
              onClick={() => !isWatching && this.handleTaskWatched()}
            >
              <div className="col-1">
                {isWatching && (
                  <i className={`text-primary ${ICON.CHECKMARK}`} />
                )}
              </div>
              <div className="col-11">
                <strong>
                  {isWatching ? 'Watching' : 'Watch'}
                </strong>
                <p className="discreet mt-2 mb-0">
                  Be notified of all important task related action
                </p>
              </div>
            </a>
          </li>
          <li>
            <a
              className="d-flex"
              onClick={() => isWatching && this.handleTaskUnwatched()}
            >
              <div className="col-1">
                {!isWatching && (
                  <i className={`text-primary ${ICON.CHECKMARK}`} />
                )}
              </div>
              <div className="col-11">
                <strong>
                  {isWatching ? 'Unwatch' : 'Not watching'}
                </strong>
                <p className="discreet mt-2 mb-0">
                  Never be notified
                </p>
              </div>
            </a>
          </li>
        </ul>
      </TDDropButton>
    );
  }
}

TaskWatchWidget.propTypes = {
  dispatch: PropTypes.func.isRequired,
  task: projectTaskSpec.isRequired,
  allowedActions: taskAllowedActionsSpec.isRequired,
  match: routerMatchSpec.isRequired,
  onStateUpdated: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskWatchWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskWatchWidget);

export default withRouter(TaskWatchWidgetConnected);
