import { isEmpty } from 'lodash';
import LicenceFeeScheme from 'finance/assets/js/lib/LicenceFeeScheme';
import ProcessingFeeScheme from 'finance/assets/js/lib/ProcessingFeeScheme';
import { CURRENCY } from 'core/assets/js/constants';

class InvoicingFeeScheme {
  static fromDefaultPricingScale(defaultPricingScale, { currency }) {
    if (!defaultPricingScale) {
      throw new Error('defaultPricingScale is required');
    }
    if (!currency) {
      throw new Error('currency is required');
    }
    return new InvoicingFeeScheme({
      processing: {
        currency,
        floor: '0.00',
        perApprovedWorksheet: '0.00',
        scale: defaultPricingScale.scheme,
      },
      licence: {
        currency: CURRENCY.USD,
        baseLicenceFee: '449.00',
        baseManagerSeats: 3,
        baseProviderSeats: 0,
        perManagerSeat: '99.00',
        perProviderSeat: '0.00',
      },
    });
  }

  static fromLegacy({ processingScheme, licenceScheme }) {
    const processing = new ProcessingFeeScheme(processingScheme.spec);
    const licence = new LicenceFeeScheme(licenceScheme.spec);
    return new InvoicingFeeScheme({
      processing: processing.serialize(),
      licence: licence.serialize(),
    });
  }

  constructor(feeScheme, { currency } = {}) {
    if (isEmpty(feeScheme)) {
      if (currency) {
        this.init({ processing: { currency }, licence: { currency } });
      } else {
        throw new Error('empty fee scheme');
      }
    } else {
      let serializedFeeScheme = feeScheme;
      if (feeScheme instanceof InvoicingFeeScheme) {
        // return a copy
        serializedFeeScheme = feeScheme.serialize();
      } else if (feeScheme.processingScheme || feeScheme.licenceScheme) {
        serializedFeeScheme = InvoicingFeeScheme.fromLegacy(feeScheme).serialize();
      }
      if (typeof serializedFeeScheme.processing === 'undefined' || typeof serializedFeeScheme.licence === 'undefined') {
        throw new Error(`fee scheme '${JSON.stringify(feeScheme)}' is malformed`);
      }
      this.init({
        processing: {
          ...serializedFeeScheme.processing,
          currency: currency || serializedFeeScheme.processing.currency,
        },
        licence: { currency, ...serializedFeeScheme.licence },
      });
    }
  }

  init({ processing = {}, licence = {} } = {}) {
    this.processingScheme = new ProcessingFeeScheme(processing || {});
    this.licenceScheme = new LicenceFeeScheme(licence || {});
  }

  serialize() {
    return this.spec;
  }

  toString() {
    return JSON.stringify(this.serialize(), null, 2);
  }

  get spec() {
    return {
      processing: this.processingScheme.serialize(),
      licence: this.licenceScheme.serialize(),
    };
  }

  isEmpty() {
    const { processingScheme, licenceScheme } = this;
    return processingScheme.isEmpty() && licenceScheme.isEmpty();
  }

  isZero() {
    const { processingScheme, licenceScheme } = this;
    return processingScheme.isZero() && licenceScheme.isZero();
  }

  getProcessingFee(amount, numApprovedWorksheets) {
    return this.processingScheme.getFee(amount, numApprovedWorksheets);
  }

  applyProcessingFee(amount, numApprovedWorksheets) {
    return this.processingScheme.apply(amount, numApprovedWorksheets);
  }

  getLicenceFee(...props) {
    return this.licenceScheme.getFee(...props);
  }

  applyLicenceFee(...props) {
    return this.licenceScheme.apply(...props);
  }

  getProcessingScheme() {
    return this.processingScheme;
  }

  setLicenceScheme(licenceScheme) {
    if (!(licenceScheme instanceof LicenceFeeScheme)) {
      throw new Error('argument must be of instance LicenceFeeScheme');
    }
    this.licenceScheme = licenceScheme;
  }

  getLicenceScheme() {
    return this.licenceScheme;
  }

  getProcessingFeeSpec() {
    return this.processingScheme.serialize();
  }

  getLicenceFeeSpec() {
    return this.licenceScheme.serialize();
  }

  hasFee() {
    return this.hasProcessingFee() || this.hasLicenceFee();
  }

  hasProcessingFee() {
    return this.processingScheme.hasFee();
  }

  hasLicenceFee() {
    return this.licenceScheme.hasFee();
  }

  hasLicenceFeeByRegistrationNumber(systemRegistrationNumber) {
    return this.licenceScheme.hasFeeByRegistrationNumber(systemRegistrationNumber);
  }

  hasLicenceFeeForAnyRegistrationNumber() {
    const systemNumbers = this.getLicenceScheme().getSystemRegistrationNumbers();
    return systemNumbers.some(
      systemNumber => this.hasLicenceFeeByRegistrationNumber(systemNumber),
    );
  }

  isFixed() {
    const { processingScheme, licenceScheme } = this;
    return processingScheme.isFixed() && licenceScheme.isFixed();
  }
}

export default InvoicingFeeScheme;
