/* eslint-disable react/no-unused-prop-types */
/* Wrapper component for the withLiveUpdates HOC */
import React from 'react';
import PropTypes from 'prop-types';

import withLiveUpdates from 'core/assets/js/components/withLiveUpdates.jsx';

const TDLiveUpdated = ({ children }) => (
  <React.Fragment>
    {children}
  </React.Fragment>
);

TDLiveUpdated.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  channel: PropTypes.string.isRequired,
  events: PropTypes.object,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
  onLimitReached: PropTypes.func,
  onError: PropTypes.func,
};

TDLiveUpdated.defaultProps = {
  events: {},
  onConnected: () => { },
  onDisconnected: () => { },
  onLimitReached: () => { },
  onError: () => { },
};

export default withLiveUpdates(TDLiveUpdated);
