import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { interviewSpec } from 'interviews/assets/js/lib/objectSpecs';
import { interviewListApiUrl } from 'interviews/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { toggleEmploymentStatusDS } from 'people/assets/js/ducks/people';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

export const getModalId = userId => `toggle-employment-id-${userId}`;

export const interviewListKey = componentName => `${componentName}-interviews-list`;

const ToggleEmploymentModal = ({
  activeOrg: { alias: orgAlias, onboarding_forms_are_mandatory: onboardingFormsAreMandatory },
  cannotConvertToEmployee,
  dispatch,
  interviews,
  isModalOpen,
  listViewComponentName,
  toContractor,
  userId,
  userName,
}) => {
  const [loading, setLoading] = useState(false);

  const newStatus = toContractor ? 'a contractor' : 'an employee';

  const onSubmit = async ({ onboardingFormId } = {}) => {
    setLoading(true);
    try {
      await dispatch(toggleEmploymentStatusDS({
        componentName: listViewComponentName,
        onboardingFormId,
        orgAlias,
        userId,
      }));
      toastr.success('Well Done!', `${userName} is now ${newStatus}.`);
      setLoading(false);
    } catch (error) {
      const message = error.response?.data?._error || error.message;
      toastr.error('Oh Snap!', message);
      setLoading(false);
      // required to prevent onConfirmSuccess in ModalConfirm being called
      throw new Error(message);
    }
  };

  const commonModalProperties = {
    confirmLabel: 'Convert',
    'data-testid': 'toggle-employment-modal',
    onClose: () => dispatch(modalCloseAC()),
    open: isModalOpen,
  };

  if (toContractor) {
    const onboardingFormOptions = interviews.map(i => ({ text: i.name, value: i.id }));
    const hasOnboardingFormOptions = onboardingFormOptions.length > 0;
    const onboardingFormMandatoryNoOptions = (
      onboardingFormsAreMandatory && !hasOnboardingFormOptions
    );

    const interviewListStoreKey = interviewListKey(listViewComponentName);

    return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form>
            <ModalConfirm
              confirmButtonDisabled={loading || onboardingFormMandatoryNoOptions}
              heading={`Convert ${userName} to be a contractor?`}
              onConfirm={handleSubmit}
              showConfirmButton={!onboardingFormMandatoryNoOptions}
              size={hasOnboardingFormOptions ? MODAL_SIZES.LARGE : MODAL_SIZES.SMALL}
              {...commonModalProperties}
            >
              <TDApiConnected
                duck="list"
                fetchData={({ authedAxios, url }) => dispatch(fetchListDS({
                  authedAxios,
                  componentName: interviewListStoreKey,
                  url: interviewListApiUrl(orgAlias, url),
                }))}
                storeKey={interviewListStoreKey}
              >
                {!onboardingFormMandatoryNoOptions && (
                  <>
                    <p>
                      {`Are you sure you want to convert ${userName} `}
                      to a contractor in this organization?
                    </p>
                    <p>
                      Please note that when an Employee is converted to a Provider while they have
                      approved service orders in the current billing period. These service orders
                      will be included in the next invoice run.
                    </p>
                    {hasOnboardingFormOptions && (
                      <>
                        <p>
                          A new onboarding submission will be created for them and they will be
                          emailed a link to complete it, before having access to the organization
                          again.
                        </p>
                        <p>
                          {onboardingFormsAreMandatory && 'Please '}
                          {!onboardingFormsAreMandatory && 'Optionally '}
                          select the onboarding form they should be sent:
                        </p>
                        <SelectField
                          data-testid="onboarding-form-selector"
                          label="Onboarding form"
                          name="onboardingFormId"
                          optionsMapping={onboardingFormOptions}
                          required={onboardingFormsAreMandatory}
                        />
                      </>
                    )}
                    <p>You can convert them to an employee afterwards, if required.</p>
                  </>
                )}
                {onboardingFormMandatoryNoOptions && (
                  <TDSystemMessage
                    data-testid="toggle-employment-modal-cannot-message"
                    title="Cannot convert to contractor"
                    type={BS_STYLE.DANGER}
                  >
                    <p>
                      Your organization does not have any onboarding forms, so you cannot convert
                      this employee to be a contractor.
                    </p>
                  </TDSystemMessage>
                )}
              </TDApiConnected>
            </ModalConfirm>
          </form>
        )}
      />
    );
  }

  return (
    <ModalConfirm
      confirmButtonDisabled={loading || cannotConvertToEmployee}
      heading={`Convert ${userName} to be an employee`}
      onConfirm={onSubmit}
      showConfirmButton={!cannotConvertToEmployee}
      {...commonModalProperties}
    >
      {cannotConvertToEmployee && (
        <TDSystemMessage
          data-testid="convert-to-employee-cannot-message"
          title={`Cannot convert ${userName} to be an employee`}
          type={BS_STYLE.DANGER}
        >
          <p>
            {`${userName} has outstanding worksheets, expenses or Proforma Invoices. So they cannot `}
            be converted to an employee.
          </p>
          <p>
            Please reject them, void them or wait for them to be processed on your
            organization&apos;s next billing cycle.
          </p>
        </TDSystemMessage>
      )}
      {!cannotConvertToEmployee && (
        <>
          <p>
            {`Are you sure you want to convert ${userName} `}
            to be an employee in this organization?
          </p>
          <p>You can convert them to a contractor afterwards, if required.</p>
        </>
      )}
    </ModalConfirm>
  );
};

ToggleEmploymentModal.propTypes = {
  activeOrg: orgSpec.isRequired,
  cannotConvertToEmployee: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  interviews: PropTypes.arrayOf(interviewSpec),
  isModalOpen: PropTypes.bool.isRequired,
  listViewComponentName: PropTypes.string.isRequired,
  toContractor: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

ToggleEmploymentModal.defaultProps = {
  interviews: [],
};

const mapStateToProps = (state, props) => ({
  activeOrg: selectActiveOrg(state),
  interviews: getListState(state, interviewListKey(props.listViewComponentName)).items,
  isModalOpen: getIsModalOpen(state, getModalId(props.userId)),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const ToggleEmploymentModalConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ToggleEmploymentModal);

export default ToggleEmploymentModalConnected;
