export const isoCountries = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, Democratic Republic',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: 'Cote D\'Ivoire',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island & Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle Of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Lao People\'s Democratic Republic',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States Of',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia And Sandwich Isl.',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis And Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const userAssignedCountries = {
  // https://en.wikipedia.org/wiki/XK_(user_assigned_code)
  XK: 'Kosovo',
};

export const countryNames = {
  ...isoCountries,
  ...userAssignedCountries,
};

export const countryCurrencies = {
  AD: 'eur',
  AE: 'aed',
  AF: 'afn',
  AG: 'xcd',
  AI: 'xcd',
  AL: 'all',
  AM: 'amd',
  AN: 'ang',
  AO: 'aoa',
  AR: 'ars',
  AS: 'usd',
  AT: 'eur',
  AU: 'aud',
  AW: 'awg',
  AX: 'eur',
  AZ: 'azn',
  BA: 'bam',
  BB: 'bbd',
  BD: 'bdt',
  BE: 'eur',
  BF: 'xof',
  BG: 'bgn',
  BH: 'bhd',
  BI: 'bif',
  BJ: 'xof',
  BM: 'bmd',
  BN: 'bnd',
  BO: 'bob',
  BR: 'brl',
  BS: 'bsd',
  BT: 'btn',
  BV: 'nok',
  BW: 'bwp',
  BY: 'byr',
  BZ: 'bzd',
  CA: 'cad',
  CC: 'aud',
  CD: 'cdf',
  CF: 'xaf',
  CG: 'xaf',
  CH: 'chf',
  CI: 'xof',
  CK: 'nzd',
  CL: 'clp',
  CM: 'xaf',
  CN: 'cny',
  CO: 'cop',
  CR: 'crc',
  CU: 'cup',
  CV: 'cve',
  CX: 'aud',
  CY: 'eur',
  CZ: 'czk',
  DE: 'eur',
  DJ: 'djf',
  DK: 'dkk',
  DM: 'xcd',
  DO: 'dop',
  DZ: 'dzd',
  EC: 'usd',
  EE: 'eur',
  EG: 'egp',
  EH: 'mad',
  ER: 'ern',
  ES: 'eur',
  ET: 'etb',
  FI: 'eur',
  FJ: 'fjd',
  FK: 'fkp',
  FM: 'usd',
  FO: 'dkk',
  FR: 'eur',
  GA: 'xaf',
  GB: 'gbp',
  GD: 'xcd',
  GE: 'gel',
  GF: 'eur',
  GG: 'gbp',
  GH: 'ghs',
  GI: 'gip',
  GL: 'dkk',
  GM: 'gmd',
  GN: 'gnf',
  GP: 'eur',
  GQ: 'xaf',
  GR: 'eur',
  GS: 'gbp',
  GT: 'gtq',
  GU: 'usd',
  GW: 'xof',
  GY: 'gyd',
  HK: 'hkd',
  HM: 'aud',
  HN: 'hnl',
  HR: 'hrk',
  HT: 'htg',
  HU: 'huf',
  ID: 'idr',
  IE: 'eur',
  IL: 'ils',
  IM: 'gbp',
  IN: 'inr',
  IO: 'usd',
  IQ: 'iqd',
  IR: 'irr',
  IS: 'isk',
  IT: 'eur',
  JE: 'gbp',
  JM: 'jmd',
  JO: 'jod',
  JP: 'jpy',
  KE: 'kes',
  KG: 'kgs',
  KH: 'khr',
  KI: 'aud',
  KM: 'kmf',
  KN: 'xcd',
  KP: 'kpw',
  KR: 'krw',
  KW: 'kwd',
  KY: 'kyd',
  KZ: 'kzt',
  LA: 'lak',
  LB: 'lbp',
  LC: 'xcd',
  LI: 'chf',
  LK: 'lkr',
  LR: 'lrd',
  LS: 'lsl',
  LT: 'ltl',
  LU: 'eur',
  LV: 'eur',
  LY: 'lyd',
  MA: 'mad',
  MC: 'eur',
  MD: 'mdl',
  ME: 'eur',
  MG: 'mga',
  MH: 'usd',
  MK: 'mkd',
  ML: 'xof',
  MM: 'mmk',
  MN: 'mnt',
  MO: 'mop',
  MP: 'usd',
  MQ: 'eur',
  MR: 'mro',
  MS: 'xcd',
  MT: 'eur',
  MU: 'mur',
  MV: 'mvr',
  MW: 'mwk',
  MX: 'mxn',
  MY: 'myr',
  MZ: 'mzn',
  NA: 'nad',
  NC: 'xpf',
  NE: 'xof',
  NF: 'aud',
  NG: 'ngn',
  NI: 'nio',
  NL: 'eur',
  NO: 'nok',
  NP: 'npr',
  NR: 'aud',
  NU: 'nzd',
  NZ: 'nzd',
  OM: 'omr',
  PA: 'pab',
  PE: 'pen',
  PF: 'xpf',
  PG: 'pgk',
  PH: 'php',
  PK: 'pkr',
  PL: 'pln',
  PM: 'eur',
  PN: 'nzd',
  PR: 'usd',
  PS: 'ils',
  PT: 'eur',
  PW: 'usd',
  PY: 'pyg',
  QA: 'qar',
  RE: 'eur',
  RO: 'ron',
  RS: 'rsd',
  RU: 'rub',
  RW: 'rwf',
  SA: 'sar',
  SB: 'sbd',
  SC: 'scr',
  SD: 'sdg',
  SE: 'sek',
  SG: 'sgd',
  SH: 'shp',
  SI: 'eur',
  SJ: 'nok',
  SK: 'eur',
  SL: 'sll',
  SM: 'eur',
  SN: 'xof',
  SO: 'sos',
  SR: 'srd',
  ST: 'std',
  SV: 'usd',
  SY: 'syp',
  SZ: 'szl',
  TC: 'usd',
  TD: 'xaf',
  TF: 'eur',
  TG: 'xof',
  TH: 'thb',
  TJ: 'tjs',
  TK: 'nzd',
  TL: 'usd',
  TM: 'tmt',
  TN: 'tnd',
  TO: 'top',
  TR: 'try',
  TT: 'ttd',
  TV: 'aud',
  TW: 'twd',
  TZ: 'tzs',
  UA: 'uah',
  UG: 'ugx',
  UM: 'usd',
  US: 'usd',
  UY: 'uyu',
  UZ: 'uzs',
  VA: 'eur',
  VC: 'xcd',
  VE: 'vef',
  VG: 'usd',
  VI: 'usd',
  VN: 'vnd',
  VU: 'vuv',
  WF: 'xpf',
  WS: 'wst',
  XK: 'eur',
  YE: 'yer',
  YT: 'eur',
  ZA: 'zar',
  ZM: 'zmk',
  ZW: 'zwl',
};

const countryToCode = Object.entries(countryNames).reduce((ret, entry) => {
  const [code, name] = entry;
  return {
    ...ret,
    [name.toLowerCase()]: code,
  };
}, {});

export const localCurrencyCoutries = [
  'AR', 'AU', 'BR', 'CA', 'CH', 'CL', 'CZ', 'DK', 'GB', 'HK',
  'HU', 'ID', 'LV', 'MX', 'MY', 'NO', 'NZ', 'PL', 'RO', 'SE',
  'SG', 'TR', 'UY', 'ZA',
];

export const countryOptions = Object.keys(isoCountries).map(k => ({
  value: k, text: isoCountries[k],
}));

// country options that contain ISO countries and user assigned codes
export const extendedCountryOptions = [
  ...countryOptions,
  ...Object.keys(userAssignedCountries).map(k => ({
    value: k, text: userAssignedCountries[k],
  })),
].sort(({ text: a }, { text: b }) => {
  if (a < b) { return -1; }
  if (b > a) { return 1; }
  return 0;
});

export function getCountryName(countryCode) {
  if (countryNames[countryCode]) {
    return countryNames[countryCode];
  }
  return countryCode;
}

export function getCountryCode(countryName) {
  if (countryToCode[countryName.toLowerCase()]) {
    return countryToCode[countryName.toLowerCase()];
  }
  return null;
}

const alpha3CountryCodes = {
  AF: 'AFG',
  AX: 'ALA',
  AL: 'ALB',
  DZ: 'DZA',
  AS: 'ASM',
  AD: 'AND',
  AO: 'AGO',
  AI: 'AIA',
  AQ: 'ATA',
  AG: 'ATG',
  AR: 'ARG',
  AM: 'ARM',
  AW: 'ABW',
  AU: 'AUS',
  AT: 'AUT',
  AZ: 'AZE',
  BS: 'BHS',
  BH: 'BHR',
  BD: 'BGD',
  BB: 'BRB',
  BY: 'BLR',
  BE: 'BEL',
  BZ: 'BLZ',
  BJ: 'BEN',
  BM: 'BMU',
  BT: 'BTN',
  BO: 'BOL',
  BQ: 'BES',
  BA: 'BIH',
  BW: 'BWA',
  BV: 'BVT',
  BR: 'BRA',
  IO: 'IOT',
  BN: 'BRN',
  BG: 'BGR',
  BF: 'BFA',
  BI: 'BDI',
  CV: 'CPV',
  KH: 'KHM',
  CM: 'CMR',
  CA: 'CAN',
  KY: 'CYM',
  CF: 'CAF',
  TD: 'TCD',
  CL: 'CHL',
  CN: 'CHN',
  CX: 'CXR',
  CC: 'CCK',
  CO: 'COL',
  KM: 'COM',
  CG: 'COG',
  CD: 'COD',
  CK: 'COK',
  CR: 'CRI',
  CI: 'CIV',
  HR: 'HRV',
  CU: 'CUB',
  CW: 'CUW',
  CY: 'CYP',
  CZ: 'CZE',
  DK: 'DNK',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  EC: 'ECU',
  EG: 'EGY',
  SV: 'SLV',
  GQ: 'GNQ',
  ER: 'ERI',
  EE: 'EST',
  SZ: 'SWZ',
  ET: 'ETH',
  FK: 'FLK',
  FO: 'FRO',
  FJ: 'FJI',
  FI: 'FIN',
  FR: 'FRA',
  GF: 'GUF',
  PF: 'PYF',
  TF: 'ATF',
  GA: 'GAB',
  GM: 'GMB',
  GE: 'GEO',
  DE: 'DEU',
  GH: 'GHA',
  GI: 'GIB',
  GR: 'GRC',
  GL: 'GRL',
  GD: 'GRD',
  GP: 'GLP',
  GU: 'GUM',
  GT: 'GTM',
  GG: 'GGY',
  GN: 'GIN',
  GW: 'GNB',
  GY: 'GUY',
  HT: 'HTI',
  HM: 'HMD',
  VA: 'VAT',
  HN: 'HND',
  HK: 'HKG',
  HU: 'HUN',
  IS: 'ISL',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  IE: 'IRL',
  IM: 'IMN',
  IL: 'ISR',
  IT: 'ITA',
  JM: 'JAM',
  JP: 'JPN',
  JE: 'JEY',
  JO: 'JOR',
  KZ: 'KAZ',
  KE: 'KEN',
  KI: 'KIR',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KWT',
  KG: 'KGZ',
  LA: 'LAO',
  LV: 'LVA',
  LB: 'LBN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MO: 'MAC',
  MG: 'MDG',
  MW: 'MWI',
  MY: 'MYS',
  MV: 'MDV',
  ML: 'MLI',
  MT: 'MLT',
  MH: 'MHL',
  MQ: 'MTQ',
  MR: 'MRT',
  MU: 'MUS',
  YT: 'MYT',
  MX: 'MEX',
  FM: 'FSM',
  MD: 'MDA',
  MC: 'MCO',
  MN: 'MNG',
  ME: 'MNE',
  MS: 'MSR',
  MA: 'MAR',
  MZ: 'MOZ',
  MM: 'MMR',
  NA: 'NAM',
  NR: 'NRU',
  NP: 'NPL',
  NL: 'NLD',
  NC: 'NCL',
  NZ: 'NZL',
  NI: 'NIC',
  NE: 'NER',
  NG: 'NGA',
  NU: 'NIU',
  NF: 'NFK',
  MK: 'MKD',
  MP: 'MNP',
  NO: 'NOR',
  OM: 'OMN',
  PK: 'PAK',
  PW: 'PLW',
  PS: 'PSE',
  PA: 'PAN',
  PG: 'PNG',
  PY: 'PRY',
  PE: 'PER',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PT: 'PRT',
  PR: 'PRI',
  QA: 'QAT',
  RE: 'REU',
  RO: 'ROU',
  RU: 'RUS',
  RW: 'RWA',
  BL: 'BLM',
  SH: 'SHN',
  KN: 'KNA',
  LC: 'LCA',
  MF: 'MAF',
  PM: 'SPM',
  VC: 'VCT',
  WS: 'WSM',
  SM: 'SMR',
  ST: 'STP',
  SA: 'SAU',
  SN: 'SEN',
  RS: 'SRB',
  SC: 'SYC',
  SL: 'SLE',
  SG: 'SGP',
  SX: 'SXM',
  SK: 'SVK',
  SI: 'SVN',
  SB: 'SLB',
  SO: 'SOM',
  ZA: 'ZAF',
  GS: 'SGS',
  SS: 'SSD',
  ES: 'ESP',
  LK: 'LKA',
  SD: 'SDN',
  SR: 'SUR',
  SJ: 'SJM',
  SE: 'SWE',
  CH: 'CHE',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TZ: 'TZA',
  TH: 'THA',
  TL: 'TLS',
  TG: 'TGO',
  TK: 'TKL',
  TO: 'TON',
  TT: 'TTO',
  TN: 'TUN',
  TR: 'TUR',
  TM: 'TKM',
  TC: 'TCA',
  TV: 'TUV',
  UG: 'UGA',
  UA: 'UKR',
  AE: 'ARE',
  GB: 'GBR',
  US: 'USA',
  UM: 'UMI',
  UY: 'URY',
  UZ: 'UZB',
  VU: 'VUT',
  VE: 'VEN',
  VN: 'VNM',
  VG: 'VGB',
  VI: 'VIR',
  WF: 'WLF',
  EH: 'ESH',
  YE: 'YEM',
  ZM: 'ZMB',
  ZW: 'ZWE',
};

export const alpha2ToAlpha3CountryCode = countryCode => alpha3CountryCodes[countryCode];

const alpha2CountryCodes = Object.entries(alpha3CountryCodes).reduce(
  (acc, [alpha2, alpha3]) => {
    acc[alpha3] = alpha2;
    return acc;
  },
  {},
);

export const alpha3ToAlpha2CountryCode = countryCode => alpha2CountryCodes[countryCode];
