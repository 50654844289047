import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import { ICON } from 'core/assets/js/constants';
import { IMPORT_TYPE, IMPORT_TYPE_LABEL } from 'importer/assets/js/constants';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';

const TYPES = [
  {
    icon: ICON.CLIPBOARD_LIST_CHECK_DUOTONE,
    id: IMPORT_TYPE.WORKSHEETS,
    subtext: 'Contractors submit worksheets in order to get paid',
    title: IMPORT_TYPE_LABEL[IMPORT_TYPE.WORKSHEETS],
  },
  {
    icon: ICON.PEN_RULER_DUOTONE,
    id: IMPORT_TYPE.SKILLS,
    subtext: "Organization's custom skills",
    title: IMPORT_TYPE_LABEL[IMPORT_TYPE.SKILLS],
  },
  {
    disabled: (activeOrg, hasPermission) => (
      !activeOrg.bulk_import_users_enabled || !hasPermission(PERMISSIONS.CAN_BULK_IMPORT_USERS)
    ),
    icon: ICON.USERS_DUOTONE,
    id: IMPORT_TYPE.USERS,
    subtext: 'Providers or Managers',
    title: IMPORT_TYPE_LABEL[IMPORT_TYPE.USERS],
  },
  {
    icon: ICON.LIST_CHECK_DUOTONE,
    id: IMPORT_TYPE.PROJECT_AND_TASK_ASSIGNMENTS,
    subtext: 'Assign Providers in bulk to Projects and/or Tasks.',
    title: IMPORT_TYPE_LABEL[IMPORT_TYPE.PROJECT_AND_TASK_ASSIGNMENTS],
  },
  {
    icon: ICON.BAR_PROGRESS_DUOTONE,
    id: IMPORT_TYPE.PROJECTS,
    subtext: 'Contractors are assigned to specific projects that are managed by managers',
    title: IMPORT_TYPE_LABEL[IMPORT_TYPE.PROJECTS],
  },
  {
    icon: ICON.LIST_CHECK_DUOTONE,
    id: IMPORT_TYPE.TASKS,
    subtext: 'Contractors work on tasks that are assigned to them by managers',
    title: IMPORT_TYPE_LABEL[IMPORT_TYPE.TASKS],
  },
];

const ImportCreateTypeOption = ({ item: { icon, subtext, title } }) => (
  <div className="d-flex flex-column align-items-center justify-content-center py-5">
    <i className={`${icon} fa-4x`} />
    <h4>{title}</h4>
    <p className="text-center d-none d-lg-block">{subtext}</p>
  </div>
);

ImportCreateTypeOption.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    subtext: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const ImportCreateType = () => {
  const { change } = useForm();
  const { submitErrors, values } = useFormState();
  const activeOrg = useSelector(selectActiveOrg);
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  return (
    <>
      <h2>Which type of import would you like to do?</h2>
      <CheckableList
        className="bulk-import-checkable-list type"
        disabledItems={TYPES.filter(t => (
          t.disabled && t.disabled(activeOrg, hasPermission)
        ))}
        initialValue={values.type !== undefined ? { id: values.type } : null}
        items={TYPES}
        itemComponent={ImportCreateTypeOption}
        onItemChecked={value => {
          change('type', value.id);
        }}
      />
      {submitErrors?.type && (
        <div className="clearfix mt-3 text-danger">{submitErrors.type}</div>
      )}
    </>
  );
};

export default ImportCreateType;
