import PropTypes from 'prop-types';
import React from 'react';

const ButtonsContainer = ({
  className, primaryButtons, primaryButtonsClassName, secondaryButtons, secondaryButtonsClassName,
}) => {
  const classNames = [
    'buttons-container', 'd-flex', 'justify-content-between', 'justify-content-sm-end',
  ];
  if (className) {
    classNames.push(className);
  }
  const secondaryButtonsClassNames = ['d-flex', 'flex-column', 'flex-sm-row'];
  if (secondaryButtonsClassName) {
    secondaryButtonsClassNames.push(secondaryButtonsClassName);
  }
  const primaryButtonsClassNames = ['d-flex', 'flex-column', 'flex-sm-row'];
  if (primaryButtonsClassName) {
    primaryButtonsClassNames.push(primaryButtonsClassName);
  }
  const thereArePrimaryButtons = primaryButtons.length > 0;
  if (thereArePrimaryButtons) {
    secondaryButtonsClassNames.push('mr-4');
  }
  return (
    <div className={classNames.join(' ')}>
      {secondaryButtons.length > 0 && (
        <div className={secondaryButtonsClassNames.join(' ')}>
          {secondaryButtons}
        </div>
      )}
      {thereArePrimaryButtons && (
        <div className={primaryButtonsClassNames.join(' ')}>
          {primaryButtons}
        </div>
      )}
    </div>
  );
};

ButtonsContainer.propTypes = {
  className: PropTypes.string,
  primaryButtons: PropTypes.arrayOf(PropTypes.node),
  primaryButtonsClassName: PropTypes.string,
  secondaryButtons: PropTypes.arrayOf(PropTypes.node),
  secondaryButtonsClassName: PropTypes.string,
};

ButtonsContainer.defaultProps = {
  className: null,
  primaryButtons: null,
  primaryButtonsClassName: null,
  secondaryButtons: null,
  secondaryButtonsClassName: null,
};

export default ButtonsContainer;
