import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { financeEntityScheme } from 'finance/assets/js/lib/objectSpecs';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import ReportSalesTax from 'finance/assets/js/components/ReportSalesTax.jsx';
import { getReportInfo } from 'settings/assets/js/lib/sales_tax_utils';

class ReportFinancialEntity extends React.PureComponent {
  renderPerson() {
    const { financialEntity } = this.props;
    const entity = new FinancialEntity(financialEntity);
    if (entity.isEmpty()) {
      return null;
    }

    const name = entity.getFullProfileName();
    const formattedAddress = entity.getProfileAddress();

    return (
      <div className="finance-report__financial_entity__person">
        <h4>{ name }</h4>
        { formattedAddress
          && (
            <div className="finance-report__provider__company">
              <div>
                <span>{formattedAddress}</span>
              </div>
            </div>
          )
        }
      </div>
    );
  }

  renderCompany() {
    const { financialEntity, showFullVat } = this.props;
    const entity = new FinancialEntity(financialEntity);
    const company = entity.getCompany();
    if (isEmpty(company)) {
      return null;
    }
    const reportSalesTaxInfo = getReportInfo(company);
    const name = get(company, 'name');
    const formattedAddress = entity.getAddress();
    const freeBillToText = get(company, 'free_bill_to_text');

    return (
      <div className="mb-2 finance-report__financial_entity__company">
        <h4>{name}</h4>
        <div className="finance-report__provider__company">
          { formattedAddress
            && (
              <div>
                <span>{formattedAddress}</span>
              </div>
            )
          }
          <ReportSalesTax
            showFullVat={showFullVat}
            {...reportSalesTaxInfo}
          />
          { freeBillToText
            && (
              <div className="mt-3">
                <span>{freeBillToText}</span>
              </div>
            )
          }
        </div>
      </div>
    );
  }

  render() {
    const { label, financialEntity, showFreeText } = this.props;
    if (!financialEntity) {
      return null;
    }

    const entity = new FinancialEntity(financialEntity);

    // explicit comparison to false for backwards compatibility
    const shouldRenderCompany = entity.getCompany() && (entity.getCompany().invoicable !== false);
    const freeBillingDetailsText = entity.getFreeBillingDetailsText();

    return (
      <div className="mt-4" data-testid="report-financial-entity">
        { label && (
          <h5>{label}</h5>
        )}
        { shouldRenderCompany ? this.renderCompany() : this.renderPerson() }
        { showFreeText && freeBillingDetailsText && entity.isContractor() && (
          <div>
            {freeBillingDetailsText}
          </div>
        )}
      </div>
    );
  }
}

ReportFinancialEntity.propTypes = {
  label: PropTypes.string,
  showFullVat: PropTypes.bool,
  showFreeText: PropTypes.bool,
  financialEntity: financeEntityScheme,
};

ReportFinancialEntity.defaultProps = {
  label: null,
  showFullVat: false,
  showFreeText: false,
  financialEntity: null,
};

export default ReportFinancialEntity;
