import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { OUTBOUND_INVOICE_TEMPLATE_DESCRIPTION_LABELS } from 'finance/assets/js/constants';

class SimpleContractorChargesTable extends React.PureComponent {

  render() {
    const {
      invoice,
      invoicingSettings: { invoicingOutboundTemplateDescription },
      withSubtotal,
    } = this.props;

    if (!invoice
      || !invoice.amounts
      || !invoicingOutboundTemplateDescription
    ) {
      return null;
    }

    const {
      currency,
    } = invoice.amounts;
    const currencySymbol = CURRENCY_SYMBOL[currency];

    // sum Amount
    const sumAmount = invoice.amounts.amount;
    const tableColumns = [
      {
        key: 'item',
        label: 'Item',
        columnClassName: 'show-label',
        width: '60px',
      },
      {
        key: 'concept',
        label: 'Concept',
        headerAlign: 'left',
        columnClassName: 'show-label',
      },
      {
        key: 'amount',
        label: 'Amount',
        headerAlign: 'right',
        isFixedDecimals: true,
        isMoney: true,
        currencySymbol,
        columnClassName: 'show-label text-right',
        width: '165px',
      },
    ];

    const rows = [];
    // First Row - Item - Concept - Amount
    rows.push({
      item: '1',
      concept: OUTBOUND_INVOICE_TEMPLATE_DESCRIPTION_LABELS[invoicingOutboundTemplateDescription],
      amount: `${Number.parseFloat(sumAmount).toFixed(2)}`,
    });

    return (
      <React.Fragment>
        <Table
          containerClass="invoice-table"
          cols={tableColumns}
          items={rows}
        />

        {withSubtotal && (
          <div className="finance-report__summary">
            <div>
              <label>Subtotal</label>
              <NumberTpl
                prefix={CURRENCY_SYMBOL[invoice.amounts.currency]}
                value={invoice.amounts.amount}
                decimals={2}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

SimpleContractorChargesTable.propTypes = {
  invoice: PropTypes.object.isRequired,
  invoicingSettings: PropTypes.object.isRequired,
  orgAlias: PropTypes.string,
  withSubtotal: PropTypes.bool,
};


SimpleContractorChargesTable.defaultProps = {
  orgAlias: null,
  withSubtotal: false,
};

export default SimpleContractorChargesTable;
