import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { REPORT_TYPES } from 'finance/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';

const BillableRequestAmendmentForm = React.memo(({ billableLabel }) => (
  <form>
    <p>
      Is any of the information provided in the
      {' '}
      {billableLabel}
      {' '}
      not accurate?
      Are any of the amounts incorrect or the summary not detailed enough?
    </p>

    <p className="mb-4">
      There is no need to reject the
      {' '}
      {billableLabel}
      {' '}
      and put the wrong message across.
      Leave a comment below to request changes in the submitted
      {' '}
      {billableLabel}
      {' '}
      information.
    </p>

    <Field
      name="reason"
      component={InputField}
      label="Reason"
      type="textarea"
    />
  </form>
));

BillableRequestAmendmentForm.propTypes = {
  billableLabel: PropTypes.oneOf([
    REPORT_TYPES.EXPENSE, REPORT_TYPES.PRO_FORMA_INVOICE, REPORT_TYPES.WORKSHEET,
  ]).isRequired,
};

BillableRequestAmendmentForm.defaultProps = {};

export default BillableRequestAmendmentForm;
