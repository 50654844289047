import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { orgPeopleListApiUrl } from 'people/urls';
import { BS_STYLE, USER_CARD_STATUS, IMG_SIZE } from 'core/assets/js/constants';
import { getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import ModalPanel from 'core/assets/js/components/ModalPanel.jsx';
import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import ProviderCard from 'people/assets/js/components/ProviderCard.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';

export const MODAL_ID = 'user-group-add-members-modal';

const STEPS = {
  SELECT_MEMBERS: 1,
  CONFIRM_SELECTION: 2,
};

class UserGroupAddMembersPanel extends Component {
  static FetchData({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) {
    const apiUrl = orgPeopleListApiUrl(params, url);
    const prerequisites = [
      dispatch(fetchListDS({
        url: apiUrl,
        querystring,
        componentName,
        authedAxios,
      })),
    ];

    return Promise.all(prerequisites);
  }

  static GetComponentName() {
    return 'UserGroupAddMembersPanel';
  }

  constructor(props) {
    super(props);
    const { addMembersModalPayload } = props;
    const { userGroupId } = addMembersModalPayload;
    this.props = props;
    this.constantFilters = {
      excludeMembersOfUserGroup: userGroupId,
      status: USER_CARD_STATUS.APPROVED,
    };
    this.state = {
      currentStep: STEPS.SELECT_MEMBERS,
      query: { ...this.constantFilters },
    };
    this.handleClosePanel = this.handleClosePanel.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.handleShowSelectedItemsToggle = this.handleShowSelectedItemsToggle.bind(this);
  }

  handleClosePanel() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(MODAL_ID));
  }

  handleQueryUpdate(newQuery) {
    const { pagination } = this.props;
    this.setState({ query: { ...this.constantFilters, ...newQuery, page: pagination.page } });
  }

  handlePageChange(newPagination) {
    const { query } = this.state;
    const newQuery = { ...this.constantFilters, ...query, ...newPagination };
    this.setState(
      { query: newQuery },
    );
  }

  handleShowSelectedItemsToggle() {
    const { showSelectedItems } = this.state;
    this.setState({ showSelectedItems: !showSelectedItems });
  }

  render() {
    const {
      addMembersModalPayload,
      filtersOpen,
      handleAddMembersToGroup,
      onFiltersToggle,
      providers,
      rateLimits,
      selectedItems,
      submitting,
      userGroupNames,
    } = this.props;
    const { currentStep, query, showSelectedItems } = this.state;

    const { orgAlias, userType } = addMembersModalPayload;
    if (!addMembersModalPayload) {
      return null;
    }

    return (
      <ModalPanel
        modalId={MODAL_ID}
        open
        heading={(
          <h4>{`Add ${userType}`}</h4>
        )}
        body={(
          <>
            <SelectableListWrapper
              className={currentStep === STEPS.CONFIRM_SELECTION ? 'd-none' : null}
              componentName={UserGroupAddMembersPanel.GetComponentName()}
              cardItem={{
                component: ProviderCard,
                props: {
                  orgAlias,
                  onUpdated: () => {},
                  // Open link in a new tab so that user doesn't loose selected items.
                  linkTarget: '_blank',
                },
              }}
              emptyListMessage="No providers found."
              emptySelectedListMessage={(
                <p className="discreet">
                  There are currently no selected providers
                  <br />
                  <span
                    className="imitate-link"
                    onClick={this.handleShowSelectedItemsToggle}
                  >
                    Find providers
                  </span>
                  .
                </p>
              )}
              fetchData={
                ({ params, ...rest }) => UserGroupAddMembersPanel.FetchData({
                  ...rest, params: { ...params, peopleType: userType },
                })
              }
              filtersOpen={filtersOpen}
              items={providers}
              onPageChange={this.handlePageChange}
              query={query}
              selectModeEnabled
              showSelectedItems={showSelectedItems}
              skeletonComponent={PeopleListSkeleton}
              searchComponent={(
                <PeopleSearch
                  filtersOpen={filtersOpen}
                  hideStatus
                  isManager
                  onFiltersChanged={this.handleQueryUpdate}
                  onFiltersToggle={onFiltersToggle}
                  peopleType={userType}
                  query={query}
                  rateLimits={rateLimits}
                  userGroupNames={userGroupNames.map(group => ({
                    text: group.name, value: group.id.toString(),
                  }))}
                />
              )}
            />
            {currentStep === STEPS.CONFIRM_SELECTION && (
              <div>
                <p>{`You’ve selected to add ${selectedItems.length} new provider${selectedItems.length > 1 ? 's' : ''} to your group`}</p>
                <ul className="mb-4">
                  {selectedItems && isArray(selectedItems) && selectedItems.map(
                    member => (
                      <ProfilePic
                        alt={member.user.profile.name}
                        className="mr-3 mb-3"
                        key={member.user.id}
                        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                        url={member.user.profile.avatar}
                      />
                    ),
                  )}
                </ul>
              </div>
            )}
          </>
        )}
        footer={(
          <>
            {currentStep === STEPS.SELECT_MEMBERS && selectedItems.length > 0 && (
              <span className="cursor-pointer d-flex align-items-center" onClick={this.handleShowSelectedItemsToggle}>
                <TDSwitch selected={showSelectedItems} />
                <span className="ml-3">
                  { !showSelectedItems
                    ? `Show ${selectedItems.length} selected ${
                      // Remove 's' if only one
                      selectedItems.length === 1
                        ? userType.substring(0, userType.length - 1)
                        : userType
                    }`
                    : `Show all ${userType}`
                  }
                </span>
              </span>
            )}
            <div className="ml-auto">
              { currentStep === STEPS.SELECT_MEMBERS && (
                <>
                  <TDButton
                    label="Cancel"
                    onClick={this.handleClosePanel}
                  />
                  <TDButton
                    className="ml-4"
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    disabled={selectedItems.length === 0}
                    label="Next"
                    onClick={() => {
                      this.setState({ currentStep: STEPS.CONFIRM_SELECTION });
                    }}
                  />
                </>
              )}
              { currentStep === STEPS.CONFIRM_SELECTION && (
                <>
                  <TDButton
                    label="Back"
                    onClick={() => {
                      this.setState({ currentStep: STEPS.SELECT_MEMBERS });
                    }}
                  />

                  <TDButton
                    className="ml-4"
                    disabled={submitting || selectedItems.length === 0}
                    label={`Add member${selectedItems.length > 1 ? 's' : ''}`}
                    onClick={() => handleAddMembersToGroup(selectedItems.map(item => item.user.id))}
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                  />
                </>
              )}
            </div>
          </>
        )}
      />
    );
  }
}

UserGroupAddMembersPanel.propTypes = {
  addMembersModalPayload: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  handleAddMembersToGroup: PropTypes.func.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  providers: PropTypes.array,
  rateLimits: PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  userGroupNames: PropTypes.array,
};

UserGroupAddMembersPanel.defaultProps = {
  addMembersModalPayload: {},
  providers: [],
  rateLimits: {},
  userGroupNames: [],
};

const mapStateToProps = (state) => {
  const listState = getListState(state, UserGroupAddMembersPanel.GetComponentName());

  return {
    addMembersModalPayload: getModalPayload(state, MODAL_ID),
    pagination: listState.pagination,
    providers: listState.items,
    rateLimits: listState.extras.rateLimits,
    searchActive: listState.search.isActive,
    selectedItems: listState.selectedItems,
    userGroupNames: listState.extras.userGroupNames,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const UserGroupAddMembersPanelConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserGroupAddMembersPanel);

export default withRouter(withFilters(UserGroupAddMembersPanelConnected));
