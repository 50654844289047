import Big from 'big.js';

import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import { fetchCsvInfoApiUrl } from 'importer/urls';
import { REQ_TYPE } from 'core/assets/js/constants';
import {
  fetchWorksheetCsvAnalysisApiUrl,
  getUnusedTimeTrackingApiUrl,
  importWorksheetCsvApiUrl,
  projectFetchManagersApiUrl,
  projectFetchOutstandingCountsApiUrl,
  projectFetchProvidersApiUrl,
  projectNextExpenseInfoApiUrl,
  projectNextWorksheetInfoApiUrl,
  projectViewApiUrl,
} from 'projects/urls';
import { fetchProjectReviewsApiUrl } from 'people/urls';
import {
  projectManagersFetchAC,
  projectProvidersFetchAC,
  projectReviewsFetchAC,
  projectOutstandingDocsFetch,
} from 'projects/assets/js/actions/view';
import {
  getViewStateExtras,
  viewFetchAC,
  viewFetchErrorAC,
  viewFetchExtrasAC,
} from 'core/assets/js/ducks/view';
import { INVOICE_CAP_USAGE_BAR_COMPONENT_NAME } from 'projects/assets/js/constants';
import { getUserCardInvoiceCapUsageApiUrl } from 'settings/urls';

Big.RM = 1;
Big.DP = 2;

const _validateParams = ({ orgAlias, id }) => {
  if (!orgAlias || !id) {
    throw new Error(
      `[fetchProjectActiveDS Error] Invalid orgAlias (${orgAlias} or projectId (${id}))`);
  }
};

export const fetchProjectActiveDS = ({
  orgAlias, id, url = '', authedAxios = null, componentName,
}) => {
  const fetchDataAC = responseData => [
    viewFetchAC(responseData.project, componentName),
    viewFetchExtrasAC(responseData.allowedActions, componentName, 'allowedActions'),
  ];

  const fetchDataErrorAC = (error) => {
    if (error.response.data._meta && (
      error.response.data._meta.notFound || error.response.data._meta.accessDenied
    )) {
      return viewFetchErrorAC(error, componentName);
    }
    return null;
  };

  return fetchDataDS({
    authedAxios,
    validate: () => _validateParams({ orgAlias, id }),
    fetchApiUrl: () => projectViewApiUrl(orgAlias, id, url),
    fetchDataAC,
    fetchDataErrorAC,
  });
};

export const fetchProjectManagersDS = ({
  orgAlias, id, url = '', authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ orgAlias, id }),
  fetchApiUrl: () => projectFetchManagersApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => (
    projectManagersFetchAC(responseData.members)
  ),
});

export const fetchProjectProvidersDS = ({
  orgAlias, id, url = '', authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ orgAlias, id }),
  fetchApiUrl: () => projectFetchProvidersApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => (
    projectProvidersFetchAC(responseData.members)
  ),
});

// Fetch the info required for the "Add Worksheet" form
export const fetchNextWorksheetInfoDS = ({
  orgAlias, id, url = '', authedAxios = null, componentName,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ orgAlias, id }),
  fetchApiUrl: () => projectNextWorksheetInfoApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => (
    viewFetchExtrasAC(responseData, componentName, 'nextWorksheetInfo')
  ),
});

export const fetchReviewsDS = ({
  orgAlias, id, url = '', authedAxios = null,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ orgAlias, id }),
  fetchApiUrl: () => fetchProjectReviewsApiUrl(orgAlias, id, url),
  fetchDataAC: responseData => (
    projectReviewsFetchAC(responseData)
  ),
});

// Fetch the info required for the "Add Worksheet" form
export const fetchNextExpenseInfoDS = ({
  orgAlias, id, url = '', authedAxios = null, componentName,
}) => fetchDataDS({
  authedAxios,
  validate: () => _validateParams({ orgAlias, id }),
  fetchApiUrl: () => projectNextExpenseInfoApiUrl(orgAlias, id, url),
  componentName,
  fetchDataAC: responseData => (
    viewFetchExtrasAC(responseData, componentName, 'nextExpenseInfo')
  ),
});

// Fetch the outstanding documents for the project
export const fetchOutstandingCountsDS = ({
  orgAlias, id, componentName, url = '', authedAxios = null,
}) => (
  fetchDataDS({
    authedAxios,
    validate: () => _validateParams({ orgAlias, id }),
    fetchApiUrl: () => projectFetchOutstandingCountsApiUrl(orgAlias, id, url),
    componentName,
    fetchDataAC: responseData => projectOutstandingDocsFetch(responseData),
  })
);

export const fetchProjectWorksheetImportInfoDS = ({
  orgAlias, id, componentName, authedAxios = null, querystring = '',
}) => fetchDataDS({
  querystring,
  authedAxios,
  validate: () => _validateParams({ orgAlias, id }),
  fetchApiUrl: () => fetchCsvInfoApiUrl({ orgAlias }),
  fetchDataAC: responseData => (
    viewFetchAC(responseData, componentName, 'analysis')
  ),
});

export const fetchWorksheetImportPreviewInfoDS = ({
  orgAlias, id, componentName, authedAxios = null, querystring = '',
}) => fetchDataDS({
  querystring,
  authedAxios,
  validate: () => _validateParams({ orgAlias, id }),
  fetchApiUrl: () => fetchWorksheetCsvAnalysisApiUrl(orgAlias, id, querystring),
  fetchDataAC: responseData => (
    viewFetchAC(responseData, componentName)
  ),
});

export const importWorksheetsDS = ({ values, orgAlias, id }) => (
  pushDataDS({
    reqType: REQ_TYPE.POST,
    validate: () => _validateParams({ orgAlias, id }),
    pushApiUrl: importWorksheetCsvApiUrl(orgAlias, id),
    values,
  })
);

export const fetchTimeTrackerUnusedRecordsDS = ({
  authedAxios = null, componentName, orgAlias, userId = null,
}) => fetchDataDS({
  authedAxios,
  fetchApiUrl: () => getUnusedTimeTrackingApiUrl(orgAlias, userId),
  fetchDataAC: responseData => viewFetchAC(responseData, componentName),
});

export const fetchInvoiceCapUsageDS = ({
  authedAxios = null, orgAlias, serviceOrderId, userId,
}) => fetchDataDS({
  authedAxios,
  fetchApiUrl: () => getUserCardInvoiceCapUsageApiUrl(orgAlias, userId, serviceOrderId),
  fetchDataAC: responseData => (
    viewFetchExtrasAC(responseData, INVOICE_CAP_USAGE_BAR_COMPONENT_NAME, 'invoiceCapUsage')
  ),
});

export const getInvoiceCapUsage = state => ({
  additionalOrganizationAmount, serviceOrderPeriodEnd,
}) => {
  const usageData = {
    ...getViewStateExtras(
      state, INVOICE_CAP_USAGE_BAR_COMPONENT_NAME, 'invoiceCapUsage',
    ),
  };

  usageData.invoiceCapIsSet = ![undefined, null].includes(usageData.invoiceCap);

  let totalUsage = usageData.usage;
  let over100 = false;
  if (usageData.usage !== undefined && usageData.invoiceCapIsSet) {
    usageData.usageAt100 = usageData.usage === parseFloat(usageData.invoiceCap);
    if (
      additionalOrganizationAmount
      && (
        !serviceOrderPeriodEnd
        || (
          new Date(`${serviceOrderPeriodEnd}T23:59:59.999Z`).getTime()
            > new Date(usageData.periodStartISO).getTime()
        )
      )
    ) {
      totalUsage = Big(usageData.usage).plus(additionalOrganizationAmount || 0).toNumber();
      if (additionalOrganizationAmount && totalUsage > usageData.invoiceCap) {
        over100 = true;
      }
    }
  }

  usageData.totalUsage = totalUsage;
  usageData.over100 = over100;

  return usageData;
};
