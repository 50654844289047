import React from 'react';

import IntegrationTalentDeskMappingSettings from 'integrations/assets/js/components/settings/IntegrationTalentDeskMappingSettings.jsx';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const IntegrationSetupTalentDeskMapping = ({ integration }) => (
  <>
    <h2>Map TalentDesk companies</h2>
    <IntegrationTalentDeskMappingSettings integration={integration} />
  </>
);

IntegrationSetupTalentDeskMapping.propTypes = {
  integration: integrationSpec.isRequired,
};

export default IntegrationSetupTalentDeskMapping;
