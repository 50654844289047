import React from 'react';

import { generateApplicationStatusLabel } from 'projects/assets/js/lib/helpers';
import StatusDot from 'core/assets/js/components/StatusDot.jsx';
import { PROJECT_APPLICATION_STATUS_CLASS } from 'projects/assets/js/constants';
import { projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';


const ProjectApplicationStatusBar = ({
  application,
}) => {
  const statusLabel = generateApplicationStatusLabel(application);
  const { status } = application;

  return (
    <div className={`project-opportunities-card__status-bar project-opportunities-card__status-bar--${PROJECT_APPLICATION_STATUS_CLASS[status]}`}>
      <span>
        <StatusDot />
        {' '}
        {statusLabel}
      </span>
    </div>
  );
};

ProjectApplicationStatusBar.propTypes = {
  application: projectApplicationSpec.isRequired,
};

export default ProjectApplicationStatusBar;
