import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { ICON } from 'core/assets/js/constants';

const STEPS = [
  {
    description: 'Navigate among our curated talent with different skillsets',
    icon: ICON.SEARCH,
    isLast: false,
    name: 'Search talent based on skills',
  },
  {
    description: 'Have discussions with your talent',
    icon: ICON.COMMENT,
    isLast: false,
    name: 'Have discussions with your talent',
  },
  {
    description:
    'Once you have found the best available contractors, invite them to join your organization',
    icon: ICON.ENVELOPE_INVITE,
    isLast: false,
    name: 'Invite talent to your organization',
  },
  {
    description: 'Work with the best talent and build your perfect team',
    icon: ICON.CHECKMARK,
    isLast: true,
    name: 'Work with your invited talent',
  },
];

const HowItWorksModal = ({ show, handleClose }) => (
  <ModalSimple
    body={(
      <div className="how-it-works">
        <i
          className={`${ICON.CROSS} how-it-works__close`}
          onClick={handleClose}
        />
        <h1 className="how-it-works__title">Search our top contractors</h1>
        <h3 className="how-it-works__sub">
          Work with the best of our curated talent pool
        </h3>
        <div className="row w-100 text-center justify-content-around">
          {STEPS.map((step) => (
            <Fragment key={step.name}>
              <div className="col-2 col-sm-12 col-xs-12 col-md-2 px-0">
                <i className={`${step.icon} how-it-works__icon`} />
                <h4 className="how-it-works__semibold">{step.name}</h4>
                <p>{step.description}</p>
              </div>
              {!step.isLast && (
                <i className={`${ICON.ARROW_RIGHT} how-it-works__arrow`} />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    )}
    noFooter
    onClose={handleClose}
    open={show}
    size="xl"
  />
);

HowItWorksModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

HowItWorksModal.defaultProps = {
  show: false,
};

export default HowItWorksModal;
