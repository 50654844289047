import React from 'react';
import PropTypes from 'prop-types';

import {
  SERVICE_ORDER_TYPE,
  SERVICE_ORDER_TYPE_ABBREVIATION,
  SERVICE_ORDER_TYPE_LABEL,
} from 'projects/assets/js/constants';


const WorksheetTypeIndicator = ({ type }) => {
  if (!type) {
    return null;
  }

  const typeAbbreviation = SERVICE_ORDER_TYPE_ABBREVIATION[type];

  return (
    <div
      title={SERVICE_ORDER_TYPE_LABEL[type]}
      className={`worksheet-type-indicator worksheet-type-indicator--${typeAbbreviation.toLowerCase()}`}
    >
      { typeAbbreviation }
    </div>
  );
};

WorksheetTypeIndicator.propTypes = {
  type: PropTypes.oneOf(Object.values(SERVICE_ORDER_TYPE)),
};

WorksheetTypeIndicator.defaultProps = {
  type: null,
};

export default WorksheetTypeIndicator;
