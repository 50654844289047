import PropTypes from 'prop-types';

export const notificationSpec = PropTypes.shape({
  body: PropTypes.string,
  created_at: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.number,
  is_read: PropTypes.bool,
  org: PropTypes.object,
  targetUrl: PropTypes.string,
  type: PropTypes.string,
});

export const activityRecordSpec = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.number,
  createdAt: PropTypes.string,
  user: PropTypes.object,
  context: PropTypes.object,
  body: PropTypes.string,
});
