import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { contactsListCsvApiUrl } from 'contacts/urls';
import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import TagsFilterField from 'core/assets/js/components/FinalFormFilterFields/TagsFilterField.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import {
  CONTACT_FILTER_STATUS,
  CONTACT_FILTER_STATUS_LABEL,
  CONTACTS_DEFAULT_ORDERING,
} from 'contacts/assets/js/constants';
import { ICON, USER_TYPE_LABEL, USER_TYPE } from 'core/assets/js/constants';
import { countryOptions } from 'core/assets/js/lib/isoCountries';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';


const defaultOrdering = CONTACTS_DEFAULT_ORDERING;

class ContactSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
  }

  getSearchSpec() {
    const { customFieldFilters } = this.props;

    const statusOptions = [{
      text: CONTACT_FILTER_STATUS_LABEL[CONTACT_FILTER_STATUS.MANAGER],
      value: CONTACT_FILTER_STATUS.MANAGER,
    }, {
      text: CONTACT_FILTER_STATUS_LABEL[CONTACT_FILTER_STATUS.PROVIDER],
      value: CONTACT_FILTER_STATUS.PROVIDER,
    }, {
      text: CONTACT_FILTER_STATUS_LABEL[CONTACT_FILTER_STATUS.PROVIDER_INV_PENDING],
      value: CONTACT_FILTER_STATUS.PROVIDER_INV_PENDING,
    }, {
      text: CONTACT_FILTER_STATUS_LABEL[CONTACT_FILTER_STATUS.MANAGER_INV_PENDING],
      value: CONTACT_FILTER_STATUS.MANAGER_INV_PENDING,
    }, {
      text: CONTACT_FILTER_STATUS_LABEL[CONTACT_FILTER_STATUS.NOT_INVITED],
      value: CONTACT_FILTER_STATUS.NOT_INVITED,
    }];

    const roleOptions = [{
      text: USER_TYPE_LABEL[USER_TYPE.PROVIDER],
      value: USER_TYPE.PROVIDER,
    }, {
      text: USER_TYPE_LABEL[USER_TYPE.MANAGER],
      value: USER_TYPE.MANAGER,
    }];
    const searchSpec = {
      customFieldFilters,
      searchTerm: {
        placeholder: 'Search Contacts',
        paramName: 'kw',
        component: AutoSuggestField,
      },
      defaultOrdering,
      orderingOptions: [
        { text: 'Created date', value: 'created_at' },
        { text: 'Last name', value: 'last_name' },
      ],
      filters: [
        // Note: Ordering filter is only shown on mobile devices.
        {
          label: 'Sort By',
          paramName: 'ordering',
          fieldComponent: SelectableListFilterField,
          multiple: false,
          isOrdering: true,
          options: [
            {
              text: (
                <span>
                  Created date
                  {' '}
                  <i className={ICON.SORTING_ASC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'ASC',
                sortBy: 'created_at',
              }),
            },
            {
              text: (
                <span>
                  Created date
                  {' '}
                  <i className={ICON.SORTING_DESC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'DESC',
                sortBy: 'created_at',
              }),
            },
            {
              text: (
                <span>
                  Last name
                  {' '}
                  <i className={ICON.SORTING_ALPHA_ASC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'ASC',
                sortBy: 'last_name',
              }),
            },
            {
              text: (
                <span>
                  Last name
                  {' '}
                  <i className={ICON.SORTING_ALPHA_DESC} />
                </span>
              ),
              value: JSON.stringify({
                direction: 'DESC',
                sortBy: 'last_name',
              }),
            },
          ],
        },
        {
          label: 'Status',
          paramName: 'status',
          fieldComponent: SelectableListFilterField,
          multiple: false,
          options: statusOptions,
        },
        {
          label: 'Contact role',
          paramName: 'role',
          fieldComponent: SelectableListFilterField,
          multiple: false,
          options: roleOptions,
        },
        {
          label: 'Country',
          paramName: 'countryCode',
          fieldComponent: TagsFilterField,
          inputClassName: 'col-12 col-md-6',
          options: countryOptions,
        },
      ],
    };

    return searchSpec;
  }

  render() {
    const { filtersOpen, location, match: { params: { orgAlias } }, onFiltersToggle } = this.props;

    return (
      <SearchFinalForm
        extraSearchElement={filtersOpen ? null : (
          <div className="d-flex align-items-center ml-auto">
            <ListActionsButton
              options={[{
                name: 'Export',
                onClick: () => {
                  WINDOW_OPEN(`${contactsListCsvApiUrl(orgAlias)}${location.search}`);
                },
              }]}
              wrapperClassName="d-block mt-3"
            />
          </div>
        )}
        filtersOpen={filtersOpen}
        onFiltersToggle={onFiltersToggle}
        searchSpec={this.getSearchSpec()}
      />
    );
  }
}

ContactSearch.propTypes = {
  customFieldFilters: PropTypes.arrayOf(PropTypes.object),
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

ContactSearch.defaultProps = {
  customFieldFilters: [],
};

export default withRouter(ContactSearch);
