import React from 'react';

import { CUSTOM_ICONS, ICON, SOCIAL_ICONS } from 'core/assets/js/constants';

function IconsSection() {
  return (
    <section id="icons">
      <h2>Social Icons</h2>
      <div className="row mb-5">
        {Object.keys(SOCIAL_ICONS).map(
          icon => (
            <div key={icon} className="col-6 col-md-4 col-xl-3 mt-4">
              <i className={SOCIAL_ICONS[icon]} />
              &ensp;
              SOCIAL_ICONS.
              {icon}
            </div>
          ),
        )}
      </div>
      <h2>Custom Icons</h2>
      <div className="row mb-5">
        {Object.keys(CUSTOM_ICONS).map(
          icon => (
            <div key={icon} className="col-6 col-md-4 col-xl-3 mt-4">
              <img alt={`CUSTOM_ICONS.${icon}`} src={CUSTOM_ICONS[icon]} />
              &ensp;
              CUSTOM_ICONS.
              {icon}
            </div>
          ),
        )}
      </div>
      <h2>Icons</h2>
      <div className="row">
        {Object.keys(ICON).map(
          icon => (
            <div key={icon} className="col-6 col-md-4 col-xl-3 mt-4">
              <i className={ICON[icon]} />
              &ensp;
              ICON.
              {icon}
            </div>
          ),
        )}
      </div>
    </section>
  );
}

export default IconsSection;
