import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CURRENCY, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { RATE_UNIT, RATE_UNIT_CUSTOM_SELECT } from 'rates/assets/js/constants';
import RateField from 'rates/assets/js/components/finalFormFields/RateField.jsx';
import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';

const TaskRateSuggestion = ({
  currencySymbol, defaultRateUnit, fieldPrefix, suggestedRateUnit,
}) => {
  const [selectedRateUnit, setSelectedRateUnit] = useState(suggestedRateUnit || defaultRateUnit);

  const currencyFieldName = `${fieldPrefix ? `${fieldPrefix}.` : ''}currency`;

  return (
    <div className="row form-group task-rate-suggestion">
      <div className="col-12 mb-4">
        <label>Suggest new rate</label>
      </div>

      <div className="col-12 mb-4">
        <CustomSelectField
          label="Rate unit"
          name={fieldPrefix ? `${fieldPrefix}.rate_unit` : 'rate_unit'}
          selectedOption={selectedRateUnit}
          onItemsUpdated={setSelectedRateUnit}
          options={RATE_UNIT_CUSTOM_SELECT.map(option => ({ ...option, isFixed: true }))}
          required
        />
      </div>

      {selectedRateUnit !== RATE_UNIT.COMMISSION && (
        <div className="col-12 mb-4">
          <CurrencySelectField
            currencyOptions={CURRENCY}
            isClearable={false}
            label="Currency"
            name={currencyFieldName}
            required
          />
        </div>
      )}

      <div className="col-12">
        <RateField
          currencySymbol={currencySymbol}
          name={fieldPrefix ? `${fieldPrefix}.rate` : 'rate'}
          unit={selectedRateUnit}
        />
      </div>
    </div>
  );
};

TaskRateSuggestion.propTypes = {
  currencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  defaultRateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  fieldPrefix: PropTypes.string,
  suggestedRateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
};

TaskRateSuggestion.defaultProps = {
  currencySymbol: CURRENCY_SYMBOL[CURRENCY.GBP],
  defaultRateUnit: RATE_UNIT.PER_HOUR,
  suggestedRateUnit: null,
  fieldPrefix: null,
};

export default TaskRateSuggestion;
