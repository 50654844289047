import { INTEGRATION_DETAILS_TABS } from 'integrations/assets/js/constants';

export const orgIntegrationsUrl = orgAlias => (
  `/${orgAlias}/integrations`
);
export const orgIntegrationConnectUrl = (orgAlias, integrationType) => (
  `/${orgAlias}/integrations/connect/${integrationType}`
);
export const orgIntegrationDetailUrl = (
  orgAlias, integrationId, tabName = INTEGRATION_DETAILS_TABS.ACTIVITY, query = null,
) => (
  `/${orgAlias}/integrations/${integrationId}/${tabName}${query ? `?${query}` : ''}`
);
export const orgAddIntegrationUrl = (orgAlias, integrationType) => (
  `/${orgAlias}/integrations/add/${integrationType}`
);

// API URLs
export const addIntegrationApiUrl = (orgAlias, integrationType) => (
  `/api/${orgAlias}/integrations/${integrationType}`
);
export const toggleIntegrationApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/${integrationId}/toggle`
);
export const deleteIntegrationApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/${integrationId}`
);
export const integrationUpdateSettingsApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/${integrationId}/settings`
);
export const orgIntegrationsApiUrl = orgAlias => (
  `/api/${orgAlias}/integrations`
);
export const orgIntegrationDetailApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/${integrationId}`
);
export const orgIntegrationConfigApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/${integrationId}/config`
);
export const orgIntegrationSyncStatusApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/${integrationId}/sync-status`
);
export const orgIntegrationCompleteSetupApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/${integrationId}/complete-setup`
);

// Accounting integration API urls
export const accountingIntegrationActivityApiUrl = (orgAlias, integrationId) => `/api/${orgAlias}/integrations/accounting/${integrationId}/activity`;

export const accountingIntegrationSyncApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/sync`
);
export const accountingSupplierMappingApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/supplier-mapping`
);
export const accountingSupplierTalentDeskMappingsApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/supplier-talentdesk-mappings`
);
export const accountingSupplierContractorsMappingsApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/supplier-contractor-mappings`
);
export const accountingSupplierSearchApiUrl = (orgAlias, integrationId, term) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/supplier-search?kw=${term}`
);
export const accountingAccountSettingsApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/account-settings`
);
export const accountingInvoiceSettingsApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/invoice-settings`
);
export const accountingCreateContractorApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/create-contractor`
);
export const accountingCreateSystemOrgApiUrl = (orgAlias, integrationId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/create-system-org`
);
export const accountingGetInvoiceSyncPushOperationApiUrl = (
  orgAlias, integrationId, codatInvoiceSyncJobId,
) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}`
  + `/invoice-sync-push-operation/${codatInvoiceSyncJobId}`
);
export const accountingRetryInvoiceSyncApiUrl = (orgAlias, integrationId, invoiceId) => (
  `/api/${orgAlias}/integrations/accounting/${integrationId}/retry-invoice-sync/${invoiceId}`
);
