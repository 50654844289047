import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import InputField, { INPUT_TYPE } from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, MAX_UPLOAD_FILES, MIME_TYPES } from 'core/assets/js/constants';
import FileUploaderField from 'core/assets/js/components/ReduxFormFields/FileUploaderField.jsx';
import { uploaderNotesPath } from 'core/urls';


const NoteForm = (props) => {
  const {
    handleSubmit, onSubmit, submitting, onCloseModal, show, editForm, subjectId, orgId,
  } = props;
  const body = (
    <React.Fragment>
      <Field name="subject_id" type="hidden" component="input" />
      <Field name="body" type={INPUT_TYPE.MARKDOWN_TEXTAREA} component={InputField} />
      <Field
        name="attachments_metadata"
        component={FileUploaderField}
        label="Attachments"
        acceptFiles={[...MIME_TYPES.DOCUMENTS, ...MIME_TYPES.IMAGES]}
        maxFiles={MAX_UPLOAD_FILES}
        path={uploaderNotesPath(orgId, subjectId)}
      />
    </React.Fragment>
  );

  const footer = (
    <>
      <TDButton
        onClick={onCloseModal}
        variant={BS_STYLE.DEFAULT}
        label="Cancel"
      />
      <TDButton
        type="submit"
        disabled={submitting}
        onClick={handleSubmit(onSubmit)}
        variant={BS_STYLE.PRIMARY}
        label={editForm ? 'Update note' : 'Post note'}
      />
    </>
  );
  return (
    <div className="static-modal">
      <form>
        <ModalSimple
          open={show}
          heading={editForm ? 'Edit note' : 'Add note'}
          body={body}
          footer={footer}
          onClose={onCloseModal}
        />
      </form>
    </div>
  );
};

NoteForm.propTypes = {
  editForm: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  subjectId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
};

NoteForm.defaultProps = {
  editForm: false,
  onCloseModal: () => {},
  show: false,
};

const NoteFormRedux = reduxForm({
  form: 'noteForm',
  enableReinitialize: true,
})(NoteForm);

export default connect()(NoteFormRedux);
