import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stringify, parse } from 'query-string';

import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { DATE_DURATION, DATE_DURATION_API_KEY } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { lineWorksheetAnalyticsApiUrl } from 'analytics/urls';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ChartLine from 'projects/assets/js/components/charts/ChartLine.jsx';
import ProjectChartBasicLineSkeleton from 'projects/assets/js/components/skeletons/ProjectChartBasicLineSkeleton.jsx';

class ProjectExpensesChartLine extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, selectedDateDuration, querystring }) {
    const storeKey = ProjectExpensesChartLine.GetStoreKey(selectedDateDuration);
    return dispatch(fetchListDS({
      url: lineWorksheetAnalyticsApiUrl(
        params.orgAlias, params.id, url,
      ),
      id: params.id,
      componentName: storeKey,
      authedAxios,
      querystring: stringify({
        ...parse(querystring),
        dateDuration: selectedDateDuration,
        billableType: SERVICE_ORDER_TYPE.EXPENSE,
      }),
    }));
  }

  static GetStoreKey(selectedDateDuration) {
    const dateDurationKey = DATE_DURATION_API_KEY[selectedDateDuration];
    return `lineExpAnalytics_${dateDurationKey}`;
  }

  render() {
    const { analytics, currencySymbol, selectedDateDuration } = this.props;
    const analyticsFormatted = analytics.map(entry => [entry.recordedAt, entry.amount]);

    return (
      <TDApiConnected
        duck="list"
        storeKey={ProjectExpensesChartLine.GetStoreKey(selectedDateDuration)}
        fetchData={({ ...args }) => (
          ProjectExpensesChartLine.FetchData({ ...args, selectedDateDuration },
          ))}
        blockingLoading
        skeletonComponent={ProjectChartBasicLineSkeleton}
      >
        <ChartLine
          chartName="Expenses"
          selectedDateDuration={selectedDateDuration}
          currencySymbol={currencySymbol}
          analytics={analyticsFormatted}
          emptyText="No approved expenses found"
          type={SERVICE_ORDER_TYPE.WORKSHEET}
        />
      </TDApiConnected>
    );
  }
}

ProjectExpensesChartLine.propTypes = {
  analytics: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  selectedDateDuration: PropTypes.number,
};

ProjectExpensesChartLine.defaultProps = {
  selectedDateDuration: DATE_DURATION.CURRENT_MONTH,
};

const mapStateToProps = (state, props) => {
  const { selectedDateDuration } = props;
  const listState = getListState(
    state, ProjectExpensesChartLine.GetStoreKey(selectedDateDuration),
  );
  return {
    analytics: listState.items,
  };
};

const ProjectTabExpensesChartStackedConnected = connect(
  mapStateToProps,
  null,
)(ProjectExpensesChartLine);

export default ProjectTabExpensesChartStackedConnected;
