import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import ExpenseForm from 'finance/assets/js/components/ExpenseForm.jsx';
import { PROJECT_TABS } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import {
  fetchProjectActiveDS, fetchNextExpenseInfoDS,
} from 'projects/assets/js/data-services/view';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectViewUrl, projectListUrl } from 'projects/urls';
import ServiceOrderPrerequisites from 'projects/assets/js/components/ServiceOrderPrerequisites.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectClaimExpenseDS } from 'projects/assets/js/data-services/form';
import { fetchExchangeRatesDS } from 'finance/assets/js/data-services/form';
import { exchangeRatesResponseSpec } from 'finance/assets/js/lib/objectSpecs';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';

class ProjectClaimExpenseView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchProjectActiveDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName,
      })),
      dispatch(fetchNextExpenseInfoDS({
        orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName,
      })),
      dispatch(fetchExchangeRatesDS({
        authedAxios, componentName, orgAlias: params.orgAlias, url,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ProjectClaimExpenseView';
  }

  constructor(props) {
    super(props);

    this._onSubmit = this._onSubmit.bind(this);
  }

  async _onSubmit(values) {
    const { history, match: { params: { orgAlias } }, project } = this.props;
    try {
      const response = await projectClaimExpenseDS(orgAlias, project.id, values);

      if (response.data._error) {
        return response.data;
      }

      history.push(projectViewUrl(orgAlias, project.id, PROJECT_TABS.EXPENSES));

      return true;
    } catch (error) {
      return error.response.data;
    }
  }

  render() {
    const {
      exchangeRatesResponse,
      info,
      match: { params: { orgAlias } },
      organization,
      project,
    } = this.props;

    const breadcrumbs = [
      { title: 'Projects', url: projectListUrl(orgAlias) },
      { title: project.title, url: projectViewUrl(orgAlias, project.id) },
      { title: 'Claim Expense', url: null },
    ];

    const {
      rates: exchangeRates = {}, service: exchangeRatesService, timestamp: exchangeRateUpdatedAt,
    } = exchangeRatesResponse;

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--add-worksheet">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={ProjectCardSkeleton}
            >
              <ServiceOrderPrerequisites
                missingFields={info.missingFields}
                orgAlias={orgAlias}
                orgName={organization.name}
                serviceOrderType={SERVICE_ORDER_TYPE.EXPENSE}
              />

              <ExpenseForm
                allowedPeriodStart={info.allowedPeriodStart}
                disableSubmit={info.missingFields && info.missingFields.length > 0}
                exchangeRates={exchangeRates}
                exchangeRatesService={exchangeRatesService}
                exchangeRateUpdatedAt={exchangeRateUpdatedAt}
                initialValues={{ cost: '0.00', currency: organization.currency, quantity: 1 }}
                onSubmit={this._onSubmit}
                orgAlias={orgAlias}
                project={project}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectClaimExpenseView.propTypes = {
  exchangeRatesResponse: exchangeRatesResponseSpec,
  history: routerHistorySpec.isRequired,
  info: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  organization: orgSpec.isRequired,
  project: projectSpec,
};

ProjectClaimExpenseView.defaultProps = {
  exchangeRatesResponse: { rates: {}, service: null, timestamp: null },
  project: {},
  info: {},
};

const mapStateToProps = (state) => {
  const componentName = ProjectClaimExpenseView.GetComponentName();
  const viewState = getViewState(state, componentName);
  return {
    exchangeRatesResponse: getViewStateExtras(state, componentName, 'exchangeRates'),
    info: getViewStateExtras(state, componentName, 'nextExpenseInfo'),
    organization: selectActiveOrg(state),
    project: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectClaimExpenseViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectClaimExpenseView);

export default withRouter(ProjectClaimExpenseViewConnected);
