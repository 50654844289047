import React from 'react';

import IntegrationInvoiceMappingSettings from 'integrations/assets/js/components/settings/IntegrationInvoiceMappingSettings.jsx';
import { integrationSpec } from 'integrations/assets/js/lib/objectSpecs';

const IntegrationSetupInvoiceMapping = ({ integration }) => (
  <>
    <h2>Map invoices</h2>
    <IntegrationInvoiceMappingSettings integrationName={integration.name} />
  </>
);

IntegrationSetupInvoiceMapping.propTypes = {
  integration: integrationSpec.isRequired,
};

export default IntegrationSetupInvoiceMapping;
