import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { orgPeopleDiscoverPortfolioUrl } from 'people/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const TalentPortfolioCard = ({ talent }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const history = useHistory();

  const portfolio = talent?.portfolio || [];

  return (
    <Card>
      <Card.Header>Portfolio</Card.Header>
      <Card.Body>
        <div className="row talent__portfolio-container">
          {portfolio.length
            ? portfolio.slice(0, 6).map((img, idx) => (
              <div className="col-6 col-xs-6 col-sm-6 col-lg-4 pl-0 pr-0 pb-3 talent__image" key={`${img.thumbnail}-${idx}`}>
                <img
                  alt={img.title}
                  className="freelancer-details-card__portfolio-image"
                  data-testid={`freelancer-details-card__portfolio-image-${img.title}`}
                  onClick={
                    () => history.push(orgPeopleDiscoverPortfolioUrl(
                      activeOrg.alias,
                      talent.id,
                      idx,
                    ))
                  }
                  src={img.thumbnail}
                />
              </div>
            ))
            : <p className="text-muted m-auto">Loading...</p>}
        </div>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Link to={orgPeopleDiscoverPortfolioUrl(activeOrg.alias, talent.id)}>
          {`View all (${talent?.portfolio?.length})`}
        </Link>
      </Card.Footer>
    </Card>
  );
};

TalentPortfolioCard.propTypes = {
  talent: PropTypes.object.isRequired,
};

export default TalentPortfolioCard;
