import React from 'react';
import PropTypes from 'prop-types';

import { CURRENCY_LABEL } from 'core/assets/js/constants';
import {
  getDefaultPaymentReferenceLength,
  generatePaymentReference,
  getPaymentReferenceLengthForCurrency,
} from 'finance/assets/js/lib/utils';

const PaymentReferencePreview = ({
  paymentReference,
  currency,
  showTruncated,
  showCalculatedPaymentReference,
  showMessage,
}) => {
  const {
    fullPaymentReference: sanitizedPaymentReference,
    paymentReference: truncatedPaymentReference,
  } = generatePaymentReference(paymentReference, '', '', currency);

  const validPaymentReference = truncatedPaymentReference.length
    === sanitizedPaymentReference.length;

  return paymentReference ? (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          {validPaymentReference ? (
            <React.Fragment>
              {showMessage && (
                <label>
                  <strong>{sanitizedPaymentReference}</strong>
                  &nbsp;
                  will appear in your bank statement when you receive your next payment.
                </label>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {currency ? (
                <React.Fragment>
                  <label className="text-warning">
                    {`The allowed payment reference length for ${CURRENCY_LABEL[currency]} is ${getPaymentReferenceLengthForCurrency(currency)} characters, but "${sanitizedPaymentReference}" is ${sanitizedPaymentReference.length} characters long${showTruncated ? ', ' : '.'}`}
                    {showTruncated && (
                      <React.Fragment>
                        <strong>{truncatedPaymentReference}</strong>
                        &nbsp;
                        will appear on your bank statement.
                      </React.Fragment>
                    )}
                  </label>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <label className="text-warning">
                    {`The allowed payment reference length is ${getDefaultPaymentReferenceLength()} characters, but "${sanitizedPaymentReference}" is ${sanitizedPaymentReference.length} characters long${showTruncated ? ', ' : '.'}`}
                    {showTruncated && (
                      <React.Fragment>
                        <strong>{truncatedPaymentReference}</strong>
                        &nbsp;
                        will appear on your bank statement.
                      </React.Fragment>
                    )}
                  </label>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {showCalculatedPaymentReference && (
            <p>
              <b>Calculated payment reference:</b>
              &nbsp;
              &quot;
              {truncatedPaymentReference}
              &quot;
            </p>
          )}
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

PaymentReferencePreview.propTypes = {
  paymentReference: PropTypes.string,
  currency: PropTypes.string,
  showMessage: PropTypes.bool,
  showTruncated: PropTypes.bool,
  showCalculatedPaymentReference: PropTypes.bool,
};

PaymentReferencePreview.defaultProps = {
  paymentReference: null,
  currency: null,
  showMessage: true,
  showTruncated: true,
  showCalculatedPaymentReference: false,
};

export default PaymentReferencePreview;
