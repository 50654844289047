import React from 'react';

import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import SettingsItem from 'core/assets/js/components/SettingsItem.jsx';
import { INVOICE_STATUS, INVOICE_STATUS_LABEL } from 'finance/assets/js/constants';
import {
  INTEGRATION_SYNC_TYPE, INTEGRATION_SYNC_TYPE_LABEL,
} from 'integrations/assets/js/constants';

const IntegrationGeneralSettings = () => (
  <>
    <SettingsItem
      containerClassName="mb-4"
      cta={(
        <div className="integration-settings__sync-type">
          <SelectField
            name="syncType"
            optionsMapping={(
              Object
                .values(INTEGRATION_SYNC_TYPE)
                .map(v => ({ value: v, text: INTEGRATION_SYNC_TYPE_LABEL[v] }))
            )}
          />
        </div>
      )}
      disclaimer={[
        'Select between manual or automatic synchronization. If none applied you can completely',
        ' disable it.',
      ].join('')}
      label="How would you like to synchronize?"
    />
    <SettingsItem
      containerClassName="mb-4"
      cta={<DatePickerField name="startDate" />}
      disclaimer="Select a starting date from which you would like to sync your data."
      label="From which date do you wish to synchronize data from?"
    />
    <SettingsItem
      containerClassName="mb-4"
      cta={(
        <div className="integration-settings__sync-type">
          <SelectField
            name="syncInvoiceStatus"
            optionsMapping={(
              [INVOICE_STATUS.RAISED, INVOICE_STATUS.PAID]
                .map(value => ({ value, text: INVOICE_STATUS_LABEL[value] }))
            )}
          />
        </div>
      )}
      disclaimer="In which state should invoices be synchronized to your accounting programme?"
      label="Which invoice state would you like to synchronize?"
    />
  </>
);

export default IntegrationGeneralSettings;
