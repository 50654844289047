import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const CancelInvitationButton = ({ onClick, label }) => (
  <TDButton
    onClick={onClick}
    variant={BS_STYLE.DANGER}
    label={label}
  />
);

CancelInvitationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CancelInvitationButton;
